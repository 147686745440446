import React, { PureComponent } from 'react'
import { Fragment } from "react"
import UserContext from "../../views/App/Context"
import _ from 'lodash'
import AgentTopNavigation from './AgentTopNavigation/container'
import TopNevigationMobile from './TopNevigationMobile/container'
import AdministrationMobileTopNavigation from './AdministrationMobileTopNavigation/container'
import AdministrationTopNavigation from './AdministrationTopNavigation/container'
import Cookies from 'universal-cookie'
import './styles.scss'
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
import { fetch } from '../../utils'
import {getHostname} from "../../utils/helper";

const cookies = new Cookies() // Import css
let _user = ''
let pre_Auth = ''

export default class TopNavigationComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props){
    super(props)
    this.state={
      userData: {},
      pre_Auth: '',
      _user: '',
      searchAgentData: [],
      searchUserClientData: []
    }
    this.getSearchData = _.debounce(this.getSearchData, 500);
  }

  componentDidMount(){
    _user = cookies.get('user')

    if(_user && _user.id){
      this.props.fetchUser({ user_id: _user.id })
    }
    this.setState({ userData: this.context.userData, _user })
    pre_Auth = cookies.get('i_user')
    if(pre_Auth){
     this.setState({pre_Auth: pre_Auth})
    }
  }

  componentDidUpdate(){
    this.setState({ userData: this.context.userData })
  }


  getSearchData=async(data)=>{
    
    const searchTerm = encodeURIComponent(data.trim())

    if(_.size(data) > 2){
      const fetchSearch = await fetch(`${SUBURBANJUNGLE_API_HOST}/users/search?term=${searchTerm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then((res) => res.json())
          .then((payload) => payload)
          .catch((error) => {
            throw error
          })

      if((_.get(this.state.userData, 'role', '') === 'agent') || (_.get(this.state.userData, 'role', '') === 'local') || (_.get(this.state.userData, 'role', '')) === 'business development'){
        this.setState({ searchAgentData: fetchSearch.data })
      }

      if((_.get(_user, 'role', '') === 'superadmin') || (_.get(_user, 'role', '') === 'admin') || (_.get(_user, 'role', '') === 'strategist')){
        this.setState({ searchUserClientData: fetchSearch.data })
      }
    }else if(data === ''){
      this.props.clearAgentPhase()
    }
  }

  async authClick(event){
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = await axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }

    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)

    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });

    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(pre_Auth.role === "superadmin" || pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/administration/dashboard" })
      // this.props.history.push({'pathname' : "/strategist/clients" })
      if(window){
        window.location.reload(true)
      }
    }
    // if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
    //   this.props.history.push({'pathname' : "/home/new-clients" })
    // }
  }

  /* This is signout user functionality */
  userSignout=()=> {
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }
    localStorage.clear()
    document.cookie ="Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    localStorage.removeItem("client_id")
    localStorage.removeItem("client_strategist")
    localStorage.removeItem("client_status")
    localStorage.removeItem("client_suburb")
    this.props.handleSignOut()
    this.props.history.push("/login")
  }

  render() {
    return (
      <div>
      {
        (this.state.pre_Auth) &&
        <header className="impersonate">
          <div className="impersonate__wrapper">
            You are impersonating ( {(_.get(_user, 'first_name', ''))} {(_.get(_user, 'last_name', ''))} ) <a id="impersonate__link" onClick={this.authClick.bind(this)} className="impersonate__link">Click Here to go back to Jungler</a>
          </div>
        </header>
      }
       {
        ((_.get(_user, 'role', '') === 'agent') || (_.get(_user, 'role', '') === 'local') || (_.get(_user, 'role', '')) === 'business development') ?
        <Fragment>
          <AgentTopNavigation {...this.props} {...this.state} userData={this.state.userData} getSearchData={this.getSearchData} userSignout={this.userSignout}/>
          <TopNevigationMobile {...this.props} {...this.state} userData={this.state.userData} getSearchData={this.getSearchData} userSignout={this.userSignout}/>
        </Fragment>
        :
        ((_.get(_user, 'role', '') === 'superadmin') || (_.get(_user, 'role', '') === 'admin') || (_.get(_user, 'role', '') === 'strategist')) &&
        <Fragment>
          <AdministrationTopNavigation {...this.props} {...this.state} userData={this.state.userData} getSearchData={this.getSearchData} userSignout={this.userSignout}/>
          <AdministrationMobileTopNavigation {...this.props} {...this.state} userData={this.state.userData} getSearchData={this.getSearchData} userSignout={this.userSignout}/>
        </Fragment>
      }
      </div>
    )
  }
}
