import React, { PureComponent /*, PropTypes*/ } from 'react'
import { get } from 'lodash'

// import TopNavigation from '../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon } from '../../../../components/icons'
import config from '../../../../config'

import './styles.scss'
import AboutUserComponent from './partials/AboutUser'
import SpouseInformationComponet from './partials/SpouseInformation'
import OtherInformationComponent from './partials/OtherInformation'
import RecommendedTownDrawer from './partials/RecommendedTownDrawer'

export default class AiAnswersComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  componentDidMount() {
    const userId = this.props.match.params.id
    if (document) document.querySelector('body').scrollTo(0, 0);
    this.props.getClientQuestion({ userId })
  }
  showRecommendedTownsDrawer = () => this.setState({ visible: true })

  onClose = () => this.setState({ visible: false })

  handleViewEmailRoute = () => this.props.history.replace({
    pathname: `/strategist/client-details/${this.props.match.params.id}`,
    // this  route become prev for next page
    state: { prevRoute: get(this.props, 'history.location.state.prevRoute', '') }
  })


  render() {
    const { visible } = this.state
    const questions = config.questions
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="client__details__header">
              <div className="row d__flex align__items__center">
                <div className="col-xs-6 col-md-4">
                  <button
                    className="btn btn-back"
                    onClick={this.handleViewEmailRoute}
                  >
                    <BackArrowIcon className="arrow-icon" />
                    Back Client Detail
                  </button>
                </div>
                {/*<div className="col-xs-6 col-md-8 text-right">*/}
                {/*  <button*/}
                {/*    className="btn btn__btn btn-dark"*/}
                {/*    onClick={this.showRecommendedTownsDrawer}*/}
                {/*  >*/}
                {/*    AI Recommended Towns*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AboutUserComponent {...this.props} />
                {/*{questions && questions[3] &&questions[3].show && (*/}
                {/*  <SpouseInformationComponet {...this.props} />*/}
                {/*)}*/}
                {/*<OtherInformationComponent {...this.props} />*/}
              </div>
            </div>
          </div>
        </main>
        {visible && (
          <RecommendedTownDrawer onClose={this.onClose} {...this.props} />
        )}
      </div>
    )
  }
}
