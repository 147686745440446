import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import _ from "lodash";
import $ from 'jquery'
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import timezone from 'moment-timezone';
import GooglePlaceSearchInput from 'react-google-place-autocomplete-input';
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
const required = value => value ? undefined : 'Required'

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

let dealData = []
class AddDealForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      err: {},
      _clientID: '',
      agent_id: '',
      today: moment(),
      isloading: false,
      status: '',
      errMessage:'',
      agentErrMsg: {},
      agentError: '',
      amount:'',
      forecast_amount:'',
      expected_close_date:'',
      closing_gift_ordered: '',
      deal_status: '',
      buy_side_commission:'',
      check_received:'',
      closing_gift_sent:'',
      sj_comission:'',
      agent_notes:'',
      bonus_paid: false,
      check_received_status: false,
      bonus_paid_date: '',
      add_referral_bonus_paid: false,
      add_referral_bonus_paid_date: '',
      isSelect: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)    
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentWillMount(){
    let clientid = ''
    if(this.props.location && this.props.location.search){
      let id = this.props.location.search
      id = id.replace("?id=", "")
      clientid = id
    }else{
      clientid = this.props.location && this.props.location.state && this.props.location.state.client_id
    }
    this.setState({_clientID:clientid})
    this.props.getFilterParams()
    if(clientid && clientid !=''){
      const getAgentData ={}
      getAgentData.client_id = clientid;
      this.props.getClientAgents(getAgentData)
      if(this.props.location && this.props.location.search){
        this.props.getClientDeal(getAgentData)
      }
    }

    if(this.props.location && this.props.location.state && this.props.location.state.deal && this.props.location.state.deal.id !='') {
      const dealarray = this.props.location.state.deal;
      dealData = [this.props.location.state.deal]
      for (let obj of Object.entries(dealarray)) {
        let keyname = obj[0];
        let valname = obj[1];
        if(valname === null){
          valname = '';
        }

        if(valname.check_received){
          this.setState({ check_received_status: true})
        }
        this.setState({[keyname]: valname })
      }
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
 
  // onSuggestSelect(place) {
  //   this.setState({address:place.description})
  // }

  handleDateChange(name, date) {
    this.setState({[name]: date});
  }

  handleddressChange(address) {
    this.setState({ address:address,  isSelect: false }, ()=>{
    })
  }

  handleSelect(addr){
    let that = this
    geocodeByAddress(addr)
      .then(results =>
        that.setState({ address: results[0].formatted_address, isSelect: true })
      )
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataPhase === "error") {
      this.setState({ errMessage: nextProps.dealError, isloading: false})
    }
    if(nextProps.addclientDealdata && nextProps.addclientDealdata.status ===true){
      this.setState({ errMessage: nextProps.addclientDealdata.message, isloading: false})
      setTimeout(function(){
        this.setState({ errMessage: ''})
        this.props.clearPhase();
        this.props.history.push(`/activity/clients-deal/${this.state._clientID}`)
      }.bind(this), 800)
    }else if(nextProps.addclientDealdata && nextProps.addclientDealdata.status ===false){
      this.props.clearPhase();
      this.setState({ errMessage: nextProps.addclientDealdata.message, isLoading: false})
    }

    if(nextProps.clientDealdata && nextProps.clientDealdata.data && nextProps.clientDealdata.data.length > 0){
      dealData = _.sortBy(nextProps.clientDealdata && nextProps.clientDealdata.data, function(o) {
       return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
      }).reverse();
      if(dealData && dealData.length > 0){
        for (let obj of Object.entries(dealData[0])) {
          let keyname = obj[0];
          let valname = obj[1];
          if(valname === null){
            valname = '';
          }

          if(valname.check_received){
            this.setState({ check_received_status: true})
          }
          this.setState({[keyname]: valname })
        }
      }
    }
  }

  onCheckClick(name, e){
    if(name === 'check_received_status'){
      this.setState({ check_received_status: !this.state.check_received_status },()=>{})
    }
    if(name === 'bonus_paid'){
      this.setState({ bonus_paid: !this.state.bonus_paid },()=>{})
    }
    if(name === 'add_referral_bonas_paid'){
      this.setState({ add_referral_bonus_paid: !this.state.add_referral_bonus_paid },()=>{})
    }
  }

  handleSubmit(event) {
    this.setState({isloading: true})
    event.preventDefault()
    const err = {}
    if (this.state.agent_id === '') {
      err.agent_id = 'Agent is required.'
    }
    if (this.state.amount === '') {
      err.amount = 'Amount is required.'
    }
    if (this.state.expected_close_date === '') {
      err.expected_close_date = 'Closing date is required.'
    }
    if (this.state.address == '' || this.state.address == 'undefined' || this.state.address == undefined) {
      err.address = 'address is required.'
    }

    if(!this.state.isSelect){
      err.address = 'Please select address.'
    }
    // if(this.state.bonus_paid_date === '' || this.state.bonus_paid_date === '0000-00-00 00:00:00'){
    //   if(this.state.bonus_paid === 1 || this.state.bonus_paid === true){
    //     err.bonus_paid_date = 'Bonus paid date is required.'
    //   }
    // }

    if(this.state.check_received === '' || this.state.check_received === '0000-00-00 00:00:00'){
      if(this.state.check_received_status === 1 || this.state.check_received_status === true){
        err.check_received = 'Check Received date is required.'
      }
    }

    // if(this.state.add_referral_bonus_paid_date === '' || this.state.add_referral_bonus_paid_date === '0000-00-00 00:00:00'){
    //   if(this.state.add_referral_bonus_paid === 1 || this.state.add_referral_bonus_paid === true){
    //     err.add_referral_bonus_paid_date = 'Add referral bonus paid date is required.'
    //   }
    // }

    // if (this.state.closing_gift_ordered === '') {
    //   err.closing_gift_ordered = 'Closing gift ordered date is required.'
    // }
    // if (this.state.deal_status === '') {
    //   err.deal_status = 'Deal status is required.'
    // }
    // if (this.state.buy_side_commission === '') {
    //   err.buy_side_commission = 'Buy side commission is required.'
    // }
    // if (this.state.check_received === '') {
    //   err.check_received = 'Check received date is required.'
    // }
    // if (this.state.closing_gift_sent === '') {
    //   err.closing_gift_sent = 'Closing gift sent is required.'
    // }
    // if (this.state.sj_comission === '') {
    //   err.sj_comission = 'Misc Fees is required.'
    // }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { addClientDeal } = this.props
      const data = {}
      data.buy_side_commission = parseFloat(this.state.buy_side_commission)
      data.agent_id =  this.state.agent_id
      data.id =  this.state.id
      data.forecast_amount =  this.state.forecast_amount.toString().replace(/\D/g,'')
      data.closing_gift_ordered =  moment(this.state.closing_gift_ordered).format('YYYY-MM-DD hh:mm')
      data.closing_gift_sent =  moment(this.state.closing_gift_sent).format('YYYY-MM-DD hh:mm')
      data.check_received =  (this.state.check_received_status === true || this.state.check_received_status === 1) ? moment(this.state.check_received).format('YYYY-MM-DD hh:mm') : ''
      data.additional_details =  this.state.additional_details
      data.agent_notes = this.state.agent_notes
      data.expected_close_date =  moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
      //data.status =  this.state.deal_status
      data.amount =  Number(this.state.amount.toString().replace(/\D/g,''))
      data.address =  this.state.address
      data.check_received_status = this.state.check_received_status
      data.bonus_paid = this.state.bonus_paid
      data.bonus_paid_date = (this.state.bonus_paid === true || this.state.bonus_paid === 1) ? moment(this.state.bonus_paid_date).format('YYYY-MM-DD hh:mm') : ''
      data.add_referral_bonus_paid = this.state.add_referral_bonus_paid
      data.add_referral_bonus_paid_date = (this.state.add_referral_bonus_paid === true || this.state.add_referral_bonus_paid === 1) ? moment(this.state.add_referral_bonus_paid_date).format('YYYY-MM-DD hh:mm') : ''
      data.sj_comission =  Number(this.state.sj_comission.toString().replace(/\D/g,''))
      // data.facebook_review_url =  this.state.facebook_review_url
      // data.google_review_url =  this.state.google_review_url
      data.client_id =  this.state._clientID
      addClientDeal(data)
    }else{
      this.setState({isloading: false})
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      filterParams,
      clientAgentData,
      addclientDealdata,
      clientDealdata
    } = this.props

    $(document).ready(function(){
      $('.googleInputC').find('input').on('focusin', function(){
        $('.address-label').css({'top': '-20px', 'color': 'rgba(0, 0, 0, 0.54)', 'font-size':'13px'})
        $(this).css({'border-bottom': '2px solid #C7CE4A',
          'transition': '0.2s ease all'})
      });

      $('.googleInputC').find('input').on('blur', function(){
        if($(this).val() ==''){
          $('.address-label').css({'top': '0px', 'color': '$primaryColor', 'font-size':'16px'})
          $(this).css({'border-bottom': '1px solid #E6EBF0'})
        }else if($(this).val()){
          $('.address-label').css({'top': '-20px', 'color': 'rgba(0, 0, 0, 0.54)'})
          $(this).css({'border-bottom': '1px solid #E6EBF0'})
        }
      });
    })
    if(this.props.location && this.props.location.state && ! this.props.location.state.client_id) {
      return(
        <Redirect to={`/activity/clients-deal/${this.state._clientID}`}/>
      )
    }
    let allData = filterParams.data;
    // let statusOptions = allData && allData.deal_status.map((statussval, statusindx) => {
    //   return (
    //     <option key={statusindx} value={statussval.status}>{statussval.status}</option>
    //   )
    // });

    let agentOptions = clientAgentData && clientAgentData.status && clientAgentData.data.length > 0 && clientAgentData.data.map((agentval, agentindx) => {
      return (
        <option key={agentindx} value={agentval.agent_id}>{agentval.agent_full_name}</option>
      )
    });

    if(clientAgentData && clientAgentData.data && clientAgentData.data.length === 0){
      this.state.agentErrMsg.agentError = 'No agent assign this client'
    }else{
      this.state.agentErrMsg.agentError = ''
    }
 
    if(this.props.location && this.props.location.state && this.props.location.state.deal && this.props.location.state.deal.id !='') {
    }
    return (
      <div>
        {/* <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <Link to={`/clients-list`}><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; {this.props.location && this.props.location.state && this.props.location.state.deal && this.props.location.state.deal.id ? 'Edit' :'Add' } Deal</span></Link>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <Link to={`/activity/clients-deal/${this.state._clientID}`}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile__header--wrapper">
                <div className="profile__header">
                  <a>
                    <img src="/img/logo.svg" />
                    <h4>
                      <span>|</span>{dealData && dealData.length > 0 && dealData[0].id ? 'Edit' :'Add' } Deal
                    </h4>
                  </a>
                </div>
                <div className="profile__close">
                  <Link to={`/activity/clients-deal/${this.state._clientID}`}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="deal__information--container">
          <div className="container overflow__tabview">
            <div className="row">
              <div className="col-md-5">
                <div className="full__page--left">
                  <h1>Deal Information</h1>
                  <p>Deals are houses that we have closed. </p>
                </div>
              </div>
              <div className="col-md-6 col-md-offset-1">
                <div className="deal__information--form">
                  <div className="deal__information--head">
                    Deal Overview
                  </div>
                  <div className="form-group">
                    <label className="labelinputs__global">Agent(s) Assigned with</label>
                    <select className="selectinputs__global" name='agent_id' value={this.state.agent_id} onChange={this.handleChange.bind(this)}>
                      <option value = "">Select Agent</option>
                      {agentOptions}
                    </select>

                    {
                      (this.state.agentErrMsg.agentError !== '')?
                        <div>
                          {this.state.agentErrMsg.agentError ?
                          <span className="agent_err">
                          {this.state.agentErrMsg.agentError}
                          </span> : '' }
                        </div>
                      :
                      <div>
                      {
                        (this.state.err.agent_id !== '')?
                        <div>
                         { this.state.err.agent_id ?
                         <span className="error_field">
                         {this.state.err.agent_id}
                         </span> : '' }
                        </div>
                        :''
                      }
                      </div>
                    }

                  </div>
                  <label className="labelinputs__global">Amount</label>
                  <div className="form-group text-group amount__input">
                    <NumberFormat 
                    name="amount"
                    className="textinputs__global"
                    value={this.state.amount} 
                    onChange={this.handleChange.bind(this)}
                    thousandSeparator={true} 
                    prefix='$'
                     />
                    { this.state.err.amount ?
                    <span className="error_field">
                    {this.state.err.amount}
                    </span> : '' }
                  </div>
                  {/*<div className="form-group">
                    <label className="text__label--sm amount__label">Sales Stage</label>
                    <select className="form-control custom-select" name="deal_status" value={this.state.deal_status} onChange={this.handleChange.bind(this)}>
                      >
                      <option value="">Select Sales Stage</option>
                      <option value="Closed Won">Closed Won</option>
                      <option value="Accepted Offer">Accepted Offer</option>
                      <option value="Closed -  2017">Closed -  2017</option>
                      <option value="Closed Won">Closed Won</option>
                      <option value="Contract">Contract</option>
                      <option value="Contracts signed">Contracts signed</option>
                      <option value="In Contract">In Contract</option>
                      <option value="Pending">Pending</option>
                      <option value="Rented">Rented</option>
                      <option value="Signed Contract">Signed Contract</option>
                      <option value="Closed-2018">Closed-2018</option>
                    </select>
                    { this.state.err.deal_status ?
                    <span className="error_field">
                    {this.state.err.deal_status}
                    </span> : '' }
                  </div>*/}

                  <div className="form-group">
                  <label className="labelinputs__global">Expected/Actual Closing Date</label>
                    <DatePicker 
                      customInput={<DatePickerCustomInput />}
                      onChange={this.handleDateChange.bind(this, 'expected_close_date')}
                      showTimeSelect
                      className="form-control custom-select date__picker"
                      value={this.state.expected_close_date ? moment(this.state.expected_close_date).format('MM/DD/YY') :'Select Date'}
                      placeholderText="Pick a date">
                    </DatePicker>
                    { this.state.err.expected_close_date ?
                    <span className="error_field">
                    {this.state.err.expected_close_date}
                    </span> : '' }
                  </div>

                  <div className="form-group text-group">
                    <label className="labelinputs__global">Address</label>
                    <div className='googleInputC'>
                    {/*<GooglePlaceSearchInput
                      className="form-control text-input googleinput"
                      placeholder='Address' 
                      value={this.state.address}
                      onChange={this.onSuggestSelect.bind(this)}
                      onPlaceSelected={this.onSuggestSelect.bind(this)}
                      onRemove={this.handleChange}
                      type="text" 
                      name="email"
                    />*/}
                    <PlacesAutocomplete
                        value={this.state.address}
                        className="form-control text-input googleinput" 
                        onChange={this.handleddressChange.bind(this)}
                        onSelect={this.handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: 'Enter Address',
                                className: 'textinputs__global addressAutoSuggest',
                              })}
                            />
                            <div className={"autocomplete-dropdown-container"}>
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
         
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <span className="address-bar"></span>
                    { this.state.err.address ?
                    <span className="error_field">
                    {this.state.err.address}
                    </span> : '' }
                  </div>
                    {/*<div className="form-group text-group">
                      <input 
                        type="text" 
                        name="email"
                        className="form-control text-input" 
                        required 
                      />
                      <span className="bar"></span>
                      <label className="text-label">Email</label>
                    </div>*/}
                    <div className="clearfix"></div>

                  <div className="deal__information--head btm_marg amount__label">
                    Commission Details
                  </div>
                  {/* <div className="form-group text-group">
                    <NumberFormat 
                    name="forecast_amount"
                    className="form-control text-input"
                    value={this.state.forecast_amount} 
                    onChange={this.handleChange.bind(this)}
                    thousandSeparator={true} 
                    prefix='$'
                     />
                    <span className="bar_new"></span>
                    <label className="text-label">Forecast Amount</label>
                    { this.state.err.forecast_amount ?
                    <span className="error_field">
                    {this.state.err.forecast_amount}
                    </span> : '' }
                  </div> */}
                  <div className="clearfix"></div>
                  <label className="labelinputs__global">Buy Side Commission</label>
                  <div className="form-group text-group">
                    <NumberFormat 
                    name="buy_side_commission"
                    className="textinputs__global"
                    value={this.state.buy_side_commission} 
                    onChange={this.handleChange.bind(this)}
                    thousandSeparator={true} 
                    suffix='%'
                     />
                    { this.state.err.buy_side_commission ?
                    <span className="error_field">
                    {this.state.err.buy_side_commission}
                    </span> : '' }
                  </div>
                  <div className="clearfix"></div>
                  <label className="labelinputs__global">Misc. Fees</label>
                  <div className="form-group text-group">
                    <NumberFormat 
                    name="sj_comission"
                    className="textinputs__global"
                    value={this.state.sj_comission} 
                    onChange={this.handleChange.bind(this)}
                    thousandSeparator={true} 
                    prefix='$'
                     />
                    { this.state.err.sj_comission ?
                    <span className="error_field">
                    {this.state.err.sj_comission}
                    </span> : '' }
                  </div>
                  <div className="clearfix"></div>

                  <div className="deal__information--head amount__label">
                    Closing Details
                  </div>
                  {/* <div className="form-group">
                    <label className="text__label--sm">Closing Gift Orders</label>
                    <DatePicker
                          name={'closing_gift_orders'}
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleDateChange.bind(this, 'closing_gift_ordered')}
                          className="schedule-date"
                          value={this.state.closing_gift_ordered ? moment(this.state.closing_gift_ordered).format('MM/DD/YY') :'Select Date'}
                          placeholderText="Select available dates to pic a time" />
                          { this.state.err.closing_gift_ordered ?
                    <span className="error_field">
                    {this.state.err.closing_gift_ordered}
                    </span> : '' }
                  </div> */}

                  {/* <div className="form-group">
                    <label className="text__label--sm">Closing Gift Sent</label>
                    <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleDateChange.bind(this, 'closing_gift_sent')}
                          showTimeSelect
                          value={this.state.closing_gift_sent ? moment(this.state.closing_gift_sent).format('MM/DD/YY') :'Select Date'}
                          className="form-control custom-select date__picker"
                          placeholderText="Pick a date">
                    </DatePicker>
                    { this.state.err.closing_gift_sent ?
                    <span className="error_field">
                    {this.state.err.closing_gift_sent}
                    </span> : '' }
                  </div> */}

                  <div className="form-group">
                    <div className="d-flex-single ">
                        <span className="custom-checkbox" onClick={this.onCheckClick.bind(this, 'check_received_status')}>
                          <input type="checkbox" checked={this.state.check_received_status} className="custom-select-option-checkbox"/>
                          <span className="checkmark"></span>
                        </span>
                        <div className="bns_text">Check Received</div>
                    </div>
                    {
                      (this.state.check_received_status === true || this.state.check_received_status === 1) &&
                        <span>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleDateChange.bind(this, 'check_received')}
                          showTimeSelect
                          value={ ( (this.state.check_received != '0000-00-00 00:00:00') &&  (this.state.check_received)) ? moment(this.state.check_received).format('MM/DD/YY') :'Select Date'}
                          className="form-control custom-select date__picker"
                          placeholderText="Pick a date">
                        </DatePicker>
                        { this.state.err.check_received ?
                        <span className="error_field">
                        {this.state.err.check_received}
                        </span> : '' }
                        </span>
                    }
                    
                  </div>

                  {/* <div className="form-group">
                  <div className="d-flex-single ">
                      <span className="custom-checkbox" onClick={this.onCheckClick.bind(this, 'bonus_paid')}>
                        <input type="checkbox" checked={this.state.bonus_paid} className="custom-select-option-checkbox"/>
                        <span className="checkmark"></span>
                      </span>
                      <div className="bns_text">Bonus Paid</div>
                  </div>
                  {
                    (this.state.bonus_paid === true || this.state.bonus_paid === 1) &&
                      <span>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleDateChange.bind(this, 'bonus_paid_date')}
                          showTimeSelect
                          value={ ( (this.state.bonus_paid_date != '0000-00-00 00:00:00') &&  (this.state.bonus_paid_date)) ? moment(this.state.bonus_paid_date).format('MM/DD/YY') : 'Select Date'}
                          className="form-control custom-select date__picker"
                          placeholderText="Pick a date">
                        </DatePicker>
                        { this.state.err.bonus_paid_date ?
                        <span className="error_field">
                        {this.state.err.bonus_paid_date}
                    </span> : '' }
                    </span>
                  }
                  </div> */}
                  
                  {/* <div className="form-group">
                  <div className="d-flex-single ">
                      <span className="custom-checkbox" onClick={this.onCheckClick.bind(this, 'add_referral_bonas_paid')}>
                        <input type="checkbox" checked={this.state.add_referral_bonus_paid} className="custom-select-option-checkbox"/>
                        <span className="checkmark"></span>
                      </span>
                      <div className="bns_text">Add Referral Bonus Paid</div>
                  </div>
                  {
                    (this.state.add_referral_bonus_paid === true || this.state.add_referral_bonus_paid === 1 ) &&
                    <span>
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleDateChange.bind(this, 'add_referral_bonus_paid_date')}
                        showTimeSelect
                        value={ ( (this.state.add_referral_bonus_paid_date != '0000-00-00 00:00:00') &&  (this.state.add_referral_bonus_paid_date)) ? moment(this.state.add_referral_bonus_paid_date).format('MM/DD/YY') : 'Select Date'}
                        className="form-control custom-select date__picker"
                        placeholderText="Pick a date">
                      </DatePicker>
                      { this.state.err.add_referral_bonus_paid_date ?
                      <span className="error_field">
                      {this.state.err.add_referral_bonus_paid_date}
                      </span> : '' }
                    </span>
                  }
                  </div> */}



                  <div className="form-group">
                    <label className="labelinputs__global">SJ Notes</label>
                    <textarea 
                      name="additional_details" 
                      className="textareainputs__global" 
                      rows="6" 
                      placeholder="Enter SJ Notes" 
                      value={this.state.additional_details} 
                      onChange={this.handleChange.bind(this)}>
                    </textarea>
                    { this.state.err.additional_details ?
                    <span className="error_field">
                    {this.state.err.additional_details}
                    </span> : '' }
                  </div>
                  {/*<div className="deal__information--head btm_marg">
                  Reviews
                  </div>
                  <div className="clearfix"></div>*/}
                  {/*<div className="form-group text-group">
                    <input 
                      name="facebook_review_url" 
                      type="text" 
                      className="form-control text-input" 
                      name="" 
                      value={this.state.facebook_review_url} 
                      onChange={this.handleChange.bind(this)} />
                    <span className="bar_new"></span>
                    <label className="text-label">Facebook URL</label>
                    { this.state.err.facebook_review_url ?
                    <span className="error_field">
                    {this.state.err.facebook_review_url}
                    </span> : '' }
                  </div>
                  <div className="clearfix"></div>
                  <div className="form-group text-group">
                    <input
                       name="google_review_url" 
                       type="text" 
                       className="form-control text-input" 
                       value={this.state.google_review_url} 
                       onChange={this.handleChange.bind(this)} />
                    <span className="bar_new"></span>
                    <label className="text-label">Google URL</label>
                    { this.state.err.google_review_url ?
                    <span className="error_field">
                    {this.state.err.google_review_url}
                    </span> : '' }
                  </div>
                  <div className="clearfix"></div>*/}
                  {this.state.errMessage ? <div className='text-center alert alert-success'>{this.state.errMessage}</div> : ''}
                  <div className="deal__information--form__btn">
                    <Link to={`/activity/clients-deal/${this.state._clientID}`} className="btn btn__black--lg btn__black--outline padd__t--b">Cancel</Link>
                    <button 
                      type="submit" 
                      disabled={this.state.isloading} 
                      onClick={this.handleSubmit.bind(this)} 
                      className="btn btn__green btn__green--lg">{this.state.isloading ? 'Saving...' : 'Save' }</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>




      </div>
    )
  }
}

export default reduxForm({
  form: 'add-deal',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AddDealForm)
