import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import $ from 'jquery'
import Modal from 'react-modal'
import Header from '../Header'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import { confirmAlert } from 'react-confirm-alert'
import GoogleDrivePDF from './../googleDrivePDF'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()

let _user = ''

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '500px',
    padding: '25px 25px',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class Taxes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDateLoading: false,
      modalIsOpen: false,
      modelType: '',
      selectType: '',
      url: '',
      note: '',
      fileName: '',
      file: '',
      type: 'taxes',
      err: {},
      fileType: '',
      current_user_id: 0,
      id: 0,
      drive: false,
      pdfUrl: '',
      token: ''
    }
    this.sendData = this.sendData.bind(this)
  }

  componentWillMount(){
    const {match: { params: { townId }} } = this.props
    _user = cookies.get('user')
    if(_user) {
      this.setState({current_user_id: _user.id})
    }
  }

  componentWillReceiveProps(np){
    const {match: { params: { townId }}, getResearch } = this.props
    if(np.getResearchPhase !== this.props.getResearchPhase){
      this.setState({ modalIsOpen: false, isDateLoading: false, drive: false, url: '' })
    }
    if(np.addResearchPhase === "error"){
      this.setState({ modalIsOpen: false, isDateLoading: false })
    }
    if(np.deleteResearchPhase === "error"){
      this.setState({ isDateLoading: false })
    }
  }

  onChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  selectInsightType(e){
    this.setState({ selectType: e.target.value, err: {} })
  }

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL
    let files = $("#profileImage")[0].files[0]
    let fileName = files.name
    let fileType = files.type
    var img = new Image()
    let self=this
    let reader = new FileReader()
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files)
      img.onload = function() {
          self.setState({file: files})
          self.setState({avatar: reader.result})
      }
    }
    this.setState({ fileName: fileName, file: files, fileType: fileType})
    var read = reader.readAsDataURL(files) 
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str)
  }

  editInsightButton(data, e){
    this.setState({ 
      modalIsOpen: true,
      selectType: data.insight_type,
      file: data.file, 
      url: data.link, 
      note: data.note,
      id: data.id,
      fileName: data.file_name
    })
  }

  afterOpenModal(){ }

  closeModal=()=>{ 
    this.setState({ modalIsOpen: false })
  }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false })
  }

  editButton(e){
    const err = {}
    const {match: { params: { townId }}, addResearch, addResearchFile } = this.props
    const { file, url, fileType, note, selectType, type, current_user_id, id, fileName, drive, token, pdfUrl } = this.state 
    if(note === '' || note.trim() === ''){
      err.note = "Please enter note"
    }
    if(selectType === ''){
      err.type = "Select type"
    }
    if(selectType === "link"){
      if(!this.validURL(url)){
        err.url = "Enter valid url"
      }
    }
    if(selectType === "file" && !file){
      if(file == ''){
        err.file = "Please select file"
      }else if( (fileType !== 'application/pdf') ){
        err.file = "Select correct file"
      }
    }

    this.setState({ err })
     if(!Object.keys(err).length) {
       let data = {
        id: id,
        town_id: parseInt(townId),
        note: note,
        type: type,
        file: file,
        insight_type: selectType,
        created_by: current_user_id,
        fileName: ''
       }
        if(selectType === "link"){
          data.link = url
          data.file = ''
          addResearch(data)
        }else if(selectType === "file"){
          this.setState({ modalIsOpen: false, isDateLoading: true })
          const formdata = new FormData()
          formdata.append("id", id)
          formdata.append("town_id", parseInt(townId))
          formdata.append("note", note)
          formdata.append("type", type)
          formdata.append("insight_type", selectType)
          formdata.append("created_by", current_user_id)
          formdata.append('file',file)
          formdata.append('link', '')
          formdata.append('fileName', fileName)
          formdata.append('drive', drive)
          formdata.append('pdfUrl', pdfUrl)
          formdata.append('authToken', token)
          addResearchFile(formdata)
        }else if( selectType === "note"){
          data.link = ''
          data.file = ''
          data.fileName = ''
          addResearch(data)
        }
        this.setState({ isDateLoading: true })
     }
  }

  sendData(data, token) {
    this.setState({ 
      fileName: data[0].name, 
      file: data[0], 
      fileType: data[0].mimeType, 
      drive: true, 
      pdfUrl: data[0].url,
      token: token
    })
  }

  deleteResearch(data, e){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
            <h4 className="popupheader__heading">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          <p className="allpoppadding__para poppinsfontsedit">You want to delete this?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.deleteData(data)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  async deleteData(data){
    const { deleteResearch, match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    let data1 = {
      id: data.id,
      town_id: parseInt(townId)
    }
    deleteResearch(data1)
  }

  render() {
    const { getResearchData } = this.props
    const { selectType } = this.state
    return (
      <div className="town-report posi__rel">
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }
      <Header title={`Edit ${this.state.town_name} Public Schools`}/>
      <div className="row">
          <div className="latest__town--report marg__minus--top">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                      {
                        (getResearchData && getResearchData.research && getResearchData.research.length > 0) ?
                        <table className="table table-striped school__table--tabs school__table">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Notes</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getResearchData && getResearchData.research && getResearchData.research.map((rs, idx)=>{
                                if(rs.type == "taxes"){
                                  if(rs.link && rs.link !== ''){
                                    if (!rs.link.match(/^[a-zA-Z]+:\/\//)){
                                      rs.link = 'https://' + rs.link;
                                    }
                                  }
                                  return(
                                    <tr key={idx}>
                                      {
                                        (rs.file) ?
                                        <td className="green__types green__anchor"><a href={rs.file} download={rs.file_name}>{(rs.file_name)}</a></td>
                                        : 
                                        (rs.link) ? 
                                          <td className="green__anchor"><a href={rs.link} target='_blank'>{(rs.link)}</a></td>
                                        : <td></td>
                                      }
                                      
                                      <td><div className="for__text__break">{rs.note}</div></td>
                                      <td style={{ textAlign: 'right' }}>
                                        <button onClick={this.editInsightButton.bind(this, rs)} className="edit__btn"><img src="../../img/edit-icon.svg" /></button>
                                        <img onClick={this.deleteResearch.bind(this, rs)} style={{width: '26px', height: '26px', cursor: 'pointer'}} src="./img/delete_icon.svg"/>
                                      </td>
                                    </tr>
                                  )
                                }
                              })
                            }
                          </tbody>
                        </table>
                        :
                        (<p className="no__data--show">No Taxes Data</p>)
                      }
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
        
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom">
          <div className="form-group">
            <h1 className="headModal">{ "Edit Insight" }</h1>
            <button onClick={this.closeCrossBtn.bind(this)} type="button" className="btn close-btn closeModal" data-dismiss="modal">
            <img data-dismiss="modal" src="./img/login_close.svg"/></button>
          </div>

          <div className="form-group">
          <label className="text__label--sm text__label--smnew">TYPE</label>
            <select className="select__common" value={this.state.selectType} onChange={this.selectInsightType.bind(this)}>
              <option value=''>Select Type</option>
              <option value="link">Add Link</option>
              <option value="file">Upload File</option>
              <option value="note">Add Note</option>
            </select>
          </div>
          { this.state.err.type ?
            <span className="error_msg town__err">
            {this.state.err.type }
            </span> : '' }

          {
            (selectType === "link") &&
            <div>
              <div className="form-group">
              <label className="text__label--sm text__label--smnew">ENTER URL</label>
                <input 
                  type="url"
                  name="url"
                  className="form-control additional__textarea" 
                  rows="2" 
                  value={this.state.url}
                  onChange={this.onChange.bind(this)}/>
              </div>
              { this.state.err.url ?
              <span className="error_msg town__err">
              {this.state.err.url }
              </span> : '' }
            </div>
          }

          {
            (selectType === "file") && 
            <div className="insight__upload--wrapper">
              <span className="form-group">
                <label className="upload__cursor">Upload
                  <input type="file" style={{ display: "none" }}  accept="application/pdf" onChange={this.handleFileUpload.bind(this)} id="profileImage" name="profileImage"/>
                </label>
              </span>
              <span className="or">Or</span>
              <span>
                <GoogleDrivePDF key="googleDrivePDF" sendData={this.sendData} />
              </span>
              <div>{ this.state.fileName}</div>

              { this.state.err.file ?
              <span className="error_msg town__err">
              {this.state.err.file }
              </span> : '' }
            </div>
          }

          <div className="form-group">
          <label className="text__label--sm text__label--smnew">NOTE</label>
            <textarea 
              name="note"
              className="form-control additional__textarea add__space" 
              rows="2" 
              value={this.state.note}
              onChange={this.onChange.bind(this)}/>
          </div>
          { this.state.err.note ?
            <span className="error_msg town__err">
            {this.state.err.note }
            </span> : '' }

          <div className="modalbtns modalbtns__new">
            <button
              className="submitBtnModal"
              onClick={this.editButton.bind(this)}
              type="button">
              Edit 
            </button>
          </div>
          </div>
        </Modal>

      </div>
    )
  }
}

export default reduxForm({
  form: 'Taxes', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: [],
})(Taxes)