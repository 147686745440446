import * as types from './action-types'
/*
    ////////////////////////////////////
  /// Interal notes action ///
////////////////////////////////////
*/
// fetch interalNotes list
export const getInteralNotes = (payload) => ({
  type: types.FETCH_INTERAL_NOTES,
  payload,
})

// create interal notes
export const createInteralNote = (payload) => ({
  type: types.CREATE_NEW_NOTE,
  payload,
})

// create interal notes
export const editInteralNote = (payload) => ({
  type: types.EDIT_INTERNAL_NOTE,
  payload,
})

// delete interal notes
export const deleteInteralNote = (payload) => ({
  type: types.DELETE_INTERAL_NOTE,
  payload,
})

// clear interal notes
export const clearInteralNotePhase = () => ({
  type: types.CLEAR_NOTES_PHASE,
})
