/* eslint-disable camelcase */
import React, { PureComponent /*, PropTypes*/ } from 'react'
import { get, map, filter, find, size } from 'lodash'
import moment from 'moment'
import { Spin, Pagination } from 'antd'
// import Pagination from "react-js-pagination";

import './styles.scss'
import CustomeDatePicker from '../../Strategist/Common/CustomeDatePicker';
import AppointmentList from './partials/AppointmentList';
import AppointmentFilter from './partials/AppointmentFilter';
import AppointmentTableHeader from './partials/AppointmentTableHeader';
import TopNavigation from "../../../../components/TopNavigation/container"
import Loader from '../../../../components/Loader'
import { AgentAppointmentsColorIcon, CloseIcon } from "../../../../components/icons"
import AdministrationSidebarNavigation from "../../../../components/AdministrationSidebarNavigation/container"
import { SUCCESS, ERROR } from '../../../../constants/phase';

export default class AgentAppointmentsComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      initalFilter: true,
      visible: false,
      clientStatus: 'all_appointments',
      // clientType: 'upcoming_appointments',
      pageLimit: 20,
      activePage: 1,
      fromDate: moment(moment().startOf('month').format('LL')).format("YYYY-MM-DDT00:00:00"),
      toDate: moment(moment().endOf('month').format('LL')).format("YYYY-MM-DDT23:59:59"),
      totalPages: 0,
      selectedAppointmentType: [],
      sortByType: 0,
      sortByKey: "",
      maxWidth: "400px",
      defaultSelectedType: "month",
      noCall: false,
      noTour: false
    }
    this.filterAppointmentChildRef = React.createRef();
  }
  componentDidMount() {
    const appointmentFilter = JSON.parse(localStorage.getItem('agentAppointmentFilter'))
    this.props.getFilterParams();
    if (appointmentFilter) {
      this.setState({
        clientType: appointmentFilter.clientType,
        clientStatus: appointmentFilter.clientStatus,
        pageLimit: appointmentFilter.pageLimit,
        activePage: appointmentFilter.activePage,
        fromDate: appointmentFilter.from,
        toDate: appointmentFilter.to,
        createdDate: appointmentFilter.createdDate,
        selectedAppointmentType: appointmentFilter.appointmentType,
        selectedSubrbs: appointmentFilter.suburbs,
        sortByKey: appointmentFilter.sortBy.key,
        sortByType: appointmentFilter.sortBy.type,
        filterCount: appointmentFilter.filterCount || 0,
        defaultSelectedType: appointmentFilter.defaultSelectedType
      }, () => this.fetchAppointment())
      this.filterAppointmentChildRef.current.resetHeadFilterParams({
          selectedAppointmentType: appointmentFilter.appointmentType,
          selectedSubrbs: appointmentFilter.suburbs,
          createdDate: appointmentFilter.createdDate
      })
    } else {
      this.fetchAppointment();
    }
  }
  static getDerivedStateFromProps(props, state) {
    let states = {...state}
    if (props.fetchAppointmentPhase === SUCCESS || props.fetchAppointmentPhase === ERROR) {
      props.clearStrategistAppointment()
      states = { ...states, isLoading: false }
    }
    if (get(props, 'location.state.filter', false) === 'today' && state.initalFilter) {
      return {
        ...states,
        defaultSelectedType: 'today',
        fromDate: moment().format('YYYY-MM-DDT00:00:00'),
        toDate: moment().format('YYYY-MM-DDT23:59:59'),
      }
    }
    return states
  }
  handlePageChange = (activePage) => this.setState({ activePage, initalFilter: false }, () => this.fetchAppointment());

  handleResultEntity = ({ clientLimit }) => this.setState({ pageLimit: clientLimit, activePage: 1, initalFilter: false }, () => this.fetchAppointment());

  fetchAppointment = () => {
    const clientdata = {
      clientType: this.state.clientType,
      clientStatus: this.state.clientStatus,
      pageLimit: this.state.pageLimit,
      activePage: this.state.activePage,
      from: this.state.fromDate,
      to: this.state.toDate,
      createdDate: this.state.createdDate,
      appointmentType: this.state.selectedAppointmentType,
      suburbs: this.state.selectedSubrbs,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType },
      noCall: this.state.noCall,
      noTour: this.state.noTour
    }
    localStorage.setItem('agentAppointmentFilter', JSON.stringify({...clientdata, filterCount: this.state.filterCount, defaultSelectedType: this.state.defaultSelectedType }))
    this.setState({ createdDate: "", isLoading: true })
    this.props.fetchAppointment(clientdata)
  }

  setFilterParams = (filter) => {
    this.setState({
      selectedAppointmentType: filter.selectedAppointmentType,
      selectedSubrbs: filter.selectedSubrbs,
      activePage: 1,
      filterCount: filter.filterCount,
      initalFilter: false,
      createdDate: filter.createdDate ? moment(filter.createdDate).format("YYYY-MM-DD") : "",
      noCall: filter.noCall,
      noTour: filter.noTour
    }, () => this.fetchAppointment())
    this.forceUpdate()
  }
  filterAppointment = (id, type, e) => {
    let { selectedAppointmentType, selectedSubrbs, createdDate } = this.state
    if (type === "filterAppointmentTypeHead") {
      selectedAppointmentType = filter(selectedAppointmentType, status => status !== id)
    }
    if (type === "filterSuburbHead") {
      selectedSubrbs = filter(selectedSubrbs, suburb => suburb !== id)
    }
    if (type === "filterCreatedeHead") {
      createdDate = ""
    }
    this.filterAppointmentChildRef.current.resetHeadFilterParams({ selectedAppointmentType, selectedSubrbs, createdDate })
    this.setState({ selectedAppointmentType, selectedSubrbs, initalFilter: false }, () => this.fetchAppointment())
  }
  // sort data with filter value
  sortDate = (sortByKey) => {
    let sortByType = this.state.sortByType
    // eslint-disable-next-line no-nested-ternary
    sortByType = sortByKey !== this.state.sortByKey ? 0 : (sortByType === 0 ? 1 : 0)
    this.setState({ sortByType, sortByKey, activePage: 1, initalFilter: false }, () => this.fetchAppointment())
  }

  // filter appointment by date
  filterByDate = ({ from, to, val }) => {
    this.setState({
      fromDate: `${from}T00:00:00`,
      toDate: `${to}T23:59:59`,
      activePage: 1,
      initalFilter: false,
      defaultSelectedType: val
    }, () => this.fetchAppointment())
  }
  formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    const { filterParams } = this.props
    const { sortByType, sortByKey, isLoading, pageLimit, activePage, defaultSelectedType  } = this.state
    const suburbs = filterParams && filterParams.data && filterParams.data.suburbs;
    const appointments = get(this.props, "appointments", [])
    const appointmentCount = get(this.props, "appointmentCount.all_appointments", 0)
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h2 className="heading-title">
                  <span className="heading-icon"><AgentAppointmentsColorIcon className="" /></span>
                    Agent Appointments{appointmentCount > 0 && `(${this.formatNumber(appointmentCount)})`}
                </h2>
              </div>
              <AppointmentFilter
                ref={this.filterAppointmentChildRef}
                handleResultEntity={this.handleResultEntity}
                setFilterParams={this.setFilterParams}
                filterParams={this.props.filterParams}
                filterCount={this.state.filterCount}
                pageLimit={this.state.pageLimit}
              />
            </div>
            <div className="">
              <div className="row">
                <div className="col-md-5">
                  <div className="mt__20">
                    <CustomeDatePicker {...this.state} filterByDate={this.filterByDate} value={defaultSelectedType} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ul className="filter__list__flitered">
                    {map(this.state.selectedAppointmentType, (appointment, appointmentIndex) => (
                      <li key={appointmentIndex}>{appointment} <span onClick={this.filterAppointment.bind(this, appointment, 'filterAppointmentTypeHead')}>
                        <CloseIcon className="close-icon" />
                      </span></li>
                    ))}
                    {map(this.state.selectedSubrbs, (selectedSubrb, suburbIndex) => {
                      const suburb = find(suburbs, { id: selectedSubrb })
                      if (size(suburb)) {
                        return <li key={suburbIndex}>{suburb.name} <span onClick={this.filterAppointment.bind(this, selectedSubrb, 'filterSuburbHead')}>
                          <CloseIcon className="close-icon" />
                        </span></li>
                      }
                    })}
                    {this.state.createdDate &&
                      <li>{moment(this.state.createdDate).format('MM/DD/YYYY')}
                       <span onClick={this.filterAppointment.bind(this, this.state.createdDate, 'filterCreatedeHead')}>
                        <CloseIcon className="close-icon" />
                      </span>
                      </li>
                    }
                  </ul>
                  <Spin
                    size="large"
                    spinning={isLoading}
                    indicator={<Loader />}
                  >
                    {appointments && appointments.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <AppointmentTableHeader appointments={appointments} sortByType={sortByType} sortByKey={sortByKey} sortData={this.sortDate} />
                          <AppointmentList appointments={appointments} {...this.props} />
                        </table>
                      </div>
                      ) : (
                        <div className="blank__table__state">
                          No appointment found.
                        </div>
                      )}
                  </Spin>
                  {appointments && appointments.length > 0 && (appointmentCount / pageLimit) > 1 && <Pagination
                      current={activePage}
                      defaultCurrent={1}
                      className="mt__20 mb__20"
                      total={appointmentCount}
                      showQuickJumper={true}
                      showSizeChanger={false}
                      pageSize={pageLimit}
                      onChange={this.handlePageChange}
                    />}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}
