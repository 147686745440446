
import React, { PureComponent, Fragment } from 'react'
import { Link, Route, Redirect, Switch } from "react-router-dom"
import { DatePicker } from 'antd'
import TopNavigation from "../../../../components/TopNavigation/container"
import SidebarNavigation from "../../../../components/SidebarNavigation/component"
import { ColoredTasklistIcon, DownCaretIcon, CaretRightIcon, CloseIcon } from "../../../../components/icons"
import './styles.scss'
import NeverTouched from './partials/NeverTouched/container'
import NotTouchedInFourWeek from './partials/NotTouchedInFourWeek/container'
import InterestedNotTownTour from './partials/InterestedNotTownTour/container'
import _ from "lodash"
import axios from 'axios'
import ReactModal from 'react-modal'
import { } from '../../../../components/icons'
import moment from 'moment'
import UserContext from "../../../App/Context"
import $ from 'jquery'
import NumberFormat from 'react-number-format'
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from '../../../../utils/helper'
const cookies = new Cookies()

const routeTab = {
  "": 0,
  "/agent/task-list/new-never-touched": 0,
  "/agent/task-list/not-touched-in-4-weeks": 1,
  "/agent/task-list/interested-but-don’t-want-town-tour-yet": 2
}

export default class TaskListComponent extends PureComponent {
  static contextType = UserContext;
  constructor() {
    super()
    this.state = {
      loginUserId: 0,
      activeTab: 0,
      modalIsOpen: false,
      deleteDealModal: false,
      dealData: [],
      clientData: [],
      dealType: "sales",
      address: '',
      dealStatus: '',
      amount: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      isSelect: false,
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      lease_start_date: '',
      lease_term: '',
      buttonType: 'add',
      client_id: '',
      selectResultEntry: 100,
      sectionCounts: {
        notTouched: 0,
        notTouched4Week: 0,
        notReadyToTour: 0
      }
    }
  }

  componentDidMount() {
    this.pathSetting()
    this.getSectionCounts()
  }

  getSectionCounts = async () => {
    const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
    const jwtToken = cookies.get('Authorization');
    if (jwtToken) {
      const res = await axios({
        method: 'POST',
        url: `${HOSTNAME_V2}/agent/record-counts/task-list`,
        'headers': { 'Authorization': jwtToken },
        data: {}
      }).catch(logoutInvalidRequest)
      this.setState({ sectionCounts: _.get(res, 'data.data', {}) })
    }
  }

  getTaskListClientData(){
    if(this.state.activeTab === 0 ){
      this.props.getNotTouchedData({ sort: '', sort_value: 0 })
    }else if(this.state.activeTab === 1){
      this.props.getNotTouchedIn4Week({ sort: '', sort_value: 0 })
    }
  }

  componentDidUpdate() {
    this.pathSetting()
    if(this.props.clientDealPhase === "success"){
      this.updateClientStatus()
      this.props.clearAgentDatePhase()
    }
    if(this.props.clientDealPhase === "error"){
      this.props.clearAgentDatePhase()
      this.closeModal()
    }
    if(this.props.updateUserPhase === "success"){
      this.props.clearPhase()
      this.getTaskListClientData()
      this.getSectionCounts()
    }
    if(this.props.deleteClientDealPhase === "success"){
      this.props.clearAgentDatePhase()
      this.deleteUpdateClientStatus()
      this.setState({ deleteDealModal: false })
      this.getSectionCounts()
    }
  }

  updateClientStatus(){
    let data = {}
    data.client_id    = this.state.client_id
    data.activity_status = this.state.dealStatus
    data.isAcceptOffer = true
    data.isSendEmail = false
    data.address =  this.state.address
    data.amount =  parseInt(this.state.amount.toString().replace(/\D/g,''))
    data.buy_side_commission = parseFloat(this.state.buy_side_commission)
    data.accepted_offer_date =  moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')
    data.expected_close_date =  moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
    data.lease_start_date =  moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
    data.lease_term = this.state.lease_term  
    data.deal_type = this.state.dealType === "sales" ? 0 : 1
    data.agent_notes = this.state.agent_notes
    data.sj_comission = this.state.misc_fee ? parseInt(this.state.misc_fee.toString().replace(/\D/g,'')) : 0
    if(this.state.signed_contract_date){
      data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
    }
    this.props.updateClientUser(data)
    this.closeModal()
  }

  deleteUpdateClientStatus(){
    let data = {}
    data.client_id    =  this.state.client_id
    data.isAcceptOffer = false
    data.isSendEmail = false
    data.activity_status = "Null"
    this.props.updateClientUser(data)
  }

  pathSetting() {
    // let currentUrl = this.props.location.pathname.split(`${this.props.match.url}`)[1]
    const currentUrl = this.props.location.pathname
    this.setState({ activeTab: routeTab[currentUrl] })
  }

  changeProps(val) {
    this.setState({ activeTab: val })
    if (val === 0) {
      this.props.history.push(`${this.props.match.url}/new-never-touched`)
    } else if (val === 1) {
      this.props.history.push(`${this.props.match.url}/not-touched-in-4-weeks`)
    } else if (val === 2) {
      this.props.history.push(`${this.props.match.url}/interested-but-don’t-want-town-tour-yet`)
    }
  }

  //changeProps for mobile function
  changePropsForMobile(e) {
    let val = parseInt(e.target.value)
    this.setState({ activeTab: val })
    if (val === 0) {
      this.props.history.push(`${this.props.match.url}/new-never-touched`)
    } else if (val === 1) {
      this.props.history.push(`${this.props.match.url}/not-touched-in-4-weeks`)
    } else if (val === 2) {
      this.props.history.push(`${this.props.match.url}/interested-but-don’t-want-town-tour-yet`)
    }
  }

  handleAddressChange(address) {
    this.setState({ address: address, isSelect: false }, () => {
    })
  }

  handleSelect = (addr) => {
    let that = this
    geocodeByAddress(addr)
      .then(results =>
        that.setState({ address: results[0].formatted_address, isSelect: true })
      )
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  }

  handleChange(event) {
    if(event.target.value === "sales" || event.target.value === "rental" ){
      this.setState({ err: {} })
    }
    this.setState({ [event.target.name]: event.target.value })
  }

  // This is function when add accept offer, signed contract and closed deal
  openDealModal = (isOpen, buttonType, errorMessage, clientData, deal_status) => {
    let errMsg = ''
    if(deal_status === "Signed Contract" || deal_status === "Closed"){
      errMsg = "mandatory"
    }else{
      errMsg = "optional"
    }
    this.setState({
      modalIsOpen: isOpen,
      dealStatus: deal_status,
      clientData: clientData,
      buttonType: buttonType,
      client_id: clientData.id,
      errorMessage: errMsg
    })
  }

  closeModal = (data) => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({
      modalIsOpen: false,
      isSelect: false,
      dealStatus: '',
      dealData: [],
      buttonType: 'add',
      clientData: [],
      amount: '',
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      dealType: "sales",
      dealStatus: '',
      address: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      errorMessage: "optional",
      lease_start_date: '',
      lease_term: '',
      dealType: "sales"
    })
  }

  // If deal is already created and click on accepted offer so first delete deal
  deleteDealData = (dealData, clientData, deal_status) => {
    this.setState({
      deleteDealModal: true,
      client_id: clientData.id,
      dealStatus: deal_status,
      dealData: dealData
    })
  }

  // Delete deal button when get accepted offer created
  deleteDealButton(e) {
    this.setState({ buttonType: "edit" })
    let dealZeroPosition = _.sortBy(this.state.dealData, function (o) {
      return new moment(o.created).format('YYYY-MM-DD');
    }).reverse()
    let dealDlt = {}
    dealDlt.client_id = this.state.client_id
    dealDlt.deal_id = _.get(dealZeroPosition[0], 'id')
    this.props.deleteClientDealByAgent(dealDlt)
  }

  // close deal modal
  deleteDealCloseModal = () => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({ deleteDealModal: false, dealData: [], preStatus: this.state.preStatus })
  }

  // If deal already created but click on signed contract or closed deal so work this function
  editDealSection = (dealData, clientData, deal_status) => {
    let errMsg = ''
    if(deal_status === "Signed Contract" || deal_status === "Closed"){
      errMsg = "mandatory"
    }else{
      errMsg = "optional"
     }
    this.setState({
      modalIsOpen: true,
      dealStatus: deal_status,
      dealData: dealData,
      isSelect: true,
      clientData: clientData,
      buttonType: 'edit',
      client_id: clientData.id,
      amount: dealData.amount,
      dealStatusDate: deal,
      accepted_offer:  (_.isEmpty(dealData.accepted_offer_date) || (dealData.accepted_offer_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.accepted_offer_date),
      signed_contract_date:  (_.isEmpty(dealData.date_of_signed_contract) || (dealData.date_of_signed_contract === "0000-00-00 00:00:00")) ? '' : moment(dealData.date_of_signed_contract),
      expected_close_date: (_.isEmpty(dealData.expected_close_date) || (dealData.expected_close_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.expected_close_date),
      deal_id: dealData.id,
      dealStatus: deal_status,
      address: dealData.address,
      buy_side_commission: dealData.buy_side_commission,
      misc_fee: dealData.sj_comission,
      agent_notes: dealData.agent_notes,
      preStatus: deal_status,
      preStatus: deal_status,
      errorMessage: errMsg,
      lease_start_date: (_.isEmpty(dealData.lease_start_date) || (dealData.lease_start_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.lease_start_date),
      lease_term: dealData.lease_term,
      dealType: dealData.deal_type === 0 ? "sales" : "rental"
    })
  }

  dateAcceptedClick(date, dateString) {
    this.setState({ accepted_offer: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  dateSignedClick(date, dateString) {
    this.setState({ signed_contract_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  dateClosedClick(date, dateString) {
    this.setState({ expected_close_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  dateLeaseClick(date, dateString) {
    this.setState({ lease_start_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  CloseTabSetStatus(clientId) {
    $('.uniQueselect-' + clientId).prop('selectedIndex', 0)
  }

  submitDealButton() {
    const err = {}
    if (!this.state.isSelect) {
      err.address = 'Please Select address'
    }
    if (this.state.amount === '') {
      err.amount = 'Amount is required'
    }
    if (this.state.dealType === 'sales' || this.state.dealType === 0) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }
  
      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
          }
        }
        if ((this.state.expected_close_date !== '') || !_.isEmpty(this.state.expected_close_date)) {
          if ((this.state.accepted_offer > this.state.expected_close_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
          }
          if (this.state.signed_contract_date > this.state.expected_close_date) {
            err.signed_contract_date = 'Signed Contract date cannot be after Closing date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed date is required';
        }
        if ((this.state.expected_close_date === '' || _.isEmpty(this.state.expected_close_date))) {
          err.expected_close_date = 'Closed date is required';
        }
  
        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.accepted_offer > this.state.expected_close_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.signed_contract_date > this.state.expected_close_date)) {
          err.signed_contract_date = 'Signed contract date cannot be after Closing date';
        }
      }
    } else if (this.state.dealType === 'rentals' || this.state.dealType === 1) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }
  
      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
          }
        }
        if ((this.state.lease_start_date !== '') || !_.isEmpty(this.state.lease_start_date)) {
          if ((this.state.accepted_offer > this.state.lease_start_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
          }
          if (this.state.signed_contract_date > this.state.lease_start_date) {
            err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed Lease is required';
        }
        if ((this.state.lease_start_date === '' || _.isEmpty(this.state.lease_start_date))) {
          err.lease_start_date = 'Lease Start date is required';
        }
  
        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.accepted_offer > this.state.lease_start_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.signed_contract_date > this.state.lease_start_date)) {
          err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
        }
      }
    }
    if(this.state.errorMessage === "mandatory"){
      if (this.state.signed_contract_date === '' || this.state.signed_contract_date == "Invalid date" || this.state.signed_contract_date == "0000-00-00 00:00:00") {
        err.signed_contract_date = 'Please Select Signed Date'
      }
    }

    if(this.state.dealType === "sales"){
      if(this.state.errorMessage === "mandatory"){
        if (this.state.expected_close_date === '' || this.state.expected_close_date == "Invalid date" || this.state.expected_close_date == "0000-00-00 00:00:00" || this.state.expected_close_date == 'null' || this.state.expected_close_date == null) {
          err.expected_close_date = 'Please Select Closed Date'
        }
      }
    }else{
      if(this.state.lease_term === ''){
        err.lease_term = 'Please Fill Lease Term'
      }
      if(this.state.errorMessage === "mandatory"){
        if (_.isEmpty(this.state.lease_start_date) || (this.state.lease_start_date === "0000-00-00 00:00:00")) {
          err.lease_start_date = 'Please Select Date'
        }
      }
    }

    if (this.state.misc_fee === '') {
      err.misc_fee = 'Please Enter Misc Fee'
    }

    if (this.state.buy_side_commission === '') {
      err.buy_side_commission = 'Commission is required'
    }

    if (this.state.agent_notes === '') {
      err.agent_notes = 'Please fill agent note'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      const { createClientDeal } = this.props
      const data = {}
      data.deal_type = this.state.dealType === "sales" ? 0 : 1
      data.client_id = parseInt(this.state.clientData.id)
      data.strategist_id = _.get(this.state.clientData, 'strategists[0].strategist_id')
      data.agent_id = this.context.userData.id
      data.amount = parseInt(this.state.amount.toString().replace(/\D/g, ''))
      data.address = this.state.address
      data.status = this.state.dealStatus
      data.id = (this.state.buttonType) === "add" ? 0 : this.state.deal_id
      data.accepted_offer_date = moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')
      if(this.state.dealType === "sales"){
        if(this.state.expected_close_date){
          data.expected_close_date =  moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
        }
      }else{
        data.lease_term = this.state.lease_term
        if (this.state.lease_start_date) {
          data.lease_start_date = moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
        }
      }
      if(this.state.signed_contract_date){
        data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
      }
      data.sj_comission = parseInt(this.state.misc_fee.toString().replace(/\D/g, ''))
      data.buy_side_commission = parseFloat(this.state.buy_side_commission)
      data.agent_notes = this.state.agent_notes
      createClientDeal(data)
      this.setState({ modalIsOpen: false })
    }
  }

  render() {
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h2 className="heading-title">
                  <span className="heading-icon"><ColoredTasklistIcon /></span>
                  Task List
                </h2>
              </div>
            </div>
            <div className="mob__view__container mob__view__control">
              <div className="col-md-12">
                <select
                    defaultValue={this.state.activeTab}
                    onClick={this.changePropsForMobile.bind(this)}
                    className="form-control custom__select mb__20">
                      <option  value={0} selected={this.state.activeTab === 0 ? "selected" : ""}>New: Never Touched </option>
                      <option  value={1} selected={this.state.activeTab === 1 ? "selected" : ""}>Not touched in 4 weeks </option>
                      <option  value={2} selected={this.state.activeTab === 2 ? "selected" : ""}>Interested but don’t want town tour yet </option>
                </select>
              </div>
            </div>

            <div className="desktop__view">
              <div className="row">
                <div className="col-md-12">
                  <div className="tabs__control">
                  <button onClick={() => this.changeProps(0)} className={this.state.activeTab === 0 ? "btn btn-link active" : "btn btn-link"}>New: Never Touched <span className={this.state.sectionCounts.notTouched > 0 ? "red" : "green"}>{this.state.sectionCounts.notTouched > 1 ? `${this.state.sectionCounts.notTouched} Clients` : `${this.state.sectionCounts.notTouched} Client`}</span></button>
                  <button onClick={() => this.changeProps(1)} className={this.state.activeTab === 1 ? "btn btn-link active" : "btn btn-link"}>Not touched in 4 weeks  <span className="red" >{this.state.sectionCounts.notTouched4Week > 1 ? `${this.state.sectionCounts.notTouched4Week} Clients` : `${this.state.sectionCounts.notTouched4Week} Client`}</span></button>
                    <button onClick={() => this.changeProps(2)} className={this.state.activeTab === 2 ? "btn btn-link active" : "btn btn-link"}>Interested but don’t want town tour yet <span className="orange">{this.state.sectionCounts.notReadyToTour > 1 ? `${this.state.sectionCounts.notReadyToTour} Clients` : `${this.state.sectionCounts.notReadyToTour} Client`}</span></button>
                  </div>
                </div>
              </div>
            </div>

            {/*This is switch component for tab- Never touched and not touched in 4 week tabs*/}
            <Switch>
              <Route exact path={`${this.props.match.url}/new-never-touched`}>
                <NeverTouched {...this.props} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`${this.props.match.url}/not-touched-in-4-weeks`}>
                <NotTouchedInFourWeek {...this.props} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`${this.props.match.url}/interested-but-don’t-want-town-tour-yet`}>
                <InterestedNotTownTour {...this.props}/>
              </Route>
            </Switch>
            {/*This is switch component end*/}

            {/* This is for accept offer, signed contract and close deal modal */}
            <ReactModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Accepted Offer / Signed Contract / Closed"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">{this.state.dealStatus}</h5>
                  <button type="button" className="btn react-modal-close" onClick={this.closeModal}><CloseIcon /></button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <select
                      name="dealType"
                      defaultValue={this.state.dealType}
                      onChange={this.handleChange.bind(this)}
                      className="form-control custom-select material-textfield-input textfield-input-lg"
                      defaultValue={this.state.dealType}
                      >
                      <option value="sales">Sales</option>
                      <option value="rental">Rental</option>
                    </select>
                    <label className="material-textfield-label label-lg">Deal Type<span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group material-textfield">
                    {/* Autoplace search */}
                    <PlacesAutocomplete
                      value={this.state.address}
                      className="form-control material-textfield-input textfield-input-lg"
                      onChange={this.handleAddressChange.bind(this)}
                      onSelect={this.handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Fragment>
                          <input
                            {...getInputProps({
                              required: true,
                              className: 'form-control material-textfield-input textfield-input-lg text-input addressAutoSuggest'
                            })}
                            required
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {

                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Fragment>
                      )}
                    </PlacesAutocomplete>
                    <label
                      className="material-textfield-label label-lg">
                      Address<span className="text-danger">*</span>
                    </label>
                    {this.state.err.address ?
                      <span className="invalid-feedback">
                        {this.state.err.address}
                      </span> : ''}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">Date of Accepted Offer<span className="text-danger">*</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateAcceptedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                      {this.state.err.accepted_offer_date ?
                        <span className="invalid-feedback">
                          {this.state.err.accepted_offer_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="amount"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.amount}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix='$'
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Sales Price" : "Monthly Rental Price"}<span className="text-danger">*</span></label>
                        {this.state.err.amount ?
                          <span className="invalid-feedback">
                            {this.state.err.amount}
                          </span> : ''}
                      </div>
                    </div>
                  </div>

                  {/* This is for signed contract */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">{this.state.dealType === "sales" ? "Date of Signed Contract" : "Date of Signed Lease"}<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateSignedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.signed_contract_date ? moment.utc(this.state.signed_contract_date) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                        {this.state.err.signed_contract_date ?
                        <span className="invalid-feedback">
                          {this.state.err.signed_contract_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="buy_side_commission"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.buy_side_commission}
                          onChange={this.handleChange.bind(this)}
                          suffix='%'
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Buy Side Commission" : "Tenant's Agent Commission"}<span className="text-danger">*</span></label>
                        {this.state.err.buy_side_commission ?
                          <span className="invalid-feedback">
                            {this.state.err.buy_side_commission}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is end for signed contract and commision */}

                    <div className="row">
                    { this.state.dealType === "sales" ?
                      (<div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">Expected/Actual Closing date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateClosedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                            defaultValue={this.state.expected_close_date ? moment.utc(this.state.expected_close_date) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                        {this.state.err.expected_close_date ?
                        <span className="invalid-feedback">
                          {this.state.err.expected_close_date}
                        </span> : ''}
                      </div>) : ''
                    }
                    { this.state.dealType !== "sales" ?
                      (<div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Lease Start Date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.dateLeaseClick.bind(this)}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            defaultValue={this.state.lease_start_date ? moment.utc(this.state.lease_start_date) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                    </div>
                        {this.state.err.lease_start_date ?
                          <span className="invalid-feedback">
                            {this.state.err.lease_start_date}
                          </span> : ''}
                      </div>) : ''
                    }
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="misc_fee"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.misc_fee}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix="$"
                          required
                        />
                        <label className="material-textfield-label label-lg">Misc. Fee<span className="text-danger">*</span></label>
                        {this.state.err.misc_fee ?
                          <span className="invalid-feedback">
                            {this.state.err.misc_fee}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is expected closing dateand misc fee */}
                  {
                    this.state.dealType !== "sales" ?
                    (<div className="row">
                      <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <input
                          name="lease_term"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.lease_term}
                          onChange={this.handleChange.bind(this)}
                          required
                        />
                        <label className="material-textfield-label label-lg">Lease Term<span className="text-danger">*</span></label>
                        {this.state.err.lease_term ?
                          <span className="invalid-feedback">
                            {this.state.err.lease_term}
                          </span> : ''}
                      </div>
                    </div>
                    </div>) : ''
                  }
                  <div className="form-group material-textfield">
                    <textarea
                      type="text"
                      className="form-control material-textfield-input"
                      rows="8"
                      name="agent_notes"
                      value={this.state.agent_notes}
                      placeholder={this.state.dealType === "sales" ? "" : `Please indicate how lease commission will be calculated.`}
                      onChange={this.handleChange.bind(this)}
                    >
                    </textarea>
                    <label className="material-textfield-label">Agent Notes<span className="text-danger">*</span></label>
                    {this.state.err.agent_notes ?
                      <span className="invalid-feedback">
                        {this.state.err.agent_notes}
                      </span> : ''}
                  </div>
                  <button
                    className="btn btn__btn btn-dark btn__lg w__100"
                    onClick={()=>this.submitDealButton()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </ReactModal>

            {/* This is for delete deal data modal */}
            <ReactModal
              isOpen={this.state.deleteDealModal}
              onRequestClose={this.deleteDealCloseModal}
              portalClassName="react-modal"
              contentLabel="Are you sure?"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-body react-modal-body-sm">
                  <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.deleteDealCloseModal}><CloseIcon /></button>
                  <h5 className="react-modal-title mb__13">Are you sure?</h5>
                  <p className="react-modal-subtitle mb__3">This will remove the deal information and you will need to re enter the deal.</p>
                  <div className="text-right">
                    <button onClick={this.deleteDealButton.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        </main>
      </div>
    )
  }
}
