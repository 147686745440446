import { connect } from 'react-redux'
import { get } from 'lodash'

import ChatWithClientComponent from './component'

import {
  fetchClientChat,
  sendChatMessage,
  clearChatPhase,
} from '../../../../../../store/strategistV2/chat/actions'

const ChatWithClientContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    clientChats: get(state, 'strategistV2.chat.clientChats', []),
    sendChatMessagePhase: state.strategistV2.chat.sendChatMessagePhase,
    fetchClientChatPhase: state.strategistV2.chat.fetchClientChatPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientChat,
    sendChatMessage,
    clearChatPhase,
  }
)(ChatWithClientComponent)

export default ChatWithClientContainer
