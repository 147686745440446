import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import config from "../../config";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import Select from "react-select";
import _ from "lodash";
import $ from 'jquery'
import difference from "lodash/difference";
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class Strategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errMessage: "",
      checkedItems: [],
      checkedItemsLocal: [],
      strategySuccessMsg: "",
      checkSuburb: [],
      search: "",
      disableButton: true,
      localDropdown:false,
      initDataLoading:true,
      checkedLOCAL: [],
      localOption: [],
      localIds: [],
      currentTag: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectMultipuleLocals = this.selectMultipuleLocals.bind(this)
  }

  componentWillMount() {
    const { location } = this.props;
    let firstIndex = [];
    if (location.state) {
      if (
        location.state.clientData &&
        location.state.clientData.suburbs &&
        location.state.clientData.suburbs.length > 0
      ) {
        firstIndex = [location.state.clientData.suburbs[0].suburb_id];
      }
      let arr = [];
      let arrLocal = [];
      // Initials already available data passing to state from the props for the strategy
      location.state.strategyData &&
        location.state.strategyData.map((strategyData, index) => {
          if (strategyData.role === "agent") {
            let obj = {};
            obj.agent_id = strategyData.agent_id;
            obj.town_id = strategyData.town_id;
            arr.push(obj);
          } else if(strategyData.role === "local") {
            let obj = {};
            obj.agent_id = strategyData.agent_id;
            obj.town_id = strategyData.town_id;
            arrLocal.push(obj);
            this.state.localIds.push(strategyData.agent_id);
          }else {
            let obj = {};
            obj.agent_id = 0;
            obj.town_id = strategyData.town_id;
            arr.push(obj);
          }
        });

      this.setState({
        checkSuburb: firstIndex,
        checkedItems: arr,
        checkedLOCAL: arrLocal,
      }, ()=>{
        let data = {};
        data.client_id = this.props.location && this.props.location.state && this.props.location.state.clientData ? this.props.location.state.clientData.client_id : 0
        data.suburb_arr = firstIndex;
        this.setState({initDataLoading:true})
        this.props.getTowns(data);
      });
    }
  }

  componentWillReceiveProps(nextProps) {

    let self = this;
    if(nextProps.townPhase =='success') {
      this.setState({initDataLoading:false})
    }
    if (nextProps.townAgnetData && nextProps.townAgnetData.status) {
      this.setState({
        [nextProps.townAgnetData.town_id]: nextProps.townAgnetData.data, initDataLoading:false
      }, ()=>{
      });
    }
    if (nextProps.addStrategyPhase === "success") {
      let self = this;
      this.setState({ strategySuccessMsg: "Added Sucessfully..." });
      this.props.clearPhase();
      setTimeout(() => {
        self.props.history.push({
          pathname: `activity/clients-strategy/${
            this.props.location.state.clientData.client_id
          }`
        });
      }, 1000);
    }
    if (nextProps.addStrategyPhase === "error") {
      this.setState({ strategySuccessMsg: "Something went wrong", initDataLoading:false});
      this.props.clearPhase();
    }
  }

  componentDidMount() {
    document.title = "Jungler: Strategy";
  }

  componentWillUnmount() {
    document.title = "Suburban Jungle";
  }

  /*
  * Function descriptions : HandleChange For select Agents not locals
  * Author : Amit Mishra
  */

  handleChange (town_id, event) {
    let obj = {};
    obj.agent_id = parseInt(event.target.value);
    obj.town_id = town_id;
    let checked = this.state.checkedItems;
    checked = _.filter(checked, function(o) {
      return o.town_id !== town_id ;
    });
    checked.push(obj);
    this.setState({ checkedItems: checked, disableButton: false }, ()=> {
    });
  }

  /*
  * Function descriptions : Clicking on the suburb name ie. New York to see avaibale town list
  * Author : Amit Mishra
  */

  clickOnSuburbs(dataid) {
    let selectArr = [];
    let data = {};
    selectArr.push(dataid);
    data.suburb_arr = selectArr;
    data.client_id = this.props.location && this.props.location.state && this.props.location.state.clientData ? this.props.location.state.clientData.client_id : 0
    this.props.getTowns(data);
    this.setState({ checkSuburb: selectArr, initDataLoading:true});
  }

  /*
  * Function descriptions : Selecting Town for see which Locals or agent available in the perticular town
  * Author : Amit Mishra
  */
  selectCheckbox(town, agent_id) {
    let obj = {};
    obj.agent_id = agent_id;
    obj.town_id = town.id;

    let checked = this.state.checkedItems;
    let initialLocals = this.state.checkedLOCAL;
    let index = -1;
    let localindex = -1;
    _.forEach(checked, function(o, i) {
      if (o.town_id === town.id) {
        index = i;
      }
    });

    _.forEach(initialLocals, function(o, i) {
      if (o.town_id === town.id) {
        localindex = i;
      }
    });

    if (index !== -1) {
      checked.splice(index, 1);
    } else {
      checked.push(obj);
    }

    if (localindex !== -1) {
      initialLocals.splice(localindex, 1);
    } else {
      // If local is not selected we'll not push
      if(agent_id !==0){
        initialLocals.push(obj);
      }
    }

    this.setState({
      checkedItems: checked,
      checkedLOCAL:initialLocals,
      disableButton: false
    }, ()=> {
    });
  }

  /*
  * Function descriptions : Toggle Locals dropDowns
  * Author : Amit Mishra
  */

  toggledropDown(val, event){
    // Open close locals List in dropdown the basis of towns
    if($("#showHideSibling-"+event.target.id).has( "li" ).length > 0){
      $("#showHideSibling-"+event.target.id).fadeToggle('fast')
    } else{
      $("#showHideSibling-"+event.target.id).html( '<li style="color: #9B9B9B" class="custom-select-option">No Local(s)</li>' ).css('top', '52px')
      $("#showHideSibling-"+event.target.id).fadeToggle('fast')
    }
  }

   /*
  * Function descriptions : Locals Multiselect
  * Author : Amit Mishra
  */

  selectMultipuleLocals(local_id, town_id, lable, event) {
     let obj = {}
     obj.agent_id = parseInt(local_id);
     obj.town_id = town_id;
     let localChecked = this.state.checkedLOCAL; // Pre assign the State already available data.
     let index = -1;
        if(lable && lable =='nolocals') {
        // Nothing to do for now here
        }else {
          // Locals multiselect checked or unchecked now
          // Filter out if coming dulicates
          _.forEach(localChecked, function(t, idx){
              if(t.town_id == town_id && t.agent_id == 0){
                localChecked.splice(index, idx)
              }
          })

          // index value increase if current town and local is already in ARRAY `localChecked`
          _.forEach(localChecked, function(o, i) {
              if (o.town_id === town_id && o.agent_id === local_id) {
                index = i;
              }
          })

            // index value will not be -1 if current town and local is already in ARRAY -> localChecked
            if (index !== -1) {
              // Remove obj if its duplicate
              localChecked.splice(index, 1)
            } else {
              // Push unique Object (localChecked array not have any exist object which belogs to same local id and current townID)
              localChecked.push(obj)
            }

          this.setState({ checkedLOCAL: localChecked, disableButton: false }, ()=> {
          });
        }
  }

   /*
  * Function descriptions : Function for saving strategy and prepare strategy data
  * Author : Amit Mishra
  */

  submitBtn(event) {
   // let combineBoth = _.merge(checkedItems, checkedLOCAL)
    let agents =   this.state.checkedItems
    let locals =  this.state.checkedLOCAL
    // Concating an seprate Array of object for remove conditional duplicates
    let addedBoth = agents.concat(locals);

    // Map the combined array
     var valueArr = addedBoth.map(function(item) {
      return item;
     });

     // Cheking if its has duplicates `duplicate` will return true/false if found and duplications
     var duplicate = function(values, town_id) {
      var duplicate = _.filter(values, function(el) {
        return el.town_id === town_id;
      });
      return duplicate.length > 1 ? true : false;
     };

     var result = [];
     var resultItem = [];
     valueArr.forEach(function(item) {
        if(item && item.town_id) {
          // Check is current pair is duplicate
            var isDuplicate = duplicate(valueArr, item.town_id);
            // Has duplicate but agent id is not zero
            if (item.agent_id > 0 && isDuplicate) {
              result.push(item);
              resultItem.push(item.town_id);
            // Not duplicate but agent id is greater then zero
            } else if (!isDuplicate && item.agent_id > 0) {
              result.push(item);
              resultItem.push(item.town_id);
            // Not duplicate but agent id is equal to zero
            } else if (!isDuplicate && item.agent_id === 0) {
              result.push(item);
              resultItem.push(item.town_id);
            }
        }
     });

    // Final array of object `result` is prepared with all possible check to save in strategy
    // Preparing API data
    let data = {};
    data.client_id = this.props.location.state.clientData.client_id;
    data.town_agents = result; // All selected agents and locals are here
    data.town_locals = []; // No required to send seprate Locals object
    this.setState({ disableButton: true });
    // Called API for create/update startegy
    this.props.addStrategy(data);

    const jwtToken = cookies.get('Authorization')
    // this is hardcoded url for now once new jungler code added then no need
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_HOSTNAME_NEW_V2}/v2/create/strategy`,
      'headers': { 'Authorization': jwtToken },
      data: {
        client_id: this.props.location.state.clientData.client_id,
        townStrategies: result
      }
    }).then(res => {
    }).catch(logoutInvalidRequest)
  }


  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    const {
      pristine,
      isSubmitting,
      rxError,
      user,
      token,
      phase,
      townData,
      townAgnetData,
      location,
      townPhase
    } = this.props;

    let townArray = [];

    const aIClientAddedTowns = townData && townData.aIClientAddedTowns ? townData.aIClientAddedTowns : []
    const selectTownList = _.filter(townData && townData.data, town => {
      let array = this.state.checkedItems;
      let filterA = array.indexOf(town.id);
      _.forEach(this.state.checkedItems, function(o, i) {
        if (o.town_id == town.id) {
         // townArray.push(town.name)
          townArray.push(town.name_long);
        }
      });
      _.forEach(this.state.checkedLOCAL, function(o, i) {
        if (o.town_id == town.id) {
         // townArray.push(town.name)
          townArray.push(town.name_long);
        }
      });
    });

    // Agent and Local towns are pushed in array `townArray`
    // Now need to remove if its duplicated due to both agent and local
    townArray = _.uniq(townArray)
    let filterList = "";
    let allTownData = townData && townData.data;
    if (allTownData) {
      filterList = allTownData.filter(allTownData => {
        return (
          allTownData.name
            .toLowerCase()
            .indexOf(this.state.search.toLowerCase()) !== -1
        );
      });
    }

    return (
      <div>
         {this.state.initDataLoading === true ?
         <div className="loader__agent" >
         <img src="../../img/loader2.svg" />
        </div>
        :''}
        {/* <div className="full__page--header">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xs-10">
                <div className="logo-header">
                  <a>
                    <img src="/img/logo.svg" />{" "}
                    <span>| &nbsp;&nbsp; Strategy</span>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-xs-2">
                <div className="close-btn">
                  <Link
                    to={`/activity/clients-strategy/${
                      this.props.location.state.clientData.client_id
                    }`}
                  >
                    <img src="img/close.svg" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile__header--wrapper">
                <div className="profile__header">
                  <a>
                    <img src="/img/logo.svg" />
                    <h4>
                      <span>|</span> Strategy
                    </h4>
                  </a>
                </div>
                <div className="profile__close">
                  <Link to={`/activity/clients-strategy/${this.props.location.state.clientData.client_id}`}><img src="img/close.svg" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="strategy--container">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="full__page--left">
                  <h1>Set Strategy</h1>
                  <span className="townrep__para">
                    Select which towns/cities that best suit this client, then
                    add an agent or local to that specific town.
                  </span>
                </div>
              </div>
              <div className="col-md-9 col-sm-12">
                <div className="strategy--form">
                  <div className="filter__container">
                    <div className="filter__container--filter-name">
                      {location &&
                        location.state &&
                        location.state.clientData.suburbs &&
                        location.state.clientData.suburbs.map((sub, index) => {
                          return (
                            <button
                              key={index}
                              onClick={() => this.clickOnSuburbs(sub.suburb_id)}
                              className={
                                this.state.checkSuburb.indexOf(
                                  sub.suburb_id
                                ) !== -1
                                  ? "btn filter__container--button active_sub"
                                  : "btn filter__container--button active"
                              }
                            >
                              {sub.suburbs_name}
                            </button>
                          );
                        })}
                    </div>

                    <h6 />
                    {
                      <div className="filter__container--town-name">
                        {townArray &&
                          townArray.map((townName, indx) => {
                            return (
                              <button
                                key={indx}
                                className="btn filter__container--button selected_towns"
                              >
                                {townName}
                              </button>
                            );
                          })}
                      </div>
                    }
                  </div>
                  <div className="strategist-search fullsearch__strategy">
                    <input
                      onChange={e => this.onSearch(e)}
                      type="text"
                      name="search"
                      placeholder="Search Town Name"
                      className="startegy__input"
                    />
                  </div>

                  <div className="strategy--form__card strategy__form-wrapper strategyht">
                    {filterList &&
                      filterList.map((town, index) => {
                        let selectedIndex = false;
                        let selectedAgent = "";
                        _.find(this.state.checkedItems, function(o) {
                          if (o.town_id === town.id) {
                            selectedAgent = o.agent_id;
                            selectedIndex = true;
                          }
                        });


                        //let selectedLocal = [];
                        _.find(this.state.checkedLOCAL, function(o) {
                          if (o.town_id === town.id) {
                            //selectedLocal.push(o.agent_id);
                            selectedIndex = true;
                          }
                        });

                        let arr = [];
                        let localExist = false;
                        town &&
                          town.agents &&
                          town.agents.map((val, idx) => {
                            if (val.agent_id != null && (val.role === "local" || val.role === "business development")) {
                              _.find(this.state.checkedLOCAL, function(o) {
                                if (o.town_id === town.id) {
                                  arr.push({
                                    label:
                                      val.agent_first_name +
                                      " " +
                                      val.agent_last_name +
                                      " (" +
                                      val.role +
                                      ")",
                                    value: val.agent_id
                                  });
                                }

                                if(o.town_id === town.id && o.agent_id > 0){
                                  localExist = true;
                                }
                              });
                            }
                          });
                        const _townType = _.filter(aIClientAddedTowns, function(o) {
                                        return o.town_id === town.id
                                      })
                        const townType = _townType && _townType.length ? _townType[0].isAi : false
                        return (
                          <ul
                            key={index}
                            className="locations__list agent-town-lists"
                          >
                            <li className={selectedIndex ? "active" : ""}>
                              <div
                                className="select__agent--name selectagentname"
                                onClick={() => this.selectCheckbox(town, 0)}
                              >
                                <span className="checkbox__icon checkboxmar">
                                  <img
                                    src={
                                      !selectedIndex
                                        ? "img/checkblank-icon.svg"
                                        : "img/checkmark-icon.svg"
                                    }
                                  />
                                </span>
                               <span className="titlenew">{town.name_long}</span>
                               {townType ? (<span className="subtitlenew">{townType === 1 ? 'AI Recommended' : 'Client Selection'}</span>) : ''}
                              </div>
                              <div className="locations__list--wrapper locationslistwrapper">
                                <select
                                  className="form-control agent_sel--box"
                                  value={selectedAgent}
                                  defaultValue=""
                                  onChange={this.handleChange.bind(
                                    this,
                                    town.id
                                  )}
                                >
                                  <option value="0">No Agent</option>
                                  {town &&
                                    town.agents &&
                                    town.agents.map((val, idx) => {
                                      if (
                                        val.agent_id != null &&
                                        val.role === "agent"
                                      ) {
                                        return (
                                          <option
                                            key={idx}
                                            value={val.agent_id}
                                          >
                                            {val.agent_first_name +
                                              " " +
                                              val.agent_last_name +
                                              " (" +
                                              val.role +
                                              ")"}
                                          </option>
                                        );
                                      }
                                    })}
                                </select>

                                <div className="local_select--box">
                                    <div id={town.id} className="custom-select custom-sel-filter custom-sel-filter-new" onClick={this.toggledropDown.bind(this, 'localDropdown')}>{localExist ? "See Selected Local(s)" : "No Locals or BD"}</div>
                                    <div id={"showHideSibling-"+town.id} className= "sel-outer">
                                      {town && town.agents && town.agents.length > 0 && town.agents.map((locals, localssindx) => {
                                      let selectedIndex = false;
                                      if(locals.role=="local" || locals.role=="business development") {
                                        _.forEach(this.state.checkedLOCAL, function(o) {
                                          if(o.agent_id === locals.agent_id  && o.town_id === town.id){
                                            selectedIndex = true;
                                          }
                                        });

                                        return (
                                        <li key={localssindx} className="custom-select-option">
                                          <span className="coverage__checkbox">
                                            <label className="custom-checkbox">
                                            {locals.agent_first_name +
                                              " " +
                                              locals.agent_last_name +
                                              " (" +
                                              (locals.role == 'business development' ? 'BD' : locals.role) +
                                              ")" }
                                            <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.selectMultipuleLocals.bind(this, locals.agent_id, town.id, 'locals')}/>
                                              <span className="checkmark"></span>
                                            </label>
                                          </span>
                                        </li>
                                      )
                                      }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                  </div>

                  {townPhase === "loading" && (
                    <div
                      style={{ padding: "20px", textAlign: "center" }}
                      className="strategy--form__card"
                    >
                      <img src="../../img/loader2.svg" />
                    </div>
                  )}
                  {this.state.strategySuccessMsg !== "" && (
                    <div className="text-center alert alert-success">
                      {this.state.strategySuccessMsg}
                    </div>
                  )}
                  {allTownData != "" && filterList != "" ? (
                    <div className="strategy--form__btn">
                      <button
                        type="submit"
                        onClick={() => {
                          this.props.history.push({
                            pathname: `activity/clients-strategy/${
                              this.props.location.state.clientData.client_id
                            }`
                          });
                        }}
                        className="btn btn__black--lg btn__black--outline padd__t--b"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={this.submitBtn.bind(this)}
                        disabled={this.state.disableButton}
                        className="btn btn__green btn__green--lg"
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default reduxForm({
  form: "strategy", // a unique identifier for this form
  destroyOnUnmount: true
})(Strategy);
