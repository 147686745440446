import { connect } from 'react-redux'

import AgentRatingReportComponent from './component'
import { getAgentRatingReport, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const AgentRatingReportContainer = connect(
  // Map state to props
  (state) => ({
    agentRatingReportPhase: state.reportV2.agentRatingReportPhase,
    agentRatingReportData: state.reportV2.agentRatingReportData
  }),
  // Map actions to dispatch and props
  {
    getAgentRatingReport,
    clearReportV2Phase
  }
)(AgentRatingReportComponent)

export default AgentRatingReportContainer
