import React from 'react'
import { map } from 'lodash'
import config from '../../../../config'

// return options for role based select role
export const roleListOption = (user) => {
  if (user.role === "strategist") {
    return map(config.roleList, (role, index) => {
      if (["superadmin", "admin", "strategist", "former-strategist-inactive"].includes(role.key))
        return;
      return (<option key={index} value={role.key}>{role.name}</option>);
    });
  } else if (user.role === "superadmin") {
    return map(config.roleList, (role, index) => (
      <option key={index} value={role.key}>{role.name} </option>
    ));
  } else if (user.role === "admin") {
    return map(config.roleList, (role, index) => {
      if (role.key === "superadmin")
        return;
      return (<option key={index} value={role.key}> {role.name} </option>);
    });
  } else {
    return map(config.roleList, (role, index) => (
      <option key={index} value="" disabled></option>
    ));
  }
}


export const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}


export const initialValues = {
  selectedRole: "",
  firstName: "",
  lastName: "",
  email: "",
  timeZone: "",
  countryCode: "+1",
  mobileNumber: "",
  conferenceContryCode: "",
  conferenceMobileNumber: "",
  acuityCalendarId: "",
  minPrice: "",
  maxPrice: "",
  firmAddress: "",
  title: "",
  strategyToggle: true,
  isImpersonate: true,
  limitClients: false,
  towns: [],
  strategistAssignmentRules: [],
  bio: "",
  hourlyAmount: "",
  payScale: "",
  dealPayout: "",
  dealPayoutAmount: "",
  refferalPayout: "",
  refferalPayoutAmount: "",
  assignedSuburbs: [],
  searchTownOption: [],
  selectedSearchTown: [],
  seletedSearchByStrategist: [],
  agentTown:""
} 