import React, { PureComponent } from 'react'
import { get } from 'lodash'

// import TopNavigation from '../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon } from '../../../../components/icons'
import EmailPanel from './partials/EmailPanel'
import './styles.scss'

export default function EmailPanelComponent(props) {

  const handleViewEmailRoute = () =>
    props.history.replace({
      pathname: `/strategist/client-details/${props.match.params.id}`,
      // this  route become prev for next page
      state: { prevRoute: get(props, 'history.location.state.prevRoute', '') }
    })

  return (
    <div className="layout-has-sidebar">
      <AdministrationSidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="container-fluid">
          <div className="client__details__header">
            <div className="row d__flex align__items__center">
              <div className="col-xs-6 col-md-4">
                <button className="btn btn-back" onClick={handleViewEmailRoute}>
                  <BackArrowIcon className="arrow-icon" />
                  Back Client Detail
                </button>
              </div>
              <div className="col-xs-6 col-md-8 text-right"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p__0">
                  <EmailPanel {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
