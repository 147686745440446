import { connect } from 'react-redux'
import {loginUser, handleSignOut} from '../../store/user/duck'
import ClientProfileForm from './component'
const ClientProfile = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase 
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut
  }
)(ClientProfileForm)
export default ClientProfile
