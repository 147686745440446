/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Spin, Drawer } from 'antd'
import { map, upperFirst } from 'lodash'
import moment from 'moment'

import './styles.scss'
import Loader from '../../../../../../components/Loader'
import { SUCCESS } from '../../../../../../constants/phase'
import { CloseIcon } from '../../../../../../components/icons'

export default function InsightsComponent(props) {
  const [insightOption, setInsightOption] = useState('all')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const clientId = props.match.params.id && props.match.params.id
    setLoading(true)
    props.fetchTownResearch({
      townId: props.townId,
      type: insightOption,
      clientId,
    })
  }, [])
  // hide loader if fetching of data is done
  useEffect(() => {
    if (props.fetchResearchTownPhase === SUCCESS) {
      setLoading(false)
    }
  }, [props.fetchResearchTownPhase])
  // hide loader if fetching of data is done
  useEffect(() => {
    if (props.updateResearchTownPhase === SUCCESS) {
      const clientId = props.match.params.id && props.match.params.id
      props.fetchTownResearch({ townId: props.townId, type: insightOption, clientId, })
    }
  }, [props.updateResearchTownPhase])
  
  const handleChangeInsightOption = ({ target }) => {
    const clientId = props.match.params.id && props.match.params.id
    setInsightOption(target.value)
    props.fetchTownResearch({
      townId: props.townId,
      type: target.value,
      clientId,
    })
  }

  const handleUpdateActiveResearch = (id, isActive) => {
    const clientId = props.match.params.id && props.match.params.id
    setLoading(true)
    props.updateTownResearch({
      researchId: id,
      isActive: isActive ? false : true,
      townId: props.townId,
      type: 'active',
      clientId,
    })
  }

  const handleShareActiveResearch = (id, isActive) => {
    const clientId = props.match.params.id && props.match.params.id
    setLoading(true)
    props.updateTownResearch({
      townId: props.townId,
      type: "share",
      clientId,
      townName: props.townName
    })
    props.closeDrawer()
  }

  return (
    <Drawer
    placement="right"
    closable={false}
    width="100%"
    onClose={props.closeDrawer}
    visible={props.visible}
    className="drawer__main"
  >
    <div className="drawer__header d__flex align__items__center">
      <h4 className="drawer__header__title">Insights for {upperFirst(props.townName)}</h4>
      <div className="drawer__header__close" onClick={props.closeDrawer}>
        <button className="btn btn__link"><CloseIcon /></button>
      </div>
    </div>
    <Spin size="large" spinning={isLoading} indicator={<Loader />}>
      <div className="share__insights__container">
        <div className="share__insights__select">
          <div className="material-textfield">
          <select
              className="form-control custom-select material-textfield-input"
              value={insightOption}
              onChange={handleChangeInsightOption}
            >
                <option value="all">See All</option>
                <option value="link">Links</option>
                <option value="file">Upload Files</option>
                <option value="note">Notes</option>
            </select>
            <label className="material-textfield-label">Select</label>
          </div>
        </div>
       <ul className="share__insights__list">
       {props.townResearchs && props.townResearchs.length > 0 ? (
            map(props.townResearchs, (research, index) => {
              if (research.link && research.link !== '' && !research.link.match(/^[a-zA-Z]+:\/\//))
                research.link = 'https://' + research.link;
              return (<li key={index} className={`${research.isActive ? 'active' : ''}`} onClick={() => handleUpdateActiveResearch(research.id, research.isActive)}>
                  <div>
                    <h4 className="title">{research.note}</h4>
                    {research.file && <a className="title-link" href={research.file} target="_blank" download={research.file_name}>{(research.file_name)}</a>}
                    {research.link && <a className="title-link" href={research.link} target="_blank">{(research.link)}</a>}
                    { !research.file && !research.link && <h4></h4> }
                    <div className="time">Uploaded on {moment(research.modified).format("MMMM DD, YYYY")}</div>
                  </div>
                </li>
              )
          })) : (
              <div className="drawer__body__inner height__remove">
                <div className="blank__state__text">
                    No Insight Data.
                </div>
              </div>
            )}
            </ul>
        <div className="share__insights__bottom">
          <button className="btn btn__btn btn-dark btn__lg w__100" onClick={handleShareActiveResearch}>Share</button>
        </div>
      </div>
    </Spin>
    </Drawer>
  )
}
