/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { orderBy, get, map, take, size } from 'lodash'
import './styles.scss'

import OldHistory from './partials/OldHistory'
import InitialWelcomeHistory from './partials/InitialWelcomeHistory'
import Loader from '../../../../../components/Loader'
import { SUCCESS } from '../../../../../constants/phase'

export default function ActivityHistoryComponent(props) {
  const [viewMore, setViewMore] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setViewMore(true)
  }, [])
  // stop loader when client information fetched
  useEffect(() => {
    if (props.fetchActivityHistoryPhase === SUCCESS) {
      props.clearActivityHistoryPhase()
      const clientId = props.match.params.id && props.match.params.id
      props.fetchClientActivityHistory({ clientId })
    }
    if (props.fetchClientActivityHistoryPhase === SUCCESS) {
      props.clearActivityPhase()
      setLoading(false)
      setViewMore(true)
    }
  }, [props.fetchClientActivityHistoryPhase, props.fetchActivityHistoryPhase])
  
  // on click more button change view and show all history
  const viewMoreButton = () => setViewMore(!viewMore)
  const data = get(props, 'activityHistories', [])
  const activityHistories = orderBy(data, ['created'], ['desc'])
  const histories = viewMore ? take(activityHistories, 5) : activityHistories
  const userName = get(props, 'clientDetail.client_full_name', '')
  return (
    <div>
      <div className="card">
        <div className="card-header d__flex align__items__center">
          <h5 className="card-title mb__0">Activity History</h5>
        </div>
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
          <div className="card-body card-body-sm">
            <ul className="activity__history__list">
              {map(histories, (history, index) => {
                return (
                  <li key={index}>
                    {history.status ? (
                      <InitialWelcomeHistory history={history} index={index} userName={userName} />
                    ) : (
                      <OldHistory history={history} index={index} />
                    )}
                  </li>
                )
              })}
            </ul>
            {size(histories) > 4 && (
              <div className="chats__sectiion">
                <div className="chats__sectiion__inner">
                  <ul className="chats__sectiion__chats__list">
                    <button onClick={viewMoreButton} className="btn btn__view_more_chat">
                      {viewMore ? 'View More' : 'View Less'}
                    </button>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  )
}
