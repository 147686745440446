import { Record } from 'immutable'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  dashboardPhase: INIT,
  dashboardData: [],
  agentAllClients: [],
  getNotTouchedPhase: INIT,
  notTouchedData: [],
  notTouchedIn4WeekPhase: INIT,
  notTouchedIn4WeekData: [],
  deletePhase: INIT,
  deleteData: [],
  editClientPhase: INIT,
  editClientData: [],
  editStrAgentPhase: INIT,
  editStrAgentData: [],
  deletStrAgentPhase: INIT,
  deletStrAgentData: [],
})
export const agentV2Reducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    // Fetch agent dashboard data
    case types.GET_AGENT_DASHBOARD: {
      return state.set('error', null).set('dashboardPhase', LOADING)
    }
    case types.GET_AGENT_DASHBOARD_SUCCESS: {
      return state
        .set('dashboardData', action.payload)
        .set('error', null)
        .set('dashboardPhase', SUCCESS)
    }
    case types.GET_AGENT_DASHBOARD_ERROR: {
      return state.set('error', action.payload.error).set('dashboardPhase', ERROR)
    }
    case types.GET_AGENT_DASHBOARD_CANCELLED: {
      return state.set('error', null).set('dashboardPhase', INIT)
    }

    // fetch agent all clients details
    case types.FETCH_AGENT_ALL_CLIENT: {
      return state.set('error', null).set('phase', LOADING)
    }
    case types.FETCH_AGENT_ALL_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentAllClients', payload)
        .set('error', null)
        .set('phase', SUCCESS)
    }

    // delete agent and client chat data
    case types.DELETE_CHAT_DATA: {
      return state.set('error', null).set('deletePhase', LOADING)
    }
    case types.DELETE_CHAT_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteData', payload)
        .set('error', null)
        .set('deletePhase', SUCCESS)
    }

    //edit strategist and agent note
    case types.EDIT_STRATEGIST_AGENT_NOTE: {
      return state.set('error', null).set('editStrAgentPhase', LOADING)
    }
    case types.EDIT_STRATEGIST_AGENT_NOTE_SUCCESS: {
      const { payload } = action
      return state
        .set('editStrAgentData', payload)
        .set('error', null)
        .set('editStrAgentPhase', SUCCESS)
    }

    //delet strategist and agent note
    case types.DELETE_STRATEGIST_AGENT_NOTE: {
      return state.set('error', null).set('deletStrAgentPhase', LOADING)
    }
    case types.DELETE_STRATEGIST_AGENT_NOTE_SUCCESS: {
      const { payload } = action
      return state
        .set('deletStrAgentData', payload)
        .set('error', null)
        .set('deletStrAgentPhase', SUCCESS)
    }

    // Fetch agent not touched data
    case types.GET_NOT_TOUCHED_DATA: {
      return state.set('error', null).set('getNotTouchedPhase', LOADING)
    }
    case types.GET_NOT_TOUCHED_DATA_SUCCESS: {
      return state
        .set('notTouchedData', action.payload)
        .set('error', null)
        .set('getNotTouchedPhase', SUCCESS)
    }
    case types.GET_NOT_TOUCHED_DATA_ERROR: {
      return state.set('error', action.payload.error).set('getNotTouchedPhase', ERROR)
    }
    case types.GET_NOT_TOUCHED_DATA_CANCELLED: {
      return state.set('error', null).set('getNotTouchedPhase', INIT)
    }

    // Fetch agent not touched in four week data
    case types.GET_NOT_TOUCHED_IN_4WEEK: {
      return state.set('error', null).set('notTouchedIn4WeekPhase', LOADING)
    }
    case types.GET_NOT_TOUCHED_IN_4WEEK_SUCCESS: {
      const { payload } = action
      return state
        .set('notTouchedIn4WeekData', payload)
        .set('error', null)
        .set('notTouchedIn4WeekPhase', SUCCESS)
    }

    case types.GET_NOT_TOUCHED_IN_4WEEK_ERROR: {
      return state.set('error', action.payload.error).set('notTouchedIn4WeekPhase', ERROR)
    }
    case types.GET_NOT_TOUCHED_IN_4WEEK_CANCELLED: {
      return state.set('error', null).set('notTouchedIn4WeekPhase', INIT)
    }

    case types.EDIT_CLIENT_CHAT: {
      return state.set('error', null).set('editClientPhase', LOADING)
    }
    case types.EDIT_CLIENT_CHAT_SUCCESS: {
      return state
        .set('editClientData', action.payload)
        .set('error', null)
        .set('editClientPhase', SUCCESS)
    }

    case types.CLEAR_AGENT_PHASE: {
      return state
        .set('phase', INIT)
        .set('dashboardPhase', INIT)
        .set('getNotTouchedPhase', INIT)
        .set('notTouchedIn4WeekPhase', INIT)
        .set('deletePhase', INIT)
        .set('editClientPhase', INIT)
        .set('editStrAgentPhase', INIT)
        .set('deletStrAgentPhase', INIT)
    }

    default: {
      return state
    }
  }
}
