import { connect } from 'react-redux'
import {loginUser, handleSignOut} from '../../store/user/duck'
import StrategyForm from './component'

import { getTowns, getTownAgent, addStrategy, clearPhase } from '../../store/dashboard/duck'

const Strategy = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase,
    townData: state.dashboard.townData,
    townAgnetData: state.dashboard.townAgnetData,
    addStrategyPhase: state.dashboard.addStrategyPhase,
    townPhase: state.dashboard.townPhase
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut,
    getTowns,
    addStrategy,
    clearPhase,
    getTownAgent,
  }
)(StrategyForm)
export default Strategy
