export const FETCH_FILTER_LIST = 'suburban/strategistV2/dashboard/FETCH_FILTER_LIST'
export const FETCH_FILTER_LIST_SUCCESS = 'suburban/strategistV2/dashboard/FETCH_FILTER_LIST_SUCCESS'
export const FETCH_FILTER_LIST_ERROR = 'suburban/strategistV2/dashboard/FETCH_FILTER_LIST_ERROR'

export const FETCH_YOURAGENT_LIST = 'suburban/strategistV2/dashboard/FETCH_YOURAGENT_LIST'
export const FETCH_YOURAGENT_LIST_SUCCESS = 'suburban/strategistV2/dashboard/FETCH_YOURAGENT_LIST_SUCCESS'
export const FETCH_YOURAGENT_LIST_ERROR = 'suburban/strategistV2/dashboard/FETCH_YOURAGENT_LIST_ERROR'

export const FETCH_CLIENT_PROFILE = 'suburban/strategistV2/dashboard/FETCH_CLIENT_PROFILE'
export const FETCH_CLIENT_PROFILE_SUCCESS = 'suburban/strategistV2/dashboard/FETCH_CLIENT_PROFILE_SUCCESS'
export const FETCH_CLIENT_PROFILE_ERROR = 'suburban/strategistV2/dashboard/FETCH_CLIENT_PROFILE_ERROR'

export const CLEAR_DASHBOARD_PHASE = 'suburban/strategistV2/dashboard/CLEAR_DASHBOARD_PHASE'

export const FETCH_DEVELOPMENT_MEMBERS='suburban/strategistV2/dashboard/FETCH_DEVELOPMENT_MEMBERS'
export const FETCH_DEVELOPMENT_MEMBERS_SUCCESS='suburban/strategistV2/dashboard/FETCH_DEVELOPMENT_MEMBERS_SUCCESS'
export const FETCH_DEVELOPMENT_MEMBERS_ERROR='suburban/strategistV2/dashboard/FETCH_DEVELOPMENT_MEMBERS_ERROR'
