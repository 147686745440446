import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "lodash";
import { Redirect, Link } from "react-router-dom";
import { AppBar } from "material-ui";
import { Container, Row, Col } from "react-grid-system";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let pre_auth = ''
let pathName = ''
let _user = ''

export class MobileSecondHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      menuIconDrawer: false,
     searchedClients: [],
     showSearchList: false,
     isloading:false,
     search:'',
     pre_auth:'',
     searchBarToggle: false
    };
    this.userSignout = this.userSignout.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillMount() {
    _user = cookies.get("user");
    let data = {};
    data.user_id = _user.id;
    this.props.getNotifications(data)

    pre_auth = cookies.get('i_user')
    if(pre_auth){
      this.setState({pre_auth : pre_auth})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearNotificationPhase === "success") {
      _user = cookies.get("user");
      let data = {};
      data.user_id = _user.id;
      this.props.getNotifications(data);
    }
  }

  componentDidMount() {
    const { history } = this.props
    let url = history.location.pathname
    let splitUrl = url.split('/')
    if(splitUrl.toString()){
      pathName =  splitUrl[1]
    }
    if(_user && _user.role != "agent"){
      this.stickyFix()
    }
  }

  stickyFix = () => {
    document.body.style.overflow = "visible";
    if ($(window) && $(window).scroll) {
      $(window).scroll(function(){
       var sticky = $('.sticky'),
           scroll = $(window).scrollTop();
       if (scroll >= 100) sticky.addClass('impstrateMobilefixedhead');
       else sticky.removeClass('impstrateMobilefixedhead');
     })
    }
  }

  userSignout(logout) {
    localStorage.clear();
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // document.cookies.remove('Authorization')
    // document.cookies.remove('user')
    this.props.handleSignOut();
    this.props.history.push("/login");
  }

  onSelected(event) {
    if (event.target.value === "dashboard") {
      this.props.history.push({ pathname: "/dashboard" });
    }
    if (event.target.value === "home") {
      this.props.history.push({ pathname: "/home/new-clients" });
    }
    if (event.target.value === "clients") {
      this.props.history.push({ pathname: "/clients-list" });
    }
    if (event.target.value === "junglerUsers") {
      this.props.history.push({ pathname: "/jungler-users" });
    }
  }

  clearNotification() {
    let _user = cookies.get("user");
    let data = {};
    data.user_id = _user.id;
    this.props.clearNotification(data);
  }

  onSearch(e){
    if (e.target.value!== '') {
      this.setState({search:e.target.value, showSearchList: true})
    }
    else {
      this.setState({showSearchList: false})
    }
  }

  onSelectClients(clientData){
    this.setState({ searchBarToggle: false })
    if(clientData.role == "client"){
      this.props.history.push('/activity/'+clientData.id)
      this.setState({showSearchList: false})
    }else{
      this.props.history.push({ pathname: '/add-jungler-user', state: clientData})
    }
  }

  closeList() {
    this.setState({searchedClients: [], showSearchList: false })
  }

  showPlaceholder(){
    const {history} = this.props
    if(history.location.pathname == '/jungler-users'){
      return "Search Jungler Users"
    } else if (history.location.pathname == '/home/new-clients' || history.location.pathname == '/dashboard'){
      return "Search Users"
    }else{
      return "Search Clients"
    }
  }

  searchButton(e){
    this.setState({ searchBarToggle: true },()=>{
      if( $('#search-box') && $('#search-box').addClass){
        $('#search-box').addClass("open")
      }
    })
  }

  closeSearchIcon(e){
    this.setState({ searchBarToggle: false, showSearchList: false },()=>{
      if( $('#search-box') && $('#search-box').removeClass){
        $('#search-box').removeClass("open")
      }
    })
  }

  menuIconbutton(e){
    this.setState({ menuIconDrawer: true })
  }

  closeMenuButton(e){
    this.setState({ menuIconDrawer: false, showSearchList: false })
  }

  render() {
    const { history, allUserData, notificationData, user, saveAuthtokenData } = this.props;
    let allData = allUserData && allUserData.data

    let filterList = ''
    if(allData !== undefined && allData !==''){
      filterList = allData.filter((data) =>{
        let firstLastName = data.first_name+' '+data.last_name
        let mobVal = ''
        let secondMob = ''
        if(data.phone_mobile){
          mobVal = data.phone_mobile.replace(/\D/g, '')
        }
        if(data.secondary_phone){
          secondMob = data.secondary_phone.replace(/\D/g, '')
        }
        return (
          (data.first_name && data.first_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) || (data.last_name && data.last_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) || (data.full_name && data.full_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) || (data.email && data.email.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) || (mobVal && mobVal.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) || ( (secondMob) ? (secondMob && secondMob.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) : '') || ( (data.secondary_name) ? (data.secondary_name && data.secondary_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) : '') || ( (data.secondary_email) ? (data.secondary_email && data.secondary_email.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) : '')  
        );
       }
      );
    }
    return (
      <div>
        <div className="container-fluid mobileAdminStickyHeader mobileView">
        <div class={ this.state.pre_auth ? (pathName === 'activity' ? "fixedhead" : "sticky") : "fixedhead" }>
          <nav className={ (this.state.pre_auth && (history.location.pathname == "/home/new-clients" || history.location.pathname == "/home/follow-up" || history.location.pathname == "/home/active-clients" || history.location.pathname == "/home/upcoming-appointments" || history.location.pathname == "/clients-list" || history.location.pathname == "/ai-towns" || history.location.pathname == "/town-report" || history.location.pathname == "/strategist-dashboard" || history.location.pathname == "/jungler-users")) ? "navbar navbar-default navbar-edit impersonate__strat--dash" : "navbar navbar-default navbar-edit" }>

            <div className="navbar-header navbar__header--mobile">
            {
              ((user && user.role) === "strategist")?
               <Link to={`/strategist-dashboard`} className="navbar-brand navbar__brand--mobile"><img src="img/logo.svg" /></Link>
              :
               <Link to={`/dashboard`} className="navbar-brand navbar__brand--mobile"><img src="img/logo.svg" /></Link>
            }
              <div className="right__contents">

            {
              !(this.state.searchBarToggle) &&
              <img onClick={this.searchButton.bind(this)} src="./img/searchiconmobile.svg" className="searchicon" />
            } 
                
            <div>
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <img src="./img/notificaton.svg" height="24" className="notificationicon"/>
              { notificationData && notificationData.length > 0 &&
                <span className="notificatonShow-icon"></span>
              }
              </a>
              <ul className="dropdown-menu">
                { notificationData && notificationData.length > 0 &&
                  <span onClick={this.clearNotification.bind(this)} className="clear-notificaton">
                    <img src="img/clear.png"/>
                  </span>
                }
                {notificationData && notificationData.length > 0 ? notificationData.map((val, index) => {
                  return (
                    <li key={index}>
                      <Link to={`#`} >{val.message}</Link>
                    </li>
                  );
                }) : 
                  <li>
                    <Link to={`#`} >No new notifications</Link>
                  </li>
                }
              </ul>
            </div> 

              <button className="mobilemenuopen" type="button" onClick={this.menuIconbutton.bind(this)}>
                <img src="./img/mobiconmenu.svg" />
              </button>
            </div>
          </div>

            <div className={this.state.menuIconDrawer ? "mobile__drawer": "mobile__drawer mobile__drawer--close"}>
              <div className="mobileclose"><img src="./img/mobileclose.svg" onClick={this.closeMenuButton.bind(this)} /></div>
              <ul>
              {
                ((user && user.role) === "strategist")?
                <li><Link to={`/strategist-dashboard`}>Dashboard</Link></li>
                :
                <li><Link to={`/dashboard`}>Dashboard</Link></li>
              }
                <li><Link to={`/home/new-clients`}>Home</Link></li>
                <li><Link to={`/clients-list`}>Clients</Link></li>
                <li><Link to={`/jungler-users`}>Jungler Users</Link></li>
                <li><Link to={`/town-report`}>Town Reprots</Link></li>
                <li><Link to={`/ai-towns`}>AI</Link></li>
                <li><Link to={`/add-jungler-user`}>Add User</Link></li>
                <li><Link to={`/add-clients`}>Add Client</Link></li>
                <li><Link to={{ pathname: "/profile", state: user && user }}>My Profile</Link></li>
                <li><a target="_blank" href="https://acuityscheduling.com/login.php">Availability</a></li>
                <li><a href="javascript:void(0)" onClick={this.userSignout.bind(this)}>Logout</a></li>
              </ul>
            </div>
          </nav>

          {/*This is for search bar*/}
            {
              <form id="search-box" className={this.state.searchBarToggle ? "navbar__header--form fullsearch__bg open-search" : "navbar__header--form fullsearch__bg"}>
              <span className="typeahead typeaheadnewmob">
                <input 
                  className="costom-search typeahead-input" 
                  onChange={(e)=> this.onSearch(e)}
                  type="text" 
                  name="search" 
                  placeholder={this.showPlaceholder()}/>
                  <span onClick={this.closeSearchIcon.bind(this)}><img src="img/close.svg" /></span>
                
                { this.state.showSearchList &&
                  <ul className="search-list">
                  <div className="search-close"><i className="fa fa-times" onClick={()=> this.closeList()}></i></div>
                    { 
                      filterList && filterList.map((c, i) => {
                        return(
                          <li key={i} onClick={()=> this.onSelectClients(c)}>
                            <a>
                              <div className="search-left">
                                <span>
                                  <p className="title">{c.first_name+ ' '+ c.last_name+' '+'('+c.role+')'}</p>
                                </span>
                              </div>
                            </a>
                          </li>
                        )
                      })
                    }                                                  
                  </ul>
                }
              </span>
            </form>
            }
            {/*This is for search bar end code*/}

        </div>
      </div>
    </div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    initialValues: props.location.state ? props.location.state : {}
  };
}

export default connect(mapStateToProps)(MobileSecondHeader);
