import * as types from './action-types'
/*
    ///////////////////////////////////
  /// jungler user  action ///
////////////////////////////////////
*/
// fetch all jungler user with filter payload
export const getJunglerUsers = (payload) => ({
  type: types.FETCH_JUNGLER_USERS_LIST,
  payload,
})
// create new jungler user or add user action
export const addJunglerUser = (payload) => ({
  type: types.CREATE_NEW_JUNGLER_USER,
  payload,
})
// create new jungler user or add user action
export const createJunglerUser = (payload) => ({
  type: types.CREATE_NEW_JUNGLER_USER,
  payload,
})
// clear jungler user phase
export const clearJunglerUserPhase = (payload) => ({
  type: types.CLEAR_JUNGLER_USERS_LIST_PHASE,
  payload,
})
