/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import { get, map, isEmpty, compact } from 'lodash'
import moment from 'moment'
import { DatePicker, Spin } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import NumberFormat from 'react-number-format';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import GooglePlaceSearchInput from 'react-google-place-autocomplete-input';
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'

import { DownCaretIcon } from '../../../../../components/icons'
import validation from '../../../../../config/validation'
import { SUCCESS } from '../../../../../constants/phase'
import Loader from '../../../../../components/Loader'
import ReactModal from 'react-modal'
import { CloseIcon } from '../../../../../components/icons'
export const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}
export default class DealForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: true,
      isLoading: false,
      searchTerm: false,
      townOptions: [],
      schooolSearchTerm: false,
      deleteDealModal:false
    }
  }
  componentDidMount() {
    const clientId = this.props.location.state.clientId
    this.setState({ isLoading: true }, () =>
    this.props.fetchTownAndSchoolDistrict({ clientId }))
    this.props.fetchSchoolDistrict({ clientId, searchTerm: ''  })
    this.props.getClientAgents({ client_id: clientId })
  }

  static getDerivedStateFromProps(props, state) {
    let states = {...state}
    if (!(props.location.state.clientId)) {
      props.history.push('/strategist/clients/all-clients')
    }
    if (props.fetchTownSchoolDistrictPhase === SUCCESS) {
      const sububrTowns = get(props, 'location.state.clientDetails.suburbs_towns', [])
      const townOptions = compact(map(sububrTowns, town => {
        if (town.town_name && town.town_id) {
          return { label: town.town_name, value: town.town_id }
        }
      }))
      props.clearDealSectionPhase()
      states = { ...states, isLoading: false, townOptions }
    }
    if (props.fetchSchoolDistrictPhase === SUCCESS) {
      const schoolDistricts = get(props, 'schoolDistricts', [])
      const schoolDistrictOptions = compact(
        map(schoolDistricts, (dist) => {
          if (dist.districtname) {
            return { label: dist.districtname, value: dist.districtname }
          }
        })
      )
      states = { ...states, isLoading: false, schoolDistrictOptions }
    }
    if (get(props, 'location.state.deal', false) && state.initialValues) {
      const dealType = get(props, 'location.state.deal.deal_type', 0)
      states = {...states,
        initialValues: false,
        dealId: get(props, 'location.state.deal.id', ''),
        agent_id: get(props, 'location.state.deal.agent_id', ''),
        dealType: dealType === 0 ? 'sales' : 'rentals',
        selectedTown: get(props, 'location.state.deal.selectedTown', ''),
        seletedSchool: get(props, 'location.state.deal.seletedSchool', ''),
        salePrice: get(props, 'location.state.deal.amount', ''),
        micFee: get(props, 'location.state.deal.sj_comission', ''),
        agentNote: get(props, 'location.state.deal.agent_notes', ''),
        address: get(props, 'location.state.deal.address', ''),
        closeDate: get(props, 'location.state.deal.expected_close_date', '') ? moment.utc(props.location.state.deal.expected_close_date) : '',
        leaseDate: get(props, 'location.state.deal.lease_start_date', '') ? moment.utc(props.location.state.deal.lease_start_date) : '',
        dateOfSignedContract: get(props, 'location.state.deal.date_of_signed_contract', '') ? moment.utc(props.location.state.deal.date_of_signed_contract) : '',
        dateOfAcceptedOffer: get(props, 'location.state.deal.accepted_offer_date', '') ? moment.utc(props.location.state.deal.accepted_offer_date) : '',
        buySideCommission: get(props, 'location.state.deal.buy_side_commission', ''),
        agent_status: get(props, 'location.state.deal.deal_status', ''),
        lease_term: get(props, 'location.state.deal.lease_term', '')
      }
    }
    if (props.createUpdateDealPhase === SUCCESS) {
      props.clearDealSectionPhase()
      const clientId = props.location.state.clientId
      props.history.push(`/strategist/client-details/${clientId}`)
    }
    if (props.deleteClientDealPhase === SUCCESS) {
      props.clearDealSectionPhase()
      const clientId = props.location.state.clientId
      props.history.push(`/strategist/client-details/${clientId}`)
    }
    return states
  }

  handleddressChange = (address, setFieldValue) => {
    setFieldValue('address', address)
  }

  handleSelect = (addr, setFieldValue) => {
    const that = this
    geocodeByAddress(addr)
      .then(results => {
        setFieldValue('address', results[0].formatted_address)
      }
      )
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  }


  handleChangeTown = (options, townId, setFieldValue) => {
    if (townId === options.value) {
      setFieldValue('selectedTown', '')
    } else {
      setFieldValue('selectedTown', options.value)
    }
    this.setState({ searchTerm: true })
  }

  handleChangeSchool = (options, schoolDistrictId, setFieldValue) => {
    if (schoolDistrictId === options.value) {
      setFieldValue('seletedSchool', '')
    } else {
      setFieldValue('seletedSchool', options.value)
    }
    this.setState({ schooolSearchTerm: true })
  }

  townInputSearch = (searchTerm) => {
    if (searchTerm.length !== 0) {
      this.setState({ searchTerm: true })
    }
    if (searchTerm.length === 0) {
      this.setState({ searchTerm: false })
    }
  }

  schoolInputSearch = (srchTerm) => {
    if (srchTerm.length !== 0) {
      this.setState({ schooolSearchTerm: true })
    }
    if (srchTerm.length === 0) {
      this.setState({ schooolSearchTerm: false })
    }
  }

  handleSubmit = (values) => {
    values.clientId = get(this.props, 'location.state.clientId', '')
    values.selectedTown = values.selectedTown
    values.seletedSchool = values.seletedSchool
    values.sj_comission = Number(values.micFee)
    values.amount = Number(values.salePrice)
    values.accepted_offer_date = moment(values.dateOfAcceptedOffer).format('YYYY-MM-DD hh:mm')

    if (values.dateOfSignedContract !== '' || isEmpty(values.dateOfSignedContract) === false || values.dateOfSignedContract !== 'null') {
      values.signed_contract_date = values.dateOfSignedContract ? moment(values.dateOfSignedContract).format('YYYY-MM-DD hh:mm') : ''
    }

    if ((values.dealType === 'sales' || values.dealType === 0) && (values.expected_close_date !== '' || isEmpty(values.expected_close_date) === false || values.expected_close_date !== 'null') ) {
      values.expected_close_date = values.closeDate ? moment(values.closeDate).format('YYYY-MM-DD hh:mm') : ''
    }
    if ( (values.dealType !== 'sales' || values.dealType !== 0) && (values.lease_start_date !== '' || isEmpty(values.lease_start_date) === false || values.lease_start_date !== 'null') ) {
      values.lease_start_date = values.leaseDate ? moment(values.leaseDate).format('YYYY-MM-DD hh:mm') : ''
    }
    if(values.dealType === 'rentals' || values.dealType === 1) {
      values.leaseTerm = values.lease_term
    }
    this.setState({ isLoading: true }, () => this.props.createUpdateDeal(values))
  }
  selectedTownOption = (townId) => {
    const options = compact(
      map(this.state.townOptions, (town) => {
        if (townId === town.value) {
          return { label: town.label, value: town.value }
        }
      })
    )
    return get(options, '[0]', {})
  }
  selectedSchoolDistrictOption = (districtId) => {
    const options = compact(
      map(this.state.schoolDistrictOptions, (dist) => {
        if (districtId === dist.value) {
          return { label: dist.label, value: dist.value }
        }
      })
    )
    return get(options, '[0]', {})
  }

  toggleDeletDealModal = () =>
    this.setState({ deleteDealModal: !this.state.deleteDealModal })

  handleClickDelete = () => {
    const clientId = this.props.location.state.clientId
    // eslint-disable-next-line camelcase

    this.props.deleteClientDeal({ client_id: clientId }, () =>
      this.setState({ deleteDealModal: false })
    )
  }

  render() {
    const { isLoading } = this.state
    const agentOptions = map(get(this.props, 'clientAgentData.data', []), (agentval, agentindx) => {
      return (
        <option key={agentindx} value={agentval.agent_id}>{agentval.agent_full_name}</option>
      )
    })

    const agentStatusMap = {
      signed: true, closed: true
    }

    if(this.state.agent_status === 'Accepted Offer') {
      agentStatusMap.signed = false
    } else if(this.state.agent_status === 'Signed Contract'  || 
      this.state.agent_status === 'Closed') {
      agentStatusMap.signed = false
      agentStatusMap.closed = false
    } 
    return (
      <div className="col-md-9">
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              dealId: get(this.state, 'dealId', ''),
              agent_id: get(this.state, 'agent_id', ''),
              dealType: get(this.state, 'dealType', 'sales'),
              agentStatus: get(this.state, 'agent_status', ''),
              address: get(this.state, 'address', ''),
              selectedTown: get(this.state, 'selectedTown', ''),
              seletedSchool: get(this.state, 'seletedSchool', ''),
              dateOfAcceptedOffer: get(this.state, 'dateOfAcceptedOffer', ''),
              dateOfSignedContract: get(this.state, 'dateOfSignedContract', ''),
              buySideCommission: get(this.state, 'buySideCommission', ''),
              closeDate: get(this.state, 'closeDate', ''),
              leaseDate: get(this.state, 'leaseDate', ''),
              salePrice: get(this.state, 'salePrice', ''),
              micFee: get(this.state, 'micFee', ''),
              agentNote: get(this.state, 'agentNote', ''),
              lease_term: get(this.state, 'lease_term', '')
            }}
            // validationSchema={validation.editDealInforamtion}
            validate={values => {
              const errors = {};
              if (values.dealType === '') {
                errors.dealType = 'Deal Type is required';
              }
              if (values.agent_id === '') {
                errors.agent_id = 'Please select agent';
              }
              if (values.agentStatus === '') {
                errors.agentStatus = 'Status is required';
              }
              if (values.address === '') {
                errors.address = 'Address is required';
              }
              if (values.dealType === 'sales' || values.dealType === 0) {
                if ((values.dateOfAcceptedOffer === '') || (isEmpty(values.dateOfAcceptedOffer))) {
                  errors.dateOfAcceptedOffer = 'Date of accepted offer is required';
                }

                if (values.agentStatus === "Accepted Offer") {
                  if ((values.dateOfSignedContract !== '') || !isEmpty(values.dateOfSignedContract)) {
                    if (values.dateOfAcceptedOffer > values.dateOfSignedContract) {
                      errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Signed Contract date';
                    }
                  }
                  if ((values.closeDate !== '') || !isEmpty(values.closeDate)) {
                    if ((values.dateOfAcceptedOffer > values.closeDate)) {
                      errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Closing date';
                    }
                    if (values.dateOfSignedContract > values.closeDate) {
                      errors.dateOfSignedContract = 'Signed Contract date cannot be after Closing date';
                    }
                  }
                } else if (values.agentStatus === "Signed Contract" || values.agentStatus === "Closed") {
                  if ((values.dateOfSignedContract === '' || isEmpty(values.dateOfSignedContract))) {
                    errors.dateOfSignedContract = 'Signed date is required';
                  }
                  if ((values.closeDate === '' || isEmpty(values.closeDate))) {
                    errors.closeDate = 'Closed date is required';
                  }

                  if ((values.dateOfSignedContract !== '' || !isEmpty(values.dateOfSignedContract)) && (values.dateOfAcceptedOffer > values.dateOfSignedContract)) {
                    errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Signed Contract date';
                  }
                  if ((values.closeDate !== '' || !isEmpty(values.closeDate)) && (values.dateOfAcceptedOffer > values.closeDate)) {
                    errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Closing date';
                  }
                  if ((values.closeDate !== '' || !isEmpty(values.closeDate)) && (values.dateOfSignedContract > values.closeDate)) {
                    errors.dateOfSignedContract = 'Signed contract date cannot be after Closing date';
                  }
                }
              } else if (values.dealType === 'rentals' || values.dealType === 1) {
                if ((values.dateOfAcceptedOffer === '') || (isEmpty(values.dateOfAcceptedOffer))) {
                  errors.dateOfAcceptedOffer = 'Date of accepted offer is required';
                }

                if (values.agentStatus === "Accepted Offer") {
                  if ((values.dateOfSignedContract !== '') || !isEmpty(values.dateOfSignedContract)) {
                    if (values.dateOfAcceptedOffer > values.dateOfSignedContract) {
                      errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Signed Lease date';
                    }
                  }
                  if ((values.leaseDate !== '') || !isEmpty(values.leaseDate)) {
                    if ((values.dateOfAcceptedOffer > values.leaseDate)) {
                      errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Lease Start date';
                    }
                    if (values.dateOfSignedContract > values.leaseDate) {
                      errors.dateOfSignedContract = 'Signed Lease date cannot be after Lease Start date';
                    }
                  }
                } else if (values.agentStatus === "Signed Contract" || values.agentStatus === "Closed") {
                  if ((values.dateOfSignedContract === '' || isEmpty(values.dateOfSignedContract))) {
                    errors.dateOfSignedContract = 'Signed Lease is required';
                  }
                  if ((values.leaseDate === '' || isEmpty(values.leaseDate))) {
                    errors.leaseDate = 'Lease Start date is required';
                  }

                  if ((values.dateOfSignedContract !== '' || !isEmpty(values.dateOfSignedContract)) && (values.dateOfAcceptedOffer > values.dateOfSignedContract)) {
                    errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Signed Lease date';
                  }
                  if ((values.leaseDate !== '' || !isEmpty(values.leaseDate)) && (values.dateOfAcceptedOffer > values.leaseDate)) {
                    errors.dateOfAcceptedOffer = 'Accepted Offer date cannot be after Lease Start date';
                  }
                  if ((values.leaseDate !== '' || !isEmpty(values.leaseDate)) && (values.dateOfSignedContract > values.leaseDate)) {
                    errors.dateOfSignedContract = 'Signed Lease date cannot be after Lease Start date';
                  }
                }
              }
              
              if (values.buySideCommission === '') {
                errors.buySideCommission = 'Commission is required';
              }
              if (values.salePrice === '') {
                errors.salePrice = 'Sale price is required';
              }
              if (values.micFee === '') {
                errors.micFee = 'Misc. fee is required';
              }
              if ((values.lease_term === '') && (values.dealType === 'rentals' || values.dealType === 1)) {
                errors.lease_term = 'Please fill lease term';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              this.handleSubmit(values)
            }}
          >
            {({ values, errors, isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <div className="card">
                  <div className="card-body">

                    <div className="form-group material-textfield">
                      <Field
                      as="select"
                      name="agent_id"
                      className="form-control custom__select material-textfield-input textfield-input-lg">
                        <option disabled value="">Select Agent</option>
                        {agentOptions}
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Agent(s) Assigned with <span className="text-danger">*</span>
                      </label>
                      <ErrorMessage className="invalid-feedback" name="agent_id" component="div" />
                    </div>

                    <div className="form-group material-textfield">
                      <Field
                      as="select"
                      name="dealType"
                      className="form-control custom__select material-textfield-input textfield-input-lg">
                        <option value="sales">Sales</option>
                        <option value="rentals">Rentals</option>
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Deal Type <span className="text-danger">*</span>
                      </label>
                      <ErrorMessage className="invalid-feedback" name="dealType" component="div" />
                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        name="agentStatus"
                        className="form-control custom__select material-textfield-input textfield-input-lg">
                        <option disabled value="">Select</option>
                        <option value="Accepted Offer">Accepted Offer</option>
                        <option value="Signed Contract" disabled={agentStatusMap.signed}>Signed Contract</option>
                        <option value="Closed" disabled={agentStatusMap.signed || agentStatusMap.closed}>Closed</option>
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Agent Status<span className="text-danger">*</span>
                      </label>
                      <ErrorMessage className="invalid-feedback" name="agentStatus" component="div" />
                    </div>
                    <div className="form-group material-textfield">
                      <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                        <ReactMultiSelectCheckboxes
                          styles={multiSelectStyles}
                          width="100%"
                          name="selectedTown"
                          isMulti={false}
                          placeholderButtonLabel="Select"
                          value={this.selectedTownOption(values.selectedTown)}
                          options={this.state.townOptions}
                        // onInputChange={this.townInputSearch}
                          onChange={(event) => this.handleChangeTown(event, values.selectedTown, setFieldValue)}
                        />
                        <label className="multiselect__checkboxes__field__label">
                          Select Town
                        </label>
                      </div>
                     </div>

                     <div className="form-group material-textfield">
                      <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                        <ReactMultiSelectCheckboxes
                          styles={multiSelectStyles}
                          width="100%"
                          name="seletedSchool"
                          isMulti={false}
                          placeholderButtonLabel="Select"
                          value={this.selectedSchoolDistrictOption(values.seletedSchool)}
                          options={this.state.schoolDistrictOptions}
                         // onInputChange={this.schoolInputSearch}
                          onChange={(event) =>
                            this.handleChangeSchool(event, values.seletedSchool, setFieldValue)
                          }
                        />
                         <label className="multiselect__checkboxes__field__label">
                          School District
                        </label>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <PlacesAutocomplete
                        value={values.address}
                        name="address"
                        className="form-control material-textfield-input textfield-input-lg"
                        onChange={(event) =>
                          this.handleddressChange(event, setFieldValue)
                        }
                        onSelect={(event) =>
                          this.handleSelect(event, setFieldValue)
                        }
                        required
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading
                        }) => (
                          <Fragment>
                            <Field
                              {...getInputProps({
                                className:
                                'form-control material-textfield-input textfield-input-lg',
                                 required: true
                              })}
                            />
                            <div className={'autocomplete-dropdown-container'}>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item'

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                )
                              })}
                            </div>
                            <label className="material-textfield-label label-lg">
                              Address<span className="text-danger">*</span>
                            </label>
                          </Fragment>
                        )}
                      </PlacesAutocomplete>
                      <ErrorMessage className="invalid-feedback" name="address" component="div" />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">
                            Date of Accepted Offer
                            <span className="text-danger">*</span>
                          </div>
                          <DatePicker
                            name="dateOfAcceptedOffer"
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={(date) => setFieldValue('dateOfAcceptedOffer', date)}
                            value={values.dateOfAcceptedOffer}
                            // inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            // defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        <ErrorMessage className="invalid-feedback" name="dateOfAcceptedOffer" component="div" />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <NumberFormat
                              name="salePrice"
                              className="form-control material-textfield-input textfield-input-lg"
                              value={values.salePrice}
                              decimalSeparator='.'
                              decimalScale={2}
                              thousandSeparator={true}
                              onChange={(event) => setFieldValue('salePrice', parseFloat(event.target.value.replace(/[^\d\.]/g, '')))}
                              prefix="$"
                              required
                          />
                          <label className="material-textfield-label label-lg">
                          {(values.dealType === "sales" || values.dealType === 0) ? "Sales Price" : "Monthly Rental Price"}
                          <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage className="invalid-feedback" name="salePrice" component="div" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">
                            {(values.dealType === 'sales' || values.dealType === 0) ? "Date of Signed Contract" : "Signed Lease" }
                            {
                              (values.agentStatus === "Signed Contract" || values.agentStatus === "Closed") &&
                              <span className="text-danger">*</span>
                            }
                          </div>
                          <DatePicker
                            name="dateOfSignedContract"
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={(date) => setFieldValue('dateOfSignedContract', date)}
                            // inputReadOnly={true}
                            value={values.dateOfSignedContract}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            // defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        <ErrorMessage className="invalid-feedback" name="dateOfSignedContract" component="div" />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            name="buySideCommission"
                            className="form-control material-textfield-input textfield-input-lg"
                            value={values.buySideCommission}
                            decimalSeparator='.'                            
                            decimalScale={2}
                            onChange={(event) => setFieldValue('buySideCommission', parseFloat(event.target.defaultValue.replace('%', '')))}
                            suffix="%"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                          {(values.dealType === "sales" || values.dealType === 0) ? "Buy Side Commission (%)" : "Tenant's Agent Commission (%)"}
                            <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage className="invalid-feedback" name="buySideCommission" component="div" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {
                        (values.dealType === 'sales' || values.dealType === 0) ?
                        <div className="col-md-6">
                          <div className="btn__follow-up-date follow-up-date-lg">
                            <div className="follow-up-date-label">
                              Expected/Actual Closing Date
                              {
                                (values.agentStatus === "Signed Contract" || values.agentStatus === "Closed") &&
                                <span className="text-danger">*</span>
                              }
                            </div>
                            <DatePicker
                              name="closeDate"
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={(date) => setFieldValue('closeDate', date)}
                              format="MM/DD/YYYY"
                              allowClear={false}
                              value={values.closeDate}
                              // defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                              placeholder="Select Date"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                          <ErrorMessage className="invalid-feedback" name="closeDate" component="div" />
                        </div>
                        :
                        <div className="col-md-6">
                          <div className="btn__follow-up-date follow-up-date-lg">
                            <div className="follow-up-date-label">
                              Lease Start Date
                              {
                                (values.agentStatus === "Signed Contract" || values.agentStatus === "Closed") &&
                                <span className="text-danger">*</span>
                              }
                            </div>
                            <DatePicker
                              name="leaseDate"
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={(date) => setFieldValue('leaseDate', date)}
                              format="MM/DD/YYYY"
                              allowClear={false}
                              value={values.leaseDate}
                              // defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                              placeholder="Select Date"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                          <ErrorMessage className="invalid-feedback" name="leaseDate" component="div" />
                        </div>
                      }

                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <NumberFormat
                              name="micFee"
                              className="form-control material-textfield-input textfield-input-lg"
                              value={values.micFee}
                              decimalSeparator='.'
                              decimalScale={2}
                              onChange={(event) => setFieldValue('micFee', parseFloat(event.target.value.replace(/[^\d\.]/g, '')))}
                              thousandSeparator={true}
                              prefix="$"
                              required
                          />
                          <label className="material-textfield-label label-lg">
                            Misc. Fee <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage className="invalid-feedback" name="micFee" component="div" />
                        </div>
                      </div>
                    </div>
                    {
                      (values.dealType === "rentals" || values.dealType === 1) &&
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <Field
                              name="lease_term"
                              className="form-control material-textfield-input textfield-input-lg"
                              required
                            />
                            <label className="material-textfield-label label-lg">Lease Term<span className="text-danger">*</span></label>
                            <ErrorMessage className="invalid-feedback" name="lease_term" component="div" />
                          </div>
                        </div>
                      </div>
                    }

                    <div className="form-group material-textfield">
                      <Field
                        component="textarea"
                        className="form-control material-textfield-input textfield-input-lg"
                        name="agentNote"
                        required
                        rows="8"
                      ></Field>
                      <label className="material-textfield-label label-lg">
                        Agent Notes
                      </label>
                      <ErrorMessage className="invalid-feedback" name="agentNote" component="div" />
                    </div>
                  </div>
                </div>
                <button type="submit" disabled={isSubmitting} className="btn btn__btn btn-dark btn__lg w__100">
                  Save
                </button>
                <br/>
                <br/>
                {
                  this.state.dealId ? (
                    <button type="button" className="btn btn__btn btn-danger btn__lg w__100" onClick={this.toggleDeletDealModal}>
                      Delete Deal
                    </button>
                  ) : ''
                }
              </Form>
            )}
          </Formik>
        </Spin>
        <ReactModal
          isOpen={this.state.deleteDealModal}
          contentLabel="Delete User"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button
                type="button"
                className="btn react-modal-close react-modal-close-sm"
                onClick={this.toggleDeletDealModal}
              >
              <CloseIcon />
              </button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">You want to delete this deal? This action remove deal and its details. Once deleted can't recoverd.</p>
              <div className="text-right">
                <button onClick={this.handleClickDelete} className="btn btn__btn btn-dark w__150">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
