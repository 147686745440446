import { connect } from 'react-redux'

import TextClientComponent from './component'

import {
  fetchClientTextMessage,
  sendClientTextMessage,
  deleteClientTextMessage,
  clearChatPhase
} from '../../../../../../store/strategistV2/chat/actions'

const TextClientContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    clientMessages: state.strategistV2.chat.clientMessages,
    sendClientTextMessagePhase:
      state.strategistV2.chat.sendClientTextMessagePhase,
    fetchClientTextMessagePhase:
      state.strategistV2.chat.fetchClientTextMessagePhase,
    deleteClientTextMessagePhase:
      state.strategistV2.chat.deleteClientTextMessagePhase
  }),
  // Map actions to dispatch and props
  {
    clearChatPhase,
    fetchClientTextMessage,
    sendClientTextMessage,
    deleteClientTextMessage
  }
)(TextClientComponent)

export default TextClientContainer
