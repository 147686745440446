import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import DatePicker from '../../../../../../components/datePicker'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class ClientsPerAgentsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tableClientData: [],
      type: 'assignPerAgent',
      fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      sortByKey:'name',
      sortByType: 0,
      datePickerModel: false,
      previousStartDate: moment().add(-1, 'month').startOf('month'),
      previousEndDate: moment(),
      search: '',
      openClient: false,
      showAll: true,
      currentAgentClients: [],
      isLoading: false,
      selectedIndex: 'all',
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id
      // const strData = {
      //   from  : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   to    : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   type  : this.state.type,
      //   sortBy: {key: this.state.sortByKey, type: this.state.sortByType},
      //   limit: 100
      // }
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        strData.type = this.state.type
        strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      }
      this.props.getClientsPerAgent(strData)
    }
  }

  componentDidUpdate(preProps, preState){
    const { clientsPerAgentsData, clientsPerAgentsPhase, clearReportV2Phase } = this.props
    if(clientsPerAgentsPhase === "success"){
      let data = []
      if(_.size(clientsPerAgentsData) > 0){
        clientsPerAgentsData && clientsPerAgentsData.map((clientData, i)=>{
          data.push({
            key: i,
            name: clientData.name,
            firm_name: clientData.firm_name,
            totalNumberOfClients: clientData.totalNumberOfClients,
            totalClients: clientData.totalClients,
            totalGreen: clientData.totalGreen,
            totalActivelyEngaged: clientData.totalActivelyEngaged,
            totalSomewhatEngaged: clientData.totalSomewhatEngaged,
            totalNotVeryEngaged: clientData.totalNotVeryEngaged,
            totalUJActivelyEngaged: clientData.totalUJActivelyEngaged,
            totalUJSomewhatEngaged: clientData.totalUJSomewhatEngaged,
            totalUJNotVeryEngaged: clientData.totalUJNotVeryEngaged,
            totalListing: clientData.totalListing,
            clientNames: clientData.clientNames
          })
        })
      }
      this.setState({ tableClientData: data, isLoading: false })
      clearReportV2Phase()
    }
    if(clientsPerAgentsPhase === "error"){
      clearReportV2Phase()
      this.setState({ tableClientData: [], isLoading: false })
    }
  }


  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index) => {
      if (val.id === parseInt(event.target.value)) {
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoading: true }, () => {
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        strData.type = this.state.type
      } else {
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        strData.type = this.state.type
        strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
        strData.limit = 100;
      }
      this.props.getClientsPerAgent(strData)
    })
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to  : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      type: this.state.type
    }
    this.props.getClientsPerAgent(strData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  _onOpenClientModal(data){
    const clients = data && data.clientNames
    if(_.size(clients) >= 1){
      this.setState({ agentName: data.name, openClient: true, currentAgentClients: clients })
    }
  }

  onCloseClientModal() {
    this.setState({ agentName: '', currentAgentClients: [], openClient: false })
  }

  changeLimit = limit => {
    this.setState({ isLoading: true })

    if(_user) {
      const data = {}
      data.user_id = _user.id
      const strData = {
        from  : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        to    : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        type  : this.state.type,
        sortBy: {key: this.state.sortByKey, type: this.state.sortByType},
        limit: Number(limit)
      }
      this.props.getClientsPerAgent(strData)
    }
  }

  render() {
    const { tableClientData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let filterList  = tableClientData
    if(_.size(tableClientData) > 0){
      filterList = tableClientData.filter(
        (val) =>{
          return (
            (val.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back">
                      <BackArrowIcon className="arrow-icon"/>Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title"># of Clients per Agent</h2>
                      <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={filterList} name="clients_per_agents">
                          <ExcelColumn label="Agent Name" value="name"/>
                          <ExcelColumn label="Firm Name" value="firm_name"/>
                          <ExcelColumn label="Total number of all green clients" value="totalNumberOfClients"/>
                          <ExcelColumn label="Total - My Clients" value="totalClients"/>
                          <ExcelColumn label="Green" value="totalGreen"/>
                          <ExcelColumn label="Green - Actively Engaged" value="totalActivelyEngaged"/>
                          <ExcelColumn label="Green - Somewhat Engaged" value="totalSomewhatEngaged"/>
                          <ExcelColumn label="Green - Not Very Engaged" value="totalNotVeryEngaged"/>
                          <ExcelColumn label="UJ Green - Actively Engaged" value="totalUJActivelyEngaged"/>
                          <ExcelColumn label="UJ Green - Somewhat Engaged" value="totalUJSomewhatEngaged"/>
                          <ExcelColumn label="UJ Green - Not Very Engaged" value="totalUJNotVeryEngaged"/>
                          <ExcelColumn label="Listing" value="totalListing"/>
                      </ExcelSheet>
                    </ExcelFile>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search for a client by name, email, or phone number..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
              {
                (_.size(filterList) > 0) ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <Table
                        className="table__fixed"
                        columns={[
                          {
                            title: 'Agent Name',
                            width: 250,
                            dataIndex: 'name',
                            key: '1',
                            fixed: 'left',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.name.localeCompare(b.name)
                          },
                          {
                            title: 'Firm Name',
                            width: 250,
                            dataIndex: 'firm_name',
                            key: '12',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.firm_name.localeCompare(b.firm_name)
                          },
                          {
                            title: 'Total number of all green clients',
                            width: 290,
                            dataIndex: 'totalNumberOfClients',
                            showSorterTooltip: false,
                            key: '2',
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalNumberOfClients - b.totalNumberOfClients
                          },
                          {
                            title: 'Total - My Clients',
                            dataIndex: 'totalClients',
                            key: '3',
                            width: 250,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalClients - b.totalClients,
                            render: (text, record) => <a style={{ "color": parseInt(text) >= 1 ? "#c7ce4a" : "$primaryColor" }} onClick={()=>this._onOpenClientModal(record)}>{text}</a>
                          },
                          {
                            title: 'Green',
                            dataIndex: 'totalGreen',
                            key: '4',
                            width: 150,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalGreen - b.totalGreen,
                          },
                          {
                            title: 'Green - Actively Engaged',
                            dataIndex: 'totalActivelyEngaged',
                            key: '5',
                            width: 280,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalActivelyEngaged - b.totalActivelyEngaged,
                          },
                          {
                            title: 'Green - Somewhat Engaged',
                            dataIndex: 'totalSomewhatEngaged',
                            key: '6',
                            width: 280,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalSomewhatEngaged - b.totalSomewhatEngaged,
                          },
                          {
                            title: 'Green - Not Very Engaged',
                            dataIndex: 'totalNotVeryEngaged',
                            key: '7',
                            width: 280,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalNotVeryEngaged - b.totalNotVeryEngaged,
                          },
                          {
                            title: 'UJ Green - Actively Engaged',
                            dataIndex: 'totalUJActivelyEngaged',
                            key: '8',
                            width: 280,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalUJActivelyEngaged - b.totalUJActivelyEngaged,
                          },
                          {
                            title: 'UJ Green - Somewhat Engaged',
                            dataIndex: 'totalUJSomewhatEngaged',
                            key: '9',
                            width: 330,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalUJSomewhatEngaged - b.totalUJSomewhatEngaged,
                          },
                          { title: 'UJ Green - Not Very Engaged',
                            dataIndex: 'totalUJNotVeryEngaged',
                            key: '10',
                            width: 310,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalUJNotVeryEngaged - b.totalUJNotVeryEngaged,
                          },
                          { title: 'Listing',
                            dataIndex: 'totalListing',
                            key: '11',
                            width: 180,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.totalListing - b.totalListing,
                          }
                        ]}
                        dataSource={filterList}
                        pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                        scroll={{ y: 420 }}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="blank__table__state">Data Not Found</div>
              }
            </Spin>

            </div>
          </main>

          <ReactModal
            isOpen={this.state.openClient}
            contentLabel="Report"
            portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                  {'Agent - ' + this.state.agentName+`'s`+' client(s)'}
                  </h5>
                  <button
                    type="button"
                    className="btn react-modal-close"
                    onClick={this.onCloseClientModal.bind(this)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="info__block">
                  {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                    return <p key={'client-name-'+index}>{client.client_name}</p>
                  })}
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </ReactModal>

        </div>
      </div>
    )
  }
}
