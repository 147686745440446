import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import _ from "lodash";
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import Modal from 'react-responsive-modal';
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class QuestionnairesCompleted extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')) : moment(),
      endDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')) : moment(),
      client_strategist:[],
      client_suburb:[],
      strategistDropdown: false,
      isLoadingData:true,
      suburbsDropDown:false,
      ad_tracking_codes: [],
      openStr: false,
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')) : moment(),
      defaultEndDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')) : moment()
    }
  }

  componentWillMount() {
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      strData.startDate = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.endDate = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.strategist_arr = this.state.client_strategist
      strData.suburbs = this.state.client_suburb
      this.props.getQuestionnairesCompletedClientList(strData)
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.QuestionnairesReportPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount() {
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ strategistDropdown: false });
        }
      }
    });
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn1").length) {
        if ($("#dropdownBtn1").is(":visible")) {
          self.setState({ suburbsDropDown: false });
        }
      }
    });
    // $(document).ready(function() {
    //   $('tbody').scroll(function(e) { //detect a scroll event on the tbody
    //     $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
    //     $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
    //     $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
    //   });
    // });
  }

  // handleFromDate(date) {
  //   this.setState({isLoadingData:true})
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     strData.startDate = moment(selectDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.endDate = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.strategist_arr = this.state.client_strategist
  //     strData.suburbs = this.state.client_suburb
  //     this.props.getQuestionnairesCompletedClientList(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date) {
  //   this.setState({isLoadingData:true})
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     strData.startDate = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.endDate = moment(selectDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.strategist_arr = this.state.client_strategist
  //     strData.suburbs = this.state.client_suburb
  //     this.props.getQuestionnairesCompletedClientList(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

      let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
      let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
      setTimeout(function(e){
        const strData = {}
        strData.startDate = start_from_date
        strData.endDate = end_to_date
        strData.strategist_arr = this.state.client_strategist
        strData.suburbs = this.state.client_suburb
        this.props.getQuestionnairesCompletedClientList(strData)
      }.bind(this), 300)
    })
  }

  number_format(x) {
    if(x){
     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }else{
      return x
     // eslint-disable-line camelcase
    }
   }

  toggledropDown(val){
    if(val==='filterStrategist'){
     this.setState({strategistDropdown: !this.state.strategistDropdown })
    }
  }

  toggleSuburbDropDown(val){
    if(val==='filterSuburbs'){
     this.setState({suburbsDropDown: !this.state.suburbsDropDown })
    }
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status , client_suburb } = this.state;
    if(type === 'strategistFilter'){
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } 
      else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_strategist : client_strategist })
    const strData = {}
    strData.startDate = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    strData.endDate = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    strData.strategist_arr = client_strategist
    strData.suburbs = client_suburb
    this.props.getQuestionnairesCompletedClientList(strData)
  }

  filterSuburbs(id ,type){
    let { client_strategist, client_status , client_suburb } = this.state;
    if(type === 'suburbsFilter'){
      if (client_suburb.indexOf(id) === -1) {
        client_suburb.push(id)
      } 
      else {
        client_suburb.splice(client_suburb.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_suburb : client_suburb })
    const strData = {}
    strData.startDate = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    strData.endDate = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    strData.strategist_arr = client_strategist
    strData.suburbs = client_suburb
    this.props.getQuestionnairesCompletedClientList(strData)
  }

  showFullText(data) {
    const codes = data.ad_tracking_codes ? data.ad_tracking_codes.split(',') : []
    this.setState({ clientName: data.client_name, openStr: true, ad_tracking_codes: codes })
  }

  onCloseStrategistModal() {
    this.setState({ clientName: '', ad_tracking_codes: [], openStr: false })
  }

  render() {
    const { QuestionnairesData, filterParams } = this.props
    var QuestionnairesExportData=[];
    var d = QuestionnairesData.data;
    let allData = filterParams && filterParams.data;
    let _user = cookies.get('user')
    if(d && d.length > 0){
      d.map((QuesD)=>{
        var questDObj={};
        questDObj.price_range = QuesD.price_min + ' - '+ QuesD.price_max
        if(QuesD.price_min == 0){
          questDObj.price_range = QuesD.price_min
        }
        questDObj.client_name = QuesD.client_name
        questDObj.completed_date = QuesD.completed_date
        questDObj.pipeline_stage = QuesD.pipeline_stage
        questDObj.source = QuesD.source
        questDObj.region = QuesD.suburbs.join(', ');
        questDObj.strategists = QuesD.strategists && QuesD.strategists.join(', ');
        questDObj.booked_how = QuesD.booked_by ? QuesD.booked_by : 'Created in Jungler'
        questDObj.ad_tracking_codes = QuesD.ad_tracking_codes
        QuestionnairesExportData.push(questDObj);
      })
    }

    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>New Clients</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            </div>

          <div className="row">
            <div className="col-lg-12"><h4 className="stragist-heading"><span>{(QuestionnairesData && QuestionnairesData.data && QuestionnairesData.data.length > 0) ? QuestionnairesData.data.length : 0}</span> New Clients</h4></div>


            <div className="col-lg-12">
            <div className="reports__flex">
            <div className="dp-wrapper dp-wrapper__minus">
             
             {
              (_user && _user.role === "superadmin" || _user && _user.role === "admin")?
                <div>
                  <div id="dropdownBtn" className={this.state.strategistDropdown ? "cust__hover custom-select custom-sel-filter custom-sel-filter-new" : "custom-select custom-sel-filter custom-sel-filter-new"} onClick={this.toggledropDown.bind(this, 'filterStrategist')}>All Strategist</div>
                  {this.state.strategistDropdown && 
                    <div id="dropdownBtn" className= "selouter__ext sel-outer selouter__strat list__hide--hor">
                      {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                       let selectedIndex = false;
                        _.find(this.state.client_strategist, function(o) {
                          if(o === strategistsval.id){
                            selectedIndex = true;
                          } 
                       });
                       return (
                          <div key={strategistsindx}>
                          {!_.includes([4943,7042,7100,5458,6801,6879,6878,5046,2407], strategistsval.id) ? 
                            (
                              <li className="custom-select-option">
                                <span  className="coverage__checkbox">
                                  <label className="custom-checkbox">
                                  {strategistsval.strategist_name}
                                  <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id, 'strategistFilter')}/>
                                    <span className="checkmark"></span>
                                  </label>
                                </span>
                              </li>
                            ): ''
                          }
                        </div>
                       )
                      })}
                    </div>
                  }
                </div>
                :''
             }
             </div>
             

             <div className="right__cont">

          
             {
              (_user && _user.role === "superadmin" || _user && _user.role === "admin")?
              <div className="questionarrie__sel">
                <div id="dropdownBtn1" className={this.state.suburbsDropDown ? "cust__hover custom-select custom-sel-filter custom-sel-filter-new" : "custom-select custom-sel-filter custom-sel-filter-new"} onClick={this.toggleSuburbDropDown.bind(this, 'filterSuburbs')}>All Suburbs</div>
                {this.state.suburbsDropDown && 
                  <div id="dropdownBtn1" className= "list__hide--hor sel-outer sel-outer__minus">
                    {allData && allData.suburbs.map((suburbsval, suburbsindx) => {
                     let selectedIndex = false;
                      _.find(this.state.client_suburb, function(o) {
                        if(o === suburbsval.id){
                          selectedIndex = true;
                        } 
                     });
                     return (
                        <div key={suburbsindx}>
                          <li className="custom-select-option">
                            <span  className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {suburbsval.name}
                              <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterSuburbs.bind(this,suburbsval.id, 'suburbsFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                      </div>
                     )
                    })}
                  </div>
                }
              </div>
              :''
             }
            

             <div className="datepicker__fix all__custom--wrapper">
                <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                {
                  (this.state.modalIsOpen) &&
                  <div>
                    <Modal2
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={customStyles}           
                      contentLabel="Example Modal">
                      <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                        <div className="modal-content">             
                          <div className="modal-body text-center">
                          <div className="calendar__header">
                            <h4>Date Range</h4>
                            <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                          </div>
                          <DateRange
                            onInit={this.handleSelect.bind(this)}
                            onChange={this.handleSelect.bind(this)}
                            startDate={this.state.fromDate}
                            endDate={this.state.endDate}
                          />
                          <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                          </div>               
                        </div>
                      </div>
                    </Modal2>
                  </div>
                }
              </div>

              {/*<div className="dp-wrapper dp-wrapper-edit">
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dp-wrapper dp-wrapper-edit">
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>*/}
              {QuestionnairesExportData && QuestionnairesExportData.length > 0 ?
                <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                    <ExcelSheet data={QuestionnairesExportData} name="Questionnaire_Report">
                        <ExcelColumn label="Client Name" value="client_name"/>
                        <ExcelColumn label="Pipeline Stage" value="pipeline_stage"/>
                        <ExcelColumn label="Price Range" value="price_range"/>
                        <ExcelColumn label="Region" value="region"/>
                        <ExcelColumn label="Strategists" value="strategists"/>
                        <ExcelColumn label="Booked How" value="booked_how"/>
                        <ExcelColumn label="Source" value="source"/>
                        <ExcelColumn label="Ad Tracking Codes" value="ad_tracking_codes"/>
                        <ExcelColumn label="Date Completed" value="completed_date"/>
                    </ExcelSheet>
                </ExcelFile>
                :''
               }
            </div> 
            </div>
            </div>

          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
            <div className="filter__container filter__container--users">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra" style={{ overflowX: 'hidden' }}>
                   <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Client Name</th>
                            <th>Pipeline Stage</th>
                            <th>Price Range</th>
                            <th>Region</th>
                            <th>Strategist Name</th>
                            <th>Booked How</th>
                            <th>Source</th>
                            <th>Ad Tracking Codes</th>
                            <th>Date Completed</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                          <tbody>
                            <tr>
                              <td colSpan="6" className="noClientResults text-center">
                              <img src="../../img/loader2.svg" />
                              <h5>Report is generating, please wait...</h5>
                              </td>
                            </tr>
                          </tbody>
                        :
                        <tbody>
                        {
                          QuestionnairesData && QuestionnairesData.data && QuestionnairesData.data.length > 0 ? QuestionnairesData.data.map((str, index)=>{
                            const ad = str.ad_tracking_codes ? str.ad_tracking_codes : ""
                            let priceRange = this.number_format(str.price_min)+'-'+this.number_format(str.price_max)
                            if(str.price_min == 0){
                              priceRange = this.number_format(str.price_min)
                            }
                            return(
                              <tr key={index} className="forcursor" >
                              <td>{str.client_name}</td>
                                <td>{str.pipeline_stage}</td>
                                <td>{priceRange}</td>
                                <td>{str.suburbs.join(", ")}</td>
                                <td>{str.strategists && str.strategists.join(", ")}</td>
                                <td>{str.booked_by ? str.booked_by : 'Created in Jungler'}</td>
                                <td>{str.source}</td>
                                <td>{ ad.length > 20 ? ad.substring(0, 19) : ad }
                                    {ad.length > 20 ? (
                                      <span className="view-more" onClick={this.showFullText.bind(this, str)}>View more</span>
                                    ) : ''}</td>
                                <td>{str.completed_date}</td>
                              </tr>
                            )
                          })
                        :
                          <tr>
                              <td colSpan="6" className="noClientResults text-center">
                              <h5>New clients record not found in this range</h5>
                              </td>
                          </tr>
                        }
                        </tbody> 
                        }
                      </table>
                     </div>
                   </div>
                 </div>
               </div>
              </main>
              {/*<div className="dashboard-footer text-center">
              Jungler | New Clients Reporting. 
              </div>*/}
            </div>
          </div>
        </div>
        <div>
          <Modal2 isOpen={this.state.openStr} onClose={this.onCloseStrategistModal.bind(this)} center className="widthofmodal">
            <div>
              <div className="flexdiv">
                <h4 className="popupheader__heading">{'Client - ' + this.state.clientName+`'s`}<span style={{display: "block"}}>ad tracking code(s)</span></h4>
                <div className="close__btn close_img--white" onClick={this.onCloseStrategistModal.bind(this)}><img src="img/close_btn.svg"/></div>
              </div>

              {/* <h2 className="modal-header margin-bottom-codes">{'Client - ' + this.state.clientName+`'s`} <span style={{display: "block"}}>ad tracking code(s)</span></h2> */}

              <div className="allpoppadding__paranew__div">
                {this.state.ad_tracking_codes && this.state.ad_tracking_codes.map((code, index) => {
                  return <div className="allpoppadding__paranew" key={'codes-'+code}>{code}</div>;
                })}
              </div>
            </div>
            <button className="savebtn__assign" onClick={this.onCloseStrategistModal.bind(this)}>Close</button>
          </Modal2>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'questionnairescompleted',  // a unique identifier for this form
  destroyOnUnmount: true,
})(QuestionnairesCompleted)