import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import Cookies from 'universal-cookie'
import styles from './styles.scss'

let timerOn = true;
const cookies = new Cookies()

class OtpVerify extends Component {
  constructor() {
    super();
    this.state = { 
      otp: '',
      err: {},
      number: '',
      minutes:0,
      seconds:0,
      otpResendMessage:''
    }
    this.timer = this.timer.bind(this)
}

componentWillReceiveProps(nextProps){
  if(nextProps.otpPhase === "success"){
    this.props.history.push('/clients')
  }
  if(nextProps.otpPhase === "error"){
    let err = {}
    err.otp = "OTP verification failed, Please enter a valid OTP."
    this.setState({ err })
  }
}

componentDidMount() {
  let user_mobile = this.props.location && this.props.location.state && this.props.location.state.mobile
  if(!user_mobile || user_mobile =='') {
   this.props.history.push('/login')
  }
  // else{
  //   let formateNumber = user_mobile && user_mobile.replace(/\D/g, '')
  //   let count = 3
  //   this.state.number = new Array(formateNumber.length - count+1).join('x')+formateNumber.slice(-count)
  //   this.setState({ number: this.state.number})
  // }
  this.timer(60)
}

handleChange(event){
  this.setState({ [event.target.name]: event.target.value })
}

otpChange(otpValue){
  const err = {}
  if(otpValue.length == 6){
    let data = {}
    data.phone = this.props.location && this.props.location.state && this.props.location.state.mobile
    data.otp   = otpValue
    data.country_code = this.props.location && this.props.location.state && this.props.location.state.country_code
    data.device_id = cookies.get('did')
    this.props.otpVerification(data)
    err.otp = ''
    this.setState({ err })
  }
}

timer(remaining) {
  var m = Math.floor(remaining / 120);
  var s = remaining % 120;
  
  m = m < 10 ? '0' + m : m;
  s = s < 10 ? '0' + s : s;
  this.setState({minutes: m, seconds:s})
  remaining -= 1;
  
  if(remaining >= 0 && timerOn) {
    setTimeout(function() {
        this.timer(remaining);
    }.bind(this), 1000);
    return;
  }

  if(!timerOn) {
    // Do validate stuff here
    return;
  }
  this.setState({minutes:0, seconds:0}, () => {

  })
  // Do timeout stuff here
}


resendBtn(){
  const { loginUserClient } = this.props
  const data = {}
  data.mobile= this.props.location && this.props.location.state && this.props.location.state.mobile
  data.login_type = "mobile"
  data.country_code = this.props.location && this.props.location.state && this.props.location.state.country_code
  loginUserClient(data)
  this.timer(60)
  this.setState({otpResendMessage: "OTP resend with text."})
}

render() {
    return (
      <div>
         <section className="login__container otp__page">
            <div className="LoignSection otpVerifySection">
              <div className="overlay-img"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="v-container">

                      <div className="login-box">
                        {/* <div className="close-btn" onClick={()=> {
                          window.history.back()
                          }}>
                          <img src="../img/login_close.svg"/>
                        </div> */}
                        <h3>Enter Code</h3>
                        <p> We just sent you a text with a code, please enter it
                            here to log into account.</p>   
                        <h4>ENTER 6 DIGIT CODE</h4>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="outer-input">
                              <OtpInput
                                onChange={this.otpChange.bind(this)}
                                numInputs={6}
                                isDisabled={this.state.seconds > 0 && this.state.seconds!=="00" ? false : true}
                              />
                                <div>
                                { this.state.err.otp && this.state.otpResendMessage=="" ?
                                <span className="error_field">
                                {this.state.err.otp}
                                </span> : '' }
                                </div>
                                <div>
                                { this.state.otpResendMessage ?
                                <span className="success_field">
                                {this.state.otpResendMessage}
                                </span> : '' }
                                </div>
                                
                            </div>
                          </div>                         
                        </div> 
                        {this.state.seconds > 0 && this.state.seconds!=="00" ?    
                        <button className="button__style" >Remaining Time: {this.state.minutes}: {this.state.seconds}</button>
                       :
                       <button type="submit" className="button__style" onClick={this.resendBtn.bind(this)}>Resend Code</button>
                        }    
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
      </section>
      </div>
    )
  }
}

export default reduxForm({
  form: 'otp-verify',  // a unique identifier for this form
  destroyOnUnmount: true,
})(OtpVerify)
