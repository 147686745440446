import React, { PureComponent /*, PropTypes*/ } from 'react';
import {
  CloseIcon,
  SortUpIcon,
  SortDownIcon,
  DeleteIcon
} from '../../../../../../../components/icons';
import ReactModal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, Space } from 'antd';
import ConfirmModal from '../../../../Common/ConfirmModal';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './styles.scss';

const { confirm } = Modal;

export default class CommuteTableComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addCommuteModal: false,
      commuteData: [],
      isLoading: false,
      selectedCommute: {
        scarsdale_type: null,
        from: '',
        to: '',
        morning: '',
        evening: ''
      },
      selectedCommuteInit: {
        scarsdale_type: null,
        from: '',
        to: '',
        morning: '',
        evening: ''
      },
      isEditMode: false,
      isDeleteModalOpen: false
    };
    this.openCommuteModal = this.openCommuteModal.bind(this);
  }

  componentDidMount() {
    this.props.toggleLoading(true);
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    this.setState({ townId });

    this.props.fetchCommute(townId);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let obj = {};
    if (get(nextProps, 'fetchCommutePhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.toggleLoading(false);
      obj.addCommuteModal = false;
      obj.commuteData = get(nextProps, 'commuteData.commute');
    }

    if (get(nextProps, 'deleteCommutePhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.fetchCommute(parseInt(state.townId));
      obj.addCommuteModal = false;
      obj.isDeleteModalOpen = false 
      obj.isLoading = false
    }

    if (get(nextProps, 'addCommutePhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.fetchCommute(parseInt(state.townId));
    }

    return obj;
  }

  openCommuteModal(selectedCommute, isEditMode) {
    this.setState({
      addCommuteModal: true,
      isEditMode: isEditMode ? isEditMode : false,
      selectedCommute: selectedCommute ? selectedCommute : this.state.selectedCommuteInit
    });
  }
  closeModal() {
    this.setState({ addCommuteModal: false });
  }

  deleteCommute = commute => {
    const { deleteCommute, toggleLoading } = this.props;
    let obj = { townId: this.state.townId, id: commute.id };
    this.setState({ isDeleteModalOpen: true, selectedCommuteObj: obj })
  };

  deleteCommuteConfirm = () => {
    const { deleteCommute, toggleLoading } = this.props;
    let obj = this.state.selectedCommuteObj
    toggleLoading(true)
    this.setState({ isLoading: true })
    deleteCommute(obj)
  }

  render() {
    const { commuteData, isLoading, isDeleteModalOpen, townId, selectedCommute, isEditMode } = this.state;

    const CommuteTableData = !isEmpty(commuteData) ? (
      commuteData.map((commute, i) => {
        return (
          <tr key={i}>
            <td>{get(commute, 'scarsdale_type')}</td>
            <td>{get(commute, 'from')}</td>
            <td>{get(commute, 'to')}</td>
            <td>{get(commute, 'morning')}</td>
            <td>{get(commute, 'evening')}</td>
            <td>
              <button
                onClick={() => this.openCommuteModal(commute, true)}
                className="btn btn__btn btn__link"
              >
                Edit
              </button>
            </td>
            <td onClick={() => this.deleteCommute(commute)}>
              <DeleteIcon />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6" className="blank__table__state">
          No data found.
        </td>
      </tr>
    );

    return (
      <div>
                <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deleteCommuteConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        <div className="row mb__20">
          <div className="col-xs-12 col-md-12 text-right">
            <button
              className="btn btn__btn btn-dark w__180"
              onClick={() => this.openCommuteModal(this.state.selectedCommuteInit)}
            >
              Add Commute
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table custom__table__simple">
            <thead>
              <tr>
                <th>
                  <span className="table-column-title">Type</span>
                  <span className="table-column-sorter">
                    <SortUpIcon />
                  </span>
                </th>
                <th>
                  <span className="table-column-title">From </span>
                </th>
                <th>
                  <span className="table-column-title">To</span>
                </th>
                <th>
                  <span className="table-column-title">Morning</span>
                </th>
                <th colSpan="2">
                  <span className="table-column-title">Evening</span>
                </th>
              </tr>
            </thead>
            <tbody>{CommuteTableData}</tbody>
          </table>
        </div>
        <ReactModal
          isOpen={this.state.addCommuteModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Add Public School"
          portalClassName="react-modal"
        >
          <Formik
            enableReinitialize={true}
            initialValues={selectedCommute}
            validationSchema={this.props.ValidationSchemas.commuteTableSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              this.props.toggleLoading(true);
              values._from = get(values, 'from');
              values._to = get(values, 'to');
              values.town_id = parseInt(this.state.townId);
              this.props.addCommute(values);
            }}
          >
            {({ values, errors, isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <div className="react-modal-dialog react-modal-dialog-centered">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">
                      {isEditMode ? 'Edit' : 'Add'} Commute
                    </h5>
                    <button
                      // disabled={isSubmitting}
                      type="button"
                      className="btn react-modal-close"
                      onClick={this.closeModal.bind(this)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        name="scarsdale_type"
                        as="select"
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        required
                      >
                        <option value selected disabled>
                          Select
                        </option>
                        <option value="Train">Train</option>
                        <option value="Bus">Bus</option>
                        <option value="Ferry">Ferry</option>
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Select Scarsdeal
                      </label>
                      <ErrorMessage className="invalid-feedback" name="scarsdale_type" component="div"/>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            name="from"
                            type="text"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            From
                          </label>
                          <ErrorMessage className="invalid-feedback" name="from" component="div"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            name="to"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            To
                          </label>
                          <ErrorMessage className="invalid-feedback" name="to" component="div"/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            name="morning"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Morning
                          </label>
                          <ErrorMessage className="invalid-feedback" name="morning" component="div"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            name="evening"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Evening
                          </label>
                          <ErrorMessage className="invalid-feedback" name="evening" component="div"/>
                        </div>
                      </div>
                    </div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn__btn btn__lg btn-dark w__100"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ReactModal>
      </div>
    );
  }
}
