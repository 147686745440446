/* eslint-disable no-undef */
import React, { PureComponent } from 'react'
import { get, isEmpty } from 'lodash'

import './styles.scss'
import EditBackgroundProfile from './partials/EditBackgroundProfile'
import AddBackgroundProfile from './partials/AddBackgroundProfile'
import { SUCCESS } from '../../../../../constants/phase'

export default class BackgroundProfileComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      clientData: [],
      backgroundProfile: "",
      addBackgroundProfile: false
    }
  }
  // change button view to create background profile view
  handleBackgroundProfile = () => {
    const { addBackgroundProfile } = this.state
    this.setState({ addBackgroundProfile: !addBackgroundProfile })
  }

  static getDerivedStateFromProps(props, state) {
    let states = {...state}
    // get data if background profile save
    if (props.sendBackgroundPhase === true) {
      const clientId = props.match.params.id && props.match.params.id
      // eslint-disable-next-line camelcase
      props.fetchClientById({ clientId })
      props.clearPhase()
      states = { ...state }
    }
    return states
  }

  render() {
    const { addBackgroundProfile } = this.state
    const { clientDetail, user } = this.props;
    const backgroundData = get(clientDetail, 'background_profile_data', {});
    const tmp = document.createElement("DIV");
    tmp.innerHTML = unescape(get(clientDetail, 'background_profile_data.bg_profile', ''));
    const htmlDoc = (tmp && tmp.textContent) || (tmp && tmp.innerText) || "";
    // eslint-disable-next-line no-nested-ternary
    const authorFullName = get(backgroundData, "author_full_name", false) ?
      backgroundData.author_full_name :
      // eslint-disable-next-line no-nested-ternary
      user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` :
        user.first_name ? user.first_name : '';
    const isHtmlDocExists = (isEmpty(htmlDoc) || (htmlDoc === "undefined") || (htmlDoc === "null"))
    return (
      <div>
        {isHtmlDocExists && !addBackgroundProfile && (
          <div className="background__profile__add">
              <button
                className="btn btn__btn btn-dark w__200"
                onClick={this.handleBackgroundProfile}
              >
                + Background Profile
              </button>
          </div>
        )}
        <div className="card none-shadow border-t-1">
          <EditBackgroundProfile
            htmlDoc={htmlDoc}
            addBackgroundProfile={addBackgroundProfile}
            clientDetail={clientDetail}
            authorFullName={authorFullName}
            backgroundData={backgroundData}
            sendBackgroundData={this.props.sendBackgroundData}
            clientId={get(this.props, "match.params.id")}
          />
          <AddBackgroundProfile
            addBackgroundProfile={addBackgroundProfile}
            clientDetail={clientDetail}
            isHtmlDocExists={isHtmlDocExists}
            authorFullName={authorFullName}
            sendBackgroundData={this.props.sendBackgroundData}
            clientId={get(this.props, "match.params.id")}
            handleBackgroundProfile={this.handleBackgroundProfile}
          />
        </div>
      </div>
    )
  }
}
