import { connect } from 'react-redux'
import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import {fetchProspectApplicants,fetchRejectedApplicants,approveApplicant,rejectApplicant} from '../../../../store/strategistV2/interview/actions'
import {getUSerProfileByID} from '../../../../store/dashboard/duck'

import AgentInterviewsComponent from './component'

const AgentInterviewsContainer = connect(
  // Map state to props
  (state) => ({
    prospectApplicants: state.strategistV2.agentInterview.prospectApplicants,
    rejectedApplicants: state.strategistV2.agentInterview.rejectedApplicants,
    fetchApplicantsPhase: state.strategistV2.agentInterview.fetchApplicantsPhase,
    applicantPhase: state.strategistV2.agentInterview.applicantPhase,
    isApprovedSuccess:state.strategistV2.agentInterview.isApprovedSuccess,
    isRejectedSuccess:state.strategistV2.agentInterview.isRejectedSuccess,
    rejectedApplicantsCount:state.strategistV2.agentInterview.rejectedApplicantsCount,
    prospectApplicantCount: state.strategistV2.agentInterview.prospectApplicantCount,
    userData: state.dashboard.userData,
  }),
  // Map actions to dispatch and props
  {
    fetchProspectApplicants,
    fetchRejectedApplicants,
    approveApplicant,
    rejectApplicant,
    getUSerProfileByID,
  }
)(AgentInterviewsComponent)

export default AgentInterviewsContainer
