/* eslint-disable no-nested-ternary */
import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Popover, Drawer } from 'antd'
import { get, upperFirst, size, isEmpty } from 'lodash'
import { NavbarIcon, SearchIcon, CloseIcon, BellIcon, DownCaretIcon, CalendarIcon, LeadsIcon } from '../../icons'
import Cookies from 'universal-cookie'
import axios from 'axios'

import './styles.scss'
import { SUCCESS } from '../../../constants/phase'
const cookies = new Cookies()
let user = ''

export default class AdministrationTopNavigationComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      mobileDrawer: false,
      firstName: '',
      lastName: '',
      firstNameUpper: '',
      lastNameUpper: '',
      userEmail: '',
      searchTerm: ''
    }
    this.userLogout = this.userLogout.bind(this)
    return null
  }
  componentDidMount = async () => {
    let firstName, lastName, userEmail
    if (!isEmpty(this.props.userData)) {
      this.props.getUSerProfileByID({ user_id: this.props._user.id }) // TODO: !!
      firstName = get(this.props, 'userData.first_name', '')
      lastName = get(this.props, 'userData.last_name', '')
      userEmail = get(this.props, 'userData.email', '')
    } else if (!isEmpty(this.props._user)) {
      firstName = this.props._user.first_name
      lastName = this.props._user.last_name
      userEmail = this.props._user.email
    } else {
      user = cookies.get('user')
      firstName = get(user, 'first_name', '')
      lastName = get(user, 'last_name', '')
      userEmail = get(user, 'email', '')
    }
    const firstNameUpper = firstName ? upperFirst(firstName.charAt(0)) : ''
    const lastNameUpper = lastName ? upperFirst(lastName.charAt(0)) : ''

    this.setState({
      firstName,
      lastName,
      firstNameUpper,
      lastNameUpper,
      timeZone: user.time_zone,
      userEmail,
    })
    // this.props.getPushNotification() 
  }
  static getDerivedStateFromProps(props, state) {
    if (get(props, 'junglerUserPhase', false) === SUCCESS || !isEmpty(props.userData)) {
      props.clearPhase()
     const firstName = get(props, 'userData.first_name', '')
     const lastName = get(props, 'userData.last_name', '')
     const userEmail = get(props, 'userData.email', '')
    const firstNameUpper = firstName ? upperFirst(firstName.charAt(0)) : ''
    const lastNameUpper = lastName ? upperFirst(lastName.charAt(0)) : ''
    props.clearPhase()
     return { ...state, firstName, lastName, userEmail, firstNameUpper, lastNameUpper }
    }
    return state
  }

  notificatonClose = () => {
    this.setState({
      visible: false
    })
  }

  handleVisibleChange = visible => {
    this.setState({ visible })
  }

  searchHandleChange = (e) => {
    if (size(e.target.value) > 2) {
      this.props.getSearchData(e.target.value)
      this.setState({ 
        searchTerm: e.target.value,
        showSearchList: true 
      })
    } else {
      this.setState({ 
        showSearchList: false 
      })
    }
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '' + match[1] + '.' + match[2] + '.' + match[3]
    }
    return null
  }

  userLogout(){
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }
    localStorage.clear()
    document.cookie ="Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    localStorage.removeItem("client_id")
    localStorage.removeItem("client_strategist")
    localStorage.removeItem("client_status")
    localStorage.removeItem("client_suburb")
    this.props.handleSignOut()
    this.props.history.push("/login")
  }

  closeNotification() {
    this.setState({ visible: false })
  }

  markAllAsRead(data, e){
    // this is send api data for mark all as read
    // this.props.markAsReadNotification({ "markData": data, user_id: user.id })
  }

  onListClick(search) {
    this.setState({ showSearchList: false })
    if (search && search.role !== 'client') {
      this.props.history.push(`/strategist/edit-user/${search.id}`)
    } else {
      this.props.history.replace({
        pathname: `/strategist/client-details/${search.id}`,
        state: { fetchClient: true, clientId: search.id, prevRoute: get(this.props, 'history.location.pathname', '') }
      })
    }
  }

  render() {
    const { firstName, lastName, firstNameUpper, lastNameUpper, userEmail } = this.state;
    const { searchUserClientData, notificationData } = this.props
    
    return (
      <Fragment>
        <header className="header topnavigation-layout-header">
          <div className="topnavigation-layout-search">
            <div className="topnavigation-layout-wrapper">
              <input
                className="form-control topnavigation-layout-input"
                placeholder="Search for a client by name, email, or phone number..."
                onChange={this.searchHandleChange}
              />
              {
                this.state.showSearchList &&
                <div className="search-wrapper-open-container">
                  <ul className="search-wrapper-options-menu">
                  {
                    searchUserClientData && searchUserClientData.map((search, idx)=>{
                      
                      const primaryCompany = get(search, 'UserClient.primary_company', false)
                      const secondaryCompany = get(search, 'UserClient.secondary_company', false)
                      const primaryCompanyMatch = primaryCompany ? this.state.searchTerm.toLowerCase().includes(primaryCompany.toLowerCase()) : false
                      const secondaryCompanyMatch = secondaryCompany ? this.state.searchTerm.toLowerCase().includes(secondaryCompany.toLowerCase()) : false
                      
                      return(
                        <li className="search-wrapper-options-menu-item" style={{ cursor: 'pointer' }} onClick={(e)=> this.onListClick(search)} key={idx}>
                          <Link to={'/'}>
                            <div className="options-menu-item-title">
                              <span className="avatar">
                              {
                                search.avatar ?
                                  <img src={search.avatar}/>
                                :
                                <img src="../img/img_v2/user-icon.png"/>
                              }
                              </span>
                              <span className="name">
                                {search.first_name+' '+search.last_name} <span className="text__transform">({search.role})</span>
                              </span>

                              <div className="email">{search.email ? search.email : ''}</div>
                              <div className="phone">{search.phone_mobile ? (search.phone_mobile.replace(/\D/g, '').length == 10) ? this.formatNumber(search.phone_mobile.replace(/\D/g, '')) : search.phone_mobile.replace(/\D/g, '') : ''}</div>
                              
                              {primaryCompanyMatch && (
                                <div className="email">Primary Contact Company: {primaryCompany}</div>  
                              )}

                              {secondaryCompanyMatch && (
                                <div className="email">Secondary Contact Company: {secondaryCompany}</div>  
                              )}

                            </div>
                          </Link>
                        </li>
                      )}
                    )
                  }
                  </ul>
                </div>
              }
            </div>
          </div>

          <div className="topnavigation-layout-notification ml__auto">
          {/*  <Popover
              trigger="click"
              visible={this.state.visible}
              onVisibleChange={this.handleVisibleChange}
              className="administration__notificaton__modal"
              overlayClassName="administration__notificaton__modal__overlay"
              content={
                <div className="administration__notificaton__container">
                  <div className="notificaton__container__header">
                    <div className="notificaton__container__content">
                      Notifications
                    </div>
                    <button onClick={this.closeNotification.bind(this)} className="btn btn__close__notify"><CloseIcon /></button>
                  </div>
                  <div className="notificaton__container__body">
                    {
                      (_.size(notificationData && notificationData.data) > 0) ?
                      <div className="text-right">
                        <button
                          className="btn btn__clear_all"
                          onClick={this.markAllAsRead.bind(this, notificationData.data)}>Mark All As Read
                        </button>
                      </div>
                      :
                      <div className="notificaton__blank__text">No Notifications</div>
                    }
                    <ul className="notificaton__list">
                      {
                        (_.size(notificationData && notificationData.data) > 0) &&
                        notificationData && notificationData.data && notificationData.data.map((val, i)=>{
                          return (
                            <Fragment key={i}>
                              {
                                (val.type === 1 && val.is_read === 0) ?
                                <li className="active" key={i}>
                                  <div className="notificaton__item">
                                    <div className="notificaton__title">You have a message from <strong>test</strong> test <strong>sbj</strong></div>
                                      <div className="notificaton__card">
                                        dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds dvdsvds
                                      </div>
                                    <div className="notificaton__delivery">Feb 6th 2020 11:57 am</div>
                                  </div>
                                </li>
                                :
                                (val.type === 2 && val.is_read === 0) ?
                                <li key={i}>
                                  <div className="notificaton__item">
                                    <div className="notificaton__title">Agent <strong>Agent Name</strong> left a chat message from <strong>client1</strong></div>
                                    <div className="notificaton__card">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                      </div>
                                    <div className="notificaton__delivery">Feb 6th 2020 11:57 am</div>
                                  </div>
                                </li>
                                :
                                (val.type === 3 && val.is_read === 0) &&
                                <li className="active" key={i}>
                                  <div className="notificaton__item">
                                    <div className="notificaton__title">Agent <strong>agnet name</strong> schedule a <strong>appointment type</strong> with the client <strong>client_name</strong> for <strong>date_time</strong></div>
                                    <div className="notificaton__delivery">Feb 6th 2020 11:57 am</div>
                                  </div>
                                </li>
                              }
                            </Fragment>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              }
            >
              <div className="chat-notificaton">
                {
                  (_.size(notificationData && notificationData.data) > 0) &&
                  <span className="chat-notificaton-count">{_.size(notificationData && notificationData.data)}</span>
                }
                <BellIcon />
              </div>
            </Popover>*/}
          </div>

          <div className="topnavigation-layout-divider"></div>
          <div className="topnavigation-layout__profile">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={'/administration/update-profile'}>Edit Profile</Link>
                  </Menu.Item>
                  <Menu.Item onClick={()=> this.userLogout()} key="2">
                    Logout
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              overlayClassName="profile--dropdown--overlay"
            >
              <a className="ant-dropdown-link layout-profile-link" href="#">
                <div className="avatar">
                  {
                    (!isEmpty(get(this.props.userData, 'avatar', ''))) ?
                      <img src={get(this.props.userData, 'avatar', '')} alt=""/>
                      :
                      <span className="avatar-string">{`${firstNameUpper}${lastNameUpper}`}</span>
                  }
                </div>
                <div className="avatar-info">
                  <div className="avatar-name">
                    {`${firstName} ${lastName}`}
                  </div>
                  <div className="avatar-email">{userEmail}</div>
                </div>
                <DownCaretIcon />
              </a>
            </Dropdown>
          </div>
        </header>
      </Fragment>
    )
  }
}
