export const GET_ADMINISTRATION_DASHBOARD_DATA =
  'suburban/strategistV2/strategist/GET_ADMINISTRATION_DASHBOARD_DATA'
export const GET_ADMINISTRATION_DASHBOARD_DATA_SUCCESS =
  'suburban/strategistV2/strategist/GET_ADMINISTRATION_DASHBOARD_DATA_SUCCESS'
export const GET_ADMINISTRATION_DASHBOARD_DATA_ERROR =
  'suburban/strategistV2/strategist/GET_ADMINISTRATION_DASHBOARD_DATA_ERROR'

export const CLOSED_CLIENT_PER_TOWN = 'suburban/strategistV2/strategist/CLOSED_CLIENT_PER_TOWN'
export const CLOSED_CLIENT_PER_TOWN_SUCCESS =
  'suburban/strategistV2/strategist/CLOSED_CLIENT_PER_TOWN_SUCCESS'
export const CLOSED_CLIENT_PER_TOWN_ERROR =
  'suburban/strategistV2/strategist/CLOSED_CLIENT_PER_TOWN_ERROR'

export const GET_ACCOUNTING_REPORT = 'suburban/strategistV2/strategist/GET_ACCOUNTING_REPORT'
export const GET_ACCOUNTING_REPORT_SUCCESS =
  'suburban/strategistV2/strategist/GET_ACCOUNTING_REPORT_SUCCESS'
export const GET_ACCOUNTING_REPORT_ERROR =
  'suburban/strategistV2/strategist/GET_ACCOUNTING_REPORT_ERROR'

export const GET_USER_BY_ID = 'suburban/strategistV2/strategist/GET_USER_BY_ID'
export const GET_USER_BY_ID_SUCCESS = 'suburban/strategistV2/strategist/GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_ERROR = 'suburban/strategistV2/strategist/GET_USER_BY_ID_ERROR'

export const GET_CLIENT_STRATEGIST_LIST =
  'suburban/strategistV2/strategist/GET_CLIENT_STRATEGIST_LIST'
export const GET_CLIENT_STRATEGIST_LIST_SUCCESS =
  'suburban/strategistV2/strategist/GET_CLIENT_STRATEGIST_LIST_SUCCESS'
export const GET_CLIENT_STRATEGIST_LIST_ERROR =
  'suburban/strategistV2/strategist/GET_CLIENT_STRATEGIST_LIST_ERROR'

export const ADD_EDIT_STRATEGIST_CLIENT =
  'suburban/strategistV2/strategist/ADD_EDIT_STRATEGIST_CLIENT'
export const ADD_EDIT_STRATEGIST_CLIENT_SUCCESS =
  'suburban/strategistV2/strategist/ADD_EDIT_STRATEGIST_CLIENT_SUCCESS'
export const ADD_EDIT_STRATEGIST_CLIENT_ERROR =
  'suburban/strategistV2/strategist/ADD_EDIT_STRATEGIST_CLIENT_ERROR'

export const CREATE_STRATEGIST =
  'suburban/strategistV2/strategist/CREATE_STRATEGIST_API_ON_NEW_SERVER'
export const CREATE_STRATEGIST_SUCCESS =
  'suburban/strategistV2/strategist/CREATE_STRATEGIST_API_ON_NEW_SERVER_SUCCESS'
export const CREATE_STRATEGIST_ERROR =
  'suburban/strategistV2/strategist/CREATE_STRATEGIST_API_ON_NEW_SERVER_ERROR'

export const CHANGE_CLIENT_STATUS =
  'suburban/strategistV2/strategist/CHANGE_CLIENT_STATUS_API_ON_NEW_SERVER'
export const CHANGE_CLIENT_STATUS_SUCCESS =
  'suburban/strategistV2/strategist/CHANGE_CLIENT_STATUS_API_ON_NEW_SERVER_SUCCESS'
export const CHANGE_CLIENT_STATUS_ERROR =
  'suburban/strategistV2/strategist/CHANGE_CLIENT_STATUS_API_ON_NEW_SERVER_ERROR'

export const CLEAR_STRATEGIST_PHASE = 'suburban/strategistV2/strategist/CLEAR_STRATEGIST_PHASE'
export const GET_STRATEGIST_AVAILLABLE_DATES =
  'suburban/strategistV2/strategist/GET_STRATEGIST_AVAILLABLE_DATES'

  export const GET_BDSOURCE_REPORT = 'suburban/strategistV2/strategist/GET_BDSOURCE_REPORT'
  export const GET_BDSOURCE_REPORT_SUCCESS =
    'suburban/strategistV2/strategist/GET_BDSOURCE_REPORT_SUCCESS'
  export const GET_BDSOURCE_REPORT_ERROR =
    'suburban/strategistV2/strategist/GET_BDSOURCE_REPORT_ERROR'
