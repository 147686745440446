import React from 'react'
import { get, map, forEach } from 'lodash'
import { Field, ErrorMessage } from 'formik'

export default function SuburbsCheckboxList({ 
  towns, 
  townDataOption, 
  // priceBracketDataOption, 
  setFieldValue, 
  selectedRole,
  getTownsOptionBySuburbs
 }) {


  const handleSelectionSuburbs = async (townId, seletctedtowns, role, setFieldValue) => {
    let towns = seletctedtowns;
    if (towns.includes(parseInt(townId))) {
      towns = towns.filter(town => town !== parseInt(townId))
    } else {
      towns.push(parseInt(townId))
    }
    if (role === 'agent') {
      const townsOptions = await getTownsOptionBySuburbs({ suburb_arr: towns })
      if (get(townsOptions, 'value.data', false)) {
        const searchTownOption = map(get(townsOptions, 'value.data', []), town => {
          return { label: town.name, value: town.id }
        })
        setFieldValue('searchTownOption', searchTownOption)
      }
    }
    setFieldValue('towns', towns)
  }

  return (
    <div className="form-group">
      <label className="label__text__sm">Metro Areas<span className="text-danger">*</span></label>
      <ul className="checkbox__list checkbox-list-grid">
        {map(townDataOption, (town, index) => (
          <li key={index}>
            <div className="form-group">
              <label className="custom__checkbox">
                <Field
                  type="checkbox"
                  id={`town_${town.value}`}
                  className="custom__checkbox__input"
                  checked={towns.includes(town.value)}
                  onClick={() => { handleSelectionSuburbs(town.value, towns, selectedRole,  setFieldValue) }}
                  required
                />
                {town.label}
                <span className="custom__checkmark"></span>
              </label>

              {/* towns.includes(town.value) &&

                <div class="strategist_price_range">
                  <label className="label__strategist_price_min">
                    Minimum
                    <Field
                      as="select"
                      className="form-control selectfield-input-sm"  
                      name={`town_${town.value}_min`}
                      onChange={(e) => { handleMetroAreaAndStrategistAssignmentRuleChanges(town.value, towns, 'min', e.target.value, setFieldValue) }}
                      required
                    >
                      <option value="">-</option>
                      {priceBracketDataOption.price_min.map((pm, idx) => {
                        const rules = strategistAssignmentRules.filter(r => town.value == r.suburb_id)
                        const rule = rules.length ? rules.pop() : false
                        return (
                          <option key={idx} value={pm} selected={rule.min === pm}>{`$${pm.toLocaleString()}`}</option>
                        )
                      })}

                    </Field>                  
                  </label>
                  <br/>
                  <label className="label__strategist_price_min">
                    Maximum
                    <Field
                      as="select"
                      className="form-control selectfield-input-sm"
                      name={`town_${town.value}_max`}
                      onChange={(e) => { handleMetroAreaAndStrategistAssignmentRuleChanges(town.value, towns, 'max', e.target.value, setFieldValue) }}
                      required
                    >
                      <option value="">-</option>
                      {priceBracketDataOption.price_max.map((pm, idx) => {
                        const rules = strategistAssignmentRules.filter(r => town.value == r.suburb_id)
                        const rule = rules.length ? rules.pop() : false
                        return (
                          <option key={idx} value={pm} selected={rule.max === pm}>{`$${pm.toLocaleString()}`}</option>
                        )
                      })}

                    </Field>     
                  </label>                
                </div>
              */}
            </div>
          </li>
        ))}
      </ul>

      <ErrorMessage className="invalid-feedback" name="towns" component="div" />
      
      <ErrorMessage className="invalid-feedback" name="strategistAssignmentRules" component="div" />
      
    </div>
  )
}
