import { connect } from 'react-redux'

import NoteInternalComponent from './component'

const NoteInternalContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(NoteInternalComponent)

export default NoteInternalContainer
