import React, { useState } from 'react'

export default function SelectPageResult({ handlePageChange }) {

  let limit = 20
  const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
  if (localclientdata && localclientdata !== null && localclientdata !== '') {
    limit = localclientdata.pageLimit ? localclientdata.pageLimit : 20
  }

  const [resultEntry, setResultEntry] = useState(limit);
  
  const handleResultChange = ({ target }) => {
    handlePageChange(parseInt(target.value))
    setResultEntry(parseInt(target.value))
  }

  return (
    <div className="material-textfield">
      <select
        className="form-control custom-select material-textfield-input results__select"
        onChange={handleResultChange}
        defaultValue={resultEntry}
      >
        <option key={20} value={20} >20</option>
        <option key={50} value={50} >50</option>
        <option key={100} value={100} >100</option>
        <option key={250} value={250} >250</option>
        <option key={500} value={500} >500</option>
        <option key={1000} value={1000} >1000</option>
        <option key={2000} value={2000} >2000</option>
        <option key={3000} value={3000} >3000</option>
        <option key={4000} value={4000} >4000</option>
        <option key={5000} value={5000} >5000</option>
        <option key={6000} value={6000} >6000</option>
        <option key={7000} value={7000} >7000</option>
      </select>
      <label className="material-textfield-label">Results</label>
    </div>
  )
}
