import { connect } from 'react-redux'

import {
  addClientStrategistTimeSheet,
  clearClientDetailPhase,
} from '../../../../../store/strategistV2/clientdetail/action'

import TimerComponent from './component'

const TimerContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientStrategistTimeSheet: state.strategistV2.clientDetail.clientStrategistTimeSheet,
    addClientStrategistTimeSheetPhase:
      state.strategistV2.clientDetail.addClientStrategistTimeSheetPhase,
  }),
  // Map actions to dispatch and props
  {
    addClientStrategistTimeSheet,
    clearClientDetailPhase,
  }
)(TimerComponent)

export default TimerContainer
