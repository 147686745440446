import 'rxjs'
import React, { Component } from 'react'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import $ from 'jquery'
import styles from './styles.scss'
import moment from 'moment'
import timezone from 'moment-timezone';
import Cookies from 'universal-cookie'
import _ from 'lodash'
const cookies = new Cookies()
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      _clientID: '',
      teamName: "",
      emailDataisLoading:true,
      redirect: false,
      id: ""
    }
  }


  componentWillMount(){
    const _user = cookies.get('user')
    if(_user) {
      let _clientid = this.props.match.params.id && this.props.match.params.id
      this.setState({_clientID: _clientid},()=> {})  
      if(_clientid && _clientid!=''){
        const clntdata = {}
        clntdata.client_id = _clientid;
        this.props.getClientEmail(clntdata) 
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.dataPhase && nextProps.dataPhase == 'success'){
      this.props.clearPhase()
    }

    if(nextProps && nextProps.clientEmaildata && nextProps.clientEmaildata.status){
      this.setState({emailDataisLoading: false})
    }
    
    if(nextProps && nextProps.clientEmaildata && nextProps.clientEmaildata.status ===false){
      this.setState({emailDataisLoading: false}) 
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      singleClientData,
      clientEmaildata,
      location,
      token,
      phase
    } = this.props
    var emailsListData = clientEmaildata && clientEmaildata.data;
    emailsListData = _.uniqBy(emailsListData, 'modified');
    let i = 0;
    //5498
    return (
    <div>
    {this.state.emailDataisLoading ?
      <main className="clients__emails--container clients__emails--noborder">
        <div className="table-responsive table-responsive-edit text-center">
            <img src="../../img/loader2.svg" />
        </div>
      </main>
      :
      <main className="clients__emails--container clients__emails--noborder">
      {emailsListData && emailsListData.length > 0 ? emailsListData.map((email, emailindex) => { 
        i++
        let emailSubHeading = email.mail_subject
        let arrayEmailstr = email.note.split('<body');
        let removedTags = email.note;
        if(arrayEmailstr.length > 1){
        let tempRemovetags = '<body' + email.note.split('<body')[1]
        removedTags = tempRemovetags.replace('</html>', '')
        }
        return(
          <div>
            {
              (email.author_id != 0 && email.note !== '')?
                <div key={emailindex} className="panel-group" data={i} id={"accordion-"+i} role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id={"heading-"+i}>
                    <h4 className="panel-title">
                      <a role="button" className="accordion-toggle" data-toggle="collapse" data-parent={"#accordion-"+i} href={"#collapse-"+i} aria-expanded="false" aria-controls={"collapse-"+i}>
                        {/* add class 'collapsed' in above to close by default */}
                        <div className="emails__list--wrapper">
                          <div className="emails__profile">
                            {/* <div className="emails__profile--avtar">
                              <img src={email.user_avatar ?email.user_avatar :'img/login-bg-img.png'}/>
                            </div> */}
                            <div  className="emails__profile--name">{email.from_name ? email.from_name : email.author_full_name}</div>
                          </div>
                          <div className="emails__profile--subject">
                           {emailSubHeading}
                          </div>
                          <div className="emails__time">{moment(email.modified).format('llll')} ({moment(email.modified).fromNow()})</div>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div id={"collapse-"+i} className='panel-collapse collapse in' role="tabpanel" aria-labelledby={"heading-"+i}>
                    {/* remove class 'in' in above to close by default */}
                    <div className="panel-body panelbody__edit">
                    {ReactHtmlParser(removedTags)}
                    </div>
                  </div>
                </div>
              </div>
              :''
            }
          </div>
        )
      })
      : <div className="noEmailRecords">
          This is where you would see a history of the emails sent to the client and received from the client.<br/>
          There are no email records synced into the Jungler as of yet.<br/>
          Please check back later.
        </div>
      }
      </main>
      }
    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
