import React, { Component, Fragment } from 'react'
import { Drawer } from 'antd'
import { map, find, get, orderBy, isEmpty } from 'lodash'
import ReactModal from "react-modal"
import Cookies from 'universal-cookie'
import TimeSelector from '../TimeSelector/component'
import moment from 'moment'

const cookies = new Cookies()
class TimeAvailabilityDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAvailabilitySelector: false,
      availabilityTimes: []
    }
  }
  // get availablity list to show default
  componentDidMount() {
    const user = cookies.get('user')
    this.props.getAgentTimeSlotAvailability({
      // eslint-disable-next-line camelcase
      user_id: user.id
    })
  }
  static getDerivedStateFromProps(props, state) {
    // on delete appointment show success message and clear phase for new props
    if (props.getAgentTimeAvailabilityPhase === "success") {
      const data = get(props, "agentTimeAvailability", [])
      const availabilityTimes = map(data, selection => {
        const availabilities = selection.availability ? selection.availability : []
        const availability = map(availabilities, availability => {
          return {
            id: availability.id,
            start: moment(availability.start, "H:mm").format('HH:mm'),
            end: moment(availability.end, "H:mm").format('HH:mm')
          }
        }
        )
        return { day: get(selection, "day", ""), availability }
      })
      // clear phase action remove deleteAppointmentPhase success phase
      props.clearPhase()
      return { availabilityTimes }
    }
    return props;
  }
  // show hide Availability selctor model
  toogleAvailabilitySelectorModel = () => {
    const { showAvailabilitySelector } = this.state
    this.setState({ showAvailabilitySelector: !showAvailabilitySelector })
  }
  // show hide vailability for showing week details
  toggleTimeAvailability = (selectedDay, day) => {
    const initialEditTime = find(this.state.availabilityTimes, { day: day.toString() })
    this.setState({ showAvailabilitySelector: true, selectedDay, initialEditTime })
  }
  // save availability selection
  saveAvailabilitySelection = (selections, day) => {
    const selectedTime = map(selections, selection => {
      return {
        start: moment(selection.start).utc().format('HH:mm'),
        end: moment(selection.end).utc().format('HH:mm')
      }
    })
    const { availabilityTimes } = this.state
    // find day is present or not
    if (!find(availabilityTimes, { day })) {
      // if not present instert it
      availabilityTimes.push({ day, times: selectedTime })
    }
    // make new state for update currenet selection dates
    const finalResult = map(availabilityTimes, at => {
      return at.day === day ? { day, times: selectedTime } : at
    })
    this.setState({ availabilityTimes: finalResult, showAvailabilitySelector: false })
  }
  printTimeAvailability = (day) => {
    const selectedDay = find(this.state.agentTimeAvailability, { day })
    const times = get(selectedDay, "availability", [])
    if (!isEmpty(times)) {
      return map(orderBy(times, ['start'], ['asc']), (time, index) => {
        const start = moment.utc(time.start, "HH:mm").format("hh:mm a")
        const end = moment.utc(time.end, "HH:mm").format("hh:mm a")
        return <li key={index}>{start} - {end}</li>
      })
    }
    return <li>No Times Selected</li>
  }
  render() {
    const { toggleUpdateAvailability, visible } = this.props
    const { showAvailabilitySelector, selectedDay, initialEditTime } = this.state
    return (
      <Fragment>
        <ReactModal
          isOpen={showAvailabilitySelector}
          onRequestClose={this.toogleAvailabilitySelectorModel}
          portalClassName="react-modal"
        >
          <TimeSelector
            {...this.props}
            selectedDay={selectedDay}
            initialEditTime={initialEditTime}
            saveAvailabilitySelection={this.saveAvailabilitySelection}
            toogleAvailabilitySelectorModel={this.toogleAvailabilitySelectorModel}
          />
        </ReactModal>
        <Drawer
          title="At which times are you available for calls?"
          placement="right"
          closable={true}
          width={574}
          onClose={toggleUpdateAvailability}
          visible={visible}
          className="time__availability__drawer"
        >
          <div className="time__available">

            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Mondays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Monday", 1)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("1")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Tuesdays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Tuesday", 2)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("2")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Wednesdays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Wednesday", 3)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("3")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Thursdays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Thursday", 4)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("4")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Fridays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Friday", 5)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("5")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Saturdays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Saturday", 6)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("6")}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Sundays</h5>
                <div className="ml__auto">
                  <button className="btn btn__btn btn-dark w__150" onClick={() => this.toggleTimeAvailability("Sunday", 0)}>Edit Time</button>
                </div>
              </div>
              <div className="card-body card-body-sm">
                <ul className="time__availability__list">
                  {this.printTimeAvailability("0")}
                </ul>
              </div>
            </div>
            {/* <div className="ml__auto">
              <button className="btn btn__btn btn-dark w__150">Save</button>
            </div> */}
          </div>
        </Drawer>
      </Fragment>
    )
  }
}

export default TimeAvailabilityDrawer;
