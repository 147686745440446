import { connect } from 'react-redux'

import OtherComponent from './component'

import {
  fetchClientById,
  fetchClientStrategistBDList,
  fetchStrategistBDList,
  clearClientPhase,
  clearReviewPhase,
  addUpdateClient,
  createBizDev,
  fetchStrategistDevBiz,
  createRefferClients,
} from '../../../../../store/strategistV2/clients/action'

const OtherContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    clientStrategistBDList: state.strategistV2.client.clientStrategistBDList,
    strategistBDList: state.strategistV2.client.strategistBDList,
    fetchClientStrategistBDPhase:
      state.strategistV2.client.fetchClientStrategistBDPhase,
    fetchStrategistBDPhase: state.strategistV2.client.fetchStrategistBDPhase,
    createBizDevPhase: state.strategistV2.client.createBizDevPhase,
    addUpdateClientPhase: state.strategistV2.client.addUpdateClientPhase,
    fetchClientByIdPhase: state.strategistV2.client.fetchClientByIdPhase,
    fetchReviewPhase: state.strategistV2.client.fetchReviewPhase,
    createRefferingClientPhase: state.strategistV2.client.createRefferingClientPhase,
    devBizList: state.strategistV2.client.devBizList,
    fetchStrategistBizDevPhase: state.strategistV2.client.fetchStrategistBizDevPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientById,
    fetchStrategistDevBiz,
    fetchClientStrategistBDList,
    fetchStrategistBDList,
    addUpdateClient,
    createRefferClients,
    clearReviewPhase,
    createBizDev,
    clearClientPhase,
  }
)(OtherComponent)

export default OtherContainer
