/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import './styles.scss'
import Cookies from 'universal-cookie'
import moment from 'moment'
import io from 'socket.io-client'
import { CloseIcon } from '../../../../../../components/icons'
import ReactModal from 'react-modal'
import axios from 'axios'

const SOCKET_HOSTNAME = process.env.SUBURBANJUNGLE_API_HOST
let socket = io.connect(SOCKET_HOSTNAME, {
  transports: ['websocket', 'polling']
})
const cookies = new Cookies()
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2

export default class StrategistMessageComponent extends PureComponent {
  editableRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      strategistChatData: [],
      chatText: '',
      loginUserId: 0,
      type: "add",
      deleteMsgModal: false,
      chatId: 0,
      clientId: 0,
      viewMore: false,
      editChat: '',
      editable: false,
      clientsNotes: [],
      chatData: []
    }
  }

  componentDidMount() {
    const loginUser = cookies.get('user')
    const { match: { params: { id } }, clientData } = this.props
    if (loginUser) {
      this.setState({ loginUserId: loginUser.id, clientId: parseInt(id) })
      const data = {
        sender_id: loginUser.id,
        client_id: parseInt(id)
      }
      this.props.getAgentChat(data)
    }

    //chat agent and strategist chat behalf of client
    socket.on('client_message', (data) => {

      if (data) {
        const _r = _.filter(data.receiverIds, (r) => {
          return parseInt(r) === this.state.loginUserId
        })
        let isMyChat = false
        if (data.sender_id === this.state.loginUserId && parseInt(id) === parseInt(data.client_id)) {
          isMyChat = true
        } else if (parseInt(id) === parseInt(data.client_id) && _r && _.size(_r)) {
          isMyChat = true
        }
        if (isMyChat) {
          this.setState({ chatText: '' })
          var chat = this.state.strategistChatData
          chat.unshift(data)
          this.setState({ strategistChatData: _.cloneDeep(chat) })
          this.forceUpdate()
        }
      }
    })
  }

  componentDidUpdate(preProps) {
    if (this.props.editStrAgentPhase === "success") {
      this.setState({ editChat: '', chatId: 0, editable: false, chatData: [] })
      const data = {
        sender_id: this.state.loginUserId,
        client_id: this.state.clientId
      }
      this.props.getAgentChat(data)
      this.props.clearAgentPhase()
    }
    if (this.props.deletStrAgentPhase === "success") {
      const data = {
        sender_id: this.state.loginUserId,
        client_id: this.state.clientId
      }
      this.props.getAgentChat(data)
      this.props.clearAgentPhase()
    }
    if (this.props.agentPhase === "success") {
      this.setState({ editChat: '', chatId: 0, editable: false, chatData: [] })
      this.props.clearAgentDatePhase()
      const data = {
        sender_id: this.state.loginUserId,
        client_id: this.state.clientId
      }
      this.props.getAgentChat(data)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.strategistChatData !== _.get(props.getAgentChatData, 'data', [])) {
      return { strategistChatData: _.get(props.getAgentChatData, 'data', []), clientsNotes: _.get(props.getAgentChatData, 'clients_notes', []) }
    }
    return null;
  }

  getNotesData() {
    const { match: { params: { id } } } = this.props
    setTimeout(() => {
      this.props.getAgentChat({ sender_id: this.state.loginUserId, client_id: parseInt(id) })
    }, 1000)
  }

  handleKeyDown = (e) => {
    e.persist()
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }
  handleKeyUp = (e) => {
    e.persist()
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  clientChat() {
    if (this.editableRef) {
      this.editableRef.style.height = `130px`
    }
    const { match: { params: { id } } } = this.props
    if (this.state.chatText !== '') {
      const data = {}
      let strategistData = []
      const str = this.props && this.props.clientData && this.props.clientData.users && this.props.clientData.users.strategists
      if (_.size(str) > 0) {
        strategistData = _.map(this.props.clientData.users.strategists, user => {
          return user.strategist_id
        })
      }
      const user = cookies.get('user')
      data.receiverIds = strategistData
      data.receiver_type = "strategist"
      data.sender_id = user.id
      data.sender_type = "agent"
      data.type = "text"
      data.client_id = id
      data.message = this.state.chatText
      socket.emit('client_message', data)
      this.setState({ chatText: '', type: "add", chatId: 0, editable: false })
    }
  }

  editClick(e, data) {
    this.setState({ editChat: data.message, chatId: data.chat_id, type: "edit", editable: true, chatData: data })
  }

  deleteClick(e, data) {
    this.setState({ deleteMsgModal: true, chatId: data.chat_id })
  }

  closeModal = () => {
    this.setState({ deleteMsgModal: false, chatId: 0 })
  }

  dltMsgHandle(e) {
    this.props.deleteStrategistAgentNote({
      clientId: this.state.clientId,
      chatId: this.state.chatId 
    })
    this.setState({ deleteMsgModal: false })
  }

  viewMoreButton(e) {
    this.setState({ viewMore: !this.state.viewMore })
  }

  closeIconClick(e) {
    this.setState({ editChat: '', chatId: 0, type: "add", editable: false })
  }

  //edit save for internal notes and strategist note
  editSave() {
    const { chatData } = this.state
    if (this.state.editChat !== '') {
      //this is for internal note edit
      if (chatData.typeNotes) {
        let data = {
          internal_message: this.state.editChat,
          noteId: this.state.chatId
        }
        //internal note props here
        this.updateInternalNote(data)
      } else {
        //this is for strategist note
        this.props.editStrategistAgentNote({ 
          clientId: this.state.clientId,  
          message: this.state.editChat, 
          chatId: this.state.chatId 
        })
      }
    }
  }

  async updateInternalNote(data) {
    const { match: { params: { id } } } = this.props
    if (data.internal_message !== '') {
      const noteData = {
        note_id: data.noteId,
        client_id: parseInt(id),
        note: data.internal_message
      }
      this.props.sendAgentNote(noteData)
    }
  }

  render() {
    const { strategistChatData, loginUserId, editChat, clientsNotes } = this.state
    const { clientData } = this.props
    const loginUser = cookies.get('user')

    const strategists = clientData && clientData.users && clientData.users && clientData.users.strategists

    const strategistsFullNameArr = strategists ? strategists.map((strategist) => {
      return strategist.strategist_full_name
    }) : []

    const finalStrategistName = strategistsFullNameArr && strategistsFullNameArr.length > 1 ? strategistsFullNameArr.pop() : undefined

    const strategistsFullNameStr = (_.size(strategistsFullNameArr) > 0) ? strategistsFullNameArr.join(', ') : ''

    let clientFullName = ''
    if (clientData && clientData.users) {
      clientFullName = `${_.get(clientData.users, 'first_name', '')} ${_.get(clientData.users, 'last_name', '')}`;
    }
    let _strategistChatData = _.filter(strategistChatData, (c) => {
      const rec = c.receiverIds && _.size(c.receiverIds) ? c.receiverIds : []
      const _r = _.filter(rec, (r) => {
        return parseInt(r) === loginUserId
      })
      const senderId = c.sender_id
      return (senderId === loginUserId || (_r && _.size(_r)))
    })
    let _clientsNotes = _.filter(clientsNotes, (n) => {
      return n.author_id === loginUserId
    })
    _clientsNotes = _clientsNotes && _.size(_clientsNotes) ? _clientsNotes : []

    if (loginUser && loginUser.role !== 'local') {
      _.each(_clientsNotes, n => {
        const obj = {}
        obj.chat_id = n.id
        obj.client_id = n.client_id
        obj.created_date = n.created_date
        obj.message = n.note
        obj.receiverIds = []
        obj.role = ''
        obj.sender_avatar = n.user_avatar
        obj.sender_full_name = n.user_first_name + ' ' + n.user_last_name
        obj.sender_id = n.author_id
        obj.type = "text"
        obj.typeNotes = true
        _strategistChatData.push(obj)
      })
    }

    // _strategistChatData = _.orderBy(_strategistChatData, ['created_date'], ['desc'])
    return (
      <div>
        <div className="chats__sectiion">
          <div className="chats__sectiion__header">
            <h2 className="chats__sectiion__title">Notes to {strategistsFullNameStr} {finalStrategistName ? `and ${finalStrategistName}` : ''} about {clientFullName} </h2>
            {(_.size(_strategistChatData) > 0 || loginUser.role !== 'local') &&
            <p className="chats__sectiion__subtitle">The client will not see this note, only you and {strategistsFullNameStr} {finalStrategistName ? `and ${finalStrategistName}` : ''} will see this</p>}
          </div>
          {loginUser.role !== 'local' && <div className="chats__sectiion__wrapper">
            <textarea
              className="chats__sectiion__input"
              rows="4"
              name="chatText"
              onKeyDown={this.handleKeyDown}
              onKeyUp={this.handleKeyUp}
              ref={(current) => { this.editableRef = current }}
              style={{ resize: "none", overflowY: "hidden" }}
              value={this.state.chatText}
              onChange={this.handleChange.bind(this)}
              placeholder={`Write a note to ${strategistsFullNameStr} ${finalStrategistName ? `and ${finalStrategistName}` : ''}${(finalStrategistName) ? "about" : " about"} ${clientFullName}... `}>
            </textarea>
            <div className="chats__section__cta">
              <button onClick={() => this.clientChat()} disabled={(this.state.chatText !== '' && (this.state.chatText).trim() !== '') ? false : true} type="button" className="btn btn__btn btn-dark btn__send">Send</button>
            </div>
          </div>}

          {
            (_.size(_strategistChatData) > 0) &&
            _strategistChatData.map((chat, idx) => {
              const values = (chat.sender_full_name).split(" ");
              const firstName = _.upperCase(values[0] ? values[0].charAt(0) : '');
              const lastName = values[1] ? _.upperCase(((chat.sender_full_name).substr((chat.sender_full_name).indexOf(' ') + 1)).charAt(0)) : '';
              return (
                <div key={idx} className="chats__sectiion__inner">
                  {
                    (this.state.viewMore) ?
                      (chat.message !== "" && (chat.message).trim() !== "") &&
                      <ul className="chats__sectiion__chats__list">
                        <li className="has__avatar">
                          <div className="chats__card__header">
                            <div className="chats__card__avatar">
                              {
                                (_.isEmpty(chat.sender_avatar)) ?
                                  <span className="string__avatar">{`${firstName}${lastName}`}</span>
                                  : <img src={chat.sender_avatar} />
                              }
                            </div>
                            <span className="chats__card__name">{chat.sender_full_name}</span><span className="chats__card__time">{moment.utc(chat.created_date).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm a')}
                            </span>
                          </div>
                          <div className="chats__card">
                            {
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              chat.message
                            }
                            {
                              ((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__edit">
                                <textarea
                                  className="chats__card__textarea__edit"
                                  type="text"
                                  name="editChat"
                                  ref={(current) => { if (current) current.focus() }}
                                  onKeyDown={this.handleKeyDown}
                                  onKeyUp={this.handleKeyUp}
                                  style={{ resize: "none", overflowY: "hidden" }}
                                  value={this.state.editChat}
                                  onFocus={this.handleKeyUp}
                                  onChange={this.handleChange.bind(this)}
                                  placeholder=""
                                  rows="4">
                                </textarea>
                                <button onClick={this.editSave.bind(this)} disabled={(this.state.editChat !== '' && (this.state.editChat).trim() !== "") ? false : true} className="btn btn__btn btn__xs btn-dark mr__15">Save</button>
                                <span onClick={this.closeIconClick.bind(this)}><CloseIcon className="chats__card__edit__close" /></span>
                              </div>
                            }
                          </div>
                          {
                            (loginUserId === chat.sender_id) ?
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__cta">
                                <button onClick={() => this.editClick(this, chat)} className="btn btn__edit_chat">Edit</button>
                                {
                                  (!chat.typeNotes) &&
                                  <button onClick={() => this.deleteClick(this, chat)} className="btn btn__delete_chat">Delete</button>
                                }
                              </div>
                              :
                              <div className="chats__card__cta">
                              </div>
                          }
                        </li>
                      </ul>
                      :
                      (idx < 10) &&
                      (chat.message !== "" && (chat.message).trim() !== "") &&
                      <ul className="chats__sectiion__chats__list">
                        <li className="has__avatar">
                          <div className="chats__card__header">
                            <div className="chats__card__avatar">
                              {
                                (_.isEmpty(chat.sender_avatar)) ?
                                  <span className="string__avatar">{`${firstName}${lastName}`}</span>
                                  :
                                  <img src={chat.sender_avatar} />
                              }
                            </div>
                            <span className="chats__card__name">{chat.sender_full_name}</span><span className="chats__card__time">{moment.utc(chat.created_date).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm a')}
                            </span>
                          </div>
                          <div className="chats__card">
                            {
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              chat.message
                            }
                            {
                              ((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__edit">
                                <textarea
                                  className="chats__card__textarea__edit"
                                  type="text"
                                  name="editChat"
                                  ref={(current) => { if (current) current.focus() }}
                                  onKeyDown={this.handleKeyDown}
                                  onKeyUp={this.handleKeyUp}
                                  style={{ resize: "none", overflowY: "hidden" }}
                                  onFocus={this.handleKeyUp}
                                  value={this.state.editChat}
                                  onChange={this.handleChange.bind(this)}
                                  placeholder=""
                                  rows="4">
                                </textarea>
                                <button onClick={this.editSave.bind(this)} disabled={(this.state.editChat !== '' && (this.state.editChat).trim() !== "") ? false : true} className="btn btn__btn btn__xs btn-dark mr__15">Save</button>
                                <span onClick={this.closeIconClick.bind(this)}><CloseIcon className="chats__card__edit__close" /></span>
                              </div>
                            }
                          </div>
                          {
                            (loginUserId === chat.sender_id) ?
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__cta">
                                <button onClick={() => this.editClick(this, chat)} className="btn btn__edit_chat">Edit</button>
                                {
                                  (!chat.typeNotes) &&
                                  <button onClick={() => this.deleteClick(this, chat)} className="btn btn__delete_chat">Delete</button>
                                }
                              </div>
                              :
                              <div className="chats__card__cta">
                              </div>
                          }
                        </li>
                      </ul>
                  }
                </div>
              )
            })
          }
          {
            (_.size(_strategistChatData) > 10) &&
            <div className="chats__sectiion__inner">
              <ul className="chats__sectiion__chats__list">
                <button onClick={() => this.viewMoreButton()} className="btn btn__view_more_chat">{this.state.viewMore ? "View Less" : "View More"}</button>
              </ul>
            </div>
          }
        </div>

        <ReactModal
          isOpen={this.state.deleteMsgModal}
          onRequestClose={this.closeModal}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.closeModal}><CloseIcon /></button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">Do you want to delete this note.</p>
              <div className="text-right">
                <button onClick={this.dltMsgHandle.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
