import React from 'react'

export default function MobileView() {
    return (
        <div className="mob__status__select">
{/*           <div className="material-textfield mb__20">
            <select
              defaultValue={status}
              className={"form-control custom-select material-textfield-input textfield-input-lg uniQueselect-" + this.state.clientId}
              required
              onChange={this.selectedChange.bind(this, clientData)}>
              >
              <option>
                {(status !== "" && status !== "Null" && status !== "NULL" && status !== "Inspection Scheduled") ? status : 'select a status'}
              </option>
              {
                (status !== "Initial Welcome Sent") &&
                <option value="Initial Welcome Sent">Initial Welcome Sent</option>
              }
              {
                (status !== "Actively Engaged") &&
                <option value="Actively Engaged">Actively Engaged</option>
              }
              {
                (status !== "Moderately Engaged") &&
                <option value="Moderately Engaged">Moderately Engaged</option>
              }
              {
                (status !== "Accepted Offer") &&
                <option value="Accepted Offer">Accepted Offer</option>
              }
              {
                (status !== "Signed Contract") &&
                <option value="Signed Contract">Signed Contract</option>
              }
              {
                (status !== "Closed") &&
                <option value="Closed">Closed</option>
              }
              {
                (status !== "Unresponsive") &&
                <option value="Unresponsive">Unresponsive</option>
              }
            </select>
            <label className="material-textfield-label label-lg">Status</label>
          </div> */}
        </div>
    )
}
