import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import ResetPasswordLink from './partials/ResetPasswordLink/component'
import ResetPasswordComponent from './partials/ResetPassword/component'
import { Redirect, Link } from 'react-router-dom'

const propTypes = {
  handleSignOut: PropTypes.func.isRequired
}
class Reset extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadData: false,
      image: '',
      updated: false
    }
  }
  render() {  
    const { handleSubmit } = this.props 

    return (
      <div>
          {this.props.location.pathname === '/forgotpassword' && <ResetPasswordLink {...this.props}/>}
          {this.props.location.pathname.indexOf('/reset') !== -1 && <ResetPasswordComponent {...this.props}/>}
    </div>
    )
  }
}
export default Reset
