import { connect } from 'react-redux'
import { addUpdateClient, clearClientPhase } from '../../../../store/strategistV2/clients/action'
import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'

import AddClientComponent from './component'

const AddClientContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    addUpdateClientPhase: state.strategistV2.client.addUpdateClientPhase,
    addUpdateClientdata: state.strategistV2.client.addUpdateClientdata,
    filterParams: state.strategistV2.dashboard.filterParams,
    addUpdateClientError: state.strategistV2.client.addUpdateClientError,
    addUpdateClientErrorPhase: state.strategistV2.client.addUpdateClientErrorPhase,
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    addUpdateClient,
    clearClientPhase,
  }
)(AddClientComponent)

export default AddClientContainer
