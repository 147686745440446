import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import _ from 'lodash'
import styles from './styles.scss'
// import io from 'socket.io-client'
import { compose } from 'redux';
import ShowMore from 'react-show-more'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
const required = value => value ? undefined : 'Required'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()
// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
// let socket = io.connect(SOCKET_HOSTNAME)
import { logoutInvalidRequest } from '../../utils/helper'

let availableDateSlots = ''
let forEndDate = ''
let _user

let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
let isMobile = windowWidth < 767

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

class AgentChatCenterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      chat_text:'',
      sender_id: '',
      startDate:moment(),
      receiver_id:'',
      openSchedule: false,
      client_limit:500,
      activePage:1,
      selectClient: '',
      itemCount: '',
      selectAppointment:'',
      selectStartTime: '', 
      selectEndTime: '',
      description: '',
      err: {},
      detailClientFirstName: '',
      detailClientLastName: '',
      detailClientEmail: '',
      detailClientContact: '',
      detailClientDescription: '',
      detailClientImage: '',
      allClientData: {},
      mobile_view: false,
      shortClientName: '',
      viewMore: false,
      shareInfo: false,
      loader: false,
      photoIndex: 0,
      openImageModel: false
    }
    this.sendChatMessage = this.sendChatMessage.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
 
  componentWillMount(){
    const {fetchUser} = this.props;
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id, sender_id:_user.id, loader: true })
      const data = { user_id: _user.id }
      fetchUser(data)
      let agentData = {}
       agentData.client_limit = this.state.client_limit
       agentData.client_offset= this.state.activePage
       agentData.subType = 'all'
      this.props.agentGetUsers(agentData)
      this.props.getAgentClientsLastMessage(data)
    }
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({receiver_id:_clientid})
        setTimeout(function() {
          const container = `#client__name_${receiver_id}`
          let top = $(container).offset().top
          top = top && parseInt(top) > 0 ? top - 84 : 0
          $('.chatmsg__list').animate({
              scrollTop: (top)
          },500)
        }, 2000)
      }

    // socket.on('new_message', (data) =>{
    //     if(data && data.sender_id === _user.id){
    //       this.props.getAgentClientsLastMessage({ user_id: _user.id})
    //     }
    //     this.componentDidUpdate()
    // })

    if(!isMobile){
      if(receiver_id){
       const { location } = this.props
        let selectedClientData = location.state
        let first = selectedClientData.first_name && selectedClientData.first_name.charAt(0)
        let last = selectedClientData.last_name && selectedClientData.last_name.charAt(0)
        let clientShortName = first+''+last

        var tmp = document.createElement("DIV")
        tmp.innerHTML = unescape(selectedClientData&& selectedClientData.background_profile)
        let htmlDoc = tmp.textContent || tmp.innerText || ""
        this.setState({ 
          detailClientFirstName: selectedClientData.first_name,
          detailClientLastName: selectedClientData.last_name,
          detailClientEmail: selectedClientData.email,
          detailClientContact: selectedClientData.phone_mobile,
          detailClientDescription: htmlDoc,
          detailClientImage: selectedClientData.avatar,
          allClientData: selectedClientData,
          shortClientName: clientShortName,
          shareInfo: selectedClientData.share_info
        })
    }else if(localStorage.getItem("allClientData")){
      let AllClientObj = JSON.parse(localStorage.getItem("allClientData"))
        if(AllClientObj && AllClientObj !=null && AllClientObj !='' && AllClientObj != undefined && AllClientObj != "undefined"){
          this.props.history.push({
            pathname: `/agent-chat-with-client/${AllClientObj.id}`,
            state: AllClientObj
          })
        }
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.agentCLients && nextProps.agentCLients.activeCounts){
      this.setState({ loader: false })
    }
    if( (nextProps.agentCLients && nextProps.agentCLients.activeCounts) && (nextProps.getClientLastMessageData && nextProps.getClientLastMessageData.status === true) ){
      let client_param_id = this.props && this.props.match && this.props.match.params && this.props.match.params.id
      if(!isMobile){
        if( (client_param_id == "undefined" || client_param_id == undefined || client_param_id == "" || client_param_id == null) && !(localStorage.getItem("allClientData"))){
          if(nextProps.agentCLients && nextProps.agentCLients.data && nextProps.agentCLients.data.length > 0){

            let finalListArr = []
            finalListArr =  _.map(nextProps.agentCLients.data, function(obj) {
                return _.assign(obj, _.find(nextProps.getClientLastMessageData && nextProps.getClientLastMessageData.data, {common_id: obj.id}))
            })

            let finalClientArr = []
            if(finalListArr && finalListArr.length > 0){
              finalClientArr = _.orderBy(finalListArr, ["chat_time"] , ['desc'])
            }
            if(finalClientArr){
              localStorage.setItem('allClientData', JSON.stringify(finalClientArr[0]))
              if(finalClientArr[0] && finalClientArr[0] !=null && finalClientArr[0] !='' && finalClientArr[0] != undefined && finalClientArr[0] != "undefined"){
                this.props.history.push({
                  pathname: `/agent-chat-with-client/${finalClientArr[0].id}`,
                  state: finalClientArr[0]
                })
              }
            }
          }
        }
      }
    }
  }

  handleChange(e){
    let itemCount = ''
    if(e.target.name === "selectStartTime"){
       itemCount = _.filter(availableDateSlots, (avil) => {
        return avil.props.value > e.target.value
      })
    }
    this.setState({[e.target.name]: e.target.value, itemCount: itemCount},()=>{
    })
  }

   componentDidUpdate(){
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    if(this.state.sender_id !='' && receiver_id !='' && receiver_id != undefined && receiver_id != "undefined"){
      const data = {}
      data.sender_id = this.state.sender_id
      data.receiver_id = receiver_id
      this.props.getChatHistory(data)
    }
    $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
   }
  
  componentDidMount(){
    document.title = "Jungler: Agent Chat";
    let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
    let isMobile = windowWidth < 500
    if(this.props.match.params.id !== undefined){
      this.setState({mobile_view:isMobile}, ()=>{
      })
    }
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  openScheduleBar(id, e) {
    if(id === ''){
      this.setState({ openSchedule : true})
    }else{
      this.setState({ openSchedule : true, selectClient: id})
    }
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     _user = cookies.get('user')
      const data = {}
      data.user_id  = id
      data.timezone = timezone
      data.date     = selectDate
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }
  
   sendChatMessage(){
  
    // const receiver_id = this.props.match.params.id && this.props.match.params.id
    // if( this.state.chat_text !== '' && receiver_id != '' && this.state.sender_id !=''){
    //   socket.emit('new_message', { 
    //     sender_id : this.state.sender_id, 
    //     receiver_id: receiver_id, 
    //     sender_role : _user.role,
    //     type: "text", 
    //     content: this.state.chat_text
    //   })
    //    localStorage.setItem('allClientData', JSON.stringify(this.state.allClientData))
    //    const data = {}
    //    data.user_id = _user.id
    //    this.props.getAgentClientsLastMessage(data)
    //   this.setState({chat_text: ''})
    // }
  
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectClient, selectAppointment, textarea,
           startDate } = this.state
    
    if (selectClient === '' || selectClient === "Select the client" || selectClient.trim() === '') {
      err.selectClient = 'Please Select Client'
    }
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
      err.selectEndTime = 'Select End Time'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date =  moment(startDate._d).format("YYYY-MM-DD")
     let data = {}
     data.timezone = timezone
     data.user_id  = userId
     data.appointment_type = selectAppointment
     data.date     = date
     data.client_id = this.state.selectClient
     data.start_time = selectStartTime
     data.end_time   = selectEndTime
     data.description = this.state.description
     this.props.addAgentAppointment(data)
     this.setState({ openSchedule : false, selectClient: "Select the client" })
     }
  }

  onClientClick(client, e){
    let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
    let isMobile = windowWidth < 500
    //This is for client first and last name
    let first = client.first_name && client.first_name.charAt(0)
    let last = client.last_name && client.last_name.charAt(0)
    let clientFullName = first+''+last

    var tmp = document.createElement("DIV")
    tmp.innerHTML = unescape(client.background_profile)
    let htmlDoc = tmp.textContent || tmp.innerText || ""
    this.setState({ 
      mobile_view: isMobile,
      detailClientFirstName: client.first_name,
      detailClientLastName: client.last_name,
      detailClientEmail: client.email,
      detailClientContact: client.phone_mobile,
      detailClientDescription: htmlDoc,
      detailClientImage: client.avatar,
      selectClient: client.id,
      allClientData: client,
      shortClientName: clientFullName,
      shareInfo: client.share_info,
      viewMore: false
    })
  }

  viewMoreButton(e){
    this.setState({ viewMore: !this.state.viewMore })
  }

  handleFileUpload(e) {
    let fileType = "image"
    const { match: { params: { id }} } = this.props
    var _URL = window.URL || window.webkitURL
    let files  = e.target.files[0]
    let fileName = files.name

    var ext = fileName.match(/\.([^\.]+)$/)[1]
    if(files.type === 'application/pdf'){
      fileType = "pdf"
    }else{
      fileType = "image"
    }
    if(ext === 'xlsx' || ext === 'xls' || ext === 'csv' || ext === 'xltx' || ext === 'xlt' || ext === 'xlsb' || ext === 'xlsm' || ext === 'xltm'){
      fileType = "excel"
    }
    var img = new Image()
    let self=this
    let reader = new FileReader()
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files)
      img.onload = function() {
          self.setState({file: files})
          self.setState({avatar: reader.result})
      }
    }
    /*upload file*/
    const formdata = new FormData()
    formdata.append('image', files)
    formdata.append('client_id', id)
    const jwtToken = cookies.get('Authorization')
    axios({
      method: 'POST',
      url: `${HOSTNAME}/agent/agent_upload_image_pdf`,
      'headers': { 'Authorization': jwtToken },
      data: formdata
    }).then(res => {
      if(res && res.data && res.data.status){
        // const receiver_id = id
        // if( receiver_id != '' && this.state.sender_id !=''){
        //   socket.emit('new_message', { 
        //     sender_id : this.state.sender_id, 
        //     receiver_id: receiver_id, 
        //     sender_role : _user.role,
        //     type: fileType,
        //     file_name: fileName,
        //     content: res.data.image_url
        //   })
        //    const data = {}
        //    data.user_id = _user.id
        //    this.props.getAgentClientsLastMessage(data)
        //   this.setState({chat_text: ''})
        // }

      }
   }).catch(logoutInvalidRequest)
   var read = reader.readAsDataURL(files) 
  }

  imageOpen(e){
    this.setState({ openImageModel: !this.state.openImageModel })
  }

  render() {  
    const {
      user,
      agentCLients,
      location,
      chatHistoryData,
      usersData,
      availabilityData,
      getClientLastMessageData
    } = this.props
     const { mobile_view, photoIndex, openImageModel } = this.state
    let firstDigit = user && user.first_name && (user.first_name).charAt(0)
    let secondDigit = user && user.last_name && (user.last_name).charAt(0)
    let fullDigit = firstDigit+''+secondDigit

    const ClientData = location.state;

    //This is for client FIrst and Last first letter
    let first = ClientData && ClientData.first_name && ClientData.first_name.charAt(0)
    let last = ClientData && ClientData.last_name && ClientData.last_name.charAt(0)
    let clientShortName = first+''+last

    //This is for html convert into normal peragraph
    var tmp = document.createElement("DIV")
    tmp.innerHTML = unescape(ClientData && ClientData.background_profile)
    let htmlDoc = tmp.textContent || tmp.innerText || ""

    let chatHistory = _.orderBy(chatHistoryData && chatHistoryData.data, ['created'],['desc'])
    // Iterate over first array of objects
    let finalListArr = []
    if(agentCLients && agentCLients.data && agentCLients.data.length > 0){
      finalListArr =  _.map(agentCLients.data, function(obj) {
          return _.assign(obj, _.find(getClientLastMessageData && getClientLastMessageData.data, {common_id: obj.id}));
      });
    }

    let finalClientArr = []
    if(finalListArr && finalListArr.length > 0){
      finalClientArr = _.orderBy(finalListArr, ["chat_time"] , ['desc'])
    }

    let clientList = ''
    if(agentCLients &&  agentCLients.data && agentCLients.data.length>0) {
      clientList = agentCLients.data.map((val, index) => {
        return (
          <option key={index} value={val.id}>{val.first_name +' '+ val.last_name}</option>
        )
      });
    }

    let appoinment = config.typeOfAppointments.map((val, index) => {
      return (
        <option key={index} value={val.value}>{val.name}</option>
      )
    });

    availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
      let pos = availabilityData.data.slot.length - 1
      if (pos === indexslot) {
        return(
          <option disabled={pos} key={indexslot} value={slotval}>{slotval}</option>
        )
      } else {
        return(
          <option key={indexslot} value={slotval}>{slotval}</option>
        )
      }
    });

    forEndDate = this.state.itemCount && this.state.itemCount.map((endTime, endIndex) =>{
          return(
           <option key={endIndex} value={endTime.props.value}>{endTime.props.value}</option>
          )
    });

    $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 500);

    return (
      <div>

      {this.state.loader === true ?
        <div className="loader__agent" >
         <img src="../../img/loader2.svg" />
        </div>
        :''}

        <div className="chatmsg__main--wrapper">
        {
          mobile_view ? 
          <div className="chatmsg__header">
            <div className="chatmsg__header--title agentchatfont">Chat with {this.state.detailClientFirstName+" "+this.state.detailClientLastName}</div>
            <div className="chatmsg__header--close">
              <img onClick={()=> this.setState({ mobile_view:false })} src="img/close.svg"/>
            </div>
          </div>
          : 
          <div className="chatmsg__header">
            <div className="chatmsg__header--title agentchatfont fontbold">Client/Agent Message Center</div>
            <div className="chatmsg__header--close">
              <Link to={'/'}><img src="img/close.svg"/></Link>
            </div>
          </div>
        }
          

          <div className={ mobile_view ? "chatmst__list--none" : "chatmsg__list"}>
            <ul>
              {
                finalClientArr && finalClientArr.length > 0 ? finalClientArr.map((client, indx) =>{
                  if(client && client.id !=null){
                    let fDigit = client && client.first_name && (client.first_name).charAt(0)
                    let sDigit = client && client.last_name && (client.last_name).charAt(0)
                    let finalDigit = fDigit+''+sDigit
                    return(
                      <li key={indx} id={"client__name_"+client.id} onClick={this.onClientClick.bind(this, client)} className={this.props.location.pathname === `/agent-chat-with-client/${client.id}` ? 'active__list': ''}>
                        <Link to={{ pathname: `/agent-chat-with-client/${client.id}`, state: client}}>
                          <div className="profile__img">
                            {
                              (client.avatar) ?
                                <img src={client.avatar} alt=""/>
                              :
                               <p className="profile__letter--client">{finalDigit}</p>
                            }
                          </div>
                          <div className="profile__info">
                            <h2 className="agentchatfont">{client.first_name+' '+client.last_name}</h2>
                            {
                              (client.type === 'image')?
                                <h4 className="agentchatfont"><img className="img--padding" src="../../img/camera_image.png" width="15px" heigh="15px"/>Photo</h4>
                              : 
                              (client.type === 'pdf')?
                                <h4 className="agentchatfont"><img className="img--padding" src="../../img/pdf_icon.png" width="15px" heigh="15px"/>PDF</h4>
                              :
                              (client.type === 'excel')?
                              <h4 className="agentchatfont"><img className="img--padding" src="../../img/excel_icon.png" width="15px" heigh="15px"/>Excel</h4>
                              :
                                <h4 className="agentchatfont">{client.content}</h4>
                            }
                          </div>
                        </Link>
                      </li>
                    )
                  }
                })
                : ""
              }
            </ul>
          </div>

          {
            ClientData && ClientData ? 
            <div>
              <div className={ mobile_view ? "chat__centre--block chat__centre" : "chat__centre"}>
                <div className="chat__centre--up">
                  <h5 className="agentchatfont">Here are the towns you are helping this client with: </h5>
                  {
                    ClientData && ClientData.towns && ClientData.towns.length > 0 ? 
                    ClientData.towns.map((town, indext) => {
                    let town_link = 'javascript:void(0);'
                    let authorization = cookies.get('Authorization')
                    if(town && town.town_url){
                    town_link = town.town_url ? town.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
                    }
                      return(<a key={indext} href={town_link} target="_blank" className="town__design poppinsfontss">{indext-1 ? `${town.town_name},` : town.town_name}</a>)
                    })
                    :"No Towns"
                  }
                </div>
                <div className="chat__centre--chatbox">
                  <ul className="forsafari">
                  {
                    chatHistory && chatHistory.length >0 ? chatHistory.map((chat,ind) => {
                      let userAvatar = user && user.avatar;
                      let userName   = user && user.first_name +' '+user.last_name;
                      let myuserId     = user && user.id;
                      return(
                        (chat.receiver_id == myuserId) ? (
                          <li key={ind}>
                            <img src={chat.sender_avatar ? chat.sender_avatar: "../../img/default-user.svg"} alt="" />
                            <div className="details">
                              <h6 className="agentchatfont__med">{chat.sender_full_name}</h6>
                              {chat.chat_type==='image'?
                                <span>
                                  <img className="clickable__image" onClick={this.imageOpen.bind(this)} src={chat.content}/>
                                  {openImageModel && (
                                    <Lightbox
                                      mainSrc={[chat.content][photoIndex]}
                                      onCloseRequest={this.imageOpen.bind(this)}
                                      onMovePrevRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                        })
                                      }
                                      onMoveNextRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + 1) % [chat.content].length,
                                        })
                                      }
                                    />
                                  )}
                                </span>
                                :
                                 (chat.chat_type === 'text') ?
                                  <p spellCheck="true" className="agentchatfont">{chat.content}</p>
                                :
                                  (chat.chat_type === 'automated_welcome_message') ?
                                  <p spellCheck="true" className="agentchatfont">{chat.content}</p>
                                :
                                 (chat.chat_type === 'pdf') ?
                                 <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(chat.file_name)}</div></a>
                                :
                                (chat.chat_type === 'excel') &&
                                 <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(chat.file_name)}</div></a>
                              }
                              <h5 className="agentchatfont__med">{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                            </div>
                          </li>
                        ):
                        (chat.sender_id == myuserId )? 
                          <li key={ind} className="you">
                            {
                              (chat.sender_avatar) ?
                                <img src={chat.sender_avatar} alt="" />
                              :
                               <p className="profile__letter--only">{fullDigit}</p>
                            }
                            <div className="details">
                              <h6 className="agentchatfont__med">You</h6>
                              {chat.chat_type==='image'?
                                <span>
                                  <img className="clickable__image" onClick={() => this.setState({ photoIndex: ind, openImageModel: true })} src={chat.content}/>
                                  {openImageModel && (
                                    <Lightbox
                                      mainSrc={chatHistory.map(c => c.content)[photoIndex]}
                                      onCloseRequest={this.imageOpen.bind(this)}
                                      onMovePrevRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                        })
                                      }
                                      onMoveNextRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + 1) % [chat.content].length,
                                        })
                                      }
                                    />
                                  )}
                                </span>
                                :
                                 (chat.chat_type === 'text') ? 
                                  <p spellCheck="true" className="agentchatfont">{chat.content}</p>
                                :
                                  (chat.chat_type === 'automated_welcome_message') ? 
                                  <p spellCheck="true" className="agentchatfont">{chat.content}</p>
                                :                                
                                 (chat.chat_type === 'pdf') ?
                                 <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(chat.file_name)}</div></a>
                                :
                                 (chat.chat_type === 'excel') &&
                                 <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(chat.file_name)}</div></a>
                              }
                              <h5 className="agentchatfont__med">{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                            </div>
                          </li>
                        : ''
                      )
                    }):""
                  }
                </ul>
                <div className="send__wrapper sendwrapper">
                  <textarea 
                    className="agentchatfont textareapadnew" 
                    type="text" 
                    name="chat_text" 
                    onChange={this.handleChange.bind(this)} 
                    value={this.state.chat_text}
                    placeholder={ClientData ? `Enter your message here to client ${ClientData.first_name+" "+ClientData.last_name}...` : `Enter your message here to client ${ClientData && ClientData.first_name+" "+ClientData && ClientData.last_name}...`} />

                  <div className="image-upload imguploadnew">
                    <label for="file-input">
                      <img className="add__cursor" src="https://cdn1.iconfinder.com/data/icons/internet-28/48/30-512.png" width="30px" heigh="30px"/>
                    </label>

                    <input 
                      style={{display: "none"}} 
                      onChange={this.handleFileUpload.bind(this)} 
                      accept="application/pdf, image/jpg,image/hief,image/jpeg,image/png, image/svg, image/gif, .xlsx, .xls, .csv"
                      id="file-input" type="file" />
                  </div>
                  <button
                  className="fontbold poppinsfontss"
                    type="submit"
                    onClick={()=> this.sendChatMessage()}>
                    Send
                  </button>
                </div>
              </div>
            </div>

            {
              (ClientData) && 
              <div className="chat__schedules--wrapper">
                <h3 className="agentchatfont fontbold">Client Details</h3>
                <div className="chat__schedules--profile">
                  {
                    (ClientData.avatar) ? 
                      <img src={ClientData.avatar ? ClientData.avatar : 'img/login-bg-img.png'}/>
                    :
                    <span className="profile__letter--big">{clientShortName}</span>
                  }
                  <h2 className="agentchatfont">{ClientData.first_name+' '+ClientData.last_name}</h2>
                  
                  {
                    (chatHistoryData && chatHistoryData.share_info_to_all === false) ? 
                    (chatHistoryData && chatHistoryData.client_share_info === true)&&
                    <span>
                      <h6 className="agentchatfont"><img src={'img/email-chat-profile.svg'}/>{ClientData.email}</h6>
                      <h6 className="agentchatfont"><img src={'img/contact-chat-profile.svg'}/>
                      {ClientData.phone_mobile ?  
                        ((ClientData.phone_mobile.replace(/\D/g, '').length == 10 ) ? this.formatNumber(ClientData.phone_mobile.replace(/\D/g, '')) : ClientData.phone_mobile) : "" }</h6>
                    </span>
                    :
                    <span>
                      <h6 className="agentchatfont"><img src={'img/email-chat-profile.svg'}/>{ClientData.email}</h6>
                      <h6 className="agentchatfont"><img src={'img/contact-chat-profile.svg'}/>
                      {ClientData.phone_mobile ?  
                        ((ClientData.phone_mobile.replace(/\D/g, '').length == 10 ) ? this.formatNumber(ClientData.phone_mobile.replace(/\D/g, '')) : ClientData.phone_mobile) : "" }</h6>
                    </span>
                  }
                
                  <div className="show__more--details poppinsfontss">
                  {
                    (htmlDoc !== "" && htmlDoc !== null && htmlDoc !== "null") &&
                    <div>
                      <p className={this.state.viewMore ? "" : "para__lines poppinsfontss"}>{htmlDoc}</p>
                      {
                        ((htmlDoc && htmlDoc.split(' ').length > 100)) &&
                        <span>
                          {this.state.viewMore !== true && <span className="view__more--less" onClick={this.viewMoreButton.bind(this)}>View more</span>}
                          {this.state.viewMore && <span className="view__more--less" onClick={this.viewMoreButton.bind(this)}>View less</span>}
                        </span>
                      }
                    </div>
                  }
                  </div>
                </div>
                <button 
                  className="schedule__btn fontbold poppinsfontss"
                  onClick={this.openScheduleBar.bind(this, this.props.match.params.id ? this.props.match.params.id: '')}>
                  Schedule
                </button>
              </div>
            }

            </div>
            :
            <div className="emptychat__wrapper">
              {/*<div className="emptychat">
                <img src="img/chat_icon.svg" className="chatIcon" />
                Select an Individual to send a message or to see the chat history.
              </div>*/}
            </div>
          }

        </div>

      {/*this is booking appointment with client*/}
      <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
              <div className="schedule__sidebar--form--wrapper">
                <div className="form-group">
                  <label className="labelinputs__global">Client Name</label>
                  <select 
                    className="selectinputs__global" 
                    name="selectClient" 
                    value = {this.state.selectClient}  
                    onChange={this.handleChange.bind(this)}>
                    <option value=''>Select the client</option>
                    {clientList}
                  </select>
                </div>
                { this.state.err.selectClient ?
                <span className="error_msg">
                {this.state.err.selectClient}
                </span> : '' }

                <div className="form-group">
                  <label className="labelinputs__global">Appointment Type</label>
                  <select className="selectinputs__global" name="selectAppointment" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                    <option>Select the type of Appointment</option>
                     {appoinment}
                  </select>
                </div>
                {this.state.err.selectAppointment ?
                <span className="error_msg">
                {this.state.err.selectAppointment}
                </span> : '' }

                <div className="form-group">
                 <DatePicker
                    selected={this.state.startDate}
                    customInput={<DatePickerCustomInput />}
                    onChange={this.handelDateChange.bind(this, _user.time_zone, _user.id)}
                    showTimeSelect
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date()}
                    className="form-control custom-select date__picker"
                    value={this.state.startDate}
                    placeholderText="Pick a date">
                  </DatePicker>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelinputs__global">Start</label>
                    <select 
                      name='selectStartTime' 
                      className="selectinputs__global" 
                      value = {this.state.selectStartTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>Start Time</option>
                      {availableDateSlots}
                    </select>
                  </div>
                {this.state.err.selectStartTime ?
                <span className="error_msg">
                {this.state.err.selectStartTime}
                </span> : '' }
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelinputs__global">End</label>
                    <select 
                      name="selectEndTime" 
                      className="selectinputs__global" 
                      value = {this.state.selectEndTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>End Time</option>
                       {forEndDate}
                    </select>
                  </div>
                  {this.state.err.selectEndTime ?
                <span className="error_msg">
                {this.state.err.selectEndTime}
                </span> : '' }
                </div>
                </div>

                <div className="form-group rem__marg--btm">
                  <label className="labelinputs__global">Appointment Description</label>
                  <textarea name="description" className="textareainputs__global"
                   onChange={this.handleChange}
                   placeholder="Enter description here..."></textarea>
                </div>
              </div>
            </div>

            <div className="schedule__sidebar--footer">
              <button 
                className="btn btn__green btn__green--lg btn-block btn__schedule" 
                onClick={this.submitSchedulingData.bind(this, this.props.user.time_zone, this.props.user.id )}>
                Schedule
              </button>
            </div>
        </div>
      {/*this is end booking appointment with client*/}

      </div>
    )
  }
}

export default reduxForm({
  form: 'messagecenter',
  destroyOnUnmount: true,
})(AgentChatCenterForm)
