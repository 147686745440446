import * as types from './action-types'

  ////////////////////////////
 /// for header ///
////////////////////////////

// fetch push notification
export const getPushNotification = data => ({
  type: types.GET_NOTIFICATION,
  payload: data
})

// fetch push notification mark all as read
export const markAsReadNotification = data => ({
  type: types.MARK_ALL_AS_READ,
  payload: data
})