/* eslint-disable camelcase */
import React, { Component } from 'react'
import { size, map, compact, find } from 'lodash'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import axios from 'axios';
import _ from 'lodash';

import { fetch } from '../../../../../utils'

import { multiSelectStyles } from './variables'
import { DeleteIcon } from '../../../../../components/icons'
import AgentDetails from './agentDetails'
import './styles.scss'

export default class SelectedTownList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      beforePreviewModal: false,
      agents: []
    }
  }


  // handle mutliselection option
  handleMultiSelectionOption = (options, townId, option) => {

    if (event.target.checked) {
      this.props.assignLocalToTown(options, townId, true, option)
    } else {
      this.props.assignLocalToTown(options, townId, false, option)
    }
  }
  // assign agent to town and save into payload
  assignAgentToTown = (event, townId) => {
    const { value } = event.target
    this.props.assignAgentToTown({
      agent_id: parseInt(value),
      town_id: townId
    })
  }
  assignAgentToTown1 = (value, townId) => {
    this.props.assignAgentToTown({
      agent_id: value,
      town_id: townId
    })
    this.setState({ beforePreviewModal: false })
  }
  // assign local to town and save into payload
  selectedAssignedLocal = (agents, townId) => {
    const options = []
    map(agents, (agent) => {
      if (agent.role === 'local' || agent.role === 'business development') {
        const isLocalExists = this.props.selectedLocal.find(el => el.agent_id === agent.agent_id && el.town_id === townId)
        const isAgentExists = this.props.selectedAgentId.find(el => el.agent_id === agent.agent_id && el.town_id === townId)
        if (isLocalExists || isAgentExists) {
          const a = {
            label: `${agent.agent_first_name} ${agent.agent_last_name} (${agent.role === 'business development' ? 'BD' : agent.role
              })`,
            value: agent.agent_id,
            raw: agent
          }
          options.push(a)
        }
      }
    })
    return compact(options)
  }
  // create local DB option
  assignedLocalDBOptions = (agents) => {
    const options = map(agents, (agent) => {
      if (agent.role === 'local' || agent.role === 'business development') {
        return {
          label: `${agent.agent_first_name} ${agent.agent_last_name} (${agent.role === 'business development' ? 'BD' : agent.role
            })`,
          value: agent.agent_id,
          raw: agent
        }
      }
    })
    return compact(options)
  }
  openBeforePreviewModel = async (e, townId, townName) => {
    this.setState({ loadingTownDetail: townId })
    const res = await fetch(`${process.env.SUBURBANJUNGLE_API_HOST}/towns/${townId}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((payload) => payload)
      .catch((error) => {
        throw error
      })
    const agents = _.get(res, 'data', [])
    this.setState({ beforePreviewModal: true, agents, townName, loadingTownDetail: false })
  }

  closeBeforePreviewModal = () => this.setState({ beforePreviewModal: false })

  render() {
    const { selectedTowns, selectedAgentId, townData } = this.props
    const aIClientAddedTowns =
      townData && townData.aIClientAddedTowns ? townData.aIClientAddedTowns : []
    return (
      <div className="mb__30">
        <h4 className="heading__text">Selected Towns</h4>
        <div className="table-responsive height-issue accordion jc-height">
          <table className="table custom__table__simple selected__towns__table">
            <tbody>
              {map(selectedTowns, (selectedTown, index) => {
                const townType = find(aIClientAddedTowns, { town_id: selectedTown.value })
                const townAI = size(townType) ? townType.isAi : false

                return <tr key={index}>
                  <td>
                    {selectedTown.label}
                    {/*{townAI ? (<div className="small__text">{townAI === 1 ? 'AI Recommended' : 'Client Selection'}</div>) : ''}*/}
                  </td>
                  <td>
                    <div className="form-group material-textfield d-flex jc_select_width">
                      <select className="form-control custom__select material-textfield-input" onChange={(event) => this.assignAgentToTown(event, selectedTown.value, index)}>
                        <option value="0">Select Agent</option>
                        {map(selectedTown.raw.agents, (agent, index) => {
                          // eslint-disable-next-line camelcase
                          const isAgentExists = find(selectedAgentId, { town_id: selectedTown.value, agent_id: agent.agent_id })
                          if (agent.agent_id !== null && agent.role === 'agent')
                            return (
                              <option
                                key={index}
                                value={agent.agent_id}
                                selected={size(isAgentExists) ? true : false}
                                disabled={agent.is_limit === 0}
                              >{`${agent.agent_first_name} ${agent.agent_last_name}`}</option>
                            )
                        })}
                      </select>
                      <label className="material-textfield-label">Agent</label>
                      <div className="jc-info-icon" onClick={(e) => this.openBeforePreviewModel(e, selectedTown.value, selectedTown.label)}>{this.state.loadingTownDetail === selectedTown.value ? 'Loading...' : <img src="/img/info-icon.svg" alt="" />}</div>

                    </div>
                  </td>
                  {/*removed SJ-94*/}
                  {/*<td>*/}
                  {/*  <div className="material-textfield">*/}
                  {/*    <div className="">*/}
                  {/*      <div className="multiselect__checkboxes__field">*/}
                  {/*        <ReactMultiSelectCheckboxes*/}
                  {/*          styles={multiSelectStyles}*/}
                  {/*          width="100%"*/}
                  {/*          name="towns"*/}
                  {/*          value={this.selectedAssignedLocal(selectedTown.raw.agents, selectedTown.value)}*/}
                  {/*          placeholderButtonLabel="Select Local"*/}
                  {/*          options={this.assignedLocalDBOptions(selectedTown.raw.agents)}*/}
                  {/*          onChange={(event, option) => this.handleMultiSelectionOption(event, selectedTown.value, option)}*/}
                  {/*        />*/}
                  {/*        <label className="multiselect__checkboxes__field__label">*/}
                  {/*          Select Local*/}
                  {/*        </label>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</td>*/}
                  <td className="delete_jc"
                    onClick={() =>
                        this.props.removeTownRequestModal(selectedTown.value)
                    }
                  >
                    <DeleteIcon className="cursor__pointer" />
                  </td>
                </tr>
              })}
              </tbody>
            </table>
          </div>
          <AgentDetails
            beforePreviewModal={this.state.beforePreviewModal}
            closeBeforePreviewModal={this.closeBeforePreviewModal}
            agents={this.state.agents}
            assignAgentToTown1={this.assignAgentToTown1}
            townName={this.state.townName}
          />
        </div>
    )
  }
}
