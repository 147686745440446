import { connect } from 'react-redux'
import {fetchUser, handleSignOut, getNotifications, clearNotification} from '../../store/user/duck'
import { getClientStrategist, 
         getClientAgent, 
         scheduleMeeting,
         getSearchedClients } from '../../store/client/duck'
import {getAllClients} from '../../store/agent/duck'
import {getAllUsers, saveAuthtoken} from '../../store/dashboard/duck'
import oauth2CallbackForm from './component'

const oauth2Callback = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    profilePhase: state.user.profilePhase,
    authTokenPhase:state.dashboard.authTokenPhase,
    agentData: state.client.agentData,
    resMessage: state.client.resMessage,
    allclientData: state.agent.allclientData,
    saveAuthtokenData: state.dashboard.saveAuthtokenData,
    allUserData: state.dashboard.allUserData,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getClientStrategist,
    getClientAgent,
    scheduleMeeting,
    getSearchedClients,
    getAllClients,
    getAllUsers,
    saveAuthtoken,
    getNotifications,
    clearNotification
  }
)(oauth2CallbackForm)
export default oauth2Callback

