import { connect } from 'react-redux'

import { getAgentDeal } from '../../../../../store/agent/duck'

import DealInformationComponent from './component'
const DealInformationContainer = connect(
  // Map state to props
  (state) => ({
    agentDealData: state.agent.agentDealData
  }),
  // Map actions to dispatch and props
  {
    getAgentDeal
  }
)(DealInformationComponent)

export default DealInformationContainer
