import { connect } from 'react-redux'

import FileInternalComponent from './component'

const FileInternalContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(FileInternalComponent)

export default FileInternalContainer
