import React from 'react'
import moment from 'moment'
import { map } from 'lodash'

export default function TableBody({ agents, history, openDrawer }) {
  // convert price range into propar format
  const formatPriceRange = (priceRange) => {
    if (priceRange) {
      const rangeArray = priceRange.split('-')
      return `$${parseInt(rangeArray[0].trim()).toLocaleString()} - $${parseInt(
        rangeArray[1].trim()
      ).toLocaleString()}`
    } else {
      return priceRange
    }
  }
  const goToClientDetail = (clientId) => {
    if (clientId) history.push({
      pathname: `/strategist/client-details/${clientId}`,
      state: { prevRoute: '/strategist/your-agents' }
    })
  }
  const messageDrawer = (event, clientId, agentId, agentName) => {
    event.stopPropagation()
    openDrawer(clientId, agentId, agentName)
  }
  
  return (
    <div className="your__agents__list__body">
      {map(agents, (agent, agentIndex) => (
        <div
          className="div__tr__rows cursor__pointer"
          key={agentIndex}
          onClick={(event) => goToClientDetail(agent.clientId)}
        >
          <div className="div__rows">
            <div className="div__column">{agent.clientName}</div>
            <div className="div__column">{formatPriceRange(agent.price_range)}</div>
            <div className="div__column">{agent.status ? agent.status : ''}</div>
          </div>
          {agent.agents && agent.agents.length > 0 && (
            <div className="div__nested__rows div__nested__rows__header">
              <div className="div__nested__column">Agent</div>
              <div className="div__nested__column">Agent Status</div>
              <div className="div__nested__column">Appointment</div>
              <div className="div__nested__column">Assigned Towns</div>
              <div className="div__nested__column"></div>
            </div>
          )}

          {agent.agents &&
            agent.agents.length > 0 &&
            map(agent.agents, (_agent, _agentIndex) => {
              const noAppointment =_agent.appointment && _agent.appointment.start_date && _agent.appointment.end_date
              const styleAppointment = noAppointment ? '' : 'text-red'
              return (
                <div className="div__nested__rows" key={_agentIndex}>
                  <div className={`div__nested__column ${styleAppointment}`}>
                    {_agent.agent_name}
                  </div>
                  <div className={`div__nested__column ${styleAppointment}`}>{_agent.agent_status}</div>
                  <div className={`div__nested__column ${styleAppointment}`}>
                    {_agent.appointment && _agent.appointment.appointment_type
                      ? _agent.appointment.appointment_type
                      : 'No Appointment'}
                    <span className={`time ${styleAppointment}`}>
                      {noAppointment
                        ? `${moment.utc(_agent.appointment.start_date).format(
                            'MMMM DD, YYYY@hh:mma-'
                          )}${moment.utc(_agent.appointment.end_date).format('hh:mma')}`
                        : ''}
                    </span>
                  </div>
                  <div className={`div__nested__column ${styleAppointment}`}>
                    {_agent.town_names}
                  </div>
                  <div className="div__nested__column view__accounting__report" onClick={(event) => messageDrawer(event, agent.clientId, _agent.agent_id, _agent.agent_name)}>
                    Message Agent
                  </div>
                </div>
              )
            })}
        </div>
      ))}
    </div>
  )
}
