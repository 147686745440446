import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
// import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Chart from 'react-google-charts'
import styles from './styles.scss'
const required = value => value ? undefined : 'Required'



class AdminDashboardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }


  render() {
    let usersData = [
                      ['Year', 'Users'],
                      ['Jan', 25],
                      ['Feb', 23],
                      ['Mar', 26],
                      ['Apr', 21],
                      ['May', 29],
                      ['Jun', 28],
                      ['Jul', 24],
                      ['Aug', 27],
                      ['Sep', 30],
                      ['Oct', 22],
                      ['Nov', 25],
                      ['Dec', 28],
                    ];

    return (
      <div>
        <div className="analytics__page--container">
          <div className="analytics__page--header">
            <div className="analytics__page--header-title">
              Jungler Analytics
            </div>
            <select className="form-control custom-select">
              <option>Other Metrics </option>
              <option>Select </option>
              <option>Select </option>
              <option>Select </option>
              <option>Select </option>
            </select>
            <div className="analytics__page--close">
              <Link to={`/dashboard`}>
              <img src="img/close.svg"/></Link>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="analytics__heading">
                  <h1>Strategist</h1>
                  <p>You can see information about how each strategist is doing
                    in general or by tags.
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-3">
                <div className="analytics__count">
                  45
                </div>
              </div>
              <div className="col-md-9">
                <div className="analytics__action">
                  <select className="form-control custom-select">
                    <option>Select Strategist </option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                  </select>
                  <select className="form-control custom-select">
                    <option>Select Pipeline Stage </option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                  </select>
                  <select className="form-control custom-select">
                    <option>Month</option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                    <option>Select </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="analytics__graph">
                  <Chart
                    width={'100%'}
                    height={'500px'}
                    type="linear"
                    chartType="AreaChart"
                    loader={<div>Loading Chart</div>}
                    data={usersData}
                    options={{
                      isStacked: true,
                      height: 500,
                      legend: { position: 'top', maxLines: 5 },
                      colors: ['#C6CE4A','#C6CE4A', '#C6CE4A'],
                      vAxis: { minValue: 0 },
                      // lineWidth: 25
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex">
                <div className="analytics__last--updated">
                  Your data was last updated 28 minutes ago.
                </div>
                <div className="analytics__export--btn">
                  <button className="btn btn__green btn__green--lg">Export Data</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'login',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AdminDashboardForm)
