import React, { useEffect, useState, Fragment } from 'react'
import moment from 'moment'

import './styles.scss'
import { map, get } from 'lodash'
import { Spin } from 'antd'
import $ from 'jquery'

import { CloseIcon } from '../../../../../../components/icons'
import { SUCCESS } from '../../../../../../constants/phase'
import DeleteNotes from './partials/DeleteNotes'
import Loader from '../../../../../../components/Loader'
import { createStringAvatar } from '../../../../../../utils/helper'

export default function InternalNotesComponent(props) {
  // dynamic textarea for incress and decress box size
  const handleKeyDown = (event) => {
    event.persist()
    event.target.style.height = 'inherit'
    event.target.style.height = `${event.target.scrollHeight}px`
  }
  // dynamic textarea for incress and decress box size
  const handleEditNoteKeyDown = (event) => {
    event.persist()
    event.target.style.height = 'inherit'
    event.target.style.height = `${event.target.scrollHeight}px`
  }
  // state for message or note to enter
  const [note, setNote] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [editNoteId, setEditNoteId] = useState(false)
  const [editNote, setEditNote] = useState('')
  const [deleteConfirmationModal, toggleConfirmationModal] = useState({
    open: false,
    noteId: '',
  })

  const editNotes = (note) => {
    setNote('')
    // replace all html tags .replace(/<\/?[^>]+(>|$)/g, '\n')
    const notes = get(note, 'note', '') ? note.note.replace(/<\/?[br\/>]+(>|$)/g, '\n') : ''
    setEditNote(notes)
    setEditNoteId(note.id)
  }
  const handleCancelEditNote = () => {
    setNote('')
    setEditNoteId(false)
    setEditNote('')
  }
  // handle change on notes writing
  const handleChange = ({ target }) => {
    setEditNoteId(false)
    setEditNote('')
    setNote(target.value)
  }
  const handleEditNoteChange = ({ target }) => setEditNote(target.value)

  // submit or create notes
  const handleSubmit = () => {
    const clientId = props.match.params.id
    if (note !== '' && clientId !== '' && !editNoteId ) {
      setNote('')
      props.createInteralNote({ clientId, note })
    }
    if (editNote !== '' && clientId !== '' && editNoteId) {
      props.editInteralNote({ clientId, note: editNote, noteId: editNoteId })
      setNote('')
      setEditNote('')
      setEditNoteId(false)
    }
  }
  // fetch notes on cdm and cwm
  useEffect(() => {
    const clientId = props.match.params.id
    setLoading(true)
    props.getInteralNotes({ clientId })
  }, [])

  // cwrp on creating success notes
  useEffect(() => {
    if (props.createInteralNotePhase === SUCCESS || props.editInteralNotePhase === SUCCESS) {
      setNote('')
      setEditNote('')
      const clientId = props.match.params.id
      setLoading(true)
      props.getInteralNotes({ clientId })
      props.clearInteralNotePhase()
    }
  }, [props.createInteralNotePhase, props.editInteralNotePhase])

  // cwrp on success of fetching notes
  useEffect(() => {
    if (props.fetchInteralNotePhase === SUCCESS) {
      $('.chats__container').animate({ scrollTop: $('.chats__container').prop("scrollHeight") }, 1000)
      setLoading(false)
      props.clearInteralNotePhase()
    }
  }, [props.fetchInteralNotePhase])

  const userId = props.user && props.user.id

  // close drawer on click of close
  const onClose = () => props.closeDrawer()

  const deleteNotesConfirmation = (noteId) =>
    toggleConfirmationModal({ ...deleteConfirmationModal, open: true, noteId })

  const closeDeleteNotesModal = () => toggleConfirmationModal((prevState) => ({ ...prevState, open: false, noteId: '' }))

  const correctFaultyHtmlTags = (str) => {
    const regex = /<hr(?!\/>)/g;
    return str.replace(regex, '');
  }

  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Internal Notes</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
        <div className="chats__start__bottom">
          {props.notes && props.notes.length > 0 ? (
          <div className="chats__container">
            <div className="chats__wrapper">
              {/*<Spin size="large" spinning={isLoading} indicator={<Loader />}>*/}
                <ul className="chats__wrapper__chats__list">
                  {map(props.notes, (note, index) => (
                    <li className={`has__avatar ${note.author_id === userId ? 'active' : ''}`} key={index}>
                      <div className="chats__card__header">
                        <div className="chats__card__avatar">
                          {note.avatar ? (
                            <img src={note.avatar} alt="" />
                          ) : (
                            <span className="string__avatar">{createStringAvatar(note.author_full_name)}</span>
                          )}
                        </div>
                        <span className="chats__card__name">
                          {note.author_full_name}
                        </span>
                        <span className="chats__card__time">
                          {moment(note.created).format('MM/DD/YY [at] hh:mm A')}
                        </span>
                      </div>
                      {editNoteId && editNoteId === note.id ? (
                           <div className="chats__card__edit">
                            <textarea
                              className="chats__card__textarea__edit"
                              type="text"
                              name="editNote"
                              value={editNote}
                              onChange={handleEditNoteChange}
                              ref={(editRef) => editNoteId && editRef && editRef.focus()}
                              onKeyDown={handleEditNoteKeyDown}
                              onKeyUp={handleEditNoteKeyDown}
                              style={{ resize: 'none', overflowY: 'hidden' }}
                              onFocus={handleEditNoteKeyDown}
                            >
                            </textarea>
                            <button
                              onClick={handleSubmit}
                              disabled={editNote !== '' && editNote.trim() !== '' ? false : true}
                              className="btn btn__btn btn__xs btn-dark mr__15"
                            >
                              Save
                            </button>
                            <span onClick={handleCancelEditNote}><CloseIcon className="chats__card__edit__close" /></span>
                          </div>
                          ) : (
                          <div className="chats__content">
                            <div
                              dangerouslySetInnerHTML={{ __html: correctFaultyHtmlTags(note.note) }}
                            ></div>
                          </div>
                        )}
                      {note.author_id === userId && !editNoteId && (
                        <div className="chats__card__cta">
                          <button className="btn btn__edit_chat" onClick={() => editNotes(note)}>Edit</button>
                          <button
                            className="btn btn__delete_chat"
                            onClick={() => deleteNotesConfirmation(note.id)}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              {/*</Spin>*/}
              </div>
            </div>
            ) : (
              <div className="drawer__body__inner height__remove">
                <div className="blank__state__text">
                  No Internal Notes Found.
                </div>
              </div>
            )}
          <div className="chats__container__teatarea">
            <textarea
              className="chats__sectiion__input"
              placeholder={`Write an internal note about ${get(props, 'clientDetail.client_full_name', 'client')}...`}
              // ref={(current) => !editNoteId && current && current.focus()}
              // onKeyDown={handleKeyDown}
              value={note}
              // onKeyUp={handleKeyDown}
              // style={{ resize: 'none', overflowY: 'hidden' }}
              // onFocus={handleKeyDown}
              onChange={handleChange}
            ></textarea>
            <div className="chats__section__cta">
              <button
                type="button"
                className="btn btn__btn btn-dark btn__send"
                disabled={note !== '' && note.trim() !== '' ? false : true}
                onClick={handleSubmit}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      <DeleteNotes
        openModel={deleteConfirmationModal.open}
        noteId={deleteConfirmationModal.noteId}
        closeDeleteNotesModal={closeDeleteNotesModal}
        {...props}
      />
    </Fragment>
  )
}
