import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies();
class ImpersonatUserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      email: "",
      magiclinkmsg: "",
      password: "",
      ru:"",
      err: {},
      errMessage: ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount(){
  }

  componentWillMount(){
    if(this.getParameterByName('ru') && this.getParameterByName('ru') != '' && this.getParameterByName('ru') != null){
        this.setState({ru:this.getParameterByName('ru')})
    }
    window.localStorage.setItem('clientToken', null);
    //window.FbeventHander("Login Page loaded");
    //gtag('event','Login Page loaded');
    //ga('send', 'event', 'Login', 'Load', 'Login Page loaded', '1');

  }

  // Function to set form input values in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

   componentWillReceiveProps(nextProps) {
    let backendUrl = config.url.backendUrl;
    if (nextProps.clientloginPhase===true){
      this.setState({magiclinkmsg:nextProps.user.message})
    }
    if (nextProps.clientloginPhase===false) {
      //setTimeout(function(){
        alert('Sorry, we cannot find that user, please try again or email us at info@suburbanjunglegroup.com');
     // }, 500)
    }
    //this.setState({ isLoading: false, errMessage: nextProps.loginError.message})
  }

   getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

//   validateEmailandPhone(textinput) {
//     var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
//     return re.test(String(textinput).toLowerCase());
// }

  // Function to submit login form data
  handleClick() {
    const err = {}
    this.setState({ errMessage: "" })
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    } else if (!this.validateEmail(this.state.email)) {
       err.email = 'Invalid email'
    }
    // if (this.state.password === '' || this.state.password.trim() === '') {
    //   err.password = 'Password is required.'
    // }
    this.setState({ err })
    if (!Object.keys(err).length) {
      // Static redirect due to API not worked.
      //window.location.replace(`/dashboard`);
      const { loginUserClient } = this.props
      const data = new FormData();
      data.append('email', this.state.email.toLowerCase());
      if(this.state.password !=''){
      data.append('password', this.state.password);
      }
      if(this.state.ru !=''){
      data.append('ru', this.state.ru);
      }
      loginUserClient(data)
    }
  }
  render() {
    const {
      user
    } = this.props
    if(user && user.role) {
    if(user.role === 'superadmin' || user.role === 'admin' || user.role === 'stratagist') {
        alert('You are not authorized to impersonat this user.')
         window.history.back();
      }
    }else{
      return(
        <Redirect to={`/login`}/> 
      )
    }

    return (
      <section className="login__container">
      <div className="container-fluid">
          <div className="row pop-up-header" >
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left-align">
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 right-align">
              <div className="row">
                <a href={this.state.wpUrl}>
                <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-10 center-align">
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 right-align">
              <div className="close">
                <a href={`${this.state.wpUrl}`}>
                <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="LoignSection">
          <div className="overlay-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="v-container">
                  <div className="login-box">
                    <h3>Client Login</h3>
                    <div className="col-md-12">
                      <div className="row">
                        impersonating User information... please wait a second.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'impersonatuser',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ImpersonatUserForm)
