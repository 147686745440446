import React, { PureComponent /*, PropTypes*/ } from 'react';
import AroundTown from './AroundTown/container';
import GooglePlaces from './GooglePlaces/container';
import { Switch, Route } from 'react-router-dom';
import get from 'lodash/get';
import concat from 'lodash/concat';
import axios from 'axios';
import './styles.scss';
import { logoutInvalidRequest } from '../../../../../../utils/helper';

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;

export default class TownLifeComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      places: []
    };
  }

  static propTypes = {
    // PropTypes go here
  };

  getGooglePlacesData = async placeType => {
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const fetchGooglePlace = () =>
      axios.get(
        `${HOSTNAME}/getGooglePlaces?town_id=${townId}&placeType=${placeType}`
      ).catch(logoutInvalidRequest);
    const googlePlace = await fetchGooglePlace();
    if (googlePlace && googlePlace.data && googlePlace.data.data) {
      return googlePlace.data.data;
    } else {
      return [];
    }
  };

   getGooglePlace = async (googlePlaceType, value) => {
    const { town } = this.props;
    const googlePlaces = () =>
      axios.get(
        `${HOSTNAME}/google_places?lat=${town.lat}&lng=${town.lng}&type=${googlePlaceType}&from=jungler`
      ).catch(logoutInvalidRequest);
    const raw = await googlePlaces();
    const data = get(raw, 'data.data', []);
    const placeArr = await this.getGooglePlacesData(googlePlaceType);
    const concatAllPlaces = concat(placeArr, data);
    this.props.toggleLoading(false);
    this.setState({
      places: concatAllPlaces,
      isLoading: false,
      editMode: false,
      addPlaceModal: false,
      selectedPlace: this.state.selectedPlaceInitial
    });
  }

  render() {
    const { baseUrl, location } = this.props;
    const { places } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs__control mt__15">
              <button
                onClick={() => this.props.history.push(`${baseUrl}/town-life`)}
                className={`btn btn-link ${!get(location, 'pathname').includes(
                  'google-places'
                ) && 'active'}`}
              >
                Around Town
              </button>
              <button
                onClick={() =>
                  this.props.history.push(`${baseUrl}/town-life/google-places`)
                }
                className={`btn btn-link ${get(location, 'pathname').includes(
                  'google-places'
                ) && 'active'}`}
              >
                Google Places
              </button>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path={`${baseUrl}/town-life`}>
            <AroundTown {...this.props} />
          </Route>
          <Route exact path={`${baseUrl}/town-life/google-places`}>
            <GooglePlaces
              getGooglePlace={this.getGooglePlace}
              places={places}
              {...this.props}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}
