import React, { Component } from 'react';
import { DatePicker } from 'antd'
import { CaretRightIcon, DownCaretIcon } from '../../../../../../../components/icons'
import { get, map } from 'lodash'
import moment from 'moment';
import { SUCCESS } from '../../../../../../../constants/phase'

export default class MobileTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  static getDerivedStateFromProps(props, state) {
    if (props.updateFollowUpDatePhase === SUCCESS) {
      props.clearFollowUpPhase()
      props.fetchFilterResult()
    }
    return state
  }

  handleDateChange = (clientId, strategistId, date) => {
    const data = {
      clientId,
      date: moment(date).format("YYYY-MM-DD"),
      strategistId
    }
    this.props.updateFollowUpDate(data)
  }

  formatPriceRange = (client) => {
    const rangestr = client && client.price_range;
    if (rangestr.match(/\-/)) {
      const rangearr = rangestr.split('-');
      return `$${parseInt(rangearr[0]).toLocaleString()} - $${parseInt(rangearr[1]).toLocaleString()}`;
    } else if (rangestr.match(/\+/)) {
      const rangearr = rangestr.split('+');
      return `$${parseInt(rangearr[0]).toLocaleString()}`
    }
    return ''
  }

  goToClientDetailPage = (client) => this.props.history.push({
    pathname: `/strategist/client-details/${client.client_id}`,
    state: { client, prevRoute: this.props.history.location.pathname }
  });

  disabledDate = (current) => current && current < moment.utc().startOf('day');

  render() {
    const { clients, currentUser } = this.props;
    return (
      <div className="mob__view__container mob__view__control">
        <table className="table mob__custom__table">
          <tbody>
            {clients && clients.length > 0 ?
              map(clients, (client, index) => {
                let overDueRedClass = 'datepicker__follow-up-date'
                if (client.follow_up_date && client.follow_up_date.length === 1 && client.follow_up_date[0].follow_up_date && client.follow_up_date[0].follow_up_date !== '') {
                  const timeZone = get(currentUser, 'time_zone', 'America/New_York')
                  let followupDate = moment.utc(client.follow_up_date[0].follow_up_date).format('YYYY-MM-DD')
                  let nowDate = moment().tz(timeZone).format('YYYY-MM-DD')
                  if (followupDate === nowDate || followupDate < nowDate) {
                    overDueRedClass = 'datepicker__follow-up-date-red'
                  } else {
                    overDueRedClass = 'datepicker__follow-up-date'
                  }
                }
                return (
                  <tr key={index}>
                    <td onClick={() => this.goToClientDetailPage(client)}>
                      <div className="client__name">{client.client_full_name}</div>
                      <div className="client__budget">{this.formatPriceRange(client)}</div>
                    </td>
                    <td>

                      {client.follow_up_date && client.follow_up_date.length === 1 &&
                        <span className="mob__follow__date">
                          <div className="btn__follow-up-date">
                            <div className="follow-up-date-label">Follow up date</div>
                            <DatePicker
                              className={overDueRedClass}
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              defaultValue={client && client.follow_up_date && client.follow_up_date[0].follow_up_date}
                              onChange={(date) => this.handleDateChange(client.client_id, client.follow_up_date[0].strategist_id, date)}
                              value={(client && client.follow_up_date && client.follow_up_date[0].follow_up_date) && moment.utc(client.follow_up_date[0].follow_up_date)}
                              format="MM/DD/YYYY"
                              disabledDate={this.disabledDate}
                              inputReadOnly={true}
                              allowClear={false}
                              placeholder="N/A"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                          <span>
                            <CaretRightIcon className="right-icon" />
                          </span>
                        </span>
                      }
                      {client.follow_up_date && client.follow_up_date.length >= 2 &&
                        <button className="btn btn__link__gray follow_mobile-link">Multiple Follow Up Dates </button>}
                    </td>
                  </tr>
                )
              }) :
              <tr>
                <td className="blank__table__state">No Clients</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }
}
