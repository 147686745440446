import 'rxjs'
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

class Header extends Component {
  componentWillMount() {
    // const { history: { location: {state} } } = this.props
    // this.setState({ town_name: state })
  }

  render() {
    const { title } = this.props
    return (
      <div className="full__page--header" style={{marginBottom: "17px"}}>

        {/*  <div className="row">
            <div className="col-md-8 col-xs-10">
              <div className="logo-header">
              <h3>{title}</h3>
              </div>
            </div>
            <div className="col-md-4 col-xs-2">
              <div className="close-btn">
                <Link to={'/town-report'}>
                  <img src="img/close.svg" />
                </Link>
              </div>
            </div>
          </div>*/}

      </div>
    )
  }
}

export default reduxForm({
  form: 'TownReportDilogHeader', // a unique identifier for this form
  destroyOnUnmount: true
})(Header)
