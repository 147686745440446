import { connect } from 'react-redux'

import ActivityHistoryComponent from './component'
import { clearActivityHistoryPhase } from '../../../../../store/strategistV2/clients/action'
import { fetchClientActivityHistory, clearActivityPhase } from '../../../../../store/strategistV2/activity/actions'

const ActivityHistoryContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    fetchClientByIdPhase: state.strategistV2.client.fetchClientByIdPhase,
    fetchActivityHistoryPhase: state.strategistV2.client.fetchActivityHistoryPhase,
    activityHistories: state.strategistV2.activity.activityHistories,
    fetchClientActivityHistoryPhase: state.strategistV2.activity.fetchClientActivityHistoryPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientActivityHistory,
    clearActivityHistoryPhase,
    clearActivityPhase
  }
)(ActivityHistoryComponent)

export default ActivityHistoryContainer
