import { connect } from 'react-redux'
import BusinessDevelopmentForm from './component'
import { getFilterParams} from '../../../store/dashboard/duck'

import { getAgentList, getAgentStatistics, getBusinessDevelopmentData } from '../../../store/analytics/duck'

const BusinessDevelopment = connect(
  // Map state to props
  (state) => ({
  	agentPhase: state.analytics.agentPhase,
  	agentListData: state.analytics.agentListData,
  	filterParams: state.dashboard.filterParams,
    agentStatisticsPhase: state.analytics.agentStatisticsPhase,
    agentStatisticsData: state.analytics.agentStatisticsData,
    businessDevelopmentPhase: state.analytics.businessDevelopmentPhase,
    businessDevelopmentData: state.analytics.businessDevelopmentData
  }),
  // Map actions to props
  {
  	getAgentList,
    getFilterParams,
    getAgentStatistics,
    getBusinessDevelopmentData
  }
)(BusinessDevelopmentForm)
export default BusinessDevelopment
