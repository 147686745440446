import 'rxjs'
import React, { Component } from 'react'
import _ from "lodash";
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
import axios from 'axios'
import Snackbar from 'material-ui/Snackbar'
import Header from './Header'
import PlaceSearchBox from '../../../components/forms/PlaceSearchBox'
import { SelectField } from 'redux-form-material-ui'
import MenuItem from 'material-ui/MenuItem'
import validate from '../validate'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { logoutInvalidRequest } from '../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()

const menuStyle = {
  left: '-19px'
}
const labelStyle = {
  'textAlign': 'left', 'marginLeft': '0px'
}
const floatingLabelStyle = {
  left: '0'
}

let _user = ''

class CreateTownReport extends Component {
  townTime_activity = null
  intervalID = 0
  constructor(props) {
    super(props)
    this.state = {
      suburb_name: 'Select Suburb Name',
      openSnackbar: false,
      message: '',
      hours:0,
      minutes:0,
      seconds:0,
    }
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillMount() {
    _user = cookies.get('user')
    if (_user) {
      this.setState({ userid: _user.id, current_role: _user.role })
    }
    const { getFilterParams } = this.props
    getFilterParams()
    
    /*This is for town time sheet*/
    /*
      if(_user && _user.role === 'strategist' || _user.role =='admin'){
        let splitVal = this.props.location.pathname && this.props.location.pathname.split('/')
        if(splitVal){
          if(splitVal[1] === "create-report"){
            this.townTime_activity = localStorage.getItem('townStrategistTime')
            if(!this.townTime_activity || this.townTime_activity == 'null'){
              let obj = {
                strategist_id:_user.id,
                // town_id: parseInt(townId),
                client_id: 0,
                start_date: new Date(),
                start_time: new Date(),
                activity_log: `Creating Town Report`
              }
              localStorage.setItem('townStrategistTime', Date.now())
              this.townTime_activity = Date.now()
              axios({
                method: 'POST',
                url: HOSTNAME+'/user/add_client_strategist_timesheet',
                data: obj
              })
              .then((res) => {
                if(res.data && res.data && res.data.status){
                  localStorage.setItem('townTimeId', res.data.data[0])
                }else{
                  localStorage.setItem('townStrategistTime', 'null')
                  this.townTime_activity = 'null'
                }
              }).catch(logoutInvalidRequest)
            }
          }
        }
      }
      */
      /*This is for town time sheet*/
  }

  componentWillReceiveProps(np) {
    const { generateReportPayload, history } = np
    if (generateReportPayload) {
      const { status, message, success, townId } = generateReportPayload
      if (status) {
        history.push(`/edit-report/community/${townId}`)
      }
      if (!success) {
        this.setState({ openSnackbar: !status, message })
      }
    }
  }

  componentDidMount(){
    if(this.townTime_activity && this.townTime_activity != 'null'){
      this.startInterval()
    }
  }

  componentWillUnmount() {
    const { clearTownPhase } = this.props
    clearTownPhase()
    clearInterval(this.intervalID);
    /*
    if(_user) { 
      if(_user.role =='admin' || _user.role == 'strategist'){
        let townTimeId = localStorage.getItem('townTimeId')
        if(townTimeId && townTimeId != 'null'){
          let pathname = window && window.location.pathname
          if(pathname && pathname != ''){
            let splitPath = pathname.split('/') 
            if(splitPath && splitPath.length > 0 && (splitPath[1] === 'create-report')){

            }else{
              if(splitPath[1] !== 'edit-report'){
                let obj = {
                  id: townTimeId,
                  strategist_id:_user.id,
                  client_id: 0,
                  end_date: new Date(),
                  end_time: new Date(),
                  status: 1
                }
                axios({
                  method: 'POST',
                  url: HOSTNAME+'/user/add_client_strategist_timesheet',
                  data:obj
                })
                .then((res) => {
                  if(res.data && res.data && res.data.status){
                    localStorage.setItem('townStrategistTime', 'null')
                    localStorage.setItem('townTimeId', null)
                    this.townTime_activity = 'null'
                    this.forceUpdate()
                  }else{
                    localStorage.setItem('townStrategistTime', 'null')
                    localStorage.setItem('townTimeId', null)
                    this.townTime_activity = 'null'
                  }
                }).catch(logoutInvalidRequest)
              }
            }
          }
        }
      }
    }
    */
  }

  handleRequestClose() {
    this.setState({ openSnackbar: false }, () => {
      this.props.clearTownPhase()
    })
  }

  startInterval = () => {
    this.intervalID =  setInterval( () => { 
      var a = new Date()
      var b = new Date(parseInt(this.townTime_activity))
     
      a = moment.tz(a, _user.time_zone)
      b = moment.tz(b, _user.time_zone)

      var diff = Math.abs(a - b) / 1000;
       // get hours
      var hours = Math.floor(diff / 3600) % 24;
      // get minutes
      var minutes = Math.floor(diff / 60) % 60;
      
      minutes = (parseInt(minutes) < 10) ? "0" + parseInt(minutes) : parseInt(minutes)
      // get seconds
      var seconds = diff % 60;
      seconds = (parseInt(seconds) < 10) ? "0" + parseInt(seconds) : parseInt(seconds)
      this.setState({ 'hours': parseInt(hours), 'minutes': minutes, 'seconds': seconds})
      if(parseInt(minutes) === 10){
        this.stopTime('')
      }
    }, 1000)
  }

  startTime(data){
    /*
    const { match: { params: { townId }} } = this.props
    let obj = {
      strategist_id: _user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: `Creating Town Report`
    }
    localStorage.setItem('townStrategistTime', Date.now())
    this.townTime_activity = Date.now()
    this.startInterval()
    this.forceUpdate()
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('townTimeId', res.data.data[0])
      }else{
        localStorage.setItem('townStrategistTime', 'null')
        this.townTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  stopTime(data){
    /*
    const { match: { params: { townId }} } = this.props
    clearInterval(this.intervalID);
    let townTimeId = localStorage.getItem('townTimeId')
    let obj = {
      id: townTimeId,
      strategist_id:_user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      end_date: new Date(),
      end_time: new Date(),
      status: 1
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('townStrategistTime', 'null')
        localStorage.setItem('townTimeId', null)
        this.townTime_activity = 'null'
        this.forceUpdate()
      }else{
        localStorage.setItem('townStrategistTime', 'null')
        localStorage.setItem('townTimeId', null)
        this.townTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  filterStrategist(val) {
    const { change } = this.props
    change('suburb_name', val.name)
    change('suburb_id', val.id)
  }

  handleChangePlace = async(suggestion, place) => {
    const { change } = this.props
    if (place) {
      var split = place.formatted_address.split(', ')
      if (split && split.length > 2) {
        split = split[0] + ', '+split[1]
      } else {
        split.splice(-1)
        split = split.join(',')
      }
      try {
        const { lat, lng } = await getLatLng(place)
        change('town_name', place.name)
        change('town_lat', `${lat}`)
        change('town_lng', `${lng}`)
        change('google_place_id', place.place_id)
        change('formatted_address', split)
      } catch (err) {
      }
    }
  }

  onSubmit(data) {
    const { generateReport } = this.props
    generateReport(data)
  }

  close(e){
    this.stopTime('')
    const { history } = this.props
    history.push({ pathname: "/town-report" })
  }

  render() {
    const { hours, minutes, seconds } = this.state
    const { handleSubmit, history, filterParams, generateReportPayload, disableBtn } = this.props
    const inputProps = {}
    let allData = filterParams.data
    let removeSuburbs = this.props.history && this.props.history.location

    let suburbsArray = []
    if(this.state.current_role === 'strategist'){
        if(allData && allData.suburbs){
          _.forEach(allData.suburbs, a => {
              var o = _.find(removeSuburbs.state, b => a.id == b)
              if (o) {
                suburbsArray.push(a)
              }
          })
        }
    }else{
      suburbsArray = allData && allData.suburbs
    }

    return (
      <section className="town-report">
        <Header title="Create Town Report"/>
        <div className="container full__page--header">
        {/* <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="logo-header report__header">
                <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Add Town Report</span></a>
            </div>

            <div className="close-btn close-btn__townreport">
            <img className="common__close--right" onClick={this.close.bind(this)} src="img/close.svg"/>
            </div>
          </div>
          </div> */}

          <div className="row">
          <div className="col-md-12">
          <div className="profile__header--wrapper profile__header--wrappertown">
            <div className="profile__header">
              <a>
                <img src="/img/logo.svg" />
                <h4>
                  <span>|</span>Add Town Report
                </h4>
              </a>
            </div>
            
            {
              _user && _user.role && (_user.role === 'admin' || _user.role ==='strategist') && this.townTime_activity === 'null' ?
                <button className="btn btn__green strategy-right btn__profile start__btn--table font500 start_stop_button" onClick={this.startTime.bind(this)}><img src="img/playbutton.svg" /> Start</button>
              : ''
            }
            {
              _user && _user.role && ( _user.role ==='admin' || _user.role ==='strategist') && this.townTime_activity !== 'null' ?
                  <button className="btn btn__green strategy-right btn__profile play__btn--table font500 start_stop_button" onClick={this.stopTime.bind(this)}><img src="img/stopbutton.svg" /> Stop ({hours}:{minutes}:{seconds})</button>
                : ''
            }


            <div
              className="profile__close"
              onClick={this.close.bind(this)}
            >
              <img src="img/close.svg" />
            </div>
          </div>
          </div>

        </div>

        {/* <img className="common__close--right" onClick={this.close.bind(this)} src="img/close.svg"/> */}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <p className="townrep__para">Search and select the correct town for which you want to create a report</p>
              </div>

              <div className="col-md-6 col-sm-6 col-xs-12" style={{ marginTop: '30px' }}>
                <label className="labelinputs__global">Suburb Name</label>
                <Field
                  name="suburb_name"
                  component={SelectField}
                  type="text"
                  hintText="Suburb Name"
                  className="selectinputs__global no__hr"
                  menuStyle={menuStyle}
                  maxHeight={200}
                  labelStyle={labelStyle}
                >
                  {suburbsArray && suburbsArray.map((suburb, i) => {
                    return(<MenuItem key={i} value={suburb.name} onClick={() => this.filterStrategist(suburb) } primaryText={suburb.name} />)
                  })}
                </Field>
         
                <label className="labelinputs__global" style={{ marginTop: '40px' }}>Enter Town Name and State</label>
                <Field
                  type="text"
                  name="town_name"
                  hintText="Enter Town Name and State"
                  component={PlaceSearchBox}
                  // className="search-placebox search-placebox--hr"
                  className="textinputs__global no__hr"
                  {...inputProps}
                  id="placeSearchBoxField"
                  onSelectPlace={this.handleChangePlace}
                  style={{marginBottom :'40px', width: '100%'}}
                />

  
                {disableBtn ?
                  <img className="loader-main" src="/img/loader2.svg"/> :
                  <button type="submit" disabled={disableBtn} className="pull-right town-button generatereport__mobbtn">
                    Generate Report
                  </button>
                }
              </div>
              <Snackbar open={this.state.openSnackbar} message={this.state.message} autoHideDuration={2000} onRequestClose={this.handleRequestClose} />
            </div>
          </form>
        </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'CreateTownReport', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: [],
  validate
})(CreateTownReport)