import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import Config from '../../../../../../config';

//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AdTrackingReportComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      adTrackingReportData: [],
      search: '',
      sortByKey:'name',
      todayDate: moment().format('LL'),
      sortByType: 0,
      showAll: true,
      selectedIndex: 'all'
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    if (_user && (_user.role === "superadmin" || _user.role === "admin")) {
      this.setState({ isLoading: true, userRole: _user.role })
      const data = {}
      data.user_id = _user.id
      // const strData = {
      //   from  : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   to    : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   type  : this.state.type,
      //   sortBy: {key: this.state.sortByKey, type: this.state.sortByType},
      //   limit: 100
      // }
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        // strData.type = this.state.type
        strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType };
      }
      this.props.getAdTrackingCodeReport(strData)
      // this.props.getAdTrackingCodeReport()
    } else {
      this.props.history.push(`/administration/dashboard`)
    }

  }

  componentDidUpdate(preProps, preState) {
    const { adTrackingData, adTrackingPhase, clearReportV2Phase } = this.props
    if (adTrackingPhase === "success") {
      clearReportV2Phase()
      let data = []
      if (_.size(adTrackingData && adTrackingData.data) > 0) {
        adTrackingData && adTrackingData.data && adTrackingData.data.map((val, i) => {
          data.push({
            key: i,
            client_name: val.client_name,
            client_status: val.status,
            ad_tracking_codes: val.ad_tracking_codes,
            booked_how: val.booked_by ? val.booked_by : 'Created in Jungler',
            export_created_date: val.created ? moment(val.created).format('MMMM Do YYYY, h:mm a') : 'N/A',
            created_date: val.created
          })
        })
      }
      this.setState({ adTrackingReportData: data, isLoading: false })
    }
    if (adTrackingPhase === "error") {
      clearReportV2Phase()
      this.setState({ adTrackingReportData: [], isLoading: false })
    }
  }

  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      // type : this.state.type
     sortBy : { key: this.state.sortByKey, type: this.state.sortByType }
    }
    this.props.getAdTrackingCodeReport(strData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  render() {
    const { adTrackingReportData } = this.state

    let adTrackingReportArr = []
    // let townAgentsData =  tableData
    if (adTrackingReportData) {
      adTrackingReportArr = adTrackingReportData.filter(
        (data) => {
          return (
            (data.client_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }


    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back to Dashboard</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Ad Tracking Report</h2>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                      <ExcelSheet data={adTrackingReportArr} name="ad_tracking">
                        <ExcelColumn label="Client Name" value="client_name" />
                        <ExcelColumn label="Client Status" value="client_status" />
                        <ExcelColumn label="Booked How" value="booked_how" />
                        <ExcelColumn label="Ad Tracking Codes" value="ad_tracking_codes" />
                        <ExcelColumn label="Created Date" value="export_created_date" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by client name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>

              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(adTrackingReportArr) > 0) ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Table
                            className="table__fixed"
                            sortOrde={true}
                            columns={[
                              {
                                title: 'Client Name',
                                width: 250,
                                dataIndex: 'client_name',
                                key: '1',
                                fixed: 'left',
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.client_name.localeCompare(b.client_name)
                              },
                              {
                                title: 'Client Status',
                                width: 320,
                                dataIndex: 'client_status',
                                key: '2',
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.client_status.localeCompare(b.client_status),
                              },
                              {
                                title: ' Booked How',
                                width: 320,
                                dataIndex: 'booked_how',
                                key: '3',
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.booked_how.localeCompare(b.booked_how)
                              },
                              {
                                title: 'Ad Tracking Code',
                                width: 400,
                                dataIndex: 'ad_tracking_codes',
                                key: '4',
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes)
                              },
                              {
                                title: 'Created Date',
                                width: 330,
                                dataIndex: 'created_date',
                                key: '5',
                                sortDirections: ['ascend', 'descend', 'ascend'],
                                sorter: (a, b) => a.created_date.localeCompare(b.created_date),
                                render: (text, val) => {
                                  return (
                                    <div>
                                      {val.created_date ? moment(val.created_date).format('MMMM Do YYYY, h:mm a') : 'N/A'}
                                    </div>
                                  )
                                }
                              },
                            ]}
                            dataSource={adTrackingReportArr}
                            pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions }}
                            scroll={{ y: 420 }}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}


