import { connect } from 'react-redux'

import AgentTopNavigationComponent from './component'

const AgentTopNavigationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(AgentTopNavigationComponent)

export default AgentTopNavigationContainer
