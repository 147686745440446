import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import './styles.scss'

export default class BackgroundProfileComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      clientData: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.clientData !== _.get(props.clientData, 'users', [])) {
      return { clientData: _.get(props.clientData, 'users', []) }
    }
    return null;
  }

  render() {
    const { clientData } = this.state
    let profileHTML = unescape(_.get(clientData, 'background_profile', ''))
    var tmp = document.createElement("DIV");
    tmp.innerHTML = profileHTML;
    let htmlDoc = (tmp && tmp.textContent) || (tmp && tmp.innerText) || "";
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb__3">Background Profile</h5>
            {
              (_.isEmpty(htmlDoc)) && 
              <p className="card-subtitle">Updated on <strong>{moment(clientData.background_profile_modified).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</strong> by <strong>Douglas Strategist</strong></p>
            }
          </div>
          <div className="card-body card-body-sm">
            {
              !_.isEmpty(htmlDoc) && (htmlDoc !== "undefined") && (htmlDoc !== "null") ?
                <p className="background__profile__text font__16 mb__0">{htmlDoc}</p>
                :
                <div className="blank__state__text">No Background Information</div>
            }
          </div>
        </div>
      </div>
    )
  }
}
