import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Tooltip from "react-tooltip";
import Modal from 'react-responsive-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AgentsAssignByStrategist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      fromDate: moment().add(-1, 'month').startOf('month'),
      // fromDate: moment().add(-5, 'years').startOf('month'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      type: 'assignToStrategist',
      sortByKey:'name',
      sortByType: 0,
      search:'',
      agentName: '',
      currentAgentTags:[],
      open: false,
      selectedIndex: 'all',
      clientsAssignToAgentState:[],
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment().add(-1, 'month').startOf('month'),
      defaultEndDate: moment()
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      // strData.strategist_arr = this.state.client_strategist
      // this.props.getStrategistList(strData)

      this.props.getClientsPerAgents(strData)
      this.setState({isLoadingData: true})
      // this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.clientsPerAgentsPhase === "success"){
      const clientsAssignToAgent = []
      if(nextProps.clientsPerAgentsData && nextProps.clientsPerAgentsData.length > 0){
        nextProps.clientsPerAgentsData.map(function(data){
          let obj ={}
          obj.name = data.name
          
          obj.totalClients =  data.totalClients
          obj.totalTag = data.totalTag.length > 0 ? data.totalTag.map(e => { return e }).join(" ,") : ""
          obj.totalPattiClients = data.totalPattiClients  
          obj.totalErikaClients = data.totalErikaClients
          obj.totalMayaClients= data.totalMayaClients
          obj.totalRobinClients= data.totalRobinClients
          obj.totalAllisonClients= data.totalAllisonClients
          clientsAssignToAgent.push(obj)
        })
        this.setState({ isLoadingData: false, clientsAssignToAgentState:clientsAssignToAgent },()=> {})
        // this.props.clearPhase()
      }else{
        this.setState({ isLoadingData: false, clientsAssignToAgentState:clientsAssignToAgent },()=> {})
        // this.props.clearPhase()
      }
    }
  }

  // handleFromDate(date) {
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.from = selectDate
  //       strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "from_date"
  //     strData.type = this.state.type
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date){
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.to = selectDate
  //       strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "end_date"
  //     strData.type = this.state.type
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

      let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
      let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
      setTimeout(function(e){
        const strData = {}
        if(this.state.selectedIndex === "all"){
          strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
          strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        }else{
          strData.from = start_from_date
          strData.to = end_to_date
        }
        strData.dateType = "from_date"
        strData.type = this.state.type
        strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getClientsPerAgents(strData)
      }.bind(this), 300)
    })
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }else{
      sortByType == 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const strData = {}
    if(this.state.selectedIndex === "all"){
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
    strData.type = this.state.type
    strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getClientsPerAgents(strData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  filterDate(e){
    this.setState({ isLoadingData: true })
    if(e.target.value === "all"){
      this.setState({ modalIsOpen: false })
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }else{
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }
    this.setState({ selectedIndex: e.target.value },() => {})
  }

  onOpenModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if(tags[0].length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTags: tags[0] })
    }
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  render() {
    const { clientsPerAgentsData, clientsPerAgentsPhase } = this.props
    let filterList  = []
    const { selectedIndex, open, clientsAssignToAgentState } = this.state
    const clientsAssignToAgent = clientsAssignToAgentState
    if(clientsPerAgentsPhase == 'success' && clientsPerAgentsData && clientsPerAgentsData.length > 0){
      filterList = clientsPerAgentsData.filter(
        (agent) =>{
          return (
            (agent.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Agents assigned by NY SJ Strategists</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{clientsPerAgentsData && clientsPerAgentsData.length}</span> Agents</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">

              {/* <span className="defaultCheckboxContainer">
                <label className="defaultChecboxCustom">
                <input type="checkbox"  checked = {selectedIndex} className="custom-select-option-checkbox" onChange={this.filterDate.bind(this,!selectedIndex)}/>
                 <span>
                Show all results
                </span>
                </label>
              </span> */}
              <div className="right__cont right__cont--full">
              <div className="all__custom--wrapper">
                  <select 
                  className="date__picker"
                    name="selectedIndex"
                    value = {this.state.selectedIndex} 
                    onChange={this.filterDate.bind(this)}>
                    <option value="all">All</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                {
                  (this.state.selectedIndex !== "all") &&
                  <div className="datepicker__fix all__custom--wrapper">
                    <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                    {
                      (this.state.modalIsOpen) &&
                      <div>
                        <Modal2
                          isOpen={this.state.modalIsOpen}
                          onAfterOpen={this.afterOpenModal}
                          onRequestClose={this.closeModal}
                          style={customStyles}           
                          contentLabel="Example Modal">
                          <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                            <div className="modal-content">             
                              <div className="modal-body text-center">
                              <div className="calendar__header">
                                <h4>Date Range</h4>
                                <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                              </div>
                              <DateRange
                                onInit={this.handleSelect.bind(this)}
                                onChange={this.handleSelect.bind(this)}
                                startDate={this.state.fromDate}
                                endDate={this.state.endDate}
                              />
                              <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                              </div>               
                            </div>
                          </div>
                        </Modal2>
                      </div>
                    }
                  </div>
                }

              {/*<div className={selectedIndex ? "dp-wrapper dp-wrapper-edit marg__rt--ten" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        disabled={selectedIndex}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className={selectedIndex ? "dp-wrapper dp-wrapper-edit filterHideOverride" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        disabled={selectedIndex}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>*/}
            <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
              <ExcelSheet data={clientsAssignToAgent} name="clients_per_agents">
                  <ExcelColumn label="Agent Name" value="name"/>
                  <ExcelColumn label="Total number of Clients" value="totalClients"/>
                  <ExcelColumn label="Patti Natiss clients" value="totalPattiClients"/>
                  <ExcelColumn label="Erika Ades clients" value="totalErikaClients"/>
                  <ExcelColumn label="Maya Konig clients" value="totalMayaClients"/>
                  <ExcelColumn label="Robin Hoberman clients" value="totalRobinClients"/>
                  <ExcelColumn label="Allison Levine clients" value="totalAllisonClients"/>
                  <ExcelColumn label="Tags" value="totalTag"/>
              </ExcelSheet>
            </ExcelFile>
            </div>
            </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container jungler__page--padding">
            <span className="typeahead">
              <input 
                className="costom__search--report typeahead-input reportsearchsminus add" 
                onChange={this.onSearch.bind(this)}
                type="text" 
                name="search" 
                placeholder="Search"/>
            </span>
            <div className="filter__container filter__container--users">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra">
                   <div className="table-responsive">
                     <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="text-left">Agent Name</th>
                            <th className="text-center">Total number of clients</th>
                            <th className="text-center">Patti Natiss clients</th>
                            <th className="text-center">Erika Ades clients</th>
                            <th className="text-center">Maya Konig clients</th>
                            <th className="text-center">Robin Hoberman clients</th>
                            <th className="text-center">Allison Levine clients</th>
                            <th className="text-center">Tags</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="11" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your clients report is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                        {
                          filterList && filterList.map((str, index)=>{
                            const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                            const tagArr = tags[0]
                            let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                            return(
                              <tr key={index} className="forcursor" >
                                <td className="multilines">{str.name}</td>
                                <td className="text-center">{str.totalClients}</td>
                                <td className="text-center">{str.totalPattiClients}</td>
                                <td className="text-center">{str.totalErikaClients}</td>
                                <td className="text-center">{str.totalMayaClients}</td>
                                <td className="text-center">{str.totalRobinClients}</td>
                                <td className="text-center">{str.totalAllisonClients}</td>
                                <td className="text-center"><div className={(tagArr.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div></td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                     </div>
                   </div>
                 </div>
               </div>
              </main>
              <div>
                <Modal open={open} onClose={this.onCloseModal.bind(this)} center>
                  <div>
                    <h2 className="modal-header">{'Agent - ' + this.state.agentName+`'s`+' tag(s)'}</h2>
                    {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                      return <div key={'tag-name-'+index}>{tag}</div>;
                    })}
                  </div>
                </Modal>
              </div>
              {
                clientsPerAgentsData && clientsPerAgentsData.length > 0 ?
                 /*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Agents assigned by NY SJ Strategists</h4></div>*/
                 <div></div>
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'AgentsAssignByStrategistlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AgentsAssignByStrategist)
