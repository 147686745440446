import { connect } from 'react-redux'
import StrategistReportForm from './component'

import { getFilterParams } from '../../../store/dashboard/duck'
import { getStrategistList } from '../../../store/analytics/duck'

const StrategistReporting = connect(
  // Map state to props
  (state) => ({
  	strategistPhase: state.analytics.strategistPhase,
  	strategyData: state.analytics.strategyData,
  	filterParams: state.dashboard.filterParams
  }),
  // Map actions to props
  {
  	getStrategistList,
  	getFilterParams
  }
)(StrategistReportForm)
export default StrategistReporting
