import { connect } from 'react-redux'
import OtpVerifyForm from './component'
import { otpVerification } from '../../store/user/duck'
import { loginUserClient } from '../../store/user/duck'
const OtpVerify = connect(
  // Map state to props
  (state) => ({
  	otpPhase: state.user.otpPhase,
  	otpData: state.user.otpData,
    otpMessage: state.user.otpMessage
  }),
  // Map actions to props
  {
    otpVerification,
    loginUserClient
  }
)(OtpVerifyForm)
export default OtpVerify
