import { connect } from 'react-redux'

import AgentAssignedToTownsComponent from './component'
import { getUSerProfileByID, clearPhase } from '../../../../../../store/dashboard/duck'
import { getFilterParams } from '../../../../../../store/strategistV2/dashboard/actions'
import { getAgentAssignToTown, clearReportV2Phase } from '../../../../../../store/reportV2/duck'
import { getUserById } from '../../../../../../store/strategistV2/strategist/actions'

const AgentAssignedToTownsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    agentAssTownPhase: state.reportV2.agentAssTownPhase,
    agentAssTownData: state.reportV2.agentAssTownData,
    userData: state.strategistV2.strategist.userData
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    getUSerProfileByID,
    clearPhase,
    getAgentAssignToTown,
    clearReportV2Phase,
    getUserById
  }
)(AgentAssignedToTownsComponent)

export default AgentAssignedToTownsContainer
