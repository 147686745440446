import { fetch } from '../../../utils'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

/* 
      ////////////////////////////////
   /// client activity api ///
//////////////////////////////////
 */
// fetch client activity email
export const fetchClientActivityEmail = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/${data.clientId}/emails`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// fetch client activity history to show all activity history
export const fetchClientActivityHistory = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/activity-history`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
