import { Record } from 'immutable'

const UserProfile = new Record({
  id: null,
  email: null,
  firstName : '',
  lastName : '',
  status: null,
  role: '',
  profile_picture: null,
  mobileNumber : '',
  street : '',
  city : '',
  state :'',
  companyName:'',
  pin:'',
})

export default UserProfile
