import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import PropTypes from "prop-types"
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import config from '.././../../../config'
import 'react-select/dist/react-select.css'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
const cookies = new Cookies()
class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      client_status:'over_due',
      client_type:'follow_up_date',
      totalPages:0,
      activePage:1,
      client_limit:100,
      client_offset:1,
      isLoadingData: false,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      sortByKey: 'followUp',
      sortByType : 1,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handelUserfilter = this.handelUserfilter.bind(this)
    this.apiCallforClickData = this.apiCallforClickData.bind(this)
    }
  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      if(_user.role==='superadmin' || _user.role==='admin'){
        // this.setState({client_status: 'all_clients'})
        // this.setState({client_status: 'all_follow_up'})
      }
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)

      setTimeout(function(){
        var locaclientdata = JSON.parse(localStorage.getItem('homeclientdataFollowup'));
        if(locaclientdata && locaclientdata !=null && locaclientdata !=''){
         $('.filter__container--button').removeClass('active');
          this.setState(locaclientdata, ()=>{
            var getname = this.state.client_status.toLowerCase().replace(' ', '_').replace(' ', '_').replace(' ', '_').replace(' ', '_');
            if(getname =='all_clients'){
              getname = 'all_follow_up'
            }
            $( 'button[ name='+getname+']' ).addClass( 'active' );
            this.props.homeFilterClients(locaclientdata)
          })
      }else{
        const clientdata = {}
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
        localStorage.setItem('homeclientdataFollowup', JSON.stringify(clientdata))
        this.props.homeFilterClients(clientdata)
      }
      }.bind(this),300)
    }
  }

  componentDidMount(){
    document.title = "Jungler: Follow Up";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = pageNumber;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handelUserfilter(btn) {
    this.setState({ isLoadingData: true})
    $('.filter__container--button').removeClass('active');
    if(btn.target.name !==''){
      $( 'button[ name='+btn.target.name+']' ).addClass( 'active' );   
      switch(btn.target.name){
        case 'my_follow_up':
            this.setState({client_status:'my_clients', sortByType:0}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.my_follow_up)})
            }
            break;
        case 'city':
            this.setState({client_status:'City'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.city)})
            }
            break;
        case 'green_not_very_engaged':
            this.setState({client_status:'Green - Not Very Engaged'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.green_not_very_engaged)})
            }
            break;
        case 'over_due':
          this.setState({client_status:'over_due', sortByType:1}, ()=>{
            this.apiCallforClickData();
          } );
          if(this.props.clientData && this.props.clientData.user_counts){
            this.setState({totalPages:Math.round(this.props.clientData.user_counts.over_due)})
          }
            break;
        case 'all_follow_up':
          this.setState({client_status:'all_clients', sortByType:1}, ()=>{
            this.apiCallforClickData();
          } );
          if(this.props.clientData && this.props.clientData.user_counts){
            this.setState({totalPages:Math.round(this.props.clientData.user_counts.all_follow_up)})
          }
            break;
        case 'green':
            this.setState({client_status:'Green'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.green)})
            }
            break;
        case 'never_had_a_call':
            this.setState({client_status:'Never Had A Call'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.never_had_a_call)})
            }
            break;
        case 'own_agent_pre':
            this.setState({client_status:'Own Agent - Pre'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.own_agent_pre)})
            }
            break;           
        case 'new':
            this.setState({client_status:'New'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.new)})
            }
            break;
        case 'own_agent':
            this.setState({client_status:'Own Agent'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.own_agent)})
            }
            break;
        case 'out_of_area':
            this.setState({client_status:'Out of Area'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.out_of_area)})
            }
            break;
        case 'no_response':
            this.setState({client_status:'No Response'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.no_response)})
            }
            break;
        case 'yellow':
            this.setState({client_status:'Yellow'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.yellow)})
            }
            break;
        case 'own_agent_post':
            this.setState({client_status:'Own Agent - Post'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.own_agent_post)})
            }
            break;
        case 'closed_2015':
            this.setState({client_status:'Closed-2015'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2015)})
            }
            break;
        case 'closed_2014':
            this.setState({client_status:'Closed-2014'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2014)})
            }
            break;
        case 'pending':
            this.setState({client_status:'Pending'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.pending)})
            }
            break;
        case 'closed_2017':
            this.setState({client_status:'Closed-2017'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2017)})
            }
            break;
        case 'rented':
            this.setState({client_status:'Rented'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.rented)})
            }
            break;
        case 'closed_2016':
            this.setState({client_status:'Closed-2016'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2016)})
            }
            break;
        case 'call_scheduled':
            this.setState({client_status:'Call Scheduled'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.call_scheduled)})
            }
            break;
        case 'closed_2013':
            this.setState({client_status:'Closed-2013'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2013)})
            }
            break;
        case 'reschedule':
            this.setState({client_status:'Reschedule'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.reschedule)})
            }
            break;
        case 'closed_2012':
            this.setState({client_status:'Closed-2012'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2012)})
            }
            break;
        case 'city_do_not_reach_out':
            this.setState({client_status:'City - do not reach out'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.city_do_not_reach_out)})
            }
            break;
        case 'divorced':
            this.setState({client_status:'Divorced'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.divorced)})
            }
            break;
        case 'unsubscribe':
            this.setState({client_status:'Unsubscribe'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.unsubscribe)})
            }
            break;
        case 'never_had_a_call_do_not_reach_out':
            this.setState({client_status:'Never Had a Call - do not reach out'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.never_had_a_call_do_not_reach_out)})
            }
            break;
        case 'closed_2018':
            this.setState({client_status:'Closed-2018'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.closed_2018)})
            }
            break;
        case 'listing':
            this.setState({client_status:'Listing'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.listing)})
            }
            break;
        case 'trying_to_schedule':
            this.setState({client_status:'Trying To Schedule'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.trying_to_schedule)})
            }
            break;
        case 'green_actively_engaged':
            this.setState({client_status:'Green - Actively Engaged'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.green_actively_engaged)})
            }
            break;
        case 'green_somewhat_engaged':
            this.setState({client_status:'Green - Somewhat Engaged'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.green_somewhat_engaged)})
            }
            break;
        case 'untouched_clients_report':
            this.setState({client_status:'Untouched Clients Report'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.untouched_clients_report)})
            }
            break;
        case 'rented_on_own':
            this.setState({client_status:'Rented on own'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.rented_on_own)})
            }
            break;
            
        case 'staying_in_suburbs':
            this.setState({client_status:'Staying_In_Suburbs'}, ()=>{
              this.apiCallforClickData();
            } );
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.staying_in_suburbs)})
            }
            break;

        case 'not_touch_in_four_week':
            this.setState({client_status:'not_touch_in_four_week', sortByType:1}, ()=>{
              this.apiCallforClickData();
            });
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.not_touch_in_four_week)})
            }
            break;

        case 'never_schedule_with_agents':
            this.setState({client_status:'never_schedule_with_agents', sortByType:1}, ()=>{
              this.apiCallforClickData();
            });
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.never_schedule_with_agents)})
            }
            break;

        default:
          this.setState({client_status:'all_clients'}, ()=>{
            this.apiCallforClickData();
          } );
          if(this.props.clientData && this.props.clientData.user_counts){
            this.setState({totalPages:Math.round(this.props.clientData.user_counts.all_follow_up)})
          }
      }
    }else{
      this.setState({client_status:'all_clients'}, ()=>{
        this.apiCallforClickData();
      } );
    }
    }
  
  apiCallforClickData(){
    const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = 1;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      localStorage.setItem('homeclientdataFollowup', JSON.stringify(clientdata))
      this.props.homeFilterClients(clientdata)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      localStorage.setItem('homeclientdataFollowup', JSON.stringify(clientdata))
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handleEntryChange(event){
    this.setState({client_limit: event.target.value, activePage:1 });
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit =  this.state.client_limit;
      clientdata.client_offset = 1
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      localStorage.setItem('homeclientdataFollowup', JSON.stringify(clientdata))
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  componentWillReceiveProps(nextProps){

    this.setState({ isLoadingData: true})
    if(nextProps.clientData && nextProps.clientData.user_counts){
      if(this.state.client_status ==='my_clients'){
      this.setState({totalPages:Math.round(nextProps.clientData.user_counts.my_follow_up)})
      }else if(this.state.client_status ==='all_clients'){
        this.setState({totalPages:Math.round(nextProps.clientData.user_counts.all_follow_up)})
      }else if(this.state.client_status == 'over_due'){
        this.setState({totalPages:Math.round(nextProps.clientData.user_counts.over_due)})
      }else if(this.state.client_status == 'not_touch_in_four_week'){
        this.setState({totalPages:Math.round(nextProps.clientData.user_counts.not_touch_in_four_week)})
      }else if(this.state.client_status == 'never_schedule_with_agents'){
        this.setState({totalPages:Math.round(nextProps.clientData.user_counts.all_follow_up)})
      }
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false})
    }
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const clientdata = {}
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_limit =  this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = {key: val, type: sortByType};
    localStorage.setItem('homeclientdataFollowup', JSON.stringify(clientdata))
    this.props.homeFilterClients(clientdata)
  }

  render() {
    const {user, clientData} = this.props;
    let allFollowups = clientData && clientData.user_counts ? clientData.user_counts.all_follow_up : 0;
    let myFollowups = clientData && clientData.user_counts ? clientData.user_counts.my_follow_up : 0;
    let Overdue = clientData && clientData.user_counts ? clientData.user_counts.over_due : 0;
    let City = clientData && clientData.user_counts ? clientData.user_counts.city : 0;
    let GreenVeryEngaged = clientData && clientData.user_counts ? clientData.user_counts.green_not_very_engaged : 0;
    return (
    <div>
      <main className="clients__section">
        <div className="filter__container">
          <div className="filter__container--filter-name rem-flex">
          <div className="col-md-10 overFlow__filter p_l--rem">
            {
              user && user.role != 'local' ?
              <button name="all_follow_up"  className={this.state.client_status ==='all_clients' ? 'btn filter__container--button' : 'btn filter__container--button'} onClick={this.handelUserfilter.bind(this)}>Current Follow Ups{/*allFollowups*/}</button>
              :''}
              {user && user.role!='superadmin' && user.role != 'strategist' && user.role != 'local' ?
              <button name="my_follow_up" className={this.state.client_status ==='my_clients' ? 'btn filter__container--button active' : 'btn filter__container--button'} onClick={this.handelUserfilter.bind(this)}>My Follow Ups {/*({myFollowups})*/}</button>
              : ''
            }
            <button name="over_due" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>All Overdue Followups{/*({Overdue})*/}</button>
            <button name="not_touch_in_four_week" className="btn filter__container--button " onClick={this.handelUserfilter.bind(this)}>Not touched by Strategist in 4 weeks</button>
            <button name="never_schedule_with_agents" className="btn filter__container--button " onClick={this.handelUserfilter.bind(this)}>Never Scheduled with Agent</button>
           
            {/*<div className="dropdown more-dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">More 
            <img src="./img/menu-dropdown.png" style={{float: 'right', paddingTop: '5px', width: '14px'}} /></a>
              <ul className="dropdown-menu mobile-dropdown mob-filter-dropdown">
                <li><button name="city" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>City ({City})</button></li>
                <li><button name="green_not_very_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Not Very Engaged ({GreenVeryEngaged})</button></li>
                <li><button name="green" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green ({clientData && clientData.user_counts && clientData.user_counts.green ? clientData.user_counts.green : 0}) </button></li>
                <li><button name="never_had_a_call" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Never Had A Call ({clientData && clientData.user_counts && clientData.user_counts.never_had_a_Call ? clientData.user_counts.never_had_a_Call : 0})</button></li>  
                <li><button name="own_agent_pre" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent - Pre ({clientData && clientData.user_counts && clientData.user_counts.own_agent_pre ? clientData.user_counts.own_agent_pre : 0})</button></li>
                <li><button name="new" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>New ({clientData && clientData.user_counts && clientData.user_counts.new ? clientData.user_counts.new : 0})</button></li>
                <li><button name="own_agent" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent ({clientData && clientData.user_counts && clientData.user_counts.own_agent ? clientData.user_counts.own_agent : 0})</button></li>
                <li><button name="out_of_area" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Out of Area ({clientData && clientData.user_counts && clientData.user_counts.out_of_area ? clientData.user_counts.out_of_area : 0})</button></li>
                <li><button name="no_response" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>No Response ({clientData && clientData.user_counts && clientData.user_counts.no_response ? clientData.user_counts.no_response : 0})</button></li>
                <li><button name="yellow" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Yellow ({clientData && clientData.user_counts && clientData.user_counts.yellow ? clientData.user_counts.yellow : 0})</button></li>
                <li><button name="own_agent_post" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent - Post ({clientData && clientData.user_counts && clientData.user_counts.own_agent_post ? clientData.user_counts.own_agent_post : 0})</button></li>
                <li><button name="closed_2015" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2015 ({clientData && clientData.user_counts && clientData.user_counts.closed_2015 ? clientData.user_counts.closed_2015 : 0})</button></li>
                <li><button name="closed_2014" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2014 ({clientData && clientData.user_counts && clientData.user_counts.closed_2014 ? clientData.user_counts.closed_2014 : 0})</button></li>
                <li><button name="pending" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Pending ({clientData && clientData.user_counts && clientData.user_counts.pending ? clientData.user_counts.pending : 0})</button></li>
                <li><button name="closed_2017" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2017 ({clientData && clientData.user_counts && clientData.user_counts.closed_2017 ? clientData.user_counts.closed_2017 : 0})</button></li>
                <li><button name="rented" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Rented ({clientData && clientData.user_counts && clientData.user_counts.rented ? clientData.user_counts.rented : 0})</button></li>
                <li><button name="closed_2016" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2016 ({clientData && clientData.user_counts && clientData.user_counts.closed_2016 ? clientData.user_counts.closed_2016 : 0})</button></li>
                <li><button name="call_scheduled" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Call Scheduled ({clientData && clientData.user_counts && clientData.user_counts.call_scheduled ? clientData.user_counts.call_scheduled : 0})</button></li>
                <li><button name="closed_2013" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2013 ({clientData && clientData.user_counts && clientData.user_counts.closed_2013 ? clientData.user_counts.closed_2013 : 0})</button></li>
                <li><button name="reschedule" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Reschedule ({clientData && clientData.user_counts && clientData.user_counts.reschedule ? clientData.user_counts.reschedule : 0})</button></li>
                <li><button name="closed_2012" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2012 ({clientData && clientData.user_counts && clientData.user_counts.closed_2012 ? clientData.user_counts.closed_2012 : 0})</button></li>
                <li><button name="city_do_not_reach_out" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>City - do not reach out ({clientData && clientData.user_counts && clientData.user_counts.city_do_not_reach_out ? clientData.user_counts.city_do_not_reach_out : 0})</button></li>
                <li><button name="divorced" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Divorced ({clientData && clientData.user_counts && clientData.user_counts.divorced ? clientData.user_counts.divorced : 0})</button></li>
                <li><button name="unsubscribe" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Unsubscribe ({clientData && clientData.user_counts && clientData.user_counts.unsubscribe ? clientData.user_counts.unsubscribe : 0})</button></li>
                <li><button name="never_had_a_call_do_not_reach_out" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Never Had a Call - do not reach out ({clientData && clientData.user_counts && clientData.user_counts.never_had_a_call_do_not_reach_out ? clientData.user_counts.never_had_a_call_do_not_reach_out : 0})</button></li>
                <li><button name="closed_2018" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2018 ({clientData && clientData.user_counts && clientData.user_counts.closed_2018 ? clientData.user_counts.closed_2018 : 0})</button></li>
                <li><button name="listing" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Listing ({clientData && clientData.user_counts && clientData.user_counts.listing ? clientData.user_counts.listing : 0})</button></li>
                <li><button name="trying_to_schedule" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Trying To Schedule ({clientData && clientData.user_counts && clientData.user_counts.trying_to_schedule ? clientData.user_counts.trying_to_schedule : 0})</button></li>
                <li><button name="green_actively_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Actively Engaged ({clientData && clientData.user_counts && clientData.user_counts.green_actively_engaged ? clientData.user_counts.green_actively_engaged : 0})</button></li>
                <li><button name="green_somewhat_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Somewhat Engaged ({clientData && clientData.user_counts && clientData.user_counts.green_somewhat_engaged ? clientData.user_counts.green_somewhat_engaged : 0})</button></li>
                <li><button name="untouched_clients_report" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Untouched Clients Report ({clientData && clientData.user_counts && clientData.user_counts.untouched_clients_report ? clientData.user_counts.untouched_clients_report : 0})</button></li>
                <li><button name="rented_on_own" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Rented on own ({clientData && clientData.user_counts && clientData.user_counts.rented_on_own ? clientData.user_counts.rented_on_own : 0})</button></li>
                <li><button name="staying_in_suburbs" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Staying In Suburbs ({clientData && clientData.user_counts && clientData.user_counts.staying_in_suburbs ? clientData.user_counts.staying_in_suburbs : 0})</button></li>
              </ul>
            </div>*/}

            </div>
            <div className="col-md-2 newfilter-list text-right">
            <div>
              <span className="enteries-span">
              <label className="enteries-label">Entries</label>
                <select value={this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
                {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                   })}
                </select>
              </span>  
              </div>
              </div>
          </div>
        </div>
        
        <div className="mobileFilter filter__container" style={{ display: 'none' }}>
          <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
          Filter <img src="./img/menu-dropdown.png" style={{ float:'right', paddingTop: '5px', width: '14px' }} /> 
          </a>
          <ul className="dropdown-menu mobile-dropdown mob-filter-scroller">
          {user && user.role != 'local' ? 
          <li> <button name="all_follow_up" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Current Follow Ups ({allFollowups})</button></li>
          :''}
          {user && user.role!='superadmin' && user.role != 'strategist' && user.role != 'local' ?
          <li> <button name="my_follow_up" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>My Follow Ups ({myFollowups})</button></li>
          : ''}
          <li><button name="over_due" className="btn filter__container--button over_due_red" onClick={this.handelUserfilter.bind(this)}>Overdue({Overdue})</button></li>
          <li><button name="not_touch_in_four_week" className="btn filter__container--button " onClick={this.handelUserfilter.bind(this)}>Not touched by Strategist in 4 weeks</button></li>
          <li><button name="never_schedule_with_agents" className="btn filter__container--button " onClick={this.handelUserfilter.bind(this)}>Never Schedule With Agents</button></li>
          {/*<li><button name="city" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>City ({City})</button></li>
          <li><button name="green_not_very_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Not Very Engaged ({GreenVeryEngaged})</button></li>
          <li><button name="green" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green ({clientData && clientData.user_counts && clientData.user_counts.green ? clientData.user_counts.green : 0}) </button></li>
          <li><button name="never_had_a_call" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Never Had A Call ({clientData && clientData.user_counts && clientData.user_counts.never_had_a_Call ? clientData.user_counts.never_had_a_Call : 0})</button></li>  
          <li><button name="own_agent_pre" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent - Pre ({clientData && clientData.user_counts && clientData.user_counts.own_agent_pre ? clientData.user_counts.own_agent_pre : 0})</button></li>
          <li><button name="new" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>New ({clientData && clientData.user_counts && clientData.user_counts.new ? clientData.user_counts.new : 0})</button></li>
          <li><button name="own_agent" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent ({clientData && clientData.user_counts && clientData.user_counts.own_agent ? clientData.user_counts.own_agent : 0})</button></li>
          <li><button name="out_of_area" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Out of Area ({clientData && clientData.user_counts && clientData.user_counts.out_of_area ? clientData.user_counts.out_of_area : 0})</button></li>
          <li><button name="no_response" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>No Response ({clientData && clientData.user_counts && clientData.user_counts.no_response ? clientData.user_counts.no_response : 0})</button></li>
          <li><button name="yellow" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Yellow ({clientData && clientData.user_counts && clientData.user_counts.yellow ? clientData.user_counts.yellow : 0})</button></li>
          <li><button name="own_agent_post" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Own Agent - Post ({clientData && clientData.user_counts && clientData.user_counts.own_agent_post ? clientData.user_counts.own_agent_post : 0})</button></li>
          <li><button name="closed_2015" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2015 ({clientData && clientData.user_counts && clientData.user_counts.closed_2015 ? clientData.user_counts.closed_2015 : 0})</button></li>
          <li><button name="closed_2014" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2014 ({clientData && clientData.user_counts && clientData.user_counts.closed_2014 ? clientData.user_counts.closed_2014 : 0})</button></li>
          <li><button name="pending" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Pending ({clientData && clientData.user_counts && clientData.user_counts.pending ? clientData.user_counts.pending : 0})</button></li>
          <li><button name="closed_2017" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2017 ({clientData && clientData.user_counts && clientData.user_counts.closed_2017 ? clientData.user_counts.closed_2017 : 0})</button></li>
          <li><button name="rented" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Rented ({clientData && clientData.user_counts && clientData.user_counts.rented ? clientData.user_counts.rented : 0})</button></li>
          <li><button name="closed_2016" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2016 ({clientData && clientData.user_counts && clientData.user_counts.closed_2016 ? clientData.user_counts.closed_2016 : 0})</button></li>
          <li><button name="call_scheduled" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Call Scheduled ({clientData && clientData.user_counts && clientData.user_counts.call_scheduled ? clientData.user_counts.call_scheduled : 0})</button></li>
          <li><button name="closed_2013" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2013 ({clientData && clientData.user_counts && clientData.user_counts.closed_2013 ? clientData.user_counts.closed_2013 : 0})</button></li>
          <li><button name="reschedule" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Reschedule ({clientData && clientData.user_counts && clientData.user_counts.reschedule ? clientData.user_counts.reschedule : 0})</button></li>
          <li><button name="closed_2012" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2012 ({clientData && clientData.user_counts && clientData.user_counts.closed_2012 ? clientData.user_counts.closed_2012 : 0})</button></li>
          <li><button name="city_do_not_reach_out" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>City - do not reach out ({clientData && clientData.user_counts && clientData.user_counts.city_do_not_reach_out ? clientData.user_counts.city_do_not_reach_out : 0})</button></li>
          <li><button name="divorced" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Divorced ({clientData && clientData.user_counts && clientData.user_counts.divorced ? clientData.user_counts.divorced : 0})</button></li>
          <li><button name="unsubscribe" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Unsubscribe ({clientData && clientData.user_counts && clientData.user_counts.unsubscribe ? clientData.user_counts.unsubscribe : 0})</button></li>
          <li><button name="never_had_a_call_do_not_reach_out" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Never Had a Call - do not reach out ({clientData && clientData.user_counts && clientData.user_counts.never_had_a_call_do_not_reach_out ? clientData.user_counts.never_had_a_call_do_not_reach_out : 0})</button></li>
          <li><button name="closed_2018" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Closed-2018 ({clientData && clientData.user_counts && clientData.user_counts.closed_2018 ? clientData.user_counts.closed_2018 : 0})</button></li>
          <li><button name="listing" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Listing ({clientData && clientData.user_counts && clientData.user_counts.listing ? clientData.user_counts.listing : 0})</button></li>
          <li><button name="trying_to_schedule" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Trying To Schedule ({clientData && clientData.user_counts && clientData.user_counts.trying_to_schedule ? clientData.user_counts.trying_to_schedule : 0})</button></li>
          <li><button name="green_actively_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Actively Engaged ({clientData && clientData.user_counts && clientData.user_counts.green_actively_engaged ? clientData.user_counts.green_actively_engaged : 0})</button></li>
          <li><button name="green_somewhat_engaged" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Green - Somewhat Engaged ({clientData && clientData.user_counts && clientData.user_counts.green_somewhat_engaged ? clientData.user_counts.green_somewhat_engaged : 0})</button></li>
          <li><button name="untouched_clients_report" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Untouched Clients Report ({clientData && clientData.user_counts && clientData.user_counts.untouched_clients_report ? clientData.user_counts.untouched_clients_report : 0})</button></li>
          <li><button name="rented_on_own" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Rented on own ({clientData && clientData.user_counts && clientData.user_counts.rented_on_own ? clientData.user_counts.rented_on_own : 0})</button></li>
          <li><button name="staying_in_suburbs" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Staying In Suburbs ({clientData && clientData.user_counts && clientData.user_counts.staying_in_suburbs ? clientData.user_counts.staying_in_suburbs : 0})</button></li>*/}
          </ul>
          </div>
          <div className="col-md-12 newfilter-list text-right filter__container--filter-name rem-flex">
            <div>
              <span className="enteries-span">
              <label className="enteries-label">Entries</label>
                <select value={this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
                {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                  })}
                </select>
              </span>  
              </div>
              </div>
        </div>
         <div className="uses-list useslist__extra">
         <div className="table-responsive table__responsive--mob">
               <table className="table table-striped">
                  <thead className="table__td--desktop">
                    <tr>
                      <th onClick={this.sortData.bind(this , 'clientName')}>Client Name</th>
                      {
                        (this.state.client_status === "never_schedule_with_agents")?(
                          <th>Agent Name</th>
                        ):''
                      }
                      <th onClick={this.sortData.bind(this , 'strategist')}>Strategist</th>
                      <th onClick={this.sortData.bind(this , 'pipelineStage')}>Pipeline Stage</th>
                      {/* <th onClick={this.sortData.bind(this , 'followUp')}>Follow Up</th> */}
                    </tr>
                  </thead>
                  {this.state.isLoadingData === true ? 
                  <tbody>
                  <tr>
                    <td colSpan="5" className="noClientResults text-center">
                    <img src="../../img/loader2.svg" />
                    </td>
                    </tr>
                  </tbody>
                  :
                  <tbody>
                  {
                  clientData && clientData.users && clientData.users.length > 0 ?
                    clientData.users.map((c,index) => {
                      let followupDate = moment(c.follow_up_date)
                      let nowDate = moment();
                      let overDueRedClass = '';
                      if (nowDate > followupDate) {
                        // this is past date
                        overDueRedClass ='overDueRed'
                      }
                    return(
                    <tr key={index}>
                      <td onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.client_full_name}</td>
                      {
                        (this.state.client_status === "never_schedule_with_agents")?(
                          <td onClick={() => { this.props.history.push({'pathname' : `/activity/clients-chat/${c.client_id}`})}}>{c.agent_full_name}
                          {
                            c.agentData && c.agentData.length > 0 && c.agentData.map((agent, ndx)=>{
                              return(
                                <a key={ndx} className="color__green--underline">{ (ndx ? ', ' : '') + agent.agent_full_name }</a>
                              )
                            })
                          }
                          </td>
                        ):''
                      }
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>
                      {
                        c.strategists && c.strategists.map((strategist, idx)=>{
                          return(
                            <span key={idx}>{ (idx ? ', ' : '') + strategist.strategist_full_name }</span>
                          )
                        })
                      }
                      </td>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.status}</td>
                      {/* <td onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}} className={overDueRedClass}>{c.follow_up_date && moment(c.follow_up_date).format("MM/DD/YYYY")}</td> */}
                    </tr>
                      )
                    }) : 
                    <tr><td colSpan="6" className="noClientResults">No clients found for this criteria. Please broaden or change your search criteria and filters</td></tr> 
                    }  
                  </tbody>
                  }
              </table>
             </div>
         </div>
      </main>
      <div className="pagination-container">
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.client_limit}
          totalItemsCount={this.state.totalPages}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
     </div>
    </div>
    )
  }
}





export default reduxForm({
  form: 'AddMember',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AddMember)
