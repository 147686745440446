import { connect } from 'react-redux'
import StrategistRatingReport from './component'

import { fetchUser } from '../../../store/user/duck'
import { getStrRatingData } from '../../../store/dashboard/duck'

const StrategistRatingReportContainer = connect(
  // Map state to props
  (state) => ({
    strRatingPhase: state.dashboard.strRatingPhase,
    strRatingData: state.dashboard.strRatingData
  }),
  // Map actions to props
  {
  	fetchUser,
  	getStrRatingData
  }
)(StrategistRatingReport)
export default StrategistRatingReportContainer
