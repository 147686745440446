import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { AppBar } from "material-ui";
import { Container, Row, Col } from "react-grid-system";
import Cookies from "universal-cookie";
import axios from "axios";
import _ from 'lodash'
import $ from 'jquery'
import Modal from "react-responsive-modal";
// import io from "socket.io-client";
import moment from "moment";
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME;
// let socket = io.connect(SOCKET_HOSTNAME);

let pre_auth = "";
let _user = "";

export class StrategistHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      searchedClients: [],
      showSearchList: false,
      accesstoken: "",
      isloading: false,
      err: {},
      open: false,
      search: "",
      pre_auth: "",
      client_limit: 500,
      activePage: 1,
      msgToggle: false,
      clientDropDown: false,
      clientSearch: "",
      clientClickable: false,
      chatSelectClientId: 0,
      chatClientName: "",
      agentChatWithClientData: [],
      chat_text: "",
      userid: 0,
      menuIconDrawer: false,
      searchButton: false
    };
    this.userSignout = this.userSignout.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    _user = cookies.get("user");
    this.setState({ userid: _user.id });
    pre_auth = cookies.get("i_user");
    if (pre_auth) {
      this.setState({ pre_auth: pre_auth });
    }
    if(_user && _user.role === "agent")
    this.stickyFixForAgent()
    // socket.on('new_message', (data) => {
    //   this.componentDidUpdate()
    // })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearNotificationPhase === "success") {
      let _user = cookies.get("user");
      let data = {};
      data.user_id = _user.id;
      this.props.getNotifications(data);
    }
    if (nextProps.saveAuthtokenData.status === true) {
      window.location.reload();
    } else {
    }
    if (nextProps.chatHistoryData !== this.props.chatHistoryData) {
      let chatDataArr =
        nextProps.chatHistoryData && nextProps.chatHistoryData.data;
      this.setState({ agentChatWithClientData: chatDataArr });
    }
  }

  componentDidMount() {
     if(_user && _user.role === "agent"){
       this.stickyFixForAgent()
     }
  }

  stickyFixForAgent = () => {
    document.body.style.overflow = "visible";
     $(window).scroll(function(){
       var sticky = $('.sticky'),
           scroll = $(window).scrollTop();
       if (scroll >= 100) sticky.addClass('agent__fixedhead');
       else sticky.removeClass('agent__fixedhead');
     })
  }

  async userSignout(logout) {
    localStorage.clear();
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = await axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }
    this.props.clearAllagentLogedData();
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    document.cookie =
      "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem("client_id");
    localStorage.removeItem("client_strategist");
    localStorage.removeItem("client_status");
    localStorage.removeItem("client_suburb");
    // document.cookies.remove('Authorization')
    // document.cookies.remove('user')

    this.props.handleSignOut();
    this.props.history.push("/login");
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  GmailAuthPop() {
    axios({
      method: "GET",
      url: HOSTNAME + "/agent-calendar/authenticate"
    }).then(res => {
      if (res && res.data && res.data.status && res.data.data != "")
        var win = window.open(
          res.data.data,
          "Account verifications",
          "width=600,height=500,left=50,top=100"
        );
      setTimeout(function() {}, 1000);
      setTimeout(function() {
        win.close();
        window.location.reload(true);
      }, 50000);
    });
  }

  handleTokenSave() {
    this.setState({ isloading: true });
    // event.preventDefault();
    const err = {};
    if (this.state.accesstoken === "") {
      err.agent_id = "Enter copied token, it`s required.";
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      const data = {};
      data.code = this.state.accesstoken;
      this.props.saveAuthtoken(data);
    } else {
      this.setState({ isloading: false });
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  OpenScheduleBar(event) {
    this.props.openScheduleBar();
  }

  onSearch(e) {
    if (e.target.value !== "") {
      this.setState({ search: e.target.value, showSearchList: true });
    } else {
      this.setState({ showSearchList: false });
    }
  }

  onSelectClients(clientData) {
    if (clientData.role == "client") {
      this.props.history.push(
        "/agent-home/agent-clients-profile/" + clientData.id
      );
      this.setState({ showSearchList: false });
    }
  }

  closeList() {
    this.setState({ searchedClients: [], showSearchList: false });
  }

  clearNotification() {
    let _user = cookies.get("user");
    let data = {};
    data.user_id = _user.id;
    this.props.clearNotification(data);
  }

  showPlaceholder() {
    const { history } = this.props;
    if (history.location.pathname == "/jungler-users") {
      return "Search jungler Users";
    } else if (
      history.location.pathname == "/home/new-clients" ||
      history.location.pathname == "/dashboard"
    ) {
      return "Search Users";
    } else {
      return "Search Clients";
    }
  }

  myProfileCondition(role) {
    switch (role) {
      case "agent":
        return (
          <ul className="dropdown-menu">
            <li>
              <Link to={`/agent-edit-profile`} className="text-orange">
                My Profile
              </Link>
            </li>
            <li>
              <Link to={`/availability`} className="text-orange">
                Availability
              </Link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={this.userSignout.bind(this)}
                className="logout-agent-client text-orange"
              >
                Logout
              </a>
            </li>
          </ul>
        );
      case "local":
        return (
          <ul className="dropdown-menu">
            <li>
              <Link to={`/agent-edit-profile`} className="text-orange">
                My Profile
              </Link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={this.userSignout.bind(this)}
                className="logout-agent-client text-orange"
              >
                Logout
              </a>
            </li>
          </ul>
        );
      case "business development":
        return (
          <ul className="dropdown-menu">
            <li>
              <Link to={`/agent-edit-profile`} className="text-orange">
                My Profile
              </Link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={this.userSignout.bind(this)}
                className="logout-agent-client text-orange"
              >
                Logout
              </a>
            </li>
          </ul>
        );
      case "client":
        return (
          <ul className="dropdown-menu">
            <li>
              <Link to={`/client-my-profile`} className="text-orange">
                My Profile
              </Link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={this.userSignout.bind(this)}
                className="logout-agent-client text-orange"
              >
                Logout
              </a>
            </li>
          </ul>
        );
      default:
        return null;
    }
  }

  chatHandleChange(e) {
    this.setState({ chat_text: e.target.value });
  }

  clientSearchClick(e) {
    this.setState({ clientSearch: e.target.value, clientDropDown: true });
  }

  chatButton(e) {
    if (this.props.match.params.id) {
      this.getChatDataOfClient(this.props.match.params.id);
      //this is for entery for id iside current user
      localStorage.setItem("client_id", this.props.match.params.id);
    } else if (localStorage.getItem("client_id")) {
      this.getChatDataOfClient(localStorage.getItem("client_id"));
    }
    this.setState({ msgToggle: true });
  }

  async getChatDataOfClient(clientId) {
    let clientData =
      this.props.agentSearchGetUsersData &&
      this.props.agentSearchGetUsersData.data;
    let getClient = await _.filter(clientData, client => {
      if (client.id == clientId) {
        return client;
      }
    });
    let clientFullName =
      getClient[0].first_name.trim() + " " + getClient[0].last_name.trim();
    this.setState({
      clientDropDown: false,
      clientSearch: clientFullName,
      clientClickable: true,
      chatSelectClientId: getClient[0].id,
      chatClientName: clientFullName
    });
    const receiver_id = getClient[0].id;
    if (_user.id != "" && receiver_id != "") {
      const data = {};
      data.sender_id = _user.id;
      data.receiver_id = receiver_id;
      this.props.getChatHistory(data);
    }
  }

  closeMsgToggleBar() {
    this.setState({ msgToggle: false });
  }

  dropDownClientClick(e) {
    this.setState({ clientDropDown: !this.state.clientDropDown });
  }

  selectClient(val, e) {
    let clientFullName = val.first_name.trim() + " " + val.last_name.trim();
    this.setState({
      clientDropDown: false,
      clientSearch: clientFullName,
      clientClickable: true,
      chatSelectClientId: val.id,
      chatClientName: clientFullName
    });
    const receiver_id = val.id;
    if (this.state.userid != "" && receiver_id != "") {
      const data = {};
      data.sender_id = this.state.userid;
      data.receiver_id = receiver_id;
      this.props.getChatHistory(data);
      //this is for entery for id iside current user
      localStorage.setItem("client_id", receiver_id);
    }
  }

  searchButtonIcon(e){
    this.setState({ searchButton: true },()=>{
       $('#search-box').addClass("open")
    })
  }

  closeSearchIcon(e){
    this.setState({ searchBarToggle: false, showSearchList: false },()=>{
      $('#search-box').removeClass("open")
    })
  }

  sendChatMessage() {
    // const receiver_id = this.state.chatSelectClientId;
    // if (
    //   this.state.chat_text !== "" &&
    //   receiver_id != "" &&
    //   this.state.userid != ""
    // ) {
    //   socket.emit("new_message", {
    //     sender_id: this.state.userid,
    //     receiver_id: receiver_id,
    //     sender_role: _user.role,
    //     type: "text",
    //     content: this.state.chat_text
    //   });
    //   this.setState({ chat_text: "" });
    // }
  }

  menuIconbutton(e) {
    this.setState({ menuIconDrawer: true });
  }

  closeMenuButton(e) {
    this.setState({ menuIconDrawer: false });
  }

  chatWithclient(e) {
    let clientData =
      this.props && this.props.clientData && this.props.clientData.users;
    if (clientData) {
      this.props.history.push({
        pathname: `/agent-chat-with-client/${clientData.id}`,
        state: clientData
      });
    } else {
      this.props.history.push({ pathname: `/agent-chat-with-client` });
    }
  }

  render() {
    const {
      history,
      agentSearchGetUsersData,
      notificationData,
      user,
      saveAuthtokenData,
      agentCLients
    } = this.props;
    let allData = agentSearchGetUsersData && agentSearchGetUsersData.data;

    let clientArrAlpha = _.orderBy(allData, ["first_name"], ["asc"]);
    let clientSearchData = [];
    if (clientArrAlpha) {
      clientSearchData = clientArrAlpha.filter(data => {
        return (
          (data.first_name &&
            data.first_name
              .toLowerCase()
              .indexOf(this.state.clientSearch.toLowerCase()) !== -1) ||
          (data.last_name &&
            data.last_name
              .toLowerCase()
              .indexOf(this.state.clientSearch.toLowerCase()) !== -1)
        );
      });
    }

    let chatClientAgentHistory = [];
    if (localStorage.getItem("client_id")) {
      chatClientAgentHistory = _.orderBy(
        this.state.agentChatWithClientData,
        ["created"],
        ["desc"]
      );
    }

    let filterList = "";
    if (allData !== undefined && allData !== "") {
      filterList = allData.filter(data => {
        let firstLastName = data.first_name + " " + data.last_name;
        let mobVal = "";
        let secondMob = "";
        if (data.phone_mobile) {
          mobVal = data.phone_mobile.replace(/\D/g, "");
        }
        if (data.secondary_phone) {
          secondMob = data.secondary_phone.replace(/\D/g, "");
        }

        return (
          (data.first_name &&
            data.first_name
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1) ||
          (data.last_name &&
            data.last_name
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1) ||
          (data.full_name &&
            data.full_name
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1) ||
          (data.email &&
            data.email
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1) ||
          (mobVal &&
            mobVal.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
              -1) ||
          (secondMob
            ? secondMob &&
              secondMob
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1
            : "") ||
          (data.secondary_name
            ? data.secondary_name &&
              data.secondary_name
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1
            : "") ||
          (data.secondary_email
            ? data.secondary_email &&
              data.secondary_email
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1
            : "")
        );
      });
    }

    return (
      <div>
        <div className="desktop__header--web margleftheader">
        <div className={ pre_auth ? "sticky" : "agent__fixedhead" }>
          <header
            className={
              this.state.pre_auth &&
              (history.location.pathname == "/home/new-clients" ||
                history.location.pathname == "/home/follow-up" ||
                history.location.pathname == "/home/active-clients" ||
                history.location.pathname == "/home/upcoming-appointments" ||
                history.location.pathname == "/clients-list" ||
                history.location.pathname == "/jungler-users")
                ? "secondary__navbar__header header-client auth_secondary__navbar__header nonefixed"
                : "navbar__header nonefixed"
            }
          >
            {/*<header className="navbar__header">*/}
            {/*<Link to={``} className="navbar__header--logo">
              <img src="./img/logo.svg" />
            </Link>*/}
            <div className="navbar__header--desktop">
              <form className="navbar__header--form">
                <span className="typeahead">
                  <input
                    className="costom-search typeahead-input"
                    onChange={e => this.onSearch(e)}
                    type="text"
                    name="search"
                    placeholder={this.showPlaceholder()}
                  />

                  {this.state.showSearchList && (
                    <ul className="search-list">
                      <div className="search-close">
                        <i
                          className="fa fa-times"
                          onClick={() => this.closeList()}
                        ></i>
                      </div>
                      {filterList &&
                        filterList.map((c, i) => {
                          return (
                            <li key={i} onClick={() => this.onSelectClients(c)}>
                              <a>
                                <div className="search-left">
                                  <span>
                                    <p className="title">
                                      {c.first_name +
                                        " " +
                                        c.last_name +
                                        " " +
                                        "(" +
                                        c.role +
                                        ")"}
                                    </p>
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </span>
              </form>
              <div className="navbar__header--navigation">
                <div>
                  {/* {
              (this.props.history.location.pathname == "/agent-home")?
              <a href="javascript:void(0)" className="navigation__links" onClick={this.OpenScheduleBar.bind(this)}>
                + Appointment</a>
                :''
            } */}
                  {user && user.role == "agent" ? (
                    <Link
                      to={`/agentcalendar`}
                      className="navigation__links btn__green"
                    >
                      Calendar
                    </Link>
                  ) : (
                    ""
                  )}
                  {
                    //   user && user.role =='agent' && allData && allData.length > 0 ?
                    // <Link to={`/chat-center`} className="navigation__links btn__grey">
                    // Chat with Client</Link>
                    // : ""
                  }
                  {user && user.role == "agent" && allData && allData.length > 0 ? (
                    <button
                      onClick={this.chatWithclient.bind(this)}
                      className="navigation__links btn__grey"
                    >
                      Chat with Client
                    </button>
                  ) : (
                    ""
                  )}
                  {
                    //  user && user.role =='agent' && allData && allData.length > 0 ?
                    // <button
                    //   className="navigation__links btn__grey"
                    //   onClick={this.chatButton.bind(this)}>
                    //   Chat with Client
                    // </button>
                    // : ""
                  }
                </div>

                <div className="avtar__dropdown">
                  <div className="dropdown costom-dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="profile-avtar">
                        <img
                          src={
                            user && user.avatar
                              ? user.avatar
                              : "img/login-bg-img.png"
                          }
                        />
                      </span>
                      <span className="profile-name">
                        {user && user.first_name
                          ? user.first_name + " " + user.last_name
                          : ""}
                      </span>
                      <br />
                      <span className="profile-email">
                        {user && user.role ? user.role : ""}
                      </span>{" "}
                      <img
                        className="avart__arrow--icon"
                        src="./img/profile-arrow.svg"
                      />
                    </a>
                    {this.myProfileCondition(_.get(user, "role"))}
                  </div>
                </div>
              </div>
            </div>

            {/*This is client chat open side bar*/}
            <div
              className={
                this.state.msgToggle
                  ? "right__chat--section"
                  : "chat__box__open"
              }
            >
              <div
                className={
                  this.state.msgToggle
                    ? ` activity__page schedule__sidebar--container open right__section`
                    : "schedule__sidebar--container right__section"
                }
              >
                <div className="schedule__sidebar--header search__poppins">
                  Chat With Client
                  <div
                    className="close-btn"
                    onClick={this.closeMsgToggleBar.bind(this)}
                  >
                    <img src="img/close.svg" />
                  </div>
                </div>

                <div
                  className="strategist__new--search"
                  onClick={this.dropDownClientClick.bind(this)}
                >
                  <input
                    onChange={e => this.clientSearchClick(e)}
                    type="text"
                    value={this.state.clientSearch}
                    placeholder="Select client"
                    className="search__poppins"
                  />
                </div>

                {this.state.clientDropDown && (
                  <div
                    id="dropdownBtn"
                    className="sel-outer new__search--outer"
                  >
                    {clientSearchData &&
                      clientSearchData.map((client, clientIdx) => {
                        return (
                          <li
                            onClick={this.selectClient.bind(this, client)}
                            key={clientIdx}
                            className="custom-select-option"
                          >
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                                {client.first_name + "" + client.last_name}
                              </label>
                            </span>
                          </li>
                        );
                      })}
                  </div>
                )}

                <div className="chat__listing">
                  <ul className="chat_ul_scroll chat__listing--newclient">
                    {chatClientAgentHistory && chatClientAgentHistory.length > 0
                      ? chatClientAgentHistory.map((chat, idx) => {
                          let userAvatar = _user && _user.avatar;
                          let userName =
                            _user && _user.first_name + " " + _user.last_name;
                          let myuserId = _user && _user.id;
                          return (
                            <div key={idx}>
                              {chat.receiver_id === this.state.userid &&
                              chat.sender_id ===
                                this.state.chatSelectClientId ? (
                                <li>
                                  <img
                                    src={
                                      chat.sender_avatar
                                        ? chat.sender_avatar
                                        : "../../img/default-user.svg"
                                    }
                                    alt=""
                                  />
                                  <div className="details">
                                    <h6 className="search__poppins">
                                      {chat.sender_full_name}
                                    </h6>
                                    <p className="search__poppins">
                                      {chat.content}
                                    </p>
                                    <div className="date__format--client search__poppins">
                                      {moment(chat.created)
                                        .tz(moment.tz.guess())
                                        .format("MMMM DD, YYYY hh:mm a z")}
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <div>
                                  {this.state.userid === chat.sender_id && (
                                    <li className="you">
                                      <img
                                        src={
                                          chat.sender_avatar
                                            ? chat.sender_avatar
                                            : "../../img/default-user.svg"
                                        }
                                        alt=""
                                      />
                                      <div className="details">
                                        <h6 className="search__poppins">You</h6>
                                        <p
                                          className="search__poppins"
                                          spellCheck="true"
                                        >
                                          {chat.content}
                                        </p>
                                        <div className="search__poppins date__format--client">
                                          {moment(chat.created)
                                            .tz(moment.tz.guess())
                                            .format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })
                      : ""}
                  </ul>

                  {allData && allData.length > 0 && this.state.clientClickable && (
                    <div className="send__wrapper">
                      <input
                        type="text"
                        name="chat_text"
                        spellCheck="true"
                        className="search__poppins"
                        value={this.state.chat_text}
                        onChange={this.chatHandleChange.bind(this)}
                        placeholder={
                          this.state.chatClientName
                            ? `Chat with ${this.state.chatClientName}`
                            : "Chat with client"
                        }
                      />
                      <button
                        className="search__poppins"
                        onClick={this.sendChatMessage.bind(this)}
                      >
                        Send
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*****************End***************/}
            </header>
          </div>
        </div>

        <div className="desktop__header--mob">
         <div className={ pre_auth ? "sticky" : "agent__fixedhead" }>
          <header
            className={
              this.state.pre_auth &&
              (history.location.pathname == "/home/new-clients" ||
                history.location.pathname == "/home/follow-up" ||
                history.location.pathname == "/home/active-clients" ||
                history.location.pathname == "/home/upcoming-appointments" ||
                history.location.pathname == "/clients-list" ||
                history.location.pathname == "/jungler-users")
                ? "secondary__navbar__header header-client auth_secondary__navbar__header nonefixed"
                : "navbar__header rovtop nonefixed"
            }
          >
            {/* This is side navigation for mobile accrding to new design*/}
            <div className="navbar-header navbar__header--mobile">
              <Link
                to={`/dashboard`}
                className="navbar-brand navbar__brand--mobile"
              >
                <img src="img/logo.svg" />
              </Link>
              <div className="right__contents">

              
               <img onClick={this.searchButtonIcon.bind(this)} src="./img/searchiconmobile.svg" className="searchicon" />
                
                <button
                  className="mobilemenuopen"
                  type="button"
                  onClick={this.menuIconbutton.bind(this)}
                >
                  <img src="./img/mobiconmenu.svg" />
                </button>
              </div>
            </div>

            <div
              className={
                this.state.menuIconDrawer
                  ? "mobile__drawer"
                  : "mobile__drawer mobile__drawer--close"
              }
            >
              <div className="mobileclose">
                <img
                  src="./img/mobileclose.svg"
                  onClick={this.closeMenuButton.bind(this)}
                />
              </div>
              <ul>
                <li>
                  <Link to={`/agent-dashboard`}>Dashboard</Link>
                </li>
                <li>
                  <Link to={`/agent-home`}>Home</Link>
                </li>
                <li>
                  <Link to={`/agent-all-clients`}>All Clients</Link>
                </li>
                {user && user.role == "agent" ? (
                  <li>
                    <Link to={`/agentcalendar`}>Calendar</Link>
                  </li>
                ) : (
                  ""
                )}
                {user &&
                user.role == "agent" && allData && allData.length > 0 ? (
                  <li onClick={this.chatWithclient.bind(this)}>
                    Chat with Client
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <Link to={`/agent-edit-profile`}>My Profile</Link>
                </li>
                <li>
                  <a href="/availability">Availability</a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    onClick={this.userSignout.bind(this)}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
            {/* This is side navigation for mobile accrding to new design*/}
          </header>
          </div>

          {
                <form id="search-box" className={this.state.searchButton ? "navbar__header--form fullsearch__bg" : "navbar__header--form fullsearch__bg"}>
                <span className="typeahead typeaheadnewmob">
                  <input
                    className="costom-search typeahead-input"
                    onChange={e => this.onSearch(e)}
                    type="text"
                    name="search"
                    placeholder={this.showPlaceholder()}
                  />
                  <span onClick={this.closeSearchIcon.bind(this)}><img src="img/close.svg" /></span>

                  {this.state.showSearchList && (
                    <ul className="search-list">
                      <div className="search-close">
                        <i
                          className="fa fa-times"
                          onClick={() => this.closeList()}
                        ></i>
                      </div>
                      {filterList &&
                        filterList.map((c, i) => {
                          return (
                            <li key={i} onClick={() => this.onSelectClients(c)}>
                              <a>
                                <div className="search-left">
                                  <span>
                                    <p className="title">
                                      {c.first_name +
                                        " " +
                                        c.last_name +
                                        " " +
                                        "(" +
                                        c.role +
                                        ")"}
                                    </p>
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </span>
              </form>
              }

        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    initialValues: props.location.state ? props.location.state : {}
  };
}

export default connect(mapStateToProps)(StrategistHeader);
