import React, { PureComponent, Fragment } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import './styles.scss'
import ReactModal from 'react-modal'
import { CloseIcon } from '../../../../../components/icons'
import { currencyFormatter } from '../../../../../utils/helper'

export default class DealInformationComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      clientDealData: [],
      editOrDelModal: false
    }
  }

  componentDidMount() {
    const { match: { params: { id } } } = this.props
    this.props.getAgentDeal({ client_id: parseInt(id) })
  }

  static getDerivedStateFromProps(props, state) {
    if (state.clientDealData !== _.get(props.agentDealData, 'data[0]', [])) {
      return { clientDealData: _.get(props.agentDealData, 'data[0]', []) }
    }
    return null;
  }

  editDltDealSection(type, data, e){
    let clientData = this.props && this.props.clientData && this.props.clientData.users
      let status =  this.props && this.props.clientData && this.props.clientData.users && this.props.clientData.users.activity_status
      if(status === "Accepted Offer" || status === "Signed Contract" || status === "Closed"){
        if(type === "edit"){
          this.props.editDealSection(data, clientData, status)
        }else if(type === "delete"){
          this.props.deleteDealData(data, clientData, status, 'from_delete')
        }
      }else{
        this.setState({ editOrDelModal: !this.state.editOrDelModal })
      }
  }

  editOrDelModalClick=()=>{
    this.setState({ editOrDelModal: !this.state.editOrDelModal })
  }

  _addDealSection(e){
    const { clientData } = this.props
    if(clientData.users){
      this.props.openDealModal(true, "add", "optional", clientData.users, "Closed")
    }
  }

  render() {
    const { clientDealData } = this.state
    const { clientData } = this.props

    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__0">Deal Information</h5>
            </div>
            {
              (_.size(clientDealData) === 0 && clientData && clientData.users && clientData.users.activity_status === "Closed") &&
              <div className="deal__information__cta">
                 <button onClick={this._addDealSection.bind(this)} className="btn btn__btn btn-dark mr__10 btn__edit__deal">Add Deal</button>
               </div>
            }
            {
               (_.size(clientDealData) > 0) &&
               <div className="deal__information__cta">
                 <button onClick={this.editDltDealSection.bind(this, "edit", clientDealData)} className="btn btn__btn btn-dark mr__10 btn__edit__deal">Edit Deal</button>
                 {
                   ( (clientDealData) && (clientDealData.deal_status === "Signed Contract" || clientDealData.deal_status === "Accepted Offer" || clientDealData.deal_status === "Closed")) &&
                     <button  onClick={this.editDltDealSection.bind(this, "delete", clientDealData)} className="btn btn__btn btn-danger btn__delete__deal">Delete Deal</button>
                 }
               </div>
            }

          </div>
          {
            (_.size(clientDealData) > 0) ?
              <div className="card-body card-body-sm">
                <div className="row">
                  <div className="col-md-12">
                    <div className="deal__progress">
                      <ul className="deal__progress__item">
                        <li className="active">
                          <h2>Accepted Offer</h2>
                          <h4>{clientDealData.accepted_offer_date ? moment.utc(clientDealData.accepted_offer_date).format("MM/DD/YYYY") : ''}</h4>
                        </li>
                        <li className={(clientDealData.deal_status === "Signed Contract" || clientDealData.deal_status === "Closed") ? "active" : ''}>
                          <h2>{clientDealData.deal_type === 0 ? "Signed Contract" : "Signed Lease" } </h2>
                          <h4>{clientDealData.date_of_signed_contract ? moment.utc(clientDealData.date_of_signed_contract).format("MM/DD/YYYY") : ''}</h4>
                        </li>
                        <li className={(clientDealData.deal_status === "Closed" || clientDealData.deal_status === "Closed") ? "active" : ''}>
                          <h2>{clientDealData.deal_type === 0 ? "Closed" : "Lease Start Date" }</h2>
                          <h4>{clientDealData.deal_type === 0 && clientDealData.expected_close_date ? moment.utc(clientDealData.expected_close_date).format("MM/DD/YYYY") : ''}
                          {clientDealData.deal_type === 1 && clientDealData.lease_start_date ? moment.utc(clientDealData.lease_start_date).format("MM/DD/YYYY") : ''}
                          </h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="info__block">
                      <label>{clientDealData.deal_type === 0 ? "Sales Price" : "Monthly Rental Price"}</label>
                      <p>{clientDealData.amount ? `${currencyFormatter.format(clientDealData.amount)}` : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>{clientDealData.deal_type === 0 ? "Buy Side Commission (%)" : "Tenant's Agent Commission (%)"}</label>
                      <p>{clientDealData.buy_side_commission ? `${clientDealData.buy_side_commission}%` : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>Misc. Fee</label>
                      <p>{clientDealData.sj_comission ? `${currencyFormatter.format(clientDealData.sj_comission)}` : '$0'}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="info__block">
                      <label>Date of Accepted Offer </label>
                      <p>{clientDealData.accepted_offer_date ? moment.utc(clientDealData.accepted_offer_date).format("MM/DD/YYYY") : 'N/A'}</p>
                    </div>

                    {
                      clientDealData.deal_type === 0 ? 
                      <div className="info__block">
                        <label>Expected/Actual Closing Date</label>
                        <p>{clientDealData.expected_close_date ? moment.utc(clientDealData.expected_close_date).format("MM/DD/YYYY") : 'N/A'}</p>
                      </div>
                      : 
                      <div className="info__block">
                        <label>Lease Start Date</label>
                        <p>{clientDealData.lease_start_date ? moment.utc(clientDealData.lease_start_date).format("MM/DD/YYYY") : 'N/A'}</p>
                      </div>
                    }
                                       
                    <div className="info__block">
                      <label>{clientDealData.deal_type === 0 ? "Date of Signed Contract" : "Date of Signed Lease" }</label>
                      <p>{clientDealData.date_of_signed_contract ? moment.utc(clientDealData.date_of_signed_contract).format("MM/DD/YYYY") : 'N/A'}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info__block">
                      <label>Created Date</label>
                      <p>{clientDealData.created ? moment(clientDealData.created).format("MM/DD/YYYY") : ''}</p>
                    </div>
                    <div className="info__block">
                      <label>Address</label>
                      <p>{clientDealData.address ? clientDealData.address : ''}</p>
                    </div>
                    {
                      clientDealData.deal_type === 1 ?
                        (<div className="info__block">
                          <label>Lease Term</label>
                          <p>{clientDealData.lease_term ? clientDealData.lease_term : "N/A"}</p>
                        </div>) : ('')
                    }
                  </div>
                  <div className="col-md-12">
                    <div className="info__block">
                      <label>Notes</label>
                      <p>{clientDealData.agent_notes ? clientDealData.agent_notes : 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="card-body card-body-sm blank__state__text">No Deal Information</div>
          }
        </div>

        <ReactModal
          isOpen={this.state.editOrDelModal}
          onRequestClose={this.editOrDelModalClick}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.editOrDelModalClick}><CloseIcon /></button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">If you want to edit this deal you need to change the status to either Accepted Offer, Signed Contract, or Closed.</p>
              <div className="text-right">
                <button onClick={this.editOrDelModalClick} className="btn btn__btn btn-dark w__150">Okay</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
