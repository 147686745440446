import { Record } from 'immutable'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'
/*
    ///////////////////////////////////////
  /// jungler user  reducer ///
//////////////////////////////////////
*/
const InitialState = new Record({
  error: null,
  junglerUserCounts: {},
  junglerUsers: [],
  junglerUserPhaseData: {},
  fetchJunglerUserPhase: INIT,
  createJunglerUserPhase: INIT
})

export const junglerUserReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch jungler user list */
    case types.FETCH_JUNGLER_USERS_LIST: {
      return state.set('error', null).set('fetchJunglerUserPhase', LOADING)
    }
    case types.FETCH_JUNGLER_USERS_LIST_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('junglerUsers', payload.data.users)
        .set('junglerUserCounts', payload.data.usersCounts)
        .set('fetchJunglerUserPhase', SUCCESS)
    }
    case types.FETCH_JUNGLER_USERS_LIST_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('fetchJunglerUserPhase', ERROR)
    }

    /* create new user */
    case types.CREATE_NEW_JUNGLER_USER: {
      return state.set('error', null).set('createJunglerUserPhase', LOADING)
    }
    case types.CREATE_NEW_JUNGLER_USER_SUCCESS: {
      const { payload } = action

      return state
            .set('error', null)
            .set('createJunglerUserPhase', SUCCESS)
            .set('junglerUserPhaseData', payload)
    }
    case types.CREATE_NEW_JUNGLER_USER_ERROR: {
      return state.set('error', action.payload.error).set('createJunglerUserPhase', ERROR)
    }

    /* clear jungler user list phase */
    case types.CLEAR_JUNGLER_USERS_LIST_PHASE: {
      return state
        .set('error', null)
        .set('fetchJunglerUserPhase', INIT)
        .set('createJunglerUserPhase', INIT)
    }
    default: {
      return state
    }
  }
}
