import React, { PureComponent, Fragment } from 'react';
import { Popover, DatePicker, Spin, Pagination } from 'antd';
import { get, map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import Config, { formatNumber } from '../../../../config';
import uniqBy from 'lodash/uniqBy';

import TopNavigation from '../../../../components/TopNavigation/container';
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component';
import CheckList from './partials/CheckList/component';
import ClientListTable from './partials/ClientsList';
import Loader from '../../../../components/Loader';
import moment from 'moment';
import TaskListFilter from './partials/TaskListFilter';
import {
  ColoredTasklistIcon,
  FilterIcon,
  CloseIcon
} from '../../../../components/icons';

import './styles.scss';
import { SUCCESS } from '../../../../constants/phase';

const statusObjForFilter = {
  followup: {
    client_status: [],
    client_type: 'follow_up_date'
    // sortBy: { key: 'followUp', type: 1 }
  },
  overdue: {
    client_status: [],
    client_type: 'follow_up_date'
    // sortBy: { key: 'followUp', type: 1 }
  },
  'not-touched': {
    client_status: [],
    client_type: 'follow_up_date'
    // sortBy: { key: 'followUp', type: 1 }
  }
};

const validRouteParams = ['overdue', 'not-touched', 'followup'];

const sortKeysInitial = {
  full_client_name: { sort: 'full_client_name', sort_value: 0 },
  price_min: { sort: 'price_min', sort_value: 0 },
  status: { sort: 'status', sort_value: 0 },
  follow_up_date: { sort: 'follow_up_date', sort_value: 0 }
};
class TaskListComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentLimit: 20,
      visible: false,
      urlToRouteTo: '',
      totalCounts: {},
      activePage: 1,
      isLoading: true,
      clientInitialData: [],
      tagList: [],
      searchStringAr: [],
      filterPayloadObj: {
        client_limit: 20,
        client_offset: 1,
        client_status: [],
        client_strategist: [],
        client_suburb: []
      },
      selectedKeys: {
        statuses: [],
        strategists: [],
        suburbs: [],
        createdDate: {}
      },
      routeParam: '',
      selectedFilterTags: [],
      currentSortCondition: {},
      filterParams: [],
      currentActiveTab: 'follow-up',
      clientList: [],
      fetchDataObj: {
        ...statusObjForFilter.followup,
        type: '',
        client_limit: 20,
        client_offset: 1,
        client_status: [],
        client_strategist: [],
        client_suburb: []
      }
    };
  }

  componentDidMount() {
    const { history, match } = this.props;
    let urlToRouteTo = window.location.pathname.split('/task-list')

    const taskListDataUnparsed = localStorage.getItem('taskListData');
    const taskListData = JSON.parse(taskListDataUnparsed)
    const savedTab = taskListData ? taskListData.activeTab : null

    const routeFromProps = get(match, 'params.taskListCategory', null);

    const routeParam = routeFromProps ? routeFromProps : savedTab ? savedTab : 'followup'

    let obj = this.state.fetchDataObj;

    const filters = localStorage.getItem('strategistTaskListFilter');
    let parsedFilters = {};
    if (filters && !isEmpty(filters)) {
      parsedFilters = JSON.parse(filters);
    }

    if (parsedFilters) {
      obj = parsedFilters.filterPayloadObj
        ? parsedFilters.filterPayloadObj
        : parsedFilters;
    } else {
      parsedFilters = {};
    }

    let limit = 20
    let localLimitData = parseInt(localStorage.getItem('taskListLimit'))
    if(localLimitData && localLimitData !== null && localLimitData !== ''){
      limit = localLimitData ? localLimitData : 20
    }

    obj.type = routeParam;
    obj.client_limit = limit

    const savedSort = taskListData ? taskListData.sortObj : null

    if (savedSort) {
      obj.sortBy = { ...savedSort };
    }

    this.props.tasksListUsers(obj);
    this.props.getFilterParams();
    this.setState({
      urlToRouteTo: get(urlToRouteTo, '[0]', ''),
      fetchDataObj: obj,
      ...parsedFilters,
      routeParam,
      ...(savedSort && {currentSortCondition: savedSort})
    });

    const setTaskData = {
      activeTab: routeParam,
      ...(savedSort && {sortObj: savedSort})
    }

    localStorage.setItem(
      'taskListData',
      JSON.stringify({
        ...setTaskData
      })
    );

    // // If the route parameter is valid, as specified by us so no one can access with any param.
    // if (validRouteParams.includes(routeParam)) {
    //   if (parsedFilters) {
    //     obj = { ...parsedFilters };
    //     obj.type = routeParam;
    //   }
    //   this.setState({ routeParam, isLoading: true });
    //   this.props.tasksListUsers(obj);

    //   this.props.getFilterParams();
    // } else {
    //   if (parsedFilters) {
    //     obj = { ...parsedFilters };
    //   }
    //   this.props.tasksListUsers(obj);
    // }

    // if (parsedFilters) {
    //   obj = {...obj, ...parsedFilters }
    //   obj.client_status = get(parsedFilters, 'client_status', []);
    //   obj.client_strategist = get(parsedFilters, 'client_strategist', []);
    //   obj.client_suburb = get(parsedFilters, 'client_suburb', []);

    //   this.setState({...parsedFilters, fetchDataObj: obj});
    // }

    // if (!validRouteParams.includes(routeParam)) {
    //   history.goBack();
    // }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let obj = {};
    if (nextProps.updateFollowUpDatePhase === SUCCESS) {
      nextProps.clearFollowUpPhase();
      nextProps.tasksListUsers(prevState.fetchDataObj);
    }
    if (get(nextProps, 'tasksListPhase') === 'success') {
      nextProps.clearPhase();
      obj = {
        clientList: get(nextProps, 'filterData'),
        clientInitialData: get(nextProps, 'filterData'),
        totalCounts: get(nextProps, 'filterData.user_counts', {}),
        isLoading: false,
        tagList: get(nextProps, 'filterData.statusTags', [])
      };
    }

    if (get(nextProps, 'addUpdateClientPhase') === 'success') {
      nextProps.clearPhase();
      nextProps.tasksListUsers(prevState.fetchDataObj);
    }

    if (get(nextProps, 'filterClientsPhase') === 'success') {
      nextProps.clearPhase();
      obj.tagList = get(nextProps, 'clientData.statusTags', []);
    }

    obj.filterParams = get(nextProps, 'filterParams.data', []);

    return obj;
  }

  // Change the page limit
  handleLimitChange = e => {
    const pageLimit = get(e, 'target.value')

    const strategistTaskListFilterRaw = localStorage.getItem('strategistTaskListFilter')
    const strategistTaskListFilter = JSON.parse(strategistTaskListFilterRaw)
    const newStrategistTaskListFilter = Object.assign({}, 
      strategistTaskListFilter, 
      { 
        filterPayloadObj: {
          client_limit: pageLimit
        },
        fetchDataObj: {
          client_limit: pageLimit
        }
      }
    )

    localStorage.setItem('taskListLimit', pageLimit)
    localStorage.setItem('strategistTaskListFilter', JSON.stringify(newStrategistTaskListFilter))

    const obj = this.state.fetchDataObj;
    obj.client_limit = pageLimit
    obj.client_offset = 1;

    this.setState({
      currentLimit: pageLimit,
      fetchDataObj: obj,
      isLoading: true,
      activePage: 1
    });

    this.props.tasksListUsers(obj);

  };

  updateStorage = (key, value) => {
    const taskListDataUnparsed = localStorage.getItem('taskListData');
    const taskListData = JSON.parse(taskListDataUnparsed)
    taskListData[key] = value
    localStorage.setItem(
      'taskListData',
      JSON.stringify({
        ...taskListData
      })
    );

  }

  // Function to call api to sort data
  sortData = sortKey => {
    const isLoading = this.state.isLoading;
    if (!isLoading) {

      let sortObj = sortKeysInitial[sortKey];
      if (this.state.currentSortCondition && this.state.currentSortCondition.sort === sortKey) {
        sortObj = this.state.currentSortCondition;
      }

      const obj = this.state.fetchDataObj;
      sortObj.sort_value = sortObj.sort_value === 0 ? 1 : 0;
      obj.sortBy = { ...sortObj };

      this.updateStorage('sortObj', sortObj)
      this.props.tasksListUsers(obj);
      this.setState({ isLoading: true, currentSortCondition: sortObj });

    }
  };

  // Function to change tab and fetch data accordingly
  changeTab = route => {
    this.setState({ isLoading: true });
    const { searchStringAr, filterPayloadObj } = this.state;
    let obj = this.state.fetchDataObj;

    obj = { ...statusObjForFilter[route], ...obj };
    obj.type = route;
    obj.client_offset = 1
    this.props.tasksListUsers(obj);

    this.updateStorage('activeTab', route)
    this.setState({ routeParam: route, fetchDataObj: obj, activePage: 1 });
  };

  // Called when a page is changed
  onPageChange = page => {
    this.setState({ isLoading: true, activePage: page });
    const fetchDataObj = this.state.fetchDataObj;
    fetchDataObj.client_offset = page;
    this.props.tasksListUsers(fetchDataObj);
  };

  filterVisible = visible => {
    this.setState({ visible });
  };

  // Update follow-up date
  updateFollowUpDate = (date, clientId, strategistId, index) => {
    const data = get(this, 'state.clientList');
    const obj = {
      clientId,
      date: moment(date).format('YYYY-MM-DD'),
      strategistId
    };
    data.users[index].follow_up_date[0].follow_up_date = moment(date).format(
      'YYYY-MM-DD'
    );
    this.setState({ clientList: data });
    this.forceUpdate();
    this.props.updateFollowUpDate(obj);
  };

  setDateFilter = date => {
    this.setState({ isLoading: true });
    const { filterPayloadObj, fetchDataObj, clientInitialData } = this.state;
    const filtered = this.state.selectedKeys;
    filtered.createdDate = date;
    const ar = filter(this.state.searchStringAr, d => {
      return d.category !== 'createdDate';
    });

    ar.push({
      category: 'createdDate',
      name: `${moment(date.startDate).format('MM/DD/YYYY')} - ${moment(
        date.endDate
      ).format('MM/DD/YYYY')}`
    });
    filterPayloadObj.start_date = moment(date.startDate).format('YYYY-MM-DD');
    filterPayloadObj.end_date = moment(date.endDate).format('YYYY-MM-DD');

    const obj = { ...filterPayloadObj, ...fetchDataObj };

    obj.client_status = get(filterPayloadObj, 'client_status', []);
    this.props.getClientList(obj);

    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        filterPayloadObj: obj,
        selectedKeys: filtered,
        searchStringAr: ar
      })
    );
    this.setState({
      filterPayloadObj: obj,
      selectedKeys: filtered,
      searchStringAr: ar
    });
    this.forceUpdate();
  };

  setSelectedKeys = async (e, paramName, category) => {
    this.setState({ isLoading: true });

    const {
      filterPayloadObj,
      fetchDataObj,
      clientInitialData,
      filterParams
    } = this.state;
    const mainListOf = filterParams[category];

    const filtered = this.state.selectedKeys;
    let ar = this.state.searchStringAr;

    if (e.target.checked) {
      filtered[category].push(paramName);

      if (category === 'strategists' || category === 'suburbs') {
        const name = await filter(mainListOf, { id: paramName });
        if (category === 'suburbs') {
          ar.push({ name: get(name, '[0].name', ''), id: paramName, category });
        } else {
          ar.push({
            name: get(name, '[0].strategist_name', ''),
            id: paramName,
            category
          });
        }
      } else {
        ar.push({ name: paramName, category });
      }
    } else {
      const removedAr = filter(
        filtered[category],
        search => search !== paramName
      );
      const final = filter(ar, search => {
        if (category === 'strategists' || category === 'suburbs') {
          return search.id !== paramName;
        } else {
          return search.name !== paramName;
        }
      });
      ar = final;
      filtered[category] = removedAr;
    }

    const filterAr = [];
    filter(ar, t => {
      if (t.category === category) {
        if (category === 'strategists' || category === 'suburbs') {
          filterAr.push(t.id);
        } else {
          filterAr.push(t.name);
        }
      }
    });

    const obj = { ...filterPayloadObj, ...fetchDataObj };

    obj.client_status = get(filterPayloadObj, 'client_status');
    obj.client_strategist = get(filterPayloadObj, 'client_strategist');
    obj.client_suburb = get(filterPayloadObj, 'client_suburb');
    obj.client_offset = 1

    if (category === 'statuses') {
      obj.client_status = filterAr;
    }

    if (category === 'strategists') {
      obj.client_strategist = filterAr;
    }

    if (category === 'suburbs') {
      obj.client_suburb = filterAr;
    }

    // Fetch filtered clients
    this.props.tasksListUsers(obj);

    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        filterPayloadObj: obj,
        fetchDataObj: obj,
        selectedKeys: filtered,
        searchStringAr: ar
      })
    );
    this.setState({
      filterPayloadObj: obj,
      selectedKeys: filtered,
      fetchDataObj: obj,
      searchStringAr: ar,
      activePage: 1
    });
    this.forceUpdate();
  };

  clearFilters = () => {
    this.setState({ isLoading: true });
    const { filterPayloadObj, fetchDataObj, clientInitialData } = this.state;
    const filtered = {
      statuses: [],
      strategists: [],
      suburbs: []
    };
    const ar = [];

    fetchDataObj.client_status = [];
    fetchDataObj.client_strategist = [];
    fetchDataObj.client_suburb = [];
    fetchDataObj.client_offset = 1


    delete filterPayloadObj.start_date;
    delete filterPayloadObj.end_date;

    // Fetch filtered clients
    this.props.tasksListUsers(fetchDataObj);

    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        filterPayloadObj,
        selectedKeys: filtered,
        searchStringAr: ar
      })
    );
    this.setState({
      filterPayloadObj,
      selectedKeys: filtered,
      searchStringAr: ar,
      activePage: 1
    });
    this.forceUpdate();
  };

  removeFilterKey = async key => {
    this.setState({ isLoading: true });
    const { filterPayloadObj, fetchDataObj } = this.state;

    const filtered = this.state.searchStringAr;
    const selectedKeys = this.state.selectedKeys;

    const final = await filter(filtered, search => {
      return search.name !== key.name;
    });
    const filterSelectedKeys = await filter(
      selectedKeys[key.category],
      search => {
        if (key.category === 'strategists' || key.category === 'suburbs') {
          return search !== key.id;
        } else {
          return search !== key.name;
        }
      }
    );

    const filterAr = [];
    filter(final, t => {
      if (t.category === key.category) {
        if (key.category === 'strategists' || key.category === 'suburbs') {
          filterAr.push(t.id);
        } else {
          filterAr.push(t.name);
        }
      }
    });

    const obj = { ...filterPayloadObj, ...fetchDataObj };

    obj.client_status = get(filterPayloadObj, 'client_status');
    obj.client_strategist = get(filterPayloadObj, 'client_strategist');
    obj.client_suburb = get(filterPayloadObj, 'client_suburb');

    if (key.category === 'statuses') {
      obj.client_status = filterAr;
    }

    if (key.category === 'strategists') {
      obj.client_strategist = filterAr;
    }

    if (key.category === 'suburbs') {
      obj.client_suburb = filterAr;
    }

    // Fetch filtered client
    this.props.tasksListUsers(obj);

    selectedKeys[key.category] = filterSelectedKeys;
    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        selectedKeys,
        searchStringAr: final
      })
    );

    this.setState({ searchStringAr: final, selectedKeys, fetchDataObj: obj });
  };

  onKeyUncheck = name => {
    this.setState({ isLoading: true });
    let selectedKeys = this.state.selectedKeys
    let searchStringAr = this.state.searchStringAr
    const { filterPayloadObj } = this.state;
    const obj = { ...filterPayloadObj, ...this.state.fetchDataObj };
    // selectedKeys
    // searchStringAr
    let newAr = []
    searchStringAr.map((j, i) => {
     if (name === 'strategists' || name === 'suburbs') {
      if (!selectedKeys[name].includes(j.id)) {
        newAr.push(j)
      }
     } else {
      if (!selectedKeys[name].includes(j.name)) {
        newAr.push(j)
      }
     } 
    })


    if (name === 'statuses') {
      obj.client_status = [];
      selectedKeys[name] = []
    }
    if (name === 'strategists') {
      obj.client_strategist = [];
      selectedKeys[name] = []
    }
    if (name === 'suburbs') {
      obj.client_suburb = [];
      selectedKeys[name] = []
    }

    // Fetch filtered client
    this.props.tasksListUsers(obj);

    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        filterPayloadObj: obj,
        selectedKeys,
        searchStringAr: newAr
      })
    );
    this.setState({ selectedKeys, searchStringAr: newAr, fetchDataObj: obj, filterPayloadObj: obj })
  };

  removeDateFilterKey = key => {
    const filtered = this.state.searchStringAr;
    const selectedKeys = this.state.selectedKeys;
    const filterPayloadObj = this.state.filterPayloadObj;
    filtered.createdDate = {};
    const filterSelectedKeys = filter(selectedKeys, y => {
      return y.name !== key.name;
    });

    delete filterPayloadObj.start_date;
    delete filterPayloadObj.end_date;

    localStorage.setItem(
      'strategistTaskListFilter',
      JSON.stringify({
        filterPayloadObj,
        selectedKeys: filterSelectedKeys,
        searchStringAr: filtered
      })
    );

    this.setState({
      filterPayloadObj,
      searchStringAr: filtered,
      selectedKeys: filterSelectedKeys
    });
    this.forceUpdate();
  };

  render() {
    const {
      routeParam,
      clientList,
      isLoading,
      fetchDataObj,
      currentSortCondition,
      filterParams,
      selectedFilterTags,
      searchStringAr,
      selectedKeys,
      currentLimit,
      activePage,
      totalCounts,
      tagList,
      urlToRouteTo
    } = this.state;

    const totalSize =
      (routeParam === 'followup' && totalCounts.followup48hours) ||
      (routeParam === 'overdue' && totalCounts.myOverdueClients) ||
      (routeParam === 'not-touched' && totalCounts.clientsNotTouchedIn4Weeks);

    const showPagination = totalSize > currentLimit ? false : true;
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h2 className="heading-title">
                  <span className="heading-icon">
                    <ColoredTasklistIcon className="" />
                  </span>
                  Task List
                </h2>
              </div>

              <div className="col-md-6">
                <div className="filter__result__section">
                  <TaskListFilter
                    onKeyUncheck={this.onKeyUncheck}
                    setDateFilter={this.setDateFilter}
                    clearFilters={this.clearFilters}
                    filterParams={filterParams}
                    selectedFilterTags={selectedFilterTags}
                    selectedKeys={selectedKeys}
                    setSelectedKeys={this.setSelectedKeys}
                  />

                  <div className="material-textfield">
                    <select
                      onChange={this.handleLimitChange}
                      value={get(this, 'state.fetchDataObj.client_limit', 20)}
                      className="form-control custom-select material-textfield-input results__select"
                    >
                      {!isEmpty(Config.resultsCountOptions) &&
                        Config.resultsCountOptions.map((page, i) => {
                          return (
                            <option key={i} value={get(page, 'value')}>
                              {get(page, 'label', '')}
                            </option>
                          );
                        })}
                    </select>
                    <label className="material-textfield-label">Results</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mob__view__container mob__view__control">
              <div className="col-md-12">
                <select
                  onChange={e => {
                    this.changeTab(e.target.value);
                  }}
                  className="form-control custom__select mt__20 mb__20"
                >
                  <option value={'followup'}>Followup in 48hrs</option>
                  <option value={'overdue'}>My Overdue Clients</option>
                  <option value={'not-touched'}>
                    I Haven’t Touched in 4 Weeks
                  </option>
                </select>
              </div>
            </div>
            <Spin size="large" spinning={isLoading} indicator={<Loader />}>
              <div className="tasklist__following__tags">
                These are clients with the following tags:{' '}
                {tagList && tagList.length > 0 && tagList.join(', ')}
                {/* Green - Somewhat */}
                {/* Engaged, Call Scheduled, Green */}
              </div>

              <div className="desktop__view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabs__control">
                      {/* <button
                      className="btn btn-link"
                    >
                      Check List
                      <span className="green">30 Items</span>
                    </button> */}
                      <button
                        onClick={() => this.changeTab('followup')}
                        className={`btn btn-link ${routeParam === 'followup' &&
                          'active'}`}
                      >
                        Followup in 48hrs
                        <span className="green">
                          {formatNumber(get(totalCounts, 'followup48hours', 0))}{' '}
                          Clients
                        </span>
                      </button>
                      <button
                        onClick={() => this.changeTab('overdue')}
                        className={`btn btn-link ${routeParam === 'overdue' &&
                          'active'}`}
                      >
                        My Overdue Clients
                        <span>
                          {formatNumber(
                            get(totalCounts, 'myOverdueClients', 0)
                          )}{' '}
                          Clients
                        </span>
                      </button>
                      {/*<button*/}
                      {/*  onClick={() => this.changeTab('not-touched')}*/}
                      {/*  className={`btn btn-link ${routeParam ===*/}
                      {/*    'not-touched' && 'active'}`}*/}
                      {/*>*/}
                      {/*  I Haven’t Touched in 4 Weeks*/}
                      {/*  <span className="red">*/}
                      {/*    {formatNumber(*/}
                      {/*      get(totalCounts, 'clientsNotTouchedIn4Weeks', 0)*/}
                      {/*    )}{' '}*/}
                      {/*    Clients*/}
                      {/*  </span>*/}
                      {/*</button>*/}
                    </div>
                  </div>
                </div>

                <ul className="filter__list__flitered">
                  {!isEmpty(searchStringAr) &&
                    searchStringAr.map((search, i) => {
                      return (
                        <li key={i}>
                          {search.name}
                          <span
                            onClick={() =>
                              search.category === 'date'
                                ? this.removeDateFilterKey(search)
                                : this.removeFilterKey(search)
                            }
                          >
                            <CloseIcon className="close-icon" />
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {/* <CheckList/> */}
              <ClientListTable
                routeParam={this.state.routeParam}
                sortData={this.sortData}
                clientList={clientList}
                formatNumber={formatNumber}
                history={this.props.history}
                urlToRouteTo={urlToRouteTo}
                currentSortCondition={currentSortCondition}
                updateFollowUpDate={this.updateFollowUpDate}
              />
              {!showPagination && (
                <Pagination
                  className="mt__20 mb__20"
                  current={activePage}
                  defaultCurrent={1}
                  total={totalSize}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  pageSize={get(fetchDataObj, 'client_limit', 20)}
                  onChange={page => this.onPageChange(page)}
                />
              )}
            </Spin>
            {/* <Followup {...this.props}/> */}
            {/*This is switch component for tab- Never touched and not touched in 4 week tabs*/}
            {/* <Switch>
              <Route exact path={`${this.props.match.url}/new-never-touched`}>
                <NeverTouched {...this.props} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`${this.props.match.url}/not-touched-in-4-weeks`}>
                <NotTouchedInFourWeek {...this.props} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`${this.props.match.url}/interested-but-don’t-want-town-tour-yet`}>
                <InterestedNotTownTour {...this.props}/>
              </Route>
            </Switch> */}
            {/*This is switch component end*/}
          </div>
        </main>
      </div>
    );
  }
}

export default TaskListComponent;
