import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../../../config'
import { Redirect, Link } from 'react-router-dom'
import Modal from 'react-modal';
import styles from '../../../Login/styles.scss';

const required = value => value ? undefined : 'Required'

class ResetForm extends Component {   
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      email: "",
      err: {},
      message: '',
      isLoading: false
    }
  } 
   
  // Function to set props messages in state
  componentWillReceiveProps(nextProps){
    if(nextProps.resetEmailPhase === "success"){
      this.setState({ isLoading: false, message: "Reset password link has been sent to your email account please check your inbox.", email: ""})
    } if(nextProps.resetEmailPhase === "error"){
      this.setState({ isLoading: false, message: nextProps.forgotPasswordError.message})
    }
  }
  
  // Function to set input value in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  // Function to send password link
  handleClick(event) {
    const err = {}
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    } else if (!this.validateEmail(this.state.email)) {
       err.email = 'Invalid email'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { resetPasswordLink } = this.props
      this.setState({ isLoading: true })
      const data = new FormData()
      data.set("email", this.state.email.toLowerCase())
      resetPasswordLink(data)
    }
  }
  
  render() {
    return (
      <section>
        <div className="container-fluid">
            <div className="row login-header">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-4 left-align">
                    <h4></h4></div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center"><a href={this.state.wpUrl}><img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" /></a></div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2 text-right">
                    <div className="close"><a href={`${this.state.wpUrl}`}><img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/></a></div>
                </div>
            </div>
        </div>

        <div className="LoignSection">
          <div className="overlay-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="v-container">
                  <div className="login-box ForgotSection">
                    <h3>Forgot Password</h3>
                    <p>We will send you an email to reset your password.</p>


                    <div className="outer-input">
                    <input 
                      type="email" 
                      name="email" 
                      placeholder="Enter email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)} 
                    />
                    <label>Email</label>
                    </div>

  
                    { this.state.err.email ?
                    <span className="error_field">
                    {this.state.err.email}
                    </span> : '' }
                    <button type="button" className="btn DefaultBtn login-submit mor-marg-top" onClick={this.handleClick.bind(this)}>Submit</button>
                    { this.state.message != "" &&
                      (
                        <div className="text-center error">
                          { this.state.message }
                        </div>
                      )
                    }
                    <span className="create-accoutn for-right">
                    <Link to={`/login`}>Back to Login</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    )
  }
}

export default reduxForm({
  form: 'reset',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ResetForm)
