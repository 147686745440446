import { Record } from 'immutable'
import HttpStatus from 'http-status-codes'
import Cookies from 'universal-cookie'

import * as types from './action-types'
const cookies = new Cookies()
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'
import Config from '../../../config'

const InitialState = new Record({
  phase: INIT,
  error: null,
  filterParams: [],
  users: {},
  profilePhase: INIT,
  filterParamsPhase: INIT,
  developmentMembersPhase:INIT,
  developmentMembers:[],
})

/**
 * Public: Reducer
 */

export const dashboardReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  fetch filter params list */
    case types.FETCH_FILTER_LIST: {
      return state.set('filterParamsPhase', LOADING).set('error', null)
    }
    case types.FETCH_FILTER_LIST_SUCCESS: {
      const { payload } = action
      return state
        .set('filterParams', payload)
        .set('filterParamsPhase', SUCCESS)
        .set('error', null)
    }
    case types.FETCH_FILTER_LIST_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('filterParamsPhase', ERROR)
    }

    case types.FETCH_CLIENT_PROFILE: {
      return state.set('profilePhase', INIT).set('error', null)
    }
    case types.FETCH_CLIENT_PROFILE_SUCCESS: {
      const { payload } = action
      if (payload.status && payload.status === true) {
        return state
          .set('users', payload.data)
          .set('profilePhase', SUCCESS)
          .set('error', null)
      } else {
        cookies.remove('Authorization')
        cookies.remove('user')
      }
    }
    // eslint-disable-next-line no-fallthrough
    case types.FETCH_CLIENT_PROFILE_ERROR: {
      const { error } = action.payload
      if (
        error.statusCode === HttpStatus.UNAUTHORIZED ||
        error.statusCode === HttpStatus.FORBIDDEN ||
        error.statusCode === HttpStatus.NOT_FOUND
      ) {
        //Remove JWT from local storage
        localStorage.removeItem(Config.LocalStorageKeys.Authorization)
      }
      return state.set('users', null).set('error', error)
    }
    /* clear comment phase  */
    case types.CLEAR_DASHBOARD_PHASE: {
      return state
        .set('error', null)
        .set('filterParamsPhase', INIT)
        .set('profilePhase', INIT)
    }

    case types.FETCH_DEVELOPMENT_MEMBERS:{
      return state.set('developmentMembersPhase', LOADING).set('error', null)

    }
    case types.FETCH_DEVELOPMENT_MEMBERS_SUCCESS: {
      const { payload } = action
      return state
        .set('developmentMembers', payload)
        .set('developmentMembersPhase', SUCCESS)
        .set('error', null)
    }

    case types.FETCH_DEVELOPMENT_MEMBERS_ERROR:{
      const { payload } = action
      return state.set('error', payload.error).set('developmentMembersPhase', ERROR)

    }

    default: {
      return state
    }
  }
}
