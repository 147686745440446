import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import isEmpty from "lodash/isEmpty"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import NumberFormat from 'react-number-format';
import Modal from 'react-responsive-modal';
import axios from 'axios'
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import { logoutInvalidRequest } from '../../../utils/helper'

let _user = ''


const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control selectdateglobal"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

class AccountingReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      fromDate: moment().add(-1, 'month').startOf('month'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      type: 'assignToAgent',
      agentName: '',
      currentAgentClients:[],
      openClient: false,
      currentAgentTowns: [],
      currentAgentTags: [],
      open:false,
      openTown: false,
      openStr: false,
      currentAgentStrategist: [],
      clientsAssignToAgentState:[],
      sortByKey:'',
      sortByType: 0,
      search:'',
      currentAgentInitialClients:[],
      openInitialClient:false,
      currentAgentTourClients:[],
      openTourClient:false,
      selectedIndex: 'all',
      openBiz:false,
      currentBDSource: [],
      current_client:'',
      updateBiz:null,
      strategist_fee_client:{},
      updateFeeIndex:null,
      openFee:false,
      totalRevenue: '$0',
      strategists:[],
      exportAccountingReportData:[],
      customExportAccountingReportData: [],
      datePickerModal: false,
      multiDataSet: [],
      multiDataSet2: [],
      modalIsOpen: false,
      defaultStartDate: moment().add(-1, 'month').startOf('month'),
      defaultEndDate: moment(),
      openCheck:false,
      deal_check_client:{},
      strategist_id: 0,
    }
  }

  setValuesForExcelSheet = async (sheetHeaders, customExportAccountingReportData) => {
    let MBaluesAr = []

    let setVals = customExportAccountingReportData.map(async (k, i) => {
      let d = []
      let setval = Object.keys(k).map((header, j) => {
        d.push(k[header])
      })
      await Promise.all(setval)
      MBaluesAr.push(d)
    })

    await Promise.all(setVals)
              let multiDataSet = this.state.multiDataSet

    if(!isEmpty(this.state.multiDataSet)) {
       multiDataSet = []
    }

    multiDataSet.push({ columns: sheetHeaders, data: MBaluesAr })
    this.setState({ multiDataSet })
  }

  setValuesForExcelSheet2 = async ( customExportAccountingReportData, toOmitFields) => {
    let MBaluesAr = []
    let finalAr = []

    // Remove fields from the objects
    const removeFields = customExportAccountingReportData.map((field) => {
      toOmitFields.map((k, i) => {
        delete field[k]
      })

      finalAr.push(field)
    })

    await Promise.all(removeFields)

    // Set sheet headers
    let sheetHeaders = [];
       
    Promise.all(!isEmpty(finalAr) &&  Object.keys(finalAr[0]).map((k, i) => {
      let headerName = k.replace(/_/g, " ")
      headerName = headerName.replace(/\w\S*/g, function(txt){
       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
   });
     sheetHeaders.push(headerName)
   }))
    
    
  //  Set sheet values array
    let setVals = finalAr.map(async (k, i) => {
      let d = []
      let setval = Object.keys(k).map((header, j) => {
        d.push(k[header])
      })
      await Promise.all(setval)
      MBaluesAr.push(d)
    })

    await Promise.all(setVals)
    let multiDataSet2 = this.state.multiDataSet2

    if(!isEmpty(multiDataSet2)){
      multiDataSet2 = []
 }    

    multiDataSet2.push({ columns: sheetHeaders, data: MBaluesAr })

    this.setState({ multiDataSet2 })
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
      this.props.getFilterParams()
      this.strategistDataLoad()
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = 0 //first time strategist id=0 means all strategist
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}

      this.props.getAccountingReportData(strData)
      // strData.strategist_arr = this.state.client_strategist
     // this.props.getClientsPerAgents(strData)
      this.setState({isLoadingData: true})
  }

  componentWillReceiveProps(nextProps){
    // if( nextProps.filterParams !== this.props.filterParams ){
    //   let strList = nextProps.filterParams.data && nextProps.filterParams.data.strategists
    //     if(this.state.strategist_id == 0){
    //       let strategistData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
    //         const data = {}
    //         data.user_id = _user.id;
    //         const strData = {}
    //         if(this.state.selectedIndex === "all"){
    //           strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
    //           strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    //         }else{
    //           strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    //           strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    //         }
    //         strData.type = this.state.type
    //         strData.strategistId = strategistData.id
    //         strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    //         this.props.getAccountingReportData(strData)
    //     }
    // }
    if(nextProps.accountingReportPhase === 'success'){
      let bd_source_length = 0
      let business_development_fee_length = 0
      let accountingData = []
      let exportAccountingReportData = []
      let customExportAccountingReportData = []
      let feeCount = 0
      let bdCount = 0
      let toOmitFields = []

      if(nextProps.accountingReportData && nextProps.accountingReportData.data.length > 0){
        nextProps.accountingReportData.data.map(function(data){
          if (data.strategists.length > feeCount) {
            feeCount = data.strategists.length
          }

          if (data.bd_source.length > bdCount) {
            bdCount = data.bd_source.length
          }
          data.total_sj_revenue = formatter.format(parseInt(data.total_sj_revenue))
          data.total_sj_revenue = data.total_sj_revenue.substring(0, data.total_sj_revenue.indexOf('.'))

          data.sales_price = formatter.format(parseInt(data.sales_price))
          data.sales_price = data.sales_price.substring(0, data.sales_price.indexOf('.'))
          
          let obj = {}
          obj.client_full_name = data.client_full_name
          obj.strategist_full_name = data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name }).join(" ,") : ""
          obj.agent_full_name = data.agent_full_name
          obj.agent_firm_name = data.agent_firm_name
          obj.closed_date = (data.closed_date !== null && data.closed_date !== '') ? moment(data.closed_date).format("MM-DD-YYYY") : "N/A"
          // obj.closed_date = data.closed_date
          obj.sales_price = data.sales_price
          obj.buy_side_commission = data.buy_side_commission
          obj.total_sj_revenue = data.total_sj_revenue
          obj.strategist_fee = 0
          obj.client_id = data.client_id
          obj.bd_source = data.bd_source
          obj.deal_id = data.deal_id
          obj.strategists = data.strategists
          obj.business_development_fee = data.business_development_fee
          obj.check_received = data.check_received
          obj.bonus_paid_date = data.bonus_paid_date
          obj.sj_comission = data.sj_comission
          obj.status = data.status
          obj.deal = data.deal
          accountingData.push(obj)
        })

        nextProps.accountingReportData.data.map(function(data){
          let exObj = {}
          exObj.client_full_name = data.client_full_name
          exObj.strategist_full_name = data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name }).join(" ,") : ""
          exObj.agent_full_name = data.agent_full_name
          exObj.agent_firm_name = data.agent_firm_name
          exObj.closed_date = (data.closed_date !== null && data.closed_date !== '') ? moment(data.closed_date).format("MM-DD-YYYY") : "N/A"
          // exObj.closed_date = data.closed_date
          exObj.sales_price = data.sales_price
          exObj.buy_side_commission = data.buy_side_commission
          exObj.strategist_fee = data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name+' - '+(e.strategist_fee !== null ? '$'+e.strategist_fee : '') }).join(" ,") : ""
          exObj.bd_source = data.bd_source.length > 0 ? data.bd_source.map(e => { return e.full_name+' - '+(e.business_development_fee !== null ? '$'+e.business_development_fee : '') }).join(" ,") : ""
          exObj.total_sj_revenue = data.total_sj_revenue
          exObj.check_received = data.check_received
          exObj.bonus_paid_date = data.bonus_paid_date
          exObj.sj_comission = data.sj_comission


          let customObj = {}
          customObj.strategist_name = data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name }).join(" ,") : ""
          customObj.client_name = data.client_full_name
          customObj.agent_name = data.agent_full_name
          customObj.agent_firm_name = data.agent_firm_name
          customObj["status (Agent)"] = data.status
          customObj.closed_date = data.closed_date
          customObj.check_received = (data.check_received !== '' && data.check_received !== null && data.check_received !== '0000-00-00 00:00:00') ? moment(data.check_received).format("MM-DD-YYYY") : ''
          //customObj.agent_bonus_paid = data.bonus_paid_date
          customObj.sales_price = data.sales_price
          customObj["% commission_payout"] = data.buy_side_commission
          customObj["misc._fees"] = data.sj_comission
          
          for(var i = 1; i <= feeCount; i++) {
            toOmitFields.push(`strategist_name-${i}`)
            toOmitFields.push(`fee_amount-${i}`)
            toOmitFields.push(`bonus_paid-${i}`)

            customObj[`strategist_name-${i}`] = ''
            customObj[`fee_amount-${i}`] = ''
            customObj[`bonus_paid-${i}`] = ''
          }

          // if(isEmpty(feeCount)) {
          //   let i = 1
          //   toOmitFields.push(`strategist_full_name-${i}`)
          //   toOmitFields.push(`strategist_fee-${i}`)
          //   toOmitFields.push(`strategist_payout_date-${i}`)

          //   customObj[`strategist_full_name-${i}`] = ''
          //   customObj[`strategist_payout_date-${i}`] = ''
          //   customObj[`strategist_fee-${i}`] = ''
          // }

          for(var j = 1; j <= bdCount; j++) {
            toOmitFields.push(`bd_source-${j}`)
            toOmitFields.push(`bd_source_date-${j}`)
            toOmitFields.push(`bd_source_fee-${j}`)

            customObj[`bd_source-${j}`] = ''
            customObj[`bd_source_fee-${j}`] = ''
            customObj[`bd_source_date-${j}`] = ''
            
          }

          // if(isEmpty(bdCount)) {
          //   let j = 1
          //   toOmitFields.push(`bd_source-${j}`)
          //   toOmitFields.push(`bd_source_date-${j}`)
          //   toOmitFields.push(`bd_source_fee-${j}`)

          //   customObj[`bd_source-${j}`] = ''
          //   customObj[`bd_source_date-${j}`] = ''
          //   customObj[`bd_source_fee-${j}`] = ''
          // }

          if(data.strategists.length > 0){
            data.strategists.map(function(str, index){
              customObj[`strategist_name-${index+1}`] = str.strategist_full_name
              customObj[`bonus_paid-${index+1}`] = (str.strategist_fee_created !== '' && str.strategist_fee_created !== null) ? moment(str.strategist_fee_created).format("MM-DD-YYYY") : ''
              customObj[`fee_amount-${index+1}`] = (str.strategist_fee !== '' && str.strategist_fee !== null) ? '$'+str.strategist_fee : ''
            })
          }

          if(data.bd_source.length > 0){
            data.bd_source.map(function(bd, index){
              customObj[`bd_source-${index+1}`] = bd.full_name
              customObj[`bd_source_date-${index+1}`] = (bd.business_development_fee !== '' && bd.business_development_fee !== null) ? '$'+bd.business_development_fee : ''
              customObj[`bd_source_fee-${index+1}`] = (bd.bd_created_date !== '' && bd.bd_created_date !== null) ? moment(bd.bd_created_date).format("MM-DD-YYYY") : ''
            })
          }
          customObj.total_sj_revenue = data.total_sj_revenue
          customExportAccountingReportData.push(customObj)
          exportAccountingReportData.push(exObj)
        })


        accountingData = _.reject(accountingData, ['strategist_full_name', '' ])
        customExportAccountingReportData = _.reject(customExportAccountingReportData, ['strategist_name', '' ])


        let sheetHeaders = [];
       
         Promise.all(!isEmpty(customExportAccountingReportData) &&  Object.keys(customExportAccountingReportData[0]).map((k, i) => {
           let headerName = k.replace(/_/g, " ")
           headerName = headerName.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
          sheetHeaders.push(headerName)
        }))
        
        this.setState({ toOmitFields })
        this.setValuesForExcelSheet(sheetHeaders, customExportAccountingReportData)
        this.setValuesForExcelSheet2(customExportAccountingReportData, toOmitFields)
        this.setState({ isLoadingData: false, accountingReportData:accountingData, totalRevenue:nextProps.accountingReportData.totalRevenue, exportAccountingReportData:exportAccountingReportData },()=> {})
      }else{
        this.setState({ isLoadingData: false, accountingReportData:accountingData, exportAccountingReportData:exportAccountingReportData },()=> {})
      }
    }
  }

  componentDidMount() {
    // this.props.getFilterParams()
  }

  strategistDataLoad(){
    const { filterParams } = this.props
    if( filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.length > 0 ){      
      let strList = filterParams.data && filterParams.data.strategists
      if(this.state.strategist_id == 0){
        let strategistData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
          const data = {}
          data.user_id = _user.id;
          const strData = {}
          if(this.state.selectedIndex === "all"){
            strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          }else{
            strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
          }
          strData.type = this.state.type
          strData.strategistId = strategistData.id
          strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
          this.props.getAccountingReportData(strData)
      }
    }
  }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate, multiDataSet:[], multiDataSet2:[] })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate, multiDataSet:[], multiDataSet2:[]})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate, multiDataSet:[], multiDataSet2:[] }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedIndex === "all"){
            strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
          }
          strData.dateType = "from_date"
          strData.type = this.state.type
          strData.strategistId = this.state.strategist_id
          strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
          this.props.getAccountingReportData(strData)
        }.bind(this), 300)
    })
  }

  onOpenBizModal(client, client_id) {
    var reportData = this.state.accountingReportData
    var index = _.findIndex(reportData, ['client_id', client_id])

    const bd_source = client && client.bd_source
    if(bd_source.length > 0){
      this.setState({ openBiz: true, currentBDSource: bd_source, current_client:client_id, updateBiz:index })
    }
  }

  
  onCloseBizModal() {
    this.setState({ currentBDSource: [], openBiz: false, current_client:'', updateBiz:null, multiDataSet:[], multiDataSet2:[] })
    const strData = {}
    if(this.state.selectedIndex === "all"){
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
    strData.type = this.state.type
    strData.strategistId = this.state.strategist_id
    this.props.getAccountingReportData(strData)
  }


  onOpenFeeModal(client, client_id){
    var reportData = this.state.accountingReportData
    var index = _.findIndex(reportData, ['client_id', client_id])
    this.setState({ openFee: true, strategist_fee_client:client, updateFeeIndex:index, current_client:client_id })
  }

  onCloseFeeModal() {
    this.setState({ openFee: false, strategist_fee_client: {}, updateFeeIndex:null, current_client:'',multiDataSet:[], multiDataSet2:[] })
    const strData = {}
    if(this.state.selectedIndex === "all"){
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
    strData.type = this.state.type
    strData.strategistId = this.state.strategist_id
    this.props.getAccountingReportData(strData)
  }


  filterDate(e){
    this.setState({ isLoadingData: true },() => {})
    if(e.target.value === "all"){
      this.setState({ modalIsOpen: false, multiDataSet:[], multiDataSet2:[] })
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    }else{
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    }
    this.setState({ selectedIndex: e.target.value, multiDataSet:[], multiDataSet2:[] },() => {})
  }

  handleBizChange(biz, client_id, e ){

    var reportData = this.state.accountingReportData
    var index = _.findIndex(reportData, ['client_id', client_id])
    var data = reportData[index]
    var indexBiz = _.findIndex(data.bd_source, ['id', biz.id])
    var dataBiz = data.bd_source[indexBiz]
    dataBiz.business_development_fee = e.target.value
    dataBiz.isUpdate = true
    this.setState({ updateBiz: index})
  }
  
  handleFeeChange(strategist, e){
    var reportData = this.state.accountingReportData
    var index = _.findIndex(reportData, ['client_id', this.state.current_client])
    var data = reportData[index]
    var straIndex = _.findIndex(data.strategists, ['strategist_id', strategist.strategist_id])
    var strategistData = data.strategists[straIndex]
    strategistData.strategist_fee = e.target.value
    strategistData.isUpdate = true
    this.setState({ updateFeeIndex: index})
  }

  saveBiz(client_id){
    var reportData = this.state.accountingReportData
    let obj = {
      type:'bd_source',
      client_id:client_id,
      bd_source:reportData[this.state.updateBiz].bd_source
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/analytics/update_accounting_report',
      data:obj
    })
    .then((res) => {
      this.setState({ openBiz: false, updateBiz: null, current_client:'', isLoadingData: true, multiDataSet:[], multiDataSet2:[] })
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    }).catch(logoutInvalidRequest)
  }

  saveFee(client){
    var reportData = this.state.accountingReportData
    let obj = {
      type:'strategist_fee',
      client_id:client.client_id,
      strategists:reportData[this.state.updateFeeIndex].strategists,
      deal_id: reportData[this.state.updateFeeIndex].deal_id
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/analytics/update_accounting_report',
      data:obj
    })
    .then((res) => {
      this.setState({ openFee: false, updateFeeIndex: null, strategist_fee_client:{}, isLoadingData: true, multiDataSet:[], multiDataSet2:[] })
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    }).catch(logoutInvalidRequest)
  }

  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoadingData: true },()=>{
      const strData = {}
        if(this.state.selectedIndex === "all"){
          strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
          strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        }else{
          strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
          strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        }
        strData.type = this.state.type
        strData.strategistId = this.state.strategist_id
        strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getAccountingReportData(strData)
    })
  }


  clickOnClient(clientId, deal_status, e){
    if(typeof window !== undefined){
      if(_user && (_user.role == 'admin' || _user.role == 'strategist') &&  deal_status === 'Accepted Offer'){
        window.open(`/add-deal?id=${clientId}`, "_blank")
      }

      if(_user && _user.role == 'superadmin'){
        window.open(`/add-deal?id=${clientId}`, "_blank")
      }
    }
  }

  handleFeeDate(client, date){
    var clientData = this.state.strategist_fee_client
    var straIndex = _.findIndex(clientData.strategists, ['strategist_id', client.strategist_id])
    var strategistData = clientData.strategists[straIndex]
    strategistData.strategist_fee_created = date
    strategistData.isUpdate = true
    clientData.strategists[straIndex] = strategistData
    this.setState({strategist_fee_client: clientData})
  }

  handleBDDate(client, date){
    var bdData = this.state.currentBDSource
    var bdIndex = _.findIndex(bdData, ['id', client.id])
    var businessData = bdData[bdIndex]
    businessData.bd_created_date = date
    businessData.isUpdate = true
    bdData[bdIndex] = businessData
    this.setState({ currentBDSource: bdData})
  }

  onOpenCheckModal(client){
    this.setState({ openCheck:true, deal_check_client:client, current_client:client.client_id })
  }

  onCloseCheckModal(){
    this.setState({ openCheck:false, deal_check_client:{}, current_client:'' })
  }

  handleCheckDate(client, date){
    var clientData = this.state.deal_check_client
    clientData.check_received = date
    this.setState({deal_check_client:clientData})
  }

  saveCheckReceived(client){
    let obj = {
      type:'check_received',
      client_id:client.client_id,
      client: client,
      deal_id: client.deal_id
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/analytics/update_accounting_report',
      data:obj
    })
    .then((res) => {
      this.setState({ openCheck: false, updateFeeIndex: null, deal_check_client:{}, isLoadingData: true, multiDataSet:[], multiDataSet2:[] })
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    }).catch(logoutInvalidRequest)
  }

  sortData(val){
    let sortByType = this.state.sortByType
    if(val !== this.state.sortByKey ){
      sortByType = 0
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType, multiDataSet:[], multiDataSet2:[]}, ()=>{
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      strData.strategistId = this.state.strategist_id
      this.props.getAccountingReportData(strData)
    })
  }

  render() {
    const { clientsPerAgentsData, clientsPerAgentsPhase, filterParams } = this.props
    let filterList = [];
    const { selectedIndex, clientsAssignToAgentState, accountingReportData, exportAccountingReportData } = this.state;
    const clientsAssignToAgent = clientsAssignToAgentState

    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });

    return (
      <div>

        <div className="container-fluid">
          <div className="row">
            <div className="all__reporting--headers">
              <div className="logo__header">
                <a>Accounting Report</a>
              </div>
              <div className="close__btn">
              <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
            <h4 className="stragist-heading"><span className="heading__small">{this.state.totalRevenue}</span> Total Revenue</h4>

            <h4 className="stragist-heading stragist-heading--small"><span>{accountingReportData && accountingReportData.length}</span> Clients</h4></div>
            <div className="col-md-12">
            <div className="reports__flex reports__flex--bottom">

            <div className="dp-wrapper dp__wrapper--hafl wrapper__onethird">
              {
                (_user && (_user.role === 'superadmin')) &&
                <div className="dp-wrapper stragist-span">
                  <label className="labelinputs__global">Strategists</label>
                  <select 
                    className="selectinputs__global" 
                    name="stategist_id"
                    value={this.state.strategist_id}
                    onChange={this.handleChange.bind(this)}>
                    <option value={0}>All</option>
                    {strategistList}
                  </select>
                </div>
              }
            </div>

            
            <div className="right__cont right__cont--agent right__onethird fullwidth__agentrepo fulagent">
              <span className="all__custom--wrapper">
                <select 
                 className="date__picker"
                  name="selectedIndex"
                  value = {this.state.selectedIndex} 
                  onChange={this.filterDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </span>

            

              {
                (this.state.selectedIndex !== "all") &&
                <span className="datepicker__fix datepicker__fix--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                     <Modal2
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={customStyles}           
                      contentLabel="Example Modal">
                      <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                        <div className="modal-content">             
                          <div className="modal-body text-center">
                          <div className="calendar__header">
                            <h4>Date Range</h4>
                            <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                          </div>
                          <DateRange
                            onInit={this.handleSelect.bind(this)}
                            onChange={this.handleSelect.bind(this)}
                            startDate={this.state.fromDate}
                            endDate={this.state.endDate}
                          />
                          <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                          </div>               
                        </div>
                      </div>
                    </Modal2>
                    </div>
                  }
                </span>
              }

              {
                (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com") ? 
                  <ExcelFile element={<button  className="reporting__export--btn exporthide">Export Data</button>}>
                    <ExcelSheet dataSet={this.state.multiDataSet} name="accounting_report">
                      {/* <ExcelColumn label="Strategist Name" value="strategist_full_name"/>
                      <ExcelColumn label="Client Name" value="client_full_name"/>
                      <ExcelColumn label="Agent Name" value="agent_full_name"/>
                      <ExcelColumn label="Agent Firm Name" value="agent_firm_name"/>
                      <ExcelColumn label="Closed Date" value="closed_date"/>
                      <ExcelColumn label="Check Received" value="check_received"/>
                      <ExcelColumn label="Bonus Paid" value="bonus_paid_date"/>
                      <ExcelColumn label="Sales Price" value="sales_price"/>
                      <ExcelColumn label="% Commission Payout" value="buy_side_commission"/>
                      <ExcelColumn label="Misc. Fees" value="sj_comission"/>
                      <ExcelColumn label="Strategist Fee" value="strategist_fee"/>
                      <ExcelColumn label="BD Source" value="bd_source"/>
                      <ExcelColumn label="Total SJ Revenue" value="total_sj_revenue"/> */}
                    </ExcelSheet>
                  </ExcelFile>
                : <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                  <ExcelSheet dataSet={this.state.multiDataSet2} name="accounting_report">
                    {/* <ExcelColumn label="Strategist Name" value="strategist_full_name"/>
                    <ExcelColumn label="Client Name" value="client_full_name"/>
                    <ExcelColumn label="Agent Name" value="agent_full_name"/>
                    <ExcelColumn label="Agent Firm Name" value="agent_firm_name"/>
                    <ExcelColumn label="Closed Date" value="closed_date"/>
                    <ExcelColumn label="Check Received" value="check_received"/>
                    <ExcelColumn label="Bonus Paid" value="bonus_paid_date"/>
                    <ExcelColumn label="Sales Price" value="sales_price"/>
                    <ExcelColumn label="% Commission Payout" value="buy_side_commission"/>
                    <ExcelColumn label="Misc. Fees" value="sj_comission"/>
                    <ExcelColumn label="Total SJ Revenue" value="total_sj_revenue"/> */}
                  </ExcelSheet>
                </ExcelFile>
              }
            </div>
            </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
        {
          (accountingReportData && accountingReportData.length > 0) && 
          <main className="jungler__page--container">
            <div className="filter__container filter__container--users">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra">
                   <div className="table-responsive">
                     <table className="table table-striped"> 
                        <thead>
                          <tr> 
                            <th className="text-left">Strategist Name</th>
                            <th className="text-left" onClick={this.sortData.bind(this , 'client_name')}>Client Name</th>
                            <th className="text-left" onClick={this.sortData.bind(this , 'agent_name')}>Agent Name</th>
                            <th className="text-left">Agent Firm Name</th>
                            <th className="text-left" onClick={this.sortData.bind(this , 'tag')}>Status (Agent)</th>
                            <th className="text-left" onClick={this.sortData.bind(this , 'closed_date')}>Expected Closing Date</th>
                            <th className="text-left">Check Received</th>
                            {/* <th className="text-left">Agent Bonus Paid</th> */}
                            <th className="text-left">Sales Price</th>
                            <th className="text-left">% Commission Payout</th>
                            <th className="text-left">Misc. Fees</th>
                            {
                              ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                                <th className="text-left strategist__width">Strategist Fee</th>
                            }
                            {
                              ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                                <th className="text-left strategist__width">BD Source</th>
                            }
                            <th className="text-left">Total SJ Revenue</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="9" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your accounting report is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                          {
                            accountingReportData && accountingReportData.map((account, index)=>{
                             let bd_source_count = account.bd_source.length
                              return(
                                <tr key={index} className="forcursor" >
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.strategist_full_name}</td>
                                  <td className="green__anchor" onClick={this.clickOnClient.bind(this, account.client_id, account.status)} >{account.client_full_name}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.agent_full_name}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.agent_firm_name}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.status}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)} >{account.closed_date}</td>
                                  <td><div className="strategist__links" onClick={this.onOpenCheckModal.bind(this, account)}>{'View/Edit'}
                                         </div></td>
                                  {/* <td>{account.bonus_paid_date}</td> */}
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.sales_price}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.buy_side_commission}</td>
                                  <td onClick={this.clickOnClient.bind(this, account.client_id, account.status)}>{account.sj_comission}</td>
                                  {
                                    ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                                        <td className="strategist__width">
                                          <div className="strategist__links" onClick={this.onOpenFeeModal.bind(this, account, account.client_id)}>{(account.strategists.length > 0 ? 'View/Edit' : '')}
                                         </div>
                                        {
                                          account.strategists && account.strategists.length > 0 && 
                                          account.strategists.map((acc, accIdx)=>{
                                            return(
                                              <div className="detailsgap" key={accIdx}>
                                                <div>
                                                  <label className="labelglobal">Strategist-{accIdx+1}:</label>
                                                  <p>{acc.strategist_full_name}</p>
                                                </div>
                                                <div>
                                                  <label className="labelglobal">Fee Amount:</label>
                                                  <p>{(acc.strategist_fee !== '' && acc.strategist_fee !== null ? '$'+acc.strategist_fee : '')}</p>
                                                </div>
                                                <div>
                                                  <label className="labelglobal">Bonus Paid:</label>
                                                  <p>{(acc.strategist_fee_created !== '' && acc.strategist_fee_created !== null) ? moment(acc.strategist_fee_created).format('MM-DD-YYYY') :''}</p>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                        </td>
                                        
                                  }

                                  {
                                    ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                                      <td className="strategist__width"><div className={(bd_source_count > 0 ? "strategist__links" : "")} onClick={this.onOpenBizModal.bind(this, account, account.client_id)} >{(bd_source_count > 0 ? 'View/Edit' : '')}</div>
                                      {
                                        bd_source_count > 0 && account.bd_source && account.bd_source.map((bd, bdInx)=> {
                                          return(
                                            <div className="detailsgap"  key={bdInx}>
                                              <div>
                                                <label className="labelglobal">BD Source-{bdInx+1}:</label>
                                                <p>{bd.full_name}</p>
                                              </div>
                                              <div>
                                                <label className="labelglobal">BD Source Fee:</label>
                                                <p>{(bd.business_development_fee !== '' && bd.business_development_fee !== null ? '$'+bd.business_development_fee : '')}</p>
                                              </div>
                                              <div>
                                                <label className="labelglobal">BD Source Date:</label>
                                                <p>{(bd.bd_created_date !== '' && bd.bd_created_date !== null) ? moment(bd.bd_created_date).format('MM-DD-YYYY') :''}</p>
                                              </div>
                                            </div>
                                          )
                                        })
                                      } 
                                      </td>
                                        
                                  }
                                  <td>{account.total_sj_revenue}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody> 
                        }
                      </table>
                     </div>
                  </div>
                </div>
               </div>
              </main>
             }
              <div >
                <Modal2 isOpen={this.state.openBiz}  center className="updatemodal">
                  <div>
                  <div className="flexcontent">
                    <h2 className="modal-header">Business Development Fee</h2>
                    <div className="close__btn" onClick={this.onCloseBizModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    {this.state.currentBDSource && this.state.currentBDSource.map((biz, index) => {
                        return (
                          <div  key={index}>
                            <h4 className="subhead">{biz.full_name}</h4>
                            <div className="leftside">
                              <label className="labelglobal">Fee Amount</label>
                              <NumberFormat
                                //prefix={'$'} 
                                name="business_development_fee"
                                className="form-control inputglobal"
                                placeholder=""
                                fixedDecimalScale={true}
                                value={biz.business_development_fee} 
                                thousandSeparator={true} 
                                isNumericString={true}
                                decimalScale={2}
                                onChange={this.handleBizChange.bind(this, biz, this.state.current_client)}
                               />
                            </div>
                            <div className="rightside datepickerformodal">
                              <label className="labelglobal">Select Date</label>
                              <DatePicker
                                customInput={<DatePickerCustomInput />}
                                onChange={this.handleBDDate.bind(this, biz)}
                                showTimeSelect
                                value={(biz.bd_created_date !== '' && biz.bd_created_date !== null) ? moment(biz.bd_created_date).format('MM-DD-YYYY') :'Select Date'}
                                className="form-control selectdate__global">
                              </DatePicker>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                          )
                      })}
                    {
                      ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                        <button className="savebtn__fullwidth" onClick={this.saveBiz.bind(this,this.state.current_client)}>Save</button>     
                    }
                  </div>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={this.state.openFee}  center className="updatemodal">
                  <div>
                    <div className="flexcontent">
                    <h2 className="modal-header">Strategist Fee</h2>
                    <div className="close__btn" onClick={this.onCloseFeeModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    {this.state.strategist_fee_client && this.state.strategist_fee_client.strategists && this.state.strategist_fee_client.strategists.map((stra, index) => {
                      return (
                        <div key={index}>
                          <h4 className="subhead">{stra.strategist_full_name}</h4>
                          <div className="leftside">
                            <label className="labelglobal">Fee Amount</label>
                            <NumberFormat
                              //prefix={'$'} 
                              name="business_development_fee"
                              className="form-control inputglobal"
                              placeholder=""
                              fixedDecimalScale={true}
                              value={stra.strategist_fee} 
                              thousandSeparator={true} 
                              decimalScale={2}
                              isNumericString={true}
                              onChange={this.handleFeeChange.bind(this, stra)}
                              />
                          </div>
                          <div className="rightside datepickerformodal">
                            <label className="labelglobal">Select Date</label>
                            <DatePicker
                              customInput={<DatePickerCustomInput />}
                              onChange={this.handleFeeDate.bind(this, stra)}
                              showTimeSelect
                              value={(stra.strategist_fee_created !== '' && stra.strategist_fee_created !== null) ? moment(stra.strategist_fee_created).format('MM-DD-YYYY') :'Select Date'}
                              className="form-control custom-select">
                            </DatePicker>
                          </div>
                          <div className="clearfix"></div>
                          
                        </div>
                      )
                    })}
                      {
                      ( (_user && _user.role === "superadmin") || (_user && _user.email === "alyssa@suburbanjunglegroup.com")) &&
                        <button className="savebtn__fullwidth" onClick={this.saveFee.bind(this, this.state.strategist_fee_client)}>Save</button>
                          
                    }
                  </div>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={this.state.openCheck}  center className="updatemodal">
                  <div>
                    <div className="flexcontent">
                    <h2 className="modal-header">Check Received?</h2>
                    <div className="close__btn" onClick={this.onCloseCheckModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div>
                      <div className="rightside datepickerformodal rightsidefull">
                        <label className="labelglobal">Select Date</label>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleCheckDate.bind(this, this.state.deal_check_client)}
                          showTimeSelect
                          value={(this.state.deal_check_client && this.state.deal_check_client.check_received !== '' && this.state.deal_check_client.check_received !== null) ? moment(this.state.deal_check_client.check_received).format('MM-DD-YYYY') :'Select Date'}
                          className="form-control custom-select">
                        </DatePicker>
                      </div>
                      <div className="clearfix"></div>
                      <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={this.saveCheckReceived.bind(this, this.state.deal_check_client)}>Save</button>
                    </div>
                  </div>
                </Modal2>
              </div>
              {
                accountingReportData && accountingReportData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Accounting Report</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'AccountingReportlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AccountingReport)
