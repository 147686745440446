import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import moment from 'moment'
import _ from "lodash";
import timezone from 'moment-timezone';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker'
import Cookies from 'universal-cookie'
//importing material ui popup for multiselect checkbox
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Select from '@material-ui/core/Select';
import Select from 'react-select'
import Checkbox from '@material-ui/core/Checkbox';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const reviewSourceNames = [
//   'Facebook',
//   'Google'
// ];

const reviewSourceNames = [
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Google', label: 'Google' },
]

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

let pre_Auth = ''
let _user = ''

class AddClientsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      err: {},
      time_zone: moment.tz.guess(),
      errMessage:'',
      isLoading: false,
      client_full_name: '',
      secondary_name: '',
      client_email: '',
      secondary_email: '',
      phone_mobile: '',
      secondary_phone_email:'',
      strategist_id: '',
      price_bracket_id: '',
      status: '',
      suburb_id: '',
      checkeditems: [],
      suburbitems: [],
      speak_to_press: '',
      review: '',
      review_source: [],
      currentuser: '',
      signup_source:'sbj',
      client_ID: 0,
      country_code:'+1',
      secondary_country_code:'+1',
      rental_budget:''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  
  componentWillMount(){
    const {location} = this.props
    let _currentuser = cookies.get('user')
    this.setState({currentuser:_currentuser}, ()=>{
    });
    this.props.getFilterParams()
    if(this.props.location && this.props.location.state && this.props.location.state.user && this.props.location.state.user.client_id !='') {
      const userarray = this.props.location.state.user;
      for (let obj of Object.entries(userarray)) {
        let keyname = obj[0];
        let valname = obj[1];
        if(valname ===null){
          valname = '';
        }
        this.setState({[keyname]: valname, client_ID: this.props.location.state.user.client_id })
      }
    }
    location && location.state && location.state.user && location.state.user.strategists && location.state.user.strategists.map((val, index)=>{
      this.state.checkeditems.push(val.user_id)
    }) 

    location && location.state && location.state.user && location.state.user.suburbs && location.state.user.suburbs.map((sub, idx)=>{
      this.state.suburbitems.push(sub.suburb_id)
    })

    this.setState({checkeditems: this.state.checkeditems, suburbitems: this.state.suburbitems})
    // Setting Pre Auth info
    pre_Auth = cookies.get('i_user')
    if(pre_Auth){
      this.setState({pre_Auth: pre_Auth})
    }
  }

  componentDidMount(){
   
    document.title = "Jungler: Create Client";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleDateChange(name, date) {
    this.setState({[name]: date});
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.dataPhase === "error") {
      this.setState({ errMessage: nextProps.clientError, isLoading: false})
    }
    if(nextProps && nextProps.addUpdateClientdata && nextProps.addUpdateClientdata.status === true){
      this.setState({ errMessage: nextProps.addUpdateClientdata.message, isLoading: false})
      this.props.clearPhase();
      setTimeout(function(){
        this.setState({ errMessage: ''})
        if(this.state.client_id && this.state.client_id!='') {
        this.props.history.push(`/activity/${this.state.client_id}`)
        }else{
          this.props.history.push(`/clients-list`)
        }
      }.bind(this), 800)
    }else if(nextProps && nextProps.addUpdateClientdata && nextProps.addUpdateClientdata.status ===false){
      this.props.clearPhase();
      this.setState({ errMessage: nextProps.addUpdateClientdata.message, isLoading: false})
    }
  }

  handleSubmit(event) {
    this.setState({isLoading: true})
    event.preventDefault()
    let onlyNumber = this.state.phone_mobile && this.state.phone_mobile.replace(/\D/g, '')
    let mobileValidation = onlyNumber.length
    const err = {}
    const {client_full_name, signup_source, client_id, client_email, secondary_email, phone_mobile, status, price_bracket_id, source, strategist_id, suburb_id, time_zone, review, speak_to_press, review_date , review_source} = this.state;
    if (client_full_name === '' || client_full_name.trim() === '') {
      err.client_full_name = 'Client name is required'
    }
    if (client_email === '' || client_email.trim() === '') {
      err.client_email = 'Client email is required'
    }else if (!this.validateEmail(client_email)) {
        err.client_email = 'Your email address is Invalid.'
    }

    if (secondary_email && !this.validateEmail(secondary_email)) {
        err.secondary_email = 'Your email address is Invalid.'
    }
    
    if (this.state.checkeditems.length === 0) {
      err.strategist_id = 'Strategist is required'
    }
    if (phone_mobile === '' || phone_mobile.trim() === '') {
      err.phone_mobile = 'Mobile number is required'
    }else if(mobileValidation < 6  || mobileValidation > 16 ){
      err.phone_mobile = 'Enter Correct Mobile Number'
    }
    if (price_bracket_id === '' || price_bracket_id === "Select Price Range") {
      err.price_bracket_id = 'Price is required'
    }

    if (status === '') {
      err.client_status = 'Pipeline Stage is required'
    }
    if (signup_source === '') {
      err.signup_source = 'Signup Source is required'
    }

    if (this.state.suburbitems.length === 0) {
      err.suburb_id = 'Suburbs is required'
    }
    
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { addUpdateClient } = this.props
      const data = {}
      data.phone_mobile = phone_mobile
      data.client_email = client_email.toLowerCase()
      data.time_zone = time_zone
      data.suburb_arr = this.state.suburbitems
      data.strategist_arr = this.state.checkeditems
      data.price_bracket_id = price_bracket_id
      data.status = status
      data.follow_up_date = moment(this.state.follow_up_date).format('YYYY-MM-DD hh:mm') 
      data.source = source
      data.id = client_id
      data.client_full_name = client_full_name
      data.review = review
      data.signup_source = signup_source
      data.secondary_name = this.state.secondary_name
      data.secondary_email= secondary_email.toLowerCase()
      data.secondary_phone_email = this.state.secondary_phone_email
      data.country_code = this.state.country_code
      data.secondary_country_code = this.state.secondary_country_code
      data.rental_budget = this.state.rental_budget

      if(review==="Yes"){
        data.review_date = moment(review_date).format('YYYY-MM-DD hh:mm') ;
        data.review_source = review_source;
      }
      data.speak_to_press = speak_to_press
      let _currentuser = cookies.get('user')
      this.setState({currentuser:_currentuser}, ()=>{
        let currentUserName = this.state.currentuser.first_name +' '+ this.state.currentuser.last_name;
        let cureentUserRole = this.state.currentuser.role;
        if(currentUserName && currentUserName !='' && cureentUserRole && cureentUserRole !=''){
          data.current_user_name = currentUserName;
          data.current_user_role = cureentUserRole;
          // addUpdateClient(data)
        }else{
          return false
        }
      });
    }else{
      this.setState({isLoading: false})
    }
  }

  selectCheckbox(item) {
    let data = {}
    let checked = this.state.checkeditems
    if (checked.indexOf(item.id) === -1) {
      checked.push(item.id)
    } 
    else {
      checked.splice(checked.indexOf(item.id), 1)
    }
    this.setState({checkeditems: checked})
  }

  // handleReviewSourceChange(e){
    // e.preventDefault()
    // let { review_source } = this.state;
    // let checked = []
    // checked.push(review_source)

    // if (checked.indexOf(e.target.value) === -1) {
    //    checked.push(e.target.value)
    // } 
    // else {
    //   checked.splice(checked.indexOf(e.target.value), 1)
    // }
    // let data = {}
    // data.id = this.state.client_ID,
    // data.review_source = e.target.value
    // this.props.addUpdateClient(data)
    // this.setState({ review_source: checked})
  // }

  reviewHandleChange=(selectedOption)=>{
    this.setState({ review_source: selectedOption })
  }


  selectSuburbsbox(subData) {
    let data = {}
    let suburbs = this.state.suburbitems
    if (suburbs.indexOf(subData.id) === -1) {
      suburbs.push(subData.id)
    } 
    else {
      suburbs.splice(suburbs.indexOf(subData.id), 1)
    }
    this.setState({suburbitems: suburbs})
  }

  handleCodeChange(event){
    this.setState({ country_code: '+'+event})
  }

  handleCodeChange2(event){
    this.setState({ secondary_country_code: '+'+event})
  }

  render() {
    
    let { review_source } = this.state;
    review_source = review_source.length > 0 ? review_source : [];
    const {
      filterParams,
      user
    } = this.props
    let allData = filterParams.data;
    let allStrategists = allData && allData.strategists;
    if (pre_Auth && (pre_Auth.role !== 'superadmin' && pre_Auth.role !== 'admin')) {
      allStrategists = _.filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
    }else if(!pre_Auth && user && user.role == 'strategist') {
      allStrategists = _.filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
    }
 
    let priceOptions = allData && allData.price_brackets.map((pricesval, priceindx) => {
      let price_min = '$'+pricesval.price_min.toLocaleString()
      let price_max = '$'+pricesval.price_max.toLocaleString()
      let priceRange = price_min + ' - '+price_max
      if(pricesval.price_min === 0){
        priceRange = price_min
      }
      return (
        <option key={priceindx} value={pricesval.id}>{priceRange}</option>
      )
    });
    
    let statusOptions = allData && allData.statuses && allData.statuses.map((statussval, statusindx) => {
      return (
        <option key={statusindx} value={statussval.name}>{statussval.name}</option>
      )
    });
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile__header--wrapper">
                <div className="profile__header">
                  <a>
                    <img src="/img/logo.svg" />
                    <h4>
                      <span>|</span>{this.props.location && this.props.location.state && this.props.location.state.user && this.props.location.state.user.client_id ? 'Edit' : 'Add'} Client
                    </h4>
                  </a>
                </div>
                <div className="profile__close">
                  <Link to={this.props.location && this.props.location.state && this.props.location.state.user && this.props.location.state.user.client_id ? `/activity/${this.props.location.state.user.client_id}` : '/clients-list'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="profile__container">
          <div className="container">
            <div className="row">
            <div className="col-lg-4 col-sm-4">
              <h4 className="addclient-heading">Client Information</h4>
              <h5 className="addclient-sub">Enter in all this Client information</h5>
            </div>
              <div className="col-lg-8 col-sm-8 profile__container--form addclient__container--form">
                <div className="profile__container--form addclient__container--form">  
                  <div className="panel panel-default mobile__panel">
                    <div className="panel-body">
                      <form>
                        <label className="labelinputs__global">Name</label>
                        <div className="form-group text-group">
                          <input 
                            type="text" 
                            className="textinputs__global" 
                            name='client_full_name' 
                            value={this.state.client_full_name} 
                            onChange={this.handleChange.bind(this)} required/>
                          { this.state.err.client_full_name ?
                          <span className="error_field">
                          {this.state.err.client_full_name}
                          </span> : '' }
                        </div>
                        <label className="labelinputs__global">Email Address</label>
                        <div className="form-group text-group">
                          <input type="text" className="textinputs__global" name='client_email' value={this.state.client_email} onChange={this.handleChange.bind(this)} required/>
                          { this.state.err.client_email ?
                          <span className="error_field">
                          {this.state.err.client_email}
                          </span> : '' }
                        </div>

                        <label className="labelinputs__global">Client Mobile Number</label>
                        <div className="form-group text-group textinputs__global phonefull__input">
                          <PhoneInput 
                            defaultCountry={'us'} 
                            value={this.state.country_code}
                            autoFormat={false}
                            disableAreaCodes={true}
                            enableLongNumbers={false}
                            onChange={this.handleCodeChange.bind(this)}
                            buttonClass="flag-dropdown__login"
                          />
                          <NumberFormat
                            format="(###) ### #### #####"
                            //placeholder="(444)  444 - 4444"
                            value={this.state.phone_mobile}
                            name='phone_mobile'
                            id="flagInputControlMainPhone"
                            onChange={this.handleChange.bind(this)}
                          />
                          { this.state.err.phone_mobile ?
                          <span className="error_field">
                          {this.state.err.phone_mobile}
                          </span> : '' }
                        </div>

                        <label className="labelinputs__global">Secondary Individual</label>
                        <div className="form-group text-group">
                          <input 
                            type="text" 
                            className="textinputs__global" 
                            name='secondary_name' 
                            value={this.state.secondary_name} 
                            onChange={this.handleChange.bind(this)} required/>
                        </div>

                        <label className="labelinputs__global">Secondary Email</label>
                        <div className="form-group text-group">
                          <input 
                            type="text" 
                            className="textinputs__global" 
                            name='secondary_email' 
                            value={this.state.secondary_email} 
                            onChange={this.handleChange.bind(this)} required/>
                          { this.state.err.secondary_email ?
                          <span className="error_field">
                          {this.state.err.secondary_email}
                          </span> : '' }
                        </div>
                        
                        <label className="labelinputs__global">Secondary Mobile Number</label>
                        <div className="form-group text-group textinputs__global phonefull__input">
                          <PhoneInput 
                            defaultCountry={'us'} 
                            value={this.state.secondary_country_code}
                            autoFormat={false}
                            disableAreaCodes={true}
                            enableLongNumbers={false}
                            onChange={this.handleCodeChange2.bind(this)}
                            buttonClass="flag-dropdown__login"
                          />
                          <NumberFormat
                             format="(###) ### #### #####"
                            //placeholder="(444)  444 - 4444"
                            value={this.state.secondary_phone_email}
                            name='secondary_phone_email'
                            id="flagInputControlMainPhone"
                            onChange={this.handleChange.bind(this)}
                          />
                        </div>

                        <div className="form-group text-group">
                          <label className="labelinputs__global">Signup Source</label>
                          <select className="selectinputs__global" name='signup_source' value={this.state.signup_source} onChange={this.handleChange.bind(this)}>
                          <option value ="sbj">Suburban Jungle</option>
                          <option value ="ubj">Urban Jungle</option>
                          </select>
                          { this.state.err.signup_source ?
                          <span className="error_field">
                          {this.state.err.signup_source}
                          </span> : '' }
                        </div>

                        <div className="form-group text-group">
                        <label className="labelinputs__global">Strategist(s) Assigned with</label>
                        <div className="strategy--form__card">
                          {
                            allStrategists && allStrategists.map((str, index) =>{
                              return(
                                <ul key={index} onClick={()=> this.selectCheckbox(str)} className="locations__list">
                                  <li className={this.state.checkeditems.indexOf(str.id) !== -1 ? 'active' : ''}>
                                  <div className="select__agent--name">
                                    <span 
                                      className="checkbox__icon">
                                      <img src={this.state.checkeditems.indexOf(str.id) === -1 ? 'img/checkblank-icon.svg' : 'img/checkmark-icon.svg'}/>
                                    </span>  
                                     {str.strategist_name}{str.role === 'former-strategist-inactive' ? "(inactive)" : ''}
                                  </div>
                                </li>
                                </ul>
                              )
                            })
                          }
                        </div>
                        { this.state.err.strategist_id ?
                        <span className="custom_err_msg">
                        {this.state.err.strategist_id}
                        </span> : '' }
                        </div>

                        <div className="form-group text-group">
                        <label className="labelinputs__global">Price Range</label>
                          <select className="selectinputs__global" name='price_bracket_id' value={this.state.price_bracket_id} onChange={this.handleChange.bind(this)}>
                          <option value ="">Select Price Range</option>
                          {priceOptions}
                          </select>
                          { this.state.err.price_bracket_id ?
                          <span className="error_field">
                          {this.state.err.price_bracket_id}
                          </span> : '' }
                        </div>
                        <div className="form-group text-group">
                          <label className="labelinputs__global">Rental Budget</label>
                          <input 
                            type="text" 
                            className="textinputs__global" 
                            name='rental_budget' 
                            value={this.state.rental_budget} 
                            onChange={this.handleChange.bind(this)}/>
                        </div>
                        <div className="form-group text-group">
                          <label className="labelinputs__global">Pipeline Stage</label>
                          <select className="selectinputs__global" name='status' value={this.state.status} onChange={this.handleChange.bind(this)}>
                          <option value ="">Select Pipeline Stage</option>
                          {statusOptions}
                          </select>
                          { this.state.err.client_status ?
                          <span className="error_field">
                          {this.state.err.client_status}
                          </span> : '' }
                        </div>
                        {/*<div className="form-group text-group">
                          <label className="labelinputs__global">Follow up date</label>
                            <DatePicker 
                            onChange={this.handleDateChange.bind(this, 'follow_up_date')}
                            minDate={new Date()}
                            showTimeSelect
                            className="selectdate__global"
                            value={this.state.follow_up_date ? moment(this.state.follow_up_date).format('MM/DD/YY') :'Select Date'}
                            placeholderText="Pick a date">
                          </DatePicker>
                          { this.state.err.agent_id ?
                          <span className="error_field">
                          {this.state.err.agent_id}
                          </span> : '' }
                        </div>*/}
                        <div className="form-group text-group">
                          <label className="labelinputs__global">Left Review</label>
                          <select
                            className="selectinputs__global" 
                            name='review' 
                            value={this.state.review} 
                            onChange={this.handleChange.bind(this)}>
                            <option value ="">Select an option</option>
                            <option value ="Asked">Asked</option>
                            <option value ="Yes">Yes</option>
                            <option value ="No">No</option>
                            <option value ="Declined">Declined</option>
                            <option value ="no_response">No Response</option>
                          </select>
                        </div>
                        {this.state.review==="Yes" &&
                          <div className="form-group text-group">
                            <label className="labelinputs__global">Review date</label>
                                <DatePicker 
                                onChange={this.handleDateChange.bind(this, 'review_date')}
                                minDate={new Date()}
                                showTimeSelect
                                className="selectdate__global"
                                value={this.state.review_date ? moment(this.state.review_date).format('MM/DD/YY') :'Select Date'}
                                placeholderText="Pick a date">
                                </DatePicker>
                          </div>
                        }
                        {
                          // this.state.review === 'Yes' &&
                          // <div className="form-group text-group">
                          //     <label className="select__label">Where the review was posted?</label>
                          //     <Select
                          //       name = 'review_source'
                          //       value={review_source}
                          //       onChange={this.handleReviewSourceChange.bind(this)}
                          //       input={<Input id="select-multiple-checkbox" />}
                          //       // renderValue={selected => selected.length >0  && selected.join(', ')}
                          //       MenuProps={MenuProps}
                          //       className="multiplesel-edit"
                          //       >
                          //       {reviewSourceNames.map(name => (
                          //         <MenuItem key={name} value={name}>
                          //           <Checkbox checked={this.state.review_source.indexOf(name) > -1} />
                          //           <ListItemText primary={name} />
                          //         </MenuItem>
                          //       ))}
                          //     </Select>
                          // </div>
                        }
                        {
                          this.state.review === 'Yes' &&
                          <div className="client__activity--info--items coverage__area">
                            <label className="labelinputs__global">Where the review was posted?</label>
                              <Select
                                multi 
                                simpleValue
                                className="review__select sel__review"
                                placeholder="Add a review"
                                options={reviewSourceNames}
                                onChange={this.reviewHandleChange.bind(this)} 
                                value={review_source}>
                              </Select>
                          </div>    
                        }
                        <div className="form-group text-group">
                          <label className="labelinputs__global">Willing to speak to press/featured in a blog?</label>
                          <select className="selectinputs__global" name='speak_to_press' value={this.state.speak_to_press} onChange={this.handleChange.bind(this)}>
                            <option value ="">Select an option</option>
                            <option value ="Asked">Asked</option>
                            <option value ="Yes">Yes</option>
                            <option value ="No">No</option>
                            <option value ="Declined">Declined</option>
                          </select>
                        </div>

                        <div className="form-group text-group">
                        <label className="labelinputs__global">Source</label>
                         <input type="text" className="textinputs__global" name='source' value={this.state.source} onChange={this.handleChange.bind(this)} required/>
                        </div>

                        <div className="form-group text-group">
                        <label className="labelinputs__global">Suburb/City</label>
                          <div className="strategy--form__card">
                          {
                            allData && allData.suburbs && allData.suburbs.map((sub, index) =>{
                              return(
                                <ul key={index} onClick={()=> this.selectSuburbsbox(sub)} className="locations__list">
                                  <li className={this.state.suburbitems.indexOf(sub.id) !== -1 ? 'active' : ''}>
                                  <div className="select__agent--name">
                                    <span 
                                      className="checkbox__icon">
                                      <img src={this.state.suburbitems.indexOf(sub.id) === -1 ? 'img/checkblank-icon.svg' : 'img/checkmark-icon.svg'}/>
                                    </span>  
                                     {sub.name}
                                  </div>
                                </li>
                                </ul>
                              )
                            })
                          }
                          </div>
                          { this.state.err.suburb_id ?
                          <span className="custom_err_msg">
                          {this.state.err.suburb_id}
                          </span> : '' }
                        </div>
    
                      <div className="row">
                      {this.state.errMessage ? <div className='text-center alert alert-success'>{this.state.errMessage}</div> : ''}
                      <div className="col-md-12 text-right-btn">
                      <Link to={this.props.location && this.props.location.state && this.props.location.state.user && this.props.location.state.user.client_id ? `/activity/${this.props.location.state.user.client_id}` : '/clients-list'} className="btn btn__black--outline btn__black--lg btn__cancel">Cancel</Link>
                      <button type="submit" disabled={this.state.isloading} onClick={this.handleSubmit.bind(this)} className="btn btn__green btn__green--lg">{this.state.isloading ? 'Saving...' : 'Save' }</button>
                      </div>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'addclients',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AddClientsForm)
