/* eslint-disable */
import 'rxjs'
import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import NumberFormat from 'react-number-format';
import axios from 'axios'
import moment from 'moment'
import Calendar from 'react-calendar'
import DatePicker from 'react-datepicker'
import timezone from 'moment-timezone';

import { getTimeFormate } from '../../config'
import calendar from './calendar.scss';
import styles from './styles.scss';
import $ from 'jquery';
import { logoutInvalidRequest } from '../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:true,
  autoplay:false
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows:false,
  autoplay:true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const settingsbg = {
  dots: false,
  infinite: true,
  speed: 800,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:false,
  autoplay:true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const responseFacebook = (response) => {

};

class DatePickerCustomInput extends React.Component {

  render () {
    var date = ''
   if(this.props.value){
      date = moment(this.props.value).format('ddd, MMM DD, YYYY')
   }
    return (
      <button
        className="schedule-date"
        onClick={this.props.onClick}>
        {date}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class SignupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      name: "",
      email: "",
      phone: "",
      city: "",
      price: "",
      info: "",
      source:"",
      date:"",
      times:[],
      activeSlide:'',
      availableDates: [],
      date: moment().format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      startDate:moment(),
      timezone_type:"UTC",
      selectedTime : "",
      noTimeWorks:false,
      noSchedule:false,
      scheduleToday:false,
      showTime:true,
      err: {},
      errMessage: "",
      infoLength:0,
      isLoading: 'Submit',
      isDateLoading:false,
      isAllDateloading:false,
      strategist:"",
      strategist_email:"false",
      no_strategist:false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handelDateChange = this.handelDateChange.bind(this)
  }

  // componentDidMount(){
  // // facebook signin  button render
  //   window.fbAsyncInit = function() {
  //     FB.init({
  //       appId      : '703440643378941',
  //       cookie     : true,  // enable cookies to allow the server to access
  //       xfbml      : true,  // parse social plugins on this page
  //       version    : 'v2.1' // use version 2.1
  //     });

  //     // login callback implementation goes inside the function() { ... } block
  //     FB.Event.subscribe('auth.login', function(response) {
  //       // example implementation
  //       if (response.authResponse) {
  //         FB.api('/me', function(response) {
  //         });
  //       } else {
  //       }
  //     });
  //   }.bind(this);

  //   // Load the SDK asynchronously
  //   (function(d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s); js.id = id;
  //     js.src = "//connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));
  // }

 componentWillMount(){
    window.localStorage.setItem('clientToken', null);
    window.localStorage.setItem('createSuccess', false);
    let budgetInit = config.bugets;
    const err = {}
    this.setState({ errMessage: "" })
    if(this.getParameterByName('email') && this.getParameterByName('email') != '' && this.getParameterByName('email') != null){
        this.setState({email:this.getParameterByName('email').toLowerCase()})
        if(!this.validateEmail(this.getParameterByName('email'))){
            err.email = 'Your email address is Invalid.'
            this.setState({ err })
        }
    }
    if(this.getParameterByName('full_name') && this.getParameterByName('full_name') != '' && this.getParameterByName('full_name') != null){
        this.setState({name:this.getParameterByName('full_name')})
    }
    if(this.getParameterByName('mobile') && this.getParameterByName('mobile') != '' && this.getParameterByName('mobile') != null){
        this.setState({phone:this.getParameterByName('mobile')})
    }
    if(this.getParameterByName('strategist_email') && this.getParameterByName('strategist_email') != '' && this.getParameterByName('strategist_email') != null){
      this.setState({strategist_email:this.getParameterByName('strategist_email')})
    }
    if(this.getParameterByName('strategist') && this.getParameterByName('strategist') != '' && this.getParameterByName('strategist') != null){
        this.setState({strategist:this.getParameterByName('strategist')})
        window.FbeventHander("Ques_PageWithStrategist")
        fbq('trackCustom', 'Ques_PageWithStrategist');
    }else{
      this.setState({no_strategist:true})
    }

    setTimeout(function(){
      //this.getMonthavailability(budgetInit[0].value, config.cities[0].value);
      window.FbeventHander("Ques_Page Loaded")
      fbq('trackCustom', 'Ques_Page Loaded');
      gtag('event','Ques_Page Loaded');
    },500)
    
    //ga('send', 'event', 'Questionnaire', 'Load', 'Ques_Page Loaded', '1');
  }

   componentWillReceiveProps(nextProps) {
     if(nextProps.user && nextProps.user.status==true){
      window.FbeventHander("ThankYou_Page_Loaded");
      window.location.replace('/thankyou');
     }else{
      this.setState({isLoading: 'Submit'})
      window.FbeventHander("Ques_Invalid_UserExist");
      fbq('trackCustom', 'Ques_Invalid_UserExist');
      window.alert(nextProps.user.message)
     }
  }


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  getMonthavailability(value, cityname){
    this.setState({'isAllDateloading':true})
    let price = ""
    let city = ""
    if(value && value !=''){
      price = value;
    } else {
      price = this.state.price;
    }

    if(cityname && cityname !=''){
      city = cityname;
    }else{
      city = this.state.city;
    }
    if(price && price != "" && city && city != ""){
      let data = new FormData();
      data.append('city', city)
      data.append('price', price)
      data.append('date', moment(this.state.date).format("MM/DD/YYYY"))
      data.append('timezone', this.state.timezone)
      data.append('strategist', this.state.strategist)
      // Calling the availbilty by month
      axios({
        method: 'POST',
        url: HOSTNAME+'/availlability/get_acuity_all_availlable_dates',
        data: data
      })
      .then((res) => {
        this.setState({ availableDates: [] });
        if(res.data.status) {
          this.setState({isAllDateloading:false})
          let dateArray = this.formateDates(res.data.data);
          if (dateArray.length > 0) {
            const replace = dateArray[0]
            this.setState({startDate:moment(replace), date:moment(dateArray[0]), strategy:res.data.strategy}, ()  => {
                  this.availability(price ? price : config.bugets[0].value, city ? city : config.cities[0].value);
                })
            this.setState({ availableDates:dateArray})
            //this.handleSlider(timeArray);
          } else {
            this.setState({availableDates:[]}) 
          }
        } else {
          this.setState({availableDates:[], })
        }
      }).catch(logoutInvalidRequest)
    }else{
       this.setState({availableDates:[], showTime: false})
    }
  }

  availability(value, cityname){
    this.setState({'isDateLoading':true})
    let price = ""
    let city = ""
    if(!value){
      price = this.state.price;
    } else {
      price = value;
    }

    if(!cityname){
      city = this.state.city;
    }else{
      city = cityname;
    }
    if(price && price != "" && city && city != ""){
      let data = {};
      data.city = city
      data.price = price
      data.date =  moment(this.state.startDate).format("MM/DD/YYYY")
      data.timezone = this.state.timezone
      data.strategy = this.state.strategy
      if(!this.state.no_strategist){
        data.strategist =  this.state.strategist
        data.strategist_email = this.state.strategist_email
      }else{
        data.strategist = 'false'
      }
      // Calling the Availabilty by single date
      axios({
        method: 'POST',
        url: HOSTNAME+'/users/questionnaireAvailability',
        data: data
      })
      .then((res) => {
        this.setState({ times: [] });
        if(res.data.status) {
          if(res.data.timezone_type && res.data.timezone_type!=""){
            this.setState({timezone_type:res.data.timezone_type})
          }
          let timeArray = res.data.data;
          if (timeArray.length > 0) {
            this.handleSlider(timeArray);
          } else {
             this.setState({ times: [], isDateLoading: false })
            // var currentTimestamp = moment(this.state.date).format("X");
            // var d = moment(this.state.date).add(1, 'days')._d
            // var SevenDays = moment().add(7, 'days')._d
            // var AfterWeektimestamp = moment(SevenDays).format("X");
            // if (currentTimestamp < AfterWeektimestamp) {
            //   this.setState({ date: moment(d).format("MM/DD/YYYY") }, ()  => {
            //     this.availability(value, cityname)
            //   });
            // }else{
            //   this.setState({ times: [], isDateLoading: false })
            // }
          }
        } else {
          this.setState({ times: [] })
          alert("All times booked! Please select a different criteria.");
        }
      }).catch(logoutInvalidRequest)

    }else{
      this.setState({ times: [] })
    }
  }

  handelDateChange(date){
   this.setState({startDate: date});
   this.setState({ times: [] });
    let d = date
    this.setState({ date: moment(d).format("MM/DD/YYYY") }, ()  => {
    if (this.state.city!='' && this.state.price!='') {
     this.availability()
   }else{
    this.availability(config.bugets[0].value, config.cities[0].value);
   }
    })
  }
  
  handleSlider(timeArray){
    setTimeout(function() { this.setState({isDateLoading: false}); }.bind(this), 500);
    let finalArray = []
    let arr = []
    let i = -1;
    let next = () => {
       i++;
       if(i < timeArray.length){
          arr.push(timeArray[i])
          if(arr.length > 8){
            finalArray.push(arr);
            arr = []
            next();
          } else {
            next();
          }
       } else {
          if(arr.length > 0){
            finalArray.push(arr);
          }
          this.setState({ times: finalArray })
       }
    }
    next();
  }

  formateDates(dateArray){
    let finalArr = [];
    dateArray.forEach(function(date){
      finalArr.push(moment(date.replace(/-/g, '/')));
    });
    return finalArr;
  }


  // Function to set form input values in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
    if(event.target.name == "price"){
       if(this.state.city !=''){
        this.getMonthavailability(event.target.value, this.state.city)
       }
       
       window.FbeventHander("Ques_BudgetSelected");
       gtag('event','Ques_BudgetSelected');
    }else if(event.target.name == "city"){
       if(this.state.price !=''){
         this.getMonthavailability(this.state.price, event.target.value)
       }
       window.FbeventHander("Ques_CitySelected");
       gtag('event','Ques_CitySelected');
    }else if(event.target.name == "name"){
       window.FbeventHander("Ques_NameFilled");
       gtag('event','Ques_NameFilled');
    }else if(event.target.name == "email"){
       window.FbeventHander("Ques_EmailFilled");
       gtag('event','Ques_EmailFilled');
    }else if(event.target.name == "info"){
       window.FbeventHander("Ques_AboutMe Filled");
       gtag('event','Ques_AboutMe Filled');

    }else if(event.target.name == "source"){
       window.FbeventHander("Ques_Howdidyouhear_filled");
       gtag('event','Ques_Howdidyouhear_filled');
    }
  }

  handleInfoChange(event){
    this.setState({ info: event.target.value, infoLength: event.target.value.length})
  }

  handleNumberChange(event){
    this.setState({ phone: event.target.value })
  }

  handleDateClick(type, date){
    this.setState({ times: [] });
    let d = "";
    if(type === 'next'){
       d = moment(this.state.date).add(1, 'days')._d
    } else {
       d = moment(this.state.date).add(-1, 'days')._d
    }
    this.setState({ date: moment(d).format("MM/DD/YYYY") }, ()  => {
     if (this.state.city!='' && this.state.price!='') {
     this.availability()
   }else{
    if (this.state.strategist=='') {
    alert('To pick a time please select a city and budget first.');
    }else{
      this.availability(config.bugets[0].value, config.cities[0].value);
    }
   }
    })
  }

  selectTime(time) {
    this.setState({selectedTime: time})
    window.FbeventHander("Ques_DateTimeSelected");
    gtag('event','Ques_DateTimeSelected');
    //ga('send', 'event', 'Questionnaire', 'Click', 'Ques_DateTimeSelected', '1');

  }

  handleCheck(event) {
    if(event.target.name == "noTimeWorks"){
        if(!this.state.noTimeWorks){
          this.setState({ showTime: false })
        }else{
          this.setState({ showTime: true })
        }
        this.setState({ [event.target.name]: !this.state.noTimeWorks, noSchedule: false });
        window.FbeventHander("Ques_DontSeeATime_Checked");
        gtag('event','Ques_DateTimeSelected');
        //ga('send', 'event', 'Questionnaire', 'Click', 'Ques_DontSeeATime_Checked', '1');
        fbq('trackCustom', 'Ques_DontSeeATime_Checked');

    } else if(event.target.name == "scheduleToday"){
      if(!this.state.scheduleToday){
        this.setState({ showTime: false })
      }else{
        this.setState({ showTime: true })
      }
      
      this.setState({ [event.target.name]: !this.state.scheduleToday, noSchedule: false });
      window.FbeventHander("Ques_scheduleToday");
      gtag('event','Ques_scheduleToday');
      ga('send', 'event', 'Questionnaire', 'Click', 'Ques_scheduleToday', '1');
      fbq('trackCustom', 'Ques_scheduleToday');

  } else {
       if(!this.state.noSchedule){
        this.setState({ showTime: false })
        }else{
          this.setState({ showTime: true })
        }
        this.setState({ [event.target.name]: !this.state.noSchedule, noTimeWorks: false });
        window.FbeventHander("Ques_DontScheduleNow_Checked");
        fbq('trackCustom', 'Ques_DontScheduleNow_Checked');
        gtag('event','Ques_DontScheduleNow_Checked');
    }
  }
  trackCloseEvent(event){
    window.FbeventHander("Ques_ExitWithoutSubmit");
    fbq('trackCustom', 'Ques_ExitWithoutSubmit');
    gtag('event','Ques_ExitWithoutSubmit');

  }
  handleClick(){
    const err = {}
    this.setState({ errMessage: "" })
    if (this.state.name === '' || this.state.name.trim() === '') {
        err.name = 'Please enter your Full Name.'
    }
    if (this.state.email === '' || this.state.email.trim() === '') {
        err.email = 'Please enter your Email.'
    } else if (!this.validateEmail(this.state.email)) {
        err.email = 'Your email address is Invalid.'
    }
    if (this.state.phone === '' || this.state.phone.trim() === '') {
        err.phone = 'Please enter your Number.'
    }
    if (this.state.city === '' || this.state.city.trim() === '') {
        err.city = 'Please select your City.'
    }
    if (this.state.info === '' || this.state.info.trim() === '') {
        err.info = 'Please provide more details.'
    }
    if (this.state.price === '' || this.state.price.trim() === '') {
        err.price = 'Please select your Budget.'
    }
    if (this.state.source === '' || this.state.source.trim() === '') {
        err.source = 'Please provide any source.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
        this.setState({isLoading: 'Please wait...'})
        const { createUserCheck } = this.props
        const data = new FormData();
        data.append('timezone', this.state.timezone);
        data.append('name', this.state.name);
        data.append('email', this.state.email.toLowerCase());
        data.append('phone', this.state.phone);
        data.append('city', this.state.city);
        data.append('info', this.state.info);
        data.append('price', this.state.price);
        data.append('source', this.state.source);
        data.append('strategy', this.state.strategy);
        data.append('strategist', this.state.no_strategist ? 'false': this.state.strategist);
        data.append('scheduletoday', this.state.scheduleToday == true ? 'on' : 'off')
        if(!this.state.noSchedule && !this.state.noTimeWorks && !this.state.scheduleToday){
            data.append('date', moment(this.state.startDate).format("YYYY-MM-DD"))
            data.append('time', this.state.selectedTime);
        }
        if(this.state.noSchedule){
           data.append('noSchedule', "on");
           window.FbeventHander("Ques_DontScheduleNow_Submitted");
           fbq('trackCustom', 'Ques_DontScheduleNow_Submitted');
           gtag('event','Ques_DontScheduleNow_Submitted');
 
        }
        if(this.state.noTimeWorks){
           data.append('noTimeWorks', "on");
           window.FbeventHander("Ques_DontSeeATime_Submitted");
           fbq('trackCustom', 'Ques_DontSeeATime_Submitted');
           gtag('event','Ques_DontSeeATime_Submitted');
  
        }
        createUserCheck(data)
        window.FbeventHander("Ques_Submitted");
        fbq('trackCustom', 'Ques_Submitted');
        gtag('event','Ques_Submitted');
    }else{
      ////window.FbeventHander("Invalid Submit");
      ////fbq('trackCustom', 'QuestionnaireInvalidsubmit', {usename:this.state.name , useremail:this.state.email.toLowerCase()});
      this.setState({isLoading: 'Submit'})
    }
  }

  render() {
    let cities = config.cities.map((val, index) => {
        return (
          <option key={index} value={val.value}>{val.label}</option>
        )
    });
    let bugets = config.bugets.map((val, index) => {
        return (
          <option key={index} value={val.value}>{val.label}</option>
        )
    });

    let times = this.state.times.map((val, index) => {
        let slot = val;
        return (
          <div className="time-listing" key={index}>
            <ul>
            {slot.map((v, i) =>
              <li key={i} className={this.state.selectedTime === v ? 'active' : ''} onClick={this.selectTime.bind(this, v)} style={{"cursor":"pointer"}}>{getTimeFormate(v).tval} {getTimeFormate(v).type} {this.state.timezone_type}</li>
            )}
            </ul>
          </div>
        )
    });
    return (
      <section className="contact-popup">
          <div className="container-fluid">
              <div className="row pop-up-header" >
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left-align">

                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 right-align">
                  <div className="row">
                    <a href={this.state.wpUrl}>
                      <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />
                    </a>
                  </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-10 center-align">
{/*                     <div className="fb-login-button" data-max-row="5"
                         data-size="large"
                         data-show-faces="false"
                         data-auto-logout-link="false"
                         href="javascript:void(0)">Continue with Facebook</div>*/}
                       {/*<div className="continue-fb"><a href="javascript:void(0)" onClick={this.handleFbLogin}><img src="/../img/oL5c2.png"/></a></div>*/}
{/*                    <FacebookLogin
                        appId="703440643378941"
                        autoLoad={true}
                        fields="name,email,picture"
                        cssClass="continue-fb"
                        callback={responseFacebook}
                        render={renderProps => (
                          <button onClick={renderProps.onClick}></button>
                        )}
                    />*/}
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 right-align">
                      <div className="close">
                        <a onClick={this.trackCloseEvent.bind(this)} href={`${this.state.wpUrl}`}>
                          <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                        </a>
                      </div>
                  </div>
              </div>
              <div className="row popup-marg">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="left-section">
                      <div className="signup-img">
                        <ul>
                          <Slider {...settingsbg}>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/signup1.jpeg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/signup2.jpeg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/signup3.jpeg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/signup4.jpeg`}/></li>
                          </Slider>
                      </ul>
                      <div className="overlay-img"></div>
                      <div className="contact-box-outer">
                      <div className="contact-box">
                      <p>
                      Technology. On the Ground Research. Personalized Strategy and handholding
                      <br/>
                      Begin your Suburbs Strategy Session to find the perfect suburb ….and then the perfect home!
                      </p>
                      </div>
                      </div>
                         <div className="">
                            <div className="slider-listing">
                              <ul>
                              <Slider {...settings2}>
                                <li><img src="/../../img/nyp.png"/></li>
                                <li><img src="/../../img/tbb.png"/></li>
                                <li><img src="/../../img/thp.png"/></li>
                                <li><img src="/../../img/us-news.png"/></li>
                                <li><img src="/../../img/nyp.png"/></li>
                              </Slider>
                              </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-frm">
                    <h4>Let's get to know each other better.</h4>

                    <p>
                       {this.state.strategist ?
                        <p>Provide us some quick info to schedule your initial suburbs strategy call with <span className="strategist-name-bold">{this.state.strategist}</span></p>
                        :
                        <p>Provide us some quick info to schedule your initial suburbs strategy call.</p>
                       }
                    </p>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="outer-input">

                              <input
                                type="text"
                                name="name"
                                placeholder="Enter your full name"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label>Full Name</label>
                              { this.state.err.name ?
                              <span className="error_field">
                              {this.state.err.name}
                              </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="outer-input">

                            <input
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              value={this.state.email}
                              onChange={this.handleChange.bind(this)}
                            />
                            <label>Email</label>
                            { this.state.err.email ?
                            <span className="error_field">
                            {this.state.err.email}
                            </span> : '' }
                        </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="outer-input">

                            <NumberFormat
                              format="(###)  ### - ####"
                              placeholder="(444)  444 - 4444"
                              value={this.state.phone}
                              onChange={this.handleNumberChange.bind(this)}
                            />
                            <label>Phone Number</label>
                            { this.state.err.phone ?
                            <span className="error_field">
                            {this.state.err.phone}
                            </span> : '' }
                        </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                          <div className="outer-input">
                            <select name="city" value={this.state.city} onChange={this.handleChange.bind(this)}>
                                <option value="">Select City</option>
                                { cities }
                            </select>
                            { this.state.err.city ?
                            <span className="error_field">
                            {this.state.err.city}
                            </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                        <div className="outer-input">
                            <select name="price" value={this.state.price} onChange={this.handleChange.bind(this)}>
                            <option value="">Select budget</option>
                                { bugets }
                            </select>
                            { this.state.err.price ?
                            <span className="error_field">
                            {this.state.err.price}
                            </span> : '' }
                        </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">

                            <textarea
                              name="info"
                              placeholder="Give us all the details! As much as you can!"
                              value={this.state.info}
                              onChange={this.handleInfoChange.bind(this)}
                            />
                            {/*<span className="textarea-counter">{this.state.infoLength}/500</span> */}
                            <label>It’s all about YOU</label>

                            { this.state.err.info ?
                            <span className="error_field">
                            {this.state.err.info}
                            </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">
                            <input
                              type="text"
                              name="source"
                              placeholder=""
                              value={this.state.source}
                              onChange={this.handleChange.bind(this)}
                            />
                            <label>Who told you how great we are? </label>
                            { this.state.err.source ?
                            <span className="error_field">
                            {this.state.err.source}
                            </span> : '' }
                          </div>
                        </div>
                        {this.state.city !=='' && this.state.price !=='' && this.state.showTime === true ?
                          <div>
                          <div data={this.state.activeSlide}  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center scheduleCalendar-wrapper">
                            {this.state.isAllDateloading ? 
                              <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                               Fetching available dates...
                              </div>
                              </div>
                            :
                             <div>
                              {this.state.availableDates && this.state.availableDates.length > 0 ?
                              <div>
                              <DatePicker
                              selected={this.state.startDate}
                              customInput={<DatePickerCustomInput />}
                              onChange={this.handelDateChange.bind(this)}
                              includeDates={this.state.availableDates}
                              className="schedule-date"
                              placeholderText="Select available dates to pic a time" />
                              </div>
                                :
                                <div className="No-timeSlots">Pick a city and budget to see available dates</div>
                              }
                              </div>
                            }
                          </div>
                         
                        { this.state.isAllDateloading || this.state.isDateLoading ?
                          <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                          <img src="../../img/loader2.svg" />
                          </div>
                          </div>
                          :
                          <div className="row">
                            {this.state.times.length > 0 ?
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Slider {...settings}>
                                     {times}
                                </Slider>
                              </div>
                                :
                                <div className="No-timeSlots">Pick a city and budget to see times</div>
                              }
                          </div>
                          }
                          </div>
                           :
                          ""
                        }
                           <br/>
                           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signups signups-pad">
                            <div className="row">
                              <label className="signups" >I don't see a time that works for me, please contact me
                              via email / text to set up a call
                                <input
                                  type="checkbox"
                                  name="noTimeWorks"
                                  value="noTimeWorks"
                                  checked={this.state.noTimeWorks}
                                  onChange={this.handleCheck.bind(this)}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="signups" >I am not ready to schedule right now
                                <input
                                  type="checkbox"
                                  disabled={this.state.scheduleToday}
                                  name="noSchedule"
                                  value="noSchedule"
                                  checked={this.state.noSchedule}
                                  onChange={this.handleCheck.bind(this)}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="signups" > Check here if you need to speak to a strategist TODAY
                                <input
                                  type="checkbox"
                                  name="scheduleToday"
                                  disabled={this.state.noSchedule}
                                  value="scheduleToday"
                                  checked={this.state.scheduleToday}
                                  onChange={this.handleCheck.bind(this)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          <br/>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <button className="btn btn-full DefaultBtn" onClick={this.handleClick.bind(this)}>{this.state.isLoading}</button>
                          </div>
                    </div>
                </div>
              </div>
          </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'signup',  // a unique identifier for this form
  destroyOnUnmount: true,
})(SignupForm)
