import React, { Fragment, useEffect } from 'react'
import { map } from 'lodash'

import { CloseIcon } from '../../../../../../components/icons'

import './styles.scss'

export default function AgentRankingComponent(props) {
  useEffect(() => {
    const clientId = props.match.params.id
    // eslint-disable-next-line camelcase
    props.getRankingByClientId({ clientId })
  }, [])

  const onClose = () => props.closeDrawer()

  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Agent Ranking</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="drawer__body">
        { props.agents && props.agents.length > 0 && props.isAgentRated ? (
          <ul className="ranking__list">
            {map(props.agents, (agent, agentIndex) => (
              <li key={agentIndex}>{`${agentIndex + 1}. ${agent.name}`}</li>
            ))}
          </ul>
          )
          : (
            <div className="drawer__body__inner">
              <div className="blank__state__text">
                This client has not ranked any agents yet. Once they rank then you
                will see them here.
              </div>
            </div>
        )}
      </div>
    </Fragment>
  )
}
