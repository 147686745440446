import React, { PureComponent /*, PropTypes*/ } from 'react';
// import IPropTypes from 'react-immutable-proptypes'
import get from 'lodash/get';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import Dropzone from 'react-dropzone';
import GoogleDrive from './googleDrive';
import SortableComponent from './ImageSortable';
import arrayMove from 'array-move';
import ConfirmModal from '../../../Common/ConfirmModal';

import { notification } from 'antd';

import './styles.scss';

export default class ImagesComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      featuredImage: {},
      images: [],
      isConfirmModal: false,
      angle: 0,
      rotateImageId: 0,
      rotate: 0,
      isDeleteModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.props.toggleLoading(true);
    const _user = get(this, 'props.user');
    const townId = get(this, 'props.match.params.townId');
    this.props.getGeneratedReport(townId);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const obj = {};
    if (get(nextProps, 'imageUploadPhase') === 'success') {
      if (nextProps.imageUpload.status) {
        nextProps.clearTownPhase();
        notification.success({
          message: 'Image uploaded successfully'
        });
        nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
      } else {
        notification.error({
          message: nextProps.imageUpload.message
        });
      }

    }

    if (get(nextProps, 'getGeneratedReportPhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.toggleLoading(false);

      obj.report = get(nextProps, 'report');

      const imgAr = orderBy(
        get(nextProps, 'report.images', []),
        ['sort_by'],
        ['asc']
      );
      let featuredImage = {};
      const nonFeaturedImages = filter(imgAr, img => {
        if (img.featured) {
          featuredImage = img;
        } else {
          return {
            ...img,
            loaded: false
          };
        }
      });

      obj.isLoading = false
      obj.featuredImage = featuredImage;
      obj.images = nonFeaturedImages;
    }

    if (get(nextProps, 'featured')) {
      nextProps.clearTownPhase();
      notification.success({
        message: 'Image set as featured successfully'
      });
      obj.isLoading = false
      nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
      nextProps.toggleLoading(false);
    }

    if (get(nextProps, 'removeImagePhase') === 'success') {
      nextProps.clearTownPhase();
      obj.isDeleteModalOpen = false;
      obj.isLoading = false;
      notification.success({
        message: 'Image deleted successfully'
      });
      nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
    }

    return obj;
  }

  static propTypes = {
    // PropTypes go here
  };

  setFeatured = img => {
    const self = this;
    const data = {
      town_id: get(self, 'props.match.params.townId'),
      featured_image_url: get(img, 'url'),
      image_id: get(img, 'attach_id')
    };
    this.setState({ isConfirmModal: true, featuredImageData: data });
    // confirm({
    //   title: 'Do you want to mark this image as featured?',
    //   onOk() {
    //     self.props.toggleLoading(true);

    //     self.props.markFeatured(data);
    //   },
    //   onCancel() {
    //   }
    // });
  };

  setFeaturedImageConfirm = () => {
    this.setState({ isLoading: true, isConfirmModal: false })
    const data = this.state.featuredImageData;

    this.props.toggleLoading(true);

    this.props.markFeatured(data);
  };

  imageLoaded = id => {
    const foundIndex = this.state.images.findIndex(x => x.id === id);
    const imagesCopy = this.state.images.map(item => ({...item}))
    const originalImage = imagesCopy[foundIndex]
    const updatedImage = {
      ...originalImage,
      loaded: 'inline',
    }
    imagesCopy[foundIndex] = updatedImage;
    this.setState({ images: [...imagesCopy] });
  }

  imageRotate = val => {
    const { angle } = this.state;
    const foundIndex = this.state.images.findIndex(x => x.id === val.id);
    const imagesCopy = this.state.images.map(item => ({...item}))
    const originalImage = imagesCopy[foundIndex]
    const updatedImage = {
      ...originalImage,
      rotateStyle: `rotate(${(angle + 90) % 360}deg)`,
    }
    imagesCopy[foundIndex] = updatedImage;

    this.setState({ images: [...imagesCopy] });
    this.setState({ rotateImageId: val.id });

    const angleDeg = (angle + 90) % 360;
    let rotate = 0;
    if (angleDeg == 0) {
      rotate = 1;
    } else if (angleDeg == 90) {
      rotate = 2;
    } else if (angleDeg == 180) {
      rotate = 3;
    } else if (angleDeg == 270) {
      rotate = 4;
    }
    this.setState({ angle: angleDeg, rotate });

  };

  base64(url, callback) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onload = function () {
      const fileReader = new FileReader();
      fileReader.onloadend = function () {
        callback(fileReader.result);
      };
      fileReader.readAsDataURL(httpRequest.response);
    };
    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob';
    httpRequest.send();
  }

  // resetOrientation(srcBase64, srcOrientation, callback) {
  //   const img = new Image();
  //   img.src = srcBase64;
  //
  //   img.onload = function() {
  //     console.log("ok");
  //   }
  //   img.onerror = function(e) {
  //     console.log("Not ok",e);
  //   }
  //
  //   // if(img.complete){
  //   //   const width = img.width;
  //   //   const height = img.height;
  //   //   const canvas = document.createElement('canvas');
  //   //   const ctx = canvas.getContext('2d');
  //   //   canvas.width = width;
  //   //   canvas.height = height;
  //   //   switch (srcOrientation) {
  //   //     case 3:
  //   //       ctx.transform(-1, 0, 0, -1, width, height);
  //   //       break;
  //   //     case 2:
  //   //       ctx.transform(0, 1, -1, 0, height, 0);
  //   //       break;
  //   //     case 4:
  //   //       ctx.transform(0, -1, 1, 0, 0, width);
  //   //       break;
  //   //     default:
  //   //       break;
  //   //   }
  //   //   // draw image
  //   //   ctx.drawImage(img, 0, 0);
  //   //   // export base64
  //   //   callback(canvas.toDataURL());
  //   // }
  //
  //   img.onload = function () {
  //     console.log('2')
  //     const width = img.width;
  //     const height = img.height;
  //     const canvas = document.createElement('canvas');
  //     const ctx = canvas.getContext('2d');
  //     canvas.width = width;
  //     canvas.height = height;
  //     switch (srcOrientation) {
  //       case 3:
  //         ctx.transform(-1, 0, 0, -1, width, height);
  //         break;
  //       case 2:
  //         ctx.transform(0, 1, -1, 0, height, 0);
  //         break;
  //       case 4:
  //         ctx.transform(0, -1, 1, 0, 0, width);
  //         break;
  //       default:
  //         break;
  //     }
  //     // draw image
  //     ctx.drawImage(img, 0, 0);
  //     // export base64
  //     console.log('4')
  //     callback(canvas.toDataURL());
  //   };
  //   console.log('3')
  //
  // }

  dragDrop = acceptFile => {
    this.props.toggleLoading(true);
    const {
      uploadImage,
      match: {
        params: { townId }
      }
    } = this.props;

    for (let i = 0; i < acceptFile.length; i += 1) {
      const reader = new FileReader();
      reader.onload = r => {
        if (acceptFile[i]) {
          this.setState({
            previewImage: acceptFile[i].preview,
            previewStatus: 'ViewPatientOnboarding_previewShowing'
          });
          const formdata = new FormData();
          formdata.append('image', acceptFile[i]);
          formdata.append('townId', townId);
          uploadImage(formdata);
        }
      };
      reader.readAsDataURL(acceptFile[i]);
    }
  };

  removeImage = img => {
    const {
      getGeneratedReport,
      removeImage,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    const data = {
      town_id: townId,
      url: img.url,
      image_id: img.id
    };

    this.setState({ isDeleteModalOpen: true, selectedImageData: data });
  };

  removeImageConfirm = img => {
    const {
      getGeneratedReport,
      removeImage,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    const data = this.state.selectedImageData;
    this.setState({ isLoading: true });
    toggleLoading(true);

    removeImage(data);
  };

  sendData = async (data, token) => {
    const {
      uploadImage,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    toggleLoading(true);

    for (let i = 0; i < data.length; i += 1) {
      if (data[i]) {
        const formdata = new FormData();
        formdata.append('image', data[i].url);
        formdata.append('ext', 'png');
        formdata.append('mime', data[i].mimeType);
        formdata.append('townId', townId);
        formdata.append('drive', true);
        formdata.append('url', data[i].url);
        formdata.append('authToken', token);
        formdata.append('fileId', data[i].id);
        uploadImage(formdata);
      }
    }
  };

  orderList = async ({ oldIndex, newIndex }) => {
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const images = this.state.images;

    this.setState(({ images }) => ({
      images: arrayMove(images, oldIndex, newIndex)
    }));
    // this.setState({ images: arrayMove(images, oldIndex, newIndex) });

    // const updateImagesMain = this.state.images.map((img, idx) => {
    //   this.props.updateImage({ townId, idx, id: img.id });
    // });

    // await Promise.all(updateImagesMain);
    await this.props.updateImage({ townId, images: this.state.images });
  };

  checkedClick = val => {
    const {
      uploadImage,
      match: {
        params: { townId }
      }
    } = this.props;

    if (this.state.rotateImageId === val.id) {
      const formdata = new FormData();
      formdata.append('image', this.state.base64);
      formdata.append('townId', townId);
      formdata.append('ext', 'jpeg');
      formdata.append('id', val.id);
      formdata.append('rotateImage', 'rotateImage');
      formdata.append('angle', this.state.angle);
      uploadImage(formdata);
    }
  };

  render() {
    const {
      report,
      images,
      featuredImage,
      isConfirmModal,
      isLoading,
      isDeleteModalOpen
    } = this.state;
    return (
      <div>
        {/* Delete image confirm */}
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.removeImageConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        {/* Set as featured image confirm */}
        <ConfirmModal
          isNotDelete={true}
          closeModal={() => this.setState({ isConfirmModal: false })}
          onConfirm={this.setFeaturedImageConfirm}
          isLoading={isLoading}
          isOpenModal={isConfirmModal}
        />

        {/*<div className="text-right mb__15">*/}
        {/*  <GoogleDrive key="googleDrive" sendData={this.sendData} />*/}
        {/*</div>*/}
        <div className="dropzone">
          <Dropzone multiple onDrop={e => this.dragDrop(e)}>
            {({ getRootProps, getInputProps }) => (
              <section className="upload-container">
                <div className="dropzone-inner-area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-text">
                    Upload <br /> or
                  </div>
                  <button
                    type="button"
                    className="btn btn__btn btn-dark btn-upload"
                  >
                    Drag & Drop
                  </button>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="row">
          <SortableComponent
            data={images}
            onSortEnd={this.orderList}
            image={featuredImage}
            onRemove={value => this.removeImage(value)}
            onFeatured={value => this.setFeatured(value)}
            imageRotate={img => this.imageRotate(img)}
            imageLoaded={img => this.imageLoaded(img)}
            checkedClick={index => this.checkedClick(index)}
            disabled={this.props.disabledSort}
          // shouldCancelStart={this.shouldCancelStart}
          />

          {/* {!isEmpty(get(report, 'images')) &&
            get(report, 'images').map((img, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <div className="uploaded__images__card">
                    <div className="uploaded__images__thumb__wrapp">
                      <img className="uploaded__images__thumb"
                        id={get(img, 'id')}
                        src={get(img, 'url')}
                        alt={get(img, 'id')}
                      />
                    </div>
                    <div className="uploaded__images__card__info">
                      <button className="btn btn__btn btn-rotate btn-dark" onClick={() => this.imageRotate(img)}>Rotate</button>
                      <button className="btn btn__btn btn-link-danger" onClick={() => this.removeImage(img)}>Remove</button>
                      {get(img, 'featured') ? ('Featured') : (
                        <button className="btn btn__btn btn-set-featured" onClick={() => this.setFeatured(img)}>
                          Set Featured
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )
            })} */}
        </div>
      </div>
    );
  }
}
