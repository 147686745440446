import { connect } from 'react-redux'

import { sendBackgroundData, clearPhase } from "../../../../../store/dashboard/duck"
import { fetchClientById, clearClientPhase } from "../../../../../store/strategistV2/clients/action"

import BackgroundProfileComponent from './component'

const BackgroundProfileContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    sendBackgroundPhase: state.dashboard.sendBackgroundPhase,
    user: state.user.users
  }),
  // Map actions to dispatch and props
  {
    clearPhase,
    clearClientPhase,
    fetchClientById,
    sendBackgroundData
  }
)(BackgroundProfileComponent)

export default BackgroundProfileContainer
