import { connect } from 'react-redux'
import NotTouchedI4WeekForm from './component'
import { getFilterParams} from '../../../store/dashboard/duck'

import { getAgentList, getAgentStatistics, getNotTouchedIn4WeekData } from '../../../store/analytics/duck'

const NotTouchedI4Week = connect(
  // Map state to props
  (state) => ({
  	agentPhase: state.analytics.agentPhase,
  	agentListData: state.analytics.agentListData,
  	filterParams: state.dashboard.filterParams,
    agentStatisticsPhase: state.analytics.agentStatisticsPhase,
    agentStatisticsData: state.analytics.agentStatisticsData,
    notTouchedIn4WeekPhase: state.analytics.notTouchedIn4WeekPhase,
    notTouchedIn4WeekData: state.analytics.notTouchedIn4WeekData
  }),
  // Map actions to props
  {
  	getAgentList,
    getFilterParams,
    getAgentStatistics,
    getNotTouchedIn4WeekData
  }
)(NotTouchedI4WeekForm)
export default NotTouchedI4Week
