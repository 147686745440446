import { connect } from 'react-redux'
import {fetchUser, refreshToken} from '../../store/user/duck'
import AppComponent from './component'
const AppContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    token: state.user.token,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase,
    fetchPhase: state.user.fetchPhase
  }),
  // Map actions to dispatch and props
 {
    fetchUser,
    refreshToken
  }
)(AppComponent)
export default AppContainer
