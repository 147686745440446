import { connect } from 'react-redux'
import QuestionnairesCompletedForm from './component'
import { getFilterParams } from '../../../store/dashboard/duck'
import { getQuestionnairesCompletedClientList } from '../../../store/analytics/duck'

const QuestionnairesCompletedReporting = connect(
  // Map state to props
  (state) => ({
  	QuestionnairesReportPhase: state.analytics.QuestionnairesReportPhase,
  	QuestionnairesData: state.analytics.QuestionnairesData,
  	filterParams: state.dashboard.filterParams,
  }),
  // Map actions to props
  {
  	getQuestionnairesCompletedClientList,
  	getFilterParams
  }
)(QuestionnairesCompletedForm)
export default QuestionnairesCompletedReporting
