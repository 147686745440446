import { connect } from 'react-redux'

import { agentSearchGetUsers, clearAgentPhase } from '../../store/agent/duck'
import { getAllUsers } from '../../store/dashboard/duck'
import {handleSignOut, fetchUser} from '../../store/user/duck'

import TopNavigationComponent from './component'
const TopNavigationContainer = connect(
  // Map state to props
  (state) => ({
  	agentSearchGetUsersData: state.agent.agentSearchGetUsersData,
  	allUserData: state.dashboard.allUserData
  }),
  // Map actions to dispatch and props
  { 
  	agentSearchGetUsers, 
    clearAgentPhase,
    handleSignOut,
    fetchUser,
    getAllUsers
  }
)(TopNavigationComponent)

export default TopNavigationContainer
