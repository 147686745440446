import React, { Fragment /*, PropTypes*/ } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import filter from 'lodash/filter';
import Cookies from 'universal-cookie';
import Config from '../../../../../../config';
import { Popover, Spin, Modal } from 'antd';
import PaginationMain from '../../../Common/Pagination';
import TopNavigation from '../../../../../../components/TopNavigation/container';
import SidebarNavigation from '../../../../../../components/SidebarNavigation/component'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component';
import {
  SortUpIcon,
  FilterIcon,
  CloseIcon,
  SortDownIcon, ExportIcon
} from '../../../../../../components/icons';

import ReactExport from "react-data-export"

import Loader from '../../../../../../components/Loader';
import * as Yup from 'yup';

import _ from "lodash";
const cookies = new Cookies();

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn


export default class BrokerageReportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCount: 0,
      visible: false,
      brokerageUsers: [],
      brokerageFilters: [],
      isLoading: true,
      usersCount: 0,
      user: {},
      showFirmDropdown: false,
      showStrategistDropdown: false,
      showMarketDropdown: false,
      showStatusDropdown: false,
      showDropdown: false,
      firmData: [],
      firmDataMain: [],
      strategistData: [],
      strategistDataMain: [],
      marketData: [],
      marketDataMain: [],
      statusData: [],
      statusDataMain: [],
      fetchReportObj: {
        limit: 20,
        offset: 0,
        currentActivePage: 1,
        filters: {
          firmNames: [],
          strategists: [],
          markets: [],
          statuses: [],
        },
        sortValue: '',
        sortKey: 0,
        searchTerm: ''
      },
    };
  }

  setCheckBoxNumber(filters) {
    let filterCount = 0
    let showFirm = false
    let showStrategist = false
    let showMarkets = false
    let showStatuses = false

    if (filters.firmNames.length > 0) {
      showFirm = true
      filterCount++
    }

    if (filters.strategists.length > 0) {
      showStrategist = true
      filterCount++
    }

    if (filters.markets.length > 0) {
      showMarkets = true
      filterCount++
    }

    if (filters.statuses.length > 0) {
      showStatuses = true
      filterCount++
    }

    this.setState({
      showFirmDropdown: showFirm,
      showStrategistDropdown: showStrategist,
      showMarketDropdown: showMarkets,
      showStatusDropdown: showStatuses,
      filterCount
    });
  }

  componentDidMount() {
    document.title = 'Brokerage Report | Jungler';
    const _user = cookies.get('user');
    const brokerageFilters = localStorage.getItem('brokerageReportFilter');
    const savedFilters = JSON.parse(brokerageFilters)

    if (savedFilters) {
      this.setState({ fetchReportObj: savedFilters })
      this.setCheckBoxNumber(savedFilters.filters)
    }

    this.setState({
      user: _user,
    });

    if (savedFilters) {
      this.props.fetchBrokerageReport(savedFilters);
    } else {
      this.props.fetchBrokerageReport(this.state.fetchReportObj);
    }

    this.props.fetchBrokerageReportFilters();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const obj = {};

    if (get(nextProps, 'brokerageReportFilterPhase', '') === 'success') {
      obj.brokerageFilters = get(nextProps, 'brokerageFilters', []);
      obj.firmData = get(nextProps, 'brokerageFilters.firmNames', []);
      obj.firmDataMain = get(nextProps, 'brokerageFilters.firmNames', []);
      obj.strategistData = get(nextProps, 'brokerageFilters.strategists', []);
      obj.strategistDataMain = get(nextProps, 'brokerageFilters.strategists', []);
      obj.marketData = get(nextProps, 'brokerageFilters.markets', []);
      obj.marketDataMain = get(nextProps, 'brokerageFilters.markets', []);
      obj.statusData = get(nextProps, 'brokerageFilters.statuses', []);
      obj.statusDataMain = get(nextProps, 'brokerageFilters.statuses', []);
      nextProps.clearBrokeragePhase()
    }

    if (get(nextProps, 'brokerageReportPhase', '') === 'success') {
      obj.brokerageUsers = get(nextProps, 'brokerageRows', []);
      obj.usersCount = get(nextProps, 'usersCount', 0);
      nextProps.clearBrokeragePhase()
      obj.isLoading = false;
    }


    return obj;
  }

  filterVisible = visible => {
    this.setState({ visible });
  };

  onPageChange = page => {
    const fetchReportObj = this.state.fetchReportObj;
    fetchReportObj.offset = page - 1;
    fetchReportObj.currentActivePage = page;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.setState({isLoading: true, fetchReportObj });

    this.props.fetchBrokerageReport(fetchReportObj);
  };

  debounceSearch = (fetchReportObj) => {
    this.props.fetchBrokerageReport(fetchReportObj);
  }

  search = _.debounce(this.debounceSearch, 500);

  onSearchChange = (e) => {
    const text = e.target.value
    const fetchReportObj = this.state.fetchReportObj;
    fetchReportObj.search = text;
    fetchReportObj.offset = 0
    fetchReportObj.keyValue = ''
    this.setState(fetchReportObj)
    if (!text || text.length >= 3) {
      this.setState({ isLoading: true, activePage: 1, fetchReportObj });
      localStorage.setItem(
        'brokerageReportFilter',
        JSON.stringify(fetchReportObj)
      );
      this.search(fetchReportObj)
    }
  }

  changeResultCount = count => {
    const fetchReportObj = this.state.fetchReportObj;
    fetchReportObj.limit = count;
    fetchReportObj.offset = 0;

    this.props.fetchBrokerageReport(fetchReportObj);
    fetchReportObj.limit = count
    this.setState({ isLoading: true, fetchReportObj });
    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
  };

  sortTable = sortKey => {

    const fetchReportObj = this.state.fetchReportObj;

    if (this.state.fetchReportObj.sortKey === sortKey) {
      if (fetchReportObj.sortValue === 'desc') {
        fetchReportObj.sortValue = ''
        fetchReportObj.sortKey = ''
      }
      if (fetchReportObj.sortValue === 'asc') {
        fetchReportObj.sortValue = 'desc'
      }
      if (fetchReportObj.sortValue === '') {
        fetchReportObj.sortValue = 'asc'
      }

    } else {
      fetchReportObj.sortKey = sortKey;
      fetchReportObj.sortValue = fetchReportObj.sortValue === 'desc' ? 'asc' : 'asc';
    }

    this.setState({ isLoading: true, fetchReportObj, activePage: 1 });
    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
    this.props.fetchBrokerageReport(fetchReportObj);
  };

  sortByMarket = async market => {

    const fetchReportObj = this.state.fetchReportObj;

    if (fetchReportObj.filters.markets.some(({id}) => id === market.id)) {
      fetchReportObj.filters.markets = fetchReportObj.filters.markets.filter(marketFilter => marketFilter.id !== market.id)
      this.setState({
        marketData: this.state.marketDataMain,
      });
    } else {
      fetchReportObj.filters.markets.push({id: market.id, name: market.name})
    }

    this.setState({
      isLoading: true,
      fetchReportObj,
      activePage: 1,
    });

    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
    this.setCheckBoxNumber(fetchReportObj.filters)

    this.props.fetchBrokerageReport(fetchReportObj);
    this.forceUpdate();

  }

  sortByStrategist = async strategist => {

    const fetchReportObj = this.state.fetchReportObj;

    if (fetchReportObj.filters.strategists.some(({id}) => id === strategist.id)) {
      fetchReportObj.filters.strategists = fetchReportObj.filters.strategists.filter(strategistFilter => strategistFilter.id !== strategist.id)
      this.setState({
        strategistData: this.state.strategistDataMain,
      });
    } else {
      fetchReportObj.filters.strategists.push({id: strategist.id, firstName: strategist.first_name, lastName: strategist.last_name})
    }

    this.setState({
      fetchReportObj,
      isLoading: true,
      activePage: 1,
    });

    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
    this.setCheckBoxNumber(fetchReportObj.filters)

    this.props.fetchBrokerageReport(fetchReportObj);
    this.forceUpdate();

  }

  sortByStatus = async statusParam => {

    const fetchReportObj = this.state.fetchReportObj;

    if (fetchReportObj.filters.statuses.some(({status}) => status === statusParam.status)) {
      fetchReportObj.filters.statuses = fetchReportObj.filters.statuses.filter(statusFilter => statusFilter.status !== statusParam.status)
      this.setState({
        statusData: this.state.statusDataMain,
      });
    } else {
      fetchReportObj.filters.statuses.push({status: statusParam.status})
    }

    this.setState({
      fetchReportObj,
      isLoading: true,
      activePage: 1,
    });

    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
    this.setCheckBoxNumber(fetchReportObj.filters)

    this.props.fetchBrokerageReport(fetchReportObj);
    this.forceUpdate();
  }

  sortByFirm = async firmName => {
    const fetchReportObj = this.state.fetchReportObj;

    if (fetchReportObj.filters.firmNames.some(({firm_name}) => firm_name === firmName.firm_name)) {
      fetchReportObj.filters.firmNames = fetchReportObj.filters.firmNames.filter(name => name !== firmName)
      this.setState({
        firmData: this.state.firmDataMain,
      });
    } else {
      fetchReportObj.filters.firmNames.push(firmName)
    }

    this.setState({
      fetchReportObj,
      isLoading: true,
      activePage: 1,
    });

    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );
    this.setCheckBoxNumber(fetchReportObj.filters)

    this.props.fetchBrokerageReport(fetchReportObj);
    this.forceUpdate();
  }

  handleFilterMarkets = async e => {
    const value = get(e, 'target.value');
    const { marketDataMain, marketData } = this.state;
    let filtered = marketDataMain
    if (value) {
      filtered = await filter(
        marketDataMain,
        search =>
          search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ marketData: filtered });

    this.forceUpdate();
  }

  handleFilterStrategists = async e => {
    const value = get(e, 'target.value');
    const { strategistDataMain, strategistData } = this.state;
    let filtered = strategistDataMain
    if (value) {
      filtered = await filter(
        strategistDataMain,
        search =>
          search.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || search.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ strategistData: filtered });

    this.forceUpdate();
  }

  handleFilterStatuses = async e => {
    const value = get(e, 'target.value');
    const { statusDataMain, statusData } = this.state;
    let filtered = statusDataMain

    const filteredMain = statusDataMain.filter(data => {

      if (!data.status) {
        return false; // skip
      }

      if (data.status.length < 1) {
        return false; // skip
      }

      return true;
    });

    if (value) {
      filtered = await filter(
        filteredMain,
        search =>
          search.status.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ statusData: filtered });

    this.forceUpdate();
  }

  handleFilterFirms = async e => {
    const value = get(e, 'target.value');
    const { firmDataMain, firmData } = this.state;
    let filtered = firmDataMain
    const filteredMain = firmDataMain.filter(data => {

      if (!data.firm_name) {
        return false; // skip
      }

      if (data.firm_name.length < 1) {
        return false; // skip
      }

      return true;
    });

    if (value) {
      filtered = await filter(
        filteredMain,
        search =>
          search.firm_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ firmData: filtered });

    this.forceUpdate();
  };

  clearFilter = (filter) => {
    this.setState({ isLoading: true });
    this.forceUpdate();
    const fetchReportObj = this.state.fetchReportObj;

    if (filter === 'firmNames') {
      fetchReportObj.filters.firmNames = [];
    }
    if (filter === 'strategists') {
      fetchReportObj.filters.strategists = [];
    }
    if (filter === 'markets') {
      fetchReportObj.filters.markets = [];
    }
    if (filter === 'statuses') {
      fetchReportObj.filters.statuses = [];
    }
    if (filter === 'all') {
      fetchReportObj.filters.firmNames = [];
      fetchReportObj.filters.strategists = [];
      fetchReportObj.filters.markets = [];
      fetchReportObj.filters.statuses = [];
    }

    localStorage.setItem(
      'brokerageReportFilter',
      JSON.stringify(fetchReportObj)
    );

    this.setState({
      showFirmDropdown: false,
      showStrategistDropdown: false,
      showMarketDropdown: false,
      showStatusDropdown: false,
      isLoading: true,
      fetchReportObj,
      activePage: 1,
    });

    this.setCheckBoxNumber(fetchReportObj.filters)
    this.props.fetchBrokerageReport(fetchReportObj);
    this.forceUpdate();
  };


  render() {
    const {
      isLoading,
      brokerageUsers,
      usersCount,
      fetchReportObj,
      firmData,
      strategistData,
      marketData,
      statusData,
      showFirmDropdown,
      showStrategistDropdown,
      showMarketDropdown,
      showStatusDropdown,
      user
    } = this.state;

    const BrokerageUsersRowContent =
      !isEmpty(brokerageUsers) &&
      brokerageUsers.map((brokerageUser, i) => {
        return (
          <Fragment key={i}>
            <tr>

              {
                user.role == "business development" || user.role == "local"?
                  <td>
                    <button
                      className="btn btn__btn btn__link"
                    >
                      {/*{get(town, 'name')}*/}
                      {get(brokerageUser, 'agentName')}
                    </button>
                  </td>:
                  <td>
                    <button
                      className="btn btn__btn btn__link btn-disable-hover-pointer-cursor"
                      // onClick={() => this.openEditNameModalModal(town)}
                    >
                      {/*{get(town, 'name')}*/}
                      {get(brokerageUser, 'agentName')}
                    </button>
                  </td>
              }
              {/*<td>{get(town, 'suburbName')}</td>*/}
              <td>{get(brokerageUser, 'agentFirmName')}</td>
              {
                user.role == "business development" || user.role == "local"?
                  null:
                  <td
                  >
                    {get(brokerageUser, 'clientName', '')}
                  </td>
              }

              <td>{get(brokerageUser, 'strategistName')}</td>
              <td>{get(brokerageUser, 'clientStatus')}</td>
              <td>{get(brokerageUser, 'townName')}</td>
              <td>{get(brokerageUser, 'metroAreaName')}</td>
              <td>{get(brokerageUser, 'agentAssignedDate')}</td>
              <td>{get(brokerageUser, 'clientPriceRange')}</td>

              <td>
              </td>
            </tr>
          </Fragment>
        );
      });
    return (
      <div className="layout-has-sidebar">
        {
          user.role == "business development" || user.role == "local"?
            <SidebarNavigation {...this.props}/>:
            <AdministrationSidebarNavigation {...this.props} />
        }
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <h2 className="heading-title">
                  Brokerage Report
                </h2>

                <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                  <ExcelSheet data={brokerageUsers} name="brokerage_report">
                    <ExcelColumn label="Agent Name" value="agentName" />
                    <ExcelColumn label="Firm Name" value="agentFirmName" />
                    <ExcelColumn label="Client Name" value="clientName" />
                    <ExcelColumn label="Strategist Name" value="strategistName" />
                    <ExcelColumn label="Client Status" value="clientStatus" />
                    <ExcelColumn label="Town Name" value="townName" />
                    <ExcelColumn label="Market Name" value="metroAreaName" />
                    <ExcelColumn label="Agent Assigned At" value="agentAssignedDate" />
                    <ExcelColumn label="Price Range" value="clientPriceRange" />
                  </ExcelSheet>
                </ExcelFile>
              </div>

              <div className="col-sm-12 col-md-9">
                <div className="filter__result__section">
                  <Popover
                    content={
                      <div className="filter">
                        <div className="filter__header">
                          <button
                            onClick={() => this.clearFilter('all')}
                            className="btn btn__btn btn-secondry btn__xs"
                          >
                            Clear
                          </button>
                          <div className="filter__title">Filters</div>
                          <button
                            onClick={() => this.setState({ visible: false })}
                            className="btn btn__btn btn-dark btn__xs"
                          >
                            Close
                          </button>
                        </div>
                        <div className="filter__body">
                          <ul className="filter__list">
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                Firm Name
                                <input
                                  type="checkbox"
                                  name="status"
                                  checked={showFirmDropdown}
                                  onChange={e => {
                                    this.setState({
                                      showFirmDropdown: get(e, 'target.checked')
                                    });

                                    if (!e.target.checked) {
                                      this.clearFilter('firmNames');
                                    }
                                  }}
                                  className="custom-checkbox--input"
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                            {showFirmDropdown && (
                              <ul
                                className="filter__list"
                                style={{ marginLeft: '43px' }}
                              >
                                <div className="search__form mt__10 mb__10">
                                  <input
                                    type="text"
                                    className="form-control search__form__input"
                                    onChange={this.handleFilterFirms}
                                    name="searchStatus"
                                    placeholder="Search firm names..."
                                  />
                                </div>
                                {!isEmpty(firmData) &&
                                  firmData
                                    .filter(data => {

                                      if (!data.firm_name) {
                                        return false; // skip
                                      }

                                      if (data.firm_name.length < 1) {
                                        return false; // skip
                                      }

                                      return true;
                                    })
                                    .map((data, i) => {
                                      const isSelected = filter(fetchReportObj.filters.firmNames, {firm_name: data.firm_name});

                                      return (
                                        <li key={i}>
                                          <label className="custom-checkbox custom-checkbox-line">
                                            {data.firm_name}
                                            <input
                                              type="checkbox"
                                              name="status"
                                              checked={
                                                isEmpty(isSelected) ? false : true
                                              }
                                              onChange={e =>
                                                this.sortByFirm(data)
                                              }
                                              value={data.firm_name}
                                              className="custom-checkbox--input"
                                            />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                              </ul>
                            )}
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                Strategist
                                <input
                                  type="checkbox"
                                  name="status"
                                  checked={showStrategistDropdown}
                                  onChange={e => {
                                    // this.filterClick('strategist')
                                    this.setState({
                                      showStrategistDropdown: get(e, 'target.checked')
                                    });

                                    if (!e.target.checked) {
                                      this.clearFilter('strategists');
                                    }
                                  }}
                                  className="custom-checkbox--input"
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                            {showStrategistDropdown && (
                              <ul
                                className="filter__list"
                                style={{ marginLeft: '43px' }}
                              >
                                <div className="search__form mt__10 mb__10">
                                  <input
                                    type="text"
                                    className="form-control search__form__input"
                                    onChange={this.handleFilterStrategists}
                                    name="searchStatus"
                                    placeholder="Search strategists..."
                                    // value={searchStatus}
                                  />
                                </div>
                                {!isEmpty(strategistData) &&
                                  strategistData
                                    .map((data, i) => {
                                      const isSelected = filter(fetchReportObj.filters.strategists, {id: data.id});

                                      return (
                                        <li key={i}>
                                          <label className="custom-checkbox custom-checkbox-line">
                                            {data.first_name} {data.last_name}
                                            <input
                                              type="checkbox"
                                              name="status"
                                              checked={
                                                isEmpty(isSelected) ? false : true
                                              }
                                              onChange={e =>
                                                this.sortByStrategist(data)
                                              }
                                              value={data.id}
                                              className="custom-checkbox--input"
                                            />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                              </ul>
                            )}
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                Market
                                <input
                                  type="checkbox"
                                  name="status"
                                  checked={showMarketDropdown}
                                  onChange={e => {
                                    // this.filterClick('market')
                                    this.setState({
                                      showMarketDropdown: get(e, 'target.checked')
                                    });

                                    if (!e.target.checked) {
                                      this.clearFilter('markets');
                                    }
                                  }}
                                  className="custom-checkbox--input"
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                            {showMarketDropdown && (
                              <ul
                                className="filter__list"
                                style={{ marginLeft: '43px' }}
                              >
                                <div className="search__form mt__10 mb__10">
                                  <input
                                    type="text"
                                    className="form-control search__form__input"
                                    onChange={this.handleFilterMarkets}
                                    name="searchStatus"
                                    placeholder="Search markets..."
                                    // value={searchStatus}
                                  />
                                </div>
                                {!isEmpty(marketData) &&
                                  marketData
                                    .map((data, i) => {
                                      const isSelected = filter(fetchReportObj.filters.markets, {id: data.id});

                                      return (
                                        <li key={i}>
                                          <label className="custom-checkbox custom-checkbox-line">
                                            {data.name}
                                            <input
                                              type="checkbox"
                                              name="status"
                                              checked={
                                                isEmpty(isSelected) ? false : true
                                              }
                                              onChange={e =>
                                                this.sortByMarket(data)
                                              }
                                              value={data.id}
                                              className="custom-checkbox--input"
                                            />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                              </ul>
                            )}
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                Status
                                <input
                                  type="checkbox"
                                  name="status"
                                  checked={showStatusDropdown}
                                  onChange={e => {
                                    this.setState({
                                      showStatusDropdown: get(e, 'target.checked')
                                    });

                                    if (!e.target.checked) {
                                      this.clearFilter('statuses');
                                    }
                                  }}
                                  className="custom-checkbox--input"
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                            {showStatusDropdown && (
                              <ul
                                className="filter__list"
                                style={{ marginLeft: '43px' }}
                              >
                                <div className="search__form mt__10 mb__10">
                                  <input
                                    type="text"
                                    className="form-control search__form__input"
                                    onChange={this.handleFilterStatuses}
                                    name="searchStatus"
                                    placeholder="Search statuses..."
                                  />
                                </div>
                                {!isEmpty(statusData) &&
                                  statusData
                                    .filter(data => {

                                      if (!data.status) {
                                        return false; // skip
                                      }

                                      if (data.status.length < 1) {
                                        return false; // skip
                                      }

                                      return true;
                                    })
                                    .map((data, i) => {
                                      const isSelected = filter(fetchReportObj.filters.statuses, {status: data.status});

                                      return (
                                        <li key={i}>
                                          <label className="custom-checkbox custom-checkbox-line">
                                            {data.status}
                                            <input
                                              type="checkbox"
                                              name="status"
                                              checked={
                                                isEmpty(isSelected) ? false : true
                                              }
                                              onChange={e =>
                                                this.sortByStatus(data)
                                              }
                                              value={data.id}
                                              className="custom-checkbox--input"
                                            />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                              </ul>
                            )}
                          </ul>
                        </div>
                      </div>
                    }
                    placement="bottom"
                    className="filter"
                    overlayClassName="filter__overlay"
                    trigger="click"
                    visible={this.state.visible}
                    onVisibleChange={this.filterVisible}
                  >
                    <button className="btn btn__btn btn-dark btn-filter">
                      <FilterIcon className="filter-icon" /> Filters{this.state.filterCount > 0 && `(${this.state.filterCount})`}
                    </button>
                  </Popover>
                  {/* End filter section */}

                  <div className="sort__results">
                    <div className="material-textfield results__page">
                      <select
                        onChange={e =>
                          this.changeResultCount(get(e, 'target.value'))
                        }
                        value={get(this, 'state.fetchReportObj.limit', 20)}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={get(page, 'value')}>
                                {get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="townFilterText"
                      onChange={(e) => this.onSearchChange(e)}
                      // onChange={e =>
                      //   // this.search(get(e, 'target.value', ''))
                      //   // _.debounce(this.search, 300)(get(e, 'target.value', ''))
                      // }
                      value={fetchReportObj.search}
                      placeholder="Search by agent name or firm..."
                      required
                    />
                  </div>
                  <ul className="filter__list__flitered">
                    {!isEmpty(fetchReportObj.filters.firmNames) &&
                      fetchReportObj.filters.firmNames.map((search, i) => {
                        return (
                          <li key={i}>
                            {search.firm_name}
                            <span onClick={() => this.sortByFirm(search)}>
                              <CloseIcon className="close-icon" />
                            </span>
                          </li>
                        );
                      })}
                    {!isEmpty(fetchReportObj.filters.strategists) &&
                      fetchReportObj.filters.strategists.map((search, i) => {

                        return (
                          <li key={i}>
                            {search.firstName} {search.lastName}
                            <span onClick={() => this.sortByStrategist(search)}>
                              <CloseIcon className="close-icon" />
                            </span>
                          </li>
                        );
                      })}
                    {!isEmpty(fetchReportObj.filters.markets) &&
                      fetchReportObj.filters.markets.map((search, i) => {
                        return (
                          <li key={i}>
                            {search.name}
                            <span onClick={() => this.sortByMarket(search)}>
                              <CloseIcon className="close-icon" />
                            </span>
                          </li>
                        );
                      })}
                    {!isEmpty(fetchReportObj.filters.statuses) &&
                      fetchReportObj.filters.statuses.map((search, i) => {

                        return (
                          <li key={i}>
                            {search.status}
                            <span onClick={() => this.sortByStatus(search)}>
                              <CloseIcon className="close-icon" />
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                  <Spin
                    size="large"
                    spinning={isLoading}
                    indicator={<Loader />}
                  >
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                        <tr>

                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('agent_name')}
                          >
                              <span className="table-column-title">
                                Agent Name
                              </span>
                            <span className="table-column-sorter">
                                {fetchReportObj.sortKey ===
                                'agent_name' ?
                                  get(fetchReportObj, 'sortValue') === 'desc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  ) : ''}
                              </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('firm_name')}
                          >
                            <span className="table-column-title">Firm Name</span>
                            <span className="table-column-sorter">
                              {fetchReportObj.sortKey ===
                              'firm_name' ?
                                get(fetchReportObj, 'sortValue') === 'desc' ? (
                                  <SortUpIcon />
                                ) : (
                                  <SortDownIcon />
                                ) : ''}
                              </span>
                          </th>

                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('client_name')}
                          >
                                <span className="table-column-title">
                                  Client Name
                                </span>
                            <span className="table-column-sorter">
                                  {fetchReportObj.sortKey ===
                                  'client_name' ?
                                    get(fetchReportObj, 'sortValue') === 'desc' ? (
                                      <SortUpIcon />
                                    ) : (
                                      <SortDownIcon />
                                    ) : ''}
                                </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('strategist_name')}
                          >
                              <span className="table-column-title">
                                Strategist Name
                              </span>
                            <span className="table-column-sorter">
                                {fetchReportObj.sortKey ===
                                'strategist_name' ?
                                  get(fetchReportObj, 'sortValue') === 'desc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  ) : ''}
                              </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('status')}
                          >
                              <span className="table-column-title">
                                Client Status
                              </span>
                            <span className="table-column-sorter">
                                 {fetchReportObj.sortKey ===
                                 'status' ?
                                   get(fetchReportObj, 'sortValue') === 'desc' ? (
                                     <SortUpIcon />
                                   ) : (
                                     <SortDownIcon />
                                   ) : ''}
                              </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('town_name')}
                          >
                              <span className="table-column-title">
                                Town
                              </span>
                            <span className="table-column-sorter">
                                 {fetchReportObj.sortKey ===
                                 'town_name' ?
                                   get(fetchReportObj, 'sortValue') === 'desc' ? (
                                     <SortUpIcon />
                                   ) : (
                                     <SortDownIcon />
                                   ) : ''}
                              </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('metro_area_name')}
                          >
                              <span className="table-column-title">
                                Market
                              </span>
                            <span className="table-column-sorter">
                                 {fetchReportObj.sortKey ===
                                 'metro_area_name' ?
                                   get(fetchReportObj, 'sortValue') === 'desc' ? (
                                     <SortUpIcon />
                                   ) : (
                                     <SortDownIcon />
                                   ) : ''}
                              </span>
                          </th>                          
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('agent_assigned')}
                          >
                              <span className="table-column-title">
                                Agent Assigned At
                              </span>
                            <span className="table-column-sorter">
                                 {fetchReportObj.sortKey ===
                                 'agent_assigned' ?
                                   get(fetchReportObj, 'sortValue') === 'desc' ? (
                                     <SortDownIcon />
                                   ) : (
                                     <SortUpIcon />
                                   ) : ''}
                              </span>
                          </th>
                          <th
                            className="cursor__pointer"
                            onClick={() => this.sortTable('price_range')}
                          >
                              <span className="table-column-title">
                                Price Range
                              </span>
                            <span className="table-column-sorter">
                               {fetchReportObj.sortKey ===
                               'price_range' ?
                                 get(fetchReportObj, 'sortValue') === 'desc' ? (
                                   <SortUpIcon />
                                 ) : (
                                   <SortDownIcon />
                                 ) : ''}
                              </span>
                          </th>
                          {/*}*/}
                        </tr>
                        </thead>
                        <tbody>{BrokerageUsersRowContent}</tbody>
                      </table>
                    </div>
                  </Spin>
                  {usersCount > 0 &&
                    <PaginationMain
                      currentPage={fetchReportObj.currentActivePage}
                      total={usersCount}
                      pageChanged={page => this.onPageChange(page)}
                      pageSize={get(fetchReportObj, 'limit', 20)}
                    />
                  }

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}