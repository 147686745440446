import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  townDealInfo: [],
  clientDeals: [],
  schoolDistricts: [],
  fetchClientDealPhase: INIT,
  createUpdateDealPhase: INIT,
  fetchSchoolDistrictPhase: INIT,
  fetchTownSchoolDistrictPhase: INIT,
  deleteClientDealPhase: INIT
})

export const dealsReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* get research town data and list */
    case types.GET_TOWN_AND_SCHOOL_DISTRICT: {
      return state.set('error', null).set('fetchTownSchoolDistrictPhase', LOADING)
    }
    case types.GET_TOWN_AND_SCHOOL_DISTRICT_SUCCESS: {
      const dealInfo = get(action, 'payload.data', {})
      return state
        .set('townDealInfo', dealInfo)
        .set('error', null)
        .set('fetchTownSchoolDistrictPhase', SUCCESS)
    }
    case types.GET_TOWN_AND_SCHOOL_DISTRICT_ERROR: {
      return state.set('error', action.payload.error).set('fetchTownSchoolDistrictPhase', ERROR)
    }

    /* fetch client deal with client id */
    case types.FETCH_CLIENT_DEAL: {
      return state.set('error', null).set('fetchClientDealPhase', LOADING)
    }
    case types.FETCH_CLIENT_DEAL_SUCCESS: {
      const deals = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('clientDeals', deals)
        .set('fetchClientDealPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_DEAL_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientDealPhase', ERROR)
    }

    /* create update client deal information */
    case types.CREATE_UPDATE_DEAL: {
      return state.set('error', null).set('createUpdateDealPhase', LOADING)
    }
    case types.CREATE_UPDATE_DEAL_SUCCESS: {
      return state.set('error', null).set('createUpdateDealPhase', SUCCESS)
    }
    case types.CREATE_UPDATE_DEAL_ERROR: {
      return state.set('error', action.payload.error).set('createUpdateDealPhase', ERROR)
    }
    /* get research town data and list */
    case types.FETCH_SCHOOL_DISTRICT: {
      return state.set('error', null).set('fetchSchoolDistrictPhase', LOADING)
    }
    case types.FETCH_SCHOOL_DISTRICT_SUCCESS: {
      const schoolDistricts = get(action, 'payload.data', {})
      return state
        .set('schoolDistricts', schoolDistricts)
        .set('error', null)
        .set('fetchSchoolDistrictPhase', SUCCESS)
    }
    case types.FETCH_SCHOOL_DISTRICT_ERROR: {
      return state.set('error', action.payload.error).set('fetchSchoolDistrictPhase', ERROR)
    }

    /* delete client deal information */
    case types.DELETE_CLIENT_DEAL: {
      return state.set('error', null).set('deleteClientDealPhase', LOADING)
    }
    case types.DELETE_CLIENT_DEAL_SUCCESS: {
      return state.set('error', null).set('deleteClientDealPhase', SUCCESS)
    }
    case types.DELETE_CLIENT_DEAL_ERROR: {
      return state.set('error', action.payload.error).set('deleteClientDealPhase', ERROR)
    }

    case types.CLEAR_DEALS_PHASE: {
      return state
        .set('fetchTownSchoolDistrictPhase', INIT)
        .set('fetchClientDealPhase', INIT)
        .set('createUpdateDealPhase', INIT)
        .set('fetchSchoolDistrictPhase', INIT)
        .set('deleteClientDealPhase', INIT)
    }

    default: {
      return state
    }
  }
}
