import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  activityEmails: [],
  activityHistories: [],
  fetchActivityEmailPhase: INIT,
  fetchClientActivityHistoryPhase: INIT,
})

export const activitiesReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  fetch activity client email */
    case types.FETCH_ACTIVITY_CLIENT_EMAIL: {
      return state.set('error', null).set('fetchActivityEmailPhase', LOADING)
    }
    case types.FETCH_ACTIVITY_CLIENT_EMAIL_SUCCESS: {
      const emails = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('activityEmails', emails)
        .set('fetchActivityEmailPhase', SUCCESS)
    }
    case types.FETCH_ACTIVITY_CLIENT_EMAIL_ERROR: {
      return state.set('error', action.payload.error).set('fetchActivityEmailPhase', ERROR)
    }
    /*  fetch activity client history */
    case types.FETCH_CLIENT_ACTIVITY_HISTORY: {
      return state.set('error', null).set('fetchClientActivityHistoryPhase', LOADING)
    }
    case types.FETCH_CLIENT_ACTIVITY_HISTORY_SUCCESS: {
      const activities = get(action, 'payload.data', [])
      return state
        .set('error', null)
        .set('activityHistories', activities)
        .set('fetchClientActivityHistoryPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_ACTIVITY_HISTORY_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('activityHistories', [])
        .set('fetchClientActivityHistoryPhase', ERROR)
    }

    case types.CLEAR_CLIENT_ACTIVITY_PHASE: {
      return state
        .set('error', null)
        .set('fetchActivityEmailPhase', INIT)
        .set('fetchClientActivityHistoryPhase', INIT)
    }
    default: {
      return state
    }
  }
}
