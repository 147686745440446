import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import styles from './styles.scss'
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      teamName: "",
      _clientID: "",
      redirect: false,
      id: ""
    }
  }

  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({_clientID:_clientid})
      const clntdata = {}
      clntdata.client_id = _clientid;
      this.props.getRecordingLinks(clntdata)
    }
  }

  render() {
    const {
      user,
      getRecordingLinksData,
    } = this.props
    return (
    <div>
      <main className="recording__links--container">
        <ul className="recording__links--list">
        {getRecordingLinksData && getRecordingLinksData.data && getRecordingLinksData.data.length >0 ? getRecordingLinksData.data.map((link,ind) => {
        return(
          <li key={ind}>
            <a href={link.meeting_url} target="_blank">{link.meeting_url}</a>
          </li>
        )
        })
        : 
        <li>
            This is where your zoom recording with client calls with be located.<br/>
            You've had no call with this client yet.
        </li>
        }
        </ul>
      </main>
    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
