import { connect } from 'react-redux'
import AccountingReportForm from './component'

import { getFilterParams } from '../../../store/dashboard/duck'
import { getAccountingReportData, clearPhase } from '../../../store/analytics/duck'

const AccountingReport = connect(
  // Map state to props
  (state) => ({
  	// strategistPhase: state.analytics.strategistPhase,
  	// strategyData: state.analytics.strategyData,
  	filterParams: state.dashboard.filterParams,
    accountingReportPhase: state.analytics.accountingReportPhase,
    accountingReportData: state.analytics.accountingReportData,
    clearPhase: state.analytics.clearPhase
  }),
  // Map actions to props
  {
  	// getStrategistList,
  	getFilterParams,
    getAccountingReportData,
    clearPhase
  }
)(AccountingReportForm)
export default AccountingReport
