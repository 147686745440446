/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment, useState } from 'react'
import { map, get, find, size } from 'lodash'
import { Spin } from 'antd'
import $ from 'jquery'
import './styles.scss'

import { CloseIcon } from '../../../../../../components/icons'
import { SUCCESS } from '../../../../../../constants/phase'
import Loader from '../../../../../../components/Loader'
import ChatHistory from './partials/ChatHistory'

export default function MessageNoteForAgentComponent(props) {
  const [isLoading, setLoading] = useState(false)
  const [selectedAgent, setAgentId] = useState('')
  const [agentName, setAgentName] = useState('')
  const [agentRole, setAgentRole] = useState('')
  const [chatMessage, setChatMessage] = useState('')

  useEffect(() => {
    const clientId = props.match.params.id
    setLoading(true)
    setAgentName('')
    setAgentId('')
    props.clearClientAgents()
    props.fetchClientAgent({ clientId })
  }, [])

  const onClose = () => {
    setAgentName('')
    setAgentId('')
    setAgentRole('')
    props.closeDrawer()
  }

  // fetch client chat
  const handleAgentChange = (event) => {
    const { value } = event.target
    const agent = find(props.clientAgents, { agent_id: parseInt(value) })
    if (size(agent)) {
      setAgentName(agent.agent_full_name)
      setAgentRole(agent.agent_role)
    } else {
      setAgentName(false)
      setAgentRole(false)
    }
    setAgentId(value)
  }

  // cwrp on success of fetching client agent listing and client agent chat
  useEffect(() => {
    if (props.fetchClientAgentListPhase === SUCCESS) {
      setLoading(false)
      props.clearChatPhase()
    }
    if (props.sendAgentMessagePhase === SUCCESS) {
      setLoading(true)
      const clientId = props.match.params.id
      props.fetchClientAgentChat({ clientId })
      props.clearChatPhase()
    }
    if (props.fetchClientAgentChatPhase === SUCCESS) {
      $('.chats__container').animate({ scrollTop: $('.chats__container').prop("scrollHeight") }, 1000)
      setLoading(false)
      props.clearChatPhase()
    }
  }, [props.fetchClientAgentListPhase, props.sendAgentMessagePhase, props.fetchClientAgentChatPhase])

  const userId = props.user && props.user.id

  const handleInputChange = ({ target }) => setChatMessage(target.value)

  const sendAgentMessage = () => {
    props.sendAgentMessage({
      client_id: props.match.params.id,
      sender_id: userId,
      type: 'text',
      receiverIds: [selectedAgent],
      sender_type: 'strategist',
      receiver_type: 'agent',
      message: chatMessage,
    })
    setChatMessage('')
  }

  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Message / Notes For Agent</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="chats__start__bottom">
        {props.clientAgents && props.clientAgents.length > 0 && (
          <div className="chats__select__agents">
            <div className="material-textfield">
              <select
                name="agent"
                className="form-control custom-select material-textfield-input"
                onChange={handleAgentChange}
              >
                <option value="">Select</option>
                {map(props.clientAgents, (clientAgent, index) => (
                  <option key={index} value={clientAgent.agent_id}>
                    {clientAgent.agent_full_name} ({clientAgent.agent_role})
                  </option>
                ))}
              </select>
              <label className="material-textfield-label">Agent Name</label>
            </div>
          </div>
        )}
        {/* <Spin size="large" spinning={isLoading} indicator={<Loader />}> */}
          <div className="chats__container">
            <div className="chats__wrapper">
              <ChatHistory agentId={selectedAgent} {...props} />
            </div>
          </div>
        {/* </Spin> */}
        {selectedAgent && agentRole && agentRole === 'agent' && (
          <div className="chats__container__teatarea">
            <textarea
              name="chat"
              className="chats__sectiion__input"
              value={chatMessage}
              onChange={handleInputChange}
              placeholder={`Send a message/note to ${agentName} about ${get(
                props,
                'clientDetail.client_full_name',
                ''
              )}...`}
            ></textarea>
            <div className="chats__section__cta">
              <button
                type="button"
                className="btn btn__btn btn-dark btn__send"
                disabled={chatMessage !== '' && chatMessage.trim() !== '' ? false : true}
                onClick={sendAgentMessage}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}
