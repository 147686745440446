/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react'
import _, { get } from 'lodash'
import moment from 'moment'
import ReactModal from 'react-modal'
import Cookies from 'universal-cookie'
import Calendar from '@toast-ui/react-calendar';

import "tui-calendar/dist/tui-calendar.css"
import "tui-date-picker/dist/tui-date-picker.css"
import "tui-time-picker/dist/tui-time-picker.css"

import { theme, schedules, calenders, timezones, Option } from "./variables"
import { CloseIcon, CaretLeftIcon, CaretRightIcon } from '../../../../../../components/icons'
import UserContext from "../../../../../App/Context"
import { message } from 'antd'

import './styles.scss'
// a instance variable for create calender object
let delay = false;
const cookies = new Cookies()

class CalendarComponent extends PureComponent {
  // create context instance type for get user information userData
  static contextType = UserContext;
  // create reference for calendar
  calendarRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      openDeleteSchedule: false,
      calendarView: "month",
      currentDate: moment()
    }
    this.handleChange = this.handleChange.bind(this)
    this.onWheelScroll = this.onWheelScroll.bind(this)
    this.toggleDeleteScheduleModal = this.toggleDeleteScheduleModal.bind(this)
    this.updateSchedule = this.updateSchedule.bind(this)
    this.createSchedule = this.createSchedule.bind(this)
    this.deleteSchedule = this.deleteSchedule.bind(this)
    this.openSelectedDate = this.openSelectedDate.bind(this)
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (user) {
      this.props.fetchUser({ user_id: user.id })
      // fetch user all appointments of user
      this.props.getAppointments({
        user_id: user.id,
        timezone: user.time_zone
      })
    }
  }
  static getDerivedStateFromProps(props, state) {
    // on delete appointment show success message and clear phase for new props
    if (props.deleteAppointmentPhase === "success") {
      message.success('Appointment Deleted Successfully.')
      props.reFetchAppointment();
      // clear phase action remove deleteAppointmentPhase success phase
      props.clearPhase();
    }
    return props;
  }
  // set date and time for show current selected month and year
  setRenderRangeText() {
    const calendarInstance = this.calendarRef.current.getInstance();
    const renderRange = document.getElementById('renderRange');
    const options = calendarInstance.getOptions();
    const viewName = calendarInstance.getViewName();
    const html = [];
    if (viewName === 'day') {
      // html.push(moment(calendarInstance.getDate().getTime()).format('YYYY.MM.DD'));
      html.push(moment(calendarInstance.getDate().getTime()).format('YYYY MMMM'));
    } else if (viewName === 'month' &&
      (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
      html.push(moment(calendarInstance.getDate().getTime()).format('YYYY MMMM'));
    } else {
      // html.push(moment(calendarInstance.getDateRangeStart().getTime()).format('YYYY.MMMM.DD'));
      html.push(moment(calendarInstance.getDateRangeStart().getTime()).format('YYYY MMMM'));
      // html.push(' ~ ');
      // html.push(moment(calendarInstance.getDateRangeEnd().getTime()).format(' MM.DD'));
    }
    const date = moment(calendarInstance.getDate().getTime())
    renderRange.innerHTML = html.join('');
    this.props.changeCurrentDate(new Date(date.format('YYYY'), parseInt(date.format('MM')) - 1, 1))
  }
  // change month using previous and next button
  moveToNextOrPrevRange(val) {
    // if value is -ve it go to previous and +ve to next month
    const calendarInstance = this.calendarRef.current.getInstance();
    if (val === -1) {
      // previous is used for go back to previous month
      calendarInstance.prev();
    } else if (val === 1) {
      // next function on instate give next month
      calendarInstance.next();
    }
    this.setRenderRangeText();
    // render is used to force print data
    calendarInstance.render();
  }
  // on wheel scroll
  onWheelScroll(event) {
    event.persist()
    if (this.state.calendarView === "month") {
      if (delay) return;
      delay = true;
      setTimeout(() => { delay = false }, 100)
      const calendarInstance = this.calendarRef.current.getInstance();
      // if deltaY is -ve it go to previous month
      if (event.deltaY < 0) {
        calendarInstance.prev();
      }
      // if deltaY is +ve it go to next month
      if (event.deltaY > 0) {
        calendarInstance.next();
      }
      this.setRenderRangeText();
      calendarInstance.render();
    }
  }
  // change calender view
  handleChange(event) {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.changeView(event.target.value, true);
    this.setState({ calendarView: event.target.value })
  }
  // on click on today go to today's date on calendar
  goToToday() {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.today();
    this.setRenderRangeText();
    calendarInstance.render();
  }
  // change date in main calendar with day view with date
  openSelectedDate(date) {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.changeView('day', true);
    calendarInstance.setDate(new Date(date));
    this.setState({ calendarView: 'day' })
    this.setRenderRangeText()
  }
  // on edit schedule click on event open appointment model for edit schedule
  updateSchedule(event) {
    this.props.performOperationOnCalendar("updateSchedule", event)
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.render()
  }
  // on create schedule click on event open appointment model
  createSchedule(event) {
    // call perform operation for open create schedule models
    this.props.performOperationOnCalendar("createSchedule", event)
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.render()
  }
  // on click delete schedule click on event open appointment model for delete schedule
  deleteSchedule(event) {
    if (event.schedule) {
      // if schedule is clicked as delete event
      // eslint-disable-next-line camelcase
      this.props.deleteAppointment({ calendar_id: event.schedule.raw.event_id })
      this.setState({ openDeleteSchedule: false })
    }
  }
  // open close delete appointment modal
  toggleDeleteScheduleModal(event) {
    const { openDeleteSchedule } = this.state
    this.setState({ openDeleteSchedule: !openDeleteSchedule, event })
  }

  getCalendarIdFromAppointmentType(type) {
    const types = [
      { id: "0", value: "Initial agent call", name: "Initial agent call" },
      { id: "0", value: "Initial agent call", name: "Initial Agent Town Call" },
      { id: "1", value: "Follow up agent call", name: "Follow up agent call" },
      { id: "2", value: "Initial agent town tour", name: "Initial agent town tour" },
      { id: "2", value: "Initial agent town tour", name: "Initial Town Tour" },
      { id: "3", value: "Follow up agent town tour", name: "Follow up agent visit" },
      { id: "3", value: "Follow up agent town tour", name: "Follow up Town Tour" },
      { id: "3", value: "Follow up agent town tour", name: "Follow up agent town tour" },
      { id: "4", value: "Inspection", name: "Inspection" },
      { id: "5", value: "Closing", name: "Closing" },
      { id: "6", value: "Outlook Calendar Event", name: "outlook_personal_events" },
      { id: "7", value: "Google Calendar Event", name: "google_personal_events" },
      { id: "8", value: "Not Available", name: "Busy" },

      { id: "9", value: "Initial Agent Town Call", name: "Initial Agent Town Call" },
      { id: "10", value: "Initial Town Tour", name: "Initial Town Tour" },
      { id: "11", value: "Follow up Town Tour", name: "Follow up Town Tour" }
    ]
    const data = _.find(types, { name: type })
    return _.get(data, 'id', '0')
  }

  render() {
    const { appointmentData } = this.props
    const appointments = _.get(appointmentData, 'data', [])
    const user = this.context.userData
    const schedulesData = _.map(appointments, appointment => {
      // create schedules this event and are present on raw
      let title = `${appointment.client_first_name} ${appointment.client_last_name}`
      const strategist = `${appointment.strategist_first_name} ${appointment.strategist_last_name}`
      if (appointment.appointment_type === "outlook_personal_events") {
        title = "Outlook calendar Event"
      } else if (appointment.appointment_type === "google_personal_events") {
        title = "Google calendar Event"
      } else if (title === " ") {
        title = "Busy"
      } else {
        // do nothing
      }
      return {
        raw: appointment,
        id: appointment.client_id,
        clientName: title,
        title,
        strategist,
        clientId: appointment.client_id,
        eventId: appointment.event_id,
        userId: appointment.user_id,
        start: moment(appointment.start_date).toDate(),
        end: moment(appointment.end_date).toDate(),
        appointmentId: appointment.event_id,
        role: appointment.role,
        type: appointment.type,
        description: appointment.description ? appointment.description : "",
        calendarId: this.getCalendarIdFromAppointmentType(appointment.appointment_type),
        appointmentType: appointment.appointment_type,
        // body keyword is used to show description on details popup
        body: appointment.description ? appointment.description : "",
        // attendees keyword is used to show description on details popup
        attendees: [`${get(user, "first_name", "")} ${get(user, "last_name", "")}`],
        // location keyword is used to show description on details popup
        location: "",
        category: 'time',
        dueDateClass: ''
      }
    })
    return (
      <Fragment>
        <div className="calendar__header">
          <div className="calendar__header__left">
            <button className="btn btn__year__change left" onClick={this.moveToNextOrPrevRange.bind(this, -1)}>
              <CaretLeftIcon />
            </button>
            <button className="btn btn__year__change right" onClick={this.moveToNextOrPrevRange.bind(this, 1)}>
              <CaretRightIcon />
            </button>
            <div id="renderRange" className="calendar__year">{moment().format("YYYY  MMMM")}</div>
          </div>
          <div className="calendar__header__right">
            <button className="btn btn__btn btn-dark btn__calendar" onClick={this.goToToday.bind(this)}>Today</button>
            <div className="material-textfield">
              <select
                className="form-control custom-select calendar__select"
                onChange={this.handleChange}
                value={this.state.calendarView}
                required
              >
                <option value="month">Month</option>
                <option value="week">Week</option>
                <option value="day">Day</option>
              </select>
            </div>
            <button onClick={this.props.history.goBack} className="btn btn__calendar__close"><CloseIcon /></button>
          </div>
        </div>
        <div className="calendar__full__view" onWheel={this.onWheelScroll}>
          <Calendar
            height="calc(100vh - 74px)"
            ref={this.calendarRef}
            // defined color and type of event
            calendars={calenders}
            // hide default event create pop
            useCreationPopup={false}
            useDetailPopup={true}
            disableDblClick={true}
            disableClick={false}
            // show schedule as actionalble
            isReadOnly={false}
            month={Option.month}
            // create schedules for show event details
            schedules={schedulesData}
            // call create schedule model
            onBeforeCreateSchedule={this.createSchedule}
            // call update schedule model for updating event details
            onBeforeUpdateSchedule={this.updateSchedule}
            // show confirmation details for delete schedules
            onBeforeDeleteSchedule={this.toggleDeleteScheduleModal}
            scheduleView={true}
            taskView={false}
            template={Option.template}
            // theme for change in design for calendar cell
            theme={theme}
            // show time zone for calendar selections
            timezones={timezones}
            // show default use of month on calendar
            view={"month"}
            // You can also set the `defaultView` option.
            week={Option.week}
          />
        </div>
        <ReactModal
          isOpen={this.state.openDeleteSchedule}
          onRequestClose={this.toggleDeleteScheduleModal}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.toggleDeleteScheduleModal}><CloseIcon /></button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">This will remove the schedule information and you will need to re enter the schedule if needed.</p>
              <div className="text-right">
                <button onClick={this.deleteSchedule.bind(this, this.state.event)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    )
  }
}
export default CalendarComponent
