import { connect } from 'react-redux'

import AiClientsComponent from './component'
import { getAIClientsReport, clearReportV2Phase } from '../../../../../../store/reportV2/duck'
import { getFilterParams, clearDashboardPhase } from '../../../../../../store/strategistV2/dashboard/actions'

const AiClientsContainer = connect(
  // Map state to props
  (state) => ({
    aiClientsPhase: state.reportV2.aiClientsPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    aiClientsData: state.reportV2.aiClientsData,
  }),
  // Map actions to dispatch and props
  { getFilterParams,
    getAIClientsReport,
    clearReportV2Phase
  }
)(AiClientsComponent)

export default AiClientsContainer
