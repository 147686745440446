import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'

import { appointmentEpic, appointmentReducer } from './appointment'
import { notesEpic, noteReducer } from './notes'
import { strategistEpic, strategistReducer } from './strategist'
import { activitiesEpic, activitiesReducer } from './activity'
import { headerEpic, headerReducer } from './header'
import { clientDetailEpic, clientDetailReducer } from './clientdetail'
import { yourAgentEpic, yourAgentReducer } from './youragent'
import { junglerUserEpic, junglerUserReducer } from './jungleruser'
import { chatEpic, chatReducer } from './chat'
import { townsEpic, townsReducer } from './towns'
import { clientsEpic, clientsReducer } from './clients'
import { commentEpic, commentReducer } from './comments'
import { prospectusEpic, prospectusReducer } from './prospectus'
import { dashboardEpic, dashboardReducer } from './dashboard'
import { dealsEpic, dealsReducer } from './deals'
import { agentInterviewEpic, agentInterviewsReducer } from './interview'

// combine all epic
// export combine epic
export const strategistV2Epic = combineEpics(
  // chat for client details page epics
  dashboardEpic,
  chatEpic,
  notesEpic,
  appointmentEpic,
  strategistEpic,
  activitiesEpic,
  headerEpic,
  townsEpic,
  clientDetailEpic,
  yourAgentEpic,
  junglerUserEpic,
  clientsEpic,
  commentEpic,
  prospectusEpic,
  dealsEpic,
  agentInterviewEpic
)

// combine all reducer
// export default reducer
export default combineReducers({
  dashboard: dashboardReducer,
  note: noteReducer,
  appointment: appointmentReducer,
  strategist: strategistReducer,
  activity: activitiesReducer,
  header: headerReducer,
  clientDetail: clientDetailReducer,
  youragent: yourAgentReducer,
  junglerUser: junglerUserReducer,
  chat: chatReducer,
  town: townsReducer,
  client: clientsReducer,
  comment: commentReducer,
  prospectus: prospectusReducer,
  deal: dealsReducer,
  agentInterview:agentInterviewsReducer
})
