import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'
import { Container, Row, Col, } from 'react-grid-system'
import Cookies from 'universal-cookie'
import axios from 'axios'
import Modal from "react-responsive-modal";
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
export class outlookCallbackForm extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
     code:'',
    }
  }

  componentWillMount(){
    if(this.getParameterByName('code') && this.getParameterByName('code') != '' && this.getParameterByName('code') != null){
      this.setState({code:this.getParameterByName('code')})
      const data = {}
      data.code = this.getParameterByName('code')
      this.props.saveAgentAuthtoken(data)    
    }else{
      alert('Invalid request')
      this.props.history.push('/login')
    }
    
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.authAgentTokenPhase=='success'){
      window.close();
    }

    if(nextProps.saveAgentAuthtokenData.status===false){
      alert(nextProps.saveAgentAuthtokenData.message)
      window.close();
    }
  }

  render(){
    return (
      <header className="navbar__header">
        Please wait your outlook account authenticating with Suburban Jungle group...
      </header>
    )
 } 
}

function mapStateToProps(state, props) {
    return {
        initialValues : props.location.state ? props.location.state : {}
     }
}

export default connect( mapStateToProps )(
  (outlookCallbackForm)
)