import * as types from './action-types'
/* 
   ////////////////////////////
  /// towns action ///
////////////////////////////
 */
// fetch town Research data
export const fetchTownResearch = (payload) => ({
  type: types.GET_RESEARCH_TOWN_DATA,
  payload,
})
// update town research data
export const updateTownResearch = (payload) => ({
  type: types.UPDATE_RESEARCH_TOWN_DATA,
  payload,
})
// update school order
export const updateSchoolOrder = (payload) => ({
  type: types.UPDATE_SCHOOL_ORDER,
  payload,
})
// clear towns phase
export const clearTownsPhase = () => ({
  type: types.CLEAR_TOWNS_PHASE,
})
