import { connect } from 'react-redux';
import {
  fetchBrokerageReport,
  fetchBrokerageReportFilters,
  clearBrokeragePhase
} from '../../../../../../store/brokerageReport/duck';

import BrokerageReportComponent from './component';

const BrokerageReportContainer = connect(
  // Map state to props
  state => ({
    brokerageRows: state.brokerageReport.brokerageRows,
    usersCount: state.brokerageReport.usersCount,
    brokerageFilters: state.brokerageReport.brokerageFilters,
    brokerageReportPhase: state.brokerageReport.brokerageReportPhase,
    brokerageReportFilterPhase: state.brokerageReport.brokerageReportFilterPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchBrokerageReport,
    fetchBrokerageReportFilters,
    clearBrokeragePhase,
  }
)(BrokerageReportComponent);

export default BrokerageReportContainer;