import React, { Fragment } from 'react'
import { SortDownIcon, SortUpIcon } from '../../../../../components/icons'

export default function AppointmentTableHeader({ appointments, tab,sortByType, sortByKey, sortData }) {

  return (
    <Fragment>
      <thead>
        <tr>
          <th className="cursor__pointer" onClick={()=>{
            sortData('strategist_id')
          }}>
            <span className="table-column-title">Strategist Name</span>
            <span className="table-column-sorter">
              {sortByKey === 'strategist_id' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
            </span>
          </th>
          <th className="cursor__pointer" onClick={()=>{
            sortData('agent_id')
          }}>
            <span className="table-column-title">Agent Name</span>
            <span className="table-column-sorter">
              {sortByKey === 'agent_id' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
            </span>
          </th>
          <th className="cursor__pointer" onClick={()=>{
            sortData('sb.name')
          }}>
            <span className="table-column-title">Suburb</span>
            <span className="table-column-sorter">
              {sortByKey === 'sb.name' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
            </span>
          </th>
            <th className="cursor__pointer" onClick={()=>{
              sortData('asi.created')
            }}>
              <span className="table-column-title">Date application submitted</span>
              <span className="table-column-sorter">
                {sortByKey === 'asi.created' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
              </span>
            </th>
          <th className="cursor__pointer">
            <span className="table-column-title">Action</span>
          </th>
        </tr>
      </thead>
    </Fragment>
  )
}
