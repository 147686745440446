import { connect } from 'react-redux'

import ChatsComponent from './component'

const ChatsContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(ChatsComponent)

export default ChatsContainer
