import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import _ from 'lodash'
import Config from '../../../../../../config';
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import moment from 'moment'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class StrategistRatingReportComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      users_limit:5000,
      activePage:1,
      sortByKey: '',
      sortByType : 0,
      isLoading: false,
      totalPages:0,
      search: '',
      ratingReportTableData:[],
      showAll: true,
      selectedIndex: 'all',
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    this.setState({  isLoading: true })
    _user = cookies.get('user')
    if(_user) {
      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='local' || _user.role === 'business development'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='strategist'){
        this.props.history.push('/administration/dashboard')
      }

      const strRatingParams = {};
      if (this.state.selectedIndex === "all") {
        strRatingParams.users_limit = this.state.users_limit;
        strRatingParams.users_offset = this.state.activePage;
        strRatingParams.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strRatingParams.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        strRatingParams.sortBy = { key: this.state.sortByKey, type: this.state.sortByType };
      }
      this.props.getStrategistRating(strRatingParams)
    }
  }

  componentDidUpdate(preProps, preState){
    const { strategistRatingPhase, strategistRatingData, clearReportV2Phase } = this.props
    if(strategistRatingPhase === 'success'){
      clearReportV2Phase()
      let ratingData = []
      const rateData = strategistRatingData && strategistRatingData.data
      if(_.size(rateData) > 0){
        rateData && rateData.map((str, i)=>{
          ratingData.push({
            key: i,
            appointment_date: str.appointment_date,
            client_fullname: str.client_fullname,
            client_status: str.client_status,
            rating: str.rating === -1 ? "N/A" : str.rating,
            strategist_fullname: str.strategist_fullname
          })
        })
      }
      this.setState({ ratingReportTableData: ratingData, isLoading: false })
    }
    if(strategistRatingPhase === 'error'){
      clearReportV2Phase()
      this.setState({ ratingReportTableData: [], isLoading: false })
    }
  }

  onSearch(e){
    this.setState({search:e.target.value})
  }

  changeLimit = limit => {
    // On limit change
  }

  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, toDate: to })
    const strRatingParams = {
      users_limit : this.state.users_limit,
      users_offset: this.state.activePage,
      sortBy      : {key: this.state.sortByKey, type: this.state.sortByType},
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(to).format("YYYY-MM-DD HH:mm:ss")
    }
    this.props.getStrategistRating(strRatingParams)
  }

  render() {
    const { ratingReportTableData } = this.state
    let filterList  = ratingReportTableData
    if(_.size(ratingReportTableData) > 0){
      filterList = ratingReportTableData.filter(
        (val) =>{
          return (
            ((val.strategist_fullname.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 || (val.client_fullname.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1)
          );
        }
      );
    }

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Strategist Rating Report-Initial Call</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={filterList} name="strategist_rating_report">
                          <ExcelColumn label="Strategist Name" value="strategist_fullname"/>
                          <ExcelColumn label="Client Name" value="client_fullname"/>
                          <ExcelColumn label="Rating" value="rating"/>
                          <ExcelColumn label="Pipeline Stage" value="client_status"/>
                          <ExcelColumn label="Appointment Date" value="appointment_date"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by strategist or client name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
              {
                (_.size(filterList) > 0) ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <Table
                        className="table__fixed"
                        sortOrde={true}
                        columns={[
                          {
                            title: 'Strategist Name',
                            width: 250,
                            dataIndex: 'strategist_fullname',
                            key: '1',
                            fixed: 'left',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.strategist_fullname.localeCompare(b.strategist_fullname)
                          },
                          {
                            title: 'Client Name',
                            width: 250,
                            dataIndex: 'client_fullname',
                            key: '2',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.client_fullname.localeCompare(b.client_fullname)
                          },
                          {
                            title: 'Rating',
                            dataIndex: 'rating',
                            key: '3',
                            width: 150,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.rating - b.rating,
                          },
                          {
                            title: 'Pipeline Stage',
                            dataIndex: 'client_status',
                            key: '4',
                            width: 350,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.client_status.localeCompare(b.client_status)
                          },
                          {
                            title: 'Appointment Date',
                            dataIndex: 'appointment_date',
                            key: '5',
                            width: 300,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.appointment_date.localeCompare(b.appointment_date)
                          },
                        ]}
                        dataSource={filterList}
                        pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                        scroll={{ y: 420 }}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="blank__table__state">Data Not Found</div>
              }
              </Spin>

            </div>
          </main>
        </div>
      </div>
    )
  }
}
