import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import { DateRange } from 'react-date-range'
import Modal from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AppointmentsScheduled extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')) : moment(),
      endDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')) : moment(),
      client_strategist:[],
      strategistDropdown: false,
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')) : moment(),
      defaultEndDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')) : moment()
    }
  }

  componentWillMount() {
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      strData.startDate = moment(this.state.fromDate).format("YYYY/MM/DD")
      strData.endDate = moment(this.state.endDate).format("YYYY/MM/DD")
      this.props.getAppointmentsScheduledClientList(strData)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.strategistPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  // handleFromDate(date) {
  //   let selectDate =  moment(date._d).format("YYYY/MM/DD")
  //   setTimeout(function(e){
  //     const strData = {}
  //     strData.startDate = moment(selectDate).format("YYYY/MM/DD")
  //     strData.endDate = moment(this.state.endDate).format("YYYY/MM/DD")
  //     this.props.getAppointmentsScheduledClientList(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date) {
  //   let selectDate =  moment(date._d).format("YYYY/MM/DD")
  //   setTimeout(function(e){
  //     const strData = {}
  //     strData.startDate = moment(this.state.fromDate).format("YYYY/MM/DD")
  //     strData.endDate = moment(selectDate).format("YYYY/MM/DD")
  //     this.props.getAppointmentsScheduledClientList(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

      let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
      let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
      setTimeout(function(e){
        const strData = {}
        strData.startDate = start_from_date
        strData.endDate = end_to_date
        this.props.getAppointmentsScheduledClientList(strData)
      }.bind(this), 300)
    })
  }

  render() {
    const { AppointmentScheduledData } = this.props
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Appointments Scheduled</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{(AppointmentScheduledData && AppointmentScheduledData.data && AppointmentScheduledData.data.length > 0) ? AppointmentScheduledData.data.length : 0}</span> Appointments Scheduled</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">

            <div className="right__cont right__cont--full">
              
            <div className="datepicker__fix all__custom--wrapper datepicker__fix--edit">
                <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                {
                  (this.state.modalIsOpen) &&
                  <div>
                    <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={customStyles}           
                      contentLabel="Example Modal">
                      <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                        <div className="modal-content">             
                          <div className="modal-body text-center">
                          <div className="calendar__header">
                            <h4>Date Range</h4>
                            <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                          </div>
                          <DateRange
                            onInit={this.handleSelect.bind(this)}
                            onChange={this.handleSelect.bind(this)}
                            startDate={this.state.fromDate}
                            endDate={this.state.endDate}
                          />
                          <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                          </div>               
                        </div>
                      </div>
                    </Modal>
                  </div>
                }
              </div>
              </div>

              {/*<div className="dp-wrapper dp-wrapper-edit">
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dp-wrapper dp-wrapper-edit">
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
            <div className="filter__container filter__container--users">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra">
                   <div className="table-responsive">
                     <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Client Name</th>
                            <th>Who Scheduled</th>
                            <th>Scheduled With</th>
                            <th>Booked How</th>
                            <th>Appointment Type</th>
                            <th>Date Created</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                          <tbody>
                            <tr>
                              <td colSpan="9" className="noClientResults text-center">
                              <img src="../../img/loader2.svg" />
                              <h5>Report is generating, please wait...</h5>
                              </td>
                            </tr>
                          </tbody>
                        :
                        <tbody>
                        {
                          AppointmentScheduledData && AppointmentScheduledData.data && AppointmentScheduledData.data.map((str, index)=>{
                            let appointmentType = str.appointment_type
                            if(str.appointment_type === 'Follow up agent town tour'){
                              appointmentType = "Follow up agent visit"
                            }
                            return(
                              <tr key={index} className="forcursor" >
                              <td>{str.client_name}</td>
                              <td>{str.who_scheduled}</td>
                              <td>{str.role}</td>
                              <td>{str.booked_by && str.booked_by !== '' ? str.booked_by : (str.who_scheduled ? str.who_scheduled + ' jungler dash' :  'jungler dash')}</td>
                              <td>{appointmentType}</td>
                              <td>{str.date_created}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                     </div>
                   </div>
                 </div>
               </div>
              </main>
              <div className="dashboard-footer text-center">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'appointmentsscheduled',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AppointmentsScheduled)
