import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { logoutInvalidRequest } from '../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()

let _user = ''

export default class AgentSideNavigation extends React.Component {
  townTime_activity = null
  intervalID = 0;
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      town_name: '',
      town_url: '',
      hours:0,
      minutes:0,
      seconds:0,
    }
  }

  componentWillMount(){
    const { match: { params: { townId }} } = this.props
    _user = cookies.get('user')
     this.getTownName()

     /*This is for town time sheet*/
     /*
      if(_user && _user.role === 'strategist' || _user.role =='admin'){
        let splitVal = this.props.location.pathname && this.props.location.pathname.split('/')
        if(splitVal){
          if(splitVal[1] === "edit-report"){
            this.townTime_activity = localStorage.getItem('townStrategistTime')
            let townNameFromLocal = localStorage.getItem('townName') ? localStorage.getItem('townName') : ''
            if(!this.townTime_activity || this.townTime_activity == 'null'){
              let obj = {
                strategist_id:_user.id,
                // town_id: parseInt(townId),
                client_id: 0,
                start_date: new Date(),
                start_time: new Date(),
                activity_log: `Editing Town Report: ${townNameFromLocal}`
              }
              localStorage.setItem('townStrategistTime', Date.now())
              this.townTime_activity = Date.now()
              axios({
                method: 'POST',
                url: HOSTNAME+'/user/add_client_strategist_timesheet',
                data: obj
              })
              .then((res) => {
                if(res.data && res.data && res.data.status){
                  localStorage.setItem('townTimeId', res.data.data[0])
                }else{
                  localStorage.setItem('strategistTime', 'null')
                  this.townTime_activity = 'null'
                }
              }).catch(logoutInvalidRequest)
            }
          }
        }
      }
      */
      /*This is for town time sheet*/
  }

  componentDidMount(){
    if(this.townTime_activity && this.townTime_activity != 'null'){
      this.startInterval()
    }
  }

  startInterval = () => {
    this.intervalID =  setInterval( () => { 
      var a = new Date()
      var b = new Date(parseInt(this.townTime_activity))
     
      a = moment.tz(a, _user.time_zone)
      b = moment.tz(b, _user.time_zone)

      var diff = Math.abs(a - b) / 1000;
       // get hours
      var hours = Math.floor(diff / 3600) % 24;
      // get minutes
      var minutes = Math.floor(diff / 60) % 60;
      
      minutes = (parseInt(minutes) < 10) ? "0" + parseInt(minutes) : parseInt(minutes)
      // get seconds
      var seconds = diff % 60;
      seconds = (parseInt(seconds) < 10) ? "0" + parseInt(seconds) : parseInt(seconds)
      this.setState({ 'hours': parseInt(hours), 'minutes': minutes, 'seconds': seconds})
      if(parseInt(minutes) === 10){
        this.stopTime('')
      }
    }, 1000)
  }

  startTime(data){
    /*
    const { match: { params: { townId }} } = this.props
    let obj = {
      strategist_id: _user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: `Editing Town Report: (${this.state.town_name})`
    }
    localStorage.setItem('townStrategistTime', Date.now())
    this.townTime_activity = Date.now()
    this.startInterval()
    this.forceUpdate()
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('townTimeId', res.data.data[0])
      }else{
        localStorage.setItem('townStrategistTime', 'null')
        this.townTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  stopTime(data){
    /*
    const { match: { params: { townId }} } = this.props
    clearInterval(this.intervalID);
    let townTimeId = localStorage.getItem('townTimeId')
    let _user = cookies.get('user')
    let obj = {
      id: townTimeId,
      strategist_id:_user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      end_date: new Date(),
      end_time: new Date(),
      status: 1
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('townStrategistTime', 'null')
        localStorage.setItem('townTimeId', null)
        this.townTime_activity = 'null'
        this.forceUpdate()
      }else{
        localStorage.setItem('townStrategistTime', 'null')
        localStorage.setItem('townTimeId', null)
        this.townTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      const town_name = townName.data.town && townName.data.town[0].name
      const town_url = townName.data.town && townName.data.town[0].town_url
      this.setState({ town_name: town_name, town_url: town_url })
    }
  }

  close(e){
    this.stopTime('')
    const { history } = this.props
    localStorage.setItem('townName', 'null')
    history.push({ pathname: "/town-report" })
  }

  render() {
    const {location, user, match: { params: { townId }} } = this.props;
    const { hours, minutes, seconds } = this.state
    const currentuser = user && user;
    const currentPath = location.pathname;
       return (
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={_user && _user.role && (_user.role === 'admin' || _user.role ==='strategist') ? "newprofile__header--wrapper withstop__btn" : "newprofile__header--wrapper" }>
                    <div className="newprofile__header">
                        <img src="/img/logo.svg" className="reportlogo update__logo" />
                        <h4 className="rempadleft">
                          <span>|</span>Edit {this.state.town_name} Town Report
                        </h4>
                    </div>
                    {
                      _user && _user.role && (_user.role === 'admin' || _user.role ==='strategist') && this.townTime_activity === 'null' ?
                        <button className="btn btn__green strategy-right btn__profile start__btn--table font500" onClick={this.startTime.bind(this)}><img src="img/playbutton.svg" /> Start</button>
                      : ''
                    }
                    {
                      _user && _user.role && ( _user.role ==='admin' || _user.role ==='strategist') && this.townTime_activity !== 'null' ?
                          <button className="btn btn__green strategy-right btn__profile play__btn--table font500" onClick={this.stopTime.bind(this)}><img src="img/stopbutton.svg" /> Stop ({hours}:{minutes}:{seconds})</button>
                        : ''
                    }
                    <div className="newprofile__close newprofile__close--townreo">
                    <a href={`${this.state.town_url}`} target="_blank" className="new__view--townreport">View Town Report</a>
                      <span onClick={this.close.bind(this)} style={{ cursor:'pointer' }}>
                        <img src="img/close.svg" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="header__flex">
              <div className="logo-header">
                      <a><img src="/img/logo.svg"/></a>
                  </div>
                <span>| &nbsp; Edit {this.state.town_name} Town Report</span>
                <div className="flex__right">
                <a href={`${this.state.town_url}`} target="_blank" className="btn__view--townreport">View Town Report</a>
                <img className="common__close" onClick={this.close.bind(this)} src="img/close.svg"/>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
          <div className="col-md-12">
            <div id="sidebar-wrapper" className="town__reports--sidebar scrollhoriz">
              <ul className="sidebar-nav">
                {/*<li><Link to={`/edit-report/community/${townId}`} className="sidebar-brand "><img src="img/logo.svg"/></Link></li>*/}
                <li><Link to={`/edit-report/community/${townId}`} className={currentPath == `/edit-report/community/${townId}` ? "active" : ""} >Quick Facts</Link></li>
                <li> <Link to={`/edit-report/images/${townId}`} className={currentPath == `/edit-report/images/${townId}` ? "active" : ""} >Images</Link></li>
                <li><Link to={`/edit-report/public-school/${townId}`} className={currentPath == `/edit-report/public-school/${townId}` ? "active" : ""}>Schools</Link></li>
                {/* <li><Link to={`/edit-report/private-school/${townId}`} className={currentPath == `/edit-report/private-school/${townId}` ? "active" : ""} >Private School</Link></li> */}
                <li><Link to={`/edit-report/commute/${townId}`} className={currentPath == `/edit-report/commute/${townId}` ? "active" : ""}>Commute</Link></li>
                <li><Link to={`/edit-report/google-places/${townId}`} className={currentPath == `/edit-report/google-places/${townId}` ? "active" : ""}>Google Places</Link></li>
                <li><Link to={`/edit-report/research/${townId}`} className={currentPath == `/edit-report/research/${townId}` ? "active" : ""}>Research</Link></li>
              </ul>
            </div>
        </div>
        </div>
        </div>
        </div>
    )
 }
}
