import * as types from './action-types'
/*
    //////////////////////////
  /// client action ///
//////////////////////////
*/
// fetch client list
export const fetchClientById = (payload) => ({
  type: types.FETCH_CLIENT_BY_ID,
  payload,
})

// fetch client strategist bd list
export const fetchClientStrategistBDList = (payload) => ({
  type: types.FETCH_CLIENT_STRATEGIST_BD_LIST,
  payload,
})

// fetch client strategist bd list
export const fetchStrategistBDList = (payload) => ({
  type: types.FETCH_STRATEGIST_BD_LIST,
  payload,
})

// fetch biz option
export const fetchStrategistDevBiz = (payload) => ({
  type: types.FETCH_STRATEGIST_DEV_BIZ_LIST,
  payload,
})

// fetch client list
export const clearClientPhase = () => ({
  type: types.CLEAR_CLIENT_PHASE,
})

// create Biz dev list
export const createBizDev = (payload) => ({
  type: types.CREATE_CLIENT_STRATEGIST_BIZ_DEV,
  payload,
})

// get updata client
export const addUpdateClient = (payload) => ({
  type: types.ADD_UPDATE_CLIENT,
  payload,
})

// update client basic information
export const editClientInformation = (payload) => ({
  type: types.EDIT_CLIENT_INFORMATION,
  payload,
})

// create reffering client details
export const createRefferClients = (payload) => ({
  type: types.CREATE_REFFERING_CLIENT,
  payload,
})

// filter client data for clients list page and filter option
export const fetchClientData = (payload) => ({
  type: types.FETCH_FILTER_CLIENT_DATA,
  payload,
})

export const clearDashboardPhase = () => ({
  type: types.FETCH_CLIENT_DETAIL_FOR_DASH_BOARD_PHASE,
})

// clear reivew phase
export const clearReviewPhase = () => ({
  type: types.CLEAR_REIVEW_PHASE,
})

export const clearActivityHistoryPhase = () => ({
  type: types.CLEAR_ACTIVITY_HISTORY_PHASE,
})
