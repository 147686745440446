import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'
import * as types from './action-types'
import * as api from './api'
/*
    //////////////////////////////
  /// strategist epics ///
/////////////////////////////
*/
const getAdministratorDashboardDataEpic = (action$) =>
  action$.ofType(types.GET_ADMINISTRATION_DASHBOARD_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAdministratorDashboardData(action.payload))
      .flatMap((payload) => [
        {
          type: types.GET_ADMINISTRATION_DASHBOARD_DATA_SUCCESS,
          payload,
        },
      ])
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_ADMINISTRATION_DASHBOARD_DATA_ERROR,
          payload: { error },
        })
      )
  })

const getClosedClientsDataEpic = (action$) =>
  action$.ofType(types.CLOSED_CLIENT_PER_TOWN).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClosedClientsData(action.payload))
      .map((payload) => ({
        type: types.CLOSED_CLIENT_PER_TOWN_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CLOSED_CLIENT_PER_TOWN_ERROR,
          payload: { error },
        })
      )
  })

const getAccountingReportDataEpic = (action$) =>
  action$.ofType(types.GET_ACCOUNTING_REPORT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAccountingReportData(action.payload))
      .map((payload) => ({
        type: types.GET_ACCOUNTING_REPORT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_ACCOUNTING_REPORT_ERROR,
          payload: { error },
        })
      )
  })

const getUserByIdEpic = (action$) =>
  action$.ofType(types.GET_USER_BY_ID).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getUserById(action.payload))
      .map((payload) => ({
        type: types.GET_USER_BY_ID_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_USER_BY_ID_ERROR,
          payload: { error },
        })
      )
  })

const addUpdateStrategistEpic = (action$) =>
  action$.ofType(types.ADD_EDIT_STRATEGIST_CLIENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.addUpdateStrategist(action.payload))
      .map((payload) => ({
        type: types.ADD_EDIT_STRATEGIST_CLIENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.ADD_EDIT_STRATEGIST_CLIENT_ERROR,
          payload: { error },
        })
      )
  })
// get list of strategist assign to client by client id
const getClientStrategistEpic = (action$) =>
  action$.ofType(types.GET_CLIENT_STRATEGIST_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClientStrategist(action.payload))
      .map((payload) => ({
        type: types.GET_CLIENT_STRATEGIST_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_CLIENT_STRATEGIST_LIST_ERROR,
          payload: { error },
        })
      )
  })
// create strategist or assign new strategist to client
const createStrategistEpic = (action$) =>
  action$.ofType(types.CREATE_STRATEGIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createStrategist(action.payload))
      .map((payload) => ({
        type: types.CREATE_STRATEGIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_STRATEGIST_ERROR,
          payload: { error },
        })
      )
  })
// create strategist or assign new strategist to client
const changeClientStatusEpic = (action$) =>
  action$.ofType(types.CHANGE_CLIENT_STATUS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.changeClientStatus(action.payload))
      .map((payload) => ({
        type: types.CHANGE_CLIENT_STATUS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CHANGE_CLIENT_STATUS_ERROR,
          payload: { error },
        })
      )
  })

  const getBDSourceReportDataEpic = (action$) =>
    action$.ofType(types.GET_BDSOURCE_REPORT).mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getBDSourceReportData(action.payload))
        .map((payload) => ({
          type: types.GET_BDSOURCE_REPORT_SUCCESS,
          payload,
        }))
        .catch((error) =>
          Rx.Observable.of({
            type: types.GET_BDSOURCE_REPORT_ERROR,
            payload: { error },
          })
        )
    })
// combine strategist epic
export const strategistEpic = combineEpics(
  createStrategistEpic,
  getClientStrategistEpic,
  getClosedClientsDataEpic,
  addUpdateStrategistEpic,
  getAdministratorDashboardDataEpic,
  getAccountingReportDataEpic,
  getUserByIdEpic,
  changeClientStatusEpic,
  getBDSourceReportDataEpic
)
