import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  notes: [],
  fetchInteralNotePhase: INIT,
  deleteInteralNotePhase: INIT,
  createInteralNotePhase: INIT,
  editInteralNotePhase: INIT,
})

export const noteReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch internal notes by id */
    case types.FETCH_INTERAL_NOTES: {
      return state.set('error', null).set('fetchInteralNotePhase', LOADING)
    }
    case types.FETCH_INTERAL_NOTES_SUCCESS: {
      const notes = get(action, 'payload.data', {})
      return state
        .set('notes', notes)
        .set('error', null)
        .set('fetchInteralNotePhase', SUCCESS)
    }
    case types.FETCH_INTERAL_NOTES_ERROR: {
      return state.set('error', action.payload.error).set('fetchInteralNotePhase', ERROR)
    }

    /* create new internal notes */
    case types.CREATE_NEW_NOTE: {
      return state.set('error', null).set('createInteralNotePhase', LOADING)
    }
    case types.CREATE_NEW_NOTE_SUCCESS: {
      return state.set('error', null).set('createInteralNotePhase', SUCCESS)
    }
    case types.CREATE_NEW_NOTE_ERROR: {
      return state.set('error', action.payload.error).set('createInteralNotePhase', ERROR)
    }

    /* edit internal notes */
    case types.EDIT_INTERNAL_NOTE: {
      return state.set('error', null).set('editInteralNotePhase', LOADING)
    }
    case types.EDIT_INTERNAL_NOTE_SUCCESS: {
      return state.set('error', null).set('editInteralNotePhase', SUCCESS)
    }
    case types.EDIT_INTERNAL_NOTE_ERROR: {
      return state.set('error', action.payload.error).set('editInteralNotePhase', ERROR)
    }

    /*  delete internal notes */
    case types.DELETE_INTERAL_NOTE: {
      return state.set('error', null).set('deleteInteralNotePhase', LOADING)
    }
    case types.DELETE_INTERAL_NOTE_SUCCESS: {
      return state.set('error', null).set('deleteInteralNotePhase', SUCCESS)
    }
    case types.DELETE_INTERAL_NOTE_ERROR: {
      return state.set('error', action.payload.error).set('deleteInteralNotePhase', ERROR)
    }

    /* clear notes phase */
    case types.CLEAR_NOTES_PHASE: {
      return state
        .set('fetchInteralNotePhase', INIT)
        .set('createInteralNotePhase', INIT)
        .set('deleteInteralNotePhase', INIT)
    }

    default: {
      return state
    }
  }
}
