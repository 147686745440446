import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { Drawer, Modal, Button, notification } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { size, orderBy } from 'lodash';
import {
  CloseIcon,
  SortUpIcon,
  SortDownIcon,
  DeleteIcon
} from '../../../../../../../components/icons';
import './styles.scss';
import axios from 'axios';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import ConfirmModal from '../../../../Common/ConfirmModal';
import SchoolTableData from './partials/SchoolTableData';
import { logoutInvalidRequest } from '../../../../../../../utils/helper';
import { fetch } from '../../../../../../../utils';

const { confirm } = Modal;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export default class PublicSchoolsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      publicData: [],
      publicAttomSchools: [],
      filterText: '',
      selectedSchool: {
        InstitutionName: '',
        gradespancodebldgtext: '',
        GSTestRating: '',
        studentteacher: '',
        studentsnumberof: null
      },
      selectedSchoolInitial: {
        InstitutionName: '',
        gradespancodebldgtext: '',
        GSTestRating: '',
        studentteacher: '',
        studentsnumberof: null
      },
      editMode: false,
      isLoading: false,
      isDeleteModalOpen: false,
      addPublicSchoolModal: false
    };
    this.openAddPublicSchoolModal = this.openAddPublicSchoolModal.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    this.props.toggleLoading(true);
    this.props.getAttomSchools({ townId: parseInt(townId), type: 'public' });
    this.props.fetchSchools({ townId: parseInt(townId), type: 'public' });
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { townId }
      }
    } = props;

    let obj = {};
    if (get(props, 'getSchoolsPhase', false) === 'success') {
      props.clearTownPhase();
      obj.publicData = orderBy(get(props, 'schoolData.data', []), 'sort_by');
      obj.isLoading = false
      // props.fetchSchools({ townId: parseInt(townId), type: 'public' });
      props.toggleLoading(false);
    }

    if (get(props, 'getAttomSchoolsPhase', false) === 'success') {
      props.clearTownPhase();
      let schools = get(props, 'attomSchoolData.data', []);
      const isError = typeof schools === 'array' ? true : false;

      if (!isEmpty(schools) && !isError) {
        let setscl = schools.map((data, index) => {
          const attomSchoolId = data.Identifier.OBInstID;
          const attomSchoolName = data.School.InstitutionName
          const schoolExists = state.publicData.find(o => {
            return String(o.schoolAttomId) === String(attomSchoolId) || o.InstitutionName.toLowerCase() === attomSchoolName.toLowerCase();
          });

          if (schoolExists) {
            data.School.isAvailable = true;
          }
          return data
        });

        Promise.all(setscl)
      }

      obj.publicAttomSchools = !isError ? schools : [];
      obj.publicAttomSchoolsMain = !isError ? schools : [];
    }

    if (get(props, 'addPublicPhase', false) === 'success') {
      props.clearTownPhase();
      obj.addPublicSchoolModal = false;
      obj.visible = false
      obj.editMode = false;
      obj.selectedSchool = state.selectedSchoolInitial;
      notification.success({
        message: 'School Added Successfully'
      });

      props.fetchSchools({ townId: parseInt(townId), type: 'public' });
      props.getAttomSchools({ townId: parseInt(townId), type: 'public' });
    }

    if (get(props, 'addPublicPhase', false) === 'error') {
      notification.error({
        message: get(props, 'addPublicData.error.message')
      });

      props.clearTownPhase();
      obj.editMode = false;
      obj.visible = false;
      obj.isLoading = false;
      props.toggleLoading(false);
      obj.selectedSchool = state.selectedSchoolInitial;
      props.fetchSchools({ townId: parseInt(townId), type: 'public' });
    }

    if (get(props, 'editPublicPhase', false) === 'success') {
      props.clearTownPhase();
      obj.addPublicSchoolModal = false;
      obj.editMode = false;
      obj.selectedSchool = state.selectedSchoolInitial;
      props.fetchSchools({ townId: parseInt(townId), type: 'public' });
    }

    if (get(props, 'deletePublicPhase', false) === 'success') {
      props.clearTownPhase();
      props.toggleLoading(false);
      obj.isDeleteModalOpen = false;
      obj.isLoading = false;
      props.fetchSchools({ townId: parseInt(townId), type: 'public' });
    }

    return obj;
  }

  showAttomSchoolsDrawer = () => {
    this.setState({
      visible: true
    });
  };
  openAddPublicSchoolModal() {
    this.setState({ addPublicSchoolModal: true });
  }
  closeModal() {
    this.setState({
      addPublicSchoolModal: false,
      selectedSchool: this.state.selectedSchoolInitial,
      editMode: false
    });
  }
  onClose = () => {
    const { publicAttomSchools, publicAttomSchoolsMain } = this.state;

    this.setState({
      visible: false,
      filterText: '',
      publicAttomSchools: publicAttomSchoolsMain
    });
  };

  editSchool = values => {
    this.setState({
      editMode: true,
      selectedSchool: values,
      addPublicSchoolModal: true
    });
  };

  addSchool = values => {
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    this.setState({ addPublicSchoolModal: false });
    this.props.toggleLoading(true);
    let data = {
      school_id: get(values, 'id'),
      school_name: get(values, 'InstitutionName'),
      grades: get(values, 'gradespancodebldgtext'),
      ratio: get(values, 'studentteacher'),
      totalStudent: get(values, 'studentsnumberof'),
      type: 'public',
      town_id: parseInt(townId),
      rating: get(values, 'GSTestRating')
    };

    this.props.addPublicSchool(data);
  };

  updateSchool = async values => {
    const {
      match: {
        params: { townId }
      },
      editPublicSchool
    } = this.props;

    let data = {
      school_id: get(values, 'id'),
      school_name: get(values, 'InstitutionName'),
      grades: get(values, 'gradespancodebldgtext'),
      ratio: get(values, 'studentteacher'),
      totalStudent: get(values, 'studentsnumberof'),
      type: 'public',
      town_id: parseInt(townId),
      rating: get(values, 'GSTestRating')
    };

    editPublicSchool(data);
  };

  deleteSchool = data => {
    const {
      toggleLoading,
      deletePublicSchool,
      match: {
        params: { townId }
      }
    } = this.props;
    let data1 = {
      school_id: data.id,
      town_id: parseInt(townId)
    };

    this.setState({ isDeleteModalOpen: true, selectedSchoolId: data.id });
  };

  deleteSchoolConfirm = () => {
    let data = this.state.selectedSchoolId;
    const {
      toggleLoading,
      deletePublicSchool,
      match: {
        params: { townId }
      }
    } = this.props;
    let data1 = {
      school_id: data,
      town_id: parseInt(townId)
    };

    toggleLoading(true);
    this.setState({ isLoading: true });
    deletePublicSchool(data1);
  };

  async getSchoolDetails(data, index) {
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const attomSchoolId = data.Identifier.OBInstID;

    fetch(`${SUBURBANJUNGLE_API_HOST}/attom-schools/attom-school-detail?attomSchoolId=${attomSchoolId}&town_id=${townId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          return res.json();
        })
        .then(payload => {

          const detail = payload

          if (detail && detail.data && detail.data.schoolDetail) {
            const enrollment = detail.data.schoolDetail.SchoolEnrollment;
            let publicAttomSchools = this.state.publicAttomSchools;
            const currentSchool = publicAttomSchools[index];
            currentSchool.School.Studentsnumberof = enrollment.Studentsnumberof;
            currentSchool.School.Studentteacher = enrollment.Studentteacher;
            currentSchool.School.gradespancodebldgtext =
              detail.data.schoolDetail.SchoolSummary.gradespancodebldgtext;
            publicAttomSchools[index] = currentSchool;
            this.setState({ publicAttomSchools });
            this.forceUpdate();
          }
        })
        .catch(error => {
          throw error;
        });
  }

  filterSchools = async value => {
    const { publicAttomSchools, publicAttomSchoolsMain } = this.state;
    let filtered;
    if (!value) {
      filtered = publicAttomSchoolsMain;
    } else {
      filtered = await filter(
        publicAttomSchoolsMain,
        search =>
          search.School['InstitutionName']
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ publicAttomSchools: filtered, filterText: value });
  };

  addPrivateAttom = async (data, index) => {
    this.setState({ isLoading: true });
    this.props.toggleLoading(true);
    const {
      match: {
        params: { townId }
      },
      addPublicSchool
    } = this.props;
    // if (data.School && !data.School.Studentsnumberof) {
    //   const detail = await this.getSchoolDetails(data, index);
    // }
    let attom = data.School;
    let data1 = {
      school_id: data.Identifier.OBInstID,
      school_name: attom.InstitutionName,
      grades: attom.gradespancodebldgtext,
      type: 'public',
      town_id: parseInt(townId),
      rating: attom.GSTestRating,
      totalStudent: attom.Studentsnumberof,
      ratio: attom.Studentteacher,
      geocodinglatitude: attom.geocodinglatitude,
      geocodinglongitude: attom.geocodinglongitude,
      locationcity: attom.locationcity,
      zip54: attom.zip54
    };
    const publicAttomSchools = this.state.publicAttomSchools;
    publicAttomSchools[index].School.isAvailable = true;
    this.setState({
      isDateLoading: true,
      modalIsOpen: false,
      openSideBar: false,
      publicAttomSchools
    });
    addPublicSchool(data1);
  };

  fetchSchoolTown = () => {
    const { match: { params: { townId } }} = this.props;
    this.props.fetchSchools({ townId: parseInt(townId), type: 'public' });
  }
  render() {
    const {
      editMode,
      selectedSchool,
      publicData,
      publicAttomSchools,
      isLoading,
      isDeleteModalOpen
    } = this.state;

    return (
      <div>
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deleteSchoolConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        <div className="row mb__15">
          <div className="col-md-12 text-right">
            <button
              className="btn btn__btn btn-dark w__180 mr__15"
              onClick={this.showAttomSchoolsDrawer}
            >
              Attom Schools
            </button>
            {/*<button*/}
            {/*  className="btn btn__btn btn-dark w__180"*/}
            {/*  onClick={this.openAddPublicSchoolModal}*/}
            {/*>*/}
            {/*  Add Public School*/}
            {/*</button>*/}
          </div>
        </div>
        <SchoolTableData
          publicData={publicData}
          fetchSchoolTown={this.fetchSchoolTown}
          editSchool={this.editSchool}
          deleteSchool={this.deleteSchool}
          updateSchoolTownPhase={this.props.updateSchoolTownPhase}
          clearTownsPhase={this.props.clearTownsPhase}
          updateSchoolOrder={this.props.updateSchoolOrder}
        />

        <Drawer
          placement="right"
          closable={false}
          width="600"
          onClose={this.onClose}
          visible={this.state.visible}
          className="drawer__main"
        >
          <div className="drawer__header">
            <h4 className="drawer__header__title"> Attom Public Schools..</h4>
            <p className="drawer__header__subtitle">
              {get(this, 'props.town.name')}
            </p>
            <div
              className="drawer__header__close cursor__pointer"
              onClick={this.onClose}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="search__form attom__schools__search">
            <input
              type="text"
              className="form-control search__form__input"
              name="firstName"
              value={this.state.filterText}
              onChange={e => this.filterSchools(e.target.value)}
              placeholder="Search for a school... "
              required
            />
          </div>
          <div className="attom__schools">
            <ul className="attom__public__schools__list">
              {!isEmpty(publicAttomSchools) &&
                publicAttomSchools.map((school, i) => {
                  const geocodinglatitude = school.School.geocodinglatitude;
                  const geocodinglongitude = school.School.geocodinglongitude;
                  const zip54 = school.School.zip54;
                  const filtered = publicData.filter(o => {
                    return (
                      (String(o.geocodinglatitude) === String(geocodinglatitude))
                      && (String(o.geocodinglongitude) === String(geocodinglongitude))
                      && (String(o.zip54) === String(zip54)))
                  });
                  return (
                    <li
                      key={i}
                      className={
                        get(school, 'School.isAvailable', false) ? 'active' : ''
                      }
                    >
                      <span>Name:</span>
                      {get(school, 'School.InstitutionName')}
                      <br />
                      <span>Address:</span>
                      {get(school, 'School.locationaddress')}
                      <br />
                      <span>City:</span> {get(school, 'School.locationcity')}
                      <br />
                      <span>Zipcode:</span> {get(school, 'School.zip54')}
                      <br />
                      <span>Rating:</span> {get(school, 'School.GSTestRating')}
                      <br />
                      {school.School && school.School.Studentteacher ? (
                        <>
                          <span>Grade:</span>{' '}
                          {get(school, 'School.gradespancodebldgtext')}
                          <br />
                          <span>Ratio:</span>{' '}
                          {get(school, 'School.Studentteacher')}
                          <br />
                          <span>No. of students:</span>{' '}
                          {get(school, 'School.Studentsnumberof')}
                        </>
                      ) : (
                        <span
                          onClick={this.getSchoolDetails.bind(this, school, i)}
                          className="cursor__pointer"
                        >
                          View More...
                        </span>
                      )}
                      <div onClick={() =>
                        !get(school, 'School.isAvailable', false) &&
                        this.addPrivateAttom(school, i)
                      } className="round-icon"
                         style={{
                           background: get(school, 'School.isAvailable', false) && 'black'
                         }}
                      ></div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="attom__schools__bottom">
            {/* <button
              type="button"
              className="btn btn__btn btn__lg btn-dark w__100"
            >
              Save
            </button> */}
          </div>
        </Drawer>

        <ReactModal
          isOpen={this.state.addPublicSchoolModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Add Public School"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {editMode ? 'Edit' : 'Add'} Public School
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal.bind(this)}
              >
                <CloseIcon />
              </button>
            </div>
            <Formik
              enableReinitialize
              initialValues={selectedSchool}
              validationSchema={this.props.ValidationSchemas.addSchoolValidation}
              onSubmit={values => {
                if (editMode) {
                  this.updateSchool(values);
                }

                if (!editMode) {
                  this.addSchool(values);
                }
              }}
            >
              {({
                isSubmitting,
                errors,
                setFieldValue,
                values,
                handleBlur,
                handleChange
              }) => (
                <Form noValidate>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        id="InstitutionName"
                        name="InstitutionName"
                        className="form-control material-textfield-input textfield-input-lg"
                        required
                      />
                      <label className="material-textfield-label label-lg">
                        Name
                      </label>
                      <ErrorMessage className="invalid-feedback" name="InstitutionName" component="div"/>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="gradespancodebldgtext"
                            name="gradespancodebldgtext"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Grades
                          </label>
                          <ErrorMessage className="invalid-feedback" name="gradespancodebldgtext" component="div"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="GSTestRating"
                            name="GSTestRating"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Rating
                          </label>
                          <ErrorMessage className="invalid-feedback" name="GSTestRating" component="div"/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="studentteacher"
                            name="studentteacher"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Student Teacher Ratio
                          </label>
                          <ErrorMessage className="invalid-feedback" name="studentteacher" component="div"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="number"
                            min={0}
                            id="studentsnumberof"
                            name="studentsnumberof"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            # of Students
                          </label>
                          <ErrorMessage className="invalid-feedback" name="studentsnumberof" component="div"/>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn__btn btn__lg btn-dark w__100"
                    >
                      {editMode ? 'Update' : 'Add'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ReactModal>
      </div>
    );
  }
}
