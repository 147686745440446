import React, { PureComponent } from 'react'
import './styles.scss'
import { CaretRightIcon, NotTouchedIcon, CallIcon, CalendarOtherIcon, TourIcon } from '../../../../../../components/icons'
import moment from 'moment'

export default function AgentSectionComponent({ dashboardData, ...props }) {
  // go to appointment with filter today data
  const goToAppointment = () => props.history.push({
    pathname: `/strategist/agent-appointments`,
    state: { filter: 'today'}
  })
  // go to all client page with filter option
  const goToYourAgent = (filter) => props.history.push({
    pathname: `/strategist/your-agents`,
    state: { filter }
  })
  return (
    <div className="row">
        
    <div className="col-md-6">  
      <div className="card__tile" onClick={goToAppointment}>
        <div className={dashboardData.todayAgentAppointment !== 0 ? "card__tile__icon grey" : "card__tile__icon red"}>
          <CalendarOtherIcon className="calendar-icon" />
        </div>
        <div className="card__tile__content">
          <div className="card__tile__title">Agents with Appointments today</div>
          <div className="card__tile__subtitle"># of appointments agents have with clients today</div>
        </div>
        <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.todayAgentAppointment}</span><CaretRightIcon /> </div>
      </div>

      <div className="card__tile" onClick={() => goToYourAgent('neverScheduledWithAgent')}>
        <div className={dashboardData.neverScheduledWithAgent !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
          <CalendarOtherIcon className="calendar-icon"/>
        </div>
        <div className="card__tile__content">
          <div className="card__tile__title">Never Scheduled with Agent</div>
          <div className="card__tile__subtitle">Clients that have never had a schedule with agent</div>
        </div>
        <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.neverScheduledWithAgent}</span><CaretRightIcon /> </div>
      </div>
      
      <div
       className="card__tile" onClick={() => goToYourAgent('agentNotTouchedIn4Week')}>
        <div className={dashboardData.agentNotTouchedIn4Week !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
          <NotTouchedIcon />
        </div>
        <div className="card__tile__content">
          <div className="card__tile__title">Agent: Not Touched in 4 Weeks </div>
          <div className="card__tile__subtitle">Clients that agents havn’t touched since <span className="text-black">{moment(dashboardData.agentNotTouchedIn4WeekDate).format("MM/DD/YYYY")}</span> </div>
        </div>
        <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.agentNotTouchedIn4Week}</span><CaretRightIcon /> </div>
      </div>
    </div>

    <div className="col-md-6">
      <div className="card__tile" onClick={() => goToYourAgent('noInitialOrFollowUpAgentCall')}>
        <div className={dashboardData.noInitialOrFollowUpAgentCall !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
          <CallIcon />
        </div>
        <div className="card__tile__content">
          <div className="card__tile__title">No initial or follow up agent call </div>
          <div className="card__tile__subtitle">Clients that have no initial or follow up call with agent </div>
        </div>
        <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.noInitialOrFollowUpAgentCall}</span><CaretRightIcon /> </div>
      </div>

      <div className="card__tile" onClick={() => goToYourAgent('noInitialorFollowUpAgentTownTour')}>
        <div className={dashboardData.noInitialorFollowUpAgentTownTour !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
          <TourIcon />
        </div>
        <div className="card__tile__content">
          <div className="card__tile__title">No initial or follow up agent town tour</div>
          <div className="card__tile__subtitle">Clients that have no initial or follow up tour with agent  </div>
        </div>
        <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.noInitialorFollowUpAgentTownTour}</span><CaretRightIcon /> </div>
      </div>
    </div>
  </div>
  )
}
