import { connect } from 'react-redux'
import {fetchUser,  handleSignOut} from '../../store/user/duck'
import {addUpdateClient, getFilterParams, clearPhase} from '../../store/dashboard/duck'
import AddClientsForm from './component'
const addclients = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    addUpdateClientdata: state.dashboard.addUpdateClientdata,
    clientError: state.dashboard.error,
    phase: state.user.phase,
    addUpdateClientPhase: state.dashboard.addUpdateClientPhase,
  }),
  // Map actions to props
  {
    fetchUser,
    getFilterParams,
    addUpdateClient,
    clearPhase,
    handleSignOut
  }
)(AddClientsForm)
export default addclients
