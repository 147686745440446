import { connect } from 'react-redux'
import {loginUser, handleSignOut} from '../../store/user/duck'
import {getStrategistApt, 
        getStrategistAvail, 
        getFilterParams,
        addStrategistApt,
        clearPhase,
        clearFilterPhase
      } from '../../store/dashboard/duck'
import CalendarForm from './component'
import { getAllClients, 
         getAppointments,
         agentGetUsers,
         getAgentAvailability,
         addAgentAppointment,
         clearAgentPhase
       } from '../../store/agent/duck'
const Calendar = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase,
    strategistAptData: state.dashboard.strategistAptData,
    allclientData: state.agent.allclientData,
    allClientPhase: state.agent.allClientPhase,
    appointmentData: state.agent.appointmentData,
    strategistAvailabilityData: state.dashboard.strategistAvailabilityData,
    filterParams: state.dashboard.filterParams,
    addStrategyAptPhase: state.dashboard.addStrategyAptPhase,
    usersData: state.agent.usersData,
    availabilityData: state.agent.availabilityData,
    addAppointmentPhase: state.agent.addAppointmentPhase,
    strategistMessage: state.dashboard.strategistMessage,
    filterParamsPhase: state.dashboard.filterParamsPhase
  }),
  // Map actions to props
  {
    loginUser,
    getStrategistApt,
    handleSignOut,
    getAllClients,
    getAppointments,
    getStrategistAvail,
    addStrategistApt,
    getFilterParams,
    agentGetUsers,
    getAgentAvailability,
    addAgentAppointment,
    clearAgentPhase,
    clearPhase,
    clearFilterPhase
  }
)(CalendarForm)
export default Calendar
