import React, { Fragment, PureComponent } from 'react';
import { DatePicker, Row, Col, Table } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Cookies from 'universal-cookie';
import ResizeObserver from 'rc-resize-observer';
const cookies = new Cookies()


import {
  SortUpIcon,
  SortDownIcon,
  DownCaretIcon,
  CaretRightIcon
} from '../../../../../../components/icons';

import './styles.scss';

export default class ListComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      clientData: []
    };
  }

  componentDidMount() {
    const user = cookies.get('user')
    this.setState({ user })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { clientData: get(nextProps, 'clientList') };
  }

  getFollowupDateClass(follow_up) {
    let overDueRedClass = 'datepicker__follow-up-date'
    if (follow_up && follow_up[0].follow_up_date && follow_up[0].follow_up_date !== '') {
      const timeZone = get(this.state.user, 'time_zone', 'America/New_York')
      let followupDate = moment(follow_up[0].follow_up_date).format('YYYY-MM-DD')
      let nowDate = moment.utc().tz(timeZone).format('YYYY-MM-DD')
      let overDueRedClass = ""

      if (nowDate === followupDate) {
        // this is past date
        overDueRedClass = 'datepicker__follow-up-date-red'
      } else {
        overDueRedClass = 'datepicker__follow-up-date'
      }
    }
    return overDueRedClass
  }

  disabledDate = (current) => {
    let parts1 = current.format('DD/MM/YYYY').split('/');
    let parts2 = moment().format('DD/MM/YYYY').split('/');
    let latest = true;
    if (parseInt(parts1[2]) > parseInt(parts2[2])) {
      latest = false;
    } else if (parseInt(parts1[2]) == parseInt(parts2[2])) {
      if (parseInt(parts1[1]) > parseInt(parts2[1])) {
        latest = false;
      } else if (parseInt(parts1[1]) == parseInt(parts2[1])) {
        if (parseInt(parts1[0]) >= parseInt(parts2[0])) {
          latest = false;
        } 
      }
    }
    return latest;
  }
  

  render() {
    const { clientData } = this.state;
    const { formatNumber, routeParam } = this.props;
    const allClients = get(clientData, 'users', []);
    const urlToRouteTo = get(this.props, 'urlToRouteTo', '')

    const clientListHtml = !isEmpty(allClients) ? (
      allClients.map((client, i) => {
        const strategistNameArr =
          !isEmpty(get(client, 'strategists', [])) &&
          get(client, 'strategists').map(strat => {
            return strat.strategist_full_name;
          });
        let overDueRedClass = 'datepicker__follow-up-date'

        if (client.follow_up_date && client.follow_up_date.length === 1 && client.follow_up_date[0].follow_up_date && client.follow_up_date[0].follow_up_date !== '') {
          const timeZone = get(this.state.user, 'time_zone', 'America/New_York')
          let followupDate = moment.utc(client.follow_up_date[0].follow_up_date).format('YYYY-MM-DD')
          let nowDate = moment().tz(timeZone).format('YYYY-MM-DD')
          if (followupDate === nowDate || followupDate < nowDate) {
            overDueRedClass = 'datepicker__follow-up-date-red'
          } else {
            overDueRedClass = 'datepicker__follow-up-date'
          }
        }
        return (
          <tr key={"tasklist__"+i}>
            <td className="cursor__pointer" onClick={() => this.props.history.push(`${urlToRouteTo}/client-details/${client.client_id}`)}>
              {get(client, 'client_full_name')}
            </td>
            <td className="cursor__pointer" onClick={() => this.props.history.push(`${urlToRouteTo}/client-details/${client.client_id}`)}>
              $
              {get(client, 'price_min', 0)
                ? formatNumber(get(client, 'price_min', 0))
                : 0}{' '}
              - $
              {get(client, 'price_max', 0)
                ? formatNumber(get(client, 'price_max', 0))
                : 0}
              <div className="small text-green">
                <b>Strategist: </b>
                {strategistNameArr && strategistNameArr.join(', ')}
              </div>
            </td>
            <td onClick={() => this.props.history.push(`${urlToRouteTo}/client-details/${client.client_id}`)} className="cursor__pointer">{get(client, 'status')}</td>
            <td>
              {client.follow_up_date && client.follow_up_date.length === 1 && (
                <div className="btn__follow-up-date">
                  <div className="follow-up-date-label">Follow up date</div>
                  <DatePicker
                    className={overDueRedClass}
                    dropdownClassName="datepicker__overlay__follow-up-date"
                    onChange={date =>
                      this.props.updateFollowUpDate(
                        date,
                        client.client_id,
                        client.follow_up_date[0].strategist_id,
                        i
                      )
                    }
                    defaultValue={
                      client &&
                      client.follow_up_date &&
                      client.follow_up_date[0].follow_up_date
                    }
                    value={
                      client &&
                      client.follow_up_date &&
                      client.follow_up_date[0].follow_up_date &&
                      moment.utc(client.follow_up_date[0].follow_up_date)
                    }
                    format="MM/DD/YYYY"
                    inputReadOnly={true}
                    allowClear={false}
                    placeholder="N/A"
                    disabledDate={this.disabledDate.bind(this)}
                  />

                  <DownCaretIcon className="caret-icon" />
                </div>
              )}
              {client.follow_up_date && client.follow_up_date.length >= 2 && (
                <button className="btn btn__link__gray">
                  Multiple Follow Up Dates{' '}
                </button>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" className="blank__table__state">
          No Results Found
        </td>
      </tr>
    );


    const clientListMobile =
      !isEmpty(allClients) &&
      allClients.map((client, i) => {
        const strategistNameArr =
          !isEmpty(get(client, 'strategists', [])) &&
          get(client, 'strategists').map(strat => {
            return strat.strategist_full_name;
          });
        let overDueRedClass = 'datepicker__follow-up-date'

        if (client.follow_up_date && client.follow_up_date.length === 1 && client.follow_up_date[0].follow_up_date && client.follow_up_date[0].follow_up_date !== '') {
          const timeZone = get(this.state.user, 'time_zone', 'America/New_York')
          let followupDate = moment.utc(client.follow_up_date[0].follow_up_date).format('YYYY-MM-DD')
          let nowDate = moment().tz(timeZone).format('YYYY-MM-DD')
          if (followupDate === nowDate) {
            overDueRedClass = 'datepicker__follow-up-date-red'
          } else {
            overDueRedClass = 'datepicker__follow-up-date'
          }
        }  
        return (
          <tr key={"tasklist__mob__"+i}>
            <td onClick={() => this.props.history.push(`${urlToRouteTo}/client-details/${client.client_id}`)}>
              <div className="client__name">
                {get(client, 'client_full_name')}
              </div>
              <div className="client__budget">
                $
                {get(client, 'price_min', 0)
                  ? formatNumber(get(client, 'price_min', 0))
                  : 0}{' '}
                - $
                {get(client, 'price_max', 0)
                  ? formatNumber(get(client, 'price_max', 0))
                  : 0}
              </div>
            </td>
            <td>
              <span className="mob__follow__date">
                {client.follow_up_date && client.follow_up_date.length === 1 && (
                  <div className="btn__follow-up-date">
                    <div className="follow-up-date-label">Follow up date</div>
                    <DatePicker
                      className={overDueRedClass}
                      dropdownClassName="datepicker__overlay__follow-up-date"
                      onChange={date =>
                        this.props.updateFollowUpDate(
                          date,
                          client.client_id,
                          client.follow_up_date[0].strategist_id,
                          i
                        )
                      }
                      defaultValue={
                        client &&
                        client.follow_up_date &&
                        client.follow_up_date[0].follow_up_date
                      }
                      value={
                        client &&
                        client.follow_up_date &&
                        client.follow_up_date[0].follow_up_date &&
                        moment.utc(client.follow_up_date[0].follow_up_date)
                      }
                      format="MM/DD/YYYY"
                      inputReadOnly={true}
                      allowClear={false}
                      placeholder="N/A"
                      disabledDate={current => {
                        return current && current < moment.utc().startOf('day');
                      }}
                    />
                    <DownCaretIcon className="caret-icon" />
                  </div>
                )}
              {client.follow_up_date && client.follow_up_date.length >= 2 && (
                <button className="btn btn__link__gray">
                  Multiple Follow Up Dates{' '}
                </button>
              )}
                <span>
                  <CaretRightIcon className="right-icon" />
                </span>
              </span>
            </td>
          </tr>
        );
      });

    return (
      <div>
        {/* This component for mobile only */}
        <div className="mob__view__container mob__view__control">
          {!isEmpty(allClients) ? (
            <table className="table mob__custom__table">
              <tbody>{clientListMobile}</tbody>
            </table>
          ) : (
            <div className="blank__table__state">No Clients</div>
          )}
        </div>
        {/* End component for mobile only */}
        <div className="desktop__view">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <table className="table custom-table">
                  {!isEmpty(allClients) && (
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.props.sortData('full_client_name')
                          }
                          className="cursor__pointer"
                        >
                          <span className="table-column-title">
                            Client Name
                          </span>
                          <span className="table-column-sorter">
                            {get(
                              this,
                              'props.currentSortCondition.sort',
                              ''
                            ) === 'full_client_name' &&
                            get(
                              this,
                              'props.currentSortCondition.sort_value'
                            ) ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )}
                          </span>
                        </th>
                        <th
                          onClick={() => this.props.sortData('price_min')}
                          className="cursor__pointer"
                        >
                          <span className="table-column-title">Budget</span>
                          <span className="table-column-sorter">
                            {get(
                              this,
                              'props.currentSortCondition.sort',
                              ''
                            ) === 'price_min' &&
                            get(
                              this,
                              'props.currentSortCondition.sort_value'
                            ) ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )}
                          </span>
                        </th>

                        <th
                          onClick={() => this.props.sortData('status')}
                          className="cursor__pointer"
                        >
                          <span className="table-column-title">Status</span>
                          <span className="table-column-sorter">
                            {get(
                              this,
                              'props.currentSortCondition.sort',
                              ''
                            ) === 'status' &&
                            get(
                              this,
                              'props.currentSortCondition.sort_value'
                            ) ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )}
                          </span>
                        </th>
                        <th
                          onClick={() => this.props.sortData('follow_up_date')}
                          className="cursor__pointer"
                        >
                          <span className="table-column-title">Follow Up</span>
                          <span className="table-column-sorter">
                            {get(
                              this,
                              'props.currentSortCondition.sort',
                              ''
                            ) === 'follow_up_date' &&
                            get(
                              this,
                              'props.currentSortCondition.sort_value'
                            ) ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )}
                          </span>
                        </th>
                      </tr>
                    </thead>
                  )}

                  <tbody>{clientListHtml}</tbody>
                </table> */}
                {/* <div className="blank__table__state">No Clients</div> */}

                <Table
                  className="virtual-table modify-table"
                  dataSource={allClients}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'antd-table-row-dark' : ''
                  }
                  columns={getColumns(
                    this.props,
                    this.state.user,
                    this.disabledDate
                  )}
                  pagination={false}
                  // scroll={{ x: 'max-content' }}
                  rowKey={(record) => record['client_id']}
                  onRow={(client, rowIndex) => {
                    return {
                      onClick: (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.props.history.push(`${urlToRouteTo}/client-details/${client.client_id}`);
                      }, // click row
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const getColumns = (props, user, disabledDate) => {
	const columns = [
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => props.sortData('full_client_name')}>
              <Col xs={12}>Client Name</Col>
              <Col xs={12}>
                {get(props, 'currentSortCondition.sort', '') ===
                  'full_client_name' &&
                get(props, 'currentSortCondition.sort_value') ? (
                  <SortUpIcon />
                ) : (
                  <SortDownIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: 'client_full_name',
      // width: 220,
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => props.sortData('price_min')}>
              <Col xs={12}>Budget</Col>
              <Col xs={12}>
                {get(props, 'currentSortCondition.sort', '') === 'price_min' &&
                get(props, 'currentSortCondition.sort_value') ? (
                  <SortUpIcon />
                ) : (
                  <SortDownIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: 'price_range',
      render: (text, client, index) => {
        const strategistNameArr =
          !isEmpty(get(client, 'strategists', [])) &&
          get(client, 'strategists').map((strat) => {
            return strat.strategist_full_name;
          });

        const priceBracketString = get(client, 'price_min', 0) === 25000000 ? 
          `$${props.formatNumber(get(client, 'price_min'))} +` :
          `$${props.formatNumber(get(client, 'price_min'))} - $${props.formatNumber(get(client, 'price_max'))}`

        return (
          <Fragment>
            {priceBracketString}
            <div className="small text-green">
              <b>Strategist: </b>
              {strategistNameArr && strategistNameArr.join(', ')}
            </div>
          </Fragment>
        )
      },
      // width: 250,
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => props.sortData('status')}>
              <Col xs={12}>Status</Col>
              <Col xs={12}>
                {get(props, 'currentSortCondition.sort', '') ===
                  'status' &&
                get(props, 'currentSortCondition.sort_value') ? (
                  <SortUpIcon />
                ) : (
                  <SortDownIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: 'status',
      // width: 200,
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => props.sortData('follow_up_date')}>
              <Col xs={12}>Follow Up</Col>
              <Col xs={12}>
                {get(props, 'currentSortCondition.sort', '') ===
                  'follow_up_date' &&
                get(props, 'currentSortCondition.sort_value') ? (
                  <SortUpIcon />
                ) : (
                  <SortDownIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: 'follow_up_date',
      render: (text, client, index) => {
        let overDueRedClass = 'datepicker__follow-up-date'
        if (client.follow_up_date && client.follow_up_date.length === 1 && client.follow_up_date[0].follow_up_date && client.follow_up_date[0].follow_up_date !== '') {
          const timeZone = get(user, 'time_zone', 'America/New_York')
          let followupDate = moment.utc(client.follow_up_date[0].follow_up_date).format('YYYY-MM-DD')
          let nowDate = moment().tz(timeZone).format('YYYY-MM-DD')
          if (followupDate === nowDate || followupDate < nowDate) {
            overDueRedClass = 'datepicker__follow-up-date-red'
          } else {
            overDueRedClass = 'datepicker__follow-up-date'
          }
        }
        return (
          <Fragment>
            <div onClick={(event) => event.stopPropagation()}>
              {client.follow_up_date && client.follow_up_date.length === 1 && (
                <div className="btn__follow-up-date">
                  <div className="follow-up-date-label">Follow up date</div>
                  <DatePicker
                    className={overDueRedClass}
                    dropdownClassName="datepicker__overlay__follow-up-date"
                    onChange={(date) =>
                      props.updateFollowUpDate(
                        date,
                        client.client_id,
                        client.follow_up_date[0].strategist_id,
                        index
                      )
                    }
                    defaultValue={
                      client &&
                      client.follow_up_date &&
                      client.follow_up_date[0].follow_up_date
                    }
                    value={
                      client &&
                      client.follow_up_date &&
                      client.follow_up_date[0].follow_up_date &&
                      moment.utc(client.follow_up_date[0].follow_up_date)
                    }
                    format="MM/DD/YYYY"
                    inputReadOnly={true}
                    allowClear={false}
                    placeholder="N/A"
                    disabledDate={disabledDate}
                  />

                  <DownCaretIcon className="caret-icon" />
                </div>
              )}
              {client.follow_up_date && client.follow_up_date.length >= 2 && (
                <button className="btn btn__link__gray">
                  Multiple Follow Up Dates{' '}
                </button>
              )}
            </div>
          </Fragment>
        );
      },
    },
  ];

  return columns;
}