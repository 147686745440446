import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import config from "../../config";
import _ from "lodash";
import $ from 'jquery'
import { Redirect, Link } from "react-router-dom";
import Snackbar from "material-ui/Snackbar";
import SideNavigation from "../../components/sideNavigation";
import TopNavigation from "../TopNavigation/container";
import styles from "./styles.scss";
// import monthpicker from './monthpicker.css'
import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import Picker from "react-month-picker";
import Modal from "react-modal";
import {logoutInvalidRequest, getHostname} from "../../utils/helper";
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    marginTop: "auto",
    marginBottom: "auto",
    overflowY: "auto",
    width: "500px",
    padding: "25px 25px",
    maxHeight: "95%",
    maxWidth: "95%"
  }
};

let questionsList = [
  { no: 1, logic: "filter", weightage: "10", shortq: "Feel of Town", q: "What kind of feel are you looking for…", "type": "text", "dbValue": "urban_suburban", options: [{label: "", value: ""}, {label: "Urban", value: "urban"}, {label: "Suburban", value: "suburban"}, {label: "Country", value: "country"}] },
  { no: 2, logic: "weightage", weightage: "7", shortq: "Prefer the moms", q: "What would you prefer the moms to be in town:", "type": "text", "dbValue": "sahm_wohm_mix", options: [{label: "", value: ""}, {label: "SAHM", value: "sahm"}, {label: "WOHM", value: "wohm"}, {label: "Mix", value: "mix"}] },
  { no: 3, logic: "weightage", weightage: "4", shortq: "Type A types of folks", q: "Are you looking for Type A types of folks", "type": "boolean", "dbValue": "type_a", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 4, logic: "weightage", weightage: "7", shortq: "Fancy or laid back:", q: "Are you looking for fancy or laid back?",
    "type": "multi",
    "dbValue": "",
    "id": "listLaidBack",
    options: [
              { label: 'Laid Back', value: 'laid_back' },
              { label: 'Fancy', value: 'fancy' }
            ]
  },
  { no: 5, logic: "weightage", weightage: "8", shortq: "Diversity", q: "How important is it for you to have diversity in town?", "type": "boolean", "dbValue": "diversity", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 6, logic: "weightage", weightage: "8", shortq: "Occupation:", q: "The occupation of most in your town would ideally be",
    "type": "multi",
    "dbValue": "",
    "id": "listOccupation",
    options: [{ label: 'White Collar', value: 'white_collar' },
            { label: 'Blue Collar', value: 'blue_collar' },
            { label: 'Finance Legal', value: 'finance_legal' },
            { label: 'Medical', value: 'medical' },
            { label: 'Entrepreneur Self Employed', value: 'entrepreneur_self_employed' },
            { label: 'Tech', value: 'tech' },
            { label: 'Creative', value: 'creative' }]
  },
  { no: 7, logic: "weightage", weightage: "2", shortq: "Sporty/Artsy:", q: "The type of town think would be great for us would be",
    "type": "multi",
    "dbValue": "",
    "id": "listSoprtyArtsy",
    options: [
              { label: 'Sporty', value: 'town_personality_sporty' },
              { label: 'Creative/Artsy', value: 'town_personality_artsy' }
            ]
  },
  { no: 8, logic: "weightage", weightage: "8", shortq: "Taxes", q: "What type of taxes would you be ok with", "type": "text", "dbValue": "taxes", options: [{label: "", value: ""}, {label: "High", value: "high"}, {label: "Medium", value: "medium"}, {label: "Low", value: "low"}] },
  { no: 9, logic: "filter", weightage: "9", shortq: "School system type", q: "What type of school system are you looking for", "type": "text", "dbValue": "schools", options: [{label: "", value: ""}, {label: "Top", value: "top"}, {label: "Middle", value: "middle"}, {label: "Low", value: "low"}] },
  { no: 10, logic: "filter", weightage: "8", shortq: "Language Immersion", q: "Language Immersion program is a must for me", "type": "boolean", "dbValue": "language_immersion", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 11, logic: "filter", weightage: "8", shortq: "Strong special needs program", q: "I need a strong special needs program", "type": "boolean", "dbValue": "strong_special_needs_program", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 12, logic: "filter", weightage: "8", shortq: "Full or half day kindergarten", q: "Do you need full day or ½ day kindergarten?", "type": "text", "dbValue": "kindergarten", options: [{label: "", value: ""}, {label: "Full", value: "full"}, {label: "Half", value: "half"}] },
  { no: 13, logic: "filter", weightage: "8", shortq: "Busing", q: "Do you need busing?", "type": "boolean", "dbValue": "busing", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 14, logic: "weightage", weightage: "3", shortq: "Universal Pre-K", q: "Do you need universal pre-k", "type": "boolean", "dbValue": "universal_pre_k", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 15, logic: "filter", weightage: "8", shortq: "Transport By:", q: "I would commute using the following modes of transportation",
    "type": "multi",
    "dbValue": "",
    "id": "listTransportation",
    options: [{ label: 'Train', value: 'trans_mode_train' },
            { label: 'Bus', value: 'trans_mode_bus' },
            { label: 'Car', value: 'trans_mode_car' },
            { label: 'Walk', value: 'trans_mode_walk' },
            { label: 'Bike', value: 'trans_mode_bike' },
            { label: 'Ferry', value: 'trans_mode_ferry' }]
  },
  // { no: 16, q: "I would be willing to commute:", "type": "custom", "dbValue": "commute_30_to_less,commute_30_to_45,commute_45_to_60,commute_60_to_high", options: [{label: "Up to 30 minutes", value: "commute_30_to_less"}, {label: "Up to 45 minutes", value: "commute_30_to_45"}, {label: "Up to 60 mintues", value: "commute_45_to_60"}, {label: "1 hr +", value: "commute_60_to_high"}] },// custom
  { no: 16, logic: "filter", weightage: "8", shortq: "I would be willing to commute:", q: "I would be willing to commute:",
    "type": "multi",
    "dbValue": "",
    "id": "listCommuteTime",
    options: [{ label: 'Up to 30 minutes', value: 'commute_30_to_less' },
              { label: 'Up to 45 minutes', value: 'commute_30_to_45' },
              { label: 'Up to 60 minutes', value: 'commute_45_to_60' },
              { label: '1 hr +', value: 'commute_60_to_high' }
            ]
  },
  { no: 17, logic: "weightage", weightage: "0", shortq: "I plan to use:", q: "I plan to use",
    "type": "multi",
    "dbValue": "",
    "id": "listCommutePlan",
    options: [{ label: 'Live in Nanny, non driver', value: 'childcare_live_in_non_driver' },
            { label: 'Live in Nanny, driver', value: 'childcare_live_in_driver' },
            { label: 'Live out Nanny, driver', value: 'childcare_live_out_driver' },
            { label: 'Live out Nanny, non driver', value: 'childcare_live_out_non_driver' },
            { label: 'Part time sitter', value: 'childcare_pt_sitter' },
            { label: 'Au pair', value: 'childcare_au_pair' },
            { label: 'Family', value: 'childcare_none_parents' },
            { label: 'Day care', value: 'childcare_daycare' }
            ]
  },
  { no: 18, logic: "filter", weightage: "8", shortq: "Land Up To:", q: "The ideal amount of land for us would be",
    "type": "multi",
    "dbValue": "",
    "id": "listIdealAmount",
    options: [{ label: 'Up to 1/4 acre', value: 'land_up_to_1_4_acre' },
            { label: 'Up to 1/2 acre', value: 'land_up_to_1_2_acre' },
            { label: 'Up to 1 acre', value: 'land_up_to_1_acre' },
            { label: '1+acres', value: 'land_up_to_1_plus' },
            { label: 'Large acreage', value: 'large_acreage' }
            ]
  },
  { no: 19, logic: "filter", weightage: "8", shortq: "The ideal type of home would be:", q: "The ideal type of home would be",
    "type": "multi",
    "dbValue": "",
    "id": "listIdealHome",
    options: [{ label: 'Townhome', value: 'townhome' },
            { label: 'Condo', value: 'condo' },
            { label: 'Single family home', value: 'single_family' }
            ]
  },
  { no: 20, logic: "weightage", weightage: "0", shortq: "Backyard Pool", q: "Is a pool ideal for you in your yard?", "type": "boolean", "dbValue": "backyard_pool", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  // { no: 21, q: "Would you say you prefer more folks in town to be progressive v. conservative?", "type": "custom", "dbValue": "progressive,conservative", options: [{label: "", value: "-1"}, {label: "Progressive", value: "1"}, {label: "Conservative", value: "0"}] },// custom
  { no: 21, logic: "filter", weightage: "8", shortq: "progressive v. conservative", q: "Would you say you prefer more folks in town to be progressive v. conservative?",
    "type": "multi",
    "dbValue": "",
    "id": "listMoreFolks",
    options: [{ label: 'Progressive', value: 'progressive' },
              { label: 'Conservative', value: 'conservative' }
            ]
  },
  { no: 22, logic: "filter", weightage: "8", shortq: "Beach nearby", q: "Is it very important for you to have a beach nearby?", "type": "boolean", "dbValue": "beach", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 23, logic: "filter", weightage: "8", shortq: "Home to be Walkable", q: "Is it very important for your home to be walkable?", "type": "boolean", "dbValue": "walkable", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 24, logic: "filter", weightage: "8", shortq: "Organic Food options", q: "Is it very important for you to have Organic Food options?", "type": "boolean", "dbValue": "organic_food_options", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 25, logic: "weightage", weightage: "2", shortq: "Hiking trails nearby", q: "Is it important for you to have Hiking trails nearby?", "type": "boolean", "dbValue": "hiking_trails", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 26, logic: "weightage", weightage: "0", shortq: "Activities that are most important to your family:", q: "Please check off the activities that are most important to your family:",
    "type": "multi",
    "dbValue": "",
    "id": "listCheckoffActivity",
    options: [{ label: 'Tennis', value: 'activities_tennis' },
            { label: 'Hockey', value: 'activities_hockey' },
            { label: 'Lacrosse', value: 'activities_lacrosse' },
            { label: 'Dance', value: 'activities_dance' },
            { label: 'Squash', value: 'activities_squash' },
            { label: 'Theatre', value: 'activities_theatre' },
            { label: 'Football', value: 'activities_football' },
            { label: 'Writing', value: 'activities_writing' },
            { label: 'Soccer', value: 'activities_soccer' }
            ]
  },
  // { no: 27, q: "Would any part of your weekend activities be centered around:", "type": "custom", "dbValue": "church_activities,temple_activities,bar_bat_mitzvahs", options: [{label: "", value: ""}, {label: "Church", value: "church_activities"}, {label: "Temple", value: "temple_activities"}, {label: "Other religious institution or activity", value: "bar_bat_mitzvahs"}] },// custom
  { no: 27, logic: "weightage", weightage: "0", shortq: "Weekend activities:", q: "Would any part of your weekend activities be centered around:",
    "type": "multi",
    "dbValue": "",
    "id": "listWeekendActivity",
    options: [{ label: 'Church', value: 'church_activities' },
              { label: 'Temple', value: 'temple_activities' },
              { label: 'Other religious institution or activity', value: 'bar_bat_mitzvahs' }
            ]
  },
  { no: 28, logic: "filter", weightage: "8", shortq: "Great restaurants nearby", q: "Is it important for you to have great restaurants nearby?", "type": "boolean", "dbValue": "great_restaurants", options: [{label: "", value: "-1"}, {label: "Yes", value: "1"}, {label: "No", value: "0"}] },
  { no: 29, logic: "weightage", weightage: "4", shortq: "Brand stores or mom and pop shops:", q: "Do you prefer name brand stores or mom and pop shops in town?",
    "type": "multi",
    "dbValue": "",
    "id": "listBrandsStore",
    options: [{ label: 'Name brand', value: 'brand_name_shops' },
            { label: 'Mom and pop', value: 'mom_and_pop_shops' },
            ]
  },
  { no: 30, logic: "filter", weightage: "10", shortq: "Budget:", q: "Give us a round about budget...",
    "type": "multi",
    "dbValue": "",
    "id": "listBudget",
    options: [{ label: 'Price Under 500K', value: 'price_under_500k' },
            { label: 'Price 500k to 1 mil', value: 'price_500k_1mill' },
            { label: 'Price 1 to 1.5 mil', value: 'price_1_1_5mill' },
            { label: 'Price 1.5 to 2 mil', value: 'price_1_5_2mill' },
            { label: 'Price 2 to 3 mil', value: 'price_2_3mill' },
            { label: 'Price 3 mil Plus', value: 'price_3mill_plus' }
            ]
  }
]

const multiListIds=[
  "listOccupation",
  "listLaidBack",
  "listSoprtyArtsy",
  "listCommuteTime",
  "listMoreFolks",
  "listTransportation",
  "listCommutePlan",
  "listIdealAmount",
  "listIdealHome",
  "listCheckoffActivity",
  "listBrandsStore",
  "listTaxes",
  "listWeekendActivity",
  "listBudget"
]

const occupationList = [
  { label: 'White Collar', value: 'white_collar' },
  { label: 'Blue Collar', value: 'blue_collar' },
  { label: 'Finance Legal', value: 'finance_legal' },
  { label: 'Medical', value: 'medical' },
  { label: 'Entrepreneur Self Employed', value: 'entrepreneur_self_employed' },
  { label: 'Tech', value: 'tech' },
  { label: 'Creative', value: 'creative' }
]
class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}
      >
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

let pre_Auth = ''
let _user = ''

class AIForm extends Component {
  ai_activity = null
  intervalID = 0;
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      mvalue: { year: new Date().getFullYear(), month: new Date().getMonth() },
      fromDate: moment().subtract(14, "days"),
      endDate: moment(),
      role: "",
      sbj_towns: [],
      modalIsOpen: false,
      selectedTown: {},
      selectedKey: [],
      isDataLoading: false,
      suburbDropdown: false,
      filters: [],
      suburbsData: [],
      questions: questionsList,
      hours:0,
      minutes:0,
      seconds:0,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const { fetchUser } = this.props;
    _user = cookies.get("user");
    if (_user) {
      if (
        _user.role === "agent" ||
        _user.role === "local" ||
        _user.role === "client"
      ) {
        this.props.history.push({ pathname: "/login" });
      }
      let questions = questionsList
      questions = _.orderBy(questions, [q => q.logic], ['asc'])
      this.setState({
        userid: _user.id,
        role: _user.role,
        isDataLoading: true,
        questions
      });
      setTimeout(
        function(e) {
          const data = {};
          data.user_id = _user.id;
          fetchUser(data);
          data.filters = [];
          // this.fetchAItowns(data);
          this.props.getFilterParams();
        }.bind(this),
        300
      );
      pre_Auth = cookies.get('i_user')
      if(pre_Auth){
        this.setState({pre_Auth: pre_Auth})
      }
    }

    /*This is for AI time sheet*/
      /*
      if(_user && _user.role === 'strategist' || _user.role =='admin'){
        let splitVal = this.props.location.pathname && this.props.location.pathname.split('/')
        if(splitVal){
          if(splitVal[1] === "ai-towns"){
            this.ai_activity = localStorage.getItem('aiStrategistTime')
            if(!this.ai_activity || this.ai_activity == 'null'){
              let obj = {
                strategist_id:_user.id,
                client_id: 0,
                start_date: new Date(),
                start_time: new Date(),
                activity_log: `Activity on AI Edit Screen`
              }
              localStorage.setItem('aiStrategistTime', Date.now())
              this.ai_activity = Date.now()
              axios({
                method: 'POST',
                url: HOSTNAME+'/user/add_client_strategist_timesheet',
                data: obj
              })
              .then((res) => {
                if(res.data && res.data && res.data.status){
                  localStorage.setItem('aiTimeId', res.data.data[0])
                }else{
                  localStorage.setItem('aiStrategistTime', 'null')
                  this.ai_activity = 'null'
                }
              }).catch(logoutInvalidRequest)
            }
          }
        }
      }
      */
      /*This is for AI time sheet*/
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.profilePhase == "success") {
      this.setState({ suburbsData: nextProps.user.suburbs });
    }
  }

  componentDidMount() {
    let self = this;
    document.title = "Jungler: AI Match";
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ suburbDropdown: false });
        }
      }
      _.forEach(multiListIds, listId => {
        if (!$(event.target).closest(`#${listId}`).length) {
          if ($(`#${listId}`).is(":visible")) {
            self.setState({ [listId]: false });
          }
        }
      })
    })
    if(this.ai_activity && this.ai_activity != 'null'){
      this.startInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    /*
    if(_user) {
      if(_user.role =='admin' || _user.role == 'strategist'){
        let aiTimeId = localStorage.getItem('aiTimeId')
        if(aiTimeId && aiTimeId != 'null'){
          let pathname = window && window.location.pathname
          if(pathname && pathname != ''){
            let splitPath = pathname.split('/')
            if(splitPath && splitPath.length > 0 && (splitPath[1] !== 'ai-towns')){
              let obj = {
                id: aiTimeId,
                strategist_id:_user.id,
                client_id: 0,
                end_date: new Date(),
                end_time: new Date(),
                status: 1
              }
              axios({
                method: 'POST',
                url: HOSTNAME+'/user/add_client_strategist_timesheet',
                data:obj
              })
              .then((res) => {
                if(res.data && res.data && res.data.status){
                  localStorage.setItem('aiStrategistTime', 'null')
                  localStorage.setItem('aiTimeId', null)
                  this.ai_activity = 'null'
                  this.forceUpdate()
                }else{
                  localStorage.setItem('aiStrategistTime', 'null')
                  localStorage.setItem('aiTimeId', null)
                  this.ai_activity = 'null'
                }
              }).catch(logoutInvalidRequest)
            }
          }
        }
      }
    }
    */
  }

  startInterval = () => {
    this.intervalID =  setInterval( () => {
      var a = new Date()
      var b = new Date(parseInt(this.ai_activity))

      a = moment.tz(a, _user.time_zone)
      b = moment.tz(b, _user.time_zone)

      var diff = Math.abs(a - b) / 1000;
       // get hours
      var hours = Math.floor(diff / 3600) % 24;
      // get minutes
      var minutes = Math.floor(diff / 60) % 60;

      minutes = (parseInt(minutes) < 10) ? "0" + parseInt(minutes) : parseInt(minutes)
      // get seconds
      var seconds = diff % 60;
      seconds = (parseInt(seconds) < 10) ? "0" + parseInt(seconds) : parseInt(seconds)
      this.setState({ 'hours': parseInt(hours), 'minutes': minutes, 'seconds': seconds})
      if(parseInt(minutes) === 10){
        this.stopTime('')
      }
    }, 1000)
  }

  startTime(data){
    /*
    let obj = {
      strategist_id: _user.id,
      client_id: 0,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: `Activity on AI Edit Screen`
    }
    localStorage.setItem('aiStrategistTime', Date.now())
    this.ai_activity = Date.now()
    this.startInterval()
    this.forceUpdate()
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('aiTimeId', res.data.data[0])
      }else{
        localStorage.setItem('aiStrategistTime', 'null')
        this.ai_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  stopTime(data){
    /*
    clearInterval(this.intervalID);
    let aiTimeId = localStorage.getItem('aiTimeId')
    let _user = cookies.get('user')
    let obj = {
      id: aiTimeId,
      strategist_id: _user.id,
      client_id: 0,
      end_date: new Date(),
      end_time: new Date(),
      status: 1
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('aiStrategistTime', 'null')
        localStorage.setItem('aiTimeId', null)
        this.ai_activity = 'null'
        this.forceUpdate()
      }else{
        localStorage.setItem('aiStrategistTime', 'null')
        localStorage.setItem('aiTimeId', null)
        this.ai_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  /*
  fetchAItowns(data) {
    
    axios({
      method: "POST",
      url: HOSTNAME + "/users/fetch_ai_towns",
      data: data
    }).then(resData => {
      if (resData.data.data) {
        this.setState({ sbj_towns: resData.data.data, isDataLoading: false });
      }
    }).catch(logoutInvalidRequest)
  }
  */
  editBtn(town) {
    this.setState({ selectedTown: town, modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ selectedTown: {}, modalIsOpen: false });
  };

  closeCrossBtn(e) {
    this.setState({ selectedTown: {}, modalIsOpen: false });
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });

    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();

    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }

  handleChange = (e, key, type) => {
    let { selectedKey } = this.state;
    let val = e.target.value;
    let customState = selectedKey;
    if (
      customState &&
      customState.length !== 0 &&
      customState.includes(key) !== true
    ) {
      customState.push(key);
    }
    if (customState && customState.length === 0) {
      customState.push(key);
    }
    this.setState({ [key]: val, selectedKey: customState });
  };

  handleChangeMultiSelect = (key, type, val) => {
    let { selectedKey, selectedTown } = this.state;
    let customState = selectedKey;
    if (
      customState &&
      customState.length !== 0 &&
      customState.includes(key) !== true
    ) {
      customState.push(key);
    }
    if (customState && customState.length === 0) {
      customState.push(key);
    }
    const newValue = !selectedTown[key] === true ? 1 : 0 // boolean set
    selectedTown[key] = newValue
    this.setState({ [key]: newValue, selectedKey: customState, selectedTown });
  };

  saveBtn(town) {
    let { selectedKey, userid } = this.state;
    let arr = [];
    let data = {};
    if (_.size(selectedKey) > 0) {
      let st = selectedKey.map((c, i) => {
        let obj = {};
        obj.key = c;
        obj.value = this.state[c];
        arr.push(obj);
      });
    }
    data.customFields = arr;
    data.user_id = userid;
    data.town_id = town.town_id;
    this.setState({ isDataLoading: true });
    if (data.customFields && data.customFields.length > 0) {
      axios({
        method: "POST",
        url: HOSTNAME + "/users/update_ai_towns",
        data: data
      }).then(resData => {
        this.setState({
          selectedTown: {},
          modalIsOpen: false,
          selectedKey: []
        });
        var data1 = {
          user_id: userid,
          filters: this.state.filters
        };
        // this.fetchAItowns(data1);
      }).catch(logoutInvalidRequest)
    } else {
      this.setState({
        isDataLoading: false,
        selectedTown: {},
        modalIsOpen: false,
        selectedKey: []
      });
    }
  }

  toggledropDown(val) {
    if (val === "filterSuburb") {
      this.setState({ suburbDropdown: !this.state.suburbDropdown });
    } else{
      this.setState({ [val]: !this.state[val] });
    }
  }

  filterStrategist(id, type) {
    let { filters, userid } = this.state;
    if (filters.indexOf(id) === -1) {
      filters.push(id);
    } else {
      filters.splice(filters.indexOf(id), 1);
    }
    this.setState({ filters, isDataLoading: true }, () => {
      var data1 = {
        user_id: userid,
        filters: filters
      };
      //this.fetchAItowns(data1);
    });
  }

  render() {
    const { sbj_towns, userid, suburbsData, hours, minutes, seconds } = this.state;
    let allData = this.props.filterParams.data;
    let suburbsArray = [];
    if (this.state.role === "strategist") {
      if (allData && allData.suburbs) {
        _.forEach(allData.suburbs, a => {
          var o = _.find(suburbsData, b => a.id == b.suburb_id);
          if (o) {
            suburbsArray.push(a);
          }
        });
      }
    } else {
      suburbsArray = allData && allData.suburbs;
    }
    return (
      <div>
      {
        (pre_Auth)?
        <div className="strategist_top">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
        :''
      }
        <TopNavigation {...this.props} />

        {this.state.isDataLoading === true ? (
          <div className="loader__agent">
            <img src="../../img/loader2.svg" />
          </div>
        ) : (
          ""
        )}

        <div id="wrapper">
          <SideNavigation {...this.props} />
          <div id="page-content-wrapper" className={ pre_Auth ? "tabletviewonly__ai--content trgap" : "tabletviewonly__ai--content aigap" }>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter__sub--container filter__sub--ai">
                    <li id="dropdownBtn" className="fulllistwidth">
                      <div
                        className="custom-select--all custom__greenline"
                        onClick={this.toggledropDown.bind(this, "filterSuburb")}
                      >
                        Filter by Suburb
                      </div>
                      {this.state.suburbDropdown && (
                        <div className="sel-outer suburb-filter">
                          {suburbsArray &&
                            suburbsArray.map((suburbsval, suburbsindx) => {
                              let selectedIndex = false;
                              _.find(this.state.filters, function(o) {
                                if (o === suburbsval.id) {
                                  selectedIndex = true;
                                }
                              });
                              return (
                                <span
                                  className="coverage__checkbox"
                                  key={suburbsindx}
                                >
                                  <label className="custom-checkbox">
                                    {suburbsval.name}
                                    <input
                                      type="checkbox"
                                      checked={selectedIndex}
                                      className="custom-select-option-checkbox"
                                      onChange={() =>
                                        this.filterStrategist(
                                          suburbsval.id,
                                          "suburbFilter"
                                        )
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </span>
                              );
                            })}
                        </div>
                      )}
                    </li>
                  </div>
                  {
                    _user && _user.role && (_user.role === 'admin' || _user.role ==='strategist') && this.ai_activity === 'null' ?
                      <button className="btn btn__green strategy-right btn__profile start__btn--table font500 btnright" onClick={this.startTime.bind(this)}><img src="img/playbutton.svg" /> Start</button>
                    : ''
                  }
                  {
                    _user && _user.role && ( _user.role ==='admin' || _user.role ==='strategist') && this.ai_activity !== 'null' ?
                        <button className="btn btn__green strategy-right btn__profile play__btn--table font500 btnright" onClick={this.stopTime.bind(this)}><img src="img/stopbutton.svg" /> Stop ({hours}:{minutes}:{seconds})</button>
                      : ''
                  }
                </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                <div className="useslist__extra">
                  {sbj_towns && sbj_towns.length > 0 ? (

                    <div className="table-responsive">
                      <table className="table table-striped school__table--tabs">
                      <thead className="table__td--desktop">
                          <tr>
                            <th>Town Name</th>
                            <th>Suburbs</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sbj_towns.map((town, index) => {
                            return (
                              <tr key={index}>
                                <td>{town.town_name}</td>
                                <td className="table__td--desktop">{town.city}</td>
                                <td className="overDueRed">
                                  <button className="edit__btn">
                                    <img
                                      src="../../img/edit-icon.svg"
                                      onClick={this.editBtn.bind(this, town)}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                  ) : (
                    <p className="no__data--show"></p>
                  )}
                  </div>
                  <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    portalClassName="edit-town-modal"
                    contentLabel="Example Modal"
                  >
                    <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">Edit Town Characteristics:- {this.state.selectedTown.town_name}</h4>
                      <button
                        onClick={this.closeCrossBtn.bind(this)}
                        type="button"
                        id="close_user_modal"
                        className="close_img close_img--white"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                      <img src="../../img/close_btn.svg"/>
                      </button>
                    </div>

                    {/* <div className="form-group">
                      <h1 className="headModal">
                        Edit AI Town:- {this.state.selectedTown.town_name}
                      </h1>
                      <button
                        type="button"
                        className="btn close-btn closeModal"
                        data-dismiss="modal"
                      >
                        <img
                          data-dismiss="modal"
                          src="./img/login_close.svg"
                          onClick={this.closeCrossBtn.bind(this)}
                        />
                      </button>
                    </div> */}

                    <div className="body__wrappers">
                      {this.state.questions && this.state.questions.map((question, questionIndex) => {
                        if (question.type !== 'multi') {
                            return (<div key={question.no+"__"+questionIndex} className="form-group">
                                    <label className={question.logic === "filter" ? "labelinputs__global filter__question" : "labelinputs__global"}>
                                      {question.logic === "filter" ? "*"+question.shortq: question.shortq}
                                    </label>
                                    <select className="selectinputs__global" defaultValue={this.state.selectedTown[question.dbValue]} onChange={(e) => this.handleChange(e, `${question.dbValue}` ,`${question.type}`)}>
                                      {question.options && question.options.map((options, optionIndex) => {
                                        return (
                                        <option key={options.label+'__option__label__'+optionIndex} value={options.value}>{options.label}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                )
                        } else {
                          return ( <div key={question.no+"__"+questionIndex} className="form-group">
                                <label className={question.logic === "filter" ? "labelinputs__global filter__question" : "labelinputs__global"}>{question.logic === "filter" ? "*"+question.shortq: question.shortq}</label>
                                  <div className="filter__sub--container">
                                    <li id={question.id} className="fulllistwidth fullinput">
                                      <div
                                      className="custom-select--all custom__greenline fulllistwidthinput"
                                      onClick={this.toggledropDown.bind(this, question.id)}
                                      style={{overflow: "hidden", fontSize: "12px"}}
                                      >
                                      {question.options && question.options.map((occupation, occupationindex) => {
                                        if (this.state.selectedTown[occupation.value] == '1') {
                                          return (<span key={occupation.label+'option__label__'+occupationindex}>{occupation.label}, </span>)
                                        }
                                      })}
                                      </div>
                                      {this.state[question.id] && (
                                        <div className="sel-outer suburb-filter">
                                          {question.options && question.options.map((occupation, occupationindex) => {
                                            let selectedIndex = false
                                            if (this.state.selectedTown[occupation.value] == '1') {
                                              selectedIndex = true
                                            }
                                             return (
                                              <span
                                                className="coverage__checkbox"
                                                key={occupation.label+'___option__label__'+occupationindex}
                                              >
                                                <label className="custom-checkbox">
                                                  {occupation.label}
                                                  <input
                                                    defaultChecked={selectedIndex}
                                                    type="checkbox"
                                                    className="custom-select-option-checkbox"
                                                    onClick={this.handleChangeMultiSelect.bind(this, `${occupation.value}`, "boolean")}
                                                  />
                                                  <span className="checkmark" />
                                                </label>
                                              </span>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </li>
                                  </div>
                              </div>)
                            }
                          })}

                          <button
                            className="btns__fullwidth poppinsfontsedit"
                            type="button"
                            onClick={this.saveBtn.bind(
                              this,
                              this.state.selectedTown
                            )}
                          >
                            Save
                          </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "ai", // a unique identifier for this form
  destroyOnUnmount: true
})(AIForm);
