import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import moment from 'moment'
import _ from 'lodash'
import Config from '../../../../../../config';

//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AgentsAssignedByNysjStrategistsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      sortByKey:'name',
      sortByType: 0,
      type: 'assignToStrategist',
      selectedIndex: 'today',
      isLoading: false,
      agentTableData: [],
      open: false,
      currentAgentTags: [],
      agentName: '',
      search: '',
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const strData = {}
      strData.from = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getClientsPerAgent(strData)
    }
  }

  componentDidUpdate(preProps, preState){
    const { clientsPerAgentsPhase, clientsPerAgentsData, clearReportV2Phase } = this.props
    if(clientsPerAgentsPhase === "success"){
      clearReportV2Phase()
      let data = []
      if(_.size(clientsPerAgentsData) > 0){
        clientsPerAgentsData && clientsPerAgentsData.map((ag, i)=>{
          data.push({
            key: i,
            agent_id: ag.agent_id,
            name: ag.name,
            totalAllisonClients: ag.totalAllisonClients,
            totalClients: ag.totalClients,
            totalErikaClients: ag.totalErikaClients,
            totalMayaClients: ag.totalMayaClients,
            totalPattiClients: ag.totalPattiClients,
            totalRobinClients: ag.totalRobinClients,
            totalTag: ag.totalTag,
            totalTagArr: ag.totalTag.length > 0 ? ag.totalTag.map(e => { return e }).join(" ,") : "",
          })
        })
      }
      this.setState({ agentTableData: data, isLoading: false })
    }
  }

  onOpenModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if(tags[0].length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTags: tags[0] })
    }
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  filterDate(e){
    if(e.target.value === "all"){
      this.setState({ isLoading: true, selectedIndex: e.target.value })
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgent(strData)
    }else{
      this.openDatePickerRange()
    }
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to  : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      type: this.state.type
    }
    this.props.getClientsPerAgent(strData)
  }

  changeLimit = limit => {
    // On limit change
  }
  
  render() {
    const { agentTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let filterList  = agentTableData
    if(_.size(agentTableData) > 0){
      filterList = agentTableData.filter(
        (val) =>{
          return (
            (val && val.name && val.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }
    

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Agents Assigned By NY SJ Strategists</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                    <ExcelSheet data={filterList} name="agent_assigned_by_ny_strategist">
                        <ExcelColumn label="Agent Name" value="name"/>
                        <ExcelColumn label="Total number of Clients" value="totalClients"/>
                        <ExcelColumn label="Patti Natiss clients" value="totalPattiClients"/>
                        <ExcelColumn label="Erika Ades clients" value="totalErikaClients"/>
                        <ExcelColumn label="Maya Konig clients" value="totalMayaClients"/>
                        <ExcelColumn label="Robin Hoberman clients" value="totalRobinClients"/>
                        <ExcelColumn label="Allison Levine clients" value="totalAllisonClients"/>
                        <ExcelColumn label="Tags" value="totalTagArr"/>
                    </ExcelSheet>
                  </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search for a agent by name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(filterList) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Agent Name',
                              width: 270,
                              dataIndex: 'name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                              title: 'Total number of clients',
                              width: 250,
                              dataIndex: 'totalClients',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClients - b.totalClients
                            },
                            {
                              title: 'Patti Natiss clients',
                              dataIndex: 'totalPattiClients',
                              key: '3',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalPattiClients - b.totalPattiClients
                            },
                            {
                              title: 'Erika Ades clients',
                              dataIndex: 'totalErikaClients',
                              key: '4',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalErikaClients - b.totalErikaClients
                            },
                            {
                              title: 'Maya Konig clients',
                              dataIndex: 'totalMayaClients',
                              key: '5',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalMayaClients - b.totalMayaClients
                            },
                            {
                              title: 'Robin Hoberman clients',
                              dataIndex: 'totalRobinClients',
                              key: '6',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalRobinClients - b.totalRobinClients
                            },
                            {
                              title: 'Allison Levine clients',
                              dataIndex: 'totalAllisonClients',
                              key: '7',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalAllisonClients - b.totalAllisonClients
                            },
                            {
                              title: 'Tags',
                              dataIndex: 'tag',
                              key: '8',
                              width: 320,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalTagArr.localeCompare(b.totalTagArr),
                              render: (text, str) => {
                                const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                                const tagArr = tags[0]
                                let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                                return(
                                  <div className={(tagArr.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div>
                                )
                              }
                            }
                          ]}
                          dataSource={filterList}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>

          <ReactModal
            isOpen={this.state.open}
            contentLabel="Report"
            portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                  {'Agent - ' + this.state.agentName+`'s`+' tag(s)'}
                  </h5>
                  <button
                    type="button"
                    className="btn react-modal-close"
                    onClick={this.onCloseModal.bind(this)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="info__block">
                    {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                      return <p key={'tag-name-'+index}>{tag}</p>
                    })}
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </ReactModal>

        </div>
      </div>
    )
  }
}
