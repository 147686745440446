import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import {Fragment} from "react"
import { NavbarIcon, SearchIcon, CloseIcon, CalendarIcon } from '../../icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import { Drawer } from 'antd'

const cookies = new Cookies() // Import css
let _user = ''

const routeTab = {
  "/agent/dashboard": 0,
  "/agent/clients/all-clients": 1,
  "/agent/clients/active-clients": 1,
  "/agent/clients/closed-clients": 1,
  "/agent/task-list/new-never-touched": 2,
  "/agent/task-list/not-touched-in-4-weeks": 2,
  "/agent/task-list/interested-but-don’t-want-town-tour-yet": 2
}

export default class TopNevigationMobileComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state={
      searchIconClick: false,
      showSearchList: false,
      visible: false
    }
    this.userLogout = this.userLogout.bind(this)
  }

  componentDidMount(){
    _user = cookies.get('user')
    const currentUrl = this.props.location && this.props.location.pathname && this.props.location.pathname.split(`/`)
    if (!_.isEmpty(this.props)) {
      if (currentUrl[2] === "client-details") {
        this.setState({ activeTab: 1})
      } else {
        this.setState({ activeTab: routeTab[this.props.location.pathname]})
      }
    }
  }

  searchClick(){
    this.setState({  searchIconClick: !this.state.searchIconClick })
  }

  onListClick(search) {
    if (search.role !== 'client') {
      return
    }

    this.setState({ showSearchList: false })
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }

  onClose = () => {
    this.setState({ visible: false })
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '' + match[1] + '.' + match[2] + '.' + match[3]
    }
    return null
  }

  searchHandleChange(e){
    if(_.size(e.target.value) > 2){
      this.props.getSearchData(e.target.value)
      this.setState({ showSearchList: true })
    }else{
      this.setState({ showSearchList: false })
    }
  }

  onLinkClick(val, e){
    if(val === 0){
      this.props.history.push(`/agent/dashboard`)
    }else if(val === 1){
      this.props.history.push(`/agent/clients/all-clients`)
    }else if(val === 2){
      this.props.history.push(`/agent/task-list/new-never-touched`)
    }else if(val === 3){
      this.props.history.push(`/agent/update-profile`)
    }
    this.setState({ visible: false })
  }

  userLogout(){
    this.props.userSignout()
  }

  render() {
    const { searchAgentData } = this.props
    return (
      <Fragment>
        {
          _user && ((_user.role === 'agent') || (_user.role === 'bussiness development') || (_user.role === 'local')) ?
          <Fragment>
            <div className="topnavigation__mobile">
              <div className="topnavigation__mobile__left">
                <div className="topnavigation__mobile__logo">
                  <img onClick={() => this.props.history.push(`/agent/dashboard`)} className="mobile-sidebar-resize" src="../img/logo.svg"/>
                </div>
              </div>
              <div className="topnavigation__mobile__right">
                <div className="topnavigation__mobile__inner">
                  {_user && _user.role === "agent" &&
                    <Link className="btn btn-dark btn-calendar" to={'/agent/calendar'}><CalendarIcon className="calendar-icon"/>Calendar</Link>
                  }                  
                  <button onClick={this.searchClick.bind(this)} className="btn btn__search"><SearchIcon/></button>
                  <button onClick={this.showDrawer} className="btn btn__navbar"><NavbarIcon/></button>
                </div>
              </div>
            </div>
            <div className={this.state.searchIconClick ? "modal-search-box open" : "modal-search-box"} id="search-box">
              <div className="d-flex align-items-center">
                <input
                  className="form-control modal-search-box-input"
                  placeholder="Search Clients"
                  onChange={this.searchHandleChange.bind(this)}
                />
                  <button onClick={this.searchClick.bind(this)} className="btn modal-search-box-button"><CloseIcon/></button>
              </div>
              <div className="search-wrapper-open-container">
              {
                this.state.showSearchList &&
                <ul className="search-wrapper-options-menu">
                  {
                  searchAgentData && searchAgentData.map((search, idx) => {
                      return (
                        <li key={idx} onClick={(e)=> this.onListClick(search)} className="search-wrapper-options-menu-item">
                          <Link to={`/agent/client-details/${search.id}`}>
                            <div className="options-menu-item-title">
                            <span className="avatar">
                            {
                              search.avatar ?
                                <img src={search.avatar}/>
                              :
                              <img src="../img/img_v2/user-icon.png"/>
                            }
                            </span>
                            <span className="name">
                              {search.first_name+' '+search.last_name}
                            </span>
                               {search.share_info_to_all ? (<div className="email">{search.email ? search.email : ''}</div>) : ''}
                              {search.share_info_to_all ? (<div className="phone">{search.phone_mobile ? (search.phone_mobile.replace(/\D/g, '').length == 10) ? this.formatNumber(search.phone_mobile.replace(/\D/g, '')) : search.phone_mobile.replace(/\D/g, '') : ''}</div>) : ''}
                            </div>
                          </Link>
                        </li>
                      )
                    })
                  }
                </ul>
              }
              </div>
            </div>

            {/* Side drawer */}
            <Drawer
              width="320"
              closable={true}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <ul className="mob__nav__list">
                <li className={this.state.activeTab === 0 ? "active" : ""} onClick={this.onLinkClick.bind(this, 0)}>Dashboard</li>
                <li className={this.state.activeTab === 1 ? "active" : ""} onClick={this.onLinkClick.bind(this, 1)}>Clients</li>
                <li className={this.state.activeTab === 2 ? "active" : ""} onClick={this.onLinkClick.bind(this, 2)}>Task List</li>
                <li onClick={this.onLinkClick.bind(this, 3)}>Edit Profile</li>
                <li onClick={() => this.userLogout()}>Logout</li>
              </ul>
            </Drawer>
          </Fragment>
          :
          <Fragment>

          </Fragment>
        }
      </Fragment>
    )
  }
}
