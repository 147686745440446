const validate = (values) => {
  const errors = {}
  const requiredFields = [
    'town_name',
    'suburb_name'
  ]

  requiredFields.forEach((field) => {
    if (!values[ field ]) {
      errors[ field ] = `Please enter ${field.toLowerCase().replace(/_/g, ' ')}`
    }
  })

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
    errors.email = 'Please enter a valid email'
  }
  if (!values.crossStreet) {
    errors.crossStreet = 'Please enter a cross street'
  }
  if (!values.address) {
    errors.address = 'Please enter a town name'
  }
  // if (values.website && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(values.website)) {
  //   errors.website = 'Please enter valid url'
  // }
  // if (!values.autocomplete) {
  //   errors.name = "Please enter location"
  // }
  if (!values.content) {
    errors.content = "Please enter review"
  }

  return errors
}

export default validate
