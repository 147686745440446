/* eslint-disable no-nested-ternary */
import React, { PureComponent } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import _, { map } from "lodash";
import Validation from "../../../../../config/validation";
import { DatePicker, message } from 'antd'
import moment from 'moment'
import { DownCaretIcon } from '../../../../../components/icons'
import config, { timeConverter } from "../../../../../config";
import axios from 'axios'
const HOSTNAME = process.env.API_HOSTNAME
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from "../../../../../utils/helper";
const cookies = new Cookies()

export default class SignUpPage2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      agentForm3Data: [],
      timeSlots: []
    };
  }

  componentDidMount() {
    let data = {
      sign_contract_date: '',
      sign_contract_time: '',
      broker_first_name: '',
      broker_last_name: '',
      broker_email: '',
      stage: 3
    };
    this.setState({ agentForm3Data: data });
  }

  handleDateChange = (selectDate, values) => {
    if (selectDate) {
      this.props.toggleLoading(true)
      this.setState({ values: { ...values, selectDate } }, ()=> {
        this.getTimeAPI(selectDate)
      })
    }
  }

   getTimeAPI = async(selectDate)=>{
    const { onBoardingData: { data } } = this.props
    const _getTime = {
      date: moment(selectDate).format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      city: data.city_live,
      price: data.buy_side_sales
    }
    const jwtToken = cookies.get('Authorization')
    const res = await axios({
      method: 'POST',
      url: `${HOSTNAME}/users/getAvailabilityTimeAcuity`,
      'headers': { 'Authorization': jwtToken },
      data: _getTime
    }).catch(logoutInvalidRequest)
    const getTimeData = _.get(res, 'data.data', [] )
    const strategist_id = _.get(res, 'data.strategy', [] )
    this.props.toggleLoading(false)
    this.setState({ timeSlots: getTimeData, strategist_id})
  }
  
  submitData=(data)=>{
    const user = cookies.get('user')
    if (user && user.email && user.email !== data.broker_email) {
      data.sign_contract_date = moment(data.sign_contract_date).format("YYYY-MM-DD hh:mm:ss")
      data.datetime = `${moment(data.sign_contract_date).format("YYYY-MM-DD")} ${data.sign_contract_time}`
      data.time_zone = moment.tz.guess()
      data.strategist_id = this.state.strategist_id
      this.props.onClickPage2(data)
    } else {
      message.error(`Agent & Broker email can't be the same. Please use different email for the Broker.`)
    }
  }
  
  goBackClick() {
    this.props.goBack();
  }
  render() {
    const { onBoardingData } = this.props
    //this is for select timeing
    let selectTimeData = this.state.timeSlots && this.state.timeSlots.map((slotval, indexslot) => {
      return (
        <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
      );
    });

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.agentForm3Data}
          validationSchema={Validation.agentSignUpPage2}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.submitData(values, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form noValidate>
              <div className="card">
                <div className="card-header d__flex align__items__center">
                  <h5 className="card-title mb__0">
                    Schedule a call and sign contract
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <div className="form-group material-textfield">
                          
                          <div className="btn__follow-up-date">
                            <div className="follow-up-date-label">Select Date</div>
                            <DatePicker
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={date => {
                                setFieldValue("sign_contract_date", date)
                                this.handleDateChange(date, values)
                              }}
                              name="sign_contract_date"
                              inputReadOnly={true}
                              allowClear={false}
                              format="MM/DD/YYYY"
                              disabledDate={d => !d || d.isSameOrBefore(moment().format("MM/DD/YY")) }
                              defaultValue={this.state.sign_contract_date}
                              placeholder="Select Date"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                          <ErrorMessage className="invalid-feedback" name="sign_contract_date" component="div"/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          defaultValue=""
                          as="select" 
                          name="sign_contract_time"
                          className="form-control custom__select material-textfield-input"
                          required
                        >
                          <option disabled value=""></option>
                          {selectTimeData}
                        </Field>
                        <label className="material-textfield-label">Select Time</label>
                        <ErrorMessage className="invalid-feedback" name="sign_contract_time" component="div"/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input"
                          name="broker_first_name"
                          required
                        />
                        <label className="material-textfield-label">
                          Managing Broker First Name
                        </label>
                        <ErrorMessage className="invalid-feedback" name="broker_first_name" component="div"/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input"
                          name="broker_last_name"
                          required
                        />
                        <label className="material-textfield-label">
                          Managing Broker Last Name
                        </label>
                        <ErrorMessage className="invalid-feedback" name="broker_last_name" component="div"/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      type="text"
                      className="form-control material-textfield-input"
                      name="broker_email"
                      required
                    />
                    <label className="material-textfield-label">Managing Broker Email </label>
                    <ErrorMessage className="invalid-feedback" name="broker_email" component="div"/>
                    <div className="note__text">
                      {`We need you and your managing broker at ${onBoardingData && onBoardingData.data  && onBoardingData.data.firm_name} to
                      sign the agreement to get activated on Suburban Jungle and
                      receive clients`}
                    </div>
                  </div>
                  <button type="submit" className="btn btn__btn btn-dark btn__lg w__100">
                    Next
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <button
          onClick={this.goBackClick.bind(this)}
          className="btn btn__btn btn-go-back w__100"
        >
          Go Back
        </button>
      </div>
    );
  }
}
