import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import { map } from 'lodash'
import Config from '../../../../../../config';
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}

export default class StrategistStatisticsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      //fromDate: moment('2019-03-27'),
      fromDate: moment('2010-01-01').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      client_strategist: [],
      selectedIndex: 'All',
      isLoading: false,
      strategistTableData: [],
      datePickerModel: false,
      todayDate: moment().format('LL')
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    this.setState({ isLoading: true })
    if (_user && _user.role === "agent" || _user && _user.role === "local") {
      this.props.history.push({ pathname: '/agent/dashboard' })
    }
    if (_user && _user.role === "client") {
      this.props.history.push({ pathname: '/clients' })
    }
    if (_user) {
      const strData = {}
      strData.from = moment('2010-01-01').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      //strData.from = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
      //strData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'any'
      strData.limit = 100
      strData.strategist_arr = this.state.client_strategist
      this.props.getStrategistStatistics(strData)
      this.props.getFilterParams()
    }
  }

  componentDidUpdate(preProps, preState) {
    const { strategistStatPhase, strategistStatData, clearReportV2Phase } = this.props
    if (strategistStatPhase === "success") {
      let data = []
      const statData = strategistStatData && strategistStatData.data
      if (_.size(statData) > 0) {
        statData && statData.map((str, i) => {
          data.push({
            key: i,
            RentedOnOwn: str.RentedOnOwn,
            activelyEngagedPercentage: str.activelyEngagedPercentage,
            activelyEngagedPercentageSort: parseFloat(str.activelyEngagedPercentage.match(/[\d\.]+/)),
            boughtDirectly: str.boughtDirectly,
            boughtDirectlyPercentage: str.boughtDirectlyPercentage,
            boughtDirectlyPercentageSort: parseFloat(str.boughtDirectlyPercentage.match(/[\d\.]+/)),
            callScheduled: str.callScheduled,
            callScheduledPercentage: str.callScheduledPercentage,
            callScheduledPercentageSort: parseFloat(str.callScheduledPercentage.match(/[\d\.]+/)),
            cityDoNotReachOut: str.cityDoNotReachOut,
            cityDoNotReachOutPercentage: str.cityDoNotReachOutPercentage,
            cityDoNotReachOutPercentageSort: parseFloat(str.cityDoNotReachOutPercentage.match(/[\d\.]+/)),
            cityOwnAgent: str.cityOwnAgent,
            cityOwnAgentPercentage: str.cityOwnAgentPercentage,
            cityOwnAgentPercentageSort: parseFloat(str.cityOwnAgentPercentage.match(/[\d\.]+/)),
            // cityPercentage: str.cityPercentage,
            // cityPercentageSort: parseFloat(str.cityPercentage.match(/[\d\.]+/)),
            divorcedPercentage: str.divorcedPercentage,
            divorcedPercentageSort: parseFloat(str.divorcedPercentage.match(/[\d\.]+/)),
            greenNotVeryEngaged: str.greenNotVeryEngaged,
            greenPercentage: str.greenPercentage,
            greenPercentageSort: parseFloat(str.greenPercentage.match(/[\d\.]+/)),
            listingPercentage: str.listingPercentage,
            listingPercentageSort: parseFloat(str.listingPercentage.match(/[\d\.]+/)),
            name: str.name,
            neverHadACall: str.neverHadACall,
            neverHadACallDont: str.neverHadACallDont,
            neverHadACallDontPercentage: str.neverHadACallDontPercentage,
            neverHadACallDontPercentageSort: parseFloat(str.neverHadACallDontPercentage.match(/[\d\.]+/)),
            neverHadACallOwnAgent: str.neverHadACallOwnAgent,
            neverHadACallOwnAgentPercentage: str.neverHadACallOwnAgentPercentage,
            neverHadACallOwnAgentPercentageSort: parseFloat(str.neverHadACallOwnAgentPercentage.match(/[\d\.]+/)),
            neverHadACallPercentage: str.neverHadACallPercentage,
            neverHadACallPercentageSort: parseFloat(str.neverHadACallPercentage.match(/[\d\.]+/)),
            neverHadACallUnsubscribe: str.neverHadACallUnsubscribe,
            neverHadACallUnsubscribePercentage: str.neverHadACallUnsubscribePercentage,
            neverHadACallUnsubscribePercentageSort: parseFloat(str.neverHadACallUnsubscribePercentage.match(/[\d\.]+/)),
            newNotScheduled: str.newNotScheduled,
            newNotScheduledPercentage: str.newNotScheduledPercentage,
            newNotScheduledPercentageSort: parseFloat(str.newNotScheduledPercentage.match(/[\d\.]+/)),
            newPercentage: str.newPercentage,
            newPercentageSort: parseFloat(str.newPercentage.match(/[\d\.]+/)),
            noResponsePercentage: str.noResponsePercentage,
            noResponsePercentageSort: parseFloat(str.noResponsePercentage.match(/[\d\.]+/)),
            notVeryEngagedPercentage: str.notVeryEngagedPercentage,
            notVeryEngagedPercentageSort: parseFloat(str.notVeryEngagedPercentage.match(/[\d\.]+/)),
            outOfArea: str.outOfArea,
            outOfAreaPercentage: str.outOfAreaPercentage,
            outOfAreaPercentageSort: parseFloat(str.outOfAreaPercentage.match(/[\d\.]+/)),
            ownAgent: str.ownAgent,
            ownAgentPercentage: str.ownAgentPercentage,
            ownAgentPercentageSort: parseFloat(str.ownAgentPercentage.match(/[\d\.]+/)),
            ownAgentPre: str.ownAgentPre,
            ownAgentPrePercentage: str.ownAgentPrePercentage,
            ownAgentPrePercentageSort: parseFloat(str.ownAgentPrePercentage.match(/[\d\.]+/)),
            reloPercentage: str.reloPercentage,
            reloPercentageSort: parseFloat(str.reloPercentage.match(/[\d\.]+/)),
            rentedOnOwnPercentage: str.rentedOnOwnPercentage,
            rentedOnOwnPercentageSort: parseFloat(str.rentedOnOwnPercentage.match(/[\d\.]+/)),
            rentedOnOwnUnsubscribe: str.rentedOnOwnUnsubscribe,
            rentedOnOwnUnsubscribePercentage: str.rentedOnOwnUnsubscribePercentage,
            rentedOnOwnUnsubscribePercentageSort: parseFloat(str.rentedOnOwnUnsubscribePercentage.match(/[\d\.]+/)),
            rentedPercentage: str.rentedPercentage,
            rentedPercentageSort: parseFloat(str.rentedPercentage.match(/[\d\.]+/)),
            reschedulePercentage: str.reschedulePercentage,
            reschedulePercentageSort: parseFloat(str.reschedulePercentage.match(/[\d\.]+/)),
            somewhatEngagedPercentage: str.somewhatEngagedPercentage,
            somewhatEngagedPercentageSort: parseFloat(str.somewhatEngagedPercentage.match(/[\d\.]+/)),
            stayingInSuburbs: str.stayingInSuburbs,
            stayingInSuburbsPercentage: str.stayingInSuburbsPercentage,
            stayingInSuburbsPercentageSort: parseFloat(str.stayingInSuburbsPercentage.match(/[\d\.]+/)),
            strategist_id: str.strategist_id,
            totalActivelyEngaged: str.totalActivelyEngaged,
            // totalCity: str.totalCity,
            totalCityPerm: str.totalCityPerm,
            totalCityPermPercentage: str.totalCityPermPercentage,
            totalCityPermPercentageSort: parseFloat(str.totalCityPermPercentage.match(/[\d\.]+/)),
            totalCityTemp: str.totalCityTemp,
            totalCityTempPercentage: str.totalCityTempPercentage,
            totalCityTempPercentageSort: parseFloat(str.totalCityTempPercentage.match(/[\d\.]+/)),
            totalClientReceived: str.totalClientReceived,
            totalClosed: str.totalClosed,
            totalClosedPending: str.totalClosedPending,
            totalDivorced: str.totalDivorced,
            totalFakeClient: str.totalFakeClient,
            totalFakeClientPercentage: str.totalFakeClientPercentage,
            totalFakeClientPercentageSort: parseFloat(str.totalFakeClientPercentage.match(/[\d\.]+/)),
            totalGreen: str.totalGreen,
            totalListing: str.totalListing,
            totalNew: str.totalNew,
            totalNoResponse: str.totalNoResponse,
            totalNoResponseAI: str.totalNoResponseAI,
            totalNoResponseAIPercentage: str.totalNoResponseAIPercentage,
            totalNoResponseAIPercentageSort: parseFloat(str.totalNoResponseAIPercentage.match(/[\d\.]+/)),
            totalOnlineNew: str.totalOnlineNew,
            totalOnlineNewPercentage: str.totalOnlineNewPercentage,
            totalOnlineNewPercentageSort: parseFloat(str.totalOnlineNewPercentage.match(/[\d\.]+/)),
            totalPending: str.totalPending,
            totalRelo: str.totalRelo,
            totalRented: str.totalRented,
            totalReschedule: str.totalReschedule,
            totalSomewhatEngaged: str.totalSomewhatEngaged,
            totalUnsubscribe: str.totalUnsubscribe,
            totalWonTotalReceivedPercentage: str.totalWonTotalReceivedPercentage,
            totalWonTotalReceivedPercentageSort: parseFloat(str.totalWonTotalReceivedPercentage.match(/[\d\.]+/)),
            tryingToSchedule: str.tryingToSchedule,
            tryingToSchedulePercentage: str.tryingToSchedulePercentage,
            tryingToSchedulePercentageSort: parseFloat(str.tryingToSchedulePercentage.match(/[\d\.]+/)),
            ujActivelyEngaged: str.ujActivelyEngaged,
            ujActivelyEngagedPercentage: str.ujActivelyEngagedPercentage,
            ujActivelyEngagedPercentageSort: parseFloat(str.ujActivelyEngagedPercentage.match(/[\d\.]+/)),
            ujNotVeryEngaged: str.ujNotVeryEngaged,
            ujNotVeryEngagedPercentage: str.ujNotVeryEngagedPercentage,
            ujNotVeryEngagedPercentageSort: parseFloat(str.ujNotVeryEngagedPercentage.match(/[\d\.]+/)),
            ujSomewhatEngaged: str.ujSomewhatEngaged,
            ujSomewhatEngagedPercentage: str.ujSomewhatEngagedPercentage,
            ujSomewhatEngagedPercentageSort: parseFloat(str.ujSomewhatEngagedPercentage.match(/[\d\.]+/)),
            unsubscribePercentage: str.unsubscribePercentage,
            unsubscribePercentageSort: parseFloat(str.unsubscribePercentage.match(/[\d\.]+/))
          })
        })
      }
      this.setState({ strategistTableData: data, isLoading: false })
      clearReportV2Phase()
    }
    if (strategistStatPhase === "error" || strategistStatData.status === false) {
      clearReportV2Phase()
      this.setState({ strategistTableData: [], isLoading: false })
    }
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to }, () => {
      // strData.from = moment('2010-01-01').format("YYYY-MM-DD HH:mm:ss")
      // strData.to = moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      // strData.selectedValue = 'total'
      const strData = {
        from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
        to: moment(to).format("YYYY-MM-DD HH:mm:ss"),
        selectedValue: 'any',
        strategist_arr: this.state.client_strategist
      }
      this.props.getStrategistStatistics(strData)
    })
  }

  handleStrOption = (options, event) => {
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ client_strategist: selectedOptions, isLoading: true })
      const strData = {}
      if (this.state.selectedIndex === "All") {
        strData.from = moment('2010-01-01').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        strData.selectedValue = 'any'
      } else {
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'any'
      }
      // strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      // strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      // strData.selectedValue = 'any'
      strData.strategist_arr = selectedOptions
      this.props.getStrategistStatistics(strData)
    }
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { strategistTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    const { filterParams } = this.props

    let strData = filterParams && filterParams.data && filterParams.data.strategists
    const strategistArr = map(strData, (str, index) => {
      return { label: str.strategist_name, value: str.id }
    });

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button
                      onClick={() => this.props.history.push(`/administration/dashboard`)}
                      className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Strategist Statistics</h2>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                      <ExcelSheet data={strategistTableData} name="strategists_statistics">
                        <ExcelColumn label="Strategist Name" value="name" />
                        <ExcelColumn label="Total Clients Received" value="totalClientReceived" />
                        <ExcelColumn label="Total Closed" value="totalClosed" />
                        <ExcelColumn label="Total Pending" value="totalPending" />
                        <ExcelColumn label="Total Closed + Pending" value="totalClosedPending" />
                        <ExcelColumn label="Total Won/Total Received" value="totalWonTotalReceivedPercentage" />
                        <ExcelColumn label="Total No Response" value="totalNoResponse" />
                        <ExcelColumn label="No Response %" value="noResponsePercentage" />
                        {/* <ExcelColumn label="Total City" value="totalCity" />
                        <ExcelColumn label="City %" value="cityPercentage" /> */}
                        <ExcelColumn label="Green-Actively Engaged" value="totalActivelyEngaged" />
                        <ExcelColumn label="%Green- Actively Engaged" value="activelyEngagedPercentage" />
                        <ExcelColumn label="Green- Somewhat  Engaged" value="totalSomewhatEngaged" />
                        <ExcelColumn label="%Green- Somewhat Engaged" value="somewhatEngagedPercentage" />
                        <ExcelColumn label="Green Not Very Engaged" value="greenNotVeryEngaged" />
                        <ExcelColumn label="% Not Very Engaged" value="notVeryEngagedPercentage" />
                        <ExcelColumn label="Never Had a Call" value="neverHadACall" />
                        <ExcelColumn label="% Never Had a Call" value="neverHadACallPercentage" />
                        <ExcelColumn label="Own Agent Post" value="ownAgent" />
                        <ExcelColumn label="% Own Agent Post" value="ownAgentPercentage" />
                        <ExcelColumn label="Green" value="totalGreen" />
                        <ExcelColumn label="% Green" value="greenPercentage" />
                        <ExcelColumn label="Own Agent - Pre" value="ownAgentPre" />
                        <ExcelColumn label="% Own Agent - Pre" value="ownAgentPrePercentage" />
                        <ExcelColumn label="New" value="totalNew" />
                        <ExcelColumn label="% New" value="newPercentage" />
                        <ExcelColumn label="Out of Area" value="outOfArea" />
                        <ExcelColumn label="% Out of Area" value="outOfAreaPercentage" />
                        <ExcelColumn label="Rented" value="totalRented" />
                        <ExcelColumn label="% Rented" value="rentedPercentage" />
                        <ExcelColumn label="Call Scheduled" value="callScheduled" />
                        <ExcelColumn label="% Call Scheduled" value="callScheduledPercentage" />
                        <ExcelColumn label="Reschedule" value="totalReschedule" />
                        <ExcelColumn label="% Reschedule" value="reschedulePercentage" />
                        <ExcelColumn label="City - do not reach out" value="cityDoNotReachOut" />
                        <ExcelColumn label="% City - do not reach out" value="cityDoNotReachOutPercentage" />
                        <ExcelColumn label="Divorced" value="totalDivorced" />
                        <ExcelColumn label="% Divorced" value="divorcedPercentage" />
                        <ExcelColumn label="Unsubscribe" value="totalUnsubscribe" />
                        <ExcelColumn label="% Unsubscribe" value="unsubscribePercentage" />
                        <ExcelColumn label="Never Had a Call - do not reach out" value="neverHadACallDont" />
                        <ExcelColumn label="% Never Had a Call - do not reach out" value="neverHadACallDontPercentage" />
                        <ExcelColumn label="Listing" value="totalListing" />
                        <ExcelColumn label="% Listing" value="listingPercentage" />
                        <ExcelColumn label="Trying To Schedule" value="tryingToSchedule" />
                        <ExcelColumn label="% Trying To Schedule" value="tryingToSchedulePercentage" />
                        <ExcelColumn label="Relo" value="totalRelo" />
                        <ExcelColumn label="% Relo" value="reloPercentage" />
                        <ExcelColumn label="Never Had A Call - Own Agent" value="neverHadACallOwnAgent" />
                        <ExcelColumn label="% Never Had A Call - Own Agent" value="neverHadACallOwnAgentPercentage" />
                        <ExcelColumn label="Rented On Own" value="RentedOnOwn" />
                        <ExcelColumn label="% Rented On Own" value="rentedOnOwnPercentage" />
                        <ExcelColumn label="Staying_In_Suburbs" value="stayingInSuburbs" />
                        <ExcelColumn label="% Staying_In_Suburbs" value="stayingInSuburbsPercentage" />
                        <ExcelColumn label="Bought Directly" value="boughtDirectly" />
                        <ExcelColumn label="% Bought Directly" value="boughtDirectlyPercentage" />
                        <ExcelColumn label="UJ Green - Actively Engaged" value="ujActivelyEngaged" />
                        <ExcelColumn label="% UJ Green - Actively Engaged" value="ujActivelyEngagedPercentage" />
                        <ExcelColumn label="UJ Green - Somewhat Engaged" value="ujSomewhatEngaged" />
                        <ExcelColumn label="% UJ Green - Somewhat Engaged" value="ujSomewhatEngagedPercentage" />
                        <ExcelColumn label="UJ Green - Not Very Engaged" value="ujNotVeryEngaged" />
                        <ExcelColumn label="% UJ Green - Not Very Engaged" value="ujNotVeryEngagedPercentage" />
                        <ExcelColumn label="New Not Scheduled" value="newNotScheduled" />
                        <ExcelColumn label="% New Not Scheduled" value="newNotScheduledPercentage" />
                        <ExcelColumn label="City - Own Agent" value="cityOwnAgent" />
                        <ExcelColumn label="% City - Own Agent" value="cityOwnAgentPercentage" />
                        <ExcelColumn label="Never Had A Call-Unsubscribe" value="neverHadACallUnsubscribe" />
                        <ExcelColumn label="% Never Had A Call-Unsubscribe" value="neverHadACallUnsubscribePercentage" />
                        <ExcelColumn label="Online-New" value="totalOnlineNew" />
                        <ExcelColumn label="% Online-New" value="totalOnlineNewPercentage" />
                        <ExcelColumn label="Rented On Own - Unsubscribe" value="rentedOnOwnUnsubscribe" />
                        <ExcelColumn label="% Rented On Own - Unsubscribe" value="rentedOnOwnUnsubscribePercentage" />
                        <ExcelColumn label="Fake Client" value="totalFakeClient" />
                        <ExcelColumn label="% Fake Client" value="totalFakeClientPercentage" />
                        <ExcelColumn label="City Temp" value="totalCityTemp" />
                        <ExcelColumn label="% City Temp" value="totalCityTempPercentage" />
                        <ExcelColumn label="City Perm" value="totalCityPerm" />
                        <ExcelColumn label="% City Perm" value="totalCityPermPercentage" />
                        <ExcelColumn label="No Response-AI" value="totalNoResponseAI" />
                        <ExcelColumn label="% No Response-AI" value="totalNoResponseAIPercentage" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-md-offset-4">
                  {
                    (_user && _user.role !== "strategist") &&
                    <div className="form-group material-textfield">
                      <div className="multiselect__checkboxes__field">
                        <ReactMultiSelectCheckboxes
                          styles={multiSelectStyles}
                          width="100%"
                          name="client_strategist"
                          placeholderButtonLabel="All"
                          options={strategistArr}
                          onChange={(event) => this.handleStrOption(event, 'client_strategist')}
                        />
                        <label className="multiselect__checkboxes__field__label">Strategist</label>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-md-5">
                  <ReportCustomeDatePicker showAll={true} filterByDate={this.filterByDate} label="Closed Date" />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(strategistTableData) > 0) ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Table
                            className="table__fixed"
                            sortOrde={true}
                            pagination={_user && _user.role !== "strategist" ? true : false}
                            columns={[
                              {
                                title: 'Strategist Name',
                                width: 220,
                                dataIndex: 'name',
                                key: '1',
                                fixed: 'left',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.name.localeCompare(b.name)
                              },
                              {
                                title: 'Total Clients Received',
                                width: 240,
                                dataIndex: 'totalClientReceived',
                                key: '2',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalClientReceived - b.totalClientReceived
                              },
                              {
                                title: 'Total Closed',
                                width: 200,
                                dataIndex: 'totalClosed',
                                key: '3',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalClosed - b.totalClosed
                              },
                              {
                                title: 'Total Pending',
                                width: 250,
                                dataIndex: 'totalPending',
                                key: '4',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalPending - b.totalPending
                              },
                              {
                                title: 'Total Closed + Pending',
                                width: 280,
                                dataIndex: 'totalClosedPending',
                                key: '5',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalClosedPending - b.totalClosedPending
                              },
                              {
                                title: 'Total Won/Total Received',
                                width: 280,
                                dataIndex: 'totalWonTotalReceivedPercentage',
                                key: '6',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalWonTotalReceivedPercentageSort - b.totalWonTotalReceivedPercentageSort
                              },
                              {
                                title: 'Total No Response',
                                width: 250,
                                dataIndex: 'totalNoResponse',
                                key: '7',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalNoResponse - b.totalNoResponse
                              },
                              {
                                title: 'No Response %',
                                width: 250,
                                dataIndex: 'noResponsePercentage',
                                key: '8',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.noResponsePercentageSort - b.noResponsePercentageSort
                              },
                              // {
                              //   title: 'City',
                              //   width: 200,
                              //   dataIndex: 'totalCity',
                              //   key: '9',
                              //   showSorterTooltip: false,
                              //   sortDirections: ['descend', 'ascend', 'descend'],
                              //   sorter: (a, b) => a.totalCity - b.totalCity
                              // },
                              // {
                              //   title: 'City %',
                              //   width: 150,
                              //   dataIndex: 'cityPercentage',
                              //   key: '10',
                              //   showSorterTooltip: false,
                              //   sortDirections: ['descend', 'ascend', 'descend'],
                              //   sorter: (a, b) => a.cityPercentageSort - b.cityPercentageSort
                              // },
                              {
                                title: 'Green - Actively Engaged',
                                width: 250,
                                dataIndex: 'totalActivelyEngaged',
                                key: '11',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalActivelyEngaged - b.totalActivelyEngaged
                              },
                              {
                                title: '% Green-Actively Engaged',
                                width: 250,
                                dataIndex: 'activelyEngagedPercentage',
                                key: '12',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.activelyEngagedPercentageSort - b.activelyEngagedPercentageSort
                              },
                              {
                                title: 'Green - Somewhat Engaged',
                                width: 270,
                                dataIndex: 'totalSomewhatEngaged',
                                key: '13',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalSomewhatEngaged - b.totalSomewhatEngaged
                              },
                              {
                                title: '% Green - Somewhat Engaged',
                                width: 250,
                                dataIndex: 'somewhatEngagedPercentage',
                                key: '14',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.somewhatEngagedPercentageSort - b.somewhatEngagedPercentageSort
                              },
                              {
                                title: 'Green Not Very Engaged',
                                width: 270,
                                dataIndex: 'greenNotVeryEngaged',
                                key: '15',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.greenNotVeryEngaged - b.greenNotVeryEngaged
                              },
                              {
                                title: '% Green Not Very Engaged',
                                width: 250,
                                dataIndex: 'notVeryEngagedPercentage',
                                key: '16',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.notVeryEngagedPercentageSort - b.notVeryEngagedPercentageSort
                              },
                              {
                                title: 'Never Had a Call',
                                width: 220,
                                dataIndex: 'neverHadACall',
                                key: '17',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACall - b.neverHadACall
                              },
                              {
                                title: '% Never Had a Call',
                                width: 250,
                                dataIndex: 'neverHadACallPercentage',
                                key: '18',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallPercentageSort - b.neverHadACallPercentageSort
                              },
                              {
                                title: 'Own Agent Post',
                                width: 220,
                                dataIndex: 'ownAgent',
                                key: '19',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ownAgent - b.ownAgent
                              },
                              {
                                title: '% Own Agent Post',
                                width: 220,
                                dataIndex: 'ownAgentPercentage',
                                key: '20',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ownAgentPercentageSort - b.ownAgentPercentageSort
                              },
                              {
                                title: 'Green',
                                width: 170,
                                dataIndex: 'totalGreen',
                                key: '21',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalGreen - b.totalGreen
                              },
                              {
                                title: '% Green',
                                width: 170,
                                dataIndex: 'greenPercentage',
                                key: '22',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.greenPercentageSort - b.greenPercentageSort
                              },
                              {
                                title: 'Own Agent - Pre',
                                width: 270,
                                dataIndex: 'ownAgentPre',
                                key: '23',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ownAgentPre - b.ownAgentPre
                              },
                              {
                                title: '% Own Agent - Pre',
                                width: 270,
                                dataIndex: 'ownAgentPrePercentage',
                                key: '24',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ownAgentPrePercentageSort - b.ownAgentPrePercentageSort
                              },
                              {
                                title: 'New',
                                width: 170,
                                dataIndex: 'totalNew',
                                key: '25',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalNew - b.totalNew
                              },
                              {
                                title: '% New',
                                width: 170,
                                dataIndex: 'newPercentage',
                                key: '26',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.newPercentageSort - b.newPercentageSort
                              },
                              {
                                title: 'Out of Area',
                                width: 200,
                                dataIndex: 'outOfArea',
                                key: '27',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.outOfArea - b.outOfArea
                              },
                              {
                                title: '% Out of Area',
                                width: 200,
                                dataIndex: 'outOfAreaPercentage',
                                key: '28',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.outOfAreaPercentageSort - b.outOfAreaPercentageSort
                              },
                              {
                                title: 'Rented',
                                width: 170,
                                dataIndex: 'totalRented',
                                key: '29',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalRented - b.totalRented
                              },
                              {
                                title: '% Rented',
                                width: 170,
                                dataIndex: 'rentedPercentage',
                                key: '30',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.rentedPercentageSort - b.rentedPercentageSort
                              },
                              {
                                title: 'Call Scheduled',
                                width: 200,
                                dataIndex: 'callScheduled',
                                key: '31',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.callScheduled - b.callScheduled
                              },
                              {
                                title: '% Call Scheduled',
                                width: 240,
                                dataIndex: 'callScheduledPercentage',
                                key: '32',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.callScheduledPercentageSort - b.callScheduledPercentageSort
                              },
                              {
                                title: 'Reschedule',
                                width: 170,
                                dataIndex: 'totalReschedule',
                                key: '33',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalReschedule - b.totalReschedule
                              },
                              {
                                title: '% Reschedule',
                                width: 220,
                                dataIndex: 'reschedulePercentage',
                                key: '34',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.reschedulePercentageSort - b.reschedulePercentageSort
                              },
                              {
                                title: 'City - do not reach out',
                                width: 250,
                                dataIndex: 'cityDoNotReachOut',
                                key: '35',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.cityDoNotReachOut - b.cityDoNotReachOut
                              },
                              {
                                title: '% City - do not reach out',
                                width: 300,
                                dataIndex: 'cityDoNotReachOutPercentage',
                                key: '36',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.cityDoNotReachOutPercentageSort - b.cityDoNotReachOutPercentageSort
                              },
                              {
                                title: 'Divorced',
                                width: 170,
                                dataIndex: 'totalDivorced',
                                key: '37',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalDivorced - b.totalDivorced
                              },
                              {
                                title: '% Divorced',
                                width: 170,
                                dataIndex: 'divorcedPercentage',
                                key: '38',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.divorcedPercentageSort - b.divorcedPercentageSort
                              },
                              {
                                title: 'Unsubscribe',
                                width: 200,
                                dataIndex: 'totalUnsubscribe',
                                key: '39',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalUnsubscribe - b.totalUnsubscribe
                              },
                              {
                                title: '% Unsubscribe',
                                width: 200,
                                dataIndex: 'unsubscribePercentage',
                                key: '40',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.unsubscribePercentageSort - b.unsubscribePercentageSort
                              },
                              {
                                title: 'Never Had a Call - do not reach out',
                                width: 350,
                                dataIndex: 'neverHadACallDont',
                                key: '41',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallDont - b.neverHadACallDont
                              },
                              {
                                title: '% Never Had a Call - do not reach out',
                                width: 350,
                                dataIndex: 'neverHadACallDontPercentage',
                                key: '42',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallDontPercentageSort - b.neverHadACallDontPercentageSort
                              },
                              {
                                title: 'Listing',
                                width: 170,
                                dataIndex: 'totalListing',
                                key: '43',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalListing - b.totalListing
                              },
                              {
                                title: '% Listing',
                                width: 170,
                                dataIndex: 'listingPercentage',
                                key: '44',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.listingPercentageSort - b.listingPercentageSort
                              },
                              {
                                title: 'Trying To Schedule',
                                width: 220,
                                dataIndex: 'tryingToSchedule',
                                key: '45',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.tryingToSchedule - b.tryingToSchedule
                              },
                              {
                                title: '% Trying To Schedule',
                                width: 270,
                                dataIndex: 'tryingToSchedulePercentage',
                                key: '46',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.tryingToSchedulePercentageSort - b.tryingToSchedulePercentageSort
                              },
                              {
                                title: 'Relo',
                                width: 170,
                                dataIndex: 'totalRelo',
                                key: '47',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalRelo - b.totalRelo
                              },
                              {
                                title: '% Relo',
                                width: 170,
                                dataIndex: 'reloPercentage',
                                key: '48',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.reloPercentageSort - b.reloPercentageSort
                              },
                              {
                                title: 'Never Had A Call - Own Agent',
                                width: 300,
                                dataIndex: 'neverHadACallOwnAgent',
                                key: '49',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallOwnAgent - b.neverHadACallOwnAgent
                              },
                              {
                                title: '% Never Had A Call - Own Agent',
                                width: 340,
                                dataIndex: 'neverHadACallOwnAgentPercentage',
                                key: '50',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallOwnAgentPercentageSort - b.neverHadACallOwnAgentPercentageSort
                              },
                              {
                                title: 'Rented On Own',
                                width: 200,
                                dataIndex: 'RentedOnOwn',
                                key: '51',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.RentedOnOwn - b.RentedOnOwn
                              },
                              {
                                title: '% Rented On Own',
                                width: 230,
                                dataIndex: 'rentedOnOwnPercentage',
                                key: '52',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.rentedOnOwnPercentageSort - b.rentedOnOwnPercentageSort
                              },
                              {
                                title: 'Staying_In_Suburbs',
                                width: 240,
                                dataIndex: 'stayingInSuburbs',
                                key: '53',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.stayingInSuburbs - b.stayingInSuburbs
                              },
                              {
                                title: '% Staying_In_Suburbs',
                                width: 270,
                                dataIndex: 'stayingInSuburbsPercentage',
                                key: '54',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.stayingInSuburbsPercentageSort - b.stayingInSuburbsPercentageSort
                              },
                              {
                                title: 'Bought Directly',
                                width: 200,
                                dataIndex: 'boughtDirectly',
                                key: '55',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.boughtDirectly - b.boughtDirectly
                              },
                              {
                                title: '% Bought Directly',
                                width: 230,
                                dataIndex: 'boughtDirectlyPercentage',
                                key: '56',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.boughtDirectlyPercentageSort - b.boughtDirectlyPercentageSort
                              },
                              {
                                title: 'UJ Green - Actively Engaged',
                                width: 300,
                                dataIndex: 'ujActivelyEngaged',
                                key: '57',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujActivelyEngaged - b.ujActivelyEngaged
                              },
                              {
                                title: '% UJ Green - Actively Engaged',
                                width: 300,
                                dataIndex: 'ujActivelyEngagedPercentage',
                                key: '58',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujActivelyEngagedPercentageSort - b.ujActivelyEngagedPercentageSort
                              },
                              {
                                title: 'UJ Green - Somewhat Engaged',
                                width: 320,
                                dataIndex: 'ujSomewhatEngaged',
                                key: '59',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujSomewhatEngaged - b.ujSomewhatEngaged
                              },
                              {
                                title: '% UJ Green - Somewhat Engaged',
                                width: 320,
                                dataIndex: 'ujSomewhatEngagedPercentage',
                                key: '60',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujSomewhatEngagedPercentageSort - b.ujSomewhatEngagedPercentageSort
                              },
                              {
                                title: 'UJ Green - Not Very Engaged',
                                width: 320,
                                dataIndex: 'ujNotVeryEngaged',
                                key: '61',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujNotVeryEngaged - b.ujNotVeryEngaged
                              },
                              {
                                title: '% UJ Green - Not Very Engaged',
                                width: 320,
                                dataIndex: 'ujNotVeryEngagedPercentage',
                                key: '62',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ujNotVeryEngagedPercentageSort - b.ujNotVeryEngagedPercentageSort
                              },
                              {
                                title: 'New Not Scheduled',
                                width: 270,
                                dataIndex: 'newNotScheduled',
                                key: '63',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.newNotScheduled - b.newNotScheduled
                              },
                              {
                                title: '% New Not Scheduled',
                                width: 270,
                                dataIndex: 'newNotScheduledPercentage',
                                key: '64',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.newNotScheduledPercentageSort - b.newNotScheduledPercentageSort
                              },
                              {
                                title: 'City - Own Agent',
                                width: 230,
                                dataIndex: 'cityOwnAgent',
                                key: '65',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.cityOwnAgent - b.cityOwnAgent
                              },
                              {
                                title: '% City - Own Agent',
                                width: 230,
                                dataIndex: 'cityOwnAgentPercentage',
                                key: '66',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.cityOwnAgentPercentageSort - b.cityOwnAgentPercentageSort
                              },
                              {
                                title: 'Never Had A Call-Unsubscribe',
                                width: 310,
                                dataIndex: 'neverHadACallUnsubscribe',
                                key: '67',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallUnsubscribe - b.neverHadACallUnsubscribe
                              },
                              {
                                title: '% Never Had A Call-Unsubscribe',
                                width: 350,
                                dataIndex: 'neverHadACallUnsubscribePercentage',
                                key: '68',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.neverHadACallUnsubscribePercentageSort - b.neverHadACallUnsubscribePercentageSort
                              },
                              {
                                title: 'Online-New',
                                width: 220,
                                dataIndex: 'totalOnlineNew',
                                key: '69',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalOnlineNew - b.totalOnlineNew
                              },
                              {
                                title: '% Online-New',
                                width: 220,
                                dataIndex: 'totalOnlineNewPercentage',
                                key: '70',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalOnlineNewPercentageSort - b.totalOnlineNewPercentageSort
                              },
                              {
                                title: 'Rented On Own - Unsubscribe',
                                width: 300,
                                dataIndex: 'rentedOnOwnUnsubscribe',
                                key: '71',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.rentedOnOwnUnsubscribe - b.rentedOnOwnUnsubscribe
                              },
                              {
                                title: '% Rented On Own - Unsubscribe',
                                width: 340,
                                dataIndex: 'rentedOnOwnUnsubscribePercentage',
                                key: '72',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.rentedOnOwnUnsubscribePercentageSort - b.rentedOnOwnUnsubscribePercentageSort
                              },
                              {
                                title: 'Fake Client',
                                width: 200,
                                dataIndex: 'totalFakeClient',
                                key: '73',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalFakeClient - b.totalFakeClient
                              },
                              {
                                title: '% Fake Client',
                                width: 200,
                                dataIndex: 'totalFakeClientPercentage',
                                key: '74',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalFakeClientPercentageSort - b.totalFakeClientPercentageSort
                              },
                              {
                                title: 'City Temp',
                                width: 170,
                                dataIndex: 'totalCityTemp',
                                key: '75',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalCityTemp - b.totalCityTemp
                              },
                              {
                                title: '% City Temp',
                                width: 190,
                                dataIndex: 'totalCityTempPercentage',
                                key: '76',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalCityTempPercentageSort - b.totalCityTempPercentageSort
                              },
                              {
                                title: 'City Perm',
                                width: 170,
                                dataIndex: 'totalCityPerm',
                                key: '77',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalCityPerm - b.totalCityPerm
                              },
                              {
                                title: '% City Perm',
                                width: 200,
                                dataIndex: 'totalCityPermPercentage',
                                key: '78',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalCityPermPercentageSort - b.totalCityPermPercentageSort
                              },
                              {
                                title: 'No Response-AI',
                                width: 200,
                                dataIndex: 'totalNoResponseAI',
                                key: '79',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalNoResponseAI - b.totalNoResponseAI
                              },
                              {
                                title: '% No Response-AI',
                                width: 240,
                                dataIndex: 'totalNoResponseAIPercentage',
                                key: '80',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.totalNoResponseAIPercentageSort - b.totalNoResponseAIPercentageSort
                              },
                            ]}
                            dataSource={strategistTableData}
                            pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions }}
                            scroll={{ y: 420 }}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>

      </div>
    )
  }
}
