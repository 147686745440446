import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import TopNavigation from '../../../../components/TopNavigation/container'
import SidebarNavigation from '../../../../components/SidebarNavigation/component'
import moment from 'moment'
import Loader from '../../../../components/Loader'
import { LaunchDateIcon, CaretRightIcon, TrackIcon, DashboardColorIcon, CalendarOtherIcon, TourIcon, HeartIcon, NotReadyTourIcon, CloseIcon, PdfIcon } from '../../../../components/icons'
import ReactModal from 'react-modal'
import UserContext from "../../../App/Context"
import { DateRange } from 'react-date-range'
import _ from 'lodash'
import { Spin } from 'antd'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import axios from 'axios'
// import InitialStrategyCallPdf from './../../assets/images/initial_strategy_call.pdf'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2
const cookies = new Cookies()
let user = ''

let curr = new Date() // get current date
let first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
let last = first + 6 // last day is the first day + 6
const firstday = moment().subtract(1, 'weeks').startOf('week').toDate()
const lastday = moment().subtract(1, 'weeks').endOf('week').toDate()
const monthFirstDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD hh:mm')
const monthLastDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD hh:mm')

export default class DashboardComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)

    this.state = {
      todayDate: moment().format('LL'),
      weekFirstDate: moment().startOf('week').format('LL'),
      weekLastDate: moment().endOf('week').format('LL'),
      monthFirstDate: moment().startOf('month').format('LL'),
      monthLastDate: moment().endOf('month').format('LL'),
      datePickerModel: false,
      customFirstDate: moment(),
      customEndDate: moment(),
      expandList: false,
      dashboardData: [],
      isLoading: false,
    }
  }

  componentDidMount() {
     user = cookies.get('user')

    // if (user && user.onboarding_status && parseInt(user.onboarding_status) > 0 && parseInt(user.onboarding_status) < 7) {
    //   this.props.history.push('/agent/signup')
    // } else
    if (user) {
      document.title = "Dashboard | Jungler"
      document.documentElement.scrollTop = 0
      this.setState({ isLoading: true })
      const data = {
        from: moment(this.state.todayDate).format("YYYY-MM-DD"),
        to: moment(this.state.todayDate).format("YYYY-MM-DD")
      }
      localStorage.setItem('dashboardFromToDate', JSON.stringify(data))
      this.props.getDashboardData(data)
    }
  }

  static getDerivedStateFromProps(prop, state) {
    if (state.dashboardData !== prop.dashboardData.data) {
      return { dashboardData: _.get(prop.dashboardData, 'data', []), isLoading: false }
    }
    return null;
  }

  changeDate(e) {
    const data = {}
    if (e.target.value === "today") {
      data.from = moment(this.state.todayDate).format("YYYY-MM-DD")
      data.to = moment(this.state.todayDate).format("YYYY-MM-DD")
      this.props.getDashboardData(data)
    } else if (e.target.value === "week") {
      data.from = moment(this.state.weekFirstDate).format("YYYY-MM-DD")
      data.to = moment(this.state.weekLastDate).format("YYYY-MM-DD")
      this.props.getDashboardData(data)
    } else if (e.target.value === "month") {
      data.from = moment(this.state.monthFirstDate).format("YYYY-MM-DD")
      data.to = moment(this.state.monthLastDate).format("YYYY-MM-DD")
      this.props.getDashboardData(data)
    } else if (e.target.value === 'custom') {
      this.setState({ datePickerModel: true })
    }

    localStorage.setItem('dashboardFromToDate', JSON.stringify(data))
  }


  afterOpenModal() { }

//   opemModal() {
// 	 // this.setState({ modal: true })
//    this.setState({ isLoading: true })

//     let data = {}
//    // data.agent_id =  "16246"
//      data.agent_id = user.id

//     const jwtToken = cookies.get('Authorization')
//     axios({
//       method: 'POST',
//       url: `${HOSTNAME}/downloaddoc`,
//       data: data,
//       'headers': { 'Authorization': jwtToken }
//     })
//       .then(res => {
//         if(res.data.status) {
//           this.setState({ isLoading: false })
//           var url =res.data.data[0]; 
//           var aTag = document.createElement('a'); 
//           aTag.setAttribute('href',url); 
//           aTag.setAttribute("target", "_blank");
//           aTag.setAttribute('download',"download"); 
//           document.body.appendChild(aTag); 
//           aTag.click(); 
//           document.body.removeChild(aTag);
//         } else {
//           this.setState({ isLoading: false })
//           alert(res.data.message);
//         }
       
     
//       })
//       .catch(err => {
//         this.setState({ isLoading: false })
//       })
// 	}

  openDatePickerRange() {
    this.setState({ datePickerModel: !this.state.datePickerModel })
  }

  closeCustomDate(e) {
    this.setState({ datePickerModel: false, customFirstDate: this.state.customFirstDate, customEndDate: this.state.customEndDate })
  }

  handleSelect(range) {
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ customFirstDate: startDate, customEndDate: endDate })
  }

  submitCustomDate() {
    this.setState({ datePickerModel: false }, () => {
      const data = {
        from: moment(this.state.customFirstDate).format("YYYY-MM-DD"),
        to: moment(this.state.customEndDate).format("YYYY-MM-DD")
      }
      localStorage.setItem('dashboardFromToDate', JSON.stringify(data))
      this.props.getDashboardData(data)
    })
  }

  townList() {
    this.setState({ expandList: !this.state.expandList })
  }

  navigateToClientDetail(val) {
    this.props.history.push({ pathname: '/agent/clients/all-clients', state: {status: val }})
  }
  render() {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate, dashboardData, pdfString } = this.state
    return (
      <div>
      <Spin size="large" spinning={this.state.isLoading || this.props.loading} indicator={<Loader />}>
        <div className="layout-has-sidebar">
          <SidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />

          <main className="dashboard-layout-content">
            <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading-title"><span className="heading-icon"><DashboardColorIcon className="" /></span>Welcome {_.startCase(_.toLower(this.context.userData && this.context.userData.first_name))}!</h2>
                  <p className="heading-subtitle">Reports Overview</p>
                </div>
              </div>
              {
                (user && (user.role) !== "business development")?
                <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield date__selector">
                    <select
                      className="form-control custom-select material-textfield-input textfield-input-lg"
                      required
                      onChange={(e) => this.changeDate(e)}
                    >
                      <option value="today">{`Today (${todayDate})`}</option>
                      <option value="week">{`This Week (${weekFirstDate} - ${weekLastDate})`}</option>
                      <option value="month">{`This Month (${monthFirstDate} - ${monthLastDate})`}</option>
                      <option value="custom">{`Custom (${moment(this.state.customFirstDate).format('LL')} - ${moment(this.state.customEndDate).format('LL')})`}</option>
                    </select>
                    <label className="material-textfield-label label-lg">Date</label>
                    <CalendarOtherIcon className="datepicker-calendar-icon" />
                  </div>

                  {/*<div className="card-info form-group">*/}
                  {/*  <div className="card-info-inner">*/}
                  {/*    <LaunchDateIcon className="card-info-icon"/>{`You can only see the stats for clients you have received since June 24th, 2020`}*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/* <div className="card__tile">
                  <div className="card__tile__icon green">
                    <CalendarOtherIcon className="calendar-icon" />
                  </div>
                  <div onClick={() => this.props.history.push({ pathname: `/agent/calendar` })} className="card__tile__content">
                    <div className="card__tile__title">Calendar</div>
                    <div className="card__tile__subtitle">See or schedule all your appointments with clients</div>
                  </div>
                  <div className="card__tile__arrow"><CaretRightIcon /></div>
                </div>
                <div className="card__tile">
                  <div className="card__tile__icon green">
                    <ChatIcon className="chat-icon" />
                  </div>
                  <div onClick={() => this.props.history.push({ pathname: `/agent-chat-with-client` })} className="card__tile__content">
                    <div className="card__tile__title">Chat with Client</div>
                    <div className="card__tile__subtitle">See or chat with all your clients</div>
                  </div>
                  <div className="card__tile__arrow"><CaretRightIcon /></div>
                </div> */}
                  
                  <div 
                    className="card card__tile__other card__administrator cursor__pointer"
                    onClick={() =>
                      this.props.history.push({
                        pathname: '/agent/clients/all-clients',
                        state: JSON.parse(localStorage.getItem('dashboardFromToDate'))
                      })
                    }
                  >
                    <div className="card__tile__title">Clients received</div>
                    <div className="card__tile__subtitle">Number of clients assigned to you by strategist during the time you selected. May also reflect newly added towns for a client.</div>
                    <div className="clients__received"><span>{dashboardData.totalClients}</span> Clients received</div>
                  </div>
                  
                  <div className="card card__tile__other">
                   <div className="d__flex align__items__center justify__content__between">
                     <div className="card__tile__title">Total Closed Deals</div>
                     <div className="total__closed__count">{dashboardData.closedCount}</div>
                   </div>
                   <ul className="total__closed__list">
                     {dashboardData && dashboardData.closed && dashboardData.closed.map((close, index) => {
                        return (
                          <li key={"closed__"+index}>
                            <span>{close.closedYear} - ${close.totalSales.toLocaleString()} Total Sales</span>
                            <span>{close.totalClosed}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  {/*<div className="card card__tile__other">*/}
                  {/*  <div className="card__tile__title"><TrackIcon className="mr__15" /> Clients by Status</div>*/}
                  {/*  <ul className="clients__status__list">*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Actively Engaged')}>*/}
                  {/*      <span>Actively Engaged (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Actively Engaged" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Initial Welcome Sent')}>*/}
                  {/*      <span to={'/'}>Initial Welcome Sent (*/}
                  {/*        {_.get(_.find(dashboardData.status, { status: "Initial Welcome Sent" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Moderately Engaged')}>*/}
                  {/*      <span to={'/'}>Moderately Engaged (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Moderately Engaged" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Accepted Offer')}>*/}
                  {/*      <span to={'/'}>Accepted Offer (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Accepted Offer" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Signed Contract')}>*/}
                  {/*      <span to={'/'}>Signed Contract (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Signed Contract" }), "statusCount", '0')}*/}
                  {/*        )</span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Closed')}>*/}
                  {/*      <span to={'/'}>Closed (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Closed" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*    <li onClick={this.navigateToClientDetail.bind(this, 'Unresponsive')}>*/}
                  {/*      <span to={'/'}>Unresponsive (*/}
                  {/*      {_.get(_.find(dashboardData.status, { status: "Unresponsive" }), "statusCount", '0')}*/}
                  {/*        )*/}
                  {/*      </span>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}

                  {/* <div className="card__tile" onClick={this.opemModal.bind(this)}> 
                    <div className="card__tile__icon grey">
                      <PdfIcon />
                    </div>
                    <div className="card__tile__content">
                      <div className="card__tile__title">Agent's Signed Agreement </div>
                    </div>
                    <div className="card__tile__arrow"><span className="card__tile__count"></span><CaretRightIcon /> </div>
                  </div> */}

                </div>
                {/* This is for never touch section */}
                {/*<div className="col-md-6">*/}
                {/*  <div onClick={() => this.props.history.push('/agent/task-list/new-never-touched')} className="card__tile">*/}
                {/*    <div className={dashboardData.notTouched !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>*/}
                {/*      <HeartIcon />*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__content">*/}
                {/*      <div className="card__tile__title">New: Never Touched </div>*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.notTouched}</span><CaretRightIcon /> </div>*/}
                {/*  </div>*/}

                {/*  /!* This is for Not touched in 4 weeks section *!/*/}
                {/*  <div onClick={() => this.props.history.push('/agent/task-list/not-touched-in-4-weeks')} className="card__tile">*/}
                {/*    <div className={dashboardData.notTouched4Week !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>*/}
                {/*      <HeartIcon />*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__content">*/}
                {/*      <div className="card__tile__title">Not touched in 4 weeks  </div>*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.notTouched4Week}</span><CaretRightIcon /> </div>*/}
                {/*  </div>*/}

                {/*  /!* This is for Overdue Follow up Date section *!/*/}
                
                 <div onClick={() => this.props.history.push({ pathname: '/agent/clients/all-clients', state: { overdue_follow_ups: true } })} className="card__tile">
                   <div className={dashboardData.overdueClients !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
                     <CalendarOtherIcon className="calendar-icon" />
                   </div>
                   <div className="card__tile__content">
                     <div className="card__tile__title">Overdue Follow up Date  </div>
                     <div className="card__tile__subtitle">Clients that have a follow up date that has passed   </div>
                   </div>
                   <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.overdueClients}</span><CaretRightIcon /> </div>
                 </div>

                {/*  /!* This is for never touch section*/}
                {/*  <div onClick={() => this.props.history.push('/agent/task-list/new-never-touched')} className="card__tile">
                {/*    <div className={dashboardData.notTouched !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>*/}
                {/*      <HeartIcon />*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__content">*/}
                {/*      <div className="card__tile__title">New: Never Touched </div>*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.notTouched}</span><CaretRightIcon /> </div>*/}
                {/*  </div>*!/

                {/*  /!* This is section for no follow up date *!/*/}
                 <div onClick={() => this.props.history.push({ pathname: '/agent/clients/all-clients', state: {no_follow_up: true}})} className="card__tile">
                   <div className={dashboardData.noFollowUpClients !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
                     <CalendarOtherIcon className="calendar-icon" />
                   </div>
                   <div className="card__tile__content">
                     <div className="card__tile__title">No Follow up Date  </div>
                     <div className="card__tile__subtitle">Clients with no followup date</div>
                   </div>
                   <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.noFollowUpClients}</span><CaretRightIcon /> </div>
                 </div>

                {/*  /!* This is section for no status *!/*/}
                 <div onClick={() => this.props.history.push({ pathname: '/agent/clients/all-clients', state: { no_status: true} })} className="card__tile">
                   <div className={dashboardData.noStatusClients !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>
                     <TrackIcon className="track-icon" />
                   </div>
                   <div className="card__tile__content">
                     <div className="card__tile__title">No Status  </div>
                     <div className="card__tile__subtitle">Clients that you have not put a status </div>
                   </div>
                   <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.noStatusClients}</span><CaretRightIcon /> </div>
                 </div>

                {/*  /!* This is section for no town tour *!/*/}
                {/*  <div onClick={() => this.props.history.push({ pathname: '/agent/clients/all-clients', state: {no_town_tour: true }})} className="card__tile">*/}
                {/*    <div className={dashboardData.noTownTour !== 0 ? "card__tile__icon red" : "card__tile__icon grey"}>*/}
                {/*      <TourIcon />*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__content">*/}
                {/*      <div className="card__tile__title">No Town Tour </div>*/}
                {/*      <div className="card__tile__subtitle">Clients you have not had a town tour with </div>*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.noTownTour}</span><CaretRightIcon /> </div>*/}
                {/*  </div>*/}

                {/*  /!* This is section for client not ready to tour yet *!/*/}
                {/*  <div onClick={() => this.props.history.push('/agent/task-list/interested-but-don’t-want-town-tour-yet')} className="card__tile">*/}
                {/*    <div className={dashboardData.notReadyToTour !== 0 ? "card__tile__icon orange" : "card__tile__icon grey"}>*/}
                {/*      <NotReadyTourIcon />*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__content">*/}
                {/*      <div className="card__tile__title">Clients Not Ready to Tour Yet</div>*/}
                {/*      <div className="card__tile__subtitle">Don't reach out unless indicated by your strategist.</div>*/}
                {/*    </div>*/}
                {/*    <div className="card__tile__arrow"><span className="card__tile__count">{dashboardData.notReadyToTour}</span><CaretRightIcon /> </div>*/}
                {/*  </div>*/}

                {/*  <div className="card card__tile__other">*/}
                {/*    <div className="card__tile__title">Towns Covered ({(_.size(dashboardData && dashboardData.towns))})</div>*/}
                {/*    {*/}
                {/*      (_.size(dashboardData && dashboardData.towns) > 3) &&*/}
                {/*      <div onClick={() => this.townList()} className="expand__list">{this.state.expandList ? "Collapse List" : "Expand List"}</div>*/}
                {/*    }*/}

                {/*    {*/}
                {/*      !(this.state.expandList) ?*/}
                {/*        <ul className="clients__status__list">*/}
                {/*          {*/}
                {/*            dashboardData.towns && dashboardData.towns.map((t, idx) => {*/}
                {/*              return (*/}
                {/*                idx < 3 && <li key={idx}><span>{t.name}</span></li>*/}
                {/*              )*/}
                {/*            })*/}
                {/*          }*/}
                {/*        </ul>*/}
                {/*        :*/}
                {/*        <ul className="clients__status__list">*/}
                {/*          {*/}
                {/*            dashboardData.towns && dashboardData.towns.map((t, index) => {*/}
                {/*              return (*/}
                {/*                <li key={index}><span>{t.name}</span></li>*/}
                {/*              )*/}
                {/*            })*/}
                {/*          }*/}
                {/*        </ul>*/}
                {/*    }*/}

                {/*  </div>*/}

                {/*</div>*/}
              </div>:
              <div></div>
              }
            </div>
            {
              (user && (user.role) === "business development")?
              <div className="row">
              <div className="col-md-6">
                <div className="strategist__pages__tile">
                <div onClick={() => this.props.history.push(`/report/business-development`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Business Development</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
                </div>
              </div>
            </div>
              :
              <div></div>
            }

            </div>
          </main>

          <ReactModal
            isOpen={this.state.datePickerModel}
            onAfterOpen={(e) => this.afterOpenModal(e)}
            onRequestClose={(e) => this.openDatePickerRange(e)}
            contentLabel="Date Picker"
            portalClassName="react-modal"
          >
            <div className="react-modal-dialog react-modal-dialog-sm react-modal-dialog-centered">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Date Picker</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeCustomDate.bind(this)}><CloseIcon /></button>
              </div>
              <div className="react-modal-body">
                <div className="date-range-picker">
                  <DateRange
                    onInit={this.handleSelect.bind(this)}
                    onChange={this.handleSelect.bind(this)}
                    startDate={this.state.customFirstDate}
                    endDate={this.state.customEndDate}
                  />
                </div>
                <div className="text-right">
                  <button type="button" className="btn btn__btn btn-dark w__150" onClick={() => this.submitCustomDate()}>Submit</button>
                </div>
              </div>
            </div>
          </ReactModal>


        </div>
      </Spin>
   
       
       </div>



    )
  }
}
