/* eslint-disable no-nested-ternary */
import React from 'react'
import { get } from 'lodash'
import config from '../../../../../config/index'

export default function AboutUserComponent({ clientQuestion, ...props }) {
  const questionnaireExist = clientQuestion && clientQuestion.length > 0
  const questionnaireArrHalfLength = questionnaireExist ? Math.ceil(clientQuestion.length / 2) : 0

  const questionnaireCol1 = questionnaireExist ? clientQuestion.slice(0, questionnaireArrHalfLength) : []
  const questionnaireCol2 = questionnaireExist ? clientQuestion.slice(questionnaireArrHalfLength, clientQuestion.length) : []

  return (
    <div className="card mb__30">
      <div className="card-header d__flex align__items__center">
        <div>
          <h5 className="card-title mb__0">About {get(props, 'clientDetail.client_full_name', '')}</h5>
        </div>
      </div>
      {questionnaireExist ?
        (<div className="card-body card-body-sm">
          <div className="row">
            <div className="col-md-6">
              {
                questionnaireCol1.map(question => {
                  return (
                    <div className="info__block">
                      <label>
                        {question.questionLabel}
                      </label>
                      <p>{question.text}</p>
                    </div>
                  )
                })}
            </div>
            <div className="col-md-6">
              {
                questionnaireCol2.map(question => {
                  return (
                    <div className="info__block">
                      <label>
                        {question.questionLabel}
                      </label>
                      <p>{question.text}</p>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>) :
        <div className="card-body card-body-sm">
          <div className="row">
            <div className="col-md-12">
              <div className="blank__state__text">
                This client has not filled out the AI Questionnaire yet. Once they do you will see their answers here.
            </div>
            </div>
          </div>
        </div>

      }
    </div>
  )
}
