import { List } from 'immutable';
import Config from '../../config';
import { fetch } from '../../utils';

const HOSTNAME = process.env.API_HOSTNAME;
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getClientStrategist = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.user_id}/strategists`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientStrategistData = data => {
  const queryString = new URLSearchParams({
    date: data.date,
    timezone: data.timezone
  })

  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategist_id}/availability-dates?${queryString}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientStrategistAvaillability = data => {
  return fetch(`${HOSTNAME}/availability/get_agent_available_dates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const getClientAgent = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/agents`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const scheduleMeeting = data => {
  return fetch(`${HOSTNAME}/client/create_client_strategist_appointment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getTowns = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/towns`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    })
}


export const getClientsByTown = (data) => {
  return fetch(`${process.env.REACT_APP_API_HOSTNAME_V2}/clients/town?townId=${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  })
}


export const addTowns = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/recommended-towns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getRecommendedTowns = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/recommended-towns`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    })
}

export const removeTown = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/recommended-town-status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getInsightData = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/town/${data.town_id}/town-research`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const getClientTownRateData = (data) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
  // return fetch(`${HOSTNAME}/client/get_client_town_rate`, {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // })
  // .then((res) => {
  //   return res.json()
  // })
  // .then((payload) => {
  //   return payload
  // })
}

export const updateClientTownRatingData = data => {
  return fetch(`${HOSTNAME}/client/update_client_town_rating`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientTownRateDataById = data => {
  return fetch(`${HOSTNAME}/client/get_client_town_rate_by_id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientAgentMessage = data => {
  return fetch(`${HOSTNAME}/client/get_client_agent_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const shareClientInfoToAgent = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/share-info-with-agent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .catch((error) => {
      throw error
    })
};

export const getSearchedClients = (data) => {
  return fetch(`${HOSTNAME}/web/products/search?search=${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  })
}
