import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import config from '../../../config'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class LoginReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      users_limit:100,
      activePage:1,
      sortByKey: '',
      sortByType : 0,
      isLoadingData: true,
      totalPages:0,
      search: '',
    }
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)

      const LoginRecordsParams = {}
      LoginRecordsParams.users_limit = this.state.users_limit;
      LoginRecordsParams.users_offset = this.state.activePage;
      LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.getLoginRecords(LoginRecordsParams)

      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent')
      }else if(_user.role==='local'){
       this.props.history.push('/agent')
      }else if(_user.role==='strategist'){
        this.props.history.push('/home/new-clients')
      }

    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.getLoginRecordsPhase === "success"){
      this.setState({ isLoadingData: false,  totalPages:nextProps.loginRecordsData.user_counts.all_count })
    }
  }

 // On entry change get records
  handleEntryChange(event){
    this.setState({users_limit: event.target.value, activePage: 1}, ()=>{
      const LoginRecordsParams = {}
      LoginRecordsParams.users_limit = this.state.users_limit;
      LoginRecordsParams.users_offset = this.state.activePage;
      LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.getLoginRecords(LoginRecordsParams)
    });

  }
 // Paginations change get records

  handlePageChange(pageNumber) {
    this.setState({ isLoadingData: true, activePage: pageNumber}, ()=> {
      const LoginRecordsParams = {}
      LoginRecordsParams.users_limit = this.state.users_limit;
      LoginRecordsParams.users_offset = this.state.activePage;
      LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.getLoginRecords(LoginRecordsParams)
    })
  }

  // Shorting the Table from Headings

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType}, ()=>{
      const LoginRecordsParams = {}
      LoginRecordsParams.users_limit = this.state.users_limit;
      LoginRecordsParams.users_offset = this.state.activePage;
      LoginRecordsParams.sortBy = {key: val, type: sortByType};
      this.props.getLoginRecords(LoginRecordsParams)
    })
   
  }


   onSearch(e){
    this.setState({search:e.target.value})
  }


  render() {

    const {
      loginRecordsData,
    } = this.props

    let loginRecords = '';
    let loginRecordsAllData = loginRecordsData && loginRecordsData.users
    if(loginRecordsAllData){
      loginRecords = loginRecordsAllData.filter(
        (LoginRecordAllData) =>{
          return (
            (LoginRecordAllData.full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Login Records</a>
                </div>
                <div className="close__btn">
                  <Link to={'/administration/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container">
        <div className="row">
            <div className="col-md-12">
            <div className="reports__flex">
              <div className="right__cont right__cont--full right__flex">
            

              <span className="totalRecords__label">Total Records ({loginRecords && loginRecords.length > 0  ? loginRecords.length : 0})</span>
  
        
              <span className="enteries-span">
              <label className="enteries-label">Entries</label>
                <select className="selectinputs__global" value = {this.state.users_limit} onChange={this.handleEntryChange.bind(this)}>
                  {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                      <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                    })}
                </select>
              </span>  
         
              
              </div>
              </div>
  
   
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form className="navbar__header--form">
                <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input reportsearchsminus add" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search Users"/>
              </span>
              </form>
          </div>
          </div>
          
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
                <div>
                  <div className="filter__sub--container filter__sub--container-edit">
                    <div className="jungler__list useslist__extra">
                       <div className="table-responsive">
                         <table className="table table-striped">
                            <thead>
                              <tr>
                                <th onClick={this.sortData.bind(this , 'clientName')}>Full Name</th>
                                <th>User Type</th>
                                <th>Times Logged In</th>
                                <th onClick={this.sortData.bind(this , 'loginDate')}>Last Login Date</th>
                              </tr>
                            </thead>
                            {this.state.isLoadingData === true ?
                            <tbody>
                                <tr>
                                  <td colSpan="5" className="noClientResults text-center">
                                  <img src="../../img/loader2.svg" />
                                  <h5>Your Operation report is generating, please wait.........</h5>
                                  </td>
                                  </tr>
                            </tbody>
                            :
                            <tbody>
                            {
                              loginRecords && loginRecords.length > 0 ?
                              loginRecords.map((c,index) => {
                              return(
                              <tr key={index} >
                                {/*----------User full name--------*/}
                                <td>{c.full_name ? c.full_name:'N/A'}</td>

                                {/*----------User Role/Type --------*/}
                                <td>{c.role ? c.role  :'N/A' }</td>
                               
                                {/*----------Times Logged IN--------*/}
                                <td>{c.login_counts ? c.login_counts: 0}</td>
                              
                                {/*----Closing Date------*/}
                                <td>{c.current_login_date ? moment(c.current_login_date).format('MMMM Do YYYY, h:mm a'):'N/A'}</td>
                              </tr>
                               )
                              }) : 
                              <tr><td colSpan="6" className="noClientResults">No login records found</td></tr> 
                              }
                            </tbody> 
                            }
                        </table>
                       </div>
                   </div>
                 </div>
               </div>
              </main>
              <div className="pagination-container">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.users_limit}
                  totalItemsCount={this.state.totalPages}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
             
            </div>
          </div>
        </div>
        {/*<div className="dashboard-footer text-center">
              Suburban Jungler - User Login Records 
        </div>*/}
      </div>

    )
  }
}

export default reduxForm({
  form: 'loginreport',  // a unique identifier for this form
  destroyOnUnmount: true,
})(LoginReport)
