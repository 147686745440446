import { connect } from 'react-redux'

import AgentSectionComponent from './component'

const AgentSectionContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(AgentSectionComponent)

export default AgentSectionContainer
