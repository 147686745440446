import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import SideNavigation from '../../components/sideNavigation'
import TopNavigation from '../TopNavigation/container'
import styles from './styles.scss'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import {getHostname} from "../../utils/helper";
const required = value => value ? undefined : 'Required'

const cookies = new Cookies()

let pre_Auth = ''
let _user = ''

class StrategistDashboardForm extends Component {
  constructor(props) {
    super(props)
  }

  // componentWillMount() {
  //   let _user = cookies.get('user')
  //   if(_user) {
  //     if(_user.role !=="strategist") {
  //       this.props.history.push({ pathname: '/login'})
  //     }
  //   }
  // }

  componentWillMount(){
    const {fetchUser} = this.props;
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)

      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
        this.props.history.push('/agent/dashboard')
      }
    }
    pre_Auth = cookies.get('i_user')
    if(pre_Auth){
      this.setState({pre_Auth: pre_Auth})
    }
  }


  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/strategist-dashboard" })
    }
  }

  render() {

    const { user } = this.props
    let filterList = []
    if(user && user.suburbs && user.suburbs.length > 0){
      filterList =  user.suburbs.filter(
             (u) =>{
               return (
                 (u.suburb_id) === 1 
               );
             }
           );
    }
    return (
      <div>
        {
          (pre_Auth)?
          <div className="strategist_top">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
          :''
        }
        <TopNavigation {...this.props}/>
        <div id="wrapper">
          <SideNavigation {...this.props}/>
          <div id="page-content-wrapper" className={ (pre_Auth)? "downwrapper" : ""}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">

                    <div className='analyticsContainer analyticsContainer__padding analyticsContainer__paddingbtm'>
                      
                     <div className="row">
                      <div className="col-md-12">
                        <div className={pre_Auth ? "reportslistingpadrem reports__listing--all" : "reports__listing--all" }>
                        <h4>Jungler Reports</h4>
                          <ul>
                            <li>
                              <Link to={`/clients-per-agents`}>
                                # of Clients per Agent
                              </Link>
                            </li>
                            <li>
                             <Link to={`/accounting-report`}>
                                 Accounting Report
                              </Link>
                            </li>
                            <li>
                            <Link to={`/agents-assigned-to-towns`}>
                                Agents Assigned to Towns
                            </Link>
                            </li>

                            <li>
                              {
                                filterList && filterList.length > 0 ? 
                                 <span>
                                 <div className="extra__spacing--between">
                                    <Link to={`/agents-assign-by-ny-strategist`}>
                                      Agents assigned by NY SJ Strategists
                                    </Link>
                                  </div>
                                  <div>
                                    <Link to={`/agent-statistics`}>
                                      Agent Statistics
                                    </Link>
                                  </div>
                                 </span>
                                :
                                <span>
                                 <div className="extra__spacing--between">
                                   <Link to={`/agent-statistics`}>
                                    Agent Statistics
                                   </Link>
                                 </div>
                                 <div>
                                   <Link to={`/not-touched-in-4-weeks`}>
                                      Not Touched by Agent in 4 Weeks
                                  </Link>
                                  </div>
                                </span>
                              }
                            </li>
                            <li>
                              <Link to={`/ai-clients`} className="fontsize14">
                                AI Clients
                              </Link>
                            </li>
                            <li>
                              <Link to={`/business-development`}>
                                Business Development
                              </Link>
                            </li>
                            <li>
                              <Link to={`/clients-assign-to-agents`}>
                                Clients assigned to agents
                              </Link>
                            </li>
                            <li>
                              <Link to={`/clients-assign-per-town`}>
                                Clients assigned per town
                              </Link>
                            </li>
                            <li>
                              <Link to={`/closed-clients-per-towns`}>
                                Closed Clients Per Town
                              </Link>
                            </li>
                            <li>
                              <Link to={`/no-initial-call`}>
                                No Initial or Follow up Agent Call
                              </Link>
                            </li>
                            <li>
                              <Link to={`/no-initial-tour`}>
                                No Initial or Follow up agent visit
                              </Link>
                            </li>
                            
                             {
                              filterList && filterList.length > 0 ?
                              <li>
                                 <Link to={`/not-touched-in-4-weeks`}>
                                  Not Touched by Agent in 4 Weeks
                                </Link>
                              </li>
                              :
                              ''
                            }
                            <li>
                              <Link to={`/strategist-statistics`}>
                                Strategist Statistics
                              </Link>
                            </li>
                            <li>
                              <Link to={`/strategist-timesheet`}>
                                Strategist Timesheet
                              </Link>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                     </div>
                    </div>
    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'strategistdashboard',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategistDashboardForm)
