/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment, useState } from 'react'
import { Spin } from 'antd'
import { map, get } from 'lodash'
import moment from 'moment'
import $ from 'jquery'

import { CloseIcon } from '../../../../../../components/icons'

import './styles.scss'
import { SUCCESS } from '../../../../../../constants/phase'
import Loader from '../../../../../../components/Loader'
import DeleteTextClient from './partials/DeleteTextClient'
import { createStringAvatar } from '../../../../../../utils/helper'
import ReactHtmlParser from 'react-html-parser'

export default function TextClientComponent(props) {
  let intervalRef = React.createRef()
  const [textMessage, setTextMessage] = useState('')
  const [editMessage, setEditTextMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [initialFetch, setInitialFetchLoading] = useState(true)
  const [editMessageId, setEditMessageId] = useState(false)
  const [deleteConfirmationModal, toggleConfirmationModal] = useState({
    open: false,
    textId: '',
  })

  const editTextMessage = (message) => {
    setTextMessage('')
    const textMessage = get(message, 'message_body', false)
      ? message.message_body.replace(/<\/?[br\/>]+(>|$)/g, '\n')
      : ''
    setEditTextMessage(textMessage)
    setEditMessageId(message.id)
  }
  const handleCancelEditMessage = () => {
    setTextMessage('')
    setEditTextMessage('')
    setEditMessageId(false)
  }

  useEffect(() => {
    $('.chats__container').animate({ scrollTop: $('.chats__container').prop('scrollHeight') }, 1000)
    const clientId = props.match.params.id
    setLoading(true)
    props.fetchClientTextMessage({ clientId })
  }, [])
  useEffect(() => {
    intervalRef = setInterval(() => {
      // fetch text message in every 5 second
      const clientId = props.match.params.id
      props.fetchClientTextMessage({ clientId })
    }, 7000)
    return () => {
      // stop on componet unmount or clear interval
      clearInterval(intervalRef)
    }
  }, [])

  useEffect(() => {
    if (props.fetchClientTextMessagePhase === SUCCESS) {
      if (initialFetch) {
        $('.chats__container').animate(
          { scrollTop: $('.chats__container').prop('scrollHeight') },
          1000
        )
      }
      setLoading(false)
      setInitialFetchLoading(false)
    }
  }, [props.fetchClientTextMessagePhase])

  const onClose = () => props.closeDrawer()

  const handleChange = ({ target }) => setTextMessage(target.value)
  const handleEditTextMessageChange = ({ target }) => setEditTextMessage(target.value)

  const handleSubmit = () => {
    const clientId = props.match.params.id
    const senderId = props.user.id
    if (textMessage !== '' && clientId !== '' && senderId !== '' && !editMessageId) {
      setTextMessage('')
      setLoading(true)
      setEditTextMessage('')
      props.sendClientTextMessage({
        clientId: clientId,
        senderId: senderId,
        messageBody: textMessage,
      })
    }
    if (editMessage !== '' && clientId !== '' && senderId !== '' && editMessageId) {
      setTextMessage('')
      setLoading(true)
      setEditTextMessage('')
      setEditMessageId(false)
      props.sendClientTextMessage({
        clientId: clientId,
        senderId: senderId,
        messageBody: editMessage,
        messageId: editMessageId,
      })
    }
  }

  useEffect(() => {
    if (props.sendClientTextMessagePhase === SUCCESS) {
      const clientId = props.match.params.id
      setLoading(true)
      props.clearChatPhase()
      setInitialFetchLoading(true)
      props.fetchClientTextMessage({ clientId })
    }
  }, [props.sendClientTextMessagePhase])

  const deleteTextConfirmation = (textId) =>
    toggleConfirmationModal((prevState) => ({ ...prevState, open: true, textId }))

  const closeDeleteTextModal = () =>
    toggleConfirmationModal((prevState) => ({ ...prevState, open: false, textId: '' }))

  const userId = props.user && props.user.id
  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">
          Send text to {get(props, 'clientDetail.client_full_name', 'client')}
        </h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>

      <div className="chats__start__bottom">
        {props.clientMessages && props.clientMessages.length > 0 ? (
          <div className="chats__container">
            <div className="chats__wrapper">
              <Spin size="large" spinning={isLoading} indicator={<Loader />}>
                <ul className="chats__wrapper__chats__list">
                  {map(props.clientMessages, (message, index) => {
                    return (
                      <li
                        className={`has__avatar ${message.sender_id === userId ? 'active' : ''}`}
                        key={index}
                      >
                        <div className="chats__card__header">
                          <div className="chats__card__avatar">
                            {message.user_avatar ? (
                              <img className="img-responsive" src={message.user_avatar} alt="sbj" />
                            ) : (
                              <span className="string__avatar">
                                {createStringAvatar(message.sender_full_name)}
                              </span>
                            )}
                          </div>
                          <span className="chats__card__name">{message.sender_full_name}</span>
                          <span className="chats__card__time">
                            {moment(message.created).format('MM/DD/YY ddd hh:mm A')}
                          </span>
                        </div>
                        {editMessageId && editMessageId === message.id ? (
                          <div className="chats__card__edit">
                            <textarea
                              className="chats__card__textarea__edit"
                              type="text"
                              name="editMessage"
                              value={editMessage}
                              onChange={handleEditTextMessageChange}
                            ></textarea>
                            <button
                              onClick={handleSubmit}
                              disabled={
                                editMessage !== '' && editMessage.trim() !== '' ? false : true
                              }
                              className="btn btn__btn btn__xs btn-dark mr__15"
                            >
                              Save
                            </button>
                            <span onClick={handleCancelEditMessage}>
                              <CloseIcon className="chats__card__edit__close" />
                            </span>
                          </div>
                        ) : (
                          <div className="chats__content">{message.message_body}</div>
                        )}
                        {
                          (message.is_received && message.reason && message.reason.indexOf('Invalid Destination Number') < 0) ? (<div className="delivered">
                              {message.reason && message.reason !== '' ? message.reason : 'Delivered'}
                            </div>) :
                            (<div className="undelivered">
                              {message.reason && message.reason !== '' ? message.reason : 'Invalid Destination Number'}
                            </div>)
                        }
                        {message.sender_id === userId && !editMessageId && false && (
                          <div className="chats__card__cta">
                            <button
                              className="btn btn__edit_chat"
                              onClick={() => editTextMessage(message)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn__delete_chat"
                              onClick={() => deleteTextConfirmation(message.id)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </Spin>
            </div>
          </div>
        ) : (
          <div className="drawer__body__inner height__remove">
            <div className="blank__state__text">No Text Message Found.</div>
          </div>
        )}
        <div className="chats__container__teatarea">
          <textarea
            value={textMessage}
            onChange={handleChange}
            className="chats__sectiion__input"
            placeholder={`Send a text message to ${get(
              props,
              'clientDetail.client_full_name',
              'client'
            )}... `}
          ></textarea>
          <div className="chats__section__cta">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn__btn btn-dark btn__send"
              disabled={textMessage !== '' && textMessage.trim() !== '' 
                  ? (textMessage.length <= 737 ? false : true )
                  : true
              }
            >
              Send
            </button>
            {textMessage && textMessage.length < 737 && `${textMessage.length}/737`}
            {textMessage && textMessage.length > 737 && <div className="text-message-max-length-error">Maximum 737 characters allowed</div>}
          </div>
        </div>
      </div>

      <DeleteTextClient
        openModel={deleteConfirmationModal.open}
        clientTextId={deleteConfirmationModal.textId}
        closeDeleteTextModal={closeDeleteTextModal}
        {...props}
      />
    </Fragment>
  )
}
