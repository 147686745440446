import { connect } from 'react-redux'

import {
  deleteClientDealByAgent,
  createClientDeal,
  updateClientUser,
  clearPhase,
  clearAgentPhase,
  clearAgentDatePhase
} from '../../../../store/agent/duck'

import { getNotTouchedData, getNotTouchedIn4Week } from '../../../../store/agentV2/actions'

import TaskListComponent from './component'
const TaskListContainer = connect(
  // Map state to props
  (state) => ({
    clientDealPhase: state.agent.clientDealPhase,
    updateUserPhase: state.agent.updateUserPhase,
    deleteClientDealPhase: state.agent.deleteClientDealPhase
  }),
  // Map actions to dispatch and props
  {
    deleteClientDealByAgent,
    createClientDeal,
    getNotTouchedData,
    getNotTouchedIn4Week,
    updateClientUser,
    clearPhase,
    clearAgentPhase,
    clearAgentDatePhase
  }
)(TaskListComponent)

export default TaskListContainer
