import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import config from "../../config";
import _ from "lodash";
import { Redirect, Link } from "react-router-dom";
import SideNavigation from "../../components/sideNavigation";
import TopNavigation from "../TopNavigation/container";
import styles from "./styles.scss";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Calendar from 'react-calendar'
import Modal from 'react-modal'
import {getHostname} from "../../utils/helper";

const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;

let pre_Auth = "";
let _user = "";

const customStyles2 = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '450px',
    padding: '24px 24px',
    maxHeight: "95%"
  }
}

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}
      >
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class ClientsListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      openSnackbar: false,
      errMessage: "",
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      userid: "",
      success: "",
      startDate: moment(),
      role: "",
      timezone: "",
      client_status: [],
      client_strategist: [],
      client_type: "all_clients",
      client_suburb: [],
      hide_inactive: false,
      totalPages: 0,
      activePage: 1,
      client_limit: 20,
      client_offset: 1,
      isLoadingData: false,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      sortByKey: "",
      sortByType: 0,
      strategistDropdown: false,
      suburbDropdown: false,
      statusDropdown: false,
      pre_Auth: "",
      calendarIsOpen: false,
      selectedUserData: [],
      selectedDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handelUserfilter = this.handelUserfilter.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentWillMount() {
    _user = cookies.get("user");
    if (_user) {
      if (_user.role === "strategist") {
        this.setState({ client_type: "my_clients" });
      }
      this.setState({ userid: _user.id });
      const data = {};
      data.user_id = _user.id;
      this.props.fetchUser(data);
      this.props.getFilterParams();
      setTimeout(
        function() {
          var localclientdata = JSON.parse(localStorage.getItem("clientdata"));
          if (
            localclientdata &&
            localclientdata != null &&
            localclientdata != ""
          ) {
            this.setState(localclientdata);
            this.props.getClientList(localclientdata);
          } else {
            const clientdata = {};
            clientdata.client_type = this.state.client_type;
            clientdata.client_status = this.state.client_status;
            clientdata.client_suburb = this.state.client_suburb;
            clientdata.client_strategist = this.state.client_strategist;
            clientdata.hide_inactive = this.state.hide_inactive;
            clientdata.client_limit = this.state.client_limit;
            clientdata.client_offset = this.state.activePage;
            clientdata.sortBy = {
              key: this.state.sortByKey,
              type: this.state.sortByType
            };
            localStorage.setItem("clientdata", JSON.stringify(clientdata));
            this.props.getClientList(clientdata);
          }
        }.bind(this),
        500
      );

      if (_user.role === "client") {
        this.props.history.push("/clients");
      } else if (_user.role === "agent") {
        this.props.history.push("/agent");
      } else if (_user.role === "local") {
        this.props.history.push("/agent");
      }
    }
    pre_Auth = cookies.get("i_user");
    if (pre_Auth) {
      this.setState({ pre_Auth: pre_Auth });
    }
  }

  componentDidMount() {
    document.title = "Jungler: Clients";
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({
            strategistDropdown: false,
            statusDropdown: false,
            suburbDropdown: false
          });
        }
      }
    });
  }

  componentWillUnmount() {
    document.title = "Suburban Jungle";
  }

  handlePageChange(pageNumber) {
    this.setState({ isLoadingData: true });
    this.setState({ activePage: pageNumber });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_suburb = this.state.client_suburb;
        clientdata.client_strategist = this.state.client_strategist;
        clientdata.hide_inactive = this.state.hide_inactive;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        localStorage.setItem("clientdata", JSON.stringify(clientdata));
        this.props.getClientList(clientdata);
      }.bind(this),
      500
    );
  }

  handelUserfilter(btn) {
    this.setState({ isLoadingData: true });
    $(".filter__container--button").removeClass("active");
    if (btn.target.name !== "") {
      $("button[ name=" + btn.target.name + "]").addClass("active");
      switch (btn.target.name) {
        case "all_clients":
          this.setState({ client_type: "all_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.all_clients
              )
            });
          }
          break;
        case "my_clients":
          this.setState({ client_type: "my_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.my_clients
              )
            });
          }
          break;
        default:
          this.setState({ client_type: "my_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.my_clients
              )
            });
          }
      }
    } else {
      this.setState({ client_type: "all_clients" });
    }
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_suburb = this.state.client_suburb;
        clientdata.client_strategist = this.state.client_strategist;
        clientdata.hide_inactive = this.state.hide_inactive;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        localStorage.setItem("clientdata", JSON.stringify(clientdata));
        this.props.getClientList(clientdata);
      }.bind(this),
      500
    );
  }

  handleChange(event) {
    this.setState({ isLoadingData: true });
    this.setState({ [event.target.name]: event.target.value });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_suburb = this.state.client_suburb;
        clientdata.client_strategist = this.state.client_strategist;
        clientdata.hide_inactive = this.state.hide_inactive;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        localStorage.setItem("clientdata", JSON.stringify(clientdata));
        this.props.getClientList(clientdata);
      }.bind(this),
      500
    );
  }

  sortData(val) {
    let sortByType = this.state.sortByType;
    if (val !== this.state.sortByKey) {
      sortByType = 0;
    } else {
      sortByType === 0 ? (sortByType = 1) : (sortByType = 0);
    }
    this.setState({
      isLoadingData: true,
      sortByKey: val,
      sortByType: sortByType
    });
    const clientdata = {};
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_suburb = this.state.client_suburb;
    clientdata.client_strategist = this.state.client_strategist;
    clientdata.hide_inactive = this.state.hide_inactive;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = { key: val, type: sortByType };
    localStorage.setItem("clientdata", JSON.stringify(clientdata));
    this.props.getClientList(clientdata);
  }

  handleCheck(event) {
    if (event.target.name == "hide_inactive") {
      if (!this.state.hide_inactive) {
        this.setState({ hide_inactive: true });
      } else {
        this.setState({ hide_inactive: false });
      }
      setTimeout(
        function() {
          const clientdata = {};
          clientdata.client_type = this.state.client_type;
          clientdata.client_status = this.state.client_status;
          clientdata.client_suburb = this.state.client_suburb;
          clientdata.client_strategist = this.state.client_strategist;
          clientdata.hide_inactive = this.state.hide_inactive;
          clientdata.client_limit = this.state.client_limit;
          clientdata.client_offset = this.state.activePage;
          clientdata.sortBy = {
            key: this.state.sortByKey,
            type: this.state.sortByType
          };
          localStorage.setItem("clientdata", JSON.stringify(clientdata));
          this.props.getClientList(clientdata);
        }.bind(this),
        500
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isLoadingData: true });
    let self = this;
    if (this.state.client_type === "all_clients") {
      this.setState({
        totalPages: Math.round(
          nextProps.clientData &&
            nextProps.clientData.user_counts &&
            nextProps.clientData.user_counts.all_clients
        )
      });
    } else if (this.state.client_type === "my_clients") {
      this.setState({
        totalPages: Math.round(
          nextProps.clientData &&
            nextProps.clientData.user_counts &&
            nextProps.clientData.user_counts.my_clients
        )
      });
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false });
    }
    if (nextProps.profilePhase === "error") {
      this.setState({
        isLoadingProfile: false,
        errProfileMessage: nextProps.profileError
      });
    } else if (nextProps.profilePhase === "success") {
      this.setState({
        isLoadingProfile: false,
        errProfileMessage: "Profile info saved successfully."
      });
      // this.props.CLEAR_PHASE;

      if (nextProps.isSubmitting != false && nextProps.dataPhase !== "error") {
        if (this.state.userid != "") {
          const data = {};
          data.user_id = this.state.userid;
          this.props.fetchUser(data);
        }
      }
    }
    if (nextProps.profilePhase === "success") {
      if (nextProps.user.user) {
        let user = nextProps.user.user;
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone
        });
      }
    }
    if (nextProps.addUpdateClientPhase === "success") {
      const clientdata = {};
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_suburb = this.state.client_suburb;
      clientdata.client_strategist = this.state.client_strategist;
      clientdata.hide_inactive = this.state.hide_inactive;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {
        key: this.state.sortByKey,
        type: this.state.sortByType
      };
      this.props.getClientList(clientdata);
      localStorage.setItem("clientdata", JSON.stringify(clientdata));
      this.props.clearPhase();
    }
    if (nextProps.impersonatePhase === "success") {
      if (nextProps.impersonateData && nextProps.impersonateData.magic_link) {
        let i_user = cookies.get("user");
        let i_Authorization = cookies.get("Authorization");
        let expires = new Date();
        localStorage.clear();
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth() + 2);
        expires = new Date(expires);
        cookies.set("i_Authorization", i_Authorization, { path: "/", expires });
        cookies.set("i_user", JSON.stringify(i_user), { path: "/", expires });
        let self = this;
        setTimeout(function() {
          let magic_link = nextProps.impersonateData.magic_link;
          let pathname = "client-login/token?validate=" + magic_link;
          self.props.clearPhase();
          document.cookie =
            "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          self.props.history.push({ pathname: pathname });
        }, 1000);
      } else {
        alert("Not have enough access");
      }
    }
  }

  handleRequestClose() {
    this.setState({ isLoadingData: false });
    this.setState({
      openSnackbar: false,
      errMessage: ""
    });
  }

  handleDateChange(cData, date) {
    let selectDate = moment(date).format("YYYY-MM-DD");
    let data = {};
    data.follow_up_date_req = selectDate;
    data.id = cData.client_id;
    this.props.addUpdateClient(data);
  }

  handleEntryChange(event) {
    this.setState({ client_limit: event.target.value });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_suburb = this.state.client_suburb;
        clientdata.client_strategist = this.state.client_strategist;
        clientdata.hide_inactive = this.state.hide_inactive;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        localStorage.setItem("clientdata", JSON.stringify(clientdata));
        this.props.getClientList(clientdata);
      }.bind(this),
      500
    );
  }

  authClick(event) {
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get("i_user");
    let authorization = cookies.get("i_Authorization");
    localStorage.clear();
    let expires = new Date();
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth() + 2);
    expires = new Date(expires);
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set("user", JSON.stringify(user), { path: "/", expires });

    document.cookie =
      "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if (pre_Auth.role === "superadmin") {
      this.props.history.push({ pathname: "/dashboard" });
    }
    if (pre_Auth.role === "admin" || pre_Auth.role === "strategist") {
      this.props.history.push({ pathname: "/home/new-clients" });
    }
  }

  impersonateUser(client_id) {
    let data = {};
    data.client_id = client_id;
    this.props.impersonateUser(data);
  }

  toggledropDown(val) {
    if (val === "filterStrategist") {
      this.setState({
        strategistDropdown: !this.state.strategistDropdown,
        statusDropdown: false,
        suburbDropdown: false
      });
    }
    if (val === "filterStatus") {
      this.setState({
        statusDropdown: !this.state.statusDropdown,
        strategistDropdown: false,
        suburbDropdown: false
      });
    }
    if (val === "filterSuburb") {
      this.setState({
        suburbDropdown: !this.state.suburbDropdown,
        statusDropdown: false,
        strategistDropdown: false
      });
    }
  }

  clearAllFilter(e) {
    this.setState({
      isLoadingData: true,
      client_strategist: [],
      client_status: [],
      client_suburb: []
    });
    setTimeout(() => {
      const clientdata = {};
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_suburb = this.state.client_suburb;
      clientdata.client_strategist = this.state.client_strategist;
      clientdata.hide_inactive = this.state.hide_inactive;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {
        key: this.state.sortByKey,
        type: this.state.sortByType
      };
      localStorage.setItem("clientdata", JSON.stringify(clientdata));
      this.props.getClientList(clientdata);
    }, 300);
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status, client_suburb } = this.state;
    if (type === "strategistFilter") {
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id);
      } else {
        client_strategist.splice(client_strategist.indexOf(id), 1);
      }
    }
    if (type === "statusFilter") {
      if (client_status.indexOf(id) === -1) {
        client_status.push(id);
      } else {
        client_status.splice(client_status.indexOf(id), 1);
      }
    }
    if (type === "suburbFilter") {
      if (client_suburb.indexOf(id) === -1) {
        client_suburb.push(id);
      } else {
        client_suburb.splice(client_suburb.indexOf(id), 1);
      }
    }
    this.setState({
      isLoadingData: true,
      client_strategist: client_strategist,
      client_status: client_status,
      client_suburb: client_suburb
    });
    const clientdata = {};
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = client_status;
    clientdata.client_suburb = client_suburb;
    clientdata.client_strategist = client_strategist;
    clientdata.hide_inactive = this.state.hide_inactive;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = {
      key: this.state.sortByKey,
      type: this.state.sortByType
    };
    localStorage.setItem("clientdata", JSON.stringify(clientdata));
    this.props.getClientList(clientdata);
  }

  openCalendarModalIcon(cData, date){
    let finalDateSelect = ''
    if(cData.follow_up_date){
      let followUpDate = cData.follow_up_date
      let a = followUpDate.substring(0, followUpDate.indexOf('.'))
      finalDateSelect = followUpDate
    }else{
      finalDateSelect = new Date()
    }

    this.setState({ calendarIsOpen: true, selectedUserData: cData, selectedDate: new Date(finalDateSelect) })
  }

  afterOpenModal() {

  }

  closeModal() {
    // this.setState({calendarIsOpen: false, selectedUserData: []})
  }

  closeCalendarModalIcon(e){
    this.setState({ calendarIsOpen: false, selectedUserData: [] })
  }

  onSubmitDateRange(e){
    let changeFormat = new Date(this.state.selectedDate).toISOString() //change into iso string format
    let selectDate =  moment(changeFormat).format("YYYY-MM-DD")
    let data = {};
    data.follow_up_date_req = selectDate;
    data.id = this.state.selectedUserData.client_id;
    this.props.addUpdateClient(data);
    this.setState({ calendarIsOpen: false, selectedUserData: [] })
  }
  changeMonthDate(date){
    this.setState({ selectedDate: date })
  }

  render() {
    const { user, clientData, filterParams } = this.props;
    const { client_strategist } = this.state;
    if (user && user.role === "client") {
      return <Redirect to={`/clients`} />;
    } else if (user && user === "agent") {
      return <Redirect to={`/agents`} />;
    }

    let allData = filterParams.data;
    let allStrategists = allData && allData.strategists;
    if (
      pre_Auth &&
      pre_Auth.role !== "superadmin" &&
      pre_Auth.role !== "admin"
    ) {
      allStrategists = _.filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    } else if (!pre_Auth && user && user.role == "strategist") {
      allStrategists = _.filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    }
    let currentuser = user && user;
    return (
      <div>
        {pre_Auth ? (
          <div className="strategist_top">
            You are impersonating{" "}
            {"( " + _user.first_name + " " + _user.last_name + " ) "}
            <a onClick={this.authClick.bind(this)}>
              Click Here to go back to Jungler
            </a>
          </div>
        ) : (
          ""
        )}
        <TopNavigation {...this.props} />
        <div id="wrapper">
          <SideNavigation {...this.props} />
          <div id="page-content-wrapper" className={ pre_Auth ? "imper__clientlistdownwrapper" : "clientlistdownwrapper"}>
            <div className="row">
              <div className="col-md-12">
                <main className="jungler__page--container padding-left hide__hori--scroll mob__rt--15">
                  <div
                    className="filter__container filter__container--users"
                  >
                    {(currentuser &&
                      currentuser.role &&
                      currentuser.role === "superadmin") ||
                    currentuser.role === "admin" ? (
                      <div className="filter__container--filter-name rem-flex">
                        <div className="col-md-7 rem-left">
                          <button
                            name="all_clients"
                            className={
                              this.state.client_type === "all_clients"
                                ? "btn filter__container--button active"
                                : "btn filter__container--button"
                            }
                            onClick={this.handelUserfilter.bind(this)}
                          >
                            All Clients (
                            {clientData &&
                              clientData.user_counts &&
                              clientData.user_counts.all_clients}
                            )
                          </button>
                          <button
                            name="my_clients"
                            className={
                              this.state.client_type === "my_clients"
                                ? "btn filter__container--button active"
                                : "btn filter__container--button"
                            }
                            onClick={this.handelUserfilter.bind(this)}
                          >
                            My Clients (
                            {clientData &&
                              clientData.user_counts &&
                              clientData.user_counts.my_clients}
                            )
                          </button>
                        </div>

                        <div className="col-md-5 newfilter-list text-right">
                        <li className="widthauto shiftleft">
                            <span className="enteries-span">
                              <label className="enteries-label">Entries</label>
                              <select
                                value={this.state.client_limit}
                                onChange={this.handleEntryChange.bind(this)}
                              >
                                {config.entries &&
                                  config.entries.map((entry, entryindx) => {
                                    return (
                                      <option
                                        key={entryindx}
                                        value={entry.value}
                                      >
                                        {entry.label}
                                      </option>
                                    );
                                  })}
                              </select>
                            </span>
                          </li>
                          <li className="widthauto">
                            <span className="totalRecords">
                              Total Records (
                              {clientData && clientData.users
                                ? clientData.users.length
                                : 0}
                              )
                            </span>
                          </li>
                        </div>
                      </div>
                    ) : (
                      <div className="filter__container--filter-name rem-flex">
                        <div className="col-md-12 newfilter-list text-right">
                          <li className="widthauto shiftleft">
                            <span className="enteries-span">
                              <label className="enteries-label">Entries</label>
                              <select
                                value={this.state.client_limit}
                                onChange={this.handleEntryChange.bind(this)}
                              >
                                {config.entries &&
                                  config.entries.map((entry, entryindx) => {
                                    return (
                                      <option
                                        key={entryindx}
                                        value={entry.value}
                                      >
                                        {entry.label}
                                      </option>
                                    );
                                  })}
                              </select>
                            </span>
                          </li>
                          <li className="widthauto">
                            <span className="totalRecords">
                              Total Records (
                              {clientData && clientData.users
                                ? clientData.users.length
                                : 0}
                              )
                            </span>
                          </li>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="mobileFilter filter__container" style={{ display: 'none' }}>
                    <div className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
                    Filter <img src="./img/menu-dropdown.png" style={{ float:'right', paddingTop: '5px', width: '14px' }} /> 
                    </a>
                    { currentuser && currentuser.role && currentuser.role ==='superadmin' || currentuser.role ==='admin' ?
                    <ul className="dropdown-menu mobile-dropdown">
                    <li>
                    <button name="all_clients" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>All Clients ({clientData && clientData.user_counts && clientData.user_counts.all_clients})</button>
                    </li>
                    <li>
                    <button name="my_clients" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>My Clients ({clientData && clientData.user_counts && clientData.user_counts.my_clients})</button>
                    </li>
                    </ul>
                    :
                    <ul className="dropdown-menu mobile-dropdown">
                    <li>
                    <button name="all_clients" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>All Clients ({clientData && clientData.user_counts && clientData.user_counts.all_clients})</button>
                    </li>
                    </ul>
                    }
                    </div>
                    <div className="col-md-12 newfilter-list text-right filter__container--filter-name rem-flex" style={{right: '0'}}>
                      
                      <li>
                      <span className="enteries-span">
                      <label className="enteries-label">Entries</label>
                        <select value = {this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
                        {config.entries && config.entries.map((entry, entryindx) => {
                            return (
                            <option key={entryindx} value={entry.value}>{entry.label}</option>
                            )
                          })}
                        </select>
                      </span>  
                      </li>
                      <li>
                      <span className="totalRecords">Total Records ({clientData && clientData.users  ? clientData.users.length : 0})</span>
                      </li>
                      </div>
                  </div> */}

                  <div className="filter__sub--container filter__sub--container-edit filterarrg">
                    {(currentuser &&
                      currentuser.role &&
                      currentuser.role === "superadmin") ||
                    currentuser.role === "admin" ? (
                      <li id="dropdownBtn">
                        <div
                          className="custom__all--filters"
                          onClick={this.toggledropDown.bind(
                            this,
                            "filterStrategist"
                          )}
                        >
                          Filter by Strategist
                        </div>
                        {this.state.strategistDropdown && (
                          <div className="sel-outer sel-outer-edit">
                            {allStrategists &&
                              allStrategists.map(
                                (strategistsval, strategistsindx) => {
                                  let selectedIndex = false;
                                  _.find(this.state.client_strategist, function(
                                    o
                                  ) {
                                    if (o === strategistsval.id) {
                                      selectedIndex = true;
                                    }
                                  });
                                  return (
                                    <li
                                      key={strategistsindx}
                                      className="custom-select-option"
                                    >
                                      <span className="coverage__checkbox">
                                        <label className="custom-checkbox">
                                          {strategistsval.strategist_name}
                                          <input
                                            type="checkbox"
                                            checked={selectedIndex}
                                            className="custom-select-option-checkbox"
                                            onClick={this.filterStrategist.bind(
                                              this,
                                              strategistsval.id,
                                              "strategistFilter"
                                            )}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                          </div>
                        )}
                      </li>
                    ) : (
                      ""
                    )}

                    <li id="dropdownBtn">
                      <div
                        className="custom__all--filters"
                        onClick={this.toggledropDown.bind(this, "filterStatus")}
                      >
                        Filter by Status
                      </div>
                      {this.state.statusDropdown && (
                        <div className="sel-outer sel-outer-edit">
                          {allData &&
                            allData.statuses.map((statussval, statusindx) => {
                              let selectedIndex = false;
                              _.find(this.state.client_status, function(o) {
                                if (o === statussval.name) {
                                  selectedIndex = true;
                                }
                              });
                              return (
                                <li
                                  key={statusindx}
                                  className="custom-select-option"
                                >
                                  <span className="coverage__checkbox">
                                    <label className="custom-checkbox">
                                      {statussval.name}
                                      <input
                                        type="checkbox"
                                        checked={selectedIndex}
                                        className="custom-select-option-checkbox"
                                        onClick={this.filterStrategist.bind(
                                          this,
                                          statussval.name,
                                          "statusFilter"
                                        )}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </span>
                                </li>
                              );
                            })}
                        </div>
                      )}
                    </li>
                    {currentuser &&
                    currentuser.role &&
                    currentuser.role != "strategist" ? (
                      <li id="dropdownBtn">
                        <div
                          className="custom__all--filters"
                          onClick={this.toggledropDown.bind(
                            this,
                            "filterSuburb"
                          )}
                        >
                          Filter by Suburb
                        </div>
                        {this.state.suburbDropdown && (
                          <div className="sel-outer sel-outer-edit">
                            {allData &&
                              allData.suburbs.map((suburbsval, suburbsindx) => {
                                let selectedIndex = false;
                                _.find(this.state.client_suburb, function(o) {
                                  if (o === suburbsval.id) {
                                    selectedIndex = true;
                                  }
                                });
                                return (
                                  <li
                                    key={suburbsindx}
                                    className="custom-select-option"
                                  >
                                    <span className="coverage__checkbox">
                                      <label className="custom-checkbox">
                                        {suburbsval.name}
                                        <input
                                          type="checkbox"
                                          checked={selectedIndex}
                                          className="custom-select-option-checkbox"
                                          onClick={this.filterStrategist.bind(
                                            this,
                                            suburbsval.id,
                                            "suburbFilter"
                                          )}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </span>
                                  </li>
                                );
                              })}
                          </div>
                        )}
                      </li>
                    ) : (
                      ""
                    )}   

                    <li className="clearfilterTab">
                      <h5
                        onClick={this.clearAllFilter.bind(this)}
                        className="clear-filter"
                      >
                        {(this.state.client_strategist &&
                          this.state.client_strategist.length > 0) ||
                          (this.state.client_status &&
                            this.state.client_status.length > 0) ||
                          (this.state.client_suburb &&
                            this.state.client_suburb.length > 0)
                          ? "Clear All Filters"
                          : ""}
                      </h5>
                    </li>                
                  </div>


                  

                    {/* <div>
                      <span className="hide__inactive displ-none">
                        <label className="custom-checkbox">
                          Hide inactive clients
                          <input
                            type="checkbox"
                            name="hide_inactive"
                            checked={this.state.hide_inactive}
                            onChange={this.handleCheck.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </span>
                    </div>  */}



                  <div className="jungler__list useslist__extra bordertoptable">
                    <table className="table table-striped font__size--update spacetab">
                      <thead className="table__td--desktop">
                        <tr>
                          <th onClick={this.sortData.bind(this, "clientName")}>
                            Client Name
                          </th>
                          <th
                            className="table__td--desktop"
                            onClick={this.sortData.bind(this, "strategist")}
                          >
                            Strategist
                          </th>
                          <th
                            onClick={this.sortData.bind(this, "pipelineStage")}
                          >
                            Pipeline Stage
                          </th>
                          {/* <th
                            onClick={this.sortData.bind(this, "followUpDate")}
                          >
                            Follow Up
                          </th> */}
                          <th
                            className="table__td--desktop"
                            onClick={this.sortData.bind(this, "lastActivity")}
                          >
                            Last Activity
                          </th>
                          <th
                            className="table__td--desktop"
                            onClick={this.sortData.bind(this, "suburb")}
                          >
                            Suburb
                          </th>
                          <th className="table__td--desktop"></th>
                          <th className="table__td--desktop"></th>
                        </tr>
                      </thead>
                      {this.state.isLoadingData === true ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="5"
                              className="noClientResults text-center"
                            >
                              <img src="../../img/loader2.svg" />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {clientData &&
                          clientData.users &&
                          clientData.users.length > 0 ? (
                            clientData.users.map((c, index) => {
                              return (
                                <tr className="forcursor" key={index}>
                                  <td
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/activity/${c.client_id}`,
                                        state: c
                                      });
                                    }}
                                  >
                                    {c.client_full_name}
                                    <span className="showschedule__mob">
                                      {c.status && c.status}
                                    </span>
                                  </td>

                                  <td
                                    className="table__td--desktop"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/activity/${c.client_id}`,
                                        state: c
                                      });
                                    }}
                                  >
                                    {c.strategists &&
                                      c.strategists.map((strategist, idx) => {
                                        return (
                                          <span key={idx}>
                                            {(idx ? ", " : "") +
                                              strategist.strategist_full_name}
                                          </span>
                                        );
                                      })}
                                  </td>
                                  <td
                                    className="table__td--desktop"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/activity/${c.client_id}`,
                                        state: c
                                      });
                                    }}
                                  >
                                    {c.status && c.status}
                                  </td>
                                  {/* <td
                                    onClick={this.openCalendarModalIcon.bind(this, c)} 
                                    className="fordt-picker" style={{position: 'relative'}}>
                                    <span className="text-green">
                                      {c.follow_up_date &&
                                        moment(c.follow_up_date).format(
                                          "MM/DD/YYYY"
                                        )}
                                    </span>
                                    <a
                                      className="btn__edit mob__btn--edit"
                                    >
                                      <img src="img/edit-icon.svg"/>
                                    </a> */}
                                    {/*<DatePicker
                                      customInput={<DatePickerCustomInput />}
                                      onChange={this.handleDateChange.bind(
                                        this,
                                        c
                                      )}
                                      showTimeSelect
                                      peekNextMonth
                                      minDate={new Date()}
                                      maxDate={moment(new Date()).add(
                                        1,
                                        "months"
                                      )}
                                      className="form-control custom-select date__picker"
                                    ></DatePicker>*/}
                                  {/* </td> */}
                                  <td
                                    className="table__td--desktop"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/activity/${c.client_id}`,
                                        state: c
                                      });
                                    }}
                                  >
                                    {c.latest_activity &&
                                      moment(c.latest_activity).format(
                                        "MM/DD/YYYY"
                                      )}
                                  </td>

                                  <td
                                    className="table__td--desktop"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/activity/${c.client_id}`,
                                        state: c
                                      });
                                    }}
                                  >
                                    {c.suburbs &&
                                      c.suburbs.map((sub, idx) => {
                                        return (
                                          <span key={idx}>
                                            {(idx ? ", " : "") +
                                              sub.suburbs_name}
                                          </span>
                                        );
                                      })}
                                  </td>

                                  {c.suburb_name && c.suburb_name}

                                  <td className="table__td--desktop">
                                    <button
                                      className="btn btn__black--outline btn__profile"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: `/activity/${c.client_id}`,
                                          state: c
                                        });
                                      }}
                                    >
                                      View Profile
                                    </button>
                                  </td>
                                  <td className="table__td--desktop">
                                    {currentuser &&
                                    currentuser.role &&
                                    (currentuser.role === "superadmin" ||
                                      currentuser.role === "admin" ||
                                      currentuser.role === "strategist") ? (
                                        <button
                                          className="btn btn__green--outline btn__profile"
                                          onClick={this.impersonateUser.bind(
                                            this,
                                            c.client_id
                                          )}
                                        >
                                          Impersonate User
                                        </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8" className="noClientResults">
                                No clients found for this criteria. Please
                                broaden or change your search criteria and
                                filters
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </main>
                <div className="pagination-container pagination-container-wrapper">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.client_limit}
                    totalItemsCount={this.state.totalPages}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                {/*This is model for calendar*/}
                <Modal
                  isOpen={this.state.calendarIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.closeModal}
                  style={customStyles2}           
                  contentLabel="Example Modal">
                    <div className="datepicker__newcalendar"> 

                      <div className="header__datepicker"> 
                        <h4>Follow Up</h4>
                        <div 
                          className="calendar__close"><img 
                          onClick={this.closeCalendarModalIcon.bind(this)} 
                          data-dismiss="modal" src="./img/close_btn.svg"/>
                        </div>
                      </div>
                        <label className="label__calendar">Select Date</label>
                        <Calendar
                          activeStartDate={this.state.selectedDate}
                          value={this.state.selectedDate}
                          minDate={new Date()}
                          onChange={this.changeMonthDate.bind(this)}
                        />
                        <button className="sub__btn--date" onClick={this.onSubmitDateRange.bind(this)}>Submit</button>        
                    </div>
                </Modal>
                {/************End****************/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "jungleruserslist", // a unique identifier for this form
  destroyOnUnmount: true
})(ClientsListForm);
