import { connect } from 'react-redux'
import {fetchUser,  handleSignOut} from '../../store/user/duck'
import {getUSerProfileByID} from '../../store/dashboard/duck'
import JunglerUserProfileForm from './component'
const JunglerUserProfile = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userData: state.dashboard.userData,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase 
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getUSerProfileByID
  }
)(JunglerUserProfileForm)
export default JunglerUserProfile
