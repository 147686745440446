import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import Config from '../../../../../../config';

//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import ReactModal from 'react-modal'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AiClientsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      aiClientsTableData: [],
      sortByKey:'client_name',
      sortByType: 0,
      townName:'',
      openAgent: false,
      currentTownAgents:[],
      type: '',
      strategist_name:'',
      strategist_id:0

    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    if(_user) {
      this.setState({ isLoadingData: true, userRole: _user.role })
      const data = {}
      data.search = ''
      data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      data.strategist_id=this.state.strategist_id
      this.props.getAIClientsReport(data)
      this.props.getFilterParams()
    }
  }

  componentDidUpdate(preProps, preState){
    const { aiClientsPhase, aiClientsData, clearReportV2Phase } = this.props
    if(aiClientsPhase === "success"){
      clearReportV2Phase()
      let data = []
      const closedClientArr = aiClientsData && aiClientsData.data
      if(_.size(closedClientArr) > 0){
        closedClientArr && closedClientArr.map((clnt, i)=>{

          let priceRange = clnt.price_range
          if(priceRange == '0 - 0'){
            priceRange = "0"
          }

          data.push({
            key: i,
            client_name: clnt.client_name,
            booked_by  : clnt.booked_by,
            price_range: priceRange,
            source     : clnt.source ? clnt.source : 'N/A',
            strategists: clnt.strategists.length > 0 ? clnt.strategists.map(e => { return e}).join(", ") : "",
            ad_tracking_codes: clnt.ad_tracking_codes ? clnt.ad_tracking_codes : 'N/A',
            status     : clnt.status
          })
        })
      }
      this.setState({ aiClientsTableData: data, isLoading: false })
    }
    if(aiClientsPhase === "error"){
      clearReportV2Phase()
      this.setState({ aiClientsTableData: [], isLoading: false })
    }
  }

  onOpenAgentModal(agent, type) {
    let agents = []
    if(type == 'agent'){
      agents = agent && agent.agents
    }
    if(type == 'client'){
      agents = agent && agent.clients
    }
    if(type == 'tag'){
      agents = agent && agent.status
    }
    if(type == 'strategist'){
      agents = agent && agent.strategists
    }
    if(agents.length > 1){
      $( "body" ).addClass( "overflow_visible" )
      this.setState({ townName: agent.client_name, openAgent: true, currentTownAgents: agents, type:type  })
    }
  }

  onCloseAgentModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ townName: '', currentTownAgents: [], openAgent: false, type:'' })
  }

  changeLimit = limit => {
    // On limit change
  }
  handleChange(event) {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
   
    this.setState({ strategist_id: event.target.value, isLoading: true },()=>{
      const strData={}
      // if(this.state.strategist_id!=){
      //   strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      //   strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      //   strData.selectedValue = 'total'

      // }else{
        //  strData.from=moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        //  strData.to= moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        //  strData.selectedValue='total'
      // }
      //  strData = {
      //   from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      //   to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
      //   selectedValue : 'any',
      //   strategist_id : parseInt(this.state.strategist_id)
      // }
      strData.search = ''
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
     strData.strategist_id = parseInt(this.state.strategist_id)
      this.props.getAIClientsReport(strData);
      this.props.getFilterParams()
      
    })
  }

  render() {
    const { aiClientsTableData } = this.state
    const { filterParams } = this.props
    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">AI Clients</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={aiClientsTableData} name="ai_clients_record">
                        <ExcelColumn label="Client Name" value="client_name" />
                        <ExcelColumn label="Strategist" value="strategists" />
                        <ExcelColumn label="Budget" value="price_range" />
                        <ExcelColumn label="Current Status" value="status" />
                        <ExcelColumn label="Booked How" value="booked_by" />
                        <ExcelColumn label="Source" value="source" />
                        <ExcelColumn label="Ad Tracking Codes" value="ad_tracking_codes" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-md-offset-4">
                {

                 <div className="form-group material-textfield">
                    <select
                      className="form-control custom__select material-textfield-input"
                      name="stategist_id"
                      value={this.state.strategist_id}
                      onChange={this.handleChange.bind(this)}>
                      <option value={0}>All</option>
                      {strategistList}
                    </select>
                    <label className="material-textfield-label">Strategist</label>
                  </div>
                }
                </div>
                {/* <div className="col-md-5">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div> */}
              </div>
              
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(aiClientsTableData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Client Name',
                              width: 250,
                              dataIndex: 'client_name',
                              key: '1',
                              fixed: 'left',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.client_name.localeCompare(b.client_name)
                            },
                            {
                              title: 'Strategist',
                              width: 300,
                              dataIndex: 'strategists',
                              key: '2',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.strategists.localeCompare(b.strategists),
                              render: (text, val) => {
                                const totalStrategist = val && val.strategists
                                let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                                return(
                                  <div>
                                  {
                                    strategistNo !== '' && totalStrategist.length > 1  ? (
                                      <td className="multilines"><div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenAgentModal.bind(this,val, 'strategist')}>{( totalStrategist.length >= 1 ? (totalStrategist ) : 'No Strategist' ) }</div></td>
                                    ):(
                                      strategistNo == '' && totalStrategist.length == 1 ? (
                                        <td><div className=''>{(totalStrategist)}</div></td>
                                      ):(
                                        <td><div className='' >No Strategist</div></td>
                                      )
                                    )
                                  }
                                  </div>
                                )
                              }
                            },
                            {
                              title: 'Budget',
                              width: 180,
                              dataIndex: 'price_range',
                              key: '3',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.price_range.localeCompare(b.price_range)
                            },
                            {
                              title: 'Current Status',
                              width: 320,
                              dataIndex: 'status',
                              key: '4',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.status.localeCompare(b.status)
                            },
                            {
                              title: 'Booked How',
                              width: 250,
                              dataIndex: 'booked_by',
                              key: '5',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.booked_by.localeCompare(b.booked_by)
                            },
                            {
                              title: 'Source',
                              width: 320,
                              dataIndex: 'source',
                              key: '6',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.source.localeCompare(b.source)
                            },
                            {
                              title: 'Ad Tracking Codes',
                              width: 350,
                              dataIndex: 'ad_tracking_codes',
                              key: '7',
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes)
                            },
                          ]}
                          dataSource={aiClientsTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
        
        <ReactModal
          isOpen={this.state.openAgent}
          contentLabel="Report"
          portalClassName="react-modal"
          >
            <div className="react-modal-dialog react-modal-dialog-centered">
              <div className="react-modal-header">
                <h5 className="react-modal-title">
                  {'Client - ' + this.state.townName+`'s`+' '+this.state.type+'(s)'}
                </h5>
                <button
                  type="button"
                  onClick={this.onCloseAgentModal.bind(this)}
                  className="btn react-modal-close"
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="info__block">
                  {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                    if(this.state.type == 'tag'){
                    return <p key={'agent-name-'+index}>{(agent)}</p>;
                    }else{
                    return <p key={'agent-name-'+index}>{(agent)}</p>;
                    }
                  })}
                </div>
                <div>
                </div>
              </div>
            </div>
          </ReactModal>

      </div>
    )
  }
}

