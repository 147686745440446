import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import { retry } from 'rxjs/operator/retry'
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class NoInitialOrFollowUpAgentCallComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      noInitialTableData: [],
      open: false
    }
  }

  componentDidMount(){
    _user = cookies.get("user")
    this.setState({ isLoading: true })
    if (_user) {
      const agentData = {
        from         : moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss"),
        to           : moment().format("YYYY-MM-DD HH:mm:ss"),
        selectedValue: 'total',
        town_arr     : this.state.town_list
      }
      this.props.noInitialAgentCallData(agentData)
    }
  }

  componentDidUpdate(preProps, preState){
    const { initialAgentCallPhase, initialAgentCallData, clearReportV2Phase } = this.props
    if(initialAgentCallPhase === "success"){
      let callArr = []
      let initDataArr = initialAgentCallData && initialAgentCallData.data
      if(_.size(initDataArr) > 0){
        initDataArr && initDataArr.map((data, i)=>{
          callArr.push({
            key: i,
            agent_full_name: data.agent_full_name,
            clients: data.clients,
            status: data.status,
            strategists: data.strategists,
            export_clients: data.clients.toString(),
            export_status: data.status.toString(),
            export_strategists: data.strategists.toString()
          })
        })
      }
      this.setState({ noInitialTableData: callArr, isLoading: false })
      clearReportV2Phase()
    }
    if(initialAgentCallPhase === "error"){
      clearReportV2Phase()
      this.setState({ noInitialTableData: [], isLoading: false })
    }
  }

  _onOpenModal(agent,key){
    let tags = []
    if(key == 'client'){
     tags = agent && agent.clients && agent.clients.indexOf(',') !== -1 ? agent.clients.split(',') : [agent.clients]
    }
    if(key == 'status'){
      tags = agent && agent.status && agent.status.indexOf(',') !== -1 ? agent.status.split(',') : [agent.status]
    }
    if(key == 'strategist'){
      tags = agent && agent.strategists && agent.strategists.indexOf(',') !== -1 ? agent.strategists.split(',') : [agent.strategists]
    }
    if(tags[0].length > 1){
      this.setState({ agentName: agent.agent_full_name, open: true, currentAgentTags: tags[0], key:key })
    }
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { noInitialTableData } = this.state
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back">
                      <BackArrowIcon className="arrow-icon"/>Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">No Initial or Follow up Agent Call</h2>
                      <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                        <ExcelSheet data={noInitialTableData} name="No_Initital_OR_FollowUp_Call_Report">
                          <ExcelColumn label="Agent Name" value="agent_full_name" />
                          <ExcelColumn label="Clients" value="export_clients" />
                          <ExcelColumn label="Status" value="export_status" />
                          <ExcelColumn label="Strategists" value="export_strategists" />
                        </ExcelSheet>
                      </ExcelFile>
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(noInitialTableData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          columns={[
                            {
                              title: 'Agent Name',
                              width: 250,
                              dataIndex: 'agent_full_name',
                              key: '1',
                              fixed: 'left', 
                            },
                            {
                              title: 'Client Name',
                              width: 250,
                              dataIndex: 'clientName', 
                              key: '2',
                              render: (text, agent) => {
                                const totalClient = agent && agent.clients
                                let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                                return(
                                  <div className={(totalClient.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, agent,'client')}>{(totalClient.length >= 1 ? totalClient[0]+clientNo : 'No Clients')}</div>
                                )
                              }
                            },
                            {
                              title: 'Pipeline Status',
                              dataIndex: 'pipelingStatus', 
                              key: '3',
                              width: 350,
                              render: (text, agent) => {
                                const status = agent && agent.status
                                let statusNo = (status.length-1) <= 0 ? '' : " (+"+(status.length-1)+")"
                                return(
                                  <div className={(status.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, agent,'status')}>{(status.length >= 1 ? status[0]+statusNo : 'No Status')}</div>
                                )
                              }
                            },
                            {
                              title: 'Strategist Name',
                              dataIndex: 'strategistName', 
                              key: '4',
                              width: 280,
                              render: (text, agent) => {
                                const totalStrategist = agent && agent.strategists
                                let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                                return(
                                  <div className={(totalStrategist.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, agent,'strategist')}>{(totalStrategist.length >= 1 ? totalStrategist[0]+strategistNo : 'No Strategist')}</div>
                                )
                              }
                            }
                          ]}
                          dataSource={noInitialTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
          </div>
        </main>
         
        <ReactModal
          isOpen={this.state.open}
          contentLabel="Report"
          portalClassName="react-modal"
          >
            <div className="react-modal-dialog react-modal-dialog-centered">
              <div className="react-modal-header">
                <h5 className="react-modal-title">
                {'Agent - ' + this.state.agentName+`'s`+' '+this.state.key+'(s)'}
                </h5>
                <button
                  type="button"
                  onClick={this.onCloseModal.bind(this)}
                  className="btn react-modal-close">
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="info__block">
                   {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                      return <p key={'tag-name-'+index}>{tag}</p>
                   })}
                </div>
                <div>
                </div>
              </div>
            </div>
          </ReactModal>

        </div>
      </div>
    )
  }
}