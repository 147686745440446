import { connect } from 'react-redux'
import {fetchUser,  handleSignOut, updatePassword, clearPhaseUser} from '../../store/user/duck'
import AgentCalendarForm from './component'
import { getUSerProfileByID, 
        getFilterParams, 
        getTowns,
        deleteClient,
        addUsers,
        clearPhase,
        updateScheduling,
        impersonateUser } from '../../store/dashboard/duck'

const agentcalendar = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    saveGoogleDataPhase: state.user.saveGoogleDataPhase,
    rxError: state.user.error,
    phase: state.user.phase,
    userData: state.dashboard.userData,
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    townData: state.dashboard.townData,
    addUserPhase: state.dashboard.addUserPhase,
    deleteUserdata: state.dashboard.deleteClientdata,
    passwordPhase: state.user.passwordPhase,
    junglerUserPhase: state.dashboard.junglerUserPhase,
    addUserMessage: state.dashboard.addUserMessage,
     impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData
  }),
  // Map actions to props
  {
    fetchUser,
    getUSerProfileByID,
    getFilterParams,
    getTowns,
    updatePassword,
    addUsers,
    clearPhase,
    clearPhaseUser,
    deleteClient,
    updateScheduling,
    handleSignOut,
    impersonateUser
  }
)(AgentCalendarForm)
export default agentcalendar
