import React from 'react'
import { Link } from 'react-router-dom'
import {
  DashboardIcon,
  DashboardColorIcon,
  MylistingIcon,
  LeadsIcon,
  ClientsColorIcon,
  MylistingColorIcon,
  YourAgentIcon,
  YourAgentColorIcon,
  AgentAppointmentsIcon,
  AgentAppointmentsColorIcon,
  TownCheatSheetIcon,
  TownCheatSheetColorIcon,
  TownsIcon,
  TownsColorIcon,
  JunglerUsersIcon,
  JunglerUsersColorIcon
} from '../icons'
import './styles.scss'
// import { townReportRoutes, interviewRoutes, dashboardRoutes, clientRoutes, taskRoutes, agentRoutes, appointmentRoutes, townsRoutes, junglerRoutes } from './variables'
import { townReportRoutes, interviewRoutes, dashboardRoutes, clientRoutes, taskRoutes, agentRoutes, appointmentRoutes, townsRoutes, junglerRoutes, emailTemplateRoutes } from './variables'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default function AdministrationSidebarNavigationComponent({ location }) {
  let _user = cookies.get('user')
  const isBD = _user && _user.role && _user.role === "business development"
  return (
    <aside id="sidebar-layout" className="sidebar-layout-sider administration-layout-sider">
      <div className="sidebar-layout-sider__children">
        <div className="sidebar-layout-sider__logo">
          <Link className="sidebar-item__link" to={'/administration/dashboard'}><img src={`../img/logo.svg`} alt="Suburban Jungle" /></Link>
          {/*<Link className="sidebar-item__link" to={'/strategist/clients'}><img src="../img/logo.svg" alt="" /></Link>*/}
        </div>
        <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
          <li className={`sidebar-item ${dashboardRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={'/administration/dashboard'}>
              <span className="sidebar-item__icon">
                {dashboardRoutes.includes(location.pathname) ? <DashboardColorIcon /> : <DashboardIcon />}</span>
                Dashboard
              </Link>
          </li>
          <li className={`sidebar-item ${(clientRoutes.includes(location.pathname) ||
            clientRoutes.indexOf(location.pathname.replace(/[0-9]/g, '')) !== -1) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={isBD ? '/agent/clients/all-clients' : '/strategist/clients'}>
              <span className="sidebar-item__icon">
                {clientRoutes.includes(location.pathname) ||
                  clientRoutes.indexOf(location.pathname.replace(/[0-9]/g, '')) !== -1
                  ? <ClientsColorIcon /> : <LeadsIcon />}
              </span>
                 Clients
              </Link>
          </li>
          {!isBD &&
            <li className={`sidebar-item ${taskRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
              <Link className="sidebar-item__link" to={'/strategist/task-list'}>
                <span className="sidebar-item__icon">
                  {taskRoutes.includes(location.pathname) ? <MylistingColorIcon /> : <MylistingIcon />}
                </span>
                  Task List
                </Link>
            </li>
          }
          {/*{!isBD &&*/}
          {/*  <li className={`sidebar-item ${agentRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>*/}
          {/*    <Link className="sidebar-item__link" to={'/strategist/your-agents'}>*/}
          {/*      <span className="sidebar-item__icon">*/}
          {/*        {agentRoutes.includes(location.pathname) ? <YourAgentColorIcon /> : <YourAgentIcon />}*/}
          {/*      </span>*/}
          {/*        Your Agents*/}
          {/*      </Link>*/}
          {/*  </li>*/}
          {/*}*/}
          {/*           <li className={`sidebar-item ${townsRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={'/strategist/town-cheat-sheet'}>
              <span className="sidebar-item__icon">
                {townsRoutes.includes(location.pathname) ? <TownCheatSheetColorIcon /> : <TownCheatSheetIcon />}
              </span>
                Town Cheat Sheet
              </Link>
          </li> */}
          {!isBD &&
            <li className={`sidebar-item ${appointmentRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
              <Link className="sidebar-item__link" to={'/strategist/agent-appointments'}>
                <span className="sidebar-item__icon">
                  {appointmentRoutes.includes(location.pathname) ? <AgentAppointmentsColorIcon /> : <AgentAppointmentsIcon />}
                </span>
                  Agent Appointments
                </Link>
            </li>
          }
          {!isBD &&
            <li className={`sidebar-item ${interviewRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
              <Link className="sidebar-item__link" to={'/strategist/agent-interviews'}>
                <span className="sidebar-item__icon">
                  {interviewRoutes.includes(location.pathname) ? <AgentAppointmentsColorIcon /> : <AgentAppointmentsIcon />}
                </span>
                  Agent Applications
                </Link>
            </li>
          }
          <li className={`sidebar-item ${(townReportRoutes.includes(location.pathname) || location.pathname.startsWith('/strategist/town-characteristics')) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={'/strategist/town-reports'}>
              <span className="sidebar-item__icon">
                {(townReportRoutes.includes(location.pathname) || location.pathname.startsWith('/strategist/town-characteristics') || townReportRoutes.indexOf(location.pathname.replace(/[0-9]/g, '')) !== -1) ? <TownsColorIcon /> : <TownsIcon />}
              </span>
                Towns
              </Link>
          </li>
          {!isBD &&
            <li className={`sidebar-item ${junglerRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
              <Link className="sidebar-item__link" to={'/strategist/jungler-users'}>
                <span className="sidebar-item__icon">
                  {junglerRoutes.includes(location.pathname) ? <JunglerUsersColorIcon /> : <JunglerUsersIcon />}
                </span>
                  Users
                </Link>
            </li>
          }
          {_user && _user.role && _user.role === "admin" && <li className={`sidebar-item ${(location.pathname.startsWith('/report/brokerage-report')) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={'/report/brokerage-report'}>
              <span className="sidebar-item__icon">
                {(location.pathname.startsWith('/report/brokerage-report')) ? <DashboardColorIcon /> : <DashboardIcon />}
              </span>
              Brokerage Report
            </Link>
          </li>}

          {/* {_user && _user.role && _user.role === "strategist" && <li className={`sidebar-item ${emailTemplateRoutes.includes(location.pathname) && "sidebar-item-is-selected sidebar-item"}`}>
            <Link className="sidebar-item__link" to={'/strategist/email-template'}>
              <span className="sidebar-item__icon fa fa-envelope-o">
                {emailTemplateRoutes.includes(location.pathname)}
              </span>
           Email Templates
           </Link>
          </li>} */}
        </ul>
      </div>
    </aside>
  )
}
