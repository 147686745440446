import React, { Fragment } from 'react'
import { Drawer } from 'antd'
import _ from 'lodash'
import { map, filter, compact } from 'lodash'
import { CloseIcon } from '../../../../../components/icons'
import { SUCCESS } from '../../../../../constants/phase'

export default class RecommendedTownDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTown: [],
    }
  }
  componentDidMount() {
    // fetch all ai unapproved recommended towns
    const clientId = this.props.match.params.id
    this.props.getUnapprovedRecommendedTowns({ clientId })
  }
  static getDerivedStateFromProps(props, state) {
    if (props.fetchClientRecommendedTownPhase === SUCCESS) {
      // filter all towns and show selected town out for showing auto select
      const towns = filter(props.recommendedTowns, (town) => town.status !== 2)
      props.clearClientDetailPhase()
      return { ...state, selectedTown: map(towns, (town) => town.town_id) }
    }
    if (props.fetchClientRecommendedTownPhase === SUCCESS) {
      const clientId = this.props.match.params.id
      props.getUnapprovedRecommendedTowns({ clientId })
      props.clearClientDetailPhase()
      return state
    }
    return state
  }

  selectTown = (townId) => {
    const { selectedTown } = this.state
    if (selectedTown.indexOf(townId) !== -1) {
      selectedTown.pop(townId)
    } else {
      selectedTown.push(townId)
    }
    this.setState({ selectedTown })
  }
  handleSaveTown = () => {
    const { selectedTown } = this.state
    // selected towns return town id for approved towns
    const approvedTowns = compact(
      map(this.props.recommendedTowns, (recommendedTown) => {
        if (selectedTown.includes(recommendedTown.town_id))
          return recommendedTown.id
      })
    )
    // filter out all selected except of this all town are unapproved
    const unapprovedTowns = map(
      filter(
        this.props.recommendedTowns,
        (recommendedTown) => !selectedTown.includes(recommendedTown.town_id)
      ),
      (town) => town.id
    )
    this.props.approveRecommendedTowns({
      clientId: this.props.match.params.id,
      approvedTowns,
      unapprovedTowns
    })
  }

  render() {
    let { recommendedTowns } = this.props
    recommendedTowns = _.orderBy(recommendedTowns, ['town_want', 'cns_percentage'], ['desc', 'desc']);
    const { selectedTown } = this.state
    return (
      <Drawer
        placement="right"
        closable={false}
        width="100%"
        onClose={this.props.onClose}
        visible={true}
        className="drawer__main"
      >
        <div className="drawer__header align__items__center">
          <h4 className="drawer__header__title"> AI Recommended Town</h4>
          <div>CNS Total: {recommendedTowns && recommendedTowns[0] && recommendedTowns[0].cns_total}</div>
          <div className="drawer__header__close" onClick={this.props.onClose}>
            <button className="btn btn__link">
              <CloseIcon />
            </button>
          </div>

        </div>
        {recommendedTowns && recommendedTowns.length > 0 ? (
          <Fragment>
            <div className="ai__recommended__towns">
              <ul className="ai__recommended__towns__list">
                {map(recommendedTowns, (town, townIndex) => (
                  <li
                    key={townIndex}
                    className={
                      selectedTown.includes(town.town_id) ? 'active' : ''
                    }
                    onClick={() => this.selectTown(town.town_id)}
                  >
                    {/* {town.town_name} */}
                    {town.town_name}  {town.cns_percentage}%
                    {town.town_want === 1 && <span className="recommended_town">Client selected.</span>}
                  </li>
                ))}
              </ul>
            </div>
            <div className="ai__recommended__towns__bottom">
              <button
                type="button"
                className="btn btn__btn btn__lg btn-dark w__100"
                onClick={this.handleSaveTown}
              >
                Save
              </button>
            </div>
          </Fragment>
        ) : (
            <div className="drawer__body__inner">
              <div className="blank__state__text">
                No town(s) are available for this client.
            </div>
            </div>
          )}
      </Drawer>
    )
  }
}
