import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  error: null,
  appointments: [],
  appointmentCount: {},
  agentAvailability: [],
  strategistAvailabilityDates: [],
  strategistAvailabilityTimes: [],
  createAgentPhase: INIT,
  fetchAppointmentPhase: INIT,
  strategistAvailabilityPhase: INIT,
  fetchAgentAvailabilityPhase: INIT,
  strategistAvailabilityDatePhase: INIT,
  createStrategistAppointmentPhase: INIT,
})

export const appointmentReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  fetch appointment list */
    case types.FETCH_APPOINTMENT_LIST: {
      return state.set('error', null).set('fetchAppointmentPhase', LOADING)
    }
    case types.FETCH_APPOINTMENT_LIST_SUCCESS: {
      const appointments = get(action, 'payload.data', {})
      return state
        .set('appointments', appointments.appointments)
        .set('appointmentCount', appointments.count)
        .set('error', null)
        .set('fetchAppointmentPhase', SUCCESS)
    }
    case types.FETCH_APPOINTMENT_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchAppointmentPhase', ERROR)
    }

    /*  fetch strategist availability  */
    case types.FETCH_STRATEGIST_AVAILABILITY: {
      return state.set('strategistAvailabilityPhase', LOADING).set('error', null)
    }
    case types.FETCH_STRATEGIST_AVAILABILITY_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('strategistAvailabilityPhase', SUCCESS)
        .set('strategistAvailabilityTimes', payload.data)
    }
    case types.FETCH_STRATEGIST_AVAILABILITY_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('strategistAvailabilityPhase', ERROR)
    }

    /*  fetch agent availability  */
    case types.FETCH_AGENT_AVAILABILITY: {
      return state.set('fetchAgentAvailabilityPhase', LOADING).set('error', null)
    }
    case types.FETCH_AGENT_AVAILABILITY_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('fetchAgentAvailabilityPhase', SUCCESS)
        .set('agentAvailability', payload)
    }
    case types.FETCH_AGENT_AVAILABILITY_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('fetchAgentAvailabilityPhase', ERROR)
    }

    /*  create agent appointment  */
    case types.CREATE_AGENT_APPOINTMENT: {
      return state.set('createAgentPhase', LOADING).set('error', null)
    }
    case types.CREATE_AGENT_APPOINTMENT_SUCCESS: {
      return state.set('error', null).set('createAgentPhase', SUCCESS)
    }
    case types.CREATE_AGENT_APPOINTMENT_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('createAgentPhase', ERROR)
    }

    /*  create strategist appointment  */
    case types.CREATE_STRATEGIST_APPOINTMENT: {
      return state.set('createStrategistAppointmentPhase', LOADING).set('error', null)
    }
    case types.CREATE_STRATEGIST_APPOINTMENT_SUCCESS: {
      return state.set('error', null).set('createStrategistAppointmentPhase', SUCCESS)
    }
    case types.CREATE_STRATEGIST_APPOINTMENT_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('createStrategistAppointmentPhase', ERROR)
    }

    case types.GET_STRATEGIST_AVAILLABLE_DATES: {
      return state.set('error', null).set('strategistAvailabilityDatePhase', INIT)
    }
    case types.GET_STRATEGIST_AVAILLABLE_DATES_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('strategistAvailabilityDates', payload.data)
        .set('strategistAvailabilityDatePhase', SUCCESS)
    }
    case types.GET_STRATEGIST_AVAILLABLE_DATES_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('strategistAvailabilityDatePhase', ERROR)
    }

    /* clear strategist appointment phase  */
    case types.CLEAR_STRATEGIST_APPOINTMENT_PHASE: {
      return state
        .set('error', null)
        .set('createAgentPhase', INIT)
        .set('fetchAppointmentPhase', INIT)
        .set('strategistAvailabilityPhase', INIT)
        .set('createStrategistAppointmentPhase', INIT)
        .set('strategistAvailabilityDatePhase', INIT)
        .set('fetchAgentAvailabilityPhase', INIT)
    }
    default: {
      return state
    }
  }
}
