import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'
import * as api from './api'
import * as types from './action-types'

const getDashboardDataEpic = (action$) =>
  action$.ofType(types.GET_AGENT_DASHBOARD).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getDashboardData(action.payload))
      .map((payload) => ({
        type: types.GET_AGENT_DASHBOARD_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_AGENT_DASHBOARD_ERROR,
          payload: { error },
        })
      )
      .takeUntil(action$.ofType(types.GET_AGENT_DASHBOARD_CANCELLED))
  })

const getNotTouchedDataEpic = (action$) =>
  action$.ofType(types.GET_NOT_TOUCHED_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getNotTouchedData(action.payload))
      .map((payload) => ({
        type: types.GET_NOT_TOUCHED_DATA_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_NOT_TOUCHED_DATA_ERROR,
          payload: { error },
        })
      )
      .takeUntil(action$.ofType(types.GET_NOT_TOUCHED_DATA_CANCELLED))
  })

const getNotTouchedIn4WeekEpic = (action$) =>
  action$.ofType(types.GET_NOT_TOUCHED_IN_4WEEK).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getNotTouchedIn4Week(action.payload))
      .map((payload) => ({
        type: types.GET_NOT_TOUCHED_IN_4WEEK_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_NOT_TOUCHED_IN_4WEEK_ERROR,
          payload: { error },
        })
      )
      .takeUntil(action$.ofType(types.GET_NOT_TOUCHED_IN_4WEEK_CANCELLED))
  })

// call fetch agent all clinet epic
const fetchAgentAllClientEpic = (action$) =>
  action$.ofType(types.FETCH_AGENT_ALL_CLIENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchAgentAllClients(action.payload))
      .map((payload) => ({
        type: types.FETCH_AGENT_ALL_CLIENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_AGENT_ALL_CLIENT_SUCCESS,
          payload: { error },
        })
      )
  })

// call delete agent chat with client data epic
const deleteChatDataEpic = (action$) =>
  action$.ofType(types.DELETE_CHAT_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.deleteChatData(action.payload))
      .map((payload) => ({
        type: types.DELETE_CHAT_DATA_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.DELETE_CHAT_DATA_ERROR,
          payload: { error },
        })
      )
  })

// call edit strategist and  agent note epic
const editStrategistAgentNoteEpic = (action$) =>
  action$.ofType(types.EDIT_STRATEGIST_AGENT_NOTE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.editStrategistAgentNote(action.payload))
      .map((payload) => ({
        type: types.EDIT_STRATEGIST_AGENT_NOTE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.EDIT_STRATEGIST_AGENT_NOTE_ERROR,
          payload: { error },
        })
      )
  })

// call delete strategist and agent note epic
const deleteStrategistAgentNoteEpic = (action$) =>
  action$.ofType(types.DELETE_STRATEGIST_AGENT_NOTE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.deleteStrategistAgentNote(action.payload))
      .map((payload) => ({
        type: types.DELETE_STRATEGIST_AGENT_NOTE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.DELETE_STRATEGIST_AGENT_NOTE_ERROR,
          payload: { error },
        })
      )
  })

// call delete strategist and agent note epic
const editClientChatEpic = (action$) =>
  action$.ofType(types.EDIT_CLIENT_CHAT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.editClientChat(action.payload))
      .map((payload) => ({
        type: types.EDIT_CLIENT_CHAT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.EDIT_CLIENT_CHAT_ERROR,
          payload: { error },
        })
      )
  })

/**
 * Public: Export Epics
 */

export const agentV2Epic = combineEpics(
  getDashboardDataEpic,
  getNotTouchedDataEpic,
  fetchAgentAllClientEpic,
  getNotTouchedIn4WeekEpic,
  editStrategistAgentNoteEpic,
  deleteStrategistAgentNoteEpic,
  editClientChatEpic,
  deleteChatDataEpic
)
