import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Modal from 'react-responsive-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class ClientsPerTown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      fromDate: moment().add(-1, 'month').startOf('month'),
      // fromDate: moment().add(-5, 'years').startOf('month'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      type:'assignPerTown',
      currentAgentClients:[],
      openClient: false,
      open:false,
      currentAgentTags: [],
      currentTownAgents: [],
      openAgent: false,
      currentTownStategist: [],
      openStr:false,
      clientsAssignPerAgentState:[],
      value: '',
      copied: false,
      selectIndex:null,
      selectOutIndex:null,
      selectedIndex: 'all',
      clientsEmails:[],
      selectOutEmails:false,
      selectInEmails:false,
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment().add(-1, 'month').startOf('month'),
      defaultEndDate: moment()
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}

      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      // strData.strategist_arr = this.state.client_strategist
      // this.props.getStrategistList(strData)
      this.props.getClientsPerAgents(strData)
      this.setState({isLoadingData: true})
      // this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.clientsPerAgentsPhase === "success"){
      const clientsAssignAgents = [] 
      if(nextProps.clientsPerAgentsData && nextProps.clientsPerAgentsData.length > 0){
        nextProps.clientsPerAgentsData.map(function(data){
          let obj ={}
          obj.town_name = data.town_name
          
          obj.totalClient =  data.totalClient.length > 0 ? data.totalClient.map(e => { return e.client_name}).join(" ,") : ""
          obj.totalTag = data.totalTag.length > 0 ? data.totalTag.map(e => { return e }).join(" ,") : ""
          obj.totalAgent = data.totalAgent.length > 0 ?  data.totalAgent.map(e => { return e.agent_full_name}).join(" ,") : ""
          obj.totalStrategist = data.totalStrategist.length > 0 ? data.totalStrategist.map(e => { return e.strategist_name}).join(" ,") : ""
          clientsAssignAgents.push(obj)
        })
        this.setState({ isLoadingData: false, clientsAssignPerAgentState:clientsAssignAgents },() => {})
        this.props.clearPhase()
      }else{
        this.setState({ isLoadingData: false, clientsAssignPerAgentState:clientsAssignAgents },() => {})
        this.props.clearPhase()
      }
    }
  }

  componentDidMount() {
    let self = this;
    // $(document).click(function(event) {
    //   if (!$(event.target).closest("#dropdownBtn").length) {
    //     if ($("#dropdownBtn").is(":visible")) {
    //       self.setState({ strategistDropdown: false });
    //     }
    //   }
    // });
  }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedIndex === "all"){
            strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
          }
          strData.dateType = "from_date"
          strData.type = this.state.type
          this.props.getClientsPerAgents(strData)
        }.bind(this), 300)
    })
  }

  onOpenClientModal(agent) {
    const clients = agent && agent.totalClient
    if(clients.length > 1){
      let clientsEmails = []
      clients.map((client)=> {
        clientsEmails.push(client.email)
      })
      this.setState({ agentName: agent.town_name, openClient: true, currentAgentClients: clients, selectIndex:null, selectOutIndex: null, clientsEmails: clientsEmails.toString()})
    }
  }

  
  onCloseClientModal() {
    this.setState({ agentName: '', currentAgentClients: [], openClient: false, selectIndex: null, selectOutIndex: null })
  }

  onOpenModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if(tags[0].length > 1){
      this.setState({ agentName: agent.town_name, open: true, currentAgentTags: tags[0] })
    }
  }


  onOpenAgentsModal(agent) {
    const agents = agent && agent.totalAgent
    if(agents.length > 1){
      this.setState({ agentName: agent.town_name, openAgent: true, currentTownAgents: agents })
    }
  }


  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  onCloseAgentsModal() {
    this.setState({ agentName: '', currentTownAgents: [], openAgent: false })
  }

  onOpenStrategistModal(agent) {
    const strategists = agent && agent.totalStrategist 
    if(strategists.length > 1){
      this.setState({ agentName: agent.town_name, openStr: true, currentTownStategist: strategists })
    }
  }

  onCloseStrategistModal() {
    this.setState({ agentName: '', currentTownStategist: [], openStr: false })
  }

  onCopy1(index){
    this.setState({copied: true, selectOutIndex: index})
    setTimeout( function(){$('#showEmail1').hide();} , 2000)
  }
  onCopy2(index){
    this.setState({copied: true, selectIndex: index})
    setTimeout( function(){$('#showEmail2').hide();} , 2000)
  }

  filterDate(e){
    if(e.target.value === "all"){
      this.setState({ isLoadingData: true, modalIsOpen: false},() => {})
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }else{
      this.setState({ isLoadingData: true},() => {})
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }
    this.setState({ selectedIndex: e.target.value },() => {})
  }

  onCopy3(){
    this.setState({ selectOutEmails: true })
    setTimeout(() => {
      this.setState({ selectOutEmails: false });
    }, 2000)
  }

  onCopy4(){
    this.setState({ selectInEmails: true })
    setTimeout(() => {
      this.setState({ selectInEmails: false });
    }, 2000)
  }
  render() {
    const { clientsPerAgentsData } = this.props

    const { openClient, open, openAgent,openStr, clientsAssignPerAgentState, value, selectedIndex, clientsEmails } = this.state;
    let clientsEmailData = []
    const clientsAssignPerAgentData = clientsAssignPerAgentState
    clientsPerAgentsData.map((str)=>{
      const totalClient = str && str.totalClient
      if(totalClient.length > 0){
        totalClient.map((client)=> {
          clientsEmailData.push(client.email)
        })
      }
    })
    clientsEmailData = _.uniq(clientsEmailData)
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Clients Assigned Per Town</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{clientsPerAgentsData && clientsPerAgentsData.length}</span> Towns</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">
              <div className="right__cont right__cont--full">
              <div className="all__custom--wrapper">
                <select 
                  name="selectedIndex"
                  className="date__picker"
                  value = {this.state.selectedIndex} 
                  onChange={this.filterDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {
                (this.state.selectedIndex !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                      <Modal2
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}           
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">             
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>               
                          </div>
                        </div>
                      </Modal2>
                    </div>
                  }
                </div>
              }

              
              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={clientsAssignPerAgentData} name="clients_per_agents">
                    <ExcelColumn label="Town" value="town_name"/>
                    <ExcelColumn label="Client Name" value="totalClient"/>
                    <ExcelColumn label="Tag" value="totalTag"/>
                    <ExcelColumn label="Agent Name" value="totalAgent"/>
                    <ExcelColumn label="Strategist" value="totalStrategist"/>
                </ExcelSheet>
              </ExcelFile>
              
            </div>
            </div>

            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
            <div className="filter__container filter__container--users">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra">
                   <div className="table-responsive">
                     <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Town</th>
                            <th className="text-left">Client Name 
                            </th>
                            <th className="text-left">Tag</th>
                            <th className="text-left">Agent Name</th>
                            <th className="text-left">Strategist</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="9" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your clients report is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                        {
                          clientsPerAgentsData && clientsPerAgentsData.map((str, index)=>{
                            const totalClient = str && str.totalClient
                            const totalAgent = str && str.totalAgent
                            const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]

                            const tagArr = tags[0]
                            const totalStrategist = str && str.totalStrategist
                            let clientNo = (totalClient.length-1) <= 0 ? (totalClient.length > 0 ? ' ('+totalClient[0].email+')' : '') : " (+"+(totalClient.length-1)+")"
                            let agentNo = (totalAgent.length-1) <= 0 ? '' : " (+"+(totalAgent.length-1)+")"
                            let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                            let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                            return(
                              <tr key={index} className="forcursor" >
                                <td>{str.town_name}</td>
                                <td className="wrap-text multilines">
                                  <div className={(totalClient.length > 1 ? "link" : '')} onClick={this.onOpenClientModal.bind(this, str)}>{(totalClient.length >= 1 ? totalClient[0].client_name+clientNo : 'No Clients') }
                                    {totalClient.length == 1 ? 
                                    <CopyToClipboard 
                                      // text={totalClient[0].email} 
                                      text={totalClient[0].email} 
                                      // onCopy={() => this.setState({copied: true, selectOutIndex: index })}> 
                                      onCopy={this.onCopy1.bind(this,index)}> 
                                      <i className="glyphicon glyphicon-duplicate"></i>
                                    </CopyToClipboard> : ''}
                                    {this.state.selectOutIndex === index ? <span id ="showEmail1" style={{color: '#c7ce4a'}}> Copied</span> : ''}
                                  </div>
                                </td>
                                <td className="wrap-text multilines"><div className={(tagArr.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0]+tagNo : 'No Tag' )}</div></td>

                                <td className="wrap-text multilines"><div className={(totalAgent.length > 1 ? "link" : '')} onClick={this.onOpenAgentsModal.bind(this, str)}>{(totalAgent.length >= 1 ? totalAgent[0].agent_full_name+agentNo : 'No Agents')}</div></td>

                                <td className="wrap-text multilines"><div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenStrategistModal.bind(this, str)}>{( totalStrategist.length >= 1 ? totalStrategist[0].strategist_name+strategistNo : 'No Strategist')}</div></td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                     </div>
                   </div>
                 </div>
               </div>
              </main>
              <div  >
                <Modal2 isOpen={openClient} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading" >{'Town - ' + this.state.agentName+`'s`+' client(s)'} 
                      </h4>
                      <CopyToClipboard 
                        text={clientsEmails} 
                        onCopy={this.onCopy4.bind(this)}>
                        <button className="copyBoxButton orderchange" >
                          <span >{this.state.selectInEmails ? "Copied" : "Copy" } Emails</span>
                          <i className="glyphicon glyphicon-duplicate page-generator__button-icon"></i>
                        </button>
                      </CopyToClipboard>
                      <div className="close__btn close_img--white" onClick={this.onCloseClientModal.bind(this)}><img src="img/close_btn.svg"/></div>
                     
                     
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                          return <div className="allpoppadding__paranew" key={'client-name-'+index} >{client.client_name} ({client.email})  
                                <CopyToClipboard text={client.email}
                                  onCopy={this.onCopy2.bind(this, index)}>
                                  <i className="glyphicon glyphicon-duplicate"></i>
                                </CopyToClipboard>
                                {this.state.selectIndex === index ? <span id ="showEmail2" style={{color: '#c7ce4a'}}> Copied</span> : ''}
                            </div>
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseClientModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={open} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Town - ' + this.state.agentName+`'s`+' tag(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                        return <div className="allpoppadding__paranew" key={'tag-name-'+index}>{tag}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseModal.bind(this)}>Close</button>
                </Modal2>
              </div>

              <div>
                <Modal2 isOpen={openAgent} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Town - ' + this.state.agentName+`'s`+' agent(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseAgentsModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                        return <div className="allpoppadding__paranew" key={'agent-name-'+index}>{agent.agent_full_name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseAgentsModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={openStr} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Town - ' + this.state.agentName+`'s`+' strategist(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseStrategistModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentTownStategist && this.state.currentTownStategist.map((strategist, index) => {
                        return <div className="allpoppadding__paranew" key={'strategist-name-'+index}>{strategist.strategist_name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseStrategistModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              {
                clientsPerAgentsData && clientsPerAgentsData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Clients Assigned Per Town</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'ClientsPerTownlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientsPerTown)
