import { connect } from 'react-redux'
import {loginUser, fetchUser, getNotifications, clearNotification} from '../../store/user/duck'
import {getJunglerUsers, impersonateUser, clearPhase} from '../../store/dashboard/duck'
import JunglerUsersList from './component'
const JunglerUsers = connect(
  // Map state to props
  (state) => ({
    dataPhase: state.dashboard.dataPhase,
    profilePhase: state.user.profilePhase,
    profileError: state.user.profileError,
    user: state.user.users,
    usersData: state.dashboard.usersData,
    isSubmitting: state.user.isSubmitting,
    error: state.dashboard.error,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData
  }),
  // Map actions to props
  {
    loginUser,
    fetchUser,
    getJunglerUsers,
    getNotifications,
    clearNotification,
    impersonateUser,
    clearPhase
  }
)(JunglerUsersList)
export default JunglerUsers
