import { connect } from 'react-redux'

import { updateClientUser, agentClientFollowUp, clearPhase } from '../../../../../../store/agent/duck'
import { getNotTouchedIn4Week } from '../../../../../../store/agentV2/actions'

import NotTouchedInFourWeekComponent from './component'
const NotTouchedInFourWeekContainer = connect(
  // Map state to props
  (state) => ({
    notTouchedIn4WeekData: state.agentV2.notTouchedIn4WeekData,
    followUpPhase: state.agent.followUpPhase
  }),
  // Map actions to dispatch and props
  {
    updateClientUser,
    agentClientFollowUp,
    getNotTouchedIn4Week,
    clearPhase
  }
)(NotTouchedInFourWeekComponent)

export default NotTouchedInFourWeekContainer
