import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import { getClientStrategist, getClientAgent, scheduleMeeting } from '../../store/client/duck'
import ClientsAppointmentForm from './component'
const ClientsAppointment = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    profilePhase: state.user.profilePhase,
    message: state.user.message,
    userUpdatePhase: state.user.userUpdatePhase,
    strategistData: state.client.strategistData,
    agentData: state.client.agentData,
    submitSchedulePhase: state.client.submitSchedulePhase,
    resMessage: state.client.resMessage
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getClientStrategist,
    getClientAgent,
    scheduleMeeting
  }
)(ClientsAppointmentForm)
export default ClientsAppointment
