import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import { map, size, get } from 'lodash'
import Cookies from 'universal-cookie'
import Config from '../../../../../../config';
import DatePicker from '../../../../../../components/datePicker'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import ReactModal from 'react-modal'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "Total Clients Received per Year"]

export default class TotalClientsReceivedPerMonthComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      userRole: '',
      strName: '',
      strategist_id: 0,
      strategist_name: '',
      clientTableData: []
    }
  }

  componentDidMount(){
    _user = cookies.get("user")
    if (_user) {
      this.setState({ userRole: _user.role, strName: (_user.first_name+' '+_user.last_name), isLoading: true })
      this.props.getFilterParams()
    }
  }

  componentDidUpdate(preProps, preState){
    const { filterParamsPhase, filterParams, clearFilterPhase, totalPerMonthPhase, totalPerMonthData, clearReportV2Phase } = this.props
    if(filterParamsPhase === "success"){
      clearFilterPhase()
      let strList = filterParams && filterParams.data && filterParams.data.strategists
      if(this.state.strategist_id === 0){
        let strData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
        if(strData){
          this.setState({ strategist_id: strData.id, strategist_name: strData.strategist_name }, ()=>{
            this.props.getTotalClientPerMonth({ strategist_id: strData.id })
          })
        }
      }
    }
    if(filterParamsPhase === "error"){
      clearFilterPhase()
    }
    if(totalPerMonthPhase === "success"){
      clearReportV2Phase()
      let data = []
      const clientArr = totalPerMonthData && totalPerMonthData.data
      if(size(clientArr) > 0){
        
        clientArr && clientArr.map((clnt, i)=>{
          data.push({
            key: i,
            compare19to18: clnt.compare19to18,
            compare20to19: clnt.compare20to19,
            percentage1819: clnt.percentage1819,
            percentage1920: clnt.percentage1920,
            y18: clnt.y18,
            y19: clnt.y19,
            y20: clnt.y20
          })
        })
      }
      this.setState({ clientTableData: data, isLoading: false })
    }
    if(totalPerMonthPhase === "error"){
      clearReportV2Phase()
      this.setState({ clientTableData: [], isLoading: false })
    }
  }

  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoading: true },()=>{
      let data = {
        strategist_id: parseInt(this.state.strategist_id)
      }
      this.props.getTotalClientPerMonth(data)
    })
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { clientTableData } = this.state
    const { filterParams } = this.props
    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)}  className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={get(page, 'value')}>
                                {get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Total Clients Received</h2>
                     <p className="reports__subtitle">*Total (Questionnaires+Created In Jungler)</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-md-offset-9">
                  <div className="form-group material-textfield">
                    <select
                      className="form-control custom__select material-textfield-input"
                      name="stategist_id"
                      value={this.state.strategist_id}
                      onChange={this.handleChange.bind(this)}>
                      {strategistList}
                    </select>
                    <label className="material-textfield-label">Strategist</label>
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (size(clientTableData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          pagination={false}
                          columns={[
                            {
                              title: "Month's Name",
                              width: 250,
                              dataIndex: 'name',
                              key: '1',
                              fixed: 'left',
                              render: (text, clnt) => {
                                return(
                                  <span className={clnt.key === 12 ? "only__for-index" : ""}>{months[clnt.key]}</span>
                                )
                              }
                            },
                            {
                              title: '2018',
                              width: 180,
                              dataIndex: 'y18',
                              key: '2',
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.y18}</span>
                                )
                              }
                            },
                            {
                              title: '2019',
                              dataIndex: 'y19',
                              key: '3',
                              width: 180,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.y19}</span>
                                )
                              }
                            },
                            {
                              title: '2020',
                              dataIndex: 'y20',
                              key: '4',
                              width: 180,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.y20}</span>
                                )
                              }
                            },
                            {
                              title: '2020 versus 2019',
                              dataIndex: 'compare20to19',
                              key: '5',
                              width: 230,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.compare20to19}</span>
                                )
                              }
                            },
                            {
                              title: '2020 versus 2019 %',
                              dataIndex: 'percentage1920',
                              key: '6',
                              width: 230,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.key === 12 ? '' : (val && val.percentage1920 && val.percentage1920.toFixed(0))+'%' }</span>
                                )
                              }
                            },
                            {
                              title: '2019 versus 2018',
                              dataIndex: 'compare19to18',
                              key: '7',
                              width: 230,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index text-center" : "text-center"}>{val.compare19to18}</span>
                                )
                              }
                            },
                            {
                              title: '2019 versus 2018 %',
                              dataIndex: 'percentage1819',
                              key: '8',
                              width: 230,
                              render: (text, val) => {
                                return(
                                  <span className={val.key === 12 ? "only__for-index" : ""}>{val.key === 12 ? '' : (val && val.percentage1819 && val.percentage1819.toFixed(0))+'%' }</span>
                                )
                              }
                            },
                          ]}
                          dataSource={clientTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
