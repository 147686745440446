import React, { PureComponent /*, PropTypes*/ } from 'react';
import get from 'lodash/get';
// import IPropTypes from 'react-immutable-proptypes'

import './styles.scss';

export default class NoteInternalComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: ''
    };
  }

  static propTypes = {
    // PropTypes go here
  };

  addInsight = (e) => {
    e.preventDefault()
    const {
      insight,
      match: {
        params: { townId }
      }
    } = this.props;

    let data = {
      id: get(insight, 'id', 0),
      town_id: townId,
      type: 'education',
      file: '',
      note: get(this, 'state.note', ''),
      insight_type: 'note',
      is_external: 0,
      created_by: this.props.user.id,
      fileName: ''
    };

    this.props.addInsight(data);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.addInsight}>
          <div className="form-group material-textfield">
            <textarea
              onChange={e => this.setState({ note: e.target.value })}
              defaultValue={get(this, 'props.insight.note')}
              className="form-control material-textfield-input textfield-input-lg"
              rows="8"
              required
            ></textarea>
            <label className="material-textfield-label label-lg">
              Write an insight note about {get(this, 'props.town_name', '')}
            </label>
          </div>

          <button
            hidden
            ref={this.props.submitBtnRef}
            type="submit"
            className="btn btn__btn btn-dark btn__lg w__100"
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}
