import { connect } from 'react-redux'

import { impersonateUser, clearPhase } from '../../../../store/dashboard/duck'
import { getJunglerUsers, clearJunglerUserPhase } from '../../../../store/strategistV2/jungleruser/actions'

import JunglerUsersComponent from './component'

const JunglerUsersContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    profilePhase: state.user.profilePhase,

    junglerUsers: state.strategistV2.junglerUser.junglerUsers,
    usersCounts: state.strategistV2.junglerUser.junglerUserCounts,
    fetchJunglerUserPhase: state.strategistV2.junglerUser.fetchJunglerUserPhase,
  }),
  // Map actions to dispatch and props
  {
    getJunglerUsers,
    impersonateUser,
    clearPhase,
    clearJunglerUserPhase
  }
)(JunglerUsersComponent)

export default JunglerUsersContainer
