import { connect } from 'react-redux'

import NoInitialOrFollowUpAgentCallComponent from './component'
import { noInitialAgentCallData, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const NoInitialOrFollowUpAgentCallContainer = connect(
  // Map state to props
  (state) => ({
    initialAgentCallPhase: state.reportV2.initialAgentCallPhase,
    initialAgentCallData: state.reportV2.initialAgentCallData,
  }),
  // Map actions to dispatch and props
  {
    noInitialAgentCallData,
    clearReportV2Phase
  }
)(NoInitialOrFollowUpAgentCallComponent)

export default NoInitialOrFollowUpAgentCallContainer
