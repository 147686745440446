import Rx from 'rxjs/Rx'
import { Record, List} from 'immutable'
import { combineEpics } from 'redux-observable'
import HttpStatus from 'http-status-codes'
import { assign } from 'lodash'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import * as api from './api'

/**
 * Public: Action Types
 */

export const GET_CHAT_HISTORY = 'suburban/chat/GET_CHAT_HISTORY'
export const GET_CHAT_HISTORY_SUCCESS = 'suburban/chat/GET_CHAT_HISTORY_SUCCESS'
export const GET_CHAT_HISTORY_ERROR = 'suburban/chat/GET_CHAT_HISTORY_ERROR'

export const GET_CLIENT_CHAT = 'suburban/agent/GET_CLIENT_CHAT'
export const GET_CLIENT_CHAT_SUCCESS = 'suburban/agent/GET_CLIENT_CHAT_SUCCESS'
export const GET_CLIENT_CHAT_ERROR = 'suburban/agent/GET_CLIENT_CHAT_ERROR'


export const CLEAR_USER_ERROR = 'suburban/chat/CLEAR_USER_ERROR'
export const CLEAR_PHASE = 'suburban/chat/CLEAR_PHASE'

/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  userPhase: INIT,
  chatPhase: INIT,
  chathisotryPhase: INIT,
  clientPhase: INIT,
  clientChatPhase: INIT,
  clientChatData: [],
  chatHistoryData:[],
  error: null,
  isSubmitting: false ,
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case GET_CHAT_HISTORY: {
      return state
        .set('chathisotryPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CHAT_HISTORY_SUCCESS: {
      const { payload } = action
      return state
        .set('chathisotryPhase', SUCCESS)
        .set('chatHistoryData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CHAT_HISTORY_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('chathisotryPhase', ERROR)
    }

    case GET_CLIENT_CHAT: {
      return state
        .set('clientChatPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLIENT_CHAT_SUCCESS: {
      const { payload } = action
      return state
        .set('clientChatPhase', SUCCESS)
        .set('clientChatData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLIENT_CHAT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientChatPhase', ERROR)
    }

    case CLEAR_PHASE: {
        return state
        .set('chatPhase', INIT)
    }
    
    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const getChatHistory = (data) => ({
  type: GET_CHAT_HISTORY,
  payload: data
})

export const getClientChatData = (data) => ({
  type: GET_CLIENT_CHAT,
  payload: data
})

export const clearPhase = () =>{
  return {
      type: CLEAR_PHASE
  }
}

/**
 * Private: Epics
 */

  const getChatHistoryEpic = (action$) =>
  action$
  .ofType(GET_CHAT_HISTORY)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getChatHistory(action.payload))
    .flatMap((payload) => ([{
      type: GET_CHAT_HISTORY_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_CHAT_HISTORY_ERROR,
      payload: { error }
    }))
  })

  const getClientChatDataEpic = (action$) =>
  action$
  .ofType(GET_CLIENT_CHAT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClientChatData(action.payload))
    .flatMap((payload) => ([{
      type: GET_CLIENT_CHAT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_CLIENT_CHAT_ERROR,
      payload: { error }
    }))
  })
  


/**
 * Public: Export Epics
 */

export const chatEpic = combineEpics(
  getChatHistoryEpic,
  getClientChatDataEpic,
)
