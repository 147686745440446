import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import ShowMore from "react-show-more";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Modal from 'react-responsive-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { StickyContainer, Sticky } from 'react-sticky'
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();

const HOSTNAME = process.env.API_HOSTNAME;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button className="form-control custom-select date__picker" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AgentStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment('2019-03-27'),
      // fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      town_list: [],
      suburb_list: [],
      townDropdown: false,
      search: "",
      searchAgent: '',
      open: false,
      currentAgentTowns: [],
      agentName: '',
      userRole: '',
      selectedDefault: 'all',
      sortByKey: '',
      sortByType : 0,
      datePickerModal: false,
      defaultStartDate: moment('2019-03-27'),
      defaultEndDate: moment(),
      modalIsOpen: false,
      suburbDropdown: false,
      suburb_list: []
    };
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      const data = {}
      data.user_id = _user.id
      const agentData = {}
      if(this.state.selectedDefault === "all"){
        agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        // agentData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'total'
      }else{
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
      }
      // agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      // agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.town_arr = this.state.town_list
      agentData.suburbs = this.state.suburb_list
      agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getAgentStatistics(agentData)
      this.props.getFilterParams()
      this.setState({ isLoadingData: true, userRole: _user.role })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agentStatisticsPhase === "success") {
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount(){
    $(document).ready(function() {
      $('tbody').scroll(function(e) { //detect a scroll event on the tbody
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
      });
    });
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  datePickerClick(e){
    // this.setState({ datePickerModal: !this.state.datePickerModal,  })
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, datePickerModal: false, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e) {
          const agentData = {}
          if(this.state.selectedDefault === "all"){
            agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
            agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
            agentData.selectedValue = 'total'
          }else{
            agentData.from = start_from_date
            agentData.to = end_to_date
            agentData.selectedValue = 'any'
          }
          agentData.town_arr = this.state.town_list
          agentData.suburbs = this.state.suburb_list
          agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
          this.props.getAgentStatistics(agentData)
        }.bind(this),300)
    })
  }

  toggledropDown(val) {
    if (val === "filterTowns") {
      this.setState({ townDropdown: !this.state.townDropdown, suburbDropdown: false });
    }
    if(val === 'filterSuburb'){
      this.setState({ suburbDropdown: !this.state.suburbDropdown, townDropdown: false })
    }
  }

  filterStrategist(id, type) {
    let { town_list, suburb_list } = this.state;
    if (type === "townFilter") {
      if (town_list.indexOf(id) === -1) {
        town_list.push(id);
      } else {
        town_list.splice(town_list.indexOf(id), 1);
      }
    }

    if(type === 'suburbFilter'){
      if (suburb_list.indexOf(id) === -1) {
        suburb_list.push(id)
      } 
      else {
        suburb_list.splice(suburb_list.indexOf(id), 1)
      }
    }

    this.setState({ isLoadingData: true, town_list: town_list, suburb_list: suburb_list });
    const agentData = {};
    // agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    // agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    if(this.state.selectedDefault === "all"){
      agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'total'
    }else{
      agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'any'
    }
    agentData.town_arr = town_list
    agentData.suburbs = suburb_list
    agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getAgentStatistics(agentData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  onSearchAgentName(e){
    this.setState({ searchAgent: e.target.value })
  }

  clearAllFilter(e){
    this.setState({ isLoadingData: true, town_list : [], suburb_list: [] })
    setTimeout(
      function(e) {
        const agentData = {}
        if(this.state.selectedDefault === "all"){
          agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'total'
        }else{
          agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'any'
        }
        // agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        // agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.town_arr = this.state.town_list
        agentData.suburbs = this.state.suburb_list
        agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getAgentStatistics(agentData)
      }.bind(this),
      300
    );
  }

  onOpenModal(agent) {
    const towns = agent && agent.townsCovered && agent.townsCovered.indexOf(',') !== -1 ? agent.townsCovered.split(',') : [agent.townsCovered]
    if(towns.length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTowns: towns })
    }
  }
 
  onCloseModal() {
    this.setState({ agentName: '', currentAgentTowns: [], open: false })
  }

  filterDefaultDate(e){
    const agentData = {}
    this.setState({ isLoadingData: true },() => {})
    if(e.target.value === "all"){
      // this.setState({ datePickerModal: false })
      this.setState({ modalIsOpen:false })
      agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'total'
    }else{
      agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'any'
    }
    agentData.town_arr = this.state.town_list
    agentData.suburbs = this.state.suburb_list
    agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getAgentStatistics(agentData)
    this.setState({ selectedDefault: e.target.value },() => {})
  }

  sortData(val){
    let sortByType = this.state.sortByType
    if(val !== this.state.sortByKey ){
      sortByType = 0
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType}, ()=>{
      const agentData = {}
      if(this.state.selectedDefault === "all"){
        agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'total'
      }else{
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
      }
      agentData.town_arr = this.state.town_list
      agentData.suburbs = this.state.suburb_list
      agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getAgentStatistics(agentData)
    })
  }


  render() {
    const { open } = this.state;
    const { agentStatisticsData, filterParams } = this.props;
    let allData = filterParams && filterParams && filterParams.data;
    let filterList = agentStatisticsData;
    let allTownData = filterParams && filterParams.data && filterParams.data.towns;

    if(this.state.suburb_list && this.state.suburb_list.length > 0){
      let townResult = allTownData.filter(o1 => this.state.suburb_list.some(o2 => o1.suburb_id === o2));
      if(townResult){
        filterList = townResult
        .filter(
          (townResult) =>{
            return (
              (townResult.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
            );
          }
        );
      }
    }else{
      if(allTownData){
        filterList = allTownData
        .filter(
          (allTownData) =>{
            return (
              (allTownData.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
            );
          }
        );
      }
    }

    let filterSuburbsList = []
    let allSuburbs = filterParams && filterParams.data && filterParams.data.suburbs;
    if(allSuburbs){
      filterSuburbsList = allSuburbs
      .filter(
        (allSuburbs) =>{
          return (
            (allSuburbs.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    let agentData = []
    let agentStatisticsDataBySearch = agentStatisticsData
    if(agentStatisticsDataBySearch){
      agentData = agentStatisticsDataBySearch.filter(
        (data) =>{
          return (
            (data.name.toLowerCase().indexOf(this.state.searchAgent.toLowerCase())) !== -1 
          );
        }
      );
    }

    if(this.state.open){
      document.body.style.overflowX = 'visible !important'
    }

    return (
      <div>

        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Agent Statistics</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50  remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{agentStatisticsData && agentStatisticsData.length}</span> Agents
              </h4>
            </div>
            <div className="col-md-12">
            <div className="reports__flex">
              <div className="dp-wrapper dp__wrapper--hafl">
                <h5 
                  onClick={this.clearAllFilter.bind(this)}
                  className="clear-filter clear-filter--label m-0">
                   {(this.state.town_list && this.state.town_list.length>0) || (this.state.suburb_list && this.state.suburb_list.length > 0) ? "Clear All Filters": ''}
                </h5>

                <div className="for__suburbs--filter">
                {
                  (this.state.userRole !=='agent') &&
                  <div 
                    className="strategist-search strategist-search-float"
                    onClick={this.toggledropDown.bind(this, 'filterSuburb')}>
                    <input 
                      onChange={(e)=> this.onSearch(e)}
                      type="text"  
                      className="report__search--global width_300"
                      placeholder="Search Suburbs Name"/>
                  </div>
                }
                { 
                (this.state.userRole !=='agent') &&
                  this.state.suburbDropdown && 
                  <div id="dropdownBtn" className= "sel-outer selouter__not--touch">
                    {filterSuburbsList && filterSuburbsList.map((sub, subIndex) => {
                      let selectedIndex = false;
                        _.find(this.state.suburb_list, function(o) {
                          if(o === sub.id){
                            selectedIndex = true;
                          } 
                      });
                      return (
                        <li key={subIndex} className="custom-select-option">
                          <span className="coverage__checkbox">
                            <label className="custom-checkbox">
                            {sub.name}
                            <input 
                              type="checkbox" 
                              checked = {selectedIndex} 
                              className="custom-select-option-checkbox" 
                              onClick={this.filterStrategist.bind(this,sub.id, 'suburbFilter')}/>
                              <span className="checkmark"></span>
                            </label>
                          </span>
                        </li>
                      )
                    })}
                  </div>
                }
                </div>

                <div className="for__town--filter">
                {this.state.userRole !== 'agent' &&
                  <div className="strategist-search strategist-search-float" onClick={this.toggledropDown.bind(this, 'filterTowns')}>
                    <input 
                      onChange={(e)=> this.onSearch(e)}
                      type="text"  
                      className="report__search--global"
                      placeholder="Search Town Name"/>
                  </div>
                }
                {this.state.userRole !== 'agent' && this.state.townDropdown &&
                    <div id="dropdownBtn" className= "sel-outer selouter__agent--statatics">
                      {filterList && filterList.map((town, townIndex) => {
                       let selectedIndex = false;
                        _.find(this.state.town_list, function(o) {
                          if(o === town.id){
                            selectedIndex = true;
                          } 
                       });
                       return (
                        <li key={townIndex} className="custom-select-option">
                          <span className="coverage__checkbox">
                            <label className="custom-checkbox">
                            {town.name}
                            <input 
                              type="checkbox" 
                              checked = {selectedIndex} 
                              className="custom-select-option-checkbox" 
                              onClick={this.filterStrategist.bind(this,town.id, 'townFilter')}/>
                              <span className="checkmark"></span>
                            </label>
                          </span>
                        </li>
                       )
                      })}
                    </div>
                  }
                </div>
              </div>
              
              <div className="right__cont right__cont--agent fullwidth__agentrepo fulagent">

              <div className="all__custom--wrapper">
                <select 
                  className="date__picker"
                  name="selectedDefault"
                  value = {this.state.selectedDefault} 
                  onChange={this.filterDefaultDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {
                (this.state.selectedDefault !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                      <Modal2
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}           
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">             
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>               
                          </div>
                        </div>
                      </Modal2>
                    </div>
                  }
                </div>
              }

              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={agentData} name="Agent_Report">
                  <ExcelColumn label="Agent Name" value="name" />
                  <ExcelColumn label="Towns Covered" value="townsCovered" />
                  <ExcelColumn label="Total Clients Received" value="totalClientReceived" />
                  <ExcelColumn label="Total Closed" value="totalClosed" />
                  <ExcelColumn label="Total Pending" value="totalPending" />
                  <ExcelColumn label="Total Closed + Pending" value="totalClosedPending" />
                  <ExcelColumn label="Total Won/Total Received" value="totalWonTotalReceivedPercentage" />
                  <ExcelColumn label="Total Closed-2020" value="totalClosed20" />
                  <ExcelColumn label="Total Closed-2019" value="totalClosed19" />
                  <ExcelColumn label="Total Closed-2018" value="totalClosed18" />
                  <ExcelColumn label="Total Closed-2017" value="totalClosed17" />
                  <ExcelColumn label="Total Actively Engaged" value="totalActivelyEngaged" />
                  <ExcelColumn label="% Actively Engaged" value="totalActivelyEngagedPercentage" />
                  <ExcelColumn label="Total Somewhat  Engaged" value="totalSomewhatEngaged" />
                  <ExcelColumn label="% somewhat Engaged" value="totalSomewhatEngagedPercentage" />
                  <ExcelColumn label="Total Unresponsive" value="totalUnresponsive" />
                  <ExcelColumn label="% Unresponsive" value="totalUnresponsivePercentage" />
                  <ExcelColumn label="total No status selected" value="totalNoStatusSelected" />
                  <ExcelColumn label="% no status selected" value="totalNoStatusSelectedPercentage" />
                  <ExcelColumn label="Total NO tour" value="totalNoTour" />
                  <ExcelColumn label="% No Tour" value="totalNoTourPercentage" />
                </ExcelSheet>
              </ExcelFile>
              
            </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          


          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">

                     

                    
                    {/* <div className="pane pane--table2">
                      </div> */}
                      <div className="list_table useslist__extra">


                      <StickyContainer>
                        <table className="table table-striped">
                        <Sticky topOffset={50}>
                          {({
                            style,
                            isSticky,
                            wasSticky,
                            distanceFromTop,
                            distanceFromBottom,
                            calculatedHeight
                          }) => (
                            <header className="updiv" style={style}>
           
                               <span className="typeahead searchbox__agent--input">
                                <input 
                                  className="costom__search--report typeahead-input reportsearchs reportsearchsminus add" 
                                  onChange={this.onSearchAgentName.bind(this)}
                                  type="text" 
                                  name="search" 
                                  placeholder="Search Users"/>
                              </span>
          
                              <thead>
                                <tr>
                                  <th className="for__sort for__static" onClick={this.sortData.bind(this , 'agent_name')}>Agent Name</th>
                                  <th className="text-left for__sort">Towns Covered</th>
                                  <th className="text-left for__sort" onClick={this.sortData.bind(this , 'total_clients_received')}>Total Clients Received</th>
                                  <th className="text-left for__sort" onClick={this.sortData.bind(this , 'total_closed')}>Total Closed</th>
                                   <th className="text-left for__sort" onClick={this.sortData.bind(this , 'total_pending')}>Total Pending</th>
                                   <th onClick={this.sortData.bind(this , 'total_closed_pending')} className="text-left for__sort">Total Closed + Pending</th>
                                  <th onClick={this.sortData.bind(this , 'total_won')} className="text-left for__sort">Total Won/Total Received</th>
                                  <th onClick={this.sortData.bind(this , 'total_closed_2020')} className="text-left for__sort">Total Closed-2020</th>
                                  <th onClick={this.sortData.bind(this , 'total_closed_2019')} className="text-left for__sort">Total Closed-2019</th>
                                  <th onClick={this.sortData.bind(this , 'total_closed_2018')} className="text-left for__sort">Total Closed-2018</th>
                                  <th onClick={this.sortData.bind(this , 'total_closed_2017')} className="text-left for__sort">Total Closed-2017</th>
                                  <th className="text-left">Total Actively Engaged</th>
                                  <th className="text-left">% Actively Engaged</th>
                                  <th className="text-left">Total Moderately Engaged</th>
                                  <th className="text-left">% Moderately Engaged</th>
                                  <th className="text-left">Total Unresponsive</th>
                                  <th className="text-left">% Unresponsive</th>
                                  <th className="text-left">total No status selected</th>
                                  <th className="text-left">% no status selected</th>
                                  <th onClick={this.sortData.bind(this , 'total_no_tour')} className="text-left for__sort">Total NO tour</th>
                                  <th className="text-left">% No Tour</th>
                                </tr>
                              </thead>
                            </header>
                          )}
                        </Sticky>
                        {/* ... */}
                        <tbody>
                          { this.state.isLoadingData === true ? (
                            <tr className="loader__center">
                                <img src="../../img/loader2.svg" />
                                <h5>Your Agent report is generating, please wait.........</h5>
                            </tr>
                            ) : (
                            agentData &&
                                agentData.map((agent, index) => {
                                  const towns = agent && agent.townsCovered.indexOf(',') !== -1 ? agent.townsCovered.split(',') : [agent.townsCovered]
                                  let townNo = (towns.length-1) <= 0 ? '' : " (+"+(towns.length-1)+")"
                                  return (
                                    <tr key={index} className="forcursor">
                                      <td className="testing">{agent.name}</td>
                                      <td className="wrap-text text-left"><div className="link" onClick={this.onOpenModal.bind(this, agent)}>{(towns.length >= 1 ? towns[0]+townNo : 'No Town')}</div></td>
                                      <td className="text-left">{agent.totalClientReceived}</td>
                                      <td className="text-left">{agent.totalClosed}</td>
                                      <td className="text-left">{agent.totalPending}</td>
                                      <td className="text-left">{agent.totalClosedPending}</td>
                                      <td className="text-left">{agent.totalWonTotalReceivedPercentage}%</td>
                                      <td className="text-left">{agent.totalClosed20}</td>
                                      <td className="text-left">{agent.totalClosed19}</td>
                                      <td className="text-left">{agent.totalClosed18}</td>
                                      <td className="text-left">{agent.totalClosed17}</td>
                                      <td className="text-left">{agent.totalActivelyEngaged}</td>
                                      <td className="text-left">{agent.totalActivelyEngagedPercentage}</td>
                                      <td className="text-left">{agent.totalSomewhatEngaged}</td>
                                      <td className="text-left">{agent.totalSomewhatEngagedPercentage}</td>
                                      <td className="text-left">{agent.totalUnresponsive}</td>
                                      <td className="text-left">{agent.totalUnresponsivePercentage}</td>
                                      <td className="text-left">{agent.totalNoStatusSelected}</td>
                                      <td className="text-left">{agent.totalNoStatusSelectedPercentage}</td>
                                      <td className="text-left">{agent.totalNoTour}</td>
                                      <td className="text-left">{agent.totalNoTourPercentage}</td>
                                    </tr>
                                  );
                                })
                            )}
                          </tbody>
                        </table>
                      </StickyContainer>
                      </div>

              </main>
              <div>
                <Modal2 isOpen={open} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' town(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTowns && this.state.currentAgentTowns.map((town, index) => {
                        return <div className="modal-contents modalcontent-marg allpoppadding__paranew" key={'town-name-'+index}>{town}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              {
                filterList && filterList.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center mar__top--zero"><h4>Suburban Jungler -Agent Statistics</h4></div>*/
                :
                 <div className="dashboard-footer text-center mar__top--zero">Data Not Found</div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "agentstatistics", // a unique identifier for this form
  destroyOnUnmount: true
})(AgentStatistics);
