import { connect } from 'react-redux'

import SignupComponent from './component'
import { getFilterParams, clearPhase, getTowns } from '../../../../store/dashboard/duck'
import { sumbitAgentForm, getAgentOnBoardData, clearAgentPhase, clearAgentSubmitPhase } from '../../../../store/agentV2/duck'

const SignupContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.dashboard.filterParams,
    filterParamsPhase: state.dashboard.filterParamsPhase,
    townPhase: state.dashboard.townPhase,
    townData: state.dashboard.townData,
    submitAgentFormPhase: state.agentV2.submitAgentFormPhase,
    submitAgentFormData: state.agentV2.submitAgentFormData,
    onBoardingPhase: state.agentV2.onBoardingPhase,
    onBoardingData: state.agentV2.onBoardingData,
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    sumbitAgentForm,
    getAgentOnBoardData,
    clearAgentPhase,
    clearPhase, 
    clearAgentSubmitPhase,
    getTowns
  }
)(SignupComponent)

export default SignupContainer
