import { connect } from 'react-redux'

import InternalNotesComponent from './component'

import {
  getInteralNotes,
  createInteralNote,
  editInteralNote,
  deleteInteralNote,
  clearInteralNotePhase,
} from '../../../../../../store/strategistV2/notes/actions'

const InternalNotesContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    notes: state.strategistV2.note.notes,
    clientDetail: state.strategistV2.client.clientDetail,
    fetchInteralNotePhase: state.strategistV2.note.fetchInteralNotePhase,
    createInteralNotePhase: state.strategistV2.note.createInteralNotePhase,
    deleteInteralNotePhase: state.strategistV2.note.deleteInteralNotePhase,
    editInteralNotePhase: state.strategistV2.note.editInteralNotePhase,
  }),
  // Map actions to dispatch and props
  {
    createInteralNote,
    editInteralNote,
    getInteralNotes,
    deleteInteralNote,
    clearInteralNotePhase,
  }
)(InternalNotesComponent)

export default InternalNotesContainer
