import { connect } from 'react-redux'

import ArtificialIntelligenceComponent from './component'
const ArtificialIntelligenceContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(ArtificialIntelligenceComponent)

export default ArtificialIntelligenceContainer
