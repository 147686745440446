import { connect } from 'react-redux'

import StrategistStatisticsComponent from './component'
import { getFilterParams } from '../../../../../../store/strategistV2/dashboard/actions'
import { getStrategistStatistics, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const StrategistStatisticsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    strategistStatPhase: state.reportV2.strategistStatPhase,
    strategistStatData: state.reportV2.strategistStatData
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    getStrategistStatistics,
    clearReportV2Phase
  }
)(StrategistStatisticsComponent)

export default StrategistStatisticsContainer
