/* eslint-disable camelcase */
import React, { Fragment, Component } from 'react'
import Cookies from 'universal-cookie'
import moment from 'moment-timezone'
import { get, size, findIndex } from 'lodash'
import { SUCCESS } from '../../../../../constants/phase'

const cookies = new Cookies()

class Timer extends Component {
  timerId = 0
  constructor(props) {
    super(props)
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
  componentDidMount() {
    const user = cookies.get('user')
    if (size(user)) {
      if (user.role === 'admin' || user.role === 'strategist') {
        this.strategistTimeActivity = localStorage.getItem('strategistTime')
        if (!this.strategistTimeActivity || this.strategistTimeActivity === 'null') {
          let obj = {
            strategist_id: user.id,
            client_id: this.props.match.params.id && this.props.match.params.id,
            start_date: new Date(),
            start_time: new Date(),
            activity_log: 'client_dashboard',
          }
          localStorage.setItem('strategistTime', Date.now())
          this.strategistTimeActivity = Date.now()
          this.props.addClientStrategistTimeSheet(obj)
        }
        if (this.strategistTimeActivity && this.strategistTimeActivity !== 'null') {
          this.startTimerInterval()
        }
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.addClientStrategistTimeSheetPhase === SUCCESS) {
      if (props.clientStrategistTimeSheet) {
        localStorage.setItem('strategistTimeId', props.clientStrategistTimeSheet[0]) //.data[0]
      } else {
        localStorage.setItem('strategistTime', 'null')
        this.strategistTimeActivity = 'null'
      }
      props.clearClientDetailPhase()
    }
    return state
  }
  componentWillUnmount() {
    clearInterval(this.timerId)
    const user = cookies.get('user')
    if (size(user)) {
      if (user.role === 'admin' || user.role === 'strategist') {
        const strategistTimeId = localStorage.getItem('strategistTimeId')
        const currentpath = window && window.location.pathname
        const current_client_id = this.props.match.params.id && this.props.match.params.id
        const clientPath1 = [
          '/strategist/edit-information/',
          '/strategist/ai/',
          '/strategist/town-details/',
          '/strategist/deal',
          '/strategist/emails/',
        ]
        let findIdx1 = findIndex(clientPath1, (el) => el + current_client_id === currentpath)
        const findIdx2 = findIndex(clientPath1, (el) => el === currentpath)
        if (findIdx2 > 0 && findIdx1 < 0) {
          findIdx1 = findIdx2
        }
        if (strategistTimeId && strategistTimeId !== 'null' && findIdx1 < 0) {
          const obj = {
            id: strategistTimeId,
            strategist_id: user.id,
            client_id: this.state.client_ID,
            end_date: new Date(),
            end_time: new Date(),
            status: 1,
          }
          this.props.addClientStrategistTimeSheet(obj)
          localStorage.setItem('strategistTime', 'null')
          localStorage.setItem('strategistTimeId', null)
        }
      }
    }
  }
  startTimerInterval = () => {
    this.timerId = setInterval(() => {
      const user = cookies.get('user')
      let a = new Date()
      let b = new Date(parseInt(this.strategistTimeActivity))
      a = moment.tz(a, user.time_zone)
      b = moment.tz(b, user.time_zone)
      const diff = Math.abs(a - b) / 1000
      // get hours
      const hours = Math.floor(diff / 3600) % 24
      // get minutes
      let minutes = Math.floor(diff / 60) % 60
      minutes = parseInt(minutes) < 10 ? `0${parseInt(minutes)}` : parseInt(minutes)
      // get seconds
      let seconds = diff % 60
      seconds = parseInt(seconds) < 10 ? `0${parseInt(seconds)}` : parseInt(seconds)
      this.setState({ hours: parseInt(hours), minutes, seconds })
      if (parseInt(minutes) === 10) {
        this.stopTime('')
      }
    }, 1000)
  }
  startTime = () => {
    const user = cookies.get('user')
    const obj = {
      strategist_id: user.id,
      client_id: this.props.match.params.id && this.props.match.params.id,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: 'client_dashboard',
    }
    localStorage.setItem('strategistTime', Date.now())
    this.strategistTimeActivity = Date.now()
    this.setState({ hours: 0, minutes: 0, seconds: 0 })
    this.startTimerInterval()
    this.forceUpdate()
    this.props.addClientStrategistTimeSheet(obj)
  }
  stopTime = () => {
    clearInterval(this.timerId)
    const strategistTimeId = localStorage.getItem('strategistTimeId')
    const user = cookies.get('user')
    const obj = {
      id: strategistTimeId,
      strategist_id: user.id,
      client_id: this.props.match.params.id && this.props.match.params.id,
      end_date: new Date(),
      end_time: new Date(),
      status: 1,
    }
    this.props.addClientStrategistTimeSheet(obj)
    localStorage.setItem('strategistTime', 'null')
    localStorage.setItem('strategistTimeId', null)
    this.strategistTimeActivity = 'null'
    this.forceUpdate()
  }
  render() {
    const user = get(this.props, 'user', {})
    const { hours, minutes, seconds } = this.state
    return (
      <Fragment>
        {user &&
          user.role &&
          (user.role === 'admin' || user.role === 'strategist') &&
          this.strategistTimeActivity === 'null' && (
            <button
              className="btn btn__btn btn__start__time"
              onClick={this.startTime}
            >
              <img src="img/playbutton.svg" />
              Start
            </button>
          )}
        {user &&
          user.role &&
          (user.role === 'admin' || user.role === 'strategist') &&
          this.strategistTimeActivity !== 'null' && (
            <button
              className="btn btn__btn btn__stop__time"
              onClick={this.stopTime}
            >
              <img src="img/stopbutton.svg" /> Stop ({hours}:{minutes}:{seconds})
            </button>
          )}
      </Fragment>
    )
  }
}
export default Timer
