import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import { map } from 'lodash'
import Config from '../../../../../../config';
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}

export default class NotTouchedByAgentIn4WeeksComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      notTouchedIn4WeekTableData: [],
      suburb_list: [],
      sortByKey:'name',
      sortByType: 0,
      commonArrayData: [],
      commonSubject: '',
      commonValue: '',
      commonAgentName: '',
      open: false
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get("user")
    if (_user) {
      const data = {
        suburb_arr: [],
        sortBy: {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getNotTouchedIn4Weeks(data)
      this.props.getFilterParams()
    }
  }

  componentDidUpdate(preProps, preState){
    const { notTouchedIn4weeksPhase, notTouchedIn4weeksData, clearReportV2Phase } = this.props
    if(notTouchedIn4weeksPhase === "success"){
      clearReportV2Phase()
      let notTouchArr = []
      const notTouchedData = notTouchedIn4weeksData && notTouchedIn4weeksData.data
      if(_.size(notTouchedData) > 0){
        notTouchedData && notTouchedData.map((data, i)=>{
          notTouchArr.push({
            key: i,
            name: data.name,
            totalClientLength: data.totalClient && data.totalClient.length,
            totalAssignClients: data.totalAssignClients,
            percentage: data.percentage,
            totalClient: data.totalClient,
            totalStrategist: data.totalStrategist,
            totalTag: data.totalTag,
             
            export_clients: data.totalClient.length > 0 ? data.totalClient.map(e => { return e.client_name}).join(" ,") : "",
            export_totalTag: data.totalTag.length > 0 ? data.totalTag.map(e => { return e }).join(" ,") : "",
            export_totalStrategist: data.totalStrategist.length > 0 ? data.totalStrategist.map(e => { return e.strategist_name}).join(" ,") : ""
           
          })
        })
      }
      this.setState({ notTouchedIn4WeekTableData: notTouchArr, isLoading: false })
    }
    if(notTouchedIn4weeksPhase === "error"){
      clearReportV2Phase()
      this.setState({ notTouchedIn4WeekTableData: [], isLoading: false })
    }
  }

  _onOpenModal(agent, val){
    let commonArr = []
    let sub = ''
    if(val === "clientName"){
      commonArr = agent && agent.totalClient
      sub = 'client'
    }
    if(val === "tagName"){
      const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
      commonArr = tags[0]
      sub = 'tag'
    }
    if(val === "strategistName"){
      commonArr = agent && agent.totalStrategist
      sub = 'strategist'
    }

    if(_.size(commonArr) > 1){
      this.setState({ commonAgentName: agent.name, open: true, commonArrayData: commonArr, commonSubject: sub, commonValue: val })
    }
  }

  _closeCommonModal(){
    this.setState({ commonAgentName: '', open: false, commonArrayData: [], commonSubject: '', commonValue: '' })
  }

  handleSuburbsOption = (options, event) => {
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ suburb_list: selectedOptions, isLoading: true })
      const data = {
        suburb_arr: selectedOptions,
        sortBy: {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getNotTouchedIn4Weeks(data)
    }
  }

  changeLimit = limit => {
    // On limit change
  }
  
  render() {
    const { notTouchedIn4WeekTableData } = this.state
    const { filterParams } = this.props

    let suburbsData = filterParams.data && filterParams.data.suburbs
    const suburbs = map(suburbsData, (suburb, index) => {
      return { label: suburb.name, value: suburb.id }
    });

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back">
                      <BackArrowIcon className="arrow-icon"/>Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Not Touched by Agent in 4 Weeks Statistics</h2>
                      <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                        <ExcelSheet data={notTouchedIn4WeekTableData} name="Not_Touch_In_Four_Week_Report">
                          <ExcelColumn label="Agent Name" value="name" />
                          <ExcelColumn label="Total Clients Not Touched by Agent in 4 Weeks" value="totalClientLength" />
                          <ExcelColumn label="Total Clients Assigned" value="totalAssignClients" />
                          <ExcelColumn label="% Of Clients Not Touched by Agent in 4 Weeks" value="percentage" />
                          <ExcelColumn label="Client Name" value="export_clients" />
                          <ExcelColumn label="Tag" value="export_totalTag" />
                          <ExcelColumn label="Strategist Name" value="export_totalStrategist" />
                        </ExcelSheet>
                      </ExcelFile>
                  </div>
                </div>
              </div>

              {
                _user && (_user.role !== "strategist") &&
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group material-textfield">
                      <div className="multiselect__checkboxes__field">
                        <ReactMultiSelectCheckboxes
                          styles={multiSelectStyles}
                          width="100%"
                          name="suburb_list"
                          placeholderButtonLabel="Select"
                          options={suburbs}
                          onChange={(event) => this.handleSuburbsOption(event, 'suburb_list')}
                        />
                        <label className="multiselect__checkboxes__field__label">Suburbs</label>
                      </div>
                    </div> 
                  </div>
                </div>
              }

              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(notTouchedIn4WeekTableData) > 0) ? 
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          columns={[
                            {
                              title: 'Agent Name',
                              width: 250, 
                              dataIndex: 'name',
                              key: '1',
                              fixed: 'left',
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                              title: 'Total Clients Not Touched by Agent in 4 Weeks',
                              width: 430, 
                              dataIndex: 'totalClientLength',
                              key: '2',
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClientLength - b.totalClientLength,
                            },
                            {
                              title: 'Total Clients Assigned',
                              dataIndex: 'totalAssignClients',
                              key: '3', 
                              width: 280,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalAssignClients - b.totalAssignClients,
                            },
                            {
                              title: '% Of Clients Not Touched by Agent in 4 Weeks',
                              dataIndex: 'percentage',
                              key: '4', 
                              width: 430,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.percentage.localeCompare(b.percentage)
                            },
                            {
                              title: 'Client Name',
                              dataIndex: 'clientName',
                              key: '5', 
                              width: 280,
                              render: (text, str) => {
                                const totalClient = str && str.totalClient
                                let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                                return(
                                  <div className={(totalClient.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, str, "clientName")}>{( totalClient.length >= 1 ? totalClient[0].client_name+ clientNo : 'No Client' ) }</div>
                                )
                              }
                            },
                            {
                              title: 'Tag',
                              dataIndex: 'tag',
                              key: '6', 
                              width: 320,
                              render: (text, str) => {
                                const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                                const tagArr = tags[0]
                                let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                                return(
                                  <div className={(tagArr.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, str, "tagName")}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div>
                                )
                              }
                            },
                            {
                              title: 'Strategist Name',
                              dataIndex: 'strategistName',
                              key: '7', 
                              width: 280,
                              render: (text, str) => {
                                const totalStrategist = str && str.totalStrategist
                                let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                                return(
                                  <div className={(totalStrategist.length > 1 ? "link cursor__pointer" : '')} onClick={this._onOpenModal.bind(this, str, "strategistName")}>{(totalStrategist.length >= 1 ?totalStrategist[0].strategist_name+ strategistNo : 'No Strategist') }</div>
                                )
                              }
                            }
                          ]}
                          dataSource={notTouchedIn4WeekTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
             </Spin>
          </div>
        </main>
         
        <ReactModal
          isOpen={this.state.open}
          contentLabel="Report"
          portalClassName="react-modal"
          >
            <div className="react-modal-dialog react-modal-dialog-centered">
              <div className="react-modal-header">
                <h5 className="react-modal-title">
                {`Agent - ${this.state.commonAgentName}'s ${this.state.commonSubject}(s)`}
                </h5>
                <button
                  type="button"
                  onClick={this._closeCommonModal.bind(this)}
                  className="btn react-modal-close">
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="info__block">
                {
                  (this.state.commonValue === "clientName") &&
                  this.state.commonArrayData && this.state.commonArrayData.map((client, index) => {
                    return <p key={'client-name-'+index}>{client.client_name}</p>
                  })
                }
                {
                  (this.state.commonValue === "tagName") &&
                  this.state.commonArrayData && this.state.commonArrayData.map((tag, index) => {
                    return <p key={'tag-name-'+index}>{tag}</p>
                  })
                }
                {
                  (this.state.commonValue === "strategistName") &&
                  this.state.commonArrayData && this.state.commonArrayData.map((strategist, index) => {
                    return <p key={'strategist-name-'+index}>{strategist.strategist_name} - Clients {strategist.clients}</p>
                  })
                }
                </div>
                <div>
                </div>
              </div>
            </div>
          </ReactModal>

        </div>
      </div>
    )
  }
}

