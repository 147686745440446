import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import _, { get } from 'lodash'
import $ from "jquery";
import { Field, reduxForm } from 'redux-form'
import Select from 'react-select'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import NumberFormat from 'react-number-format';
import axios from 'axios'
import Cookies from 'universal-cookie'
// Importing Alert box module
import { confirmAlert } from 'react-confirm-alert'; // Import core
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const API_HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
import { logoutInvalidRequest } from '../../utils/helper'
import {fetch} from "../../utils";

let _user = ''

class AddJunglerUserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id:'',
      email: "",
      firm_name: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      first_name: '',
      last_name: '',
      timezone: '',
      role: '',
      description: '',
      mobile_phone: '',
      selectedOption: '',
      email: '',
      conference_phone: '',
      townOptions: [],
      password: '',
      checked: [],
      file: null,
      avatar:'',
      confirmPass: '',
      addUserMsg: "",
      editSecondMsg: "",
      editUserMsg:"",
      passwordMsg:"",
      showPriceRange:true,
      msgDeleteUser:'',
      strategyToggle: false,
      err: {},
      err2: {},
      isSchedulingOn: '',
      currentUser: '',
      calendar_id:'',
      designation:'',
      is_limit:false,
      country_code:'+1',
      strategyPermissionToggle:false,
      strategistOpt: [],
      strategyPermissionStatus: false,
      strategyPermissionMsg:''
    }
    // this.checked = []
    this.towns = []
    this.handleChange = this.handleChange.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.townChk = this.townChk.bind(this)
    this.strategistToggleBtn = this.strategistToggleBtn.bind(this)
    this.strategistPermissionBtn = this.strategistPermissionBtn.bind(this)
    this.limitClientToggleBtn = this.limitClientToggleBtn.bind(this)
  }
  componentWillMount(){
    this.state.checked = []
    window.scrollTo(0,0)
    _user = cookies.get('user')
   this.props.getFilterParams()
   const {location} = this.props;
   let _usrID = location && location.state && location.state.id && location.state.id
    if(_usrID && _usrID !==''){
      let usrdata = {}
      usrdata.user_id = _usrID;
      this.props.getUSerProfileByID(usrdata)
    }
    this.setState({currentUser: _user})
  }

  componentDidMount(){
    let { location } = this.props;
    let _user_id = location && location.state && location.state.id && location.state.id
    if(_user_id && _user_id !=''){
      document.title = "Jungler: Edit Jungler User";
    }
    else{
      document.title = "Jungler: Create Jungler User";
    }
  }

  componentWillUnmount(){
    this.state.checked = []
    document.title = "Suburban Jungle";
  }
  
  componentWillReceiveProps(nextProps) {
    let data1 = {}
    let self = this
    const { checked, strategistOpt, strategyPermissionToggle } = this.state
    const {getTowns} = this.props
    if(nextProps && nextProps.deleteUserdata && nextProps.deleteUserdata.status ===true){
      this.setState({'msgDeleteUser': nextProps.deleteUserdata.message})
      this.props.clearPhase();
      setTimeout(function(){
        window.history.back()
      }, 800)
    }

    if (this.props.junglerUserPhase === 'success') {
      nextProps.userData && nextProps.userData.user && nextProps.userData.user.suburbs && nextProps.userData.user.suburbs.length > 0 &&  nextProps.userData.user.suburbs.forEach(function(val, indx){
        if(checked.indexOf(val.id) === -1) {
          checked.push(val.id)
        }
      })

      nextProps.userData && nextProps.userData.user && nextProps.userData.user.impersonate_strategist && nextProps.userData.user.impersonate_strategist.length > 0 &&  nextProps.userData.user.impersonate_strategist.forEach(function(val, indx){
        if(strategistOpt.indexOf(val.id) === -1) {
          strategistOpt.push(val.id)
        }
      })


      const {townOptions} = this.state
      nextProps.userData && nextProps.userData.user && nextProps.userData.user.towns && nextProps.userData.user.towns.length > 0 && nextProps.userData.user.towns.forEach(function(townVal, townIndex){
        if(townOptions.indexOf(townVal.id) === -1) {
          townOptions.push(townVal.id)
        }
      })
      this.townChk()
      this.props.clearPhase()
    } 

    
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }

    if(nextProps.junglerUserPhase === "success") {
      const { userData } = nextProps
      let conferencePhoneNo = ''
      if(userData && userData.user && userData.user.phone_work){
        let conf_mo_no = userData.user.phone_work
        conferencePhoneNo = (conf_mo_no.replace(/\D/g, '').length == 10) ? this.formatNumber(conf_mo_no) : conf_mo_no
      }
      this.setState({
        user_id: userData.user.id,
        first_name: userData.user.first_name,
        last_name: userData.user.last_name,
        timezone: userData.user.time_zone,
        role: userData.user.role,
        description: userData.user.description,
        mobile_phone: userData.user.phone_mobile,
        email: userData.user.email,
        firm_name:userData.user.firm_name,
        price_min: (userData.user.price_min != "" ? userData.user.price_min : "0"),
        price_max: (userData.user.price_max != "" ? userData.user.price_max : "25000000"),
        avatar: userData.user.avatar,
        strategyToggle: userData.user.is_schedule_on,
        calendar_id:userData.user.calendar_id,
        conference_phone: conferencePhoneNo,
        designation: userData.user.designation,
        is_limit : userData.user.is_limit === 0 ? true : false,
        country_code: userData.user.country_code,
        strategyPermissionToggle: userData.user.impersonate_strategist && userData.user.impersonate_strategist.length > 0 ? true : false,
        strategyPermissionStatus: userData.user.impersonate_strategist && userData.user.impersonate_strategist.length > 0 ? true : false
      })
      this.props.clearPhase()
    }
    if(nextProps.addUserPhase === "success"){
      if(nextProps.addUserMessage === "User profile updated successfully"){
        this.setState({ 
            addUserMsg: "User Add Successfully....", 
            editUserMsg:"Updated Sucessfully...."})
        setTimeout(function() {
          window.history.back()
        },1500)

      }else{
        this.setState({ 
            addSecondMsg: "User Add Successfully....", 
            editSecondMsg:"Updated Sucessfully...."})
        setTimeout(function() {
          window.history.back()
        },1500)
      }
      this.props.clearPhase()
    }
    if(nextProps.addUserPhase === "error"){
      this.setState({ 
        addUserMsg: "Error..",
        editUserMsg: "Error in update profile",
        passwordMsg: "Error in password"})
    }
    if(nextProps.passwordPhase === "success"){
      this.setState({ passwordMsg: "Change Password Sucessfully...."})
      this.props.clearPhaseUser()
    }
    if(nextProps.impersonatePhase === "success"){
      if(nextProps.impersonateData && nextProps.impersonateData.magic_link){
        let i_user = cookies.get('user')
        let i_Authorization = cookies.get('Authorization')
        let expires = new Date()
        localStorage.clear();
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth()+2);
        expires = new Date(expires)
        cookies.set('i_Authorization',i_Authorization, { path: '/', expires });
        cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires });
        let self = this;
        setTimeout(function() {
          let magic_link = nextProps.impersonateData.magic_link;
          let pathname = "client-login/token?validate="+magic_link
          self.props.clearPhase()
          document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          self.props.history.push({'pathname' : pathname })
        }, 1500);
      }else{
        alert('Not have enough access')
      }
    }
  }

  DeletedUser = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding">

            <div className="flexdiv">
                <h4 className="popupheader__heading">Are you sure?</h4>
                <button
                  onClick={onClose}
                  type="button"
                  id="close_user_modal"
                  className="close_img close_img--white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                <img src="../../img/close_btn.svg"/>
                </button>
              </div>
              <p className="allpoppadding__para marg25">You want to delete this user?</p>
           
              <button className="btns__fullwidth" onClick={() => {
                this.handleClickDelete();
                onClose();
              }}>Yes, Delete it!</button>
            {this.state.msgDeleteUser ? (
              <div className="text-center alert alert-success">
                {this.state.msgDeleteUser}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    });
  };

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleClickDelete(){
    let { location } = this.props;
    const d_userdata = {}
    let _user_id = location && location.state && location.state.id && location.state.id
    if(_user_id && _user_id !=''){
      d_userdata.client_id = _user_id;
      this.props.deleteClient(d_userdata)
    }
  }

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL
    // let files = $("#profileImage")[0].files[0]
    let files  = e.target.files[0]
    if(files){
      var img = new Image()
      let self=this
      const reader = new FileReader()
      reader.onloadend = () => {
        img.src = _URL.createObjectURL(files)
        img.onload = function() {
            self.setState({file: files})
            self.setState({avatar: reader.result})
        }
      }
      var read = reader.readAsDataURL(files) 
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleRequestClose() {
    this.setState({
      openSnackbar: false,
      errMessage: ''
    })
  }

  townChk(dataid){
    let data = {}
    if(dataid && dataid !=''){
     const id = $.inArray(dataid, this.state.checked)
      if (id === -1) {
       this.state.checked.push(dataid)
       data.suburb_arr = this.state.checked
       this.props.getTowns(data)
      } else {
       this.state.checked.splice(id, 1)
       data.suburb_arr = this.state.checked
       this.props.getTowns(data)
      }
     this.forceUpdate()
   }else{
      data.suburb_arr = this.state.checked
      this.props.getTowns(data)
    }
  }

  
  handleTownChange(selectedOption) {
    let {townData} = this.props
    let towns = selectedOption.split(",")
      _.forEach(towns,function(towndata){
          var serv  =_.findIndex(townData, function(cat) {
          return cat.name == towndata
        })
      }
    )
    var array = JSON.parse("[" + selectedOption + "]");
    this.setState({townOptions: array})
  }

  submitAllData(){
    let onlyNumber = this.state.mobile_phone.replace(/\D/g, '')
    let mobileValidation = onlyNumber.length
    const err = {}
    const { first_name, last_name, email, firm_name, calendar_id, mobile_phone, conference_phone, role, timezone, password, description, townOptions,  confirmPass, price_min, price_max, avatar, designation, is_limit, country_code } = this.state
    if (first_name === '' || first_name.trim() === '') {
      err.first_name = 'Enter First Name'
    }
    if (last_name === '' || last_name.trim() === '') {
      err.last_name = 'Enter Last Name'
    }
    if (email === '' || email.trim() === '') {
      err.email = 'Enter Email Address'
    }else if (!this.validateEmail(this.state.email)) {
        err.email = 'Your email address is Invalid.'
    }

    if(this.props.user.role==='superadmin'){
      if (parseInt(price_min) > parseInt(price_max) || parseInt(price_min) === parseInt(price_max)) {
        err.price_max = 'Max price should be greater than min price'
      }
    }
    if (mobile_phone === '' || mobile_phone.trim() === '') {
      err.mobile_phone = 'Enter Mobile Number'
    }else if(mobileValidation < 6  || mobileValidation > 16){
      err.mobile_phone = 'Enter Correct Mobile Number'
    }
    
    if (role === '' || role ==="Select Role" || role.trim() === '') {
      err.role = 'Enter role'
    }
    if (timezone === '' || timezone ==="Select Time Zone") {
      err.timezone = 'Enter Time Zone'
    }
    if(this.state.strategyToggle){
      if (calendar_id === '') {
        err.calendar_id = 'Enter Calendar ID'
      }
    }
    /*if (password === '' || password.trim() === '') {
      err.password = 'Enter Password'
    }
    if (confirmPass === '' || confirmPass.trim() === '' ) {
      err.confirmPass = 'Enter Confirm Passowrd'
    }
    if(password !== confirmPass){
      err.confirmPass = 'Password Does not match'
    }*/

    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {}
      data.first_name   = first_name
      data.last_name    = last_name
      data.email        = email
      data.firm_name    = firm_name
      data.role         = role
      data.phone_mobile = mobile_phone
      data.phone_work   = conference_phone
      data.calendar_id  = parseInt(calendar_id)
      // data.password     = password 
      data.time_zone    = timezone
      data.description  = description
      data.towns_arr    = townOptions
      data.price_min    = price_min
      data.price_max    = price_max
      data.avatar       = avatar
      data.designation  = designation
      data.suburbs_arr  = this.state.checked
      data.is_limit     = is_limit
      data.country_code = country_code
      this.props.addUsers(data)
    }
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  strategistToggleBtn(){
    const {location} = this.props
    if(this.state.strategyToggle){
      this.setState({ strategyToggle: false},function(){
      let data = {}
      data.user_id    = location && location.state && location.state.id && location.state.id
      data.is_schedule_on = this.state.strategyToggle
      this.props.updateScheduling(data)
    })
    }
    else{
    this.setState({ strategyToggle: !this.state.strategyToggle},function(){
      let data = {}
      data.user_id    = location && location.state && location.state.id && location.state.id
      data.is_schedule_on = this.state.strategyToggle
      this.props.updateScheduling(data)
    })
    }
  }

  strategistPermissionBtn(){
    this.setState({ 
      strategyPermissionToggle : !this.state.strategyPermissionToggle, 
      strategyPermissionStatus:true
    })
  }

  handleStrategistChange(selectedOption) {
    let {filterParams} = this.props
    let allData = filterParams && filterParams.data;
    let _strategists = selectedOption.split(",")
    _.forEach(allData.strategists,function(strategistdata){
        var serv  =_.findIndex(_strategists, function(cat) {
          return cat.name == strategistdata.strategist_name
      })
    })
    var array = JSON.parse("[" + selectedOption + "]");
    this.setState({ strategistOpt: array, strategyPermissionStatus: true})
  }

  editStrategistSubmit(user_id){
    let authorization = cookies.get('Authorization')
    const err = {}
    let data = {
      strategist_id: user_id,
      user_ids: this.state.strategistOpt
    }
    if(this.state.strategyPermissionToggle){
      if(_.size(data.user_ids) === 0){
        err.strategyPermission = 'Please select strategists'
        this.setState({ err })
      }
    }
    if(!this.state.strategyPermissionToggle){
      data.user_ids = []
    }

    if (!Object.keys(err).length) {
      fetch(`${SUBURBANJUNGLE_API_HOST}/users/impersonate/strategist/${data.strategist_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(response => {
          const err = {}
          err.strategyPermission = ''
          this.setState({strategyPermissionMsg: "Updated Sucessfully....", err})
          setTimeout(function () {
            window.history.back()
          }, 1500)
        }).catch(logoutInvalidRequest)
    }
  }

  submitData(event){
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', this.state.user_id);
    axios.post(`${API_HOSTNAME}/users/upload_file`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if(response.data && response.data.status){
        this.setState({avatar: response.data.image_url})
        this.submitAllData()
        }
      else{
        this.submitAllData()
        }
    }).catch(logoutInvalidRequest)
  }


  editFirstSubmit(id, event){
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', this.state.user_id);
    axios.post(`${API_HOSTNAME}/users/upload_file`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if(response.data && response.data.status){
        this.setState({avatar: response.data.image_url})
        this.editAllData(id)
        }
      else{
        this.editAllData(id)
        }
    }).catch(logoutInvalidRequest)
  }

  editAllData(id, event){
    let onlyNumber = this.state.mobile_phone.replace(/\D/g, '')
    let mobileValidation = onlyNumber.length
    
    const err = {}
    const {first_name, last_name, email, firm_name, calendar_id, mobile_phone, conference_phone, role, timezone,description, townOptions, price_min, price_max, avatar, designation, is_limit, country_code } = this.state
    if (first_name === '' || first_name.trim() === '') {
      err.first_name = 'Enter First Name'
    }
    if (last_name === '' || last_name.trim() === '') {
      err.last_name = 'Enter Last Name'
    }
    if (email === '' || email.trim() === '') {
      err.email = 'Enter Email Address'
    }else if (!this.validateEmail(this.state.email)) {
        err.email = 'Your email address is Invalid.'
    }

    if (mobile_phone === '' || mobile_phone.trim() === '') {
      err.mobile_phone = 'Enter Mobile Number'
    }else if(mobileValidation < 6  || mobileValidation > 16){
      err.mobile_phone = 'Enter Mobile Correct Number'
    }
    
    if(this.props.user.role==='superadmin'){
      if (parseInt(price_min) > parseInt(price_max) || parseInt(price_min) === parseInt(price_max)) {
        err.price_max = 'Max price should be greater than min price'
      }
    }
    if (role === '' || role ==="Select Role" || role.trim() === '') {
      err.role = 'Enter role'
    }
    if (timezone === '' || timezone ==="Select Time Zone") {
      err.timezone = 'Enter Time Zone'
    }
    if(this.state.strategyToggle){
      if (calendar_id === '') {
        err.calendar_id = 'Enter Calendar ID'
      }
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {}
      data.id           = id
      data.first_name   = first_name
      data.last_name    = last_name
      data.email        = email
      data.firm_name    = firm_name
      data.update_type  = "basic_info"
      data.calendar_id  = parseInt(calendar_id)
      data.role         = role
      data.phone_mobile = mobile_phone
      data.phone_work   = conference_phone
      data.time_zone    = timezone
      data.description  = description
      data.price_min    = price_min
      data.price_max    = price_max
      data.avatar       = avatar
      data.designation  = designation
      data.is_limit  = is_limit
      data.country_code  = country_code
      this.props.addUsers(data)
    }
  }

  editSecondSubmit(id, event){
    event.preventDefault()
    const err = {}
    const { townOptions } = this.state
    let data = {}
    data.id      = id
    data.update_type  = "suburb_info"
    data.price_max = this.state.price_max
    data.price_min = this.state.price_min
    data.role         = this.state.role
    data.towns_arr    = townOptions
    data.suburbs_arr  = this.state.checked
    this.props.addUsers(data)
  }

  impersonateUser(user_id){
    let data = {}
    data.client_id = user_id;
    this.props.impersonateUser(data)
  }

  changePassword(id, event){
    event.preventDefault()
    const err2 = {}
    const { password, confirmPass } = this.state

    if (password === '' || password.trim() === '') {
      err2.password = 'Enter Password'
    }
    if (confirmPass === '' || confirmPass.trim() === '') {
      err2.confirmPass = 'Enter Confirm Passowrd'
    }
    if(password !== confirmPass){
      err2.confirmPass = 'Password Does not match'
    }

    this.setState({ err2 })
    if (!Object.keys(err2).length) {
      let data = {}
      data.user_id       = id
      data.new_password = password
      this.props.updatePassword(data)
      
      setTimeout(function() {
        this.setState({passwordMsg: '', editUserMsg:'', addUserMsg:''})
        window.history.back()
      }.bind(this),2000)
    }
  }

  switchToPreviousPage(){
    window.history.back();
  }

  limitClientToggleBtn(){
    this.setState({ is_limit: !this.state.is_limit})
  }


  handleCodeChange(event){
    this.setState({ country_code: '+'+event})
  }


  render() {
    const {
      pristine,
      isSubmitting,
      rxError,
      user,
      userData,
      location,
      filterParams,
      token,
      phase,
      townData
    } = this.props
    const { avatar, email } = this.state;

    let allData = filterParams && filterParams.data;
    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>
          {val.name}
        </option>
      );
    });

    let minprice =
      allData &&
      allData.price_brackets.map((val, index) => {
        return (
          <option key={index} value={val.price_min}>
            {val.price_min.toLocaleString()}
          </option>
        );
      });

    let maxprice =
      allData &&
      allData.price_brackets.map((val, index) => {
        return (
          <option key={index} value={val.price_max}>
            {val.price_max.toLocaleString()}
          </option>
        );
      });

    let roleList = [];
    if (_user.role == "strategist") {
      roleList = config.roleList.map((val, index) => {
        if (
          val.key === "superadmin" ||
          val.key === "admin" ||
          val.key === "strategist" ||
          val.key === "former-strategist-inactive"
        ) {
          return;
        }
        return (
          <option key={index} value={val.key}>
            {val.name}
          </option>
        );
      });
    } else if (_user.role == "superadmin") {
      roleList = config.roleList.map((val, index) => {
        return (
          <option key={index} value={val.key}>
            {val.name}
          </option>
        );
      });
    } else if (_user.role == "admin") {
      roleList = config.roleList.map((val, index) => {
        if (val.key === "superadmin") {
          return;
        }
        return (
          <option key={index} value={val.key}>
            {val.name}
          </option>
        );
      });
    } else {
      roleList = config.roleList.map((val, index) => {
        return <option key={index} value="" disabled></option>;
      });
    }

    let options = [];
    townData &&
      townData.data &&
      townData.data.map(function(data, key) {
        options.push({ label: data.name_long, value: data.id });
      });
    
    let strategistOptions = [];
    allData &&
      allData.strategists.map((val, index) => {
        if( (val && val.role === 'strategist') && (userData && userData.user && userData.user.id !== val.id)){
          strategistOptions.push({ label: val.strategist_name, value: val.id })
        }
      })

    let _user_id =
      location && location.state && location.state.id && location.state.id;
    const selectPriceStyle = {
      marginTop: "0px"
    };
    if (_user_id && _user_id != "") {
      return (
        <div>
        <div className="junglerwrapperouter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__header--wrapper">
                  <div className="profile__header">
                    <a>
                      <img src="/img/logo.svg" />
                      <h4>
                        <span>|</span>Edit Jungler User
                      </h4>
                    </a>
                  </div>
                  <div
                    className="profile__close"
                    onClick={this.switchToPreviousPage.bind(this)}
                  >
                    <img src="img/close.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mobile__profile--container">
                  <span className="mobile__profile--avtar">
                    <img
                      src={
                        avatar !== "" && avatar !== null
                          ? avatar
                          : '../img/blank_profile_image.png'
                      }
                      alt=""
                    />
                  </span>
                  <form>
                    <span className="btn-file">
                      <span className="editPhotoBtn">Edit Photo </span>
                      <input
                        type="file"
                        name="profileImage"
                        className="edit__photo--btn"
                        id="profileImage"
                        onChange={this.handleFileUpload.bind(this)}
                      />
                    </span>
                    <span className="maximg__label">(Max 1MB allowed)</span>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="profile__container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="profile__container--form">
                    <div className="col-md-12 text-right">
                      <div className="row">
                        {!['former-agent', 'prospect-agent'].includes(this.state.role) ? (
                          <button
                            className="btn__green--outline btn__profile profile__impersonate--btn"
                            onClick={this.impersonateUser.bind(this, _user_id)}
                          >
                            Impersonate User
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group more__margin--btm">
                      <label className="labelinputs__global">
                        Role of User
                      </label>
                      <select
                        name="role"
                        className="selectinputs__global"
                        value={this.state.role}
                        onChange={this.handleChange}
                      >
                        <option>Select Role</option>
                        {roleList}
                      </select>
                    </div>
                    {this.state.err.role ? (
                      <span className="error_msg">{this.state.err.role}</span>
                    ) : (
                      ""
                    )}
                    {user &&
                    (user.role === "superadmin" || user.role === "admin") &&
                    this.state.role === "strategist" ? (
                      <div className="form-group more__margin--btm">
                        <label className="labelinputs__global">
                          Price Range for Strategist
                        </label>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label className="min-price">Min</label>
                            <select
                              name="price_min"
                              value={this.state.price_min}
                              className="form-control custom-select custom-select-label"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="">Select Price</option>
                              {minprice}
                            </select>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label className="min-price">Max</label>
                            <select
                              name="price_max"
                              value={this.state.price_max}
                              className="form-control custom-select custom-select-label"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="">Select Price</option>
                              {maxprice}
                            </select>
                            {this.state.err.price_max ? (
                              <span
                                className="error_msg"
                                style={selectPriceStyle}
                              >
                                {this.state.err.price_max}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role === "strategist" ? (
                      <div className="form-group more__margin--btm">
                        <div className="strategist-yesno">
                          Can strategist recieve clients? &nbsp;
                          <span className="toggle__btn">
                            No
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={this.strategistToggleBtn}
                                checked={this.state.strategyToggle}
                              ></input>
                              <span className="slider round"></span>
                            </label>
                            Yes
                          </span>
                        </div>
                        {this.state.strategyToggle ? (
                          <div className="">
                            <label className="labelinputs__global">
                              Acuity Calendar ID
                            </label>
                            <div className="form-group text-group">
                              <input
                                type="number"
                                name="calendar_id"
                                value={this.state.calendar_id}
                                onChange={this.handleChange}
                                className="textinputs__global"
                                required
                              />
                            </div>
                            {this.state.err.calendar_id ? (
                              <span className="error_msg">
                                {this.state.err.calendar_id}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role === "strategist" ? (
                      <div className="form-group more__margin--btm border-section">
                        <div className="strategist-yesno">
                          Allow Strategist to impersonate other Strategist ? &nbsp;
                          <span className="toggle__btn">
                            No
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={this.strategistPermissionBtn}
                                checked={this.state.strategyPermissionToggle}
                              ></input>
                              <span className="slider round"></span>
                            </label>
                            Yes
                          </span>
                        </div>
                        { this.state.strategyPermissionToggle
                          ? (
                            <div
                              className="coverage__area"
                              style={{ marginBottom: "20px" }}
                            >
                              <label className="labelinputs__global">
                                Strategists
                              </label>
                              <Select
                                multi
                                simpleValue
                                placeholder="Add a strategist"
                                name="strategist"
                                options={strategistOptions}
                                onChange={this.handleStrategistChange.bind(this)}
                                value={this.state.strategistOpt}
                              ></Select>
                            {this.state.err.strategyPermission ? (
                                <span className="strategyPermission__error">
                                  {this.state.err.strategyPermission}
                                </span>
                              ) : (
                                ""
                              )}
                            {
                              this.state.strategyPermissionStatus ? (
                                <button
                                  type="submit"
                                  className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                                  style={{marginTop: '20px'}}
                                  onClick={this.editStrategistSubmit.bind(this, _user_id)}
                                >
                                  Save
                                </button>) : ''
                            }
                            
                            {this.state.strategyPermissionMsg !== "" && (
                              <div className="text-center alert alert-success">
                                {this.state.strategyPermissionMsg}
                              </div>
                            )}
                          </div>
                          ) : (
                            <div>
                              {
                                this.state.strategyPermissionStatus ? (
                                <button
                                  type="submit"
                                  className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                                  onClick={this.editStrategistSubmit.bind(this, _user_id)}
                                >
                                  Save
                                </button>
                                ) : ''
                              }
                              {this.state.strategyPermissionMsg !== "" && (
                                <div className="text-center alert alert-success">
                                  {this.state.strategyPermissionMsg}
                                </div>
                              )}
                            </div>
                          )
                        }
                      </div>)
                      : ("")
                    }
                    
                    <div className="panel panel-default mobile__no--border">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Profile</h3>
                        <div className="profile__container--section">
                          <span className="profile__container--avtar">
                            <img
                              src={
                                avatar !== "" && avatar !== null
                                  ? avatar
                                  : '../img/blank_profile_image.png'
                              }
                              alt=""
                            />
                          </span>
                          <form>
                            <span className="btn-file">
                              <span className="editPhotoBtn">Edit Photo </span>
                              <input
                                type="file"
                                name="profileImage"
                                className="edit__photo--btn"
                                id="profileImage"
                                onChange={this.handleFileUpload.bind(this)}
                              />
                            </span>
                            <span>(Max 1MB allowed)</span>
                          </form>
                        </div>
                      </div>
                      <div className="panel-body">
                        <form>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                First Name
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="first_name"
                                  value={this.state.first_name}
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                              {this.state.err.first_name ? (
                                <span className="error_msg">
                                  {this.state.err.first_name}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Last Name
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="last_name"
                                  value={this.state.last_name}
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                              {this.state.err.last_name ? (
                                <span className="error_msg">
                                  {this.state.err.last_name}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Mobile Phone
                              </label>
                              <div className="form-group text-group country__flag textinputs__global phonefull__input">
                                <PhoneInput
                                  defaultCountry={"us"}
                                  value={this.state.country_code}
                                  autoFormat={false}
                                  disableAreaCodes={true}
                                  enableLongNumbers={false}
                                  onChange={this.handleCodeChange.bind(this)}
                                  buttonClass="flag-dropdown__login"
                                />
                                <NumberFormat
                                  format="(###) ### #### #####"
                                  //placeholder="(444)  444 - 4444"
                                  value={this.state.mobile_phone}
                                  name="mobile_phone"
                                  id="flagInputControlMainPhone"
                                  onChange={this.handleChange.bind(this)}
                                />
                              </div>
                              {this.state.err.mobile_phone ? (
                                <span className="error_msg">
                                  {this.state.err.mobile_phone}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Conference Phone Number
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="conference_phone"
                                  value={this.state.conference_phone}
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <label className="labelinputs__global">Email</label>
                          <div className="form-group text-group">
                            <input
                              type="text"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="textinputs__global"
                              required
                            />
                          </div>
                          {this.state.err.email ? (
                            <span className="error_msg">
                              {this.state.err.email}
                            </span>
                          ) : (
                            ""
                          )}

                          {this.state.role === "strategist" ||
                          this.state.role === "admin" ||
                          this.state.role === "superadmin" ? (
                            <div className="form-group text-group">
                              <label className="labelinputs__global">
                                Designation
                              </label>
                              <input
                                type="text"
                                name="designation"
                                value={this.state.designation}
                                onChange={this.handleChange}
                                className="textinputs__global"
                                required
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.role === "agent" ||
                          this.state.role === "local" ||
                          this.state.role === "business development" ||
                          this.state.role === "former-agent" ||
                          this.state.role === "former-local" ||
                          this.state.role === "former business development" ? (
                            <div className="form-group text-group">
                              <label className="labelinputs__global">
                                Firm Name
                              </label>
                              <input
                                type="text"
                                name="firm_name"
                                value={this.state.firm_name}
                                onChange={this.handleChange}
                                className="textinputs__global"
                                required
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <label className="labelinputs__global">
                              Time Zone
                            </label>
                            <select
                              className="selectinputs__global"
                              name="timezone"
                              value={this.state.timezone}
                              onChange={this.handleChange}
                            >
                              <option>Select Time Zone</option>
                              {timezonlist}
                            </select>
                          </div>
                          {this.state.err.timezone ? (
                            <span className="timezone_error_msg">
                              {this.state.err.timezone}
                            </span>
                          ) : (
                            ""
                          )}

                          <div className="form-group">
                            <textarea
                              className="textareainputs__global"
                              value={this.state.description}
                              rows="8"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter Bio"
                            ></textarea>
                          </div>
                          {this.state.role === "agent" && (
                            <div className="form-group more__margin--btm">
                              <div className="agent-yesno">
                                Limit Clients ? &nbsp;
                                <span className="toggle__btn">
                                  No
                                  <label className="switch agent_switch">
                                    <input
                                      type="checkbox"
                                      onChange={this.limitClientToggleBtn}
                                      checked={this.state.is_limit}
                                    ></input>
                                    <span className="slider round"></span>
                                  </label>
                                  Yes
                                </span>
                              </div>
                            </div>
                          )}
                        </form>

                        <button
                          type="submit"
                          className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                          onClick={this.editFirstSubmit.bind(this, _user_id)}
                        >
                          Save
                        </button>

                        {this.state.editUserMsg !== "" && (
                          <div className="text-center alert alert-success">
                            {this.state.editUserMsg}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="panel panel-default mobile__no--border">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Region Coverage</h3>
                      </div>
                      <div className="panel-body">
                        <form>
                          {allData &&
                            allData.suburbs &&
                            allData.suburbs.map((filter, ind) => {
                              return (
                                <div key={ind} className="col-md-4 col-xs-6">
                                  <div className="row">
                                    <ul className="checkbox__list">
                                      <li>
                                        <span className="coverage__checkbox">
                                          <label className="custom-checkbox">
                                            {filter.name}
                                            <input
                                              checked={
                                                this.state.checked.includes(
                                                  filter.id
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.handleChange}
                                              onClick={() =>
                                                this.townChk(filter.id)
                                              }
                                              type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="clearfix"></div>

                          {!(
                            this.state.role === "superadmin" ||
                            this.state.role === "admin" ||
                            this.state.role === "strategist"
                          ) ? (
                            <div
                              className="coverage__area"
                              style={{ marginBottom: "20px" }}
                            >
                              <label className="labelinputs__global">
                                Suburb
                              </label>
                              <Select
                                multi
                                simpleValue
                                placeholder="Add a town"
                                name="category"
                                options={options}
                                onChange={this.handleTownChange.bind(this)}
                                value={this.state.townOptions}
                              ></Select>
                            </div>
                          ) : (
                            ""
                          )}

                          <button
                            type="submit"
                            className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                            onClick={this.editSecondSubmit.bind(this, _user_id)}
                          >
                            Save
                          </button>

                          {this.state.editSecondMsg !== "" && (
                            <div className="text-center alert alert-success">
                              {this.state.editSecondMsg}
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={this.DeletedUser}
                      className="deleteUserBtn btn btn__delete--outline btn__green--lg mobile__full--btn"
                    >
                      Delete User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
    } else {
      return (
        <div>
        <div className="junglerwrapperouter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__header--wrapper">
                  <div className="profile__header">
                    <a>
                      <img src="/img/logo.svg" />
                      <h4>
                        <span>|</span>Create Jungler User
                      </h4>
                    </a>
                  </div>
                  <div
                    className="profile__close"
                    onClick={this.switchToPreviousPage.bind(this)}
                  >
                    <img src="img/close.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mobile__profile--container">
                  <span className="mobile__profile--avtar">
                    <img
                      src={avatar !== "" ? avatar : "../img/blank_profile_image.png"}
                      alt=""
                    />
                  </span>
                  <form>
                    <span className="btn-file">
                      <span className="editPhotoBtn">Edit Photo </span>
                      <input
                        type="file"
                        name="profileImage"
                        className="edit__photo--btn"
                        id="profileImage"
                        onChange={this.handleFileUpload.bind(this)}
                      />
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="profile__container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="profile__container--form">
                    <div className="form-group more__margin--btm">
                      <label className="labelinputs__global">
                        Role of User
                      </label>
                      <select
                        name="role"
                        onChange={this.handleChange}
                        className="selectinputs__global"
                      >
                        <option>Select Role</option>
                        {roleList}
                      </select>
                    </div>
                    {this.state.err.role ? (
                      <span className="error_msg">{this.state.err.role}</span>
                    ) : (
                      ""
                    )}

                    {user &&
                    user.role === "superadmin" &&
                    this.state.role === "strategist" ? (
                      <div className="form-group more__margin--btm">
                        <label className="labelinputs__global">
                          Price Range for Strategist
                        </label>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label className="min-price">Min</label>
                            <select
                              name="price_min"
                              className="form-control custom-select custom-select-label"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="">Select Price</option>
                              {minprice}
                            </select>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label className="min-price">Max</label>
                            <select
                              name="price_max"
                              className="form-control custom-select custom-select-label"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="">Select Price</option>
                              {maxprice}
                            </select>
                            {this.state.err.price_max ? (
                              <span
                                className="error_msg"
                                style={selectPriceStyle}
                              >
                                {this.state.err.price_max}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.role === "strategist" ? (
                      <div className="form-group more__margin--btm">
                        <div className="strategist-yesno">
                          Can strategist recieve clients? &nbsp;
                          <span className="toggle__btn">
                            No
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={this.strategistToggleBtn}
                                checked={this.state.strategyToggle}
                              ></input>
                              <span className="slider round"></span>
                            </label>
                            Yes
                          </span>
                        </div>
                        {this.state.strategyToggle ? (
                          <div className="">
                            <label className="labelinputs__global">
                              Acuity Calendar ID
                            </label>
                            <div className="form-group text-group">
                              <input
                                type="number"
                                name="calendar_id"
                                value={this.state.calendar_id}
                                onChange={this.handleChange}
                                className="textinputs__global"
                                required
                              />
                            </div>
                            {this.state.err.calendar_id ? (
                              <span className="error_msg">
                                {this.state.err.calendar_id}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="panel panel-default mobile__no--border">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Profile</h3>
                        <div className="profile__container--section">
                          <span className="profile__container--avtar">
                            <img
                              src={
                                avatar !== ""
                                  ? avatar
                                  : "../img/blank_profile_image.png"
                              }
                              alt=""
                            />
                          </span>
                          <form>
                            <span className="btn-file">
                              <span className="editPhotoBtn">Edit Photo </span>
                              <input
                                type="file"
                                name="profileImage"
                                className="edit__photo--btn"
                                id="profileImage"
                                onChange={this.handleFileUpload.bind(this)}
                              />
                            </span>
                          </form>
                        </div>
                      </div>
                      <div className="panel-body">
                        <form>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                First Name
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="first_name"
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                              {this.state.err.first_name ? (
                                <span className="error_msg">
                                  {this.state.err.first_name}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Last Name
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="last_name"
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                              {this.state.err.last_name ? (
                                <span className="error_msg">
                                  {this.state.err.last_name}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Mobile Phone
                              </label>
                              <div className="form-group text-group country__flag textinputs__global phonefull__input">
                                <PhoneInput
                                  defaultCountry={"us"}
                                  value={this.state.country_code}
                                  autoFormat={false}
                                  disableAreaCodes={true}
                                  enableLongNumbers={false}
                                  onChange={this.handleCodeChange.bind(this)}
                                  buttonClass="flag-dropdown__login"
                                />
                                <NumberFormat
                                  format="(###) ### #### #####"
                                  //placeholder="(444)  444 - 4444"
                                  name="mobile_phone"
                                  id="flagInputControlMainPhone"
                                  onChange={this.handleChange.bind(this)}
                                />
                              </div>
                              {this.state.err.mobile_phone ? (
                                <span className="error_msg">
                                  {this.state.err.mobile_phone}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label className="labelinputs__global">
                                Conference Phone Number
                              </label>
                              <div className="form-group text-group">
                                <input
                                  type="text"
                                  name="conference_phone"
                                  onChange={this.handleChange}
                                  className="textinputs__global"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <label className="labelinputs__global">Email</label>
                          <div className="form-group text-group">
                            <input
                              type="text"
                              name="email"
                              onChange={this.handleChange}
                              className="textinputs__global"
                              required
                            />
                          </div>
                          {this.state.err.email ? (
                            <span className="error_msg">
                              {this.state.err.email}
                            </span>
                          ) : (
                            ""
                          )}

                          {this.state.role === "agent" ||
                          this.state.role === "local" ||
                          this.state.role === "former-agent" ||
                          this.state.role === "business development" ||
                          this.state.role === "former-local" ||
                          this.state.role === "former business development" ? (
                            <div className="form-group text-group">
                              <input
                                type="text"
                                name="firm_name"
                                onChange={this.handleChange}
                                className="form-control text-input"
                                required
                              />
                              <span className="bar"></span>
                              <label className="text-label">Firm Name</label>
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.role === "strategist" ||
                          this.state.role === "admin" ||
                          this.state.role === "superadmin" ? (
                            <div className="form-group text-group">
                              <input
                                type="text"
                                name="designation"
                                value={this.state.designation}
                                onChange={this.handleChange}
                                className="form-control text-input"
                                required
                              />
                              <span className="bar"></span>
                              <label className="text-label">Designation</label>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <label className="labelinputs__global">
                              Time Zone
                            </label>
                            <select
                              className="selectinputs__global"
                              name="timezone"
                              onChange={this.handleChange}
                            >
                              <option>Select Time Zone</option>
                              {timezonlist}
                            </select>
                          </div>
                          {this.state.err.timezone ? (
                            <span className="error_msg">
                              {this.state.err.timezone}
                            </span>
                          ) : (
                            ""
                          )}

                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={this.handleChange}
                              className="textareainputs__global"
                              rows="8"
                              placeholder="Enter Bio"
                            ></textarea>
                          </div>
                          {this.state.role === "agent" ? (
                            <div className="form-group more__margin--btm">
                              <div className="agent-yesno">
                                Limit Clients? &nbsp;
                                <span className="toggle__btn">
                                  No
                                  <label className="switch agent_switch">
                                    <input
                                      type="checkbox"
                                      onChange={this.limitClientToggleBtn}
                                      checked={this.state.is_limit}
                                    ></input>
                                    <span className="slider round"></span>
                                  </label>
                                  Yes
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>

                    <div className="panel panel-default mobile__no--border">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Region Coverage</h3>
                      </div>
                      <div className="panel-body">
                        <form>
                          {allData &&
                            allData.suburbs &&
                            allData.suburbs.map((filter, ind) => {
                              return (
                                <div key={ind} className="col-md-4 col-xs-6">
                                  <div className="row">
                                    <ul className="checkbox__list">
                                      <li>
                                        <span className="coverage__checkbox">
                                          <label className="custom-checkbox">
                                            {filter.name}
                                            <input
                                              checked={
                                                this.state.checked.includes(
                                                  filter.id
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                this.townChk(filter.id)
                                              }
                                              type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}

                          <div className="clearfix"></div>

                          {!(
                            this.state.role === "superadmin" ||
                            this.state.role === "admin" ||
                            this.state.role === "strategist"
                          ) ? (
                            <div className="coverage__area">
                              <label className="labelinputs__global">
                                Suburb
                              </label>
                              {/*<input type="text" className="form-control coverage__area--input" placeholder="Add a town"/>*/}
                              <Select
                                multi
                                simpleValue
                                placeholder="Add a town"
                                name="category"
                                options={options}
                                onChange={this.handleTownChange.bind(this)}
                                value={this.state.townOptions}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>

                    <form>
                      {/*<div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">Password</h3>
                    </div>
                    
                    <div className="panel-body">
                      
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group text-group">
                              <input 
                                name="password"
                                type="password" 
                                onChange={this.handleChange}
                                className="form-control text-input" 
                                required/>
                              <span className="bar"></span>
                              <label className="text-label">Password</label>
                            </div>
                            { this.state.err.password ?
                            <span className="error_msg">
                            {this.state.err.password}
                            </span> : '' }
                          </div>
                          <div className="col-md-12">
                            <div className="form-group text-group">
                              <input 
                                type="password" 
                                name="confirmPass"
                                onChange={this.handleChange}
                                className="form-control text-input" 
                                required/>
                              <span className="bar"></span>
                              <label className="text-label">Re-enter Password</label>
                            </div>
                            { this.state.err.confirmPass ?
                            <span className="error_msg">
                            {this.state.err.confirmPass}
                            </span> : '' }
                          </div>
                        </div>

                        {this.state.addUserMsg !== '' &&
                          <div className="text-center alert alert-success">
                            {this.state.addUserMsg}
                          </div>
                        }

                    </div>                 
                   </div>*/}

                      <button
                        type="submit"
                        className="btn btn__green btn__green--lg btm-marg mobile__full--btn tablet__btn--center"
                        onClick={this.submitData.bind(this)}
                      >
                        ADD
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
    }
  }
}

export default reduxForm({
  form: "addjungleruser", // a unique identifier for this form
  destroyOnUnmount: true
})(AddJunglerUserForm);
