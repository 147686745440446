import { connect } from 'react-redux'
import AgentReportForm from './component'
import { getFilterParams} from '../../../store/dashboard/duck'

import { getAgentList } from '../../../store/analytics/duck'

const AgentReporting = connect(
  // Map state to props
  (state) => ({
  	agentPhase: state.analytics.agentPhase,
  	agentListData: state.analytics.agentListData,
  	filterParams: state.dashboard.filterParams
  }),
  // Map actions to props
  {
  	getAgentList,
    getFilterParams
  }
)(AgentReportForm)
export default AgentReporting
