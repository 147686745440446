import { List } from 'immutable'
import Config from '../../config'
import { fetch } from '../../utils'
import { logoutInvalidRequest } from '../../utils/helper'
import UserModel from './user-model'
import $ from 'jquery'
import axios from 'axios'
const HOSTNAME = process.env.API_HOSTNAME
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
const HOME_URL = process.env.HOME_URL
const SITE_URL = process.env.SITE_URL

/* 
Author: Amit Mishra
Descriptions: Function to call login API
*/
export const loginUser = (credentials) => {
  return axios({
    method: 'POST',
    url: `${HOSTNAME}/users/login`,
    data: credentials      
  })
  .then((payload) => {
    return payload.data
  })
  .catch((error) => {
    throw error
  })
}

/* 
Author: Amit Mishra
Descriptions: Function to call login api for Clients
*/
export const loginUserClient = (credentials) => {

  const url = `${SUBURBANJUNGLE_API_HOST}/auth/` + 
    (credentials.login_type === 'mobile' ? 'sms' : 'email') +
    `/login`

  return axios({
    method: 'POST',
    url,
    data: credentials      
  })
  .then((payload) => {
    return payload.data
  })
  .catch((error) => {
    throw error
  })
}

/* 
Author: Amit Mishra
Descriptions: Function to call VAlidating Client EMAIL magic link token 
*/

export const clientValidateToken = data => {

  const url = `${SUBURBANJUNGLE_API_HOST}/auth/` + 
    (data.login_type === 'mobile' ? 'sms' : 'email') +
    `/validate`

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to Get loggedIN user profile
*/

export const fetchUser = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .catch(error => {
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to Updating Profile Info 
*/


export const updateProfile = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/me`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to updating User password
*/

export const updatePassword = data => {
  return fetch(`${HOSTNAME}/users/update_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to call Updating self profile  
*/

export const updateOwnProfile = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/users/${data.user_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to call refreshing token
*/

export const refreshToken = _ => {
  return fetch(`${HOSTNAME}/auth/refresh_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: '{}'
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(responseError => {
      const error = {
        message: 'Authentication failed',
        error: responseError
      };
      throw error;
    });
};

/* 
Author: Amit Mishra
Descriptions: Function to Saving google AUTH data after google authentications for SBJ users
*/

export const saveGoogleAuthdata = data => {
  return fetch(`${HOSTNAME}/users/user_googleauth_validation`, { 
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};



//Function to call WP login api
// export const loginUserWp = (data) => {
//   if(data){
//     var url = `${HOME_URL}/wp-admin/admin-ajax.php`
//     $.ajax({
//         type: "POST",
//         dataType: "json",
//         url: url,
//         data: {
//             action: "jungler_user_login",
//             data:data,
//         },
//         success: function(response) {
//           if(response.success){
//               window.localStorage.setItem('prev', '/login');
//               window.localStorage.setItem('token', response.accessToken);
//               window.location.replace(`${SITE_URL}/thankyou`);
//           } else {
//               alert(response.message)
//           } 
//         }
//     });
//   }
// }

//Function to call signup api
// export const createUser = (data) => {
//   return axios({
//     method: 'POST',
//     url: `${HOSTNAME}/users/api_from_questionnaire_check_again/`,
//     data: data      
//   })
//   .then((payload) => {
//     if(payload.data.status == true ){
//        //window.localStorage.setItem('createSuccess', true);
//        return payload.data
//     } else {
//       // if (payload.data.message !==undefined) {
//       //   alert(payload.data.message)
//       // }else{
//       //   alert('Somthing went wrong')
//       // }
//     }
//   })
//   .catch((error) => {
//       throw error
//   })
// }

/* 
Author: Amit Mishra
Descriptions: Function to call signup Client
*/
export const createUserCheck = (data) => {
  return axios({
    method: 'POST',
    url: `${HOSTNAME}/users/submitQuestionnaireForm`,
    data: data      
  })
  .then((payload) => {
    return payload.data
   // if(payload.data.status ==true){
   //     window.localStorage.setItem('createSuccess', true);
   //     window.localStorage.setItem('prev', '/signup');
   //     //window.location.replace(`${SITE_URL}/thankyou`);
   //     return payload.data
   //  } else {
   //    // if (payload.data.message !==undefined) {
   //    //   alert(payload.data.message)
   //    // }else{
   //    //   //alert('Something went wrong');
   //    //   //window.location.reload();
   //    // }
   //  }
  })
  .catch(logoutInvalidRequest)
}

/* 
Author: Amit Mishra
Descriptions: Function to call Agent booking
*/
export const createAgent = (data) => {
  for (var pair of data.entries()) {
  }
  return axios({
    method: 'POST',
    url: `${HOSTNAME}/users/api_from_questionnaire_agent`,
    data: data      
  })
  .then((payload) => {
    return payload.data
  })
  .catch(logoutInvalidRequest)
}


//Function to call WP signup api
// export const signupUserWp = (data) => {
//   if(data){
//     var url = `${HOME_URL}/wp-admin/admin-ajax.php`
//     $.ajax({
//         type: "POST",
//         dataType: "json",
//         url: url,
//         data: {
//             action: "jungler_user_signup",
//             data:data,
//         },
//         success: function(response) {
//           if(response.success){
//              window.localStorage.setItem('prev', '/signup');
//              window.localStorage.setItem('token', response.accessToken);
//              //window.location.replace(`${SITE_URL}/thankyou`);
//           } else {
//             alert(response.message)
//           }
//         },
//         error: function(err){
          
//         }
//     });
//   }
// }


/* 
Author: Amit Mishra
Descriptions: Function to call reset password link api
*/

export const resetPasswordLink = (credentials) => {
  return axios({
    method: 'POST',
    url: `${HOSTNAME}/users/forgot_password_check`,
    data: credentials      
  })
  .then((payload) => {
      if(payload && payload.data.status){
        return payload.data
      } else {
        alert(payload.data.message)
      }
  })
  .catch(logoutInvalidRequest)
}

/* 
Author: Amit Mishra
Descriptions: Function to call Saving updated password 
*/

//Function to call reset password api
export const resetPassword = (data) => {
  return axios({
      method: 'POST',
      url: `${HOSTNAME}/users/reset_password_check`,
      data: data      
  })
  .then((payload) => {
      if(payload && payload.data.status){
        return payload.datac
      } else {
        // alert(payload.data.message)
      }
  })
  .catch(logoutInvalidRequest)
}


//Function to call reset password wp api
// export const resetPasswordWp = (data) => {
//   if(data){
//     var url = `${HOME_URL}/wp-admin/admin-ajax.php`
//     $.ajax({
//         type: "POST",
//         dataType: "json",
//         url: url,
//         data: {
//             action: "jungler_user_update_password",
//             data:data,
//         },
//         success: function(response) {
//           if(response.success){
//               window.location.replace(`${SITE_URL}/login`);
//           } else {
//               alert(response.message)
//           } 
//         }
//     });
//   }
// }


/* 
Author: Amit Mishra
Descriptions: Function to getting users Notifications
*/

export const getNotifications = (data) => {
  return fetch(`${HOSTNAME}/notification/get_notifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* 
Author: Amit Mishra
Descriptions: Function to clearing notifications 
*/

export const clearNotification = (data) => {
  return fetch(`${HOSTNAME}/notification/clear_notifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const otpVerification = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/auth/sms/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}




