import React, { PureComponent /*, PropTypes*/ } from 'react'
import ReactModal from 'react-modal'
import { get, uniqBy, map, isEmpty } from 'lodash'
import Cookies from 'universal-cookie'

import './styles.scss'
import BackgroundProfile from '../BackgroundProfile/container'
import EditProspectusInformation from '../../Common/Prospectus'
import { SUCCESS } from '../../../../../constants/phase'
import { Spin } from 'antd'
import { formatNumber } from '../../../../../config'
import Loader from '../../../../../components/Loader'
// import EmailPreview from "../../EmailPreview/container"
// import { CloseIcon } from '../../../../../components/icons'

const cookies = new Cookies()

export default class ProspectusInformationComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      editProspectModel: false,
      // previewModel: false,
      // beforePreviewModal: false,
      isLoading: true,
    }
  }
  componentDidMount() {
    this.setState({ isLoading: true })
  }
  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    if (props.createProspectusPhase === SUCCESS) {
      const clientId = props.match.params.id
      props.fetchClientById({ clientId })
      props.fetchFollowUpDate({ clientId })
      states = { ...states, openEditProspectModel: false, isLoading: true }
      props.clearProspectusPhase()
    }
    if (props.fetchClientByIdPhase === SUCCESS) {
      states = { ...states, openEditProspectModel: false, isLoading: false }
      props.clearClientPhase()
    }
    return states
  }

  openEditProspectModel = () => this.setState({ editProspectModel: !this.state.editProspectModel })

  closeEditProspectModal = () => this.setState({ editProspectModel: false })

  // openBeforePreviewModel = () => this.setState({ beforePreviewModal: true })

  // closeBeforePreviewModal = () => this.setState({ beforePreviewModal: false })

  // openPreviewModel = () => this.setState({ previewModel: !this.state.previewModel, beforePreviewModal: false })

  // closePreviewModal = () => this.setState({ previewModel: false })

  render() {
    const { isLoading } = this.state
    const user = cookies.get('user')
    const client = get(this.props, 'clientDetail', {})
    const strategists = uniqBy(get(client, 'strategists', []), 'user_id')
    const rangestr = client && client.price_range
    let priceRange
    if (rangestr) {
      const rangearr = rangestr.split('-')
      if (parseInt(rangearr[0].trim()).toLocaleString() == 0) {
        priceRange = `$${parseInt(rangearr[0].trim()).toLocaleString()}`
      } else {
        priceRange = `$${parseInt(rangearr[0].trim()).toLocaleString()} - $${parseInt(
          rangearr[1].trim()
        ).toLocaleString()}`
      }
    } else {
      priceRange = rangestr
    }

    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__0">Client Information</h5>
            </div>
            <div className="deal__information__cta">
              <button
                className="btn btn__btn btn-dark mr__10 btn__edit__deal"
                onClick={this.openEditProspectModel}
              >
                Edit Client Information
              </button>
            </div>
          </div>
          <div className="card-body card-body-sm">
            <Spin size="large" spinning={isLoading} indicator={<Loader />}>
              <div className="row">
                <div className="col-md-4">
                  <div className="info__block">
                    <label>Strategist(s) </label>
                    {strategists &&
                      map(strategists, (strategist, ind) => {
                        return strategist.role === 'strategist' ||
                          strategist.role === 'admin' ||
                          strategist.role === 'former-strategist-inactive' ? (
                            <p key={ind}>
                              <span> {strategist.strategist_full_name}</span>
                              {((user && user.role === 'superadmin') ||
                                (user && user.role === 'admin')) &&
                                strategist.strategist_rating > 0 &&
                                (<span className="rating-strategist">(Client Rating for Strategist = {strategist.strategist_rating})</span>)}
                            </p>
                          ) : (
                            strategists &&
                            strategists.length === 1 &&
                            strategist.role === 'assign_strategist' && (
                              <p key={ind}>
                                <span>{strategist.strategist_full_name}</span>
                                <span> {client.has_co_strategist ? 'Melissa Gentile' : null} </span>
                                {((user && user.role === 'superadmin') ||
                                  (user && user.role === 'admin')) &&
                                  strategist.strategist_rating > 0 &&
                                  (<span className="rating-strategist">(Client Rating for Strategist = {strategist.strategist_rating})</span>)}
                              </p>
                            )
                          )
                      })}
                  </div>
                  <div className="info__block">
                    <label>Price Range</label>
                    <p>{priceRange}</p>
                  </div>
                  <div className="info__block">
                    <label>Rental Budget (monthly)</label>
                    <p>{get(client, 'rental_budget', false) ? `$${formatNumber(client.rental_budget.toLocaleString())}/mo` : 'N/A'}</p>
                  </div>
                  <div className="info__block">
                    <label>Timing</label>
                    <p>{get(client, 'timing', '') ? client.timing : 'N/A'}</p>
                  </div>

                  <div className="info__block">
                    <label>Signup Source</label>
                    <p>{get(client, 'signup_source', 'sbj') === 'ubj' ? 'Urban Jungle' : 'Suburban Jungle'}</p>
                  </div>

                </div>
                <div className="col-md-8">
                  <div className="info__block">
                    <label>Suburb</label>
                    <p>
                      {get(client, 'suburbs', []).length > 0
                        ? map(client.suburbs, (suburbs, idx) => (
                          <span key={idx}>
                            {suburbs.suburbs_name}
                            <br />
                          </span>
                        ))
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>Towns Assigned</label>
                    <p>
                      {get(client, 'towns', []).length > 0
                        ? map(client.towns, (town, idx) => {
                          if (town) {
                            return <span key={idx}>{town.town_name},{' '}</span>
                          }
                          return ''
                        })
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>Type of advice</label>
                    <p>{get(client, 'advice', false) ? client.advice : 'N/A'}</p>
                  </div>
                  <div className="info__block">
                    <label>Already working with an agent</label>
                    <p>
                      {
                        client.working_with_agent === null ? 
                          'N/A' : 
                          (client.working_with_agent ? 'Yes' : 'No')
                      }
                    </p>
                  </div>
                </div>

                <div className="col-md-12">

                  <div className="info__block">
                    <label>Client Note on Questionnaire </label>
                    <p>{get(client, 'client_info', false) ? client.client_info : 'N/A'}</p>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          {/* <div className="card-body card-body-sm blank__state__text">No Deal Information</div> */}
          <BackgroundProfile {...this.props} />
        </div>
        <EditProspectusInformation
          {...this.props}
          openEditProspectModel={this.openEditProspectModel}
          editProspectModel={this.state.editProspectModel}
          closeEditProspectModal={this.closeEditProspectModal}
          // openBeforePreviewModel={this.openBeforePreviewModel}
        />
        {/* <ReactModal
          isOpen={this.state.beforePreviewModal}
          contentLabel="Before Email Template Preview"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered preview-modalj">

            <div className="react-modal-header removed-border-text">
              <button type="button" className="btn react-modal-close" onClick={() => this.closeBeforePreviewModal()}><CloseIcon /></button>
            </div>
            <div className="modal-body">
              <p class="text-center-prosp">Changing the status to closed will send an email to the client, click on Preview link below to see the copy of the mail</p>
              <div className="buttons button-prospect">

                <button className="btn fill-btn" onClick={() => this.openPreviewModel()}>Preview</button>
              </div>
            </div>
          </div>
        </ReactModal>
        {/* <EmailPreview
          {...this.props}
          openPreviewModel={this.openPreviewModel}
          previewModel={this.state.previewModel}
          closePreviewModal={this.closePreviewModal}
          collapse={2}
        /> */}
      </div>
    )
  }
}
