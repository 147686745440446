import React, { PureComponent } from 'react'
import StrategistMessage from './StrategistMessage/container'
import ClientChat from './ClientChat/container'
import './styles.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
let _user = ''
export default class ChatsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'strategist_chat'
    }
    this.myRef = React.createRef()
  }

  componentDidMount(){
    _user = cookies.get('user')
    const chatTab = _.get(this.props, 'location.state.chatTab', false)
    if (chatTab) {
      this.setState({ tab: chatTab === 'strategist' ? 'strategist_chat' : 'chat_client' })
      const element = document.getElementById('chat_div')
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleClick(tabName, e) {
    this.setState({ tab: tabName })
  }

  render() {
    return (
      <div>
        <div className="card">
          {
            (_user && _user.role === "agent") ?
            <div className="tabs__control tabs__control__simple">
              <button onClick={this.handleClick.bind(this, 'strategist_chat')} className={this.state.tab === "strategist_chat" ? "btn btn-link active selected" : "btn btn-link"}>Message/ Note For Strategist</button>
              <button onClick={this.handleClick.bind(this, 'chat_client')} className={this.state.tab === "chat_client" ? "btn btn-link active selected" : "btn btn-link" }>Chat with Client</button>
            </div>
            :
            <div className="tabs__control tabs__control__simple">
              <button onClick={this.handleClick.bind(this, 'strategist_chat')} className={this.state.tab === "strategist_chat" ? "btn btn-link active" : "btn btn-link"}>Message/ Note For Strategist</button>
            </div>
          }
          

          <div className="card-body card-body-sm" id="chat_div">
            {
              (this.state.tab === "strategist_chat") ?
                <StrategistMessage {...this.props} />
                :
                (this.state.tab === "chat_client") &&
                < ClientChat {...this.props} />
        }
          </div>
        </div>
      </div>
    )
  }
}
