import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import TopNavigation from '../TopNavigation/container'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import { GoogleLogin } from 'react-google-login'
import FontAwesome from 'react-fontawesome'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies();

class UserGoogleAuthForm extends Component {
  constructor() {
    super();
    this.state = { 
      isAuthenticated: false,
      googledata: null,
      isGoogleauthed:false,
      token: '',
      wpUrl: config.url.wpUrl,
      user_id:''
    };
    this.cleraGoogleAuthBackLogin = this.cleraGoogleAuthBackLogin.bind(this)
}
componentWillMount(){

}
cleraGoogleAuthBackLogin(){
  document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  window.location.replace('/login')
}
logout = () => {
    this.setState({isAuthenticated: false, token: '', googledata: null})
};

googleResponse = (res) => {
  const {saveGoogleAuthdata}= this.props;
  let currentuser = cookies.get('user');
  this.setState({isGoogleauthed: true, isAuthenticated: true , googledata: res, user_id:currentuser.id})
  // if(res && res.profileObj && currentuser.email === res.profileObj.email){
  //   const data = {
  //       google_data: this.state.googledata,
  //       isGoogleauthed: this.state.isGoogleauthed,
  //       user_id:this.state.user_id ? this.state.user_id : currentuser.id
  //     }
  //   saveGoogleAuthdata(data);
  // }else{
  //   this.logout();
  //   alert('This email not matched with your profile email, please login with your email id : '+ currentuser.email);
  // }

};
onFailure = (error) => {
  alert(error);
}
componentWillReceiveProps(nextProps) {
    if (nextProps.saveGoogleDataPhase === "success" && nextProps.user.status===true) {
      if(nextProps.user.role ==='strategist' || nextProps.user.role ==='admin'){
        this.props.history.push('/home')
      }else if(nextProps.user.role ==='agent' || nextProps.user.role ==='local'){
         this.props.history.push('/agent')
      }
      
    }
  }
render() {
    const {loginPhase, user}= this.props;
    let userData = user
      if(userData.role ==='superadmin') {
        return(
          <Redirect to={`/dashboard`}/>
          )
      } else if(userData.role ==='admin' || userData.role ==='strategist') {
        if(userData.isGoogleauthed===true) {
          return(
            <Redirect to={`/home`}/>
            )
        }
      }else if(userData.role ==='agent' || userData.role ==='local'){
        //if(userData.isGoogleauthed===true) {
          return(
            <Redirect to={`/agent`}/>
            )
        // }else{
        //   document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //   document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //   return(
        //     <Redirect to={`/login`}/>
        //     )
        // }
    }
  

   
    
    let content = !!this.state.isAuthenticated ?
        (
            <div>
                <p>Authenticated</p>
                <div>
                    {this.state.googledata.profileObj ? this.state.googledata.profileObj.email : 'Email not'}
                </div>
                <div>
                    <button onClick={this.logout} className="button">
                        Log out
                    </button>
                </div>
            </div>
        ) :
        (
            <div className="GoogleAuthButton">
                <GoogleLogin
                  clientId={'922160605945-povmr2rbc1bejk9g2flohfglcbsc2f0r.apps.googleusercontent.com'}
                  fetchBasicProfile={true}
                  onSuccess={this.googleResponse}
                  onFailure={this.googleResponse}
                >
                    <FontAwesome
                      name='google'
                    />
                    <span className="text">Login with SBJ Google Account</span>
                </GoogleLogin>
                
            </div> 
        );

  
    return (
      <section className="login__container">
        <div className="container-fluid">
          <div className="row pop-up-header" >
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left-align">
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 right-align">
              <div className="row">
                <a href={this.state.wpUrl}>
                <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-10 center-align">
              {/*
              <div className="fb-login-button" data-max-row="5"
                data-size="large"
                data-show-faces="false"
                data-auto-logout-link="false"
                href="javascript:void(0)">Continue with Facebook</div>
              */}
              {/*
              <div className="continue-fb"><a href="javascript:void(0)" onClick={this.handleFbLogin}><img src="/../img/oL5c2.png"/></a></div>
              */}
              {/*
              <FacebookLogin
                appId="703440643378941"
                autoLoad={true}
                fields="name,email,picture"
                cssClass="continue-fb"
                callback={responseFacebook}
                render={renderProps =>
              (
              <button onClick={renderProps.onClick}></button>
              )}
              />*/}
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 right-align">
              <div className="close">
                <a href={`${this.state.wpUrl}`}>
                <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="LoignSection">
          <div className="overlay-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="v-container">
                  <div className="login-box">
                    <h3>Authenticate Your Suburban Junle INC or Urbun Group Email Account</h3>
                    <span className="loginAccesstype text-center">(Admins, Strategists, Agents, and Locals)</span>
                    <div className="text-center" >{content} </div>
                    <span className="create-accoutn for-left" onClick={this.cleraGoogleAuthBackLogin.bind(this)}>Back to Login</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'user-google-auth',  // a unique identifier for this form
  destroyOnUnmount: true,
})(UserGoogleAuthForm)
