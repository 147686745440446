import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import _ from 'lodash'
import Config from '../../../../../../config';

//This is for Download All Data into Excel Sheet
import Loader from '../../../../../../components/Loader'
import ReactExport from "react-data-export"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AgentRatingReportComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      users_limit:7000,
      activePage:1,
      sortByKey: 'client_full_name',
      sortByType : 0,
      isLoading: true,
      search: '',
      ratingData: []
    }
  }

  componentDidMount(){
    _user = cookies.get('user')
    if(_user) {
      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='local' || _user.role === 'business development'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='strategist'){
        this.props.history.push('/administration/dashboard')
      }
      const strRatingParams = {
        users_limit : this.state.users_limit,
        users_offset: this.state.activePage,
        sortBy      : {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getAgentRatingReport(strRatingParams)
    }
  }

  componentDidUpdate(){
    const { agentRatingReportPhase, clearReportV2Phase, agentRatingReportData } = this.props
    if(agentRatingReportPhase === "success"){
      let data = []
      if(_.size(agentRatingReportData && agentRatingReportData.data) > 0){
        agentRatingReportData && agentRatingReportData.data && agentRatingReportData.data.map((c, i)=>{
          data.push({
            key: i,
            client_id: c.client_id,
            client_full_name: c.client_full_name ? c.client_full_name  :'N/A',
            agent_full_name: c.agent_full_name ? c.agent_full_name:'N/A',
            rating: c.rating,
            strategists: c.strategists && c.strategists.join(", "),
            appointment_date: c.appointment_date == '0000-00-00 12:00:00 AM' ? '-' : c.appointment_date,
            status: c.status
          })
        })
      }
      this.setState({ ratingData: data, isLoading: false })
      clearReportV2Phase()
    }
    if(agentRatingReportPhase === "error"){
      this.setState({ ratingData: [], isLoading: false})
      clearReportV2Phase()
    }
  }

  onSearch(e){
    this.setState({search:e.target.value})
    const strRatingParams = {
      users_limit : this.state.users_limit,
      users_offset: this.state.activePage,
      sortBy      : {}
    }
    if (String(e.target.value).length > 2) {
      strRatingParams.search = e.target.value
      this.props.getAgentRatingReport(strRatingParams)
    } else if (e.target.value === '') {
      this.props.getAgentRatingReport(strRatingParams)
    }
  }
  
  changeLimit = limit => {
    // On limit change
  }
  
  render() {
    const { agentRatingReportData } = this.props
    const { ratingData } = this.state
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Agent Rating Report</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={ratingData} name="agent_rating">
                          <ExcelColumn label="Client Full Name" value="client_full_name"/>
                          <ExcelColumn label="Agent Full Name" value="agent_full_name"/>
                          <ExcelColumn label="Rating" value="rating"/>
                          <ExcelColumn label="Strategists" value="strategists"/>
                          <ExcelColumn label="Appointment Date" value="appointment_date"/>
                          <ExcelColumn label="Status" value="status"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by agent or client name (*required 3 characters atleast)..."
                      required
                    />
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(ratingData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Client Name',
                              width: 250,
                              dataIndex: 'client_full_name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend'],
                              sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name),
                              render: (text, val) => <span className="cursor_pointer" onClick={()=> this.props.history.push(`/activity/${val.client_id}`)}>{text}</span>
                            },
                            {
                              title: 'Agent Name',
                              width: 250,
                              dataIndex: 'agent_full_name',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend'],
                              sorter: (a, b) => a.agent_full_name.localeCompare(b.agent_full_name)
                            },
                            {
                              title: 'Rating',
                              dataIndex: 'rating',
                              key: '3',
                              width: 100,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.rating.localeCompare(b.rating)
                            },
                            {
                              title: 'Strategist Name',
                              dataIndex: 'strategists',
                              key: '4',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.strategists.localeCompare(b.strategists)
                            },
                            {
                              title: 'Appointment Date',
                              dataIndex: 'appointment_date',
                              key: '5',
                              width: 300,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.appointment_date.localeCompare(b.appointment_date)
                            },
                            {
                              title: 'Pipeline Stage',
                              dataIndex: 'status',
                              key: '6',
                              width: 320,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.status.localeCompare(b.status)
                            },
                          ]}
                          dataSource={ratingData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
