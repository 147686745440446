import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import validate from '../validate'
import ReportNavigation from './reportNavigation'
import Community from './Community/component'
import Images from './Images/component'
import PrivateSchool from './PrivateSchool/component'
import PublicSchool from './PublicSchool/component'
import Commute from './Commute/component'
import GooglePlaces from './GooglePlaces/component'
import Research from './Research/component'


class EditTownReport extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { match: { params: { townId }} } = this.props;
    const currentPath = window.location.pathname
    return (
      <div className="town-report">
        <ReportNavigation {...this.props} />

        <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  { this.props.match.path === `/edit-report/community/:townId` && <Community {...this.props}/> }
                  { this.props.match.path === '/edit-report/images/:townId' && <Images {...this.props}/> }
                  { this.props.match.path === '/edit-report/private-school/:townId' && <PrivateSchool {...this.props}/> }
                  { this.props.match.path === '/edit-report/public-school/:townId' && <PublicSchool {...this.props}/> }
                  { this.props.match.path === '/edit-report/commute/:townId' && <Commute {...this.props}/> }
                  { this.props.match.path === '/edit-report/google-places/:townId' && <GooglePlaces {...this.props}/> } 
                  { this.props.match.path === '/edit-report/research/:townId' && <Research {...this.props}/> } 
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'EditTownReport', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: [],
  validate
})(EditTownReport)
