import { fetch } from '../../../utils'
import qs from 'qs'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

/*
     /////////////////////////
   /// clients apis ///
 ////////////////////////
*/
// fetch notes of client using clientId
export const fetchClientById = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// old Api name /users/client_strategist_bd_list/${client_id}`
export const fetchClientStrategistBDList = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/biz-dev-credits`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch notes of client using clientId
export const fetchStrategistBDList = (data) => {
  let url = `${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/referrers`
  if (data.searchTerm) url += `?searchTerm=${data.searchTerm}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch dev biz option
export const fetchStrategistDevBiz = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/all-biz-dev-credits`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// create using clientId
export const createBizDevCredit = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/biz-dev-credits`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// create using clientId
export const addUpdateClient = (data) => {
  
  // SJ-617: Handle phone, country code data issues.  
  // Component doesn't set initial value correctly until onChange event, but sets default value
  data.phone_mobile = data.phone_mobile.trim()
  if (!data.country_code) data.country_code = "+1"

  if (data.secondary_phone_email) {
    data.secondary_phone_email = data.secondary_phone_email.trim()
    if (!data.secondary_country_code) data.secondary_country_code = "+1"
  } 

  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
//  edit bais client information
export const editClientInformation = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// create reffering client
export const createRefferClient = (data) => {
  const url = `${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/referrers`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// get client list
export const getClientList = (data) => {
  const qsQueryString = qs.stringify(data)
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/?${qsQueryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload.data)
    .catch((error) => {
      throw error
    })
}
