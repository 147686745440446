/* eslint-disable consistent-this */
/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react'
import { DatePicker, Spin, Switch, Alert } from 'antd'
import TopNavigation from '../../../../components/TopNavigation/container'
import SidebarNavigation from '../../../../components/SidebarNavigation/component'
import ClientInformation from './ClientInformation/container'
import BackgroundProfile from './BackgroundProfile/container'
import DealInformation from './DealInformation/container'
import Chats from './Chats/container'
import ActivityHistory from './ActivityHistory/container'
import { BackArrowIcon, DownCaretIcon, CloseIcon, BlockNotReadyTourIcon } from '../../../../components/icons'
import UserContext from "../../../App/Context"
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'
import NumberFormat from 'react-number-format'
import ReactModal from 'react-modal'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import Loader from "../../../../components/Loader"
import './styles.scss'

import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from '../../../../utils/helper'
const cookies = new Cookies()
let _user = ''

export default class ClientDetailsComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      deleteDealModal: false,
      dealData: [],
      clientData: [],
      dealType: "sales",
      address: '',
      dealStatus: '',
      amount: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      isSelect: false,
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      lease_start_date: '',
      lease_term: '',
      buttonType: 'add',
      client_id: '',
      dltType: '',
      clientId: 0,
      notReadyForTownsTour: [],
      spinFollowUp: false,
      selectType: "background_profile"
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    const { match: { params: { id } } } = this.props
    this.props.getClientById(parseInt(id))
    this.setState({ clientId: parseInt(id) })
    this.getNotReadyForTownTourData()
  }

  getNotReadyForTownTourData = async () => {
    this.setState({ notReadyForTownsTour: []})
    // const { match: { params: { id } } } = this.props
    // const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = await axios({
    //     method: 'GET',
    //     url: `${HOSTNAME_V2}/agent/intrested_not_ready_tour_towns/${id}`,
    //     headers: { Authorization: jwtToken }
    //   }).catch(logoutInvalidRequest)
    //   this.setState({ notReadyForTownsTour: _.get(res, 'data.data', []) })
    // }
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } }, location } = this.props
    if (location.pathname !== prevProps.location.pathname) {
      this.props.getClientById(parseInt(id))
      this.props.getAgentDeal({ client_id: parseInt(id) })
      this.getNotReadyForTownTourData()
      this.setState({ clientId: parseInt(id) })
    }

    if (this.props.clientDealPhase === "success") {
      this.updateClientStatus()
      this.props.clearAgentDatePhase()
    }
    if (this.props.clientDealPhase === "error") {
      this.props.clearAgentDatePhase()
      this.closeModal()
    }
    if (this.props.updateUserPhase === "success") {
      this.props.clearPhase()
      this.props.getAgentDeal({ client_id: parseInt(id) })
      this.props.getClientById(parseInt(id))
    }
    if (this.props.deleteClientDealPhase === "success") {
      this.props.clearAgentDatePhase()
      this.deleteUpdateClientStatus()
      this.setState({ deleteDealModal: false })
    }
    if (this.props.deleteClientDealPhase === "error") {
      this.props.clearAgentDatePhase()
      this.setState({ deleteDealModal: false })
    }
    //this is for follow up dates
    if (this.props.followUpPhase === "success") {
      this.props.getClientById(parseInt(id))
      this.props.clearPhase()
    }
    if (this.props.followUpPhase === "error") {
      this.setState({ spinFollowUp: false })
      this.props.clearPhase()
    }
    if (this.props.clientPhase === "success") {
      this.props.clearPhase()
      const usrData = _.get(this.props.clientData, 'users', [])
      document.title = `${usrData && usrData.first_name} ${usrData && usrData.last_name} | Jungler`
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.clientData !== _.get(props.clientData, 'users', [])) {
      return { clientData: _.get(props.clientData, 'users', []), spinFollowUp: false }
    }
    return null;
  }

  updateClientStatus() {
    const data = {}
    data.client_id = this.state.client_id
    data.activity_status = this.state.dealStatus
    data.isAcceptOffer = true
    data.isSendEmail = false
    data.address = this.state.address
    data.amount = parseInt(this.state.amount.toString().replace(/\D/g, ''))
    data.buy_side_commission = parseFloat(this.state.buy_side_commission)
    data.accepted_offer_date = moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')
    data.expected_close_date = moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
    data.lease_start_date = moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
    data.lease_term = this.state.lease_term
    data.deal_type = this.state.dealType === "sales" ? 0 : 1
    data.agent_notes = this.state.agent_notes
    data.sj_comission = this.state.misc_fee ? parseInt(this.state.misc_fee.toString().replace(/\D/g, '')) : 0
    if (this.state.signed_contract_date) {
      data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
    }
    this.props.updateClientUser(data)
    this.closeModal()
  }

  deleteUpdateClientStatus() {
    const data = {}
    data.client_id = this.state.client_id
    data.isAcceptOffer = false
    data.isSendEmail = false
    data.activity_status = "Moderately Engaged"
    this.props.updateClientUser(data)
  }

  handleAddressChange(address) {
    this.setState({ address, isSelect: false }, () => {
    })
  }

  handleSelect = (addr) => {
    const that = this
    geocodeByAddress(addr)
      .then(results =>
        that.setState({ address: results[0].formatted_address, isSelect: true })
      )
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  }

  handleChange(event) {
    if (event.target.value === "sales" || event.target.value === "rental") {
      this.setState({ err: {} })
    }

    // Parse decimal fields
    const decimalFields = ['amount', 'buy_side_commission', 'misc_fee']
    if (decimalFields.includes(event.target.name)) {
      let newValue = event.target.value.replace(/[\$,]/g, '')
      newValue = parseFloat(newValue)
      this.setState({ [event.target.name]: newValue })
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }

  }

  selectChange(e) {
    this.setState({ selectType: e.target.value })
  }

  onDateChange(clientId, date, dateString) {
    const selectDate = moment(date).format("YYYY-MM-DD")
    const data = {}
    data.follow_up_date = selectDate
    data.client_id = clientId
    data.agent_id = _user && _user.id

    const clientData = this.state.clientData
    clientData.follow_up_date = selectDate
    this.setState({ clientData, spinFollowUp: true }, () => {
      this.props.agentClientFollowUp(data)
    })
  }

  // This is function when add accept offer, signed contract and closed deal
  openDealModal = (isOpen, buttonType, errorMessage, clientData, deal_status) => {
    let errMsg = ''
    if (deal_status === "Signed Contract" || deal_status === "Closed") {
      errMsg = "mandatory"
    } else {
      errMsg = "optional"
    }
    this.setState({
      modalIsOpen: isOpen, dealStatus: deal_status, clientData, buttonType, client_id: clientData.id, errorMessage: errMsg
    })
  }

  closeModal = (data) => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({
      modalIsOpen: false,
      isEmailModel: false,
      isSelect: false,
      dealStatus: '',
      dealData: [],
      buttonType: 'add',
      clientData: [],
      amount: '',
      dealType: "sales",
      address: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      errorMessage: "optional",
      lease_start_date: '',
      lease_term: ''
    })
  }

  // If deal is already created and click on accepted offer so first delete deal
  deleteDealData = (dealData, clientData, deal_status, where) => {
    this.setState({
      deleteDealModal: true, client_id: clientData.id, dealStatus: deal_status, dealData, dltType: where
    })
  }

  // Delete deal button when get accepted offer created
  deleteDealButton(e) {
    this.setState({ buttonType: "edit" })
    const dealID = this.state.dealData[0] && this.state.dealData[0].id
    const dealDlt = {}
    dealDlt.agent_id = this.state.dealData.agent_id
    dealDlt.client_id = this.state.client_id
    // dealDlt.deal_id = this.state.dltType === '' ? _.get(dealZeroPosition[0], 'id') : dealID
    dealDlt.deal_id = this.state.dltType === 'from_delete' ? this.state.dealData.id : dealID
    this.props.deleteClientDealByAgent(dealDlt)
  }

  // close deal modal
  deleteDealCloseModal = () => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({ deleteDealModal: false, dealData: [], preStatus: this.state.preStatus })
  }

  // If deal already created but click on signed contract or closed deal so work this function
  editDealSection = (dealData, clientData, deal_status) => {
    let errMsg = ''
    if (deal_status === "Signed Contract" || deal_status === "Closed") {
      errMsg = "mandatory"
    } else {
      errMsg = "optional"
    }
    this.setState({
      modalIsOpen: true,
      dealStatus: deal_status,
      dealData,
      isSelect: true,
      clientData,
      buttonType: 'edit',
      client_id: clientData.id,
      amount: dealData.amount,
      accepted_offer: (_.isEmpty(dealData.accepted_offer_date) || (dealData.accepted_offer_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.accepted_offer_date),
      signed_contract_date: (_.isEmpty(dealData.date_of_signed_contract) || (dealData.date_of_signed_contract === "0000-00-00 00:00:00")) ? '' : moment(dealData.date_of_signed_contract),
      expected_close_date: (_.isEmpty(dealData.expected_close_date) || (dealData.expected_close_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.expected_close_date),
      deal_id: dealData.id,
      address: dealData.address,
      buy_side_commission: dealData.buy_side_commission,
      misc_fee: dealData.sj_comission,
      agent_notes: dealData.agent_notes,
      preStatus: deal_status,
      errorMessage: errMsg,
      lease_start_date: (_.isEmpty(dealData.lease_start_date) || (dealData.lease_start_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.lease_start_date),
      lease_term: dealData.lease_term,
      dealType: dealData.deal_type === 0 ? "sales" : "rental"
    })
  }

  dateAcceptedClick(date, dateString) {
    this.setState({ accepted_offer: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  dateSignedClick(date, dateString) {
    this.setState({ signed_contract_date: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  dateClosedClick(date, dateString) {
    this.setState({ expected_close_date: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  dateLeaseClick(date, dateString) {
    this.setState({ lease_start_date: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  CloseTabSetStatus(clientId) {
    $(`.uniQueselect-${clientId}`).prop('selectedIndex', 0)
  }

  submitDealButton() {
    const err = {}
    if (!this.state.isSelect) {
      err.address = 'Please Select address'
    }
    if (this.state.amount === '') {
      err.amount = 'Amount is required'
    }
    if (this.state.dealType === 'sales' || this.state.dealType === 0) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }

      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
          }
        }
        if ((this.state.expected_close_date !== '') || !_.isEmpty(this.state.expected_close_date)) {
          if ((this.state.accepted_offer > this.state.expected_close_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
          }
          if (this.state.signed_contract_date > this.state.expected_close_date) {
            err.signed_contract_date = 'Signed Contract date cannot be after Closing date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed date is required';
        }
        if ((this.state.expected_close_date === '' || _.isEmpty(this.state.expected_close_date))) {
          err.expected_close_date = 'Closed date is required';
        }

        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.accepted_offer > this.state.expected_close_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.signed_contract_date > this.state.expected_close_date)) {
          err.signed_contract_date = 'Signed contract date cannot be after Closing date';
        }
      }
    } else if (this.state.dealType === 'rentals' || this.state.dealType === 'rental' || this.state.dealType === 1) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }

      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
          }
        }
        if ((this.state.lease_start_date !== '') || !_.isEmpty(this.state.lease_start_date)) {
          if ((this.state.accepted_offer > this.state.lease_start_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
          }
          if (this.state.signed_contract_date > this.state.lease_start_date) {
            err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed Lease is required';
        }
        if ((this.state.lease_start_date === '' || _.isEmpty(this.state.lease_start_date))) {
          err.lease_start_date = 'Lease Start date is required';
        }

        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.accepted_offer > this.state.lease_start_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.signed_contract_date > this.state.lease_start_date)) {
          err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
        }
      }
    }

    if (this.state.errorMessage === "mandatory") {
      if (this.state.signed_contract_date === '' || this.state.signed_contract_date === "Invalid date" || this.state.signed_contract_date === "0000-00-00 00:00:00") {
        err.signed_contract_date = 'Please Select Signed Date'
      }
    }

    if (this.state.dealType === "sales") {
      if (this.state.errorMessage === "mandatory") {
        if (this.state.expected_close_date === '' || this.state.expected_close_date === "Invalid date" || this.state.expected_close_date === "0000-00-00 00:00:00" || this.state.expected_close_date === 'null' || this.state.expected_close_date === null) {
          err.expected_close_date = 'Please Select Closed Date'
        }
      }
    } else {
      if (this.state.lease_term === '') {
        err.lease_term = 'Please Fill Lease Term'
      }
      if (this.state.errorMessage === "mandatory") {
        if (_.isEmpty(this.state.lease_start_date) || (this.state.lease_start_date === "0000-00-00 00:00:00")) {
          err.lease_start_date = 'Please Select Date'
        }
      }
    }

    if (this.state.misc_fee === '') {
      err.misc_fee = 'Please Enter Misc Fee'
    }

    if (this.state.buy_side_commission === '') {
      err.buy_side_commission = 'Commission is required'
    }

    if (this.state.agent_notes === '') {
      err.agent_notes = 'Please fill agent note'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      const { createClientDeal } = this.props
      const data = {}
      data.deal_type = this.state.dealType === "sales" ? 0 : 1
      data.client_id = parseInt(this.state.clientData.id)
      data.strategist_id = _.get(this.state.clientData, 'strategist[0].strategist_id')
      data.agent_id = this.context.userData.id
      data.amount = this.state.amount
      data.address = this.state.address
      data.status = this.state.dealStatus
      data.id = (this.state.buttonType) === "add" ? 0 : this.state.deal_id
      data.accepted_offer_date = moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')

      if (this.state.dealType === "sales") {
        if (this.state.expected_close_date) {
          data.expected_close_date = moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
        }
      } else {
        data.lease_term = this.state.lease_term
        if (this.state.lease_start_date) {
          data.lease_start_date = moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
        }
      }

      if (this.state.signed_contract_date) {
        data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
      }
      data.sj_comission = this.state.misc_fee
      data.buy_side_commission = this.state.buy_side_commission
      data.agent_notes = this.state.agent_notes
      createClientDeal(data)
      this.setState({ modalIsOpen: false })
    }
  }

  initialSelected = (id, clientData) => {
    this.setState({ client_id: parseInt(id), isEmailModel: true })
  }

  emailClickHandle(e) {
    this.setState({ isEmailModel: false })
    const data = {
      client_id: this.state.clientId
    }
    data.client_id = this.state.clientId
    if (this.state.clientIsSendEmail) {
      this.setState({ isSendEmail: false }, function () {
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Null"
        this.props.updateClientUser(data)
      })

    } else {
      this.setState({ isSendEmail: !this.state.isSendEmail }, function () {
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Initial Welcome Sent"
        this.props.updateClientUser(data)
      })
    }
  }

  render() {
    const { clientData } = this.state
    return (
      <div className="client__details">
        <div className="layout-has-sidebar">
          <SidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={this.props.history.goBack} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back</button>
                  </div>
                  <div className="col-xs-6 col-md-8">
                    <div className="client__details__header__cta">
                      <Spin spinning={this.state.spinFollowUp} indicator={<Loader />}>
                        <div className="btn__follow-up-date">
                          <div className="follow-up-date-label">Follow up date</div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.onDateChange.bind(this, _.get(clientData, 'id', 0))}
                            value={clientData && clientData.follow_up_date && moment.utc(clientData.follow_up_date)}
                            inputReadOnly={true}
                            allowClear={false}
                            placeholder="N/A"
                            format="MM/DD/YYYY"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.notReadyForTownsTour && this.state.notReadyForTownsTour.length > 0 ?
                (<div className="alert__info__indicated"><BlockNotReadyTourIcon />&nbsp;&nbsp;These clients have indicated some interest in these towns but are NOT ready to tour yet. They should not be reached out to until indicated by your strategist.
                </div>) : ('')
              }
              <div className="indicator_margin"></div>

              <div className="row">
                <div className="col-md-12">
                  <ClientInformation
                    {...this.props}
                    notReadyForTownsTour={this.state.notReadyForTownsTour}
                    openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} initialSelected={this.initialSelected}
                  />
                  <div className="mob__client__select">
                    <select
                      defaultValue={this.state.selectType}
                      onChange={this.selectChange.bind(this)}
                      className="form-control custom__select">
                      <option value="background_profile">Background Profile</option>
                      <option value="deal_information">Deal Information</option>
                      <option value="chat">Chat</option>
                      <option value="activity_history">Activity History</option>
                    </select>
                  </div>

                  {/* This view only for mobile */}
                  <div className="mob__client__select">
                    {
                      (_.get(this.context.userData, 'role') !== "local") && (this.state.selectType === "background_profile") &&
                      <BackgroundProfile {...this.props} />
                    }
                    {
                      (_.get(this.context.userData, 'role') === "agent") && (this.state.selectType === "deal_information") &&
                      <DealInformation
                        {...this.props}
                        openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection}
                      />
                    }
                    {
                      (this.state.selectType === "chat") &&
                      <Chats {...this.props} />
                    }
                    {
                      (this.state.selectType === "activity_history") &&
                      <ActivityHistory {...this.props} />
                    }
                  </div>
                  {/* End for mobile view */}

                  {/* View only for desktop */}
                  <div className="desktop__view">
                    {
                      (_.get(this.context.userData, 'role') !== "local") &&
                      <BackgroundProfile {...this.props} />
                    }
                    {
                      (_.get(this.context.userData, 'role') === "agent") &&
                      <DealInformation
                        {...this.props}
                        openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection}
                      />
                    }
                    <Chats {...this.props} />
                    <ActivityHistory {...this.props} />
                  </div>
                </div>
              </div>
            </div>

            {/* This is for accept offer, signed contract and close deal modal */}
            <ReactModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Accepted Offer / Signed Contract / Closed"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">{this.state.dealStatus}</h5>
                  <button type="button" className="btn react-modal-close" onClick={this.closeModal}><CloseIcon /></button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <select
                      name="dealType"
                      defaultValue={this.state.dealType}
                      onChange={this.handleChange.bind(this)}
                      className="form-control custom-select material-textfield-input textfield-input-lg"
                      defaultValue={this.state.dealType}
                    >
                      <option value="sales">Sales</option>
                      <option value="rental">Rental</option>
                    </select>
                    <label className="material-textfield-label label-lg">Deal Type<span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group material-textfield">
                    {/* Autoplace search */}
                    <PlacesAutocomplete
                      value={this.state.address}
                      className="form-control material-textfield-input textfield-input-lg"
                      onChange={this.handleAddressChange.bind(this)}
                      onSelect={this.handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Fragment>
                          <input
                            {...getInputProps({
                              required: true,
                              className: 'form-control material-textfield-input textfield-input-lg text-input addressAutoSuggest'
                            })}
                            required
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {

                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Fragment>
                      )}
                    </PlacesAutocomplete>
                    <label
                      className="material-textfield-label label-lg">
                      Address<span className="text-danger">*</span>
                    </label>
                    {this.state.err.address ?
                      <span className="invalid-feedback">
                        {this.state.err.address}
                      </span> : ''}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">Date of Accepted Offer<span className="text-danger">*</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateAcceptedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                      {this.state.err.accepted_offer_date ?
                        <span className="invalid-feedback">
                          {this.state.err.accepted_offer_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="amount"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.amount}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix="$"
                          decimalSeparator='.'
                          decimalScale={2}                          
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Sales Price" : "Monthly Rental Price"}<span className="text-danger">*</span></label>
                        {this.state.err.amount ?
                          <span className="invalid-feedback">
                            {this.state.err.amount}
                          </span> : ''}
                      </div>
                    </div>
                  </div>

                  {/* This is for signed contract */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">{this.state.dealType === "sales" ? "Date of Signed Contract" : "Date of Signed Lease"}<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateSignedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.signed_contract_date ? moment.utc(this.state.signed_contract_date) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                      {this.state.err.signed_contract_date ?
                        <span className="invalid-feedback">
                          {this.state.err.signed_contract_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="buy_side_commission"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.buy_side_commission}
                          onChange={this.handleChange.bind(this)}
                          suffix="%"
                          decimalSeparator='.'
                          decimalScale={2}                          
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Buy Side Commission" : "Tenant's Agent Commission"}<span className="text-danger">*</span></label>
                        {this.state.err.buy_side_commission ?
                          <span className="invalid-feedback">
                            {this.state.err.buy_side_commission}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is end for signed contract and commision */}

                  <div className="row">
                    {this.state.dealType === "sales" ?
                      (<div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Expected/Actual Closing date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.dateClosedClick.bind(this)}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            defaultValue={this.state.expected_close_date ? moment.utc(this.state.expected_close_date) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        {this.state.err.expected_close_date ?
                          <span className="invalid-feedback">
                            {this.state.err.expected_close_date}
                          </span> : ''}
                      </div>) : ''
                    }
                    {this.state.dealType !== "sales" ?
                      (<div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Lease Start Date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.dateLeaseClick.bind(this)}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            defaultValue={this.state.lease_start_date ? moment.utc(this.state.lease_start_date) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        {this.state.err.lease_start_date ?
                          <span className="invalid-feedback">
                            {this.state.err.lease_start_date}
                          </span> : ''}
                      </div>) : ''
                    }
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="misc_fee"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.misc_fee}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          decimalSeparator='.'
                          decimalScale={2}
                          prefix="$"
                          required
                        />
                        <label className="material-textfield-label label-lg">Misc. Fee<span className="text-danger">*</span></label>
                        {this.state.err.misc_fee ?
                          <span className="invalid-feedback">
                            {this.state.err.misc_fee}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is expected closing dateand misc fee */}
                  {
                    this.state.dealType !== "sales" ?
                      (<div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              name="lease_term"
                              className="form-control material-textfield-input textfield-input-lg"
                              value={this.state.lease_term}
                              onChange={this.handleChange.bind(this)}
                              required
                            />
                            <label className="material-textfield-label label-lg">Lease Term<span className="text-danger">*</span></label>
                            {this.state.err.lease_term ?
                              <span className="invalid-feedback">
                                {this.state.err.lease_term}
                              </span> : ''}
                          </div>
                        </div>
                      </div>) : ''
                  }
                  <div className="form-group material-textfield">
                    <textarea
                      type="text"
                      className="form-control material-textfield-input"
                      rows="8"
                      name="agent_notes"
                      value={this.state.agent_notes}
                      placeholder={this.state.dealType === "sales" ? "" : `Please indicate how lease commission will be calculated.`}
                      onChange={this.handleChange.bind(this)}
                    >
                    </textarea>
                    <label className="material-textfield-label">Agent Notes<span className="text-danger">*</span></label>
                    {this.state.err.agent_notes ?
                      <span className="invalid-feedback">
                        {this.state.err.agent_notes}
                      </span> : ''}
                  </div>
                  <button
                    className="btn btn__btn btn-dark btn__lg w__100"
                    onClick={() => this.submitDealButton()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </ReactModal>

            {/* This is for delete deal data modal */}
            <ReactModal
              isOpen={this.state.deleteDealModal}
              onRequestClose={this.deleteDealCloseModal}
              contentLabel="Are you sure?"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-body react-modal-body-sm">
                  <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.deleteDealCloseModal}><CloseIcon /></button>
                  <h5 className="react-modal-title mb__13">Are you sure?</h5>
                  <p className="react-modal-subtitle mb__3">This will remove the deal information and you will need to re enter the deal.</p>
                  <div className="text-right">
                    <button onClick={this.deleteDealButton.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
                  </div>
                </div>
              </div>
            </ReactModal>

            <ReactModal
              isOpen={this.state.isEmailModel}
              onRequestClose={this.closeModal}
              contentLabel="Are you sure?"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-body react-modal-body-sm">
                  <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.closeModal}><CloseIcon /></button>
                  <h5 className="react-modal-title mb__13">Are you sure?</h5>
                  <p className="react-modal-subtitle mb__3">This is just a confirmation that you sent an Initial Welcome Email to the client. Click “Yes” below if you did.</p>
                  <div className="text-right">
                    <button onClick={this.emailClickHandle.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Sent</button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </main>
        </div>
      </div>
    )
  }
}
