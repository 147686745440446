import { fetch } from "../../../utils";
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2;

////////////////////////////
/// push notification ///
////////////////////////////

// fetch push notification
export const getPushNotification = () => {
  // This request is for fake data.  Removing.

  // return fetch(`${HOSTNAME_V2}/administrator/get_notification`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // })
  //   .then(res => res.json())
  //   .then(payload => {
  //     return payload
  //   })
  //   .catch(error => {
  //     throw error;
  //   });
  return true
};

// fetch notes of client using clientId
export const markAsReadNotification = data => {
  return fetch(`${HOSTNAME_V2}/notification/mark_all_as_read`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
