import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  townResearchs: [],
  fetchResearchTownPhase: INIT,
  updateSchoolTownPhase: INIT,
  updateResearchTownPhase: INIT,
})

export const townsReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* get research town data and list */
    case types.GET_RESEARCH_TOWN_DATA: {
      return state.set('error', null).set('fetchResearchTownPhase', LOADING)
    }
    case types.GET_RESEARCH_TOWN_DATA_SUCCESS: {
      const notes = get(action, 'payload.data', {})
      return state
        .set('townResearchs', notes)
        .set('error', null)
        .set('fetchResearchTownPhase', SUCCESS)
    }
    case types.GET_RESEARCH_TOWN_DATA_ERROR: {
      return state.set('error', action.payload.error).set('fetchResearchTownPhase', ERROR)
    }

    /*  update school town */
    case types.UPDATE_SCHOOL_ORDER: {
      return state.set('error', null).set('updateSchoolTownPhase', LOADING)
    }
    case types.UPDATE_SCHOOL_ORDER_SUCCESS: {
      return state.set('error', null).set('updateSchoolTownPhase', SUCCESS)
    }
    case types.UPDATE_SCHOOL_ORDER_ERROR: {
      return state.set('error', action.payload.error).set('updateSchoolTownPhase', ERROR)
    }

    /*  update research town */
    case types.UPDATE_RESEARCH_TOWN_DATA: {
      return state.set('error', null).set('updateResearchTownPhase', LOADING)
    }
    case types.UPDATE_RESEARCH_TOWN_DATA_SUCCESS: {
      return state.set('error', null).set('updateResearchTownPhase', SUCCESS)
    }
    case types.UPDATE_RESEARCH_TOWN_DATA_ERROR: {
      return state.set('error', action.payload.error).set('updateResearchTownPhase', ERROR)
    }

    case types.CLEAR_TOWNS_PHASE: {
      return state
        .set('fetchResearchTownPhase', INIT)
        .set('updateResearchTownPhase', INIT)
        .set('updateSchoolTownPhase', INIT)
    }

    default: {
      return state
    }
  }
}
