import { Component, createElement } from 'react'
import PropTypes from "prop-types"
import { TextField } from 'material-ui'

const mapError = ({
  meta: {
    touched,
    error,
    warning
  } = {},
  input: {
    ...inputProps
  },
  ...props
}, errorProp = 'errorText') =>
  touched && (error || warning) ? {
    ...props,
    ...inputProps,
    [errorProp]: error || warning
  } : {
    ...inputProps,
    ...props }

function createComponent(MaterialUIComponent, mapProps) {
  class InputComponent extends Component {

    constructor(props) {
      super(props)
      this._handleChange = this._handleChange.bind(this)
      this.state = {
        value: ''
      }
    }

    componentWillReceiveProps(nextProps) {
      const { value } = nextProps
      this.setState({ value: value || '' })
    }

    getRenderedComponent() {
      return this.refs.component
    }

    componentDidMount() {
      const { id } = this.props
      const input = document.getElementById(id)

      const options = {
        componentRestrictions: {},
        types: [
          'bar',
          'restaurant',
          'cafe',
          'casino',
          'bowling_alley',
          'movie_theater',
          'night_club']
      }
      // check if google map is loaded
      let geoSearchBox
      try {
        geoSearchBox = new window.google.maps.places.SearchBox((input), options)
      } catch (err) {
        return
      }

      // if geoSearchBox is not initialized return
      if (!geoSearchBox) {
        return
      }

      // eslint-disable-next-line max-statements
      geoSearchBox.addListener('places_changed', () => {

        const selectedPlace = geoSearchBox.getPlaces()

        const componentForm = {
          // eslint-disable-next-line camelcase
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          // eslint-disable-next-line camelcase
          administrative_area_level_1: 'short_name',
          country: 'long_name',
          // eslint-disable-next-line camelcase
          postal_code: 'short_name',
          name: 'name'
        }

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        const selectedSuggest = {}

        componentForm.name = selectedPlace[0].name
        for (const addressComponent of selectedPlace[0].address_components) {
          const addressType = addressComponent.types[0]
          if (componentForm[addressType]) {
            selectedSuggest[addressType] = addressComponent[componentForm[addressType]]
          }
        }
        selectedSuggest.name = selectedPlace[0].name

        // input.value = `${selectedSuggest.street_number} ${selectedSuggest.route}`
        // set name
        input.value = selectedPlace[0].name

        // generate address from the selected selectedSuggest
        selectedSuggest.address = ''
        if (selectedSuggest.street_number || selectedSuggest.route) {
          if (selectedSuggest.street_number) {
            selectedSuggest.address = `${selectedSuggest.street_number}`
          }

          if (selectedSuggest.route) {
            if (selectedSuggest.address) {
              selectedSuggest.address = `${selectedSuggest.address} ${selectedSuggest.route}`
            } else {
              selectedSuggest.address = `${selectedSuggest.route}`
            }
          }
        }

        // attach place type
        selectedSuggest.types = selectedPlace[0].types

        // attach refined suggest and selected place
        this.props.onSelectPlace(selectedSuggest, selectedPlace[0])
      })
    }

    _handleChange(event, value) {
      this.setState({ value })
    }

    render() {

      const thisProps = { ...this.props }

      // Remove unknown props
      delete thisProps.onSelectPlace

      // construct props
      const props = {
        ...mapProps({ ...thisProps,
          placeholder: ''
        }),
        ref: 'component'
      }

      return createElement(MaterialUIComponent, props)
    }
  }
  InputComponent.displayName = `ReduxFormMaterialUI${MaterialUIComponent.name}`

  InputComponent.propTypes = {
    ...MaterialUIComponent.propTypes,
    onSelectPlace: PropTypes.func
  }

  return InputComponent
}

const PlaceSearchBox = createComponent(TextField, mapError)

export default PlaceSearchBox
