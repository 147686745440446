import { connect } from 'react-redux'

import AdministrationMobileTopNavigationComponent from './component'

const AdministrationMobileTopNavigationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(AdministrationMobileTopNavigationComponent)

export default AdministrationMobileTopNavigationContainer
