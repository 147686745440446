/* eslint-disable camelcase */
import React, { PureComponent /*, PropTypes*/ } from 'react'
import { get, size, upperFirst, map, filter } from 'lodash'
import { Spin, Pagination } from 'antd'
import Cookies from 'universal-cookie'

import Loader from '../../../../components/Loader'
import { SUCCESS } from '../../../../constants/phase'
import TopNavigation from '../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import {
  CloseIcon,
  JunglerUsersColorIcon,
} from '../../../../components/icons'

import FilterUser from './partials/FilterUser'

import './styles.scss'
import JunglerUserTableHeader from './partials/JunglerUserTableHeader'
import JunglerUserList from './partials/JunglerUserList'
import MobileTopView from './partials/MobileTopView'

const cookies = new Cookies()

export default class JunglerUsersComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      selectedRole: "",
      isLoading: false,
      visible: false,
      userRole: [],
      totalPages: 0,
      clientLimit: 20,
      activePage: 1,
      sortByKey: '',
      sortByType: 0,
      totalUser: 0,
      resultEntry: 20,
    }
    this.filterUserRef = React.createRef();
  }
  componentDidMount() {
    const junglerFilter = JSON.parse(localStorage.getItem('junglerUserFilter'))
    if(junglerFilter) {
      this.setState({
        userRole: junglerFilter.userRole && junglerFilter.userRole === '' ? [] : junglerFilter.userRole,
        pageLimit: junglerFilter.pageLimit,
        activePage: junglerFilter.activePage,
        sortByKey: junglerFilter.sortBy.key,
        sortByType: junglerFilter.sortBy.type,
        filterCount: junglerFilter.filterCount,
        selectedRole: junglerFilter.userRole && junglerFilter.userRole === '' ? [] : junglerFilter.userRole
      })
    }
    const usersFilter = {
      userRole: junglerFilter ? junglerFilter.userRole : [],
      pageLimit: junglerFilter ? junglerFilter.pageLimit : this.state.clientLimit,
      activePage: junglerFilter ? junglerFilter.activePage : 1,
      sortBy: junglerFilter ? junglerFilter.sortBy : { key: this.state.sortByKey, type: this.state.sortByType },
    }
    this.props.getJunglerUsers(usersFilter)
  }
  static getDerivedStateFromProps(props, state) {
    if (
      props.impersonatePhase === 'success' &&
      props.impersonateData &&
      props.impersonateData.magic_link
    ) {
      const i_user = cookies.get('user')
      const i_Authorization = cookies.get('Authorization')
      let expires = new Date()
      localStorage.clear()
      expires.setMonth(expires.getMonth() + 2)
      expires = new Date(expires)
      cookies.set('i_Authorization', i_Authorization, { path: '/', expires })
      cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires })
      const magicLink = props.impersonateData.magic_link
      const pathname = `/client-login/token?validate=${magicLink}`
      props.clearPhase()
      document.cookie =
        'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      localStorage.clear()
      window.location.href = pathname
      // props.history.push({ pathname })
    }
    let states = {}
    // if user click on filter show page based on user role selected
    if (props && props.usersCounts) {
        states = {
          ...state,
          totalPages: Math.round(props.usersCounts),
          totalUser: get(props, 'usersCounts', 0),
        }
    }
    if (props.fetchJunglerUserPhase === SUCCESS) {
      props.clearJunglerUserPhase()
      states = { ...state, isLoading: false }
    }
    return states
  }

  impersonateUser = (userId) =>
    this.props.impersonateUser({ client_id: userId })

  clearFilter = () =>
    this.setState(
      {
        clientStatus: [],
        statusDropdown: false,
        status: false,
        filterCount: 0,
      },
      () => {
        this.fetchFilterResult()
      }
    )

  fetchFilterResult() {
    const usersFilter = {
      userRole: this.state.selectedRole,
      pageLimit: this.state.clientLimit,
      activePage: this.state.activePage,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType }
    }

    localStorage.setItem('junglerUserFilter', JSON.stringify({ ...usersFilter, filterCount: this.state.filterCount }))
    this.setState({ isLoading: true }, () => this.props.getJunglerUsers(usersFilter)
    )
  }
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, () => {
      this.fetchFilterResult()
    })
  }
  // handle change role details
  handleChangeRole = ({ userRole }) =>
    this.setState({ userRole, isLoading: true, activePage: 1, filterCount: userRole.length }, () => this.fetchFilterResult())

  setFilterParams = ({ userRole }) => {
    this.setState({ selectedRole: userRole, filterCount: userRole.length })}

  handleResetParams = (removeRole) =>  {
    const { selectedRole } = this.state
    const userRole = filter(selectedRole, role => upperFirst(role) !== removeRole)
    this.setState({ selectedRole: userRole, filterCount: userRole.length, userRole, isLoading: true, activePage: 1 }, () => this.fetchFilterResult())
    this.forceUpdate()
    this.filterUserRef.current.resetFilterParams({ userRole })
  }

  handleResultChange = ({ clientLimit, resultEntry }) =>
    this.setState({ clientLimit, resultEntry, pageLimit: clientLimit }, () => this.fetchFilterResult())

  sortData = (sortByKey) => {
    let sortByType = this.state.sortByType
    sortByType =
      // eslint-disable-next-line no-nested-ternary
      sortByKey !== this.state.sortByKey ? 0 : sortByType === 0 ? 1 : 0
    this.setState({ sortByKey, sortByType }, () => this.fetchFilterResult())
  }
  formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  resetFilterArray = () => {
    const usersFilter = {
      userRole: [],
      pageLimit: this.state.clientLimit,
      activePage: this.state.activePage,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType }
    }
    localStorage.setItem('junglerUserFilter', JSON.stringify({ ...usersFilter, filterCount: 0 }))
    this.setState({ userRole: [], selectedRole: [] })
  }

  render() {
    const {
      selectedRole,
      clientLimit,
      totalPages,
      activePage,
      resultEntry,
      totalUser,
      sortByKey,
      isLoading,
      sortByType,
      userRole
    } = this.state
    const { user } = this.props
    const currentuser = user && user
    const users = get(this.props, 'junglerUsers', [])
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <h2 className="heading-title">
                  <span className="heading-icon">
                    <JunglerUsersColorIcon className="" />
                  </span>
                  Jungler Users {totalUser > 0 && `(${this.formatNumber(totalUser)})`}
                </h2>
              </div>
              <FilterUser
                ref={this.filterUserRef}
                resetFilterArray={this.resetFilterArray}
                handleChangeRole={this.handleChangeRole}
                handleResultChange={this.handleResultChange}
                setFilterParams={this.setFilterParams}
                filterCount={this.state.filterCount}
                selectedRole={this.state.userRole}
                pageLimit={this.state.pageLimit}
                {...this.props}
              />
            </div>

            {/* This component for mobile only */}
            <MobileTopView
              sortByKey={sortByKey}
              sortByType={sortByType}
              sortData={this.sortData}
              users={users}
              history={this.props.history}
              currentUser={currentuser}
              impersonateUser={this.impersonateUser}
             />
            {/* End component for mobile only */}
            <div className="desktop__view">
              <div className="row">
                <div className="col-md-12">
                <ul className="filter__list__flitered">
                  {selectedRole && selectedRole.length > 0 &&
                    map(selectedRole, (role, index) => (
                      <li key={index}>{upperFirst(role)} <span onClick={() => this.handleResetParams(role)}> <CloseIcon className="close-icon" /></span></li>
                    ))}
                  </ul>
                  <div className="table-responsive">
                    <Spin
                      size="large"
                      spinning={isLoading}
                      indicator={<Loader />}
                    >
                     {users && users.length > 0 ? (
                        <table className="table custom-table">
                          <JunglerUserTableHeader
                            sortByKey={sortByKey}
                            sortByType={sortByType}
                            sortData={this.sortData}
                          />
                          <JunglerUserList
                            users={users}
                            history={this.props.history}
                            currentUser={currentuser}
                            impersonateUser={this.impersonateUser}
                          />
                        </table>
                      ) : (
                        <div className="blank__table__state">
                          No user found for this criteria. Please broaden or change your search
                          criteria and filters
                        </div>
                      )}
                    </Spin>
                    {users &&
                      users.length > 0 &&
                      resultEntry < totalPages &&
                      totalPages > 1 && (
                       <Pagination
                        className="mt__20 mb__20"
                        current={activePage}
                        defaultCurrent={1}
                        total={totalPages}
                        showQuickJumper={false}
                        showSizeChanger={false}
                        pageSize={clientLimit}
                        onChange={this.handlePageChange}
                      />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}
