import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/* 
    ///////////////////////////////////
  /// client details epics ///
//////////////////////////////////
 */
// get town and agent ranking by client id
const getRankingByClientIdEpic = (action$) =>
  action$.ofType(types.FETCH_RANKING_BY_CLIENT_ID).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchRankingByClientId(action.payload))
      .map((payload) => ({
        type: types.FETCH_RANKING_BY_CLIENT_ID_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_RANKING_BY_CLIENT_ID_ERROR,
          payload: { error },
        })
      )
  })
// fetch client question and ai questioner
const fetchClientQuestionEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_QUESTION_BY_USER_ID).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientQuestions(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_QUESTION_BY_USER_ID_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_QUESTION_BY_USER_ID_ERROR,
          payload: { error },
        })
      )
  })
// fetch AI recommended town epic
const fetchAIRecommededTownsEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchRecommendedTowns(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_ERROR,
          payload: { error },
        })
      )
  })
//  set approve recommended town epic
const setApproveRecommendedTownEpic = (action$) =>
  action$.ofType(types.APPROVE_RECOMMENDED_AI_TOWNS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.setApproveRecommendedTowns(action.payload))
      .map((payload) => ({
        type: types.APPROVE_RECOMMENDED_AI_TOWNS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.APPROVE_RECOMMENDED_AI_TOWNS_ERROR,
          payload: { error },
        })
      )
  })
//  set approve recommended town epic
const addClientStrategistTimeSheetEpic = (action$) =>
  action$.ofType(types.ADD_CLIENT_STRATEGIST_TIME_SHEET).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.addClientStrategistTimeSheet(action.payload))
      .map((payload) => ({
        type: types.ADD_CLIENT_STRATEGIST_TIME_SHEET_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.ADD_CLIENT_STRATEGIST_TIME_SHEET_ERROR,
          payload: { error },
        })
      )
  })

// combine client detail epics
export const clientDetailEpics = combineEpics(
  fetchClientQuestionEpic,
  getRankingByClientIdEpic,
  fetchAIRecommededTownsEpic,
  addClientStrategistTimeSheetEpic,
  setApproveRecommendedTownEpic
)
