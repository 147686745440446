import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { CalendarOtherIcon, CloseIcon } from "../../../../components/icons"
import ReactModal from 'react-modal'
import { DatePicker } from 'antd'

export default class DateFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      todayDate: moment().format('LL'),
      weekFirstDate: moment().startOf('week').format('LL'),
      weekLastDate: moment().endOf('week').format('LL'),
      monthFirstDate: moment().startOf('month').format('LL'),
      monthLastDate: moment().endOf('month').format('LL'),
      datePickerModel: false,
      customFirstDate: moment(),
      customEndDate: moment(),
      oldCustomFirstDate: moment(),
      oldCustomEndDate: moment(),
      isSelectOpen: false,
      selectIndex: props.value ? props.value : 'today'
    }
  }

  componentDidMount() {
    let localData = JSON.parse(localStorage.getItem('dashboardFromToDate'))
    if (localData && (localData !== null && localData !== undefined && localData !== '')) {
      this.setState({ oldCustomFirstDate: localData.from, oldCustomEndDate: localData.to })
    }
  }
  openDatePickerRange = () => this.setState({ datePickerModel: !this.state.datePickerModel })

  closeCustomDate = (e) => this.setState({ datePickerModel: false, customFirstDate: "", customEndDate: "" })

  handleDateChange = (name, date) => this.setState({ [name]: date })

  submitCustomDate() {
    this.setState(
      {
        datePickerModel: false,
        selectIndex: 'custom',
        oldCustomFirstDate: moment(this.state.customFirstDate).format(
          'YYYY-MM-DD'
        ),
        oldCustomEndDate: moment(this.state.customEndDate).format('YYYY-MM-DD'),
      },
      () =>
        this.props.filterByDate({
          from: moment(this.state.customFirstDate).format('YYYY-MM-DD'),
          to: moment(this.state.customEndDate).format('YYYY-MM-DD'),
          val: 'custom',
        })
    )
  }
  // if user select custom option
  openCustomModel = (event) => {
    if (get(event, 'target.value', '') === 'custom')
      this.setState({
        datePickerModel: true,
        customFirstDate: moment(this.state.oldCustomFirstDate),
        customEndDate: moment(this.state.oldCustomEndDate),
      })
  }

  changeDate(e) {
    const data = {}
    if (e.target.value === 'today') {
      data.from = moment(this.state.todayDate).format('YYYY-MM-DD')
      data.to = moment(this.state.todayDate).format('YYYY-MM-DD')
      this.changeClickProp(
        this.state.todayDate,
        this.state.todayDate,
        e.target.value
      )
    } else if (e.target.value === 'week') {
      data.from = moment(this.state.weekFirstDate).format('YYYY-MM-DD')
      data.to = moment(this.state.weekLastDate).format('YYYY-MM-DD')
      this.changeClickProp(
        this.state.weekFirstDate,
        this.state.weekLastDate,
        e.target.value
      )
    } else if (e.target.value === 'month') {
      data.from = moment(this.state.monthFirstDate).format('YYYY-MM-DD')
      data.to = moment(this.state.monthLastDate).format('YYYY-MM-DD')
      this.changeClickProp(
        this.state.monthFirstDate,
        this.state.monthLastDate,
        e.target.value
      )
    } else if (e.target.value === 'custom') {
      this.setState({
        datePickerModel: true,
        customFirstDate: moment(this.state.oldCustomFirstDate),
        customEndDate: moment(this.state.oldCustomEndDate),
      })
    }
  }

  changeClickProp(from, to, val) {
    this.setState({ selectIndex: val })
    this.props.filterByDate({
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      val,
    })
  }
  disabledDate = (current) => current && current < moment().subtract(1, 'day');

  render() {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    // value is props to set value of  selected
    const { maxWidth, value } = this.props
    return (
      <Fragment>
        <div style={{ maxWidth }}>
          <div className="form-group material-textfield date__selector">
            <select
              className="form-control custom-select material-textfield-input textfield-input-lg"
              required
              value={value ? value : 'today'}
              onChange={(e) => this.changeDate(e)}
            // onClick={this.openCustomModel}
            >
              <option value="today">{`Today (${todayDate})`}</option>
              <option value="week">{`This Week (${weekFirstDate} - ${weekLastDate})`}</option>
              <option value="month">{`This Month (${monthFirstDate} - ${monthLastDate})`}</option>
              <option value="custom">{`Custom (${moment(this.state.oldCustomFirstDate).format('LL')} - ${moment(this.state.oldCustomEndDate).format('LL')})`}</option>
            </select>
            <label className="material-textfield-label label-lg">Date</label>
            <CalendarOtherIcon className="datepicker-calendar-icon" />
          </div>
        </div>

        <ReactModal
          isOpen={this.state.datePickerModel}
          onAfterOpen={(e) => { }}
          onRequestClose={(e) => this.closeCustomDate}
          contentLabel="Custom Date"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-sm react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Custom Date</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeCustomDate}><CloseIcon /></button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">From</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        onChange={(date) => this.handleDateChange("customFirstDate", date)}
                        //disabledDate={this.disabledDate}
                        name="customFirstDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={this.state.customFirstDate}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">To</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        onChange={(date) => this.handleDateChange("customEndDate", date)}
                        //disabledDate={this.disabledDate}
                        name="customEndDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={this.state.customEndDate}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <button type="button" className="btn btn__btn btn__lg btn-dark w__100" onClick={() => this.submitCustomDate()}>Save</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    )
  }
}
