import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class StrategistReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
    }
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.strategist_arr = this.state.client_strategist
      this.props.getStrategistList(strData)
      this.setState({isLoadingData: true})
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.strategistPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount() {
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ strategistDropdown: false });
        }
      }
    });
  }

  handleFromDate(date) {
    this.setState({ isLoadingData: true })
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(function(e){
      const strData = {}
      strData.from = selectDate
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.dateType = "from_date"
      strData.strategist_arr = this.state.client_strategist
      this.props.getStrategistList(strData)
    }.bind(this), 300)
    this.setState({fromDate: date})
  }

  handEndDate(date){
    this.setState({ isLoadingData: true })
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(function(e){
      const strData = {}
      strData.to = selectDate
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.dateType = "end_date"
      strData.strategist_arr = this.state.client_strategist
      this.props.getStrategistList(strData)
    }.bind(this), 300)
    this.setState({endDate: date})
  }

  toggledropDown(val){
    if(val==='filterStrategist'){
      this.setState({strategistDropdown: !this.state.strategistDropdown })
    }
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status , client_suburb } = this.state;
    if(type === 'strategistFilter'){
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } 
      else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_strategist : client_strategist })
    const strData = {}
    strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    strData.strategist_arr = client_strategist
    this.props.getStrategistList(strData)
  }


  render() {
    const { strategyData, filterParams } = this.props
    let allData = filterParams && filterParams.data;
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Strategist Reporting</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{(strategyData && strategyData.total_count) ? strategyData.total_count : 0 }</span> Strategist</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">
            <div className="dp-wrapper stragist-span">
             
              <div id="dropdownBtn" className="custom-select custom-sel-filter custom-sel-filter-new" onClick={this.toggledropDown.bind(this, 'filterStrategist')}>All Strategist</div>
              {this.state.strategistDropdown && 
                <div id="dropdownBtn" className= "sel-outer">
                  {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                   let selectedIndex = false;
                    _.find(this.state.client_strategist, function(o) {
                      if(o === strategistsval.id){
                        selectedIndex = true;
                      } 
                   });
                   return (
                    <li key={strategistsindx} className="custom-select-option">
                      <span className="coverage__checkbox">
                        <label className="custom-checkbox">
                        {strategistsval.strategist_name}
                        <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id, 'strategistFilter')}/>
                          <span className="checkmark"></span>
                        </label>
                      </span>
                    </li>
                   )
                  })}
                </div>
              }
              
            </div>
              <div className="dp-wrapper dp-wrapper-edit">
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dp-wrapper dp-wrapper-edit">
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>              
            <ExcelFile element={<button className="reporting__btn">Export Data</button>}>
              <ExcelSheet data={strategyData.data} name="Agent_Report">
                  <ExcelColumn label="Strategist Name" value="full_name"/>
                  <ExcelColumn label="Assigned Clients" value="assigned_clients"/>
                  <ExcelColumn label="Strategies Sent" value="strategies_sent"/>
                  <ExcelColumn label="Call Scheduled" value="call_scheduled"/>
                  <ExcelColumn label="Dead Deals" value="dead_deal"/>
                  <ExcelColumn label="Closed Clients" value="closed_clients"/>
              </ExcelSheet>
            </ExcelFile>
            </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
            <div className="filter__container filter__container--users desktopFilter">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list useslist__extra">
                   <div className="table-responsive">
                     <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Strategist Name</th>
                            <th className="text-center">Assigned Clients</th>
                            <th className="text-center">Strategies Sent</th>
                            <th className="text-center">Call Scheduled</th>
                            <th className="text-center">Dead Deals</th>
                            <th className="text-center">Closed Clients</th>
                          </tr>
                        </thead>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="9" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your Strategist report is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                        {
                          strategyData && strategyData.data && strategyData.data.map((str, index)=>{
                            return(
                              <tr key={index} className="forcursor" >
                              <td>{str.full_name}</td>
                                <td className="text-center">{str.assigned_clients}</td>
                                <td className="text-center">{str.strategies_sent}</td>
                                <td className="text-center">{str.call_scheduled}</td>
                                <td className="text-center">{str.dead_deal}</td>
                                <td className="text-center">{str.closed_clients}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                     </div>
                   </div>
                 </div>
               </div>
              </main>

              {
                strategyData && strategyData.data && strategyData.data.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler -Strategist Reporting</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'strategistreportlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategistReport)
