import { fetch } from '../../../utils'
import qs from 'qs'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
     ///////////////////////
   /// town apist ///
////////////////////////
 */
// fetch notes of client using clientId
export const fetchTownResearch = (data) => {
  const qsQueryString = qs.stringify(data)
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town/${data.townId}/town-research?${qsQueryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch notes of client using clientId
export const updateTownResearch = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town/${data.townId}/town-research`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// update school town data order
export const updateSchoolOrder = (data) => {
  return fetch(`${HOSTNAME_V2}/update_school`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
