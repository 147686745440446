import React, { PureComponent /*, PropTypes*/ } from 'react';
import ReactModal from 'react-modal';
import { Spin, notification } from 'antd';
import { CloseIcon } from '../../../../../../components/icons';
import axios from 'axios';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { ToastContainer, toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import Loader from '../../../../../../components/Loader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import EditField from './EditFieldValue';
import { occupationList, landSizes, budget, fancyLaidBack, homeType, progressiveConservative, sportyArtsy, goodForFinding } from "../../townKeys"
// import County from './countiesList';

import './styles.scss';
import { logoutInvalidRequest } from '../../../../../../utils/helper';
import { fetch } from '../../../../../../utils'

const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

let quickFactsFieldsAr = [
  'diversity',
  'taxes',
  'urban_suburban',
  'type_a',
  'walkable',
  'sahm_wohm_mix',
  // ar
  'blue_collar',
  'finance_legal',
  'medical',
  'legal',
  'entrepreneur_self_employed',
  'tech',
  'sales_marketing',
  'creative',
  'retail',
  'education',
  'government',
  'nonprofit',
  'nice_mix_of_all',
  'land_up_to_1_4_acre',
  'land_up_to_1_2_acre',
  'land_up_to_1_acre',
  'land_up_to_1_plus',
  'large_acreage',
  'single_family',
  'townhome',
  'condo',
  'price_under_500k',
  'price_500k_1mill',
  'price_1_1_5mill',
  'price_1_5_2mill',
  'price_2_3mill',
  'price_3mill_plus',
  'fancy',
  'laid_back',
  'progressive',
  'conservative',
  'town_personality_sporty',
  'town_personality_artsy',
  'childcare_live_in_non_driver',
  'childcare_live_in_driver',
  'childcare_live_out_driver',
  'childcare_live_out_non_driver',
  'childcare_pt_sitter',
  'childcare_au_pair',
  'childcare_none_parents',
  'childcare_daycare'
  // 'county'
];

const quickFactsFieldsAttomAr = [
  'population',
  'avg_age',
  'avg_household_income',
  'tax_avg_percentage',
  'avg_home_sale_price'
];


const RadioInputField = props => {
  return (
    <Field
      {...props}
      checked={
        get(props.values, props.name, props.noval) === props.valueon
          ? true
          : false
      }
      onChange={e =>
        props.toggleradiofields(
          props.name,
          props.valueon,
          props.values,
          props.setfieldvalue
        )
      }
      className="custom__radiobox__input"
    />
  );
};

const CheckboxInputField = props => {
  return (
    <Field
      {...props}
      checked={get(props, `values.${props.name}`, 0) === 1 ? true : false}
      onChange={e => {
        props.setfieldvalue(props.name, e.target.checked ? 1 : 0);

        if (props.name === 'none') {
          //uncheck all good for finding if its none
          goodForFinding.map((g, i) => {
            props.setfieldvalue(g.value, 0);
          });

          props.setfieldvalue('none', 1);
        }

        if (props.name === 'nice_mix_of_all') {
          // check all fields if its a nice mix of all
          occupationList.map((g, i) => {
            props.setfieldvalue(g.value, e.target.checked ? 1 : 0);
          });
        }
      }}
    />
  );
};

export default class QuickFactsComponent extends PureComponent {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      editAttomData: false,
      quickFacts: [],
      aiData: {},
      quickRacial: [],
      isLoading: false,
      areaCodes: [],
      attomQuickFacts: {},
      textAvgPer: 0,
      population: 0,
      avgAge: 0,
      avgHomeSale: 0,
      avgHouseHold: 0,
      avgTaxRate: 0,
      modalIsOpen: false,
      edit_population: 0,
      edit_avg_age: 0,
      edit_avg_home_sp: 0,
      edit_avg_house_income: 0,
      edit_white: 0,
      edit_hispanic: 0,
      edit_asian: 0,
      edit_black: 0,
      edit_other: 0,
      edit_avg_tax_rate: 0,
      edit_avg_tax_rate: 0,
      attomCommunityGeoId: 0,
      attomSalesTrendGeoId: 0
    };
    this.openEditAttomDataModal = this.openEditAttomDataModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const {
      user,
      match: {
        params: { townId }
      },
      communityDataByTownId
    } = this.props;

    this.setState({ isLoading: true, aiData: this.props.aiData });
    const data1 = {
      town_id: townId
    };
    communityDataByTownId(data1);
    this.getAttomAreaCodes();
    }

  number_format(x) {
    // eslint-disable-line camelcase
    if (!x) return ''

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatRacialDemographicData(data) {
    const dataStr = this.number_format(data)

    // eslint-disable-next-line no-magic-numbers
    return dataStr.includes(".") ? `${dataStr} %` : dataStr
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted) {
      const np = this.props;
      const {
        match: {
          params: { townId }
        },
        communityDataByTownId
      } = prevProps;
      if (np.communityPhase !== prevProps.communityPhase) {
        if (np.communityData) {
          this.props.toggleLoading(false);
          const data = np.communityData;
          // this.attomChange({ target: { value: get(data, 'attom_geo_id') } });
          this.setState({
            quickFacts: data,
            isLoading: false,
            attomQuickFacts: data,
            population: data.population,
            avgAge: data.avg_age,
            avgHomeSale: data.avg_home_sale_price,
            avgHouseHold: data.avg_household_income,
            avgTaxRate: data.tax_avg_percentage,
            quickRacial: data.racial,
            edit_avg_tax_rate: data.tax_avg_percentage
          });
        }
      }
      if (np.editCommunityPhase === 'success') {
        np.clearTownPhase();
        const data1 = { town_id: townId };
        communityDataByTownId(data1);
        this.props.toggleLoading(true);
        this.setState({ isLoading: true });
      }
      if (np.synchCommunityPhase === 'success') {
        np.clearTownPhase();
        const data1 = { town_id: townId };
        notification.success({
          message: 'Attom data updated succesfully'
        });
        communityDataByTownId(data1);
        this.props.toggleLoading(true);
        this.setState({ editAttomData: false });
      }
      if (np.synchCommunityPhase === 'error') {
        np.clearTownPhase();
        this.props.toggleLoading(false);
        notification.error({
          message: 'An error occured'
        });
        this.setState({ editAttomData: false });
      }
    }
  }

  syncBtn = obj => {
    const {
      match: {
        params: { townId }
      },
      synchCommunity
    } = this.props;
    const { attomQuickFacts, attomCommunityGeoId } = this.state;

    if (attomCommunityGeoId && attomCommunityGeoId !== 0) {
      this.setState({ isLoading: true });
      attomQuickFacts.townId = parseInt(townId);
      attomQuickFacts.attomCommunityGeoId = this.state.attomCommunityGeoId;

      if (obj && !isEmpty(obj)) {
        Object.keys(obj).map((k, i) => {
          attomQuickFacts[k] = obj[k];
        });
      }

      synchCommunity({
        townId,
        data: attomQuickFacts
      });
      this.setState({ isLoading: false });
    }
  };

  get_attom_field_value = (data, field_label, geo) => {
    let value = '';
    const filtered = data.filter(o => {
      return o.field_label === field_label;
    });
    forEach(filtered, val => {
      if (field_label === 'geocodinglatitude') {
        value = val.lat;
      } else if (field_label === 'geocodinglongitude') {
        value = val.lng;
      } else if (geo === 'geo') {
        value = val.attom_geo_id;
      } else {
        value = val.field_value;
      }
    });
    return value;
  };


  async getAttomAreaCodes(e) {
    this.props.toggleLoading(true);

    this.setState({ isLoading: true });
    const {
      user,
      match: {
        params: { townId }
      },
      communityDataByTownId
    } = this.props;

    const fetchAttomData = () => fetch(
        `${SUBURBANJUNGLE_API_HOST}/towns/${townId}/attom/geoList`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => {
          return res.json();
        })
        .catch((err) => console.log(err))

    const geoListData = await fetchAttomData()

    // The spinner for this component does not get disabled
    // unless raw is a resolved promise.  Go figure.

    const raw = await Promise.resolve(geoListData)

    if (raw && raw.data) {
      const { communityAreaTypes, salesTrendAreaType } = raw.data
      const areaCodes = communityAreaTypes
      this.props.toggleLoading(false);
      this.setState({
        isLoading: false,
        areaCodes
      });

      if (salesTrendAreaType) {
        this.setState({
          attomSalesTrendGeoId: salesTrendAreaType.id
        });
      }

      if (this.props.communityData.attom_geo_id_v4) {
        const selectedAttomArea = communityAreaTypes.find(area => area.id === this.props.communityData.attom_geo_id_v4)
        if (selectedAttomArea) {
          this.setState({
            attomCommunityGeoId: selectedAttomArea.id
          });
        }
      }
    }
  }


  async attomChange(e) {
    this.setState({ isLoading: true });

    const {
      match: {
        params: { townId }
      }
    } = this.props;

    if (e.target.value) {
      const attomCommunityGeoId = e.target.value;

      const selectedCommunityAreaType = this.state.areaCodes.find(areaCode => areaCode.id === attomCommunityGeoId)

      const attomSalesTrendGeoId = selectedCommunityAreaType && ['N1', 'N2', 'N3'].includes(selectedCommunityAreaType.type) ? attomCommunityGeoId : this.state.attomSalesTrendGeoId

      const fetchAttomData = () => fetch(
        `${SUBURBANJUNGLE_API_HOST}/towns/${townId}/attom/community?attomCommunityGeoId=${attomCommunityGeoId}&attomSalesTrendGeoId=${attomSalesTrendGeoId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => {
          return res.json();
        })
        .catch((err) => console.log(err))

      const raw = await fetchAttomData();
      const communityData = raw && raw.data;
      if (communityData) {
        if (communityData.avg_prop_tax && communityData.avgsaleprice) {
          let textAvgPer = communityData.avg_prop_tax / communityData.avgsaleprice;
          textAvgPer = (parseFloat(textAvgPer) * 100).toFixed(2);
          this.setState({
            textAvgPer
          });
        }
        this.props.toggleLoading(false);

        this.setState({
          isLoading: false,
          attomQuickFacts: communityData,
          attomCommunityGeoId
        });
      } else {
        this.props.toggleLoading(false);

        this.setState({ isLoading: false, attomCommunityGeoId: 0 }, () => {
          toast('This Attom Data Not Found', { autoClose: true });
        });
      }
    }
  }

  openEditAttomDataModal() {
    this.setState({ editAttomData: true });
  }
  closeModal() {
    this.setState({ editAttomData: false });
  }

  parseQuickFacts(quick_facts) {
    // eslint-disable-line camelcase
    const obj = {};
    // obj.population = this.number_format(
    //   this.get_attom_field_value(quick_facts, 'poppy_10')
    // );

    obj.population = this.get_attom_field_value(quick_facts, 'poppy_10');
    obj.population_formatted = this.number_format(
      this.get_attom_field_value(quick_facts, 'poppy_10')
    );

    const racial_breakdown = []; // eslint-disable-line camelcase

    const white = this.get_attom_field_value(quick_facts, 'racewhitepy_5');
    racial_breakdown.push({
      racial_label: 'White',
      racial_count: this.number_format(white)
    });

    const hispanic = this.get_attom_field_value(quick_facts, 'racehisppy_5');
    racial_breakdown.push({
      racial_label: 'Hispanic',
      racial_count: this.number_format(hispanic)
    });

    const asian = this.get_attom_field_value(quick_facts, 'raceasianpy_5');
    racial_breakdown.push({
      racial_label: 'Asian',
      racial_count: this.number_format(asian)
    });

    const black = this.get_attom_field_value(quick_facts, 'raceblackpy_5');
    racial_breakdown.push({
      racial_label: 'Black',
      racial_count: this.number_format(black)
    });
    const other = this.get_attom_field_value(quick_facts, 'raceotherpy_5');
    racial_breakdown.push({
      racial_label: 'Other',
      racial_count: this.number_format(other)
    });
    obj.racial = racial_breakdown; // eslint-disable-line camelcase

    obj.avg_home_sale_price = this.number_format(
      this.get_attom_field_value(quick_facts, 'avgSalePrice')
    );

    obj.avg_household_income = this.number_format(
      this.get_attom_field_value(quick_facts, 'incavehhpy_5')
    );

    obj.avg_age = this.get_attom_field_value(quick_facts, 'ageavepy_10');

    obj.assessed_home_value = '3,000,000';

    obj.safety_crime = this.number_format(
      this.get_attom_field_value(quick_facts, 'crmcytotc')
    );

    // if (this.state.suburb_id === 2) {
    //   const correctedAvgPropertyTax = parseInt(parseFloat(obj.avg_home_sale_price.replace(/,/g, '')) * 0.012)
    //   obj.average_property_tax_amount = this.number_format(correctedAvgPropertyTax)
    // } else {
    obj.average_property_tax_amount = this.number_format(
      this.get_attom_field_value(quick_facts, 'avg_prop_tax')
    );
    // }

    if (
      obj.avg_home_sale_price &&
      parseInt(obj.avg_home_sale_price) > 0 &&
      obj.average_property_tax_amount &&
      parseInt(obj.average_property_tax_amount) > 0
    ) {
      const tax_avg_percentage =
        parseFloat(obj.average_property_tax_amount.replace(/,/g, '')) /
        parseFloat(obj.avg_home_sale_price.replace(/,/g, '')); // eslint-disable-line camelcase
      obj.tax_avg_percentage = (parseFloat(tax_avg_percentage) * 100).toFixed(
        2
      );
    }

    obj.attom_geo_id = this.get_attom_field_value(
      quick_facts,
      'poppy_10',
      'geo'
    );
    return obj;
  }

  setEditModeOn = (setFieldValue, values, key) => {
    setFieldValue(key, !get(values, key));
  };

  setEditModeValue = (setFieldValue, value, key) => {
    setFieldValue(key, value);
  };

  updateDemographicFields = (setFieldValue, values) => {
    const initialValues = this.state.quickRacial;
    const syncObj = this.state.attomQuickFacts;

    initialValues.map((r, i) => {
      const labelName = `racial_${get(r, 'racial_label')}`;

      if (get(values, labelName)) {
        r.racial_count = get(values, labelName);
        syncObj[`race${get(r, 'racial_label').toLowerCase()}py_5`] = String(
          r.racial_count
        );
      }
    });

    this.syncBtn(syncObj);
    this.setState({ quickRacial: initialValues });
    this.setEditModeOn(setFieldValue, values, 'isEditDemographic');
  };

  toggleRadioFields = (key, val, values, setFieldValue) => {
    if (values[key] === val) {
      setFieldValue(key, -1);
    } else {
      setFieldValue(key, val);
    }

    this.forceUpdate();
  };

  saveQuickFacts = async values => {
    const obj = {};
    const attomData = {};
    const demoObj = {};

    // Filter only the form submitted values
    quickFactsFieldsAr.map((k, i) => {
      if (!isNil(get(values, k))) {
        obj[k] = get(values, k);
      }
    });

    const customFields = [];

    // Set custom fields
    // let setCustomFields = Object.keys(obj).map((c, i) => {
    //   let cObj = {};
    //   if (!isNil(obj[c])) {
    //     cObj.key = c;
    //     cObj.value = obj[c];
    //     customFields.push(cObj);
    //   }
    // });
    // await Promise.all(setCustomFields);

    quickFactsFieldsAttomAr.map((t, i) => {
      if (values[t]) {
        const unformatted = values[t].replace(/,/g, '');

        attomData[t] = Number(unformatted);
      }
    });
    this.state.quickRacial.map((r, i) => {
      const unformatted = r.racial_count.replace(/,/g, '');

      demoObj[get(r, 'racial_label').toLowerCase()] = Number(unformatted);
    });

    attomData.demographics = demoObj;

    // let finalObj = { attomData, customFields };

    this.props.updateAiData(obj);
  };

  getAvarageTaxRate = (avgPropTax, avgSalePrice) => {
    if (!avgPropTax || !avgSalePrice) return '-'

    return (parseFloat(avgPropTax / avgSalePrice) * 100).toFixed(2) + '%'
  }

  render() {
    const {
      quickFacts,
      quickRacial,
      areaCodes,
      attomQuickFacts,
      isLoading,
      aiData
    } = this.state;

    return (
      <div>
        <ToastContainer autoClose={3000} />
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              isPopulationEdit: false,
              isEditDemographic: false,
              isEditAverageAge: false,
              isEditAvgHouseholdIncome: false,
              isEditAvgTaxRate: false,
              isEditAvgHomeSalePrice: false,
              ...quickFacts,
              // county: -1,
              quickRacial,
              diversity: -1,
              taxes: -1,
              urban_suburban: -1,
              type_a: -1,
              walkable: -1,
              sahm_wohm_mix: -1,
              ...this.props.aiData
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.saveQuickFacts(values);
            }}
          >
            {({ values, errors, isSubmitting, setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-md-12 text-right mb__15">
                    <button
                      type="button"
                      className="btn btn__btn btn-dark w__180"
                      onClick={this.openEditAttomDataModal}
                    >
                      Edit Source
                    </button>
                  </div>

                </div>
                {/*
                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">County/Region</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="material-textfield-label label-lg">
                            Select County
                          </label>
                          <Field
                            name="county"
                            as="select"
                            className="form-control custom__select material-textfield-input textfield-input-lg"
                            required
                          >
                            <option value selected disabled>Select</option>
                            {values && values.city && County && County && County[0] && County[0][values.city] && County[0][values.city].map((key, value) => {
                              return (<option value={key.value}>{key.value}</option>)
                            })
                            }
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Demographics </h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          {get(values, 'isPopulationEdit', false) ? (
                            <>
                              <EditField
                                values={values}
                                attomKey={'poppy_10'}
                                syncBtn={this.syncBtn}
                                setfieldvalue={setFieldValue}
                                setEditModeOn={this.setEditModeOn}
                                toggleKey={'isPopulationEdit'}
                                mainVal={'population'}
                                label="Population"
                                initVal={'populationEditVal'}
                              />
                            </>
                          ) : (
                            <>
                              <label className="label__text__sm">
                                Population
                              </label>

                              <p className="text__desciption">
                                {this.number_format(
                                  get(this.props.communityData, 'poppy_10', '')
                                )}
                              </p>


                              {/*
                              <div className="info__block__wrap__edit">
                                <button
                                  onClick={() =>
                                    this.setEditModeOn(
                                      setFieldValue,
                                      values,
                                      'isPopulationEdit'
                                    )
                                  }
                                  type="button"
                                  className="btn btn__link"
                                >
                                  Edit
                                </button>
                              </div>
                              */}

                            </>
                          )}
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            View Demographic makeup
                          </label>
                          <p className="text__desciption">
                            - White:{' '}
                            {this.props.communityData.racewhitepy_5 && `${this.formatRacialDemographicData(get(this.props.communityData, 'racewhitepy_5', ''))}`}
                          </p>
                          <p className="text__desciption">
                            - Hispanic:{' '}
                            {this.props.communityData.racewhitepy_5 && `${this.formatRacialDemographicData(get(this.props.communityData, 'racehisppy_5', ''))}`}
                          </p>
                          <p className="text__desciption">
                            - Asian:{' '}
                            {this.props.communityData.racewhitepy_5 && `${this.formatRacialDemographicData(get(this.props.communityData, 'raceasianpy_5', ''))}`}
                          </p>
                          <p className="text__desciption">
                            - Black:{' '}
                            {this.props.communityData.racewhitepy_5 && `${this.formatRacialDemographicData(get(this.props.communityData, 'raceblackpy_5', ''))}`}
                          </p>
                          <p className="text__desciption">
                            - Other:{' '}
                            {this.props.communityData.racewhitepy_5 && `${this.formatRacialDemographicData(get(this.props.communityData, 'raceotherpy_5', ''))}`}
                          </p>


                          {/*


                          {!values.isEditDemographic &&
                            values.quickRacial &&
                            values.quickRacial.map((racial, id) => {
                              return (
                                <p key={id} className="text__desciption">
                                  - {racial.racial_label}: {racial.racial_count}
                                </p>
                              );
                            })}

                          {values.isEditDemographic &&
                            values.quickRacial &&
                            values.quickRacial.map((racial, id) => {
                              const labelName = `racial_${get(
                                racial,
                                'racial_label'
                              )}`;
                              return (
                                <>
                                  <div key={id} className="edit__data__inline">
                                    <div className="form-group material-textfield">
                                      <NumberFormat
                                        thousandSeparator={true}
                                        value={get(
                                          values,
                                          labelName,
                                          get(racial, 'racial_count')
                                        )}
                                        onValueChange={e =>
                                          setFieldValue(labelName, e.floatValue)
                                        }
                                        className="form-control material-textfield-input textfield-input-lg"
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        {get(racial, 'racial_label', '')}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            */}

                          {/*
                          {!values.isEditDemographic ? (

                            <div className="info__block__wrap__edit">
                              <button
                                onClick={() =>
                                  this.setEditModeOn(
                                    setFieldValue,
                                    values,
                                    'isEditDemographic'
                                  )
                                }
                                type="button"
                                className="btn btn__link"
                              >
                                Edit
                              </button>
                            </div>


                          ) : (
                            <div className="edit__data__inline__cta">
                              <button
                                onClick={() => {
                                  this.setEditModeOn(
                                    setFieldValue,
                                    values,
                                    'isEditDemographic'
                                  );
                                }}
                                type="button"
                                className="btn btn__btn btn-cancel"
                              >
                                Cancel
                              </button>

                              <button
                                onClick={() => {
                                  this.updateDemographicFields(
                                    setFieldValue,
                                    values
                                  );
                                }}
                                type="button"
                                className="btn btn__btn btn-dark btn-save"
                              >
                                Save
                              </button>
                            </div>
                          )}
                          */}
                        </div>
                        <hr className="small__hr" />


                        {/*

                        <div className="info__block__wrap">
                          <label className="label__text__sm">Diverse?</label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="diversity"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="diversity"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />


                        */}

                        <div className="info__block__wrap">
                          {get(values, 'isEditAverageAge', false) ? (
                            <>
                              <EditField
                                values={values}
                                syncBtn={this.syncBtn}
                                setfieldvalue={setFieldValue}
                                setEditModeOn={this.setEditModeOn}
                                toggleKey={'isEditAverageAge'}
                                mainVal={'avg_age'}
                                label="Average Age"
                                attomKey={'ageavepy_10'}
                                initVal={'avgAgeEditVal'}
                              />
                            </>
                          ) : (
                            <>
                              <label className="label__text__sm">
                                Average Age
                              </label>

                              <p className="text__desciption">
                                {get(this.props.communityData, 'ageavepy_10', '')}
                              </p>

                              {/*
                              <div className="info__block__wrap__edit">
                                <button
                                  onClick={() =>
                                    this.setEditModeOn(
                                      setFieldValue,
                                      values,
                                      'isEditAverageAge'
                                    )
                                  }
                                  type="button"
                                  className="btn btn__link"
                                >
                                  Edit
                                </button>
                              </div>
                              */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Income</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          {get(values, 'isEditAvgHouseholdIncome', false) ? (
                            <>
                              <EditField
                                values={values}
                                syncBtn={this.syncBtn}
                                attomKey={'incavehhpy_5'}
                                setfieldvalue={setFieldValue}
                                setEditModeOn={this.setEditModeOn}
                                toggleKey={'isEditAvgHouseholdIncome'}
                                mainVal={'avg_household_income'}
                                label="Average Household Income"
                                initVal={'avgHouseholdIncomeEditVal'}
                                prefix={'$'}
                              />
                            </>
                          ) : (
                            <>
                              <label className="label__text__sm">
                                Average Household Income
                              </label>
                              <p className="text__desciption">
                                $ {this.number_format(get(this.props.communityData, 'incavehhpy_5', ''))}
                              </p>


                              {/*
                              <div className="info__block__wrap__edit">
                                <button
                                  onClick={() =>
                                    this.setEditModeOn(
                                      setFieldValue,
                                      values,
                                      'isEditAvgHouseholdIncome'
                                    )
                                  }
                                  type="button"
                                  className="btn btn__link"
                                >
                                  Edit
                                </button>
                              </div>
                              */}
                            </>
                          )}
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          {get(values, 'isEditAvgTaxRate', false) ? (
                            <>
                              <EditField
                                values={values}
                                syncBtn={this.syncBtn}
                                setfieldvalue={setFieldValue}
                                setEditModeOn={this.setEditModeOn}
                                toggleKey={'isEditAvgTaxRate'}
                                mainVal={'tax_avg_percentage'}
                                label="Average Tax Rate"
                                initVal={'avgTaxPercentageEditVal'}
                                suffix={'%'}
                              />
                            </>
                          ) : (
                            <>
                              <label className="label__text__sm">
                                Average Tax Rate
                              </label>
                              <p className="text__desciption">
                                {this.getAvarageTaxRate(this.props.communityData.avg_prop_tax, this.props.communityData.avgsaleprice)}
                              </p>


                              {/*

                              <div className="info__block__wrap__edit">
                                <button
                                  onClick={() =>
                                    this.setEditModeOn(
                                      setFieldValue,
                                      values,
                                      'isEditAvgTaxRate'
                                    )
                                  }
                                  type="button"
                                  className="btn btn__link"
                                >
                                  Edit
                                </button>
                              </div>

                              */}

                            </>
                          )}
                        </div>


                        {/*
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Taxes?</label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                High
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'high'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Medium
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'medium'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Low
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'low'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Popular Occupations
                          </label>
                          <ul className="checkbox__list checkbox-list-grid-4">
                            {occupationList.map((o, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {o.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={o.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        */}


                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Homes</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          {get(values, 'isEditAvgHomeSalePrice', false) ? (
                            <>
                              <EditField
                                values={values}
                                syncBtn={this.syncBtn}
                                attomKey={'medsaleprice'}
                                setfieldvalue={setFieldValue}
                                setEditModeOn={this.setEditModeOn}
                                toggleKey={'isEditAvgHomeSalePrice'}
                                mainVal={'avg_home_sale_price'}
                                label="Median Home Sale Price"
                                initVal={'avgHomeSalePriceEditVal'}
                                prefix={'$'}
                              />
                            </>
                          ) : (
                            <>
                              <label className="label__text__sm">
                                Median Home Sale Price
                              </label>

                              <p className="text__desciption">
                                $ {this.number_format(get(this.props.communityData, 'medsaleprice', ''))}
                              </p>

                              {/*
                              <div className="info__block__wrap__edit">
                                <button
                                  onClick={() =>
                                    this.setEditModeOn(
                                      setFieldValue,
                                      values,
                                      'isEditAvgHomeSalePrice'
                                    )
                                  }
                                  type="button"
                                  className="btn btn__link"
                                >
                                  Edit
                                </button>
                              </div>
                              */}

                            </>
                          )}
                        </div>


                        {/*
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Land Size(s)
                          </label>
                          <ul className="checkbox__list vertical checkbox-list-grid-2">
                            {landSizes.map((l, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {l.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={l.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                      //required
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Home Type</label>
                          <ul className="checkbox__list vertical">
                            {homeType.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Budget</label>
                          <ul className="checkbox__list vertical checkbox-list-grid-2">
                            {budget.map((b, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {b.label}
                                    <CheckboxInputField
                                      name={b.value}
                                      type="checkbox"
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        */}


                      </div>
                    </div>
                  </div>
                </div>


                {/*
                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Other</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Feel of the town
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Suburban
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'suburban'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Urban
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'urban'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Country
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'country'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Fancy or laid back?{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {fancyLaidBack.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Type A folks?{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="type_a"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="type_a"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Walkable Neighborhood?{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="walkable"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="walkable"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Mom Preference{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                SAHM
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'sahm'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                WOHM
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'wohm'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Mix
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'mix'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Progressive v conservative{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {progressiveConservative.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Sporty/Artsy{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {sportyArtsy.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Good for finding{' '}
                          </label>
                          <ul className="checkbox__list checkbox-list-grid-3">
                            {goodForFinding.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn__btn btn-dark btn__lg w__100"
                    >
                      Save
                    </button>
                  </div>
                </div>
                */}

              </Form>
            )}
          </Formik>
          <ReactModal
            isOpen={this.state.editAttomData}
            onRequestClose={(e) => this.closeModal(e)}
            contentLabel="Change Attom Data "
            portalClassName="react-modal"
          >
            <Spin size="large" spinning={isLoading} indicator={<Loader />}>
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Edit Source</h5>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn react-modal-close"
                    onClick={this.closeModal.bind(this)}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <select
                      name="attomChange"
                      value={this.state.attomCommunityGeoId}
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                      onChange={e => this.attomChange(e)}
                      //required
                    >
                      <option value="0">Select</option>
                      {!isEmpty(areaCodes) &&
                        areaCodes.map((community, idx) => {
                          return (
                            <option key={idx} value={community.id}>
                              {community.name} - {community.typeDesc}
                            </option>
                          );
                        })}
                    </select>
                    <label className="material-textfield-label label-lg">
                      Attom Area Code
                    </label>
                  </div>
                  <div className="info__block">
                    <label>Population</label>
                    <p>
                      {this.number_format(
                        get(attomQuickFacts, 'poppy_10', ' - ')
                      )}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>View Demographic makeup</label>
                    <p>
                      - White:{' '}
                      {attomQuickFacts.racewhitepy_5 && `${this.formatRacialDemographicData(
                        get(attomQuickFacts, 'racewhitepy_5', ' - ')
                      ) }`}
                    </p>
                    <p>
                      - Hispanic:{' '}
                      {attomQuickFacts.racehisppy_5 && `${this.formatRacialDemographicData(
                        get(attomQuickFacts, 'racehisppy_5', ' - ')
                      ) }`}
                    </p>
                    <p>
                      - Asian:{' '}
                      {attomQuickFacts.raceasianpy_5 && `${this.formatRacialDemographicData(
                        get(attomQuickFacts, 'raceasianpy_5', ' - ')
                      ) }`}
                    </p>
                    <p>
                      - Black:{' '}
                      {attomQuickFacts.raceblackpy_5 && `${this.formatRacialDemographicData(
                        get(attomQuickFacts, 'raceblackpy_5', ' - ')
                      ) }`}
                    </p>
                    <p>
                      - Other:{' '}
                      {attomQuickFacts.raceotherpy_5 && `${this.formatRacialDemographicData(
                        get(attomQuickFacts, 'raceotherpy_5', ' - ')
                      ) }`}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>Average Age</label>
                    <p>{get(attomQuickFacts, 'ageavepy_10', ' - ')}</p>
                  </div>
                  <div className="info__block">
                    <label>Average Household Income</label>
                    <p>
                      {get(attomQuickFacts, 'incavehhpy_5', '')
                        ? `$${this.number_format(
                          get(attomQuickFacts, 'incavehhpy_5', ' - ')
                        )}`
                        : '- '}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>Average Tax Rate</label>
                    <p>
                      {this.getAvarageTaxRate(this.state.attomQuickFacts.avg_prop_tax, this.state.attomQuickFacts.avgsaleprice)}
                    </p>
                  </div>
                  <div className="info__block">
                    <label>Median Home Sale Price</label>
                    <p>
                      {get(attomQuickFacts, 'medsaleprice', '')
                        ? `$${this.number_format(
                          get(attomQuickFacts, 'medsaleprice', ' - ')
                        )}`
                        : '- '}
                    </p>
                  </div>
                  <button
                    onClick={() => this.syncBtn(attomQuickFacts)}
                    type="button"
                    disabled={isEmpty(attomQuickFacts) || isLoading}
                    className="btn btn__btn btn__lg btn-dark w__100"
                  >
                    Update
                  </button>
                </div>
              </div>
            </Spin>
          </ReactModal>
        </Spin>
      </div>
    );
  }
}
