import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss';
class ThankYouForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
        wpUrl: config.url.wpUrl,
        backendUrl:config.url.backendUrl,
        prevPath: window.localStorage.getItem('prev'),
        accessToken:window.localStorage.getItem('token'),
        clientToken:window.localStorage.getItem('clientToken')
    }
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps) {

  }

  render() {
    if(this.state.clientToken && this.state.clientToken !==null && this.state.prevPath =='/login' ){
      window.location.replace(`${this.state.backendUrl}/clients/client_strategy_token?token=${this.state.clientToken}`);
    }else{
    return (
        <div>
          <div className="thankyou__page">
            
            <header className="header-fix">
                <nav className="navbar navbar-default edit-header">
                    <div className="container">

                        <div className="navbar-header">
                            <div className="socialMenu">
                                <ul>
                                    <li>
                                        <a href="https://www.instagram.com/suburbanjunglerealty/"><img src={`${this.state.wpUrl}/wp-content/themes/suburbanjungle/images/Instagram-black.svg`} alt="img" /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/SuburbanJungleRealty/"><img src={`${this.state.wpUrl}/wp-content/themes/suburbanjungle/images/Facebook-black.svg`} alt="img" /></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/subjunglerealty"><img src={`${this.state.wpUrl}/wp-content/themes/suburbanjungle/images/Twitter-black.svg`} alt="img" /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="site-branding">
                                <a href={`${this.state.wpUrl}`} className="custom-logo-link" rel="home" itemProp="url">
                                    <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="custom-logo" alt="Suburban Jungle" itemProp="logo" srcSet={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png 502w, ${this.state.wpUrl}/wp-content/uploads/2018/03/logo-300x56.png 300w`} sizes="(max-width: 502px) 100vw, 502px" />
                                </a>
                            </div>

                            <div className="LogiSection">
                                <a href="/login"><button className="btn btn-login">Log In</button></a>
                                <a href="#"><button className="btn btn-get-started-header DefaultBtn">Get Started</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>

                        <div className="navbar-collapse nav-menu-style collapse" id="bs-example-navbar-collapse-1" aria-expanded="false" style={{"height": "0px"}}>
                            <div className="menu-top-menu-container">
                                <ul id="primary-menu" className="menu TopMainMenu">
                                    <li id="menu-item-184" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-184"><a href={`${this.state.wpUrl}/how-it-work/`}>How It Work</a></li>
                                    <li id="menu-item-208" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-208"><a href="http://54.210.165.23/explore-the-suburbs/">Explore The Suburbs</a>
                                        <ul className="sub-menu">
                                            <li id="menu-item-211" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-211"><a href={`${this.state.wpUrl}/neighborhoods/`}>New York</a></li>
                                            <li id="menu-item-209" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"><a href={`${this.state.wpUrl}/faq/`}>Boston</a></li>
                                            <li id="menu-item-209" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"><a href={`${this.state.wpUrl}/faq/`}>Chicago</a></li>
                                            <li id="menu-item-209" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"><a href={`${this.state.wpUrl}/faq/`}>Southern California</a></li>
                                            <li id="menu-item-209" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"><a href={`${this.state.wpUrl}/faq/`}>San Francisco</a></li>
                                            <li id="menu-item-209" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"><a href={`${this.state.wpUrl}/faq/`}>Dallas/Ft. Worth</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-207" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-207"><a href={`${this.state.wpUrl}/our-story/`}>Our Story</a></li>
                                    <li id="menu-item-206" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"><a href={`${this.state.wpUrl}/our-team/`}>Our Team</a></li>
                                    <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-205"><a href={`${this.state.wpUrl}/press/`}>Press</a></li>
                                    <li id="menu-item-204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-204"><a href={`${this.state.wpUrl}/blog/`}>Blog</a></li>
                                    <li id="menu-item-204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-204"><a href={`${this.state.wpUrl}/blog/`}>Testimonials</a></li>
                                    <li id="menu-item-203" className="OpenContactPopup menu-item menu-item-type-post_type menu-item-object-page menu-item-203"><a href={`${this.state.wpUrl}/contact-us/`}>Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="ThankSection content-pad">
                <div className="login-img">
                    <div className="container">
                        <div className="row">
                            <div className="thankyou-box">
                                <div className="thankyou">
                                    <h3>THANK YOU!</h3>
                                    <img src="/../../img/thankyou-icon.png"/>
                                    <p>
                                      Our team is looking forward to working with you in the Suburban Jungle!
                                    </p>
                                    {this.state.prevPath !== "/login" ?
                                      <a href={`${this.state.wpUrl}`}>
                                        <span className="">
                                            <button className="btn DefaultBtn-thankyou">Back to Home</button>
                                        </span>
                                      </a>
                                      :
                                      <a href={`${this.state.backendUrl}/clients/client_strategy_token?token=${this.state.clientToken}`}>
                                        <span className="thankyou-right">
                                            <button className="btn DefaultBtn-thankyou">Go to Dashboard</button>
                                        </span>
                                      </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
              <div className="container">

                <div className="footer-a">
                  <h3 className="widget-title">Company</h3>
                  <div className="bord-btm"></div>
                  <div className="clearfix"></div>
                  <p><a href={`${this.state.wpUrl}/how-it-work/`}>How it Works</a></p>
                  <p><a href={`${this.state.wpUrl}/suburbs-all`}>Suburbs</a></p>
                  <p><a href={`${this.state.wpUrl}/our-story`}>Our Story</a></p>
                  <p><a href={`${this.state.wpUrl}/our-team`}>Our Team</a></p>
                  <p><a href={`${this.state.wpUrl}/press-list`}>Press</a></p>
                </div>

                <div className="footer-b">
                  <h3 className="widget-title">Questions</h3>
                  <div className="bord-btm"></div>
                  <div className="clearfix"></div>
                  <p><a href={`${this.state.wpUrl}/faq`}>FAQ</a></p>
                  <p><a href={`${this.state.wpUrl}/testimonials`}>Testimonials</a></p>
                  <p><a href={`${this.state.wpUrl}/contact-us`}>Contact Us</a></p>
                </div>

                <div className="footer-c">
                  <h3 className="widget-title">Legal</h3>
                  <div className="bord-btm"></div>
                  <div className="clearfix"></div>
                  <p><a href={`${this.state.wpUrl}`}>Privacy &amp; Security</a></p>
                  <p><a href={`${this.state.wpUrl}`}> </a></p>
                </div>

                <div className="footer-d">
                  <h3 className="widget-title-reg">Keep Up with Suburban Jungle</h3>
                  <input name="EMAIL" placeholder="E-mail Address" className="yikes-easy-mc-email " required="required" type="email" value="" />
                  <input type="button" name=""/>

                  <div className="folow-us-widget-footer">
                      <ul>
                        <li><a href={`${this.state.wpUrl}/how-it-work/`} target="_blank"><img src="/../../img/LinkedIn-white.svg"/></a></li>
                        <li><a href={`${this.state.wpUrl}/how-it-work/`} target="_blank"><img src="/../../img/Facebook-white.svg"/></a></li>
                        <li><a href={`${this.state.wpUrl}/how-it-work/`} target="_blank"><img src="/../../img/Twitter-white.svg"/></a></li>
                      </ul>
                  </div>

                  <div className="footer-copyright">
                    Copyright 2018. Suburban Jungle Realty. All rights reserved.
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
    )
}
  }
}

export default reduxForm({
  form: 'thankyou',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ThankYouForm)
