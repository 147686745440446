import { fetch } from '../../../utils'
const qs = require('qs')

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
    /////////////////////////////////
  /// appointment apis ///
/////////////////////////////////
*/
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// fetch appointment list
export const fetchInterviews = (data) => {
  // set indicator in localStorage
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/agent-applications?${qs.stringify(data)}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// approve an agent
export const approveAgent = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/agent-applications/${data.user.agent_id}/approve-applicant`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data.user)
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// reject an agent
export const rejectAgent = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/agent-applications/${data.user.agent_id}/reject-applicant`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data.user)
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

