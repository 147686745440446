import { connect } from 'react-redux'

import {
  fetchClientDeal,
  clearDealSectionPhase,
} from '../../../../../store/strategistV2/deals/actions'

import DealInformationComponent from './component'
const DealInformationContainer = connect(
  // Map state to props
  (state) => ({
    clientDeals: state.strategistV2.deal.clientDeals,
    fetchClientDealPhase: state.strategistV2.deal.fetchClientDealPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientDeal,
    clearDealSectionPhase
  }
)(DealInformationComponent)

export default DealInformationContainer
