import React, { PureComponent /*, PropTypes*/ } from 'react'
// import IPropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Popover, Drawer } from 'antd'
import { get, upperFirst, size } from 'lodash'
import { NavbarIcon, SearchIcon, CloseIcon, BellIcon, DownCaretIcon, CalendarIcon, LeadsIcon } from '../../icons'
import Cookies from 'universal-cookie'

import './styles.scss'
import { SUCCESS } from '../../../constants/phase'
const cookies = new Cookies()
let user = ''

export default class AdministrationMobileTopNavigationComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mobileDrawer: false,
      searchIconClick: false
    }
    this.userLogout = this.userLogout.bind(this)
    return null
  }

  showDrawer = () => {
    this.setState({ mobileDrawer: true })
  }

  onClose = () => {
    this.setState({ mobileDrawer: false })
  }

  searchClick(){
    this.setState({  searchIconClick: !this.state.searchIconClick })
  }

  searchHandleChange = (e) => {
    if (size(e.target.value) > 2) {
      this.props.getSearchData(e.target.value)
      this.setState({ showSearchList: true })
    } else {
      this.setState({ showSearchList: false })
    }
  }

  onListClick(search) {
    this.setState({ showSearchList: false })
    if (search && search.role !== 'client') {
      this.props.history.push(`/strategist/edit-user/${search.id}`)
    } else {
      this.props.history.replace({
        pathname: `/strategist/client-details/${search.id}`,
        state: { fetchClient: true, clientId: search.id, prevRoute: get(this.props, 'history.location.pathname', '') }
      })
    }
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '' + match[1] + '.' + match[2] + '.' + match[3]
    }
    return null
  }

  userLogout(){
    this.props.userSignout()
  }

  render() {
    const { searchUserClientData, location } = this.props
    const pathname =  location && location.pathname
    return (
      <div>
        <div className="topnavigation__mobile">
          <div className="topnavigation__mobile__left">
            <div className="topnavigation__mobile__logo">
              <Link className="sidebar-item__link" to={'/administration/dashboard'}>
              {/*<Link className="sidebar-item__link" to={'/strategist/clients'}>*/}
                <img className="mobile-sidebar-resize" src="../img/logo.svg"/>
              </Link>
            </div>
          </div>
          <div className="topnavigation__mobile__right">
            <div className="topnavigation__mobile__inner">
              <button onClick={this.searchClick.bind(this)} className="btn btn__search"><SearchIcon/></button>
              <button onClick={this.showDrawer} className="btn btn__navbar"><NavbarIcon/></button>
            </div>
          </div>
        </div>
        <div className={this.state.searchIconClick ? "modal-search-box open" : "modal-search-box"}      id="search-box">
          <div className="d-flex align-items-center">
            <input
              className="form-control modal-search-box-input"
              placeholder="Search Clients"
              onChange={this.searchHandleChange}
            />
              <button onClick={this.searchClick.bind(this)} className="btn modal-search-box-button"><CloseIcon/></button>
          </div>
          {
           this.state.showSearchList &&
            <div className="search-wrapper-open-container">
              <ul className="search-wrapper-options-menu">
                {
                  searchUserClientData && searchUserClientData.map((search, idx)=>{
                    return(
                      <li className="search-wrapper-options-menu-item" onClick={(e)=> this.onListClick(search)} key={idx}>
                        <Link to={'/'}>
                          <div className="options-menu-item-title">
                            <span className="avatar">
                            {
                              search.avatar ?
                                <img src={search.avatar}/>
                              :
                              <img src="../img/img_v2/user-icon.png"/>
                            }
                            </span>
                            <span className="name">
                              {search.first_name+' '+search.last_name} <span className="text__transform">({search.role})</span>
                            </span>
                            <div className="email">{search.email ? search.email : ''}</div>
                            <div  className="phone">{search.phone_mobile ? (search.phone_mobile.replace(/\D/g, '').length == 10) ? this.formatNumber(search.phone_mobile.replace(/\D/g, '')) : search.phone_mobile.replace(/\D/g, '') : ''}</div>
                          </div>
                        </Link>
                      </li>
                    )}
                  )
                }
              </ul>
            </div>
          }
        </div>

        {/* Side drawer */}
        <Drawer
          width="320"
          closable={true}
          onClose={this.onClose}
          visible={this.state.mobileDrawer}
        >
          <ul className="administration__mob__nav__list">
            <li className={ pathname === '/administration/dashboard' ? "active" : ''}>
              <Link to={'/administration/dashboard'}> Dashboard </Link>
            </li>
            <li className={ pathname === '/strategist/clients' ? "active" : ''}>
              <Link to={'/strategist/clients'}>Clients </Link>
            </li>
            <li className={ pathname === '/strategist/task-list' ? "active" : ''}>
              <Link to={'/strategist/task-list'}>Task List</Link>
            </li>
            {/*<li className={ pathname === '/strategist/your-agents' ? "active" : ''}>*/}
            {/*  <Link to={'/strategist/your-agents'}>Your Agents</Link>*/}
            {/*</li>*/}
           {/*  <li>
              <Link to={'/strategist/town-cheat-sheet'}>Town Cheat Sheet</Link>
            </li> */}
            {/*<li className={ pathname === '/strategist/agent-appointments' ? "active" : ''}>*/}
            {/*  <Link to={'/strategist/agent-appointments'}>Agent Appointments</Link>*/}
            {/*</li>*/}
            <li className={ pathname === '/strategist/town-reports' ? "active" : ''}>
              <Link to={'/strategist/town-reports'}>Towns</Link>
            </li>
            {/*<li className={ pathname === '/strategist/jungler-users' ? "active" : ''}>*/}
            {/*  <Link to={'/strategist/jungler-users'}>Jungler Users</Link>*/}
            {/*</li>*/}
            {/*<li className={ pathname === '/administration/update-profile' ? "active" : ''}>*/}
            {/*  <Link to={'/administration/update-profile'}>Edit Profile</Link>*/}
            {/*</li>*/}
            <li onClick={() => this.userLogout()}>Logout</li>
          </ul>
        </Drawer>
      </div>
    )
  }
}
