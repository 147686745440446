import React from 'react'
import ReactModal from 'react-modal'

import { CloseIcon } from '../../../../../../components/icons'

export default function RemoveComment({
  openModel,
  commentId,
  closeDeleteCommentModal,
  ...props
}) {
  // submit or delete client text message
  const handelDeleteSubmission = () => {
    if (commentId) {
      props.deleteComment({ commentId })
      closeDeleteCommentModal()
    }
  }
  return (
    <ReactModal
      isOpen={openModel}
      contentLabel="Delete User"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-body react-modal-body-sm">
          <button
            type="button"
            className="btn react-modal-close react-modal-close-sm"
            onClick={closeDeleteCommentModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title mb__13">Are you sure?</h5>
          <p className="react-modal-subtitle mb__3">
                You want to delete this comment/note ?
          </p>
          <div className="text-right">
            <button
              onClick={handelDeleteSubmission}
              className="btn btn__btn btn-dark w__150"
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
