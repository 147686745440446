export const FETCH_PROSPECT_APPLICANTS_LIST = 'suburban/strategistV2/interview/FETCH_PROSPECT_APPLICANTS_LIST'
export const FETCH_PROSPECT_APPLICANTS_LIST_SUCCESS =
    'suburban/strategistV2/interview/FETCH_PROSPECT_APPLICANTS_LIST_SUCCESS'
export const FETCH_PROSPECT_APPLICANTS_LIST_ERROR =
    'suburban/strategistV2/interview/FETCH_PROSPECT_APPLICANTS_LIST_ERROR'

export const FETCH_REJECTED_APPLICANTS_LIST = 'suburban/strategistV2/interview/FETCH_REJECTED_APPLICANTS_LIST'
export const FETCH_REJECTED_APPLICANTS_LIST_SUCCESS =
      'suburban/strategistV2/interview/FETCH_REJECTED_APPLICANTS_LIST_SUCCESS'
export const FETCH_REJECTED_APPLICANTS_LIST_ERROR =
      'suburban/strategistV2/interview/FETCH_REJECTED_APPLICANTS_LIST_ERROR'
    
export const APPLICANT_APPROVED = 'suburban/strategistV2/interview/APPLICANT_APPROVED'
export const APPLICANT_APPROVED_SUCCESS =
  'suburban/strategistV2/interview/APPLICANT_APPROVED_SUCCESS'
export const APPLICANT_APPROVED_ERROR =
  'suburban/strategistV2/interview/APPLICANT_APPROVED_ERROR'


export const APPLICANT_REJECTED = 'suburban/strategistV2/interview/APPLICANT_REJECTED'
export const APPLICANT_REJECTED_SUCCESS =
  'suburban/strategistV2/interview/APPLICANT_REJECTED_SUCCESS'
export const APPLICANT_REJECTED_ERROR =
  'suburban/strategistV2/interview/APPLICANT_REJECTED_ERROR'
  