export const GET_AGENT_DASHBOARD = 'suburban/agentV2/GET_AGENT_DASHBOARD'
export const GET_AGENT_DASHBOARD_SUCCESS = 'suburban/agentV2/GET_AGENT_DASHBOARD_SUCCESS'
export const GET_AGENT_DASHBOARD_ERROR = 'suburban/agentV2/GET_AGENT_DASHBOARD_ERROR'
export const GET_AGENT_DASHBOARD_CANCELLED = 'suburban/agentV2/GET_AGENT_DASHBOARD_CANCELLED'

export const GET_NOT_TOUCHED_DATA = 'suburban/agentV2/GET_NOT_TOUCHED_DATA'
export const GET_NOT_TOUCHED_DATA_SUCCESS = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_SUCCESS'
export const GET_NOT_TOUCHED_DATA_ERROR = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_ERROR'
export const GET_NOT_TOUCHED_DATA_CANCELLED = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_CANCELLED'

export const GET_NOT_TOUCHED_IN_4WEEK = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK'
export const GET_NOT_TOUCHED_IN_4WEEK_SUCCESS = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_SUCCESS'
export const GET_NOT_TOUCHED_IN_4WEEK_ERROR = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_ERROR'
export const GET_NOT_TOUCHED_IN_4WEEK_CANCELLED =
  'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_CANCELLED'

export const FETCH_AGENT_ALL_CLIENT = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT'
export const FETCH_AGENT_ALL_CLIENT_SUCCESS = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT_SUCCESS'
export const FETCH_AGENT_ALL_CLIENT_ERROR = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT_ERROR'

export const EDIT_CLIENT_CHAT = 'suburban/agentV2/EDIT_CLIENT_CHAT'
export const EDIT_CLIENT_CHAT_SUCCESS = 'suburban/agentV2/EDIT_CLIENT_CHAT_SUCCESS'
export const EDIT_CLIENT_CHAT_ERROR = 'suburban/agentV2/EDIT_CLIENT_CHAT_ERROR'

export const DELETE_CHAT_DATA = 'suburban/agentV2/DELETE_CHAT_DATA'
export const DELETE_CHAT_DATA_SUCCESS = 'suburban/agentV2/DELETE_CHAT_DATA_SUCCESS'
export const DELETE_CHAT_DATA_ERROR = 'suburban/agentV2/DELETE_CHAT_DATA_ERROR'

export const EDIT_STRATEGIST_AGENT_NOTE = 'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE'
export const EDIT_STRATEGIST_AGENT_NOTE_SUCCESS =
  'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE_SUCCESS'
export const EDIT_STRATEGIST_AGENT_NOTE_ERROR = 'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE_ERROR'

export const DELETE_STRATEGIST_AGENT_NOTE = 'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE'
export const DELETE_STRATEGIST_AGENT_NOTE_SUCCESS =
  'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE_SUCCESS'
export const DELETE_STRATEGIST_AGENT_NOTE_ERROR =
  'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE_ERROR'

export const CLEAR_AGENT_PHASE = 'suburban/agent/CLEAR_AGENT_PHASE'
