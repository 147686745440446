import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
    ////////////////////////////////
  /// client details api ///
///////////////////////////////
 */
// fetch ranking using clientId
export const fetchRankingByClientId = (data) => {
  return fetch(`${HOSTNAME_V2}/client/get_client_town_rate_by_id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch client question by userId
export const fetchClientQuestions = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/${data.userId}/questions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch client unapproved recommended towns
export const fetchRecommendedTowns = (data) => {
  return fetch(
    `${HOSTNAME_V2}/user/get_unapproved_recommended_ai_towns/${data.clientId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  )
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// update approve recommended ai towns
export const setApproveRecommendedTowns = (data) => {
  return fetch(`${HOSTNAME_V2}/user/approve_recommended_ai_towns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// update approve recommended ai towns
export const addClientStrategistTimeSheet = (data) => {
  /*
  return fetch(`${HOSTNAME_V2}/user/add_client_strategist_timesheet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
     return payload
    })
    .catch((error) => {
      throw error
    })
  */
}
