import { connect } from 'react-redux'

import LinkInternalComponent from './component'

const LinkInternalContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(LinkInternalComponent)

export default LinkInternalContainer
