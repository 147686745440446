/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react';
import { Switch, notification } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { get, map, filter, compact } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import 'react-phone-input-2/dist/style.css';
import axios from 'axios'
import './styles.scss';
import TopNavigation from '../../../../components/TopNavigation/container';
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component';
import { BackArrowIcon, CameraIcon } from '../../../../components/icons';
import Validation from '../../../../config/validation';
import config from '../../../../config';
import SelectTown from './partials/SelectTown';
import SuburbsCheckboxList from './partials/SuburbsCheckboxList';
import PayoutAdditionalFields from './partials/PayoutAdditionalFields';
import MinMaxPrice from './partials/MinMaxPrice';
import SelectStrategist from './partials/SelectStrategist';
import { roleListOption, multiSelectStyles, initialValues, displayUserFormFieldForCurrentUserAndSelectedRole as displayFieldForRole } from './variables';
import { SUCCESS, ERROR } from '../../../../constants/phase';
import { createStringAvatar, logoutInvalidRequest } from '../../../../utils/helper';
import {fetch} from "../../../../utils";
const API_HOSTNAME = process.env.API_HOSTNAME;
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

const cookies = new Cookies();

export default class AddUserComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '+1',
      isLoading: false
    };
  }

  componentDidMount = () => this.props.getFilterParams();

  static getDerivedStateFromProps(props, state) {
    let states = { ...state };

    if (props.createJunglerUserPhase === SUCCESS) {
      if (get(props, 'junglerUserPhaseData.status')) {
        props.clearJunglerUserPhase();
        props.history.push('/strategist/jungler-users');
      }
      if (!get(props, 'junglerUserPhaseData.status')) {
        props.clearJunglerUserPhase();
        notification.error({
          message: get(props, 'junglerUserPhaseData.message')
        });
        states.isLoading = false
      }
    }

    if (props.createJunglerUserPhase === ERROR) {
      props.clearJunglerUserPhase();
      notification.error({
        message: 'An error occured'
      });
      states.isLoading = false;
    }
    return states;
  }

  submitData = async () => {
    const _user = cookies.get('user');

    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', _user.id);
    return await fetch(`${SUBURBANJUNGLE_API_HOST}/users/me/avatar`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(response => {
        if(response.status && response.data){
          return response;
        }
      }).catch(logoutInvalidRequest)
  };

  handleSubmit = async values => {
    const user = cookies.get('user');

    values.avatar = ''

    let img = ''
    if (this.state.file) {
      const res = await this.submitData();
      this.setState({ file: '', avatar: '' })
      if (res) {
        img = get(res, 'data', '');
      }
    }

    const data = {
      role: get(values, 'selectedRole', ''),
      first_name: get(values, 'firstName', ''),
      last_name: get(values, 'lastName', ''),
      description: get(values, 'bio', ''),
      email: get(values, 'email', ''),
      time_zone: get(values, 'timeZone', ''),
      avatar: img,
      country_code: get(values, 'countryCode', '+1'),
      phone_mobile: get(values, 'mobileNumber', ''),
      designation: get(values, 'title', ''),
      firm_name: get(values, 'firmAddress', ''),
      agent_town: get(values, 'agent_town', ''),
      suburbs_arr: get(values, 'towns', []),
      hourly_amount: get(values, 'hourly_amount', 0),
      conferenceContryCode: get(values, 'conferenceContryCode', ''),
      phone_work: get(values, 'phone_work', ''),
      is_schedule_on: get(values, 'strategyToggle', false),
      is_copy_email: get(values, 'is_copy_email', false),
      calendar_id: get(values, 'calendar_id', null),
      is_limit: get(values, 'is_limit', false)
    };

    console.log(data)

    if (get(values, 'selectedRole', '') === 'agent') {
      data.firm_name = get(values, 'firmAddress', '');
      data.agent_town = get(values, 'agent_town', ''),
      data.designation = get(values, 'title', '');
      data.email = get(values, 'email', '');
      data.is_limit = get(values, 'limitClients', false);
      data.country_code = get(values, 'countryCode', '+1');
      data.phone_mobile = get(values, 'mobileNumber', '');
      data.business_profile = get(values, 'business_profile', '');
      data.zillow_profile = get(values, 'zillow_profile', '');
      data.towns_arr = map(
        get(values, 'selectedSearchTown', []),
        town => town.value
      );
    }

    if (get(values, 'selectedRole', '') === 'prospect-agent') {
      data.business_profile = get(values, 'business_profile', '');
      data.zillow_profile = get(values, 'zillow_profile', '');
    }

    // data.strategistAssignmentRules = get(values, 'strategistAssignmentRules', [])

    if (get(values, 'selectedRole', '') === 'local') {
      data.update_type = "suburb_info";
      data.towns_arr = map(
        get(values, "selectedSearchTown", []),
        (town) => town.value
      );
    }

    if (get(values, 'selectedRole', '') === 'strategist') {
      data.country_code = get(values, 'countryCode', '');
      data.phone_mobile = get(values, 'mobileNumber', '');
      data.hourly_amount = get(values, 'hourlyAmount', 0);
      data.conferenceContryCode = get(values, 'conferenceContryCode', '');
      data.phone_work = get(values, 'conferenceMobileNumber', '');
      
      data.calendar_id = get(values, 'acuityCalendarId', false);

      data.isImpersonate = get(values, 'isImpersonate', '');
      data.strategist = map(
        get(values, 'seletedSearchByStrategist', []),
        strategist => strategist.value
      );
    }
    if (get(user, 'role', '') === 'superadmin' || get(user, 'role', '') === 'admin') {
      data.pay_scale = get(values, 'payScale', '');
      data.deal_payout = get(values, 'dealPayout', '');
      data.deal_payout_amount = get(values, 'dealPayoutAmount', 0) 
      data.refferal_payout = get(values, 'refferalPayout', '');
      data.refferal_payout_amount = get(
        values,
        'refferalPayoutAmount',
        0
      ) 
      data.hourly_amount = get(values, 'hourlyAmount', 0)
    }
    this.props.addJunglerUser(data);
  };

  handleAddJunglerUser = () =>
    this.props.history.push('/strategist/jungler-users');

  handleFileUpload = (e, setFieldValue) => {
    const _URL = window.URL || window.webkitURL;
    // let files = $("#profileImage")[0].files[0]
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();
      reader.onloadend = () => {
        img.src = _URL.createObjectURL(file);
        img.onload = () => {
          this.setState({ file, avatar: reader.result });
          setFieldValue('avatar', reader.result);
        };
      };
      const read = reader.readAsDataURL(file);
    }
  };

  handleChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    this.setState({ [name]: value.trim() });
    setFieldValue(name, value);
  };

  // handle switch for change form view
  handleSwitch = (event, name, setFieldValue) => setFieldValue(name, event);

  handleMultiSelectionOption = (options, event, setFieldValue) => {
    if (options) {
      const selectedOptions = map(options, option => option.value);
      setFieldValue(event, selectedOptions);
    }
  };

  // handleRuleMinMaxChanges = (metroAreaId, strategistAssignmentRules = [], minMax, value=false, setFieldValue) => {
    
  //   const newStrategistAssignmentRules = strategistAssignmentRules.map((rule) => {
  //     if (rule.suburb_id !== metroAreaId) return rule
  //     return {
  //       suburb_id: metroAreaId,
  //       min: (minMax === 'min' ? parseInt(value) : rule.min),
  //       max: (minMax === 'max' ? parseInt(value) : rule.max)
  //     }
  //   })

  //   if (!newStrategistAssignmentRules.map(r => r.suburb_id).includes(metroAreaId)) {
  //     newStrategistAssignmentRules.push({
  //       suburb_id: metroAreaId,
  //       min: (minMax === 'min' ? parseInt(value) : null),
  //       max: (minMax === 'max' ? parseInt(value) : null)
  //     })
  //   }
  //   setFieldValue('strategistAssignmentRules', newStrategistAssignmentRules)

  // }

  render() {
    const { isLoading } = this.state
    const user = cookies.get('user');
    const timeZoneOption = config.timezonelist.map((val, index) => (
      <option key={index} value={val.key}>
        {val.name}{' '}
      </option>
    ));
    const townDataOption = map(
      get(this.props, 'filterParams.data.suburbs', []),
      suburb => {
        return { label: suburb.name, value: suburb.id };
      }
    );
    const strategistsOptions = compact(
      map(get(this.props, 'filterParams.data.strategists', []), strategist => {
        if (strategist && user.id !== strategist.id) {
          return { label: strategist.strategist_name, value: strategist.id };
        }
      })
    );

    const priceBrackets = get(this.props, 'filterParams.data.price_brackets', [])
    const priceBracketDataOption = {
      price_min: priceBrackets.map(pb => parseInt(pb.price_min)).sort((a,b) => a-b).filter(pm => pm !== 1),
      price_max: priceBrackets.map(pb => parseInt(pb.price_max)).sort((a,b) => a-b).filter(pm => pm !== 0)
    }
    
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="client__details__header">
              <div className="row d__flex align__items__center">
                <div className="col-xs-12 col-md-4">
                  <button
                    className="btn btn-back"
                    onClick={this.handleAddJunglerUser}
                  >
                    <BackArrowIcon className="arrow-icon" />
                    Back
                  </button>
                </div>
                {/* <div className="col-xs-12 col-md-8">
                  <div className="filter__result__section">
                    <button className="btn btn__btn btn-dark w__180 mr__15">Impersonate User</button>
                    <button className="btn btn__btn btn-danger w__150">Delete User</button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <h1 className="title__section">Add User</h1>
              </div>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  ...initialValues,
                  searchByStrategist: strategistsOptions,
                  creatorRole: get(user, 'role', '') === 'superadmin' || get(user, 'role', '') === 'admin'

                }}
                validationSchema={Validation.createUser}
                onSubmit={(values, { setSubmitting }) => {
                  this.setState({ isLoading: true })
                  this.handleSubmit(values);
                }}
              >
                {({ values, errors, isSubmitting, setFieldValue }) => (
                  <Form noValidate>
                    <div className="col-md-10">
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group material-textfield">
                            <Field
                              as="select"
                              className="form-control custom__select material-textfield-input textfield-input-lg"
                              name="selectedRole"
                            >
                              <option value="">Select Role</option>
                              {roleListOption(user)}
                            </Field>
                            <label className="material-textfield-label label-lg">
                              Role <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              className="invalid-feedback"
                              name="selectedRole"
                              component="div"
                            />
                          </div>
                          {values.selectedRole && (
                            <Fragment>
                              <div className="form-group material-textfield">
                                <div className="d__flex align__items__center">
                                  <div className="avatar-text">
                                    Profile Photo <span> (1mb max)</span>
                                  </div>
                                  <div className="avatar avatar-lg">
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {values.avatar ? (
                                      <img src={values.avatar} alt="" />
                                    ) : values.firstName ? (
                                      <span className="avatar-string">
                                        {createStringAvatar(
                                          `${values.firstName} ${values.lastName}`
                                        )}
                                      </span>
                                    ) : (
                                      <img
                                        src="../img/blank_profile_image.png"
                                        alt=""
                                      />
                                    )}
                                    <div className="upload-avatar-container">
                                      <input
                                        type="file"
                                        className="upload-avatar"
                                        accept="image/*"
                                        name="image"
                                        onChange={e =>
                                          this.handleFileUpload(
                                            e,
                                            setFieldValue
                                          )
                                        }
                                        id="file"
                                      />
                                      <CameraIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">

                              {displayFieldForRole(user.role, values.selectedRole, 'firstName') &&
                                <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="firstName"
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        First Name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="firstName"
                                        component="div"
                                      />
                                    </div>                                      
                                  </div>
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'lastName') &&                                
                                  <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="lastName"
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Last Name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="lastName"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                }

                              </div>
                              <div className="row">

                                {displayFieldForRole(user.role, values.selectedRole, 'email') &&                                
                                  <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="email"
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Email{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="email"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                }


                                {displayFieldForRole(user.role, values.selectedRole, 'countryCode') &&                                
                                  <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <PhoneInput
                                        defaultCountry={'us'}
                                        value={this.state.countryCode}
                                        autoFormat={false}
                                        name="countryCode"
                                        disableAreaCodes={true}
                                        enableLongNumbers={false}
                                        onChange={event =>
                                          setFieldValue(
                                            'countryCode',
                                            `+${event}`
                                          )
                                        }
                                        buttonClass="flag-textfield"
                                        required
                                      />
                                      <NumberFormat
                                        format="(###) ### #### #####"
                                        value={this.state.mobileNumber}
                                        name="mobileNumber"
                                        id="mobileNumber"
                                        className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                        onChange={event =>
                                          this.handleChange(event, setFieldValue)
                                        }
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Mobile Number{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="mobileNumber"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                }
                              </div>
                              <div className="row">

                                {displayFieldForRole(user.role, values.selectedRole, 'title') &&
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="title"
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Title
                                      </label>
                                      {/* <ErrorMessage className="invalid-feedback" name="title" component="div" /> */}
                                    </div>
                                  </div>
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'conferenceContryCode') &&
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <PhoneInput
                                        defaultCountry={'us'}
                                        value={
                                          this.state.conferenceContryCode
                                        }
                                        autoFormat={false}
                                        name="conferenceContryCode"
                                        disableAreaCodes={true}
                                        enableLongNumbers={false}
                                        onChange={event =>
                                          setFieldValue(
                                            'conferenceContryCode',
                                            `+${event}`
                                          )
                                        }
                                        buttonClass="flag-textfield"
                                        required
                                      />
                                      <NumberFormat
                                        format="(###) ### #### #####"
                                        value={
                                          this.state.conferenceMobileNumber
                                        }
                                        name="conferenceMobileNumber"
                                        id="conferenceMobileNumber"
                                        className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                        onChange={event =>
                                          this.handleChange(
                                            event,
                                            setFieldValue
                                          )
                                        }
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Conference Phone Number
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="conferenceMobileNumber"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'MinMaxPrice') && 
                                  <div className="col-md-12">
                                    <MinMaxPrice
                                      filterParams={this.props.filterParams}
                                      minPrice={values.minPrice}
                                    />
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'strategyToggle') && 
                                  <div className="col-md-12">
                                    <div className="status__switch form-group">
                                      <span className="status__switch__text">
                                        Can strategist receive clients?
                                      </span>
                                      <Switch
                                        defaultChecked={values.strategyToggle}
                                        onChange={event =>
                                          this.handleSwitch(
                                            event,
                                            'strategyToggle',
                                            setFieldValue
                                          )
                                        }
                                        name="strategyToggle"
                                      />
                                      {values.strategyToggle ? (
                                        <span className="status yes">
                                          Yes
                                        </span>
                                      ) : (
                                        <span className="status">No</span>
                                      )}
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'is_copy_email') && 
                                  <div className="col-md-12">
                                    <div className="status__switch form-group">
                                      <span className="status__switch__text">
                                        Allow user to perform bulk actions on clients?
                                      </span>
                                      <Switch
                                        defaultChecked={
                                          values.is_copy_email
                                        }
                                        onChange={event =>
                                          this.handleSwitch(
                                            event,
                                            'is_copy_email',
                                            setFieldValue
                                          )
                                        }
                                        name="is_copy_email"
                                      />
                                      {values.copy_email ? (
                                        <span className="status yes">
                                          Yes
                                        </span>
                                      ) : (
                                        <span className="status">No</span>
                                      )}
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'acuityCalendarId') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <NumberFormat
                                        value={values.acuityCalendarId}
                                        name="acuityCalendarId"
                                        id="acuityCalendarId"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        onChange={event =>
                                          this.handleChange(
                                            event,
                                            setFieldValue
                                          )
                                        }
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Acuity Calendar ID <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="acuityCalendarId"
                                        component="div"
                                      />
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'payScale') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        as="select"
                                        className="form-control custom__select material-textfield-input textfield-input-lg"
                                        name="payScale"
                                      >
                                        <option value="">Select</option>
                                        <option value="hourly">
                                          Hourly{' '}
                                        </option>
                                        <option value="salary">
                                          Salary
                                        </option>
                                        <option value="flat">Flat</option>
                                      </Field>
                                      <label className="material-textfield-label label-lg">
                                        Pay Scale
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="payScale"
                                        component="div"
                                      />
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'hourlyAmount') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <NumberFormat
                                        value={values.hourlyAmount}
                                        name="hourlyAmount"
                                        thousandSeparator={true}
                                        id="hourlyAmount"
                                        prefix="$"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        onValueChange={
                                          (value) => {
                                            this.setState({ 'hourlyAmount': value.floatValue });
                                            setFieldValue('hourlyAmount', value.floatValue);
                                          }
                                        }
                                        required
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Hourly Amount
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="hourlyAmount"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'PayoutAdditionalFields') && 
                                  <PayoutAdditionalFields
                                    values={values}
                                    setFieldValue={setFieldValue}
                                  />
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'timeZone') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        as="select"
                                        className="form-control custom__select material-textfield-input textfield-input-lg"
                                        name="timeZone"
                                      >
                                        <option value="">Select Time Zone</option>
                                        {timeZoneOption}
                                      </Field>
                                      <label className="material-textfield-label label-lg">
                                        Select Time Zone{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="timeZone"
                                        component="div"
                                      />
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'business_profile') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="business_profile"
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Business Profile
                                      </label>
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'zillow_profile') &&
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="zillow_profile"
                                      />
                                      <label className="material-textfield-label label-lg">
                                        Zillow Profile
                                      </label>
                                    </div>
                                  </div>
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'bio') && 
                                  <div className="col-md-12">
                                    <div className="form-group material-textfield">
                                      <Field
                                        component="textarea"
                                        type="text"
                                        className="form-control material-textfield-input textfield-input-lg"
                                        name="bio"
                                        rows="8"
                                        required
                                      ></Field>
                                      <label className="material-textfield-label label-lg">
                                        Enter Bio <span class="text-danger">*</span>
                                      </label>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="bio"
                                        component="div"
                                      />
                                    </div>
                                  </div>                                
                                }

                                {displayFieldForRole(user.role, values.selectedRole, 'limitClients') && 
                                  <div className="col-md-12">
                                    <div className="status__switch form-group">
                                      <span className="status__switch__text">
                                        Limit clients?
                                      </span>
                                      <Switch
                                        defaultChecked={values.limitClients}
                                        name="limitClients"
                                        onChange={event =>
                                          this.handleSwitch(
                                            event,
                                            'limitClients',
                                            setFieldValue
                                          )
                                        }
                                      />
                                      {values.limitClients ? (
                                        <span className="status yes">Yes</span>
                                      ) : (
                                        <span className="status">No</span>
                                      )}
                                    </div>
                                  </div>
                                }

                              </div>

                              {displayFieldForRole(user.role, values.selectedRole, 'SuburbsCheckboxList') &&
                                <SuburbsCheckboxList
                                  towns={values.towns}
                                  townDataOption={townDataOption}
                                  selectedRole={values.selectedRole}
                                  setFieldValue={setFieldValue}
                                  priceBracketDataOption={priceBracketDataOption}
                                  getTownsOptionBySuburbs={
                                    this.props.getTownsOptionBySuburbs
                                  }
                                />                              
                              }

                              {displayFieldForRole(user.role, values.selectedRole, 'SelectTown') && 
                                <SelectTown
                                  selectedRole={values.selectedRole}
                                  selectedSearchTown={values.selectedSearchTown}
                                  searchTownOption={values.searchTownOption}
                                  setFieldValue={setFieldValue}
                                />                              
                              }

                              
                              <button
                                className="btn btn__btn btn-dark btn__lg w__100"
                                type="submit"
                                disabled={isLoading}
                              >
                                Save
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
