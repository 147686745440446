import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from '../.././styles.scss'
import Cookies from 'universal-cookie'
import axios from 'axios'
import $ from 'jquery';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME
class ClientLoginTokenForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      token:"",
      email: "",
      password: "",
      ru:"",
      err: {},
      errMessage: ""
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  componentDidMount() {
    if(this.state.token!="") {
      const { clientValidateToken } = this.props
      const data = {};
      data.token = this.state.token;
      if(this.state.ru !=''){
        data.ru = this.state.ru;
      }
      clientValidateToken(data);
      }
  }

  componentWillMount() {

    if(this.getParameterByName('validate') && this.getParameterByName('validate') != '' && this.getParameterByName('validate') != null){
      this.setState({token:this.getParameterByName('validate')})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clientloginPhase===true){
      const user = nextProps.user
      // if(user.token !==undefined && user.token !="" && (nextProps.user.user.role === 'strategist' || nextProps.user.user.role === 'admin' || nextProps.user.user.role === 'superadmin')){
      //  this.props.history.push('/home/new-clients')
      // } else if(user.token !==undefined && user.token !="" && nextProps.user.user.role === 'local'){
      //  this.props.history.push('/agent/dashboard')
      // } else
      if(nextProps.user.token !==undefined && nextProps.user.token !=""){
        this.props.history.push('/clients')
      }else if(nextProps.user.ru && nextProps.user.ru!="" && nextProps.user.rutoken !==undefined ){
        this.props.history.push('/clients')
      }
    }
    if (nextProps.clientloginPhase===false) {
      //setTimeout(function(){
      alert('Sorry, Your login token not validated, try to send a magic ling again.');
      // }, 500)
    }
    //this.setState({ isLoading: false, errMessage: nextProps.loginError.message})
  }

   getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  render() {
    const {user, isAuthenticated, clientloginPhase }=this.props
    return (
      <section className="login__container">
        Redirecting to the client profile please wait...
      </section>
    )
  }
}

export default reduxForm({
  form: 'client-token-login',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientLoginTokenForm)
