import { connect } from 'react-redux'
import AgentStatisticsForm from './component'
import { getFilterParams} from '../../../store/dashboard/duck'

import { getAgentList, getAgentStatistics, getNoInitialCall } from '../../../store/analytics/duck'

const AgentStatistics = connect(
  // Map state to props
  (state) => ({
  	agentPhase: state.analytics.agentPhase,
  	agentListData: state.analytics.agentListData,
  	filterParams: state.dashboard.filterParams,
    agentStatisticsPhase: state.analytics.agentStatisticsPhase,
    agentStatisticsData: state.analytics.agentStatisticsData,
    initialCallPhase: state.analytics.initialCallPhase,
    initialCallData: state.analytics.initialCallData,
  }),
  // Map actions to props
  {
  	getAgentList,
    getFilterParams,
    getAgentStatistics,
    getNoInitialCall
  }
)(AgentStatisticsForm)
export default AgentStatistics
