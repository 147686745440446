import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  error: null,
  prospectApplicants: [],
  rejectedApplicants:[],
  fetchApplicantsPhase: INIT,
  applicantPhase:INIT,
  // isApprovedSuccess:0,
  // isRejectedSuccess:0
  rejectedApplicantsCount:0,
  prospectApplicantCount:0
})

export const agentInterviewsReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  fetch appointment list */
    case types.FETCH_PROSPECT_APPLICANTS_LIST: {
      return state.set('error', null).set('fetchApplicantsPhase', LOADING)
    }
    case types.FETCH_PROSPECT_APPLICANTS_LIST_SUCCESS: {
      const applicants = get(action, 'payload.data.applicants', {})
      const rejectedApplicantsCount = get(action, 'payload.data.rejectedApplicantsCount', {})
      const prospectApplicantCount = get(action, 'payload.data.prospectApplicantCount', {})

      return state
        .set('prospectApplicants', applicants)
        .set('rejectedApplicantsCount', rejectedApplicantsCount)
        .set('prospectApplicantCount', prospectApplicantCount)
        .set('error', null)
        .set('fetchApplicantsPhase', SUCCESS)

    }
    case types.FETCH_PROSPECT_APPLICANTS_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchApplicantsPhase', ERROR)
    }

    /*  fetch rejected applicants */
    case types.FETCH_REJECTED_APPLICANTS_LIST: {
      return state.set('error', null).set('fetchApplicantsPhase', LOADING)
    }
    case types.FETCH_REJECTED_APPLICANTS_LIST_SUCCESS: {
      const applicants = get(action, 'payload.data.applicants', {})

      return state
        .set('rejectedApplicants', applicants)
        .set('error', null)
        .set('fetchApplicantsPhase', SUCCESS)

    }
    case types.FETCH_REJECTED_APPLICANTS_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchApplicantsPhase', ERROR)
    }

    /*  approve applicant */
    case types.APPLICANT_APPROVED: {
      return state
            .set('error', null)
            .set('applicantPhase', LOADING)
    }
    case types.APPLICANT_APPROVED_SUCCESS: {
      return state
        .set('error', null)
        .set('applicantPhase', SUCCESS)
    }
    case types.APPLICANT_APPROVED_ERROR: {
      return state.set('error', action.payload.error).set('applicantPhase', ERROR)
    }

    /*  reject applicant */
    case types.APPLICANT_REJECTED: {
      return state
            .set('error', null)
            .set('applicantPhase', LOADING)
    }
    case types.APPLICANT_REJECTED_SUCCESS: {
      return state
        .set('error', null)
        .set('applicantPhase', SUCCESS)
    }
    case types.APPLICANT_REJECTED_ERROR: {
      return state.set('error', action.payload.error).set('applicantPhase', ERROR)
    }

    default: {
      return state
    }
  }
}
