import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { isEmpty, get } from 'lodash'

export default function EditBackgroundProfile({ htmlDoc,
  addBackgroundProfile,
  clientDetail,
  authorFullName,
  sendBackgroundData,
  backgroundData,
  clientId
}) {

  const [backgroundProfile, setBackgroundProfile] = useState("")
  const [isEditable, setIsEdit] = useState(false)

  const editBackgroundProfile = () => {
    setBackgroundProfile(htmlDoc)
    setIsEdit(true)
  }

  const handleBackgroundProfile = () => setIsEdit(false)
  const handleChange = ({ target }) => setBackgroundProfile(target.value)
  const handleSubmit = () => {
    if (backgroundProfile !== '' && clientId !== '') {
      sendBackgroundData({
        // eslint-disable-next-line camelcase
        client_id: clientId,
        content: backgroundProfile
      })
      setIsEdit(false)
    }
  }
  // dynamic textarea for incress and decress box size
  const handleKeyDown = (event) => {
    event.persist()
    event.target.style.height = 'inherit'
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  return (
    <Fragment>
      {!isEmpty(htmlDoc) && (htmlDoc !== "undefined") && (htmlDoc !== "null") ?
        <Fragment>
          {!isEditable && <Fragment>
            <div className="card-header d__flex align__items__center">
              <div>
                <span className="card-title mb__3">Background Profile</span>&nbsp;
                <span className="card-subtitle">
                  (Updated on <strong>{moment(backgroundData.date_modified).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</strong> by <strong>{authorFullName}</strong>)
                </span>
                <p className="card-subtitle">
                  Notes are based on information the client provided to Suburban Jungle, written and/or verbal.
                </p>
              </div>
              <div className="cleint__information__cta">
                <button className="btn btn__btn btn-dark btn__create__appointment mr__15" onClick={editBackgroundProfile}>Edit Background Profile</button>
              </div>
            </div><div className="card-body card-body-sm">
              <p className="background__profile__text font__16 mb__0">
                {htmlDoc}
              </p>
            </div>
          </Fragment>}
          {isEditable && <div className="card-body card-body-sm">
            <p className="card-subtitle" style={{"margin-bottom": "10px"}}>
              Notes are based on information the client provided to Suburban Jungle, written and/or verbal.
            </p>
            <div className="background__information__wrapper">
              <textarea
                className="form-control background__information__textarea"
                type="text"
                name="backgroundProfile"
                ref={(current) => current && current.focus()}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyDown}
                style={{ resize: "none", overflowY: "hidden" }}
                value={backgroundProfile}
                onFocus={handleKeyDown}
                onChange={handleChange}
                placeholder={`Add background profile about ${get(clientDetail, "client_full_name", "")}...`}
                rows="4">
              </textarea>
              <div className="background__information__cta">
                <button className="btn btn__btn btn-secondry w__130 mr__15" onClick={handleBackgroundProfile}>Cancel</button>
                <button className="btn btn__btn btn-dark w__130" onClick={handleSubmit}>Update</button>
              </div>
            </div>
          </div>
          }
        </Fragment> :
        !addBackgroundProfile && false && <div className="card-body card-body-sm">
          <div className="blank__state__text">
            No Background Information
          </div>
        </div>
      }
    </Fragment>
  )
}
