import React from 'react'
import moment from 'moment'
import { map, get } from 'lodash'

export default function JunglerUserList({ users, currentUser, impersonateUser, history }) {
  return (
    <tbody>
      {map(users, (user) => (
        <tr className="cursor__pointer" key={user.id} onClick={() => {
          window.location.href = `/strategist/edit-user/${user.id}`;
        }}>
          <td>{get(user, 'full_name', '')}</td>
          <td>{get(user, 'role', '')}</td>
          <td>{get(user, 'email', '')}</td>
          {/* <td>{get(user, 'phone_mobile', '')}</td> */}
          <td>{user.created ? moment(user.created).format('MM/DD/YYYY') : ''}</td>
          
          <td>
            {['agent', 'client', 'strategist', 'admin'].includes(get(user, 'role', false)) && (
                <button
                  className="btn btn__btn btn__impersonate"
                  onClick={() => impersonateUser(user.id)}
                >
                  Impersonate
                </button>
              )}
          </td>
          <td>
            {currentUser &&
              currentUser.role &&
              (currentUser.role === 'superadmin' ||
                currentUser.role === 'admin' ||
                currentUser.role === 'strategist') && (
                <button
                  className="btn btn__btn btn__impersonate"
                  onClick={() => history.push(`/strategist/edit-user/${user.id}`, { pathnameToRoute: window.location.pathname })}
                >
                  Edit User
                </button>
              )}
          </td>
        </tr>
      ))}
    </tbody>
  )
}
