import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import Chart from 'react-google-charts'
import Snackbar from 'material-ui/Snackbar'
import monthpicker from '.././monthpicker.css'
import axios from 'axios'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import Picker from 'react-month-picker'
import moment from 'moment';
import DatePicker from 'react-datepicker'
import styles from './styles.scss'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


//import { AreaChart } from "react-easy-chart";
let janMonth = ''
let janCount = ''
let febMonth = ''
let febCount = ''
let marMonth = ''
let marCount = ''
let aprilMonth =''
let aprilCount = ''
let mayMonth = ''
let mayCount = ''
let juneMonth = ''
let juneCount = ''
let julyMonth = ''
let julyCount = ''
let augMonth = ''
let augCount = ''
let sepMonth = ''
let sepCount = ''
let octMonth = ''
let octCount = ''
let novMonth = ''
let novCount = ''
let decMonth = ''
let decCount = ''


class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h2>Welcome to React</h2>
        </div>
        <p className="App-intro">
          Hi {this.props.name}!
        </p>
        <p className="App-clock">
          {/* The time is {time}. */}
        </p> 
      </div>
    );
  }
}


class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AnalyticsClientData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      year:new Date().getFullYear(),
      password: "",
      err: {},
      data:'',
      openSnackbar: false,
      errMessage:'',
      strategistDropdown: false,
      suburbDropdown:false,
      client_strategist:[],
      client_status: [],
      client_suburb:[],
      fromDate: moment('01/01/2018'), //moment(new Date().setMonth(new Date().getMonth() - 11)),
      endDate: moment(),
      setDate: moment(),
      time: new Date().toLocaleString()
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount(){
    const {fetchUser} = this.props;
    let _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      const analyticsClient = {}
      analyticsClient.strategist_id = this.state.client_strategist
      analyticsClient.from = this.state.fromDate
      analyticsClient.to = this.state.endDate
      analyticsClient.year = this.state.year
      analyticsClient.suburbs_id = this.state.client_suburb
      this.props.analyticsClients(analyticsClient)
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }
    if(nextProps.clientAnalyticsPhase === "success"){
      let firstMonth =  _.get(nextProps.clientAnalyticsData, 'results[0]')
      janMonth = _.get(firstMonth, '[0]')
      janCount = _.get(firstMonth, '[1]')

      let secondMonth =  _.get(nextProps.clientAnalyticsData, 'results[1]')
      febMonth = _.get(secondMonth, '[0]')
      febCount = _.get(secondMonth, '[1]')

      let thiredMonth =  _.get(nextProps.clientAnalyticsData, 'results[2]')
      marMonth = _.get(thiredMonth, '[0]')
      marCount = _.get(thiredMonth, '[1]')

      let fourthMonth =  _.get(nextProps.clientAnalyticsData, 'results[3]')
      aprilMonth = _.get(fourthMonth, '[0]')
      aprilCount = _.get(fourthMonth, '[1]')

      let fiveMonth =  _.get(nextProps.clientAnalyticsData, 'results[4]')
      mayMonth = _.get(fiveMonth, '[0]')
      mayCount = _.get(fiveMonth, '[1]')

      let sixMonth =  _.get(nextProps.clientAnalyticsData, 'results[5]')
      juneMonth = _.get(sixMonth, '[0]')
      juneCount = _.get(sixMonth, '[1]')

      let savenMonth =  _.get(nextProps.clientAnalyticsData, 'results[6]')
      julyMonth = _.get(savenMonth, '[0]')
      julyCount = _.get(savenMonth, '[1]')

      let eightMonth =  _.get(nextProps.clientAnalyticsData, 'results[7]')
      augMonth = _.get(eightMonth, '[0]')
      augCount = _.get(eightMonth, '[1]')

      let nineMonth =  _.get(nextProps.clientAnalyticsData, 'results[8]')
      sepMonth = _.get(nineMonth, '[0]')
      sepCount = _.get(nineMonth, '[1]')

      let tenMonth =  _.get(nextProps.clientAnalyticsData, 'results[9]')
      octMonth = _.get(tenMonth, '[0]')
      octCount = _.get(tenMonth, '[1]')

      let elevenMonth =  _.get(nextProps.clientAnalyticsData, 'results[10]')
      novMonth = _.get(elevenMonth, '[0]')
      novCount = _.get(elevenMonth, '[1]')

      let twelveMonth =  _.get(nextProps.clientAnalyticsData, 'results[11]')
      decMonth = _.get(twelveMonth, '[0]')
      decCount = _.get(twelveMonth, '[1]')
    }
  }

  componentDidMount(){
     window.setInterval(function () {
      this.setState({setDate: this.state.setDate})
    }.bind(this), 1000);
  }

  handleChange(event) {
  this.setState({ [event.target.name]: event.target.value })
    setTimeout(function(){
      const analyticsClient = {}
      analyticsClient.strategist_id = this.state.client_strategist
      analyticsClient.from = this.state.fromDate
      analyticsClient.to = this.state.endDate
      analyticsClient.year = this.state.year
      analyticsClient.suburbs_id = this.state.client_suburb
      this.props.analyticsClients(analyticsClient)
    }.bind(this), 500)
  }

  handleFromDate(date) {
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    const analyticsClient = {}
    analyticsClient.strategist_id = this.state.client_strategist
    analyticsClient.from = selectDate
    analyticsClient.to = this.state.endDate
    analyticsClient.year = this.state.year
    analyticsClient.suburbs_id = this.state.client_suburb
    this.props.analyticsClients(analyticsClient)
    this.setState({fromDate: date})
    
  }

  handEndDate(date){
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    const analyticsClient = {}
    analyticsClient.strategist_id = this.state.client_strategist
    analyticsClient.from = this.state.fromDate
    analyticsClient.to = selectDate
    analyticsClient.year = this.state.year
    analyticsClient.suburbs_id = this.state.client_suburb
    this.props.analyticsClients(analyticsClient)
    this.setState({endDate: date})
  }

  toggledropDown(val){
    if(val==='filterStrategist'){
      this.setState({strategistDropdown: !this.state.strategistDropdown, statusDropdown : false, suburbDropdown : false})
    }
    if(val==='filterStatus'){
      this.setState({statusDropdown: !this.state.statusDropdown, strategistDropdown : false, suburbDropdown : false})
    }

    if(val==='filterSuburb'){
      this.setState({suburbDropdown: !this.state.suburbDropdown, statusDropdown : false, strategistDropdown : false})
    }
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status, client_suburb } = this.state;
    if(type === 'strategistFilter'){
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } 
      else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
    }
    if(type === 'statusFilter'){
      if (client_status.indexOf(id) === -1) {
        client_status.push(id)
      } 
      else {
        client_status.splice(client_status.indexOf(id), 1)
      }
    }

    if(type === 'suburbFilter'){
      if (client_suburb.indexOf(id) === -1) {
        client_suburb.push(id)
      } 
      else {
        client_suburb.splice(client_suburb.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_strategist : client_strategist, client_suburb : client_suburb })
    const analyticsClient = {}
    analyticsClient.strategist_id = this.state.client_strategist
    analyticsClient.from = this.state.fromDate
    analyticsClient.to = this.state.endDate
    analyticsClient.year = this.state.year
    analyticsClient.suburbs_id = this.state.client_suburb
    this.props.analyticsClients(analyticsClient)
  }

  render() {
    const {
      user,
      filterParams,
      clientAnalyticsData,
    } = this.props
   
    let allData = filterParams.data;
    let currentuser = user && user;
    let stratagistOptions = allData && allData.strategists.map((strategistsval, strategistsindx) => {
      return (
        <option key={strategistsindx} value={strategistsval.id}>{strategistsval.strategist_name}</option>
      )
    });
    let suburbsOptions = allData && allData.suburbs.map((suburbsval, suburbsindx) => {
      return (
        <option key={suburbsindx} value={suburbsval.id}>{suburbsval.name}</option>
      )
    });
    let statusOptions = allData && allData.statuses.map((statussval, statusindx) => {
      return (
        <option key={statusindx} value={statussval.name}>{statussval.name}</option>
      )
    });

    let usersTotalCounts =  clientAnalyticsData && clientAnalyticsData.total_counts; 
    
    let usersData2 = [['Month', 'Client counts']]
    let usersData = [
                      ['Month', 'Client counts'],
                      [janMonth, janCount],
                      [febMonth, febCount],
                      [marMonth, marCount],
                      [aprilMonth, aprilCount],
                      [mayMonth, mayCount],
                      [juneMonth, juneCount],
                      [julyMonth, julyCount],
                      [augMonth, augCount],
                      [sepMonth, sepCount],
                      [octMonth, octCount],
                      [novMonth, novCount],
                      [decMonth, decCount],
                    ];

    return (
      <div>
        <div className="analytics__page--container">
          <div className="analytics__page--header">
            <div className="analytics__page--header-title">
              Jungler Analytics
            </div>
            {/* <select className="form-control custom-select">
              <option>Other Metrics </option>
              <option>Select </option>
              <option>Select </option>
              <option>Select </option>
              <option>Select </option>
            </select> */}
            <div className="analytics__page--close">
              <Link to={`/dashboard`}>
              <img src="img/close.svg"/></Link>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="analytics__heading">
                  <h1>Total Clients</h1>
                </div>
              </div>
            </div>
            <div className="row d-flex d-flex-brake">
              <div className="col-md-2">
                <div className="analytics__count">
                  {usersTotalCounts && usersTotalCounts.toLocaleString()}
                </div>
              </div>
              <div className="col-md-10">
                <div className="analytics__action">
                  
                  <div className="dp-wrapper stragist-span">
                    <div className="custom-select custom-sel-filter custom-sel-filter-new" onClick={this.toggledropDown.bind(this, 'filterStrategist')}>All Strategist</div>
                    {this.state.strategistDropdown && 
                      <div className= "sel-outer">
                        {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                         let selectedIndex = false;
                          _.find(this.state.client_strategist, function(o) {
                            if(o === strategistsval.id){
                              selectedIndex = true;
                            } 
                         });
                         return (
                          <li key={strategistsindx} className="custom-select-option">
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {strategistsval.strategist_name}
                              <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id, 'strategistFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                         )
                        })}
                      </div>
                    }
                    </div>
              
                  <div className="dp-wrapper stragist-span">
                  <div className="custom-select custom-sel-filter custom-sel-filter-new" onClick={this.toggledropDown.bind(this, 'filterSuburb')}>All Markets</div>
                    {this.state.suburbDropdown && 
                      <div className= "sel-outer">
                        {allData && allData.suburbs.map((suburbsval, suburbsindx) => {
                          let selectedIndex = false;
                          _.find(this.state.client_suburb, function(o) {
                            if(o === suburbsval.id){
                              selectedIndex = true;
                            } 
                          });
                          return (
                          <li key={suburbsindx} className="custom-select-option">
                          <span className="coverage__checkbox">
                            <label className="custom-checkbox">
                            {suburbsval.name}
                            <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,suburbsval.id, 'suburbFilter')}/>
                              <span className="checkmark"></span>
                            </label>
                          </span>
                        </li>
                          )
                        })}
                      </div>
                    }
                    </div>

                  <div className="dp-wrapper stragist-span">
                      <label>From</label>
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control date__picker">
                      </DatePicker>
                  </div>

                  <div className="dp-wrapper stragist-span">
                      <label>To</label>
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control date__picker">
                      </DatePicker>
                  </div>
                
                  <ExcelFile element={<button className="reporting__btn">Export Data</button>}>
                    <ExcelSheet data={this.props.clientAnalyticsData.export_data} name="Client_Analytics">
                      <ExcelColumn label="Month" value='created_month'/>
                      <ExcelColumn label="Count" value='client_count'/>
                      <ExcelColumn label="Year" value='year'/>
                    </ExcelSheet>
                  </ExcelFile>
                  {/*<select name='year' 
                    className="custom-select" 
                    value={this.state.year} 
                    onChange={this.handleChange.bind(this)}>
                    <option value=''>Select Year</option>
                    <option value='2018'>2018</option>
                    <option value='2017'>2017</option>
                    <option value='2016'>2016</option>
                 </select>*/}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="analytics__graph">
                  <Chart
                    width={'100%'}
                    height={'500px'}
                    type="linear"
                    chartType="AreaChart"
                    loader={<div>Loading Chart</div>}
                    data={usersData}
                    options={{
                      isStacked: true,
                      height: 500,
                      legend: { position: 'top', maxLines: 5 },
                      colors: ['#C6CE4A','#C6CE4A', '#C6CE4A'],
                      vAxis: { minValue: 0 },
                      // lineWidth: 25
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>
                
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex d-flex-brake">
                <div className="analytics__last--updated">
                  
                </div>
                <div className="analytics__export--btn">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'login',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AnalyticsClientData)
