import React, { Component } from 'react'
import { FilterIcon } from "../../../../../components/icons"
import { Popover, DatePicker } from 'antd'
import { get, map, find, filter, size } from 'lodash'
import config from '../../../../../config'

export default class AppointmentFilter extends Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      agent: false,
      filterCount: 0,
      status: false,
      appointmentType: false,
      searchAppointmentType: '',
      searchedAppointmentType: config.typeOfAppointments,
      filteredAppointmentTypeDropdown: false,
      searchedSuburb: [],
      searchSuburb: "",
      suburbs: false,
      selectedAppointmentType: [],
      selectedSubrbs: [],
      createdDate: false,
      selectedCreatedDate: "",
      noCall: false,
      noTour: false
    }
  }
  // filter appointment type drop down
  filterClick = (type, event) => {
    if (event) event.preventDefault();
    let { filterCount } = this.state
    const { appointmentType, suburbs, selectedAppointmentType, selectedCreatedDate, selectedSubrbs, noCall, noTour} = this.state

    switch (type) {
      case 'noCall':
        filterCount = noCall ? filterCount - 1 : filterCount + 1
        this.setState({noCall: !noCall}, () => {
          this.props.setFilterParams({selectedAppointmentType, selectedSubrbs, createdDate: selectedCreatedDate, filterCount, noCall: this.state.noCall, noTour})
        })
        break
      case 'noTour':
        filterCount = noTour ? filterCount - 1 : filterCount + 1
        this.setState({noTour: !noTour}, () => {
          this.props.setFilterParams({selectedAppointmentType, selectedSubrbs, createdDate: selectedCreatedDate, filterCount, noCall, noTour: this.state.noTour})
        })
        break        
      case 'appointmentType':
        filterCount = appointmentType ? filterCount - 1 : filterCount + 1
        this.setState({ filteredAppointmentTypeDropdown: true, appointmentType: !appointmentType })
        if (appointmentType) {
          this.setState({ filteredAppointmentTypeDropdown: false, searchAppointmentType: '', selectedAppointmentType: [] })
          this.props.setFilterParams({ selectedAppointmentType: [], selectedSubrbs, createdDate: selectedCreatedDate, filterCount, noCall, noTour})
        }        
        break
      case 'suburbs':
        const { filterParams } = this.props;
        const searchedSuburb = filterParams && filterParams.data && filterParams.data.suburbs;
        filterCount = suburbs ? filterCount - 1 : filterCount + 1
        if (suburbs) {
          this.props.setFilterParams({ selectedAppointmentType, selectedSubrbs: [], createdDate: selectedCreatedDate, filterCount, noCall, noTour })
        }
        this.setState({ searchedSuburb, filteredSuburbDropdown: true, suburbs: !suburbs })
        break
      case 'createdDate':
        filterCount = this.state.createdDate ? filterCount - 1 : filterCount + 1
        if (this.state.createdDate) {
          this.setState({ createdDate: false, selectedCreatedDate: "" })
          this.props.setFilterParams({ selectedAppointmentType, selectedSubrbs, createdDate: "", filterCount, noCall, noTour })
        } else {
          this.setState({ createdDate: true })
        }        
        break
      default:
        break
    }

    this.setState({ filterCount })
  }
  // filter client data with client
  filterAppointment = (id, type, e) => {
    const { selectedAppointmentType, selectedSubrbs, selectedCreatedDate, filterCount, noCall, noTour } = this.state
    if (type === 'filterAppointment') {
      if (selectedAppointmentType.indexOf(id) === -1) {
        selectedAppointmentType.push(id)
      } else {
        selectedAppointmentType.splice(selectedAppointmentType.indexOf(id), 1)
      }
      this.setState({ selectedAppointmentType })
    }
    if (type === 'filterSuburb') {
      if (selectedSubrbs.indexOf(id) === -1) {
        selectedSubrbs.push(id)
      } else {
        selectedSubrbs.splice(selectedSubrbs.indexOf(id), 1)
      }
    }
    this.props.setFilterParams({ selectedAppointmentType, selectedSubrbs, createdDate: selectedCreatedDate, filterCount, noCall, noTour })
  }
  // handle select filter option on click of selection of options
  handleSelectFilterOption = (event) => {
    const { name } = event.target
    if (this.state[name]) {
      if (name === "appointmentType") {
        this.setState({ [name]: false, filteredAppointmentTypeDropdown: false, searchAppointmentType: '', selectedAppointmentType: [] })
      }
      this.setState({ [name]: false })
    } else {
      this.setState({ [name]: true })
    }
  }
  // change fileter value with selection for select
  handleChange = ({ target }) => {
    const { name, value } = target
    this.setState({ [name]: value })
    if (name === "searchAppointmentType") {
      const searchedAppointmentType = filter(config.typeOfAppointments, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.setState({ searchedAppointmentType })
    }
    if (name === "searchSuburb") {
      const { filterParams } = this.props
      const suburbs = filterParams && filterParams.data && filterParams.data.suburbs;
      const searchedSuburb = filter(suburbs, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.setState({ searchedSuburb })
    }

  }
  clearFilter = () => this.setState({
    filteredAppointmentTypeDropdown: false,
    filteredSuburbDropdown: false,
    searchedSuburb: [],
    searchAppointmentType: '',
    selectedAppointmentType: [],
    selectedSubrbs: [],
    filterCount: 0,
    appointmentType: false,
    suburbs: false,
    agent: false,
    createdDate: false,
    selectedCreatedDate: "",
    noCall: false,
    noTour:false
  }, () => this.props.setFilterParams({ selectedAppointmentType: [], selectedSubrbs: [], createdDate: "", filterCount: 0, noCall: false, noTour: false }));
  // handle page change on result entity
  handleResultEntity = ({ target }) => this.props.handleResultEntity({ clientLimit: parseInt(target.value) });

  filterVisible = visible => this.setState({ visible })

  // change status close filter model with
  closeFilterModel = () => this.setState({ visible: !this.state.visible })


  // child reset head or remove item from filter
  resetHeadFilterParams = (data) => {

    let { appointmentType, suburbs } = this.state
    let filterCount = 0

    /*
    if (size(data.selectedSubrbs) === 0) {
      if (suburbs) filterCount -= 1
      this.setState({ suburbs: false, filteredSuburbDropdown: false, searchAppointmentType: "" })
    } else {
      this.setState({ suburbs: true, filteredSuburbDropdown: true, searchAppointmentType: "" })
    }
    */

    if (size(data.selectedAppointmentType) === 0) {
      this.setState({ appointmentType: false, filteredAppointmentTypeDropdown: false, searchAppointmentType: "" })
    } else {
      filterCount++
      this.setState({ appointmentType: true, filteredAppointmentTypeDropdown: true, searchAppointmentType: "" })
    }

    this.setState({ 
      filterCount, 
      selectedAppointmentType: data.selectedAppointmentType, 
      selectedSubrbs: data.selectedSubrbs, 
      createdDate: data.createdDate 
    })
    
    this.forceUpdate()
  }

  // handle change and filter data based on date
  handleDateChange = (date) => {
    const { selectedAppointmentType, filteredStrategists, selectedSubrbs, filterCoun, noCall, noTour } = this.state
    this.setState({ selectedCreatedDate: date }, () => {
      this.props.setFilterParams({ selectedAppointmentType, createdDate: date, selectedSubrbs, filterCount, noCall, noTour })
    })
  }


  render() {
    const { filteredAppointmentTypeDropdown, filteredSuburbDropdown, searchedSuburb, searchedAppointmentType, searchSuburb, filterCount, noCall, noTour } = this.state;
    const { pageLimit } = this.props

    return (
      <div className="col-md-6">
        <div className="filter__result__section">
          {/* This is for filter section */}
          <Popover
            content={
              <div className="filter">
                <div className="filter__header">
                  <button className="btn btn__btn btn-secondry btn__xs" onClick={this.clearFilter}>Clear</button>
                  <div className="filter__title">Filters</div>
                  <button className="btn btn__btn btn-dark btn__xs" onClick={this.closeFilterModel}>Close</button>
                </div>
                <div className="filter__body">
                  <ul className="filter__list">
                    {/*<li>
                      <label className="custom-checkbox custom-checkbox-line" onClick={this.filterClick.bind(this, "suburbs")}>
                        Suburb
                        <input type="checkbox"
                          name="status"
                          checked={this.state.suburbs}
                          onChange={this.handleSelectFilterOption}
                          className="custom-checkbox--input" />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    {filteredSuburbDropdown &&
                      <ul className="filter__list__inner">
                        <div className="search__form mt__10 mb__10">
                          <input
                            type="text"
                            className="form-control search__form__input"
                            onChange={this.handleChange}
                            name="searchSuburb"
                            placeholder="Search for suburbs..."
                            value={searchSuburb}
                          />
                        </div>
                        {searchedSuburb &&
                          map(searchedSuburb, (suburb, suburbIdx) => {
                            const findSelected = find(this.state.selectedSubrbs, o => o === suburb.id)
                            return (
                              <li key={`suburb__${suburbIdx}`} >
                                <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                                  {suburb.name}
                                  <input
                                    type="checkbox"
                                    checked={findSelected ? true : false} className="custom-checkbox--input"
                                    onClick={this.filterAppointment.bind(this, suburb.id, 'filterSuburb')}
                                    readOnly
                                  />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    }*/}
                    <li>
                      <label className="custom-checkbox custom-checkbox-line" onClick={this.filterClick.bind(this, "appointmentType")}>
                        Appointment Type
                        <input type="checkbox"
                          name="appointmentType"
                          onChange={this.handleSelectFilterOption}
                          checked={this.state.appointmentType}
                          className="custom-checkbox--input"
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    {filteredAppointmentTypeDropdown && <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                        <input
                          type="text"
                          className="form-control search__form__input"
                          onChange={this.handleChange}
                          name="searchAppointmentType"
                          placeholder="Search for appointment type..."
                          value={this.state.searchAppointmentType}
                        />
                      </div>
                      {searchedAppointmentType &&
                        map(searchedAppointmentType, (appointment, appointmentIdx) => {
                          const findSelected = find(this.state.selectedAppointmentType, o => o === appointment.value)
                          return (
                            <li key={`appointment__${appointmentIdx}`} >
                              <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                                {appointment.name}
                                <input
                                  type="checkbox"
                                  checked={findSelected ? true : false}
                                  onClick={this.filterAppointment.bind(this, appointment.value, 'filterAppointment')}
                                  className="custom-checkbox--input"
                                  readOnly
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                    </ul>}


                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        No Call
                        <input type="checkbox"
                          name="noCall"
                          checked={noCall}
                          className="custom-checkbox--input"
                          onChange={this.filterClick.bind(this, 'noCall', null)}
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>            
                    </li>


                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        No Tour
                        <input type="checkbox"
                          name="noTour"
                          checked={noTour}
                          className="custom-checkbox--input"
                          onChange={this.filterClick.bind(this, 'noTour', null)}
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>            
                    </li>


                    {/*<li>
                      <label onClick={this.filterClick.bind(this, "createdDate")} className="custom-checkbox custom-checkbox-line">
                        Start Date
                            <input type="checkbox"
                          name="createdDate"
                          checked={this.state.createdDate}
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    {this.state.createdDate && <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                        <DatePicker
                          className="datepicker__follow-up-date form-control search__form__input"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          placeholder="Select Date"
                          format="MM/DD/YYYY"
                          onChange={this.handleDateChange}
                          value={this.state.selectedCreatedDate}
                          placeholder="Select Date"
                        />
                      </div>
                    </ul>}*/}
                  </ul>
                </div>
              </div>
            }
            placement="bottom"
            className="filter"
            overlayClassName="filter__overlay"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.filterVisible}
          >
            <button className="btn btn__btn btn-dark btn-filter"><FilterIcon className="filter-icon" /> Filters {filterCount > 0 && `(${filterCount})`}</button>
          </Popover>
          {/* End filter section */}

          <div className="material-textfield">
            <select
              className="form-control custom-select material-textfield-input results__select"
              name="resultEntity"
              onChange={this.handleResultEntity}
              value={this.props.pageLimit}
            >
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
              <option value={100}>1000</option>
              <option value={2000}>2000</option>
              <option value={3000}>3000</option>
              <option value={4000}>4000</option>
              <option value={5000}>5000</option>
              <option value={6000}>6000</option>
              <option value={7000}>7000</option>
            </select>
            <label className="material-textfield-label">Results</label>
          </div>
        </div>
      </div>
    )
  }
}
