import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import { getClientStrategist, 
	    getClientAgent, scheduleMeeting } from '../../store/client/duck'
import { getAppointments, 
         getAllClients, 
         agentGetUsers,
         addAgentAppointment,
         getAgentAvailability,
         deleteAppointment,
         agentSearchGetUsers,
         clearPhase
        } from '../../store/agent/duck'
import AgentCalendarForm from './component'
const agentcalendar = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    appointmentData: state.agent.appointmentData,
    allclientData: state.agent.allclientData,
    allClientPhase: state.agent.allClientPhase,
    usersData: state.agent.usersData,
    availabilityData: state.agent.availabilityData,
    userPhase: state.agent.userPhase,
    agentSearchGetUsersData: state.agent.agentSearchGetUsersData,
    searchUserPhase: state.agent.searchUserPhase,
    addAppointmentPhase: state.agent.addAppointmentPhase,
    deleteAppointmentPhase: state.agent.deleteAppointmentPhase,
    deleteAppointmentData: state.agent.deleteAppointmentData,
    addAppointmentMessage: state.agent.addAppointmentMessage
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getAllClients,
    agentGetUsers,
    getAgentAvailability,
    addAgentAppointment,
    getAppointments,
    deleteAppointment,
    clearPhase,
    agentSearchGetUsers
  }
)(AgentCalendarForm)
export default agentcalendar
