import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import HttpStatus from 'http-status-codes';
import { assign } from 'lodash';
import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();
export const GET_CLIENT_STRATEGIST = 'suburban/client/GET_CLIENT_STRATEGIST';
export const GET_CLIENT_STRATEGIST_SUCCESS = 'suburban/client/GET_CLIENT_STRATEGIST_SUCCESS';
export const GET_CLIENT_STRATEGIST_ERROR = 'suburban/client/GET_CLIENT_STRATEGIST_ERROR';

export const GET_CLIENTS_BY_TOWN = 'suburban/client/GET_CLIENTS_BY_TOWN';
export const GET_CLIENTS_BY_TOWN_SUCCESS = 'suburban/client/GET_CLIENTS_BY_TOWN_SUCCESS';
export const GET_CLIENTS_BY_TOWN_ERROR = 'suburban/client/GET_CLIENTS_BY_TOWN_ERROR';

export const GET_CLIENT_STRATEGIST_DATA = 'suburban/client/GET_CLIENT_STRATEGIST_DATA';
export const GET_CLIENT_STRATEGIST_DATA_SUCCESS = 'suburban/client/GET_CLIENT_STRATEGIST_DATA_SUCCESS';
export const GET_CLIENT_STRATEGIST_DATA_ERROR = 'suburban/client/GET_CLIENT_STRATEGIST_DATA_ERROR';

export const GET_CLIENT_STRATEGIST_AVAILLABILITY = 'suburban/client/GET_CLIENT_STRATEGIST_AVAILLABILITY';
export const GET_CLIENT_STRATEGIST_AVAILLABILITY_SUCCESS = 'suburban/client/GET_CLIENT_STRATEGIST_AVAILLABILITY_SUCCESS';
export const GET_CLIENT_STRATEGIST_AVAILLABILITY_ERROR = 'suburban/client/GET_CLIENT_STRATEGIST_AVAILLABILITY_ERROR';

export const GET_CLIENT_AGENT = 'suburban/client/GET_CLIENT_AGENT';
export const GET_CLIENT_AGENT_SUCCESS = 'suburban/client/GET_CLIENT_AGENT_SUCCESS';
export const GET_CLIENT_AGENT_ERROR = 'suburban/client/GET_CLIENT_AGENT_ERROR';

export const SCHEDULE_MEETING = 'suburban/client/SCHEDULE_MEETING';
export const SCHEDULE_MEETING_SUCCESS = 'suburban/client/SCHEDULE_MEETING_SUCCESS';
export const SCHEDULE_MEETING_ERROR = 'suburban/client/SCHEDULE_MEETING_ERROR';

export const GET_TOWN = 'suburban/client/GET_TOWN';
export const GET_TOWN_SUCCESS = 'suburban/client/GET_TOWN_SUCCESS';
export const GET_TOWN_ERROR = 'suburban/client/GET_TOWN_ERROR';

export const ADD_TOWN = 'suburban/client/ADD_TOWN'
export const ADD_TOWN_SUCCESS = 'suburban/client/ADD_TOWN_SUCCESS'
export const ADD_TOWN_ERROR = 'suburban/client/ADD_TOWN_ERROR'

export const TOWN_RECOMMENDED = 'suburban/client/TOWN_RECOMMENDED'
export const TOWN_RECOMMENDED_SUCCESS = 'suburban/client/TOWN_RECOMMENDED_SUCCESS'
export const TOWN_RECOMMENDED_ERROR = 'suburban/client/TOWN_RECOMMENDED_ERROR'

export const REMOVE_TOWN = 'suburban/client/REMOVE_TOWN'
export const REMOVE_TOWN_SUCCESS = 'suburban/client/REMOVE_TOWN_SUCCESS'
export const REMOVE_TOWN_ERROR = 'suburban/client/REMOVE_TOWN_ERROR'

export const GET_INSIGHT_DATA = 'suburban/client/GET_INSIGHT_DATA'
export const GET_INSIGHT_DATA_SUCCESS = 'suburban/client/GET_INSIGHT_DATA_SUCCESS'
export const GET_INSIGHT_DATA_ERROR = 'suburban/client/GET_INSIGHT_DATA_ERROR'

export const GET_CLIENTS_SEARCH = 'suburban/client/GET_CLIENTS_SEARCH'
export const GET_CLIENTS_SEARCH_SUCCESS = 'suburban/client/GET_CLIENTS_SEARCH_SUCCESS'
export const GET_CLIENTS_SEARCH_ERROR = 'suburban/client/GET_CLIENTS_SEARCH_ERROR'
export const GET_CLIENTS_SEARCH_CANCELLED = 'suburban/client/GET_CLIENTS_SEARCH_CANCELLED'

export const GET_TOWN_RATE_DATA = 'suburban/client/GET_TOWN_RATE_DATA';
export const GET_TOWN_RATE_DATA_SUCCESS = 'suburban/client/GET_TOWN_RATE_DATA_SUCCESS';
export const GET_TOWN_RATE_DATA_ERROR = 'suburban/client/GET_TOWN_RATE_DATA_ERROR';

export const GET_TOWN_RATE_DATA_BY_ID = 'suburban/client/GET_TOWN_RATE_DATA_BY_ID';
export const GET_TOWN_RATE_DATA_BY_ID_SUCCESS = 'suburban/client/GET_TOWN_RATE_DATA_BY_ID_SUCCESS';
export const GET_TOWN_RATE_DATA_BY_ID_ERROR = 'suburban/client/GET_TOWN_RATE_DATA_BY_ID_ERROR';

export const GET_AGENT_LAST_MESSAGE = 'suburban/client/GET_AGENT_LAST_MESSAGE';
export const GET_AGENT_LAST_MESSAGE_SUCCESS = 'suburban/client/GET_AGENT_LAST_MESSAGE_SUCCESS';
export const GET_AGENT_LAST_MESSAGE_ERROR = 'suburban/client/GET_AGENT_LAST_MESSAGE_ERROR';

export const SHARE_CLIENT_INFO_TO_AGENT = 'suburban/client/SHARE_CLIENT_INFO_TO_AGENT';
export const SHARE_CLIENT_INFO_TO_AGENT_SUCCESS = 'suburban/client/SHARE_CLIENT_INFO_TO_AGENT_SUCCESS';
export const SHARE_CLIENT_INFO_TO_AGENT_ERROR = 'suburban/client/SHARE_CLIENT_INFO_TO_AGENT_ERROR';

export const UPDATE_CLIENT_TOWN_RATE = 'suburban/client/UPDATE_CLIENT_TOWN_RATE';
export const UPDATE_CLIENT_TOWN_RATE_SUCCESS = 'suburban/client/UPDATE_CLIENT_TOWN_RATE_SUCCESS';
export const UPDATE_CLIENT_TOWN_RATE_ERROR = 'suburban/client/UPDATE_CLIENT_TOWN_RATE_ERROR';

export const CLEAR_DATE_PHASE = 'suburban/client/CLEAR_DATE_PHASE'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  getStrategistAvaillabilityData: [],
  getStrategistAvaillabilityPhase: INIT,
  getStrategistPhase: INIT,
  getStrategistDataPhase: INIT,
  strategistData: [],
  getStrategistData: [],
  getAgentPhase: INIT,
  submitSchedulePhase: INIT,
  getTownPhase: INIT,
  addTownPhase: INIT,
  recommendedTownPhase: INIT,
  removeTownPhase: INIT,
  removeTownData: [],
  recommendedTownData: [],
  addTownData: [],
  getTownData: [],
  agentData: [],
  insightPhase: INIT,
  insightData: [],
  searchedClients: [],
  getRateTownPhase: INIT,
  getRateTownData: [],
  updateClientTownRatePhase: [],
  updateClientTownRateData: [],
  getTownAndAgentRatePhase: INIT,
  getTownAndAgentRateData: [],
  is_rated_town: false,
  is_rated_agent: false,
  townClients: [],
  townClientsPhase: INIT,
  getAgentLastMessagePhase: INIT,
  getAgentLastMessageData: [],
  shareClientInfoPhase: INIT,
  shareClientInfoData: [],
  error: null,
  isSubmitting: false,
  resMessage: null
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = cookies.get('Authorization')
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_CLIENT_STRATEGIST: {
      return state
        .set('getStrategistPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_STRATEGIST_SUCCESS: {
      const { payload } = action;
      if(payload.strategy && payload.strategy.length > 0) {
        payload.strategy.forEach(function(s) {
          if(s.role === "admin") {
            s.role = "strategist"
          }
        })
      }
      return state
        .set('strategistData', payload.strategy)
        .set('getStrategistPhase', payload.success)
        .set('error', null);
    }

    case GET_CLIENT_STRATEGIST_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getStrategistPhase', ERROR);
    }

    case GET_CLIENT_STRATEGIST_DATA: {
      return state
        .set('getStrategistDataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_STRATEGIST_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('getStrategistData', payload.data)
        .set('getStrategistDataPhase', payload.status)
        .set('error', null);
    }

    case GET_CLIENTS_BY_TOWN: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('townClientsPhase', LOADING);
    }

    case GET_CLIENTS_BY_TOWN_SUCCESS: {
      return state
        .set('townClientsPhase', SUCCESS)
        .set('townClients', action.payload)
        .set('isSubmitting', true);
    }

    case GET_CLIENTS_BY_TOWN_ERROR: {
      const { payload } = action;
      return state
        .set('getStrategistData', payload.data)
        .set('townClientsPhase', ERROR)
        .set('error', null);
    }

    case GET_CLIENT_STRATEGIST_DATA_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getStrategistDataPhase', ERROR);
    }

    case GET_CLIENT_STRATEGIST_DATA: {
      return state
        .set('insightPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_STRATEGIST_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('insightData', payload.data)
        .set('insightPhase', SUCCESS)
        .set('error', null);
    }

    case GET_CLIENT_STRATEGIST_DATA_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('insightPhase', ERROR);
    }

    case GET_CLIENT_STRATEGIST_AVAILLABILITY: {
      return state
        .set('getStrategistAvaillabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_STRATEGIST_AVAILLABILITY_SUCCESS: {
      const { payload } = action;
      return state
        .set('getStrategistAvaillabilityData', payload.data)
        .set('getStrategistAvaillabilityPhase', payload.status)
        .set('error', null);
    }

    case GET_CLIENT_STRATEGIST_AVAILLABILITY_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getStrategistAvaillabilityPhase', ERROR);
    }

    case GET_CLIENT_AGENT: {
      return state
        .set('getAgentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_AGENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('agentData', payload.data)
        .set('getAgentPhase', SUCCESS)
        .set('error', null);
    }

    case GET_CLIENT_AGENT_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getAgentPhase', ERROR);
    }

    case GET_INSIGHT_DATA: {
      return state
        .set('insightPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_INSIGHT_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('insightData', payload.data)
        .set('insightPhase', SUCCESS)
        .set('error', null);
    }

    case GET_INSIGHT_DATA_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('insightPhase', ERROR);
    }

    case GET_TOWN_RATE_DATA_BY_ID: {
      return state
        .set('getTownAndAgentRatePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TOWN_RATE_DATA_BY_ID_SUCCESS: {
      const { payload } = action;
      return state
        .set('getTownAndAgentRateData', payload.data)
        .set('is_rated_town', payload.is_rated)
        .set('is_rated_agent', payload.is_agent_rated)
        .set('getTownAndAgentRatePhase', SUCCESS)
        .set('error', null);
    }

    case GET_TOWN_RATE_DATA_BY_ID_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getTownAndAgentRatePhase', ERROR);
    }

    case SCHEDULE_MEETING: {
      return state
        .set('submitSchedulePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SCHEDULE_MEETING_SUCCESS: {
      const { payload } = action;
      return state
        .set('submitSchedulePhase', payload.status)
        .set('resMessage', payload.message)
        .set('error', null);
    }

    case SCHEDULE_MEETING_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('submitSchedulePhase', ERROR);
    }

    case GET_TOWN: {
      return state
        .set('getTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TOWN_SUCCESS: {
      const { payload } = action;
      return state
        .set('getTownPhase', payload.status)
        .set('getTownData', payload.data)
        .set('error', null);
    }

    case GET_TOWN_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getTownPhase', ERROR);
    }
    
    case GET_AGENT_LAST_MESSAGE: {
      return state
        .set('getAgentLastMessagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_AGENT_LAST_MESSAGE_SUCCESS: {
      const { payload } = action;
      return state
        .set('getAgentLastMessagePhase', payload)
        .set('getAgentLastMessageData', payload)
        .set('error', null);
    }

    case GET_AGENT_LAST_MESSAGE_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getAgentLastMessagePhase', ERROR);
    }

    case UPDATE_CLIENT_TOWN_RATE: {
      return state
        .set('updateClientTownRatePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_CLIENT_TOWN_RATE_SUCCESS: {
      const { payload } = action;
      return state
        .set('updateClientTownRatePhase', payload.status)
        .set('updateClientTownRateData', payload.data)
        .set('error', null);
    }

    case UPDATE_CLIENT_TOWN_RATE_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('updateClientTownRatePhase', ERROR);
    }

    case SHARE_CLIENT_INFO_TO_AGENT: {
      return state
        .set('shareClientInfoPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SHARE_CLIENT_INFO_TO_AGENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('shareClientInfoPhase', payload.status)
        .set('shareClientInfoData', payload.data)
        .set('error', null);
    }

    case SHARE_CLIENT_INFO_TO_AGENT_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('shareClientInfoPhase', ERROR);
    }

    case ADD_TOWN: {
      return state
        .set('addTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_TOWN_SUCCESS: {
      const { payload } = action;
      return state
        .set('addTownPhase', payload.status)
        .set('addTownData', payload.data)
        .set('error', null);
    }

    case ADD_TOWN_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('addTownPhase', ERROR);
    }

    case TOWN_RECOMMENDED: {
      return state
        .set('recommendedTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case TOWN_RECOMMENDED_SUCCESS: {
      const { payload } = action;
      return state
        .set('recommendedTownPhase', SUCCESS)
        .set('recommendedTownData', payload)
        .set('error', null);
    }

    case TOWN_RECOMMENDED_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('recommendedTownPhase', ERROR);
    }

    case REMOVE_TOWN: {
      return state
        .set('removeTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case REMOVE_TOWN_SUCCESS: {
      const { payload } = action;
      return state
        .set('removeTownPhase', SUCCESS)
        .set('removeTownData', payload)
        .set('error', null);
    }

    case GET_TOWN_RATE_DATA: {
      return state
        .set('getRateTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TOWN_RATE_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('getRateTownData', payload)
        .set('getRateTownPhase', SUCCESS)
        .set('error', null);
    }

    case GET_TOWN_RATE_DATA_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('getRateTownPhase', ERROR);
    }

    case REMOVE_TOWN_ERROR: {
      const { error } = action.payload;
      return state
      .set('error', error)
      .set('removeTownPhase', ERROR);
    }

    case GET_CLIENTS_SEARCH: {
      return state 
    }

    case GET_CLIENTS_SEARCH_SUCCESS: {
      const { payload } = action;
      return state
        .set('searchedClients', payload)
    }

    case CLEAR_DATE_PHASE: {
        return state
        .set('getStrategistDataPhase', INIT)
        .set('getStrategistAvaillabilityPhase', INIT)
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getClientStrategist = data => {
  return {
    type: GET_CLIENT_STRATEGIST,
    payload: { data }
  };
};

export const getClientStrategistData = data => {
  return {
    type: GET_CLIENT_STRATEGIST_DATA,
    payload: { data }
  };
}

export const getClientsByTown = data => {
  return {
    type: GET_CLIENTS_BY_TOWN,
    payload: {data}
  };
}

export const getClientStrategistAvaillability = data => {
  return {
    type: GET_CLIENT_STRATEGIST_AVAILLABILITY,
    payload: { data }
  };
}

export const getClientAgent = data => {
  return {
    type: GET_CLIENT_AGENT,
    payload: { data }
  };
};

export const scheduleMeeting = data => {
  return {
    type: SCHEDULE_MEETING,
    payload: { data }
  };
};

export const getTowns = data => {
  return {
    type: GET_TOWN,
    payload: { data }
  }
}

export const shareClientInfoToAgent = data => {
  return {
    type: SHARE_CLIENT_INFO_TO_AGENT,
    payload: { data }
  }
}

export const addTowns = data => {
  return {
    type: ADD_TOWN,
    payload: { data }
  }
}

export const getRecommendedTowns = data => {
  return {
    type: TOWN_RECOMMENDED,
    payload: { data }
  }
}

export const getClientTownRateDataById = data => {
  return {
    type: GET_TOWN_RATE_DATA_BY_ID,
    payload: { data }
  }
}

export const removeTown = data => {
  return {
    type: REMOVE_TOWN,
    payload: { data }
  }
}

export const getClientAgentMessage = data => {
  return {
    type: GET_AGENT_LAST_MESSAGE,
    payload: { data }
  }
}

export const updateClientTownRatingData = data => {
  return {
    type: UPDATE_CLIENT_TOWN_RATE,
    payload: { data }
  }
}

export const getSearchedClients = (data) => ({
  type: GET_CLIENTS_SEARCH,
  payload: api.getSearchedClients(data)
})

export const getInsightData = data => {
  return {
    type: GET_INSIGHT_DATA,
    payload: { data }
  }
}

export const getClientTownRateData = data => {
  return {
    type: GET_TOWN_RATE_DATA,
    payload: { data }
  }
}

export const clearDatePhase = () =>{
  return {
      type: CLEAR_DATE_PHASE
  }
}

/***********************************
 * Epics
 ***********/

const getClientStrategistEpic = action$ =>
  action$.ofType(GET_CLIENT_STRATEGIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientStrategist(action.payload.data))
      .map(payload => ({
        type: GET_CLIENT_STRATEGIST_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_STRATEGIST_ERROR,
          payload: { error }
        })
      );
  });

  const getClientStrategistDataEpic = action$ =>
  action$.ofType(GET_CLIENT_STRATEGIST_DATA).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientStrategistData(action.payload.data))
      .map(payload => ({
        type: GET_CLIENT_STRATEGIST_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_STRATEGIST_DATA_ERROR,
          payload: { error }
        })
      );
  });

  const getClientsByTownEpic = action$ =>
  action$.ofType(GET_CLIENTS_BY_TOWN).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientsByTown(action.payload.data))
      .map(payload => ({
        type: GET_CLIENTS_BY_TOWN_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENTS_BY_TOWN_ERROR,
          payload: { error }
        })
      );
  });

  
  const getClientStrategistAvaillabilityEpic = action$ =>
  action$.ofType(GET_CLIENT_STRATEGIST_AVAILLABILITY).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientStrategistAvaillability(action.payload.data))
      .map(payload => ({
        type: GET_CLIENT_STRATEGIST_AVAILLABILITY_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_STRATEGIST_AVAILLABILITY_ERROR,
          payload: { error }
        })
      );
  });

  const getClientAgentEpic = action$ =>
  action$.ofType(GET_CLIENT_AGENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientAgent(action.payload.data))
      .map(payload => ({
        type: GET_CLIENT_AGENT_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_AGENT_ERROR,
          payload: { error }
        })
      );
  });

  const scheduleMeetingEpic = action$ =>
  action$.ofType(SCHEDULE_MEETING).mergeMap(action => {
    return Rx.Observable.fromPromise(api.scheduleMeeting(action.payload.data))
      .map(payload => ({
        type: SCHEDULE_MEETING_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: SCHEDULE_MEETING_ERROR,
          payload: { error }
        })
      );
  });

  const getSearchedClientsEpic = action$ =>
  action$.ofType(GET_CLIENTS_SEARCH).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getSearchedClients(action.payload.data))
      .map(payload => ({
        type: GET_CLIENTS_SEARCH_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENTS_SEARCH_ERROR,
          payload: { error }
        })
      );
  });

  const getTownsEpic = action$ =>
  action$.ofType(GET_TOWN).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getTowns(action.payload.data))
      .map(payload => ({
        type: GET_TOWN_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_TOWN_ERROR,
          payload: { error }
        })
      );
  });

  const addTownsEpic = action$ =>
  action$.ofType(ADD_TOWN).mergeMap(action => {
    return Rx.Observable.fromPromise(api.addTowns(action.payload.data))
      .map(payload => ({
        type: ADD_TOWN_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: ADD_TOWN_ERROR,
          payload: { error }
        })
      );
  });

  const getRecommendedTownsEpic = action$ =>
  action$.ofType(TOWN_RECOMMENDED).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getRecommendedTowns(action.payload.data))
      .map(payload => ({
        type: TOWN_RECOMMENDED_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: TOWN_RECOMMENDED_ERROR,
          payload: { error }
        })
      );
  });

  const removeTownEpic = action$ =>
  action$.ofType(REMOVE_TOWN).mergeMap(action => {
    return Rx.Observable.fromPromise(api.removeTown(action.payload.data))
      .map(payload => ({
        type: REMOVE_TOWN_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: REMOVE_TOWN_ERROR,
          payload: { error }
        })
      );
  });

  const getInsightDataEpic = action$ =>
  action$.ofType(GET_INSIGHT_DATA).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getInsightData(action.payload.data))
      .map(payload => ({
        type: GET_INSIGHT_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_INSIGHT_DATA_ERROR,
          payload: { error }
        })
      )
  })

  const getClientTownRateDataEpic = action$ =>
  action$.ofType(GET_TOWN_RATE_DATA).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientTownRateData(action.payload.data))
      .map(payload => ({
        type: GET_TOWN_RATE_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_TOWN_RATE_DATA_ERROR,
          payload: { error }
        })
      )
  })

  const updateClientTownRatingDataEpic = action$ =>
  action$.ofType(UPDATE_CLIENT_TOWN_RATE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateClientTownRatingData(action.payload.data))
      .map(payload => ({
        type: UPDATE_CLIENT_TOWN_RATE_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_CLIENT_TOWN_RATE_ERROR,
          payload: { error }
        })
      )
  })

  const getClientTownRateDataByIdEpic = action$ =>
  action$.ofType(GET_TOWN_RATE_DATA_BY_ID).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientTownRateDataById(action.payload.data))
      .map(payload => ({
        type: GET_TOWN_RATE_DATA_BY_ID_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_TOWN_RATE_DATA_BY_ID_ERROR,
          payload: { error }
        })
      )
  })

  const getClientAgentMessageEpic = action$ =>
  action$.ofType(GET_AGENT_LAST_MESSAGE).mergeMap(action => {
  return Rx.Observable.fromPromise(api.getClientAgentMessage(action.payload.data))
    .map(payload => ({
      type: GET_AGENT_LAST_MESSAGE_SUCCESS,
      payload
    }))
    .catch(error =>
      Rx.Observable.of({
        type: GET_AGENT_LAST_MESSAGE_ERROR,
        payload: { error }
      })
    )
  })

  const shareClientInfoToAgentEpic = action$ =>
  action$.ofType(SHARE_CLIENT_INFO_TO_AGENT).mergeMap(action => {
  return Rx.Observable.fromPromise(api.shareClientInfoToAgent(action.payload.data))
    .map(payload => ({
      type: SHARE_CLIENT_INFO_TO_AGENT_SUCCESS,
      payload
    }))
    .catch(error =>
      Rx.Observable.of({
        type: SHARE_CLIENT_INFO_TO_AGENT_ERROR,
        payload: { error }
      })
    )
  })

export const clientEpic = combineEpics(
  getClientStrategistEpic,
  getClientAgentEpic,
  scheduleMeetingEpic,
  getSearchedClientsEpic,
  getClientStrategistDataEpic,
  getClientStrategistAvaillabilityEpic,
  getTownsEpic,
  addTownsEpic,
  getRecommendedTownsEpic,
  removeTownEpic,
  getInsightDataEpic,
  getClientTownRateDataEpic,
  updateClientTownRatingDataEpic,
  getClientTownRateDataByIdEpic,
  getClientAgentMessageEpic,
  shareClientInfoToAgentEpic,
  getClientsByTownEpic
);
