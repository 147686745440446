import { connect } from 'react-redux'

import {
  fetchProspectus,
  fetchProspectusById,
  createProspectus,
  clearProspectusPhase,
} from '../../../../../store/strategistV2/prospectus/prospectus/actions'

import { fetchClientById, clearClientPhase } from '../../../../../store/strategistV2/clients/action'
import { getFilterParams } from '../../../../../store/strategistV2/dashboard/actions'
import ProspectusInformationComponent from './component'
import {
  fetchFollowUpDate
} from '../../../../../store/strategistV2/prospectus/followup/actions'

const ProspectusInformationContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    fetchClientByIdPhase: state.strategistV2.client.fetchClientByIdPhase,
    prospectus: state.strategistV2.prospectus.prospectus.prospectus,
    prospectusDetail: state.strategistV2.prospectus.prospectus.prospectusDetail,
    fetchProspectusPhase: state.strategistV2.prospectus.prospectus.fetchProspectusPhase,
    createProspectusPhase: state.strategistV2.prospectus.prospectus.createProspectusPhase,
    fetchProspectusByIdPhase: state.strategistV2.prospectus.prospectus.fetchProspectusByIdPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    profilePhase: state.strategistV2.dashboard.profilePhase,
  }),
  // Map actions to dispatch and props
  {
    fetchProspectus,
    createProspectus,
    fetchProspectusById,
    clearProspectusPhase,
    fetchClientById,
    getFilterParams,
    clearClientPhase,
    fetchFollowUpDate
  }
)(ProspectusInformationComponent)

export default ProspectusInformationContainer
