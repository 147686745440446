import Rx from 'rxjs/Rx'
import { Record, List } from 'immutable'
import { combineEpics } from 'redux-observable'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
import * as api from './api'
import { get } from 'lodash'

/**
 * Public: Action Types
 */

export const GET_QUESTIONNAIRES_COMPLETED_REPORT = 'suburban/reportV2/GET_QUESTIONNAIRES_COMPLETED_REPORT'
export const GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS = 'suburban/reportV2/GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS'
export const GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR = 'suburban/reportV2/GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR'

export const CLIENTS_PER_AGENT_REPORT = 'suburban/dashboard/CLIENTS_PER_AGENT_REPORT'
export const CLIENTS_PER_AGENT_REPORT_SUCCESS = 'suburban/dashboard/CLIENTS_PER_AGENT_REPORT_SUCCESS'
export const CLIENTS_PER_AGENT_REPORT_ERROR = 'suburban/dashboard/CLIENTS_PER_AGENT_REPORT_ERROR'

export const APPOINTMENT_SCHEDULE_REPORT = 'suburban/dashboard/APPOINTMENT_SCHEDULE_REPORT'
export const APPOINTMENT_SCHEDULE_REPORT_SUCCESS = 'suburban/dashboard/APPOINTMENT_SCHEDULE_REPORT_SUCCESS'
export const APPOINTMENT_SCHEDULE_REPORT_ERROR = 'suburban/dashboard/APPOINTMENT_SCHEDULE_REPORT_ERROR'

export const AGENT_ASS_TO_TOWN = 'suburban/dashboard/AGENT_ASS_TO_TOWN'
export const AGENT_ASS_TO_TOWN_SUCCESS = 'suburban/dashboard/AGENT_ASS_TO_TOWN_SUCCESS'
export const AGENT_ASS_TO_TOWN_ERROR = 'suburban/dashboard/AGENT_ASS_TO_TOWN_ERROR'

export const AGENT_RATING_REPORT = 'suburban/dashboard/AGENT_RATING_REPORT'
export const AGENT_RATING_REPORT_SUCCESS = 'suburban/dashboard/AGENT_RATING_REPORT_SUCCESS'
export const AGENT_RATING_REPORT_ERROR = 'suburban/dashboard/AGENT_RATING_REPORT_ERROR'

export const LOGIN_RECORD_REPORT = 'suburban/dashboard/LOGIN_RECORD_REPORT'
export const LOGIN_RECORD_REPORT_SUCCESS = 'suburban/dashboard/LOGIN_RECORD_REPORT_SUCCESS'
export const LOGIN_RECORD_REPORT_ERROR = 'suburban/dashboard/LOGIN_RECORD_REPORT_ERROR'

export const STRATEGIST_RATING_REPORT = 'suburban/dashboard/STRATEGIST_RATING_REPORT'
export const STRATEGIST_RATING_REPORT_SUCCESS = 'suburban/dashboard/STRATEGIST_RATING_REPORT_SUCCESS'
export const STRATEGIST_RATING_REPORT_ERROR = 'suburban/dashboard/STRATEGIST_RATING_REPORT_ERROR'

export const STRATEGIST_STATISTICS_REPORT = 'suburban/dashboard/STRATEGIST_STATISTICS_REPORT'
export const STRATEGIST_STATISTICS_REPORT_SUCCESS = 'suburban/dashboard/STRATEGIST_STATISTICS_REPORT_SUCCESS'
export const STRATEGIST_STATISTICS_REPORT_ERROR = 'suburban/dashboard/STRATEGIST_STATISTICS_REPORT_ERROR'

export const STRATEGIST_TIMESHEET_REPORT = 'suburban/dashboard/STRATEGIST_TIMESHEET_REPORT'
export const STRATEGIST_TIMESHEET_REPORT_SUCCESS = 'suburban/dashboard/STRATEGIST_TIMESHEET_REPORT_SUCCESS'
export const STRATEGIST_TIMESHEET_REPORT_ERROR = 'suburban/dashboard/STRATEGIST_TIMESHEET_REPORT_ERROR'

export const TOTAL_CLIENT_RECEIVED_PER_MONTH = 'suburban/dashboard/TOTAL_CLIENT_RECEIVED_PER_MONTH'
export const TOTAL_CLIENT_RECEIVED_PER_MONTH_SUCCESS = 'suburban/dashboard/TOTAL_CLIENT_RECEIVED_PER_MONTH_SUCCESS'
export const TOTAL_CLIENT_RECEIVED_PER_MONTH_ERROR = 'suburban/dashboard/TOTAL_CLIENT_RECEIVED_PER_MONTH_ERROR'

export const BUSINESS_DEVELOPMENT = 'suburban/dashboard/BUSINESS_DEVELOPMENT'
export const BUSINESS_DEVELOPMENT_SUCCESS = 'suburban/dashboard/BUSINESS_DEVELOPMENT_SUCCESS'
export const BUSINESS_DEVELOPMENT_ERROR = 'suburban/dashboard/BUSINESS_DEVELOPMENT_ERROR'

export const NO_INITIAL_AGENT_CALL = 'suburban/dashboard/NO_INITIAL_AGENT_CALL'
export const NO_INITIAL_AGENT_CALL_SUCCESS = 'suburban/dashboard/NO_INITIAL_AGENT_CALL_SUCCESS'
export const NO_INITIAL_AGENT_CALL_ERROR = 'suburban/dashboard/NO_INITIAL_AGENT_CALL_ERROR'

export const NO_INITIAL_AGENT_TOUR = 'suburban/dashboard/NO_INITIAL_AGENT_TOUR'
export const NO_INITIAL_AGENT_TOUR_SUCCESS = 'suburban/dashboard/NO_INITIAL_AGENT_TOUR_SUCCESS'
export const NO_INITIAL_AGENT_TOUR_ERROR = 'suburban/dashboard/NO_INITIAL_AGENT_TOUR_ERROR'

export const NOT_TOUCHED_IN_4_WEEKS = 'suburban/dashboard/NOT_TOUCHED_IN_4_WEEKS'
export const NOT_TOUCHED_IN_4_WEEKS_SUCCESS = 'suburban/dashboard/NOT_TOUCHED_IN_4_WEEKS_SUCCESS'
export const NOT_TOUCHED_IN_4_WEEKS_ERROR = 'suburban/dashboard/NOT_TOUCHED_IN_4_WEEKS_ERROR'

export const ACCOUNTING_EDIT_DEAL = 'suburban/dashboard/ACCOUNTING_EDIT_DEAL'
export const ACCOUNTING_EDIT_DEAL_SUCCESS = 'suburban/dashboard/ACCOUNTING_EDIT_DEAL_SUCCESS'
export const ACCOUNTING_EDIT_DEAL_ERROR = 'suburban/dashboard/ACCOUNTING_EDIT_DEAL_ERROR'

export const GET_AI_CLIENTS_REPORT= 'suburban/dashboard/GET_AI_CLIENTS_REPORT'
export const GET_AI_CLIENTS_REPORT_SUCCESS = 'suburban/dashboard/GET_AI_CLIENTS_REPORT_SUCCESS'
export const GET_AI_CLIENTS_REPORT_ERROR = 'suburban/dashboard/GET_AI_CLIENTS_REPORT_ERROR'

export const GET_AD_TRACKING_REPORT= 'suburban/dashboard/GET_AD_TRACKING_REPORT'
export const GET_AD_TRACKING_REPORT_SUCCESS = 'suburban/dashboard/GET_AD_TRACKING_REPORT_SUCCESS'
export const GET_AD_TRACKING_REPORT_ERROR = 'suburban/dashboard/GET_AD_TRACKING_REPORT_ERROR'

export const CLEAR_PHASE = "suburban/dashboard/CLEAR_PHASE"

/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  error: null,
  QuestionnairesReportPhase: INIT,
  QuestionnairesData: [],
  clientsPerAgentsPhase: INIT,
  clientsPerAgentsData: [],
  appointmentSchedulePhase: INIT,
  appointmentScheduleData: [],
  agentAssTownPhase: INIT,
  agentAssTownData: [],
  agentRatingReportPhase: INIT,
  agentRatingReportData: [],
  loginRecordPhase: INIT,
  loginRecordData: [],
  strategistRatingPhase: INIT,
  strategistRatingData: [],
  strategistStatPhase: INIT,
  strategistStatData: [],
  strategistTimePhase:INIT,
  strategistTimeData: [],
  totalPerMonthPhase: INIT,
  totalPerMonthData: [],
  businessDevelopmentPhase: INIT,
  businessDevelopmentData: [],
  initialAgentCallPhase: INIT,
  initialAgentCallData: [],
  noInitialAgentTourPhase: INIT,
  noInitialAgentTourData: [],
  notTouchedIn4weeksPhase: INIT,
  notTouchedIn4weeksData: [],
  accountingEditDealPhase: INIT,
  accountingEditDealData: [],
  aiClientsPhase: INIT,
  aiClientsData: [],
  adTrackingPhase: INIT,
  adTrackingData: []
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {
    case GET_QUESTIONNAIRES_COMPLETED_REPORT: {
      return state
        .set('error', null)
        .set('QuestionnairesReportPhase', LOADING)
    }

    case GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS: {
      return state
        .set('QuestionnairesData', action.payload)
        .set('error', null)
        .set('QuestionnairesReportPhase', SUCCESS)
    }

    case GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('QuestionnairesReportPhase', ERROR)
    }

    case CLIENTS_PER_AGENT_REPORT: {
      return state
        .set('clientsPerAgentsPhase', LOADING)
        .set('clientsPerAgentsData',[])
        .set('error', null)
    }

    case CLIENTS_PER_AGENT_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('clientsPerAgentsPhase', SUCCESS)
        .set('clientsPerAgentsData', payload)
        .set('error', null)
    }

    case CLIENTS_PER_AGENT_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientsPerAgentsPhase', ERROR)
    }
    
    case APPOINTMENT_SCHEDULE_REPORT: {
      return state
        .set('appointmentSchedulePhase', LOADING)
        .set('appointmentScheduleData',[])
        .set('error', null)
    }

    case APPOINTMENT_SCHEDULE_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('appointmentSchedulePhase', SUCCESS)
        .set('appointmentScheduleData', payload)
        .set('error', null)
    }

    case APPOINTMENT_SCHEDULE_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('appointmentSchedulePhase', ERROR)
    }
    
    case AGENT_ASS_TO_TOWN: {
      return state
        .set('agentAssTownPhase', LOADING)
        .set('agentAssTownData',[])
        .set('error', null)
    }

    case AGENT_ASS_TO_TOWN_SUCCESS: {
      const { payload } = action
      return state
        .set('agentAssTownPhase', SUCCESS)
        .set('agentAssTownData', payload)
        .set('error', null)
    }

    case AGENT_ASS_TO_TOWN_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentAssTownPhase', ERROR)
    }
    
    case AGENT_RATING_REPORT: {
      return state
        .set('agentRatingReportPhase', LOADING)
        .set('agentRatingReportData',[])
        .set('error', null)
    }

    case AGENT_RATING_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentRatingReportPhase', SUCCESS)
        .set('agentRatingReportData', payload)
        .set('error', null)
    }

    case AGENT_RATING_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentRatingReportPhase', ERROR)
    }

    /* This is for Login record  */
    case LOGIN_RECORD_REPORT: {
      return state
        .set('loginRecordPhase', LOADING)
        .set('loginRecordData',[])
        .set('error', null)
    }

    case LOGIN_RECORD_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('loginRecordPhase', SUCCESS)
        .set('loginRecordData', payload)
        .set('error', null)
    }

    case LOGIN_RECORD_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('loginRecordPhase', ERROR)
    }

    /* This is for strategis rating  */
    case STRATEGIST_RATING_REPORT: {
      return state
        .set('strategistRatingPhase', LOADING)
        .set('strategistRatingData',[])
        .set('error', null)
    }

    case STRATEGIST_RATING_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistRatingPhase', SUCCESS)
        .set('strategistRatingData', payload)
        .set('error', null)
    }

    case STRATEGIST_RATING_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistRatingPhase', ERROR)
    }
    
    /* This is for strategis statastics report  */
    case STRATEGIST_STATISTICS_REPORT: {
      return state
        .set('strategistStatPhase', LOADING)
        .set('strategistStatData',[])
        .set('error', null)
    }

    case STRATEGIST_STATISTICS_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistStatPhase', SUCCESS)
        .set('strategistStatData', payload)
        .set('error', null)
    }

    case STRATEGIST_STATISTICS_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistStatPhase', ERROR)
    }
    
    /* This is for strategis time sheet report  */
    case STRATEGIST_TIMESHEET_REPORT: {
      return state
        .set('strategistTimePhase', LOADING)
        .set('strategistTimeData', [])
        .set('error', null)
    }

    case STRATEGIST_TIMESHEET_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistTimePhase', SUCCESS)
        .set('strategistTimeData', payload)
        .set('error', null)
    }

    case STRATEGIST_TIMESHEET_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistTimePhase', ERROR)
    }
    
    /* This is for total client per month report  */
    case TOTAL_CLIENT_RECEIVED_PER_MONTH: {
      return state
        .set('totalPerMonthPhase', LOADING)
        .set('totalPerMonthData', [])
        .set('error', null)
    }

    case TOTAL_CLIENT_RECEIVED_PER_MONTH_SUCCESS: {
      const { payload } = action
      return state
        .set('totalPerMonthPhase', SUCCESS)
        .set('totalPerMonthData', payload)
        .set('error', null)
    }

    case TOTAL_CLIENT_RECEIVED_PER_MONTH_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('totalPerMonthPhase', ERROR)
    }
    
    /* This is for business development report  */
    case BUSINESS_DEVELOPMENT: {
      return state
        .set('businessDevelopmentPhase', LOADING)
        .set('businessDevelopmentData', [])
        .set('error', null)
    }

    case BUSINESS_DEVELOPMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('businessDevelopmentPhase', SUCCESS)
        .set('businessDevelopmentData', payload)
        .set('error', null)
    }

    case BUSINESS_DEVELOPMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('businessDevelopmentPhase', ERROR)
    }
    
    /* This is for no initial agent call report  */
    case NO_INITIAL_AGENT_CALL: {
      return state
        .set('initialAgentCallPhase', LOADING)
        .set('initialAgentCallData', [])
        .set('error', null)
    }

    case NO_INITIAL_AGENT_CALL_SUCCESS: {
      const { payload } = action
      return state
        .set('initialAgentCallPhase', SUCCESS)
        .set('initialAgentCallData', payload)
        .set('error', null)
    }

    case NO_INITIAL_AGENT_CALL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('initialAgentCallPhase', ERROR)
    }
    
    /* This is for no initial agent visit report  */
    case NO_INITIAL_AGENT_TOUR: {
      return state
        .set('noInitialAgentTourPhase', LOADING)
        .set('noInitialAgentTourData', [])
        .set('error', null)
    }

    case NO_INITIAL_AGENT_TOUR_SUCCESS: {
      const { payload } = action
      return state
        .set('noInitialAgentTourPhase', SUCCESS)
        .set('noInitialAgentTourData', payload)
        .set('error', null)
    }

    case NO_INITIAL_AGENT_TOUR_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('noInitialAgentTourPhase', ERROR)
    }
    
    /* This is for Not Touched by Agent in 4 Weeks Statistics report  */
    case NOT_TOUCHED_IN_4_WEEKS: {
      return state
        .set('notTouchedIn4weeksPhase', LOADING)
        .set('notTouchedIn4weeksData', [])
        .set('error', null)
    }

    case NOT_TOUCHED_IN_4_WEEKS_SUCCESS: {
      const { payload } = action
      return state
        .set('notTouchedIn4weeksPhase', SUCCESS)
        .set('notTouchedIn4weeksData', payload)
        .set('error', null)
    }

    case NOT_TOUCHED_IN_4_WEEKS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('notTouchedIn4weeksPhase', ERROR)
    }

    /* This is for account report edit deal data  */
    case ACCOUNTING_EDIT_DEAL: {
      return state
        .set('accountingEditDealPhase', LOADING)
        .set('error', null)
    }

    case ACCOUNTING_EDIT_DEAL_SUCCESS: {
      const { payload } = action
      return state
        .set('accountingEditDealPhase', SUCCESS)
        .set('accountingEditDealData', payload)
        .set('error', null)
    }

    case ACCOUNTING_EDIT_DEAL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('accountingEditDealPhase', ERROR)
    }

    /* This is for ai client report  */
    case GET_AI_CLIENTS_REPORT: {
      return state
        .set('aiClientsPhase', LOADING)
        .set('error', null)
    }

    case GET_AI_CLIENTS_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('aiClientsPhase', SUCCESS)
        .set('aiClientsData', payload)
        .set('error', null)
    }

    case GET_AI_CLIENTS_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('aiClientsPhase', ERROR)
    }
    
    /* This is for ad tracking code report  */
    case GET_AD_TRACKING_REPORT: {
      return state
        .set('adTrackingPhase', LOADING)
        .set('error', null)
    }

    case GET_AD_TRACKING_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('adTrackingPhase', SUCCESS)
        .set('adTrackingData', payload)
        .set('error', null)
    }

    case GET_AD_TRACKING_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('adTrackingPhase', ERROR)
    }

    case CLEAR_PHASE: {
      return state
        .set("QuestionnairesReportPhase", INIT)
        .set("clientsPerAgentsPhase", INIT)
        .set("appointmentSchedulePhase", INIT)
        .set("agentAssTownPhase", INIT)
        .set("agentRatingReportPhase", INIT)
        .set("loginRecordPhase", INIT)
        .set("strategistRatingPhase", INIT)
        .set("strategistStatPhase", INIT)
        .set("strategistStatData",  [])
        .set("strategistTimePhase",  INIT)
        .set("totalPerMonthPhase",  INIT)
        .set("businessDevelopmentPhase",  INIT)
        .set("initialAgentCallPhase",  INIT)
        .set("noInitialAgentTourPhase",  INIT)
        .set("notTouchedIn4weeksPhase",  INIT)
        .set("accountingEditDealPhase",  INIT)
        .set("aiClientsPhase",  INIT)
        .set("adTrackingPhase",  INIT)
    }

    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const getQuestionnairesCompletedClientList = (data) => ({
  type: GET_QUESTIONNAIRES_COMPLETED_REPORT,
  payload: data
})

export const getClientsPerAgent = (data) => ({
  type: CLIENTS_PER_AGENT_REPORT,
  payload: data
})

export const getAppointmentScheduled = (data) => ({
  type: APPOINTMENT_SCHEDULE_REPORT,
  payload: data
})

export const getAgentAssignToTown = (data) => ({
  type: AGENT_ASS_TO_TOWN,
  payload: data
})

export const getAgentRatingReport = (data) => ({
  type: AGENT_RATING_REPORT,
  payload: data
})

//for login record function
export const getLoginRecord = (data) => ({
  type: LOGIN_RECORD_REPORT,
  payload: data
})

//for strategist rating report function
export const getStrategistRating = (data) => ({
  type: STRATEGIST_RATING_REPORT,
  payload: data
})

//for strategist statatics report function
export const getStrategistStatistics = (data) => ({
  type: STRATEGIST_STATISTICS_REPORT,
  payload: data
})

//for strategist timesheet report function
export const getStrategistTimesheet = (data) => ({
  type: STRATEGIST_TIMESHEET_REPORT,
  payload: data
})

//for total per month clients report function
export const getTotalClientPerMonth = (data) => ({
  type: TOTAL_CLIENT_RECEIVED_PER_MONTH,
  payload: data
})

//for business development report function
export const getBusinessDevelopmentReport = (data) => ({
  type: BUSINESS_DEVELOPMENT,
  payload: data
})

//for business development report function
export const noInitialAgentCallData = (data) => ({
  type: NO_INITIAL_AGENT_CALL,
  payload: data
})

//for business development report function
export const getNoInitialAgentTourData = (data) => ({
  type: NO_INITIAL_AGENT_TOUR,
  payload: data
})

//for not touched in 4 weeks report function
export const getNotTouchedIn4Weeks = (data) => ({
  type: NOT_TOUCHED_IN_4_WEEKS,
  payload: data
})

//for accounting report edit deal data function
export const accountReportDealData = (data) => ({
  type: ACCOUNTING_EDIT_DEAL,
  payload: data
})

//for ai clients report data function
export const getAIClientsReport = (data) => ({
  type: GET_AI_CLIENTS_REPORT,
  payload: data
})

//for ad tracking code report data function
export const getAdTrackingCodeReport = (data) => ({
  type: GET_AD_TRACKING_REPORT,
  payload: data
})

export const clearReportV2Phase = () => {
  return {
    type: CLEAR_PHASE
  };
};

/**
 * Private: Epics
 */
  const getQuestionnairesCompletedClientListEpic = (action$) =>
  action$
  .ofType(GET_QUESTIONNAIRES_COMPLETED_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getQuestionnairesCompletedClientList(action.payload))
    .flatMap((payload) => ([{
      type: GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR,
      payload: { error }
    }))
  })

  const getClientsPerAgentEpic = (action$) =>
  action$
  .ofType(CLIENTS_PER_AGENT_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClientsPerAgent(action.payload))
    .flatMap((payload) => ([{
      type: CLIENTS_PER_AGENT_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: CLIENTS_PER_AGENT_REPORT_ERROR,
      payload: { error }
    }))
  })
  
  const getAppointmentScheduledEpic = (action$) =>
  action$
  .ofType(APPOINTMENT_SCHEDULE_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAppointmentScheduled(action.payload))
    .flatMap((payload) => ([{
      type: APPOINTMENT_SCHEDULE_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: APPOINTMENT_SCHEDULE_REPORT_ERROR,
      payload: { error }
    }))
  })
  
  const getAgentAssignToTownEpic = (action$) =>
  action$
  .ofType(AGENT_ASS_TO_TOWN)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAgentAssignToTown(action.payload))
    .flatMap((payload) => ([{
      type: AGENT_ASS_TO_TOWN_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: AGENT_ASS_TO_TOWN_ERROR,
      payload: { error }
    }))
  })
  
  const getAgentRatingReportEpic = (action$) =>
  action$
  .ofType(AGENT_RATING_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAgentRatingReport(action.payload))
    .flatMap((payload) => ([{
      type: AGENT_RATING_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: AGENT_RATING_REPORT_ERROR,
      payload: { error }
    }))
  })

  const getLoginRecordEpic = (action$) =>
  action$
  .ofType(LOGIN_RECORD_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getLoginRecord(action.payload))
    .flatMap((payload) => ([{
      type: LOGIN_RECORD_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: LOGIN_RECORD_REPORT_ERROR,
      payload: { error }
    }))
  })

  const getStrategistRatingEpic = (action$) =>
  action$
  .ofType(STRATEGIST_RATING_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getStrategistRating(action.payload))
    .flatMap((payload) => ([{
      type: STRATEGIST_RATING_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: STRATEGIST_RATING_REPORT_ERROR,
      payload: { error }
    }))
  })
  
  const getStrategistStatisticsEpic = (action$) =>
  action$
  .ofType(STRATEGIST_STATISTICS_REPORT)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getStrategistStatistics(action.payload))
    .flatMap((payload) => ([{
      type: STRATEGIST_STATISTICS_REPORT_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: STRATEGIST_STATISTICS_REPORT_ERROR,
      payload: { error }
    }))
  })
  
  const getStrategistTimesheetEpic = (action$) =>
    action$
    .ofType(STRATEGIST_TIMESHEET_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getStrategistTimesheet(action.payload))
      .flatMap((payload) => ([{
        type: STRATEGIST_TIMESHEET_REPORT_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: STRATEGIST_TIMESHEET_REPORT_ERROR,
        payload: { error }
      }))
    })
  
  const getTotalClientPerMonthEpic = (action$) =>
    action$
    .ofType(TOTAL_CLIENT_RECEIVED_PER_MONTH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getTotalClientPerMonth(action.payload))
      .flatMap((payload) => ([{
        type: TOTAL_CLIENT_RECEIVED_PER_MONTH_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: TOTAL_CLIENT_RECEIVED_PER_MONTH_ERROR,
        payload: { error }
      }))
    })
  
  const getBusinessDevelopmentReportEpic = (action$) =>
    action$
    .ofType(BUSINESS_DEVELOPMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getBusinessDevelopmentReport(action.payload))
      .flatMap((payload) => ([{
        type: BUSINESS_DEVELOPMENT_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: BUSINESS_DEVELOPMENT_ERROR,
        payload: { error }
      }))
    })
  
  const noInitialAgentCallDataEpic = (action$) =>
    action$
    .ofType(NO_INITIAL_AGENT_CALL)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.noInitialAgentCallData(action.payload))
      .flatMap((payload) => ([{
        type: NO_INITIAL_AGENT_CALL_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: NO_INITIAL_AGENT_CALL_ERROR,
        payload: { error }
      }))
    })
  
  const getNoInitialAgentTourDataEpic = (action$) =>
    action$
    .ofType(NO_INITIAL_AGENT_TOUR)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getNoInitialAgentTourData(action.payload))
      .flatMap((payload) => ([{
        type: NO_INITIAL_AGENT_TOUR_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: NO_INITIAL_AGENT_TOUR_ERROR,
        payload: { error }
      }))
    })
  
  const getNotTouchedIn4WeeksEpic = (action$) =>
    action$
    .ofType(NOT_TOUCHED_IN_4_WEEKS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getNotTouchedIn4Weeks(action.payload))
      .flatMap((payload) => ([{
        type: NOT_TOUCHED_IN_4_WEEKS_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: NOT_TOUCHED_IN_4_WEEKS_ERROR,
        payload: { error }
      }))
    })
 
  const accountReportDealDataEpic = (action$) =>
    action$
    .ofType(ACCOUNTING_EDIT_DEAL)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.accountReportDealData(action.payload))
      .flatMap((payload) => ([{
        type: ACCOUNTING_EDIT_DEAL_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: ACCOUNTING_EDIT_DEAL_ERROR,
        payload: { error }
      }))
    })
  
  const getAIClientsReportEpic = (action$) =>
    action$
    .ofType(GET_AI_CLIENTS_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAIClientsReport(action.payload))
      .flatMap((payload) => ([{
        type: GET_AI_CLIENTS_REPORT_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: GET_AI_CLIENTS_REPORT_ERROR,
        payload: { error }
      }))
    })
  
  const getAdTrackingCodeReportEpic = (action$) =>
    action$
    .ofType(GET_AD_TRACKING_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAdTrackingCodeReport(action.payload))
      .flatMap((payload) => ([{
        type: GET_AD_TRACKING_REPORT_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: GET_AD_TRACKING_REPORT_ERROR,
        payload: { error }
      }))
    })

 /**
 * Public: Export Epics
 */

export const reportV2Epic = combineEpics(
  getQuestionnairesCompletedClientListEpic,
  getClientsPerAgentEpic,
  getAppointmentScheduledEpic,
  getAgentAssignToTownEpic,
  getAgentRatingReportEpic,
  getLoginRecordEpic,
  getStrategistRatingEpic,
  getStrategistStatisticsEpic,
  getStrategistTimesheetEpic,
  getTotalClientPerMonthEpic,
  getBusinessDevelopmentReportEpic,
  noInitialAgentCallDataEpic,
  getNoInitialAgentTourDataEpic,
  getNotTouchedIn4WeeksEpic,
  accountReportDealDataEpic,
  getAIClientsReportEpic,
  getAdTrackingCodeReportEpic
)
