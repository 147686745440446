import { connect } from 'react-redux'

import BusinessDevelopmentComponent from './component'
import { getBusinessDevelopmentData, clearPhase } from '../../../../../../store/analytics/duck'

const BusinessDevelopmentContainer = connect(
  // Map state to props
  (state) => ({
    businessDevelopmentPhase: state.analytics.businessDevelopmentPhase,
    businessDevelopmentData: state.analytics.businessDevelopmentData
  }),
  // Map actions to dispatch and props
  {
    getBusinessDevelopmentData,
    clearPhase
  }
)(BusinessDevelopmentComponent)

export default BusinessDevelopmentContainer
