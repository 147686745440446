import "rxjs";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import $ from "jquery";
import _ from 'lodash'
import Cookies from "universal-cookie";
import Modal from 'react-responsive-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import Modal2 from 'react-modal';
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class AgentAssignedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suburbsFilter: false,
      isLoadingData: false,
      suburb_list: [],
      suburbDropdown: false,
      sortByKey:'town_name',
      sortByType: 0,
      openClient:false,
      currentAgentClients:[],
      openTag:false,
      currentAgentTags:[],
      openStr:false,
      search: '',
      townName:'',
      openAgent:false,
      currentTownAgents:[],
      exportData: []
    }
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      this.props.getFilterParams()
      this.setState({ isLoadingData: true, userRole: _user.role })
      const data = {}
      data.suburb_arr = []
      data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.agentAssignedTown(data)
      let usrdata = {
        user_id: _user.id
      }
      this.props.getUSerProfileByID(usrdata)
    }
  }

  componentWillReceiveProps(np) {
      if(np.agentAssToTownPhase !== this.props.agentAssToTownPhase){
        const exportArr = []
        if(np.agentAssToTownData && np.agentAssToTownData.data && np.agentAssToTownData.data.length > 0){
          np.agentAssToTownData.data.map(function(data){
            let obj ={}
            obj.name = data.name
            obj.totalAgents = data.agents.length > 0 ? data.agents.map(e => { return (e.first_name+' '+e.last_name)}).join(" ,") : ""
            exportArr.push(obj)
          }) 
          this.setState({ isLoadingData: false, exportData: exportArr })
        }
        if(np.agentAssToTownData && np.agentAssToTownData.data && np.agentAssToTownData.data.length == 0){
          this.setState({ isLoadingData: false, exportData: [] })
        }
    }
    if(np.agentAssToTownPhase === "error"){
      this.setState({  isLoadingData: false })
    }
  }

  toggledropDown(val) {
    if(val==='filterSuburb'){
      this.setState({ suburbDropdown: !this.state.suburbDropdown })
    }
  }

  filterStrategist(id, type){
    const { suburb_list } = this.state
    this.setState({ isLoadingData: true})
    if(type === 'suburbFilter'){
      if (suburb_list.indexOf(id) === -1) {
        suburb_list.push(id)
      } 
      else {
        suburb_list.splice(suburb_list.indexOf(id), 1)
      }
    }
    this.setState({ suburb_list : suburb_list },()=>{
      const data = {}
      data.suburb_arr = suburb_list
      data.sortBy = {key: 'town_name', type: 0}
      this.props.agentAssignedTown(data)
    })
  }

  onOpenAgentModal(agent) {
    const agents = agent && agent.agents
    if(agents.length > 1){
      $( "body" ).addClass( "overflow_visible" )
      this.setState({ townName: agent.name, openAgent: true, currentTownAgents: agents })
    }
  }

  onCloseAgentModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ townName: '', currentTownAgents: [], openAgent: false })
  }


  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }else{
      sortByType == 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType},()=>{
      const data = {}
      data.suburb_arr = this.state.suburb_list
      data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.agentAssignedTown(data)
    })
  }

  render() {
    const { filterParams, agentAssToTownData, userData } = this.props
    let allData = filterParams.data
    let suburbsData = userData && userData.user && userData.user.suburbs
    let suburbsArray = []
    if(this.state.userRole === 'strategist'){
        if(allData && allData.suburbs){
          _.forEach(allData.suburbs, a => {
              var o = _.find(suburbsData, b => a.id == b.id)
              if (o) {
                suburbsArray.push(a)
              }
          })
        }
    }else{
      suburbsArray = allData && allData.suburbs
    }

    let filterList = []
    let allSuburbs = suburbsArray
    if(allSuburbs){
      filterList = allSuburbs
      .filter(
        (allSuburbs) =>{
          return (
            (allSuburbs.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    let townAgentsArr = []
    let townAgentsData = agentAssToTownData && agentAssToTownData.data
    if(townAgentsData){
      townAgentsArr = townAgentsData.filter(
        (data) =>{
          return (
            (data.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }
    
    return (
      <div>

        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Agents Assigned To Town</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{agentAssToTownData && agentAssToTownData.data && agentAssToTownData.data.length} </span> Towns
              </h4>
            </div>
            <div className="col-md-12">
            <div className="reports__flex">
            
            <div className="right__cont right__cont--full">

            <span className="spanclsass">
                <div 
                  // className="strategist-search strategist-search-float" 
                  onClick={this.toggledropDown.bind(this, 'filterSuburb')}>
                  <input 
                    onChange={(e)=> this.onSearch(e)}
                    type="text"  
                    className="report__search--global width_300"
                    placeholder="Search Suburbs Name"/>
                </div>
              
                { 
                    this.state.suburbDropdown && 
                    <div className= "sel-outer selouter__not--touch ">
                      {filterList && filterList.map((sub, subIndex) => {
                        let selectedIndex = false;
                          _.find(this.state.suburb_list, function(o) {
                            if(o === sub.id){
                              selectedIndex = true;
                            } 
                        });
                        return (
                          <li key={subIndex} className="custom-select-option">
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {sub.name}
                              <input 
                                type="checkbox" 
                                checked = {selectedIndex} 
                                className="custom-select-option-checkbox" 
                                onClick={this.filterStrategist.bind(this,sub.id, 'suburbFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                        )
                      })}
                    </div>
                }
              </span>
              

              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={this.state.exportData} name="agnet_assigned_to_town_reprot">
                  <ExcelColumn label="Town Name" value="name" />
                  <ExcelColumn label="Agents Name" value="totalAgents" />
                </ExcelSheet>
              </ExcelFile>

              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">
              <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input reportsearchsminus add" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search"/>
              </span>
                <div className="jungler__list jungler__list--fullwidth useslist__extra">
                  <div className="table-responsive">
                    {this.state.isLoadingData === true ? (
                      <tr className="loader__center">
                          <img src="../../img/loader2.svg" />
                          <h5>Your agents assigned to towns report is generating, please wait.........</h5>
                      </tr>
                      ) : (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th onClick={this.sortData.bind(this , 'town_name')} >Town Name</th>
                            <th>Agent Name</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          townAgentsArr && townAgentsArr.length > 0 ?
                          townAgentsArr && townAgentsArr.map((val, idx)=>{
                            const totalAgent = val && val.agents
                            let agentNo = (totalAgent.length-1) <= 0 ? '' : " (+"+(totalAgent.length-1)+")"
                            
                            return(
                              <tr key={idx} className="forcursor" >
                                <td>{val.name}</td>
                                {
                                  agentNo !== '' && totalAgent.length > 1  ? (
                                    <td className="wrap-text multilines"><div className={(totalAgent.length > 1 ? "link" : '')} onClick={this.onOpenAgentModal.bind(this,val)}>{( totalAgent.length >= 1 ? (totalAgent[0].first_name+' '+totalAgent[0].last_name )+ agentNo : 'No Agent' ) }</div></td>
                                  ):(
                                    agentNo == '' && totalAgent.length == 1 ? (
                                      <td><div className='' onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: {id:totalAgent[0].agent_id}})}}>{(totalAgent[0].first_name+' '+totalAgent[0].last_name)}</div></td>
                                    ):(
                                      <td><div className='' >No Agent</div></td>
                                    )
                                  )
                                }
                                
                              </tr>
                            )
                          })
                          :
                          <tr><td colSpan="2" className="not__found">Data Not Found</td></tr>
                        }
                          
                        </tbody>
                          <div>
                            <Modal2 isOpen={this.state.openAgent} center className="widthofmodal">
                              <div>
                                <div className="flexdiv">
                                  <h4 className="popupheader__heading">{'Town - ' + this.state.townName+`'s`+' agent(s)'}</h4>
                                  <div className="close__btn close_img--white" onClick={this.onCloseAgentModal.bind(this)}><img src="img/close_btn.svg"/></div>
                                </div>
                                <div className="allpoppadding__paranew__div">
                                  {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                                    let data = { id: agent.agent_id}
                                    return <div className="allpoppadding__paranew" onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: data})}} key={'agent-name-'+index}>{(agent.first_name+' '+agent.last_name)}</div>;
                                  })}
                                </div>
                              </div>
                              <button className="savebtn__assign" onClick={this.onCloseAgentModal.bind(this)}>Close</button>
                            </Modal2>
                          </div>
                        </table>
                      )}
                    </div>
                  </div>
                 </main>
                <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "AgentAssignedForm", // a unique identifier for this form
  destroyOnUnmount: true
})(AgentAssignedForm);
