import React, { PureComponent /*, PropTypes*/ } from 'react'
// import IPropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { Menu, Dropdown, Popover } from 'antd'
import { ChatIcon, DownCaretIcon, CalendarIcon, CloseIcon, LeadsIcon } from '../../icons'
import './styles.scss'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from '../../../utils/helper'

const cookies = new Cookies() // Import css
let _user = ''
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2

export default class AgentTopNavigationComponent extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      fNameFirstLatter: '',
      lNameFirstLatter: '',
      visible: false,
      showSearchList: false,
      agentChat: [],
      messageCount: 0,
      timeZone: moment.tz.guess(),
      agentClientChat:[],
      agentStrategistChat:[]
    }
    this.userLogout = this.userLogout.bind(this)
  }

  notificatonClose = () => {
    this.setState({
      visible: false,
    });
  }
  notificationOpen = visible => {
    this.setState({ visible });
  }

  componentDidMount = async() => {
    _user = cookies.get('user')
    let fNameFirstLatter = _.get(_user, 'first_name', '') ? _.upperFirst(_.get(_user, 'first_name', '').charAt(0)) : ''
    let lNameFirstLatter = _.get(_user, 'last_name', '') ? _.upperFirst(_.get(_user, 'last_name', '').charAt(0)) : ''
    this.setState({ fNameFirstLatter, lNameFirstLatter, timeZone: _user.time_zone})

    // const jwtToken = cookies.get('Authorization')
    // const res = await axios({
    //   method: 'GET',
    //   url: `${HOSTNAME_V2}/agent/chat/notifications`,
    //   'headers': { 'Authorization': jwtToken }
    // }).catch(logoutInvalidRequest)

    // const notifications = _.get(res, 'data', {})
    // this.setState({
    //   agentChat: _.get(notifications, 'agentChat', []),
    //   messageCount: _.get(notifications, 'messageCount', 0)
    // })

  }

  searchHandleChange(e){
    if(_.size(e.target.value) > 2){
      this.props.getSearchData(e.target.value)
      this.setState({ showSearchList: true })
    }else{
      this.setState({ showSearchList: false })
    }
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '' + match[1] + '.' + match[2] + '.' + match[3]
    }
    return null
  }

  onListClick(search) {
    if (search.role !== 'client') {
      return
    }

    this.setState({ showSearchList: false })
  }

  userLogout(){
    this.props.userSignout()
  }

  closeNotification() {
    this.setState({ visible: false })
  }

  clearAll = async() => {
    const jwtToken = cookies.get('Authorization')
    const userChatIds = []
    const agentStrategistChatsIds = []

    _.each(this.state.agentChat, record => {
      if (record.chat === 'strategist') {
        agentStrategistChatsIds.push(record.id)
      } else {
        userChatIds.push(record.id)
      }
    })
    const data = {
      userChatIds,
      agentStrategistChatsIds
    }
    const res = await axios({
      method: 'POST',
      url: `${HOSTNAME_V2}/agent/clear/notifications`,
      'headers': { 'Authorization': jwtToken },
      data
    }).catch(logoutInvalidRequest)
    this.setState({
      agentClientChat: [],
      agentStrategistChat: [],
      messageCount: 0,
      agentChat:[]
    })
  }
  closeAPICall = async(userChatIds, agentStrategistChatsIds) => {
    const jwtToken = cookies.get('Authorization')
    const data = {
      userChatIds,
      agentStrategistChatsIds
    }
    const res = await axios({
      method: 'POST',
      url: `${HOSTNAME_V2}/agent/clear/notifications`,
      'headers': { 'Authorization': jwtToken },
      data
    }).catch(logoutInvalidRequest)
  }
  navigateToClient = async(chat, index) => {
    const clientId = chat.client_id && chat.chat === 'strategist' ? chat.client_id : chat.sender_id
    if (clientId) {
      const data = [chat.id]
      if (chat.chat === 'strategist') {
        this.closeAPICall([], data)
      } else {
        this.closeAPICall(data, [])
      }
      
      this.props.history.push({ pathname: `/agent/client-details/${clientId}`, state: { chatTab: chat.chat } })
    }
  }

  render() {
    const {fNameFirstLatter, lNameFirstLatter } = this.state
    const { searchAgentData } = this.props
    return (
      <header className="header topnavigation-layout-header">
        <div className="topnavigation-layout-search">
          <div className="topnavigation-layout-wrapper">
            <input
              className="form-control topnavigation-layout-input"
              placeholder="Search for a client by name, email, or phone number..."
              onChange={this.searchHandleChange.bind(this)}
            />
            {
              this.state.showSearchList &&
              <div className="search-wrapper-open-container">
                <ul className="search-wrapper-options-menu">
                {
                  searchAgentData && searchAgentData.map((search, idx)=>{
                    return(
                      <li onClick={(e)=> this.onListClick(search)} key={idx} className="search-wrapper-options-menu-item">
                        <Link to={`/agent/client-details/${search.id}`}>
                         <div className="options-menu-item-title">
                            <span className="avatar">
                            {
                              search.avatar ?
                                <img src={search.avatar}/>
                              :
                              <img src="../img/img_v2/user-icon.png"/>
                            }
                            </span>
                            <span className="name">
                              {search.first_name+' '+search.last_name}
                            </span>
                            {search.share_info_to_all ? (<div className="email">{search.email ? search.email : ''}</div>) : ''}
                            {search.share_info_to_all ? (<div  className="phone">{search.phone_mobile ? (search.phone_mobile.replace(/\D/g, '').length == 10) ? this.formatNumber(search.phone_mobile.replace(/\D/g, '')) : search.phone_mobile.replace(/\D/g, '') : ''}</div>) : ''}
                         </div>
                        </Link>
                      </li>
                    )
                  })
                }
                </ul>
              </div>
            }

          </div>
        </div>

        {
          (_user && _user.role === "agent") &&
            <div className="topnavigation-layout-form">
            <Link className="btn btn-dark btn-calendar" to={'/agent/calendar'}><CalendarIcon className="calendar-icon"/>Calendar</Link>
          </div>
        }

      {/*<div className="topnavigation-layout-notification ml__auto">*/}
      {/*  {(_user && _user.role === "agent") &&*/}
      {/*      <Popover*/}
      {/*        trigger="click"*/}
      {/*        visible={this.state.visible}*/}
      {/*        onVisibleChange={this.notificationOpen}*/}
      {/*        className="notificaton__modal"*/}
      {/*        overlayClassName="notificaton__modal__overlay"*/}
      {/*        content={*/}
      {/*          <div className="notificaton__container">*/}
      {/*              <div className="notificaton__container__header">*/}
      {/*                <div className="notificaton__container__content">*/}
      {/*                  Unread Messages*/}
      {/*                </div>*/}
      {/*                <button className="btn btn__close__notify" onClick={this.closeNotification.bind(this)}><CloseIcon/></button>*/}
      {/*              </div>*/}
      {/*              {this.state.messageCount && this.state.messageCount > 0 ? */}
      {/*                (*/}
      {/*                  <div className="notificaton__container__body padding__less">*/}
      {/*                    <div className="text-right"><button onClick={this.clearAll.bind(this)} className="btn btn__clear_all">Clear All</button></div>*/}
      {/*                  </div>*/}
      {/*                ) : (<div className="notificaton__container__body margin__ten"><div className="text-center">You don't have any unread message.</div></div>)*/}
      {/*              }*/}
      {/*              <div className="notificaton__container__body">*/}
      {/*                {this.state.agentChat && this.state.agentChat.map((chat, index) => {*/}
      {/*                  return (*/}
      {/*                    <ul key={"agent__chat__strategist__"+index} className="notificaton__list" onClick={this.navigateToClient.bind(this,chat,index)}>*/}
      {/*                      <li className="active">*/}
      {/*                        <div className="notificaton__item">*/}
      {/*                          <div className="notificaton__title">You have a message from <strong>{chat.strategist_full_name}</strong> {chat.chat === "client" ? "client" : "about"} <strong>{chat.client_full_name}</strong></div>*/}
      {/*                          <div className="notificaton__card">*/}
      {/*                            {chat.content}*/}
      {/*                          </div>*/}
      {/*                          <div className="notificaton__delivery">{moment(chat.chat_time).tz(this.state.timeZone).format("MMMM DD, YYYY hh:mm a")}</div>*/}
      {/*                        </div>*/}
      {/*                      </li>*/}
      {/*                    </ul>*/}
      {/*                  )*/}
      {/*                })}*/}
      {/*              </div>*/}
      {/*          </div>*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <div className="chat-notificaton">*/}
      {/*          {this.state.messageCount && this.state.messageCount > 0 ? (<span className="chat-notificaton-count">{this.state.messageCount > 0 ? this.state.messageCount : ''}</span>) : ''}*/}
      {/*          <ChatIcon/>*/}
      {/*        </div>*/}
      {/*      </Popover>*/}
      {/*  }*/}
      {/*  </div>*/}

          <div className="topnavigation-layout-divider"></div>
          <div className="topnavigation-layout__profile">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={'/agent/update-profile'}>Edit Profile</Link>
                  </Menu.Item>
                  <Menu.Item onClick={()=>this.userLogout()} key="2">
                    Logout
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              overlayClassName="profile--dropdown--overlay"
            >
              <a className="ant-dropdown-link layout-profile-link" href="#">
                <div className="avatar">
                  {
                    (!_.isEmpty(_.get(this.props.userData, 'avatar', ''))) ?
                      <img src={_.get(this.props.userData, 'avatar', '')} alt=""/>
                      :
                      <span className="avatar-string">{fNameFirstLatter}{lNameFirstLatter}</span>
                  }
                </div>
                <div className="avatar-info">
                  <div className="avatar-name">
                    {`${_.get(this.props.userData, 'first_name', '')} ${ _.get(this.props.userData, 'last_name', '')}`}
                  </div>
                  <div  className="avatar-email">{_.get(this.props.userData, 'email', '')}</div>
                </div>
                <DownCaretIcon/>
              </a>
            </Dropdown>
          </div>
      </header>
    )
  }
}
