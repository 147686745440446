import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReactExport from "react-data-export"
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AppointmentScheduledComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      appointmentTableData: [],
      fromDate: moment().subtract(14, 'days'),
      endDate: moment(),
      todayDate: moment().format('LL'),
      dateValue: {}
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    let localData = JSON.parse(localStorage.getItem('dashboardFromToDate'))

    if (localData.to) {
      this.setState({dateValue: {from: localData.from, to: localData.to, val: localData.type}})
      const strData = {
        startDate: moment(localData.from).format("YYYY/MM/DD"),
        endDate  : moment(localData.to).format("YYYY/MM/DD")
      }
      this.props.getAppointmentScheduled(strData)
    }
  }

  componentDidUpdate(preProps, preState){
    const { appointmentSchedulePhase, appointmentScheduleData, clearReportV2Phase } = this.props
    if(appointmentSchedulePhase === "success"){
      clearReportV2Phase()
      let data = []
      const aptData = appointmentScheduleData && appointmentScheduleData.data
      if(_.size(aptData) > 0){
        aptData && aptData.map((apt, i)=>{
          const isScheduledThroughAcuity = apt.appointment_created && apt.booked_by === 'from_acuity_link'
          const isScheduledByClient = apt.who_scheduled && apt.who_scheduled === 'client'

          const appointmentDateObj = moment.utc(apt.appointment_created).toDate()

          const appointmentCreated = (isScheduledThroughAcuity || isScheduledByClient) ? moment(appointmentDateObj).local().format('YYYY-MM-DD hh:mm:A') : apt.appointment_created

          data.push({
            key: i,
            appointment_type: apt.appointment_type ? apt.appointment_type : '',
            booked_by: apt.booked_by && apt.booked_by !== '' ? apt.booked_by : (apt.who_scheduled ? apt.who_scheduled + ' jungler dash' :  'jungler dash'),
            client_name: apt.client_name ? apt.client_name : '',
            appointment_created: appointmentCreated ? appointmentCreated : '',
            role: apt.role ? apt.role : '',
            who_scheduled: apt.who_scheduled ? apt.who_scheduled : ''
          })
        })
      }
      this.setState({ appointmentTableData: data, isLoading: false })
    }
    if(appointmentSchedulePhase === "error"){
      clearReportV2Phase()
      this.setState({ appointmentTableData: [], isLoading: false })
    }
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to, dateValue: {from, to, val} })
    const strData = {
      startDate: moment(from).format("YYYY/MM/DD"),
      endDate  : moment(to).format("YYYY/MM/DD")
    }
    this.props.getAppointmentScheduled(strData)
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { appointmentTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Strategist Appointments</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={appointmentTableData} name="appointment_schedule">
                          <ExcelColumn label="Client Name" value="client_name"/>
                          <ExcelColumn label="Who Scheduled" value="who_scheduled"/>
                          <ExcelColumn label="Scheduled With" value="role"/>
                          <ExcelColumn label="Booked How" value="booked_by"/>
                          <ExcelColumn label="Appointment Type" value="appointment_type"/>
                          <ExcelColumn label="Appointment Date" value="appointment_created"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">

                </div>
                <div className="col-md-4">
                   <ReportCustomeDatePicker filterByDate={this.filterByDate} dateValue={this.state.dateValue} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(appointmentTableData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Client Name',
                              width: 250,
                              dataIndex: 'client_name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.client_name.localeCompare(b.client_name)
                            },
                            {
                              title: 'Who Scheduled',
                              width: 200,
                              dataIndex: 'who_scheduled',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.who_scheduled.localeCompare(b.who_scheduled)
                            },
                            {
                              title: 'Scheduled With',
                              dataIndex: 'role',
                              key: '3',
                              width: 200,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.role.localeCompare(b.role)
                            },
                            {
                              title: 'Booked How',
                              dataIndex: 'booked_by',
                              key: '4',
                              width: 320,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.booked_by.localeCompare(b.booked_by)
                            },
                            {
                              title: 'Appointment Type',
                              dataIndex: 'appointment_type',
                              key: '5',
                              width: 290,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.appointment_type.localeCompare(b.appointment_type)
                            },
                            {
                              title: 'Appointment Date',
                              dataIndex: 'appointment_created',
                              key: '5',
                              width: 300,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.appointment_created.localeCompare(b.appointment_created)
                            }
                          ]}
                          dataSource={appointmentTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
