import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from "universal-cookie"
import moment from 'moment'
import ReactModal from 'react-modal'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import { map, get } from 'lodash'
import Config from '../../../../../../config';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}
export default class AgentAssignedToTownsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      selectedIndex: 'all',
      userRole: '',
      todayDate: moment().format('LL'),
      suburb_list: [],
      town_list: [],
      sortByKey:'town_name',
      sortByType: 0,
      tableData: [],
      openAgent: false,
      showAll: true,
      townName: '',
      currentTownAgents: [],
      search: '',
      isLoading: false,
      datePickerModel: false,
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    _user = cookies.get("user")
    if (_user) {
      this.props.getFilterParams()
      this.setState({ isLoading: true, userRole: _user.role })
      const data = {}
      if (this.state.selectedIndex === "all") {
        data.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        data.end = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        // data.town_arr= []
        data.suburb_arr = []
        data.sortBy     = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getAgentAssignToTown(data)
      } else {
        data.from   = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        data.end    = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        // data.town_arr= []
        data.suburb_arr = []
        data.sortBy     = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getAgentAssignToTown(data)
      }
      this.props.getUserById({ user_id: _user.id })
    }
  }

  componentDidUpdate(preProps, preState){
    const { agentAssTownPhase, agentAssTownData, clearReportV2Phase } = this.props
    if(agentAssTownPhase === "success"){
      clearReportV2Phase()
      let data = []
      if(_.size(agentAssTownData) > 0){
        agentAssTownData && agentAssTownData.data && agentAssTownData.data.map((town, i)=>{
          data.push({
            key: i,
            townName: town.name,
            agents: town.agents,
            totalAgents: town.agents.length > 0 ? town.agents.map(e => {
               let firmName = e.firm_name ? e.firm_name : 'N/A'
               firmName = ' (' + firmName + ')'
               return (e.first_name + ' ' + e.last_name + firmName)
              }).join(", ") : ""
          })
        })
      }
      this.setState({ tableData: data, isLoading: false })
    }
    if(agentAssTownPhase === "error"){
      clearReportV2Phase()
      this.setState({ tableData: [], isLoading: false })
    }
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  onOpenAgentModal(agent) {
    const agents = agent && agent.agents
    if(agents.length > 1){
      $( "body" ).addClass( "overflow_visible" )
      this.setState({ townName: agent.townName, openAgent: true, currentTownAgents: agents })
    }
  }

  onCloseAgentModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ townName: '', currentTownAgents: [], openAgent: false })
  }

  handleSuburbsOption = (options, event) => {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ suburb_list: selectedOptions, isLoading: true })
      const data = {}
      data.from    =  moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      data.end     =  moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      // data.town_arr= this.state,town_list
      data.suburb_arr = selectedOptions
      data.sortBy     = {key: 'town_name', type: 0}
      this.props.getAgentAssignToTown(data)
    }
  }

  handleTownOption = (options, event) => {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ town_list: selectedOptions, isLoading: true })
      const data = {}
      data.from    =  moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      data.end     =  moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      // data.town_arr= this.state,town_list
      data.suburb_arr = this.state.suburb_list
      data.sortBy     = {key: 'town_name', type: 0}
      this.props.getAgentAssignToTown(data)
    }
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const data = {
      from    : moment(from).format("YYYY-MM-DD HH:mm:ss"),
      end     : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      // town_arr: [],
      suburb_arr : this.state.suburb_list,
      sortBy     : {key: this.state.sortByKey, type: this.state.sortByType}
    }
    this.props.getAgentAssignToTown(data)
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { filterParams, agentAssTownData, userData } = this.props
    const { tableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state

    let allData = filterParams.data
    let suburbsData = userData && userData.user && userData.user.suburbs
    let suburbsArray = []
    //this is for strategist and admin suburbs difference
    if(this.state.userRole === 'strategist'){
        if(allData && allData.suburbs){
          _.forEach(allData.suburbs, a => {
              var o = _.find(suburbsData, b => a.id == b.id)
              if (o) {
                suburbsArray.push(a)
              }
          })
        }
    }else{
      suburbsArray = allData && allData.suburbs
    }

    const suburbs = map(suburbsArray, (suburb, index) => {
      return { label: suburb.name, value: suburb.id }
    });

    let allTownData = filterParams && filterParams.data && filterParams.data.towns;
    const townsArr = map(allTownData, (town, index) => {
      return { label: town.name, value: town.id }
    });

    //for searching town name
    let townAgentsArr = []
    let townAgentsData =  tableData
    if(townAgentsData){
      townAgentsArr = townAgentsData.filter(
        (data) =>{
          return (
            (data.townName.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={get(page, 'value')}>
                                {get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Agents Assigned to Towns</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={townAgentsArr} name="agnet_assigned_to_town_report">
                        <ExcelColumn label="Town Name" value="townName" />
                        <ExcelColumn label="Agents Name" value="totalAgents" />
                      </ExcelSheet>
                    </ExcelFile>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      onChange={(e)=> this.onSearch(e)}
                      className="form-control search__form__input"
                      name="firstName"
                      placeholder="Search Town name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="assignedSuburbs"
                      placeholderButtonLabel="Select"
                      options={suburbs}
                      onChange={(event) => this.handleSuburbsOption(event, 'assignedSuburbs')}
                    />
                    <label className="multiselect__checkboxes__field__label">Suburb/City</label>
                  </div>
                </div>
                </div>
                {/* <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="assignedTowns"
                      placeholderButtonLabel="Select"
                      options={townsArr}
                      onChange={(event) => this.handleTownOption(event, 'assignedTowns')}
                    />
                    <label className="multiselect__checkboxes__field__label">Towns</label>
                  </div>
                </div>
                </div> */}
                {/* <div className="col-md-3">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div> */}
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(townAgentsArr) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          columns={[
                            {
                              title: 'Town Name',
                              width: 250,
                              dataIndex: 'townName',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.townName.localeCompare(b.townName)
                            },
                            {
                              title: 'Agent Name',
                              width: 180,
                              dataIndex: 'agent',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalAgents.localeCompare(b.totalAgents),
                              key: '2',
                              render: (text, val) => {
                                const totalAgent = val && val.agents
                                let agentNo = (totalAgent.length-1) <= 0 ? '' : " (+"+(totalAgent.length-1)+")"
                                return(
                                  agentNo !== '' && totalAgent.length > 1  ? (
                                    <td className="wrap-text multilines cursor__pointer"><div className={(totalAgent.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenAgentModal.bind(this,val)}>{( totalAgent.length >= 1 ? (totalAgent[0].first_name+' '+totalAgent[0].last_name )+ agentNo : 'No Agent' ) }</div></td>
                                  ):(
                                    agentNo == '' && totalAgent.length == 1 ? (
                                      <td><div className='cursor__pointer' onClick={() => { this.props.history.push({ pathname: '/add-jungler-user', state: {id:totalAgent[0].agent_id}})}}>{(totalAgent[0].first_name+' '+totalAgent[0].last_name+' ('+totalAgent[0].firm_name+')')}</div></td>
                                    ):(
                                      <td><div>No Agent</div></td>
                                    )
                                  )
                                )
                              }
                            }
                          ]}
                          dataSource={townAgentsArr}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>

            </div>
          </main>

          {/* When click on agent names so open this modal */}
         <ReactModal
            isOpen={this.state.openAgent}
            contentLabel="Report"
            portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                  {'Town - ' + this.state.townName+`'s`+' agent(s)'}
                  </h5>
                  <button
                    type="button"
                    className="btn react-modal-close"
                    onClick={this.onCloseAgentModal.bind(this)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="info__block">
                  {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                    let data = { id: agent.agent_id}
                    let firm = agent.firm_name ? agent.firm_name : 'N/A'
                    let agentInfo = agent.first_name+' '+agent.last_name + ' ' + '(' + firm + ')'
                    return <p className="cursor__pointer" onClick={() => {
                      this.props.history.push({
                        pathname: '/add-jungler-user', state: data}
                      )}}
                    key={'agent-name- '+index}>

                     {agentInfo}

                    </p>
                  })}
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </ReactModal>

        </div>
      </div>
    )
  }
}
