import { connect } from 'react-redux'
import { getFilterParams, 
         addStrategistApt, 
         addClientStragistApt, 
         clearFilterPhase,
         cancelAppointment,
         cancelStrategistAppointment,
         impersonateUser } from '../../store/dashboard/duck'

import {handleSignOut, fetchUser} from '../../store/user/duck'
import { getChatHistory } from '../../store/chat/duck'
import { 
         getClientStrategist, 
         getClientAgent, 
         getClientStrategistData,
         getClientStrategistAvaillability,
         clearDatePhase, 
         getTowns, 
         addTowns, 
         getRecommendedTowns,
         getInsightData, 
         getClientTownRateData,
         updateClientTownRatingData,
         removeTown } from '../../store/client/duck'

import { clearAgentDatePhase, 
         getAgentAvailability, 
         createAgentAppointment,
         clearPhase,
         removeAgentFromTown,
         shareClientInfo,
         addAgentOnTown } from '../../store/agent/duck'

import { 
         getAppointments,
         getClientAppointments
       } from '../../store/agent/duck'

import { 
         getClientChatData
       } from '../../store/chat/duck'

import ClientDashboardComponent from './component'

const ClientDashboardContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    strategistData: state.client.strategistData,
    getStrategistPhase: state.client.getStrategistPhase,
    agentData: state.client.agentData,
    getAgentPhase: state.client.getAgentPhase,
    appointmentData: state.agent.appointmentData,
    clientAptData: state.agent.clientAptData,
    appointmentPhase: state.agent.appointmentPhase,
    getStrategistDataPhase: state.client.getStrategistDataPhase,
    getStrategistData: state.client.getStrategistData,
    availabilityData: state.agent.availabilityData,
    availabilityPhase: state.agent.availabilityPhase,
    agentAppPhase: state.agent.agentAppPhase,
    getStrategistAvaillabilityPhase: state.client.getStrategistAvaillabilityPhase,
    getStrategistAvaillabilityData: state.client.getStrategistAvaillabilityData,
    addClientStrategyAptPhase: state.dashboard.addClientStrategyAptPhase,
    addClientStrategyAptData: state.dashboard.addClientStrategyAptData,
    cancelAptPhase: state.dashboard.cancelAptPhase,
    cancelStrAptPhase: state.dashboard.cancelStrAptPhase,
    clientChatPhase: state.chat.clientChatPhase,
    clientChatData: state.chat.clientChatData,
    getTownPhase: state.client.getTownPhase,
    getTownData: state.client.getTownData,
    addTownPhase: state.client.addTownPhase,
    addTownData: state.client.addTownData,
    recommendedTownPhase: state.client.recommendedTownPhase, 
    recommendedTownData: state.client.recommendedTownData,
    removeTownPhase: state.client.removeTownPhase,
    removeTownData: state.client.removeTownData,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    insightPhase: state.client.insightPhase,
    insightData: state.client.insightData,
    shareClientInfoPhase: state.agent.shareClientInfoPhase,
    shareClientInfoData: state.agent.shareClientInfoData,
    chatHistoryData: state.chat.chatHistoryData,
    removeAgentTownPhase: state.agent.removeAgentTownPhase,
    getRateTownPhase: state.client.getRateTownPhase,
    getRateTownData: state.client.getRateTownData,
    updateClientTownRatePhase: state.client.updateClientTownRatePhase,
    updateClientTownRateData: state.client.updateClientTownRateData,
    addAgentTownPhase: state.agent.addAgentTownPhase,
    addAgentTownData: state.agent.addAgentTownData,
    agentAppData: state.agent.agentAppData
  }),

  {
    fetchUser,
    handleSignOut,
    getClientAgent,
    getClientStrategist,
    getAppointments,
    getClientStrategistData,
    clearAgentDatePhase,
    getClientStrategistAvaillability,
    clearDatePhase,
    getAgentAvailability,
    createAgentAppointment,
    clearPhase,
    addStrategistApt,
    addClientStragistApt,
    clearFilterPhase,
    cancelAppointment,
    cancelStrategistAppointment,
    getClientAppointments,
    getClientChatData,
    getTowns,
    addTowns,
    getRecommendedTowns,
    removeTown,
    impersonateUser,
    getInsightData,
    shareClientInfo,
    getChatHistory,
    removeAgentFromTown,
    getClientTownRateData,
    updateClientTownRatingData,
    addAgentOnTown
  }
)(ClientDashboardComponent)
export default ClientDashboardContainer
