/* eslint-disable no-nested-ternary */
import React, { PureComponent } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import config from "../../../../../config";
import _ , { map } from "lodash";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Validation from "../../../../../config/validation";
import Dropzone from 'react-dropzone'
import { message } from 'antd'
import NumberFormat from 'react-number-format'
import axios from 'axios'
import { logoutInvalidRequest } from '../../../../../utils/helper'
const API_HOSTNAME = process.env.API_HOSTNAME

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: "8px 12px",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: "none",
  }),
  menu: () => ({
    borderRadius: "4px",
    background: "#fff",
    boxShadow: "5px 10px 30px #E5E5E5",
  }),
};

export default class SignUpPage1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      business_towns_ids: [],
      townNames: [],
      agentForm2Data: {},
      selectedTown: [],
      selectedOpt: [],
      buy_side_sales: ''
    };
  }

  componentDidMount() {
    let localData = JSON.parse(localStorage.getItem("agentSignUp2"));
    let data = []
    let selectedOpt = []
    const boardData= this.props.onBoardingData && this.props.onBoardingData.data
    if (localData) {
      data = localData
      selectedOpt = localData.selectedOpt
    } else {
      if(boardData){
        data = {
          city_live: boardData.city_live ? boardData.city_live : '',
          year_in_real_estate: boardData.year_in_real_estate ? boardData.year_in_real_estate : '',
          business_towns_ids: !_.isEmpty(boardData.business_towns_ids) ? boardData.business_towns_ids : [],
          buy_side_sales: boardData.buy_side_sales? boardData.buy_side_sales : '',
          bio: boardData.bio ? boardData.bio: '',
          image: boardData.image ? boardData.image : ''
        };
        selectedOpt = !_.isEmpty(boardData.business_towns_ids) ? boardData.business_towns_ids : []
      }
    }
    this.setState({ agentForm2Data: data, selectedOpt, buy_side_sales: data.buy_side_sales });
  }

  componentDidUpdate(props, state){
    const { filterParamsPhase, filterParams, clearPhase } = this.props
    if(filterParamsPhase === 'success'){
      clearPhase()
      //this is for town drop down options
      let allTowns = filterParams && filterParams.data && filterParams.data.towns;
      let selectTownConduct = []
      if (allTowns) {
        allTowns && allTowns.map((val, index)=>{
          if (!_.isEmpty(this.state.agentForm2Data)) {
            if (this.state.agentForm2Data.business_towns_ids.includes(val.id)) {
              selectTownConduct.push({ label: val.name, value: val.id });
            }
          }
        })
        this.setState({ selectedTown: selectTownConduct, selectedOpt:  map(selectTownConduct, (option) => option.value) })
      }
    }
  }

  handleTownSelect = (options, event, setFieldValue) => {
    if (options) {
      const selectedOptions = map(options, (option) => option.value)
      const selectTownName = map(options, (option) => option.town_full_name)
      // selectTownConduct.push({ label: selectTownName, value: selectedOptions })
      this.setState({
        business_towns_ids: selectedOptions,
        townNames: selectTownName,
        selectedTown: options,
        selectedOpt: selectedOptions
      });
      setFieldValue("business_towns_ids", selectedOptions)
      setFieldValue("business_town_names", selectTownName)
    }
  }

  handleChange(setFieldValue, event) {
    this.setState({ [event.target.name]: event.target.value })
    setFieldValue("buy_side_sales", event.target.value )
  }

  dragDrop=(acceptFile, setFieldValue)=>{
    if(acceptFile[0]){
      this.props.toggleLoading(true)
      const reader = new FileReader()
      reader.onload = (r) => {
        this.setState({
          previewImage: acceptFile[0].preview,
          previewStatus: 'viewAgent_onboarding'
        })
      }
      this.uploadFile(acceptFile[0], setFieldValue)
      reader.readAsDataURL(acceptFile[0])
    }
  }

  uploadFile(fileData, setFieldValue){
    const { onBoardingData } = this.props
    let userId = onBoardingData.data && onBoardingData.data.id
    const formData = new FormData()
    formData.append('image', fileData)
    formData.append('user_id', userId)
    axios.post(`${API_HOSTNAME}/users/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if(response.data && response.data.status){
        this.setState({ image: response.data.image_url })
        setFieldValue("image", response.data.image_url)
        this.props.toggleLoading(false)
      }
      else{
        this.props.toggleLoading(false)
        message.error(`Only jpeg, jpg & png image formats are allowed`)
      }
    }).catch(logoutInvalidRequest)
  }

  submitData = (data) => {
    data.stage = 2
    data.selectedOpt = this.state.selectedOpt
    data.business_towns_ids = this.state.selectedOpt
    data.buy_side_sales = this.state.buy_side_sales
    localStorage.setItem("agentSignUp2", JSON.stringify(data))
    this.props.onClickPage1(data)
  };

  render() {
    const { filterParams, townData } = this.props;
    //this is for real estate drop down options
    let realEstateData = config.realEstateOptions.map((val, index) => {
      return (
        <option key={index} value={val.key}>
          {val.name}{index > 1 ? 's' : null}
        </option>
      );
    });

    let selectTownConduct = [];
    //this is for town drop down options
    // let allTowns = filterParams && filterParams.data && filterParams.data.towns;
    let allTowns = townData && townData.data;
    let townConductData = [];
    if (allTowns) {
      townConductData = map(allTowns, (val, index) => {
        if (!_.isEmpty(this.state.agentForm2Data)) {
          if (this.state.selectedOpt.includes(val.id)) {
            selectTownConduct.push({ label: val.name, value: val.id, town_full_name: val.name_long });
          }
        }
        return { label: val.name, value: val.id, town_full_name: val.name_long };
      });
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.state.agentForm2Data}
        validationSchema={Validation.agentSignUpPage1}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.submitData(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form noValidate>
            <div className="card">
              <div className="card-header d__flex align__items__center">
                <h5 className="card-title mb__0">Tell us more about you</h5>
              </div>
              <div className="card-body">
                <div className="form-group material-textfield">
                  <Field
                    type="text"
                    className="form-control material-textfield-input"
                    name="city_live"
                    required
                  />
                  <label className="material-textfield-label">
                    Which city do you live in?
                  </label>
                  <ErrorMessage className="invalid-feedback" name="city_live" component="div"/>
                </div>
                <div className="form-group material-textfield">
                  <Field
                    defaultValue=""
                    name="year_in_real_estate"
                    component="select"
                    className="form-control custom__select material-textfield-input"
                    required
                  >
                    <option disabled value=""></option>
                    {realEstateData}
                  </Field>
                  <label className="material-textfield-label">
                    How long have you been in real estate?
                  </label>
                  <ErrorMessage className="invalid-feedback" name="year_in_real_estate" component="div"/>
                </div>
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      component="select"
                      name="business_towns_ids"
                      // defaultValue={selectTownConduct}
                      getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                        if (value && value.length === 0)
                        return 'Select'
                        return value && value.length+" selected"
                      }}
                      value={selectTownConduct}
                      placeholderButtonLabel="Select"
                      options={townConductData}
                      onChange={(event) =>
                        this.handleTownSelect(event, "townOptions", setFieldValue)
                      }
                    />
                    <label className="multiselect__checkboxes__field__label">
                    Which towns do you currently do the majority of your business in?
                    </label>
                    <ErrorMessage className="invalid-feedback" name="business_towns_ids" component="div"/>
                  </div>
                </div>

                <div className="form-group material-textfield material-textfield-long-text">
                  {/* <Field
                    type="text"
                    className="form-control material-textfield-input"
                    name="buy_side_sales"
                    required
                  /> */}

                  <NumberFormat
                    name="buy_side_sales"
                    className="form-control material-textfield-input"
                    value={this.state.buy_side_sales}
                    onChange={this.handleChange.bind(this, setFieldValue)}
                    thousandSeparator={true}
                    required
                  />

                  <label className="material-textfield-label">
                    Enter the total number of buy side sales you have closed in last 12 months
                  </label>
                  <ErrorMessage className="invalid-feedback" name="buy_side_sales" component="div"/>
                  <div className="note__text">
                    Note: We will verify this with your MLS records
                  </div>
                </div>
                <div className="form-group material-textfield">
                  <Field
                    type="text"
                    component="textarea"
                    className="form-control material-textfield-input"
                    name="bio"
                    required
                    rows="6"
                  />
                  <label className="material-textfield-label">
                    Please add your bio (in third person)
                  </label>
                  <ErrorMessage className="invalid-feedback" name="bio" component="div"/>
                </div>
                {/* Drop zone */}
                <h4 className="dropzone-title">Upload Profile Photo (only jpeg, jpg and png format allowed )</h4>
                <h6 className="dropzone-subtitle">100 x 100 recommended (5 MB Max.)</h6>
                <div className="dropzone dropzone-sm form-group">
                  <Dropzone
                    className="dropzone__multiple"
                    multiple
                    name="image"
                    // onDrop={this.dragDrop.bind(this)}
                    onDrop={(event) =>
                      this.dragDrop(event, setFieldValue)
                    }
                    >
                    {({ getRootProps, getInputProps }) => (
                      <section className="upload-container">
                        <div className="dropzone-inner-area">

                          <div className="upload-text">
                            Select from Computer <br /> or
                          </div>
                          <button
                            type="button"
                            className="btn btn__btn btn-dark btn-upload"
                          >
                            Drag & Drop
                          </button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <ErrorMessage className="invalid-feedback" name="image" component="div"/>
                {/* Drop zone */}
                {
                  values.image &&
                  <img
                    className="form-group dropzone-image"
                    src={values.image}/>
                }
                <button type="submit" className="btn btn__btn btn-dark btn__lg w__100">Next</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
