import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
const required = value => value ? undefined : 'Required'

class JunglerUserProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }
  componentWillMount() {
    const {location} = this.props;
    let useid = location.state && location.state.id && location.state.id;
    if(useid && useid !==''){
    let usrdata = {}
    usrdata.user_id = useid;
    this.props.getUSerProfileByID(usrdata)
    }else{
     this.props.history.push('/jungler-users')
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }
  }
  handleSubmit(event) {
    event.preventDefault()
    const err = {}
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    }
    if (this.state.password === '' || this.state.password.trim() === '') {
      err.password = 'Password is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { loginUser } = this.props
      const data = {
        email: this.state.email,
        password: this.state.password
      }
      loginUser(data)
    }
  }

  handleRequestClose() {
    this.setState({
      openSnackbar: false,
      errMessage: ''
    })
  }

  render() {
    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
  });
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      userData,
      token,
      phase
    } = this.props
    // if(phase == "success") {
    //   return(
    //     <Redirect to={`/dashboard`}/>
    //   )
    // }
  
    return (
      <div>
        <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Jungler User Profile</span></a>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <Link to={`/jungler-users`}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="profile__container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="profile__container--form">

                 <div className="form-group more__margin--btm">
                    <label className="text__label--sm">Role of User</label>
                    <div className="profile-result">{userData && userData.user && userData.user.role }</div>
                  </div>
   
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">Profile</h3>
                      <div className="profile__container--section">
                        <span className="profile__container--avtar">
                          <img src={userData && userData.user && userData.user.avatar} alt="" />
                        </span>
                      </div>
                    </div>
                    <div className="panel-body">
                      <form>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <div className="form-group text-group">
                              <label className="label-result">First Name</label>
                              <div className="profile-result">{userData && userData.user && userData.user.first_name}</div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="form-group text-group">
                            <label className="label-result">Last Name</label>
                            <div className="profile-result">{userData && userData.user && userData.user.last_name}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <div className="form-group text-group">
                            <label className="label-result">Mobile Phone</label>
                            <div className="profile-result">{userData && userData.user && userData.user.phone_mobile}</div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="form-group text-group">
                            <label className="label-result">Conference Phone Number</label>
                            <div className="profile-result">{userData && userData.user && userData.user.phone_work}</div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group text-group">
                        <label className="label-result">Email</label>
                        <div className="profile-result">{userData && userData.user && userData.user.email}</div>
                        </div>
                        <div className="form-group text-group">
                          <label className="label-result">Time Zone</label>
                          <div className="profile-result">{userData && userData.user && userData.user.time_zone}</div>
                        </div>
                        <div className="form-group text-group">
                        <label className="label-result">Description</label>
                        <div className="profile-result">{userData && userData.user && userData.user.description}</div>
                      </div>
                      </form>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">Region Coverage</h3>
                    </div>
                    <div className="panel-body">
                      <form>
                        <div className="region_coverage">
                          <ul>
                          {userData && userData.user && userData.user && userData.user.suburbs && userData.user.suburbs.length >0 ? userData.user.suburbs.map((suburbsval, suburbsindex) => {
                            return (
                              <li key={suburbsindex}>{suburbsval.name}</li>
                            )
                          })
                          : 'No suburbs for this user.'
                          } 
                          </ul>
                        </div>
                          <label className="label-result-sub">Towns</label>
                            <div className="region_coverage">
                              <ul>
                              {userData && userData.user && userData.user && userData.user.towns && userData.user.towns.length >0 ? userData.user.towns.map((townssval, townsindex) => {
                                  return (
                                    <li key={townsindex}>{townssval.name}</li>
                                  )
                              })
                              : 'No twons for this user.'
                              } 
                              </ul>
                            </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'jungleruserprofile',  // a unique identifier for this form
  destroyOnUnmount: true,
})(JunglerUserProfileForm)
