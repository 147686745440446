import 'rxjs'
import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import NumberFormat from 'react-number-format';
import axios from 'axios'
import moment from 'moment'
import Calendar from 'react-calendar'
import DatePicker from 'react-datepicker'
import timezone from 'moment-timezone';

import { getTimeFormate } from '../../config'
import calendar from './calendar.scss';
import styles from './styles.scss';
import $ from 'jquery';
const HOSTNAME = process.env.API_HOSTNAME

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:true,
  autoplay:false
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows:false,
  autoplay:true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const settingsbg = {
  dots: false,
  infinite: true,
  speed: 800,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:false,
  autoplay:true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const responseFacebook = (response) => {

};

class DatePickerCustomInput extends React.Component {

  render () {
    return (
      <button
        className="schedule-date"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AgentAppForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      name: "",
      email: "",
      password:'',
      confirm_password:'',
      phone: "",
      firm: '',
      howlong:'',
      side_transactions:'',
      city: "",
      price: "Under $400,000",
      info: "",
      source:"",
      times:[],
      activeSlide:'',
      availableDates: [],
      date: moment().format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      startDate:moment(),
      timezone_type:"UTC",
      selectedTime : "",
      noTimeWorks:false,
      noSchedule:false,
      scheduleToday:false,
      showTime:true,
      err: {},
      errMessage: "",
      infoLength:0,
      strategy:[1143],
      isLoading: 'Submit',
      isDateLoading:false,
      isAllDateloading:false,
      strategist:"Erika Ades"
    }
    this.handleChange = this.handleChange.bind(this)
  }


 componentWillMount(){
    window.localStorage.setItem('clientToken', null);
    window.localStorage.setItem('createSuccess', false);
    let budgetInit = config.bugets;
    const err = {}
    this.setState({ errMessage: "" })
    if(this.getParameterByName('email') && this.getParameterByName('email') != '' && this.getParameterByName('email') != null){
        this.setState({email:this.getParameterByName('email').toLowerCase()})
        if(!this.validateEmail(this.getParameterByName('email'))){
            err.email = 'Your email address is Invalid.'
            this.setState({ err })
        }
    }
    if(this.getParameterByName('full_name') && this.getParameterByName('full_name') != '' && this.getParameterByName('full_name') != null){
        this.setState({name:this.getParameterByName('full_name')})
    }
    if(this.getParameterByName('mobile') && this.getParameterByName('mobile') != '' && this.getParameterByName('mobile') != null){
        this.setState({phone:this.getParameterByName('mobile')})
    }
    if(this.getParameterByName('city') && this.getParameterByName('city') != '' && this.getParameterByName('city') != null){
        this.setState({city:this.getParameterByName('city')})
    }
    
    if(this.getParameterByName('firm') && this.getParameterByName('firm') != '' && this.getParameterByName('firm') != null){
        this.setState({firm:this.getParameterByName('firm')})
    }

    if(this.getParameterByName('howlong') && this.getParameterByName('howlong') != '' && this.getParameterByName('howlong') != null){
        this.setState({howlong:this.getParameterByName('howlong')})
    }

  }

   componentWillReceiveProps(nextProps) {
     if(nextProps.user && nextProps.user.status==true){
      //window.FbeventHander("ThankYou_Page_Loaded");
      window.location.replace('/thankyou');
     }else{
      this.setState({isLoading: 'Submit'})
      //window.FbeventHander("Ques_Invalid_UserExist");
      //fbq('trackCustom', 'Ques_Invalid_UserExist');
      window.alert(nextProps.user.message)
     }
  }
  
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  // Function to set form input values in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleInfoChange(event){
    this.setState({ info: event.target.value, infoLength: event.target.value.length})
  }

  handleNumberChange(event){
    this.setState({ phone: event.target.value })
  }


  handleCheck(event) {
    if(event.target.name == "noTimeWorks"){
        if(!this.state.noTimeWorks){
          this.setState({ showTime: false })
        } else {
          this.setState({ showTime: true })
        }
        this.setState({ [event.target.name]: !this.state.noTimeWorks, noSchedule: false });
        //window.FbeventHander("Ques_DontSeeATime_Checked");
        //gtag('event','Ques_DateTimeSelected');
        //ga('send', 'event', 'Questionnaire', 'Click', 'Ques_DontSeeATime_Checked', '1');
        //fbq('trackCustom', 'Ques_DontSeeATime_Checked');

    } else if(event.target.name == "scheduleToday"){
      // if(!this.state.scheduleToday){
      //   this.setState({ showTime: false })
      // } else {
      //   this.setState({ showTime: true })
      // }
      this.setState({ [event.target.name]: !this.state.scheduleToday, noSchedule: false });
      //window.FbeventHander("Ques_DontSeeATime_Checked");
      //gtag('event','Ques_DateTimeSelected');
      //ga('send', 'event', 'Questionnaire', 'Click', 'Ques_DontSeeATime_Checked', '1');
      //fbq('trackCustom', 'Ques_DontSeeATime_Checked');

  } else {
        if(!this.state.noSchedule){
          this.setState({ showTime: false })
        } else {
          this.setState({ showTime: true })
        }
        this.setState({ [event.target.name]: !this.state.noSchedule, noTimeWorks: false });
        //window.FbeventHander("Ques_DontScheduleNow_Checked");
        //fbq('trackCustom', 'Ques_DontScheduleNow_Checked');
        //gtag('event','Ques_DontScheduleNow_Checked');
        //ga('send', 'event', 'Questionnaire', 'Click', 'Ques_DontScheduleNow_Checked', '1');
    }
  }

  handleClick(){
    const err = {}
    this.setState({ errMessage: "" })
    if (this.state.name === '' || this.state.name.trim() === '') {
        err.name = 'Please enter your Full Name.'
    }
    if (this.state.email === '' || this.state.email.trim() === '') {
        err.email = 'Please enter your Email.'
    } else if (!this.validateEmail(this.state.email)) {
        err.email = 'Your email address is Invalid.'
    }
    if (this.state.phone === '' || this.state.phone.trim() === '') {
        err.phone = 'Please enter your Number.'
    }
    if (this.state.city === '' || this.state.city.trim() === '') {
        err.city = 'Please select your City.'
    }
    if (this.state.info === '' || this.state.info.trim() === '') {
        err.info = 'Please provide more details.'
    }
    if (this.state.firm === '' || this.state.firm.trim() === '') {
      err.firm = 'Please enter firm name.'
    }
    if (this.state.howlong === '' || this.state.howlong.trim() === '') {
      err.howlong = 'This field is required.'
    }
    if (this.state.side_transactions === '' || this.state.side_transactions.trim() === '') {
      err.side_transactions = 'This field is required.'
    }


    this.setState({ err })
    if (!Object.keys(err).length) {
        this.setState({isLoading: 'Please wait...'})
        const { createAgent } = this.props
        const data = new FormData();
        data.append('timezone', this.state.timezone);
        data.append('name', this.state.name);
        data.append('email', this.state.email.toLowerCase());
        data.append('phone', this.state.phone);
        data.append('city', this.state.city);
        data.append('info', this.state.info);
        data.append('firm', this.state.firm);
        data.append('howlong', this.state.howlong);
        data.append('side_transactions', this.state.side_transactions);
        createAgent(data)
    }else{
      this.setState({isLoading: 'Submit'})
    }
  }

  render() {
    let citydropdowncss = { marginTop: 0}
    let cities = config.cities.map((val, index) => {
        return (
          <option key={index} value={val.value}>{val.label}</option>
        )
    });
    return (
      <section className="contact-popup">
          <div className="container-fluid">
              <div className="row pop-up-header" >
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left-align">
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 right-align">
                  <div className="row">
                    <a href={`${this.state.wpUrl}/agent`}>
                      <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />
                    </a>
                  </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-10 center-align">
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 right-align">
                      <div className="close">
                        <a href={`${this.state.wpUrl}agent/`}>
                          <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                        </a>
                      </div>
                  </div>
              </div>
              <div className="row popup-marg">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="left-section">
                      <div className="signup-img">
                        <ul>
                          <Slider {...settingsbg}>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/5.jpg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/11.jpg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/5.jpg`}/></li>
                            <li><img src={`${this.state.wpUrl}/wp-content/uploads/2018/05/11.jpg`}/></li>
                            
                          </Slider>
                      </ul>
                      <div className="overlay-img"></div>
                      <div className="contact-box-outer">
                      <div className="contact-box">
                       <img src={`${this.state.wpUrl}/wp-content/themes/suburbanjungle/images/agent.svg`}/>
                      <p>
                      Apply here to be a part of our exclusive Suburban Jungle Agent team. Please note, we do not employ our own agents- we simply partner with you.
If you are a good fit for our team, our Director of Agent Development will be in touch with you.
                      </p>
                      </div>
                      </div>
                         <div className="">
                            <div className="slider-listing">
                              <ul>
                              <Slider {...settings2}>
                                <li><img src="/../../img/nyp.png"/></li>
                                <li><img src="/../../img/tbb.png"/></li>
                                <li><img src="/../../img/thp.png"/></li>
                                <li><img src="/../../img/us-news.png"/></li>
                                <li><img src="/../../img/nyp.png"/></li>
                              </Slider>
                              </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-frm">
                    <h4>Let's Start.</h4>
                    <span>
                        <p>Apply to be a part of the Suburban Jungle agent team:</p>
                    </span>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="outer-input">
                              <input
                                type="text"
                                name="name"
                                placeholder="Enter your name"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label>Name</label>
                              { this.state.err.name ?
                              <span className="error_field">
                              {this.state.err.name}
                              </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="outer-input">

                            <input
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              value={this.state.email}
                              onChange={this.handleChange.bind(this)}
                            />
                            <label>Email</label>
                            { this.state.err.email ?
                            <span className="error_field">
                            {this.state.err.email}
                            </span> : '' }
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="outer-input">

                            <NumberFormat
                              format="(###)  ### - ####"
                              placeholder="(444)  444 - 4444"
                              value={this.state.phone}
                              onChange={this.handleNumberChange.bind(this)}
                            />
                            <label>Mobile Number</label>
                            { this.state.err.phone ?
                            <span className="error_field">
                            {this.state.err.phone}
                            </span> : '' }
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="outer-input">

                              <input
                                type="text"
                                name="firm"
                                placeholder="Firm"
                                value={this.state.firm}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label>Firm</label>
                              { this.state.err.firm ?
                              <span className="error_field">
                              {this.state.err.firm}
                              </span> : '' }
                          </div>
                        </div> 
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">
                              <input
                                type="text"
                                name="howlong"
                                value={this.state.howlong}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label>How long have you been in real estate?</label>
                              { this.state.err.howlong ?
                              <span className="error_field">
                              {this.state.err.howlong}
                              </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">
                              <input
                                type="text"
                                name="side_transactions"
                                value={this.state.side_transactions}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label>How many buy side transactions have you closed in the last 12 months?</label>
                              { this.state.err.side_transactions ?
                              <span className="error_field">
                              {this.state.err.side_transactions}
                              </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">
                            <select style={citydropdowncss} name="city" value={this.state.city} onChange={this.handleChange.bind(this)}>
                                <option value="">Select City</option>
                                { cities }
                            </select>
                            <label>Where do you live?</label>
                            { this.state.err.city ?
                            <span className="error_field">
                            {this.state.err.city}
                            </span> : '' }
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="outer-input">

                            <textarea
                              name="info"
                              placeholder="Give us all the details! As much as you can!"
                              value={this.state.info}
                              onChange={this.handleInfoChange.bind(this)}
                            />
                            {/*<span className="textarea-counter">{this.state.infoLength}/500</span> */}
                            <label>Please add your bio in the third person</label>

                            { this.state.err.info ?
                            <span className="error_field">
                            {this.state.err.info}
                            </span> : '' }
                          </div>
                        </div> 
                          <br/>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <button className="btn btn-full DefaultBtn" onClick={this.handleClick.bind(this)}>{this.state.isLoading}</button>
                          </div>
                    </div>
                </div>
              </div>
          </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'agentappform',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AgentAppForm)
