import React from 'react';
import { Pagination } from 'antd';
import get from 'lodash/get';

class PaginationMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    };
  }

  pageChange = page => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.setState({ currentPage: page });
    if (this.props.pageChanged) {
      this.props.pageChanged(page);
    }
  };

  render() {
    const { currentPage } = this.state;
    return (
      <>
        <Pagination
          className="mt__20 mb__20"
          current={currentPage}
          onChange={e => this.pageChange(e)}
          pageSize={get(this, 'props.pageSize', 100)}
          hideOnSinglePage={true}
          showSizeChanger={false}
          total={get(this, 'props.total', 1000)}
        />
      </>
    );
  }
}

export default PaginationMain;
