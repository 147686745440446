import * as types from './action-types'
/* 
    ///////////////////////////////////
  /// client detail action ///
//////////////////////////////////
 */
// fetch interalNotes list
export const getRankingByClientId = (payload) => ({
  type: types.FETCH_RANKING_BY_CLIENT_ID,
  payload,
})
// get client quetion for client detail questioner
export const getClientQuestion = (payload) => ({
  type: types.FETCH_CLIENT_QUESTION_BY_USER_ID,
  payload,
})
// get un-approved recommended town
export const getUnapprovedRecommendedTowns = (payload) => ({
  type: types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS,
  payload,
})
// set approve recommended towns
export const approveRecommendedTowns = (payload) => ({
  type: types.APPROVE_RECOMMENDED_AI_TOWNS,
  payload,
})
// timer for client-detail
export const addClientStrategistTimeSheet = (payload) => ({
  type: types.ADD_CLIENT_STRATEGIST_TIME_SHEET,
  payload,
})

// clear client detail phase
export const clearClientDetailPhase = () => ({
  type: types.CLEAR_CLIENT_DETAIL_PHASE,
})
