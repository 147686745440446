import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'

class StrategyEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      agent_name: '',
      checkeditems: [],
      filterArrVal: [],
      allTownArr: [],
      editUserMsg:"",
      checkSuburb: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount(){
    const { location } = this.props
    let suburbsArr = []
    if(location.state){
   
      location.state.suburbs && location.state.suburbs.map((sub, idx)=>{
        suburbsArr.push(sub.suburb_id)
      })
      let data = {}
      suburbsArr.push(location.state.suburb_id)
      data.suburb_arr = suburbsArr
      this.props.getTowns(data) 
    }

    this.state.checkeditems.push(location.state.town_id)
    this.setState({checkeditems: this.state.checkeditems})
    this.setState({ agent_name: location.state.agent_id})
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }
    if(nextProps.townAgnetData && nextProps.townAgnetData.status){
      this.setState({[nextProps.townAgnetData.town_id]:nextProps.townAgnetData.data})
    }
    if(nextProps.addStrategyPhase === "loading"){
     this.setState({ editUserMsg: "Save Changes Sucessfully..."})
     this.props.clearPhase()
    }
    if(nextProps.addStrategyPhase === "error"){
      this.setState({ editUserMsg: "Something Went Wrong..."})
      this.props.clearPhase()
    }
  }

  clickOnSuburbs(dataid){
    let selectArr = []
    let data = {}
    selectArr.push(dataid)
    data.suburb_arr = selectArr
    this.props.getTowns(data)
    this.setState({ checkSuburb: selectArr})
  }

  componentDidMount(){
    document.title = "Jungler: Strategy";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handleChange(index, townID , event) {
    let data1 = {}
    let filterArr = this.state.filterArrVal
    let townArr = this.state.allTownArr
    if (townArr.indexOf(townID) === -1) {
      townArr.push(townID)
      data1.town_id = townID
      data1.agent_id= event.target.value
      filterArr.push(data1)
    } 
    else {
      townArr.splice(townArr.indexOf(townID), 1)
      var removed = this.removeByKey(filterArr, {
        key: 'town_id',
        value: townID
      });
      data1.town_id = townID
      data1.agent_id= event.target.value
      filterArr.push(data1)
    }
    this.setState({ filterArrVal: filterArr})
    this.setState({agent_name: event.target.value})
  }

  removeByKey(array, params){
    array.some(function(filterArr, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }


  handleSubmit(event) {
    event.preventDefault()
    const err = {}
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    }
    if (this.state.password === '' || this.state.password.trim() === '') {
      err.password = 'Password is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { loginUser } = this.props
      const data = {
        email: this.state.email,
        password: this.state.password
      }
      loginUser(data)
    }
  }

  selectCheckbox(item) {
    let filterArr = this.state.filterArrVal
    let data = {}
    let checked = this.state.checkeditems
    if (checked.indexOf(item.id) === -1) {
      checked.push(item.id)
    } 
    else {
      checked.splice(checked.indexOf(item.id), 1)
    }
    this.setState({checkeditems: checked})
  }

  submitBtn(event){
    let data = {}
    data.type = "edit"
    data.client_id = this.props.location.state.client_id
    data.town_agents = this.state.filterArrVal
    this.props.addStrategy(data)
    setTimeout(()=> {
      this.props.history.push({ pathname: `activity/clients-strategy/${this.props.location.state.client_id}`})
      this.setState({ editUserMsg: ''})
    },800)
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      token,
      phase,
      townData,
      townAgnetData,
      location
    } = this.props;
    return (
      <div>
        <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                    <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Edit Strategy</span></a>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                    <Link to={`/activity/clients-strategy/${this.props.location.state.client_id}`}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <main className="strategy--container">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="full__page--left">
                  <h1>Edit Strategy</h1>
                  <p>Select which towns/cities that best suit this client, then Edit
                      a agent to that specific town. 
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="strategy--form">
                  <div className="filter__container">
                    <div className="filter__container--filter-name">
                      <button className="btn filter__container--button active">{this.props.location.state && this.props.location.state.suburb_name}</button>
                    </div>
                  </div>
                  <div className="strategy--form__card">
                    {
                      townData && townData.data && townData.data.map((town, index) =>{
                        return(
                          <ul key={index} className="locations__list agent-town-lists">
                            <li className={this.state.checkeditems.indexOf(town.id) !== -1 ? 'active' : ''}>
                              <div className="select__agent--name" onClick={()=> this.selectCheckbox(town)}>
                                <span className="checkbox__icon">
                                  <img src={this.state.checkeditems.indexOf(town.id) === -1 ? 'img/checkblank-icon.svg' : 'img/checkmark-icon.svg'}/>
                                </span>  
                                {town.name}
                              </div>
                              <div className="locations__list--select">
                                <select 
                                  className="form-control"
                                  value={this.state.agent_name}
                                  onChange={this.handleChange.bind(this, index, town.id)}>
                                  {
                                    town && town.agents && town.agents.map((val, idx) => {
                                      return (
                                        <option 
                                          key={idx} 
                                          value={val.agent_id}>{val.agent_first_name +' '+val.agent_last_name}
                                        </option>
                                      )    
                                    })
                                  }
                                </select>
                              </div>
                            </li>
                          </ul>
                        )
                      })
                    }
                  </div>
                  {this.state.editUserMsg !== '' &&
                    <div className="text-center alert alert-success">
                      {this.state.editUserMsg}
                    </div>
                  }
                  <div className="strategy--form__btn">
                    <button 
                      type="submit"
                      onClick={() => 
                      { 
                       this.props.history.push({ pathname: `activity/clients-strategy/${this.props.location.state.client_id}`})
                      }} 
                      className="btn btn__black--lg btn__black--outline">
                      Cancel
                    </button>
                    <button 
                      type="submit"
                      onClick={this.submitBtn.bind(this)} 
                      className="btn btn__green btn__green--lg">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default reduxForm({
  form: 'strategy',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategyEdit)
