/* eslint-disable object-shorthand */
import moment from "moment"

// create schedules to show task on calender
export const schedules = [
  {
    id: '1',
    calendarId: '0',
    title: 'TOAST UI Calendar Study',
    category: 'time',
    dueDateClass: '',
    start: (new Date()).toISOString(),
    end: new Date()
  },
  {
    id: '2',
    calendarId: '2',
    title: 'Practice',
    category: 'time',
    dueDateClass: '',
    start: new Date(),
    end: new Date(),
    isReadOnly: true
  },
  {
    id: '3',
    calendarId: '3',
    title: 'FE Workshop',
    category: 'time',
    dueDateClass: '',
    start: new Date(),
    end: new Date()
    // isReadOnly: true
  },
  {
    id: '4',
    calendarId: '4',
    title: 'Report',
    category: 'time',
    dueDateClass: '',
    start: (new Date()).toISOString(),
    end: new Date()
  }
]
// calender theme
export const theme = {
  'common.border': '1px solid #e5e5e5',
  'common.holiday.color': '#333',
  'common.saturday.color': '#333',
  'common.dayname.color': '#333',
  'common.today.color': '#333',
  'common.today.backgroundColor': 'rgba(81, 92, 230, 0.05)',
  // creation guide style
  'common.creationGuide.backgroundColor': 'rgba(81, 92, 230, 0.05)',
  'common.creationGuide.border': '1px solid #515ce6',

  // month header 'dayname'
  'month.dayname.height': '30px',
  'month.dayname.borderLeft': '1px solid #e5e5e5',
  'month.dayname.paddingLeft': '10px',
  'month.dayname.paddingRight': '10px',
  // 'month.dayname.backgroundColor': 'inherit',
  'month.dayname.fontSize': '12px',
  'month.dayname.fontWeight': 'normal',
  'month.dayname.textAlign': 'left',
  // month day grid cell 'day'
  'month.holidayExceptThisMonth.color': 'rgba(255, 64, 64, 0.4)',
  'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
  'month.weekend.backgroundColor': 'inherit',
  'month.day.fontSize': '14px',

  // month schedule style
  'month.schedule.borderRadius': '2px',
  'month.schedule.height': '24px',
  'month.schedule.marginTop': '2px',
  'month.schedule.marginLeft': '8px',
  'month.schedule.marginRight': '8px',

  // month more view
  'month.moreView.border': '1px solid #d5d5d5',
  'month.moreView.boxShadow': '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  'month.moreView.backgroundColor': 'white',
  'month.moreView.paddingBottom': '17px',
  'month.moreViewTitle.height': '44px',
  'month.moreViewTitle.marginBottom': '12px',
  'month.moreViewTitle.backgroundColor': 'inherit',
  'month.moreViewTitle.borderBottom': 'none',
  'month.moreViewTitle.padding': '12px 17px 0 17px',
  'month.moreViewList.padding': '0 17px',

  // week header 'dayname'
  'week.dayname.height': '42px',
  'week.dayname.borderTop': '1px solid #e5e5e5',
  'week.dayname.borderBottom': '1px solid #e5e5e5',
  'week.dayname.borderLeft': 'inherit',
  'week.dayname.paddingLeft': '0',
  'week.dayname.backgroundColor': 'inherit',
  'week.dayname.textAlign': 'left',
  'week.today.color': '#333',
  'week.pastDay.color': '#bbb',

  // week vertical panel 'vpanel'
  'week.vpanelSplitter.border': '1px solid #e5e5e5',
  'week.vpanelSplitter.height': '3px',

  // week daygrid 'daygrid'
  'week.daygrid.borderRight': '1px solid #e5e5e5',
  'week.daygrid.backgroundColor': 'inherit',

  'week.daygridLeft.width': '72px',
  'week.daygridLeft.backgroundColor': 'inherit',
  'week.daygridLeft.paddingRight': '8px',
  'week.daygridLeft.borderRight': '1px solid #e5e5e5',

  'week.today.backgroundColor': 'rgba(81, 92, 230, 0.05)',
  'week.weekend.backgroundColor': 'inherit',

  // week timegrid 'timegrid'
  'week.timegridLeft.width': '72px',
  'week.timegridLeft.backgroundColor': 'inherit',
  'week.timegridLeft.borderRight': '1px solid #e5e5e5',
  'week.timegridLeft.fontSize': '11px',
  'week.timegridLeftTimezoneLabel.height': '40px',
  'week.timegridLeftAdditionalTimezone.backgroundColor': 'white',

  'week.timegridOneHour.height': '52px',
  'week.timegridHalfHour.height': '26px',
  'week.timegridHalfHour.borderBottom': 'none',
  'week.timegridHorizontalLine.borderBottom': '1px solid #e5e5e5',

  'week.timegrid.paddingRight': '0px',
  'week.timegrid.borderRight': '1px solid #e5e5e5',
  'week.timegridSchedule.borderRadius': '2px',
  'week.timegridSchedule.paddingLeft': '2px',

  'week.currentTime.color': '#515ce6',
  'week.currentTime.fontSize': '11px',
  'week.currentTime.fontWeight': 'normal',

  'week.pastTime.color': '#bbb',
  'week.pastTime.fontWeight': 'normal',

  'week.futureTime.color': '#333',
  'week.futureTime.fontWeight': 'normal',

  'week.currentTimeLinePast.border': '1px dashed #515ce6',
  'week.currentTimeLineBullet.backgroundColor': '#515ce6',
  'week.currentTimeLineToday.border': '1px solid #515ce6',
  'week.currentTimeLineFuture.border': 'none',

  // week creation guide style
  'week.creationGuide.color': '#515ce6',
  'week.creationGuide.fontSize': '11px',
  'week.creationGuide.fontWeight': 'bold',

  // week daygrid schedule style
  'week.dayGridSchedule.borderRadius': '2px',
  'week.dayGridSchedule.height': '24px',
  'week.dayGridSchedule.marginTop': '2px',
  'week.dayGridSchedule.marginLeft': '8px',
  'week.dayGridSchedule.marginRight': '8px'
}

export const calenders = [
  {
    id: '0',
    name: 'Initial agent call',
    bgColor: '#c7ce4a',
    borderColor: '#c7ce4a',
    appointment: {}

  }, {
    id: '1',
    name: 'Follow up agent call',
    bgColor: '#82a8ec',
    borderColor: '#82a8ec',
    appointment: {}
  }, {
    id: '2',
    name: 'Initial Agent Town tour',
    bgColor: '#f9d800',
    borderColor: '#f9d800',
    appointment: {}
  }, {
    id: '3',
    name: 'Follow up agent visit',
    bgColor: '#34a853',
    borderColor: '#34a853',
    appointment: {}
  }, {
    id: '4',
    name: 'Inspection',
    bgColor: '#0072c6',
    borderColor: '#0072c6',
    appointment: {}
  }, {
    id: '5',
    name: 'Closing',
    bgColor: '#d378f2',
    borderColor: '#d378f2',
    appointment: {}
  }, {
    id: '6',
    name: 'Outlook Calendar Event',
    bgColor: '#9b9b9b',
    borderColor: '#9b9b9b',
    appointment: {}
  }, {
    id: '7',
    name: 'Google Calendar Event',
    bgColor: '#9b9b9b',
    borderColor: '#9b9b9b',
    appointment: {}
  }, {
    id: '8',
    name: 'Busy',
    bgColor: '#9b9b9b',
    borderColor: '#9b9b9b',
    appointment: {}
  }
]

export const timezones = [
  // {
  //   timezoneOffset: 540,
  //   displayLabel: 'GMT+09:00',
  //   tooltip: 'Seoul'
  // }, {
  //   timezoneOffset: -420,
  //   displayLabel: 'GMT-08:00',
  //   tooltip: 'Los Angeles'
  // }
]
export const Option = {
  week: {
    showTimezoneCollapseButton: true,
    timezonesCollapsed: true,
    //narrowWeekend: false,
  },
  month: {
    startDayOfWeek: 0,
    isAlways6Week: false
  },
  template: {
    // time and title on calendar shows on calendar
    time: function (schedule) {
      // eslint-disable-next-line prefer-template
      return "<strong>" + moment(schedule.start.getTime()).format("hh:mma") + "</strong> " + schedule.title
    },
    schedule: function (schedule) {
      // eslint-disable-next-line prefer-template
      return '<span class="calendar-font-icon ic-milestone-b"></span> <span>' + moment(schedule.start).format("hh:mm a") + " " + schedule.title + '</span>';
    },
    popupDetailBody: function (schedule) {
      if (schedule.body) {
        return `Description : ${schedule.body}`;
      }
      return "";
    },
    popupDetailLocation: function (schedule) {
      if (schedule.location) {
        return `Location : ${schedule.location}`
      }
      return "";
    },
    popupDetailUser: function (schedule) {
      if (schedule.attendees) {
        return `Agent : ${(schedule.attendees || []).join(', ')}`;
      }
      return "";
    },
    // popup details show date
    popupDetailDate(isAllDay, start, end) {
      const isSameDate = moment(start).isSame(end);
      const endFormat = `${(isSameDate ? "" : "MM/DD/YYYY ")}hh:mm a`;
      if (isAllDay) {
        return `${moment(start.getTime()).format("MM/DD/YYYY")}${isSameDate ? "" : " - "}${moment(end).format("MM/DD/YYYY")}`;
      }
      return `${moment(start.getTime()).format("MM/DD/YYYY hh:mm a")}-${moment(end.getTime()).format(endFormat)}`;
    }
  }
}