const ThankYouEmailTemplate = {
  CONTENT: `Hi <<client_first_name>>! My name is <<agent_full_name>> and I am the local partner agent working with Suburban Jungle to help you begin exploring! I have been partnered with you to help you learn more about <<town_name>>. I look forward to showing you around and providing a great tour of the area! If you are interested, we can also see some homes during our visit based on your criteria.
Please let me know when you are available for a quick chat. I look forward to hearing from you!
 
You can reply to this message, or below is my contact info: 
 
<<agent_full_name>>
<<agent_email>> / <<agent_mobile_number>>`
}

export default Object.freeze(ThankYouEmailTemplate)