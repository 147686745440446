import { connect } from 'react-redux'
import { loginUserClient, clientValidateToken } from '../../store/user/duck'
import ClientLoginForm from './component'
const ClientLogin = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientloginPhase: state.user.clientloginPhase,
    clientloginError: state.user.clientloginError,
    isAuthenticated:state.user.clientloginPhase
  }),
  // Map actions to props
  {
    loginUserClient,
    clientValidateToken
  }
)(ClientLoginForm)
export default ClientLogin
