import React from 'react'
import { map } from 'lodash'
import moment from 'moment';

export default function AppointmentList({ applicants, history,tab, approveApplicant, rejectApplicant }) {

  return (
    <tbody>
      {map(applicants, (applicant, index) => (
        <tr
          key={index}
          id={index}
        >
          <td> {applicant.strategist_first_name} {applicant.strategist_last_name} </td>
          <td className="disable__cursor__pointer"><a  onClick={()=>{window.location.href = `/strategist/edit-user/${applicant.agent_id}`}} className="disable__text__decoration">{applicant.agent_first_name} {applicant.agent_last_name}</a></td>
          <td className="disable__cursor__pointer">{applicant.suburb}</td>
          <td>{moment(applicant.created).format('MMMM DD, YYYY')}</td>
          <td className="disable__cursor__pointer">
            <button onClick={() => approveApplicant(applicant)} className="btn btn__btn btn_38px btn-dark">Approve</button>
            {/*{rejectApplicant ? <button onClick={()=>rejectApplicant(applicant.id)} className="ml__disable__cursor__pointer btn btn__btn btn_38px">Reject</button> : null}*/}
          </td>
        </tr>
      ))}
    </tbody>
  )
}
