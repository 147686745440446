import React, { PureComponent } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { get } from 'lodash'

import CalendarSidebar from './partials/CalendarSidebar/component'
import MainCalendar from './partials/Calendar/component'
import TimeDrawer from './partials/TimeAvailabilityDrawer/component'
import AppointmentModel from '../Appointment/container'
import UserContext from "../../../App/Context"
import './styles.scss'
import { message } from 'antd'

class CalendarComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isEditAppointment: false,
      calendarView: "month",
      currentDate: new Date()
    }
    // create reference for main calendar
    this.mainCalendarRef = React.createRef();
    // reference for small calendar
    this.calendarSidebarRef = React.createRef();
    this.changeCurrentDate = this.changeCurrentDate.bind(this)
    this.handleCurrentDate = this.handleCurrentDate.bind(this)
    this.reFetchAppointment = this.reFetchAppointment.bind(this)
    this.openAppointmentModel = this.openAppointmentModel.bind(this)
    this.toggleUpdateAvailability = this.toggleUpdateAvailability.bind(this)
    this.performOperationOnCalendar = this.performOperationOnCalendar.bind(this)
  }

  componentDidMount(){
    let showTimerDrawer = get(this.props, 'location.state.showTimerDrawer', false)

    if(showTimerDrawer){
      if (this.props.history && this.props.history.location && this.props.history.location.state) {
        let state = this.props.history.location.state
        delete state.showTimerDrawer
        this.props.history.replace({ ...history.location, state })
      }
      this.setState({ visible: showTimerDrawer })
    }
  }

  onNewSlotRequest = () => {
    this.setState({ visible: true, appointmentModel: false });
  }

  toggleUpdateAvailability = () => {
    const { visible } = this.state
    this.setState({ visible: !visible });
  };
  // change current date change on click or scroll on big calender
  changeCurrentDate(currentDate) {
    this.setState({ currentDate })
    this.calendarSidebarRef.current.goToCurrentDate(currentDate)
  }
  // open close appointment model
  openAppointmentModel() {
    const { appointmentModel } = this.state
    if (!appointmentModel) {
      this.setState({ eventStartTime: "", eventEndTime: "" })
    }
    this.setState({ appointmentModel: !appointmentModel, isEditAppointment: false })
  }
  // small react-calender change react calender
  handleCurrentDate(currentDate) {
    this.setState({ currentDate })
    this.mainCalendarRef.current.openSelectedDate(currentDate);
  }
  // on click operation perform appointment
  performOperationOnCalendar(operation, event) {
    if (operation === "createSchedule") {
      let eventStartTime = ""
      let eventEndTime = ""
      if (moment().format("YYYY-MM-DD") === moment(new Date(event.start)).format("YYYY-MM-DD")
        && moment().format("HH:mm") >= moment(new Date(event.start)).format("HH:mm")) {
        eventStartTime = ""
        eventEndTime = ""
      } else {
        eventStartTime = event.start ? moment(new Date(event.start)).format('HH:mm') : ""
        eventEndTime = event.end ? moment(new Date(event.end)).format('HH:mm') : ""
      }
      const time = event.start && event.start < moment().endOf('day')
      this.setState({
        appointmentModel: true,
        isEditAppointment: false,
        selectAppointmentDate: time ? moment() : event.start,
        eventStartTime,
        eventEndTime,
        selectedEvent: {}
      })
    }
    if (operation === "updateSchedule") {
      let eventStartTime = ""
      let eventEndTime = ""
      let appointmentModel = true
      let reScheduleDate = false;
      // check start and end date exists in raw schedule
      if (get(event, 'start', false)) {
        // if appointment is below current date
        if (moment().format("YYYY-MM-DD") > moment(new Date(event.start)).format("YYYY-MM-DD")) {
          message.error("Past date/time appointment not allowed ");
          appointmentModel = false
        } else {
          // reschedule when user draging
          reScheduleDate = moment(new Date(event.start)).format("YYYY-MM-DD")
          // eslint-disable-next-line no-lonely-if
          if (moment().format("YYYY-MM-DD") === moment(new Date(event.start)).format("YYYY-MM-DD")
            && moment().format("HH:mm") >= moment(new Date(event.start)).format("HH:mm")) {
            eventStartTime = ""
            eventEndTime = ""
          } else {
            eventStartTime = event.start ? moment(new Date(event.start)).format('HH:mm') : ""
            eventEndTime = event.end ? moment(new Date(event.end)).format('HH:mm') : ""
          }
        }
      } else if (get(event, "schedule.raw.start_date", false)) {
        const start = event.schedule.raw.start_date
        const end = event.schedule.raw.end_date
        // if (moment().format("YYYY-MM-DD") === moment(new Date(start)).format("YYYY-MM-DD")
        //   && moment().format("HH:mm") >= moment(new Date(start)).format("HH:mm")) {
        //   eventStartTime = ""
        //   eventEndTime = ""
        // } else {
          eventStartTime = start ? moment(new Date(start)).format('HH:mm') : ""
          eventEndTime = end ? moment(new Date(end)).format('HH:mm') : ""
        // }
      }
      this.setState({
        appointmentModel,
        isEditAppointment: true,
        selectedEvent: event.schedule,
        reScheduleDate,
        eventStartTime,
        eventEndTime
      })
    }
  }
  // fetch all appointment when create/update appointment
  reFetchAppointment() {
    const user = this.context.userData
    this.props.getAppointments({
      // eslint-disable-next-line camelcase
      user_id: user.id,
      timezone: user.time_zone
    })
    this.setState({
      appointmentModel: false,
      isEditAppointment: false,
      selectAppointmentDate: moment(),
      eventStartTime: "",
      eventEndTime: ""
    })
  }
  render() {
    return (
      <div className="calender__fullpage">
        {/* Side bar for time availability/sync/and small calendar */}
        <CalendarSidebar
          {...this.props}
          // ref for change month
          ref={this.calendarSidebarRef}
          reFetchAppointment={this.reFetchAppointment}
          toggleUpdateAvailability={this.toggleUpdateAvailability}
          changeCurrentDate={this.handleCurrentDate}
          openAppointmentModel={this.openAppointmentModel}
          currentDate={this.state.currentDate}
        />
        {/* Main calendar for big calendar to show appointment */}
        <MainCalendar
          {...this.props}
          // ref update/create/delete schedule
          ref={this.mainCalendarRef}
          // fetch appointment again
          reFetchAppointment={this.reFetchAppointment}
          performOperationOnCalendar={this.performOperationOnCalendar}
          changeCurrentDate={this.changeCurrentDate}
          currentDate={this.state.currentDate}
        />
        {/* For create and update Appointment */}
        <AppointmentModel
          {...this.props}
          event={this.state.selectedEvent}
          // on create event start and end
          eventStartTime={this.state.eventStartTime}
          // on create event start and end
          eventEndTime={this.state.eventEndTime}
          selectAppointmentDate={this.state.selectAppointmentDate}
          // if appointment is edit send isEdit as true
          isEdit={this.state.isEditAppointment}
          // if user wants reschedule using drag on week view
          reScheduleDate={this.state.reScheduleDate}
          // open close state appointment model
          openAppointment={this.state.appointmentModel}
          // change open close appointment model
          closeAppointmentModal={this.openAppointmentModel}
          // featch all appointment on parents components
          reFetchAppointment={this.reFetchAppointment}
          onNewSlotRequest={this.onNewSlotRequest}
        />
        {/* Time drawer for update availabiltiy*/}
        <TimeDrawer
          {...this.props}
          visible={this.state.visible}
          toggleUpdateAvailability={this.toggleUpdateAvailability}
        />
      </div>
    )
  }
}
export default CalendarComponent
