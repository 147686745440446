/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react'
import { Pagination, Spin } from 'antd'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { get, isEmpty } from 'lodash'
import ClientList from '../layout/ClientList'
import ClientTableHeader from '../layout/ClientTableHeader'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Loader from '../../../../../../components/Loader'

import './styles.scss'
import MobileTableView from './partials/MobileTableView'
import NoClientFound from '../layout/NoClientFound'
import { ERROR, SUCCESS } from '../../../../../../constants/phase'

import { shouldCopyEmailDisplay, shouldCopyEmailEnabled, DisableCopyEmailPopup } from '../common'

const cookies = new Cookies()

export default class NewClientsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      clientStatus: [],
      clientStrategist: [],
      clientAgent: [],
      clientTiming: [],
      clientType: 'my_clients',
      clientSuburb: [],
      clientTown: [],
      clientBizDevCredit: [],
      clientCurrentLocation: false,
      pageLimit: 20,
      totalPages: 20,
      hideInactive: false,
      activePage: 1,
      sortByKey: '',
      sortByType: 0,
      user: {},
      copied: false,
      selectOutIndex: null,
      selectInEmails: false,
      clientsEmails: '',
    }
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (user) {
      this.setState({ user })
      const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
      if (!get(this.props, 'location.state.filter', false)) {
        if (localclientdata && localclientdata !== null && localclientdata !== '') {
          this.setState(
            {
              isLoading: true,
              selectedClientStatus: localclientdata.clientStatus,
              selectedClientStrategists: localclientdata.clientStrategist,
              selectedClientTimings: localclientdata.clientTiming,
              selectedClientSuburbs: localclientdata.clientSuburb,
              selectedClientAgents: localclientdata.clientAgent,
              selectedClientTowns: localclientdata.clientTown,
              selectedClientBizDevCredits: localclientdata.clientBizDevCredit,
              clientType: 'my_clients',
              clientStatus: localclientdata.clientStatus,
              status: isEmpty(localclientdata.clientStatus) ? false : true,
              statusDropdown: isEmpty(localclientdata.clientStatus) ? false : true,
              clientSuburb: localclientdata.clientSuburb,
              filterCount: localclientdata.filterCount,
              clientStrategist: localclientdata.clientStrategist,
              clientAgent: localclientdata.clientAgent,
              clientTown: localclientdata.clientTown,
              clientBizDevCredit: localclientdata.clientBizDevCredit,
              clientTiming: localclientdata.clientTiming,
              clientCurrentLocation: localclientdata.clientCurrentLocation,
              hideInactive: localclientdata.hideInactive,
              pageLimit: localclientdata.pageLimit,
              activePage: localclientdata.activePage,
              startDate: localclientdata.startDate,
              endDate: localclientdata.endDate,
              ...(localclientdata.sortBy && {sortByKey: localclientdata.sortBy.key}),
              ...(localclientdata.sortByType && {sortByType: localclientdata.sortBy.type})
            },
            () => {
              this.props.getClientList({
                clientType: this.state.clientType,
                clientStatus: this.state.clientStatus,
                clientSuburb: this.state.clientSuburb,
                clientStrategist: this.state.clientStrategist,
                clientAgent: this.state.clientAgent,
                clientTiming: this.state.clientTiming,
                clientTown: this.state.clientTown,
                clientBizDevCredit: this.state.clientBizDevCredit,
                clientCurrentLocation: this.state.clientCurrentLocation,
                hideInactive: this.state.hideInactive,
                pageLimit: localclientdata.pageLimit ? localclientdata.pageLimit : this.state.pageLimit,
                activePage: this.state.activePage,
                startDate: localclientdata.startDate ? localclientdata.startDate : this.state.startDate,
                endDate: localclientdata.endDate ? localclientdata.endDate : this.state.endDate
              })
            }
          )
        } else {
          const {
            clientType,
            filterCount,
            clientStatus,
            clientStrategist,
            clientTiming,
            clientSuburb,
            clientAgent,
            clientTown,
            clientBizDevCredit,
            pageLimit,
            activePage,
            startDate,
            endDate,
            hideInactive,
            sortByType,
            sortByKey,
          } = this.state
          const clientdata = {
            clientType,
            clientStatus,
            clientSuburb,
            clientStrategist,
            clientAgent,
            clientTown,
            clientBizDevCredit,
            clientTiming,
            hideInactive,
            pageLimit,
            activePage,
            startDate,
            endDate,
            sortBy: { key: sortByKey, type: sortByType },
          }
          const localclientdata = JSON.parse(localStorage.getItem('dashboardFromToDate'))
          if (localclientdata && localclientdata !== null && localclientdata !== '') {
            clientdata.startDate = localclientdata.startDate ? localclientdata.startDate : clientdata.startDate
            clientdata.endDate = localclientdata.endDate ? localclientdata.endDate : clientdata.endDate
          }
          localStorage.setItem('clientFilterData', JSON.stringify({ ...clientdata, filterCount }))
          this.props.getClientList(clientdata)
        }
      }
    }
  }

  componentDidUpdate(preProps, preState) {
    const { clientsData } = this.props
    if (clientsData && clientsData.status === true) {
      const clients = clientsData && clientsData.users && clientsData.users.length > 0 && clientsData.users
      let clientsEmails = []
      if (clients.length >= 1) {
        clients.map((client) => {
          clientsEmails.push(client.client_email)
        })
        this.setState({ clientsEmails: clientsEmails.toString() })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let states = {
      ...state,
      totalPages: Math.round(
        props.clientsData &&
        props.clientsData.user_counts &&
        state.clientType &&
        props.clientsData.user_counts[state.clientType]
      ),
      pageLimit: props.resultEntry
    }
    // when datea phase is success then update page size
    if (props.filterClientsPhase === SUCCESS) {
      props.clearClientPhase()
      states = { ...states, isLoading: false }
    }
    // when api failed or api not working
    if (props.filterClientsPhase === ERROR) {
      props.clearClientPhase()
      states = { ...states, isLoading: false }
    }
    // if page my_clients with state
    if (get(props, 'clientsData.user_counts.my_clients', false)) {
      states = { ...states, isLoading: false }
    }
    return states
  }

  handlePageChange = (pageNumber) => {
    const {
      clientType,
      clientStatus,
      clientStrategist,
      clientTiming,
      clientSuburb,
      clientAgent,
      pageLimit,
      hideInactive,
      sortByType,
      sortByKey,
      startDate,
      endDate
    } = this.state
    const clientdata = {
      clientType,
      clientStatus,
      clientSuburb,
      clientStrategist,
      clientAgent,
      clientTiming,
      hideInactive,
      pageLimit,
      activePage: pageNumber,
      sortBy: { key: sortByKey, type: sortByType },
      startDate: startDate ? moment.utc(startDate).format('YYYY-MM-DD') : "",
      endDate: endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ""
    }
    localStorage.setItem('clientFilterData', JSON.stringify(clientdata))
    this.setState({ isLoading: true, activePage: pageNumber }, () => this.props.getClientList(clientdata))
  }

  sortClientData = (sortByKey) => {
    let sortByType = this.state.sortByType
    sortByType =
      // eslint-disable-next-line no-nested-ternary
      sortByKey !== this.state.sortByKey ? 0 : sortByType === 0 ? 1 : 0
    this.setState({ sortByKey, sortByType, isLoading: true }, () => this.handlePageChange(1))
  }

  // when click on userfilter set filter data from filter user
  setfilterParams = (data) => {
    const { clientStatus, filteredStrategists, filteredAgents, filteredTimings, filteredSuburb, startDate, endDate, filterCount, searchCurrentLocation } = data
    this.setState({
      clientStatus,
      clientStrategist: filteredStrategists,
      clientTiming: filteredTimings,
      clientAgent: filteredAgents,
      clientSuburb: filteredSuburb,
      selectedClientStatus: clientStatus,
      selectedClientStrategists: filteredStrategists,
      selectedClientTimings: filteredTimings,
      selectedClientAgents: filteredAgents,
      selectedClientSuburbs: filteredSuburb,
      clientCurrentLocation: searchCurrentLocation,
      filterCount: filterCount ? filterCount : 0,
      startDate: startDate ? moment.utc(startDate).format('YYYY-MM-DD') : "",
      endDate: endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ""
    })
    this.forceUpdate()
  }

  onCopy4() {
    this.setState({ selectInEmails: true })
    setTimeout(() => {
      this.setState({ selectInEmails: false })
    }, 2000)
    this.copyPopup.open()
  }

  render() {
    const { clientsData, resultEntry, isCopyEmail, showStrategistColumn, showCurrentLocationColumn, allowFollowUpDateEdit } = this.props
    const { user, filterCount, pageLimit, totalPages, sortByKey, sortByType, isLoading, clientBizDevCredit } = this.state
    const clients =
      clientsData && clientsData.users && clientsData.users.length > 0 && clientsData.users
      const disableCopyEmail = !shouldCopyEmailEnabled(user, filterCount)
    return (
      <div>
        {/* This component for mobile only */}
        <MobileTableView clients={clients} {...this.props} currentUser={this.state.user} />
        {/* End component for mobile only */}
        <div className="desktop__view">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Spin size="large" spinning={isLoading} indicator={<Loader />}>
                  {/*{shouldCopyEmailDisplay(isCopyEmail, clients, user) ? (*/}
                  {/*  <div className="text-right">*/}
                  {/*    <CopyToClipboard*/}
                  {/*      className="btn btn__btn btn-dark btn__150"*/}
                  {/*      text={this.state.clientsEmails}*/}
                  {/*      onCopy={this.onCopy4.bind(this)}*/}
                  {/*    >*/}
                  {/*      <button  disabled={disableCopyEmail} className="copyBoxButton orderchange" >*/}
                  {/*        {this.state.selectInEmails ? "Copied" : "Copy"} Emails*/}
                  {/*      </button>*/}
                  {/*    </CopyToClipboard>*/}
                  {/*    <DisableCopyEmailPopup */}
                  {/*      enabled={disableCopyEmail}*/}
                  {/*      onRef={(copyPopup)=>{*/}
                  {/*        this.copyPopup = copyPopup*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </div>) :*/}
                  {/*  ''}*/}
                  {clients && clients.length > 0 ? (
                    <table className="table custom-table">
                      <ClientTableHeader
                        sortByType={sortByType}
                        sortByKey={sortByKey}
                        sortClientData={this.sortClientData}
                        currentUser={this.state.user}
                        bizDevCreditFilter={this.state.clientBizDevCredit}
                        currentLocationFilter={this.state.clientCurrentLocation}
                        showStrategistColumn={showStrategistColumn}
                        showCurrentLocationColumn={showCurrentLocationColumn}
                        allowFollowUpDateEdit
                      />

                      <ClientList 
                        clients={clients}
                        {...this.props}
                        currentUser={this.state.user} 
                        bizDevCreditFilter={this.state.clientBizDevCredit} 
                        currentLocationFilter={this.state.clientCurrentLocation}
                        showStrategistColumn={showStrategistColumn}
                        showCurrentLocationColumn={showCurrentLocationColumn}
                        allowFollowUpDateEdit />
                    </table>
                  ) : (
                    <NoClientFound />
                  )}
                </Spin>
              </div>
            </div>
          </div>
        </div>
        {clients && clients.length > 0 && resultEntry < totalPages && totalPages > 1 && (
          <Pagination
            className="mt__20 mb__20"
            defaultCurrent={this.state.activePage}
            total={totalPages}
            showQuickJumper={false}
            showSizeChanger={false}
            pageSize={pageLimit}
            onChange={this.handlePageChange}
          />
        )}
      </div>
    )
  }
}
