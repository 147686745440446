import { connect } from 'react-redux'

import BDSourceReportComponent from './component'
import { getFilterParams,getDevelopmentMembers } from '../../../../../../store/strategistV2/dashboard/actions'
import { getAccountingReportData, clearStrategistPhase , getBDSourceReportData } from '../../../../../../store/strategistV2/strategist/actions'

const BDSourceReportContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    accountingReportPhase: state.strategistV2.strategist.accountingReportPhase,
    accountingReportData: state.strategistV2.strategist.accountingReportData,
    bdSourceReportPhase: state.strategistV2.strategist.bdSourceReportPhase,
    bdSourceReportData: state.strategistV2.strategist.bdSourceReportData,
    developmentMembers:state.strategistV2.dashboard.developmentMembers
  }),
  // Map actions to dispatch and props
  {
    getAccountingReportData,
    clearStrategistPhase,
    getFilterParams,
    getBDSourceReportData,
    getDevelopmentMembers
  }
)(BDSourceReportComponent)

export default BDSourceReportContainer
