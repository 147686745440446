export const occupationList = [
  { label: 'White Collar', value: 'white_collar' },
  { label: 'Blue Collar', value: 'blue_collar' },
  { label: 'Finance', value: 'finance_legal' },

  // new
  { label: 'Legal', value: 'legal' },
  { label: 'Medical', value: 'medical' },
  { label: 'Entrepreneur', value: 'entrepreneur_self_employed' },
  { label: 'Tech', value: 'tech' },
  { label: 'Creative', value: 'creative' },

  // new
  { label: 'Sales/Marketing', value: 'sales_marketing' },
  { label: 'Retail', value: 'retail' },
  { label: 'Education', value: 'education' },
  { label: 'Government', value: 'government' },
  { label: 'Nonprofit', value: 'nonprofit' },
  { label: 'Nice mix of all', value: 'nice_mix_of_all' }
];

export const landSizes = [
  { label: 'Up to ¼ acre', value: 'land_up_to_1_4_acre' },
  { label: 'Up to ½ acre', value: 'land_up_to_1_2_acre' },
  { label: 'Up to 1 acre', value: 'land_up_to_1_acre' },
  { label: '1+acres', value: 'land_up_to_1_plus' },
  { label: 'Large acreage', value: 'large_acreage' }
];

export const budget = [
  { label: 'Price Under 500K', value: 'price_under_500k' },
  { label: 'Price 500k to 1 mil', value: 'price_500k_1mill' },
  { label: 'Price 1 to 1.5 mil', value: 'price_1_1_5mill' },
  { label: 'Price 1.5 to 2 mil', value: 'price_1_5_2mill' },
  { label: 'Price 2 to 3 mil', value: 'price_2_3mill' },
  { label: ' Price 3 mil Plus', value: 'price_3mill_plus' }
];

export const fancyLaidBack = [
  { label: 'Fancy', value: 'fancy' },
  { label: 'Laid back', value: 'laid_back' }
];

export const homeType = [
  { label: 'Single family home', value: 'single_family' },
  { label: 'Townhome', value: 'townhome' },
  { label: 'Condo', value: 'condo' }
];

export const progressiveConservative = [
  { label: 'Progressive', value: 'progressive' },
  { label: 'Conservative', value: 'conservative' }
];

export const sportyArtsy = [
  { label: 'Sporty', value: 'town_personality_sporty' },
  { label: 'Creative/Artsy', value: 'town_personality_artsy' }
];

export const goodForFinding = [
  { label: 'Live in Nanny, non driver', value: 'childcare_live_in_non_driver' },
  { label: 'Live in Nanny, driver', value: 'childcare_live_in_driver' },
  { label: 'Live out Nanny, driver', value: 'childcare_live_out_driver' },
  {
    label: 'Live out Nanny, non driver',
    value: 'childcare_live_out_non_driver'
  },
  { label: 'Part time sitter', value: 'childcare_pt_sitter' },
  { label: 'Au pair', value: 'childcare_au_pair' },
  { label: 'Family', value: 'childcare_none_parents' },
  { label: 'None', value: 'none' },
  { label: 'Day Care', value: 'childcare_daycare' }
];

export const willingToCommute = [
  { label: 'Up to 30 minutes', value: 'commute_30_to_less' },
  { label: 'Up to 45 minutes', value: 'commute_30_to_45' },
  { label: 'Up to 60 minutes', value: 'commute_45_to_60' },
  { label: '1 hr +', value: 'commute_60_to_high' }
];

export const transportation = [
  { label: 'Train', value: 'trans_mode_train' },
  { label: 'Bus', value: 'trans_mode_bus' },
  { label: 'Car', value: 'trans_mode_car' },
  { label: 'Walk', value: 'trans_mode_walk' },
  { label: 'Bike', value: 'trans_mode_bike' },
  { label: 'Ferry', value: 'trans_mode_ferry' }
];
