import { List } from 'immutable'
import { fetch } from '../../utils'


const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST


export const getChatHistory = (data) => {

  const queryString = new URLSearchParams({
    receiver_id: data.receiver_id,
    sender_id: data.sender_id,
    message_type: 'agent'
  })
  const url = `${SUBURBANJUNGLE_API_HOST}/chat/?${queryString}`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClientChatData = (data) => {
  const queryString = new URLSearchParams({
    client_id: data.user_id,
    message_type: 'strategist'
  })
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}
