import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/*
   ///////////////////////////////////
  /// Interal notes epics ///
//////////////////////////////////
*/
// fetch all internal notes epic
const fetchInteralNotesEpic = (action$) =>
  action$.ofType(types.FETCH_INTERAL_NOTES).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchInteralNotes(action.payload))
      .map((payload) => ({
        type: types.FETCH_INTERAL_NOTES_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_INTERAL_NOTES_ERROR,
          payload: { error },
        })
      )
  })

// on create notes call create note api on action on create new note
const createNewNoteEpic = (action$) =>
  action$.ofType(types.CREATE_NEW_NOTE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createNewNote(action.payload))
      .map((payload) => ({
        type: types.CREATE_NEW_NOTE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_NEW_NOTE_ERROR,
          payload: { error },
        })
      )
  })

const editNoteEpic = (action$) =>
  action$.ofType(types.EDIT_INTERNAL_NOTE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.editInternalNote(action.payload))
      .map((payload) => ({
        type: types.EDIT_INTERNAL_NOTE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.EDIT_INTERNAL_NOTE_ERROR,
          payload: { error },
        })
      )
  })

// remove notes on action of delete notes
const removeNoteEpic = (action$) =>
  action$.ofType(types.DELETE_INTERAL_NOTE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.removeNote(action.payload))
      .map((payload) => ({
        type: types.DELETE_INTERAL_NOTE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.DELETE_INTERAL_NOTE_ERROR,
          payload: { error },
        })
      )
  })

// combine internal notes epics
export const notesEpic = combineEpics(createNewNoteEpic, removeNoteEpic, fetchInteralNotesEpic, editNoteEpic)
