import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/*
    //////////////////////////////////////
  /// leave comment epics ///
/////////////////////////////////////
*/
// add leave comment for client details
const addLeaveCommentEpic = (action$) =>
  action$.ofType(types.ADD_LEAVE_COMMENTS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.addLeaveComments(action.payload))
      .map((payload) => ({
        type: types.ADD_LEAVE_COMMENTS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.ADD_LEAVE_COMMENTS_ERROR,
          payload: { error },
        })
      )
  })
// get  leave comment epic and midleware for api calling
const getLeaveCommentEpic = (action$) =>
  action$.ofType(types.GET_LEAVE_COMMENTS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getLeaveComments(action.payload))
      .map((payload) => ({
        type: types.GET_LEAVE_COMMENTS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_LEAVE_COMMENTS_ERROR,
          payload: { error },
        })
      )
  })
// remove leave comment epic
const removeLeaveCommentEpic = (action$) =>
  action$.ofType(types.REMOVE_LEAVE_COMMENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.removeLeaveComment(action.payload))
      .map((payload) => ({
        type: types.REMOVE_LEAVE_COMMENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.REMOVE_LEAVE_COMMENT_ERROR,
          payload: { error },
        })
      )
  })
// combine all leave comment epics
export const commentEpic = combineEpics(
  getLeaveCommentEpic,
  addLeaveCommentEpic,
  removeLeaveCommentEpic
)
