import { connect } from 'react-redux'

import AdminEditDealComponent from './component'
import { accountReportDealData, clearReportV2Phase } from '../../../../store/reportV2/duck'

const AdminEditDealContainer = connect(
  // Map state to props
  (state) => ({
    accountingEditDealPhase: state.reportV2.accountingEditDealPhase,
    accountingEditDealData: state.reportV2.accountingEditDealData
  }),
  // Map actions to dispatch and props
  {
    accountReportDealData,
    clearReportV2Phase
  }
)(AdminEditDealComponent)

export default AdminEditDealContainer
