import { connect } from 'react-redux'

import { getAdTrackingCodeReport, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

import AdTrackingReportComponent from './component'

const AdTrackingReportContainer = connect(
  // Map state to props
  (state) => ({
    adTrackingPhase: state.reportV2.adTrackingPhase,
    adTrackingData: state.reportV2.adTrackingData,
  }),
  // Map actions to dispatch and props
  {
    getAdTrackingCodeReport,
    clearReportV2Phase
  }
)(AdTrackingReportComponent)

export default AdTrackingReportContainer
