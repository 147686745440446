import { Record } from "immutable";
import { get } from "lodash";

import * as types from "./action-types";
import { INIT, LOADING, SUCCESS, ERROR } from "../../../constants/phase";

const InitialState = new Record({
  phase: INIT,
  error: null,
  notificationPhase: INIT,
  notificationData: [],
  markAsReadPhase: INIT,
  markAsReadData: []
});

export const headerReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    case types.GET_NOTIFICATION: {
      return state
          .set("error", null)
          .set("notificationPhase", LOADING);
    }
    case types.GET_NOTIFICATION_SUCCESS: {
      return state
        .set("notificationData", action.payload)
        .set("error", null)
        .set("notificationPhase", SUCCESS);
    }

    case types.GET_NOTIFICATION_ERROR: {
      return state
        .set("error", action.payload.error)
        .set("notificationPhase", ERROR);
    }

    //this is for mark all as read 
    case types.MARK_ALL_AS_READ: {
      return state
        .set("error", null)
        .set("markAsReadPhase", LOADING);
    }
    case types.MARK_ALL_AS_READ_SUCCESS: {
      return state
        .set("markAsReadData", action.payload)
        .set("error", null)
        .set("markAsReadPhase", SUCCESS);
    }

    case types.MARK_ALL_AS_READ_ERROR: {
      return state
        .set("error", action.payload.error)
        .set("markAsReadPhase", ERROR);
    }

    default: {
      return state;
    }
  }
};
