// client routest for
export const dashboardRoutes = [
  '/administration/dashboard',
  '/report/clients-per-agents',
  '/report/account-report',
  '/report/agents-assigned-to-towns',
  '/report/agent-rating-report',
  '/report/agent-statistics',
  '/report/clients-assigned-per-town',
  '/report/clients-assign-to-agents',
  '/report/login-records',
  '/report/strategist-rating-report',
  '/report/strategist-statistics',
  '/report/strategist-timesheet',
  '/report/total-clients-received-per-month',
  '/report/agents-assign-by-ny-strategist',
  '/report/business-development',
  '/report/new-clients',
  '/report/appointment-scheduled',
  '/strategist/edit-deal',
  '/report/ai-clients',
  '/report/closed-clients-per-towns',
  '/report/ad-tracking',
  '/report/bd-source-report'
]

// client routest for
export const clientRoutes = [
  '/strategist/clients',
  '/strategist/clients/all-clients',
  '/strategist/clients/new-clients',
  '/strategist/add-client',
  '/strategist/client-details/',
  '/strategist/edit-information/',
  '/strategist/add-client',
  'strategist/ai/',
  '/strategist/emails/',
  '/strategist/town-details/',
  '/strategist/ai/',
  '/strategist/emails/',
  '/strategist/deal'
]

// task related routes
export const taskRoutes = [
  '/strategist/task-list',
  '/strategist/task-list/followup',
  '/strategist/task-list/overdue',
  '/strategist/task-list/not-touched',
]

// agent related routes
export const agentRoutes = ['/strategist/your-agents']

// interview related routes
export const interviewRoutes = ['/strategist/agent-interviews']

// town related routes
export const townsRoutes = ['/strategist/town-cheat-sheet']

// appointment related routes
export const appointmentRoutes = ['/strategist/agent-appointments']

// town report related routes
export const townReportRoutes = ['/strategist/town-reports', '/strategist/town-characteristics/']

// jungler report related routes
export const junglerRoutes = [
  '/strategist/jungler-users',
  '/strategist/add-user',
  '/strategist/edit-user',
]

// email template related routes
// export const emailTemplateRoutes = ['/strategist/email-template']