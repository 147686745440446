import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/* 
    //////////////////////////////
  /// Your agent epic ///
//////////////////////////////
 */
// fetch you agent epic
const fetchYourAgentEpic = (action$) =>
  action$.ofType(types.FETCH_YOUR_AGENT_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchYourAgent(action.payload))
      .map((payload) => ({
        type: types.FETCH_YOUR_AGENT_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_YOUR_AGENT_LIST_ERROR,
          payload: { error },
        })
      )
  })

// combine all your agent epics
export const yourAgentEpic = combineEpics(fetchYourAgentEpic)
