import React from 'react'
import moment from 'moment'
import { map, get } from 'lodash'
import { SortUpIcon, SortDownIcon } from '../../../../../components/icons'

export default function MobileTopView({
  sortByKey,
  sortByType,
  sortData,
  users,
  currentUser,
  impersonateUser,
  history,
}) {
  const sortJunglarUserData = (sortByKey) => sortData(sortByKey)
  return (
    <div className="mob__view__container mob__view__control mt__20">
      {users && users.length > 0 ? (
        <table className="table mob__custom__table">
          <thead>
            <tr>
              <th className="cursor__pointer" onClick={() => sortJunglarUserData('userName')}>
                <span className="table-column-title">Name</span>
                <span className="table-column-sorter">
                  {sortByKey === 'userName' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
                </span>
              </th>
              <th className="cursor__pointer" onClick={() => sortJunglarUserData('role')}>
                <span className="table-column-title">Role</span>
                <span className="table-column-sorter">
                  {sortByKey === 'role' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
                </span>
              </th>
              <th className="cursor__pointer" onClick={() => sortJunglarUserData('email')}>
                <span className="table-column-title">Email</span>
                <span className="table-column-sorter">
                  {sortByKey === 'email' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
                </span>
              </th>
              {/* <th className="cursor__pointer" onClick={() => sortJunglarUserData('phone')}>
          <span className="table-column-title">Phone</span>
          <span className="table-column-sorter">
            {sortByKey === 'phone' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th> */}
              <th
                className="cursor__pointer"
                colSpan="2"
                onClick={() => sortJunglarUserData('created')}
              >
                <span className="table-column-title">Created Date</span>
                <span className="table-column-sorter">
                  {sortByKey === 'created' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {map(users, (user) => (
              <tr
                className="cursor__pointer"
                key={user.id}
                onClick={() =>
                  history.push(`/strategist/edit-user/${user.id}`, {
                    pathnameToRoute: window.location.pathname,
                  })
                }
              >
                <td>{get(user, 'full_name', '')}</td>
                <td>{get(user, 'role', '')}</td>
                <td>{get(user, 'email', '')}</td>
                {/* <td>{get(user, 'phone_mobile', '')}</td> */}
                <td>{user.created ? moment(user.created).format('MM/DD/YYYY') : ''}</td>

                <td>
                  {currentUser &&
                    currentUser.role &&
                    (currentUser.role === 'superadmin' ||
                      currentUser.role === 'admin' ||
                      currentUser.role === 'strategist') && (
                      <button
                        className="btn btn__btn btn__impersonate"
                        onClick={() => impersonateUser(user.id)}
                      >
                        Impersonate
                      </button>
                    )}
                </td>
                <td>
                  {currentUser &&
                    currentUser.role &&
                    (currentUser.role === 'superadmin' ||
                      currentUser.role === 'admin' ||
                      currentUser.role === 'strategist') && (
                      <button
                        className="btn btn__btn btn__impersonate"
                        onClick={() =>
                          history.push(`/strategist/edit-user/${user.id}`, {
                            pathnameToRoute: window.location.pathname,
                          })
                        }
                      >
                        Edit User
                      </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="blank__table__state">No Users</div>
      )}
    </div>
  )
}
