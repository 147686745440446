import React, { Component } from 'react'
import { Popover, DatePicker } from 'antd'
import moment from 'moment'
import { filter, map, get, isEmpty, size } from 'lodash'
import { FilterIcon } from "../../../../../components/icons"
import config from '../../../../../config'
import { configConsumerProps } from 'antd/lib/config-provider'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default class YourAgentFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initalFilter: true,
      visible: false,
      clientName: false,
      strategistName: false,
      agentName: false,
      priceRange: false,
      agentStatus: false,
      neverScheduled: false,
      appointmentType: false,
      appointmentDate: false,
      followUpAgentCall: false,
      followUpTownTour: false,
      notTouch4Week: false,
      clientList: [],
      filteredClients: [],
      filteredStatuses: [],
      filteredStrategist: [],
      searchedAppointmentType: config.typeOfAppointments,
      selectedAppointmentType: [],
      appointmentStartDate: '',
      searchStrategistName: '',
      filterCount: 0,
      setFilterOptions: true,
      filterOptions: {},
      minPrice: '',
      maxPrice: ''
    }
  }

  componentDidMount() {
    this.props.history.replace({pathname: '/strategist/your-agents', state: {}});
    this.fetchAgentFilterParams()
  }
  fetchAgentFilterParams = async () => {
    const { value: { data } } = await this.props.yourAgentsFilter({ searchTerm: '' })
    this.props.setFilterParams(data)
    this.setState({clientList: data.clients, agentStatusesMain: data.agentStatuses, ...data})
  }
  static getDerivedStateFromProps(props, state) {
    const states = { ...state }
    // redirection from dashboard page and have filter option only one time
    if (get(props, 'location.state.filter', false) && state.initalFilter) {
      let neverScheduled; let notTouch4Week; let followUpAgentCall; let followUpTownTour = false
      switch (props.location.state.filter) {
        case 'neverScheduledWithAgent':
          neverScheduled = true
          break
        case 'agentNotTouchedIn4Week':
          notTouch4Week = true
          break
        case 'noInitialOrFollowUpAgentCall':
          followUpAgentCall = true
          break
        case 'noInitialorFollowUpAgentTownTour':
          followUpTownTour = true
          break
        default:
          break
      }
      let filterOptions = { ...states.filterOptions }
      const filterCount = neverScheduled || notTouch4Week || followUpAgentCall || followUpTownTour ? 1 : 0
      if (state.setFilterOptions) {
        filterOptions = { neverScheduled, notTouch4Week, followUpAgentCall, followUpTownTour }
        props.setFilterOptions(filterOptions)
        localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...filterOptions, filterCount }))
      }
      return { ...states, neverScheduled, notTouch4Week, followUpAgentCall, followUpTownTour, filterCount, setFilterOptions: false, filterOptions }
    }
    return states
  }
  // show filter option model and inital filter for not set props state again and again
  filterVisible = visible => this.setState({ visible, initalFilter: false, searchedStrategists: this.getAllStrategists() })
 // close filter option
  handleCloseFilter = () => this.setState({ visible: false, initalFilter: false })
  // on click of clear filter remove all values
  handleClearFilter = () => [
    this.setState({
      strategistName: false,
      clientName: false,
      agentName: false,
      priceRange: false,
      agentStatus: false,
      appointmentType: false,
      searchClientName: '',
      searchStrategistName: '',
      appointmentDate: false,
      neverScheduled: false,
      followUpAgentCall: false,
      followUpTownTour: false,
      notTouch4Week: false,
      searchedAppointmentType: config.typeOfAppointments,
      selectedAppointmentType: [],
      clientList: [],
      filteredClients: [],
      filteredStrategist: [],
      filteredStatuses: [],
      searchedStrategists: this.getAllStrategists(),
      minPrice: '',
      maxPrice: '',
      filterCount: 0,
      filterOptions: {}
    }),
    localStorage.removeItem('yourAgentFilterOptions'),
    this.props.setFilterOptions({filterOptions: {}})
  ]
  // handle change main option not ineer filter option
  handleOptionChange = async (event) => {
    const { name } = event.target
    let { filterCount } = this.state
    const oldFilterCount = filterCount
    let { filterOptions } = this.state
    const { selectedAppointmentType, filteredStatuses, filteredClients, filteredStrategist } = this.state
    // on handle change of option date and date is filled
    if (name === 'appointmentDate' && this.state.appointmentDate) {
      if (this.state.appointmentStartDate !== '') {
        filterCount -= 1
      }
      filterOptions = { ...filterOptions, appointmentStartDate: '' }
      this.setState({ appointmentStartDate: '' })
    }
    if (name === 'appointmentType' && this.state.appointmentType) {
      if (selectedAppointmentType.length > 0) filterCount -= 1
      this.setState({ selectedAppointmentType: [] })
      filterOptions = { ...filterOptions, selectedAppointmentType: [] }
    }
    if (name === 'clientName' && this.state.clientName) {
      if (filteredClients.length > 0) filterCount -= 1
      this.setState({ filteredClients: [] })
      filterOptions = { ...filterOptions, filteredClients: [] }
    }
    if (name === 'clientName' && !this.state.clientName) {
      const { value: { data } } = await this.props.yourAgentsFilter({ searchTerm: '' })
      this.props.setFilterParams(data)
      this.setState({clientList: data.clients, agentStatusesMain: data.agentStatuses, ...data})
    }
    if (name === 'strategistName' && this.state.strategistName) {
      if (filteredStrategist.length > 0) filterCount -= 1
      const searchedStrategists = this.getAllStrategists()
      this.setState({ filteredStrategist: [], searchStrategistName: '', searchedStrategists })
      filterOptions = { ...filterOptions, filteredStrategist: [] }
    }
    if (name === 'strategistName' && !this.state.strategistName) {
      const searchedStrategists = this.getAllStrategists()
      this.setState({ filteredStrategist: [], searchStrategistName: '', searchedStrategists })
      filterOptions = { ...filterOptions, filteredStrategist: [] }
    }
    if (name === 'agentStatus' && this.state.agentStatus) {
      if (filteredStatuses.length > 0) filterCount -= 1
      this.setState({ filteredStatuses: [] })
      filterOptions = { ...filterOptions, filteredStatuses: [] }
    }
    // price range if max price are selected then set price range
    if (name === 'priceRange' && this.state.priceRange) {
      if (this.state.maxPrice !== '') {
        filterCount -= 1
      }
      filterOptions = { ...filterOptions, minPrice: '', maxPrice: '' }
      this.setState({ minPrice: '', maxPrice: '' })
    }
    if (name === 'neverScheduled') {
      if (this.state.neverScheduled) filterCount -= 1
      if (!this.state.neverScheduled) filterCount += 1
      filterOptions = { ...filterOptions, neverScheduled: !this.state.neverScheduled }
    }
    if (name === 'followUpAgentCall') {
      if (this.state.followUpAgentCall) filterCount -= 1
      if (!this.state.followUpAgentCall) filterCount += 1
      filterOptions = { ...filterOptions, followUpAgentCall: !this.state.followUpAgentCall }
    }
    if (name === 'followUpTownTour') {
      if (this.state.followUpTownTour) filterCount -= 1
      if (!this.state.followUpTownTour) filterCount += 1
      filterOptions = { ...filterOptions, followUpTownTour: !this.state.followUpTownTour }
    }
    if (name === 'notTouch4Week') {
      if (this.state.notTouch4Week) filterCount -= 1
      if (!this.state.notTouch4Week) filterCount += 1
      filterOptions = { ...filterOptions, notTouch4Week: !this.state.notTouch4Week }
    }
    // check any changes in option if change then call api
    if (oldFilterCount !== filterCount) {
      this.props.setFilterOptions(filterOptions)
      localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...filterOptions, filterCount }))
    }
    this.setState({ [name]: !this.state[name], filterCount, filterOptions })
    this.forceUpdate()
  }
  // filter search option based on search filed on filter option
  handleChange = async (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
    // filter appointment type based on search keyword
    if (name === "searchAppointmentType") {
      const searchedAppointmentType = filter(config.typeOfAppointments, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.setState({ searchedAppointmentType })
    }
    // filter client based on search text option
    if (name === "searchClientName") {
      const { value: { data } } = await this.props.yourAgentsFilter({ searchTerm: value })
      this.props.setFilterParams(data)
      this.setState({ clientList: data.clients })
    }
    // filter strategist based on search text option
    if (name === "searchStrategistName") {
      const strategists = this.getAllStrategists()
      const searchedStrategists = filter(strategists, search => search.strategist_name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.setState({ searchedStrategists })
    }
    if (name === "searchAgentStatus") {
      const statuses = filter(this.state.agentStatusesMain, search => search.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.setState({ agentStatuses: statuses })
    }
    this.forceUpdate()
  }
  // handle change and filter data based on date
  handleDateChange = (date) => {
    date = date ? moment(date).format('YYYY-MM-DD') : ''
    let { filterCount, filterOptions } = this.state
    if (date === '') {
      filterCount -= 1
    }
    if (this.state.appointmentStartDate === '') {
        filterCount += 1
    }
    filterOptions = {...filterOptions, appointmentStartDate: date}
    this.props.setFilterOptions(filterOptions)
    localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...filterOptions, filterCount }))
    this.setState({ appointmentStartDate: date, filterCount, filterOptions })
    this.forceUpdate()
  }
  // add remove filter option on serarch or selection
  // select inner option after filter or direct selection of object
  hanldeSelectOption = (id, type) => {
    const { selectedAppointmentType, filteredClients, filteredStatuses, filteredStrategist } = this.state
    let { filterCount, filterOptions } = this.state
    // reset filter appoitnment type
    if (type === 'filterAppointment') {
      if (selectedAppointmentType.indexOf(id) === -1) {
        if (selectedAppointmentType.length === 0) filterCount += 1
        selectedAppointmentType.push(id)
      } else {
        if (selectedAppointmentType.length === 1 && selectedAppointmentType.indexOf(id) !== -1) filterCount -= 1
        selectedAppointmentType.splice(selectedAppointmentType.indexOf(id), 1)
      }
      filterOptions = {...filterOptions, selectedAppointmentType}
    }
    // reset filter client status
    if (type === 'filteredClients') {
      if (filteredClients.indexOf(id) === -1) {
        if (filteredClients.length === 0) filterCount += 1
        filteredClients.push(id)
      } else {
        if (filteredClients.length === 1 && filteredClients.indexOf(id) !== -1) filterCount -= 1
        filteredClients.splice(filteredClients.indexOf(id), 1)
      }
      filterOptions = {...filterOptions, filteredClients}
    }
    // reset filter strategist status
    if (type === 'filteredStrategist') {
      if (filteredStrategist.indexOf(id) === -1) {
        if (filteredStrategist.length === 0) filterCount += 1
        filteredStrategist.push(id)
      } else {
        if (filteredStrategist.length === 1 && filteredStrategist.indexOf(id) !== -1) filterCount -= 1
        filteredStrategist.splice(filteredStrategist.indexOf(id), 1)
      }
      filterOptions = {...filterOptions, filteredStrategist}
    }
    // filter agent status push or save for new option and remove option if alerady exists
    if (type === 'filteredStatuses') {
      if (filteredStatuses.indexOf(id) === -1) {
        if (filteredStatuses.length === 0) filterCount += 1
        filteredStatuses.push(id)
      } else {
        if (filteredStatuses.length === 1 && filteredStatuses.indexOf(id) !== -1) filterCount -= 1
        filteredStatuses.splice(filteredStatuses.indexOf(id), 1)
      }
      filterOptions = {...filterOptions, filteredStatuses}
    }
    this.props.setFilterOptions(filterOptions)
    localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...filterOptions, filterCount }))
    this.setState({ selectedAppointmentType, filteredClients, filteredStatuses, filteredStrategist, filterCount, filterOptions })
    this.forceUpdate()
  }
  // when parent change and remove filter option set new filter option on child
  changeFilterOption = (data) => {
    let { filterCount } = this.state
    const { selectedAppointmentType, filteredClients, filteredStrategist, filteredStatuses } = this.state
    if (this.state.neverScheduled && !data.neverScheduled) filterCount = filterCount > 0 ? filterCount - 1 : 0
    if (this.state.notTouch4Week && !data.notTouch4Week) filterCount = filterCount > 0 ? filterCount - 1 : 0
    if (this.state.followUpAgentCall && !data.followUpAgentCall) filterCount = filterCount > 0 ? filterCount - 1 : 0
    if (this.state.followUpTownTour && !data.followUpTownTour) filterCount = filterCount > 0 ? filterCount - 1 : 0
    if (this.state.appointmentStartDate !== '' && data.appointmentStartDate === '') filterCount = filterCount > 0 ? filterCount - 1 : 0
    // max price is not exists and count down if price not exists
    if (this.state.maxPrice !== '' && data.maxPrice === '') {
      this.setState({ priceRange: false, minPrice: '', maxPrice: '' })
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (selectedAppointmentType && selectedAppointmentType.length > 0 && data.selectedAppointmentType && data.selectedAppointmentType.length === 0) {
      this.setState({ appointmentType: false, selectedAppointmentType: [] })
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (filteredClients && filteredClients.length > 0 && data.filteredClients && data.filteredClients.length === 0) {
      this.setState({ clientName: false, filteredClients: [], searchClientName: '' })
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (filteredStrategist && filteredStrategist.length > 0 && data.filteredStrategist && data.filteredStrategist.length === 0) {
      this.setState({ strategistName: false, filteredStrategist: [], searchStrategistName: '' })
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (filteredStatuses && filteredStatuses.length > 0 && data.filteredStatuses && data.filteredStatuses.length === 0) {
      this.setState({ agentStatus: false, filteredStatuses: [] })
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    // count up when new item added or show selected item
    if (this.state.maxPrice === '' && data.maxPrice && data.maxPrice !== '') {
      filterCount += 1
      this.setState({ priceRange: true, maxPrice: data.maxPrice, minPrice: data.minPrice  })
    }
    // if neverScheduled is not exists and data come for neverScheduled show selected
    if (!this.state.neverScheduled && data.neverScheduled) {
      filterCount += 1
      this.setState({ neverScheduled: true })
    }
    if (!this.state.notTouch4Week && data.notTouch4Week) {
      filterCount += 1
      this.setState({ notTouch4Week: true })
    }
    if (!this.state.followUpAgentCall && data.followUpAgentCall) {
      filterCount += 1
      this.setState({ followUpAgentCall: true })
    }
    if (!this.state.followUpTownTour && data.followUpTownTour) {
      filterCount += 1
      this.setState({ followUpTownTour: true })
    }
    if (this.state.appointmentStartDate === '' && data.appointmentStartDate && data.appointmentStartDate !== '') {
      filterCount += 1
      this.setState({ appointmentDate: true, appointmentStartDate: data.appointmentStartDate })
    }
    // selected appointment type is not exists and count up for show and save state data
    if (selectedAppointmentType && selectedAppointmentType.length === 0 && data.selectedAppointmentType && data.selectedAppointmentType.length > 0) {
      this.setState({ appointmentType: true, selectedAppointmentType: data.selectedAppointmentType })
      filterCount += 1
    }
    // filter client or selected client in filter data with data
    if (filteredClients && filteredClients.length === 0 && data.filteredClients && data.filteredClients.length > 0) {
      this.setState({ clientName: true, filteredClients: data.filteredClients })
      filterCount += 1
    }
    // filter client or selected client in filter data with data
    if (filteredStrategist && filteredStrategist.length === 0 && data.filteredStrategist && data.filteredStrategist.length > 0) {
      const searchedStrategists = this.getAllStrategists()
      this.setState({ strategistName: true, searchStrategistName: '', searchedStrategists })
      filterCount += 1
    }
    if (filteredStatuses && filteredStatuses.length === 0 && data.filteredStatuses && data.filteredStatuses.length > 0) {
      this.setState({ agentStatus: true, filteredStatuses: data.filteredStatuses })
      filterCount += 1
    }
    delete data.filterCount
    this.setState({ 
      ...data,
      filterOptions: data,
      filterCount: filterCount >= 0 ? filterCount : 0,
      appointmentDate: this.state.appointmentStartDate === '' && data.appointmentStartDate && data.appointmentStartDate !== '' ? true : false
    }, () => localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...data, filterCount })))
    this.forceUpdate()
  }
  // handle price range for filter
  handlePriceRangeChange = (event) => {
    const { name, value } = event.target
    let { filterCount, filterOptions } = this.state
    this.setState({ [name]: value })
    // if minprice change it should remove max price
    if (name === 'minPrice') {
      this.setState({ maxPrice: '' })
    }
    // when maxPrice change
    if (name === 'maxPrice') {
      // check alerady present if not count up and new value present
      if (value !== '') {
        filterOptions = { ...filterOptions, minPrice: this.state.minPrice, maxPrice: value }
        if (this.state.maxPrice === '') {
          filterCount += 1
        }
        this.setState({ minPrice: this.state.minPrice, maxPrice: value, filterOptions })
        this.props.setFilterOptions(filterOptions)
        localStorage.setItem('yourAgentFilterOptions', JSON.stringify({ ...filterOptions, filterCount }))
      }
      // check alerady present if yes count down and new value not present
      if (this.state.maxPrice !== '' && value === '') {
        filterCount -= 1
      }
    }
    this.setState({ filterCount })
    this.forceUpdate()
  }
  // get all strategist
  getAllStrategists = () => {
    const { filterParams } = this.props;
    const auth = cookies.get("i_user");
    const user = cookies.get("user");
    let allStrategists = filterParams && filterParams.data && filterParams.data.strategists;
    if (
      auth &&
      auth.role !== "superadmin" &&
      auth.role !== "admin"
    ) {
      allStrategists = filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    } else if (!auth && user && user.role == "strategist") {
      allStrategists = filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    }
    return allStrategists;
  }

  render() {
    const user = cookies.get('user')
    const { searchedAppointmentType, selectedAppointmentType, filterCount, clientList, agentStatuses, appointmentDate, appointmentStartDate, filteredClients, filteredStatuses, filteredStrategist, searchedStrategists } = this.state
    const { filterParams } = this.props
    const minPriceOption = map(get(filterParams, 'data.price_brackets', []), (val, index) => (
      <option key={index} value={val.price_min}> {val.price_min.toLocaleString()} </option>
    ));
    return (
      <Popover
        content={
          <div className="filter">
            <div className="filter__header">
              <button className="btn btn__btn btn-secondry btn__xs" onClick={this.handleClearFilter}>Clear</button>
              <div className="filter__title">Filters</div>
              <button className="btn btn__btn btn-dark btn__xs" onClick={this.handleCloseFilter}>Close</button>
            </div>
            <div className="filter__body">
              <ul className="filter__list">
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Client Name
                    <input type="checkbox" checked={this.state.clientName} name="clientName" className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {this.state.clientName && <ul className="filter__list__inner">
                  <div className="search__form mt__10 mb__10">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      onChange={this.handleChange}
                      name="searchClientName"
                      placeholder="Search for client name..."
                      value={this.state.searchClientName}
                    />
                  </div>
                  {clientList &&
                      map(clientList, (client, clientIdx) => {
                        const findSelected = filter(filteredClients, o => o === client.id)
                        return (
                          <li key={`client__${clientIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {client.client_full_name}
                              <input
                                type="checkbox"
                                checked={!isEmpty(findSelected) ? true : false} className="custom-checkbox--input"
                                onClick={() => this.hanldeSelectOption(client.id, 'filteredClients')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                </ul>}
                {user && user.role !== "strategist" && <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Strategist
                    <input
                      type="checkbox"
                      name="strategistName"
                      checked={this.state.strategistName}
                      className="custom-checkbox--input"
                      onChange={this.handleOptionChange}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>}
                {this.state.strategistName && <ul className="filter__list__inner">
                  <div className="search__form mt__10 mb__10">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      onChange={this.handleChange}
                      name="searchStrategistName"
                      placeholder="Search for strategist name..."
                      value={this.state.searchStrategistName}
                    />
                  </div>
                  {searchedStrategists &&
                      map(searchedStrategists, (strategist, strategistIdx) => {
                        const findSelected = filter(filteredStrategist, o => o === strategist.id)
                        return (
                          <li key={`strategists__${strategistIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {strategist.strategist_name}
                              <input
                                type="checkbox"
                                checked={!isEmpty(findSelected) ? true : false} className="custom-checkbox--input"
                                onClick={() => this.hanldeSelectOption(strategist.id, 'filteredStrategist')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                </ul>}
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Price Range
                    <input type="checkbox" name="priceRange" checked={this.state.priceRange} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {this.state.priceRange &&
                <ul className="filter__list__inner">
                  <div className="search__form mt__10 mb__10">
                    <select name="minPrice" value={this.state.minPrice} className="form-control search__form__input" onChange={this.handlePriceRangeChange}>
                      <option value="">Select Min Price</option>
                      {minPriceOption}
                    </select>
                  </div>
                  <div className="search__form mt__10 mb__10">
                  <select name="maxPrice" value={this.state.maxPrice} className="form-control search__form__input" onChange={this.handlePriceRangeChange}>
                      <option value="">Select Max Price</option>
                      {map(get(filterParams, 'data.price_brackets', []), (val, index) => (
                            this.state.minPrice < val.price_max && <option key={index} value={val.price_max}>{val.price_max.toLocaleString()} </option>
                       ))}
                    </select>
                  </div>
                </ul>}
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Agent Status
                    <input type="checkbox" name="agentStatus" checked={this.state.agentStatus} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                  {this.state.agentStatus && <ul className="filter__list__inner">
                  <div className="search__form mt__10 mb__10">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      onChange={this.handleChange}
                      name="searchAgentStatus"
                      placeholder="Search for client name..."
                      value={this.state.searchAgentStatus}
                    />
                  </div>
                  {agentStatuses &&
                      map(agentStatuses, (agentStatus, clientIdx) => {
                        const findSelected = filter(filteredStatuses, o => o === agentStatus )
                        return (
                          <li key={`strategists__${clientIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {agentStatus}
                              <input
                                type="checkbox"
                                checked={!isEmpty(findSelected) ? true : false} className="custom-checkbox--input"
                                onClick={() => this.hanldeSelectOption(agentStatus, 'filteredStatuses')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                </ul>}
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Appointment Type
                    <input type="checkbox"
                        name="appointmentType"
                        checked={this.state.appointmentType}
                        className="custom-checkbox--input"
                        onChange={this.handleOptionChange}
                      />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                 {this.state.appointmentType &&
                  <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                        <input
                          type="text"
                          className="form-control search__form__input"
                          onChange={this.handleChange}
                          name="searchAppointmentType"
                          placeholder="Search for appointment type..."
                          value={this.state.searchAppointmentType}
                        />
                      </div>
                      {searchedAppointmentType &&
                        map(searchedAppointmentType, (appointment, appointmentIdx) => {
                          return (
                            <li key={`appointment__${appointmentIdx}`} >
                              <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                                {appointment.name}
                                <input
                                  type="checkbox"
                                  checked={selectedAppointmentType.includes(appointment.value)}
                                  onClick={() => this.hanldeSelectOption(appointment.value, 'filterAppointment')}
                                  className="custom-checkbox--input"
                                  readOnly
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                  </ul>}
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Appointment Date
                    <input type="checkbox" name="appointmentDate" checked={appointmentDate} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {appointmentDate && <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                       <DatePicker
                          className="datepicker__follow-up-date form-control search__form__input"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          placeholder="Select Appointment Date"
                          format="MM/DD/YYYY"
                          onChange={this.handleDateChange}
                          value={appointmentStartDate ? moment(appointmentStartDate): ''}
                          placeholder="Select Date"
                        />
                      </div>
                    </ul>}
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Never Scheduled with Agent
                    <input type="checkbox" name="neverScheduled" checked={this.state.neverScheduled} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    No initial or follow up agent call
                    <input type="checkbox" name="followUpAgentCall" checked={this.state.followUpAgentCall} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    No initial or follow up agent town tour
                    <input type="checkbox" name="followUpTownTour" checked={this.state.followUpTownTour} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    Agent: Not Touched in 4 Weeks
                    <input type="checkbox" name="notTouch4Week" checked={this.state.notTouch4Week} className="custom-checkbox--input" onChange={this.handleOptionChange} />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        }
        placement="bottom"
        className="filter"
        overlayClassName="filter__overlay"
        // title="Title"
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.filterVisible}
      >
        <button className="btn btn__btn btn-dark btn-filter"><FilterIcon className="filter-icon" /> Filters{filterCount > 0 ? `(${filterCount})` : ''}</button>
      </Popover>
    );
  }
}
