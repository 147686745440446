import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
/*
     ///////////////////////////////
   /// Your agent apis ///
///////////////////////////////
 */
// fetch your agnet  list
export const fetchYourAgent = (data) => {
  return fetch(`${HOSTNAME_V2}/get-my-agent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
