import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import styles from './styles.scss'
import ReportNavigation from '../reportNavigation'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format'
import PlaceSearchBox from '../../../../components/forms/PlaceSearchBox'
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
import { confirmAlert } from 'react-confirm-alert'
import Modal from 'react-modal'
import _ from 'lodash'
import Header from '../Header'
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '500px',
    padding: '25px 30px',
    maxHeight: "95%"
  }
}

class GooglePlace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDateLoading: false,
      town_name: '',
      lat: 0,
      lng: 0,
      google_place_id: '',
      placeType: '',
      name: '',
      type: '',
      vicinity: '',
      rating: '',
      google_primary_id:'',
      err: {},
      modalIsOpen: false,
      addType: '',
      id: 0
    }
  }

  componentWillMount() {
    this.getTownName()
  }

  componentWillReceiveProps(np) {
    if(np.addGooglePhase !== this.props.addGooglePhase){
      this.getTownName()
      setTimeout(()=>{
        this.getGooglePlace(null, this.state.placeType)
      }, 300)
    }
    if(np.editGooglePhase !== this.props.editGooglePhase){
      this.getTownName()
      setTimeout(()=>{
        this.getGooglePlace(null, this.state.placeType)
      }, 300)
    }
  }

  onChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangePlace = async(suggestion, place) => {
    const { change } = this.props
    change('vicinity', place.formatted_address)
      this.setState({ vicinity: place.formatted_address, lat: place.geometry.location.lat(), long: place.geometry.location.lng(), google_place_id: place.place_id, google_primary_id: place.id })
  }

  afterOpenModal(){ }

  closeModal=()=>{ 
    this.closePopUp()
  }

  closeCrossBtn(e){
    this.closePopUp()
  }

  closePopUp(){
    const { change } = this.props
    this.setState({ 
        modalIsOpen: false, 
        town_id: 0, 
        vicinity: '', 
        name: '', 
        rating: '', 
        addType: ''  })
    change('vicinity', '')
  }

  placeBtn(e){
    const { change } = this.props
    this.setState({ modalIsOpen: true, id: 0, addType: "add" })
    change('vicinity', '')
  }

  selectPlaceType(e){
    this.setState({ type: e.target.value })
  }

  async getTownName() {
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)

    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name,
                      lat: townName.data.town[0].lat,
                      lng: townName.data.town[0].lng,
                      google_place_id: townName.data.town[0].google_place_id
                    })
    }
  }
  
  async getGooglePlace(event, value) {
    let googlePlaceType = value ? value : event.target.value
    this.setState({ placeType: value ? value : event.target.value, isDateLoading: true })
    const googlePlaces = () => axios.get(
      `${HOSTNAME}/google_places?lat=${this.state.lat}&lng=${this.state.lng}&type=${value ? value : event.target.value}&from=jungler`
    ).catch(logoutInvalidRequest)
    const raw = await googlePlaces()
    let data = _.get(raw, 'data.data', [])
    const placeArr = await this.getGooglePlacesData(googlePlaceType)
    let concatAllPlaces = _.concat(placeArr, data)
    this.setState({ places: concatAllPlaces, isDateLoading: false })
  }

  getGooglePlacesData= async (placeType) =>{
    const {match: { params: { townId }} } = this.props
    const fetchGooglePlace = () => axios.get(
      `${HOSTNAME}/getGooglePlaces?town_id=${townId}&placeType=${placeType}`
    ).catch(logoutInvalidRequest)

    const googlePlace = await fetchGooglePlace()
    if(googlePlace && googlePlace.data && googlePlace.data.data){
      // this.setState({ places: googlePlace.data.data })
      return googlePlace.data.data 
    }else{
      return []
    }
  }

  addPlaceBtn(e){
    const { vicinity, name, type, lat, long, google_place_id, google_primary_id, rating, addType } = this.state
    const {match: { params: { townId }}, addGooglePlace, change, editGooglePlace } = this.props
    let err = {}
    if( name === '' || name.trim() === ''){
      err.name = "Enter Name"
    }
    if( type === '' || type.trim() === ''){
      err.type = "Enter Type"
    }
    if( rating === '' || rating.trim() === ''){
      err.rating = "Enter Rating"
    }
    if( vicinity == '' || vicinity.trim() === ''){
      err.vicinity = "Enter Correct Vicinity"
    }
    if( lat == '' || lat == undefined  || long == '' || long == undefined){
      err.vicinity = "Enter Correct Vicinity"
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {
        town_id: parseInt(townId),
        vicinity: vicinity,
        name: name,
        type: type,
        lat: lat,
        lng: long,
        rating: rating,
        google_place_id: google_place_id,
        google_primary_id: google_primary_id,
        is_manual: 1
      }
      if(addType == 'add'){
        addGooglePlace(data)
      }else if( addType == 'edit'){
        data.id = this.state.id
        editGooglePlace(data)
      }
      this.setState({ modalIsOpen: false, town_id: 0, vicinity: '', name: '', type: '', lat: '', long: '', rating: '', google_place_id: '', google_primary_id: 0  })
      change('vicinity', '')
    }
  }

  async deletePlaceConfirmation(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
            <h4 className="popupheader__heading">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          <p className="allpoppadding__para poppinsfontsedit">You want to delete this place?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.deletePlace(data)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  async deletePlace(data) {
    const {match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    // API logic
    const jwtToken = cookies.get('Authorization');
    const url = `${ HOSTNAME }/deletePlacesForJungler`
    if (data.is_manual) {
      axios({
        method: 'POST',
        url: url,
        'headers': { 'Authorization': jwtToken },
        data: {
          id: data.id,
          delete: true
        }
      }).then(res => {
        this.getGooglePlace(null, this.state.placeType)
        // this.setState({ isDateLoading: false })
      }).catch(logoutInvalidRequest)  
    } else {
    const obj = {}
    obj.name = data.name
    obj.vicinity = data.vicinity
    obj.google_place_id = data.place_id
    obj.rating = data.rating
      obj.type = data.types ? data.types.join(', ') : data.type
    obj.user_rating_total = data.user_ratings_total
    obj.google_primary_id = data.id
  
    obj.lat = data.geometry.location.lat
    obj.lng = data.geometry.location.lng
     
    obj.is_manual = 3 // deleted place from the google result
    obj.town_id = townId
      
    axios({
      method: 'POST',
      url: url,
      'headers': { 'Authorization': jwtToken },
      data: obj
    }).then(res => {
      this.getGooglePlace(null, this.state.placeType)
      // this.setState({ isDateLoading: false })
    }).catch(logoutInvalidRequest)  
    }
  }

  editGooglePlace(data, e){
    const { change } = this.props
    let type = data.types ? data.types :  data.type
    this.setState({
      modalIsOpen: true,
      name: data.name,
      type: type,
      rating: data.rating,
      lat: data.lat,
      long: data.lng,
      vicinity: data.vicinity,
      google_place_id: data.place_id,
      google_primary_id: data.id,
      addType: "edit",
      id: data.id
    })
    change('vicinity', data.vicinity)
  }

  render() {
    const { places, placeType } = this.state
    const inputProps = {}
    return (
      <div className="town-report">
       <Header title={`Google Places for ${this.state.town_name}`} />
        {
          placeType !== '' ? (
            <button onClick={this.placeBtn.bind(this)} className="btn__add--places" type="submit">Add Place for {placeType}</button>
          ) : ''
        }
        <ToastContainer autoClose={3000}/>
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }
          <div className="latest__town--report latest__town--reportmarg">
              <div className="container">
                <div className="row">

                    <div className="view__breakdown view__breakdown--edit view__breakdown--marg">
                        <label>Select Place Type</label>
                          <select onChange={this.getGooglePlace.bind(this)}>
                        <option value=''>Select</option>
                        <option value="Around Town">Around town</option>
                        <option value="Food">Food</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Parks/Beaches">Parks/Beaches</option>
                        <option value="Country Clubs">Country Clubs</option>
                      </select>
                        </div>

                </div>

                <div className="row">
                  {places && places.length > 0 ? (
                      <div className="latest__town--report">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                  <table className="table table-striped school__table--tabs school__table mobtablegoogle">
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Types</th>
                                        <th>Rating</th>
                                        <th>Vicinity</th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        places.map((data, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{data.name}</td>
                                              <td>{ data.types ? data.types.join(', ') : data.type}</td>
                                              <td>{data.rating}</td>
                                              <td>{data.vicinity}</td>
                                              {/*<td>{data.user_ratings_total}</td>*/}
                                              <td style={{textAlign: "center"}}>
                                                {data.is_manual ? (
                                                  <button className="edit__btn" onClick={this.editGooglePlace.bind(this, data)}><img src="../../img/edit-icon.svg" /></button>

                                                ) : (
                                                  <img style={{width: '26px', height: '26px'}}
                                                    src="https://suburbanjungler-new.s3.amazonaws.com/googleIcon.svg" />
                                                )}
                                                
                                              </td>
                                              <td>
                                                {data.is_delete ? (
                                                    <span>Deleted</span>
                                                  ) : (
                                                  <img style={{width: '26px', height: '26px', cursor: 'pointer'}}
                                                    onClick={this.deletePlaceConfirmation.bind(this, data)}
                                                    src="./img/delete_icon.svg" />
                                                )}
                                               {/* <button type="submit" className="navigation__links" style={{marginRight: "0px", minWidth: "91px", padding: "6px 6px"}} onClick={this.deletePlace.bind(this, data)}>Delete</button>*/}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (<p className="no__data--show">{placeType !== '' ? 'No Places for ' + placeType : ''}</p>)}
                </div>
            </div>
          </div>

          <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom">
          <div className="form-group">
            <h1 className="headModal">Add Google Place</h1>
            <button onClick={this.closeCrossBtn.bind(this)} type="button" className="btn close-btn closeModal" data-dismiss="modal">
            <img data-dismiss="modal" src="./img/login_close.svg"/></button>
          </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Name</label>
            <input 
              name="name"
              className="textinputs__global" 
              rows="2" 
              value={this.state.name}
              onChange={this.onChange.bind(this)}/>
          </div>
          { this.state.err.name ?
          <span className="error_msg town__err">
          {this.state.err.name}
          </span> : '' }
          

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Select Place Type</label>
            <select className="selectinputs__global" value={this.state.type} onChange={this.selectPlaceType.bind(this)}>
              <option value=''>Select</option>
              <option value="Around Town">Around town</option>
              <option value="Food">Food</option>
              <option value="Fitness">Fitness</option>
              {/*<option value="Schools">Schools</option>
              <option value="Houses of Worship">Houses of Worship</option>*/}
              <option value="Parks/Beaches">Parks/Beaches</option>
              <option value="Country Clubs">Country Clubs</option>
            </select>
          </div>
          { this.state.err.type ?
          <span className="error_msg town__err">
          {this.state.err.type}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Rating</label>
            <input 
              name="rating"
              className="textinputs__global" 
              rows="2" 
              value={this.state.rating}
              onChange={this.onChange.bind(this)}/>
          </div>
          { this.state.err.rating ?
          <span className="error_msg town__err">
          {this.state.err.rating}
          </span> : '' }


          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Vicinity</label>
            <PlaceSearchBox
              type="text"
              name="vicinity"
              component={PlaceSearchBox}
              className="search-placebox pac-container"
              {...inputProps}
              change={this.state.vicinity}
              id="placeSearchBoxField"
              onSelectPlace={this.handleChangePlace.bind(this)}
              style={{"width":"90%"}}
            />  
          </div>
          { this.state.err.vicinity ?
          <span className="error_msg vicinity__err">
          {this.state.err.vicinity}
          </span> : '' }

          <div className="modalbtns modalbtns__new">
            {
              (this.state.addType == 'add')?
              <button
                className="submitBtnModal"
                style={{ margin: '45px 0 7px 0' }}
                type="button"Save
                onClick={this.addPlaceBtn.bind(this)}>
                Save 
              </button>
              :
              <button
                className="submitBtnModal edit__margin" style={{ width: '100%', marginLeft: '0', marginRight: '0', marginTop: '15px' }}
                type="button"
                onClick={this.addPlaceBtn.bind(this)}>
                Edit 
              </button>
            }
            
          </div>
          </div>
        </Modal>

      </div>
    )
  }
}

export default reduxForm({
  form: 'GooglePlace', // a unique identifier for this form            
  destroyOnUnmount: true
})(GooglePlace)
