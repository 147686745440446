import React, { PureComponent, Fragment} from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import UserContext from "../../../../App/Context"
import './styles.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies() // Import css
let _user = ''

export default class ActivityHistoryComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      activityData: [],
      currentUserName: '',
      viewMore: false
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    if (_user && _user.id) {
      this.setState({ agentId: _user.id, currentUserName: _user.first_name + ' ' + _user.last_name })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.activityData !== _.get(props.clientData, 'users', [])) {
      return { activityData: _.get(props.clientData, 'users', []) }
    }
    return null;
  }

  viewMoreButton(){
    this.setState({ viewMore: !this.state.viewMore })
  }

  render() {
    const { activityData, agentId } = this.state
    let data = activityData && activityData.all_activity_status
    let activityHistoryData = _.orderBy(data, ['created'],['desc']);
    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <h5 className="card-title mb__0">Activity History</h5>
          </div>

      <div className="card-body card-body-sm">
        <ul className="activity__history__list">
          {
            (_.size(activityHistoryData) > 0) ?
            activityHistoryData.map((h, indx) => {
              const isFormattedActivityHistory = !!(h.activity && h.activity.length && h.activity.slice(-1) === '.')

              if (h['_type'] === 'appointment' && isFormattedActivityHistory) {
                const timezone = h.activity_by_timezone ? h.activity_by_timezone : moment.tz.guess()
                return (
                  <li>
                    <span>
                      <div className="activity-history-text" key={indx} dangerouslySetInnerHTML={{ __html: h.activity }}/>
                      <div className="activity-history-time">
                        {moment(h.created).tz(timezone).format("MMMM DD, YYYY hh:mm a z")}
                      </div>
                    </span>
                  </li>
                )
              }

                return (
                   this.state.viewMore ? 
                   <Fragment  key={indx}>
                    {
                      (h.activity_type !== "" && h.activity_type !== "Null" && h.activity_type !== "NULL" && h.activity_type !== "null") ?
                        (h._type === 'appointment') ?
                          <Fragment>
                            {
                              (h.old_event_date) ?
                                <li>
                                  {
                                    (h.scheduled_by && agentId === h.scheduled_by) ?
                                      <div className="activity-history-text"><strong>You</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                      <div  className="activity-history-time">
                                        {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                      </div>
                                      </div>
                                      : (h.scheduled_by && agentId != h.scheduled_by) ?
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                        </div>
                                        :
                                        (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name) ?
                                          <div className="activity-history-text"><strong>You</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                          <div  className="activity-history-time">
                                            {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                          </div>
                                          </div>
                                          :
                                          <div className="activity-history-text"><strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                          <div  className="activity-history-time">
                                            {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                          </div>
                                          </div>
                                        )
                                  }
                                </li>
                                :
                                <li key={indx}>
                                  {
                                    (!h.activity_date) ?
                                      <span>
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span>
                                        </div>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </span>
                                      :
                                      <span>
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span>
                                        <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                        </div>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </span>
                                  }
                                </li>
                            }
                          </Fragment>
                          :
                          <Fragment key={indx}>
                            {
                              (h.activity_type === 'Initial Welcome Sent' || h.activity_type === 'Inspection Scheduled') ?
                                <Fragment>
                                  {
                                    (h.activity_type === 'Initial Welcome Sent') ?
                                      <Fragment>
                                        {
                                          (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                           <li>
                                              <div className="activity-history-text" key={indx}>
                                                You selected the status icon <strong>Initial Welcome Sent</strong> for <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                              </div>
                                              <div className="activity-history-time">
                                                {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                              </div>
                                            </li>
                                            :
                                            <li>
                                                <div className="activity-history-text">
                                                  You selected the status icon <strong>Initial Welcome Sent</strong> for <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                                </div>
                                                <div className="activity-history-time">
                                                  {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                                </div>
                                            </li>
                                        }
                                      </Fragment>
                                      :
                                      <Fragment>
                                        {
                                          (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                            <li>
                                              <div className="activity-history-text" key={indx}>
                                                You marked <strong>You have an accepted offer and have scheduled an inspection</strong> as Yes for client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                              </div>
                                              <div className="activity-history-time">
                                                {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                              </div>
                                            </li>
                                            :
                                            <li>
                                            <div className="activity-history-text" key={indx}>
                                              <strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> marked <strong>You have an accepted offer and have scheduled an inspection</strong> as Yes for client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                            </div>
                                            <div className="activity-history-time">
                                              {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                            </div>
                                            </li>
                                        }
                                      </Fragment>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {
                                    (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                      <li>
                                        <div className="activity-history-text">
                                          You updated the status of client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{h.activity_type}</strong>
                                        </div>
                                        <div className="activity-history-time">
                                          {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </li>
                                      :
                                      <li>
                                        <div className="activity-history-text">
                                          <strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> updated the status of client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{h.activity_type}</strong>
                                        </div>
                                        <div className="activity-history-time">
                                          {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </li>
                                  }
                                </Fragment>
                            }
                          </Fragment>
                        : ""
                    }
                  </Fragment>
                  :
                  (indx < 10) && 
                  <Fragment  key={indx}>
                    {
                      (h.activity_type !== "" && h.activity_type !== "Null" && h.activity_type !== "NULL" && h.activity_type !== "null") ?
                        (h._type === 'appointment') ?
                          <Fragment>
                            {
                              (h.old_event_date) ?
                                <li>
                                  {
                                    (h.scheduled_by && agentId === h.scheduled_by) ?
                                      <div className="activity-history-text"><strong>You</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                      <div  className="activity-history-time">
                                        {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                      </div>
                                      </div>
                                      : (h.scheduled_by && agentId != h.scheduled_by) ?
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span> to <strong>{moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                        </div>
                                        :
                                        (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name) ?
                                          <div className="activity-history-text"><strong>You</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                          <div  className="activity-history-time">
                                            {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                          </div>
                                          </div>
                                          :
                                          <div className="activity-history-text"><strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> rescheduled your <strong>{h.activity_type}</strong> appointment with client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong> from <strong>{moment(h.old_event_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                          <div  className="activity-history-time">
                                            {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                          </div>
                                          </div>
                                        )
                                  }
                                </li>
                                :
                                <li key={indx}>
                                  {
                                    (!h.activity_date) ?
                                      <span>
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span>
                                        </div>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </span>
                                      :
                                      <span>
                                        <div className="activity-history-text"><span dangerouslySetInnerHTML={{ __html: h.activity }}></span>
                                        <strong>{moment(h.activity_date).tz(h.activity_by_timezone).format("MMMM DD, YYYY hh:mm a z")}</strong>
                                        </div>
                                        <div  className="activity-history-time">
                                          {moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </span>
                                  }
                                </li>
                            }
                          </Fragment>
                          :
                          <Fragment key={indx}>
                            {
                              (h.activity_type === 'Initial Welcome Sent' || h.activity_type === 'Inspection Scheduled') ?
                                <Fragment>
                                  {
                                    (h.activity_type === 'Initial Welcome Sent') ?
                                      <Fragment>
                                        {
                                          (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                           <li>
                                              <div className="activity-history-text" key={indx}>
                                                You selected the status icon <strong>Initial Welcome Sent</strong> for <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                              </div>
                                              <div className="activity-history-time">
                                                {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                              </div>
                                            </li>
                                            :
                                            <li>
                                                <div className="activity-history-text">
                                                  You selected the status icon <strong>Initial Welcome Sent</strong> for <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                                </div>
                                                <div className="activity-history-time">
                                                  {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                                </div>
                                            </li>
                                        }
                                      </Fragment>
                                      :
                                      <Fragment>
                                        {
                                          (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                            <li>
                                              <div className="activity-history-text" key={indx}>
                                                You marked <strong>You have an accepted offer and have scheduled an inspection</strong> as Yes for client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                              </div>
                                              <div className="activity-history-time">
                                                {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                              </div>
                                            </li>
                                            :
                                            <li>
                                            <div className="activity-history-text" key={indx}>
                                              <strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> marked <strong>You have an accepted offer and have scheduled an inspection</strong> as Yes for client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong>
                                            </div>
                                            <div className="activity-history-time">
                                              {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                            </div>
                                            </li>
                                        }
                                      </Fragment>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {
                                    (this.state.currentUserName === (h.activity_by_first_name + ' ' + h.activity_by_last_name)) ?
                                      <li>
                                        <div className="activity-history-text">
                                          You updated the status of client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{h.activity_type}</strong>
                                        </div>
                                        <div className="activity-history-time">
                                          {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </li>
                                      :
                                      <li>
                                        <div className="activity-history-text">
                                          <strong>{h.activity_by_first_name + ' ' + h.activity_by_last_name}</strong> updated the status of client <strong>{activityData.first_name + ' ' + activityData.last_name}</strong> to <strong>{h.activity_type}</strong>
                                        </div>
                                        <div className="activity-history-time">
                                          {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}
                                        </div>
                                      </li>
                                  }
                                </Fragment>
                            }
                          </Fragment>
                        : ""
                    }
                  </Fragment>
                )
              })
              :
              <div className="card-body card-body-sm blank__state__text">No Activity Information</div>
          }
          </ul>

          {
            (_.size(activityHistoryData) > 10) &&
            <div className="chats__sectiion">
              <div className="chats__sectiion__inner">
                <ul className="chats__sectiion__chats__list">
                  <button onClick={() => this.viewMoreButton()} className="btn btn__view_more_chat">{this.state.viewMore ? "View Less" : "View More"}</button>
                </ul>
              </div>
            </div>
          }
        </div>

        </div>
      </div>
    )
  }
}
