import { List } from 'immutable'

import { fetch } from '../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getQuestionnairesCompletedClientList = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/questionnairesAnalytics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* this is for get clients per agent report api */
export const getClientsPerAgent = (data) => {
  
  let url = `${SUBURBANJUNGLE_API_HOST}/dashboard-reports/client?`

  const queryStringParams = {
    to: data.to,
    from: data.from,
    type: data.type
  }

  if (data.clientStatus) queryStringParams.clientStatus = data.clientStatus

  url += new URLSearchParams(queryStringParams).toString()
  
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }      
  }

  return fetch(url, options)
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload.data
  }).catch((error) => {
    throw error
  })
}

export const getAppointmentScheduled = (data) => {
  const url = data.page ? `${SUBURBANJUNGLE_API_HOST}/strategists/appointments?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}` :
    `${SUBURBANJUNGLE_API_HOST}/strategists/appointments?startDate=${data.startDate}&endDate=${data.endDate}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getAgentAssignToTown = (data) => {
  return fetch(`${HOSTNAME_V2}/report/agents_assigned_to_towns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getAgentRatingReport = (data) => {
  return fetch(`${HOSTNAME_V2}/statistics/agent_rating_initial_town_tour`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for login record api */
export const getLoginRecord = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/getloginrecords`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for strategist rating report */
export const getStrategistRating = (data) => {
  return fetch(`${HOSTNAME_V2}/strategistRatingReport`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for strategist rating report */
export const getStrategistStatistics = (data) => {
  return fetch(`${HOSTNAME_V2}/statistics/strategist-agent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for strategist time sheet report */
export const getStrategistTimesheet = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/fetch_client_strategist_timesheet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for total client per month report */
export const getTotalClientPerMonth = (data) => {
  return fetch(`${HOSTNAME_V2}/report/get_total_client_receive_per_month`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for business development report */
export const getBusinessDevelopmentReport = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/business_development`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for no initial agent call report */
export const noInitialAgentCallData = (data) => {
  return fetch(`${HOSTNAME_V2}/statistics/no_initial_call`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for no initial agent visit report */
export const getNoInitialAgentTourData = (data) => {
  return fetch(`${HOSTNAME_V2}/statistics/no_initial_town_tour`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for no not touched by agent in 4 weeks report */
export const getNotTouchedIn4Weeks = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/notTouchIn4WeekAnalytics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for no account report edit deal data */
export const accountReportDealData = (data) => {
  return fetch(`${HOSTNAME_V2}/account_report_edit_deal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for ai clients report data */
export const getAIClientsReport = (data) => {
  return fetch(`${HOSTNAME_V2}/report/ai-clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

/* get api for ad tracking code report data */
export const getAdTrackingCodeReport = (data) => {
  return fetch(`${HOSTNAME_V2}/report/ad_tracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}