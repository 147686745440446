import { connect } from 'react-redux'

import LoginRecordsComponent from './component'
import { getLoginRecord, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const LoginRecordsContainer = connect(
  // Map state to props
  (state) => ({
    loginRecordPhase: state.reportV2.loginRecordPhase,
    loginRecordData: state.reportV2.loginRecordData
  }),
  // Map actions to dispatch and props
  {
    getLoginRecord,
    clearReportV2Phase
  }
)(LoginRecordsComponent)

export default LoginRecordsContainer
