import { connect } from 'react-redux'

import TotalClientsReceivedPerMonthComponent from './component'
import { getFilterParams, clearDashboardPhase } from '../../../../../../store/strategistV2/dashboard/actions'
import { getTotalClientPerMonth, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const TotalClientsReceivedPerMonthContainer = connect(
  // Map state to props
  (state) => ({
    totalPerMonthPhase: state.reportV2.totalPerMonthPhase,
    totalPerMonthData: state.reportV2.totalPerMonthData,
    filterParams: state.strategistV2.dashboard.filterParams,
    filterParamsPhase: state.strategistV2.dashboard.filterParamsPhase
  }),
  // Map actions to dispatch and props
  {
    getTotalClientPerMonth,
    getFilterParams,
    clearFilterPhase: clearDashboardPhase,
    clearReportV2Phase
  }
)(TotalClientsReceivedPerMonthComponent)

export default TotalClientsReceivedPerMonthContainer
