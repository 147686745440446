import { map, split, upperCase } from 'lodash'
import Cookies from 'universal-cookie'
import moment from 'moment'

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const createStringAvatar = (name) => {
  const names = split(name, ' ')
  const letters = map(names, (firstName) => upperCase(firstName.charAt(0)))
  return letters.join('').substring(0, 2)
}

export const convertTimeString = (time) => {
  if (time.indexOf(':') !== -1) {
    return moment(time, 'HH:mm').format('hh:mm a')
  }
  return time
}

export const getHostname = () => {
  const myURL = new URL(process.env.API_HOSTNAME).hostname
  const split = myURL.split(".")

  if (split.length > 1) {
    const sliced = split.slice(-2)
    return '.' + sliced.join('.')
  }

  return myURL
}

export const logoutInvalidRequest = (error) => {
  const cookies = new Cookies()
  localStorage.clear()
  for (const cookie in cookies.getAll()) {
    cookies.remove(cookie)
  }
  window.location.pathname = '/login'
  throw new Error(error)
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return false;

  return str.charAt(0).toUpperCase() + str.slice(1);
}
