import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import BigCalendar from 'react-big-calendar'
import Calendar from 'react-calendar'
import moment from 'moment'
import events from './events'
import dates from './dates'
import calendar from './calendar.scss'
import styles from './styles.scss'
const required = value => value ? undefined : 'Required'




BigCalendar.momentLocalizer(moment); // or globalizeLocalizer



class CalendarAppointmentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSchedule: false,
    }


  }

  openScheduleBar(e) {
    this.setState({ openSchedule : true})
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  handleChange(){
  }

  submitSchedulingData(){
  }


  render() {


    return (
      <div>
        <div className="calendar__page--container">
          <div className="calendar__page--header">
            <div className="calendar__page--header-title">
              Jungler Calendar
            </div>
 
            <button 
              type="button" className="appointment-btn"
              onClick={this.openScheduleBar.bind(this)}>
               Appointment
              </button>

            <div className="calendar__page--close">
              <Link to={`/`}><img src="img/close.svg"/></Link>
            </div>
          </div>

          <div className="calendar__page--wrapper">
            <div className="calendar__page--small-calendar">
              <Calendar />
            </div>

            <div className="calendar__page--big-calendar">
              <BigCalendar
                events={events}
                // views={allViews}
                step={60}
                showMultiDayTimes
                max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                defaultDate={new Date(2015, 3, 1)}
                // localizer={localizer}
              />
            </div>

          </div>

        </div>




        <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>
          <div className="schedule__sidebar--body">
  
                    <div className="schedule__sidebar--form--wrapper">

                      <div className="form-group">
                        <label className="text__label--sm">Clint Name</label>
                        <select className="form-control custom-select" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                          <option>Select the client</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="text__label--sm">Appointment Type</label>
                        <select className="form-control custom-select" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                          <option>Select the type of Appointment</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <select className="form-control custom-select date__picker" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                          <option>Select Date</option>
                        </select>
                      </div>

                      <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text__label--sm">Start</label>
                          <select className="form-control custom-select-small" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                            <option>Start Time</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text__label--sm">End</label>
                          <select className="form-control custom-select-small" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                            <option>End Time</option>
                          </select>
                        </div>
                      </div>
                      </div>

                      <div className="form-group rem__marg--btm">
                        <label className="text__label--sm">Appointment Description</label>
                        <textarea className="form-control custom-textarea" placeholder="Enter description here..."></textarea>
                      </div>


                      
    
 
                    </div>
                  </div>
                  <div className="schedule__sidebar--footer">
                      <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this)}>Schedule</button>
                    </div>
          
        </div>










      </div>
    )
  }
}

export default reduxForm({
  form: 'calendar',  // a unique identifier for this form
  destroyOnUnmount: true,
})(CalendarAppointmentForm)
