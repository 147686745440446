import * as types from './action-types'
/* 
    ////////////////////////
  /// chat action ///
////////////////////////
 */
// fetch client agents list
export const fetchClientAgent = (payload) => ({
  type: types.FETCH_CLIENT_AGENT_LIST,
  payload,
})

// fetch chat between agent and clients
export const fetchClientAgentChat = (payload) => ({
  type: types.FETCH_CLIENT_AGENT_CHAT,
  payload,
})

export const fetchClientChat = (payload) => ({
  type: types.FETCH_CLIENT_CHAT_LIST,
  payload,
})

export const sendChatMessage = (payload) => ({
  type: types.SEND_CHAT_MESSAGE,
  payload,
})
// send message between client and agent
export const sendAgentMessage = (payload) => ({
  type: types.SEND_AGENT_MESSAGE,
  payload,
})

export const getChatHistory = (payload) => ({
  type: types.GET_CHAT_HISTORY,
  payload,
})
export const clearAgentChat = () => ({
  type: types.CLEAR_AGENT_CHAT_PHASE
})
// c
export const clearClientAgents = () => ({
  type: types.CLEAR_CLIENT_AGENT,
})

// create interal notes
export const clearChatPhase = () => ({
  type: types.CLEAR_CHAT_PHASE,
})


/* 
    //////////////////////////////////////////////
  /// client Text message action ///
/////////////////////////////////////////////
 */
// get all client message by client id to show
export const fetchClientTextMessage = (payload) => ({
  type: types.FETCH_CLIENT_TEXT_MESSAGE,
  payload,
})

// send new message by user to client id with message body
export const sendClientTextMessage = (payload) => ({
  type: types.SEND_CLIENT_TEXT_MESSAGE,
  payload,
})

// delete client message by messageId
export const deleteClientTextMessage = (payload) => ({
  type: types.DELETE_CLIENT_TEXT_MESSAGE,
  payload,
})
