export const FETCH_CLIENT_AGENT_LIST = 'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_LIST'
export const FETCH_CLIENT_AGENT_LIST_SUCCESS =
  'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_LIST_SUCCESS'
export const FETCH_CLIENT_AGENT_LIST_ERROR =
  'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_LIST_ERROR'

export const FETCH_CLIENT_AGENT_CHAT = 'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_CHAT'
export const FETCH_CLIENT_AGENT_CHAT_SUCCESS =
  'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_CHAT_SUCCESS'
export const FETCH_CLIENT_AGENT_CHAT_ERROR =
  'suburban/strategistV2/chat/FETCH_CLIENT_AGENT_CHAT_ERROR'

export const FETCH_CLIENT_CHAT_LIST =
  'suburban/strategistV2/chat/FETCH_CLIENT_CHAT_FOR_CLIENT_DETAIL'
export const FETCH_CLIENT_CHAT_LIST_SUCCESS =
  'suburban/strategistV2/chat/FETCH_CLIENT_CHAT_FOR_CLIENT_DETAIL_SUCCESS'
export const FETCH_CLIENT_CHAT_LIST_ERROR =
  'suburban/strategistV2/chat/FETCH_CLIENT_CHAT_FOR_CLIENT_DETAIL_ERROR'

export const FETCH_CLIENT_TEXT_MESSAGE =
  'suburban/strategistV2/chat/FETCH_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL'
export const FETCH_CLIENT_TEXT_MESSAGE_SUCCESS =
  'suburban/strategistV2/chat/FETCH_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_SUCCESS'
export const FETCH_CLIENT_TEXT_MESSAGE_ERROR =
  'suburban/strategistV2/chat/FETCH_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_ERROR'

export const SEND_CLIENT_TEXT_MESSAGE =
  'suburban/strategistV2/chat/SEND_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL'
export const SEND_CLIENT_TEXT_MESSAGE_SUCCESS =
  'suburban/strategistV2/chat/SEND_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_SUCCESS'
export const SEND_CLIENT_TEXT_MESSAGE_ERROR =
  'suburban/strategistV2/chat/SEND_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_ERROR'

export const DELETE_CLIENT_TEXT_MESSAGE =
  'suburban/strategistV2/chat/DELETE_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL'
export const DELETE_CLIENT_TEXT_MESSAGE_SUCCESS =
  'suburban/strategistV2/chat/DELETE_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_SUCCESS'
export const DELETE_CLIENT_TEXT_MESSAGE_ERROR =
  'suburban/strategistV2/chat/DELETE_CLIENT_TEXT_MESSAGE_FOR_CLIENT_DETAIL_ERROR'

export const GET_CHAT_HISTORY =
  'suburban/strategistV2/chat/GET_CHAT_HISTORY_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID'
export const GET_CHAT_HISTORY_SUCCESS =
  'suburban/strategistV2/chat/GET_CHAT_HISTORY_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID_SUCCESS'
export const GET_CHAT_HISTORY_ERROR =
  'suburban/strategistV2/chat/GET_CHAT_HISTORY_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID_ERROR'

export const SEND_CHAT_MESSAGE =
  'suburban/strategistV2/chat/SEND_CHAT_MESSAGE_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID'
export const SEND_CHAT_MESSAGE_SUCCESS =
  'suburban/strategistV2/chat/SEND_CHAT_MESSAGE_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID_SUCCESS'
export const SEND_CHAT_MESSAGE_ERROR =
  'suburban/strategistV2/chat/SEND_CHAT_MESSAGE_FOR_CLIENT_DETAIL_BY_SENDER_RECEIVER_ID_ERROR'

export const SEND_AGENT_MESSAGE = 'suburban/strategistV2/chat/SEND_AGENT_MESSAGE_FOR_CLIENT_DETAIL'
export const SEND_AGENT_MESSAGE_SUCCESS =
  'suburban/strategistV2/chat/SEND_AGENT_MESSAGE_FOR_CLIENT_DETAIL_SUCCESS'
export const SEND_AGENT_MESSAGE_ERROR =
  'suburban/strategistV2/chat/SEND_AGENT_MESSAGE_FOR_CLIENT_DETAIL_ERROR'

export const CLEAR_AGENT_CHAT_PHASE = 'suburban/strategistV2/chat/CLEAR_AGENT_CHAT_PHASE'
export const CLEAR_CLIENT_AGENT = 'suburban/strategistV2/chat/CLEAR_CLIENT_AGENT'
export const CLEAR_CHAT_PHASE = 'suburban/strategistV2/chat/CLEAR_CHAT_PHASE'
