import React, { Fragment } from 'react';
import { Spin } from 'antd';
import { multiSelectStyles } from './variable';
import { map } from 'lodash';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const Dropdown = props => {
  return (
    <Fragment>
      <div className="form-group material-textfield">
          <div className="multiselect__checkboxes__field">
            <ReactMultiSelectCheckboxes
              styles={multiSelectStyles}
              width="100%"
              name="refferringClient"
              placeholderButtonLabel="Select"
              value={props.selectedRefferringClient}
              options={props.townListOptions}
              inputValue={props.searchTerm}
              onInputChange={props.filterRefferClientsOption}
              onChange={props.handleClientSelect}
            />
            <label className="multiselect__checkboxes__field__label">
              Select Town
            </label>
          </div>
      </div>
    </Fragment>
  );
};

export default Dropdown;
