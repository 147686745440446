import { connect } from 'react-redux'
import { get } from 'lodash'

import AgentRankingComponent from './component'

import { getRankingByClientId } from '../../../../../../store/strategistV2/clientdetail/action'

const AgentRankingContainer = connect(
  // Map state to props
  (state) => ({
    agents: get(state, 'strategistV2.clientDetail.agents', []),
    isAgentRated: get(state, 'strategistV2.clientDetail.isAgentRated', false),
    getTownAndAgentRateData: get(state, 'client.getTownAndAgentRateData', {}),
  }),
  // Map actions to dispatch and props
  {
    getRankingByClientId,
  }
)(AgentRankingComponent)

export default AgentRankingContainer
