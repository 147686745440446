import { connect } from 'react-redux'
import NoAgentRatingReport from './component'

import { fetchUser } from '../../../store/user/duck'
import { getAgentRatingData } from '../../../store/dashboard/duck'

const NoAgentRatingReportContainer = connect(
  // Map state to props
  (state) => ({
    agentRatingPhase: state.dashboard.agentRatingPhase,
    agentRatingData: state.dashboard.agentRatingData
  }),
  // Map actions to props
  {
  	fetchUser,
  	getAgentRatingData
  }
)(NoAgentRatingReport)
export default NoAgentRatingReportContainer
