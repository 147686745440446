import React from 'react'
import moment from 'moment'
import { map } from 'lodash'

export default function AppointmentList({ appointments, history }) {
  const printType = (appointmentType) => {
    switch (appointmentType) {
      case 'Follow up agent town tour':
        return 'Follow up agent visit'
      case 'Initial Town Tour':
        return 'Initial agent town tour'
      default:
        return appointmentType
    }
  }
  const goToClientDetail = (clientId) => {
    if (clientId) history.push({
      pathname: `/strategist/client-details/${clientId}`,
      state: { prevRoute: '/strategist/agent-appointments' }
    })
  }
  return (
    <tbody>
      {map(appointments, (appointment, index) => (
        <tr
          key={index}
          className="cursor__pointer"
          onClick={() => goToClientDetail(appointment.client_id)}
        >
          <td>{appointment.client_full_name}</td>
          <td>{appointment.strategists ? appointment.strategists : ''}</td>
          <td>{appointment.strategist_full_name}</td>
          <td>{printType(appointment.appointment_type)}</td>
          <td>{moment(appointment.start_date).format('MM/DD/YY hh:mm a')}</td>
        </tr>
      ))}
    </tbody>
  )
}
