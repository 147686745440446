import React, { Fragment } from 'react'
import { get, map } from 'lodash'
import { ErrorMessage } from 'formik'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { DeleteIcon } from '../../../../../components/icons'
import { multiSelectStyles } from '../variables'

export default function SelectStrategist({ values, setFieldValue, seletedSearchByStrategist, searchByStrategist }) {
  const handleSearchSelectionOption = (options, event, setFieldValue) => setFieldValue(event, options)

  const handleRemoveSearchStrategistSelection = (selectedStrategist, strategistId, setFieldValue) => {
    setFieldValue('seletedSearchByStrategist', selectedStrategist.filter(strategist => strategist.value !== strategistId));
  }

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="form-group material-textfield">
          <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
            <ReactMultiSelectCheckboxes
              styles={multiSelectStyles}
              width="100%"
              name="searchByStrategist"
              value={seletedSearchByStrategist}
              placeholderButtonLabel="Select"
              options={searchByStrategist}
              onChange={(event) => handleSearchSelectionOption(event, 'seletedSearchByStrategist', setFieldValue)}
            />
            <label className="multiselect__checkboxes__field__label">Search by strategist...</label>
          </div>
          <ErrorMessage className="invalid-feedback" name="searchByStrategist" component="div" />
        </div>
      </div>
      {get(values, 'seletedSearchByStrategist', []).length > 0 &&
       <div className="col-md-12">
        <label className="label__text__sm">Selected Strategist</label>
        <ul className="selected__strategist__list">
            {map(seletedSearchByStrategist, (strategist, index) => (
              <li key={index}>
                <div className="strategist__title">
                  {strategist.label}
                </div>
                <div onClick={() => handleRemoveSearchStrategistSelection(seletedSearchByStrategist, strategist.value, setFieldValue)}>
                  <DeleteIcon />
                </div>
              </li>
              )) }
            {/* <li>
              <div className="strategist__title">
                <center>No strategist selected.</center>
              </div>
            </li> */}
        </ul>
      </div>}
    </Fragment>
  )
}
