import { connect } from 'react-redux'

import { getUserbyId } from '../../../../../store/dashboard/duck'
import {
  fetchProspectus,
  fetchProspectusById,
  createProspectus,
  clearProspectusPhase,
} from '../../../../../store/strategistV2/prospectus/prospectus/actions'

import ProspectusForm from './ProspectusForm'

const ProspectusInformationContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    prospectus: state.strategistV2.prospectus.prospectus.prospectus,
    prospectusDetail: state.strategistV2.prospectus.prospectus.prospectusDetail,
    fetchProspectusPhase: state.strategistV2.prospectus.prospectus.fetchProspectusPhase,
    createProspectusPhase: state.strategistV2.prospectus.prospectus.createProspectusPhase,
    fetchProspectusByIdPhase: state.strategistV2.prospectus.prospectus.fetchProspectusByIdPhase,
  }),
  // Map actions to dispatch and props
  {
    getUserbyId,
    fetchProspectus,
    createProspectus,
    fetchProspectusById,
    clearProspectusPhase,
  }
)(ProspectusForm)

export default ProspectusInformationContainer
