import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'
import * as types from './action-types'
import * as api from './api'

////////////////////////////
/// Interal notes action ///
////////////////////////////

const fetchTownResearchEpic = (action$) =>
  action$.ofType(types.GET_RESEARCH_TOWN_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchTownResearch(action.payload))
      .map((payload) => ({
        type: types.GET_RESEARCH_TOWN_DATA_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_RESEARCH_TOWN_DATA_ERROR,
          payload: { error },
        })
      )
  })
const updateTownResearchEpic = (action$) =>
  action$.ofType(types.UPDATE_RESEARCH_TOWN_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updateTownResearch(action.payload))
      .map((payload) => ({
        type: types.UPDATE_RESEARCH_TOWN_DATA_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.UPDATE_RESEARCH_TOWN_DATA_ERROR,
          payload: { error },
        })
      )
  })

const updateSchoolOrderEpic = (action$) =>
  action$.ofType(types.UPDATE_SCHOOL_ORDER).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updateSchoolOrder(action.payload))
      .map((payload) => ({
        type: types.UPDATE_SCHOOL_ORDER_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.UPDATE_SCHOOL_ORDER_ERROR,
          payload: { error },
        })
      )
  })

// combine town epic
export const townsEpic = combineEpics(
  fetchTownResearchEpic,
  updateTownResearchEpic,
  updateSchoolOrderEpic
)
