import React from 'react'

export default function YourAgentPageFilter({ handleResultEntity, page }) {

  const handleChange = ({ target }) => handleResultEntity({ pageLimit: parseInt(target.value) });

  return (
    <div className="material-textfield">
      <select
        className="form-control custom-select material-textfield-input results__select"
        name="resultEntity"
        onChange={handleChange}
        value={page}
      >
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        <option value={250}>250</option>
        <option value={500}>500</option>
        <option value={1000}>1000</option>
        <option value={2000}>2000</option>
        <option value={3000}>3000</option>
        <option value={4000}>4000</option>
        <option value={5000}>5000</option>
        <option value={6000}>6000</option>
        <option value={7000}>7000</option>
      </select>
      <label className="material-textfield-label">Results</label>
    </div>
  )
}
