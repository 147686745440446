import * as types from './action-types'
/*
    ///////////////////////////////////
  /// Your agent actions ///
//////////////////////////////////
*/
// fetch your agent list
export const fetchYourAgentList = (payload) => ({
  type: types.FETCH_YOUR_AGENT_LIST,
  payload,
})

// clear your agent phase
export const clearYourAgentPhase = (payload) => ({
  type: types.CLEAR_YOUR_AGENT_PHASE,
  payload,
})
