import * as types from './action-types'
/* 
   //////////////////////////
  /// deals action ///
/////////////////////////
 */
// fetch town and school district data
export const fetchTownAndSchoolDistrict = (payload) => ({
  type: types.GET_TOWN_AND_SCHOOL_DISTRICT,
  payload,
})
// fetch school district for deal form
export const fetchSchoolDistrict = (payload) => ({
  type: types.FETCH_SCHOOL_DISTRICT,
  payload,
})
// fetch town and school district data
export const fetchClientDeal = (payload) => ({
  type: types.FETCH_CLIENT_DEAL,
  payload,
})
// create update deal for client
export const createUpdateDeal = (payload) => ({
  type: types.CREATE_UPDATE_DEAL,
  payload,
})
// clear deal section phase
export const clearDealSectionPhase = () => ({
  type: types.CLEAR_DEALS_PHASE,
})
// Delete  deal for client
export const deleteClientDeal = (payload) => ({
  type: types.DELETE_CLIENT_DEAL,
  payload,
})
