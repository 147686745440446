import React, { PureComponent, /*, PropTypes*/
Fragment} from 'react'
import { DatePicker, Spin } from 'antd'
import { SortUpIcon, ColoredUserIcon, FilterIcon, DownCaretIcon, CloseIcon, SortDownIcon, CaretRightIcon, NotReadyTourIcon } from "../../../../../../components/icons"
import './styles.scss'
import UserContext from "../../../../../App/Context"
import _ from "lodash"
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'
import Loader from '../../../../../../components/Loader'
import ReactModal from 'react-modal'
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from '../../../../../../utils/helper'
const cookies = new Cookies()

const HOSTNAME = process.env.API_HOSTNAME
let _user = ''
export default class InterestedNotTownTourComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      sort: '',
      sort_value: 0,
      notReadyTourData: [],
      isLoading: false,
      isEmailModel: false,
      clientIsSendEmail: false
    }
  }
  static propTypes = {
    // PropTypes go here
  }

  componentDidMount() {
    document.title = "Interested but doesn't want a town tour | Jungler"
    _user = cookies.get('user')
    this.getNotReadyTourData()
  }

  componentDidUpdate(preProps){
    if(this.props.followUpPhase === "success"){
      this.getNotReadyTourData()
      this.props.clearPhase()
    }
    if(this.props.followUpPhase === "error"){
      this.setState({ isLoading: false })
      this.props.clearPhase()
    }
  }

  getNotReadyTourData = async (data) => {
    this.setState({ isLoading: true })
    if (!data) {
      data = {
        sort: this.state.sort,
        sort_value: this.state.sort_value
      }
    }

    const jwtToken = cookies.get('Authorization');
    if (jwtToken) {
      const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
      const res = await axios({
        method: 'GET',
        url: `${HOSTNAME_V2}/agent/intrested_not_ready_to_tour?sort=${data.sort}&sort_value=${data.sort_value}`,
        'headers': { 'Authorization': jwtToken }
      }).catch(logoutInvalidRequest)
      const sectionCounts = _.get(res, 'data.data', {})
      this.setState({ notReadyTourData: sectionCounts, isLoading: false })
    }
  }

  onDateChange(clientId, index, date, dateString) {
    let selectDate = moment(date).format("YYYY-MM-DD")
    let data = {}
    data.follow_up_date = selectDate
    data.client_id = clientId
    data.agent_id =_user && _user.id

    const notReadyTourData = this.state.notReadyTourData
    notReadyTourData[index].follow_up_date = selectDate
    this.setState({ isLoading: true, notReadyTourData }, () => {
      this.props.agentClientFollowUp(data)
    })
  }

  sortData(val) {
    let sort_value = this.state.sort_value
    if (val !== this.state.sort) {
      sort_value = 0
    }
    else {
      sort_value === 0 ? sort_value = 1 : sort_value = 0
    }
    this.setState({ sort: val, sort_value: sort_value }, () => {
      const data = {
        sort: this.state.sort,
        sort_value: this.state.sort_value
      }
      this.getNotReadyTourData(data)
    })
  }

  render() {
    const { notReadyTourData, sort, sort_value } = this.state
    return (
      <Spin spinning={this.state.isLoading} size="large" indicator={<Loader />}>

        {/* This component for mobile only */}
        <div className="mob__view__container mob__view__control">
            <table className="table mob__custom__table">
              <tbody>
                {notReadyTourData && notReadyTourData.length > 0 ?
                  notReadyTourData.map((client, idx) => {
                    return (
                    <tr key={idx}>
                      <td onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                        <div className="client__name">
                          {client.full_client_name}
                        </div>
                      </td>
                      <td>
                        <span className="mob__follow__date">
                        <div className="btn__follow-up-date">
                            <div className="follow-up-date-label">Follow up date</div>
                            <DatePicker
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={this.onDateChange.bind(this, client.id, idx)}
                              defaultValue={client && client.follow_up_date}
                              value={client && client.follow_up_date && client.follow_up_date !== "" && moment.utc(client.follow_up_date)}
                              format="MM/DD/YYYY"
                              inputReadOnly={true}
                              allowClear={false}
                              placeholder="N/A"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                          <span onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}><CaretRightIcon className="right-icon" /></span>
                        </span>
                      </td>
                    </tr>
                    )
                  })
                  :
                  <div className="blank__table__state">No Clients</div>
                }
              </tbody>
            </table>
          </div>
         {/* End component for mobile only */}

        <div className="desktop__view">
          <div>
            <div className="alert__info__indicated mb__20"><NotReadyTourIcon className="indicated-icon" /> These clients have indicated some interest in these towns but are NOT ready to tour yet. They should not be reached out to until indicated by your strategist. </div>
            <div className="row">
              <div className="col-md-12">
                {
                  (_.size(notReadyTourData) > 0) ?
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead className="header-color">
                        <tr>
                          <th onClick={this.sortData.bind(this, 'full_client_name')} className="cursor__pointer">
                            <span className="table-column-title">Client Name</span>
                            <span className="table-column-sorter">
                              {
                                (sort === "full_client_name" && sort_value === 0) ?
                                  <SortDownIcon />
                                  :
                                  <SortUpIcon />
                              }
                            </span>
                          </th>
                          <th onClick={this.sortData.bind(this, 'town_name')} className="cursor__pointer">
                            <span className="table-column-title">Town Name</span>
                            <span className="table-column-sorter">
                              {
                                (sort === "town_name" && sort_value === 0) ?
                                  <SortDownIcon />
                                  :
                                  <SortUpIcon />
                              }
                            </span>
                          </th>
                          <th onClick={this.sortData.bind(this, 'full_strategist_name')} className="cursor__pointer">
                            <span className="table-column-title">Strategist Name</span>
                            <span className="table-column-sorter">
                              {
                                (sort === "full_strategist_name" && sort_value === 0) ?
                                  <SortDownIcon />
                                  :
                                  <SortUpIcon />
                              }
                            </span>
                          </th>
                          <th onClick={this.sortData.bind(this, 'follow_up_date')} className="cursor__pointer">
                            <span className="table-column-title">Follow Up</span>
                            <span className="table-column-sorter">
                              {
                                (sort === "follow_up_date" && sort_value === 0) ?
                                  <SortDownIcon />
                                  :
                                  <SortUpIcon />
                              }
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          notReadyTourData.map((client, idx) => {
                            return (
                              <tr key={idx}>
                                <td
                                className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>{client.full_client_name}</td>
                                <td
                                   className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                   <span key={"town__tour__"+idx}>{client.towns && client.towns.join(",")}</span>
                                </td>
                                <td
                                  className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                  {client.strategists && client.strategists.map((strategist, idx) => {
                                  return <span key={"strategist__tour__"+idx}>{(idx ? ', ' : '') + strategist.full_strategist_name}</span>;
                                  })
                                }
                                </td>
                                <td>
                                  <div className="btn__follow-up-date">
                                    <div className="follow-up-date-label">Follow up date</div>
                                    <DatePicker
                                      className="datepicker__follow-up-date"
                                      dropdownClassName="datepicker__overlay__follow-up-date"
                                      onChange={this.onDateChange.bind(this, client.id, idx)}
                                      defaultValue={client && client.follow_up_date}
                                      value={(client && client.follow_up_date) && moment.utc(client.follow_up_date)}
                                      format="MM/DD/YYYY"
                                      inputReadOnly={true}
                                      allowClear={false}
                                      placeholder="N/A"
                                    />
                                    <DownCaretIcon className="caret-icon" />
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  :
                  <div className="blank__table__state">No Clients</div>
                }
              </div>
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}
