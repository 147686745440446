import React, { PureComponent /*, PropTypes*/ } from 'react'
import { DatePicker, Spin } from 'antd'
import { SortUpIcon, DownCaretIcon, CloseIcon, SortDownIcon, CaretRightIcon, NotReadys } from "../../../../../../components/icons"
import './styles.scss'
import UserContext from "../../../../../App/Context"
import _ from 'lodash'
import moment from 'moment'
import $ from 'jquery'
import Loader from '../../../../../../components/Loader'
import ReactModal from 'react-modal'
import { fetch } from '../../../../../../utils'

const HOSTNAME = process.env.API_HOSTNAME
export default class NotTouchedInFourWeekComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      sort: '',
      sort_value: 0,
      notTouchedIn4WeekData: [],
      isLoading: false,
      isEmailModel: false,
      clientIsSendEmail: false
    }
  }
  static propTypes = {
    // PropTypes go here
  }

  componentDidMount() {
    document.title = "Not Touched in 4 Weeks | Jungler"
    this.getNotTouchedIn4Week()
  }

  componentDidUpdate(preProps){
    if(this.props.followUpPhase === "success"){
      this.getNotTouchedIn4Week()
      this.props.clearPhase()
    }
    if(this.props.followUpPhase === "error"){
      this.setState({ isLoading: false })
      this.props.clearPhase()
    }
  }

  getNotTouchedIn4Week() {
    this.setState({ isLoading: true })
    const data = {
      sort: this.state.sort,
      sort_value: this.state.sort_value
    }
    this.props.getNotTouchedIn4Week(data)
  }

  static getDerivedStateFromProps(props, state) {
    if (state.notTouchedIn4WeekData !== props.notTouchedIn4WeekData.data) {
      return { notTouchedIn4WeekData: _.get(props, 'notTouchedIn4WeekData.data', ''), isLoading: false }
    }
    return null;
  }

  selectedChange(id, clientData, e) {
    if (e.target.value != "Select a Status") {
      this.setState({ selectStatus: e.target.value, clientId: id, clientIsSendEmail: clientData.isSendEmail, preActivityStatus: clientData.activity_status })
      if (e.target.value == "Initial Welcome Sent") {
        this.initialSelected(id, clientData)
      } else if (e.target.value == "Accepted Offer") {
        this.dealClick(id, clientData, "acceprt_offer_field")
      } else if (e.target.value == "Signed Contract") {
        this.dealClick(id, clientData, "signed_contract_field")
      } else if (e.target.value == "Closed") {
        this.dealClick(id, clientData, "closed_field")
      }
      else {
        let data1 = {
          client_id: id,
          activity_status: e.target.value,
          isAcceptOffer: false,
          isSendEmail: this.state.isSendEmail
        }
        this.props.updateClientUser(data1)
        this.getNotTouchedIn4Week()
      }
    }
  }

  dealClick(clientID, clientData, field) {
    let agentData = {
      client_id: clientID
    }
    fetch(`${HOSTNAME}/deal/get_client_deal_by_agent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(agentData)
    }).then((response) => response.json())
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          if (field === "acceprt_offer_field") {
            this.props.deleteDealData(response.data, clientData, this.state.selectStatus)
          } else {
            let dealsData = _.sortBy(response.data, function (o) {
              return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
            }).reverse();
            this.props.editDealSection(dealsData[0], clientData, this.state.selectStatus)
          }
        } else {
          //true, false- model open close, add, edit- button type, optional- error message
          if (field === "acceprt_offer_field") {
            this.props.openDealModal(true, "add", "optional", clientData, this.state.selectStatus)
          } else {
            this.props.openDealModal(true, "add", "mandatory", clientData, this.state.selectStatus)
          }
        }
      })
      .catch((error) => {
      })
  }

  initialSelected(id, clientData) {
    this.setState({ isEmailModel: true })
  }

  emailClickHandle(e) {
    this.setState({ isEmailModel: false })
    if (this.state.clientIsSendEmail) {
      this.setState({ isSendEmail: false }, function () {
        let data = {}
        data.client_id = this.state.clientId
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Null"
        this.props.updateClientUser(data)
      })
    } else {
      this.setState({ isSendEmail: !this.state.isSendEmail }, function () {
        let data = {}
        data.client_id = this.state.clientId
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Initial Welcome Sent"
        this.props.updateClientUser(data)
      })
    }
  }

  closeModal() {
    let c_id = this.state.clientId
    $('.uniQueselect-' + c_id).prop('selectedIndex', 0)
    this.setState({ isEmailModel: false, clientId: 0, selectStatus: this.state.preActivityStatus })
  }

  onDateChange(clientId, index, date, dateString) {
    let selectDate = moment(date).format("YYYY-MM-DD")
    let data = {}
    data.follow_up_date = selectDate
    data.client_id = clientId
    data.agent_id = this.context.userData.id

    const notTouchedIn4WeekData = this.state.notTouchedIn4WeekData
    notTouchedIn4WeekData[index].follow_up_date = selectDate
    this.setState({ isLoading: true, notTouchedIn4WeekData }, () => {
      this.props.agentClientFollowUp(data)
    })
  }

  sortData(val) {
    let sort_value = this.state.sort_value
    if (val !== this.state.sort) {
      sort_value = 0
    }
    else {
      sort_value === 0 ? sort_value = 1 : sort_value = 0
    }
    this.setState({ sort: val, sort_value: sort_value }, () => {
      const data = {
        sort: this.state.sort,
        sort_value: this.state.sort_value
      }
      this.props.getNotTouchedIn4Week(data)
    })
  }


  render() {
    const { notTouchedIn4WeekData, sort, sort_value } = this.state
    return (
      <Spin spinning={this.state.isLoading} size="large" indicator={<Loader />}>

        {/* This component for mobile only */}
        <div className="mob__view__container mob__view__control">
            <table className="table mob__custom__table">
              <tbody>
              {
                notTouchedIn4WeekData && notTouchedIn4WeekData.length > 0 ?
                notTouchedIn4WeekData.map((client, idx) => {
                  return(
                  <tr key={idx}>
                    <td onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                      <div className="client__name">
                        {client.full_client_name}
                      </div>
                      <div className="client__budget">
                        {client.price_min ? `$${client.price_min.toLocaleString()}` : "$0"} - {client.price_max ? `$${client.price_max.toLocaleString()}` : "$0"}
                      </div>
                      <div>
                        {client.rental_budget && client.rental_budget !== "" ? (<b>Rental Budget: </b>) : ""}
                        {client.rental_budget && client.rental_budget !== "" ? client.rental_budget : ""}
                      </div>
                    </td>
                    <td>
                      <span className="mob__follow__date">
                      <div className="btn__follow-up-date">
                          <div className="follow-up-date-label">Follow up date</div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.onDateChange.bind(this, client.id, idx)}
                            defaultValue={client && client.follow_up_date}
                            value={client && client.follow_up_date && client.follow_up_date != "" && moment.utc(client.follow_up_date)}
                            format="MM/DD/YYYY"
                            inputReadOnly={true}
                            allowClear={false}
                            placeholder="N/A"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        <span  onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}><CaretRightIcon className="right-icon" /></span>
                      </span>
                    </td>
                  </tr>
                  )
                })
                :
                <div className="blank__table__state">No Clients</div>
              }
              </tbody>
            </table>
          </div>
         {/* End component for mobile only */}

        <div className="desktop__view">
          <div>
      
          <div className="alert__info__indicated2 mb__20" style={{ color: '#cf0808' }}><NotReadys className="indicated-icon2"  />These clients have not been touched in 4 weeks.</div>
            <div className="row">
              <div className="col-md-12">
                  {
                    (_.size(notTouchedIn4WeekData) > 0) ?
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead className="header-color">
                          <tr>
                            <th onClick={this.sortData.bind(this, 'full_client_name')} className="cursor__pointer">
                              <span className="table-column-title">Client Name</span>
                              <span className="table-column-sorter">
                                {
                                  (sort === "full_client_name" && sort_value === 0) ?
                                    <SortDownIcon />
                                    :
                                    <SortUpIcon />
                                }
                              </span>
                            </th>
                            <th onClick={this.sortData.bind(this, 'price_min')} className="cursor__pointer">
                              <span className="table-column-title">Budget</span>
                              <span className="table-column-sorter">
                                {
                                  (sort === "price_min" && sort_value === 0) ?
                                    <SortDownIcon />
                                    :
                                    <SortUpIcon />
                                }
                              </span>
                            </th>
                            <th onClick={this.sortData.bind(this, 'full_strategist_name')} className="cursor__pointer">
                              <span className="table-column-title">Strategist Name</span>
                              <span className="table-column-sorter">
                                {
                                  (sort === "full_strategist_name" && sort_value === 0) ?
                                    <SortDownIcon />
                                    :
                                    <SortUpIcon />
                                }
                              </span>
                            </th>
                            <th onClick={this.sortData.bind(this, 'status')} className="cursor__pointer">
                              <span className="table-column-title">Status</span>
                              <span className="table-column-sorter">
                                {
                                  (sort === "status" && sort_value === 0) ?
                                    <SortDownIcon />
                                    :
                                    <SortUpIcon />
                                }
                              </span>
                            </th>
                            <th onClick={this.sortData.bind(this, 'follow_up_date')} className="cursor__pointer">
                              <span className="table-column-title">Follow Up</span>
                              <span className="table-column-sorter">
                                {
                                  (sort === "follow_up_date" && sort_value === 0) ?
                                    <SortDownIcon />
                                    :
                                    <SortUpIcon />
                                }
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            notTouchedIn4WeekData.map((client, idx) => {
                              return (
                                <tr key={idx}>
                                  <td
                                   className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                   {<span style={{color: client.status === "Closed" ? 'black' : '#cf0808' }}>{client.full_client_name}</span>} 
                          
                                  </td>
                                  <td
                                    className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                    {client.price_min ? `$${client.price_min.toLocaleString()}` : "$0"} - {client.price_max ? `$${client.price_max.toLocaleString()}` : "$0"}
                                    <div>
                                      {client.rental_budget && client.rental_budget !== "" ? (<b>Rental Budget: </b>) : ""}
                                      {client.rental_budget && client.rental_budget !== "" ? client.rental_budget : ""}
                                    </div>
                                  </td>
                                  <td
                                    className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                    {client.strategists && client.strategists.map((strategist, idx) => {
                                      return <span key={idx}>{(idx ? ', ' : '') + strategist.full_strategist_name}</span>;
                                      })
                                    }
                                  </td>
                                  <td>
                                    {
                                      (this.context.userData && (this.context.userData.role !== 'local' || this.context.userData.role !== 'business development')) &&
                                      <div className="material-textfield">
                                        <select
                                          defaultValue={client.status}
                                          value={client.status}
                                          className={"form-control custom-select material-textfield-input status__select uniQueselect-" + client.id}
                                          onChange={this.selectedChange.bind(this, client.id, client)}>
                                          >
                                      <option>
                                            {(client.status !== "" && client.status !== "Null" && client.status !== "NULL" && client.status !== "Inspection Scheduled") ? client.status : 'Select a status'}
                                          </option>
                                          {
                                            (client.status !== "Initial Welcome Sent") &&
                                            <option value="Initial Welcome Sent" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Initial Welcome Sent</option>
                                          }
                                          {
                                            (client.status !== "Actively Engaged") &&
                                            <option value="Actively Engaged" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Actively Engaged</option>
                                          }
                                          {
                                            (client.status !== "Moderately Engaged") &&
                                            <option value="Moderately Engaged" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Moderately Engaged</option>
                                          }
                                          {
                                            (client.status !== "Accepted Offer") &&
                                            <option value="Accepted Offer" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Accepted Offer</option>
                                          }
                                          {
                                            (client.status !== "Signed Contract") &&
                                            <option value="Signed Contract" disabled={client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Signed Contract</option>
                                          }
                                          {
                                            (client.status !== "Closed") &&
                                            <option value="Closed" disabled={client.status === "Closed" ? true : false }>Closed</option>
                                          }
                                          {
                                            (client.status !== "Unresponsive") &&
                                            <option value="Unresponsive" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Unresponsive</option>
                                          }
                                        </select>
                                        <label className="material-textfield-label">Status</label>
                                      </div>
                                    }
                                  </td>
                                  <td>
                                    <div className="btn__follow-up-date">
                                      <div className="follow-up-date-label">Follow up date</div>
                                      <DatePicker
                                        className="datepicker__follow-up-date"
                                        dropdownClassName="datepicker__overlay__follow-up-date"
                                        onChange={this.onDateChange.bind(this, client.id, idx)}
                                        defaultValue={client && client.follow_up_date}
                                        value={(client && client.follow_up_date) && moment.utc(client.follow_up_date)}
                                        format="MM/DD/YYYY"
                                        inputReadOnly={true}
                                        allowClear={false}
                                        placeholder="N/A"
                                      />
                                      <DownCaretIcon className="caret-icon" />
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    :
                    <div className="blank__table__state">No Clients</div>
                  }
              </div>
            </div>
            <ReactModal
              isOpen={this.state.isEmailModel}
              onRequestClose={this.closeModal.bind(this)}
              contentLabel="Are you sure?"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-body react-modal-body-sm">
                  <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.closeModal.bind(this)}><CloseIcon /></button>
                  <h5 className="react-modal-title mb__13">Are you sure?</h5>
                  <p className="react-modal-subtitle mb__3">This is just a confirmation that you sent an Initial Welcome Email to the client. Click “Yes” below if you did.</p>
                  <div className="text-right">
                    <button onClick={this.emailClickHandle.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Sent</button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        </div>
      </Spin>
    )
  }
}
