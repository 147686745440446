import { connect } from 'react-redux'
import {fetchUser, handleSignOut, getNotifications, clearNotification} from '../../store/user/duck'
import {dashboardClients} from '../../store/analytics/duck'
import AdminDashboardForm from './component'
const AdminDashboard = connect(
  // Map state to props
  (state) => ({
    profilePhase: state.user.profilePhase,
    user: state.user.users,
    dashClientsData:state.analytics.dashClientsData,
    profileError: state.user.profileError, 
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    dashboardClients,
    getNotifications,
    clearNotification
  }
)(AdminDashboardForm)
export default AdminDashboard
