import React, { Fragment } from 'react'
import { SortDownIcon, SortUpIcon } from '../../../../../components/icons'

export default function AppointmentTableHeader({ appointments, sortByType, sortByKey, sortData }) {
  const sortAppointmentData = (sortByKey) => sortData(sortByKey)

  return (
    <Fragment>
      <thead>
        <tr>
          <th className="cursor__pointer" onClick={() => sortAppointmentData('clientName')}>
            <span className="table-column-title">Client Name</span>
            <span className="table-column-sorter">
              {sortByKey === 'clientName' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
            </span>
          </th>
          <th className="cursor__pointer" onClick={() => sortAppointmentData('strategistName')}>
            <span className="table-column-title">Strategist's Name</span>
            <span className="table-column-sorter">
              {sortByKey === 'strategistName' && sortByType === 0 ? (
                <SortDownIcon />
              ) : (
                <SortUpIcon />
              )}
            </span>
          </th>
          <th className="cursor__pointer" onClick={() => sortAppointmentData('agentName')}>
            <span className="table-column-title">Agent Name</span>
            <span className="table-column-sorter">
              {sortByKey === 'agentName' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
            </span>
          </th>
          <th className="cursor__pointer" onClick={() => sortAppointmentData('typeOfAppointment')}>
            <span className="table-column-title">Type of Appointment</span>
            <span className="table-column-sorter">
              {sortByKey === 'typeOfAppointment' && sortByType === 0 ? (
                <SortDownIcon />
              ) : (
                <SortUpIcon />
              )}
            </span>
          </th>
          <th className="cursor__pointer" onClick={() => sortAppointmentData('appointmentDate')}>
            <span className="table-column-title">Appointment Date & Time</span>
            <span className="table-column-sorter">
              {sortByKey === 'appointmentDate' && sortByType === 0 ? (
                <SortDownIcon />
              ) : (
                <SortUpIcon />
              )}
            </span>
          </th>
        </tr>
      </thead>
    </Fragment>
  )
}
