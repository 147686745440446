import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/* 
    /////////////////////////////
  /// follow up epic ///
////////////////////////////
*/
// fetch prospectus epic
const fetchFollowUpEpic = (action$) =>
  action$.ofType(types.FETCH_FOLLOW_UP_DATE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchFollowUp(action.payload))
      .map((payload) => ({
        type: types.FETCH_FOLLOW_UP_DATE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_FOLLOW_UP_DATE_ERROR,
          payload: { error },
        })
      )
  })
// fetch prospectus by id epic
const updateFollowUpEpic = (action$) =>
  action$.ofType(types.UPDATE_FOLLOW_UP_DATE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updateFollowUp(action.payload))
      .map((payload) => ({
        type: types.UPDATE_FOLLOW_UP_DATE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.UPDATE_FOLLOW_UP_DATE_ERROR,
          payload: { error },
        })
      )
  })

// combine prospectus epic
export const followUpEpic = combineEpics(fetchFollowUpEpic, updateFollowUpEpic)
