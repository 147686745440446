import fetch from 'isomorphic-fetch'
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from './helper'
const cookies = new Cookies()
import { notification } from 'antd';

const handleResponseErrors = (res) => {
  const clonedResponse = res.clone()
  if (res.ok) { 
    return res
  } else {
    if (res.status === 500) {
      res.json().then(body => {
        if (body.message) {
          notification.error({ message: "Sorry, we ran into an error." })
        }
      });
      return clonedResponse
    }
    logoutInvalidRequest(res.statusText)
  }
}

export default (url, options) => {

  // const jwtToken = localStorage.getItem(Config.LocalStorageKeys.Authorization)
  const jwtToken = cookies.get('Authorization');
  if (jwtToken) {
    let authAddedOptions = options
    if (typeof options !== 'object') {
      authAddedOptions = {}
    }
    if (typeof authAddedOptions.headers !== 'object') {
      authAddedOptions.headers = {}
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: jwtToken
    }
    return fetch(url, authAddedOptions).then(handleResponseErrors)
  } else {
    return fetch(url, options).then(handleResponseErrors)
  }
}