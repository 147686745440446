import React, { useState, useEffect } from 'react'
import { get, isEmpty, map } from 'lodash'
import { Spin } from 'antd'
import { DeleteIcon } from '../../../../../../../../components/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { SUCCESS } from '../../../../../../../../constants/phase'
import Loader from '../../../../../../../../components/Loader'

export default function SchoolTableData({ publicData, editSchool, deleteSchool, ...props }) {
  const [isLoading, setLoader] = useState(false)

  useEffect(() => {
    if (props.updateSchoolTownPhase === SUCCESS) {
      setLoader(false)
      props.clearTownsPhase()
      props.fetchSchoolTown()
    }
  }, [props.updateSchoolTownPhase])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // const newSchoolOrder = arrayMove(publicData, oldIndex, newIndex)
    // const schoolOrder = map(newSchoolOrder, (order, index) => ({
    //   school_id: order.id,
    //   school_index: index,
    // }))
    // setLoader(true)
    // props.updateSchoolOrder({ schoolOrder })
  }
  return (
    <Spin size="large" spinning={isLoading} indicator={<Loader />}>
      <div className="table-responsive">
        <table className="table custom__table__simple">
          <thead>
            <tr>
              <th>
                <span className="table-column-title">Schools</span>
              </th>
              <th>
                <span className="table-column-title">Grades </span>
              </th>
              <th>
                <span className="table-column-title">Rating</span>
              </th>
              <th>
                <span className="table-column-title">Student to Teacher Ratio</span>
              </th>
              <th colSpan="3">
                <span className="table-column-title"># of Students</span>
              </th>
            </tr>
          </thead>

          {isEmpty(publicData) ? (
            <tr>
              <td colSpan="7" className="blank__table__state">
                No data found.
              </td>
            </tr>
          ) : (
            <SortableList
              items={publicData}
              handleEditClick={(index) => editSchool(index)}
              handleDeleteClick={(index) => deleteSchool(index)}
              onSortEnd={onSortEnd}
            />
          )}
        </table>
      </div>
    </Spin>
  )
}

const SortableItem = SortableElement(({ value, handleEditClick, handleDeleteClick, style }) => (
  <tr style={style} className="dragable_td">
    <td>{get(value, 'InstitutionName')}</td>
    <td>{get(value, 'gradespancodebldgtext')}</td>
    <td>
      {/* eslint-disable-next-line no-nested-ternary */}
      {get(value, 'GSTestRating') !== ''
        ? !isNaN(parseInt(get(value, 'GSTestRating')))
          ? `${get(value, 'GSTestRating')}/5`
          : `${get(value, 'GSTestRating')}`
        : 'Not Rated'}
    </td>
    <td>
      {value.studentteacher !== 'Not Reported' && value.studentteacher !== '' ? value.studentteacher.split(':').length > 1 ? value.studentteacher : value.studentteacher + ':1' : 'Not Reported'}
    </td>
    <td>{get(value, 'studentsnumberof')}</td>
    <td>
      {/*<button className="btn btn__btn btn__link" onClick={() => handleEditClick(value)}>*/}
      {/*  Edit*/}
      {/*</button>*/}
    </td>
    <td>
      <button className="btn btn__btn btn__link" onClick={() => handleDeleteClick(value)}>
        <DeleteIcon pointerEvents={true} />
      </button>
    </td>
  </tr>
))

const SortableList = SortableContainer(({ items, handleEditClick, handleDeleteClick }) => {
  return (
    <tbody>
      {map(items, (value, index) => (
        <SortableItem
          disabled={true}
          style={{ zIndex: 1000 }}
          key={`item-${index}`}
          index={index}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          value={value}
        />
      ))}
    </tbody>
  )
})
