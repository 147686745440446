import { connect } from 'react-redux'
import {loginUser, handleSignOut} from '../../store/user/duck'
import StrategyEditForm from './component'

import { getTowns, getTownAgent, addStrategy, clearPhase} from '../../store/dashboard/duck'

const StrategyEdit = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase,
    townData: state.dashboard.townData,
    townAgnetData: state.dashboard.townAgnetData,
    addStrategyPhase: state.dashboard.addStrategyPhase,
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut,
    getTowns,
    addStrategy,
    clearPhase,
    getTownAgent,
  }
)(StrategyEditForm)
export default StrategyEdit
