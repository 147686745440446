/* eslint-disable no-nested-ternary */
import React from 'react'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../../../../utils/helper'

export default function InitialWelcomeHistory({ history, index, userName }) {
  const authorFieldsEmpty = !history.author_role && !history.author_full_name && !history.user_timezone

  if (authorFieldsEmpty) {
    return (
      <div>
        <div>
          <p className="activity-history-text" key={index}>
            {history.activity}
          </p>
          <div className="activity-history-time">
            {moment(history.created).tz(history.client_timezone).format("MMMM DD, YYYY hh:mm a z")}
          </div>
        </div>
      </div>
      )
  }

  return (
    <div>
      {
        (history.status !== "Null" && history.status !== "null" && history.status !== "NULL" && history.status !== "") &&
        <div>
          {(history.status === "Initial Welcome Sent") ?
              <div>
                <p className="activity-history-text" key={index}>
                  {capitalizeFirstLetter(history.author_role) || 'Agent'} <b>{history.author_full_name}</b> selected the status icon <b>Initial Welcome Sent</b> for client <b>{userName}</b>
                </p>
                <div className="activity-history-time">
                  {moment(history.created).tz(history.client_timezone).format("MMMM DD, YYYY hh:mm a z")}
                </div>
              </div>
              :
              (history.status === "Inspection Scheduled") ?
                <div>
                  <p className="activity-history-text" key={index}>
                    {capitalizeFirstLetter(history.author_role) || 'Agent'} <b>{history.author_full_name}</b> updated the question “You have an accepted offer and have scheduled inspection?” to <b>yes</b> for client <b>{userName}</b>
                  </p>
                  <div className="activity-history-time">
                    {moment(history.created).tz(history.client_timezone).format("MMMM DD, YYYY hh:mm a z")}
                  </div>
                </div>
                : <div>
                  <p className="activity-history-text" key={index}>
                    {capitalizeFirstLetter(history.author_role) || 'Agent'} <b>{history.author_full_name} </b> updated the status of the client <b>{userName}</b> to <b>{history.status}</b>
                  </p>
                  <div className="activity-history-time">
                    {moment(history.created).tz(history.client_timezone).format("MMMM DD, YYYY hh:mm a z")}
                  </div>
                </div>
          }
        </div>}
    </div>
  )
}

