export const FETCH_CLIENT_BY_ID = 'suburban/strategistV2/clients/FETCH_CLIENT_BY_ID'
export const FETCH_CLIENT_BY_ID_SUCCESS = 'suburban/strategistV2/clients/FETCH_CLIENT_BY_ID_SUCCESS'
export const FETCH_CLIENT_BY_ID_ERROR = 'suburban/strategistV2/clients/FETCH_CLIENT_BY_ID_ERROR'

export const FETCH_CLIENT_STRATEGIST_BD_LIST =
  'suburban/strategistV2/clients/FETCH_CLIENT_STRATEGIST_BD_LIST'
export const FETCH_CLIENT_STRATEGIST_BD_LIST_SUCCESS =
  'suburban/strategistV2/clients/FETCH_CLIENT_STRATEGIST_BD_LIST_SUCCESS'
export const FETCH_CLIENT_STRATEGIST_BD_LIST_ERROR =
  'suburban/strategistV2/clients/FETCH_CLIENT_STRATEGIST_BD_LIST_ERROR'

export const FETCH_STRATEGIST_BD_LIST = 'suburban/strategistV2/clients/FETCH_STRATEGIST_BD_LIST'
export const FETCH_STRATEGIST_BD_LIST_SUCCESS =
  'suburban/strategistV2/clients/FETCH_STRATEGIST_BD_LIST_SUCCESS'
export const FETCH_STRATEGIST_BD_LIST_ERROR =
  'suburban/strategistV2/clients/FETCH_STRATEGIST_BD_LIST_ERROR'

export const FETCH_STRATEGIST_DEV_BIZ_LIST =
  'suburban/strategistV2/clients/FETCH_STRATEGIST_DEV_BIZ_LIST'
export const FETCH_STRATEGIST_DEV_BIZ_LIST_SUCCESS =
  'suburban/strategistV2/clients/FETCH_STRATEGIST_DEV_BIZ_LIST_SUCCESS'
export const FETCH_STRATEGIST_DEV_BIZ_LIST_ERROR =
  'suburban/strategistV2/clients/FETCH_STRATEGIST_DEV_BIZ_LIST_ERROR'

export const CREATE_CLIENT_STRATEGIST_BIZ_DEV =
  'suburban/strategistV2/clients/CREATE_CLIENT_STRATEGIST_BIZ_DEV'
export const CREATE_CLIENT_STRATEGIST_BIZ_DEV_SUCCESS =
  'suburban/strategistV2/clients/CREATE_CLIENT_STRATEGIST_BIZ_DEV_SUCCESS'
export const CREATE_CLIENT_STRATEGIST_BIZ_DEV_ERROR =
  'suburban/strategistV2/clients/CREATE_CLIENT_STRATEGIST_BIZ_DEV_ERROR'

export const CREATE_REFFERING_CLIENT = 'suburban/strategistV2/clients/CREATE_REFFERING_CLIENT'
export const CREATE_REFFERING_CLIENT_SUCCESS =
  'suburban/strategistV2/clients/CREATE_REFFERING_CLIENT_SUCCESS'
export const CREATE_REFFERING_CLIENT_ERROR =
  'suburban/strategistV2/clients/CREATE_REFFERING_CLIENT_ERROR'

export const ADD_UPDATE_CLIENT = 'suburban/strategistV2/clients/ADD_UPDATE_CLIENT'
export const ADD_UPDATE_CLIENT_SUCCESS = 'suburban/strategistV2/clients/ADD_UPDATE_CLIENT_SUCCESS'
export const ADD_UPDATE_CLIENT_ERROR = 'suburban/strategistV2/clients/ADD_UPDATE_CLIENT_ERROR'

export const EDIT_CLIENT_INFORMATION = 'suburban/strategistV2/clients/EDIT_CLIENT_INFORMATION'
export const EDIT_CLIENT_INFORMATION_SUCCESS =
  'suburban/strategistV2/clients/EDIT_CLIENT_INFORMATION_SUCCESS'
export const EDIT_CLIENT_INFORMATION_ERROR =
  'suburban/strategistV2/clients/EDIT_CLIENT_INFORMATION_ERROR'

export const FETCH_FILTER_CLIENT_DATA = 'suburban/strategistV2/clients/FETCH_FILTER_CLIENT_DATA'
export const FETCH_FILTER_CLIENT_DATA_SUCCESS =
  'suburban/strategistV2/clients/FETCH_FILTER_CLIENT_DATA_SUCCESS'
export const FETCH_FILTER_CLIENT_DATA_ERROR =
  'suburban/strategistV2/clients/FETCH_FILTER_CLIENT_DATA_ERROR'

export const FETCH_REIVEW_SUCCESS = 'suburban/strategistV2/clients/FETCH_REIVEW_SUCCESS'
export const CLEAR_REIVEW_PHASE = 'suburban/strategistV2/clients/CLEAR_REIVEW_PHASE'

export const CLEAR_ACTIVITY_HISTORY_PHASE = 'suburban/strategistV2/clients/CLEAR_ACTIVITY_HISTORY_PHASE'

export const FETCH_CLIENT_DETAIL_FOR_DASH_BOARD_PHASE =
  'suburban/strategistV2/clients/FETCH_CLIENT_DETAIL_FOR_DASH_BOARD_PHASE'
export const CLEAR_CLIENT_PHASE = 'suburban/strategistV2/clients/CLEAR_CLIENT_PHASE'
