/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment, useState } from 'react'
import { map } from 'lodash'
import { Spin } from 'antd'

import './styles.scss'

import { CloseIcon } from '../../../../../../components/icons'
import { SUCCESS } from '../../../../../../constants/phase'
import Loader from '../../../../../../components/Loader'
import ChatHistory from './partials/ChatHistory'

export default function AgentClientChatComponent(props) {
  const [isLoading, setLoading] = useState(false)
  const [selectedAgent, setAgentId] = useState('')
  useEffect(() => {
    const clientId = props.match.params.id
    setAgentId('')
    props.clearClientAgents()
    setLoading(true)
    props.fetchClientAgent({ clientId })
  }, [])

  const onClose = () => {
    setAgentId('')
    props.closeDrawer()
  }

  // fetch client chat
  const handleAgentChange = (event) => {
    const { value } = event.target
    setAgentId(value)
  }

  // cwrp on success of fetching client agent listing and client agent chat
  useEffect(() => {
    if (props.fetchClientAgentListPhase === SUCCESS) {
      setLoading(false)
      props.clearChatPhase()
    }
  }, [props.fetchClientAgentListPhase])

  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Agent & Client Chat</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
     {/*  <Spin size="large" spinning={isLoading} indicator={<Loader />}> */}
        <div className="chats__start__bottom">
          {props.clientAgents && props.clientAgents.length > 0 && (
            <div className="chats__select__agents">
              <div className="material-textfield">
                <select
                  name="agent"
                  className="form-control custom-select material-textfield-input"
                  onChange={handleAgentChange}
                >
                  <option value="">Select</option>
                  {map(props.clientAgents, (clientAgent, index) => (
                    <option key={index} value={clientAgent.agent_id}>
                      {' '}
                      {clientAgent.agent_full_name}
                    </option>
                  ))}
                </select>
                <label className="material-textfield-label">Agent Name</label>
              </div>
            </div>
          )}

          <div className="chats__container">
            <div className="chats__wrapper">
              {props.clientAgents && props.clientAgents.length > 0 ? (
                <ChatHistory agentId={selectedAgent} {...props} />
              ) : (
               <div className="drawer__body__inner">
                  <div className="blank__state__text">
                    There are no agents assigned to a client. You'll be able to chat with an agent once you assign one to the strategy.
                  </div>
              </div>
              )}
            </div>
          </div>
        </div>
      {/* </Spin> */}
    </Fragment>
  )
}
