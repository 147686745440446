import { connect } from 'react-redux'

import DashboardComponent from './component'
import { getDashboardData } from '../../../../store/agentV2/actions'
import { LOADING } from '../../../../constants/phase'


const DashboardContainer = connect(
  // Map state to props
  (state) => ({
    dashboardData: state.agentV2.dashboardData,
    loading: state.agentV2.dashboardPhase === LOADING
  }),
  // Map actions to dispatch and props
  {
    getDashboardData
  }
)(DashboardComponent)

export default DashboardContainer
