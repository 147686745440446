import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import styles from './styles.scss'
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      teamName: "",
      _clientID: "",
      text: '',
      redirect: false,
      id: "",
      openSchedule: false,
      type: ''
    }
    this.sendBackgroundProfile = this.sendBackgroundProfile.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({_clientID:_clientid})
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.sendBackgroundPhase===true){
      this.state.text = "";
      const clntdata = {}
      clntdata.client_id = this.state._clientID;
      this.props.getUserbyId(clntdata)
      this.setState({ openSchedule: false})
      this.props.clearPhase()
    }
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  openScheduleBar(type, backData, e) {
    if(type === "add"){
      this.setState({ 
        openSchedule : true, 
        type: "add", 
        backData: "", 
        text: ""
      })
    }else{
      let profileData = ''
      let profileHTML = unescape(backData.background_profile) //{__html: user.background_profile}
      var tmp = document.createElement("DIV");
      tmp.innerHTML = profileHTML;
      profileData = tmp.textContent || tmp.innerText || "";

      this.setState({ 
        openSchedule : true, 
        type: "edit", 
        backData: backData, 
        text: profileData
      })
   }
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  sendBackgroundProfile(){
    if( this.state.text !== '' && this.state._clientID != ''){
      let data = {}
      data.client_id = this.state._clientID
      data.content  = this.state.text
      this.props.sendBackgroundData(data)
      this.setState({'text':''})
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      singleClientData,
    } = this.props

    
    
    let profileData = ''
    let modified_date = '';
    let author_full_name = '';
    let author_avatar = '';
    if(singleClientData && singleClientData.user && singleClientData.user.background_profile){
      let profileHTML = unescape(singleClientData.user.background_profile) //{__html: user.background_profile}
      var tmp = document.createElement("DIV");
      tmp.innerHTML = profileHTML;
      profileData = tmp.textContent || tmp.innerText || "";
      let background_data = singleClientData.user.background_profile_data;
      modified_date = !this.isEmpty(background_data) ? background_data.date_modified : user.modified ?  user.modified : '';
      author_full_name = !this.isEmpty(background_data) ? background_data.author_full_name : user.first_name && user.last_name ? user.first_name+' '+user.last_name : user.first_name ? user.first_name : '';
      author_avatar = !this.isEmpty(background_data) ? background_data.author_avatar :  user.avatar ?  user.avatar : '';
    }

    return (
    <div>
      <main className="clients__Messages--container">
        <div className="chat__container">

        {
          (profileData)?
           <button 
              className="btn btn__green back-right zindexingup new__common--btn" 
              type="button"
              onClick={this.openScheduleBar.bind(this, "edit", singleClientData && singleClientData.user)}>
              Edit Background Profile
            </button>
            : 
            <button 
              className="btn btn__green back-right zindexingup new__common--btn" 
              type="button"
              onClick={this.openScheduleBar.bind(this, "add", {})}>
              Add Background Profile
            </button>
        }

          <div className="mesgs">
            {profileData !=='' ? 
              <div className="chat__msg--history">
                <div className="chat__msg--date__time">{modified_date && moment(modified_date).format('dddd, MMMM Do YYYY, h:mm:ss A')}</div>
                <div className="incoming_msg">
                  <div className="incoming_msg_img">
                    <div className="chat__msg--avtar">
                      <img className="img-responsive" src={author_avatar && author_avatar !=='' ? author_avatar : 'img/login-bg-img.png'} alt="sunil"/>
                    </div>
                    <div className="chat__msg--avtar-name">
                      {author_full_name}
                    </div>
                  </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>{profileData}</p>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="chat__msg--history">
                <div className="received_msg">
                    This is where you would see a history of the background profile data of the client.<br/>There is no background profile data updated yet.
                  </div>
              </div>
            }
            {/*<div className="type_msg">
              <div className="chat___msg--input-write chat___msg--input-write-edit">
                <textarea 
                  type="text" 
                  name="text" 
                  value={this.state.text}
                  onChange={this.handleChange.bind(this)} 
                  className="chat___msg--input" 
                  placeholder="Send a Background profile" />

              <button 
                className="chat__msg--send__btn" 
                type="button"
                onClick={()=> this.sendBackgroundProfile()}>
                Send
              </button>
            </div>
            </div>*/}
          </div>
        </div>
      </main>


      <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            {
              (this.state.type === "add")?
                <div>Add Background Profile</div>
              :
                <div>Edit Background Profile</div>
            }
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body schedule__bodyauto">
            <div className="schedule__sidebar--form--wrapper">
              <div className="form-group rem__marg--btm">
                <textarea 
                  name="text" 
                  className="textareainputs__global textareainputs__globalht"
                  onChange={this.handleChange}
                  value={this.state.text}>
                </textarea>
              </div>
            </div>
          </div>
          <div className="schedule__sidebar--footer schedule__sidebar--footeredit ">
            {
              (this.state.type === "add")?
            <button 
              className="btn btn__green btn__green--lg btn-block btn__schedule" 
              onClick={()=> this.sendBackgroundProfile()}>
              Submit
            </button>
            :
            <div>
              <button 
                className="btn btn__green btn__green--lg btn-block btn__schedule"
                onClick={()=> this.sendBackgroundProfile()}>
                Save
              </button>
              
              {
                /*(this.state.type === "edit")?(
                <h5
                  onClick={this.deleteNote.bind(this)} 
                  style={{"cursor": "pointer","color": "#D0021B", "text-align": "center", "paddingBottom":"5px"}}>
                  Delete Note
                </h5>
                ):''*/
              }
            </div>
          }
          </div>
        </div>


    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
