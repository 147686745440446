import React from 'react'
import ReactModal from 'react-modal'
import { Collapse } from 'antd';
import _ from 'lodash';
import moment from "moment";

import {
  CloseIcon
} from "../../../../../components/icons";

import './styles.scss';
import { createStringAvatar } from '../../../../../utils/helper';

const { Panel } = Collapse;

export default class AgentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: 1
    };
  }

  toggleModal() {
    this.setState({
      collapse: 0
    });
  }

  handleSort = (first, second) => {
    if(first && second){
      if(first.value > second.value){
        return -1
      } else if(first.value < second.value){
        return 1
      } else {
        return 0
      }
    }
  }

  render() {

    let agents = this.props.agents

    if(Array.isArray(agents) && agents.length > 0){
      agents = agents.map((el) => {
        if(Array.isArray(el.dealPerCity) && el.dealPerCity.length > 0){
          const temp = el.dealPerCity.sort(this.handleSort)
          return {...el, dealPerCity: temp}
        }
        return {...el}
      })
    }

    return (
      <ReactModal
        isOpen={this.props.beforePreviewModal}
        contentLabel="Agent Details"
        portalClassName="react-modal"
      >
        <div className="react-modal-dialog react-modal-dialog-centered jc-accordion-width">
          <div className="react-modal-header jc-accordion-header">
            <label>{this.props.agents.length ? 'Agent Details' : 'No agents available at the moment'} - {this.props.townName}</label>
            {/* <button
              type="button"
              className="btn react-modal-close react-modal-close-sm"
              onClick={() => this.props.closeBeforePreviewModal()}
            >
              <CloseIcon />
            </button> */}
            <button
                onClick={() => this.props.closeBeforePreviewModal()}
                type="button"
                // id="close_user_modal"
                className="dashboard-react-modal-popup-close-icon"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
          </div>
          <div className="react-modal-body jc-accordion">
            {
              agents.map((agent, index) => {
                return (
                  <Collapse
                    accordion
                    expandIconPosition={'right'}
                    key={index}
                  >
                    <Panel header={`${agent.agent_first_name} ${agent.agent_last_name}, ${agent.firm_name || 'NA'}`} key={index}>
                      <div className="accordion-wrapper">
                        <div className="avatar-button">
                          <div className="avatar-con">
                            <div className="agent-pro">
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {agent.avatar ? (
                                <img src={agent.avatar} alt="" />
                              ) : agent.agent_first_name ? (
                                <div className="avatar-img">
                                  <div className="text-img">
                                    {createStringAvatar(
                                      `${agent.agent_first_name} ${agent.agent_last_name}`
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <img
                                  src="../img/blank_profile_image.png"
                                  alt=""
                                />
                              )}

                              <div>
                                {`${agent.agent_first_name} ${agent.agent_last_name},`}
                                <br/>
                                {`${agent.firm_name || 'NA'}`}
                              </div>
                            </div>
                            <div className="jc-body-copy">
                              <p>Start date: <b>{agent.start_date ? moment(agent.start_date).format('MM-DD-YYYY') : 'N/A'}</b></p>
                              <p>Town Agent lives in: <b>{agent.city_live || 'N/A'}</b></p>
                              <p>Total all green clients: <b>{agent.greenClients || 'N/A'}</b></p>
                            </div>
                          </div>
                          <div className="button-con">
                            {/*<div className="acc-btn outline-btn" onClick={() => {*/}
                            {/*  window.open(`/strategist/edit-user/${agent.agent_id}`, '_blank').focus();*/}
                            {/*}}>View Profile</div>*/}
                            <div className="acc-btn fill-btn" onClick={() => this.props.assignAgentToTown1(agent.agent_id, agent.id)}>Select Agent</div>
                          </div>
                        </div>
                        <div>
                          <div className="acc-client-info">
                            Closed Clients
                            <div className="acc-table">
                              <div className="table-row">
                                <span>
                                  <b>Total Closed Deals</b>
                                </span>
                                <span className="tcount">{agent.totalClosedDeals}</span>
                              </div>
                              {agent.closedDeals && agent.closedDeals.length > 0 &&
                                agent.closedDeals.map((deal, index) => {
                                  return (
                                    <div className="table-row" key={index}>
                                      <span>
                                        {deal.closedYear} - ${deal.totalSales.toLocaleString()} Total Sales
                                      </span>
                                      <span className="count">{deal.countClosed}</span>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          <div className="acc-client-info">
                            Deals per town
                            <div className="acc-table">
                              <div className="table-row">
                                <span>
                                  <b>Total Deals</b>
                                </span>
                                <span className="tcount">{agent.totalDeals}</span>
                              </div>
                              {agent.dealPerCity && agent.dealPerCity.length > 0 &&
                                agent.dealPerCity.map((deal, index) => {
                                  return (
                                    <div className="table-row" key={index}>
                                      <span>
                                        {deal.key}
                                      </span>
                                      <span className="count">{deal.value}</span>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                )
              })
            }
          </div>
        </div>
      </ReactModal>
    )
  }
}
