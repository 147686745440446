import Rx from 'rxjs/Rx'
import { Record, List} from 'immutable'
import { combineEpics } from 'redux-observable'
import HttpStatus from 'http-status-codes'
import { assign } from 'lodash'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import * as api from './api'

/**
 * Public: Action Types
 */

export const GET_DASH_CLIENTS = 'suburban/analytics/GET_DASH_CLIENTS'
export const GET_DASH_CLIENTS_SUCCESS = 'suburban/analytics/GET_DASH_CLIENTS_SUCCESS'
export const GET_DASH_CLIENTS_ERROR = 'suburban/analytics/GET_DASH_CLIENTS_ERROR'

export const GET_DASH_CLIENTS_SUBMISSIONS = 'suburban/analytics/GET_DASH_CLIENTS_SUBMISSIONS'
export const GET_DASH_CLIENTS_SUBMISSIONS_SUCCESS = 'suburban/analytics/GET_DASH_CLIENTS_SUBMISSIONS_SUCCESS'
export const GET_DASH_CLIENTS_SUBMISSIONS_ERROR = 'suburban/analytics/GET_DASH_CLIENTS_SUBMISSIONS_ERROR'

export const GET_DASH_CLIENTS_ANALYTICS = 'suburban/analytics/GET_DASH_CLIENTS_ANALYTICS'
export const GET_DASH_CLIENTS_ANALYTICS_SUCCESS = 'suburban/analytics/GET_DASH_CLIENTS_ANALYTICS_SUCCESS'
export const GET_DASH_CLIENTS_ANALYTICS_ERROR = 'suburban/analytics/GET_DASH_CLIENTS_ANALYTICS_ERROR'

export const GET_DASH_DEALS_ANALYTICS = 'suburban/analytics/GET_DASH_DEALS_ANALYTICS'
export const GET_DASH_DEALS_ANALYTICS_SUCCESS = 'suburban/analytics/GET_DASH_DEALS_ANALYTICS_SUCCESS'
export const GET_DASH_DEALS_ANALYTICS_ERROR = 'suburban/analytics/GET_DASH_DEALS_ANALYTICS_ERROR'

export const GET_OPERATION_LIST = 'suburban/dashboard/GET_OPERATION_LIST'
export const GET_OPERATION_LIST_SUCCESS = 'suburban/dashboard/GET_OPERATION_LIST_SUCCESS'
export const GET_OPERATION_LIST_ERROR = 'suburban/dashboard/GET_OPERATION_LIST_ERROR'

export const GET_LOGIN_RECORDS = 'suburban/dashboard/GET_LOGIN_RECORDS'
export const GET_LOGIN_RECORDS_SUCCESS = 'suburban/dashboard/GET_LOGIN_RECORDS_SUCCESS'
export const GET_LOGIN_RECORDS_ERROR = 'suburban/dashboard/GET_LOGIN_RECORDS_ERROR'

export const GET_STRATEGIST_STATISTICS = 'suburban/dashboard/GET_STRATEGIST_STATISTICS'
export const GET_STRATEGIST_STATISTICS_SUCCESS = 'suburban/dashboard/GET_STRATEGIST_STATISTICS_SUCCESS'
export const GET_STRATEGIST_STATISTICS_ERROR = 'suburban/dashboard/GET_STRATEGIST_STATISTICS_ERROR'

export const GET_AGENT_STATISTICS = 'suburban/dashboard/GET_AGENT_STATISTICS'
export const GET_AGENT_STATISTICS_SUCCESS = 'suburban/dashboard/GET_AGENT_STATISTICS_SUCCESS'
export const GET_AGENT_STATISTICS_ERROR = 'suburban/dashboard/GET_AGENT_STATISTICS_ERROR'

export const GET_STRATEGIST_LIST = 'suburban/dashboard/GET_STRATEGIST_LIST'
export const GET_STRATEGIST_LIST_SUCCESS = 'suburban/dashboard/GET_STRATEGIST_LIST_SUCCESS'
export const GET_STRATEGIST_LIST_ERROR = 'suburban/dashboard/GET_STRATEGIST_LIST_ERROR'

export const GET_AGENT_LIST = 'suburban/dashboard/GET_AGENT_LIST'
export const GET_AGENT_LIST_SUCCESS = 'suburban/dashboard/GET_AGENT_LIST_SUCCESS'
export const GET_AGENT_LIST_ERROR = 'suburban/dashboard/GET_AGENT_LIST_ERROR'

export const GET_NO_INITIAL_CALL = 'suburban/dashboard/GET_NO_INITIAL_CALL'
export const GET_NO_INITIAL_CALL_SUCCESS = 'suburban/dashboard/GET_NO_INITIAL_CALL_SUCCESS'
export const GET_NO_INITIAL_CALL_ERROR = 'suburban/dashboard/GET_NO_INITIAL_CALL_ERROR'

export const GET_NO_INITIAL_TOUR = 'suburban/dashboard/GET_NO_INITIAL_TOUR'
export const GET_NO_INITIAL_TOUR_SUCCESS = 'suburban/dashboard/GET_NO_INITIAL_TOUR_SUCCESS'
export const GET_NO_INITIAL_TOUR_ERROR = 'suburban/dashboard/GET_NO_INITIAL_TOUR_ERROR'

export const GET_QUESTIONNAIRES_COMPLETED_REPORT = 'suburban/dashboard/GET_QUESTIONNAIRES_COMPLETED_REPORT'
export const GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS = 'suburban/dashboard/GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS'
export const GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR = 'suburban/dashboard/GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR'

export const GET_APPOINTMENTS_SCHEDULED_REPORT = 'suburban/dashboard/GET_APPOINTMENTS_SCHEDULED_REPORT'
export const GET_APPOINTMENTS_SCHEDULED_REPORT_SUCCESS = 'suburban/dashboard/GET_APPOINTMENTS_SCHEDULED_REPORT_SUCCESS'
export const GET_APPOINTMENTS_SCHEDULED_REPORT_ERROR = 'suburban/dashboard/GET_APPOINTMENTS_SCHEDULED_REPORT_ERROR'


export const GET_CLIENTS_PER_AGENTS = 'suburban/dashboard/GET_CLIENTS_PER_AGENTS'
export const GET_CLIENTS_PER_AGENTS_SUCCESS = 'suburban/dashboard/GET_CLIENTS_PER_AGENTS_SUCCESS'
export const GET_CLIENTS_PER_AGENTS_ERROR = 'suburban/dashboard/GET_CLIENTS_PER_AGENTS_ERROR'

export const GET_NOT_TOUCHED_IN_4_WEEK = 'suburban/dashboard/GET_NOT_TOUCHED_IN_4_WEEK'
export const GET_NOT_TOUCHED_IN_4_WEEK_SUCCESS = 'suburban/dashboard/GET_NOT_TOUCHED_IN_4_WEEK_SUCCESS'
export const GET_NOT_TOUCHED_IN_4_WEEK_ERROR = 'suburban/dashboard/GET_NOT_TOUCHED_IN_4_WEEK_ERROR'

export const GET_ACCOUNTING_REPORT = 'suburban/dashboard/GET_ACCOUNTING_REPORT'
export const GET_ACCOUNTING_REPORT_SUCCESS = 'suburban/dashboard/GET_ACCOUNTING_REPORT_SUCCESS'
export const GET_ACCOUNTING_REPORT_ERROR = 'suburban/dashboard/GET_ACCOUNTING_REPORT_ERROR'


export const GET_STRATEGIST_TIMESHEET_REPORT = 'suburban/analytics/GET_STRATEGIST_TIMESHEET_REPORT'
export const GET_STRATEGIST_TIMESHEET_REPORT_SUCCESS = 'suburban/analytics/GET_STRATEGIST_TIMESHEET_REPORT_SUCCESS'
export const GET_STRATEGIST_TIMESHEET_REPORT_ERROR = 'suburban/analytics/GET_STRATEGIST_TIMESHEET_REPORT_ERROR'

export const GET_BUSIENESS_DEVELOPMENT_REPORT = 'suburban/analytics/GET_BUSIENESS_DEVELOPMENT_REPORT'
export const GET_BUSIENESS_DEVELOPMENT_REPORT_SUCCESS = 'suburban/analytics/GET_BUSIENESS_DEVELOPMENT_REPORT_SUCCESS'
export const GET_BUSIENESS_DEVELOPMENT_REPORT_ERROR = 'suburban/analytics/GET_BUSIENESS_DEVELOPMENT_REPORT_ERROR'


export const CLEAR_USER_ERROR = 'suburban/analytics/CLEAR_USER_ERROR'
export const CLEAR_PHASE = 'suburban/analytics/CLEAR_PHASE'


/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  userPhase: INIT,
  chatPhase: INIT,
  dashclientPhase: INIT,
  clientPhase: INIT,
  clientAnalyticsPhase: INIT,
  dealAnalyticsPhase: INIT,
  operationListPhase: INIT,
  getLoginRecordsPhase:INIT,
  agentPhase: INIT,
  strategistPhase: INIT,
  strategistStatisticsPhase: INIT,
  strategistStatisticsData: [],
  agentStatisticsPhase: INIT,
  initialCallPhase: INIT,
  initialTourPhase: INIT,
  notTouchedIn4WeekPhase: INIT,
  notTouchedIn4WeekData: [],
  initialTourData: [],
  initialCallData: [],
  agentStatisticsData: [],
  clientAnalyticsData: [],
  dealAnalyticsData: [],
  dashClientsData:[],
  operationListData: [],
  loginRecordsData: [],
  strategyData: [],
  agentListData: [],
  dashSubmissionsData:[],
  error: null,
  isSubmitting: false,
  QuestionnairesReportPhase: INIT,
  QuestionnairesData: [],
  AppointmentScheduledReportPhase: INIT,
  AppointmentScheduledData: [],
  clientsPerAgentsPhase: INIT,
  clientsPerAgentsData: [],
  accountingReportPhase: INIT,
  accountingReportData: [],
  strategistTimesheetPhase:INIT,
  strategistTimesheetData:[],
  businessDevelopmentPhase:INIT,
  businessDevelopmentData:[]
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case GET_DASH_CLIENTS: {
      return state
        .set('dashclientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_DASH_CLIENTS_SUCCESS: {
      const { payload } = action
      return state
        .set('dashclientPhase', SUCCESS)
        .set('dashClientsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_DASH_CLIENTS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('dashclientPhase', ERROR)
    }

    case GET_DASH_CLIENTS_SUBMISSIONS: {
      return state
        .set('dashclientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_DASH_CLIENTS_SUBMISSIONS_SUCCESS: {
      const { payload } = action
      return state
        .set('dashclientPhase', SUCCESS)
        .set('dashSubmissionsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_DASH_CLIENTS_SUBMISSIONS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('dashclientPhase', ERROR)
    }

    case GET_DASH_CLIENTS_ANALYTICS: {
      return state
        .set('clientAnalyticsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_DASH_CLIENTS_ANALYTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('clientAnalyticsPhase', SUCCESS)
        .set('clientAnalyticsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_DASH_CLIENTS_ANALYTICS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientAnalyticsPhase', ERROR)
    }

    case GET_DASH_DEALS_ANALYTICS: {
      return state
        .set('dealAnalyticsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_DASH_DEALS_ANALYTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('dealAnalyticsPhase', SUCCESS)
        .set('dealAnalyticsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_DASH_DEALS_ANALYTICS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('dealAnalyticsPhase', ERROR)
    }

    case GET_OPERATION_LIST: {
      return state
        .set('operationListPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_OPERATION_LIST_SUCCESS: {
      const { payload } = action
      return state
        .set('operationListPhase', SUCCESS)
        .set('operationListData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_OPERATION_LIST_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('operationListPhase', ERROR)
    }
    
    case GET_LOGIN_RECORDS: {
      return state
        .set('getLoginRecordsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_LOGIN_RECORDS_SUCCESS: {
      const { payload } = action
      return state
        .set('getLoginRecordsPhase', SUCCESS)
        .set('loginRecordsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_LOGIN_RECORDS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('getLoginRecordsPhase', ERROR)
    }

    case GET_STRATEGIST_STATISTICS: {
      return state
        .set('strategistStatisticsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_STRATEGIST_STATISTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistStatisticsPhase', SUCCESS)
        .set('strategistStatisticsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_STRATEGIST_STATISTICS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistStatisticsPhase', ERROR)
    }

    case GET_AGENT_STATISTICS: {
      return state
        .set('agentStatisticsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_STATISTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('agentStatisticsPhase', SUCCESS)
        .set('agentStatisticsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_STATISTICS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentStatisticsPhase', ERROR)
    }

    case GET_STRATEGIST_LIST: {
      return state
        .set('strategistPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_STRATEGIST_LIST_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistPhase', SUCCESS)
        .set('strategyData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_STRATEGIST_LIST_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistPhase', ERROR)
    }

    case GET_AGENT_LIST: {
      return state
        .set('agentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_LIST_SUCCESS: {
      const { payload } = action
      return state
        .set('agentPhase', SUCCESS)
        .set('agentListData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_LIST_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentPhase', ERROR)
    }

    case GET_NO_INITIAL_CALL: {
      return state
        .set('initialCallPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_NO_INITIAL_CALL_SUCCESS: {
      const { payload } = action
      return state
        .set('initialCallPhase', SUCCESS)
        .set('initialCallData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_NO_INITIAL_CALL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('initialCallPhase', ERROR)
    }

    case GET_NOT_TOUCHED_IN_4_WEEK: {
      return state
        .set('notTouchedIn4WeekPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_NOT_TOUCHED_IN_4_WEEK_SUCCESS: {
      const { payload } = action
      return state
        .set('notTouchedIn4WeekPhase', SUCCESS)
        .set('notTouchedIn4WeekData', payload.data)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_NOT_TOUCHED_IN_4_WEEK_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('notTouchedIn4WeekPhase', ERROR)
    }


    case GET_NO_INITIAL_TOUR: {
      return state
        .set('initialTourPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_NO_INITIAL_TOUR_SUCCESS: {
      const { payload } = action
      return state
        .set('initialTourPhase', SUCCESS)
        .set('initialTourData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_NO_INITIAL_TOUR_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('initialTourPhase', ERROR)
    }

    case GET_QUESTIONNAIRES_COMPLETED_REPORT: {
      return state
        .set('QuestionnairesReportPhase', LOADING)
    }

    case GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('QuestionnairesReportPhase', SUCCESS)
        .set('QuestionnairesData', payload)
    }

    case GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('QuestionnairesData', [])
        .set('QuestionnairesReportPhase', ERROR)
    }

    case GET_APPOINTMENTS_SCHEDULED_REPORT: {
      return state
        .set('AppointmentScheduledReportPhase', LOADING)
    }

    case GET_APPOINTMENTS_SCHEDULED_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('AppointmentScheduledReportPhase', SUCCESS)
        .set('AppointmentScheduledData', payload)
    }

    case GET_APPOINTMENTS_SCHEDULED_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('AppointmentScheduledData', [])
        .set('AppointmentScheduledReportPhase', ERROR)
    }

    case GET_CLIENTS_PER_AGENTS: {
      return state
        .set('clientsPerAgentsPhase', LOADING)
        .set('clientsPerAgentsData',[])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLIENTS_PER_AGENTS_SUCCESS: {
      const { payload } = action
      return state
        .set('clientsPerAgentsPhase', SUCCESS)
        .set('clientsPerAgentsData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLIENTS_PER_AGENTS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientsPerAgentsPhase', ERROR)
    }

    case GET_ACCOUNTING_REPORT: {
      return state
        .set('accountingReportPhase', LOADING)
        .set('accountingReportData',[])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_ACCOUNTING_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('accountingReportPhase', SUCCESS)
        .set('accountingReportData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_ACCOUNTING_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('accountingReportPhase', ERROR)
    }

    case GET_STRATEGIST_TIMESHEET_REPORT: {
      return state
        .set('strategistTimesheetPhase', LOADING)
        .set('strategistTimesheetData',[])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_STRATEGIST_TIMESHEET_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('strategistTimesheetPhase', SUCCESS)
        .set('strategistTimesheetData', payload.data)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_STRATEGIST_TIMESHEET_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('strategistTimesheetPhase', ERROR)
    }
    
    case GET_BUSIENESS_DEVELOPMENT_REPORT: {
      return state
        .set('businessDevelopmentPhase', LOADING)
        .set('businessDevelopmentData',[])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_BUSIENESS_DEVELOPMENT_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('businessDevelopmentPhase', SUCCESS)
        .set('businessDevelopmentData', payload.data)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_BUSIENESS_DEVELOPMENT_REPORT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('businessDevelopmentPhase', ERROR)
    }

    case CLEAR_PHASE: {
        return state
        .set('chatPhase', INIT)
        .set('clientsPerAgentsPhase', INIT)
        .set('accountingReportPhase', INIT)
        .set('strategistTimesheetPhase', INIT)
        .set('businessDevelopmentPhase', INIT)
        .set('agentStatisticsPhase', INIT)
        .set('getLoginRecordsPhase', INIT)
        .set('initialCallPhase', INIT)
        .set('initialTourPhase', INIT)
        .set('notTouchedIn4WeekPhase', INIT)
        .set('strategistStatisticsPhase', INIT)
    }
    
    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const dashboardClients = (data) => ({
  type: GET_DASH_CLIENTS,
  payload: data
})

export const analyticsSubmissions = (data) => ({
  type: GET_DASH_CLIENTS_SUBMISSIONS,
  payload: data
})

export const analyticsClients = (data) => ({
  type: GET_DASH_CLIENTS_ANALYTICS,
  payload: data
})

export const analyticsDeals = (data) => ({
  type: GET_DASH_DEALS_ANALYTICS,
  payload: data
})
export const clearPhase = () =>{
  return {
      type: CLEAR_PHASE
  }
}

export const getOperationList = (data) => ({
  type: GET_OPERATION_LIST,
  payload: data
})

// Getting the users Login records 

export const getLoginRecords = (data) => ({
  type: GET_LOGIN_RECORDS,
  payload: data
})

export const getStrategistStatistics = (data) => ({
  type: GET_STRATEGIST_STATISTICS,
  payload: data
})

export const getClientsPerAgents = (data) => ({
  type: GET_CLIENTS_PER_AGENTS,
  payload: data
})

export const getAgentStatistics = (data) => ({
  type: GET_AGENT_STATISTICS,
  payload: data
})

export const getNotTouchedIn4WeekData = (data) => ({
  type: GET_NOT_TOUCHED_IN_4_WEEK,
  payload: data
})

export const getStrategistList = (data) => ({
  type: GET_STRATEGIST_LIST,
  payload: data
})

export const getAgentList = (data) => ({
  type: GET_AGENT_LIST,
  payload: data
})

export const getNoInitialCall = (data) => ({
  type: GET_NO_INITIAL_CALL,
  payload: data
})

export const getNoInitialTour = (data) => ({
  type: GET_NO_INITIAL_TOUR,
  payload: data
})

export const getQuestionnairesCompletedClientList = (data) => ({
  type: GET_QUESTIONNAIRES_COMPLETED_REPORT,
  payload: data
})

export const getAppointmentsScheduledClientList = (data) => ({
  type: GET_APPOINTMENTS_SCHEDULED_REPORT,
  payload: data
})

export const getAccountingReportData = (data) => ({
  type: GET_ACCOUNTING_REPORT,
  payload: data
})

export const getStrategistTimesheetData = (data) => ({
  type: GET_STRATEGIST_TIMESHEET_REPORT,
  payload: data
})

export const getBusinessDevelopmentData = (data) => ({
  type: GET_BUSIENESS_DEVELOPMENT_REPORT,
  payload: data
})
/**
 * Private: Epics
 */

  const dashboardClientsEpic = (action$) =>
  action$
  .ofType(GET_DASH_CLIENTS)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.dashboardClients(action.payload))
    .flatMap((payload) => ([{
      type: GET_DASH_CLIENTS_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_DASH_CLIENTS_ERROR,
      payload: { error }
    }))
  })
  
  const analyticsSubmissionsEpic = (action$) =>
  action$
  .ofType(GET_DASH_CLIENTS_SUBMISSIONS)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.analyticsSubmissions(action.payload))
    .flatMap((payload) => ([{
      type: GET_DASH_CLIENTS_SUBMISSIONS_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_DASH_CLIENTS_SUBMISSIONS_ERROR,
      payload: { error }
    }))
  })

  const  analyticsClientsEpic = (action$) =>
  action$
  .ofType(GET_DASH_CLIENTS_ANALYTICS)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.analyticsClients(action.payload))
    .flatMap((payload) => ([{
      type: GET_DASH_CLIENTS_ANALYTICS_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_DASH_CLIENTS_ANALYTICS_ERROR,
      payload: { error }
    }))
  })

  const analyticsDealsEpic = (action$) =>
  action$
  .ofType(GET_DASH_DEALS_ANALYTICS)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.analyticsDeals(action.payload))
    .flatMap((payload) => ([{
      type: GET_DASH_DEALS_ANALYTICS_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_DASH_DEALS_ANALYTICS_ERROR,
      payload: { error }
    }))
  })

const getOperationListEpic = (action$) =>
action$
.ofType(GET_OPERATION_LIST)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getOperationList(action.payload))
  .flatMap((payload) => ([{
    type: GET_OPERATION_LIST_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_OPERATION_LIST_ERROR,
    payload: { error }
  }))
})

const getLoginRecordsEpic = (action$) =>
action$
.ofType(GET_LOGIN_RECORDS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getLoginRecords(action.payload))
  .flatMap((payload) => ([{
    type: GET_LOGIN_RECORDS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_LOGIN_RECORDS_ERROR,
    payload: { error }
  }))
})

const getStrategistListEpic = (action$) =>
action$
.ofType(GET_STRATEGIST_LIST)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategistList(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGIST_LIST_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGIST_LIST_ERROR,
    payload: { error }
  }))
})
  
const getAgentListEpic = (action$) =>
action$
.ofType(GET_AGENT_LIST)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAgentList(action.payload))
  .flatMap((payload) => ([{
    type: GET_AGENT_LIST_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_AGENT_LIST_ERROR,
    payload: { error }
  }))
})

const getNoInitialCallEpic = (action$) =>
action$
.ofType(GET_NO_INITIAL_CALL)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getNoInitialCall(action.payload))
  .flatMap((payload) => ([{
    type: GET_NO_INITIAL_CALL_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_NO_INITIAL_CALL_ERROR,
    payload: { error }
  }))
})

const getNoInitialTourEpic = (action$) =>
action$
.ofType(GET_NO_INITIAL_TOUR)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getNoInitialTour(action.payload))
  .flatMap((payload) => ([{
    type: GET_NO_INITIAL_TOUR_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_NO_INITIAL_TOUR_ERROR,
    payload: { error }
  }))
})

const getQuestionnairesCompletedClientListEpic = (action$) =>
action$
.ofType(GET_QUESTIONNAIRES_COMPLETED_REPORT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getQuestionnairesCompletedClientList(action.payload))
  .flatMap((payload) => ([{
    type: GET_QUESTIONNAIRES_COMPLETED_REPORT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_QUESTIONNAIRES_COMPLETED_REPORT_ERROR,
    payload: { error }
  }))
})

const getAppointmentsScheduledClientListEpic = (action$) =>
action$
.ofType(GET_APPOINTMENTS_SCHEDULED_REPORT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAppointmentsScheduledClientList(action.payload))
  .flatMap((payload) => ([{
    type: GET_APPOINTMENTS_SCHEDULED_REPORT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_APPOINTMENTS_SCHEDULED_REPORT_ERROR,
    payload: { error }
  }))
})

const getStrategistStatisticsEpic = (action$) =>
action$
.ofType(GET_STRATEGIST_STATISTICS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategistStatistics(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGIST_STATISTICS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGIST_STATISTICS_ERROR,
    payload: { error }
  }))
})

const getClientsPerAgentsEpic = (action$) =>
action$
.ofType(GET_CLIENTS_PER_AGENTS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientsPerAgents(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENTS_PER_AGENTS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENTS_PER_AGENTS_ERROR,
    payload: { error }
  }))
})

const getAgentStatisticsEpic = (action$) =>
action$
.ofType(GET_AGENT_STATISTICS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAgentStatistics(action.payload))
  .flatMap((payload) => ([{
    type: GET_AGENT_STATISTICS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_AGENT_STATISTICS_ERROR,
    payload: { error }
  }))
})

const getNotTouchedIn4WeekDataEpic = (action$) =>
action$
.ofType(GET_NOT_TOUCHED_IN_4_WEEK)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getNotTouchedIn4WeekData(action.payload))
  .flatMap((payload) => ([{
    type: GET_NOT_TOUCHED_IN_4_WEEK_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_NOT_TOUCHED_IN_4_WEEK_ERROR,
    payload: { error }
  }))
})

const getAccountingReportDataEpic = (action$) =>
action$
.ofType(GET_ACCOUNTING_REPORT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAccountingReportData(action.payload))
  .flatMap((payload) => ([{
    type: GET_ACCOUNTING_REPORT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_ACCOUNTING_REPORT_ERROR,
    payload: { error }
  }))
})

const getStrategistTimesheetDataEpic = (action$) =>
action$
.ofType(GET_STRATEGIST_TIMESHEET_REPORT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategistTimesheetData(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGIST_TIMESHEET_REPORT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGIST_TIMESHEET_REPORT_ERROR,
    payload: { error }
  }))
})

const getBusinessDevelopmentDataEpic = (action$) =>
action$
.ofType(GET_BUSIENESS_DEVELOPMENT_REPORT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getBusinessDevelopmentData(action.payload))
  .flatMap((payload) => ([{
    type: GET_BUSIENESS_DEVELOPMENT_REPORT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_BUSIENESS_DEVELOPMENT_REPORT_ERROR,
    payload: { error }
  }))
})

/**
 * Public: Export Epics
 */

export const analyticsEpic = combineEpics(
  dashboardClientsEpic,
  analyticsSubmissionsEpic,
  analyticsClientsEpic,
  analyticsDealsEpic,
  getOperationListEpic,
  getLoginRecordsEpic,
  getStrategistListEpic,
  getAgentListEpic,
  getQuestionnairesCompletedClientListEpic,
  getAppointmentsScheduledClientListEpic,
  getStrategistStatisticsEpic,
  getAgentStatisticsEpic,
  getClientsPerAgentsEpic,
  getNoInitialCallEpic,
  getNoInitialTourEpic,
  getNotTouchedIn4WeekDataEpic,
  getAccountingReportDataEpic,
  getStrategistTimesheetDataEpic,
  getBusinessDevelopmentDataEpic
)
