import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'

/* 
    /////////////////////
  /// chat epic ///
/////////////////////
*/

// featch client agent list
const fetchClientAgentEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_AGENT_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientAgent(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_AGENT_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_AGENT_LIST_ERROR,
          payload: { error },
        })
      )
  })
// fetch client agent chat data
const fetchClientAgentChatEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_AGENT_CHAT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientAgentChat(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_AGENT_CHAT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_AGENT_CHAT_ERROR,
          payload: { error },
        })
      )
  })
// fetch client chat to show on client detail show chat option
const fetchClientChatEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_CHAT_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientChat(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_CHAT_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_CHAT_LIST_ERROR,
          payload: { error },
        })
      )
  })
// get chat history between sender and reciver
const getChatHistoryEpic = (action$) =>
  action$.ofType(types.GET_CHAT_HISTORY).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getChatHistory(action.payload))
      .map((payload) => ({
        type: types.GET_CHAT_HISTORY_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_CHAT_HISTORY_ERROR,
          payload: { error },
        })
      )
  })
// send text messge from client to agent or strategist
const sendChatMessageEpic = (action$) =>
  action$.ofType(types.SEND_CHAT_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.sendChatMessage(action.payload))
      .map((payload) => ({
        type: types.SEND_CHAT_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.SEND_CHAT_MESSAGE_ERROR,
          payload: { error },
        })
      )
  })
// send chat message to agnet and client
const sendAgentMessageEpic = (action$) =>
  action$.ofType(types.SEND_AGENT_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.sendAgentMessage(action.payload))
      .map((payload) => ({
        type: types.SEND_AGENT_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.SEND_AGENT_MESSAGE_ERROR,
          payload: { error },
        })
      )
  })
// fetch client chat to show on client detail show chat option
const fetchClientTextMessageEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_TEXT_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClientTextMessages(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_TEXT_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_TEXT_MESSAGE_ERROR,
          payload: { error },
        })
      )
  })
// fetch client chat to show on client detail show chat option
const sendClientTextMessageEpic = (action$) =>
  action$.ofType(types.SEND_CLIENT_TEXT_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.sendClientTextMessages(action.payload))
      .map((payload) => ({
        type: types.SEND_CLIENT_TEXT_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.SEND_CLIENT_TEXT_MESSAGE_ERROR,
          payload: { error },
        })
      )
  })
// remove client message by message id and store phase to show success
const deleteClientTextMessageEpic = (action$) =>
  action$.ofType(types.DELETE_CLIENT_TEXT_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.deleteClientTextMessages(action.payload))
      .map((payload) => ({
        type: types.DELETE_CLIENT_TEXT_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.DELETE_CLIENT_TEXT_MESSAGE_ERROR,
          payload: { error },
        })
      )
  })

// combine chat epic
export const chatEpic = combineEpics(
  getChatHistoryEpic,
  fetchClientChatEpic,
  fetchClientAgentEpic,
  sendChatMessageEpic,
  sendAgentMessageEpic,
  sendClientTextMessageEpic,
  fetchClientAgentChatEpic,
  fetchClientTextMessageEpic,
  deleteClientTextMessageEpic
)
