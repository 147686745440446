/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { get, map } from 'lodash'
import Cookies from 'universal-cookie'
import { Spin } from 'antd'
import TopNavigation from '../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import ClientInformation from './ClientInformation/container'
import ArtificialIntelligence from './ArtificialIntelligence/container'
import Strategy from './Strategy/container'
import DealInformation from './DealInformation/container'
import ProspectusInformation from './ProspectusInformation/container'
import Other from './Other/container'
import ActivityHistory from './ActivityHistory/container'

import { BackArrowIcon } from '../../../../components/icons'

import './styles.scss'
import ScheduleAppointment from '../Common/ScheduleAppointment/index'
import FollowUpComponent from './ProspectusInformation/FollowUp/container'
import { SUCCESS } from '../../../../constants/phase'
import ScrollToTop from '../../../../components/ScrollToTop'
import Timer from '../Common/Timer'
import Loader from '../../../../components/Loader'

const cookies = new Cookies()
export default class ClientDetailsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      modalIsOpen: false,
      initialData: true,
      clientId: 0,
      isProspectusLoading: false
    }
  }
  componentDidUpdate(preProps, state) {
    // on search and click on client send clientid to render
    const clientId = get(this.props, 'location.state.clientId', false)
    // check fetchClient props is present
    // check new client id is present so that it will not rerender multiple time
    // only on time fetch client details we use initialData for fetching one time data
    if (get(this.props, 'location.state.fetchClient', false) && clientId && (clientId !== state.clientId || state.initialData)) {
      this.setState({ initialData: false, clientId, isLoading: true }, () => {
        this.fetchClientAllDetails()
      })
    }
    // on change of location using browser go back button fetch client details
    if (location.pathname !== preProps.location.pathname) {
      this.setState({ isLoading: true }, () => {
        this.fetchClientAllDetails()
      })
    }
  }
  // on mount or first time compoentent createtion call apis
  componentDidMount() {
    const user = cookies.get('user')
    const clientId = this.props.match.params.id && this.props.match.params.id;
    if (document) document.querySelector('body').scrollTo(0, 0);
    if (clientId) {
      this.setState(
        {
          isLoading: true,
          clientId,
          currentUserId: user.id,
          currentRole: user.role,
          currentUserName: `${user.first_name} ${user.last_name}`
        },
        () => {
          this.props.fetchUser({ user_id: user.id })
          this.props.fetchClientById({ clientId })
          this.props.getFilterParams()
        }
      )
    } else {
      this.props.history.push('/strategist/clients/all-clients')
    }
  }

  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    if (
      props.impersonatePhase === 'success' &&
      props.impersonateData &&
      props.impersonateData.magic_link
    ) {
      const i_user = cookies.get('user')
      const i_Authorization = cookies.get('Authorization')
      let expires = new Date()
      localStorage.clear()
      expires.setMonth(expires.getMonth() + 2)
      expires = new Date(expires)
      cookies.set('i_Authorization', i_Authorization, { path: '/', expires })
      cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires })
      const magicLink = props.impersonateData.magic_link
      const pathname = `/client-login/token?validate=${magicLink}`
      props.clearPhase()
      document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      localStorage.clear()
      window.location.href = pathname
      // props.history.push({ pathname })
    }
    if (props.fetchProspectusByIdPhase === SUCCESS) {
      // clear prospectus phase
      props.clearProspectusPhase()
      states = { ...states, isProspectusLoading: true }
    }
    if (props.fetchClientDetailForDashBoardPhase === SUCCESS) {
      props.clearDashboardPhase()
      states = { ...states, isLoading: false }
    }
    return states
  }
  // fetch client all details with search and change of  location
  fetchClientAllDetails = () => {
    const clientId = get(this.props, 'location.state.clientId', false)
    if (clientId) {
      this.props.fetchClientById({ clientId })
      this.props.fetchFollowUpDate({ clientId })
      this.props.fetchClientAgent({ clientId })
      this.props.getRankingByClientId({ clientId })
      this.props.fetchClientChat({ user_id: clientId })
      this.props.getInteralNotes({ clientId })
      this.props.fetchClientAgentChat({ clientId })
      this.props.fetchClientTextMessage({ clientId })
      this.props.getActivityEmail({ clientId })
      this.props.clearFollowUpPhase()
      // force update to set state and rereander
      this.forceUpdate()
    } else {
      this.props.history.push('/strategist/clients/all-clients')
    }
  }
  // open schedule appointmen modal for open react-modal
  openScheduleAppointmentModal = () => this.setState({ modalIsOpen: true })
  // close schedule appoeintment model close
  closeModal = () => this.setState({ modalIsOpen: false })
  
  // this.props.history.push('/strategist/clients/all-clients')
  handleGoBackPath = () => {
    const prevRoute = get(this.props, 'history.location.state.prevRoute', '')
    // replace previce path for avoid loop
    if (prevRoute !== '') {
      this.props.history.push(prevRoute)
    } else {
      this.props.history.goBack()
    }
  }
  impersonateUser = () => {
    const userId = this.props.match.params.id && this.props.match.params.id
    this.props.impersonateUser({ client_id: userId })
  }

  render() {
    const { isLoading } = this.state
    const user = get(this.props, 'user', {})
    const client = get(this.props, 'clientDetail', {})
    const prospectusList = get(this.props, 'prospectus.data', [])
    return (
      <div className="client__details">
        <ScrollToTop {...this.props}/>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
          {/* <Spin spinning={isLoading} indicator={<Loader />}> */}
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row client__details__header__row">
                  <div className="col-xs-12 col-md-2">
                    <button className="btn btn-back" onClick={this.handleGoBackPath}>
                      <BackArrowIcon className="arrow-icon" />
                      Back
                    </button>
                  </div>
                  <div className="col-xs-12 col-md-10">
                    <div className="client__details__header__cta">
                      {prospectusList && prospectusList.length > 0 && (
                        <div className="material-textfield w__180">
                          <select className="form-control custom-select material-textfield-input">
                            {map(prospectusList, (prospectus, index) => (
                              <option key={index} value={prospectus.id}>
                                {prospectus.title}
                              </option>
                            ))}
                          </select>
                          <label className="material-textfield-label">Prospectus</label>
                        </div>
                      )}
                      <div className="client__details__header__cta__inline">
                        {/*<Timer user={user} {...this.props}/>*/}
                        <button
                          className="btn btn__btn btn-dark btn__impersonate__user"
                          onClick={() => this.impersonateUser()}
                        >
                          Impersonate User
                        </button>
                        <button
                          className="btn btn__btn btn-dark btn__schedule__appointment"
                          onClick={this.openScheduleAppointmentModal}
                        >
                          Schedule Appointment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ClientInformation {...this.props} />
                  <FollowUpComponent {...this.props} />
                  <div className="mob__client__select">
                    <select className="form-control custom__select">
                      <option value="chat">Prospect Information</option>
                      <option value="chat">Strategy for this deal</option>
                      <option value="deal_information">Deal Information</option>
                      <option value="chat">Other</option>
                      <option value="activity_history">Activity History</option>
                    </select>
                  </div>
                  <ProspectusInformation {...this.props} />
                  <ArtificialIntelligence {...this.props} />
                  <Strategy {...this.props} />
                  {/* <div className="mob__client__select">
                  <select
                    defaultValue={this.state.selectType}
                    onChange={this.selectChange.bind(this)}
                    className="form-control custom__select">
                    <option value="background_profile">Background Profile</option>
                    <option value="deal_information">Deal Information</option>
                    <option value="chat">Chat</option>
                    <option value="activity_history">Activity History</option>
                  </select>
                </div> */}

                  {/* This view only for mobile */}
                  {/* <div className="mob__client__select">
                  {
                     ( _.get(this.context.userData, 'role') !== "local") && (this.state.selectType === "background_profile") &&
                      <BackgroundProfile {...this.props}/>
                  }
                  {
                  ( _.get(this.context.userData, 'role') === "agent") && (this.state.selectType === "deal_information") &&
                    <DealInformation
                      {...this.props}
                      openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection}
                    />
                  }
                  {
                    (this.state.selectType === "chat") &&
                      <Chats {...this.props}/>
                  }
                  {
                    (this.state.selectType === "activity_history") &&
                      <ActivityHistory {...this.props}/>
                  }
                </div> */}
                  {/* End for mobile view */}
                  {/* View only for desktop */}
                  <div className="desktop__view">
                    <DealInformation {...this.props} />
                    <Other {...this.props} />
                    <ActivityHistory {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          {/* </Spin> */}
          </main>
        </div>
        {this.state.modalIsOpen && (
          <ScheduleAppointment
            client={{
              clientId: client.client_id,
              name: client.client_full_name,
              strategistId: get(client, 'strategists[0].user_id', '')
            }}
            isAppointmentModelOpen={this.state.modalIsOpen}
            closeModal={this.closeModal}
            {...this.props}
          />
        )}
      </div>
    )
  }
}
