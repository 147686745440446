import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import moment from "moment";
import $ from "jquery";
import _ from 'lodash'
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import ShowMore from "react-show-more";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button className="form-control custom-select date__picker" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AgentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: moment(),
      town_list: [],
      suburb_list: [],
      townDropdown: false,
      suburbDropdown: false,
      search: ""
    };
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      const data = {}
      data.user_id = _user.id
      const agentData = {}
      agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.town_arr = this.state.town_list
      agentData.suburbs = this.state.suburb_list
      this.props.getAgentList(agentData);
      this.props.getFilterParams()
      this.setState({ isLoadingData: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agentPhase === "success") {
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount() {
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ townDropdown: false, suburbDropdown: false });
        }
      }
    });
  }

  handleFromDate(date) {
    this.setState({ isLoadingData: true })
    let selectDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(
      function(e) {
        const agentData = {}
        agentData.from = selectDate
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.town_arr = this.state.town_list
        agentData.suburbs = this.state.suburb_list
        this.props.getAgentList(agentData);
      }.bind(this),
      300
    );
    this.setState({ fromDate: date });
  }

  handleEndDate(date) {
    this.setState({ isLoadingData: true })
    let selectDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(
      function(e) {
        const agentData = {};
        agentData.to = selectDate
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.town_arr = this.state.town_list
        agentData.suburbs = this.state.suburb_list
        this.props.getAgentList(agentData)
      }.bind(this),
      300
    );
    this.setState({ endDate: date })
  }

  toggledropDown(val) {
    if (val === "filterTowns") {
      this.setState({ townDropdown: !this.state.townDropdown, suburbDropdown: false });
    }
    if (val === "filterSuburb") {
      this.setState({ suburbDropdown: !this.state.suburbDropdown, townDropdown: false });
    }
  }

  filterStrategist(id, type) {
    let { town_list, suburb_list } = this.state;
    if (type === "townFilter") {
      if (town_list.indexOf(id) === -1) {
        town_list.push(id);
      } else {
        town_list.splice(town_list.indexOf(id), 1);
      }
    }
    if (type === "suburbFilter") {
      if (suburb_list.indexOf(id) === -1) {
        suburb_list.push(id);
      } else {
        suburb_list.splice(suburb_list.indexOf(id), 1);
      }
    }

    this.setState({ isLoadingData: true, town_list: town_list, suburb_list: suburb_list });
    const agentData = {};
    agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    agentData.town_arr = town_list
    agentData.suburbs = suburb_list
    this.props.getAgentList(agentData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    const { agentListData, filterParams } = this.props;
    let allData = filterParams && filterParams && filterParams.data;

    let filterList = '';
    let allTownData = agentListData && agentListData.data
    if(allTownData){
      filterList = allTownData.filter(
        (allTownData) =>{
          return (
            (allTownData.full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    return (
      <div>
        <div className="full__page--header light-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-xs-10">
                <div className="logo-header">
                  <a>
                    <span className="reporting-title">Agent Reporting</span>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-xs-2">
                <div className="close-btn close__reports">
                  <Link to={"/dashboard"}>
                    <img src="img/close.svg" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid padd-l-r-50">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{agentListData && agentListData.total_count? agentListData.total_count: 0}</span> Agents
              </h4>
            </div>
            <div className="col-md-12 text-right">
              
              <div className="dp-wrapper dp-wrapper-edit hide">
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control custom-select date__picker"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dp-wrapper dp-wrapper-edit hide">
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control custom-select date__picker"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <ExcelFile element={<button className="reporting__btn">Export Data</button>}>
                <ExcelSheet data={filterList} name="Agent_Report">
                  <ExcelColumn label="Agent Name" value="full_name" />
                  <ExcelColumn label="Clients Assigned" value="client_assigned" />
                  <ExcelColumn label="Active Clients" value="active_clients" />
                  <ExcelColumn label="Closed Clients" value="closed_clients" />
                  <ExcelColumn label="Calls Scheduled" value="call_scheduled" />
                </ExcelSheet>
              </ExcelFile>
              
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">

              <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search"/>
              </span>

                <div>
                  <div className="filter__sub--container filter__sub--container-edit">

                    <li id="dropdownBtn">
                      <div className="custom-select--all" onClick={this.toggledropDown.bind(this, "filterTowns")}>
                        Filter by Towns
                      </div>
                      {this.state.townDropdown && (
                        <div className="sel-outer sel-outer-edit">
                          {allData &&
                            allData.towns &&
                            allData.towns.map((town, townIndex) => {
                              let selectedIndex = false;
                              _.find(this.state.town_list, function(o) {
                                if (o === town.id) {
                                  selectedIndex = true;
                                }
                              });
                              return (
                                <li key={townIndex} className="custom-select-option">
                                  <span className="coverage__checkbox">
                                    <label className="custom-checkbox">
                                      {town.name}
                                      <input type="checkbox" checked={selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this, town.id, "townFilter")} />
                                      <span className="checkmark" />
                                    </label>
                                  </span>
                                </li>
                              );
                            })}
                        </div>
                      )}
                    </li>

                    <li id="dropdownBtn">
                      <div id="dropdownBtn" className="custom-select--all" onClick={this.toggledropDown.bind(this, "filterSuburb")}>
                        Filter by Suburb
                      </div>
                      {this.state.suburbDropdown && (
                        <div className="sel-outer sel-outer-edit">
                          {allData &&
                            allData.suburbs.map((suburbsval, suburbsindx) => {
                              let selectedIndex = false;
                              _.find(this.state.suburb_list, function(o) {
                                if (o === suburbsval.id) {
                                  selectedIndex = true;
                                }
                              });
                              return (
                                <li key={suburbsindx} className="custom-select-option">
                                  <span className="coverage__checkbox">
                                    <label className="custom-checkbox">
                                      {suburbsval.name}
                                      <input type="checkbox" checked={selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this, suburbsval.id, "suburbFilter")} />
                                      <span className="checkmark" />
                                    </label>
                                  </span>
                                </li>
                              );
                            })}
                        </div>
                      )}
                    </li>
                </div>

                    <div className="jungler__list useslist__extra">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                             {/* <th>Agent Name</th>
                              <th>Firm Name</th>
                              <th>Towns Coverd</th>
                              <th># Clients Assigned</th>
                              <th># Calls Scheduled</th>
                              <th># Initial Tours</th>
                              <th># Deals</th>*/}
                              <th>Agent Name</th>
                              <th className="text-center">Clients Assigned</th>
                              <th className="text-center">Active Clients</th>
                              <th className="text-center">Closed Clients</th>
                              <th className="text-center">Calls Scheduled</th>
                            </tr>
                          </thead>
                          {this.state.isLoadingData === true ? (
                            <tbody>
                              <tr>
                                <td colSpan="7" className="noClientResults text-center">
                                  <img src="../../img/loader2.svg" />
                                  <h5>Your Agent report is generating, please wait.........</h5>
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {filterList &&
                                filterList.map((agent, index) => {
                                  return (
                                    <tr key={index} className="forcursor">
                                      <td>{agent.full_name ? agent.full_name : "N/A"}</td>
                                      <td className="text-center">{agent.client_assigned}</td>

                                      <td className="text-center">{agent.active_clients}</td>
                                      <td className="text-center">{agent.closed_clients}</td>
                                      <td className="text-center">{agent.call_scheduled}</td>
                                    </tr>
                                    /*<tr key={index} className="forcursor">
                                      <td>{agent.full_name ? agent.full_name : "N/A"}</td>
                                      <td>{agent.firm_name ? agent.firm_name : "N/A"}</td>
                                      <td>
                                        <ShowMore lines={2} more="View more" less="View less" anchorClass="ShowMoreToggle">
                                          {agent.towns_covered &&
                                            agent.towns_covered.map((town, idx) => {
                                              return <span key={idx}>{(idx ? ", " : "") + town.name}</span>;
                                            })}
                                        </ShowMore>
                                      </td>
                                      <td>{agent.client_assigned ? agent.client_assigned : "N/A"}</td>
                                      <td>{agent.call_scheduled ? agent.call_scheduled : "N/A"}</td>
                                      <td>{agent.initial_tours ? agent.initial_tours : "N/A"}</td>
                                      <td>{agent.deals ? agent.deals : "N/A"}</td>
                                    </tr>*/
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  
                </div>
              </main>

              {
                filterList && filterList.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler -Agent Reporting</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }
            
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "agentreportlist", // a unique identifier for this form
  destroyOnUnmount: true
})(AgentReport);
