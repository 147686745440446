import { connect } from 'react-redux'
import {fetchUser, handleSignOut, getNotifications, clearNotification} from '../../store/user/duck'
import { getClientStrategist, getClientAgent, scheduleMeeting, getClientStrategistData, getClientStrategistAvaillability, clearDatePhase } from '../../store/client/duck'
import { getAgentAvailability, createAgentAppointment, clearPhase, clearAgentDatePhase } from '../../store/agent/duck'
import ClientsForm from './component'
const Clients = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    profilePhase: state.user.profilePhase,
    message: state.user.message,
    userUpdatePhase: state.user.userUpdatePhase,
    strategistData: state.client.strategistData,
    getStrategistData: state.client.getStrategistData,
    getStrategistDataPhase: state.client.getStrategistDataPhase,
    submitSchedulePhase: state.client.submitSchedulePhase,
    getStrategistAvaillabilityData: state.client.getStrategistAvaillabilityData,
    getStrategistAvaillabilityPhase: state.client.getStrategistAvaillabilityPhase,
    resMessage: state.client.resMessage,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase,
    availabilityData: state.agent.availabilityData,
    agentAppPhase: state.agent.agentAppPhase,
    availabilityPhase: state.agent.availabilityPhase,
    agentData: state.client.agentData,
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getClientStrategist,
    getClientAgent,
    scheduleMeeting,
    getNotifications,
    clearNotification,
    getAgentAvailability,
    createAgentAppointment,
    getClientStrategistData,
    clearPhase,
    getClientStrategistAvaillability,
    clearDatePhase,
    clearAgentDatePhase
  }
)(ClientsForm)
export default Clients
