import React, { Fragment, useEffect } from 'react'
import moment from 'moment'
import { map } from 'lodash'
import { Collapse } from 'antd'
import ReactHtmlParser from 'react-html-parser'

const { Panel } = Collapse
import { DownCaretIcon } from '../../../../../components/icons'

export default function EmailPanelList(props) {
	useEffect(() => {
		const clientId = props.match.params.id
		// eslint-disable-next-line camelcase
		props.getActivityEmail({ clientId })
	}, [])
	return (
		<Collapse
			bordered={false}
			accordion
			defaultActiveKey={
				props.activityEmails && props.activityEmails.length > 0
					? map(
							props.activityEmails,
							(activityEmail, index) => `${index + 1}`
					  )
					: ['1']
			}
			expandIconPosition={'right'}
			expandIcon={({ isActive }) => (
				<DownCaretIcon rotate={isActive ? 90 : 0} />
			)}
			className="site-collapse-custom-collapse emails__custom__collapse"
		>
			{props.activityEmails && props.activityEmails.length > 0 ? (
				map(props.activityEmails, (activityEmail, index) => {
					const emailSubHeading = activityEmail.mail_subject
					const email = activityEmail.note.split('<body')
					const removedTags =
						email.length > 1
							? `<body${activityEmail.note.split('<body')[1]}`.replace(
									'</html>',
									''
							  )
							: activityEmail.note
					return (
						<Panel
							header={
								<div className="emails__header">
									<h4 className="email__title">
										{activityEmail.mail_subject ? activityEmail.mail_subject : ""}
									</h4>
									<h6 className="email__emailinfo">
										{activityEmail.from_name
											? activityEmail.from_name
											: activityEmail.author_full_name}
									</h6>
									<h6 className="email__time">
										{/*{moment(activityEmail.modified).format(*/}
										{moment(activityEmail.created).format(
											'llll'
										)}{' '}
										(
										{moment(
											activityEmail.modified
										).fromNow()}
										)
									</h6>
								</div>
							}
							key={`${index + 1}`}
							className="site-collapse-custom-panel"
						>
							<p>{ReactHtmlParser(removedTags)}</p>
						</Panel>
					)
				})
			) : (
				<Panel
					showArrow={false}
					header={
						<div className="emails__header">
							<h4 className="email__title">No Email Found</h4>
						</div>
					}
					key="1"
				>
					<p>
						This is where you would see a history of the emails sent
						to the client and received from the client.
						<br />
						There are no email records synced into the Jungler as of
						yet.
						<br />
						Please check back later.
					</p>
				</Panel>
			)}
		</Collapse>
	)
}
