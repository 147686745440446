import React, { PureComponent /*, PropTypes*/ } from 'react';
import { Formik, Form, Field } from 'formik';
import get from 'lodash/get'
// import IPropTypes from 'react-immutable-proptypes'

import './styles.scss';

let willingToCommute = [
  { label: 'Up to 30 minutes', value: 'commute_30_to_less' },
  { label: 'Up to 45 minutes', value: 'commute_30_to_45' },
  { label: 'Up to 60 minutes', value: 'commute_45_to_60' },
  { label: '1 hr +', value: 'commute_60_to_high' }
];

let transportation = [
  { label: 'Train', value: 'trans_mode_train' },
  { label: 'Bus', value: 'trans_mode_bus' },
  { label: 'Car', value: 'trans_mode_car' },
  { label: 'Walk', value: 'trans_mode_walk' },
  { label: 'Bike', value: 'trans_mode_bike' },
  { label: 'Ferry', value: 'trans_mode_ferry' }
];

const CheckboxInputField = props => {
  return (
    <Field
      {...props}
      checked={get(props, `values.${props.name}`, 0) === 1 ? true : false}
      onChange={e => {
        props.setfieldvalue(props.name, e.target.checked ? 1 : 0);
      }}
    />
  );
};
export default class CommuterCharacteristicsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    // PropTypes go here
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">Commute Info</h1>
          </div>
          <Formik
            enableReinitialize
            initialValues={get(this, "props.aiData", {})}
            onSubmit={data => {
              this.props.updateAiData(data)
            }}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form>
                <div className="col-md-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Willing to commute to work{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          {willingToCommute.map((c, i) => {
                            return (
                              <li key={i}>
                                <label className="custom__checkbox">
                                  {c.label}
                                  <CheckboxInputField
                                    type="checkbox"
                                    name={c.value}
                                    values={values}
                                    setfieldvalue={setFieldValue}
                                    className="custom__checkbox__input"
                                  />
                                  <span className="custom__checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Transportation Types{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          {transportation.map((c, i) => {
                            return (
                              <li key={i}>
                                <label className="custom__checkbox">
                                  {c.label}
                                  <CheckboxInputField
                                    type="checkbox"
                                    name={c.value}
                                    values={values}
                                    setfieldvalue={setFieldValue}
                                    className="custom__checkbox__input"
                                  />
                                  <span className="custom__checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn__btn btn-dark btn__lg w__100"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
