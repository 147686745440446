/* eslint-disable camelcase */
import React, { Component } from 'react';
import { get, map } from 'lodash';
import moment from 'moment';
import { DatePicker } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

import { DownCaretIcon, CopyIcon } from "../../../../../../components/icons"
import { SUCCESS } from '../../../../../../constants/phase';

export default class ClientList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      selectOutIndex: null,
      selectInEmails: false,
      clientsEmails: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.updateFollowUpDatePhase === SUCCESS) {
      props.clearFollowUpPhase()
      props.fetchFilterResult()
    }
    return state
  }

  handleDateChange = (clientId, strategistId, date) => {
    const data = {
      clientId,
      date: moment(date).format("YYYY-MM-DD"),
      strategistId
    }
    this.props.updateFollowUpDate(data)
  }

  formatPriceRange = (client) => {
    const rangestr = client && client.price_range;
    if (rangestr.match(/\-/)) {
      const rangearr = rangestr.split('-');
      return `$${parseInt(rangearr[0]).toLocaleString()} - $${parseInt(rangearr[1]).toLocaleString()}`;
    } else if (rangestr.match(/\+/)) {
      const rangearr = rangestr.split('+');
      return `$${parseInt(rangearr[0]).toLocaleString()}`
    }
    return ''
  }

  goToClientDetailPage = (client, currentUser) => {
    if (!this.props.clientIsReadOnly(client, currentUser)) {
      this.props.history.push({
        pathname: `/strategist/client-details/${client.client_id}`,
        state: { client, prevRoute: this.props.history.location.pathname }
      })
    }
  }

  disabledDate = (current) => current && current < moment.utc().startOf('day');

  onCopy1(index) {
    this.setState({ copied: true, selectOutIndex: index })
    setTimeout(function () { $('#showEmail1').hide() }, 2000)
  }

  render() {
    const { clients, bizDevCreditFilter, currentLocationFilter } = this.props;
    const user = cookies.get('user')

    const showStrategistColumn = this.props.showStrategistColumn(user, bizDevCreditFilter)
    const showCurrentLocationColumn = this.props.showCurrentLocationColumn(currentLocationFilter)

    return (
      <tbody>
        {clients && clients.length > 0 ?

          map(clients, (client, index) => {
            
            const clientIsReadOnly = this.props.clientIsReadOnly(client, user)

            let overDueRedClass = 'datepicker__follow-up-date'
            if (client.follow_up_date && client.follow_up_date.length === 1 && client.follow_up_date[0].follow_up_date && client.follow_up_date[0].follow_up_date !== '') {
              const timeZone = get(user, 'time_zone', 'America/New_York')
              let followupDate = moment.utc(client.follow_up_date[0].follow_up_date).format('YYYY-MM-DD')
              let nowDate = moment().tz(timeZone).format('YYYY-MM-DD')
              if (followupDate === nowDate || followupDate < nowDate) {
                overDueRedClass = 'datepicker__follow-up-date-red'
              } else {
                overDueRedClass = 'datepicker__follow-up-date'
              }
            }

            const pointerClass = clientIsReadOnly ? null : 'cursor__pointer'
            const rowClass = clientIsReadOnly ? 'read-only-client' : null

            return (
              <tr key={index} className={rowClass}>
                <td className={pointerClass}>
                  <span onClick={() => this.goToClientDetailPage(client, user)}>{client.client_full_name} </span>
                  <span className="wrap-text multilines">
                    <CopyToClipboard
                      text={client.client_email}
                      onCopy={this.onCopy1.bind(this, index)}>
                      <i>{!clientIsReadOnly &&
                        <CopyIcon className="clipcopy-icon" />
                      }</i>
                    </CopyToClipboard>
                    {this.state.selectOutIndex === index ? <span id="showEmail1" style={{ color: '#c7ce4a' }}> Copied</span> : ''}
                  </span>
                </td>

                {/* Add check for filter criteria here to see if we show the Strategist data*/}
                {showStrategistColumn &&
                  (
                    <td className={pointerClass} onClick={() => this.goToClientDetailPage(client, user)}>
                      {client.strategist_name && <div className="wrap-td">{client.strategist_name.replace(/,/g, ', ')}</div>}
                    </td>
                  )
                }

                {showCurrentLocationColumn &&
                  (
                    <td className={pointerClass} onClick={() => this.goToClientDetailPage(client, user)}>
                      <div>{client.current_location}</div>
                    </td>
                  )
                }                

                <td className={pointerClass} onClick={() => this.goToClientDetailPage(client, user)}>{this.formatPriceRange(client)}</td>
                <td className={pointerClass} onClick={() => this.goToClientDetailPage(client, user)}>{client.status}</td>
                <td className={pointerClass} onClick={() => this.goToClientDetailPage(client, user)}>{moment(client.created).tz(moment.tz.guess()).format('MM/DD/YYYY')}</td>
                <td>
                  {clientIsReadOnly && client.follow_up_date && client.follow_up_date.length === 1 &&
                    <div>
                      {client.follow_up_date[0].follow_up_date}
                    </div>
                  }
                  {!clientIsReadOnly && client.follow_up_date && client.follow_up_date.length === 1 &&
                    <div className="btn__follow-up-date">
                      <div className="follow-up-date-label">Follow up date</div>
                      <DatePicker
                        className={overDueRedClass}
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        defaultValue={client && client.follow_up_date && client.follow_up_date[0].follow_up_date}
                        onChange={(date) => this.handleDateChange(client.client_id, client.follow_up_date[0].strategist_id, date)}
                        value={(client && client.follow_up_date && client.follow_up_date[0].follow_up_date) && moment.utc(client.follow_up_date[0].follow_up_date)}
                        format="MM/DD/YYYY"
                        disabledDate={this.disabledDate}
                        inputReadOnly={true}
                        allowClear={false}
                        placeholder="N/A"
                      />
                      <DownCaretIcon className="caret-icon" />
                    </div>}
                  {client.follow_up_date && client.follow_up_date.length >= 2 && <button className="btn btn__link__gray">Multiple Follow Up Dates </button>}
                </td>
              </tr>
            )
          }) :
          <tr>
            <td colSpan="8" className="noClientResults">
              No clients found for this criteria. Please
              broaden or change your search criteria and
              filters
            </td>
          </tr>
        }
      </tbody>
    )
  }
}
