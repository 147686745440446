import React, { PureComponent /*, PropTypes*/ } from "react";
import { Table, Spin } from "antd";
import TopNavigation from "../../../../../../components/TopNavigation/container";
import AdministrationSidebarNavigation from "../../../../../../components/AdministrationSidebarNavigation/component";
import {
  BackArrowIcon,
  ExportIcon,
} from "../../../../../../components/icons";
import "./styles.scss";
import Cookies from "universal-cookie";
import moment from "moment";
import Config from "../../../../../../config";
import _ from "lodash";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import Loader from "../../../../../../components/Loader";
import ReportCustomeDatePicker from "../../../../Strategist/Common/reportCustomeDatePicker";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const cookies = new Cookies();
let _user = "";
let columnDataStrategist = [];
let columnDataAdmin = [];
let columnDataSuperAdmin = [];

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const firstday = moment().startOf("week").toDate();

export default class AccountReportComponent extends PureComponent {
  constructor(props) {
    columnDataStrategist = [
      {
        title: "Client Name",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name),
        children: [
          {
            title: "Summary",
            key: "1",
            width: 270,
            fixed: "left",
            dataIndex: "client_full_name",
          },
        ],
      },
      {
        title: "Unique Identity", //summaryData
        key: "1",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_id - b.client_id,
        children: [
          {
            title: "",
            key: "1",
            dataIndex: "client_id",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.client_id}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Name",
        key: "2",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_full_name.localeCompare(b.strategist_full_name),
        children: [
          {
            title: "",
            dataIndex: "strategist_full_name",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.strategist_full_name}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Region",
        key: "3",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_from.localeCompare(b.client_registered_from),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_from",
          },
        ],
      },
      {
        title: "Registration Date",
        key: "4",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_on.localeCompare(b.client_registered_on),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_on",
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.client_registered_on) &&
                    account.client_registered_on !== "N/A"
                    ? moment(account.client_registered_on).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Initial Price Range Indicated",
        key: "5",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.price_range_max - b.price_range_max,
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "price_range_max",
            render: (text, account) => {
              return (
                <span>
                  {account && account.price_range_max && account.price_range_min
                    ? formatter.format(account.price_range_min) + " - " + formatter.format(account.price_range_max)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Status",
        key: "6",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_status.localeCompare(b.client_status),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_status",
          },
        ],
      },
      {
        title: "Agent Status",
        key: "7",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.deal_status || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.deal_status || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "deal.deal_status",
            width: 290,
            render: (text, account) => {
              return (
                <span>
                  {account && account.deal && account.deal.deal_status
                    ? account.deal.deal_status
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Deal Type",
        key: "8",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.dealType || "";
          let B = b && b.dealType || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "dealType",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account && account.dealType
                    ? account.dealType
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Accepted Offer Date",
        key: "9",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.accepted_offer_date || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.accepted_offer_date || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_date",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.accepted_offer_date) &&
                    account.deal.accepted_offer_date !== "N/A"
                    ? moment(account.deal.accepted_offer_date).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration to Accepted Offer",
        key: "10",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.accepted_offer_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.accepted_offer_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.accepted_offer_after_days !== "N/A"
                    ? account.deal.accepted_offer_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Signed Contract Date",
        key: "11",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.date_of_signed_contract || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.date_of_signed_contract || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "date_of_signed_contract",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.date_of_signed_contract) &&
                    account.deal.date_of_signed_contract !== "N/A"
                    ? moment(account.deal.date_of_signed_contract).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration Date to Signed Contract Date",
        key: "12",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.signed_contract_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.signed_contract_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "signed_contract_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.signed_contract_after_days !== "N/A"
                    ? account.deal.signed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Street Address",
        key: "13",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.address || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.address || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "address",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.address !== "N/A"
                    ? account.deal.address
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Town",
        key: "14",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.town_name || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.town_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "town_name",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "State",
        key: "15",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_from.localeCompare(b.client_registered_from),
        children: [
          {
            title: "",
            dataIndex: "state",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Name",
        key: "16",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_full_name.localeCompare(b.agent_full_name),
        children: [
          {
            title: "",
            dataIndex: "agent_full_name",
            width: 280,
          },
        ],
      },
      {
        title: "Agent Firm",
        key: "17",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_firm_name.localeCompare(b.agent_firm_name),
        children: [
          {
            title: "",
            dataIndex: "agent_firm_name",
            width: 250,
          },
        ],
      },
      {
        title: "Date Closed",
        key: "18",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_date.localeCompare(b.closed_date),
        children: [
          {
            title: "",
            dataIndex: "closed_date",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.closed_date) &&
                    account.closed_date !== "N/A"
                    ? moment(account.closed_date).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registraton Date to Closed Date",
        key: "19",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_contract_after_days - b.closed_contract_after_days,
        children: [
          {
            title: "",
            dataIndex: "closed_contract_after_days",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.closed_contract_after_days !== "N/A"
                    ? account.closed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Price",
        key: "20",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sales_price - b.sales_price,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sales_price}
                </div>
              );
            },
            dataIndex: "sales_price",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sales_price
                    ? formatter.format(account.sales_price)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Commission %",
        key: "21",
        showHeader: false,
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.buy_side_commission - b.buy_side_commission,
        children: [
          {
            title: "",
            dataIndex: "buy_side_commission",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.buy_side_commission
                    ? account.buy_side_commission + " %"
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Misc. Fees",
        key: "22",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sj_comission - b.sj_comission,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sj_comission}
                </div>
              );
            },
            dataIndex: "sj_comission",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sj_comission
                    ? formatter.format(account.sj_comission)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Notes",
        key: "25",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.agent_notes || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.agent_notes || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "agent_notes",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.agent_notes !== "N/A"
                    ? account.deal.agent_notes
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Total Check Received",
        key: "26",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.total_check_received - b.total_check_received,
        children: [
          {
            title: "",
            dataIndex: "total_check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.total_check_received
                    ? formatter.format(account.total_check_received)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Check Received",
        key: "27",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.check_received.localeCompare(b.check_received),
        children: [
          {
            title: "",
            dataIndex: "check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.check_received) &&
                    account.check_received !== "N/A"
                    ? moment(account.check_received).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Strategist Bonus Paid",
        key: "33",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_fee_created.localeCompare(b.strategist_fee_created),
        children: [
          {
            title: "",
            dataIndex: "strategist_fee_created",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.strategist_fee_created) ? account.strategist_fee_created : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Biz Dev Manager",
        key: "34",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.full_name || "";
          let B = b && b.full_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "full_name",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.biz_dev_manager || ''}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Booked How",
        width: 200,
        dataIndex: "booked_how",
        key: "37",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.booked_how.localeCompare(b.booked_how),
        children: [
          {
            title: "",
            dataIndex: "booked_how",
            width: 250,
          },
        ],
      },
      {
        title: "Source",
        width: 250,
        dataIndex: "source",
        key: "38",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.source.localeCompare(b.source),
        children: [
          {
            title: "",
            dataIndex: "source",
            width: 250,
          },
        ],
      },
      {
        title: "Ad Tracking Codes",
        width: 500,
        dataIndex: "ad_tracking_codes",
        key: "39",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes),
        children: [
          {
            title: "",
            dataIndex: "ad_tracking_codes",
            width: 250,
          },
        ],
      },
      {
        title: "",
        key: "40",
        children: [
          {
            title: "",
            dataIndex: "",
            width: 100,
            render: (text, account) => {
              return (
                <span>
                  <span
                    className="text__green cursor__pointer"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/strategist/edit-deal/${account.deal_id}`,
                        state: { account, summaryData: this.state.summaryData },
                      })
                    }
                  >
                    {account.forSummary ? "" : "Edit"}
                  </span>
                </span>
              );
            },
          },
        ],
      },
    ];

    columnDataAdmin = [
      {
        title: "Client Name",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name),
        children: [
          {
            title: "Summary",
            key: "1",
            width: 270,
            fixed: "left",
            dataIndex: "client_full_name",
          },
        ],
      },
      {
        title: "Unique Identity", //summaryData
        key: "1",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_id - b.client_id,
        children: [
          {
            title: "",
            key: "1",
            dataIndex: "client_id",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.client_id}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Name",
        key: "2",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_full_name.localeCompare(b.strategist_full_name),
        children: [
          {
            title: "",
            dataIndex: "strategist_full_name",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.strategist_full_name}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Region",
        key: "3",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_from.localeCompare(b.client_registered_from),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_from",
          },
        ],
      },
      {
        title: "Registration Date",
        key: "4",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_on.localeCompare(b.client_registered_on),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_on",
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.client_registered_on) &&
                    account.client_registered_on !== "N/A"
                    ? moment(account.client_registered_on).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Initial Price Range Indicated",
        key: "5",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.price_range_max - b.price_range_max,
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "price_range_max",
            render: (text, account) => {
              return (
                <span>
                  {account && account.price_range_max && account.price_range_min
                    ? formatter.format(account.price_range_min) + " - " + formatter.format(account.price_range_max)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Status",
        key: "6",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_status.localeCompare(b.client_status),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_status",
          },
        ],
      },
      {
        title: "Agent Status",
        key: "7",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.deal_status || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.deal_status || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "deal.deal_status",
            width: 290,
            render: (text, account) => {
              return (
                <span>
                  {account && account.deal && account.deal.deal_status
                    ? account.deal.deal_status
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Deal Type",
        key: "8",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.dealType || "";
          let B = b && b.dealType || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "dealType",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account && account.dealType
                    ? account.dealType
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Accepted Offer Date",
        key: "9",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.accepted_offer_date || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.accepted_offer_date || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_date",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.accepted_offer_date) &&
                    account.deal.accepted_offer_date !== "N/A"
                    ? moment(account.deal.accepted_offer_date).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration to Accepted Offer",
        key: "10",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.accepted_offer_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.accepted_offer_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.accepted_offer_after_days !== "N/A"
                    ? account.deal.accepted_offer_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Signed Contract Date",
        key: "11",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.date_of_signed_contract || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.date_of_signed_contract || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "date_of_signed_contract",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.date_of_signed_contract) &&
                    account.deal.date_of_signed_contract !== "N/A"
                    ? moment(account.deal.date_of_signed_contract).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration Date to Signed Contract Date",
        key: "12",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.signed_contract_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.signed_contract_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "signed_contract_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.signed_contract_after_days !== "N/A"
                    ? account.deal.signed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Street Address",
        key: "13",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.address || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.address || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "address",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.address !== "N/A"
                    ? account.deal.address
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Town",
        key: "14",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.town_name || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.town_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "town_name",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "State",
        key: "15",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.town_name || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.town_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "state",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Name",
        key: "16",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_full_name.localeCompare(b.agent_full_name),
        children: [
          {
            title: "",
            dataIndex: "agent_full_name",
            width: 280,
          },
        ],
      },
      {
        title: "Agent Firm",
        key: "17",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_firm_name.localeCompare(b.agent_firm_name),
        children: [
          {
            title: "",
            dataIndex: "agent_firm_name",
            width: 250,
          },
        ],
      },
      {
        title: "Date Closed",
        key: "18",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_date.localeCompare(b.closed_date),
        children: [
          {
            title: "",
            dataIndex: "closed_date",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.closed_date) &&
                    account.closed_date !== "N/A"
                    ? moment(account.closed_date).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registraton Date to Closed Date",
        key: "19",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_contract_after_days - b.closed_contract_after_days,
        children: [
          {
            title: "",
            dataIndex: "closed_contract_after_days",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.closed_contract_after_days !== "N/A"
                    ? account.closed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Price",
        key: "20",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sales_price - b.sales_price,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sales_price}
                </div>
              );
            },
            dataIndex: "sales_price",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sales_price
                    ? formatter.format(account.sales_price)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Commission %",
        key: "21",
        showHeader: false,
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.buy_side_commission - b.buy_side_commission,
        children: [
          {
            title: "",
            dataIndex: "buy_side_commission",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.buy_side_commission
                    ? account.buy_side_commission + " %"
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Misc. Fees",
        key: "22",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sj_comission - b.sj_comission,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sj_comission}
                </div>
              );
            },
            dataIndex: "sj_comission",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sj_comission
                    ? formatter.format(account.sj_comission)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Misc Fees Approved",
        key: "23",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.date_misc_fees.localeCompare(b.date_misc_fees),
        children: [
          {
            title: "",
            dataIndex: "date_misc_fees",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.date_misc_fees) &&
                    account.date_misc_fees !== "N/A" || account.date_misc_fees !== ""
                    ? moment(account.date_misc_fees).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Total SJ Revenue",
        key: "24",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.total_sj_revenue - b.total_sj_revenue,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_total_sj_revenue}
                </div>
              );
            },
            dataIndex: "total_sj_revenue",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.total_sj_revenue
                    ? formatter.format(account.total_sj_revenue)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Notes",
        key: "25",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.agent_notes || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.agent_notes || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "agent_notes",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.agent_notes !== "N/A"
                    ? account.deal.agent_notes
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Total Check Received",
        key: "26",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.total_check_received - b.total_check_received,
        children: [
          {
            title: "",
            dataIndex: "total_check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.total_check_received
                    ? formatter.format(account.total_check_received)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Check Received",
        key: "27",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.check_received.localeCompare(b.check_received),
        children: [
          {
            title: "",
            dataIndex: "check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.check_received) &&
                    account.check_received !== "N/A"
                    ? moment(account.check_received).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Copy uploaded of check",
        key: "28",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.checkCopy.localeCompare(b.checkCopy),
        children: [
          {
            title: "",
            dataIndex: "checkCopy",
            width: 250,
            render: (text, account) => {
              return (
                <a href={account.checkCopy} target="_blank">
                  {account.checkCopy}
                </a>
              );
            },
          },
        ],
      },
      {
        title: "Difference between Check Received and Expected SJ revenue",
        key: "29",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          const totalSJVenueA = a.total_sj_revenue != 'N/A' && a.total_sj_revenue
          const totalSJVenueB = b.total_sj_revenue != 'N/A' && b.total_sj_revenue

          const totalCheckreceivedA = a.total_check_received !== "N/A" && a.total_check_received
          const totalA = (totalCheckreceivedA - totalSJVenueA)

          const totalCheckreceivedB = b.total_check_received !== "N/A" && b.total_check_received
          const totalB = (totalCheckreceivedB - totalSJVenueB)

          return (totalA - totalB);
        },
        children: [
          {
            title: "",
            dataIndex: "total_check_received",
            width: 250,
            render: (text, account) => {
              const totalCheckreceived = account.total_check_received !== "N/A" && account.total_check_received
              const totalSJVenue = account.total_sj_revenue
              const total = (totalCheckreceived - totalSJVenue)
              return (
                <span>
                  {total ? formatter.format(parseInt(total)) : 0}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Explanation of Difference",
        key: "30",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.explanation.localeCompare(b.explanation),
        children: [
          {
            title: "",
            dataIndex: "explanation",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.explanation}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Approved of Difference",
        key: "31",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.date_approved_of_difference.localeCompare(b.date_approved_of_difference),
        children: [
          {
            title: "",
            dataIndex: "date_approved_of_difference",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.date_approved_of_difference) &&
                    account.date_approved_of_difference !== "N/A"
                    ? moment(account.date_approved_of_difference).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Strategist Bonus Paid",
        key: "33",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_fee_created.localeCompare(b.strategist_fee_created),
        children: [
          {
            title: "",
            dataIndex: "strategist_fee_created",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.strategist_fee_created) ? account.strategist_fee_created : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Biz Dev Manager",
        key: "34",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.full_name || "";
          let B = b && b.full_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "full_name",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.biz_dev_manager || ''}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Biz Dev Paid",
        key: "36",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.bd_created_date || "";
          let B = b && b.bd_created_date || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "bd_created_date",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.bd_created_date !== "N/A"
                    ? account.bd_created_date
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Booked How",
        width: 200,
        dataIndex: "booked_how",
        key: "37",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.booked_how.localeCompare(b.booked_how),
        children: [
          {
            title: "",
            dataIndex: "booked_how",
            width: 250,
          },
        ],
      },
      {
        title: "Source",
        width: 250,
        dataIndex: "source",
        key: "38",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.source.localeCompare(b.source),
        children: [
          {
            title: "",
            dataIndex: "source",
            width: 250,
          },
        ],
      },
      {
        title: "Ad Tracking Codes",
        width: 500,
        dataIndex: "ad_tracking_codes",
        key: "39",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes),
        children: [
          {
            title: "",
            dataIndex: "ad_tracking_codes",
            width: 250,
          },
        ],
      },
      {
        title: "",
        key: "40",
        children: [
          {
            title: "",
            dataIndex: "",
            width: 100,
            render: (text, account) => {
              return (
                <span>
                  <span
                    className="text__green cursor__pointer"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/strategist/edit-deal/${account.deal_id}`,
                        state: { account, summaryData: this.state.summaryData },
                      })
                    }
                  >
                    {account.forSummary ? "" : "Edit"}
                  </span>
                </span>
              );
            },
          },
        ],
      },
    ];

    columnDataSuperAdmin = [
      {
        title: "Client Name",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name),
        children: [
          {
            title: "Summary",
            key: "1",
            dataIndex: "client_full_name",
            width: 270,
            fixed: "left",
          },
        ],
      },
      {
        title: "Unique Identity", //summaryData
        key: "1",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_id - b.client_id,
        children: [
          {
            title: "",
            key: "1",
            dataIndex: "client_id",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.client_id}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Name", //summaryData
        key: "2",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_full_name.localeCompare(b.strategist_full_name),
        children: [
          {
            title: "",
            dataIndex: "strategist_full_name",
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {_.size(data.strategists) > 0 &&
                    data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {val.strategist_full_name}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            },
          },
        ],
      },
      {
        title: "Region",
        key: "3",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_from.localeCompare(b.client_registered_from),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_from",
          },
        ],
      },
      {
        title: "Registration Date",
        key: "4",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_registered_on.localeCompare(b.client_registered_on),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_registered_on",
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.client_registered_on) &&
                    account.client_registered_on !== "N/A"
                    ? moment(account.client_registered_on).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Initial Price Range Indicated",
        key: "5",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.price_range_max - b.price_range_max,
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "price_range_max",
            render: (text, account) => {
              return (
                <span>
                  {account && account.price_range_max && account.price_range_min
                    ? formatter.format(account.price_range_min) + " - " + formatter.format(account.price_range_max)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Status",
        key: "6",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_status.localeCompare(b.client_status),
        children: [
          {
            title: "",
            width: 270,
            dataIndex: "client_status",
          },
        ],
      },
      {
        title: "Agent Status",
        key: "7",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.deal_status || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.deal_status || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "deal.deal_status",
            width: 290,
            render: (text, account) => {
              return (
                <span>
                  {account && account.deal && account.deal.deal_status
                    ? account.deal.deal_status
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Deal Type",
        key: "8",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.dealType || "";
          let B = b && b.dealType || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "dealType",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account && account.dealType
                    ? account.dealType
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Accepted Offer Date",
        key: "9",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.accepted_offer_date.localeCompare(b.accepted_offer_date),
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_date",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.accepted_offer_date) &&
                    account.deal.accepted_offer_date !== "N/A"
                    ? moment(account.deal.accepted_offer_date).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration to Accepted Offer",
        key: "10",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.accepted_offer_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.accepted_offer_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "accepted_offer_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.accepted_offer_after_days !== "N/A"
                    ? account.deal.accepted_offer_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Signed Contract Date",
        key: "11",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.date_of_signed_contract || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.date_of_signed_contract || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "date_of_signed_contract",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.deal.date_of_signed_contract) &&
                    account.deal.date_of_signed_contract !== "N/A"
                    ? moment(account.deal.date_of_signed_contract).format(
                      "MM/DD/YYYY"
                    )
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registration Date to Signed Contract Date",
        key: "12",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.signed_contract_after_days || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.signed_contract_after_days || "";
          return A - B;
        },
        children: [
          {
            title: "",
            dataIndex: "signed_contract_after_days",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.signed_contract_after_days !== "N/A"
                    ? account.deal.signed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Street Address",
        key: "13",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.address || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.address || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "address",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.address !== "N/A"
                    ? account.deal.address
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Town",
        key: "14",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.town_name || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.town_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "town_name",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "State",
        key: "14",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.town_name || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.town_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "state",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.town_name !== "N/A"
                    ? account.deal.town_name
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Name",
        key: "16",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_full_name.localeCompare(b.agent_full_name),
        children: [
          {
            title: "",
            dataIndex: "agent_full_name",
            width: 280,
          },
        ],
      },
      {
        title: "Agent Firm",
        key: "17",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.agent_firm_name.localeCompare(b.agent_firm_name),
        children: [
          {
            title: "",
            dataIndex: "agent_firm_name",
            width: 250,
          },
        ],
      },
      {
        title: "Date Closed",
        key: "18",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_date.localeCompare(b.closed_date),
        children: [
          {
            title: "",
            dataIndex: "closed_date",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.closed_date) &&
                    account.closed_date !== "N/A"
                    ? moment(account.closed_date).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Days from Registraton Date to Closed Date",
        key: "19",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.closed_contract_after_days - b.closed_contract_after_days,
        children: [
          {
            title: "",
            dataIndex: "closed_contract_after_days",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.closed_contract_after_days !== "N/A"
                    ? account.closed_contract_after_days
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Price",
        key: "20",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sales_price - b.sales_price,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sales_price}
                </div>
              );
            },
            dataIndex: "sales_price",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sales_price
                    ? formatter.format(account.sales_price)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Commission %",
        key: "21",
        showHeader: false,
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.buy_side_commission - b.buy_side_commission,
        children: [
          {
            title: "",
            dataIndex: "buy_side_commission",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.buy_side_commission
                    ? account.buy_side_commission + " %"
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Misc. Fees",
        key: "22",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.sj_comission - b.sj_comission,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_sj_comission}
                </div>
              );
            },
            dataIndex: "sj_comission",
            width: 200,
            render: (text, account) => {
              return (
                <span>
                  {account && account.sj_comission
                    ? formatter.format(account.sj_comission)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Misc Fees Approved",
        key: "23",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.date_misc_fees.localeCompare(b.date_misc_fees),
        children: [
          {
            title: "",
            dataIndex: "date_misc_fees",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.date_misc_fees) &&
                    account.date_misc_fees !== "N/A" || account.date_misc_fees !== ""
                    ? moment(account.date_misc_fees).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Total SJ Revenue",
        key: "24",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.total_sj_revenue - b.total_sj_revenue,
        children: [
          {
            title: () => {
              return (
                <div>
                  {this.state.summaryData &&
                    this.state.summaryData[0].summary_total_sj_revenue}
                </div>
              );
            },
            dataIndex: "total_sj_revenue",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.total_sj_revenue
                    ? formatter.format(account.total_sj_revenue)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Agent Notes",
        key: "25",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.deal && !_.isEmpty(a.deal) && a.deal.agent_notes || "";
          let B = b && b.deal && !_.isEmpty(b.deal) && b.deal.agent_notes || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "agent_notes",
            width: 180,
            render: (text, account) => {
              return (
                <span>
                  {account.deal.agent_notes !== "N/A"
                    ? account.deal.agent_notes
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Total Check Received",
        key: "26",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.total_check_received - b.total_check_received,
        children: [
          {
            title: "",
            dataIndex: "total_check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.total_check_received
                    ? formatter.format(account.total_check_received)
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Check Received",
        key: "27",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.check_received.localeCompare(b.check_received),
        children: [
          {
            title: "",
            dataIndex: "check_received",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.check_received) &&
                    account.check_received !== "N/A"
                    ? moment(account.check_received).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Copy uploaded of check",
        key: "28",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.checkCopy.localeCompare(b.checkCopy),
        children: [
          {
            title: "",
            dataIndex: "checkCopy",
            width: 250,
            render: (text, account) => {
              return (
                <a href={account.checkCopy} target="_blank">
                  {account.checkCopy}
                </a>
              );
            },
          },
        ],
      },
      {
        title: "Difference between Check Received and Expected SJ revenue",
        key: "29",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          const totalSJVenueA = a.total_sj_revenue != 'N/A' && a.total_sj_revenue
          const totalSJVenueB = b.total_sj_revenue != 'N/A' && b.total_sj_revenue

          const totalCheckreceivedA = a.total_check_received !== "N/A" && a.total_check_received
          const totalA = (totalCheckreceivedA - totalSJVenueA)

          const totalCheckreceivedB = b.total_check_received !== "N/A" && b.total_check_received
          const totalB = (totalCheckreceivedB - totalSJVenueB)

          return (totalA - totalB);
        },
        children: [
          {
            title: "",
            dataIndex: "total_check_received",
            width: 250,
            render: (text, account) => {
              const totalCheckreceived = account.total_check_received !== "N/A" && account.total_check_received
              const totalSJVenue = account.total_sj_revenue
              const total = (totalCheckreceived - totalSJVenue)
              return (
                <span>
                  {total ? formatter.format(parseInt(total)) : 0}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Explanation of Difference",
        key: "30",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.explanation.localeCompare(b.explanation),
        children: [
          {
            title: "",
            dataIndex: "explanation",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.explanation}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Approved of Difference",
        key: "31",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.date_approved_of_difference.localeCompare(b.date_approved_of_difference),
        children: [
          {
            title: "",
            dataIndex: "date_approved_of_difference",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.date_approved_of_difference) &&
                    account.date_approved_of_difference !== "N/A"
                    ? moment(account.date_approved_of_difference).format("MM/DD/YYYY")
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Strategist Bonus",
        key: "32",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_fee.localeCompare(b.strategist_fee),
        children: [
          {
            title: "",
            dataIndex: "strategist_fee",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account.strategist_fee ? account.strategist_fee : 0}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Strategist Bonus Paid",
        key: "33",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_fee_created.localeCompare(b.strategist_fee_created),
        children: [
          {
            title: "",
            dataIndex: "strategist_fee_created",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {!_.isEmpty(account.strategist_fee_created) ? account.strategist_fee_created : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Biz Dev Manager",
        key: "34",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.full_name || "";
          let B = b && b.full_name || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "full_name",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.biz_dev_manager || ''}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Biz Dev Fee",
        key: "35",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.business_development_fee;
          let B = b && b.business_development_fee;
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "business_development_fee",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.business_development_fee
                    ? account.business_development_fee
                    : formatter.format(0)
                  }
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Date Biz Dev Paid",
        key: "36",
        sortDirections: ["descend", "ascend", "descend"],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let A = a && a.bd_created_date || "";
          let B = b && b.bd_created_date || "";
          return A.localeCompare(B);
        },
        children: [
          {
            title: "",
            dataIndex: "bd_created_date",
            width: 250,
            render: (text, account) => {
              return (
                <span>
                  {account && account.bd_created_date !== "N/A"
                    ? account.bd_created_date
                    : ""}
                </span>
              );
            },
          },
        ],
      },
      {
        title: "Booked How",
        width: 200,
        dataIndex: "booked_how",
        key: "37",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.booked_how.localeCompare(b.booked_how),
        children: [
          {
            title: "",
            dataIndex: "booked_how",
            width: 250,
          },
        ],
      },
      {
        title: "Source",
        width: 250,
        dataIndex: "source",
        key: "38",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.source.localeCompare(b.source),
        children: [
          {
            title: "",
            dataIndex: "source",
            width: 250,
          },
        ],
      },
      {
        title: "Ad Tracking Codes",
        width: 500,
        dataIndex: "ad_tracking_codes",
        key: "39",
        showSorterTooltip: false,
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes),
        children: [
          {
            title: "",
            dataIndex: "ad_tracking_codes",
            width: 250,
          },
        ],
      },
      {
        title: "",
        key: "40",
        children: [
          {
            title: "",
            dataIndex: "",
            width: 100,
            render: (text, account) => {
              return (
                <span>
                  <span
                    className="text__green cursor__pointer"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/strategist/edit-deal/${account.deal_id}`,
                        state: { account, summaryData: this.state.summaryData },
                      })
                    }
                  >
                    {account.forSummary ? "" : "Edit"}
                  </span>
                </span>
              );
            },
          },
        ],
      },
    ];

    super(props);
    this.state = {
      accountingReportData: [],
      selectedIndex: "All",
      type: "assignToAgent",
      previousStartDate: moment().add(-1, "month").startOf("month"),
      previousEndDate: moment(),
      sortByKey: "",
      sortByType: 0,
      strategist_id: 0,
      status_name: '',
      todayDate: moment().format("LL"),
      weekFirstDate: moment(firstday).format("LL"),
      openCheck: false,
      deal_check_client: {},
      current_client: "",
      openFee: false,
      strategist_fee_client: {},
      updateFeeIndex: null,
      openBiz: false,
      currentBDSource: [],
      updateBiz: null,
      showAll: true,
      summaryData: [],
      multiDataSet: [],
      multiDataSet2: [],
      region: '',
      bd_source_id: 0,
      search: '',
      searchBaseName: ''
    };
  }

  setValuesForExcelSheet = async (
    sheetHeaders,
    customExportAccountingReportData
  ) => {
    let MBaluesAr = [];

    let setVals = customExportAccountingReportData.map(async (k, i) => {
      let d = [];
      let setval = Object.keys(k).map((header, j) => {
        d.push(k[header]);
      });
      await Promise.all(setval);
      MBaluesAr.push(d);
    });

    await Promise.all(setVals);
    let multiDataSet = this.state.multiDataSet;

    if (!_.isEmpty(this.state.multiDataSet)) {
      multiDataSet = [];
    }

    multiDataSet.push({ columns: sheetHeaders, data: MBaluesAr });
    this.setState({ multiDataSet });
  };

  setValuesForExcelSheet2 = async (
    sheetHeaders,
    customExportAccountingReportData,
    toOmitFields
  ) => {
    let MBaluesAr = [];
    let finalAr = [];

    // Remove fields from the objects
    const removeFields = customExportAccountingReportData.map((field) => {
      toOmitFields.map((k, i) => {
        delete field[k];
      });

      finalAr.push(field);
    });

    await Promise.all(removeFields);

    //  Set sheet values array
    let setVals = finalAr.map(async (k, i) => {
      let d = [];
      let setval = Object.keys(k).map((header, j) => {
        d.push(k[header]);
      });
      await Promise.all(setval);
      MBaluesAr.push(d);
    });

    await Promise.all(setVals);
    let multiDataSet2 = this.state.multiDataSet2;

    if (!_.isEmpty(multiDataSet2)) {
      multiDataSet2 = [];
    }

    multiDataSet2.push({ columns: sheetHeaders, data: MBaluesAr });

    this.setState({ multiDataSet2 });
  };

  componentDidMount() {
    _user = cookies.get("user");
    if (
      (_user && _user.role === "agent") ||
      (_user && _user.role === "local")
    ) {
      this.props.history.push({ pathname: "/agent/dashboard" });
    }
    if (_user && _user.role === "client") {
      this.props.history.push({ pathname: "/clients" });
    }
    this.setState({ isLoading: true });
    this.props.getFilterParams();
    this.props.getDevelopmentMembers()
    // this.strategistDataLoad()
    const strData = {};
    if (this.state.selectedIndex === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    strData.type = this.state.type;
    strData.status = this.state.status_name;
    strData.region = this.state.region;
    if (_user && _user.role === "strategist" && _user.id) {
      strData.strategistId = _user.id;
    } else {
      strData.strategistId = 0; //first time strategist id=0 means all strategist
    }
    strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType };
    this.props.getAccountingReportData(strData);
  }

  componentDidUpdate(preProps, preState) {
    const {
      accountingReportPhase,
      accountingReportData,
      clearStrategistPhase,
    } = this.props;
    if (accountingReportPhase === "success") {
      clearStrategistPhase();
      let actData = [];
      let summaryData = [];
      let accountingData = [];
      let customExportAccountingReportData = [];
      let feeCount = 0;
      let bdCount = 0;
      let toOmitFields = [];
      const accountData = accountingReportData && accountingReportData.data;
      if (_.size(accountData) > 0) {
        summaryData.push({
          key: 1,
          summary_sales_price: accountingReportData.salesPriceCount
            ? `$${accountingReportData.salesPriceCount.toLocaleString()}`
            : 0,
          summary_sj_comission: accountingReportData.miscFeeCount
            ? `$${accountingReportData.miscFeeCount.toLocaleString()}`
            : 0,
          summary_total_sj_revenue: accountingReportData.totalSJRevenueCount
            ? `$${accountingReportData.totalSJRevenueCount.toLocaleString()}`
            : 0,
          summary_strategist_fee: accountingReportData.strategistFeeCount
            ? `$${accountingReportData.strategistFeeCount.toLocaleString()}`
            : 0,
          summary_business_development_fee: accountingReportData.bdFeeCount
            ? `$${accountingReportData.bdFeeCount.toLocaleString()}`
            : 0,
        });

        accountData &&
          accountData.map((data, i) => {
            if (data.strategists.length > feeCount) {
              feeCount = data.strategists.length;
            }
            if (data.bd_source.length > bdCount) {
              bdCount = data.bd_source.length;
            }

            data.total_sj_revenue = data.total_sj_revenue;
            data.sales_price = data.sales_price;

            let date = "";
            date = data.bd_source.length > 0
              ? data.bd_source
                .map((e) => {
                  return e.bd_created_date ? moment(e.bd_created_date).format('MM/DD/YYYY') : ''
                })
              : "",

              actData.push({
                key: i,
                strategist_full_name:
                  data.strategists.length > 0
                    ? data.strategists
                      .map((e) => e.strategist_full_name).join(", ")
                    : "",
                client_id: data.client_id || 0,
                client_full_name: data.client_full_name || "",
                client_registered_from: data.client_registered_from || "",
                client_registered_on: data.client_registered_on || "",
                price_range_max: data.price_range_max || 0,
                price_range_min: data.price_range_min || 0,
                client_status: data.client_status || "",
                deal: data.deal || {},
                dealType: data.deal_type || "",
                accepted_offer_date: data.deal.accepted_offer_date || "",
                town_name: data.town_name || "",
                agent_full_name: data.agent_full_name || "",
                agent_firm_name: data.agent_firm_name || "",
                closed_date: data.closed_date || "",
                closed_contract_after_days: data.closed_contract_after_days || 0,
                sales_price: data.sales_price || 0,
                buy_side_commission: data.buy_side_commission || 0,
                sj_comission: data.sj_comission || 0,
                date_misc_fees: data.date_misc_fees || "",
                total_sj_revenue: data.total_sj_revenue || 0,
                total_check_received: data.total_check_received || 0,
                check_received: data.check_received || "",
                checkCopy: data.checkCopy || "",
                explanation: data.explanation || "",
                date_approved_of_difference: data.date_approved_of_difference || "",
                strategist_fee: data.strategists.length > 0
                  ? data.strategists
                    .map((e) => {
                      return formatter.format(e.strategist_fee);
                    })
                    .join(", ")
                  : formatter.format(0),
                strategist_fee_created: data.strategists.length > 0
                  ? data.strategists
                    .map((e) => {
                      return e.strategist_fee_created ? moment(e.strategist_fee_created).format('MM/DD/YYYY') : "";
                    })
                    .join(", ")
                  : "",
                strategist_full_name:
                  data.strategists.length > 0
                    ? data.strategists.map((e) => e.strategist_full_name).join(", ")
                    : "",
                biz_dev_manager: data.bd_source.length > 0
                  ? data.bd_source.map((e) => e.full_name).join(", ")
                  : "",
                business_development_fee: data.bd_source.length > 0
                  ? data.bd_source.map((e) => formatter.format(e.business_development_fee)).join(", ")
                  : formatter.format(0),
                bd_created_date: date && date.join(", "),

                // bd_source: data.bd_source || "",
                booked_how: data.booked_how
                  ? data.booked_how
                  : "Created in Jungler",
                source: data.source ? data.source : "",
                ad_tracking_codes: data.ad_tracking_codes
                  ? data.ad_tracking_codes
                  : "",
                deal_id: data.deal_id || 0,
                strategistListArr: data.strategists,
                strategists: data.strategists
              });
          });

        //this is start for export data
        accountData &&
          accountData.map(function (data) {
            let customObj = {};
            customObj["Client First Name"] = data.client_full_name;
            customObj["Unique Identity"] = data.client_id;
            customObj.strategist_name =
              data.strategists.length > 0
                ? data.strategists
                  .map((e) => {
                    return e.strategist_full_name;
                  })
                  .join(" ,")
                : "";
            customObj["Region"] = data.client_registered_from;
            customObj["Registration Date"] = moment(data.client_registered_on).format("MM/DD/YYYY");
            customObj["Initial Price Range Indicated"] = (data.price_range_min !== "" && data.price_range_min !== null ? formatter.format(data.price_range_min) : "") + " - " + (data.price_range_max !== "" && data.price_range_max !== null ? formatter.format(data.price_range_max) : "");
            customObj["Strategist Status"] = data.client_status;
            customObj["Agent Status"] = data.deal.deal_status;
            customObj["Deal Type"] = data.deal_type;
            customObj["Accepted Offer Date"] = moment(data.deal.accepted_offer_date).format("MM/DD/YYYY");
            customObj["Days from Registration to Accepted Offer"] = data.deal.accepted_offer_after_days;
            customObj["Signed Contract Date"] = moment(data.deal.date_of_signed_contract).format("MM/DD/YYYY");
            customObj["Days from Registration Date to Signed Contract Date"] = data.deal.signed_contract_after_days
            customObj["Street Address"] = data.deal.address;
            customObj["Town"] = data.deal.town_name;
            customObj["State"] = data.town_name;
            customObj["Agent Name"] = data.agent_full_name;
            customObj["Agent Firm"] = data.agent_firm_name;
            customObj["Date Closed"] = moment(data.closed_date).format("MM/DD/YYYY");
            customObj["Days from Registraton Date to Closed Date"] = data.closed_contract_after_days;
            customObj["Price"] = formatter.format(data.sales_price);
            customObj["Commission %"] = data.buy_side_commission + " %";
            customObj["Misc Fees"] = formatter.format(data.sj_comission);

            if (_user && _user.role !== "strategist") {
              customObj["Date Misc Fees Approved"] = data.date_misc_fees !== "" &&
                data.date_misc_fees !== null &&
                data.date_misc_fees !== "0000-00-00 00:00:00"
                ? moment(data.date_misc_fees).format("MM/DD/YYYY")
                : "";
              customObj["Total SJ Revenue"] = formatter.format(data.total_sj_revenue);
              customObj["Copy uploaded of check"] = data.checkCopy;
              const totalCheckreceived = data.total_check_received !== "N/A" && data.total_check_received;
              let totalSJVenue = data.total_check_received !== "N/A" && data.total_sj_revenue;
              const total = (totalCheckreceived - Number(totalSJVenue));
              customObj["Difference between Check Received and Expected SJ revenue"] = total !== "" && total !== null ? "$" + total : ""
              customObj["Explanation of Difference"] = data.explanation;
              customObj["Date Approved of Difference"] = data.date_approved_of_difference !== "" &&
                data.date_approved_of_difference !== null &&
                data.date_approved_of_difference !== "0000-00-00 00:00:00"
                ? moment(data.date_approved_of_difference).format("MM/DD/YYYY")
                : "";
            }
            customObj["Agent Notes"] = data.deal.agent_notes;
            customObj["Total Check Received"] = formatter.format(data.total_check_received);
            customObj["Date Check Received"] = data.check_received !== "" &&
              data.check_received !== null &&
              data.check_received !== "0000-00-00 00:00:00"
              ? moment(data.check_received).format("MM-DD-YYYY")
              : "";

            if (_user && _user.role !== "admin" && _user.role !== "strategist") {
              customObj["Strategist Bonus"] = data.strategists.length > 0
                ? data.strategists
                  .map((e) => {
                    return formatter.format(e.strategist_fee);
                  })
                  .join(", ")
                : "";
            }

            customObj["Date Strategist Bonus Paid"] = data.strategists.length > 0
              ? data.strategists
                .map((e) => {
                  return e.strategist_fee_created ? moment(e.strategist_fee_created).format("MM/DD/YYYY") : "";
                })
                .join(", ")
              : "";
            customObj.booked_how = data.booked_how ? data.booked_how : "Created in Jungler";
            customObj.source = data.source;
            customObj.ad_tracking_codes = data.ad_tracking_codes
              ? data.ad_tracking_codes
              : "";

            if (data && data.bd_source && data.bd_source.length > 0) {
              data.bd_source.map(function (bd, index) {
                customObj[`Biz Dev Manager ${index + 1}`] = bd.full_name;
                if (_user && _user.role !== "admin" && _user.role !== "strategist") {
                  customObj[`Biz Dev Fee ${index + 1}`] =
                    bd.business_development_fee !== "" &&
                      bd.business_development_fee !== null
                      ? "$" + bd.business_development_fee
                      : "";
                }
                if (_user && _user.role !== "strategist") {
                  customObj[`Biz Dev Paid ${index + 1}`] =
                    bd.bd_created_date !== "" && bd.bd_created_date !== null
                      ? moment(bd.bd_created_date).format("MM-DD-YYYY")
                      : "";
                }
              });
            }
            customExportAccountingReportData.push(customObj);
          });

        accountingData = _.reject(actData, ["strategist_full_name", ""]);
        customExportAccountingReportData = _.reject(
          customExportAccountingReportData,
          ["strategist_name", ""]
        );

        let sheetHeaders = [];
        let a = 0;
        let maxLength = 0;
        customExportAccountingReportData.map((c, index) => {
          if (Object.keys(c).length > maxLength) {
            a = index;
            maxLength = Object.keys(c).length;
          }
        })
        Promise.all(
          !_.isEmpty(customExportAccountingReportData) &&
          Object.keys(customExportAccountingReportData[a]).map((k, i) => {
            let headerName = k.replace(/_/g, " ");
            headerName = headerName.replace(/\w\S*/g, function (txt) {
              return (
                txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              );
            });
            sheetHeaders.push(headerName);
          })
        );

        this.setState({ toOmitFields });
        this.setValuesForExcelSheet(
          sheetHeaders,
          customExportAccountingReportData
        );
        this.setValuesForExcelSheet2(
          sheetHeaders,
          customExportAccountingReportData,
          toOmitFields
        );
        this.setState(
          {
            isLoading: false,
            accountingReportData: accountingData,
          },
          () => { }
        );
        //////////
      }
      this.setState({
        accountingReportData: actData,
        isLoading: false,
        summaryData,
      });
    }
    if (accountingReportPhase === "error") {
      clearStrategistPhase();
      this.setState({ accountingReportData: [], isLoading: false });
    }
  }

  handleChange(event) {
    let strListData =
      this.props.filterParams &&
      this.props.filterParams.data &&
      this.props.filterParams.data.strategists;
    strListData && strListData.map((val, index) => {
      if (event.target.value == 0) {
        this.setState({ strategist_name: 'All', strategist_id: event.target.value, isLoading: true });
      } else if (val.id === parseInt(event.target.value)) {
        this.setState({ strategist_name: val.strategist_name, strategist_id: val.id, isLoading: true });
      }
    });
    const strData = {};
    if (this.state.selectedIndex === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss");
    }
    strData.type = this.state.type;
    strData.strategistId = event.target.value;
    strData.region = this.state.region;
    strData.status = this.state.status_name;
    strData.sortBy = {
      key: this.state.sortByKey,
      type: this.state.sortByType,
    };
    this.props.getAccountingReportData(strData);
  }

  handleBdSources(event) {

    this.setState({ bd_source_id: event.target.value, isLoading: true });
    const strData = {};
    if (this.state.selectedIndex === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss");
    }
    strData.type = this.state.type;
    strData.strategistId = this.state.strategist_id;
    strData.bdStrategistId = event.target.value;
    strData.region = this.state.region;
    strData.status = this.state.status_name;
    strData.sortBy = {
      key: this.state.sortByKey,
      type: this.state.sortByType,
    };
    this.props.getAccountingReportData(strData);
  }

  handleStatusChange(event) {
    let strListData =
      this.props.filterParams &&
      this.props.filterParams.data &&
      this.props.filterParams.data.statuses;
    const strData = {};
    if (event.target.value == 0) {
      this.setState({ status_name: 0, status_id: 0, isLoading: true });
      strData.status = 0;
    } else {
      strListData && strListData.map((val, index) => {
        if (val.id === parseInt(event.target.value)) {
          this.setState({ status_name: val.name, status_id: val.id, isLoading: true });
          strData.status = val.name;
        }
      });
    }

    if (this.state.selectedIndex === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss");
    }
    strData.strategistId = this.state.strategist_id;
    strData.type = this.state.type;
    strData.region = this.state.region;
    strData.sortBy = {
      key: this.state.sortByKey,
      type: this.state.sortByType,
    };
    this.props.getAccountingReportData(strData);
  }

  filterByRegion(event) {
    this.setState({ isLoading: true, region: event.target.value });
    const strData = {};
    if (this.state.selectedIndex === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss");
    }
    strData.type = this.state.type;
    strData.status = this.state.status_name;
    strData.strategistId = this.state.strategist_id;
    strData.sortBy = {
      key: this.state.sortByKey,
      type: this.state.sortByType,
    };
    strData.region = event.target.value;
    this.props.getAccountingReportData(strData);
  }
  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, toDate: to });
    const strData = {
      type: this.state.type,
      strategistId: this.state.strategist_id,
      region: this.state.region,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType },
      status: this.state.status_name
    };

    if (val === "All") {
      strData.from = moment().add(-5, "years").format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      strData.from = moment(from).format("YYYY-MM-DD HH:mm:ss");
      strData.to = moment(to).format("YYYY-MM-DD HH:mm:ss");
    }
    this.props.getAccountingReportData(strData);
  };

  onSearch(e) {
    this.setState({ searchBaseName: e.target.value })
  }

  changeLimit = (limit) => {
    // On limit change
  };

  render() {
    const {
      accountingReportData
    } = this.state;
    let filterList = accountingReportData
    if (_.size(accountingReportData) > 0) {
      filterList = accountingReportData.filter(
        (val) => {
          return (
            (val.client_full_name.toLowerCase().indexOf(this.state.searchBaseName.toLowerCase())) !== -1
          );
        }
      );
    }
    const { filterParams, developmentMembers } = this.props;
    let strategistList =
      filterParams &&
      filterParams.data &&
      filterParams.data.strategists &&
      filterParams.data.strategists.map((val, index) => {
        return (
          <option key={index + 1} value={val.id}>
            {val.strategist_name}
          </option>
        );
      });
    if (_.size(accountingReportData) > 0) {
      filterList = accountingReportData.filter(
        (val) => {
          return (
            (val.client_full_name.toLowerCase().indexOf(this.state.searchBaseName.toLowerCase())) !== -1
          );
        }
      );
    }
    let strategistStatusList =
      filterParams &&
      filterParams.data &&
      filterParams.data.statuses &&
      filterParams.data.statuses.map((val, index) => {
        return (
          <option key={index + 1} value={val.id}>
            {val.name}
          </option>
        );
      });
    let region = [
      { key: "Austin", value: "Austin" },
      { key: "Boston", value: "Boston" },
      { key: "Chicago", value: "Chicago" },
      { key: "Dallas/Ft. Worth", value: "Dallas/Ft. Worth" },
      { key: "Hamptons", value: "Hamptons" },
      { key: "Southern California", value: "Southern California" },
      { key: "New York City", value: "New York City" },
      { key: "Philadelphia", value: "Philadelphia" },
      { key: "San Francisco", value: "San Francisco" },
      { key: "South Florida", value: "South Florida" },
      { key: "Washinton D.C", value: "Washinton D.C" },
      { key: "Denver", value: "Denver" },
    ].map((val, index) => {
      return (
        <option key={index} value={val.key}>
          {val.value}
        </option>
      );
    });
    if (developmentMembers && developmentMembers.data && developmentMembers.data.length > 0) {
      let a = developmentMembers['data'].sort((a, b) => (a.full_name > b.full_name) ? 1 : -1)
      developmentMembers['data'] = a;
    }
    let developmentMembersList = developmentMembers && developmentMembers.data && developmentMembers.data.length > 0 && developmentMembers.data.map((val, index) => {
      return (
        <option key={index} value={val.client_id}>{val.full_name}</option>
      )

    })
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button
                      onClick={() =>
                        this.props.history.push(`/administration/dashboard`)
                      }
                      className="btn btn-back"
                    >
                      <BackArrowIcon className="arrow-icon" />
                      Back to Dashboard
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Accounting Report</h2>
                    {(_user && _user.role === "superadmin") ||
                      (_user &&
                        _user.email === "alyssa@suburbanjunglegroup.com") ? (
                      <ExcelFile
                        element={
                          <button className="btn btn__btn btn__export">
                            <ExportIcon className="mr__15" /> Export
                          </button>
                        }
                      >
                        <ExcelSheet
                          dataSet={this.state.multiDataSet}
                          name="accounting_report"
                        ></ExcelSheet>
                      </ExcelFile>
                    ) : (
                      <ExcelFile
                        element={
                          <button className="btn btn__btn btn__export">
                            <ExportIcon className="mr__15" /> Export
                          </button>
                        }
                      >
                        <ExcelSheet
                          dataSet={this.state.multiDataSet2}
                          name="accounting_report"
                        ></ExcelSheet>
                      </ExcelFile>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  {_user && _user.role !== "strategist" &&
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom__select material-textfield-input"
                        name="stategist_id"
                        value={this.state.strategist_id}
                        onChange={this.handleChange.bind(this)}
                      >
                        <option value={0} key={0}>All</option>
                        {strategistList}
                      </select>
                      <label className="material-textfield-label">
                        Strategist
                      </label>
                    </div>
                  }
                </div>
                <div className="col-md-4 col-md-offset-4">
                  <ReportCustomeDatePicker
                    {...this.state}
                    filterByDate={this.filterByDate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  {
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom__select material-textfield-input"
                        name="stategist_id"
                        value={this.state.region}
                        onChange={this.filterByRegion.bind(this)}
                      >
                        <option value={0}>All</option>
                        {region}
                      </select>
                      <label className="material-textfield-label">
                        Region
                      </label>
                    </div>
                  }
                </div>
                <div className="col-md-4 col-md-offset-4">
                  {
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom__select material-textfield-input"
                        name="status_id"
                        value={this.state.status_id}
                        onChange={this.handleStatusChange.bind(this)}
                      >
                        <option value={0} key={0}>All</option>
                        {strategistStatusList}
                      </select>
                      <label className="material-textfield-label">
                        Strategist Status
                      </label>
                    </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <select
                      className="form-control custom__select material-textfield-input"
                      name="bd_source_id"
                      value={this.state.bd_source_id}
                      onChange={this.handleBdSources.bind(this)}>
                      <option value={0}>All</option>
                      {developmentMembersList}
                    </select>
                    <label className="material-textfield-label">BD Source</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search for a client by name..."
                      required
                    />
                  </div>
                </div>
              </div>
              <Spin
                size="large"
                spinning={this.state.isLoading}
                indicator={<Loader />}
              >
                {_.size(filterList) > 0 ? (
                  <div className="row">
                    <div className="col-md-12 ps-tble">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrder={true}
                          columns={
                            _user &&
                              (_user.role === "superadmin" ||
                                _user.email === "alyssa@suburbanjunglegroup.com")
                              ? columnDataSuperAdmin
                              : _user &&
                                (_user.role === "strategist" ||
                                  _user.role === "admin") &&
                                _user.role === "admin"
                                ? columnDataAdmin
                                : columnDataStrategist
                          }
                          dataSource={filterList}
                          pagination={{
                            defaultPageSize: Config.defaultPageSize,
                            showSizeChanger: true,
                            pageSizeOptions: Config.pageSizeOptions,
                          }}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="blank__table__state">Data Not Found</div>
                )}
              </Spin>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
