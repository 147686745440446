import { connect } from 'react-redux'
import {loginUser, handleSignOut, resetPasswordLink, resetPassword, fetchUser} from '../../store/user/duck'
import ResetForm from './component'
const Reset = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    resetEmailPhase: state.user.resetEmailPhase,
    resetPasswordPhase: state.user.resetPasswordPhase,
    isSubmitting: state.user.isSubmitting,
    forgotPasswordError: state.user.forgotPasswordError,
    resetPasswordError: state.user.resetPasswordError,
    phase: state.user.phase,
    notificationPhase: state.user.notificationPhase 
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut,
    resetPasswordLink,
    resetPassword,
    fetchUser
    // , setUnreadNotification
  }
)(ResetForm)
export default Reset
