/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Spin } from 'antd'
import $ from 'jquery'
import { map } from 'lodash'

import { SUCCESS } from '../../../../../../../constants/phase'
import Loader from '../../../../../../../components/Loader'
import ChatContent from './ChatContent'
import { createStringAvatar } from '../../../../../../../utils/helper'

export default function chatHistory(props) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (props.agentId) {
      setLoading(true)
      const clientId = props.match.params.id
      props.getChatHistory({
        senderId: parseInt(props.agentId),
        receiverId: clientId,
      })
    }
  }, [props.agentId])

  // cwrp on success of fetching client agent listing and client agent chat
  useEffect(() => {
    if (props.chatHisotryPhase === SUCCESS) {
      // $('.chats__container').animate({ scrollTop: $('.chats__container').prop("scrollHeight") }, 1000)
      setLoading(false)
      props.clearChatPhase()
    }
  }, [props.chatHisotryPhase])

  const userId = props.user && props.user.id

  return (
    <Spin size="large" spinning={isLoading} indicator={<Loader />}>
      <ul className="chats__wrapper__chats__list">
        {props.agentId && props.chatHistories && props.chatHistories.length > 0 ?
            map(props.chatHistories, (chat, index) => (
                <li
                  key={index}
                  className={`has__avatar ${
                    chat.sender_id === userId ? 'active' : ''
                  }`}
                >
                  <div className="chats__card__header">
                    <div className="chats__card__avatar">
                    {chat.sender_avatar ? <img src={chat.sender_avatar} alt="" /> :
                       <span className="string__avatar">{createStringAvatar(chat.sender_full_name)}</span>}
                    </div>
                    <span className="chats__card__name">
                      {chat.sender_full_name}
                    </span>
                    <span className="chats__card__time">
                      {moment(chat.created).format('MM/DD/YY [at] hh:mm A')}
                    </span>
                  </div>
                  <ChatContent
                    index={index}
                    chatHistory={props.chatHistories}
                    chat={chat}
                  />
                  {chat.sender_id === userId && (
                    <div className="chats__card__cta">
                      <button className="btn btn__edit_chat">Edit</button>
                      <button className="btn btn__delete_chat">Delete</button>
                    </div>
                  )}
                </li>
             ))
        :  ''}
        </ul>
    </Spin>
  )
}
