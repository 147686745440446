import React, { Fragment, useState } from 'react'
import { get } from 'lodash'

export default function AddBackgroundProfile({ isHtmlDocExists, clientId, addBackgroundProfile, clientDetail, handleBackgroundProfile, sendBackgroundData }) {
  // dynamic textarea for incress and decress box size
  const handleKeyDown = (event) => {
    event.persist()
    event.target.style.height = 'inherit'
    event.target.style.height = `${event.target.scrollHeight}px`
  }
  const [backgroundProfile, setBackgroundProfile] = useState("")

  const handleChange = ({ target }) => setBackgroundProfile(target.value)

  const cancleAddBackgroundProfile = () => handleBackgroundProfile()


  const handleSubmit = () => {
    if (backgroundProfile !== '' && clientId !== '') {
      sendBackgroundData({
        // eslint-disable-next-line camelcase
        client_id: clientId,
        content: backgroundProfile
      })
    }
  }

  return (
    <Fragment>
      {isHtmlDocExists && addBackgroundProfile &&
        <div className="card-body card-body-sm">
          <p className="card-subtitle" style={{"margin-bottom": "10px"}}>
            Notes are based on information the client provided to Suburban Jungle, written and/or verbal.
          </p>
          <div className="background__information__wrapper">
            <textarea
              className="form-control background__information__textarea"
              type="text"
              name="backgroundProfile"
              ref={(current) => current && current.focus()}
              onKeyDown={handleKeyDown}
              value={backgroundProfile}
              onKeyUp={handleKeyDown}
              style={{ resize: "none", overflowY: "hidden" }}
              onFocus={handleKeyDown}
              onChange={handleChange}
              placeholder={`Add background profile about ${get(clientDetail, "client_full_name", "")}...`}
              rows="4">
            </textarea>
            <div className="background__information__cta">
            <button className="btn btn__btn btn-secondry w__130 mr__15" onClick={cancleAddBackgroundProfile}>Cancel</button>
            <button className="btn btn__btn btn-dark w__130" onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}
