/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { Spin, notification } from 'antd'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { get, map, filter, find, size, compact, uniqWith, difference } from 'lodash'

// import TopNavigation from '../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, DeleteIcon } from '../../../../components/icons'
import RemoveTownModal from './partials/RemoveTownModal'
import { multiSelectStyles, uniqWithAgentAndTown } from './partials/variables'

import './styles.scss'
import SelectedTownList from './partials/SelectedTownList'
import { SUCCESS } from '../../../../constants/phase'
import Loader from '../../../../components/Loader'

export default class TownDetailsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      initializeTown: true,
      suburb: 0,
      newTowns: [],
      clientSuburbs: [],
      changeStrategy: false,
      isLoadingOption: true,
      townId: false,
      selectedAgentId: [],
      selectedLocal: [],
      removeTownModel: false,
      localCount: 0
    }
  }

  removeAgents = []
  removeLocals = []
  initAgents = {}

  componentDidMount() {
    const clientId = get(this.props, 'match.params.id', false)
    if (!clientId) {
      this.props.history.push('/strategist/clients/all-clients')
    }
    // eslint-disable-next-line camelcase
    this.props.getClientStrategist({ client_id: clientId })
    // eslint-disable-next-line camelcase
    this.props.getUserbyId({ client_id: clientId })
  }

  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    // on page first load load initial towns for taken first  suburbs
    if (get(props, 'singleClientData.user.suburbs', false) && state.initializeTown) {
      const suburbs = get(props, 'singleClientData.user.suburbs')
      const suburbId = suburbs.map((suburb) => suburb.suburb_id)
      const singleSelect = suburbId[0] ? [suburbId[0]] : suburbId
      props.getTowns({
        suburb_arr: singleSelect,
        client_id: parseInt(get(props, 'match.params.id')),
      })
      states = {
        ...states,
        initializeTown: false,
        suburb: suburbId,
        clientSuburbs: get(props, 'singleClientData.user.suburbs'),
      }
    }
    if (props.getClientStrategistPhase === SUCCESS) {
      const selectedAgentId = []
      const selectedLocal = []
      const strategists = get(props, 'strategyData.data', [])
      map(strategists, (strategy) => {
        if (strategy.role === 'agent') {
          selectedAgentId.push({
            agent_id: strategy.agent_id,
            suburb: strategy.suburb_id,
            town_id: strategy.town_id,
          })
        } else if (strategy.role === 'local') {
          selectedLocal.push({
            agent_id: strategy.agent_id,
            suburb: strategy.suburb_id,
            town_id: strategy.town_id,
          })
          selectedAgentId.push({
            agent_id: strategy.agent_id,
            suburb: strategy.suburb_id,
            town_id: strategy.town_id,
          })
        } else {
          selectedAgentId.push({
            agent_id: 0,
            suburb: strategy.suburb_id,
            town_id: strategy.town_id,
          })
        }
      })
      props.clearStrategistPhase()
      states = {
        ...states,
        selectedAgentId: uniqWithAgentAndTown(selectedAgentId),
        selectedLocal: uniqWithAgentAndTown(selectedLocal),
        isLoadingOption: false,
      }
    }
    if (props.addUpdateStrategistPhase === SUCCESS) {
      props.clearStrategistPhase()
      notification.success({
        message: `Success`,
        description: 'Strategy Updated Successfully.',
        placement: 'topRight' //'bottomRight',
      });
      const clientId = props.match.params.id
      // fetch new primary data for setup again
      props.getClientStrategist({ client_id: clientId })
      return { ...states, isLoadingOption: true }
    }
    return states
  }
  // go back to client page on click to back page
  backClientDetail = (clientId) =>
    this.props.history.push({
      pathname: `/strategist/client-details/${clientId}`,
      state: { prevRoute: get(this.props, 'history.location.state.prevRoute', '') },
    })
  // handle suburb change and get town list
  handleSuburbChange = ({ target }) => {
    const { name, value } = target
    const clientId = get(this.props, 'match.params.id', false)
    this.props.getTowns({
      suburb_arr: [parseInt(value)],
      client_id: parseInt(clientId),
    })
    this.setState({ suburb: value })
  }

  // add new town to the list for create new paylaod
  assignAgentToTown = ({ agent_id, town_id }) => {
    const { selectedAgentId, selectedLocal, suburb } = this.state
    const isAleradyAssign = find(selectedAgentId, { agent_id: 0, town_id })

    /**Remove agents array builder */

    if(!this.initAgents[town_id]){
      this.initAgents[town_id] = selectedAgentId.filter(slt => slt.town_id == town_id )
    }

    const rm = this.initAgents[town_id].find(i => agent_id == i.agent_id)

    if(rm)
      this.removeAgents = this.removeAgents.filter(i => i.agent_id !== agent_id && i.town_id !== town_id)
    else if(this.initAgents[town_id].length){
      const agt = this.initAgents[town_id][0]
      if(!this.removeAgents.find(i => i.agent_id == agt.agent_id && i.town_id == agt.town_id))
        this.removeAgents.push(agt)
    }
     
    /**************************************** */ 

    if (size(isAleradyAssign)) {
      const selectedAgent = map(selectedAgentId, (agent) => {
        if (agent.agent_id === 0 && agent.town_id === town_id) return { ...agent, agent_id }
        return agent
      })
      this.setState({ selectedAgentId: selectedAgent, changeStrategy: true })
    } else {

      const needToRemove = this.removeAgents.find(rm => rm.town_id !== town_id)
      
      let newAgentArr = []
      const removeTown = filter(selectedAgentId, (agent) => agent.town_id === town_id)
      const addTown = filter(selectedAgentId, (agent) => agent.town_id !== town_id)
      
      const towns = get(this.props, 'townData.data', [])
      let localsArr = []
      map(towns, (local) => {
        if(local.id == town_id) {
          for(let i=0;i<local.agents.length;i++) {
            if(local.agents[i].role == 'local' || local.agents[i].role == 'business development') {
              localsArr.push(local.agents[i].agent_id)
            }
          }
        }
      })

      map(removeTown, (rmt) => {
        if(localsArr.includes(rmt.agent_id)) {
          addTown.push(rmt)
        }
      })

      this.setState({
        selectedAgentId: [...addTown, { agent_id, town_id, suburb: parseInt(suburb) }],
        changeStrategy: true,
      })
    }
  }


  assignLocalToTown = (options, townId, checked, option) => {

    let { selectedLocal, selectedAgentId, suburb } = this.state
    let localsArr = []
    let agentsArr = []
    let flag = false

    /**Remove locals array builder */

    if(option.action === 'deselect-option'){
      this.removeLocals.push({ agent_id: option.option.value, town_id: townId })
    }
    else if(option.action === 'select-option'){
      let idx = this.removeLocals.findIndex(i => i.agent_id == option.option.value && i.town_id == townId)
      if(idx >= 0)this.removeLocals.splice(idx, 1)
    }

    /************************************/

    const towns = get(this.props, 'townData.data', [])
    map(towns, (local) => {
      if(local.id == townId) {
        for(let i=0;i<local.agents.length;i++) {
          if(local.agents[i].role == 'local' || local.agents[i].role == 'business development') {
            localsArr.push(local.agents[i].agent_id)
          } else if(local.agents[i].role == 'agent'){
            agentsArr.push(local.agents[i].agent_id)
          }
        }
      }
    })

    if(checked) {
      map(options, (option) => {
        const isLocalExists = selectedLocal.find(el => el.agent_id === option.value && el.town_id === townId)
        const isAgentExists = selectedAgentId.find(el => el.agent_id === option.value && el.town_id === townId)

        let a = {
          'agent_id': option.value,
          'town_id': townId,
          'suburb': parseInt(suburb)
        }
        if(!isLocalExists) selectedLocal.push(a)
        if(!isAgentExists) selectedAgentId.push(a)
      })
    }

    if(!options.length) {
      let filterSelectedLocal = selectedLocal.filter(sel => sel.town_id != townId)
      selectedLocal = filterSelectedLocal

      let filterSelectedAgent = selectedAgentId.filter(sel => sel.town_id != townId)
      map(selectedAgentId, (agt) => {
        if(agentsArr.includes(agt.agent_id) && agt.town_id === townId) {
          filterSelectedAgent.push(agt)
          flag = true
        }
      })
      if(!flag) {
        filterSelectedAgent.push({
          'agent_id': 0,
          'town_id': townId,
          'suburb': parseInt(suburb)
        })
      }
      selectedAgentId = filterSelectedAgent
    }
    if(!checked && options.length) {
      flag = false
      let newFilteredLocal = []
      let newFilteredAgent = []
      map(selectedLocal, (loc) => {
        map(options, (opt) => {
          if(loc.agent_id === opt.value && (loc.town_id === townId)) {
            newFilteredLocal.push(loc)
          }
          if(loc.town_id !== townId) {
            newFilteredLocal.push(loc)
          }
        })
      })

      map(selectedAgentId, (loc) => {
        map(options, (opt) => {
          if(loc.agent_id === opt.value && loc.town_id === townId) {
            newFilteredAgent.push(loc)
          }
          if(loc.town_id !== townId) {
            newFilteredAgent.push(loc)
          }
          if(agentsArr.includes(loc.agent_id) && loc.town_id === townId) {
            newFilteredAgent.push(loc)
            flag = true
          }
        })
      })
      if(!flag) {
        newFilteredAgent.push({
          'agent_id': 0,
          'town_id': townId,
          'suburb': parseInt(suburb)
        })
      }
      selectedLocal = newFilteredLocal
      selectedAgentId = newFilteredAgent
    }
    
    this.setState({
      selectedLocal: selectedLocal,
      selectedAgentId: selectedAgentId,
      changeStrategy: true,
      localCount: selectedLocal.length
    })
  }

  // save payload and call api to save data
  handleSubmit = () => {
    const { selectedAgentId } = this.state
    const clientId = this.props.match.params.id
    // old type payload remove object if is deleted
    const removeSuburbs = map(selectedAgentId, (agent) => ({
      agent_id: agent.agent_id,
      town_id: agent.town_id,
    }))
    // No need to uniqunes in payload data
    const townAgents = uniqWithAgentAndTown(removeSuburbs)

    const payload = {
      client_id: parseInt(clientId),
      town_agents: townAgents,
      remove_town_agents: [...this.removeAgents, ...this.removeLocals].filter(i => i.agent_id !== 0)
    }

    this.setState({ isLoadingOption: true, changeStrategy: false }, () => {
      // old api with payload for assigend town and agent
      this.props.addUpdateStrategist(payload)
      this.props.createStrategist({
        client_id: parseInt(clientId),
        townStrategies: townAgents,
      })
      this.removeAgents = []
      this.removeLocals = []
      this.initAgents = {}
    })
  }
  // handle multiselection option
  handleMultiSelectionOption = (options) => {
    const { selectedAgentId, suburb, selectedLocal } = this.state
    // get all towns values from options
    const newOptions = map(options, option => option.value)
    // check towns alerady exists and remove then for unselect option
    const removeTown = difference(
      compact(map(selectedAgentId, agent => {
        if (parseInt(suburb) === agent.suburb) {
          return agent.town_id
        }
      })),
      newOptions,
    )
    let newTowns = []
    if (size(removeTown) === 0) {
       newTowns = map(options, (option) => {
        // if selected town is alerady exists then return all assigned agent and same town
        const isAleradyExist = find(selectedAgentId, { town_id: option.value })
        if (size(isAleradyExist)) {
          // if alerdy exists then return same object to new towns
          return { agent_id: isAleradyExist.agent_id, town_id: isAleradyExist.town_id, suburb: parseInt(isAleradyExist.suburb) }
        }
        // assign agent to town on new option create new town
        this.assignAgentToTown({ agent_id: 0, town_id: option.value })
        return { agent_id: 0, town_id: option.value, suburb: parseInt(suburb) }
      })
    } else {
      // remove or unchecked same option from town option and list
      newTowns = filter(selectedAgentId, town => !removeTown.includes(town.town_id))
      const newSelectedLocal = filter(selectedLocal, town => !removeTown.includes(town.town_id))
      this.setState({ selectedAgentId: newTowns, selectedLocal: newSelectedLocal })
    }
    this.setState({ newTowns: uniqWithAgentAndTown(newTowns), changeStrategy: true })
  }
  removeTownRequestModal = (townId) => this.setState({ removeTownModel: true, townId })
  closeRemoveTownRequestModal = () => this.setState({ removeTownModel: false })

  handleRemoveTown = (townId) => {
    let { selectedAgentId, selectedLocal, newTowns } = this.state
    selectedAgentId = filter(selectedAgentId, (agent) => agent.town_id !== townId)
    selectedLocal = filter(selectedLocal, (agent) => agent.town_id !== townId)
    newTowns = filter(newTowns, (agent) => agent.town_id !== townId)
    this.setState({ selectedAgentId, selectedLocal, newTowns, changeStrategy: true })
  }
  render() {
    const { isLoadingOption, suburb } = this.state
    const clientId = get(this.props, 'match.params.id')
    const suburbs = get(this.props, 'singleClientData.user.suburbs', [])
    const towns = get(this.props, 'townData.data', [])
    const alreadySelectedTown = []
    const { selectedLocal, selectedAgentId, newTowns } = this.state
    const townOptions = map(towns, (town, index) => {
      const isAgentExists = find(selectedAgentId, { town_id: town.id })
      const isLocalExists = find(selectedLocal, { town_id: town.id })
      const isNewExists = find(newTowns, { town_id: town.id })
      if (size(isAgentExists) || size(isLocalExists) || size(isNewExists)) {
        alreadySelectedTown.push({ label: `${town.name}`, value: town.id, raw: town, suburb: parseInt(suburb) })
      }
      return { label: `${town.name}`, value: town.id, suburb: parseInt(suburb), raw: town }
    })

    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="client__details__header">
              <div className="row d__flex align__items__center">
                <div className="col-xs-6 col-md-4">
                  <button className="btn btn-back" onClick={() => this.backClientDetail(clientId)}>
                    <BackArrowIcon className="arrow-icon" />
                    Back Client Detail
                  </button>
                </div>
                <div className="col-xs-6 col-md-8 text-right"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-2">
                <h1 className="title__section">Town Details</h1>
                <h4 className="title__sub__section">
                  Select which towns that best suit this client, then add an agent to that specific town.
                </h4>
              </div>

              <div className="col-md-10">
                <div className="form-group material-textfield">
                  <select
                    className="form-control custom__select material-textfield-input textfield-input-lg"
                    onChange={this.handleSuburbChange}
                    name="suburb"
                    value={this.state.suburb}
                  >
                    {map(suburbs, (suburb, index) => (
                      <option key={index} value={suburb.suburb_id}>
                        {suburb.suburbs_name}
                      </option>
                    ))}
                  </select>
                  <label className="material-textfield-label label-lg">Suburb</label>
                </div>
                <Spin size="large" spinning={isLoadingOption} indicator={<Loader />}>
                  <div className="search__form mt__0">
                    <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                      <ReactMultiSelectCheckboxes
                        styles={multiSelectStyles}
                        width="100%"
                        name="towns"
                        value={alreadySelectedTown}
                        placeholderButtonLabel="Search by town name to add to clients strategy..."
                        options={townOptions}
                        onChange={(event) =>
                          this.handleMultiSelectionOption(event, this.state.suburb)
                        }
                      />
                      <label className="multiselect__checkboxes__field__label">
                        Search Town Name<span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  {alreadySelectedTown.length > 0 && (
                    <SelectedTownList
                      selectedSuburb={this.state.suburb}
                      selectedTowns={alreadySelectedTown}
                      selectedLocal={selectedLocal}
                      assignLocalToTown={this.assignLocalToTown}
                      newTowns={newTowns}
                      selectedAgentId={selectedAgentId}
                      assignAgentToTown={this.assignAgentToTown}
                      removeTownRequestModal={this.removeTownRequestModal}
                      townData={this.props.townData}
                    />
                  )}
                </Spin>
                <button
                  className="btn btn__btn btn-dark btn__lg w__100"
                  onClick={this.handleSubmit}
                  disabled={!this.state.changeStrategy}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </main>
        <RemoveTownModal
          openModel={this.state.removeTownModel}
          handleRemoveTown={this.handleRemoveTown}
          closeRemoveTownRequestModal={this.closeRemoveTownRequestModal}
          townId={this.state.townId}
        />
      </div>
    )
  }
}
