import React from 'react'
import { map } from 'lodash'
import config from '../../../../config'

// return options for role based select role

/**
 * 
 * @param Object user object with role attribute
 * @returns array of <option></option> objects of roles available to the current user
 */
export const roleListOption = (user) => {
  
  // The only users that can create users are strategists and admins
  if (!['admin', 'superadmin', 'strategist'].includes(user.role)) return []

  // Strategists can only create users of the following type
  const allowedRolesForStrategistsToCreate = ['agent', 'prospect-agent','former-agent']

  // Returned filtered list of options
  return map(config.roleList, (role, index) => {
    if (user.role === 'strategist' && !allowedRolesForStrategistsToCreate.includes(role.key)) return 
    else if (role.key === 'superadmin') return 
    else return (<option key={index} value={role.key}>{role.name}</option>)
  })

}


export const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}


export const initialValues = {
  selectedRole: "",
  firstName: "",
  lastName: "",
  email: "",
  timeZone: "",
  countryCode: "+1",
  mobileNumber: "",
  conferenceContryCode: "",
  conferenceMobileNumber: "",
  acuityCalendarId: "",
  minPrice: "",
  maxPrice: "",
  firmAddress: "",
  title: "",
  strategyToggle: true,
  isImpersonate: true,
  limitClients: false,
  towns: [],
  strategistAssignmentRules: [],
  bio: "",
  hourlyAmount: "",
  payScale: "",
  dealPayout: "",
  dealPayoutAmount: "",
  refferalPayout: "",
  refferalPayoutAmount: "",
  assignedSuburbs: [],
  searchTownOption: [],
  selectedSearchTown: [],
  seletedSearchByStrategist: []
} 

/**
 * Given the current user's role, and the role of the user being created, determine whether or not the
 * field should be displayed.
 * 
 * @param string currentUserRole role of user accessing form
 * @param string selectedRole selected role of user being created
 * @param string fieldKey key of the field for the add/edit user forms
 * 
 * The following components can be used in the fieldKey:
 * MinMaxPrice
 * PayoutAdditionalFields
 * SuburbsCheckboxList
 * SelectTown
 * AgentAgreement
 * 
 * @returns bool 
 */
export function displayUserFormFieldForCurrentUserAndSelectedRole(currentUserRole, selectedRole, fieldKey) {

  // Fields to be displayed when creating or managing all users
  const allUserFields = [
    'image',
    'firstName', 'first_name',
    'lastName', 'last_name',
    'email',
    'countryCode',
    'country_code', 
    'mobileNumber', 
    'phone_mobile',
    'time_zone',
    'timeZone'
  ]

  // Fields to be displayed when creating or managing admin users
  const adminUserFields = allUserFields.concat([
    'title', 'designation',
    'conferenceContryCode',
    'conferenceMobileNumber', 'phone_work',
    'MinMaxPrice',
    'strategyToggle', 'is_schedule_on',
    'is_copy_email',
    'acuityCalendarId', 'calendar_id',
    'payScale', 'pay_scale',
    'hourly_amount', 'hourlyAmount',
    'PayoutAdditionalFields',
    'bio', 'description',
    'SuburbsCheckboxList'
  ])

  // Fields to be displayed when creating or managing strategist users
  const strategistUserFields = allUserFields.concat([
    'title', 'designation',    
    'conferenceContryCode',
    'conferenceMobileNumber', 'phone_work',
    'MinMaxPrice',
    'strategyToggle', 'is_schedule_on',
    'is_copy_email',
    'acuityCalendarId', 'calendar_id',
    'payScale', 'pay_scale',
    'hourly_amount', 'hourlyAmount',
    'PayoutAdditionalFields',
    'bio', 'description',
    'SuburbsCheckboxList',
    'isImpersonate'
  ])
  
 
  // Fields to be displayed when creating or managing client users
  const clientUserFields = allUserFields.concat([]) // None specified beyond fields for all 

  // Fields to be displayed when creating or managing agent users
  const agentUserFields = allUserFields.concat([
    'firm_name',
    'agent_town',
    'zillow_profile',
    'business_profile',
    'bio', 'description',
    'limitClients', 'is_limit',
    'SuburbsCheckboxList',
    'SelectTown',
    'AgentAgreement'
  ])

  // Fields to be displayed when creating or managing business development users
  const businessDevelopmentUserFields = allUserFields.concat([
    'bio', 'description'
  ])

  switch (selectedRole) {

    case 'admin':
    case 'superadmin': {
      return adminUserFields.includes(fieldKey)
    }

    case 'strategist':
    case 'former-strategist-inactive': {
      return strategistUserFields.includes(fieldKey)
    }

    case 'client': {
      return clientUserFields.includes(fieldKey)
    }

    case 'agent':
    case 'prospect-agent': 
    case 'former-agent': {
      return agentUserFields.includes(fieldKey)
    }

    case 'business development':
    case 'former business development': {
      return businessDevelopmentUserFields.includes(fieldKey)
    }
  
  }

}