import { connect } from 'react-redux'
import {loginUser, fetchUser, getNotifications, clearNotification} from '../../store/user/duck'
import {getClientList, 
        getFilterParams,
        addUpdateClient,
        clearPhase,
        impersonateUser
       } from '../../store/dashboard/duck'
import ClientsListForm from './component'
const ClientsList = connect(
  // Map state to props
  (state) => ({
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    profilePhase: state.user.profilePhase,
    profileError: state.user.profileError,
    user: state.user.users,
    clientData: state.dashboard.clientData,
    isSubmitting: state.user.isSubmitting,
    error: state.dashboard.error,
    addUpdateClientPhase: state.dashboard.addUpdateClientPhase,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase
  }),
  // Map actions to props
  {
    loginUser,
    fetchUser,
    getClientList,
    getFilterParams,
    addUpdateClient,
    clearPhase,
    impersonateUser,
    getNotifications,
    clearNotification
  }
)(ClientsListForm)
export default ClientsList
