import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'

// fetch appointment epic for list
const fetchAppointmentEpic = (action$) =>
  action$.ofType(types.FETCH_APPOINTMENT_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchAppointment(action.payload))
      .map((payload) => ({
        type: types.FETCH_APPOINTMENT_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_APPOINTMENT_LIST_ERROR,
          payload: { error },
        })
      )
  })

// get strategist availablity epic
const getStrategistAvailabilityEpic = (action$) =>
  action$.ofType(types.FETCH_STRATEGIST_AVAILABILITY).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getStrategistAvailability(action.payload))
      .map((payload) => ({
        type: types.FETCH_STRATEGIST_AVAILABILITY_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_STRATEGIST_AVAILABILITY_ERROR,
          payload: { error },
        })
      )
  })
// get strategist availablity epic
const getAgentAvailabilityEpic = (action$) =>
  action$.ofType(types.FETCH_AGENT_AVAILABILITY).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAgentAvailibity(action.payload))
      .map((payload) => ({
        type: types.FETCH_AGENT_AVAILABILITY_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_AGENT_AVAILABILITY_ERROR,
          payload: { error },
        })
      )
  })
// get strategist availablity epic
const getStrategistAvailabilityDatesEpic = (action$) =>
  action$.ofType(types.GET_STRATEGIST_AVAILLABLE_DATES).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getStrategistAvaillableDates(action.payload))
      .map((payload) => ({
        type: types.GET_STRATEGIST_AVAILLABLE_DATES_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_STRATEGIST_AVAILLABLE_DATES_ERROR,
          payload: { error },
        })
      )
  })
// create agent appointment availablity epic
const createAgentAppointmentEpic = (action$) =>
  action$.ofType(types.CREATE_AGENT_APPOINTMENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createAgentAppointment(action.payload))
      .map((payload) => ({
        type: types.CREATE_AGENT_APPOINTMENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_AGENT_APPOINTMENT_ERROR,
          payload: { error },
        })
      )
  })
// create strategist appointment availablity epic
const createStrategistAppointmentEpic = (action$) =>
  action$.ofType(types.CREATE_STRATEGIST_APPOINTMENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createStrategistAppointment(action.payload))
      .map((payload) => ({
        type: types.CREATE_STRATEGIST_APPOINTMENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_STRATEGIST_APPOINTMENT_ERROR,
          payload: { error },
        })
      )
  })

// combine appointment epic
export const appointmentEpic = combineEpics(
  fetchAppointmentEpic,
  getStrategistAvailabilityEpic,
  getStrategistAvailabilityDatesEpic,
  createStrategistAppointmentEpic,
  createAgentAppointmentEpic,
  getAgentAvailabilityEpic
)
