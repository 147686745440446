/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import ReactModal from 'react-modal'
import _, { get, map, compact } from "lodash"
import Cookies from 'universal-cookie'
import moment from "moment"
import { message, DatePicker } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { CloseIcon, DownCaretIcon } from '../../../../components/icons'
import config, { timeConverter } from '../../../../config'
import Validation from '../../../../config/validation'
import UserContext from "../../../App/Context"
import './styles.scss';

const cookies = new Cookies()

class AppointmentComponent extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      selectAppointmentDate: moment(),
      selectAppointment: "",
      availabilityTimes: []
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeAppointmentModal = this.closeAppointmentModal.bind(this)
  }
  componentDidMount() {
    const agentclients = {
      sort: '',
      sort_value: 0,
      limit: 1000,
      offset: 1,
      "No Follow Up": 0,
      "No Status": 0,
      "No Town Tour": 0,
      "Overdue Followups": 0,
      status: [],
      strategist: []
    }
    // fetch all client of that agent
    this.props.fetchAgentAllClient(agentclients, "all")
    const user = cookies.get('user')
    if (this.props.getAgentTimeSlotAvailability) {
      this.props.getAgentTimeSlotAvailability({
        // eslint-disable-next-line camelcase
        user_id: user.id
      })
    }
  }
  // close appointment modal
  closeAppointmentModal() {
    this.setState({ values: {} })
    this.props.closeAppointmentModal()
  }
  static getDerivedStateFromProps(props, state) {
    // on delete appointment show success message and clear phase for new props
    if (props.addAppointmentPhase === "success") {
      if (get(state, "values.calendarId", false)) {
        // if calendarId is present it will update appointment
        message.success('Appointment Updated Successfully.')
      } else {
        // if calendarId is present it will create new appointment
        message.success('Appointment Created Successfully.')
      }
      // refetch appointmnet list
      props.reFetchAppointment()
      // clear phase action remove deleteAppointmentPhase success phase
      props.clearPhase()
      return { ...state, values: {} }
    }

    if (props.getAgentTimeAvailabilityPhase === "success") {
      const data = get(props, "agentTimeAvailability", [])
      const availabilityTimes = map(data, selection => {
        const availabilities = selection.availability ? selection.availability : []
        const availability = map(availabilities, availability => {
          return {
            id: availability.id,
            start: moment(availability.start, "H:mm").format('HH:mm'),
            end: moment(availability.end, "H:mm").format('HH:mm')
          }
        }
        )
        return { day: get(selection, "day", ""), availability }
      })
      // clear phase action remove deleteAppointmentPhase success phase
      props.clearPhase()
      return { ...state, availabilityTimes }
    }

    return props;
  }
  // on handle data change date with call appointment availability with date
  handleDateChange = (selectAppointmentDate, values) => {
    if (selectAppointmentDate) {
      this.setState({ values: { ...values, selectAppointmentDate } })
    }
  }
  // submit data and create/Update new Appointment
  handleSubmit(values) {
    const user = this.context.userData
    const data = {
      timezone: user.time_zone,
      user_id: user.id,
      appointment_type: values.selectAppointment === "Follow up agent town tour" ? "Follow up agent visit" : values.selectAppointment,
      calendar_id: values.calendarId ? values.calendarId : '',
      date: moment(values.selectAppointmentDate).format("YYYY-MM-DD"),
      start_time: values.selectStartTime,
      end_time: values.selectEndTime
    }
    // appointmnet type is Busy then we should save as null client wtih
    // Busy description
    if (values.selectAppointment === "Busy") {
      data.client_id = 0
      data.description = "Busy"
    } else {
      data.client_id = parseInt(values.selectClient)
      data.description = values.description
    }
    this.setState({ values })
    // add and update appointment
    this.props.addAgentAppointment(data)
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  // start time selection for 2 hr time slot for Initial agent call
  availableStartTimeSlots = (appointmentDate, event) => {
    const day = moment(appointmentDate).day();

    const selectedDay = _.find(this.state.availabilityTimes, { day: `${day}` });
    const times = _.chain(selectedDay).get("availability", []).orderBy(['start'], ['asc']).valueOf();

    const checkBetween = (time) => {
      time = moment(time, "HH:mm")

      let isBetween = false;

      for (let i = 0; i < times.length; i++) {
        const t = times[i];

        const start = moment(t.start, "HH:mm");
        const end = moment(t.end, "HH:mm");

        isBetween = time.isSameOrAfter(start) && time.isBefore(end);

        if (isBetween) {
          break;
        }
      }

      return isBetween;
    };

    // get time from configuration for half hours slots
    let startTimes = config.timeslotHalfHours.map(t => checkBetween(t) ? t : null).filter(x => x);
    // check user wants to create today appointment
    if (moment().format("YYYY-MM-DD") === moment(appointmentDate).format("YYYY-MM-DD")) {
      // remove from current time
      startTimes = _.filter(startTimes, startTime => moment().format("HH:mm") <= startTime)
      if (event.start_date) {
        if (!startTimes.includes(moment(event.start_date).format("HH:mm"))) {
          return [
            <option key={'default'} value={moment(event.start_date).format("HH:mm")} disabled={true}>{timeConverter(moment(event.start_date).format("HH:mm"))}</option>,
            ...map(startTimes, (slotval, indexslot) => {
              const pos = startTimes.length - 1
              // disable last element from time slot
              if (pos !== indexslot) {
                return <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
              }
            })
          ];
        }
      }
    }
    if(startTimes.length === 0){
      return <option key="crt" value="crt">Create Availablity Time Slot</option>
    }
    return map(startTimes, (slotval, indexslot) => {
      return <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
    });
  }
  // end time selection based on appointment type and selected start appointment type
  availableEndTimeSlots = (appointmentDate, selectedStartTime, event) => {
    const day = moment(appointmentDate).day();

    const selectedDay = _.find(this.state.availabilityTimes, { day: `${day}` });
    const times = _.chain(selectedDay).get("availability", []).orderBy(['start'], ['asc']).valueOf();

    const checkBetween = (time) => {
      time = moment(time, "HH:mm")

      let isBetween = false;

      for (let i = 0; i < times.length; i++) {
        const t = times[i];

        const start = moment(t.start, "HH:mm");
        const end = moment(t.end, "HH:mm");

        isBetween = time.isSameOrAfter(start) && time.isSameOrBefore(end);

        if (isBetween) {
          break;
        }
      }

      return isBetween;
    };

    // get time from configuration for half hours slots
    let endTimes = config.timeslotHalfHours.map(t => checkBetween(t) ? t : null).filter(x => x);
    if (moment().format("YYYY-MM-DD") === moment(appointmentDate).format("YYYY-MM-DD")) {
      // remove from current time
      endTimes = _.filter(endTimes, startTime => moment().format("HH:mm") <= startTime)
      if (event.end_date) {
        if (!endTimes.includes(moment(event.end_date).format("HH:mm"))) {
          return [
            <option key={'default'} value={moment(event.end_date).format("HH:mm")} disabled={true}>{timeConverter(moment(event.end_date).format("HH:mm"))}</option>,
            ...map(endTimes, (slotval, indexslot) => {
              const pos = endTimes.length - 1
              // disable last element from time slot
              if (pos !== indexslot) {
                return <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
              }
            })
          ];
        }
      }
    }
    // if appoinment is not initial agent call return all time slot for end time
    return _.filter(endTimes, startTime => selectedStartTime < startTime).map((endTime, endIndex) => (
      <option key={endIndex} value={endTime}>{timeConverter(endTime)}</option>
    ))
  }

  getAppointmentType = (isEdit, appointmentTypeDetail) => {
    let appointmentType = !isEdit ? get(appointmentTypeDetail, "values.selectAppointment", "") : get(appointmentTypeDetail, "appointment_type", "")
    if (appointmentType === "Follow up agent visit") {
      appointmentType = "Follow up agent town tour"
    } else if (appointmentType === "Initial Agent Town Call") {
      appointmentType = "Initial agent call"
    } else if (appointmentType === "Initial Town Tour") {
      appointmentType = "Initial agent town tour"
    } else if (appointmentType === "Follow up Town Tour") {
      appointmentType = "Follow up agent town tour"
    }
    return appointmentType;
  }
  // is agent is able to create schedule or not
  isAbleToReschedule = (isSubmitting, appointmentDate, startTime) => {
    // if agnet create future appointment and form is completed
    if (moment().format("YYYY-MM-DD") < moment(appointmentDate).format("YYYY-MM-DD")) {
      return isSubmitting
      // if agent update past appointment and selecte past date can't update appointment
    } else if (moment().format("YYYY-MM-DD") > moment(appointmentDate).format("YYYY-MM-DD")) {
      return true
      // if agent update or create new appointment
    } else if (moment().format("YYYY-MM-DD") === moment(appointmentDate).format("YYYY-MM-DD")) {
      // check time is passed if passed he can't create appointment
      return moment().format("HH:mm") > startTime ? true : false
    }
    // if it failed all above condition which is optional
    return true
  }

  // return only selected client only option for client client list
  editClientOption = (clientId) => {
    const agents = get(this.props, 'agentAllClients.data.clients', [])
    // list of all agents for client data and return only selected data
    const clientList = compact(
      map(agents, (val, index) => {
        if (val.id === parseInt(clientId)) {
          return (
            <option key={index} value={val.id}>
              {val.full_client_name}
            </option>
          )
        }
      })
    )
    return clientList
  }

  generate24HrTimeSlots = (startTime = "07:00") => {

    const timeSlotDurationMinutes = 15;
    const timeSlots = [];

    let start = moment(`2023-01-01 ${startTime}`);
    const end = moment(`2023-01-01 22:00`);
    let i = 1;
    
    while (start < end) {
      const optionValue = start.format("HH:mm"); // 15:30
      const optionDisplay = start.format("h:mm a"); // 3:30 pm
      timeSlots.push({
        optionKey: i,
        optionValue,
        optionDisplay
      });
      start.add(timeSlotDurationMinutes, "minutes");
      i++;
    }

    return timeSlots.map(slot => {
      return (
        <option key={slot.optionKey} value={`${slot.optionValue}`}>
          {slot.optionDisplay}
        </option>
      )      
    })

  }
  
  render() {
    const { isEdit, agentAllClients, selectAppointmentDate, eventStartTime, clientName, eventEndTime, reScheduleDate } = this.props
    // appointment options for showing appointment type for create appointment
    const appointmentOptions = config.typeOfAppointments.map((val, index) => (
      <option key={index} value={val.value}>{val.name}</option>
    ));
    const agents = agentAllClients && agentAllClients.data && agentAllClients.data.clients ? agentAllClients.data.clients : []
    // list of all agents for client data
    const clientList = map(agents, (val, index) => (
      <option key={index} value={val.id}>{val.full_client_name}</option>
    ));
    const selectedAppointmentDate = isEdit ?
      // if appointment is edit show date from selected event
      reScheduleDate ? moment(reScheduleDate) : moment(get(this.props, "event.raw.start_date", new Date()))
      // if select appointment date is change
      : get(this.state, "values.selectAppointmentDate", false) ? moment(new Date(this.state.values.selectAppointmentDate)) :
        // select appointment date from selected appointment date
        selectAppointmentDate ? moment(new Date(selectAppointmentDate)) : moment()
    const selectedEvent = get(this.props, "event.raw", {})
    const appointmentType = this.getAppointmentType(isEdit, !isEdit ? this.state : selectedEvent)

    const description = !isEdit ?
      get(this.state, "values.description", "") ? get(this.state, "values.description", "") : get(this.props, "event.raw.description", "") :
      get(selectedEvent, "description", "") ? get(selectedEvent, "description", "") : ""
    return (
      <Fragment>
        <ReactModal
          isOpen={this.props.openAppointment}
          onRequestClose={this.closeAppointmentModal}
          contentLabel={isEdit ? "Edit Appointment" : "Create Appointment"}
          portalClassName="react-modal appointment-model"
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              // initial values
              // isEdit is value if present get data from event
              calendarId: !isEdit ?
                get(this.state, "values.calendarId", "") ? get(this.state, "values.calendarId", "") : "" :
                get(selectedEvent, "event_id", "") ? get(selectedEvent, "event_id", "") : "",
              description,
              selectClient: !isEdit ?
                get(this.state, "values.selectClient", "") ? get(this.state, "values.selectClient", "") : clientName :
                get(selectedEvent, "client_id", "") ? get(selectedEvent, "client_id", "") : clientName,
              selectAppointment: appointmentType,
              selectEndTime: !isEdit ? get(this.state, "values.selectEndTime", eventEndTime) : eventEndTime,
              selectStartTime: !isEdit ? get(this.state, "values.selectStartTime", eventStartTime) : eventStartTime,
              selectAppointmentDate: selectedAppointmentDate
            }}
            validationSchema={Validation.createAppointment}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              this.handleSubmit(values)
              // this.submitData(values, setSubmitting)
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              setFieldValue
            }) => {
              
              if(values.selectStartTime === 'crt'){
                  if(window.location.pathname.startsWith('/agent/calendar')){
                    if(typeof this.props.onNewSlotRequest === 'function')
                      this.props.onNewSlotRequest()
                  }
                  else{
                    this.props.history.push({
                      pathname: '/agent/calendar',
                      state: { showTimerDrawer: true }
                    })
                  }

                  return null
              }

              return (
              <div className="react-modal-dialog react-modal-dialog-centered appointment-container">
                <Form>
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">
                      {isEdit ? "Edit Appointment" : "Create Appointment"}
                    </h5>
                    <button type="button" className="btn react-modal-close" onClick={this.closeAppointmentModal}><CloseIcon /></button>
                  </div>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        className="form-control custom-select material-textfield-input textfield-input-lg"
                        name="selectAppointment"
                      >
                        <option value="">Select the type of Appointment</option>
                        {appointmentOptions}
                        <option value="Busy">Not Available</option>
                      </Field>
                      <label className="material-textfield-label label-lg">Appointment Type</label>
                      <ErrorMessage className="invalid-feedback" name="selectAppointment" component="div" />
                    </div>
                    {values.selectAppointment !== "Busy" &&
                      <div className="form-group material-textfield">
                        <Field
                          as="select"
                          name="selectClient"
                          className="form-control custom-select material-textfield-input textfield-input-lg"
                        >
                          <option value="">Select the client</option>
                          {isEdit ? this.editClientOption(values.selectClient) : clientList}
                        </Field>
                        <label className="material-textfield-label label-lg">Client Name</label>
                        <ErrorMessage className="invalid-feedback" name="selectClient" component="div" />
                      </div>}
                    <div className="form-group material-textfield">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">Date</div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={date => {
                            setFieldValue("selectAppointmentDate", date)
                            this.handleDateChange(date, values)
                          }}
                          disabledDate={this.disabledDate}
                          name="selectAppointmentDate"
                          inputReadOnly={true}
                          allowClear={false}
                          format="MM/DD/YYYY"
                          value={values.selectAppointmentDate}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                      <ErrorMessage className="invalid-feedback" name="selectAppointmentDate" component="div" />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            as="select"
                            name="selectStartTime"
                            className="form-control custom-select material-textfield-input textfield-input-lg"
                          >
                            <option value="">Start Time</option>
                            {/* Create option for start time using selected appointmnent date */}
                            {/*{this.availableStartTimeSlots(values.selectAppointmentDate, selectedEvent)}*/}
                            {this.generate24HrTimeSlots()}
                          </Field>
                          <label className="material-textfield-label label-lg">Start Time</label>
                          <ErrorMessage className="invalid-feedback" name="selectStartTime" component="div" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            as="select"
                            name="selectEndTime"
                            className="form-control custom-select material-textfield-input textfield-input-lg"
                          >
                            <option value="">End Time</option>
                            {/* Create option of end time with respect start time */}
                            {this.generate24HrTimeSlots(values.selectStartTime)}
                            {/*{this.availableEndTimeSlots(values.selectAppointmentDate, values.selectStartTime, selectedEvent)}*/}
                          </Field>
                          <label className="material-textfield-label label-lg">End Time</label>
                          <ErrorMessage className="invalid-feedback" name="selectEndTime" component="div" />
                        </div>
                      </div>
                    </div>
                    {values.selectAppointment !== "Busy" &&
                      <div className="form-group material-textfield">
                        <Field
                          component="textarea"
                          type="text"
                          className="form-control material-textfield-input"
                          rows="8"
                          name="description"
                        />
                        <label className="material-textfield-label">Appointment Description</label>
                        <ErrorMessage className="invalid-feedback" name="description" component="div" />
                      </div>}
                    <button className="btn btn__btn btn-dark btn__lg w__100" type="submit" disabled={this.isAbleToReschedule(isSubmitting, values.selectAppointmentDate, values.selectStartTime)} >{values.selectAppointment === "Busy" ? "Save" : "Schedule"}</button>
                  </div>
                </Form>
              </div>
            )
            }}
          </Formik>
        </ReactModal>
      </Fragment >
    )
  }
}
export default AppointmentComponent;
