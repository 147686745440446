import React, { PureComponent /*, PropTypes*/ } from 'react'
import { DatePicker } from 'antd';
import { SortUpIcon, SortDownIcon, DownCaretIcon, CaretRightIcon } from '../../../../../../components/icons'
import './styles.scss'

export default class CheckListComponent extends PureComponent {

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              Client Name: Jane Doe
              Budget: $400,000 - $500,000
              Status: Green
              Days Open: 23 days
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <button className="btn btn__btn btn-dark w__100 mb__10">Text Client</button>
              <button className="btn btn__btn btn-dark w__100 mb__10">Chat Client</button>
              <button className="btn btn__btn btn-dark w__100 mb__10">Schedule Client</button>
              <button className="btn btn__btn btn-dark w__100">Email Client</button>
            </div>
          </div>
        </div>
        {/* <div className="btn__follow-up-date">
          <div className="follow-up-date-label">Follow up date</div>
          <DatePicker
            className="datepicker__follow-up-date"
            dropdownClassName="datepicker__overlay__follow-up-date"
            defaultValue="02/28/20"
            format="MM/DD/YYYY"
            inputReadOnly={true}
            allowClear={false}
            placeholder="N/A"
          />
          <DownCaretIcon className="caret-icon" />
        </div> */}
      </div>
    )
  }
}
