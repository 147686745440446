import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { StickyContainer, Sticky } from 'react-sticky'
import { DateRange } from 'react-date-range'
import Modal from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class StrategistStatistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      // fromDate: moment().add(-1, 'month').startOf('month'),
      fromDate: moment('2015-01-01'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      selectedDefault: 'all',
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment('2015-01-01'),
      defaultEndDate: moment()
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedDefault === "all"){
        strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'total'
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'any'
      }
      strData.strategist_arr = this.state.client_strategist
      // this.props.getStrategistList(strData)
      this.props.getStrategistStatistics(strData)
      this.setState({isLoadingData: true})
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.strategistStatisticsPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount() {
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ strategistDropdown: false });
        }
      }
    });
    $(document).ready(function() {
      $('tbody').scroll(function(e) { //detect a scroll event on the tbody
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
      });
    });
  }

  datePickerClick(e){
    // this.setState({ datePickerModal: !this.state.datePickerModal, modalIsOpen: true })
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  // handleFromDate(date) {
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedDefault){
  //       strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //       strData.selectedValue = 'total'
  //     }else{
  //       strData.from = selectDate
  //       strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //       strData.selectedValue = 'any'
  //     }
  //     // strData.from = selectDate
  //     // strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.dateType = "from_date"
  //     strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getStrategistStatistics(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date){
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedDefault){
  //       strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //       strData.selectedValue = 'total'
  //     }else{
  //       strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = selectDate
  //       strData.selectedValue = 'any'
  //     }
  //     // strData.to = selectDate
  //     // strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //     strData.dateType = "end_date"
  //     strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getStrategistStatistics(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }

  toggledropDown(val){
    if(val==='filterStrategist'){
      this.setState({strategistDropdown: !this.state.strategistDropdown })
    }
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status , client_suburb } = this.state;
    if(type === 'strategistFilter'){
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } 
      else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_strategist : client_strategist })
    const strData = {}
    // strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    // strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    if(this.state.selectedDefault === "all"){
      strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'total'
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'any'
    }
    strData.strategist_arr = client_strategist
    this.props.getStrategistStatistics(strData)
  }

  filterDefaultDate(e){
    const strData = {}
    this.setState({ isLoadingData: true },() => {})
    if(e.target.value === "all"){
      // this.setState({ datePickerModal:false })
      this.setState({ modalIsOpen:false })
      strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'total'
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'any'
    }
    strData.strategist_arr = this.state.client_strategist
    this.props.getStrategistStatistics(strData)
    this.setState({ selectedDefault: e.target.value },() => {})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: this.state.fromDate, defaultEndDate: this.state.endDate }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedDefault === "all"){
            strData.from = moment('2015-01-01').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
            strData.selectedValue = 'total'
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
            strData.selectedValue = 'any'
          }
          strData.dateType = "from_date"
          strData.strategist_arr = this.state.client_strategist
          this.props.getStrategistStatistics(strData)
        }.bind(this), 300)
    })
  }
  
  render() {
    const { datePickerModal } = this.state
    const { strategistStatisticsData, filterParams } = this.props
    let allData = filterParams && filterParams.data;
    return (
      <div>        
          <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Strategist Statistics</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{strategistStatisticsData && strategistStatisticsData.length }</span> Strategist</h4></div>
            <div className="col-md-12">
            {/*<span className="defaultCheckboxContainer">
              <label className="defaultChecboxCustom">
              <input type="checkbox"  checked = {this.state.selectedDefault} className="custom-select-option-checkbox" onChange={this.filterDefaultDate.bind(this,!this.state.selectedDefault)}/>
               <span>
              Show all results
              </span>
              </label>
            </span> */}
            <div className="reports__flex">
            <div className={ (_user && _user.role === "superadmin" || _user && _user.role === "admin") ? "dp-wrapper dp-wrapper__minus" : "dp-wrapper" }>
             
             {
              (_user && _user.role === "superadmin" || _user && _user.role === "admin")?
                <div>
                  <div id="dropdownBtn" className={this.state.strategistDropdown ? "cust__hover custom-select custom-sel-filter cust__sel--filter" :  "custom-select custom-sel-filter cust__sel--filter"} onClick={this.toggledropDown.bind(this, 'filterStrategist')}>All Strategist</div>
                  {this.state.strategistDropdown && 
                    <div id="dropdownBtn" className= "selouter__ext sel-outer selouter__strat list__hide--hor">
                      {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                       let selectedIndex = false;
                        _.find(this.state.client_strategist, function(o) {
                          if(o === strategistsval.id){
                            selectedIndex = true;
                          } 
                       });
                       return (
                          <div key={strategistsindx}>
                          {!_.includes([4943,7042,7100,5458,6801,6879,6878,5046,2407], strategistsval.id) ? 
                            (
                              <li className="custom-select-option">
                                <span  className="coverage__checkbox">
                                  <label className="custom-checkbox">
                                  {strategistsval.strategist_name}
                                  <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id, 'strategistFilter')}/>
                                    <span className="checkmark"></span>
                                  </label>
                                </span>
                              </li>
                            ): ''
                          }
                        </div>
                       )
                      })}
                    </div>
                  }
                </div>
                : ""
             }
            </div> 
        
            <div className="right__cont">
              <div className="all__custom--wrapper">
                <select 
                  className="date__picker"
                  name="selectedDefault"
                  value = {this.state.selectedDefault} 
                  onChange={this.filterDefaultDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

            {
              (this.state.selectedDefault !== "all") &&
              <div className="datepicker__fix all__custom--wrapper">
                <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                {
                  (this.state.modalIsOpen) &&
                  <div>
                    <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={customStyles}           
                      contentLabel="Example Modal">
                      <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                        <div className="modal-content">             
                          <div className="modal-body text-center">
                          <div className="calendar__header">
                            <h4>Date Range</h4>
                            <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                          </div>
                          <DateRange
                            onInit={this.handleSelect.bind(this)}
                            onChange={this.handleSelect.bind(this)}
                            startDate={this.state.fromDate}
                            endDate={this.state.endDate}
                          />
                          <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                          </div>               
                        </div>
                      </div>
                    </Modal>
                  </div>
                }
              </div>
            }
            

              {/*<div className={this.state.selectedDefault ? "dp-wrapper dp-wrapper-edit marg__rt--ten" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment('2015-01-01')}
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        disabled={this.state.selectedDefault}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.state.selectedDefault ? "dp-wrapper dp-wrapper-edit filterHideOverride" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" } >
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        disabled={this.state.selectedDefault}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>    */}
            <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
              <ExcelSheet data={strategistStatisticsData} name="strategists_statistics">
                  <ExcelColumn label="Strategist Name" value="name"/>
                  <ExcelColumn label="Total Clients Received" value="totalClientReceived"/>
                  <ExcelColumn label="Total Closed" value="totalClosed"/>
                  <ExcelColumn label="Total Pending" value="totalPending"/>
                  <ExcelColumn label="Total Closed + Pending" value="totalClosedPending"/>
                  <ExcelColumn label="Total Won/Total Received" value="totalWonTotalReceivedPercentage"/>
                  <ExcelColumn label="Total No Response" value="totalNoResponse"/>
                  <ExcelColumn label="No Response %" value="noResponsePercentage"/>
                  <ExcelColumn label="Total City" value="totalCity"/>
                  <ExcelColumn label="City %" value="cityPercentage"/>
                  <ExcelColumn label="Total Actively Engaged" value="totalActivelyEngaged"/>
                  <ExcelColumn label="% Actively Engaged" value="activelyEngagedPercentage"/>
                  <ExcelColumn label="Total Somewhat  Engaged" value="totalSomewhatEngaged"/>
                  <ExcelColumn label="% Somewhat Engaged" value="somewhatEngagedPercentage"/>
                  <ExcelColumn label="Green Not Very Engaged" value="greenNotVeryEngaged"/>
                  <ExcelColumn label="% Not Very Engaged" value="notVeryEngagedPercentage"/>
                  <ExcelColumn label="Never Had a Call" value="neverHadACall"/>
                  <ExcelColumn label="% Never Had a Call" value="neverHadACallPercentage"/>
                  <ExcelColumn label="Own Agent Post" value="ownAgent"/>
                  <ExcelColumn label="% Own Agent Post" value="ownAgentPercentage"/>
                  <ExcelColumn label="Green" value="totalGreen"/>
                  <ExcelColumn label="% Green" value="greenPercentage"/>
                  <ExcelColumn label="Own Agent - Pre" value="ownAgentPre"/>
                  <ExcelColumn label="% Own Agent - Pre" value="ownAgentPrePercentage"/>
                  <ExcelColumn label="New" value="totalNew"/>
                  <ExcelColumn label="% New" value="newPercentage"/>
                  <ExcelColumn label="Out of Area" value="outOfArea"/>
                  <ExcelColumn label="% Out of Area" value="outOfAreaPercentage"/>
                  <ExcelColumn label="Rented" value="totalRented"/>
                  <ExcelColumn label="% Rented" value="rentedPercentage"/>
                  <ExcelColumn label="Call Scheduled" value="callScheduled"/>
                  <ExcelColumn label="% Call Scheduled" value="callScheduledPercentage"/>
                  <ExcelColumn label="Reschedule" value="totalReschedule"/>
                  <ExcelColumn label="% Reschedule" value="reschedulePercentage"/>
                  <ExcelColumn label="City - do not reach out" value="cityDoNotReachOut"/>
                  <ExcelColumn label="% City - do not reach out" value="cityDoNotReachOutPercentage"/>
                  <ExcelColumn label="Divorced" value="totalDivorced"/>
                  <ExcelColumn label="% Divorced" value="divorcedPercentage"/>
                  <ExcelColumn label="Unsubscribe" value="totalUnsubscribe"/>
                  <ExcelColumn label="% Unsubscribe" value="unsubscribePercentage"/>
                  <ExcelColumn label="Never Had a Call - do not reach out" value="neverHadACallDont"/>
                  <ExcelColumn label="% Never Had a Call - do not reach out" value="neverHadACallDontPercentage"/>
                  <ExcelColumn label="Listing" value="totalListing"/>
                  <ExcelColumn label="% Listing" value="listingPercentage"/>
                  <ExcelColumn label="Trying To Schedule" value="tryingToSchedule"/>
                  <ExcelColumn label="% Trying To Schedule" value="tryingToSchedulePercentage"/>
                  <ExcelColumn label="Relo" value="totalRelo"/>
                  <ExcelColumn label="% Relo" value="reloPercentage"/>
                  <ExcelColumn label="Never Had A Call - Own Agent" value="neverHadACallOwnAgent"/>
                  <ExcelColumn label="% Never Had A Call - Own Agent" value="neverHadACallOwnAgentPercentage"/>
                  <ExcelColumn label="Rented On Own" value="RentedOnOwn"/>
                  <ExcelColumn label="% Rented On Own" value="rentedOnOwnPercentage"/>
                  <ExcelColumn label="Staying_In_Suburbs" value="stayingInSuburbs"/>
                  <ExcelColumn label="% Staying_In_Suburbs" value="stayingInSuburbsPercentage"/>
                  <ExcelColumn label="Bought Directly" value="boughtDirectly"/>
                  <ExcelColumn label="% Bought Directly" value="boughtDirectlyPercentage"/>
                  <ExcelColumn label="UJ Green - Actively Engaged" value="ujActivelyEngaged"/>
                  <ExcelColumn label="% UJ Green - Actively Engaged" value="ujActivelyEngagedPercentage"/>
                  <ExcelColumn label="UJ Green - Somewhat Engaged" value="ujSomewhatEngaged"/>
                  <ExcelColumn label="% UJ Green - Somewhat Engaged" value="ujSomewhatEngagedPercentage"/>
                  <ExcelColumn label="UJ Green - Not Very Engaged" value="ujNotVeryEngaged"/>
                  <ExcelColumn label="% UJ Green - Not Very Engaged" value="ujNotVeryEngagedPercentage"/>
                  <ExcelColumn label="New Not Scheduled" value="newNotScheduled"/>
                  <ExcelColumn label="% New Not Scheduled" value="newNotScheduledPercentage"/>
                  <ExcelColumn label="City - Own Agent" value="cityOwnAgent"/>
                  <ExcelColumn label="% City - Own Agent" value="cityOwnAgentPercentage"/>
                  <ExcelColumn label="Never Had A Call-Unsubscribe" value="neverHadACallUnsubscribe"/>
                  <ExcelColumn label="% Never Had A Call-Unsubscribe" value="neverHadACallUnsubscribePercentage"/>
                  <ExcelColumn label="Online-New" value="totalOnlineNew"/>
                  <ExcelColumn label="% Online-New" value="totalOnlineNewPercentage"/>
                  <ExcelColumn label="Rented On Own - Unsubscribe" value="rentedOnOwnUnsubscribe"/>
                  <ExcelColumn label="% Rented On Own - Unsubscribe" value="rentedOnOwnUnsubscribePercentage"/>
                  <ExcelColumn label="Fake Client" value="totalFakeClient"/>
                  <ExcelColumn label="% Fake Client" value="totalFakeClientPercentage"/>
                  <ExcelColumn label="City Temp" value="totalCityTemp"/>
                  <ExcelColumn label="% City Temp" value="totalCityTempPercentage"/>
                  <ExcelColumn label="City Perm" value="totalCityPerm"/>
                  <ExcelColumn label="% City Perm" value="totalCityPermPercentage"/>
                  <ExcelColumn label="No Response-AI" value="totalNoResponseAI"/>
                  <ExcelColumn label="% No Response-AI" value="totalNoResponseAIPercentage"/>
              </ExcelSheet>
            </ExcelFile>
            </div>
            </div>
          </div>
        </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
{/*             
            <div className="filter__container filter__container--users desktopFilter">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list">
                   <div className="table-responsive"> */}

                   <div className="list_table useslist__extra">
                   <StickyContainer>
                     <table className="table table-striped">
                     <Sticky topOffset={50}>
                          {({
                            style,
                            isSticky,
                            wasSticky,
                            distanceFromTop,
                            distanceFromBottom,
                            calculatedHeight
                          }) => (
                            <header className="updiv" style={style}>
                        <thead>
                          <tr>
                            <th className="for__static">Strategist Name</th>
                            <th className="text-center">Total Clients Received</th>
                            <th className="text-center">Total Closed</th>
                            <th className="text-center">Total Pending</th>
                            <th className="text-center">Total Closed + Pending</th>
                            <th className="text-center">Total Won/Total Received</th>
                            <th className="text-center">Total No Response</th>
                            <th className="text-center">No Response %</th>
                            <th className="text-center">Total City</th>
                            <th className="text-center">City %</th>
                            <th className="text-center">Total Actively Engaged</th>
                            <th className="text-center">% Actively Engaged</th>
                            <th className="text-center">Total Somewhat  Engaged</th>
                            <th className="text-center">% Somewhat Engaged</th>
                            <th className="text-center">Green Not Very Engaged</th>
                            <th className="text-center">% Not Very Engaged</th>
                            <th className="text-center">Never Had a Call</th>
                            <th className="text-center">% Never Had a Call</th>
                            <th className="text-center">Own Agent Post</th>
                            <th className="text-center">% Own Agent Post</th>
                            <th className="text-center">Green</th>
                            <th className="text-center">% Green</th>
                            <th className="text-center">Own Agent - Pre</th>
                            <th className="text-center">% Own Agent - Pre</th>
                            <th className="text-center">New</th>
                            <th className="text-center">% New</th>
                            <th className="text-center">Out of Area</th>
                            <th className="text-center">% Out of Area</th>
                            <th className="text-center">Rented</th>
                            <th className="text-center">% Rented</th>
                            <th className="text-center">Call Scheduled</th>
                            <th className="text-center">% Call Scheduled</th>
                            <th className="text-center">Reschedule</th>
                            <th className="text-center">% Reschedule</th>
                            <th className="text-center">City - do not reach out</th>
                            <th className="text-center">% City - do not reach out</th>
                            <th className="text-center">Divorced</th>
                            <th className="text-center">% Divorced</th>
                            <th className="text-center">Unsubscribe</th>
                            <th className="text-center">% Unsubscribe</th>
                            <th className="text-center">Never Had a Call - do not reach out</th>
                            <th className="text-center">% Never Had a Call - do not reach out</th>
                            <th className="text-center">Listing</th>
                            <th className="text-center">% Listing</th>
                            <th className="text-center">Trying To Schedule</th>
                            <th className="text-center">% Trying To Schedule</th>
                            <th className="text-center">Relo</th>
                            <th className="text-center">% Relo</th>
                            <th className="text-center">Never Had A Call - Own Agent</th>
                            <th className="text-center">% Never Had A Call - Own Agent</th>
                            <th className="text-center">Rented On Own</th>
                            <th className="text-center">% Rented On Own</th>
                            <th className="text-center">Staying_In_Suburbs</th>
                            <th className="text-center">% Staying_In_Suburbs</th>
                            <th className="text-center">Bought Directly</th>
                            <th className="text-center">% Bought Directly</th>
                            <th className="text-center">UJ Green - Actively Engaged</th>
                            <th className="text-center">% UJ Green - Actively Engaged</th>
                            <th className="text-center">UJ Green - Somewhat Engaged</th>
                            <th className="text-center">% UJ Green - Somewhat Engaged</th>
                            <th className="text-center">UJ Green - Not Very Engaged</th>
                            <th className="text-center">% UJ Green - Not Very Engaged</th>
                            <th className="text-center">New Not Scheduled</th>
                            <th className="text-center">% New Not Scheduled</th>
                            <th className="text-center">City - Own Agent</th>
                            <th className="text-center">% City - Own Agent</th>
                            <th className="text-center">Never Had A Call-Unsubscribe</th>
                            <th className="text-center">% Never Had A Call-Unsubscribe</th>
                            <th className="text-center">Online-New</th>
                            <th className="text-center">% Online-New</th>
                            <th className="text-center">Rented On Own - Unsubscribe</th>
                            <th className="text-center">% Rented On Own - Unsubscribe</th>
                            <th className="text-center">Fake Client</th>
                            <th className="text-center">% Fake Client</th>
                            <th className="text-center">City Temp</th>
                            <th className="text-center">% City Temp</th>
                            <th className="text-center">City Perm</th>
                            <th className="text-center">% City Perm</th>
                            <th className="text-center">No Response-AI</th>
                            <th className="text-center">% No Response-AI</th>
                          </tr>
                        </thead>
                        </header>
                          )}
                        </Sticky>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="9" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your Strategist report is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                        {
                          strategistStatisticsData && strategistStatisticsData.map((str, index)=>{
                            return(
                              <tr key={index} className="forcursor" >
                              <td>{str.name}</td>
                                <td className="text-center">{str.totalClientReceived}</td>
                                <td className="text-center">{str.totalClosed}</td>
                                <td className="text-center">{str.totalPending}</td>
                                <td className="text-center">{str.totalClosedPending}</td>
                                <td className="text-center">{str.totalWonTotalReceivedPercentage}</td>
                                <td className="text-center">{str.totalNoResponse}</td>
                                <td className="text-center">{str.noResponsePercentage}</td>
                                <td className="text-center">{str.totalCity}</td>
                                <td className="text-center">{str.cityPercentage}</td>
                                <td className="text-center">{str.totalActivelyEngaged}</td>
                                <td className="text-center">{str.activelyEngagedPercentage}</td>
                                <td className="text-center">{str.totalSomewhatEngaged}</td>
                                <td className="text-center">{str.somewhatEngagedPercentage}</td>
                                <td className="text-center">{str.greenNotVeryEngaged}</td>
                                <td className="text-center">{str.notVeryEngagedPercentage}</td>
                                <td className="text-center">{str.neverHadACall}</td>
                                <td className="text-center">{str.neverHadACallPercentage}</td>
                                <td className="text-center">{str.ownAgent}</td>
                                <td className="text-center">{str.ownAgentPercentage}</td>
                                <td className="text-center">{str.totalGreen}</td>
                                <td className="text-center">{str.greenPercentage}</td>
                                <td className="text-center">{str.ownAgentPre}</td>
                                <td className="text-center">{str.ownAgentPrePercentage}</td>
                                <td className="text-center">{str.totalNew}</td>
                                <td className="text-center">{str.newPercentage}</td>
                                <td className="text-center">{str.outOfArea}</td>
                                <td className="text-center">{str.outOfAreaPercentage}</td>
                                <td className="text-center">{str.totalRented}</td>
                                <td className="text-center">{str.rentedPercentage}</td>
                                <td className="text-center">{str.callScheduled}</td>
                                <td className="text-center">{str.callScheduledPercentage}</td>
                                <td className="text-center">{str.totalReschedule}</td>
                                <td className="text-center">{str.reschedulePercentage}</td>
                                <td className="text-center">{str.cityDoNotReachOut}</td>
                                <td className="text-center">{str.cityDoNotReachOutPercentage}</td>
                                <td className="text-center">{str.totalDivorced}</td>
                                <td className="text-center">{str.divorcedPercentage}</td>
                                <td className="text-center">{str.totalUnsubscribe}</td>
                                <td className="text-center">{str.unsubscribePercentage}</td>
                                <td className="text-center">{str.neverHadACallDont}</td>
                                <td className="text-center">{str.neverHadACallDontPercentage}</td>
                                <td className="text-center">{str.totalListing}</td>
                                <td className="text-center">{str.listingPercentage}</td>
                                <td className="text-center">{str.tryingToSchedule}</td>
                                <td className="text-center">{str.tryingToSchedulePercentage}</td>
                                <td className="text-center">{str.totalRelo}</td>
                                <td className="text-center">{str.reloPercentage}</td>
                                <td className="text-center">{str.neverHadACallOwnAgent}</td>
                                <td className="text-center">{str.neverHadACallOwnAgentPercentage}</td>
                                <td className="text-center">{str.RentedOnOwn}</td>
                                <td className="text-center">{str.rentedOnOwnPercentage}</td>
                                <td className="text-center">{str.stayingInSuburbs}</td>
                                <td className="text-center">{str.stayingInSuburbsPercentage}</td>
                                <td className="text-center">{str.boughtDirectly}</td>
                                <td className="text-center">{str.boughtDirectlyPercentage}</td>
                                <td className="text-center">{str.ujActivelyEngaged}</td>
                                <td className="text-center">{str.ujActivelyEngagedPercentage}</td>
                                <td className="text-center">{str.ujSomewhatEngaged}</td>
                                <td className="text-center">{str.ujSomewhatEngagedPercentage}</td>
                                <td className="text-center">{str.ujNotVeryEngaged}</td>
                                <td className="text-center">{str.ujNotVeryEngagedPercentage}</td>
                                <td className="text-center">{str.newNotScheduled}</td>
                                <td className="text-center">{str.newNotScheduledPercentage}</td>
                                <td className="text-center">{str.cityOwnAgent}</td>
                                <td className="text-center">{str.cityOwnAgentPercentage}</td>
                                <td className="text-center">{str.neverHadACallUnsubscribe}</td>
                                <td className="text-center">{str.neverHadACallUnsubscribePercentage}</td>
                                <td className="text-center">{str.totalOnlineNew}</td>
                                <td className="text-center">{str.totalOnlineNewPercentage}</td>
                                <td className="text-center">{str.rentedOnOwnUnsubscribe}</td>
                                <td className="text-center">{str.rentedOnOwnUnsubscribePercentage}</td>
                                <td className="text-center">{str.totalFakeClient}</td>
                                <td className="text-center">{str.totalFakeClientPercentage}</td>
                                <td className="text-center">{str.totalCityTemp}</td>
                                <td className="text-center">{str.totalCityTempPercentage}</td>
                                <td className="text-center">{str.totalCityPerm}</td>
                                <td className="text-center">{str.totalCityPermPercentage}</td>
                                <td className="text-center">{str.totalNoResponseAI}</td>
                                <td className="text-center">{str.totalNoResponseAIPercentage}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                      </StickyContainer>
                      </div>
{/*                       
                     </div>
                   </div>
                 </div>
               </div> */}
              </main>

              {
                strategistStatisticsData && strategistStatisticsData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Strategist Statistics</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'strategistreportlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategistStatistics)
