import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'
import { Container, Row, Col, } from 'react-grid-system'
import Cookies from 'universal-cookie'
import axios from 'axios'
import _ from 'lodash'
import $ from 'jquery'
import Modal from "react-responsive-modal";
import { logoutInvalidRequest } from '../../../utils/helper'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME

let pre_auth = ''

export class AgentClientHeader extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
     isLoggedIn:true,
     searchedClients: [],
     showSearchList: false,
     accesstoken:'',
     isloading:false,
     err:{},
     open: false,
     pre_auth:''
    }
     this.userSignout = this.userSignout.bind(this)
     this.onOpenModal = this.onOpenModal.bind(this)
     this.onCloseModal = this.onCloseModal.bind(this)
     this.GmailAuthPop = this.GmailAuthPop.bind(this)
     this.handleChange = this.handleChange.bind(this)
     this.handleTokenSave = this.handleTokenSave.bind(this)
  }

  componentWillMount(){
    pre_auth = cookies.get('i_user')
    if(pre_auth){
      this.setState({pre_auth : pre_auth})
    }
  }

  userSignout(logout) {
    localStorage.clear();
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookies.remove('Authorization')
    // document.cookies.remove('user')
    this.props.handleSignOut();
    this.props.history.push('/login')
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  GmailAuthPop(){
    axios({
      method: 'GET',
      url: HOSTNAME+'/agent-calendar/authenticate'
    })
    .then((res) => {
      if(res && res.data && res.data.status && res.data.data !='')
      var win = window.open(res.data.data, 'Account verifications','width=600,height=500,left=50,top=100');
      setTimeout(function(){
       },1000)
       setTimeout(function () { win.close();
        window.location.reload(true)
      }, 50000);
    }).catch(logoutInvalidRequest)
  }

  handleTokenSave(){
    this.setState({isloading: true})
    // event.preventDefault()
    const err = {}
    if (this.state.accesstoken === '') {
      err.agent_id = 'Enter copied token, it`s required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const data = {}
      data.code = this.state.accesstoken
      this.props.saveAuthtoken(data)
    }else{
      this.setState({isloading: false})
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  OpenScheduleBar(event) {
    this.props.openScheduleBar()
  }

  myProfileCondition(role){
    switch(role) {
      case 'agent':
        return(
        <ul className="dropdown-menu">
        <li><Link to={`/agent-edit-profile`} className="text-orange">
        My Profile</Link></li>
        <li><Link to={`/availability`} className="text-orange">Availability</Link>
        </li>
        <li>
          <a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-agent-client text-orange">
        Logout</a>
        </li>
        </ul>
      )
      case 'local':
        return(
        <ul className="dropdown-menu">
        <li><Link to={`/agent-edit-profile`} className="text-orange">
        My Profile</Link></li>
        <li><Link to={`/availability`} className="text-orange">Availability</Link>
        </li>
        <li>
          <a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-agent-client text-orange">
        Logout</a>
        </li>
        </ul>
      )
      case 'client':
        return(
        <ul className="dropdown-menu">
        <li><Link to={`/client-my-profile`} className="text-orange">
        My Profile</Link></li>
        <li>
          <a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-agent-client text-orange">
        Logout</a>
        </li>
        </ul>
      )
      default:
        return null;
    }
  }

  render(){
    const { user } = this.props
    return (
     
      <header className={this.state.pre_auth ? "secondary__navbar__header header-client auth_secondary__navbar__header" : "secondary__navbar__header header-client"}>
       {
          (_.get(user, 'role') === "client") ?
             <Link to={`/clients`} className="secondary__navbar__header--logo">
                <img src="./img/logo.svg"/> <span>Suburban Jungle {user && user.role  ? user.role : 'users'} portal</span>
             </Link>
            :
            <Link to={`/agent`} className="secondary__navbar__header--logo">
               <img src="./img/logo.svg"/> <span>Suburban Jungle {user && user.role  ? user.role : 'users'} portal</span>
            </Link>
       }

       
        <div className="secondary__navbar__header--desktop">

          <div className="secondary__navbar__header--navigation m__l--auto">
            {
              (_.get(user, 'role') === "client") ?
                 <div>
                    <Link to={`/client-chat-with-agent`}  className="navigation__links btn__grey">
                    Message Agent</Link>
                    <a href="javascript:void(0)" className="navigation__links" onClick={this.OpenScheduleBar.bind(this)}>
                    Schedule</a>
                </div>
                :
                <div>
                  { (_.get(user, 'role') === "agent") ? 
                  <div>
                    <button onClick={this.GmailAuthPop.bind(this)} className="navigation__links--google-sync">
                      <img src="img/google-icon.svg"/>{user.gmail_authentication && user.gmail_authentication===true ? 'Sync Calendar '+ user.gmail_authentication_id : ' Sync Calendar'}
                    </button>
                     {
                      (this.props.history.location.pathname == "/agent")?
                      <a href="javascript:void(0)" className="navigation__links" onClick={this.OpenScheduleBar.bind(this)}>
                        + Appointment</a>
                        :''
                     }
                    <Link to={`/agentcalendar`} className="navigation__links btn__green">
                    Calendar</Link>
                    <Link to={`/chat-center`} className="navigation__links btn__grey">
                    Message Client</Link>
                  </div>
                  : 
                   ''
                  }
                </div>
            }

            <div className="avtar__dropdown">
              <div className="dropdown costom-dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <span className="profile-name">{user && user.first_name && user.last_name ? user.first_name + " "+user.last_name : ''}</span><br/>
                <img className="avart__arrow--icon" src="./img/profile-arrow.svg"/></a>
                  { 
                    this.myProfileCondition(_.get(user, 'role'))
                  }    
              </div>
            </div>
          </div>
        </div>
      </header>
    )
 } 
}

function mapStateToProps(state, props) {
    return {
        initialValues : props.location.state ? props.location.state : {}
     }
}

export default connect( mapStateToProps )(
  (AgentClientHeader)
)