import { connect } from 'react-redux'
import { fetchUser } from '../../store/user/duck'
import StrategistDashboardForm from './component'
const StrategistDashboard = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
  }),
  // Map actions to props
  {
    fetchUser,
  }
)(StrategistDashboardForm)
export default StrategistDashboard
