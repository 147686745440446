import { connect } from 'react-redux'
import StrategistTimesheetForm from './component'

import { getFilterParams } from '../../../store/dashboard/duck'
import { getStrategistList, getStrategistStatistics, getStrategistTimesheetData } from '../../../store/analytics/duck'

const StrategistTimesheet = connect(
  // Map state to props
  (state) => ({
  	strategistPhase: state.analytics.strategistPhase,
  	strategyData: state.analytics.strategyData,
  	filterParams: state.dashboard.filterParams,
    strategistStatisticsPhase: state.analytics.strategistStatisticsPhase,
    strategistStatisticsData: state.analytics.strategistStatisticsData,
    strategistTimesheetPhase: state.analytics.strategistTimesheetPhase,
    strategistTimesheetData: state.analytics.strategistTimesheetData
  }),
  // Map actions to props
  {
  	getStrategistList,
  	getFilterParams,
    getStrategistStatistics,
    getStrategistTimesheetData
  }
)(StrategistTimesheetForm)
export default StrategistTimesheet
