import 'rxjs'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import config from '../../../config'
import { Redirect, Link } from 'react-router-dom'
import SideNavigation from '../../../components/sideNavigation'
import TopNavigation from '../../TopNavigation/container'
import styles from '../styles.scss'
import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import Pagination from 'react-js-pagination'
import Modal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'
import {getHostname} from "../../../utils/helper";
const required = value => (value ? undefined : 'Required')
const cookies = new Cookies()

const missingData = ['Yes', 'No']
let pre_Auth = ''
let _user = ''

const additionalArr = ["Most Recent Towns", "Alphabetical Towns"]

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    maxWidth: '96%',
    padding: '0',
    maxHeight: "95%"
  }
}

class ViewTownReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      role: '',
      client_strategist: [],
      client_type: 'all_clients',
      filters: [],
      hide_inactive: false,
      totalPages: 0,
      activePage: 1,
      client_limit: 100,
      strategistDropdown: false,
      suburbDropdown: false,
      statusDropdown: false,
      missingDataDropdown: false,
      missingData: '',
      townsData: [],
      modalIsOpen: false,
      editTownName: '',
      editTownId: 0,
      sortByKey: '',
      sortByType: 0,
      err: {},
      isLoading: false,
      additonalDropDown: false,
      additionalArrData: [],
      search: ''
    }
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentWillMount() {
    let self = this
    _user = cookies.get('user')
    if (_user) {
      if (_user.role === 'strategist') {
        this.setState({ client_type: 'my_clients' })
      }
      this.setState({ userid: _user.id, current_role: _user.role })
      const data = {}
      data.user_id = _user.id
      this.props.fetchUser(data)
      this.props.getFilterParams()
      this.townReportData()

      pre_Auth = cookies.get('i_user')
      if(pre_Auth){
        this.setState({pre_Auth: pre_Auth})
      }
    }
  }

  townReportData(){
    let self = this
    // setTimeout( function(){
    //   var townAllData = JSON.parse(localStorage.getItem('towndata'))
    //   if( !(townAllData && townAllData.keyValue) && !(townAllData && townAllData.keyType) ){
    //     const params = {
    //       limit: self.state.client_limit * self.state.activePage,
    //       offset: 0, // First time load so offset 1
    //       client_limit: self.state.client_limit,
    //       filters: self.state.filters,
    //       keyValue: "townId",
    //       keyType: self.state.sortByType
    //     }
    //     localStorage.setItem('towndata', JSON.stringify(params))
    //     self.props.fetchTownReport(params)
    //   }
    //   if(townAllData && townAllData !=null && townAllData !='' && townAllData !== "null"){
    //     self.setState(townAllData)
    //     self.props.fetchTownReport(townAllData)
    //   }else{
    //     const params = {
    //       limit: self.state.client_limit * self.state.activePage,
    //       offset: 0, // First time load so offset 1
    //       client_limit: self.state.client_limit,
    //       filters: self.state.filters,
    //       keyValue: self.state.sortByKey,
    //       keyType: self.state.sortByType
    //     }
    //     localStorage.setItem('towndata', JSON.stringify(params))
    //     self.props.fetchTownReport(params)
    //   }
    // }, 500)
    const params = {
      limit: self.state.client_limit * 1,
      offset: 0, // First time load so offset 1
      client_limit: self.state.client_limit,
      filters: self.state.filters,
      keyValue: self.state.sortByKey,
      keyType: self.state.sortByType,
      search: this.state.search
    }

    localStorage.setItem('towndata', JSON.stringify(params))
    this.setState({
      client_limit: self.state.client_limit,
      activePage: 1
    })
    self.props.fetchTownReport(params)
  }

  componentDidMount() {
    let self = this
    document.title = 'Jungler: Clients'
    $(document).click(function(event) { 
        if(!$(event.target).closest('#dropdownBtn').length) {
            if($('#dropdownBtn').is(":visible")) {
               self.setState({ suburbDropdown: false, additonalDropDown: false })
            }
        }       
    })
  }

  componentWillReceiveProps(np){
    if(np.updateTownPhase !== this.props.updateTownPhase){
      this.townReportData()
    }
    if(np.updateTownData && np.updateTownData.status === false ){
      let err = {}
      err.editTownName = 'Town Name is already exists.'
      this.setState({ isLoading: false, err })
    }
    if(np.updateTownData.status === true && np.updateTownData !== this.props.updateTownData){
       this.setState({ isLoading: false, modalIsOpen: false, editTownName: '', editTownId: 0, err: '' })
    }
    if(np.townReportPhase !== this.props.townReportPhase){
      this.setState({ isLoading: false })
    }
    if(np.deleteTownPhase !== this.props.deleteTownPhase && np.deleteTownPhase === "success"){
      this.setState({ isLoading: false })
      this.townReportData()
    }
  }

  componentWillUnmount() {
    document.title = 'Suburban Jungle'
  }

  missingData(missingData) {
    this.setState({ missingData }, () => {
      const params = {
        limit: this.state.client_limit,
        offset: this.state.activePage * this.state.activePage,
        filters: this.state.filters,
        client_limit: this.state.client_limit,
        missingData: this.state.missingData,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      this.props.fetchTownReport(params)
    })
  }

  afterOpenModal(){ }
  closeModal(){ }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false, editTownName: '', editTownId: 0, err: '' })
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();

    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }

  toggledropDown(val) {
    if (val === 'filterStrategist') {
      this.setState({ strategistDropdown: !this.state.strategistDropdown, statusDropdown: false, suburbDropdown: false })
    }
    if (val === 'filterStatus') {
      this.setState({ statusDropdown: !this.state.statusDropdown, strategistDropdown: false, suburbDropdown: false })
    }
    if (val === 'filterSuburb') {
      this.setState({ suburbDropdown: !this.state.suburbDropdown, statusDropdown: false, strategistDropdown: false })
    }
    if(val === 'additionalFilter'){
      this.setState({ strategistDropdown: false, statusDropdown: false, suburbDropdown: false, additonalDropDown: !this.state.additonalDropDown })
    }
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePageChange(activePage) {
    this.setState({ activePage }, () => {
      const params = {
        limit: this.state.client_limit,
        offset: (activePage - 1) * this.state.client_limit,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      if (this.state.missingData !== '') {
        params.missingData = this.state.missingData
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    })
  }

  clearAllFilter(e) {
    this.setState({ filters: [], activePage: 1, missingData: '', suburbDropdown: false, missingDataDropdown: false, additionalArrData: [] }, () => {
      const params = {
        limit: this.state.client_limit,
        offset: 0,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: '',
        keyType: this.state.sortByType,
        search: this.state.search
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    })
  }

  filterStrategist(id, type) {
    let { client_strategist, filters } = this.state
    if (filters.indexOf(id) === -1) {
      filters.push(id)
    } else {
      filters.splice(filters.indexOf(id), 1)
    }
    this.setState({ filters, activePage: 1 }, () => {
      const params = {
        limit: this.state.client_limit,
        offset: 0,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      if (this.state.missingData !== '') {
        params.missingData = this.state.missingData
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    })
  }

  additionalFilter(name){
    let { additionalArrData, sortByKey } = this.state
    if (additionalArrData.indexOf(name) === -1) {
      additionalArrData = []
      additionalArrData.push(name)
    } else{
      additionalArrData = []
    }

    if(additionalArrData.length > 0){
      if(name === 'Most Recent Towns'){
        sortByKey = 'townId'
      }else if(name === 'Alphabetical Towns'){
        sortByKey = 'townName'
      }
    }else{
      sortByKey = 'townName'
    }
    this.setState({ additionalArrData: additionalArrData, sortByKey:sortByKey }, () => {
      const params = {
        limit: this.state.client_limit,
        offset: (this.state.activePage - 1) * this.state.client_limit,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      if (this.state.missingData !== '') {
        params.missingData = this.state.missingData
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    })
  }

  handleEntryChange(event){
    this.setState({client_limit: event.target.value, activePage: 1 })
    setTimeout(function(){
      const params = {
        limit: this.state.client_limit,
        offset: 0,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      if (this.state.missingData !== '') {
        params.missingData = this.state.missingData
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    }.bind(this), 500)
  }

  createTownBtn (e) {
    const { history, suburbsData } = this.props
    history.push({ pathname: '/create-report', state: suburbsData })
  }

  clickOnTownName(c){
    this.setState({ modalIsOpen: true, editTownName: c.name, editTownId: c.town_db_id })
  }

  capital_letter(str){
    if(str !== ''){
    str = str.split(" ")
    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1)
    }
    return str.join(" ")
  }
  }

  updateTownName(e){
    let err = {}
    const { towns, updateTownName } = this.props
    const { editTownName, editTownId } = this.state
    let upperName = editTownName

    if(editTownName == '' || editTownName.trim() == ''){
      err.editTownName = 'Enter Town Name'
    }else{
      upperName = this.capital_letter(editTownName)
    }

    towns && towns.map((val, idx)=>{
      if(val.name === upperName){
        err.editTownName = 'Town Name is already exists.'
      }
    })

    if(editTownId === 0){
      err.editTownName = 'Something Went Wrong'
    }

    this.setState({ err })
     if (!Object.keys(err).length) {
        let data = {
          town_id: editTownId,
          town_name: upperName
        }
        updateTownName(data)
        this.setState({ isLoading: true })
     }
  }

  deleteTownBtn=(townId, e)=>{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
              <img src="../../img/close_btn.svg"/>
              </button>
            </div>
            <p className="allpoppadding__para poppinsfontsedit">You want to delete this town?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.deleteTown(townId)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  sortData(val) {
    let sortByType = this.state.sortByType
    if (val !== this.state.sortByKey) {
      sortByType = 0
    } else {
      sortByType === 0 ? (sortByType = 1) : (sortByType = 0)
    }
    this.setState({ isLoadingData: true, sortByKey: val, sortByType: sortByType }, ()=>{
      const params = {
        limit: this.state.client_limit,
        offset: (this.state.activePage - 1) * this.state.client_limit,
        client_limit: this.state.client_limit,
        filters: this.state.filters,
        keyValue: this.state.sortByKey,
        keyType: this.state.sortByType,
        search: this.state.search
      }
      if (this.state.missingData !== '') {
        params.missingData = this.state.missingData
      }
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    })
  }

  deleteTown=(townId)=>{
    const { deleteTownById } = this.props
    let data = {
      town_id: townId,
      user_id: this.state.userid
     }
    deleteTownById(data)
  }

  editReport(c, e){
    localStorage.setItem("townName", c.name)
  }

  onSearch(e){
    this.setState({search: e.target.value})
    const params = {
      limit: this.state.client_limit,
      offset: (this.state.activePage - 1) * this.state.client_limit,
      client_limit: this.state.client_limit,
      filters: this.state.filters,
      keyValue: this.state.sortByKey,
      keyType: this.state.sortByType,
      search: e.target.value
    }
    if (this.state.missingData !== '') {
      params.missingData = this.state.missingData
    }
    if (String(e.target.value).length > 2) {
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    } else if (e.target.value === '') {
      localStorage.setItem('towndata', JSON.stringify(params))
      this.props.fetchTownReport(params)
    }
  }

  render() {
    const { townsCount, towns, suburbsData, handleSubmit, pristine, isSubmitting, rxError, clientData, filterParams, token, phase } = this.props
    const { client_strategist } = this.state
    let allData = filterParams.data

    let suburbsArray = []
    if(this.state.current_role === 'strategist'){
        if(allData && allData.suburbs){
          _.forEach(allData.suburbs, a => {
              var o = _.find(suburbsData, b => a.id == b)
              if (o) {
                suburbsArray.push(a)
              }
          })
        }
    }else{
      suburbsArray = allData && allData.suburbs
    }

    return (
      <div>
      {
        (this.state.isLoading) &&
        <tbody>
          <tr>
            <td colSpan="5" className="noClientResults text-center">
              <img src="../../img/loader2.svg" />
            </td>
          </tr>
        </tbody>
      }

      {
        (pre_Auth)?
        <div className="strategist_top">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
        :''
      }

        <TopNavigation {...this.props} />
        <ToastContainer autoClose={3000}/>
        <div id="wrapper">
          <SideNavigation {...this.props} />
          <div id="page-content-wrapper" className={ pre_Auth ? "onlyformobile__town--report for__townHeader" : "onlyformobile__town--report" }>
            <div className="row">
              <div className="col-md-12">
                <main className="jungler__page--container padding-left">
                  <div className="filter__container filter__container--users townreportgap">
                    <div className="filter__container--filter-name rem-flex rem-flexmargtab">
                      <span className="enteries-span paging-button">
                        <label className="enteries-label">Entries</label>
                        <select onChange={this.handleEntryChange.bind(this)} value={this.state.client_limit}>
                        {config.entries && config.entries.map((entry, entryindx) => {
                            return (
                            <option key={entryindx} value={entry.value}>{entry.label}</option>
                            )
                           })}
                        </select>
                      </span> 
                      <div>
                        <button type="submit" onClick={this.createTownBtn.bind(this)} className="create-town-button">
                          Create Town Report
                        </button>
                      </div>
                    </div>
                  </div>

                <div className="row">
                  <div className="col-md-12">
                    <form className="navbar__header--form navbarheaderform">
                      <span className="typeahead">
                      <input 
                        className="costom__search--report typeahead-input reportsearchsminus add" 
                        onChange={this.onSearch.bind(this)}
                        type="text" 
                        name="search" 
                        placeholder="Search by town name… (enter at lease 3 characters to see results)"/>
                    </span>
                    </form>
                  </div>
                </div>
                 
                  <div className="filter__sub--container filter__sub--container-edit filterarrg">
                      <li id="dropdownBtn">
                        <div className="custom__all--filters filtersviewtown" onClick={this.toggledropDown.bind(this, 'filterSuburb')}>
                          Filter by Suburb
                        </div>
                        {this.state.suburbDropdown && (
                          <div className="sel-outer sel-outer-townreport suburb-filter">
                            {suburbsArray &&
                              suburbsArray.map((suburbsval, suburbsindx) => {
                                let selectedIndex = false
                                _.find(this.state.filters, function(o) {
                                  if (o === suburbsval.id) {
                                    selectedIndex = true
                                  }
                                })
                                return (
                                  <span className="coverage__checkbox" key={suburbsindx}>
                                    <label className="custom-checkbox">
                                      {suburbsval.name}
                                      <input
                                        type="checkbox"
                                        checked={selectedIndex}
                                        className="custom-select-option-checkbox"
                                        onChange={() => this.filterStrategist(suburbsval.id, 'suburbFilter')}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </span>
                                )
                              })}
                          </div>
                        )}
                      </li>
                      <li id="dropdownBtn">
                        <div className="custom__all--filters filtersviewtown" onClick={this.toggledropDown.bind(this, 'additionalFilter')}>
                          Additional Filter
                        </div>
                        {this.state.additonalDropDown && (
                          <div className="sel-outer sel-outer-townreport suburb-filter">
                            {additionalArr.map((alpha, alphaIndx) => {
                                let alphaIndexing = false
                                _.find(this.state.additionalArrData, function(o) {
                                  if(o === alpha) {
                                    alphaIndexing = true
                                  }else{
                                    alphaIndexing = false
                                  }
                                })
                                return (
                                  <span className="coverage__checkbox" key={alphaIndx}>
                                    <label className="custom-checkbox">
                                      {alpha}
                                      <input
                                        type="checkbox"
                                        checked={alphaIndexing}
                                        className="custom-select-option-checkbox"
                                        onChange={() => this.additionalFilter(alpha)}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </span>
                                )
                              })}
                          </div>
                        )}
                      </li>
                      <li>
                      <div
                        className="custom-select custom-sel-filter hide"
                        onClick={() => this.setState({ missingDataDropdown: !this.state.missingDataDropdown })}
                      >
                        Filter by Missing Data
                      </div>
                      {this.state.missingDataDropdown && (
                        <div className="sel-outer sel-outer-townreport suburb-filter missing-filter hide">
                        {missingData.map((d, i) => {
                          return(
                            <span className="coverage__checkbox" key={i} onClick={() => this.missingData(d)}>
                              <label className="custom-checkbox">
                                {d}
                                <input
                                  type="checkbox"
                                  checked={this.state.missingData === d ? true : false }
                                  className="custom-select-option-checkbox"
                                  onChange={() => this.missingData(d)}
                                />
                                <span className="checkmark" />
                              </label>
                            </span>
                          )
                        })}
                        </div>
                      )}
                    </li>
                    <li className="clearfilterTab">
                      <div>
                        <h5 onClick={this.clearAllFilter.bind(this)} className="clear-filter">
                          {((this.state.client_strategist && this.state.client_strategist.length > 0) ||
                            (this.state.client_status && this.state.client_status.length > 0) ||
                            (this.state.filters && this.state.filters.length > 0) || (this.state.additionalArrData && this.state.additionalArrData.length > 0))
                            ? 'Clear All Filters'
                            : ''}
                        </h5>
                      </div>
                    </li>
                      
                  </div>
                  
                  <div className="jungler__list useslist__extra bordertoptable">
                    <div className="">
                      <table className="table table-striped">
                        <thead className="table__td--desktop">
                          <tr>
                            <th className="tableextra__marg" onClick={()=> this.sortData("town_name")}>Town Name</th>
                            <th onClick={()=> this.sortData("suburbs")}>Suburb</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        {isSubmitting ? (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="noClientResults text-center">
                                <img src="../../img/loader2.svg" />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {towns && towns.length > 0 ? (
                              towns.map((c, i) => {
                                return (
                                  <tr className="forcursor" key={i}>
                                      <td onClick={this.clickOnTownName.bind(this, c)} className="tableextra__marg"><a className="town__name">{c.name}</a>
                                      <span className="showschedule__mob">
                                      {c.suburbName}
                                      </span>
                                    </td>
                                    <td className="table__td--desktop">{c.suburbName}</td>
                                    <td className="right_align">
                                      {/*<button
                                        className="btn btn__black--outline btn__profile"
                                        onClick={() => this.props.history.push(`/edit-images/${c.id}`)}
                                      >
                                        Edit Images
                                      </button>*/}

                                      {
                                      (this.state.current_role !== "agent") &&
                                        <a
                                          onClick={this.editReport.bind(this, c)}
                                          target="_blank" className="btn btn__black--outline btn__profile btn__profile--mob"
                                          href={`/edit-report/community/${c.townId}`} target="_blank"
                                        >
                                          <span className="hidemob">Edit Report</span>
                                          <span className="hideDesk"><img src="./img/editblack.svg"/></span>
                                        </a>
                                      }

                                     
                                    </td>
                                    <td className="">
                                    <a
                                        target="_blank" className="btn btn__green--outline btn__profile btn__profile--mob"
                                        href={`${c.town_url}`} target="_blank"
                                      >
                                        <span className="hidemob">View Report</span>
                                        <span className="hideDesk"><img src="./img/viewgreen.svg"/></span>
                                      </a>
                                      {c.is_delete && (this.state.current_role === "admin" || this.state.current_role === "superadmin" || this.state.current_role === "strategist") ? (
                                        <span className="new__del--icon">
                                          <img
                                            onClick={this.deleteTownBtn.bind(this, c.townId)}
                                            src="./img/delete_icon.svg"
                                          />
                                        </span>
                                      ) : ('')}
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan="6" className="noClientResults">
                                  No towns found for this criteria. Please broaden or change your search criteria and filters
                                </td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </main>
                <div className="pagination-container">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.client_limit}
                    totalItemsCount={townsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
          <div className="flexdiv">
            <h4 className="popupheader__heading">Edit Town Name</h4>
            <button
              onClick={this.closeCrossBtn.bind(this)}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>


          {/* <div className="form-group">
            <h3 className="headModal">Edit Town Name</h3>
            <button onClick={this.closeCrossBtn.bind(this)} type="button" className="btn close-btn closeModal" data-dismiss="modal">
            <img data-dismiss="modal" src="./img/login_close.svg"/></button>
          </div> */}

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Town Name</label>
            <input 
              name="editTownName"
              className="textinputs__global" 
              rows="2" 
              placeholder="Enter Town Name" 
              value = {this.state.editTownName}
              onChange={this.handleChange.bind(this)}/>
          </div>
          { this.state.err.editTownName ?
          <span className="error_msg town__err">
          {this.state.err.editTownName}
          </span> : '' }

          <div className="modalsub__btn">
            <button
              className="btns__fullwidth poppinsfontsedit"
              type="button"
              onClick={this.updateTownName.bind(this)}>
              Update 
            </button>
          </div>
          </div>
        </Modal>


      </div>
    )
  }
}

export default reduxForm({
  form: 'ViewTownReport', // a unique identifier for this form
  destroyOnUnmount: true
})(ViewTownReport)
