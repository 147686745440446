import React, { PureComponent } from 'react'
import { get } from 'lodash';
import Cookies from 'universal-cookie'
import ReactModal from 'react-modal'
import Calendar from 'react-calendar';

import { CalendarIcon, CaretRightIcon, GoogleIcon, CloseIcon } from '../../../../../../components/icons'
import UserContext from "../../../../../App/Context"
import './styles.scss'
import { message } from 'antd';

const cookies = new Cookies()

class CalendarSidebarComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      date: new Date(),
      asynConfirmationModel: false
    }
    this.calendar = React.createRef();
  }

  componentDidUpdate(props) {
    // is unsync calendar event success then fetch appoinment
    if (props.unsyncCalendarEventPhase === "success") {
      const user = this.context.userData
      props.getAppointments({
        user_id: user.id,
        timezone: user.time_zone
      })
      // clear phase for appointmnet
      props.clearAllagentLogedData()
    }
  }
  // toggle update availability open close modal
  toggleUpdateAvailability = () => {
    this.props.toggleUpdateAvailability()
  }
  toogleunsyncCalendarEventModel = (type) => {
    const { asynConfirmationModel } = this.state
    this.setState({ type, asynConfirmationModel: !asynConfirmationModel })
  }
  unsyncCalendarEvent = (type) => {
    const user = this.context.userData
    const data = { userId: user.id }
    if (type === 'google') {
      data.type = 'google'
    } else {
      data.type = 'outlook'
    }
    this.props.unsyncCalendarEvent(data)
    this.setState({ type: '', asynConfirmationModel: false })
  }
  // get data for google and outlook data
  syncCalendarEvent = async type => {
    if (type === 'google') {
      const { value } = await this.props.getGoogleAuthentication();
      if (value && value.data) {
        const win = window.open(value.data, 'Account verifications', 'width=600,height=500,left=50,top=100');
        setTimeout(() => { }, 1000)
        setTimeout(() => {
          win.close();
          window.location.reload(true)
        }, 50000);
      } else {
        message.error("Can't sync calendar. Please try later")
      }
    } else {
      const { value } = await this.props.getOutlookAuthentication();
      if (value && value.data) {
        const win = window.open(value.data, 'Account verifications', 'width=600,height=500,left=50,top=100');
        setTimeout(() => { }, 1000)
        setTimeout(() => {
          win.close();
          window.location.reload(true)
        }, 50000);
      } else {
        message.error("Can't sync calendar. Please try later")
      }
    }
  }
  // open appointment
  openAppointmentModel = () => {
    this.props.openAppointmentModel()
  }
  goToCurrentDate(date) {
    this.calendar.current.setActiveStartDate(date)
  }

  // handle date change
  handleDateChange = date => {
    this.props.changeCurrentDate(date)
  }
  render() {
    const user = this.context.userData
    const { asynConfirmationModel, type } = this.state
    return (
      <div className="calendar__sidebar fixed__sidebar">
        <div className="calendar__sidebar__header">
          <div className="calendar__sidebar__title"><CalendarIcon className="title-icon" />Jungler Calendar</div>
        </div>
        <div className="calendar__sidebar__body">
          <div className="calendar__sidebar__body_inner">
            <button className="btn btn__btn btn-dark w__100 calendar__create__appointment" onClick={this.openAppointmentModel}>
              <CalendarIcon className="calendar-icon" /> Create Appointment
              <CaretRightIcon className="calendar-caret" />
            </button>
            <Calendar
              // minDate={new Date()}
              ref={this.calendar}
              className="calendar__small"
              onChange={this.handleDateChange}
              calendarType={"US"}
              value={this.props.currentDate}
            />
          </div>
          <hr />
          {/*<div className="calendar__sidebar__body_inner">*/}
          {/*  <h4>Update Availability</h4>*/}
          {/*  <p>Click below to setup times that you work each day. If you do not set your “working hours” then we default them from 9am to 5pm every day in your time zone.</p>*/}
          {/*  <button className="btn btn__btn btn__success btn__update__availability w__100" onClick={this.toggleUpdateAvailability}>*/}
          {/*    <CalendarIcon className="calendar-icon" /> Update Availability*/}
          {/*    <CaretRightIcon className="calendar-caret" />*/}
          {/*  </button>*/}
          {/*  <h4>Sync Calendar</h4>*/}
          {/*  <p>Connect your Google or Outlook calendar so we can automatically block off times when you are not available. If you have an event on your calendar, we will not book you for that time slot.</p>*/}
          {/*  <div className="">*/}
          {/*    {get(user, "role", "") === "agent" &&*/}
          {/*      (get(user, 'is_gmail_sync') === 0 ? <button className="btn btn__cal__sync google" onClick={this.syncCalendarEvent.bind(this, 'google')}>*/}
          {/*        <GoogleIcon className="google-icon" />*/}
          {/*      Sync Google Calendar*/}
          {/*    </button> :*/}
          {/*        <div className="btn btn__cal__sync google synced">*/}
          {/*          <GoogleIcon className="google-icon" />*/}
          {/*            Synced*/}
          {/*          <button className="btn btn__btn btn__unsync" onClick={this.toogleunsyncCalendarEventModel.bind(this, 'google')}>*/}
          {/*            Unsync*/}
          {/*          </button>*/}
          {/*        </div>*/}
          {/*      )}*/}
          {/*    {get(user, 'role') === 'agent' &&*/}
          {/*      (get(user, 'is_outlook_sync') === 0 ?*/}
          {/*      <button className="btn btn__cal__sync outlook" onClick={this.syncCalendarEvent.bind(this, 'outlook')}>*/}
          {/*          <img className="outlook-icon" src="./img/img_v2/outlook-cal-sync.png" />*/}
          {/*        Sync Outlook Calendar*/}
          {/*      </button> :*/}
          {/*        <div className="btn btn__cal__sync outlook synced">*/}
          {/*          <img className="outlook-icon" src="./img/img_v2/outlook-cal-sync.png" />*/}
          {/*          Synced*/}
          {/*          <button className="btn btn__btn btn__unsync" onClick={this.toogleunsyncCalendarEventModel.bind(this, 'outlook')}>*/}
          {/*            Unsync*/}
          {/*          </button>*/}
          {/*        </div>*/}
          {/*      )}*/}
          {/*  </div>*/}
          {/*  <p>Note we will not be able to see the event title, we will just block the time out here.</p>*/}
          {/*</div>*/}
        </div>
        <ReactModal
          isOpen={asynConfirmationModel}
          onRequestClose={this.toogleunsyncCalendarEventModel.bind(this, '')}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button type="button" className="btn react-modal-close react-modal-close-sm" onRequestClose={this.toogleunsyncCalendarEventModel.bind(this, '')}><CloseIcon /></button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">This will remove future schedule information sync by {type} calendar and you will need to re sync the schedule if needed.</p>
              <div className="text-right">
                <button onClick={() => this.unsyncCalendarEvent(type)} className="btn btn__btn btn-dark w__150">Yes, Unsync</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
export default CalendarSidebarComponent;
