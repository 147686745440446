import { connect } from 'react-redux'

import TownDetailsComponent from './component'

import { getUserbyId, getTowns, clearPhase } from '../../../../store/dashboard/duck'
import {
  createStrategist,
  addUpdateStrategist,
  getClientStrategist,
  clearStrategistPhase,
} from '../../../../store/strategistV2/strategist/actions'

const TownDetailsContainer = connect(
  // Map state to props
  (state) => ({
    townData: state.dashboard.townData,
    singleClientData: state.dashboard.singleClientData,
    strategyData: state.strategistV2.strategist.clientStrategist,
    getClientStrategistPhase: state.strategistV2.strategist.getClientStrategistPhase,
    addUpdateStrategistPhase: state.strategistV2.strategist.addUpdateStrategistPhase,
  }),
  // Map actions to dispatch and props
  {
    getTowns,
    clearPhase,
    getClientStrategist,
    clearStrategistPhase,
    getUserbyId,
    createStrategist,
    addUpdateStrategist,
  }
)(TownDetailsComponent)

export default TownDetailsContainer
