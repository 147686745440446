import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import Modal from 'react-modal'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import Header from '../Header'
import PrivateSchool from '../PrivateSchool/component'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import styles from './styles.scss'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME

const SortableItem = SortableElement(({value, pubEditClick, pubDeleteClick}) => 
<div className="table school__table--tabs flex__table">
      <div className="school__name">{value.InstitutionName}</div>
      <div className="grade__name">{value.gradespancodebldgtext}</div>
      <div className="rating__name">{value.GSTestRating > 0 ? value.GSTestRating+'/5' : 'No Rated'}</div>
      <div className="teacher__student--name">{value.studentteacher !== 'Not Reported' ? value.studentteacher + ':1' : 'Not Reported'}</div>
      <div className="student__name">{value.studentsnumberof}</div>
      <div className="fl__right">
        <button className="edit__btn edit__btn--new" onClick={()=> pubEditClick(value)}></button>
        <button className="edit__btn delete__btn--new" onClick={()=> pubDeleteClick(value)}></button>
      </div>
  </div>
  )
 
const SortableList = SortableContainer(({items, pubEditClick, pubDeleteClick}) => {
  return (
      <div>
        <div className="flex__table rem__odd--even">
          <div className="school__name">Schools</div>
          <div className="grade__name">Grades</div>
          <div className="rating__name">Rating</div>
          <div className="teacher__student--name">Student to Teacher Ratio</div>
          <div className="student__name"># of Students</div>
          <div className="fl__right"></div>
        </div>

        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} pubEditClick={pubEditClick} pubDeleteClick={pubDeleteClick} value={value} />
        ))}
      </div>
  )
})

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    padding: '0 0',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class PublicSchool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      publicData: [],
      isDateLoading: false,
      modalIsOpen: false,
      edit_school: '',
      school_id: 0,
      edit_grades: '',
      edit_ratio: '',
      edit_no_of_students: '',
      edit_rating: '',
      modelType: '',
      add_school: '',
      add_grades: '',
      add_ratio: '',
      add_no_of_students: '',
      add_rating: '',
      err: {},
      town_name: '',
      openSideBar: false,
      privateAttom: []
    }
  }

  componentWillMount(){
    // this.setState({ isDateLoading: true })
    this.getAttomSchool()
  }

  componentWillReceiveProps(np){
    if(np.editPublicPhase !== this.props.editPublicPhase){
      this.loadingSchoolData()
    }
    if(np.deletePublicPhase !== this.props.deletePublicPhase){
      this.loadingSchoolData()
    }
    if(np.addPublicPhase !== this.props.addPublicPhase){
      this.loadingSchoolData()
    }
  }

  componentDidMount(){
    this.loadingSchoolData()  
    this.getTownName()
  }

  async loadingSchoolData(){
    const {match: { params: { townId }} } = this.props
    const fetchPublicData = () => axios.get(
      `${HOSTNAME}/schoolDataByTownId?town_id=${townId}&type=attom_school_public`
    ).catch(logoutInvalidRequest)
    const pubData = await fetchPublicData()
    if(pubData && pubData.data && pubData.data.data){
      this.setState({ publicData: pubData.data.data, isDateLoading: false })
    }
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name })
    }
  }

async getAttomSchool(){
    const {match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    const getPrivateAttom = () => axios.get(
      `${HOSTNAME}/attomSchools?town_id=${townId}&type=public`
    ).catch(logoutInvalidRequest)

    const privateSchool = await getPrivateAttom()
    if(privateSchool && privateSchool.data && privateSchool.data.schools){
      let privateAttom = privateSchool.data.schools

      privateAttom.map((data, index) => {
        const attomSchoolId = data.Identifier.OBInstID
        const filtered = this.state.publicData.filter(o => {
          return String(o.schoolAttomId) === String(attomSchoolId)
        })
        if (filtered && filtered.length > 0) {
          privateAttom[index].School.isAvailable = true
        }
      })
      
      this.setState({ privateAttom: privateAttom, isDateLoading: false }, ()=>{
      })
    }else{
    this.setState({ isDateLoading: false })
    }
  }

  async loadingSchoolData(){
    const {match: { params: { townId }} } = this.props
    const fetchPublicData = () => axios.get(
      `${HOSTNAME}/schoolDataByTownId?town_id=${townId}&type=attom_school_public`
    ).catch(logoutInvalidRequest)
    const pubData = await fetchPublicData()
    if(pubData && pubData.data && pubData.data.data){
      const publicData = _.orderBy(pubData.data.data, ['sort_by'],['asc'])
      this.setState({ publicData: publicData, isDateLoading: false })
    }
  }

  editInfoChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  pubEditClick(data, e){
    this.setState({ modalIsOpen: true, edit_school: data.InstitutionName, edit_grades: data.gradespancodebldgtext, edit_ratio: data.studentteacher, edit_no_of_students: data.studentsnumberof, school_id: data.id, edit_rating: data.GSTestRating, modelType: "edit" })
  }

  pubAddClick(e){
    this.setState({ 
      modelType: "add", 
      modalIsOpen: true,
      add_school: '',
      add_grades: '',
      add_ratio: '',
      add_no_of_students: '',
      add_rating: '',
    })
  }

parseSchools(schools) {
    const schoolsFinal = []
    _.forEach(schools, school => {
      const obj = {}
      obj.school_name = _.get(school, '[0].InstitutionName','')
      obj.school_grades = _.get(school, '[0].gradespancodebldgtext','')
      obj.school_rating = _.get(school, '[0].GSTestRating','0')
      obj.school_stud_teacher_ratio = _.get(school, '[0].studentteacher','')
      obj.no_of_student = _.get(school, '[0].studentsnumberof','')
      obj.school_type = _.get(school, '[0].Filetypetext','')
      obj.geocodinglatitude = _.get(school, '[0].geocodinglatitude','')
      obj.geocodinglongitude = _.get(school, '[0].geocodinglongitude','')
      schoolsFinal.push(obj)
    })
    return schoolsFinal
  }

  afterOpenModal(){ }

  closeModal=()=>{
    this.setState({ modalIsOpen: false, school_id: 0, modelType: '' })
  }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false, school_id: 0, modelType: '' })
  }

  pubDeleteClick(data, e){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
            <h4 className="popupheader__heading">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          <p className="allpoppadding__para poppinsfontsedit">You want to delete this school?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.dltInst(data)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  async dltInst(data){
    const { deletePublicSchool, match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    let data1 = {
      school_id: data.id,
      town_id: parseInt(townId)
    }
    deletePublicSchool(data1)
  }

  editModelBtn(e){
    const {match: { params: { townId }}, editPublicSchool } = this.props
    const { edit_school, school_id, edit_ratio, edit_grades, edit_no_of_students, edit_rating } = this.state
    let data = {
      school_id: school_id,
      school_name: edit_school,
      grades: edit_grades,
      ratio: edit_ratio,
      totalStudent: edit_no_of_students,
      type: 'public',
      town_id: parseInt(townId),
      rating: edit_rating
    }
    editPublicSchool(data)
    this.setState({ isDateLoading: true, modalIsOpen: false })
  }
 closeCrossBtn(e){
    this.setState({ modalIsOpen: false, school_id: 0, modelType: '' })
  }

  openSideBar(e){
    this.setState({ openSideBar: true })
  }

closeScheduleBar(e){
    this.setState({ openSideBar: false })
  }

  addModelBtn(e){
    let err = {}
    const {match: { params: { townId }}, addPublicSchool } = this.props
    const { add_school, school_id, add_ratio, add_rating, add_grades, add_no_of_students } = this.state
    if( add_school == '' || add_school.trim() === ''){
      err.school = "Enter School Name"
    }
    if( add_ratio === '' || add_ratio.trim() === ''){
      err.ratio = "Enter Ratio"
    }
    if( add_grades === '' || add_grades.trim() === ''){
      err.grades = "Enter Grade"
    }
    if( add_rating === '' || add_rating.trim() == '' ){
      err.rating = "Enter Rating"
    }
    if( add_no_of_students === '' || add_no_of_students.trim() === ''){
      err.no_of_student = "Enter Number of Students"
    }

    this.setState({ err })
     if (!Object.keys(err).length) {
        let data = {
          school_id: 0,
          school_name: add_school,
          grades: add_grades,
          ratio: add_ratio,
          totalStudent: add_no_of_students,
          type: 'public',
          town_id: parseInt(townId),
          rating: add_rating
        }
        addPublicSchool(data) 
        this.setState({ isDateLoading: true, modalIsOpen: false })
     }
  }

  async addPrivateAttom(data,index){
      const {match: { params: { townId }}, addPublicSchool } = this.props
      if (data.School && !data.School.Studentsnumberof) {
        const detail = await this.getSchoolDetails(data, index)
      }
      let attom = data.School
      let data1 = {
        school_id: data.Identifier.OBInstID,
        school_name: attom.InstitutionName,
        grades: attom.gradespancodebldgtext,
        type: 'public',
        town_id: parseInt(townId),
        rating: attom.GSTestRating,
        totalStudent: attom.Studentsnumberof,
        ratio: attom.Studentteacher,
        geocodinglatitude: attom.geocodinglatitude,
        geocodinglongitude: attom.geocodinglongitude,
        locationcity: attom.locationcity,
        zip54: attom.zip54
      }
      const privateAttom = this.state.privateAttom
      privateAttom[index].School.isAvailable = true
      this.setState({ isDateLoading: true, modalIsOpen: false, openSideBar: false, privateAttom })
      addPublicSchool(data1)
  }

  async getSchoolDetails(data,index){
    const {match: { params: { townId }} } = this.props
    const attomSchoolId = data.Identifier.OBInstID
    const schoolDetail = () => axios.get(
      `${HOSTNAME}/attomSchoolDetail?attomSchoolId=${attomSchoolId}&town_id=${townId}`
    ).catch(logoutInvalidRequest)

    const detail = await schoolDetail()
    if(detail && detail.data && detail.data.schoolDetail){
      const enrollment = detail.data.schoolDetail.SchoolEnrollment
      const privateAttom = this.state.privateAttom
      const currentSchool = privateAttom[index]
      currentSchool.School.Studentsnumberof = enrollment.Studentsnumberof
      currentSchool.School.Studentteacher = enrollment.Studentteacher
      currentSchool.School.gradespancodebldgtext = detail.data.schoolDetail.SchoolSummary.gradespancodebldgtext
      privateAttom[index] = currentSchool
      this.setState({ privateAttom })
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({publicData}) => ({
      publicData: arrayMove(publicData, oldIndex, newIndex),
    }))
    const self = this
    setTimeout(()=>{
      self.state.publicData && self.state.publicData.map((val, idx)=>{
        self.updateSchoolList(val.id, idx)
      })
    }, 500)
  }
  
  async updateSchoolList(id, idx){
    const { match: { params: { townId } } } = this.props
    const schoolUpdate = () => axios.get(
      `${HOSTNAME}/update_school?town_id=${parseInt(townId)}&school_id=${parseInt(id)}&school_index=${parseInt(idx)}`
    ).catch(logoutInvalidRequest)
    const towns = await schoolUpdate()
  }

  render() {
    const { publicData, modelType, add_school, add_rating, add_ratio, add_grades, add_no_of_students, edit_school, edit_rating, edit_ratio, edit_grades, edit_no_of_students } = this.state
    return (
      <div className="town-report posi__rel">
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }
         <Header title={`Edit ${this.state.town_name} Public Schools`}/>
        
         <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
        <div className="flexdiv">
          <h4 className="popupheader__heading">{ (modelType === "add") ? "Add Quick Facts" : "Edit Quick Facts" }</h4>
          <button
            onClick={this.closeCrossBtn.bind(this)}
            type="button"
            id="close_user_modal"
            className="close_img close_img--white"
            data-dismiss="modal"
            aria-label="Close"
          >
          <img src="../../img/close_btn.svg"/>
          </button>
        </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">SCHOOL NAME</label>
            <input 
              name={ (modelType=== "add") ? "add_school" : "edit_school" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType=== "add") ? add_school : edit_school}
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.school ?
          <span className="error_msg town__err">
          {this.state.err.school}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">GRADES</label>
            <input 
              name={ (modelType === "add") ? "add_grades" : "edit_grades" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType=== "add") ? add_grades : edit_grades }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.grades ?
          <span className="error_msg town__err">
          {this.state.err.grades}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">RATING</label>
            <input 
              name={ (modelType === "add") ? "add_rating" : "edit_rating" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_rating : edit_rating }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.rating ?
          <span className="error_msg town__err">
          {this.state.err.rating }
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">STUDENT TO TEACHER RATIO</label>
            <input 
              name={ (modelType=== "add") ? "add_ratio" : "edit_ratio" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_ratio : edit_ratio }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.ratio ?
          <span className="error_msg town__err">
          {this.state.err.ratio}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global"># OF STUDENTS</label>
            <input 
              name={ (modelType === "add") ? "add_no_of_students" : "edit_no_of_students" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_no_of_students : edit_no_of_students }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.no_of_student ?
          <span className="error_msg town__err">
          {this.state.err.no_of_student }
          </span> : '' }

          <div className="modalbtns modalbtns__new">
            {
              (modelType === "add")?
              <button
                className="btns__fullwidth poppinsfontsedit"
                type="button"
                onClick={this.addModelBtn.bind(this)}>
                Save 
              </button>
              :
              <button
              className="btns__fullwidth poppinsfontsedit"
              type="button"
              onClick={this.editModelBtn.bind(this)}>
              Update 
            </button>
            }
            
          </div>
          </div>
        </Modal>

{/*-----------this is for sidebar-----------*/}
        <div className={this.state.openSideBar ? "schedule__sidebar--container open sidebar__shadow" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            Attom Public Schools
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
            <div className="schedule__sidebar--form--wrapper">
              {
                (this.state.privateAttom && this.state.privateAttom.length > 0 ) && 
                this.state.privateAttom.map((pri, idx) =>{
                  return(
                    <ul className="position__container" key={idx}>
                      <li>
                        {(pri.School && pri.School.isAvailable) ? (
                          <span className="label__text add__report__txt">Live</span>
                        ) : (
                          <button onClick={this.addPrivateAttom.bind(this, pri, idx)} className="btn__add add__report__btn">Save To Report</button>
                        )}
                        <span className="label__text">Name:</span>
                        <span className="label__details">{pri.School.InstitutionName}</span>
                      </li>
                      <li>
                        <span className="label__text">Address:</span>
                        <span className="label__details">{pri.School.locationaddress}</span>
                      </li>
                      <li>
                        <span className="label__text">City:</span>
                        <span className="label__details">{pri.School.locationcity}</span>
                      </li>
                      <li>
                        <span className="label__text">Zipcode:</span>
                        <span className="label__details">{pri.School.zip54}</span>
                        <span className="label__text label__text--edit">Rating:</span>
                        <span className="label__details label__details--edit">{pri.School.GSTestRating}</span>
                      </li>
                      {(pri.School && pri.School.Studentteacher) ? (
                          <li>
                            <span className="label__text">Grade:</span>
                            <span className="label__details">{pri.School.gradespancodebldgtext}</span>
                            <span className="label__text label__text--edit">Ratio:</span>
                            <span className="label__details label__details--edit">{pri.School.Studentteacher}</span>
                            <span className="label__text label__text--edit">No. of students:</span>
                            <span className="label__details label__details--edit">{pri.School.Studentsnumberof}</span>
                          </li>
                        ) : (
                          <li>
                            <a onClick={this.getSchoolDetails.bind(this, pri, idx)} className="link__view__more">View More...</a>
                          </li>
                        )}
                    </ul>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="tabs__town--reports">
        <div className="row">
        <div className="col-md-12 padd__left--zero padd__top--add">

          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item active">
              <a className="" data-toggle="pill" href="#home">Public Schools</a>
            </li>
            <li className="nav-item">
              <a data-toggle="pill" href="#menu1">Private Schools</a>
            </li>
          </ul>


          <div className="tab-content">
            <div id="home" className="tab-pane active">
              
                <div className="row">
                <div className="col-md-12 posi__rel">
                    <div className="atom__add--btn">
                      <button className="add__button__fixed_new" type="submit" onClick={this.pubAddClick.bind(this)}>Add School</button>
                      <button className="right_button" type="submit" onClick={this.openSideBar.bind(this)}>Attom Schools</button>
                    </div>
                    {
                      publicData && publicData.length > 0 ? 
                      <SortableList
                        items={this.state.publicData} 
                        pubEditClick={(index) => this.pubEditClick(index)}
                        pubDeleteClick={(index) => this.pubDeleteClick(index)}
                        onSortEnd={this.onSortEnd} />
                      :
                      (<p className="no__data--show">No Public Schools</p>)
                    }
                  </div>
              </div>
            </div>
            <div id="menu1" className="tab-pane">
              <div className="row">
                <div className="col-md-12">
                  <PrivateSchool {...this.props}/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
      </div>
    )
  }
}



      
export default reduxForm({
  form: 'PublicSchool', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: [],
})(PublicSchool)
