import 'rxjs'
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import styles from './styles.scss'
import ReportNavigation from '../reportNavigation'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format'
import Modal from 'react-modal'
import Header from '../Header'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME


const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    padding: '0 0',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class Community extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quickFacts: [],
      quickRacial: [],
      isDateLoading: false,
      areaCodes: [],
      attomQuickFacts: [],
      textAvgPer: 0,
      population: 0,
      avgAge: 0,
      avgHomeSale: 0,
      avgHouseHold: 0,
      avgTaxRate: 0,
      modalIsOpen: false,
      edit_population: 0,
      edit_avg_age: 0,
      edit_avg_home_sp: 0,
      edit_avg_house_income: 0,
      edit_white: 0, edit_hispanic: 0, edit_asian: 0, edit_black: 0, edit_other: 0,
      edit_avg_tax_rate: 0,
      edit_avg_tax_rate: 0,
      attomId: 0,
      town_name: ''
    }
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentWillMount(){
    const {user, match: { params: { townId }}, communityDataByTownId } = this.props
    this.setState({ isDateLoading: true })
    let data1 = {
      town_id: townId
    }
    communityDataByTownId(data1)
    this.getAttomAreaCodes()
    this.getTownName()
  }

  componentWillReceiveProps(np){
    const {match: { params: { townId }}, communityDataByTownId } = this.props
    if(np.communityPhase !== this.props.communityPhase){
      if(np.communityData && np.communityData.data){
      this.setState({ isDateLoading: false })
       const data = this.parseQuickFacts(np.communityData.data)
       this.setState({ quickFacts: data, population: data.population, avgAge: data.avg_age, avgHomeSale: 
        data.avg_home_sale_price, avgHouseHold: data.avg_household_income, avgTaxRate: data.tax_avg_percentage, quickRacial: data.racial, edit_avg_tax_rate: data.tax_avg_percentage })
      }
    }
    if(np.editCommunityPhase !== this.props.editCommunityPhase){
      let data1 = { town_id: townId }
      communityDataByTownId(data1)
      this.setState({ isDateLoading: true })
    }
    if(np.synchCommunityPhase !== this.props.synchCommunityPhase){
      let data1 = { town_id: townId }
      communityDataByTownId(data1)
      // this.setState({ isDateLoading: true })
    }
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name })
    }
  }

  editInfoChange(e){
    this.setState({ [e.target.name]: e.target.value }, ()=>{
      let edit_property = this.state.edit_property.toString().replace(/,/g, '')
      edit_property = edit_property.toString().replace('$', '')

      let edit_avg_home_sp = this.state.edit_avg_home_sp.toString().replace(/,/g, '')
      edit_avg_home_sp = edit_avg_home_sp.toString().replace('$', '')

      if (e.target.name === 'edit_avg_tax_rate') {
        edit_property = (edit_avg_home_sp*parseFloat(e.target.value))/100
        this.setState({ edit_property: parseInt(edit_property) })
      } else if(this.state.edit_property || this.state.edit_avg_home_sp){
        let editAvgTaxRate = edit_property / edit_avg_home_sp
        editAvgTaxRate = (parseFloat(editAvgTaxRate) * 100).toFixed(2)
        this.setState({ edit_avg_tax_rate: editAvgTaxRate })
      }
    })
  }

  parseQuickFacts(quick_facts) {
    // eslint-disable-line camelcase
    const obj = {}
    obj.population = this.number_format(this.get_attom_field_value(quick_facts, 'poppy_10'))

    const racial_breakdown = [] // eslint-disable-line camelcase

    const white = this.get_attom_field_value(quick_facts, 'racewhitepy_5')
    racial_breakdown.push({
      racial_label: 'White',
      racial_count: this.number_format(white)
    })

    const hispanic = this.get_attom_field_value(quick_facts, 'racehisppy_5')
    racial_breakdown.push({
      racial_label: 'Hispanic',
      racial_count: this.number_format(hispanic)
    })

    const asian = this.get_attom_field_value(quick_facts, 'raceasianpy_5')
    racial_breakdown.push({
      racial_label: 'Asian',
      racial_count: this.number_format(asian)
    })

    const black = this.get_attom_field_value(quick_facts, 'raceblackpy_5')
    racial_breakdown.push({
      racial_label: 'Black',
      racial_count: this.number_format(black)
    })
    const other = this.get_attom_field_value(quick_facts, 'raceotherpy_5')
    racial_breakdown.push({
      racial_label: 'Other',
      racial_count: this.number_format(other)
    })
    obj.racial = racial_breakdown // eslint-disable-line camelcase

    obj.avg_home_sale_price = this.number_format(this.get_attom_field_value(quick_facts, 'avgsaleprice'))

    obj.avg_household_income = this.number_format(this.get_attom_field_value(quick_facts, 'incavehhpy_5'))

    obj.avg_age = this.get_attom_field_value(quick_facts, 'ageavepy_10')

    obj.assessed_home_value = '3,000,000' 

    obj.safety_crime = this.number_format(this.get_attom_field_value(quick_facts, 'crmcytotc'))

    // if (this.state.suburb_id === 2) { 
    //   const correctedAvgPropertyTax = parseInt(parseFloat(obj.avg_home_sale_price.replace(/,/g, '')) * 0.012)
    //   obj.average_property_tax_amount = this.number_format(correctedAvgPropertyTax)
    // } else {
      obj.average_property_tax_amount = this.number_format(this.get_attom_field_value(quick_facts, 'avg_prop_tax'))
    // }

    if (
      obj.avg_home_sale_price &&
      parseInt(obj.avg_home_sale_price) > 0 &&
      obj.average_property_tax_amount &&
      parseInt(obj.average_property_tax_amount) > 0
    ) {
      const tax_avg_percentage = parseFloat(obj.average_property_tax_amount.replace(/,/g, '')) / parseFloat(obj.avg_home_sale_price.replace(/,/g, '')) // eslint-disable-line camelcase
      obj.tax_avg_percentage = (parseFloat(tax_avg_percentage) * 100).toFixed(2)
    }

    obj.attom_geo_id = this.get_attom_field_value(quick_facts, 'poppy_10', 'geo')
    return obj
  }

  get_attom_field_value(data, field_label, geo) {
    let value = ''
    const filtered = data.filter(o => {
      return o.field_label === field_label 
    })
    _.forEach(filtered, val => {
      if (field_label === 'geocodinglatitude') {
        value = val['lat']
      } else if (field_label === 'geocodinglongitude') {
        value = val['lng']
      } else if (geo === 'geo') {
        value = val['attom_geo_id']
      } else {
        value = val['field_value']
      }
    })
    return value
  }

  number_format(x) {
    // eslint-disable-line camelcase
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  changeContent( type, e){
  }

  async getAttomAreaCodes(e){
    this.setState({ isDateLoading: true })
    const {user, match: { params: { townId }}, communityDataByTownId } = this.props
    const fetchAttomData = () => axios.get(
      `${HOSTNAME}/getAttomAreaCodes?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const raw = await fetchAttomData()
    if(raw && raw.data){
      this.setState({ isDateLoading: false, areaCodes: raw.data })
    }
  }

  async attomChange(e){
    this.setState({ isDateLoading: true })
    if(e.target.value){
      let id = e.target.value
      const fetchAttomData = () => axios.get(
        `${HOSTNAME}/communityDataByAreaCode?id=${e.target.value}`
      ).catch(logoutInvalidRequest)
      const raw = await fetchAttomData()
      let finalData = raw && raw.data && raw.data.data
      if(raw && raw.data && raw.data.data){
        let totalData = raw.data && raw.data.data
        let textAvgPer = totalData.avg_prop_tax / totalData.avgsaleprice
        textAvgPer = (parseFloat(textAvgPer) * 100).toFixed(2)
        this.setState({ isDateLoading: false, attomQuickFacts: raw.data.data, textAvgPer: textAvgPer, attomId: id })
      }else{
        this.setState({ isDateLoading: false, attomId: 0 }, ()=>{
          toast("This Attom Data Not Found", {autoClose: true })
        })
      }
    }
  }

  afterOpenModal(){ }

  closeModal(){
    this.setState({ modalIsOpen: false })
  }

  editInfo(e){
    const { quickFacts } = this.state
    quickFacts && quickFacts.racial && quickFacts.racial.map((rac, idx)=>{
      if(rac.racial_label === 'White'){
        this.setState({ edit_white: rac.racial_count})
      }else if(rac.racial_label === 'Hispanic'){
        this.setState({ edit_hispanic: rac.racial_count})
      }else if(rac.racial_label === 'Asian'){
        this.setState({ edit_asian: rac.racial_count})
      }else if(rac.racial_label === 'Black'){
        this.setState({ edit_black: rac.racial_count})
      }else if(rac.racial_label === 'Other'){
        this.setState({ edit_other: rac.racial_count})
      }
    })
    this.setState({ modalIsOpen: true, edit_population: _.get(quickFacts, 'population', 0), edit_avg_age: _.get(quickFacts, 'avg_age', 0), edit_avg_home_sp: _.get(quickFacts, 'avg_home_sale_price', 0), edit_avg_house_income: _.get(quickFacts, 'avg_household_income', 0), edit_property:  _.get(quickFacts, 'average_property_tax_amount', 0) })
  }

  closeCrossBtn(){
    this.setState({ modalIsOpen: false })
  }

  editModelBtn(e){
    const { match: { params: { townId }}, editCommunityData } = this.props
    let { edit_population, edit_white, edit_hispanic, edit_asian, edit_black, edit_other, edit_avg_age, edit_avg_home_sp, edit_avg_house_income, edit_property } = this.state

    edit_property = edit_property.toString().replace(/,/g, '')
    edit_property = edit_property.toString().replace('$', '')

    edit_avg_home_sp = edit_avg_home_sp.toString().replace(/,/g, '')
    edit_avg_home_sp = edit_avg_home_sp.toString().replace('$', '')

    let editAvgTaxRate = edit_property / edit_avg_home_sp
    editAvgTaxRate = (parseFloat(editAvgTaxRate) * 100).toFixed(2)

    let data = {
      average_property_tax_amount: parseInt(edit_property.toString().replace(/\D/g,'')),
      avg_age: parseFloat(edit_avg_age.toString()),
      avg_home_sale_price: parseInt(edit_avg_home_sp.toString().replace(/\D/g,'')),
      avg_household_income: parseInt(edit_avg_house_income.toString().replace(/\D/g,'')),
      population: parseInt(edit_population.toString().replace(/\D/g,'')),
      tax_avg_percentage: editAvgTaxRate,
      racial_white: parseInt(edit_white.toString().replace(/\D/g,'')),
      racial_hispanic: parseInt(edit_hispanic.toString().replace(/\D/g,'')),
      racial_asian: parseInt(edit_asian.toString().replace(/\D/g,'')),
      racial_black: parseInt(edit_black.toString().replace(/\D/g,'')),
      racial_other: parseInt(edit_other.toString().replace(/\D/g,'')),
      town_id: townId
    }
    editCommunityData(data)
    this.setState({ isDateLoading: true, modalIsOpen: false })
  }

  synchBtn(e){
    const {match: { params: { townId }}, synchCommunity } = this.props
    const { attomQuickFacts, attomId } = this.state
    if( attomId && attomId !== 0){
      attomQuickFacts.townId = parseInt(townId)
      attomQuickFacts.attomId = this.state.attomId
      synchCommunity(attomQuickFacts)
      this.setState({ isDateLoading: true })
    }
  }

  render() {
    const { communityData } = this.props
    const { quickFacts, quickRacial, areaCodes, attomQuickFacts, population, avgAge, avgHomeSale, avgHouseHold, avgTaxRate } = this.state
    return (
      <div>
       <Header title={`Edit ${this.state.town_name} Town Report`} />
        <ToastContainer autoClose={3000}/>
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }

        <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-12 col-lg-4 quickfacts__row">
            <h3 className="section__title">Quick Facts {_.get(quickFacts, 'attom_geo_id', false) ? '('+_.get(quickFacts, 'attom_geo_id', false)+')': ''} <img className="quick__edit" onClick={this.editInfo.bind(this)} src="../../img/edit-icon.svg" /></h3>
            <div className="row">
              <div className="quickfacts__listing">
                <ul style={{marginLeft: "5px"}}>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="populationIcon" className="media-object" alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          Population <img onClick={this.changeContent.bind(this, 'population')} src="infoIcon" alt="" />
                        </h4>
                        <p>{_.get(quickFacts, 'population', '')}</p>
                        <div className="view__breakdown" onClick={this.changeContent.bind(this, 'racicalBreakdown')}>
                          <h4 className="media-heading new__media--heading">View Demographic makeup</h4>
                          {
                            quickRacial && quickRacial.map((racial, id)=>{
                              return(
                                <div key={id}>
                                  <p>{racial.racial_label}: {racial.racial_count}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="averageIcon" className="media-object" alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">
                          Average Age
                        </h4>
                        <p>{_.get(quickFacts, 'avg_age', '')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="quickfacts__listing">
                <ul style={{marginLeft: "15px"}}>
                  <li>
                    <div className="media">
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Home Sale Price</h4>
                        <p className="new__para">${_.get(quickFacts, 'avg_home_sale_price', '')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Household Income</h4>
                        <p className="new__para">${_.get(quickFacts, 'avg_household_income', '')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Tax Rate</h4>
                        <p className="new__para">{_.get(quickFacts, 'tax_avg_percentage', '-')}%</p>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <button className="navigation__links" type="text" onClick={this.editInfo.bind(this)}>Edit</button>
                  </li> */}
                </ul>
              </div>
            </div>
        </div>

        <div className="col-xs-12 col-md-12 col-sm-6 col-lg-4 quickfacts__row">
            <h3 className="section__title">Attom data</h3>
            <div className="row">
              <div className="quickfacts__listing">
                <ul style={{marginLeft: "17px"}}>
                <li>
                    <div className="media">
                      <div className="media-body">
                        {/*<h4 className="media-heading">
                          Attom Area Code
                        </h4>*/}

                        <div className="view__breakdown view__breakdown--edit margb">
                        <label>Attom Area Code</label>
                          <select value={this.state.attomId} 
                            onChange={this.attomChange.bind(this)}>
                            <option value=''>Select</option>
                            {
                              areaCodes && areaCodes.data && areaCodes.data.map((community, idx)=>{
                                return(
                                  <option key={idx} value={community.id}>{community.id}</option>
                                )
                              })
                            }
                          </select>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-body">
                        <h4 className="media-heading">
                          Population
                        </h4>
                        <p>{attomQuickFacts.poppy_10 ? attomQuickFacts.poppy_10: ' - ' }</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <h5>View Demographic makeup</h5>
                  </li>
                  <li>
                    <h6 className="media-heading media__heading--new">
                      White: <span>{attomQuickFacts.racewhitepy_5 ? attomQuickFacts.racewhitepy_5: ' - ' }</span>
                    </h6>
                  </li>
                  <li>
                        <h6 className="media-heading media__heading--new">
                          Hispanic:  <span>{attomQuickFacts.racehisppy_5 ? attomQuickFacts.racehisppy_5: ' - ' }</span>
                        </h6>
                  </li>
                  <li>
                        <h6 className="media-heading media__heading--new">
                          Asian: <span>{attomQuickFacts.raceasianpy_5 ? attomQuickFacts.raceasianpy_5: ' - ' }</span>
                        </h6>
                  </li>
                  <li>
                        <h6 className="media-heading media__heading--new">
                          Black: <span>{attomQuickFacts.raceblackpy_5 ? attomQuickFacts.raceblackpy_5: ' - ' }</span>
                        </h6>
                        
                  </li>
                  <li>
                        <h6 className="media-heading media__heading--new">
                          Other:  <span>{attomQuickFacts.raceotherpy_5 ? attomQuickFacts.raceotherpy_5: ' - ' }</span>
                        </h6>
                  </li>
                  <li>

                        <h4 className="media-heading media__heading--new">
                          Average Age 
                        </h4>
                        <p>{ attomQuickFacts.ageavepy_10 ? attomQuickFacts.ageavepy_10 : ' - '}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="quickfacts__listing">
                <ul style={{marginLeft: "5px"}}>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="saleprice" className="media-object" alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Home Sale Price</h4>
                        <p>{ attomQuickFacts.avgsaleprice ? '$'+attomQuickFacts.avgsaleprice : ' - '}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="householdincome" className="media-object" alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Household Income</h4>
                        <p>{ attomQuickFacts.incavehhpy_5 ? '$'+attomQuickFacts.incavehhpy_5 : ' - '}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="householdincome" className="media-object" alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading new__media--heading">Average Tax Rate</h4>
                        <p>{ this.state.textAvgPer ? this.state.textAvgPer+"%" : "-" }</p>
                      </div>
                    </div>
                  </li>
                </ul>

                <div>
                  <button onClick={this.synchBtn.bind(this)} className="navigation__links extra__padding marglr" type="button">
                    Update Data
                  </button>
                </div>
              </div>
            </div>
        </div>
        </div>



        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom  town-report-edit allpoppadding poppinsfontsedit">
          <div className="flexdiv">
            <h4 className="popupheader__heading">Edit Quick Facts</h4>
            <button
              onClick={this.closeCrossBtn.bind(this)}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Population</label>
            <NumberFormat 
              name="edit_population"
              className="textinputs__global"
              value={this.state.edit_population} 
              onChange={this.editInfoChange.bind(this)}
              thousandSeparator={true}
              style={{marginBottom: "10px !important"}}
            />
          </div>

          <h5>View Demographic makeup</h5>
          <div className="form-group">
            <label className="labelinputs__global labelcapital__global">White</label>
            <NumberFormat 
            name="edit_white"
            className="textinputs__global"
            value={this.state.edit_white} 
            onChange={this.editInfoChange.bind(this)}
            thousandSeparator={true}
            style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
            <label className="labelinputs__global labelcapital__global">Hispanic</label>
            <NumberFormat 
            name="edit_hispanic"
            className="textinputs__global"
            value={this.state.edit_hispanic} 
            onChange={this.editInfoChange.bind(this)}
            thousandSeparator={true}
            style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
            <label className="labelinputs__global labelcapital__global">Asian</label>
            <NumberFormat 
            name="edit_asian"
            className="textinputs__global"
            value={this.state.edit_asian} 
            onChange={this.editInfoChange.bind(this)}
            thousandSeparator={true}
            style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
            <label className="labelinputs__global labelcapital__global">Black</label>
            <NumberFormat 
            name="edit_black"
            className="textinputs__global"
            value={this.state.edit_black} 
            onChange={this.editInfoChange.bind(this)}
            thousandSeparator={true}
            style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
            <label className="labelinputs__global labelcapital__global">Other</label>
            <NumberFormat 
            name="edit_other"
            className="textinputs__global"
            value={this.state.edit_other} 
            onChange={this.editInfoChange.bind(this)}
            thousandSeparator={true}
            style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Average Age</label>
            <NumberFormat 
              name="edit_avg_age"
              className="textinputs__global"
              value={this.state.edit_avg_age} 
              onChange={this.editInfoChange.bind(this)}
              thousandSeparator={true}
              style={{marginBottom: "10px !important"}}
             />
          </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Average Home Sale Price</label>
            <NumberFormat 
              name="edit_avg_home_sp"
              className="textinputs__global"
              value={this.state.edit_avg_home_sp} 
              onChange={this.editInfoChange.bind(this)}
              thousandSeparator={true} 
              style={{marginBottom: "10px !important"}}
              prefix='$'
            />
          </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Average Household Income</label>
            <NumberFormat 
              name="edit_avg_house_income"
              className="textinputs__global"
              value={this.state.edit_avg_house_income} 
              onChange={this.editInfoChange.bind(this)}
              thousandSeparator={true} 
              style={{marginBottom: "10px !important"}}
              prefix='$'
            />
          </div>

          <div className="form-group" style={{display : "none"}}>
            <label className="labelinputs__global labelcapital__global">Average Property Tax Amount</label>
            <NumberFormat 
              name="edit_property"
              className="textinputs__global"
              value={this.state.edit_property} 
              onChange={this.editInfoChange.bind(this)} 
              style={{marginBottom: "10px !important"}}
              thousandSeparator={true} 
              prefix='$'
            />
          </div>

          <label className="labelinputs__global labelcapital__global">Average Tax Rate: 
            <NumberFormat 
              name="edit_avg_tax_rate"
              className="textinputs__global"
              value={this.state.edit_avg_tax_rate} 
              onChange={this.editInfoChange.bind(this)}
              thousandSeparator={true} 
              style={{marginBottom: "10px !important"}}
            />%
          </label>
          <div className="modalbtns modalbtns__new">
            <button
              className="btns__fullwidth poppinsfontsedit"
              type="button"
              onClick={this.editModelBtn.bind(this)}>
              Update 
            </button>
          </div>
          </div>
        </Modal>

      </div>
    )
  }
}

export default reduxForm({
  form: 'Community', // a unique identifier for this form
  destroyOnUnmount: true
})(Community)
