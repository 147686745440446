import React from "react";
import Loader from '../../../../../components/Loader'
import AppointmentTableHeader from "./AppointmentTableHeader";
import AppointmentList from "./AppointmentList";
import { Spin } from "antd";

const RejectedAgents = props => {
  const {rejectedApplicants,pageLimit,isLoading,sortByKey,sortByType,sortData,handleApprove,activePage} = props;
  
  return (
    <div>
      <Spin size="large" spinning={isLoading} indicator={<Loader />}>
        {rejectedApplicants && rejectedApplicants.length > 0 ? (
          <div className="table-responsive">
            <table className="table custom-table">
              <AppointmentTableHeader
                appointments={rejectedApplicants}
                tab={2}
                sortByType={sortByType}
                sortByKey={sortByKey}
                sortData={sortData}
              />
              <AppointmentList
                applicants={rejectedApplicants}
                key={Math.random(0,10)}
                approveApplicant={handleApprove}
                pageLimit={pageLimit}
                tab={2}
              />
            </table>
          </div>
        ) : (
          <div className="blank__table__state">No agents found.</div>
        )}
      </Spin>
    </div>
  );
};

export default RejectedAgents;
