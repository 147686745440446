import * as types from './action-types'

export const getDashboardData = (payload) => ({
  type: types.GET_AGENT_DASHBOARD,
  payload,
})

export const getNotTouchedData = (payload) => ({
  type: types.GET_NOT_TOUCHED_DATA,
  payload,
})

export const getNotTouchedIn4Week = (payload) => ({
  type: types.GET_NOT_TOUCHED_IN_4WEEK,
  payload,
})

export const fetchAgentAllClient = (data, type) => ({
  type: types.FETCH_AGENT_ALL_CLIENT,
  payload: { data, type },
})

export const deleteChatData = (payload) => ({
  type: types.DELETE_CHAT_DATA,
  payload,
})

export const editStrategistAgentNote = (payload) => ({
  type: types.EDIT_STRATEGIST_AGENT_NOTE,
  payload,
})

export const deleteStrategistAgentNote = (payload) => ({
  type: types.DELETE_STRATEGIST_AGENT_NOTE,
  payload,
})

export const editClientChat = (payload) => ({
  type: types.EDIT_CLIENT_CHAT,
  payload,
})

export const clearAgentPhase = () => {
  return {
    type: types.CLEAR_AGENT_PHASE,
  }
}
