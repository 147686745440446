import Rx from 'rxjs/Rx'
import { Record } from 'immutable'
import { combineEpics } from 'redux-observable'
import HttpStatus from 'http-status-codes'
import { assign } from 'lodash'
import Cookies from 'universal-cookie'

import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
import Config,{ getQueryParam } from '../../config'

import * as api from './api'
import {getHostname} from "../../utils/helper";

/***********************************
 * Action Types
 ***********/
const cookies = new Cookies();
export const LOGIN_USER = 'suburban/user/LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'suburban/user/LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'suburban/user/LOGIN_USER_ERROR'

export const LOGIN_CLIENT_USER = 'suburban/user/LOGIN_CLIENT_USER'
export const LOGIN_CLIENT_USER_SUCCESS = 'suburban/user/LOGIN_CLIENT_USER_SUCCESS'
export const LOGIN_CLIENT_USER_ERROR = 'suburban/user/LOGIN_CLIENT_USER_ERROR'

export const VALIDATE_TOKEN_CLIENT_USER = 'suburban/user/VALIDATE_TOKEN_CLIENT_USER'
export const VALIDATE_TOKEN_CLIENT_USER_SUCCESS = 'suburban/user/VALIDATE_TOKEN_CLIENT_USER_SUCCESS'
export const VALIDATE_TOKEN_CLIENT_USER_ERROR = 'suburban/user/VALIDATE_TOKEN_CLIENT_USER_ERROR'

export const OTP_VERIFICATION = 'suburban/user/OTP_VERIFICATION'
export const OTP_VERIFICATION_SUCCESS = 'suburban/user/OTP_VERIFICATION_SUCCESS'
export const OTP_VERIFICATION_ERROR = 'suburban/user/OTP_VERIFICATION_ERROR'

export const FETCH_USER = 'suburban/user/FETCH_USER'
export const FETCH_USER_SUCCESS = 'suburban/user/FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'suburban/user/FETCH_USER_ERROR'
export const FETCH_USER_CANCELLED = 'suburban/user/FETCH_USER_ERROR'

export const UPDATE_PROFILE = 'suburban/user/UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCESS = 'suburban/user/UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'suburban/user/UPDATE_PROFILE_ERROR'

export const UPDATE_PASSWORD = 'suburban/user/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'suburban/user/UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'suburban/user/UPDATE_PASSWORD_ERROR'

export const UPDATE_OWN_PROFILE = 'suburban/user/UPDATE_OWN_PROFILE'
export const UPDATE_OWN_PROFILE_SUCCESS = 'suburban/user/UPDATE_OWN_PROFILE_SUCCESS'
export const UPDATE_OWN_PROFILE_ERROR = 'suburban/user/UPDATE_OWN_PROFILE_ERROR'

export const SAVE_GOOGLE_DATA = 'suburban/user/SAVE_GOOGLE_DATA'
export const SAVE_GOOGLE_DATA_SUCCESS = 'suburban/user/SAVE_GOOGLE_DATA_SUCCESS'
export const SAVE_GOOGLE_DATA_ERROR = 'suburban/user/SAVE_GOOGLE_DATA_ERROR'

export const REFRESH_TOKEN = 'suburban/user/REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'suburban/user/REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'suburban/user/REFRESH_TOKEN_ERROR'

export const LOGIN_USER_WP = 'suburban/user/LOGIN_USER_WP'
export const LOGIN_USER_WP_SUCCESS = 'suburban/user/LOGIN_USER_WP_SUCCESS'
export const LOGIN_USER_WP_ERROR = 'suburban/user/LOGIN_USER_WP_ERROR'

export const SIGNUP_USER_WP = 'suburban/user/SIGNUP_USER_WP'
export const SIGNUP_USER_WP_SUCCESS = 'suburban/user/SIGNUP_USER_WP_SUCCESS'
export const SIGNUP_USER_WP_ERROR = 'suburban/user/SIGNUP_USER_WP_ERROR'

export const CREATE_USER_CHECK = 'suburban/user/CREATE_USER'
export const CREATE_USER_CHECK_SUCCESS = 'suburban/user/CREATE_USER_SUCCESS'
export const CREATE_USER_CHECK_ERROR = 'suburban/user/CREATE_USER_ERROR'

export const CREATE_AGENT_CHECK = 'suburban/user/CREATE_AGENT_CHECK'
export const CREATE_AGENT_CHECK_SUCCESS = 'suburban/user/CREATE_AGENT_CHECK_SUCCESS'
export const CREATE_AGENT_CHECK_ERROR = 'suburban/user/CREATE_AGENT_CHECK_ERROR'

export const CREATE_USER = 'suburban/user/CREATE_USER'
export const CREATE_USER_SUCCESS = 'suburban/user/CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'suburban/user/CREATE_USER_ERROR'

export const RESET_PASSWORD_LINK = 'suburban/user/RESET_PASSWORD_LINK'
export const RESET_PASSWORD_LINK_SUCCESS = 'suburban/user/RESET_PASSWORD_LINK_SUCCESS'
export const RESET_PASSWORD_LINK_ERROR = 'suburban/user/RESET_PASSWORD_LINK_ERROR'

export const RESET_PASSWORD = 'suburban/user/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'suburban/user/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'suburban/user/RESET_PASSWORD_ERROR'

export const RESET_PASSWORD_WP = 'suburban/user/RESET_PASSWORD_WP'
export const RESET_PASSWORD_WP_SUCCESS = 'suburban/user/RESET_PASSWORD_WP_SUCCESS'
export const RESET_PASSWORD_WP_ERROR = 'suburban/user/RESET_PASSWORD_WP_ERROR'

export const USER_SIGNOUT = 'suburban/user/USER_SIGNOUT'
export const USER_SIGNOUT_SUCCESS = 'suburban/user/USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_ERROR = 'suburban/user/USER_SIGNOUT_ERROR'

export const GET_NOTIFICATIONS = 'suburban/user/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'suburban/user/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'suburban/user/GET_NOTIFICATIONS_ERROR'

export const CLEAR_NOTIFICATION = 'suburban/user/CLEAR_NOTIFICATION'
export const CLEAR_NOTIFICATION_SUCCESS = 'suburban/user/CLEAR_NOTIFICATION_SUCCESS'
export const CLEAR_NOTIFICATION_ERROR = 'suburban/user/CLEAR_NOTIFICATION_ERROR'

export const CLEAR_PHASES = 'suburban/user/CLEAR_PHASES'
export const CLEAR_PHASES_USER = 'suburban/user/CLEAR_PHASES_USER'


/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null,
  phase:INIT,
  updateProfilePhase:INIT,
  profilePhase: INIT,
  profileError: null,
  saveGoogleDataPhase:INIT,
  error: null,
  loginPhase: INIT,
  isStratagist: false,
  isAdmin: false,
  isSuperAdmin: false,
  isAgent:false,
  isLocal:false,
  isClient: false,
  loginError: null,
  clientloginPhase: INIT,
  clientloginError: null,
  signupWpPhase: INIT,
  signupWpError: null,
  loginWpPhase: INIT,
  loginWpError: null,
  createUserCheckPhase: INIT,
  createUserCheckError: null,
  createAgentCheckPhase: INIT,
  otpPhase: INIT,
  otpData: [],
  createAgentCheckError: null,
  createUserPhase: INIT,
  createUserError: null,
  passwordPhase: INIT,
  ownUpdatePhase: INIT,
  passwordError: null,  
  profileError: null,
  user: null,
  users: {},
  passwordData: [],
  ownUpdateData: [],
  isSubmitting: false,
  resetEmailPhase: INIT,
  resetPasswordPhase: INIT,
  forgotPasswordError: null,
  resetPasswordError: null,
  resetPasswordWpPhase: INIT,
  resetPasswordWpError: null,
  otpMessage: '',
  otpStatus: '',
  pageLimit:0,
  ownUpdatMessage: '',
  updateProfileMsg: '',
  refreshTokenPhase:INIT,
  notificationPhase : INIT,
  notificationData: [],
  clearNotificationPhase: INIT
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = cookies.get('Authorization')
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function (state = new InitialState(), action = {}) {

  switch (action.type) {
    case LOGIN_USER: {
      return state
        .set('loginPhase', LOADING)
        .set('loginError', null)
    }
    case LOGIN_USER_SUCCESS: {
      const { payload } = action
      if(payload && payload.status && payload.status ===true && payload.token && payload.token !==''){
        let expires = new Date()
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth()+2);
        expires = new Date(expires)
        cookies.set('Authorization', payload.token, { path: '/', expires, domain: getHostname() });
        cookies.set('user', JSON.stringify(payload.user), { path: '/', expires, domain: getHostname()  });
        return state
          .set('loginPhase', SUCCESS)
          .set('users', payload)
          .set('loginError', null)
      }else{
        return state
          .set('loginPhase',ERROR)
          .set('loginError', payload.message)
      }
    
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action
      return state
        .set('loginPhase', ERROR)
        .set('loginError', payload.error)        
    }

    case LOGIN_CLIENT_USER: {
      return state
        .set('clientloginPhase', LOADING)
        .set('clientloginError', null)
    }

    case LOGIN_CLIENT_USER_SUCCESS: {
      const { payload } = action
      // Do not set here the token, will setting after validated email link   
      return state
        .set('clientloginPhase', payload.status)
        .set('users', payload)
        .set('clientloginError', null)
    }

    case LOGIN_CLIENT_USER_ERROR: {
      const { payload } = action
      return state
        .set('clientloginPhase', ERROR)
        .set('clientloginError', payload.error)        
    }
    
    case USER_SIGNOUT: {
      cookies.remove('Authorization', { path: '/', domain: getHostname() })
      cookies.remove('user', { path: '/', domain: getHostname() })
      return state
      .set('loginPhase', INIT)
      .set('users', [])
      .set('loginError', null)
    }
    

    case VALIDATE_TOKEN_CLIENT_USER: {
      return state
        .set('clientloginPhase', LOADING)
        .set('clientloginError', null)
    }

    case VALIDATE_TOKEN_CLIENT_USER_SUCCESS: {
      const { payload } = action
      let expires = new Date()
      //expires.setHours(expires.getHours() + 24 )
      expires.setMonth(expires.getMonth()+2);
      expires = new Date(expires)
      // cookies.set('Authorization', payload.token, { path: '/', expires });
      cookies.set('Authorization', payload.token, { path: '/', expires, domain: getHostname() });
      cookies.set('user', JSON.stringify(payload.user), { path: '/', expires, domain: getHostname()  });
      return state
        .set('clientloginPhase', payload.status)
        .set('users', payload)
        .set('clientloginError', null)
    }

    case VALIDATE_TOKEN_CLIENT_USER_ERROR: {
      const { payload } = action
      return state
        .set('clientloginPhase', ERROR)
        .set('clientloginError', payload.error)        
    }

    case FETCH_USER: {
      return state
        .set('phase', INIT)
        .set('updateProfilePhase', INIT)
        .set('clientloginPhase', INIT)
        .set('profilePhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case FETCH_USER_SUCCESS: {
      const { payload } = action
       if(payload.status && payload.status===true){
        // let expires = new Date()
        // expires.setHours(expires.getHours() + 24 )
        // expires = new Date(expires)
        // cookies.set('Authorization', payload.token, { path: '/', expires });
        return state
        .set('users', payload.data)
        .set('phase', SUCCESS)
        .set('profilePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
      }
      else{
        cookies.remove('Authorization')
        cookies.remove('user')
      } 
    }

    case FETCH_USER_ERROR: {
      const { error } = action.payload

        if (error.statusCode === HttpStatus.UNAUTHORIZED
          || error.statusCode === HttpStatus.FORBIDDEN
          || error.statusCode === HttpStatus.NOT_FOUND
        ) {
          //Remove JWT from local storage
          localStorage.removeItem(Config.LocalStorageKeys.Authorization)
        }

      return state
        .set('token', null)
        .set('users', null)
        .set('error', error)
        .set('phase', ERROR)
        .set('isSubmitting', false)

      }

    case FETCH_USER_CANCELLED:

     case UPDATE_PROFILE: {
      return state
        .set('phase', LOADING)
        .set('updateProfilePhase', LOADING)
        .set('clientloginPhase', INIT)
        .set('profileError', null)        
    }

    case UPDATE_PROFILE_SUCCESS: {
      const { payload } = action 
      return state
        .set('updateProfilePhase', SUCCESS)
        .set('updateProfileMsg', payload.message)
        .set('loginPhase', INIT)
        .set('profileError', null)
    }

    case UPDATE_PROFILE_ERROR: {
      const { payload } = action
      return state
        .set('updateProfilePhase', ERROR)
        .set('profileError', payload.error)        
    }
    

    case SAVE_GOOGLE_DATA: {
      return state
        .set('phase', LOADING)
        .set('loginPhase', LOADING)
        .set('saveGoogleDataPhase', LOADING)
        .set('profileError', null)        
    }

    case SAVE_GOOGLE_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('loginPhase', SUCCESS)
        .set('users', payload.data.user)
        .set('saveGoogleDataPhase', SUCCESS)
        .set('profileError', null)
    }

    case SAVE_GOOGLE_DATA_ERROR: {
      const { payload } = action
      return state
        .set('saveGoogleDataPhase', ERROR)
        .set('profileError', payload.error)        
    }

    case REFRESH_TOKEN: {
      return state
        .set('refreshTokenPhase', LOADING)
    }

    case REFRESH_TOKEN_SUCCESS: {
      const { payload } = action
      if(payload.status && payload.token !== '' && payload.token !== undefined){
        let expires = new Date()
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth()+2);
        expires = new Date(expires)
        // cookies.set('Authorization', payload.token, { path: '/', expires });
        cookies.set('Authorization', payload.token, { path: '/', expires, domain: getHostname() });
        return state
          .set('refreshTokenPhase', SUCCESS)
      } else {
        cookies.remove('Authorization', { path: '/', domain: getHostname() })
        cookies.remove('user', { path: '/', domain: getHostname() })
      }
    }

    case REFRESH_TOKEN_ERROR: {
      const { payload } = action
      return state
        .set('refreshTokenPhase', ERROR)
    }

    case SIGNUP_USER_WP: {
      return state
        .set('signupWpPhase', LOADING)
        .set('signupWpError', null)
    }

    case SIGNUP_USER_WP_SUCCESS: {
      const { payload } = action
      return state
        .set('signupWpPhase', SUCCESS)
        .set('user', payload)
        .set('signupWpError', null)
    }

    case SIGNUP_USER_WP_ERROR: {
      const { payload } = action
      return state
        .set('signupWpPhase', ERROR)
        .set('signupWpError', payload)        
    }


    case OTP_VERIFICATION: {
      return state
        .set('otpPhase', LOADING)
        .set('loginError', null)
    }

    case OTP_VERIFICATION_SUCCESS: {
      const { payload } = action
      if(payload && payload.status ==true) {
        let expires = new Date()
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth()+2);
        expires = new Date(expires)
        cookies.set('Authorization', payload.user.token, { path: '/', expires, domain: getHostname() });
        cookies.set('user', JSON.stringify(payload.user), { path: '/', expires, domain: getHostname()  });
        return state
          .set('clientloginPhase', payload.status)
          .set('users', payload)
          .set('clientloginError', null)
          .set('otpPhase', SUCCESS)
          .set('otpData', payload)
          .set('otpStatus', payload.status)
          .set('otpMessage', payload.message)
          .set('loginError', null)
        }else {
          return state
          .set('clientloginPhase', payload.status)
          .set('users', payload)
          .set('clientloginError', null)
          .set('otpPhase', ERROR)
          .set('otpData', payload)
          .set('otpStatus', payload.status)
          .set('otpMessage', payload.message)
          .set('loginError', null)
        }
      

    }

    case OTP_VERIFICATION_ERROR: {
      const { payload } = action
      return state
        .set('otpPhase', ERROR)
        .set('loginError', payload)        
    }

    case UPDATE_PASSWORD: {
      return state
        .set('passwordPhase', LOADING)
        .set('passwordError', null)
    }

    case UPDATE_PASSWORD_SUCCESS: {
      const { payload } = action
      return state
        .set('passwordPhase', SUCCESS)
        .set('passwordData', payload)
        .set('passwordError', null)
    }

    case UPDATE_PASSWORD_ERROR: {
      const { payload } = action
      return state
        .set('passwordPhase', ERROR)
        .set('passwordError', payload)        
    }


    case UPDATE_OWN_PROFILE: {
      return state
        .set('ownUpdatePhase', LOADING)
        .set('profileError', null)
    }

    case UPDATE_OWN_PROFILE_SUCCESS: {
      const { payload } = action
      return state
        .set('ownUpdatePhase', SUCCESS)
        .set('ownUpdateData', payload)
        .set('ownUpdatMessage', payload.message)
        .set('profileError', null)
    }

    case UPDATE_OWN_PROFILE_ERROR: {
      const { payload } = action
      return state
        .set('ownUpdatePhase', ERROR)
        .set('profileError', payload)        
    }



    case LOGIN_USER_WP: {
      return state
        .set('loginWpPhase', LOADING)
        .set('loginWpError', null)
    }

    case LOGIN_USER_WP_SUCCESS: {
      const { payload } = action
      return state
        .set('loginWpPhase', SUCCESS)
        .set('user', payload)
        .set('loginWpError', null)
    }

    case LOGIN_USER_WP_ERROR: {
      const { payload } = action
      return state
        .set('loginWpPhase', ERROR)
        .set('loginWpError', payload)        
    }

    case CREATE_USER_CHECK: {
      return state
        .set('createUserPhase', LOADING)
        .set('loginPhase', INIT)
        .set('createUserError', null)        
    }

    case CREATE_USER_CHECK_SUCCESS: {
      const { payload } = action

      return state
        .set('createUserPhase', SUCCESS)
        .set('loginPhase', INIT)
        .set('createUserError', null)
        .set('user', payload)
    }

    case CREATE_USER_CHECK_ERROR: {
      const { payload } = action
      return state
        .set('createUserPhase', ERROR)
        .set('createUserError', payload.error)        
    }

    case CREATE_AGENT_CHECK_SUCCESS: {
      const { payload } = action

      return state
        .set('createAgentCheckPhase', SUCCESS)
        .set('loginPhase', INIT)
        .set('createAgentCheckError', null)
        .set('user', payload)
    }

    case CREATE_AGENT_CHECK_ERROR: {
      const { payload } = action
      return state
        .set('createAgentCheckPhase', ERROR)
        .set('createAgentCheckError', payload.error)        
    }

    case CREATE_USER: {
      return state
        .set('createUserPhase', LOADING)
        .set('loginPhase', INIT)
        .set('createUserError', null)        
    }

    case CREATE_USER: {
      return state
        .set('createUserPhase', LOADING)
        .set('loginPhase', INIT)
        .set('createUserError', null)        
    }

    case CREATE_USER_SUCCESS: {
      const { payload } = action

      return state
        .set('createUserPhase', SUCCESS)
        .set('loginPhase', INIT)
        .set('createUserError', null)
        .set('user', payload.user)
    }

    case CREATE_USER_ERROR: {
      const { payload } = action
      return state
        .set('createUserPhase', ERROR)
        .set('createUserError', payload.error)        
    }

    case CLEAR_PHASES: {
      return state
        .set('notificationPhase', INIT) 
        .set('passwordPhase', INIT)
        .set('profilePhase', INIT)
    }

    case CLEAR_PHASES_USER: {
      return state 
        .set('passwordPhase', INIT)
        .set('ownUpdatePhase', INIT)
        .set('ownUpdatMessage', '')
        .set('updateProfileMsg', '')
        .set('clientloginPhase', INIT)
    }


    case RESET_PASSWORD_LINK: {
      return state
        .set('resetEmailPhase', LOADING)
        .set('forgotPasswordError', null)        
    }

    case RESET_PASSWORD_LINK_SUCCESS: {
      const { payload } = action
      return state
        .set('resetEmailPhase', SUCCESS)
        .set('forgotPasswordError', payload.error)
    }

    case RESET_PASSWORD_LINK_ERROR: {
      const { payload } = action
      return state
        .set('forgotPasswordError', payload.error)
        .set('resetEmailPhase', ERROR)
    }

    case RESET_PASSWORD: {
      return state
        .set('resetPasswordPhase', LOADING)
        .set('resetPasswordError', null)
    }

    case RESET_PASSWORD_SUCCESS: {
      const { payload } = action
      return state
        .set('resetPasswordPhase', SUCCESS)
        .set('resetPasswordError', payload)
        .set('isSubmitting', false)
    }

    case RESET_PASSWORD_ERROR: {
      const { payload } = action
      return state
        .set('resetPasswordError', payload.error)
        .set('resetPasswordPhase', ERROR)
        .set('isSubmitting', false)
    }

    case RESET_PASSWORD_WP: {
      return state
        .set('resetPasswordWpPhase', LOADING)
        .set('resetPasswordWpError', null)
    }

    case RESET_PASSWORD_WP_SUCCESS: {
      const { payload } = action
      return state
        .set('resetPasswordWpPhase', SUCCESS)
        .set('resetPasswordWpError', payload)
        .set('isSubmitting', false)
    }

    case RESET_PASSWORD_WP_ERROR: {
      const { payload } = action
      return state
        .set('resetPasswordWpError', payload.error)
        .set('resetPasswordWpPhase', ERROR)
        .set('isSubmitting', false)
    }

    case GET_NOTIFICATIONS: {
      return state
        .set('clearNotificationPhase', INIT)
        .set('notificationPhase', LOADING) 
    }

    case GET_NOTIFICATIONS_SUCCESS: {
      const { payload } = action
      return state
        .set('notificationPhase', SUCCESS)
        .set('notificationData', payload.data)
    }

    case GET_NOTIFICATIONS_ERROR: {
      const { payload } = action
      return state
        .set('notificationPhase', ERROR)      
    }

    case CLEAR_NOTIFICATION: {
      return state
        .set('clearNotificationPhase', LOADING) 
    }

    case CLEAR_NOTIFICATION_SUCCESS: {
      const { payload } = action
      return state
        .set('clearNotificationPhase', SUCCESS)
    }

    case CLEAR_NOTIFICATION_ERROR: {
      const { payload } = action
      return state
        .set('clearNotificationPhase', ERROR)      
    }

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/
 
//Set function as per the login api response
export const loginUser = (credentials) => {
  return {
    type: LOGIN_USER,
    payload: credentials
  }
}

//Set function as per the login api response
export const loginUserClient = (credentials) => {
  return {
    type: LOGIN_CLIENT_USER,
    payload: credentials
  }
}

export const otpVerification = (credentials) => {
  return {
    type: OTP_VERIFICATION,
    payload: credentials
  }
}

//Set function as per the login api response
export const clientValidateToken = (credentials) => {
  return {
    type: VALIDATE_TOKEN_CLIENT_USER,
    payload: credentials
  }
}

// Fetching user profile and data

export const fetchUser = (data) => ({
  type: FETCH_USER,
  payload: data
})

// Cancle fatch user 
export const cancelFetchUser = () => ({
  type: FETCH_USER_CANCELLED
})

// Updating profile 

export const updateProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: { data }
})
// Update Password
export const updatePassword = (data) => ({
  type: UPDATE_PASSWORD,
  payload: { data }
})

// Update Self profile
export const updateOwnProfile = (data) => ({
  type: UPDATE_OWN_PROFILE,
  payload: { data }
})

// Refreshing the token
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN
  }
}

// Saving the Google auth data

export const saveGoogleAuthdata = (data) => {
  return {
    type: SAVE_GOOGLE_DATA,
    payload: { data }
  } 
}

//Set function as per the create user api response
export const createUserCheck = (data) => ({
  type: CREATE_USER_CHECK,
  payload: { data }
})

//Set function as per the create AGENT api response
export const createAgent = (data) => ({
  type: CREATE_AGENT_CHECK,
  payload: { data }
})


//function to get notifications on top bar
export const getNotifications = (data) => ({
  type: GET_NOTIFICATIONS,
  payload: { data }
})

//function to clear notification from users records
export const clearNotification = (data) => ({
  type: CLEAR_NOTIFICATION,
  payload: { data }
})

export const handleSignOut = () => ({
  type: USER_SIGNOUT,
})


//Set function as per the create user api response
export const createUser = (data) => ({
  type: CREATE_USER,
  payload: { data }
})


//Set function as per the reset password link api response
export const resetPasswordLink = (credentials) => {
  return {
    type: RESET_PASSWORD_LINK,
    payload: credentials
  }
}

//Set function as per the reset password api response
export const resetPassword = (credentials) => {
  return {
    type: RESET_PASSWORD,
    payload: credentials
  }
}

//Set function as per the reset password wp api response
// export const resetPasswordWp = (credentials) => {
//   return {
//     type: RESET_PASSWORD_WP,
//     payload: credentials
//   }
// }

//Set function as per the clear phase request
export const clearPhase = () => {
  return {
    type: CLEAR_PHASES
  }
}

export const clearPhaseUser = () => {
  return {
    type: CLEAR_PHASES_USER
  }
}


/***********************************
 * Epics
 ***********************************/

/* 
Author: Amit Mishra
Descriptions: Function to use for call login api 
*/
const loginUserEpic = (action$) =>
  action$
  .ofType(LOGIN_USER)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.loginUser(action.payload))
    .flatMap((payload) => ([{
      type: LOGIN_USER_SUCCESS,
      payload
    },]))
    .catch((error) => Rx.Observable.of({
      type: LOGIN_USER_ERROR,
      payload: { error }
    }))    
  })

  //Function to use for call login api for client
/* 
Author: Amit Mishra
Descriptions: Function to use for call login api 
*/
const loginUserClientEpic = (action$) =>
action$
.ofType(LOGIN_CLIENT_USER)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.loginUserClient(action.payload))
  .flatMap((payload) => ([{
    type: LOGIN_CLIENT_USER_SUCCESS,
    payload
  },]))
  .catch((error) => Rx.Observable.of({
    type: LOGIN_CLIENT_USER_ERROR,
    payload: { error }
  }))    
})

/* 
Author: Amit Mishra
Descriptions: Function to use for call login api 
*/
  //Function to use for call validating client login magic link token
  const clientValidateTokenEpic = (action$) =>
  action$
  .ofType(VALIDATE_TOKEN_CLIENT_USER)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.clientValidateToken(action.payload))
    .flatMap((payload) => ([{
      type: VALIDATE_TOKEN_CLIENT_USER_SUCCESS,
      payload
    },]))
    .catch((error) => Rx.Observable.of({
      type: VALIDATE_TOKEN_CLIENT_USER_ERROR,
      payload: { error }
    }))    
  })

/* 
Author: Amit Mishra
Descriptions: Function to use for call login api 
*/
export const fetchUserEpic = (action$) =>
  action$
  .ofType(FETCH_USER)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchUser(action.payload))
    .map((payload) => ({ // payload = {token: String, user: UserModel}
      type: FETCH_USER_SUCCESS,
      payload
    }))
    .catch((error) => Rx.Observable.of({
      type: FETCH_USER_ERROR,
      payload: { error }
    }))
    .takeUntil(action$.ofType(FETCH_USER_CANCELLED))
  })

/* 
Author: Amit Mishra
Descriptions: Function to use for call login api 
*/
  export const updateProfileEpic = (action$) =>
  action$
  .ofType(UPDATE_PROFILE)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updateProfile(action.payload.data))
    .map((payload) => ({ // payload = {token: String, user: UserModel}
      type: UPDATE_PROFILE_SUCCESS,
      payload
    }))
    .catch((error) => Rx.Observable.of({
      type: UPDATE_PROFILE_ERROR,
      payload: { error }
    }))
    .takeUntil(action$.ofType())
  })

  /* 
Author: Amit Mishra
Descriptions: Function to use for call update password 
*/
  export const updatePasswordEpic = (action$) =>
  action$
  .ofType(UPDATE_PASSWORD)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updatePassword(action.payload.data))
    .map((payload) => ({ // payload = {token: String, user: UserModel}
      type: UPDATE_PASSWORD_SUCCESS,
      payload
    }))
    .catch((error) => Rx.Observable.of({
      type: UPDATE_PASSWORD_ERROR,
      payload: { error }
    }))
    .takeUntil(action$.ofType())
  })
  
/* 
Author: Amit Mishra
Descriptions: Function to use for call updating profile self
*/
  export const updateOwnProfileEpic = (action$) =>
  action$
  .ofType(UPDATE_OWN_PROFILE)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.updateOwnProfile(action.payload.data))
    .map((payload) => ({ // payload = {token: String, user: UserModel}
      type: UPDATE_OWN_PROFILE_SUCCESS,
      payload
    }))
    .catch((error) => Rx.Observable.of({
      type: UPDATE_OWN_PROFILE_ERROR,
      payload: { error }
    }))
    .takeUntil(action$.ofType())
  })

 /* 
Author: Amit Mishra
Descriptions: Function to use for call saving Google auth
*/ 
  export const saveGoogleAuthdataEpic = (action$) =>
  action$
  .ofType(SAVE_GOOGLE_DATA)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.saveGoogleAuthdata(action.payload.data))
    .map((payload) => ({ // payload = {token: String, user: UserModel}
      type: SAVE_GOOGLE_DATA_SUCCESS,
      payload
    }))
    .catch((error) => Rx.Observable.of({
      type: SAVE_GOOGLE_DATA_ERROR,
      payload: { error }
    }))
  }) 
/* 
Author: Amit Mishra
Descriptions: Function to use for call Refresh token
*/
  const refreshTokenEpic = (action$) =>
    action$
    .ofType(REFRESH_TOKEN)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.refreshToken())
      .flatMap((payload) => ([{
        type: REFRESH_TOKEN_SUCCESS,
        payload
      }]))
      .catch((error) => Rx.Observable.of({
        type: REFRESH_TOKEN_ERROR,
        payload: { error }
      }))
  })


//Function to use for call login api
// const loginUserWpEpic = (action$) =>
//   action$
//   .ofType(LOGIN_USER_WP)
//   .mergeMap((action) => {
//     return Rx.Observable.fromPromise(api.loginUserWp(action.payload))
//     .flatMap((payload) => ([{
//       type: LOGIN_USER_WP_SUCCESS,
//       payload
//     }]))
//     .catch((error) => Rx.Observable.of({
//       type: LOGIN_USER_WP_ERROR,
//       payload: { error }
//     }))    
//   })

 //Function to use for call WP signup api
// const signupUserWpEpic = (action$) =>
//   action$
//   .ofType(SIGNUP_USER_WP)
//   .mergeMap((action) => {
//     return Rx.Observable.fromPromise(api.signupUserWp(action.payload))
//     .flatMap((payload) => ([{
//       type: SIGNUP_USER_WP_SUCCESS,
//       payload
//     }]))
//     .catch((error) => Rx.Observable.of({
//       type: SIGNUP_USER_WP_ERROR,
//       payload: { error }
//     }))    
//   }) 

//Function to use for call create user api
// const createUserEpic = (action$) =>
//   action$
//   .ofType(CREATE_USER)
//   .mergeMap((action) => {
//     return Rx.Observable.fromPromise(api.createUser(action.payload.data))
//     .flatMap((payload) => ([{
//       type: CREATE_USER_SUCCESS,
//       payload
//     }]))
//     .catch((error) => Rx.Observable.of({
//       type: CREATE_USER_ERROR,
//       payload: { error }
//     }))
//   })

/* 
Author: Amit Mishra
Descriptions: Function to use for call create check user api 
*/
const createUserCheckEpic = (action$) =>
  action$
  .ofType(CREATE_USER_CHECK)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createUserCheck(action.payload.data))
    .flatMap((payload) => ([{
      type: CREATE_USER_CHECK_SUCCESS,
      payload
    }
    ]))
    .catch((error) => Rx.Observable.of({
      type: CREATE_USER_CHECK_ERROR,
      payload: { error }
    }))
  })

/* 
Author: Amit Mishra
Descriptions: Function to use for call create check Agent api 
*/
const createAgentEpic = (action$) =>
  action$
  .ofType(CREATE_AGENT_CHECK)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createAgent(action.payload.data))
    .flatMap((payload) => ([{
      type: CREATE_AGENT_CHECK_SUCCESS,
      payload
    }
    ]))
    .catch((error) => Rx.Observable.of({
      type: CREATE_AGENT_CHECK_ERROR,
      payload: { error }
    }))
  })


/* 
Author: Amit Mishra
Descriptions: Function to use for call reset password link api 
*/
const resetPasswordLinkEpic = (action$) =>
  action$
  .ofType(RESET_PASSWORD_LINK)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.resetPasswordLink(action.payload))
    .flatMap((payload) => ([{
      type: RESET_PASSWORD_LINK_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: RESET_PASSWORD_LINK_ERROR,
      payload: { error }
    }))    
  })


/* 
Author: Amit Mishra
Descriptions: Function to use for call reset password api
*/
const resetPasswordEpic = (action$) =>
  action$
  .ofType(RESET_PASSWORD)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.resetPassword(action.payload))
    .flatMap((payload) => ([{
      type: RESET_PASSWORD_SUCCESS,
      payload
    },
    {
      type: RESET_PASSWORD_WP,
      payload:payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: RESET_PASSWORD_ERROR,
      payload: { error }
    }))    
  })

//Function to use for call reset password wp api
// const resetPasswordWpEpic = (action$) =>
//   action$
//   .ofType(RESET_PASSWORD_WP)
//   .mergeMap((action) => {
//     return Rx.Observable.fromPromise(api.resetPasswordWp(action.payload))
//     .flatMap((payload) => ([{
//       type: RESET_PASSWORD_WP_SUCCESS,
//       payload
//     }]))
//     .catch((error) => Rx.Observable.of({
//       type: RESET_PASSWORD_WP_ERROR,
//       payload: { error }
//     }))    
//   })

/* 
Author: Amit Mishra
Descriptions: Function to use for getting notifications 
*/
const getNotificationsEpic = (action$) =>
  action$
  .ofType(GET_NOTIFICATIONS)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getNotifications(action.payload.data))
    .flatMap((payload) => ([{
      type: GET_NOTIFICATIONS_SUCCESS,
      payload
    },]))
    .catch((error) => Rx.Observable.of({
      type: GET_NOTIFICATIONS_ERROR,
      payload: { error }
    }))    
  })

/* 
Author: Amit Mishra
Descriptions: Function to use for Clearing notifications 
*/
  const clearNotificationEpic = (action$) =>
  action$
  .ofType(CLEAR_NOTIFICATION)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.clearNotification(action.payload.data))
    .flatMap((payload) => ([{
      type: CLEAR_NOTIFICATION_SUCCESS,
      payload
    },]))
    .catch((error) => Rx.Observable.of({
      type: CLEAR_NOTIFICATION_ERROR,
      payload: { error }
    }))    
  })

  const otpVerificationEpic = (action$) =>
  action$
  .ofType(OTP_VERIFICATION)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.otpVerification(action.payload))
    .flatMap((payload) => ([{
      type: OTP_VERIFICATION_SUCCESS,
      payload
    },]))
    .catch((error) => Rx.Observable.of({
      type: OTP_VERIFICATION_ERROR,
      payload: { error }
    }))    
  })



export const userEpic = combineEpics(
  loginUserEpic,
  //loginUserWpEpic,
  //signupUserWpEpic,
  //createUserEpic,
  loginUserClientEpic,
  createUserCheckEpic,
  createAgentEpic,
  clientValidateTokenEpic,
  fetchUserEpic,
  updateProfileEpic,
  saveGoogleAuthdataEpic,
  resetPasswordLinkEpic,
  resetPasswordEpic,
  refreshTokenEpic,
  updatePasswordEpic,
  getNotificationsEpic,
  clearNotificationEpic,
  updateOwnProfileEpic,
  otpVerificationEpic,
  //resetPasswordWpEpic
)
