export const FETCH_PROSPECTUS = 'suburban/strategistV2/prospectus/FETCH_PROSPECTUS'
export const FETCH_PROSPECTUS_SUCCESS = 'suburban/strategistV2/prospectus/FETCH_PROSPECTUS_SUCCESS'
export const FETCH_PROSPECTUS_ERROR = 'suburban/strategistV2/prospectus/FETCH_PROSPECTUS_ERROR'

export const CREATE_PROSPECTUS = 'suburban/strategistV2/prospectus/CREATE_PROSPECTUS'
export const CREATE_PROSPECTUS_SUCCESS =
  'suburban/strategistV2/prospectus/CREATE_PROSPECTUS_SUCCESS'
export const CREATE_PROSPECTUS_ERROR = 'suburban/strategistV2/prospectus/CREATE_PROSPECTUS_ERROR'

export const FETCH_PROSPECTUS_BY_ID = 'suburban/strategistV2/prospectus/FETCH_PROSPECTUS_BY_ID'
export const FETCH_PROSPECTUS_BY_ID_SUCCESS =
  'suburban/strategistV2/prospectus/FETCH_PROSPECTUS_BY_ID_SUCCESS'
export const FETCH_PROSPECTUS_BY_ID_ERROR =
  'suburban/strategistV2/prospectus/FETCH_PROSPECTUS_BY_ID_ERROR'

export const CLEAR_PROSPECTUS_PHASE = 'suburban/strategistV2/prospectus/CLEAR_PROSPECTUS_PHASE'