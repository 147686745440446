import { connect } from 'react-redux'
import StrategistStatisticsForm from './component'

import { getFilterParams } from '../../../store/dashboard/duck'
import { getStrategistList, getStrategistStatistics } from '../../../store/analytics/duck'

const StrategistStatistics = connect(
  // Map state to props
  (state) => ({
  	strategistPhase: state.analytics.strategistPhase,
  	strategyData: state.analytics.strategyData,
  	filterParams: state.dashboard.filterParams,
    strategistStatisticsPhase: state.analytics.strategistStatisticsPhase,
    strategistStatisticsData: state.analytics.strategistStatisticsData
  }),
  // Map actions to props
  {
  	getStrategistList,
  	getFilterParams,
    getStrategistStatistics
  }
)(StrategistStatisticsForm)
export default StrategistStatistics
