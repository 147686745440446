import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import ShowMore from "react-show-more";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Modal from 'react-responsive-modal';
import Modal2 from 'react-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button className="form-control custom-select date__picker" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class NoInitalTourComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment('2019-03-27'),
      endDate: moment(),
      town_list: [],
      suburb_list: [],
      townDropdown: false,
      search: "",
      open: false,
      currentAgentTowns: [],
      agentName: '',
      userRole: '',
      selectedDefault:true,
      tagKey:'',
      exportData:[]
    };
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      const data = {}
      data.user_id = _user.id
      const agentData = {}
      if(this.state.selectedDefault){
        agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'total'
      }else{
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
      }
      agentData.town_arr = this.state.town_list
      this.props.getNoInitialTour(agentData)
      this.setState({ isLoadingData: true, userRole: _user.role })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialTourPhase === "success") {
      let exportData = []
      if(nextProps.initialTourData && nextProps.initialTourData.exportData && nextProps.initialTourData.exportData.length > 0){
        nextProps.initialTourData.exportData.map(function(data){
          let exportObj = {}
          exportObj.agent_full_name = data.agent_full_name
          exportObj.totalClients = data.clients.length
          exportObj.clients = data.clients.toString()
          exportObj.status = data.status.toString()
          exportObj.strategists = data.strategists.toString()
          exportData.push(exportObj)
        })
        this.setState({ isLoadingData: false, exportData:exportData },() => {})
      }else{
        this.setState({ isLoadingData: false, exportData:exportData },() => {})
      }
    }
  }

  handleFromDate(date) {
    this.setState({ isLoadingData: true })
    let selectDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(
      function(e) {
        const agentData = {}
        if(this.state.selectedDefault){
          agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'total'
        }else{
          agentData.from = selectDate
          agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'any'
        }
        agentData.town_arr = this.state.town_list
        this.props.getNoInitialTour(agentData)
      }.bind(this),
      300
    );
    this.setState({ fromDate: date });
  }

  handleEndDate(date) {
    this.setState({ isLoadingData: true })
    let selectDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(
      function(e) {
        const agentData = {};
        if(this.state.selectedDefault){
          agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'total'
        }else{
          agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.to = selectDate
          agentData.selectedValue = 'any'
        }
        agentData.town_arr = this.state.town_list
        this.props.getNoInitialTour(agentData)
      }.bind(this),
      300
    );
    this.setState({ endDate: date })
  }

  toggledropDown(val) {
    if (val === "filterTowns") {
      this.setState({ townDropdown: !this.state.townDropdown });
    }
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  clearAllFilter(e){
    this.setState({ isLoadingData: true, town_list : [] })
    setTimeout(
      function(e) {
        const agentData = {}
        if(this.state.selectedDefault){
          agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'total'
        }else{
          agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'any'
        }
        agentData.town_arr = this.state.town_list
        this.props.getNoInitialTour(agentData)
      }.bind(this),
      300
    );
  }
 
  onCloseModal() {
    this.setState({ agentName: '', currentAgentTowns: [], open: false })
  }

  filterDefaultDate(selectedDefault){
    const agentData = {}
    this.setState({ isLoadingData: true },() => {})
    if(selectedDefault){
      agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'total'
    }else{
      agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'any'
    }
    agentData.town_arr = this.state.town_list
    this.props.getNoInitialTour(agentData)
    this.setState({ selectedDefault: selectedDefault },() => {})
  }

  onOpenModal(agent,key) {
    let tags = []
    if(key == 'client'){
     tags = agent && agent.clients && agent.clients.indexOf(',') !== -1 ? agent.clients.split(',') : [agent.clients]
    }
    if(key == 'status'){
      tags = agent && agent.status && agent.status.indexOf(',') !== -1 ? agent.status.split(',') : [agent.status]
    }
    if(key == 'strategist'){
      tags = agent && agent.strategists && agent.strategists.indexOf(',') !== -1 ? agent.strategists.split(',') : [agent.strategists]
    }
    if(tags[0].length > 1){
      this.setState({ agentName: agent.agent_full_name, open: true, currentAgentTags: tags[0], key:key })
    }
  }

  render() {
    const { open, exportData } = this.state;
    const { agentStatisticsData, filterParams, initialTourData  } = this.props;
    let allData = filterParams && filterParams && filterParams.data;
    let filterList = agentStatisticsData;
    let _user = cookies.get("user");
    let allTownData = filterParams && filterParams.data && filterParams.data.towns;
    if(allTownData){
      filterList = allTownData
      .filter(
        (allTownData) =>{
          return (
            (allTownData.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }
    return (
      <div>

        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>No Initial or Follow up agent visit</a>
                </div>
                <div className="close__btn">
                {
                    _user.role == 'strategist' ? (<Link to={"/strategist-dashboard"}>
                    <img src="img/close.svg" />
                  </Link>) : (<Link to={"/dashboard"}>
                    <img src="img/close.svg" />
                  </Link>)
                  }
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{initialTourData && initialTourData.data && initialTourData.data.length}</span> Agents
              </h4>
            </div>
            <div className="col-md-12">
            <div className="reports__flex">
            
            <div className="right__cont right__cont--full">
              {/*<span className="defaultCheckboxContainer">
                <label className="defaultChecboxCustom">
                <input type="checkbox" checked = {this.state.selectedDefault} className="custom-select-option-checkbox" onChange={this.filterDefaultDate.bind(this,!this.state.selectedDefault)}/>
                 <span>
                Show all results
                </span>
                </label>
              </span> 
              
              <div className={this.state.selectedDefault ? "dp-wrapper dp-wrapper-edit filterHideOverride" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment('2019-03-27')}
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        disabled={this.state.selectedDefault}
                        className="form-control custom-select date__picker"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={this.state.selectedDefault ? "dp-wrapper dp-wrapper-edit filterHideOverride" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        disabled={this.state.selectedDefault}
                        className="form-control custom-select date__picker"
                      />
                    </div>
                  </div>
                </div>
              </div>*/}

             <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={exportData} name="No_Initital_Tour_Report">
                  <ExcelColumn label="Agent Name" value="agent_full_name" />
                  <ExcelColumn label="Clients" value="clients" />
                  <ExcelColumn label="Status" value="status" />
                  <ExcelColumn label="Strategists" value="strategists" />
                  <ExcelColumn label="Number Of Clients " value="totalClients" />
                </ExcelSheet>
              </ExcelFile>
            </div>
            </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">
                    <div className="jungler__list useslist__extra">
                      <div className="table-responsive">
                        {this.state.isLoadingData === true ? (
                          <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td className="noClientResults text-left">
                                <img src="../../img/loader2.svg" />
                                <h5>Your No Initial or Follow up agent visit report is generating, please wait.........</h5>
                              </td>
                            </tr>
                          </tbody>
                          </table>
                          ) : (
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Agent Name</th>
                                <th className="text-left">Client Name</th>
                                <th className="text-left">Pipeline Status</th>
                                <th className="text-left">Strategist Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {initialTourData &&
                                initialTourData.data.length > 0 && initialTourData.data.map((agent, index) => {

                                  const totalClient = agent && agent.clients
                                  const status = agent && agent.status
                                  const totalStrategist = agent && agent.strategists
                                  let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                                  let statusNo = (status.length-1) <= 0 ? '' : " (+"+(status.length-1)+")"
                                  let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                                  return (
                                    <tr key={index} className="forcursor">
                                      <td>{agent.agent_full_name}</td>
                                      <td className="wrap-text multilines"><div className={(totalClient.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, agent,'client')}>{(totalClient.length >= 1 ? totalClient[0]+clientNo : 'No Clients')}</div></td>

                                      <td className="wrap-text multilines"><div className={(status.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, agent,'status')}>{(status.length >= 1 ? status[0]+statusNo : 'No Status')}</div></td>
                                      <td className="wrap-text multilines"><div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, agent,'strategist')}>{(totalStrategist.length >= 1 ? totalStrategist[0]+strategistNo : 'No Strategist')}</div></td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                            </table>
                          )}
                      </div>
                    </div>
              </main>
              {<div>
                <Modal2 isOpen={open} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' '+this.state.key+'(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                        return <div className="allpoppadding__paranew" key={'tag-name-'+index}>{tag}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseModal.bind(this)}>Close</button>
                </Modal2>
              </div>}
              {
                initialTourData && initialTourData.data && initialTourData.data.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - No Initial or Follow up Agent Town Tour</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "agentstatistics", // a unique identifier for this form
  destroyOnUnmount: true
})(NoInitalTourComponent);
