import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import moment from 'moment'
import Config from '../../../../../../config';
import _ from 'lodash'
import SideNavigation from '../../../../../../components/SidebarNavigation/component'
import ReactModal from 'react-modal'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import { map } from 'lodash'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''
let columnDataStrategist = []
let columnDataAdmin = []

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const firstday = moment().startOf('week').toDate()

export default class BDSourceReportComponent extends PureComponent {
  constructor(props) {

    columnDataAdmin = [
      {
        title: 'BD Source', //summaryData
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => a && a.bd_source && a.bd_source.length !== 0 ? a.bd_source[0].full_name.localeCompare(b.bd_source[0].full_name) : 0,
        children: [
          {
            title: '',
            dataIndex: 'bd_source',
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {
                    (_.size(data.bd_source) > 0) && data.bd_source.map((val, i) => {
                      return (
                        <React.Fragment key={i}>{val.full_name}<br /></React.Fragment>
                      )
                    })
                  }
                </React.Fragment>
              )
            }
          },
        ],
      },
      {
        title: 'Client Name',
        key: '2',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name),
        children: [
          {
            title: '',
            width: 270,
            dataIndex: 'client_full_name',
          },
        ],
      },
      {
        title: 'Client Email',
        key: '3',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => a.client_email.localeCompare(b.client_email),
        children: [
          {
            title: '',
            dataIndex: 'client_email',
            width: 280,
          },
        ],
      },
      {
        title: 'Assigned Strategist',
        key: '4',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => a.strategist_full_name.localeCompare(b.strategist_full_name),
        children: [
          {
            title: '',
            dataIndex: 'strategists',
            width: 270,
            render: (text, data) => {
              return (
                <React.Fragment>
                  {
                    (_.size(data.strategists) > 0) && data.strategists.map((val, i) => {
                      return (
                        <React.Fragment key={i}>{val.strategist_full_name}<br /></React.Fragment>
                      )
                    })
                  }
                </React.Fragment>
              )
            }
          },
        ],
      },
      {
        title: 'Pipeline Stage',
        key: '5',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => a.status.localeCompare(b.status),
        children: [
          {
            title: '',
            dataIndex: 'status',
            width: 290,
          },
        ],
      },
      {
        title: 'Date of Signed Contract',
        width: 500,
        dataIndex: 'date_of_signed_contract',
        key: '12',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => new Date(a.date_of_signed_contract) - new Date(b.date_of_signed_contract),
        children: [
          {
            title: '',
            dataIndex: 'date_of_signed_contract',
            width: 250,
            render: (text, data) => {
              return (
                <span>{(!(_.isEmpty(data.date_of_signed_contract)) && data.date_of_signed_contract !== "N/A") ? moment(data.date_of_signed_contract).format('MM/DD/YYYY') : ''}</span>
              )
            }
          },
        ],
      },
      {
        title: 'Closing Date',
        key: '6',
        dataIndex: 'closed_date',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => new Date(a.closed_date) - new Date(b.closed_date),
        children: [
          {
            title: '',
            dataIndex: 'closed_date',
            width: 250,
            render: (text, data) => {
              return (
                <span>{(!(_.isEmpty(data.closed_date)) && data.closed_date !== "N/A") ? moment(data.closed_date).format('MM/DD/YYYY') : ''}</span>
              )
            }
          },
        ],
      },
      {
        title: 'Sales Price',
        width: 500,
        dataIndex: 'sales_price',
        key: '13',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.sales_price - b.sales_price,
        children: [
          {
            title: '',
            dataIndex: 'sales_price',
            width: 250,
            render: (text, data) => {
              return (
                <span>{!(_.isUndefined(data.sales_price)) ? formatter.format(data.sales_price) : ''}</span>
              )
            }
          },
        ],
      },
      {
        title: 'Client Created Date',
        key: '7',
        dataIndex: 'created',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => new Date(a.created) - new Date(b.created),
        children: [
          {
            title: '',
            dataIndex: 'created',
            width: 250,
            render: (text, data) => {
              return(
                <span>{(!(_.isEmpty(data.created)) && data.created !== "N/A") ? moment(data.created).format('MM/DD/YYYY') : ''}</span>
              )
            }
          },
        ],
      },
      {
        title: 'Price Range',
        key: '8',
        sortDirections: ['descend', 'ascend', 'descend'],
        showSorterTooltip: false,
        sorter: (a, b) => {
          let sort = (a.price_range && b.price_range && (parseInt(a.price_range.split("-")[1]) - parseInt(a.price_range.split("-")[0])) - (parseInt(b.price_range.split("-")[1]) - (parseInt(b.price_range.split("-")[0]))))
          return sort;
        },
        children: [
          {
            title: '',
            dataIndex: 'price_range',
            width: 300,
            render: (text, data) => {
              return (
                <div>{
                  !isNaN(data.price_range && (parseInt(data.price_range.split('-')[0]) - parseInt(data.price_range.split('-')[1]))) ?
                    <span>{!(_.isEmpty(formatter.format(parseInt(data.price_range.split('-')[0])) + '-' + formatter.format(parseInt(data.price_range.split('-')[1])))) ? formatter.format(parseInt(data.price_range.split('-')[0])) + '-' + formatter.format(parseInt(data.price_range.split('-')[1])) : ''}</span> :
                    <span>$0.00</span>
                }
                </div>
              )
            }
          },
        ],
      },
      {
        title: 'Region',
        width: 250,
        dataIndex: 'region',
        key: '10',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.region.localeCompare(b.region),
        children: [
          {
            title: '',
            dataIndex: 'region',
            width: 250,
          },
        ],
      },
      {
        title: 'Source',
        width: 250,
        dataIndex: 'source',
        key: '9',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.source.localeCompare(b.source),
        children: [
          {
            title: '',
            dataIndex: 'source',
            width: 250,
          },
        ],
      },
      {
        title: 'Ad Tracking Codes',
        width: 500,
        dataIndex: 'ad_tracking_codes',
        key: '11',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes),
        children: [
          {
            title: '',
            dataIndex: 'ad_tracking_codes',
            width: 250,
          },
        ],
      },

      {
        title: 'BD Paid',
        width: 500,
        dataIndex: 'development_fee',
        key: '14',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.development_fee - b.development_fee,
        children: [
          {
            title: '',
            dataIndex: 'development_fee',
            width: 250,
          },
        ]
      },
      {
        title: 'Referring Client',
        width: 500,
        dataIndex: 'referal_client',
        key: '14',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => a.referal_client.localeCompare(b.referal_client),
        children: [
          {
            title: '',
            dataIndex: 'referal_client',
            width: 250,
          },
        ],
      }
    ]

    super(props)
    this.state = {
      selectedIndex: 'all',
      type: 'assignToAgent',
      previousStartDate: moment().add(-1, 'month').startOf('month'),
      previousEndDate: moment(),
      sortByKey: '',
      sortByType: 0,
      strategist_id: 0,
      fromDate: moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      todayDate: moment().format('LL'),
      weekFirstDate: moment(firstday).format('LL'),
      openCheck: false,
      deal_check_client: {},
      current_client: '',
      bdSourceReportData: [],
      bdSourceReportExportData: [],
      bdSources: [],
      bd_source_id: 0,
      showAll: true,
      searchBaseName: ''
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    if (_user && _user.role === "agent" || _user && _user.role === "local") {
      this.props.history.push({ pathname: '/agent/dashboard' })
    }
    if (_user && _user.role === "client") {
      this.props.history.push({ pathname: '/clients' })
    }
    this.setState({ isLoading: true })
    this.props.getFilterParams()
    this.props.getDevelopmentMembers()
    // this.strategistDataLoad()
    const strData = {}
    if (this.state.selectedIndex === "all") {
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    strData.type = this.state.type
    strData.strategistId = 0 //first time strategist id=0 means all strategist
    strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType }
    strData.bd_source_id = this.state.bd_source_id;
    // this.props.getAccountingReportData(strData)
    this.props.getBDSourceReportData(strData)
  }

  componentDidUpdate(preProps, preState) {
    const { clearStrategistPhase, bdSourceReportPhase, bdSourceReportData } = this.props
    if (bdSourceReportPhase === "success") {
      clearStrategistPhase()
      let bdSourceData = []
      let bdData = []
      let bdExportData = []
      let bdSources = []
      const bdSource = bdSourceReportData && bdSourceReportData.data
      if (_.size(bdSource) > 0) {
        bdSource && bdSource.map((data, i) => {
          bdData.push({
            key: i,
            client_full_name: data.client_full_name,
            strategist_full_name: data.strategists && data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name }).join(", ") : "",
            closed_date: (data.closed_date !== null && data.closed_date !== '') ? data.closed_date : "N/A",
            client_id: data.client_id,
            bd_source: data.bd_source,
            date_of_signed_contract: (data.date_of_signed_contract !== null && data.date_of_signed_contract !== '') ? data.date_of_signed_contract : "N/A",
            deal_id: data.deal_id,
            sales_price: data.sales_price ? data.sales_price : 0,
            development_fee: data.development_fee ? data.development_fee : 0,
            strategists: data.strategists,
            status: data.client_status,
            deal: data.deal,
            strategistStatus: "",
            source: data.source ? data.source : '',
            ad_tracking_codes: data.ad_tracking_codes ? data.ad_tracking_codes : '',
            booked_how: data.booked_how ? data.booked_how :  'Created in Jungler',
            created: (data.created !== null && data.created !== '') ? data.created : "N/A",
            region: data.region,
            price_range: data.price_range === '0 - 0' ? '0' : data.price_range,
            client_email: data.client_email,
            referal_client: data.refer_clients && data.refer_clients.length !== 0 ? data.refer_clients[0].refer_name : ''
          })
          { data.bd_source ? bdSources.push(data.bd_source[0]) : null }
        })

        bdSource && bdSource.map((data, i) => {
          bdExportData.push({
            bd_source: data.bd_source && data.bd_source.length > 0 ? data.bd_source.map(e => { return e.full_name }).join(", ") : "",
            client_full_name: data.client_full_name,
            client_email: data.client_email,
            strategists: data.strategists && data.strategists.length > 0 ? data.strategists.map(e => { return e.strategist_full_name }).join(", ") : "",
            closed_date: (data.closed_date !== null && data.closed_date !== '') ? data.closed_date : "N/A",
            status: data.client_status,
            source: data.source ? data.source : '',
            created: (data.created !== null && data.created !== '') ? data.created : "N/A",
            region: data.region,
            price_range: data.price_range === '0 - 0' ? '0' : data.price_range,
            ad_tracking_codes: data.ad_tracking_codes ? data.ad_tracking_codes : '',
          })
        })
      }

      this.setState({ bdSourceReportData: bdData, isLoading: false, bdSources: bdSources, bdSourceReportExportData: bdExportData })
    }
    if (bdSourceReportPhase === "error") {
      clearStrategistPhase()
      this.setState({ bdSourceReportData: [], isLoading: false, bdSourceReportExportData: [] })
    }
  }

  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index) => {
      if (val.id === parseInt(event.target.value)) {
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoading: true }, () => {
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      strData.bd_source_id = this.state.bd_source_id
      strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType }
      this.props.getBDSourceReportData(strData)
    })
  }

  handleBdSources(event) {
    let bdSourceList = this.state.bdSources;
    bdSourceList && bdSourceList.map((val, index) => {
      if (val.client_id === parseInt(event.target.value)) {


      }
    })
    this.setState({ bd_source_id: event.target.value, isLoading: true }, () => {
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      strData.bd_source_id = this.state.bd_source_id
      strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType }
      this.props.getBDSourceReportData(strData)


    })

  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      type: this.state.type,
      strategistId: this.state.strategist_id,
      bd_source_id: this.state.bd_source_id,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType }
    }
    this.props.getBDSourceReportData(strData)
  }

  onSearch(e) {
    this.setState({ searchBaseName: e.target.value })
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate, bdSourceReportData, bdSources, bdSourceReportExportData } = this.state
    const { filterParams, developmentMembers } = this.props
    let filterList = bdSourceReportData
    if (_.size(bdSourceReportData) > 0) {
      filterList = bdSourceReportData.filter(
        (val) => {
          return (
            (val.client_full_name.toLowerCase().indexOf(this.state.searchBaseName.toLowerCase())) !== -1
          );
        }
      );
    }
    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if (val.role === 'admin' || val.role === 'strategist') {
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });

    if (developmentMembers && developmentMembers.data && developmentMembers.data.length > 0) {
      let a = developmentMembers['data'].sort((a, b) => (a.full_name > b.full_name) ? 1 : -1)
      developmentMembers['data'] = a;
    }

    let developmentMembersList = developmentMembers && developmentMembers.data && developmentMembers.data.length > 0 && developmentMembers.data.map((val, index) => {
      return (
        <option key={index} value={val.client_id}>{val.full_name}</option>
      )

    })
    // let bdSourceList = bdSources.map((val, index) => {
    //   if(val.role === 'admin' || val.role === 'strategist'){
    //     return (
    //       <option key={index} value={val.id}>{val.full_name}</option>
    //     )
    //   }
    // });

    return (
      <div>
        <div className="layout-has-sidebar">
          {
            (_user && (_user.role) === "business development")?
            <SideNavigation {...this.props}/>:
            <AdministrationSidebarNavigation {...this.props}/>
          }

          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    {
                      (_user && (_user.role) === "business development")?
                      <button onClick={() => this.props.history.push(`/agent/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                      :
                      <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                    }
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Biz Dev Tracking Report</h2>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                      <ExcelSheet data={bdSourceReportExportData} name="biz_dev_tracking_report">
                        <ExcelColumn label="BD Source" value="bd_source" />
                        <ExcelColumn label="Client Name" value="client_full_name" />
                        <ExcelColumn label="Client Email" value="client_email" />
                        <ExcelColumn label="Strategists" value="strategists" />
                        <ExcelColumn label="Closing date" value="closed_date" />
                        <ExcelColumn label="Pipeline Stage" value="status" />
                        <ExcelColumn label="Source" value="source" />
                        <ExcelColumn label="Client Created Date" value="created" />
                        <ExcelColumn label="Region" value="region" />
                        <ExcelColumn label="Price Range" value="price_range" />
                        <ExcelColumn label="Ad Tracking Code" value="ad_tracking_codes" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  {
                    (_user && _user.role !== "strategist") &&
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom__select material-textfield-input"
                        name="stategist_id"
                        value={this.state.strategist_id}
                        onChange={this.handleChange.bind(this)}>
                        <option value={0}>All</option>
                        {strategistList}
                    </select>
                    <label className="material-textfield-label">Strategist</label>
                  </div>
                  }
                  {
                    (_user && (_user.role) !== "business development") &&
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom__select material-textfield-input"
                        name="bd_source_id"
                        value={this.state.bd_source_id}
                        onChange={this.handleBdSources.bind(this)}>
                        <option value={0}>All</option>
                        {developmentMembersList}
                      </select>
                      <label className="material-textfield-label">BD Source</label>
                    </div>
                  }
                </div>
                <div className="col-md-4 col-md-offset-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={(event) => this.onSearch(event)}
                      placeholder="Search for a client by name..."
                      required
                    />
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(filterList) > 0) ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Table
                            className="table__fixed"
                            sortOrde={true}
                            columns={columnDataAdmin}
                            dataSource={filterList}
                            pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions }}
                            scroll={{ y: 420 }}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

