import * as types from './action-types'

// fetch prospectus list
export const fetchProspectus = (payload) => ({
  type: types.FETCH_PROSPECTUS,
  payload,
})
// fetch prospectus details by id
export const fetchProspectusById = (payload) => ({
  type: types.FETCH_PROSPECTUS_BY_ID,
  payload,
})
// create new prospectus
export const createProspectus = (payload) => ({
  type: types.CREATE_PROSPECTUS,
  payload,
})
// clear prospectus phase
export const clearProspectusPhase = (payload) => ({
  type: types.CLEAR_PROSPECTUS_PHASE,
  payload,
})
