import { connect } from 'react-redux'

import AgentStatisticsComponent from './component'

import {
  getFilterParams,
  clearDashboardPhase,
} from '../../../../../../store/strategistV2/dashboard/actions'
import { getAgentStatistics, clearPhase } from '../../../../../../store/analytics/duck'

const AgentStatisticsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    filterParamsPhase: state.strategistV2.dashboard.filterParamsPhase,
    agentStatisticsPhase: state.analytics.agentStatisticsPhase,
    agentStatisticsData: state.analytics.agentStatisticsData,
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    getAgentStatistics,
    clearPhase,
    clearFilterPhase: clearDashboardPhase,
  }
)(AgentStatisticsComponent)

export default AgentStatisticsContainer
