import React from 'react'
export default class Loader extends React.Component {
    render() {
        return (
            <div className="ant-spin-spinning">
              <div className="custon__spinner"></div>
                {/* <img src="../../img/loader2.svg" /> */}
            </div>
        );
    }
}
