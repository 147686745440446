import { connect } from 'react-redux'
import {
  impersonateUser,
  getTowns,
  deleteClient,
  getTownsOptionBySuburbs,
  getUSerProfileByID,
  getUserProfileByIDEditProfile,
  clearPhase,
} from '../../../../store/dashboard/duck'
import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import {
  clearJunglerUserPhase,
  addJunglerUser,
  createJunglerUser
} from '../../../../store/strategistV2/jungleruser/actions'

import AddUserComponent from './component'

const AddUserContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    townData: state.dashboard.townData,
    townPhase: state.dashboard.townPhase,
    userDataMain: state.dashboard.userData,
    junglerUserPhase: state.dashboard.junglerUserPhase,
    createJunglerUserPhase: state.strategistV2.junglerUser.createJunglerUserPhase,
    deleteClientdata: state.dashboard.deleteClientdata,
    dataPhase: state.dashboard.dataPhase,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    junglerUserPhaseData: state.strategistV2.junglerUser.junglerUserPhaseData
  }),
  // Map actions to dispatch and props
  {
    getTowns,
    clearPhase,
    deleteClient,
    getTownsOptionBySuburbs,
    addJunglerUser,
    clearJunglerUserPhase,
    getFilterParams,
    createJunglerUser,
    getUSerProfileByID,
    impersonateUser,
    getUserProfileByIDEditProfile
  }
)(AddUserComponent)

export default AddUserContainer
