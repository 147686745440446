import React, { PureComponent /*, PropTypes*/ } from 'react';
import ReactModal from 'react-modal';
import { Drawer, notification } from 'antd';
import ConfirmModal from '../../../../Common/ConfirmModal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  CloseIcon,
  SortUpIcon,
  SortDownIcon,
  DeleteIcon
} from '../../../../../../../components/icons';
import './styles.scss';
import { get, orderBy, isEmpty, filter } from 'lodash';
import axios from 'axios';
import SchoolPrivateTableData from './partials/SchoolPrivateTableData';
import { logoutInvalidRequest } from '../../../../../../../utils/helper';
import { fetch } from '../../../../../../../utils';
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export default class PublicSchoolsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      addPublicSchoolModal: false,
      privateAttomSchools: [],
      privateAttomSchoolsMain: [],
      privateData: [],
      selectedSchool: {
        InstitutionName: '',
        gradespancodebldgtext: '',
        GSTestRating: '',
        studentteacher: '',
        studentsnumberof: null
      },
      selectedSchoolInitial: {
        InstitutionName: '',
        gradespancodebldgtext: '',
        GSTestRating: '',
        studentteacher: '',
        studentsnumberof: null
      },
      editMode: false
    };
    this.openAddPublicSchoolModal = this.openAddPublicSchoolModal.bind(this);
  }

  componentDidMount() {
    this.props.toggleLoading(true);
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    this.props.getAttomSchools({ townId: parseInt(townId), type: 'private' });
    this.props.fetchSchools({ townId: parseInt(townId), type: 'private' });
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { townId }
      }
    } = props;
    const obj = {};

    if (get(props, 'editPrivatePhase', false) === 'success') {
      props.clearTownPhase();
      obj.addPublicSchoolModal = false;
      obj.editMode = false;
      obj.selectedSchool = state.selectedSchoolInitial;
      props.fetchSchools({ townId: parseInt(townId), type: 'private' });
    }

    if (get(props, 'deletePrivatePhase', false) === 'success') {
      props.clearTownPhase();
      notification.success({
        message: 'School Deleted Successfully'
      });
      obj.isDeleteModalOpen = false;
      obj.isLoading = false;
      props.toggleLoading(false);
      props.fetchSchools({ townId: parseInt(townId), type: 'private' });
    }

    if (get(props, 'getSchoolsPhase', false) === 'success') {
      props.clearTownPhase();
      obj.privateData = orderBy(get(props, 'schoolData.data', []), 'sort_by');
      obj.isLoading = false;
      props.toggleLoading(false);
    }

    if (get(props, 'addPrivatePhase', false) === 'success') {
      props.clearTownPhase();
      notification.success({
        message: 'School Added Successfully'
      });
      obj.addPublicSchoolModal = false;
      obj.editMode = false;
      obj.visible = false;
      obj.isLoading = false;
      obj.selectedSchool = state.selectedSchoolInitial;
      props.fetchSchools({ townId: parseInt(townId), type: 'private' });
    }

    if (get(props, 'addPrivatePhase', false) === 'error') {
      notification.error({
        message: get(props, 'addPrivateData.error.message')
      });

      props.clearTownPhase();
      obj.editMode = false;
      obj.visible = false;
      obj.isLoading = false;
      props.toggleLoading(false);
      obj.selectedSchool = state.selectedSchoolInitial;
    }

    // if (get(props, 'getAttomSchoolsPhase', false) === 'success') {
    //   props.clearTownPhase();
    //   let schools = get(props, 'attomSchoolData.schools', []);

    //   if (!isEmpty(schools)) {
    //     schools.map((data, index) => {
    //       const attomSchoolId = data.Identifier.OBInstID;
    //       const filtered = state.privateData.filter(o => {
    //         return String(o.schoolAttomId) === String(attomSchoolId);
    //       });
    //       if (filtered && filtered.length > 0) {
    //         schools[index].School.isAvailable = true;
    //       }
    //     });
    //   }

    //   obj.privateAttomSchools = schools
    //   obj.privateAttomSchoolsMain = schools
    // }

    if (get(props, 'getAttomSchoolsPhase', false) === 'success') {
      props.clearTownPhase();
      const schools = get(props, 'attomSchoolData.data', []);
      const isError = typeof schools === 'array' ? true : false;

      if (!isEmpty(schools) && !isError) {
        const setscl = schools.map((data, index) => {
          const attomSchoolId = data.Identifier.OBInstID;
          const attomSchoolName = data.School.InstitutionName
          const schoolExists = state.privateData.find(o => {
            return String(o.schoolAttomId) === String(attomSchoolId) || o.InstitutionName.toLowerCase() === attomSchoolName.toLowerCase();
          });

          if (schoolExists) {
            data.School.isAvailable = true;
          }
          return data
        });

        Promise.all(setscl)
      }

      obj.privateAttomSchools = !Error ? schools : [];
      obj.privateAttomSchoolsMain = !Error ? schools : [];
    }

    return obj;
  }

  deleteSchool = data => {
    this.setState({ isDeleteModalOpen: true, selectedSchoolId: data.id });
  };

  deleteSchoolConfirm = () => {
    const data = this.state.selectedSchoolId;
    const {
      toggleLoading,
      deletePrivateSchool,
      match: {
        params: { townId }
      }
    } = this.props;
    const data1 = {
      school_id: data,
      town_id: parseInt(townId)
    };

    toggleLoading(true);
    this.setState({ isLoading: true });
    deletePrivateSchool(data1);
  };

  editSchool = values => {
    this.setState({
      editMode: true,
      selectedSchool: values,
      addPublicSchoolModal: true
    });
    this.forceUpdate();
  };

  async getSchoolDetails(data, index) {
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const attomSchoolId = data.Identifier.OBInstID;

    fetch(`${SUBURBANJUNGLE_API_HOST}/attom-schools/attom-school-detail?attomSchoolId=${attomSchoolId}&town_id=${townId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(payload => {

        const detail = payload

        if (detail && detail.data && detail.data.schoolDetail) {
          const enrollment = detail.data.schoolDetail.SchoolEnrollment;
          let privateAttomSchools = this.state.privateAttomSchools;
          const currentSchool = privateAttomSchools[index];
          currentSchool.School.Studentsnumberof = enrollment.Studentsnumberof;
          currentSchool.School.Studentteacher = enrollment.Studentteacher;
          currentSchool.School.gradespancodebldgtext =
            detail.data.schoolDetail.SchoolSummary.gradespancodebldgtext;
          privateAttomSchools[index] = currentSchool;
          this.setState({ privateAttomSchools });
          this.forceUpdate();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  //  getSchoolDetails = async (data, index) => {
  //   const {
  //     match: {
  //       params: { townId }
  //     }
  //   } = this.props;
  //   const attomSchoolId = get(data, 'Identifier.OBInstID', '');
  //   const schoolDetail = () =>
  //     axios.get(
  //       `${HOSTNAME}/attomSchoolDetail?attomSchoolId=${attomSchoolId}&town_id=${townId}`
  //     ).catch(logoutInvalidRequest);
  //
  //   const detail = await schoolDetail();
  //   if (detail && detail.data && detail.data.schoolDetail) {
  //     const enrollment = detail.data.schoolDetail.SchoolEnrollment;
  //     const privateAttomSchools = this.state.privateAttomSchools;
  //     const currentSchool = privateAttomSchools[index];
  //     currentSchool.School.Studentsnumberof = enrollment.Studentsnumberof;
  //     currentSchool.School.Studentteacher = enrollment.Studentteacher;
  //     currentSchool.School.gradespancodebldgtext =
  //       detail.data.schoolDetail.SchoolSummary.gradespancodebldgtext;
  //     privateAttomSchools[index] = currentSchool;
  //     this.props.toggleLoading(false);
  //     this.setState({ privateAttomSchools });
  //     this.forceUpdate();
  //   }
  // }

  filterSchools = async value => {
    const { privateAttomSchools, privateAttomSchoolsMain } = this.state;
    let filtered;
    if (!value) {
      filtered = privateAttomSchoolsMain;
    } else {
      filtered = await filter(
        privateAttomSchoolsMain,
        search =>
          search.School.InstitutionName
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ privateAttomSchools: filtered });
  };

  addPrivateAttom = async (data, index) => {
    this.props.toggleLoading(true);
    this.setState({ isLoading: true });
    const {
      match: {
        params: { townId }
      },
      addPrivateSchool
    } = this.props;
    // if (data.School && !data.School.Studentsnumberof) {
    //   const detail = await this.getSchoolDetails(data, index);
    // }
    const attom = data.School;
    const data1 = {
      school_id: data.Identifier.OBInstID,
      school_name: attom.InstitutionName,
      grades: attom.gradespancodebldgtext,
      type: 'private',
      town_id: parseInt(townId),
      rating: 0,//attom.GSTestRating,
      totalStudent: attom.Studentsnumberof,
      ratio: attom.Studentteacher,
      geocodinglatitude: attom.geocodinglatitude,
      geocodinglongitude: attom.geocodinglongitude,
      locationcity: attom.locationcity,
      zip54: attom.zip54
    };
    const privateAttomSchools = this.state.privateAttomSchools;
    privateAttomSchools[index].School.isAvailable = true;
    this.setState({
      isDateLoading: true,
      modalIsOpen: false,
      openSideBar: false,
      privateAttomSchools
    });
    addPrivateSchool(data1);
  };

  showAttomSchoolsDrawer = () => {
    this.setState({
      visible: true,
      selectedSchool: this.state.selectedSchoolInitial
    });
  };
  openAddPublicSchoolModal() {
    this.setState({ addPublicSchoolModal: true });
  }
  closeModal() {
      this.setState({ addPublicSchoolModal: false, selectedSchool: this.state.selectedSchoolInitial });
  }
  onClose = () => {
    this.setState({
      visible: false
    });
  };

  addSchool = values => {
    this.props.toggleLoading(true);
    this.setState({ addPublicSchoolModal: false, isLoading: true });
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    let data = {
      school_id: get(values, 'id'),
      school_name: get(values, 'InstitutionName'),
      grades: get(values, 'gradespancodebldgtext'),
      ratio: get(values, 'studentteacher'),
      totalStudent: get(values, 'studentsnumberof'),
      type: 'private',
      town_id: parseInt(townId),
      rating: 0//get(values, 'GSTestRating')
    };

    this.props.addPrivateSchool(data);
  };

  updateSchool = async values => {
    const {
      match: {
        params: { townId }
      },
      editPrivateSchool
    } = this.props;

    let data = {
      school_id: get(values, 'id'),
      school_name: get(values, 'InstitutionName'),
      grades: get(values, 'gradespancodebldgtext'),
      ratio: get(values, 'studentteacher'),
      totalStudent: get(values, 'studentsnumberof'),
      type: 'private',
      town_id: parseInt(townId),
      rating: 0//get(values, 'GSTestRating')
    };

    editPrivateSchool(data);
  };
  fetchPrivateSchoolTown = () => {
    const { match: { params: { townId } }} = this.props;
    this.props.fetchSchools({ townId: parseInt(townId), type: 'private' });
  }

  render() {
    const {
      privateData,
      privateAttomSchools,
      editMode,
      selectedSchool,
      isLoading,
      isDeleteModalOpen
    } = this.state;

    return (
      <div>
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deleteSchoolConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        <div className="row mb__15">
          <div className="col-md-12 text-right">
            <button
              className="btn btn__btn btn-dark w__180 mr__15"
              onClick={this.showAttomSchoolsDrawer}
            >
              Attom Schools
            </button>
            {/*<button*/}
            {/*  className="btn btn__btn btn-dark w__180"*/}
            {/*  onClick={this.openAddPublicSchoolModal}*/}
            {/*>*/}
            {/*  Add Private School*/}
            {/*</button>*/}
          </div>
        </div>
        <SchoolPrivateTableData
          privateData={privateData}
          fetchPrivateSchoolTown={this.fetchPrivateSchoolTown}
          editSchool={this.editSchool}
          deleteSchool={this.deleteSchool}
          updateSchoolTownPhase={this.props.updateSchoolTownPhase}
          clearTownsPhase={this.props.clearTownsPhase}
          updateSchoolOrder={this.props.updateSchoolOrder}
        />
        <Drawer
          placement="right"
          closable={false}
          width="600"
          onClose={this.onClose}
          visible={this.state.visible}
          className="drawer__main"
        >
          <div className="drawer__header">
            <h4 className="drawer__header__title"> Attom Public Schools</h4>
            <p className="drawer__header__subtitle">
              {get(this, 'props.town.name')}
            </p>
            <div
              className="drawer__header__close cursor__pointer"
              onClick={this.onClose}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="search__form attom__schools__search">
            <input
              type="text"
              className="form-control search__form__input"
              name="firstName"
              onChange={e => this.filterSchools(e.target.value)}
              placeholder="Search for a school... "
              required
            />
          </div>
          <div className="attom__schools">
            <ul className="attom__public__schools__list">
              {!isEmpty(privateAttomSchools) &&
                privateAttomSchools.map((school, i) => {
                  return (
                    <li
                      key={i}
                      // onClick={() => this.addPrivateAttom(school, i)}
                      className={
                        get(privateAttomSchools, 'School.isAvailable')
                          ? 'active'
                          : ''
                      }
                    >
                      <span>Name:</span>
                      {get(school, 'School.InstitutionName')}
                      <br />
                      <span>Address:</span>
                      {get(school, 'School.locationaddress')}
                      <br />
                      <span>City:</span> {get(school, 'School.locationcity')}
                      <br />
                      <span>Zipcode:</span> {get(school, 'School.zip54')}
                      <br />
                      {/* <span>Rating:</span> {get(school, 'School.GSTestRating')}
                      <br /> */}
                      {school.School && school.School.Studentteacher ? (
                        <>
                          <span>Grade:</span>{' '}
                          {get(school, 'School.gradespancodebldgtext')}
                          <br />
                          <span>Ratio:</span>{' '}
                          {get(school, 'School.Studentteacher')}
                          <br />
                          <span>No. of students:</span>{' '}
                          {get(school, 'School.Studentsnumberof')}
                        </>
                      ) : (
                        <span
                          onClick={() => this.getSchoolDetails(school, i)}
                          className="cursor__pointer"
                        >
                          View More...
                        </span>
                      )}
                      <div
                        onClick={() =>
                          !get(school, 'School.isAvailable', false) &&
                          this.addPrivateAttom(school, i)
                        }
                        className="round-icon"
                        style={{
                          background: get(school, 'School.isAvailable', false) && 'black'
                        }}
                      ></div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Drawer>

        <ReactModal
          isOpen={this.state.addPublicSchoolModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Add Private School"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {editMode ? 'Edit' : 'Add'} Private School
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal.bind(this)}
              >
                <CloseIcon />
              </button>
            </div>
            <Formik
              enableReinitialize
              validationSchema={
                this.props.ValidationSchemas.addPrivateSchoolValidation
              }
              initialValues={selectedSchool}
              onSubmit={values => {
                if (editMode) {
                  this.updateSchool(values);
                }

                if (!editMode) {
                  this.addSchool(values);
                }
              }}
            >
              {({
                isSubmitting,
                errors,
                setFieldValue,
                values,
                handleBlur,
                handleChange
              }) => (
                <Form noValidate>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        id="InstitutionName"
                        name="InstitutionName"
                        className="form-control material-textfield-input textfield-input-lg"
                        required
                      />
                      <label className="material-textfield-label label-lg">
                        Name
                      </label>
                      <ErrorMessage
                        className="invalid-feedback"
                        name="InstitutionName"
                        component="div"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="gradespancodebldgtext"
                            name="gradespancodebldgtext"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Grades
                          </label>
                          <ErrorMessage
                            className="invalid-feedback"
                            name="gradespancodebldgtext"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="studentteacher"
                            name="studentteacher"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Student Teacher Ratio
                          </label>
                          <ErrorMessage
                            className="invalid-feedback"
                            name="studentteacher"
                            component="div"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            id="GSTestRating"
                            name="GSTestRating"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            Rating
                          </label>
                          <ErrorMessage
                            className="invalid-feedback"
                            name="GSTestRating"
                            component="div"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            type="number"
                            min={0}
                            id="studentsnumberof"
                            name="studentsnumberof"
                            className="form-control material-textfield-input textfield-input-lg"
                            required
                          />
                          <label className="material-textfield-label label-lg">
                            # of Students
                          </label>
                          <ErrorMessage
                            className="invalid-feedback"
                            name="studentsnumberof"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn__btn btn__lg btn-dark w__100"
                    >
                      {editMode ? 'Update' : 'Add'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ReactModal>
      </div>
    );
  }
}
