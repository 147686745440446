import { connect } from 'react-redux'

import { 
  deleteClientDealByAgent,
  createClientDeal,
  updateClientUser,
  clearPhase,
  clearAgentPhase,
  clearAgentDatePhase
} from '../../../../store/agent/duck'

import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import { fetchClientData, clearClientPhase } from '../../../../store/strategistV2/clients/action'
import { getClientList as getClientListApi } from '../../../../store/strategistV2/clients/api'

import {
  updateFollowUpDate,
  clearFollowUpPhase
} from '../../../../store/strategistV2/prospectus/followup/actions'

import ClientsComponent from './component'
const ClientsContainer = connect(
  // Map state to props
  (state) => ({
    phase: state.agentV2.phase,
    clientDealPhase: state.agent.clientDealPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    clientsData: state.strategistV2.client.clientData,
    updateUserPhase: state.agent.updateUserPhase,
    deleteClientDealPhase: state.agent.deleteClientDealPhase,
    filterClientsPhase: state.strategistV2.client.filterClientsPhase,
    updateFollowUpDatePhase: state.strategistV2.prospectus.followUp.updateFollowUpDatePhase,
    isCopyEmail: state.user.users.is_copy_email,
    getClientListApi
  }),
  // Map actions to dispatch and props
  {
    deleteClientDealByAgent,
    createClientDeal,
    clearClientPhase,
    clearAgentPhase,
    getClientList: fetchClientData,
    updateClientUser,
    clearPhase,
    clearAgentDatePhase,
    getFilterParams,
    updateFollowUpDate,
    clearFollowUpPhase,
  }
)(ClientsComponent)

export default ClientsContainer
