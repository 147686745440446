import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
// import config from '../../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import styles from './styles.scss'
import _ from 'lodash'
// import io from 'socket.io-client'
import { isArray } from 'util';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
// let socket = io.connect(SOCKET_HOSTNAME)

let _user = ''
let availableDateSlots = ''
let forEndDate = ''

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

class StratagistAgentChatCenterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      chat_text:'',
      sender_id: '',
      startDate: moment(),
      receiver_id:'',
      selectAgentId:'',
      activeAgent: '',
      activeAgentChatBool: false,
      townName: '',
      chatData: []
    }
    this.sendChatMessage = this.sendChatMessage.bind(this)
    // socket.on('client_message', (data) => {
    //   if(this.props.match.params.id == data.client_id){
    //     var chat = this.state.chatData
    //     chat.push(data)
    //     this.setState({chatData: _.cloneDeep(chat)})
    //     //$(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
    //   }
    // })
  }
 
  componentWillMount(){
    const {fetchUser} = this.props;
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    _user = cookies.get('user')
    if(receiver_id && receiver_id != ''){
      const clntdata = {}
      clntdata.client_id = receiver_id;
      this.props.getStrategy(clntdata)
    }
    if(_user) {
      this.setState({userid: _user.id, sender_id:_user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      this.props.getClientAgent({user_id:receiver_id})
    }
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({receiver_id:_clientid})
    }
  }
  
  componentWillReceiveProps(nextProps){
   if(nextProps.getAgentChatPhase === "success") {
    this.setState({chatData: nextProps.getAgentChatData.data})
   }
  }

  componentDidMount(){
    document.title = "Jungler: Client Chat";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handleChange(e){
   this.setState({[e.target.name]: e.target.value })
  }

  selectAgent(agentData){
    this.setState({ activeAgent: agentData.agent_id, town_data: agentData.town_data, activeAgentChatBool: true })
    if(this.props.match.params.id !=''){
      const data={}
      data.client_id = this.props.match.params.id
      this.props.getAgentChat(data);
      //$(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
    }
  }

  closeButton(e){
    this.setState({ activeAgentChatBool: false })
  }

  componentDidUpdate(){
    //$(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 'fast');
     $(document).ready(function(){
        if($(".chat__msg--history").length > 0 ){
          $(".chat__msg--history").scrollTop($(".chat__msg--history")[0].scrollHeight);
        }
     })
  }

   sendChatMessage(){

    // const client_id = this.props.match.params.id
    // if( this.state.chat_text !== '' && client_id != '' && _user.id !=''){
    //   socket.emit('client_message', { 
    //     client_id : this.props.match.params.id,
    //     sender_id : _user.id, 
    //     type : "text",
    //     receiverIds: [this.state.activeAgent], 
    //     sender_type: "strategist",
    //     receiver_type: "agent",
    //     message: this.state.chat_text
    //   })
    //   this.setState({chat_text: ''})
    // }
  }

  render() {
    const {
      user,
      agentData,
      location,
      availabilityData,
      chatHistoryData,
      strategyData,
      chatAgent,
      getAgentChatData
    } = this.props
    let user_id = this.state.userid
    let authorization = cookies.get('Authorization')
    const townInfo = location && location.state && location.state;
    let style = {
      marginRight: '16px',
      color: '#C6CE4A'
    };
    let town_link = 'javascript:void(0);'
    
    let chatStrAgent = _.orderBy(this.state.chatData, ['created_date'],['asc'])

    if(townInfo && townInfo.town_url){
    town_link = townInfo.town_url ? townInfo.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
    }    
    //$(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 500);
    return (
        <div className="client__agent--chat">
            {
              (chatAgent && Array.isArray(chatAgent) && chatAgent.length > 0)? (
                <div className="message__page--container">
                  <div className="message__page--wrapper message__page--ht">
                    <div className={this.state.activeAgent !== '' ? "message__page--left-side chatagentmobi" : "chatagentmobi message__page--left-side seventy__vh"}>
                      <ul className="chat__user--list">
                        {
                          chatAgent && chatAgent.map((agentData, idx)=>{
                            if(agentData.agent_full_name && agentData.agent_full_name !== ''){
                              return(
                                <li 
                                  key={idx} className={(agentData.agent_id === this.state.activeAgent) ? 'active': ''}
                                  onClick={this.selectAgent.bind(this, agentData)}
                                  >
                                  <span className="chat__user--avtar">
                                    <img src={agentData.agent_avatar ? agentData.agent_avatar : 'img/login-bg-img.png'}/>
                                  </span>
                                  <div className="chat__user--info chat__user--infowidth">
                                    <h2 className="chat__user--name user__name--font">{agentData.agent_full_name}</h2>
                                    <h4 className="chat__user--type">{agentData.role}</h4>
                                  </div>
                                </li> 
                              )
                            }
                          })
                        }                
                    </ul>
                  </div>
                <div className={this.state.activeAgentChatBool ? "message__page--right-side hiderightmob hiderightmobshow" : "message__page--right-side hiderightmob"}>
                    <div className="chat__header chat__header--details">
                    {
                      (this.state.activeAgent)?(
                        <div className="chat__header--heading">Here are the towns this agent will help you with: </div>
                       ):''
                    }
                      <span className="chat__header--users">
                        {this.state.town_data && this.state.town_data.length > 0 && this.state.town_data.map((town, ix) => {
                          var town_link = town.town_url ? town.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
                            return(<a key={ix} href={town_link} target="_blank" style={style}>{town.town_name}</a>)
                          })
                        }
                      </span>
                      <div className="profile__close"><img onClick={this.closeButton.bind(this)} src="img/close.svg" /></div>
                    </div>
                    <div className="chat__container chat__container--mobi">
                      <div className="chat__container--info-btn">
                        {/*<img src="img/info-icon.svg"/>*/}
                      </div>
                      <div className="mesgs mesgs__new">
                      <div className="chat__msg--history chat__msg--historymob">
                        <div className="ful">
                            {
                              chatStrAgent && chatStrAgent.length > 0 && chatStrAgent.map((chat,ind) => {
                                let userAvatar = _user && _user.avatar;
                                let userName   = _user && _user.first_name +' '+_user.last_name;
                                let myuserId     = _user && _user.id;
                                let valueAtIndex1 = chat.receiverIds;
                                let receiverValue = valueAtIndex1[0];
                                return(
                                  <div key={ind}>
                                    { (chat.sender_id == this.state.activeAgent) ? (
                                      <div className="ful">
                                        <div className="chat__msg--date__time">{moment(chat.created_date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</div>

                                        <div className="incoming_msg">
                                          <div className="incoming_msg_img">
                                            <div className="chat__msg--avtar">
                                              <img className="img-responsive" src={chat.sender_avatar ? chat.sender_avatar:"img/login-bg-img.png"} alt=""/>
                                                      
                                            </div>
                                            <div className="chat__msg--avtar-name">
                                              {chat.sender_full_name} ({chat.role.charAt(0).toUpperCase()+ chat.role.slice(1)})
                                            </div>
                                          </div>
                                          <div className="received_msg">
                                            <div className="received_withd_msg">
                                              {chat.type==='image' ?
                                                 <a href={chat.message} target="_blank">
                                                 <img src={chat.message}/>
                                                 </a>
                                                :
                                                 <p>{chat.message}</p>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                     ):
                                      <div>
                                      {
                                        (receiverValue == this.state.activeAgent)?(
                                          <div>
                                            <div className="chat__msg--date__time">{moment(chat.created_date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</div>
                                              <div className="outgoing_msg">
                                                <div className="sent_msg">
                                                {chat.type==='image' ?
                                                 <a href={chat.message} target="_blank">
                                                 <img src={chat.message}/>
                                                 </a>
                                                :
                                                 <p>{chat.message}</p>
                                                }
                                                </div>
                                                <div className="outgoin_msg_img">
                                                  <div className="chat__msg--avtar-name">
                                                    {chat.sender_full_name == userName ? "Me": `${chat.sender_full_name}(${chat.role.charAt(0).toUpperCase()+ chat.role.slice(1)})`}
                                                  </div>
                                                <div className="chat__msg--avtar">
                                                  <img className="img-responsive" src={chat.sender_avatar} alt=""/>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        ):''
                                      }
                                      </div> 
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                          </div>
                        <div className={this.state.activeAgent !== '' ? "type_msg type_msg-newchat type_msg_mob" : "not__show"}>
                        <div className="chat___msg--input-write">
                            <textarea 
                              name="chat_text" 
                              value={this.state.chat_text}
                              onChange={this.handleChange.bind(this)} 
                              className="chat___msg--input inputmob" 
                              placeholder="Type your message to the agent..." />
                            <button 
                              className="chat__msg--send__btn" 
                              type="submit"
                              onClick={()=> this.sendChatMessage()}>
                              Send
                            </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ):
              <div className="client_blank_msg">
                There are no agents assigned to a client. You'll be able to chat with agent once you assign strategist
              </div>
            }
      </div>
    )
  }
}

export default reduxForm({
  form: 'messagecenter',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StratagistAgentChatCenterForm)
