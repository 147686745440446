import Rx from 'rxjs/Rx';
import { Record, List } from 'immutable';
import { combineEpics } from 'redux-observable';
import HttpStatus from 'http-status-codes';
import { assign } from 'lodash';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';

import * as api from './api';

/**
 * Public: Action Types
 */

export const GET_DASHBOARD_DATA = 'suburban/dashboard/GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS =
  'suburban/dashboard/GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR =
  'suburban/dashboard/GET_DASHBOARD_DATA_ERROR';

export const GET_FILTER_PARAMS = 'suburban/dashboard/GET_FILTER_PARAMS';
export const GET_FILTER_PARAMS_SUCCESS =
  'suburban/dashboard/GET_FILTER_PARAMS_SUCCESS';
export const GET_FILTER_PARAMS_ERROR =
  'suburban/dashboard/GET_FILTER_PARAMS_ERROR';

export const GET_TOWNS = 'suburban/dashboard/GET_TOWNS';
export const GET_TOWNS_SUCCESS = 'suburban/dashboard/GET_TOWNS_SUCCESS';
export const GET_TOWNS_ERROR = 'suburban/dashboard/GET_TOWNS_ERROR';

export const SAVE_AUTH_TOKEN = 'suburban/dashboard/SAVE_AUTH_TOKEN';
export const SAVE_AUTH_TOKEN_SUCCESS =
  'suburban/dashboard/SAVE_AUTH_TOKEN_SUCCESS';
export const SAVE_AUTH_TOKEN_ERROR = 'suburban/dashboard/SAVE_AUTH_TOKEN_ERROR';

export const SAVE_AGENT_AUTH_TOKEN = 'suburban/dashboard/SAVE_AGENT_AUTH_TOKEN';
export const SAVE_AGENT_AUTH_TOKEN_SUCCESS =
  'suburban/dashboard/SAVE_AGENT_AUTH_TOKEN_SUCCESS';
export const SAVE_AGENT_AUTH_TOKEN_ERROR =
  'suburban/dashboard/SAVE_AGENT_AUTH_TOKEN_ERROR';

export const GET_ALL_USERS = 'suburban/dashboard/GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'suburban/dashboard/GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'suburban/dashboard/GET_ALL_USERS_ERROR';

export const GET_STRATEGY = 'suburban/dashboard/GET_STRATEGY';
export const GET_STRATEGY_SUCCESS = 'suburban/dashboard/GET_STRATEGY_SUCCESS';
export const GET_STRATEGY_ERROR = 'suburban/dashboard/GET_STRATEGY_ERROR';

export const GET_TOWN_AGENTS = 'suburban/dashboard/GET_TOWN_AGENTS';
export const GET_TOWN_AGENTS_SUCCESS =
  'suburban/dashboard/GET_TOWN_AGENTS_SUCCESS';
export const GET_TOWN_AGENTS_ERROR = 'suburban/dashboard/GET_TOWN_AGENTS_ERROR';

export const GET_CLIENT_DATA = 'suburban/dashboard/GET_CLIENT_DATA';
export const GET_CLIENT_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_DATA_SUCCESS';
export const GET_CLIENT_DATA_ERROR = 'suburban/dashboard/GET_CLIENT_DATA_ERROR';

export const GET_STRATEGIST_APT = 'suburban/dashboard/GET_STRATEGIST_APT';
export const GET_STRATEGIST_APT_SUCCESS =
  'suburban/dashboard/GET_STRATEGIST_APT_SUCCESS';
export const GET_STRATEGIST_APT_ERROR =
  'suburban/dashboard/GET_STRATEGIST_APT_ERROR';

export const GET_STRATEGIST_AVAILABILITY =
  'suburban/dashboard/GET_STRATEGIST_AVAILABILITY';
export const GET_STRATEGIST_AVAILABILITY_SUCCESS =
  'suburban/dashboard/GET_STRATEGIST_AVAILABILITY_SUCCESS';
export const GET_STRATEGIST_AVAILABILITY_ERROR =
  'suburban/dashboard/GET_STRATEGIST_AVAILABILITY_ERROR';

export const DELETE_CLIENT_DATA = 'suburban/dashboard/DELETE_CLIENT_DATA';
export const DELETE_CLIENT_DATA_SUCCESS =
  'suburban/dashboard/DELETE_CLIENT_DATA_SUCCESS';
export const DELETE_CLIENT_DATA_ERROR =
  'suburban/dashboard/DELETE_CLIENT_DATA_ERROR';

export const GET_JUNGLER_USERS_DATA =
  'suburban/dashboard/GET_JUNGLER_USERS_DATA';
export const GET_JUNGLER_USERS_DATA_SUCCESS =
  'suburban/dashboard/GET_JUNGLER_USERS_DATA_SUCCESS';
export const GET_JUNGLER_USERS_DATA_ERROR =
  'suburban/dashboard/GET_JUNGLER_USERS_DATA_ERROR';

export const GET_JUNGLER_USERBYID_DATA_EDIT =
  'suburban/dashboard/GET_JUNGLER_USERBYID_DATA_EDIT';

export const GET_JUNGLER_USERBYID_DATA =
  'suburban/dashboard/GET_JUNGLER_USERBYID_DATA';
export const GET_JUNGLER_USERBYID_DATA_SUCCESS =
  'suburban/dashboard/GET_JUNGLER_USERBYID_DATA_SUCCESS';
export const GET_JUNGLER_USERBYID_DATA_ERROR =
  'suburban/dashboard/GET_JUNGLER_USERBYID_DATA_ERROR';

export const GET_CLIENT_BYID_DATA = 'suburban/dashboard/GET_CLIENT_BYID_DATA';
export const GET_CLIENT_BYID_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_BYID_DATA_SUCCESS';
export const GET_CLIENT_BYID_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_BYID_DATA_ERROR';

export const ADD_USER = 'suburban/dashboard/ADD_USER';
export const ADD_USER_SUCCESS = 'suburban/dashboard/ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'suburban/dashboard/ADD_USER_ERROR';

export const ADD_STRATEGY_APPOINTMENT =
  'suburban/dashboard/ADD_STRATEGY_APPOINTMENT';
export const ADD_STRATEGY_APPOINTMENT_SUCCESS =
  'suburban/dashboard/ADD_STRATEGY_APPOINTMENT_SUCCESS';
export const ADD_STRATEGY_APPOINTMENT_ERROR =
  'suburban/dashboard/ADD_STRATEGY_APPOINTMENT_ERROR';

export const ADD_CLIENT_STRATEGY_APPOINTMENT =
  'suburban/dashboard/ADD_CLIENT_STRATEGY_APPOINTMENT';
export const ADD_CLIENT_STRATEGY_APPOINTMENT_SUCCESS =
  'suburban/dashboard/ADD_CLIENT_STRATEGY_APPOINTMENT_SUCCESS';
export const ADD_CLIENT_STRATEGY_APPOINTMENT_ERROR =
  'suburban/dashboard/ADD_CLIENT_STRATEGY_APPOINTMENT_ERROR';

export const ADD_STRATEGY = 'suburban/dashboard/ADD_STRATEGY';
export const ADD_STRATEGY_SUCCESS = 'suburban/dashboard/ADD_STRATEGY_SUCCESS';
export const ADD_STRATEGY_ERROR = 'suburban/dashboard/ADD_STRATEGY_ERROR';

export const GET_CLIENT_EMAIL_BYID_DATA =
  'suburban/dashboard/GET_CLIENT_EMAIL_BYID_DATA';
export const GET_CLIENT_EMAIL_BYID_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_EMAIL_BYID_DATA_SUCCESS';
export const GET_CLIENT_EMAIL_BYID_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_EMAIL_BYID_DATA_ERROR';

export const GET_CLIENT_AGENT_DATA = 'suburban/dashboard/GET_CLIENT_AGENT_DATA';
export const GET_CLIENT_AGENT_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_AGENT_DATA_SUCCESS';
export const GET_CLIENT_AGENT_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_AGENT_DATA_ERROR';

export const GET_CLOSED_CLIENTS_PER_TOWN = 'suburban/dashboard/GET_CLOSED_CLIENTS_PER_TOWN';
export const GET_CLOSED_CLIENTS_PER_TOWN_SUCCESS =
  'suburban/dashboard/GET_CLOSED_CLIENTS_PER_TOWN_SUCCESS';
export const GET_CLOSED_CLIENTS_PER_TOWN_ERROR =
  'suburban/dashboard/GET_CLOSED_CLIENTS_PER_TOWN_ERROR';


export const GET_AI_CLIENTS = 'suburban/dashboard/GET_AI_CLIENTS';
export const GET_AI_CLIENTS_SUCCESS =
  'suburban/dashboard/GET_AI_CLIENTS_SUCCESS';
export const GET_AI_CLIENTS_ERROR =
  'suburban/dashboard/GET_AI_CLIENTS_ERROR';

export const GET_CLIENT_DEAL_DATA = 'suburban/dashboard/GET_CLIENT_DEAL_DATA';
export const GET_CLIENT_DEAL_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_DEAL_DATA_SUCCESS';
export const GET_CLIENT_DEAL_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_DEAL_DATA_ERROR';

export const ADD_CLIENT_DEAL_DATA = 'suburban/dashboard/ADD_CLIENT_DEAL_DATA';
export const ADD_CLIENT_DEAL_DATA_SUCCESS =
  'suburban/dashboard/ADD_CLIENT_DEAL_DATA_SUCCESS';
export const ADD_CLIENT_DEAL_DATA_ERROR =
  'suburban/dashboard/ADD_CLIENT_DEAL_DATA_ERROR';

export const ADD_UPDATE_CLIENT_DATA =
  'suburban/dashboard/ADD_UPDATE_CLIENT_DATA';
export const ADD_UPDATE_CLIENT_DATA_SUCCESS =
  'suburban/dashboard/ADD_UPDATE_CLIENT_DATA_SUCCESS';
export const ADD_UPDATE_CLIENT_DATA_ERROR =
  'suburban/dashboard/ADD_UPDATE_CLIENT_DATA_ERROR';

export const UPDATE_SCHEDULING = 'suburban/dashboard/UPDATE_SCHEDULING';
export const UPDATE_SCHEDULING_SUCCESS =
  'suburban/dashboard/UPDATE_SCHEDULING_SUCCESS';
export const UPDATE_SCHEDULING_ERROR =
  'suburban/dashboard/UPDATE_SCHEDULING_ERROR';

export const GET_CLIENT_NOTES_BYID_DATA =
  'suburban/dashboard/GET_CLIENT_NOTES_BYID_DATA';
export const GET_CLIENT_NOTES_BYID_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_NOTES_BYID_DATA_SUCCESS';
export const GET_CLIENT_NOTES_BYID_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_NOTES_BYID_DATA_ERROR';

export const GET_CLIENT_RECORDING_LINK =
  'suburban/dashboard/GET_CLIENT_RECORDING_LINK';
export const GET_CLIENT_RECORDING_LINK_SUCCESS =
  'suburban/dashboard/GET_CLIENT_RECORDING_LINK_SUCCESS';
export const GET_CLIENT_RECORDING_LINK_ERROR =
  'suburban/dashboard/GET_CLIENT_RECORDING_LINK_ERROR';

export const GET_CLIENT_MESSAGES_DATA =
  'suburban/dashboard/GET_CLIENT_MESSAGES_DATA';
export const GET_CLIENT_MESSAGES_DATA_SUCCESS =
  'suburban/dashboard/GET_CLIENT_MESSAGES_DATA_SUCCESS';
export const GET_CLIENT_MESSAGES_DATA_ERROR =
  'suburban/dashboard/GET_CLIENT_MESSAGES_DATA_ERROR';

export const SEND_CLIENT_MESSAGES_DATA =
  'suburban/dashboard/SEND_CLIENT_MESSAGES_DATA';
export const SEND_CLIENT_MESSAGES_DATA_SUCCESS =
  'suburban/dashboard/SEND_CLIENT_MESSAGES_DATA_SUCCESS';
export const SEND_CLIENT_MESSAGES_DATA_ERROR =
  'suburban/dashboard/SEND_CLIENT_MESSAGES_DATA_ERROR';

export const SEND_MY_NOTES_DATA = 'suburban/dashboard/SEND_MY_NOTES_DATA';
export const SEND_MY_NOTES_DATA_SUCCESS =
  'suburban/dashboard/SEND_MY_NOTES_DATA_SUCCESS';
export const SEND_MY_NOTES_DATA_ERROR =
  'suburban/dashboard/SEND_MY_NOTES_DATA_ERROR';

export const SEND_GMAIL_CODE = 'suburban/dashboard/SEND_GMAIL_CODE';
export const SEND_GMAIL_CODE_SUCCESS =
  'suburban/dashboard/SEND_GMAIL_CODE_SUCCESS';
export const SEND_GMAIL_CODE_ERROR = 'suburban/dashboard/SEND_GMAIL_CODE_ERROR';

export const SEND_BACKGROUND_DATA = 'suburban/dashboard/SEND_BACKGROUND_DATA';
export const SEND_BACKGROUND_DATA_SUCCESS =
  'suburban/dashboard/SEND_BACKGROUND_DATA_SUCCESS';
export const SEND_BACKGROUND_DATA_ERROR =
  'suburban/dashboard/SEND_BACKGROUND_DATA_ERROR';

export const HOME_FILTER_CLIENTS = 'suburban/dashboard/HOME_FILTER_CLIENTS';
export const HOME_FILTER_CLIENTS_SUCCESS =
  'suburban/dashboard/HOME_FILTER_CLIENTS_SUCCESS';
export const HOME_FILTER_CLIENTS_ERROR =
  'suburban/dashboard/HOME_FILTER_CLIENTS_ERROR';

export const CANCEL_APPOINTMENT = 'suburban/dashboard/CANCEL_APPOINTMENT';
export const CANCEL_APPOINTMENT_SUCCESS =
  'suburban/dashboard/CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_ERROR =
  'suburban/dashboard/CANCEL_APPOINTMENT_ERROR';

export const CANCEL_STRATEGIST_APPOINTMENT =
  'suburban/dashboard/CANCEL_STRATEGIST_APPOINTMENT';
export const CANCEL_STRATEGIST_APPOINTMENT_SUCCESS =
  'suburban/dashboard/CANCEL_STRATEGIST_APPOINTMENT_SUCCESS';
export const CANCEL_STRATEGIST_APPOINTMENT_ERROR =
  'suburban/dashboard/CANCEL_STRATEGIST_APPOINTMENT_ERROR';

export const CLEAR_USER_ERROR = 'suburban/dashboard/CLEAR_USER_ERROR';
export const CLEAR_PHASE = 'suburban/dashboard/CLEAR_PHASE';
export const CLEAR_FILTER_PHASE = 'suburban/dashboard/CLEAR_FILTER_PHASE';

export const DELETE_NOTES = 'suburban/dashboard/DELETE_NOTES';
export const DELETE_NOTES_SUCCESS = 'suburban/dashboard/DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_ERROR = 'suburban/dashboard/DELETE_NOTES_ERROR';

export const IMPERSONATE_USER = 'suburban/dashboard/IMPERSONATE_USER';
export const IMPERSONATE_USER_SUCCESS =
  'suburban/dashboard/IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_USER_ERROR =
  'suburban/dashboard/IMPERSONATE_USER_ERROR';

export const GET_STR_RATING = 'suburban/dashboard/GET_STR_RATING';
export const GET_STR_RATING_SUCCESS =
  'suburban/dashboard/GET_STR_RATING_SUCCESS';
export const GET_STR_RATING_ERROR = 'suburban/dashboard/GET_STR_RATING_ERROR';

export const GET_AGENTS_ASSIGNED_TO_TOWNS =
  'suburban/dashboard/GET_AGENTS_ASSIGNED_TO_TOWNS';
export const GET_AGENTS_ASSIGNED_TO_TOWNS_SUCCESS =
  'suburban/dashboard/GET_AGENTS_ASSIGNED_TO_TOWNS_SUCCESS';
export const GET_AGENTS_ASSIGNED_TO_TOWNS_ERROR =
  'suburban/dashboard/GET_AGENTS_ASSIGNED_TO_TOWNS_ERROR';

export const GET_NO_AGENT_RATING = 'suburban/dashboard/GET_NO_AGENT_RATING';
export const GET_NO_AGENT_RATING_SUCCESS =
  'suburban/dashboard/GET_NO_AGENT_RATING_SUCCESS';
export const GET_NO_AGENT_RATING_ERROR =
  'suburban/dashboard/GET_NO_AGENT_RATING_ERROR';

export const GET_AGENT_RATING = 'suburban/dashboard/GET_AGENT_RATING';
export const GET_AGENT_RATING_SUCCESS =
  'suburban/dashboard/GET_AGENT_RATING_SUCCESS';
export const GET_AGENT_RATING_ERROR =
  'suburban/dashboard/GET_AGENT_RATING_ERROR';

export const GET_CLIENTS_PER_MONTHS =
  'suburban/dashboard/GET_CLIENTS_PER_MONTHS';
export const GET_CLIENTS_PER_MONTHS_SUCCESS =
  'suburban/dashboard/GET_CLIENTS_PER_MONTHS_SUCCESS';
export const GET_CLIENTS_PER_MONTHS_ERROR =
  'suburban/dashboard/GET_CLIENTS_PER_MONTHS_ERROR';

export const CLEAR_CLIENT_ACTIVITY_PHASE =
  'suburban/dashboard/CLEAR_CLIENT_ACTIVITY_PHASE';
  
export const GET_TOWNS_OPTION_BY_SUBURBS =
  'suburban/dashboard/GET_TOWNS_OPTION_BY_SUBURBS';

export const TASKS_LIST_USERS = 'suburban/dashboard/TASKS_LIST_USERS';
export const TASKS_LIST_USERS_SUCCESS =
  'suburban/dashboard/TASKS_LIST_USERS_SUCCESS';
export const TASKS_LIST_USERS_ERROR =
  'suburban/dashboard/TASKS_LIST_USERS_ERROR';
/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  dataPhase: INIT,
  notesPhase: INIT,
  messagePhase: INIT,
  authTokenPhase: INIT,
  authAgentTokenPhase: INIT,
  linkPhase: INIT,
  townPhase: INIT,
  addUserPhase: INIT,
  users: {},
  filterDataPhase: INIT,
  strategyPhase: INIT,
  townAgentPhase: INIT,
  filterClientsPhase: INIT,
  addStrategyPhase: INIT,
  strategistAptPhase: INIT,
  strategistAvailabilityPhase: INIT,
  addStrategyAptPhase: INIT,
  addUpdateClientPhase: INIT,
  allUserPhase: INIT,
  impersonatePhase: INIT,
  schedulingPhase: INIT,
  junglerUserPhase: INIT,
  filterParamsPhase: INIT,
  strategistPhase: INIT,
  gmailPhase: INIT,
  deleteNotePhase: INIT,
  addClientStrategyAptPhase: INIT,
  cancelAptPhase: INIT,
  cancelStrAptPhase: INIT,
  strRatingPhase: INIT,
  strRatingData: [],
  agentAssToTownPhase: INIT,
  agentAssToTownData: [],
  cancelAptData: [],
  cancelStrAptData: [],
  cancelAptMessage: '',
  cancelStrAptMessage: '',
  addClientStrategyAptData: [],
  addClientStrAptMessage: '',
  clientsPerMonthPhase: INIT,
  clientsPerMonthData: [],
  error: null,
  venues: [],
  clientData: [],
  gmailData: [],
  allUserData: [],
  singleClientData: [],
  saveAuthtokenData: [],
  saveAgentAuthtokenData: [],
  deleteNoteData: [],
  townData: [],
  clientEmaildata: [],
  clientNotesdata: [],
  getClientMessagesData: [],
  getRecordingLinksData: [],
  sendClientMessagesData: [],
  strategistAvailabilityData: [],
  strategistListData: [],
  addStrategyAptData: [],
  schedulingData: [],
  sendMynoteData: [],
  townAgnetData: [],
  strategyData: {},
  addStrategyData: [],
  clientAgentData: [],
  strategistAptData: [],
  clientDealdata: [],
  addclientDealdata: [],
  deleteClientdata: [],
  addUpdateClientdata: [],
  usersData: [],
  userData: [],
  addUserData: [],
  filterParams: [],
  isSubmitting: false,
  searchTextEverywhere: '',
  addUserMessage: '',
  name: '',
  city: '',
  state: '',
  message: '',
  addAptMessage: '',
  zipcode: '',
  venueUtc: '',
  strategistMessage: '',
  venuesMessage: null,
  impersonateData: {},
  sendBackgroundPhase: INIT,
  chatAgent: [],
  noAgentRatingPhase: INIT,
  noAgentRatingData: [],
  agentRatingPhase: INIT,
  agentRatingData: [],
  closedClientPhase: INIT,
  closedClientData: [],
  aiClientPhase: INIT,
  aiClientData: [],
  tasksListPhase: INIT,
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case GET_DASHBOARD_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_DASHBOARD_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('dashboardData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_DASHBOARD_DATA_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('dataPhase', ERROR);
    }

    case GET_TOWNS: {
      return state
        .set('townPhase', LOADING)
        .set('townData', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TOWNS_SUCCESS: {
      const { payload } = action;
      return state
        .set('townPhase', SUCCESS)
        .set('townData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_TOWNS_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('townPhase', ERROR);
    }

    case GET_STR_RATING: {
      return state
        .set('strRatingPhase', LOADING)
        .set('strRatingData', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_STR_RATING_SUCCESS: {
      const { payload } = action;
      return state
        .set('strRatingPhase', SUCCESS)
        .set('strRatingData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_STR_RATING_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('strRatingPhase', ERROR);
    }

    case GET_AGENTS_ASSIGNED_TO_TOWNS: {
      return state
        .set('agentAssToTownPhase', LOADING)
        .set('agentAssToTownData', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_AGENTS_ASSIGNED_TO_TOWNS_SUCCESS: {
      const { payload } = action;
      return state
        .set('agentAssToTownPhase', SUCCESS)
        .set('agentAssToTownData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_AGENTS_ASSIGNED_TO_TOWNS_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('agentAssToTownPhase', ERROR);
    }

    case SAVE_AUTH_TOKEN: {
      return state
        .set('authTokenPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SAVE_AUTH_TOKEN_SUCCESS: {
      const { payload } = action;
      return state
        .set('authTokenPhase', SUCCESS)
        .set('saveAuthtokenData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SAVE_AUTH_TOKEN_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('authTokenPhase', ERROR);
    }

    case SAVE_AGENT_AUTH_TOKEN: {
      return state
        .set('authAgentTokenPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SAVE_AGENT_AUTH_TOKEN_SUCCESS: {
      const { payload } = action;
      return state
        .set('authAgentTokenPhase', SUCCESS)
        .set('saveAgentAuthtokenData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SAVE_AGENT_AUTH_TOKEN_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('authAgentTokenPhase', ERROR);
    }

    case GET_ALL_USERS: {
      return state
        .set('allUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_ALL_USERS_SUCCESS: {
      const { payload } = action;
      return state
        .set('allUserPhase', SUCCESS)
        .set('allUserData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_ALL_USERS_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('allUserPhase', ERROR);
    }

    case GET_STRATEGIST_APT: {
      return state
        .set('strategistAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_STRATEGIST_APT_SUCCESS: {
      const { payload } = action;
      return state
        .set('strategistAptPhase', SUCCESS)
        .set('strategistAptData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_STRATEGIST_APT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('strategistAptPhase', ERROR);
    }

    case GET_TOWN_AGENTS: {
      return state
        .set('townAgentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TOWN_AGENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('townAgentPhase', SUCCESS)
        .set('townAgnetData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_TOWN_AGENTS_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('townAgentPhase', ERROR);
    }

    case GET_CLIENT_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('filterClientsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('clientData', payload)
        .set('filterClientsPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('filterClientsPhase', ERROR)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_CLIENTS_PER_MONTHS: {
      return state
        .set('clientsPerMonthPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENTS_PER_MONTHS_SUCCESS: {
      const { payload } = action;
      return state
        .set('clientsPerMonthPhase', SUCCESS)
        .set('clientsPerMonthData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENTS_PER_MONTHS_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('clientsPerMonthPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_STRATEGIST_AVAILABILITY: {
      return state
        .set('strategistAvailabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_STRATEGIST_AVAILABILITY_SUCCESS: {
      const { payload } = action;
      return state
        .set('strategistAvailabilityPhase', SUCCESS)
        .set('strategistAvailabilityData', payload)
        .set('strategistMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_STRATEGIST_AVAILABILITY_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('strategistAvailabilityPhase', ERROR);
    }

    case CANCEL_APPOINTMENT: {
      return state
        .set('cancelAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CANCEL_APPOINTMENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('cancelAptPhase', SUCCESS)
        .set('cancelAptData', payload)
        .set('cancelAptMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case CANCEL_APPOINTMENT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('cancelAptPhase', ERROR);
    }

    case CANCEL_STRATEGIST_APPOINTMENT: {
      return state
        .set('cancelStrAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CANCEL_STRATEGIST_APPOINTMENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('cancelStrAptPhase', SUCCESS)
        .set('cancelStrAptData', payload)
        .set('cancelStrAptMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case CANCEL_STRATEGIST_APPOINTMENT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('cancelStrAptPhase', ERROR);
    }

    case DELETE_CLIENT_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case DELETE_CLIENT_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('deleteClientdata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case DELETE_CLIENT_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case ADD_UPDATE_CLIENT_DATA: {
      return state
        .set('addUpdateClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_UPDATE_CLIENT_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('addUpdateClientPhase', SUCCESS)
        .set('addUpdateClientdata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case ADD_UPDATE_CLIENT_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('addUpdateClientPhase', ERROR)
        .set('isSubmitting', false);
    }

    case UPDATE_SCHEDULING: {
      return state
        .set('schedulingPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_SCHEDULING_SUCCESS: {
      const { payload } = action;
      return state
        .set('schedulingPhase', SUCCESS)
        .set('schedulingData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case UPDATE_SCHEDULING_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('schedulingPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_JUNGLER_USERS_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_JUNGLER_USERS_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('usersData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_JUNGLER_USERS_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }


    case GET_JUNGLER_USERBYID_DATA: {
      return state
        .set('junglerUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_JUNGLER_USERBYID_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('junglerUserPhase', SUCCESS)
        .set('userData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_JUNGLER_USERBYID_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('junglerUserPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_BYID_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('clientEmaildata', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_BYID_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('singleClientData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_BYID_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_EMAIL_BYID_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_EMAIL_BYID_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('clientEmaildata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_EMAIL_BYID_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_NOTES_BYID_DATA: {
      return state
        .set('notesPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_NOTES_BYID_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('notesPhase', SUCCESS)
        .set('clientNotesdata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_NOTES_BYID_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('notesPhase', ERROR)
        .set('isSubmitting', false);
    }

    case SEND_MY_NOTES_DATA: {
      return state
        .set('notesPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SEND_MY_NOTES_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('notesPhase', SUCCESS)
        .set('sendMynoteData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SEND_MY_NOTES_DATA_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('notesPhase', ERROR);
    }

    case GET_CLIENT_MESSAGES_DATA: {
      return state
        .set('messagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_MESSAGES_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('messagePhase', SUCCESS)
        .set('getClientMessagesData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_MESSAGES_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('messagePhase', ERROR)
        .set('isSubmitting', false);
    }

    case SEND_CLIENT_MESSAGES_DATA: {
      return state
        .set('messagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SEND_CLIENT_MESSAGES_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('messagePhase', SUCCESS)
        .set('sendClientMessagesData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SEND_CLIENT_MESSAGES_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('messagePhase', ERROR)
        .set('isSubmitting', false);
    }

    case SEND_GMAIL_CODE: {
      return state
        .set('gmailPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SEND_GMAIL_CODE_SUCCESS: {
      const { payload } = action;
      return state
        .set('gmailPhase', SUCCESS)
        .set('gmailData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SEND_GMAIL_CODE_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('gmailPhase', ERROR)
        .set('isSubmitting', false);
    }

    case SEND_BACKGROUND_DATA: {
      return state
        .set('sendBackgroundPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SEND_BACKGROUND_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('sendBackgroundPhase', payload.status)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SEND_BACKGROUND_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('sendBackgroundPhase', ERROR);
    }

    case GET_CLIENT_AGENT_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_AGENT_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('clientAgentData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_AGENT_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_DEAL_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_DEAL_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('clientDealdata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_DEAL_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case ADD_CLIENT_DEAL_DATA: {
      return state
        .set('dataPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_CLIENT_DEAL_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('addclientDealdata', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case ADD_CLIENT_DEAL_DATA_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    case HOME_FILTER_CLIENTS: {
      return state
        .set('dataPhase', LOADING)
        .set('filterDataPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case HOME_FILTER_CLIENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('filterDataPhase', SUCCESS)
        .set('clientData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case HOME_FILTER_CLIENTS_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }
    case GET_FILTER_PARAMS: {
      return state
        .set('filterParamsPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_FILTER_PARAMS_SUCCESS: {
      const { payload } = action;
      return state
        .set('filterParams', payload)
        .set('isSubmitting', false)
        .set('filterParamsPhase', SUCCESS)
        .set('error', null);
    }
    case GET_FILTER_PARAMS_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('filterParamsPhase', ERROR)
        .set('isSubmitting', false);
    }

    case ADD_USER: {
      return state
        .set('addUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_USER_SUCCESS: {
      const { payload } = action;
      return state
        .set('addUserData', payload)
        .set('isSubmitting', false)
        .set('addUserPhase', SUCCESS)
        .set('addUserMessage', payload.message)
        .set('error', null);
    }
    case ADD_USER_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('addUserPhase', ERROR)
        .set('isSubmitting', false);
    }

    case ADD_STRATEGY: {
      return state
        .set('addStrategyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_STRATEGY_SUCCESS: {
      const { payload } = action;
      return state
        .set('addStrategyData', payload)
        .set('addStrategyPhase', SUCCESS)
        .set('error', null);
    }
    case ADD_STRATEGY_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('addStrategyPhase', ERROR)
        .set('isSubmitting', false);
    }

    case GET_STRATEGY: {
      return state
        .set('strategyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_STRATEGY_SUCCESS: {
      const { payload } = action;
      if (payload && payload.data && payload.data.length > 0) {
        var arr = [];
        var arr_id = [];
        try {
          payload.data.forEach(function(agent) {
            if (arr_id.length == 0) {
              if (!agent.town_names) {
                agent.town_names = [];
              }
              if (!agent.town_urls) {
                agent.town_data = [];
              }
              agent.town_names.push(agent.town_name);
              agent.town_data.push({
                town_name: agent.town_name,
                town_url: agent.town_url
              });
              arr.push(agent);
              arr_id.push(agent.agent_id);
            } else {
              var i = arr_id.indexOf(agent.agent_id);
              if (i > -1) {
                if (arr[i].town_name !== undefined) {
                  var a = arr[i].town_names.indexOf(agent.town_name);
                  if (a == -1) {
                    arr[i].town_names.push(agent.town_name);
                    arr[i].town_data.push({
                      town_name: agent.town_name,
                      town_url: agent.town_url
                    });
                  }
                }
              } else {
                if (!agent.town_names) {
                  agent.town_names = [];
                }
                if (!agent.town_urls) {
                  agent.town_data = [];
                }
                agent.town_names.push(agent.town_name);
                agent.town_data.push({
                  town_name: agent.town_name,
                  town_url: agent.town_url
                });
                arr.push(agent);
                arr_id.push(agent.agent_id);
              }
            }
          });
        } catch({ message }) {
        }
      }

      return state
        .set('strategyPhase', SUCCESS)
        .set('strategyData', payload)
        .set('chatAgent', arr)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_STRATEGY_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('strategyPhase', ERROR);
    }

    case DELETE_NOTES: {
      return state
        .set('deleteNotePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case DELETE_NOTES_SUCCESS: {
      const { payload } = action;
      return state
        .set('deleteNotePhase', SUCCESS)
        .set('deleteNoteData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case DELETE_NOTES_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('deleteNotePhase', ERROR);
    }

    case ADD_STRATEGY_APPOINTMENT: {
      return state
        .set('addStrategyAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_STRATEGY_APPOINTMENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('addStrategyAptPhase', SUCCESS)
        .set('addStrategyAptData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case ADD_STRATEGY_APPOINTMENT_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('addStrategyAptPhase', ERROR)
        .set('addAptMessage', payload.message)
        .set('isSubmitting', false);
    }

    case ADD_CLIENT_STRATEGY_APPOINTMENT: {
      return state
        .set('addClientStrategyAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_CLIENT_STRATEGY_APPOINTMENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('addClientStrategyAptPhase', SUCCESS)
        .set('addClientStrategyAptData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case ADD_CLIENT_STRATEGY_APPOINTMENT_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('addClientStrategyAptPhase', ERROR)
        .set('addClientStrAptMessage', payload.message)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_RECORDING_LINK: {
      return state
        .set('linkPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_CLIENT_RECORDING_LINK_SUCCESS: {
      const { payload } = action;
      return state
        .set('linkPhase', SUCCESS)
        .set('getRecordingLinksData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_CLIENT_RECORDING_LINK_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('linkPhase', ERROR)
        .set('isSubmitting', false);
    }

    case IMPERSONATE_USER: {
      return state
        .set('impersonatePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case IMPERSONATE_USER_SUCCESS: {
      const { payload } = action;
      return state
        .set('impersonatePhase', SUCCESS)
        .set('impersonateData', payload.data)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case IMPERSONATE_USER_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('impersonatePhase', ERROR);
    }

    case CLEAR_PHASE: {
      return state
        .set('dataPhase', INIT)
        .set('phase', INIT)
        .set('strategyPhase', INIT)
        .set('filterDataPhase', INIT)
        .set('filterClientsPhase', INIT)
        .set('addUpdateClientPhase', INIT)
        .set('tasksListPhase', INIT)
        .set('notesPhase', INIT)
        .set('addUpdateClientdata', INIT)
        .set('deleteClientdata', INIT)
        .set('addclientDealdata', INIT)
        .set('sendMynoteData', [])
        .set('sendClientMessagesData', INIT)
        .set('addUserPhase', INIT)
        .set('addStrategyPhase', INIT)
        .set('addUpdateClientPhase', INIT)
        .set('impersonatePhase', INIT)
        .set('sendBackgroundPhase', INIT)
        .set('junglerUserPhase', INIT)
        .set('addStrategyAptPhase', INIT)
        .set('strategistAvailabilityPhase', INIT)
        .set('deleteNotePhase', INIT)
        .set('filterParamsPhase', INIT)
        .set('agentAssToTownPhase', INIT)
        .set('agentRatingPhase', INIT)
        .set('strRatingPhase', INIT)
        .set('clientsPerMonthPhase', INIT)
    }

    case CLEAR_CLIENT_ACTIVITY_PHASE: {
      return (
        state
          .set('clientEmaildata', [])
          .set('strategyData', [])
          //.set('singleClientData', [])
          .set('chatAgent', [])
          .set('clientDealdata', [])
          .set('clientNotesdata', [])
          .set('getRecordingLinksData', [])
          .set('getClientMessagesData', [])
      );
    }
    case CLEAR_FILTER_PHASE: {
      return state
        .set('filterParamsPhase', INIT)
        .set('addClientStrategyAptPhase', INIT)
        .set('cancelAptPhase', INIT)
        .set('cancelStrAptPhase', INIT);
    }

    case GET_NO_AGENT_RATING: {
      return state
        .set('noAgentRatingPhase', LOADING)
        .set('noAgentRatingData', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_NO_AGENT_RATING_SUCCESS: {
      const { payload } = action;
      return state
        .set('noAgentRatingPhase', SUCCESS)
        .set('noAgentRatingData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_NO_AGENT_RATING_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('noAgentRatingPhase', ERROR);
    }

    case GET_AGENT_RATING: {
      return state
        .set('agentRatingPhase', LOADING)
        .set('agentRatingData', [])
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_AGENT_RATING_SUCCESS: {
      const { payload } = action;
      return state
        .set('agentRatingPhase', SUCCESS)
        .set('agentRatingData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_AGENT_RATING_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('agentRatingPhase', ERROR);
    }

    case GET_CLOSED_CLIENTS_PER_TOWN: {
      return state
        .set('closedClientPhase', LOADING)
        .set('closedClientData', [])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLOSED_CLIENTS_PER_TOWN_SUCCESS: {
      const { payload } = action
      return state
        .set('closedClientPhase', SUCCESS)
        .set('closedClientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLOSED_CLIENTS_PER_TOWN_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('closedClientPhase', ERROR)
    }

    case GET_AI_CLIENTS: {
      return state
        .set('aiClientPhase', LOADING)
        .set('aiClientData', [])
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AI_CLIENTS_SUCCESS: {
      const { payload } = action
      return state
        .set('aiClientPhase', SUCCESS)
        .set('aiClientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AI_CLIENTS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('aiClientPhase', ERROR)
    }

    case TASKS_LIST_USERS_SUCCESS : {
      const { payload } = action;
      return state
        .set('dataPhase', SUCCESS)
        .set('tasksListPhase', SUCCESS)
        .set('clientData', payload)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case TASKS_LIST_USERS_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('dataPhase', ERROR)
        .set('isSubmitting', false);
    }

    default: {
      return state
    }
  }
}

/**
 * Public: Action Creators
 */

export const getDashboardData = data => ({
  type: GET_DASHBOARD_DATA,
  payload: data
});

export const getClientList = data => ({
  type: GET_CLIENT_DATA,
  payload: data
});

export const saveAuthtoken = data => ({
  type: SAVE_AUTH_TOKEN,
  payload: data
});

export const saveAgentAuthtoken = data => ({
  type: SAVE_AGENT_AUTH_TOKEN,
  payload: data
});

export const cancelAppointment = data => ({
  type: CANCEL_APPOINTMENT,
  payload: data
});

export const cancelStrategistAppointment = data => ({
  type: CANCEL_STRATEGIST_APPOINTMENT,
  payload: data
});

export const getJunglerUsers = data => ({
  type: GET_JUNGLER_USERS_DATA,
  payload: data
});

export const getUSerProfileByID = data => ({
  type: GET_JUNGLER_USERBYID_DATA,
  payload: data
});

export const getUserProfileByIDEditProfile = data => ({
  type: GET_JUNGLER_USERBYID_DATA_EDIT,
  payload: api.getUSerProfileByID(data)
});


export const agentAssignedTown = data => ({
  type: GET_AGENTS_ASSIGNED_TO_TOWNS,
  payload: data
});

export const homeFilterClients = data => ({
  type: HOME_FILTER_CLIENTS,
  payload: data
});

export const getFilterParams = () => ({
  type: GET_FILTER_PARAMS
});

export const getUserbyId = data => ({
  type: GET_CLIENT_BYID_DATA,
  payload: data
});

export const getTowns = data => ({
  type: GET_TOWNS,
  payload: data
});

export const getTownsOptionBySuburbs = data => ({
  type: GET_TOWNS_OPTION_BY_SUBURBS,
  payload: api.getTowns(data)
});

export const addUsers = data => ({
  type: ADD_USER,
  payload: data
});

export const getClientEmail = data => ({
  type: GET_CLIENT_EMAIL_BYID_DATA,
  payload: data
});

export const getClientNotes = data => ({
  type: GET_CLIENT_NOTES_BYID_DATA,
  payload: data
});

export const sendMyNote = data => ({
  type: SEND_MY_NOTES_DATA,
  payload: data
});

export const getClientMessages = data => ({
  type: GET_CLIENT_MESSAGES_DATA,
  payload: data
});

export const sentClientMessage = data => ({
  type: SEND_CLIENT_MESSAGES_DATA,
  payload: data
});

export const sendBackgroundData = data => ({
  type: SEND_BACKGROUND_DATA,
  payload: data
});

export const getClientAgents = data => ({
  type: GET_CLIENT_AGENT_DATA,
  payload: data
});

export const getClientDeal = data => ({
  type: GET_CLIENT_DEAL_DATA,
  payload: data
});

export const addClientDeal = data => ({
  type: ADD_CLIENT_DEAL_DATA,
  payload: data
});

export const addUpdateClient = data => ({
  type: ADD_UPDATE_CLIENT_DATA,
  payload: data
});

export const deleteClient = data => ({
  type: DELETE_CLIENT_DATA,
  payload: data
});

export const getAllUsers = data => ({
  type: GET_ALL_USERS,
  payload: data
});

export const getRecordingLinks = data => ({
  type: GET_CLIENT_RECORDING_LINK,
  payload: data
});

export const clearUserError = () => ({
  type: CLEAR_USER_ERROR
});

export const clearPhase = () => {
  return {
    type: CLEAR_PHASE
  };
};

export const clearClientActivityPhase = () => {
  return {
    type: CLEAR_CLIENT_ACTIVITY_PHASE
  };
};

export const clearFilterPhase = () => {
  return {
    type: CLEAR_FILTER_PHASE
  };
};

export const getStrategy = data => ({
  type: GET_STRATEGY,
  payload: data
});

export const getTownAgent = data => ({
  type: GET_TOWN_AGENTS,
  payload: data
});

export const addStrategy = data => ({
  type: ADD_STRATEGY,
  payload: data
});

export const getStrategistApt = data => ({
  type: GET_STRATEGIST_APT,
  payload: data
});

export const getStrategistAvail = data => ({
  type: GET_STRATEGIST_AVAILABILITY,
  payload: data
});

export const addStrategistApt = data => ({
  type: ADD_STRATEGY_APPOINTMENT,
  payload: data
});

export const getTotalClientReceivePerMonth = data => ({
  type: GET_CLIENTS_PER_MONTHS,
  payload: data
});

export const addClientStragistApt = data => ({
  type: ADD_CLIENT_STRATEGY_APPOINTMENT,
  payload: data
});

export const impersonateUser = data => ({
  type: IMPERSONATE_USER,
  payload: data
});

export const updateScheduling = data => ({
  type: UPDATE_SCHEDULING,
  payload: data
});

export const sendGmailCode = data => ({
  type: SEND_GMAIL_CODE,
  payload: data
});

export const deleteNote = data => ({
  type: DELETE_NOTES,
  payload: data
});

export const getStrRatingData = data => ({
  type: GET_STR_RATING,
  payload: data
});

export const getNoAgentRatingData = data => ({
  type: GET_NO_AGENT_RATING,
  payload: data
});

export const getAgentRatingData = data => ({
  type: GET_AGENT_RATING,
  payload: data
});

export const getAIClientsData = (data) => ({
  type: GET_AI_CLIENTS,
  payload: data
})

export const tasksListUsers = data => ({
  type: TASKS_LIST_USERS,
  payload: data
});

/**
 * Private: Epics
 */

const getDashboardDataEpic = (action$) =>
  action$
  .ofType(GET_DASHBOARD_DATA)
  .mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getDashboardData(action.payload))
    .flatMap((payload) => ([{
      type: GET_DASHBOARD_DATA_SUCCESS,
      payload
    }]))
    .catch((error) => Rx.Observable.of({
      type: GET_DASHBOARD_DATA_ERROR,
      payload: { error }
    }))
  })

const getClientListEpic = (action$) =>
action$
.ofType(GET_CLIENT_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientList(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_DATA_ERROR,
    payload: { error }
  }))
})

const saveAuthtokenEpic = (action$) =>
action$
.ofType(SAVE_AUTH_TOKEN)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.saveAuthtoken(action.payload))
  .flatMap((payload) => ([{
    type: SAVE_AUTH_TOKEN_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SAVE_AUTH_TOKEN_ERROR,
    payload: { error }
  }))
})

const saveAgentAuthtokenEpic = (action$) =>
action$
.ofType(SAVE_AGENT_AUTH_TOKEN)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.saveAgentAuthtoken(action.payload))
  .flatMap((payload) => ([{
    type: SAVE_AGENT_AUTH_TOKEN_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SAVE_AGENT_AUTH_TOKEN_ERROR,
    payload: { error }
  }))
})

const getJunglerUsersEpic = (action$) =>
action$
.ofType(GET_JUNGLER_USERS_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getJunglerUsers(action.payload))
  .flatMap((payload) => ([{
    type: GET_JUNGLER_USERS_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_JUNGLER_USERS_DATA_ERROR,
    payload: { error }
  }))
})

const getUSerProfileByIDEpic = (action$) =>
action$
.ofType(GET_JUNGLER_USERBYID_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getUSerProfileByID(action.payload))
  .flatMap((payload) => ([{
    type: GET_JUNGLER_USERBYID_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_JUNGLER_USERBYID_DATA_ERROR,
    payload: { error }
  }))
})

const getClientEmailEpic = (action$) =>
action$
.ofType(GET_CLIENT_EMAIL_BYID_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientEmail(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_EMAIL_BYID_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_EMAIL_BYID_DATA_ERROR,
    payload: { error }
  }))
})

const getClientNotesEpic = (action$) =>
action$
.ofType(GET_CLIENT_NOTES_BYID_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientNotes(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_NOTES_BYID_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_NOTES_BYID_DATA_ERROR,
    payload: { error }
  }))
})

const sendMyNoteEpic = (action$) =>
action$
.ofType(SEND_MY_NOTES_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.sendMyNote(action.payload))
  .flatMap((payload) => ([{
    type: SEND_MY_NOTES_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SEND_MY_NOTES_DATA_ERROR,
    payload: { error }
  }))
})

const getClientMessagesEpic = (action$) =>
action$
.ofType(GET_CLIENT_MESSAGES_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientMessages(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_MESSAGES_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_MESSAGES_DATA_ERROR,
    payload: { error }
  }))
})

const sentClientMessageEpic = (action$) =>
action$
.ofType(SEND_CLIENT_MESSAGES_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.sentClientMessage(action.payload))
  .flatMap((payload) => ([{
    type: SEND_CLIENT_MESSAGES_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SEND_CLIENT_MESSAGES_DATA_ERROR,
    payload: { error }
  }))
})

const sendBackgroundDataEpic = (action$) =>
action$
.ofType(SEND_BACKGROUND_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.sendBackgroundData(action.payload))
  .flatMap((payload) => ([{
    type: SEND_BACKGROUND_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SEND_BACKGROUND_DATA_ERROR,
    payload: { error }
  }))
})

const getUserbyIdEpic = (action$) =>
action$
.ofType(GET_CLIENT_BYID_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getUserbyId(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_BYID_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_BYID_DATA_ERROR,
    payload: { error }
  }))
})

const homeFilterClientsEpic = (action$) =>
action$
.ofType(HOME_FILTER_CLIENTS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.homeFilterClients(action.payload))
  .flatMap((payload) => ([{
    type: HOME_FILTER_CLIENTS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: HOME_FILTER_CLIENTS_ERROR,
    payload: { error }
  }))
})

const getFilterParamsEpic = (action$) =>
action$
.ofType(GET_FILTER_PARAMS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getFilterParams(action.payload))
  .flatMap((payload) => ([{
    type: GET_FILTER_PARAMS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_FILTER_PARAMS_SUCCESS,
    payload: { error }
  }))
})


const getTownsEpic = (action$) =>
action$
.ofType(GET_TOWNS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getTowns(action.payload))
  .flatMap((payload) => ([{
    type: GET_TOWNS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_TOWNS_ERROR,
    payload: { error }
  }))
})

const addUsersEpic = (action$) =>
action$
.ofType(ADD_USER)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addUsers(action.payload))
  .flatMap((payload) => ([{
    type: ADD_USER_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_USER_ERROR,
    payload: { error }
  }))
})

const getClientAgentsEpic = (action$) =>
action$
.ofType(GET_CLIENT_AGENT_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientAgents(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_AGENT_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_AGENT_DATA_ERROR,
    payload: { error }
  }))
})

const getClientDealEpic = (action$) =>
action$
.ofType(GET_CLIENT_DEAL_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClientDeal(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_DEAL_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_DEAL_DATA_ERROR,
    payload: { error }
  }))
})


const addUpdateClientEpic = (action$) =>
action$
.ofType(ADD_UPDATE_CLIENT_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addUpdateClient(action.payload))
  .flatMap((payload) => ([{
    type: ADD_UPDATE_CLIENT_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_UPDATE_CLIENT_DATA_ERROR,
    payload: { error }
  }))
})

const addClientDealEpic = (action$) =>
action$
.ofType(ADD_CLIENT_DEAL_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addClientDeal(action.payload))
  .flatMap((payload) => ([{
    type: ADD_CLIENT_DEAL_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_CLIENT_DEAL_DATA_ERROR,
    payload: { error }
  }))
})

const deleteClientEpic = (action$) =>
action$
.ofType(DELETE_CLIENT_DATA)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.deleteClient(action.payload))
  .flatMap((payload) => ([{
    type: DELETE_CLIENT_DATA_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: DELETE_CLIENT_DATA_ERROR,
    payload: { error }
  }))
})


const getStrategyEpic = (action$) =>
action$
.ofType(GET_STRATEGY)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategy(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGY_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGY_ERROR,
    payload: { error }
  }))
})

const getTownAgentEpic = (action$) =>
action$
.ofType(GET_TOWN_AGENTS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getTownAgent(action.payload))
  .flatMap((payload) => ([{
    type: GET_TOWN_AGENTS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_TOWN_AGENTS_ERROR,
    payload: { error }
  }))
})

const addStrategyEpic = (action$) =>
action$
.ofType(ADD_STRATEGY)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addStrategy(action.payload))
  .flatMap((payload) => ([{
    type: ADD_STRATEGY_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_STRATEGY_ERROR,
    payload: { error }
  }))
})

const getStrategistAptEpic = (action$) =>
action$
.ofType(GET_STRATEGIST_APT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategistApt(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGIST_APT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGIST_APT_ERROR,
    payload: { error }
  }))
})

const getStrategistAvailEpic = (action$) =>
action$
.ofType(GET_STRATEGIST_AVAILABILITY)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrategistAvail(action.payload))
  .flatMap((payload) => ([{
    type: GET_STRATEGIST_AVAILABILITY_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STRATEGIST_AVAILABILITY_ERROR,
    payload: { error }
  }))
})

const addStrategistAptEpic = (action$) =>
action$
.ofType(ADD_STRATEGY_APPOINTMENT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addStrategistApt(action.payload))
  .flatMap((payload) => ([{
    type: ADD_STRATEGY_APPOINTMENT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_STRATEGY_APPOINTMENT_ERROR,
    payload: { error }
  }))
})

const addClientStragistAptEpic = (action$) =>
action$
.ofType(ADD_CLIENT_STRATEGY_APPOINTMENT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.addClientStragistApt(action.payload))
  .flatMap((payload) => ([{
    type: ADD_CLIENT_STRATEGY_APPOINTMENT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: ADD_CLIENT_STRATEGY_APPOINTMENT_ERROR,
    payload: { error }
  }))
})

const getRecordingLinksEpic = (action$) =>
action$
.ofType(GET_CLIENT_RECORDING_LINK)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getRecordingLinks(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENT_RECORDING_LINK_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENT_RECORDING_LINK_ERROR,
    payload: { error }
  }))
})

const getAllUsersEpic = (action$) =>
action$
.ofType(GET_ALL_USERS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAllUsers(action.payload))
  .flatMap((payload) => ([{
    type: GET_ALL_USERS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_ALL_USERS_ERROR,
    payload: { error }
  }))
})

const impersonateUserEpic = (action$) =>
action$
.ofType(IMPERSONATE_USER)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.impersonateUser(action.payload))
  .flatMap((payload) => ([{
    type: IMPERSONATE_USER_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: IMPERSONATE_USER_ERROR,
    payload: { error }
  }))
})

const updateSchedulingEpic = (action$) =>
action$
.ofType(UPDATE_SCHEDULING)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.updateScheduling(action.payload))
  .flatMap((payload) => ([{
    type: UPDATE_SCHEDULING_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: UPDATE_SCHEDULING_ERROR,
    payload: { error }
  }))
})

const sendGmailCodeEpic = (action$) =>
action$
.ofType(SEND_GMAIL_CODE)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.sendGmailCode(action.payload))
  .flatMap((payload) => ([{
    type: SEND_GMAIL_CODE_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: SEND_GMAIL_CODE_ERROR,
    payload: { error }
  }))
})

const deleteNoteEpic = (action$) =>
action$
.ofType(DELETE_NOTES)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.deleteNote(action.payload))
  .flatMap((payload) => ([{
    type: DELETE_NOTES_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: DELETE_NOTES_ERROR,
    payload: { error }
  }))
})

const cancelAppointmentEpic = (action$) =>
action$
.ofType(CANCEL_APPOINTMENT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.cancelAppointment(action.payload))
  .flatMap((payload) => ([{
    type: CANCEL_APPOINTMENT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: CANCEL_APPOINTMENT_ERROR,
    payload: { error }
  }))
})

const cancelStrategistAppointmentEpic = (action$) =>
action$
.ofType(CANCEL_STRATEGIST_APPOINTMENT)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.cancelStrategistAppointment(action.payload))
  .flatMap((payload) => ([{
    type: CANCEL_STRATEGIST_APPOINTMENT_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: CANCEL_STRATEGIST_APPOINTMENT_ERROR,
    payload: { error }
  }))
})

const getStrRatingDataEpic = (action$) =>
action$
.ofType(GET_STR_RATING)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getStrRatingData(action.payload))
  .flatMap((payload) => ([{
    type: GET_STR_RATING_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_STR_RATING_ERROR,
    payload: { error }
  }))
})

const getNoAgentRatingDataEpic = (action$) =>
action$
.ofType(GET_NO_AGENT_RATING)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getNoAgentRatingData(action.payload))
  .flatMap((payload) => ([{
    type: GET_NO_AGENT_RATING_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_NO_AGENT_RATING_ERROR,
    payload: { error }
  }))
})

const getAgentRatingDataEpic = (action$) =>
action$
.ofType(GET_AGENT_RATING)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAgentRatingData(action.payload))
  .flatMap((payload) => ([{
    type: GET_AGENT_RATING_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_AGENT_RATING_ERROR,
    payload: { error }
  }))
})

const agentAssignedTownEpic = (action$) =>
action$
.ofType(GET_AGENTS_ASSIGNED_TO_TOWNS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.agentAssignedTown(action.payload))
  .flatMap((payload) => ([{
    type: GET_AGENTS_ASSIGNED_TO_TOWNS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_AGENTS_ASSIGNED_TO_TOWNS_ERROR,
    payload: { error }
  }))
})

const getTotalClientReceivePerMonthEpic = (action$) =>
action$
.ofType(GET_CLIENTS_PER_MONTHS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getTotalClientReceivePerMonth(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLIENTS_PER_MONTHS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLIENTS_PER_MONTHS_ERROR,
    payload: { error }
  }))
})

const getClosedClientPerTownEpic = (action$) =>
action$
.ofType(GET_CLOSED_CLIENTS_PER_TOWN)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getClosedClientsData(action.payload))
  .flatMap((payload) => ([{
    type: GET_CLOSED_CLIENTS_PER_TOWN_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_CLOSED_CLIENTS_PER_TOWN_ERROR,
    payload: { error }
  }))
})

const getAIClientsEpic = (action$) =>
action$
.ofType(GET_AI_CLIENTS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getAIClientsData(action.payload))
  .flatMap((payload) => ([{
    type: GET_AI_CLIENTS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: GET_AI_CLIENTS_ERROR,
    payload: { error }
  }))
})

const getTasksListUsersEpic = (action$) =>
action$
.ofType(TASKS_LIST_USERS)
.mergeMap((action) => {
  return Rx.Observable.fromPromise(api.getTasksList(action.payload))
  .flatMap((payload) => ([{
    type: TASKS_LIST_USERS_SUCCESS,
    payload
  }]))
  .catch((error) => Rx.Observable.of({
    type: TASKS_LIST_USERS_ERROR,
    payload: { error }
  }))
})

/**
 * Public: Export Epics
 */

export const dashboardEpic = combineEpics(
  getDashboardDataEpic,
  getClientListEpic,
  saveAuthtokenEpic,
  saveAgentAuthtokenEpic,
  getFilterParamsEpic,
  homeFilterClientsEpic,
  getJunglerUsersEpic,
  getUSerProfileByIDEpic,
  getUserbyIdEpic,
  getTownsEpic,
  addUsersEpic,
  getClientEmailEpic,
  getClientNotesEpic,
  sendMyNoteEpic,
  sendBackgroundDataEpic,
  getStrategyEpic,
  getTownAgentEpic,
  getClientAgentsEpic,
  getClientDealEpic,
  addClientDealEpic,
  addStrategyEpic,
  getStrategistAptEpic,
  addUpdateClientEpic,
  deleteClientEpic,
  getClientMessagesEpic,
  sentClientMessageEpic,
  getUserbyIdEpic,
  getStrategistAvailEpic,
  addStrategistAptEpic,
  addClientStragistAptEpic,
  getRecordingLinksEpic,
  getAllUsersEpic,
  impersonateUserEpic,
  updateSchedulingEpic,
  sendGmailCodeEpic,
  deleteNoteEpic,
  cancelAppointmentEpic,
  cancelStrategistAppointmentEpic,
  getStrRatingDataEpic,
  getNoAgentRatingDataEpic,
  agentAssignedTownEpic,
  getTotalClientReceivePerMonthEpic,
  getAgentRatingDataEpic,
  getClosedClientPerTownEpic,
  getAIClientsEpic,
  getTasksListUsersEpic
)
