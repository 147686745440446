import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import SideNavigation from '../../components/sideNavigation'
import TopNavigation from '../TopNavigation/container'
import styles from './styles.scss'
import moment from 'moment'
import timezone from 'moment-timezone'
import {formatPhoneNumber} from '../../config'
import Cookies from 'universal-cookie'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery'
import {getHostname} from "../../utils/helper";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()

let _user = ''
let pre_Auth = ''

const HOSTNAME = process.env.API_HOSTNAME
class JunglerUsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      user_role:'any',
      user_limit: 20,
      user_offset:1,
      totalPages: 0,
      activePage:1,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingData: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      pre_Auth: '',
      sortByKey: '',
      sortByType : 0,
      
    }
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handelUserfilter = this.handelUserfilter.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this) 
  }

  componentWillMount(){
    window.scrollTo(0, 0);
    _user = cookies.get('user')
    if(_user) {
      if(_user.role ==="agent" || _user.role ==="local" ||  _user.role ==="client"){
        this.props.history.push({ pathname: '/login'})
      }
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      setTimeout(()=>{
      var localusersFiletrObj = JSON.parse(localStorage.getItem('usersFiletrObj'));
      if(localusersFiletrObj && localusersFiletrObj !=null && localusersFiletrObj !=''){
          $('.filter__container--button').removeClass('active');
          this.setState(localusersFiletrObj, ()=>{
            if( this.state.user_role === "business development"){
              let btnName = 'development'
              $( 'button[ name='+btnName+']' ).addClass( 'active' );
            }else{
              $( 'button[ name='+this.state.user_role+']' ).addClass( 'active' );
            }
            this.props.getJunglerUsers(localusersFiletrObj)
          })
      }else{
        const usersFiletrObj = {}
        usersFiletrObj.user_role = this.state.user_role;
        usersFiletrObj.user_limit = this.state.user_limit;
        usersFiletrObj.user_offset = this.state.user_offset;
        usersFiletrObj.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
        localStorage.setItem('usersFiletrObj', JSON.stringify(usersFiletrObj))
        this.props.getJunglerUsers(usersFiletrObj)
      }
      }, 300)
      // if(this.props.usersData && this.props.usersData.user_counts){
      //   let pagecount = nextProps.usersData.user_counts.all/this.state.user_limit;
      //   this.setState({totalPages:Math.round(pagecount)})
      // }
      
    }
    pre_Auth = cookies.get('i_user')
    if(pre_Auth){
      this.setState({pre_Auth: pre_Auth})
    }
  }

  componentDidMount(){
    document.title = "Jungler: Jungler Users";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    setTimeout(function(){
      const usersFiletrObj = {}
      usersFiletrObj.user_role = this.state.user_role;
      usersFiletrObj.user_limit = this.state.user_limit;
      usersFiletrObj.user_offset = this.state.activePage;
      usersFiletrObj.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.getJunglerUsers(usersFiletrObj)
    }.bind(this), 500)
  }


  handleEntryChange(event){
    this.setState({user_limit: event.target.value});
    setTimeout(function(){
      const usersFiletrObj = {}
      usersFiletrObj.user_role = this.state.user_role;
      usersFiletrObj.user_limit = this.state.user_limit;
      usersFiletrObj.user_offset = this.state.user_offset;
      usersFiletrObj.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      localStorage.setItem('usersFiletrObj', JSON.stringify(usersFiletrObj))
      this.props.getJunglerUsers(usersFiletrObj)
    }.bind(this), 500)
  }


  
  handelUserfilter(btn) {
  this.setState({ isLoadingData: true})
  $('.filter__container--button').removeClass('active');
  if(btn.target.name !==''){
    $( 'button[ name='+btn.target.name+']' ).addClass( 'active' );
    
    switch(btn.target.name){
      case 'admin':
          this.setState({user_role:'admin'});
          if(this.props.usersData && this.props.usersData.user_counts){
            this.setState({totalPages:Math.round(this.props.usersData.user_counts.admin)})
          }
          break;
      case 'agent':
          this.setState({user_role:'agent'});
          if(this.props.usersData && this.props.usersData.user_counts){
            this.setState({totalPages:Math.round(this.props.usersData.user_counts.agent)})
          }
          break;
      case 'strategist':
          this.setState({user_role:'strategist'});
          if(this.props.usersData && this.props.usersData.user_counts){
            this.setState({totalPages:Math.round(this.props.usersData.user_counts.strategist)})
          }
          break;
      case 'local':
        this.setState({user_role:'local'});
        if(this.props.usersData && this.props.usersData.user_counts){
          this.setState({totalPages:Math.round(this.props.usersData.user_counts.local)})
        }
          break;
      case 'development':
        this.setState({user_role:'business development'});
        if(this.props.usersData && this.props.usersData.user_counts){
          this.setState({totalPages:Math.round(this.props.usersData.user_counts.business_development)})
        }
          break;
      case 'all':
        this.setState({user_role:'any'});
        if(this.props.usersData && this.props.usersData.user_counts){
          this.setState({totalPages:Math.round(this.props.usersData.user_counts.all)})
        }
          break;
      default:
        this.setState({user_role:'any'});
        if(this.props.usersData && this.props.usersData.user_counts){
          this.setState({totalPages:Math.round(this.props.usersData.user_counts.all)})
        }
    }
  }else{
    this.setState({user_role:'any'})
  }
  setTimeout(function(){
    const usersFiletrObj = {}
    usersFiletrObj.user_role = this.state.user_role;
    usersFiletrObj.user_limit = this.state.user_limit;
    usersFiletrObj.user_offset = this.state.activePage;
    usersFiletrObj.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
    localStorage.setItem('usersFiletrObj', JSON.stringify(usersFiletrObj))
    this.props.getJunglerUsers(usersFiletrObj)
  }.bind(this), 500)
     
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();

    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }


  componentWillReceiveProps(nextProps) {
    this.setState({ isLoadingData: true})
    let self = this

    if(nextProps.usersData && nextProps.usersData.user_counts){
      let pagecount = nextProps.usersData.user_counts.all/this.state.user_limit;
      if(this.state.user_role ==='any'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.all)})
      }
      if(this.state.user_role ==='admin'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.admin)})
      }
      if(this.state.user_role ==='local'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.local)})
      }
      if(this.state.user_role ==='business development'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.business_development)})
      }
      if(this.state.user_role ==='strategist'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.strategist)})
      }
      if(this.state.user_role ==='agent'){
        this.setState({totalPages:Math.round(nextProps.usersData.user_counts.agent)})
      }
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false})
    }
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    } else if (nextProps.profilePhase === "success") {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile info saved successfully."})
      if(nextProps.isSubmitting !=false && nextProps.dataPhase !=="error"){
          if(this.state.userid !=""){
            const data = {}
            data.user_id = this.state.userid;
            this.props.fetchUser(data)
          }
      }
      if(nextProps.impersonatePhase === "success"){
        if(nextProps.impersonateData && nextProps.impersonateData.magic_link){
          let i_user = cookies.get('user')
          let i_Authorization = cookies.get('Authorization')
          let expires = new Date()
          localStorage.clear();
          //expires.setHours(expires.getHours() + 24 )
          expires.setMonth(expires.getMonth()+2);
          expires = new Date(expires)
          cookies.set('i_Authorization',i_Authorization, { path: '/', expires });
          cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires });
          let self = this;
          setTimeout(function() {
            let magic_link = nextProps.impersonateData.magic_link;
            let pathname = "client-login/token?validate="+magic_link
            self.props.clearPhase()
            document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            localStorage.clear();
            self.props.history.push({'pathname' : pathname })
          }, 1500);
        }else{
          alert('Not have enough access')
        }
      }
    }

    if (nextProps.profilePhase === "success") {
      if(nextProps.user.user) {
        let user = nextProps.user.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone
        })
      }
    }
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: false , sortByKey: val, sortByType: sortByType},()=>{
    const usersFiletrObj = {}
    usersFiletrObj.user_role = this.state.user_role;
    usersFiletrObj.user_limit = this.state.user_limit;
    usersFiletrObj.user_offset = this.state.activePage;
    usersFiletrObj.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
    this.props.getJunglerUsers(usersFiletrObj)
    })
  }

  impersonateUser(user_id){
    let data = {}
    data.client_id = user_id;
    this.props.impersonateUser(data)
  }


  handleRequestClose() {
    this.setState({
      openSnackbar: false,
      errMessage: ''
    })
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      usersData,
      phase
    } = this.props
    let currentuser = user && user;
    let impersonateUser = false 
    impersonateUser = _user.role == "strategist" && currentuser.impersonate_strategist_count > 0 && this.state.user_role == 'strategist' ? true : false 

    
    return (
      <div>
      {
        (pre_Auth)?
        <div className="strategist_top">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
        :''
      }
        <TopNavigation {...this.props}/>
        <div id="wrapper">
          <SideNavigation {...this.props}/>
          <div id="page-content-wrapper" className={ pre_Auth ? "impr__junglerusers" : "without__impr--junglerusers"}>
            <div className="">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <main className="jungler__page--container padding-left">

                          <div className="filter__container filter__container--users">
                            <div className="filter__container--filter-name rem-flex">
                            
                            <div className="col-md-9 rem-left overFlow__filter">
                              <button name='all' className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>All Users ({usersData && usersData.user_counts && usersData.user_counts.all})</button>
                              {currentuser && currentuser.role ==='superadmin' ?
                              <button name='admin' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Admin ({usersData && usersData.user_counts && usersData.user_counts.admin})</button>
                              : ''}
                              <button name='agent' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Agent ({usersData && usersData.user_counts && usersData.user_counts.agent})</button>
                              {
                                (_user.role !== "strategist")?(
                                    <button name='strategist' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Strategist ({usersData && usersData.user_counts && usersData.user_counts.strategist})</button>
                                ):(_user.role == "strategist" && currentuser.impersonate_strategist_count > 0)?(
                                    <button name='strategist' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Strategist ({usersData && usersData.user_counts && usersData.user_counts.strategist})</button>
                                  ) : ''
                              }
                              
                              <button name='local' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Local ({usersData && usersData.user_counts && usersData.user_counts.local})</button>
                              <button name='development' className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Business Development ({usersData && usersData.user_counts && usersData.user_counts.business_development})</button>
                              </div>

                              <div className="col-md-3 text-right">
                              <div>
                              <span className="enteries-span">
                              <label className="enteries-label">Entries</label>
                                <select value={this.state.user_limit} onChange={this.handleEntryChange.bind(this)}>
                                  {config.entries && config.entries.map((entry, entryindx) => {
                                    return (
                                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                                    )
                                  })}
                                </select>
                              </span>  
                              </div>
                              </div>
                            </div>
                          </div>

                           <div className="jungler__list useslist__extra">
                           <div className="table-responsive">
                                 <table className="table table-striped font__size--update">
                                    <thead className="table__td--desktop">
                                      <tr>
                                        <th onClick={this.sortData.bind(this , 'userName')}>Full Name</th>
                                        <th onClick={this.sortData.bind(this , 'role')}>Role</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th onClick={this.sortData.bind(this , 'created')}>Created</th>
                                        {(_user.role == "strategist" && currentuser.impersonate_strategist_count > 0) ? '' : <th></th>}
                                      </tr>
                                    </thead>
                                    {this.state.isLoadingData === true ? 
                                      <tbody>
                                      <tr>
                                        <td colSpan="5" className="noClientResults">
                                        <img src="../../img/loader2.svg" />
                                        </td>
                                        </tr>
                                      </tbody>
                                     :
                                    <tbody>
                                     {usersData && usersData.users && usersData.users.length >0 && usersData.users.map((userval, userindex) => {
                                      let singleUserObj = userval;
                                      return(
                                        <tr className="forcursor" key={userindex}>
                                        {
                                          impersonateUser ? (
                                            <td>
                                            {userval.full_name}
                                            <span className="showschedule__mob">{userval.role}</span>
                                            </td>
                                          ) : (
                                            <td onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})}}>
                                            {userval.full_name}
                                            <span className="showschedule__mob">{userval.role}</span>
                                            </td>
                                          )
                                        }

                                        {
                                          impersonateUser ? (
                                            <td className="table__td--desktop">
                                            {userval.role}</td>
    
                                          ) : (
                                            <td className="table__td--desktop" onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})}}>
                                            {userval.role}</td>
                                          )
                                        }

                                        {
                                          impersonateUser ? (
                                            <td className="table__td--desktop">
                                              {userval.email} </td>
                                          ) : (
                                            <td className="table__td--desktop" onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})}}>
                                              {userval.email} </td>
                                          )
                                        }
                                        
                                        {
                                          impersonateUser ? (
                                            <td className="singleLine table__td--desktop" >
                                            {formatPhoneNumber(userval.phone_mobile)}</td>
                                          ) : (
                                            <td className="singleLine table__td--desktop" onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})}}>
                                            {formatPhoneNumber(userval.phone_mobile)}</td>
                                          )
                                        }
                                        
                                        {
                                          impersonateUser ? (
                                            <td className="table__td--desktop" > 
                                            {moment(userval.created).format('MM/DD/YYYY')}</td>
                                          ) : (
                                            <td className="table__td--desktop" onClick={() => { this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})}}> 
                                              {moment(userval.created).format('MM/DD/YYYY')}</td>
                                          )
                                        }
                                        
                                        {
                                          (_user.role == "strategist" && currentuser.impersonate_strategist_count > 0 && this.state.user_role == 'strategist') ? '' : (
                                            <td className="table__td--desktop">
                                              <button className="btn btn__black--outline btn__profile btn__impersonate" onClick={() => 
                                                { 
                                                  this.props.history.push({ pathname: 'add-jungler-user', state: singleUserObj})
                                                  }}  >Edit User Profile
                                              </button>
                                            </td>
                                          )
                                        }
                                        
                                        <td className="right__in--mob">
                                          {currentuser && currentuser.role && (currentuser.role ==='superadmin' || currentuser.role ==='admin' || currentuser.role ==='strategist') ?
                                            <div>
                                            {(userval.role !== "former-agent")?(
                                              <button 
                                                className="btn btn__green--outline btn__profile" 
                                                onClick={this.impersonateUser.bind(this, userval.id)}>Impersonate User
                                              </button>
                                              ):''
                                            }
                                            </div>
                                          : ''
                                          }
                                        </td>
                                      </tr>
                                      )
                                     })
                                     } 
                                    </tbody>
                                  }
                                </table>
                               </div>
                           </div>
                        </main>
                        <div className="pagination-container">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.user_limit}
                          totalItemsCount={this.state.totalPages}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'jungleruserslist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(JunglerUsersList)
