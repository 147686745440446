import { connect } from 'react-redux'

import ActivityHistoryComponent from './component'

const ActivityHistoryContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(ActivityHistoryComponent)

export default ActivityHistoryContainer
