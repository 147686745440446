import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import config from ".././../../../config";
import Select from "react-select";
import "react-select/dist/react-select.css";
import styles from "./styles.scss";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Calendar from 'react-calendar'
import Modal from 'react-modal'
const cookies = new Cookies();

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}
      >
        {this.props.value}
      </button>
    );
  }
}

const customStyles2 = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '450px',
    padding: '24px 24px',
    maxHeight: "95%"
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      openSnackbar: false,
      errMessage: "",
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      userid: "",
      success: "",
      role: "",
      timezone: "",
      client_status: "my_clients",
      client_type: "new_clients",
      totalPages: 0,
      activePage: 1,
      client_limit: 100,
      client_offset: 1,
      isLoadingData: false,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      sortByKey: "",
      sortByType: 0,
      currentU_user_timezone: "",
      calendarIsOpen: false,
      selectedUserData: [],
      selectedDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handelUserfilter = this.handelUserfilter.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      this.setState({
        userid: _user.id,
        currentU_user_timezone: _user.time_zone
      });
      if (_user.role === "superadmin" || _user.role === "admin") {
        this.setState({ client_status: "all_clients" });
      }
      const data = {};
      data.user_id = _user.id;
      this.props.fetchUser(data);
      setTimeout(
        function() {
          var locaclientdata = JSON.parse(
            localStorage.getItem("homeclientdata")
          );
          if (
            locaclientdata &&
            locaclientdata != null &&
            locaclientdata != ""
          ) {
            $(".filter__container--button").removeClass("active");
            this.setState(locaclientdata, () => {
              var getname = this.state.client_status
                .toLowerCase()
                .replace(" ", "_")
                .replace(" ", "_")
                .replace(" ", "_")
                .replace(" ", "_");
              $("button[ name=" + getname + "]").addClass("active");
              this.props.homeFilterClients(locaclientdata);
            });
          } else {
            const clientdata = {};
            clientdata.client_type = this.state.client_type;
            clientdata.client_status = this.state.client_status;
            clientdata.client_limit = this.state.client_limit;
            clientdata.client_offset = this.state.client_offset;
            clientdata.sortBy = {
              key: this.state.sortByKey,
              type: this.state.sortByType
            };
            localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
            this.props.homeFilterClients(clientdata);
          }
        }.bind(this),
        300
      );
    }
  }

  componentDidMount() {
    document.title = "Jungler: New Clients";
  }

  componentWillUnmount() {
    document.title = "Suburban Jungle";
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        this.props.homeFilterClients(clientdata);
      }.bind(this),
      500
    );
  }

  handleEntryChange(event) {
    this.setState({ client_limit: event.target.value, activePage: 1 });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = 1;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        this.props.homeFilterClients(clientdata);
        localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
      }.bind(this),
      500
    );
  }

  handelUserfilter(btn) {
    this.setState({ isLoadingData: true });
    $(".filter__container--button").removeClass("active");
    if (btn.target.name !== "") {
      $("button[ name=" + btn.target.name + "]").addClass("active");

      switch (btn.target.name) {
        case "my_clients":
          this.setState({ client_status: "my_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.my_clients
              )
            });
          }
          break;
        case "new_not_scheduled":
          this.setState({ client_status: "New Not Scheduled" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.new_not_scheduled
              )
            });
          }
          break;
        case "call_scheduled":
          this.setState({ client_status: "Call Scheduled" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.call_scheduled
              )
            });
          }
          break;
        case "trying_to_schedule":
          this.setState({ client_status: "Trying To Schedule" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.trying_to_schedule
              )
            });
          }
          break;
        case "reschedule":
          this.setState({ client_status: "Reschedule" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.reschedule
              )
            });
          }
          break;
        case "all":
          this.setState({ client_status: "all_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.all_clients
              )
            });
          }
          break;
        default:
          this.setState({ client_status: "all_clients" });
          if (this.props.clientData && this.props.clientData.user_counts) {
            this.setState({
              totalPages: Math.round(
                this.props.clientData.user_counts.all_clients
              )
            });
          }
      }
    } else {
      this.setState({ client_status: "all_clients" });
    }
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = 1;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        this.props.homeFilterClients(clientdata);
        localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
      }.bind(this),
      500
    );
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    setTimeout(
      function() {
        const clientdata = {};
        clientdata.client_type = this.state.client_type;
        clientdata.client_status = this.state.client_status;
        clientdata.client_limit = this.state.client_limit;
        clientdata.client_offset = this.state.activePage;
        clientdata.sortBy = {
          key: this.state.sortByKey,
          type: this.state.sortByType
        };
        this.props.homeFilterClients(clientdata);
        localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
      }.bind(this),
      500
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isLoadingData: true });
    if (nextProps.clientData && nextProps.clientData.user_counts) {
      if (this.state.client_status === "all_clients") {
        this.setState({
          totalPages: Math.round(nextProps.clientData.user_counts.all_clients)
        });
      } else if (this.state.client_status === "my_clients") {
        this.setState({
          totalPages: Math.round(nextProps.clientData.user_counts.my_clients)
        });
      } else if (this.state.client_status == "Call Scheduled") {
        this.setState({
          totalPages: Math.round(
            nextProps.clientData.user_counts.call_scheduled
          )
        });
      } else if (this.state.client_status == "Trying To Schedule") {
        this.setState({
          totalPages: Math.round(
            nextProps.clientData.user_counts.trying_to_schedule
          )
        });
      } else if (this.state.client_status == "Reschedule") {
        this.setState({
          totalPages: Math.round(nextProps.clientData.user_counts.reschedule)
        });
      }
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false });
    }

    if (nextProps.addUpdateClientPhase === "success") {
      const clientdata = {};
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {
        key: this.state.sortByKey,
        type: this.state.sortByType
      };
      this.props.homeFilterClients(clientdata);
      localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
      this.props.clearPhase();
    }
  }

  handleDateChange(cData, date) {
    let selectDate = moment(date).format("YYYY-MM-DD");
    let data = {};
    data.follow_up_date_req = selectDate;
    data.id = cData.client_id;
    this.props.addUpdateClient(data);
  }

  sortData(val) {
    let sortByType = this.state.sortByType;
    if (val !== this.state.sortByKey) {
      sortByType = 0;
    } else {
      sortByType === 0 ? (sortByType = 1) : (sortByType = 0);
    }
    this.setState({
      isLoadingData: true,
      sortByKey: val,
      sortByType: sortByType
    });
    const clientdata = {};
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = { key: val, type: sortByType };
    localStorage.setItem("homeclientdata", JSON.stringify(clientdata));
    this.props.homeFilterClients(clientdata);
  }

  openCalendarModalIcon(cData, date){
    let finalDateSelect = ''
    if(cData.follow_up_date){
      let followUpDate = cData.follow_up_date
      let a = followUpDate.substring(0, followUpDate.indexOf('.'))
      finalDateSelect = followUpDate
    }else{
      finalDateSelect = new Date()
    }
    this.setState({ calendarIsOpen: true, selectedUserData: cData, selectedDate: new Date(finalDateSelect) })
  }

  afterOpenModal() {

  }

  closeModal() {
    // this.setState({calendarIsOpen: false, selectedUserData: []})
  }

  closeCalendarModalIcon(e){
    this.setState({ calendarIsOpen: false, selectedUserData: [] })
  }

  onSubmitDateRange(e){
    let changeFormat = new Date(this.state.selectedDate).toISOString() //change into iso string format
    let selectDate =  moment(changeFormat).format("YYYY-MM-DD")
    let data = {};
    data.follow_up_date_req = selectDate;
    data.id = this.state.selectedUserData.client_id;
    this.props.addUpdateClient(data);
    this.setState({ calendarIsOpen: false, selectedUserData: [] })
  }

  changeMonthDate(date){
    this.setState({ selectedDate: date })
  }

  render() {
    const { user, clientData } = this.props;
    let AllClients =
      clientData.user_counts && clientData.user_counts.all_clients
        ? clientData.user_counts.all_clients
        : 0;
    let AssignedToMe =
      clientData.user_counts && clientData.user_counts.my_clients
        ? clientData.user_counts.my_clients
        : 0;
    let NewNotScheduled =
      clientData.user_counts && clientData.user_counts.new_not_scheduled
        ? clientData.user_counts.new_not_scheduled
        : 0;
    let TryingtoSchedule =
      clientData.user_counts && clientData.user_counts.trying_to_schedule
        ? clientData.user_counts.trying_to_schedule
        : 0;
    let CallScheduled =
      clientData.user_counts && clientData.user_counts.call_scheduled
        ? clientData.user_counts.call_scheduled
        : 0;
    let Reschedule =
      clientData.user_counts && clientData.user_counts.reschedule
        ? clientData.user_counts.reschedule
        : 0;
    return (
      <div>
        <main className="clients__section">
          <div
            className="filter__container desktopFilter"
            style={{ display: "block" }}
          >
            <div className="filter__container--filter-name rem-flex">
              <div className="col-md-9 overFlow__filter p_l--rem margin__btm--20">
                {user && user.role != "strategist" && user.role != "local" ? (
                  <button
                    name="all_clients"
                    className={
                      this.state.client_status === "all_clients"
                        ? "btn filter__container--button active"
                        : "btn filter__container--button"
                    }
                    onClick={this.handelUserfilter.bind(this)}
                  >
                    All Clients ({AllClients})
                  </button>
                ) : (
                  ""
                )}
                {user &&
                user.role != "superadmin" &&
                user.role != "strategist" &&
                user.role != "local" ? (
                  <button
                    name="my_clients"
                    className={
                      this.state.client_status === "my_clients"
                        ? "btn filter__container--button active"
                        : "btn filter__container--button"
                    }
                    onClick={this.handelUserfilter.bind(this)}
                  >
                    Assigned to Me ({AssignedToMe})
                  </button>
                ) : (
                  ""
                )}
                <button
                  name="new_not_scheduled"
                  className="btn filter__container--button"
                  onClick={this.handelUserfilter.bind(this)}
                >
                  {" "}
                  New Not Scheduled ({NewNotScheduled})
                </button>
                <button
                  name="call_scheduled"
                  className="btn filter__container--button"
                  onClick={this.handelUserfilter.bind(this)}
                >
                  Call Scheduled ({CallScheduled})
                </button>
                <button
                  name="trying_to_schedule"
                  className="btn filter__container--button"
                  onClick={this.handelUserfilter.bind(this)}
                >
                  Trying to Schedule({TryingtoSchedule})
                </button>
                <button
                  name="reschedule"
                  className="btn filter__container--button"
                  onClick={this.handelUserfilter.bind(this)}
                >
                  Reschedule({Reschedule})
                </button>
              </div>
              <div className="col-md-3 newfilter-list text-right">
                <div>
                  <span className="enteries-span">
                    <label className="enteries-label">Entries</label>
                    <select
                      onChange={this.handleEntryChange.bind(this)}
                      value={this.state.client_limit}
                    >
                      {config.entries &&
                        config.entries.map((entry, entryindx) => {
                          return (
                            <option key={entryindx} value={entry.value}>
                              {entry.label}
                            </option>
                          );
                        })}
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mobileFilter filter__container"
            style={{ display: "none" }}
          >
            <div className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Filter{" "}
                <img
                  src="./img/custom-select-arrow.svg"
                  style={{ float: "right", paddingTop: "5px", width: "16px" }}
                />
              </a>
              <ul className="dropdown-menu mobile-dropdown">
                {user && user.role != "strategist" && user.role != "local" ? (
                  <li>
                    <button
                      name="all_clients"
                      className="btn filter__container--button active"
                      onClick={this.handelUserfilter.bind(this)}
                    >
                      All Clients ({AllClients})
                    </button>
                  </li>
                ) : (
                  ""
                )}
                {user &&
                user.role != "superadmin" &&
                user.role != "strategist" &&
                user.role != "local" ? (
                  <li>
                    <button
                      name="my_clients"
                      className="btn filter__container--button "
                      onClick={this.handelUserfilter.bind(this)}
                    >
                      Assigned to Me ({AssignedToMe})
                    </button>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <button
                    name="call_scheduled"
                    className="btn filter__container--button"
                    onClick={this.handelUserfilter.bind(this)}
                  >
                    Call Scheduled ({CallScheduled})
                  </button>
                </li>
                <li>
                  <button
                    name="trying_to_schedule"
                    className="btn filter__container--button"
                    onClick={this.handelUserfilter.bind(this)}
                  >
                    Trying to Schedule({TryingtoSchedule})
                  </button>
                </li>
                <li>
                  <button
                    name="reschedule"
                    className="btn filter__container--button"
                    onClick={this.handelUserfilter.bind(this)}
                  >
                    Reschedule({Reschedule})
                  </button>
                </li>
              </ul>
            </div>

            <div className="col-md-12 newfilter-list text-right filter__container--filter-name rem-flex">
              <div>
                <span className="enteries-span">
                  <label className="enteries-label">Entries</label>
                  <select onChange={this.handleEntryChange.bind(this)}>
                    {config.entries &&
                      config.entries.map((entry, entryindx) => {
                        return (
                          <option key={entryindx} value={entry.value}>
                            {entry.label}
                          </option>
                        );
                      })}
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div className="uses-list useslist__extra">
            <div className="table-responsive table__responsive--mob">
              <table className="table table-striped">
                <thead className="table__td--desktop">
                  <tr>
                    <th onClick={this.sortData.bind(this, "clientName")}>
                      Client Name
                    </th>
                    <th onClick={this.sortData.bind(this, "strategist")}>
                      Strategist
                    </th>
                    <th onClick={this.sortData.bind(this, "pipelineStage")}>
                      Pipeline Stage
                    </th>
                    <th onClick={this.sortData.bind(this, "bookedBy")}>
                      Booked By
                    </th>
                    {/* <th onClick={this.sortData.bind(this, "followUp")}>
                      Follow Up
                    </th> */}
                    <th onClick={this.sortData.bind(this, "created")}>
                      Created
                    </th>
                  </tr>
                </thead>
                {this.state.isLoadingData === true ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="noClientResults text-center">
                        <img src="../../img/loader2.svg" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {clientData &&
                    clientData.users &&
                    clientData.users.length > 0 ? (
                      clientData.users.map((c, index) => {
                        let followupDate = moment(c.follow_up_date);
                        // let nowDate = moment();
                        let convertIntoTimeZone = new Date().toLocaleString(
                          "en-US",
                          { timeZone: this.state.currentU_user_timezone }
                        );
                        let nowDate = moment(convertIntoTimeZone).add(
                          -1,
                          "days"
                        );
                        let overDueRedClass = "";
                        if (nowDate > followupDate) {
                          // this is past date
                          overDueRedClass = "overDueRed";
                        } else {
                          overDueRedClass = "text-green";
                        }
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/activity/${c.client_id}`,
                                  state: c
                                });
                              }}
                            >
                              {c.client_full_name}
                              <span className="showschedule__mob">
                                {c.status}
                              </span>
                            </td>
                            <td
                              className="table__td--desktop"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/activity/${c.client_id}`,
                                  state: c
                                });
                              }}
                            >
                              {c.strategists &&
                                c.strategists.map((strategist, idx) => {
                                  return (
                                    <span key={idx}>
                                      {(idx ? ", " : "") +
                                        strategist.strategist_full_name}
                                    </span>
                                  );
                                })}
                            </td>
                            <td
                              className="table__td--desktop"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/activity/${c.client_id}`,
                                  state: c
                                });
                              }}
                            >
                              {c.status}
                            </td>
                            <td
                              className="table__td--desktop"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/activity/${c.client_id}`,
                                  state: c
                                });
                              }}
                            >
                              {c.booked_by}
                            </td>
                            {/* <td
                              onClick={this.openCalendarModalIcon.bind(this, c)} 
                              className="fordt-picker posirel" style={{position: 'relative'}}>
                              <div className="flexing flexingmob">
                                <span className={overDueRedClass}>
                                  {c.follow_up_date &&
                                    moment(c.follow_up_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                </span>
                                <a className="btn__edit">
                                  <img src="img/edit-icon.svg" />
                                </a> */}
                                {/*<DatePicker
                                  customInput={<DatePickerCustomInput />}
                                  onChange={this.handleDateChange.bind(this, c)}
                                  showTimeSelect
                                  peekNextMonth
                                  minDate={new Date()}
                                  maxDate={moment(new Date()).add(1, "months")}
                                  className="form-control custom-select date__picker extraleft"
                                ></DatePicker>*/}
                              {/* </div>
                            </td> */}
                            <td
                              className="table__td--desktop"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/activity/${c.client_id}`,
                                  state: c
                                });
                              }}
                            >
                              {c.latest_activity &&
                                moment(c.latest_activity).format(
                                  "MM/DD/YYYY HH:mm a"
                                )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="noClientResults">
                          No clients found for this criteria. Please broaden or
                          change your search criteria and filters
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </main>
        <div className="pagination-container pagination-container-wrapper">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.client_limit}
            totalItemsCount={this.state.totalPages}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>

        {/*This is model for calendar*/}
        <Modal
          isOpen={this.state.calendarIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles2}           
          contentLabel="Example Modal">
            <div className="datepicker__newcalendar"> 

              <div className="header__datepicker"> 
                <h4>Follow Up</h4>
                <div 
                  className="calendar__close"><img 
                  onClick={this.closeCalendarModalIcon.bind(this)} 
                  data-dismiss="modal" src="./img/close_btn.svg"/>
                </div>
              </div>
                <label className="label__calendar">Select Date</label>
                <Calendar
                  activeStartDate={this.state.selectedDate}
                  value={this.state.selectedDate}
                  minDate={new Date()}
                  onChange={this.changeMonthDate.bind(this)}
                />
                <button className="sub__btn--date" onClick={this.onSubmitDateRange.bind(this)}>Submit</button>        
            </div>
        </Modal>
        {/************End****************/}
      </div>
    );
  }
}

export default reduxForm({
  form: "AddMember", // a unique identifier for this form
  destroyOnUnmount: true
})(AddMember);
