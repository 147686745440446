import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import Modal from 'react-responsive-modal'
import Modal2 from 'react-modal'
import { StickyContainer, Sticky } from 'react-sticky'
import { DateRange } from 'react-date-range'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class ClientsPerAgents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      fromDate: moment().add(-1, 'month').startOf('month'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      type: 'assignToAgent',
      agentName: '',
      currentAgentClients:[],
      openClient: false,
      currentAgentTowns: [],
      currentAgentTags: [],
      open:false,
      openTown: false,
      openStr: false,
      currentAgentStrategist: [],
      clientsAssignToAgentState:[],
      sortByKey:'name',
      sortByType: 0,
      search:'',
      currentAgentInitialClients:[],
      openInitialClient:false,
      currentAgentTourClients:[],
      openTourClient:false,
      selectedIndex: "all",
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment().add(-1, 'month').startOf('month'),
      defaultEndDate: moment()
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      // strData.strategist_arr = this.state.client_strategist
      this.props.getClientsPerAgents(strData)
      this.setState({isLoadingData: true},()=> {})
      // this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.clientsPerAgentsPhase === "success"){
      const clientsAssignToAgent = []
      if(nextProps.clientsPerAgentsData && nextProps.clientsPerAgentsData.length > 0){
        nextProps.clientsPerAgentsData.map(function(data){
          let obj ={}
          obj.name = data.name
          
          obj.totalClient =  data.totalClient.length > 0 ? data.totalClient.map(e => { return e.client_name}).join(" ,") : ""
          obj.totalTag = data.totalTag.length > 0 ? data.totalTag.map(e => { return e }).join(" ,") : ""
          obj.totalTown = data.totalTown.length > 0 ?  data.totalTown.map(e => { return e.name}).join(" ,") : ""
          obj.totalStrategist = data.totalStrategist.length > 0 ? data.totalStrategist.map(e => { return e.strategist_name}).join(" ,") : ""
          obj.totalInitialCall = data.totalInitialCall.length > 0 ? data.totalInitialCall.map(e => { return e.client_name }).join(" ,") : ""
          obj.totalAgentTownTour = data.totalAgentTownTour.length > 0 ? data.totalAgentTownTour.map(e => { return e.client_name }).join(" ,") : ""
          clientsAssignToAgent.push(obj)
        })
        this.setState({ isLoadingData: false, clientsAssignToAgentState:clientsAssignToAgent },()=> {})
        // this.props.clearPhase()
      }else{
        this.setState({ isLoadingData: false, clientsAssignToAgentState:clientsAssignToAgent },()=> {})
        // this.props.clearPhase()
      }
    }
  }

  componentDidMount() {
    let self = this;
    // $(document).click(function(event) {
    //   if (!$(event.target).closest("#dropdownBtn").length) {
    //     if ($("#dropdownBtn").is(":visible")) {
    //       self.setState({ strategistDropdown: false });
    //     }
    //   }
    // });
    $(document).ready(function() {
      $('tbody').scroll(function(e) { //detect a scroll event on the tbody
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
      });
    });
  }

  // handleFromDate(date) {
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.from = selectDate
  //       strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "from_date"
  //     strData.type = this.state.type
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date){
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.to = selectDate
  //       strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "end_date"
  //     strData.type = this.state.type
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }

  onOpenClientModal(agent) {
    const clients = agent && agent.totalClient
    if(clients.length > 1){
      this.setState({ agentName: agent.name, openClient: true, currentAgentClients: clients })
    }
  }

  
  onCloseClientModal() {
    this.setState({ agentName: '', currentAgentClients: [], openClient: false })
  }


  onOpenModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if(tags[0].length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTags: tags[0] })
    }
  }


  onOpenTownModal(agent) {
    const towns = agent && agent.totalTown 
    if(towns.length > 1){
      this.setState({ agentName: agent.name, openTown: true, currentAgentTowns: towns })
    }
  } 


  onOpenStrategistModal(agent) {
    const strategists = agent && agent.totalStrategist
    if(strategists.length > 1){
      this.setState({ agentName: agent.name, openStr: true, currentAgentStrategist: strategists })
    } 
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  onCloseTownModal() {
    this.setState({ agentName: '', currentAgentTowns: [], openTown: false })
  }

  onCloseStrategistModal() {
    this.setState({ agentName: '', currentAgentStrategist: [], openStr: false })
  }


  onOpenInitialModal(agent) {
    const clients = agent && agent.totalInitialCall
    if(clients.length > 1){
      this.setState({ agentName: agent.name, openInitialClient: true, currentAgentInitialClients: clients })
    }
  }

  
  onCloseInitialClientModal() {
    this.setState({ agentName: '', currentAgentInitialClients: [], openInitialClient: false })
  }

  onOpenTourModal(agent) {
    const clients = agent && agent.totalAgentTownTour
    if(clients.length > 1){
      this.setState({ agentName: agent.name, openTourClient: true, currentAgentTourClients: clients })
    }
  }

  
  onCloseTourModal() {
    this.setState({ agentName: '', currentAgentTourClients: [], openTourClient: false })
  }


  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }else{
      sortByType == 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const strData = {}
    if(this.state.selectedIndex === "all"){
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
    strData.type = this.state.type
    strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getClientsPerAgents(strData)
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  filterDate(e){
    this.setState({ isLoadingData: true })
    if(e.target.value === "all"){
      this.setState({ modalIsOpen: false })
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }else{
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }
    this.setState({ selectedIndex: e.target.value },() => {})
  }

  datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedIndex === "all"){
            strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
          }
          strData.dateType = "from_date"
          strData.type = this.state.type
          strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
          this.props.getClientsPerAgents(strData)
        }.bind(this), 300)
    })
  }


  render() {
    const { clientsPerAgentsData, clientsPerAgentsPhase } = this.props
    let filterList = [];
    const { openClient, open, openTown, openStr, clientsAssignToAgentState, search, openInitialClient, openTourClient, selectedIndex } = this.state;
    const clientsAssignToAgent = clientsAssignToAgentState
    if(clientsPerAgentsPhase == 'success' && clientsPerAgentsData && clientsPerAgentsData.length > 0){
      filterList = clientsPerAgentsData.filter(
        (agent) =>{
          return (
            (agent.name.toLowerCase().indexOf(search.toLowerCase())) !== -1 
          );
        }
      );
    }
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Clients Assigned To Agents</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>


        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{clientsPerAgentsData && clientsPerAgentsData.length}</span> Agents</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">
             {/*<span className="defaultCheckboxContainer">
                <label className="defaultChecboxCustom">
                <input type="checkbox"  checked = {selectedIndex} className="custom-select-option-checkbox" onChange={this.filterDate.bind(this,!selectedIndex)}/>
                 <span>
                Show all results
                </span>
                </label>
              </span>  */}


              
              <div className="right__cont right__cont--full">
              <div className="all__custom--wrapper">
                <select 
                  name="selectedIndex"
                  className="date__picker"
                  value = {this.state.selectedIndex} 
                  onChange={this.filterDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {
                (this.state.selectedIndex !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                      <Modal2
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}           
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">             
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>               
                          </div>
                        </div>
                      </Modal2>
                    </div>
                  }
                </div>
              }

              {/*<div className={selectedIndex ? "dp-wrapper dp-wrapper-edit marg__rt--ten" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        maxDate={moment(this.state.endDate)}
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        disabled={selectedIndex}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className={selectedIndex ? "dp-wrapper dp-wrapper-edit filterHideOverride" : "dp-wrapper dp-wrapper-edit filterWrapOvveride" }>
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        minDate={moment(this.state.fromDate)}
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        disabled={selectedIndex}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>*/}
              
            <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
              <ExcelSheet data={clientsAssignToAgent} name="clients_assigned_to_agents">
                  <ExcelColumn label="Agent Name" value="name"/>
                  <ExcelColumn label="Name Of Clients" value="totalClient"/>
                  <ExcelColumn label="Had Initial or Follow up Agent Call" value="totalInitialCall"/>
                  <ExcelColumn label="Had Initial or Follow up agent visit" value="totalAgentTownTour"/>
                  <ExcelColumn label="Tag" value="totalTag"/>
                  <ExcelColumn label="Towns" value="totalTown"/>
                  <ExcelColumn label="Strategist" value="totalStrategist"/>
              </ExcelSheet>
            </ExcelFile>
            
            </div>

            </div>
            </div>
          </div>
          </div>
   
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <main className="jungler__page--container">
              <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input reportsearchsminus add" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search"/>
              </span>

              <div className="list_table useslist__extra">
                <StickyContainer>
                  <table className="table table-striped">
                    <Sticky topOffset={50}>
                      {({
                        style,
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <header className="updiv" style={style}>
                          <thead>
                            <tr>
                              <th className="for__static" onClick={this.sortData.bind(this , 'name')}>Agent Name</th>
                              <th className="text-left">Name Of Client</th>
                              <th className="text-left">Had Initial or Follow up Agent Call</th>
                              <th className="text-left">Had Initial or Follow up agent visit</th>
                              <th className="text-left">Tag</th>
                              <th className="text-left">Towns</th>
                              <th className="text-left">Strategist</th>
                            </tr>
                          </thead>
                        </header>
                      )}
                    </Sticky>
                  
                  {this.state.isLoadingData === true ?
                  <tbody>
                    <tr>
                      <td colSpan="9" className="noClientResults text-center">
                      <img src="../../img/loader2.svg" />
                      <h5>Your clients report is generating, please wait...</h5>
                      </td>
                    </tr>
                  </tbody>
                  :
                  <tbody>
                  {
                    filterList && filterList.map((str, index)=>{
                      const totalClient = str && str.totalClient
                      const totalInitialCall = str && str.totalInitialCall
                      const totalAgentTownTour = str && str.totalAgentTownTour
                      const totalStrategist = str && str.totalStrategist
                      const totalTown = str && str.totalTown
                      const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                      const tagArr = tags[0]
                      let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                      let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                      let townNo = (totalTown.length-1) <= 0 ? '' : " (+"+(totalTown.length-1)+")"
                      let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                      let callNo = (totalInitialCall.length-1) <= 0 ? '' : " (+"+(totalInitialCall.length-1)+")"
                      let tourNo = (totalAgentTownTour.length-1) <= 0 ? '' : " (+"+(totalAgentTownTour.length-1)+")"
                      return(
                        <tr key={index} className="forcursor" >
                          <td>{str.name}</td>
                          <td className="wrap-text multilines"><div className={(totalClient.length > 1 ? "link" : '')} onClick={this.onOpenClientModal.bind(this, str)}>{( totalClient.length >= 1 ? totalClient[0].client_name+ clientNo : 'No Client' ) }</div></td>
                          <td className="wrap-text multilines"><div className={(totalInitialCall.length > 1 ? "link" : '')} onClick={this.onOpenInitialModal.bind(this, str)} >{( totalInitialCall.length >= 1 ? totalInitialCall[0].client_name+ callNo : 'No Client' ) }</div></td>
                          <td className="wrap-text multilines"><div className={(totalAgentTownTour.length > 1 ? "link" : '')} onClick={this.onOpenTourModal.bind(this, str)}>{( totalAgentTownTour.length >= 1 ? totalAgentTownTour[0].client_name+ tourNo : 'No Client' ) }</div></td>

                          <td className="wrap-text multilines"><div className={(tagArr.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div></td>

                          <td className="wrap-text multilines"><div className={(totalTown.length > 1 ? "link" : '')} onClick={this.onOpenTownModal.bind(this, str)}>{(totalTown.length >= 1 ? totalTown[0].name+ townNo : 'No Town')}</div></td>

                          <td className="wrap-text multilines"><div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenStrategistModal.bind(this, str)}>{(totalStrategist.length >= 1 ?totalStrategist[0].strategist_name+ strategistNo : 'No Strategist') }</div></td>
                        </tr>
                      )
                    })
                  }
                  </tbody> 
                  }
                  </table>
                </StickyContainer>
              </div>
            </main>
              <div>
                <Modal2 isOpen={openClient} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' client(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseClientModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                        return <div className="allpoppadding__paranew" key={'client-name-'+index}>{client.client_name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseClientModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={open} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' tag(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                        return <div className="allpoppadding__paranew" key={'tag-name-'+index}>{tag}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseModal.bind(this)}>Close</button>
                </Modal2>
              </div>

              <div>
                <Modal2 isOpen={openTown} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' town(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseTownModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTowns && this.state.currentAgentTowns.map((town, index) => {
                        return <div className="allpoppadding__paranew" key={'tag-name-'+index}>{town.name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseTownModal.bind(this)}>Close</button>
                </Modal2>
              </div>

              <div>
                <Modal2 isOpen={openStr} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' strategist(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseStrategistModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentStrategist && this.state.currentAgentStrategist.map((strategist, index) => {
                        return <div className="allpoppadding__paranew" key={'strategist-name-'+index}>{strategist.strategist_name} - Clients {strategist.clients}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseStrategistModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={openInitialClient} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' client(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseInitialClientModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentInitialClients && this.state.currentAgentInitialClients.map((client, index) => {
                        return <div className="allpoppadding__paranew" key={'client-name-'+index}>{client.client_name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseInitialClientModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              <div>
                <Modal2 isOpen={openTourClient} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' client(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseTourModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentTourClients && this.state.currentAgentTourClients.map((client, index) => {
                        return <div className="allpoppadding__paranew" key={'client-name-'+index}>{client.client_name}</div>;
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseTourModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              {
                clientsPerAgentsData && clientsPerAgentsData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Clients Assigned To Agents</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'ClientsPerAgentslist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientsPerAgents)
