import React, { PureComponent /*, PropTypes*/ } from 'react';
import CommuterCharacteristics from './CommuterCharacteristics/container';
import CommuteTable from './CommuteTable/container';
import { Switch, Route } from 'react-router-dom';
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import './styles.scss';

export default class TransportationCommuteComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  render() {
    const {
      baseUrl,
      location: { pathname }
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs__control mt__15">
              <button
                onClick={() =>
                  this.props.history.push(
                    `${baseUrl}/transportation-commute/characteristics`
                  )
                }
                className={`btn btn-link ${pathname.includes(
                  'transportation-commute/characteristics'
                ) && 'active'}`}
              >
                Commuter Characteristics
              </button>
              <button
                onClick={() =>
                  this.props.history.push(
                    `${baseUrl}/transportation-commute/table`
                  )
                }
                className={`btn btn-link ${pathname.includes(
                  'transportation-commute/table'
                ) && 'active'}`}
              >
                Commute Table{' '}
              </button>
            </div>
          </div>
        </div>
        <Switch>
          <Route
            exact
            path={`${baseUrl}/transportation-commute/characteristics`}
          >
            <CommuterCharacteristics {...this.props} />
          </Route>
          <Route exact path={`${baseUrl}/transportation-commute/table`}>
            <CommuteTable {...this.props} />
          </Route>
        </Switch>
      </div>
    );
  }
}
