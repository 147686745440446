import { connect } from 'react-redux'

import DashboardComponent from './component'

import { fetchUser } from '../../../../store/strategistV2/dashboard/actions'
import { getAdministratorDashboardData } from '../../../../store/strategistV2/strategist/actions'

const DashboardContainer = connect(
  // Map state to props
  (state) => ({
    user: state.strategistV2.dashboard.users,
    adminstrationDashboardData:
    state.strategistV2.strategist.adminstrationDashboardData,
  }),
  // Map actions to dispatch and props
  {
    fetchUser,
    getAdministratorDashboardData,
  }
)(DashboardComponent)

export default DashboardContainer
