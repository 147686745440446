import { connect } from 'react-redux'
import {
    handleSignOut, 
    loginUser, 
    fetchUser, 
    getNotifications, 
    clearNotification, 
    clearPhaseUser
  } from '../../store/user/duck'
import { getAllClients, 
         clearAgentPhase, 
         getAgentChat, 
         clearAllagentLogedData,
         getAgentAvailability,
         agentScheduleOnClient,
         townResearchData,
         updateTownResearch
       } from '../../store/agent/duck'
import {
  homeFilterClients,
  getUserbyId,
  getClientEmail,
  getClientNotes,
  sendMyNote,
  sendBackgroundData,
  getStrategy,
  getClientDeal,
  deleteClient,
  getClientMessages,
  sentClientMessage,
  getFilterParams,
  getStrategistAvail,
  addStrategistApt,
  getRecordingLinks,
  clearPhase,
  clearFilterPhase,
  addUpdateClient,
  impersonateUser,
  deleteNote,
  clearClientActivityPhase,
} from '../../store/dashboard/duck'

import { 
         getClientChatData,
       } from '../../store/chat/duck'

import {getClientAgent, getClientTownRateDataById} from '../../store/client/duck'
import {getChatHistory} from '../../store/chat/duck'
import ClientActivityForm from './component'
const ClientActivity = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    profileError: state.user.profileError,
    dataPhase: state.dashboard.dataPhase,
    notesPhase: state.dashboard.notesPhase,
    profilePhase: state.user.profilePhase,
    singleClientData: state.dashboard.singleClientData,
    clientEmaildata: state.dashboard.clientEmaildata,
    clientNotesdata: state.dashboard.clientNotesdata,
    sendClientMessagesData: state.dashboard.sendClientMessagesData,
    getClientMessagesData:state.dashboard.getClientMessagesData,
    getRecordingLinksData: state.dashboard.getRecordingLinksData,
    deleteClientdata: state.dashboard.deleteClientdata,
    clientDealdata: state.dashboard.clientDealdata,
    sendMynoteData:state.dashboard.sendMynoteData,
    isSubmitting: state.user.isSubmitting,
    error: state.dashboard.error,
    strategyData: state.dashboard.strategyData,
    chatAgent: state.dashboard.chatAgent,
    filterParams: state.dashboard.filterParams,
    strategistAvailabilityData: state.dashboard.strategistAvailabilityData,
    strategistAvailabilityPhase: state.dashboard.strategistAvailabilityPhase,
    addStrategyAptPhase: state.dashboard.addStrategyAptPhase,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase,
    addUpdateClientPhase: state.dashboard.addUpdateClientPhase,
    sendBackgroundPhase: state.dashboard.sendBackgroundPhase,
    allclientData: state.agent.allclientData,
    strategistMessage: state.dashboard.strategistMessage,
    filterParamsPhase: state.dashboard.filterParamsPhase,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    deleteNotePhase: state.dashboard.deleteNotePhase,
    addAppointmentPhase: state.client.addAppointmentPhase,
    chatHistoryData: state.chat.chatHistoryData,
    getAgentChatPhase: state.agent.getAgentChatPhase,
    getAgentChatData: state.agent.getAgentChatData,
    addAptMessage: state.dashboard.addAptMessage,
    availabilityData: state.agent.availabilityData,
    agentSchedulePhase: state.agent.agentSchedulePhase,
    agentScheduleMessage: state.agent.agentScheduleMessage,
    clientChatPhase: state.chat.clientChatPhase,
    clientChatData: state.chat.clientChatData,
    townResearchPhase: state.agent.townResearchPhase,
    townResData: state.agent.townResData,
    updateResearchPhase: state.agent.updateResearchPhase,
    getTownAndAgentRatePhase: state.client.getTownAndAgentRatePhase,
    getTownAndAgentRateData: state.client.getTownAndAgentRateData,
    is_rated_town: state.client.is_rated_town,
    is_rated_agent: state.client.is_rated_agent,
    chatHistoryData: state.chat.chatHistoryData
  }),
  // Map actions to props
  {
    loginUser,
    fetchUser,
    handleSignOut,
    getUserbyId,
    getClientEmail,
    getClientNotes,
    sendMyNote,
    sendBackgroundData,
    clearPhase,
    clearFilterPhase,
    getStrategy,
    getClientDeal,
    deleteClient,
    clearAllagentLogedData,
    getClientMessages,
    sentClientMessage,
    getFilterParams,
    getStrategistAvail,
    getRecordingLinks,
    addStrategistApt,
    getNotifications,
    clearNotification,
    addUpdateClient,
    getAllClients,
    clearAgentPhase,
    impersonateUser,
    deleteNote,
    getClientAgent,
    getChatHistory,
    getAgentChat,
    clearClientActivityPhase,
    getAgentAvailability,
    agentScheduleOnClient,
    clearPhaseUser,
    getClientChatData,
    townResearchData,
    updateTownResearch,
    getClientTownRateDataById,
    getChatHistory
  }
)(ClientActivityForm)
export default ClientActivity
