import React, { PureComponent } from 'react'
import { Link } from "react-router-dom"
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2'
import { CloseIcon, CameraIcon } from '../../../../components/icons'
import UserContext from "../../../App/Context"
import './styles.scss'
import config from '../../../../config'
import { logoutInvalidRequest } from '../../../../utils/helper'
import _ from 'lodash'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { message } from 'antd'
import axios from 'axios'
import agent_automated_welcome_message from './agent_automated_welcome_message';
import { fetch } from './../../../../utils'

const API_HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export default class UpdateProfileComponent extends React.Component {
  static contextType = UserContext;


  constructor(props) {
    super(props)
    this.state = {
      loginUserId: 0,
      activeTab: 0,
      userData: {},
      avatar: '',
      file: {}
    }
  }

  handleFileUpload(e, setFieldValue) {
    var _URL = window.URL || window.webkitURL;
    let files = e.target.files[0]
    var img = new Image();
    let self = this
    let reader = new FileReader();
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files);
      img.onload = function () {
        self.setState({ file: files })
        self.setState({ avatar: reader.result })
      }
    }
    var read = reader.readAsDataURL(files)
    setFieldValue("avatar", _URL.createObjectURL(files))
  }

  submitData = (data, setSubmitting) => {
    if (!(_.isEmpty(this.state.file.name))) {
      const formData = new FormData()
      formData.append('image', this.state.file)
      fetch(`${SUBURBANJUNGLE_API_HOST}/users/me/avatar`, {
        method: 'POST',
        body: formData
      }).then(res => res.json())
        .then(response => {
          if (response.status && response.data) {
            this.setState({avatar: response.data})
          this.handleSubmitButton(data, setSubmitting, true)
        } else {
          this.handleSubmitButton(data, setSubmitting, false)
        }
      }).catch(logoutInvalidRequest)
    } else {
      this.handleSubmitButton(data, setSubmitting, false)
    }
  }

  async handleSubmitButton(data, setSubmitting, isImage) {
    const userData = {
      user_id: data.id ? data.id : data.user_id,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_mobile: data.phone_mobile,
      email: data.email,
      firm_name: data.firm_name,
      time_zone: data.time_zone,
      business_profile: data.business_profile ? data.business_profile : '',
      zillow_profile: data.zillow_profile ? data.zillow_profile : '',
      update_type: "basic_info",
      description: data.description,
      avatar: isImage ? this.state.avatar : data.avatar,
      is_limit: data.is_limit,
      country_code: data.country_code,
      automated_welcome_message: data.automated_welcome_message ? data.automated_welcome_message : ''
    }
    userData.id = data.id ? data.id : data.user_id
    userData.role = data.role
    await this.props.updateProfile(userData)
    message.success('Information Saved')
    this.context.updateUserData(userData)
    setTimeout(() => {
      setSubmitting(false)
    }, 2000)
  }

  render() {
    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
    })

    return (
      <div>
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">
            Update Profile
          </div>
          <div className="head-section-cta">
            <button onClick={this.props.history.goBack} className="btn btn-link btn-back">
              <CloseIcon />
            </button>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            id: _.get(this.context.userData, "id", ""),
            first_name: _.get(this.context.userData, "first_name", ""),
            last_name: _.get(this.context.userData, "last_name", ""),
            email: _.get(this.context.userData, "email", ""),
            firm_name: _.get(this.context.userData, "firm_name", "+1"),
            role: _.get(this.context.userData, "role", ""),
            phone_mobile: _.get(this.context.userData, "phone_mobile", ""),
            time_zone: _.get(this.context.userData, "time_zone", ""),
            business_profile: _.get(this.context.userData, "business_profile", ""),
            zillow_profile: _.get(this.context.userData, "zillow_profile", ""),
            modified: _.get(this.context.userData, "modified", ""),
            created: _.get(this.context.userData, "created", ""),
            avatar: _.get(this.context.userData, "avatar", false),
            automated_welcome_message: _.get(this.context.userData, "automated_welcome_message", "") || agent_automated_welcome_message.CONTENT,
            country_code: _.get(this.context.userData, "country_code", "+1"),
            is_limit: _.get(this.context.userData, "is_limit", ""),
            description: _.get(this.context.userData, "description", ""),
          }}
          validate={values => {
            let onlyNumber = values.phone_mobile && values.phone_mobile.replace(/\D/g, '')
            let mobileValidation = onlyNumber.length
            const errors = {};
            if (!values.first_name) {
              errors.first_name = 'Enter First Name';
            }
            if (!values.last_name) {
              errors.last_name = 'Enter Last Name';
            }
            if (!values.description) {
              errors.description = 'Please Enter Bio';
            }
            if (!values.phone_mobile) {
              errors.phone_mobile = 'Enter Mobile Number';
            } else if (mobileValidation < 6 || mobileValidation > 16) {
              errors.phone_mobile = 'Enter Mobile Correct Number'
            }
            if (!values.email) {
              errors.email = 'Enter Email Address';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
              errors.email = 'Invalid Email Address'
            }
            if (!values.time_zone) {
              errors.time_zone = 'Enter Timezone';
            }
            if (_.get(this.context.userData, 'role') === "agent") {
              if (!values.firm_name) {
                errors.firm_name = 'Enter Firm Name';
              }
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            this.submitData(values, setSubmitting)
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => (
            <Form>
              <div className="card profile__card">
                <div className="card-header d__flex align__items__center">
                  <h5 className="card-title mb__0">My Profile</h5>
                </div>
                <div className="card-body">
                  <div className="form-group material-textfield">
                    <div className="d__flex align__items__center">
                      <div className="avatar-text">Photo<span> (1mb max)</span></div>
                      <div className="avatar avatar-lg">
                        <img src={values && values.avatar !== '' && values && values.avatar !== null ? values && values.avatar : '../img/blank_profile_image.png'} alt="" />
                        {
                          //   (_.isEmpty(values.avatar)) &&
                          // <span className="avatar-string">{ _.get(values, 'first_name', '') ? _.upperFirst(_.get(values, 'first_name', '').charAt(0)) : ''}{_.get(values, 'last_name', '') ? _.upperFirst(_.get(values, 'last_name', '').charAt(0)) : ''}</span>
                        }
                        <div className="upload-avatar-container">
                          <input
                            type="file"
                            className="upload-avatar"
                            accept="image/*"
                            name="image"
                            onChange={(e) => this.handleFileUpload(e, setFieldValue)}
                            id="file" />
                          <CameraIcon />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          name="first_name"
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          required
                        />
                        <label className="material-textfield-label label-lg">First Name</label>
                        <ErrorMessage className="invalid-feedback" name="first_name" component="div" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="last_name"
                          required
                        />
                        <label className="material-textfield-label label-lg">Last Name</label>
                        <ErrorMessage className="invalid-feedback" name="last_name" component="div" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <PhoneInput
                          defaultCountry={'us'}
                          autoFormat={false}
                          disableAreaCodes={true}
                          enableLongNumbers={false}
                          buttonClass="flag-textfield"
                          onChange={e => setFieldValue("country_code", e)}
                          required
                        />
                        <NumberFormat
                          format="(###) ### #### #####"
                          name='phone_mobile'
                          className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                          value={values && values.phone_mobile ? values.phone_mobile : ''}
                          onChange={e => setFieldValue("phone_mobile", e.target.value)}
                          required
                        />
                        <label className="material-textfield-label label-lg">Phone Number</label>
                        <ErrorMessage className="invalid-feedback" name="phone_mobile" component="div" />
                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="email"
                          required
                        />
                        <label className="material-textfield-label label-lg">Email</label>
                        <ErrorMessage className="invalid-feedback" name="email" component="div" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {
                      (_.get(this.context.userData, 'role') === "agent") &&
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <Field
                            type="text"
                            className="form-control material-textfield-input textfield-input-lg"
                            name="firm_name"
                            required
                          />
                          <label className="material-textfield-label label-lg">Firm Name</label>
                          <ErrorMessage className="invalid-feedback" name="firm_name" component="div" />
                        </div>
                      </div>
                    }

                    <div className={(_.get(this.context.userData, 'role') === "agent") ? "col-md-12" : "col-md-12"}>
                      <div className="form-group material-textfield">
                        <Field
                          name="time_zone"
                          component="select"
                          className="form-control custom-select material-textfield-input textfield-input-lg">
                          <option disabled value=""></option>
                          {timezonlist}
                        required
                      </Field>
                        <label className="material-textfield-label label-lg">Time Zone</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="business_profile"
                        />
                        <label className="material-textfield-label label-lg">
                          Business profile
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="zillow_profile"
                        />
                        <label className="material-textfield-label label-lg">Zillow profile</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="textarea"
                      type="text"
                      className="form-control material-textfield-input textfield-input-lg"
                      name="description"
                      rows="9"
                      required
                    />
                    <label className="material-textfield-label label-lg">Bio</label>
                    <ErrorMessage className="invalid-feedback" name="description" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="textarea"
                      type="text"
                      className="form-control material-textfield-input textfield-input-lg"
                      name="automated_welcome_message"
                      rows="9"
                    />
                    <label className="material-textfield-label label-lg">Automated Welcome Message</label>
                    <ErrorMessage className="invalid-feedback" name="automated_welcome_message" component="div" />
                  </div>
                  <div className="form-group material-textfield textfield-input-lg" style={{cursor: 'pointer'}} onClick={() => {
                    setFieldValue("automated_welcome_message", agent_automated_welcome_message.CONTENT)
                  }}>
                    Reset
                  </div>
                  <button type="submit" disabled={isSubmitting} className="btn btn__btn btn-dark btn__lg w__100">Save</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}
