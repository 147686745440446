import { connect } from 'react-redux'

import { updateClientUser, agentClientFollowUp, clearPhase } from '../../../../../../store/agent/duck'
import { fetchAgentAllClient } from '../../../../../../store/agentV2/actions'

import ClosedClientsComponent from './component'
const ClosedClientsContainer = connect(
  // Map state to props
  (state) => ({
    agentClientData: state.agent.agentClientData,
    agentAllClients: state.agentV2.agentAllClients,
    followUpPhase: state.agent.followUpPhase
  }),
  // Map actions to dispatch and props
  {
    updateClientUser,
    agentClientFollowUp,
    fetchAgentAllClient,
    clearPhase
  }
)(ClosedClientsComponent)

export default ClosedClientsContainer
