import React from 'react'
import { get } from 'lodash'

// import TopNavigation from '../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon } from '../../../../components/icons'

import './styles.scss'
import DealForm from './partials/DealForm'

export default function DealComponent(props) {
  // go back to same page from we came
  const handleBack = () => {
    const prevRoute = get(props, 'history.location.state.prevRoute', '')
    const clientId = get(props, 'clientDetail.user.id', '')
    if (prevRoute && clientId) {
      props.history.push({
        pathname: `/strategist/client-details/${clientId}`,
        state: { prevRoute }
      })
    } else {
      props.history.goBack()
    }
  }
    
  return (
    <div className="layout-has-sidebar">
      <AdministrationSidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="container-fluid">
          <div className="client__details__header">
            <div className="row d__flex align__items__center">
              <div className="col-xs-6 col-md-4">
                <button className="btn btn-back" onClick={handleBack}>
                  <BackArrowIcon className="arrow-icon" />
                  Back Client Detail
                </button>
              </div>
              <div className="col-xs-6 col-md-8 text-right"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h1 className="title__section">{get(props, 'clientDetail.user.client_full_name', '')} Deal</h1>
            </div>
            <DealForm {...props} />
          </div>
        </div>
      </main>
    </div>
  )
}
