import Rx from 'rxjs/Rx'
import { Record } from 'immutable'
import { combineEpics } from 'redux-observable'
import { INIT, LOADING, SUCCESS } from '../../constants/phase'

import * as api from './api'

/**
 * Public: Action Types
 */

export const FETCH_BROKERAGE_REPORT = 'suburban/brokerage/FETCH_BROKERAGE_REPORT'
export const FETCH_BROKERAGE_REPORT_SUCCESS = 'suburban/town/FETCH_BROKERAGE_REPORT_SUCCESS'
export const FETCH_BROKERAGE_REPORT_ERROR = 'suburban/town/FETCH_BROKERAGE_REPORT_ERROR'

export const FETCH_BROKERAGE_REPORT_FILTERS = 'suburban/brokerage/FETCH_BROKERAGE_REPORT_FILTERS'
export const FETCH_BROKERAGE_REPORT_FILTERS_SUCCESS = 'suburban/town/FETCH_BROKERAGE_REPORT_FILTERS_SUCCESS'
export const FETCH_BROKERAGE_REPORT_FILTERS_ERROR = 'suburban/town/FETCH_BROKERAGE_REPORT_FILTERS_ERROR'

export const CLEAR_PHASE = 'suburban/brokerage/CLEAR_PHASE'

/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  userPhase: INIT,
  chatPhase: INIT,
  chathisotryPhase: INIT,
  clientPhase: INIT,
  communityPhase: INIT,
  editCommunityPhase: INIT,
  editPrivatePhase: INIT,
  editPublicPhase: INIT,
  deletePrivatePhase: INIT,
  deletePrivateData: [],
  deletePublicPhase: INIT,
  getGooglePlacePhase: INIT,
  googlePlace: [],
  getGooglePlaceDataPhase: INIT,
  googlePlaceData: [],
  deletePublicData: [],
  fetchTownByIdPhase: INIT,
  fetchTownByIdData: {},
  editPublicData: [],
  editPrivateData: [],
  addPrivatePhase: INIT,
  deleteCommutePhase: INIT,
  addPrivateData: [],
  addPublicPhase: INIT,
  synchCommunityData: [],
  synchCommunityPhase: INIT,
  updateTownPhase: INIT,
  updateTownData: [],
  addPublicData: [],
  suburbsData: [],
  communityData: [],
  editCommunityData: [],
  addCommutePhase: INIT,
  brokerageReportPhase: INIT,
  brokerageReportFilterPhase: INIT,
  deleteTownPhase: INIT,
  addResearchPhase: INIT,
  deleteResearchPhase: INIT,
  deleteResearchData: [],
  addResearchData: [],
  deleteTownData: [],
  addCommuteData: [],
  addGooglePhase: INIT,
  addGoogleData: [],
  editGooglePhase: INIT,
  getResearchPhase: INIT,
  getResearchData: [],
  editGoogleData: [],
  researchFilePhase: INIT,
  researchFileData: [],
  error: null,
  isSubmitting: false,
  towns: [],
  brokerageRows: [],
  usersCount: [],
  brokerageFilters: [],
  generateReport: null,
  disableBtn: false,
  report: null,
  imageUpload: false,
  videoUpload: false,
  uploadProgress: false,
  pushToWp: null,
  featured: false,
  removeImagePhase: INIT,
  removeVideoPhase: INIT,
  imageUploadPhase: INIT,
  videoUploadPhase: INIT,
  removeImage: false,
  removeVideo: false,
  townsCount: 0,
  getGeneratedReportPhase: INIT,
  editReportSuccess: false,
  generateReportData: [],
  generateReportPhase: INIT,
  fetchCommutePhase: INIT,
  commuteData: [],
  townCharacteristics: [],
  fetchTownCharacteristicsPhase: INIT,
  updateTownCharacteristicsPhase: INIT,
  getSchoolsPhase: INIT,
  schoolData: [],
  getAttomSchoolsPhase: INIT,
  attomSchoolData: []
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case FETCH_BROKERAGE_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('brokerageRows', payload.data.users)
        .set('usersCount', payload.data.usersCount)
        .set('error', null)
        .set('brokerageReportPhase', SUCCESS)
        .set('isSubmitting', false)
        .set('disableBtn', false)
    }
    case FETCH_BROKERAGE_REPORT: {
      const { payload } = action
      return state
        .set('error', null)
        .set('brokerageReportPhase', INIT)
        .set('isSubmitting', true)
        .set('disableBtn', false)
    }
    case FETCH_BROKERAGE_REPORT_FILTERS_SUCCESS: {
      const { payload } = action
      return state
        .set('brokerageFilters', payload.data)
        .set('error', null)
        .set('brokerageReportFilterPhase', SUCCESS)
        .set('isSubmitting', false)
        .set('disableBtn', false)
    }
    case FETCH_BROKERAGE_REPORT_FILTERS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('brokerageReportFilterPhase', INIT)
        .set('isSubmitting', true)
        .set('disableBtn', false)
    }


    case CLEAR_PHASE: {
      return state
        .set('chatPhase', INIT)
        .set('addResearchPhase', INIT)
        .set('synchCommunityPhase', INIT)
        .set('editCommunityPhase', INIT)
        .set('addCommutePhase', INIT)
        .set('researchFilePhase', INIT)
        .set('editPrivatePhase', INIT)
        .set('deletePrivatePhase', INIT)
        .set('getSchoolsPhase', INIT)
        .set('getAttomSchoolsPhase', INIT)
        .set('brokerageReportPhase', INIT)
        .set('brokerageReportFilterPhase', INIT)
        .set('addGooglePhase', INIT)
        .set('addPublicPhase', INIT)
        .set('addPrivatePhase', INIT)
        .set('deleteCommutePhase', INIT)
        .set('fetchCommutePhase', INIT)
        .set('generateReportPhase', INIT)
        .set('editGooglePhase', INIT)
        .set('removeImagePhase', INIT)
        .set('fetchTownByIdPhase', INIT)
        .set('getGeneratedReportPhase', INIT)
        .set('imageUploadPhase', INIT)
        .set('videoUploadPhase', INIT)
        .set('getGooglePlaceDataPhase', INIT)
        .set('getGooglePlacePhase', INIT)
        .set('deletePublicPhase', INIT)
        .set('editReportSuccess', false)
        .set('editPublicPhase', INIT)
        .set('deleteResearchPhase', LOADING)
        .set('updateTownPhase', INIT)
        .set('deleteTownPhase', LOADING)
        .set('generateReport', null)
        .set('featured', false)
        .set('fetchTownCharacteristicsPhase', INIT)
        .set('updateTownCharacteristicsPhase', INIT)
    }

    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const fetchBrokerageReport = (payload) => ({
  type: FETCH_BROKERAGE_REPORT,
  payload
})

export const fetchBrokerageReportFilters = (payload) => ({
  type: FETCH_BROKERAGE_REPORT_FILTERS,
  payload
})

export const clearBrokeragePhase = () => ({
  type: CLEAR_PHASE
})


/**
 * Private: Epics
 */

const getBrokerageReportsEpic = (action$) =>
  action$
    .ofType(FETCH_BROKERAGE_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getBrokerageReports(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_BROKERAGE_REPORT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_BROKERAGE_REPORT_ERROR,
          payload: { error }
        }))
    })

const getBrokerageReportFiltersEpic = (action$) =>
  action$
    .ofType(FETCH_BROKERAGE_REPORT_FILTERS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getBrokerageReportFilters(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_BROKERAGE_REPORT_FILTERS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_BROKERAGE_REPORT_FILTERS_ERROR,
          payload: { error }
        }))
    })


/**
 * Public: Export Epics
 */

export const brokerageReportEpic = combineEpics(
  getBrokerageReportsEpic,
  getBrokerageReportFiltersEpic,
)