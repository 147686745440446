import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  prospectus: [],
  prospectusDetail: [],
  fetchProspectusPhase: INIT,
  createProspectusPhase: INIT,
  fetchProspectusByIdPhase: INIT,
})

export const prospectusReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch prospectus by id */
    case types.FETCH_PROSPECTUS: {
      return state.set('error', null).set('fetchProspectusPhase', LOADING)
    }
    case types.FETCH_PROSPECTUS_SUCCESS: {
      const prospectus = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('prospectus', prospectus)
        .set('fetchProspectusPhase', SUCCESS)
    }
    case types.FETCH_PROSPECTUS_ERROR: {
      return state.set('error', action.payload.error).set('fetchProspectusPhase', ERROR)
    }

    /* fetch prospectus  detail by id */
    case types.FETCH_PROSPECTUS_BY_ID: {
      return state.set('error', null).set('fetchProspectusByIdPhase', LOADING)
    }
    case types.FETCH_PROSPECTUS_BY_ID_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('prospectusDetail', payload)
        .set('fetchProspectusByIdPhase', SUCCESS)
    }
    case types.FETCH_PROSPECTUS_BY_ID_ERROR: {
      return state.set('error', action.payload.error).set('fetchProspectusByIdPhase', ERROR)
    }

    /*  create prospectus  */
    case types.CREATE_PROSPECTUS: {
      return state.set('error', null).set('createProspectusPhase', LOADING)
    }
    case types.CREATE_PROSPECTUS_SUCCESS: {
      return state.set('error', null).set('createProspectusPhase', SUCCESS)
    }
    case types.CREATE_PROSPECTUS_ERROR: {
      return state.set('error', action.payload.error).set('createProspectusPhase', ERROR)
    }

    /* clear prospectus phase */
    case types.CLEAR_PROSPECTUS_PHASE: {
      return state
        .set('fetchProspectusPhase', INIT)
        .set('fetchProspectusByIdPhase', INIT)
        .set('createProspectusPhase', INIT)
    }

    default: {
      return state
    }
  }
}
