import React, { PureComponent, Fragment } from 'react'
// import IPropTypes from 'react-immutable-proptypes'
import { SortUpIcon, CloseIcon, SortDownIcon, DownCaretIcon, CaretRightIcon } from "../../../../../../components/icons"
import './styles.scss'
import _ from 'lodash'
import moment from 'moment'
import ReactModal from 'react-modal'
import UserContext from "../../../../../App/Context"
import { fetch } from '../../../../../../utils'
import $ from 'jquery'
import { DatePicker, Spin, Row, Col, Table } from 'antd';
import ResizeObserver from 'rc-resize-observer';
import Pagination from 'react-js-pagination';
import Loader from "../../../../../../components/Loader"

const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export default class ActiveClientsComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      activePage: 1,
      client_status: [],
      client_strategist: [],
      sortByKey: '',
      sortByType: 0,
      clientsData: [],
      isEmailModel: false,
      previousStatus: '',
      clientIsSendEmail: false,
      isLoading: false,
      preActivityStatus: '',
      totalPages: 0,
      no_follow_up: false,
      no_status: false,
      no_town_tour: false,
      overdue_followups: false
    }

    this.sortData = this.sortData.bind(this)
    this.selectedChange = this.selectedChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
  }

  componentDidMount() {
    document.title = "Active Clients | Jungler"
    this.paginationSetting()
    // this.getClientInformation()
  }

  componentDidUpdate(preProps){
    if(this.props.followUpPhase === "success"){
      this.getClientInformation()
      this.props.clearPhase()
    }
    if(this.props.followUpPhase === "error"){
      this.setState({ isLoading: false })
      this.props.clearPhase()
    }
    this.paginationSetting()
    this.sortSetting()
  }

  sortSetting(){
    const localData = JSON.parse(localStorage.getItem('agentV2clientSections'))
    const sortKey = localData && localData.sort
    if (sortKey) {
      this.setState({ sortByKey: sortKey })
    }
    const sortValue = localData && localData.sort_value
    if (sortValue) {
      this.setState({ sortByType: sortValue })
    }
  }

  paginationSetting(){
    let localData = JSON.parse(localStorage.getItem('agentV2clientSections'))
    let activeOffset = localData && localData.activeOffset ? localData.activeOffset : 1
    this.setState({ activePage: activeOffset })
  }

  getClientInformation() {
    const { activePage, client_status, client_strategist, sortByType, sortByKey } = this.state
    const convertedStartDate = this.props.selectedStartDate ? moment.utc(this.props.selectedStartDate).format('MM/DD/YYYY') : null
    const convertedEndDate = this.props.selectedEndDate ? moment.utc(this.props.selectedEndDate).format('MM/DD/YYYY') : null
    let agentclients = {
      "sort": sortByKey,
      "sort_value": sortByType,
      "limit": parseInt(this.props.selectResultEntry),
      "offset": activePage,
      "No Follow Up": this.props.no_follow_up ? 1 : 0,
      "No Status": this.props.no_status ? 1 : 0,
      "No Town Tour": this.props.no_town_tour ? 1 : 0,
      "Overdue Followups": this.props.overdue_follow_ups ? 1 : 0,
      "status": this.props.client_status,
      "strategist": this.props.client_strategist,
      "selectedStartDate": convertedStartDate,
      "selectedEndDate": convertedEndDate
    }
    this.props.fetchAgentAllClient(agentclients, "active")
    agentclients.allOffset = 1
    agentclients.activeOffset = activePage
    agentclients.closedffset = 1
    localStorage.setItem('agentV2clientSections', JSON.stringify(agentclients))
  }

  static getDerivedStateFromProps(props, state) {
    const data = _.get(props, 'agentAllClients.data.clients', [])
    if (props.phase !== 'success' && props.phase !== 'error') {
      return { isLoading: true }
    } else if (state.clientsData !== data) {
      return { isLoading: false, clientsData: data, selectResultEntry: props.selectResultEntry, totalPages: parseInt(props.sectionCounts && props.sectionCounts.totalActiveClients), phase: props.phase }
    }
    return null
  }

  selectedChange(id, clientData, e) {
    if (e.target.value != "Select a Status") {
      this.setState({ selectStatus: e.target.value, clientId: id, clientIsSendEmail: clientData.isSendEmail, preActivityStatus: clientData.status })
      if (e.target.value == "Initial Welcome Sent") {
        this.initialSelected(id, clientData)
      } else if (e.target.value == "Accepted Offer") {
        this.dealClick(id, clientData, "acceprt_offer_field")
      } else if (e.target.value == "Signed Contract") {
        this.dealClick(id, clientData, "signed_contract_field")
      } else if (e.target.value == "Closed") {
        this.dealClick(id, clientData, "closed_field")
      }
      else {
        let data1 = {
          client_id: id,
          activity_status: e.target.value,
          isAcceptOffer: false,
          isSendEmail: this.state.isSendEmail
        }
        this.props.updateClientUser(data1)
        this.getClientInformation()
      }
    }
  }

  initialSelected(id, clientData) {
    this.setState({ isEmailModel: true })
  }

  emailClickHandle(e) {
    this.setState({ isEmailModel: false })
    let data = {
      client_id: this.state.clientId
    }
    data.client_id = this.state.clientId
    if (this.state.clientIsSendEmail) {
      this.setState({ isSendEmail: false }, function () {
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Null"
        this.props.updateClientUser(data)
        this.getClientInformation()
      })

    } else {
      this.setState({ isSendEmail: !this.state.isSendEmail }, function () {
        data.isSendEmail = this.state.isSendEmail
        data.activity_status = "Initial Welcome Sent"
        this.props.updateClientUser(data)
        this.getClientInformation()
      })
    }
  }

  closeModal = () => {
    let c_id = this.state.clientId
    $('.uniQueselect-' + c_id).prop('selectedIndex', 0)
    this.setState({ isEmailModel: false, clientId: 0, selectStatus: this.state.preActivityStatus })
  }

  dealClick(clientID, clientData, field) {
    let agentData = {
      client_id: clientID
    }
    fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/${clientID}/deals`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json())
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          if (field === "acceprt_offer_field") {
            this.props.deleteDealData(response.data, clientData, this.state.selectStatus)
          } else {
            let dealsData = _.sortBy(response.data, function (o) {
              return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
            }).reverse();
            this.props.editDealSection(dealsData[0], clientData, this.state.selectStatus)
          }
        } else {
          //true, false- model open close, add, edit- button type, optional- error message
          if (field === "acceprt_offer_field") {
            this.props.openDealModal(true, "add", "optional", clientData, this.state.selectStatus)
          } else {
            this.props.openDealModal(true, "add", "mandatory", clientData, this.state.selectStatus)
          }
        }
      })
      .catch((error) => {
      })
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getClientInformation()
    })
  }

  onDateChange(clientId, index, date, dateString) {
    let selectDate = moment(date).format("YYYY-MM-DD")
    let data = {}
    data.follow_up_date = selectDate
    data.client_id = clientId
    data.agent_id = this.context.userData.id

    const clientsData = this.state.clientsData
    clientsData[index].follow_up_date = selectDate
    this.setState({ isLoading: true, clientsData }, () => {
      this.props.agentClientFollowUp(data)
    })
  }

  sortData(val) {
    let sortByType = this.state.sortByType
    if (val !== this.state.sortByKey) {
      sortByType = 0
    }
    else {
      sortByType === 0 ? sortByType = 1 : sortByType = 0
    }
    this.setState({ sortByKey: val, sortByType: sortByType }, () => {
      this.getClientInformation()
    })
  }

  render() {
    const { clientsData, isLoading, sortByKey, sortByType } = this.state
    return (
      <div>
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
          {/* This component for mobile only */}
          <div className="mob__view__container mob__view__control">
            <table className="table mob__custom__table">
              <tbody>
                {clientsData && clientsData.length > 0 ? (
                  clientsData.map((client, idx) => {
                    let priceRange =
                      (client.price_min
                        ? `$${client.price_min.toLocaleString()}`
                        : '$0') +
                      '-' +
                      (client.price_max
                        ? `$${client.price_max.toLocaleString()}`
                        : '$0');
                    if (priceRange == '$0-$0') {
                      priceRange = '$0';
                    }
                    let rentalBuget = client.rental_budget
                      ? `$${Number(client.rental_budget).toLocaleString()}`
                      : '$0';
                    if (rentalBuget == '$0') {
                      rentalBuget = '$0';
                    }
                    return (
                      <tr key={idx}>
                        <td
                          onClick={() =>
                            this.props.history.push(
                              `/agent/client-details/${client.id}`
                            )
                          }>
                          <div className="client__name">
                            {client.full_client_name}
                          </div>
                          <div className="client__budget">{priceRange}</div>
                          <div>
                            {rentalBuget &&
                            rentalBuget !== '' &&
                              rentalBuget &&
                              rentalBuget !== '$0' ? (
                              <b>Rental Budget: </b>
                            ) : (
                              ''
                            )}
                            {rentalBuget &&
                            rentalBuget !== '' &&
                              rentalBuget &&
                              rentalBuget !== '$0'
                              ? rentalBuget
                              : ''}
                          </div>
                        </td>
                        <td>
                          <span className="mob__follow__date">
                            <div className="btn__follow-up-date">
                              <div className="follow-up-date-label">
                                Follow up date
                              </div>
                              <DatePicker
                                className="datepicker__follow-up-date"
                                dropdownClassName="datepicker__overlay__follow-up-date"
                                onChange={this.onDateChange.bind(
                                  this,
                                  client.id,
                                  idx
                                )}
                                defaultValue={client && client.follow_up_date}
                                value={
                                  client &&
                                  client.follow_up_date &&
                                  client.follow_up_date != '' &&
                                  moment(client.follow_up_date)
                                }
                                format="MM/DD/YYYY"
                                inputReadOnly={true}
                                allowClear={false}
                                placeholder="N/A"
                              />
                              <DownCaretIcon className="caret-icon" />
                            </div>
                            <span
                              onClick={() =>
                                this.props.history.push(
                                  `/agent/client-details/${client.id}`
                                )
                              }>
                              <CaretRightIcon className="right-icon" />
                            </span>
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="blank__table__state">No Clients</div>
                )}
              </tbody>
            </table>
          </div>
          {/* End component for mobile only */}

          <div className="desktop__view">
            <div className="row">
              <div className="col-md-12">
                {_.size(clientsData) > 0 ? (
                  <div className="table-responsive">
                    {/* <table className="table custom-table">
                          <thead className="header-color">
                            <tr>
                              <th onClick={this.sortData.bind(this, 'full_client_name')} className="cursor__pointer">
                                <span className="table-column-title">Client Name</span>
                                <span className="table-column-sorter">
                                  {
                                    (sortByKey === "full_client_name" && sortByType === 0) ?
                                      <SortDownIcon />
                                      :
                                      <SortUpIcon />
                                  }

                                </span>
                              </th>
                              <th onClick={this.sortData.bind(this, 'price_min')} className="cursor__pointer">
                                <span className="table-column-title">Budget</span>
                                <span className="table-column-sorter">
                                  {
                                    (sortByKey === "price_min" && sortByType === 0) ?
                                      <SortDownIcon />
                                      :
                                      <SortUpIcon />
                                  }
                                </span>
                              </th>
                              <th onClick={this.sortData.bind(this, 'full_strategist_name')} className="cursor__pointer">
                                <span className="table-column-title">Strategist Name</span>
                                <span className="table-column-sorter">
                                  {
                                    (sortByKey === "full_strategist_name" && sortByType === 0) ?
                                      <SortDownIcon />
                                      :
                                      <SortUpIcon />
                                  }
                                </span>
                              </th>
                              {
                                (_.get( this.context.userData, 'role', '') === "agent") ?
                                <th onClick={this.sortData.bind(this, 'status')} className="cursor__pointer">
                                  <span className="table-column-title">Status</span>
                                  <span className="table-column-sorter">
                                    {
                                      (sortByKey === "status" && sortByType === 0) ?
                                        <SortDownIcon />
                                        :
                                        <SortUpIcon />
                                    }
                                  </span>
                                </th>
                                :''
                              }
                              <th onClick={this.sortData.bind(this, 'follow_up_date')} className="cursor__pointer">
                                <span className="table-column-title">Follow Up</span>
                                <span className="table-column-sorter">
                                  {
                                    (sortByKey === "follow_up_date" && sortByType === 0) ?
                                      <SortDownIcon />
                                      :
                                      <SortUpIcon />
                                  }
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              clientsData && clientsData.length > 0 &&
                              clientsData.map((client, idx) => {
                                let followupDate = moment(client.follow_up_date);
                                let nowDate = moment();
                                let overDueRedClass = 'text-green';
                                if (nowDate > followupDate) {
                                  // this is past date
                                  overDueRedClass = 'overDueRed';
                                }
                                let priceRange = (client.price_min ? `$${client.price_min.toLocaleString()}` : "$0")+"-"+(client.price_max ? `$${client.price_max.toLocaleString()}` : "$0")
                                if(priceRange == "$0-$0"){
                                  priceRange = "$0"
                                }
                                let rentalBuget = (client.rental_budget ? `$${Number(client.rental_budget).toLocaleString()}` : "$0")
                                if(rentalBuget == "$0"){
                                  rentalBuget = "$0"
                                }
                                return (
                                  <tr key={idx}>
                                    <td className="cursor__pointer" onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>{client.full_client_name}</td>
                                    <td
                                      className="cursor__pointer"
                                      onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>{priceRange}
                                      <div>
                                        {rentalBuget && (rentalBuget !== "" && rentalBuget && rentalBuget !== "$0") ? (<b>Rental Budget: </b>) : ""}
                                        {rentalBuget && (rentalBuget !== "" && rentalBuget && rentalBuget !== "$0") ? rentalBuget : ""}
                                      </div>
                                    </td>
                                    <td
                                      className="cursor__pointer"
                                      onClick={() => this.props.history.push(`/agent/client-details/${client.id}`)}>
                                      {client.strategists && client.strategists.map((strategist, idx) => {
                                        return <span key={idx}>{(idx ? ', ' : '') + strategist.full_strategist_name}</span>;
                                      })
                                      }
                                    </td>
                                    <td>
                                      {
                                        (_.get( this.context.userData, 'role', '') === "agent") ?
                                        <div className="material-textfield">
                                          <select
                                            defaultValue={client.status}
                                            value={client.status}
                                            className={"form-control custom-select material-textfield-input status__select uniQueselect-" + client.id}
                                            onChange={this.selectedChange.bind(this, client.id, client)}>
                                        <option>
                                              {(client.status !== "" && client.status !== "Null" && client.status !== "NULL" && client.status !== "Inspection Scheduled") ? client.status : 'Select a status'}
                                            </option>
                                            {
                                              (client.status !== "Initial Welcome Sent") &&
                                              <option value="Initial Welcome Sent" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Initial Welcome Sent</option>
                                            }
                                            {
                                              (client.status !== "Actively Engaged") &&
                                              <option value="Actively Engaged" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Actively Engaged</option>
                                            }
                                            {
                                              (client.status !== "Moderately Engaged") &&
                                              <option value="Moderately Engaged" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Moderately Engaged</option>
                                            }
                                            {
                                              (client.status !== "Accepted Offer") &&
                                              <option value="Accepted Offer" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Accepted Offer</option>
                                            }
                                            {
                                              (client.status !== "Signed Contract") &&
                                              <option value="Signed Contract" disabled={client.status === "" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Signed Contract</option>
                                            }
                                            {
                                              (client.status !== "Closed") &&
                                              <option value="Closed" disabled={client.status === "" || client.status === "Closed" || client.status === "Accepted Offer" }>Closed</option>
                                            }
                                            {
                                              (client.status !== "Unresponsive") &&
                                              <option value="Unresponsive" disabled={client.status === "Accepted Offer" || client.status === "Signed Contract" || client.status === "Closed" ? true : false }>Unresponsive</option>
                                            }
                                          </select>
                                          <label className="material-textfield-label">Status</label>
                                        </div>
                                        : ''
                                      }
                                    </td>
                                    <td>
                                      <div className="btn__follow-up-date">
                                        <div className="follow-up-date-label">Follow up date</div>
                                        <DatePicker
                                          className="datepicker__follow-up-date"
                                          dropdownClassName="datepicker__overlay__follow-up-date"
                                          onChange={this.onDateChange.bind(this, client.id, idx)}
                                          defaultValue={client && client.follow_up_date}
                                          value={(client && client.follow_up_date) && moment.utc(client.follow_up_date)}
                                          format="MM/DD/YYYY"
                                          inputReadOnly={true}
                                          allowClear={false}
                                          placeholder="N/A"
                                        />
                                        <DownCaretIcon className="caret-icon" />
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table> */}
                    <Table
                      className="virtual-table modify-table"
                      dataSource={clientsData}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'antd-table-row-dark' : ''
                      }
                      columns={getColumns({
                        sortByType,
                        sortByKey,
                        userData: this.context.userData,
                        sortData: this.sortData,
                        selectedChange: this.selectedChange,
                        onDateChange: this.onDateChange,
                      })}
                      pagination={false}
                      // scroll={{ x: 'max-content' }}
                      rowKey={(record) => record['id']}
                      onRow={(client, rowIndex) => {
                        return {
                          onClick: (event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.props.history.push(`/agent/client-details/${client.id}`)
                          }, // click row
                        };
                      }}
                    />
                  </div>
                ) : (
                  <div className="blank__table__state">No Clients</div>
                )}

                {clientsData &&
                  clientsData.length > 0 &&
                  this.state.selectResultEntry < this.state.totalPages &&
                  this.state.totalPages > 1 && (
                    <div className="pagination-container">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.props.selectResultEntry}
                        totalItemsCount={this.state.totalPages}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.isEmailModel}
            onRequestClose={this.closeModal}
            contentLabel="Are you sure?"
            portalClassName="react-modal">
            <div className="react-modal-dialog react-modal-dialog-centered">
              <div className="react-modal-body react-modal-body-sm">
                <button
                  type="button"
                  className="btn react-modal-close react-modal-close-sm"
                  onClick={this.closeModal}>
                  <CloseIcon />
                </button>
                <h5 className="react-modal-title mb__13">Are you sure?</h5>
                <p className="react-modal-subtitle mb__3">
                  This is just a confirmation that you sent an Initial Welcome
                  Email to the client. Click “Yes” below if you did.
                </p>
                <div className="text-right">
                  <button
                    onClick={this.emailClickHandle.bind(this)}
                    className="btn btn__btn btn-dark w__150">
                    Yes, Sent
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
        </Spin>
      </div>
    );
  }
}

const getColumns = ({
  sortByType,
  sortByKey,
  userData,
  sortData,
  selectedChange,
  onDateChange,
}) => {
  let columns = [
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('full_client_name')}>
              <Col xs={12}>Client Name</Col>
              <Col xs={12}>
                {sortByKey === 'full_client_name' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'full_client_name',
      dataIndex: 'full_client_name',
      render: (text, client, index) => {
        return (
          <Fragment>
            {
              <span
                style={{
                  color:
                    client.status === 'Closed' || client.flag == 'no'
                      ? 'black'
                      : 'red',
                }}>
                {client.full_client_name}
              </span>
            }
          </Fragment>
        );
      },
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('price_min')}>
              <Col xs={12}>Budget</Col>
              <Col xs={12}>
                {sortByKey === 'price_min' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'price_range',
      dataIndex: 'price_range',
      render: (text, client, index) => {
        let priceRange =
          (client.price_min ? `$${client.price_min.toLocaleString()}` : '$0') +
          '-' +
          (client.price_max ? `$${client.price_max.toLocaleString()}` : '$0');
        if (priceRange == '$0-$0') {
          priceRange = '$0';
        }
        let rentalBuget = client.rental_budget
          ? `$${Number(client.rental_budget).toLocaleString()}`
          : '$0';
        if (rentalBuget == '$0') {
          rentalBuget = '$0';
        }
        return (
          <Fragment>
            {priceRange}
            <div>
              {rentalBuget &&
              rentalBuget !== '' && rentalBuget && rentalBuget !== '$0' ? (
                <b>Rental Budget: </b>
              ) : (
                ''
              )}
              {rentalBuget &&
              rentalBuget !== '' && rentalBuget && rentalBuget !== '$0'
                ? rentalBuget
                : ''}
            </div>
          </Fragment>
        );
      },
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('full_strategist_name')}>
              <Col xs={12}>Strategist</Col>
              <Col xs={12}>
                {sortByKey === 'full_strategist_name' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'full_strategist_name',
      dataIndex: 'full_strategist_name',
      render: (text, client, index) => {
        return (
          <Fragment>
            {client.strategists &&
              client.strategists.map((strategist, idx) => {
                return (
                  <span key={idx}>
                    {(idx ? ', ' : '') + strategist.full_strategist_name}
                  </span>
                );
              })}
          </Fragment>
        );
      }
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('assigned_date')}>
              <Col xs={12}>Assigned Date</Col>
              <Col xs={12}>
                {sortByKey === 'assigned_date' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'assigned_date',
      dataIndex: 'assigned_date',
      render: (text, client, index) => {
        return (
          <Fragment>
            <span key={index}>
              {moment(text).format('MM/DD/YYYY')}
            </span>
          </Fragment>        
        )
      }
    },    
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('status')}>
              <Col xs={12}>Status</Col>
              <Col xs={12}>
                {sortByKey === 'status' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'status',
      dataIndex: 'status',
      render: (text, client, index) => {
        return (
          <Fragment>
            <div className="material-textfield" onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}>
              <select
                // defaultValue={client.status}
                value={client.status}
                className={
                  'form-control custom-select material-textfield-input status__select uniQueselect-' +
                  client.id
                }
                onChange={(event) => selectedChange(client.id, client, event)}>
                <option>
                  {client.status !== '' &&
                  client.status !== 'Null' &&
                  client.status !== 'NULL' &&
                  client.status !== 'Inspection Scheduled'
                    ? client.status
                    : 'Select a status'}
                </option>
                {client.status !== 'Initial Welcome Sent' && (
                  <option
                    value="Initial Welcome Sent"
                    disabled={
                      client.status === 'Accepted Offer' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Initial Welcome Sent
                  </option>
                )}
                {client.status !== 'Actively Engaged' && (
                  <option
                    value="Actively Engaged"
                    disabled={
                      client.status === 'Accepted Offer' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Actively Engaged
                  </option>
                )}
                {client.status !== 'Moderately Engaged' && (
                  <option
                    value="Moderately Engaged"
                    disabled={
                      client.status === 'Accepted Offer' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Moderately Engaged
                  </option>
                )}
                {client.status !== 'Accepted Offer' && (
                  <option
                    value="Accepted Offer"
                    disabled={
                      client.status === 'Accepted Offer' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Accepted Offer
                  </option>
                )}
                {client.status !== 'Signed Contract' && (
                  <option
                    value="Signed Contract"
                    disabled={
                      client.status === '' ||
                      client.status === 'Moderately Engaged' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Signed Contract
                  </option>
                )}
                {client.status !== 'Closed' && (
                  <option
                    value="Closed"
                    disabled={
                      client.status === '' ||
                      client.status === 'Moderately Engaged' ||
                      client.status === 'Accepted Offer' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Closed
                  </option>
                )}
                {client.status !== 'Unresponsive' && (
                  <option
                    value="Unresponsive"
                    disabled={
                      client.status === 'Accepted Offer' ||
                      client.status === 'Signed Contract' ||
                      client.status === 'Closed'
                        ? true
                        : false
                    }>
                    Unresponsive
                  </option>
                )}
              </select>
              <label className="material-textfield-label">Status</label>
            </div>
          </Fragment>
        );
      }
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row
              className="cursor__pointer"
              onClick={() => sortData('follow_up_date')}>
              <Col xs={12}>Follow Up</Col>
              <Col xs={12}>
                {sortByKey === 'follow_up_date' && sortByType === 0 ? (
                  <SortDownIcon />
                ) : (
                  <SortUpIcon />
                )}
              </Col>
            </Row>
          </Fragment>
        );
      },
      key: 'follow_up_date',
      dataIndex: 'follow_up_date',
      render: (text, client, index) => {
        return (
          <Fragment>
            <div className="btn__follow-up-date" onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}>
              <div className="follow-up-date-label">Follow up date</div>
              <DatePicker
                className="datepicker__follow-up-date"
                dropdownClassName="datepicker__overlay__follow-up-date"
                onChange={(date, dateString) => onDateChange(client.id, index, date, dateString)}
                defaultValue={client && client.follow_up_date}
                value={
                  client &&
                  client.follow_up_date &&
                  client.follow_up_date != '' &&
                  moment.utc(client.follow_up_date)
                }
                format="MM/DD/YYYY"
                inputReadOnly={true}
                allowClear={false}
                placeholder="N/A"
              />
              <DownCaretIcon className="caret-icon" />
            </div>
          </Fragment>
        );
      },
    },
  ];

  if (_.get(userData, 'role', '') !== 'agent') {
    columns = columns.filter((el) => el.dataIndex !== 'status');
  }
  return columns;
};