import { connect } from 'react-redux'
import OperationReportForm from './component'

import { getFilterParams, 
         getClientList,
         addUpdateClient,
       } from '../../../store/dashboard/duck'
import { fetchUser } from '../../../store/user/duck'
import { getOperationList } from '../../../store/analytics/duck'

const OperationReporting = connect(
  // Map state to props
  (state) => ({
  	filterParams: state.dashboard.filterParams,
    clientData: state.dashboard.clientData,
    operationListPhase: state.analytics.operationListPhase,
    operationListData: state.analytics.operationListData
  }),
  // Map actions to props
  {
  	getFilterParams,
  	fetchUser,
  	getClientList,
    addUpdateClient,
    getOperationList,
  }
)(OperationReportForm)
export default OperationReporting
