import React, { useState, Fragment, useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getPlace,
  getLatLng,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import get from 'lodash/get';
import filter from 'lodash/filter'


const AutoComplete = props => {
  const [address, setAddress] = useState('');
  const [suggestionsAr, setSuggestions] = useState("")

  useEffect(() => {
    setAddress(get(props, "field.value", ""))
  }, [])

  const handleSelect = addr => {
    geocodeByAddress(addr)
      .then(async results => {
        const result = results[0];
        result.location = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        };


        const selectedSuggestion = await filter(suggestionsAr, { placeId: get(result, "place_id", "") })
        result.google_primary_id = get(selectedSuggestion, "[0].id")
        setAddress(get(result, 'formatted_address'));
        props.setAddress(result);
      })
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  };
  const handleAddressChange = address => {
    setAddress(address);
  };

  return (
    <PlacesAutocomplete
      value={address.replace(/\d{5}/, '')}
      className="form-control material-textfield-input textfield-input-lg"
      onChange={handleAddressChange}
      onSelect={handleSelect}
      {...props}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          setSuggestions(suggestions)
        return (
          <Fragment>
            <input
              value={address}
              {...getInputProps({
                required: true,
                className:
                  'form-control material-textfield-input textfield-input-lg text-input addressAutoSuggest'
                //   ...props
              })}
              required
            />
            <div className={'autocomplete-dropdown-container'}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';

                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </Fragment>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AutoComplete;
