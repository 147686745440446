import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
     ///////////////////////
   /// deals apis ///
///////////////////////
 */
// fetch town and district school
export const fetchTownSchoolDistrict = (data) => {

  return new Promise((resolve, reject) => {
    resolve({})
  })

  // return fetch(`${HOSTNAME_V2}/town/get_attom_school`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(data),
  // })
  //   .then((res) => res.json())
  //   .then((payload) => payload)
  //   .catch((error) => {
  //     throw error
  //   })
}
// fetch client deal
export const fetchClientDeal = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/deals`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
      .then((res) => res.json())
      .then((payload) => payload)
      .catch((error) => {
        throw error
      })
}
// create update client deal
export const createUpdateClientDeal = (data) => {
    const type = data.dealId ? 'PUT' : 'POST'
    const url = data.dealId ? `${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/deals/${data.dealId}` : `${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/deals`
    return fetch(url, {
      method: type,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((payload) => payload)
        .catch((error) => {
          throw error
        })
}
// fetch school district
  export const fetchSchoolDistrict = (data) => {
    return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/school-districts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
        .then((res) => res.json())
        .then((payload) => payload)
        .catch((error) => {
          throw error
        })
  }
// delete client deal
export const deleteClientDeal = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/deals`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}