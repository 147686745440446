import { connect } from 'react-redux'

import CommuteTableComponent from './component'

const CommuteTableContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(CommuteTableComponent)

export default CommuteTableContainer
