import React, { Fragment } from 'react'
import { SortDownIcon, SortUpIcon } from '../../../../../components/icons'

export default function TableHeader({ sortByKey, sortByType, sortAgentData }) {
  // call sort data function on call
  const sortData = (key) => sortAgentData(key)
  return (
    <Fragment>
      <ul className="your__agents__list__header">
        <li className="cursor__pointer" onClick={() => sortData('clientName')}>
          <span className="table-column-title">Client Name</span>
          <span className="table-column-sorter">
            {sortByKey === 'clientName' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </li>
        <li className="cursor__pointer" onClick={() => sortData('budget')}>
          <span className="table-column-title">Budget</span>
          <span className="table-column-sorter">
            {sortByKey === 'budget' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </li>
        <li className="cursor__pointer" onClick={() => sortData('status')}>
          <span className="table-column-title">Status</span>
          <span className="table-column-sorter">
            {sortByKey === 'status' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </li>
      </ul>
    </Fragment>
  )
}
