import * as types from './action-types'
/*
    ////////////////////////////////////
  /// interview action ///
///////////////////////////////////
*/

// fetch prospect applicants
export const fetchProspectApplicants = (payload = null) => ({
  type: types.FETCH_PROSPECT_APPLICANTS_LIST,
  payload
})

// fetch rejected applicants
export const fetchRejectedApplicants = (payload = null) => ({
  type: types.FETCH_REJECTED_APPLICANTS_LIST,
  payload
})

// approve an ajent
export const approveApplicant = (payload) => ({
  type: types.APPLICANT_APPROVED,
  payload
})

// reject an agent
export const rejectApplicant = (payload = null) => ({
  type: types.APPLICANT_REJECTED,
  payload
})
