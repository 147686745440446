import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const SortableItem = SortableElement(
  ({ value, onRemove, onFeatured, imageRotate, checkedClick, setLoaded, imageLoaded }) => (
    <div className="col-sm-6 col-md-3" key={`input-${value}`}>
      <div className="uploaded__images__card">
        <div className="uploaded__images__thumb__wrapp">
          <img
            className="uploaded__images__thumb"
            id={get(value, 'id')}
            src={get(value, 'url')}
            alt={get(value, 'id')}
            style={{transform: get(value, 'rotateStyle', ''), display: get(value, 'loaded', '')}}
            onLoad={() => imageLoaded(get(value, 'id'))}
          />
        </div>
        <div className="uploaded__images__card__info">
          <button
            className="btn btn__btn btn-dark btn-rotate"
            onClick={() => imageRotate(value)}
          >
            Rotate
          </button>
          {get(value, 'featured') ? (
            'Featured'
          ) :
            cookies.get('user').role == "business development" || cookies.get('user').role == "local" ?
              // cookies.get('user').id == get(value, 'created_by')? 
              // (
              //   <button
              //     className="btn btn__btn btn-set-featured"
              //     onClick={() => onFeatured(value)}
              //   >
              //     Set Featured
              //   </button>
              // )
              // :
              <div></div> :
              (
                <button
                  className="btn btn__btn btn-set-featured"
                  onClick={() => onFeatured(value)}
                >
                  Set Featured
                </button>
              )}
          <button
            className="btn btn__btn btn-link ml__auto"
            onClick={() => checkedClick(value)}
          >
            <img src="./img/checked-ic.png" width="20"/>
          </button>
          {
            cookies.get('user').role == "business development" || cookies.get('user').role == "local" ?
              cookies.get('user').id == get(value, 'created_by') ?
                <button
                  className="btn btn__btn btn-link"
                  onClick={() => onRemove(value)}
                >
                  <img src="./img/close-fill.svg" width="20" />
                </button> :
              <div></div> :
            <button
              className="btn btn__btn btn-link"
              onClick={() => onRemove(value)}
            >
              <img src="./img/close-fill.svg" width="20" />
            </button>
          }

          {/* <i onClick={() => checkedClick(value)} className="fa fa-check-circle" aria-hidden="true"></i>
          <i onClick={() => onRemove(value)} className="fa fa-times" aria-hidden="true"></i> */}


        </div>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, onRemove, onFeatured, imageRotate, imageLoaded, checkedClick, disabled }) => {
    return (

        <ui>
        {!isEmpty(items) && items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            onRemove={onRemove}
            checkedClick={checkedClick}
            onFeatured={onFeatured}
            imageRotate={imageRotate}
            index={index}
            value={value}
            disabled = {disabled}
            imageLoaded={imageLoaded}
          />
        ))}
        </ui>
    );
  }
);

class SortableComponent extends Component {

  render() {

    const { image, data, disabled }  = this.props

    return (
      <>
      <div className="col-sm-6 col-md-3">
      <div className="uploaded__images__card">
        <div className="featured__label">Featured </div>
        <div className="uploaded__images__thumb__wrapp default__featured">
          <img
            className="uploaded__images__thumb"
            id={get(image, 'id')}
            src={get(image, 'url')}
            alt={get(image, 'id')}
          />
        </div>
        <div className="uploaded__images__card__info">
          {/* <button
            className="btn btn__btn btn-rotate btn-dark"
            onClick={() => imageRotate(image)}
          >
            Rotate
          </button> */}
          {get(data, 'featured') && (
            'Featured'
          )}
        </div>
      </div>
    </div>
      <SortableList
        items={this.props.data}
        image={this.props.image}
        onRemove={this.props.onRemove}
        onFeatured={this.props.onFeatured}
        imageRotate={this.props.imageRotate}
        imageLoaded={this.props.imageLoaded}
        checkedClick={this.props.checkedClick}
        onSortEnd={this.props.onSortEnd}
        axis="xy"
        disabled = {disabled}
      />
      </>
    );
  }
}

export default SortableComponent;
