import { connect } from 'react-redux'

import {
  getStrategistAvaillableDates,
  createAgentAppointment,
  clearStrategistAppointment,
  fetchAgentAvailability,
} from '../../../../../store/strategistV2/appointment/actions'

import AgentAppointmentComponent from './AgentAppointment'

const ClientDetailsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    createAgentPhase: state.strategistV2.appointment.createAgentPhase,
    fetchAgentAvailabilityPhase: state.strategistV2.appointment.fetchAgentAvailabilityPhase,
    agentAvailability: state.strategistV2.appointment.agentAvailability,
  }),
  // Map actions to dispatch and props
  {
    fetchAgentAvailability,
    createAgentAppointment,
    getStrategistAvaillableDates,
    clearStrategistAppointment,
  }
)(AgentAppointmentComponent)

export default ClientDetailsContainer
