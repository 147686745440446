import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getAdministratorDashboardData = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/dashboard-statistics?start=${data.from}&end=${data.to}`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getClosedClientsData = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/closedClients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getAccountingReportData = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/accountingReportAnalytcis`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

//get user by id
export const getUserById = (data) => {
  return fetch(`${HOSTNAME_V2}/users/get_user_by_id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
//get user by id
export const addUpdateStrategist = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/strategies`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
//get user by id
export const getClientStrategist = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/strategies`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
//get user by id
export const createStrategist = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/strategies`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// change client status data
export const changeClientStatus = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/strategies/recommended-town-status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getBDSourceReportData = (data) => {
  return fetch(`${HOSTNAME_V2}/analytics/bdSourceAnalytics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}