import React, { PureComponent, Fragment } from "react"
import { Link, Route, Redirect, Switch } from "react-router-dom"
import { Popover, DatePicker } from 'antd'
import TopNavigation from "../../../../components/TopNavigation/container"
import SidebarNavigation from "../../../../components/SidebarNavigation/component"
import {
  SortUpIcon,
  ColoredUserIcon,
  FilterIcon,
  CaretRightIcon,
  DownCaretIcon,
  CloseIcon,
  NotReadys
} from '../../../../components/icons'
import AllClients from './partials/AllClient/container'
import _ from "lodash"
import axios from 'axios'
import ActiveClients from './partials/ActiveClients/container'
import ClosedClients from './partials/ClosedClients/container'
import ReactModal from 'react-modal'
import moment from 'moment'
import UserContext from "../../../App/Context"
import $ from 'jquery'
import NumberFormat from 'react-number-format'
import PlacesAutocomplete, { geocodeByAddress, getPlace, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
import "./styles.scss"
import config from '../../../../config';
import Cookies from 'universal-cookie'
import { logoutInvalidRequest } from "../../../../utils/helper"
const cookies = new Cookies()

let _user = ''
const routeTab = {
  "": 0,
  "/agent/clients/all-clients": 0,
  "/agent/clients/active-clients": 1,
  "/agent/clients/closed-clients": 2
}

export default class ClientsComponent extends PureComponent {
  static contextType = UserContext;
  constructor() {
    super()
    this.state = {
      loginUserId: 0,
      activeTab: 0,
      visible: false,
      modalIsOpen: false,
      deleteDealModal: false,
      dealData: [],
      clientData: [],
      dealType: "sales",
      address: '',
      dealStatus: '',
      amount: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      isSelect: false,
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      lease_start_date: '',
      lease_term: '',
      buttonType: 'add',
      client_id: '',
      selectResultEntry: 20,
      activeClientCount: 0,
      closedClientCount: 0,
      no_follow_up: false,
      assignedDate: false,
      selectedStartDate: "",
      selectedEndDate: "",
      no_status: false,
      no_town_tour: false,
      overdue_follow_ups: false,
      client_status: [],
      client_strategist: [],
      status: false,
      strategists: false,
      statusDropdown: false,
      strategistsDropDown: false,
      sectionCounts: {
        totalActiveClients: 0,
        totalClients: 0,
        totalClosedClients: 0
      },
      strategistsData: [],
      filterCount: 0
    }
  }

  filterVisible = visible => {
    this.setState({ visible })
  }

  componentDidMount() {
    this.pathSetting()
    // when comes from dashboard with path so check which type of state in location
    let no_follow_up = _.get(this.props, 'location.state.no_follow_up', false)
    let no_status = _.get(this.props, 'location.state.no_status', false)
    let no_town_tour = _.get(this.props, 'location.state.no_town_tour', false)
    let overdue_follow_ups = _.get(this.props, 'location.state.overdue_follow_ups', false)
    let assignedDate = _.get(this.props, 'location.state.assignedDate', false)
    let from = _.get(this.props, 'location.state.from', false)
    let to = _.get(this.props, 'location.state.to', false)
    let selectedStartDate = from
    let selectedEndDate = to

    const state = _.get(this.props, 'location.state')

    let status = _.get(this.props, 'location.state.status', false)

    let localData = JSON.parse(localStorage.getItem('agentV2clientSections'))

    if (!localData || !localData.limit) {
      localData = {
        filterCount: 0,
        statusDropdown: false,
        strategistsDropDown: false,
        "No Follow Up": false,
        "No Status": false,
        "No Town Tour": false,
        "Overdue Followups": false,
        sort: '',
        sort_value: 0,
        limit: 20,
        offset: 1,
        status: [],
        strategist: [],
      }
    }
    let filterCount = 0

    // From dashboard if below options clicked then set options in filter
    if (no_follow_up || no_status || no_town_tour || overdue_follow_ups || assignedDate || from || to) {
      // To handle No Follow Up or Overdue Followups any one selected 
      /*if ((no_follow_up && !localData["No Follow Up"]) || (overdue_follow_ups && !localData["Overdue Followups"])) {
        filterCount = 1
      } else if (no_town_tour && !localData["No Town Tour"]) {
        filterCount = 1
      }
      else if (no_status && !localData["No Status"]) {
        filterCount = 1
      } 
      else if (overdue_follow_ups && !localData["Overdue Followups"]) {
        filterCount = 1
      }*/

      // localData["No Follow Up"] = no_follow_up ? no_follow_up : localData["No Follow Up"]
      // localData["No Status"] = no_status ? no_status : localData["No Status"]
      localData["No Town Tour"] = no_town_tour ? no_town_tour : localData["No Town Tour"]
      // localData["Overdue Followups"] = overdue_follow_ups ? overdue_follow_ups : localData["Overdue Followups"]

      if (this.props.history && this.props.history.location && this.props.history.location.state) {
        let state = this.props.history.location.state
        delete state.no_follow_up
        delete state.no_status
        delete state.no_town_tour
        delete state.overdue_follow_ups
        delete state.assignedDate
        delete state.from
        delete state.to
        this.props.history.replace({ ...history.location, state })
      }
    } else if (status) { // From dashboard if status clicked then set options in filter
      const isStatus = _.filter(localData.status, (s) => {
        return s === status
      })
      if (!isStatus || _.size(isStatus) === 0) {
        // filterCount = localData.status && _.size(localData.status) ? 0 : 1
        localData.status = localData && localData.status ? localData.status : []
        localData.statusDropdown = true
        localData.status.push(status)
        if (this.props.history && this.props.history.location && this.props.history.location.state) {
          let state = this.props.history.location.state
          delete state.status
          this.props.history.replace({ ...history.location, state })
        }
      }
    } else { // To handle No Follow Up or Overdue Followups any one selected 
      no_follow_up = localData["No Follow Up"]
      overdue_follow_ups = localData["Overdue Followups"]
    }

    if (no_follow_up) { // To handle No Follow Up or Overdue Followups any one selected 
      overdue_follow_ups = 0
    } else if (overdue_follow_ups) {
      no_follow_up = 0
    } else if (no_status) {
      // if (!localData["No Status"]) {
      //   filterCount = 1
      // }
      // if (localData.status && _.size(localData.status)) {
      //   localData.filterCount = parseInt(localData.filterCount) - 1
      // }
      localData.status = []
    } else if (status && localData.status && _.size(localData.status)) {
      // if (localData["No Status"]) {
      //   localData.filterCount = parseInt(localData.filterCount) - 1
      // }
      no_status = 0
    }
    // total applicable filter count
    if (no_follow_up) {
      filterCount++
    }
    if (no_status) {
      filterCount++
    }
    if (no_town_tour || localData["No Town Tour"]) {
      filterCount++
    }
    if (overdue_follow_ups) {
      filterCount++
    }

    if(!assignedDate) {
      if(localData.assignedDate) {
        assignedDate = localData.assignedDate
      }
    }

    if(to && from) {
      assignedDate = true
    }

    if(localData.selectedStartDate && localData.selectedEndDate) {
      selectedStartDate = localData.selectedStartDate
      selectedEndDate = localData.selectedEndDate
    }

    if (assignedDate) {
      filterCount++
    }
    if (localData.status && _.size(localData.status)) {
      filterCount++
    }
    if (localData.strategist && _.size(localData.strategist)) {
      filterCount++
    }
    this.setState({
        // filterCount: localData.filterCount && parseInt(localData.filterCount) ? (parseInt(localData.filterCount) + filterCount) : filterCount,
        filterCount,
        "no_follow_up": no_follow_up,
        "no_status": no_status,
        "no_town_tour": no_town_tour ? no_town_tour : localData["No Town Tour"],
        "overdue_follow_ups": overdue_follow_ups,
        "assignedDate": assignedDate,
        sortByKey: localData.sort,
        sortByType: localData.sort_value,
        selectResultEntry: parseInt(localData.limit),
        activePage: localData.offset,
        client_status: localData.status,
        status: localData.status && _.size(localData.status) ? true : false,
        statusDropdown: localData.status && _.size(localData.status) ? true : false,
        client_strategist: localData.strategist,
        strategists: localData.strategist && _.size(localData.strategist) ? true : false,
        strategistsDropDown: localData.strategist && _.size(localData.strategist) ? true : false,
        from, 
        to,
        selectedStartDate,
        selectedEndDate
    }, () => {
      this.getSectionData()
    })
  }

  getSectionData = async (init) => {
    const { activePage, client_status, client_strategist, sortByType, sortByKey, selectResultEntry, no_follow_up, no_status, no_town_tour, overdue_follow_ups, assignedDate, from, to, selectedStartDate, selectedEndDate } = this.state
    const convertedStartDate = selectedStartDate ? moment.utc(selectedStartDate).format('MM/DD/YYYY') : null
    const convertedEndDate = selectedEndDate ? moment.utc(selectedEndDate).format('MM/DD/YYYY') : null

    _user = cookies.get('user')
    let localData = JSON.parse(localStorage.getItem('agentV2clientSections'))
    let offsetVal = 1
    if(this.state.activeTab === 0){
      let allClientOffset =  localData && localData.allOffset ? localData.allOffset : 1
      offsetVal = allClientOffset
    }else if(this.state.activeTab === 1){
      let activeOffset = localData && localData.activeOffset ? localData.activeOffset : 1
      offsetVal = activeOffset
    }else if(this.state.activeTab === 2){
      let closedffset = localData && localData.closedffset ? localData.closedffset : 1
      offsetVal = closedffset
    }

    let data = {
      "sort": sortByKey,
      "sort_value": sortByType,
      "limit": parseInt(selectResultEntry),
      "offset": offsetVal,
      "No Follow Up": no_follow_up ? 1 : 0,
      "No Status": no_status ? 1 : 0,
      "No Town Tour": no_town_tour ? 1 : 0,
      "Overdue Followups": overdue_follow_ups ? 1 : 0,
      "assignedDate": assignedDate ? 1 : 0,
      "status": client_status,
      "strategist": client_strategist,
      "from": from,
      "to": to,
      "selectedStartDate": convertedStartDate,
      "selectedEndDate": convertedEndDate
    }

    // here 0 = all client, 1= active clients, 2= closed clients
    if (this.state.activeTab === 0){
      data.type = "all"
      this.props.fetchAgentAllClient(data)
    } else if (this.state.activeTab === 1){
      data.type = "active"
      this.props.fetchAgentAllClient(data)
    } else if (this.state.activeTab === 2){
      data.type = "closed"
      this.props.fetchAgentAllClient(data)
    }
    data.filterCount = this.state.filterCount
    if (this.state.status) {
      data.statusDropdown = true
    }
    if (this.state.strategists) {
      data.strategistsDropDown = true
    }

    let allClientOffset =  localData && localData.allOffset ? localData.allOffset : 1
    data.allOffset = allClientOffset
    let activeOffset = localData && localData.activeOffset ? localData.activeOffset : 1
    data.activeOffset = activeOffset
    let closedffset = localData && localData.closedffset ? localData.closedffset : 1
    data.closedffset = closedffset

    if(this.state.activeTab === 0){
      data.activeOffset = 1
      data.closedffset = 1
    }else if(this.state.activeTab === 1){
      data.allOffset = 1
      data.closedffset = 1
    }else if(this.state.activeTab === 2){
      data.allOffset = 1
      data.activeOffset = 1
    }

    localStorage.setItem('agentV2clientSections', JSON.stringify(data))
  }

  static getDerivedStateFromProps(props, state) {
    const strategistsData = _.get(props, 'agentAllClients.data.strategists', [])
    const sectionCounts = _.get(props, 'agentAllClients.data.counts',
      {
        totalActiveClients: 0,
        totalClients: 0,
        totalClosedClients: 0
      })

    if (!_.isEqual(sectionCounts, state.sectionCounts) || !_.isEqual(strategistsData, state.strategistsData)) {
      return {
        sectionCounts,
        strategistsData
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.pathSetting()
    if(this.props.clientDealPhase === "success"){
      this.updateClientStatus()
      this.props.clearAgentDatePhase()
    }
    if(this.props.clientDealPhase === "error"){
      this.props.clearAgentDatePhase()
      this.closeModal()
    }
    if(this.props.updateUserPhase === "success"){
      this.props.clearPhase()
      this.getSectionData()
    }
    if(this.props.deleteClientDealPhase === "success"){
      this.props.clearAgentDatePhase()
      this.deleteUpdateClientStatus()
      this.setState({ deleteDealModal: false })
    }
  }

  updateClientStatus(){
    let data = {}
    data.client_id    = this.state.client_id
    data.activity_status = this.state.dealStatus
    data.isAcceptOffer = true
    data.isSendEmail = false
    data.address =  this.state.address
    data.amount =  parseInt(this.state.amount.toString().replace(/\D/g,''))
    data.buy_side_commission = parseFloat(this.state.buy_side_commission)
    data.accepted_offer_date =  moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')
    data.expected_close_date =  moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
    data.lease_start_date =  moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
    data.lease_term = this.state.lease_term  
    data.deal_type = this.state.dealType === "sales" ? 0 : 1
    data.agent_notes = this.state.agent_notes
    data.sj_comission = this.state.misc_fee ? parseInt(this.state.misc_fee.toString().replace(/\D/g,'')) : 0
    if(this.state.signed_contract_date){
      data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
    }
    this.props.updateClientUser(data)
    this.closeModal()
  }

  deleteUpdateClientStatus(){
    let data = {}
    data.client_id    =  this.state.client_id
    data.isAcceptOffer = false
    data.isSendEmail = false
    data.activity_status = "Null"
    this.props.updateClientUser(data)
  }

  pathSetting() {
    // let currentUrl = this.props.location.pathname.split(`${this.props.match.url}`)[1]
    const currentUrl = this.props.location.pathname
    this.setState({ activeTab: routeTab[currentUrl] })
  }

  handleAddressChange(address) {
    this.setState({ address: address, isSelect: false }, () => {
    })
  }

  handleSelect = (addr) => {
    let that = this
    geocodeByAddress(addr)
      .then(results =>
        that.setState({ address: results[0].formatted_address, isSelect: true })
      )
      .then(latLng => console.log())
      .catch(error => console.error('Error', error));
  }

  // changeProps function for change component
  changeProps(val) {
    this.setState({ activeTab: val }, () => {
      this.getSectionData()
    })
    if (val === 0) {
      this.props.history.push(`${this.props.match.url}/all-clients`)
    } else if (val === 1) {
      this.props.history.push(`${this.props.match.url}/active-clients`)
    } else if (val === 2) {
      this.props.history.push(`${this.props.match.url}/closed-clients`)
    }
  }

  //changeProps for mobile function
  changePropsForMobile(e) {
    let val = parseInt(e.target.value)
    this.setState({ activeTab: val }, () => {
      this.getSectionData()
    })
    if (val === 0) {
      this.props.history.push(`${this.props.match.url}/all-clients`)
    } else if (val === 1) {
      this.props.history.push(`${this.props.match.url}/active-clients`)
    } else if (val === 2) {
      this.props.history.push(`${this.props.match.url}/closed-clients`)
    }
  }

  handleDateChange = (date, type) => {
    if (type === "start") {
      this.setState({ selectedStartDate: date, selectedEndDate: "" })
    }
    if (type === "end") {
      this.setState({ selectedEndDate: date }, () => {
        this.forceUpdate()
        this.getSectionData()
      })
    }

  }

  handleChange(event) {
    if(event.target.value === "sales" || event.target.value === "rental" ){
      this.setState({ err: {} })
    }
    this.setState({ [event.target.name]: event.target.value })
  }

  resultChange(e) {
    this.setState({ selectResultEntry: e.target.value }, () => {
      this.getSectionData()
    })
  }

  // This is function when add accept offer, signed contract and closed deal
  openDealModal = (isOpen, buttonType, errorMessage, clientData, deal_status) => {
    let errMsg = ''
    if(deal_status === "Signed Contract" || deal_status === "Closed"){
      errMsg = "mandatory"
    }else{
      errMsg = "optional"
    }
    this.setState({
      modalIsOpen: isOpen,
      dealStatus: deal_status,
      clientData: clientData,
      buttonType: buttonType,
      client_id: clientData.id,
      errorMessage: errMsg
    })
  }

  closeModal = (data) => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({
      modalIsOpen: false,
      isSelect: false,
      dealStatus: '',
      dealData: [],
      buttonType: 'add',
      clientData: [],
      amount: '',
      accepted_offer: '',
      signed_contract_date: '',
      expected_close_date: '',
      dealType: "sales",
      dealStatus: '',
      address: '',
      buy_side_commission: '',
      misc_fee: '',
      agent_notes: '',
      err: {},
      errorMessage: "optional",
      lease_start_date: '',
      lease_term: '',
      dealType: "sales"
    })
  }

  // If deal is already created and click on accepted offer so first delete deal
  deleteDealData = (dealData, clientData, deal_status) => {
    this.setState({
      deleteDealModal: true,
      client_id: clientData.id,
      dealStatus: deal_status,
      dealData: dealData
    })
  }

  // Delete deal button when get accepted offer created
  deleteDealButton(e) {
    this.setState({ buttonType: "edit" })
    let dealZeroPosition = _.sortBy(this.state.dealData, function (o) {
      return new moment(o.created).format('YYYY-MM-DD');
    }).reverse()
    let dealDlt = {}
    dealDlt.client_id = this.state.client_id
    dealDlt.deal_id = _.get(dealZeroPosition[0], 'id')
    this.props.deleteClientDealByAgent(dealDlt)
  }

  // close deal modal
  deleteDealCloseModal = () => {
    this.CloseTabSetStatus(this.state.client_id)
    this.setState({ deleteDealModal: false, dealData: [], preStatus: this.state.preStatus })
  }

  // If deal already created but click on signed contract or closed deal so work this function
  editDealSection = (dealData, clientData, deal_status) => {
    let errMsg = ''
    if(deal_status === "Signed Contract" || deal_status === "Closed"){
      errMsg = "mandatory"
    }else{
      errMsg = "optional"
    }
    this.setState({
      modalIsOpen: true,
      dealStatus: deal_status,
      dealData: dealData,
      isSelect: true,
      clientData: clientData,
      buttonType: 'edit',
      client_id: clientData.id,
      amount: dealData.amount,
      accepted_offer:  (_.isEmpty(dealData.accepted_offer_date) || (dealData.accepted_offer_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.accepted_offer_date),
      signed_contract_date:  (_.isEmpty(dealData.date_of_signed_contract) || (dealData.date_of_signed_contract === "0000-00-00 00:00:00")) ? '' : moment(dealData.date_of_signed_contract),
      expected_close_date: (_.isEmpty(dealData.expected_close_date) || (dealData.expected_close_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.expected_close_date),
      deal_id: dealData.id,
      dealStatus: deal_status,
      address: dealData.address,
      buy_side_commission: dealData.buy_side_commission,
      misc_fee: dealData.sj_comission,
      agent_notes: dealData.agent_notes,
      preStatus: deal_status,
      errorMessage: errMsg,
      lease_start_date: (_.isEmpty(dealData.lease_start_date) || (dealData.lease_start_date === "0000-00-00 00:00:00")) ? '' : moment(dealData.lease_start_date),
      lease_term: dealData.lease_term,
      dealType: dealData.deal_type === 0 ? "sales" : "rental"
    })
  }

  dateAcceptedClick(date, dateString) {
    this.setState({ accepted_offer: moment(date._d).format('YYYY-MM-DD hh:mm') })
  }

  dateSignedClick(date, dateString) {
    this.setState({ signed_contract_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  dateClosedClick(date, dateString) {
    this.setState({ expected_close_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  dateLeaseClick(date, dateString) {
    this.setState({ lease_start_date: moment(date).format('YYYY-MM-DD hh:mm') })
  }

  CloseTabSetStatus(clientId) {
    $('.uniQueselect-' + clientId).prop('selectedIndex', 0)
  }

  submitDealButton() {
    const err = {}
    if (!this.state.isSelect) {
      err.address = 'Please Select address'
    }
    if (this.state.amount === '') {
      err.amount = 'Amount is required'
    }
    if (this.state.dealType === 'sales' || this.state.dealType === 0) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }
  
      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
          }
        }
        if ((this.state.expected_close_date !== '') || !_.isEmpty(this.state.expected_close_date)) {
          if ((this.state.accepted_offer > this.state.expected_close_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
          }
          if (this.state.signed_contract_date > this.state.expected_close_date) {
            err.signed_contract_date = 'Signed Contract date cannot be after Closing date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed date is required';
        }
        if ((this.state.expected_close_date === '' || _.isEmpty(this.state.expected_close_date))) {
          err.expected_close_date = 'Closed date is required';
        }
  
        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Contract date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.accepted_offer > this.state.expected_close_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Closing date';
        }
        if ((this.state.expected_close_date !== '' || !_.isEmpty(this.state.expected_close_date)) && (this.state.signed_contract_date > this.state.expected_close_date)) {
          err.signed_contract_date = 'Signed contract date cannot be after Closing date';
        }
      }
    } else if (this.state.dealType === 'rentals' || this.state.dealType === 1) {
      if ((this.state.accepted_offer === '') || (_.isEmpty(this.state.accepted_offer))) {
        err.accepted_offer_date = 'Date of accepted offer is required';
      }
  
      if (this.state.dealStatus === "Accepted Offer") {
        if ((this.state.signed_contract_date !== '') || !_.isEmpty(this.state.signed_contract_date)) {
          if (this.state.accepted_offer > this.state.signed_contract_date) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
          }
        }
        if ((this.state.lease_start_date !== '') || !_.isEmpty(this.state.lease_start_date)) {
          if ((this.state.accepted_offer > this.state.lease_start_date)) {
            err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
          }
          if (this.state.signed_contract_date > this.state.lease_start_date) {
            err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
          }
        }
      } else if (this.state.dealStatus === "Signed Contract" || this.state.dealStatus === "Closed") {
        if ((this.state.signed_contract_date === '' || _.isEmpty(this.state.signed_contract_date))) {
          err.signed_contract_date = 'Signed Lease is required';
        }
        if ((this.state.lease_start_date === '' || _.isEmpty(this.state.lease_start_date))) {
          err.lease_start_date = 'Lease Start date is required';
        }
  
        if ((this.state.signed_contract_date !== '' || !_.isEmpty(this.state.signed_contract_date)) && (this.state.accepted_offer > this.state.signed_contract_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Signed Lease date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.accepted_offer > this.state.lease_start_date)) {
          err.accepted_offer_date = 'Accepted Offer date cannot be after Lease Start date';
        }
        if ((this.state.lease_start_date !== '' || !_.isEmpty(this.state.lease_start_date)) && (this.state.signed_contract_date > this.state.lease_start_date)) {
          err.signed_contract_date = 'Signed Lease date cannot be after Lease Start date';
        }
      }
    }
    if(this.state.errorMessage === "mandatory"){
      if (this.state.signed_contract_date === '' || this.state.signed_contract_date == "Invalid date" || this.state.signed_contract_date == "0000-00-00 00:00:00") {
        err.signed_contract_date = 'Please Select Signed Date'
      }
    }

    if(this.state.dealType === "sales"){
      if(this.state.errorMessage === "mandatory"){
        if (this.state.expected_close_date === '' || this.state.expected_close_date == "Invalid date" || this.state.expected_close_date == "0000-00-00 00:00:00" || this.state.expected_close_date == 'null' || this.state.expected_close_date == null) {
          err.expected_close_date = 'Please Select Closed Date'
        }
      }
    }else{
      if(this.state.lease_term === ''){
        err.lease_term = 'Please Fill Lease Term'
      }
      if(this.state.errorMessage === "mandatory"){
        if (_.isEmpty(this.state.lease_start_date) || (this.state.lease_start_date === "0000-00-00 00:00:00")) {
          err.lease_start_date = 'Please Select Date'
        }
      }
    }

    if (this.state.misc_fee === '') {
      err.misc_fee = 'Please Enter Misc Fee'
    }

    if (this.state.buy_side_commission === '') {
      err.buy_side_commission = 'Commission is required'
    }

    if (this.state.agent_notes === '') {
      err.agent_notes = 'Please fill agent note'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      const { createClientDeal } = this.props
      const data = {}
      data.deal_type = this.state.dealType === "sales" ? 0 : 1
      data.client_id = parseInt(this.state.clientData.id)
      data.strategist_id = _.get(this.state.clientData, 'strategists[0].strategist_id')
      data.agent_id = this.context.userData.id
      data.amount = parseInt(this.state.amount.toString().replace(/\D/g, ''))
      data.address = this.state.address
      data.status = this.state.dealStatus
      data.id = (this.state.buttonType) === "add" ? 0 : this.state.deal_id
      data.accepted_offer_date = moment(this.state.accepted_offer).format('YYYY-MM-DD hh:mm')

      if(this.state.dealType === "sales"){
        if(this.state.expected_close_date){
          data.expected_close_date =  moment(this.state.expected_close_date).format('YYYY-MM-DD hh:mm')
        }
      }else{
        data.lease_term = this.state.lease_term
        if (this.state.lease_start_date) {
          data.lease_start_date = moment(this.state.lease_start_date).format('YYYY-MM-DD hh:mm')
        }
      }
      if(this.state.signed_contract_date){
        data.signed_contract_date = moment(this.state.signed_contract_date).format('YYYY-MM-DD hh:mm')
      }
      data.sj_comission = parseInt(this.state.misc_fee.toString().replace(/\D/g, ''))
      data.buy_side_commission = parseFloat(this.state.buy_side_commission)
      data.agent_notes = this.state.agent_notes
      createClientDeal(data)
      this.setState({ modalIsOpen: false })
    }
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  clearFilter(e) {
    e.preventDefault()
    this.setState({ filterCount: 0, client_status: [], client_strategist: [], no_follow_up: false, no_status: false, no_town_tour: false, overdue_follow_ups: false, assignedDate: false, status: false, strategists: false, statusDropdown: false, strategistsDropDown: false }, () => {
      this.getSectionData()
    })
  }

  forPaginationClick(){
    let localData = JSON.parse(localStorage.getItem('agentV2clientSections'))
    if(localData){
      let agentData = {
        "sort": localData.sort,
        "sort_value": localData.sort_value,
        "limit": localData.limit,
        "offset": localData.offset,
        "No Follow Up": localData["No Follow Up"],
        "No Status":  localData["No Status"],
        "No Town Tour": localData["No Town Tour"],
        "Overdue Followups": localData["Overdue Followups"],
        "status": localData.status,
        "strategist": localData.strategist,
        "filterCount": localData.filterCount,
        "allOffset": 1,
        "activeOffset": 1,
        "closedffset": 1,
        "selectedStartDate": localData.convertedStartDate,
        "selectedEndDate": localData.convertedEndDate
      }
      localStorage.setItem('agentV2clientSections', JSON.stringify(agentData))
    }
  }

  filterClick(val, e) {
    this.forPaginationClick()
    if (e) {
      e.preventDefault()
    }
    let filterCount = this.state.filterCount
    if (val === "no_follow_up") {
      filterCount = this.state.no_follow_up ? filterCount - 1 : filterCount + 1
      filterCount = this.state.overdue_follow_ups ? filterCount - 1 : filterCount
      this.setState({ no_follow_up: !this.state.no_follow_up, overdue_follow_ups: false }, () => {
        this.getSectionData()
      })
    }
    if (val === "no_status") {
      filterCount = this.state.no_status ? filterCount - 1 : filterCount + 1
      filterCount = this.state.status ? filterCount - 1 : filterCount
      this.setState({ no_status: !this.state.no_status, status: false, statusDropdown: false, client_status: [] }, () => {
        this.getSectionData()
      })
    }
    if (val === "no_town_tour") {
      filterCount = this.state.no_town_tour ? filterCount - 1 : filterCount + 1
      this.setState({ no_town_tour: !this.state.no_town_tour }, () => {
        this.getSectionData()
      })
    }
    if (val === "overdue_follow_ups") {
      filterCount = this.state.overdue_follow_ups ? filterCount - 1 : filterCount + 1
      filterCount = this.state.no_follow_up ? filterCount - 1 : filterCount
      this.setState({ overdue_follow_ups: !this.state.overdue_follow_ups, no_follow_up: false }, () => {
        this.getSectionData()
      })
    }
    if (val === "assignedDate") {
      filterCount = this.state.assignedDate ? filterCount - 1 : filterCount + 1
      if(this.state.assignedDate) {
        this.setState({
          assignedDate: !this.state.assignedDate,
          selectedStartDate: false,
          selectedEndDate: false
        }, () => {
          this.getSectionData()
        })
      } else {
        this.setState({ assignedDate: !this.state.assignedDate }, () => {
          this.getSectionData()
        })
      }

    }
    if (val === "status") {
      filterCount = this.state.status ? filterCount - 1 : filterCount + 1
      filterCount = this.state.no_status ? filterCount - 1 : filterCount
      if (this.state.status) {
        this.setState({ status: false, statusDropdown: false, client_status: [] })
      } else {
        this.setState({ status: true, statusDropdown: true, no_status: false })
      }
    }
    if (val === "strategists") {
      filterCount = this.state.strategists ? filterCount - 1 : filterCount + 1
      if (this.state.strategists) {
        this.setState({ strategists: false, strategistsDropDown: false, client_strategist: [] })
      } else {
        this.setState({ strategists: true, strategistsDropDown: true })
      }
    }
    if(val === "from_to") {
      this.setState({
        from:'',
        to: ''
      }, this.getSectionData)
    }

    this.setState({ filterCount })
  }

  filterStrategist(id, type, e) {
    let { client_strategist, client_status } = this.state;
    if (type === 'filterStatus' || type === 'filterStatusHead') {
      if (client_status.indexOf(id) === -1) {
        client_status.push(id)
      } else {
        client_status.splice(client_status.indexOf(id), 1)
      }
      this.setState({ client_status })
      this.forceUpdate()
      this.getSectionData()
    } else if (type === 'filterStrategists' || type === 'filterStrategistsHead') {
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
      this.setState({ client_strategist })
      this.forceUpdate()
      this.getSectionData()
    }
    if (type === 'filterStatusHead' && !_.size(client_status)) {
      this.filterClick("status")
    } else if (type === 'filterStrategistsHead' && !_.size(client_strategist)) {
      this.filterClick("strategists")
    }
    if (type === "filterDateHead") {
      this.filterClick("assignedDate")
    }
  }

  render() {
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h2 className="heading-title">
                  <span className="heading-icon"><ColoredUserIcon className="" /></span>
                  Clients
                </h2>
              </div>

              <div className="col-md-6">
                <div className="filter__result__section">
                  {/* This is for filter section */}
                  {
                    (_user && _user.role === "agent") &&
                    <Popover
                      content={
                        <div className="filter">
                          <div className="filter__header">
                            <button onClick={this.clearFilter.bind(this)} className="btn btn__btn btn-secondry btn__xs">Clear</button>
                            <div className="filter__title">Filters</div>
                            <button onClick={this.hide} className="btn btn__btn btn-dark btn__xs">Close</button>
                          </div>
                          <div className="filter__body">
                            <ul className="filter__list">
                              <li>
                                <label onClick={this.filterClick.bind(this, "status")} className="custom-checkbox custom-checkbox-line">
                                  Status
                                  <input type="checkbox" name="status" checked={this.state.status}
                                    onChange={this.handleChange} className="custom-checkbox--input" />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              {
                                this.state.statusDropdown &&
                                <ul className="filter__list" style={{ marginLeft: "43px" }}>
                                  {config.clientStatusList &&
                                    config.clientStatusList.map((client, clientIdx) => {
                                      const findSelected = _.find(this.state.client_status, o => o === client.name)
                                      return (
                                        <li key={"status__" + clientIdx} >
                                          <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                                            {client.name}
                                            <input type="checkbox" checked={findSelected ? true : false} onClick={this.filterStrategist.bind(this, client.name, 'filterStatus')} className="custom-checkbox--input" />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                                </ul>
                              }
                              <li>
                                <label onClick={this.filterClick.bind(this, "strategists")} className="custom-checkbox custom-checkbox-line">
                                  Strategist
                                  <input type="checkbox" name="strategists" className="custom-checkbox--input" checked={this.state.strategists}
                                    onChange={this.handleChange} />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              {
                                this.state.strategistsDropDown &&
                                <ul className="filter__list" style={{ marginLeft: "43px" }}>
                                  {this.state.strategistsData &&
                                    this.state.strategistsData.map((strategist, sIndex) => {
                                      const findSelected = _.find(this.state.client_strategist, o => o === strategist.strategist_id)
                                      return (
                                        <li key={"strategist__" + sIndex} >
                                          <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                                            {strategist.full_strategist_name}
                                            <input type="checkbox" checked={findSelected ? true : false} onClick={this.filterStrategist.bind(this, strategist.strategist_id, 'filterStrategists')} className="custom-checkbox--input" />
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      );
                                    })}
                                </ul>
                              }
                              <li>
                                <label onClick={this.filterClick.bind(this, "no_follow_up")} className="custom-checkbox custom-checkbox-line">
                                  No Follow Up Date
                                  <input type="checkbox" checked={this.state.no_follow_up} onChange={this.handleChange}
                                    name="no_follow_up" className="custom-checkbox--input" />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label onClick={this.filterClick.bind(this, "no_status")} className="custom-checkbox custom-checkbox-line">
                                  No Status
                                  <input type="checkbox" checked={this.state.no_status} onChange={this.handleChange} name="no_status" className="custom-checkbox--input" />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label onClick={this.filterClick.bind(this, "no_town_tour")} className="custom-checkbox custom-checkbox-line">
                                  No Town Tour
                                  <input type="checkbox" checked={this.state.no_town_tour} onChange={this.handleChange} name="no_town_tour" className="custom-checkbox--input" />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label onClick={this.filterClick.bind(this, "overdue_follow_ups")} className="custom-checkbox custom-checkbox-line">
                                  Overdue Followups
                                  <input type="checkbox" checked={this.state.overdue_follow_ups} onChange={this.handleChange} name="overdue_follow_ups" className="custom-checkbox--input" />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label onClick={this.filterClick.bind(this, "assignedDate")} className="custom-checkbox custom-checkbox-line">
                                  Assigned Date
                                  <input type="checkbox"
                                         name="assignedDate"
                                         checked={this.state.assignedDate}
                                         className="custom-checkbox--input"
                                         onChange={this.handleChange}
                                  />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                              {this.state.assignedDate && <ul className="filter__list__inner">
                                <div className="search__form mt__10 mb__10">
                                  <DatePicker
                                    className="datepicker__follow-up-date form-control search__form__input"
                                    dropdownClassName="datepicker__overlay__follow-up-date"
                                    format="MM/DD/YYYY"
                                    onChange={(date) => this.handleDateChange(date, "start")}
                                    value={this.state.selectedStartDate ? moment(this.state.selectedStartDate) : null}
                                    placeholder="Select Start Date"
                                    disabledDate={(value) => value > new Date()}
                                  />
                                </div>
                              </ul>}
                              {this.state.selectedStartDate && <ul className="filter__list__inner">
                                <div className="search__form mt__10 mb__10">
                                  <DatePicker
                                    className="datepicker__follow-up-date form-control search__form__input"
                                    dropdownClassName="datepicker__overlay__follow-up-date"
                                    format="MM/DD/YYYY"
                                    disabledDate={(value) => value < this.state.selectedStartDate || value > new Date()}
                                    onChange={(date) => this.handleDateChange(date, "end")}
                                    value={this.state.selectedEndDate ? moment(this.state.selectedEndDate) : null}
                                    placeholder="Select End Date"
                                  />
                                </div>
                              </ul>}
                            </ul>
                          </div>
                        </div>
                      }
                      placement="bottom"
                      className="filter"
                      overlayClassName="filter__overlay"
                      // title="Title"
                      trigger="click"
                      visible={this.state.visible}
                      onVisibleChange={this.filterVisible}
                    >
                      <button className="btn btn-dark btn-filter"><FilterIcon className="filter-icon" /> Filters {this.state.filterCount > 0 ? "(" + this.state.filterCount + ")" : ''}</button>
                    </Popover>
                  }
                  {/* End filter section */}

                  <div className="material-textfield">
                    <select
                      className="form-control custom-select material-textfield-input results__select"
                      onChange={this.resultChange.bind(this)}
                       >
                      <option selected={this.state.selectResultEntry === 20} value={20}>20</option>
                      <option selected={this.state.selectResultEntry === 50} value={50}>50</option>
                      <option selected={this.state.selectResultEntry === 100} value={100}>100</option>
                      <option selected={this.state.selectResultEntry === 250} value={250}>250</option>
                      <option selected={this.state.selectResultEntry === 500} value={500}>500</option>
                      <option selected={this.state.selectResultEntry === 1000} value={1000}>1000</option>
                      <option selected={this.state.selectResultEntry === 2000} value={2000}>2000</option>
                      <option selected={this.state.selectResultEntry === 3000} value={3000}>3000</option>
                      <option selected={this.state.selectResultEntry === 4000} value={4000}>4000</option>
                      <option selected={this.state.selectResultEntry === 5000} value={5000}>5000</option>
                      <option selected={this.state.selectResultEntry === 6000} value={6000}>6000</option>
                      <option selected={this.state.selectResultEntry === 7000} value={7000}>7000</option>
                    </select>
                    <label className="material-textfield-label">Results</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mob__view__container mob__view__control">
              <div className="col-md-12">
                <select
                    defaultValue={this.state.activeTab}
                    onClick={this.changePropsForMobile.bind(this)}
                    className="form-control custom__select mt__20 mb__20">
                      <option value={0} selected={this.state.activeTab === 0 ? "selected" : ""}>All Clients</option>
                      <option value={1} selected={this.state.activeTab === 1 ? "selected" : ""}>Active Clients </option>
                      <option value={2} selected={this.state.activeTab === 2 ? "selected" : ""}>Closed Clients</option>
                </select>
                <ul className="filter__list__flitered">
                  {config.clientStatusList && config.clientStatusList.map((client, clientIdx) => {
                    const findSelected = _.find(this.state.client_status, o => o === client.name)
                    if (findSelected && _.size(findSelected)) {
                      return (<li>{client.name}<span onClick={this.filterStrategist.bind(this, client.name, 'filterStatusHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })
                  }
                  {this.state.strategistsData && this.state.strategistsData.map((strategist, sIndex) => {
                    const findSelected = _.find(this.state.client_strategist, o => o === strategist.strategist_id)
                    if (findSelected) {
                      return (<li>{strategist.full_strategist_name}<span onClick={this.filterStrategist.bind(this, strategist.strategist_id, 'filterStrategistsHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })
                  }
                  {this.state.no_status ? (<li>No Status <span onClick={this.filterClick.bind(this, "no_status")}><CloseIcon className="close-icon" /></span></li>) : ''}
                  {this.state.no_town_tour ? (<li>No Town Tour <span onClick={this.filterClick.bind(this, "no_town_tour")}><CloseIcon className="close-icon" /></span></li>) : ''}
                  {this.state.overdue_follow_ups ? (<li>Overdue Followups <span onClick={this.filterClick.bind(this, "overdue_follow_ups")}><CloseIcon className="close-icon" /></span></li>) : ''}
                  {this.state.no_follow_up ? (<li>No Follow up <span onClick={this.filterClick.bind(this, "no_follow_up")}><CloseIcon className="close-icon" /></span></li>) : ''}
                  {(this.state.selectedStartDate && this.state.selectedEndDate) &&
                    (<li>{moment.utc(this.state.selectedStartDate).format('MM/DD/YYYY')}-{moment.utc(this.state.selectedEndDate).format('MM/DD/YYYY')}<span onClick={this.filterStrategist.bind(this, "date", 'filterDateHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                </ul>
              </div>
            </div>

            <div className="desktop__view">
              <div className="row">
                <div className="col-md-12">
                  <div className="tabs__control">
                    <button onClick={() => this.changeProps(0)} className={this.state.activeTab === 0 ? "btn btn-link active" : "btn btn-link"}>All Clients <span>{this.state.sectionCounts.totalClients > 1 ? `${this.state.sectionCounts.totalClients} Clients` : `${this.state.sectionCounts.totalClients} Client`}</span></button>
                    <button onClick={() => this.changeProps(1)} className={this.state.activeTab === 1 ? "btn btn-link active" : "btn btn-link"}>Active Clients <span>{this.state.sectionCounts.totalActiveClients > 1 ? `${this.state.sectionCounts.totalActiveClients} Clients` : `${this.state.sectionCounts.totalActiveClients} Client`}</span> </button>
                    <button onClick={() => this.changeProps(2)} className={this.state.activeTab === 2 ? "btn btn-link active" : "btn btn-link"}>Closed Clients <span>{this.state.sectionCounts.totalClosedClients > 1 ? `${this.state.sectionCounts.totalClosedClients} Clients` : `${this.state.sectionCounts.totalClosedClients} Client`}</span> </button>
                  </div>
                  {this.state.activeTab !== 2 && 
                    <div className="alert__info__indicated2 mb__20" style={{ color: '#cf0808' }}><NotReadys className="indicated-icon2"  />These clients have not been touched in 4 weeks.</div>
                  }
                </div>
              </div>
              <ul className="filter__list__flitered">
                {config.clientStatusList && config.clientStatusList.map((client, clientIdx) => {
                  const findSelected = _.find(this.state.client_status, o => o === client.name)
                  if (findSelected && _.size(findSelected)) {
                    return (<li>{client.name}<span onClick={this.filterStrategist.bind(this, client.name, 'filterStatusHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })
                }
                {this.state.strategistsData && this.state.strategistsData.map((strategist, sIndex) => {
                  const findSelected = _.find(this.state.client_strategist, o => o === strategist.strategist_id)
                  if (findSelected) {
                    return (<li>{strategist.full_strategist_name}<span onClick={this.filterStrategist.bind(this, strategist.strategist_id, 'filterStrategistsHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })
                }
                {this.state.no_status ? (<li>No Status <span onClick={this.filterClick.bind(this, "no_status")}><CloseIcon className="close-icon" /></span></li>) : ''}
                {this.state.no_town_tour ? (<li>No Town Tour <span onClick={this.filterClick.bind(this, "no_town_tour")}><CloseIcon className="close-icon" /></span></li>) : ''}
                {this.state.overdue_follow_ups ? (<li>Overdue Followups <span onClick={this.filterClick.bind(this, "overdue_follow_ups")}><CloseIcon className="close-icon" /></span></li>) : ''}
                {this.state.no_follow_up ? (<li>No Follow Up Date <span onClick={this.filterClick.bind(this, "no_follow_up")}><CloseIcon className="close-icon" /></span></li>) : ''}
                {(this.state.selectedStartDate && this.state.selectedEndDate) &&
                  (<li>{moment.utc(this.state.selectedStartDate).format('MM/DD/YYYY')}-{moment.utc(this.state.selectedEndDate).format('MM/DD/YYYY')}<span onClick={this.filterStrategist.bind(this, "date", 'filterDateHead')}><CloseIcon className="close-icon" /></span></li>)
                }
              </ul>
            </div>

            {/*This is switch component for tab- all clients, active and close tabs*/}
            <Switch>
              <Route exact path={`/agent/clients/all-clients`}>
                <AllClients
                  {...this.props}
                  {...this.state}
                  client_status={this.state.client_status}
                  selectResultEntry={this.state.selectResultEntry}
                  openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`/agent/clients/active-clients`}>
                <ActiveClients
                  {...this.props}
                  {...this.state}
                  selectResultEntry={this.state.selectResultEntry} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
              <Route exact path={`/agent/clients/closed-clients`}>
                <ClosedClients
                  {...this.props}
                  {...this.state}
                  selectResultEntry={this.state.selectResultEntry} openDealModal={this.openDealModal} closeModal={this.closeModal} deleteDealData={this.deleteDealData} editDealSection={this.editDealSection} />
              </Route>
            </Switch>
            {/*This is switch component end*/}

            {/* This is for accept offer, signed contract and close deal modal */}
            <ReactModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Accepted Offer / Signed Contract / Closed"
              portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">{this.state.dealStatus}</h5>
                  <button type="button" className="btn react-modal-close" onClick={this.closeModal}><CloseIcon /></button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <select
                      name="dealType"
                      defaultValue={this.state.dealType}
                      onChange={this.handleChange.bind(this)}
                      className="form-control custom-select material-textfield-input textfield-input-lg"
                      >
                      <option value="sales">Sales</option>
                      <option value="rental">Rental</option>
                    </select>
                    <label className="material-textfield-label label-lg">Deal Type<span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group material-textfield">
                    {/* Autoplace search */}
                    <PlacesAutocomplete
                      value={this.state.address}
                      className="form-control material-textfield-input textfield-input-lg"
                      onChange={this.handleAddressChange.bind(this)}
                      onSelect={this.handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Fragment>
                          <input
                            {...getInputProps({
                              required: true,
                              className: 'form-control material-textfield-input textfield-input-lg text-input addressAutoSuggest'
                            })}
                            required
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {

                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Fragment>
                      )}
                    </PlacesAutocomplete>
                    <label
                      className="material-textfield-label label-lg">
                      Address<span className="text-danger">*</span>
                    </label>
                    {this.state.err.address ?
                      <span className="invalid-feedback">
                        {this.state.err.address}
                      </span> : ''}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">Date of Accepted Offer<span className="text-danger">*</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateAcceptedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.accepted_offer ? moment.utc(this.state.accepted_offer) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                      {this.state.err.accepted_offer_date ?
                        <span className="invalid-feedback">
                          {this.state.err.accepted_offer_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="amount"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.amount}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix='$'
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Sales Price" : "Monthly Rental Price"}<span className="text-danger">*</span></label>
                        {this.state.err.amount ?
                          <span className="invalid-feedback">
                            {this.state.err.amount}
                          </span> : ''}
                      </div>
                    </div>
                  </div>

                  {/* This is for signed contract */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn__follow-up-date follow-up-date-lg">
                        <div className="follow-up-date-label">{this.state.dealType === "sales" ? "Date of Signed Contract" : "Date of Signed Lease"}<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                        <DatePicker
                          className="datepicker__follow-up-date"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          onChange={this.dateSignedClick.bind(this)}
                          inputReadOnly={true}
                          format="MM/DD/YYYY"
                          allowClear={false}
                          defaultValue={this.state.signed_contract_date ? moment.utc(this.state.signed_contract_date) : ''}
                          placeholder="Select Date"
                        />
                        <DownCaretIcon className="caret-icon" />
                      </div>
                        {this.state.err.signed_contract_date ?
                        <span className="invalid-feedback">
                          {this.state.err.signed_contract_date}
                        </span> : ''}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="buy_side_commission"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.buy_side_commission}
                          onChange={this.handleChange.bind(this)}
                          suffix='%'
                          required
                        />
                        <label className="material-textfield-label label-lg">{this.state.dealType === "sales" ? "Buy Side Commission" : "Tenant's Agent Commission"}<span className="text-danger">*</span></label>
                        {this.state.err.buy_side_commission ?
                          <span className="invalid-feedback">
                            {this.state.err.buy_side_commission}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is end for signed contract and commision */}

                  <div className="row">
                    { this.state.dealType === "sales" ?
                      (<div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Expected/Actual Closing date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.dateClosedClick.bind(this)}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            defaultValue={this.state.expected_close_date ? moment.utc(this.state.expected_close_date) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                          {this.state.err.expected_close_date ?
                          <span className="invalid-feedback">
                            {this.state.err.expected_close_date}
                          </span> : ''}
                      </div>) : ''
                    }
                    { this.state.dealType !== "sales" ?
                      (<div className="col-md-6">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Lease Start Date<span className="text-danger">{this.state.errorMessage === "mandatory" ? "*" : ''}</span></div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            onChange={this.dateLeaseClick.bind(this)}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            allowClear={false}
                            defaultValue={this.state.lease_start_date ? moment.utc(this.state.lease_start_date) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                        {this.state.err.lease_start_date ?
                          <span className="invalid-feedback">
                            {this.state.err.lease_start_date}
                          </span> : ''}
                      </div>) : ''
                    }
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="misc_fee"
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.misc_fee}
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix="$"
                          required
                        />
                        <label className="material-textfield-label label-lg">Misc. Fee<span className="text-danger">*</span></label>
                        {this.state.err.misc_fee ?
                          <span className="invalid-feedback">
                            {this.state.err.misc_fee}
                          </span> : ''}
                      </div>
                    </div>
                  </div>
                  {/* This is expected closing dateand misc fee */}
                  {
                    this.state.dealType !== "sales" ?
                    (<div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            name="lease_term"
                            className="form-control material-textfield-input textfield-input-lg"
                            value={this.state.lease_term}
                            onChange={this.handleChange.bind(this)}
                            required
                          />
                          <label className="material-textfield-label label-lg">Lease Term<span className="text-danger">*</span></label>
                          {this.state.err.lease_term ?
                            <span className="invalid-feedback">
                              {this.state.err.lease_term}
                            </span> : ''}
                        </div>
                      </div>
                    </div>) : ''
                  }
                  <div className="form-group material-textfield">
                    <textarea
                      type="text"
                      className="form-control material-textfield-input"
                      rows="8"
                      name="agent_notes"
                      value={this.state.agent_notes}
                      placeholder={this.state.dealType === "sales" ? "" : `Please indicate how lease commission will be calculated.`}
                      onChange={this.handleChange.bind(this)}
                    >
                    </textarea>
                    <label className="material-textfield-label">Agent Notes<span className="text-danger">*</span></label>
                    {this.state.err.agent_notes ?
                      <span className="invalid-feedback">
                        {this.state.err.agent_notes}
                      </span> : ''}
                  </div>
                  <button
                    className="btn btn__btn btn-dark btn__lg w__100"
                    onClick={()=>this.submitDealButton()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </ReactModal>

            {/* This is for delete deal data modal */}
            <ReactModal
              isOpen={this.state.deleteDealModal}
              onRequestClose={this.deleteDealCloseModal}
              portalClassName="react-modal"
              contentLabel="Are you sure?"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-body react-modal-body-sm">
                  <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.deleteDealCloseModal}><CloseIcon /></button>
                  <h5 className="react-modal-title mb__13">Are you sure?</h5>
                  <p className="react-modal-subtitle mb__3">This will remove the deal information and you will need to re enter the deal.</p>
                  <div className="text-right">
                    <button onClick={this.deleteDealButton.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        </main>
      </div>
    )
  }
}
