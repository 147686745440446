import React, { PureComponent /*, PropTypes*/ } from 'react';
import { DeleteIcon } from '../../../../../../components/icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Modal, Button } from 'antd';
import ConfirmModal from '../../../Common/ConfirmModal';

import './styles.scss';

const { confirm } = Modal;

export default class InternalInsightsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      research: [],
      isDeleteModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { townId }
      },
      getResearch,
      user
    } = this.props;

    this.props.toggleLoading(true);
    getResearch({
      town_id: townId,
      user_id: user.id,
      is_external: 0
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { townId }
      },
      getResearch,
      user
    } = nextProps;
    let obj = {};

    if (get(nextProps, 'getResearchPhase') === 'success') {
      obj.research = get(nextProps, 'getResearchData.research');
      nextProps.toggleLoading(false);
    }

    if (get(nextProps, 'deleteResearchPhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.getResearch({
        town_id: townId,
        user_id: user.id,
        is_external: 0
      });
    }

    return obj;
  }

  deleteInsightMain = async insight => {
    this.props.toggleLoading(true);
    const {
      user,
      getResearch,
      deleteResearch,
      match: {
        params: { townId }
      }
    } = this.props;
    let data1 = {
      id: insight.id,
      town_id: townId
    };

    this.setState({ isDeleteModalOpen: true, deleteInsightData: data1 });
  };

  deleteReasearchConfirm = () => {
    this.props.toggleLoading(true)
    this.setState({ isDeleteModalOpen: false })
    this.props.deleteResearch(this.state.deleteInsightData);
  };

  static propTypes = {
    // PropTypes go here
  };

  render() {
    const { research, isDeleteModalOpen, isLoading } = this.state;

    return (
      <div>
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deleteReasearchConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">Town Insights</h1>
          </div>
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <div className="town__insights">
                  <ul className="insights__list">
                    {!isEmpty(research) ? (
                      research.map((r, i) => {
                        return (
                          <React.Fragment key={i}>
                            {(r.insight_type === 'link' && (
                              <li>
                                <div>
                                  <h4 className="title">{r.note}</h4>
                                  <a download onClick={() => {
                                    window.open(r.link)
                                  }} className="title-link">{r.link}</a>
                                  <div className="time">
                                    Uploaded on{' '}
                                    {moment(r.created).format('LLL')}
                                  </div>
                                </div>
                                <div className="town__insights__cta">
                                  <button
                                    onClick={() =>
                                      this.props.history.push('internal-insights/edit', {
                                        insight: r
                                      })
                                    }
                                    className="btn btn__btn btn__link"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => this.deleteInsightMain(r)}
                                    className="btn btn__btn btn__link"
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </li>
                            )) ||
                              (r.insight_type === 'file' && (
                                <li>
                                  <div>
                                    <h4 className="title">{r.note}</h4>
                                    <a className="title-link">{r.file}</a>
                                    <div className="time">
                                      Uploaded on{' '}
                                      {moment(r.created).format('LLL')}
                                    </div>
                                  </div>
                                  <div className="town__insights__cta">
                                    <button
                                      onClick={() =>
                                        this.props.history.push(
                                          'internal-insights/edit',
                                          { insight: r }
                                        )
                                      }
                                      className="btn btn__btn btn__link"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => this.deleteInsightMain(r)}
                                      className="btn btn__btn btn__link"
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                </li>
                              ))}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <li>No Results Found</li>
                    )}
                  </ul>
                  {!isEmpty(research) &&
                    research.map((r, i) => {
                      return (
                        <React.Fragment key={i}>
                          {r.insight_type === 'note' && (
                            <ul className="early__dismissal__list">
                              <li>{r.note}</li>
                              {/* Early Dismissal:
                              <li>
                                - Elementary Schools: Every Wednesday at 12pm
                              </li>
                              <li>
                                - Middle and High School: About once per month
                                on Wednesdays, dismissal is at 11:19 am and
                                11:29 am.
                              </li> */}
                              <div className="town__insights__cta">
                                <button
                                  onClick={() =>
                                    this.props.history.push('internal-insights/edit', {
                                      insight: r
                                    })
                                  }
                                  className="btn btn__btn btn__link"
                                >
                                  Edit
                                </button>

                                <button
                                  onClick={() => this.deleteInsightMain(r)}
                                  className="btn btn__btn btn__link"
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </ul>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
