import { fetch } from '../../../utils'

const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
/*
    //////////////////////
  /// chat apis ///
/////////////////////
*/
// fetch client agent details
export const fetchClientAgent = (data) => {
  // return fetch(`${HOSTNAME_V2}/strategy/get_strategy`, {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/agents`, {
  //   method: 'POST',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch client agent details
export const fetchClientAgentChat = (data) => {
  const queryString = new URLSearchParams({
    type: 'strategist'
  })
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/agent-strategist-chat?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch client chat to show
export const fetchClientChat = (data) => {
  const queryString = new URLSearchParams({
    client_id: data.user_id,
    message_type: 'strategist'
  })
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getChatHistory = (data) => {
  const queryString = new URLSearchParams({
    receiver_id: data.receiverId,
    sender_id: data.senderId,
    message_type: "agent"
  })
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
export const sendChatMessage = (data) => {
  data.message_type = 'strategist'
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
export const sendAgentMessage = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/agent-strategist-chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

/*
    ///////////////////////////////////////////
  ///  client text message apis ///
///////////////////////////////////////////
*/
// get all text message to client or by clientid
export const getClientTextMessages = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/sms/${data.clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// sent new text message to client
export const sendClientTextMessages = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/sms/${data.clientId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// delete client text message
export const deleteClientTextMessages = (data) => {
  return fetch(`${HOSTNAME_V2}/sms/delete_text_messages`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
