/* eslint-disable camelcase */
import React, { useEffect, useState, Fragment } from 'react'
import moment from 'moment'
import io from 'socket.io-client'
import $ from 'jquery'
import { map, get } from 'lodash'
import { CloseIcon } from '../../../../../../components/icons'

import './styles.scss'
import Cookies from 'universal-cookie'
import { createStringAvatar } from '../../../../../../utils/helper'
import ChatContent from './partials/ChatContent'
import { SUCCESS } from '../../../../../../constants/phase'

// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
const SOCKET_HOSTNAME = process.env.SUBURBANJUNGLE_API_HOST
const socket = io.connect(SOCKET_HOSTNAME, {
  transports: ['websocket', 'polling']
})
const cookies = new Cookies()

export default function ChatWithClientComponent(props) {
  const [chatMessage, setChatMessage] = useState('')
  const user = cookies.get('user') ? cookies.get('user') : {}

  useEffect(() => {
    const userId = props.match.params.id
    props.fetchClientChat({ user_id: userId })
  }, [])

  useEffect(() => {
    socket.on('client_strategist_chat', (data) => {
      const userId = props.match.params.id
      if (user.id && userId) {
        const clientId = parseInt(userId)
        const strategistId = parseInt(user.id)
        if (
          data &&
          ((strategistId === data.sender_id && clientId === data.receiver_id) ||
            (strategistId === data.receiver_id && clientId === data.sender_id))
        ) {
          props.fetchClientChat({ user_id: userId })
        }
      }
    })
  }, [])
  useEffect(() => {
    if (props.sendChatMessagePhase === SUCCESS) {
      props.clearChatPhase()
      const userId = props.match.params.id
      props.fetchClientChat({ user_id: userId })
    }
    if (props.fetchClientChatPhase === SUCCESS) {
      $('.chats__container').animate({ scrollTop: $('.chats__container').prop("scrollHeight") }, 1000)
      props.clearChatPhase()
    }
  }, [props.sendChatMessagePhase, props.fetchClientChatPhase])
  const onClose = () => props.closeDrawer()

  const handleInputChange = ({ target }) => setChatMessage(target.value)

  const sendTextMessage = () => {
    if (chatMessage && chatMessage.replace(/\s/g, '').length !== 0) {
      const clientId = props.match.params.id
      props.sendChatMessage({
        message: chatMessage,
        receiver_id: clientId,
        sender_id: user.id,
        sender_type: 'client',
        receiver_type: 'strategist',
        client_id: clientId,
        strategist_id: user.id,
      })
      setChatMessage('')
    }
  }
  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Chat With Client</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="chats__start__bottom">
        <div className="chats__container">
          <div className="chats__wrapper">
            <ul className="chats__wrapper__chats__list">
              {map(props.clientChats, (clientChat, index) => (
                <li
                  key={index}
                  className={`has__avatar ${user.id === clientChat.sender_id ? 'active' : ''}`}
                >
                  <div className="chats__card__header">
                    <div className="chats__card__avatar">
                      {clientChat.sender_avatar ? (
                        <img src={clientChat.sender_avatar} alt="" />
                      ) : (
                        <span className="string__avatar">
                          {createStringAvatar(clientChat.sender_full_name)}
                        </span>
                      )}
                    </div>
                    <span className="chats__card__name">{clientChat.sender_full_name}</span>
                    <span className="chats__card__time">
                      {moment(clientChat.created_date)
                        .tz(user && user.time_zone)
                        .format('MMMM D, YYYY [at] hh:mm a z')}
                    </span>
                  </div>
                  <ChatContent index={index} chatHistory={props.clientChats} chat={clientChat} />
                  {user.id === clientChat.sender_id && false && (
                    <div className="chats__card__cta">
                      <button className="btn btn__edit_chat">Edit</button>
                      <button className="btn btn__delete_chat">Delete</button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="chats__container__teatarea">
          <textarea
            type="text"
            name="chat"
            value={chatMessage}
            onChange={handleInputChange}
            spellCheck="true"
            className="chats__sectiion__input"
            placeholder={`Send a chat to ${get(
              props,
              'clientDetail.client_full_name',
              'client'
            )}......`}
          ></textarea>
          <div className="chats__section__cta">
            <button
              type="button"
              className="btn btn__btn btn-dark btn__send"
              disabled={chatMessage !== '' && chatMessage.trim() !== '' ? false : true}
              onClick={sendTextMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
