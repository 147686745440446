import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  followUpDates: [],
  fetchFollowUpDatePhase: INIT,
  updateFollowUpDatePhase: INIT,
})

export const followUpReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch follow up dates by client id */
    case types.FETCH_FOLLOW_UP_DATE: {
      return state.set('error', null).set('fetchFollowUpDatePhase', LOADING)
    }
    case types.FETCH_FOLLOW_UP_DATE_SUCCESS: {
      const followUpDates = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('followUpDates', followUpDates)
        .set('fetchFollowUpDatePhase', SUCCESS)
    }
    case types.FETCH_FOLLOW_UP_DATE_ERROR: {
      return state.set('error', action.payload.error).set('fetchFollowUpDatePhase', ERROR)
    }

    /* update follow up date */
    case types.UPDATE_FOLLOW_UP_DATE: {
      return state.set('error', null).set('updateFollowUpDatePhase', LOADING)
    }
    case types.UPDATE_FOLLOW_UP_DATE_SUCCESS: {
      return state.set('error', null).set('updateFollowUpDatePhase', SUCCESS)
    }
    case types.UPDATE_FOLLOW_UP_DATE_ERROR: {
      return state.set('error', action.payload.error).set('updateFollowUpDatePhase', ERROR)
    }

    /* clear prospectus phase */
    case types.CLEAR_FOLLOW_UP_PHASE: {
      return state.set('fetchFollowUpDatePhase', INIT).set('updateFollowUpDatePhase', INIT)
    }

    default: {
      return state
    }
  }
}
