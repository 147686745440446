import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const SortableItem = SortableElement(
  ({ value, onRemove, checkedClick }) => (
    <div className="col-sm-6 col-md-3" key={`input-${value}`}>
      <div className="uploaded__images__card">
        <div className="uploaded__images__thumb__wrapp">
          <video
            controls={true}
            className="uploaded__images__thumb"
            id={get(value, 'id')}
            src={get(value, 'url')}
            alt={get(value, 'id')}
          />
        </div>
        <div className="uploaded__images__card__info">
          <button
            className="btn btn__btn btn-link ml__auto"
            onClick={() => checkedClick(value)}
          >
            {/* <img src="./img/checked-ic.png" width="20" /> */}
          </button>
          {
            cookies.get('user').role == "business development" || cookies.get('user').role == "local" ?
              cookies.get('user').id == get(value, 'created_by') ?
                <button
                  className="btn btn__btn btn-link"
                  onClick={() => onRemove(value)}
                >
                  <img src="./img/close-fill.svg" width="20" />
                </button> :
                <div></div> :
              <button
                className="btn btn__btn btn-link"
                onClick={() => onRemove(value)}
              >
                <img src="./img/close-fill.svg" width="20" />
              </button>
          }
        </div>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, onRemove, onFeatured, checkedClick, disabled }) => {
    return (
      <ui>
        {!isEmpty(items) && items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            onRemove={onRemove}
            checkedClick={checkedClick}
            onFeatured={onFeatured}
            index={index}
            value={value}
            disabled={disabled}
          />
        ))}
      </ui>
    );
  }
);

class SortableComponent extends Component {
  render() {
    return (
      <SortableList
        items={this.props.data}
        image={this.props.image}
        onRemove={this.props.onRemove}
        onFeatured={this.props.onFeatured}
        checkedClick={this.props.checkedClick}
        onSortEnd={this.props.onSortEnd}
        axis="xy"
        disabled = {this.props.disabled}
      />
    );
  }
}

export default SortableComponent;
