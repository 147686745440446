import { connect } from 'react-redux'

import ClosedClientsPerTownComponent from './component'
import {
  getClosedClientsData,
  clearStrategistPhase,
} from '../../../../../../store/strategistV2/strategist/actions'

const ClosedClientsPerTownContainer = connect(
  // Map state to props
  (state) => ({
    closedClientPhase: state.strategistV2.strategist.closedClientPhase,
    closedClientData: state.strategistV2.strategist.closedClientData,
  }),
  // Map actions to dispatch and props
  {
    getClosedClientsData,
    clearStrategistPhase,
  }
)(ClosedClientsPerTownComponent)

export default ClosedClientsPerTownContainer
