import { fetch } from '../../utils'
import qs from 'qs'

const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getBrokerageReportFilters = () => {
  const url = `${SUBURBANJUNGLE_API_HOST}/brokerage-report/filters`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    }).catch((error) => {
      console.log(error)
    })
}

export const getBrokerageReports = (params) => {

  const qsQueryString = qs.stringify({type: 'towns', ...params, filters: JSON.stringify(params.filters)})

  const url = `${SUBURBANJUNGLE_API_HOST}/brokerage-report?${qsQueryString}`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    }).catch((error) => {
      console.log(error)
    })
}