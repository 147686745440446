import React, { PureComponent /*, PropTypes*/ } from 'react';
import Link from './Link/container';
import File from './File/container';
import Note from './Note/container';
import { notification } from 'antd';
import { Formik, Form, Field } from 'formik';
import {
  occupationList,
  landSizes,
  budget,
  fancyLaidBack,
  homeType,
  progressiveConservative,
  sportyArtsy,
  goodForFinding
} from '../../townKeys';

import './styles.scss';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const insightAr = {
  link: 0,
  note: 2,
  file: 1
};

const willingToCommute = [
  { label: 'Up to 30 minutes', value: 'commute_30_to_less' },
  { label: 'Up to 45 minutes', value: 'commute_30_to_45' },
  { label: 'Up to 60 minutes', value: 'commute_45_to_60' },
  { label: '1 hr +', value: 'commute_60_to_high' }
];

const transportation = [
  { label: 'Train', value: 'trans_mode_train' },
  { label: 'Bus', value: 'trans_mode_bus' },
  { label: 'Car', value: 'trans_mode_car' },
  { label: 'Walk', value: 'trans_mode_walk' },
  { label: 'Bike', value: 'trans_mode_bike' },
  { label: 'Ferry', value: 'trans_mode_ferry' }
];

const activities = [
  { label: 'Tennis', value: 'activities_tennis' },
  { label: 'Skiing', value: 'activities_ski' },
  { label: 'Hockey', value: 'activities_hockey' },
  { label: 'Lacrosse', value: 'activities_lacrosse' },
  { label: 'Dance', value: 'activities_dance' },
  { label: 'Squash', value: 'activities_squash' },
  { label: 'Theatre', value: 'activities_theatre' },
  { label: 'Football', value: 'activities_football' },
  { label: 'Writing', value: 'activities_writing' },
  { label: 'Soccer', value: 'activities_soccer' }
];

const RadioInputField = props => {
  return (
    <Field
      {...props}
      checked={
        get(props.values, props.name, props.noval) === props.valueon
          ? true
          : false
      }
      onChange={e =>
        props.toggleradiofields(
          props.name,
          props.valueon,
          props.values,
          props.setfieldvalue
        )
      }
      className="custom__radiobox__input"
    />
  );
};

const CheckboxInputField = props => {
  return (
    <Field
      {...props}
      checked={get(props, `values.${props.name}`, 0) === 1 ? true : false}
      onChange={e => {
        props.setfieldvalue(props.name, e.target.checked ? 1 : 0);

        if (props.name === 'none') {
          //uncheck all good for finding if its none
          goodForFinding.map((g, i) => {
            props.setfieldvalue(g.value, 0);
          });
        }

        if (props.name === 'nice_mix_of_all') {
          // check all fields if its a nice mix of all
          occupationList.map((g, i) => {
            props.setfieldvalue(g.value, e.target.checked ? 1 : 0);
          });
        }
      }}
    />
  );
};
export default class AddEditInternalInsightComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentActiveTab: 0,
      isEditMode: false,
      insight: {},
      town_name: '',
      publicData: []
    };

    this.submitBtnRef = React.createRef();
  }

  componentDidMount() {
    const {
      town,
      match: {
        params: { townId }
      }
    } = this.props;

    this.props.fetchSchools({ townId: parseInt(townId), type: 'public' });

    if (!isEmpty(get(this, 'props.location.state.insight'))) {
      const insight = get(this, 'props.location.state.insight');
      const insight_type = get(insight, 'insight_type');

      this.setState({
        insight,
        town_name: town.name,
        isEditMode: true,
        currentActiveTab: insightAr[insight_type]
      });
    }
  }

  addInsight = async formData => {
    const { currentActiveTab } = this.state;
    this.props.toggleLoading(true);
    const {
      insight,
      match: {
        params: { townId }
      },
      addResearch,
      addResearchFile
    } = this.props;

    if (currentActiveTab === 0 || currentActiveTab === 2) {
      await addResearch(formData);
    }
    if (currentActiveTab === 1) {
      await addResearchFile(formData);
    }
    const url = this.props.history.location.pathname.split(
      `/${insight ? 'add' : 'edit'}`
    );
    this.props.history.push(url[0]);
  };

  static propTypes = {
    // PropTypes go here
  };

  static getDerivedStateFromProps(nextProps, state) {
    const insight = state.insight;
    const obj = {};
    obj.town_name = get(nextProps, 'town.name', '');

    if (get(nextProps, 'addResearchPhase') === 'success') {
      nextProps.clearTownPhase();
      if (get(nextProps, 'addResearchData.status', false)) {
        notification.success({
          message: insight
            ? 'Insight Added Successfully'
            : 'Insight Updated Successfully'
        });
        const url = nextProps.history.location.pathname.split(
          `/${insight ? 'add' : 'edit'}`
        );

        nextProps.history.push(url[0]);
      } else {
        nextProps.toggleLoading(false)
        notification.error({
          message: get(nextProps, 'addResearchData.message')
        });
      }
    }
    if (get(nextProps, 'researchFilePhase') === 'success') {
      nextProps.clearTownPhase();
      notification.success({
        message: insight
          ? 'Insight Added Successfully'
          : 'Insight Updated Successfully'
      });
      const url = nextProps.history.location.pathname.split(
        `/${insight ? 'add' : 'edit'}`
      );

      nextProps.history.push(url[0]);
    }

    if (get(nextProps, 'getSchoolsPhase', false) === 'success') {
      nextProps.clearTownPhase();
      obj.publicData = get(nextProps, 'schoolData.data');
      nextProps.toggleLoading(false);
    }

    return obj;
  }

  changeTab = tab => {
    if (!this.state.isEditMode) {
      this.setState({ currentActiveTab: tab });
    }
  };

  toggleRadioFields = (key, val, values, setFieldValue) => {
    if (values[key] === val) {
      setFieldValue(key, -1);
    } else {
      setFieldValue(key, val);
    }

    this.forceUpdate();
  };

  render() {
    const {
      currentActiveTab,
      isEditMode,
      insight,
      publicData,
      town_name
    } = this.state;

    const TabToShow =
      (currentActiveTab === 0 && (
        <Link
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      )) ||
      (currentActiveTab === 1 && (
        <File
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      )) ||
      (currentActiveTab === 2 && (
        <Note
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      ));
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">
              {isEditMode ? 'Edit' : 'Add'} Insight
            </h1>
            <h4 className="title__sub__section">
              {isEditMode ? 'Edit' : 'Add'} an insight for{' '}
              {get(this, 'props.town.name')} and select which supporting item
              from below it supports.
            </h4>
          </div>
          <div className="col-md-10">
            <div className="tabs__control mt__0">
              <button
                onClick={() => this.changeTab(0)}
                className={`btn btn-link ${currentActiveTab === 0 && 'active'}`}
              >
                Link
              </button>
              <button
                onClick={() => this.changeTab(1)}
                className={`btn btn-link ${currentActiveTab === 1 && 'active'}`}
              >
                File{' '}
              </button>
              <button
                onClick={() => this.changeTab(2)}
                className={`btn btn-link ${currentActiveTab === 2 && 'active'}`}
              >
                Note{' '}
              </button>
            </div>

            {TabToShow}
          </div>
          </div>
          
        
          <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section"></h1>
                  </div>
                  <div className="col-md-10">
                  <button
                      type="button"
                      onClick={() => this.submitBtnRef.current.click()}
                      className="btn btn__btn btn-dark btn__lg w__100"
                    >
                      Save
                    </button>
                  </div>
                  </div>
       
      </div>
    );
  }
}
