import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import config from '.././../../../config'
import _ from 'lodash'
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import { timeConverter } from '.././../../../config'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import styles from './styles.scss'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import Cookies from 'universal-cookie'
import Modal2 from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import { logoutInvalidRequest } from '../../../../utils/helper'
let availableDateSlots = ''
let forEndDate = ''
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()
class DatePickerCustomInput extends React.Component {

  render () {
    return (
      <button
        className="form-control selectdate__global date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      teamName: "",
      _clientID: "",
      redirect: false,
      stratagyisLoading:true,
      agentOpenSchedule: false,
      startDate:moment(),
      id: "",
      clientId: '',
      selectAgent: "",
      agentFullName: '',
      selectAppointment: '',
      agentTimeZone: '',
      selectStartTime: '',
      selectEndTime: '',
      description: '',
      err: {},
      err1: {},
      openModel: false,
      isActive: false,
      town_id: 0,
      selectType: 'all',
      town_name: '',
      openComment:false,
      comment:'',
      commentsData:[],
      openEditComment:false,
      comment_id: null,
      edit_comment:''
    }
    this.handleChange = this.handleChange.bind(this)
  }
  
  componentDidMount(){
    document.title = "Jungler: Strategy";
  }

  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({_clientID:_clientid})
      const clntdata = {}
      clntdata.client_id = _clientid;
      this.props.getStrategy(clntdata)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.strategyData && nextProps.strategyData.status===true){
      this.setState({stratagyisLoading:false})
    }
    if(nextProps.agentSchedulePhase == 'success' ){
      this.setState({ agentOpenSchedule: false, selectStartTime:'', selectEndTime:'', description:'', startDate:moment() })
      toast(nextProps.agentScheduleMessage, {autoClose: true })
      this.props.clearAgentPhase()
      this.getClientData()        
    }
    if(nextProps.updateResearchPhase !== this.props.updateResearchPhase){
      let data = {
        town_id: this.state.town_id,
        type: this.state.selectType,
        client_id: parseInt(this.state._clientID)
      }
      this.props.townResearchData(data)
    }
  }

  getClientData(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    const clntdata = {}
    clntdata.client_id = _clientid;
    this.props.getUserbyId(clntdata)
  }

  handleChange(event) {
    let itemCount = []
    let avilData = this.props.availabilityData && this.props.availabilityData.data && this.props.availabilityData.data.slot
    if(event.target.name === "selectStartTime"){
       itemCount = _.filter(avilData, (avil) => {
        if(avil > event.target.value){
          return avil;
        }
      })
    }
    
    this.setState({[event.target.name]: event.target.value, itemCount: itemCount},()=>{
    })
  }
  
  componentWillUnmount(){
    document.title = "Suburban Jungle";
    
  }

  handleChangeApt(event) {
    this.setState({[event.target.name]: event.target.value})
  }

  addAppointmentBtn(id, agentId, agentTimeZone, agnetName, event){
    const data = {}
    data.user_id  = agentId
    data.timezone = agentTimeZone
    data.date     = moment(this.state.startDate._d).format("YYYY-MM-DD")
    this.props.getAgentAvailability(data)

    this.setState({
      agentOpenSchedule: true,
      clientId: id,
      selectAgent: agentId,
      agentFullName: agnetName,
      agentTimeZone: agentTimeZone
    })
  }

  closeAgentSchduleBar(){
    this.setState({
      agentOpenSchedule: false,
      startDate: moment(),
      description: ''
    })
  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
      const data = {}
      data.user_id  = id
      data.timezone = timezone
      data.date     = selectDate
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }

  editAgentOnStrategy(data){
    const { user } = this.props;
      if(user && user.role && (user.role==='admin' || user.role==='superadmin' || user.role ==='strategist')){
      let obj = {}
      obj.id = data.agent_id;
      this.props.history.push({ pathname: '/add-jungler-user', state: obj})
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  submitSchedulingData(timezone, agentId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectClient, selectAppointment, textarea,
           startDate } = this.state
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
      err.selectEndTime = 'Select End Time'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date              =  moment(startDate._d).format("YYYY-MM-DD")
     let data              = {}
     data.timezone         = timezone
     data.client_id        = this.state.clientId
     data.user_id         = agentId
     data.appointment_type = selectAppointment
     data.date             = date
     data.start_time       = selectStartTime
     data.end_time         = selectEndTime
     data.description      = this.state.description
     this.props.agentScheduleOnClient(data)
     this.setState({ openSchedule : false })
     }
  }

  selectInsightType(e){
    this.setState({ selectType: e.target.value }, ()=> {
      let data = {
        town_id: this.state.town_id,
        type: this.state.selectType,
        client_id: parseInt(this.state._clientID)
      }
      this.props.townResearchData(data)
    })
  }

  checkboxChange(val, e){
    const { town_id, _clientID } = this.state
    let data = {
      town_id: town_id,
      id: val.id,
      client_id: parseInt(_clientID)
    }
    if(val.isActive === 1){
      this.setState({ isActive: false},()=>{
        data.isActive = this.state.isActive
        data.type = 'active'
        this.props.updateTownResearch(data)
      })
    }else{
      this.setState({ isActive: !this.state.isActive},()=>{
        data.isActive = this.state.isActive
        data.type = 'active'
        this.props.updateTownResearch(data)
      })
    }
  }

  closeResearchBar(e){
    this.setState({ openModel: false })
  }

  researchButton(town_id, town_name, e){
    let data = {
      town_id: town_id,
      type: this.state.selectType,
      client_id: parseInt(this.state._clientID)
    }
    this.props.townResearchData(data)
    this.setState({ openModel: true, town_id: town_id, town_name: town_name })
  }


  closeCommentModel(e){
    this.setState({
      openComment:false,
      comment:'',
      comment_id:null
    })
  }

  leaveCommentButton(town_id, town_name, e){
    let obj = {
      client_id: parseInt(this.state._clientID),
      town_id: parseInt(town_id),
    }
    axios({
      'method': 'POST',
      'url': HOSTNAME+'/client/get_leave_comments',
      'data':obj,
      // 'headers': { 'Authorization': jwtToken },
    })
    .then((res) => {
      if(res.data && res.data.status && res.data.data){
        this.setState({ 
          openComment: true, 
          town_id: town_id, 
          town_name: town_name ,
          commentsData: res.data.data
        })
      }else{
        this.setState({ 
          openComment: true, 
          town_id: town_id, 
          town_name: town_name 
        })
      }
    }).catch(logoutInvalidRequest)
  }

  shareButton(e){
    let data = {
      town_id: this.state.town_id,
      type: 'share',
      town_name: this.state.town_name,
      client_id: parseInt(this.state._clientID)
    }
    this.props.updateTownResearch(data)
    this.setState({ openModel: false })
  }

  sendComment(e){
    let { town_id, comment, _clientID } = this.state
    const err = {}
    const { user } = this.props
    const jwtToken = cookies.get('Authorization')
    if (comment === '' || comment.trim() === '') {
      err.comment = 'Please provide any comment/note.'
    }else{
      err.comment = ''
    }
    let obj = {
      client_id: parseInt(_clientID),
      town_id: parseInt(town_id),
      comment: comment,
      sender_id: parseInt(user.id),
      type: 'text'
    }
    
    if(this.state.comment_id){
      obj.id = this.state.comment_id
    }

    this.setState({ err })
    if (err.comment == '') {
      axios({
        'method': 'POST',
        'url': HOSTNAME+'/client/add_leave_comments',
        'data':obj,
        // 'headers': { 'Authorization': jwtToken },
      })
      .then((res) => {
        this.setState({
          town_id:null,
          comment: '',
          openComment: false
        })
      }).catch(logoutInvalidRequest)
    }
  }

  editComment(comment_id, sender_id, comment, e ){
    this.setState({
      comment: comment,
      comment_id: comment_id
    })
  }

  deleteComment(comment_id, e){

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
            <div className="flexdiv">
                <h4 className="popupheader__heading">Are you sure?</h4>
                <button
                  onClick={onClose}
                  type="button"
                  id="close_user_modal"
                  className="close_img close_img--white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                <img src="../../img/close_btn.svg"/>
                </button>
              </div>

              <p className="allpoppadding__para poppinsfontsedit">You want to delete this comment/note ?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.handleClickDelete(comment_id)
                onClose()
            }}> Yes, Delete it!</button>
          </div>
        )
      }
    })
  }

  onCloseCommentModal(e){
    this.setState({
      openEditComment:false,
      edit_comment: '',
      comment_id: null
    })
  }

  handleClickDelete(comment_id){
    let obj = {
      comment_id: comment_id
    }
    axios({
      'method': 'POST',
      'url': HOSTNAME+'/client/delete_leave_comments',
      'data':obj,
      // 'headers': { 'Authorization': jwtToken },
    })
    .then((res) => {
      this.setState({
        town_id:null,
        comment: '',
        openComment: false
      })
    }).catch(logoutInvalidRequest)
  }

  handleKeyDown=(e)=> {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
  }
  
  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      token,
      phase,
      strategyData,
      singleClientData,
      availabilityData,
      townResData
    } = this.props;
    let locationData = {}
    locationData.clientData = singleClientData.user;
    const clientInfo = singleClientData && singleClientData.user;
    locationData.strategyData = strategyData && strategyData.data && strategyData.data.length > 0 ? strategyData.data : [];
    
    if(availabilityData.data === '' || availabilityData.data && availabilityData.data.length == 0){
      this.state.err1.startTimeErr = 'In this date no time slot available'
    }else{
      this.state.err1.startTimeErr = ''
    }

    if(this.state.openModel){
      document.body.style.overflow = "hidden"
    }else{
      document.body.style.overflow = "auto"
    }

    let appoinment = config.typeOfAppointments.map((val, index) => {
      return (
        <option key={index} value={val.value}>{val.name}</option>
      )
    });

    availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
      let pos = availabilityData.data.slot.length - 1
      if (pos === indexslot) {
        return(
          <option disabled={pos} key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
        )
      } else {
        return(
          <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
        )
      }
    });

    if( this.state.itemCount && this.state.itemCount.length > 0 ){
      forEndDate = this.state.itemCount.map((endTime, endIndex) =>{
          return(
           <option key={endIndex} value={endTime}>{timeConverter(endTime)}</option>
          )
      });
    }
    return (
    <div>
      <ToastContainer autoClose={4000}/>
      <main className="clients__strategy--container clients__strategy--containermob">
        <div className="btn-bg">
          { strategyData && strategyData.data && strategyData.data.length > 0 ?
            <button 
              className="btn btn__green strategy-right new__common--btn"
              onClick={() => { this.props.history.push({ pathname: '/add-strategy', state: locationData})}}
            >
              Edit Strategy
            </button>
            :
            <button 
              className="btn btn__green strategy-right new__common--btn"
              onClick={() => { this.props.history.push({ pathname: '/add-strategy', state: locationData})}}
            >
              Add Strategy
            </button>
          }
        </div>
        {this.state.stratagyisLoading ?
          <div className="table-responsive table-responsive-edit">
              <img src="../../img/loader2.svg" />
          </div>
        :
          <div className="table-responsive table-responsive-edit padtopmob">
          {!this.isEmpty(strategyData) && strategyData.data && strategyData.data.length > 0 ?
            <table className="table table-striped table-edit clients__strategy--list tablewidthtab">
              <thead>
                <tr>
                  <th>Agent/Local Name</th>
                  <th>Suburb</th>
                  {/*<th>Stage</th>*/}
                  <th>Status</th>
                  <th className="no-break">Agent Rating</th>
                </tr>
              </thead>
              <tbody>
              {(strategyData && strategyData.data !== undefined || strategyData && strategyData.data !== '') ? (
                strategyData && strategyData.data && strategyData.data.map((data, index)=>{
                return(
                  <tr key={index}>
                    <td>
                      <div className="clients__strategy--avtar" onClick={this.editAgentOnStrategy.bind(this, data)}><img src={data.agent_avatar ? data.agent_avatar:"img/login-bg-img.png"} /></div>
                      {/*<div className="clients__strategy--name" onClick={() => { this.props.history.push({ pathname: `/stratagist-agent-chat/${data.agent_id}`, state: data})}}>{data.agent_full_name}</div>*/}
                      <div className="padd-45">{data.agent_full_name ? data.agent_full_name+'('+data.role+')' : "N/A"}</div>
                    </td>
                    <td>{data.town_name}</td>
                    {/*<td>{data.appointment_type ? data.appointment_type: "N/A"}</td>*/}
                    <td>{data.activity_status ? data.activity_status: "N/A"}</td>
                   
                   <td>
                    {data.agent_rating && data.agent_rating > 0 && data.agent_rating < 6  ? 
                    <ul className="rating-icons">
                      <li><img src={data.agent_rating >=1 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                      <li><img src={data.agent_rating >=2 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                      <li><img src={data.agent_rating >=3 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                      <li><img src={data.agent_rating >=4 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                      <li><img src={data.agent_rating ===5 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                    </ul>
                    : "No Ratings"
                    }
                  </td>
                    {
                      (data.role === "agent")?
                        <td> 
                          <button className="btn btn__green btn__green--pad"
                          style={{color:'#fff'}} 
                          onClick={this.addAppointmentBtn.bind(this, singleClientData && singleClientData.user && singleClientData.user.client_id, data.agent_id, data.agent_time_zone, data.agent_full_name)}>Schedule Agent Call</button>
                        </td>
                      :<td></td>
                    }
                    {
                      (data.isInsight === true) ?
                      <td> 
                        <button className="btn btn__green btn__green--pad extra__spacing"
                        style={{color:'#fff'}} 
                        onClick={this.researchButton.bind(this, data.town_id, data.town_name)}>Share Insights</button>
                      </td>
                      :<td></td>
                    }
                    <td> 
                      <button className="btn btn__green btn__green--pad extra__spacing"
                      style={{color:'#fff'}} 
                      onClick={this.leaveCommentButton.bind(this, data.town_id, data.town_name)}>Leave Comment</button>
                    </td>
                  </tr>
                  )}))
                  :
                  ''
                }
              </tbody>
              </table>
              : 
              <div className="no-strategy-msg">No strategy has been created for this client yet</div>
            }
            </div>
        }
      </main>


      {/*======This is appointment for agent schedule call===========*/}
        <div className={this.state.agentOpenSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
            <div className="schedule__sidebar--header">
               Appointment with agent
              <div className="close-btn" onClick={this.closeAgentSchduleBar.bind(this)}>
                  <img src="img/close.svg"/>
              </div>
            </div>

          <div className="schedule__sidebar--body schbodymob">
            <div className="schedule__sidebar--form--wrapper">

              <div className="form-group">
                <label className="labelinputs__global">Client Name</label>
                <select 
                  className="form-control selectinputs__global" 
                  name="selectClient" 
                  value = {this.state.clientId}>
                   <option>{clientInfo && clientInfo.client_full_name}</option>
                </select>
              </div>

              <div>
                <div className="form-group">
                  <label className="labelinputs__global">Strategist Name</label>
                  <select 
                    className="form-control selectinputs__global" 
                    name="selectStrategist" 
                    value = {this.state.selectAgent}>
                      <option value={this.state.selectAgent}>{this.state.agentFullName}</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label className="labelinputs__global">Appointment Type</label>
                <select 
                  className="form-control selectinputs__global" 
                  name="selectAppointment" 
                  value = {this.state.selectedUserId}  
                  onChange={this.handleChangeApt.bind(this)}>
                  <option>Select the type of Appointment</option>
                    {appoinment}
                </select>
              </div>
              {this.state.err.selectAppointment ?
              <span className="error_msg">
              {this.state.err.selectAppointment}
              </span> : '' }

              <div className="form-group">
                <DatePicker
                  selected={this.state.startDate}
                  customInput={<DatePickerCustomInput />}
                  onChange={this.handelDateChange.bind(this, this.state.agentTimeZone, this.state.selectAgent)}
                  showTimeSelect
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date()}
                  className="form-control selecttime__global date__picker"
                  value={this.state.startDate}
                  placeholderText="Pick a date">
                </DatePicker>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelinputs__global">Start Time</label>
                    <select 
                      name='selectStartTime' 
                      className="form-control selectinputs__global" 
                      value = {this.state.selectStartTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>Start Time</option>
                      {availableDateSlots}
                    </select>
                  </div>
                {this.state.err.selectStartTime ?
                <span className="error_msg">
                {this.state.err.selectStartTime}
                </span> : '' }
                {this.state.err1.startTimeErr ?
                <span className="error_msg">
                {this.state.err1.startTimeErr}
                </span> : '' }
                </div>
                

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelinputs__global">End Time</label>
                    <select 
                      name="selectEndTime" 
                      className="form-control selectinputs__global" 
                      value = {this.state.selectEndTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>End Time</option>
                      {forEndDate}
                    </select>
                  </div>
                  {this.state.err.selectEndTime ?
                <span className="error_msg">
                {this.state.err.selectEndTime}
                </span> : '' }
                </div>

                <div className="form-group rem__marg--btm">
                <div className="col-md-12">
                  <label className="labelinputs__global">Appointment Description</label>
                  <textarea name="description" className="form-control textareainputs__global"
                    onChange={this.handleChange}
                    value={this.state.description}
                    placeholder="Enter description here..." ></textarea>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div className="schedule__sidebar--footer text-center schmob-btn">
            <button 
              className="btn btn__green btn__green--lg btn-block btn__schedule" 
              onClick={this.submitSchedulingData.bind(this, this.state.agentTimeZone, this.state.selectAgent, )}>
               Schedule
            </button>
          </div>
        </div>      
      {/*=====This is end of agent schedule call==========*/}


        {/* ******************************************** */}
        <div className={this.state.openModel ? "right__chat--section": "chat__box__open"}>
          <div className={this.state.openModel ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
            <div className="schedule__sidebar--header">
               Share Insight for {this.state.town_name}
              <div className="close-btn" onClick={this.closeResearchBar.bind(this)}>
                  <img src="img/close.svg"/>
              </div>
            </div>
            <div className="schedule__sidebar--body schedule__sidebar--bodyfull">
              <div className="schedule__sidebar--form--wrapper">
                <div className="form-group">
                 
                    <select className="select__common" value={this.state.selectType} onChange={this.selectInsightType.bind(this)}>
                      <option value="all">See All</option>
                      <option value="link">Links</option>
                      <option value="file">Upload Files</option>
                      <option value="note">Notes</option>
                    </select>
                </div>
              </div>

              <div className="checkbox__listing">
                <ul>
                  {
                    (townResData && townResData.data && townResData.data.length > 0) ?
                     townResData.data.map((res, idx) =>{
                      if(res.link && res.link !== ''){
                        if (!res.link.match(/^[a-zA-Z]+:\/\//)){
                          res.link = 'https://' + res.link;
                        }
                      }
                      return(
                          <li key={idx}>
                            <div className="listing__left">
                            {
                                (res.file) ?
                                <td className="green__types green__anchor"><a href={res.file} download={res.file_name}>{(res.file_name)}</a></td>
                                : 
                                (res.link) ? 
                                  <h6 className="green__anchor"><a href={(res.link)} target='_blank'>{(res.link)}</a></h6>
                                : <h6></h6>
                              }
                              <h5><div className="for__text__break">{res.note}</div></h5>
                              <p>Uploaded on {moment(res.modified).format("MMMM DD, YYYY")}</p>
                            </div>
                            <div className="listing__right">
                              <div className="form-group">
                              <input 
                                type="checkbox" 
                                id={res.id} 
                                onChange={this.checkboxChange.bind(this, res)}
                                checked={res.isActive ? res.isActive : false} />
                              <label htmlFor={res.id}></label>
                              </div>
                            </div>
                        </li>
                        )
                      })
                    : (<p className="no__data--show">No Insight Data</p>)
                  }
                  </ul>
              </div>
            </div>
            <div className="schedule__sidebar--footer text-center">
              <button 
                className="btn btn__green btn__green--lg btn-block share__btn--new" 
                onClick={this.shareButton.bind(this)}>
                 Share
              </button>
            </div>
          </div>
        </div> 
        {/* ******************************************** */}

        {/* ****************Leave Comment Modal**************************** */}
        <div className={this.state.openComment ? "right__chat--section": "chat__box__open"}>
          <div className={this.state.openComment ? "schedule__sidebar--container comment-wrapper open" : "schedule__sidebar--container"}>
            <div className="">
              <div className="comment-box-wrapper">
                <span className="modalclose" onClick={this.closeCommentModel.bind(this)}><img src="img/close_btn.svg"/></span>
                <h2>Leave a comment</h2>
                <p>Leave a comment or note about {this.state.town_name} for your client </p>
                <div className="clearfix"></div>
                <textarea 
                  name="comment"
                  placeholder="Write a comment or note…"
                  value={this.state.comment}
                  onChange={this.handleChange.bind(this)}
                  onKeyDown={this.handleKeyDown}
                  style={{ "resize":"none", "overflowY": "hidden"}}
                  rows="6"
                  >
                </textarea>
                  {this.state.err.comment ?
                <span className="error_comment_msg">
                {this.state.err.comment}
                </span> : '' }
                <div className="text-right">
                  <button className="send-btn" onClick={this.sendComment.bind(this)}>{this.state.comment_id != null ? "EDIT": "SEND"}</button>
                </div>
              </div>
              <div className="comment-list-wrapper">
                <ul>
                  {
                    (this.state.commentsData && this.state.commentsData.length > 0) ? 
                      this.state.commentsData.map((res, idx) =>{
                        return (
                          <li key={idx}>
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="profile-image profile-image-40"><img src={res.sender_image? res.sender_image : "img/default-user.svg"}/></div>
                              <div className="profile-name">{res.sender_full_name}</div>
                              <div className="profile-date">{moment(res.created).tz(moment.tz.guess()).format('LL')+" at "+moment(res.created).tz(moment.tz.guess()).format('LT')}</div>
                            </div>
                            <div className="txt-wrapper">
                              <p>
                                {res.comment}
                              </p>
                            </div>
                            {
                              parseInt(user.id) === parseInt(res.sender_id) ? (
                                <div className="btn-wrapper">
                                  <a href="javascript:void(0)" className="edit-delete-chat" onClick={this.editComment.bind(this, res.id, user.id, res.comment)}>Edit</a>
                                  <a href="javascript:void(0)" className="edit-delete-chat" onClick={this.deleteComment.bind(this, res.id)}>Delete</a>
                                </div>
                              ): ''
                            }
                          </li>
                        )
                      })
                     : ''
                  }
                </ul>
              </div> 
            </div>

          </div>
        </div> 
        {/* ******************************************** */}
    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
