export const FETCH_RANKING_BY_CLIENT_ID =
  'suburban/strategistV2/clientDetail/FETCH_RANKING_BY_CLIENT_ID'
export const FETCH_RANKING_BY_CLIENT_ID_SUCCESS =
  'suburban/strategistV2/clientDetail/FETCH_RANKING_BY_CLIENT_ID_SUCCESS'
export const FETCH_RANKING_BY_CLIENT_ID_ERROR =
  'suburban/strategistV2/clientDetail/FETCH_RANKING_BY_CLIENT_ID_ERROR'

export const FETCH_CLIENT_QUESTION_BY_USER_ID =
  'suburban/strategistV2/clientDetail/FETCH_CLIENT_QUESTION_BY_USER_ID'
export const FETCH_CLIENT_QUESTION_BY_USER_ID_SUCCESS =
  'suburban/strategistV2/clientDetail/FETCH_CLIENT_QUESTION_BY_USER_ID_SUCCESS'
export const FETCH_CLIENT_QUESTION_BY_USER_ID_ERROR =
  'suburban/strategistV2/clientDetail/FETCH_CLIENT_QUESTION_BY_USER_ID_ERROR'

export const FETCH_CLIENTS_AI_RECOMMENDED_TOWNS =
  'suburban/strategistV2/clientDetail/FETCH_CLIENTS_AI_RECOMMENDED_TOWNS'
export const FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_SUCCESS =
  'suburban/strategistV2/clientDetail/FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_SUCCESS'
export const FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_ERROR =
  'suburban/strategistV2/clientDetail/FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_ERROR'

export const APPROVE_RECOMMENDED_AI_TOWNS =
  'suburban/strategistV2/clientDetail/APPROVE_RECOMMENDED_AI_TOWNS'
export const APPROVE_RECOMMENDED_AI_TOWNS_SUCCESS =
  'suburban/strategistV2/clientDetail/APPROVE_RECOMMENDED_AI_TOWNS_SUCCESS'
export const APPROVE_RECOMMENDED_AI_TOWNS_ERROR =
  'suburban/strategistV2/clientDetail/APPROVE_RECOMMENDED_AI_TOWNS_ERROR'

export const ADD_CLIENT_STRATEGIST_TIME_SHEET =
  'suburban/strategistV2/clientDetail/ADD_CLIENT_STRATEGIST_TIME_SHEET'
export const ADD_CLIENT_STRATEGIST_TIME_SHEET_SUCCESS =
  'suburban/strategistV2/clientDetail/ADD_CLIENT_STRATEGIST_TIME_SHEET_SUCCESS'
export const ADD_CLIENT_STRATEGIST_TIME_SHEET_ERROR =
  'suburban/strategistV2/clientDetail/ADD_CLIENT_STRATEGIST_TIME_SHEET_ERROR'

export const CLEAR_CLIENT_DETAIL_PHASE =
  'suburban/strategistV2/clientDetail/CLEAR_CLIENT_DETAIL_STORE_PHASE'
