import "rxjs"
import React, { Component } from "react"
import _ from "lodash";
import { reduxForm } from "redux-form"
import styles from "./styles.scss"
import Cookies from "universal-cookie"
import { Redirect, Link } from 'react-router-dom'
const required = value => (value ? undefined : "Required")
const cookies = new Cookies()

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "Total Clients Received per Year"]

class TotalClientsReceive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingData: false,
      userRole: '',
      strName: '',
      strategist_id: 0,
      strategist_name: ''
    }
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      this.setState({ userRole: _user.role, strName: (_user.first_name+' '+_user.last_name), isLoadingData: true })
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(np) {
    if(np.clientsPerMonthData && np.clientsPerMonthData.data && np.clientsPerMonthData.data.length > 0){
      this.setState({ isLoadingData: false }) 
    }
    if(np.clientsPerMonthData && np.clientsPerMonthData.data && np.clientsPerMonthData.data.length == 0){
      this.setState({ isLoadingData: false }) 
    }
    if( np.filterParams !== this.props.filterParams ){
      let strList = np.filterParams.data && np.filterParams.data.strategists
      if(this.state.strategist_id === 0){
        let strData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
        this.setState({ strategist_id: strData.id, strategist_name: strData.strategist_name })
        let data = {
          strategist_id: strData.id
        }
        this.props.getTotalClientReceivePerMonth(data)
      }
    }
  }

  componentDidMount(){
    let strList = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    if(strList && strList.length > 0){
      if(this.state.strategist_id === 0){
        let strData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
        this.setState({ strategist_id: strData.id, strategist_name: strData.strategist_name })
        let data = {
          strategist_id: strData.id
        }
        this.props.getTotalClientReceivePerMonth(data)
      }
    }
  }

  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoadingData: true },()=>{
      let data = {
        strategist_id: parseInt(this.state.strategist_id)
      }
      this.props.getTotalClientReceivePerMonth(data)
    })
  }

  render() {
    const { agentAssToTownData, clientsPerMonthData, filterParams } = this.props

    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });
    
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>{this.state.strategist_name} - Total Clients Received</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <div className="clients__received--section">
                <div className="dp-wrapper stragist-span">
                  <label className="labelinputs__global">Strategists</label>
                  <select 
                    className="selectinputs__global" 
                    name="stategist_id"
                    value={this.state.strategist_id}
                    onChange={this.handleChange.bind(this)}>
                    {strategistList}
                  </select>
                </div>
              </div>
            </div>              
          </div>          
        </div>

        

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">
                <div className="jungler__list useslist__extra">
                  <div className="table-responsive">
                    {this.state.isLoadingData === true ? (
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td className="noClientResults text-left">
                              <img src="../../img/loader2.svg" />
                              <h5>Your total clients received per month report is generating, please wait.........</h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      ) : (
                      <table className="table table-striped">
                        {
                          clientsPerMonthData && clientsPerMonthData.data && clientsPerMonthData.data.length > 0 &&
                          <thead>
                            <tr>
                              <th>Month's Name</th>
                              <th className="text-center">2018</th>
                              <th className="text-center">2019</th>
                              <th className="text-center">2020</th>
                              <th className="text-center">2020 versus 2019</th>
                              <th className="text-center">2020 versus 2019 %</th>
                              <th className="text-center">2019 versus 2018</th>
                              <th className="text-center">2019 versus 2018 %</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                            {
                              clientsPerMonthData && clientsPerMonthData.data && clientsPerMonthData.data.length > 0 ? (
                                clientsPerMonthData.data.map((val, idx)=>{
                                  return(
                                    <tr key={idx}>
                                      <td className={idx === 12 ? "only__for-index" : ""}>{months[idx]}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{val.y18}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{val.y19}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{val.y20}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{val.compare20to19}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{idx === 12 ? '' : (val && val.percentage1920 && val.percentage1920.toFixed(0))+'%' }</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{val.compare19to18}</td>
                                      <td className={idx === 12 ? "only__for-index text-center" : "text-center"}>{idx === 12 ? '' : (val && val.percentage1819 && val.percentage1819.toFixed(0))+'%' }</td>
                                    </tr>
                                  )
                                }) 
                              ): <div className="dashboard-footer text-center clients__received-center">Data Not Found</div>
                            }
                        </tbody>
                      </table>
                    )}
                    </div>
                  </div>
                 </main>
                <div>
              </div>
            </div>
          </div>
        </div>

        {
          clientsPerMonthData && clientsPerMonthData.data && clientsPerMonthData.data.length > 0 &&
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <span className="complete__total">*Total ( Questionnaires+Created In Jungler )</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default reduxForm({
  form: "TotalClientsReceive", // a unique identifier for this form
  destroyOnUnmount: true
})(TotalClientsReceive);
