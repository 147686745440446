import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import _ from 'lodash'
import styles from './styles.scss'
import io from 'socket.io-client'
import Modal from 'react-modal';
import {getTimeFormate, formatPhoneNumber, timeConverter} from '../../config'
import { confirmAlert } from 'react-confirm-alert'; // Import core
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const SOCKET_HOSTNAME = process.env.SUBURBANJUNGLE_API_HOST
let socket = io.connect(SOCKET_HOSTNAME, {
  transports: ['websocket', 'polling']
})

let _user = ''
let availableDateSlots = ''
let forEndDate = ''

let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
let isMobile = windowWidth < 767

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class ClientChatCenterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      chat_text:'',
      sender_id: '',
      startDate: moment(),
      receiver_id:'',
      selectAgentId:'',
      openSchedule: false,
      itemCount: [],
      selectAppointment:'',
      selectStartTime: '',
      selectEndTime: '',
      description: '',
      err: {},
      detailAgentFirstName: '',
      detailAgentLastName: '',
      detailAgentEmail: '',
      detailAgentContact: '',
      detailAgentShareInfo: false,
      detailAgentDescription: '',
      detailAgentImage: '',
      allAgentData: {},
      mobile_view: false,
      sortAgentName: '',
      viewMore: false,
      modalIsOpen: false,
      userTime: '',
      bookingConfirmedDate:moment(),
      loader: false,
      townsData: '',
      photoIndex: 0,
      openImageModel: false,
      successDone : false,
      chatHistoryDataState: []
    }
    this.sendChatMessage = this.sendChatMessage.bind(this)
  
  }

   componentWillMount(){
    const {fetchUser} = this.props;
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id, sender_id:_user.id, loader: true})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      // this.props.getClientAgentMessage(data)
      this.props.getClientAgent(data)
    }
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({receiver_id:_clientid})
      //This is for scrolling for list
      setTimeout(function() {
        const container = `#agent__name_${receiver_id}`
        let top = 0
        if ($(container).length) {
          top = $(container).offset().top
        }

        top = top && parseInt(top) > 0 ? top - 84 : 0
          $('.chatmsg__list').animate({
              scrollTop: (top)
          },500)
        }, 2000)
    }
    // socket.on('new_message', (data) => {
    //   // this.componentDidUpdate()
    //   if (this.state.userid) {
    //     const clientId = parseInt(this.state.userid)
    //     if (data && ((clientId === parseInt(data.sender_id)) || (clientId === parseInt(data.receiver_id)))) {
    //       var chat = this.state.chatHistoryDataState
    //       chat.push(data)
    //       this.setState({ chatHistoryDataState: _.cloneDeep(chat) })
    //     }
    //   }
    //
    // })
    //  if(!isMobile){
        if(receiver_id){
          const { location } = this.props
          let selectedAgentData = location.state
          if(selectedAgentData){
            var firstName = selectedAgentData.agent_full_name && selectedAgentData.agent_full_name.split(' ').slice(0, -1).join(' ')
            var lastName = selectedAgentData.agent_full_name && selectedAgentData.agent_full_name.split(' ').slice(-1).join(' ')
            let first = firstName && firstName.charAt(0)
            let last = lastName && lastName.charAt(0)
            let agentFullName = first+''+last
            this.setState({
              detailAgentFirstName: firstName,
              detailAgentLastName: lastName,
              detailAgentEmail: selectedAgentData.agent_email,
              detailAgentContact: selectedAgentData.agent_mobile,
              detailAgentShareInfo: selectedAgentData.share_info,
              detailAgentDescription: selectedAgentData.agent_description,
              detailAgentImage: selectedAgentData.agent_image,
              allAgentData: selectedAgentData,
              sortAgentName: agentFullName
            })
          }
        }else if(localStorage.getItem("allAgentData")){
          let allAgentObj = JSON.parse(localStorage.getItem("allAgentData"))
          if(allAgentObj && allAgentObj !=null && allAgentObj !='' && allAgentObj != undefined && allAgentObj != "undefined"){
            this.setState({ receiver_id: allAgentObj.agent_id })
            this.props.history.push({
              pathname: `/client-chat-with-agent/${allAgentObj.agent_id}`,
              state: allAgentObj
            })
          }
        }
     // }
  }

  componentWillReceiveProps(nextProps){

    if (nextProps.chathisotryPhase === "success" || nextProps.chatHistoryData.status === true) {
      this.setState({ chatHistoryDataState: nextProps.chatHistoryData.data })
      $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
    }

    if(nextProps.addAppointmentPhase === "success"){
      this.setState({ openSchedule : false })
      this.props.clearPhase()
    }
    if(nextProps.getAgentLastMessagePhase && nextProps.getAgentLastMessagePhase.status === true){
    }
    if(nextProps.agentData !== this.props.agentData){
      this.setState({ loader: false })
      let agent_param_id = this.props.match.params.id && this.props.match.params.id
      if(!isMobile){
        if(!agent_param_id && !(localStorage.getItem("allAgentData"))){
          if(nextProps.agentData && nextProps.agentData.length > 0){
            let findAgentOnly = _.filter(nextProps.agentData, (agent)=>{
               return agent.agent_role === "agent"
            })
            let finalListArr = []
            if(findAgentOnly && findAgentOnly.length > 0){
              finalListArr =  _.map(findAgentOnly, function(obj) {
                return _.assign(obj, _.find(nextProps.getAgentLastMessageData && nextProps.getAgentLastMessageData.data, {common_id: obj.agent_id}))
              })

              let finalAgentArr = []
              if(finalListArr && finalListArr.length > 0){
                finalAgentArr = _.orderBy(finalListArr, ["chat_time"] , ['desc'])
              }

              if(finalAgentArr){
                localStorage.setItem('allAgentData', JSON.stringify(finalAgentArr[0]))
                if(finalAgentArr[0] && finalAgentArr[0] !=null && finalAgentArr[0] !='' && finalAgentArr[0] != undefined && finalAgentArr[0] != "undefined"){
                  this.setState({ receiver_id: finalAgentArr[0].agent_id })
                  this.props.history.push({
                    pathname: `/client-chat-with-agent/${finalAgentArr[0].agent_id}`,
                    state: finalAgentArr[0]
                  })
                }
              }
            }
          }
        }
      }
    }
    if(nextProps.shareClientInfoData !== this.props.shareClientInfoData){
      const data = {}
      data.user_id = _user.id
      this.props.getClientAgent(data)
    }
    if(nextProps.removeAgentTownPhase !== this.props.removeAgentTownPhase){
      const data = {}
      data.user_id = _user.id
      this.props.getClientAgent(data)
    }
    if(nextProps.agentAppPhase === "success" && nextProps.agentAppData && nextProps.agentAppData.status_chat === true){
      this.setState({
        selectedTime: '',
        selectedUserId: '',
        date: moment().format("MM/DD/YYYY"),
        startDate:moment(),
        timezone: '',
        appointment_type: '',
        openSchedule: false,
       
   
      })
    }
   
  }

  componentDidMount(){
    let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
    let isMobile = windowWidth < 500
    if(this.props.match.params.id !== undefined){
      this.setState({mobile_view:isMobile}, ()=>{
      })
    }
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    if(this.state.sender_id !='' && receiver_id !='' && receiver_id != undefined && receiver_id != "undefined"){
      const data={}
      data.sender_id = this.state.sender_id;
      data.receiver_id = receiver_id;
      this.props.getChatHistory(data);
      $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
    }
    document.title = "Jungler: Client Chat";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handleChange(e){
    let itemCount = []
    if(e.target.name === "selectStartTime"){
       itemCount = _.filter(availableDateSlots, (avil) => {
        return avil.props.value > e.target.value
      })
      this.setState({[e.target.name]: e.target.value, itemCount: itemCount})
    }
    this.setState({[e.target.name]: e.target.value })
  }

  componentDidUpdate(){
    const receiver_id = this.props.match.params.id && this.props.match.params.id
      if(this.state.sender_id !='' && receiver_id !='' && receiver_id != undefined && receiver_id != "undefined"){
        const data={}
        data.sender_id = this.state.sender_id;
        data.receiver_id = receiver_id;
        this.props.getChatHistory(data);
        $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
      }
   }

   sendChatMessage(){
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    if( this.state.chat_text !== '' && receiver_id != '' && this.state.sender_id !=''){
      socket.emit('new_message', {
        sender_id : this.state.sender_id,
        receiver_id: receiver_id,
        sender_role: _user.role,
        type: "text",
        content: this.state.chat_text
      })
     localStorage.setItem('allAgentData', JSON.stringify(this.state.allAgentData))
     const data = {}
      data.user_id = _user.id;
      // this.props.getClientAgentMessage(data)
     this.setState({chat_text: ''})
    }
  }

  openScheduleBar(id, e) {
    if(id === ''){
      this.setState({ openSchedule : true})
    }else{
      this.setState({ openSchedule : true, selectAgentId: id, successDone :false})
    }
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false, successDone : false})
  }

  handelDateChange(timezone, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     _user = cookies.get('user')
      const data = {}
      data.user_id  = this.state.selectAgentId //this is agent user id
      data.timezone = timezone                 //current user time zone
      data.date     = selectDate               //select date for scheduling
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, successDone : false})
  }

  afterTownAddOpen () {

  }

  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectAgentId, selectAppointment, textarea,
           startDate } = this.state

    if (selectAgentId === '' || selectAgentId === "Select the client" || selectAgentId.trim() === '') {
      err.selectAgentId = 'Please Select Client'
    }
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    // if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
    //   err.selectEndTime = 'Select End Time'
    // }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date =  moment(startDate._d).format("YYYY-MM-DD")
      let data = {}
      data.time = selectStartTime
      data.date = moment(startDate).format("YYYY-MM-DD");
      data.timezone = timezone
      data.appointment_type   = selectAppointment
      data.description = ""
      data.user_id = this.state.selectAgentId
      data.booking_type= "chat_side"
      this.props.createAgentAppointment(data, "new_schedule")
      this.setState({ userTime: selectStartTime, bookingConfirmedDate: startDate, modalIsOpen : true })
     }
  }

  clickOnAgent (agent, e) {
    let windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
    // let isMobile = windowWidth < 500
    let isMobile = windowWidth < 768
     //This is for client first and last name
    let first = agent.agent_first_name && agent.agent_first_name.charAt(0)
    let last = agent.agent_last_name && agent.agent_last_name.charAt(0)
    let agentFullName = first+''+last

    this.setState({
      mobile_view: isMobile,
      receiver_id: agent.agent_id,
      detailAgentFirstName: agent.agent_first_name,
      detailAgentLastName: agent.agent_last_name,
      detailAgentEmail: agent.agent_email,
      detailAgentContact: agent.agent_mobile,
      detailAgentShareInfo: agent.share_info,
      detailAgentDescription: agent.agent_description,
      detailAgentImage: agent.agent_image,
      selectAgentId: agent.agent_id,
      allAgentData: agent,
      sortAgentName: agentFullName,
      viewMore: false
    })
  }

  shareInfoToggle(e){
    this.setState({ detailAgentShareInfo: !this.state.detailAgentShareInfo },()=>{
      let data = {
        client_id: parseInt(_user.id),
        agent_id: parseInt(this.props.match.params.id && this.props.match.params.id),
        share_info: this.state.detailAgentShareInfo
      }
      this.props.shareClientInfo(data)
    })
  }

  removeAgentClick(val, e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
              >
              <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">Once you click “yes”, the agent will be removed and a new one will be assigned.</p>
            <div className="text-right">
              {/* <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={onClose}>No</button> */}
              <button className="btn btn__green btn__flat btn__flat__lg w__180" onClick={() => {
              this.removeClientAgent(val)
              onClose()
              }}>Yes</button>
            </div>
          </div>
        )
      }
    })
  }

  viewMoreButton(e){
    this.setState({ viewMore: !this.state.viewMore })
  }

  removeClientAgent(val){
    $(".btn-warning").click()
    let data = {
      client_id: parseInt(_user.id),
      agent_id: parseInt(this.props.match.params.id && this.props.match.params.id),
      type: ""
    }
    this.props.history.push("/client-chat-with-agent")
    localStorage.removeItem("allAgentData")
    this.props.removeAgentFromTown(data)
  }

  upcomingClick(event){
    this.setState({ modalIsOpen: false, userTime: '' })
  }

   //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  imageOpen(){
    this.setState({ openImageModel: !this.state.openImageModel })
  }

  render() {
    const {
      user,
      agentData,
      location,
      availabilityData,
      chatHistoryData,
      getAgentLastMessageData
    } = this.props
    const { photoIndex, openImageModel, chatHistoryDataState } = this.state

    const ClientData = location.state;
    let chatHistory = _.orderBy(chatHistoryDataState, ['created'],['desc'])

    let userAvatar = user && user.avatar;
    let userName   = user && user.first_name +' '+user.last_name;
    let myuserId     = user && user.id;

    //This is for client FIrst and Last first letter
    if(ClientData){
       var agentShortFL = ""
       var htmlDoc = ''
      if(ClientData && ClientData.agent_full_name){
        var firstName = ClientData && ClientData.agent_full_name && ClientData.agent_full_name.split(' ').slice(0, -1).join(' ')
        var lastName = ClientData && ClientData.agent_full_name && ClientData.agent_full_name.split(' ').slice(-1).join(' ')
        let first = firstName && firstName.charAt(0)
        let last = lastName && lastName.charAt(0)
        agentShortFL = first+''+last
      }else{
        let first = ClientData && ClientData.agent_first_name && ClientData.agent_first_name.charAt(0)
        let last = ClientData && ClientData.agent_last_name && ClientData.agent_last_name.charAt(0)
        agentShortFL = first+''+last
      }
      //This is for html convert into normal peragraph
      var tmp = document.createElement("DIV")
      tmp.innerHTML = unescape(ClientData && ClientData.agent_description)
      htmlDoc = tmp.textContent || tmp.innerText || ""
    }

    //Get first name and last name first letter
    let firstD = user && user.first_name && (user.first_name).charAt(0)
    let secondD = user && user.last_name && (user.last_name).charAt(0)
    let sortFullDigit = firstD+''+secondD

    // Iterate over first array of objects
    let finalListArr = []
    if(agentData && agentData.length > 0){
      finalListArr =  _.map(agentData, function(obj) {
          return _.assign(obj, _.find(getAgentLastMessageData && getAgentLastMessageData.data, {common_id: obj.agent_id}));
      });
    }

    let finalAgentArr = []
    if(finalListArr && finalListArr.length > 0){
      finalAgentArr = _.orderBy(finalListArr, ["chat_time"] , ['desc'])
    }

    let agentList = ''
    if(agentData && agentData.length>0) {
      agentList = agentData.map((val, index) => {
        if(val.agent_id && val.agent_id  !=null && val.agent_role=='agent'){
          return (
            <option key={index} value={val.agent_id}>{val.agent_first_name +' '+ val.agent_last_name}</option>
          )
        }
      });
    }

    let appoinment = config.typeOfAppointments.map((val, index) => {
      return (
        <option key={index} value={val.value}>{val.name}</option>
      )
    });

    availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => {
      let pos = availabilityData.data.slot.length - 1
      if (pos === indexslot) {
        return(
          <option disabled={pos} key={indexslot} value={slotval}>{slotval}</option>
        )
      } else {
        return(
          <option key={indexslot} value={slotval}>{slotval}</option>
        )
      }
    });

    forEndDate = this.state.itemCount && this.state.itemCount.map((endTime, endIndex) =>{
      return(
       <option key={endIndex} value={endTime.props.value}>{endTime.props.value}</option>
      )
    });

    $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 500);

    return (
      <div className="clients__changes__parent">

        {
        this.state.loader === true ?
        <div className="loader__agent" >
         <img src="../../img/loader2.svg" />
        </div>
        :''}

        <div className="chatmsg__main--wrapper">
          {
            this.state.mobile_view ?
              <div className="chatmsg__header">
                <div className="chatmsg__header--title">Chat with {ClientData.agent_full_name ? ClientData.agent_full_name : ClientData.agent_first_name+' '+ClientData.agent_last_name}</div>
                <div className="chatmsg__header--close">
                  <img onClick={()=> this.setState({ mobile_view: false })} src="img/close.svg"/>
                </div>
              </div>
            :
             <div className="chatmsg__header">
              <div className="chatmsg__header--title">Client/Agent Message Center</div>
              <div className="chatmsg__header--close">
                <Link to={'/'}><img src="img/close.svg"/></Link>
              </div>
            </div>
          }

          <div className={ this.state.mobile_view ? "chatmst__list--none" : "chatmsg__list"}>
            <ul>
            {
              finalAgentArr && finalAgentArr.length > 0 ? finalAgentArr.map((agent, indx) =>{
                if(agent.agent_id && agent.agent_id !=null && agent.agent_role == 'agent'){
                  let fDigit = agent &&agent.agent_first_name && (agent.agent_first_name).charAt(0)
                  let sDigit = agent && agent.agent_last_name && (agent.agent_last_name).charAt(0)
                  let finalDigit = fDigit+''+sDigit
                  return(
                      <div key={"finalAgentArr__"+indx} onClick={this.clickOnAgent.bind(this, agent)} >
                        <li id={"agent__name_"+agent.agent_id} className={this.props.location.pathname === `/client-chat-with-agent/${agent.agent_id}` ? 'active__list': ''}>
                         <Link key={indx} to={{ pathname: `/client-chat-with-agent/${agent.agent_id}`, state: agent}}>
                          <div className="profile__img">
                          {
                            (agent.agent_image)?
                            <img src={agent.agent_image ? agent.agent_image : 'img/login-bg-img.png'}/>
                            :
                            <p className="profile__letter--client">{finalDigit}</p>
                          }
                          </div>
                          <div className="profile__info">
                            <h2>{agent.agent_first_name+' '+agent.agent_last_name}</h2>
                            {
                              (agent.type === 'image')?
                                <h4 className="agentchatfont"><img className="img--padding" src="../../img/camera_image.png" width="15px" heigh="15px"/>Photo</h4>
                              :
                              (agent.type === 'pdf')?
                                <h4 className="agentchatfont"><img className="img--padding" src="../../img/pdf_icon.png" width="15px" heigh="15px"/>PDF</h4>
                              :
                               (agent.type === 'excel')?
                                <h4 className="agentchatfont"><img className="img--padding" src="../../img/excel_icon.png" width="15px" heigh="15px"/>Excel</h4>
                              :
                                <h4>{agent.content}</h4>
                            }
                          </div>
                        </Link>
                      </li>
                    </div>
                  )
                }
              })
              :
              <li>No Agents</li>
            }
            </ul>
          </div>

        {
          ClientData && ClientData ?
          <div>
            <div className={ this.state.mobile_view ? "chat__centre--block chat__centre" : "chat__centre"}>
              <div className="chat__centre--up">
                <h5>Here are the towns this agent will help you with: </h5>
                {
                  ClientData && ClientData.towns && ClientData.towns.length > 0 ?
                  ClientData.towns.map((town, indext) => {
                  let town_link = 'javascript:void(0);'
                  let authorization = cookies.get('Authorization')
                  if(town && town.town_url){
                  town_link = town.town_url ? town.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
                  }
                    return(<a key={indext} href={town_link} target="_blank" className="town__design">{indext-1 ? `${town.town_name},` : town.town_name}</a>)
                  })
                  :
                  this.state.townsData && this.state.townsData.length > 0 ?
                  this.state.townsData.map((town, indext) => {
                  let town_link = 'javascript:void(0);'
                  let authorization = cookies.get('Authorization')
                  if(town && town.town_url){
                  town_link = town.town_url ? town.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
                  }
                    return(<a key={indext} href={town_link} target="_blank" className="town__design">{indext-1 ? `${town.town_name},` : town.town_name}</a>)
                  })
                  :
                  "No Towns"
                }
              </div>

              <div className="chat__centre--chatbox">

              <ul className="forsafari">
              {
                chatHistory && chatHistory.length > 0 ? chatHistory.map((chat, ind) => {
                  let fName = chat && chat.sender_full_name && chat.sender_full_name.split(' ').slice(0, -1).join(' ')
                  let lName = chat && chat.sender_full_name && chat.sender_full_name.split(' ').slice(-1).join(' ')
                  let fLetter = fName && fName.charAt(0)
                  let lLetter = lName && lName.charAt(0)
                  let agentShort = fLetter+''+lLetter
                  return (
                    (chat.receiver_id == myuserId) ?
                      <li key={ind}>
                      {
                        (chat.sender_avatar !== "") ?
                          <img src={chat.sender_avatar ? chat.sender_avatar: "../../img/default-user.svg"} alt="" />
                        :
                        <p className="profile__letter--onlyleft">{agentShort}</p>
                      }

                        <div className="details">
                          <h6>{chat.sender_full_name} <span className="time">{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</span></h6>
                          {chat.chat_type==='image' ?
                            <span>
                              <img className="clickable__image" onClick={() => this.setState({ photoIndex: ind, openImageModel: true })} src={chat.content}/>
                              {openImageModel && (
                                <Lightbox
                                  mainSrc={chatHistory.map(c => c.content)[photoIndex]}
                                  onCloseRequest={this.imageOpen.bind(this)}
                                  onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                    })
                                  }
                                  onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + 1) % [chat.content].length,
                                    })
                                  }
                                />
                              )}
                            </span>
                            :
                             (chat.chat_type === 'text') ?
                              <p>{chat.content}</p>
                            :
                              (chat.chat_type === 'automated_welcome_message') ?
                              <p>{chat.content}</p>
                            :
                             (chat.chat_type === 'pdf') ?
                             <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(chat.file_name)}</div></a>
                            :
                             (chat.chat_type === 'excel') &&
                              <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(chat.file_name)}</div></a>
                          }
                          {/* <h5>{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5> */}
                        </div>
                      </li>
                    :
                    (chat.sender_id == myuserId) ?
                      <li key={ind} className="you">
                        {
                          (user && user.avatar)?
                            <img src={user.avatar} alt="" />
                          :
                           <p className="profile__letter--only">{sortFullDigit}</p>
                        }

                        <div className="details">
                          <h6>You <span className="time">{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</span></h6>
                          {chat.chat_type==='image' ?
                             <span>
                              <img className="clickable__image" onClick={this.imageOpen.bind(this)} src={chat.content}/>
                              {openImageModel && (
                                <Lightbox
                                  mainSrc={[chat.content][photoIndex]}
                                  onCloseRequest={this.imageOpen.bind(this)}
                                  onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                    })
                                  }
                                  onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + 1) % [chat.content].length,
                                    })
                                  }
                                />
                              )}
                            </span>
                            :
                             (chat.chat_type === 'text')?
                              <p>{chat.content}</p>
                            :
                              (chat.chat_type === 'automated_welcome_message')?
                              <p>{chat.content}</p>
                            :
                             (chat.chat_type === 'pdf') ?
                             <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(chat.file_name)}</div></a>
                            :
                             (chat.chat_type === 'excel') &&
                             <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(chat.file_name)}</div></a>
                          }
                          {/* <h5>{moment(chat.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5> */}
                        </div>
                      </li>
                    : ""
                  )
                }): ""
              }
               </ul>
              <div className="send__wrapper">
                <textarea
                  placeholder={`Enter your message here to ${ClientData.agent_full_name ? ClientData.agent_full_name : ClientData.agent_first_name+' '+ClientData.agent_last_name}`}
                  name="chat_text"
                  value={this.state.chat_text}
                  onChange={this.handleChange.bind(this)}
                />
                <button
                  type="submit"
                  onClick={()=> this.sendChatMessage()}>
                  Send
                </button>
              </div>
            </div>
          </div>

          {
           (ClientData) &&
           <div className="chat__schedules--wrapper">
              <h3>Agent Details</h3>
              <div className="chat__schedules--profile">
                {
                  (ClientData.agent_image)?
                    <img src={ClientData.agent_image ? ClientData.agent_image : "img/login-bg-img.png"}/>
                  :
                  <span className="profile__letter--big">{agentShortFL}</span>
                }

                {
                  (ClientData.agent_full_name)?
                  <h2>{ClientData.agent_full_name}</h2>
                  :
                  <h2>{ClientData.agent_first_name+' '+ClientData.agent_last_name}</h2>
                }

                <h6>
                  <a href={'mailto:' + ClientData.agent_email}>
                    <img src={'img/email-chat-profile.svg'}/>{ClientData.agent_email}
                  </a>
                </h6>
                <h6>
                  <a href={'tel:' + ClientData.agent_mobile}>
                  <img src={'img/contact-chat-profile.svg'}/>
                  {
                    ClientData.agent_mobile ?
                     (
                      (ClientData.agent_mobile.replace(/\D/g, '').length == 10)?
                        this.formatNumber(ClientData.agent_mobile)
                        :
                        ClientData.agent_mobile
                     )
                     :
                     ""
                  }
                  </a>
                </h6>

                <div className="show__more--details">
                {
                  (htmlDoc !== "" && htmlDoc !== null && htmlDoc !== "null") &&
                  <div>
                    <p className={this.state.viewMore ? "" : "para__lines"}>{htmlDoc}</p>
                    {
                      (htmlDoc && htmlDoc.split(' ').length > 100 ) &&
                      <span>
                        {this.state.viewMore !== true && <span className="view__more--less" onClick={this.viewMoreButton.bind(this)}>View more</span>}
                        {this.state.viewMore && <span className="view__more--less" onClick={this.viewMoreButton.bind(this)}>View less</span>}
                      </span>
                    }

                  </div>
                }
              </div>
              </div>

              <div className="status__wrapper">
                <h6>{`Share my contact info with agent ${ClientData.agent_full_name ? ClientData.agent_full_name : ClientData.agent_first_name+' '+ClientData.agent_last_name}`}</h6>
                <div className="toggle__wrapper">
                  <label className="switch1">
                    <input type="checkbox" checked={this.state.detailAgentShareInfo} onChange={this.shareInfoToggle.bind(this)}/>
                    <span style={{ marginTop: '10px'}} className="slider1 round switch__padding"></span>
                  </label>
                  {
                    (this.state.detailAgentShareInfo)? <p style={{ marginTop: '13px'}}>Status: <span>Yes</span></p> : <p style={{ marginTop: '13px'}}>Status: <span>No</span></p>
                  }
              </div>
              </div>

              {/*<button*/}
              {/*  className="btn btn-block btn__flat btn__flat__lg btn__green"*/}
              {/*  onClick={this.openScheduleBar.bind(this, this.props.match.params.id ? this.props.match.params.id: '')}>*/}
              {/*  Schedule*/}
              {/*</button>*/}
              {/*<br/>*/}
              <button onClick={this.removeAgentClick.bind(this)} className="thanks__btn">THANKS, BUT DON'T LOVE THIS AGENT...PLEASE SWAP OUT</button>

              <h5>
                Once you click on the <span>"Thanks, but don't love this agent... please swap out"</span> button, the agent will be
                removed and a new one will be assigned
              </h5>
            </div>
          }

          </div>
          :
          <div className="emptychat__wrapper">
           {/* <div className="emptychat">
              <img src="img/chat_icon.svg" className="chatIcon" />
              Select an Individual to send a message or to see the chat history.
            </div>*/}
          </div>
        }
        </div>

      {/*Here star scheduling for agent*/}
         <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
           + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body transparent__scroll">
              <div className="schedule__sidebar--form--wrapper">
                <div className="form-group">
                  <label className="label__text">Agent Name</label>
                  <select
                    className="selectinputs__global"
                    name="selectAgentId"
                    value = {this.state.selectAgentId}
                    onChange={this.handleChange.bind(this)}>
                    <option value=''>Select the Agent</option>
                    {agentList}
                  </select>
                </div>
                { this.state.err.selectAgentId ?
                <span className="error_msg">
                {this.state.err.selectAgentId}
                </span> : '' }

                <div className="form-group">
                  <label className="label__text">Appointment Type</label>
                  <select className="selectinputs__global" name="selectAppointment" value = {this.state.selectAppointment}  onChange={this.handleChange.bind(this)}>
                    <option>Select the type of Appointment</option>
                     {appoinment}
                  </select>
                </div>
                {this.state.err.selectAppointment ?
                <span className="error_msg">
                {this.state.err.selectAppointment}
                </span> : '' }

                <div className="form-group">
                 <DatePicker
                    selected={this.state.startDate}
                    customInput={<DatePickerCustomInput />}
                    onChange={this.handelDateChange.bind(this, _user.time_zone)}
                    showTimeSelect
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date()}
                    className="form-control custom-select date__picker"
                    value={this.state.startDate}
                    placeholderText="Pick a date">
                  </DatePicker>
                </div>

                <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label__text">Time</label>
                    <select
                      name='selectStartTime'
                      className="selectinputs__global"
                      value = {this.state.selectStartTime}
                      onChange={this.handleChange.bind(this)}>
                      <option>Start Time</option>
                      {availableDateSlots}
                    </select>
                  </div>
                {this.state.err.selectStartTime ?
                <span className="error_msg">
                {this.state.err.selectStartTime}
                </span> : '' }
                </div>


                {/*<div className="col-md-6">
                  <div className="form-group">
                    <label className="label__text">End</label>
                    <select
                      name="selectEndTime"
                      className="selectinputs__global"
                      value = {this.state.selectEndTime}
                      onChange={this.handleChange.bind(this)}>
                      <option>End Time</option>
                       {forEndDate}
                    </select>
                  </div>
                  {this.state.err.selectEndTime ?
                <span className="error_msg">
                {this.state.err.selectEndTime}
                </span> : '' }
                </div>*/}
                </div>

                {/* <div className="form-group rem__marg--btm">
                  <label className="label__text">Appointment Description</label>
                  <textarea
                  name="description" className="textareainputs__global"
                   onChange={this.handleChange.bind(this)}
                   placeholder="Enter description here..."></textarea>
                </div> */}



              </div>
            </div>
            <div className="schedule__sidebar--footer">
              <button
                className="btn btn__flat btn__green btn__green--lg btn-block btn__schedule"
                onClick={this.submitSchedulingData.bind(this, _user.time_zone, _user.id)}>
                Schedule
              </button>
            </div>

        </div>
      {/*Here end scheduling for agent*/}

      {<Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal.bind(this)}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="view__booking--modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <img onClick={this.upcomingClick.bind(this)} src="./img/close_btn.svg" className="clo"/>
              <div className="modal-body text-center" style={{ padding: '30px'}}>
                <img src="./img/noun_fireworks.png"/>
                <p>
                  Thank You, <span> {this.state.allAgentData.agent_first_name+" "+this.state.allAgentData.agent_last_name} </span>will be in
                  touch with you at <span>{timeConverter(this.state.userTime)}</span> on <span>{moment(this.state.bookingConfirmedDate).format('LL')}</span>
                </p>
                {/*<button
                  type="submit"
                  onClick={this.upcomingClick.bind(this)}
                  className="btn btn-block btn__green btn__green--lg">
                  VIEW BOOKING
                </button>*/}
              </div>
            </div>
          </div>
        </div>
      </Modal>}

      </div>
    )
  }
}

export default reduxForm({
  form: 'messagecenter',
  destroyOnUnmount: true,
})(ClientChatCenterForm)
