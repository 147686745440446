import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import _ from "lodash";
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import styles from './styles.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
let _user = ''
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      teamName: "",
      _clientID: "",
      redirect: false,
      id: ""
    }
  }

  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      _user = cookies.get('user')
      this.setState({_clientID:_clientid})
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      clientDealdata
    } = this.props
    let cliendObj = {}
    cliendObj.client_id = this.props.match.params.id && this.props.match.params.id;

    // let dealData = _.orderBy(clientDealdata && clientDealdata.data, 'created', 'desc'); // Use Lodash to sort array by 'created date'

    let dealData = _.sortBy(clientDealdata && clientDealdata.data, function(o) {
     return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
    }).reverse();

    return (
    <div>
      <main className="clients__deal--container">
        <div className="row">
          <div className="col-md-12">
            <div className="client__activity--info clients__deal--bg">
             {clientDealdata && clientDealdata.data && clientDealdata.data.length < 1 ?
              <div className="addDeal-Section clients__deal--action">
              <button onClick={() => { this.props.history.push({'pathname' : `/add-deal`, 'state':cliendObj})}} className="btn btn__green new__common--btn">Add Deal</button>
              </div>
              : ''
             }
              {dealData && dealData.length > 0 ? dealData.map((deal, dindex) => {
                if(dindex == 0){
                  cliendObj.deal = deal;
                return (
                  <div className="row dealList" key={dindex}>
                    <div className="col-md-3">
                      <div className="client__activity--info--items">
                        <h1>AGENT</h1>
                        <h2><a>{deal.agent_full_name ? deal.agent_full_name : 'N/A'}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>AMOUNT</h1>
                        <h2><a>{deal.amount ? "$"+deal.amount.toLocaleString() : 'N/A'}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>DEAL STATUS</h1>
                        <h2><a>{ deal.deal_status ? deal.deal_status : "N/A"}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>EXPECTED/ACTUAL CLOSING DATE</h1>
                        <h2><a>{deal.expected_close_date && (deal.expected_close_date != '0000-00-00 00:00:00') ? moment(deal.expected_close_date).format('MM/DD/YY') : 'N/A'}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>ADDRESS</h1>
                        <h2><a href={"http://maps.google.com/?q="+deal.address} target="_blank">{deal.address}</a></h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                    <div className="client__activity--info--items">
                        <h1>CREATED DATE</h1>
                        <h2><a>{moment(deal.created).format('MM/DD/YY')}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>BUY SIDE COMMISSION</h1>
                        <h2>{deal.buy_side_commission ? deal.buy_side_commission.toLocaleString()+"%" : 'N/A'}</h2>
                      </div>
                      {/* ToDO : Removing this feild because we dont have any edit or add input in form for this*/}
                      {/*<div className="client__activity--info--items">
                        <h1>SJ COMMISSION</h1>
                        <h2>{deal.sj_comission ? deal.sj_comission.toLocaleString() : 'N/A'}</h2>
                      </div>*/}
                      <div className="client__activity--info--items">
                        <h1>Misc. Fees</h1>
                        <h2>{deal.sj_comission ? "$" +deal.sj_comission.toLocaleString() : "$0"}</h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>Date of Accepted Offer</h1>
                        <h2><a>{deal.accepted_offer_date ? moment(deal.accepted_offer_date).format('MM/DD/YY') : 'N/A'}</a></h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>Date of Signed Contract</h1>
                        <h2><a>{deal.date_of_signed_contract ? moment(deal.date_of_signed_contract).format('MM/DD/YY') : 'N/A'}</a></h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="client__activity--info--items">
                        <h1>CHECK RECEIVED</h1>
                        <h2>{deal.check_received && (deal.check_received != '0000-00-00 00:00:00') ? moment(deal.check_received).format('MM/DD/YY') : 'N/A'}</h2>
                      </div>
                      <div className="client__activity--info--items">
                        <h1>SJ Notes</h1>
                        <h2>{deal.additional_details ? deal.additional_details : 'N/A'}</h2>
                      </div>
                        <div className="client__activity--info--items">
                        <h1>Agent Notes</h1>
                        <h2>{deal.agent_notes ? deal.agent_notes : 'N/A' }</h2>
                      </div>
                      {/*<div className="client__activity--info--items">
                        <h1>Strategist Fee</h1>
                        <h2>{deal.strategist_fee ? '$'+deal.strategist_fee : 'N/A' }</h2>
                      </div>*/}
                    </div>
                    <div className="col-md-3 mobile__position">

                      {
                        _user && (_user.role == 'admin' || _user.role == 'strategist') &&  deal.deal_status === 'Accepted Offer' ? (
                          <div className="clients__deal--action">
                            <button onClick={() => { this.props.history.push({'pathname' : `/add-deal`, 'state':cliendObj})}} className="btn btn__black--outline">Edit Deal</button>
                          </div>
                        ) : ''
                      }
                      {
                        _user && _user.role == 'superadmin' ? (
                          <div className="clients__deal--action">
                            <button onClick={() => { this.props.history.push({'pathname' : `/add-deal`, 'state':cliendObj})}} className="btn btn__black--outline">Edit Deal</button>
                          </div>
                        ) : ''
                      }
                      {/* <div className="client__activity--info--items">
                         <h1>Google Review</h1>
                         <h2><a target="_blank" href={deal.facebook_review_url}>{deal.facebook_review_url ? deal.facebook_review_url : 'N/A'}</a></h2>
                       </div>
                       <div className="client__activity--info--items">
                         <h1>Facebook Review</h1>
                         <h2><a target="_blank" href={deal.google_review_url}>{deal.google_review_url ? deal.google_review_url : 'N/A'}</a></h2>
                       </div>*/}

                    </div>
                  </div>
                )
                }
              }) 
              : <div>
                  This is where you will see a Deal that has been created when there is an accepted offer. There are No Deals created
                  for this client yet. Click on "Add Deal"
                  to create one!
                </div>
              }
            </div>
          </div>
        </div>
      </main>
    </div>
    )
  }
}

export default reduxForm({
  form: 'client-deal',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
