import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import Cookies from "universal-cookie"
import './styles.scss'
import ReactExport from "react-data-export"
import moment from 'moment'
import _ from 'lodash'
import { map } from 'lodash'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReactModal from 'react-modal'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const cookies = new Cookies()
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}

export default class AgentStatisticsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment('2019-03-27'),
      endDate: moment(),
      selectedDefault: 'today',
      town_list: [],
      suburb_list: [],
      sortByKey: '',
      sortByType : 0,
      agentStatData: [],
      searchAgent: '',
      previousStartDate: moment('2019-03-27'),
      previousEndDate: moment(),
      currentAgentTowns: [],
      open: false,
      townDataArr: [],
      suburbsMultiArr: [],
      townsMultiArr: [],
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    let _user = cookies.get("user");
    if (_user) {
      this.setState({ isLoading: true, userRole: _user.role })
      const agentData = {
        from : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        to : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedValue : 'any',
        town_arr : this.state.town_list,
        suburbs : this.state.suburb_list,
        sortBy : {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getAgentStatistics(agentData)
      this.props.getFilterParams()
    }
  }

  componentDidUpdate(preProps, preState){
    const { agentStatisticsPhase, agentStatisticsData, clearPhase, filterParamsPhase, filterParams } = this.props
    if(agentStatisticsPhase === "success"){
      let data = []
      clearPhase()
      if(_.size(agentStatisticsData) > 0){
        agentStatisticsData && agentStatisticsData.map((agent, i)=>{
          data.push({
            name: agent.name,
            townsCovered: agent.townsCovered,
            totalClientReceived: agent.totalClientReceived,
            totalClosed: agent.totalClosed,
            totalPending: agent.totalPending,
            totalClosedPending: agent.totalClosedPending,
            totalWonTotalReceivedPercentage: agent.totalWonTotalReceivedPercentage,
            totalClosed20: agent.totalClosed20,
            totalClosed19: agent.totalClosed19,
            totalClosed18: agent.totalClosed18,
            totalClosed17: agent.totalClosed17,
            totalActivelyEngaged: agent.totalActivelyEngaged,
            totalActivelyEngagedPercentage: agent.totalActivelyEngagedPercentage,
            totalSomewhatEngaged: agent.totalSomewhatEngaged,
            totalSomewhatEngagedPercentage: agent.totalSomewhatEngagedPercentage,
            totalUnresponsive: agent.totalUnresponsive,
            totalUnresponsivePercentage: agent.totalUnresponsivePercentage,
            totalNoStatusSelected: agent.totalNoStatusSelected,
            totalNoStatusSelectedPercentage: agent.totalNoStatusSelectedPercentage,
            totalNoTour: agent.totalNoTour,
            totalNoTourPercentage: agent.totalNoTourPercentage
          })
        })
      }
      this.setState({ agentStatData: data, isLoading: false })
    }
    if(agentStatisticsPhase === "error"){
      clearPhase()
      this.setState({ agentStatData: [], isLoading: false })
    }
    if(filterParamsPhase === "success"){
      this.props.clearFilterPhase()
      let allTownData = filterParams && filterParams.data && filterParams.data.towns
      this.setState({ townDataArr: allTownData })
    }
  }

  onSearchAgentName(e){
    this.setState({ searchAgent: e.target.value })
  }

  onOpenModal(agent) {
    const towns = agent && agent.townsCovered && agent.townsCovered.indexOf(',') !== -1 ? agent.townsCovered.split(',') : [agent.townsCovered]
    if(towns.length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTowns: towns })
    }
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTowns: [], open: false })
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const agentData = {
      from : moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      selectedValue : 'any',
      town_arr : this.state.town_list,
      suburbs : this.state.suburb_list,
      sortBy : {key: this.state.sortByKey, type: this.state.sortByType}
    }
    this.props.getAgentStatistics(agentData)
  }

  handleSuburbsOption = (options, event) => {
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ suburb_list: selectedOptions, isLoading: true, suburbsMultiArr: options })
      const agentData = {
        from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
        to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedValue : 'any',
        town_arr : this.state.town_list,
        suburbs : selectedOptions,
        sortBy : {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getAgentStatistics(agentData)
    }
  }

  handleTownOption = (options, event) => {
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ town_list: selectedOptions, isLoading: true, townsMultiArr: options })
      const agentData = {
        from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
        to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedValue : 'any',
        town_arr : selectedOptions,
        suburbs : this.state.suburb_list,
        sortBy : {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getAgentStatistics(agentData)
    }
  }

  clearAllFilter(e){
    this.setState({ isLoading: true, townsMultiArr : [], suburbsMultiArr: [], suburb_list: [], town_list: [] })
    const agentData = {
      from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
      selectedValue : 'any',
      town_arr : [],
      suburbs : [],
      sortBy : {key: this.state.sortByKey, type: this.state.sortByType}
    }
    this.props.getAgentStatistics(agentData)
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { filterParams } = this.props
    const { agentStatData } = this.state

    //this is search according agent's name
    let agentData = []
    let agentStatisticsDataBySearch = agentStatData
    if(agentStatisticsDataBySearch){
      agentData = agentStatisticsDataBySearch.filter(
        (data) =>{
          return (
            (data.name.toLowerCase().indexOf(this.state.searchAgent.toLowerCase())) !== -1
          );
        }
      );
    }

    let suburbsData = filterParams.data && filterParams.data.suburbs
    const suburbs = map(suburbsData, (suburb, index) => {
      return { label: suburb.name, value: suburb.id }
    });

    const townsArr = map(this.state.townDataArr, (town, index) => {
      return { label: town.name, value: town.id }
    });

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Agent Statistics</h2>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                    <ExcelSheet data={agentData} name="Agent_Report">
                      <ExcelColumn label="Agent Name" value="name" />
                      <ExcelColumn label="Towns Covered" value="townsCovered" />
                      <ExcelColumn label="Total Clients Received" value="totalClientReceived" />
                      <ExcelColumn label="Total Closed" value="totalClosed" />
                      <ExcelColumn label="Total Pending" value="totalPending" />
                      <ExcelColumn label="Total Closed + Pending" value="totalClosedPending" />
                      <ExcelColumn label="Total Won/Total Received" value="totalWonTotalReceivedPercentage" />
                      <ExcelColumn label="Total Closed-2020" value="totalClosed20" />
                      <ExcelColumn label="Total Closed-2019" value="totalClosed19" />
                      <ExcelColumn label="Total Closed-2018" value="totalClosed18" />
                      <ExcelColumn label="Total Closed-2017" value="totalClosed17" />
                      <ExcelColumn label="Total Actively Engaged" value="totalActivelyEngaged" />
                      <ExcelColumn label="% Actively Engaged" value="totalActivelyEngagedPercentage" />
                      <ExcelColumn label="Total Somewhat  Engaged" value="totalSomewhatEngaged" />
                      <ExcelColumn label="% somewhat Engaged" value="totalSomewhatEngagedPercentage" />
                      <ExcelColumn label="Total Unresponsive" value="totalUnresponsive" />
                      <ExcelColumn label="% Unresponsive" value="totalUnresponsivePercentage" />
                      <ExcelColumn label="total No status selected" value="totalNoStatusSelected" />
                      <ExcelColumn label="% no status selected" value="totalNoStatusSelectedPercentage" />
                      <ExcelColumn label="Total NO tour" value="totalNoTour" />
                      <ExcelColumn label="% No Tour" value="totalNoTourPercentage" />
                    </ExcelSheet>
                  </ExcelFile>
                  {
                    ((_.size(this.state.suburbsMultiArr) > 0) || (_.size(this.state.townsMultiArr) > 0)) &&
                    <button className="btn btn__btn btn__export" onClick={this.clearAllFilter.bind(this)}> Clear All Filter</button>
                  }
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearchAgentName.bind(this)}
                      placeholder="Search by agent by name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="suburb_list"
                      value={this.state.suburbsMultiArr}
                      placeholderButtonLabel="Select"
                      options={suburbs}
                      onChange={(event) => this.handleSuburbsOption(event, 'suburb_list')}
                    />
                    <label className="multiselect__checkboxes__field__label">Suburbs</label>
                  </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="town_list"
                      value={this.state.townsMultiArr}
                      placeholderButtonLabel="Select"
                      options={townsArr}
                      onChange={(event) => this.handleTownOption(event, 'town_list')}
                    />
                    <label className="multiselect__checkboxes__field__label">Towns</label>
                  </div>
                </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(agentData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Agent Name',
                              width: 220,
                              dataIndex: 'name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                              title: 'Towns Covered',
                              width: 200,
                              dataIndex: 'townsCovered',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.townsCovered.localeCompare(b.townsCovered),
                              render: (text, agent) => {
                                const towns = agent && agent.townsCovered && agent.townsCovered.indexOf(',') !== -1 ? agent.townsCovered.split(',') : [agent.townsCovered]
                                      let townNo = (towns.length-1) <= 0 ? '' : " (+"+(towns.length-1)+")"
                                return(
                                  <span className="wrap-text text-left cursor__pointer"><div className="link" onClick={this.onOpenModal.bind(this, agent)}>{(towns.length >= 1 ? towns[0]+townNo : 'No Town')}</div></span>
                                )
                              }
                            },
                            {
                              title: 'Total Clients Received',
                              dataIndex: 'totalClientReceived',
                              key: '3',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClientReceived - b.totalClientReceived
                            },
                            {
                              title: 'Total Closed',
                              dataIndex: 'totalClosed',
                              key: '4',
                              width: 180,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosed - b.totalClosed
                            },
                            {
                              title: 'Total Pending',
                              dataIndex: 'totalPending',
                              key: '5',
                              width: 180,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalPending - b.totalPending
                            },
                            {
                              title: 'Total Closed + Pending',
                              dataIndex: 'totalClosedPending',
                              key: '6',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosedPending - b.totalClosedPending
                            },
                            {
                              title: 'Total Won/Total Received',
                              dataIndex: 'totalWonTotalReceivedPercentage',
                              key: '7',
                              width: 290,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalWonTotalReceivedPercentage - b.totalWonTotalReceivedPercentage,
                              render: (text, agent) => {
                                return(
                                  <span>{`${text}%`}</span>
                                )
                              }
                            },
                            {
                              title: 'Total Closed-2020',
                              dataIndex: 'totalClosed20',
                              key: '8',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosed20 - b.totalClosed20
                            },
                            {
                              title: 'Total Closed-2019',
                              dataIndex: 'totalClosed19',
                              key: '9',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosed19 - b.totalClosed19
                            },
                            {
                              title: 'Total Closed-2018',
                              dataIndex: 'totalClosed18',
                              key: '10',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosed18 - b.totalClosed18
                            },
                            {
                              title: 'Total Closed-2017',
                              dataIndex: 'totalClosed17',
                              key: '11',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalClosed17 - b.totalClosed17
                            },
                            {
                              title: 'Total Actively Engaged',
                              dataIndex: 'totalActivelyEngaged',
                              key: '12',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalActivelyEngaged - b.totalActivelyEngaged
                            },
                            {
                              title: '% Actively Engaged',
                              dataIndex: 'totalActivelyEngagedPercentage',
                              key: '13',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalActivelyEngagedPercentage.localeCompare(b.totalActivelyEngagedPercentage)
                            },
                            {
                              title: 'Total Moderately Engaged',
                              dataIndex: 'totalSomewhatEngaged',
                              key: '14',
                              width: 280,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalSomewhatEngaged - b.totalSomewhatEngaged
                            },
                            {
                              title: '% Moderately Engaged',
                              dataIndex: 'totalSomewhatEngagedPercentage',
                              key: '15',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalSomewhatEngagedPercentage.localeCompare(b.totalSomewhatEngagedPercentage)
                            },
                            {
                              title: 'Total Unresponsive',
                              dataIndex: 'totalUnresponsive',
                              key: '16',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalUnresponsive - b.totalUnresponsive
                            },
                            {
                              title: '% Unresponsive',
                              dataIndex: 'totalUnresponsivePercentage',
                              key: '17',
                              width: 200,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalUnresponsivePercentage.localeCompare(b.totalUnresponsivePercentage)
                            },
                            {
                              title: 'Total No Status Selected',
                              dataIndex: 'totalNoStatusSelected',
                              key: '18',
                              width: 280,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalNoStatusSelected - b.totalNoStatusSelected
                            },
                            {
                              title: '% No Status Selected',
                              dataIndex: 'totalNoStatusSelectedPercentage',
                              key: '19',
                              width: 250,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalNoStatusSelectedPercentage.localeCompare(b.totalNoStatusSelectedPercentage)
                            },
                            {
                              title: 'Total No Tour',
                              dataIndex: 'totalNoTour',
                              key: '20',
                              width: 180,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalNoTour - b.totalNoTour
                            },
                            {
                              title: '% No Tour',
                              dataIndex: 'totalNoTourPercentage',
                              key: '21',
                              width: 180,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.totalNoTourPercentage.localeCompare(b.totalNoTourPercentage)
                            },
                          ]}
                          dataSource={agentData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>

          <ReactModal
            isOpen={this.state.open}
            contentLabel="Report"
            portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                  {'Agent - ' + this.state.agentName+`'s`+' town(s)'}
                  </h5>
                  <button
                    type="button"
                    className="btn react-modal-close"
                    onClick={this.onCloseModal.bind(this)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="info__block">
                  {this.state.currentAgentTowns && this.state.currentAgentTowns.map((town, index) => {
                    return <p key={'town-name-'+index}>{town}</p>
                  })}
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </ReactModal>

        </div>
      </div>
    )
  }
}
