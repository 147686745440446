import { connect } from 'react-redux'
import {fetchUser,  
        handleSignOut, 
        updatePassword, 
        clearPhaseUser,
        updateOwnProfile } from '../../store/user/duck'
import AgentCalendarForm from './component'
import { getUSerProfileByID, 
        getFilterParams, 
        getTowns,
        addUsers,
        clearPhase, } from '../../store/dashboard/duck'
import ProfileForm from './component'
const Profile = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    saveGoogleDataPhase: state.user.saveGoogleDataPhase,
    rxError: state.user.error,
    phase: state.user.phase,
    userData: state.dashboard.userData,
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    townData: state.dashboard.townData,
    addUserPhase: state.dashboard.addUserPhase,
    passwordPhase: state.user.passwordPhase,
    ownUpdatePhase: state.user.ownUpdatePhase,
    ownUpdatMessage: state.user.ownUpdatMessage,
    junglerUserPhase: state.dashboard.junglerUserPhase,
  }),
  // Map actions to props
  {
    fetchUser,
    getUSerProfileByID,
    getFilterParams,
    getTowns,
    updatePassword,
    addUsers,
    clearPhase,
    clearPhaseUser,
    handleSignOut,
    updateOwnProfile
  }
)(ProfileForm)
export default Profile
