import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import axios from 'axios'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import styles from './styles.scss'
// import io from 'socket.io-client'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
// let socket = io.connect(SOCKET_HOSTNAME)

let _user = ''
let availableDateSlots = ''
let forEndDate = ''

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

class StratagistAgentChatCenterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      chat_text:'',
      sender_id: '',
      startDate: moment(),
      receiver_id:'',
      selectAgentId:'',
      openSchedule: false,
      itemCount: '',
      selectAppointment:'',
      selectStartTime: '', 
      selectEndTime: '',
      description: '',
      err: {}
    }
    this.sendChatMessage = this.sendChatMessage.bind(this)
  }
 
  componentWillMount(){
    const {fetchUser} = this.props;
    const receiver_id = this.props.match.params.id && this.props.match.params.id
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id, sender_id:_user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      this.props.getClientAgent(data)
    }
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({receiver_id:_clientid})
    }
  //   socket.on('new_message', (data) => {
  //     this.componentDidUpdate() 
  // })
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.addAppointmentPhase === "success"){
      this.setState({ openSchedule : false })
      this.props.clearPhase()
    }
  }

  componentDidMount(){
    document.title = "Jungler: Client Chat";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handleChange(e){
    let itemCount = ''
    if(e.target.name === "selectStartTime"){
       itemCount = _.filter(availableDateSlots, (avil) => {
        return avil.props.value > e.target.value
      })
      this.setState({[e.target.name]: e.target.value, itemCount: itemCount})
    }
    this.setState({[e.target.name]: e.target.value })
  }

  componentDidUpdate(){
    const receiver_id = this.props.match.params.id
      if(this.state.sender_id !='' && receiver_id !=''){
        const data={}
        data.sender_id = this.state.sender_id;
        data.receiver_id = receiver_id;
        this.props.getChatHistory(data);
        $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 100);
      }  
   }
  
   sendChatMessage(){
    // const receiver_id = this.props.match.params.id && this.props.match.params.id
    // if( this.state.chat_text !== '' && receiver_id != '' && this.state.sender_id !=''){
    //   socket.emit('new_message', { 
    //     sender_id : this.state.sender_id, 
    //     receiver_id: receiver_id, 
    //     type: "text", 
    //     content: this.state.chat_text
    //   })
    //   this.setState({chat_text: ''})
    // }
  }

  openScheduleBar(id, e) {
    if(id === ''){
      this.setState({ openSchedule : true})
    }else{
      this.setState({ openSchedule : true, selectAgentId: id})
    }
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  handelDateChange(timezone, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     _user = cookies.get('user')
      const data = {}
      data.user_id  = this.state.selectAgentId //this is agent user id
      data.timezone = timezone                 //current user time zone
      data.date     = selectDate               //select date for scheduling
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }

  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectAgentId, selectAppointment, textarea,
           startDate } = this.state
    
    if (selectAgentId === '' || selectAgentId === "Select the client" || selectAgentId.trim() === '') {
      err.selectAgentId = 'Please Select Client'
    }
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
      err.selectEndTime = 'Select End Time'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date =  moment(startDate._d).format("YYYY-MM-DD")
     let data = {}
     data.timezone = timezone
     data.user_id  = userId
     data.appointment_type = selectAppointment
     data.date     = date
     data.client_id = this.state.selectAgentId
     data.start_time = selectStartTime
     data.end_time   = selectEndTime
     data.description = this.state.description
     this.props.addAgentAppointment(data)
     // this.setState({ openSchedule : false, selectClient: "Select the client" })
     }
  }

  render() {
    const {
      user,
      agentData,
      location,
      availabilityData,
      chatHistoryData,
    } = this.props
    const townInfo = location && location.state && location.state;
    let style = {
      marginRight: '16px',
      color: '#C6CE4A'
    };
    let town_link = 'javascript:void(0);'
    let authorization = cookies.get('Authorization')
    if(townInfo && townInfo.town_url){
    town_link = townInfo.town_url ? townInfo.town_url+'?userid='+this.state.userid+'&auth='+ authorization : '';
    }
    // if(user && user.role !== 'client'){
    //   return(
    //     <Redirect to='/client-login' />
    //   )
    // }

  let agentList = ''
  if(agentData &&  agentData && agentData.length>0) {
    agentList = agentData.map((val, index) => {
      return (
        <option key={index} value={val.agent_id}>{val.agent_first_name +' '+ val.agent_last_name}</option>
      )
    });
  }

  let appoinment = config.typeOfAppointments.map((val, index) => {
    return (
      <option key={index} value={val.value}>{val.name}</option>
    )
  });

  availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
    let pos = availabilityData.data.slot.length - 1
    if (pos === indexslot) {
      return(
        <option disabled={pos} key={indexslot} value={slotval}>{slotval}</option>
      )
    } else {
      return(
        <option key={indexslot} value={slotval}>{slotval}</option>
      )
    }
  });

  forEndDate = this.state.itemCount && this.state.itemCount.map((endTime, endIndex) =>{
    return(
     <option key={endIndex} value={endTime.props.value}>{endTime}</option>
    )
  });

    
    $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 500);
    return (
      <div>
        <div className="message__page--container">
          <div className="message__page--header">
            <div className="message__page--header-title">
              Strategist Agent Chat Center
            </div>
            {/* <div className="message__page--header-right">
              <button 
                className="btn btn__green"
                onClick={this.openScheduleBar.bind(this, this.props.match.params.id ? this.props.match.params.id: '')}>
                Schedule
              </button>
            </div> */}
            <div className="message__page--close">
              <a onClick={this.props.history.goBack}>< img src="img/close.svg"/></a>
            </div>
          </div>
          <div className="message__page--wrapper">
            <div className="message__page--left-side">
              <ul className="chat__user--list">
                  <li className={this.props.location.pathname === `/stratagist-agent-chat/${townInfo.id}` ? 'active': ''}>
                    <span className="chat__user--avtar">
                      <img src={townInfo.agent_avatar ? townInfo.agent_avatar : 'img/login-bg-img.png'}/>
                    </span>
                    <div className="chat__user--info">
                      <h2 className="chat__user--name">{townInfo.agent_full_name} </h2>
                      <h4 className="chat__user--type">agent</h4>
                    </div>
                  </li>                   
              </ul>
            </div>
            <div className="message__page--right-side">

              <div className="chat__header">
              
                <div className="chat__header--heading">Here are the towns this agent will help you with: </div>
                <span className="chat__header--users">
                  <a  href={town_link} target="_blank" style={style}>
                  <span>{townInfo.town_name}</span>
                  </a>
                </span>
              </div>
              <div className="chat__container">
                <div className="chat__container--info-btn">
                  {/*<img src="img/info-icon.svg"/>*/}
                </div>
                <div className="mesgs">
                <div className="chat__msg--history">
                      {
                        chatHistoryData && chatHistoryData.data && chatHistoryData.data.length >0 ? chatHistoryData.data.map((chat,ind) => {
                          let userAvatar = user && user.avatar;
                          let userName   = user && user.first_name +' '+user.last_name;
                          let myuserId     = user && user.id;
                          return(
                            <div key={ind}>

                              { (chat.sender_id != myuserId) ? (
                                <div>
                                    <div className="chat__msg--date__time">{moment(chat.created).format('MM/DD/YY ddd hh:mm A')}</div>

                                  <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                      <div className="chat__msg--avtar">
                                        <img className="img-responsive" src={chat.sender_avatar ? chat.sender_avatar:"img/login-bg-img.png"} alt=""/>
                                                
                                      </div>
                                      <div className="chat__msg--avtar-name">
                                        {chat.sender_full_name} (Agent)
                                      </div>
                                    </div>
                                    <div className="received_msg">
                                      <div className="received_withd_msg">
                                      {chat.type==='image' || chat.type==='meadia' ?
                                            <a href={chat.content} target="_blank">
                                            <img src={chat.content}/>
                                            </a>
                                          :
                                            <p>{chat.content}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                ):
                                <div>
                                  <div className="chat__msg--date__time">{moment(chat.created).format('MM/DD/YY ddd hh:mm A')}</div>
                                    <div className="outgoing_msg">
                                      <div className="sent_msg">
                                      {chat.type==='image' || chat.type==='meadia' ?
                                            <a href={chat.content} target="_blank">
                                            <img src={chat.content}/>
                                            </a>
                                          :
                                            <p>{chat.content}</p>
                                        }
                                      </div>
                                      <div className="outgoin_msg_img">
                                        <div className="chat__msg--avtar-name">
                                          {userName ? 'Me' :''}
                                        </div>
                                      <div className="chat__msg--avtar">
                                        <img className="img-responsive" src={userAvatar ? userAvatar:"img/login-bg-img.png"} alt=""/>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              }
                            </div>
                          )
                        })
                      :
                      <div className="mesgs nochathistory">
                        <div className="chat__msg--history">
                        <div className="noChatHistory">
                        <img src="img/chat_icon.svg" className="chatIcon" />
                          Your currently do not have any messages.
                        </div>
                        </div>
                      </div>
                      }
                    </div>
                  <div className="type_msg">
                  <div className="chat___msg--input-write">
                      <input 
                        type="text" 
                        name="chat_text" 
                        value={this.state.chat_text}
                        onChange={this.handleChange.bind(this)} 
                        className="chat___msg--input" 
                        placeholder="Type a text" />
                      <button 
                        className="chat__msg--send__btn" 
                        type="submit"
                        onClick={()=> this.sendChatMessage()}>
                        Send
                      </button>
                  </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>

        <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
              <div className="schedule__sidebar--form--wrapper">
                <div className="form-group">
                  <label className="text__label--sm">Agent Name</label>
                  <select 
                    className="form-control custom-select" 
                    name="selectAgentId" 
                    value = {this.state.selectAgentId}  
                    onChange={this.handleChange.bind(this)}>
                    <option value=''>Select the Agent</option>
                    {agentList}
                  </select>
                </div>
                { this.state.err.selectAgentId ?
                <span className="error_msg">
                {this.state.err.selectAgentId}
                </span> : '' }

                <div className="form-group">
                  <label className="text__label--sm">Appointment Type</label>
                  <select className="form-control custom-select" name="selectAppointment" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                    <option>Select the type of Appointment</option>
                     {appoinment}
                  </select>
                </div>
                {this.state.err.selectAppointment ?
                <span className="error_msg">
                {this.state.err.selectAppointment}
                </span> : '' }

                <div className="form-group">
                 <DatePicker
                    selected={this.state.startDate}
                    customInput={<DatePickerCustomInput />}
                    onChange={this.handelDateChange.bind(this, _user.time_zone)}
                    showTimeSelect
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date()}
                    className="form-control custom-select date__picker"
                    value={this.state.startDate}
                    placeholderText="Pick a date">
                  </DatePicker>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text__label--sm">Start</label>
                    <select 
                      name='selectStartTime' 
                      className="form-control custom-select-small" 
                      value = {this.state.selectStartTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>Start Time</option>
                      {availableDateSlots}
                    </select>
                  </div>
                {this.state.err.selectStartTime ?
                <span className="error_msg">
                {this.state.err.selectStartTime}
                </span> : '' }
                </div>
                

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text__label--sm">End</label>
                    <select 
                      name="selectEndTime" 
                      className="form-control custom-select-small" 
                      value = {this.state.selectEndTime}  
                      onChange={this.handleChange.bind(this)}>
                      <option>End Time</option>
                       {forEndDate}
                    </select>
                  </div>
                  {this.state.err.selectEndTime ?
                <span className="error_msg">
                {this.state.err.selectEndTime}
                </span> : '' }
                </div>
                </div>

                <div className="form-group rem__marg--btm">
                  <label className="text__label--sm">Appointment Description</label>
                  <textarea 
                  name="description" className="form-control custom-textarea"
                   onChange={this.handleChange.bind(this)}
                   placeholder="Enter description here..."></textarea>
                </div>



              </div>
            </div>
            <div className="schedule__sidebar--footer">
              <button 
                className="btn btn__green btn__green--lg btn-block btn__schedule" 
                onClick={this.submitSchedulingData.bind(this, _user.time_zone, _user.id)}>
                Schedule
              </button>
            </div>
          
        </div>

      </div>
    )
  }
}

export default reduxForm({
  form: 'messagecenter',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StratagistAgentChatCenterForm)
