import React, { PureComponent, Fragment /*, PropTypes*/ } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import Cookies from 'universal-cookie';
import Config, { formatNumber } from '../../../../config';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ConfirmModal from '../Common/ConfirmModal';
import { Popover, Menu, Dropdown, Spin, Modal, notification } from 'antd';
import PaginationMain from '../Common/Pagination';
import PlaceSearchBox from '../../../../components/GoogleAutoComplete';
import ReactModal from 'react-modal';
import TopNavigation from '../../../../components/TopNavigation/container';
import SidebarNavigation from '../../../../components/SidebarNavigation/component'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component';
import axios from 'axios';
import {
  SortUpIcon,
  TownsColorIcon,
  FilterIcon,
  MoreIcon,
  CloseIcon,
  DeleteIcon,
  SortDownIcon
} from '../../../../components/icons';
import Pagination from 'react-js-pagination';

import Loader from '../../../../components/Loader';
import * as Yup from 'yup';

import './styles.scss';
import { logoutInvalidRequest } from '../../../../utils/helper';
const cookies = new Cookies();
const { confirm } = Modal;

const townNameValidate = Yup.object().shape({
  name: Yup.string().required(`Town Name can't be empty`)
});

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;
export default class TownsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      defaultSortBy: 'townId',
      isDeleteModalOpen: false,
      featuredAgentModal: false,
      editNameModal: false,
      towns: [],
      addTownReportModal: false,
      isEditMode: false,
      filterParams: {},
      selectedRowToUpdate: { name: '' },
      isLoading: true,
      townsCount: 0,
      user: {},
      showDropdown: false,
      currentActivePage: 1,
      suburbsData: [],
      submitTownData: {},
      suburbsDataMain: [],
      searchStringAr: [],
      fetchTownsObj: {
        limit: 20,
        offset: 0,
        filters: [],
        // What is missing data key?
        // missingData,
        keyValue: '',
        keyType: 0,
        search: ''
      },
      agentsToShow: [],
      agentList: [],
      above1Million: null,
      below1Million: null
    };
    this.openFeaturedAgentModal = this.openFeaturedAgentModal.bind(this);
    this.openEditNameModalModal = this.openEditNameModalModal.bind(this);
  }

  componentDidMount() {
    document.title = 'Towns | Jungler';
    const _user = cookies.get('user');
    const sortByCount = localStorage.getItem('townsSortBy');

    let localTownLimit = parseInt(localStorage.getItem('townLimit'))
    if(localTownLimit && localTownLimit !== null && localTownLimit !== '' && localTownLimit !== undefined){
      let fetchTownsObj = this.state.fetchTownsObj
      fetchTownsObj.limit = localTownLimit ? localTownLimit : 20
      this.setState({ fetchTownsObj })
    }


    const filtersObj = this.state.fetchTownsObj;
    filtersObj.keyValue = sortByCount ? sortByCount : '';

    const filters = localStorage.getItem('strategistTownListFilter');
    if (filters) {
      const ids = [];
      JSON.parse(filters).map((t, i) => {
        ids.push(t.id);
      });
      filtersObj.filters = ids;
      this.setState({ searchStringAr: JSON.parse(filters) });
    }

    this.setState({
      user: _user,
      defaultSortBy: sortByCount ? sortByCount : 'townId'
    });

    this.props.fetchTownReport(filtersObj);
    this.props.getFilterParams();
  }

  selectSuburb = async (e, setFieldValue) => {
    const id = Number(e.target.value);
    const suburbName = await filter(this.state.suburbsData, {
      id
    });

    setFieldValue('suburb_name', get(suburbName, '[0].name'));
    setFieldValue('suburb_id', id);
    this.forceUpdate();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const obj = {};

    if (!prevState.showDropdown) {
      const assignedSuburbs = get(nextProps, 'filterParams.data.assignedSuburbs', false)
      if (assignedSuburbs) obj.suburbsData = obj.suburbsDataMain = assignedSuburbs
    }

    if (get(nextProps, 'generateReportPhase') === 'success') {
      nextProps.clearTownPhase();
      if (get(nextProps, 'generateReportData.status')) {
        notification.success({
          message: 'Town Report Created!'
        });
        const townId = get(nextProps, 'generateReportData.data.townId')
        if (townId) {
          nextProps.history.push(
            `/strategist/town-characteristics/${townId}`
          );
        }
      }

      if (isEmpty(get(nextProps, 'generateReportData.data'))){
        obj.isLoading = false;
        obj.addTownReportModal = false;
        notification.error({
          message: get(nextProps, 'generateReportData.message')
        });
      }

      if (!get(nextProps, 'generateReportData.status')) {
        obj.isLoading = false;
        obj.addTownReportModal = false;
        notification.error({
          message: get(nextProps, 'generateReportData.message')
        });
      }
    }

    if (nextProps.updateTownPhase === 'success') {
      nextProps.clearTownPhase();
      obj.editNameModal = false;
      obj.isLoading = true;
      obj.isDeleteModalOpen = false;
      obj.deleteData = {};
      nextProps.fetchTownReport(prevState.fetchTownsObj);
      obj.isEditMode = false;
    }

    if (get(nextProps, 'townReportPhase', '') === 'success') {
      nextProps.clearTownPhase();
      const suburbAr = get(nextProps, 'suburbsData') ? get(nextProps, 'suburbsData') : [];
      const suburbs = get(nextProps, 'filterParams.data.suburbs') ? get(nextProps, 'filterParams.data.suburbs') : [];

      const isAdmin = ['admin', 'superadmin'].includes(prevState.user.role)

      const filteredSubrubs = !isAdmin ? filter(suburbs, s => {
        if (suburbAr.includes(s.id)) {
          return s;
        }
      }) : [...suburbs];

      if (!isEmpty(filteredSubrubs)) {
        obj.suburbsData = filteredSubrubs;
        obj.suburbsDataMain = filteredSubrubs;
      } else {
        obj.suburbsData = suburbs;
        obj.suburbsDataMain = suburbs;
      }
      obj.towns = get(nextProps, 'towns', []);
      obj.isLoading = false;
      obj.isDeleteModalOpen = false;
      obj.deleteData = {};
      obj.townsCount = get(nextProps, 'townsCount');
      obj.filterParams = get(nextProps, 'filterParams.data', {});
    }

    if (get(nextProps, 'deleteTownPhase') === 'success') {
      nextProps.clearTownPhase();
      obj.isDeleteModalOpen = false;
      obj.deleteData = {};
      nextProps.fetchTownReport(prevState.fetchTownsObj);
    }

    return obj;
  }

  filterVisible = visible => {
    this.setState({ visible });
  };
  openFeaturedAgentModal(town) {
    this.setState({
      featuredAgentModal: true,
      selectedRowToUpdate: town,
      agentList: town.agents,
      above1Million: town && _.size(town.aiAgents) > 0 ? town.aiAgents[0].max_agent_id : 0,
      below1Million: town && _.size(town.aiAgents) > 0 ? town.aiAgents[0].min_agent_id : 0,
    });
  }
  openEditNameModalModal(town) {
    this.setState({
      editNameModal: true,
      selectedRowToUpdate: town,
      isEditMode: true
    });
  }
  closeModal() {
    this.setState({
      featuredAgentModal: false,
      editNameModal: false,
      agentList: [],
      above1Million: null,
      below1Million: null
    });
  }

  onPageChange = page => {
    const fetchTownsObj = this.state.fetchTownsObj;
    fetchTownsObj.offset = page - 1;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.setState({ currentActivePage: page, isLoading: true, fetchTownsObj });

    this.props.fetchTownReport(fetchTownsObj);
  };

  filterByTown = text => {
    const fetchTownsObj = this.state.fetchTownsObj;
    fetchTownsObj.search = text;
    fetchTownsObj.offset = 0
    fetchTownsObj.keyValue = ''
    if (!text || text.length >= 3) {
      this.setState({ isLoading: true, activePage: 1, fetchTownsObj });

      this.props.fetchTownReport(fetchTownsObj);
    }
  };

  changeResultCount = count => {
    const fetchTownsObj = this.state.fetchTownsObj;
    fetchTownsObj.limit = count;

    this.props.fetchTownReport(fetchTownsObj);
    fetchTownsObj.limit = count
    this.setState({ isLoading: true, fetchTownsObj });
    localStorage.setItem('townLimit', count)
  };

  sortTable = count => {
    if (count === 'townId' || count === 'townName') {
      localStorage.setItem('townsSortBy', count);
      this.setState({ defaultSortBy: count });
    }

    const fetchTownsObj = this.state.fetchTownsObj;
    fetchTownsObj.keyValue = count;
    fetchTownsObj.keyType = fetchTownsObj.keyType;
    fetchTownsObj.offset = 0

    if (fetchTownsObj.keyValue === count) {
      fetchTownsObj.keyType = fetchTownsObj.keyType === 1 ? 0 : 1;
    }

    this.setState({ isLoading: true, fetchTownsObj, activePage: 1 });
    this.props.fetchTownReport(fetchTownsObj);
  };

  sortByTown = async town => {
    this.setState({ isLoading: true });
    this.forceUpdate();
    const townId = town.id;
    let searchStringAr = this.state.searchStringAr;

    const fetchTownsObj = this.state.fetchTownsObj;
    const filtersAr = get(fetchTownsObj, 'filters');
    const index = filtersAr.indexOf(townId);

    if (filtersAr.includes(townId)) {
      filtersAr.splice(index, 1);
      const filtered = await filter(searchStringAr, t => {
        return t.id !== town.id;
      });
      searchStringAr = filtered;
    }
    if (index === -1) {
      filtersAr.push(townId);
      searchStringAr.push(town);
    }

    fetchTownsObj.filters = filtersAr;
    fetchTownsObj.offset = 0

    localStorage.setItem(
      'strategistTownListFilter',
      JSON.stringify(searchStringAr)
    );
    this.setState({
      fetchTownsObj,
      searchStringAr,
      activePage: 1,
      showDropdown: isEmpty(searchStringAr) ? false : true
    });
    this.props.fetchTownReport(fetchTownsObj);
    this.forceUpdate();
  };

  deleteTownConfirm = async town => {
    
    const { deleteTownById } = this.props;
    const self = this;
    
    this.setState({ isDeleteModalOpen: true, deleteData: {id: town.town_db_id} });

  };

  deleteTown = () => {
    this.setState({ isLoading: true });
    this.props.deleteTownById(this.state.deleteData);
  };

  capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  changeTownName = values => {
    this.setState({ isLoading: true });
    const townData = this.state.selectedRowToUpdate;

    const data = {
      town_id: get(townData, 'town_db_id'),
      town_name: this.capitalize(get(values, 'name'))
    };

    this.props.updateTownName(data);
  };

  handleFilterTown = async e => {
    const value = get(e, 'target.value');
    const { suburbsDataMain, suburbsData } = this.state;
    let filtered = suburbsDataMain;

    if (value) {
      filtered = await filter(
        suburbsDataMain,
        search =>
          search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    this.setState({ suburbsData: filtered });

    this.forceUpdate();
  };

  onCreateTownReport = async e => {
    this.props.getFilterParams()
    this.setState({ addTownReportModal: true })
  }

  clearFilter = () => {
    this.setState({ isLoading: true });
    this.forceUpdate();
    const searchStringAr = [];
    const fetchTownsObj = this.state.fetchTownsObj;
    fetchTownsObj.filters = [];
    fetchTownsObj.offset = 0
    localStorage.setItem(
      'strategistTownListFilter',
      JSON.stringify(searchStringAr)
    );
    this.setState({
      showDropdown: false,
      fetchTownsObj,
      activePage: 1,
      searchStringAr
    });
    this.props.fetchTownReport(fetchTownsObj);
    this.forceUpdate();
  };

  createTownReport = townData => {
    this.setState({ isLoading: true, submitTownData: townData });
    this.props.generateReport(townData);
  };

  setTownAddress = async (data, setFieldValue) => {
    // const townName = await filter(data.address_components, a => {
    //   return (
    //     a.types.includes('sublocality_level_1') || a.types.includes('locality')
    //   );
    // });
    let townName = get(data, "formatted_address");
    townName = townName.replace(/\d{5}/, "");
    townName = String(townName).replace(", USA", "");
    setFieldValue("google_place_id", get(data, "place_id"));
    setFieldValue("formatted_address", get(data, "formatted_address"));
    setFieldValue("town_lat", String(get(data, "location.lat")));
    setFieldValue("town_lng", String(get(data, "location.lng")));
    setFieldValue("town_name", get(townName, "[0].long_name"));
    setFieldValue("town_name", townName);
  };

  addFeaturedAgent = agentData => {
    this.setState({ isLoading: true });
    agentData.townId = parseInt(this.state.selectedRowToUpdate.townId);
    let token = cookies.get('Authorization')
    axios({
      method: 'POST',
      url: HOSTNAME + '/addAIAgent',
      data: agentData,
      headers: {
        Authorization: token
      }
    }).then(res => {
      const fetchTownsObj = this.state.fetchTownsObj;
      this.setState({
        featuredAgentModal: false,
        editNameModal: false,
        agentList: [],
        above1Million: null,
        below1Million: null,
        fetchTownsObj,
        activePage: 1
      })
      this.props.fetchTownReport(fetchTownsObj);
    }).catch(logoutInvalidRequest)
  };

  toggleAgentAssignedModal = data => {
    const agentsToShow = sortBy(get(data, 'agents'), 'agent_full_name');
    this.setState({
      agentsToShow,
      selectedRowToUpdate: data ? data : {},
      viewAgentsAssignedToTownModal: !this.state.viewAgentsAssignedToTownModal
    });
  };

  render() {
    const {
      isLoading,
      towns,
      viewAgentsAssignedToTownModal,
      townsCount,
      fetchTownsObj,
      currentActivePage,
      filterParams,
      suburbsData,
      showDropdown,
      selectedRowToUpdate,
      searchStringAr,
      defaultSortBy,
      agentsToShow,
      isDeleteModalOpen,
      user
    } = this.state;

    const TownRowContent =
      !isEmpty(towns) &&
      towns.map((town, i) => {
        return (
          <Fragment key={i}>
            <tr>
            {
              user.role == "business development" || user.role == "local"?
              <td>
                <button
                  className="btn btn__btn btn__link"
                >
                  {get(town, 'name')}
                </button>
              </td>:
              <td>
                <button
                  className="btn btn__btn btn__link"
                  onClick={() => this.openEditNameModalModal(town)}
                >
                  {get(town, 'name')}
                </button>
              </td>
            }
              <td>{get(town, 'suburbName')}</td>
              {
                user.role == "business development" || user.role == "local"?
                null:
                <td
                  // onClick={() =>
                  //   get(town, 'agentCount') > 0 &&
                  //   this.toggleAgentAssignedModal(town)
                  // }
                  // className={`${
                  //   get(town, 'agentCount') > 0
                  //     ? 'text__green cursor__pointer'
                  //     : ''
                  // }`}
                >
                  {get(town, 'agentCount', '0')}
                </td>
              }
              {
                user.role == "business development" || user.role == "local"?
                null:
                <td>
                  {/*{get(town, 'agentCount') > 0 && (*/}
                  {/*  <button*/}
                  {/*    className="btn btn__btn btn__link"*/}
                  {/*    onClick={() => this.openFeaturedAgentModal(town)}*/}
                  {/*  >*/}
                  {/*    Edit Feature Agent*/}
                  {/*  </button>*/}
                  {/*)}*/}
                </td>
              }
              <td>
                {user.role !== 'agent' && (
                  <Link
                    className="btn__link btn__edit__town__characteristics"
                    to={{
                      pathname: `/strategist/town-characteristics/${get(town, 'town_db_id')}`,
                      state: { town }
                    }}
                  >
                    Edit Town Characteristics
                  </Link>
                )}
              </td>
              <td>
                <button
                  onClick={() => {
                    window.location.href = get(town, 'town_url')
                  }}
                  className="btn btn__btn btn__link"
                >
                  View Report
                </button>
              </td>
              <td>
                {/* <Dropdown
                  overlay={
                    <Menu>
                      {get(town, 'is_delete') && (
                        <Menu.Item
                          onClick={() => this.deleteTownConfirm(town)}
                          key="1"
                        >
                          Delete
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  trigger={['click']}
                  overlayClassName="more__menu__overlay"
                >
                  <button className="btn more__menu ant-dropdown-link" href="#">
                    <MoreIcon />
                  </button>
                </Dropdown> */}
                {get(town, 'canDelete') && (
                    <button
                      className="btn btn__link"
                      onClick={() => this.deleteTownConfirm(town)}
                    >
                      <DeleteIcon className="cursor__pointer" />
                    </button>
                  )}
              </td>
            </tr>
          </Fragment>
        );
      });
    return (
      <div className="layout-has-sidebar">
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deleteTown}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
         {
            user.role == "business development" || user.role == "local"?
            <SidebarNavigation {...this.props}/>:
            <AdministrationSidebarNavigation {...this.props} />
          }
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <h2 className="heading-title">
                  <span className="heading-icon">
                    <TownsColorIcon className="" />
                  </span>
                  Towns ({formatNumber(townsCount)})
                </h2>
              </div>

              <div className="col-sm-12 col-md-9">
                <div className="towns__head">
                {
                  user.role === "admin" || user.role === "superadmin" || user.role === "strategist" ?
                    <button
                      className="btn btn__btn btn-dark btn__create__town__report mr__15"
                      onClick={this.onCreateTownReport}
                    >
                      Create Town Report
                    </button> :
                  <div>
                  </div>
                }
                  {/* This is for filter section */}
                  <Popover
                    content={
                      <div className="filter">
                        <div className="filter__header">
                          <button
                            onClick={() => this.clearFilter()}
                            className="btn btn__btn btn-secondry btn__xs"
                          >
                            Clear
                          </button>
                          <div className="filter__title">Filters</div>
                          <button
                            onClick={() => this.setState({ visible: false })}
                            className="btn btn__btn btn-dark btn__xs"
                          >
                            Close
                          </button>
                        </div>
                        <div className="filter__body">
                          <ul className="filter__list">
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                Suburb
                                <input
                                  type="checkbox"
                                  name="status"
                                  checked={showDropdown}
                                  onChange={e => {
                                    this.setState({
                                      showDropdown: get(e, 'target.checked')
                                    });

                                    if (!e.target.checked) {
                                      this.clearFilter();
                                    }
                                  }}
                                  className="custom-checkbox--input"
                                />
                                <span className="custom-checkbox-checkmark"></span>
                              </label>
                            </li>
                            {showDropdown && (
                              <ul
                                className="filter__list"
                                style={{ marginLeft: '43px' }}
                              >
                                <div className="search__form mt__10 mb__10">
                                  <input
                                    type="text"
                                    className="form-control search__form__input"
                                    onChange={this.handleFilterTown}
                                    name="searchStatus"
                                    placeholder="Search for suburbs..."
                                    // value={searchStatus}
                                  />
                                </div>
                                {!isEmpty(suburbsData) &&
                                  suburbsData.map((data, i) => {
                                    const isSelected = filter(searchStringAr, {
                                      id: data.id,
                                      name: data.name
                                    });

                                    return (
                                      <li key={i}>
                                        <label className="custom-checkbox custom-checkbox-line">
                                          {data.name}
                                          <input
                                            type="checkbox"
                                            name="status"
                                            checked={
                                              isEmpty(isSelected) ? false : true
                                            }
                                            onChange={e =>
                                              this.sortByTown(data)
                                            }
                                            value={data.id}
                                            className="custom-checkbox--input"
                                          />
                                          <span className="custom-checkbox-checkmark"></span>
                                        </label>
                                      </li>
                                    );
                                  })}
                              </ul>
                            )}
                          </ul>
                        </div>
                      </div>
                    }
                    placement="bottom"
                    className="filter"
                    overlayClassName="filter__overlay"
                    // title="Title"
                    trigger="click"
                    visible={this.state.visible}
                    onVisibleChange={this.filterVisible}
                  >
                    <button className="btn btn__btn btn-dark btn-filter">
                      <FilterIcon className="filter-icon" /> Filters
                    </button>
                  </Popover>
                  {/* End filter section */}

                  <div className="sort__results">
                    <div className="material-textfield sort__by">
                      <select
                        onChange={e => this.sortTable(get(e, 'target.value'))}
                        className="form-control custom-select material-textfield-input"
                        value={defaultSortBy}
                      >
                        <option value={'townId'}>Most Recent</option>
                        <option value={'townName'}>Alphabetical</option>
                      </select>
                      <label className="material-textfield-label">Sort by</label>
                    </div>
                    <div className="material-textfield results__page">
                      <select
                        onChange={e =>
                          this.changeResultCount(get(e, 'target.value'))
                        }
                        value={get(this, 'state.fetchTownsObj.limit', 20)}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={get(page, 'value')}>
                                {get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="townFilterText"
                      onChange={e =>
                        this.filterByTown(get(e, 'target.value', ''))
                      }
                      placeholder="Search by town..."
                      required
                    />
                  </div>
                  <ul className="filter__list__flitered">
                    {!isEmpty(searchStringAr) &&
                      searchStringAr.map((search, i) => {
                        return (
                          <li key={i}>
                            {search.name}
                            <span onClick={() => this.sortByTown(search)}>
                              <CloseIcon className="close-icon" />
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                  <Spin
                    size="large"
                    spinning={isLoading}
                    indicator={<Loader />}
                  >
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th
                              className="cursor__pointer"
                              onClick={() => this.sortTable('town_name')}
                            >
                              <span className="table-column-title">
                                Town Name
                              </span>
                              <span className="table-column-sorter">
                                {get(fetchTownsObj, 'keyValue', '') ===
                                  'town_name' &&
                                get(fetchTownsObj, 'keyType') ? (
                                  <SortUpIcon />
                                ) : (
                                  <SortDownIcon />
                                )}
                              </span>
                            </th>
                            <th
                              className="cursor__pointer"
                              onClick={() => this.sortTable('suburbs')}
                            >
                              <span className="table-column-title">Suburb</span>
                              <span className="table-column-sorter">
                                {get(fetchTownsObj, 'keyValue', '') ===
                                  'suburbs' && get(fetchTownsObj, 'keyType') ? (
                                  <SortUpIcon />
                                ) : (
                                  <SortDownIcon />
                                )}
                              </span>
                            </th>
                            {
                            user.role == "business development" || user.role == "local"?
                              null:
                              <th
                                className="cursor__pointer"
                                onClick={() => this.sortTable('agents')}
                              >
                                <span className="table-column-title">
                                  # of Agents
                                </span>
                                <span className="table-column-sorter">
                                  {get(fetchTownsObj, 'keyValue', '') ===
                                    'agents' && get(fetchTownsObj, 'keyType') ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  )}
                                </span>
                              </th>
                            }
                          </tr>
                        </thead>
                        <tbody>{TownRowContent}</tbody>
                      </table>
                      {/* <div className="blank__table__state">No Clients</div> */}
                    </div>
                  </Spin>

                  <PaginationMain
                    total={townsCount}
                    pageChanged={page => this.onPageChange(page)}
                    pageSize={get(fetchTownsObj, 'limit', 20)}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <ReactModal
          isOpen={this.state.featuredAgentModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Featured Agent"
          portalClassName="react-modal"
        >
          <Formik
            enableReinitialize
            initialValues={{
              isAbove1Million: 0,
              isBelow1Million: 0,
              below1Million: this.state.below1Million,
              above1Million: this.state.above1Million
            }}
            onSubmit={this.addFeaturedAgent}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form>
                <div className="react-modal-dialog react-modal-dialog-centered">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">Featured Agent</h5>
                    <p className="react-modal-subtitle text-muted">
                      {get(selectedRowToUpdate, 'name')}
                    </p>
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn react-modal-close"
                      onClick={this.closeModal.bind(this)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        name="below1Million"
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        onChange={e => {
                          setFieldValue(
                            'below1Million',
                            parseInt(e.target.value)
                          );
                          setFieldValue('isBelow1Million', 1);
                        }}
                      >
                        <option>Select</option>
                        {!isEmpty(this.state.agentList) &&
                          this.state.agentList.map((data, i) => {
                            return (
                              <option key={i} value={data.agent_id}>
                                {data.agent_full_name}
                              </option>
                            );
                          })}
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Less than $1M
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        name="above1Million"
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        onChange={e => {
                          setFieldValue(
                            'above1Million',
                            parseInt(e.target.value)
                          );
                          setFieldValue('isAbove1Million', 1);
                        }}
                      >
                        <option>Select</option>
                        {!isEmpty(this.state.agentList) &&
                          this.state.agentList.map((data, i) => {
                            return (
                              <option key={i} value={data.agent_id}>
                                {data.agent_full_name}
                              </option>
                            );
                          })}
                      </Field>
                      <label className="material-textfield-label  label-lg">
                        More than $1M<span className="text-danger"></span>
                      </label>
                    </div>
                    <div>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn__btn btn__lg btn-dark w__100"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ReactModal>

        <ReactModal
          isOpen={this.state.addTownReportModal}
          contentLabel="Add Town Report"
          portalClassName="react-modal"
        >
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={this.createTownReport}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form>
                <div className="react-modal-dialog react-modal-dialog-centered">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">Add Town Report</h5>
                    <p className="react-modal-subtitle text-muted">
                      {get(selectedRowToUpdate, 'name')}
                    </p>
                    <button
                      type="button"
                      className="btn react-modal-close"
                      disabled={isLoading}
                      onClick={() =>
                        this.setState({
                          addTownReportModal: !this.state.addTownReportModal
                        })
                      }
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        name="suburb_id"
                        onChange={e => {
                          e.preventDefault();
                          this.selectSuburb(e, setFieldValue);
                        }}
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        required
                      >
                        <option selected disabled>
                          Select
                        </option>
                        {!isEmpty(suburbsData) &&
                          suburbsData.map((suburb, i) => {
                            return (
                              <option key={i} value={get(suburb, 'id')}>
                                {suburb.name}
                              </option>
                            );
                          })}
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Suburb
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        component={PlaceSearchBox}
                        name="town_name"
                        setAddress={data => {
                          this.setTownAddress(data, setFieldValue);
                        }}
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        required
                      />

                      <label className="material-textfield-label  label-lg">
                        Town Name<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn__btn btn__lg btn-dark w__100"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ReactModal>

        <ReactModal
          isOpen={this.state.editNameModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Featured Agent"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Edit Name</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal.bind(this)}
              >
                <CloseIcon />
              </button>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={selectedRowToUpdate}
              validationSchema={townNameValidate}
              onSubmit={(values, { setSubmitting }) => {
                // setSubmitting(true);
                this.setState({ isLoading: true });
                this.changeTownName(values);
              }}
            >
              {({ values, errors, isSubmitting, setFieldValue }) => (
                <Form noValidate>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        name="name"
                        className="form-control material-textfield-input textfield-input-lg"
                        required
                      />
                      <label className="material-textfield-label label-lg">
                        Edit Town<span className="text-danger">*</span>
                      </label>
                      <ErrorMessage
                        className="invalid-feedback"
                        name="name"
                        component="div"
                      />
                    </div>
                    <div>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn__btn btn__lg btn-dark w__100"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* <form onSubmit={this.changeTownName}>

            </form> */}
          </div>
        </ReactModal>

        {/* Show agents assigned to towns */}
        {/* When click on agent names so open this modal */}
        <ReactModal
          isOpen={viewAgentsAssignedToTownModal}
          contentLabel="Report"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {`
                  ${get(selectedRowToUpdate, 'name', '')}'s agent(s)`}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.toggleAgentAssignedModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="info__block">
                {!isEmpty(agentsToShow) &&
                  agentsToShow.map((agent, index) => {
                    const data = { id: agent.agent_id };
                    return (
                      <p
                        // className="cursor__pointer"
                        key={`agent-name-${index}`}
                      >
                        {`${get(agent, 'agent_full_name')}, ${get(agent, 'firm_name') || 'NA'}`}
                      </p>
                    );
                  })}
              </div>
              <div></div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
