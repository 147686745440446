import React from 'react'
import ReactModal from 'react-modal'
import { CloseIcon } from '../../../../../components/icons'
import './styles.scss'

export const shouldCopyEmailDisplay = (isCopyEmail, clients, user) => {
    
    if(!user)return false
    
    const { role } = user
    const required = clients && clients.length > 0
    switch(role){
      case "superadmin":
      case "admin":
        return required
      case "strategist":
      return isCopyEmail && required
      default: return false
    }
  }

export const shouldCopyEmailEnabled = (user, filterCount) => {
    
    if(!user)return false
    const { role } = user
    
    switch(role){
      case "superadmin":
      case "admin":
        return true
      case "strategist":
      return filterCount > 0
      default: return false
    }
}

export const DisableCopyEmailPopup = ({ enabled, onRef }) => {

  const [show, showPopup] = React.useState(false)

  const open = () => {
    showPopup(true)
  }

  const close = () => {
    showPopup(false)
  }

  React.useEffect(() => {
    if(typeof onRef === 'function')
      onRef({ open, close })
  },[])

  return (
    <>
      {enabled && <div className = 'disable-overlay' onClick = {open} />}

      <ReactModal
          isOpen={(show)}
          onRequestClose={close}
          portalClassName="react-modal"
        >
        <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header no-border">
              { !enabled && <h5 className="react-modal-title">Emails Copied</h5> }
              <button type="button" className="btn react-modal-close" onClick={close}><CloseIcon /></button>
          </div>
          <div className="react-modal-body react-modal-body-sm no-padding-top">
            <p className="react-modal-subtitle mb__3 copy-email-msg">
              { !enabled ? 'In order to send an email to these individuals, compose an email and “paste” them into the “bcc” field. You can then remove individual email addresses from there.' : 'To copy client emails, you must first apply a filter.' }
            </p>
           </div>
        </div>
      </ReactModal>
    </>
  )
}