import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import Modal from 'react-modal'
import { confirmAlert } from 'react-confirm-alert'
import Header from '../Header'
import styles from './styles.scss'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME

const SortableItem = SortableElement(({value, priEditClick, priDeleteClick}) => 
<div className="table school__table--tabs flex__table">
      <div className="name__table">{value.InstitutionName}</div>
      <div className="grade__table">{value.gradespancodebldgtext}</div>
      <div className="teacher__table">{value.value !== 'Not Reported' ? value.studentteacher + ':1' : 'Not Reported'}</div>
      <div className="student__table">{value.studentsnumberof}</div>
      <div className="fl__right">
        <button className="edit__btn edit__btn--new" onClick={()=> priEditClick(value)}></button>
        <button className="edit__btn delete__btn--new" onClick={()=> priDeleteClick(value)}></button>
      </div>
  </div>
  )

const SortableList = SortableContainer(({items, priEditClick, priDeleteClick}) => {
  return (
      <div>
        <div className="flex__table rem__odd--even">
          <div className="name__table">Schools</div>
          <div className="grade__table">Grades</div>
          <div className="teacher__table">Student to Teacher Ratio</div>
          <div className="student__table"># of Students</div>
          <div className="fl__right"></div>
        </div>

        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} priEditClick={priEditClick} priDeleteClick={priDeleteClick} value={value} />
        ))}
      </div>
  )
})

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    padding: '0 0',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class PrivateSchool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      privateData: [],
      isDateLoading: false,
      modalIsOpen: false,
      school_id: 0,
      edit_school: '',
      edit_grades: '',
      edit_ratio: '',
      edit_no_of_students: '',
      modelType: '',
      add_school: '',
      add_grades: '',
      add_ratio: '',
      add_no_of_students: '',
      err: {},
      town_name: '',
      openSideBar: false,
      privateAttom: [],
    }
    this.onSortEnd = this.onSortEnd.bind(this)
    this.priEditClick=this.priEditClick.bind(this)
  }

  componentWillMount(){
    // this.setState({ isDateLoading: true })
    this.getTownName()
    this.getAttomSchool()
  }

  componentWillReceiveProps(np){
    if(np.editPrivatePhase !== this.props.editPrivatePhase){
      this.loadingSchoolData()
    }
    if(np.deletePrivatePhase !== this.props.deletePrivatePhase){
      this.loadingSchoolData()
    }
    if(np.addPrivatePhase !== this.props.addPrivatePhase){
      this.loadingSchoolData()
    }
    if(np.addPublicPhase !== this.props.addPublicPhase){
      this.loadingSchoolData()
    }
  }

  componentDidMount(){
    this.loadingSchoolData()
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name })
    }
  }

  async getAttomSchool(){
    const {match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    const getPrivateAttom = () => axios.get(
      `${HOSTNAME}/attomSchools?town_id=${townId}&type=private`
    ).catch(logoutInvalidRequest)
    const privateSchool = await getPrivateAttom()
    if(privateSchool && privateSchool.data && privateSchool.data.schools){
      let privateAttom = privateSchool.data.schools

      privateAttom.map((data, index) => {
        const attomSchoolId = data.Identifier.OBInstID
        const filtered = this.state.privateData.filter(o => {
          return String(o.schoolAttomId) === String(attomSchoolId)
        })
        if (filtered && filtered.length > 0) {
          privateAttom[index].School.isAvailable = true
        }
      })
      
      this.setState({ privateAttom: privateAttom, isDateLoading: false }, ()=>{
      })
    }else{
    this.setState({ isDateLoading: false })
    }
  }

  async loadingSchoolData(){
    const {match: { params: { townId }} } = this.props
    const fetchPrivateData = () => axios.get(
      `${HOSTNAME}/schoolDataByTownId?town_id=${townId}&type=attom_school_private`
    ).catch(logoutInvalidRequest)
    const priData = await fetchPrivateData()
    if(priData && priData.data && priData.data.data){
      const privateData = _.orderBy(priData.data.data, ['sort_by'],['asc'])
      this.setState({ privateData: privateData, isDateLoading: false })
    }
  }

  editInfoChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  parseSchools(schools) {
    const schoolsFinal = []
    _.forEach(schools, school => {
      const obj = {}
      obj.school_name = _.get(school, '[0].InstitutionName','')
      obj.school_grades = _.get(school, '[0].gradespancodebldgtext','')
      obj.school_rating = _.get(school, '[0].GSTestRating','0')
      obj.school_stud_teacher_ratio = _.get(school, '[0].studentteacher','')
      obj.no_of_student = _.get(school, '[0].studentsnumberof','')
      obj.school_type = _.get(school, '[0].Filetypetext','')
      obj.geocodinglatitude = _.get(school, '[0].geocodinglatitude','')
      obj.geocodinglongitude = _.get(school, '[0].geocodinglongitude','')
      schoolsFinal.push(obj)
    })
    return schoolsFinal
  }

  afterOpenModal(){ }

  closeModal=()=>{
    this.setState({ modalIsOpen: false, school_id: 0, modelType: '' })
  }

  priEditClick(data, e){
    this.setState({ modalIsOpen: true, edit_school: data.InstitutionName, edit_grades: data.gradespancodebldgtext, edit_ratio: data.studentteacher, edit_no_of_students: data.studentsnumberof, school_id: data.id, modelType: "edit" })
  }

  priAddClick(e){
    this.setState({ 
        modelType: "add", 
        modalIsOpen: true,
        add_school: '',
        add_grades: '',
        add_ratio: '',
        add_no_of_students: '',
    })
  }

  priDeleteClick(data, e){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
            <h4 className="popupheader__heading">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          <p className="allpoppadding__para poppinsfontsedit">You want to delete this school?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.dltInst(data)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  async dltInst(data){
    const { deletePrivateSchool, match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    let data1 = {
      school_id: data.id,
      town_id: parseInt(townId)
    }
    deletePrivateSchool(data1)
  }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false, school_id: 0, modelType: '' })
  }

  openSideBar(e){
    this.setState({ openSideBar: true })
  }

  closeScheduleBar(e){
    this.setState({ openSideBar: false })
  }

  addModelBtn(e){
    let err = {}
    const {match: { params: { townId }}, addPrivateSchool } = this.props
    const { add_school, school_id, add_ratio, add_grades, add_no_of_students } = this.state
    if( add_school == '' || add_school.trim() === ''){
      err.school = "Enter School Name"
    }
    if( add_ratio === '' || add_ratio.trim() === ''){
      err.ratio = "Enter Ratio"
    }
    if( add_grades === '' || add_grades.trim() === ''){
      err.grades = "Enter Grade"
    }
    if( add_no_of_students === '' || add_no_of_students.trim() === ''){
      err.no_of_student = "Enter Number of Students"
    }

    this.setState({ err })
     if (!Object.keys(err).length) {
        let data = {
          school_id: 0,
          school_name: add_school,
          grades: add_grades,
          ratio: add_ratio,
          totalStudent: add_no_of_students,
          type: 'private',
          town_id: parseInt(townId),
          rating: 0
        }
        this.setState({ isDateLoading: true, modalIsOpen: false })
        addPrivateSchool(data) 
     }
  }

  editModelBtn(e){
    const {match: { params: { townId }}, editPrivateSchool } = this.props
    const { edit_school, school_id, edit_ratio, edit_grades, edit_no_of_students } = this.state
    let data = {
      school_id: school_id,
      school_name: edit_school,
      grades: edit_grades,
      ratio: edit_ratio,
      totalStudent: edit_no_of_students,
      type: 'private',
      town_id: parseInt(townId),
      rating: 0
    }
    editPrivateSchool(data)
    this.setState({ isDateLoading: true, modalIsOpen: false })
  }

  async addPrivateAttom(data,index){
    const {match: { params: { townId }}, addPrivateSchool } = this.props
    if (data.School && !data.School.Studentsnumberof) {
      const detail = await this.getSchoolDetails(data, index)
    }
    let attom = data.School
    let data1 = {
      school_id: data.Identifier.OBInstID,
      school_name: attom.InstitutionName,
      grades: attom.gradespancodebldgtext,
      type: 'private',
      town_id: parseInt(townId),
      rating: 0,
      totalStudent: attom.Studentsnumberof,
      ratio: attom.Studentteacher,
      geocodinglatitude: attom.geocodinglatitude,
      geocodinglongitude: attom.geocodinglongitude,
      locationcity: attom.locationcity,
      zip54: attom.zip54
    }
    const privateAttom = this.state.privateAttom
    privateAttom[index].School.isAvailable = true
    this.setState({ isDateLoading: true, modalIsOpen: false, openSideBar: false, privateAttom })
    addPrivateSchool(data1)
  }

  async getSchoolDetails(data,index){
    const {match: { params: { townId }} } = this.props
    const attomSchoolId = data.Identifier.OBInstID
    const schoolDetail = () => axios.get(
      `${HOSTNAME}/attomSchoolDetail?attomSchoolId=${attomSchoolId}&town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const detail = await schoolDetail()
    if(detail && detail.data && detail.data.schoolDetail){
      const enrollment = detail.data.schoolDetail.SchoolEnrollment
      const privateAttom = this.state.privateAttom
      const currentSchool = privateAttom[index]
      currentSchool.School.Studentsnumberof = enrollment.Studentsnumberof
      currentSchool.School.Studentteacher = enrollment.Studentteacher
      currentSchool.School.gradespancodebldgtext = detail.data.schoolDetail.SchoolSummary.gradespancodebldgtext
      privateAttom[index] = currentSchool
      this.setState({ privateAttom })
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({privateData}) => ({
      privateData: arrayMove(privateData, oldIndex, newIndex),
    }))

    const self = this
    setTimeout(()=>{
      self.state.privateData && self.state.privateData.map((val, idx)=>{
        self.updateSchoolList(val.id, idx)
      })
    }, 500)
  }

  async updateSchoolList(id, idx){
    const { match: { params: { townId } } } = this.props
    const schoolUpdate = () => axios.get(
      `${HOSTNAME}/update_school?town_id=${parseInt(townId)}&school_id=${parseInt(id)}&school_index=${parseInt(idx)}`
    ).catch(logoutInvalidRequest)
    const towns = await schoolUpdate()
  }
  
  render() {
    const { privateData,rows, cols, modelType, add_school, add_ratio, add_grades, add_no_of_students, edit_school, edit_ratio, edit_grades, edit_no_of_students } = this.state
    return (

      <div className="town-report">
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }
         {/* <Header title={`Edit ${this.state.town_name} Private Schools`} /> */}
         <div className="atom__add--btn">
         <button className="add__button__fixed_new" type="submit" onClick={this.priAddClick.bind(this)}>Add School</button>
         <button className="right_button" type="submit" onClick={this.openSideBar.bind(this)}>Attom Schools</button>
         </div>

         {
           (this.state.privateData && this.state.privateData.length > 0) ?
           <SortableList
           items={this.state.privateData} 
           priEditClick={(index) => this.priEditClick(index)}
           priDeleteClick={(index) => this.priDeleteClick(index)}
           onSortEnd={this.onSortEnd} />
           :
           (<p className="no__data--show">No Private Schools</p>)
         }
         

         <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
        <div className="flexdiv">
          <h4 className="popupheader__heading">{ (modelType === "add") ? "Add Quick Facts" : "Edit Quick Facts" }</h4>
          <button
            onClick={this.closeCrossBtn.bind(this)}
            type="button"
            id="close_user_modal"
            className="close_img close_img--white"
            data-dismiss="modal"
            aria-label="Close"
          >
          <img src="../../img/close_btn.svg"/>
          </button>
        </div>

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">School Name</label>
            <input 
              name={(modelType === "add") ? "add_school" : "edit_school" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_school : edit_school }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.school ?
          <span className="error_msg town__err">
          {this.state.err.school}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Grades</label>
            <input 
              name={ (modelType === "add") ? "add_grades" : "edit_grades" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_grades : edit_grades }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.grades ?
          <span className="error_msg town__err">
          {this.state.err.grades}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Student to Teacher Ratio</label>
            <input 
              name={ (modelType === "add") ? "add_ratio" : "edit_ratio" } 
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_ratio : edit_ratio }
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.ratio ?
          <span className="error_msg town__err">
          {this.state.err.ratio}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global"># of Students</label>
            <input 
              name={ (modelType === "add") ? "add_no_of_students" : "edit_no_of_students" } 
              className="textinputs__global" 
              rows="2"
              value = { (modelType === "add") ? add_no_of_students : edit_no_of_students}
              onChange={this.editInfoChange.bind(this)}/>
          </div>
          { this.state.err.no_of_student ?
          <span className="error_msg student__town">
          {this.state.err.no_of_student}
          </span> : '' }

          <div className="modalbtns modalbtns__new">
            {
              (modelType === "add") ?
                <button
                className="btns__fullwidth poppinsfontsedit"
                type="button"
                onClick={this.addModelBtn.bind(this)}>
                Save 
              </button>
              :
              <button
                className="btns__fullwidth poppinsfontsedit"
                type="button"
                onClick={this.editModelBtn.bind(this)}>
                Update 
              </button>
            }
          </div>
          </div>
        </Modal>



      {/*-----------this is for sidebar-----------*/}
        <div className={this.state.openSideBar ? "schedule__sidebar--container open sidebar__shadow" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            Attom Private Schools
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
            <div className="schedule__sidebar--form--wrapper">
              {
                (this.state.privateAttom && this.state.privateAttom.length > 0 ) && 
                this.state.privateAttom.map((pri, idx) =>{
                  return(
                    <ul className="position__container" key={idx}>
                      <li>
                        {(pri.School && pri.School.isAvailable) ? (
                          <span className="label__text add__report__txt">Live</span>
                        ) : (
                          <button onClick={this.addPrivateAttom.bind(this, pri, idx)} className="btn__add add__report__btn">Save To Report</button>
                        )}
                        <span className="label__text">Name:</span>
                        <span className="label__details">{pri.School.InstitutionName}</span>
                      </li>
                      <li>
                        <span className="label__text">Address:</span>
                        <span className="label__details">{pri.School.locationaddress}</span>
                      </li>
                      <li>
                        <span className="label__text">City:</span>
                        <span className="label__details">{pri.School.locationcity}</span>
                      </li>
                      <li>
                        <span className="label__text">Zipcode:</span>
                        <span className="label__details">{pri.School.zip54}</span>
                      </li>
                      {(pri.School && pri.School.Studentteacher) ? (
                          <li>
                            <span className="label__text">Grade:</span>
                            <span className="label__details">{pri.School.gradespancodebldgtext}</span>
                            <span className="label__text label__text--edit">Ratio:</span>
                            <span className="label__details label__details--edit">{pri.School.Studentteacher}</span>
                            <span className="label__text label__text--edit">No. of students:</span>
                            <span className="label__details label__details--edit">{pri.School.Studentsnumberof}</span>
                          </li>
                        ) : (
                          <li>
                            <a onClick={this.getSchoolDetails.bind(this, pri, idx)} className="link__view__more">View More...</a>
                          </li>
                        )}
                    </ul>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default reduxForm({
  form: 'PrivateSchool', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: []
})(PrivateSchool)
