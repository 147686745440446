import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import BigCalendar from 'react-big-calendar'
import Calendar from 'react-calendar'
import moment from 'moment'
import _ from "lodash";
import timezone from 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert'; 
import dates from './dates'
import DatePicker from 'react-datepicker'
import calendar from './calendar.scss'
import calendar1 from './calendar1.scss';
import styles from './styles.scss'
import styles1 from './styles1.scss'
import Cookies from 'universal-cookie'
import $ from 'jquery'

import config from '../../config'
import { getTimeFormate } from '../../config'
import { timeConverter } from '../../config'
import { ToastContainer, toast } from 'react-toastify';
const required = value => value ? undefined : 'Required'


const cookies = new Cookies()


BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])

let forEndDate = ''
let _user = ''

class DatePickerCustomInput extends React.Component {

  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class AgentCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSchedule: false,
      selectClient: '',
      selectAppointment:'',
      selectStartTime: '', 
      selectEndTime: '',
      allSlots:'',
      calendar_id: '',
      add_edit_type: '',
      pickStartTime: '',
      startDate:moment(),
      client_limit:1000,
      activePage:1,
      itemCount: [],
      description: '',
      events: [],
      err: {},
      err1: {},
      startTimeErr: ''
    }
    
    this.handleChange = this.handleChange.bind(this)
    setTimeout(()=>{
      var classname = document.getElementsByClassName("react-calendar__navigation__arrow")
      var myNxtFunction = function() {
        var attribute = this.getAttribute("class")
        if(attribute === "react-calendar__navigation__arrow react-calendar__navigation__next-button"){
          document.getElementsByClassName('rbc-btn-group')[0].children[2].click();
        }
      };
      var myPreFunction = function() {
        var attribute = this.getAttribute("class")
        if(attribute === "react-calendar__navigation__arrow react-calendar__navigation__prev-button"){
          document.getElementsByClassName('rbc-btn-group')[0].children[1].click();
        }
      };
      for (var i = 0; i < classname.length; i++) {
        if(classname[i].getAttribute('class').indexOf("2") ===-1){
          classname[i].addEventListener('click', myNxtFunction, false)
        }
        if(classname[i].getAttribute('class').indexOf("1") ===-1){
          classname[i].addEventListener('click', myPreFunction, false)
        }
      }
    },1000)
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data1 = {}
      data1.user_id  = _user.id
      this.props.fetchUser(data1)
      const data2 = {}
      data2.user_id  = _user.id
      data2.timezone = _user.time_zone
      this.props.getAppointments(data2)
      let agentData = {}
      agentData.client_limit = this.state.client_limit
      agentData.client_offset= this.state.activePage
      agentData.subType = 'all'
      this.props.agentGetUsers(agentData)

      const data3 = {}
      data3.user_id  = _user.id
      data3.timezone = _user.time_zone
      data3.date     = moment(this.state.startDate._d).format("YYYY-MM-DD")
      this.props.getAgentAvailability(data3)

      let search_data = {} 
      search_data.client_limit = 1000
      search_data.client_offset = 1
      this.props.agentSearchGetUsers(search_data)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.addAppointmentPhase === "success"){
       _user = cookies.get('user')
      if(_user) {
        this.setState({userid: _user.id})
        const data = {}
        data.user_id = _user.id;
        data.timezone = _user.time_zone
        this.props.getAppointments(data)
        this.calendarBackground()
      }
    }

    if(nextProps.addAppointmentPhase === "success" && nextProps.addAppointmentPhase === this.props.addAppointmentPhase){
      toast(nextProps.addAppointmentMessage, {autoClose: true })
      this.props.clearPhase()
    }

    if(nextProps.deleteAppointmentPhase === "success"){
      toast(nextProps.deleteAppointmentData.message, {autoClose: true })
      this.props.clearPhase()
      const data = {}
      data.user_id  = _user.id
      data.timezone = _user.time_zone
      this.props.getAppointments(data)
      this.calendarBackground()
      this.setState({ 
        openSchedule : false, 
        selectClient: '', 
        startDate: moment(),
        selectStartTime: '', 
        selectEndTime: '', 
        selectAppointment: '', 
        description: '',
        calendar_id: '',
        add_edit_type: '',
      })
      //window.location.reload();
    }

    if(nextProps.deleteAppointmentPhase === "error"){
      toast(nextProps.deleteAppointmentData.message, {autoClose: true })
      this.props.clearPhase()
      const data = {}
      data.user_id  = _user.id
      data.timezone = _user.time_zone
      this.props.getAppointments(data) // TODO: function to get appointments
      this.calendarBackground()
      this.setState({ 
        openSchedule : false, 
        selectClient: '', 
        startDate: moment(),
        selectStartTime: '', 
        selectEndTime: '', 
        selectAppointment: '', 
        description: '',
        calendar_id: '',
        add_edit_type: '',
      })
    }

  }

  componentDidMount(){
    this.calendarBackground()
    document.title = "Jungler: Agent Calendar";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  calendarBackground(){
    setTimeout(()=> {
      let data = this.props.appointmentData.data;
      if(data.length>0){
      for (var i = 0; i < data.length; i++) {
          let calVal = document.getElementsByClassName('react-calendar__tile')
          let calVal2 = Array.from(calVal);
          calVal2.forEach((element)=> {
            // if(moment(data[i].start_date) > moment(new Date())){
              if(moment(element.firstChild.dateTime).format('YYYY-MM-DD')==moment(data[i].start_date).format('YYYY-MM-DD')){
                element.style.backgroundColor = "#C3CF21"
                element.style.color = "#FFF"
              }
            // }
          });
        }
      }
    }, 1000)

    let redColorValue = document.getElementsByClassName('react-calendar__month-view__days__day--weekend')
    let redVal = Array.from(redColorValue)
    redVal.forEach((val)=> {
      val.style.color = "$primaryColor"
    })

    let navLabel = document.getElementsByClassName('react-calendar__navigation__label'); 
    for (let i = 0; i < navLabel.length; i++) { 
        navLabel[i].style.backgroundColor = "#FFF"
        navLabel[i].disabled = true;
    }

    let next2 = document.getElementsByClassName('react-calendar__navigation__arrow react-calendar__navigation__next2-button');
    for (let i = 0; i < next2.length; i++) { 
        next2[i].style.backgroundColor = "#FFF"
        next2[i].style.color = "#FFF"
        next2[i].disabled = true;
    }
    
    var prev2 = document.getElementsByClassName('react-calendar__navigation__arrow react-calendar__navigation__prev2-button');
    for (let i = 0; i < prev2.length; i++) { 
        prev2[i].style.backgroundColor = "#FFF"
        prev2[i].style.color = "#FFF"
        prev2[i].disabled = true;
    }
    // document.getElementsByClassName('rbc-toolbar-label')[0] && document.getElementsByClassName('rbc-toolbar-label')[0].remove()
  }

  handleChange(event) {
    let itemCount = []
    let avilData = this.props.availabilityData && this.props.availabilityData.data && this.props.availabilityData.data.slot
    if(event.target.name === "selectStartTime"){
       itemCount = _.filter(avilData, (avil) => {
        if(avil > event.target.value){
          return avil;
        }
      })
    }
    
    this.setState({[event.target.name]: event.target.value, itemCount: itemCount},()=>{
    })
  }

  openScheduleBar(type, e) {
    if(type === "add"){
      const data = {}
      data.user_id  = _user.id
      data.timezone = _user.time_zone
      data.date     = moment(this.state.startDate._d).format("YYYY-MM-DD")
      this.props.getAgentAvailability(data)
      this.setState({ 
        openSchedule : false, 
        selectClient: '', 
        startDate: moment(),
        selectStartTime: '', 
        selectEndTime: '', 
        selectAppointment: '', 
        description: '',
        calendar_id: '',
        add_edit_type: "add"
      })
    }else{
      this.setState({ add_edit_type : "edit" })
    }
    this.setState({ openSchedule : true })
  }

  closeScheduleBar(){
    this.setState({ 
      openSchedule : false, 
      selectClient: '', 
      startDate: moment(),
      selectStartTime: '', 
      selectEndTime: '', 
      selectAppointment: '', 
      description: '',
      calendar_id: '',
      add_edit_type: '',
    })
  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     let _user = cookies.get('user')
      const data = {}
      data.user_id  = _user.id
      data.timezone = _user.time_zone
      data.date     = selectDate
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }

  changeMonth(event){
    this.calendarBackground()
  }

  deleteAppointment(cal_id, event){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
                <h4 className="popupheader__heading">Are you sure?</h4>
                <button
                  onClick={onClose}
                  type="button"
                  id="close_user_modal"
                  className="close_img close_img--white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                <img src="../../img/close_btn.svg"/>
                </button>
              </div>
            <p className="allpoppadding__para poppinsfontsedit">You want to cancel this appointment? This will be removed from your calendar and we will send an email to the client to let them know.</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                if(cal_id && cal_id !=''){
                  this.handleClickDelete(cal_id)
                  onClose()
                }else{
                  onClose()
                }
            }}>Yes Cancel</button>
          </div>
        )
      }
    })
  }
  // Deleting Google and Outlook event from jungler 
  deleteGandOAppointment(type, cal_id, event){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
              <img src="../../img/close_btn.svg"/>
              </button>
            </div>
            <p className="allpoppadding__para poppinsfontsedit">If you want to remove this event make sure you have also removed from your {type} as well. If you don’t this event will appear again when we auto sync your appointments which happens every 15 minutes.</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                if(cal_id && cal_id !=''){
                  this.handleClickDelete(cal_id)
                  onClose()
                }else{
                  onClose()
                }
            }}>Yes Remove it</button>
          </div>
        )
      }
    })
  }

  rescheduleAppointment(cal_id, timezone, userId, event){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>

              <div className="flexdiv">
                <h4 className="popupheader__heading">Are you sure?</h4>
                <button
                  onClick={onClose}
                  type="button"
                  id="close_user_modal"
                  className="close_img close_img--white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                <img src="../../img/close_btn.svg"/>
                </button>
              </div>

            <p className="allpoppadding__para poppinsfontsedit">Are you sure you want to reschedule this appointment? Rescheduling will send the client an email with the new time</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                if(cal_id && cal_id !=''){
                  this.submitSchedulingData(timezone, userId, event)
                  onClose()
                }else{
                  onClose()
                }
            }}>Yes, Reschedule</button>
          </div>
        )
      }
    })
  }

  handleClickDelete(cal_id){
    if(cal_id && cal_id !=''){
      const appData = {}
      appData.calendar_id = cal_id;
      this.props.deleteAppointment(appData)
      this.setState({ openSchedule : false })
    }else{
      alert('Somthing went wrong, please try again to cancel this appointment.')
    }
  }


  handleSelectEvent(event, target){
    if(event !== undefined && event !== ""){
      if(event.appointment_type === "outlook_personal_events" || event.appointment_type === "google_personal_events"){
        this.setState({ openSchedule: false })
        this.deleteGandOAppointment(event.appointment_type === "outlook_personal_events" ? "Outlook Calendar" : "Google Calendar", event.event_id, event)

      }else{
        this.setState({calendar_id: event.event_id, add_edit_type: "edit"})
        let agent_full_name = event.agent_first_name+' '+event.agent_last_name
        const data = {}
        data.user_id  = event.user_id // event.agent_id
        data.timezone = _user.time_zone
        data.dateType = "forAllDate"
        data.date     = moment(event.start).format("YYYY-MM-DD")
        this.props.getAgentAvailability(data)
        
        setTimeout(()=> {
          this.setState({
            openSchedule : true,
            selectClient: event.client_id, 
            startDate: moment(event.start),
            selectStartTime:moment(event.start).format("HH:mm"),
            selectEndTime:moment(event.end).format("HH:mm"),
            selectAppointment: event.appointment_type, 
            selectAgentId: event.user_id, // event.agent_id,
            description: event.description
          })
          let avilData = this.props.availabilityData && this.props.availabilityData.data && this.props.availabilityData.data.slot
          let itemCount = []
          if(this.state.selectStartTime){
             itemCount = _.filter(avilData, (avil) => {
              if(avil > this.state.selectStartTime){
                return avil;
              }
            })
          }
          this.setState({itemCount:itemCount})
        }, 1500)
      }
    }
  }


  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectClient, selectAppointment, textarea,
           startDate } = this.state
    
    if (selectClient === '' || selectClient === "Select the client") {
      err.selectClient = 'Please Select Client'
    }
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
      err.selectEndTime = 'Select End Time'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date              =  moment(startDate._d).format("YYYY-MM-DD")
     let data              = {}
     data.timezone         = timezone
     data.user_id          = userId
     data.appointment_type = selectAppointment
     data.date             = date
     data.calendar_id      = this.state.calendar_id
     data.client_id        = this.state.selectClient
     data.start_time       = selectStartTime
     data.end_time         = selectEndTime
     data.description      = this.state.description

     this.props.addAgentAppointment(data) // Where it submits the form
     this.setState({ openSchedule : false, selectClient: "Select the client" })
     }
  }
  updateTooltip(e) {
    return ""
  }
  render() {
  const {
    availabilityData,
    usersData,
    allclientData,
    agentSearchGetUsersData
  } = this.props
  let availableDateSlots = ''
  $(document).ready(function(){
    $('.rbc-toolbar button:contains("agenda")').text('List');
  })


  let finalArray = []
  let avilArray = _.concat(_.get(availabilityData, 'unavailableDate'), availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot);
  let data = _.filter(avilArray, function(a) {
    finalArray.push(a)
  });

  if(availabilityData.data === '' || availabilityData.data && availabilityData.data.length == 0){
    this.state.err1.startTimeErr = 'In this date no time slot available'
  }else{
    this.state.err1.startTimeErr = ''
  }

  let clientList = ''
  if(usersData &&  usersData.data && usersData.data.length>0) {
    clientList = usersData.data.map((val, index) => {
      return (
        <option key={index} value={val.id}>{val.first_name +' '+ val.last_name}</option>
      )
    });
  }

  let appoinment = config.typeOfAppointments.map((val, index) => {
    return (
      <option key={index} value={val.value}>{val.name}</option>
    )
  });
  
  availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
    let pos = availabilityData.data.slot.length - 1
    // let  d = new Date(); // for now
    // let currenttimteS = d.getHours()
    // let currentslot =  moment(slotval).format('')
    //if(currentslot > currenttimteS){
      if (pos === indexslot) {
        return(
          <option disabled={pos} key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
        )
      } else {
        return(
          <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
        )
      }
    // }else{
    // }
  
  });

  if( this.state.itemCount && this.state.itemCount.length > 0 ){
    forEndDate = this.state.itemCount.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime}>{timeConverter(endTime)}</option>
        )
    });
  }

  let events= []
  let appointmentData = '' // TODO
    if(this.props.appointmentData && this.props.appointmentData.data && 
      this.props.appointmentData.data.length > 0){
        appointmentData = _.get(this.props.appointmentData, 'data')
        appointmentData = _.uniqBy(this.props.appointmentData && this.props.appointmentData.data, 'event_id')
          appointmentData && appointmentData.map((appData,index) => {
            let obj = []
            let showStart_t = moment.tz(appData.start_date, moment.tz.guess()).format("hh:mm a");
            let appointmentType = appData.appointment_type
            if(appData.appointment_type === 'Follow up agent town tour'){
              appointmentType = 'Follow up agent visit'
            }
            if(appData.appointment_type !== "google_personal_events" && appData.appointment_type !== "outlook_personal_events"){
              obj = {
                id   : appData.client_id,
                title: <div dangerouslySetInnerHTML={{ 
                  __html: showStart_t +'<br/>'+
                          '<h4>'+appData.client_first_name+' '+appData.client_last_name+'</h4>'+
                          'Type: '+appointmentType+'<br/>'+
                          'Agent: '+appData.strategist_first_name+' '+ appData.strategist_last_name}} />,
                start: moment(appData.start_date).toDate(),
                end  : moment(appData.end_date).toDate(),
                strategist_first_name: appData.strategist_first_name,
                strategist_last_name: appData.strategist_last_name,
                client_first_name: appData.client_first_name,
                client_last_name: appData.client_last_name,
                client_id: appData.client_id,
                user_id: appData.user_id,
                appointment_type: appData.appointment_type,
                description: appData.description,
                role: appData.role,
                event_id: appData.event_id,
                agent_first_name: appData.agent_first_name,
                agent_last_name: appData.agent_last_name,
                agent_id: appData.agent_id,
                type: appData.type
              }
            }else{
              let aptType = "Google Calendar Event"
              let aptClassName = "calTypeNormalEvent"
              if(appData.appointment_type == "outlook_personal_events") {
                aptType = "Outlook Calendar Event"
                aptClassName= "calTypeOutlookEvent"
              }else if(appData.appointment_type == "google_personal_events") {
                aptClassName= "calTypeGoogleEvent"
              }
              obj = {
                id   : appData.client_id,
                title: <div dangerouslySetInnerHTML={{ 
                  __html: moment(appData.start_date).format("hh:mm a")+'<br/>'+
                          '<h5>Unavailable</h5>'+
                          '<span class="'+aptClassName+'">Type: '+ aptType+'</span>'}} />,
                start: moment(appData.start_date).toDate(),
                end  : moment(appData.end_date).toDate(),
                strategist_first_name: appData.strategist_first_name,
                strategist_last_name: appData.strategist_last_name,
                client_first_name: appData.client_first_name,
                client_last_name: appData.client_last_name,
                client_id: appData.client_id,
                event_id: appData.event_id,
                user_id: appData.user_id,
                appointment_type: appData.appointment_type,
                description: appData.description,
                role: appData.role,
                type: appData.type
              }
            }
            events.push(obj)

         })
  }

    return (
      <div>
      <ToastContainer autoClose={3000}/>
        <div className="calendar__page--container">
          <div className="calendar__page--header">
            <div className="calendar__page--header-title">
              Jungler Calendar
            </div>
 
            <button 
              type="button" className="appointment-btn"
              onClick={this.openScheduleBar.bind(this, "add")}>
               + Appointment
            </button>

            <div className="calendar__page--close">
              <img className="forCursor" onClick={this.props.history.goBack} src="img/close.svg"/>
            </div>
          </div>

          <div className="calendar__page--wrapper">
            <div className="calendar__page--small-calendar">
              <Calendar
                activeStartDate={new Date()}
                value={new Date()}
                onActiveDateChange={this.changeMonth.bind(this)}
                 />
            </div>

            <div className="calendar__page--big-calendar">
              <BigCalendar
                events={events}
                tooltipAccessor={this.updateTooltip}
                views={['month', 'week', 'day', 'agenda']}
                step={30}
                selectable
                onSelectEvent={this.handleSelectEvent.bind(this)}
                // toolbar={false}
                // components={{toolbar: CustomToolbar}}
                showMultiDayTimes
                defaultDate={new Date()}
                // localizer={localizer}
              />
            </div>

          </div>

        </div>




        <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            {
              (this.state.add_edit_type === "add")?(
                  <p>Appointment Details</p>
                ):<p>Reschedule Appointment Details</p>
            }
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className={(this.state.add_edit_type !== "add") ? 'schedule__sidebar--body cancelAppointments' : "schedule__sidebar--body" }>
            <div className="schedule__sidebar--form--wrapper">

              <div className="form-group">
                <label className="labelinputs__global">Client Name</label>
                <select 
                  className="selectinputs__global" 
                  name="selectClient" 
                  value = {this.state.selectClient}  
                  onChange={this.handleChange.bind(this)}>
                  <option>Select the client</option>
                  {clientList}
                </select>
              </div>
              { this.state.err.selectClient ?
              <span className="error_msg">
              {this.state.err.selectClient}
              </span> : '' }

              <div className="form-group">
                <label className="labelinputs__global">Appointment Type</label>
                <select 
                  className="selectinputs__global" 
                  name="selectAppointment" 
                  value = {this.state.selectAppointment}  
                  onChange={this.handleChange}>
                  <option>Select the type of Appointment</option>
                  {appoinment}
                </select>
              </div>
              {this.state.err.selectAppointment ?
              <span className="error_msg">
              {this.state.err.selectAppointment}
              </span> : '' }

              <div className="form-group">
                <DatePicker
                  selected={this.state.startDate}
                  customInput={<DatePickerCustomInput />}
                  onChange={this.handelDateChange.bind(this, this.props.user.time_zone, this.props.user.id)}
                  showTimeSelect
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date()}
                  className="form-control custom-select date__picker"
                  value={this.state.startDate}
                  placeholderText="Pick a date">
                </DatePicker>
              </div>

              <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelinputs__global">Start</label>
                  <select 
                    name='selectStartTime' 
                    className="selectinputs__global" 
                    value = {this.state.selectStartTime}  
                    onChange={this.handleChange.bind(this)}>
                    <option>Start Time</option>
                    {availableDateSlots}
                  </select>
                </div>
              {this.state.err.selectStartTime ?
              <span className="error_msg">
              {this.state.err.selectStartTime}
              </span> : '' }
              {this.state.err1.startTimeErr ?
              <span className="error_msg">
              {this.state.err1.startTimeErr}
              </span> : '' }
              </div>
              

              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelinputs__global">End</label>
                  <select 
                    name="selectEndTime" 
                    className="selectinputs__global" 
                    value = {this.state.selectEndTime}  
                    onChange={this.handleChange.bind(this)}>
                    <option>End Time</option>
                    {forEndDate}
                  </select>
                </div>
                {this.state.err.selectEndTime ?
              <span className="error_msg">
              {this.state.err.selectEndTime}
              </span> : '' }
              </div>
              </div>
              <div className="form-group">
                <label className="labelinputs__global">Timezone</label>
                <p>{this.props.user.time_zone}</p>
              </div>

              <div className="form-group rem__marg--btm">
                <label className="labelinputs__global">Appointment Description</label>
                <textarea name="description" className="textareainputs__global"
                  onChange={this.handleChange}
                  value={this.state.description}
                  placeholder="Enter description here..." ></textarea>
              </div>
                    </div>
                  </div>
                  <div className="schedule__sidebar--footer text-center">
                  {
                  (this.state.add_edit_type !== "add")? 
                  <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.rescheduleAppointment.bind(this, this.state.calendar_id, this.props.user.time_zone, this.props.user.id)} >Reschedule Appointment</button>
                  :
                  <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this, this.props.user.time_zone, this.props.user.id, )}>Schedule</button>
                  }
                  {
                    (this.state.add_edit_type !== "add")?(
                      <button
                        className="btn cancelAppointments-btn"
                        data={this.state.calendar_id}
                        onClick={this.deleteAppointment.bind(this, this.state.calendar_id)} 
                        >
                        Cancel Appointment
                      </button>
                    ):''
                }
                  </div>
          
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'calendar',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AgentCalendar)
