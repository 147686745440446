import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin, Button, Popover } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon, CopyIcon, FilterIcon } from '../../../../../../components/icons'
import moment from 'moment'
import { size, update, xor, range } from 'lodash'
import DatePicker from '../../../../../../components/datePicker'
import Loader from '../../../../../../components/Loader'
import Cookies from 'universal-cookie'
import ReactExport from "react-data-export"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import $ from 'jquery'
import Config from '../../../../../../config';
import ReactModal from 'react-modal'
import './styles.scss'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
let _user = ''

const defaultFromDate = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
const defaultToDate = moment().format("YYYY-MM-DD HH:mm:ss")

const cookies = new Cookies()
const cookieName = "ClientAssignedPerTownStatuses"
const cookieOptions = {
  expires: new Date(moment().add(1, 'year').format())
}
const inititalClientStatusValues = cookies.get(cookieName)  || []

let allowedStatuses = [
  'Green',
  'Green - Actively Engaged',
  'Green - Somewhat Engaged',
  'Green - Not Very Engaged',
  'UJ Green - Actively Engaged',
  'UJ Green - Somewhat Engaged',
  'UJ Green - Not Very Engaged',
  'Listing',
  'Pending',
  'Staying in Suburbs',
  'Rented',
  'Rented on Own',
  'Rented On Own - Unsubscribe',
  'Rented - Unsubscribe',
  'City Temp'
]

// @TODO Remove manual year-based tags for 'Closed' statuses
for (const year of range(2012, new Date().getFullYear()+1)) {
  allowedStatuses.push(`Closed-${year}`)
  allowedStatuses.push(`Closed-${year} Unsubscribe`)
}

allowedStatuses = allowedStatuses.sort()

export default class ClientsAssignedPerTownComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: defaultFromDate,
      toDate: defaultToDate,
      type: 'assignPerTown',
      selectedIndex: 'all',
      clientPerTownData: [],
      openClient: false,
      openAgent: false,
      currentAgentClients: [],
      currentAgentTags: [],
      copied: false,
      selectIndex: null,
      selectOutIndex: null,
      clientsEmails: [],
      selectOutEmails: false,
      selectInEmails: false,
      datePickerModel: false,
      searchName: '',
      clientStatus: inititalClientStatusValues,
      showAll: true,
      todayDate: moment().format('LL'),
      filtersVisible: false,
      searchClientStatus: ''
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    if (_user && _user.role === "agent" || _user && _user.role === "local") {
      this.props.history.push({ pathname: '/agent/dashboard' })
    }
    if (_user && _user.role === "client") {
      this.props.history.push({ pathname: '/clients' })
    }
    if (_user) {
      this.refreshReportData()
    }
  }

  componentDidUpdate(preProps, preState) {
    const { clientsPerAgentsPhase, clientsPerAgentsData, clearReportV2Phase } = this.props
    if (clientsPerAgentsPhase === "success") {
      clearReportV2Phase()
      let data = []

      if (size(clientsPerAgentsData) > 0) {
        clientsPerAgentsData && clientsPerAgentsData.map((cData, i) => {
          data.push({
            key: i,
            totalAgent: cData.totalAgent,
            totalAgentCount: cData.totalAgentCount,
            totalClient: cData.totalClient,
            totalClientCount: cData.totalClientCount,
            totalStrategist: cData.totalStrategist,
            totalStrategistCount: cData.totalStrategistCount,
            totalTag: cData.totalTag,
            town_id: cData.town_id,
            town_name: cData.town_name,
            exportTotalClient: cData.totalClient.length > 0 ? cData.totalClient.map(e => { return e.client_name }).join(" ,") : "",
            exportTotalTags: cData.totalTag.length > 0 ? cData.totalTag.map(e => { return e }).join(" ,") : "",
            exportTotalAgent: cData.totalAgent.length > 0 ? cData.totalAgent.map(e => { return e.agent_full_name }).join(" ,") : "",
            exporttotalStrategist: cData.totalStrategist.length > 0 ? cData.totalStrategist.map(e => { return e.strategist_name }).join(" ,") : ""
          })
        })
      }
      this.setState({ clientPerTownData: data, isLoading: false })
    }
    if (clientsPerAgentsPhase === "error") {
      clearReportV2Phase()
      this.setState({ clientPerTownData: [], isLoading: false })
    }
  }

  refreshReportData() {
    const apiParams = {
      from: this.state.fromDate,
      to: this.state.toDate,
      type: this.state.type,
      sortBy: {
        key: this.state.sortByKey,
        type: this.state.sortByType
      },
      clientStatus: this.state.clientStatus
    }
    this.props.getClientsPerAgent(apiParams)
  }

  onOpenClientModal(agent) {
    const clients = agent && agent.totalClient
    if (clients.length > 1) {
      let clientsEmails = []
      clients.map((client) => {
        clientsEmails.push(client.email)
      })
      this.setState({ agentName: agent.town_name, openClient: true, currentAgentClients: clients, selectIndex: null, selectOutIndex: null, clientsEmails: clientsEmails.toString() })
    }
  }
  onCloseClientModal() {
    this.setState({ agentName: '', currentAgentClients: [], openClient: false, selectIndex: null, selectOutIndex: null })
  }

  onOpenTagModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if (tags[0].length > 1) {
      this.setState({ agentName: agent.town_name, open: true, currentAgentTags: tags[0] })
    }
  }
  onCloseModal() {
    this.setState({ agentName: '', currentAgentTags: [], open: false })
  }

  onOpenAgentsModal(agent) {
    const agents = agent && agent.totalAgent
    if (agents.length > 1) {
      this.setState({ agentName: agent.town_name, openAgent: true, currentTownAgents: agents })
    }
  }
  onCloseAgentsModal() {
    this.setState({ agentName: '', currentTownAgents: [], openAgent: false })
  }

  onOpenStrategistModal(agent) {
    const strategists = agent && agent.totalStrategist
    if (strategists.length > 1) {
      this.setState({ agentName: agent.town_name, openStr: true, currentTownStategist: strategists })
    }
  }
  onCloseStrategistModal() {
    this.setState({ agentName: '', currentTownStategist: [], openStr: false })
  }

  onSearch(e) {
    this.setState({ searchName: e.target.value })
  }

  onCopy1(index) {
    this.setState({ copied: true, selectOutIndex: index })
    setTimeout(function () { $('#showEmail1').hide() }, 2000)
  }
  onCopy2(index) {
    this.setState({ copied: true, selectIndex: index })
    setTimeout(function () { $('#showEmail2').hide(); }, 2000)
  }
  onCopy3() {
    this.setState({ selectOutEmails: true })
    setTimeout(() => {
      this.setState({ selectOutEmails: false })
    }, 2000)
  }
  onCopy4() {
    this.setState({ selectInEmails: true })
    setTimeout(() => {
      this.setState({ selectInEmails: false })
    }, 2000)
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ 
      isLoading: true, 
      selectedIndex: val,
      fromDate: from,
      toDate: to
    }, () => { this.refreshReportData() })
    
  }

  removeClientStatusFilter = (status) => {    
    const updatedClientStatus = this.state.clientStatus.filter(s => s !== status)
    cookies.set(cookieName, updatedClientStatus, cookieOptions)
    this.setState({ 
      isLoading: true,
      clientStatus: updatedClientStatus
    }, () => { this.refreshReportData() })
  }

  resetFilters() {
    this.setState({
      isLoading: true,       
      clientStatus: []
    }, () => { this.refreshReportData() })
  }

  openFilters() {
    this.setState({filtersVisible: true})
  }

  closeFilters() {
    this.setState({filtersVisible: false})
  }

  updateClientStatusFilter = (e) => {
    const updatedClientStatus = xor(this.state.clientStatus, [e.target.value])
    cookies.set(cookieName, updatedClientStatus, cookieOptions)
    this.setState({ 
      isLoading: true,
      clientStatus: updatedClientStatus 
    }, () => { this.refreshReportData() })
  }

  searchClientStatuses = (e) => {
    this.setState({ searchClientStatus: e.target.value })
  }

  displayClientStatuses() {
    // Display filtered statuses + current statuses OR all statuses
    return this.state.searchClientStatus.length ?
      allowedStatuses.filter(s => {
        const clientStatusRegExp = new RegExp(this.state.searchClientStatus, 'i')
        return s.match(clientStatusRegExp) || this.state.clientStatus.includes(s)
      }) :
      allowedStatuses
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { clientPerTownData, clientStatus, searchClientStatus, filtersVisible, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state

    let tableData = clientPerTownData
    if (size(clientPerTownData) > 0) {

      tableData = clientPerTownData.filter(
        (town) => {
          if (this.state.searchName) {
            const townNameRegex = new RegExp(`${this.state.searchName}`, 'i')
            if (!town.town_name.match(townNameRegex)) return false
          }
          return true
        }
      );
    }



    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Clients Assigned Per Town</h2>
                    <div className="tasklist__following__tags">
                      Use filter to adjust results. Default shows all tags.
                    </div>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                      <ExcelSheet data={tableData} name="clients_per_town">
                        <ExcelColumn label="Town" value="town_name" />
                        <ExcelColumn label="Client Name" value="exportTotalClient" />
                        <ExcelColumn label="Tag" value="exportTotalTags" />
                        <ExcelColumn label="Agent Name" value="exportTotalAgent" />
                        <ExcelColumn label="Strategist" value="exporttotalStrategist" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by town name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker filterByDate={this.filterByDate} />
                </div>
                <div className="col-md-2">

                    <div className="filter__result__section">
                      <Popover
                        content={
                          <div className="filter">
                            <div className="filter__header">
                              <button className="btn btn__btn btn-secondry btn__xs" onClick={this.resetFilters.bind(this)}>Clear</button>
                              <div className="filter__title">Filters</div>
                              <button className="btn btn__btn btn-dark btn__xs" onClick={this.closeFilters.bind(this)}>Close</button>
                            </div>
                            <div className="filter__body">
                              <ul className="filter__list">
                                <li><label className="custom-checkbox">Status</label></li>
                                <ul className="filter__list__inner">
                                  <div className="search__form mt__10 mb__10">
                                      <input
                                        type="text"
                                        className="form-control search__form__input"
                                        onChange={(e) => this.searchClientStatuses(e)}
                                        placeholder="Search for status..." />
                                  </div>
                                  {this.displayClientStatuses().map(s => {
                  
                                      return (
                                        <li key={s}>
                                          <label className="custom-checkbox custom-checkbox-line">
                                            {s}
                                            <input type="checkbox"
                                              value={s}
                                              className="custom-checkbox--input"
                                              checked={clientStatus.includes(s)}
                                              onChange={(e) => this.updateClientStatusFilter(e)}/>
                                            <span className="custom-checkbox-checkmark"></span>
                                          </label>
                                        </li>
                                      )

                                  })}

                                  {this.displayClientStatuses().length === 0 &&
                                    <li>
                                      <label className="custom-checkbox custom-checkbox-line">
                                        No status matching "{this.state.searchClientStatus}"
                                      </label>
                                    </li>
                                  }


                                </ul>
                              </ul>
                            </div>
                          </div>
                        }
                        placement="bottomRight"
                        trigger="click"
                        visible={filtersVisible}
                        className="filter"
                        overlayClassName="filter__overlay">
                        <button onClick={this.openFilters.bind(this)} className="btn btn__btn btn-dark btn-filter">
                          <FilterIcon className="filter-icon" />
                          Filter 
                        </button>
                      </Popover>
                    </div>
                </div>

                <div className="col-md-12">
                  {clientStatus.length > 0 && 
                    <ul className="filter__list__flitered">
                      {clientStatus.map(s => 
                        <li key={s}>
                          {s}
                          <span onClick={this.removeClientStatusFilter.bind(this, s)}><CloseIcon className="close-icon" /></span>
                        </li>
                      )}
                    </ul>
                  }
                </div>

              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (size(tableData) > 0) ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Table
                            className="table__fixed"
                            columns={[
                              {
                                title: "NClients",
                                width: 0, // filtering columns list removes sortability
                                defaultSortOrder: 'descend',
                                sortDirections: ['descend','ascend'],
                                sorter: (a, b) => { 
                                  return a.totalClientCount > b.totalClientCount 
                                },
                                dataIndex: 'totalClientCount'
                              },                        
                              {
                                title: 'Town',
                                width: 320,
                                dataIndex: 'town_name',
                                key: '1',
                                fixed: 'left',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.town_name.localeCompare(b.town_name)
                              },
                              {
                                title: 'Client Name',
                                width: 320,
                                dataIndex: 'clientName',
                                key: '2',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.exportTotalClient.localeCompare(b.exportTotalClient),
                                render: (text, str) => {
                                  const totalClient = str && str.totalClient
                                  let clientNo = (totalClient.length - 1) <= 0 ? (totalClient.length > 0 ? ' (' + totalClient[0].email + ')' : '') : " (+" + (totalClient.length - 1) + ")"
                                  return (
                                    <span className="wrap-text multilines cursor__pointer">
                                      <div className={(totalClient.length > 1 ? "link" : '')} onClick={this.onOpenClientModal.bind(this, str)}>{(totalClient.length >= 1 ? totalClient[0].client_name + clientNo : 'No Clients')}
                                        {totalClient.length == 1 ?
                                          <CopyToClipboard
                                            text={totalClient[0].email}
                                            onCopy={this.onCopy1.bind(this, str.key)}>
                                            <i><CopyIcon className="clipcopy-icon" /></i>
                                          </CopyToClipboard> : ''}
                                        {this.state.selectOutIndex === str.key ? <span id="showEmail1" style={{ color: '#c7ce4a' }}> Copied</span> : ''}
                                      </div>
                                    </span>
                                  )
                                }
                              },
                              {
                                title: 'Tag',
                                dataIndex: 'tag',
                                key: '3',
                                width: 320,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                onFilter: (value, record) => record.totalTag.includes(value),
                                sorter: (a, b) => a.exportTotalTags.localeCompare(b.exportTotalTags),
                                render: (text, str) => {
                                  const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                                  const tagArr = tags[0]
                                  let tagNo = (tagArr.length - 1) <= 0 ? '' : " (+" + (tagArr.length - 1) + ")"
                                  return (
                                    <span className="cursor__pointer">
                                      <div className={(tagArr.length > 1 ? "link" : '')} onClick={this.onOpenTagModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0] + tagNo : 'No Tag')}</div>
                                    </span>
                                  )
                                }
                              },
                              {
                                title: 'Agent Name',
                                dataIndex: 'agentName',
                                key: '4',
                                width: 310,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.exportTotalAgent.localeCompare(b.exportTotalAgent),
                                render: (text, str) => {
                                  const totalAgent = str && str.totalAgent
                                  let agentNo = (totalAgent.length - 1) <= 0 ? '' : " (+" + (totalAgent.length - 1) + ")"
                                  return (
                                    <span className="cursor__pointer">
                                      <div className={(totalAgent.length > 1 ? "link" : '')} onClick={this.onOpenAgentsModal.bind(this, str)}>{(totalAgent.length >= 1 ? totalAgent[0].agent_full_name + agentNo : 'No Agents')}</div>
                                    </span>
                                  )
                                }
                              },
                              {
                                title: 'Strategist',
                                dataIndex: 'strategist',
                                key: '5',
                                width: 300,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.exporttotalStrategist.localeCompare(b.exporttotalStrategist),
                                render: (text, str) => {
                                  const totalStrategist = str && str.totalStrategist
                                  let strategistNo = (totalStrategist.length - 1) <= 0 ? '' : " (+" + (totalStrategist.length - 1) + ")"
                                  return (
                                    <span className="cursor__pointer">
                                      <div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenStrategistModal.bind(this, str)}>{(totalStrategist.length >= 1 ? totalStrategist[0].strategist_name + strategistNo : 'No Strategist')}</div>
                                    </span>
                                  )
                                }
                              },
                            ]}
                            dataSource={tableData}
                            pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions }}
                            scroll={{ y: 420 }}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>

        {/* This is for client name modal and copied tag */}
        <ReactModal
          isOpen={this.state.openClient}
          contentLabel="report"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{`Town - ${this.state.agentName}'s` + ` client(s)`}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.onCloseClientModal.bind(this)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="text-right">
                <CopyToClipboard
                  className="btn btn__btn btn-dark btn__150"
                  text={this.state.clientsEmails}
                  onCopy={this.onCopy4.bind(this)}>
                  <button className="copyBoxButton orderchange" >
                    {this.state.selectInEmails ? "Copied" : "Copy"} Emails
                  </button>
                </CopyToClipboard>
              </div>
              <form onSubmit={this.changeTownName}>
                <ul className="emails__list">
                  {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                    return <li key={'client-name-' + index} >{client.client_name} ({client.email})
                      <CopyToClipboard
                        text={client.email}
                        onCopy={this.onCopy2.bind(this, index)}>
                        <i><CopyIcon className="clipcopy-icon" /></i>
                      </CopyToClipboard>
                      {this.state.selectIndex === index ? <span id="showEmail2" style={{ color: '#c7ce4a' }}> Copied</span> : ''}
                    </li>
                  })}
                </ul>
                <div>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>

        {/* This is for Tag modal*/}
        <ReactModal
          isOpen={this.state.open}
          contentLabel="Report"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{'Town - ' + this.state.agentName + `'s` + ' tag(s)'}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.onCloseModal.bind(this)}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="info__block">
                {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                  return <p key={'tag-name-' + index}>{tag}</p>;
                })}
              </div>
              <div>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* This is for Agent names modal*/}
        <ReactModal
          isOpen={this.state.openAgent}
          contentLabel="Report"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{'Town - ' + this.state.agentName + `'s` + ' agent(s)'}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.onCloseAgentsModal.bind(this)}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="info__block">
                {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                  return <p key={'agent-name-' + index}>{agent.agent_full_name}</p>;
                })}
              </div>
              <div>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* This is for Strategist names modal*/}
        <ReactModal
          isOpen={this.state.openStr}
          contentLabel="Report"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{'Town - ' + this.state.agentName + `'s` + ' strategist(s)'}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.onCloseStrategistModal.bind(this)}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="info__block">
                {this.state.currentTownStategist && this.state.currentTownStategist.map((strategist, index) => {
                  return <p key={'strategist-name-' + index}>{strategist.strategist_name}</p>;
                })}
              </div>
              <div>
              </div>
            </div>
          </div>
        </ReactModal>

      </div>
    )
  }
}
