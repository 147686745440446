import Rx from "rxjs/Rx";
import { combineEpics } from "redux-observable";
import * as types from './action-types'
import * as api from './api'

  ////////////////////////////
 /// get push notification ///
////////////////////////////

const getPushNotificationEpic = action$ =>
  action$.ofType(types.GET_NOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getPushNotification(action.payload))
      .map(things => ({
        type: types.GET_NOTIFICATION_SUCCESS,
        payload: things
      }))
      .catch(error =>
        Rx.Observable.of({
          type: types.GET_NOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const markAsReadNotificationEpic = action$ =>
  action$.ofType(types.MARK_ALL_AS_READ).mergeMap(action => {
    return Rx.Observable.fromPromise(api.markAsReadNotification(action.payload))
      .map(things => ({
        type: types.MARK_ALL_AS_READ_SUCCESS,
        payload: things
      }))
      .catch(error =>
        Rx.Observable.of({
          type: types.MARK_ALL_AS_READ_ERROR,
          payload: { error }
        })
      );
  });

export const headerEpic = combineEpics(
  getPushNotificationEpic,
  markAsReadNotificationEpic
);
