import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Spin } from 'antd'
import { map } from 'lodash'
import { SUCCESS } from '../../../../../../constants/phase'
import Loader from '../../../../../../components/Loader'

export default function FollowUpDate({ client, ...props }) {
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    const clientId = props.match.params.id
    setLoading(true)
    props.fetchFollowUpDate({ clientId })
  }, [])

  useEffect(() => {
    if (props.updateFollowUpDatePhase === SUCCESS) {
      const clientId = props.match.params.id
      props.fetchFollowUpDate({ clientId })
      props.clearFollowUpPhase()
    }
    if (props.fetchFollowUpDatePhase === SUCCESS) {
      setLoading(false)
      props.clearFollowUpPhase()
    }
  }, [props.updateFollowUpDatePhase, props.fetchFollowUpDatePhase])

  const handleFollowUpDate = (date, strategistId) => {
    setLoading(true)
    props.updateFollowUpDate({
      clientId: props.match.params.id,
      strategistId,
      date: moment(date).format('YYYY-MM-DD'),
    })
  }

  const disabledDate = (current) => {
    let parts1 = current.format('DD/MM/YYYY').split('/');
    let parts2 = moment().format('DD/MM/YYYY').split('/');
    let latest = true;
    if (parseInt(parts1[2]) > parseInt(parts2[2])) {
      latest = false;
    } else if (parseInt(parts1[2]) == parseInt(parts2[2])) {
      if (parseInt(parts1[1]) > parseInt(parts2[1])) {
        latest = false;
      } else if (parseInt(parts1[1]) == parseInt(parts2[1])) {
        if (parseInt(parts1[0]) >= parseInt(parts2[0])) {
          latest = false;
        } 
      }
    }
    return latest;
  }

  return (
    <Fragment>
      {props.followUpDates && props.followUpDates.length > 0 && (
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
        <ul className="stratgist__follow__list">
          {map(props.followUpDates, (strategist, strategistIndex) => {
            const disabledOtherStrategist = (props.user.role === "strategist" && Number(props.user.id) !== Number(strategist.strategist_id));
            return (
            <li key={`follow_up_date_${strategistIndex}`}>
              <div className="stratgist__name">{strategist.name}</div>
              <div className={`btn__follow-up-date ${disabledOtherStrategist && 'btn__follow-up-date-other'}`}>
                <div className="follow-up-date-label">Follow up date</div>
                <DatePicker
                  className="datepicker__follow-up-date"
                  dropdownClassName="datepicker__overlay__follow-up-date"
                  onChange={(date) => handleFollowUpDate(date, strategist.strategist_id)}
                  value={(strategist && strategist.follow_up_date && moment.utc(strategist.follow_up_date) !== "Invalid date") ? moment.utc(strategist.follow_up_date) : ""}
                  disabledDate={disabledDate}
                  inputReadOnly={true}
                  allowClear={false}
                  placeholder="N/A"
                  format="MM/DD/YYYY"
                  disabled={disabledOtherStrategist ? true : false}
                />
                {/*<DownCaretIcon className="caret-icon" />*/}
              </div>
            </li>
          )})}
        </ul>
        </Spin>
      )}
    </Fragment>
  )
}
