import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/* 
    ///////////////////////////////
  /// prospectus epic ///
//////////////////////////////
*/
// fetch prospectus epic
const fetchProspectusEpic = (action$) =>
  action$.ofType(types.FETCH_PROSPECTUS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchProspectus(action.payload))
      .map((payload) => ({
        type: types.FETCH_PROSPECTUS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_PROSPECTUS_ERROR,
          payload: { error },
        })
      )
  })
// fetch prospectus by id epic
const fetchProspectusByIdEpic = (action$) =>
  action$.ofType(types.FETCH_PROSPECTUS_BY_ID).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchProspectusById(action.payload))
      .map((payload) => ({
        type: types.FETCH_PROSPECTUS_BY_ID_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_PROSPECTUS_BY_ID_ERROR,
          payload: { error },
        })
      )
  })
// create prospectus epic
const createProspectusEpic = (action$) =>
  action$.ofType(types.CREATE_PROSPECTUS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createProspectus(action.payload))
      .map((payload) => ({
        type: types.CREATE_PROSPECTUS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_PROSPECTUS_ERROR,
          payload: { error },
        })
      )
  })

// combine prospectus epic
export const prospectusEpic = combineEpics(
  fetchProspectusEpic,
  fetchProspectusByIdEpic,
  createProspectusEpic
)
