import { fetch } from '../../../../utils'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
    //////////////////////////////
  /// prospectus apis ///
//////////////////////////////
*/
// fetch prospectus list
export const fetchProspectus = (data) => {
  return fetch(`${HOSTNAME_V2}/client/fetch_prospectus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch prospectus details by id
export const fetchProspectusById = (data) => {
    return fetch(`${HOSTNAME_V2}/client/fetch_prospectus_by_id`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((payload) => payload)
      .catch((error) => {
        throw error
      })
  }
// create prospectus details
export const createProspectus = (data) => {
    return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/user-client`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((payload) => payload)
      .catch((error) => {
        throw error
      })
  }
  