import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import {saveAgentAuthtoken} from '../../store/dashboard/duck'
import outlookCallbackForm from './component'

const outlookCallback = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    profilePhase: state.user.profilePhase,
    authAgentTokenPhase:state.dashboard.authAgentTokenPhase,
    resMessage: state.client.resMessage,
    saveAgentAuthtokenData: state.dashboard.saveAgentAuthtokenData
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    saveAgentAuthtoken,
  }
)(outlookCallbackForm)
export default outlookCallback

