import React from 'react'
import { DateRange } from 'react-date-range'
import { CloseIcon } from './icons'
import ReactModal from 'react-modal'

const ModalComponent = ({ datePickerModal, openDatePickerRange, handleSelect, customFirstDate, customEndDate, closeCustomDate, onSubmitDateRanges }) => (
  <ReactModal
    isOpen={datePickerModal}
    onRequestClose={openDatePickerRange}
    contentLabel="Date Picker"
    portalClassName="react-modal"
  >
    <div className="react-modal-dialog react-modal-dialog-sm react-modal-dialog-centered">
       <div className="react-modal-header">
          <h5 className="react-modal-title">Date Picker</h5>
          <button type="button" className="btn react-modal-close" onClick={closeCustomDate}><CloseIcon /></button>
       </div>
      <div className="react-modal-body">
        <div className="date-range-picker">
        <DateRange
            onInit={handleSelect}
            onChange={handleSelect}
            startDate={customFirstDate}
            endDate={customEndDate}
        />
        </div>
        <div className="text-right ">
            <button type="button" className="btn btn__btn btn-dark w__150" onClick={onSubmitDateRanges}>Submit</button>
        </div>
      </div>
    </div>
  </ReactModal>
)

export default ModalComponent