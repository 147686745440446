import { connect } from 'react-redux'

import ClientByStatusComponent from './component'

const ClientByStatusContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(ClientByStatusComponent)

export default ClientByStatusContainer
