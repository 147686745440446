import { connect } from 'react-redux'
import LoginReport from './component'

import { getClientList
       } from '../../../store/dashboard/duck'
import { fetchUser } from '../../../store/user/duck'
import { getLoginRecords } from '../../../store/analytics/duck'

const LoginReportData = connect(
  // Map state to props
  (state) => ({
    clientData: state.dashboard.clientData,
    getLoginRecordsPhase: state.analytics.getLoginRecordsPhase,
    loginRecordsData: state.analytics.loginRecordsData
  }),
  // Map actions to props
  {
  	fetchUser,
  	getClientList,
    getLoginRecords,
  }
)(LoginReport)
export default LoginReportData
