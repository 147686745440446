import { List } from 'immutable'
import { fetch } from '../../utils'
import qs from 'qs'


const HOSTNAME = process.env.API_HOSTNAME
const SITE_URL = process.env.SITE_URL
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
// export const agentGetUsers = (data) => {
//   data = JSON.stringify(data)
//   return fetch(`${HOSTNAME}/users`, {
//     method: 'POST'
//   })
//   .then((res) => {
//     return res.json()
//   })
//   .then((payload) => {
//     return payload
//   }).catch((error) => {
//    throw error
//   })
// }

// export const agentGetUsers = (data) => {
//   return fetch(`${HOSTNAME}/users`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data)
//   })
//   .then((res) => {
//     return res.json()
//   })
//   .then((payload) => {
//     return payload
//   }).catch((error) => {
//    throw error
//   })
// }

export const agentGetUsers = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/users/${data}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const agentGetActiveUsers = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/users/${data}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const agentGetClosedUsers = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/users/${data}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


// Calling API for all agent users for search result in agent dashboard

export const agentSearchGetUsers = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/search_agent_cliets/${data}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAllClients = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/users/all_clients`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


export const getAgentClientNote = (data) => {
  return fetch(`${HOSTNAME}/users/get_agent_client_notes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


export const getAgentAvailability = (data) => {
  return fetch(`${HOSTNAME}/availability/get_agent_availability`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addAgentAppointment = (data) => {
  data.description = data && data.description ? data.description : '' // hot fix for null condition
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/appointments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAppointments = (data) => {
  const qsQueryString = qs.stringify(data)

  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/appointments/?${qsQueryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getClientAppointments = (data) => {

  const queryString = new URLSearchParams({
    timezone: data.timezone
  })

  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/upcoming-appointments?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateClientUser = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/activity-status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const sendAgentNote = (data) => {
  return fetch(`${HOSTNAME}/users/create_client_note`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addAgentDay = (data) => {
  return fetch(`${HOSTNAME}/availability/set_availability`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const createAgentAppointment = (data, schedule_type) => {
  let api = '/client/create_agent_appointment'
  if (schedule_type === "new_schedule") {
    api = '/client/create_agent_appointment'
  } else {
    api = '/appointment/reschedule_agent_appointment_by_client'
  }
  return fetch(`${HOSTNAME}${api}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getClientById = (data) => {
  // data = JSON.stringify(data)
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/${data}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      if (payload && payload.data) {
        return {
          users: payload.data
        }
      } else {
        // window.location = 'agent/dashboard'
      }
    }).catch((error) => {
      throw error
    })
}

export const getAvailability = (data) => {
  // data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/availability/get_availability`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentTown = (data) => {
  return fetch(`${HOSTNAME}/users/get_towns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const agentChatStrategist = (data) => {
  return fetch(`${HOSTNAME}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentChat = (data) => {
  const queryString = new URLSearchParams({
    type: 'agent'
  })

  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/agent-strategist-chat?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const deleteAppointment = (data) => {
  // return new Promise((resolve, reject) => {
  //   resolve({})
  // })
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/appointments/${data.calendar_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const clientNotTouch4W = (data) => {
  return fetch(`${HOSTNAME}/agent/never_touch_in_4W`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const clientNotTouch = (data) => {
  return fetch(`${HOSTNAME}/agent/never_touch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const agentClientFollowUp = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/follow-up-date`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentAllClients = (data) => {
  return fetch(`${HOSTNAME}/agent/get_agent_all_clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const agentScheduleOnClient = (data) => {
  return fetch(`${HOSTNAME}/client/create_agent_appointment_by_admin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const createClientDeal = (data) => {
  const method = data.id ? 'PUT' : 'POST'
  const url = data.id ? `${SUBURBANJUNGLE_API_HOST}/agent/clients/${data.client_id}/deals/${data.id}` :
    `${SUBURBANJUNGLE_API_HOST}/agent/clients/${data.client_id}/deals`

  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentDeal = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/${data.client_id}/deals`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const deleteClientDealByAgent = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/${data.client_id}/deals/${data.deal_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const townResearchData = (data) => {
  return fetch(`${HOSTNAME}/strategy/get_town_research_data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateTownResearch = (data) => {
  return fetch(`${HOSTNAME}/strategy/update_town_research`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const shareClientInfo = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/share-info-with-agent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .catch((error) => {
      throw error
    })
}

export const sendAgentClientSMS = (data) => {
  return fetch(`${HOSTNAME}/sms/send_agent_client_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentClientMessageData = (data) => {
  return fetch(`${HOSTNAME}/sms/get_agent_client_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const removeAgentFromTown = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/agents`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error
    })
}

export const addAgentOnTown = (data) => {
  return fetch(`${HOSTNAME}/client/add_client_agent_on_town`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAgentClientsLastMessage = (data) => {
  return fetch(`${HOSTNAME}/agent/get_agent_client_last_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

// get Agent Time Availability in slots
export const getAgentTimeAvailability = (data) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
  // return fetch(`${HOSTNAME}/agent-avabilibility`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data)
  // })
  //   .then((res) => res.json())
  //   .then((payload) => {
  //     return payload
  //   }).catch((error) => {
  //     throw error
  //   })
}
// get Agent Time Availability in slots
export const updateAgentTimeAvailability = (data) => {
  return fetch(`${HOSTNAME}/set-agent-avabilibility`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


// get authentication data for google
export const getGoogleAuthentication = (data) => {
  return fetch(`${HOSTNAME}/agent-calendar/authenticate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

// get authentication data for google
export const getOutlookAuthentication = () => {
  return fetch(`${HOSTNAME}/agent-calendar/outlook-authenticate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

// get authentication data for google
export const unsyncCalendarEvent = (data) => {
  return fetch(`${HOSTNAME}/unsync_calendar_event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

