import { connect } from 'react-redux'

import TopNevigationMobileComponent from './component'

const TopNevigationMobileContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(TopNevigationMobileComponent)

export default TopNevigationMobileContainer
