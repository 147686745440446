import { connect } from 'react-redux'

import AgentClientChatComponent from './component'

import {
  clearChatPhase,
  clearClientAgents,
  fetchClientAgent,
  getChatHistory,
  fetchClientAgentChat,
} from '../../../../../../store/strategistV2/chat/actions'

const AgentClientChatContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientAgents: state.strategistV2.chat.clientAgents,
    fetchClientAgentListPhase:
      state.strategistV2.chat.fetchClientAgentListPhase,
    fetchClientAgentChatPhase:
      state.strategistV2.chat.fetchClientAgentChatPhase,
    chatHistories: state.strategistV2.chat.chatHistories,
    chatHisotryPhase: state.strategistV2.chat.chatHisotryPhase,
  }),
  // Map actions to dispatch and props
  {
    clearChatPhase,
    clearClientAgents,
    fetchClientAgent,
    getChatHistory,
    fetchClientAgentChat,
  }
)(AgentClientChatComponent)

export default AgentClientChatContainer
