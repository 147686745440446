import { connect } from 'react-redux'
import AppointmentsScheduledForm from './component'
import { getAppointmentsScheduledClientList } from '../../../store/analytics/duck'

const AppointmentsScheduledReporting = connect(
  // Map state to props
  (state) => ({
  	AppointmentScheduledReportPhase: state.analytics.AppointmentScheduledReportPhase,
  	AppointmentScheduledData: state.analytics.AppointmentScheduledData
  }),
  // Map actions to props
  {
  	getAppointmentsScheduledClientList
  }
)(AppointmentsScheduledForm)
export default AppointmentsScheduledReporting
