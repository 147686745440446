import React from 'react'
import { SortDownIcon, SortUpIcon } from "../../../../../../components/icons"
import Cookies from 'universal-cookie'
const cookies = new Cookies()
export default function ClientTableHeader({ sortByType, sortByKey, sortClientData, bizDevCreditFilter, currentLocationFilter, showStrategistColumn, showCurrentLocationColumn }) {

  const user = cookies.get('user')
  const sortData = (key) => sortClientData(key)

  return (
    <thead>
      <tr>
        <th className="cursor__pointer" onClick={() => sortData('clientName')}>
          <span className="table-column-title">Client Name</span>
          <span className="table-column-sorter">
            {(sortByKey === "clientName" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        {showStrategistColumn(user, bizDevCreditFilter) &&
          (
            <th className="cursor__pointer" onClick={() => sortData('strategist_full_name')}>
              <span className="table-column-title">Strategist</span>
              <span className="table-column-sorter">
                {(sortByKey === "strategist_full_name" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </span>
            </th>
          )
        }
        {showCurrentLocationColumn(currentLocationFilter) &&
          (
            <th className="cursor__pointer">
              <span className="table-column-title">Current Location</span>
              <span className="table-column-sorter">
                {(sortByKey === "current_location" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </span>
            </th>            
          )
        }
        <th className="cursor__pointer" onClick={() => sortData('priceRange')}>
          <span className="table-column-title">Budget</span>
          <span className="table-column-sorter">
            {(sortByKey === "priceRange" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        <th className="cursor__pointer" onClick={() => sortData('pipelineStage')}>
          <span className="table-column-title">Status</span>
          <span className="table-column-sorter">
            {(sortByKey === "pipelineStage" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        <th className="cursor__pointer" onClick={() => sortData('created')}>
          <span className="table-column-title">Created Date</span>
          <span className="table-column-sorter">
            {(sortByKey === "created" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        <th className="cursor__pointer" onClick={() => sortData('lastActivity')}>
          <span className="table-column-title">Follow Up</span>
          <span className="table-column-sorter">
            {(sortByKey === "lastActivity" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
          </span>

        </th>
      </tr>
    </thead>
  )
}
