import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../../store/user/duck'
import {analyticsSubmissions} from '../../../store/analytics/duck'
import {
  getFilterParams,
  clearPhase
 } from '../../../store/dashboard/duck'
import AdminDashboardForm from './component'
const AnalyticsSubmissions = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    filterParams: state.dashboard.filterParams,
    dashSubmissionsData: state.analytics.dashSubmissionsData,
    rxError: state.user.error,
    phase: state.user.phase,
    dashclientPhase: state.analytics.dashclientPhase
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getFilterParams,
    analyticsSubmissions
    
  }
)(AdminDashboardForm)
export default AnalyticsSubmissions
