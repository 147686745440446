import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import { map, size, get } from 'lodash'
import Cookies from 'universal-cookie'
import DatePicker from '../../../../../../components/datePicker'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Loader from '../../../../../../components/Loader'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class StrategistTimesheetComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment(),
      endDate: moment(),
      strategistTimesheet: [],
      totalDuration: '',
      strategist_id: 0,
      strategist_name:'',
      strName:'',
      showAll:true,
      isLoading: false,
      datePickerModel: false,
      //selectedIndex: 'today'
      selectedIndex:'all'
    }
  }

  componentDidMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
   // if(_user) {
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'total'
        strData.limit = 100
        strData.strategist_id=0;
      }
    //   else{
    //   strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
    //   strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
    //   strData.selectedValue = 'any'
    //   strData.limit = 100
    //     //When only admin or strategist so use this case
    //   if(_user.role === 'admin' || _user.role === 'strategist' ){
    //     strData.strategist_id = _user.id
    //   }
    // }
      this.props.getStrategistTimesheet(strData)
      this.setState({isLoading: true, strategist_id:0,fromDate:strData.from,endDate:strData.to})
      this.props.getFilterParams()
   // }
  
}

  componentDidUpdate(preProps, preState){
    const { strategistTimePhase, strategistTimeData, clearReportV2Phase, clearFilterPhase, filterParamsPhase, filterParams } = this.props
    if(strategistTimePhase === 'success'){
      clearReportV2Phase()
      let timeSheet = []
      let totalDuration = ''
      let totalHours = 0
      let totalMinutes = 0
      let totalSeconds = 0
      let sheetData = strategistTimeData && strategistTimeData.data
      if(size(sheetData) > 0){
        sheetData && sheetData.map((data, i)=>{
          if(data.end_date && data.end_date !== null){
            var a = moment(data.start_date)
            var b = moment(data.end_date)
            var diff = Math.abs(a - b) / 1000;
            // get hours
            var hours = Math.floor(diff / 60 / 60);
            // get minutes
            var minutes = Math.floor(diff / 60) - (hours * 60);
            // get seconds
            var seconds = diff % 60;
            totalHours = totalHours+hours
            totalMinutes = totalMinutes+minutes
            totalSeconds = totalSeconds+seconds
            if(totalMinutes >= 60){
              var tempHours = Math.floor(totalMinutes / 60)
              totalHours = totalHours+tempHours
              totalMinutes = totalMinutes % 60
            }

            if(totalSeconds >= 60){
              var tempSeconds = Math.floor(totalSeconds / 60)
              totalMinutes = totalMinutes+tempSeconds
              totalSeconds = totalSeconds % 60
            }

            var duration = hours !== 0 ? hours+' hr '+minutes+ ' min ' +seconds+ ' sec': minutes+' min ' +seconds+ ' sec'
            var totalTime = totalHours !== 0 ? totalHours+' hr '+totalMinutes+ ' min '+totalSeconds+' sec ' : totalMinutes+' min '+totalSeconds+ ' sec '
            totalDuration = totalTime
          }else{
            var duration = 'process'
          }
          timeSheet.push({
            client_full_name: data.client_full_name ? data.client_full_name : "N/A",
            strategist_full_name: data.strategist_full_name,
            start_date: moment(data.start_date).format('L'),
            start_time: moment.utc(data.start_time).tz(_user.time_zone).format('h:mma z'),
            end_date: data.end_date,
            end_time: data.end_time && data.end_time !== null ? moment.utc(data.end_time).tz(_user.time_zone).format('h:mma z') : 'process',
            activity_log: ( (data.client_full_name === "N/A") ? (data.activity_log) : ((data.activity_log === 'client_dashboard') ? 'Activity on client account' : 'phone call: '+data.activity_log)),
            status: data.status,
            duration: duration,
            type: data.type
          })
        })
      }
      this.setState({ strategistTimesheet: timeSheet, isLoading: false })
    }
    if(strategistTimePhase === 'error'){
      clearReportV2Phase()
      this.setState({ strategistTimesheet: [], isLoading: false })
    }
    if(filterParamsPhase === "success"){
      // clearFilterPhase()
      // let strList = filterParams && filterParams.data && filterParams.data.strategists
      //   if(_user && _user.role === 'superadmin'){
      //     let strData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
      //     if(strData){
      //       this.setState({ strategist_id: strData.id, strategist_name: strData.strategist_name })
      //       const data = {
      //         from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      //         to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
      //         selectedValue : 'any',
      //         strategist_id : parseInt(strData.id)
      //       }
      //       this.props.getStrategistTimesheet(data)
      //     }
      //   }
    }
    if(filterParamsPhase === "error"){
      clearFilterPhase()
    }
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      from : moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to   : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      selectedValue: 'any',
      strategist_id: parseInt(this.state.strategist_id)
    }
    this.props.getStrategistTimesheet(strData);
    this.props.getFilterParams()
  }

  handleChange(event) {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
   
    this.setState({ strategist_id: event.target.value, isLoading: true },()=>{
      const strData={}
      // if(this.state.strategist_id!=){
      //   strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      //   strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      //   strData.selectedValue = 'total'

      // }else{
         strData.from=moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
         strData.to= moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
         strData.selectedValue='total'
      // }
      //  strData = {
      //   from : moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      //   to : moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss"),
      //   selectedValue : 'any',
      //   strategist_id : parseInt(this.state.strategist_id)
      // }
     strData.strategist_id = parseInt(this.state.strategist_id)
      this.props.getStrategistTimesheet(strData)
    })
  }


  changeLimit = limit => {
    // On limit change
  }
  
  render() {
    const { strategistTimesheet, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    const { filterParams } = this.props
    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={get(page, 'value')}>
                                {get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Strategist Timesheets</h2>
                      <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={strategistTimesheet} name="strategist_timesheet">
                          <ExcelColumn label="Strategist Name" value="strategist_full_name"/>
                          <ExcelColumn label="Client Name" value="client_full_name"/>
                          <ExcelColumn label="Date" value="start_date"/>
                          <ExcelColumn label="Start Time" value="start_time"/>
                          <ExcelColumn label="End Time" value="end_time"/>
                          <ExcelColumn label="Total Duration" value="duration"/>
                          <ExcelColumn label="Activity Type" value="activity_log"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-md-offset-4">
                {
                 (_user && _user.role === "superadmin") &&
                 <div className="form-group material-textfield">
                    <select
                      className="form-control custom__select material-textfield-input"
                      name="stategist_id"
                      value={this.state.strategist_id}
                      onChange={this.handleChange.bind(this)}>
                      <option value={0}>All</option>
                      {strategistList}
                    </select>
                    <label className="material-textfield-label">Strategist</label>
                  </div>
                }
                </div>
                <div className="col-md-5">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (size(strategistTimesheet) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Strategist Name',
                              width: 260,
                              dataIndex: 'strategist_full_name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.strategist_full_name.localeCompare(b.strategist_full_name)
                            },
                            {
                              title: 'Client Name',
                              width: 260,
                              dataIndex: 'client_full_name',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name)
                            },
                            {
                              title: 'Date',
                              dataIndex: 'start_date',
                              key: '3',
                              width: 290,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.start_date.localeCompare(b.start_date)
                            },
                            {
                              title: 'Start Time',
                              dataIndex: 'start_time',
                              key: '4',
                              width: 230,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.start_time.localeCompare(b.start_time)
                            },
                            {
                              title: 'End Time',
                              dataIndex: 'end_time',
                              key: '5',
                              width: 230,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.end_time.localeCompare(b.end_time)
                            },
                            {
                              title: 'Total Duration',
                              dataIndex: 'duration',
                              key: '6',
                              width: 210,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.duration.localeCompare(b.duration)
                            },
                            {
                              title: 'Activity Type',
                              dataIndex: 'address',
                              key: '7',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.activity_log.localeCompare(b.activity_log),
                              width: 400,
                              render: (text, str) => {
                                let activity = str.activity_log
                                if(str.type === 'ios'){
                                  activity = 'iOS app: '+ str.activity_log
                                }
                                return(
                                  <span>{activity}</span>
                                )
                              }
                            },
                          ]}
                          dataSource={strategistTimesheet}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
