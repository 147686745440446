import { connect } from 'react-redux'

import ClientInformationComponent from './component'
const ClientInformationContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    fetchClientByIdPhase: state.strategistV2.client.fetchClientByIdPhase,
  }),
  // Map actions to dispatch and props
  {}
)(ClientInformationComponent)

export default ClientInformationContainer
