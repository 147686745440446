import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import { NavbarIcon, SearchIcon, CloseIcon } from '../icons'
import './styles.scss'

export default class AdministrationTopNavigationMobileComponent extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }

  onClose = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <Fragment>
        <div className="topnavigation__mobile">
          <div className="topnavigation__mobile__left">
            <div className="topnavigation__mobile__logo">
              <img className="" src="../img/img_v2/logo-mobile.png"/>
            </div>
          </div>
          <div className="topnavigation__mobile__right">
            <div className="topnavigation__mobile__inner">
              <button className="btn btn__search"><SearchIcon/></button>
              <button onClick={this.showDrawer} className="btn btn__navbar"><NavbarIcon/></button>
            </div>
          </div>
        </div>
        <div className="modal-search-box" id="search-box">
          <div className="d-flex align-items-center">
            <input
              className="form-control modal-search-box-input"
              placeholder="Search Clients"
            />
              <button className="btn modal-search-box-button"><CloseIcon/></button>
          </div>
          <div className="search-wrapper-open-container">
            <ul className="search-wrapper-options-menu">
              <li className="search-wrapper-options-menu-item">
                <Link to={`/`}>
                  <div className="options-menu-item-title">
                  <span className="avatar">
                    {/* <img src={search.avatar}/> */}
                    <img src="../img/img_v2/user-icon.png"/>
                  </span>
                  <span className="name">
                    Aubrey Graham
                  </span>
                     <div className="email">admin@gmail.com</div>
                     <div className="phone">101.1010.1001</div>)
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Side drawer */}
        <Drawer
          width="320"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ul className="mob__nav__list">
            <li className="active">Dashboard</li>
            <li>Clients</li>
            <li>Task List</li>
            <li>Your Agents</li>
            {/* <li>Town Cheat Sheet</li> */}
            <li>Agent Appointments </li>
            <li>Towns</li>
            <li>Jungler Users</li>
            <li>Edit Profile</li>
            <li>Logout</li>
          </ul>
        </Drawer>
      </Fragment>
    )
  }
}
