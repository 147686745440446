import React from 'react'
import { SortUpIcon, SortDownIcon } from '../../../../../components/icons'

export default function JunglerUserTableHeader({ sortByKey, sortByType, sortData }) {
  const sortJunglarUserData = (sortByKey) => sortData(sortByKey)

  return (
    <thead>
      <tr>
        <th className="cursor__pointer" onClick={() => sortJunglarUserData('userName')}>
          <span className="table-column-title">Name</span>
          <span className="table-column-sorter">
            {sortByKey === 'userName' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        <th className="cursor__pointer" onClick={() => sortJunglarUserData('role')}>
          <span className="table-column-title">Role</span>
          <span className="table-column-sorter">
            {sortByKey === 'role' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        <th className="cursor__pointer" onClick={() => sortJunglarUserData('email')}>
          <span className="table-column-title">Email</span>
          <span className="table-column-sorter">
            {sortByKey === 'email' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
        {/* <th className="cursor__pointer" onClick={() => sortJunglarUserData('phone')}>
          <span className="table-column-title">Phone</span>
          <span className="table-column-sorter">
            {sortByKey === 'phone' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th> */}
        <th className="cursor__pointer" colSpan="2" onClick={() => sortJunglarUserData('created')}>
          <span className="table-column-title">Created Date</span>
          <span className="table-column-sorter">
            {sortByKey === 'created' && sortByType === 0 ? <SortDownIcon /> : <SortUpIcon />}
          </span>
        </th>
      </tr>
    </thead>
  )
}
