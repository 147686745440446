import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import config from '.././../../../config'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
const cookies = new Cookies()
class UpcomingAppintments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      client_status:'all_appointments',
      client_type:'upcoming_appointments',
      totalPages:0,
      activePage:1,
      client_limit:100,
      client_offset:1,
      isLoadingData: false,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      sortByKey: 'upcomingAppointment',
      sortByType : 0,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handelUserfilter = this.handelUserfilter.bind(this)
    }
  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
      
    }
  }

  componentDidMount(){
    document.title = "Jungler: Upcoming Appointments";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handelUserfilter(btn) {
    this.setState({ isLoadingData: true})
    $('.filter__container--button').removeClass('active');
    if(btn.target.name !==''){
      $( 'button[ name='+btn.target.name+']' ).addClass( 'active' );
      
      switch(btn.target.name){
        case 'my_appointments':
            this.setState({client_status:'my_appointments'});
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.my_appointments)})
            }
            break;
        default:
          this.setState({client_status:'all_appointments'});
          if(this.props.clientData && this.props.clientData.user_counts){
            this.setState({totalPages:Math.round(this.props.clientData.user_counts.all_appointments)})
          }
      }
    }else{
      this.setState({client_status:'all_appointments'})
    }
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = 1;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)

    }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handleEntryChange(event){
    this.setState({client_limit: event.target.value, activePage:1});
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit =  this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  componentWillReceiveProps(nextProps){
    this.setState({ isLoadingData: true})
    if(nextProps.clientData && nextProps.clientData.user_counts){
      if(this.state.client_status ==='all_appointments'){
      this.setState({totalPages:Math.round(nextProps.clientData.user_counts.all_appointments)})
      }
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false})
    }
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const clientdata = {}
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_limit =  this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = {key: val, type: sortByType};
    this.props.homeFilterClients(clientdata)
  }

  render() {
    const {user, clientData} = this.props;
    let allAppointments = clientData && clientData.user_counts ? clientData.user_counts.all_appointments : 0;
    let myAppointments = clientData && clientData.user_counts ? clientData.user_counts.my_appointments : 0;
    return (
    <div>
      <main className="upcoming__appointments--section">
        <div className="filter__container desktopFilter" style={{display: 'block'}}>
          <div className="filter__container--filter-name rem-flex">
            <div className="col-md-9 overFlow__filter p_l--rem">
              {user && user.role != 'strategist' ?
              <button name="all_appointments" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>Scheduled Appointments between Client & Agent ({allAppointments})</button>
              :''}
              {user && user.role != 'strategist' ?
              <a className="btn filter__container--button" href="https://secure.acuityscheduling.com/appointments.php" target="_blank">My Appointments</a>
              :''}
            </div>
            <div className="col-md-3 newfilter-list text-right">
            <div>
            <span className="enteries-span">
            <label className="enteries-label">Entries</label>
              <select value={this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
              {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                   })}
              </select>
            </span>  
            </div>
            </div>
          
          </div>
        </div>

        <div className="mobileFilter filter__container" style={{display:'none'}}>
        {user && user.role != 'strategist' ?
          <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
          Filter <img src="./img/menu-dropdown.png" style={{ float:'right', paddingTop: '5px', width: '14px' }} /> 
          </a>
          <ul className="dropdown-menu mobile-dropdown">
            <li><button name="all_appointments" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>Scheduled Appointments between Client & Agent ({allAppointments})</button></li>
            <li><a className="btn filter__container--button active" href="https://secure.acuityscheduling.com/appointments.php" target="_blank">My Appointments</a></li>
          </ul>
          </div>
          : ''}
          <div className="col-md-2 newfilter-list text-right filter__container--filter-name rem-flex">
            <div>
            <span className="enteries-span">
            <label className="enteries-label">Entries</label>
              <select value={this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
              {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                   })}
              </select>
            </span>  
            </div>
            </div>
        </div>


         <div className="upcoming__appointments--table-list useslist__extra">
             <div className="table-responsive table__responsive--mob">
               <table className="table table-striped">
                  <thead className="table__td--desktop">
                    <tr>
                      <th onClick={this.sortData.bind(this , 'clientName')}>Client Name</th>
                      <th onClick={this.sortData.bind(this , 'strategist')}>Strategist's Name</th>
                      <th>Agent Name</th>
                      <th onClick={this.sortData.bind(this , 'typeOfAppointment')}>Type of Appointment</th>
                      <th onClick={this.sortData.bind(this , 'upcomingAppointment')}>Appointment Date & Time </th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.state.isLoadingData === true ? 
                  <tbody>
                  <tr>
                    <td colSpan="5" className="noClientResults text-center">
                    <img src="../../img/loader2.svg" />
                    </td>
                    </tr>

                  </tbody>
                  :
                  <tbody>
                  {
                  clientData && clientData.users && clientData.users.length > 0 ?
                    clientData.users.map((c,index) => {
                    let appointmentType = (c.appointment_type === 'Follow up agent town tour' ? 'Follow up agent visit' : c.appointment_type)
                    return(
                    <tr key={index}>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.client_full_name}</td>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>
                      {
                        c.strategists && c.strategists.map((strategist, idx)=>{
                          return(
                            <span key={idx}>{ (idx ? ', ' : '') + strategist.strategist_full_name }</span>
                          )
                        })
                      }
                      </td>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.strategist_full_name}</td>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{appointmentType}</td>
                      <td className="table__td--desktop">{moment(c.start_date).format('MM/DD/YY hh:mm a')}</td>
                      <td className="table__td--desktop">
                        {/* <button onClick={() => { this.props.history.push({ pathname: '/calendar', state: c})}} className="btn btn__black--outline btn__calandar">View on Calendar</button> */}
                        <button className="btn btn__green--outline btn__profile" onClick={() => { this.props.history.push({ pathname: `/activity/${c.client_id}`, state: c})}}>View Client Profile</button>
                      </td>

                      <td className="onlymob" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>
                        <span className="table__fullname">{c.client_full_name}</span>
                        <span className="table__fulltime">{moment(c.start_date).format('MM/DD/YY hh:mm a')}</span>
                        <span className="table__both">{c.appointment_type}{c.strategist_full_name}</span>
                      </td>

    
                     {/* <td colSpan="3" className="mobile__agent--app" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>
                     {c.appointment_type}{c.strategist_full_name}
                     </td> */}


                      {/* <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.strategist_full_name}</td>
                      <td className="table__td--desktop" onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>{c.appointment_type}</td> */}
                   
                    </tr>
                      )
                    }) : 
                    <tr><td colSpan="6" className="noClientResults">No clients found for this criteria. Please broaden or change your search criteria and filters</td></tr> 
                    }  
                  </tbody>
                  }
              </table>
             </div>
         </div>
      </main>
      <div className="pagination-container">
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.client_limit}
          totalItemsCount={this.state.totalPages}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
     </div>
    </div>
    )
  }
}

export default reduxForm({
  form: 'upcomingappintments',  // a unique identifier for this form
  destroyOnUnmount: true,
})(UpcomingAppintments)
