/* eslint-disable no-nested-ternary */
import React, { PureComponent, Fragment } from 'react'
import { AttachmentIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import moment from 'moment'
import _ from 'lodash'
import io from 'socket.io-client'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import axios from 'axios'
import ReactModal from 'react-modal'
import { logoutInvalidRequest } from '../../../../../../utils/helper'
import {fetch} from "../../../../../../utils";

const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
const SOCKET_HOSTNAME = process.env.SUBURBANJUNGLE_API_HOST
let socket = io.connect(SOCKET_HOSTNAME, {
  transports: ['websocket', 'polling']
})
const cookies = new Cookies()
export default class ClientChatComponent extends PureComponent {
  editableRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      chatData: [],
      chat_text: '',
      photoIndex: 0,
      openImageModel: false,
      client_full_name: '',
      loginUserId: 0,
      chatId: 0,
      deleteMsgModal: false,
      clientId: 0,
      viewMore: false,
      editChat: '',
      editable: false
    }
  }

  componentDidMount() {
    const { match: { params: { id } }, clientData } = this.props
    const loginUser = cookies.get('user')
    if (loginUser) {
      this.setState({ loginUserId: loginUser.id, clientId: parseInt(id) })
      const data = {
        sender_id: parseInt(loginUser.id),
        receiver_id: parseInt(id)
      }
      this.props.getChatHistory(data)
    }

    //agent and client chat getting data
    socket.on('new_message', (data) => {
      if (data && ((this.state.loginUserId === data.sender_id && parseInt(id) === data.receiver_id) || (this.state.loginUserId === data.receiver_id && parseInt(id) === data.sender_id))) {
        this.setState({ chat_text: '' })
        var chat = this.state.chatData
        chat.push(data)
        this.setState({ chatData: _.cloneDeep(chat) })
        this.forceUpdate()
      }
    })
  }

  getClientAgentData() {
    const { match: { params: { id } } } = this.props
    this.props.getChatHistory({ sender_id: this.state.loginUserId, receiver_id: parseInt(id) })
  }

  componentDidUpdate(preProps) {
    if (this.props.deletePhase === "success") {
      this.props.getChatHistory({ sender_id: this.state.loginUserId, receiver_id: this.state.clientId })
      this.props.clearAgentPhase()
    }
    if (this.props.editClientPhase === "success") {
      this.setState({ chatId: 0, editable: false, editChat: '' })
      this.props.getChatHistory({ sender_id: this.state.loginUserId, receiver_id: this.state.clientId })
      this.props.clearAgentPhase()
    }

    if (this.props.clientData && this.props.clientData.users) {
      const client_full_name = _.get(this.props.clientData.users, 'first_name', '') + ' ' + _.get(this.props.clientData.users, 'last_name', '')
      if (client_full_name !== this.state.client_full_name) {
        this.setState({ client_full_name })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.chatData !== _.get(props.chatHistoryData, 'data', [])) {
      return { chatData: _.get(props.chatHistoryData, 'data', []) }
    }
    return null;
  }

  imageOpen(e) {
    this.setState({ openImageModel: !this.state.openImageModel })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFileUpload(e) {
    const { match: { params: { id } } } = this.props
    let fileType = "image"
    var _URL = window.URL || window.webkitURL
    let files = e.target.files[0]
    let fileName = files.name

    var ext = fileName.match(/\.([^\.]+)$/)[1]
    if (files.type === 'application/pdf') {
      fileType = "pdf"
    } else {
      fileType = "image"
    }
    if (ext === 'xlsx' || ext === 'xls' || ext === 'csv' || ext === 'xltx' || ext === 'xlt' || ext === 'xlsb' || ext === 'xlsm' || ext === 'xltm') {
      fileType = "excel"
    }
    var img = new Image()
    let self = this
    let reader = new FileReader()
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files)
      img.onload = function () {
        self.setState({ file: files })
        self.setState({ avatar: reader.result })
      }
    }
    /*upload file*/
    const formdata = new FormData()
    formdata.append('image', files)
    formdata.append('client_id', id)
    fetch(`${SUBURBANJUNGLE_API_HOST}/agent-client/chat-attachment`, {
      method: 'POST',
      body: formdata
    })
      .then(res => res.json())
      .then(res => {
      if (res && res.data && res.status) {
        const receiver_id = id
        if (receiver_id != '' && this.state.loginUserId != '') {
          socket.emit('new_message', {
            sender_id: this.state.loginUserId,
            receiver_id: receiver_id,
            sender_role: "agent",
            type: fileType,
            file_name: fileName,
            content: res.data
          })
          this.setState({ chat_text: '' })
          // this.getClientAgentData()
        }
      }
    }).catch(logoutInvalidRequest)
    var read = reader.readAsDataURL(files)
  }

  sendChatMessage() {
    const { match: { params: { id } }, clientData } = this.props
    if (this.state.chat_text !== '' && id != '' && this.state.sender_id != '') {
      socket.emit('new_message', {
        sender_id: this.state.loginUserId,
        receiver_id: parseInt(id),
        sender_role: "agent",
        type: "text",
        content: this.state.chat_text
      })
      if (this.editableRef) {
        this.editableRef.style.height = `130px`
      }
      this.setState({chat_text: ''})
    }
  }

  handleKeyDown=(e)=> {
    e.persist()
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  handleKeyUp = (e) => {
    e.persist()
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  editChat(e, data) {
    this.setState({ editChat: data.content, chatId: data.chat_id, type: "edit", editable: true })
  }

  deleteChat(e, data) {
    this.setState({ deleteMsgModal: true, chatId: data.chat_id })
  }

  closeModal = () => {
    this.setState({ deleteMsgModal: false, chatId: 0 })
  }

  dltMsgHandle() {
    this.props.deleteChatData({ chatId: this.state.chatId })
    this.setState({ deleteMsgModal: false })
  }

  viewMoreButton(e) {
    this.setState({ viewMore: !this.state.viewMore })
  }

  closeIconClick(e) {
    this.setState({ editChat: '', chatId: 0, editable: false })
  }

  editSave() {
    if (this.state.editChat !== '') {
      this.props.editClientChat({ chatId: this.state.chatId, content: this.state.editChat })
    }
  }

  render() {
    const { chatData, openImageModel, photoIndex, loginUserId } = this.state
    const { clientData } = this.props
    return (
      <div>
        <div className="chats__sectiion">
          <div className="chats__sectiion__header">
            <h2 className="chats__sectiion__title">Chat between you and {this.state.client_full_name}</h2>
            <p className="chats__sectiion__subtitle">This is a chat between you and the client. The client will get an email alert after you send your message.</p>
          </div>
          <div className="chats__sectiion__wrapper">
            <textarea
              className="chats__sectiion__input"
              rows="4"
              name="chat_text"
              onKeyDown={this.handleKeyDown}
              style={{ resize: "none", overflowY: "hidden" }}
              onKeyUp={this.handleKeyUp}
              ref={(current) => { this.editableRef = current }}
              value={this.state.chat_text}
              onChange={this.handleChange.bind(this)}
              placeholder={`Send a message to ${this.state.client_full_name}...`}>
            </textarea>
            <div className="chats__section__cta">
              <button onClick={() => this.sendChatMessage()} disabled={(this.state.chat_text !== '' && (this.state.chat_text).trim() !== '') ? false : true} type="button" className="btn btn__btn btn-dark btn__send">Send</button>
              {/* <button type="button" className="btn btn__btn btn-link btn__attachment"><AttachmentIcon /></button> */}

              <label htmlFor="file-input">
                <AttachmentIcon />
              </label>

            </div>

            <input
              style={{ display: "none" }}
              onChange={this.handleFileUpload.bind(this)}
              accept="application/pdf, image/jpg,image/hief,image/jpeg,image/png, image/svg, image/gif, .xlsx, .xls, .csv"
              id="file-input" type="file" />
          </div>

          {
            (_.size(chatData) > 0) &&
            _.map(_.orderBy(chatData, ['modified'], ['desc']), (chat, ind) => {
              const values = (chat.sender_full_name).split(" ");
              const firstName = _.upperCase(values[0] ? values[0].charAt(0) : '');
              const lastName = values[1] ? _.upperCase(((chat.sender_full_name).substr((chat.sender_full_name).indexOf(' ') + 1)).charAt(0)) : '';
              return (
                <div key={ind} className="chats__sectiion__inner">
                  {
                    (this.state.viewMore) ?
                      (chat.content !== '' && (chat.content).trim() !== '') &&
                      <ul className="chats__sectiion__chats__list">
                        <li className="has__avatar">
                          <div className="chats__card__header">
                            <div className="chats__card__avatar">
                              {
                                (_.isEmpty(chat.sender_avatar)) ?
                                  <span className="string__avatar">{`${firstName}${lastName}`}</span>
                                  :
                                  <img src={chat.sender_avatar} />
                              }
                            </div>
                            <span className="chats__card__name">{chat.sender_full_name}</span><span className="chats__card__time">
                              {moment(chat.modified).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm a')}
                            </span>
                          </div>
                          {
                            chat.chat_type === 'image' ?
                              <div className="chats__card">
                                <img className="clickable__image" onClick={() => this.setState({ photoIndex: ind, openImageModel: true })} src={chat.content} />
                                {openImageModel && (
                                  <Lightbox
                                    mainSrc={chatData.map(c => c.content)[photoIndex]}
                                    onCloseRequest={this.imageOpen.bind(this)}
                                    onMovePrevRequest={() =>
                                      this.setState({
                                        photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                      })
                                    }
                                    onMoveNextRequest={() =>
                                      this.setState({
                                        photoIndex: (photoIndex + 1) % [chat.content].length,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              :
                              (chat.chat_type === "text" || chat.chat_type === "automated_welcome_message") ?
                              <Fragment>
                                {
                                  !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                  <div className="chats__card">{chat.content}</div>
                                }
                                {
                                  ((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                  <div className="chats__card__edit">
                                    <textarea
                                        className="chats__card__textarea__edit"
                                        type="text"
                                        name="editChat"
                                        ref={(current) => { if (current) current.focus() }}
                                        onKeyDown={this.handleKeyDown}
                                        style={{ resize: "none", overflowY: "hidden" }}
                                        onKeyUp={this.handleKeyUp}
                                        value={this.state.editChat}
                                        onFocus={this.handleKeyUp}
                                        onChange={this.handleChange.bind(this)}
                                        placeholder=""
                                        rows="4">
                                      </textarea>
                                      <button onClick={this.editSave.bind(this)} disabled={(this.state.editChat !== '' && (this.state.editChat).trim() !== "" ) ? false : true} className="btn btn__btn btn__xs btn-dark mr__15">Save</button>
                                      <span onClick={this.closeIconClick.bind(this)}><CloseIcon className="chats__card__edit__close" /></span>
                                    </div>
                                  }
                                </Fragment>
                                :
                                (chat.chat_type === 'pdf') ?
                                  <div className="chats__card">
                                    <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png" />{(chat.file_name)}</div></a>
                                  </div>
                                  :
                                  (chat.chat_type === 'excel') &&
                                  <div className="chats__card">
                                    <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png" />{(chat.file_name)}</div></a>
                                  </div>
                          }
                          {
                            (loginUserId === chat.sender_id && chat.chat_type === "text") ?
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__cta">
                                <button onClick={() => this.editChat(this, chat)} className="btn btn__edit_chat">Edit</button>
                                <button onClick={() => this.deleteChat(this, chat)} className="btn btn__delete_chat">Delete</button>
                              </div>
                              :
                              ((loginUserId === chat.sender_id) && chat.chat_type !== "text") ?
                                !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                <div className="chats__card__cta">
                                  <button onClick={() => this.deleteChat(this, chat)} className="btn btn__delete_chat">Delete</button>
                                </div>
                                :
                                <div className="chats__card__cta" />
                          }
                        </li>
                      </ul>
                    :
                     (ind < 10) &&
                     (chat.content !== '' && (chat.content).trim() !== '') &&
                        <ul className="chats__sectiion__chats__list">
                        <li className="has__avatar">
                          <div className="chats__card__header">
                            <div className="chats__card__avatar">
                              {
                                (_.isEmpty(chat.sender_avatar)) ?
                                  <span className="string__avatar">{`${firstName}${lastName}`}</span>
                                  :
                                  <img src={chat.sender_avatar} />
                              }
                            </div>
                            <span className="chats__card__name">{chat.sender_full_name}</span>
                            <span className="chats__card__time">
                            {moment(chat.modified).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm a')}
                            </span>
                          </div>
                          {
                            chat.chat_type === 'image' ?
                              <div className="chats__card">
                                <img className="clickable__image" onClick={() => this.setState({ photoIndex: ind, openImageModel: true })} src={chat.content} />
                                {openImageModel && (
                                  <Lightbox
                                    mainSrc={chatData.map(c => c.content)[photoIndex]}
                                    onCloseRequest={this.imageOpen.bind(this)}
                                    onMovePrevRequest={() =>
                                      this.setState({
                                        photoIndex: (photoIndex + [chat.content].length - 1) % [chat.content].length,
                                      })
                                    }
                                    onMoveNextRequest={() =>
                                      this.setState({
                                        photoIndex: (photoIndex + 1) % [chat.content].length,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              :
                              (chat.chat_type === "text" || chat.chat_type === "automated_welcome_message") ?
                              <Fragment>
                                {
                                  !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                  <div className="chats__card">{chat.content}</div>
                                }
                                {
                                  ((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                  <div className="chats__card__edit">
                                    <textarea
                                        className="chats__card__textarea__edit"
                                        type="text"
                                        name="editChat"
                                        ref={(current) => { if (current) current.focus() }}
                                        onKeyDown={this.handleKeyDown}
                                        style={{ resize: "none", overflowY: "hidden" }}
                                        onKeyUp={this.handleKeyUp}
                                        value={this.state.editChat}
                                        onFocus={this.handleKeyUp}
                                        onChange={this.handleChange.bind(this)}
                                        placeholder=""
                                        rows="4">
                                      </textarea>
                                      <button onClick={this.editSave.bind(this)} disabled={(this.state.editChat !== '' && (this.state.editChat).trim() !== "" ) ? false : true} className="btn btn__btn btn__xs btn-dark mr__15">Save</button>
                                      <span onClick={this.closeIconClick.bind(this)}><CloseIcon className="chats__card__edit__close" /></span>
                                    </div>
                                  }
                                </Fragment>
                                :
                                (chat.chat_type === 'pdf') ?
                                  <div className="chats__card">
                                    <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/pdf_icon.png" />{(chat.file_name)}</div></a>
                                  </div>
                                  :
                                  (chat.chat_type === 'excel') &&
                                  <div className="chats__card">
                                    <a className="pdf_file" href={chat.content} download={chat.content} target="_blank"><div><img src="../../img/excel_icon.png" />{(chat.file_name)}</div></a>
                                  </div>
                          }
                          {
                            (loginUserId === chat.sender_id && chat.chat_type === "text") ?
                              !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                              <div className="chats__card__cta">
                                <button onClick={() => this.editChat(this, chat)} className="btn btn__edit_chat">Edit</button>
                                <button onClick={() => this.deleteChat(this, chat)} className="btn btn__delete_chat">Delete</button>
                              </div>
                              :
                              ((loginUserId === chat.sender_id) && chat.chat_type !== "text") ?
                                !((this.state.chatId === chat.chat_id) && this.state.editable) &&
                                <div className="chats__card__cta">
                                  <button onClick={() => this.deleteChat(this, chat)} className="btn btn__delete_chat">Delete</button>
                                </div>
                                :
                                <div className="chats__card__cta" />
                          }
                        </li>
                      </ul>
                  }
                </div>
              )
            })
          }
          {
            (_.size(chatData) > 5) &&
            <div className="chats__sectiion__inner">
              <ul className="chats__sectiion__chats__list">
                <button onClick={() => this.viewMoreButton(this)} className="btn btn__view_more_chat">{this.state.viewMore ? "View Less" : "View More"}</button>
              </ul>
            </div>
          }
        </div>

        <ReactModal
          isOpen={this.state.deleteMsgModal}
          onRequestClose={this.closeModal}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button type="button" className="btn react-modal-close react-modal-close-sm" onClick={this.closeModal}><CloseIcon /></button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">Do you want to delete this chat.</p>
              <div className="text-right">
                <button onClick={this.dltMsgHandle.bind(this)} className="btn btn__btn btn-dark w__150">Yes, Delete</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
