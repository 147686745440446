import { connect } from 'react-redux'
import ClientsPerAgentsForm from './component'

// import { getFilterParams } from '../../../store/dashboard/duck'
import { getClientsPerAgents, clearPhase } from '../../../store/analytics/duck'

const ClientsPerAgents = connect(
  // Map state to props
  (state) => ({
  	// strategistPhase: state.analytics.strategistPhase,
  	// strategyData: state.analytics.strategyData,
  	// filterParams: state.dashboard.filterParams,
    clientsPerAgentsPhase: state.analytics.clientsPerAgentsPhase,
    clientsPerAgentsData: state.analytics.clientsPerAgentsData,
    clearPhase: state.analytics.clearPhase
  }),
  // Map actions to props
  {
  	// getStrategistList,
  	// getFilterParams,
    getClientsPerAgents,
    clearPhase
  }
)(ClientsPerAgentsForm)
export default ClientsPerAgents
