export const FETCH_INTERAL_NOTES = 'suburban/strategistV2/notes/FETCH_INTERAL_NOTES'
export const FETCH_INTERAL_NOTES_SUCCESS = 'suburban/strategistV2/notes/FETCH_INTERAL_NOTES_SUCCESS'
export const FETCH_INTERAL_NOTES_ERROR = 'suburban/strategistV2/notes/FETCH_INTERAL_NOTES_ERROR'

export const EDIT_INTERNAL_NOTE = 'suburban/strategistV2/notes/EDIT_INTERNAL_NOTE'
export const EDIT_INTERNAL_NOTE_SUCCESS =
  'suburban/strategistV2/notes/EDIT_INTERNAL_NOTE_SUCCESS'
export const EDIT_INTERNAL_NOTE_ERROR =
  'suburban/strategistV2/notes/EDIT_INTERNAL_NOTE_ERROR'

export const CREATE_NEW_NOTE = 'suburban/strategistV2/notes/CREATE_NEW_NOTE'
export const CREATE_NEW_NOTE_SUCCESS = 'suburban/strategistV2/notes/CREATE_NEW_NOTE_SUCCESS'
export const CREATE_NEW_NOTE_ERROR = 'suburban/strategistV2/notes/CREATE_NEW_NOTE_ERROR'

export const DELETE_INTERAL_NOTE = 'suburban/strategistV2/notes/CONFIRM_DELETE_INTERAL_NOTE'
export const DELETE_INTERAL_NOTE_SUCCESS =
  'suburban/strategistV2/notes/CONFIRM_DELETE_INTERAL_NOTE_SUCCESS'
export const DELETE_INTERAL_NOTE_ERROR =
  'suburban/strategistV2/notes/CONFIRM_DELETE_INTERAL_NOTE_ERROR'

export const CLEAR_NOTES_PHASE = 'suburban/strategistV2/notes/CLEAR_NOTES_PHASE'
