import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import ShowMore from "react-show-more";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Modal from 'react-responsive-modal';
import isEmpty from "lodash/isEmpty"
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { StickyContainer, Sticky } from 'react-sticky'
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button className="form-control custom-select date__picker" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class BusinessDevelopment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment('2019-03-27'),
      // fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      town_list: [],
      suburb_list: [],
      townDropdown: false,
      search: "",
      searchClient: '',
      open: false,
      currentAgentTowns: [],
      agentName: '',
      userRole: '',
      selectedDefault: 'all',
      sortByKey: '',
      sortByType : 0,
      datePickerModal: false,
      defaultStartDate: moment('2019-03-27'),
      defaultEndDate: moment(),
      modalIsOpen: false,
      suburbDropdown: false,
      suburb_list: [],
      businessDevelopmentExport: [],
      multiDataSet: [],
      customExportData:[
        {
          client_full_name: '',
          tag: '',
          closed_date:'',
          assign_strategist: "",
          reffer_clients : "",
          bd_source : "",
          source : "",
          "strategist_name-1" : '',
          "bonus_paid-1" : ''
        }
      ]
    };
  }

  setValuesForExcelSheet = async (sheetHeaders, businessDevelopment, initialHeaders) => {
    if(initialHeaders) {
      let MBaluesAr = []
      let setVals = businessDevelopment.map(async (k, i) => {
        let d = []
        let setval = initialHeaders.map((header, j) => {
          d.push(k[header])
        })
        await Promise.all(setval)
        MBaluesAr.push(d)
      })

      await Promise.all(setVals)
                let multiDataSet = this.state.multiDataSet

      if(!isEmpty(this.state.multiDataSet)) {
         multiDataSet = []
      }

      multiDataSet.push({ columns: sheetHeaders, data: MBaluesAr })
      this.setState({ multiDataSet })
    }
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      const data = {}
      data.user_id = _user.id
      const agentData = {}
      if(this.state.selectedDefault === "all"){
        agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'total'
      }else{
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
      }
      agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getBusinessDevelopmentData(agentData)
      this.setState({ isLoadingData: true, userRole: _user.role, multiDataSet:[] })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.businessDevelopmentPhase === "success") {
      let businessDevelopment = []
      let feeCount = 0
      nextProps.businessDevelopmentData && nextProps.businessDevelopmentData.length > 0 && nextProps.businessDevelopmentData.map(_client=> {
        if (_client.bd_paid.length > feeCount) {
          feeCount = _client.bd_paid.length
        }

        let exObj = {}
        exObj.client_full_name = _client.client_full_name
        exObj.tag = _client.client_status
        exObj.closed_date = (_client.closed_date !== '' && _client.closed_date !== null) ? moment(_client.closed_date).format("MM-DD-YYYY") : ''

        for(var i = 1; i <= feeCount; i++) {
          exObj[`strategist_name-${i}`] = ''
          exObj[`bonus_paid-${i}`] = ''
        }

        if(_client.bd_paid.length > 0){
          _client.bd_paid.map(function(str, index){
            exObj[`strategist_name-${index+1}`] = str.strategist_full_name
            exObj[`bonus_paid-${index+1}`] = (str.strategist_fee_date !== '' && str.strategist_fee_date !== null) ? moment(str.strategist_fee_date).format("MM-DD-YYYY") : ''
          })
        }
        exObj.assigned_strategist = _client.strategists
        exObj.reffering_client = _client.reffer_clients
        exObj.biz_dev_credit = _client.bd_source
        exObj.source = _client.source
        businessDevelopment.push(exObj)
      })
      let sheetHeaders = [];
      let maxLengthObj = {}
      let objLengths = []
      !isEmpty(businessDevelopment) && Promise.all(businessDevelopment.map((t, i) => {
        objLengths.push(Object.keys(t).length)
      }))

      let mainVal = Math.max(...objLengths)
      maxLengthObj = businessDevelopment[objLengths.indexOf(mainVal)]

      if(!isEmpty(businessDevelopment) && !isEmpty(maxLengthObj)) {
        Promise.all(Object.keys(maxLengthObj).map((k, i) => {
          let headerName = k.replace(/_/g, " ")
          headerName = headerName.replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
          sheetHeaders.push(headerName)
        }))
      }

      this.setValuesForExcelSheet(sheetHeaders, businessDevelopment, maxLengthObj ? Object.keys(maxLengthObj) : {})
      this.setState({ isLoadingData: false, businessDevelopmentExport:businessDevelopment })
    }
  }

  componentDidMount(){
    $(document).ready(function() {
      $('tbody').scroll(function(e) { //detect a scroll event on the tbody
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
      });
    });
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  datePickerClick(e){
    // this.setState({ datePickerModal: !this.state.datePickerModal,  })
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, datePickerModal: false, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: startDate, defaultEndDate: endDate, multiDataSet:[] }, ()=>{

      let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
      let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
      setTimeout(function(e) {
        const agentData = {}
        if(this.state.selectedDefault === "all"){
          agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
          agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          agentData.selectedValue = 'total'
        }else{
          agentData.from = start_from_date
          agentData.to = end_to_date
          agentData.selectedValue = 'any'
        }
        agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
        this.props.getBusinessDevelopmentData(agentData)
      }.bind(this),300)
    })
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  onOpenModal(agent) {
    const towns = agent && agent.townsCovered && agent.townsCovered.indexOf(',') !== -1 ? agent.townsCovered.split(',') : [agent.townsCovered]
    if(towns.length > 1){
      this.setState({ agentName: agent.name, open: true, currentAgentTowns: towns })
    }
  }

  onCloseModal() {
    this.setState({ agentName: '', currentAgentTowns: [], open: false })
  }

  filterDefaultDate(e){
    const agentData = {}
    this.setState({ isLoadingData: true, multiDataSet:[] },() => {})
    if(e.target.value === "all"){
      this.setState({ modalIsOpen:false })
      agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'total'
    }else{
      agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      agentData.selectedValue = 'any'
    }
    agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getBusinessDevelopmentData(agentData)
    this.setState({ selectedDefault: e.target.value },() => {})
  }

  sortData(val){
    let sortByType = this.state.sortByType
    if(val !== this.state.sortByKey ){
      sortByType = 0
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType, multiDataSet:[]}, ()=>{
      const agentData = {}
      if(this.state.selectedDefault === "all"){
        agentData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'total'
      }else{
        agentData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
      }
      agentData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getBusinessDevelopmentData(agentData)
    })
  }


  render() {
    let _user = cookies.get("user");
    const { open } = this.state;
    const { businessDevelopmentData } = this.props;
    let filterList = businessDevelopmentData;
    let clientData = []
    let clientDataBySearch = businessDevelopmentData
    if(clientDataBySearch){
      clientData = clientDataBySearch.filter(
        (data) =>{
          return (
            (data.client_full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }
    if(this.state.open){
      document.body.style.overflowX = 'visible !important'
    }

    return (
      <div>

        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Business Development</a>
                </div>
                <div className="close__btn">
                  <Link to={_user && _user.role === 'admin' ? '/dashboard' : '/strategist-dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50  remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{businessDevelopmentData && businessDevelopmentData.length}</span> Clients
              </h4>
            </div>
            <div className="col-md-12">
            <div className="reports__flex">
              <div className="dp-wrapper dp__wrapper--hafl">

              </div>

              <div className="right__cont right__cont--agent fullwidth__agentrepo fulagent">

              <div className="all__custom--wrapper">
                <select
                  className="date__picker"
                  name="selectedDefault"
                  value = {this.state.selectedDefault}
                  onChange={this.filterDefaultDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {
                (this.state.selectedDefault !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                      <Modal2
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </Modal2>
                    </div>
                  }
                </div>
              }

              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet dataSet={this.state.multiDataSet} name="Business_Development">
                </ExcelSheet>
              </ExcelFile>

            </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">
                <div className="list_table useslist__extra">
                  <StickyContainer>
                    <table className="table table-striped table-business-development">
                    <Sticky topOffset={50}>
                      {({
                        style,
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <header className="updiv" style={style}>
                          <span className="typeahead searchbox__agent--input">
                            <input
                              className="costom__search--report typeahead-input reportsearchs reportsearchsminus add"
                              onChange={this.onSearch.bind(this)}
                              type="text"
                              name="search"
                              placeholder="Search Client"/>
                          </span>
                          <thead>
                            <tr>
                              <th className="for__sort for__static" onClick={this.sortData.bind(this , 'client_name')}>Client Name</th>
                              <th className="text-left for__sort" onClick={this.sortData.bind(this , 'tag')}>Tag</th>
                              <th className="text-left for__sort" onClick={this.sortData.bind(this , 'closed_date')}>Closed Date</th>
                              <th className="text-left for__sort strategist__width__bd">BD Paid</th>
                              <th className="text-left for__sort">Assigned Strategist</th>
                              <th className="text-left for__sort">Referring Client</th>
                              <th className="text-left">Biz Dev Credit</th>
                              <th className="text-left">Source</th>
                            </tr>
                          </thead>
                        </header>
                      )}
                    </Sticky>
                    <tbody>
                      { this.state.isLoadingData === true ? (
                        <tr className="loader__center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your business development report is generating, please wait.........</h5>
                        </tr>
                        ) : (
                          clientData &&
                          clientData.map((client, index) => {
                            return (
                              <tr key={index} className="forcursor">
                                <td className="testing">{client.client_full_name}</td>
                                <td className="wrap-text text-left">{client.client_status}</td>
                                <td className="text-left">{client.closed_source && client.closed_source.length > 0 ? moment(client.closed_source[0].expected_close_date).format("MM-DD-YYYY") : ''}</td>
                                <td className="strategist__width__bd">
                                {
                                  client.bd_paid && client.bd_paid.length > 0 &&
                                  client.bd_paid.map((acc, accIdx)=>{
                                    return(
                                      <div className="detailsgap" key={accIdx}>
                                        <div>
                                          <label className="labelglobal">Strategist-{accIdx+1}:</label>
                                          <p>{acc.strategist_full_name}</p>
                                        </div>
                                        <div>
                                          <label className="labelglobal">Bonus Paid:</label>
                                          <p>{(acc.strategist_fee_date !== '' && acc.strategist_fee_date !== null) ? moment(acc.strategist_fee_date).format('MM-DD-YYYY') :''}</p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                </td>
                                <td className="text-left">{client.strategists}</td>
                                <td className="text-left">{client.reffer_clients}</td>
                                <td className="text-left">{client.bd_source}</td>
                                <td className="text-left">{client.source}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </StickyContainer>
                </div>
              </main>
              {
                filterList && filterList.length > 0 ?
                 <div></div>
                :
                 <div className="dashboard-footer text-center mar__top--zero">Data Not Found</div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "businessdevelopment", // a unique identifier for this form
  destroyOnUnmount: true
})(BusinessDevelopment);
