import React, { PureComponent /*, PropTypes*/ } from 'react';
import EducationOverview from './EducationOverview/component';
import PublicSchools from './PublicSchools/component';
import PrivateSchools from './PrivateSchools/component';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import './styles.scss';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forEach from "lodash/forEach"
import { logoutInvalidRequest } from '../../../../../../utils/helper';


export default class EducationComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: '',
      publicData: [],
      privateData: [],
      isDateLoading: false,
      modalIsOpen: false,
      edit_school: '',
      school_id: 0,
      edit_grades: '',
      edit_ratio: '',
      edit_no_of_students: '',
      edit_rating: '',
      modelType: '',
      add_school: '',
      add_grades: '',
      add_ratio: '',
      add_no_of_students: '',
      add_rating: '',
      err: {},
      town_name: '',
      openSideBar: false,
      privateAttom: []
    };
  }
  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    // this.getAttomSchool();
    // this.loadingSchoolData();
    // this.props.toggleLoading(true);
    const url = get(this, 'props.location.pathname').split('education');
    const defaultUrl = get(url, '[1]', '') ? get(url, '[1]', '') : '/public'
    this.setState({ currentTab: defaultUrl });
    this.props.history.push(`${this.props.match.url}${defaultUrl}`);
  }

  switchTabs = type => {
    this.setState({ currentTab: type });
    this.props.history.push(`${this.props.match.url}${type}`);
  };


  parseSchools(schools) {
    const schoolsFinal = [];
    forEach(schools, school => {
      const obj = {};
      obj.school_name = get(school, '[0].InstitutionName', '');
      obj.school_grades = get(school, '[0].gradespancodebldgtext', '');
      obj.school_rating = get(school, '[0].GSTestRating', '0');
      obj.school_stud_teacher_ratio = get(school, '[0].studentteacher', '');
      obj.no_of_student = get(school, '[0].studentsnumberof', '');
      obj.school_type = get(school, '[0].Filetypetext', '');
      obj.geocodinglatitude = get(school, '[0].geocodinglatitude', '');
      obj.geocodinglongitude = get(school, '[0].geocodinglongitude', '');
      schoolsFinal.push(obj);
    });
    return schoolsFinal;
  }

   loadingSchoolData = async(type, key) => {
     this.props.toggleLoading(true)
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const fetchData = () =>
      axios.get(
        `${HOSTNAME}/schoolDataByTownId?town_id=${townId}&type=attom_school_${type}`
      ).catch(logoutInvalidRequest);
    const pubData = await fetchData();
    if (pubData && pubData.data && pubData.data.data) {
      this.props.toggleLoading(false);

      this.setState({ [key]: pubData.data.data, isDateLoading: false });
    }
  }

  render() {
    const { currentTab } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs__control mt__15">
              {/*<button*/}
              {/*  onClick={() => this.switchTabs('')}*/}
              {/*  className={`btn btn-link ${currentTab === '' && 'active'}`}*/}
              {/*>*/}
              {/*  Education Overview*/}
              {/*</button>*/}
              <button
                onClick={() => this.switchTabs('/public')}
                className={`btn btn-link ${currentTab === '/public' &&
                  'active'}`}
              >
                Public Schools
              </button>
              <button
                onClick={() => this.switchTabs('/private')}
                className={`btn btn-link ${currentTab === '/private' &&
                  'active'}`}
              >
                Private Schools
              </button>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path={this.props.match.url}>
            <EducationOverview {...this.props} {...this.state}  />
          </Route>
          <Route exact path={`${this.props.match.url}/public`}>
            <PublicSchools loadingSchoolData={this.loadingSchoolData} {...this.props} {...this.state} />
          </Route>
          <Route exact path={`${this.props.match.url}/private`}>
            <PrivateSchools loadingSchoolData={this.loadingSchoolData} {...this.props} {...this.state} />
          </Route>
        </Switch>
      </div>
    );
  }
}
