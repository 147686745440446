/* eslint-disable camelcase */
import React, { PureComponent /*, PropTypes*/ } from "react";
import ReactModal from "react-modal";
import { notification } from 'antd';
import "./styles.scss";
import TopNavigation from "../../../../components/TopNavigation/container";
import {
  AgentAppointmentsColorIcon,
  CloseIcon,
} from "../../../../components/icons";
import SelectPageResult from "../Clients/partials/layout/SelectPageResult";
import AdministrationSidebarNavigation from "../../../../components/AdministrationSidebarNavigation/container";
import ProspectAgents from "./partials/ProspectAgents";
import RejectedAgents from "./partials/RejectedAgents";
import { Pagination } from "antd";

export default class AgentInterviewsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      visible: false,
      pageLimit: 20,
      activePage: 1,
      totalPages: 0,
      sortByType: 1, // desc by default
      sortByKey: "id",
      fetchApplicantsPhase: "",
      applicantPhase: "",
      activeTab: 1,
      applicantsType: 1,
      applicants:[],
      prospectApplicants:[],
      rejectedApplicants:[],
      userData:"",
    };
    this.filterAppointmentChildRef = React.createRef();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.sortData = this.sortData.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }


  componentDidMount() {

    if (this.props.user) {
      this.props.getUSerProfileByID({ user_id: this.props.user.id })
    }
    const { activePage,pageLimit, sortByKey, sortByType } = this.state;
    const clientdata = {
      pageLimit,
      sortByKey,
      sortByType,
      activePage,
      fetchRejectedCount:true,
      applicantsType: 1, // prospect applicants only
    };
    this.props.fetchProspectApplicants(clientdata);

    // remove indicators after load
    // localStorage.removeItem("rejectedAgentsTab");
    // localStorage.removeItem("rejectedApplicantsLoad");
  }

  static getDerivedStateFromProps(props, state) {
    let states = { ...state };
    if (props.fetchApplicantsPhase != state.fetchApplicantsPhase) {
      if(state.activeTab === 1) {
        states = {
          isLoading: false,
          prospectApplicants: props.prospectApplicants,
          fetchApplicantsPhase: props.fetchApplicantsPhase,
          rejectedApplicantsCount:props.rejectedApplicantsCount,
          prospectApplicantCount: props.prospectApplicantCount
        };
      } else if(state.activeTab === 2) {
        states = {
          isLoading: false,
          rejectedApplicants: props.rejectedApplicants,
          fetchApplicantsPhase: props.fetchApplicantsPhase,
          rejectedApplicantsCount:props.rejectedApplicantsCount,
          prospectApplicantCount: props.prospectApplicantCount
        };
      } else {
        states = {
          isLoading:false
        }
      }
    }

    // // on accept/reject show loader
    props.applicantPhase == "loading" ? (states = { isLoading:true,applicantPhase:props.applicantPhase }) : null;

    // reload data after accept/reject
    if(props.applicantPhase == 'success' && state.applicantPhase != 'success') {
      const clientdata = {
        pageLimit:state.pageLimit,
        sortByKey:state.sortByKey,
        sortByType:state.sortByType,
        activePage:state.activePage,
        applicantsType: state.applicantsType,
      } ;
      state.activeTab === 1 ? props.fetchProspectApplicants(clientdata) : props.fetchRejectedApplicants(clientdata)
      states = { applicantPhase:props.applicantPhase, fetchApplicantsPhase:''}
    }

    return states;
  }

  handlePageChange(pageNumber) {
    this.setState({ isLoading: true, activePage: pageNumber }, () => {
      const clientdata = {
        pageLimit: this.state.pageLimit,
        sortByKey: this.state.sortByKey,
        sortByType: this.state.sortByType,
        activePage: pageNumber,
        applicantsType:this.state.applicantsType
      };
      this.state.activeTab === 1 ? this.props.fetchProspectApplicants(clientdata) : this.props.fetchRejectedApplicants(clientdata);
    });
  }

  // handlePageChange = (pageNumber) => {
  //   //this.setState({ activePage: pageNumber })
  //   let activePage = pageNumber
  //   const { pageLimit, sortByKey, sortByType } = this.props;
  //   const clientdata = {
  //     pageLimit,
  //     sortByKey,
  //     sortByType,
  //     activePage,
  //     fetchRejectedCount:true,
  //     applicantsType: 2, // prospect applicants only
  //   };
  //   this.props.fetchProspectApplicants(clientdata);
  // }

  sortData(sortByKey) {
    let sortByType = Number(!this.state.sortByType);
    this.setState({ isLoading: true, sortByKey, sortByType }, () => {
      const clientdata = {
        pageLimit: this.state.pageLimit,
        sortByKey,
        sortByType,
        activePage: this.state.activePage,
        applicantsType:this.state.applicantsType
      };
      this.state.activeTab === 1 ? this.props.fetchProspectApplicants(clientdata) : this.props.fetchRejectedApplicants(clientdata);
    });
  }

  handleApprove(agentApplicant) {
    this.setState({
      visible: true,
      approved: 1,
      rejected: 0,
      modalTitle: "Approve agent",
      modalMessage: "Are you sure you want to approve this agent? If so, the agreement will automatically be sent to the agent and the managing broker.",
      userData: agentApplicant
      })
    }

  handleResultChange = (pageLimit) => this.setState({ pageLimit }, () => {
    this.sortData()
  })

  handleReject(agentApplicant) {
    this.setState({
      visible: true,
      approved: 0,
      rejected: 1,
      modalTitle: "Reject agent",
      modalMessage: "Are you sure you want to reject this agent? If you select “Yes”, the agreement will not be sent to the agent and the agent will automatically move to the “Rejected Agents” tab. If you want to approve them later, you will be able to do so. ",
      userData: agentApplicant
    });
  }

  handleOk() {
    this.setState(
      { isLoading: true, visible: false, fetchApplicantsPhase:'', applicantPhase: ""},
      () => {
        if(this.state.rejected) {
          this.props.rejectApplicant({user: this.state.userData})
          if(this.state.rejectedApplicantsCount) this.state.rejectedApplicantsCount +=1;
        }
        this.state.approved ? this.props.approveApplicant({user: this.state.userData}) : null;
      }
    );
  }

  handleTabChange() {
    this.setState({activePage:1})
    const clientdata = {
      pageLimit: this.state.pageLimit,
      activePage: 1,
      applicantsType: this.state.applicantsType, 
    };
    this.state.activeTab === 1 ? this.props.fetchProspectApplicants(clientdata) : this.props.fetchRejectedApplicants(clientdata)
  }

  // show hide delete client confirmation modal
  toggleModal = () => this.setState({ visible: !this.state.visible });

  render() {
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="desktop__view">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10">
                      <h2 className="heading-title">
                        <span className="heading-icon">
                          <AgentAppointmentsColorIcon className="" />
                        </span>
                        Agent Applications
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <SelectPageResult handlePageChange={this.handleResultChange} />
                    </div>
                  </div>
                  <div className="tabs__control">
                    <button
                      className={`btn btn-link ${
                        this.state.activeTab === 1 ? "active" : ""
                      }`}
                      onClick={() => this.setState({ isLoading: true,activeTab: 1,applicantsType:1}, ()=> {
                          setTimeout(()=>{
                            this.handleTabChange();
                          }, 500)
                      })}
                    >
                      Agent Applications <span>{this.state.prospectApplicantCount} Agents </span>
                    </button>
                    <button
                      className={`btn btn-link ${
                        this.state.activeTab === 2 ? "active" : ""
                      }`}
                      onClick={() => this.setState({ isLoading: true,activeTab: 2,applicantsType:2}, ()=>{
                        setTimeout(()=>{
                          this.handleTabChange();
                        }, 500)
                      })}
                    >
                      Rejected Agents <span>{this.state.rejectedApplicantsCount} Agents </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {this.state.activeTab === 1 ? (
                  <ProspectAgents
                    handleApprove={this.handleApprove}
                    handleReject={this.handleReject}
                    sortData={this.sortData}
                    {...this.state}
                  />
                ) : (
                  <RejectedAgents
                    handleApprove={this.handleApprove}
                    sortData={this.sortData}
                    {...this.state}
                  />
                )}
              </div>
              {this.state.activeTab === 2 && this.state.rejectedApplicants &&
                this.state.rejectedApplicants.length > 0 &&
                this.state.rejectedApplicantsCount > 0 && (
                <Pagination
                  defaultCurrent={this.state.activePage}
                  className="mt__20 mb__20"
                  total={this.state.rejectedApplicantsCount}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  pageSize={this.state.pageLimit}
                  onChange={this.handlePageChange}
                />
              )}
              {this.state.activeTab === 1 && this.state.prospectApplicants &&
                this.state.prospectApplicants.length > 0 &&
                this.state.prospectApplicantCount > 0 && (
                <Pagination
                  defaultCurrent={this.state.activePage}
                  className="mt__20 mb__20"
                  total={this.state.prospectApplicantCount}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  pageSize={this.state.pageLimit}
                  onChange={this.handlePageChange}
                />
              )}
            </div>
          </div>
        </main>
        <ReactModal
          isOpen={this.state.visible}
          contentLabel={this.state.modalTitle}
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button
                type="button"
                className="btn react-modal-close react-modal-close-sm"
                onClick={this.toggleModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">
                {this.state.modalMessage}
              </p>
              <div className="text-right">
                <button
                  onClick={this.handleOk}
                  className="btn btn__btn btn__success w__150"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
