import { connect } from 'react-redux'

import StrategyComponent from './component'

import { getClientStrategist, clearStrategistPhase, changeClientStatus } from '../../../../../store/strategistV2/strategist/actions'
import {
  getLeaveComments,
  addLeaveComments,
  deleteComment,
  clearCommentPhase,
} from '../../../../../store/strategistV2/comments/actions'

const StrategyContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    singleClientData: state.dashboard.singleClientData,
    leaveComments: state.strategistV2.comment.leaveComments,
    strategyData: state.strategistV2.strategist.clientStrategist,
    getClientStrategistPhase: state.strategistV2.strategist.getClientStrategistPhase,
    addLeaveCommentPhase: state.strategistV2.comment.addLeaveCommentPhase,
    getLeaveCommentPhase: state.strategistV2.comment.getLeaveCommentPhase,
    removeLeaveCommentPhase: state.strategistV2.comment.removeLeaveCommentPhase,
    changeClientStatusPhase: state.strategistV2.strategist.changeClientStatusPhase,
  }),
  // Map actions to dispatch and props
  {
    getClientStrategist, 
    clearStrategistPhase,
    getLeaveComments,
    deleteComment,
    addLeaveComments,
    changeClientStatus,
    clearCommentPhase,
  }
)(StrategyComponent)

export default StrategyContainer
