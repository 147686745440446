import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import './styles.scss'
import Page2 from './partials/sign_up_page1'
import Page3 from './partials/sign_up_page2'
import Page4 from './partials/sign_up_page3'
import Cookies from 'universal-cookie'
import { Spin, message } from 'antd'
import Loader from "../../../../components/Loader"
import {getHostname} from "../../../../utils/helper";

const cookies = new Cookies()
let _user = ''
let pre_Auth = ''

export default class SignupComponent extends PureComponent {
  constructor(props) {
    super(props)
       this.state = {
       stage: 1,
       isLoading: false,
       current_user_data: []
    }
  }

  componentDidMount(){
    _user = cookies.get('user')
    pre_Auth = cookies.get('i_user')
    if(_user){
      this.setState({ isLoading: true, current_user_data: _user })
      this.props.getFilterParams()
      this.props.getAgentOnBoardData({ user_id: _user.id })
    }
  }

  componentDidUpdate(prePorps, preState){
    const { onBoardingPhase, onBoardingData, clearAgentPhase, submitAgentFormPhase, submitAgentFormData, clearAgentSubmitPhase } = this.props
    if(onBoardingPhase === 'success'){
      clearAgentPhase()
      const suburbsArr = onBoardingData && onBoardingData.data.suburbs
      if(_.size(suburbsArr)>0){
        this.props.getTowns({ suburb_arr: suburbsArr })
      }
      let agentStatus = onBoardingData && onBoardingData.data && onBoardingData.data.onboarding_status
      if(agentStatus === 1) {
        this.setState({ isLoading: false, stage: 2 })
      } else if(agentStatus === 2) { 
        this.setState({ isLoading: false, stage: 3 })
      } else if(agentStatus === 3 || agentStatus === 5 || agentStatus === 6) {
        this.setState({ isLoading: false, stage: 4 })
      } else if(agentStatus === 7) {
        this.props.history.push(`/agent/dashboard`)
      } else {
        this.props.history.push(`/login`)
      }
    }
    if(onBoardingPhase === 'error'){
      clearAgentPhase()
      this.setState({ isLoading: false })
    }
    if(submitAgentFormPhase === 'success' && submitAgentFormData && submitAgentFormData.status){
      clearAgentPhase()
      this.props.getAgentOnBoardData({ user_id: _user.id })
    }
    if(submitAgentFormPhase === 'error' || (submitAgentFormData && submitAgentFormData.status === false) ){
      clearAgentPhase()
      clearAgentSubmitPhase()
      this.setState({ isLoading: false })
      message.error(`${submitAgentFormData.message}`)
    }
  }

  onClickPage1 =(data)=> {
    this.setState({ isLoading: true })
    data.user_id = _user.id
    // this.setState({ stage: 3 })
    this.props.sumbitAgentForm(data)
  }

  onClickPage2 =(data)=>{
    this.setState({ isLoading: true })
    data.user_id = _user.id
    // this.setState({ stage: 4 })
    this.props.sumbitAgentForm(data)
    localStorage.clear()
    localStorage.removeItem("agentSignUp2")
  }

  goBack = (e) => {
    this.setState({ stage: 2 })
  }

  toggleLoading =(isToggle)=>{
    this.setState({ isLoading: isToggle })
  }

  /* This is signout user functionality */
  userSignout=()=> {
    localStorage.clear()
    document.cookie ="Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    localStorage.removeItem("client_id")
    localStorage.removeItem("client_strategist")
    localStorage.removeItem("client_status")
    localStorage.removeItem("client_suburb")
    this.props.history.push("/login")
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });

    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('agent_id')
    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
          {
            (pre_Auth)?
            <div className="auth-name">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
            :''
          }
          <div className={pre_Auth ? "agent-signup extra__spacing" : "agent-signup"}>
            <div className="agent-signup-header">
              <div className="agent-signup-title">
                Sign Up
              </div>
              <div className="agent-signup-progress">
                <div className="progress">
                  <div className="progress-bar" role="progressbar"  style={{ 'width': `${25*(this.state.stage-1)}%`}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                  <div className="progress-bar-text">{`${25*(this.state.stage-1)}% Completed`}</div>
              </div>
              <div className="agent-signup-cta">
                <button onClick={this.userSignout} className="btn btn__btn btn-dark">Logout</button>
              </div>
            </div>

            <div className="agent-signup-inner d__flex align__items__center">
              <div className="agent-signup-body">
              {this.state.stage === 2 && <Page2 {...this.props} {...this.state} toggleLoading={this.toggleLoading} onClickPage1={this.onClickPage1}/>}
              {this.state.stage === 3 && <Page3 {...this.props} {...this.state} toggleLoading={this.toggleLoading} onClickPage2={this.onClickPage2} goBack={this.goBack}/>}
              {this.state.stage === 4 && <Page4 {...this.props} {...this.state}/>}
            </div>
          </div>
        </div>
       </Spin>
      </div>
    )
  }
}
