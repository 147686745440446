import { connect } from 'react-redux'

import NoInitialOrFollowUpAgentVisitComponent from './component'
import { getNoInitialAgentTourData, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const NoInitialOrFollowUpAgentVisitContainer = connect(
  // Map state to props
  (state) => ({
    noInitialAgentTourPhase: state.reportV2.noInitialAgentTourPhase,
    noInitialAgentTourData: state.reportV2.noInitialAgentTourData
  }),
  // Map actions to dispatch and props
  {
    getNoInitialAgentTourData,
    clearReportV2Phase
  }
)(NoInitialOrFollowUpAgentVisitComponent)

export default NoInitialOrFollowUpAgentVisitContainer
