import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'

import { prospectEpic, prospectReducer } from './prospectus'
import { followUpEpic, followUpReducer } from './followup'

export const prospectusEpic = combineEpics(prospectEpic, followUpEpic)
export const prospectusReducer = combineReducers({
  prospectus: prospectReducer,
  followUp: followUpReducer,
})
