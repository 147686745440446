import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { CameraIcon } from '../../components/icons'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import { TextField } from 'redux-form-material-ui'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import axios from 'axios'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { confirmAlert } from 'react-confirm-alert';
import { logoutInvalidRequest } from '../../utils/helper'
import { fetch } from '../../utils'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies() // Import core
const API_HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

let _user = ''

class ProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      avatar: '',
      err: {},
      file: null,
      country_code:'+1',
      infoToggle: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.saveFirstBtn = this.saveFirstBtn.bind(this)
    this.handleProfileInfoSubmit = this.handleProfileInfoSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      this.props.getClientAgent(data)
    }
  }

  componentDidMount(){
    document.title = "Jungler: Client Profile";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  componentWillReceiveProps(nextProps) {
    const { fetchUser } = this.props
    let self = this
    if (nextProps.updateProfilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    }
    if(nextProps.shareClientInfoData !== this.props.shareClientInfoData){
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
    }
    if (nextProps.updateProfilePhase === "success") {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile updated successfully."})
      const data = {}
      data.user_id = this.state.userid
      this.props.fetchUser(data)
    }
    if (nextProps.profilePhase === "success" && !this.state.id) {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone,
          avatar: user.avatar,
          country_code: user.country_code,
          infoToggle: user.share_info_to_all
        })
      }
    }
    setTimeout(function() {
      self.setState({ errProfileMessage: '', errAddressMessage: '', errPasswordMessage: ''})
    }, 5000);
  }

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL;
    // let files = $("#profileImage")[0].files[0];
    let files  = e.target.files[0]
    var img = new Image();
    let self=this
    let reader = new FileReader();
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files);
      img.onload = function() {
          self.setState({file: files})
          self.setState({avatar: reader.result})
      }
    }
    var read = reader.readAsDataURL(files)
  }

  saveFirstBtn(id, e){
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', this.state.file);
    fetch(`${SUBURBANJUNGLE_API_HOST}/users/me/avatar`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(response => {
      if(response.status && response.data){
        this.setState({avatar: response.data})
        this.handleProfileInfoSubmit(id)
      }
      else{
        this.handleProfileInfoSubmit(id)
      }
    }).catch(logoutInvalidRequest)
  }

  handleProfileInfoSubmit(id) {
    const err = {}
    if (this.state.firstname === '' || this.state.firstname.trim() === '') {
      err.firstname = 'First name is required.'
    }
    if (this.state.lastname === '' || this.state.lastname.trim() === '') {
      err.lastname = 'Last name is required.'
    }
    if (this.state.phone === '' || this.state.phone.trim() === '') {
      err.phone = 'Phone number is required.'
    }
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(this.state.email)) {
      err.email = 'Invalid email'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({ isLoadingProfile: true })
      const data = {
        user_id: this.state.id,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        phone_mobile: this.state.phone,
        email: this.state.email,
        time_zone : this.state.timezone,
        avatar   : this.state.avatar,
        country_code : this.state.country_code
      }
      this.props.updateProfile(data)
    }
  }

  handleCodeChange(event){
    this.setState({ country_code: '+'+event})
  }

  shareInfoToggle(e){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding piersandfonts'>
          <div className="flexdiv">
            <h4 className="popupheader__heading piersandfonts">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          {
            this.state.infoToggle === false ?
            <p className="allpoppadding__para">Show your contact info to all agents?</p>
            :
            <p className="allpoppadding__para">Hide your contact info to all agents?</p>
          }

            <button className="btns__fullwidth no-radius uppercasefonts" onClick={() => {
                this.shareInfoToAgent()
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  shareInfoToAgent(){
    this.setState((prevState) => {
      return {
        ...prevState,
        infoToggle: !prevState.infoToggle
      }
    }, () => {
      let data = {
        share_info: this.state.infoToggle
      }
      this.props.shareClientInfoToAgent(data)
    })
  }

  render() {
    const { user, agentData } = this.props;
    let findAgentOnly  = []

    findAgentOnly = _.filter(agentData, (agent)=>{
      return agent.agent_role === "agent"
    })
    let authorization = cookies.get('Authorization');
    let { avatar } = this.state;
    if(authorization ===''){
      return(
         <Redirect to={'/client-login'} />
        )
    }
    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
    });
    const isLoadingProfile = this.state.isLoadingProfile
    return (
      <div className="clients__changes__parent">
        {/* <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Edit Profile</span>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <a onClick={() => {window.history.back()}}><img src="img/close.svg"/></a>
                  </div>
                </div>
              </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile__header--wrapper">
                <div className="profile__header">
                  <a>
                    <img src="/img/logo.svg" />
                    <h4>
                      <span>|</span> Edit Profile
                    </h4>
                  </a>
                </div>
                <div className="profile__close">
                  <a onClick={() => {window.history.back()}}><img src="img/close.svg"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mobile__profile--container">
                  <span className="mobile__profile--avtar">
                  <img
                    src={
                      avatar !== "" && avatar !== null
                        ? avatar
                        : "img/login-bg-img.png"
                    }
                    alt=""
                  />
                  </span>
                  <form>
                    <span className="btn-file">
                      <span className="editPhotoBtn">Edit Photo </span>
                      <input
                        type="file"
                        className="edit__photo--btn"
                        id="profileImage"
                        onChange={this.handleFileUpload.bind(this)}
                      />
                    </span>
                    <span className="maximg__label">(Max 1MB allowed)</span>
                  </form>
                </div>
              </div>
            </div>
          </div>

        <div className="profile__container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="profile__container--form open__sans--font addclient__container--form">
                  <div className="panel panel-default mobile__no--border addbord mobmarbtm">
                    <div className="panel-heading mobile__no--display">
                      <h3 className="panel-title">My Profile</h3>
                      {/* <div className="profile__container--section">
                        <span className="profile__container--avtar">
                        <img src={avatar !== '' && avatar !== null ? avatar : '../img/login-bg-img.png' } alt="" />
                      </span>
                      <form>
                        <span className="btn-file">
                          <span className="editPhotoBtn">Edit Photo </span>
                          <input type="file" className="edit__photo--btn" id="profileImage" onChange={this.handleFileUpload.bind(this)} />
                        </span>
                        <span>(Max 1MB allowed)</span>
                      </form>
                      </div> */}
                    </div>
                    <div className="panel-body">
                      <div className="form-group material-textfield">
                        <div className="d__flex align__items__center">
                            <div className="avatar-text">Photo<span> (1mb max)</span></div>
                            <div className="avatar avatar-lg">
                              <img src={avatar !== '' && avatar !== null ? avatar : '../img/blank_profile_image.png' } alt=""/>
                              {
                              //   (_.isEmpty(values.avatar)) &&
                              // <span className="avatar-string">{ _.get(values, 'first_name', '') ? _.upperFirst(_.get(values, 'first_name', '').charAt(0)) : ''}{_.get(values, 'last_name', '') ? _.upperFirst(_.get(values, 'last_name', '').charAt(0)) : ''}</span>
                              }
                              <div className="upload-avatar-container">
                                <input
                                  type="file"
                                  className="upload-avatar"
                                  accept="image/*"
                                  name="image"
                                  onChange={this.handleFileUpload.bind(this)}
                                  // onChange={(e) => this.handleFileUpload(e, setFieldValue)}
                                  id="file" />
                                <CameraIcon/>
                              </div>
                            </div>

                        </div>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                          <label className="labelinputs__global">First Name</label>
                            <div className="form-group text-group">
                             <input type="text" name = "firstname" className="textinputs__global" value={this.state.firstname} onChange={this.handleChange.bind(this)} required/>
                              { this.state.err.firstname ?
                              <span className="error_field">
                              {this.state.err.firstname}
                              </span> : '' }
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                          <label className="labelinputs__global">Last Name</label>
                            <div className="form-group text-group">
                              <input type="text" name = "lastname" className="textinputs__global" value={this.state.lastname} onChange={this.handleChange.bind(this)} required/>
                              { this.state.err.lastname ?
                              <span className="error_field">
                              {this.state.err.lastname}
                              </span> : '' }
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <label className="labelinputs__global">Mobile Phone</label>
                            <div className="form-group text-group textinputs__global phonefull__input">
                              <PhoneInput
                                defaultCountry={'us'}
                                value={this.state.country_code}
                                autoFormat={false}
                                disableAreaCodes={true}
                                enableLongNumbers={false}
                                onChange={this.handleCodeChange.bind(this)}
                                buttonClass="flag-dropdown__login"
                              />
                              <NumberFormat
                                format="(###) ### #### #####"
                                //placeholder="(444)  444 - 4444"
                                value={this.state.phone}
                                name='phone'
                                id="flagInputControlMainPhone"
                                onChange={this.handleChange.bind(this)}
                              />
                              { this.state.err.phone ?
                              <span className="error_field">
                              {this.state.err.phone}
                              </span> : '' }
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label className="labelinputs__global">Email</label>
                            <div className="form-group text-group">
                              <input type="text" name = "email" className="textinputs__global" value={this.state.email} onChange={this.handleChange.bind(this)} required/>
                              { this.state.err.email ?
                              <span className="error_field">
                              {this.state.err.email}
                              </span> : '' }
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="labelinputs__global">Time Zone</label>
                          <select className="selectinputs__global" name = "timezone" value={this.state.timezone} onChange={this.handleChange.bind(this)}>
                            <option>Select Time Zone</option>
                            {timezonlist}
                          </select>
                        </div>

                        {findAgentOnly && findAgentOnly.length > 0 ? ( <div className="form-group">
                          <div className="formgroup__inner">
                          {/*this id for toggle button show share own info to agent*/}
                          <label className="labelinputs__global">Contact info visible to all agents</label>
                          <div className="toggle__wrapper">
                            <label className="switch1">
                              <input type="checkbox" checked={this.state.infoToggle} onChange={this.shareInfoToggle.bind(this)}/>
                              <span style={{ marginTop: '10px'}} className="slider1 round switch__padding"></span>
                            </label>
                            {
                              (this.state.infoToggle)? <p style={{ marginTop: '14px'}}><span>Yes</span></p> : <p style={{ marginTop: '14px'}}><span>No</span></p>
                            }
                          </div>
                          {/*************End*****************/}
                          </div>
                        </div>) : ''}

                        <button
                            type="submit"
                            disabled={isLoadingProfile}
                            className="btn btn__flat btn-block btn__green btn__flat__lg"
                            onClick={this.saveFirstBtn.bind(this, user.id)} >{isLoadingProfile ?
                              'Please wait...'
                              : 'Save'
                            }</button>
                        {this.state.errProfileMessage !== '' &&
                          <div className="text-center alert alert-success">
                            {this.state.errProfileMessage}
                          </div>
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'profile',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ProfileForm)
