import { connect } from 'react-redux'

import {
  getAgentChat,
  sendAgentNote,
  clearAgentDatePhase
} from '../../../../../../store/agent/duck'

import { editStrategistAgentNote, deleteStrategistAgentNote, clearAgentPhase } from '../../../../../../store/agentV2/actions'

import StrategistMessageComponent from './component'
const StrategistMessageContainer = connect(
  // Map state to props
  (state) => ({
    getAgentChatData: state.agent.getAgentChatData,
    editStrAgentPhase: state.agentV2.editStrAgentPhase,
    deletStrAgentPhase: state.agentV2.deletStrAgentPhase,
    agentPhase: state.agent.agentPhase,
  }),
  // Map actions to dispatch and props
  {
    getAgentChat,
    editStrategistAgentNote,
    deleteStrategistAgentNote,
    clearAgentPhase,
    sendAgentNote,
    clearAgentDatePhase
  }
)(StrategistMessageComponent)

export default StrategistMessageContainer
