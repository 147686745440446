import { connect } from 'react-redux'

import { getClientById, agentClientFollowUp, updateClientUser, deleteClientDealByAgent, createClientDeal, clearAgentDatePhase, getAgentDeal, clearAgentPhase,
  clearPhase } from '../../../../store/agent/duck'

import ClientDetailsComponent from './component'
const ClientDetailsContainer = connect(
  // Map state to props
  (state) => ({
    clientData: state.agent.clientData,
    deleteClientDealPhase: state.agent.deleteClientDealPhase,
    clientDealPhase: state.agent.clientDealPhase,
    updateUserPhase: state.agent.updateUserPhase,
    followUpPhase: state.agent.followUpPhase,
    clientPhase: state.agent.clientPhase
  }),
  // Map actions to dispatch and props
  {
    getClientById,
    agentClientFollowUp,
    updateClientUser,
    deleteClientDealByAgent,
    createClientDeal,
    clearAgentDatePhase,
    getAgentDeal,
    clearAgentPhase,
    clearPhase
  }
)(ClientDetailsComponent)

export default ClientDetailsContainer
