import { List } from 'immutable'
import { fetch } from '../../utils'
import qs from 'qs'

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getTownReports = (params) => {

  const qsQueryString = qs.stringify({type: 'towns', ...params, filters: JSON.stringify(params.filters)})

  const url = `${SUBURBANJUNGLE_API_HOST}/town-report/all?${qsQueryString}`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    }).catch((error) => {
      console.log(error)
    })
}

export const generateReport = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-attom`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateTownCharacteristics = (data) => {
  return fetch(`${HOSTNAME}/import/single/town`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const fetchTownCharacteristics = (data) => {
  return fetch(`${HOSTNAME}/import/single/town`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getGeneratedReport = (id) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/data-checklist/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const uploadImage = (data) => {
  const formDataObj = {};
  data.forEach((value, key) => (formDataObj[key] = value));

  if (formDataObj.rotateImage) {
    const rotateData = {
      angle: formDataObj.angle,
      id: formDataObj.id,
      townId: formDataObj.townId
    }
    return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/${formDataObj.townId}/image-rotate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rotateData)
    })
      .then((res) => {
        return res.json()
      })
      .then((payload) => {
        return payload
      }).catch((error) => {
        throw error
      })
  }

  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/${formDataObj.townId}/image-upload`, {
    method: 'POST',
    body: data
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const uploadVideo = (data) => {
  return fetch(`${HOSTNAME}/town/video`, {
    method: 'POST',
    body: data
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateImage = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/${parseInt(data.townId)}/image-sort`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateVideo = (data) => {
  return fetch(`${HOSTNAME}/update_video?town_id=${parseInt(data.townId)}&video_id=${parseInt(data.id)}&video_index=${parseInt(data.idx)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const editReport = (data) => {
  return fetch(`${HOSTNAME}/edit/single/town`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const pushToWordpress = (data) => {
  return fetch(`${HOSTNAME}/wp/synch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const repushToWordpress = (data) => {
  return fetch(`${HOSTNAME}/wp/synch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const markFeatured = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/featured-image/${data.town_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const markFeaturedVideo = (data) => {
  return fetch(`${HOSTNAME}/town-report/video/featured`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const removeImage = (data) => {

  return fetch(`${SUBURBANJUNGLE_API_HOST}/town-reports/image-delete/${data.image_id}`, {
    method: 'DELETE',
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const removeVideo = (data) => {
  return fetch(`${HOSTNAME}/town-report/video/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const communityDataByTownId = (params) => {
  // SJ-441
  const { town_id } = params
  const url = `${SUBURBANJUNGLE_API_HOST}/town/${town_id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => {
    return res.json()
  }).then((payload) => {
    return payload.data.communityData
  }).catch((error) => {
    throw error
  })
}

export const editCommunityData = (data) => {
  return fetch(`${HOSTNAME}/editCommunityData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAttomSchools = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.townId}/attom-schools?type=${data.type}&town_id=${data.townId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


export const fetchSchools = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.townId}/town-attom-school-data?type=attom_school_${data.type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}


export const editPrivateSchool = (data) => {
  return fetch(`${HOSTNAME}/editSchoolData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const editPublicSchool = (data) => {
  return fetch(`${HOSTNAME}/editSchoolData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const deletePrivateSchool = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.town_id}/town-attom-school-data`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const deletePublicSchool = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.town_id}/town-attom-school-data`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addPrivateSchool = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.town_id}/town-attom-school-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addPublicSchool = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${data.town_id}/town-attom-school-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const synchCommunity = ({townId, data}) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/towns/${townId}/attom/community`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addCommute = (data) => {
  return fetch(`${HOSTNAME}/addCommuteData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const deleteCommute = (data) => {
  return fetch(`${HOSTNAME}/deleteCommute?town_id=${parseInt(data.townId)}&id=${data.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const fetchCommute = (townId) => {
  return fetch(`${HOSTNAME}/getCommuteData?town_id=${townId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const fetchTownById = (townId) => {
  // SJ-441
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town/${townId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return {
        town: [payload.data.townData]
      }
    }).catch((error) => {
      throw error
    })
}

export const deleteTownById = (town) => {

  return fetch(`${SUBURBANJUNGLE_API_HOST}/town/${town.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const updateTownName = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/town/${data.town_id}/town-name`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addGooglePlace = (data) => {
  return fetch(`${HOSTNAME}/addPlacesForJungler`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const fetchGooglePlace = (data) => {
  return fetch(`${HOSTNAME}/google_places?lat=${data.lat}&lng=${data.lng}&type=${data.googlePlaceType}&from=jungler`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const fetchGooglePlaceData = (data) => {
  return fetch(`${HOSTNAME}/getGooglePlaces?town_id=${data.townId}&placeType=${data.placeType}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const editGooglePlace = (data) => {
  return fetch(`${HOSTNAME}/editPlacesForJungler`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addResearch = (data) => {

  const url = data.id ? `${SUBURBANJUNGLE_API_HOST}/town/${data.town_id}/external-town-research/${data.id}` : `${SUBURBANJUNGLE_API_HOST}/town/${data.town_id}/external-town-research`

  return fetch(url, {
    method: data.id ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const addResearchFile = (formData) => {
  const townId = formData.get('townId')
  const note = formData.get('note')
  const url = `${SUBURBANJUNGLE_API_HOST}/town/${townId}/external-town-research/file`
  return fetch(url, {
    method: 'POST',
    body: formData
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      const addResearchData = {
        insight_type: 'file',
        type: 'general',
        town_id: townId,
        file: payload.data.fileUrl,
        file_name: payload.data.fileName,
        note
      }
      
      return addResearch(addResearchData)

    }).catch((error) => {
      throw error
    })
}

export const deleteResearch = (data) => {

  const url = `${SUBURBANJUNGLE_API_HOST}/town/${data.town_id}/external-town-research`

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getResearch = (data) => {
  
  const url = `${SUBURBANJUNGLE_API_HOST}/town/${data.town_id}/external-town-research`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
            
      // refactor response from new api endpoint
      return {
        ...payload,
        research: payload.data
      }
    }).catch((error) => {
      throw error
    })
}
