import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from "universal-cookie"
import moment from 'moment'
import ReactModal from 'react-modal'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Config from '../../../../../../config';
import { map, size, get } from 'lodash'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}
export default class NewClientsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate:  moment().subtract(14, 'days'),
      endDate: moment(),
      selectedIndex: 'today',
      questionnaireTableData: [],
      userRole: '',
      client_strategist:[],
      client_suburb:[],
      isLoading: false,
      datePickerModel: false,
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    _user = cookies.get("user")
    if (_user) {
      const from = get(this.props, 'location.state.from', '')
      const to = get(this.props, 'location.state.to', '')
      this.setState({ isLoading: true, userRole: _user.role, fromDate: moment(from), endDate: moment(to) })
      const strData = {
        startDate     : moment(from || this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate       : moment(to || this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
        strategist_arr: this.state.client_strategist,
        suburbs       : this.state.client_suburb
      }
      this.props.getQuestionnairesCompletedClientList(strData)
      this.props.getFilterParams()

      if(from || to){
        const { location, history } = this.props
        let state = location.state
        delete state.from
        delete state.to
        delete state.dateType
        history.replace({ ...location, state })
      }
    }
  }

  componentDidUpdate(preProps, preState){
    const { QuestionnairesReportPhase, QuestionnairesData, clearReportV2Phase } = this.props
    if(QuestionnairesReportPhase === "success"){
      clearReportV2Phase()
      let data = []
      const quesData = QuestionnairesData && QuestionnairesData.data
      if(size(quesData) > 0){
        quesData && quesData.map((que, i)=>{

          let priceRange = "$"+ (que.price_min ? (que.price_min).toLocaleString() : 0) + ' - '+ (que.price_max ? (que.price_max).toLocaleString() : 0)
          if(priceRange == '$0 - 0'){
            priceRange = "$0"
          }
          data.push({
            key: i,
            ad_tracking_codes: que.ad_tracking_codes,
            booked_how: que.booked_by ? que.booked_by : 'Created in Jungler',
            client_id: que.client_id,
            client_name: que.client_name,
            completed_date: que.completed_date,
            pipeline_stage: que.pipeline_stage,
            price_range : priceRange,
            source: que.source ? que.source : '',
            region : que.suburbs.join(', '),
            strategists: que.strategists && que.strategists.join(', ')
          })
        })
      }
      this.setState({ questionnaireTableData: data, isLoading: false })
    }
    if(QuestionnairesReportPhase === "error"){
      clearReportV2Phase()
      this.setState({ questionnaireTableData: [], isLoading: false })
    }
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  handleSuburbsOption = (options, event) => {
    const { fromDate, endDate } = this.state
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ client_suburb: selectedOptions, isLoading: true })
      const strData = {
        startDate   :  moment(fromDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate     :  moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        strategist_arr : this.state.client_strategist,
        suburbs : selectedOptions
      }
      this.props.getQuestionnairesCompletedClientList(strData)
    }
  }

  handleStrOption = (options, event) => {
    const { fromDate, endDate } = this.state
    if (options) {
      const selectedOptions = map(options, option => option.value)
      this.setState({ client_strategist: selectedOptions, isLoading: true })
      const strData = {
        startDate   :  moment(fromDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate     :  moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        strategist_arr : selectedOptions,
        suburbs : this.state.client_suburb
      }
      this.props.getQuestionnairesCompletedClientList(strData)
    }
  }

   // change report by date
   filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const strData = {
      startDate     : moment(from).format("YYYY-MM-DD HH:mm:ss"),
      endDate       : moment(to).format("YYYY-MM-DD HH:mm:ss"),
      strategist_arr: this.state.client_strategist,
      suburbs       : this.state.client_suburb
    }
    this.props.getQuestionnairesCompletedClientList(strData)
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { filterParams } = this.props
    const { questionnaireTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let allData = filterParams.data
    let suburbsArray = allData && allData.suburbs

    const suburbs = map(suburbsArray, (suburb, index) => {
      return { label: suburb.name, value: suburb.id }
    });

    let allTownData = filterParams && filterParams.data && filterParams.data.towns;
    const townsArr = map(allTownData, (town, index) => {
      return { label: town.name, value: town.id }
    });

    let strData = filterParams && filterParams.data && filterParams.data.strategists
    const strategistArr = map(strData, (str, index) => {
      return { label: str.strategist_name, value: str.id }
    });

    const from = get(this.props, 'location.state.from', '')
    const to = get(this.props, 'location.state.to', '')
    const dateType = get(this.props, 'location.state.dateType', '')
    
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard {dateType}</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">New Clients <span className="reports__title__count">{questionnaireTableData && questionnaireTableData.length} <span>New Clients</span></span></h2>
                      {
                        (size(questionnaireTableData) > 0) &&
                          <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                          <ExcelSheet data={questionnaireTableData} name="Questionnaire_Report">
                              <ExcelColumn label="Client Name" value="client_name"/>
                              <ExcelColumn label="Pipeline Stage" value="pipeline_stage"/>
                              <ExcelColumn label="Price Range" value="price_range"/>
                              <ExcelColumn label="Region" value="region"/>
                              <ExcelColumn label="Strategists" value="strategists"/>
                              <ExcelColumn label="Booked How" value="booked_how"/>
                              <ExcelColumn label="Source" value="source"/>
                              <ExcelColumn label="Ad Tracking Codes" value="ad_tracking_codes"/>
                              <ExcelColumn label="Date Completed" value="completed_date"/>
                          </ExcelSheet>
                      </ExcelFile>
                      }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                </div>
                <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="assignedSuburbs"
                      placeholderButtonLabel="Select"
                      options={suburbs}
                      onChange={(event) => this.handleSuburbsOption(event, 'assignedSuburbs')}
                    />
                    <label className="multiselect__checkboxes__field__label">Suburb/City</label>
                  </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="form-group material-textfield">
                  <div className="multiselect__checkboxes__field">
                    <ReactMultiSelectCheckboxes
                      styles={multiSelectStyles}
                      width="100%"
                      name="client_strategist"
                      placeholderButtonLabel="Select"
                      options={strategistArr}
                      onChange={(event) => this.handleStrOption(event, 'assignedStrategist')}
                    />
                    <label className="multiselect__checkboxes__field__label">Strategist</label>
                  </div>
                </div>
                </div>
                <div className="col-md-4">
                   <ReportCustomeDatePicker 
                      defaultValue={ dateType || "today" }
                      defaultStart={from}
                      defaultEnd={to}
                      filterByDate={this.filterByDate} 
                   />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(questionnaireTableData) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          columns={[
                            {
                              title: 'Client Name',
                              width: 250,
                              dataIndex: 'client_name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.client_name.localeCompare(b.client_name)
                            },
                            {
                              title: 'Pipeline Stage',
                              width: 310,
                              dataIndex: 'pipeline_stage',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.pipeline_stage.localeCompare(b.pipeline_stage)
                            },
                            {
                              title: 'Price Range',
                              width: 290,
                              dataIndex: 'price_range',
                              key: '3',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.price_range.localeCompare(b.price_range)
                            },
                            {
                              title: 'Region',
                              width: 250,
                              dataIndex: 'region',
                              key: '4',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.region.localeCompare(b.region)
                            },
                            {
                              title: 'Strategist Name',
                              width: 250,
                              dataIndex: 'strategists',
                              key: '5',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.strategists.localeCompare(b.strategists)
                            },
                            {
                              title: 'Booked How',
                              width: 320,
                              dataIndex: 'booked_how',
                              key: '6',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.booked_how.localeCompare(b.booked_how)
                            },
                            {
                              title: 'Source',
                              width: 350,
                              dataIndex: 'source',
                              key: '7',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.source.localeCompare(b.source)
                            },
                            {
                              title: 'Ad Tracking Codes',
                              width: 350,
                              dataIndex: 'ad_tracking_codes',
                              key: '8',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes)
                            },
                            {
                              title: 'Date Completed',
                              width: 300,
                              dataIndex: 'completed_date',
                              key: '9',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.completed_date.localeCompare(b.completed_date)
                            },
                          ]}
                          dataSource={questionnaireTableData}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>

            </div>
          </main>
        </div>
      </div>
    )
  }
}
