import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  yourAgents: [],
  fetchYourAgentPhase: INIT,
})

export const yourAgentReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch your agnet list */
    case types.FETCH_YOUR_AGENT_LIST: {
      return state.set('error', null).set('fetchYourAgentPhase', LOADING)
    }
    case types.FETCH_YOUR_AGENT_LIST_SUCCESS: {
      const agents = get(action, 'payload.data', {})
      return state
        .set('yourAgents', agents)
        .set('error', null)
        .set('fetchYourAgentPhase', SUCCESS)
    }
    case types.FETCH_YOUR_AGENT_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchYourAgentPhase', ERROR)
    }

    case types.CLEAR_YOUR_AGENT_PHASE: {
      return state.set('fetchYourAgentPhase', INIT)
    }

    default: {
      return state
    }
  }
}
