import { Record, List } from 'immutable'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  notes: [],
  userData: [],
  closedClientData: [],
  clientStrategist: [],
  accountingReportData: [],
  adminstrationDashboardData: [],
  bdSourceReportData: [],
  userPhase: INIT,
  closedClientPhase: INIT,
  createStrategistPhase: INIT,
  fetchInteralNotePhase: INIT,
  createInteralNotePhase: INIT,
  accountingReportPhase: INIT,
  getClientStrategistPhase: INIT,
  changeClientStatusPhase: INIT,
  addUpdateStrategistPhase: INIT,
  adminstrationDashboardPhase: INIT,
  bdSourceReportPhase: INIT
})

export const strategistReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* Adminstration dashboard getting data */
    case types.GET_ADMINISTRATION_DASHBOARD_DATA: {
      return state.set('error', null).set('adminstrationDashboardPhase', LOADING)
    }
    case types.GET_ADMINISTRATION_DASHBOARD_DATA_SUCCESS: {
      return state
        .set('adminstrationDashboardData', action.payload.data)
        .set('error', null)
        .set('adminstrationDashboardPhase', SUCCESS)
    }
    case types.GET_ADMINISTRATION_DASHBOARD_DATA_ERROR: {
      return state.set('error', action.payload.error).set('adminstrationDashboardPhase', ERROR)
    }

    /* close client town  */
    case types.CLOSED_CLIENT_PER_TOWN: {
      return state.set('error', null).set('closedClientPhase', LOADING)
    }
    case types.CLOSED_CLIENT_PER_TOWN_SUCCESS: {
      return state
        .set('closedClientData', action.payload)
        .set('error', null)
        .set('closedClientPhase', SUCCESS)
    }
    case types.CLOSED_CLIENT_PER_TOWN_ERROR: {
      return state.set('error', action.payload.error).set('closedClientPhase', ERROR)
    }

    /* get account report data with data  */
    case types.GET_ACCOUNTING_REPORT: {
      return state.set('error', null).set('accountingReportPhase', LOADING)
    }
    case types.GET_ACCOUNTING_REPORT_SUCCESS: {
      return state
        .set('accountingReportData', action.payload)
        .set('error', null)
        .set('accountingReportPhase', SUCCESS)
    }
    case types.GET_ACCOUNTING_REPORT_ERROR: {
      return state.set('error', action.payload.error).set('accountingReportPhase', ERROR)
    }

    /* get user detail by user id  */
    case types.GET_USER_BY_ID: {
      return state.set('error', null).set('userPhase', LOADING)
    }
    case types.GET_USER_BY_ID_SUCCESS: {
      return state
        .set('userData', action.payload)
        .set('error', null)
        .set('userPhase', SUCCESS)
    }
    case types.GET_USER_BY_ID_ERROR: {
      return state.set('error', action.payload.error).set('userPhase', ERROR)
    }

    /* add edit strategist client data */
    case types.ADD_EDIT_STRATEGIST_CLIENT: {
      return state.set('error', null).set('addUpdateStrategistPhase', LOADING)
    }
    case types.ADD_EDIT_STRATEGIST_CLIENT_SUCCESS: {
      return state.set('error', null).set('addUpdateStrategistPhase', SUCCESS)
    }
    case types.ADD_EDIT_STRATEGIST_CLIENT_ERROR: {
      return state.set('error', action.payload.error).set('addUpdateStrategistPhase', ERROR)
    }

    /* get client strategist list wrt clinet id  */
    case types.GET_CLIENT_STRATEGIST_LIST: {
      return state.set('error', null).set('getClientStrategistPhase', LOADING)
    }
    case types.GET_CLIENT_STRATEGIST_LIST_SUCCESS: {
      return state
        .set('clientStrategist', action.payload)
        .set('error', null)
        .set('getClientStrategistPhase', SUCCESS)
    }
    case types.GET_CLIENT_STRATEGIST_LIST_ERROR: {
      return state.set('error', action.payload.error).set('getClientStrategistPhase', ERROR)
    }

    /* create strategist phase */
    case types.CREATE_STRATEGIST: {
      return state.set('error', null).set('createStrategistPhase', LOADING)
    }
    case types.CREATE_STRATEGIST_SUCCESS: {
      return state.set('error', null).set('createStrategistPhase', SUCCESS)
    }
    case types.CREATE_STRATEGIST_ERROR: {
      return state.set('error', action.payload.error).set('createStrategistPhase', ERROR)
    }

    /* client status from strategist with client details page */
    case types.CHANGE_CLIENT_STATUS: {
      return state.set('error', null).set('changeClientStatusPhase', LOADING)
    }
    case types.CHANGE_CLIENT_STATUS_SUCCESS: {
      return state.set('error', null).set('changeClientStatusPhase', SUCCESS)
    }
    case types.CHANGE_CLIENT_STATUS_ERROR: {
      return state.set('error', action.payload.error).set('changeClientStatusPhase', ERROR)
    }

    /* get account report data with data  */
    case types.GET_BDSOURCE_REPORT: {
      return state.set('error', null).set('bdSourceReportPhase', LOADING)
    }
    case types.GET_BDSOURCE_REPORT_SUCCESS: {
      return state
        .set('bdSourceReportData', action.payload)
        .set('error', null)
        .set('bdSourceReportPhase', SUCCESS)
    }
    case types.GET_BDSOURCE_REPORT_ERROR: {
      return state.set('error', action.payload.error).set('bdSourceReportPhase', ERROR)
    }

    /* clear strategist phase */
    case types.CLEAR_STRATEGIST_PHASE: {
      return state
        .set('closedClientPhase', INIT)
        .set('accountingReportPhase', INIT)
        .set('bdSourceReportPhase', INIT)
        .set('getClientStrategistPhase', INIT)
        .set('addUpdateStrategistPhase', INIT)
        .set('changeClientStatusPhase', INIT)
      }

    default: {
      return state
    }
  }
}
