import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { CloseIcon } from "../../../../components/icons"
import ReactModal from 'react-modal'
import { DatePicker } from 'antd'
import _ from "lodash";

export default class ReportDateFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      todayDate: moment().format('LL'),
      weekFirstDate: moment().startOf('week').format('LL'),
      weekLastDate: moment().endOf('week').format('LL'),
      monthFirstDate: moment().startOf('month').format('LL'),
      monthLastDate: moment().endOf('month').format('LL'),
      yearFirstDate: moment().startOf('year').format('LL'),
      yearLastDate: moment().endOf('year').format('LL'),
      datePickerModel: false,
      customFirstDate: moment(),
      customEndDate: moment(),
      oldCustomFirstDate: this.props.defaultStart || moment(),
      oldCustomEndDate: this.props.defaultEnd ||moment(),
      isSelectOpen: false,
      selectIndex: this.props.defaultValue || 'All',
      showForAll: false,
      label: "Town Assigned At"
    }
  }

  static getDerivedStateFromProps(props, state) {


    const returnObj = {
      selectIndex: _.get(props.dateValue, 'val', null)
    }

    if (state.selectIndex !== _.get(props.dateValue, 'val', null)) {

      if (_.get(props.dateValue, 'val', null) === 'custom') {
        returnObj.oldCustomFirstDate = moment(_.get(props.dateValue, 'from', null)).format("YYYY-MM-DD")
        returnObj.oldCustomEndDate = moment(_.get(props.dateValue, 'to', null)).format("YYYY-MM-DD")
      }

      return returnObj
    }
    return null;

  }

  componentDidMount() {
    if (this.props.showAll) {
      this.setState({ showForAll: true })
    } else {
      this.setState({ showForAll: false })
    }
    if (this.props.label) {
      this.setState({ label: this.props.label })
    }
  }

  openDatePickerRange = () => this.setState({ datePickerModel: !this.state.datePickerModel })

  closeCustomDate = (e) => this.setState({ datePickerModel: false, customFirstDate: "", customEndDate: "" })

  handleDateChange = (name, date) => this.setState({ [name]: date })

  submitCustomDate() {
    this.setState({
      datePickerModel: false,
      selectIndex: "custom",
      oldCustomFirstDate: moment(this.state.customFirstDate).format("YYYY-MM-DD"),
      oldCustomEndDate: moment(this.state.customEndDate).format("YYYY-MM-DD")
    }, () => this.props.filterByDate({
      from: moment(this.state.customFirstDate).format("YYYY-MM-DD"),
      to: moment(this.state.customEndDate).format("YYYY-MM-DD"),
      val: "custom"
    }))
  }

  changeDate(e) {
    const data = {}
    const val = e.target.value
    if (val === "today") {
      data.from = moment(this.state.todayDate).format("YYYY-MM-DD")
      data.to = moment(this.state.todayDate).format("YYYY-MM-DD")
      this.changeClickProp(this.state.todayDate, this.state.todayDate, val)
    } else if (val === "week") {
      data.from = moment(this.state.weekFirstDate).format("YYYY-MM-DD")
      data.to = moment(this.state.weekLastDate).format("YYYY-MM-DD")
      this.changeClickProp(this.state.weekFirstDate, this.state.weekLastDate, val)
    } else if (val === "month") {
      data.from = moment(this.state.monthFirstDate).format("YYYY-MM-DD")
      data.to = moment(this.state.monthLastDate).format("YYYY-MM-DD")
      this.changeClickProp(this.state.monthFirstDate, this.state.monthLastDate, val)
    } else if (val === 'custom') {
      // this.changeClickProp(
      //   this.state.oldCustomFirstDate,
      //   this.state.oldCustomEndDate,
      //   'custom'
      // )
      this.setState({ datePickerModel: true, customFirstDate: moment(this.state.oldCustomFirstDate), customEndDate: moment(this.state.oldCustomEndDate) })
    } else if (val === 'All') {
      data.from = moment('2010-01-01').format('YYYY-MM-DD HH:mm:ss')
      data.to = moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      this.changeClickProp(moment('2010-01-01').format('YYYY-MM-DD HH:mm:ss'), moment().month("December").add(10, 'year').endOf('month').format('YYYY-MM-DD HH:mm:ss'), val)
    } else if (val === "year") {
      data.from = moment(this.state.yearFirstDate).format("YYYY-MM-DD")
      data.to = moment(this.state.yearLastDate).format("YYYY-MM-DD")
      this.changeClickProp(this.state.yearFirstDate, this.state.yearLastDate, val)
    }
  }

  changeClickProp(from, to, val) {
    this.setState({ selectIndex: val })
    this.props.filterByDate({
      from: moment(from).format("YYYY-MM-DD"),
      to: moment(to).format("YYYY-MM-DD"),
      val
    })
  }

  disabledDate = (current) => current && current < moment().startOf('day');

  render() {
    const { todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate, showForAll, yearFirstDate, yearLastDate, label } = this.state

    return (
      <Fragment>
        <div className="form-group material-textfield">
          <select
            className="form-control custom__select material-textfield-input"
            required
            value={this.state.selectIndex}
            onChange={(e) => this.changeDate(e)}
          // onClick={this.openCustomModel}
          >
            {<option value="All">All</option>}
            <option value="today">{`Today (${todayDate})`}</option>
            <option value="week">{`This Week (${weekFirstDate} - ${weekLastDate})`}</option>
            <option value="month">{`This Month (${monthFirstDate} - ${monthLastDate})`}</option>
            <option value="year">{`This Year (${yearFirstDate} - ${yearLastDate})`}</option>
            <option value="custom">{`Custom (${moment(this.state.oldCustomFirstDate).format('LL')} - ${moment(this.state.oldCustomEndDate).format('LL')})`}</option>
          </select>
          <label className="material-textfield-label label-lg">{label}</label>
        </div>

        <ReactModal
          isOpen={this.state.datePickerModel}
          onAfterOpen={(e) => { }}
          onRequestClose={(e) => this.closeCustomDate}
          contentLabel="Custom Date"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-sm react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Custom Date</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeCustomDate}><CloseIcon /></button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">From</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        onChange={(date) => this.handleDateChange("customFirstDate", date)}
                        //disabledDate={this.disabledDate}
                        name="customFirstDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={this.state.customFirstDate}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">To</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        onChange={(date) => this.handleDateChange("customEndDate", date)}
                        //disabledDate={this.disabledDate}
                        name="customEndDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={this.state.customEndDate}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <button type="button" className="btn btn__btn btn__lg btn-dark w__100" onClick={() => this.submitCustomDate()}>Save</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    )
  }
}
