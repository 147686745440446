import React, { PureComponent /*, PropTypes*/ } from 'react';
// import IPropTypes from 'react-immutable-proptypes'
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import Dropzone from 'react-dropzone';
import GoogleDrive from './googleDrive';
import SortableComponent from './VideoSortable';
import arrayMove from 'array-move';
import axios from 'axios';
import ConfirmModal from '../../../Common/ConfirmModal';

import { Modal, notification } from 'antd';

const { confirm } = Modal;

export default class VideosComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      featuredImage: {},
      images: [],
      videos: [],
      isConfirmModal: false,
      angle: 0,
      rotateImageId: 0,
      rotate: 0,
      isDeleteModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.props.toggleLoading(true);
    const _user = get(this, 'props.user');
    const townId = get(this, 'props.match.params.townId');
    this.props.getGeneratedReport(townId);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const obj = {};
    if (get(nextProps, 'videoUploadPhase') === 'success') {
      if (nextProps.videoUpload.status) {
        nextProps.clearTownPhase();
        notification.success({
          message: 'Video uploaded successfully'
        });
        nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
      } else {
        notification.error({
          message: nextProps.videoUpload.message
        });
      }

    }

    if (get(nextProps, 'getGeneratedReportPhase') === 'success') {
      nextProps.clearTownPhase();
      nextProps.toggleLoading(false);

      obj.report = get(nextProps, 'report');

      const imgAr = orderBy(
        get(nextProps, 'report.videos', []),
        ['sort_by'],
        ['asc']
      );
      let featuredImage = {};
      const nonFeaturedImages = filter(imgAr, img => {
        if (img.featured) {
          featuredImage = img;
        } else {
          return img;
        }
      });

      obj.isLoading = false
      obj.featuredImage = featuredImage;
      obj.videos = nonFeaturedImages;
    }

    if (get(nextProps, 'featured')) {
      nextProps.clearTownPhase();
      notification.success({
        message: 'Video set as featured successfully'
      });
      obj.isLoading = false
      nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
      nextProps.toggleLoading(false);
    }

    if (get(nextProps, 'removeVideoPhase') === 'success') {
      nextProps.clearTownPhase();
      obj.isDeleteModalOpen = false;
      obj.isLoading = false;
      notification.success({
        message: 'Video deleted successfully'
      });
      nextProps.getGeneratedReport(get(nextProps, 'match.params.townId'));
    }

    return obj;
  }

  static propTypes = {
    // PropTypes go here
  };

  setFeatured = video => {
    const self = this;
    const data = {
      town_id: get(self, 'props.match.params.townId'),
      featured_video_url: get(video, 'url'),
      video_id: get(video, 'attach_id')
    };
    this.setState({ isConfirmModal: true, featuredVideoData: data });
  };

  setFeaturedVideoConfirm = () => {
    this.setState({ isLoading: true, isConfirmModal: false })
    const data = this.state.featuredVideoData;

    this.props.toggleLoading(true);

    this.props.markFeaturedVideo(data);
  };

  dragDrop = acceptFile => {
    const extensionError = () => {
      notification.error({
        message: 'Error',
        description: (
          <div>
            Please upload only <b>.mp4</b>, <b>.m4v</b> or <b>.mov</b> files
          </div>
        ),
      });
    };

    if (Array.isArray(acceptFile)) {
      if (acceptFile.length === 0) {
        extensionError();
        return;
      } else if (acceptFile[0].path) {
        const ext = acceptFile[0].path.slice(acceptFile[0].path.length - 3);
        if (ext !== 'mp4' && ext !== 'm4v' && ext !== 'mov') {
          extensionError();
          return;
        }
      }
    }

    this.props.toggleLoading(true);
    const {
      uploadVideo,
      match: {
        params: { townId }
      }
    } = this.props;

    for (let i = 0; i < acceptFile.length; i += 1) {
      const reader = new FileReader();
      reader.onload = r => {
        if (acceptFile[i]) {
          this.setState({
            previewImage: acceptFile[i].preview,
            previewStatus: 'ViewPatientOnboarding_previewShowing'
          });
          const formdata = new FormData();
          formdata.append('video', acceptFile[i]);
          formdata.append('townId', townId);
          uploadVideo(formdata);
        }
      };
      reader.readAsDataURL(acceptFile[i]);
    }
  };

  removeVideo = video => {
    const {
      getGeneratedReport,
      removeImage,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    const data = {
      town_id: townId,
      url: video.url,
      video_id: video.id
    };

    this.setState({ isDeleteModalOpen: true, selectedVideoeData: data });
  };

  removeVideoConfirm = video => {
    const {
      getGeneratedReport,
      removeVideo,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    const data = this.state.selectedVideoeData;
    this.setState({ isLoading: true });
    toggleLoading(true);

    removeVideo(data);
  };

  sendData = async (data, token) => {
    const {
      uploadVideo,
      toggleLoading,
      match: {
        params: { townId }
      }
    } = this.props;

    toggleLoading(true);

    for (let i = 0; i < data.length; i += 1) {
      if (data[i]) {
        const formdata = new FormData();
        formdata.append('video', data[i].url);
        //formdata.append('ext', 'png');
        formdata.append('mime', data[i].mimeType);
        formdata.append('townId', townId);
        formdata.append('drive', true);
        formdata.append('url', data[i].url);
        formdata.append('authToken', token);
        formdata.append('fileId', data[i].id);
        uploadVideo(formdata);
      }
    }
  };

  orderList = async ({ oldIndex, newIndex }) => {
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    const videos = this.state.videos;

    this.setState(({ videos }) => ({
      videos: arrayMove(videos, oldIndex, newIndex)
    }));
    // this.setState({ images: arrayMove(images, oldIndex, newIndex) });

    const updateVideosMain = this.state.videos.map((video, idx) => {
      this.props.updateVideo({ townId, idx, id: video.id });
    });

    await Promise.all(updateVideosMain);
  };

  checkedClick = val => {
    const {
      uploadVideo,
      match: {
        params: { townId }
      }
    } = this.props;
    if (this.state.rotateImageId === val.id) {
      const formdata = new FormData();
      formdata.append('image', this.state.base64);
      formdata.append('townId', townId);
      formdata.append('ext', 'jpeg');
      formdata.append('id', val.id);
      formdata.append('rotateImage', 'rotateImage');
      uploadVideo(formdata);
    }
  };

  render() {
    const {
      report,
      images,
      videos,
      featuredImage,
      isConfirmModal,
      isLoading,
      isDeleteModalOpen
    } = this.state;
    return (
      <div>
        {/* Delete image confirm */}
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.removeVideoConfirm}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        {/* Set as featured image confirm */}
        <ConfirmModal
          isNotDelete={true}
          closeModal={() => this.setState({ isConfirmModal: false })}
          onConfirm={this.setFeaturedVideoConfirm}
          isLoading={isLoading}
          isOpenModal={isConfirmModal}
        />

        <div className="text-right mb__15">
          <GoogleDrive key="googleDrive" sendData={this.sendData} />
        </div>
        <div className="dropzone">
          <Dropzone accept={"video/*"} multiple onDrop={e => this.dragDrop(e)}>
            {({ getRootProps, getInputProps }) => (
              <section className="upload-container">
                <div className="dropzone-inner-area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-text">
                    Upload <br /> or
                  </div>
                  <button
                    type="button"
                    className="btn btn__btn btn-dark btn-upload"
                  >
                    Drag & Drop
                  </button>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="row">
          <SortableComponent
            data={videos}
            onSortEnd={this.orderList}
            image={featuredImage}
            onRemove={value => this.removeVideo(value)}
            onFeatured={value => this.setFeatured(value)}
            checkedClick={index => this.checkedClick(index)}
            disabled={this.props.disabledSort}
          />
        </div>
      </div>
    );
  }
}
