/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, Fragment } from 'react'
import moment from 'moment'
import { Spin } from 'antd'
import { map } from 'lodash'

import { SUCCESS } from '../../../../../../../constants/phase'
import Loader from '../../../../../../../components/Loader'
import ChatContent from './ChatContent'
import { createStringAvatar } from '../../../../../../../utils/helper'

export default function chatHistory(props) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (props.agentId) {
      setLoading(true)
      props.clearAgentChat()
      const clientId = props.match.params.id
      props.fetchClientAgentChat({ clientId })
    }
  }, [props.agentId])

  // cwrp on success of fetching client agent listing and client agent chat
  useEffect(() => {
    if (props.fetchClientAgentChatPhase === SUCCESS) {
      setLoading(false)
      props.clearChatPhase()
    }
  }, [props.fetchClientAgentChatPhase])

  const userId = props.user && props.user.id
  return (
    <Fragment>
      {props.agentId && (
        <ul className="chats__wrapper__chats__list">
          {map(props.agentChats, (chat, index) => {
           return (chat.receiverIds.includes(String(props.agentId)) || parseInt(props.agentId) === chat.sender_id) ?
            <li
              key={index}
              className={`has__avatar ${
                chat.sender_id === userId ? 'active' : ''
              }`}
            >
              <div className="chats__card__header">
                <div className="chats__card__avatar">
                  {chat.sender_avatar ? <img src={chat.sender_avatar} alt="" /> : <span className="string__avatar">{createStringAvatar(chat.sender_full_name)}</span>}
                </div>
                <span className="chats__card__name">
                  {chat.sender_full_name}
                </span>
                <span className="chats__card__time">
                  {moment(chat.created_date).format('MM/DD/YY [at] hh:mm A')}
                </span>
              </div>
              <ChatContent
                index={index}
                chatHistory={props.agentChats}
                chat={chat}
              />
            </li> : ''
           } 
          )}
          {/* <button className="btn btn__view_more_chat">View More</button> */}
        </ul>
      )}
    </Fragment>
  )
}
