import React, { PureComponent /*, PropTypes*/ } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Spin, notification } from 'antd';
// import TopNavigation from '../../../../../components/TopNavigation/AdministrationTopNavigation/container';
import TopNavigation from "../../../../../components/TopNavigation/container"
import SidebarNavigation from '../../../../../components/SidebarNavigation/component'
import AdministrationSidebarNavigation from '../../../../../components/AdministrationSidebarNavigation/component';
import QuickFacts from './QuickFacts/component';
import TownLife from './TownLife/component';
import Education from './Education/component';
import TransportationCommute from './TransportationCommute/component';
import Images from './Images/component';
import Videos from './Videos/component';
import Insights from './Insights/component';
import InternalInsights from './InternalInsights/component'
import { BackArrowIcon } from '../../../../../components/icons';
import Cookies from 'universal-cookie';
import moment from 'moment';
import axios from 'axios';
import AddEditInsight from './AddEditInsight/component';
import AddEditInternalInsight from './AddEditInternalInsight/component';
import Loader from '../../../../../components/Loader';
import ValidationSchemas from '../../../../../config/validation'
const cookies = new Cookies();

import './styles.scss';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import InsightsComponent from './Insights/component';
import {getHostname, logoutInvalidRequest} from '../../../../../utils/helper';
let pre_Auth = "";
let _user = "";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;

export default class TownCharacteristicsComponent extends PureComponent {
  townTime_activity = null;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      townName: '',
      isTimerActive: false,
      isLoading: true,
      townCharacteristics: [],
      hours: 0,
      minutes: 0,
      seconds: 0,
      aiData: {}
    };
  }
  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    _user = cookies.get('user');
    let isTimerActive = false
    if ((_user && _user.role === 'strategist') || _user.role === 'admin') {
      isTimerActive = true
    }
    this.setState({ isLoading: true, isTimerActive });
    const { match, location } = this.props;
    const baseUrl = `/strategist/town-characteristics/${get(
      match,
      'params.townId'
    )}`;
    let stateObj = {
      townId: get(match, 'params.townId'),
      baseUrl,
      townName: get(location, 'state.town.name'),
      user: _user,
      disabledSort: (!_user.role || 
      _user.role !== 'strategist' && 
      _user.role !== 'admin' &&
      _user.role !== 'superadmin')
    };

    // this.fetchAItowns(_user, get(match, 'params.townId'));
    if (get(location, 'state.town')) {
      stateObj.town = get(location, 'state.town');
    }
    if (!get(location, 'state.town')) {
      this.props.fetchTownById(get(match, 'params.townId'));
    }

    // this.props.fetchTownCharacteristics()
    this.setState(stateObj);

    /*This is for town time sheet*/
    /*
    if ((_user && _user.role === 'strategist') || _user.role === 'admin') {

          this.townTime_activity = localStorage.getItem('townStrategistTime');
          let townNameFromLocal = get(location, 'state.town.name', '')
          if (!this.townTime_activity || this.townTime_activity == 'null') {
            let token = cookies.get('Authorization')

            let obj = {
              strategist_id: _user.id,
              // town_id: parseInt(townId),
              client_id: 0,
              start_date: new Date(),
              start_time: new Date(),
              activity_log: `Editing Town Report: ${townNameFromLocal}`
            };
            localStorage.setItem('townStrategistTime', Date.now());
            this.townTime_activity = Date.now();
            axios({
              method: 'POST',
              url: HOSTNAME + '/user/add_client_strategist_timesheet',
              data: obj,
              headers: {
                Authorization: token
              }
            }).then(res => {
              if (res.data && res.data && res.data.status) {
                localStorage.setItem('townTimeId', res.data.data[0]);
              } else {
                localStorage.setItem('strategistTime', 'null');
                this.townTime_activity = 'null';
              }
            }).catch(logoutInvalidRequest);
          }


    }
    */
   
    /*
    if (this.townTime_activity && this.townTime_activity !== 'null') {
      this.startInterval();
    }
    */
  }

  componentWillUnmount() {
    // clearInterval(this.intervalID);
    /*
    let token = cookies.get('Authorization')
    let _user = cookies.get('user');

    if(token) {
      let townTimeId = localStorage.getItem('townTimeId');
      let obj = {
        id: townTimeId,
        strategist_id: _user.id,
        // town_id: parsid: townTimeIdeInt(townId),
        client_id: 0,
        start_date: new Date(),
        start_time: new Date(),
        activity_log: `Editing Town Report: (${this.state.town_name})`
      }
      axios({
        method: 'POST',
        url: HOSTNAME + '/user/add_client_strategist_timesheet',
        data: obj,
        headers: {
          Authorization: token
        }
      }).then(res => {
        if (res.data && res.data && res.data.status) {
          localStorage.setItem('townStrategistTime', 'null');
          localStorage.setItem('townTimeId', null);
          this.townTime_activity = 'null';
          this.forceUpdate();
        } else {
          localStorage.setItem('townStrategistTime', 'null');
          localStorage.setItem('townTimeId', null);
          this.townTime_activity = 'null';
        }
      }).catch(logoutInvalidRequest);
    }
    */
  }

  /*
  fetchAItowns(user, townId) {
    let data = { filters: [], user_id: user.id, townId: parseInt(townId) };
    axios({
      method: 'POST',
      url: HOSTNAME + '/users/fetch_ai_towns',
      data: data
    }).then(resData => {
      if (resData.data.data) {
        let mainData = get(resData, 'data.data[0]', []);
        this.setState({ aiData: mainData, isDataLoading: false });
      }
    }).catch(logoutInvalidRequest);
  }
  */

  startInterval = () => {
    this.intervalID = setInterval(() => {
      var a = new Date();
      var b = new Date(parseInt(this.townTime_activity));

      a = moment.tz(a, _user.time_zone);
      b = moment.tz(b, _user.time_zone);

      var diff = Math.abs(a - b) / 1000;
      // get hours
      var hours = Math.floor(diff / 3600) % 24;
      // get minutes
      var minutes = Math.floor(diff / 60) % 60;

      minutes =
        parseInt(minutes) < 10 ? '0' + parseInt(minutes) : parseInt(minutes);
      // get seconds
      var seconds = diff % 60;
      seconds =
        parseInt(seconds) < 10 ? '0' + parseInt(seconds) : parseInt(seconds);
      this.setState({
        hours: parseInt(hours),
        minutes: minutes,
        seconds: seconds
      });
      if (parseInt(minutes) === 10) {
        this.stopTime('');
      }
    }, 1000);
  };

  startTime(data) {
    /*
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    let token = cookies.get('Authorization')

    let obj = {
      strategist_id: _user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: `Editing Town Report: (${this.state.townName})`
    };
    localStorage.setItem('townStrategistTime', Date.now());
    this.townTime_activity = Date.now();
    // this.startInterval();
    this.forceUpdate();
    axios({
      method: 'POST',
      url: HOSTNAME + '/user/add_client_strategist_timesheet',
      data: obj,
      headers: {
        Authorization: token
      }
    }).then(res => {
      if (res.data && res.data && res.data.status) {
        localStorage.setItem('townTimeId', res.data.data[0]);
      } else {
        localStorage.setItem('townStrategistTime', 'null');
        this.townTime_activity = 'null';
      }
    }).catch(logoutInvalidRequest);
    */
  }

  stopTime(data) {
    /*
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    // clearInterval(this.intervalID);
    let townTimeId = localStorage.getItem('townTimeId');
    let _user = cookies.get('user');
    let token = cookies.get('Authorization')
    let obj = {
      id: townTimeId,
      strategist_id: _user.id,
      // town_id: parseInt(townId),
      client_id: 0,
      end_date: new Date(),
      end_time: new Date(),
      status: 1
    };
    axios({
      method: 'POST',
      url: HOSTNAME + '/user/add_client_strategist_timesheet',
      data: obj,
      headers: {
        Authorization: token
      }
    }).then(res => {
      if (res.data && res.data && res.data.status) {
        localStorage.setItem('townStrategistTime', 'null');
        localStorage.setItem('townTimeId', null);
        this.townTime_activity = 'null';
        this.forceUpdate();
      } else {
        localStorage.setItem('townStrategistTime', 'null');
        localStorage.setItem('townTimeId', null);
        this.townTime_activity = 'null';
      }
    }).catch(logoutInvalidRequest);
    */
  }

  updateAiData = async data => {
    let aiData = this.state.aiData;

    let ar = [];
    // Set object
    if (data && !isEmpty(data)) {
      let setObject = Object.keys(data).map((k, i) => {
        if (!isNil(data[k]) && aiData[k] !== data[k]) {
          let obj = {};
          obj.key = k;
          obj.value = String(data[k]);
          ar.push(obj);
        }
      });

      await Promise.all(setObject);
    }


    this.setState({ isLoading: true });
    let obj = {
      customFields: ar
    };

    obj.user_id = this.state.user.id;
    obj.town_id = parseInt(this.state.townId);

    if (!isEmpty(obj.customFields)) {
      axios({
        method: 'POST',
        url: HOSTNAME + '/users/update_ai_towns',
        data: obj
      }).then(resData => {
        if (!get(resData, 'data.status')) {
          this.setState({ isLoading: false });
          notification['error']({
            message: 'An error occured',
            description: get(resData, 'data.message')
          });
        } else {
          notification['success']({
            message: 'Updated Successfully!'
          });
          this.setState({ isLoading: false });

          // this.fetchAItowns(this.state.user, this.state.townId);
        }
      }).catch(logoutInvalidRequest);
    } else {
      this.setState({
        isLoading: false
      });
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    let obj = {};
    if (get(nextProps, 'fetchTownByIdPhase') === 'success') {
      nextProps.clearTownPhase();
      obj.town = get(nextProps, 'fetchTownByIdData.town[0]', {});
      obj.townName = get(nextProps, 'fetchTownByIdData.town[0].name', {});
    }

    if (get(nextProps, 'fetchTownCharacteristicsPhase') === 'success') {
      nextProps.clearTownPhase();
      obj.townCharacteristics = [];
    }

    if (get(nextProps, 'updateTownCharacteristicsPhase') === 'success') {
      nextProps.clearTownPhase();
    }

    return obj;
  }

  toggleLoading = val => {
    this.setState({ isLoading: val });
  };

  authClick(event) {
    let pre_Auth = cookies.get("i_user");
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get("i_user");
    let authorization = cookies.get("i_Authorization");
    localStorage.clear();
    let expires = new Date();
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth() + 2);
    expires = new Date(expires);
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set("user", JSON.stringify(user), { path: "/", expires });

    document.cookie =
      "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if (pre_Auth.role === "superadmin") {
      this.props.history.push({ pathname: "/dashboard" });
    }
    if (pre_Auth.role === "admin" || pre_Auth.role === "strategist") {
      this.props.history.push({ pathname: "/home/new-clients" });
    }
  }

  render() {
    const { match, user } = this.props;
    const {
      townId,
      baseUrl,
      town,
      townName,
      hours,
      minutes,
      seconds,
      isLoading,
      townCharacteristics,
      aiData,
      isTimerActive
    } = this.state;

    let _user = cookies.get('user');
    let pre_Auth = cookies.get("i_user");

    let activeTab = get(match, 'params.characteristicName');
    return (
      <div>
        <div className="layout-has-sidebar">
        {
            _user.role == "business development" || _user.role == "local"?
            <SidebarNavigation {...this.props}/>:
            <AdministrationSidebarNavigation {...this.props} />
          }
          {pre_Auth ? (
          <div className="strategist_top">
            You are impersonating{" "}
            {"( " + _user.first_name + " " + _user.last_name + " ) "}
            <a onClick={this.authClick.bind(this)}>
              Click Here to go back to Jungler
            </a>
          </div>
          ) : (
            ""
          )}
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-12 col-sm-5 col-md-4">
                    <button
                      onClick={() =>
                        this.props.history.push('/strategist/town-reports')
                      }
                      className="btn btn-back"
                    >
                      <BackArrowIcon className="arrow-icon" />
                      Back to Towns
                    </button>
                  </div>
                  <div className="col-xs-12 col-sm-7 col-md-8">

                    <div className="town__characteristics__cta">


                      {/*

                      {_user &&
                        _user.role && 
                       (_user.role === 'admin' || _user.role === 'strategist') && 
                       this.townTime_activity === 'null' ? ( 
                         <button 
                           className="btn btn__btn btn__start__time" 
                           onClick={this.startTime.bind(this)} 
                         > 
                           <img src="img/playbutton.svg" /> Start 
                         </button> 
                       ) : ( 
                         '' 
                       )} 
                       {_user && 
                       _user.role && 
                       (_user.role === 'admin' || _user.role === 'strategist') && 
                       this.townTime_activity !== 'null' ? ( 
                         <button 
                           className="btn btn__btn btn__stop__time" 
                           onClick={this.stopTime.bind(this)} 
                         > 
                           <img src="img/stopbutton.svg" /> Stop ({hours}: 
                           {minutes}:{seconds}) 
                         </button> 
                       ) : ( 
                         '' 
                       )} 

                       */}

                      {
                        _user.role == "business development" || _user.role == "local"?
                        null: 
                        <div>
                          <button
                            onClick={() =>
                              this.props.history.push(`${baseUrl}/insights/add`)
                            }
                            className="btn btn__btn btn-dark w__180 ml__15"
                            >
                            Add External Insight
                          </button>
                          {/* 
                            <button 
                             onClick={() => 
                               this.props.history.push(`${baseUrl}/internal-insights/add`) 
                             } 
                             className="btn btn__btn btn-dark w__180 ml__15" 
                           > 
                             Add Internal Insight 
                           </button> 
                          
                          */}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">{townName}</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  {
                    _user.role == "business development" || _user.role == "local"?
                    <ul className="tabs__control__links">
                    <li>
                      <Link
                        className={`tabs__link ${!activeTab && 'active'}`}
                        to={`${baseUrl}`}
                      >
                        Images
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`tabs__link ${activeTab === 'videos' &&
                          'active'}`}
                        to={`${baseUrl}/videos`}
                      >
                        Videos
                      </Link>
                    </li>
                  </ul>
                    :
                  <ul className="tabs__control__links">
                    <li>
                      <Link
                        className={`tabs__link ${!activeTab && 'active'}`}
                        to={`${baseUrl}`}
                      >
                        Quick Facts
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`tabs__link ${activeTab === 'education' &&
                        'active'}`}
                        to={`${baseUrl}/education`}
                      >
                        Education
                      </Link>
                    </li>




                    {/*
                     <li> 
                       <Link 
                         className={`tabs__link ${activeTab === 'town-life' && 
                           'active'}`} 
                         to={`${baseUrl}/town-life`} 
                       > 
                         Town Life 
                       </Link> 
                     </li> 
                     <li> 
                       <Link 
                         className={`tabs__link ${activeTab === 'education' && 
                           'active'}`} 
                         to={`${baseUrl}/education`} 
                       > 
                         Education 
                       </Link> 
                     </li> 
                     <li> 
                       <Link 
                         className={`tabs__link ${activeTab === 
                           'transportation-commute' && 'active'}`} 
                         to={`${baseUrl}/transportation-commute/characteristics`} 
                       > 
                         Transportation / Commute 
                       </Link> 
                     </li>
                    */}




                    <li>
                      <Link
                        className={`tabs__link ${activeTab === 'images' &&
                          'active'}`}
                        to={`${baseUrl}/images`}
                      >
                        Images
                      </Link>
                    </li>


                    {/*
                    <li> 
                       <Link 
                         className={`tabs__link ${activeTab === 'videos' && 
                           'active'}`} 
                         to={`${baseUrl}/videos`} 
                       > 
                         Videos 
                       </Link> 
                     </li> 
                    */}



                    <li>
                      <Link
                        className={`tabs__link ${activeTab === 'insights' &&
                          'active'}`}
                        to={`${baseUrl}/insights`}
                      >
                       External Insights
                      </Link>
                    </li>


                    {/* 
                   
                    <li> 
                       <Link 
                         className={`tabs__link ${activeTab === 'internal-insights' && 
                           'active'}`} 
                         to={`${baseUrl}/internal-insights`} 
                       > 
                        Internal Insights 
                       </Link> 
                     </li>
                  
                    */}
                  </ul>
                }

                  {/*<Spin*/}
                  {/*  size="large"*/}
                  {/*  spinning={isLoading}*/}
                  {/*  indicator={<Loader />}*/}
                  {/*>*/}
                    {
                      _user.role == "business development" || _user.role == "local"?
                      <Switch>
                        <Route exact path={`${baseUrl}`}>
                          <Images
                            town={town}
                            townCharacteristics={townCharacteristics}
                            baseUrl={baseUrl}
                            aiData={aiData}
                            isTimerActive={isTimerActive}
                            townTime_activity={this.townTime_activity}
                            ValidationSchemas={ValidationSchemas}
                            startTime={this.startTime}
                            updateAiData={this.updateAiData}
                            toggleLoading={this.toggleLoading}
                            disabledSort = {this.state.disabledSort}
                            {...this.props}
                          />
                        </Route>
                        <Route exact path={`${baseUrl}/videos`}>
                          <Videos
                            town={town}
                            townCharacteristics={townCharacteristics}
                            baseUrl={baseUrl}
                            aiData={aiData}
                            isTimerActive={isTimerActive}
                            townTime_activity={this.townTime_activity}
                            ValidationSchemas={ValidationSchemas}
                            startTime={this.startTime}
                            updateAiData={this.updateAiData}
                            toggleLoading={this.toggleLoading}
                            disabledSort = {this.state.disabledSort}
                            {...this.props}
                          />
                        </Route>
                      </Switch>
                      :
                    <Switch>
                        <Route exact path={baseUrl}>
                          <QuickFacts
                            town={town}
                            townCharacteristics={townCharacteristics}
                            baseUrl={baseUrl}
                            updateAiData={this.updateAiData}
                            aiData={aiData}
                            isTimerActive={isTimerActive}
                            townTime_activity={this.townTime_activity}
                            startTime={this.startTime}
                            toggleLoading={this.toggleLoading}
                            {...this.props}
                          />
                        </Route>
                      <Route path={`${baseUrl}/town-life`}>
                        <TownLife
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          aiData={aiData}
                          townTime_activity={this.townTime_activity}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                      <Route path={`${baseUrl}/education/:type?`}>
                        <Education
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          aiData={aiData}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                      <Route path={`${baseUrl}/transportation-commute`}>
                        <TransportationCommute
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          aiData={aiData}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                      <Route exact path={`${baseUrl}/images`}>
                        <Images
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          aiData={aiData}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          disabledSort={this.state.disabledSort}
                          {...this.props}
                        />
                      </Route>
                      <Route exact path={`${baseUrl}/videos`}>
                        <Videos
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          aiData={aiData}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          disabledSort = {this.state.disabledSort}
                          {...this.props}
                        />
                      </Route>
                      <Route exact path={`${baseUrl}/insights`}>
                        <Insights
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          ValidationSchemas={ValidationSchemas}
                          aiData={aiData}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                       <Route exact path={`${baseUrl}/internal-insights`}>
                        <InternalInsights
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          ValidationSchemas={ValidationSchemas}
                          aiData={aiData}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route> 
                      <Route exact path={`${baseUrl}/internal-insights/:type`}>
                        <AddEditInternalInsight
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          aiData={aiData}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                      <Route exact path={`${baseUrl}/insights/:type`}>
                        <AddEditInsight
                          town={town}
                          townCharacteristics={townCharacteristics}
                          baseUrl={baseUrl}
                          isTimerActive={isTimerActive}
                          townTime_activity={this.townTime_activity}
                          aiData={aiData}
                          ValidationSchemas={ValidationSchemas}
                          startTime={this.startTime}
                          updateAiData={this.updateAiData}
                          toggleLoading={this.toggleLoading}
                          {...this.props}
                        />
                      </Route>
                    </Switch>
                    }
                  {/*</Spin>*/}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
