import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from "lodash";
// import PropTypes from 'prop-types'
import styles from './styles.scss'
import { TownsIcon, TownsColorIcon, DashboardIcon, MylistingIcon, LeadsIcon, DashboardColorIcon, LeadsColorIcon, MylistingColorIcon } from '../icons'
import Cookies from 'universal-cookie'

const cookies = new Cookies() // Import css
let _user = ''

const routeTab = {
  "/agent/dashboard": 0,
  "/agent/clients/all-clients": 1,
  "/agent/clients/active-clients": 1,
  "/agent/clients/closed-clients": 1,
  "/agent/task-list/new-never-touched": 2,
  "/agent/task-list/not-touched-in-4-weeks": 2,
  "/agent/task-list/interested-but-don’t-want-town-tour-yet": 2,
  "/strategist/town-reports": 2,
  "/strategist/town-characteristics/": 2
}

export default class SidebarNavigationComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      loginUserId: 0,
      activeTab: 0
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    const currentUrl = this.props.location && this.props.location.pathname && this.props.location.pathname.split(`/`)
    if (!_.isEmpty(this.props)) {
      if (currentUrl[2] === "client-details") {
        this.setState({ activeTab: 1})
      }else if(currentUrl[2] === "town-characteristics"){
        this.setState({ activeTab: 2})
      }
       else {
        this.setState({ activeTab: routeTab[this.props.location.pathname]}, ()=>{ })
      }
    }

    if(_user && ((_user.role) === "agent") || (_user.role) === "local" || (_user.role) === "business development"){
      let localData = JSON.parse(localStorage.getItem('agentV2clientSections'))
      if(localData){
        setTimeout(() => {
          if(this.state.activeTab === 0 || this.state.activeTab === 2){
            let agentData = {
              "sort": localData.sort,
              "sort_value": localData.sort_value,
              "limit": localData.limit,
              "offset": localData.offset,
              "No Follow Up": localData["No Follow Up"],
              "No Status":  localData["No Status"],
              "No Town Tour": localData["No Town Tour"],
              "Overdue Followups": localData["Overdue Followups"],
              "status": localData.status,
              "strategist": localData.strategist,
              "filterCount": localData.filterCount,
              "allOffset": 1,
              "activeOffset": 1,
              "closedffset": 1,
            }
            localStorage.setItem('agentV2clientSections', JSON.stringify(agentData))
          }
        }, 500)
      }
    }
  }

  render() {
    const {user} = this.props;

    return (
      <aside id="sidebar-layout" className="sidebar-layout-sider">
        {
          (_user && (_user.role) === "agent") ?
          <div className="sidebar-layout-sider__children">
            <div className="sidebar-layout-sider__logo">
              <Link className="sidebar-item__link" to={'/agent/dashboard'}><img src="/img/logo.svg" alt=""/></Link>
            </div>
            <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
              <li className={this.state.activeTab === 0 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                <Link className="sidebar-item__link" to={'/agent/dashboard'}><span className="sidebar-item__icon">{this.state.activeTab === 0 ? <DashboardColorIcon/> : <DashboardIcon/>}</span> Dashboard</Link>
              </li>
              <li className={this.state.activeTab === 1 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                <Link className="sidebar-item__link" to={'/agent/clients/all-clients'}><span className="sidebar-item__icon">{this.state.activeTab === 1 ? <LeadsColorIcon/> : <LeadsIcon/>}</span> Clients</Link>
              </li>
              {/*<li className={this.state.activeTab === 2 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>*/}
              {/*    <Link className="sidebar-item__link" to={'/agent/task-list/new-never-touched'}><span className="sidebar-item__icon">{this.state.activeTab === 2 ? <MylistingColorIcon/> : <MylistingIcon/>}</span> Task List</Link>*/}
              {/*</li>*/}
            </ul>
          </div>
          :
          <div>
            {
              ((_user && (_user.role) === "local"))?
              <div className="sidebar-layout-sider__children">
                <div className="sidebar-layout-sider__logo">
                  <Link className="sidebar-item__link" to={'/agent/dashboard'}><img src="/img/logo.svg" alt=""/></Link>
                </div>
                <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
                  <li className={this.state.activeTab === 1 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                    <Link className="sidebar-item__link" to={'/agent/clients/all-clients'}><span className="sidebar-item__icon">{this.state.activeTab === 1 ? <LeadsColorIcon/> : <LeadsIcon/>}</span> Clients</Link>
                  </li>
                  <li className={this.state.activeTab === 2 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                    <Link className="sidebar-item__link" to={'/strategist/town-reports'}><span className="sidebar-item__icon">{this.state.activeTab === 2 ? <TownsColorIcon/> : <TownsIcon/>}</span> Towns</Link>
                  </li>
                </ul>
              </div>:
              (_user && (_user.role) === "business development")?
              <div className="sidebar-layout-sider__children">
                <div className="sidebar-layout-sider__logo">
                  <Link className="sidebar-item__link" to={'/agent/dashboard'}><img src="/img/logo.svg" alt=""/></Link>
                </div>
                <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
                  <li className={this.state.activeTab === 0 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                    <Link className="sidebar-item__link" to={'/agent/dashboard'}><span className="sidebar-item__icon">{this.state.activeTab === 0 ? <DashboardColorIcon/> : <DashboardIcon/>}</span> Dashboard</Link>
                  </li>
                  <li className={this.state.activeTab === 1 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                    <Link className="sidebar-item__link" to={'/agent/clients/all-clients'}><span className="sidebar-item__icon">{this.state.activeTab === 1 ? <LeadsColorIcon/> : <LeadsIcon/>}</span> Clients</Link>
                  </li>
                  <li className={this.state.activeTab === 2 ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                    <Link className="sidebar-item__link" to={'/strategist/town-reports'}><span className="sidebar-item__icon">{this.state.activeTab === 2 ? <TownsColorIcon/> : <TownsIcon/>}</span> Towns</Link>
                  </li>
                </ul>
            </div>:
            <div></div>
            }
          </div>
        }
      </aside>
    )
  }
}
