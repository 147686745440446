import { connect } from 'react-redux'

import CalendarComponent from './component'
import { fetchUser, clearPhase as userClearPhase } from '../../../../store/user/duck'
import {
  getAppointments,
  deleteAppointment,
  getAgentAvailability,
  getAvailability,
  getAgentTimeSlotAvailability,
  updateAgentTimeSlotAvailability,
  clearPhase,
  // sync google data for calendar
  getGoogleAuthentication,
  getOutlookAuthentication,
  unsyncCalendarEvent,
  clearAllagentLogedData
} from '../../../../store/agent/duck'

const CalendarContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    appointmentData: state.agent.appointmentData,
    deleteAppointmentPhase: state.agent.deleteAppointmentPhase,
    addAppointmentPhase: state.agent.addAppointmentPhase,
    // get avilable data
    getAvilData: state.agent.getAvilData,
    getAvilPhase: state.agent.getAvilPhase,
    // get avability data
    agentTimeAvailability: state.agent.agentTimeAvailability,
    getAgentTimeAvailabilityPhase: state.agent.getAgentTimeAvailabilityPhase,
    updateAgentTimeAvailabilityPhase: state.agent.updateAgentTimeAvailabilityPhase,
    unsyncCalendarEventPhase: state.agent.unsyncCalendarEventPhase
  }),
  // Map actions to dispatch and props
  {
    fetchUser,
    clearPhase,
    userClearPhase,
    getAvailability,
    // get agent multiple time availabiltity
    getAgentTimeSlotAvailability,
    updateAgentTimeSlotAvailability,
    getAppointments,
    deleteAppointment,
    getAgentAvailability,
    // google calendar
    getGoogleAuthentication,
    getOutlookAuthentication,
    // unsync calendar
    unsyncCalendarEvent,
    clearAllagentLogedData
  }
)(CalendarComponent)

export default CalendarContainer
