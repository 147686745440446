import { connect } from 'react-redux'

import ClientsAssignedPerTownComponent from './component'
import { getClientsPerAgent, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const ClientsAssignedPerTownContainer = connect(
  // Map state to props
  (state) => ({
    clientsPerAgentsPhase: state.reportV2.clientsPerAgentsPhase,
    clientsPerAgentsData: state.reportV2.clientsPerAgentsData,
  }),
  // Map actions to dispatch and props
  {
    getClientsPerAgent,
    clearReportV2Phase
  }
)(ClientsAssignedPerTownComponent)

export default ClientsAssignedPerTownContainer
