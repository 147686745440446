import React from 'react'
import ReactExport from "react-data-export"
import { ExportIcon, CloseIcon } from "../icons"
import ReactModal from 'react-modal'
import { Spin } from 'antd'
import Loader from '../Loader'
import moment from 'moment'
import './styles.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const mapping = data => data.map(i => ({ ...i, follow_up_date: (i.follow_up_date || []).map(j => moment(j.follow_up_date).format('MM/DD/YYYY')).toString() }))


export default ({ data = [], hide, disabled, dataSource, filterCount }) => {

    const [ showWarning, setShowWarning ] = React.useState(false)
    const [ loading, setLoading ] = React.useState(false)
    const [ local_data, setLocalData ] = React.useState([])
    const excelData = mapping(dataSource ? local_data : data)
    
    const openWarning = () => {
        setShowWarning(true)
    }

    const closeWarning = () => {
        setShowWarning(false)
    }

    const fetchLocaldata = async() => {
      setLoading(true)
      const data = await dataSource()
      setLocalData(data.users)
      if(data.users.length){
        setTimeout(()=>{
            document.getElementById("excel-btn").click()
            setLoading(false)
            setTimeout(() => setLocalData([]), 50)
        },100)
      }
      else setLoading(false)
    }

    if(hide)return null

    return (<Spin size="large" spinning={loading} indicator={<Loader />}>
                {
                    disabled || (dataSource && local_data.length === 0) ? 
                    <>
                        <button onClick = { disabled ? openWarning : fetchLocaldata } className={`btn btn__btn btn__export ${disabled && ' disable-opacity'}`}>
                            <ExportIcon className="mr__15"/> 
                            Export
                        </button>
                        <ReactModal
                            isOpen={(showWarning)}
                            onRequestClose={closeWarning}
                            portalClassName="react-modal"
                        >
                            <div className="react-modal-dialog react-modal-dialog-centered">
                                <div className="react-modal-header no-border">
                                <button type="button" className="btn react-modal-close" onClick={closeWarning}><CloseIcon /></button>
                                </div>
                                <div className="react-modal-body react-modal-body-sm no-padding-top">
                                    <p className="react-modal-subtitle mb__3 copy-email-msg">
                                        To export client records in CSV, you must first apply a filter.
                                    </p>
                                </div>
                            </div>
                        </ReactModal>
                    </>
                    : 
                    <div>
                        <ExcelFile 
                            element={
                                <button id="excel-btn" className="btn btn__btn btn__export">
                                    <ExportIcon className="mr__15"/> 
                                    Export
                                </button>
                            }
                        >
                            <ExcelSheet data={excelData} name="clients">
                                <ExcelColumn label="Client Name" value="client_full_name" />
                                <ExcelColumn label="Strategist" value="strategist_name" />
                                <ExcelColumn label="Budget" value="price_range" />
                                <ExcelColumn label="Status" value="status" />
                                <ExcelColumn label="Created Date" value="created" />
                                <ExcelColumn label="Follow Up Date" value="follow_up_date" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                }
            </Spin>
    )
}