import * as types from './action-types'

// fetch prospectus list
export const fetchFollowUpDate = (payload) => ({
  type: types.FETCH_FOLLOW_UP_DATE,
  payload,
})
// create new prospectus
export const updateFollowUpDate = (payload) => ({
  type: types.UPDATE_FOLLOW_UP_DATE,
  payload,
})
// clear prospectus phase
export const clearFollowUpPhase = (payload) => ({
  type: types.CLEAR_FOLLOW_UP_PHASE,
  payload,
})
