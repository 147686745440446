import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  addUpdateClientError: '',
  clientDetail: [],
  clientStrategistBDList: [],
  strategistBDList: [],
  devBizList: [],
  clientData: [],
  fetchReviewPhase: INIT,
  filterClientsPhase: INIT,
  fetchClientByIdPhase: INIT,
  createBizDevPhase: INIT,
  addUpdateClientPhase: INIT,
  fetchStrategistBDPhase: INIT,
  fetchStrategistBizDevPhase: INIT,
  fetchClientStrategistBDPhase: INIT,
  fetchActivityHistoryPhase: INIT,
  editClientInformationPhase: INIT,
  createRefferingClientPhase: INIT,
  addUpdateClientErrorPhase: INIT,
  fetchClientDetailForDashBoardPhase: INIT,
})
export const clientsReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  get client  details by client id */
    case types.FETCH_CLIENT_BY_ID: {
      return state.set('error', null).set('fetchClientByIdPhase', LOADING)
    }
    case types.FETCH_CLIENT_BY_ID_SUCCESS: {
      const client = get(action, 'payload.data', {})
      return state
        .set('clientDetail', client)
        .set('error', null)
        .set('fetchClientByIdPhase', SUCCESS)
        .set('fetchClientDetailForDashBoardPhase', SUCCESS)
        .set('fetchActivityHistoryPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_BY_ID_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientByIdPhase', ERROR)
    }

    /* fetch client stratgitst dev biz list  */
    case types.FETCH_CLIENT_STRATEGIST_BD_LIST: {
      return state.set('error', null).set('fetchClientStrategistBDPhase', LOADING)
    }
    case types.FETCH_CLIENT_STRATEGIST_BD_LIST_SUCCESS: {
      const client = get(action, 'payload.data', {})
      return state
        .set('clientStrategistBDList', client)
        .set('error', null)
        .set('fetchClientStrategistBDPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_STRATEGIST_BD_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientStrategistBDPhase', ERROR)
    }

    /* fetch strategist dev biz list */
    case types.FETCH_STRATEGIST_BD_LIST: {
      return state.set('error', null).set('fetchStrategistBDPhase', LOADING)
    }
    case types.FETCH_STRATEGIST_BD_LIST_SUCCESS: {
      const client = get(action, 'payload.data', {})
      return state
        .set('strategistBDList', client)
        .set('error', null)
        .set('fetchStrategistBDPhase', SUCCESS)
    }
    case types.FETCH_STRATEGIST_BD_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchStrategistBDPhase', ERROR)
    }

    /* fetch strategist dev biz list */
    case types.FETCH_STRATEGIST_DEV_BIZ_LIST: {
      return state.set('error', null).set('fetchStrategistBizDevPhase', LOADING)
    }
    case types.FETCH_STRATEGIST_DEV_BIZ_LIST_SUCCESS: {
      const client = get(action, 'payload.data', {})
      return state
        .set('devBizList', client)
        .set('error', null)
        .set('fetchStrategistBizDevPhase', SUCCESS)
    }
    case types.FETCH_STRATEGIST_DEV_BIZ_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchStrategistBizDevPhase', ERROR)
    }

    /* create client strategist biz dev */
    case types.CREATE_CLIENT_STRATEGIST_BIZ_DEV: {
      return state.set('error', null).set('createBizDevPhase', LOADING)
    }
    case types.CREATE_CLIENT_STRATEGIST_BIZ_DEV_SUCCESS: {
      return state.set('error', null).set('createBizDevPhase', SUCCESS)
    }
    case types.CREATE_CLIENT_STRATEGIST_BIZ_DEV_ERROR: {
      return state.set('error', action.payload.error).set('createBizDevPhase', ERROR)
    }

    /*  add update client details for review and source */
    case types.ADD_UPDATE_CLIENT: {
      return state.set('error', null).set('addUpdateClientPhase', LOADING)
    }
    case types.ADD_UPDATE_CLIENT_SUCCESS: {
      const { payload } = action
      if (payload.status === false) {
        return state
          .set('error', null)
          .set('addUpdateClientPhase', SUCCESS)
          .set('addUpdateClientErrorPhase', SUCCESS)
          .set('addUpdateClientError', payload.message)
      }
      return state.set('error', null).set('addUpdateClientPhase', SUCCESS)
    }
    case types.ADD_UPDATE_CLIENT_ERROR: {
      return state.set('error', action.payload.error).set('addUpdateClientPhase', ERROR)
    }

    /* create reffering client */
    case types.CREATE_REFFERING_CLIENT: {
      return state.set('error', null).set('createRefferingClientPhase', LOADING)
    }
    case types.CREATE_REFFERING_CLIENT_SUCCESS: {
      return state.set('error', null).set('createRefferingClientPhase', SUCCESS)
    }
    case types.CREATE_REFFERING_CLIENT_ERROR: {
      return state.set('error', action.payload.error).set('createRefferingClientPhase', ERROR)
    }

    /* create reffering client */
    case types.FETCH_REIVEW_SUCCESS: {
      return state.set('error', null).set('fetchReviewPhase', SUCCESS)
    }
    case types.CLEAR_REIVEW_PHASE: {
      return state.set('error', null).set('fetchReviewPhase', INIT)
    }

    /* fetch filter client data */
    case types.FETCH_FILTER_CLIENT_DATA: {
      return state.set('error', null).set('filterClientsPhase', LOADING)
    }
    case types.FETCH_FILTER_CLIENT_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('clientData', payload)
        .set('filterClientsPhase', SUCCESS)
    }
    case types.FETCH_FILTER_CLIENT_DATA_ERROR: {
      return state.set('error', action.payload.error).set('filterClientsPhase', ERROR)
    }

    /* update basic information for client details */
    case types.EDIT_CLIENT_INFORMATION: {
      return state.set('error', null).set('editClientInformationPhase', LOADING)
    }
    case types.EDIT_CLIENT_INFORMATION_SUCCESS: {
      return state.set('error', null).set('editClientInformationPhase', SUCCESS)
    }
    case types.EDIT_CLIENT_INFORMATION_ERROR: {
      return state.set('error', action.payload.error).set('editClientInformationPhase', ERROR)
    }

    case types.FETCH_CLIENT_DETAIL_FOR_DASH_BOARD_PHASE: {
      return state.set('error', null).set('fetchClientDetailForDashBoardPhase', ERROR)
    }

    case types.CLEAR_ACTIVITY_HISTORY_PHASE: {
      return state.set('error', null).set('fetchActivityHistoryPhase', INIT)
    }

    /* clear client phase */
    case types.CLEAR_CLIENT_PHASE: {
      return state
        .set('filterClientsPhase', INIT)
        .set('fetchClientByIdPhase', INIT)
        .set('fetchClientStrategistBDPhase', INIT)
        .set('fetchStrategistBDPhase', INIT)
        .set('fetchStrategistBizDevPhase', INIT)
        .set('createBizDevPhase', INIT)
        .set('addUpdateClientPhase', INIT)
        .set('addUpdateClientError', INIT)
        .set('addUpdateClientErrorPhase', INIT)
        .set('editClientInformationPhase', INIT)
        .set('createRefferingClientPhase', INIT)
    }

    default: {
      return state
    }
  }
}
