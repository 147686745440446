import { connect } from 'react-redux'

import StrategistRatingReportComponent from './component'
import { getStrategistRating, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const StrategistRatingReportContainer = connect(
  // Map state to props
  (state) => ({
    strategistRatingPhase: state.reportV2.strategistRatingPhase,
    strategistRatingData: state.reportV2.strategistRatingData
  }),
  // Map actions to dispatch and props
  {
    getStrategistRating,
    clearReportV2Phase
  }
)(StrategistRatingReportComponent)

export default StrategistRatingReportContainer
