import { connect } from 'react-redux'

import NeverTouchedComponent from './component'
import { getNotTouchedData } from '../../../../../../store/agentV2/actions'
import { updateClientUser, agentClientFollowUp, clearPhase } from '../../../../../../store/agent/duck'

const NeverTouchedContainer = connect(
  // Map state to props
  (state) => ({
    notTouchedData: state.agentV2.notTouchedData,
    followUpPhase: state.agent.followUpPhase
  }),
  // Map actions to dispatch and props
  {
    getNotTouchedData,
    updateClientUser,
    agentClientFollowUp,
    clearPhase
  }
)(NeverTouchedComponent)

export default NeverTouchedContainer
