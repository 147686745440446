import React, { Fragment } from 'react'
import { map } from 'lodash'
import { ErrorMessage } from 'formik'
import { DeleteIcon } from '../../../../../components/icons'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { multiSelectStyles } from '../variables'

export default function SelectTown({ searchTownOption, setFieldValue, selectedSearchTown, selectedRole }) {

  const allowedRoleTown = [
    "agent", "local", "former-local",
    "former-agent", "former-strategist-inactive",
    "business development",
    "former business development"
  ]

  const handleSearchSelectionOption = (options, event, setFieldValue) => setFieldValue(event, options)

  const handleRemoveSearchSelection = (selectedTowns, townId, setFieldValue) => {
    setFieldValue('selectedSearchTown', selectedTowns.filter(town => town.value !== townId));
  }
  return (
    <Fragment>
      {allowedRoleTown.includes(selectedRole) ?
        <Fragment>
          <div className="form-group material-textfield">
            <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
              <ReactMultiSelectCheckboxes
                styles={multiSelectStyles}
                width="100%"
                name="selectedSearchTown"
                value={selectedSearchTown}
                placeholderButtonLabel="Select"
                options={searchTownOption}
                onChange={(event) => handleSearchSelectionOption(event, 'selectedSearchTown', setFieldValue)}
              />
              <label className="multiselect__checkboxes__field__label">
              { selectedRole === 'agent' || selectedRole === 'former-agent' ? 'Select the towns for this agent' : `Select the towns for this ${selectedRole}` }
                <span className="text-danger">*</span>
              </label>
            </div>
            <ErrorMessage className="invalid-feedback" name="assignedSuburbs" component="div" />
          </div>
          {selectedSearchTown.length > 0 && <div className="">
            <label className="">Selected Towns</label>
            <ul className="selected__strategist__list">
              {map(selectedSearchTown, (town, index) => (
                  <li key={index}>
                    <div className="strategist__title"> {town.label} </div>
                    <div className="cursor__pointer" onClick={() => handleRemoveSearchSelection(selectedSearchTown, town.value, setFieldValue)}> <DeleteIcon /> </div>
                  </li>
                ))}
            </ul>
          </div>}
        </Fragment>
        : ''}
    </Fragment>
  )
}
