import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import {getAgentTimeFormate, formatPhoneNumber} from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import _ from 'lodash'
import axios from 'axios'
import { logoutInvalidRequest } from '../../utils/helper'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const API_HOSTNAME = process.env.API_HOSTNAME

let timeList = ''
let sundayEnding = ''
let mondayEnding = ''
let tuesdayEnding = ''
let wednesdayEnding = ''
let thursdayEnding = ''
let fridayEnding = ''
let saturdayEnding = ''
class AvailabilityForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      sundayStartTime:'',
      sundayEndTime:'',
      mondayStartTime:'',
      mondayEndTime:'',      
      tuesdayStartTime:'',
      tuesdayEndTime:'',      
      wednesdayStartTime: '',
      wednesdayEndTime: '',      
      thursdayStartTime: '',
      thursdayEndTime: '',      
      fridayStartTime: '',
      fridayEndTime: '',
      saturdayStartTime: '',
      saturdayEndTime: '',
      sundayEnd:'',
      mondayEnd:'',
      tuesdayEnd:'',
      wednesdayEnd:'',
      thursdayEnd:'',
      fridayEnd:'',
      saturdayEnd:'',
      sundayId: '',
      mondayId:'',
      tuesdayId:'',
      wednesdayId:'',
      thursdayId:'',
      fridayId:'',
      saturdayId: '',
      userId:'',
      addAvailabilityMsg: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      this.props.getAvailability()
      this.setState({userId: _user.id})
      if(_user.role && _user.role !='agent'){
        this.props.history.push('/login')
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }
    if(nextProps.getAvilPhase === "success"){
      let data = this.props.getAvilData
      let sundayEnd = ''
      let mondayEnd = ''
      let tuesdayEnd = ''
      let wednesdayEnd = ''
      let thursdayEnd = ''
      let fridayEnd = ''
      let saturdayEnd = ''
      setTimeout(function(){
       this.setState({
          sundayId: _.get(this.props.getAvilData, 'data[0].id'),
          sundayStartTime: _.get(this.props.getAvilData, 'data[0].start_time'),
          sundayEndTime:  _.get(this.props.getAvilData, 'data[0].end_time'),
          mondayId: _.get(this.props.getAvilData, 'data[1].id'),
          mondayStartTime: _.get(this.props.getAvilData, 'data[1].start_time'),
          mondayEndTime:  _.get(this.props.getAvilData, 'data[1].end_time'),
          tuesdayId: _.get(this.props.getAvilData, 'data[2].id'),
          tuesdayStartTime: _.get(this.props.getAvilData, 'data[2].start_time'),
          tuesdayEndTime: _.get(this.props.getAvilData, 'data[2].end_time'),
          wednesdayId: _.get(this.props.getAvilData, 'data[3].id'),
          wednesdayStartTime: _.get(this.props.getAvilData, 'data[3].start_time'),
          wednesdayEndTime: _.get(this.props.getAvilData, 'data[3].end_time'),
          thursdayId: _.get(this.props.getAvilData, 'data[4].id'),
          thursdayStartTime: _.get(this.props.getAvilData, 'data[4].start_time'),
          thursdayEndTime: _.get(this.props.getAvilData, 'data[4].end_time'),
          fridayId: _.get(this.props.getAvilData, 'data[5].id'),
          fridayStartTime: _.get(this.props.getAvilData, 'data[5].start_time'),
          fridayEndTime: _.get(this.props.getAvilData, 'data[5].end_time'),
          saturdayId: _.get(this.props.getAvilData, 'data[6].id'),
          saturdayStartTime: _.get(this.props.getAvilData, 'data[6].start_time'),
          saturdayEndTime: _.get(this.props.getAvilData, 'data[6].end_time')
        }, () => {
            sundayEnd = _.filter(config.timings, (avil) => {
              return avil.time > this.state.sundayStartTime
            })
            this.setState({ sundayEnd: sundayEnd})
          // }
          // if(event.target.name === "mondayStartTime"){
          //    mondayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, mondayEnd: mondayEnd})
          // }
          // if(event.target.name === "tuesdayStartTime"){
          //    tuesdayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, tuesdayEnd: tuesdayEnd})
          // }
          // if(event.target.name === "wednesdayStartTime"){
          //    wednesdayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, wednesdayEnd: wednesdayEnd})
          // }
          // if(event.target.name === "thursdayStartTime"){
          //    thursdayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, thursdayEnd: thursdayEnd})
          // }
          // if(event.target.name === "fridayStartTime"){
          //    fridayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, fridayEnd: fridayEnd})
          // }
          // if(event.target.name === "saturdayStartTime"){
          //    saturdayEnd = _.filter(timeList, (avil) => {
          //     return avil.props.value > event.target.value
          //   })
          //   this.setState({ [event.target.name]: event.target.value, saturdayEnd: saturdayEnd})
          // }
        })

      }.bind(this), 200)
    }
    if(nextProps.addAgentDayPhase === "success"){
      this.setState({addAvailabilityMsg: 'Availability Saved.....'})
      setTimeout(function(){
        this.setState({addAvailabilityMsg: ''})
         this.props.history.push('/agent-home')
      }.bind(this),800)
      this.props.clearPhase()
    }
  }

  SyncAuthPop(type, e){
    if(type ==='google') {
      axios({
        method: 'GET',
        url: API_HOSTNAME+'/agent-calendar/authenticate'
      })
      .then((res) => {
        if(res && res.data && res.data.status && res.data.data !='')
        var win = window.open(res.data.data, 'Account verifications','width=600,height=500,left=50,top=100');
        setTimeout(function(){
         },1000)
         setTimeout(function () { win.close();
          window.location.reload(true)
        }, 50000);
      }).catch(logoutInvalidRequest)
    } else {
      axios({
        method: 'GET',
        url: API_HOSTNAME+'/agent-calendar/outlook-authenticate'
      })
      .then((res) => {
        if(res && res.data && res.data.status && res.data.data !='')
        var win = window.open(res.data.data, 'Account verifications','width=600,height=500,left=50,top=100');
        setTimeout(function(){
         },1000)
         setTimeout(function () { win.close();
          window.location.reload(true)
        }, 50000);
      }).catch(logoutInvalidRequest)
    }
  }

  handleChange(event) {
    // let sundayEnd = ''
    // let mondayEnd = ''
    // let tuesdayEnd = ''
    // let wednesdayEnd = ''
    // let thursdayEnd = ''
    // let fridayEnd = ''
    // let saturdayEnd = ''
    // if(event.target.name === "sundayStartTime") {
    //    sundayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, sundayEnd: sundayEnd})
    // }
    // if(event.target.name === "mondayStartTime"){
    //    mondayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, mondayEnd: mondayEnd})
    // }
    // if(event.target.name === "tuesdayStartTime"){
    //    tuesdayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, tuesdayEnd: tuesdayEnd})
    // }
    // if(event.target.name === "wednesdayStartTime"){
    //    wednesdayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, wednesdayEnd: wednesdayEnd})
    // }
    // if(event.target.name === "thursdayStartTime"){
    //    thursdayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, thursdayEnd: thursdayEnd})
    // }
    // if(event.target.name === "fridayStartTime"){
    //    fridayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, fridayEnd: fridayEnd})
    // }
    // if(event.target.name === "saturdayStartTime"){
    //    saturdayEnd = _.filter(timeList, (avil) => {
    //     return avil.props.value > event.target.value
    //   })
    //   this.setState({ [event.target.name]: event.target.value, saturdayEnd: saturdayEnd})
    // }
    this.setState({ [event.target.name]: event.target.value})
  }

  submitBtn(event){
    event.preventDefault()
    const err = {}
    const {sundayId, sundayStartTime, sundayEndTime, mondayId, mondayStartTime, mondayEndTime,
           tuesdayId, tuesdayStartTime, tuesdayEndTime,
          wednesdayId, wednesdayStartTime, wednesdayEndTime,
          thursdayId, thursdayStartTime, thursdayEndTime,
          fridayId, fridayStartTime, fridayEndTime, saturdayId, saturdayStartTime, saturdayEndTime} = this.state
    if(sundayStartTime !=='' && sundayEndTime === ''){
      err.sundayEndTime = "Select End Time"
    }
    if(mondayStartTime !=='' && mondayEndTime === ''){
      err.mondayEndTime = "Select End Time"
    }
    if(tuesdayStartTime !=='' && tuesdayEndTime === ''){
      err.tuesdayEndTime = "Select End Time"
    }
    if(wednesdayStartTime !=='' && wednesdayEndTime === ''){
      err.wednesdayEndTime = "Select End Time"
    }
    if(thursdayStartTime !=='' && thursdayEndTime === ''){
      err.thursdayEndTime = "Select End Time"
    }
    if(fridayStartTime !=='' && fridayEndTime === ''){
      err.fridayEndTime = "Select End Time"
    }
    if(saturdayStartTime !=='' && saturdayEndTime === ''){
      err.saturdayEndTime = "Select End Time"
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {}
    data.user_id = this.state.userId
    data.availability_arr = [{
      id: sundayId,
      day  : 0,
      start: sundayStartTime,
      end  : sundayEndTime
    },{
      id: mondayId,
      day  : 1,
      start: mondayStartTime,
      end  : mondayEndTime
    },
    {
      id: tuesdayId,
      day  : 2,
      start: tuesdayStartTime,
      end  : tuesdayEndTime
    },
    {
      id: wednesdayId,
      day  : 3,
      start: wednesdayStartTime,
      end  : wednesdayEndTime
    },
    {
      id: thursdayId,
      day  : 4,
      start: thursdayStartTime,
      end  : thursdayEndTime
    },
    {
      id: fridayId,
      day  : 5,
      start: fridayStartTime,
      end  : fridayEndTime
    },
    {
      id: saturdayId,
      day  : 6,
      start: saturdayStartTime,
      end  : saturdayEndTime
    }]
    this.props.addAgentDay(data)
    }
  }

  render() {
    const {
      user,
      token,
      phase
    } = this.props
    let sundayEnd = ''
    let mondayEnd = ''
    let tuesdayEnd = ''
    let wednesdayEnd = ''
    let thursdayEnd = ''
    let fridayEnd = ''
    let saturdayEnd = ''  
    timeList = config.timings.map((val1, index1) => {
      let pos = config.timings.length - 1
      if (pos === index1) {
        return(
          <option disabled={pos} key={index1} value={val1.time}>{getAgentTimeFormate(val1.time).tval} {getAgentTimeFormate(val1.time).type}</option>
        )
      } else {
        return(
          <option key={index1} value={val1.time}>{getAgentTimeFormate(val1.time).tval} {getAgentTimeFormate(val1.time).type}</option>
        )
      }
    });
    sundayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.sundayStartTime
    })
      
    mondayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.mondayStartTime
    })
    
    tuesdayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.tuesdayStartTime
    })

    wednesdayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.wednesdayStartTime
    })

    thursdayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.thursdayStartTime
    })

    fridayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.fridayStartTime
    })

    saturdayEnd = _.filter(timeList, (avil) => {
      return avil.props.value > this.state.saturdayStartTime
    })

    if(sundayEnd !== '') {
      sundayEnding = sundayEnd && sundayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    } else {
      sundayEnding = this.state.sundayEndTime ? (
         <option value={this.state.sundayEndTime}>{getAgentTimeFormate(this.state.sundayEndTime).tval} {getAgentTimeFormate(this.state.sundayEndTime).type}
         </option>
      ) : ''
    }

    if(mondayEnd !== '') {
      mondayEnding = mondayEnd && mondayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    } else {
      mondayEnding = this.state.mondayEndTime ? (
         <option value={this.state.mondayEndTime}>{getAgentTimeFormate(this.state.mondayEndTime).tval} {getAgentTimeFormate(this.state.mondayEndTime).type}
         </option>
      ) : ''
    }

    if(tuesdayEnd !== ''){
      tuesdayEnding = tuesdayEnd && tuesdayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    }else{
      tuesdayEnding = this.state.tuesdayEndTime ? (
         <option value={this.state.tuesdayEndTime}>{getAgentTimeFormate(this.state.tuesdayEndTime).tval} {getAgentTimeFormate(this.state.tuesdayEndTime).type}
         </option>
      ) : ''
    }

    if(wednesdayEnd !== ''){
      wednesdayEnding = wednesdayEnd && wednesdayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    }else{
      wednesdayEnding = this.state.wednesdayEndTime ? (
         <option value={this.state.wednesdayEndTime}>{getAgentTimeFormate(this.state.wednesdayEndTime).tval} {getAgentTimeFormate(this.state.wednesdayEndTime).type}
         </option>
      ) : ''
    }

    if(thursdayEnd !== ''){
      thursdayEnding = thursdayEnd && thursdayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    }else{
      thursdayEnding = this.state.thursdayEndTime ? (
         <option value={this.state.thursdayEndTime}>{getAgentTimeFormate(this.state.thursdayEndTime).tval} {getAgentTimeFormate(this.state.thursdayEndTime).type}
         </option>
      ) : ''
    }

    if(fridayEnd !== ''){
      fridayEnding = fridayEnd && fridayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    }else{
      fridayEnding = this.state.fridayEndTime ? (
         <option value={this.state.fridayEndTime}>{getAgentTimeFormate(this.state.fridayEndTime).tval} {getAgentTimeFormate(this.state.fridayEndTime).type}
         </option>
      ) : ''
    }

    if(saturdayEnd !== '') {
      saturdayEnding = saturdayEnd && saturdayEnd.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime.props.value}>{getAgentTimeFormate(endTime.props.value).tval} {getAgentTimeFormate(endTime.props.value).type}</option>
        )
      })
    } else {
      saturdayEnding = this.state.saturdayEndTime ? (
         <option value={this.state.saturdayEndTime}>{getAgentTimeFormate(this.state.saturdayEndTime).tval} {getAgentTimeFormate(this.state.saturdayEndTime).type}
         </option>
      ) : ''
    }

    return (
      <div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__header--wrapper">
                  <div className="profile__header">
                    <a>
                      <img src="/img/logo.svg" />
                      <h4>
                        <span>|</span>Update Availability
                      </h4>
                    </a>
                  </div>
                  <div
                    className="profile__close"
                    onClick={() => {window.history.back()}}
                  >
                    <img src="img/close.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        {/* <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Update Availability</span></a>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <a onClick={() => {window.history.back()}}><img src="img/close.svg"/></a>
                  </div>
                </div>
              </div>
          </div>
        </div> */}

        <main className="availability__container">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="full__page--left">
                    <h1>Availability</h1>
                    <p>This is the availability that will appear on your calendar. Please make sure to update this frequently.</p>
                    <div className="syncCalenderBtns">
                    <div className="row googleCalSync">
                        <div className="col-md-12">
                          { _.get(user, 'role') ==='agent' ? 
                          <button onClick={this.SyncAuthPop.bind(this, 'google')} className="navigation__links--google-sync syncbtn-width" style={{background: _.get(user, 'gmail_authentication') ===true ? "#c7ce49" : "#3367d6"}}>
                            <img src="img/google-icon.svg"/>{ _.get(user, 'gmail_authentication') ===true ? 'Google Calendar Synced' : ' Sync Google Calendar'}
                          </button>
                          : ""
                          }
                        </div>
                    </div>
                    <div className="row outlookCalSync">
                        <div className="col-md-12">
                          { (_.get(user, 'role') ==='agent') ? 
                          <button onClick={ this.SyncAuthPop.bind(this, 'outlook') }className="navigation__links--google-sync syncbtn-width" style={{background: _.get(user, 'outlook_authentication') ===true ? "#c7ce49" : "#3367d6"}}>
                            <img src="img/outlook-cal-sync.png"/> { (_.get(user, 'outlook_authentication') === true) ? 'Outlook Calendar Synced' : ' Sync Outlook Calendar'}
                          </button>
                          : ""
                          }
                        </div>
                    </div>
                    </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="availability__container--wrapper-form">

                  <div className="availability--form__card">
                      <ul className="locations__list">
                        <li>
                          <div className="select__days--name">
                            Sunday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='sundayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.sundayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='sundayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.sundayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {sundayEnding}
                              </select>
                              { this.state.err.sundayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.sundayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Monday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='mondayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.mondayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='mondayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.mondayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {mondayEnding}
                              </select>
                              { this.state.err.mondayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.mondayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Tuesday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                             <select 
                                name='tuesdayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.tuesdayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='tuesdayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.tuesdayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {tuesdayEnding}
                              </select>
                              { this.state.err.tuesdayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.tuesdayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Wednesday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='wednesdayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.wednesdayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='wednesdayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.wednesdayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {wednesdayEnding}
                              </select>
                              { this.state.err.wednesdayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.wednesdayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Thursday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='thursdayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.thursdayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='thursdayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.thursdayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {thursdayEnding}
                              </select>
                              { this.state.err.thursdayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.thursdayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Friday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='fridayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.fridayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='fridayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.fridayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {fridayEnding}
                              </select>
                              { this.state.err.fridayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.fridayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="select__days--name">
                            Saturday
                          </div>
                          <div className="time__list--select">
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">From</label>
                              <select 
                                name='saturdayStartTime' 
                                className="selectinputs__global" 
                                value = {this.state.saturdayStartTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {timeList}
                              </select>
                            </div>
                            <div className="time__list--select--col-6">
                              <label className="time__list--select-label">To</label>
                              <select 
                                name='saturdayEndTime' 
                                className="selectinputs__global" 
                                value = {this.state.saturdayEndTime}  
                                onChange={this.handleChange.bind(this)}>
                                <option value=''>Select Time</option>
                                {saturdayEnding}
                              </select>
                              { this.state.err.saturdayEndTime ?
                              <span className="error_msg-tab">
                              {this.state.err.saturdayEndTime}
                              </span> : '' }
                            </div>
                          </div>
                        </li>
                      </ul>
                  </div>
                  {this.state.addAvailabilityMsg !== '' &&
                    <div className="text-center alert alert-success">
                      {this.state.addAvailabilityMsg}
                    </div>
                  }
                   <div className='text-center'>{}</div>
                   <div className="availability__container--form__btn">
                    <Link
                       className="btn btn__black--lg btn__black--outline padd__t--b cancelmob"
                       to={`/dashboard`}>Cancel</Link>
                    <button 
                      type="submit"
                      onClick={this.submitBtn.bind(this)} 
                      className="btn btn__green btn__green--lg">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default reduxForm({
  form: 'availability',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AvailabilityForm)
