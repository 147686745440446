import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { Field, ErrorMessage } from 'formik'

export default function PayoutAdditionalFields({ values, setFieldValue }) {

  const handleChangeRefferalPayout = ({ target }) => setFieldValue('refferalPayoutAmount', target.value)

  const handleChangeDealPayout = ({ target }) => setFieldValue('dealPayoutAmount', target.value)

  return (
    <Fragment>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select" name="dealPayout" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select</option>
            <option value="percentageOfDeal">% of Deal </option>
            <option value="flat">Flat</option>
          </Field>
          <label className="material-textfield-label label-lg">Deal Payout  </label>
          <ErrorMessage className="invalid-feedback" name="dealPayout" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
           <NumberFormat
              name="dealPayoutAmount"
              className="form-control material-textfield-input textfield-input-lg"
              // onChange={handleChangeDealPayout}
              onValueChange={
                (value) => {
                  setFieldValue('dealPayoutAmount', value.floatValue);
                }
              }
              thousandSeparator={true}
              prefix="$"
              value={values.dealPayoutAmount}
              decimalScale={2}
              required
            />
          <label className="material-textfield-label label-lg">Deal Payout Amount </label>
          <ErrorMessage className="invalid-feedback" name="dealPayoutAmount" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select" name="refferalPayout" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select</option>
            <option value="percentageOfDeal">% of Deal</option>
            <option value="flat">Flat</option>
          </Field>
          <label className="material-textfield-label label-lg">BD Referral Payout </label>
          <ErrorMessage className="invalid-feedback" name="refferalPayout" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
            <NumberFormat
              name="refferalPayoutAmount"
              className="form-control material-textfield-input textfield-input-lg"
              // onChange={handleChangeRefferalPayout}
              onValueChange={
                (value) => {
                  setFieldValue('refferalPayoutAmount', value.floatValue);
                }
              }
              thousandSeparator={true}
              prefix="$"
              value={values.refferalPayoutAmount}
              decimalScale={2}
              required
            />
          <label className="material-textfield-label label-lg">Deal Payout Amount </label>
          <ErrorMessage className="invalid-feedback" name="refferalPayoutAmount" component="div" />
        </div>
      </div>
    </Fragment>
  )
}
