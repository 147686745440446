import { connect } from 'react-redux'

import { impersonateUser, clearPhase } from '../../../../store/dashboard/duck'
import { fetchUser, getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import { fetchClientById, clearClientPhase, clearDashboardPhase } from '../../../../store/strategistV2/clients/action'

import {
  fetchProspectusById,
  clearProspectusPhase,
} from '../../../../store/strategistV2/prospectus/prospectus/actions'
import {
  addClientStrategistTimeSheet,
  clearClientDetailPhase,
  getRankingByClientId
} from '../../../../store/strategistV2/clientdetail/action'

import {
  getInteralNotes
} from '../../../../store/strategistV2/notes/actions'

import {
  fetchClientAgent,
  getChatHistory,
  fetchClientChat,
  fetchClientAgentChat,
  fetchClientTextMessage
} from '../../../../store/strategistV2/chat/actions'

import { getActivityEmail } from '../../../../store/strategistV2/activity/actions'

import {
  fetchFollowUpDate,
  clearFollowUpPhase,
} from '../../../../store/strategistV2/prospectus/followup/actions'

import ClientDetailsComponent from './component'
const ClientDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    clientData: state.agent.clientData,
    filterParams: state.strategistV2.dashboard.filterParams,
    impersonatePhase: state.dashboard.impersonatePhase,
    impersonateData: state.dashboard.impersonateData,
    clientPhase: state.agent.clientPhase,
    fetchProspectusByIdPhase: state.strategistV2.prospectus.prospectus.fetchProspectusByIdPhase,
    prospectus: state.strategistV2.prospectus.prospectus.prospectus,
    prospectusDetail: state.strategistV2.prospectus.prospectus.prospectusDetail,
    clientStrategistTimeSheet: state.strategistV2.prospectus.prospectus.clientStrategistTimeSheet,
    addClientStrategistTimeSheetPhase: state.strategistV2.prospectus.prospectus.addClientStrategistTimeSheetPhase,
    fetchClientDetailForDashBoardPhase: state.strategistV2.client.fetchClientDetailForDashBoardPhase,
  }),
  // Map actions to dispatch and props
  {
    impersonateUser,
    fetchUser,
    clearPhase,
    getFilterParams,
    fetchClientById, 
    clearClientPhase,
    fetchProspectusById,
    clearProspectusPhase,
    addClientStrategistTimeSheet,
    clearClientDetailPhase,
    fetchFollowUpDate,
    clearFollowUpPhase,
    fetchClientAgent,
    getRankingByClientId,
    fetchClientChat,
    getInteralNotes,
    fetchClientAgentChat,
    fetchClientTextMessage,
    clearDashboardPhase,
    getActivityEmail
  }
)(ClientDetailsComponent)

export default ClientDetailsContainer
