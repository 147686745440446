import { connect } from 'react-redux'

import InterestedNotTownTourComponent from './component'
import { agentClientFollowUp, clearPhase } from '../../../../../../store/agent/duck'

const InterestedNotTownTourContainer = connect(
  // Map state to props
  (state) => ({
    followUpPhase: state.agent.followUpPhase
  }),
  // Map actions to dispatch and props
  {
    agentClientFollowUp,
    clearPhase
  }
)(InterestedNotTownTourComponent)

export default InterestedNotTownTourContainer
