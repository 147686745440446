import { connect } from 'react-redux'
import {loginUser, handleSignOut} from '../../store/user/duck'
import {getFilterParams, getClientAgents, addClientDeal, clearPhase, getClientDeal} from '../../store/dashboard/duck'
import AddDealForm from './component'
const AddDeal = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    filterParams: state.dashboard.filterParams,
    clientAgentData: state.dashboard.clientAgentData,
    addclientDealdata: state.dashboard.addclientDealdata,
    dealError: state.dashboard.error,
    phase: state.user.phase,
    dataPhase: state.dashboard.dataPhase,
    clientDealdata: state.dashboard.clientDealdata,
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut,
    getFilterParams,
    getClientAgents,
    addClientDeal,
    clearPhase,
    getClientDeal
  }
)(AddDealForm)
export default AddDeal
