export const FETCH_FOLLOW_UP_DATE = 'suburban/strategistV2/prospectus/followup/FETCH_FOLLOW_UP_DATE'
export const FETCH_FOLLOW_UP_DATE_SUCCESS =
  'suburban/strategistV2/prospectus/followup/FETCH_FOLLOW_UP_DATE_SUCCESS'
export const FETCH_FOLLOW_UP_DATE_ERROR =
  'suburban/strategistV2/prospectus/followup/FETCH_FOLLOW_UP_DATE_ERROR'

export const UPDATE_FOLLOW_UP_DATE =
  'suburban/strategistV2/prospectus/followup/UPDATE_FOLLOW_UP_DATE'
export const UPDATE_FOLLOW_UP_DATE_SUCCESS =
  'suburban/strategistV2/prospectus/followup/UPDATE_FOLLOW_UP_DATE_SUCCESS'
export const UPDATE_FOLLOW_UP_DATE_ERROR =
  'suburban/strategistV2/prospectus/followup/UPDATE_FOLLOW_UP_DATE_ERROR'

export const CLEAR_FOLLOW_UP_PHASE =
  'suburban/strategistV2/prospectus/followup/CLEAR_FOLLOW_UP_PHASE'
