import React, { PureComponent /*, PropTypes*/ } from 'react'
// import IPropTypes from 'react-immutable-proptypes'
import './styles.scss'
import { CaretRightIcon } from '../../../../../../components/icons'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
let _user = ''

export default class ReportsComponent extends PureComponent {

  componentDidMount(){
    _user = cookies.get('user')
  }

  render() {
    const { user } = this.props
    let filterList = []
    if(user && user.suburbs && user.suburbs.length > 0){
      filterList =  user.suburbs.filter((u) =>{ return ((u.suburb_id) === 1); });
    }
    return (
        
        <div className="col-md-12">
          <div className="strategist__pages__tile">

            {/* <div onClick={() => this.props.history.push(`/report/clients-per-agents`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title"># of Clients per Agent</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}

            {/* <div onClick={() => this.props.history.push(`/report/account-report`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Accounting Report</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}

            {/*
              (_user && _user.role !== "strategist") &&
              <div onClick={() => this.props.history.push(`/report/ad-tracking`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Ad Tracking Report</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}
            
            {/*
            _user && (_user.role === "strategist") && (filterList && filterList.length > 0) &&
            <div onClick={() => this.props.history.push(`/report/agents-assign-by-ny-strategist`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Agents assigned by NY SJ Strategists</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div>
            */}

            {/* <div onClick={() => this.props.history.push(`/report/agents-assigned-to-towns`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Agents Assigned to Towns</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}
          
            {/*
              _user && (_user.role !== "strategist") &&
              <div onClick={() => this.props.history.push(`/report/agent-rating-report`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Agent Rating Report</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}

            {/* <div onClick={() => this.props.history.push(`/report/agent-statistics`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Agent Statistics</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}
            
            {/* <div onClick={() => this.props.history.push(`/report/ai-clients`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">AI Clients</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}

            {/*
              (_user && _user.role !== "strategist") &&
              <div onClick={() => this.props.history.push(`/report/bd-source-report`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Biz Dev Tracking Report</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}

            {/*
              (_user && _user.role === "strategist") &&
              <div onClick={() => this.props.history.push(`/report/business-development`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Business Development</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}

            <div onClick={() => this.props.history.push(`/report/clients-assigned-per-town`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Clients assigned per town</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div>

            {/* <div onClick={() => this.props.history.push(`/report/clients-assign-to-agents`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Clients assigned to agents</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}
            
            {/* <div onClick={() => this.props.history.push(`/report/closed-clients-per-towns`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Closed Clients Per Town</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div> */}

        
            {/*
              _user && (_user.role !== "strategist") &&
              <div onClick={() => this.props.history.push(`/report/login-records`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Login Records</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}
            
            {/*
              (_user && _user.role !== "strategist") &&
              <div 
                onClick={() => this.props.history.push(`/report/strategist-rating-report`)} className="card__tile">
                <div className="card__tile__content">
                  <div className="card__tile__title">Strategist Rating Report-Initial Call</div>
                </div>
                <div className="card__tile__arrow"><CaretRightIcon /> </div>
              </div>
            */}

            {/* 
            <div onClick={() => this.props.history.push(`/report/strategist-statistics`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Strategist Statistics</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div>
            */}

            {/* 
            <div onClick={() => this.props.history.push(`/report/strategist-timesheet`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Strategist Timesheet</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div>
            */}

            {/*
            _user && (_user.role !== "strategist") &&
            <div onClick={() => this.props.history.push(`/report/total-clients-received-per-month`)} className="card__tile">
              <div className="card__tile__content">
                <div className="card__tile__title">Total clients received per month</div>
              </div>
              <div className="card__tile__arrow"><CaretRightIcon /> </div>
            </div>
            */}

          </div>
        </div>

    )
  }
}
