import { connect } from 'react-redux'
import NoInitalTourForm from './component'

import { getNoInitialTour } from '../../../store/analytics/duck'

const NoInitalTourComponent = connect(
  // Map state to props
  (state) => ({
    initialTourPhase: state.analytics.initialTourPhase,
    initialTourData: state.analytics.initialTourData,
  }),
  // Map actions to props
  {
    getNoInitialTour
  }
)(NoInitalTourForm)
export default NoInitalTourComponent
