import { connect } from 'react-redux'

import ReportsComponent from './component'

const ReportsContainer = connect(
  // Map state to props
  (state) => ({

  }),
  // Map actions to dispatch and props
  {
    
  }
)(ReportsComponent)

export default ReportsContainer
