import * as types from './action-types'
/*
    //////////////////////////////////////
  /// activity email action ///
/////////////////////////////////////
*/
// fetch activity email for client actiivty
export const getActivityEmail = (payload) => ({
  type: types.FETCH_ACTIVITY_CLIENT_EMAIL,
  payload,
})

// fetch activity email for client actiivty
export const fetchClientActivityHistory = (payload) => ({
  type: types.FETCH_CLIENT_ACTIVITY_HISTORY,
  payload,
})

// clear activity client email
export const clearActivityPhase = () => ({
  type: types.CLEAR_CLIENT_ACTIVITY_PHASE,
})
