import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../../../config'
import { Redirect, Link } from 'react-router-dom'
import Modal from 'react-modal';
import styles from '../../../Login/styles.scss';

const required = value => value ? undefined : 'Required'

class ResetPasswordForm extends Component {   
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      email: "",
      password: "",
      confirmpassword:"",
      err: {},
      message: '',
      isLoading: false
    }
  } 
  
  // Function to set token in state
  componentWillMount(){ 
    var token = ''
    if(this.props.match.params && this.props.match.params.token) {
      token = this.props.match.params.token
      this.setState({ token : token })      
    }   
  }

  // Function to show error message
  componentWillReceiveProps(nextProps){
    if(nextProps.resetPasswordPhase === "success"){
      let self = this;
      this.setState({ message: "Reset Your Password Successfully..."})
      setTimeout(()=> {
        self.props.history.push({ pathname: `/login`})
      },1000)
    }
  }
  
  // Function to set input value in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  
  // Function to change password
  handleClick(event) {
    const err = {}
    if (this.state.password === '' || this.state.password === undefined) {
      err.password = 'password is required.'
    }
    if (this.state.confirmpassword === '' || this.state.confirmpassword === undefined) {
      err.confirmpassword = 'Confirm password is required.'
    }
    if ((this.state.password !== '' && this.state.password !== undefined) && (this.state.confirmpassword !== '' && this.state.confirmpassword !== undefined) && this.state.password !== this.state.confirmpassword) {
      err.confirmpassword = 'Password and confirm password doesn\'t match.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { resetPassword } = this.props
      const data = { 
        password: this.state.password,
        token: this.state.token
      }
      resetPassword(data)
    }
  }
  
  render() {
    return (
      <section>
        <div className="container-fluid">
            <div className="row login-header">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-4 text-left">
                    <h4></h4>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                <a href={this.state.wpUrl}>
                  <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />
                </a> 
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2 text-right">
                    <div className="close">
                      <a href={`${this.state.wpUrl}`}>
                        <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                      </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="LoignSection">
        
          <div className="overlay-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="v-container">
                  <div className="login-box ForgotSection">

                    <h3>Reset Password</h3>
                    <div className="col-md-12">
                    <div className="row">
                    <div className="outer-input outer-input-forgot">
                    <input 
                      type="password" 
                      name="password" 
                      placeholder="Enter new password"
                      value={this.state.password}
                      onChange={this.handleChange.bind(this)} 
                    />
                    <label>New Password</label>
                    </div>
                    </div>
                    </div>
                    { this.state.err.password ?
                    <span className="error_field">
                    {this.state.err.password}
                    </span> : '' }

                    <div className="col-md-12">
                    <div className="row">
                    <div className="outer-input outer-input-forgot">
                    <input 
                      type="password" 
                      name="confirmpassword" 
                      placeholder="Confirm password"
                      value={this.state.confirmpassword}
                      onChange={this.handleChange.bind(this)} 
                    />
                    <label>Confirm Password</label>
                    </div>
                    </div>
                    </div>
                    { this.state.err.confirmpassword ?
                    <span className="error_field">
                    {this.state.err.confirmpassword}
                    </span> : '' }

                    <button type="submit" className="btn DefaultBtn login-submit mor-marg-top" onClick={this.handleClick.bind(this)}>Submit</button>
                    <span className="create-accoutn for-right">
                      <Link to={`/login`}>Back to Login</Link>
                    </span>

                    <div>
                    {this.state.message !== '' &&
                      <div className="text-center alert alert-success">
                        {this.state.message}
                      </div>
                    }
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section> 
    )
  }
}

export default reduxForm({
  form: 'resetpassword',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ResetPasswordForm)
