export const multiSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '8px 12px',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'none',
    }),
    menu: () => ({
      borderRadius: '4px',
      background: '#fff',
      boxShadow: '5px 10px 30px #E5E5E5',
    }),
  }
export const customeReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}