import { connect } from 'react-redux'

import EmailsComponent from './component'

import { getActivityEmail } from '../../../../store/strategistV2/activity/actions'

const EmailsContainer = connect(
	// Map state to props
	(state) => ({
		user: state.user.users,
		activityEmails: state.strategistV2.activity.activityEmails,
	}),
	// Map actions to dispatch and props
	{
		getActivityEmail,
	}
)(EmailsComponent)

export default EmailsContainer
