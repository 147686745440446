import { connect } from 'react-redux'

import UpdateProfileComponent from './component'
import { getUSerProfileByID, clearPhase } from '../../../../store/dashboard/duck'
import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import { updateOwnProfile, clearPhaseUser } from '../../../../store/user/duck'
const UpdateProfileContainer = connect(
  // Map state to props
  (state) => (
    {
      userData: state.dashboard.userData,
      ownUpdatePhase: state.user.ownUpdatePhase,
      filterParams: state.strategistV2.dashboard.filterParams,
      junglerUserPhase: state.dashboard.junglerUserPhase,
      profileData: state.user.users
    }),
  // Map actions to dispatch and props
  {
    getUSerProfileByID,
    updateOwnProfile,
    clearPhaseUser,
    getFilterParams,
    clearPhase
  }
)(UpdateProfileComponent)

export default UpdateProfileContainer
