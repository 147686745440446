import { connect } from 'react-redux'
import {fetchUser, updateProfile, handleSignOut} from '../../store/user/duck'
import { shareClientInfoToAgent, getClientAgent } from '../../store/client/duck'
import ProfileForm from './component'
const Profile = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    profilePhase: state.user.profilePhase,
    updateProfilePhase: state.user.updateProfilePhase,
    message: state.user.message,
    userUpdatePhase: state.user.userUpdatePhase,
    shareClientInfoData: state.client.shareClientInfoData,
    agentData: state.client.agentData,
    getAgentPhase: state.client.getAgentPhase,
  }),
  // Map actions to props
  {
    fetchUser,
    updateProfile,
    handleSignOut,
    shareClientInfoToAgent,
    getClientAgent
  }
)(ProfileForm)
export default Profile
