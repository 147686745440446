/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react'
import { map, get, uniqWith } from 'lodash'
import { Spin } from 'antd';
import Cookies from 'universal-cookie'

import './styles.scss'
import Insights from './Insights/container'
import Loader from '../../../../../components/Loader';
import { SUCCESS } from '../../../../../constants/phase';
import AgentAppointment from '../../Common/AgentAppointment/index'
import LeaveComment from './partials/LeaveComment';

const cookies = new Cookies()

export default class StrategyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      visible: false,
      townId: "",
      leaveCommentModal: false,
      townName: "",
      modalIsOpen: false,
      agent: {}
    }
  }
  componentDidMount() {
    const clientId = get(this.props, "match.params.id", false)
    if (!clientId) {
      this.props.history.push('/strategist/clients/all-clients')
    }
    // eslint-disable-next-line camelcase
    this.setState({ isLoading: true }, () => this.props.getClientStrategist({ client_id: clientId }))
  }
  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    if (props.getClientStrategistPhase === SUCCESS) {
      props.clearStrategistPhase()
      states = { ...states, isLoading: false }
    }
    if (props.changeClientStatusPhase === SUCCESS) {
      props.clearStrategistPhase()
      const clientId = get(props, "match.params.id", false)
      props.getClientStrategist({ client_id: clientId })
      states = { ...states, isLoading: true }
    }
    return states
  }

  componentDidUpdate(preProps, preState) {
    const {
      match: {
        params: { id },
      },
      location,
      getClientStrategist,
    } = this.props
    if (location.pathname !== preProps.location.pathname) {
      getClientStrategist({ client_id: parseInt(id) })
    }
  }

  showInsightsDrawer = (townId, townName) => this.setState({ visible: true, townId, townName })
  showLeaveCommentModal = (townId) => this.setState({ leaveCommentModal: true, townId })

  onClose = () => this.setState({ visible: false })

  handleEditStrategy = (strategistId) => this.props.history.push({
    pathname: `/strategist/town-details/${strategistId}`,
    state: { prevRoute: get(this.props, 'history.location.state.prevRoute', '')}
  })
  // open schedule appointment modal with data for create appointment
  openScheduleAppointmentModal = (agentId, name, timeZone) => this.setState({ modalIsOpen: true, agent: { agentId, name, timeZone } });
  // close all modal with data with client data
  closeModal = () => this.setState({ modalIsOpen: false, visible: false, leaveCommentModal: false }, () => this.props.getClientStrategist({ client_id: this.props.match.params.id }));
  // change client status wrt to strategy
  handleChangeClientStatus = (event, agentId, clientId, townId) => {
    const { value } = event.target
    this.props.changeClientStatus({
      agentId: agentId ? agentId : 0,
      clientId,
      status: value,
      townId
    })
  }
  render() {
    const { townId, townName, isLoading, leaveCommentModal, modalIsOpen } = this.state
    const client = get(this.props, "clientDetail", {})
    const user = cookies.get('user')
    const strategiests = uniqWith(get(this.props, 'strategyData.data', []), (a, b) => a.agent_id === b.agent_id && a.town_id === b.town_id);
    const clientId = get(this.props, "match.params.id")
    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__0">Strategy for this deal</h5>
            </div>
            <div className="deal__information__cta">
              <button className="btn btn__btn btn-dark mr__10 btn__edit__deal" onClick={() => this.handleEditStrategy(clientId)}>
                {strategiests.length > 0 ? "Edit Strategy" : "Add Strategy"}
              </button>
            </div>
          </div>
          <Spin size="large" spinning={isLoading} indicator={<Loader />}>
            <div className="card-body card-body-sm p__0">
                {strategiests && strategiests.length > 0 ?
                <div className="table-responsive auto-scroll-jungler">
                  <table className="table strategy__table">
                    <thead>
                      <tr>
                        <th>Agent Name</th>
                        <th>Suburb</th>
                        <th>Status</th>
                        {/*<th colSpan="6">Agent Rating</th>*/}
                      </tr>
                    </thead>
                    <tbody>
                      {map(strategiests, (strategy, index) => (
                        <tr key={index}>
                          <td><span className={`${!strategy.agent_full_name && "text-muted"}`}>{strategy.agent_full_name ? `${strategy.agent_full_name}` : "No one assigned"}</span></td>
                          <td>{strategy.town_name}</td>
                          <td ><span className={`${!strategy.activity_status && "text-muted"}`}>{strategy.activity_status ? strategy.activity_status : "No Status Set"}</span></td>
                          {/*<td><span className="text-muted">*/}
                          {/*  {strategy.agent_rating && strategy.agent_rating > 0 && strategy.agent_rating < 6 ?*/}
                          {/*    <ul className="rating-icons">*/}
                          {/*      <li><img src={strategy.agent_rating >= 1 ? "img/rating-active.png" : "img/rating-inactive.png"} /></li>*/}
                          {/*      <li><img src={strategy.agent_rating >= 2 ? "img/rating-active.png" : "img/rating-inactive.png"} /></li>*/}
                          {/*      <li><img src={strategy.agent_rating >= 3 ? "img/rating-active.png" : "img/rating-inactive.png"} /></li>*/}
                          {/*      <li><img src={strategy.agent_rating >= 4 ? "img/rating-active.png" : "img/rating-inactive.png"} /></li>*/}
                          {/*      <li><img src={strategy.agent_rating === 5 ? "img/rating-active.png" : "img/rating-inactive.png"} /></li>*/}
                          {/*    </ul>*/}
                          {/*    : "No Rating"*/}
                          {/*  }*/}
                          {/*</span></td>*/}

                          {/*<td>*/}
                          {/*  <button*/}
                          {/*    className="btn btn__btn btn__link"*/}
                          {/*    onClick={() => this.showLeaveCommentModal(strategy.town_id)}*/}
                          {/*  >*/}
                          {/*    Leave Comment*/}
                          {/*  {user &&*/}
                          {/*    (user.role === 'strategist' || user.role === 'admin') &&*/}
                          {/*    strategy.comments > 0 ? (*/}
                          {/*      <span className="leave-comment-count">{strategy.comments}</span>*/}
                          {/*    ) : (*/}
                          {/*      ''*/}
                          {/*    )}*/}
                          {/*  </button>*/}
                          {/*</td>*/}
                          <td>
                              {strategy.isInsight === true && <button className="btn btn__btn btn__link" onClick={() => this.showInsightsDrawer(strategy.town_id, strategy.town_name)}>
                            Share Insight
                            </button>}
                            </td>
                          {/*<td>*/}
                          {/*  {*/}
                          {/*    (strategy.role === "agent") &&*/}
                          {/*    <button className="btn btn__btn btn__link" onClick={() => this.openScheduleAppointmentModal(strategy.agent_id, strategy.agent_full_name, strategy.agent_time_zone) }>*/}
                          {/*        Schedule Agent Call*/}
                          {/*      </button>*/}
                          {/*  }*/}
                          {/*</td>*/}

                         <td>
                            <div className="material-textfield w__300">
                              <select
                                className="form-control custom-select material-textfield-input"
                                value={strategy.town_status}
                                onChange={(event) => this.handleChangeClientStatus(event, strategy.agent_id, strategy.client_id, strategy.town_id)}
                              >
                                <option value={1}>Favorites and Ready to Explore</option>
                                <option value={3}>Interested but not ready to tour yet</option>
                                <option value={0}>Not Interested</option>
                              </select>
                              <label className="material-textfield-label">Client Column</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                :
                <div className="blank__state__text pt__25 pb__25">No strategy has been created for this client yet</div>
                }
            </div>
          </Spin>

        </div>
        {this.state.visible && <Insights
          townId={townId}
          townName={townName}
          closeDrawer={this.onClose}
          visible={this.state.visible}
          {...this.props}
        />}
        {modalIsOpen && <AgentAppointment
          client={{
            clientId: client.client_id,
            name: client.client_full_name,
          }}
          agent={{
            agentId: get(this.state, 'agent.agentId', ''),
            name: get(this.state, 'agent.name', ''),
            timeZone: get(this.state, 'agent.timeZone', ''),
          }}
          isAppointmentModelOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          {...this.props}
        />}
        {leaveCommentModal && <LeaveComment
          townId={this.state.townId}
          visible={leaveCommentModal}
          onClose={this.closeModal}
          {...this.props}
        />}
      </div>
    )
  }
}
