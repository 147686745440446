import { connect } from 'react-redux'

import AiAnswersComponent from './component'

import {
  getClientQuestion,
  clearClientDetailPhase,
  approveRecommendedTowns,
  getUnapprovedRecommendedTowns,
} from '../../../../store/strategistV2/clientdetail/action'

const AiAnswersContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    clientQuestion: state.strategistV2.clientDetail.clientQuestion,
    fetchClientQuestionPhase:
      state.strategistV2.clientDetail.fetchClientQuestionPhase,
    recommendedTowns: state.strategistV2.clientDetail.recommendedTowns,
    approveRecommendedPhase:
      state.strategistV2.clientDetail.approveRecommendedPhase,
    fetchClientRecommendedTownPhase:
      state.strategistV2.clientDetail.fetchClientRecommendedTownPhase,
  }),
  // Map actions to dispatch and props
  {
    getClientQuestion,
    clearClientDetailPhase,
    approveRecommendedTowns,
    getUnapprovedRecommendedTowns,
  }
)(AiAnswersComponent)

export default AiAnswersContainer
