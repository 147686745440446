import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import config from '../../../config'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME

class NoAgentRatingReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      users_limit:100,
      activePage:1,
      sortByKey: '',
      sortByType : 0,
      isLoadingData: true,
      totalPages:0,
      search: '',
    }
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)

      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getNoAgentRatingData(strRatingParams)

      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='local' || _user.role === 'business development'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='strategist'){
        this.props.history.push('/home/new-clients')
      }

    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.noAgentRatingPhase === "success"){
      this.setState({ isLoadingData: false, totalPages:nextProps.noAgentRatingData.total_count })
    }
  }

  // On entry change get records
  handleEntryChange(event){
    this.setState({isLoadingData: true, users_limit: event.target.value, activePage: 1}, ()=>{
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getNoAgentRatingData(strRatingParams)
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ isLoadingData: true, activePage: pageNumber}, ()=> {
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getNoAgentRatingData(strRatingParams)
    })
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType}, ()=>{
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getNoAgentRatingData(strRatingParams)
    })
  }

  onSearch(e){
    this.setState({search:e.target.value})
  }

  clickOnClient(clientId, e){
    this.props.history.push(`/activity/${clientId}`)
  }

  render() {
    const { noAgentRatingData } = this.props

    let ratingReport = '';
    let ratingAllData = noAgentRatingData && noAgentRatingData.data
    if(ratingAllData){
      ratingReport = ratingAllData.filter(
        (ratingData) =>{
          return (
            (ratingData.agent_full_name.toLowerCase().indexOf(this.state.search.toLowerCase()) && ratingData.client_full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    return (
      <div>
        <div className="full__page--header light-bg">
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <a><span className="reporting-title">Jungler Analytics</span></a>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="container">
        <div className="row">
            <div className="col-md-6"><h4 className="reporting-loginrecords">Agent No Rating Report</h4></div>
            <div className="col-md-6 text-right">
            <div className="filter__container--filter-name rem-flex">
              <div className="col-md-12 newfilter-list text-right" style={{right: '0'}}>
              <ul>
              <li>
              <span className="enteries-span">
              <label className="enteries-label">Entries</label>
               <select value = {this.state.users_limit} onChange={this.handleEntryChange.bind(this)}>
                  {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                      <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                    })}
                </select>
              </span>  
              </li>
              <li>
              <span className="totalRecords">Total Records ({this.state.totalPages > 0  ? this.state.totalPages : 0})</span>
              </li>
              </ul>
              </div>
              </div>
            </div>
          </div>
          {<div className="row">
            <div className="col-md-12">
              <form className="navbar__header--form">
                <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search agent or  client"/>
              </span>
              </form>
          </div>
          </div>}
          
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
                <div>
                  <div className="filter__sub--container filter__sub--container-edit">
                    <div className="jungler__list useslist__extra">
                       <div className="table-responsive">
                         <table className="table table-striped">
                            <thead>
                              <tr>
                                <th onClick={this.sortData.bind(this , 'client_full_name')}>Client Name</th>
                                <th onClick={this.sortData.bind(this , 'agent_full_name')}>Agent Name</th>
                                <th onClick={this.sortData.bind(this , 'strategist_full_name')}>Strategist Name</th>
                                <th onClick={this.sortData.bind(this , 'appointment_date')}>Appointment Date</th>
                                <th onClick={this.sortData.bind(this , 'status')}>Pipeline Stage</th>
                              </tr>
                            </thead>
                            {this.state.isLoadingData === true ?
                            <tbody>
                                <tr>
                                  <td colSpan="5" className="noClientResults text-center">
                                  <img src="../../img/loader2.svg" />
                                  <h5>Your No Agent Rating Report is generating, please wait.........</h5>
                                  </td>
                                  </tr>
                            </tbody>
                            :
                            <tbody>
                            {
                              ratingReport && ratingReport.length > 0 ?
                              ratingReport.map((c,index) => {
                              return(
                              <tr key={index} >
                                {/*----------Client full name ----------*/}
                                <td className="client__click" onClick={this.clickOnClient.bind(this, c.client_id)}>{c.client_full_name ? c.client_full_name  :'N/A' }</td>

                                {/*----------Agent full name--------*/}
                                <td>{c.agent_full_name ? c.agent_full_name:'N/A'}</td>
                                <td>{c.strategists && c.strategists.join(", ")}</td>

                               
                                <td>{c.appointment_date == '0000-00-00 12:00:00 AM' ? '-' : c.appointment_date}</td>
                                <td>{c.status}</td>
                              </tr>
                               )
                              }) : 
                              <tr><td colSpan="6" className="noClientResults">No record found</td></tr> 
                              }
                            </tbody> 
                            }
                        </table>
                       </div>
                   </div>
                 </div>
               </div>
              </main>
              {
                (ratingReport && ratingReport.length > 0)?
                  <div className="pagination-container">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.users_limit}
                    totalItemsCount={this.state.totalPages}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                :''
              }
              
             
            </div>
          </div>
        </div>
        {/*<div className="dashboard-footer text-center">
              Suburban Jungler - Agent No Rating Report
        </div>*/}
      </div>

    )
  }
}

export default reduxForm({
  form: 'noagentratingreport',  // a unique identifier for this form
  destroyOnUnmount: true,
})(NoAgentRatingReport)
