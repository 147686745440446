export const FETCH_ACTIVITY_CLIENT_EMAIL =
  'suburban/strategistV2/activity/FETCH_ACTIVITY_CLIENT_EMAIL'
export const FETCH_ACTIVITY_CLIENT_EMAIL_SUCCESS =
  'suburban/strategistV2/activity/FETCH_ACTIVITY_CLIENT_EMAIL_SUCCESS'
export const FETCH_ACTIVITY_CLIENT_EMAIL_ERROR =
  'suburban/strategistV2/activity/FETCH_ACTIVITY_CLIENT_EMAIL_ERROR'

export const FETCH_CLIENT_ACTIVITY_HISTORY =
  'suburban/strategistV2/activity/FETCH_CLIENT_ACTIVITY_HISTORY'
export const FETCH_CLIENT_ACTIVITY_HISTORY_SUCCESS =
  'suburban/strategistV2/activity/FETCH_CLIENT_ACTIVITY_HISTORY_SUCCESS'
export const FETCH_CLIENT_ACTIVITY_HISTORY_ERROR =
  'suburban/strategistV2/activity/FETCH_CLIENT_ACTIVITY_HISTORY_ERROR'

export const CLEAR_CLIENT_ACTIVITY_PHASE =
  'suburban/strategistV2/activity/CLEAR_CLIENT_ACTIVITY_PHASE'
