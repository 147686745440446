import { connect } from 'react-redux'
import {loginUser, handleSignOut, getNotifications, clearNotification} from '../../store/user/duck'
import CalendarAppointmentForm from './component'
const Calendar = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase
  }),
  // Map actions to props
  {
    loginUser,
    handleSignOut,
    getNotifications,
    clearNotification
  }
)(CalendarAppointmentForm)
export default Calendar
