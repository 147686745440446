export const GET_RESEARCH_TOWN_DATA =
  'suburban/strategistV2/towns/GET_RESEARCH_TOWN_DATA'
export const GET_RESEARCH_TOWN_DATA_SUCCESS =
  'suburban/strategistV2/towns/GET_RESEARCH_TOWN_DATA_SUCCESS'
export const GET_RESEARCH_TOWN_DATA_ERROR =
  'suburban/strategistV2/towns/GET_RESEARCH_TOWN_DATA_ERROR'

export const UPDATE_RESEARCH_TOWN_DATA =
  'suburban/strategistV2/towns/UPDATE_RESEARCH_TOWN_DATA'
export const UPDATE_RESEARCH_TOWN_DATA_SUCCESS =
  'suburban/strategistV2/towns/UPDATE_RESEARCH_TOWN_DATA_SUCCESS'
export const UPDATE_RESEARCH_TOWN_DATA_ERROR =
  'suburban/strategistV2/towns/UPDATE_RESEARCH_TOWN_DATA_ERROR'

  export const UPDATE_SCHOOL_ORDER =
  'suburban/strategistV2/towns/UPDATE_SCHOOL_ORDER'
export const UPDATE_SCHOOL_ORDER_SUCCESS =
  'suburban/strategistV2/towns/UPDATE_SCHOOL_ORDER_SUCCESS'
export const UPDATE_SCHOOL_ORDER_ERROR =
  'suburban/strategistV2/towns/UPDATE_SCHOOL_ORDER_ERROR'

export const CLEAR_TOWNS_PHASE = 'suburban/strategistV2/towns/CLEAR_TOWNS_PHASE'
