import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { Field, ErrorMessage } from 'formik'

export default function PayoutAdditionalFields({ values, setFieldValue }) {

  const handleChangeRefferalPayout = ({ target }) => setFieldValue('refferal_payout_amount', target.value)

  const handleChangeDealPayout = ({ target }) => setFieldValue('deal_payout_amount', target.value)

  return (
    <Fragment>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select" name="deal_payout" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select</option>
            <option value="percentageOfDeal">% of Deal </option>
            <option value="flat">Flat</option>
          </Field>
          <label className="material-textfield-label label-lg">Deal Payout
          {/* <span className="text-danger">*</span> */}
          </label>
          <ErrorMessage className="invalid-feedback" name="deal_payout" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
           <NumberFormat
              name="deal_payout_amount"
              className="form-control material-textfield-input textfield-input-lg"
              // onChange={handleChangeDealPayout}
              onValueChange={
                (value) => {
                  setFieldValue('deal_payout_amount', value.floatValue);
                }
              }
              thousandSeparator={true}
              prefix="$"
              value={values.deal_payout_amount}
              decimalScale={2}
              required
            />
          <label className="material-textfield-label label-lg">Deal Payout
          {/* <span className="text-danger">*</span> */}
           </label>
          <ErrorMessage className="invalid-feedback" name="deal_payout_amount" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select" name="refferal_payout" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select</option>
            <option value="percentageOfDeal">% of Deal</option>
            <option value="flat">Flat</option>
          </Field>
          <label className="material-textfield-label label-lg">BD Referral Payout
          {/* <span className="text-danger">*</span> */}
          </label>
          <ErrorMessage className="invalid-feedback" name="refferal_payout" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
            <NumberFormat
              name="refferal_payout_amount"
              className="form-control material-textfield-input textfield-input-lg"
              // onChange={handleChangeRefferalPayout}
              onValueChange={
                (value) => {
                  setFieldValue('refferal_payout_amount', value.floatValue);
                }
              }
              thousandSeparator={true}
              prefix="$"
              value={values.refferal_payout_amount}
              decimalScale={2}
              required
            />
          <label className="material-textfield-label label-lg">Deal Payout
          {/* <span className="text-danger">*</span> */}
           </label>
          <ErrorMessage className="invalid-feedback" name="refferal_payout_amount" component="div" />
        </div>
      </div>
    </Fragment>
  )
}
