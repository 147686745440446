import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import SideNavigation from '../../components/sideNavigation'
import TopNavigation from '../TopNavigation/container'
import styles from './styles.scss'
import monthpicker from './monthpicker.css'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import Picker from 'react-month-picker'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};


class AdminDashboardForm extends Component {
  constructor(props) {
    super(props)
     this.state = {
      year:new Date().getFullYear(),
      month: new Date().getMonth(),
      mvalue: {year: new Date().getFullYear(), month: new Date().getMonth()},
      fromDate: moment().subtract(14, 'days'),
      endDate: moment(),
      role: '',
      isLoading: false
    }
  }

  componentWillMount() {
    const {fetchUser} = this.props;
    let _user = cookies.get('user')
    this.setState({ isLoading: true })
    if(_user) {
      
      if(_user.role ==="agent" || _user.role ==="local" ||  _user.role ==="client" ||  _user.role ==="strategist"){
        this.props.history.push({ pathname: '/login'})
      }
      if(localStorage.getItem('startDate')) {
        this.setState({ fromDate: moment(localStorage.getItem('startDate'))})
      } else {
        localStorage.setItem('startDate', moment(this.state.fromDate).format("YYYY/MM/DD"))
      }
      if(localStorage.getItem('endDate')) {
        this.setState({ endDate: moment(localStorage.getItem('endDate'))})
      } else {
        localStorage.setItem('endDate', moment(this.state.endDate).format("YYYY/MM/DD"))
      }
      this.setState({userid: _user.id, role: _user.role})
      setTimeout(function(e) {
        const data = {}
        data.user_id = _user.id;
        fetchUser(data)
        const analyticsData = {}
        analyticsData.startDate = moment(this.state.fromDate).format("YYYY/MM/DD")
        analyticsData.endDate = moment(this.state.endDate).format("YYYY/MM/DD")
        this.props.dashboardClients(analyticsData)
      }.bind(this), 300)
    }
  }

  handleFromDate(date) {
    let sDate = moment(date._d).format("YYYY/MM/DD")
    let eDate = moment(this.state.endDate).format("YYYY/MM/DD")
    let day =  moment(sDate._d).format("DD")
    let month =  moment(sDate._d).format("MM")
    let year =  moment(sDate._d).format("YYYY")
    setTimeout(function(e) {
      const analyticsData = {}
      analyticsData.dateType = "from_date"
      analyticsData.year = year;
      analyticsData.month = month;
      analyticsData.startDate = sDate;
      analyticsData.endDate = eDate;      
      this.props.dashboardClients(analyticsData)
    }.bind(this), 300)
    localStorage.setItem('startDate', sDate)
    localStorage.setItem('endDate', eDate)
    this.setState({fromDate: date})
  }

  handEndDate(date) {
    let eDate = moment(date._d).format("YYYY/MM/DD")
    let sDate = moment(this.state.fromDate).format("YYYY/MM/DD")
    let day =  moment(eDate._d).format("DD")
    let month =  moment(eDate._d).format("MM")
    let year =  moment(eDate._d).format("YYYY")
    setTimeout(function(e) {
      const analyticsData = {}
      analyticsData.dateType = "to_date"
      analyticsData.year = year;
      analyticsData.month = month;
      analyticsData.startDate = sDate;
      analyticsData.endDate = eDate;   
      this.props.dashboardClients(analyticsData)
    }.bind(this), 300)
    localStorage.setItem('startDate', sDate)
    localStorage.setItem('endDate', eDate)
    this.setState({endDate: date})
  }

  componentWillReceiveProps(nextProps) {
    const { fetchUser, user } = this.props;
    let _userd = cookies.get('user')
    let self = this
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    }
    if (nextProps.profilePhase != "success" && _userd && user) {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile updated successfully."})
      const data = {}
      data.user_id = this.state.userid
      this.props.fetchUser(data)
    }
    if (nextProps.profilePhase === "success") {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone,
        })
      }
    }
    if(nextProps.dashClientsData && nextProps.dashClientsData.status === true){
      this.setState({ isLoading: false })
    }
    if(nextProps.dashClientsData && nextProps.dashClientsData.status === false){
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(){
    document.title = "Jungler: Dashboard"
  }

  render() {
    const {
      dashClientsData,
      user
    } = this.props
    const pickerLang = {
      months: ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"],
        from: 'From', to: 'To',
    }

    const mvalue = this.state.mvalue
    const makeText = m => {
      if (m && m.year && m.month) return (pickerLang.months[m.month-1] + ' ' + m.year)
      return '?'
    }
    const monthNames = ['', "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return (
      <div>
        <TopNavigation {...this.props}/>
        <div id="wrapper">
          <SideNavigation {...this.props}/>
          <div id="page-content-wrapper" className="admindownwrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="admin-dashboardContainer">
                    <div className="row text-center">
                      <div className="dp-wrapper fullwidthwrapper"> 
                        <label className="labelinputs__global" style={{ textAlign: 'left' }}>From</label>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleFromDate.bind(this)}
                          showTimeSelect
                          peekNextMonth
                          selected={this.state.fromDate}
                          value={this.state.fromDate}
                          className="form-control custom-select date__picker">
                        </DatePicker>
                      </div>
                      <div className="dp-wrapper fullwidthwrapper"> 
                        <label className="labelinputs__global" style={{ textAlign: 'left' }}>To</label>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handEndDate.bind(this)}
                          showTimeSelect
                          peekNextMonth
                          selected={this.state.endDate}
                          value={this.state.endDate}
                          className="form-control custom-select date__picker">
                        </DatePicker>
                      </div>
                    </div> 

                    {
                      (this.state.isLoading === true) ?
                      <div className="loader__container">
                          <img src="../../img/loader2.svg" />
                      </div>
                      :
                    
                      (dashClientsData && dashClientsData.data) ?
                    <div className='analyticsContainer'>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="card card-analytics text-center">
                            <h4>{dashClientsData.data.questionnairesCount}</h4>
                            <p>New Clients</p>
                            <br/>
                            <Link to={`/questionnaire-completed`} className="fontsize14">View List of Clients</Link>
                          </div>                              
                        </div>                            
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="card card-analytics text-center">
                            <h4>{dashClientsData.data.appointmentsCount}</h4>
                            <p>Appointments Scheduled</p>
                            <br/>
                            <Link to={`/appointment-scheduled`} className="fontsize14">View List of Clients</Link>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="reports__listing--all">
                          <h4>Jungler Reports</h4>
                            <ul>
                              <li>
                                <Link to={`/clients-per-agents`} className="fontsize14">
                                  # of Clients per Agent
                                </Link>
                              </li>
                              <li>
                              <Link to={`/accounting-report`} className="fontsize14">
                                  Accounting Report
                                </Link>
                              </li>
                               <li>
                                <Link to={`/agents-assigned-to-towns`} className="fontsize14">
                                  Agents Assigned to Towns
                                </Link>
                                </li>
                              <li>
                              <Link to={`/agent-rating-report`} className="fontsize14">
                                  Agent Rating Report
                                </Link>
                              </li>
                              <li>
                                <Link to={`/agent-statistics`} className="fontsize14">
                                  Agent Statistics
                                </Link>
                              </li>
                              <li>
                                <Link to={`/ai-clients`} className="fontsize14">
                                  AI Clients
                                </Link>
                              </li>
                              {
                                user && user.role === 'admin' && user.email === 'barbara@suburbanjunglegroup.com' ?
                                (
                                  <li>
                                    <Link to={`/business-development`}>
                                      Business Development
                                    </Link>
                                  </li>
                                ) : ''
                              }
                              
                              <li>
                                <Link to={`/clients-assign-per-town`} className="fontsize14">
                                  Clients assigned per town
                                </Link>
                              </li>
                              <li>
                                <Link to={`/clients-assign-to-agents`} className="fontsize14">
                                  Clients assigned to agents
                                </Link>
                              </li>
                              <li>
                                <Link to={`/closed-clients-per-towns`} className="fontsize14">
                                  Closed Clients Per Town
                                </Link>
                              </li>
                              <li>
                                <Link to={`/login-records`} className="fontsize14">
                                  Login Records
                                </Link>
                              </li>
                              <li>
                                <Link to={`/no-initial-call`} className="fontsize14">
                                  No Initial or Follow up Agent Call
                                </Link>
                              </li>
                              <li>
                                <Link to={`/no-initial-tour`} className="fontsize14">
                                  No Initial or Follow up agent visit
                                </Link>
                              </li>
                              <li>
                                <Link to={`/not-touched-in-4-weeks`} className="fontsize14">
                                  Not Touched by Agent in 4 Weeks
                                </Link>
                              </li>
                              <li>
                              <Link to={`/strategist-rating-report`} className="fontsize14">
                                  Strategist Rating Report
                                </Link>
                              </li>
                              <li>
                                <Link to={`/strategist-statistics`} className="fontsize14">
                                  Strategist Statistics
                                </Link>
                              </li>
                              <li>
                                <Link to={`/strategist-timesheet`} className="fontsize14">
                                  Strategist Timesheet
                                </Link>
                              </li>
                              <li>
                                <Link to={`/total-clients-received-per-month`} className="fontsize14">
                                  Total clients received per month
                                </Link>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                      :
                      <div className='analyticsContainer'>
                        <div>
                         Data not available
                      </div>
                    </div>
                    
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'admindashboard',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AdminDashboardForm)
