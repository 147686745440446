import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
// import GoogleDrive from './Drive';

import './styles.scss';
import get from 'lodash/get';

export default class FileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
      drive: false,
      pdfUrl: '',
      token: '',
      err: ''
    };
  }

  static propTypes = {
    // PropTypes go here
  };

  dragDrop = acceptFile => {
    const reader = new FileReader();
    reader.onload = r => {
      if (acceptFile[0]) {
        this.setState({
          fileName: get(acceptFile, '[0].name'),
          file: acceptFile
        });
      }
    };
    reader.readAsDataURL(acceptFile[0]);
  };

  async sendData(data, token) {
    if (data[0].mimeType === 'application/pdf') {
      this.setState({
        fileName: data[0].name,
        file: data[0],
        fileType: data[0].mimeType,
        drive: true,
        pdfUrl: data[0].url,
        token
      });
    } else {
      this.setState({ err: 'Select correct file' })
    }
  }

  addInsight = e => {
    e.preventDefault();
    
    const { file } = this.state
    const note = this.state.note
    const townId = this.props.match.params.townId
    
    this.props.toggleLoading(true);
    
    const formdata = new FormData();
    
    formdata.append('townId', townId);
    formdata.append('note', note);
    formdata.append('file', file[0]);

    this.props.addInsight(formdata);
  };

  render() {
    const { insight } = this.props;
    const { fileName, note, err } = this.state;
    return (
      <div>
        <form onSubmit={this.addInsight}>
          <div className="text-right mb__20">

            {/*
            <GoogleDrive key="googleDrive" sendData={this.sendData} />
            */}

            {/* <button type="button" className="btn btn__btn btn-dark">
              Select Image(s) from Google Drive
            </button> */}
          </div>
          {/* <Dropzone
            className="dashed-border"
            multiple={false}
            onDrop={this.dragDrop.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <h5 {...getRootProps()} className="drag-box">
                <span className="Upload_txt">
                  <input {...getInputProps()} />
                  <span>
                    Drag & Drop <br />
                    or
                  </span>
                  <br />
                  Upload
                </span>
              </h5>
            )}
          </Dropzone> */}
          <div className="dropzone">
            <Dropzone
              accept="application/pdf"
              multiple={false}
              onDrop={this.dragDrop.bind(this)}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="upload-container">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag and Drop or 
                    </div>
                    <button
                      type="button"
                      className="btn btn__btn btn-dark btn-upload"
                    >
                      Browse and Upload
                    </button>
                    <div className="upload-text">
                      PDF Files
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            {err}
          </div>
          {fileName ? fileName : get(insight, 'file_name')}
          <div className="form-group material-textfield">
            <textarea
              name="note"
              onChange={e => this.setState({ note: e.target.value })}
              defaultValue={get(insight, 'note', '')}
              value={note}
              className="form-control material-textfield-input textfield-input-lg"
              rows="6"
              required
            ></textarea>
            <label className="material-textfield-label label-lg">
              Add a note about your file...{' '}
            </label>
          </div>
          <button
            hidden
            ref={this.props.submitBtnRef}
            type="submit"
            className="btn btn__btn btn-dark btn__lg w__100"
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}
