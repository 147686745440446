import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'


export default class SideNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {open: false};
  }

  render() {
    const {user, location} = this.props;
    const currentuser = user && user;
    const currentPath = location.pathname;
    if(currentuser && currentuser.role ==='superadmin' || currentuser && currentuser.role ==='admin'){
       return (
      <div>
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
              <li><Link to={`/dashboard`} className="sidebar-brand "><img src="img/logo.svg"/></Link></li>
               <li><Link to={`/dashboard`} className={currentPath == '/dashboard' ? "active" : ""} >
               <img className="non-active"  src="img/dashboard-green.svg"/>
              <img className="active" src="img/dashboard.svg"/>Dashboard</Link></li>

              <li> <Link to={`/home/new-clients`} className={currentPath.indexOf('home') > 0 ? "active" : ""} ><img className="non-active" src="img/home-icon-green.svg"/><img className="active" src="img/home-icon.svg"/>Home</Link></li>
              <li><Link to={`/clients-list`} className={currentPath == '/clients-list' ? "active" : ""} ><img className="non-active" src="img/clients-icon-green.svg"/><img className="active" src="img/clients-icon.svg"/>Clients</Link></li>
              <li><Link to={`/jungler-users`} className={currentPath == '/jungler-users' ? "active" : ""}><img className="non-active" src="img/jungeler_users_green.svg"/><img className="active" src="img/jungeler_users.svg"/>Jungler Users</Link></li>
              <li><Link to={`/town-report`} className={currentPath == '/town-report' ? "active" : ""}><img className="non-active" src="img/report_green.svg"/><img className="active" src="img/report.svg"/>Town Reports</Link></li>
              <li><Link to={`/ai-towns`} className={currentPath == '/ai-towns' ? "active" : ""} >
                <img className="non-active sidebar__nav--img"  src="img/ai-green.svg"/>
                <img className="active sidebar__nav--img" src="img/ai-white.svg"/>AI</Link>
              </li>
            </ul>
          </div>
      </div>
    );
    }/*else if(currentuser && currentuser.role ==='admin') {
    return (
      <div>
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
              <li><Link to={`/home/new-clients`} className="sidebar-brand "><img src="img/logo.svg"/></Link></li>
              <li> <Link to={`/home/new-clients`} className={currentPath == '/home/new-clients' ? "active" : ""} ><img className="non-active" src="img/home-icon-green.svg"/><img className="active" src="img/home-icon.svg"/>Home</Link></li>
              <li><Link to={`/clients-list`} className={currentPath == '/clients-list' ? "active" : ""} ><img className="non-active" src="img/clients-icon-green.svg"/><img className="active" src="img/clients-icon.svg"/>Clients</Link></li>
              <li><Link to={`/jungler-users`} className={currentPath == '/jungler-users' ? "active" : ""}><img className="non-active" src="img/jungeler_users_green.svg"/><img className="active" src="img/jungeler_users.svg"/>Jungler Users</Link></li>
            </ul>

          </div>
      </div>
    );
  }*/ else if(currentuser && currentuser.role ==='strategist') {
    return (
      <div>
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
              <li><Link to={`/home/new-clients`} className="sidebar-brand "><img src="img/logo.svg"/></Link></li>
              <li><Link to={`/strategist-dashboard`} className={currentPath == '/strategist-dashboard' ? "active" : ""} >
               <img className="non-active"  src="img/dashboard-green.svg"/>
              <img className="active" src="img/dashboard.svg"/>Dashboard</Link></li>
              <li> <Link to={`/home/new-clients`} className={currentPath == '/home/new-clients' ? "active" : ""} ><img className="non-active" src="img/home-icon-green.svg"/><img className="active" src="img/home-icon.svg"/>Home</Link></li>
              <li><Link to={`/clients-list`} className={currentPath == '/clients-list' ? "active" : ""} ><img className="non-active" src="img/clients-icon-green.svg"/><img className="active" src="img/clients-icon.svg"/>Clients</Link></li>
              <li><Link to={`/jungler-users`} className={currentPath == '/jungler-users' ? "active" : ""}><img className="non-active" src="img/jungeler_users_green.svg"/><img className="active" src="img/jungeler_users.svg"/>Jungler Users</Link></li>
              <li><Link to={`/town-report`} className={currentPath == '/town-report' ? "active" : ""}><img className="non-active" src="img/report_green.svg"/><img className="active" src="img/report.svg"/>Town Reports</Link></li>
              <li><Link to={`/ai-towns`} className={currentPath == '/ai-towns' ? "active" : ""} >
                <img className="non-active sidebar__nav--img"  src="img/ai-green.svg"/>
                <img className="active sidebar__nav--img" src="img/ai-white.svg"/>AI</Link>
              </li>
            </ul>

            {/* <ul className="sidebar-nav sidebar-nav-bottom">
              <li><Link to={`/admin-dashboard`}><img className="non-active" src="img/report-orange.svg"/><img className="active" src="img/report.svg"/>Reporting</Link></li>
            </ul> */}
          </div>
      </div>
    );
  }else  {
    return (
      <div>
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
              <li><Link to={`/home/new-clients`} className="sidebar-brand "><img src="img/logo.svg"/></Link></li>
              <li> <Link to={`/home/new-clients`} className={currentPath == '/home/new-clients' ? "active" : ""} ><img className="non-active" src="img/home-icon-green.svg"/><img className="active" src="img/home-icon.svg"/>Home</Link></li>
            </ul>

            {/* <ul className="sidebar-nav sidebar-nav-bottom">
              <li><Link to={`/admin-dashboard`}><img className="non-active" src="img/report-orange.svg"/><img className="active" src="img/report.svg"/>Reporting</Link></li>
            </ul> */}
          </div>
      </div>
    );
  }
}
}
