import Rx from 'rxjs/Rx'
import { Record, List } from 'immutable'
import { combineEpics } from 'redux-observable'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
import * as api from './api'

/**
 * Public: Action Types
 */

export const FETCH_EXAMPLE = 'suburban/agentV2/FETCH_EXAMPLE'
export const FETCH_EXAMPLE_SUCCESS = 'suburban/agentV2/FETCH_EXAMPLE_SUCCESS'
export const FETCH_EXAMPLE_ERROR = 'suburban/agentV2/FETCH_EXAMPLE_ERROR'
export const FETCH_EXAMPLE_CANCELLED = 'suburban/agentV2/FETCH_EXAMPLE_CANCELLED'

export const GET_AGENT_DASHBOARD = 'suburban/agentV2/GET_AGENT_DASHBOARD'
export const GET_AGENT_DASHBOARD_SUCCESS = 'suburban/agentV2/GET_AGENT_DASHBOARD_SUCCESS'
export const GET_AGENT_DASHBOARD_ERROR = 'suburban/agentV2/GET_AGENT_DASHBOARD_ERROR'
export const GET_AGENT_DASHBOARD_CANCELLED = 'suburban/agentV2/GET_AGENT_DASHBOARD_CANCELLED'

export const GET_NOT_TOUCHED_DATA = 'suburban/agentV2/GET_NOT_TOUCHED_DATA'
export const GET_NOT_TOUCHED_DATA_SUCCESS = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_SUCCESS'
export const GET_NOT_TOUCHED_DATA_ERROR = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_ERROR'
export const GET_NOT_TOUCHED_DATA_CANCELLED = 'suburban/agentV2/GET_NOT_TOUCHED_DATA_CANCELLED'

export const GET_NOT_TOUCHED_IN_4WEEK = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK'
export const GET_NOT_TOUCHED_IN_4WEEK_SUCCESS = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_SUCCESS'
export const GET_NOT_TOUCHED_IN_4WEEK_ERROR = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_ERROR'
export const GET_NOT_TOUCHED_IN_4WEEK_CANCELLED = 'suburban/agentV2/GET_NOT_TOUCHED_IN_4WEEK_CANCELLED'

export const FETCH_AGENT_ALL_CLIENT = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT'
export const FETCH_AGENT_ALL_CLIENT_SUCCESS = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT_SUCCESS'
export const FETCH_AGENT_ALL_CLIENT_ERROR = 'suburban/agentV2/FETCH_AGENT_ALL_CLIENT_ERROR'

export const EDIT_CLIENT_CHAT = 'suburban/agentV2/EDIT_CLIENT_CHAT'
export const EDIT_CLIENT_CHAT_SUCCESS = 'suburban/agentV2/EDIT_CLIENT_CHAT_SUCCESS'
export const EDIT_CLIENT_CHAT_ERROR = 'suburban/agentV2/EDIT_CLIENT_CHAT_ERROR'

export const DELETE_CHAT_DATA = 'suburban/agentV2/DELETE_CHAT_DATA'
export const DELETE_CHAT_DATA_SUCCESS = 'suburban/agentV2/DELETE_CHAT_DATA_SUCCESS'
export const DELETE_CHAT_DATA_ERROR = 'suburban/agentV2/DELETE_CHAT_DATA_ERROR'

export const EDIT_STRATEGIST_AGENT_NOTE = 'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE'
export const EDIT_STRATEGIST_AGENT_NOTE_SUCCESS = 'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE_SUCCESS'
export const EDIT_STRATEGIST_AGENT_NOTE_ERROR = 'suburban/agentV2/EDIT_STRATEGIST_AGENT_NOTE_ERROR'

export const DELETE_STRATEGIST_AGENT_NOTE = 'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE'
export const DELETE_STRATEGIST_AGENT_NOTE_SUCCESS = 'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE_SUCCESS'
export const DELETE_STRATEGIST_AGENT_NOTE_ERROR = 'suburban/agentV2/DELETE_STRATEGIST_AGENT_NOTE_ERROR'

export const SUBMIT_AGENT_FORM = 'suburban/agentV2/SUBMIT_AGENT_FORM'
export const SUBMIT_AGENT_FORM_SUCCESS = 'suburban/agentV2/SUBMIT_AGENT_FORM_SUCCESS'
export const SUBMIT_AGENT_FORM_ERROR = 'suburban/agentV2/SUBMIT_AGENT_FORM_ERROR'

export const GET_AGENT_ONBOARD_DATA = 'suburban/agentV2/GET_AGENT_ONBOARD_DATA'
export const GET_AGENT_ONBOARD_DATA_SUCCESS = 'suburban/agentV2/GET_AGENT_ONBOARD_DATA_SUCCESS'
export const GET_AGENT_ONBOARD_DATA_ERROR = 'suburban/agentV2/GET_AGENT_ONBOARD_DATA_ERROR'

export const CLEAR_AGENT_PHASE = 'suburban/agent/CLEAR_AGENT_PHASE'

export const CLEAR_AGENT_SUBMIT_PHASE = 'suburban/agent/CLEAR_AGENT_SUBMIT_PHASE'

/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  exampleThings: List(),
  error: null,
  dashboardPhase: INIT,
  dashboardData: [],
  agentAllClients: [],
  getNotTouchedPhase: INIT,
  notTouchedData: [],
  notTouchedIn4WeekPhase: INIT,
  notTouchedIn4WeekData: [],
  deletePhase: INIT,
  deleteData: [],
  editClientPhase: INIT,
  editClientData: [],
  editStrAgentPhase: INIT,
  editStrAgentData: [],
  deletStrAgentPhase: INIT,
  deletStrAgentData: [],
  submitAgentFormPhase: INIT,
  submitAgentFormData: [],
  onBoardingPhase: INIT, 
  onBoardingData: []

})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case FETCH_EXAMPLE: {
      return state
        .set('error', null)
        .set('phase', LOADING)
    }

    case FETCH_EXAMPLE_SUCCESS: {
      return state
        .set('exampleThings', action.payload.things)
        .set('error', null)
        .set('phase', SUCCESS)
    }

    case FETCH_EXAMPLE_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('phase', ERROR)
    }

    case FETCH_EXAMPLE_CANCELLED: {
      return state
        .set('error', null)
        .set('phase', INIT)
    }

    // Fetch agent dashboard data
    case GET_AGENT_DASHBOARD: {
      return state
        .set('error', null)
        .set('dashboardPhase', LOADING)
    }

    case GET_AGENT_DASHBOARD_SUCCESS: {
      return state
        .set('dashboardData', action.payload)
        .set('error', null)
        .set('dashboardPhase', SUCCESS)
    }

    case GET_AGENT_DASHBOARD_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('dashboardPhase', ERROR)
    }

    case GET_AGENT_DASHBOARD_CANCELLED: {
      return state
        .set('error', null)
        .set('dashboardPhase', INIT)
    }

    // fetch agent all clients details
    case FETCH_AGENT_ALL_CLIENT: {
      return state
        .set('error', null)
        .set('phase', LOADING)
    }
    case FETCH_AGENT_ALL_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentAllClients', payload)
        .set('error', null)
        .set('phase', SUCCESS)
    }

     // delete agent and client chat data
     case DELETE_CHAT_DATA: {
      return state
        .set('error', null)
        .set('deletePhase', LOADING)
    }
    case DELETE_CHAT_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteData', payload)
        .set('error', null)
        .set('deletePhase', SUCCESS)
    }

    //edit strategist and agent note
    case EDIT_STRATEGIST_AGENT_NOTE: {
      return state
        .set('error', null)
        .set('editStrAgentPhase', LOADING)
    }
    case EDIT_STRATEGIST_AGENT_NOTE_SUCCESS: {
      const { payload } = action
      return state
        .set('editStrAgentData', payload)
        .set('error', null)
        .set('editStrAgentPhase', SUCCESS)
    }

    //delet strategist and agent note
    case DELETE_STRATEGIST_AGENT_NOTE: {
      return state
        .set('error', null)
        .set('deletStrAgentPhase', LOADING)
    }
    case DELETE_STRATEGIST_AGENT_NOTE_SUCCESS: {
      const { payload } = action
      return state
        .set('deletStrAgentData', payload)
        .set('error', null)
        .set('deletStrAgentPhase', SUCCESS)
    }

    // Fetch agent not touched data
    case GET_NOT_TOUCHED_DATA: {
      return state
        .set('error', null)
        .set('getNotTouchedPhase', LOADING)
    }

    case GET_NOT_TOUCHED_DATA_SUCCESS: {
      return state
        .set('notTouchedData', action.payload)
        .set('error', null)
        .set('getNotTouchedPhase', SUCCESS)
    }

    case GET_NOT_TOUCHED_DATA_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('getNotTouchedPhase', ERROR)
    }

    case GET_NOT_TOUCHED_DATA_CANCELLED: {
      return state
        .set('error', null)
        .set('getNotTouchedPhase', INIT)
    }


    // Fetch agent not touched in four week data
    case GET_NOT_TOUCHED_IN_4WEEK: {
      return state
        .set('error', null)
        .set('notTouchedIn4WeekPhase', LOADING)
    }

    case GET_NOT_TOUCHED_IN_4WEEK_SUCCESS: {
      const { payload } = action
      return state
        .set('notTouchedIn4WeekData', payload)
        .set('error', null)
        .set('notTouchedIn4WeekPhase', SUCCESS)
    }

    case GET_NOT_TOUCHED_IN_4WEEK_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('notTouchedIn4WeekPhase', ERROR)
    }

    case GET_NOT_TOUCHED_IN_4WEEK_CANCELLED: {
      return state
        .set('error', null)
        .set('notTouchedIn4WeekPhase', INIT)
    }

    case EDIT_CLIENT_CHAT: {
      return state
        .set('error', null)
        .set('editClientPhase', LOADING)
    }

    case EDIT_CLIENT_CHAT_SUCCESS: {
      return state
        .set('editClientData', action.payload)
        .set('error', null)
        .set('editClientPhase', SUCCESS)
    }

    //submit agent sign up form 
    case SUBMIT_AGENT_FORM: {
      return state
        .set('error', null)
        .set('submitAgentFormPhase', LOADING)
    }

    case SUBMIT_AGENT_FORM_SUCCESS: {
      const { payload } = action
      return state
        .set('submitAgentFormData', payload)
        .set('error', null)
        .set('submitAgentFormPhase', SUCCESS)
    }

    case SUBMIT_AGENT_FORM_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('submitAgentFormPhase', ERROR)
    }

    //agent on boarding get data
    case GET_AGENT_ONBOARD_DATA: {
      return state
        .set('error', null)
        .set('onBoardingPhase', LOADING)
    }

    case GET_AGENT_ONBOARD_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('onBoardingData', payload)
        .set('error', null)
        .set('onBoardingPhase', SUCCESS)
    }

    case GET_AGENT_ONBOARD_DATA_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('onBoardingPhase', ERROR)
    }

    case CLEAR_AGENT_PHASE: {
      return state
      .set('phase', INIT)
      .set('dashboardPhase', INIT)
      .set('getNotTouchedPhase', INIT)
      .set('notTouchedIn4WeekPhase', INIT)
      .set('deletePhase', INIT)
      .set('editClientPhase', INIT)
      .set('editStrAgentPhase', INIT)
      .set('deletStrAgentPhase', INIT)
      .set('onBoardingPhase', INIT)
      .set('submitAgentFormPhase', INIT)
    }
    
    case CLEAR_AGENT_SUBMIT_PHASE: {
      return state
      .set('submitAgentFormData', [])
    }

    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const fetchExample = () => ({
  type: FETCH_EXAMPLE
})

export const getDashboardData = (data) => ({
  type: GET_AGENT_DASHBOARD,
  payload: data
})

export const getNotTouchedData = (data) => ({
  type: GET_NOT_TOUCHED_DATA,
  payload: data
})

export const getNotTouchedIn4Week = (data) => ({
  type: GET_NOT_TOUCHED_IN_4WEEK,
  payload: data
})

export const cancelFetchExample = () => ({
  type: FETCH_EXAMPLE_CANCELLED
})

export const fetchAgentAllClient = (data, type) => ({
  type: FETCH_AGENT_ALL_CLIENT,
  payload: {data, type}
})

export const deleteChatData = (data) => ({
  type: DELETE_CHAT_DATA,
  payload: data
})

export const editStrategistAgentNote = (data) => ({
  type: EDIT_STRATEGIST_AGENT_NOTE,
  payload: data
})

export const deleteStrategistAgentNote = (data) => ({
  type: DELETE_STRATEGIST_AGENT_NOTE,
  payload: data
})

export const editClientChat = (data) => ({
  type: EDIT_CLIENT_CHAT,
  payload: data
})

export const sumbitAgentForm = (data) => ({
  type: SUBMIT_AGENT_FORM,
  payload: data
})

export const getAgentOnBoardData = (data) => ({
  type: GET_AGENT_ONBOARD_DATA,
  payload: data
})

export const clearAgentPhase = () =>{
  return {
      type: CLEAR_AGENT_PHASE
  }
}

export const clearAgentSubmitPhase = () =>{
  return {
      type: CLEAR_AGENT_SUBMIT_PHASE
  }
}

/**
 * Private: Epics
 */

const fetchExampleEpic = (action$) =>
  action$
    .ofType(FETCH_EXAMPLE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchExample(action.payload))
        .map((things) => ({
          type: FETCH_EXAMPLE_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: FETCH_EXAMPLE_ERROR,
          payload: { error }
        }))
        .takeUntil(action$.ofType(FETCH_EXAMPLE_CANCELLED))
    })

const getDashboardDataEpic = (action$) =>
  action$
    .ofType(GET_AGENT_DASHBOARD)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getDashboardData(action.payload))
        .map((payload) => ({
          type: GET_AGENT_DASHBOARD_SUCCESS,
          payload: payload
        }))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_DASHBOARD_ERROR,
          payload: { error }
        }))
        .takeUntil(action$.ofType(GET_AGENT_DASHBOARD_CANCELLED))
    })

const getNotTouchedDataEpic = (action$) =>
  action$
    .ofType(GET_NOT_TOUCHED_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getNotTouchedData(action.payload))
        .map((payload) => ({
          type: GET_NOT_TOUCHED_DATA_SUCCESS,
          payload: payload
        }))
        .catch((error) => Rx.Observable.of({
          type: GET_NOT_TOUCHED_DATA_ERROR,
          payload: { error }
        }))
        .takeUntil(action$.ofType(GET_NOT_TOUCHED_DATA_CANCELLED))
    })

const getNotTouchedIn4WeekEpic = (action$) =>
  action$
    .ofType(GET_NOT_TOUCHED_IN_4WEEK)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getNotTouchedIn4Week(action.payload))
        .map((payload) => ({
          type: GET_NOT_TOUCHED_IN_4WEEK_SUCCESS,
          payload: payload
        }))
        .catch((error) => Rx.Observable.of({
          type: GET_NOT_TOUCHED_IN_4WEEK_ERROR,
          payload: { error }
        }))
        .takeUntil(action$.ofType(GET_NOT_TOUCHED_IN_4WEEK_CANCELLED))
    })

// call fetch agent all clinet epic
const fetchAgentAllClientEpic = (action$) =>
  action$
    .ofType(FETCH_AGENT_ALL_CLIENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchAgentAllClients(action.payload))
        .map((things) => ({
          type: FETCH_AGENT_ALL_CLIENT_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: FETCH_EXAMPLE_ERROR,
          payload: { error }
        }))
    })

// call delete agent chat with client data epic
const deleteChatDataEpic = (action$) =>
  action$
    .ofType(DELETE_CHAT_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteChatData(action.payload))
        .map((things) => ({
          type: DELETE_CHAT_DATA_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: DELETE_CHAT_DATA_ERROR,
          payload: { error }
        }))
    })

// call edit strategist and  agent note epic
const editStrategistAgentNoteEpic = (action$) =>
  action$
    .ofType(EDIT_STRATEGIST_AGENT_NOTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editStrategistAgentNote(action.payload))
        .map((things) => ({
          type: EDIT_STRATEGIST_AGENT_NOTE_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: EDIT_STRATEGIST_AGENT_NOTE_ERROR,
          payload: { error }
        }))
    })

// call delete strategist and agent note epic
const deleteStrategistAgentNoteEpic = (action$) =>
  action$
    .ofType(DELETE_STRATEGIST_AGENT_NOTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteStrategistAgentNote(action.payload))
        .map((things) => ({
          type: DELETE_STRATEGIST_AGENT_NOTE_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: DELETE_STRATEGIST_AGENT_NOTE_ERROR,
          payload: { error }
        }))
    })

// call delete strategist and agent note epic
const editClientChatEpic = (action$) =>
  action$
    .ofType(EDIT_CLIENT_CHAT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editClientChat(action.payload))
        .map((things) => ({
          type: EDIT_CLIENT_CHAT_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: EDIT_CLIENT_CHAT_ERROR,
          payload: { error }
        }))
    })

// sumbit agent sign up form 
const sumbitAgentFormEpic = (action$) =>
  action$
    .ofType(SUBMIT_AGENT_FORM)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.sumbitAgentForm(action.payload))
        .map((things) => ({
          type: SUBMIT_AGENT_FORM_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: SUBMIT_AGENT_FORM_ERROR,
          payload: { error }
        }))
    })

// get agent on boarding data
const getAgentOnBoardDataEpic = (action$) =>
  action$
    .ofType(GET_AGENT_ONBOARD_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentOnBoardData(action.payload))
        .map((things) => ({
          type: GET_AGENT_ONBOARD_DATA_SUCCESS,
          payload: things
        }))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_ONBOARD_DATA_ERROR,
          payload: { error }
        }))
    })

/**
 * Public: Export Epics
 */

export const agentV2Epic = combineEpics(
  fetchExampleEpic,
  getDashboardDataEpic,
  getNotTouchedDataEpic,
  fetchAgentAllClientEpic,
  getNotTouchedIn4WeekEpic,
  editStrategistAgentNoteEpic,
  deleteStrategistAgentNoteEpic,
  editClientChatEpic,
  deleteChatDataEpic,
  sumbitAgentFormEpic,
  getAgentOnBoardDataEpic
)
