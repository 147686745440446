import React, { PureComponent, Fragment } from 'react'
import { get, map, size, take, find } from 'lodash'
import moment from 'moment'
import { Spin, Pagination, Drawer } from 'antd'
import Loader from '../../../../components/Loader'
import { SUCCESS, ERROR } from '../../../../constants/phase'
import TopNavigation from '../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import AdministrationTopNavigationMobile from '../../../../components/AdministrationTopNavigationMobile/component'
import { YourAgentColorIcon, CloseIcon } from '../../../../components/icons'

import './styles.scss'
import YourAgentFilter from './partials/AgentFilter'
import YourAgentPageFilter from './partials/AgentPageFilter'
import TableHeader from './partials/TableHeader'
import TableBody from './partials/TableBody'
import AgentMessage from './AgentMessage/container'

export default class YourAgentsComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      activePage: 1,
      viewAll: true,
      totalPage: 0,
      isLoading: false,
      visible: false,
      pageLimit: 20,
      sortByKey: "",
      sortByType: 0,
      tagStatus: [],
      filterOptions: {},
      agentId: 0,
      agentName: ''
    }
    this.filterMyAgentFilterChildRef = React.createRef();
  }
  componentDidMount() {
    this.setState({ isLoading: true })
    this.props.getFilterParams();
    const yourAgentFilterOptions = JSON.parse(localStorage.getItem('yourAgentFilterOptions'))
    if (size(yourAgentFilterOptions)) {
      const filterCount = yourAgentFilterOptions.filterCount
       delete yourAgentFilterOptions.filterCount
       if (this.filterMyAgentFilterChildRef.current) this.filterMyAgentFilterChildRef.current.changeFilterOption({...yourAgentFilterOptions, filterCount})
        this.setState({ filterOptions: {...yourAgentFilterOptions} }, () => this.fetchYourAgentList())
    } else {
      this.fetchYourAgentList()
    }
  }
  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    if (props.fetchYourAgentPhase === SUCCESS) {
      props.clearYourAgentPhase()
      states = { ...state, isLoading: false }
    }
    if (props.fetchYourAgentPhase === ERROR) {
      props.clearYourAgentPhase()
      states = { ...states, isLoading: false }
    }
    if (props && props.agents) {
      states = { ...state, totalPage: props.agents.count, isLoading: false, tagStatus: props.agents.tagStatus }
    }
    return states
  }
  // handle page change on result entity for number of item par page
  handleResultEntity = ({ pageLimit }) => this.setState({ pageLimit }, () => this.fetchYourAgentList())

  fetchYourAgentList = () => {
    const filterData = {
      pageLimit: this.state.pageLimit,
      activePage: this.state.activePage,
      filterOption: this.state.filterOptions,
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType }
   }
   this.setState({ isLoading: true }, () => this.props.fetchYourAgentList(filterData))
  }
  setFilterOptions = (filterOptions) => {
    this.setState({ filterOptions, isLoading: true}, () => this.fetchYourAgentList())
    this.forceUpdate()
  }
  // on click of remove tag we need to remove tag from filter option
  resetFilterHead = (type, option) => {
    let { filterOptions } = this.state
    if (['neverScheduled', 'followUpAgentCall', 'followUpTownTour', 'notTouch4Week'].includes(type)) {
      filterOptions = { ...filterOptions, [type]: false }
    }
    if (type === 'appointmentStartDate') {
      filterOptions = { ...filterOptions, appointmentStartDate: '', appointmentDate: false }
    }
    if (type === 'appointment') {
      const selectedAppointmentType = filterOptions.selectedAppointmentType ? filterOptions.selectedAppointmentType : []
      selectedAppointmentType.splice(selectedAppointmentType.indexOf(option), 1)
      filterOptions = { ...filterOptions, selectedAppointmentType }
    }
    if (type === 'priceRange') {
      filterOptions = { ...filterOptions, minPrice: '', maxPrice: '' }
    }
    if (type === 'status') {
      filterOptions.filteredStatuses = get(filterOptions, 'filteredStatuses', []).filter((val) => val !== option)
    }
    if (type === 'clientName') {
      filterOptions.filteredClients = get(filterOptions, 'filteredClients', []).filter((val) => val !== option)
    }
    if (type === 'strategistName') {
      filterOptions.filteredStrategist = get(filterOptions, 'filteredStrategist', []).filter((val) => val !== option)
    }
    if (this.filterMyAgentFilterChildRef.current) this.filterMyAgentFilterChildRef.current.changeFilterOption(filterOptions)
    this.setState({ filterOptions, isLoading: true }, () => this.fetchYourAgentList())
    this.forceUpdate()
  }
  sortClientData = (sortByKey) => {
    let sortByType = this.state.sortByType
    // eslint-disable-next-line no-nested-ternary
    sortByType = sortByKey !== this.state.sortByKey ? 0 : (sortByType === 0 ? 1 : 0)
    this.setState({ sortByKey, sortByType, activePage: 1, isLoading: true }, () => this.fetchYourAgentList())
  }
  handlePageChange = (activePage) => this.setState({ activePage, isLoading: true }, () => this.fetchYourAgentList());

  // show hide all or less tag to show tages
  viewMoreButton = () => this.setState({ viewAll: !this.state.viewAll })

  // set filter parmas set by child component
  setFilterParams = (data) => this.setState(data)

  onClose = () => this.setState({ visible: false })
  handleOpenDrawer = (clientId, agentId, agentName) => this.setState({ visible: true, clientId, agentId, agentName })

  render() {
    const { isLoading, sortByKey, sortByType, filterOptions, tagStatus, viewAll, agentId, clientId, visible } = this.state
    const selectedTag = viewAll ? take(tagStatus, 10) : tagStatus
    const agents = get(this.props, 'agents.users', [])
    const strategists = get(this.props, 'filterParams.data.strategists', [])
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h2 className="heading-title">
                  <span className="heading-icon">
                    <YourAgentColorIcon className="" />
                  </span>
                  Your Agents
                </h2>
              </div>

              <div className="col-md-6">
                <div className="filter__result__section">
                  {/* This is for filter section */}
                  <YourAgentFilter
                    ref={this.filterMyAgentFilterChildRef}
                    setFilterOptions={this.setFilterOptions}
                    setFilterParams={this.setFilterParams}
                    {...this.props}
                  />
                  {/* End filter section */}
                  <YourAgentPageFilter
                    page={this.state.pageLimit}
                    handleResultEntity={this.handleResultEntity}
                  />
                </div>
              </div>
            </div>

            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div className="tasklist__following__tags mb__25">
                    These are clients with the following tags: {selectedTag.join(', ')}
                    {tagStatus && tagStatus.length > 10 && <span onClick={this.viewMoreButton} className="tags__view_all">{viewAll ? 'View All' : 'View Less'}</span>}
                  </div>
                  <ul className="filter__list__flitered">
                  {filterOptions.neverScheduled && <li>Never Scheduled with Agent <span onClick={() => this.resetFilterHead('neverScheduled')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.followUpAgentCall && <li>No initial or follow up agent call <span onClick={() => this.resetFilterHead('followUpAgentCall')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.followUpTownTour && <li>No initial or follow up agent town tour <span onClick={() => this.resetFilterHead('followUpTownTour')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.notTouch4Week && <li>Agent: Not Touched in 4 Weeks <span onClick={() => this.resetFilterHead('notTouch4Week')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.selectedAppointmentType &&
                      map(filterOptions.selectedAppointmentType, (appointment, index) => (
                        <li key={index}>
                          {appointment}
                          <span onClick={() => this.resetFilterHead('appointment', appointment)}>
                            <CloseIcon className="close-icon" />
                          </span>
                        </li>
                    ))}
                  {filterOptions.appointmentStartDate && <li>{moment(filterOptions.appointmentStartDate).format('MM/DD/YYYY')}<span onClick={() => this.resetFilterHead('appointmentStartDate')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.minPrice && filterOptions.maxPrice && <li>{`${filterOptions.minPrice}-${filterOptions.maxPrice}`}<span onClick={() => this.resetFilterHead('priceRange')}><CloseIcon className="close-icon" /></span></li>}
                  {filterOptions.filteredStatuses &&
                      map(filterOptions.filteredStatuses, (status, index) => (
                        <li key={index}>
                          {status}
                          <span onClick={() => this.resetFilterHead('status', status)}>
                            <CloseIcon className="close-icon" />
                          </span>
                        </li>
                    ))}
                   {filterOptions.filteredClients &&
                      map(filterOptions.filteredClients, (client, index) => {
                        const selectedClient = find(this.state.clients, { id: client })
                        if (size(selectedClient)) {
                          return (<li key={index}>
                          {selectedClient.client_full_name}
                          <span onClick={() => this.resetFilterHead('clientName', client)}>
                            <CloseIcon className="close-icon" />
                          </span>
                        </li>)
                        }
                    })}
                    {filterOptions.filteredStrategist &&
                      map(strategists, (strategist, index) => {
                        if (filterOptions.filteredStrategist.includes(parseInt(strategist.id))) {
                          return (<li key={index}>
                          {strategist.strategist_name}
                          <span onClick={() => this.resetFilterHead('strategistName', strategist.id)}>
                            <CloseIcon className="close-icon" />
                          </span>
                        </li>)
                        }
                    })}

                  </ul>
                  <div className="your__agents__container__responsive">
                    <div className="your__agents__container">
                      <Spin
                        size="large"
                        spinning={isLoading}
                        indicator={<Loader />}
                      >
                        {agents && agents.length > 0 ? (
                          <Fragment>
                            <TableHeader
                              agents={agents}
                              sortByType={sortByType}
                              sortByKey={sortByKey}
                              sortAgentData={this.sortClientData}
                            />
                            <TableBody agents={agents} history={this.props.history} openDrawer={this.handleOpenDrawer} />
                          </Fragment>
                        ) : (
                          <div className="blank__table__state">
                             No Results Found
                          </div>
                        )}
                      </Spin>
                    </div>
                  </div>
                  {this.state.totalPage > this.state.pageLimit &&
                    <Pagination
                      className="mt__20 mb__20"
                      current={this.state.activePage}
                      defaultCurrent={this.state.activePage}
                      total={this.state.totalPage}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      pageSize={this.state.pageLimit}
                      onChange={this.handlePageChange}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
        <Drawer
          placement="right"
          closable={false}
          width="100%"
          onClose={this.onClose}
          visible={visible}
          className="drawer__main"
         >
        {visible && <AgentMessage closeDrawer={this.onClose} agentName={this.state.agentName} clientId={clientId} agentId={agentId} openDrawer={this.handleOpenDrawer} {...this.props} />}
        </Drawer>
      </div>
    )
  }
}
