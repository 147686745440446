import CommunicationPhonelinkSetup from 'material-ui/svg-icons/communication/phonelink-setup'
import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

/*
/////////////////////////////////
/// appointment apis ///
/////////////////////////////////
*/

// fetch appointment list
// const agentAppointmentUrl = `${HOSTNAME_V2}/get-all-appointment` : LEGACY
const agentAppointmentUrl = `${SUBURBANJUNGLE_API_HOST}/agents/appointments`

export const fetchAppointment = (data) => {

  const queryStringData = []
  for (const key in data) {

    if (key === 'sortBy') {

      if (data['sortBy']['key'] !== "") {
        const direction = data['sortBy']['type'] ? 'ASC' : 'DESC' 
        queryStringData.push(['sortKey', data['sortBy']['key']])
        queryStringData.push(['sortDirection',direction])
      }

    } else if (key === 'appointmentType') {

      
      for (const appointmentType of data['appointmentType']) {
        queryStringData.push(['appointmentType', appointmentType])
      }
      

    } else if (data[key]) {
      queryStringData.push([key, data[key]])
    }
    
  }

  const newAgentAppointmentUrl = [
    SUBURBANJUNGLE_API_HOST, 
    '/agents/appointments?',
    new URLSearchParams(queryStringData).toString()
  ].join('')

  return fetch(newAgentAppointmentUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// get strategist availabilty
export const getStrategistAvailability = (data) => {
  const queryString = new URLSearchParams({
    date: data.date,
    timezone: data.timezone
  })
  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategist_id}/availability-times?${queryString}`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// get strategist availability dates
export const getStrategistAvaillableDates = (data) => {
  const queryString = new URLSearchParams({
    date: data.date,
    timezone: data.timezone
  })

  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategistId}/availability-dates?${queryString}`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    })
    .catch((error) => {
      throw error
    })
}

// create agent appointment
export const createAgentAppointment = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/appointments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// create strategist appointment
export const createStrategistAppointment = (data) => {
  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/appointments`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// get agent availability dates
export const getAgentAvailibity = (data) => {
  return fetch(`${HOSTNAME_V2}/availlability/get_agent_availability`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
