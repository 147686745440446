import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { StickyContainer, Sticky } from 'react-sticky'
import { DateRange } from 'react-date-range'
import Modal from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class StrategistTimesheet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      // fromDate: moment().add(-1, 'month').startOf('month'),
      fromDate: moment('2019-03-27'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      selectedDefault: 'all',
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment('2019-03-27'),
      defaultEndDate: moment(),
      strategistTimesheet:[],
      totalDuration:'',
      strategist_id:0,
      strategist_name:'',
      strName:''
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedDefault === "all"){
        strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'total'
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'any'
      }

      if(_user.role === 'admin' || _user.role === 'strategist' ){
        strData.strategist_id = _user.id
      }
     
      this.props.getStrategistTimesheetData(strData)
      this.setState({isLoadingData: true, strategist_id: _user.id})
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    _user = cookies.get('user')
    if(nextProps.strategistTimesheetPhase === "success"){
      var totalDuration = ''
      var totalHours = 0
      var totalMinutes = 0
      var totalSeconds = 0
      const strategistTimesheet = []
      if(nextProps.strategistTimesheetData && nextProps.strategistTimesheetData.length > 0){
        nextProps.strategistTimesheetData.map(function(data){
          if(data.end_date && data.end_date !== null){
            var a = moment(data.start_date)
            var b = moment(data.end_date)

            var diff = Math.abs(a - b) / 1000;
            // get hours
            var hours = Math.floor(diff / 60 / 60);        
            // get minutes
            var minutes = Math.floor(diff / 60) - (hours * 60);
            // get seconds
            var seconds = diff % 60;
            totalHours = totalHours+hours
            totalMinutes = totalMinutes+minutes
            totalSeconds = totalSeconds+seconds
            if(totalMinutes >= 60){
              var tempHours = Math.floor(totalMinutes / 60)
              totalHours = totalHours+tempHours
              totalMinutes = totalMinutes % 60
            }

            if(totalSeconds >= 60){
              var tempSeconds = Math.floor(totalSeconds / 60)
              totalMinutes = totalMinutes+tempSeconds
              totalSeconds = totalSeconds % 60
            }

            var duration = hours !== 0 ? hours+' hr '+minutes+ ' min ' +seconds+ ' sec': minutes+' min ' +seconds+ ' sec'
            var totalTime = totalHours !== 0 ? totalHours+' hr '+totalMinutes+ ' min '+totalSeconds+' sec ' : totalMinutes+' min '+totalSeconds+ ' sec '
            totalDuration = totalTime
          }else{
            var duration = 'process'
          }
          let obj ={}
          obj.client_full_name = data.client_full_name ? data.client_full_name : "N/A"
          obj.strategist_full_name = data.strategist_full_name
          obj.start_date = moment(data.start_date).format('L')
          obj.start_time = moment.utc(data.start_date).tz(_user.time_zone).format('h:mma z')
          obj.end_date = data.end_date
          obj.end_time = data.end_time && data.end_time !== null ? moment.utc(data.end_time).tz(_user.time_zone).format('h:mma z') : 'process'
          // obj.activity_log = data.activity_log === 'client_dashboard' ? 'Activity on client account' : 'phone call: '+data.activity_log
          obj.activity_log = ( (data.client_full_name === "N/A") ? (data.activity_log) : ((data.activity_log === 'client_dashboard') ? 'Activity on client account' : 'phone call: '+data.activity_log))
          obj.status = data.status
          obj.duration = duration
          obj.type = data.type
         
          strategistTimesheet.push(obj)
          
        })
        this.setState({ isLoadingData: false, strategistTimesheet:strategistTimesheet, totalDuration:totalDuration },()=> {})
        // this.props.clearPhase()
      }else{
        this.setState({ isLoadingData: false, strategistTimesheet:strategistTimesheet, totalDuration:totalDuration },()=> {})
        // this.props.clearPhase()
      }

      if( nextProps.filterParams !== this.props.filterParams ){
        let strList = nextProps.filterParams.data && nextProps.filterParams.data.strategists
        if(this.state.strategist_id === 0){
          let strData = _.find(strList, (s) => s.role === "strategist" || s.role === 'admin')
          this.setState({ strategist_id: strData.id, strategist_name: strData.strategist_name })
          const data = {}
          if(this.state.selectedDefault === "all"){
            data.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
            data.to = moment().format("YYYY-MM-DD HH:mm:ss")
            data.selectedValue = 'total'
          }else{
            data.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
            data.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
            data.selectedValue = 'any'
          }
          data.strategist_id = parseInt(strData.id)
          this.props.getStrategistTimesheetData(data)
        }
      }
    }
  }

  componentDidMount() {
    let self = this;
    $(document).click(function(event) {
      if (!$(event.target).closest("#dropdownBtn").length) {
        if ($("#dropdownBtn").is(":visible")) {
          self.setState({ strategistDropdown: false });
        }
      }
    });
    $(document).ready(function() {
      $('tbody').scroll(function(e) { //detect a scroll event on the tbody
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
      });
    });
  }

  datePickerClick(e){
    // this.setState({ datePickerModal: !this.state.datePickerModal, modalIsOpen: true })
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  filterDefaultDate(e){
    const strData = {}
    this.setState({ isLoadingData: true },() => {})
    if(e.target.value === "all"){
      // this.setState({ datePickerModal:false })
      this.setState({ modalIsOpen:false })
      strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'total'
    }else{
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.selectedValue = 'any'
    }
    strData.strategist_id = parseInt(this.state.strategist_id)
    this.props.getStrategistTimesheetData(strData)
    this.setState({ selectedDefault: e.target.value },() => {})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate, defaultStartDate: this.state.fromDate, defaultEndDate: this.state.endDate }, ()=>{
        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedDefault === "all"){
            strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
            strData.selectedValue = 'total'
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
            strData.selectedValue = 'any'
          }
          strData.dateType = "from_date"
          strData.strategist_id = parseInt(this.state.strategist_id)
      this.props.getStrategistTimesheetData(strData)
        }.bind(this), 300)
    })
  }
  
  handleChange(event) {
    let strListData = this.props.filterParams && this.props.filterParams.data && this.props.filterParams.data.strategists
    strListData && strListData.map((val, index)=>{
      if(val.id === parseInt(event.target.value)){
        this.setState({ strategist_name: val.strategist_name })
      }
    })
    this.setState({ strategist_id: event.target.value, isLoadingData: true },()=>{
      const strData = {}
      if(this.state.selectedDefault === "all"){
        strData.from = moment('2016-01-01').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'total'
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
        strData.selectedValue = 'any'
      }
      strData.strategist_id = parseInt(this.state.strategist_id)
      this.props.getStrategistTimesheetData(strData)
    })
  }

  render() {
    _user = cookies.get('user')
    const { datePickerModal, strategistTimesheet, totalDuration } = this.state
    const { strategistTimesheetData, filterParams } = this.props
    let strategistList = filterParams && filterParams.data && filterParams.data.strategists && filterParams.data.strategists.map((val, index) => {
      if(val.role === 'admin' || val.role === 'strategist'){
        return (
          <option key={index} value={val.id}>{val.strategist_name}</option>
        )
      }
    });

    return (
      <div>        
          <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Strategist Timesheets</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{totalDuration}</span></h4></div>
            <div className="col-md-12">
              
            <div className="reports__flex">
              { _user && _user.role === 'superadmin' ? (<div className="clients__received--section">
                  <div className="dp-wrapper stragist-span">
                    <select 
                      className="selectinputs__global" 
                      name="stategist_id"
                      value={this.state.strategist_id}
                      onChange={this.handleChange.bind(this)}>
                      {strategistList}
                    </select>
                  </div>
                </div>) : '' }
              <div className={_user && _user.role === 'superadmin' ? "right__cont" : 'right__cont right__cont--full'}>
                <div className="all__custom--wrapper">
                  <select 
                    className="date__picker"
                    name="selectedDefault"
                    value = {this.state.selectedDefault} 
                    onChange={this.filterDefaultDate.bind(this)}>
                    <option value="all">All</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

              {
                (this.state.selectedDefault !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div>
                      <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}           
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">             
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>               
                          </div>
                        </div>
                      </Modal>
                    </div>
                  }
                </div>
              }
              
              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={strategistTimesheet} name="strategist_timesheet">
                    <ExcelColumn label="Strategist Name" value="strategist_full_name"/>
                    <ExcelColumn label="Client Name" value="client_full_name"/>
                    <ExcelColumn label="Date" value="start_date"/>
                    <ExcelColumn label="Start Time" value="start_time"/>
                    <ExcelColumn label="End Time" value="end_time"/>
                    <ExcelColumn label="Total Duration" value="duration"/>
                    <ExcelColumn label="Activity Type" value="activity_log"/>
                </ExcelSheet>
              </ExcelFile>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
{/*             
            <div className="filter__container filter__container--users desktopFilter">
              <div className="filter__sub--container filter__sub--container-edit">
                 <div className="jungler__list">
                   <div className="table-responsive"> */}

                   <div className="list_table useslist__extra">
                   <StickyContainer>
                     <table className="table table-striped">
                     <Sticky topOffset={50}>
                          {({
                            style,
                            isSticky,
                            wasSticky,
                            distanceFromTop,
                            distanceFromBottom,
                            calculatedHeight
                          }) => (
                            <header className="updiv" style={style}>
                        <thead>
                          <tr>
                            <th className="for__static">Strategist Name</th>
                            <th className="text-center">Client Name</th>
                            <th className="text-center">Date</th>
                            <th className="text-center">Start Time</th>
                            <th className="text-center">End Time</th>
                            <th className="text-center">Total Duration</th>
                            <th className="text-center">Activity Type</th>
                          </tr>
                        </thead>
                        </header>
                          )}
                        </Sticky>
                        {this.state.isLoadingData === true ?
                        <tbody>
                          <tr>
                            <td colSpan="9" className="noClientResults text-center">
                            <img src="../../img/loader2.svg" />
                            <h5>Your Strategist timesheet is generating, please wait...</h5>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                       {
                          strategistTimesheet && strategistTimesheet.map((str, index)=>{
                            let activity = str.activity_log
                            if(str.type === 'ios'){
                              activity = 'iOS app: '+ str.activity_log
                            }
                            return(
                              <tr key={index} className="forcursor" >
                                <td >{str.strategist_full_name}</td>
                                <td className="text-center">{str.client_full_name}</td>
                                <td className="text-center">{str.start_date}</td>
                                <td className="text-center">{str.start_time}</td>
                                <td className="text-center">{str.end_time}</td>
                                <td className="text-center">{str.duration}</td>
                                <td className="text-center">{activity}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody> 
                        }
                      </table>
                      </StickyContainer>
                      </div>
{/*                       
                     </div>
                   </div>
                 </div>
               </div> */}
              </main>

              {
                strategistTimesheetData && strategistTimesheetData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center"><h4>Suburban Jungler - Strategist Statistics</h4></div>*/
                :
                 <div className="dashboard-footer text-center">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'strategistreportlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategistTimesheet)
