import React, { Fragment } from 'react'
import { map, get } from 'lodash'
import { ErrorMessage, Field } from 'formik'

export default function MinMaxPrice({ minPrice, filterParams }) {

  const minPriceOption = map(get(filterParams, 'data.price_brackets', []), (val, index) => (
    <option key={index} value={val.price_min}> {val.price_min.toLocaleString()} </option>
  ));

  return (
    <Fragment>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select"
            name="price_min" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select Price</option>
            {minPriceOption}
          </Field>
          <label className="material-textfield-label label-lg">Select Min Price <span className="text-danger">*</span></label>
          <ErrorMessage className="invalid-feedback" name="price_min" component="div" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group material-textfield">
          <Field as="select" name="price_max" className="form-control custom__select material-textfield-input textfield-input-lg" >
            <option value="">Select Price</option>
            {map(get(filterParams, 'data.price_brackets', []), (val, index) => (
              minPrice < val.price_max && <option key={index} value={val.price_max}>{val.price_max.toLocaleString()} </option>
            ))}
          </Field>
          <label className="material-textfield-label label-lg">Select Max Price <span className="text-danger">*</span></label>
          <ErrorMessage className="invalid-feedback" name="price_max" component="div" />
        </div>
      </div>
    </Fragment>
  )
}
