import React, { PureComponent /*, PropTypes*/ } from 'react';
import Link from './Link/container';
import File from './File/container';
import Note from './Note/container';
import { notification } from 'antd';
import { Formik, Form, Field } from 'formik';
import {
  occupationList,
  landSizes,
  budget,
  fancyLaidBack,
  homeType,
  progressiveConservative,
  sportyArtsy,
  goodForFinding
} from '../../townKeys';

import './styles.scss';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const insightAr = {
  link: 0,
  note: 2,
  file: 1
};

const willingToCommute = [
  { label: 'Up to 30 minutes', value: 'commute_30_to_less' },
  { label: 'Up to 45 minutes', value: 'commute_30_to_45' },
  { label: 'Up to 60 minutes', value: 'commute_45_to_60' },
  { label: '1 hr +', value: 'commute_60_to_high' }
];

const transportation = [
  { label: 'Train', value: 'trans_mode_train' },
  { label: 'Bus', value: 'trans_mode_bus' },
  { label: 'Car', value: 'trans_mode_car' },
  { label: 'Walk', value: 'trans_mode_walk' },
  { label: 'Bike', value: 'trans_mode_bike' },
  { label: 'Ferry', value: 'trans_mode_ferry' }
];

const activities = [
  { label: 'Tennis', value: 'activities_tennis' },
  { label: 'Skiing', value: 'activities_ski' },
  { label: 'Hockey', value: 'activities_hockey' },
  { label: 'Lacrosse', value: 'activities_lacrosse' },
  { label: 'Dance', value: 'activities_dance' },
  { label: 'Squash', value: 'activities_squash' },
  { label: 'Theatre', value: 'activities_theatre' },
  { label: 'Football', value: 'activities_football' },
  { label: 'Writing', value: 'activities_writing' },
  { label: 'Soccer', value: 'activities_soccer' }
];

const RadioInputField = props => {
  return (
    <Field
      {...props}
      checked={
        get(props.values, props.name, props.noval) === props.valueon
          ? true
          : false
      }
      onChange={e =>
        props.toggleradiofields(
          props.name,
          props.valueon,
          props.values,
          props.setfieldvalue
        )
      }
      className="custom__radiobox__input"
    />
  );
};

const CheckboxInputField = props => {
  return (
    <Field
      {...props}
      checked={get(props, `values.${props.name}`, 0) === 1 ? true : false}
      onChange={e => {
        props.setfieldvalue(props.name, e.target.checked ? 1 : 0);

        if (props.name === 'none') {
          //uncheck all good for finding if its none
          goodForFinding.map((g, i) => {
            props.setfieldvalue(g.value, 0);
          });
        }

        if (props.name === 'nice_mix_of_all') {
          // check all fields if its a nice mix of all
          occupationList.map((g, i) => {
            props.setfieldvalue(g.value, e.target.checked ? 1 : 0);
          });
        }
      }}
    />
  );
};
export default class AddEditInsightComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentActiveTab: 0,
      isEditMode: false,
      insight: {},
      town_name: '',
      publicData: []
    };

    this.submitBtnRef = React.createRef();
  }

  componentDidMount() {
    const {
      town,
      match: {
        params: { townId }
      }
    } = this.props;

    // this.props.fetchSchools({ townId: parseInt(townId), type: 'public' });

    if (!isEmpty(get(this, 'props.location.state.insight'))) {
      const insight = get(this, 'props.location.state.insight');
      const insight_type = get(insight, 'insight_type');

      this.setState({
        insight,
        town_name: town ? town.name : "",
        isEditMode: true,
        currentActiveTab: insightAr[insight_type]
      });
    }
  }

  addInsight = async formData => {
    const { currentActiveTab } = this.state;
    this.props.toggleLoading(true);
    const {
      insight,
      match: {
        params: { townId }
      },
      addResearch,
      addResearchFile
    } = this.props;

    if (currentActiveTab === 0 || currentActiveTab === 2) {
      await addResearch(formData);
    }
    if (currentActiveTab === 1) {
      await addResearchFile(formData);
    }
    const url = this.props.history.location.pathname.split(
      `/${insight ? 'add' : 'edit'}`
    );
    this.props.history.push(url[0]);
  };

  static propTypes = {
    // PropTypes go here
  };

  static getDerivedStateFromProps(nextProps, state) {
    const insight = state.insight;
    const obj = {};
    obj.town_name = get(nextProps, 'town.name', '');

    if (get(nextProps, 'addResearchPhase') === 'success') {
      nextProps.clearTownPhase();
      if (get(nextProps, 'addResearchData.status', false)) {
        notification.success({
          message: insight
            ? 'Insight Added Successfully'
            : 'Insight Updated Successfully'
        });
        const url = nextProps.history.location.pathname.split(
          `/${insight ? 'add' : 'edit'}`
        );

        nextProps.history.push(url[0]);
      } else {
        nextProps.toggleLoading(false)
        notification.error({
          message: get(nextProps, 'addResearchData.message')
        });
      }
    }
    if (get(nextProps, 'researchFilePhase') === 'success') {
      nextProps.clearTownPhase();
      notification.success({
        message: insight
          ? 'Insight Added Successfully'
          : 'Insight Updated Successfully'
      });
      const url = nextProps.history.location.pathname.split(
        `/${insight ? 'add' : 'edit'}`
      );

      nextProps.history.push(url[0]);
    }

    if (get(nextProps, 'getSchoolsPhase', false) === 'success') {
      nextProps.clearTownPhase();
      obj.publicData = get(nextProps, 'schoolData.data');
      nextProps.toggleLoading(false);
    }

    return obj;
  }

  changeTab = tab => {
    // if (!this.state.isEditMode) {
      this.setState({ currentActiveTab: tab });
    // }
  };

  toggleRadioFields = (key, val, values, setFieldValue) => {
    if (values[key] === val) {
      setFieldValue(key, -1);
    } else {
      setFieldValue(key, val);
    }

    this.forceUpdate();
  };

  render() {
    const {
      currentActiveTab,
      isEditMode,
      insight,
      publicData,
      town_name
    } = this.state;

    const TabToShow =
      (currentActiveTab === 0 && (
        <Link
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      )) ||
      (currentActiveTab === 1 && (
        <File
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      )) ||
      (currentActiveTab === 2 && (
        <Note
          submitBtnRef={this.submitBtnRef}
          town_name={town_name}
          addInsight={this.addInsight}
          insight={insight}
          {...this.props}
        />
      ));
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">
              {isEditMode ? 'Edit' : 'Add'} Insight
            </h1>
            <h4 className="title__sub__section">
              {isEditMode ? 'Edit' : 'Add'} an insight for{' '}
              {get(this, 'props.town.name')} and select which supporting item
              from below it supports.
            </h4>
          </div>
          <div className="col-md-10">
            <div className="tabs__control mt__0">
              <button
                onClick={() => this.changeTab(0)}
                className={`btn btn-link ${currentActiveTab === 0 && 'active'}`}
              >
                Link
              </button>
              <button
                onClick={() => this.changeTab(1)}
                className={`btn btn-link ${currentActiveTab === 1 && 'active'}`}
              >
                File{' '}
              </button>
              <button
                onClick={() => this.changeTab(2)}
                className={`btn btn-link ${currentActiveTab === 2 && 'active'}`}
              >
                Note{' '}
              </button>
            </div>

            {TabToShow}
          </div>
        </div>

        <>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={data => {
              // this.props.updateAiData(data);
            }}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (



              <Form>
                {/*

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Demo Insight</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Diverse?</label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="diversity"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="diversity"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Income Insight</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Taxes</label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                High
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'high'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Medium
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'medium'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Low
                                <RadioInputField
                                  type="checkbox"
                                  name="taxes"
                                  valueon={'low'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Popular Occupations
                          </label>
                          <ul className="checkbox__list checkbox-list-grid-4">
                            {occupationList.map((o, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {o.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={o.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Education Insight</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table custom__table__simple">
                          <thead>
                            <tr>
                              <th></th>
                              <th>
                                <span className="table-column-title">
                                  Schools
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  Grades{' '}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {publicData && !isEmpty(publicData) ? (
                              publicData.map((school, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <label className="single__radio">
                                        <input
                                          type="radio"
                                          className="single__radio__input"
                                          // checked="checked"
                                          name="radio"
                                        />
                                        <span className="single__checkmark"></span>
                                      </label>
                                    </td>
                                    <td>{school.InstitutionName}</td>
                                    <td>{school.studentsgrade}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {' '}
                                <td colSpan="6" className="blank__table__state">
                                  No data found.
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                          <td>
                            <label className="single__radio">
                              <input
                                type="radio"
                                className="single__radio__input"
                                checked="checked"
                                name="radio"
                              />
                              <span className="single__checkmark"></span>
                            </label>
                          </td>
                          <td>THE LYNCH CENTER</td>
                          <td>K-8</td>
                        </tr> */}
                            {/* <tr>
                          <td>MICHAEL DRISCOLL</td>
                          <td>K-8</td>
                        </tr>
                        <tr>
                          <td>WILLIAM H LINCOLN</td>
                          <td>K-8</td>
                        </tr> 
                          </tbody>
                        </table>
                      </div>
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Language Immersion
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="language_immersion"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="language_immersion"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Full or half day kindergarten{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Full
                                <RadioInputField
                                  type="checkbox"
                                  name="kindergarten"
                                  valueon={'full'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Half
                                <RadioInputField
                                  type="checkbox"
                                  name="kindergarten"
                                  valueon={'half'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Busing</label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="busing"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="busing"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Strong special needs program
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="strong_special_needs_program"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="strong_special_needs_program"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            School system type
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Top
                                <RadioInputField
                                  type="checkbox"
                                  name="schools"
                                  valueon={'top'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Middle
                                <RadioInputField
                                  type="checkbox"
                                  name="schools"
                                  valueon={'middle'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Low
                                <RadioInputField
                                  type="checkbox"
                                  name="schools"
                                  valueon={'low'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Universal Pre-K{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="universal_pre_k"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="universal_pre_k"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Home Insight</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Land Size(s){' '}
                          </label>
                          <ul className="checkbox__list vertical checkbox-list-grid-2">
                            {landSizes.map((l, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {l.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={l.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                      //required
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Home Type</label>
                          <ul className="checkbox__list vertical">
                            {homeType.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Budget</label>
                          <ul className="checkbox__list vertical checkbox-list-grid-2">
                            {budget.map((b, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {b.label}
                                    <CheckboxInputField
                                      name={b.value}
                                      type="checkbox"
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">General Insight</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Feel of the town{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Suburban
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'suburban'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Urban
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'urban'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Country
                                <RadioInputField
                                  type="checkbox"
                                  name="urban_suburban"
                                  valueon={'country'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Fancy or laid back?{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {fancyLaidBack.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Type A folks?{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="type_a"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="type_a"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>

                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Walkable Neighborhood?{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="walkable"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="walkable"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Mom Preference{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                SAHM
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'sahm'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                WOHM
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'wohm'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                Mix
                                <RadioInputField
                                  type="checkbox"
                                  name="sahm_wohm_mix"
                                  valueon={'mix'}
                                  noval={''}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Progressive v conservative{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {progressiveConservative.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Sporty/Artsy{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            {sportyArtsy.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Good for finding
                          </label>
                          <ul className="checkbox__list checkbox-list-grid-3">
                            {goodForFinding.map((h, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {h.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={h.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Around Town </h1>
                  </div>
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Great restaurants nearby{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="great_restaurants"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="great_restaurants"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Type of Stores{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            <li>
                              <label className="custom__checkbox">
                                Name brand
                                <CheckboxInputField
                                  name="brand_name_shops"
                                  type="checkbox"
                                  values={values}
                                  setfieldvalue={setFieldValue}
                                  className="custom__checkbox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__checkbox">
                                Mom and pop
                                <CheckboxInputField
                                  name="mom_and_pop_shops"
                                  type="checkbox"
                                  values={values}
                                  setfieldvalue={setFieldValue}
                                  className="custom__checkbox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Beach nearby?
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="beach"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="beach"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Weekend Activities{' '}
                          </label>
                          <ul className="checkbox__list vertical">
                            <li>
                              <label className="custom__checkbox">
                                Church
                                <CheckboxInputField
                                  name="church_activities"
                                  type="checkbox"
                                  values={values}
                                  setfieldvalue={setFieldValue}
                                  className="custom__checkbox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__checkbox">
                                Temple
                                <CheckboxInputField
                                  name="temple_activities"
                                  type="checkbox"
                                  values={values}
                                  setfieldvalue={setFieldValue}
                                  className="custom__checkbox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__checkbox">
                                Other religious institution or activity
                                <CheckboxInputField
                                  name="bar_bat_mitzvahs"
                                  type="checkbox"
                                  values={values}
                                  setfieldvalue={setFieldValue}
                                  className="custom__checkbox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Pools? </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="backyard_pool"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="backyard_pool"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Organic food options{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="organic_food_options"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="organic_food_options"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Hiking trails nearby?{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            <li>
                              <label className="custom__radiobox">
                                Yes
                                <RadioInputField
                                  type="checkbox"
                                  name="hiking_trails"
                                  valueon={1}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom__radiobox">
                                No
                                <RadioInputField
                                  type="checkbox"
                                  name="hiking_trails"
                                  valueon={0}
                                  noval={-1}
                                  setfieldvalue={setFieldValue}
                                  toggleradiofields={this.toggleRadioFields}
                                  values={values}
                                  className="custom__radiobox__input"
                                />
                                <span className="custom__checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">Activities </label>
                          <ul className="checkbox__list checkbox-list-grid-3">
                            {activities.map((activity, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {activity.label}
                                    <CheckboxInputField
                                      name={activity.value}
                                      type="checkbox"
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}

                <div className="row">

                  <div className="col-md-2">
                    
                    {/*
                    <h1 className="title__section">Commute Info</h1>
                    */}
                    

                  </div>
                  <div className="col-md-10">

                    {/*
                    <div className="card">
                      <div className="card-body">
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Willing to commute to work{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            {willingToCommute.map((c, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {c.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={c.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <hr className="small__hr" />
                        <div className="info__block__wrap">
                          <label className="label__text__sm">
                            Transportation Types{' '}
                          </label>
                          <ul className="radiobox__list vertical">
                            {transportation.map((c, i) => {
                              return (
                                <li key={i}>
                                  <label className="custom__checkbox">
                                    {c.label}
                                    <CheckboxInputField
                                      type="checkbox"
                                      name={c.value}
                                      values={values}
                                      setfieldvalue={setFieldValue}
                                      className="custom__checkbox__input"
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    */}
                    
                    <button
                      type="button"
                      onClick={() => this.submitBtnRef.current.click()}
                      className="btn btn__btn btn-dark btn__lg w__100"
                    >
                      Save
                    </button>

                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </>
      </div>
    );
  }
}
