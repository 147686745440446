import React, { useState } from 'react'

export default function SiteMaintenancePage(props) {

  const containerStyle = {
    backgroundColor: '#fff',
    color: '#333'
  }

  const iconStyle = {
    padding: '24px 15px',
    textAlign: 'center'
  }

  return <div className={'container'} style={containerStyle}>
    <div className="container text-center px-5">
      <div>
        <div style={iconStyle}>
          <img src="../img/logo.svg" alt=""/>
        </div>
      </div>
      <h4>Sorry, we’re down for maintenance. We’ll be back shortly, try again soon!
      </h4>
    </div>
  </div>
}
