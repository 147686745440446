export const FETCH_JUNGLER_USERS_LIST = 'suburban/strategistV2/junlerUser/FETCH_JUNGLER_USERS_LIST'
export const FETCH_JUNGLER_USERS_LIST_SUCCESS =
  'suburban/strategistV2/junlerUser/FETCH_JUNGLER_USERS_LIST_SUCCESS'
export const FETCH_JUNGLER_USERS_LIST_ERROR =
  'suburban/strategistV2/junlerUser/FETCH_JUNGLER_USERS_LIST_ERROR'

export const CREATE_NEW_JUNGLER_USER = 'suburban/strategistV2/junlerUser/CREATE_NEW_JUNGLER_USER'
export const CREATE_NEW_JUNGLER_USER_SUCCESS =
  'suburban/strategistV2/junlerUser/CREATE_NEW_JUNGLER_USER_SUCCESS'
export const CREATE_NEW_JUNGLER_USER_ERROR =
  'suburban/strategistV2/junlerUser/CREATE_NEW_Jungler_USER_ERROR'

export const CLEAR_JUNGLER_USERS_LIST_PHASE =
  'suburban/strategistV2/junlerUser/CLEAR_JUNGLER_USERS_LIST_PHASE'
