import { connect } from 'react-redux'
import {homeFilterClients, getFilterParams, addUpdateClient, clearPhase} from '../../store/dashboard/duck'
import {handleSignOut, fetchUser, getNotifications, clearNotification} from '../../store/user/duck'
import Dashboard from './component'
const DashboardContainer = connect(
  // Map state to props
  (state) => ({
  	user: state.user.users,
    profileError: state.user.profileError,
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    profilePhase: state.user.profilePhase,
    clientData: state.dashboard.clientData,
    isSubmitting: state.user.isSubmitting,
    error: state.dashboard.error,
    addUpdateClientPhase: state.dashboard.addUpdateClientPhase,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase
  }),
  // Map actions to props
  {
   handleSignOut,
   fetchUser,
   addUpdateClient,
   homeFilterClients,
   clearPhase,
   getFilterParams,
   getNotifications,
   clearNotification
  }
)(Dashboard)
export default DashboardContainer
