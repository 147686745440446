import * as types from './action-types'
/*
    ////////////////////////////////////////
  /// leave comment action ///
///////////////////////////////////////
*/
// get leave comment with town and client id
export const getLeaveComments = (payload) => ({
  type: types.GET_LEAVE_COMMENTS,
  payload,
})

// get user by id data
export const addLeaveComments = (payload) => ({
  type: types.ADD_LEAVE_COMMENTS,
  payload,
})
// delete comment by comment id
export const deleteComment = (payload) => ({
  type: types.REMOVE_LEAVE_COMMENT,
  payload,
})

export const clearCommentPhase = () => ({
  type: types.CLEAR_COMMENT_PHASE,
})
