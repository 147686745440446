/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment, useState } from 'react'
import { map, get, find, size } from 'lodash'
import { Spin } from 'antd'
import $ from 'jquery'
import Cookies from 'universal-cookie'

import { CloseIcon } from '../../../../../components/icons'
import { SUCCESS } from '../../../../../constants/phase'
import ChatHistory from './partials/ChatHistory'

const cookies = new Cookies()

export default function MessageAgentComponent(props) {
  const [isLoading, setLoading] = useState(false)
  const [selectedAgent, setAgentId] = useState('')
  const [chatMessage, setChatMessage] = useState('')

  useEffect(() => {
    setLoading(true)
    setAgentId(props.agentId)
    if (props.clientId) {
      props.fetchClientAgentChat({ clientId: props.clientId })
    }
  }, [])

  const onClose = () => props.closeDrawer()

  // cwrp on success of fetching client agent listing and client agent chat
  useEffect(() => {
    if (props.sendAgentMessagePhase === SUCCESS) {
      setLoading(true)
      if (props.clientId) {
        props.fetchClientAgentChat({ clientId: props.clientId })
      }
      props.clearChatPhase()
    }
    if (props.fetchClientAgentChatPhase === SUCCESS) {
      $('.chats__container').animate(
        { scrollTop: $('.chats__container').prop('scrollHeight') },
        1000
      )
      setLoading(false)
      props.clearChatPhase()
    }
  }, [
    props.fetchClientAgentListPhase,
    props.sendAgentMessagePhase,
    props.fetchClientAgentChatPhase,
  ])

  const userId = props.user && props.user.id

  const handleInputChange = ({ target }) => setChatMessage(target.value)

  const sendAgentMessage = () => {
    props.sendAgentMessage({
      client_id: props.clientId,
      sender_id: userId,
      type: 'text',
      receiverIds: [String(props.agentId)],
      sender_type: 'strategist',
      receiver_type: 'agent',
      message: chatMessage,
    })
    setChatMessage('')
  }

  return (
    <Fragment>
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Message / Notes For Agent</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="chats__start__bottom">
        {/* <Spin size="large" spinning={isLoading} indicator={<Loader />}> */}
        <div className="chats__container">
          <div className="chats__wrapper">
            <ChatHistory agentId={selectedAgent} {...props} />
          </div>
        </div>
        {/* </Spin> */}
        <div className="chats__container__teatarea">
          <textarea
            name="chat"
            className="chats__sectiion__input"
            value={chatMessage}
            onChange={handleInputChange}
            placeholder={`Send a message/note to ${props.agentName}...`}
          ></textarea>
          <div className="chats__section__cta">
            <button
              type="button"
              className="btn btn__btn btn-dark btn__send"
              disabled={chatMessage !== '' && chatMessage.trim() !== '' ? false : true}
              onClick={sendAgentMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
