import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Modal from 'react-modal'
import Header from '../Header'
import PrivateSchool from '../PrivateSchool/component'
import Education from '../Education/component'
import CommunityAndRecreation from '../CommunityAndRecreation/component'
import ResearchCommute from '../ResearchCommute/component'
import Taxes from '../Taxes/component'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import $ from 'jquery'
import { parse } from 'querystring';
import GoogleDrivePDF from './../googleDrivePDF'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()

let _user = ''

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    padding: '0 0',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class Research extends Component {
  constructor(props) {
    super(props)
    this.state = {
        isDateLoading: false,
        modalIsOpen: false,
        modelType: '',
        selectType: '',
        url: '',
        note: '',
        fileName: '',
        file: '',
        type: 'education',
        err: {},
        fileType: '',
        current_user_id: 0,
        id: 0,
        drive: false,
        pdfUrl: '',
        token: '',
        dropDownBool: false,
        dropDownVal: "Education"
    }
    this.sendData = this.sendData.bind(this)
  }

  componentWillMount(){
    const {match: { params: { townId }}, getResearch } = this.props
    _user = cookies.get('user')
    if(_user) {
      this.setState({current_user_id: _user.id})
    }
    this.getData()
  }

  componentWillReceiveProps(np){
    if(np.addResearchPhase !== this.props.addResearchPhase){
      this.getData()
    }
    if(np.researchFilePhase !== this.props.researchFilePhase){
      this.getData()
    }
    if(np.addResearchPhase === "error"){
      this.setState({ modalIsOpen: false, isDateLoading: false })
    }
    if(np.deleteResearchPhase !== this.props.deleteResearchPhase){
      this.getData()
    }
    if(np.getResearchPhase !== this.props.getResearchPhase){
      this.setState({ modalIsOpen: false, isDateLoading: false, selectType: '', note: '', file: '', fileName: '', drive: false, url: '' })
    }
  }

  getData(){
    const {match: { params: { townId }}, getResearch } = this.props
    let data = {
      town_id: parseInt(townId),
      user_id: _user.id
    }
    getResearch(data)
  }

  onChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  selectInsightType(e){
    this.setState({ selectType: e.target.value, err: {} })
  }

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL
    let files = $("#profileImage")[0].files[0]
    let fileName = files.name
    let fileType = files.type
    var img = new Image()
    let self=this
    let reader = new FileReader()
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files)
      img.onload = function() {
          self.setState({file: files})
          self.setState({avatar: reader.result})
      }
    }
    this.setState({ fileName: fileName, file: files, fileType: fileType})
    var read = reader.readAsDataURL(files) 
  }

  tabClick(val, e){
    this.setState({ type: val, dropDownBool: !this.state.dropDownBool })
    if(val === 'education'){
      this.setState({ dropDownVal: "Education" })
    }else if(val === 'community'){
      this.setState({ dropDownVal: "Community & Recreation" })
    }else if(val === 'commute'){
      this.setState({ dropDownVal: "Commute" })
    }else if(val === 'taxes'){
      this.setState({ dropDownVal: "Taxes" })
    }
  }

  addInsightButton(e){
    this.setState({ 
      modelType: "add", 
      modalIsOpen: true,
      selectType: '',
      id: 0
    })
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str)
  }

  afterOpenModal(){ }

  closeModal=()=>{ 
    // this.setState({ modalIsOpen: false, modelType: '', selectType: '', file: {}, url: '', note: '' })
  }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false, modelType: '', selectType: '', file: {}, url: '', note: '' })
  }

  saveButton(e){
    const err = {}
    const {match: { params: { townId }}, addResearch, addResearchFile } = this.props
    const { file, url, fileType, note, selectType, type, current_user_id, id, fileName, drive, pdfUrl, token } = this.state 
    if(note === '' || note.trim() === ''){
      err.note = "Please enter note"
    }
    if(selectType === ''){
      err.type = "Select type"
    }
    if(selectType === "link"){
      if(!this.validURL(url)){
        err.url = "Enter valid url"
      }
    }else if(selectType === "file"){
      if(file == '' || file.trim === ''){
        err.file = "Please select file"
      }else if(fileType !== 'application/pdf'){
        err.file = "Select correct file"
      }
    }

    this.setState({ err })
    if(!Object.keys(err).length) {
      let data = {
       id: id,
       town_id: parseInt(townId),
       note: note,
       type: type,
       file: file,
       insight_type: selectType,
       created_by: current_user_id,
       fileName: ''
      }
       if(selectType === "link"){
         data.link = url
         data.file = ''
         addResearch(data)
       }else if(selectType === "file"){
       const formdata = new FormData()
         formdata.append("id", id)
         formdata.append("town_id", parseInt(townId))
         formdata.append("note", note)
         formdata.append("type", type)
         formdata.append("insight_type", selectType)
         formdata.append("created_by", current_user_id)
         formdata.append('file',file)
         formdata.append('link', '')
         formdata.append('drive', drive)
         formdata.append('fileName', fileName)
         formdata.append('pdfUrl', pdfUrl)
         formdata.append('authToken', token)
         addResearchFile(formdata)
         this.setState({ modalIsOpen: false, isDateLoading: true })
       }else if( selectType === "note"){
         data.link = ''
         data.file = ''
         data.fileName = ''
         addResearch(data)
       }
       this.setState({ isDateLoading: true })
    }
  }

  async sendData(data, token) {
    this.setState({ 
      fileName: data[0].name, 
      file: data[0], 
      fileType: data[0].mimeType, 
      drive: true, 
      pdfUrl: data[0].url,
      token: token
    })
  }

  onSelectClick(e){
    this.setState({ dropDownBool: !this.state.dropDownBool })
  }

  render() {
    const { modelType, selectType } = this.state
    return (
      <div className="town-report posi__rel">
      {
        this.state.isDateLoading === true ?
        <div className="loader__agent" >
         <img src="../../img/loader2.svg" />
        </div>
        : ''
       }
      <Header title={`Edit ${this.state.town_name} Public Schools`}/>
      <div className="tabs__town--reports">
        <div className="row">
        <div className="col-md-12 padd__left--zero padd__top--add">

          <ul className="nav nav-pills desktop__research--tabs" role="tablist">
            <li className="nav-item active">
              <a className="" data-toggle="pill" onClick={this.tabClick.bind(this, 'education')} href="#home">Education</a>
            </li>
            <li className="nav-item">
              <a data-toggle="pill" onClick={this.tabClick.bind(this, 'community')} href="#menu1">Community & Recreation</a>
            </li>
            <li className="nav-item">
              <a data-toggle="pill" onClick={this.tabClick.bind(this, 'commute')} href="#menu2">Commute</a>
            </li>
            <li className="nav-item">
              <a data-toggle="pill" onClick={this.tabClick.bind(this, 'taxes')} href="#menu3">Taxes</a>
            </li>
          </ul>

           {/* mobile dashboard dropdown open */}
            <div className="mobview__dropdown--select selectmobresearch" style={{ position: 'relative' }}>
              <button className={this.state.dropDown ? "agentclient__btn--drop agentclient__btn--dropfocus" :"agentclient__btn--drop"} onClick={this.onSelectClick.bind(this)}>{this.state.dropDownVal}</button>
                <div className={this.state.dropDownBool ? "agentclient__dropdownsshow agentclient__dropdowns forboxshadowdrop" : "agentclient__dropdowns"}>
                  {
                    (this.state.dropDownBool) && 
                    <ul className="nav nav-pills" role="tablist">
                      <li>
                        <a className="" data-toggle="pill" onClick={this.tabClick.bind(this, 'education')} href="#home">Education</a>
                      </li>
                      <li>
                        <a data-toggle="pill" onClick={this.tabClick.bind(this, 'community')} href="#menu1">Community & Recreation</a>
                      </li>
                      <li>
                        <a data-toggle="pill" onClick={this.tabClick.bind(this, 'commute')} href="#menu2">Commute</a>
                      </li>
                      <li>
                        <a data-toggle="pill" onClick={this.tabClick.bind(this, 'taxes')} href="#menu3">Taxes</a>
                      </li>
                    </ul>
                  }
              </div>
          </div>
          {/* mobile dashboard dropdown close */}

          <div className="research__add--btn">
            <button className="add__button__fixed_new extra__space" onClick={this.addInsightButton.bind(this)} type="submit">Add Insight</button>
          </div>

          <div className="tab-content">
            <div id="home" className="tab-pane active">
              <div className="row">
                <div className="col-md-12">
                  <Education {...this.props}/>
                </div>
              </div>
            </div>
            <div id="menu1" className="tab-pane">
              <div className="row">
                <div className="col-md-12">
                  <CommunityAndRecreation {...this.props}/>
                </div>
              </div>
            </div>
            <div id="menu2" className="tab-pane">
              <div className="row">
                <div className="col-md-12">
                  <ResearchCommute {...this.props}/>
                </div>
              </div>
            </div>
            <div id="menu3" className="tab-pane">
              <div className="row">
                <div className="col-md-12">
                  <Taxes {...this.props}/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>

      <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
          <div className="flexdiv">
            <h4 className="popupheader__heading">{ (modelType === "add") ? "Add Insight" : "Edit Insight" }</h4>
            <button
              onClick={this.closeCrossBtn.bind(this)}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>

          {/* <div className="form-group">
            <h1 className="headModal">{ (modelType === "add") ? "Add Insight" : "Edit Insight" }</h1>
            <button onClick={this.closeCrossBtn.bind(this)} type="button" className="btn close-btn closeModal" data-dismiss="modal">
            <img data-dismiss="modal" src="./img/login_close.svg"/></button>
          </div> */}

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">TYPE</label>
            <select className="selectinputs__global" value={this.state.selectType} onChange={this.selectInsightType.bind(this)}>
              <option value=''>Select Type</option>
              <option value="link">Add Link</option>
              <option value="file">Upload File</option>
              <option value="note">Add Note</option>
            </select>
          </div>
          { this.state.err.type ?
            <span className="error_msg town__err">
            {this.state.err.type }
            </span> : '' }

          {
            (selectType === "link") &&
            <div>
              <div className="form-group">
              <label className="labelinputs__global labelcapital__global">ENTER URL</label>
                <input 
                  type="url"
                  name="url"
                  className="textinputs__global" 
                  rows="2" 
                  value={this.state.url}
                  onChange={this.onChange.bind(this)}/>
              </div>
              { this.state.err.url ?
              <span className="error_msg town__err">
              {this.state.err.url }
              </span> : '' }
            </div>
          }

          {
            (selectType === "file") && 
            <div className="insight__upload--wrapper">
              <span className="form-group">
                <label className="upload__cursor">Upload
                  <input type="file" style={{ display: "none" }}  accept="application/pdf" onChange={this.handleFileUpload.bind(this)} id="profileImage" name="profileImage"/>
                </label>
              </span>
              <span className="or">Or</span>
              <span>
                <GoogleDrivePDF key="googleDrivePDF" sendData={this.sendData} />
              </span>
              <div>{this.state.fileName}</div>

              { this.state.err.file ?
              <div className="error_msg town__err">
              {this.state.err.file }
              </div> : '' }
            </div>
          }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">NOTE</label>
            <textarea 
              name="note"
              className="textareainputs__global" 
              rows="2" 
              value={this.state.note}
              onChange={this.onChange.bind(this)}/>
          </div>
          { this.state.err.note ?
            <span className="error_msg town__err">
            {this.state.err.note }
            </span> : '' }

          <div className="modalbtns modalbtns__new">
            {
              (modelType === "add")?
              <button
                className="btns__fullwidth poppinsfontsedit"
                onClick={this.saveButton.bind(this)}
                type="button">
                Create 
              </button>
              :
              <button
                className="btns__fullwidth poppinsfontsedit"
                type="button">
                Update 
              </button>
            }
            
          </div>
          </div>
        </Modal>

    </div>
    )
  }
}

export default reduxForm({
  form: 'Research', // a unique identifier for this form
  destroyOnUnmount: true,
  asyncBlurFields: [],
})(Research)