import React from "react";
import Loader from '../../../../../components/Loader'
import AppointmentTableHeader from "./AppointmentTableHeader";
import AppointmentList from "./AppointmentList";
import { Spin } from "antd";

const ProspectAgents = ({prospectApplicants,pageLimit,isLoading,sortByKey,sortByType,sortData,activePage,handleApprove,handleReject}) => {

  return (
    <div>
      <Spin size="large" spinning={isLoading} indicator={<Loader />}>
        {prospectApplicants && prospectApplicants.length > 0 ? (
          <div className="table-responsive">
            <table className="table custom-table">
              <AppointmentTableHeader
                appointments={prospectApplicants}
                sortData={sortData}
                sortByType={sortByType}
                sortByKey={sortByKey}
                tab={1}
              />
              <AppointmentList
                applicants={prospectApplicants}
                approveApplicant={handleApprove}
                rejectApplicant={handleReject}
                pageLimit={pageLimit}
                tab={1}
              />
            </table>
          </div>
        ) : (
          <div className="blank__table__state">No agents found.</div>
        )}
      </Spin>
    </div>
  );
};

export default ProspectAgents;
