import React, { Fragment, useState, useEffect } from 'react'
import { Drawer } from 'antd'
import { get, map } from 'lodash'
import moment from 'moment'
import $ from 'jquery'

import { CloseIcon } from '../../../../../../components/icons'
import { createStringAvatar } from '../../../../../../utils/helper'
import RemoveComment from './RemoveComment'
import { SUCCESS } from '../../../../../../constants/phase'

export default function LeaveComment({ visible, onClose, townId, user, ...props }) {
  // handle comment state for write comment
  const [comment, setComment] = useState("")
  const [commentId, setCommentId] = useState("")
  const [editComment, setEditComment] = useState("")
  const [deleteConfirmationModal, toggleConfirmationModal] = useState({
    open: false,
    commentId: ''
  })

  useEffect(() => {
    const clientId = get(props, "match.params.id")
    props.getLeaveComments({ townId, clientId })
  }, [])

  useEffect(() => {
    if (props.removeLeaveCommentPhase === SUCCESS) {
      const clientId = get(props, 'match.params.id')
      props.getLeaveComments({ townId, clientId })
      props.clearCommentPhase()
    }
    if (props.addLeaveCommentPhase === SUCCESS) {
      const clientId = get(props, 'match.params.id')
      setComment('')
      setEditComment('')
      setCommentId('')
      props.getLeaveComments({ townId, clientId })
      props.clearCommentPhase()
    }
    if (props.getLeaveCommentPhase === SUCCESS) {
      $('.chats__container').animate({ scrollTop: $('.chats__container').prop("scrollHeight") }, 1000)
      props.clearCommentPhase()
    }
  }, [
    props.addLeaveCommentPhase,
    props.removeLeaveCommentPhase,
    props.getLeaveCommentPhase
  ])


  // dynamic textarea for incress and decress box size
  const handleKeyDown = (event) => {
    event.persist()
    event.target.style.height = 'inherit'
    event.target.style.height = `${event.target.scrollHeight}px`
  }
  // handle change event
  const handleChange = (event) => {
    const { value } = event.target
    setComment(value)
  }
  // on submit of comment write comment
  const handleSubmit = () => {
    const clientId = get(props, "match.params.id")
    const commentObj = {
      clientId,
      townId,
      comment,
      senderId: parseInt(user.id),
      type: 'text'
    }
    if (commentId) {
      commentObj.commentId = commentId
      commentObj.comment = editComment
    }
    props.addLeaveComments(commentObj)
  }
  // edit comment
  const handleEditComment = (commentId, comment) => {
    setEditComment(comment)
    setCommentId(commentId)
  }
  // edit comment
  const handleCancelEditComment = () => {
    setEditComment("")
    setCommentId("")
  }

  const deleteTextConfirmation = (commentId) => toggleConfirmationModal((prevState) => ({ ...prevState, open: true, commentId }))

  const closeDeleteCommentModal = () => toggleConfirmationModal((prevState) => ({ ...prevState, open: false, commentId: '' }))
  return (
    <Drawer
      placement="right"
      closable={false}
      width="100%"
      onClose={onClose}
      visible={visible}
      className="drawer__main"
    >
      <div className="drawer__header d__flex align__items__center">
        <h4 className="drawer__header__title">Leave a comment</h4>
        <div className="drawer__header__close" onClick={onClose}>
          <button className="btn btn__link">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="chats__start__bottom">
        {props.leaveComments && props.leaveComments.length > 0 ? (
            <div className="chats__container">
              <div className="chats__wrapper">
                  <ul className="chats__wrapper__chats__list">
                    {map(props.leaveComments, (comment, index) => (
                      <li className={`has__avatar ${comment.sender_id === user.id ? 'active' : ''}`} key={index}>
                        <div className="chats__card__header">
                          <div className="chats__card__avatar">
                            {comment.sender_image ? (
                              <img src={comment.sender_image} alt="" />
                            ) : (
                              <span className="string__avatar">{createStringAvatar(comment.sender_full_name)}</span>
                            )}
                          </div>
                          <span className="chats__card__name">
                            {comment.sender_full_name}
                          </span>
                          <span className="chats__card__time">
                            {moment(comment.created).tz(moment.tz.guess()).format('MM/DD/YY [at] hh:mm A')}
                          </span>
                        </div>
                        {commentId && commentId === comment.id ? (
                            <div className="chats__card__edit">
                              <textarea
                                className="chats__card__textarea__edit"
                                type="text"
                                name="editComment"
                                value={editComment}
                                onChange={({ target: { value }}) => setEditComment(value)}
                                ref={(editRef) => commentId && editRef && editRef.focus()}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyDown}
                                style={{ resize: 'none', overflowY: 'hidden' }}
                                onFocus={handleKeyDown}
                              >
                              </textarea>
                              <button
                                onClick={handleSubmit}
                                disabled={editComment !== '' && editComment.trim() !== '' ? false : true}
                                className="btn btn__btn btn__xs btn-dark mr__15"
                              >
                                Save
                              </button>
                              <span onClick={handleCancelEditComment}><CloseIcon className="chats__card__edit__close" /></span>
                            </div>
                        ) : (
                            <div className="chats__content">
                              <div
                                dangerouslySetInnerHTML={{ __html: comment.comment }}
                              ></div>
                            </div>
                          )}
                        {comment.sender_id === user.id && !commentId && (
                          <div className="chats__card__cta">
                            <button className="btn btn__edit_chat" onClick={() => handleEditComment(comment.id, comment.comment)}>Edit</button>
                            <button
                              className="btn btn__delete_chat"
                              onClick={() => deleteTextConfirmation(comment.id)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
              </div>
            </div>
            ) : (
          <div className="drawer__body__inner height__remove">
                <div className="blank__state__text">No Comment Found.</div>
            </div>
         )}
        <div className="chats__container__teatarea">
          <textarea
            className="chats__sectiion__input"
            placeholder={`Write a comment or note....`}
            ref={(current) => !commentId && current && current.focus()}
            onKeyDown={handleKeyDown}
            value={comment}
            onKeyUp={handleKeyDown}
            style={{ resize: 'none', overflowY: 'hidden' }}
            onFocus={handleKeyDown}
            onChange={handleChange}
          ></textarea>
          <div className="chats__section__cta">
            <button
              type="button"
              disabled={comment !== '' && comment.trim() !== '' ? false : true}
              className="btn btn__btn btn-dark btn__send"
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
        </div>
        <RemoveComment
          openModel={deleteConfirmationModal.open}
          commentId={deleteConfirmationModal.commentId}
          closeDeleteCommentModal={closeDeleteCommentModal}
          deleteComment={props.deleteComment}
          {...props}
        />
      </div>
    </Drawer>
  )
}
