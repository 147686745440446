import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import _ from 'lodash'
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'
import { Container, Row, Col, } from 'react-grid-system'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export class MobileFirstHeader extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      menuIconDrawer: false
    }
     this.userSignout = this.userSignout.bind(this)
  }

  userSignout(logout) {
      localStorage.clear();
      document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "client_id =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
      
      // document.cookies.remove('Authorization')
      // document.cookies.remove('user')
      this.props.handleSignOut();
      this.props.history.push('/login')
    
  }

  myProfileCondition(role){
    const { user } = this.props;
    switch(role) {
      case 'agent':
        return(
        <li><Link to={`/agent-edit-profile`} className="text-orange">
        My Profile</Link></li>
      )
      case 'client':
        return(
        <li><Link to={{ pathname: '/client-my-profile', state: user && user }} className="text-orange">
        My Profile</Link></li>
      )
      default:
        return null;
    }
  }

  OpenScheduleBar(event) {
    this.props.openScheduleBar()
  }

  menuIconbutton(e){
    this.setState({ menuIconDrawer: true })
  }

  closeMenuButton(e){
    this.setState({ menuIconDrawer: false })
  }

  render(){
    const { user } = this.props
    return (
      <div>
          <div className="container-fluid mobileView">
            <nav className="navbar navbar-default navbar-fixed-top navbar-edit">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>                        
                  </button>
                  {
                    _.get(user, 'role') === "client" ?
                        <a className="navbar-brand navbar-brand-edit" href="/client">
                          <img src="img/logo.svg" className="logoImg" /> 
                          <span className="logo__title">Suburban Jungle client portal</span>
                        </a>
                     : 

                        <a className="navbar-brand navbar-brand-edit" href="/agent">
                          <img src="img/logo.svg" className="logoImg" /> 
                          <span className="logo__title">Suburban Jungle client portal</span>
                        </a>
                  }
                  
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                  <ul className="nav navbar-nav navbar-right navbar-right-edit">
                    {
                      (_.get(user, 'role') === "client") ?
                         <div>
                            <li><Link to={`/chat-center`} className="btn__all btn__grey"> Message</Link></li>
                            <li onClick={this.OpenScheduleBar.bind(this)}> <a href="javascript:void(0)" className="navigation__links">
                    Schedule</a></li>
                         </div>
                      :

                        <div>
                            <li><Link to={`/agentcalendar`} className="btn__all btn__green"> Calender</Link></li>
                            <li><Link to={`/chat-center`} className="btn__all btn__grey"> Message</Link></li>
                        </div>
                    }
                    
                    <li className="dropdown profile-name">
                      <a className="dropdown-toggle" data-toggle="dropdown">
                      {user && user.first_name && user.last_name ? user.first_name + " "+user.last_name : ''}
                      <img className="avart__arrow--icon" src="img/profile-arrow.svg"/>
                      </a>
                      
                      <ul className="dropdown-menu">
                        { 
                            this.myProfileCondition(_.get(user, 'role'))
                        }
                        <li><a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-mobile-h1 text-orange">
                          Logout</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>

           </nav>
          </div>
      </div>
    )
 } 
}

function mapStateToProps(state, props) {
    return {
        initialValues : props.location.state ? props.location.state : {}
     }
}

export default connect( mapStateToProps )(
  (MobileFirstHeader)
)