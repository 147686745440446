import { connect } from 'react-redux'

import { deleteClient, clearPhase } from '../../../../../store/dashboard/duck'
import {
  fetchClientById,
  editClientInformation,
  clearClientPhase,
} from '../../../../../store/strategistV2/clients/action'
import EditClientInformationComponent from './component'

const EditClientInformationContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    deleteClientdata: state.dashboard.deleteClientdata,
    editClientInformationPhase: state.strategistV2.client.editClientInformationPhase,
  }),
  // Map actions to dispatch and props
  {
    editClientInformation,
    clearClientPhase,
    deleteClient,
    clearPhase,
    fetchClientById,
  }
)(EditClientInformationComponent)

export default EditClientInformationContainer
