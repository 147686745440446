import Rx from 'rxjs/Rx'
import { Record, List } from 'immutable'
import { combineEpics } from 'redux-observable'
import HttpStatus from 'http-status-codes'
import { assign } from 'lodash'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import * as api from './api'

/**
 * Public: Action Types
 */

export const GET_USERS = 'suburban/agent/GET_USERS'
export const GET_USERS_SUCCESS = 'suburban/agent/GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'suburban/agent/GET_USERS_ERROR'

export const GET_ACTIVE_USERS = 'suburban/agent/GET_ACTIVE_USERS'
export const GET_ACTIVE_USERS_SUCCESS = 'suburban/agent/GET_ACTIVE_USERS_SUCCESS'
export const GET_ACTIVE_USERS_ERROR = 'suburban/agent/GET_ACTIVE_USERS_ERROR'

export const GET_CLOSED_USERS = 'suburban/agent/GET_CLOSED_USERS'
export const GET_CLOSED_USERS_SUCCESS = 'suburban/agent/GET_CLOSED_USERS_SUCCESS'
export const GET_CLOSED_USERS_ERROR = 'suburban/agent/GET_CLOSED_USERS_ERROR'

export const GET_SEARCH_USERS = 'suburban/agent/GET_SEARCH_USERS'
export const GET_SEARCH_USERS_SUCCESS = 'suburban/agent/GET_SEARCH_USERS_SUCCESS'
export const GET_SEARCH_USERS_ERROR = 'suburban/agent/GET_SEARCH_USERS_ERROR'

export const GET_AGENT_TOWN = 'suburban/agent/GET_AGENT_TOWN'
export const GET_AGENT_TOWN_SUCCESS = 'suburban/agent/GET_AGENT_TOWN_SUCCESS'
export const GET_AGENT_TOWN_ERROR = 'suburban/agent/GET_AGENT_TOWN_ERROR'

export const GET_ALL_CLIENTS = 'suburban/agent/GET_ALL_CLIENTS'
export const GET_ALL_CLIENTS_SUCCESS = 'suburban/agent/GET_ALL_CLIENTS_SUCCESS'
export const GET_ALL_CLIENTS_ERROR = 'suburban/agent/GET_ALL_CLIENTS_ERROR'

export const GET_AGENTS_ALL_CLIENT = 'suburban/agent/GET_AGENTS_ALL_CLIENT'
export const GET_AGENTS_ALL_CLIENT_SUCCESS = 'suburban/agent/GET_AGENTS_ALL_CLIENT_SUCCESS'
export const GET_AGENTS_ALL_CLIENT_ERROR = 'suburban/agent/GET_AGENTS_ALL_CLIENT_ERROR'

export const GET_APPOINTMENT = 'suburban/agent/GET_APPOINTMENT'
export const GET_APPOINTMENT_SUCCESS = 'suburban/agent/GET_APPOINTMENT_SUCCESS'
export const GET_APPOINTMENT_ERROR = 'suburban/agent/GET_APPOINTMENT_ERROR'

export const GET_CLIENT_APPOINTMENT = 'suburban/agent/GET_CLIENT_APPOINTMENT'
export const GET_CLIENT_APPOINTMENT_SUCCESS = 'suburban/agent/GET_CLIENT_APPOINTMENT_SUCCESS'
export const GET_CLIENT_APPOINTMENT_ERROR = 'suburban/agent/GET_CLIENT_APPOINTMENT_ERROR'

export const GET_CLIENT = 'suburban/agent/GET_CLIENT'
export const GET_CLIENT_SUCCESS = 'suburban/agent/GET_CLIENT_SUCCESS'
export const GET_CLIENT_ERROR = 'suburban/agent/GET_CLIENT_ERROR'

export const GET_AGENT_DEAL = 'suburban/agent/GET_AGENT_DEAL'
export const GET_AGENT_DEAL_SUCCESS = 'suburban/agent/GET_AGENT_DEAL_SUCCESS'
export const GET_AGENT_DEAL_ERROR = 'suburban/agent/GET_AGENT_DEAL_ERROR'

export const GET_AGENT_AVAILABILITY = 'suburban/agent/GET_AGENT_AVAILABILITY'
export const GET_AGENT_AVAILABILITY_SUCCESS = 'suburban/agent/GET_AGENT_AVAILABILITY_SUCCESS'
export const GET_AGENT_AVAILABILITY_ERROR = 'suburban/agent/GET_AGENT_AVAILABILITY_ERROR'

export const GET_AVAILABILITY = 'suburban/agent/GET_AVAILABILITY'
export const GET_AVAILABILITY_SUCCESS = 'suburban/agent/GET_GET_AVAILABILITY_SUCCESS'
export const GET_AVAILABILITY_ERROR = 'suburban/agent/GET_AVAILABILITY_ERROR'

export const GET_ALL_CLIENT = 'suburban/agent/GET_ALL_CLIENT'
export const GET_ALL_CLIENT_SUCCESS = 'suburban/aGET_ALL_CLIENT_SUCCESS'
export const GET_ALL_CLIENT_ERROR = 'suburban/agent/GET_ALL_CLIENT_ERROR'

export const SEND_AGENT_NOTE = 'suburban/agent/SEND_AGENT_NOTE'
export const SEND_AGENT_NOTE_SUCCESS = 'suburban/agent/SEND_AGENT_NOTE_SUCCESS'
export const SEND_AGENT_NOTE_ERROR = 'suburban/agent/SEND_AGENT_NOTE_ERROR'

export const AGENT_CHAT_STRATEGIST = 'suburban/agent/AGENT_CHAT_STRATEGIST'
export const AGENT_CHAT_STRATEGIST_SUCCESS = 'suburban/agent/AGENT_CHAT_STRATEGIST_SUCCESS'
export const AGENT_CHAT_STRATEGIST_ERROR = 'suburban/agent/AGENT_CHAT_STRATEGIST_ERROR'

export const GET_AGENT_CHAT = 'suburban/agent/GET_AGENT_CHAT'
export const GET_AGENT_CHAT_SUCCESS = 'suburban/agent/GET_AGENT_CHAT_SUCCESS'
export const GET_AGENT_CHAT_ERROR = 'suburban/agent/GET_AGENT_CHAT_ERROR'

export const ADD_AGENT_DAY = 'suburban/agent/ADD_AGENT_DAY'
export const ADD_AGENT_DAY_SUCCESS = 'suburban/agent/ADD_AGENT_DAY_SUCCESS'
export const ADD_AGENT_DAY_ERROR = 'suburban/agent/ADD_AGENT_DAY_ERROR'

export const AGENT_SCHEDULE_ON_CLIENT = 'suburban/agent/AGENT_SCHEDULE_ON_CLIENT'
export const AGENT_SCHEDULE_ON_CLIENT_SUCCESS = 'suburban/agent/AGENT_SCHEDULE_ON_CLIENT_SUCCESS'
export const AGENT_SCHEDULE_ON_CLIENT_ERROR = 'suburban/agent/AGENT_SCHEDULE_ON_CLIENT_ERROR'

export const CREATE_AGENT_APPOINTMENT = 'suburban/agent/CREATE_AGENT_APPOINTMENT'
export const CREATE_AGENT_APPOINTMENT_SUCCESS = 'suburban/agent/CREATE_AGENT_APPOINTMENT_SUCCESS'
export const CREATE_AGENT_APPOINTMENT_ERROR = 'suburban/agent/CREATE_AGENT_APPOINTMENT_ERROR'

export const ADD_AGENT_APPOINTMENT = 'suburban/agent/ADD_AGENT_APPOINTMENT'
export const ADD_AGENT_APPOINTMENT_SUCCESS = 'suburban/agent/ADD_AGENT_APPOINTMENT_SUCCESS'
export const ADD_AGENT_APPOINTMENT_ERROR = 'suburban/agent/ADD_AGENT_APPOINTMENT_ERROR'

export const CREATE_CLIENT_DEAL = 'suburban/agent/CREATE_CLIENT_DEAL'
export const CREATE_CLIENT_DEAL_SUCCESS = 'suburban/agent/CREATE_CLIENT_DEAL_SUCCESS'
export const CREATE_CLIENT_DEAL_ERROR = 'suburban/agent/CREATE_CLIENT_DEAL_ERROR'

export const DELETE_APPOINTMENT = 'suburban/agent/DELETE_APPOINTMENT'
export const DELETE_APPOINTMENT_SUCCESS = 'suburban/agent/DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_ERROR = 'suburban/agent/DELETE_APPOINTMENT_ERROR'

export const UPDATE_CLIENT_USER = 'suburban/agent/UPDATE_CLIENT_USER'
export const UPDATE_CLIENT_USER_SUCCESS = 'suburban/agent/UPDATE_CLIENT_USER_SUCCESS'
export const UPDATE_CLIENT_USER_ERROR = 'suburban/agent/UPDATE_CLIENT_USER_ERROR'

export const GET_AGENT_CLIENT_NOTES = 'suburban/agent/GET_AGENT_CLIENT_NOTES'
export const GET_AGENT_CLIENT_NOTES_SUCCESS = 'suburban/agent/GET_AGENT_CLIENT_NOTES_SUCCESS'
export const GET_AGENT_CLIENT_NOTES_ERROR = 'suburban/agent/GET_AGENT_CLIENT_NOTES_ERROR'

export const CLIENT_NEVER_TOUCH_IN_4W = 'suburban/agent/CLIENT_NEVER_TOUCH_IN_4W'
export const CLIENT_NEVER_TOUCH_IN_4W_SUCCESS = 'suburban/agent/CLIENT_NEVER_TOUCH_IN_4W_SUCCESS'
export const CLIENT_NEVER_TOUCH_IN_4W_ERROR = 'suburban/agent/CLIENT_NEVER_TOUCH_IN_4W_ERROR'

export const CLIENT_NOT_TOUCH = 'suburban/agent/CLIENT_NOT_TOUCH'
export const CLIENT_NOT_TOUCH_SUCCESS = 'suburban/agent/CLIENT_NOT_TOUCH_SUCCESS'
export const CLIENT_NOT_TOUCH_ERROR = 'suburban/agent/CLIENT_NOT_TOUCH_ERROR'

export const CLIENT_FOLLOW_UP = 'suburban/agent/CLIENT_FOLLOW_UP'
export const CLIENT_FOLLOW_UP_SUCCESS = 'suburban/agent/CLIENT_FOLLOW_UP_SUCCESS'
export const CLIENT_FOLLOW_UP_ERROR = 'suburban/agent/CLIENT_FOLLOW_UP_ERROR'

export const DELETE_CLIENT_DEAL = 'suburban/agent/DELETE_CLIENT_DEAL'
export const DELETE_CLIENT_DEAL_SUCCESS = 'suburban/agent/DELETE_CLIENT_DEAL_SUCCESS'
export const DELETE_CLIENT_DEAL_ERROR = 'suburban/agent/DELETE_CLIENT_DEAL_ERROR'

export const CLEAR_USER_ERROR = 'suburban/agent/CLEAR_USER_ERROR'
export const CLEAR_PHASE = 'suburban/agent/CLEAR_PHASE'
export const CLEAR_AGENT_PHASE = 'suburban/agent/CLEAR_AGENT_PHASE'
export const CLEAR_AGENT_DATE_PHASE = 'suburban/agent/CLEAR_AGENT_DATE_PHASE'
export const CLEAR_ALL_AGENT_DATA = 'suburban/agent/CLEAR_ALL_AGENT_DATA'

export const TOWN_RESEARCH_DATA = 'suburban/agent/TOWN_RESEARCH_DATA'
export const TOWN_RESEARCH_DATA_SUCCESS = 'suburban/agent/TOWN_RESEARCH_DATA_SUCCESS'
export const TOWN_RESEARCH_DATA_ERROR = 'suburban/agent/TOWN_RESEARCH_DATA_ERROR'

export const SHARE_CLIENT_INFO = 'suburban/agent/SHARE_CLIENT_INFO'
export const SHARE_CLIENT_INFO_SUCCESS = 'suburban/agent/SHARE_CLIENT_INFO_SUCCESS'
export const SHARE_CLIENT_INFO_ERROR = 'suburban/agent/SHARE_CLIENT_INFO_ERROR'

export const UPDATE_TOWN_RESEARCH = 'suburban/agent/UPDATE_TOWN_RESEARCH'
export const UPDATE_TOWN_RESEARCH_SUCCESS = 'suburban/agent/UPDATE_TOWN_RESEARCH_SUCCESS'
export const UPDATE_TOWN_RESEARCH_ERROR = 'suburban/agent/UPDATE_TOWN_RESEARCH_ERROR'

export const SEND_AGENT_CLIENT_MESSAGE = 'suburban/agent/SEND_AGENT_CLIENT_MESSAGE'
export const SEND_AGENT_CLIENT_MESSAGE_SUCCESS = 'suburban/agent/SEND_AGENT_CLIENT_MESSAGE_SUCCESS'
export const SEND_AGENT_CLIENT_MESSAGE_ERROR = 'suburban/agent/SEND_AGENT_CLIENT_MESSAGE_ERROR'

export const GET_AGENT_CLIENT_MESSAGE = 'suburban/agent/GET_AGENT_CLIENT_MESSAGE'
export const GET_AGENT_CLIENT_MESSAGE_SUCCESS = 'suburban/agent/GET_AGENT_CLIENT_MESSAGE_SUCCESS'
export const GET_AGENT_CLIENT_MESSAGE_ERROR = 'suburban/agent/GET_AGENT_CLIENT_MESSAGE_ERROR'

export const GET_AGENT_CLIENT_LAST_MESSAGE = 'suburban/agent/GET_AGENT_CLIENT_LAST_MESSAGE'
export const GET_AGENT_CLIENT_LAST_MESSAGE_SUCCESS = 'suburban/agent/GET_AGENT_CLIENT_LAST_MESSAGE_SUCCESS'
export const GET_AGENT_CLIENT_LAST_MESSAGE_ERROR = 'suburban/agent/GET_AGENT_CLIENT_LAST_MESSAGE_ERROR'

export const REMOVE_AGENT_FROM_TOWN = 'suburban/agent/REMOVE_AGENT_FROM_TOWN'
export const REMOVE_AGENT_FROM_TOWN_SUCCESS = 'suburban/agent/REMOVE_AGENT_FROM_TOWN_SUCCESS'
export const REMOVE_AGENT_FROM_TOWN_ERROR = 'suburban/agent/REMOVE_AGENT_FROM_TOWN_ERROR'

export const ADD_AGENT_ON_TOWN = 'suburban/agent/ADD_AGENT_ON_TOWN'
export const ADD_AGENT_ON_TOWN_SUCCESS = 'suburban/agent/ADD_AGENT_ON_TOWN_SUCCESS'
export const ADD_AGENT_ON_TOWN_ERROR = 'suburban/agent/ADD_AGENT_ON_TOWN_ERROR'

// fetch multiple time slot availability
export const GET_AGENT_TIME_AVAILABILITY = 'suburban/agent/GET_AGENT_TIME_AVAILABILITY'
export const GET_AGENT_TIME_AVAILABILITY_SUCCESS = 'suburban/agent/GET_AGENT_TIME_AVAILABILITY_SUCCESS'
export const GET_AGENT_TIME_AVAILABILITY_ERROR = 'suburban/agent/GET_AGENT_TIME_AVAILABILITY_ERROR'
// update multiple time slot availability
export const UPDATE_AGENT_TIME_AVAILABILITY = 'suburban/agent/UPDATE_AGENT_TIME_AVAILABILITY'
export const UPDATE_AGENT_TIME_AVAILABILITY_SUCCESS = 'suburban/agent/UPDATE_AGENT_TIME_AVAILABILITY_SUCCESS'
export const UPDATE_AGENT_TIME_AVAILABILITY_ERROR = 'suburban/agent/UPDATE_AGENT_TIME_AVAILABILITY_ERROR'

// sync and async google calendar
export const GET_GOOGLE_AUTHENTICATION = 'suburban/agent/GET_GOOGLE_AUTHENTICATION'
export const GET_OUT_LOOK_AUTHENTICATION = 'suburban/agent/GET_OUT_LOOK_AUTHENTICATION'

// call unsync claner api
export const UNSYNC_CALENDAR_EVENT = 'suburban/agent/UNSYNC_CALENDAR_EVENT'
export const UNSYNC_CALENDAR_EVENT_ERROR = 'suburban/agent/UNSYNC_CALENDAR_EVENT_ERROR'
export const UNSYNC_CALENDAR_EVENT_SUCCESS = 'suburban/agent/UNSYNC_CALENDAR_EVENT_SUCCESS'
/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  userPhase: INIT,
  taskListPhase: INIT,
  updateUserPhase: INIT,
  clientPhase: INIT,
  allClientPhase: INIT,
  availabilityPhase: '',
  notePhase: INIT,
  agentPhase: INIT,
  agentChatPhase: INIT,
  addAppointmentPhase: INIT,
  agentAppPhase: INIT,
  agentAppData: [],
  addAgentDayPhase: INIT,
  appointmentPhase: INIT,
  agentTownPhase: INIT,
  getAvilPhase: INIT,
  neverTouchIn4Phase: INIT,
  getAgentChatPhase: INIT,
  deleteAppointmentPhase: INIT,
  searchUserPhase: INIT,
  notTouchPhase: INIT,
  followUpPhase: INIT,
  agentClientPhase: INIT,
  activeClientPhase: INIT,
  agentSchedulePhase: INIT,
  addAppointmentMessage: '',
  activeClientData: [],
  closedUserPhase: INIT,
  clientAptPhase: INIT,
  clientDealPhase: INIT,
  agentDealPhase: INIT,
  deleteClientDealPhase: INIT,
  deleteClientDealData: [],
  agentDealData: [],
  clientDealData: [],
  clientDealMessage: '',
  clientAptData: [],
  closedUserData: [],
  agentClientData: [],
  deleteAppointmentData: [],
  agentScheduleData: [],
  getAvilData: [],
  agentTownData: [],
  agentSearchGetUsersData: [],
  appointmentData: [],
  addAppointmentData: [],
  addAgentDayData: [],
  getAgentChatData: [],
  allclientData: [],
  availabilityData: [],
  neverTouchIn4Data: [],
  followUpMessage: '',
  followUpData: [],
  agentScheduleMessage: '',
  townResearchPhase: INIT,
  townResData: [],
  updateResearchPhase: INIT,
  updateResearchData: [],
  shareClientInfoPhase: INIT,
  shareClientInfoData: [],
  sendAgentClientMsgPhase: INIT,
  sendAgentClientMsgData: [],
  getAgentClientMsgPhase: INIT,
  getAgentClientMsgData: [],
  removeAgentTownPhase: INIT,
  removeAgentTownData: [],
  getClientLastMessagePhase: INIT,
  getClientLastMessageData: [],
  error: null,
  usersData: [],
  updateUserData: [],
  sendAgentData: [],
  notTouchData: [],
  noteData: [],
  clientData: [],
  isSubmitting: false,
  addAgentTownPhase: INIT,
  addAgentTownData: [],

  updateAgentTimeAvailabilityPhase: INIT,
  getAgentTimeAvailabilityPhase: INIT,
  agentTimeAvailability: [],

  unsyncCalendarEventPhase: INIT
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case GET_USERS: {
      return state
        .set('userPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_USERS_SUCCESS: {
      const { payload } = action
      return state
        .set('userPhase', SUCCESS)
        .set('usersData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_USERS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('userPhase', ERROR)
    }

    case GET_ACTIVE_USERS: {
      return state
        .set('activeClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_ACTIVE_USERS_SUCCESS: {
      const { payload } = action
      return state
        .set('activeClientPhase', SUCCESS)
        .set('activeClientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_ACTIVE_USERS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('activeClientPhase', ERROR)
    }

    case TOWN_RESEARCH_DATA: {
      return state
        .set('townResearchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case TOWN_RESEARCH_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('townResearchPhase', SUCCESS)
        .set('townResData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case TOWN_RESEARCH_DATA_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('townResearchPhase', ERROR)
    }

    case GET_CLOSED_USERS: {
      return state
        .set('closedUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLOSED_USERS_SUCCESS: {
      const { payload } = action
      return state
        .set('closedUserPhase', SUCCESS)
        .set('closedUserData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLOSED_USERS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('closedUserPhase', ERROR)
    }

    case GET_SEARCH_USERS: {
      return state
        .set('searchUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_SEARCH_USERS_SUCCESS: {
      const { payload } = action
      return state
        .set('searchUserPhase', SUCCESS)
        .set('agentSearchGetUsersData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_SEARCH_USERS_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('searchUserPhase', ERROR)
    }


    case GET_ALL_CLIENT: {
      return state
        .set('allClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_ALL_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('allClientPhase', SUCCESS)
        .set('allclientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_ALL_CLIENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('allClientPhase', ERROR)
    }


    case GET_AGENTS_ALL_CLIENT: {
      return state
        .set('agentClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENTS_ALL_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentClientPhase', SUCCESS)
        .set('agentClientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENTS_ALL_CLIENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentClientPhase', ERROR)
    }


    case GET_AGENT_AVAILABILITY: {
      return state
        .set('availabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_AVAILABILITY_SUCCESS: {
      const { payload } = action
      return state
        .set('availabilityPhase', SUCCESS)
        .set('availabilityData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_AVAILABILITY_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('availabilityPhase', ERROR)
    }

    // get update agent availability
    case GET_AGENT_TIME_AVAILABILITY: {
      return state
        .set('getAgentTimeAvailabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UPDATE_AGENT_TIME_AVAILABILITY: {
      return state
        .set('updateAgentTimeAvailabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPDATE_AGENT_TIME_AVAILABILITY_SUCCESS: {
      return state
        .set('updateAgentTimeAvailabilityPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_TIME_AVAILABILITY_SUCCESS: {
      const { payload } = action
      return state
        .set('getAgentTimeAvailabilityPhase', SUCCESS)
        .set('agentTimeAvailability', payload.data)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_TIME_AVAILABILITY_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.message)
        .set('getAgentTimeAvailabilityPhase', ERROR)
        .set('updateAgentTimeAvailabilityPhase', ERROR)
    }

    case GET_AVAILABILITY: {
      return state
        .set('getAvilPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AVAILABILITY_SUCCESS: {
      const { payload } = action
      return state
        .set('getAvilPhase', SUCCESS)
        .set('getAvilData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AVAILABILITY_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('getAvilPhase', ERROR)
    }

    case SHARE_CLIENT_INFO: {
      return state
        .set('shareClientInfoPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case SHARE_CLIENT_INFO_SUCCESS: {
      const { payload } = action
      return state
        .set('shareClientInfoPhase', SUCCESS)
        .set('shareClientInfoData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case SHARE_CLIENT_INFO_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('shareClientInfoPhase', ERROR)
    }

    case GET_CLIENT: {
      return state
        .set('clientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('clientPhase', SUCCESS)
        .set('clientData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLIENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientPhase', ERROR)
    }

    case GET_AGENT_DEAL: {
      return state
        .set('agentDealPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_DEAL_SUCCESS: {
      const { payload } = action
      return state
        .set('agentDealPhase', SUCCESS)
        .set('agentDealData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_DEAL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentDealPhase', ERROR)
    }


    case GET_APPOINTMENT: {
      return state
        .set('appointmentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_APPOINTMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('appointmentPhase', SUCCESS)
        .set('appointmentData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_APPOINTMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('appointmentPhase', ERROR)
    }

    case GET_CLIENT_APPOINTMENT: {
      return state
        .set('clientAptPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_CLIENT_APPOINTMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('clientAptPhase', SUCCESS)
        .set('clientAptData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_CLIENT_APPOINTMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientAptPhase', ERROR)
    }

    case GET_AGENT_TOWN: {
      return state
        .set('agentTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_TOWN_SUCCESS: {
      const { payload } = action
      return state
        .set('agentTownPhase', SUCCESS)
        .set('agentTownData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_TOWN_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentTownPhase', ERROR)
    }


    case ADD_AGENT_APPOINTMENT: {
      return state
        .set('addAppointmentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_AGENT_APPOINTMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('addAppointmentPhase', SUCCESS)
        .set('addAppointmentData', payload)
        .set('addAppointmentMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case ADD_AGENT_APPOINTMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('addAppointmentPhase', ERROR)
        .set('addAppointmentMessage', payload.message)
    }

    case CREATE_CLIENT_DEAL: {
      return state
        .set('clientDealPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case CREATE_CLIENT_DEAL_SUCCESS: {
      const { payload } = action
      return state
        .set('clientDealPhase', SUCCESS)
        .set('clientDealData', payload)
        .set('clientDealMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case CREATE_CLIENT_DEAL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('clientDealPhase', ERROR)
        .set('clientDealMessage', payload.message)
    }

    case AGENT_SCHEDULE_ON_CLIENT: {
      return state
        .set('agentSchedulePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case AGENT_SCHEDULE_ON_CLIENT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentSchedulePhase', SUCCESS)
        .set('agentScheduleData', payload)
        .set('agentScheduleMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case AGENT_SCHEDULE_ON_CLIENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentSchedulePhase', ERROR)
        .set('addAppointmentMessage', payload.message)
    }

    case DELETE_APPOINTMENT: {
      return state
        .set('deleteAppointmentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case DELETE_APPOINTMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteAppointmentPhase', SUCCESS)
        .set('deleteAppointmentData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case DELETE_APPOINTMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('deleteAppointmentPhase', ERROR)
    }

    case SEND_AGENT_NOTE: {
      return state
        .set('agentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case SEND_AGENT_NOTE_SUCCESS: {
      const { payload } = action
      return state
        .set('agentPhase', SUCCESS)
        .set('sendAgentData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case SEND_AGENT_NOTE_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentPhase', ERROR)
    }

    case ADD_AGENT_DAY: {
      return state
        .set('addAgentDayPhase', LOADING)
        .set('getAvilPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_AGENT_DAY_SUCCESS: {
      const { payload } = action
      return state
        .set('addAgentDayPhase', SUCCESS)
        .set('addAgentDayData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case ADD_AGENT_DAY_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('addAgentDayPhase', ERROR)
    }


    case AGENT_CHAT_STRATEGIST: {
      return state
        .set('agentChatPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case AGENT_CHAT_STRATEGIST_SUCCESS: {
      const { payload } = action
      return state
        .set('agentChatPhase', SUCCESS)
        .set('agentChatData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case AGENT_CHAT_STRATEGIST_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentChatPhase', ERROR)
    }

    case GET_AGENT_CHAT: {
      return state
        .set('getAgentChatPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_CHAT_SUCCESS: {
      const { payload } = action
      return state
        .set('getAgentChatPhase', SUCCESS)
        .set('getAgentChatData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_CHAT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('getAgentChatPhase', ERROR)
    }

    case CREATE_AGENT_APPOINTMENT: {
      return state
        .set('agentAppPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case CREATE_AGENT_APPOINTMENT_SUCCESS: {
      const { payload } = action
      return state
        .set('agentAppPhase', SUCCESS)
        .set('agentAppData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case CREATE_AGENT_APPOINTMENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('agentAppPhase', ERROR)
    }


    case GET_AGENT_CLIENT_NOTES: {
      return state
        .set('notePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_CLIENT_NOTES_SUCCESS: {
      const { payload } = action
      return state
        .set('notePhase', SUCCESS)
        .set('noteData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_CLIENT_NOTES_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('notePhase', ERROR)
    }

    case CLIENT_NEVER_TOUCH_IN_4W: {
      return state
        .set('userPhase', LOADING)
        .set('taskListPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case CLIENT_NEVER_TOUCH_IN_4W_SUCCESS: {
      const { payload } = action
      return state
        .set('userPhase', SUCCESS)
        .set('taskListPhase', SUCCESS)
        .set('usersData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case CLIENT_NEVER_TOUCH_IN_4W_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('userPhase', ERROR)
        .set('taskListPhase', ERROR)
    }

    case UPDATE_CLIENT_USER: {
      return state
        .set('updateUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UPDATE_CLIENT_USER_SUCCESS: {
      const { payload } = action
      return state
        .set('updateUserPhase', SUCCESS)
        .set('updateUserData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case UPDATE_CLIENT_USER_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('updateUserPhase', ERROR)
    }

    case CLIENT_NOT_TOUCH: {
      return state
        .set('userPhase', LOADING)
        .set('taskListPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case CLIENT_NOT_TOUCH_SUCCESS: {
      const { payload } = action
      return state
        .set('userPhase', SUCCESS)
        .set('taskListPhase', SUCCESS)
        .set('usersData', payload)
        .set('notTouchData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case CLIENT_NOT_TOUCH_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('userPhase', ERROR)
        .set('taskListPhase', ERROR)
    }

    case CLIENT_FOLLOW_UP: {
      return state
        .set('followUpPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case CLIENT_FOLLOW_UP_SUCCESS: {
      const { payload } = action
      return state
        .set('followUpPhase', SUCCESS)
        .set('followUpData', payload)
        .set('followUpMessage', payload.message)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case CLIENT_FOLLOW_UP_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('followUpPhase', ERROR)
    }

    case SEND_AGENT_CLIENT_MESSAGE: {
      return state
        .set('sendAgentClientMsgPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case SEND_AGENT_CLIENT_MESSAGE_SUCCESS: {
      const { payload } = action
      return state
        .set('sendAgentClientMsgPhase', SUCCESS)
        .set('sendAgentClientMsgData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case SEND_AGENT_CLIENT_MESSAGE_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('sendAgentClientMsgPhase', ERROR)
    }

    case GET_AGENT_CLIENT_MESSAGE: {
      return state
        .set('getAgentClientMsgPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_CLIENT_MESSAGE_SUCCESS: {
      const { payload } = action
      return state
        .set('getAgentClientMsgPhase', SUCCESS)
        .set('getAgentClientMsgData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_CLIENT_MESSAGE_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('getAgentClientMsgPhase', ERROR)
    }

    case UNSYNC_CALENDAR_EVENT: {
      return state
        .set('unsyncCalendarEventPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UNSYNC_CALENDAR_EVENT_SUCCESS: {
      return state
        .set('unsyncCalendarEventPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case UNSYNC_CALENDAR_EVENT_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('unsyncCalendarEventPhase', ERROR)
    }

    case DELETE_CLIENT_DEAL: {
      return state
        .set('deleteClientDealPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case DELETE_CLIENT_DEAL_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteClientDealPhase', SUCCESS)
        .set('deleteClientDealData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case DELETE_CLIENT_DEAL_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('deleteClientDealPhase', ERROR)
    }


    case GET_AGENT_CLIENT_LAST_MESSAGE: {
      return state
        .set('getClientLastMessagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_AGENT_CLIENT_LAST_MESSAGE_SUCCESS: {
      const { payload } = action
      return state
        .set('getClientLastMessagePhase', SUCCESS)
        .set('getClientLastMessageData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_AGENT_CLIENT_LAST_MESSAGE_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('getClientLastMessagePhase', ERROR)
    }


    case REMOVE_AGENT_FROM_TOWN: {
      return state
        .set('removeAgentTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case REMOVE_AGENT_FROM_TOWN_SUCCESS: {
      const { payload } = action
      return state
        .set('removeAgentTownPhase', SUCCESS)
        .set('removeAgentTownData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case REMOVE_AGENT_FROM_TOWN_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('removeAgentTownPhase', ERROR)
    }

    case UPDATE_TOWN_RESEARCH: {
      return state
        .set('updateResearchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UPDATE_TOWN_RESEARCH_SUCCESS: {
      const { payload } = action
      return state
        .set('updateResearchPhase', SUCCESS)
        .set('updateResearchData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case UPDATE_TOWN_RESEARCH_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('updateResearchPhase', ERROR)
    }

    case CLEAR_PHASE: {
      return state
        .set('updateUserPhase', INIT)
        .set('agentPhase', INIT)
        .set('appointmentPhase', INIT)
        .set('addAppointmentPhase', INIT)
        .set('addAgentDayPhase', INIT)
        .set('agentAppPhase', INIT)
        .set('deleteAppointmentPhase', INIT)
        .set('addAppointmentData', INIT)
        .set('searchUserPhase', INIT)
        .set('userPhase', INIT)
        .set('followUpPhase', INIT)
        .set('getAvilPhase', INIT)
        .set('clientPhase', INIT)
        .set('addAgentTownPhase', INIT)
        .set('getAgentTimeAvailabilityPhase', INIT)
        .set('updateAgentTimeAvailabilityPhase', INIT)
    }

    case CLEAR_AGENT_PHASE: {
      return state
        .set('addAppointmentPhase', INIT)
        .set('agentSchedulePhase', INIT)
        .set('agentSearchGetUsersData', [])
        .set('deleteClientDealPhase', INIT)
        .set('clientDealPhase', INIT)
    }

    case CLEAR_ALL_AGENT_DATA: {
      return state
        .set('taskListPhase', INIT)
        .set('unsyncCalendarEventPhase', INIT)
        .set('updateUserPhase', INIT)
        .set('agentPhase', INIT)
        .set('addAppointmentPhase', INIT)
        .set('addAgentDayPhase', INIT)
        .set('agentAppPhase', INIT)
        .set('deleteAppointmentPhase', INIT)
        .set('searchUserPhase', INIT)
        .set('userPhase', INIT)
        .set('followUpPhase', INIT)
        .set('clientPhase', INIT)
        .set('availabilityData', [])
        .set('activeClientData', [])
        .set('closedUserData', [])
        .set('agentClientData', [])
        .set('deleteAppointmentData', [])
        .set('getAvilData', [])
        .set('agentTownData', [])
        .set('agentSearchGetUsersData', [])
        .set('appointmentData', [])
        .set('addAppointmentData', [])
        .set('addAgentDayData', [])
        .set('getAgentChatData', [])
        .set('allclientData', [])
        .set('agentChatPhase', [])
        .set('neverTouchIn4Data', [])
        .set('followUpData', [])
        .set('usersData', [])
        .set('updateUserData', [])
        .set('sendAgentData', [])
        .set('notTouchData', [])
        .set('noteData', [])
        .set('clientData', [])
    }


    case CLEAR_AGENT_DATE_PHASE: {
      return state
        .set('availabilityData', [])
        .set('agentSchedulePhase', INIT)
        .set('clientDealPhase', INIT)
        .set('deleteClientDealPhase', INIT)
        .set('agentPhase', INIT)
    }

    case ADD_AGENT_ON_TOWN: {
      return state
        .set('addAgentTownPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_AGENT_ON_TOWN_SUCCESS: {
      const { payload } = action
      return state
        .set('addAgentTownPhase', SUCCESS)
        .set('addAgentTownData', payload)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case ADD_AGENT_ON_TOWN_ERROR: {
      const { payload } = action
      return state
        .set('error', payload.error)
        .set('addAgentTownPhase', ERROR)
    }

    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */


export const agentGetUsers = (data) => ({
  type: GET_USERS,
  payload: data
})

export const agentGetActiveUsers = (data) => ({
  type: GET_ACTIVE_USERS,
  payload: data
})

export const agentGetClosedUsers = (data) => ({
  type: GET_CLOSED_USERS,
  payload: data
})

export const agentSearchGetUsers = (data) => ({
  type: GET_SEARCH_USERS,
  payload: data
})

export const getClientById = (data) => ({
  type: GET_CLIENT,
  payload: data
})

export const getAgentDeal = (data) => ({
  type: GET_AGENT_DEAL,
  payload: data
})

export const getAgentClientMessageData = (data) => ({
  type: GET_AGENT_CLIENT_MESSAGE,
  payload: data
})

export const getAgentClientNote = (data) => ({
  type: GET_AGENT_CLIENT_NOTES,
  payload: data
})

export const sendAgentNote = (data) => ({
  type: SEND_AGENT_NOTE,
  payload: data
})

export const updateClientUser = (data) => ({
  type: UPDATE_CLIENT_USER,
  payload: data
})

export const getAppointments = (data) => ({
  type: GET_APPOINTMENT,
  payload: data
})

export const shareClientInfo = (data) => ({
  type: SHARE_CLIENT_INFO,
  payload: data
})

export const sendAgentClientSMS = (data) => ({
  type: SEND_AGENT_CLIENT_MESSAGE,
  payload: data
})

export const getClientAppointments = (data) => ({
  type: GET_CLIENT_APPOINTMENT,
  payload: data
})

export const getAllClients = (data) => ({
  type: GET_ALL_CLIENT,
  payload: data
})

export const townResearchData = (data) => ({
  type: TOWN_RESEARCH_DATA,
  payload: data
})

export const getAgentAvailability = (data) => ({
  type: GET_AGENT_AVAILABILITY,
  payload: data
})
export const getAgentTimeSlotAvailability = (data) => ({
  type: GET_AGENT_TIME_AVAILABILITY,
  payload: data
})
export const updateAgentTimeSlotAvailability = (data) => ({
  type: UPDATE_AGENT_TIME_AVAILABILITY,
  payload: data
})
export const addAgentAppointment = (data) => ({
  type: ADD_AGENT_APPOINTMENT,
  payload: data
})

export const createClientDeal = (data) => ({
  type: CREATE_CLIENT_DEAL,
  payload: data
})

export const addAgentDay = (data) => ({
  type: ADD_AGENT_DAY,
  payload: data
})

export const createAgentAppointment = (data, schedule_type) => ({
  type: CREATE_AGENT_APPOINTMENT,
  payload: data,
  schedule_type: schedule_type

})

export const clearUserError = () => ({
  type: CLEAR_USER_ERROR
})

export const getAvailability = (data) => ({
  type: GET_AVAILABILITY,
  payload: data
})

export const getAgentTown = (data) => ({
  type: GET_AGENT_TOWN,
  payload: data
})

export const deleteClientDealByAgent = (data) => ({
  type: DELETE_CLIENT_DEAL,
  payload: data
})



export const agentChatStrategist = (data) => ({
  type: AGENT_CHAT_STRATEGIST,
  payload: data
})

export const deleteAppointment = (data) => ({
  type: DELETE_APPOINTMENT,
  payload: data
})

export const getAgentChat = (data) => ({
  type: GET_AGENT_CHAT,
  payload: data
})

export const clientNotTouch4W = (data) => ({
  type: CLIENT_NEVER_TOUCH_IN_4W,
  payload: data
})

export const clientNotTouch = (data) => ({
  type: CLIENT_NOT_TOUCH,
  payload: data
})

export const removeAgentFromTown = (data) => ({
  type: REMOVE_AGENT_FROM_TOWN,
  payload: data
})

export const agentClientFollowUp = (data) => ({
  type: CLIENT_FOLLOW_UP,
  payload: data
})

export const getAgentAllClients = (data) => ({
  type: GET_AGENTS_ALL_CLIENT,
  payload: data
})

export const addAgentOnTown = (data) => ({
  type: ADD_AGENT_ON_TOWN,
  payload: data
})

export const getAgentClientsLastMessage = (data) => ({
  type: GET_AGENT_CLIENT_LAST_MESSAGE,
  payload: data
})

export const clearPhase = () => {
  return {
    type: CLEAR_PHASE
  }
}

export const clearAgentPhase = () => {
  return {
    type: CLEAR_AGENT_PHASE
  }
}

export const agentScheduleOnClient = (data) => ({
  type: AGENT_SCHEDULE_ON_CLIENT,
  payload: data
})

export const updateTownResearch = (data) => ({
  type: UPDATE_TOWN_RESEARCH,
  payload: data
})

export const clearAllagentLogedData = () => {
  return {
    type: CLEAR_ALL_AGENT_DATA
  }
}

export const clearAgentDatePhase = () => {
  return {
    type: CLEAR_AGENT_DATE_PHASE
  }
}

// call api for google authentication token
export const getGoogleAuthentication = () => ({
  type: GET_GOOGLE_AUTHENTICATION,
  payload: api.getGoogleAuthentication()
})
// call api for google authentication token
export const getOutlookAuthentication = () => ({
  type: GET_OUT_LOOK_AUTHENTICATION,
  payload: api.getOutlookAuthentication()
})

export const unsyncCalendarEvent = (data) => ({
  type: UNSYNC_CALENDAR_EVENT,
  payload: data
})


/**
 * Private: Epics
 */

const agentGetUsersEpic = (action$) =>
  action$
    .ofType(GET_USERS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentGetUsers(action.payload))
        .flatMap((payload) => ([{
          type: GET_USERS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_USERS_ERROR,
          payload: { error }
        }))
    })

const agentGetActiveUsersEpic = (action$) =>
  action$
    .ofType(GET_ACTIVE_USERS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentGetActiveUsers(action.payload))
        .flatMap((payload) => ([{
          type: GET_ACTIVE_USERS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_ACTIVE_USERS_ERROR,
          payload: { error }
        }))
    })

const agentGetClosedUsersEpic = (action$) =>
  action$
    .ofType(GET_CLOSED_USERS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentGetClosedUsers(action.payload))
        .flatMap((payload) => ([{
          type: GET_CLOSED_USERS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_CLOSED_USERS_ERROR,
          payload: { error }
        }))
    })

// EPIC : Getting the all agent users for search result in agent dashboard

const agentSearchGetUsersEpic = (action$) =>
  action$
    .ofType(GET_SEARCH_USERS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentSearchGetUsers(action.payload))
        .flatMap((payload) => ([{
          type: GET_SEARCH_USERS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_SEARCH_USERS_ERROR,
          payload: { error }
        }))
    })

const getAgentAvailabilityEpic = (action$) =>
  action$
    .ofType(GET_AGENT_AVAILABILITY)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentAvailability(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_AVAILABILITY_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_AVAILABILITY_ERROR,
          payload: { error }
        }))
    })

const getAllClientsEpic = (action$) =>
  action$
    .ofType(GET_ALL_CLIENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAllClients(action.payload))
        .flatMap((payload) => ([{
          type: GET_ALL_CLIENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_ALL_CLIENT_ERROR,
          payload: { error }
        }))
    })

const getAppointmentsEpic = (action$) =>
  action$
    .ofType(GET_APPOINTMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAppointments(action.payload))
        .flatMap((payload) => ([{
          type: GET_APPOINTMENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_APPOINTMENT_ERROR,
          payload: { error }
        }))
    })

const getClientAppointmentsEpic = (action$) =>
  action$
    .ofType(GET_CLIENT_APPOINTMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getClientAppointments(action.payload))
        .flatMap((payload) => ([{
          type: GET_CLIENT_APPOINTMENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_CLIENT_APPOINTMENT_ERROR,
          payload: { error }
        }))
    })

const getClientByIdEpic = (action$) =>
  action$
    .ofType(GET_CLIENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getClientById(action.payload))
        .flatMap((payload) => ([{
          type: GET_CLIENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_CLIENT_ERROR,
          payload: { error }
        }))
    })

const getAgentDealEpic = (action$) =>
  action$
    .ofType(GET_AGENT_DEAL)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentDeal(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_DEAL_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_DEAL_ERROR,
          payload: { error }
        }))
    })


const sendAgentNoteEpic = (action$) =>
  action$
    .ofType(SEND_AGENT_NOTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.sendAgentNote(action.payload))
        .flatMap((payload) => ([{
          type: SEND_AGENT_NOTE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: SEND_AGENT_NOTE_ERROR,
          payload: { error }
        }))
    })


const getAgentClientNoteEpic = (action$) =>
  action$
    .ofType(GET_AGENT_CLIENT_NOTES)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentClientNote(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_CLIENT_NOTES_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_CLIENT_NOTES_ERROR,
          payload: { error }
        }))
    })

const updateClientUserEpic = (action$) =>
  action$
    .ofType(UPDATE_CLIENT_USER)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateClientUser(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_CLIENT_USER_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_CLIENT_USER_ERROR,
          payload: { error }
        }))
    })

const addAgentAppointmentEpic = (action$) =>
  action$
    .ofType(ADD_AGENT_APPOINTMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addAgentAppointment(action.payload))
        .flatMap((payload) => ([{
          type: ADD_AGENT_APPOINTMENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_AGENT_APPOINTMENT_ERROR,
          payload: { error }
        }))
    })

const createClientDealEpic = (action$) =>
  action$
    .ofType(CREATE_CLIENT_DEAL)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.createClientDeal(action.payload))
        .flatMap((payload) => ([{
          type: CREATE_CLIENT_DEAL_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: CREATE_CLIENT_DEAL_ERROR,
          payload: { error }
        }))
    })

const addAgentDayEpic = (action$) =>
  action$
    .ofType(ADD_AGENT_DAY)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addAgentDay(action.payload))
        .flatMap((payload) => ([{
          type: ADD_AGENT_DAY_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_AGENT_DAY_ERROR,
          payload: { error }
        }))
    })

const getAvailabilityEpic = (action$) =>
  action$
    .ofType(GET_AVAILABILITY)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAvailability(action.payload))
        .flatMap((payload) => ([{
          type: GET_AVAILABILITY_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AVAILABILITY_ERROR,
          payload: { error }
        }))
    })

const createAgentAppointmentEpic = (action$) =>
  action$
    .ofType(CREATE_AGENT_APPOINTMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.createAgentAppointment(action.payload, action.schedule_type))
        .flatMap((payload) => ([{
          type: CREATE_AGENT_APPOINTMENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: CREATE_AGENT_APPOINTMENT_ERROR,
          payload: { error }
        }))
    })

const getAgentTownEpic = (action$) =>
  action$
    .ofType(GET_AGENT_TOWN)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentTown(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_TOWN_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_TOWN_ERROR,
          payload: { error }
        }))
    })

const agentChatStrategistEpic = (action$) =>
  action$
    .ofType(AGENT_CHAT_STRATEGIST)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentChatStrategist(action.payload))
        .flatMap((payload) => ([{
          type: AGENT_CHAT_STRATEGIST_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: AGENT_CHAT_STRATEGIST_ERROR,
          payload: { error }
        }))
    })

const getAgentChatEpic = (action$) =>
  action$
    .ofType(GET_AGENT_CHAT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentChat(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_CHAT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_CHAT_ERROR,
          payload: { error }
        }))
    })

const deleteAppointmentEpic = (action$) =>
  action$
    .ofType(DELETE_APPOINTMENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteAppointment(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_APPOINTMENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_APPOINTMENT_ERROR,
          payload: { error }
        }))
    })

const clientNotTouch4WEpic = (action$) =>
  action$
    .ofType(CLIENT_NEVER_TOUCH_IN_4W)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.clientNotTouch4W(action.payload))
        .flatMap((payload) => ([{
          type: CLIENT_NEVER_TOUCH_IN_4W_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: CLIENT_NEVER_TOUCH_IN_4W_ERROR,
          payload: { error }
        }))
    })

const clientNotTouchEpic = (action$) =>
  action$
    .ofType(CLIENT_NOT_TOUCH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.clientNotTouch(action.payload))
        .flatMap((payload) => ([{
          type: CLIENT_NOT_TOUCH_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: CLIENT_NOT_TOUCH_ERROR,
          payload: { error }
        }))
    })

const agentClientFollowUpEpic = (action$) =>
  action$
    .ofType(CLIENT_FOLLOW_UP)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentClientFollowUp(action.payload))
        .flatMap((payload) => ([{
          type: CLIENT_FOLLOW_UP_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: CLIENT_FOLLOW_UP_ERROR,
          payload: { error }
        }))
    })

const getAgentAllClientsEpic = (action$) =>
  action$
    .ofType(GET_AGENTS_ALL_CLIENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentAllClients(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENTS_ALL_CLIENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENTS_ALL_CLIENT_ERROR,
          payload: { error }
        }))
    })

const agentScheduleOnClientEpic = (action$) =>
  action$
    .ofType(AGENT_SCHEDULE_ON_CLIENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.agentScheduleOnClient(action.payload))
        .flatMap((payload) => ([{
          type: AGENT_SCHEDULE_ON_CLIENT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: AGENT_SCHEDULE_ON_CLIENT_ERROR,
          payload: { error }
        }))
    })

const deleteClientDealByAgentEpic = (action$) =>
  action$
    .ofType(DELETE_CLIENT_DEAL)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteClientDealByAgent(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_CLIENT_DEAL_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_CLIENT_DEAL_ERROR,
          payload: { error }
        }))
    })

const townResearchDataEpic = (action$) =>
  action$
    .ofType(TOWN_RESEARCH_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.townResearchData(action.payload))
        .flatMap((payload) => ([{
          type: TOWN_RESEARCH_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: TOWN_RESEARCH_DATA_ERROR,
          payload: { error }
        }))
    })

const updateTownResearchEpic = (action$) =>
  action$
    .ofType(UPDATE_TOWN_RESEARCH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateTownResearch(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_TOWN_RESEARCH_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_TOWN_RESEARCH_ERROR,
          payload: { error }
        }))
    })

const shareClientInfoEpic = (action$) =>
  action$
    .ofType(SHARE_CLIENT_INFO)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.shareClientInfo(action.payload))
        .flatMap((payload) => ([{
          type: SHARE_CLIENT_INFO_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: SHARE_CLIENT_INFO_ERROR,
          payload: { error }
        }))
    })

const sendAgentClientSMSEpic = (action$) =>
  action$
    .ofType(SEND_AGENT_CLIENT_MESSAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.sendAgentClientSMS(action.payload))
        .flatMap((payload) => ([{
          type: SEND_AGENT_CLIENT_MESSAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: SEND_AGENT_CLIENT_MESSAGE_ERROR,
          payload: { error }
        }))
    })

const getAgentClientMessageDataEpic = (action$) =>
  action$
    .ofType(GET_AGENT_CLIENT_MESSAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentClientMessageData(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_CLIENT_MESSAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_CLIENT_MESSAGE_ERROR,
          payload: { error }
        }))
    })

const removeAgentFromTownEpic = (action$) =>
  action$
    .ofType(REMOVE_AGENT_FROM_TOWN)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.removeAgentFromTown(action.payload))
        .flatMap((payload) => ([{
          type: REMOVE_AGENT_FROM_TOWN_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: REMOVE_AGENT_FROM_TOWN_ERROR,
          payload: { error }
        }))
    })

const addAgentOnTownEpic = (action$) =>
  action$
    .ofType(ADD_AGENT_ON_TOWN)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addAgentOnTown(action.payload))
        .flatMap((payload) => ([{
          type: ADD_AGENT_ON_TOWN_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_AGENT_ON_TOWN_ERROR,
          payload: { error }
        }))
    })

const getAgentClientsLastMessageEpic = (action$) =>
  action$
    .ofType(GET_AGENT_CLIENT_LAST_MESSAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentClientsLastMessage(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_CLIENT_LAST_MESSAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_CLIENT_LAST_MESSAGE_ERROR,
          payload: { error }
        }))
    })


const getAgentTimeAvailabilityEpic = (action$) =>
  action$
    .ofType(GET_AGENT_TIME_AVAILABILITY)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAgentTimeAvailability(action.payload))
        .flatMap((payload) => ([{
          type: GET_AGENT_TIME_AVAILABILITY_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_TIME_AVAILABILITY_ERROR,
          payload: { error }
        }))
    })
const updateAgentTimeAvailabilityEpic = (action$) =>
  action$
    .ofType(UPDATE_AGENT_TIME_AVAILABILITY)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateAgentTimeAvailability(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_AGENT_TIME_AVAILABILITY_SUCCESS,
          payload
        },
        {
          type: GET_AGENT_TIME_AVAILABILITY,
          // eslint-disable-next-line camelcase
          payload: { user_id: action.payload.user_id }
        }
        ]))
        .catch((error) => Rx.Observable.of({
          type: GET_AGENT_TIME_AVAILABILITY_ERROR,
          payload: error
        }))
    })


const unsyncCalendarEventEpic = (action$) =>
  action$
    .ofType(UNSYNC_CALENDAR_EVENT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.unsyncCalendarEvent(action.payload))
        .flatMap((payload) => ([{
          type: UNSYNC_CALENDAR_EVENT_SUCCESS,
          payload
        }, {
            type: 'suburban/user/FETCH_USER',
            // eslint-disable-next-line camelcase
            payload: { user_id: action.payload.userId }
        }]))
        .catch((error) => Rx.Observable.of({
          type: UNSYNC_CALENDAR_EVENT_ERROR,
          payload: error
        }))
    })

/**
 * Public: Export Epics
 */

export const agentEpic = combineEpics(
  agentGetUsersEpic,
  agentSearchGetUsersEpic,
  updateClientUserEpic,
  getAgentClientNoteEpic,
  sendAgentNoteEpic,
  getClientByIdEpic,
  getAppointmentsEpic,
  getAllClientsEpic,
  getAgentAvailabilityEpic,
  addAgentAppointmentEpic,
  addAgentDayEpic,
  getAvailabilityEpic,
  createAgentAppointmentEpic,
  getAgentTownEpic,
  agentChatStrategistEpic,
  getAgentChatEpic,
  deleteAppointmentEpic,
  clientNotTouch4WEpic,
  clientNotTouchEpic,
  agentClientFollowUpEpic,
  getAgentAllClientsEpic,
  agentGetClosedUsersEpic,
  agentGetActiveUsersEpic,
  agentScheduleOnClientEpic,
  getClientAppointmentsEpic,
  createClientDealEpic,
  getAgentDealEpic,
  deleteClientDealByAgentEpic,
  townResearchDataEpic,
  updateTownResearchEpic,
  shareClientInfoEpic,
  sendAgentClientSMSEpic,
  getAgentClientMessageDataEpic,
  removeAgentFromTownEpic,
  addAgentOnTownEpic,
  getAgentClientsLastMessageEpic,
  // epic to fetch AgentAvailability
  getAgentTimeAvailabilityEpic,
  // epic to fetch AgentAvailability
  updateAgentTimeAvailabilityEpic,
  // unsync calendar event type google and outlook
  unsyncCalendarEventEpic
)
