import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import timezone from 'moment-timezone';
import Cookies from 'universal-cookie'
import $ from 'jquery'

import styles from './styles.scss'

const cookies = new Cookies()
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      teamName: "",
      _clientID:'',
      getClientMessagesDataLoading:true,
      chat_text: "",
      id: ""
    }
    this.sendMessage = this.sendMessage.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({_clientID:_clientid})
      const clntdata = {}
      clntdata.client_id = _clientid;
      this.props.getClientMessages(clntdata)
    }
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.sendClientMessagesData && nextProps.sendClientMessagesData.status){
      const clntdata = {}
      clntdata.client_id = this.state._clientID;
      this.props.getClientMessages(clntdata)
      this.props.clearPhase()
    }

    if(nextProps.getClientMessagesData && nextProps.getClientMessagesData.status){
      this.setState({getClientMessagesDataLoading:false})
    }

    if(nextProps.getClientMessagesData && nextProps.getClientMessagesData.status===false){
      this.setState({getClientMessagesDataLoading:false})
    }

  }

  componentDidUpdate(){
    $(".chat__msg--history").animate({ scrollTop: $('.chat__msg--history').prop("scrollHeight")}, 'fast');
  }
  
  sendMessage(sender_id){
    if( this.state.chat_text !== '' && this.state._clientID != '' && sender_id !=''){
      let data = {}
      data.client_id = this.state._clientID
      data.message_body      = this.state.chat_text
      data.sender_id = sender_id
      this.props.sentClientMessage(data)
      this.state.chat_text = ""
    }else{
      return false
    }
  }

  render() {
    const {
      user,
      getClientMessagesData
    } = this.props
    let userAvatar = user && user.avatar;
    let userName   = user && user.first_name +' '+user.last_name;
    let myuserId     = user && user.id;
    return (
    <div>
    {this.state.getClientMessagesDataLoading ?
        <main className="clients__Messages--container">
          <div className="table-responsive table-responsive-edit text-center">
              <img src="../../img/loader2.svg" />
          </div>
        </main>
        :
      <main className="clients__Messages--container">
        <div className="chat__container chat__container--msgs">
          <div className="mesgs">
          <div className="chat__msg--history chat__msg--historymob">
            {
              getClientMessagesData && getClientMessagesData.data && getClientMessagesData.data.map((message,ind) => {
                return(
                  <div key={ind}>

                    { (message.sender_id != myuserId) ? (
                      <div>
                          <div className="chat__msg--date__time">{moment(message.created).format("MM/DD/YY ddd hh:mm A")}</div>
                              {/* dddd, MMMM Do YYYY */}
                        <div className="incoming_msg">
                          <div className="incoming_msg_img">
                            <div className="chat__msg--avtar">
                              <img className="img-responsive" src={message.user_avatar ? message.user_avatar:"img/login-bg-img.png"} alt="sbj"/>
                            </div>
                            <div className="chat__msg--avtar-name">
                              {message.sender_full_name}
                            </div>
                          </div>
                          <div className="received_msg received_msg--mob">
                            <div className="received_withd_msg">
                              <p>{message.message_body}</p>
                              {message.is_received ? 
                               <span className='recievedToreciever'><img src="img/checkmark-check.svg"/> </span>
                               :
                               <span className='notRecieveToreciever'><img src="img/checkmark-uncheck.svg"/></span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      ):
                      <div>
                        <div className="chat__msg--date__time">{moment(message.created).format("MM/DD/YY ddd hh:mm A")}</div>
                          <div className="outgoing_msg">
                            <div className="sent_msg">
                              <p>{message.message_body}</p>
                              {message.is_received ? 
                               <span className='recievedToreciever'><img src="img/checkmark-check.svg"/></span>
                               :
                               <span className='notRecieveToreciever'><img src="img/checkmark-uncheck.svg"/></span>
                              }
                            </div>
                            <div className="outgoin_msg_img">
                              <div className="chat__msg--avtar-name">
                                {userName ? userName:''}
                              </div>
                            <div className="chat__msg--avtar">
                              <img className="img-responsive" src={userAvatar ? userAvatar:"img/login-bg-img.png"} alt="sbj"/>
                            </div>
                          </div>
                        </div>
                      </div> 
                    }
                  </div>
                )
              })
            }
            </div>
            <div className="type_msg typemsg-mob">
            <div className="chat___msg--input-write">
              <textarea 
                name="chat_text" 
                value={this.state.chat_text}
                onChange={this.handleChange.bind(this)} 
                className="chat___msg--input textarea__textmsg" 
                placeholder="Text the Client" />
              <button 
                disabled={myuserId ? false : true}
                className="chat__msg--send__btn textareabtn__textmsg" 
                type="button"
                onClick={()=> this.sendMessage(myuserId)}>
                Send Message
              </button>
            </div>
            </div>
          </div>
        </div>
      </main>
    }
    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
