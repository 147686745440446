import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'

// get jungler user list epic middleware
const getJunglerUsersEpic = (action$) =>
  action$.ofType(types.FETCH_JUNGLER_USERS_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getJunglerUsers(action.payload))
      .map((payload) => ({
        type: types.FETCH_JUNGLER_USERS_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_JUNGLER_USERS_LIST_ERROR,
          payload: { error },
        })
      )
  })
// add update jungler user
const createJunglerUsersEpic = (action$) =>
  action$.ofType(types.CREATE_NEW_JUNGLER_USER).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createJunglerUser(action.payload))
      .map((payload) => ({
        type: types.CREATE_NEW_JUNGLER_USER_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_NEW_JUNGLER_USER_ERROR,
          payload: { error },
        })
      )
  })

// combine jungler user epic
export const junglerUserEpic = combineEpics(getJunglerUsersEpic, createJunglerUsersEpic)
