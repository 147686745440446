import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import _ from "lodash";
import { timeConverter } from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import SideNavigation from '../../components/sideNavigation'
import {getTimeFormate, formatPhoneNumber } from '../../config'
import TopNavigation from '../TopNavigation/container'
import DatePicker from 'react-datepicker'
import Slider from 'react-slick'
import ClientsEmails from './partials/ClientsEmails/component'
import ClientsTextMessages from './partials/ClientsTextMessages/component'
import ClientsNotes from './partials/ClientsNotes/component'
import ClientsChatAgent from './partials/ClientsChatAgent/component'
import ClientsBackgroundProfile from './partials/ClientsBackgroundProfile/component'
import ClientsStrategy from './partials/ClientsStrategy/component'
import ClientsDeal from './partials/ClientsDeal/component'
import ClientsRecordingLinks from './partials/ClientsRecordingLinks/component'
import styles from './styles.scss'
import moment from 'moment'
// import moment from 'moment-timezone';
import timezone from 'moment-timezone';
import Cookies from 'universal-cookie'
// Importing Alert box module
import { confirmAlert } from 'react-confirm-alert'; // Import core
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import $ from 'jquery'
//importing material ui popup for multiselect checkbox
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import {decode as atob } from 'base-64'
// import io from 'socket.io-client'
import Select from 'react-select'
import Modal from 'react-responsive-modal';
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { logoutInvalidRequest } from '../../utils/helper'
const required = value => value ? undefined : 'Required'

// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
// let socket = io.connect(SOCKET_HOSTNAME)
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows:true,
  autoplay:false
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const reviewSourceNames = [
//   'Facebook',
//   'Google'
// ];

const reviewSourceNames = [
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Google', label: 'Google' },
]

const cookies = new Cookies()
let _user = ''
let availableDateSlots = ''
const HOSTNAME = process.env.API_HOSTNAME
let questionData = []
let bizCreditData = []
let options = []
let optionClients = []
let windows = null
// let this.strategistTime_activity = null
class DatePickerCustomInputForEdit extends React.Component {
  strategistTime_activity = null
  render () {
    return (
      <button
        className="form-control custom-select date__picker date__picker--new"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInputForEdit.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="selectdate__global"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};


class ClientActivity extends Component {
  intervalID = 0;
  constructor(props) {
    super(props)

    this.state = {
      id:"",
      client_ID:'',
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      openSchedule: false,
      openActivityHistory: false,
      startDate:moment(),
      selectAppointment:'',
      selectStartTime: '',
      description: '',
      clientId: '',
      err1: {},
      startTimeErr: '',
      review: '',
      review_date : '',
      review_source : [],
      speak_to_press : '',
      selectClient: '',
      availableDates:[],
      selectStrategist: '',
      isLoading: false,
      openChat: false,
      chat: '',
      chatData: [],
      current_userId: '',
      openQuestion: false,
      strategist_bizDev:[],
      selectBizMailIds:[],
      bizDevCount:0,
      reffer_clients:[],
      refferClientsCount:0,
      selectedOption: [],
      current_role: '',
      current_user_name: '',
      ad_tracking_codes: [],
      openStr: false,
      townRankingOpen: false,
      agentRankingOpen: false,
      townDataArray: [],
      agentDataArray: [],
      hours:0,
      minutes:0,
      seconds:0,
      whereFrom: '',
      questionData:[],
      dropDownMenuVal: "emails",
      is_rated_town: false,
      is_rated_agent: false,
      unApprovedAITowns: [],
      processAITowns: false,
      showAIResult: false,
      loadingAITowns: false,
      agentClientChatOpen: false,
      selectAgent: '',
      selectedAgentId: 0,
      photoIndex: 0,
      openImageModel: false
    }
    this.handleFetchAvailaibility = this.handleFetchAvailaibility.bind(this)
    this.selectApprovedTowns = this.selectApprovedTowns.bind(this)
  }

  componentWillMount(){
    const {fetchUser, filterParams} = this.props;
    _user = cookies.get('user')

    if(_user) {
      /*
      if(_user.role =='admin' || _user.role == 'strategist'){
        this.strategistTime_activity = localStorage.getItem('strategistTime')
        if(!this.strategistTime_activity || this.strategistTime_activity == 'null'){
          let obj = {
            strategist_id:_user.id,
            client_id:this.props.match.params.id && this.props.match.params.id,
            start_date: new Date(),
            start_time: new Date(),
            activity_log: 'client_dashboard'
          }
          localStorage.setItem('strategistTime', Date.now())
          this.strategistTime_activity = Date.now()
          axios({
            method: 'POST',
            url: HOSTNAME+'/user/add_client_strategist_timesheet',
            data:obj
          })
          .then((res) => {
            if(res.data && res.data && res.data.status){
              localStorage.setItem('strategistTimeId', res.data.data[0])
            }else{
              localStorage.setItem('strategistTime', 'null')
              this.strategistTime_activity = 'null'
            }
          }).catch(logoutInvalidRequest)
        }
      }
      */
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      let _clientid = this.props.match.params.id && this.props.match.params.id
      this.setState({client_ID: _clientid, current_userId: _user.id, current_role: _user.role, current_user_name: _user.first_name+' '+_user.last_name },()=> {})

      if(_clientid && _clientid !='' ){
        const clntdata = {}
        clntdata.client_id = _clientid;
        this.props.getUserbyId(clntdata)
        this.props.getClientEmail(clntdata)
        this.props.getClientDeal(clntdata)
        this.props.getStrategy(clntdata)
        const userData ={}
        userData.user_id = _clientid
        this.props.getClientChatData(userData)
        this.props.getAllClients()
        this.props.getFilterParams()
        this.props.getClientTownRateDataById(clntdata)
        axios({
          method: 'GET',
          url: HOSTNAME+`/users/fetch_strategist_bd_list/${_clientid}`,
        })
        .then((resData) => {
          if(resData.data.data){
            options = []
            optionClients = []
            bizCreditData = resData.data.data
            bizCreditData && bizCreditData.map(function(data, key) {
              if(data.role !== 'client'){
                options.push({label: data.name ,value:data.id })
              }
              if(data.role == 'client' && data.secondary == true){
                var a = data.id+'1'
                optionClients.push({label: data.secondary_name ,value:a, secondary:true, id:data.id })
                // optionClients.push({label: data.name ,value:data.id, secondary:false })
                // if(data.secondary_name && data.secondary_name !== null){
                //   optionClients.push({label: data.secondary_name ,value:data.id, secondary:true })
                // }
              }
              if(data.role == 'client' && data.primary == true){
                var a = data.id+'0'
                optionClients.push({label: data.name ,value:a, secondary:false, id:data.id })
              }
            })
          }
          this.fetchClientBizDevCredit(_clientid)
        }).catch(logoutInvalidRequest)
      }
    }
    let setVal = this.props && this.props.location && this.props.location.state
    if(setVal === "emails"){
      this.setState({ dropDownMenuVal: "emails" })
    }else if(setVal === "text_msg"){
      this.setState({ dropDownMenuVal: "text_msg" })
    }else if(setVal === "internal_notes"){
      this.setState({ dropDownMenuVal: "internal_notes" })
    }else if(setVal === "chat_with_agents"){
      this.setState({ dropDownMenuVal: "chat_with_agents" })
    }else if(setVal === "background_profile"){
      this.setState({ dropDownMenuVal: "background_profile" })
    }else if(setVal === "strategy_section"){
      this.setState({ dropDownMenuVal: "strategy_section" })
    }else if(setVal === "deal_section"){
      this.setState({ dropDownMenuVal: "deal_section" })
    }else if(setVal === "undefined" || setVal === undefined){
      this.setState({ dropDownMenuVal: "strategy_section" })
    }else{
      this.setState({ dropDownMenuVal: "emails" })
    }
    windows = window
  }

  componentWillReceiveProps(nextProps) {
    const { fetchUser, user } = this.props;
    let _userd = cookies.get('user')
    let self = this
    if(!this.props.match.params.id || this.props.match.params.id =='' || nextProps.singleClientData.status===false){
      this.props.history.push({'pathname' : '/clients-list'})
    }

    if(nextProps && nextProps.deleteClientdata && nextProps.deleteClientdata.status ===true){
      this.setState({'msgDeleteClient': nextProps.deleteClientdata.message})
      this.props.clearPhase();

      // socket.emit('security_alert', {
      //   is_deleted: true,
      //   user_id: this.state.client_ID
      // })

      setTimeout(function(){
        this.props.history.push(`/clients-list`)
      }.bind(this), 800)
    }else if(nextProps && nextProps.deleteClientdata && nextProps.deleteClientdata.status ===false){
      this.setState({'msgDeleteClient': nextProps.deleteClientdata.message})
      this.props.clearPhase();
      setTimeout(function(){
        this.props.history.push(`/activity/${this.state.client_ID}`)
      }.bind(this), 800)

    }

    if(nextProps.addStrategyAptPhase === "success"){
      this.setState({ openSchedule : false })
    }

    if(nextProps.addStrategyAptPhase === "error"){
      toast(nextProps.addAptMessage, {autoClose: true })
    }

    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    }
    if (nextProps.profilePhase != "success" && _userd && user) {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile updated successfully."})
      const data = {}
      data.user_id = this.state.userid
      // this.props.fetchUser(data)
      this.props.clearPhaseUser()
    }
    if (nextProps.profilePhase === "success" && nextProps.profilePhase !== this.props.profilePhase) {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone
        })
      }
      this.props.clearPhaseUser()
    }

    if(nextProps.addUpdateClientPhase === "success"){
      let clientID = this.props.match.params.id && this.props.match.params.id
      const clntdata = {}
      clntdata.client_id = clientID;
      this.props.getUserbyId(clntdata)
      this.props.clearPhase()
    }

    if(nextProps.getTownAndAgentRateData !== this.props.getTownAndAgentRateData){
      let townDataArr = nextProps.getTownAndAgentRateData && nextProps.getTownAndAgentRateData.towns
      let agentDataArr = nextProps.getTownAndAgentRateData && nextProps.getTownAndAgentRateData.agents
      let townRate = nextProps.is_rated_town
      let agentRate = nextProps.is_rated_agent
      this.setState({ townDataArray: townDataArr, is_rated_town: townRate, is_rated_agent: agentRate, agentDataArray: agentDataArr })
    }

    if(nextProps.filterParamsPhase === "success"){
      const data1 = {}
      let strategistId = ''
      const {filterParams} = this.props
      data1.timezone = _user.time_zone
      data1.date  = moment(this.state.startDate._d).format("YYYY-MM-DD")
      if(_user.role === "superadmin" || _user.role === "admin"){
        if(filterParams &&  filterParams.data &&
            filterParams.data.strategists &&
            filterParams.data.strategists.length > 0){
            strategistId = _.get(filterParams.data.strategists,'[0]id')
            this.props.clearFilterPhase()
            data1.strategist_id  = strategistId
            // this.props.getStrategistAvail(data1)
            this.handleFetchAvailaibility(strategistId)
        }
      } else {
        data1.strategist_id  = strategistId
        // this.props.getStrategistAvail(data1)
        strategistId = _user.id
        this.props.clearFilterPhase()
        this.handleFetchAvailaibility(strategistId)
      }
    }

    if (nextProps.impersonatePhase === "success") {
      if(nextProps.impersonateData && nextProps.impersonateData.magic_link){
        let i_user = cookies.get('user')
        let i_Authorization = cookies.get('Authorization')
        let expires = new Date()
        localStorage.clear();
        //expires.setHours(expires.getHours() + 24 )
        expires.setMonth(expires.getMonth()+2);
        expires = new Date(expires)
        cookies.set('i_Authorization',i_Authorization, { path: '/', expires });
        cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires });

        let self = this;
        setTimeout(function() {
          let magic_link = nextProps.impersonateData.magic_link;
          let pathname = "/client-login/token?validate="+magic_link
          self.props.clearPhase()
          document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          self.props.history.push({'pathname' : pathname })
        }, 1000);
      }else{
        alert('Not have enough access')
      }
    }

    if(nextProps.singleClientData && nextProps.singleClientData.user) {
      this.setState({client_ID: nextProps.singleClientData.user.client_id},()=> {})
      this.props.clearPhase()
    }
    if(nextProps.clientChatPhase === "success" || nextProps.clientChatData.status === true){
      this.setState({ chatData: nextProps.clientChatData.data })
    }

    if(nextProps.chatAgent && nextProps.chatAgent.length > 0){
      this.clientAgentsData()
    }
  }

  componentDidMount () {
    let _user = cookies.get('user')

    // socket.on('client_strategist_chat', (data) => {
    //   if (this.state.current_userId && this.props.match.params.id) {
    //     const clientId = parseInt(this.props.match.params.id)
    //     const strategistId = parseInt(this.state.current_userId)
    //     // if(this.state.current_userId){
    //     if (data && ((strategistId === data.sender_id && clientId === data.receiver_id) || (strategistId === data.receiver_id && clientId === data.sender_id))) {
    //       var chat = this.state.chatData
    //       chat.push(data)
    //       this.setState({chatData: _.cloneDeep(chat)})
    //     }
    //   }
    // })

    if(this.strategistTime_activity && this.strategistTime_activity != 'null'){
      this.startInterval()
    }

    document.querySelectorAll('[data-autoresize]').forEach(function (element) {
      element.style.boxSizing = 'border-box'
      var offset = element.offsetHeight - element.clientHeight
      document.addEventListener('input', function (event) {
        event.target.style.height = 'auto'
        event.target.style.height = event.target.scrollHeight + offset + 'px'
      });
      element.removeAttribute('data-autoresize')
    })
  }

  startInterval = () => {
    this.intervalID =  setInterval( () => {
      var a = new Date()
      var b = new Date(parseInt(this.strategistTime_activity))

      a = moment.tz(a, _user.time_zone)
      b = moment.tz(b, _user.time_zone)

      var diff = Math.abs(a - b) / 1000;
       // get hours
      var hours = Math.floor(diff / 3600) % 24;

      // get minutes
      var minutes = Math.floor(diff / 60) % 60;

      minutes = (parseInt(minutes) < 10) ? "0" + parseInt(minutes) : parseInt(minutes)
      // get seconds
      var seconds = diff % 60;
      seconds = (parseInt(seconds) < 10) ? "0" + parseInt(seconds) : parseInt(seconds)
      this.setState({ 'hours': parseInt(hours), 'minutes': minutes, 'seconds': seconds})
      if(parseInt(minutes) === 10){
        this.stopTime('')
      }

    }, 1000)
  }

  componentDidUpdate(prevProps){
    let { singleClientData, location } = this.props;
    const clientInfo = singleClientData && singleClientData.user;
    let client__name = clientInfo && clientInfo.client_full_name
    document.title = !client__name ? 'Suburban Jungle' :  "Jungler: "+client__name;
    if (location.pathname !== prevProps.location.pathname) {
      // if any changes in location then call a child function and load the data from api
      this.historyPushDataUpdates()
    }
  }

  historyPushDataUpdates () {
    const { location } = this.props
    const urlArray = location.pathname.split('/')
    if (urlArray.length === 3) {
      const c_id = urlArray.slice(-1)[0]
      if(urlArray.indexOf('activity') !== -1){
        this.props.clearClientActivityPhase()
        this.props.clearAllagentLogedData()
        const clntdata = {}
        clntdata.client_id = c_id
        this.props.getUserbyId(clntdata)
        this.props.getClientEmail(clntdata)
        this.props.getStrategy(clntdata)
        this.fetchClientBizDevCredit(c_id)
      }
    }
  }

  componentWillUnmount(){
    let _user = cookies.get('user')
    document.title = "Suburban Jungle";
    this.props.clearPhase()
    this.props.clearClientActivityPhase()
    this.props.clearAllagentLogedData()
    clearInterval(this.intervalID);
    
    /*
    if(_user) {
      if(_user.role =='admin' || _user.role == 'strategist'){
        let strategistTimeId = localStorage.getItem('strategistTimeId')
        if(strategistTimeId && strategistTimeId != 'null'){
          let pathname = window && window.location.pathname
          let location = window && window.location.search
          if(pathname && pathname != ''){
            let splitPath = pathname.split('/') // "add-strategy" "add-deal"
            if(splitPath && splitPath.length > 0 && (splitPath[1] === 'activity' || splitPath[1] === 'add-strategy' || splitPath[1] === 'add-deal' || (splitPath[1] === 'add-clients' && location === '?edit'))){
            }else{
              let obj = {
                id:strategistTimeId,
                strategist_id:_user.id,
                client_id:this.state.client_ID,
                end_date: new Date(),
                end_time: new Date(),
                status: 1
              }
              axios({
                method: 'POST',
                url: HOSTNAME+'/user/add_client_strategist_timesheet',
                data:obj
              })
              .then((res) => {
                if(res.data && res.data && res.data.status){
                  localStorage.setItem('strategistTime', 'null')
                  localStorage.setItem('strategistTimeId', null)
                  this.strategistTime_activity = 'null'
                  this.forceUpdate()
                }else{
                  localStorage.setItem('strategistTime', 'null')
                  localStorage.setItem('strategistTimeId', null)
                  this.strategistTime_activity = 'null'
                }
              }).catch(logoutInvalidRequest)
            }
          }
        }
      }
    }
    
    */

    // if (windows) {
    //   window.removeEventListener('beforeunload')
    // }
  }

  handleChangeApt(event) {
    if(event.target.name === "selectStrategist"){
      let selectDate =  moment(this.state.startDate._d).format("YYYY-MM-DD")
      // const data = {}
      // data.strategist_id  = event.target.value
      // data.timezone = _user.time_zone
      // data.date     = selectDate
      // this.props.getStrategistAvail(data)
      let userT = {
        strategist_id: event.target.value,
        date: selectDate,
        timezone:_user.time_zone
      }
      this.handleSetDates(userT)
    }
    this.setState({[event.target.name]: event.target.value})
  }

  handleAgentList(event) {
    this.setState({[event.target.name]: event.target.value , selectedAgentId: event.target.value })
    if(event.target.value){
      const data={}
      data.sender_id = event.target.value
      data.receiver_id = this.state.client_ID
      this.props.getChatHistory(data)
    }
  }

  clientAgentsData(){
    if(this.state.selectedAgentId === 0 ){
      let agentFilterArr = _.filter(this.props.chatAgent, (chat)=>{
        return chat.role === "agent"
      })
      if(agentFilterArr && agentFilterArr.length > 0){
        let firstAgentId = agentFilterArr[0] && agentFilterArr[0].agent_id
        this.setState({ selectedAgentId: firstAgentId })
        const data={}
        data.sender_id = firstAgentId
        data.receiver_id = this.state.client_ID
        this.props.getChatHistory(data)
      }
    }
  }

  impersonateUser(client_id){
    let _user = cookies.get('user')
    let data = {}
    data.client_id = client_id;
    if(_user){
      if(_user.role === 'admin' || _user.role === 'strategist'){
        let strategistTimeId = localStorage.getItem('strategistTimeId')
        if(strategistTimeId && strategistTimeId != 'null'){
          this.stopTime('impersonateUser')
        }
      }
    }
    this.props.impersonateUser(data)
  }

  DeletedClient = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
            <div className="flexdiv">
                <h4 className="popupheader__heading">Are you sure?</h4>
                <button
                  onClick={onClose}
                  type="button"
                  id="close_user_modal"
                  className="close_img close_img--white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                <img src="../../img/close_btn.svg"/>
                </button>
              </div>

              <p className="allpoppadding__para poppinsfontsedit">You want to delete this client?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.handleClickDelete()
                onClose()
            }}> Yes, Delete it!</button>
            {this.state.msgDeleteClient ? <div className='text-center alert alert-success'>{this.state.msgDeleteClient}</div> : ''}
          </div>
        )
      }
    })
  }

  handleClickDelete(){
    const clntdata = {}
    clntdata.client_id = this.state.client_ID;
    this.props.deleteClient(clntdata)
  }


  handleFetchAvailaibility(strategistId){
    this.setState({
        selectStrategist:strategistId
      }, () => {
        let userT = {
          strategist_id:strategistId,
          date:moment().format("YYYY-MM-DD"),
          timezone:_user.time_zone
        }
        this.handleSetDates(userT)
      }
    )
  }

  addAppointmentBtn(id){
    let selectDate =  moment(this.state.startDate._d).format("YYYY-MM-DD")
    const { singleClientData } = this.props
    const clientInfo = singleClientData && singleClientData.user;
      let userT = {
        strategist_id: clientInfo.strategist_id,
        date: selectDate,
        timezone:_user.time_zone
      }
      this.handleSetDates(userT)

    let getAllInfo = this.props.singleClientData && this.props.singleClientData.user
    let strData = _.get(getAllInfo, 'strategists[0].user_id')
    this.setState({
      openSchedule : true,
      clientId: id,
      selectStrategist: strData
    })
  }

  closeScheduleBar(){
    this.setState({
      openSchedule : false,
      startDate:moment()
    })
  }

  activityHistoryBtn(event){
    this.setState({ openActivityHistory: true })
  }

  closeActivityHistory(){
    this.setState({ openActivityHistory: false })
  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
    let _user = cookies.get('user')
    const data = {}
    data.strategist_id  = this.state.selectStrategist
    data.timezone = timezone
    data.date     = selectDate
    this.props.getStrategistAvail(data)
    this.setState({startDate: date})
  }

  handleDateChange(cData, date) {
    let selectDate =  moment(date).format("YYYY-MM-DD")
    let data = {}
    data.follow_up_date_req = selectDate
    data.id = cData.client_id
    this.props.addUpdateClient(data)
  }

  handleReviewDateChange(clientInfo, date){
    let ratingDate =  moment(date).format("YYYY-MM-DD")
    this.setState({review_date: ratingDate})
    let data = {}
    data.review_date = ratingDate
    data.id = clientInfo.client_id
    this.props.addUpdateClient(data)
  }

  chatHandle (e) {
    this.setState({ chat: e.target.value })
  }

  clientChatClick (e) {
    $(".chat_ul_scroll").animate({ scrollTop: $('.chat_ul_scroll').prop("scrollHeight")}, 1000)
    this.setState({ openChat: true })
  }

  clientQuestionClick (e){
    axios({
      method: 'GET',
      url: `${SUBURBANJUNGLE_API_HOST}/users/${this.props.match.params.id}/questions`
    })
    .then((res) => {
      let questionData = []
      if(res.data.data){
        if(res.data.data.question_answer_json !== ''){
          questionData = JSON.parse(new Buffer(res.data.data.question_answer_json, 'base64').toString('utf8'))
          let isChildren = true
          if (questionData && questionData[0] && (parseInt(questionData[0].key) === 0 || parseInt(questionData[0].key) === -2)) {
            isChildren = false
          }
          questionData && questionData.map(function(q, key) {
            // q.question = config.questions[key].q
            // q.hide = config.questions[key].hide ? config.questions[key].hide : false
            // Depends on child options and matched with index ie 5th index will be 6th number question
            if (key === 5 || key === 16 || key === 18 || key === 19 || key === 20 || key === 21 || key === 22 || key === 25 || key === 35) {
              q.isChildren = isChildren
            }

            if (key === 0) { // First question
              if (q.key && parseInt(q.key) > 0) {
                const child = []
                for (let i = 0; i < parseInt(q.key); i++ ) {
                  child.push({
                    name: q.details[`name${i+1}`],
                    age: q.details[`age${i+1}`],
                    school: q.details[`school${i+1}`],
                  })
                }
                q.child = child
              } else {
                q.child = []
              }
            }
            if(q.occupation){
              if(q.occupation.all == 1){
                q.occupation = 'White collar, Blue collar, Finance, Medical, Entrepreneurial, Tech, Creative, Legal, Nice mix of all'
              }else if(q.occupation.unknown == 1){
                q.occupation = "Doesn't matter"
              }else{
                let arr = []
                if(q.occupation.white_collar == 1){ arr.push('White collar') }
                if(q.occupation.blue_collar == 1){ arr.push('Blue collar') }
                if(q.occupation.finance_legal == 1){ arr.push('Finance') }
                if(q.occupation.medical == 1){ arr.push('Medical') }
                if(q.occupation.entrepreneur_self_employed == 1){ arr.push('Entrepreneurial') }
                if(q.occupation.tech == 1){ arr.push('Tech') }
                if(q.occupation.creative == 1){ arr.push('Creative') }
                if(q.occupation.legal == 1){ arr.push('Legal') }
                q.occupation = arr.join(", ")
              }
            }
            if(q.typeOfTown){
              let arr = []
              if(q.typeOfTown.town_personality_sporty == 1){ arr.push('Sporty') }
              if(q.typeOfTown.town_personality_artsy == 1){ arr.push('creative/artsy') }
              if(arr.length > 0){
                q.typeOfTown = arr.join(", ")
              }else{
                q.typeOfTown = ''
              }
            }
            if(q.taxes){
              let arr = []
              if(q.taxes.low == 1){ arr.push('Low') }
              if(q.taxes.high == 1){ arr.push('High') }
              if(q.taxes.medium == 1){ arr.push('Medium') }
              if(arr.length > 0){
                q.taxes = arr.join(", ")
              }else{
                q.taxes = ''
              }
            }
            if(q.schools){
              let arr = []
              if(q.schools.high == 1){ arr.push('Super Highly Ranked …') }
              if(q.schools.middle == 1){ arr.push('Pretty good') }
              if(q.schools.low == 1){ arr.push('Ok to not be highly ranked as long as my kids get a good education') }
              if(arr.length > 0){
                q.schools = arr.join(", ")
              }else{
                q.schools = ''
              }
            }
            if(q.kindergarten){
              let arr = []
              if(q.kindergarten.full == 1){ arr.push('Full day') }
              if(q.kindergarten.half == 1){ arr.push('½ day') }
              if(q.kindergarten.none == 1){ arr.push('Either') }
              if(arr.length > 0){
                q.kindergarten = arr.join(", ")
              }else{
                q.kindergarten = ''
              }
            }
            if(q.busing){
              let arr = []
              if(q.busing.yes == 1){ arr.push('Yes') }
              if(q.busing.no == 1){ arr.push('No') }
              if(q.busing.both == 1){ arr.push("Doesn't matter") }
              if(arr.length > 0){
                q.busing = arr.join(", ")
              }else{
                q.busing = ''
              }
            }
            if(q.universal_pre_k){
              let arr = []
              if(q.universal_pre_k.yes == 1){ arr.push('Yes') }
              if(q.universal_pre_k.no == 1){ arr.push('No') }
              if(q.universal_pre_k.both == 1){ arr.push("Doesn't matter") }
              if(arr.length > 0){
                q.universal_pre_k = arr.join(", ")
              }else{
                q.universal_pre_k = ''
              }
            }
            if(q.transportation){
              let arr = []
              if(q.transportation.trans_mode_train == 1){ arr.push('Train') }
              if(q.transportation.trans_mode_bus == 1){ arr.push('Bus') }
              if(q.transportation.trans_mode_car == 1){ arr.push('Car') }
              if(q.transportation.trans_mode_walk == 1){ arr.push('Walk') }
              if(q.transportation.trans_mode_bike == 1){ arr.push('Bike') }
              if(q.transportation.trans_mode_ferry == 1){ arr.push('Ferry') }
              if(arr.length > 0){
                q.transportation = arr.join(", ")
              }else{
                q.transportation = ''
              }
            }
            if(q.plans){
              let arr = []
              if(q.plans.childcare_live_in_non_driver == 1){ arr.push('Live in Nanny, non driver') }
              if(q.plans.childcare_live_in_driver == 1){ arr.push('Live in Nanny, driver') }
              if(q.plans.childcare_live_out_driver == 1){ arr.push('Live out Nanny, driver') }
              if(q.plans.childcare_live_out_non_driver == 1){ arr.push('Live out Nanny, non driver') }
              if(q.plans.childcare_au_pair == 1){ arr.push('Au pair') }
              if(q.plans.childcare_none_parents == 1){ arr.push('Family') }
              if(q.plans.childcare_daycare == 1){ arr.push('Day care') }
              if(q.plans.childcare_pt_sitter == 1){ arr.push('Part time sitter') }
              if(q.plans.none == 1){ arr.push('None') }
              if(arr.length > 0){
                q.plans = arr.join(", ")
              }else{
                q.plans = ''
              }
            }
            if(q.lands){
              let arr = []
              if(q.lands.land_up_to_1_4_acre == 1){ arr.push('Up to ¼ acre') }
              if(q.lands.land_up_to_1_2_acre == 1){ arr.push('Up to ½ acre') }
              if(q.lands.land_up_to_1_acre == 1){ arr.push('Up to 1 acre') }
              if(q.lands.land_up_to_1_plus == 1){ arr.push('1+acres') }
              if(q.lands.large_acreage == 1){ arr.push('Large acreage') }
              if(arr.length > 0){
                q.lands = arr.join(", ")
              }else{
                q.lands = ''
              }
            }
            if(q.homeType){
              let arr = []
              if(q.homeType.townhome == 1){ arr.push('Townhome') }
              if(q.homeType.condo == 1){ arr.push('Condo') }
              if(q.homeType.single_family == 1){ arr.push('Single family home') }
              if(arr.length > 0){
                q.homeType = arr.join(", ")
              }else{
                q.homeType = ''
              }
            }
            if(q.activities){
              let arr = []
              if(q.activities.activities_ski == 1){ arr.push('Skiing') }
              if(q.activities.activities_squash == 1){ arr.push('Squash') }
              if(q.activities.activities_tennis == 1){ arr.push('Tennis') }
              if(q.activities.activities_hockey == 1){ arr.push('Hockey') }
              if(q.activities.activities_lacrosse == 1){ arr.push('Lacrosse') }
              if(q.activities.activities_dance == 1){ arr.push('Dance') }
              if(q.activities.activities_theatre == 1){ arr.push('Theatre') }
              if(q.activities.activities_football == 1){ arr.push('Football') }
              if(q.activities.activities_soccer == 1){ arr.push('Soccer') }
              if(q.activities.activities_writing == 1){ arr.push('Writing') }
              if(arr.length > 0){
                q.activities = arr.join(", ")
              }else{
                q.activities = ''
              }
            }
            if(q.prices){
              var prices = ''
              if(q.prices.none == 1){ prices = '' }
              if(q.prices.price_1_1_5mill == 1){ prices = '$1,000,000 - $1,200,000' }
              if(q.prices.price_1_5_2mill == 1){ prices = '$1,500,000 - $2,000,000' }
              if(q.prices.price_2_3mill == 1){ prices = '$2,000,000 - $2,500,000' }
              if(q.prices.price_3mill_plus == 1){ prices = '$3,000,000 - $3,500,000' }
              if(q.prices.price_500k_1mill == 1){ prices = '$500,000 - $600,000' }
              if(q.prices.price_under_500k == 1){ prices = 'Under $400,000' }
              q.prices = prices
            }
            if (q.commute_to) {
              let commute_to_simple = ''
              _.map(q.commute_to, function(value, key) {
                commute_to_simple += `<b>${value.suburb}</b>` +": " + value.option + " " + value.otherValue+'</br>'
              });
              q.commute_to_simple = 'A. ' + commute_to_simple
            }else{
              q.commute_to_simple = 'A. '
            }
            if (q.places_want) {
              // let places = ''
              // let optionPWA = []
              // _.map(q.places_want, function(value, key) {
              //   let t = ''
              //   let q = ''
              //   let a = ''
              //   places += `<b>${value.suburb}: </b>`
              //   _.map(value.options, function(v, k) {
              //     const otherkey = `other_${k}`
              //     if (v == 1) {
              //       const _k = k.replace(/_/g, ' ')
              //       // places += ''+_k
              //       t = _k
              //       if (value.options[otherkey] && value.options[otherkey].length && k !== 'other_I_have_no_idea!') {
              //         const p = []
              //         _.map(value.options[otherkey], function(opt) {
              //           p.push(opt.label)
              //         })
              //          if (p.length) {
              //           q += "</br><b>If you want to be more specific but you don't have to be...</b></br>"
              //           a += p.join(', ')
              //         }
              //         let obj = {
              //           "t":t,
              //           "q":q,
              //           "a":a
              //         }
              //         optionPWA.push(obj)
              //       }else{
              //         places += ''+_k
              //         places +=', '
              //       }
              //     }
              //   })
              //   places += '</br>'
              // });

              let places = ''
              let optionPWA = []
              _.map(q.places_want, function(value, key) {
                let t = ''
                let q = ''
                let a = []
                places += `<b>${value.suburb}: </b>`
                _.map(value.options, function(v, k) {
                  const otherkey = `other_${k}`
                  if(v == 1){
                    const _k = k.replace(/_/g, ' ')
                    t = _k
                    if (value.options[otherkey] && value.options[otherkey].length && k !== 'other_I_have_no_idea!') {
                      const p = []
                      _.map(value.options[otherkey], function(opt) {
                        p.push(opt.label)
                      })
                      if (p.length) {
                        q = "</br><b>If you want to be more specific but you don't have to be...</b></br>"
                        a = p
                      }
                      let obj = {
                        "t":t,
                        "q":q,
                        "a":a
                      }
                      optionPWA.push(obj)
                    }else{
                      let obj = {
                        "t":_k,
                        "q":'',
                        "a":[]
                      }
                      optionPWA.push(obj)
                    }

                  }
                })
              })

              q.places_want_simple = 'A. ' + places
              q.optionPWA = optionPWA
            }
            if (q.places_donot) {
              // let places = ''
              // _.map(q.places_donot, function(value, key) {
              //   places += `<b>${value.suburb}: </b>`

              //   _.map(value.options, function(v, k) {
              //     const otherkey = `other_${k}`
              //     if (v === 1) {
              //       const _k = k.replace(/_/g, ' ')
              //       places += ''+_k
              //       if (value.options[otherkey] && value.options[otherkey].length && k !== 'other_I_have_no_idea!') {
              //         const p = []
              //         _.map(value.options[otherkey], function(opt) {
              //           p.push(opt.label)
              //         })
              //         if (p.length) {
              //           places += "</br><b>If you want to be more specific but you don't have to be...</b></br>"
              //           places += p.join(', ')
              //           places += '</br>'
              //         }
              //       }else{
              //         places +=', '
              //       }
              //     }
              //   })
              //   places += '</br>'
              // });
              // q.places_donot_simple = 'A. ' + places

              let places = ''
              let optionArr = []
              _.map(q.places_donot, function(value, key) {
                let t = ''
                let q = ''
                let a = []
                places += value.suburb +":"
                _.map(value.options, function(v, k) {
                  const otherkey = `other_${k}`
                  if(v == 1){
                    const _k = k.replace(/_/g, ' ')
                    t = _k
                    if (value.options[otherkey] && value.options[otherkey].length && k !== 'other_I_have_no_idea!') {
                      const p = []
                      _.map(value.options[otherkey], function(opt) {
                        p.push(opt.label)
                      })
                      if (p.length) {
                        q = "</br><b>If you want to be more specific but you don't have to be...</b></br>"
                        a = p
                      }
                      let obj = {
                        "t":t,
                        "q":q,
                        "a":a
                      }
                      optionArr.push(obj)
                    }else{
                      let obj = {
                        "t":_k,
                        "q":'',
                        "a":[]
                      }
                      optionArr.push(obj)
                    }

                  }
                })
              })
              q.places_donot_simple = 'A. ' + places
              q.optionArr = optionArr
            }

            if (key === 2 && q.details) {
              const details = q.details
              q.transportation = ''
              if(details && details.transportation){
                let arr = []
                if(details.transportation.trans_mode_train == 1){ arr.push('Train') }
                if(details.transportation.trans_mode_bus == 1){ arr.push('Bus') }
                if(details.transportation.trans_mode_car == 1){ arr.push('Car') }
                if(details.transportation.trans_mode_walk == 1){ arr.push('Walk') }
                if(details.transportation.trans_mode_bike == 1){ arr.push('Bike') }
                if(details.transportation.trans_mode_ferry == 1){ arr.push('Ferry') }
                if(arr.length > 0){
                  q.transportation = arr.join(", ")
                }else{
                  q.transportation = ''
                }
              }
              q.plans = ''
              if (details && details.plans) {
                let arr = []
                if(details.plans.childcare_live_in_non_driver == 1){ arr.push('Live in Nanny, non driver') }
                if(details.plans.childcare_live_in_driver == 1){ arr.push('Live in Nanny, driver') }
                if(details.plans.childcare_live_out_driver == 1){ arr.push('Live out Nanny, driver') }
                if(details.plans.childcare_live_out_non_driver == 1){ arr.push('Live out Nanny, non driver') }
                if(details.plans.childcare_au_pair == 1){ arr.push('Au pair') }
                if(details.plans.childcare_none_parents == 1){ arr.push('Family') }
                if(details.plans.childcare_daycare == 1){ arr.push('Day care') }
                if(details.plans.childcare_pt_sitter == 1){ arr.push('Part time sitter') }
                if(details.plans.none == 1){ arr.push('None') }
                if(arr.length > 0){
                  q.plans = arr.join(", ")
                }else{
                  q.plans = ''
                }
              }
              q.commute_to = ''
              if (details && details.commute_to) {
                let commute_to_simple = ''
                _.map(details.commute_to, function(value, key) {
                  const _other = 'otherValue'+key
                  commute_to_simple += `<b>${value.suburb}</b>` +": " + value.option + " " + (value.option === 'Other'? details[_other] : "") +'</br>'
                });
                q.commute_to_simple = 'A. ' + commute_to_simple
              }
            }

            if(q && q.threePriorityInSearch){
              var text = q.threePriorityInSearch
              text = text.replace(/(?:\r\n|\r|\n)/g, '</br>')
              q.threePriorityInSearch = text
            }
            if(q && q.selectBestTown){
              var text = q.selectBestTown
              text = text.replace(/(?:\r\n|\r|\n)/g, '</br>')
              q.selectBestTown = text
            }
            questionData.push(q)
          })
          this.setState({openQuestion: true, questionData : questionData })
        }else{
          this.setState({openQuestion: true, questionData : questionData })
        }
      }else{
        this.setState({openQuestion: true, questionData : questionData })
      }
    }).catch(logoutInvalidRequest)
    //this.setState({ openQuestion: true, questionData: questionData })
  }

  closeChatBar(e) {
    this.setState({ openChat: false })
  }

  closeQuestionBar(e) {
    if (this.state.showAIResult) {
      this.setState({ showAIResult: false })
    } else {
      this.setState({ openQuestion: false, questionData:[] })
    }
  }

  sendBtn (e) {
    // const { chatStrategist, chat, current_userId, client_ID } = this.state
    // if(chat && chat.replace(/\s/g, '').length !== 0){
    //   socket.emit('client_strategist_chat', {
    //     message: chat,
    //     receiver_id: client_ID,
    //     sender_id: current_userId,
    //     sender_type: "client",
    //     receiver_type: "strategist",
    //     client_id: client_ID,
    //     strategist_id: current_userId
    //   })
    //   $(".chat_ul_scroll").animate({ scrollTop: $('.chat_ul_scroll').prop("scrollHeight")}, 1000)
    //   this.setState({chat: ''})
    //   document.getElementById('my_textareaBox').style.height = '58px'
    // }
  }

  selectTime(time) {
    this.setState({selectStartTime: time, err: {}})
  }

  handleChange(clientInfo, event) {
    this.setState({ [event.target.name]: event.target.value })
    let data = {}
    if(event.target.name==='speak_to_press'){
      data.speak_to_press = event.target.value;
    }
    if(event.target.name==='review'){
      if(event.target.value!=='Yes'){
        data.review_source = [];
        data.review_date = '';
      }
      data.review = event.target.value;
    }
    if(event.target.name==='review_source'){
      data.review_source = event.target.value;
    }
    data.id = clientInfo.client_id;
    this.props.addUpdateClient(data)
  }

  reviewHandleChange = selectedOption => {
    let data = {}
    if(selectedOption){
      data.id = this.state.client_ID
      data.review_source = selectedOption
      this.props.addUpdateClient(data)
      this.setState({ review_source: selectedOption })
    }else{
      data.id = this.state.client_ID
      data.review_source = []
      this.props.addUpdateClient(data)
      this.setState({ review_source: [] })
    }
  }

  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectClient, selectAppointment, textarea, startDate, selectStrategist } = this.state;
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
     let date =  moment(startDate._d).format("YYYY-MM-DD")
     let data = {}
     data.client_id        = this.state.clientId
     data.appointment_type = selectAppointment
     data.date             = date
     data.timezone         = timezone
     data.description      = this.state.description
     data.time             = selectStartTime
     data.strategist_id    = selectStrategist
     this.props.addStrategistApt(data)
    }
  }

  handleSetDates(data){
    this.setState({ availableDates:[], startDate: moment()})

    const queryString = new URLSearchParams({
      date: data.date,
      timezone: data.timezone
    })
    
    const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategistId}/availability-dates?${queryString}`

    axios({
      url: url,
      data: data
    })
    .then((res) => {
      if(res && res.data && res.data.data){
        let dateArray = this.formateDates(res.data.data);
        if (dateArray.length > 0) {
          this.setState({ availableDates:dateArray, startDate: dateArray[0]})
          const data1 = {}
          data1.timezone = _user.time_zone
          data1.date  = moment(dateArray[0]).format("YYYY-MM-DD")
          data1.strategist_id  = this.state.selectStrategist
          this.props.getStrategistAvail(data1)
          if(dateArray == '' && dateArray.length == 0){
            this.state.err.dateErr = "Dates Not Available"
          }
        } else {
          this.setState({availableDates:[]})
        }
      } else {
          this.setState({availableDates:[]})
      }
    })
      .catch(logoutInvalidRequest)
  }

  formateDates(dateArray){
    let finalArr = [];
    dateArray.forEach(function(date){
      finalArr.push(moment(date.date));
    });
    return finalArr;
  }

  toggledropDown(val){
    if(val==='filterStrategist'){
      this.setState({strategistDropdown: !this.state.strategistDropdown })
    }
  }

  selectBizDevCredit(selectedOption) {
    let _user = cookies.get('user')
    let idsArr = this.state.selectBizMailIds
    let clientId = this.props.match.params.id
    if(selectedOption && selectedOption !== undefined && selectedOption !== null){
      var difference = _.difference(selectedOption, idsArr)
      this.setState({ strategist_bizDev: selectedOption, selectBizMailIds:idsArr })
    }
  }

  selectRefferClients(selectedOption) {
    var diffForRemove = _.difference(this.state.reffer_clients, selectedOption)
    if(_.size(diffForRemove) > 0){
      selectedOption = diffForRemove
    }
    let _user = cookies.get('user')
    let clientId = this.props.match.params.id
    if(selectedOption && selectedOption !== undefined && selectedOption !== null){
      this.setState({ reffer_clients: selectedOption})
      this.sendRefferClients(selectedOption)
    }
  }

  sendBizDevCredit(e){
    const { strategist_bizDev, reffer_clients } = this.state
    let _user = cookies.get('user')
    let clientId = this.props.match.params.id
    let userIds = []
    let refferClientsIds = []
    strategist_bizDev && strategist_bizDev.map(function(data, key) {
      userIds.push(data.value)
    })

    reffer_clients && reffer_clients.map(function(data, key) {
      refferClientsIds.push(data.id)
    })

    let data = {}
    data.client_id = parseInt(clientId)
    data.user_id = userIds
    data.created_by = _user.id
    data.type = 'add'
    data.refferClients = refferClientsIds
    axios({
      method: 'POST',
      url: SUBURBANJUNGLE_API_HOST+`/clients/${data.client_id}/biz-dev-credits`,
      data:data
    })
    .then((res) => {
      this.setState({strategist_bizDev: [], bizDevCount:0 })
      this.fetchClientBizDevCredit(clientId)
    }).catch(logoutInvalidRequest)
  }

  sendRefferClients(selectedOption){    
    const { reffer_clients, strategist_bizDev } = this.state
    let _user = cookies.get('user')
    let clientId = this.props.match.params.id
    let userIds = []
    let bizdevIds = []
    selectedOption && selectedOption.map(function(data, key) {
      userIds.push(data.id)
    })
    strategist_bizDev && strategist_bizDev.map(function(data, key) {
      bizdevIds.push(data.id)
    })
    let data = {}
    data.client_id = parseInt(clientId)
    data.user_id = [userIds[userIds.length-1]]
    data.created_by = _user.id
    data.type = 'add'
    data.bizdevs = bizdevIds
    data.secondary = ([selectedOption[selectedOption.length-1]] && [selectedOption[selectedOption.length-1]][0].secondary && [selectedOption[selectedOption.length-1]][0].secondary == true) ? true : false
    const url = `${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/referrers`
    axios({
      method: 'POST',
      url,
      data
    })
    .then((res) => {
      this.setState({reffer_clients:  [], refferClientsCount:0})
      this.fetchClientBizDevCredit(clientId,'save')
    }).catch(logoutInvalidRequest)
  }

  fetchClientBizDevCredit(client_id,type){
    axios({
      method: 'GET',
      url: HOSTNAME+`/users/client_strategist_bd_list/${client_id}`,
    })
    .then((resD) => {
      if(resD.data.data && resD.data.data.length > 0){
        let ids = []
        let clientIds = []
        let cIds = []
        let filterRefferClients = []
        resD.data.data && resD.data.data.map(function(data, key) {
          if(data.type == 'strategist_bd'){
            ids.push(data.user_id)
          }
          if(data.type == 'client'){
            cIds.push(data.user_id)
            if(data.secondary === 1){
              var a = data.user_id+'1'
              let temp = _.filter(optionClients, (v) =>  (v.value === a) === true )
              let indexT = temp && temp.length && _.findIndex(temp, p => {
                const txt = data.secondary === 1 ? true : false
                return (p.secondary === txt)
              })
              if(indexT !== -1){
                filterRefferClients.push(temp[indexT])
              }
            }

            if(data.secondary === 0){
              var a = data.user_id+'0'
              let temp = _.filter(optionClients, (v) =>  (v.value === a) === true )
              let indexT = temp && temp.length && _.findIndex(temp, p => {
                return (p.secondary === false)
              })
              if(indexT !== -1){
                filterRefferClients.push(temp[indexT])
              }
            }
          }
        })
        let filterArr = _.filter(options, (v) => _.includes(ids, v.value) === true)
        if(type !== 'save'){
          if(_.size(filterArr) > 0){
            this.setState({ strategist_bizDev: filterArr, selectBizMailIds: filterArr, bizDevCount: _.size(filterArr)})
          }else{
            this.setState({ strategist_bizDev: [], selectBizMailIds: [], bizDevCount:0})
          }
        }

        if(_.size(filterRefferClients) > 0){
          this.setState({ reffer_clients: filterRefferClients, refferClientsCount: _.size(filterRefferClients)})
        }
      }else{
        if(type == 'save'){
          this.setState({ reffer_clients:[],refferClientsCount:0 })
        }else{
          this.setState({ strategist_bizDev: [], selectBizMailIds: [], bizDevCount: 0, reffer_clients:[],refferClientsCount:0 })
        }
      }
    }).catch(logoutInvalidRequest)
  }
  showFullText(data) {
    const codes = data.ad_tracking_codes ? data.ad_tracking_codes.split(',') : []
    this.setState({ clientName: data.client_full_name, openStr: true, ad_tracking_codes: codes })
  }

  onCloseStrategistModal() {
    this.setState({ clientName: '', ad_tracking_codes: [], openStr: false })
  }

  townRankingClick(e){
    this.setState({ townRankingOpen: true, agentRankingOpen: false })
  }

  closeTownRanking(e){
    this.setState({ townRankingOpen: false })
  }

  agentRankingClick(e){
    this.setState({ agentRankingOpen: true, townRankingOpen: false })
  }

  closeAgentRanking(e){
    this.setState({ agentRankingOpen: false })
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  mainDropDownMenu(e){
    this.setState({ dropDownMenuVal: e.target.value })
    const { history } = this.props
    if(e.target.value === "emails"){
      history.push({pathname: `/activity/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "text_msg"){
      history.push({pathname:`/activity/text-messages/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "internal_notes"){
      history.push({pathname: `/activity/clients-notes/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "chat_with_agents"){
      history.push({pathname:`/activity/clients-chat/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "background_profile"){
      history.push({pathname: `/activity/background-profile/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "strategy_section"){
      history.push({pathname:`/activity/clients-strategy/${this.state.client_ID}`, state: e.target.value})
    }else if(e.target.value === "deal_section"){
      history.push({pathname: `/activity/clients-deal/${this.state.client_ID}`, state: e.target.value})
    }
  }

  startTime(data){
    /*
    let _user = cookies.get('user')
    let obj = {
      strategist_id:_user.id,
      client_id:this.props.match.params.id && this.props.match.params.id,
      start_date: new Date(),
      start_time: new Date(),
      activity_log: 'client_dashboard'
    }
    localStorage.setItem('strategistTime', Date.now())
    this.strategistTime_activity = Date.now()
    this.startInterval()
    this.forceUpdate()
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('strategistTimeId', res.data.data[0])
      }else{
        localStorage.setItem('strategistTime', 'null')
        this.strategistTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  stopTime(data){
    /*
    clearInterval(this.intervalID);
    let strategistTimeId = localStorage.getItem('strategistTimeId')
    let _user = cookies.get('user')
    let obj = {
      id:strategistTimeId,
      strategist_id:_user.id,
      client_id:this.props.match.params.id && this.props.match.params.id,
      end_date: new Date(),
      end_time: new Date(),
      status: 1
    }
    axios({
      method: 'POST',
      url: HOSTNAME+'/user/add_client_strategist_timesheet',
      data:obj
    })
    .then((res) => {
      if(res.data && res.data && res.data.status){
        localStorage.setItem('strategistTime', 'null')
        localStorage.setItem('strategistTimeId', null)
        this.strategistTime_activity = 'null'
        this.forceUpdate()
      }else{
        localStorage.setItem('strategistTime', 'null')
        localStorage.setItem('strategistTimeId', null)
        this.strategistTime_activity = 'null'
      }
    }).catch(logoutInvalidRequest)
    */
  }

  editClientButton(data, e){
    this.setState({ whereFrom: "edit" })
    this.props.history.push({
      'pathname': '/add-clients',
      state: data,
      search: '?edit'
    })
  }

  showAIResults() {
    const jwtToken = cookies.get('Authorization')
    if (jwtToken) {
      this.setState({ showAIResult: true, loadingAITowns: true })
      axios({
        method: 'GET',
        url: HOSTNAME+`/user/get_unapproved_recommended_ai_towns/${this.props.match.params.id}`,
        'headers': { 'Authorization': jwtToken }
      }).then(res => {
        if (res.data && res.data && res.data.status) {
          const unApprovedAITowns = res.data && res.data.data ? res.data.data : []
          this.setState({ unApprovedAITowns, loadingAITowns: false })
        } else {
          this.setState({ unApprovedAITowns: [], loadingAITowns: false })
        }
      }).catch(logoutInvalidRequest)
    }
  }

  selectApprovedTowns(index) {
    let towns = this.state.unApprovedAITowns
    towns[index].status = towns[index].status === 2 ? 1 : 2
    this.setState({ unApprovedAITowns: towns })
  }

  saveApprovedTowns() {
    const jwtToken = cookies.get('Authorization')
    const unApprovedAITowns = this.state.unApprovedAITowns
    const approvedTowns = []
    const unapprovedTowns = []

    if (jwtToken && unApprovedAITowns && unApprovedAITowns.length > 0) {
      this.setState({ processAITowns: true })
      let client_id = 0
      unApprovedAITowns.forEach(function(town){
        if (town && town.status === 1) {
          approvedTowns.push(town.id) // recommended_town table primary id
        } else if (town && town.status === 2) {
          unapprovedTowns.push(town.id) // recommended_town table primary id
        }
        client_id = town.client_id
      })
      const data = {
        client_id,
        approvedTowns,
        unapprovedTowns
      }
      axios({
          method: 'POST',
          url: HOSTNAME+'/user/approve_recommended_ai_towns',
          'headers': { 'Authorization': jwtToken },
          data: data
        }).then(res => {
          this.setState({ processAITowns: false })
        }).catch(logoutInvalidRequest)
    }
  }

  agentClientChatClick(val){
    if(val === 'show'){
      this.agentAndClientGetChat()
    }
    this.setState({ agentClientChatOpen: !this.state.agentClientChatOpen })
  }

  agentAndClientGetChat(){
    let agentFilterArr = _.filter(this.props.chatAgent, (chat)=>{
      return chat.role === "agent"
    })
    if(agentFilterArr && agentFilterArr.length > 0){
      let firstAgentId = agentFilterArr[0] && agentFilterArr[0].agent_id
      this.setState({ selectedAgentId: firstAgentId })
      const data={}
      data.sender_id = firstAgentId
      data.receiver_id = this.state.client_ID
      this.props.getChatHistory(data)
    }
  }

  imageOpen(){
    this.setState({ openImageModel: !this.state.openImageModel })
  }

  render() {

    const {
      singleClientData,
      clientEmaildata,
      clientDealdata,
      token,
      user,
      strategyData,
      filterParams,
      strategistAvailabilityData,
      strategistAvailabilityPhase,
      phase,
      location,
      allclientData,
      chatAgent,
      chatHistoryData
    } = this.props;

    const {
      selectStrategist,
      review,
      speak_to_press,
      review_date,
      review_source,
      clientId,
      availableDates,
      hours,
      minutes,
      seconds,
      questionData,
      openImageModel,
      photoIndex
    } = this.state

    // if (windows) {
    //   window.addEventListener('beforeunload', this.onUnload)
    // }
    let chatHistory = _.orderBy(chatHistoryData && chatHistoryData.data, ['created'],['desc'])
    if(strategistAvailabilityData.length === 0 || strategistAvailabilityData.data === '' || strategistAvailabilityData.data && strategistAvailabilityData.data.length == 0){
      this.state.err1.startTimeErr = 'In this date no time slot available'
    }else if(this.props.strategistMessage === "Calendar not found"){
      this.state.err1.startTimeErr = 'In this date no time slot available'
    }else{
      this.state.err1.startTimeErr = ''
    }

    if(this.state.openChat){
      document.body.style.overflow = "hidden"
    }else{
      document.body.style.overflow = "auto"
    }

    if(this.state.openQuestion){
      document.body.style.overflow = "hidden"
    }else{
      document.body.style.overflow = "auto"
    }


    const clientInfo = singleClientData && singleClientData.user;
    let rangestr = clientInfo && clientInfo.price_range;
    let priceRange
    if(rangestr){
      let rangearr = rangestr.split('-');
      if(parseInt(rangearr[0].trim()).toLocaleString() == 0){
        priceRange = '$'+ parseInt(rangearr[0].trim()).toLocaleString()
      }else{
        priceRange = '$'+ parseInt(rangearr[0].trim()).toLocaleString()+ ' - '+'$'+parseInt(rangearr[1].trim()).toLocaleString();
      }
    }else{
      priceRange = rangestr;
    }

    let clientList = ''
    if(allclientData &&  allclientData.data && allclientData.data.length>0) {
      clientList = allclientData.data.map((val, index) => {
        if(val.client_id === clientId){
          return (
              <option key={index} value={val.client_id}>{ val.first_name +' '+ val.last_name }</option>
          )
        }
      });
    }

    let strategistList = ''
    if(filterParams &&  filterParams.data && filterParams.data.strategists && filterParams.data.strategists.length>0) {
      strategistList = filterParams.data.strategists.map((strategist, idx) => {
        return (
          <option key={idx} value={strategist.id}>{strategist.first_name +' '+ strategist.last_name}</option>
        )
      });
    }

    let appoinment = config.strategistAppointment.map((val, index) => {
      return (
        <option key={index} value={val.name}>{val.name}</option>
      )
    });

    availableDateSlots = strategistAvailabilityData && strategistAvailabilityData.data && strategistAvailabilityData.data.length > 0 && strategistAvailabilityData.data.map((slotval, indexslot) => {
        return(
          <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
        )
    });

    if(strategistAvailabilityData === '' || strategistAvailabilityData && strategistAvailabilityData.data === '' || strategistAvailabilityData && strategistAvailabilityData.data && strategistAvailabilityData.data.length === 0){
      this.state.err.startTimeErr = 'Calendar Not Found'
    }else{
      this.state.err.startTimeErr = ''
    }

    let agentList = []
    if(chatAgent && chatAgent.length>0) {
      agentList = chatAgent.map((agent, agentIdx) => {
        if(agent.role === "agent"){
          return (
            <option key={agentIdx} value={agent.agent_id}>{agent.agent_full_name}</option>
          )
        }
      });
    }

    let locationData = {}
    let histryLength = []
    locationData.clientData = singleClientData.user;
    locationData.strategyData = strategyData && strategyData.data && strategyData.data.length > 0 ? strategyData.data : [];
    let reviewValue = review !=='' ? review : clientInfo && clientInfo.review ? clientInfo.review : '';
    let speakToPressValue = speak_to_press !=='' ? speak_to_press : clientInfo && clientInfo.speak_to_press ? clientInfo.speak_to_press : '';
    let reviewSourceValue = review_source.length > 0 ? review_source : clientInfo && clientInfo.review_source && clientInfo.review_source.length > 0 ? clientInfo.review_source : [];
    let reviewDateValue = review_date !=='' ? moment(review_date).format('MM/DD/YY') : clientInfo && clientInfo.review_date ? moment.utc(clientInfo.review_date).format('MM/DD/YY') : '';
    let clientInfoData = clientInfo && clientInfo.strategists
    var uniqueStrategistList = _.uniqBy(clientInfoData, 'user_id');
    let cData = []
    if(clientInfo){
      cData = {
        showMsg: true,
        client_id: this.state.client_ID,
        created_date: "2018-07-12T05:41:59.000Z",
        message: "Hi there! Let me know if you have any questions?",
        receiver_id: this.state.current_userId,
        role: "client",
        sender_avatar: (_.get(clientInfo, '[0].avatar', '../../img/default-user.svg')),
        sender_full_name: this.state.current_user_name,
        sender_id: this.state.client_ID
      }
    }
    let concatChat = _.concat(this.state.chatData, cData)
    let allChatData =  _.orderBy(concatChat, ['created_date'],['desc'])
    let questions = config.questions
    const ad = clientInfo && clientInfo.ad_tracking_codes ? clientInfo.ad_tracking_codes : ""
    // clientInfo.phone_mobile = _.get(clientInfo,'phone_mobile','123').replace(/\D/g, '')
    // clientInfo.secondary_phone_email = clientInfo.secondary_phone_email.replace(/\D/g, '')
    return (
      <div>
         <TopNavigation {...this.props}/>
         <ToastContainer autoClose={3000}/>
          <div id="wrapper">
            <SideNavigation {...this.props}/>
            <div id="page-content-wrapper">
              <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="client__activity--container mobile__tab--container">

                        <div className="row">
                          <div className="col-md-12">
                            <div className="client__name">
                                {clientInfo && clientInfo.client_full_name} <span className="client__name--status">({clientInfo && clientInfo.status})</span>
                            </div>
                            <div className="client__secondary_name">
                                {clientInfo && clientInfo.secondary_name ? "Secondary Contact: ": ''}{clientInfo && clientInfo.secondary_name ? clientInfo.secondary_name : ''}
                            </div>
                          </div>

                          <div className="col-md-12 ">

                            <div className="client__action--btn">
                              {
                                  user && user.role && (user.role ==='strategist'|| user.role ==='superadmin'|| user.role === 'admin' || user.role === 'former-strategist-inactive') ?
                                  <button className="btn btn__green strategy-right font500"
                                   onClick={ this.clientChatClick.bind(this)} >
                                  Chat Client
                                 </button>

                                : ''
                              }
                              {
                                user && user.role && (user.role ==='superadmin' || user.role ==='admin' || user.role ==='strategist') ?
                                  <button className="btn btn__green strategy-right font500"
                                    onClick={ this.clientQuestionClick.bind(this)} >
                                    AI Questions
                                  </button>
                                : ''
                              }

                              {user && user.role && (user.role ==='superadmin' || user.role ==='admin' || user.role ==='strategist') ?
                                          <button className="btn btn__green strategy-right btn__profile font500" onClick={this.impersonateUser.bind(this, clientInfo && clientInfo.client_id)}>Impersonate User</button>
                                        : ''
                              }
                              <button className="btn btn__green strategy-right font500"
                                 onClick={() => this.addAppointmentBtn(singleClientData.user.client_id)}>
                                + Appointment
                               </button>

                              <button className="btn btn__green strategy-right font500"
                                 onClick={() =>
                                  {
                                    this.props.history.push({ pathname: '/add-strategy', state: locationData})
                                  }}
                                  >
                                Add Strategy
                               </button>

                              <button onClick={this.editClientButton.bind(this, singleClientData)} className="btn btn__black--outline font500">Edit Client</button>

                              <button className="btn btn__green strategy-right btn__profile font500" onClick={this.townRankingClick.bind(this)}>Towns Ranking</button>
                               <button className="btn btn__green strategy-right btn__profile font500" onClick={this.agentRankingClick.bind(this)}>Agents Ranking</button>

                               {user && user.role && (user.role ==='admin' || user.role ==='strategist') && this.strategistTime_activity === 'null' ?
                                          <button className="btn btn__green strategy-right btn__profile start__btn--table font500" onClick={this.startTime.bind(this)}><img src="img/playbutton.svg" /> Start</button>
                                        : ''
                              }
                              {user && user.role && (user.role ==='admin' || user.role ==='strategist') && this.strategistTime_activity !== 'null' ?
                                          <button className="btn btn__green strategy-right btn__profile play__btn--table font500" onClick={this.stopTime.bind(this)}><img src="img/stopbutton.svg" /> Stop ({hours}:{minutes}:{seconds})</button>
                                        : ''
                              }
                              <button className="btn btn__delete--outline font500" onClick={this.DeletedClient}>Delete Client</button>

                               <button className="btn btn__green strategy-right btn__profile font500" onClick={()=> this.agentClientChatClick('show')}>Agent & Client Chat</button>
                            </div>
                          </div>

                              {/* mobile btns dropdown open */}
                              {/* <div className="mobview__dropdown--select">
                                <select
                                  onChange={this.mainDropDownMenu.bind(this)}
                                  value={location && location.state}
                                  className="selectdropdown"
                                >
                                  <option value="chat_client">Chat Client</option>
                                  <option value="ai_questions">AI Questions</option>
                                  <option value="impersonate_user">Impersonate User</option>
                                  <option value="appointment">+ Appointment</option>
                                  <option value="add_strategy">Add Strategy</option>
                                  <option value="edit_client">Edit Client</option>
                                  <option value="towns_ranking">Towns Ranking</option>
                                  <option value="agents_ranking">Agents Ranking</option>
                                  <option value="delete_client">Delete Client</option>
                                </select>
                              </div> */}
                              {/* mobile btns dropdown close */}

                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="client__activity--info client__activity--card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">STRATEGIST</h1>
                                    {
                                      uniqueStrategistList && uniqueStrategistList.map((strategist, ind)=>{
                                        return(
                                          (strategist.role === 'strategist' || strategist.role === 'admin' || strategist.role === 'former-strategist-inactive') ?
                                            <h2 key={ind}>
                                              <a className="str_name_rating">
                                                {strategist.strategist_full_name}
                                              </a>
                                              {
                                                (_user.role === "superadmin" || _user.role === "admin") ?(
                                                  strategist.strategist_rating > 0 ?
                                                  <span>
                                                    ({strategist.strategist_rating})
                                                  </span>
                                                  /*<ul className="rating-icons">
                                                    <li><img src={strategist.rating >=1 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                                                    <li><img src={strategist.rating >=2 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                                                    <li><img src={strategist.rating >=3 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                                                    <li><img src={strategist.rating >=4 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                                                    <li><img src={strategist.rating ==5 ? "img/rating-active.png" : "img/rating-inactive.png"}/></li>
                                                  </ul>*/
                                                  : ""
                                                ):""
                                            }
                                            </h2>
                                          :
                                          (uniqueStrategistList && uniqueStrategistList.length === 1 && strategist.role === 'assign_strategist') &&
                                           <h2 key={ind}>
                                              <a className="str_name_rating">
                                                {strategist.strategist_full_name}
                                              </a>
                                              {
                                                (_user.role === "superadmin" || _user.role === "admin") ?(
                                                  strategist.strategist_rating > 0 ?
                                                  <span>
                                                    ({strategist.strategist_rating})
                                                  </span>
                                                  : ""
                                                ):""
                                              }
                                            </h2>
                                        )
                                      })
                                    }
                                  </div>
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">CONTACT</h1>
                                    <h2>{clientInfo && clientInfo.client_email ? "Primary Account ": ''}</h2>

                                    <h2><a>{clientInfo && clientInfo.client_email ? clientInfo.client_email : ''} {clientInfo && clientInfo.phone_mobile.replace(/\D/g, '') ? '| '+clientInfo.country_code+' '+ (clientInfo.phone_mobile.replace(/\D/g, '').length == 10 ? this.formatNumber(clientInfo.phone_mobile.replace(/\D/g, '')) : clientInfo.phone_mobile.replace(/\D/g, '')) :''}</a></h2>

                                    <h2>{clientInfo && clientInfo.secondary_email ? "Secondary Account ": ''}</h2>
                                    <h2>

                                    <a>{clientInfo && clientInfo.secondary_email ? clientInfo.secondary_email :''} {clientInfo && clientInfo.secondary_phone_email.replace(/\D/g, '') ? '| '+clientInfo.secondary_country_code+' '+ (clientInfo.secondary_phone_email.replace(/\D/g, '').length == 10 ? this.formatNumber(clientInfo.secondary_phone_email.replace(/\D/g, '')) : clientInfo.secondary_phone_email.replace(/\D/g, '') ) : ''}
                                    </a>

                                    </h2>
                                  </div>
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">PRICE RANGE</h1>
                                    <h2>{priceRange}</h2>
                                  </div>
                                  {
                                    (clientInfo && clientInfo.rental_budget && clientInfo.rental_budget !== null && clientInfo.rental_budget !== '') ? (
                                      <div className="client__activity--info--items">
                                        <h1 className="uppercasefonts">Rental Budget</h1>
                                        <h2>{clientInfo && clientInfo.rental_budget}</h2>
                                      </div>
                                    ) : ''
                                  }

                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">SOURCE</h1>
                                    <h2>{clientInfo && clientInfo.source}</h2>
                                  </div>
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">Type of Advice</h1>
                                    <h2>{clientInfo && clientInfo.advice ? clientInfo.advice : ''}</h2>
                                  </div>

                                  {_user && _user.role && _user.role ==='strategist' || _user.role == 'superadmin' || _user.role == 'admin' ?
                                    <div className="client__activity--info--items coverage__area">
                                      <h1 className="uppercasefonts">Biz Dev Credit
                                      {(this.state.strategist_bizDev && this.state.strategist_bizDev.length > 0) || this.state.bizDevCount != 0 ? (<button className="btn btn__green_viewmore rigth__btn" type="button" onClick={this.sendBizDevCredit.bind(this)}>
                                         Send
                                        </button>) : ''}
                                      </h1>

                                      <Select
                                        multi={true}
                                        placeholder="select/type an option"
                                        options={options}
                                        onChange={this.selectBizDevCredit.bind(this)}
                                        value={this.state.strategist_bizDev}
                                        className="select__edit select__tab"
                                        >
                                       </Select>
                                    </div>

                                   : ''}
                                  {_user && _user.role && _user.role ==='strategist' || _user.role == 'superadmin' || _user.role == 'admin' ?
                                    <div className="client__activity--info--items coverage__area">
                                      <h1 className="uppercasefonts">Referring Client
                                     {/* {(this.state.reffer_clients && this.state.reffer_clients.length > 0) || this.state.refferClientsCount != 0  ? (<button className="btn btn__green_viewmore rigth__btn" type="button" onClick={this.sendRefferClients.bind(this)}>
                                         Send
                                        </button>) : ''}*/}
                                      </h1>

                                      <Select
                                        multi={true}
                                        placeholder="select/type an option"
                                        options={optionClients}
                                        onChange={this.selectRefferClients.bind(this)}
                                        value={this.state.reffer_clients}
                                        className="select__edit select__tab"
                                        id="data-type"
                                        >
                                       </Select>
                                    </div>

                                   : ''}
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">Willing to speak to press/featured in a blog?</h1>
                                    <select className="client-review-select custom-select" name='speak_to_press' value={speakToPressValue} onChange={this.handleChange.bind(this, clientInfo)}>
                                      <option value ="">Select an option</option>
                                      <option value ="Asked">Asked</option>
                                      <option value ="Yes">Yes</option>
                                      <option value ="No">No</option>
                                      <option value ="Declined">Declined</option>
                                      <option value ="no_response">No Response</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">SUBURB</h1>
                                    {
                                      clientInfo && clientInfo.suburbs && clientInfo.suburbs.map((suburbs, idx)=>{
                                        return(
                                         <h2 key={idx}>{suburbs.suburbs_name}</h2>
                                        )
                                      })
                                    }

                                  </div>
                                  {/* <div className="client__activity--info--items" style={{position: "relative"}}>
                                    <div className="fordt-picker">
                                    <h1 className="uppercasefonts">FOLLOW UP <img src="img/edit-icon.svg" /></h1>
                                    <h2><a> {clientInfo && moment( clientInfo.follow_up_date).format("MM/DD/YYYY")}</a></h2>
                                    <DatePicker
                                      customInput={<DatePickerCustomInputForEdit />}
                                      onChange={this.handleDateChange.bind(this, clientInfo)}
                                      showTimeSelect
                                      //selectDate={}
                                      peekNextMonth
                                      minDate={new Date()}
                                      maxDate={moment(new Date()).add(1, 'months')}
                                      className="form-control custom-select date__picker">
                                    </DatePicker>
                                    </div>
                                  </div> */}
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">Left Review</h1>
                                    <select
                                      className="client-review-select custom-select"
                                      name='review'
                                      value={reviewValue}
                                      onChange={this.handleChange.bind(this, clientInfo)}>
                                      <option value ="">Select an option</option>
                                      <option value ="Asked">Asked</option>
                                      <option value ="Yes">Yes</option>
                                      <option value ="No">No</option>
                                      <option value ="Declined">Declined</option>
                                    </select>
                                  </div>
                                  {reviewValue === 'Yes' &&
                                    <div className="client__activity--info--items newclassdate" style={{position: "relative"}}>
                                      <div className="fordt-picker">
                                      <h1 className="uppercasefonts">Review date <img src="img/edit-icon.svg"/></h1>
                                      <h2><a>{reviewDateValue}</a></h2>
                                      <DatePicker
                                        customInput={<DatePickerCustomInput />}
                                        onChange={this.handleReviewDateChange.bind(this, clientInfo)}
                                        showTimeSelect
                                        peekNextMonth
                                        // minDate={new Date()}
                                        // maxDate={moment(new Date()).add(1, 'months')}
                                        className="form-control custom-select date__picker">
                                      </DatePicker>
                                      </div>
                                    </div>
                                  }
                                  {
                                    // reviewValue === 'Yes' &&
                                    // <div className="client__activity--info--items">
                                    //     <h1>Where the review was posted?</h1>
                                    //     <Select
                                    //       multiple
                                    //       name = 'review_source'
                                    //       value={reviewSourceValue}
                                    //       onChange={this.handleChange.bind(this, clientInfo)}
                                    //       input={<Input id="select-multiple-checkbox" />}
                                    //       renderValue={selected => selected.length > 0  && selected.join(', ')}
                                    //       MenuProps={MenuProps}
                                    //       className="multiplesel-edit"
                                    //       >
                                    //       {reviewSourceNames.map(name => (
                                    //         <MenuItem key={name} value={name}>
                                    //           <Checkbox checked={reviewSourceValue.indexOf(name) > -1} />
                                    //           <ListItemText primary={name} />
                                    //         </MenuItem>
                                    //       ))}
                                    //     </Select>
                                    // </div>
                                  }

                                  {
                                    reviewValue === 'Yes' &&
                                    <div className="client__activity--info--items">
                                      <h1 className="uppercasefonts">Where the review was posted?</h1>
                                        <Select
                                          multi
                                          simpleValue
                                          placeholder="Add a review"
                                          name="review_source"
                                          options={reviewSourceNames}
                                          onChange={this.reviewHandleChange.bind(this)}
                                          value={reviewSourceValue}>
                                        </Select>
                                    </div>
                                  }

                                  <div className="client__activity--info--items">
                                    <div className="fordt-picker">
                                      <h1 className="uppercasefonts">App Installed?</h1>
                                        {
                                          ( clientInfo && clientInfo.is_app_installed === 1) ?
                                            <h2><a>YES</a> ({ clientInfo.app_install_date && clientInfo.app_install_date !== "0000-00-00 00:00:00"? moment(clientInfo.app_install_date).format('MM-DD-YYYY') : "N/A"})</h2>
                                           :
                                            <h2><a style={{"color":"red"}}>NO</a></h2>
                                        }
                                    </div>
                                  </div>
                                  <div className="client__activity--info--items">
                                    <div className="fordt-picker">
                                      <h1 className="uppercasefonts">Ad Tracking Codes</h1>
                                      { ad.length > 20 ? ad.substring(0, 19) : (ad === "" ? "N/A" : ad) }
                                      { ad.length > 20 ? (
                                          <span className="view-more" onClick={this.showFullText.bind(this, clientInfo)}>View more</span>
                                        ) : ''}
                                    </div>
                                    <div>
                                      <Modal open={this.state.openStr} onClose={this.onCloseStrategistModal.bind(this)} center>
                                        <div>
                                          <h2 className="modal-header margin-bottom-codes">{'Client - ' + this.state.clientName+`'s`} <span style={{display: "block"}}>ad tracking code(s)</span></h2>
                                          {this.state.ad_tracking_codes && this.state.ad_tracking_codes.map((code, index) => {
                                            return <div key={'codes-'+code}>{code}</div>;
                                          })}
                                        </div>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="client__activity--info--items">
                                    <h1 className="uppercasefonts">ACTIVITY HISTORY</h1>
                                    {
                                      clientInfo && clientInfo.all_activity_history && clientInfo.all_activity_history.length > 0 ? clientInfo.all_activity_history.map((h, indx)=>{
                                        histryLength.push('1')
                                        return(
                                          <div key={indx}>
                                            {
                                              (indx < 6)?
                                                 <div>
                                                   {
                                                    (h.status)?
                                                       <div>
                                                        {
                                                          (h.status !== "Null" && h.status !== "null" && h.status !== "NULL" && h.status !== "")?
                                                           <div>
                                                             {
                                                              (h.status === "Initial Welcome Sent") ?
                                                               <div>
                                                                  <p className="history__heading" key={indx}>Agent <b>{h.author_full_name}</b> selected the status icon <b>Initial Welcome Sent</b> for client <b>{clientInfo && clientInfo.client_full_name}</b></p>
                                                                  <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                               </div>
                                                               :
                                                               (h.status === "Inspection Scheduled")?
                                                                <div>
                                                                  <p className="history__heading" key={indx}>Agent <b>{h.author_full_name}</b> updated the question “You have an accepted offer and have scheduled inspection?” to <b>yes</b> for client <b>{clientInfo && clientInfo.client_full_name}</b></p>
                                                                  <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                                </div>
                                                               :
                                                               <div>
                                                                  <p className="history__heading" key={indx}>Agent <b>{h.author_full_name} </b> updated the status of the client <b>{clientInfo && clientInfo.client_full_name}</b> to <b>{h.status}</b></p>
                                                                  <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                               </div>
                                                             }
                                                            </div>
                                                          :""
                                                        }
                                                       </div>
                                                      :
                                                      (h.activity_date) ?
                                                       (h.old_event_date)?
                                                          <div>
                                                            {/*<p className="history__heading">Agent <b>{h.author_full_name}</b> has rescheduled a <b>{h.activity_type}</b> appointment with client <b>{clientInfo && clientInfo.client_full_name}</b> to <b>{moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> from <b>{moment(h.old_event_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> </p>
                                                            <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>*/}
                                                            <div>
                                                            <p className="history__heading"><span dangerouslySetInnerHTML={{ __html: h.activity}}></span> to <b>{moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> from <b>{moment(h.old_event_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> </p>
                                                              <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                            </div>
                                                          </div>
                                                          :
                                                          <div>
                                                            <p className="history__heading" key={indx}><span dangerouslySetInnerHTML={{ __html: h.activity}}></span> {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} </p>
                                                            {/* Commenting auther name <b>{h.author_full_name}</b> */}
                                                            <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                          </div>
                                                        :
                                                        (h.activity_type && h.activity_type =='strategy') ?
                                                          <div>
                                                            <p className="history__heading" key={indx}><div dangerouslySetInnerHTML={{ __html: h.activity}}></div></p>
                                                            <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                          </div>
                                                          :
                                                          <div>
                                                            <p className="history__heading" key={indx}><div dangerouslySetInnerHTML={{ __html: h.activity}}></div></p>
                                                            <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                                          </div>
                                                      }
                                                 </div>
                                              : ''
                                            }
                                          </div>
                                        )
                                      }):''
                                    }
                                    {/*
                                      clientInfo && clientInfo.activity_history && clientInfo.activity_history.length > 0 ? clientInfo.activity_history.map ((h, indx) => {
                                        histryLength.push('1')
                                      return(
                                        <div>
                                         {(indx <= 5) ?
                                          <h2 key={indx}>{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} - {h.status} <b>Sent by </b> {h.author_full_name}</h2>
                                          : ''
                                         }
                                        </div>
                                      )
                                      })
                                      : ''
                                    }

                                    {clientInfo && clientInfo.client_activity_history && clientInfo.client_activity_history.length > 0 ? clientInfo.client_activity_history.map ((h, indx) => {
                                      histryLength.push('1')
                                      if(indx <= 2){
                                        if(h.activity_date !== null){
                                          return(
                                            <h2 key={indx}>{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} - {h.activity + moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} {h.author_full_name}</h2>
                                          )
                                        }else{
                                          return(
                                            <h2 key={indx}>{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} - {h.activity} {h.author_full_name}</h2>
                                          )
                                        }
                                      }

                                    })
                                    : ''*/}
                                  </div>
                                  {histryLength && histryLength.length > 6 ?

                                     <button
                                    className="btn btn__green_viewmore viewmorebtns"
                                    type="button"
                                    onClick={this.activityHistoryBtn.bind(this)}
                                  >
                                  View More
                                  </button>

                                   : ""
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                           <div className="col-md-12">
                             <div className="tabs__container tabs__container__desktop deskview__btns">
                                <ul className="nav nav-tabs" role="tablist">
                                  <li><Link to={`/activity/${this.state.client_ID}?em=true`} className={this.props.location.pathname === `/activity/${this.state.client_ID}` ? 'active': ''}>Emails</Link></li>
                                  <li><Link to={`/activity/text-messages/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/text-messages/${this.state.client_ID}` ? 'active': ''}>Text Messages</Link></li>
                                  <li><Link to={`/activity/clients-notes/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/clients-notes/${this.state.client_ID}` ? 'active': ''}>Internal Notes</Link></li>

                                  <li><Link to={`/activity/clients-chat/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/clients-chat/${this.state.client_ID}` ? 'active': ''}>Chat with Agents</Link></li>

                                  <li><Link to={`/activity/background-profile/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/background-profile/${this.state.client_ID}` ? 'active': ''}>Background Profile</Link></li>
                                  <li><Link to={`/activity/clients-strategy/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/clients-strategy/${this.state.client_ID}` ? 'active': ''}>Strategy</Link></li>
                                  <li><Link to={`/activity/clients-deal/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/clients-deal/${this.state.client_ID}` ? 'active': ''}>Deal</Link></li>
                                  {/*<li><Link to={`/activity/recording-links/${this.state.client_ID}`} className={this.props.location.pathname === `/activity/recording-links/${this.state.client_ID}` ? 'active': ''}>Recording Links</Link></li>*/}
                                </ul>
                              </div>

                              {/* mobile dashboard dropdown open */}
                              <div className="mobview__dropdown--select">
                                <select
                                  onChange={this.mainDropDownMenu.bind(this)}
                                  value={this.state.dropDownMenuVal}
                                  className="selectdropdown"
                                >
                                  <option value="emails">Emails</option>
                                  <option value="text_msg">Text Messages</option>
                                  <option value="internal_notes">Internal Notes</option>
                                  <option value="chat_with_agents">Chat with Agents</option>
                                  <option value="background_profile">Background Profile</option>
                                  <option value="strategy_section">Strategy</option>
                                  <option value="deal_section">Deal</option>
                                </select>
                              </div>
                              {/* mobile dashboard dropdown close */}
                            </div>
                        </div>

                        {this.props.location.pathname === `/activity/${this.state.client_ID}` && <ClientsEmails key="ClientsEmails"  {...this.props}/> }
                        {this.props.location.pathname === `/activity/text-messages/${this.state.client_ID}` && <ClientsTextMessages key="ClientsTextMessages" {...this.props} /> }
                        {this.props.location.pathname === `/activity/clients-notes/${this.state.client_ID}` && <ClientsNotes key="ClientsNotes" {...this.props}/> }
                        {this.props.location.pathname === `/activity/clients-chat/${this.state.client_ID}` && <ClientsChatAgent key="ClientsChatAgent" {...this.props}/> }
                        {this.props.location.pathname === `/activity/background-profile/${this.state.client_ID}` && <ClientsBackgroundProfile key="ClientsBackgroundProfile" {...this.props}/> }
                        {this.props.location.pathname === `/activity/clients-strategy/${this.state.client_ID}` && <ClientsStrategy key="ClientsStrategy" {...this.props}/> }
                        {this.props.location.pathname === `/activity/clients-deal/${this.state.client_ID}` && <ClientsDeal key="ClientsDeal" {...this.props}/> }
                        {/*this.props.location.pathname === `/activity/recording-links/${this.state.client_ID}` && <ClientsRecordingLinks {...this.props}/> */}

                      </div>
                    </div>
                  </div>
              </div>
            </div>
          <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
            <div className="schedule__sidebar--header">
               + Appointment
              <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                  <img src="img/close.svg"/>
              </div>
            </div>
          <div className="schedule__sidebar--body transparent__scroll">
                    <div className="schedule__sidebar--form--wrapper">

                      <div className="form-group">
                        <label className="labelinputs__global">Client Name</label>
                        <select
                          className="selectinputs__global"
                          name="selectClient" 
                          value = {clientId}>
                           {clientList}
                        </select>
                      </div>

                      {
                        (_user.role == 'superadmin' || _user.role == 'admin')?
                        <div>
                        <div className="form-group">
                          <label className="labelinputs__global">Strategist Name</label>
                          <select
                            className="selectinputs__global"
                            name="selectStrategist"
                            value = {this.state.selectStrategist}
                            onChange={this.handleChangeApt.bind(this)}>
                              {strategistList}
                          </select>
                        </div>
                        </div>
                        :
                        <div>
                        <div className="form-group">
                          <label className="labelinputs__global">Strategist Name</label>
                          <select
                            className="selectinputs__global"
                            name="selectStrategist" value = {this.state.selectedUserId}  onChange={this.handleChangeApt.bind(this)}>
                            <option key={0} value={_user.id}>{_user.first_name+' '+_user.last_name}</option>
                          </select>
                        </div>
                        </div>
                      }

                      <div className="form-group">
                        <label className="labelinputs__global">Appointment Type</label>
                        <select className="selectinputs__global" name="selectAppointment" value = {this.state.selectedUserId}  onChange={this.handleChangeApt.bind(this)}>
                          <option>Select the type of Appointment</option>
                            {appoinment}
                        </select>
                      </div>
                      {this.state.err.selectAppointment ?
                      <span className="error_msg">
                      {this.state.err.selectAppointment}
                      </span> : '' }

                      {
                        availableDates.length > 0 ?
                        <div>
                          <div className="form-group">
                             <DatePicker
                              selected={this.state.startDate}
                              customInput={<DatePickerCustomInput />}
                              onChange={this.handelDateChange.bind(this, _user.time_zone, _user.id)}
                              includeDates={availableDates.length > 0 ? availableDates : ''}
                              showTimeSelect
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              className="selectdate__global"
                              value={this.state.startDate}
                              placeholderText="Pick a date">
                            </DatePicker>
                          </div>
                          <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {strategistAvailabilityPhase === "loading" ?
                                <span  className="time_loading">
                                  Loading ...
                                </span>
                                :
                                /*<div name="selectStartTime"
                                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b">
                                  <Slider {...settings} className="client-timeslot-slider">
                                    {availableDateSlots}
                                  </Slider>
                                </div>*/
                                <select name='selectStartTime' className="selectinputs__global" value = {this.state.selectStartTime}  onChange={this.handleChangeApt.bind(this)}>
                                  <option>Time</option>
                                   {availableDateSlots}
                                </select>
                              }
                            </div>
                            {this.state.err.startTimeErr ?
                            <span className="str_error_msg">
                            {this.state.err.startTimeErr}
                            </span> : '' }
                          </div>


                           {<div className="col-md-6">
                            <p>
                            You only need to put in your start time because we have already defined the length of the appointment in Acuity. Once you create this it will be created in Acuity as well.
                            </p>
                          </div>}
                        </div>
                      </div>
                        :
                        <p>Date Not Found</p>
                      }

                      <div className="form-group rem__marg--btm">
                        <label className="labelinputs__global">Appointment Description</label>
                        <textarea name="description" className="textareainputs__global"
                         onChange={this.handleChangeApt.bind(this)}
                         placeholder="Enter description here..."></textarea>
                      </div>


                    </div>
          </div>
              <div className="schedule__sidebar--footer">
                <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this, _user.time_zone, _user.id, )}>Schedule</button>
              </div>
          </div>
          {/*This is for Open and Close Activity History*/}
          <div className={this.state.openActivityHistory ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
            <div className="schedule__sidebar--header">
               Activity History
              <div className="close-btn" onClick={this.closeActivityHistory.bind(this)}>
                  <img src="img/close.svg"/>
              </div>
            </div>

            <div className="schedule__sidebar--body activityHistorySidebar transparent__scroll">
               <div className="schedule__sidebar--form--wrapper">
                  <div className="client__activity--info--items">
                    {clientInfo && clientInfo.all_activity_history && clientInfo.all_activity_history.length > 0 ? clientInfo.all_activity_history.map ((h, indx) => {
                      return(
                        <div key={indx}>
                         {
                          (h.status)?
                            <div>
                              {
                                (h.status !== "Null" && h.status !== "null" && h.status !== "NULL" && h.status !== "" )?
                                 <div>
                                   {
                                    (h.status === "Initial Welcome Sent") ?
                                     <div>
                                        <p className="history__heading" key={indx}>Agent <b>{h.author_full_name}</b> selected the status icon <b>Initial Welcome Sent</b> for client <b>{clientInfo && clientInfo.client_full_name}</b></p>
                                        <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                     </div>
                                     :
                                     (h.status === "Inspection Scheduled")?
                                      <div>
                                        <p className="history__heading" key={indx}>Agent <b>{h.author_full_name}</b> updated the question “You have an accepted offer and have scheduled inspection?” to <b>yes</b> for client <b>{clientInfo && clientInfo.client_full_name}</b></p>
                                        <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                      </div>
                                     :
                                     <div>
                                        <p className="history__heading" key={indx}>Agent <b>{h.author_full_name} </b> updated the status of the client <b>{clientInfo && clientInfo.client_full_name}</b> to <b>{h.status}</b></p>
                                        <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                     </div>
                                   }
                                  </div>
                                :""
                              }
                             </div>
                            :
                            (h.activity_date) ?
                             (h.old_event_date)?
                                <div>
                                <p className="history__heading"><span dangerouslySetInnerHTML={{ __html: h.activity}}></span> to <b>{moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> from <b>{moment(h.old_event_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</b> </p>
                                  <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                </div>
                              :
                              <div>
                                <p className="history__heading" key={indx}><span dangerouslySetInnerHTML={{ __html: h.activity}}></span> {moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY - hh:mm a z")} </p>
                                {/* Commenting auther name <b>{h.author_full_name}</b> */}
                                <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                              </div>
                              :
                              (h.activity_type && h.activity_type =='strategy') ?
                               <div>
                                 <p className="history__heading" key={indx}><div dangerouslySetInnerHTML={{ __html: h.activity}}></div></p>
                                 <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                               </div>
                               :
                               <div>
                                 <p className="history__heading" key={indx}><div dangerouslySetInnerHTML={{ __html: h.activity}}></div></p>
                                 <div className="history__date">{moment(h.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                               </div>
                         }
                       </div>
                      )
                    })
                    : ''}
                  </div>
              </div>
            </div>
          </div>
        {/*End Of Activity History*/}

          </div>
        }

      {/*This is client chat open side bar*/}
      <div className={this.state.openChat ? "right__chat--section": "chat__box__open"}>
        <div className={this.state.openChat ? "activity__page schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section" }>
            <div className="schedule__sidebar--header poppinsfonts">
               Chat With Client
              <div className="close-btn" onClick={this.closeChatBar.bind(this)}>
                  <img src="img/close.svg"/>
              </div>
            </div>
            <div className="chat__listing chat__listing--activity">
              <ul className="chat_ul_scroll chat_ul_scrollnew">
              {
                allChatData && allChatData.map((client, idx) => {
                  // var finalTime = new Date(client.created_date).toLocaleString("en-US", {timeZone: _user.time_zone});
                  // finalTime = new Date(finalTime)
                  return(
                    <div key={idx}>

                    {
                      (_user.role == 'admin' || _user.role == 'superadmin'|| _user.role == 'strategist') ? (

                          (this.state.current_userId == client.receiver_id) ? (
                            <li>
                              {/*<div className="for__date--format">{(client.showMsg !== true) && moment(finalTime).format("MMM Do YYYY h:mm a") }</div>*/}
                              <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                              <div className="details">
                                <h6 className="poppinsfonts">{client.sender_full_name}</h6>
                                <p className="poppinsfonts break__text">{client.message}</p>
                                {
                                  (client.showMsg !== true) &&
                                  <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                                }
                              </div>
                            </li>
                          ):
                          <div>
                            {
                              (this.state.current_userId == client.sender_id)?(
                              <li className="you">
                                {/*<div className="for__date--format">{moment(finalTime).format("MMM Do YYYY h:mm a")}</div>*/}
                                <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                                <div className="details">
                                  <h6 className="poppinsfonts">You</h6>
                                  <p className="poppinsfonts break__text" spellCheck="true">{client.message}</p>
                                  <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                                </div>
                              </li>
                              ):<li>
                                {/*<div className="for__date--format">{moment(finalTime).format("MMM Do YYYY h:mm a")}</div>*/}
                                <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                                <div className="details">
                                  <h6 className="poppinsfonts break__text">{client.sender_full_name}</h6>
                                  <p className="poppinsfonts" spellCheck="true">{client.message}</p>
                                  {
                                    (client.showMsg !== true) &&
                                    <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                                  }
                                </div>
                              </li>
                            }
                          </div>

                      ): (
                        (this.state.current_userId == client.receiver_id) ? (
                          <li>
                            {/*<div className="for__date--format">{(client.showMsg !== true) &&  moment(finalTime).format("MMM Do YYYY h:mm a") }</div>*/}
                            <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                            <div className="details">
                              <h6  className="poppinsfonts">{client.sender_full_name}</h6>
                              <p  className="poppinsfonts break__text">{client.message}</p>
                              {
                                (client.showMsg !== true) &&
                                <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                              }
                            </div>
                          </li>
                        ):
                        <div>
                          {
                            (this.state.current_userId == client.sender_id)?(
                            <li className="you">
                              {/*<div className="for__date--format">{moment(finalTime).format("MMM Do YYYY h:mm a")}</div>*/}
                              <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                              <div className="details">
                                <h6  className="poppinsfonts">You</h6>
                                <p  className="poppinsfonts break__text" spellCheck="true">{client.message}</p>
                                <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                              </div>
                            </li>
                            ):<li>
                              {/*<div className="for__date--format">{moment(finalTime).format("MMM Do YYYY h:mm a")}</div>*/}
                              <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                              <div className="details">
                                <h6  className="poppinsfonts">You</h6>
                                <p  className="poppinsfonts break__text" spellCheck="true">{client.message}</p>
                                <h5 className="agentchatfont__med poppinsfonts">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                              </div>
                            </li>
                          }
                        </div>
                      )
                    }

                    </div>
                  )
                })
              }
              </ul>

              <div className="send__wrapper--clientstrat sendwrapper__mob">
                <textarea data-autoresize id="my_textareaBox" className="poppinsfonts" type="text" name="chat" spellCheck="true" value={this.state.chat} onChange={this.chatHandle.bind(this)} placeholder="Chat with your client… " />
                <button onClick={this.sendBtn.bind(this)} className="poppinsfonts">Send</button>
            </div>
          </div>
        </div>
      </div>
      {/*****************End***************/}

    {/*This is client questions open side bar*/}
      <div className={this.state.openQuestion ? "right__chat--section": "chat__box__open"}>
        <div className={this.state.openQuestion ? " activity__page schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section" }>
            <div className="schedule__sidebar--header fullheaderwithclose">
              {this.state.showAIResult ? (
                <span>AI Towns Selection</span>
              ) : (
                <span>
                  <button className="btn btn__green resultsbtns" onClick={this.showAIResults.bind(this)}>Results</button> Client's AI Questions
                </span>
              )}
              <div className="close-btn" onClick={this.closeQuestionBar.bind(this)}>
                  {this.state.showAIResult ? "Back" : (<img src="img/close.svg"/>)}
              </div>
            </div>
            <div className={this.state.showAIResult ? "question__list questionlistedit__ai" : "question__list questionlistedit"}>
              <div className={this.state.showAIResult? "fulllistwidth__ai" : "fulllistwidth"}>

                  {
                    questionData && questionData.length > 0 ? (
                      <div>
                        <div className={!this.state.showAIResult? "q__a--wrapper showAI" : "q__a--wrapper hideAI"}>

                          {/*<div className="questions__txt">Q. Can you let us know the type of advice you are looking for so we know which division to best send you to?</div>
                          <div className="answer__txt">A.</div>*/}

                          <div className="questions__txt">Q. Tell us a bit about yourself...tell us as little or as much as you like!</div>
                          <div className="answer__txt">A. {clientInfo && clientInfo.client_info ? clientInfo.client_info : ""}</div>

                          <div className="questions__txt">Q. {questions && questions[46].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[46] && questionData[46].where_do_you_live }</div>
                          
                          <div className="questions__txt">Q. {questions && questions[0].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[0] && questionData[0].key === -2 ? 'Expecting' : questionData[0].key}
                            {questionData && questionData[0] && questionData[0].child && questionData[0].key > 0 && questionData[0].child.map((cld, index) => {
                              return (
                                <div style={{margin: "5px 5px 5px 18px"}} key={"__children__"+index}>
                                  <span >Name: <b>{cld.name}</b></span>
                                  <span style={{margin: "0px 7px 0px 7px"}}>Age: <b>{cld.age}</b></span>
                                  <span>School: <b>{cld.school}</b></span>
                                </div>
                              )}
                            )}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[1].q}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[0]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].persional} {questionData[1] && questionData[1].personalOther && questionData[1].personalOther !== "" ? "("+questionData[1].personalOther+")" : ""}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[1]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].companyName}</div>

                          <div className="questions__txt--inner">- {questions && questions[23].q}</div>
                          <div className="answer__txt--inner">A. {questionData && questionData[23] && questionData[23].transportation}</div>
                          <div className="questions__txt--inner">- {questions && questions[24].q}</div>
                          <div className="answer__txt--inner">A. {questionData && questionData[24] && questionData[24].commute.commute_30_to_less == 1 ? "Up to 30 minutes" : (questionData[24].commute.commute_30_to_45 == 1 ? "Up to 45 minutes" : (questionData[24].commute.commute_45_to_60 == 1 ? "Up to 60 minutes" : (questionData[24].commute.commute_60_to_high == 1 ? "1 hr +" : ""))) }
                          </div>
                          <div className="questions__txt--inner">- {questions && questions[42].q}</div>
                          <div className="answer__txt--inner" dangerouslySetInnerHTML={{__html: questionData[42] && questionData[42].commute_to_simple}}></div>
                          <div className="questions__txt--inner">- {questions && questions[43].q}</div>
                          <div className="answer__txt--inner">A. {questionData && questionData[43] && questionData[43].work_home && questionData[43].work_home.yes == 1 ? "Yes" : (questionData[43].work_home && questionData[43].work_home.no == 1 ? "No" : (questionData[43].work_home && questionData[43].work_home.both == 1 ? "Flexible...at least one day a week" : ""))}</div>
                          {/*<div className={!questionData[25].isChildren ? "questions__txt--inner hideAI" : "questions__txt--inner"}>- {questions && questions[25].q}</div>
                          <div className={!questionData[25].isChildren ? "answer__txt--inner hideAI" : "answer__txt--inner"}>A. {questionData && questionData[25] && questionData[25].plans}
                          </div>*/}

                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[2]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].undergrad}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[3]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].goTogradSchool == -1 ? "None" : (questionData[1] && questionData[1].goTogradSchool == 1 ? "Yes" : "No") }</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[4]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].enterGradSchool}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[5]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].cityGrowUp}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options && questions[1].options[6]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].likeIt == -1 ? 'None' : (questionData[1] && questionData[1].likeIt == 1 ? "Yes" : "No")}</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options[7]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].q2FamilyLive }</div>
                          <div className="questions__txt--inner">- {questions[1].options && questions[1].options[8]}</div>
                          <div className="answer__txt--inner">A. {questionData[1] && questionData[1].q2nearby == 1  ? "Yes" : (questionData[1].q2nearby == 0  ? "No" : "None") }</div>

                          <div className="questions__txt">Q. {questions && questions[2].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[2] && questionData[2].status}
                          </div>
                          { questionData && questionData[2] && ( questionData[2].status == "married" || questionData[2].status == "significant" || questionData[2].status == "engaged" ) ? (

                            <div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[0]}</div>
                              <div className="answer__txt--inner">
                                A. {questionData[2] && questionData[2].details && questionData[2].details.partnerName }
                              </div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[1]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.mobileNo }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[2]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.email3 }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[3]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.professional } {questionData[2] && questionData[2].details && questionData[2].details.professionalOther && questionData[2].details.professionalOther !== "" ? "("+questionData[2].details.professionalOther+")" : ""}</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[4]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3Company }</div>

                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[12]}</div>
                              <div className="answer__txt--inner">A. {questionData && questionData[2] && questionData[2].transportation}</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[13]}</div>
                              <div className="answer__txt--inner">A. {questionData && questionData[2] && questionData[2].details && questionData[2].details.commute && questionData[2].details.commute.commute_30_to_less == 1 ? "Up to 30 minutes" : (questionData && questionData[2] && questionData[2].details && questionData[2].details.commute && questionData[2].details.commute.commute_30_to_45 == 1 ? "Up to 45 minutes" : (questionData && questionData[2] && questionData[2].details && questionData[2].details.commute && questionData[2].details.commute.commute_45_to_60 == 1 ? "Up to 60 minutes" : (questionData && questionData[2] && questionData[2].details && questionData[2].details.commute && questionData[2].details.commute.commute_60_to_high == 1 ? "1 hr +" : ""))) }
                              </div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[14]}</div>
                              <div className="answer__txt--inner" dangerouslySetInnerHTML={{__html: questionData[2] && questionData[2].commute_to_simple}}></div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[15]}</div>
                              <div className="answer__txt--inner">A. {questionData && questionData[2] && questionData[2].details && questionData[2].details.work_home && questionData[2].details.work_home.yes == 1 ? "Yes" : (questionData[2] && questionData[2].details.work_home && questionData[2].details.work_home.no == 1 ? "No" : (questionData[2] && questionData[2].details.work_home && questionData[2].details.work_home.both == 1 ? "Flexible...at least one day a week" : ""))}</div>
                              {/*<div className={!questionData[25].isChildren ? "questions__txt--inner hideAI" : "questions__txt--inner"}>- {questions[2].options && questions[2].options[16]}</div>
                              <div className={!questionData[25].isChildren ? "answer__txt--inner hideAI" : "answer__txt--inner"}>A. {questionData && questionData[2] && questionData[2].plans}
                              </div>*/}

                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[5]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3UnderGrad }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[6]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3GoToGradSchool == 1 ? "Yes" : (questionData[2] && questionData[2].details.q3GoToGradSchool == 0 ? "No" : "None" )}</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[7]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3GradSchool }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[8]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3GrowUp }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[9]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3LikeIt == 1  ? "Yes" : (questionData[2].details.q3LikeIt == 0  ? "No" : "None") }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[10]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3FamilyLive }</div>
                              <div className="questions__txt--inner">- {questions[2].options && questions[2].options[11]}</div>
                              <div className="answer__txt--inner">A. {questionData[2] && questionData[2].details && questionData[2].details.q3nearby == 1  ? "Yes" : (questionData[2].details.q3nearby == 0  ? "No" : "None") }</div>
                            </div>
                           )
                            : ""
                          }

                          <div className="questions__txt">Q. {questions && questions[3].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[3] && questionData[3].town_size_small == 'm' ? "Medium" : (questionData[3].town_size_small == 's' ? 'Small' : (questionData[3].town_size_small == 'l' ? "Large" : ""))}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[4].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[4] && questionData[4].urban_suburban == 'urban' ? "Urban" : (questionData[4].urban_suburban == 'suburban' ? 'Suburban' : (questionData[4].urban_suburban == 'urban/suburban' ? 'Country' : ""))}
                          </div>

                          <div className={!questionData[5].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[5].q}</div>
                          <div className={!questionData[5].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[5] && questionData[5].sahm_wohm_mix == 'SAHM' ? "Stay at home" : (questionData[5].sahm_wohm_mix == 'WOHM' ? 'Work out of home' : (questionData[5].sahm_wohm_mix == 'Mix' ? 'Mix' : ""))}
                          </div>


                          <div className="questions__txt">Q. {questions && questions[6].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[6] && questionData[6].type_a == 2 ? "None" : (questionData[6].type_a == 1 ? 'Yes' : (questionData[6].type_a == 0 ? 'No' : ''))}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[7].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[7] && questionData[7].fancyLaidBack.laid_back == 1 ? "Laid back" : (questionData[7].fancyLaidBack.fancy == 1 ? 'Fancy' :(questionData[7].fancyLaidBack.both == 1 ? "Doesn't matter to me" : ""))}
                          </div>


                          {/*<div className="questions__txt">Q. {questions && questions[8].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[8] && questionData[8].heels_or_flats == "heels" ? "Heels" : (questionData[8].heels_or_flats == "flats" ? 'Flats' : (questionData[8].heels_or_flats == 'both' ? "Doesn’t matter to me" : ""))}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[9].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[9] && questionData[9].make_up_daily == 1 ? "Of course" : (questionData[9].make_up_daily == 0 ? 'Absolutely not' :  (questionData[9].make_up_daily == 2 ? 'Why would I care ?' : ""))}
                          </div>*/}

                          {/*<div className="questions__txt">Q. {questions && questions[10].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[10] && questionData[10].diversity == 1 ? "Very" : (questionData[10].diversity == 0 ? 'So so' : (questionData[10].diversity == 2 ? 'Not at all' : ""))}
                          </div>*/}

                          <div className="questions__txt">Q. {questions && questions[11].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[11] && questionData[11].occupation}
                          </div>

                          {/*<div className="questions__txt">Q. {questions && questions[12].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[12] && questionData[12].ideallyDrive == "Wouldnt care what they drive" ? "Wouldn’t care what they drive" : questionData[12].ideallyDrive}
                          </div>*/}

                          <div className="questions__txt">Q. {questions && questions[13].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[13] && questionData[13].typeOfTown}</div>

                          <div className="questions__txt">Q. {questions && questions[41].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[41] && questionData[41].educated_people && questionData[41].educated_people.yes == 1 ? "Yes" : (questionData[41] && questionData[41].educated_people && questionData[41].educated_people.no == 1 ? "No" : (questionData[41] && questionData[41].educated_people && questionData[41].educated_people.both == 1 ? "Doesn't matter" : ""))}</div>

                          {/*<div className="questions__txt">Q. {questions && questions[14].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[14] && questionData[14].ivy_league_parents == 1 ? "Yes" : (questionData && questionData[14] && questionData[14].ivy_league_parents == 0 ? "No" : "")}
                          </div>*/}

                          <div className="questions__txt">Q. {questions && questions[15].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[15] && questionData[15].taxes}
                          </div>

                          <div className={!questionData[16].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[16].q}</div>
                          <div className={!questionData[16].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[16] && questionData[16].schools}
                          </div>

                          {/*<div className="questions__txt">Q. {questions && questions[17].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[17] && questionData[17].pressure_cooker == 1 ? "Yes" : (questionData && questionData[17] && questionData[17].pressure_cooker == 0 ? "No" : "")}
                          </div>*/}

                          <div className={!questionData[18].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[18].q}</div>
                          <div className={!questionData[18].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[18] && questionData[18].language_immersion == 1 ? "Yes" : (questionData && questionData[18] && questionData[18].language_immersion == 0 ? "No" : "")}
                          </div>

                          <div className={!questionData[19].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[19].q}</div>
                          <div className={!questionData[19].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[19] && questionData[19].strong_special_needs_program == 1 ? "Yes" : (questionData && questionData[19] && questionData[19].strong_special_needs_program == 0 ? "No" : "")}
                          </div>

                          <div className={!questionData[20].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[20].q}</div>
                          <div className={!questionData[20].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[20] && questionData[20].kindergarten}
                          </div>

                          <div className={!questionData[21].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[21].q}</div>
                          <div className={!questionData[21].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[21] && questionData[21].busing}
                          </div>

                          <div className={!questionData[22].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[22].q}</div>
                          <div className={!questionData[22].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[22] && questionData[22].universal_pre_k}
                          </div>

                          {/*<div className="questions__txt">Q. {questions && questions[23].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[23] && questionData[23].transportation}</div>

                          <div className="questions__txt">Q. {questions && questions[24].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[24] && questionData[24].commute.commute_30_to_less == 1 ? "Up to 30 minutes" : (questionData[24].commute.commute_30_to_45 == 1 ? "Up to 45 minutes" : (questionData[24].commute.commute_45_to_60 == 1 ? "Up to 60 mintues" : (questionData[24].commute.commute_60_to_high == 1 ? "1 hr +" : ""))) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[42].q}</div>
                          <div className="answer__txt" dangerouslySetInnerHTML={{__html: questionData[42] && questionData[42].commute_to_simple}}></div>

                          <div className="questions__txt">Q. {questions && questions[43].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[43] && questionData[43].work_home && questionData[43].work_home.yes == 1 ? "Yes" : (questionData[43].work_home && questionData[43].work_home.no == 1 ? "No" : (questionData[43].work_home && questionData[43].work_home.both == 1 ? "Flexible...at least one day a week" : ""))}</div>
                          */}
                          <div className={!questionData[25].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[25].q}</div>
                          <div className={!questionData[25].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[25] && questionData[25].plans}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[26].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[26] && questionData[26].lands}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[27].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[27] && questionData[27].homeType}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[28].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[28] && questionData[28].backyard_pool.yes == 1 ? "Yes" : (questionData[28].backyard_pool.no == 1 ? "No" : (questionData[28].backyard_pool.both == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[29].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[29] && questionData[29].folks.progressive == 1 ? "Progressive" : (questionData[29].folks.conservative == 1 ? "Conservative" : (questionData[29].folks.both == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[30].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[30] && questionData[30].beach.yes == 1 ? "Yes" : (questionData[30].beach.no == 1 ? "No" : (questionData[30].beach.both == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[31].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[31] && questionData[31].walkable.yes == 1 ? "Yes" : (questionData[31].walkable.no == 1 ? "No" : (questionData[31].walkable.both == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[32].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[32] && questionData[32].organic_food_options.yes == 1 ? "Yes" : (questionData[32].organic_food_options.no == 1 ? "No" : (questionData[32].organic_food_options.both == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[33].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[33] && questionData[33].hiking_trails == 1 ? "Yes" :(questionData && questionData[33] && questionData[33].hiking_trails == 0 ? "No" : "") }
                          </div>

                          {/*<div className="questions__txt">Q. {questions && questions[34].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[34] && questionData[34].name_brand_clothing.yes == 1 ? "Part of life, it is what it is" : (questionData[34].name_brand_clothing.no == 1 ? "Nightmare, I would never live in a town where that was happening" : (questionData[34].name_brand_clothing.both == 1 ? "Doesn’t matter to me" : "")) }
                          </div>*/}

                          <div className={!questionData[35].isChildren ? "questions__txt hideAI" : "questions__txt"}>Q. {questions && questions[35].q}</div>
                          <div className={!questionData[35].isChildren ? "answer__txt hideAI" : "answer__txt"}>A. {questionData && questionData[35] && questionData[35].activities}
                          </div>

                          <div className="questions__txt">Q. {questions && questions[36].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[36] && questionData[36].weekend_activities.church_activities == 1 ? "Church" : (questionData[36].weekend_activities.temple_activities == 1 ? "Temple" : (questionData[36].weekend_activities.bar_bat_mitzvahs == 1 ? "Other religious institution or activity" : (questionData[36].weekend_activities.none == 1 ? "Not at all" : ""))) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[37].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[37] && questionData[37].great_restaurants.yes == 1 ? "Yes" : (questionData[37].great_restaurants.no == 1 ? "No" : (questionData[37].great_restaurants.none == 1 ? "Doesn't matter" : "")) }
                          </div>

                          <div className="questions__txt">Q. {questions && questions[38].q}</div>
                        <div className="answer__txt">A. {questionData && questionData[38] && questionData[38].stores.brand_name_shops == 1 ? "Name brand" : (questionData[38].stores.mom_and_pop_shops == 1 ? "Mom and pop" : (questionData[38].stores.none == 1 ? "Doesn't matter" : (questionData[38].stores.both == 1 ? "Both" : ""))) }
                          </div>

                          {/*<div className="questions__txt">Q. {questions && questions[39].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[39] && questionData[39].cars.two_cars == 1 ? "2" : (questionData[39].cars.one_cars == 1 ? "1" : (questionData[39].cars.no_cars == 1 ? "0" : (questionData[39].cars.both == 1 ? "3" : "")))}
                          </div>*/}

                          <div className="questions__txt">Q. {questions && questions[44].q}</div>
                         <div className="answer__txt" dangerouslySetInnerHTML={{ __html: questionData && questionData[44] && questionData[44].places_want_simple ? questionData[44].places_want_simple : 'A. '}}>
                          </div>
                          <div style={{margin: "0px 0px 10px 38px"}}>
                            {
                              questionData && questionData[44] && questionData[44].optionPWA && questionData[44].optionPWA.length > 0 ? (
                                questionData[44].optionPWA.map((option, idx) => {
                                  let places = option.a
                                  return(
                                    <ul key={idx}>
                                      <li className="anwser__multiple_text">{option.t}</li>
                                      {
                                        places && places.length > 0 ? (
                                          places.map((place, pidx) => {
                                            return(
                                              <p key={pidx} className="anwser__multiple_place">-{place}</p>
                                            )

                                          })
                                        ) : ''
                                      }

                                    </ul>
                                  )
                                })
                              ) : ''
                            }
                          </div>
                          <div className="questions__txt">Q. {questions && questions[45].q}</div>
                          <div className="answer__txt" dangerouslySetInnerHTML={{ __html: questionData && questionData[45] && questionData[45].places_donot_simple ? questionData[45].places_donot_simple : 'A. '}}></div>
                          <div style={{margin: "0px 0px 10px 38px"}}>
                            {
                              questionData && questionData[45] && questionData[45].optionArr && questionData[45].optionArr.length > 0 ? (
                                questionData[45].optionArr.map((option, idx) => {
                                  let places = option.a
                                  return(
                                    <ul key={idx}>
                                      <li className="anwser__multiple_text">{option.t}</li>
                                      {
                                        places && places.length > 0 ? (
                                          places.map((place, pidx) => {
                                            return(
                                              <p key={pidx} className="anwser__multiple_place">-{place}</p>
                                            )

                                          })
                                        ) : ''
                                      }

                                    </ul>
                                  )
                                })
                              ) : ''
                            }
                          </div>
                          <div className="questions__txt">Q. {questions && questions[40].q}</div>
                          <div className="answer__txt">A. {questionData && questionData[40] && questionData[40].prices !=='' ? priceRange : ''}</div>

                          <div className="questions__txt">Q. What are your top 3 priorities in your search?</div>
                           <div className="answer__txt" dangerouslySetInnerHTML={{ __html: (questionData && questionData[47] && questionData[47].threePriorityInSearch) ? questionData[47].threePriorityInSearch : 'A .'}}></div>

                          <div className="questions__txt">Q. Is there anything else you would like to share with us or that would be helpful in selecting the best towns for you?</div>
                          <div className="answer__txt" dangerouslySetInnerHTML={{ __html: (questionData && questionData[48] && questionData[48].selectBestTown) ? questionData[48].selectBestTown : 'A .'}}></div>

                        </div>
                      </div>
                    ) : ('')
                  }
                  {questionData && questionData.length === 0 && !this.state.showAIResult ? (<div className="empty__ai--msg empty__town--msg">This client has not filled out the AI Questionnaire yet. Once they do you will see their answers here.</div>) : ('')}
                  <div className={this.state.showAIResult? "form-group text-group showAI" : "form-group text-group hideAI"}>
                    <div className={this.state.unApprovedAITowns && this.state.unApprovedAITowns.length > 0 ? "strategy--form__card strategyformcard" : ""}>
                      {this.state.unApprovedAITowns && this.state.unApprovedAITowns.map((town, index) => {
                        return (
                          <ul key={"town__"+index} onClick={()=> this.selectApprovedTowns(index)} className="locations__list">
                            <li className={town.status === 2 ? '' : 'active'}>
                              <div className="select__agent--name">
                                <span
                                  className="checkbox__icon">
                                  <img src={town.status === 2 ? 'img/checkblank-icon.svg' : 'img/checkmark-icon.svg'}/>
                                </span>
                                 {town.town_name}
                              </div>
                            </li>
                          </ul>
                        )}
                      )}
                    </div>
                    {!this.state.processAITowns && this.state.unApprovedAITowns && this.state.unApprovedAITowns.length > 0 && (<button className="btn btn__green strategy-right font500 fullbtnswidth" onClick={this.saveApprovedTowns.bind(this)}>Save</button>)}
                    {!this.state.loadingAITowns && this.state.unApprovedAITowns && this.state.unApprovedAITowns.length === 0 && (<div className="empty__ai--msg empty__town--msg">No town(s) are available for this client.</div>)}
                    {this.state.loadingAITowns && (<div className="empty__ai--msg empty__town--msg">Results are loading. Please wait...</div>)}
                    {this.state.processAITowns && (<span className="savemsg__">Town(s) are saving, please wait...</span>)}
                  </div>
              </div>
            </div>
        </div>
      </div>

    {/***************************Town Ranking****************************/}
    <div className={this.state.townRankingOpen ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            <div>Towns Ranking</div>
            <div className="close-btn" onClick={this.closeTownRanking.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>
          {
            (this.state.townDataArray && this.state.townDataArray.length > 0 && this.state.is_rated_town === true)?
              <div className="rate__town--listingnew">
                <ul>
                  {
                    this.state.townDataArray.map((town, townIdx)=>{
                      return(
                        <li key={townIdx}>
                          <span className="serials">{townIdx+1}</span>
                          <span className="names">{town.town_name}</span>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            :
            <div className="empty__town--msg">
              This client has not ranked any towns yet. Once they rank then you will see them here.
            </div>
          }
        </div>
    {/***************************End Town Ranking****************************/}

    {/***************************Agent Ranking****************************/}
    <div className={this.state.agentRankingOpen ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            <div>Agents Ranking</div>
            <div className="close-btn" onClick={this.closeAgentRanking.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>
          {
            (this.state.agentDataArray && this.state.agentDataArray.length > 0 && this.state.is_rated_agent === true)?
            <div className="rate__town--listingnew">
              <ul>
                {
                  this.state.agentDataArray.map((agent, agentIdx)=>{
                    return(
                      <li key={agentIdx}>
                        <span className="serials">{agentIdx+1}</span>
                        <span className="profile__image"><img src={agent.image ? agent.image : "img/default-user.svg"} /></span>
                        <span className="names">{agent.name}</span>
                      </li>
                    )
                  })
                }
              </ul>
             </div>
            :
            <div className="empty__town--msg">
              This client has not ranked any agents yet. Once they rank then you will see them here.
            </div>
          }
        </div>
    {/***************************End Agent Ranking****************************/}

      {/*****************End***************/}


      {/***********This is agent client chat*****************/}
      <div className={this.state.agentClientChatOpen ? "right__chat--section remove__index--forImage": "chat__box__open"}>
        <div className={this.state.agentClientChatOpen ? "activity__page schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section" }>
            <div className="schedule__sidebar--header">
              <div>Agent & Client Chat</div>
              <div className="close-btn" onClick={()=> this.agentClientChatClick('close')}>
                  <img src="img/close.svg"/>
              </div>
            </div>
            {
             (agentList && _.compact(agentList).length > 0) ?
              <div>
              {
                chatAgent && chatAgent.length > 0 &&
                 <div className="form-group select_wrap_drawer">
                  <label className="labelinputs__global">Agent Name</label>
                  <select
                    className="selectinputs__global"
                    name="selectAgent"
                    value = {this.state.selectAgent}
                    onChange={this.handleAgentList.bind(this)}>
                      {agentList}
                  </select>
                </div>
              }

            <div className="chat__listing chat__listing--activity">
              <ul className="chat_ul_scroll showchat__agent-client">
                {
                  (chatHistory && chatHistory.length > 0 ) ?
                  chatHistory && chatHistory.map((client, idx) => {
                    return(
                      <div key={idx}>
                       {
                        (this.state.selectedAgentId == client.receiver_id) ?
                          <li>
                            <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                            <div className="details">
                              <h6 className="poppinsfonts">{client.sender_full_name}</h6>
                              {client.chat_type==='image' ?
                                <span>
                                  <img className="clickable__image" onClick={() => this.setState({ photoIndex: idx, openImageModel: true })} src={client.content}/>
                                  {openImageModel && (
                                    <Lightbox
                                      mainSrc={chatHistory.map(c => c.content)[photoIndex]}
                                      onCloseRequest={this.imageOpen.bind(this)}
                                      onMovePrevRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + [client.content].length - 1) % [client.content].length,
                                        })
                                      }
                                      onMoveNextRequest={() =>
                                        this.setState({
                                          photoIndex: (photoIndex + 1) % [client.content].length,
                                        })
                                      }
                                    />
                                  )}
                                </span>
                                :
                                 (client.chat_type === 'text') ?
                                  <p>{client.content}</p>
                                :
                                  (client.chat_type === 'automated_welcome_message') ?
                                  <p>{client.content}</p>
                                :                                
                                 (client.chat_type === 'pdf') ?
                                 <a className="pdf_file" href={client.content} download={client.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(client.file_name)}</div></a>
                                :
                                 (client.chat_type === 'excel') &&
                                  <a className="pdf_file" href={client.content} download={client.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(client.file_name)}</div></a>
                              }
                              {
                                (client.showMsg !== true) &&
                                <h5 className="agentchatfont__med poppinsfonts">{moment(client.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                              }
                            </div>
                          </li>
                        :
                        <div>
                          {
                            (this.state.selectedAgentId == client.sender_id) &&
                            <li className="you">
                              <img src={client.sender_avatar ? client.sender_avatar: "../../img/default-user.svg"} alt="" />
                              <div className="details">
                                <h6 className="poppinsfonts">{client.sender_full_name}</h6>
                                {client.chat_type==='image' ?
                                  <span>
                                    <img className="clickable__image" onClick={() => this.setState({ photoIndex: idx, openImageModel: true })} src={client.content}/>
                                    {openImageModel && (
                                      <Lightbox
                                        mainSrc={chatHistory.map(c => c.content)[photoIndex]}
                                        onCloseRequest={this.imageOpen.bind(this)}
                                        onMovePrevRequest={() =>
                                          this.setState({
                                            photoIndex: (photoIndex + [client.content].length - 1) % [client.content].length,
                                          })
                                        }
                                        onMoveNextRequest={() =>
                                          this.setState({
                                            photoIndex: (photoIndex + 1) % [client.content].length,
                                          })
                                        }
                                      />
                                    )}
                                  </span>
                                  :
                                   (client.chat_type === 'text') ?
                                    <p>{client.content}</p>
                                  :
                                    (client.chat_type === 'automated_welcome_message') ?
                                    <p>{client.content}</p>
                                  :
                                   (client.chat_type === 'pdf') ?
                                   <a className="pdf_file" href={client.content} download={client.content} target="_blank"><div><img src="../../img/pdf_icon.png"/>{(client.file_name)}</div></a>
                                  :
                                   (client.chat_type === 'excel') &&
                                    <a className="pdf_file" href={client.content} download={client.content} target="_blank"><div><img src="../../img/excel_icon.png"/>{(client.file_name)}</div></a>
                                }

                                <h5 className="agentchatfont__med poppinsfonts">{moment(client.chat_time).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                              </div>
                            </li>
                          }
                        </div>
                       }
                      </div>
                    )
                  })
                :
                 <div className="no_agent_wap extra__gap--forChat">No Chat Available</div>
                }
              </ul>
            </div>
          </div>
          :
            <div className="no_agent_wap">There are no agent assigned to this client</div>
          }
        </div>
      </div>
       {/***********This is agent client chat* end****************/}



      </div>
    )
  }
}

export default reduxForm({
  form: 'clientactivity',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientActivity)

