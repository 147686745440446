import { is } from 'immutable'
import { isInteger } from 'lodash'
import * as Yup from 'yup'

// create appointment data
const createAppointment = Yup.object().shape({
  selectAppointment: Yup.string().required('Please select appointment type'),
  selectClient: Yup.string().when('selectAppointment', {
    is: (val) => val === 'Busy',
    then: Yup.string(),
    otherwise: Yup.string().required('Please select client'),
  }),
  selectStartTime: Yup.string().required('Please select start time'),
  selectEndTime: Yup.string().required('Please select end time'),
  description: Yup.string().when('selectAppointment', {
    is: (val) => val === 'Busy',
    then: Yup.string(),
    otherwise: Yup.string().required('Appointment description is required'),
  }),
})

// create agent appointment data
const createAgentAppointment = Yup.object().shape({
  appoitnmentType: Yup.string().required('Please select appointment type'),
  clientId: Yup.string().when('selectAppointment', {
    is: (val) => val === 'Busy',
    then: Yup.string(),
    otherwise: Yup.string().required('Please select client'),
  }),
  appointmentDate: Yup.string().required('Please select appointment date'),
  selectStartTime: Yup.string().required('Please select start time'),
  selectEndTime: Yup.string().required('Please select end time'),
  description: Yup.string().when('selectAppointment', {
    is: (val) => val === 'Busy',
    then: Yup.string(),
    otherwise: Yup.string().required('Appointment description is required'),
  }),
})

// create client from strategist dashboard
const createClient = Yup.object().shape({
  pipelineStage: Yup.string().required('Pipeline Stage is required'),
  assignedStrategist: Yup.array()
    .of(Yup.number().required())
    .required('Strategist is required'),
  priceRange: Yup.string().required('Price is required'),
  // rentalPrice: Yup.string().required('Price is required'),
  primaryFirstName: Yup.string().required('Client first name is required'),
  primaryLastName: Yup.string().required('Client last name is required'),
  primaryMobileNumber: Yup.string()
    .required('Mobile number is required')
    .trim()
    .min(13, 'Primary contact is invalid')
    .max(21, 'Primary contact is invalid'),
  primaryEmail: Yup.string()
    .required('Client email is required')
    .email('Client email address is invalid'),
  // secondryFirstName: Yup.string().required('Client first name is required'),
  // secondryLastName: Yup.string().required('Client last name is required'),
  // number is not required but enter need to validate
  secondaryMobileNumber: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .min(13, 'Secondary mobile number is invalid')
    .max(21, 'Secondary mobile number is invalid'),
  secondaryEmail: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .email('Client secondary email address is invalid'),
  // signupSource: Yup.string().required('Signup source is required'),
  assignedSuburbs: Yup.array()
    .of(Yup.number().required())
    .required('Suburbs is required'),
})

const roleList = [
  'admin',
  'agent',
  'local',
  'strategist',
  'former-local',
  'former-agent',
  'former-strategist-inactive',
  'business development',
  'former business development',
]
// create user from strategist and agent on add junglar user
const createUser = Yup.object().shape({
  selectedRole: Yup.string().required('Select role is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  
  mobileNumber: Yup.string()
    .required('Mobile number is required')
    .trim()
    .min(13, 'Mobile number is invalid')
    .max(21, 'Mobile number is invalid'),
  creatorRole: Yup.boolean().default(false),
  conferenceMobileNumber: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .min(13, 'Mobile number is invalid')
    .max(21, 'Secondary mobile number is invalid'),
  // validation if form submited by super admin
  // dealPayout: Yup.string().when('creatorRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  //   otherwise: Yup.string().required('Deal payout is required'),
  // }),
  // dealPayoutAmount: Yup.string().when('creatorRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  //   otherwise: Yup.string().required('Deal payout amount is required'),
  // }),
  // refferalPayout: Yup.string().when('creatorRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  //   otherwise: Yup.string().required('Refferal payout is required'),
  // }),
  // refferalPayoutAmount: Yup.string().when('creatorRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  //   otherwise: Yup.string().required('Refferal payout amount is required'),
  // }),

  // validation for selected role is agent
  email: Yup.string().when('selectedRole', {
    is: (val) => ['superadmin', ...roleList].includes(val),
    then: Yup.string()
      .required('Email is required')
      .email('Email address is invalid'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default('')
  }),

  // firmAddress: Yup.string().when('selectedRole', {
  //   is: (val) => roleList.includes(val),
  //   then: Yup.string().required('Firm address is required'),
  //   otherwise: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  // }),
  // title: Yup.string().when('selectedRole', {
  //   is: (val) => roleList.includes(val),
  //   then: Yup.string().required('Title is required'),
  //   otherwise: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  // }),

  // validation on strategist
  timeZone: Yup.string().required('Time zone is required'),
  clientLimit: Yup.boolean().default(false),
  minPrice: Yup.string().when('selectedRole', {
    is: (val) => val === 'strategist',
    then: Yup.string().required('Please select min price'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default(''),
  }),
  maxPrice: Yup.string().when('selectedRole', {
    is: (val) => val === 'strategist',
    then: Yup.string().required('Please select max price'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default(''),
  }),
  towns: Yup.array().when('selectedRole', {
    is: (val) => !['business development', 'former business development'].includes(val),
    then: Yup.array()
      .of(Yup.number().required())
      .required('Please select at least one Metro Area'),
    otherwise: Yup.array().notRequired()
  })

  // strategistAssignmentRules: Yup.array().when('selectedRole', {
  //   is: (val) => {
  //     return ['admin', 'strategist', 'agent'].includes(val)
  //   },
  //   then: Yup.array()
  //     .test(  
  //       'validStrategistAssignmentRules', 
  //       'Ensure that each Metro Area has a valid minimum and maximum price',
  //       (value, context) => {
  //         if (!value.length) {
  //           return false
  //         }
  //         let valid = true
  //         for (const rule of value) {
  //           if (
  //             !isInteger(rule.min) ||
  //             !isInteger(rule.max) ||
  //             rule.min >= rule.max
  //           ) {
  //             valid = false
  //           }
  //         }
  //         return valid
  //       }
  //     ),
  //   otherwise: Yup.array().notRequired()
  // }),

  // assignedSuburbs: Yup.array().when('selectedRole', {
  //   is: val => val === 'strategist',
  //   then: Yup.array().of(Yup.number().required()).required('Towns is required').default([]),
  //   otherwise: Yup.array().nullable().notRequired().default([])
  // }),

  // acuityCalendarId: Yup.string().when('selectedRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string().when('strategyToggle', {
  //     is: (val) => val === false,
  //     then: Yup.string()
  //       .nullable()
  //       .notRequired()
  //       .default(''),
  //     otherwise: Yup.string().required('Acuity calendar id is required'),
  //   }),
  //   otherwise: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  // }),
})

// edit user from strategist and agent on add junglar user
const editUser = Yup.object().shape({
  selectedRole: Yup.string().required('Select role is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone_mobile: Yup.string()
    .required('Mobile number is required')
    .trim()
    .min(10, 'Mobile number is invalid')
    .max(21, 'Mobile number is invalid'),
    // .length(17, 'Mobile number is invalid'),
  // creatorRole: Yup.boolean().default(false),
  conferenceMobileNumber: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .min(13, 'Secondary mobile number is invalid')
    .max(21, 'Secondary mobile number is invalid'),
  // validation for selected role is agent
  email: Yup.string().when('selectedRole', {
    is: (val) => ['superadmin', ...roleList].includes(val),
    then: Yup.string()
      .required('Email is required')
      .email('Email address is invalid'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default('')
  }),

  // validation on strategist
  time_zone: Yup.string().required('Time zone is required'),
  is_schedule_on: Yup.boolean().default(false),
  is_limit: Yup.boolean().default(false),
  // calendar_id: Yup.number().positive().nullable().notRequired().when('is_schedule_on', {
  //   is: true,
  //   then: Yup.number().required('Calendar Id is required').default(0)
  // }),
  calendar_id: Yup.number().nullable().when(['selectedRole', 'is_schedule_on'], {
    is: (selectedRole, is_schedule_on) => selectedRole === 'strategist' && is_schedule_on === true,
    then: Yup.number().required('Calendar Id is required').default(0)
  }),

    // dealPayoutAmount: Yup.string().when('creatorRole', {
  //   is: (val) => val === 'strategist',
  //   then: Yup.string()
  //     .nullable()
  //     .notRequired()
  //     .default(''),
  //   otherwise: Yup.string().required('Deal payout amount is required'),
  // }),

  isImpersonate: Yup.boolean().notRequired().default(false),
  // seletedSearchByStrategist: Yup.array().when('isImpersonate', {
  //   is: true,
  //   then: Yup.array().min(1).required('Strategists are required')
  // }),
  price_min: Yup.string().when('selectedRole', {
    is: (val) => val === 'strategist',
    then: Yup.string().required('Please select min price'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default('')
  }),
  price_max: Yup.string().when('selectedRole', {
    is: (val) => val === 'strategist',
    then: Yup.string().required('Please select max price'),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .default(''),
  }),
  
  towns: Yup.array().when('selectedRole', {
    is: (val) => !['business development', 'former business development'].includes(val),
    then: Yup.array()
      .of(Yup.number().required())
      .required('Please select at least one Metro Area'),
    otherwise: Yup.array().notRequired()
  })

  // strategistAssignmentRules: Yup.array()    
  //   .test(
  //     'validStrategistAssignmentRules', 
  //     'Ensure that each Metro Area has a valid minimum and maximum price',
  //     (value, context) => {
  //       if (!value.length) return false
  //       let valid = true
  //       for (const rule of value) {
  //         if (
  //           !isInteger(rule.min) ||
  //           !isInteger(rule.max) ||
  //           rule.min >= rule.max
  //         ) {
  //           valid = false
  //         }
  //       }
  //       return valid
  //     }
  //  )
})

// validation for client basic information
const editClientInformation = Yup.object().shape({
  primaryFirstName: Yup.string().required('First name is required'),
  primaryLastName: Yup.string().required('Last name is required'),
  primaryEmail: Yup.string()
    .required('Email is required')
    .email('Email address is invalid'),
  primaryContact: Yup.string()
    .required('Primary contact is required')
    .trim()
    .min(13, 'Primary contact is invalid')
    .max(21, 'Primary contact is invalid'),
  primaryCountryCode: Yup.string().required('Primary country code is required'),
  // secondaryFirstName: Yup.string().required('Secondary first name is required'),
  // secondaryLastName: Yup.string().required('Secondary last name is required'),
  secondaryEmail: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .email('Client secondary email address is invalid'),
  secondaryContact: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .min(13, 'Seconday contact is invalid')
    .max(21, 'Seconday contact is invalid'),
  // secondaryCountryCode: Yup.string().required(
  //   'Seconday country code is required'
  // ),
  // source: Yup.string().required('Source is required'),
  makeClientLocal: Yup.boolean().default(false),
})

// validation for client contact information
const editClientContactInformation = Yup.object().shape({
  primaryFirstName: Yup.string().required('First name is required'),
  primaryLastName: Yup.string().required('Last name is required'),
  primaryEmail: Yup.string()
    .required('Email is required')
    .email('Email address is invalid'),
  primaryContact: Yup.string()
    .required('Primary contact is required')
    .trim()
    .min(13, 'Primary contact is invalid')
    .max(21, 'Primary contact is invalid'),
})

// edit propspectus informaiton for model
const editProspectusInformation = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  assignedSuburbs: Yup.array()
    .of(Yup.number().required())
    .required('Suburb is required'),
  budget: Yup.string().required('Budget is required'),
  // rentalBudget: Yup.string().required('Rental budget is required'),
  assignedStrategist: Yup.array()
    // .of(Yup.number().required())
    // .required('Strategist is required'),
  // typeOfAdvices: Yup.array()
  //   .of(Yup.string().required())
  //   .required('Type of advice is required'),
})

const backgroundProfileValidation = Yup.object().shape({
  backgroundProfile: Yup.string().required('Background profile is required'),
})

const scheduleAppointment = Yup.object().shape({
  clientId: Yup.string().required('Status is required'),
  clientName: Yup.string().required('Client name is required'),
  strategistName: Yup.string().required('Strategist name is required'),
  appoitnmentType: Yup.string().required('Appointment type is required'),
  date: Yup.string().required('Date is required'),
  startTime: Yup.string().required('Start time is required'),
})

const editDealInforamtion = Yup.object().shape({
  dealType: Yup.string().required('Deal Type is required'),
  agent_id: Yup.number().required('Please select agent'),
  // agentStatus: Yup.string().required('Agent status is required'),
  // selectTown: Yup.string().required('Town is required'),
  agentStatus: Yup.string().required('Status is required'),
  address: Yup.string().required('Address is required'),
  // schoolDistrict: Yup.string().required('School district is required'),
  dateOfAcceptedOffer: Yup.string().required('Date of accepted offer is required'),
  dateOfSignedContract: Yup.string().required('Signed date is required'),
  buySideCommission: Yup.string().required('Commission is required'),
  
  //here condition for sales and rental check
  leaseDate: Yup.string().when('dealType', {
    is: (val) => (val === 'sales' || val === 0 || val === "0"),
    then: Yup.string(),
    otherwise: Yup.string().required('Date is required'),
  }),
  closeDate: Yup.string().when('dealType', {
    is: (val) => (val === 'rentals' || val === 1 || val === "1"),
    then: Yup.string(),
    otherwise: Yup.string().required('Date is required'),
  }),
  salePrice: Yup.string().required('Sale price is required'),
  micFee: Yup.string().required('Misc. fee is required'),
  // agentNote: Yup.string().required('Agent note is required'),
})

// this is for signup agent page2
const agentSignUpPage1 = Yup.object().shape({
  city_live: Yup.string().required('Please enter city'),
  year_in_real_estate: Yup.string().required('Please select value'),
  business_towns_ids: Yup.array().required('Please select towns'),
  buy_side_sales: Yup.string().required('Please enter value'),
  bio: Yup.string().required('Please enter bio'),
  image: Yup.string().required('Please select image'),
})

// this is for signup agent page3
const agentSignUpPage2 = Yup.object().shape({
  sign_contract_date: Yup.string().required('Please select date'),
  sign_contract_time: Yup.string().required('Please select time'),
  broker_first_name: Yup.string().required('Enter first name'),
  broker_last_name: Yup.string().required('Enter last name'),
  broker_email: Yup.string()
   .required('Enter email address')
   .email('Email address is invalid'),
})


const addSchoolValidation = Yup.object().shape({
  InstitutionName: Yup.string().required('Name is required'),
  gradespancodebldgtext: Yup.string().required('Grades are required'),
  GSTestRating: Yup.string().required('Rating is required'),
  studentteacher: Yup.string().required('Student Teacher Ratio is required'),
  studentsnumberof: Yup.number().nullable().required('Number of students are required')
})

const addPrivateSchoolValidation = Yup.object().shape({
  InstitutionName: Yup.string().required('Name is required'),
  gradespancodebldgtext: Yup.string().required('Grades are required'),
  studentteacher: Yup.string().required('Student Teacher Ratio is required'),
  studentsnumberof: Yup.number().nullable().required('Number of students are required')
})

const googlePlaceSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.string().nullable().required('Type is required'),
  rating: Yup.number().nullable().required('Rating is required'),
  vicinity: Yup.string().required('Vicnity is required'),
})

const commuteTableSchema = Yup.object().shape({
  scarsdale_type: Yup.string().nullable().required('Type is required'),
  from: Yup.string().required('From is required'),
  to: Yup.string().nullable().required('To is required'),
  morning: Yup.string().required('Morning is required'),
  evening: Yup.string().required('Evening is required')
})

export default {
  // create appointment
  createAppointment,
  // create client validation
  createClient,
  // create user validation with agent and stragiest
  createUser,
  editUser,
  // edit client information
  editClientInformation,
  editClientContactInformation,
  // edit propectus inforamtion
  editProspectusInformation,
  // background profile validation for background data string
  backgroundProfileValidation,
  // create schedule appointmnet
  scheduleAppointment,
  // create and edit deal infromation
  editDealInforamtion,
  // create agent appointment
  createAgentAppointment,
  // create appointment for client-detail page
  createAppointment,
  //this is for agent on boarding sign up
  agentSignUpPage1,
  agentSignUpPage2,
  
  // These are town characteristics validation
  addSchoolValidation,
  addPrivateSchoolValidation,
  googlePlaceSchema,
  commuteTableSchema
}
