import { connect } from 'react-redux'

import { 
  deleteClientDealByAgent,
  createClientDeal,
  updateClientUser,
  clearPhase,
  clearAgentPhase,
  clearAgentDatePhase
} from '../../../../store/agent/duck'

import { fetchAgentAllClient } from '../../../../store/agentV2/actions'

import ClientsComponent from './component'
const ClientsContainer = connect(
  // Map state to props
  (state) => ({
    agentAllClients: state.agentV2.agentAllClients,
    phase: state.agentV2.phase,
    clientDealPhase: state.agent.clientDealPhase,
    updateUserPhase: state.agent.updateUserPhase,
    deleteClientDealPhase: state.agent.deleteClientDealPhase
  }),
  // Map actions to dispatch and props
  {
    deleteClientDealByAgent,
    createClientDeal,
    fetchAgentAllClient,
    clearAgentPhase,
    updateClientUser,
    clearPhase,
    clearAgentDatePhase
  }
)(ClientsComponent)

export default ClientsContainer
