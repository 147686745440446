import { connect } from 'react-redux'

import AdministrationTopNavigationComponent from './component'
import { handleSignOut } from '../../../store/user/duck'
import { getPushNotification, markAsReadNotification } from '../../../store/strategistV2/header/actions'
import { getUSerProfileByID, clearPhase } from '../../../store/dashboard/duck'

const AdministrationTopNavigationContainer = connect(
  // Map state to props
  (state) => ({
    // userData: state.dashboard.userData,
    junglerUserPhase: state.dashboard.junglerUserPhase,
    notificationPhase: state.strategistV2.header.notificationPhase,
    notificationData: state.strategistV2.header.notificationData,
    markAsReadPhase: state.strategistV2.header.markAsReadPhase,
    markAsReadData: state.strategistV2.header.markAsReadData,
  }),
  // Map actions to dispatch and props
  {
    handleSignOut,
    getPushNotification,
    getUSerProfileByID,
    clearPhase,
    markAsReadNotification
  }
)(AdministrationTopNavigationComponent)

export default AdministrationTopNavigationContainer
