import { connect } from 'react-redux';

import {
  tasksListUsers,
  addUpdateClient,
  clearPhase,
  getClientList
} from '../../../../store/dashboard/duck';
import {
  getFilterParams,
} from '../../../../store/strategistV2/dashboard/actions';
import {
  updateFollowUpDate,
  clearFollowUpPhase
} from '../../../../store/strategistV2/prospectus/followup/actions'

import TaskListComponent from './component';
const TaskListContainer = connect(
  // Map state to props
  state => ({
    filterData: state.dashboard.clientData,
    // filterDataPhase: state.dashboard.dataPhase,
    tasksListPhase: state.dashboard.tasksListPhase,
    clientData: state.dashboard.clientData,
    filterClientsPhase: state.dashboard.filterClientsPhase,
    dataPhase: state.dashboard.dataPhase,
    addUpdateClientdata: state.dashboard.addUpdateClientdata,
    addUpdateClientPhase: state.dashboard.addUpdateClientPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    updateFollowUpDatePhase: state.strategistV2.prospectus.followUp.updateFollowUpDatePhase,
    
  }),
  // Map actions to dispatch and props
  {
    tasksListUsers,
    addUpdateClient,
    updateFollowUpDate,
    clearFollowUpPhase,
    getFilterParams,
    clearPhase,
    getClientList
  }
)(TaskListComponent);

export default TaskListContainer;
