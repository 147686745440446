import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import config from '../../../config'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import ReactExport from "react-data-export"
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class StrategistRatingReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      users_limit:100,
      activePage:1,
      sortByKey: '',
      sortByType : 0,
      isLoadingData: true,
      totalPages:0,
      search: '',
      ratingReportExportData:[]
    }
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)

      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getStrRatingData(strRatingParams)

      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent')
      }else if(_user.role==='local' || _user.role === 'business development'){
       this.props.history.push('/agent')
      }else if(_user.role==='strategist'){
        this.props.history.push('/home/new-clients')
      }

    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.strRatingPhase === "success"){
      let exportArray = []
      if(nextProps.strRatingData.data && nextProps.strRatingData.data.length > 0){
        nextProps.strRatingData.data.map((c,index) => {
          if(c.rating == -1){
            c.rating = 'N/A'
          }
          exportArray.push(c)
        })
      }
      this.setState({ isLoadingData: false, totalPages:nextProps.strRatingData.user_counts, ratingReportExportData:exportArray })
    }
  }

  // On entry change get records
  handleEntryChange(event){
    this.setState({isLoadingData: true, users_limit: event.target.value, activePage: 1}, ()=>{
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getStrRatingData(strRatingParams)
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ isLoadingData: true, activePage: pageNumber}, ()=> {
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getStrRatingData(strRatingParams)
    })
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType}, ()=>{
      const strRatingParams = {}
      strRatingParams.users_limit = this.state.users_limit
      strRatingParams.users_offset = this.state.activePage
      strRatingParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getStrRatingData(strRatingParams)
    })
  }

  onSearch(e){
    this.setState({search:e.target.value})
  }

  clickOnClient(clientId, e){
    this.props.history.push(`/activity/${clientId}`)
  }

  render() {
    const { strRatingData } = this.props
    const { ratingReportExportData } = this.state
    let ratingReport = '';
    let ratingAllData = strRatingData && strRatingData.data
    if(ratingAllData){
      ratingReport = ratingAllData.filter(
        (ratingData) =>{
          return (
            (ratingData.strategist_fullname.toLowerCase().indexOf(this.state.search.toLowerCase()) && ratingData.client_fullname.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }

    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Strategist Rating Report</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container">
        <div className="row">
      
              <div className="col-md-12">
              <div className="reports__flex">

              <div className="right__cont right__flex  right__cont--full">
           
     
              <span className="totalRecords__label">Total Records ({this.state.totalPages > 0  ? this.state.totalPages : 0})</span>
   
         
              <span className="enteries-span">
              <label className="enteries-label">Entries</label>
               <select className="selectinputs__global" value = {this.state.users_limit} onChange={this.handleEntryChange.bind(this)}>
                  {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                      <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                    })}
                </select>
              </span>  
         
                

                <ExcelFile element={<button className="reporting__export--btn btn__mob--mar exporthide">Export Data</button>}>
                <ExcelSheet data={ratingReportExportData} name="strategist_rating_report">
                    <ExcelColumn label="Strategist Name" value="strategist_fullname"/>
                    <ExcelColumn label="Client Name" value="client_fullname"/>
                    <ExcelColumn label="Rating" value="rating"/>
                    <ExcelColumn label="Pipeline Stage" value="client_status"/>
                    <ExcelColumn label="Appointment Date" value="appointment_date"/>
                </ExcelSheet>
              </ExcelFile>
            
           
              </div>
              </div>
              </div>
              
  
          </div>
          {<div className="row">
            <div className="col-md-12">
              <form className="navbar__header--form">
                <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input reportsearchsminus add" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search strategist or client"/>
              </span>
              </form>
          </div>
          </div>}
          
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
                <div>
                  <div className="filter__sub--container filter__sub--container-edit">
                    <div className="jungler__list useslist__extra">
                       <div className="table-responsive">
                         <table className="table table-striped">
                            <thead>
                              <tr>
                                <th onClick={this.sortData.bind(this , 'strategistName')}>Strategist Name</th>
                                <th onClick={this.sortData.bind(this , 'clientName')}>Client Name</th>
                                <th onClick={this.sortData.bind(this , 'rating')}>Rating</th>
                                <th onClick={this.sortData.bind(this , 'tag')}>Pipeline Stage</th>
                                <th onClick={this.sortData.bind(this , 'appointment_date')}>Appointment Date</th>
                              </tr>
                            </thead>
                            {this.state.isLoadingData === true ?
                            <tbody>
                                <tr>
                                  <td colSpan="5" className="noClientResults text-center">
                                  <img src="../../img/loader2.svg" />
                                  <h5>Your Strategist Rating Report is generating, please wait.........</h5>
                                  </td>
                                  </tr>
                            </tbody>
                            :
                            <tbody>
                            {
                              ratingReport && ratingReport.length > 0 ?
                              ratingReport.map((c,index) => {
                              return(
                              <tr key={index} >
                                {/*----------Strategist full name--------*/}
                                <td>{c.strategist_fullname ? c.strategist_fullname:'N/A'}</td>

                                {/*----------Client full name ----------*/}
                                <td className="client__click" onClick={this.clickOnClient.bind(this, c.clientId)}>{c.client_fullname ? c.client_fullname  :'N/A' }</td>
                               
                                {/*----------Rating--------*/}
                                <td>{ (c.rating !== -1 ) ? c.rating: "N/A"}</td>
                                <td>{c.client_status}</td>
                                <td>{c.appointment_date == '0000-00-00 12:00:00 AM' ? '-' : c.appointment_date}</td>
                              </tr>
                               )
                              }) : 
                              <tr><td colSpan="6" className="noClientResults">No rating record found</td></tr> 
                              }
                            </tbody> 
                            }
                        </table>
                       </div>
                   </div>
                 </div>
               </div>
              </main>
              {
                (ratingReport && ratingReport.length > 0)?
                  <div className="pagination-container">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.users_limit}
                    totalItemsCount={this.state.totalPages}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                :''
              }
              
             
            </div>
          </div>
        </div>
        {/*<div className="dashboard-footer text-center">
              Suburban Jungler - Strategist Rating Report
        </div>*/}
      </div>

    )
  }
}

export default reduxForm({
  form: 'strategistratingreport',  // a unique identifier for this form
  destroyOnUnmount: true,
})(StrategistRatingReport)
