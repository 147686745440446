import { connect } from 'react-redux'

import GooglePlacesComponent from './component'

const GooglePlacesContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(GooglePlacesComponent)

export default GooglePlacesContainer
