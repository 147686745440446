import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
const required = value => value ? undefined : 'Required'
class ClientProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    const err = {}
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required.'
    }
    if (this.state.password === '' || this.state.password.trim() === '') {
      err.password = 'Password is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      const { loginUser } = this.props
      const data = {
        email: this.state.email,
        password: this.state.password
      }
      loginUser(data)
    }
  }

  handleRequestClose() {
    this.setState({
      openSnackbar: false,
      errMessage: ''
    })
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      token,
      phase
    } = this.props
    // if(phase == "success") {
    //   return(
    //     <Redirect to={`/dashboard`}/>
    //   )
    // }
    return (
      <div>

        <div className="full__page--header">
          <div className="container">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Client Profile</span></a>
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <Link to={`/activeSIMs`}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <main className="client__profile--container">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="full__page--left">
                  <h1>Client Information</h1>
                  <p>Enter in all this client information</p>
                </div>
              </div>
              <div className="col-md-6 col-md-offset-1">
                <div className="client__profile--form">
                  <div className="form-group">
                    <label className="text__label--sm">Strategist(s) Assigned with</label>
                    <select className="form-control custom-select">
                      <option>Select Strategist(s)</option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                    </select>
                  </div>
                  <div className="form-group text-group">
                    <input type="text" className="form-control text-input" required/>
                    <span className="bar"></span>
                    <label className="text-label">Email Address</label>
                  </div>
                  <div className="form-group text-group">
                    <input type="text" className="form-control text-input" required/>
                    <span className="bar"></span>
                    <label className="text-label">Client Mobile Number</label>
                  </div>

                  <div className="form-group">
                    <label className="text__label--sm">Price Range</label>
                    <select className="form-control custom-select">
                      <option>Select Price Range</option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="text__label--sm">Source</label>
                    <select className="form-control custom-select">
                      <option>Select Source</option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="text__label--sm">Suburb/City</label>
                    <select className="form-control custom-select">
                      <option>Select a Suburb/City</option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                      <option>Select </option>
                    </select>
                  </div>


                  <div className="client__profile--form__btn">
                    <button type="submit" className="btn btn__black--lg btn__black--outline">Cancel</button>
                    <button type="submit" className="btn btn__green btn__green--lg">Save</button>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </main>




      </div>
    )
  }
}

export default reduxForm({
  form: 'clientprofile',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientProfile)
