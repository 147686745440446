import { connect } from 'react-redux'
import NoAgentRatingReport from './component'

import { fetchUser } from '../../../store/user/duck'
import { getNoAgentRatingData } from '../../../store/dashboard/duck'

const NoAgentRatingReportContainer = connect(
  // Map state to props
  (state) => ({
    noAgentRatingPhase: state.dashboard.noAgentRatingPhase,
    noAgentRatingData: state.dashboard.noAgentRatingData
  }),
  // Map actions to props
  {
  	fetchUser,
  	getNoAgentRatingData
  }
)(NoAgentRatingReport)
export default NoAgentRatingReportContainer
