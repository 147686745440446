import { connect } from 'react-redux'

import InsightsComponent from './component'

import { fetchTownResearch, updateTownResearch } from '../../../../../../store/strategistV2/towns/actions'

const InsightsContainer = connect(
  // Map state to props
  (state) => ({
    townResearchs: state.strategistV2.town.townResearchs,
    fetchResearchTownPhase: state.strategistV2.town.fetchResearchTownPhase,
    updateResearchTownPhase: state.strategistV2.town.updateResearchTownPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchTownResearch,
    updateTownResearch,
  }
)(InsightsComponent)

export default InsightsContainer
