/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { map, get } from 'lodash'
import { DatePicker, Spin } from 'antd'

import { CloseIcon } from '../../../../../components/icons'
import config from '../../../../../config'
import Cookies from 'universal-cookie'
import validation from '../../../../../config/validation'
import { SUCCESS } from '../../../../../constants/phase'
import { convertTimeString } from '../../../../../utils/helper'
import Loader from '../../../../../components/Loader'

const cookies = new Cookies()

const parseTimezone = (id, strategists) => {
  if (id && strategists) {
    const strategist = strategists.find(item => item.id == id)
    return strategist ? strategist.time_zone : null
  }
  return null
}

export default function ScheduleAppointment({
  isAppointmentModelOpen,
  closeModal,
  client,
  ...props
}) {
  const clientId = props.match.params.id
  const strategistId = client ? client.strategistId : null
  const user = cookies.get('user')
  const [availableDates, setAvailableDates] = useState([])
  const [availableStrategists, setAvailableStrategists] = useState([])
  const [selectedStrategist, setSelectedStrategist] = useState(null)
  const [selectedStrategistTimeZone, setSelectedStrategistTimeZone] = useState(null)
  const [isLoading, setLoader] = useState(false)

  useEffect(() => {
    if (availableStrategists.length > 0 && strategistId) {
      const strategistTimeZone = parseTimezone(strategistId, availableStrategists)
      if (strategistTimeZone) {
        setSelectedStrategistTimeZone(moment().tz(strategistTimeZone).format('z'))
      }
    }
  }, [availableStrategists])

  useEffect(() => {
    if (selectedStrategist) {
      const strategistTimeZone = parseTimezone(selectedStrategist, availableStrategists)
      if (strategistTimeZone) {
        setSelectedStrategistTimeZone(moment().tz(strategistTimeZone).format('z'))
      }
    }

  }, [selectedStrategist])

  useEffect(() => {
    setLoader(true)
    const selectDate = moment().format('YYYY-MM-DD')
    props.getStrategistAvaillableDates({
      strategistId: client.strategistId ? client.strategistId : '',
      date: selectDate,
      timezone: user.time_zone,
    })
  }, [])
  useEffect(() => {
    if (props.strategistAvailabilityDatePhase === SUCCESS) {
      setLoader(false)
      setAvailableDates(props.strategistAvailabilityDates)
      props.clearStrategistAppointment()
    }
    if (props.strategistAvailabilityPhase === SUCCESS) {
      setLoader(false)
      props.clearStrategistAppointment()
    }
    if (props.createStrategistAppointmentPhase === SUCCESS) {
      closeModal()
      props.clearStrategistAppointment()
    }
  }, [
    props.strategistAvailabilityDatePhase,
    props.strategistAvailabilityPhase,
    props.createStrategistAppointmentPhase,
  ])
  useEffect(() => {
    const strategists =
      user.role === 'superadmin' || user.role === 'admin'
        ? get(props, 'filterParams.data.strategists', [])
        : // eslint-disable-next-line camelcase
        [
          {
            id: user.id,
            strategist_name: `${user.first_name} ${user.last_name}`,
            time_zone: user.time_zone
          },
        ]
    setAvailableStrategists(strategists)

  }, [
    props.filterParams
  ])
 // strategist option
  const strategistOptions = () => {
    const strategists =
      user.role === 'superadmin' || user.role === 'admin'
        ? get(props, 'filterParams.data.strategists', [])
        : // eslint-disable-next-line camelcase
          [
            {
              id: user.id,
              strategist_name: `${user.first_name} ${user.last_name}`,
            },
          ]

    return map(strategists, (strategist, index) => (
      <option key={index} value={strategist.id}>{`${strategist.strategist_name}`}</option>
    ))
  }
  // type of appointment options
  const typeOfAppointment = () => {
    return map(config.strategistAppointment, (appointment, index) => (
      <option key={index} value={appointment.name}>
        {appointment.value}
      </option>
    ))
  }
  // start time options
  const startTimeOptions = () => {
    const availabilities = get(props, 'strategistAvailabilityTimes', [])
    return map(availabilities, (slotval, indexslot) => (
      <option key={indexslot} value={slotval}>
        {convertTimeString(slotval)} {selectedStrategistTimeZone}
      </option>
    ))
  }

  const handleChangeStrategist = (event, setFieldValue) => {
    const selectDate = moment().format('YYYY-MM-DD')
    setSelectedStrategist(event.target.value)
    if (event.target.value) {
      setLoader(true)
      props.getStrategistAvaillableDates({
        strategistId: event.target.value,
        date: selectDate,
        timezone: user.time_zone,
      })
    }
    setFieldValue('strategistName', event.target.value)
  }

  const selectDate = (values, date, setFieldValue) => {
    const selectDate = moment(date).format('YYYY-MM-DD')
    setLoader(true)
    setFieldValue('date', date)
    props.fetchStrategistAvailability({
      strategist_id: values.strategistName,
      timezone: user.time_zone,
      date: selectDate,
    })
  }
  // disable date for availbility not found
  const disabledDate = (current) => {
    const dates = map(availableDates, (date) => date.date)
    // Can not select days before today and today
    return (
      current && (current < moment.utc().startOf('day') || !dates.includes(moment(current).format('YYYY-MM-DD')))
    )
  }
  const handleSubmit = (values) => {
    props.createStrategistAppointment({
      client_id: values.clientId,
      strategist_id: values.strategistName,
      appointment_type: values.appoitnmentType,
      date: moment(values.date).format('YYYY-MM-DD'),
      time: values.startTime,
      timezone: user.time_zone,
      description: '',
    })
  }
  return (
    <Fragment>
      <ReactModal
        isOpen={isAppointmentModelOpen}
        onRequestClose={closeModal}
        contentLabel="Create Appointment"
        portalClassName="react-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            // initial values
            clientId,
            clientName: client.name,
            strategistName: client.strategistId ? client.strategistId : '',
            appoitnmentType: '',
            date: '',
            startTime: '',
          }}
          validationSchema={validation.scheduleAppointment}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            handleSubmit(values)
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Create Appointment </h5>
                  <button type="button" className="btn react-modal-close" onClick={closeModal}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="clientName"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value={client.clientId}>{client.name}</option>
                    </Field>
                    <label className="material-textfield-label label-lg">Client Name</label>
                    <ErrorMessage className="invalid-feedback" name="clientName" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="strategistName"
                      value={values.strategistName}
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                      onChange={(event) => handleChangeStrategist(event, setFieldValue)}
                    >
                      <option value="">Select strategist name</option>
                      {strategistOptions()}
                    </Field>
                    <label className="material-textfield-label label-lg">Strategist Name</label>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="strategistName"
                      component="div"
                    />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="appoitnmentType"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value="">Select the type of Appointment</option>
                      {typeOfAppointment()}
                    </Field>
                    <label className="material-textfield-label label-lg">Appointment Type</label>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="appoitnmentType"
                      component="div"
                    />
                  </div>

                  <Spin size="small" spinning={isLoading} indicator={<Loader />}>
                    <div className="row">
                        <div className="col-md-6">
                          {availableDates.length > 0 ? (
                            <div className="form-group material-textfield">
                              <div className="btn__follow-up-date follow-up-date-lg">
                                <div className="follow-up-date-label">Date</div>
                                <DatePicker
                                  className="datepicker__follow-up-date"
                                  dropdownClassName="datepicker__overlay__follow-up-date"
                                  onChange={(date) => selectDate(values, date, setFieldValue)}
                                  disabledDate={disabledDate}
                                  name="date"
                                  inputReadOnly={true}
                                  allowClear={false}
                                  format="MM/DD/YYYY"
                                  value={values.date}
                                  placeholder="Select Date"
                                />
                              </div>
                              <ErrorMessage className="invalid-feedback" name="date" component="div" />
                            </div>
                          ) : (
                            <div className="form-group material-textfield">
                              <Field
                                component="text"
                                name="date"
                                className="form-control material-textfield-input textfield-input-lg"
                                value=""
                                inputReadOnly
                              />
                            <label className="material-textfield-label label-lg">Date Not Found</label>
                          </div>
                          )}
                        </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Field
                            component="select"
                            className="form-control material-textfield-input textfield-input-lg"
                            name="startTime"
                          >
                            <option value="">Start Time</option>
                            {startTimeOptions()}
                          </Field>
                          <label className="material-textfield-label label-lg">Start Time</label>
                          <ErrorMessage
                            className="invalid-feedback"
                            name="startTime"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  </Spin>

                  <div className="form-group">
                    <div className="note__text">
                      You only need to put in your start time because we have already defined the
                      length of the appointment in Acuity. Once you create this it will be created
                      in Acuity as well.
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn__btn btn__lg btn-dark w__100"
                      disabled={isSubmitting}
                    >
                      Schedule
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    </Fragment>
  )
}
