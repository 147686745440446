import { connect } from 'react-redux'
import AgentAssignedForm from './component'
import { getFilterParams, agentAssignedTown, getUSerProfileByID } from '../../../store/dashboard/duck'

const AgentAssignedReporting = connect(
  // Map state to props
  (state) => ({
    filterParams: state.dashboard.filterParams,
    agentAssToTownPhase: state.dashboard.agentAssToTownPhase,
    agentAssToTownData: state.dashboard.agentAssToTownData,
    userData: state.dashboard.userData
  }),
  // Map actions to props
  {
    getFilterParams,
    agentAssignedTown,
    getUSerProfileByID
  }
)(AgentAssignedForm)
export default AgentAssignedReporting
