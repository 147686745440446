import { connect } from 'react-redux'
import TotalClientReceivePerMonthForm from './component'

import { getTotalClientReceivePerMonth, getFilterParams } from '../../../store/dashboard/duck'

const TotalClientReceivePerMonthFormStatistics = connect(
  // Map state to props
  (state) => ({
    clientsPerMonthPhase: state.dashboard.clientsPerMonthPhase,
    clientsPerMonthData: state.dashboard.clientsPerMonthData,
    filterParams: state.dashboard.filterParams,
  }),
  // Map actions to props
  {
    getTotalClientReceivePerMonth,
    getFilterParams
  }
)(TotalClientReceivePerMonthForm)
export default TotalClientReceivePerMonthFormStatistics
