import React from 'react';
import NumberFormat from 'react-number-format';
import get from 'lodash/get';
import isNil from 'lodash/isNil';


const EditField = props => {
  let values = props.values;
  let initVal = props.initVal;
  let mainVal = props.mainVal;
  return (
    <div className="edit__data__inline">
      <div className="material-textfield">
        <NumberFormat
          thousandSeparator={true}
          value={get(values, initVal, values[mainVal])}
          onValueChange={e => props.setfieldvalue(initVal, e.floatValue)}
          name={initVal}
          prefix={get(props, 'prefix', '')}
          suffix={get(props, 'suffix', '')}
          className="form-control material-textfield-input textfield-input-lg"
          required
        />
        <label className="material-textfield-label label-lg">
          {get(props, 'label', '')}
        </label>
      </div>
      <div className="edit__data__inline__cta">
        <button
          onClick={() => {
            props.setEditModeOn(props.setfieldvalue, values, props.toggleKey);
          }}
          type="button"
          className="btn btn__btn btn-cancel"
        >
          Cancel
        </button>

        <button
          onClick={() => {
            // if(props.mainVal === "avg_home_sale_price") {
            // average_property_tax_amount
            // }
            
            let edit_avg_home_sp = get(values, 'edit_avg_home_sp');
            let edit_property = get(values, 'average_property_tax_amount');
            if (mainVal === 'edit_avg_tax_rate') {
              edit_property =
                (edit_avg_home_sp *
                  parseFloat(get(values, initVal, values[mainVal]))) /
                100;
              this.setState({ edit_property: parseInt(edit_property) });
            } else if (edit_property || edit_avg_home_sp) {
              let editAvgTaxRate = edit_property / edit_avg_home_sp;
              editAvgTaxRate = (parseFloat(editAvgTaxRate) * 100).toFixed(2);
              // this.setState({ edit_avg_tax_rate: editAvgTaxRate })
              props.setfieldvalue('edit_avg_tax_rate', editAvgTaxRate);
            }
            let obj = {
              [props.attomKey]: String(get(values, initVal, values[mainVal]))
            };


            props.syncBtn(obj);
            props.setfieldvalue(mainVal, get(values, initVal, values[mainVal]));
            props.setEditModeOn(props.setfieldvalue, values, props.toggleKey);
          }}
          type="button"
          className="btn btn__btn btn-dark btn-save"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditField;
