/* eslint-disable */
import React, { Component } from 'react'
import axios from 'axios'
// The Browser API key obtained from the Google API Console.
var developerKey = process.env.REACT_APP_GOOGLE_DEV_KEY

var clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

var scope ='https://www.googleapis.com/auth/drive'
var pickerApiLoaded = false;
var oauthToken;
export default class GoogleDrive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgArray:[]
    }
  }
  onLoad = () => {
    // const map = new window.google.maps.Map(document.getElementById(this.props.id), this.props.options)
    // this.props.onMount(map)
  }
  componentWillReceiveProps(np) {
    // const { removeImg, imageUpload, getGeneratedReport, match: { params: { townId } }, pushToWp, history } = np
    if(np.report !== this.props.report) {
      this.componentDidMount()
    }
  }
  componentDidMount() {
    if (!window.gapi) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://apis.google.com/js/api.js`
      const headScript = document.getElementsByTagName('script')[0]
      headScript.parentNode.insertBefore(script, headScript)
      script.addEventListener('load', () => {
        this.onApiLoad()
      })
    } else {
      this.onAuthApiLoad()
    }
  }
  componentWillUnmount() {
    
  }
  onApiLoad() {
    gapi.load('auth2', this.onAuthApiLoad);
    gapi.load('picker', this.onPickerApiLoad);
  }
  componentDidCatch(error, errorInfo) {
  }
  // Create and render a Picker object for picking from Google Photos.
  createPicker=()=>{
    if (pickerApiLoaded && oauthToken) {
      var view = new google.picker.View(google.picker.ViewId.DOCS);
          view.setMimeTypes("image/png,image/jpeg,image/jpg,image/heic,application/vnd.google-apps.folder");
      var picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .addView(view)
          .setOAuthToken(oauthToken)
          .setDeveloperKey(developerKey)
          .setCallback(this.pickerCallback)
          // enableFeature(google.picker.Feature.MINE_ONLY).
          .build();
      picker.setVisible(true);
    }
  }
   onAuthApiLoad =()=> {
    var authBtn = document.getElementById('auth');
    authBtn.disabled = false;
    authBtn.addEventListener('click', ()=> {
      gapi.auth2.init({ client_id: clientId }).then((googleAuth)=> {
        googleAuth.signIn({ scope: scope }).then((result)=> {
          this.handleAuthResult(result.getAuthResponse());
        })
      })
    });
  }
  onPickerApiLoad=()=> {
    pickerApiLoaded = true;
    this.createPicker();
  }
  handleAuthResult=(authResult)=> {
    if (authResult && !authResult.error) {
      oauthToken = authResult.access_token;
      this.createPicker();
    }
  }
  // A simple callback implementation.
  pickerCallback = (data) => {
    var doc = 'nothing';
    var imageArray = [];
    var token = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      doc = data.docs;
      const finalData = []
      for (var i = 0; i < doc.length; i++) {
        const obj = {}
        obj.mimeType = doc[i]['mimeType']
        obj.id = doc[i]['id']
        obj.name = doc[i]['name']
        obj.url = "https://www.googleapis.com/drive/v2/files/"+ doc[i]['id']+"?alt=media"// "https://testjungler-api.suburbanjungleinc.com/v1/getImageFromUrl";
        finalData.push(obj)
      }
      this.sendData(finalData, token)
    }
  }
  sendData=(data, token)=>{
    this.props.sendData(data, token)
  }
  srcToFile(src, fileName, mimeType){
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    return (fetch(proxyurl+src)
      .then(function(res){return res.arrayBuffer()})
      .then(function(buf){return new File([buf], fileName, {type:mimeType})})
    )
  }
  render() {
    return (
        <button type="button" disabled id="auth" className="towngoogledrivebtn">Pick Image From Google Drive</button>
    )
  }
}
