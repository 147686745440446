import 'rxjs'
import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import SideNavigation from '../../components/sideNavigation'
import TopNavigation from '../TopNavigation/container'
import moment from 'moment';
import NewClients from './partials/NewClients/component'
import FollowUp from './partials/FollowUp/component'
import ActiveClients from './partials/ActiveClients/component'
import UpcomingAppointments from './partials/UpcomingAppointments/component'
import config from '../../config'
import './styles.scss'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {getHostname} from "../../utils/helper";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()

let pre_Auth = ''
let _user = ''

const HOSTNAME = process.env.API_HOSTNAME

const initialValues = {
}

class DashboardComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      pre_Auth: '',
      dropDownMenuVal: "new_clients"
    }
  }

  componentWillMount(){
    const {fetchUser} = this.props;
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      this.props.getFilterParams()

    if(_user.role==='client'){
        this.props.history.push('/clients')
     }else if(_user.role==='agent'){
       this.props.history.push('/clients')
     }
    }
    pre_Auth = cookies.get('i_user')
    if(pre_Auth){
      this.setState({pre_Auth: pre_Auth})
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchUser, user } = this.props;
    let _userd = cookies.get('user')
    let self = this
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    }
    if (nextProps.profilePhase != "success" && _userd && user) {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile updated successfully."})
      const data = {}
      data.user_id = this.state.userid
      this.props.fetchUser(data)
    }
    if (nextProps.profilePhase === "success") {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone
        })
      }
    }
    setTimeout(function() {
      self.setState({ errProfileMessage: '', errAddressMessage: '', errPasswordMessage: ''})
    }, 3000);
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/strategist-dashboard" })
    }
  }

  mainDropDownMenu(e){
   this.setState({ dropDownMenuVal: e.target.value })
    const { history } = this.props
    if(e.target.value === "new_clients"){
      history.push({pathname: "/home/new-clients", state: e.target.value})
    }else if(e.target.value === "your_task_list"){
      history.push({pathname: "/home/follow-up",  state: e.target.value})
    }else if(e.target.value === "latest_active_clients"){
      history.push({pathname: "/home/active-clients",  state: e.target.value})
    }else if(e.target.value === "upcoming_appointment"){
      history.push({pathname: "/home/upcoming-appointments",  state: e.target.value})
    }
    // <li><Link to={`/home/new-clients`} className={currentPath == '/home/new-clients' ? "active" : ""}>New Clients</Link></li>
    // <li><Link to={`/home/follow-up`} className={currentPath == '/home/follow-up' ? "active" : ""} >Your Task List</Link></li>
    // <li><Link to={`/home/active-clients`} className={currentPath == '/home/active-clients' ? "active" : ""}>Latest Active Clients</Link></li>
    // <li><Link to={`/home/upcoming-appointments`}
  }

  render() {
    if(user && user.role==='client'){
      return (
        <Redirect to={`/clients`} />
      )
    }else if(user && user==='agent'){
      return (
        <Redirect to={`/agents`} />
      )
    }
    const currentPath = window.location.pathname
    if(this.props.location.pathname === '/home' || this.props.location.pathname === '/home/'){
    return(
      <Redirect to="/home/new-clients" />
      )
    }
    const {
      handleSubmit,
      isSubmitting,
      pristine,
      rxError,
      token,
      user,
      phase,
      message,
      DashboardData,
      location
    } = this.props
    return (
      <div>
        {
          (pre_Auth)?
          <div className="strategist_top">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
          :''
        }
        <TopNavigation {...this.props}/>
        <div id="wrapper">
          <SideNavigation {...this.props}/>
          <div id="page-content-wrapper" className={ pre_Auth ? "imper__homedownwrapper" : "homedownwrapper"}> 
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 padding-left">
                  <ul className="dashboard__navbar--tabs desktop__only minuspadtop">
                    <li><Link to={`/home/new-clients`} className={currentPath == '/home/new-clients' ? "active" : ""}>New Clients</Link></li>
                    <li><Link to={`/home/follow-up`} className={currentPath == '/home/follow-up' ? "active" : ""} >Your Task List</Link></li>
                    <li><Link to={`/home/active-clients`} className={currentPath == '/home/active-clients' ? "active" : ""}>Latest Active Clients</Link></li>
                    <li><Link to={`/home/upcoming-appointments`} className={currentPath == '/home/upcoming-appointments' ? "active" : ""} >Upcoming Appointments</Link></li>
                  </ul>

                  {/* mobile dashboard dropdown open */}
                  <div className="dropdown__mobview">
                    <select 
                      onChange={this.mainDropDownMenu.bind(this)}
                      value={location && location.state} 
                      className="selectdropdown"
                    >
                      <option value="new_clients">New Clients</option>
                      <option value="your_task_list">Your Task List</option>
                      <option value="latest_active_clients">Latest Active Clients</option>
                      <option value="upcoming_appointment">Upcoming Appointments</option>
                    </select>
                  </div>
                  {/* mobile dashboard dropdown close */}

                  <div className="clearfix"></div>
                    { this.props.location.pathname === '/home/new-clients' && <NewClients {...this.props}/> }
                    { this.props.location.pathname.indexOf('/home/follow-up') !== -1 && <FollowUp {...this.props}/> }
                    { this.props.location.pathname.indexOf('/home/active-clients') !== -1 && <ActiveClients {...this.props}/> }
                    { this.props.location.pathname.indexOf('/home/upcoming-appointments') !== -1 && <UpcomingAppointments {...this.props}/> }
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'venues',  // a unique identifier for this form
  destroyOnUnmount: true,
  initialValues
})(DashboardComponent)
