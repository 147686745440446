import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import TokenLogin from './partials/TokenLogin/component'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import $ from 'jquery'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { get } from 'lodash'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies();
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wpUrl: config.url.wpUrl,
      email: "",
      password: "",
      ru: "",
      err: {},
      errMessage: "",
      errorMessageOTP: "",
      isLoading: false,
      isEmailTab: true,
      phoneNumber: "",
      country_code: '+1'
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    if (this.getParameterByName('ru') && this.getParameterByName('ru') != '' && this.getParameterByName('ru') != null) {
      this.setState({ ru: this.getParameterByName('ru') })
    }
    window.localStorage.setItem('clientToken', null);
  }

  componentDidMount(){
    if(this.props.location.search.split('&').includes('all_appointments')) {
      localStorage.setItem('appointments_drawer', 1);
    }
    document.title = "Jungler: Staff Login";
  }

  componentWillUnmount() {
    document.title = "Suburban Jungle";
  }

  // Function to set form input values in state
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCodeChange(event) {
    this.setState({ country_code: '+' + event })
  }

  componentWillReceiveProps(nextProps) {
    let backendUrl = config.url.backendUrl;
    if (nextProps && nextProps.clientloginPhase === true) {
      if (nextProps.user && nextProps.user.login_type === "email") {
        this.setState({ magiclinkmsg: nextProps.user.message })
      } else if (nextProps.user && nextProps.user.login_type === "mobile") {
        this.props.history.push({
          pathname: '/otp-verify',
          state: { mobile: this.state.phoneNumber, country_code: this.state.country_code }
        })
      }
    }
    if (nextProps.clientloginPhase === false) {
      if (nextProps.user.login_type === "mobile") {
        this.setState({ errorMessageOTP: nextProps.user.message })
      } else {
        this.setState({ magiclinkmsg: 'Sorry, we cannot find that user, please try again or email us at info@suburbanjunglegroup.com' })
      }
      //alert('Sorry, we cannot find that user, please try again or email us at info@suburbanjunglegroup.com');
    } else if (nextProps.clientloginPhase === "error" && nextProps.clientloginError) {
      if (get(nextProps, 'clientloginError.response.status', '') === 503) {
        nextProps.history.push("site-maintenance")
      }
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Function to submit login form data
  handleClick() {
    if (this.state.isEmailTab == true) {
      const err = {}
      this.setState({ errMessage: "" })
      if (this.state.email === '' || this.state.email.trim() === '') {
        err.email = 'Email is required.'
      } else if (!this.validateEmail(this.state.email)) {
        err.email = 'Invalid email'
      }
      this.setState({ err })
      if (!Object.keys(err).length) {
        const { loginUserClient } = this.props
        const data = {}
        data.email = this.state.email.toLowerCase();
        data.login_type = "email";
        loginUserClient(data)
      }
    } else {
      let onlyNumber = this.state.phoneNumber && this.state.phoneNumber.replace(/\D/g, '')
      const err = {}
      this.setState({ errMessage: "" })
      if (this.state.phoneNumber === '' || this.state.phoneNumber.trim() === '') {
        err.phoneNumber = 'Phone is required.'
      } else if (onlyNumber.toString().length < 6 || onlyNumber.toString().length > 16) {
        err.phoneNumber = 'Invalid phone'
      }
      this.setState({ err })
      if (!Object.keys(err).length) {
        const { loginUserClient } = this.props
        const data = {}
        data.mobile = this.state.phoneNumber
        data.login_type = "mobile"
        data.country_code = this.state.country_code
        loginUserClient(data)
      }
    }
  }


  render() {
    const isLoading = this.state.isLoading
    let userData = cookies.get('user')
    if (userData && userData.role) {
      if (userData.role === 'superadmin') {
        return (
          <Redirect to={`/administration/dashboard`} />
          // <Redirect to={`/strategist/clients`} />
        )
      } else if (userData.role === 'admin' || userData.role === 'strategist') {
        return (
          // <Redirect to={`/home`}/>
          <Redirect to={`/administration/dashboard`} />
          // <Redirect to={`/strategist/clients`} />
        )
      } else if (userData.role === 'agent') {
        if (userData && userData.onboarding_status && parseInt(userData.onboarding_status) > 0 && parseInt(userData.onboarding_status) < 7) {
          <Redirect to={`/agent/signup`} />
        } else {
          <Redirect to={`/agent/dashboard`} />
          // <Redirect to={`/agent/clients/all-clients`} />
        }
      } else if (userData.role === 'local') {
        return (
          <Redirect to={`/agent/clients/all-clients`} />
        )
      }else if (userData.role === 'business development') {
        return (
          // <Redirect to={`/agent/dashboard`} />
        <Redirect to={`/agent/clients/all-clients`} />
        )
      }
      else if (userData.role === 'client') {
        return (
          <Redirect to={`/clients`} />
        )
      }
    }

    $(document).ready(function () {
      // Execute a function when the user releases a key on the keyboard
      $('.login-password').on("keyup", function (event) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          // Trigger the button element with a click
          $(".login-submit").click();
        }
      });

    })

    return (
      <section className="login__container">
        {this.props.location.pathname.indexOf('/token') !== -1 && <TokenLogin {...this.props} />}
        <div className="container-fluid">
          <div className="row pop-up-header" >
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left-align">
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 right-align">
              <div className="row">
                <a href={this.state.wpUrl}>
                  {/*<img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/logo.png`} className="popup-logo" />*/}
                  <img src='/img/logo.svg' className="popup-logo" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-10 center-align">
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 right-align">
              {/* <div className="close">
                <a href={`${this.state.wpUrl}`}>
                <img src={`${this.state.wpUrl}/wp-content/uploads/2018/03/close-icon.png`}/>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="LoignSection TabLoginSection login__page">
          <div className="overlay-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="v-container">


                  <div className="loginbox_new">
                    <h1>Login</h1>
                    <h3>Two easy ways to login to the Jungler</h3>
                    <button className="close_btn--img" onClick={() => {
                      window.location.replace(process.env.SUBURBAN_HOST)
                    }}><img src="../img/login_close.svg" /></button>
                    < div className="product-dtl-tab-wrapper" >
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="product-dtl-tab-section">
                              <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#emailTab" onClick={() => {
                                  this.setState({ isEmailTab: true })
                                }} >Email</a></li>
                                <li><a data-toggle="tab" href="#phoneTab" onClick={() => {
                                  this.setState({ isEmailTab: false })
                                }}>Phone Number</a></li>
                              </ul>

                              <div className="tab-content">
                                <div id="emailTab" className="tab-pane fade in active">
                                  <div>
                                    <main className="clients__Messages--container">
                                      <div className="">
                                        <div className="mesgs">
                                          <div className="chatWithSbjTeam">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="outer-input">
                                                  <label className="labelinputs__global">EMAIL</label>
                                                  <input
                                                    className="textinputs__global"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your Email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange.bind(this)}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {this.state.err.email ?
                                              <span className="error_field">
                                                {this.state.err.email}
                                              </span> : ''}
                                            {this.state.magiclinkmsg ?
                                              <span className="success_field">
                                                {this.state.magiclinkmsg}
                                              </span> : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </div>


                                <div id="phoneTab" className="tab-pane fade">
                                  <div>
                                    <main className="clients__Messages--container">
                                      <div className="">
                                        <div className="mesgs">
                                          <div className="chatWithSbjTeam">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <label className="labelinputs__global">PHONE NUMBER</label>
                                                <div className="outer-input textinputs__global phonefull__input">
                                                  <PhoneInput
                                                    defaultCountry={'us'}
                                                    value={this.state.country_code}
                                                    autoFormat={false}
                                                    disableAreaCodes={true}
                                                    enableLongNumbers={false}
                                                    onChange={this.handleCodeChange.bind(this)}
                                                    buttonClass="flag__login"
                                                  />
                                                  <NumberFormat
                                                    format="(###) ### #### #####"
                                                    //placeholder="(000)  000 - 0000"
                                                    value={this.state.phoneNumber}
                                                    name='phoneNumber'
                                                    className="flagInputControlMainPhone"
                                                    onChange={this.handleChange.bind(this)}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {this.state.err.phoneNumber ?
                                              <span className="error_field">
                                                {this.state.err.phoneNumber}
                                              </span> : ''}
                                            {this.state.errorMessageOTP ?
                                              <span className="error_field">
                                                {this.state.errorMessageOTP}
                                              </span> : ''}


                                          </div>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </div>
                              </div>
                              <button type="submit" className="btn DefaultBtn login-submit" onClick={this.handleClick.bind(this)}>Sign In</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<span className="create-accoutn for-left"><Link to={`/signup`}>Create An Account?</Link></span>*/}
                  </div>






















                  {/*This is for main email component*/}
                  {/*<div className="login-box">
                    <h3 className="marg-btm-heading">Suburban Jungler Login</h3>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="outer-input">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter your Email"
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            />
                          <label>Email</label>
                        </div>
                      </div>
                    </div>
                    { this.state.err.email ?
                    <span className="error_field">
                    {this.state.err.email}
                    </span> : '' }
                    { this.state.magiclinkmsg ?
                    <span className="success_field">
                    {this.state.magiclinkmsg}
                    </span> : '' }
                    <button type="submit" className="btn DefaultBtn login-submit" onClick={this.handleClick.bind(this)}>Submit</button>
                    <span className="create-accoutn for-left"><Link to={`/signup`}>Create An Account?</Link></span>
                  </div>*/}
                  {/*This is for main email component*/}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'login',  // a unique identifier for this form
  destroyOnUnmount: true,
})(LoginForm)
