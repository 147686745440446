import { Record } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  towns: [],
  agents: [],
  suburbs: [],
  clientQuestion: {},
  recommendedTowns: [],
  clientStrategistTimeSheet: {},
  isTownRated: false,
  isAgentRated: false,
  approveRecommendedPhase: INIT,
  fetchClientQuestionPhase: INIT,
  fetchRankingByClientIdPhase: INIT,
  fetchClientRecommendedTownPhase: INIT,
  addClientStrategistTimeSheetPhase: INIT,
})

export const clientDetailReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /* fetch agnet and town ranking by client id */
    case types.FETCH_RANKING_BY_CLIENT_ID: {
      return state.set('error', null).set('fetchRankingByClientIdPhase', LOADING)
    }
    case types.FETCH_RANKING_BY_CLIENT_ID_SUCCESS: {
      const notes = get(action, 'payload', {})
      return state
        .set('error', null)
        .set('agents', notes.data.agents)
        .set('towns', notes.data.towns)
        .set('isTownRated', notes.isTownRated)
        .set('isAgentRated', notes.isAgentRated)
        .set('fetchRankingByClientIdPhase', SUCCESS)
    }
    case types.FETCH_RANKING_BY_CLIENT_ID_ERROR: {
      return state.set('error', action.payload.error).set('fetchRankingByClientIdPhase', ERROR)
    }

    /* fetch client question by user id to show on AI questioner */
    case types.FETCH_CLIENT_QUESTION_BY_USER_ID: {
      return state.set('error', null).set('fetchClientQuestionPhase', LOADING)
    }
    case types.FETCH_CLIENT_QUESTION_BY_USER_ID_SUCCESS: {
      const payload = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('clientQuestion', payload.question)
        .set('suburbs', payload.suburbs)
        .set('fetchClientQuestionPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_QUESTION_BY_USER_ID_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientQuestionPhase', ERROR)
    }

    /* fetch client AI recommended town */
    case types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS: {
      return state.set('error', null).set('fetchClientRecommendedTownPhase', LOADING)
    }
    case types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_SUCCESS: {
      const towns = get(action, 'payload.data', {})
      return state
        .set('error', null)
        .set('recommendedTowns', towns)
        .set('fetchClientRecommendedTownPhase', SUCCESS)
    }
    case types.FETCH_CLIENTS_AI_RECOMMENDED_TOWNS_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientRecommendedTownPhase', ERROR)
    }

    /* approve recommended ai town  */
    case types.APPROVE_RECOMMENDED_AI_TOWNS: {
      return state.set('error', null).set('approveRecommendedPhase', LOADING)
    }
    case types.APPROVE_RECOMMENDED_AI_TOWNS_SUCCESS: {
      return state.set('error', null).set('approveRecommendedPhase', SUCCESS)
    }
    case types.APPROVE_RECOMMENDED_AI_TOWNS_ERROR: {
      return state.set('error', action.payload.error).set('approveRecommendedPhase', ERROR)
    }

    /* approve recommended ai town  */
    case types.ADD_CLIENT_STRATEGIST_TIME_SHEET: {
      return state.set('error', null).set('addClientStrategistTimeSheetPhase', LOADING)
    }
    case types.ADD_CLIENT_STRATEGIST_TIME_SHEET_SUCCESS: {
      return state
        .set('error', null)
        .set('clientStrategistTimeSheet', action.payload.data)
        .set('addClientStrategistTimeSheetPhase', SUCCESS)
    }
    case types.ADD_CLIENT_STRATEGIST_TIME_SHEET_ERROR: {
      return state
        .set('error', action.payload.error)
        .set('addClientStrategistTimeSheetPhase', ERROR)
    }

    /* clear client details phase */
    case types.CLEAR_CLIENT_DETAIL_PHASE: {
      return state
        .set('error', null)
        .set('approveRecommendedPhase', INIT)
        .set('fetchClientQuestionPhase', INIT)
        .set('fetchRankingByClientIdPhase', INIT)
        .set('addClientStrategistTimeSheetPhase', INIT)
        .set('fetchClientRecommendedTownPhase', INIT)
    }

    default: {
      return state
    }
  }
}
