import { connect } from 'react-redux';

import TownCheatSheetComponent from './component'

import { getFilterParams, clearDashboardPhase } from '../../../../store/strategistV2/dashboard/actions';
import {
  clearTownPhase,
  fetchTownById,
  communityDataByTownId,
  fetchSchools,
  getResearch
} from '../../../../store/townReport/duck';
import { getClientsByTown } from '../../../../store/client/duck';

const TownCheatSheetContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    fetchTownByIdPhase: state.townReport.fetchTownByIdPhase,
    fetchTownByIdData: state.townReport.fetchTownByIdData,
    filterParamsPhase: state.strategistV2.dashboard.filterParamsPhase,
    communityPhase: state.townReport.communityPhase,
    communityData: state.townReport.communityData,
    getSchoolsPhase: state.townReport.getSchoolsPhase,
    schoolData: state.townReport.schoolData,
    townClients: state.client.townClients,
    townClientsPhase: state.client.townClientsPhase,
    getResearchPhase: state.townReport.getResearchPhase,
    getResearchData: state.townReport.getResearchData
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    clearFilterPhase: clearDashboardPhase,
    clearTownPhase,
    fetchTownById,
    communityDataByTownId,
    fetchSchools,
    getClientsByTown,
    getResearch
  }
)(TownCheatSheetComponent);

export default TownCheatSheetContainer;
