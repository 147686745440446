import 'rxjs'
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import TopNavigation from '../TopNavigation/container'
import SideNavigation from '../../components/sideNavigation'
import ViewTownReport from './partials/ViewTownReport'
import CreateTownReport from './partials/CreateTownReport'
import GenerateReport from './partials/GenerateReport'
import EditTownReport from './partials/EditTownReport'
import EditImages from './partials/EditImages'
import styles from './styles.scss'

class TownReport extends Component {
  componentWillMount() {
    const {} = this.props
  }

  componentDidMount(){
    document.title = "Jungler: Town Reports"
  }
  
  render() {
    const {
      location: { pathname }
    } = this.props
    return (
      <section className="town-report">
        {pathname === '/town-report' && (
          <div>
            <SideNavigation {...this.props} />
            <ViewTownReport {...this.props} />
          </div>
        )}
        {pathname === '/create-report' && <CreateTownReport {...this.props} />}
        {this.props.match.path === '/generate-report/:townId' && <GenerateReport {...this.props} />}
        {this.props.match.path === '/edit-report/community/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/images/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/private-school/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/public-school/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/commute/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/google-places/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-report/research/:townId' && <EditTownReport {...this.props} />}
        {this.props.match.path === '/edit-images/:townId' && <EditImages {...this.props} />}
        
      </section>
    )
  }
}

export default reduxForm({
  form: 'TownReport', // a unique identifier for this form
  destroyOnUnmount: true
})(TownReport)
