import { fetch } from '../../../../utils'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

/*
    ////////////////////////////
  /// follow up apis ///
////////////////////////////
*/
// fetch follow up dates list
export const fetchFollowUp = (data) => {

  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/follow-up-date`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// update follow up dates with stragey id and new date
export const updateFollowUp = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/follow-up-date`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
