import { fetch } from '../../../utils'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
/*
    /////////////////////////////////////
  /// leave comment apis ///
////////////////////////////////////
*/
// get leave comments with get comments
export const getLeaveComments = (data) => {
  return fetch(`${HOSTNAME_V2}/client/get_leave_comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// post comments for strategistes
export const addLeaveComments = (data) => {
  return fetch(`${HOSTNAME_V2}/client/add_leave_comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// post comments for strategistes
export const removeLeaveComment = (data) => {
  return fetch(`${HOSTNAME_V2}/client/delete_leave_comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
