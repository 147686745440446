import { connect } from 'react-redux';
import {
  loginUser,
  getNotifications,
  clearNotification
} from '../../../../../store/user/duck';
import {
  getClientList,
  addUpdateClient,
  clearPhase,
  impersonateUser
} from '../../../../../store/dashboard/duck';
import {
  fetchUser,
  getFilterParams,
} from '../../../../../store/strategistV2/dashboard/actions';
import {
  clearTownsPhase,
  updateSchoolOrder,
} from '../../../../../store/strategistV2/towns/actions';
import {
  clearTownPhase,
  repushToWordpress,
  fetchTownReport,
  generateReport,
  markFeatured,
  markFeaturedVideo,
  removeImage,
  removeVideo,
  getGeneratedReport,
  pushToWordpress,
  uploadImage,
  uploadVideo,
  editReport,
  communityDataByTownId,
  editCommunityData,
  editPrivateSchool,
  editPublicSchool,
  deletePrivateSchool,
  deletePublicSchool,
  addPrivateSchool,
  addPublicSchool,
  synchCommunity,
  addCommute,
  updateTownName,
  deleteTownById,
  addGooglePlace,
  editGooglePlace,
  addResearch,
  getResearch,
  deleteResearch,
  addResearchFile,
  fetchGooglePlace,
  fetchGooglePlaceData,
  fetchTownCharacteristics,
  updateTownCharacteristics,
  fetchCommute,
  fetchTownById,
  deleteCommute,
  updateImage,
  updateVideo,
  fetchSchools,
  getAttomSchools
} from '../../../../../store/townReport/duck';
import TownCharacteristicsComponent from './component';
const TownCharacteristicsContainer = connect(
  // Map state to props
  state => ({
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    user: state.strategistV2.dashboard.users,
    clientData: state.dashboard.clientData,
    isSubmitting: state.townReport.isSubmitting,
    error: state.dashboard.error,
    clearNotificationPhase: state.user.clearNotificationPhase,
    towns: state.townReport.towns,
    generateReportPayload: state.townReport.generateReport,
    disableBtn: state.townReport.disableBtn,
    editReportSuccess: state.townReport.editReportSuccess,
    report: state.townReport.report,
    imageUpload: state.townReport.imageUpload,
    videoUpload: state.townReport.videoUpload,
    uploadProgress: state.townReport.uploadProgress,
    featured: state.townReport.featured,
    pushToWp: state.townReport.pushToWp,
    removeImg: state.townReport.removeImage,
    removeVideo: state.townReport.removeVideo,
    townsCount: state.townReport.townsCount,
    suburbsData: state.townReport.suburbsData,
    communityPhase: state.townReport.communityPhase,
    communityData: state.townReport.communityData,
    editCommunityPhase: state.townReport.editCommunityPhase,
    editPrivatePhase: state.townReport.editPrivatePhase,
    editPublicPhase: state.townReport.editPublicPhase,
    deletePrivatePhase: state.townReport.deletePrivatePhase,
    deletePublicPhase: state.townReport.deletePublicPhase,
    addPrivatePhase: state.townReport.addPrivatePhase,
    addPublicPhase: state.townReport.addPublicPhase,
    synchCommunityPhase: state.townReport.synchCommunityPhase,
    addCommutePhase: state.townReport.addCommutePhase,
    updateTownPhase: state.townReport.updateTownPhase,
    townReportPhase: state.townReport.townReportPhase,
    updateTownData: state.townReport.updateTownData,
    addGooglePhase: state.townReport.addGooglePhase,
    addGoogleData: state.townReport.addGoogleData,
    editGooglePhase: state.townReport.editGooglePhase,
    editGoogleData: state.townReport.editGoogleData,
    deleteTownPhase: state.townReport.deleteTownPhase,
    addResearchPhase: state.townReport.addResearchPhase,
    addResearchData: state.townReport.addResearchData,
    getResearchData: state.townReport.getResearchData,
    deleteResearchPhase: state.townReport.deleteResearchPhase,
    researchFilePhase: state.townReport.researchFilePhase,
    researchFileData: state.townReport.researchFileData,
    getResearchPhase: state.townReport.getResearchPhase,
    removeImagePhase: state.townReport.removeImagePhase,
    removeVideoPhase: state.townReport.removeVideoPhase,
    getGooglePlacePhase: state.townReport.getGooglePlacePhase,
    googlePlace: state.townReport.googlePlace,
    imageUploadPhase: state.townReport.imageUploadPhase,
    videoUploadPhase: state.townReport.videoUploadPhase,
    getGeneratedReportPhase: state.townReport.getGeneratedReportPhase,
    getGooglePlaceDataPhase: state.townReport.getGooglePlaceDataPhase,
    googlePlaceData: state.townReport.googlePlaceData,
    fetchTownByIdData: state.townReport.fetchTownByIdData,
    fetchTownByIdPhase: state.townReport.fetchTownByIdPhase,
    townCharacteristics: state.townReport.townCharacteristics,
    fetchTownCharacteristicsPhase: state.townReport.fetchTownCharacteristicsPhase,
    updateTownCharacteristicsPhase: state.townReport.updateTownCharacteristicsPhase,
    fetchCommutePhase: state.townReport.fetchCommutePhase,
    commuteData: state.townReport.commuteData,
    deleteCommutePhase: state.townReport.deleteCommutePhase,
    getSchoolsPhase: state.townReport.getSchoolsPhase,
    schoolData: state.townReport.schoolData,
    getAttomSchoolsPhase: state.townReport.getAttomSchoolsPhase,
    attomSchoolData: state.townReport.attomSchoolData,
    addPublicData: state.townReport.addPublicData,
    updateSchoolTownPhase: state.strategistV2.town.updateSchoolTownPhase,
  }),
  // Map actions to props
  {
    loginUser,
    fetchUser,
    getClientList,
    fetchCommute,
    getFilterParams,
    addUpdateClient,
    clearPhase,
    impersonateUser,
    updateImage,
    updateVideo,
    getNotifications,
    clearNotification,
    fetchTownReport,
    generateReport,
    fetchTownById,
    clearTownPhase,
    getGeneratedReport,
    uploadImage,
    uploadVideo,
    editReport,
    markFeatured,
    markFeaturedVideo,
    pushToWordpress,
    removeImage,
    removeVideo,
    repushToWordpress,
    communityDataByTownId,
    editCommunityData,
    editPrivateSchool,
    editPublicSchool,
    deletePrivateSchool,
    deletePublicSchool,
    addPrivateSchool,
    addPublicSchool,
    synchCommunity,
    addCommute,
    updateTownName,
    deleteTownById,
    addGooglePlace,
    editGooglePlace,
    addResearch,
    getResearch,
    deleteResearch,
    addResearchFile,
    fetchGooglePlace,
    fetchGooglePlaceData,
    fetchTownCharacteristics,
    updateTownCharacteristics,
    deleteCommute,
    fetchSchools,
    getAttomSchools,
    clearTownsPhase,
    updateSchoolOrder,
  }
)(TownCharacteristicsComponent);
export default TownCharacteristicsContainer;
