import { connect } from 'react-redux'
import { loginUser, saveGoogleAuthdata } from '../../store/user/duck'
import UserGoogleAuthForm from './component'
const UserGoogleAuth = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    loginPhase: state.user.loginPhase,
    loginWpPhase:state.user.loginWpPhase,
    loginError: state.user.loginError,
    loginWpError: state.user.loginWpError  
  }),
  // Map actions to props
  {
    loginUser,
    saveGoogleAuthdata
  }
)(UserGoogleAuthForm)
export default UserGoogleAuth
