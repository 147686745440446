/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { Switch } from 'antd'
import { get } from 'lodash'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
// import io from 'socket.io-client'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import PhoneInput from 'react-phone-input-2'
import NumberFormat from 'react-number-format'
import 'react-phone-input-2/dist/style.css'

import Validation from '../../../../../config/validation'
import { BackArrowIcon, CloseIcon } from '../../../../../components/icons'
// import TopNavigation from '../../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../../components/AdministrationSidebarNavigation/component'

import './styles.scss'
import { SUCCESS } from '../../../../../constants/phase'

// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
// const socket = io.connect(SOCKET_HOSTNAME)

export default class EditClientInformationComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteClientModal: false,
      // is_send_thank_you_email: ''
    }
  }
  componentDidMount() {
    const clientId = this.props.match.params.id && this.props.match.params.id
    // eslint-disable-next-line camelcase
    this.props.fetchClientById({ clientId })
  }
  static getDerivedStateFromProps(props, state) {
    // if ((_.get(props.clientDetail, 'is_send_thank_you_email') !== state.is_send_thank_you_email) && state.is_send_thank_you_email === '') {
    //   return { is_send_thank_you_email: _.get(props.clientDetail, 'is_send_thank_you_email', true) }
    // }

    const clientId = props.match.params.id && props.match.params.id
    if (get(props, 'deleteClientdata.status', '') === true) {
      // this.setState({'msgDeleteClient': props.deleteClientdata.message})
      props.clearPhase()
      // eslint-disable-next-line camelcase
      // socket.emit('security_alert', { is_deleted: true, user_id: clientId })
      props.history.push(`/strategist/clients/all-clients`)
    } else if (get(props, 'deleteClientdata.status', '') === false) {
      // this.setState({'msgDeleteClient': props.deleteClientdata.message})
      props.clearPhase()
      const prevRoute = get(props, 'history.location.state.prevRoute', '')
      // replace previce path for avoid loop
      if (prevRoute && clientId) {
        props.history.push({
          pathname: `/strategist/client-details/${clientId}`,
          state: { prevRoute }
        })
      } else {
        props.history.goBack()
      }
    }
    if (props.editClientInformationPhase === SUCCESS) {
      props.clearClientPhase()
      const clientId = props.match.params.id
      const prevRoute = get(props, 'history.location.state.prevRoute', '')
      if (prevRoute && clientId) {
        props.history.push({
          pathname: `/strategist/client-details/${clientId}`,
          state: { prevRoute }
        })
      } else {
        props.history.goBack()
      }
    }
    return state
  }
  // handle switch for change form view
  handleSwitch = (event, name, setFieldValue) => setFieldValue(name, event)

  handleChange = ({ target }, setFieldValue) => {
    const { name, value } = event.target
    this.setState({ [name]: value.trim() })
    setFieldValue(name, value)
  }
  handleSubmit = (data) => {
    // let is_send_thank_you_email;
    // if(this.state.is_send_thank_you_email === true) {
    //   is_send_thank_you_email = "1"
    // } else {
    //   is_send_thank_you_email = "0"
    // }

    const secondaryContact = data.secondaryContact ? 
      data.secondaryContact.replace(/\D/g, '') :
      null

    const secondaryEmail = data.secondaryEmail ? 
      data.secondaryEmail.toLowerCase() :
      null

    const clientData = {
      ...data,
      // is_send_thank_you_email: is_send_thank_you_email,
      primaryEmail: data.primaryEmail.toLowerCase(),
      primaryContact: data.primaryContact.replace(/\D/g, ''),
      secondaryContact,
      secondaryEmail
    }
    this.props.editClientInformation(clientData)
  }

  // isSendThankYouEmail  = () => this.setState({ is_send_thank_you_email: !this.state.is_send_thank_you_email })

  toggleDeletClientModal = () =>
    this.setState({ deleteClientModal: !this.state.deleteClientModal })

  handleClickDelete = () => {
    const clientId = this.props.match.params.id && this.props.match.params.id
    // eslint-disable-next-line camelcase
    this.props.deleteClient({ client_id: clientId }, () =>
      this.setState({ deleteClientModal: false })
    )
  }
  // format number with coutnry code
  formatNumber = (contactNumber) => {
    const trimContact = `${contactNumber} `.replace(/\D/g, '')
    const matched = trimContact.match(/^(\d{3})(\d{3})(\d*)$/)
    if (matched) {
      return `(${matched[1]}) ${matched[2]} - ${matched[3]}`
    }
    return ''
  }
  render() {
    const user = get(this.props, 'user', {})
    const client = get(this.props, 'clientDetail', {})
    const clientId = this.props.match.params.id && this.props.match.params.id;
    const primaryContact = this.formatNumber(get(client, "phone_mobile", ""))
    const secondaryContact = this.formatNumber(get(client, "secondary_phone_email", "")) 
    const initialStratyCallData = get(client, 'initialStratyCallData', {})
    const isInitialStratyCallData = initialStratyCallData.length
    
    const role = get(user, 'role', '')
    const showDeleteUserButton = role.includes('admin')

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <Link to={{
                      pathname: `/strategist/client-details/${clientId}`,
                      state: { prevRoute: get(this.props, 'history.location.state.prevRoute', '')}
                      }} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back</Link>
                  </div>
                  <div className="col-xs-6 col-md-8 text-right">

                  </div>
                </div>
              </div>
              <div className="row d__flex align__items__center mb__25">
                <div className="col-xs-6 col-md-4">
                  <div className="">
                    <h2 className="title__section mb__0">Edit {get(client, "client_full_name", "")}</h2>
                  </div>
                </div>
                <div className="col-xs-6 col-md-8 text-right">
                  {showDeleteUserButton &&
                    <div className="">
                      <button className="btn btn__btn btn-danger w__180" onClick={this.toggleDeletClientModal} >Delete User</button>
                    </div>
                  }
                </div>
              </div>
              {/* {(user && user.role === "strategist" && user.is_send_thank_you_email === 1 && isInitialStratyCallData > 0) &&
                <div className="row d__flex align__items__center mb__25">
                  <div className="col-xs-6 col-md-4">
                  </div>
                  <div className="col-xs-6 col-md-8 text-right">
                    <div className="client__details__header__cta">
                      <div className="client__details__header__cta__inline">
                        <div className="agent-yesno" style={{ paddingTop: "10px" }}>
                          Thank You automated email send? &nbsp;
                            <span className="toggle__btn">
                            No
                              <label className="switch agent_switch">
                              <input
                                type="checkbox"
                                onChange={(e) => this.isSendThankYouEmail(e)}
                                checked={this.state.is_send_thank_you_email}
                              ></input>
                              <span className="slider round"></span>
                            </label>
                              Yes
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              } */}
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      // initial values
                      clientId,
                      primaryFirstName: get(client, "client_first_name", ""),
                      primaryLastName: get(client, "client_last_name", ""),
                      primaryEmail: get(client, "client_email", ""),
                      primaryCountryCode: get(client, "country_code", "+1"),
                      primaryContact: primaryContact,
                      primaryCompany: get(client, "primary_company", "NO CLIENT PRIMARY COMPANY"),
                      secondaryLastName: get(client, "secondary_last_name", ""),
                      secondaryFirstName: get(client, "secondary_first_name", ""),
                      secondaryEmail: get(client, "secondary_email", ""),
                      secondaryContact: secondaryContact,
                      secondaryCountryCode: get(client, "secondary_country_code", "+1"),
                      secondaryCompany: get(client, "secondary_company", "NO CLIENT SECONDARY COMPANY"),
                      source: get(client, "source", ""),
                      makeClientLocal: get(client, "make_client_local", false)
                    }}
                    validationSchema={Validation.editClientContactInformation}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true)
                      this.handleSubmit(values)
                    }}
                  >
                    {({
                      values,
                      errors,
                      isSubmitting,
                      setFieldValue
                    }) => (
                        <Form noValidate>
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="primaryFirstName"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">First Name (Primary Contact)<span className="text-danger">*</span></label>
                                    <ErrorMessage className="invalid-feedback" name="primaryFirstName" component="div" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="primaryLastName"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Last Name  (Primary Contact) <span className="text-danger">*</span></label>
                                    <ErrorMessage className="invalid-feedback" name="primaryLastName" component="div" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="primaryEmail"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Email  (Primary Contact) <span className="text-danger">*</span></label>
                                    <ErrorMessage className="invalid-feedback" name="primaryEmail" component="div" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <PhoneInput
                                      defaultCountry={'us'}
                                      value={values.primaryCountryCode}
                                      autoFormat={false}
                                      disableAreaCodes={true}
                                      enableLongNumbers={false}
                                      onChange={(event) => setFieldValue('primaryCountryCode', `+${event}`)}
                                      buttonClass="flag-textfield"
                                      required
                                    />
                                    <NumberFormat
                                      format="(###) ### #### #####"
                                      value={values.primaryContact}
                                      name="primaryContact"
                                      className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                      onChange={(event) => this.handleChange(event, setFieldValue)}
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Phone Number (Primary Contact) <span className="text-danger">*</span></label>
                                    <ErrorMessage className="invalid-feedback" name="primaryContact" component="div" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="secondaryFirstName"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">First Name (Secondary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="secondaryFirstName" component="div" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="secondaryLastName"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Last Name (Secondary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="secondaryLastName" component="div" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="secondaryEmail"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Email (Secondary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="secondaryEmail" component="div" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <PhoneInput
                                      defaultCountry={'us'}
                                      value={values.secondaryCountryCode}
                                      autoFormat={false}
                                      disableAreaCodes={true}
                                      enableLongNumbers={false}
                                      onChange={(event) => setFieldValue('secondaryCountryCode', `+${event}`)}
                                      buttonClass="flag-textfield"
                                      required
                                    />
                                    <NumberFormat
                                      format="(###) ### #### #####"
                                      value={values.secondaryContact}
                                      name="secondaryContact"
                                      className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                      onChange={(event) => this.handleChange(event, setFieldValue)}
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Phone Number (Secondary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="secondaryContact" component="div" />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="primaryCompany"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Company (Primary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="primaryCompany" component="div" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <Field
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      name="secondaryCompany"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Company (Secondary Contact)</label>
                                    <ErrorMessage className="invalid-feedback" name="secondaryCompany" component="div" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group material-textfield">
                                <Field
                                  type="textarea"
                                  r
                                  className="form-control material-textfield-input textfield-input-lg"
                                  name="source"
                                  required
                                />
                                <label className="material-textfield-label label-lg">Source</label>
                                <ErrorMessage className="invalid-feedback" name="source" component="div" />
                              </div>


{/*                               <div className="status__switch form-group">
                                <span className="status__switch__text">Make Client Local </span>
                                <Switch defaultChecked={values.makeClientLocal}
                                  onChange={(event) => this.handleSwitch(event, 'makeClientLocal', setFieldValue)}
                                  name="makeClientLocal"
                                />
                                {values.makeClientLocal ? <span className="status yes">Yes</span>
                                : <span className="status">No</span>}
                              </div> */}
                              <button className="btn btn__btn btn-dark btn__lg w__100" type="submit" disabled={isSubmitting}>Save</button>
                            </div>
                          </div>
                        </Form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </main>
        </div>
        <ReactModal
          isOpen={this.state.deleteClientModal}
          contentLabel="Delete User"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button
                type="button"
                className="btn react-modal-close react-modal-close-sm"
                onClick={this.toggleDeletClientModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">You want to delete this client? This action remove client and its details. Once deleted can't recoverd.</p>
              <div className="text-right">
                <button onClick={this.handleClickDelete} className="btn btn__btn btn-dark w__150">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}
