import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Config from '../../../../../../config';
import Cookies from 'universal-cookie'
import DatePicker from '../../../../../../components/datePicker'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class ClientsAssignedToAgentsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      assignToAgentTableData: [],
      fromDate: moment().add(-1, 'month').startOf('month'),
      endDate: moment(),
      type: 'assignToAgent',
      sortByKey:'name',
      sortByType: 0,
      selectedIndex: 'all',
      search:'',
      commonOpenModal: false,
      commonArray: [],
      commonSubject: '',
      showAll: true,
      commonAgentName: '',
      commonValue: '',
      todayDate: moment().format('LL')
    }
  }

  componentDidMount(){
    this.setState({isLoadingData: true},()=> {})
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      // const strData = {
      //   from : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   to   : moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss"),
      //   type : this.state.type,
      //   sortBy:  {key: this.state.sortByKey, type: this.state.sortByType}
      // }
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        strData.type = this.state.type
        strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      }
      this.props.getClientsPerAgent(strData)
    }
  }

  componentDidUpdate(preProps, preState){
    const { clientsPerAgentsPhase, clientsPerAgentsData, clearReportV2Phase } = this.props
    if(clientsPerAgentsPhase === 'success'){
      clearReportV2Phase()
      let data = []
      if(_.size(clientsPerAgentsData) > 0){
        clientsPerAgentsData && clientsPerAgentsData.map((aData, i)=>{
          data.push({
            key: i,
            agent_id: aData.agent_id,
            name: aData.name,
            totalAgentTownTour: aData.totalAgentTownTour,
            totalClient: aData.totalClient,
            totalInitialCall: aData.totalInitialCall,
            totalStrategist: aData.totalStrategist,
            totalTag: aData.totalTag,
            totalTown: aData.totalTown,
            exportTotalClient: aData.totalClient && aData.totalClient.length > 0 ? aData.totalClient.map(e => { return e.client_name}).join(", ") : "",
            exportTotalTag: aData.totalTag.length > 0 ? aData.totalTag.map(e => { return e }).join(", ") : "",
            exportTotalTown: aData.totalTown.length > 0 ?  aData.totalTown.map(e => { return e.name}).join(", ") : "",
            exportTotalStrategist: aData.totalStrategist.length > 0 ? aData.totalStrategist.map(e => { return e.strategist_name}).join(", ") : "",
            exportTotalInitialCall: aData.totalInitialCall.length > 0 ? aData.totalInitialCall.map(e => { return e.client_name }).join(", ") : "",
            exportTotalAgentTownTour: aData.totalAgentTownTour.length > 0 ? aData.totalAgentTownTour.map(e => { return e.client_name }).join(", ") : ""
            })
        })
      }
      this.setState({ assignToAgentTableData: data, isLoading: false })
    }
    if(clientsPerAgentsPhase === "error"){
      clearReportV2Phase()
      this.setState({ assignToAgentTableData: [], isLoading: false })
    }
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  commonOpenClick(agent, val, e){
    let sub = ''
    let commonArr = []
    if(val === "client"){
      commonArr = agent && agent.totalClient
      sub = "client"
    }else if(val === "agentCall"){
      commonArr = agent && agent.totalInitialCall
      sub = "client"
    }else if(val === "agentVisit"){
      commonArr = agent && agent.totalAgentTownTour
      sub = "client"
    }else if(val === "tag"){
      const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
      commonArr = tags[0]
      sub = "tag"
    }else if(val === "towns"){
      commonArr = agent && agent.totalTown
      sub = "town"
    }else if(val === "strategist"){
      commonArr = agent && agent.totalStrategist
      sub = "strategist"
    }
    if(_.size(commonArr) > 1){
      this.setState({ commonAgentName: agent.name, commonOpenModal: true, commonArray: commonArr, commonSubject: sub, commonValue: val })
    }
  }

  commonClose(){
    this.setState({ commonAgentName: '', commonOpenModal: false, commonArray: [], commonSubject: '', commonValue: '' })
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val })
    const strData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to  :moment(to).format("YYYY-MM-DD HH:mm:ss"),
      type: this.state.type
    }
    this.props.getClientsPerAgent(strData)
  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { assignToAgentTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let filterList = assignToAgentTableData
    if(_.size(assignToAgentTableData) > 0){
      filterList = assignToAgentTableData.filter(
        (agent) =>{
          return (
            (agent.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Clients Assigned To Agents</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={filterList} name="clients_assigned_to_agents">
                          <ExcelColumn label="Agent Name" value="name"/>
                          <ExcelColumn label="Name Of Clients" value="exportTotalClient"/>
                          <ExcelColumn label="Had Initial or Follow up Agent Call" value="exportTotalInitialCall"/>
                          <ExcelColumn label="Had Initial or Follow up agent visit" value="exportTotalAgentTownTour"/>
                          <ExcelColumn label="Tag" value="exportTotalTag"/>
                          <ExcelColumn label="Towns" value="exportTotalTown"/>
                          <ExcelColumn label="Strategist" value="exportTotalStrategist"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by agent name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(filterList) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Agent Name',
                              width: 250,
                              dataIndex: 'name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                              title: '	Name Of Client',
                              width: 260,
                              dataIndex: 'clientName',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalClient.localeCompare(b.exportTotalClient),
                              render: (text, str) => {
                                const totalClient = str && str.totalClient
                                let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                                return(
                                  <div className={(totalClient.length > 1 ? "link cursor__pointer" : '')} onClick={this.commonOpenClick.bind(this, str, "client")}>{( totalClient.length >= 1 ? totalClient[0].client_name+ clientNo : 'No Client' ) }</div>
                                )
                              }
                            },
                            {
                              title: 'Had Initial or Follow up Agent Call',
                              dataIndex: 'hadInitialAgentCall',
                              key: '3',
                              width: 320,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalInitialCall.localeCompare(b.exportTotalInitialCall),
                              render: (text, str) => {
                                const totalInitialCall = str && str.totalInitialCall
                                let callNo = (totalInitialCall.length-1) <= 0 ? '' : " (+"+(totalInitialCall.length-1)+")"
                                return(
                                  <div className={(totalInitialCall.length > 1 ? "link cursor__pointer" : '')} onClick={this.commonOpenClick.bind(this, str, "agentCall")}>{( totalInitialCall.length >= 1 ? totalInitialCall[0].client_name+ callNo : 'No Client' ) }</div>
                                )
                              }
                            },
                            {
                              title: 'Had Initial or Follow up agent visit',
                              dataIndex: 'hadInitialAgentVisit',
                              key: '4',
                              width: 320,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalAgentTownTour.localeCompare(b.exportTotalAgentTownTour),
                              render: (text, str) => {
                                const totalAgentTownTour = str && str.totalAgentTownTour
                                let tourNo = (totalAgentTownTour.length-1) <= 0 ? '' : " (+"+(totalAgentTownTour.length-1)+")"
                                return(
                                  <div className={(totalAgentTownTour.length > 1 ? "link cursor__pointer" : '')} onClick={this.commonOpenClick.bind(this, str, "agentVisit")}>{( totalAgentTownTour.length >= 1 ? totalAgentTownTour[0].client_name+ tourNo : 'No Client' ) }</div>
                                )
                              }
                            },
                            {
                              title: 'Tag',
                              dataIndex: 'tag',
                              key: '5',
                              width: 330,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalTag.localeCompare(b.exportTotalTag),
                              render: (text, str) => {
                                const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                                const tagArr = tags[0]
                                let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                                return(
                                  <div className={(tagArr.length > 1 ? "link cursor__pointer" : '')} onClick={this.commonOpenClick.bind(this, str, "tag")}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div>
                                )
                              }
                            },
                            {
                              title: 'Towns',
                              dataIndex: 'towns',
                              key: '6',
                              width: 260,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalTown.localeCompare(b.exportTotalTown),
                              render: (text, str) => {
                                const totalTown = str && str.totalTown
                                let townNo = (totalTown.length-1) <= 0 ? '' : " (+"+(totalTown.length-1)+")"
                                return(
                                  <div className={(totalTown.length > 1 ? "link cursor__pointer" : '')} onClick={this.commonOpenClick.bind(this, str, "towns")}>{(totalTown.length >= 1 ? totalTown[0].name+ townNo : 'No Town')}</div>
                                )
                              }
                            },
                            {
                              title: 'Strategist',
                              dataIndex: 'strategist',
                              key: '7',
                              width: 300,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend', 'descend'],
                              sorter: (a, b) => a.exportTotalStrategist.localeCompare(b.exportTotalStrategist),
                              render: (text, str) => {
                                const totalStrategist = str && str.totalStrategist
                                let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                                return(
                                  <div className={(totalStrategist.length > 1 ? "link cursor__pointer" : '')}onClick={this.commonOpenClick.bind(this, str, "strategist")}>{(totalStrategist.length >= 1 ?totalStrategist[0].strategist_name+ strategistNo : 'No Strategist') }</div>
                                )
                              }
                            },
                          ]}
                          dataSource={filterList}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }

              </Spin>
            </div>
          </main>
        </div>

        {/* This is common modal*/}
        <ReactModal
         isOpen={this.state.commonOpenModal}
         contentLabel="Report"
         portalClassName="react-modal"
         >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {`Agent - ${this.state.commonAgentName}'s ${this.state.commonSubject}(s)`}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.commonClose.bind(this)}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="info__block">
                {
                  (this.state.commonValue === "client" || this.state.commonValue === "agentCall" || this.state.commonValue === "agentVisit") &&
                  this.state.commonArray && this.state.commonArray.map((client, index) => {
                    return <p key={'client-name-'+index}>{client.client_name}</p>
                  })
                }
                {
                  (this.state.commonValue === "tag") &&
                  this.state.commonArray && this.state.commonArray.map((tag, index) => {
                    return <p key={'tag-name-'+index}>{tag}</p>
                  })
                }
                {
                  (this.state.commonValue === "towns") &&
                  this.state.commonArray && this.state.commonArray.map((town, index) => {
                    return <p key={'tag-name-'+index}>{town.name}</p>
                  })
                }
                {
                  (this.state.commonValue === "strategist") &&
                  this.state.commonArray && this.state.commonArray.map((strategist, index) => {
                    return <p key={'strategist-name-'+index}>{strategist.strategist_name} - Clients {strategist.clients}</p>
                  })
                }
              </div>
              <div>
              </div>
            </div>
          </div>
        </ReactModal>

      </div>
    )
  }
}
