import { connect } from 'react-redux'
import { createUserCheck, handleSignOut} from '../../store/user/duck'
import SignupForm from './component'
const Signup = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    createUserCheckPhase: state.user.createUserCheckPhase,
    createUserCheckError: state.user.createUserCheckError
  }),
  // Map actions to props
  {
    createUserCheck,
    handleSignOut
  }
)(SignupForm)
export default Signup
