import { connect } from 'react-redux'
import {fetchUser, handleSignOut, getNotifications, clearNotification} from '../../store/user/duck'
import { agentGetUsers, 
        updateClientUser,
        getAppointments,
        getAgentAvailability,
        clearPhase,
        agentSearchGetUsers,
        clientNotTouch4W,
        clientNotTouch,
        addAgentAppointment } from '../../store/agent/duck'
import AgentForm from './component'
const Agent = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    saveGoogleDataPhase: state.user.saveGoogleDataPhase,
    rxError: state.user.error,
    phase: state.user.phase,
    userPhase: state.agent.userPhase,
    usersData: state.agent.usersData,
    availabilityData: state.agent.availabilityData,
    addAppointmentPhase: state.agent.addAppointmentPhase,
    agentSearchGetUsersData: state.agent.agentSearchGetUsersData,
    searchUserPhase: state.agent.searchUserPhase,
    notificationPhase: state.user.notificationPhase,
    notificationData: state.user.notificationData,
    clearNotificationPhase: state.user.clearNotificationPhase,
    neverTouchIn4Phase: state.agent.neverTouchIn4Phase,
    neverTouchIn4Data: state.agent.neverTouchIn4Data,
    notTouchPhase: state.agent.notTouchPhase,
    notTouchData: state.agent.notTouchData
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    updateClientUser,
    getAppointments,
    getAgentAvailability,
    addAgentAppointment,
    agentGetUsers,
    getNotifications,
    clearNotification,
    agentSearchGetUsers,
    clearPhase,
    clientNotTouch4W,
    clientNotTouch
  }
)(AgentForm)
export default Agent
