import "rxjs";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import styles from "./styles.scss";
import $ from "jquery";
import _ from 'lodash'
import Select from "react-select";
import Cookies from "universal-cookie";
import ReactDOM from "react-dom";
import Modal from 'react-responsive-modal';
import Modal2 from 'react-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => (value ? undefined : "Required");
const cookies = new Cookies();
const HOSTNAME = process.env.API_HOSTNAME;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class NotTouchedI4Week extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suburbsFilter: false,
      isLoadingData: false,
      suburb_list: [],
      suburbDropdown: false,
      notTouchedIn4WeekDataExport:[],
      sortByKey:'name',
      sortByType: 0,
      agentName:'',
      openClient:false,
      currentAgentClients:[],
      openTag:false,
      currentAgentTags:[],
      openStr:false,
      currentAgentStrategist:[],
      search: ''
    }
  }

  componentWillMount() {
    let _user = cookies.get("user");
    if (_user) {
      this.props.getFilterParams()
      this.setState({ isLoadingData: true, userRole: _user.role })
      const data = {}
      data.suburb_arr = []
      data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getNotTouchedIn4WeekData(data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notTouchedIn4WeekData !== this.props.notTouchedIn4WeekData) {
      const notTouchedIn4Week = []
      if(nextProps.notTouchedIn4WeekData && nextProps.notTouchedIn4WeekData.length > 0){
        nextProps.notTouchedIn4WeekData.map(function(data){
          let obj ={}
          obj.name = data.name
          obj.totalClient =  data.totalClient.length 
          obj.clients =  data.totalClient.length > 0 ? data.totalClient.map(e => { return e.client_name}).join(" ,") : ""
          obj.totalTag = data.totalTag.length > 0 ? data.totalTag.map(e => { return e }).join(" ,") : ""
          obj.totalStrategist = data.totalStrategist.length > 0 ? data.totalStrategist.map(e => { return e.strategist_name}).join(" ,") : ""
          obj.totalAssignClients = data.totalAssignClients
          obj.percentage = data.percentage
          notTouchedIn4Week.push(obj)
        })
        this.setState({ isLoadingData: false, notTouchedIn4WeekDataExport:notTouchedIn4Week },()=> {})
        // this.props.clearPhase()
      }else{
        this.setState({ isLoadingData: false, notTouchedIn4WeekDataExport:notTouchedIn4Week },()=> {})
        // this.props.clearPhase()
      }
    }
  }
  

  componentDidMount(){
    let self = this
    // $(document).click(function(event) { 
    //   if(!$(event.target).closest('#dropdownBtn').length) {
    //     if($('#dropdownBtn').is(":visible")) {
    //       self.setState({suburbDropdown : false})
    //     }
    //   }       
    // })
  }

  toggledropDown(val) {
    if(val==='filterSuburb'){
      this.setState({ suburbDropdown: !this.state.suburbDropdown })
    }
  }

  filterStrategist(id, type){
    const { suburb_list } = this.state
    if(type === 'suburbFilter'){
      if (suburb_list.indexOf(id) === -1) {
        suburb_list.push(id)
      } 
      else {
        suburb_list.splice(suburb_list.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, suburb_list : suburb_list })
    const data = {}
    data.suburb_arr = suburb_list
    data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    this.props.getNotTouchedIn4WeekData(data)
  }

  onOpenClientModal(agent) {
    const clients = agent && agent.totalClient
    if(clients.length > 1){

      $( "body" ).addClass( "overflow_visible" )
      this.setState({ agentName: agent.name, openClient: true, currentAgentClients: clients })
    }
  }

  onCloseClientModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ agentName: '', currentAgentClients: [], openClient: false })
  }

  onOpenModal(agent) {
    const tags = agent && agent.totalTag && agent.totalTag.indexOf(',') !== -1 ? agent.totalTag.split(',') : [agent.totalTag]
    if(tags[0].length > 1){
      $( "body" ).addClass( "overflow_visible" )
      this.setState({ agentName: agent.name, openTag: true, currentAgentTags: tags[0] })
    }
  }

  onCloseModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ agentName: '', currentAgentTags: [], openTag: false })
  }

  onSearch(e) {
    this.setState({ search: e.target.value })
  }

  onOpenStrategistModal(agent) {
    const strategists = agent && agent.totalStrategist
    if(strategists.length > 1){
      $( "body" ).addClass( "overflow_visible" )
      this.setState({ agentName: agent.name, openStr: true, currentAgentStrategist: strategists })
    } 
  }

  onCloseStrategistModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ agentName: '', currentAgentStrategist: [], openStr: false })
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }else{
      sortByType == 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const data = {}
    data.suburb_arr = this.state.suburb_list
    data.sortBy = { key: val, type: sortByType }
    this.props.getNotTouchedIn4WeekData(data)
  }

  render() {
    const { notTouchedIn4WeekDataExport, openClient, openTag, openStr } = this.state
    const { filterParams, notTouchedIn4WeekData } = this.props
    // let allData = filterParams && filterParams && filterParams.data

    let filterList = []
    let allSuburbs = filterParams && filterParams.data && filterParams.data.suburbs;
    if(allSuburbs){
      filterList = allSuburbs
      .filter(
        (allSuburbs) =>{
          return (
            (allSuburbs.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Not Touched by Agent in 4 Weeks Statistics</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12">
              <h4 className="stragist-heading">
                <span>{notTouchedIn4WeekData.length}</span> Agents
              </h4>
            </div>

            <div className="col-md-12">
            <div className="reports__flex">

              
              
              <div className="right__cont right__cont--full">

              <span className="spanclsass">
              {
                (this.state.userRole ==='superadmin' || this.state.userRole === 'admin') &&
                <div 
                  // className="strategist-search strategist-search-float" 
                  onClick={this.toggledropDown.bind(this, 'filterSuburb')}>
                  <input 
                    onChange={(e)=> this.onSearch(e)}
                    type="text"  
                    className="report__search--global width_300"
                    placeholder="Search Suburbs Name"/>
                </div>
              }
                { (this.state.userRole ==='superadmin' || this.state.userRole === 'admin') &&
                    this.state.suburbDropdown && 
                    <div id="dropdownBtn" className= "sel-outer selouter__not--touch">
                      {filterList && filterList.map((sub, subIndex) => {
                        let selectedIndex = false;
                          _.find(this.state.suburb_list, function(o) {
                            if(o === sub.id){
                              selectedIndex = true;
                            } 
                        });
                        return (
                          <li key={subIndex} className="custom-select-option">
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {sub.name}
                              <input 
                                type="checkbox" 
                                checked = {selectedIndex} 
                                className="custom-select-option-checkbox" 
                                onClick={this.filterStrategist.bind(this,sub.id, 'suburbFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                        )
                      })}
                    </div>
                }
              </span>

              <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
                <ExcelSheet data={notTouchedIn4WeekDataExport} name="Not_Touch_In_Four_Week_Report">
                  <ExcelColumn label="Agent Name" value="name" />
                  <ExcelColumn label="Total Clients Not Touched by Agent in 4 Weeks" value="totalClient" />
                  <ExcelColumn label="Total Clients Assigned" value="totalAssignClients" />
                  <ExcelColumn label="% Of Clients Not Touched by Agent in 4 Weeks" value="percentage" />
                  <ExcelColumn label="Client Name" value="clients" />
                  <ExcelColumn label="Tag" value="totalTag" />
                  <ExcelColumn label="Strategist Name" value="totalStrategist" />
                </ExcelSheet>
              </ExcelFile>
            </div>
            </div>
            </div>




          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <main className="jungler__page--container">
                <div className="jungler__list useslist__extra">
                  <div className="table-responsive">
                    {this.state.isLoadingData === true ? (
                      <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td className="noClientResults text-left">
                            <img src="../../img/loader2.svg" />
                            <h5>Your Not Touched by Agent in 4 Weeks report is generating, please wait.........</h5>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                      ) : (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th onClick={this.sortData.bind(this , 'name')} >Agent Name</th>
                            <th className="text-left multilines tdwidth250" onClick={this.sortData.bind(this , 'clients')} >Total Clients Not Touched by Agent in 4 Weeks</th>
                            <th onClick={this.sortData.bind(this , 'total_client_assign')} className="text-left multilines">Total Clients Assigned</th>
                            <th onClick={this.sortData.bind(this , 'percentage')} className="text-left multilines tdwidth250">% Of Clients Not Touched by Agent in 4 Weeks</th>
                            <th className="text-left multilines">Client Name</th>
                            <th className="text-left multilines">Tag</th>
                            <th className="text-left multilines">Strategist Name</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {
                          notTouchedIn4WeekData && notTouchedIn4WeekData.map((str, index)=>{
                            const totalClient = str && str.totalClient
                            const totalStrategist = str && str.totalStrategist
                            const tags = str && str.totalTag.indexOf(',') !== -1 ? str.totalTag.split(',') : [str.totalTag]
                            const tagArr = tags[0]
                            let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                            let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                            let tagNo = (tagArr.length-1) <= 0 ? '' : " (+"+(tagArr.length-1)+")"
                            return(
                              <tr key={index} className="forcursor" >
                                <td>{str.name}</td>
                                <td>{totalClient.length}</td>
                                <td>{str.totalAssignClients}</td>
                                <td>{str.percentage}</td>
                                <td className="wrap-text multilines"><div className={(totalClient.length > 1 ? "link" : '')} onClick={this.onOpenClientModal.bind(this, str)}>{( totalClient.length >= 1 ? totalClient[0].client_name+ clientNo : 'No Client' ) }</div></td>
                                <td className="wrap-text multilines"><div className={(tagArr.length > 1 ? "link" : '')} onClick={this.onOpenModal.bind(this, str)}>{(tagArr.length >= 1 ? tagArr[0]+ tagNo : 'No Tag')}</div></td>
                                <td className="wrap-text multilines"><div className={(totalStrategist.length > 1 ? "link" : '')} onClick={this.onOpenStrategistModal.bind(this, str)}>{(totalStrategist.length >= 1 ?totalStrategist[0].strategist_name+ strategistNo : 'No Strategist') }</div></td>
                                
                              </tr>
                            )
                          })
                        }
                        </tbody>
                          <div>
                            <Modal2 isOpen={openClient} center className="widthofmodal">
                              <div>
                                <div className="flexdiv">
                                  <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' client(s)'}</h4>
                                  <div className="close__btn close_img--white" onClick={this.onCloseClientModal.bind(this)}><img src="img/close_btn.svg"/></div>
                                </div>
                                <div className="allpoppadding__paranew__div">
                                  {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                                    return <div className="allpoppadding__paranew" key={'client-name-'+index}>{client.client_name}</div>;
                                  })}
                                </div>
                              </div>
                              <button className="savebtn__assign" onClick={this.onCloseClientModal.bind(this)}>Close</button>
                            </Modal2>
                          </div>
                          <div>
                            <Modal2 isOpen={openTag} center className="widthofmodal">
                              <div>
                                <div className="flexdiv">
                                  <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' tag(s)'}</h4>
                                  <div className="close__btn close_img--white" onClick={this.onCloseModal.bind(this)}><img src="img/close_btn.svg"/></div>
                                </div>
                                <div className="allpoppadding__paranew__div">
                                  {this.state.currentAgentTags && this.state.currentAgentTags.map((tag, index) => {
                                    return <div className="allpoppadding__paranew" key={'tag-name-'+index}>{tag}</div>;
                                  })}
                                </div>
                              </div>
                              <button className="savebtn__assign" onClick={this.onCloseModal.bind(this)}>Close</button>
                            </Modal2>
                          </div>
                          <div>
                            <Modal2 isOpen={openStr} center className="widthofmodal">
                              <div>
                                <div className="flexdiv">
                                  <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' strategist(s)'}</h4>
                                  <div className="close__btn close_img--white" onClick={this.onCloseStrategistModal.bind(this)}><img src="img/close_btn.svg"/></div>
                                </div>
                                <div className="allpoppadding__paranew__div">
                                  {this.state.currentAgentStrategist && this.state.currentAgentStrategist.map((strategist, index) => {
                                    return <div className="allpoppadding__paranew" key={'strategist-name-'+index}>{strategist.strategist_name} - Clients {strategist.clients}</div>;
                                  })}
                                </div>
                              </div>
                              <button className="savebtn__assign" onClick={this.onCloseStrategistModal.bind(this)}>Close</button>
                            </Modal2>
                          </div>
                        </table>
                      )}
                  </div>
                </div>
              </main>
              
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "NotTouchedI4Week", // a unique identifier for this form
  destroyOnUnmount: true
})(NotTouchedI4Week);
