export const FETCH_APPOINTMENT_LIST = 'suburban/strategistV2/appointment/FETCH_APPOINTMENT_LIST'
export const FETCH_APPOINTMENT_LIST_SUCCESS =
  'suburban/strategistV2/appointment/FETCH_APPOINTMENT_LIST_SUCCESS'
export const FETCH_APPOINTMENT_LIST_ERROR =
  'suburban/strategistV2/appointment/FETCH_APPOINTMENT_LIST_ERROR'

export const FETCH_STRATEGIST_AVAILABILITY =
  'suburban/strategistV2/appointment/FETCH_STRATEGIST_AVAILABILITY_FOR_APPOINTMENT'
export const FETCH_STRATEGIST_AVAILABILITY_SUCCESS =
  'suburban/strategistV2/appointment/FETCH_STRATEGIST_AVAILABILITY_FOR_APPOINTMENT_SUCCESS'
export const FETCH_STRATEGIST_AVAILABILITY_ERROR =
  'suburban/strategistV2/appointment/FETCH_STRATEGIST_AVAILABILITY_FOR_APPOINTMENT_ERROR'

export const FETCH_AGENT_AVAILABILITY =
  'suburban/strategistV2/appointment/FETCH_AGENT_AVAILABILITY_FOR_APPOINTMENT'
export const FETCH_AGENT_AVAILABILITY_SUCCESS =
  'suburban/strategistV2/appointment/FETCH_AGENT_AVAILABILITY_FOR_APPOINTMENT_SUCCESS'
export const FETCH_AGENT_AVAILABILITY_ERROR =
  'suburban/strategistV2/appointment/FETCH_AGENT_AVAILABILITY_FOR_APPOINTMENT_ERROR'

export const CREATE_AGENT_APPOINTMENT =
  'suburban/strategistV2/appointment/CREATE_AGENT_APPOINTMENT_FOR_APPOINTMENT'
export const CREATE_AGENT_APPOINTMENT_SUCCESS =
  'suburban/strategistV2/appointment/CREATE_AGENT_APPOINTMENT_FOR_APPOINTMENT_SUCCESS'
export const CREATE_AGENT_APPOINTMENT_ERROR =
  'suburban/strategistV2/appointment/CREATE_AGENT_APPOINTMENT_FOR_APPOINTMENT_ERROR'

  export const CREATE_STRATEGIST_APPOINTMENT =
  'suburban/strategistV2/appointment/CREATE_STRATEGIST_APPOINTMENT_FOR_APPOINTMENT'
export const CREATE_STRATEGIST_APPOINTMENT_SUCCESS =
  'suburban/strategistV2/appointment/CREATE_STRATEGIST_APPOINTMENT_FOR_APPOINTMENT_SUCCESS'
export const CREATE_STRATEGIST_APPOINTMENT_ERROR =
  'suburban/strategistV2/appointment/CREATE_STRATEGIST_APPOINTMENT_FOR_APPOINTMENT_ERROR'

export const CLEAR_STRATEGIST_APPOINTMENT_PHASE =
  'suburban/strategistV2/appointment/CLEAR_STRATEGIST_APPOINTMENT_PHASE'

export const GET_STRATEGIST_AVAILLABLE_DATES =
  'suburban/strategistV2/appointment/GET_STRATEGIST_AVAILLABLE_DATES'
export const GET_STRATEGIST_AVAILLABLE_DATES_SUCCESS =
  'suburban/strategistV2/appointment/GET_STRATEGIST_AVAILLABLE_DATES_SUCCESS'
export const GET_STRATEGIST_AVAILLABLE_DATES_ERROR =
  'suburban/strategistV2/appointment/GET_STRATEGIST_AVAILLABLE_DATES_ERROR'
