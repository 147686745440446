import { connect } from 'react-redux'
import { loginUser } from '../../store/user/duck'
import { loginUserClient, clientValidateToken } from '../../store/user/duck'
import LoginForm from './component'
const Login = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    loginPhase: state.user.loginPhase,
    loginError: state.user.loginError,
    clientloginPhase: state.user.clientloginPhase,
    clientloginError: state.user.clientloginError,
    isAuthenticated:state.user.clientloginPhase
  }),
  // Map actions to props
  {
    loginUser,
    loginUserClient,
    clientValidateToken
  }
)(LoginForm)
export default Login
