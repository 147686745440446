import { fetch } from '../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
import { List } from 'immutable'


export const fetchExample = () => {
  return fetch(`${HOSTNAME_V2}/example`)
    .then((res) => res.json())
    .then((payload) => List(payload))
}

export const getDashboardData = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/dashboard-statistics?from=${data.from}&to=${data.to}`, { method: 'GET' })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getNotTouchedData = (data) => {
  return fetch(
    `${HOSTNAME_V2}/agent/never-touched?sort=${data.sort}&sort_value=${data.sort_value}`,
    { method: 'GET' }
  )
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

export const getNotTouchedIn4Week = (data) => {
  return fetch(
    `${HOSTNAME_V2}/agent/never-touched-in-4weeks?sort=${data.sort}&sort_value=${data.sort_value}`,
    { method: 'GET' }
  )
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// fetch all clients from agent details
export const fetchAgentAllClients = (data) => {
  if (data.type) {
    data.data.type = data.type
  }
  if(!data.data.selectedEndDate){
    data.data.selectedEndDate = ""
  }
  if(!data.data.selectedStartDate){
    data.data.selectedStartDate = ""
  }

  return fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients?${new URLSearchParams(data.data).toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

//Edit startetist and agent notes
export const editStrategistAgentNote = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/agent-strategist-chat/${data.chatId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message: data.message
    }),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

//delete starategist and agent note
export const deleteStrategistAgentNote = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/agent-strategist-chat/${data.chatId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// edit agent and client chat data
export const editClientChat = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat/${data.chatId}/agent`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: data.content
    }),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// delete agent and client chat data
export const deleteChatData = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/chat/${data.chatId}/agent`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

// agent on boarding sign up page api
export const sumbitAgentForm = (data) => {
  return fetch(`${HOSTNAME_V2}/onboarding/agent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

// agent on boarding get data
export const getAgentOnBoardData = (data) => {
  return fetch(`${HOSTNAME_V2}/onboarding/${data.user_id}`, {
    method: 'GET'
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

