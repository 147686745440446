import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../../store/user/duck'
import {
        analyticsSubmissions,
        analyticsDeals
       } from '../../../store/analytics/duck'
import {
  getFilterParams,
  clearPhase
 } from '../../../store/dashboard/duck'
import AdminDealsForm from './component'
const AnalyticsDeals = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    filterParams: state.dashboard.filterParams,
    dashSubmissionsData: state.analytics.dashSubmissionsData,
    rxError: state.user.error,
    phase: state.user.phase,
    dashclientPhase: state.analytics.dashclientPhase,
    dealAnalyticsPhase: state.analytics.dealAnalyticsPhase,
    dealAnalyticsData: state.analytics.dealAnalyticsData
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getFilterParams,
    analyticsSubmissions,
    analyticsDeals
  }
)(AdminDealsForm)
export default AnalyticsDeals
