/* eslint-disable camelcase */
import React, { Fragment, PureComponent } from 'react'
import { Pagination, Spin, Table, DatePicker, Row, Col } from 'antd'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ResizeObserver from 'rc-resize-observer';

import { SortDownIcon, SortUpIcon } from "../../../../../../components/icons"
import Loader from '../../../../../../components/Loader'
import ClientTableHeader from '../layout/ClientTableHeader'
import ClientList from '../layout/ClientList'

import NoClientFound from '../layout/NoClientFound'
import MobileTableView from './partials/MobileTableView'
import { ERROR, SUCCESS } from '../../../../../../constants/phase'
import { shouldCopyEmailDisplay, shouldCopyEmailEnabled, DisableCopyEmailPopup } from '../common'
import { ClientContext } from '../../context/ClientContext'
import { DownCaretIcon, CopyIcon } from "../../../../../../components/icons"
import './styles.scss'

const cookies = new Cookies()

export default class AllClientComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      clientStatus: [],
      clientStrategist: [],
      clientAgent: [], 
      clientTiming: [],
      clientType: 'all_clients',
      clientSuburb: [],
      clientTown: [],
      clientBizDevCredit: [],
      clientCurrentLocation: '',
      pageLimit: 20,
      totalPages: 20,
      hideInactive: false,
      activePage: 1,
      startDate: false,
      endDate: false,
      sortByKey: '',
      sortByType: 0,
      user: {},
      copied: false,
      selectOutIndex: null,
      selectInEmails: false,
      clientsEmails: '',
    }
  }
  static contextType = ClientContext
  componentDidMount() {
    if(Boolean(localStorage.getItem('parentFilter'))){
      localStorage.removeItem('parentFilter')
      return
    }
    const user = cookies.get('user')
    if (user) {
      this.setState({ user })
      const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
      if (!get(this.props, 'location.state.filter', false)) {
        if (localclientdata && localclientdata !== null && localclientdata !== '') {
          this.setState(
            {
              isLoading: true,
              selectedClientStatus: localclientdata.clientStatus,
              selectedClientStrategists: localclientdata.clientStrategist,
              selectedClientAgents: localclientdata.clientAgent,
              selectedClientTimings: localclientdata.clientTiming,
              selectedClientSuburbs: localclientdata.clientSuburb,
              selectedClientTowns: localclientdata.clientTown,
              selectedClientBizDevCredits: localclientdata.clientBizDevCredit,
              clientType: 'all_clients',
              clientStatus: localclientdata.clientStatus,
              filterCount: localclientdata.filterCount,
              status: isEmpty(localclientdata.clientStatus) ? false : true,
              statusDropdown: isEmpty(localclientdata.clientStatus) ? false : true,
              clientSuburb: localclientdata.clientSuburb,
              clientStrategist: localclientdata.clientStrategist,
              clientAgent: localclientdata.clientAgent,
              clientTown: localclientdata.clientTown,
              clientBizDevCredit: localclientdata.clientBizDevCredit,
              clientCurrentLocation: localclientdata.clientCurrentLocation,
              clientTiming: localclientdata.clientTiming,
              hideInactive: localclientdata.hideInactive,
              pageLimit: localclientdata.pageLimit,
              activePage: localclientdata.activePage,
              startDate: localclientdata.startDate,
              ...(localclientdata.sortBy && {sortByKey: localclientdata.sortBy.key}),
              ...(localclientdata.sortByType && {sortByType: localclientdata.sortBy.type})
            },
            () => {
              
              this.props.getClientList({
                clientType: this.state.clientType,
                clientStatus: this.state.clientStatus,
                clientSuburb: this.state.clientSuburb,
                clientStrategist: this.state.clientStrategist,
                clientAgent: this.state.clientAgent,
                clientTown: this.state.clientTown,
                clientBizDevCredit: this.state.clientBizDevCredit,
                clientCurrentLocation: this.state.clientCurrentLocation,
                clientTiming: this.state.clientTiming,
                hideInactive: this.state.hideInactive,
                pageLimit: localclientdata.pageLimit ? localclientdata.pageLimit : this.state.pageLimit,
                activePage: this.state.activePage,
                startDate: localclientdata.startDate ? localclientdata.startDate : this.state.startDate,
                endDate: localclientdata.endDate ? localclientdata.endDate : this.state.endDate
              })
            }
          )
        } else {
          
          const {
            clientType,
            filterCount,
            clientStatus,
            clientStrategist,
            clientAgent,
            clientTiming,
            clientSuburb,
            clientTown,
            clientBizDevCredit,
            clientCurrentLocation,
            pageLimit,
            activePage,
            startDate,
            endDate,
            hideInactive,
            sortByType,
            sortByKey,
          } = this.state
          
          const clientdata = {
            clientType,
            clientStatus,
            clientSuburb,
            clientStrategist,
            clientAgent,
            clientTown,
            clientBizDevCredit,
            clientCurrentLocation,
            clientTiming,
            hideInactive,
            pageLimit,
            activePage,
            startDate,
            endDate,
            sortBy: { key: sortByKey, type: sortByType },
          }
          const localclientdata = JSON.parse(localStorage.getItem('dashboardFromToDate'))
          if (localclientdata && localclientdata !== null && localclientdata !== '') {
            clientdata.startDate = localclientdata.startDate ? localclientdata.startDate : clientdata.startDate
            clientdata.startDate = localclientdata.endDate ? localclientdata.endDate : clientdata.endDate
          }
          
          localStorage.setItem('clientFilterData', JSON.stringify({ ...clientdata, filterCount}))
          this.props.getClientList(clientdata)
        }
      }
    }
  }

  componentDidUpdate(preProps, preState) {
    const { clientsData } = this.props
    if (clientsData && clientsData.status === true) {
      const clients = clientsData && clientsData.users && clientsData.users.length > 0 && clientsData.users
      let clientsEmails = []
      if (clients.length >= 1) {
        clients.map((client) => {
          clientsEmails.push(client.client_email)
        })
        this.setState({ clientsEmails: clientsEmails.toString() })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
    let pageLimit = localclientdata && localclientdata.pageLimit !== null ? localclientdata.pageLimit : 20;
    let startDate = localclientdata && localclientdata.startDate !== null ? localclientdata.startDate : false;
    let endDate = localclientdata && localclientdata.endDate !== null ? localclientdata.endDate : false;
    let states = {
      ...state,
      totalPages: props.clientdata ?
        Math.round(props.clientsData.user_counts.all_clients / pageLimit) :
        Math.round(
          props.clientsData &&
          props.clientsData.user_counts &&
          state.clientType &&
          props.clientsData.user_counts[state.clientType]
        ),
      pageLimit: pageLimit,
      startDate,
      endDate
    }
    if (get(props, 'location.state.filter', false)) {
      const clientStatus = []
      switch (props.location.state.filter) {
        case 'Call Scheduled':
          clientStatus.push('Call Scheduled')
          break
        case 'Green':
          clientStatus.push('Green')
          break
        case 'Green - Actively Engaged':
          clientStatus.push('Green - Actively Engaged')
          break
        case 'Green - Not Very Engaged':
          clientStatus.push('Green - Not Very Engaged')
          break
        case 'Green - Somewhat Engaged':
          clientStatus.push('Green - Somewhat Engaged')
          break
        case 'Never Had A Call':
          clientStatus.push('Never Had A Call')
          break
        case 'Online-New':
          clientStatus.push('Online-New')
          break
        case 'Trying To Schedule':
          clientStatus.push('Trying To Schedule')
          break
        // case  'neverScheduledWithAgent': 
        // break
        // case  'agentNotTouchedIn4Week': 
        // break
        // case  'noInitialOrFollowUpAgentCall': 
        // break
        // case  'noInitialorFollowUpAgentTownTour': 
        // break
        default:
          break
      }
      const user = cookies.get('user')
      const data = {
        pageLimit: 20,
        activePage: 1,
        clientStatus,
        clientStrategist: [],
        clientAgent: [],
        clientTiming: [],
        clientSuburb: [],
        clientType: 'all_clients',
        endDate: '',
        filterCount: 1,
        hideInactive: false,
        sortBy: { key: '', type: 0 },
        startDate: '',
      }
      localStorage.setItem('clientFilterData', JSON.stringify(data))
      states = { ...states, clientStatus }
    }
    // when datea phase is success then update page size
    if (props.filterClientsPhase === SUCCESS) {
      props.clearClientPhase()
      states = { ...states, isLoading: false }
    }
    // when api are not wroking
    if (props.filterClientsPhase === ERROR) {
      props.clearClientPhase()
      states = { ...states, isLoading: false }
    }
    return states
  }

  handlePageChange = (pageNumber) => {

    this.setState({ activePage: pageNumber })
    const {
      clientType,
      clientStatus,
      clientStrategist,
      clientAgent,
      clientTiming,
      clientSuburb,
      clientTown,
      clientBizDevCredit,
      clientCurrentLocation,
      pageLimit,
      hideInactive,
      sortByType,
      sortByKey,
      startDate,
      endDate
    } = this.state

    const clientdata = {
      clientType,
      clientStatus,
      clientSuburb,
      clientTown,
      clientBizDevCredit,
      clientStrategist,
      clientAgent,
      clientTiming,
      clientCurrentLocation,
      hideInactive,
      pageLimit,
      activePage: pageNumber,
      sortBy: { key: sortByKey, type: sortByType },
      startDate: startDate ? moment.utc(startDate).format('YYYY-MM-DD') : "",
      endDate: endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ""
    }

    localStorage.setItem('clientFilterData', JSON.stringify(clientdata))

    this.props.getClientList(clientdata)

  }

  sortClientData = (sortByKey) => {
    let sortByType = this.state.sortByType
    sortByType =
      // eslint-disable-next-line no-nested-ternary
      sortByKey !== this.state.sortByKey ? 0 : sortByType === 0 ? 1 : 0
    this.setState({ sortByKey, sortByType }, () => this.handlePageChange(1))
  }
  // when click on userfilter set filter data from filter user
  setfilterParams = (data) => {
    const { clientStatus, filteredStrategists, filteredAgents, filteredTimings, filteredSuburb, filteredTowns, filteredBizDevCredits, startDate, endDate, filterCount, searchCurrentLocation } = data
    this.setState({
      clientStatus,
      client_strategist: filteredStrategists,
      clientStrategist: filteredStrategists,
      clientAgent: filteredAgents,
      clientTiming: filteredTimings,
      clientSuburb: filteredSuburb,
      clientTown: filteredTowns,
      clientBizDevCredit: filteredBizDevCredits,
      clientCurrentLocation: searchCurrentLocation,
      selectedClientStatus: clientStatus,
      selectedClientStrategists: filteredStrategists,
      selectedClientAgents: filteredAgents,
      selectedClientTimings: filteredTimings,
      selectedClientSuburbs: filteredSuburb,
      filterCount: filterCount ? filterCount : 0,
      startDate: startDate ? moment.utc(startDate).format('YYYY-MM-DD') : "",
      endDate: endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ""
    })
    this.forceUpdate()
  }

  onCopy4() {
    this.setState({ selectInEmails: true })
    setTimeout(() => {
      this.setState({ selectInEmails: false })
    }, 2000)
    this.copyPopup.open()
  }

	goToClientDetailPage = (client, currentUser) => {
    if (!this.props.clientIsReadOnly(client, currentUser)) {
      this.props.history.push({
        pathname: `/strategist/client-details/${client.client_id}`,
        state: { client, prevRoute: this.props.history.location.pathname }
      })
    }
  }

  render() {
    const { clientsData, resultEntry, isCopyEmail } = this.props
    const { user, filterCount, pageLimit, totalPages, sortByKey, sortByType, isLoading, clientBizDevCredit, clientCurrentLocation } = this.state
    const clients =
      clientsData && clientsData.users && clientsData.users.length > 0 && clientsData.users
    const disableCopyEmail = !shouldCopyEmailEnabled(user, filterCount)
    return (
      <div>
        {/* This component for mobile only */}
        <MobileTableView clients={clients} {...this.props} currentUser={this.state.user} />
        {/* End component for mobile only */}
        <div className="desktop__view">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Spin size="large" spinning={isLoading || this.context.isClientDataFetching} indicator={<Loader />}>
                  {/*{shouldCopyEmailDisplay(isCopyEmail, clients, user) ? (*/}
                  {/*  <div className="text-right">*/}
                  {/*    <CopyToClipboard*/}
                  {/*      className="btn btn__btn btn-dark btn__150"*/}
                  {/*      text={this.state.clientsEmails}*/}
                  {/*      onCopy={this.onCopy4.bind(this)}*/}
                  {/*    >*/}
                  {/*      <button disabled={disableCopyEmail} className="copyBoxButton orderchange"  >*/}
                  {/*        {this.state.selectInEmails ? "Copied" : "Copy"} Emails*/}
                  {/*      </button>*/}
                  {/*    </CopyToClipboard>*/}
                  {/*    <DisableCopyEmailPopup */}
                  {/*      enabled={disableCopyEmail}*/}
                  {/*      onRef={(copyPopup)=>{*/}
                  {/*        this.copyPopup = copyPopup*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </div>) :*/}
                  {/*  ''}*/}
                  {/* {clients && clients.length > 0 ? (
                    <table className="table custom-table">
                      <ClientTableHeader
                        sortByType={sortByType}
                        sortByKey={sortByKey}
                        sortClientData={this.sortClientData}
                        currentUser={this.state.user}
                      />
                      <ClientList clients={clients} {...this.props} currentUser={this.state.user} />
                    </table>
                  ) : (
                    <NoClientFound />
                  )} */}
                  {clients && clients.length > 0 ? (
                    <VirtualTable 
                      {...this.props}
                      dataSource={clients}
                      currentUser={this.state.user}
											sortByType={sortByType}
                      sortByKey={sortByKey}
                      sortClientData={this.sortClientData}
											goToClientDetailPage={this.goToClientDetailPage}
                      bizDevCreditFilter={clientBizDevCredit}
                      currentLocationFilter={clientCurrentLocation}
                    />
                  ) : (
                    <NoClientFound />
                  )}
                </Spin>

              </div>
            </div>
          </div>
        </div>
        {clients && clients.length > 0 && resultEntry < totalPages && totalPages > 1 && (
          <Pagination
            className="mt__20 mb__20"
            defaultCurrent={this.state.activePage}
            total={totalPages}
            showQuickJumper={false}
            showSizeChanger={false}
            pageSize={pageLimit}
            onChange={this.handlePageChange}
          />
        )}
      </div>
    )
  }
}

const formatPriceRange = (client) => {
  const rangestr = client && client.price_range;
  if (rangestr && rangestr.indexOf('-') > 0) {
    const rangearr = rangestr.split('-');
    if (parseInt(rangearr[0].trim()).toLocaleString() == 0) {
      return `$${parseInt(rangearr[0].trim()).toLocaleString()}`;
    } else {
      return `$${parseInt(rangearr[0].trim()).toLocaleString()} - $${parseInt(rangearr[1].trim()).toLocaleString()}`;
    }
  } else {
    return `$${parseInt(rangestr).toLocaleString()} +`;
  }
}

const disabledDate = (current) => current && current < moment.utc().startOf('day');
const handleDateChange = (clientId, strategistId, date, props) => {
  const data = {
    clientId,
    date: moment(date).format("YYYY-MM-DD"),
    strategistId
  }
  props.updateFollowUpDate(data)
}

const getColumns = ({sortByType, sortByKey, sortClientData, currentUser, bizDevCreditFilter, currentLocationFilter, ...props}) => {
	const sortData = (key) => sortClientData(key)

  // Provide a function that determines if a row should be given the pointer class and thus be clickable
  const getPointerClass = (client) => {
    return props.clientIsReadOnly(client, currentUser) ?
      null :
      'cursor__pointer'
  }

  const clientIsReadOnly = (client) => props.clientIsReadOnly(client, currentUser)

	let columns = [
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('clientName')}>
              <Col xs={12}>Client Name</Col>
              <Col xs={12}>
								{(sortByKey === "clientName" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "client_full_name",
      render: (text, client, index) => {
        return (
          <Fragment>
            <span style={{ paddingRight: 5 }}>{client.client_full_name}</span>
            {!clientIsReadOnly(client) &&
              <span className={`wrap-text multilines ${getPointerClass(client)}`}>
                <CopyToClipboard
                  text={client.client_email}
                  onCopy={() => props.onCopy1(index)}>
                  <i>
                    <CopyIcon className="clipcopy-icon" />
                  </i>
                </CopyToClipboard>
                {props.selectOutIndex === index && props.copied ? (
                  <span id="showEmail1" style={{ color: "#c7ce4a" }}>
                    {" "}
                    Copied
                  </span>
                ) : (
                  ""
                )}
              </span>
            }
          </Fragment>
        );
      },
      width: 220,
    },
    {
			title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('strategist_full_name')}>
              <Col xs={12}>Strategist</Col>
              <Col xs={12}>
								{(sortByKey === "strategist_full_name" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "strategist_name",
      // sorter: (a, b) => handleSorting(a.strategist_name, b.strategist_name),
      // sortDirections: ['descend', 'ascend'],
      width: 300,
    },
    {
			title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('current_location')}>
              <Col xs={12}>Current Location</Col>
              <Col xs={12}>
								{(sortByKey === "current_location" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "current_location",
      render: (text, client, index) => {
        return client.current_location
      },
      // width: 200,
    },
    {
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('priceRange')}>
              <Col xs={12}>Budget</Col>
              <Col xs={12}>
								{(sortByKey === "priceRange" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "price_range",
      render: (text, record) => {
        return formatPriceRange(record);
      },
      width: 250,
    },
    {
			title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('pipelineStage')}>
              <Col xs={12}>Status</Col>
              <Col xs={12}>
								{(sortByKey === "pipelineStage" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "status",
      width: 200,
    },
    {
			title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('created')}>
              <Col xs={12}>Created Date</Col>
              <Col xs={12}>
								{(sortByKey === "created" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "created",
      render: (text, record) => {
        return moment(record.created)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY");
      },
      // width: 200,
    },
    {
			title: ({ sortOrder, sortColumn, filters }) => {
        return (
          <Fragment>
            <Row className="cursor__pointer" onClick={() => sortData('lastActivity')}>
              <Col xs={12}>Follow Up</Col>
              <Col xs={12}>
								{(sortByKey === "lastActivity" && sortByType === 0) ? <SortDownIcon /> : <SortUpIcon />}
              </Col>
            </Row>
          </Fragment>
        );
      },
      dataIndex: "follow_up_date",
      render: (text, client) => {
        let overDueRedClass = "datepicker__follow-up-date";
        if (
          client.follow_up_date &&
          client.follow_up_date.length === 1 &&
          client.follow_up_date[0].follow_up_date &&
          client.follow_up_date[0].follow_up_date !== ""
        ) {
          const timeZone = get(
            props.currentUser,
            "time_zone",
            "America/New_York"
          );
          let followupDate = moment
            .utc(client.follow_up_date[0].follow_up_date)
            .format("YYYY-MM-DD");
          let nowDate = moment()
            .tz(timeZone)
            .format("YYYY-MM-DD");
          if (followupDate === nowDate || followupDate < nowDate) {
            overDueRedClass = "datepicker__follow-up-date-red";
          } else {
            overDueRedClass = "datepicker__follow-up-date";
          }
        }
        const clientIsReadOnly = props.clientIsReadOnly(client, props.currentUser)

        return (
          <Fragment>

            {clientIsReadOnly && client.follow_up_date && client.follow_up_date.length === 1 && (
              <div>
                {client.follow_up_date[0].follow_up_date}
              </div>
            )}

            {!clientIsReadOnly && client.follow_up_date && client.follow_up_date.length === 1 && (

              <div className="btn__follow-up-date"  onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}>
                <div className="follow-up-date-label">Follow up date</div>
                <DatePicker
                  className={overDueRedClass}
                  dropdownClassName="datepicker__overlay__follow-up-date"
                  defaultValue={
                    client &&
                    client.follow_up_date &&
                    client.follow_up_date[0].follow_up_date
                  }
                  onChange={(date) =>
                    handleDateChange(
                      client.client_id,
                      client.follow_up_date[0].strategist_id,
                      date,
                      props
                    )
                  }
                  value={
                    client &&
                    client.follow_up_date &&
                    client.follow_up_date[0].follow_up_date &&
                    moment.utc(client.follow_up_date[0].follow_up_date)
                  }
                  format="MM/DD/YYYY"
                  disabledDate={disabledDate}
                  inputReadOnly={true}
                  allowClear={false}
                  placeholder="N/A"
                />
                <DownCaretIcon className="caret-icon" />
              </div>
            )}

            {client.follow_up_date && client.follow_up_date.length >= 2 && (
              <button className="btn btn__link__gray">
                Multiple Follow Up Dates{" "}
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  if (!props.showStrategistColumn(currentUser, bizDevCreditFilter)) columns = columns.filter((el) => el.dataIndex !== 'strategist_name')
  if (!props.showCurrentLocationColumn(currentLocationFilter)) columns = columns.filter((el) => el.dataIndex !== 'current_location')

  return columns

}

function VirtualTable(props) {

  const [tableWidth, setTableWidth] = React.useState(0);
  const [copied, setCopied] = React.useState(false)
  const [selectOutIndex, setSelectOutIndex, user] = React.useState(null)
  
  const rowClassName = (record, index) => {
    
    if (props.clientIsReadOnly(record, props.currentUser)) {
      return 'antd-table-row-no-link'
    }
    return index % 2 === 0 ? 'antd-table-row-dark' :  ''
  }
  

  const onCopy1 = (index) => {
    setCopied(true)
    setSelectOutIndex(index)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
    // setTimeout(function () { $('#showEmail1').hide() }, 2000)
  }

  const getCustomColumns = (props, onCopy1, copied, selectOutIndex) => {
    return getColumns({...props, onCopy1, copied, selectOutIndex });
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className="virtual-table modify-table"
        rowClassName={rowClassName}
        columns={getCustomColumns(props, onCopy1, copied, selectOutIndex)}
        pagination={false}
        scroll={{x: 'max-content'}}
        rowKey={(record) => record['client_id']}
				onRow={(client, rowIndex) => {
					return {
						onClick: event => {
              event.preventDefault();
              props.goToClientDetailPage(client, props.currentUser) 
            }, // click row
					};
				}}
      />
    </ResizeObserver>
  );
}


