import { connect } from 'react-redux'

import {
  getStrategistAvaillableDates,
  fetchStrategistAvailability,
  createStrategistAppointment,
  clearStrategistAppointment,
} from '../../../../../store/strategistV2/appointment/actions'

import ScheduleAppointmentComponent from './ScheduleAppointment'

const ClientDetailsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    strategistAvailabilityData: state.strategistV2.appointment.strategistAvailabilityData,
    strategistAvailabilityDatePhase: state.strategistV2.appointment.strategistAvailabilityDatePhase,
    strategistAvailabilityDates: state.strategistV2.appointment.strategistAvailabilityDates,
    strategistAvailabilityTimes: state.strategistV2.appointment.strategistAvailabilityTimes,
    strategistAvailabilityPhase: state.strategistV2.appointment.strategistAvailabilityPhase,
    createStrategistAppointmentPhase:
      state.strategistV2.appointment.createStrategistAppointmentPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchStrategistAvailability,
    getStrategistAvaillableDates,
    createStrategistAppointment,
    clearStrategistAppointment,
  }
)(ScheduleAppointmentComponent)

export default ClientDetailsContainer
