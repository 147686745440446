import * as types from './action-types'
import * as api from './apis'

/*
    /////////////////////////////////
  /// dashboard action ///
/////////////////////////////////
*/
// get filter parmas
export const getFilterParams = () => ({
  type: types.FETCH_FILTER_LIST,
})
// get filter params for your agents section
export const yourAgentsFilter = (data) => ({
  type: types.FETCH_YOURAGENT_LIST,
  payload: api.yourAgentsFilter(data)
})
// fetch client details
export const fetchUser = (payload) => ({
  type: types.FETCH_CLIENT_PROFILE,
  payload,
})
// clear dashboard phase
export const clearDashboardPhase = () => ({
  type: types.CLEAR_DASHBOARD_PHASE,
})

export const getDevelopmentMembers=()=>({
  type:types.FETCH_DEVELOPMENT_MEMBERS,
})
