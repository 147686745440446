import { fetch } from "../../../utils";
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2;
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
    //////////////////////////////////
  /// Interal notes apis ///
/////////////////////////////////
*/
// fetch notes of client using clientId
export const fetchInteralNotes = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
// add internal note
export const createNewNote = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

// edit internal notes
export const editInternalNote = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes/${data.noteId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

// delete internal note by id
export const removeNote = data => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes/${data.noteId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
