
import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import Cookies from 'universal-cookie'
import moment from 'moment'
import _ from 'lodash'
import isEmpty from "lodash/isEmpty"
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import Modal from 'react-responsive-modal';
import Loader from '../../../../../../components/Loader'
import DatePicker from '../../../../../../components/datePicker'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class AgentsAssignedNysjStrategistsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment('2019-03-27'),
      endDate: moment(),
      sortByKey: 'name',
      sortByType: 0,
      type: 'assignToStrategist',
      selectedIndex: 'all',
      todayDate: moment().format('LL'),
      isLoading: false,
      businessDevTableData: [],
      search: '',
      businessDevelopmentExport: [],
      multiDataSet: [],
      bdSources: [],
      showAll: true,
      customExportData: [
        {
          client_full_name: '',
          tag: '',
          closed_date: '',
          assign_strategist: "",
          reffer_clients: "",
          bd_source: "",
          source: "",
          "strategist_name-1": '',
          "bonus_paid-1": ''
        }
      ]
    }
  }

  setValuesForExcelSheet = async (sheetHeaders, businessDevelopment, initialHeaders) => {
    if (initialHeaders) {
      let MBaluesAr = []
      let setVals = businessDevelopment.map(async (k, i) => {
        let d = []
        let setval = initialHeaders.map((header, j) => {
          d.push(k[header])
        })
        await Promise.all(setval)
        MBaluesAr.push(d)
      })

      await Promise.all(setVals)
      let multiDataSet = this.state.multiDataSet

      if (!isEmpty(this.state.multiDataSet)) {
        multiDataSet = []
      }

      multiDataSet.push({ columns: sheetHeaders, data: MBaluesAr })
      this.setState({ multiDataSet })
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
    if (_user && _user.role === "agent" || _user && _user.role === "local") {
      this.props.history.push({ pathname: '/agent/dashboard' })
    }
    if (_user && _user.role === "client") {
      this.props.history.push({ pathname: '/clients' })
    }
    if (_user) {
      if (this.state.selectedIndex == 'all') {
        const agentData = {}
        //moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        // agentData.from = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        // agentData.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        agentData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        agentData.to = moment().format("YYYY-MM-DD HH:mm:ss")
        agentData.selectedValue = 'any'
        agentData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType }
        this.props.getBusinessDevelopmentData(agentData)
        this.setState({ isLoading: true, userRole: _user.role, multiDataSet: [] })

      }

    }
  }

  componentDidUpdate(preProps, preState) {
    const { businessDevelopmentPhase, businessDevelopmentData, clearPhase } = this.props
    if (businessDevelopmentPhase === "success") {
      clearPhase()
      let data = []
      let bdSources = [];
      //set table data
      if(_.size(businessDevelopmentData) > 0){
        businessDevelopmentData && businessDevelopmentData.map((buss, i)=>{
          data.push({
            key: i,
            client_full_name: buss.client_full_name ? buss.client_full_name : '',
            client_status: buss.client_status ? buss.client_status : '',
            closed_source: buss.closed_source && buss.closed_source.length > 0 ? moment(buss.closed_source[0].expected_close_date).format("MM-DD-YYYY") : '',
            bd_paid: buss.bd_paid ? buss.bd_paid : '',
            strategists: buss.strategists ? buss.strategists : '',
            reffer_clients: buss.reffer_clients ? buss.reffer_clients : '',
            bd_source: buss.bd_source ? buss.bd_source : '',
            source: buss.source ? buss.source : '',
            ad_tracking_codes: buss.ad_tracking_codes ? buss.ad_tracking_codes : '',
            created: moment(buss.created).format("MM-DD-YYYY"),
            region: buss.region ? buss.region : ''
          })
          bdSources.push(buss.bd_source);
        })
      }

      this.setState({ businessDevTableData: data, isLoading: false, bdSources: bdSources })

      let businessDevelopment = []
      let feeCount = 0
      businessDevelopmentData && businessDevelopmentData.length > 0 && businessDevelopmentData.map(_client => {
        if (_client.bd_paid.length > feeCount) {
          feeCount = _client.bd_paid.length
        }

        let exObj = {}
        exObj.client_full_name = _client.client_full_name
        exObj.tag = _client.client_status
        exObj.closed_date = (_client.closed_date !== '' && _client.closed_date !== null) ? moment(_client.closed_date).format("MM-DD-YYYY") : ''

        for (var i = 1; i <= feeCount; i++) {
          exObj[`strategist_name-${i}`] = ''
          exObj[`bonus_paid-${i}`] = ''
        }

        if (_client.bd_paid.length > 0) {
          _client.bd_paid.map(function (str, index) {
            exObj[`strategist_name-${index + 1}`] = str.strategist_full_name
            exObj[`bonus_paid-${index + 1}`] = (str.strategist_fee_date !== '' && str.strategist_fee_date !== null) ? moment(str.strategist_fee_date).format("MM-DD-YYYY") : ''
          })
        }
        exObj.assigned_strategist = _client.strategists
        exObj.reffering_client = _client.reffer_clients
        exObj.biz_dev_credit = _client.bd_source
        exObj.source = _client.source
        businessDevelopment.push(exObj)
      })
      let sheetHeaders = [];
      let maxLengthObj = {}
      let objLengths = []
      !isEmpty(businessDevelopment) && Promise.all(businessDevelopment.map((t, i) => {
        objLengths.push(Object.keys(t).length)
      }))

      let mainVal = Math.max(...objLengths)
      maxLengthObj = businessDevelopment[objLengths.indexOf(mainVal)]

      if (!isEmpty(businessDevelopment) && !isEmpty(maxLengthObj)) {
        Promise.all(Object.keys(maxLengthObj).map((k, i) => {
          let headerName = k.replace(/_/g, " ")
          headerName = headerName.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
          sheetHeaders.push(headerName)
        }))
      }

      this.setValuesForExcelSheet(sheetHeaders, businessDevelopment, maxLengthObj ? Object.keys(maxLengthObj) : {})
      this.setState({ isLoadingData: false, businessDevelopmentExport: businessDevelopment })
    }
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, endDate: to })
    const agentData = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      selectedValue: 'any',
      sortBy: { key: this.state.sortByKey, type: this.state.sortByType }
    }
    this.props.getBusinessDevelopmentData(agentData)
  }
  handleBdSources(event) {
    let bdSourceList = this.state.bdSources;
    bdSourceList && bdSourceList.map((val, index) => {
      if (val.client_id === parseInt(event.target.value)) {


      }
    })
    this.setState({ bd_source_id: event.target.value, isLoading: true }, () => {
      const strData = {}
      if (this.state.selectedIndex === "all") {
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.strategistId = this.state.strategist_id
      strData.bd_source_id = this.state.bd_source_id
      strData.sortBy = { key: this.state.sortByKey, type: this.state.sortByType }
      this.props.getBDSourceReportData(strData)


    })

  }

  changeLimit = limit => {
    // On limit change
  }

  render() {
    const { businessDevTableData, todayDate, weekFirstDate, weekLastDate, monthFirstDate, monthLastDate } = this.state
    let filterList = businessDevTableData
    if (_.size(businessDevTableData) > 0) {
      filterList = businessDevTableData.filter(
        (val) => {
          return (
            (val && val.client_full_name && val.client_full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-6 col-md-4">
                    {
                      (_user && (_user.role) === "business development") ?
                        <button onClick={() => this.props.history.push(`/agent/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back to Dashboard</button>
                        :
                        <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back to Dashboard</button>
                    }
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <h2 className="reports__title">Business Development</h2>
                    <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15" /> Export</button>}>
                      <ExcelSheet dataSet={this.state.multiDataSet} name="Business_Development">
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="firstName"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search for a client by name..."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
                {/* <div className="col-md-4">
                    <select
                      className="form-control custom__select material-textfield-input"
                      name="bd_source_id"
                      value={this.state.bd_source_id}
                      onChange={this.handleBdSources.bind(this)}>
                        <option value={0}>All</option>
                        {
                         this.state.bdSources.map((val, index) => {
                          
                            return (
                                   <option key={index} value={val.client_id}>{val.full_name}</option>
                                )
                             
                           })
                        }
                    </select>
                   
                  </div> */}
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(filterList) > 0) ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Table
                            className="table__fixed"
                            sortOrde={true}
                            columns={[
                              {
                                title: 'Client Name',
                                width: 320,
                                dataIndex: 'client_full_name',
                                key: '1',
                                fixed: 'left',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.client_full_name.localeCompare(b.client_full_name)
                              },
                              {
                                title: 'Pipeline Stage',
                                width: 320,
                                dataIndex: 'client_status',
                                key: '2',
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.client_status.localeCompare(b.client_status)
                              },
                              {
                                title: 'Closed Date',
                                dataIndex: 'closed_source',
                                key: '3',
                                width: 200,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => moment(Date.parse(a.closed_source) || 0).diff(moment(Date.parse(b.closed_source) || 0))
                              },
                              {
                                title: 'BD Paid',
                                dataIndex: 'bd_paid',
                                key: '4',
                                width: 220,
                                showSorterTooltip: false,
                                render: (text, client) => {
                                  return (
                                    client.bd_paid && client.bd_paid.length > 0 &&
                                    client.bd_paid.map((acc, accIdx) => {
                                      return (
                                        <div className="detailsgap" key={accIdx}>
                                          <div>
                                            <label className="labelglobal">Strategist-{accIdx + 1}:</label>
                                            <p>{acc.strategist_full_name}</p>
                                          </div>
                                          <div>
                                            <label className="labelglobal">Bonus Paid:</label>
                                            <p>{(acc.strategist_fee_date !== '' && acc.strategist_fee_date !== null) ? moment(acc.strategist_fee_date).format('MM-DD-YYYY') : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    })
                                  )
                                }
                              },
                              {
                                title: 'Assigned Strategist',
                                dataIndex: 'strategists',
                                key: '5',
                                width: 240,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.strategists.localeCompare(b.strategists)
                              },
                              {
                                title: 'Referring Client',
                                dataIndex: 'reffer_clients',
                                key: '6',
                                width: 230,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.reffer_clients.localeCompare(b.reffer_clients)
                              },
                              {
                                title: ' BD Source',
                                dataIndex: 'bd_source',
                                key: '7',
                                width: 230,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.bd_source.localeCompare(b.bd_source)
                              },
                              {
                                title: 'Source',
                                dataIndex: 'source',
                                key: '8',
                                width: 400,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.source.localeCompare(b.source)
                              },
                              {
                                title: 'Ad Tracking Code',
                                dataIndex: 'ad_tracking_codes',
                                key: '9',
                                width: 400,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.ad_tracking_codes.localeCompare(b.ad_tracking_codes)
                              },
                              {
                                title: 'Client Created Date',
                                dataIndex: 'created',
                                key: '10',
                                width: 400,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => new Date(a.created) - new Date(b.created)

                              },
                              {
                                title: 'Region',
                                dataIndex: 'region',
                                key: '11',
                                width: 400,
                                showSorterTooltip: false,
                                sortDirections: ['descend', 'ascend', 'descend'],
                                sorter: (a, b) => a.region.localeCompare(b.region),

                              }
                            ]}
                            dataSource={filterList}
                            pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions }}
                            scroll={{ y: 420 }}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
