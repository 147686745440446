import { uniqWith } from 'lodash'

export const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none',
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5',
  }),
}

export const uniqWithAgentAndTown = (data) => {
  return uniqWith(data, (prev, next) => {
    return prev.agent_id === next.agent_id && prev.town_id === next.town_id
  })
}
