import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'
import * as types from './action-types'
import * as api from './api'
/*
    //////////////////////
  /// deal epics ///
//////////////////////
*/
const fetchTownSchoolDistrictEpic = (action$) =>
  action$.ofType(types.GET_TOWN_AND_SCHOOL_DISTRICT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchTownSchoolDistrict(action.payload))
      .map((payload) => ({
        type: types.GET_TOWN_AND_SCHOOL_DISTRICT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.GET_TOWN_AND_SCHOOL_DISTRICT_ERROR,
          payload: { error },
        })
      )
  })
// fetch client deals for show on client deatils page
const fetchClientDealEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_DEAL).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientDeal(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_DEAL_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_DEAL_ERROR,
          payload: { error },
        })
      )
  })
// create update client deal
const createUpdateClientDealEpic = (action$) =>
  action$.ofType(types.CREATE_UPDATE_DEAL).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createUpdateClientDeal(action.payload))
      .map((payload) => ({
        type: types.CREATE_UPDATE_DEAL_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_UPDATE_DEAL_ERROR,
          payload: { error },
        })
      )
  })
// create update client deal
const fetchSchoolDistrictEpic = (action$) =>
  action$.ofType(types.FETCH_SCHOOL_DISTRICT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchSchoolDistrict(action.payload))
      .map((payload) => ({
        type: types.FETCH_SCHOOL_DISTRICT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_SCHOOL_DISTRICT_ERROR,
          payload: { error },
        })
      )
  })

  // delete client deal
  const deleteClientDealEpic = (action$) =>
    action$.ofType(types.DELETE_CLIENT_DEAL).mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteClientDeal(action.payload))
        .map((payload) => ({
          type: types.DELETE_CLIENT_DEAL_SUCCESS,
          payload,
        }))
        .catch((error) =>
          Rx.Observable.of({
            type: types.DELETE_CLIENT_DEAL_ERROR,
            payload: { error },
          })
        )
    })

// combine deal epic
export const dealsEpic = combineEpics(
  fetchClientDealEpic,
  fetchSchoolDistrictEpic,
  createUpdateClientDealEpic,
  fetchTownSchoolDistrictEpic,
  deleteClientDealEpic
)
