import { connect } from 'react-redux'

import NewClientsComponent from './component'
import { getFilterParams } from '../../../../../../store/strategistV2/dashboard/actions'
import { getQuestionnairesCompletedClientList, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const NewClientsContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    QuestionnairesReportPhase: state.reportV2.QuestionnairesReportPhase,
    QuestionnairesData: state.reportV2.QuestionnairesData
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    getQuestionnairesCompletedClientList,
    clearReportV2Phase
  }
)(NewClientsComponent)

export default NewClientsContainer
