import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import $ from "jquery";
import config from '../../config'
import { fetch } from '../../utils';
import {getTimeFormate, formatPhoneNumber, uniqueArrayObject} from '../../config'
import { timeConverter } from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
// import TopNavigation from '../TopNavigation/container'
import TopNavigation from '../TopNavigation/container'
import Calendar from 'react-calendar'
import moment from 'moment'
import Slider from 'react-slick'
import Pagination from "react-js-pagination";
import timezone from 'moment-timezone';
import NumberFormat from 'react-number-format';
import ShowMore from 'react-show-more';
import axios from 'axios'
import _ from 'lodash';
import DatePicker from 'react-datepicker'
import Cookies from 'universal-cookie'
import styles from './styles.scss'
import {getHostname} from "../../utils/helper";
const required = value => value ? undefined : 'Required'
const HOSTNAME = process.env.API_HOSTNAME

const cookies = new Cookies()

let availableDateSlots = ''
let forEndDate = ''
let pre_Auth = ''
let _user = ''

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};


class AgentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      openSchedule: false,
      source:"",
      times:[],
      date: moment().format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      timezone_type:"UTC",
      selectedTime : "",
      noTimeWorks:false,
      noSchedule:false,
      totalPages:0,
      inactiveTotalPages: 0,
      activePage:1,
      inactivePage: 1,
      not4weekPage:1,
      notTouchedPage: 1,
      client_limit:20,
      inactive_client_limit: 20,
      client_offset:1,
      inactive_client_offset: 1,
      err: {},
      infoLength:0,
      strategy:[],
      isLoading: 'Submit',
      selectValue:'',
      openSchedule: false,
      clientId:'',
      startDate:moment(),
      itemCount : [],
      selectStartTime:'',
      selectEndTime:'',
      selectAppointment: '',
      textarea: '',
      initDataLoaded:false,
      err1: {},
      startTimeErr: '',
      clientFirstName: '',
      clientLastName: '',
      type: '',
      searchedClients: [],
      showSearchList: false,
      accesstoken:'',
      open: false,
      isLoadingData:true,
      search:'',
      status: 'active',
      inactiveCount: 0,
      activeCount: 0,
      notTouch4WeekTotalPages: 0,
      neverTouchTotalPages: 0,
      subType: 'all',
      neverTouchStatusCount: 0,
      showActiveTab:true,
      showClosedTab:false,
      show4WeekTouchTab:false,
      showNotTouchTab:false,
    }
    this.handleChange = this.handleChange.bind(this)
  }
  
  componentWillMount(){

    _user = cookies.get('user')
    if(_user) {
      this.props.history.push('/agent/dashboard')
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      const clientdata ={}
      clientdata.client_limit = this.state.client_limit
      clientdata.client_offset= this.state.activePage
      clientdata.status= 'active'
      clientdata.subType = 'all'
      this.props.agentGetUsers(clientdata)
      
      const data1 = {}
      data1.user_id  = _user.id
      data1.timezone = _user.time_zone
      data1.date     = moment(this.state.startDate._d).format("YYYY-MM-DD")
      this.props.getAgentAvailability(data1)

      pre_Auth = cookies.get('i_user')
      if(pre_Auth){
        this.setState({pre_Auth: pre_Auth})
      }
      // Gathering the Data for search
      let search_data = {} 
      search_data.client_limit = 1000
      search_data.client_offset = 1
      search_data.subType = 'all'
      this.props.agentSearchGetUsers(search_data)
      this.setState({ initDataLoaded: true },()=>{})
    }
  }

  componentDidMount(){
    document.title = "Jungler: Agent Home";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }


  selectedChange(id, e){
    let data = {}
      data.client_id     = id
      data.activity_status = e.target.value
      this.props.updateClientUser(data)
  }

  handleChange(event) {
    let itemCount = []
    let avilData = this.props.availabilityData && this.props.availabilityData.data && this.props.availabilityData.data.slot
    if(event.target.name === "selectStartTime"){
        itemCount = _.filter(avilData, (avil) => {
        if(avil > event.target.value){
          return avil;
        }
      })
     this.setState({[event.target.name]: event.target.value, itemCount: itemCount},()=>{
    })
    }else {
     this.setState({[event.target.name]: event.target.value},()=>{
      })
    }    
  }


  onSearch(e){
    if (e.target.value!== '') {
      this.setState({search:e.target.value, showSearchList: true})
    }
    else {
      this.setState({showSearchList: false})
    }
  }

  onSelectClients(clientDataObj, alldata){
    if(alldata.role == "client"){
      this.props.history.push({ pathname: `agent-clients-profile/${alldata.id}`, state: {'userObj':clientDataObj, 'userAllD': alldata}})
      this.setState({showSearchList: false})
    }
  }

  closeList() {
    this.setState({searchedClients: [], showSearchList: false})
  }

  showPlaceholder(){
    const {history} = this.props
    if(history.location.pathname == '/agent'){
      return "Search Clients"
    }else{
      return "Search"
    }
  }

  componentWillReceiveProps(nextProps) {
    let self = this

    if(nextProps.searchUserPhase == "success"){
      this.setState({
        totalPages:nextProps.usersData.counts, 
        inactiveTotalPages: nextProps.usersData.counts, 
        initDataLoaded:false,
        inactiveCount: nextProps.agentSearchGetUsersData && nextProps.agentSearchGetUsersData.closed_counts, 
        activeCount: nextProps.agentSearchGetUsersData && nextProps.agentSearchGetUsersData.active_counts,
        notTouch4WeekTotalPages: nextProps.agentSearchGetUsersData && nextProps.agentSearchGetUsersData.neverTouchIn4Week,
        neverTouchTotalPages: nextProps.agentSearchGetUsersData && nextProps.agentSearchGetUsersData.neverTouch,
      })
      this.props.clearPhase()
    }
    if(nextProps.userPhase === "success"){
      this.setState({ isLoadingData:false })
    }
    if(nextProps.searchUserPhase == "success"){
      let clientID = _.get(nextProps.agentSearchGetUsersData && nextProps.agentSearchGetUsersData.data, '[0]id')
      this.setState({ clientId: clientID})
      this.props.clearPhase()
    }
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    } else if (nextProps.profilePhase === "success") {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile info saved successfully."})

      if(nextProps.isSubmitting !=false){
          if(this.state.userid !=""){
            const data = {}
            data.user_id = this.state.userid;
            this.props.fetchUser(data)
          }
      }
    }
    if (nextProps.profilePhase === "success") {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role,
          timezone: user.time_zone
        })
      }
    }
     if(nextProps.addAppointmentPhase === "success"){
      this.setState({ appSchedule: false })
      this.props.clearPhase()
      let _user = cookies.get('user')
       _user = cookies.get('user')
        if(_user) {
          this.setState({userid: _user.id})
          const data = {}
          data.user_id = _user.id
          data.client_limit = this.state.client_limit
          data.client_offset= this.state.activePage
          data.status= 'active'
          data.subType = this.state.subType
          this.props.agentGetUsers(data)
        }
    } 
  }

  scheduleClick(id, firstName, lastName){
    this.setState({ 
      openSchedule : true, 
      clientId: id, 
      type: "one",
      clientFirstName: firstName, 
      clientLastName: lastName})
  }

  openScheduleBar(event){
    this.setState({ 
      openSchedule : true, 
      type:"many", 
      clientFirstName: '', 
      clientLastName: '',
      startDate:moment()
    })
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     let _user = cookies.get('user')
      const data = {}
      data.user_id  = id
      data.timezone = timezone
      data.date     = selectDate
    this.props.getAgentAvailability(data)
    this.setState({startDate: date})
  }

  handlePageChange(pageNumber) {
    this.setState({ isLoadingData: true})
    this.setState({activePage: pageNumber});
    setTimeout(function(){
      const clientdata ={}
      clientdata.client_limit = this.state.client_limit
      clientdata.client_offset= this.state.activePage
      clientdata.status= this.state.status
      clientdata.subType = this.state.subType
      this.props.agentGetUsers(clientdata)
    }.bind(this), 500)
  }

  handlePageChange2(pageNumber){
    this.setState({ isLoadingData: true})
    this.setState({inactivePage: pageNumber});
    setTimeout(function(){
      const clientdata ={}
      clientdata.client_limit = this.state.inactive_client_limit
      clientdata.client_offset= this.state.inactivePage
      clientdata.status= this.state.status
      clientdata.subType = this.state.subType
      this.props.agentGetUsers(clientdata)
    }.bind(this), 500)
  }

  handlePageNotIn4Change(pageNumber){
    this.setState({ isLoadingData: true})
    this.setState({not4weekPage: pageNumber});
    setTimeout(function(){
      const clientdata ={}
      clientdata.client_limit = this.state.client_limit
      clientdata.client_offset= this.state.not4weekPage
      clientdata.status= this.state.status
      clientdata.subType = this.state.subType
      this.props.clientNotTouch4W(clientdata)
    }.bind(this), 500)
  }

  handlePageNotChange(pageNumber){
    this.setState({ isLoadingData: true})
    this.setState({notTouchedPage: pageNumber});
    setTimeout(function(){
      const clientdata ={}
      clientdata.client_limit = this.state.client_limit
      clientdata.client_offset= this.state.notTouchedPage
      clientdata.status= this.state.status
      clientdata.subType = this.state.subType
      this.props.clientNotTouch(clientdata)
    }.bind(this), 500)
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }


  submitSchedulingData(timezone, userId, event){
    const err = {}
    const { selectStartTime, selectEndTime, selectAppointment, textarea,
           startDate } = this.state
    
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if (selectEndTime === '' || selectEndTime === "End Time" || selectEndTime.trim() === '') {
      err.selectEndTime = 'Select End Time'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
     let date =  moment(startDate._d).format("YYYY-MM-DD")
     let data = {}
     data.timezone = timezone
     data.user_id  = userId
     data.appointment_type = selectAppointment
     data.date     = date
     data.client_id = this.state.clientId
     data.start_time = selectStartTime
     data.end_time   = selectEndTime
     data.description = this.state.description

     this.props.addAgentAppointment(data)
     this.setState({ openSchedule : false })
     }
  }

  handelClientfilter(type, btn, event){
    $('.filter__container--button').removeClass('active');
    if(btn == "active"){
      this.setState({ showNotTouchTab: false, showActiveTab:true, showClosedTab:false, show4WeekTouchTab: false  })
    } else if(btn == "inactive"){
      this.setState({ showNotTouchTab: false, showActiveTab:false, showClosedTab:true, show4WeekTouchTab: false  })
    } else if(btn == "not_touched_in_four_week"){
      this.setState({ showNotTouchTab: false, showActiveTab:false, showClosedTab:false, show4WeekTouchTab: true  })
    } else {
      this.setState({ showNotTouchTab: true, showActiveTab:false, showClosedTab:false, show4WeekTouchTab: false  })
    }

    if(type !==''){
      // $( 'button[ name='+type+']' ).addClass( 'active' );
      switch(type){
        case 'all':
          this.setState({subType:'all', activePage: 1, inactivePage: 1, not4weekPage: 1, notTouchedPage: 1});
          break;
        case 'actively_engaged':
          this.setState({subType:'Actively Engaged', activePage: 1, inactivePage: 1, not4weekPage: 1, notTouchedPage: 1});
          break;
        case 'moderately_engaged':
          this.setState({subType:'Moderately Engaged', activePage: 1, inactivePage: 1, not4weekPage: 1, notTouchedPage: 1});
          break;
        case 'unresponsive':
          this.setState({subType:'Unresponsive', activePage: 1, inactivePage: 1, not4weekPage: 1, notTouchedPage: 1});
          break;
        default:

      }
    }else{
      // this.setState({client_status:'all_clients'})
    }
    setTimeout(function(){
      this.setState({isLoadingData: true})
      let data = {}
        data.client_limit = this.state.client_limit
        data.client_offset = 1
        data.status = btn
        data.subType = this.state.subType
      if(btn === "active" || btn === "inactive" ){
        this.props.agentGetUsers(data)
      } else if( btn === "not_touched_in_four_week"){
        this.props.clientNotTouch4W(data);
      } else{
        this.props.clientNotTouch(data);
      }
    }.bind(this), 500)
  }
  
  handleStatusChange(status){
    $('.filter__container--button').removeClass('active');
    let type = 'all'
    $( 'button[ name='+type+']' ).addClass( 'active' );

    if(status == 'active'){
      this.setState({ showNotTouchTab: false, showActiveTab:true, showClosedTab:false, show4WeekTouchTab: false },()=>{})
    }

    if(status == 'inactive'){
      this.setState({ showNotTouchTab: false, showActiveTab:false, showClosedTab:true, show4WeekTouchTab: false },()=>{})
    }
    this.setState({status: status, isLoadingData: true, subType: 'all'},()=>{
      let data = {}
      data.client_limit = this.state.client_limit
      data.client_offset= 1
      data.status = status
      data.subType = "all"
      this.props.agentGetUsers(data)
    })
  }

  notTouchedIn4W(status){
    $('.filter__container--button').removeClass('active');
    let type = 'all'
    $( 'button[ name='+type+']' ).addClass( 'active' );
    this.setState({status: status, isLoadingData: true, subType: 'all', showNotTouchTab: false, showActiveTab:false, showClosedTab:false, show4WeekTouchTab: true },()=>{
      let data = {}
      data.client_limit = this.state.client_limit
      data.client_offset = 1
      data.status = status
      data.subType = "all"
      this.props.clientNotTouch4W(data);
    })
  }

  notTouched(status){
    $('.filter__container--button').removeClass('active');
    let type = 'all'
    $( 'button[ name='+type+']' ).addClass( 'active' );
    this.setState({status: status, isLoadingData: true, subType: 'all', showNotTouchTab: true, showActiveTab:false, showClosedTab:false, show4WeekTouchTab: false },()=>{
      let data = {}
      data.client_limit = this.state.client_limit
      data.client_offset = 1
      data.status = status
      data.subType = "all"
      this.props.clientNotTouch(data);
    })
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      token,
      availabilityData,
      agentSearchGetUsersData,
      usersData,
      getAllClients,
      phase,
    } = this.props
    
    if(availabilityData.data === '' || availabilityData.data && availabilityData.data.length == 0){
      this.state.err1.startTimeErr = 'In this date no time slot available'
    }else{
      this.state.err1.startTimeErr = ''
    }

    let appoinment = config.typeOfAppointments.map((val, index) => {
      return (
        <option key={index} value={val.value}>{val.name}</option>
      )
    });

    availableDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
    let pos = availabilityData.data.slot.length - 1
    if (pos === indexslot) {
      return(
        <option disabled={pos} key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
      )
    } else {
      return(
        <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
      )
    }
  });

  if( this.state.itemCount ){
    forEndDate = this.state.itemCount.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime}>{timeConverter(endTime)}</option>
        )
    });
  }

  let clientList = '';
  if(this.state.type !== "one"){
    if(agentSearchGetUsersData &&  agentSearchGetUsersData.data && agentSearchGetUsersData.data.length>0) {
      clientList = agentSearchGetUsersData.data.map((val, index) => {
        return (
          <option key={index} value={val.id}>{val.first_name +' '+ val.last_name}</option>
        )
      });
    }
  }

  let allData = agentSearchGetUsersData && agentSearchGetUsersData.data

    let filterList = ''
    if(allData !== undefined && allData !==''){
      filterList = allData.filter((data) =>{
        let firstLastName = data.first_name+' '+data.last_name
        let mobVal = ''
        if(data.phone_mobile){
          mobVal = data.phone_mobile.replace(/\D/g, '')
        }

        return (
          (data.first_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 ||
          ((data.last_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1) ||
          ((firstLastName.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1) ||
          ((data.email.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1) ||
          ((mobVal.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1) 
        );
       }
      );
    }


    // if(phase == "success") {
    //   return(
    //     <Redirect to={`/dashboard`}/>
    //   )
    // }


    return (
      <div>
      {
        (pre_Auth)?
        <div className="auth-name">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
        :''
      }
      <TopNavigation onRef={ref => (this.TopNavigation = ref)} {...this.props} openScheduleBar={this.openScheduleBar.bind(this)}/>
        {/*<TopNavigation {...this.props}/>*/}

      {this.state.initDataLoaded === true ?
        <div className="loader__agent" >
         <img src="../../img/loader2.svg" />
        </div>
        :''}

        <div className="agent__page--container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
               <div className='clinet-list-main-wraper'>
              {/*
                (usersData && usersData.counts && usersData.counts.length !== 0)? (*/
                  <div className="agent__page--head">
                    <h1>Welcome to the Jungler!</h1>
                  </div>
                /*):''
              */}

           {/*
            (usersData && usersData.counts && usersData.counts.length !== 0)? (*/
              <div>
                <div className="row">  
                  <div className="col-md-12 ">
                   <form className="navbar__header--form agentSearchBox">
                    <span className="typeahead">
                      <input 
                        className="costom-search typeahead-input" 
                        onChange={(e)=> this.onSearch(e)}
                        type="text" 
                        name="search" 
                        placeholder={this.showPlaceholder()}/>
                      
                      { this.state.showSearchList &&
                        <ul className="search-list">
                        <div className="search-close"><i className="fa fa-times" onClick={()=> this.closeList()}></i></div>
                          { 
                            filterList && filterList.map((c, i) => {
                              let userSearchObj = {}
                              userSearchObj.user = c
                              userSearchObj.agentId = this.state.userid
                              userSearchObj.agentAvatar = this.props.user.avatar
                              userSearchObj.agentName   = this.props.user.first_name
                              userSearchObj.agentId     = this.props.user.id
                              return(
                                <li key={i} onClick={()=> this.onSelectClients(userSearchObj, c)}>
                                  <a>
                                    <div className="search-left">
                                      <span>
                                        <p className="title">{c.first_name+ ' '+ c.last_name+' '+'('+c.role+')'}</p>
                                      </span>
                                    </div>
                                  </a>
                                </li>
                              )
                            })
                          }                                                  
                        </ul>
                      }
                     </span>
                   </form>
                  </div>
                </div>

                <div className="product-dtl-tab-wrapper">
                  <div className="">

                    <div className="row">
                      <div className="col-md-12">
                        <div className="agent-product-dtl-tab">
                           {user && user.role ==='agent' ?
                           <ul className="nav nav-tabs">
                            <li ><a data-toggle="tab" href="#never_touched" onClick={this.notTouched.bind(this, 'never_touched')}>New: Never Touched ({this.state.neverTouchTotalPages})</a></li>
                            <li ><a data-toggle="tab" href="#not_touched_in_four_week" onClick={this.notTouchedIn4W.bind(this, 'not_touched_in_four_week')}>Not Touched in 4 Weeks ({this.state.notTouch4WeekTotalPages})</a></li>
                            <li className="active" ><a data-toggle="tab" href="#active_clients" onClick={this.handleStatusChange.bind(this, 'active')}>Active Clients ({this.state.activeCount})</a></li>
                            <li ><a data-toggle="tab" href="#closed_clients" onClick={this.handleStatusChange.bind(this, 'inactive')}>Closed Clients ({this.state.inactiveCount})</a></li>
                          </ul>
                           :
                           <ul className="nav nav-tabs">
                            <li className="active" ><a data-toggle="tab" href="#active_clients" onClick={this.handleStatusChange.bind(this, 'active')}>Active Clients ({this.state.activeCount})</a></li>
                            <li ><a data-toggle="tab" href="#closed_clients" onClick={this.handleStatusChange.bind(this, 'inactive')}>Closed Clients ({this.state.inactiveCount})</a></li>
                          </ul>
                           }

                          <div className="filter__container desktopFilter desktopFilter-agent">
                          <div className="filter__container--filter-name">
                           <div className="col-md-12 rem-left">
                            <div className="filter__container--title">
                              <button name="all" className={this.state.subType === 'all' ? 'type filter__container--button active' : 'type filter__container--button'} onClick={this.handelClientfilter.bind(this, 'all', this.state.status)}> All </button>
                              <button name="actively_engaged" className={this.state.subType === 'Actively Engaged' ? 'type filter__container--button active' : 'type filter__container--button'} onClick={this.handelClientfilter.bind(this, 'actively_engaged', this.state.status)}>Actively Engaged</button>
                              <button name="moderately_engaged" className={this.state.subType === 'Moderately Engaged' ? 'type filter__container--button active' : 'type filter__container--button'} onClick={this.handelClientfilter.bind(this, 'moderately_engaged', this.state.status)}>Moderately Engaged</button>
                              <button name="unresponsive" className={this.state.subType === 'Unresponsive' ? 'type filter__container--button active' : 'type filter__container--button'} onClick={this.handelClientfilter.bind(this, 'unresponsive', this.state.status)}> Unresponsive </button>
                            </div>
                           </div>
                          </div>
                         </div>

                          {this.state.isLoadingData === true ?
                            <div className="tab-content loader-container">
                             <img src="../../img/loader2.svg" />
                            </div>
                            :
                            <div className="tab-content">
                              
                              <div id="never_touched" className={this.state.showNotTouchTab ? 'tab-pane fade in active' : 'tab-pane fade'} >   
                                <div>
                                  { 
                                    usersData && usersData.data && usersData.data.length ? usersData.data.map((user,index) => {
                                    let userObj = {}
                                      userObj.user = user
                                      userObj.agentId = this.state.userid
                                      userObj.agentAvatar = this.props.user.avatar
                                      userObj.agentName   = this.props.user.first_name
                                      userObj.agentId     = this.props.user.id

                                      let profileHTML = unescape(user.background_profile) //{__html: user.background_profile}
                                      var tmp = document.createElement("DIV");
                                      tmp.innerHTML = profileHTML;
                                      let htmlDoc = tmp.textContent || tmp.innerText || "";
                                      return(
                                          <div key={index} className="agent__page--list">
                                              <ul className="agent__page--list-view">
                                              <li>
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div className="agent__page--list__info">
                                                      <div className="agent__page--list__info-header b__bottom">
                                                        <div className="">
                                                          <h4 className="agent__page--list--avtar-title">{user.first_name+" "+ user.last_name }</h4>
                                                          <p className="agent__page--list--avtar-profile">{user.role}</p>
                                                          <div className="client__secondary_name">
                                                              {user && user.secondary_name ? "Secondary Contact: ": ''}{user && user.secondary_name ? user.secondary_name : ''}
                                                          </div>
                                                        </div>
                                                        <div className="agent__page--list__info-header--action">
                                                          <div className="form-control custom-select">
                                                          <select className="form-control custom-select"
                                                            onChange={this.selectedChange.bind(this, user.id)} >
                                                              <option>{user.activity_status ? user.activity_status : "Select a Status"}</option>
                                                              {
                                                                (user.activity_status !== "Actively Engaged") ?
                                                                  <option value="Actively Engaged">Actively Engaged</option>
                                                                  : ''
                                                              }
                                                              {
                                                                (user.activity_status !== "Moderately Engaged") ?
                                                                 <option value="Moderately Engaged">Moderately Engaged</option>
                                                                  : ''
                                                              }
                                                              {
                                                                (user.activity_status !== "Unresponsive") ?
                                                                 <option value="Unresponsive">Unresponsive</option>
                                                                  : ''
                                                              }
                                                          </select>
                                                          </div>
                                                          
                                                          <button className="btn btn__green"
                                                           onClick={() => 
                                                            { 
                                                              this.props.history.push({ pathname: `agent-clients-profile/${userObj.user.id}`, state: {'userObj':userObj, 'userAllD': user}})
                                                              }} 
                                                            >
                                                          View Client Profile
                                                         </button>
                                                        </div>
                                                      </div>

                                                      <div className="agent__page--list__info-body">
                                                        <div className="row">
                                                          <div className="col-md-6 ">
                                                            <div className="agent__page--list-items">
                                                              <h2>Budget Amount</h2>
                                                              <h4 className="price__size">${user.price_min.toLocaleString()} - ${user.price_max.toLocaleString()}</h4>
                                                            </div>
                                                            <div className="agent__page--list-items">
                                                              <h2>Assigned Towns</h2>
                                                              
                                                              {
                                                                user.towns && user.towns.map((town,i) => {
                                                                  return(
                                                                     <span key={i}>{ (i ? ', ' : '') + town.town_name}</span>
                                                                    )
                                                                })
                                                              }

                                                            </div>
                                                          </div>
                                                          <div className="col-md-6 b__left">
                                                            <div className="agent__page--list-items">
                                                              <div className='row'>
                                                              <div className="col-md-12">
                                                              <h2>Assigned Strategist</h2>
                                                              
                                                              {
                                                                user.strategist && user.strategist.map((str, idx)=>{
                                                                  return(
                                                                    <div key={idx} className="strategist_box--wrapper">
                                                                      <h4 
                                                                        className="strategist_box">
                                                                        {str.strategist_full_name}
                                                                      </h4>
                                                                  </div>
                                                                  )
                                                                })
                                                              }
                                                              
                                                              </div>
                                                              </div>
                                                            </div>
                                                            <div className="agent__page--list-items">
                                                              <h2>Background Information</h2>
                                                              <h4>
                                                                {
                                                                  (htmlDoc !== "null")?
                                                                  <ShowMore
                                                                    lines={3}
                                                                    more='View more'
                                                                    less='View less'
                                                                    anchorClass='ShowMoreToggle'
                                                                    >{htmlDoc}
                                                                  </ShowMore>
                                                                :
                                                                 'There is no background information for this client'
                                                                }
                                                              </h4>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="agent__page--list__contact--info">
                                                      <div className="row">
                                                        <div className="col-md-5">
                                                          <div className="list__contact">
                                                            <h2>Client Primary Email</h2>
                                                            <h4><a href={'mailto:'+user.email}>{user.email}</a></h4>
                                                            {user.secondary_email ? 
                                                            <div>
                                                            <h2>Client Secondary Email</h2>
                                                            <h4><a href={'mailto:'+user.secondary_email}>{user.secondary_email}</a></h4>
                                                            </div>
                                                            :''}
                                                          </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <div className="list__contact">
                                                            <h2>Primary Phone Number</h2>
                                                            <h4><a href={'tel:'+formatPhoneNumber(user.phone_mobile)}>{user.phone_mobile ? formatPhoneNumber(user.phone_mobile) : 'Not set'}</a></h4>

                                                            {user.secondary_phone_email ?
                                                            <div>
                                                            <h2>Secondary Phone Number</h2>
                                                            <h4><a href={'tel:'+formatPhoneNumber(user.secondary_phone_email)}>{user.secondary_phone_email ? formatPhoneNumber(user.secondary_phone_email) : 'Not set'}</a></h4>
                                                            </div>
                                                            : 
                                                            ''
                                                            }
                                                          </div>
                                                        </div>
                                                        <div className="col-md-4 p__rl-zero">
                                                          {user && user.role ==='agent' ? 
                                                          <div className="list__contact">
                                                            <h2>Next Appointment</h2>
                                                            {
                                                              (user.latest_appointment_date !== '') ?
                                                                <h4>
                                                                  <a>
                                                                   {user.latest_appointment_type} - {moment(user.latest_appointment_date).format("MM/DD/YY")}
                                                                  </a>
                                                                </h4>
                                                                :
                                                                
                                                                <h5>
                                                                 <a onClick={()=>this.scheduleClick(user.id, user.first_name, user.last_name)} className="red-appointment">Schedule Appointment</a>
                                                                </h5>
                                                            }
                                                            
                                                          </div>
                                                          :
                                                          ''
                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                        </div>
                                      )
                                    }) : <div className="client_blank_msg">No clients with this criteria.</div>
                                  }
                                </div>
                                  <div className="pagination-container">
                                    <Pagination
                                      activePage={this.state.notTouchedPage}
                                      itemsCountPerPage={this.state.client_limit}
                                      totalItemsCount={this.state.subType == "all" ? this.state.neverTouchTotalPages : this.props.usersData && this.props.usersData.neverTouch}
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageNotChange.bind(this)}
                                    />
                                  </div>
                              </div>

                              <div id="not_touched_in_four_week" className={this.state.show4WeekTouchTab ? 'tab-pane in fade active' : 'tab-pane fade'} >
                                <div>
                                  {
                                    usersData && usersData.data && usersData.data.length ? usersData.data.map((user,index) => {
                                      let userObj = {}
                                        userObj.user = user
                                        userObj.agentId = this.state.userid
                                        userObj.agentAvatar = this.props.user.avatar
                                        userObj.agentName   = this.props.user.first_name
                                        userObj.agentId     = this.props.user.id

                                        let profileHTML = unescape(user.background_profile) //{__html: user.background_profile}
                                        var tmp = document.createElement("DIV");
                                        tmp.innerHTML = profileHTML;
                                        let htmlDoc = tmp.textContent || tmp.innerText || "";
                                        return(
                                            <div key={index} className="agent__page--list">
                                                <ul className="agent__page--list-view">
                                                <li>
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div className="agent__page--list__info">
                                                        <div className="agent__page--list__info-header b__bottom">
                                                          <div className="">
                                                            <h4 className="agent__page--list--avtar-title">{user.first_name+" "+ user.last_name }</h4>
                                                            <p className="agent__page--list--avtar-profile">{user.role}</p>
                                                            <div className="client__secondary_name">
                                                                {user && user.secondary_name ? "Secondary Contact: ": ''}{user && user.secondary_name ? user.secondary_name : ''}
                                                            </div>
                                                          </div>
                                                          <div className="agent__page--list__info-header--action">
                                                            <div className="form-control custom-select">                                                            <select className="form-control custom-select"
                                                              onChange={this.selectedChange.bind(this, user.id)} >
                                                                <option>{user.activity_status ? user.activity_status : "Select a Status" }</option>
                                                                {
                                                                  (user.activity_status !== "Actively Engaged") ?
                                                                    <option value="Actively Engaged">Actively Engaged</option>
                                                                    : ''
                                                                }
                                                                {
                                                                  (user.activity_status !== "Moderately Engaged") ?
                                                                   <option value="Moderately Engaged">Moderately Engaged</option>
                                                                    : ''
                                                                }
                                                                {
                                                                  (user.activity_status !== "Unresponsive") ?
                                                                   <option value="Unresponsive">Unresponsive</option>
                                                                    : ''
                                                                }
                                                            </select>
                                                            
                                                            </div>
                                                            
                                                            <button className="btn btn__green"
                                                             onClick={() => 
                                                              { 
                                                                this.props.history.push({ pathname: `agent-clients-profile/${userObj.user.id}`, state: {'userObj':userObj, 'userAllD': user}})
                                                                }} 
                                                              >
                                                            View Client Profile
                                                           </button>
                                                          </div>
                                                        </div>

                                                        <div className="agent__page--list__info-body">
                                                          <div className="row">
                                                            <div className="col-md-6 ">
                                                              <div className="agent__page--list-items">
                                                                <h2>Budget Amount</h2>
                                                                <h4 className="price__size">${user.price_min.toLocaleString()} - ${user.price_max.toLocaleString()}</h4>
                                                              </div>
                                                              <div className="agent__page--list-items">
                                                                <h2>Assigned Towns</h2>
                                                                
                                                                {
                                                                  user.towns && user.towns.map((town,i) => {
                                                                    return(
                                                                       <span key={i}>{ (i ? ', ' : '') + town.town_name}</span>
                                                                      )
                                                                  })
                                                                }

                                                              </div>
                                                            </div>
                                                            <div className="col-md-6 b__left">
                                                              <div className="agent__page--list-items">
                                                                <div className='row'>
                                                                <div className="col-md-12">
                                                                <h2>Assigned Strategist</h2>
                                                                
                                                                {
                                                                  user.strategist && user.strategist.map((str, idx)=>{
                                                                    return(
                                                                      <div key={idx} className="strategist_box--wrapper">
                                                                        <h4 
                                                                          className="strategist_box">
                                                                          {str.strategist_full_name}
                                                                        </h4>
                                                                    </div>
                                                                    )
                                                                  })
                                                                }
                                                                
                                                                </div>
                                                                </div>
                                                              </div>
                                                              <div className="agent__page--list-items">
                                                                <h2>Background Information</h2>
                                                                <h4>
                                                                  {
                                                                    (htmlDoc !== "null")?
                                                                    <ShowMore
                                                                      lines={3}
                                                                      more='View more'
                                                                      less='View less'
                                                                      anchorClass='ShowMoreToggle'
                                                                      >{htmlDoc}
                                                                    </ShowMore>
                                                                  :
                                                                   'There is no background information for this client'
                                                                  }
                                                                </h4>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="agent__page--list__contact--info">
                                                        <div className="row">
                                                          <div className="col-md-5">
                                                            <div className="list__contact">
                                                              <h2>Client Primary Email</h2>
                                                              <h4><a href={'mailto:'+user.email}>{user.email}</a></h4>
                                                              {user.secondary_email ? 
                                                              <div>
                                                              <h2>Client Secondary Email</h2>
                                                              <h4><a href={'mailto:'+user.secondary_email}>{user.secondary_email}</a></h4>
                                                              </div>
                                                              :''}
                                                            </div>
                                                          </div>
                                                          <div className="col-md-3">
                                                            <div className="list__contact">
                                                              <h2>Primary Phone Number</h2>
                                                              <h4><a href={'tel:'+formatPhoneNumber(user.phone_mobile)}>{user.phone_mobile ? formatPhoneNumber(user.phone_mobile) : 'Not set'}</a></h4>

                                                              {user.secondary_phone_email ?
                                                              <div>
                                                              <h2>Secondary Phone Number</h2>
                                                              <h4><a href={'tel:'+formatPhoneNumber(user.secondary_phone_email)}>{user.secondary_phone_email ? formatPhoneNumber(user.secondary_phone_email) : 'Not set'}</a></h4>
                                                              </div>
                                                              : 
                                                              ''
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="col-md-4 p__rl-zero">
                                                          {user && user.role ==='agent' ? 
                                                          <div className="list__contact">
                                                            <h2>Next Appointment</h2>
                                                            {
                                                              (user.latest_appointment_date !== '') ?
                                                                <h4>
                                                                  <a>
                                                                   {user.latest_appointment_type} - {moment(user.latest_appointment_date).format("MM/DD/YY")}
                                                                  </a>
                                                                </h4>
                                                                :
                                                                
                                                                <h5>
                                                                 <a onClick={()=>this.scheduleClick(user.id, user.first_name, user.last_name)} className="red-appointment">Schedule Appointment</a>
                                                                </h5>
                                                            }
                                                            
                                                          </div>
                                                          :
                                                          ''
                                                          }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                          </div>
                                        )
                                      }) : <div className="client_blank_msg">No clients with this criteria.</div>
                                    }
                                </div>
                                  <div className="pagination-container">
                                    <Pagination
                                      activePage={this.state.not4weekPage}
                                      itemsCountPerPage={this.state.client_limit}
                                      totalItemsCount={this.state.subType == "all" ? this.state.notTouch4WeekTotalPages : this.props.usersData && this.props.usersData.neverTouchIn4Week}
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageNotIn4Change.bind(this)}
                                    />
                                  </div>
                              </div>

                              <div id="active_clients" className={this.state.showActiveTab ? 'tab-pane fade in active' : 'tab-pane fade'}>
                                <div>
                                {
                                  usersData && usersData.data && usersData.data.length ? usersData.data.map((user,index) => {
                                    let userObj = {}
                                    userObj.user = user
                                    userObj.agentId = this.state.userid
                                    userObj.agentAvatar = this.props.user.avatar
                                    userObj.agentName   = this.props.user.first_name
                                    userObj.agentId     = this.props.user.id

                                    let profileHTML = unescape(user.background_profile) //{__html: user.background_profile}
                                    var tmp = document.createElement("DIV");
                                    tmp.innerHTML = profileHTML;
                                    let htmlDoc = tmp.textContent || tmp.innerText || "";
                                    return(
                                        <div key={index} className="agent__page--list">
                                            <ul className="agent__page--list-view">
                                            <li>
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="agent__page--list__info">
                                                    <div className="agent__page--list__info-header b__bottom">
                                                      <div className="">
                                                        <h4 className="agent__page--list--avtar-title">{user.first_name+" "+ user.last_name }</h4>
                                                        <p className="agent__page--list--avtar-profile">{user.role}</p>
                                                        <div className="client__secondary_name">
                                                            {user && user.secondary_name ? "Secondary Contact: ": ''}{user && user.secondary_name ? user.secondary_name : ''}
                                                        </div>
                                                      </div>
                                                      <div className="agent__page--list__info-header--action">
                                                        <div className="form-control custom-select">
                                                        
                                                        <select className="form-control custom-select"
                                                          onChange={this.selectedChange.bind(this, user.id)} >
                                                            <option>{user.activity_status ? user.activity_status : "Select a Status"}</option>
                                                            {
                                                              (user.activity_status !== "Actively Engaged") ?
                                                                <option value="Actively Engaged">Actively Engaged</option>
                                                                : ''
                                                            }
                                                            {
                                                              (user.activity_status !== "Moderately Engaged") ?
                                                               <option value="Moderately Engaged">Moderately Engaged</option>
                                                                : ''
                                                            }
                                                            {
                                                              (user.activity_status !== "Unresponsive") ?
                                                               <option value="Unresponsive">Unresponsive</option>
                                                                : ''
                                                            }
                                                        </select>
                                                        </div>
                                                        
                                                        <button className="btn btn__green"
                                                         onClick={() => 
                                                          { 
                                                            this.props.history.push({ pathname: `agent-clients-profile/${userObj.user.id}`, state: {'userObj':userObj, 'userAllD': user}})
                                                            }} 
                                                          >
                                                        View Client Profile
                                                       </button>
                                                      </div>
                                                    </div>

                                                    <div className="agent__page--list__info-body">
                                                      <div className="row">
                                                        <div className="col-md-6 ">
                                                          <div className="agent__page--list-items">
                                                            <h2>Budget Amount</h2>
                                                            <h4 className="price__size">${user.price_min.toLocaleString()} - ${user.price_max.toLocaleString()}</h4>
                                                          </div>
                                                          <div className="agent__page--list-items">
                                                            <h2>Assigned Towns</h2>
                                                            
                                                            {
                                                              user.towns && user.towns.map((town,i) => {
                                                                return(
                                                                   <span key={i}>{ (i ? ', ' : '') + town.town_name}</span>
                                                                  )
                                                              })
                                                            }

                                                          </div>
                                                        </div>
                                                        <div className="col-md-6 b__left">
                                                          <div className="agent__page--list-items">
                                                            <div className='row'>
                                                            <div className="col-md-12">
                                                            <h2>Assigned Strategist</h2>
                                                            
                                                            {
                                                              user.strategist && user.strategist.map((str, idx)=>{
                                                                return(
                                                                  <div key={idx} className="strategist_box--wrapper">
                                                                    <h4 
                                                                      className="strategist_box">
                                                                      {str.strategist_full_name}
                                                                    </h4>
                                                                </div>
                                                                )
                                                              })
                                                            }
                                                            
                                                            </div>
                                                            </div>
                                                          </div>
                                                          <div className="agent__page--list-items">
                                                            <h2>Background Information</h2>
                                                            <h4>
                                                              {
                                                                (htmlDoc !== "null")?
                                                                <ShowMore
                                                                  lines={3}
                                                                  more='View more'
                                                                  less='View less'
                                                                  anchorClass='ShowMoreToggle'
                                                                  >{htmlDoc}
                                                                </ShowMore>
                                                              :
                                                               'There is no background information for this client'
                                                              }
                                                            </h4>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="agent__page--list__contact--info">
                                                    <div className="row">
                                                      <div className="col-md-5">
                                                        <div className="list__contact">
                                                          <h2>Primary Contact Email</h2>
                                                          <h4><a href={'mailto:'+user.email}>{user.email}</a></h4>
                                                          {user.secondary_email ? 
                                                          <div>
                                                          <h2>Secondary Contact Email</h2>
                                                          <h4><a href={'mailto:'+user.secondary_email}>{user.secondary_email}</a></h4>
                                                          </div>
                                                          :''}
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="list__contact">
                                                          <h2>Primary Phone Number</h2>
                                                          <h4><a href={'tel:'+formatPhoneNumber(user.phone_mobile)}>{user.phone_mobile ? formatPhoneNumber(user.phone_mobile) : 'Not set'}</a></h4>

                                                          {user.secondary_phone_email ?
                                                          <div>
                                                          <h2>Secondary Phone Number</h2>
                                                          <h4><a href={'tel:'+formatPhoneNumber(user.secondary_phone_email)}>{user.secondary_phone_email ? formatPhoneNumber(user.secondary_phone_email) : 'Not set'}</a></h4>
                                                          </div>
                                                          : 
                                                          ''
                                                          }
                                                        </div>
                                                      </div>
                                                      <div className="col-md-4 p__rl-zero">
                                                      {user && user.role ==='agent' ? 
                                                          <div className="list__contact">
                                                            <h2>Next Appointment</h2>
                                                            {
                                                              (user.latest_appointment_date !== '') ?
                                                                <h4>
                                                                  <a>
                                                                   {user.latest_appointment_type} - {moment(user.latest_appointment_date).format("MM/DD/YY")}
                                                                  </a>
                                                                </h4>
                                                                :
                                                                
                                                                <h5>
                                                                 <a onClick={()=>this.scheduleClick(user.id, user.first_name, user.last_name)} className="red-appointment">Schedule Appointment</a>
                                                                </h5>
                                                            }
                                                            
                                                          </div>
                                                          :
                                                          ''
                                                          }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                      </div>
                                    )
                                  }) : <div className="client_blank_msg">No clients with this criteria.</div>
                                }
                                </div>

                                <div className="pagination-container">
                                  <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.client_limit}
                                    totalItemsCount={this.state.subType == "all" ? this.state.activeCount : this.props.usersData && this.props.usersData.activeCounts}
                                    pageRangeDisplayed={50}
                                    onChange={this.handlePageChange.bind(this)}
                                  />
                                </div>
                              </div>

                            {/*THis is for closed user profile*/}
                              <div id="closed_clients" className="tab-pane fade" className={this.state.showClosedTab ? 'tab-pane fade in active' : 'tab-pane fade'}>
                                <div>
                                  {
                                    usersData && usersData.data && usersData.data.length ? usersData.data.map((user,index) => {
                                      let userObj = {}
                                      userObj.user = user
                                      userObj.agentId = this.state.userid
                                      userObj.agentAvatar = this.props.user.avatar
                                      userObj.agentName   = this.props.user.first_name
                                      userObj.agentId     = this.props.user.id

                                      let profileHTML = unescape(user.background_profile) //{__html: user.background_profile}
                                      var tmp = document.createElement("DIV");
                                      tmp.innerHTML = profileHTML;
                                      let htmlDoc = tmp.textContent || tmp.innerText || "";
                                        return(
                                            <div key={index} className="agent__page--list">
                                              <ul className="agent__page--list-view">
                                                <li>
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div className="agent__page--list__info">
                                                        <div className="agent__page--list__info-header b__bottom">
                                                          <div className="">
                                                            <h4 className="agent__page--list--avtar-title">{user.first_name+" "+ user.last_name }</h4>
                                                            <p className="agent__page--list--avtar-profile">{user.role}</p>
                                                          </div>
                                                          <div className="agent__page--list__info-header--action">
                                                            <div className="form-control custom-select">
                                                          
                                                            
                                                            <select className="form-control custom-select"
                                                              onChange={this.selectedChange.bind(this, user.id)} >
                                                                <option>{user.activity_status ? user.activity_status : "Select a Status"}</option>
                                                                {
                                                                  (user.activity_status !== "Actively Engaged") ?
                                                                    <option value="Actively Engaged">Actively Engaged</option>
                                                                    : ''
                                                                }
                                                                {
                                                                  (user.activity_status !== "Moderately Engaged") ?
                                                                   <option value="Moderately Engaged">Moderately Engaged</option>
                                                                    : ''
                                                                }
                                                                {
                                                                  (user.activity_status !== "Unresponsive") ?
                                                                   <option value="Unresponsive">Unresponsive</option>
                                                                    : ''
                                                                }
                                                                
                                                            </select>
                                                            </div>
                                                            
                                                            <button className="btn btn__green"
                                                             onClick={() => 
                                                              { 
                                                                this.props.history.push({ pathname: `agent-clients-profile/${userObj.user.id}`, state: {'userObj':userObj, 'userAllD': user}})
                                                                }} 
                                                              >
                                                            View Client Profile
                                                           </button>
                                                          </div>
                                                        </div>

                                                        <div className="agent__page--list__info-body">
                                                          <div className="row">
                                                            <div className="col-md-6 ">
                                                              <div className="agent__page--list-items">
                                                                <h2>Budget Amount</h2>
                                                                <h4 className="price__size">${user.price_min.toLocaleString()} - ${user.price_max.toLocaleString()}</h4>
                                                              </div>
                                                              <div className="agent__page--list-items">
                                                                <h2>Assigned Towns</h2>
                                                                
                                                                {
                                                                  user.towns && user.towns.map((town,i) => {
                                                                    return(
                                                                       <span key={i}>{ (i ? ', ' : '') + town.town_name}</span>
                                                                      )
                                                                  })
                                                                }

                                                              </div>
                                                            </div>
                                                            <div className="col-md-6 b__left">
                                                              <div className="agent__page--list-items">
                                                                <div className='row'>
                                                                <div className="col-md-12">
                                                                <h2>Assigned Strategist</h2>
                                                                
                                                                {
                                                                  user.strategist && user.strategist.map((str, idx)=>{
                                                                    return(
                                                                      <div key={idx} className="strategist_box--wrapper">
                                                                        <h4 
                                                                          className="strategist_box">
                                                                          {str.strategist_full_name}
                                                                        </h4>
                                                                    </div>
                                                                    )
                                                                  })
                                                                }
                                                                </div>
                                                                </div>
                                                              </div>
                                                              <div className="agent__page--list-items">
                                                                <h2>Background Information</h2>
                                                                <h4>
                                                                  {
                                                                    (htmlDoc !== "null")?
                                                                    <ShowMore
                                                                      lines={3}
                                                                      more='View more'
                                                                      less='View less'
                                                                      anchorClass='ShowMoreToggle'
                                                                      >{htmlDoc}
                                                                    </ShowMore>
                                                                  :
                                                                   'There is no background information for this client'
                                                                  }
                                                                </h4>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="agent__page--list__contact--info">
                                                        <div className="row">
                                                          <div className="col-md-5">
                                                            <div className="list__contact">
                                                              <h2>Client Email</h2>
                                                              <h4><a href={'mailto:'+user.email}>{user.email}</a></h4>
                                                            </div>
                                                          </div>
                                                          <div className="col-md-3">
                                                            <div className="list__contact">
                                                              <h2>Phone Number</h2>
                                                              <h4><a href={'tel:'+formatPhoneNumber(user.phone_mobile)}>{user.phone_mobile ? formatPhoneNumber(user.phone_mobile) : 'Not set'}</a></h4>
                                                            </div>
                                                          </div>
                                                          <div className="col-md-4 p__rl-zero">
                                                          {user && user.role ==='agent' ? 
                                                          <div className="list__contact">
                                                            <h2>Next Appointment</h2>
                                                            {
                                                              (user.latest_appointment_date !== '') ?
                                                                <h4>
                                                                  <a>
                                                                   {user.latest_appointment_type} - {moment(user.latest_appointment_date).format("MM/DD/YY")}
                                                                  </a>
                                                                </h4>
                                                                :
                                                                
                                                                <h5>
                                                                 <a onClick={()=>this.scheduleClick(user.id, user.first_name, user.last_name)} className="red-appointment">Schedule Appointment</a>
                                                                </h5>
                                                            }
                                                            
                                                          </div>
                                                          :
                                                          ''
                                                          }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          )
                                        }) : <div className="client_blank_msg">No clients with this criteria.</div>
                                    }
                                </div>
                                  <div className="pagination-container">
                                    <Pagination
                                      activePage={this.state.inactivePage}
                                      itemsCountPerPage={this.state.inactive_client_limit}
                                      totalItemsCount={this.state.subType == "all" ? this.state.inactiveCount : this.props.usersData && this.props.usersData.activeCounts}
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageChange2.bind(this)}
                                    />
                                  </div>
                              </div>
                            {/*End close user profile*/}
                        </div>
                      }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                    /*):                   
                    <div className="welcome_agent_main">
                      <div className="agent__page--head">
                      <h1>Welcome to the Jungler!</h1>
                      <p>You do not have any clients to manage yet.</p>
                      <p>Please check back later.</p>
                      <p>You will also receive an email notification when you get a client assigned to you.</p>
                      <p>Thanks for being part of the Suburban Jungle Family!</p>
                     </div>
                    </div>
                  */}
                 </div>
                </div>
              </div>
          </div>
        </div>

        <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
           + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
            <div className="schedule__sidebar--form--wrapper">
              <div className="form-group">
                <label className="text__label--sm">Client Name</label>
                <select 
                  className="form-control custom-select" 
                  name="clientId"
                  value={this.state.clientId} 
                  onChange={this.handleChange}>
                    {clientList ? clientList :
                     <option key={0} value={this.state.clientId}>{this.state.clientFirstName+' '+this.state.clientLastName}</option>
                    }
                </select>
              </div>

              <div className="form-group">
                <label className="text__label--sm">Appointment Type</label>
                <select className="form-control custom-select" name="selectAppointment" onChange={this.handleChange}>
                  <option>Select the type of Appointment</option>
                  {appoinment}
                </select>
              </div>
              {this.state.err.selectAppointment ?
              <span className="error_msg">
              {this.state.err.selectAppointment}
              </span> : '' }

              <div className="form-group">
               <DatePicker
                  selected={this.state.startDate}
                  customInput={<DatePickerCustomInput />}
                  onChange={this.handelDateChange.bind(this, this.props.user.time_zone, this.props.user.id)}
                  showTimeSelect
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date()}
                  className="form-control custom-select date__picker"
                  value={this.state.startDate}
                  placeholderText="Pick a date">
                </DatePicker>
              </div>
              <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="text__label--sm">Start</label>
                  <select 
                    name='selectStartTime' 
                    className="form-control custom-select-small" 
                    value = {this.state.selectStartTime}  
                    onChange={this.handleChange}>
                    <option>Start Time</option>
                    {availableDateSlots}
                  </select>
                </div>
                {this.state.err.selectStartTime ?
              <span className="error_msg">
              {this.state.err.selectStartTime}
              </span> : '' }
              {this.state.err1.startTimeErr ?
              <span className="error_msg">
              {this.state.err1.startTimeErr}
              </span> : '' }
              </div>                      
              <div className="col-md-6">
                <div className="form-group">
                  <label className="text__label--sm">End</label>
                  <select 
                    name="selectEndTime" 
                    className="form-control custom-select-small 11111111" 
                    value = {this.state.selectEndTime}  
                    onChange={this.handleChange}>
                    <option>End Time</option>
                    {forEndDate}
                  </select>
                  </div>
                  {this.state.err.selectEndTime ?
                <span className="error_msg">
                {this.state.err.selectEndTime}
                </span> : '' }
                </div>
              </div>
              <div className="form-group rem__marg--btm">
                <label className="text__label--sm">Appointment Description</label>
                <textarea name="description" className="form-control custom-textarea"
                 onChange={this.handleChange}
                 placeholder="Enter description here..."></textarea>
              </div>
            </div>
          </div>
          <div className="schedule__sidebar--footer">
            <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this, this.props.user.time_zone, this.props.user.id, )} >Schedule</button>
          </div>
        </div>


      </div>
    )
  }
}

export default reduxForm({
  form: 'agent',  // a unique identifier for this form
  destroyOnUnmount: true,
})(AgentForm)
