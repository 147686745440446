import { Record } from 'immutable'
import { get, uniqBy, filter } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  clientAgents: [],
  clientChats: [],
  clientMessages: [],
  sendClientMessages: {},
  chatHistories: [],
  clientAgentsChat: [],
  chatHisotryPhase: INIT,
  fetchClientChatPhase: INIT,
  sendAgentMessagePhase: INIT,
  sendChatMessagePhase: INIT,
  fetchClientAgentListPhase: INIT,
  fetchClientAgentChatPhase: INIT,
  sendClientTextMessagePhase: INIT,
  fetchClientTextMessagePhase: INIT,
  deleteClientTextMessagePhase: INIT,
})

export const chatReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  client agent list for show chat option */
    case types.FETCH_CLIENT_AGENT_LIST: {
      return state.set('error', null).set('fetchClientAgentListPhase', LOADING)
    }
    case types.FETCH_CLIENT_AGENT_LIST_SUCCESS: {
      const agents = uniqBy(get(action, 'payload.data', []), 'agent_id')
      const clientAgents = filter(agents, (o) => o.agent_id)
      return state
        .set('clientAgents', clientAgents)
        .set('error', null)
        .set('fetchClientAgentListPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_AGENT_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientAgentListPhase', ERROR)
    }

    /* fetch client agent chat */
    case types.FETCH_CLIENT_AGENT_CHAT: {
      return state.set('error', null).set('fetchClientAgentChatPhase', LOADING)
    }
    case types.FETCH_CLIENT_AGENT_CHAT_SUCCESS: {
      const chats = get(action, 'payload.data.clientChats', [])
      return state
        .set('clientAgentsChat', chats)
        .set('error', null)
        .set('fetchClientAgentChatPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_AGENT_CHAT_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientAgentChatPhase', ERROR)
    }

    /*  list of all client chat with to show on client details page */
    case types.FETCH_CLIENT_CHAT_LIST: {
      return state.set('error', null).set('fetchClientChatPhase', LOADING)
    }
    case types.FETCH_CLIENT_CHAT_LIST_SUCCESS: {
      const chats = get(action, 'payload.data', {})
      return state
        .set('clientChats', chats)
        .set('error', null)
        .set('fetchClientChatPhase', SUCCESS)
    }
    case types.FETCH_CLIENT_CHAT_LIST_ERROR: {
      return state.set('error', action.payload.error).set('fetchClientChatPhase', ERROR)
    }

    /* chat history between sender and reciver */
    case types.GET_CHAT_HISTORY: {
      return state.set('error', null).set('chatHisotryPhase', LOADING)
    }
    case types.GET_CHAT_HISTORY_SUCCESS: {
      const chatHistories = get(action, 'payload.data', [])
      return state
        .set('error', null)
        .set('chatHisotryPhase', SUCCESS)
        .set('chatHistories', chatHistories)
    }
    case types.GET_CHAT_HISTORY_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('chatHisotryPhase', ERROR)
    }

    /* fetch client text message list  */
    case types.FETCH_CLIENT_TEXT_MESSAGE: {
      return state.set('error', null).set('fetchClientTextMessagePhase', LOADING)
    }
    case types.FETCH_CLIENT_TEXT_MESSAGE_SUCCESS: {
      return state
        .set('error', null)
        .set('fetchClientTextMessagePhase', SUCCESS)
        .set('clientMessages', get(action, 'payload.data'))
    }
    case types.FETCH_CLIENT_TEXT_MESSAGE_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('fetchClientTextMessagePhase', ERROR)
    }

    /*  send client text message using senderid and reciver id */
    case types.SEND_CHAT_MESSAGE: {
      return state.set('sendChatMessagePhase', LOADING).set('error', null)
    }
    case types.SEND_CHAT_MESSAGE_SUCCESS: {
      return state.set('error', null).set('sendChatMessagePhase', SUCCESS)
    }
    case types.SEND_CHAT_MESSAGE_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('sendChatMessagePhase', ERROR)
    }

    /*  send client agent chat message using senderid and reciver id */
    case types.SEND_AGENT_MESSAGE: {
      return state.set('sendAgentMessagePhase', LOADING).set('error', null)
    }
    case types.SEND_AGENT_MESSAGE_SUCCESS: {
      return state.set('error', null).set('sendAgentMessagePhase', SUCCESS)
    }
    case types.SEND_AGENT_MESSAGE_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('sendAgentMessagePhase', ERROR)
    }

    /*  send client text message using senderid and reciver id */
    case types.SEND_CLIENT_TEXT_MESSAGE: {
      return state.set('sendClientTextMessagePhase', LOADING).set('error', null)
    }
    case types.SEND_CLIENT_TEXT_MESSAGE_SUCCESS: {
      const { payload } = action
      return state
        .set('error', null)
        .set('sendClientTextMessagePhase', SUCCESS)
        .set('sendClientMessages', payload)
    }
    case types.SEND_CLIENT_TEXT_MESSAGE_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('sendClientTextMessagePhase', ERROR)
    }

    /* remove chat text message for chat using message id */
    case types.DELETE_CLIENT_TEXT_MESSAGE: {
      return state.set('deleteClientTextMessagePhase', LOADING).set('error', null)
    }
    case types.DELETE_CLIENT_TEXT_MESSAGE_SUCCESS: {
      return state.set('error', null).set('deleteClientTextMessagePhase', SUCCESS)
    }
    case types.DELETE_CLIENT_TEXT_MESSAGE_ERROR: {
      const { payload } = action
      return state.set('error', payload.error).set('deleteClientTextMessagePhase', ERROR)
    }
    case types.CLEAR_CLIENT_AGENT: {
      return state.set('error', null).set('clientAgents', [])
    }
    case types.CLEAR_AGENT_CHAT_PHASE: {
      return state.set('error', null).set('clientAgentsChat', [])
    }
    /* clear chat phase */
    case types.CLEAR_CHAT_PHASE: {
      return state
        .set('fetchClientChatPhase', INIT)
        .set('sendAgentMessagePhase', INIT)
        .set('sendChatMessagePhase', INIT)
        .set('fetchClientAgentListPhase', INIT)
        .set('fetchClientAgentChatPhase', INIT)
        .set('fetchClientTextMessagePhase', INIT)
        .set('sendClientTextMessagePhase', INIT)
        .set('deleteClientTextMessagePhase', INIT)
        .set('chatHisotryPhase', INIT)
    }

    default: {
      return state
    }
  }
}
