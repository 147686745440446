import 'rxjs'
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import Header from '../Header'
import axios from 'axios'
import _ from 'lodash'
import RaisedButton from 'material-ui/RaisedButton'
import Cookies from 'universal-cookie'
import styles from './styles.scss'
import Modal from 'react-modal'
import { confirmAlert } from 'react-confirm-alert'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()


const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY : 'auto',
    width: '550px',
    padding: '0 0',
    maxHeight: "95%",
    maxWidth: "95%"
  }
}

class Commute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      town_id: 0,
      id: 0,
      town_name: '',
      modalIsOpen: false,
      isDateLoading: false,
      modelType: '',
      add_scarsdaleType: '',
      add_from: '',
      add_to: '',
      add_morning: '',
      add_evening: '',
      edit_scarsdaleType: '',
      edit_from: '',
      edit_to: '',
      edit_morning: '',
      edit_evening: '',
      err: {},
      commuteData: []
    }
  }

  componentWillMount() {
    let _user = cookies.get('user')
    const { getGeneratedReport, match: { params: { townId } } } = this.props
    this.setState({ userid: _user.id, current_role: _user.role, town_id: townId })
    this.getTownName()
    this.getCommuteData()
  }

  componentWillReceiveProps(np){
    if(np.addCommutePhase !== this.props.addCommutePhase){
      this.setState({ modalIsOpen: false, isDateLoading: true, modelType: '', add_scarsdaleType: '', add_from: '', add_to: '', add_morning: '', add_evening: '' })
      this.getCommuteData()
    }
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name })
    }
  }

  async getCommuteData(){
    const {match: { params: { townId }} } = this.props
    const fetchCommute = () => axios.get(
      `${HOSTNAME}/getCommuteData?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const commuteData = await fetchCommute()
    if(commuteData && commuteData.data && commuteData.data.commute){
      let data = commuteData.data.commute
      this.setState({ commuteData: data, isDateLoading: false })
    } 
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  commuteAdd(e){
    this.setState({ modalIsOpen: true, modelType: 'add' })
  }

  closeCrossBtn(e){
    this.setState({ modalIsOpen: false, commute_id: 0, modelType: '' })
  }

  afterOpenModal(){}

  closeModal=()=>{
    this.setState({ 
      modalIsOpen: false, 
      commute_id: 0, 
      modelType: '', 
      add_scarsdaleType: '',
      add_from: '',
      add_to: '',
      add_morning: '',
      add_evening: '' 
    })
  }

  selectChange(e){
    this.setState({ add_scarsdaleType: e.target.value })
  }

  addCommute(e){
    const { addCommute } = this.props
    let err = {}
    const { add_scarsdaleType, add_from, add_to, add_morning, add_evening, commute_id, town_id } = this.state
    if( add_scarsdaleType == ''){
      err.scarsdale_type = "Select Scarsdale"
    }
    if( add_from == '' || add_from.trim() === ''){
      err.from = "Enter From"
    }
    if( add_to == '' || add_to.trim() === ''){
      err.to = "Enter To"
    }
    if( add_morning == '' || add_morning.trim() === ''){
      err.morning = "Enter Morning"
    }
    if( add_evening == '' || add_evening.trim() === ''){
      err.evening = "Enter Evening"
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {
        id: 0,
        town_id: parseInt(town_id),
        scarsdale_type: add_scarsdaleType,
        _from: add_from,
        _to: add_to,
        morning: add_morning,
        evening: add_evening
      }
      addCommute(data)
      this.setState({ isDateLoading: true })
    }
  }

  editBtn(data, e){
    this.setState({ modalIsOpen: true, edit_scarsdaleType: data.scarsdale_type, edit_from: data.from, edit_to: data.to, edit_morning: data.morning, edit_evening: data.evening, modelType: "edit", id: data.id })
  }

  editModelCommute(e){
    const { addCommute } = this.props
    let err = {}
    const { edit_scarsdaleType, edit_from, edit_to, edit_morning, edit_evening, id, town_id } = this.state
    if( edit_scarsdaleType == ''){
      err.scarsdale_type = "Select Scarsdale"
    }
    if( edit_from == '' || edit_from.trim() === ''){
      err.from = "Enter From"
    }
    if( edit_to == '' || edit_to.trim() === ''){
      err.to = "Enter To"
    }
    if( edit_morning == '' || edit_morning.trim() === ''){
      err.morning = "Enter Morning"
    }
    if( edit_evening == '' || edit_evening.trim() === ''){
      err.evening = "Enter Evening"
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {
        id: id,
        town_id: parseInt(town_id),
        scarsdale_type: edit_scarsdaleType,
        _from: edit_from,
        _to: edit_to,
        morning: edit_morning,
        evening: edit_evening
      }
      addCommute(data)
      this.setState({ isDateLoading: true })
    }
  }

  deleteBtn(data, e){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='deletealert-custom-ui allpoppadding poppinsfontsedit'>
          <div className="flexdiv">
            <h4 className="popupheader__heading">Are you sure?</h4>
            <button
              onClick={onClose}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>
          <p className="allpoppadding__para poppinsfontsedit">You want Delete this commute?</p>
            <button className="btns__fullwidth poppinsfontsedit" onClick={() => {
                this.dltCommute(data)
                onClose()
            }}>Yes</button>
          </div>
        )
      }
    })
  }

  async dltCommute(data){
    const { deleteCommute, match: { params: { townId }} } = this.props
    this.setState({ isDateLoading: true })
    const fetchDeleteData = () => axios.get(
      `${HOSTNAME}/deleteCommute?town_id=${parseInt(townId)}&id=${data.id}`
    ).catch(logoutInvalidRequest)
    const dltData = await fetchDeleteData()
    if(dltData && dltData.data && dltData.data.status){
      this.getCommuteData()
    }
  }

  render() {
    
    const { modelType, add_scarsdaleType, add_from, add_to, add_morning, add_evening, commuteData, edit_scarsdaleType, edit_from, edit_to, edit_morning, edit_evening } = this.state
    
    let trainCount = _.filter(commuteData, function(cd) { 
                          return cd.scarsdale_type == 'Train' 
                       })

    let busCount = _.filter(commuteData, function(cd) { 
                          return cd.scarsdale_type == 'Bus' 
                       })

    let ferryCount = _.filter(commuteData, function(cd) { 
                          return cd.scarsdale_type == 'Ferry' 
                       })
    let i = 0;
    let j = 0;
    let k = 0;
    return (
      <div className="town-report">
        {
          this.state.isDateLoading === true ?
          <div className="loader__agent" >
           <img src="../../img/loader2.svg" />
          </div>
          : ''
         }
         <Header title={`Commute for ${this.state.town_name}`} />
         <button className="btn__add--commute" type="submit" onClick={this.commuteAdd.bind(this)}>Add Commute</button>
         {commuteData && commuteData.length > 0 ? (
            <div className="latest__town--report table__margin">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table school__table--tabs school__table">
                        <thead>
                          <tr>
                            <th>{this.state.town_name}</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Morning</th>
                            <th>Evening</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            commuteData.map((data, index) => {
                              if(data.scarsdale_type == "Train"){
                                i = i+1;
                                return (
                                  <tr key={index}>
                                    { (i === 1) && <td className="vertical__mid" rowSpan={trainCount && trainCount.length}>{data.scarsdale_type}</td> }
                                    <td>{data.from}</td>
                                    <td>{data.to}</td>
                                    <td>{data.morning}</td>
                                    <td>{data.evening}</td>
                                    <td className="fl__right" style={{ width: '100%' }}>
                                      <button className="edit__btn" onClick={this.editBtn.bind(this, data)}><img src="../../img/edit-icon.svg" /></button>
                                      <button className="edit__btn" onClick={this.deleteBtn.bind(this, data)}><img src="../../img/delete_icon.svg" /></button>
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                        </tbody>
                        <tbody>
                          { 
                            commuteData.map((data, index) => {
                              if(data.scarsdale_type == "Bus"){
                                j = j+1;
                                return (
                                  <tr key={index}>
                                    { (j === 1) && <td className="vertical__mid" rowSpan={busCount && busCount.length}>{data.scarsdale_type}</td> }
                                    <td>{data.from}</td>
                                    <td>{data.to}</td>
                                    <td>{data.morning}</td>
                                    <td>{data.evening}</td>
                                    <td className="fl__right" style={{ width: '100%' }}>
                                      <button className="edit__btn" onClick={this.editBtn.bind(this, data)}><img src="../../img/edit-icon.svg" /></button>
                                      <button className="edit__btn" onClick={this.deleteBtn.bind(this, data)}><img src="../../img/delete_icon.svg" /></button>
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                        </tbody>
                        <tbody>
                          { 
                            commuteData.map((data, index) => {
                              if(data.scarsdale_type == "Ferry"){
                                k = k+1;
                                return (
                                  <tr key={index}>
                                    { (k === 1) && <td className="vertical__mid" rowSpan={ferryCount && ferryCount.length}>{data.scarsdale_type}</td> }
                                    <td>{data.from}</td>
                                    <td>{data.to}</td>
                                    <td>{data.morning}</td>
                                    <td>{data.evening}</td>
                                    <td className="fl__right" style={{ width: '100%' }}>
                                      <button className="edit__btn" onClick={this.editBtn.bind(this, data)}><img src="../../img/edit-icon.svg" /></button>
                                      <button className="edit__btn" onClick={this.deleteBtn.bind(this, data)}><img src="../../img/delete_icon.svg" /></button>
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (<p className="no__data--show">No Commute Data</p>)}

         <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="agentDealModalCustom allpoppadding poppinsfontsedit">
          <div className="flexdiv">
            <h4 className="popupheader__heading">{ (modelType === "add") ? "Add Commute" : "Edit Commute" }</h4>
            <button
              onClick={this.closeCrossBtn.bind(this)}
              type="button"
              id="close_user_modal"
              className="close_img close_img--white"
              data-dismiss="modal"
              aria-label="Close"
            >
            <img src="../../img/close_btn.svg"/>
            </button>
          </div>

          {/* <div className="form-group">
            <h1 className="headModal">{ (modelType === "add") ? "Add Commute" : "Edit Commute" }</h1>
            <button onClick={this.closeCrossBtn.bind(this)} type="button" className="btn close-btn closeModal" data-dismiss="modal">
            <img data-dismiss="modal" src="./img/login_close.svg"/></button>
          </div> */}

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Select Scarsdale</label>
            <select className="selectinputs__global" value={ (modelType === "add") ? add_scarsdaleType : edit_scarsdaleType } 
              onChange={this.selectChange.bind(this)}>
              <option value=''>Select</option>
              <option value="Train">Train</option>
              <option value="Bus">Bus</option>
              <option value="Ferry">Ferry</option>
            </select>
          </div>
          { this.state.err.scarsdale_type ?
          <span className="error_msg town__err">
          {this.state.err.scarsdale_type}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">From</label>
            <input 
              name={ (modelType === "add") ? "add_from" : "edit_from" } 
              className="textinputs__global" 
              rows="2" 
              value={ (modelType === "add") ? add_from : edit_from }
              onChange={this.handleChange.bind(this)}/>
          </div> 
          { this.state.err.from ?
          <span className="error_msg town__err">
          {this.state.err.from}
          </span> : '' }         

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">To</label>
            <input 
              name={ (modelType === "add") ? "add_to" : "edit_to" }
              className="textinputs__global" 
              rows="2" 
              value={ (modelType === "add") ? add_to : edit_to }
              onChange={this.handleChange.bind(this)}/>
          </div>
          { this.state.err.to ?
          <span className="error_msg town__err">
          {this.state.err.to}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Morning</label>
            <input 
              name={ (modelType === "add") ? "add_morning" : "edit_morning"}
              className="textinputs__global" 
              rows="2" 
              value = { (modelType === "add") ? add_morning : edit_morning }
              onChange={this.handleChange.bind(this)}/>
          </div>
          { this.state.err.morning ?
          <span className="error_msg town__err">
          {this.state.err.morning}
          </span> : '' }

          <div className="form-group">
          <label className="labelinputs__global labelcapital__global">Evening</label>
            <input 
              name={ (modelType === "add") ? "add_evening" : "edit_evening" }
              className="textinputs__global" 
              rows="2" 
              value={ (modelType === "add") ? add_evening : edit_evening}
              onChange={this.handleChange.bind(this)}/>
          </div>
          { this.state.err.evening ?
          <span className="error_msg town__err">
          {this.state.err.evening }
          </span> : '' }

          <div className="modalbtns modalbtns__new">
            {
              (modelType === "add") ?
                <button
                  className="btns__fullwidth poppinsfontsedit"
                  type="button"
                  onClick={this.addCommute.bind(this)}>
                  Save 
                </button>
              : 
                <button
                  className="btns__fullwidth poppinsfontsedit"
                  type="button"
                  onClick={this.editModelCommute.bind(this)}>
                  Edit 
                </button>
            }
            
          </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default reduxForm({
  form: 'Commute', // a unique identifier for this form
  destroyOnUnmount: true
})(Commute)
