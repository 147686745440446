import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import _ from "lodash"
import styles from './styles.scss'
import moment from 'moment'
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import Tooltip from "react-tooltip";
import Modal1 from 'react-responsive-modal';
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
import { DateRange } from 'react-date-range'
import Modal2 from 'react-modal';
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let _user = ''

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important'
  }
}

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class ClientsAssignToAgents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)),
      fromDate: moment().add(-1, 'month').startOf('month'),
      // fromDate: moment().add(-5, 'years').startOf('month'),
      // endDate: moment().add(-1, 'month').endOf('month'),
      endDate: moment(),
      client_strategist:[],
      strategistDropdown: false,
      type: 'assignPerAgent',
      sortByKey:'name',
      sortByType: 0,
      search:'',
      agentName: '',
      currentAgentClients:[],
      openClient: false,
      selectedIndex: 'all',
      datePickerModal: false,
      modalIsOpen: false,
      defaultStartDate: moment().add(-1, 'month').startOf('month'),
      defaultEndDate: moment()
    }
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user && _user.role === "agent" || _user && _user.role === "local"){
      this.props.history.push({ pathname: '/agent/dashboard'})
    }
    if(_user && _user.role === "client"){
      this.props.history.push({ pathname: '/clients'})
    }
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      // strData.strategist_arr = this.state.client_strategist
      // this.props.getStrategistList(strData)

      this.props.getClientsPerAgents(strData)
      this.setState({isLoadingData: true})
      // this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.clientsPerAgentsPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  componentDidMount() {
    let self = this;
    // $(document).click(function(event) {
    //   if (!$(event.target).closest("#dropdownBtn").length) {
    //     if ($("#dropdownBtn").is(":visible")) {
    //       self.setState({ strategistDropdown: false });
    //     }
    //   }
    // });

    // Example 2
    $('.pane--table2').scroll(function() {
      $('.pane--table2 table').width($('.pane--table2').width() + $('.pane--table2').scrollLeft());
    });

  }

   datePickerClick(e){
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate })
  }

  closeModalIcon(e){
    this.setState({modalIsOpen: false, fromDate: this.state.defaultStartDate, endDate: this.state.defaultEndDate})
  }

  handleSelect(range){
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate:endDate })
  }

  onSubmitDateRanges (e) {
    let startDate = this.state.fromDate
    let endDate = this.state.endDate
      this.setState({ isLoadingData: true, datePickerModal: false, modalIsOpen: false, fromDate: this.state.fromDate, endDate: this.state.endDate }, ()=>{

        let start_from_date =  moment(startDate._d).format("YYYY-MM-DD HH:mm:ss")
        let end_to_date =  moment(endDate._d).format("YYYY-MM-DD HH:mm:ss")
        setTimeout(function(e){
          const strData = {}
          if(this.state.selectedIndex === "all"){
            strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
            strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
          }else{
            strData.from = start_from_date
            strData.to = end_to_date
          }
          strData.dateType = "from_date"
          strData.type = this.state.type
          strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
          this.props.getClientsPerAgents(strData)
        }.bind(this), 300)
    })
  }

  // handleFromDate(date) {
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex === "all"){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.from = selectDate
  //       strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "from_date"
  //     strData.type = this.state.type
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({fromDate: date})
  // }

  // handEndDate(date){
  //   this.setState({ isLoadingData: true })
  //   let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
  //   setTimeout(function(e){
  //     const strData = {}
  //     if(this.state.selectedIndex === "all"){
  //       strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
  //       strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
  //     }else{
  //       strData.to = selectDate
  //       strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
  //     }
  //     strData.dateType = "end_date"
  //     strData.type = this.state.type
  //     strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
  //     // strData.strategist_arr = this.state.client_strategist
  //     // this.props.getStrategistList(strData)
  //     this.props.getClientsPerAgents(strData)
  //   }.bind(this), 300)
  //   this.setState({endDate: date})
  // }


  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }else{
      sortByType == 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType}, ()=>{
      const strData = {}
      if(this.state.selectedIndex === "all"){
        strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      }else{
        strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
        strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      strData.type = this.state.type
      strData.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
      this.props.getClientsPerAgents(strData)
    })
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  onOpenClientModal(agent) {
    const clients = agent && agent.clientNames
    if(clients.length >= 1){
      this.setState({ agentName: agent.name, openClient: true, currentAgentClients: clients })
    }
  }

  onCloseClientModal() {
    this.setState({ agentName: '', currentAgentClients: [], openClient: false })
  }

  filterDate(e){
    this.setState({ isLoadingData: true },() => {})
    if(e.target.value === "all"){
       this.setState({ modalIsOpen: false })
      const strData = {}
      strData.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment().format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }else{
      const strData = {}
      strData.from = moment(this.state.fromDate).format("YYYY-MM-DD HH:mm:ss")
      strData.to = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
      strData.type = this.state.type
      this.props.getClientsPerAgents(strData)
    }
    this.setState({ selectedIndex: e.target.value },() => {})
  }

  render() {
    const { clientsPerAgentsData, clientsPerAgentsPhase } = this.props
    let filterList  = []
    const { openClient, selectedIndex } = this.state
    if(clientsPerAgentsPhase == 'success' && clientsPerAgentsData && clientsPerAgentsData.length > 0){
      filterList = clientsPerAgentsData.filter(
        (agent) =>{
          return (
            (agent.name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }
    return (
      <div>
        <div className="container-fluid">
            <div className="row">
              <div className="all__reporting--headers">
                <div className="logo__header">
                  <a>Clients Per Agents</a>
                </div>
                <div className="close__btn">
                  <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                </div>
              </div>
            </div>
          </div>

        <div className="container-fluid padd-l-r-50 remove__padd--lr">
          <div className="row">
            <div className="col-md-12"><h4 className="stragist-heading"><span>{clientsPerAgentsData && clientsPerAgentsData.length}</span> Agents</h4></div>
            <div className="col-md-12">
            <div className="reports__flex">
              <div className="right__cont right__cont--full">
              <div className="all__custom--wrapper">
                <select 
                className="date__picker"
                  name="selectedIndex"
                  value = {this.state.selectedIndex} 
                  onChange={this.filterDate.bind(this)}>
                  <option value="all">All</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {
                (this.state.selectedIndex !== "all") &&
                <div className="datepicker__fix all__custom--wrapper">
                  <div onClick={this.datePickerClick.bind(this)} className="click__btn date__picker">{`${moment(this.state.fromDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</div>
                  {
                    (this.state.modalIsOpen) &&
                    <div style={{ position: 'absolute' }}>
                      <Modal2
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}           
                        contentLabel="Example Modal">
                        <div className="modal-dialog modal-dialog-centered custom__calendar--wrapper" role="document">
                          <div className="modal-content">             
                            <div className="modal-body text-center">
                            <div className="calendar__header">
                              <h4>Date Range</h4>
                              <div className="calendar__close"><img onClick={this.closeModalIcon.bind(this)} data-dismiss="modal" src="./img/close_btn.svg"/></div>
                            </div>
                            <DateRange
                              onInit={this.handleSelect.bind(this)}
                              onChange={this.handleSelect.bind(this)}
                              startDate={this.state.fromDate}
                              endDate={this.state.endDate}
                            />
                            <button className="sub__btn" onClick={this.onSubmitDateRanges.bind(this)}>Submit</button>
                            </div>               
                          </div>
                        </div>
                      </Modal2>
                    </div>
                  }
                </div>
              }
              
            <ExcelFile element={<button className="reporting__export--btn exporthide">Export Data</button>}>
              <ExcelSheet data={clientsPerAgentsData} name="clients_per_agents">
                  <ExcelColumn label="Agent Name" value="name"/>
                  <ExcelColumn label="Total number of clients" value="totalNumberOfClients"/>
                  <ExcelColumn label="Total - My Clients" value="totalClients"/>
                  <ExcelColumn label="Green" value="totalGreen"/>
                  <ExcelColumn label="Green - Actively Engaged" value="totalActivelyEngaged"/>
                  <ExcelColumn label="Green - Somewhat Engaged" value="totalSomewhatEngaged"/>
                  <ExcelColumn label="Green - Not Very Engaged" value="totalNotVeryEngaged"/>
                  <ExcelColumn label="UJ Green - Actively Engaged" value="totalUJActivelyEngaged"/>
                  <ExcelColumn label="UJ Green - Somewhat Engaged" value="totalUJSomewhatEngaged"/>
                  <ExcelColumn label="UJ Green - Not Very Engaged" value="totalUJNotVeryEngaged"/>
                  <ExcelColumn label="Listing" value="totalListing"/>
              </ExcelSheet>
            </ExcelFile>
            </div>
            </div>

            </div>
          </div>
        </div>

       
        
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <main className="jungler__page--container jungler__page--padding posi__sticky">
              <span className="typeahead">
                <input 
                  className="costom__search--report typeahead-input reportsearchsminus add" 
                  onChange={this.onSearch.bind(this)}
                  type="text" 
                  name="search" 
                  placeholder="Search"/>
              </span>
              <div className="pane pane--table2 useslist__extra">
              <div className="table-responsive">
                <table className="table table-striped" style={{ minWidth: '1140px'}}>
                <thead>
                  <tr>
                    <th onClick={this.sortData.bind(this , 'name')} className="maxwd forcursor">Agent Name</th>
                    {/*<th className="text-center multilines">Total number of clients <span title="Based on all selected tags in this report" className="glyphicon glyphicon-info-sign">&nbsp;</span></th>*/}
                    <th onClick={this.sortData.bind(this , 'total_no_of_clients')} className="maxwd forcursor">Total number of clients</th>
                    <th onClick={this.sortData.bind(this , 'total_my_clients')} className="maxwd forcursor">Total - My Clients</th>
                    <th className="">Green</th>
                    <th onClick={this.sortData.bind(this , 'green_actively_engaged')} className="maxwd forcursor">Green - Actively Engaged</th>
                    <th onClick={this.sortData.bind(this , 'green_somewhat_engaged')} className="maxwd forcursor">Green - Somewhat Engaged</th>
                    <th onClick={this.sortData.bind(this , 'green_not_very_engaged')} className="maxwd forcursor">Green - Not Very Engaged</th>
                    <th className="">UJ Green - Actively Engaged</th>
                    <th className="">UJ Green - Somewhat Engaged</th>
                    <th className="">UJ Green - Not Very Engaged</th>
                    <th className="">Listing</th>
                  </tr>
                </thead>
                  {this.state.isLoadingData === true ?
                    <tbody className="loader__spacing">
                      
                        <img src="../../img/loader2.svg" />
                        <h5>Your clients report is generating, please wait...</h5>

                    </tbody>
                  :
                  <tbody>
                  {
                    filterList && filterList.map((str, index)=>{
                      return(
                        <tr key={index} className="forcursor" >
                          <td className="maxwd">{str.name}</td>
                          {/*<td className="text-center">{str.totalClients}</td>*/}
                          <td className="">{str.totalNumberOfClients}</td>
                          <td className=""><div className={(str.totalClients >= 1 ? "link" : '')} onClick={this.onOpenClientModal.bind(this, str)} >{str.totalClients}</div></td>
                          <td className="">{str.totalGreen}</td>
                          <td className="">{str.totalActivelyEngaged}</td>
                          <td className="">{str.totalSomewhatEngaged}</td>
                          <td className="">{str.totalNotVeryEngaged}</td>
                          <td className="">{str.totalUJActivelyEngaged}</td>
                          <td className="">{str.totalUJSomewhatEngaged}</td>
                          <td className="">{str.totalUJNotVeryEngaged}</td>
                          <td className="">{str.totalListing}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody> 
                  }
                </table>
              </div>
              </div>    
              <div className="filter__container filter__container--users">
                <div className="filter__sub--container filter__sub--container-edit">
                  <div className="jungler__list">
                    <div className="table-responsive">
                   
                    </div>
                  </div>
                </div>
              </div>
            </main>
              <div>
                <Modal2 isOpen={openClient} center className="widthofmodal">
                  <div>
                    <div className="flexdiv">
                      <h4 className="popupheader__heading">{'Agent - ' + this.state.agentName+`'s`+' client(s)'}</h4>
                      <div className="close__btn close_img--white" onClick={this.onCloseClientModal.bind(this)}><img src="img/close_btn.svg"/></div>
                    </div>
                    <div className="allpoppadding__paranew__div">
                      {this.state.currentAgentClients && this.state.currentAgentClients.map((client, index) => {
                        return <div className="allpoppadding__paranew" key={'client-name-'+index}>{client.client_name}</div>
                      })}
                    </div>
                  </div>
                  <button className="savebtn__assign" onClick={this.onCloseClientModal.bind(this)}>Close</button>
                </Modal2>
              </div>
              {
                clientsPerAgentsData && clientsPerAgentsData.length > 0 ?
                 <div></div>/*<div className="dashboard-footer text-center mar__top--zero"><h4>Suburban Jungler - Clients Per Agents</h4></div>*/
                :
                 <div className="dashboard-footer text-center mar__top--zero">Data Not Found</div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'ClientsAssignToAgentslist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientsAssignToAgents)
