import React, { useEffect } from 'react'
import ReactModal from 'react-modal'

import { SUCCESS } from '../../../../../../../constants/phase'
import { CloseIcon } from '../../../../../../../components/icons'

export default function DeleteTextClient({
  openModel,
  clientTextId,
  closeDeleteTextModal,
  ...props
}) {
  // submit or delete client text message
  const handelDeleteSubmission = () => {
    if (clientTextId) {
      props.deleteClientTextMessage({ messageId: clientTextId })
      closeDeleteTextModal()
    }
  }
  // cwrp on text message delete success
  useEffect(() => {
    if (props.deleteClientTextMessagePhase === SUCCESS) {
      const clientId = props.match.params.id
      props.fetchClientTextMessage({ clientId })
      props.clearChatPhase()
    }
  }, [props.deleteClientTextMessagePhase])

  return (
    <ReactModal
      isOpen={openModel}
      contentLabel="Delete User"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-body react-modal-body-sm">
          <button
            type="button"
            className="btn react-modal-close react-modal-close-sm"
            onClick={closeDeleteTextModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title mb__13">Are you sure?</h5>
          <p className="react-modal-subtitle mb__3">
            This will remove the text message information and you will need to re enter
            if needed.
          </p>
          <div className="text-right">
            <button
              onClick={handelDeleteSubmission}
              className="btn btn__btn btn-dark w__150"
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
