import React from 'react';
import '../theme/variables.scss'

const DashboardIcon = () => {
  return (
    <svg width={26} height={26} viewBox="0 0 40 40" fill="none">
      <path d="M0 5a5 5 0 015-5h30a5 5 0 015 5v35H0V5z" fill="#444" />
      <path fill="#9E9E9E" d="M0 38h40v2H0z" />
      <path
        d="M5 22.8c0-.442.448-.8 1-.8h4c.552 0 1 .358 1 .8V38H5V22.8zM17 27.75c0-.414.448-.75 1-.75h4c.552 0 1 .336 1 .75V38h-6V27.75z"
        fill="#EBEBEB"
      />
      <path
        d="M29 14.75c0-.414.448-.75 1-.75h4c.552 0 1 .336 1 .75V38h-6V14.75z"
        fill="#9E9E9E"
      />
    </svg>
  )
}
const DashboardColorIcon = ({className}) => {
  return (
    <svg width={26} height={26} viewBox="0 0 30 30" fill="none" className={className}>
      <path d="M0 5a5 5 0 015-5h20a5 5 0 015 5v25H0V5z" fill="#0065A1" />
      <path fill="#fff" d="M0 28.5h30V30H0z" />
      <path
        d="M3.75 17.1c0-.331.336-.6.75-.6h3c.414 0 .75.269.75.6v11.4h-4.5V17.1z"
        fill="#000"
      />
      <path
        d="M12.749 20.813c0-.31.335-.563.75-.563h3c.414 0 .75.252.75.563V28.5h-4.5v-7.687z"
        fill="#fff"
      />
      <path
        d="M21.749 11.062c0-.31.336-.562.75-.562h3c.414 0 .75.252.75.562V28.5h-4.5V11.062z"
        fill="#000"
      />
    </svg>
  )
}
const LeadsIcon = () => {
  return (
    <svg width={28} height={28} viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
        fill="#444"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={40}
        height={40}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 40c8.56 0 15.5-2.32 15.5-2.32C35.843 29.021 29.06 22 20.5 22 11.94 22 5 29.02 5 37.68c0 0 6.94 2.32 15.5 2.32z"
          fill="#D8D8D8"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13a8 8 0 1116 0 8 8 0 01-16 0zm8-5.037a5.037 5.037 0 100 10.074 5.037 5.037 0 000-10.074z"
        fill="#979797"
      />
    </svg>
  )
}
const LeadsColorIcon = () => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
        fill="#0065A1"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={28}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.35 28c5.992 0 10.85-1.624 10.85-1.624-.11-6.062-4.858-10.976-10.85-10.976S3.5 20.314 3.5 26.376c0 0 4.858 1.624 10.85 1.624z"
          fill="#fff"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.402 9.1a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0zm5.6-3.526a3.526 3.526 0 100 7.052 3.526 3.526 0 000-7.052z"
        fill="#fff"
      />
    </svg>
  )
}
const MylistingIcon = () => {
  return (
    <svg width={22} height={28} viewBox="0 0 22 28" fill="none">
      <path
        d="M0 6.504a2.89 2.89 0 012.89-2.89h15.9a2.89 2.89 0 012.89 2.89v18.067a2.89 2.89 0 01-2.89 2.89H2.89A2.89 2.89 0 010 24.572V6.504z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.036 10.964a1.553 1.553 0 100-3.105 1.553 1.553 0 000 3.105zm0 6.211a1.553 1.553 0 100-3.105 1.553 1.553 0 000 3.105zm1.552 4.658a1.553 1.553 0 11-3.105 0 1.553 1.553 0 013.105 0z"
        fill="#D8D8D8"
      />
      <path
        opacity={0.76}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.831 9.023c0-.643.463-1.164 1.035-1.164h10.35c.572 0 1.036.521 1.036 1.164V9.8c0 .643-.464 1.164-1.035 1.164H7.867c-.573 0-1.036-.521-1.036-1.164v-.777zm0 6.211c0-.643.463-1.164 1.035-1.164h10.35c.572 0 1.035.521 1.035 1.164v.777c0 .643-.463 1.164-1.035 1.164H7.866c-.572 0-1.035-.521-1.035-1.164v-.777zm1.035 5.046c-.572 0-1.035.521-1.035 1.165v.776c0 .643.463 1.164 1.035 1.164h10.35c.572 0 1.035-.521 1.035-1.164v-.776c0-.643-.463-1.165-1.035-1.165H7.866z"
        fill="#444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.07 5.059a.186.186 0 00.173-.255 2.89 2.89 0 00-2.275-1.788l-.875-.125V1.807c0-.998-.81-1.807-1.807-1.807h-2.168C9.12 0 8.311.809 8.311 1.807V2.89l-.875.125a2.89 2.89 0 00-2.275 1.788.186.186 0 00.173.255H17.07zM11.2 3.614a1.084 1.084 0 100-2.168 1.084 1.084 0 000 2.168z"
        fill="#444"
      />
    </svg>
  )
}
const MylistingColorIcon = () => {
  return (
    <svg width={22} height={28} viewBox="0 0 24 30" fill="none">
      <path
        d="M0 7.105a3.158 3.158 0 013.158-3.158h17.368a3.158 3.158 0 013.158 3.158v19.737A3.158 3.158 0 0120.526 30H3.158A3.158 3.158 0 010 26.842V7.105z"
        fill="#0065A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41 11.978a1.696 1.696 0 100-3.392 1.696 1.696 0 000 3.392zm0 6.784a1.696 1.696 0 100-3.392 1.696 1.696 0 000 3.393zm1.696 5.089a1.696 1.696 0 11-3.392 0 1.696 1.696 0 013.392 0z"
        fill="#fff"
      />
      <path
        opacity={0.76}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.463 9.858c0-.703.506-1.272 1.13-1.272h11.308c.625 0 1.131.57 1.131 1.272v.848c0 .702-.506 1.272-1.13 1.272H8.593c-.625 0-1.131-.57-1.131-1.272v-.848zm0 6.784c0-.702.506-1.272 1.13-1.272h11.308c.625 0 1.131.57 1.131 1.272v.848c0 .703-.506 1.273-1.13 1.273H8.593c-.625 0-1.13-.57-1.13-1.273v-.848zm1.13 5.513c-.624 0-1.13.57-1.13 1.272v.848c0 .703.506 1.272 1.13 1.272h11.308c.625 0 1.131-.57 1.131-1.272v-.848c0-.703-.506-1.272-1.13-1.272H8.593z"
        fill="#444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.647 5.526a.203.203 0 00.189-.278 3.158 3.158 0 00-2.485-1.954l-.956-.136V1.974C15.395.884 14.51 0 13.42 0h-2.368c-1.09 0-1.974.884-1.974 1.974v1.184l-.956.136a3.158 3.158 0 00-2.485 1.954.203.203 0 00.188.278h12.821zm-6.41-1.579a1.184 1.184 0 100-2.368 1.184 1.184 0 000 2.368z"
        fill="#444"
      />
    </svg>
  )
}

const ClientsColorIcon = ({className}) => {
  return (
    <svg width={28} height={28} viewBox="0 0 18 18" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18A9 9 0 109 0a9 9 0 000 18z"
        fill="#0065A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.561 17.99c.181.005.364.008.548.01a8.98 8.98 0 006.744-3.165c-.91-2.86-3.506-4.935-6.628-4.935-3.25 0-5.982 2.249-6.756 5.293a8.974 8.974 0 006.092 2.796z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.401 5.85a3.6 3.6 0 117.2 0 3.6 3.6 0 01-7.2 0zm3.6-2.267a2.267 2.267 0 100 4.534 2.267 2.267 0 000-4.534z"
        fill="#fff"
      />
    </svg>
  )
}
const YourAgentIcon = ({className}) => {
  return (
    <svg width={18} height={23} viewBox="0 0 18 23" fill="none" className={className}>
      <path
        d="M17.712 4.543c.173 0 .288-.115.288-.287V2.243c0-.173-.115-.288-.288-.288H3.566V.288C3.566.115 3.45 0 3.278 0H1.265C1.093 0 .978.115.978.288v1.667h-.69c-.173 0-.288.115-.288.288v2.013c0 .172.115.287.288.287h.69v17.281c0 .173.115.288.287.288h2.013c.172 0 .288-.115.288-.288V4.544H5.55V5.75h-.72c-.172 0-.287.115-.287.287v9.374c0 .173.115.288.288.288h12.853c.172 0 .287-.115.287-.288V6.038c0-.172-.115-.287-.287-.287h-.95V4.543h.978zM1.552.575H2.99v1.38H1.553V.575zM.605 2.53h16.82v1.438H.605V2.53zM2.99 21.537H1.553V4.543H2.99v16.994zM14.406 6.326l-9.288 4.6v-4.6h9.288zm-8.54 8.799l11.53-5.722v5.722H5.866zm11.53-6.355L5.118 14.837V11.56L15.7 6.326h1.696V8.77zM16.16 5.75H6.153V4.544H16.16v1.208z"
        fill="#979797"
      />
    </svg>
  )
}
const YourAgentColorIcon = ({className}) => {
  return (
    <svg width={18} height={23} viewBox="0 0 18 23" fill="none" className={className}>
      <path
        d="M17.712 4.543c.173 0 .288-.115.288-.287V2.243c0-.173-.115-.288-.288-.288H3.566V.288C3.566.115 3.45 0 3.278 0H1.265C1.093 0 .978.115.978.288v1.667h-.69c-.173 0-.288.115-.288.288v2.013c0 .172.115.287.288.287h.69v17.281c0 .173.115.288.287.288h2.013c.172 0 .288-.115.288-.288V4.544H5.55V5.75h-.72c-.172 0-.287.115-.287.287v9.374c0 .173.115.288.288.288h12.853c.172 0 .287-.115.287-.288V6.038c0-.172-.115-.287-.287-.287h-.95V4.543h.978zM1.552.575H2.99v1.38H1.553V.575zM.605 2.53h16.82v1.438H.605V2.53zM2.99 21.537H1.553V4.543H2.99v16.994zM14.406 6.326l-9.288 4.6v-4.6h9.288zm-8.54 8.799l11.53-5.722v5.722H5.866zm11.53-6.355L5.118 14.837V11.56L15.7 6.326h1.696V8.77zM16.16 5.75H6.153V4.544H16.16v1.208z"
        fill="#0065A1"
      />
    </svg>
  )
}
const AgentAppointmentsIcon = ({className}) => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" className={className}>
      <path
        d="M4.256 0a.393.393 0 00-.386.4v3.401c0 .221.173.4.386.4.213 0 .386-.179.386-.4v-3.4C4.642.178 4.47 0 4.256 0zm8.5 0a.393.393 0 00-.386.4v3.401c0 .221.173.4.386.4.213 0 .386-.179.386-.4v-3.4c0-.222-.173-.401-.386-.401zM1.551 1.2C.693 1.2 0 1.918 0 2.807v3.595h17V2.807c0-.89-.693-1.607-1.552-1.607H13.53v1.707c.237.22.386.539.386.894 0 .663-.519 1.2-1.159 1.2s-1.16-.537-1.16-1.2c0-.355.15-.674.387-.894V1.2h-6.96v1.7c.24.22.392.543.392.901 0 .663-.519 1.2-1.159 1.2s-1.16-.537-1.16-1.2c0-.352.148-.668.381-.888V1.2H1.551zM0 7.202v7.596c0 .89.693 1.607 1.551 1.607h13.897c.86 0 1.552-.717 1.552-1.607V7.202H0z"
        fill="#979797"
      />
    </svg>
  )
}
const AgentAppointmentsColorIcon = ({className}) => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" className={className}>
      <path
        d="M4.256 0a.393.393 0 00-.386.4v3.401c0 .221.173.4.386.4.213 0 .386-.179.386-.4v-3.4C4.642.178 4.47 0 4.256 0zm8.5 0a.393.393 0 00-.386.4v3.401c0 .221.173.4.386.4.213 0 .386-.179.386-.4v-3.4c0-.222-.173-.401-.386-.401zM1.551 1.2C.693 1.2 0 1.918 0 2.807v3.595h17V2.807c0-.89-.693-1.607-1.552-1.607H13.53v1.707c.237.22.386.539.386.894 0 .663-.519 1.2-1.159 1.2s-1.16-.537-1.16-1.2c0-.355.15-.674.387-.894V1.2h-6.96v1.7c.24.22.392.543.392.901 0 .663-.519 1.2-1.159 1.2s-1.16-.537-1.16-1.2c0-.352.148-.668.381-.888V1.2H1.551zM0 7.202v7.596c0 .89.693 1.607 1.551 1.607h13.897c.86 0 1.552-.717 1.552-1.607V7.202H0z"
        fill="#0065A1"
      />
    </svg>
  )
}
const TownCheatSheetIcon = ({className}) => {
  return (
    <svg width={18} height={20} viewBox="0 0 18 20" fill="none" className={className}>
      <path
        d="M15.407 9.506c-.413 0-.864.24-1.207.644-.31.364-.68 1.032-.68 2.147v.96a.36.36 0 01-.36.36c-1.29 0-2.34 1.05-2.34 2.34a.36.36 0 01-.72 0 3.064 3.064 0 012.7-3.04v-.26h-8v.761c0 1.888.94 3.64 2.516 4.689.1.067.16.18.16.3v.384c0 .203.164.366.366.366h5.172a.366.366 0 00.366-.366v-.384c0-.12.06-.233.16-.3a5.62 5.62 0 002.516-4.689s-.006-2.46-.006-3.266c0-.583-.45-.646-.643-.646zM17 0H1C.45 0 0 .449 0 1v9.936c0 .552.449 1 1 1h11.816c.075-1.121.484-1.84.835-2.253.479-.562 1.135-.897 1.756-.897.815 0 1.363.549 1.363 1.366l.003 1.785h.226c.552 0 1.001-.45 1.001-1.001V1.001C18 .449 17.551 0 17 0zM9 8.34H3.78v-.72H9v.72zm5.22-2.37H3.78v-.72h10.44v.72zm0-2.37H3.78v-.72h10.44v.72z"
        fill="#979797"
      />
    </svg>
  )
}
const TownCheatSheetColorIcon = ({className}) => {
  return (
    <svg width={18} height={20} viewBox="0 0 18 20" fill="none" className={className}>
      <path
        d="M15.407 9.506c-.413 0-.864.24-1.207.644-.31.364-.68 1.032-.68 2.147v.96a.36.36 0 01-.36.36c-1.29 0-2.34 1.05-2.34 2.34a.36.36 0 01-.72 0 3.064 3.064 0 012.7-3.04v-.26h-8v.761c0 1.888.94 3.64 2.516 4.689.1.067.16.18.16.3v.384c0 .203.164.366.366.366h5.172a.366.366 0 00.366-.366v-.384c0-.12.06-.233.16-.3a5.62 5.62 0 002.516-4.689s-.006-2.46-.006-3.266c0-.583-.45-.646-.643-.646zM17 0H1C.45 0 0 .449 0 1v9.936c0 .552.449 1 1 1h11.816c.075-1.121.484-1.84.835-2.253.479-.562 1.135-.897 1.756-.897.815 0 1.363.549 1.363 1.366l.003 1.785h.226c.552 0 1.001-.45 1.001-1.001V1.001C18 .449 17.551 0 17 0zM9 8.34H3.78v-.72H9v.72zm5.22-2.37H3.78v-.72h10.44v.72zm0-2.37H3.78v-.72h10.44v.72z"
        fill="#0065A1"
      />
    </svg>
  )
}
const TownsIcon = ({className}) => {
  return (
    <svg width={17} height={19} viewBox="0 0 17 19" fill="none" className={className}>
      <path
        d="M12.3 12.203V9.26h-.642l2.67-2.723.963.98v-.5h.748V8.28L17 9.26h-.64v2.943H12.3zM.64 18.74v-2.943H0l2.67-2.723.962.98v-.5h.748v1.262l.961.981h-.64v2.943H.64zM12.3 18.74v-2.943h-.642l2.67-2.723.963.98v-.5h.748v1.262l.961.981h-.64v2.943H12.3zM.64 12.203V9.26H0l2.67-2.723.962.98v-.5h.748V8.28l.961.981h-.64v2.943H.64zM.64 5.665V2.722H0L2.67 0l.962.98v-.5h.748v1.262l.961.98h-.64v2.943H.64zM12.3 5.665V2.722h-.642L14.329 0l.963.98v-.5h.748v1.262l.961.98h-.64v2.943H12.3zM11.137 0H6.124v18.74h5.013V0z"
        fill="#979797"
      />
      <path
        d="M8.763 17.257h-.264v-1.213h.264v1.213zm0-2.08h-.264v-1.213h.264v1.213zm0-2.08h-.264v-1.213h.264v1.213zm0-2.08h-.264V9.803h.264v1.214zm0-2.08h-.264V7.723h.264v1.214zm0-2.08h-.264V5.642h.264v1.213zm0-2.08h-.264V3.562h.264v1.213zm0-2.08h-.264V1.482h.264v1.213z"
        fill="#979797"
      />
    </svg>
  )
}
const TownsColorIcon = ({className}) => {
  return (
    <svg width={17} height={19} viewBox="0 0 17 19" fill="none" className={className}>
      <path
        d="M12.3 12.203V9.26h-.642l2.67-2.723.963.98v-.5h.748V8.28L17 9.26h-.64v2.943H12.3zM.64 18.74v-2.943H0l2.67-2.723.962.98v-.5h.748v1.262l.961.981h-.64v2.943H.64zM12.3 18.74v-2.943h-.642l2.67-2.723.963.98v-.5h.748v1.262l.961.981h-.64v2.943H12.3zM.64 12.203V9.26H0l2.67-2.723.962.98v-.5h.748V8.28l.961.981h-.64v2.943H.64zM.64 5.665V2.722H0L2.67 0l.962.98v-.5h.748v1.262l.961.98h-.64v2.943H.64zM12.3 5.665V2.722h-.642L14.329 0l.963.98v-.5h.748v1.262l.961.98h-.64v2.943H12.3zM11.137 0H6.124v18.74h5.013V0z"
        fill="#0065A1"
      />
      <path
        d="M8.763 17.257h-.264v-1.213h.264v1.213zm0-2.08h-.264v-1.213h.264v1.213zm0-2.08h-.264v-1.213h.264v1.213zm0-2.08h-.264V9.803h.264v1.214zm0-2.08h-.264V7.723h.264v1.214zm0-2.08h-.264V5.642h.264v1.213zm0-2.08h-.264V3.562h.264v1.213zm0-2.08h-.264V1.482h.264v1.213z"
        fill="#0065A1"
      />
    </svg>
  )
}
const JunglerUsersIcon = ({className}) => {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" className={className}>
      <path
        d="M7.19 7.955c-4.455 0-6.66 3.977-7.19 6.363C1.326 15.644 3.583 17.5 7.19 17.5c3.606 0 6.658-2.121 7.454-3.977-.796-2.387-3-5.568-7.455-5.568z"
        fill="#979797"
      />
      <path
        d="M14.56 14.06c.293-.292.44-.646.44-1.06 0-.414-.146-.768-.44-1.06a1.445 1.445 0 00-1.06-.44c-.414 0-.768.146-1.06.44-.293.292-.44.646-.44 1.06 0 .414.146.768.44 1.06.292.293.646.44 1.06.44.414 0 .768-.146 1.06-.44zM18 12.361v1.301a.226.226 0 01-.047.135.178.178 0 01-.117.076l-1.084.164c-.074.211-.15.389-.229.533.137.196.346.465.627.809.04.047.059.096.059.146 0 .051-.018.096-.053.135a5.958 5.958 0 01-.58.633c-.281.277-.465.416-.55.416a.286.286 0 01-.153-.053l-.808-.633a3.34 3.34 0 01-.534.223c-.062.531-.119.895-.17 1.09-.027.11-.097.164-.21.164H12.85a.225.225 0 01-.147-.053.168.168 0 01-.064-.123l-.164-1.078a3.622 3.622 0 01-.528-.217l-.826.627a.21.21 0 01-.146.053.202.202 0 01-.147-.064c-.492-.446-.814-.774-.967-.985a.23.23 0 01.006-.27c.059-.081.158-.21.299-.386.14-.18.246-.319.316-.416a2.904 2.904 0 01-.24-.58L9.17 13.85a.188.188 0 01-.123-.07.234.234 0 01-.047-.141v-1.301c0-.047.016-.092.047-.135a.176.176 0 01.111-.076l1.09-.164c.055-.18.13-.36.229-.54a13.388 13.388 0 00-.627-.808.22.22 0 01-.059-.14c0-.04.018-.084.053-.135.101-.14.293-.35.574-.627.285-.281.47-.422.557-.422.05 0 .101.02.152.059l.808.627c.172-.09.35-.165.534-.223.062-.531.119-.895.17-1.09.027-.11.097-.164.21-.164h1.301c.055 0 .102.018.141.053a.162.162 0 01.07.123l.164 1.078c.192.062.368.135.528.217l.832-.627a.19.19 0 01.14-.053c.051 0 .1.02.147.059.504.464.826.796.967.996a.19.19 0 01.04.129.226.226 0 01-.046.134c-.059.082-.158.213-.299.393-.14.176-.246.313-.316.41.101.195.181.387.24.574l1.072.164a.18.18 0 01.123.077.21.21 0 01.047.134z"
        fill="#444"
      />
      <circle cx={7.455} cy={3.712} r={3.712} fill="#444" />
    </svg>
  )
}
const JunglerUsersColorIcon = ({className}) => {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" className={className}>
      <path
        d="M7.19 7.955c-4.455 0-6.66 3.977-7.19 6.363C1.326 15.644 3.583 17.5 7.19 17.5c3.606 0 6.658-2.121 7.454-3.977-.796-2.387-3-5.568-7.455-5.568z"
        fill="#000"
      />
      <path
        d="M14.56 14.06c.293-.292.44-.646.44-1.06 0-.414-.146-.768-.44-1.06a1.445 1.445 0 00-1.06-.44c-.414 0-.768.146-1.06.44-.293.292-.44.646-.44 1.06 0 .414.146.768.44 1.06.292.293.646.44 1.06.44.414 0 .768-.146 1.06-.44zM18 12.361v1.301a.226.226 0 01-.047.135.178.178 0 01-.117.076l-1.084.164c-.074.211-.15.389-.229.533.137.196.346.465.627.809.04.047.059.096.059.146 0 .051-.018.096-.053.135a5.958 5.958 0 01-.58.633c-.281.277-.465.416-.55.416a.286.286 0 01-.153-.053l-.808-.633a3.34 3.34 0 01-.534.223c-.062.531-.119.895-.17 1.09-.027.11-.097.164-.21.164H12.85a.225.225 0 01-.147-.053.168.168 0 01-.064-.123l-.164-1.078a3.622 3.622 0 01-.528-.217l-.826.627a.21.21 0 01-.146.053.202.202 0 01-.147-.064c-.492-.446-.814-.774-.967-.985a.23.23 0 01.006-.27c.059-.081.158-.21.299-.386.14-.18.246-.319.316-.416a2.904 2.904 0 01-.24-.58L9.17 13.85a.188.188 0 01-.123-.07.234.234 0 01-.047-.141v-1.301c0-.047.016-.092.047-.135a.176.176 0 01.111-.076l1.09-.164c.055-.18.13-.36.229-.54a13.388 13.388 0 00-.627-.808.22.22 0 01-.059-.14c0-.04.018-.084.053-.135.101-.14.293-.35.574-.627.285-.281.47-.422.557-.422.05 0 .101.02.152.059l.808.627c.172-.09.35-.165.534-.223.062-.531.119-.895.17-1.09.027-.11.097-.164.21-.164h1.301c.055 0 .102.018.141.053a.162.162 0 01.07.123l.164 1.078c.192.062.368.135.528.217l.832-.627a.19.19 0 01.14-.053c.051 0 .1.02.147.059.504.464.826.796.967.996a.19.19 0 01.04.129.226.226 0 01-.046.134c-.059.082-.158.213-.299.393-.14.176-.246.313-.316.41.101.195.181.387.24.574l1.072.164a.18.18 0 01.123.077.21.21 0 01.047.134z"
        fill="#0065A1"
      />
      <circle cx={7.455} cy={3.712} r={3.712} fill="#0065A1" />
    </svg>
  )
}

const ColoredUserIcon = ({className}) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
        fill="#0065A1"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={28}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.35 28c5.992 0 10.85-1.624 10.85-1.624-.11-6.062-4.858-10.976-10.85-10.976S3.5 20.314 3.5 26.376c0 0 4.858 1.624 10.85 1.624z"
          fill="#fff"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.402 9.1a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0zm5.6-3.526a3.526 3.526 0 100 7.052 3.526 3.526 0 000-7.052z"
        fill="#fff"
      />
    </svg>
  )
}
const ColoredTasklistIcon = () => {
  return (
    <svg width={31} height={39} viewBox="0 0 31 39" fill="none">
      <path
        d="M0 9.204a4.09 4.09 0 014.09-4.09h22.5a4.09 4.09 0 014.09 4.09V34.77a4.09 4.09 0 01-4.09 4.09H4.09A4.09 4.09 0 010 34.771V9.204z"
        fill="#0065A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.712 15.516a2.197 2.197 0 100-4.394 2.197 2.197 0 000 4.394zm0 8.789a2.197 2.197 0 100-4.395 2.197 2.197 0 000 4.395zm2.197 6.591a2.197 2.197 0 11-4.394 0 2.197 2.197 0 014.394 0z"
        fill="#fff"
      />
      <path
        opacity={0.76}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.668 12.77c0-.91.656-1.648 1.465-1.648H25.78c.81 0 1.465.738 1.465 1.648v1.098c0 .91-.656 1.648-1.465 1.648H11.133c-.81 0-1.465-.738-1.465-1.648V12.77zm0 8.788c0-.91.655-1.648 1.465-1.648H25.78c.809 0 1.465.738 1.465 1.648v1.099c0 .91-.656 1.648-1.465 1.648H11.132c-.809 0-1.464-.738-1.464-1.648v-1.099zm1.465 7.14c-.81 0-1.465.739-1.465 1.649v1.098c0 .91.655 1.648 1.465 1.648H25.78c.809 0 1.465-.738 1.465-1.648v-1.098c0-.91-.656-1.648-1.465-1.648H11.132z"
        fill="#444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.155 7.159a.263.263 0 00.244-.361 4.09 4.09 0 00-3.22-2.53l-1.238-.177V2.557A2.557 2.557 0 0017.385 0h-3.068a2.557 2.557 0 00-2.557 2.557V4.09l-1.238.177a4.09 4.09 0 00-3.22 2.53c-.069.173.058.36.245.36h16.608zM15.85 5.113a1.534 1.534 0 100-3.068 1.534 1.534 0 000 3.068z"
        fill="#444"
      />
    </svg>
  )
}

const ArrowIcon = () => {
  return (
    <svg width={20} height={14} viewBox="0 0 20 14" fill="none">
      <path
        d="M19.733 6.557L14.465.891c-.357-.324-1.042-.459-1.44-.084-.394.37-.381 1.088.01 1.459l3.704 3.98H.988a.994.994 0 00-.988 1c0 .551.442 1 .988 1h15.75l-3.703 3.979c-.337.342-.4 1.084-.01 1.458.388.373 1.098.254 1.44-.083l5.268-5.667A.972.972 0 0020 7.245a1.13 1.13 0 00-.267-.688z"
        fill="#000"
      />
    </svg>
  )
}
const ViewIcon = () => {
  return (
    <svg width={38} height={38} viewBox="0 0 38 38" fill="none">
      <circle cx={19} cy={19} r={19} fill="#349DF1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.32 19.555a2.32 2.32 0 11-4.64 0 2.32 2.32 0 014.64 0zM19 23.947a4.392 4.392 0 110-8.784 4.392 4.392 0 010 8.784zM19 13c-6.075 0-11 6.523-11 6.523s4.925 6.587 11 6.587 11-6.587 11-6.587S25.075 13 19 13z"
        fill="#fff"
      />
    </svg>
  )
}
const CloseIcon = ({className, fill}) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M12.253 14.436l-8.75 8.75c-.662.661-1.743.651-2.415-.021-.673-.673-.683-1.754-.022-2.415L9.816 12l-8.75-8.75c-.66-.661-.65-1.742.022-2.415S2.84.153 3.502.813l8.75 8.751 8.751-8.75c.66-.661 1.742-.652 2.415.021.672.673.682 1.754.021 2.414L14.69 12l8.75 8.75c.66.661.651 1.742-.021 2.415-.673.672-1.754.682-2.415.021l-8.75-8.75z"
        fill={fill || "#000"}
      />
    </svg>
  )
}
const NotificationIcon = () => {
  return (
    <svg width={27} height={29} viewBox="0 0 27 29" fill="none">
      <path
        d="M24.748 18.281h-.565A2.81 2.81 0 0127 21.093v.563c0 .626-.505 1.124-1.127 1.124H1.127A1.127 1.127 0 010 21.657v-.563a2.817 2.817 0 012.817-2.812H2.25a1.127 1.127 0 001.124-1.125v-6.75C3.375 4.813 7.909.282 13.5.282c5.591 0 10.125 4.531 10.125 10.125v6.75c0 .625.505 1.127 1.123 1.127v-.003zM9.563 23.907h7.874a3.938 3.938 0 01-7.874 0z"
        fill="#000"
      />
    </svg>
  )
}
const BellIcon = () => {
  return (
    <svg width={27} height={28} viewBox="0 0 27 28" fill="none">
      <path
        d="M24.748 18.281h-.565A2.81 2.81 0 0127 21.093v.563c0 .626-.505 1.124-1.127 1.124H1.127A1.127 1.127 0 010 21.657v-.563a2.817 2.817 0 012.817-2.812H2.25a1.127 1.127 0 001.124-1.125v-6.75C3.375 4.813 7.909.282 13.5.282c5.591 0 10.125 4.531 10.125 10.125v6.75c0 .625.505 1.127 1.123 1.127v-.003zM9.563 23.907h7.874a3.938 3.938 0 01-7.874 0z"
        fill="#000"
      />
    </svg>
  )
}
const QuestionIcon = () => {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.365 0C4.648 0 0 4.648 0 10.365 0 16.082 4.648 20.73 10.365 20.73c5.717 0 10.365-4.648 10.365-10.365C20.73 4.648 16.082 0 10.365 0zm0 16.65a1.118 1.118 0 01-1.113-1.114c0-.61.502-1.112 1.113-1.112s1.113.502 1.113 1.112c0 .611-.48 1.113-1.113 1.113zm.895-5.39c0-.153.087-.284.196-.327 1.68-.546 2.662-2.204 2.357-3.972-.24-1.396-1.397-2.575-2.815-2.815a3.42 3.42 0 00-2.88.764 3.487 3.487 0 00-1.244 2.684c0 .48.392.873.872.873s.873-.393.873-.873c0-.502.219-1.004.655-1.353a1.78 1.78 0 011.44-.37c.698.13 1.266.697 1.397 1.396.174 1.003-.459 1.745-1.179 1.985-.85.284-1.418 1.091-1.418 2.008v1.113c0 .48.393.873.873.873s.873-.393.873-.873V11.26z"
        fill="#9B9B9B"
      />
    </svg>
  )
}
const LockIcon = () => {
  return (
    <svg width={17} height={25} viewBox="0 0 17 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.796 9.862h-.7V5.607A5.612 5.612 0 008.49 0a5.613 5.613 0 00-5.606 5.607v4.255h-.7A2.183 2.183 0 000 12.046v10.127c0 1.205.977 2.183 2.183 2.183h12.614a2.184 2.184 0 002.183-2.183V12.046a2.183 2.183 0 00-2.184-2.184zm-9.95-4.256A3.65 3.65 0 018.49 1.961a3.649 3.649 0 013.644 3.645v4.256H4.845V5.606zm4.761 14.118v-2.726a1.983 1.983 0 10-2.234 0v2.726a1.117 1.117 0 102.234 0z"
        fill="#9B9B9B"
      />
    </svg>
  )
}
const DownCaretIcon = ({className}) => {
  return (
    <svg width={16} height={9} viewBox="0 0 16 9" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.8c-.39 0-.781-.138-1.079-.413L.447 2.407a1.335 1.335 0 010-1.994 1.616 1.616 0 012.158 0L8 5.397 13.395.413a1.616 1.616 0 012.158 0c.596.55.596 1.443 0 1.993L9.079 8.387A1.586 1.586 0 018 8.8"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const SortUpIcon = () => {
  return (
    <svg width={10} height={14} viewBox="0 0 10 14" fill="none">
      <path
        d="M4.792.187L.826 3.874c-.227.25-.321.73-.059 1.009.26.275.762.266 1.021-.007l2.786-2.593v11.026c0 .381.313.691.7.691.386 0 .7-.31.7-.691V2.283l2.785 2.593c.24.235.76.28 1.021.007.262-.272.178-.769-.058-1.009L5.755.187A.68.68 0 005.274 0a.791.791 0 00-.482.187z"
        fill="#000"
      />
    </svg>
  )
}
const SortDownIcon = () => {
  return (
    <svg width={10} height={14} viewBox="0 0 10 14" fill="none">
      <path
        d="M5.758 13.813l3.966-3.688c.227-.25.321-.729.059-1.008-.26-.275-.761-.266-1.021.007l-2.786 2.593V.691a.696.696 0 00-.7-.691c-.386 0-.7.31-.7.691v11.026L1.791 9.124c-.24-.235-.76-.28-1.021-.007-.262.272-.178.769.058 1.008l3.967 3.688a.68.68 0 00.481.187.791.791 0 00.482-.187z"
        fill="#000"
      />
    </svg>
  )
}
const FeaturedIcon = ({ color = '#E5E5E5' }) => {
  return (
    <svg width={38} height={38} viewBox="0 0 38 38" fill="none">
      <circle cx={19} cy={19} r={19} fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.451 8.3l2.408 5.769c.1.2.25.3.401.3l6.27.553c.2 0 .35.15.451.3.05.201 0 .402-.15.502l-4.765 4.113c-.15.15-.2.3-.15.502l1.404 6.119c.05.2 0 .35-.15.501-.151.1-.352.1-.552 0L19.25 23.7a.455.455 0 00-.502 0l-5.367 3.26c-.15.1-.35.1-.551 0-.15-.15-.2-.3-.15-.501l1.404-6.12c.05-.2 0-.35-.15-.501l-4.766-4.113c-.15-.1-.2-.3-.15-.501.1-.15.25-.301.451-.301l6.27-.552c.15 0 .3-.1.401-.301l2.408-5.768c.1-.2.25-.301.451-.301.2 0 .351.1.451.3z"
        fill="#fff"
      />
    </svg>
  )
}
const DuplicateIcon = () => {
  return (
    <svg width={38} height={38} viewBox="0 0 38 38" fill="none">
      <circle cx={19} cy={19} r={19} fill="#B4B4B4" />
      <path
        d="M26.358 11h-11.71a.646.646 0 00-.643.643v11.71c0 .352.29.642.643.642h11.71c.352 0 .642-.29.642-.643v-11.71a.62.62 0 00-.642-.642zm-.643 11.71H15.29V12.285h10.425V22.71zM23.352 27h-11.71a.646.646 0 01-.642-.642v-11.71c0-.353.29-.643.643-.643.352 0 .642.29.642.643v11.067h11.067c.353 0 .643.29.643.643 0 .352-.29.642-.643.642zm-6.03-9.513c0-.352.29-.642.642-.642h1.886v-1.886c0-.353.29-.643.642-.643.353 0 .643.29.643.643v1.886h1.886c.352 0 .642.29.642.642 0 .352-.29.643-.642.643h-1.886v1.886c0 .352-.29.642-.643.642a.646.646 0 01-.642-.643V18.13h-1.886a.646.646 0 01-.643-.642z"
        fill="#fff"
      />
    </svg>
  )
}
const MoreIcon = () => {
  return (
    <svg width={6} height={26} viewBox="0 0 6 26" fill="none">
      <circle cx={3} cy={3.71} r={3} fill="#C4C4C4" />
      <circle cx={3} cy={22.71} r={3} fill="#C4C4C4" />
      <circle cx={3} cy={13.71} r={3} fill="#C4C4C4" />
    </svg>
  )
}
const UserIcon = () => {
  return (
    <svg width={38} height={38} viewBox="0 0 38 38" fill="none">
      <circle cx={19} cy={19} r={19} fill="#52E34E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.656 27.884h-14.28c-.226 0-.376-.151-.376-.38v-1.898c0-.076.038-.114.038-.19a7.536 7.536 0 015.486-6.759 4.535 4.535 0 01-2.555-4.1c0-2.507 2.03-4.557 4.51-4.557s4.509 2.05 4.509 4.556c0 2.507-2.03 4.557-4.51 4.557-3.72 0-6.764 3.076-6.764 6.835v1.139h13.529v-1.14c0-2.277-1.09-4.252-2.781-5.505a.346.346 0 01-.113-.266c0-.19.15-.38.376-.38a.34.34 0 01.263.114 7.818 7.818 0 012.969 5.506c.037.038.037.114.037.19v1.899c.038.19-.113.38-.338.38zm-3.382-13.29c0-2.088-1.691-3.797-3.758-3.797s-3.758 1.71-3.758 3.797c0 2.089 1.691 3.798 3.758 3.798s3.758-1.71 3.758-3.797z"
        fill="#fff"
      />
    </svg>
  )
}
const BackArrowIcon = ({className}) => {
  return (
    <svg width={23} height={15} viewBox="0 0 23 15" fill="none" className={className}>
      <path
        d="M.308 8.459l6.057 6.517c.411.372 1.198.527 1.657.095.452-.425.437-1.25-.012-1.677L3.75 8.818h18.114c.627 0 1.136-.515 1.136-1.15 0-.635-.509-1.15-1.136-1.15H3.751L8.01 1.942c.387-.394.46-1.247.012-1.677-.447-.43-1.263-.292-1.657.096L.308 6.877A1.118 1.118 0 000 7.668c.01.274.123.592.308.791z"
        fill="#000"
      />
    </svg>
  )
}
const DraftIcon = () => {
  return (
    <svg width={20} height={14} viewBox="0 0 20 14" fill="none">
      <path
        d="M1.463 0c-.194 0-.38.039-.548.107l8.506 7.47c.374.328.768.32 1.143-.008L19.078.107A1.462 1.462 0 0018.537 0H1.463zM.015 1.258C.006 1.324 0 1.394 0 1.463v10.244c0 .811.653 1.464 1.463 1.464h17.074A1.46 1.46 0 0020 11.707V1.463c0-.069-.006-.139-.015-.205l-8.453 7.416a2.346 2.346 0 01-3.08 0L.016 1.258z"
        fill="#fff"
      />
    </svg>
  )
}
const CalendarIcon = ({className}) => {
  return (
    <svg width={22} height={21} viewBox="0 0 22 21" fill="none" className={className}>
      <path
        d="M16.372 7.163H.512a.512.512 0 110-1.023h15.86a.512.512 0 010 1.023z"
        fill="#fff"
      />
      <path
        d="M17.169 20.465h-.051a.512.512 0 010-1.023h.051a.512.512 0 010 1.023zm-2.61 0H2.815A2.817 2.817 0 010 17.651V7.434a.512.512 0 111.023 0V17.65c0 .988.804 1.79 1.791 1.79H14.56a.512.512 0 010 1.024zm5.163-.063a.512.512 0 01-.119-1.009 1.784 1.784 0 001.374-1.742V4.861c0-.988-.804-1.791-1.791-1.791h-.767v1.535c0 .282-.23.511-.512.511h-2.558a.512.512 0 01-.512-.511V3.07H7.163v1.535c0 .282-.23.511-.512.511H4.093a.512.512 0 01-.512-.511V3.07h-.767c-.27 0-.529.058-.77.173a.512.512 0 01-.44-.924c.38-.18.787-.272 1.21-.272h1.279c.283 0 .512.229.512.511v1.535H6.14V2.558c0-.282.229-.511.511-.511h8.698c.282 0 .511.229.511.511v1.535h1.535V2.558c0-.282.23-.511.512-.511h1.279A2.817 2.817 0 0122 4.86v12.79a2.804 2.804 0 01-2.16 2.738.514.514 0 01-.118.014zM.512 5.387A.512.512 0 010 4.875v-.014-.044a.513.513 0 011.023.015v.043c0 .283-.229.512-.511.512z"
        fill="#fff"
      />
      <path
        d="M6.651 5.116H4.093a.512.512 0 01-.512-.511V1.79C3.581.803 4.385 0 5.372 0c.988 0 1.79.803 1.79 1.79v2.815c0 .282-.228.511-.51.511zM4.605 4.093H6.14V1.791a.768.768 0 00-1.535 0v2.302zM17.907 5.116h-2.558a.512.512 0 01-.512-.511V1.79c0-.988.804-1.791 1.79-1.791.988 0 1.792.803 1.792 1.79v2.815c0 .282-.23.511-.512.511zm-2.046-1.023h1.534V1.791a.768.768 0 00-1.534 0v2.302z"
        fill="#fff"
      />
    </svg>
  )
}
const CalendarOtherIcon = ({className}) => {
  return (
    <svg width={16} height={15} viewBox="0 0 16 15" fill="none" className={className}>
      <path
        d="M4.006 0c-.201 0-.364.163-.364.364v3.09a.364.364 0 00.727 0V.365C4.37.163 4.207 0 4.006 0zm8 0c-.201 0-.364.163-.364.364v3.09a.364.364 0 00.727 0V.365c0-.201-.162-.364-.363-.364zM1.46 1.09C.652 1.09 0 1.744 0 2.552v3.267h16V2.551c0-.808-.652-1.46-1.46-1.46h-1.807v1.551a1.09 1.09 0 11-1.455 0V1.091h-6.55v1.545a1.09 1.09 0 11-1.455.011V1.092H1.46zM0 6.546v6.904c0 .808.652 1.46 1.46 1.46h13.08c.808 0 1.46-.652 1.46-1.46V6.545H0z"
        fill="#000"
      />
    </svg>
  )
}
const ChatIcon = ({className}) => {
  return (
    <svg width={50} height={37} viewBox="0 0 50 37" fill="none" className={className}>
      <path
        d="M16.072 22.904c0-7.804 8.127-14.156 18.116-14.156 2.723 0 5.306.473 7.627 1.322C38.469 4.143 30.766 0 21.796 0 9.758 0 0 7.463 0 16.67c0 4.654 2.497 8.86 6.516 11.883l-1.591 7.735 7.172-4.692c2.923 1.115 6.214 1.743 9.699 1.743.054 0 .108-.005.162-.005-3.614-2.587-5.886-6.304-5.886-10.43z"
        fill="#0065A1"
      />
      <path
        d="M50 22.904c0-6.393-6.975-11.597-15.554-11.597-8.578 0-15.558 5.204-15.558 11.597 0 6.395 6.98 11.598 15.558 11.598 1.955 0 3.87-.277 5.696-.816l4.973 3-.736-4.889C47.956 29.576 50 26.36 50 22.904z"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const FilterIcon = ({className}) => {
  return (
    <svg width={22} height={15} viewBox="0 0 22 15" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.222 0h19.556a1.222 1.222 0 010 2.444H1.222a1.222 1.222 0 110-2.444zM4.89 6.111H17.11a1.222 1.222 0 010 2.445H4.89a1.222 1.222 0 010-2.445zm4.889 6.111h2.444a1.222 1.222 0 010 2.445H9.778a1.222 1.222 0 010-2.445z"
        fill="#fff"
      />
    </svg>
  )
}
const CaretLeftIcon = ({className}) => {
  return (
    <svg width={9} height={16} viewBox="0 0 9 16" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8c0-.39.137-.781.413-1.079L6.393.447a1.335 1.335 0 011.994 0c.55.595.55 1.562 0 2.158L3.403 8l4.984 5.395c.55.596.55 1.563 0 2.158a1.335 1.335 0 01-1.993 0L.413 9.079A1.586 1.586 0 010 8z"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const CaretRightIcon = ({className}) => {
  return (
    <svg width={9} height={16} viewBox="0 0 9 16" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8 8c0 .39-.138.781-.413 1.079l-5.98 6.474a1.335 1.335 0 01-1.994 0 1.616 1.616 0 010-2.158L5.397 8 .413 2.605a1.616 1.616 0 010-2.158 1.335 1.335 0 011.993 0l5.981 6.474C8.662 7.22 8.8 7.61 8.8 8z"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const TrackIcon = ({className}) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" className={className}>
      <circle cx={12} cy={12} r={12} fill="#0065A1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.795 8.312l-1.768 3.732a1.579 1.579 0 01-1.427.903H2.526a.947.947 0 010-1.894H5.4l1.984-4.188c.583-1.23 2.347-1.195 2.88.059l4.103 9.654 2.958-4.777a1.579 1.579 0 011.343-.748h2.806a.947.947 0 010 1.894h-2.63l-3.187 5.147c-.673 1.088-2.294.965-2.795-.213L8.795 8.312z"
        fill="#fff"
      />
    </svg>
  )
}
const CameraIcon = ({className}) => {
  return (
    <svg width={16} height={15} viewBox="0 0 16 15" fill="none" className={className}>
      <path
        d="M8.163 10.003a.449.449 0 10-.001-.898.449.449 0 00.001.898zM1.855 7.7c0 .157.128.285.285.285h1.854a.285.285 0 00.285-.285.285.285 0 00-.285-.284H2.14a.285.285 0 00-.285.284z"
        fill="#fff"
      />
      <path
        d="M8.164 12.156a2.605 2.605 0 002.602-2.602 2.605 2.605 0 00-2.602-2.602 2.606 2.606 0 00-2.602 2.602 2.605 2.605 0 002.602 2.602zm0-4.634c1.121 0 2.033.911 2.033 2.032a2.035 2.035 0 01-2.033 2.033 2.035 2.035 0 01-2.033-2.033c0-1.12.912-2.032 2.033-2.032z"
        fill="#fff"
      />
      <path
        d="M15.521 4.058L4.491.044a.76.76 0 00-.958.447l-1.34 3.68h-.518a.75.75 0 00-.748.749v.178H.748A.75.75 0 000 5.846v7.416a.75.75 0 00.748.748h11.41a.75.75 0 00.702-.492l3.095-8.506a.752.752 0 00-.434-.954zm-3.47 9.383H.569V5.668h.927V4.74h2.212v.927h8.343v7.773zm.57-.929V5.846a.75.75 0 00-.749-.748H4.277V4.92a.75.75 0 00-.748-.749H2.8L4.129.517 15.482 4.65l-2.862 7.863z"
        fill="#fff"
      />
    </svg>
  )
}
const AttachmentIcon = ({className}) => {
  return (
    <svg width={26} height={24} viewBox="0 0 26 24" fill="none" className={className}>
      <path
        d="M20.257 1a4.71 4.71 0 00-3.353 1.39L5.975 13.317A3.306 3.306 0 005 15.67 3.332 3.332 0 008.329 19c.889 0 1.724-.346 2.353-.975L20.853 7.854a.5.5 0 10-.707-.708L9.976 17.318a2.331 2.331 0 01-3.293-3.293L17.61 3.096A3.718 3.718 0 0120.257 2 3.747 3.747 0 0124 5.743c0 1-.39 1.94-1.096 2.646l-12.1 12.1A5.123 5.123 0 017.156 22 5.163 5.163 0 012 16.843c0-1.377.536-2.672 1.51-3.646L14.854 1.854a.5.5 0 10-.707-.707L2.803 12.49A6.115 6.115 0 001 16.843 6.163 6.163 0 007.157 23c1.644 0 3.19-.64 4.353-1.803l12.1-12.1A4.71 4.71 0 0025 5.742 4.748 4.748 0 0020.257 1z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.5}
      />
    </svg>
  )
}
const HeartIcon = ({className}) => {
  return (
    <svg width={20} height={19} viewBox="0 0 20 19" fill="none" className={className}>
      <path
        d="M1.368 9.632c.12.134.255.285.396.426l.002.004 7.978 8.514.009.006.006.008.045.028c.018.012.035.026.055.034.02.008.04.01.06.014.02.004.04.01.06.01.021 0 .04-.006.06-.01.021-.003.041-.005.06-.013.02-.008.037-.022.055-.034l.046-.028.006-.009.009-.006 7.995-8.491c.15-.15.294-.294.43-.462l.001-.002.002-.002A5.846 5.846 0 0020 5.876 5.88 5.88 0 0014.124 0a5.806 5.806 0 00-4.118 1.7A5.855 5.855 0 005.876 0 5.88 5.88 0 000 5.876c0 1.425.514 2.725 1.36 3.747l.008.009zm.813-7.456A5.217 5.217 0 015.876.646a5.21 5.21 0 013.767 1.606L7.956 6.987a.323.323 0 00.392.418l2.932-.83L9.708 11.5a.322.322 0 10.614.196L12.077 6.2a.324.324 0 00-.395-.409l-2.908.823L10.3 2.326a5.154 5.154 0 013.823-1.68c1.44 0 2.746.583 3.695 1.53a5.21 5.21 0 01.323 7.036l-.003.004c-.106.133-.233.26-.385.412l-.002.004-.005.003-7.767 8.25L2.237 9.62l-.003-.002-.004-.006a9.614 9.614 0 01-.374-.404 5.21 5.21 0 01.326-7.033z"
        fill="#979797"
      />
    </svg>
  )
}
const TourIcon = ({className}) => {
  return (
    <svg width={22} height={27} viewBox="0 0 22 27" fill="none" className={className}>
      <path
        d="M7.877 23.63L11 26.096l3.123-2.464C18.843 22.97 22 20.89 22 18.426c0-2.256-2.672-4.164-6.593-4.997-.139.278-.382.52-.625.694 3.713.694 6.42 2.394 6.42 4.268 0 2.013-2.984 3.852-7.287 4.442h-.104L11 25.088l-2.81-2.22h-.105c-4.303-.59-7.287-2.43-7.287-4.442 0-1.909 2.707-3.574 6.42-4.268-.243-.174-.486-.417-.625-.694C2.672 14.296 0 16.17 0 18.46c0 2.429 3.158 4.51 7.877 5.17zM11 4.72A2.36 2.36 0 1011 0a2.36 2.36 0 000 4.72z"
        fill="#979797"
      />
      <path
        d="M7.946 13.672c.14.07.243.208.243.382v4.268c0 .763.625 1.388 1.388 1.388h2.811c.763 0 1.388-.625 1.388-1.388v-4.268c0-.174.104-.313.243-.382.52-.208.867-.729.867-1.284V7.391c0-.763-.624-1.388-1.387-1.388H8.502c-.764 0-1.388.625-1.388 1.388v4.997c-.035.555.312 1.076.832 1.284z"
        fill="#979797"
      />
    </svg>
  )
}
const   NotReadyTourIcon = ({className}) => {
  return (
    <svg width={22} height={25} viewBox="0 0 22 25" fill="none" className={className}>
      <path
        d="M10.428 20.51a25.71 25.71 0 01-2.764-.156c-.125 0-.282-.032-.408-.032v1.382a2.386 2.386 0 002.387 2.388h1.728a2.386 2.386 0 002.387-2.388v-1.413c-.126.031-.283.031-.409.063-.88.094-1.884.157-2.92.157zM7.57 2.953a2.95 2.95 0 002.952 2.952 2.95 2.95 0 002.953-2.952A2.95 2.95 0 0010.522 0C8.92.031 7.57 1.35 7.57 2.953zM21.987 17.087c-.188-1.005-.534-2.01-1.036-3.047-.095-.188-.22-.408-.346-.628a.173.173 0 00-.157-.094c-.063 0-.126 0-.157.063a31.05 31.05 0 01-2.796 2.23c-.44.314-.91.628-1.413.973a.285.285 0 00-.094.22c.031.094.094.157.188.157.22.032.471.032.691.063.251.031.471.031.723.063a1.578 1.578 0 01-.409.157c-.377.126-.754.283-1.162.377a15.13 15.13 0 01-2.199.44v-1.54a2.376 2.376 0 001.068-2.01V8.983a2.386 2.386 0 00-2.387-2.387H8.606A2.386 2.386 0 006.22 8.983v2.136C2.481 11.779 0 13.286 0 15.171c0 .283.063.565.188.848.911 2.073 5.026 3.518 10.272 3.518 4.146 0 7.6-.911 9.297-2.325l2.041.095h.032a.238.238 0 00.126-.063c.031-.032.062-.094.031-.157zM7.256 18.092c-1.32-.188-2.545-.471-3.55-.848-1.225-.471-2.041-1.037-2.355-1.602-.032-.063-.063-.157-.095-.22-.031-.094-.031-.157-.031-.251 0-.723.942-1.508 2.481-2.105.723-.282 1.57-.502 2.482-.69v2.167c0 .816.44 1.57 1.068 2.01v1.539z"
        fill="#979797"
      />
    </svg>
  )
}
const NotReadys = ({className}) => {
  return (
    <svg width={22} height={25} viewBox="0 0 22 25" fill="none" className={className}>
      <path
        d="M10.428 20.51a25.71 25.71 0 01-2.764-.156c-.125 0-.282-.032-.408-.032v1.382a2.386 2.386 0 002.387 2.388h1.728a2.386 2.386 0 002.387-2.388v-1.413c-.126.031-.283.031-.409.063-.88.094-1.884.157-2.92.157zM7.57 2.953a2.95 2.95 0 002.952 2.952 2.95 2.95 0 002.953-2.952A2.95 2.95 0 0010.522 0C8.92.031 7.57 1.35 7.57 2.953zM21.987 17.087c-.188-1.005-.534-2.01-1.036-3.047-.095-.188-.22-.408-.346-.628a.173.173 0 00-.157-.094c-.063 0-.126 0-.157.063a31.05 31.05 0 01-2.796 2.23c-.44.314-.91.628-1.413.973a.285.285 0 00-.094.22c.031.094.094.157.188.157.22.032.471.032.691.063.251.031.471.031.723.063a1.578 1.578 0 01-.409.157c-.377.126-.754.283-1.162.377a15.13 15.13 0 01-2.199.44v-1.54a2.376 2.376 0 001.068-2.01V8.983a2.386 2.386 0 00-2.387-2.387H8.606A2.386 2.386 0 006.22 8.983v2.136C2.481 11.779 0 13.286 0 15.171c0 .283.063.565.188.848.911 2.073 5.026 3.518 10.272 3.518 4.146 0 7.6-.911 9.297-2.325l2.041.095h.032a.238.238 0 00.126-.063c.031-.032.062-.094.031-.157zM7.256 18.092c-1.32-.188-2.545-.471-3.55-.848-1.225-.471-2.041-1.037-2.355-1.602-.032-.063-.063-.157-.095-.22-.031-.094-.031-.157-.031-.251 0-.723.942-1.508 2.481-2.105.723-.282 1.57-.502 2.482-.69v2.167c0 .816.44 1.57 1.068 2.01v1.539z"
        fill="#cf0808"
      />
    </svg>
  )
}

const ConstructionIcon = ({className}) => {
  return (
    // Obtained from the following free icon library: https://freeicons.io/creatype-tool-and-construction-glyph/spanner-wrench-equipment-tools-construction-icon-45635
    // Creator: ColourCreatype
    <svg width={22} height={25}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <g id="Layer_46" data-name="Layer 46">
        <path d="M58.17,44.55a9.56,9.56,0,0,0-9.75-2.34L21.79,15.58c2.35-6.79-4-13.91-11-12.4A1,1,0,0,0,10,4.26a1.14,1.14,0,0,0,.28.61l4,4L13.1,13.1,8.83,14.24l-4-4A1.1,1.1,0,0,0,4.06,10a1,1,0,0,0-.88.79c-1.55,7,5.7,13.41,12.42,11L42.21,48.42c-2.36,6.79,4,13.91,11,12.4A1,1,0,0,0,54,59.74a1.14,1.14,0,0,0-.28-.61l-4-4L50.9,50.9l4.27-1.14,4,4a1.29,1.29,0,0,0,.71.3h0a1,1,0,0,0,1-.81A9.59,9.59,0,0,0,58.17,44.55Z"/>
        <path d="M34.48,24.65l4.88,4.88a1,1,0,0,0,1.42,0l7.34-7.36C55,24.61,62.32,18.09,60.75,11a1,1,0,0,0-.88-.79,1.1,1.1,0,0,0-.81.28l-4,4-4.36-1.17L49.49,9l4-4a1.06,1.06,0,0,0,.27-.81A1,1,0,0,0,53,3.25C45.86,1.71,39.45,9,41.84,15.86l-7.36,7.37A1,1,0,0,0,34.48,24.65Z"/>
        <path d="M24.65,34.48a1,1,0,0,0-1.42,0l-7.89,7.89C6,39.56-1.06,51.09,5.87,58.13S24.48,58,21.64,48.66l7.89-7.88a1,1,0,0,0,0-1.42Zm-7,16.11-1.1,4.11a1,1,0,0,1-.71.71l-4.11,1.1a1,1,0,0,1-1-.26l-3-3a1,1,0,0,1-.26-1l1.1-4.11a1,1,0,0,1,.71-.71l4.11-1.1a1,1,0,0,1,1,.26l3,3A1,1,0,0,1,17.65,50.59Z"/>
      </g>
    </svg>
  )
}

const BlockNotReadyTourIcon = ({className}) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none">
      <circle cx={14} cy={14} r={14} fill="#FBBC05" />
      <path
        d="M13.636 19.052c-.62 0-1.199-.04-1.758-.1-.08 0-.18-.02-.26-.02v.88c0 .84.68 1.519 1.519 1.519h1.1c.839 0 1.518-.68 1.518-1.52v-.899c-.08.02-.18.02-.26.04-.56.06-1.199.1-1.858.1zM11.816 7.879c0 1.04.84 1.879 1.88 1.879 1.039 0 1.878-.84 1.878-1.88 0-1.038-.84-1.878-1.879-1.878-1.02.02-1.879.86-1.879 1.879zM20.992 16.873c-.12-.64-.34-1.279-.66-1.938-.06-.12-.14-.26-.22-.4a.11.11 0 00-.1-.06c-.04 0-.08 0-.1.04-.52.46-1.119.96-1.779 1.42-.28.199-.58.399-.9.619-.039.04-.059.08-.059.14.02.06.06.1.12.1.14.02.3.02.44.04.16.02.3.02.46.04-.08.04-.16.08-.26.1-.24.08-.48.18-.74.24-.44.12-.9.22-1.4.28v-.98c.42-.28.68-.74.68-1.28v-3.517c0-.84-.68-1.52-1.519-1.52h-2.478c-.84 0-1.52.68-1.52 1.52v1.359C8.58 13.496 7 14.455 7 15.654c0 .18.04.36.12.54.58 1.32 3.198 2.239 6.536 2.239 2.638 0 4.837-.58 5.917-1.48l1.299.06h.02c.02 0 .06-.02.08-.04.02-.02.04-.06.02-.1zm-9.375.64c-.84-.12-1.619-.3-2.258-.54-.78-.3-1.3-.66-1.5-1.019-.02-.04-.04-.1-.06-.14-.02-.06-.02-.1-.02-.16 0-.46.6-.96 1.58-1.339.46-.18.999-.32 1.579-.44v1.38c0 .52.28.999.68 1.279v.98z"
        fill="#fff"
      />
      <path stroke="#fff" d="M25.378 3.327l-19 22" />
    </svg>
  )
}
const GoogleIcon = ({className}) => {
  return (
    <svg width={22} height={24} viewBox="0 0 22 24" fill="none" className={className}>
      <path
        d="M7.575 1.452C4.857 2.382 2.54 4.416 1.25 6.99a11.05 11.05 0 00-.972 2.808 11.2 11.2 0 00.966 7.218 11.261 11.261 0 003.03 3.726 11.12 11.12 0 004.056 2.046c1.86.498 3.84.486 5.712.06 1.692-.39 3.294-1.2 4.572-2.376 1.35-1.242 2.316-2.874 2.826-4.632.558-1.914.63-3.96.282-5.928h-10.5v4.356h6.084a5.256 5.256 0 01-2.232 3.45 6.393 6.393 0 01-2.46.972c-.876.15-1.788.168-2.664-.006a6.607 6.607 0 01-2.484-1.074 6.9 6.9 0 01-2.556-3.426 6.772 6.772 0 010-4.344 6.97 6.97 0 011.62-2.634 6.62 6.62 0 013.336-1.854 6.659 6.659 0 013.276.132c.9.27 1.728.768 2.406 1.416L17.6 4.848c.36-.366.738-.72 1.086-1.098a10.883 10.883 0 00-3.594-2.226c-2.4-.888-5.1-.906-7.518-.072z"
        fill="#fff"
      />
      <path
        d="M7.574 1.452a11.301 11.301 0 017.518.066c1.332.492 2.55 1.26 3.594 2.226-.348.378-.726.732-1.086 1.098l-2.052 2.052a6.078 6.078 0 00-2.406-1.416 6.589 6.589 0 00-3.276-.132c-1.26.27-2.43.93-3.336 1.854a6.925 6.925 0 00-1.62 2.634L1.25 6.996c1.29-2.58 3.606-4.614 6.324-5.544z"
        fill="#EA4335"
      />
      <path
        d="M.284 9.792c.198-.972.522-1.92.972-2.808l3.66 2.838a6.732 6.732 0 000 4.344l-3.654 2.838a11.105 11.105 0 01-.978-7.212z"
        fill="#FBBC05"
      />
      <path
        d="M11.223 9.906h10.5c.348 1.962.27 4.008-.282 5.928-.51 1.758-1.476 3.39-2.826 4.632l-3.546-2.754a5.256 5.256 0 002.232-3.45h-6.084l.006-4.356z"
        fill="#4285F4"
      />
      <path
        d="M1.25 17.01l3.654-2.838a6.936 6.936 0 002.556 3.426 6.649 6.649 0 002.484 1.074c.876.18 1.782.156 2.664.006a6.393 6.393 0 002.46-.972l3.546 2.754c-1.278 1.182-2.88 1.986-4.572 2.376-1.872.426-3.852.438-5.712-.06a11.028 11.028 0 01-4.056-2.046 11.236 11.236 0 01-3.024-3.72z"
        fill="#34A853"
      />
    </svg>
  )
}
const NavbarIcon = ({className}) => {
  return (
    <svg width={26} height={18} viewBox="0 0 26 18" fill="none" className={className}>
      <path
        d="M24.969 8.053H1.255C.762 8.053.36 8.479.36 9c0 .521.403.947.896.947H24.97c.493 0 .896-.426.896-.947 0-.521-.403-.947-.896-.947zM24.969 15.774H1.255c-.493 0-.896.426-.896.947 0 .521.403.948.896.948H24.97c.493 0 .896-.427.896-.948 0-.521-.403-.947-.896-.947zM24.969.332H1.255C.762.332.36.758.36 1.279c0 .521.403.947.896.947H24.97c.493 0 .896-.426.896-.947 0-.521-.403-.947-.896-.947z"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const SearchIcon = ({className}) => {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" fill="none" className={className}>
      <path
        d="M3.176 15.83h0c1.785 1.76 4.11 2.606 6.429 2.606a9.08 9.08 0 005.657-1.961l5.875 5.792c.161.158.38.233.562.233a.826.826 0 00.562-.233.784.784 0 000-1.127l-5.82-5.767c3.125-3.506 3.01-8.897-.409-12.238A9.123 9.123 0 009.605.5c-2.418 0-4.71.942-6.429 2.635C1.46 4.83.5 7.062.5 9.483c0 2.39.958 4.653 2.676 6.348zm11.734-1.156h0c-2.905 2.863-7.676 2.863-10.58 0l-.351.356.35-.356a7.3 7.3 0 01-2.197-5.22c0-1.968.785-3.8 2.201-5.224 1.406-1.414 3.292-2.166 5.302-2.166 2 0 3.861.775 5.305 2.17 2.872 2.89 2.868 7.582-.03 10.44z"
        fill="#B4B4B4"
        stroke="#B4B4B4"
      />
    </svg>
  )
}

const DeleteIcon = ({className, pointerEvents}) => {
  const style = pointerEvents ? {"pointer-events": "none"} : {};
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" className={className} style={style}>
      <path
        d="M7.5 2.5c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v.625h1.25v-1.25C18.75.839 17.91 0 16.875 0h-8.75C7.089 0 6.25.84 6.25 1.875v1.25H7.5V2.5zM24.375 4.375H.625a.625.625 0 100 1.25h2.5v17.5C3.125 24.16 3.965 25 5 25h15c1.035 0 1.875-.84 1.875-1.875v-17.5h2.5a.625.625 0 100-1.25zM8.75 18.75a.625.625 0 11-1.25 0V10a.625.625 0 111.25 0v8.75zM13.125 20a.625.625 0 11-1.25 0V8.75a.625.625 0 111.25 0V20zm4.375-1.25a.625.625 0 11-1.25 0V10a.625.625 0 111.25 0v8.75z"
        fill="#DF0000"
      />
    </svg>
  )
}

const LaunchDateIcon = ({className}) => {
  return (
    <svg width={27} height={28} viewBox="0 0 27 28" fill="none" className={className}>
      <path
        d="M23 4c-2.7-2.6-6.1-4-9.8-4C9.5 0 6 1.4 3.4 4c-.4.4-.4 1.1 0 1.4.4.3 1 .3 1.4 0 2.3-2.3 5.2-3.5 8.3-3.5 3.1 0 6.1 1.3 8.3 3.5 4.6 4.6 4.6 12.1 0 16.7-2.2 2.3-5.2 3.5-8.3 3.5-3.1 0-6.1-1.3-8.3-3.5-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4 2.7 2.6 6.1 4 9.8 4 3.7 0 7.2-1.2 9.8-3.9 2.6-2.7 4-6.1 4-9.8 0-3.7-1.3-7.2-4-9.8z"
        fill="#FBBC05"
      />
      <path
        d="M4.7 13.7c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1s.4 1 1 1h2.7c.6 0 1-.4 1-1zM6.4 13.7c0 .6.4 1 1 1h8.1l-4.6 4.6c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l6.2-6.2c.2-.2.3-.4.3-.7 0-.3-.1-.5-.2-.6l-.3-.3-6-6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4.4 4.4H7.4c-.6 0-1 .4-1 1z"
        fill="#FBBC05"
      />
    </svg>
  )
}

const CallIcon = ({className}) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none"  className={className}>
      <path
        d="M19.61 16.08l-3.58-3.578c-.503-.505-1.383-.505-1.887 0l-.979.974c-.279.284-.679.425-1.187.425-1.179 0-2.675-.762-3.904-1.991-1.804-1.804-2.52-4.133-1.566-5.091l.979-.975a1.343 1.343 0 000-1.887L3.903.378c-.5-.504-1.383-.504-1.887 0l-.617.617C0 2.395-.363 4.635.37 7.302c.717 2.587 2.387 5.287 4.708 7.608 3.129 3.128 7.016 5.074 10.14 5.074 1.554 0 2.858-.483 3.77-1.396l.621-.62c.521-.521.521-1.367 0-1.888zM10.586 4.64a4.69 4.69 0 014.684 4.684.416.416 0 10.833 0 5.523 5.523 0 00-5.517-5.517.417.417 0 100 .833z"
        fill="#979797"
      />
      <path
        d="M10.445 7.583c1.038 0 1.882.844 1.882 1.882a.416.416 0 10.834 0 2.718 2.718 0 00-2.716-2.715.416.416 0 100 .833zM10.814 1.755c4.048 0 7.341 3.293 7.341 7.341a.417.417 0 10.834 0c0-4.507-3.668-8.174-8.175-8.174a.417.417 0 100 .833zM10.002 10.327a.42.42 0 00.417-.417.417.417 0 00-.125-.296.43.43 0 00-.588 0 .419.419 0 00.296.713z"
        fill="#979797"
      />
    </svg>
  )
}
const NotTouchedIcon = ({className}) => {
  return (
    <svg width={20} height={25} viewBox="0 0 20 25" fill="none" className={className}>
      <path
        d="M9.18 15.363c0-1.236-.494-2.35-1.267-3.184a4.598 4.598 0 00-3.307-1.422A4.617 4.617 0 000 15.363c0 1.299.556 2.473 1.422 3.308l.03.03c.835.773 1.948 1.268 3.154 1.268 2.504 0 4.575-2.04 4.575-4.606zm-4.605 3.895c-.896 0-1.731-.309-2.38-.834l5.44-5.441c.526.65.835 1.484.835 2.38-.031 2.164-1.762 3.895-3.895 3.895zm0-7.759a3.9 3.9 0 012.535.958L1.67 17.93a3.9 3.9 0 01-.959-2.535c0-2.164 1.731-3.895 3.864-3.895z"
        fill="#979797"
      />
      <path
        d="M18.516 10.417V4.235v-.186-.123c0-1.051-.803-1.855-1.793-1.855a1.92 1.92 0 00-1.112.371v-.587A1.86 1.86 0 0013.756 0 1.86 1.86 0 0011.9 1.855c-.309-.248-.71-.371-1.113-.371a1.86 1.86 0 00-1.854 1.854V5.07a1.787 1.787 0 00-1.113-.371 1.86 1.86 0 00-1.855 1.854V9.49c0 .216.155.37.371.37s.371-.154.371-.37V6.553c0-.618.495-1.112 1.113-1.112s1.113.494 1.113 1.112v3.988c0 .216.154.37.37.37.217 0 .371-.154.371-.37V3.308c0-.619.495-1.113 1.113-1.113.619 0 1.113.494 1.113 1.113V10.974c0 .216.155.37.371.37s.371-.154.371-.37V3.4 1.824c0-.618.495-1.113 1.113-1.113s1.113.495 1.113 1.113v9.119c0 .216.154.37.37.37a.357.357 0 00.372-.37V3.988v-.062c0-.618.494-1.113 1.112-1.113.588 0 1.082.495 1.082 1.113V10.819c-.68.155-1.205.742-1.205 1.484v1.638c0 .773-.31 1.546-.804 2.071-.216.248-.494.402-.773.557a3.842 3.842 0 00-1.576 1.329c-.371.526-.588 1.113-.588 1.607a.2.2 0 00.186.186.2.2 0 00.185-.186c0-.834.742-2.07 1.917-2.596.309-.155.618-.34.896-.618a3.325 3.325 0 00.928-2.319v-1.638c0-.618.525-1.144 1.143-1.144.619 0 1.144.526 1.144 1.144v4.915a6.28 6.28 0 01-6.275 6.275c-2.102 0-4.05-1.02-5.193-2.782-.124-.155-.34-.216-.495-.093-.154.124-.216.34-.092.495a6.928 6.928 0 005.78 3.09c3.864 0 7.017-3.152 7.017-7.016V12.24c0-.896-.618-1.67-1.484-1.824z"
        fill="#979797"
      />
    </svg>
  )
}

// AI Icons
const BrainIcon = ({className}) => {
  return (
    <svg width={20} height={24} viewBox="0 0 20 24" fill="none" className={className}>
      <path
        d="M19.842 12.024l-2.226-3.39c-.124-.197-.174-.42-.198-.667C17.021 3.513 13.286 0 8.734 0A8.732 8.732 0 000 8.733c0 2.87 1.385 5.666 3.513 7.497.248.223.42.52.42.866v4.997c0 .396.298.718.694.767l7.496.669c.445.049.816-.322.816-.767v-3.563c0-.223.198-.42.421-.42h2.623c.816 0 1.46-.644 1.46-1.46v-3.514a.3.3 0 01.296-.296h1.287c.767.024 1.237-.842.816-1.485zm-8.98 3.068a1.225 1.225 0 01-1.164-.866H7.595a.38.38 0 01-.37-.371v-1.732H5.12a.38.38 0 01-.37-.371.38.38 0 01.37-.371h2.474a.38.38 0 01.372.37v1.733h1.756a1.225 1.225 0 011.163-.866 1.24 1.24 0 011.237 1.237c0 .668-.569 1.237-1.262 1.237zm0-4.478a1.225 1.225 0 01-1.164-.866H3.761a.38.38 0 01-.372-.371.38.38 0 01.372-.371h5.937a1.225 1.225 0 011.163-.866c.693 0 1.237.544 1.237 1.237 0 .692-.544 1.237-1.237 1.237zm0-4.478a1.225 1.225 0 01-1.164-.866H7.942v1.732a.38.38 0 01-.371.37H5.097a.38.38 0 01-.372-.37.38.38 0 01.372-.371H7.2V4.899a.38.38 0 01.37-.371h2.104a1.225 1.225 0 011.162-.866c.693 0 1.237.544 1.237 1.237 0 .692-.519 1.237-1.212 1.237z"
        fill="#0065A1"
      />
    </svg>
  )
}
const RankingIcon = ({className}) => {
  return (
    <svg width={24} height={20} viewBox="0 0 24 20" fill="none" className={className}>
      <path
        d="M14.637 6.53h-5.3a.614.614 0 00-.606.606v11.508c0 .328.278.606.606.606h5.325a.614.614 0 00.606-.606V7.136c-.026-.328-.303-.606-.631-.606zM5.905 10.896h-5.3A.614.614 0 000 11.5v7.142c0 .328.278.606.606.606H5.93a.614.614 0 00.605-.606v-7.142c-.025-.328-.303-.605-.63-.605zM1.59 7.514c.075.076.1.152.075.253l-.152 1.262a.322.322 0 00.455.328l1.16-.53c.076-.05.177-.05.278 0l1.161.53c.227.1.48-.076.454-.328L4.87 7.767c0-.101.025-.177.076-.253l.858-.933c.176-.177.075-.48-.177-.53l-1.262-.253a.368.368 0 01-.227-.151l-.63-1.11a.311.311 0 00-.556 0l-.631 1.11c-.05.076-.126.151-.227.151l-1.262.253c-.252.05-.353.353-.177.53l.934.933zM23.369 10.896h-5.3a.614.614 0 00-.605.605v7.142c0 .328.277.606.605.606h5.325a.614.614 0 00.606-.606v-7.142c-.026-.328-.303-.605-.631-.605zM19.054 7.514c.076.076.1.152.076.253l-.152 1.262a.323.323 0 00.454.328l1.161-.53c.076-.05.177-.05.278 0l1.16.53c.228.1.48-.076.455-.328l-.151-1.262c0-.101.025-.177.075-.253l.884-.933c.176-.177.075-.48-.177-.53l-1.262-.253a.367.367 0 01-.227-.151l-.63-1.11a.311.311 0 00-.556 0l-.631 1.11c-.05.076-.126.151-.227.151l-1.262.253c-.252.05-.353.353-.177.53l.909.933zM10.322 3.148c.076.076.101.152.076.253l-.151 1.261a.322.322 0 00.454.329l1.16-.53c.077-.05.178-.05.278 0l1.161.53c.227.1.48-.076.455-.329l-.152-1.261c0-.101.026-.177.076-.253l.883-.933c.177-.177.076-.48-.176-.53l-1.262-.253a.368.368 0 01-.227-.151L12.266.17a.311.311 0 00-.556 0l-.63 1.11c-.05.075-.127.151-.228.151l-1.261.253c-.253.05-.354.353-.177.53l.909.933z"
        fill="#0065A1"
      />
    </svg>
  )
}
const NotesIcon = ({className}) => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M2.135 8H1.22v2.129h.916V8zM2.135 11.843H1.22v2.128h.916v-2.128zM2.136 6.298h-.919V3.086c0-.928.757-1.685 1.688-1.685h11.472l-.757.92H2.905a.768.768 0 00-.769.768v3.209zM17.975 6.942v11.942a1.69 1.69 0 01-1.688 1.688H2.905a1.69 1.69 0 01-1.688-1.688v-3.203h.92v3.203c0 .425.343.769.768.769h13.382a.77.77 0 00.772-.769v-2.72h-.015v-1.492h.015V8.063l.916-1.12z"
        fill="#0065A1"
      />
      <path
        d="M2.343 14.43H.398a.395.395 0 100 .793h1.945a.777.777 0 100-.794zM2.344 10.587H.398a.399.399 0 000 .797h1.946a.777.777 0 001.445-.398.778.778 0 00-1.445-.399zM2.343 6.749H.398a.397.397 0 100 .792h1.945a.777.777 0 100-.792zM15.31 2.164l-.39.464c-2.213 2.71-4.427 5.416-6.64 8.13a1.747 1.747 0 00-.261.46c-.464 1.22-.914 2.438-1.365 3.648a1.428 1.428 0 00-.096.394c-.02.298.152.53.424.563a.571.571 0 00.265-.027c.139-.046.272-.122.37-.185l.61-.378c.726-.447 1.448-.898 2.17-1.355l.096-.06c.315-.198.643-.403.925-.748 1.5-1.845 3.008-3.684 4.509-5.526l2.289-2.806c.033-.036.063-.07.092-.106L15.31 2.164zm-5.019 10.618c-.453.285-.907.57-1.361.861-.103.06-.166.06-.242-.007-.106-.099-.225-.185-.328-.284-.033-.027-.053-.097-.036-.136.212-.583.427-1.16.646-1.743.006-.016.023-.033.026-.04.494.408.984.812 1.491 1.226-.06.04-.126.08-.196.123zM19.641 1.395A94.423 94.423 0 0018.2.219c-.454-.367-1.087-.264-1.509.244-.206.247-.406.496-.61.744l-.166.201c-.013.019-.03.04-.042.06l-.016-.013.22.18.066.053 2.48 2.019.265.212.243-.294c.191-.233.382-.465.57-.701.42-.518.395-1.149-.06-1.53z"
        fill="#0065A1"
      />
    </svg>
  )
}
const EmailsIcon = ({className}) => {
  return (
    <svg width={20} height={14} viewBox="0 0 20 14" fill="none" className={className}>
      <path
        d="M0 .51v12.826l7.96-6.012L0 .51zM19.44 0H.56L10 8.053 19.44 0zM12.04 7.324L20 13.299V.51l-7.96 6.815z"
        fill="#0065A1"
      />
      <path
        d="M10.28 8.818a.38.38 0 01-.28.11.38.38 0 01-.28-.11l-1.16-.984-8 6.012h18.92l-8.04-6.012-1.16.984z"
        fill="#0065A1"
      />
    </svg>
  )
}
const TextChatIcon = ({className}) => {
  return (
    <svg width={21} height={26} viewBox="0 0 21 26" fill="none" className={className}>
      <path
        d="M6.858 6.76v5.944c0 1.209.98 2.221 2.221 2.221h.653v2.221c0 .261.131.457.36.555a.605.605 0 00.228.066c.164 0 .294-.066.425-.164l2.776-2.678h5.258c1.209 0 2.221-.98 2.221-2.22V6.76c0-1.209-.98-2.221-2.22-2.221H9.046c-1.176.032-2.189 1.012-2.189 2.22zm12.966 0v5.944c0 .556-.457.98-.98.98h-5.519c-.163 0-.294.066-.425.164l-1.926 1.861v-1.404a.62.62 0 00-.621-.62H9.112a.983.983 0 01-.98-.98V6.76c0-.556.457-.98.98-.98h9.732c.523 0 .98.457.98.98zM9.57 8.427a.62.62 0 01.62-.62h7.251a.62.62 0 110 1.24h-7.25c-.327-.032-.62-.293-.62-.62zm0 2.743a.62.62 0 01.62-.62h7.251a.62.62 0 110 1.241h-7.25a.642.642 0 01-.62-.62zM0 23.287V1.96C0 .882.882 0 1.96 0h11.855c1.078 0 1.96.882 1.96 1.96v1.045a.62.62 0 11-1.242 0V1.96a.725.725 0 00-.718-.719H1.96a.725.725 0 00-.719.719v21.326c0 .392.327.719.719.719h11.855a.725.725 0 00.718-.719v-6.76a.62.62 0 111.242 0v6.76c0 1.078-.882 1.96-1.96 1.96H1.96A1.965 1.965 0 010 23.286zm9.994-1.666a.62.62 0 01-.62.621H6.335a.62.62 0 110-1.241h3.037a.6.6 0 01.62.62z"
        fill="#0065A1"
      />
    </svg>
  )
}
const BubbleChatIcon = ({className}) => {
  return (
    <svg width={26} height={20} viewBox="0 0 26 20" fill="none" className={className}>
      <path
        d="M8.357 11.91c0-4.058 4.226-7.361 9.42-7.361 1.417 0 2.76.246 3.966.687C20.004 2.155 15.998 0 11.334 0 5.074 0 0 3.88 0 8.668c0 2.42 1.298 4.607 3.388 6.18l-.827 4.021 3.73-2.44c1.52.58 3.23.907 5.043.907l.084-.002c-1.88-1.346-3.06-3.279-3.06-5.424z"
        fill="#0065A1"
      />
      <path
        d="M26 11.91c0-3.324-3.627-6.03-8.088-6.03-4.46 0-8.09 2.706-8.09 6.03 0 3.326 3.63 6.031 8.09 6.031 1.016 0 2.012-.144 2.962-.424l2.586 1.56-.383-2.542C24.937 15.379 26 13.707 26 11.91z"
        fill="#0065A1"
      />
    </svg>
  )
}
// AI Icons

const ExportIcon = ({className}) => {
  return (
    <svg width={14} height={20} viewBox="0 0 14 20" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.624 5.138l4.876 4.944c.858.86-.43 2.152-1.288 1.291l-2.87-2.878c-.24-.24-.431-.161-.431.174v9.972a.912.912 0 11-1.821 0V8.67c0-.335-.194-.413-.432-.174l-2.87 2.879c-.858.86-2.146-.431-1.288-1.292l4.844-4.94a.895.895 0 011.28-.004zM.913 1.826A.913.913 0 01.913 0h12.174a.913.913 0 010 1.826H.913z"
        fill="#0065A1"
      />
    </svg>
  )
}
const CopyIcon = ({className}) => {
  return (
    <svg width={14} height={20} viewBox="-21 0 512 512" className={className}>
      <path d="M410.668 405.332H165.332c-32.363 0-58.664-26.3-58.664-58.664v-288c0-32.363 26.3-58.668 58.664-58.668h181.504c21.059 0 41.687 8.535 56.555 23.445l42.496 42.496c15.125 15.125 23.445 35.223 23.445 56.575v224.152c0 32.363-26.3 58.664-58.664 58.664zM165.332 32c-14.7 0-26.664 11.969-26.664 26.668v288c0 14.7 11.965 26.664 26.664 26.664h245.336c14.7 0 26.664-11.965 26.664-26.664V122.516c0-12.82-4.992-24.871-14.059-33.942l-42.496-42.496C371.84 37.121 359.488 32 346.836 32zm0 0" />
      <path d="M314.668 512h-256C26.305 512 0 485.695 0 453.332V112c0-32.363 26.305-58.668 58.668-58.668h10.664c8.832 0 16 7.168 16 16s-7.168 16-16 16H58.668C43.968 85.332 32 97.301 32 112v341.332C32 468.032 43.969 480 58.668 480h256c14.7 0 26.664-11.969 26.664-26.668v-10.664c0-8.832 7.168-16 16-16s16 7.168 16 16v10.664c0 32.363-26.3 58.668-58.664 58.668zm0 0M368 181.332H208c-8.832 0-16-7.168-16-16s7.168-16 16-16h160c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" />
      <path d="M368 245.332H208c-8.832 0-16-7.168-16-16s7.168-16 16-16h160c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 309.332H208c-8.832 0-16-7.168-16-16s7.168-16 16-16h160c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" />
    </svg>
  )
}

const EditIcon = ({className}) => {
  return (
    <svg width={20} height={20} fill="#000" className={className}>
      <path d="M.43 15.877h14.646a.29.29 0 00.308-.308v-5.446h-.615v5.17H.738V1.23h5.17V.615H.43a.29.29 0 00-.308.308V15.57a.29.29 0 00.308.308zM13.354.123a.276.276 0 00-.215.092L5.754 7.6c-.03.03-.062.092-.092.123L4.8 10.8c-.03.154.062.338.215.37H5.2l3.077-.862c.062 0 .092-.03.123-.092l7.385-7.385a.297.297 0 000-.43L13.57.185a.57.57 0 00-.215-.062zm-7.816 10.34l.585-2.092L7.63 9.877l-2.092.585zM8.215 9.6L6.43 7.815 13.354.892l1.785 1.785L8.215 9.6z" />
    </svg>
  )
}
const CloseFillIcon = ({className}) => {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" className={className}>
      <path
        d="M13 .135C5.904.135.135 5.905.135 13c0 7.096 5.77 12.865 12.865 12.865 7.096 0 12.865-5.77 12.865-12.865C25.865 5.904 20.095.135 13 .135z"
        fill="#FF0321"
      />
      <path
        d="M18.552 7.448c-.27-.27-.731-.27-1.029 0L13 11.971 8.477 7.448c-.27-.27-.731-.27-1.029 0-.27.27-.27.731 0 1.03L11.971 13l-4.523 4.523c-.27.27-.27.731 0 1.03a.74.74 0 00.515.216.74.74 0 00.514-.217L13 14.03l4.523 4.523a.74.74 0 00.515.217.74.74 0 00.514-.217c.271-.27.271-.731 0-1.029L14.03 13l4.523-4.523c.271-.27.271-.731 0-1.029z"
        fill="#fff"
      />
    </svg>
  )
}

const PdfIcon = ({className}) => {
  return (
    <svg width={20} height={20} fill="#000" className={className}>
      <path d="M15.475,6.692l-4.084-4.083C11.32,2.538,11.223,2.5,11.125,2.5h-6c-0.413,0-0.75,0.337-0.75,0.75v13.5c0,0.412,0.337,0.75,0.75,0.75h9.75c0.412,0,0.75-0.338,0.75-0.75V6.94C15.609,6.839,15.554,6.771,15.475,6.692 M11.5,3.779l2.843,2.846H11.5V3.779z M14.875,16.75h-9.75V3.25h5.625V7c0,0.206,0.168,0.375,0.375,0.375h3.75V16.75z"></path>
    </svg>
  )
}


export {
  ClientsColorIcon,
  YourAgentIcon,
  YourAgentColorIcon,
  AgentAppointmentsIcon,
  AgentAppointmentsColorIcon,
  TownCheatSheetIcon,
  TownCheatSheetColorIcon,
  TownsIcon,
  TownsColorIcon,
  JunglerUsersIcon,
  JunglerUsersColorIcon,
  ArrowIcon,
  AttachmentIcon,
  BackArrowIcon,
  BlockNotReadyTourIcon,
  CalendarIcon,
  CalendarOtherIcon,
  CallIcon,
  CameraIcon,
  ExportIcon,
  BrainIcon,
  RankingIcon,
  NotesIcon,
  EmailsIcon,
  TextChatIcon,
  BubbleChatIcon,
  CaretLeftIcon,
  CaretRightIcon,
  ChatIcon,
  CloseIcon,
  EditIcon,
  CloseFillIcon,
  CopyIcon,
  ColoredTasklistIcon,
  ColoredUserIcon,
  DashboardColorIcon,
  DashboardIcon,
  DeleteIcon,
  DownCaretIcon,
  DraftIcon,
  DuplicateIcon,
  FeaturedIcon,
  FilterIcon,
  GoogleIcon,
  HeartIcon,
  LeadsColorIcon,
  LeadsIcon,
  LockIcon,
  MoreIcon,
  MylistingColorIcon,
  MylistingIcon,
  NavbarIcon,
  NotificationIcon,
  BellIcon,
  NotReadyTourIcon,
  NotReadys,
  NotTouchedIcon,
  QuestionIcon,
  SearchIcon,
  SortDownIcon,
  SortUpIcon,
  TourIcon,
  TrackIcon,
  UserIcon,
  ViewIcon,
  LaunchDateIcon,
  PdfIcon,
  ConstructionIcon
}
