import React, { Component, Fragment } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import get from 'lodash/get';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import $ from 'jquery';

import Config from '../../config';
// Agent_v2
import Agent_Dashboard from '../Dashboard_v2/Agent/Dashboard/container';
import Agent_ClientDetails from '../Dashboard_v2/Agent/ClientDetails/container';
import Agent_Calendar from '../Dashboard_v2/Agent/Calendar/container';
import TimeSelector from '../Dashboard_v2/Agent/Calendar/partials/TimeSelector/component';
import Agent_Clients from '../Dashboard_v2/Agent/Clients/container';
import Agent_TaskList from '../Dashboard_v2/Agent/TaskList/container';
import Agent_UpdateProfile from '../Dashboard_v2/Agent/UpdateProfile/container';
import Agent_Signup from '../Dashboard_v2/Agent/Signup/container';
// Agent_v2

// Strategist_v2
import Strategist_Dashboard from "../Dashboard_v2/Strategist/Dashboard/container";
import Strategist_Clients from "../Dashboard_v2/Strategist/Clients/container";
import StrategistEditClientInformation from "../Dashboard_v2/Strategist/ClientDetails/EditClientInformation/container";
import StrategistClientDetails from "../Dashboard_v2/Strategist/ClientDetails/container";
import Strategist_TaskList from "../Dashboard_v2/Strategist/TaskList/container";
import StrategistJunglerUsers from "../Dashboard_v2/Strategist/JunglerUsers/container";
import StrategistYourAgents from "../Dashboard_v2/Strategist/YourAgents/container";
import StrategistAgentInterviews from "../Dashboard_v2/Strategist/AgentInterviews/container";
import StrategistAgentAppointments from "../Dashboard_v2/Strategist/AgentAppointments/container";
import StrategistTowns from '../Dashboard_v2/Strategist/Towns/container'
import StrategistTownCharacteristics from '../Dashboard_v2/Strategist/Towns/TownCharacteristics/container'
import StrategistTownCheatSheet from "../Dashboard_v2/Strategist/TownCheatSheet/container";
import AdministrationUpdateProfile from '../Dashboard_v2/Strategist/UpdateProfile/container'

import EditStrategist from "../Dashboard_v2/Strategist/ClientDetails/Strategy/container";

//Reports start
import AccountReport_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AccountReport/container'
import BrokerageReport from '../Dashboard_v2/Strategist/Dashboard/Reports/BrokerageReport/container'
import AgentAssignedToTowns_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AgentAssignedToTowns/container'
import AgentRatingReport_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AgentRatingReport/container'
import AgentsAssignedByNYSJStrategists_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AgentsAssignedByNYSJStrategists/container'
import AgentStatistics_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AgentStatistics/container'
import AppointmentScheduled_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AppointmentScheduled/container'
import BusinessDevelopment_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/BusinessDevelopment/container'
import ClientsAssignedPerTown_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/ClientsAssignedPerTown/container'
import ClientsAssignedToAgents_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/ClientsAssignedToAgents/container'
import ClosedClientsPerTown_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/ClosedClientsPerTown/container'
import AdministrationClientsPerAgents from '../Dashboard_v2/Strategist/Dashboard/Reports/ClientsPerAgents/container'
import LoginRecords_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/LoginRecords/container'
import NewClients_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/NewClients/container'
import NoInitialOrFollowUpAgentCall_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/NoInitialOrFollowUpAgentCall/container'
import NoInitialOrFollowUpAgentVisit_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/NoInitialOrFollowUpAgentVisit/container'
import NotTouchedByAgentIn4Weeks_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/NotTouchedByAgentIn4Weeks/container'
import StrategistRatingReport_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/StrategistRatingReport/container'
import StrategistStatistics_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/StrategistStatistics/container'
import StrategistTimesheet_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/StrategistTimesheet/container'
import TotalClientsReceivedPerMonth_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/TotalClientsReceivedPerMonth/container'
import AIClients_V2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AIClients/container'
import AdTrackingReport_V2 from '../Dashboard_v2/Strategist/Dashboard/Reports/AdTrackingReport/container'
import BDSourceReport_v2 from '../Dashboard_v2/Strategist/Dashboard/Reports/BDSourceReport/container'

import StrategistTownReports from '../Dashboard_v2/Strategist/Towns/container'
import StrategistAddClient from '../Dashboard_v2/Strategist/AddClient/container'
import StrategistAddUser from '../Dashboard_v2/Strategist/AddUser/container'
import StrategistEditUser from '../Dashboard_v2/Strategist/EditUser/container'
import StrategistDeal from '../Dashboard_v2/Strategist/Deal/container'
import StrategistTownDetails from '../Dashboard_v2/Strategist/TownDetails/container'
import StrategistAI from '../Dashboard_v2/Strategist/AiAnswers/container'
import StrategistEmails from '../Dashboard_v2/Strategist/Emails/container'
import StrategistAdminEditDeal from '../Dashboard_v2/Strategist/AdminEditDeal/container'

//Report end
// Strategist_v2

//import io from 'socket.io-client'
//let socket = io(`http://localhost:3018`)
import UserModel from '../../store/user/user-model';
import Dashboard from '../Dashboard/container';
import ClientDashboard from '../ClientDashboard/container';
import SideNavigation from '../../components/sideNavigation';
import TopNavigation from '../TopNavigation/container';
import AdminDashboard from '../AdminDashboard/container';
import Agent from '../Agent/container';
import Clients from '../Clients/container';
import MessageCenter from '../MessageCenter/container';
import Calendar from '../Calendar/container';
import ClientActivity from '../ClientActivity/container';
import ClientProfile from '../ClientProfile/container';
import StratagistEditClientProfile from '../StratagistEditClientProfile/container';
import Profile from '../Profile/container';
import Availability from '../Availability/container';
import Strategy from '../Strategy/container';
import StrategyEdit from '../StrategyEdit/container';
import AddDeal from '../AddDeal/container';
import Login from '../Login/container';
import Signup from '../Signup/container';
import ResetPassword from '../ResetPassword/container';
import JunglerUsers from '../JunglerUsers/container';
import ThankYou from '../ThankYou/container';
import ClientLogin from '../ClientLogin/container';
import UserGoogleAuth from '../UserGoogleAuth/container';
import ClientsList from '../ClientsList/container';
import AddJunglerUser from '../AddJunglerUser/container';
import AgentCalendar from '../AgentCalendar/container';
import CalendarAppointment from '../CalendarAppointment/container';
import AddClients from '../AddClients/container';
import AgentApp from '../AgentApp/container';
import ClientsAppointment from '../ClientsAppointment/container';
import JunglerUsersProfile from '../JunglerUsersProfile/container';
import ImpersonatUser from '../ImpersonatUser/container';
import ClientChatCenter from '../ClientChatCenter/container';
import ClientChatWithAgent from '../ClientChatWithAgent/container';
import AgentChatWithClient from '../AgentChatWithClient/container';

import StratagistAgentChatCenter from '../StratagistAgentChatCenter/container';
import AgentStratagistChatCenter from '../AgentStratagistChatCenter/container';
import TownReport from '../TownReport/container';

// import AgentAllClient from '../AgentAllClient/container'
// import AgentEditProfile from '../AgentEditProfile/container'
// import AgentDash from '../AgentDashboard/dashboard/container'
// import AgentDashboard from '../AgentDashboard/container'
// import AgentClientsProfile from '../AgentClientsProfile/container'

import OtpVerification from '../OtpVerification/container';
import AnalyticsSubmissions from '../AdminDashboard/AnalyticsSubmissions/container';
import AnalyticsClient from '../AdminDashboard/AnalyticsClient/container';
import AnalyticsDeals from '../AdminDashboard/AnalyticsDeals/container';
import AnalyticsStratagist from '../AdminDashboard/AnalyticsStratagist/container';
import OperationReporting from '../AdminDashboard/OperationReporting/container';
import LoginReportData from '../AdminDashboard/LoginReport/container';
import AgentReporting from '../AdminDashboard/AgentReporting/container';
import StrategistReporting from '../AdminDashboard/StrategistReporting/container';
import StrategistDashboard from '../StrategistDashboard/container';

import oauth2Callback from '../oauth2Callback/container';
import outlookCallback from '../outlookCallback/container';
import QuestionnaireCompleted from '../AdminDashboard/QuestionnaireCompleted/container';
import AppointmentScheduled from '../AdminDashboard/AppointmentScheduled/container';
import StrategistStatistics from '../AdminDashboard/StrategistStatistics/container';
import AgentStatistics from '../AdminDashboard/AgentStatistics/container';
import NotTouchedIn4Week from '../AdminDashboard/NotTouchedIn4Week/container';
import ClientsPerAgents from '../AdminDashboard/ClientsPerAgents/container';
import ClientsAssignToAgents from '../AdminDashboard/ClientsAssignToAgents/container';
import ClientsPerTown from '../AdminDashboard/ClientsPerTown/container';
import AgentsAssignByStrategist from '../AdminDashboard/AgentsAssignByStrategist/container';
import StrategistRatingReport from '../AdminDashboard/StrategistRatingReport/container';
import NoInitialCall from '../AdminDashboard/NoInitialCall/container';
import NoInitialTour from '../AdminDashboard/NoInitialTour/container';
import NoAgentRatingReport from '../AdminDashboard/NoAgentRatingReport/container';
import AgentRatingReport from '../AdminDashboard/AgentRatingReport/container';
import AgentAssignedToTowns from '../AdminDashboard/AgentAssignedToTowns/container';
import TotalClientsReceivedPerMonth from '../AdminDashboard/TotalClientsReceivedPerMonth/container';
import AccountingReport from '../AdminDashboard/AccountingReport/container';
import StrategistTimesheet from '../AdminDashboard/StrategistTimesheet/container';
import BusinessDevelopment from '../AdminDashboard/BusinessDevelopment/container';
import AIView from '../AI/container';
import UserContext from './Context';
import * as Phase from '../../constants/phase';
import ConstructionBanner from '../../components/ConstructionBanner'
import SiteMaintenancePage from '../../components/SiteMaintenancePage'
// import EmailTemplate from '../Dashboard_v2/Strategist/EmailTemplate/container';

const INDEX_ROUTE = process.env.REACT_APP_ARTAPIE_INDEX_ROUTE;
const cookies = new Cookies();
const history = createBrowserHistory();
const PUBLIC_URL = process.env.PUBLIC_URL;

const PrivateRoute = ({ component, ...rest }) => {
  let user = cookies.get('user');
  let auth = cookies.get('Authorization');
  const isAuthed = user && user !== 'undefined' && user !== '' ? true : false;
  if (auth && auth !== 'undefined') {
    return (
      <Route
        exact
        {...rest}
        render={(props) =>
          isAuthed ? (
            <div>{React.createElement(component, props)}</div>
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        exact
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func]).isRequired,
  location: PropTypes.object,
};

class App extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  static propTypes = {
    token: PropTypes.string,
    phase: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const bannerStatus = localStorage.getItem('isBannerOpen')
    this.state = {
      userData: {},
      isBannerOpen: bannerStatus !== null ? (bannerStatus === 'true') : true
    };
  }

  async authClick(){
    const impersonate_link = document.getElementById("impersonate__link")
    if(impersonate_link)impersonate_link.click()
  }

  deleteCookies() {
    localStorage.clear();
    document.cookie ="Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    document.cookie ="did =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
  }

  needToResetRoute = () => {
    let user = cookies.get('user')
    const excluded = ['/','login', 'client-login', 'signup', 'oauth2callback']
    const pathName = window.location.pathname
    const roleInRoute = pathName.split('/')[1]

    if(!user && !excluded.includes(roleInRoute))return true
    if(!roleInRoute || excluded.includes(roleInRoute))return false

    const agentAccess = ['agent','prospect-agent', 'local', 'business development']
    const strategistAcess = ['administration', 'strategist']
    const clientAccess = ['clients']
    const preventedRoutes = ['/strategist/email-template', '/clients']

    if(agentAccess.includes(user.role) && roleInRoute !== 'agent')return true
    else if(user.role === 'strategist' && !strategistAcess.includes(roleInRoute))return true
    else if(user.role === "client" && !clientAccess.includes(roleInRoute))return true
    else if((user.role === 'admin' || user.role === 'superadmin') &&
    (preventedRoutes.find(route => pathName.startsWith(route))))return true

    return false
  }

  componentDidMount() {
    const { refreshToken } = this.props;
    let user = cookies.get('user');
    let authorization = cookies.get('Authorization');
    let iAuthorization = cookies.get('i_Authorization');
    let device_id = cookies.get('did');
    const pathName = window.location.pathname

    if(pathName !== '/login' && pathName !== '/login/token') {
      if(!authorization) {
        authorization = iAuthorization
        if(!authorization && pathName !== '/login') {
          this.deleteCookies()
          document.location.pathname = '/login'
        }
      }
    }

    if(!device_id) {
      this.deleteCookies()
      let did = Math.random().toString(36).substr(2, 9);
      let expires = new Date()
      expires.setMonth(expires.getMonth()+36);
      expires = new Date(expires)
      cookies.set('did', did, { path: '/', expires, secure: true });
      if(pathName !== '/login' && pathName !== '/login/token') {
        document.location.pathname = '/login'
      }
    }

    if (user && user.id) {
      this.setState({ userid: user.id });
      const data = {};
      data.user_id = user && user.id;
      this.props.fetchUser(data);
    }
    // if(user && user != null && authorization && authorization !== undefined && authorization !== 'undefined'){
    //   refreshToken()
    // }
    // else{
    //   cookies.remove('Authorization')
    //   cookies.remove('user')
    // }

    if(this.needToResetRoute()){
      if(iAuthorization && user.role !== "client")setTimeout(this.authClick, 100)
      else document.location.pathname = '/login'
      document.location.pathname = '/login'
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (get(state, 'userData.user_id', false)) {
      return { userData: state.userData };
    } else {
      return { userData: props.user };
    }
  }

  componentDidUpdate() {
    let user = cookies.get("user");
    let routePath = "";
    if (
      (user && user.role && user.role === "superadmin") ||
      (user && user.role && user.role === "admin")
    ) {
      routePath = "/dashboard";
    }
    if (user && user.role && user.role.toLowerCase() === "strategist") {
      routePath = "/administration/dashboard";
    } else if (user && user.role && user.role.toLowerCase() === "client") {
      routePath = "/clients";
    } else if (user && user.role && user.role.toLowerCase() === "agent") {
      // routePath = "/agent/dashboard";
      routePath = "/agent/clients/all-clients";
    } else if (user) {
      routePath = '/user-google-auth';
    }
    const pathName = window.location.pathname;
    if (
      user &&
      (pathName === '/login' ||
        pathName === '/' ||
        pathName === '/client-login' ||
        pathName === '/signup')
    ) {
      document.location.pathname = routePath;
    }
  }

  updateUserData = (data) => {
    this.setState({ userData: data });
    this.forceUpdate();
  };

  toggleBanner = (toggleState) => {
    this.setState({ ...this.state, isBannerOpen: toggleState })
    localStorage.setItem('isBannerOpen', toggleState)
  }

  render() {
    const { user, token, phase } = this.props;
    const { userData } = this.state;
    const href = window.location.href;
    //Adding this script for commonly add a enter submit for client chat input, agent chat input and login etc pages.
    // Execute a function when the user releases a key on the keyboard
    $(".chat___msg--input").on("keyup", function (event) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Trigger the button element with a click
        // $(".chat__msg--send__btn").click();
      }
    });

    const bannerHeight = '100px'

    return (
      <Router history={history}>
        <Switch>
          <UserContext.Provider
            value={{
              userData,
              updateUserData: this.updateUserData
            }}
          >
            <Fragment>
              {/*<ConstructionBanner bannerHeight={bannerHeight} isBannerOpen={this.state.isBannerOpen} closeBanner={() => this.toggleBanner(false)} />*/}
              <div className="app__container"
                   // style={{marginTop: this.state.isBannerOpen ? bannerHeight : 0}}
              >
                {/*<Route exact path={'/site-maintenance'} component={SiteMaintenancePage} {...this.props}/>*/}
                {/* Start Agent_v2 */}
                <PrivateRoute
                  exact
                  path={'/agent/dashboard'}
                  component={Agent_Dashboard}
                  // component={props => (
                  //   <Redirect
                  //     to={`/agent/clients/all-clients`}
                  //     {...props}
                  //   />
                  // )}
                  {...this.props}
                />
                <PrivateRoute
                  exact
                  path={'/agent/client-details/:id'}
                  component={Agent_ClientDetails}
                  {...this.props}
                />

                <PrivateRoute
                  exact
                  path={'/agent-clients-profile/:id'}
                  component={props => (
                    <Redirect
                      to={`/agent/client-details/${props.match.params.id}`}
                      {...props}
                    />
                  )}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/agent/calendar'}
                  component={Agent_Calendar}
                  {...this.props}
                />
                <Route
                  path={'/agent/time'}
                  component={TimeSelector}
                  {...this.props}
                />
                <Route
                  path={'/agent/clients'}
                  component={Agent_Clients}
                  {...this.props}
                />
                <Route
                  path={'/agent/task-list'}
                  // component={Agent_TaskList}
                  component={props => (
                    <Redirect
                      to={`/agent/clients/all-clients`}
                      {...props}
                    />
                  )}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent/update-profile'}
                  component={Agent_UpdateProfile}
                  {...this.props}
                />
                <Route
                  path={'/agent/signup'}
                  component={Agent_Signup}
                  {...this.props}
                />
                {/* End Agent_v2 */}

                {/* Start Strategist_v2 */}
                <PrivateRoute exact path={'/administration/dashboard'} component={Strategist_Dashboard} {...this.props} />
                <Route path={'/strategist/clients'} component={Strategist_Clients} {...this.props} />
                <PrivateRoute exact path={'/strategist/client-details/:id'} component={StrategistClientDetails} {...this.props} />
                <PrivateRoute exact path={'/strategist/edit-information/:id'} component={StrategistEditClientInformation} {...this.props} />
                <PrivateRoute exact path={'/strategist/emails/:id'} component={StrategistEmails} {...this.props} />
                <PrivateRoute exact path={'/strategist/ai/:id'} component={StrategistAI} {...this.props} />
                <PrivateRoute exact path={'/strategist/deal'} component={StrategistDeal} {...this.props} />
                <PrivateRoute exact path={'/strategist/town-cheat-sheet'} component={StrategistTownCheatSheet} {...this.props} />
                <PrivateRoute exact path={'/strategist/add-client'} component={StrategistAddClient} {...this.props} />
                <PrivateRoute exact path={'/strategist/add-user'} component={StrategistAddUser} {...this.props} />
                <PrivateRoute exact path={'/strategist/edit-user/:id'} component={StrategistEditUser} {...this.props} />

                <PrivateRoute exact path={'/strategist/town-details/:id'} component={StrategistTownDetails} {...this.props} />
                <PrivateRoute exact path={'/strategist/edit-deal/:id'} component={StrategistAdminEditDeal} {...this.props} />


                {/* <PrivateRoute exact path={'/strategist/email-template'} component={EmailTemplate} {...this.props} /> */}

                <PrivateRoute
                  exact
                  path={"/strategist/task-list/:taskListCategory?"}
                  component={Strategist_TaskList}
                  {...this.props}
                />
                <PrivateRoute
                  exact
                  path={"/strategist/towns"}
                  component={StrategistTowns}
                  {...this.props}
                />

                {/********* reports *********/}
                <PrivateRoute exact path={'/report/account-report'} component={AccountReport_v2} {...this.props} />
                <PrivateRoute exact path={'/report/brokerage-report'} component={BrokerageReport} {...this.props} />
                <PrivateRoute exact path={'/report/agents-assigned-to-towns'} component={AgentAssignedToTowns_v2} {...this.props} />
                <PrivateRoute exact path={'/report/agent-rating-report'} component={AgentRatingReport_v2} {...this.props} />
                <PrivateRoute exact path={'/report/agents-assign-by-ny-strategist'} component={AgentsAssignedByNYSJStrategists_v2} {...this.props} />
                <PrivateRoute exact path={'/report/agent-statistics'} component={AgentStatistics_v2} {...this.props} />
                <PrivateRoute exact path={'/report/appointment-scheduled'} component={AppointmentScheduled_v2} {...this.props} />
                <PrivateRoute exact path={'/report/business-development'} component={BusinessDevelopment_v2} {...this.props} />
                <PrivateRoute exact path={'/report/clients-assigned-per-town'} component={ClientsAssignedPerTown_v2} {...this.props} />
                <PrivateRoute exact path={'/report/clients-assign-to-agents'} component={ClientsAssignedToAgents_v2} {...this.props} />
                <PrivateRoute exact path={'/report/closed-clients-per-towns'} component={ClosedClientsPerTown_v2} {...this.props} />
                <PrivateRoute exact path={'/report/clients-per-agents'} component={AdministrationClientsPerAgents} {...this.props} />
                <PrivateRoute exact path={'/report/login-records'} component={LoginRecords_v2} {...this.props} />
                <PrivateRoute exact path={'/report/new-clients'} component={NewClients_v2} {...this.props} />
                <PrivateRoute exact path={'/report/no-initial-agent-call'} component={NoInitialOrFollowUpAgentCall_v2} {...this.props} />
                <PrivateRoute exact path={'/report/no-initial-agent-visit'} component={NoInitialOrFollowUpAgentVisit_v2} {...this.props} />
                <PrivateRoute exact path={'/report/not-touched-agent-in-4-weeks'} component={NotTouchedByAgentIn4Weeks_v2} {...this.props} />
                <PrivateRoute exact path={'/report/strategist-rating-report'} component={StrategistRatingReport_v2} {...this.props} />
                <PrivateRoute exact path={'/report/strategist-statistics'} component={StrategistStatistics_v2} {...this.props} />
                <PrivateRoute exact path={'/report/strategist-timesheet'} component={StrategistTimesheet_v2} {...this.props} />
                <PrivateRoute exact path={'/report/total-clients-received-per-month'} component={TotalClientsReceivedPerMonth_v2} {...this.props} />
                <PrivateRoute exact path={'/report/ai-clients'} component={AIClients_V2} {...this.props} />
                <PrivateRoute exact path={'/report/ad-tracking'} component={AdTrackingReport_V2} {...this.props} />
                <PrivateRoute exact path={'/report/bd-source-report'} component={BDSourceReport_v2} {...this.props} />
                {/* Start Strategist_v2 */}
              </div>
              <div className="App-pageContainer">
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route path={'/client-login'} component={ClientLogin} />
                <Route path={'/signup'} component={Signup} />
                <Route
                  path={'/thankyou'}
                  component={ThankYou}
                  {...this.props}
                />
                <Route
                  path={'/agent-app'}
                  component={AgentApp}
                  {...this.props}
                />
                <Route path={'/forgotpassword'} component={ResetPassword} />
                <Route path={'/reset/:token'} component={ResetPassword} />
                <Route path={'/login'} component={Login} />
                <Route path={'/site-maintenance'} component={SiteMaintenancePage} />
                <Route
                  path={'/otp-verify'}
                  user={user}
                  component={OtpVerification}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/user-google-auth'}
                  user={user}
                  component={UserGoogleAuth}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/impersonating-user'}
                  user={user}
                  component={ImpersonatUser}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/availability'}
                  user={user}
                  component={Availability}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/profile'}
                  user={user}
                  component={Profile}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/oauth2callback'}
                  user={user}
                  component={oauth2Callback}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/outlookcallback'}
                  user={user}
                  component={outlookCallback}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/home'}
                  user={user}
                  component={Dashboard}
                  {...this.props}
                />

                <PrivateRoute isExact={false} exact={false} path={'/strategist/town-characteristics/:townId/:characteristicName?'} component={StrategistTownCharacteristics} {...this.props} />

                {/* This is agent old path */}
                {/* <PrivateRoute path={'/agent-dashboard'} user={user} component={AgentDash} {...this.props} />
                <PrivateRoute path={'/agent-home'} user={user} component={AgentDashboard} {...this.props} />
                <PrivateRoute path={'/agent-home/task-list'} user={user} component={AgentDashboard} {...this.props} />
                <PrivateRoute path={'/agent-home/active-clients'} user={user} component={AgentDashboard} {...this.props} />
                <PrivateRoute path={'/agent-home/closed-clients'} user={user} component={AgentDashboard} {...this.props} />
                <PrivateRoute path={'/agent-all-clients'} user={user} component={AgentAllClient} {...this.props} />
                <PrivateRoute path={'/agent'} user={user} component={Agent} {...this.props} />
                <PrivateRoute path={'/agent-clients-profile'} user={user} component={AgentClientsProfile} {...this.props} />
                <PrivateRoute path={'/agent-clients-profile/:id'} user={user} component={AgentClientsProfile} {...this.props} />
                <PrivateRoute path={'/text-messages'} user={user} component={AgentClientsProfile} {...this.props} />
                <PrivateRoute path={'/clients-notes'} user={user} component={AgentClientsProfile} {...this.props} />
                <PrivateRoute path={'/background-profile'} user={user} component={AgentClientsProfile} {...this.props} />
                <PrivateRoute path={'/agent-edit-profile'} user={user} component={AgentEditProfile} {...this.props} />
                <PrivateRoute path={'/agent-home/agent-clients-profile/:id'} user={user} component={AgentClientsProfile} {...this.props} /> */}
                {/* End of agent old path */}

                {/*<PrivateRoute path={'/client-home'} user={user} component={ClientDashboard} {...this.props} />*/}

                {/*<PrivateRoute path={'/clients'} user={user} component={Clients} {...this.props} />*/}
                <PrivateRoute
                  path={'/clients'}
                  user={user}
                  component={ClientDashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/chat-center'}
                  user={user}
                  component={MessageCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/chat-center/:id'}
                  user={user}
                  component={MessageCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/client-chat-center'}
                  user={user}
                  component={ClientChatCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/client-chat-center/:id'}
                  user={user}
                  component={ClientChatCenter}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/client-chat-with-agent'}
                  user={user}
                  component={ClientChatWithAgent}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/client-chat-with-agent/:id'}
                  user={user}
                  component={ClientChatWithAgent}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/agent-chat-with-client'}
                  user={user}
                  component={AgentChatWithClient}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-chat-with-client/:id'}
                  user={user}
                  component={AgentChatWithClient}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/stratagist-agent-chat'}
                  user={user}
                  component={StratagistAgentChatCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/stratagist-agent-chat/:id'}
                  user={user}
                  component={StratagistAgentChatCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-stratagist-chat'}
                  user={user}
                  component={AgentStratagistChatCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-stratagist-chat/:id'}
                  user={user}
                  component={AgentStratagistChatCenter}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/calendar'}
                  user={user}
                  component={Calendar}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/clients-emails/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/text-messages/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/clients-notes/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/background-profile/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/clients-strategy/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/clients-chat/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/clients-deal/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/activity/recording-links/:id'}
                  user={user}
                  component={ClientActivity}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/analytics-submissions'}
                  user={user}
                  component={AnalyticsSubmissions}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/analytics-client'}
                  user={user}
                  component={AnalyticsClient}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/analytics-deal'}
                  user={user}
                  component={AnalyticsDeals}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/questionnaire-completed'}
                  user={user}
                  component={QuestionnaireCompleted}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/appointment-scheduled'}
                  user={user}
                  component={AppointmentScheduled}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/analytics-strategist'}
                  user={user}
                  component={AnalyticsStratagist}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/operation-report'}
                  user={user}
                  component={OperationReporting}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/login-records'}
                  user={user}
                  component={LoginReportData}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-report'}
                  user={user}
                  component={AgentReporting}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/strategist-report'}
                  user={user}
                  component={StrategistReporting}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/strategist-statistics'}
                  user={user}
                  component={StrategistStatistics}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/clients-per-agents'}
                  user={user}
                  component={ClientsPerAgents}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/clients-assign-to-agents'}
                  user={user}
                  component={ClientsAssignToAgents}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/clients-assign-per-town'}
                  user={user}
                  component={ClientsPerTown}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-statistics'}
                  user={user}
                  component={AgentStatistics}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/not-touched-in-4-weeks'}
                  user={user}
                  component={NotTouchedIn4Week}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agents-assigned-to-towns'}
                  user={user}
                  component={AgentAssignedToTowns}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agents-assign-by-ny-strategist'}
                  user={user}
                  component={AgentsAssignByStrategist}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/strategist-rating-report'}
                  user={user}
                  component={StrategistRatingReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/no-initial-call'}
                  user={user}
                  component={NoInitialCall}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/no-initial-tour'}
                  user={user}
                  component={NoInitialTour}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/no-agent-rating-report'}
                  user={user}
                  component={NoAgentRatingReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agent-rating-report'}
                  user={user}
                  component={AgentRatingReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/total-clients-received-per-month'}
                  user={user}
                  component={TotalClientsReceivedPerMonth}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/accounting-report'}
                  user={user}
                  component={AccountingReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/strategist-timesheet'}
                  user={user}
                  component={StrategistTimesheet}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/business-development'}
                  user={user}
                  component={BusinessDevelopment}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/jungler-users'}
                  user={user}
                  component={JunglerUsers}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/clients-list'}
                  user={user}
                  component={ClientsList}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/client-my-profile'}
                  user={user}
                  component={ClientProfile}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/stratagist-edit-client-profile'}
                  user={user}
                  component={StratagistEditClientProfile}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/add-strategy'}
                  user={user}
                  component={Strategy}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-strategy'}
                  user={user}
                  component={StrategyEdit}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/add-deal'}
                  user={user}
                  component={AddDeal}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/dashboard'}
                  user={user}
                  component={AdminDashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/home/new-clients'}
                  user={user}
                  component={Dashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/strategist-dashboard'}
                  user={user}
                  component={StrategistDashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/home/follow-up'}
                  user={user}
                  component={Dashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/home/active-clients'}
                  user={user}
                  component={Dashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/home/upcoming-appointments'}
                  user={user}
                  component={Dashboard}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/add-jungler-user'}
                  user={user}
                  component={AddJunglerUser}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/jungler-user-profile'}
                  user={user}
                  component={JunglerUsersProfile}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/agentcalendar'}
                  user={user}
                  component={AgentCalendar}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/calendarappointment'}
                  user={user}
                  component={CalendarAppointment}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/add-clients'}
                  user={user}
                  component={AddClients}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/clientscppointment'}
                  user={user}
                  component={ClientsAppointment}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/town-report'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/create-report'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/generate-report/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/edit-report/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/community/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/images/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/private-school/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/public-school/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/commute/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/google-places/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  path={'/edit-report/research/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />

                <PrivateRoute
                  path={'/edit-images/:townId'}
                  user={user}
                  component={TownReport}
                  {...this.props}
                />
                <PrivateRoute
                  exact
                  path={'/ai-towns'}
                  user={user}
                  component={AIView}
                  {...this.props}
                />

                {/********* mvp redirects *********/}
                <PrivateRoute exact path={'/administration/update-profile'} component={AdministrationUpdateProfile} {...this.props} />
                {/*<PrivateRoute*/}
                {/*    exact*/}
                {/*    path={'/administration/update-profile'}*/}
                {/*    component={props => (*/}
                {/*        <Redirect*/}
                {/*            to={`/strategist/clients`}*/}
                {/*            {...props}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    {...this.props}*/}
                {/*/>*/}

                {/*<PrivateRoute exact path={'/strategist/your-agents'} component={StrategistYourAgents} {...this.props} />*/}
                <PrivateRoute
                    exact
                    path={'/strategist/your-agents'}
                    component={props => (
                        <Redirect
                            to={`/strategist/clients`}
                            {...props}
                        />
                    )}
                    {...this.props}
                />

                <PrivateRoute exact path={'/strategist/agent-interviews'} component={StrategistAgentInterviews} {...this.props} />
                {/*<PrivateRoute*/}
                {/*    exact*/}
                {/*    path={'/strategist/agent-interviews'}*/}
                {/*    component={props => (*/}
                {/*        <Redirect*/}
                {/*            to={`/strategist/clients`}*/}
                {/*            {...props}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    {...this.props}*/}
                {/*/>*/}

                <PrivateRoute exact path={'/strategist/town-reports'} component={StrategistTownReports} {...this.props} />
                {/*<PrivateRoute*/}
                {/*    exact*/}
                {/*    path={'/strategist/town-reports'}*/}
                {/*    component={props => (*/}
                {/*        <Redirect*/}
                {/*            to={`/strategist/clients`}*/}
                {/*            {...props}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    {...this.props}*/}
                {/*/>*/}

                <PrivateRoute exact path={'/strategist/jungler-users'} component={StrategistJunglerUsers} {...this.props} />
                {/* <PrivateRoute
                    exact
                    path={'/strategist/jungler-users'}
                    component={props => (
                        <Redirect
                            to={`/strategist/clients`}
                            {...props}
                        />
                    )}
                    {...this.props}
                /> */}

                <PrivateRoute exact path={'/strategist/agent-appointments'} component={StrategistAgentAppointments} {...this.props} />
                
                {/*
                <PrivateRoute
                    exact
                    path={'/strategist/agent-appointments'}
                    component={props => (
                        <Redirect
                            to={`/strategist/clients`}
                            {...props}
                        />
                    )}
                    {...this.props}
                />
                */}

              </div>
            </Fragment>
          </UserContext.Provider>
        </Switch>
      </Router>
    );
  }
}
export default App;
