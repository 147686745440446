import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'

const fetchProspectApplicantsEpic = (action$) =>
  action$.ofType(types.FETCH_PROSPECT_APPLICANTS_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchInterviews(action.payload))
      .map((payload) => ({
        type: types.FETCH_PROSPECT_APPLICANTS_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_PROSPECT_APPLICANTS_LIST_ERROR,
          payload: { error },
        })
      )
})

const fetchRejectedApplicantsEpic = (action$) =>
  action$.ofType(types.FETCH_REJECTED_APPLICANTS_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchInterviews(action.payload))
      .map((payload) => ({
        type: types.FETCH_REJECTED_APPLICANTS_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_REJECTED_APPLICANTS_LIST_ERROR,
          payload: { error },
        })
      )
})

const approveApplicant = (action$) =>
  action$.ofType(types.APPLICANT_APPROVED).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.approveAgent(action.payload))
      .map((payload) => ({
        type: types.APPLICANT_APPROVED_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.APPLICANT_APPROVED_ERROR,
          payload: { error },
        })
      )
})

const rejectApplicant = (action$) =>
  action$.ofType(types.APPLICANT_REJECTED).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.rejectAgent(action.payload))
      .map((payload) => ({
        type: types.APPLICANT_REJECTED_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.APPLICANT_REJECTED_ERROR,
          payload: { error },
        })
      )
})

// combine agent interview epic
export const agentInterviewEpic = combineEpics(
  fetchProspectApplicantsEpic,
  fetchRejectedApplicantsEpic,
  approveApplicant,
  rejectApplicant
)
