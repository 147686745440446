import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/* 
    ////////////////////////
  /// client epics ///
////////////////////////
*/
// fetch client by id epic middleware
const fetchClientByIdEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_BY_ID).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientById(action.payload))
      .flatMap((payload) => [{
        type: types.FETCH_CLIENT_BY_ID_SUCCESS,
        payload,
      }, {
        type: types.FETCH_REIVEW_SUCCESS,
        payload,
      }])
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_BY_ID_ERROR,
          payload: { error },
        })
      )
  })
// fetch client strategist biz dev list epic middleware
const fetchClientStrategistBDListEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_STRATEGIST_BD_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientStrategistBDList(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_STRATEGIST_BD_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_STRATEGIST_BD_LIST_ERROR,
          payload: { error },
        })
      )
  })
// fetch strategist biz dev list epic middleware
const fetchStrategistBDListEpic = (action$) =>
  action$.ofType(types.FETCH_STRATEGIST_BD_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchStrategistBDList(action.payload))
      .map((payload) => ({
        type: types.FETCH_STRATEGIST_BD_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_STRATEGIST_BD_LIST_ERROR,
          payload: { error },
        })
      )
  })
// fetch strategist biz dev details epic middleware
const fetchStrategistDevBizEpic = (action$) =>
  action$.ofType(types.FETCH_STRATEGIST_DEV_BIZ_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchStrategistDevBiz(action.payload))
      .map((payload) => ({
        type: types.FETCH_STRATEGIST_DEV_BIZ_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_STRATEGIST_DEV_BIZ_LIST_ERROR,
          payload: { error },
        })
      )
  })
// create strategist biz dev list epic middleware
const createStrategistBDListEpic = (action$) =>
  action$.ofType(types.CREATE_CLIENT_STRATEGIST_BIZ_DEV).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createBizDevCredit(action.payload))
      .map((payload) => ({
        type: types.CREATE_CLIENT_STRATEGIST_BIZ_DEV_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_CLIENT_STRATEGIST_BIZ_DEV_ERROR,
          payload: { error },
        })
      )
  })
// add update client  epic
const addUpdateClientEpic = (action$) =>
  action$.ofType(types.ADD_UPDATE_CLIENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.addUpdateClient(action.payload))
      .map((payload) => ({
        type: types.ADD_UPDATE_CLIENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.ADD_UPDATE_CLIENT_ERROR,
          payload: { error },
        })
      )
  })
// create reffering client epic
const createReffferingClientEpic = (action$) =>
  action$.ofType(types.CREATE_REFFERING_CLIENT).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.createRefferClient(action.payload))
      .map((payload) => ({
        type: types.CREATE_REFFERING_CLIENT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.CREATE_REFFERING_CLIENT_ERROR,
          payload: { error },
        })
      )
  })
// get client list epic
const getClientListEpic = (action$) =>
  action$.ofType(types.FETCH_FILTER_CLIENT_DATA).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getClientList(action.payload))
      .map((payload) => ({
        type: types.FETCH_FILTER_CLIENT_DATA_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_FILTER_CLIENT_DATA_ERROR,
          payload: { error },
        })
      )
  })
  
// update basic client information
const editClientInformationEpic = (action$) =>
  action$.ofType(types.EDIT_CLIENT_INFORMATION).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.editClientInformation(action.payload))
      .map((payload) => ({
        type: types.EDIT_CLIENT_INFORMATION_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.EDIT_CLIENT_INFORMATION_ERROR,
          payload: { error },
        })
      )
  })
  
// combine client epics
export const clientsEpic = combineEpics(
  fetchClientByIdEpic,
  addUpdateClientEpic,
  getClientListEpic,
  fetchStrategistBDListEpic,
  fetchStrategistDevBizEpic,
  createReffferingClientEpic,
  createStrategistBDListEpic,
  editClientInformationEpic,
  fetchClientStrategistBDListEpic
)
