import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon, CloseIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

export default class ClosedClientsPerTownComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      tableClientData: [],
      search: '',
      sortByKey:'town_name',
      sortByType: 0,
      closedClientTableData: [],
      townName:'',
      openAgent:false,
      currentTownAgents:[]
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get("user");
    if (_user) {
      const data = {
        search: '',
        sortBy: {key: this.state.sortByKey, type: this.state.sortByType}
      }
      this.props.getClosedClientsData(data)
    }
  }

  componentDidUpdate(preProps, preState){
    const { closedClientPhase, closedClientData, clearStrategistPhase } = this.props
    if(closedClientPhase === "success"){
      clearStrategistPhase()
      let data = []
      const closedClientArr = closedClientData && closedClientData.data
      if(_.size(closedClientArr) > 0){
        closedClientArr && closedClientArr.map((clnt, i)=>{
          data.push({
            key: i,
            town_name: clnt.town_name,
            agents: clnt.agents,
            clients: clnt.clients,
            status: clnt.status,
            strategists: clnt.strategists,
            town_id: clnt.town_id,
            suburb: clnt.suburb,
            export_clients: clnt.clients.length > 0 ? clnt.clients.map(e => { return e.name}).join(" ,") : "",
            export_tag: clnt.status.length > 0 ? clnt.status.map(e => { return e}).join(" ,") : "",
            export_agents: clnt.agents.length > 0 ? clnt.agents.map(e => { return e.name}).join(" ,") : "",
            export_strategists: clnt.strategists.length > 0 ? clnt.strategists.map(e => { return e.name}).join(" ,") : ""
          })
        })
      }
      this.setState({ closedClientTableData: data, isLoading: false })
    }
    if(closedClientPhase === "error"){
      clearStrategistPhase()
      this.setState({ closedClientTableData: [], isLoading: false })
    }
  }

  openDatePickerRange() {
    this.setState({ datePickerModel: !this.state.datePickerModel })
  }

  closeCustomDate(e) {
    this.setState({ datePickerModel: false, fromDate: this.state.previousStartDate, endDate: this.state.previousEndDate })
  }

  handleSelect(range) {
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ fromDate: startDate, endDate: endDate })
  }

  submitCustomDate() {
    this.setState({ datePickerModel: false, previousStartDate: this.state.fromDate, previousEndDate: this.state.customEndDate, isLoading: true, selectedIndex: "custom" }, () => {


    })
  }

  onSearch(e) {
    this.setState({search:e.target.value })
    const data = {}
    data.sortBy = {key: this.state.sortByKey, type: this.state.sortByType}
    if (String(e.target.value).length > 2) {
       this.setState({ isLoading: true })
       data.search = e.target.value
       this.props.getClosedClientsData(data)
    } else if (e.target.value === '') {
       this.setState({ isLoading: true })
       this.props.getClosedClientsData(data)
    }
  }

  onOpenAgentModal(agent, type) {
    let agents = []
    if(type == 'agent'){
      agents = agent && agent.agents
    }
    if(type == 'client'){
      agents = agent && agent.clients
    }
    if(type == 'tag'){
      agents = agent && agent.status
    }
    if(type == 'strategist'){
      agents = agent && agent.strategists
    }
    if(agents.length > 1){
      this.setState({ townName: agent.town_name, openAgent: true, currentTownAgents: agents, type:type })
    }
  }

  onCloseAgentModal() {
    $( "body" ).removeClass( "overflow_visible" )
    this.setState({ townName: '', currentTownAgents: [], openAgent: false, type:'' })
  }

  changeLimit = limit => {
    // On limit change
  }
  
  render() {
    const { closedClientTableData } = this.state
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back">
                      <BackArrowIcon className="arrow-icon"/>Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                    </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Closed Clients Per Town</h2>
                      <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                        <ExcelSheet data={closedClientTableData} name="agnet_assigned_to_town_reprot">
                          <ExcelColumn label="Town" value="town_name" />
                          <ExcelColumn label="Client Name" value="export_clients" />
                          <ExcelColumn label="Tag" value="export_tag" />
                          <ExcelColumn label="Agent Name" value="export_agents" />
                          <ExcelColumn label="Strategist" value="export_strategists" />
                        </ExcelSheet>
                      </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search town, client, market and tag (*required 3 characters atleast)"
                      required
                    />
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
              {
                (_.size(closedClientTableData) > 0) ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <Table
                        className="table__fixed"
                        columns={[
                          {
                            title: 'Town',
                            width: 300,
                            dataIndex: 'town_name',
                            key: '1',
                            fixed: 'left',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.town_name.localeCompare(b.town_name)
                          },
                          {
                            title: 'Client Name',
                            width: 280,
                            dataIndex: 'clientName',
                            key: '2',
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.export_clients.localeCompare(b.export_clients),
                            render: (text, val) => {
                              const totalClient = val && val.clients
                              let clientNo = (totalClient.length-1) <= 0 ? '' : " (+"+(totalClient.length-1)+")"
                              return(
                                <span>
                                {
                                  clientNo !== '' && totalClient.length > 1  ? (
                                    <td className="multilines"><div className={(totalClient.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenAgentModal.bind(this,val, 'client')}>{( totalClient.length >= 1 ? (totalClient[0].name)+ clientNo : 'No Client' ) }</div></td>
                                  ):(
                                    clientNo == '' && totalClient.length == 1 ? (
                                      <td><div className='' >{(totalClient[0].name)}</div></td>
                                    ):(
                                      <td><div className='' >No Agent</div></td>
                                    )
                                  )
                                }
                                </span>
                              )
                            }
                          },
                          {
                            title: 'Tag',
                            dataIndex: 'tag',
                            key: '3',
                            width: 320,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.export_tag.localeCompare(b.export_tag),
                            render: (text, val) => {
                              const totalTag = val && val.status
                              let tagNo = (totalTag.length-1) <= 0 ? '' : " (+"+(totalTag.length-1)+")"
                              return(
                                <span>
                                {
                                  tagNo !== '' && totalTag.length > 1  ? (
                                    <td className="multilines"><div className={(totalTag.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenAgentModal.bind(this,val,'tag')}>{( totalTag.length >= 1 ? (totalTag[0])+ tagNo : 'No Tag' ) }</div></td>
                                  ):(
                                    tagNo == '' && totalTag.length == 1 ? (
                                      <td><div className=''>{(totalTag[0])}</div></td>
                                    ):(
                                      <td><div className='' >No Agent</div></td>
                                    )
                                  )
                                }
                                </span>
                              )
                            }
                          },
                          {
                            title: 'Agent Name',
                            dataIndex: 'agentName',
                            key: '4',
                            width: 300,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.export_agents.localeCompare(b.export_agents),
                            render: (text, val) => {
                              const totalAgent = val && val.agents
                              let agentNo = (totalAgent.length-1) <= 0 ? '' : " (+"+(totalAgent.length-1)+")"
                              return(
                                <span>
                                {
                                  agentNo !== '' && totalAgent.length > 1  ? (
                                    <td className="multilines"><div className={(totalAgent.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenAgentModal.bind(this,val,'agent')}>{( totalAgent.length >= 1 ? (totalAgent[0].name )+ agentNo : 'No Agent' ) }</div></td>
                                  ):(
                                    agentNo == '' && totalAgent.length == 1 ? (
                                      <td><div className=''>{(totalAgent[0].name)}</div></td>
                                    ):(
                                      <td><div className='' >No Agent</div></td>
                                    )
                                  )
                                }
                                </span>
                              )
                            }
                          },
                          {
                            title: 'Strategists',
                            dataIndex: 'strategistsName',
                            key: '5',
                            width: 300,
                            showSorterTooltip: false,
                            sortDirections: ['descend', 'ascend', 'descend'],
                            sorter: (a, b) => a.export_strategists.localeCompare(b.export_strategists),
                            render: (text, val) => {
                              const totalAgent = val && val.agents
                              const totalStrategist = val && val.strategists
                              let strategistNo = (totalStrategist.length-1) <= 0 ? '' : " (+"+(totalStrategist.length-1)+")"
                              return(
                                <span>
                                {
                                  strategistNo !== '' && totalStrategist.length > 1  ? (
                                    <td className="multilines"><div className={(totalStrategist.length > 1 ? "link cursor__pointer" : '')} onClick={this.onOpenAgentModal.bind(this,val, 'strategist')}>{( totalAgent.length >= 1 ? (totalStrategist[0].name )+ strategistNo : 'No Strategist' ) }</div></td>
                                  ):(
                                    strategistNo == '' && totalStrategist.length == 1 ? (
                                      <td><div className=''>{(totalStrategist[0].name)}</div></td>
                                    ):(
                                      <td><div className='' >No Agent</div></td>
                                    )
                                  )
                                }
                                </span>
                              )
                            }
                          },
                        ]}
                        dataSource={closedClientTableData}
                        pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                        scroll={{ y: 420 }}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="blank__table__state">Data Not Found</div>
              }
            </Spin>

            </div>
          </main>

          <ReactModal
            isOpen={this.state.openAgent}
            contentLabel="Report"
            portalClassName="react-modal"
            >
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                  {'Town - ' + this.state.townName+`'s`+' '+this.state.type+'(s)'}
                  </h5>
                  <button
                    type="button"
                    onClick={this.onCloseAgentModal.bind(this)}
                    className="btn react-modal-close"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="info__block">
                  {this.state.currentTownAgents && this.state.currentTownAgents.map((agent, index) => {
                    if(this.state.type == 'tag'){
                      return <p key={'agent-name-'+index}>{(agent)}</p>;
                    }else{
                      return <p key={'agent-name-'+index}>{(agent.name)}</p>;
                    }})
                  }
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </ReactModal>

        </div>
      </div>
    )
  }
}
