import { connect } from 'react-redux'

import NotTouchedByAgentIn4WeeksComponent from './component'
import { getFilterParams } from '../../../../../../store/strategistV2/dashboard/actions'
import { getNotTouchedIn4Weeks, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const NotTouchedByAgentIn4WeeksContainer = connect(
  // Map state to props
  (state) => ({
  	filterParams: state.strategistV2.dashboard.filterParams,
    notTouchedIn4weeksPhase: state.reportV2.notTouchedIn4weeksPhase,
    notTouchedIn4weeksData: state.reportV2.notTouchedIn4weeksData
  }),
  // Map actions to dispatch and props
  {
    getNotTouchedIn4Weeks,
    getFilterParams,
    clearReportV2Phase
  }
)(NotTouchedByAgentIn4WeeksComponent)

export default NotTouchedByAgentIn4WeeksContainer
