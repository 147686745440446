import { connect } from 'react-redux'

import BackgroundProfileComponent from './component'

const BackgroundProfileContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(BackgroundProfileComponent)

export default BackgroundProfileContainer
