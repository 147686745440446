import { connect } from 'react-redux'

import YourAgentsComponent from './component'

import { getFilterParams, yourAgentsFilter } from '../../../../store/strategistV2/dashboard/actions'
import {
	fetchYourAgentList,
	clearYourAgentPhase,
} from '../../../../store/strategistV2/youragent/actions'

const YourAgentsContainer = connect(
	// Map state to props
	(state) => ({
		agents: state.strategistV2.youragent.yourAgents,
		filterParams: state.strategistV2.dashboard.filterParams,
		fetchYourAgentPhase: state.strategistV2.youragent.fetchYourAgentPhase,
	}),
	// Map actions to dispatch and props
	{
		fetchYourAgentList,
		getFilterParams,
		clearYourAgentPhase,
		yourAgentsFilter
	}
)(YourAgentsComponent)

export default YourAgentsContainer
