/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react'
import { Spin } from 'antd'
import { get, map, filter } from 'lodash'
import moment from 'moment'
import './styles.scss'
import EditProspectusInformation from '../../Common/Prospectus'
import Loader from '../../../../../components/Loader'
import { SUCCESS } from '../../../../../constants/phase'
import { currencyFormatter } from '../../../../../utils/helper'

export default class DealInformationComponent extends PureComponent {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      modalIsOpen: false,
      editProspectModel: false,
    }
  }
  componentDidMount() {
    const clientId = this.props.match.params.id && this.props.match.params.id
    if (clientId) {
      this.setState({ isLoading: true }, () =>
        this.props.fetchClientDeal({ client_id: parseInt(clientId) })
      )
    }
  }

  componentDidUpdate(preProps, preState){
    const { match: { params: {id}}, location, fetchClientDeal } = this.props
    if(location.pathname !== preProps.location.pathname){
      fetchClientDeal({ client_id: parseInt(id) })
    }
  }

  static getDerivedStateFromProps(props, state) {
    let states = { ...state }
    if (props.fetchClientDealPhase === SUCCESS) {
      props.clearDealSectionPhase()
      states = { ...states, isLoading: false }
    }
    return states
  }
  openEditProspectModel = () => this.setState({ editProspectModel: !this.state.editProspectModel })

  closeEditProspectModal = () => this.setState({ editProspectModel: false })
  // redirect for edit selection
  handleEditDealSection = (deal) => {
    const clientId = this.props.match.params.id
    let clientDetails = get(this.props, 'clientDetail', {})
    this.props.history.push({
      pathname: '/strategist/deal',
      state: { dealId: deal.id, clientId, deal, clientDetails },
    })
  }
  handleAddDealSection = () => {
    const clientId = this.props.match.params.id
    let clientDetails = get(this.props, 'clientDetail', {})
   this.props.history.push({
      pathname: '/strategist/deal',
      state: { deal: {}, clientId, clientDetails },
    }) 
  }
  render() {
    const { isLoading } = this.state
    const { clientDeals } = this.props
    const deals = get(this.props, 'clientDeals', [])
    
    let dealData = _.sortBy(clientDeals, function(o) {
      return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
     }).reverse();

     let getTownName = []
     const townData = get(this.props, 'clientDetail.suburbs_towns', {})
     if(dealData && dealData[0] && townData){
      getTownName = filter(townData, function(town) {
        if (dealData[0].selectedTown === town.town_id) {
          return town.town_name
        }
      })
     };

    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__3">Deal Information</h5>
              {/* <p className="card-subtitle">{get(deals, '[0].agent_full_name', '')}</p> */}
            </div>
            {deals && deals.length > 0 ? <div className="deal__information__cta">
              <button
                className="btn btn__btn btn-dark mr__10 btn__edit__deal"
                onClick={() => this.handleEditDealSection(deals[0])}
              >
                Edit Deal
              </button>
            </div>:           
            <div className="deal__information__cta">
              <button
                className="btn btn__btn btn-dark mr__10 btn__edit__deal"
                onClick={this.handleAddDealSection}
              >
                Add Deal
              </button>
            </div>}
          </div>
            <Spin size="large" spinning={isLoading} indicator={<Loader />}>
              {dealData && dealData.length > 0 ? map(dealData, (deal, dealIndex) => (
                (dealIndex === 0) &&
                <div className="card-body card-body-sm" key={dealIndex}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="deal__progress">
                      <ul className="deal__progress__item">
                        <li className="active">
                          <h2>Accepted Offer</h2>
                          <h4>
                            {deal.accepted_offer_date
                              ? moment.utc(deal.accepted_offer_date).format('MM/DD/YYYY')
                              : ''}
                          </h4>
                        </li>
                        <li
                          className={
                            deal.deal_status === 'Signed Contract' || deal.deal_status === 'Closed'
                              ? 'active'
                              : ''
                          }
                        >
                          <h2>{deal.deal_type === 0 ? 'Signed Contract' : 'Signed Lease'} </h2>
                          <h4>
                            {deal.date_of_signed_contract
                              ? moment.utc(deal.date_of_signed_contract).format('MM/DD/YYYY')
                              : ''}
                          </h4>
                        </li>
                        <li
                          className={
                            deal.deal_status === 'Closed' || deal.deal_status === 'Closed'
                              ? 'active'
                              : ''
                          }
                        >
                          <h2>{deal.deal_type === 0 ? 'Closed' : 'Lease Start Date'}</h2>
                          <h4>
                            {deal.deal_type === 0 && deal.expected_close_date
                              ? moment.utc(deal.expected_close_date).format('MM/DD/YYYY')
                              : ''}
                            {deal.deal_type === 1 && deal.lease_start_date
                              ? moment.utc(deal.lease_start_date).format('MM/DD/YYYY')
                              : ''}
                          </h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="info__block">
                      <label>Agent Name</label>
                      <p>{deal.agent_full_name ? deal.agent_full_name : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>{deal.deal_type === 0 ? 'Sales Price' : 'Monthly Rental Price'}</label>
                      <p>{deal.amount ? currencyFormatter.format(deal.amount) : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>
                        {deal.deal_type === 0
                          ? 'Buy Side Commission (%)'
                          : "Tenant's Agent Commission (%)"}
                      </label>
                      <p>{deal.buy_side_commission ? `${deal.buy_side_commission}%` : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>Misc. Fee</label>
                      <p>{deal.sj_comission ? currencyFormatter.format(deal.sj_comission) : '$0'}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="info__block">
                      <label>Created Date</label>
                      <p>{deal.created ? moment(deal.created).format('MM/DD/YYYY') : ''}</p>
                    </div>
                    <div className="info__block">
                      <label>Date of Accepted Offer </label>
                      <p>
                        {deal.accepted_offer_date
                          ? moment.utc(deal.accepted_offer_date).format('MM/DD/YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                    {deal.deal_type === 0 ? (
                      <div className="info__block">
                        <label>Expected/Actual Closing Date</label>
                        <p>
                          {deal.expected_close_date
                            ? moment.utc(deal.expected_close_date).format('MM/DD/YYYY')
                            : 'N/A'}
                        </p>
                      </div>
                    ) : (
                      <div className="info__block">
                        <label>Lease Start Date</label>
                        <p>
                          {deal.lease_start_date
                            ? moment.utc(deal.lease_start_date).format('MM/DD/YYYY')
                            : 'N/A'}
                        </p>
                      </div>
                    )}
                    <div className="info__block">
                      <label>
                        {deal.deal_type === 0 ? 'Date of Signed Contract' : 'Date of Signed Lease'}
                      </label>
                      <p>
                        {deal.date_of_signed_contract
                          ? moment.utc(deal.date_of_signed_contract).format('MM/DD/YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info__block">
                      <label>Address</label>
                      <p>{deal.address ? deal.address : ''}</p>
                    </div>
                    <div className="info__block">
                      <label>Town</label>
                      <p>{getTownName && getTownName[0] && getTownName[0].town_name ? getTownName[0].town_name : 'N/A'}</p>
                    </div>
                    <div className="info__block">
                      <label>School District</label>
                      <p>{deal.seletedSchool ? deal.seletedSchool : 'N/A'}</p>
                    </div>
                    {
                      deal.deal_type === 1 &&
                      <div className="info__block">
                        <label>Lease Term</label>
                        <p>{deal.lease_term ? deal.lease_term : 'N/A'}</p>
                      </div>
                    }
                  </div>
                  <div className="col-md-12">
                    <div className="info__block">
                      <label>Notes</label>
                      <p>{deal.agent_notes ? deal.agent_notes : 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </div>
                
                )) : (
                <div className="card-body card-body-sm blank__state__text">
                  This is where you will see a Deal that has been created when there is an accepted
                  offer. There are No Deals created for this client yet. Click on "Add Deal" to create
                  one!
                </div>
              )}
          </Spin>
        </div>
        {/*  <div className="mb__20">
          <button
            className="btn btn__btn btn__lg btn-dark w__100"
            onClick={this.openEditProspectModel}
          >
            Add Another Prospectus
          </button>
        </div> */}
        <EditProspectusInformation
          {...this.props}
          openEditProspectModel={this.openEditProspectModel}
          editProspectModel={this.state.editProspectModel}
          closeEditProspectModal={this.closeEditProspectModal}
        />
      </div>
    )
  }
}
