import { connect } from 'react-redux'
import {fetchUser} from '../../store/user/duck'
import { getFilterParams } from '../../store/dashboard/duck'
import AIForm from './component'
const AI = connect(
  // Map state to props
  (state) => ({
    profilePhase: state.user.profilePhase,
    user: state.user.users,
    profileError: state.user.profileError,
    filterParams: state.dashboard.filterParams, 
  }),
  // Map actions to props
  {
    fetchUser,
    getFilterParams
  }
)(AIForm)
export default AI
