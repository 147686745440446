import React, { useEffect } from 'react'
import ReactModal from 'react-modal'

import { SUCCESS } from '../../../../../../../constants/phase'
import { CloseIcon } from '../../../../../../../components/icons'

export default function DeleteNotes({
  openModel,
  noteId,
  closeDeleteNotesModal,
  ...props
}) {
  // submit or create notes
  const handelDeleteSubmission = () => {
    const clientId = props.match.params.id
    if (noteId && clientId) {
      props.deleteInteralNote({ noteId, clientId })
      closeDeleteNotesModal()
    }
  }
  // cwrp on creating success notes
  useEffect(() => {
    if (props.deleteInteralNotePhase === SUCCESS) {
      const clientId = props.match.params.id
      props.getInteralNotes({ clientId })
      props.clearInteralNotePhase()
    }
  }, [props.deleteInteralNotePhase])

  return (
    <ReactModal
      isOpen={openModel}
      contentLabel="Delete User"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-body react-modal-body-sm">
          <button
            type="button"
            className="btn react-modal-close react-modal-close-sm"
            onClick={closeDeleteNotesModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title mb__13">Are you sure?</h5>
          <p className="react-modal-subtitle mb__3">
            This will remove the note information and you will need to re enter
            if needed.
          </p>
          <div className="text-right">
            <button
              onClick={handelDeleteSubmission}
              className="btn btn__btn btn-dark w__150"
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
