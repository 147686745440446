import React, { PureComponent /*, PropTypes*/ } from 'react';
import { Link } from 'react-router-dom';
import TopNavigation from '../../../../components/TopNavigation/container';
import Dropdown from './Other/Dropdown';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import { Spin } from 'antd';
import Loader from '../../../../components/Loader';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  occupationList,
  landSizes,
  budget,
  fancyLaidBack,
  homeType,
  progressiveConservative,
  sportyArtsy,
  goodForFinding,
  willingToCommute,
  transportation
} from '../Towns/townKeys';
import _ from 'lodash';

const HOSTNAME = process.env.API_HOSTNAME;
const cookies = new Cookies();

let _user = '';

const arTest = [
  {
  label: 'Diverse',
  townAns: '',
      townKey: 'diversity',
  clientKey: 'diversity',
  isArray: false,
  clientAns: ''
},
{
  label: 'Taxes',
  townAns: '',
          townKey: 'taxes',
  clientKey: 'taxes',
  isArray: false,
  clientAns: ''
},
{
  label: 'Popular Occupations',
  townAns: [],
  isArray: true,
          townKey: [
{ label: 'White Collar', value: 'white_collar' },
{ label: 'Blue Collar', value: 'blue_collar' },
{ label: 'Finance', value: 'finance_legal' },

// new
{ label: 'Legal', value: 'legal' },
{ label: 'Medical', value: 'medical' },
{ label: 'Entrepreneur', value: 'entrepreneur_self_employed' },
{ label: 'Tech', value: 'tech' },
{ label: 'Creative', value: 'creative' },

// new
{ label: 'Sales/Marketing', value: 'sales_marketing' },
{ label: 'Retail', value: 'retail' },
{ label: 'Education', value: 'education' },
{ label: 'Government', value: 'government' },
{ label: 'Nonprofit', value: 'nonprofit' },
{ label: 'Nice mix of all', value: 'nice_mix_of_all' }
],
  clientKey: 'occupation',
  clientAns: []
},
{
  label: 'Land Size(s)',
  townAns: [],
          townKey: [
{ label: 'Up to ¼ acre', value: 'land_up_to_1_4_acre' },
{ label: 'Up to ½ acre', value: 'land_up_to_1_2_acre' },
{ label: 'Up to 1 acre', value: 'land_up_to_1_acre' },
{ label: '1+acres', value: 'land_up_to_1_plus' },
{ label: 'Large acreage', value: 'large_acreage' }
],
  clientKey: 'lands',
  isArray: true,
  clientAns: []
},
{
  label: 'Home Type',
  townAns: [],
          townKey: [
{ label: 'Single family home', value: 'single_family' },
{ label: 'Townhome', value: 'townhome' },
{ label: 'Condo', value: 'condo' }
],
  clientKey: 'homeType',
  isArray: true,
  clientAns: []
},
{
  label: 'Budget',
  townAns: [],
          townKey: [
{ label: 'Price Under 500K', value: 'price_under_500k' },
{ label: 'Price 500k to 1 mil', value: 'price_500k_1mill' },
{ label: 'Price 1 to 1.5 mil', value: 'price_1_1_5mill' },
{ label: 'Price 1.5 to 2 mil', value: 'price_1_5_2mill' },
{ label: 'Price 2 to 3 mil', value: 'price_2_3mill' },
{ label: ' Price 3 mil Plus', value: 'price_3mill_plus' }
],
  clientKey: 'prices',
  isArray: true,
  clientAns: []
},
{
  label: 'Feel of the town',
  townAns: '',
  isArray: false,
          townKey: 'urban_suburban',
  clientKey: 'urban_suburban',
  clientAns: ''
},
// {
//   label: 'Fancy or laid back?',
//   townAns: [],
//           townKey: [
// { label: 'Fancy', value: 'Fancy' },
// { label: 'Laid back', value: 'laid_back' }
// ],
//   clientKey: 'fancyLaidBack',
//   isArray: true,
//   clientAns: []
// },
{
  label: 'Type A folks?',
  townAns: '',
          townKey: 'type_a',
  clientKey: 'type_a',
  isArray: false,
  clientAns: ''
},
// {
//   label: 'Walkable Neighborhood?',
//   townAns: [],
//   isArray: false,
//           townKey: 'walkable',
//   clientKey: 'walkable',
//   clientAns: []
// },
{
  label: 'Mom Preference',
  townKey: 'sahm_wohm_mix',
  clientKey: 'sahm_wohm_mix',
  townAns: '',
  isArray: false,
  clientAns: ''
},
// {
//   label: 'Progressive v conservative',
//   isArray: true,
//           townKey: [
// { label: 'Progressive', value: 'progressive' },
// { label: 'Conservative', value: 'conservative' }
// ],
//   clientKey: 'diversity',
//   townAns: [],
//   clientAns: []
// },
// {
//   label: 'Sporty/Artsy',
//   townAns: [],
//   isArray: true,
//           townKey: [
// { label: 'Sporty', value: 'town_personality_sporty' },
// { label: 'Creative/Artsy', value: 'town_personality_artsy' }
// ],
//   clientKey: 'diversity',
//   clientAns: []
// },
{
  label: 'Good for finding',
  townAns: [],
  isArray: true,
          townKey: [
{ label: 'Live in Nanny, non driver', value: 'childcare_live_in_non_driver' },
{ label: 'Live in Nanny, driver', value: 'childcare_live_in_driver' },
{ label: 'Live out Nanny, driver', value: 'childcare_live_out_driver' },
{
  label: 'Live out Nanny, non driver',
  value: 'childcare_live_out_non_driver'
},
{ label: 'Part time sitter', value: 'childcare_pt_sitter' },
{ label: 'Au pair', value: 'childcare_au_pair' },
{ label: 'Family', value: 'childcare_none_parents' },
{ label: 'None', value: 'none' },
{ label: 'Day Care', value: 'childcare_daycare' }
],
  clientKey: 'plans',
  clientAns: []
}
]


import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component';
import {
  SortUpIcon,
  TownCheatSheetColorIcon,
  FilterIcon,
  CaretRightIcon,
  DownCaretIcon,
  CloseIcon
} from '../../../../components/icons';
import './styles.scss';
import isEmpty from 'lodash/isEmpty';
import { logoutInvalidRequest } from '../../../../utils/helper';

export default class TownCheatSheetComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRefferringClient: [],
      searchTerm: '',
      isLoading: false,
      arTestFinal: [],
      firstOptions: [],
      aiData: {},
      townList: [],
      townCharacteristics: [],
      publicData: [],
      otherResearch: [],
      noteResearch: [],
      town: {},
      selectedClient: {},
      clients: [],
      filterRefferClientsOption: [],
      townListOptions: [],
      answers: []
    };
  }
  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    _user = cookies.get('user');
    this.props.getFilterParams();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const obj = {};

    if (get(nextProps, 'getSchoolsPhase', false) === 'success') {
      nextProps.clearTownPhase();
      obj.publicData = get(nextProps, 'schoolData.data');
    }

    if (get(nextProps, 'getResearchPhase') === 'success') {
      obj.otherResearch = filter(
        get(nextProps, 'getResearchData.research'),
        i => {
          return i.insight_type !== 'note';
        }
      );
      obj.noteResearch = filter(get(nextProps, 'getResearchData.research'), {
        insight_type: 'note'
      });
    }

    if (get(nextProps, 'townClientsPhase', false) === 'success') {
      obj.clients = get(nextProps, 'townClients.data');
    }

    if (nextProps.filterParamsPhase === 'success') {
      nextProps.clearFilterPhase();
      const optionsAr = [];
      const restOptions = [];
      obj.townList = get(nextProps, 'filterParams.data.towns', []);
      obj.townListMain = get(nextProps, 'filterParams.data.towns', []);

      if (!isEmpty(get(nextProps, 'filterParams.data.towns', []))) {
        const setOptions = get(nextProps, 'filterParams.data.towns', []).map(
          (town, i) => {
            const o = {
              label: town.name,
              value: String(town.id),
              other: town
            };
            restOptions.push(o);

            if (i <= 4) {
              optionsAr.push(o);
            }
          }
        );

        Promise.all(setOptions);
        obj.townListOptions = optionsAr;
        obj.townListOptionsMain = optionsAr;
        obj.firstOptions = restOptions;
      }
    }

    if (get(nextProps, 'fetchTownByIdPhase') === 'success') {
      nextProps.clearTownPhase();
      obj.town = get(nextProps, 'fetchTownByIdData.town[0]', {});
      obj.townName = get(nextProps, 'fetchTownByIdData.town[0].name', {});
    }

    return obj;
  }

  /*
  fetchAItowns(user, townId) {
    const data = { filters: [], user_id: user.id, townId: parseInt(townId) };
    axios({
      method: 'POST',
      url: `${HOSTNAME }/users/fetch_ai_towns`,
      data
    }).then(async resData => {
      if (resData.data.data) {
        const mainData = get(resData, 'data.data[0]', []);
        const newtest = arTest.map((a, i) => {
          const tval = get(mainData, a.townKey)

          a.townAns = (tval == -1 && '-') || (tval == 1 && 'Yes') || (tval == 0 && 'No') || tval

          if (a.isArray) {
            const aor = []
            // ar stuff
            a.townKey.map((t, o) => {
              if (get(mainData, t.value)) {
                aor.push(t.label)
              }
            })

            a.townAns = aor
          }
        })

        await Promise.all(newtest)
        this.setState({ aiData: mainData, townCharacteristics: arTest, isDataLoading: false });
      }
    }).catch(logoutInvalidRequest)
  }
  */
 
  filterRefferClientsOption = searchTerm => {
    if (searchTerm.length !== 0) {
      const filtered = filter(
        this.state.firstOptions,
        search =>
          search.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      );

      this.setState({
        isLoadingRefferingClient: true,
        searchTerm,
        townListOptions: filtered
      });
      this.forceUpdate();
    }
    if (searchTerm.length === 0) {
      this.setState({
        searchTerm: '',
        townListOptions: this.state.townListOptionsMain
      });
    }
  };

  handleClientSelect = options => {
    const town_id = parseInt(get(options, `[${options.length - 1}]value`));
    this.props.fetchTownById(town_id);
    // this.fetchAItowns(_user, town_id);
    this.props.fetchSchools({ townId: town_id, type: 'public' });
    this.props.communityDataByTownId({
      town_id
    });

    this.props.getResearch({
      town_id,
      user_id: _user.id
    });

    this.props.getClientsByTown(town_id);

    this.setState({
      isLoading: true,
      selectedRefferringClient: options[options.length - 1],
      town: isEmpty(options) ? {} : this.state.town,
      townListOptions: this.state.townListOptionsMain
    });
  };

  componentDidUpdate = async (prevProps) => {
    const np = this.props;
    const {
      match: {
        params: { townId }
      },
      communityDataByTownId
    } = prevProps;
    if (np.communityPhase !== prevProps.communityPhase) {
      if (np.communityData && np.communityData.data) {
        const data = this.parseQuickFacts(np.communityData.data);
        // this.attomChange({ target: { value: get(data, 'attom_geo_id') } });
        this.setState({
          quickFacts: data,
          isLoading: false,
          population: data.population,
          avgAge: data.avg_age,
          avgHomeSale: data.avg_home_sale_price,
          avgHouseHold: data.avg_household_income,
          avgTaxRate: data.tax_avg_percentage,
          quickRacial: data.racial,
          edit_avg_tax_rate: data.tax_avg_percentage
        });
      }
    }
  }

  get_attom_field_value = (data, field_label, geo) => {
    let value = '';
    const filtered = data.filter(o => {
      return o.field_label === field_label;
    });
    forEach(filtered, val => {
      if (field_label === 'geocodinglatitude') {
        value = val.lat;
      } else if (field_label === 'geocodinglongitude') {
        value = val.lng;
      } else if (geo === 'geo') {
        value = val.attom_geo_id;
      } else {
        value = val.field_value;
      }
    });
    return value;
  };

  parseQuickFacts(quick_facts) {
    // eslint-disable-line camelcase
    const obj = {};
    obj.population = this.number_format(
      this.get_attom_field_value(quick_facts, 'poppy_10')
    );

    obj.attom_geo_id = this.get_attom_field_value(
      quick_facts,
      'poppy_10',
      'geo'
    );
    return obj;
  }

  number_format(x) {
    // eslint-disable-line camelcase
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  clientQuestionClick(clientId) {
    const { aiData } = this.state
    const client = filter(this.state.clients, { id: parseInt(clientId) });
    this.setState({ isLoading: true, selectedClient: client[0] });
    axios({
      method: 'POST',
      url: `${SUBURBANJUNGLE_API_HOST}/users/${clientId}/questions`,
      data: {
        user_id: clientId
      }
    }).then(async res => {
      let questionData = [];
      if (res.data.data) {
        if (res.data.data.question_answer_json !== '') {
          questionData = JSON.parse(new Buffer(res.data.data.question_answer_json, 'base64').toString('utf8'))
          let isChildren = true;
          if (
            questionData &&
            questionData[0] &&
            (parseInt(questionData[0].key) === 0 ||
              parseInt(questionData[0].key) === -2)
          ) {
            isChildren = false;
          }
          // questionData &&
          if (!isEmpty(questionData)) {
            const sortQ = questionData.map((q, key) => {
              // q.question = config.questions[key].q
              // q.hide = config.questions[key].hide ? config.questions[key].hide : false
              // Depends on child options and matched with index ie 5th index will be 6th number question
              if (
                key === 5 ||
                key === 16 ||
                key === 18 ||
                key === 19 ||
                key === 20 ||
                key === 21 ||
                key === 22 ||
                key === 25 ||
                key === 35
              ) {
                q.isChildren = isChildren;
              }

              if (key === 0) {
                // First question
                if (q.key && parseInt(q.key) > 0) {
                  const child = [];
                  for (let i = 0; i < parseInt(q.key); i++) {
                    child.push({
                      name: q.details[`name${i + 1}`],
                      age: q.details[`age${i + 1}`],
                      school: q.details[`school${i + 1}`]
                    });
                  }
                  q.child = child;
                } else {
                  q.child = [];
                }
              }
              if (q.occupation) {
                if (q.occupation.all == 1) {
                  q.occupation =
                    ['White collar', 'Blue collar', 'Finance', 'Medical', 'Entrepreneurial', 'Tech', 'Creative', 'Legal', 'Nice mix of all'];
                } else if (q.occupation.unknown == 1) {
                  q.occupation = "Doesn't matter";
                } else {
                  const arr = [];
                  if (q.occupation.white_collar == 1) {
                    arr.push('White collar');
                  }
                  if (q.occupation.blue_collar == 1) {
                    arr.push('Blue collar');
                  }
                  if (q.occupation.finance_legal == 1) {
                    arr.push('Finance');
                  }
                  if (q.occupation.medical == 1) {
                    arr.push('Medical');
                  }
                  if (q.occupation.entrepreneur_self_employed == 1) {
                    arr.push('Entrepreneurial');
                  }
                  if (q.occupation.tech == 1) {
                    arr.push('Tech');
                  }
                  if (q.occupation.creative == 1) {
                    arr.push('Creative');
                  }
                  if (q.occupation.legal == 1) {
                    arr.push('Legal');
                  }
                  q.occupation = arr;
                }
              }
              if (q.typeOfTown) {
                const arr = [];
                if (q.typeOfTown.town_personality_sporty == 1) {
                  arr.push('Sporty');
                }
                if (q.typeOfTown.town_personality_artsy == 1) {
                  arr.push('Creative/Artsy');
                }
                if (arr.length > 0) {
                  q.typeOfTown = arr
                } else {
                  q.typeOfTown = '-';
                }
              }
              if (q.taxes) {
                const arr = [];
                if (q.taxes.low == 1) {
                  arr.push('Low');
                }
                if (q.taxes.high == 1) {
                  arr.push('High');
                }
                if (q.taxes.medium == 1) {
                  arr.push('Medium');
                }
                if (arr.length > 0) {
                  q.taxes = arr
                } else {
                  q.taxes = '-';
                }
              }
              if (q.schools) {
                const arr = [];
                if (q.schools.high == 1) {
                  arr.push('Super Highly Ranked …');
                }
                if (q.schools.middle == 1) {
                  arr.push('Pretty good');
                }
                if (q.schools.low == 1) {
                  arr.push(
                    'Ok to not be highly ranked as long as my kids get a good education'
                  );
                }
                if (arr.length > 0) {
                  q.schools = arr;
                } else {
                  q.schools = '-';
                }
              }
              if (q.kindergarten) {
                const arr = [];
                if (q.kindergarten.full == 1) {
                  arr.push('Full day');
                }
                if (q.kindergarten.half == 1) {
                  arr.push('½ day');
                }
                if (q.kindergarten.none == 1) {
                  arr.push('Either');
                }
                if (arr.length > 0) {
                  q.kindergarten = arr;
                } else {
                  q.kindergarten = '-';
                }
              }
              if (q.busing) {
                const arr = [];
                if (q.busing.yes == 1) {
                  arr.push('Yes');
                }
                if (q.busing.no == 1) {
                  arr.push('No');
                }
                if (q.busing.both == 1) {
                  arr.push("Doesn't matter");
                }
                if (arr.length > 0) {
                  q.busing = arr;
                } else {
                  q.busing = '';
                }
              }
              if (q.universal_pre_k) {
                const arr = [];
                if (q.universal_pre_k.yes == 1) {
                  arr.push('Yes');
                }
                if (q.universal_pre_k.no == 1) {
                  arr.push('No');
                }
                if (q.universal_pre_k.both == 1) {
                  arr.push("Doesn't matter");
                }
                if (arr.length > 0) {
                  q.universal_pre_k = arr;
                } else {
                  q.universal_pre_k = '-';
                }
              }
              if (q.transportation) {
                const arr = [];
                if (q.transportation.trans_mode_train == 1) {
                  arr.push('Train');
                }
                if (q.transportation.trans_mode_bus == 1) {
                  arr.push('Bus');
                }
                if (q.transportation.trans_mode_car == 1) {
                  arr.push('Car');
                }
                if (q.transportation.trans_mode_walk == 1) {
                  arr.push('Walk');
                }
                if (q.transportation.trans_mode_bike == 1) {
                  arr.push('Bike');
                }
                if (q.transportation.trans_mode_ferry == 1) {
                  arr.push('Ferry');
                }
                if (arr.length > 0) {
                  q.transportation = arr;
                } else {
                  q.transportation = '-';
                }
              }
              if (q.plans) {
                const arr = [];
                if (q.plans.childcare_live_in_non_driver == 1) {
                  arr.push('Live in Nanny, non driver');
                }
                if (q.plans.childcare_live_in_driver == 1) {
                  arr.push('Live in Nanny, driver');
                }
                if (q.plans.childcare_live_out_driver == 1) {
                  arr.push('Live out Nanny, driver');
                }
                if (q.plans.childcare_live_out_non_driver == 1) {
                  arr.push('Live out Nanny, non driver');
                }
                if (q.plans.childcare_au_pair == 1) {
                  arr.push('Au pair');
                }
                if (q.plans.childcare_none_parents == 1) {
                  arr.push('Family');
                }
                if (q.plans.childcare_daycare == 1) {
                  arr.push('Day care');
                }
                if (q.plans.childcare_pt_sitter == 1) {
                  arr.push('Part time sitter');
                }
                if (q.plans.none == 1) {
                  arr.push('None');
                }
                if (arr.length > 0) {
                  q.plans = arr;
                } else {
                  q.plans = '-';
                }
              }
              if (q.lands) {
                const arr = [];
                if (q.lands.land_up_to_1_4_acre == 1) {
                  arr.push('Up to ¼ acre');
                }
                if (q.lands.land_up_to_1_2_acre == 1) {
                  arr.push('Up to ½ acre');
                }
                if (q.lands.land_up_to_1_acre == 1) {
                  arr.push('Up to 1 acre');
                }
                if (q.lands.land_up_to_1_plus == 1) {
                  arr.push('1+acres');
                }
                if (q.lands.large_acreage == 1) {
                  arr.push('Large acreage');
                }
                if (arr.length > 0) {
                  q.lands = arr;
                } else {
                  q.lands = '-';
                }
              }
              if (q.homeType) {
                const arr = [];
                if (q.homeType.townhome == 1) {
                  arr.push('Townhome');
                }
                if (q.homeType.condo == 1) {
                  arr.push('Condo');
                }
                if (q.homeType.single_family == 1) {
                  arr.push('Single family home');
                }
                if (arr.length > 0) {
                  q.homeType = arr
                } else {
                  q.homeType = '-';
                }
              }
              if (q.activities) {
                const arr = [];
                if (q.activities.activities_ski == 1) {
                  arr.push('Skiing');
                }
                if (q.activities.activities_squash == 1) {
                  arr.push('Squash');
                }
                if (q.activities.activities_tennis == 1) {
                  arr.push('Tennis');
                }
                if (q.activities.activities_hockey == 1) {
                  arr.push('Hockey');
                }
                if (q.activities.activities_lacrosse == 1) {
                  arr.push('Lacrosse');
                }
                if (q.activities.activities_dance == 1) {
                  arr.push('Dance');
                }
                if (q.activities.activities_theatre == 1) {
                  arr.push('Theatre');
                }
                if (q.activities.activities_football == 1) {
                  arr.push('Football');
                }
                if (q.activities.activities_soccer == 1) {
                  arr.push('Soccer');
                }
                if (q.activities.activities_writing == 1) {
                  arr.push('Writing');
                }
                if (arr.length > 0) {
                  q.activities = arr;
                } else {
                  q.activities = '-';
                }
              }
              if (q.prices) {
                let prices = '';
                if (q.prices.none == 1) {
                  prices = '-';
                }
                if (q.prices.price_1_1_5mill == 1) {
                  prices = '$1,000,000 - $1,200,000';
                }
                if (q.prices.price_1_5_2mill == 1) {
                  prices = '$1,500,000 - $2,000,000';
                }
                if (q.prices.price_2_3mill == 1) {
                  prices = '$2,000,000 - $2,500,000';
                }
                if (q.prices.price_3mill_plus == 1) {
                  prices = '$3,000,000 - $3,500,000';
                }
                if (q.prices.price_500k_1mill == 1) {
                  prices = '$500,000 - $600,000';
                }
                if (q.prices.price_under_500k == 1) {
                  prices = 'Under $400,000';
                }
                q.prices = prices;
              }
              if (q.commute_to) {
                let commute_to_simple = '';
                map(q.commute_to, (value, key) => {
                  commute_to_simple +=
                    `${`<b>${value.suburb}</b>` +
                    ': '}${
                    value.option
                    } ${
                    value.otherValue
                    }</br>`;
                });
                q.commute_to_simple = `A. ${ commute_to_simple}`;
              } else {
                q.commute_to_simple = 'A. ';
              }
              if (q.places_want) {

                let places = '';
                const optionPWA = [];
                map(q.places_want, (value, key) => {
                  let t = '';
                  let q = '';
                  let a = [];
                  places += `<b>${value.suburb}: </b>`;
                  map(value.options, (v, k) => {
                    const otherkey = `other_${k}`;
                    if (v == 1) {
                      const _k = k.replace(/_/g, ' ');
                      t = _k;
                      if (
                        value.options[otherkey] &&
                        value.options[otherkey].length &&
                        k !== 'other_I_have_no_idea!'
                      ) {
                        const p = [];
                        map(value.options[otherkey], (opt) => {
                          p.push(opt.label);
                        });
                        if (p.length) {
                          q =
                            "</br><b>If you want to be more specific but you don't have to be...</b></br>";
                          a = p;
                        }
                        const obj = {
                          t,
                          q,
                          a
                        };
                        optionPWA.push(obj);
                      } else {
                        const obj = {
                          t: _k,
                          q: '',
                          a: []
                        };
                        optionPWA.push(obj);
                      }
                    }
                  });
                });

                q.places_want_simple = `A. ${ places}`;
                q.optionPWA = optionPWA;
              }
              if (q.places_donot) {
                let places = '';
                const optionArr = [];
                map(q.places_donot, (value, key) => {
                  let t = '';
                  let q = '';
                  let a = [];
                  places += `${value.suburb }:`;
                  map(value.options, (v, k) => {
                    const otherkey = `other_${k}`;
                    if (v == 1) {
                      const _k = k.replace(/_/g, ' ');
                      t = _k;
                      if (
                        value.options[otherkey] &&
                        value.options[otherkey].length &&
                        k !== 'other_I_have_no_idea!'
                      ) {
                        const p = [];
                        map(value.options[otherkey], (opt) => {
                          p.push(opt.label);
                        });
                        if (p.length) {
                          q =
                            "</br><b>If you want to be more specific but you don't have to be...</b></br>";
                          a = p;
                        }
                        const obj = {
                          t,
                          q,
                          a
                        };
                        optionArr.push(obj);
                      } else {
                        const obj = {
                          t: _k,
                          q: '',
                          a: []
                        };
                        optionArr.push(obj);
                      }
                    }
                  });
                });
                q.places_donot_simple = `A. ${ places}`;
                q.optionArr = optionArr;
              }

              if (key === 2 && q.details) {
                const details = q.details;
                q.transportation = '';
                if (details && details.transportation) {
                  const arr = [];
                  if (details.transportation.trans_mode_train == 1) {
                    arr.push('Train');
                  }
                  if (details.transportation.trans_mode_bus == 1) {
                    arr.push('Bus');
                  }
                  if (details.transportation.trans_mode_car == 1) {
                    arr.push('Car');
                  }
                  if (details.transportation.trans_mode_walk == 1) {
                    arr.push('Walk');
                  }
                  if (details.transportation.trans_mode_bike == 1) {
                    arr.push('Bike');
                  }
                  if (details.transportation.trans_mode_ferry == 1) {
                    arr.push('Ferry');
                  }
                  if (arr.length > 0) {
                    q.transportation = arr;
                  } else {
                    q.transportation = '';
                  }
                }
                q.plans = '';
                if (details && details.plans) {
                  const arr = [];
                  if (details.plans.childcare_live_in_non_driver == 1) {
                    arr.push('Live in Nanny, non driver');
                  }
                  if (details.plans.childcare_live_in_driver == 1) {
                    arr.push('Live in Nanny, driver');
                  }
                  if (details.plans.childcare_live_out_driver == 1) {
                    arr.push('Live out Nanny, driver');
                  }
                  if (details.plans.childcare_live_out_non_driver == 1) {
                    arr.push('Live out Nanny, non driver');
                  }
                  if (details.plans.childcare_au_pair == 1) {
                    arr.push('Au pair');
                  }
                  if (details.plans.childcare_none_parents == 1) {
                    arr.push('Family');
                  }
                  if (details.plans.childcare_daycare == 1) {
                    arr.push('Day care');
                  }
                  if (details.plans.childcare_pt_sitter == 1) {
                    arr.push('Part time sitter');
                  }
                  if (details.plans.none == 1) {
                    arr.push('None');
                  }
                  if (arr.length > 0) {
                    q.plans = arr;
                  } else {
                    q.plans = '-';
                  }
                }
                q.commute_to = '';
                if (details && details.commute_to) {
                  let commute_to_simple = '';
                  // eslint-disable-next-line prefer-arrow-callback
                  map(details.commute_to, function(value, key) {
                    const _other = `otherValue${ key}`;
                    commute_to_simple +=
                      `${`<b>${value.suburb}</b>` +
                      ': '}${
                      value.option
                      } ${
                      value.option === 'Other' ? details[_other] : ''
                      }</br>`;
                  });
                  q.commute_to_simple = `A. ${ commute_to_simple}`;
                }
              }
              questionData.push(q);
            });

            await Promise.all(sortQ)
          }


          const newtest = arTest.map((a, i) => {
            const tval = get(aiData, a.townKey)
            const cval = filter(questionData, (c, j) => { return Object.keys(c).includes(a.clientKey) })

            a.townAns = (tval == -1 && '-') || (tval == 1 && 'Yes') || (tval == 0 && 'No') || tval

            const cvalMain = get(cval, `[0].${a.clientKey}`, '-')

            a.clientAns = (cvalMain == -1 && '-') || (cvalMain == 1 && 'Yes') || (cvalMain == 0 && 'No') || cvalMain

            if (a.isArray) {
              const aor = []
              // ar stuff
              a.townKey.map((t, o) => {
                if (get(aiData, t.value)) {
                  aor.push(t.label)
                }
              })

              a.townAns = aor
            }
          })


          await Promise.all(newtest)


          this.setState({
            openQuestion: true,
            isLoading: false,
            arTestFinal: arTest,
            questionData
          });
        } else {
          this.setState({
            openQuestion: true,
            isLoading: false,
            questionData
          });
        }
      } else {
        this.setState({
          openQuestion: true,
          isLoading: false,
          questionData
        });
      }
    }).catch(logoutInvalidRequest);
    //this.setState({ openQuestion: true, questionData: questionData })
  }

   capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  render() {
    const {
      publicData,
      town,
      townListOptions,
      isLoading,
      quickFacts,
      aiData,
      clients,
      questionData,
      selectedClient,
      otherResearch,
      noteResearch,
      arTestFinal,
      townCharacteristics
    } = this.state;


    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <Spin spinning={isLoading} indicator={<Loader />}>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="heading-title">
                    <span className="heading-icon">
                      <TownCheatSheetColorIcon className="" />
                    </span>
                    Town Cheat Sheet
                  </h2>
                </div>
              </div>

              <div className="">
                <div className="row mb__30">
                  <div className="col-xs-6 col-md-5">
                    <Dropdown
                      isEnable={true}
                      isLoadingRefferingClient={false}
                      selectedRefferringClient={
                        this.state.selectedRefferringClient
                      }
                      townListOptions={townListOptions}
                      searchTerm={this.state.searchTerm}
                      filterRefferClientsOption={this.filterRefferClientsOption}
                      handleClientSelect={this.handleClientSelect}
                      {...this.props}
                    />
                  </div>
                  <div className="col-xs-6 col-md-5">
                    <div className="material-textfield">
                      <select
                        onChange={e => this.clientQuestionClick(e.target.value)}
                        className="form-control custom-select material-textfield-input textfield-input-lg"
                      >
                        <option selected disabled>
                          Select Client
                        </option>
                        {!isEmpty(clients) &&
                          clients.map((client, i) => {
                            return (
                              <option key={i} value={client.id}>
                                {get(client, 'client_full_name', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label label-lg">
                        Select Client
                      </label>
                    </div>
                  </div>
                </div>

                {!isEmpty(town) && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mb__50">
                        <div className="card-header d__flex align__items__center">
                          <div>
                            <h5 className="card-title mb__3">
                              {get(town, 'name')}
                            </h5>
                          </div>
                          <div className="cleint__information__cta">
                            <button
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/strategist/town-characteristics/${get(
                                    town,
                                    'id'
                                    // 'town_db_id'
                                  )}`,
                                  state: { town }
                                });
                              }}
                              className="btn btn__btn btn-dark btn__create__appointment mr__15"
                            >
                              Edit Town Characteristics
                            </button>
                            <button
                              onClick={() => {
                                window.open(get(town, 'town_url'), '_blank');
                              }}
                              className="btn btn__btn btn-dark btn__edit__client"
                            >
                              View Town Report
                            </button>
                          </div>
                        </div>
                        <div className="card-body card-body-sm">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="info__block">
                                <label>Population</label>
                                <p>{get(quickFacts, 'population', '-')}</p>
                              </div>
                              <div className="info__block">
                                <label>School District</label>
                                <p>District Of Columbia Public Schools</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-responsive">
                                <table className="table table__schools">
                                  <thead>
                                    <tr>
                                      <th>Schools</th>
                                      <th>Grades</th>
                                      <th>Rating</th>
                                      <th>Student to Teacher Ratio</th>
                                      <th># of Students</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!isEmpty(publicData) ? (
                                      publicData.map((school, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>
                                              {get(school, 'InstitutionName')}
                                            </td>
                                            <td>
                                              {get(school, 'gradelevel1hitext')}
                                            </td>
                                            <td>
                                              {get(
                                                school,
                                                'GSTestRating',
                                                '0'
                                              ) !== '0'
                                                ? `${get(
                                                    school,
                                                    'GSTestRating'
                                                  )}/5`
                                                : 'Not Rated'}{' '}
                                            </td>
                                            <td>
                                              {get(school, 'studentteacher')}:1
                                            </td>
                                            <td>
                                              {get(school, 'studentsnumberof')}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {' '}
                                        <td
                                          colSpan="6"
                                          className="blank__table__state"
                                        >
                                          No data found.
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>

                                {/* <tr>
                                    {' '}
                                    <td
                                      colSpan="6"
                                      className="blank__table__state"
                                    >
                                      No data found.
                                    </td>
                                  </tr> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header d__flex align__items__center">
                          <div>
                            <h5 className="card-title mb__3">Insights</h5>
                          </div>
                        </div>
                        <div className="card-body p__0">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="insights__list">
                                {!isEmpty(otherResearch) &&
                                  otherResearch.map((r, i) => {
                                    return (
                                      <li key={i}>
                                        <div>
                                          <h4 className="title">{r.note}</h4>
                                          <a className="title-link">{r.link}</a>
                                          <div className="time">
                                            Uploaded on{' '}
                                            {moment(r.created).format('LLL')}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}


                                {/* <li>
                                  <div>
                                    <h4 className="title">
                                      Wellesley Town Spotlight from Suburban
                                      Jungle blog
                                    </h4>
                                    <a className="title-link">
                                      https://suburbanjunglegroup.com/town-spotlight-wellesley/
                                    </a>
                                    <div className="time">
                                      Uploaded on October 02, 2019
                                    </div>
                                  </div>
                                </li> */}
                              </ul>
                              {!isEmpty(noteResearch) &&
                                noteResearch.map((note, i) => {
                                  return (
                                    <ul
                                      key={i}
                                      className="early__dismissal__list"
                                    >
                                      {note.note}
                                      {/* <li>
                                        - Elementary Schools: Every Wednesday at
                                        12pm
                                      </li>
                                      <li>
                                        - Middle and High School: About once per
                                        month on Wednesdays, dismissal is at
                                        11:19 am and 11:29 am.
                                      </li> */}
                                    </ul>
                                  );
                                })}
                             {
                               isEmpty(noteResearch) && isEmpty(otherResearch) &&
                               <p
                               className="blank__table__state"
                             >
                               No data found.
                             </p>
                             }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table__cheat__sheet">
                            <thead>
                              <tr>
                                <th>{get(town, 'name')} Characteristics</th>
                                <th>
                                  {get(selectedClient, 'client_full_name', '')}{' '}
                                  Characteristics
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Town characteristics */}
                              {
                                !isEmpty(townCharacteristics) && townCharacteristics.map((t, i) => {
                                  return (
                                    <tr key={i}>
                                     <td>
                                  <div className="info__block">
                                    <label>{t.label} </label>
                                    {
                                       !t.isArray ? <p>{this.capitalize(t.townAns)}</p> :
                                       t.townAns && !isEmpty(t.townAns) && t.townAns.map((ans, k) => {
                                        return (
                                            <p key={k}>- {this.capitalize(ans)} </p>
                                        )
                                      })
                                    }
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="info__block">
                                    <label>{t.label} </label>
                                    {
                                       !['object', 'array'].includes(typeof t.clientAns) ? <p>{this.capitalize(t.clientAns)}</p> :
                                       t.clientAns && !isEmpty(t.clientAns) && t.clientAns.map((ans, k) => {
                                        return (
                                            <p key={k}>- {this.capitalize(ans)} </p>
                                        )
                                      })
                                    }
                                  </div>
                                </td> */}
                                  </tr>
                                  )
                                })
                              }

                              {/* Client answers */}
                              {
                                !isEmpty(arTestFinal) && arTestFinal.map((t, i) => {
                                  return (
                                    <tr key={i}>
                                <td>
                                  <div className="info__block">
                                    <label>{t.label} </label>
                                    {
                                       !['object', 'array'].includes(typeof t.clientAns) ? <p>{this.capitalize(t.clientAns)}</p> :
                                       t.clientAns && !isEmpty(t.clientAns) && t.clientAns.map((ans, k) => {
                                        return (
                                            <p key={k}>- {this.capitalize(ans)} </p>
                                        )
                                      })
                                    }
                                  </div>
                                </td>
                                  </tr>
                                  )
                                })
                              }
                            </tbody>
                            {/* <tbody>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Budget </label>
                                    {budget.map((b, i) => {
                                      const isTrue =
                                        aiData[b.value] === 1 ? true : false;
                                      return (
                                        <>
                                          {isTrue && (
                                            <p key={i}>- {get(b, 'label')} </p>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Budget </label>
                                    <p>- under $400,000</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Feel of the town </label>
                                    <p>
                                      {get(aiData, 'urban_suburban', 'None')}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Feel of the town </label>
                                    <p>Suburban</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Willing to commute to work</label>
                                    {willingToCommute.map((b, i) => {
                                      const isTrue =
                                        aiData[b.value] === 1 ? true : false;
                                      return (
                                        <>
                                          {isTrue && (
                                            <p key={i}>- {get(b, 'label')} </p>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Willing to commute to work</label>
                                    <p>- 1 hr +</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>School system type</label>
                                    <p>{get(aiData, 'schools')}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>School system type</label>
                                    <p>High</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Transportation Types </label>
                                    {transportation.map((b, i) => {
                                      const isTrue =
                                        aiData[b.value] === 1 ? true : false;
                                      return (
                                        <>
                                          {isTrue && (
                                            <p key={i}>- {get(b, 'label')} </p>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Transportation Types </label>
                                    <p>- Train</p>
                                    <p>- Bus </p>
                                    <p>- Car </p>
                                    <p>- Walk</p>
                                    <p>- Bike </p>
                                    <p>- Ferry</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Walkable Neighborhood? </label>
                                    <p>
                                      {(get(aiData, 'walkable') === 1 &&
                                        'Yes') ||
                                        (get(aiData, 'walkable') === 0 &&
                                          'No') ||
                                        (get(aiData, 'walkable') === -1 &&
                                          'No Answer')}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Walkable Neighborhood? </label>
                                    <p>No</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Home Type</label>
                                    <p>- Single family home </p>
                                    <p>- Townhome </p>
                                    <p>- Condo</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Home Type</label>
                                    <p>- Single family home </p>
                                    <p>- Townhome </p>
                                    <p>- Condo</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Progressive v conservative </label>
                                    <p>Progressive</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Progressive v conservative </label>
                                    <p>Progressive</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Organic food options </label>
                                    <p>Yes</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Organic food options </label>
                                    <p>Yes</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Great restaurants nearby </label>
                                    <p>No</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Great restaurants nearby </label>
                                    <p>No</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Fancy or laid back? </label>
                                    <p>- Fancy </p>
                                    <p>- Laid back</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Fancy or laid back? </label>
                                    <p>- Fancy </p>
                                    <p>- Laid back</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Popular Occupations </label>
                                    <p>Doesn't matter </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Popular Occupations </label>
                                    <p>Doesn't matter </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Sporty/Artsy </label>
                                    <p>- White collar</p>
                                    <p>- Blue collar</p>
                                    <p>- Finance</p>
                                    <p>- Medical</p>
                                    <p>- Legal</p>
                                    <p>- Entrepreneurial</p>
                                    <p>- Tech</p>
                                    <p>- Sales/Marketing</p>
                                    <p>- Creative</p>
                                    <p>- Retail</p>
                                    <p>- Education</p>
                                    <p>- Government</p>
                                    <p>- Nonprofit</p>
                                    <p>- Nice mix of all</p>
                                    <p>- Doesn’t matter </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Sporty/Artsy </label>
                                    <p>- White collar</p>
                                    <p>- Blue collar</p>
                                    <p>- Finance</p>
                                    <p>- Medical</p>
                                    <p>- Legal</p>
                                    <p>- Entrepreneurial</p>
                                    <p>- Tech</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Hiking trails nearby </label>
                                    <p>No</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Hiking trails nearby </label>
                                    <p>No</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Diverse? </label>
                                    <p>Yes</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Diverse? </label>
                                    <p>Yes</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="info__block">
                                    <label>Activities</label>
                                    <p>- Tennis</p>
                                    <p>- Hockey</p>
                                    <p>- Lacrosse</p>
                                    <p>- Dance</p>
                                    <p>- Skiing</p>
                                    <p>- Squash</p>
                                    <p>- Theatre</p>
                                    <p>- Football</p>
                                    <p>- Writing</p>
                                    <p>- Soccer</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="info__block">
                                    <label>Activities</label>
                                    <p>- Tennis</p>
                                    <p>- Hockey</p>
                                    <p>- Lacrosse</p>
                                    <p>- Dance</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody> */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </Spin>
      </div>
    );
  }
}
