import React, { Fragment, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default function ChatContentComponent({ chat, chatHistory, index }) {
  const [selectedImageIndex, setIndex] = useState(0)
  const [imageModal, setImageModal] = useState(false)

  const showImageModal = (index) => {
    setImageModal(true)
    setIndex(index)
  }
  const onMovePrevImage = () => {
    const newIndex =
      (selectedImageIndex + [chat.content].length - 1) % [chat.content].length
    setIndex(newIndex)
  }
  const onMoveNextImage = () => {
    const newIndex = (selectedImageIndex + 1) % [chat.content].length
    setIndex(newIndex)
  }

  const closeImageModal = () => setImageModal(false)

  return (
    <div className="chats__content">
      {chat.type === 'text' && chat.message}
      {chat.type === 'automated_welcome_message' && chat.message}
      {chat.type === 'pdf' && (
        <a
          className="pdf_file"
          href={chat.message}
          download={chat.message}
          target="_blank"
        >
          <div>
            <img src="../../img/pdf_icon.png" />
            {chat.file_name}{' '}
          </div>
        </a>
      )}

      {chat.type === 'excel' && (
        <a
          className="pdf_file"
          href={chat.message}
          download={chat.message}
          target="_blank"
        >
          <div>
            <img src="../../img/excel_icon.png" />
            {chat.file_name}
          </div>
        </a>
      )}

      {chat.type === 'image' && (
        <Fragment>
          <img
            className="clickable__image"
            onClick={() => showImageModal(index)}
            src={chat.message}
          />
          {imageModal && (
            <Lightbox
              mainSrc={chatHistory.map((c) => c.message)[selectedImageIndex]}
              onCloseRequest={closeImageModal}
              onMovePrevRequest={onMovePrevImage}
              onMoveNextRequest={onMoveNextImage}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}
