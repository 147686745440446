import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import Select from 'react-select'
import _ from 'lodash'
import $ from 'jquery'

import config from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import styles from './styles.scss'
import NumberFormat from 'react-number-format';
import { browserHistory } from 'react-router'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { logoutInvalidRequest } from '../../utils/helper'
const required = value => value ? undefined : 'Required'
const API_HOSTNAME = process.env.API_HOSTNAME
class ProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      err: {},
      openSnackbar: false,
      errMessage:'',
      first_name: '',
      last_name: '',
      timezone: '',
      role: '',
      description: '',
      mobile_phone: '',
      selectedOption: '',
      email: '',
      conference_phone: '',
      townOptions: [],
      password: '',
      checked: [],
      confirmPass: '',
      addUserMsg: "",
      editUserMsg:"",
      basicInfoMsg: "",
      suburbsMsg: "",
      passwordMsg:"",
      err: {},
      err2: {},
      avatar: '',
      file: null,
      designation:'',
      country_code:'+1',
    }
    this.towns = []
    this.handleChange = this.handleChange.bind(this)
    this.townChk = this.townChk.bind(this)
    this.GmailAuthPop = this.GmailAuthPop.bind(this)
    this.handleTokenSave = this.handleTokenSave.bind(this)
  }

  componentWillMount(){
   const {location} = this.props;
   let _usrID = location && location.state && location.state.id && location.state.id
    if(_usrID && _usrID !==''){
      let usrdata = {}
      usrdata.user_id = _usrID;
      this.props.getUSerProfileByID(usrdata)
    }

    // Getting Basic filtering keys startegist, status ETC from DB
    this.props.getFilterParams()
  }

  componentDidMount(){
    document.title = "Jungler: My Profile";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  componentWillReceiveProps(nextProps) {
    let data1 = {}
    let self = this
    const { checked } = this.state
    const {getTowns} = this.props
    
    if (this.props.junglerUserPhase === 'success') {
    this.props.userData && this.props.userData.user && this.props.userData.user.suburbs.forEach(function(val, indx){
      if(checked.indexOf(val.id) === -1) {
        checked.push(val.id)
      }
    })

    const {townOptions} = this.state
    this.props.userData && this.props.userData.user && this.props.userData.user.towns.forEach(function(townVal, townIndex){
      if(townOptions.indexOf(townVal.id) === -1) {
        townOptions.push(townVal.id)
      }
    })
    this.townChk()
    this.props.clearPhase()
    } 

    if (nextProps.phase === "error") {
      this.setState({ errMessage: nextProps.rxError.message, openSnackbar: true})
    }

    if(nextProps.junglerUserPhase === "success"){
      const { userData } = nextProps
      let conferencePhoneNo = ''
      if(userData && userData.user && userData.user.phone_work){
        let conf_mo_no = userData.user.phone_work
        conferencePhoneNo = (conf_mo_no.replace(/\D/g, '').length == 10) ? this.formatNumber(conf_mo_no) : conf_mo_no
      }

      this.setState({ 
        first_name: userData.user.first_name,
        last_name: userData.user.last_name,
        timezone: userData.user.time_zone,
        role: userData.user.role,
        description: userData.user.description,
        mobile_phone: userData.user.phone_mobile,
        email: userData.user.email,
        conference_phone: conferencePhoneNo,
        avatar: userData.user.avatar ? userData.user.avatar : '',
        designation: userData.user.designation ? userData.user.designation : '',
        country_code: userData.user.country_code
        // checked: _.get(userData.user.suburbs, 'id')
      })
      this.props.clearPhase()
    }

    if(nextProps.ownUpdatePhase === "success"){
      if(nextProps.ownUpdatMessage === "User profile updated successfully"){
        this.setState({basicInfoMsg:"Updated Sucessfully...."})
        setTimeout(function() {
          this.setState({basicInfoMsg:'', passwordMsg: ''})
          this.props.history.push({ pathname: 'jungler-users'})
        }.bind(this),1500)
      this.props.clearPhaseUser()
      }
      else{
        this.setState({ suburbsMsg : "Updated Sucessfully...."})
        setTimeout(function() {
          this.setState({suburbsMsg: '', passwordMsg: ''})
          this.props.history.push({ pathname: 'jungler-users'})
        }.bind(this),1500)
      this.props.clearPhaseUser()
      }
    }


    if(nextProps.addUserPhase === "error"){
      this.setState({ 
        addUserMsg: "Error..",
        basicInfoMsg: "Error in update profile",
        suburbsMsg: "Error in update profile",
        passwordMsg: "Error in password"})
    }
    if(nextProps.passwordPhase === "success"){
      this.setState({ passwordMsg: "Change Password Sucessfully...."})
      setTimeout(function() {
        this.setState({passwordMsg: ''})
        this.props.history.push({ pathname: 'jungler-users'})
        this.props.clearPhaseUser()
      }.bind(this),1500)
    }
  }

  // Handel change function for updating states if anything changed.

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

 /* Method for opening the Window popup to authenticating Google GMAIL accesss by strategist */

 GmailAuthPop(){
  // Calling the API for get authenticate GOOGLE URL 
  axios({
    method: 'GET',
    url: API_HOSTNAME+'/gmail/authenticate'
  })
  .then((res) => {
    if(res && res.data && res.data.status && res.data.data !=''){
    // OPEN a seprate window with callback
    var win = window.open(res.data.data, 'Account verifications','width=600,height=500,left=50,top=100');
    //Close window in 50 seconds
    setTimeout(function () { win.close()
      window.location.reload(true)
      ;}, 50000);
    }
  }).catch(logoutInvalidRequest)
}
// END 


handleTokenSave(){
  this.setState({isloading: true})
  // event.preventDefault()
  const err = {}
  if (this.state.accesstoken === '') {
    err.agent_id = 'Enter copied token, it`s required.'
  }
  this.setState({ err })
  if (!Object.keys(err).length) {
    const data = {}
    data.code = this.state.accesstoken
    this.props.saveAuthtoken(data)
  }else{
    this.setState({isloading: false})
  }
}

  townChk(dataid){
    let data = {}
    if(dataid && dataid !=''){
     const id = $.inArray(dataid, this.state.checked)
     if (id === -1) {
       this.state.checked.push(dataid)
       data.suburb_arr = this.state.checked
       this.props.getTowns(data)
     } else {
       this.state.checked.splice(id, 1)
       data.suburb_arr = this.state.checked
       this.props.getTowns(data)
     }
     this.forceUpdate()
   }else{
      data.suburb_arr = this.state.checked
      this.props.getTowns(data)
      this.forceUpdate()
   }
  }
  
  // Handeling file upload for user profile image

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL;
    // let files = $("#profileImage")[0].files[0];
    let files  = e.target.files[0]
    var img = new Image();
    let self=this
    let reader = new FileReader();
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files);
      img.onload = function() {
          self.setState({file: files})
          self.setState({avatar: reader.result})
      }
    }
    var read = reader.readAsDataURL(files) 
  }
  

  handleTownChange(selectedOption) {
    let {townData} = this.props
    let towns = selectedOption.split(",")
      _.forEach(towns,function(towndata){
          var serv  =_.findIndex(townData, function(cat) {
          return cat.name == towndata
        })
      }
    )
    var array = JSON.parse("[" + selectedOption + "]");
    this.setState({townOptions: array})
  }
 // Validating Email Id with the regex
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

 // Saving Profile information part 1 with IMAGE

  saveFirstBtn(id, event){
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', this.state.user_id);
    axios.post(`${API_HOSTNAME}/users/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if(response.data && response.data.status){
        this.setState({avatar: response.data.image_url})
        this.saveFirstBtnSubmit(id)
      }
      else{
        this.saveFirstBtnSubmit(id)
      }
    }).catch(logoutInvalidRequest);
  }
 // Saving BASIC part of profile
  saveFirstBtnSubmit(id, event){
    let onlyNumber = this.state.mobile_phone && this.state.mobile_phone.replace(/\D/g, '')
    let mobileValidation = onlyNumber.length
    const err = {}
    const { first_name, last_name, email, mobile_phone, conference_phone, 
            role, timezone,description, avatar, designation, country_code } = this.state

    if (first_name === '' || first_name.trim() === '') {
      err.first_name = 'Enter First Name'
    }
    if (last_name === '' || last_name.trim() === '') {
      err.last_name = 'Enter Last Name'
    }
    if (email === '' || email.trim() === '') {
      err.email = 'Enter Email Address'
    }else if (!this.validateEmail(this.state.email)) {
        err.email = 'Your email address is Invalid.'
    }

    if (mobile_phone === '' || mobile_phone.trim() === '') {
      err.mobile_phone = 'Enter Mobile Number'
    }else if(mobileValidation < 6  || mobileValidation > 16){
      err.mobile_phone = 'Enter Mobile Correct Number'
    }
    
    if (timezone === '' || timezone ==="Select Time Zone" || timezone.trim() === '') {
      err.timezone = 'Enter Time Zone'
    }
    this.setState({ err })
     if (!Object.keys(err).length) {
      let data = {}
      data.user_id      = id
      data.update_type  = "basic_info"
      data.first_name   = first_name
      data.last_name    = last_name
      data.email        = email
      data.phone_mobile = mobile_phone
      data.phone_work   = conference_phone
      data.time_zone    = timezone
      data.description  = description
      data.avatar       = avatar
      data.designation  = designation
      data.country_code = country_code
      this.props.updateOwnProfile(data)
    }
  }
 // Saving SUBURBS part of profile
  saveSecondBtn(id, event){
    event.preventDefault()
    const err = {}
    const { townOptions } = this.state

    let data = {}
    data.user_id      = id
    data.update_type  = "suburb_info"
    data.towns_arr    = townOptions
    data.suburbs_arr  = this.state.checked
    this.props.updateOwnProfile(data)
  }

  changePassword(id, event){
    event.preventDefault()
    const err2 = {}
    const { password, confirmPass } = this.state

    if (password === '' || password.trim() === '') {
      err2.password = 'Enter Password'
    }
    if (confirmPass === '' || confirmPass.trim() === '') {
      err2.confirmPass = 'Enter Confirm Passowrd'
    }
    if(password !== confirmPass){
      err2.confirmPass = 'Password Does not match'
    }

    this.setState({ err2 })
     if (!Object.keys(err2).length) {
      let data = {}
      data.user_id       = id
      data.new_password = password
      this.props.updatePassword(data)
    }
  }

  handleCodeChange(event){
    this.setState({ country_code: '+'+event})
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  render() {
    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
    });
    const {
      pristine,
      isSubmitting,
      rxError,
      user,
      userData,
      location,
      filterParams,
      token,
      phase,
      townData
    } = this.props
    let { avatar } = this.state;
    let roleList = config.roleList.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
    });

    let options = []
    townData && townData.data && townData.data.map(function(data, key) {
        options.push({label: data.name ,value:data.id})
    })

    let allData = filterParams.data;
    let _user_id = location && location.state && location.state.id && location.state.id
    
      return (
        <div>
          {/* <div className="full__page--header">
            <div className="container">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="logo-header">
                        <a><img src="/img/logo.svg"/> <span>| &nbsp;&nbsp; Edit Your Profile</span></a>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12 text-center strategistEmailSync">
                    {user && user.role==='strategist' || user && user.role==='admin' ?
                    <button onClick={this.GmailAuthPop.bind(this)} className="navigation__links--google-sync">
                    <img src="img/google-icon.svg"/>{user.gmail_authentication_id && user.gmail_authentication_id !=''  ? 'Emails Synced '+ user.gmail_authentication_id : ' Sync E-mail'}</button>
                    : ''
                    } 
                    <div className="close-btn">
                       <img className="forCursor" onClick={this.props.history.goBack} src="img/close.svg"/>
                    </div>
                  </div>
                </div>
            </div>
          </div> */}

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__header--wrapper">
                  <div className="profile__header strategistEmailSync">
                    <a>
                      <img src="/img/logo.svg" />
                      <h4>
                        <span>|</span>Edit Your Profile
                      </h4>
                    </a>
                    {user && user.role==='strategist' || user && user.role==='admin' ?
                    <button onClick={this.GmailAuthPop.bind(this)} className="navigation__links--google-sync">
                    <img src="img/google-icon.svg"/>{user.gmail_authentication_id && user.gmail_authentication_id !=''  ? 'Emails Synced '+ user.gmail_authentication_id : ' Sync E-mail'}</button>
                    : ''
                    } 
                  </div>
                  <div className="profile__close">
                    <img className="forCursor" onClick={this.props.history.goBack} src="img/close.svg"/>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mobile__profile--container" style={{ marginBottom: '0' }}>
                  <span className="mobile__profile--avtar">
                  <img src={avatar !== '' && avatar !==null ? avatar : '../img/blank_profile_image.png' } alt="" />
                  </span>
                  <form>
                    <span className="btn-file">
                      <span className="editPhotoBtn">Edit Photo </span>
                      <input type='file' className="edit__photo--btn" id="profileImage" onChange={this.handleFileUpload.bind(this)} />
                    </span>
                    <span className="maximg__label">(Max 1MB allowed)</span>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="profile__container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="profile__container--form">
  
                    <div className="panel panel-default mobile__no--border">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Profile</h3>
                        <div className="profile__container--section">
                          <span className="profile__container--avtar">
                          <img src={avatar !== '' && avatar !==null ? avatar : '../img/blank_profile_image.png' } alt="" />
                        </span>
                        <form>
                          <span className="btn-file">
                            <span className="editPhotoBtn">Edit Photo </span>
                            <input type='file' className="edit__photo--btn" id="profileImage" onChange={this.handleFileUpload.bind(this)} />
                          </span>
                          <span className="maximg__label">(Max 1MB allowed)</span>
                        </form>
                        </div>
                      </div>
                      <div className="panel-body">
                        <form>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                            <label className="labelinputs__global">First Name</label>
                              <div className="form-group text-group">
                                <input 
                                  type="text" 
                                  name="first_name"
                                  value={this.state.first_name}
                                  onChange={this.handleChange}
                                  className="textinputs__global" 
                                  required/>                                
                              </div>
                              { this.state.err.first_name ?
                              <span className="error_msg">
                              {this.state.err.first_name}
                              </span> : '' }
                            </div>
                            <div className="col-md-6 col-sm-6">
                            <label className="labelinputs__global">Last Name</label>
                              <div className="form-group text-group">
                                <input 
                                  type="text" 
                                  name="last_name"
                                  value={this.state.last_name}
                                  onChange={this.handleChange}
                                  className="textinputs__global" 
                                  required/>
                              </div>
                              { this.state.err.last_name ?
                              <span className="error_msg">
                              {this.state.err.last_name}
                              </span> : '' }
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                            <label className="labelinputs__global">Mobile Phone</label>
                              <div className="form-group text-group country__code__profile textinputs__global phonefull__input">
                                <PhoneInput 
                                  defaultCountry={'us'} 
                                  value={this.state.country_code}
                                  autoFormat={false}
                                  disableAreaCodes={true}
                                  enableLongNumbers={false}
                                  onChange={this.handleCodeChange.bind(this)}
                                  buttonClass="flag-dropdown__login"
                                />                           
                                <NumberFormat
                                  format="(###) ### #### #####"
                                 // placeholder="(444)  444 - 4444"
                                  value={this.state.mobile_phone}
                                  name='mobile_phone'
                                  id="flagInputControlMainPhone"
                                  onChange={this.handleChange.bind(this)}
                                  />
                                </div>
                              { this.state.err.mobile_phone ?
                              <span className="error_msg">
                              {this.state.err.mobile_phone}
                              </span> : '' }
                            </div>
                            <div className="col-md-6 col-sm-6">
                            <label className="labelinputs__global">Conference Phone Number</label>
                              <div className="form-group text-group">
                                <input 
                                  type="text" 
                                  name="conference_phone"
                                  value={this.state.conference_phone}
                                  onChange={this.handleChange}
                                  className="textinputs__global" 
                                  required/>
                              </div>
                            </div>
                          </div>
                          <label className="labelinputs__global">Email</label>
                          <div className="form-group text-group">
                            <input 
                              type="text" 
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="textinputs__global" 
                              required/>
                          </div>
                          { this.state.err.email ?
                          <span className="error_msg">
                          {this.state.err.email}
                          </span> : '' }
                          { (this.state.role === "strategist" || this.state.role === "admin" || this.state.role=== "superadmin") ? 
                            <div className="form-group text-group">
                            <label className="labelinputs__global">Designation</label>
                              <input 
                                type="text" 
                                name="designation"
                                value={this.state.designation}
                                onChange={this.handleChange}
                                className="textinputs__global" 
                                required/>
                            </div> : ''
                          }
                          <div className="form-group">
                            <label className="labelinputs__global">Time Zone</label>
                            <select 
                              className="selectinputs__global" 
                              name="timezone"
                              value={this.state.timezone}
                              onChange={this.handleChange}>
                              <option>Select Time Zone</option>
                              {timezonlist}
                            </select>
                          </div>
                          { this.state.err.timezone ?
                          <span className="error_msg">
                          {this.state.err.timezone}
                          </span> : '' }
                          <div className="form-group">
                          <textarea 
                            className="textareainputs__global"
                            value={this.state.description} 
                            rows="8" 
                            name = "description"
                            onChange={this.handleChange}
                            placeholder="Enter description…">
                          </textarea>
                        </div>

                        {this.state.basicInfoMsg !== '' &&
                          <div className="text-center alert alert-success">
                            {this.state.basicInfoMsg}
                          </div>
                        }

                         <button 
                          type="submit" 
                          className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                          onClick={this.saveFirstBtn.bind(this, _user_id)}>
                          Save
                        </button>

                        </form>
                      </div>
                    </div>
                    <div className="panel panel-default mobile__no--border mob__coverage--marg">
                      <div className="panel-heading mobile__no--display">
                        <h3 className="panel-title">Region Coverage</h3>
                      </div>
                      <div className="panel-body">
                        <form>
                          {
                           allData && allData.suburbs && allData.suburbs.map((filter, ind) =>{
                               return(
                               <div key={ind} className="col-md-4 col-xs-6">
                                  <div className="row">
                                    <ul className="checkbox__list">
                                      <li>
                                        <span className="coverage__checkbox">
                                          <label className="custom-checkbox">{filter.name}
                                            <input
                                            checked={this.state.checked.includes(filter.id) ? true : false}
                                            onChange={this.handleChange}
                                            onClick={()=>this.townChk(filter.id)}
                                            type="checkbox"/>
                                            <span className="checkmark"></span>
                                          </label>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )
                            })
                          }
                          <div className="clearfix"></div>
  
                          {
                            (!(this.state.role === "superadmin" || this.state.role === "admin" || this.state.role === "strategist"))?
                             <div className="coverage__area">
                                <label className="coverage__area--label">Suburb</label>
                                <Select 
                                 multi 
                                 simpleValue
                                 placeholder="Add a town"
                                 name="category" 
                                 options={options}
                                 onChange={this.handleTownChange.bind(this)} 
                                 value={this.state.townOptions}>
                                </Select>
                             </div>
                             :""
                          }
                          

                        {this.state.suburbsMsg !== '' &&
                          <div className="text-center alert alert-success">
                            {this.state.suburbsMsg}
                          </div>
                        }

                        <button 
                          type="submit" 
                          className="btn btn__green btn__green--lg mobile__full--btn tablet__btn--center"
                          onClick={this.saveSecondBtn.bind(this, _user_id)}>
                          Save
                        </button>
                          
                        </form>
                      </div>
                    </div>

                    {/*<div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="panel-title">Password</h3>
                      </div>
                      <div className="panel-body">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group text-group">
                                <input 
                                  type="password" 
                                  name="password"
                                  onChange={this.handleChange}
                                  className="form-control text-input" 
                                  required/>
                                <span className="bar"></span>
                                <label className="text-label">Password</label>
                              </div>
                              { this.state.err2.password ?
                              <span className="error_msg">
                              {this.state.err2.password}
                              </span> : '' }
                            </div>
                            <div className="col-md-12">
                              <div className="form-group text-group">
                                <input 
                                  type="password" 
                                  name="confirmPass"
                                  onChange={this.handleChange}
                                  className="form-control text-input" 
                                  required/>
                                <span className="bar"></span>
                                <label className="text-label">Re-enter Password</label>
                              </div>
                              { this.state.err2.confirmPass ?
                              <span className="error_msg">
                              {this.state.err2.confirmPass}
                              </span> : '' }
                            </div>
                          </div>

                          {this.state.passwordMsg !== '' &&
                            <div className="text-center alert alert-success">
                              {this.state.passwordMsg}
                            </div>
                          }

                          <button 
                            type="submit" 
                            onClick = {this.changePassword.bind(this, _user_id)}
                            className="btn btn__green btn__green--lg">
                            Update
                          </button>
                        </form>
                      </div>
                    </div>*/}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    
  }
}

export default reduxForm({
  form: 'addjungleruser',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ProfileForm)
