import { connect } from 'react-redux'

import {
  updateClientUser,
  getAgentAvailability,
  getAvailability,
  getAgentTimeSlotAvailability,
  getClientById,
  clearPhase,
  getAppointments,
  getAgentDeal
} from '../../../../../store/agent/duck'

import ClientInformationComponent from './component'
const ClientInformationContainer = connect(
  // Map state to props
  (state) => ({
    updateUserPhase: state.agent.updateUserPhase,
    getAvilData: state.agent.getAvilData,
    getAvilPhase: state.agent.getAvilPhase,
    appointmentData: state.agent.appointmentData,
    agentTimeAvailability: state.agent.agentTimeAvailability,
    getAgentTimeAvailabilityPhase: state.agent.getAgentTimeAvailabilityPhase,
    agentDealData: state.agent.agentDealData
  }),
  // Map actions to dispatch and props
  {
    updateClientUser,
    getAvailability,
    getAgentTimeSlotAvailability,
    getAgentAvailability,
    getClientById,
    clearPhase,
    getAppointments,
    getAgentDeal
  }
)(ClientInformationComponent)

export default ClientInformationContainer
