import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './api'
/*  
     //////////////////////////
   /// activity epics ///
///////////////////////////
*/

// fetch client activity email midleware
const fetchClientActivityEmailEpic = (action$) =>
  action$.ofType(types.FETCH_ACTIVITY_CLIENT_EMAIL).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientActivityEmail(action.payload))
      .map((payload) => ({
        type: types.FETCH_ACTIVITY_CLIENT_EMAIL_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_ACTIVITY_CLIENT_EMAIL_ERROR,
          payload: { error },
        })
      )
  })
// fetch client activity email midleware
const fetchClientActivityHistoryEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_ACTIVITY_HISTORY).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientActivityHistory(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_ACTIVITY_HISTORY_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_ACTIVITY_HISTORY_ERROR,
          payload: { error },
        })
      )
  })

// combine activity epic
export const activitiesEpic = combineEpics(
  fetchClientActivityEmailEpic,
  fetchClientActivityHistoryEpic
)
