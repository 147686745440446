import React, { PureComponent /*, PropTypes*/ } from 'react'
// import IPropTypes from 'react-immutable-proptypes'
import { TrackIcon } from '../../../../../../components/icons'
import { get, find } from 'lodash'
import './styles.scss'

export default function ClientByStatusComponent({ dashboardData, history }) {
  const filterClient = (filter) => {
    history.push({
      pathname: '/strategist/clients/all-clients',
      state: { filter }
    })
  }

  const callScheduledCount = get(find(dashboardData.status, { status: "Call Scheduled" }), "statusCount", '0')
  const grrenCount = get(find(dashboardData.status, { status: "Green" }), "statusCount", '0')
  const greenActivilyEngCount = get(find(dashboardData.status, { status: "Green - Actively Engaged" }), "statusCount", '0')
  const greenNotVeryEngCount = get(find(dashboardData.status, { status: "Green - Not Very Engaged" }), "statusCount", '0')
  const greenSomeWhatEngCount = get(find(dashboardData.status, { status: "Green - Somewhat Engaged" }), "statusCount", '0')
  const neverHadACallCount = get(find(dashboardData.status, { status: "Never Had A Call" }), "statusCount", '0')
  const onlineNewCount = get(find(dashboardData.status, { status: "Online-New" }), "statusCount", '0')
  const tryingToScheduleCount = get(find(dashboardData.status, { status: "Trying To Schedule" }), "statusCount", '0')

  return (
    <div>
      <div className="card card__tile__other">
        <div className="card__tile__title"><TrackIcon className="mr__15" /> Clients by Status</div>
        <ul className="clients__status__list">
          <li onClick={() => filterClient("Call Scheduled")}>
            <span>
              Call Scheduled ({ callScheduledCount ? callScheduledCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Green")}>
            <span>
              Green ({ grrenCount ? grrenCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Green - Actively Engaged")}>
            <span>
              Green - Actively Engaged ({ greenActivilyEngCount ? greenActivilyEngCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Green - Not Very Engaged")}>
            <span>
              Green - Not Very Engaged ({ greenNotVeryEngCount ? greenNotVeryEngCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Green - Somewhat Engaged")}>
            <span>
              Green - Somewhat Engaged ({ greenSomeWhatEngCount ? greenSomeWhatEngCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Never Had A Call")}>
            <span>
              Never Had A Call ({ neverHadACallCount ? neverHadACallCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Online-New")}>
            <span>
              Online-New ({ onlineNewCount ? onlineNewCount.toLocaleString() : 0 })
            </span>
          </li>
          <li onClick={() => filterClient("Trying To Schedule")}>
            <span>
              Trying To Schedule ({ tryingToScheduleCount ? tryingToScheduleCount.toLocaleString() : 0 })
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
