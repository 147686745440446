/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { get, map, filter } from 'lodash'
import { Spin, Alert } from 'antd';
import moment from 'moment';
import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import NumberFormat from 'react-number-format';
import 'react-phone-input-2/dist/style.css'

import TopNavigation from '../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon } from '../../../../components/icons'
import Validation from '../../../../config/validation'

import './styles.scss'
import { SUCCESS } from '../../../../constants/phase';
import Loader from '../../../../components/Loader';

const cookies = new Cookies()

const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}

export default class AddClientComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      countryCode: '+1',
      assignedStrategist: [],
      isLoading: false,
      assignedSuburbs: [],
      addUpdateClientErrorMessage: ''
    }
  }
  componentDidMount() {
    this.props.getFilterParams()
  }
  static getDerivedStateFromProps(props, state) {
    let states = {...state}
    if (props.addUpdateClientPhase === SUCCESS) {
      if (props.addUpdateClientErrorPhase === SUCCESS) {
        states = { ...states, addUpdateClientErrorMessage: props.addUpdateClientError, isLoading: false }
        props.clearClientPhase()
      } else {
        props.clearClientPhase()
        props.history.push('/strategist/clients/all-clients')
      }
    }
    return states
  }
  // submit data and create/Update new Appointment
  handleSubmit = (values) => {
    const currentUser = cookies.get('user')
    if (currentUser) {
      const data = {
        phone_mobile: get(values, 'primaryMobileNumber', ''),
        client_email: get(values, 'primaryEmail', '').toLowerCase(),
        time_zone: moment.tz.guess(),
        suburb_arr: get(values, 'assignedSuburbs', []),
        strategist_arr: get(values, 'assignedStrategist', []),
        price_bracket_id: get(values, 'priceRange', ''),
        status: get(values, 'pipelineStage', ''),
        source: get(values, 'source', ''),
        id: get(values, 'client_id', ''),
        client_full_name: `${get(values, 'primaryFirstName', '')} ${get(values, 'primaryLastName', '')}`,
        signup_source: get(values, 'signupSource', 'sbj'),
        secondary_name: `${get(values, 'secondryFirstName', '')} ${get(values, 'secondryLastName', '')}`,
        secondary_email: get(values, 'secondaryEmail', '').toLowerCase(),
        secondary_phone_email: get(values, 'secondaryMobileNumber', ''),
        country_code: get(values, 'primaryCountryCode', ''),
        secondary_country_code: get(values, 'secondaryCountryCode', ''),
        rental_budget: get(values, 'rentalPrice', ''),
        current_user_name: `${currentUser.first_name} ${currentUser.last_name}`,
        current_user_role: `${currentUser.role}`,
        client_first_name: `${get(values, 'primaryFirstName', '')}`,
        client_last_name:  `${get(values, 'primaryLastName', '')}`,
        secondary_first_name: `${get(values, 'secondryFirstName', '')}`,
        secondary_last_name: `${get(values, 'secondryLastName', '')}`,

      }
     this.setState({ isLoading: true }, () => this.props.addUpdateClient(data)) 
    }
  }

  handleCodeChange = (event) => this.setState({ countryCode: `+${event}` });

  handleChange = ({ target }, setFieldValue) => {
    const { name, value } = event.target
    this.setState({ [name]: value.trim() })
    setFieldValue(name, value);
  }

  handleMultiSelectionOption = (options, event, setFieldValue) => {
    if (options) {
      const selectedOptions = map(options, option => option.value)
      setFieldValue(event, selectedOptions)
      this.setState({ [event]: selectedOptions })
    }
  }

  handleRouteChange = () => this.props.history.push('/strategist/clients');

  render() {
    const { filterParams, user } = this.props
    const { addUpdateClientErrorMessage, isLoading } = this.state
    const statusOptions = map(get(filterParams, 'data.statuses', []), (status, index) => (
      <option key={index} value={status.name}>{status.name}</option>
    ));
    let prices = get(filterParams, 'data.price_brackets', [])
    const priceOptions = prices.map((pricesval, priceindx) => {
      let priceRange = `$${pricesval.price_min.toLocaleString()} - $${pricesval.price_max.toLocaleString()}`
      if(pricesval.price_min.toLocaleString() == 0){
        priceRange = `$${pricesval.price_min.toLocaleString()}`
      }
      return (
        <option key={priceindx} value={pricesval.id}>{priceRange}</option>
      )
    
    });
    const suburbs = map(get(filterParams, 'data.suburbs', []), (suburb, index) => {
      // <option key={index} value={suburb.id}>{suburb.name}</option>
      return { label: suburb.name, value: suburb.id }
    });
    const preAuth = cookies.get('i_user')
    let allStrategists = get(filterParams, 'data.strategists', [])
    if (preAuth && (preAuth.role !== 'superadmin' && preAuth.role !== 'admin')) {
      allStrategists = filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
    } else if (!preAuth && user && user.role == 'strategist') {
      allStrategists = filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
    }
    const strategistOptions = map(allStrategists, (strategist, index) => {
      // <option key={index} value={strategist.id}>{strategist.strategist_name}{strategist.role === 'former-strategist-inactive' ? "(inactive)" : ''}</option>
      return { label: `${strategist.strategist_name}${strategist.role === 'former-strategist-inactive' ? "(inactive)" : ''}`, value: strategist.id }
    });
    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button className="btn btn-back" onClick={this.handleRouteChange}><BackArrowIcon className="arrow-icon" />Back to Clients</button>
                  </div>
                  <div className="col-xs-6 col-md-8">
                  </div>
                </div>
              </div>
              <Spin size="large" spinning={isLoading} indicator={<Loader />}>
                <div className="row">
                  <div className="col-md-2">
                    <h1 className="title__section">Add Client</h1>
                  </div>
                  
                    <div className="col-md-10">
                    {addUpdateClientErrorMessage ? <Alert
                        message="Error"
                        description={addUpdateClientErrorMessage}
                        type="error"
                        showIcon
                        closable
                      /> : ''}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          pipelineStage: "",
                          assignedStrategist: [],
                          priceRange: "",
                          rentalPrice: "",
                          primaryFirstName: "",
                          primaryLastName: "",
                          primaryMobileNumber: "",
                          primaryEmail: "",
                          secondryFirstName: "",
                          secondryLastName: "",
                          secondaryMobileNumber: "",
                          secondaryEmail: "",
                          signupSource: "sbj",
                          assignedSuburbs: []
                        }}
                        validationSchema={Validation.createClient}
                        onSubmit={(values, { setSubmitting }) => {
                          // setSubmitting(true)
                          this.handleSubmit(values)
                        }}
                      >
                        {({
                          values,
                          errors,
                          isSubmitting,
                          setFieldValue
                        }) => (
                            <Form noValidate>
                              <div className="card">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          as="select"
                                          className="form-control custom-select material-textfield-input textfield-input-lg"
                                          name="pipelineStage"
                                        >
                                          <option value="">Select Pipeline Stage</option>
                                          {statusOptions}
                                        </Field>
                                        <label className="material-textfield-label label-lg">Pipeline Stage <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="pipelineStage" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                                          <ReactMultiSelectCheckboxes
                                            styles={multiSelectStyles}
                                            width="100%"
                                            name="assignedStrategist"
                                            placeholderButtonLabel="Select"
                                            options={strategistOptions}
                                            onChange={(event) => this.handleMultiSelectionOption(event, 'assignedStrategist', setFieldValue)}
                                          />
                                          <label className="multiselect__checkboxes__field__label">Assigned Strategist(s) <span className="text-danger">*</span></label>
                                        </div>
                                        <ErrorMessage className="invalid-feedback" name="assignedStrategist" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          as="select"
                                          className="form-control custom-select material-textfield-input textfield-input-lg"
                                          name="priceRange"
                                        >
                                          <option value="">Select Price Range</option>
                                          {priceOptions}
                                        </Field>
                                        <label className="material-textfield-label label-lg">Price Range <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="priceRange" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <NumberFormat
                                          name="rentalPrice"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          value={values.rentalPrice}
                                          onChange={({target}) => setFieldValue('rentalPrice', target.value.replace(/\D/g, ''))}
                                          thousandSeparator={true}
                                          prefix="$"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Rental Price (monthly)</label>
                                        <ErrorMessage className="invalid-feedback" name="rentalPrice" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="primaryFirstName"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Primary First Name <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="primaryFirstName" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="primaryLastName"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Primary Last Name <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="primaryLastName" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <PhoneInput
                                          defaultCountry={'us'}
                                          value={this.state.primaryCountryCode}
                                          autoFormat={false}
                                          disableAreaCodes={true}
                                          enableLongNumbers={false}
                                          onChange={(event) => {
                                            this.setState({ primaryCountryCode: `+${event}` })
                                            setFieldValue('primaryCountryCode', `+${event}`)
                                          }}
                                          buttonClass="flag-textfield"
                                          required
                                        />
                                        <NumberFormat
                                          format="(###) ### #### #####"
                                          value={this.state.primaryMobileNumber}
                                          name="primaryMobileNumber"
                                          className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                          onChange={(event) => this.handleChange(event, setFieldValue)}
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Primary Mobile Number <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="primaryMobileNumber" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="primaryEmail"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Primary Email<span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="primaryEmail" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="secondryFirstName"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Secondary First Name</label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="secondryLastName"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Secondary Last Name</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <PhoneInput
                                          defaultCountry={'us'}
                                          value={this.state.secondaryCountryCode}
                                          autoFormat={false}
                                          disableAreaCodes={true}
                                          enableLongNumbers={false}
                                          onChange={(event) => {
                                            this.setState({ secondaryCountryCode: `+${event}` })
                                            setFieldValue('secondaryCountryCode', `+${event}`)
                                          }}
                                          buttonClass="flag-textfield"
                                          required
                                        />
                                        <NumberFormat
                                          format="(###) ### #### #####"
                                          value={this.state.secondaryMobileNumber}
                                          name="secondaryMobileNumber"
                                          className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                          onChange={(event) => this.handleChange(event, setFieldValue)}
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Secondary Mobile Number</label>
                                        <ErrorMessage className="invalid-feedback" name="secondaryMobileNumber" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="secondaryEmail"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Secondary Email</label>
                                        <ErrorMessage className="invalid-feedback" name="secondaryEmail" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          as="select"
                                          className="form-control custom-select material-textfield-input textfield-input-lg"
                                          name="signupSource"
                                        >
                                          <option value="sbj">Suburban Jungle</option>
                                          <option value="ubj">Urban Jungle</option>
                                        </Field>
                                        <label className="material-textfield-label label-lg">Signup Source</label>
                                        <ErrorMessage className="invalid-feedback" name="signupSource" component="div" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                                          <ReactMultiSelectCheckboxes
                                            styles={multiSelectStyles}
                                            width="100%"
                                            name="assignedSuburbs"
                                            placeholderButtonLabel="Select"
                                            options={suburbs}
                                            onChange={(event) => this.handleMultiSelectionOption(event, 'assignedSuburbs', setFieldValue)}
                                          />
                                          <label className="multiselect__checkboxes__field__label">Suburb/City <span className="text-danger">*</span></label>
                                        </div>
                                        <ErrorMessage className="invalid-feedback" name="assignedSuburbs" component="div" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group material-textfield">
                                    <Field
                                      component="textarea"
                                      type="text"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      rows="4"
                                      name="source"
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Source</label>
                                    <ErrorMessage className="invalid-feedback" name="source" component="div" />
                                  </div>
                                  <button className="btn btn__btn btn-dark btn__lg w__100" type="submit">Save</button>
                                </div>
                              </div>
                            </Form>
                          )}
                      </Formik>
                    </div>
                </div>
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
