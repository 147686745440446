import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import { getClientAgent, getClientAgentMessage } from '../../store/client/duck'

import {getChatHistory} from '../../store/chat/duck'
import {getAgentAvailability, 
        addAgentAppointment,
        clearPhase,
        shareClientInfo,
        removeAgentFromTown,
        createAgentAppointment
       } from '../../store/agent/duck'
import ClientChatCenterForm from './component'
const ClientChatCenter = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    agentData: state.client.agentData,
    getAgentPhase: state.client.getAgentPhase,
    getAgentLastMessagePhase: state.client.getAgentLastMessagePhase,
    getAgentLastMessageData: state.client.getAgentLastMessageData,
    rxError: state.user.error,
    chatHistoryData: state.chat.chatHistoryData,
    phase: state.user.phase,
    availabilityData: state.agent.availabilityData,
    addAppointmentPhase: state.agent.addAppointmentPhase,
    shareClientInfoPhase: state.agent.shareClientInfoPhase,
    shareClientInfoData: state.agent.shareClientInfoData,
    removeAgentTownPhase: state.agent.removeAgentTownPhase,
    agentAppPhase: state.agent.agentAppPhase,
    agentAppData: state.agent.agentAppData
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getClientAgent,
    getChatHistory,
    getAgentAvailability,
    addAgentAppointment,
    clearPhase,
    getClientAgentMessage,
    shareClientInfo,
    removeAgentFromTown,
    createAgentAppointment
  }
)(ClientChatCenterForm)
export default ClientChatCenter
