import React, { useState } from 'react'
import { Drawer } from 'antd'
import { get } from 'lodash'
import TownsRanking from './TownsRanking/container'
import AgentRanking from './AgentRanking/container'
import TextClient from './TextClient/container'
import AgentClientChat from './AgentClientChat/container'
import ChatWithClient from './ChatWithClient/container'
import MessageNoteForAgent from './MessageNoteForAgent/container'
import InternalNotes from './InternalNotes/container'

import {
  CaretRightIcon,
  BrainIcon,
  RankingIcon,
  NotesIcon,
  EmailsIcon,
  BubbleChatIcon,
  TextChatIcon }
from '../../../../../components/icons'

import './styles.scss'


export default function ArtificialIntelligenceComponent(props) {
  const [drawerType, setDrawerType] = useState("")

  const [visible, setVisible] = useState(false)
  
  const handleOpenDrawer = (drawerType) => {
    setDrawerType(drawerType)
    setVisible(true)
  }
  
  const onClose = () => {
    setDrawerType('')
    setVisible(false)
  }

  const handleViewEmailRoute = () => props.history.push({
    pathname: `/strategist/emails/${props.match.params.id}`,
    state: { prevRoute: get(props, 'history.location.state.prevRoute', '')}
  })
  
  const handleAIAnswerRoute = () => props.history.push({
    pathname: `/strategist/ai/${props.match.params.id}`,
    state: { prevRoute: get(props, 'history.location.state.prevRoute', '')}
  })

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="card__tile" onClick={handleAIAnswerRoute}>
            <div className="card__tile__icon green">
              <BrainIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Client Profile</div>
              <div className="card__tile__subtitle">View client responses to questionnaire</div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
          {/*<div className="card__tile" onClick={() => handleOpenDrawer("agentRanking")}>*/}
          {/*  <div className="card__tile__icon green">*/}
          {/*    <RankingIcon />*/}
          {/*  </div>*/}
          {/*  <div className="card__tile__content">*/}
          {/*    <div className="card__tile__title">Agents Ranking for this deal</div>*/}
          {/*    <div className="card__tile__subtitle">View how the client ranked their agents  </div>*/}
          {/*  </div>*/}
          {/*  <div className="card__tile__arrow"><CaretRightIcon /> </div>*/}
          {/*</div>*/}
          {/*<div className="card__tile" onClick={() => handleOpenDrawer("townRanking")}>*/}
          {/*  <div className="card__tile__icon green">*/}
          {/*    <RankingIcon />*/}
          {/*  </div>*/}
          {/*  <div className="card__tile__content">*/}
          {/*    <div className="card__tile__title">Towns Ranking for this deal </div>*/}
          {/*    <div className="card__tile__subtitle">See how the clients ranked the towns</div>*/}
          {/*  </div>*/}
          {/*  <div className="card__tile__arrow"><CaretRightIcon /> </div>*/}
          {/*</div>*/}
          <div className="card__tile" onClick={() => handleOpenDrawer("agentClientChat")}>
            <div className="card__tile__icon green">
              <BubbleChatIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Agent & Client Chat</div>
              <div className="card__tile__subtitle">See a history of all the chats between the client and agent</div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
          <div className="card__tile" onClick={() => handleOpenDrawer("internalNotes")}>
            <div className="card__tile__icon green">
              <NotesIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Internal Notes</div>
              <div className="card__tile__subtitle">See and write notes about the clients </div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card__tile" onClick={handleViewEmailRoute}>
            <div className="card__tile__icon green">
              <EmailsIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">View Emails</div>
              <div className="card__tile__subtitle">View all the emails between you and the client </div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
          <div className="card__tile" onClick={() => handleOpenDrawer("textClient")}>
            <div className="card__tile__icon green">
              <TextChatIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Text Client</div>
              <div className="card__tile__subtitle">Send the client a text message</div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
          <div className="card__tile" onClick={() => handleOpenDrawer("chatClient")}>
            <div className="card__tile__icon green">
              <BubbleChatIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Chat Client</div>
              <div className="card__tile__subtitle">Send the client a chat</div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
          <div className="card__tile" onClick={() => handleOpenDrawer("agentLocal")}>
            <div className="card__tile__icon green">
              <BubbleChatIcon />
            </div>
            <div className="card__tile__content">
              <div className="card__tile__title">Message/ Note For Agent(s)/Locals</div>
              <div className="card__tile__subtitle">Send agent a message/note</div>
            </div>
            <div className="card__tile__arrow"><CaretRightIcon /> </div>
          </div>
        </div>
      </div>

      <Drawer
        placement="right"
        closable={false}
        width="100%"
        onClose={onClose}
        visible={visible}
        className="drawer__main"
      >
        {drawerType === "agentRanking" && <AgentRanking closeDrawer={onClose} {...props} />}
        {drawerType === "townRanking" && <TownsRanking closeDrawer={onClose} {...props} />}
        {drawerType === "textClient" && <TextClient closeDrawer={onClose} {...props} />}
        {drawerType === "agentClientChat" && <AgentClientChat closeDrawer={onClose} {...props} />}
        {drawerType === "chatClient" && <ChatWithClient closeDrawer={onClose} {...props} />}
        {drawerType === "agentLocal" && <MessageNoteForAgent closeDrawer={onClose} {...props} />}
        {drawerType === "internalNotes" && <InternalNotes closeDrawer={onClose} {...props} />}
      </Drawer>
    </div>
  )
}
