import React, { PureComponent /*, PropTypes*/ } from 'react'
import { DatePicker, Spin, message } from 'antd'
// import TopNavigation from '../../../../components/TopNavigation/AdministrationTopNavigation/container'
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, DownCaretIcon } from '../../../../components/icons'
import './styles.scss'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import _ from 'lodash'
import Loader from '../../../../components/Loader'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { logoutInvalidRequest } from '../../../../utils/helper'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const API_HOSTNAME = process.env.API_HOSTNAME

const cookies = new Cookies()
let _user = ''

export default class AdminEditDealComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      client_full_name: '',
      agent_full_name: '',
      agent_firm_name: '',
      strategist_full_name: '',
      client_id: 0,
      agent_id: 0,
      strategist_id: 0,
      dealType: "sales",
      expected_close_date: '',
      lease_start_date: '',
      sales_price: '',
      buy_side_commission: '',
      misc_fee: '',
      total_check_received: '',
      check_received: '',
      date_misc_fees: '',
      date_approved_of_difference: '',
      bdSourceData: [],
      strategistArrData: [],
      isLoading: true,
      allData: [],
      firm_name: '',
      explanation: '',
      checkCopy: ''
    }
  }

  componentDidMount = async () => {
    const { location } = this.props
    _user = cookies.get('user')
    const jwtToken = cookies.get('Authorization')
    const deal_id = this.props.match.params.id && this.props.match.params.id
    const data = {
      deal_id: parseInt(deal_id)
    }
    const res = await axios({
      method: 'POST',
      url: `${HOSTNAME_V2}/get_admin_account_report_deal`,
      'headers': { 'Authorization': jwtToken },
      data: data
    }).catch(logoutInvalidRequest)
    const getDealData = _.get(res, 'data.data', {})
    if (getDealData && getDealData !== undefined && getDealData !== '' && getDealData[0]) {
      let clientAllData = getDealData[0]
      let strData = getDealData[1]
      let bdData = getDealData[2]
      this.setState({
        isLoading: false,
        dealType: (clientAllData.deal_type === 0) ? 'sales' : 'rentals',
        expected_close_date: clientAllData.expected_close_date || '',
        lease_start_date: clientAllData.lease_start_date || '',
        sales_price: clientAllData.amount,
        buy_side_commission: clientAllData.buy_side_commission,
        misc_fee: clientAllData.sj_comission,
        total_check_received: clientAllData.total_check_received,
        checkCopy: clientAllData.checkCopy,
        check_received: clientAllData.check_received || '',
        date_misc_fees: clientAllData.date_misc_fees,
        date_approved_of_difference: clientAllData.date_approved_of_difference || '',
        client_full_name: clientAllData.client_full_name,
        agent_firm_name: clientAllData.agent_firm_name,
        firm_name: clientAllData.firm_name !== '' ? clientAllData.firm_name : clientAllData.agent_firm_name,
        explanation: clientAllData.explanation || '',
        bdSourceData: bdData,
        strategistArrData: strData,
        allData: getDealData[0]
      })
    }
  }

  componentDidUpdate() {
    const { accountingEditDealPhase, accountingEditDealData, clearReportV2Phase } = this.props
    if (accountingEditDealPhase === "success" && accountingEditDealData.status === true) {
      clearReportV2Phase()
      this.setState({ isLoading: false })
      message.success(`Deal Saved...`);
      // this.props.history.push({ pathname: '/administration/dashboard' })
    }
    if (accountingEditDealPhase === 'error') {
      clearReportV2Phase()
      this.setState({ isLoading: false })
      message.error(`Something went wrong...`);
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleFeeChange(strategist, e) {
    var straIndex = _.findIndex(this.state.strategistArrData, ['strategist_id', strategist.strategist_id])
    var strategistData = this.state.strategistArrData[straIndex]
    strategistData.strategist_fee = e.target.value
    strategistData.isUpdate = true
  }

  handleFeeDate(client, date1, date2) {
    var straIndex = _.findIndex(this.state.strategistArrData, ['strategist_id', client.strategist_id])
    var strategistData = this.state.strategistArrData[straIndex]
    strategistData.strategist_fee_created = moment(date1).format()
    strategistData.isUpdate = true
  }

  handleBizChange(biz, client_id, e) {
    var indexBiz = _.findIndex(this.state.bdSourceData, ['id', biz.id])
    var dataBiz = this.state.bdSourceData[indexBiz]
    dataBiz.business_development_fee = e.target.value
    dataBiz.isUpdate = true
  }

  handleBDDate(client, date1, date2) {
    var bdIndex = _.findIndex(this.state.bdSourceData, ['id', client.id])
    var businessData = this.state.bdSourceData[bdIndex]
    businessData.bd_created_date = moment(date1).format()
    businessData.isUpdate = true
  }

  onDealSelect(e) {
    this.setState({ dealType: e.target.value })
  }

  dateClick(val, date, dateString) {
    if (val === 'close_date') {
      this.setState({ expected_close_date: moment(date._d).format('YYYY-MM-DD hh:mm') })
    } else if (val === 'check_received') {
      this.setState({ check_received: moment(date._d).format('YYYY-MM-DD hh:mm') })
    } else if (val === "lease_date") {
      this.setState({ lease_start_date: moment(date._d).format('YYYY-MM-DD hh:mm') })
    } else if (val === 'date_misc_fees') {
      this.setState({ date_misc_fees: moment(date._d).format('YYYY-MM-DD hh:mm') })
    } else if (val === "date_approved_of_difference") {
      this.setState({ date_approved_of_difference: moment(date._d).format('YYYY-MM-DD hh:mm') })
    }
  }

  handleFileUpload(e) {
    var _URL = window.URL || window.webkitURL;
    let files = e.target.files[0]
    var img = new Image();
    let self = this
    let reader = new FileReader();
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files);
      img.onload = function () {
        self.setState({ file: files })
        self.setState({ checkCopy: reader.result })
      }
    }
    var read = reader.readAsDataURL(files)
  }

  submitData(e) {
    e.preventDefault();
    this.setState({ isLoading: true })
    const { dealType, expected_close_date, sales_price, buy_side_commission, misc_fee, total_check_received, check_received, date_misc_fees, date_approved_of_difference, bdSourceData, strategistArrData, firm_name, agent_firm_name, explanation, lease_start_date } = this.state

    const deal_id = this.props.match.params.id && this.props.match.params.id
    const data = this.state.allData
    const dealData = {
      dealType: dealType,
      sales_price: parseInt(sales_price.toString().replace(/\D/g, '')),
      buy_side_commission: parseFloat(buy_side_commission),
      sj_comission: parseInt(misc_fee.toString().replace(/\D/g, '')),
      total_check_received: total_check_received && parseInt(total_check_received.toString().replace(/\D/g, '')),
      check_received: check_received || '',
      date_misc_fees: date_misc_fees || '',
      date_approved_of_difference: date_approved_of_difference || '',
      firm_name: firm_name,
      agent_firm_name: agent_firm_name,
      explanation: explanation,
      client_id: parseInt(data.client_id),
      deal_id: parseInt(deal_id),
      agent_id: data.agent_id ? parseInt(data.agent_id) : 0,
      expected_close_date: expected_close_date || '',
      lease_start_date: lease_start_date || '',
      bd_source: bdSourceData,
      strategists: strategistArrData
    }

    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', this.state.user_id);
    if (this.state.file) {
      axios.post(`${API_HOSTNAME}/users/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data && response.data.status) {
          this.setState({ checkCopy: response.data.image_url })
          dealData.checkCopy = response.data.image_url;
          this.props.accountReportDealData(dealData)
        }
        else {
          this.props.accountReportDealData(dealData)
        }
      }).catch(logoutInvalidRequest)
    } else {
      this.props.accountReportDealData(dealData)
    }
  }

  render() {
    const { dealType, expected_close_date, sales_price, buy_side_commission, misc_fee, total_check_received, check_received, date_misc_fees, date_approved_of_difference, client_full_name, bdSourceData, strategistArrData, lease_start_date, checkCopy } = this.state
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push('/report/account-report')} className="btn btn-back"><BackArrowIcon className="arrow-icon" />Back Accounting Report </button>
                  </div>
                  <div className="col-xs-6 col-md-8 text-right">
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h1 className="title__section">{`${client_full_name} Deal`}</h1>
                </div>

                <div className="col-md-9">
                  <div className="card">
                    <div className="card-body card-body-square">
                      <div className="form-group material-textfield">
                        <select
                          className="form-control custom__select material-textfield-input textfield-input-lg"
                          name="dealType"
                          value={dealType}
                          onChange={this.onDealSelect.bind(this)}
                        >
                          <option value="sales">Sales</option>
                          <option value="rentals">Rentals</option>
                        </select>
                        <label className="material-textfield-label label-lg">Deal Type</label>
                      </div>

                      {this.state.dealType === "sales" &&
                        <div className="form-group">
                          <div className="btn__follow-up-date follow-up-date-lg">
                            <div className="follow-up-date-label">Expected/Actual Closing Date</div>
                            <DatePicker
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={this.dateClick.bind(this, "close_date")}
                              format="MM/DD/YYYY"
                              allowClear={false}
                              inputReadOnly={true}
                              value={expected_close_date ? moment.utc(expected_close_date) : ''}
                              placeholder="Select Date"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                        </div>
                      }

                      {this.state.dealType !== "sales" &&
                        <div className="form-group">
                          <div className="btn__follow-up-date follow-up-date-lg">
                            <div className="follow-up-date-label">Lease Start Date</div>
                            <DatePicker
                              className="datepicker__follow-up-date"
                              dropdownClassName="datepicker__overlay__follow-up-date"
                              onChange={this.dateClick.bind(this, "lease_date")}
                              inputReadOnly={true}
                              format="MM/DD/YYYY"
                              allowClear={false}
                              // value={lease_start_date ? moment.utc(lease_start_date) : ''}
                              value={lease_start_date ? moment.utc(lease_start_date) : ''}
                              placeholder="Select Date"
                            />
                            <DownCaretIcon className="caret-icon" />
                          </div>
                        </div>
                      }

                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="sales_price"
                          className="form-control material-textfield-input textfield-input-lg"
                          onChange={this.handleChange.bind(this)}
                          thousandSeparator={true}
                          prefix='$'
                          value={sales_price}
                          decimalScale={2}
                          required
                        />
                        <label className="material-textfield-label label-lg">Sales Price </label>
                      </div>

                      <div className="form-group material-textfield">
                        <input
                          name="agent_firm_name"
                          onChange={this.handleChange.bind(this)}
                          className="form-control material-textfield-input textfield-input-lg"
                          value={this.state.agent_firm_name}
                          required
                        />
                        <label className="material-textfield-label label-lg">Firm Name</label>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <NumberFormat
                              name="buy_side_commission"
                              className="form-control material-textfield-input textfield-input-lg"
                              onChange={this.handleChange.bind(this)}
                              thousandSeparator={true}
                              suffix='%'
                              decimalScale={2}
                              value={buy_side_commission}
                              required
                            />
                            <label className="material-textfield-label label-lg">{dealType === "sales" ? "Buy Side Commission" : "Tenant's Agent Commission"}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <NumberFormat
                              name="misc_fee"
                              className="form-control material-textfield-input textfield-input-lg"
                              onChange={this.handleChange.bind(this)}
                              thousandSeparator={true}
                              prefix='$'
                              decimalScale={2}
                              value={misc_fee}
                              required
                            />
                            <label className="material-textfield-label label-lg">Misc. Fee </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Date Misc Fees</div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            format="MM/DD/YYYY"
                            onChange={this.dateClick.bind(this, "date_misc_fees")}
                            allowClear={false}
                            inputReadOnly={true}
                            value={date_misc_fees ? moment.utc(date_misc_fees) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Date Check Received</div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            format="MM/DD/YYYY"
                            onChange={this.dateClick.bind(this, "check_received")}
                            allowClear={false}
                            inputReadOnly={true}
                            placeholder="Select Date"
                            value={check_received ? moment.utc(check_received) : ''}
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <NumberFormat
                              name="total_check_received"
                              className="form-control material-textfield-input textfield-input-lg"
                              onChange={this.handleChange.bind(this)}
                              thousandSeparator={true}
                              prefix='$'
                              decimalScale={2}
                              value={total_check_received}
                              required
                            />
                            <label className="material-textfield-label label-lg">Total Check Received Approved</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <span className="mobile__profile--avtar edit-ps">
                              <img
                                src={
                                  checkCopy !== "" && checkCopy !== null
                                    ? checkCopy
                                    : "img/login-bg-img.png"
                                }
                                alt=""
                              />
                            </span>
                            <form>
                              <span className="btn-file">
                                <span className="editPhotoBtn">Copy uploaded of check</span>
                                <input
                                  type="file"
                                  className="edit__photo--btn"
                                  id="profileImage"
                                  onChange={this.handleFileUpload.bind(this)}
                                />
                              </span>
                              <span className="maximg__label">(Max 1MB allowed)</span>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              name="explanation"
                              onChange={this.handleChange.bind(this)}
                              className="form-control material-textfield-input textfield-input-lg"
                              value={this.state.explanation}
                              required
                            />
                            <label className="material-textfield-label label-lg">Explanation of Difference</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="btn__follow-up-date follow-up-date-lg">
                          <div className="follow-up-date-label">Date Approved of Difference</div>
                          <DatePicker
                            className="datepicker__follow-up-date"
                            dropdownClassName="datepicker__overlay__follow-up-date"
                            format="MM/DD/YYYY"
                            onChange={this.dateClick.bind(this, "date_approved_of_difference")}
                            allowClear={false}
                            inputReadOnly={true}
                            value={date_approved_of_difference ? moment.utc(date_approved_of_difference) : ''}
                            placeholder="Select Date"
                          />
                          <DownCaretIcon className="caret-icon" />
                        </div>
                      </div>

                      {
                        strategistArrData && strategistArrData.map((stra, idx) => {
                          return (
                            <span key={idx}>
                              <label className="label__text__label">Strategist Fee: <span>{stra.strategist_full_name}</span></label>
                              <div className="row">
                                {_user && (_user.role === 'superadmin') && <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name="strategist_amount"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      onChange={this.handleFeeChange.bind(this, stra)}
                                      value={stra.strategist_fee}
                                      prefix='$'
                                      placeholder=""
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      isNumericString={true}
                                    />
                                    <label className="material-textfield-label label-lg">Amount  </label>
                                  </div>
                                </div>}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="btn__follow-up-date follow-up-date-lg">
                                      <div className="follow-up-date-label">Date fee paid</div>
                                      <DatePicker
                                        className="datepicker__follow-up-date"
                                        dropdownClassName="datepicker__overlay__follow-up-date"
                                        format="MM/DD/YYYY"
                                        allowClear={false}
                                        onChange={this.handleFeeDate.bind(this, stra)}
                                        placeholder="Select Date"
                                        defaultValue={((stra.strategist_fee_created !== null) && !(_.isEmpty(stra.strategist_fee_created))) ? moment.utc(stra.strategist_fee_created) : ''}
                                      />
                                      <DownCaretIcon className="caret-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          )
                        })
                      }

                      {
                        bdSourceData && bdSourceData.map((biz, index) => {
                          return (
                            <span key={index}>
                              <label className="label__text__label">BD Referral: <span>{biz.full_name}</span></label>
                              <div className="row">
                                {_user && (_user.role === 'superadmin') && <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name="bd_amount"
                                      className="form-control material-textfield-input textfield-input-lg"
                                      thousandSeparator={true}
                                      prefix='$'
                                      decimalScale={2}
                                      placeholder=""
                                      fixedDecimalScale={true}
                                      value={biz.business_development_fee}
                                      isNumericString={true}
                                      onChange={this.handleBizChange.bind(this, biz, this.state.current_client)}
                                      required
                                    />
                                    <label className="material-textfield-label label-lg">Amount    </label>
                                  </div>
                                </div>
                                }
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="btn__follow-up-date follow-up-date-lg">
                                      <div className="follow-up-date-label">Date referral paid</div>
                                      <DatePicker
                                        className="datepicker__follow-up-date"
                                        dropdownClassName="datepicker__overlay__follow-up-date"
                                        format="MM/DD/YYYY"
                                        onChange={this.handleBDDate.bind(this, biz)}
                                        defaultValue={((biz.bd_created_date !== null) && !(_.isEmpty(biz.bd_created_date))) ? moment.utc(biz.bd_created_date) : ''}

                                        placeholder="Select Date"
                                      />
                                      <DownCaretIcon className="caret-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          )
                        })
                      }


                    </div>
                  </div>
                  <button className="btn btn__btn btn-dark btn__lg w__100" onClick={this.submitData.bind(this)}>Save</button>
                </div>

              </div>
            </div>
          </main>
        </Spin>
      </div>
    )
  }
}
