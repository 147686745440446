import { fetch } from '../../../utils'
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
/*
    //////////////////////////////////
  /// jungler user  apist ///
//////////////////////////////////
*/
// get jungler user list
export const getJunglerUsers = (data) => {

  // map the data to a single level for use in query string
  const queryStringParams = {
    sortKey: data.sortBy.key,
    sortDirection: data.sortBy.type,
    ...data
  }

  delete queryStringParams.sortBy
  
  const url = `${SUBURBANJUNGLE_API_HOST}/admin/users?` +
    new URLSearchParams(queryStringParams).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// create new user for all role
  export const createJunglerUser = (data) => {
  const type = data.id ? 'PUT' : 'POST'
  const url = data.id ? `${SUBURBANJUNGLE_API_HOST}/strategist/agent-applications/${data.id}` : `${SUBURBANJUNGLE_API_HOST}/users`
  return fetch(url, {
    method: type,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload
    })
    .catch((error) => {
      throw error
    })
}
