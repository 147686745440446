import { Record, List } from 'immutable'
import { get } from 'lodash'

import * as types from './action-types'
import { INIT, LOADING, SUCCESS, ERROR } from '../../../constants/phase'

const InitialState = new Record({
  phase: INIT,
  error: null,
  leaveComments: [],
  addLeaveCommentPhase: INIT,
  getLeaveCommentPhase: INIT,
  removeLeaveCommentPhase: INIT,
})

/**
 * Public: Reducer
 */

export const commentReducer = (state = new InitialState(), action = {}) => {
  switch (action.type) {
    /*  Add or create leave comment */
    case types.ADD_LEAVE_COMMENTS: {
      return state.set('error', null).set('addLeaveCommentPhase', LOADING)
    }
    case types.ADD_LEAVE_COMMENTS_SUCCESS: {
      return state.set('error', null).set('addLeaveCommentPhase', SUCCESS)
    }
    case types.ADD_LEAVE_COMMENTS_ERROR: {
      return state.set('error', action.payload.error).set('addLeaveCommentPhase', ERROR)
    }

    /* fetch leave comment by client and town id */
    case types.GET_LEAVE_COMMENTS: {
      return state.set('error', null).set('getLeaveCommentPhase', LOADING)
    }
    case types.GET_LEAVE_COMMENTS_SUCCESS: {
      const comments = get(action, 'payload.data', [])
      return state
        .set('leaveComments', comments)
        .set('error', null)
        .set('getLeaveCommentPhase', SUCCESS)
    }
    case types.GET_LEAVE_COMMENTS_ERROR: {
      return state.set('error', action.payload.error).set('getLeaveCommentPhase', ERROR)
    }

    /* remove leave comment by comment id */
    case types.REMOVE_LEAVE_COMMENT: {
      return state.set('error', null).set('removeLeaveCommentPhase', LOADING)
    }
    case types.REMOVE_LEAVE_COMMENT_SUCCESS: {
      return state.set('error', null).set('removeLeaveCommentPhase', SUCCESS)
    }
    case types.REMOVE_LEAVE_COMMENT_ERROR: {
      return state.set('error', action.payload.error).set('removeLeaveCommentPhase', ERROR)
    }

    /* clear comment phase  */
    case types.CLEAR_COMMENT_PHASE: {
      return state
        .set('error', null)
        .set('addLeaveCommentPhase', INIT)
        .set('getLeaveCommentPhase', INIT)
        .set('removeLeaveCommentPhase', INIT)
    }

    default: {
      return state
    }
  }
}
