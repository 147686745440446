import * as types from './action-types'
/*
    ////////////////////////////////
  /// strategist action ///
///////////////////////////////
*/
// get administor dashboard data
export const getAdministratorDashboardData = (payload) => ({
  type: types.GET_ADMINISTRATION_DASHBOARD_DATA,
  payload,
})
// get closed client per town report
export const getClosedClientsData = (payload) => ({
  type: types.CLOSED_CLIENT_PER_TOWN,
  payload,
})
// get accounting repert data
export const getAccountingReportData = (payload) => ({
  type: types.GET_ACCOUNTING_REPORT,
  payload,
})
// get user by id data
export const getUserById = (payload) => ({
  type: types.GET_USER_BY_ID,
  payload,
})
// get strategist list by client id
export const getClientStrategist = (payload) => ({
  type: types.GET_CLIENT_STRATEGIST_LIST,
  payload,
})
// add update strategist data
export const addUpdateStrategist = (payload) => ({
  type: types.ADD_EDIT_STRATEGIST_CLIENT,
  payload,
})
// create strategist by payload
export const createStrategist = (payload) => ({
  type: types.CREATE_STRATEGIST,
  payload,
})
// clear strategist phase
export const clearStrategistPhase = () => ({
  type: types.CLEAR_STRATEGIST_PHASE,
})
// change client status on strategy
export const changeClientStatus = (payload) => ({
  type: types.CHANGE_CLIENT_STATUS,
  payload,
})
// get bd source repert data
export const getBDSourceReportData = (payload) => ({
  type: types.GET_BDSOURCE_REPORT,
  payload,
})
