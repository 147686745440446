import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import styles from './styles.scss'
import moment from 'moment'
import _ from "lodash";
import $ from 'jquery'
import Select from 'react-select'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export";
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DatePickerCustomInput extends React.Component {
  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class OperationReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      client_strategist:[],
      client_suburb: [],
      client_status:[],
      activePage:1,
      isLoadingData: false,
      sortByKey: '',
      sortByType : 0,
      strategistDropdown: false,
      suburbDropdown: false,
      statusDropdown: false,
      selectedIndex: false,
      fromDate: moment(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: moment(),
      search: '',
    }
  }

  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)

      const operationData = {}
      operationData.strategist_arr = this.state.client_strategist
      operationData.suburb_arr = this.state.client_suburb
      operationData.client_status = this.state.client_status
      operationData.from = this.state.fromDate
      operationData.to = this.state.endDate
      this.props.getOperationList(operationData)
      this.setState({isLoadingData: true})
      this.props.getFilterParams()
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.operationListPhase === "success"){
      this.setState({ isLoadingData: false })
    }
  }

  toggledropDown(val){
    if(val==='filterStrategist'){
      this.setState({strategistDropdown: !this.state.strategistDropdown, statusDropdown : false, suburbDropdown : false})
    }
    if(val==='filterStatus'){
      this.setState({statusDropdown: !this.state.statusDropdown, strategistDropdown : false, suburbDropdown : false})
    }
    if(val==='filterSuburb'){
      this.setState({suburbDropdown: !this.state.suburbDropdown, statusDropdown : false, strategistDropdown : false})
    }
  }

  filterStrategist(id, type) {
    let { client_strategist, client_status , client_suburb } = this.state;
    if(type === 'strategistFilter'){
      if (client_strategist.indexOf(id) === -1) {
        client_strategist.push(id)
      } 
      else {
        client_strategist.splice(client_strategist.indexOf(id), 1)
      }
    }
    if(type === 'statusFilter'){
      if (client_status.indexOf(id) === -1) {
        client_status.push(id)
      } 
      else {
        client_status.splice(client_status.indexOf(id), 1)
      }
    }
    if(type === 'suburbFilter'){
      if (client_suburb.indexOf(id) === -1) {
        client_suburb.push(id)
      } 
      else {
        client_suburb.splice(client_suburb.indexOf(id), 1)
      }
    }
    this.setState({ isLoadingData: true, client_strategist : client_strategist, client_status : client_status, client_suburb : client_suburb })
    const operationData = {}
    operationData.strategist_arr = this.state.client_strategist
    operationData.suburb_arr = this.state.client_suburb
    operationData.client_status = this.state.client_status
    operationData.from = this.state.fromDate
    operationData.to = this.state.endDate
    this.props.getOperationList(operationData)
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const clientdata = {}
    /*clientdata.client_type = this.state.client_type;
    clientdata.client_suburb = this.state.client_suburb;
    clientdata.client_strategist = this.state.client_strategist;
    clientdata.hide_inactive = this.state.hide_inactive;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.sortBy = {key: val, type: sortByType};
    this.props.getClientList(clientdata)*/
    //this.props.getOperationList(clientdata)
  }

  handleFromDate(date) {
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(function(e){
      const operationData = {}
      operationData.from = selectDate
      operationData.to = this.state.endDate
      operationData.strategist_arr = this.state.client_strategist
      operationData.suburb_arr = this.state.client_suburb
      operationData.client_status = this.state.client_status
      this.props.getOperationList(operationData)
    }.bind(this), 300)
    this.setState({fromDate: date})
  }

  handEndDate(date){
    let selectDate =  moment(date._d).format("YYYY-MM-DD HH:mm:ss")
    setTimeout(function(e){
      const operationData = {}
      operationData.to = selectDate
      operationData.from = this.state.fromDate
      operationData.strategist_arr = this.state.client_strategist
      operationData.suburb_arr = this.state.client_suburb
      operationData.client_status = this.state.client_status
      this.props.getOperationList(operationData)
    }.bind(this), 300)
    this.setState({endDate: date})
  }

   onSearch(e){
    this.setState({search:e.target.value})
  }


  render() {

    const {
      filterParams,
      clientData,
      operationListData,
    } = this.props

    let allData = filterParams.data;

    let filterList = '';
    let OperationAllData = operationListData && operationListData.data
    if(OperationAllData){
      filterList = OperationAllData.filter(
        (OperationAllData) =>{
          return (
            (OperationAllData.client_full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1 
          );
        }
      );
    }


    return (
      <div>
        <div className="full__page--header light-bg">
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 col-xs-10">
                  <div className="logo-header">
                      <a><span className="reporting-title">Jungler Analytics</span></a>
                     {/* <span>
                        <select className="reporting-sel">
                          <option>Other Metrics</option>
                          <option>Other Metrics</option>
                          <option>Other Metrics</option>
                        </select>
                      </span>*/}
                      
                  </div>
                </div>
                <div className="col-md-4 col-xs-2">
                  <div className="close-btn">
                      <Link to={'/dashboard'}><img src="img/close.svg"/></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4"><h4 className="reporting-heading">Operations Reporting</h4></div>
            <div className="col-md-8 text-right">
              <div className="dp-wrapper dp-wrapper-edit">
                <label>From</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handleFromDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.fromDate}
                        value={this.state.fromDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dp-wrapper dp-wrapper-edit">
                <label>To</label>
                <div>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker
                        customInput={<DatePickerCustomInput />}
                        onChange={this.handEndDate.bind(this)}
                        showTimeSelect
                        peekNextMonth
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        className="form-control custom-select date__picker">
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
             {/* <button 
                className="reporting__btn">
                Export Data
              </button>*/}

              <ExcelFile element={<button className="reporting__btn">Export Data</button>}>
                <ExcelSheet data={filterList} name="Operation_Report">
                  <ExcelColumn label="Full Name" value="client_full_name"/>
                  <ExcelColumn label="Strategist" value="full_name"/>
                  <ExcelColumn label="Date of Questionnaire" value="questionaire_date"/>
                  <ExcelColumn label="Status PipeLine" value="pipeline_stage"/>
                  <ExcelColumn label="Price" value="budget"/>
                  <ExcelColumn label="Bus Dev Source" value="bus_dev_source"/>
                  <ExcelColumn label="Date of Initial Strategy Call" value="initial_call_date"/>
                  <ExcelColumn label="Number of days between Date of Questionnaire and Date of Initial Call" value="days_bet_initial_call_and_questionarie"/>
                  <ExcelColumn label="Date of first tour" value="initial_tour_date"/>
                  <ExcelColumn label="Number of days between initial call and first tour" value="days_bet_initial_call_and_first_tour"/>
                  <ExcelColumn label="Date of pending" value="pending_date"/>
                  <ExcelColumn label="Number of days between initial call and pending" value="days_bet_initial_call_and_pending"/>
                  <ExcelColumn label="Number of days between date of questionnaire and pending" value="days_bet_questionarie_and_pending"/>
                  <ExcelColumn label="Closing Date" value="closing_date"/>
                </ExcelSheet>
            </ExcelFile>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form className="navbar__header--form">
                <span className="typeahead">
                  <input 
                    onChange={(e)=> this.onSearch(e)}
                    className="reporting-search typeahead-input" 
                    type="text" 
                    name="search" 
                    placeholder="Search Users" />
                </span>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <main className="jungler__page--container">
                <div className="filter__container filter__container--users desktopFilter">
                  <div className="filter__sub--container filter__sub--container-edit">
                    {/*This is for Strategist Filter*/}
                    <li>
                      <div className="custom-select custom-sel-filter" onClick={this.toggledropDown.bind(this, 'filterStrategist')}>Filter by Strategist</div>
                      {this.state.strategistDropdown && 
                        <div className= "sel-outer">
                          {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                           let selectedIndex = false;
                            _.find(this.state.client_strategist, function(o) {
                              if(o === strategistsval.id){
                                selectedIndex = true;
                              } 
                           });
                           return (
                            <li key={strategistsindx} className="custom-select-option">
                              <span className="coverage__checkbox">
                                <label className="custom-checkbox">
                                {strategistsval.strategist_name}
                                <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id, 'strategistFilter')}/>
                                  <span className="checkmark"></span>
                                </label>
                              </span>
                            </li>
                           )
                          })}
                        </div>
                      }
                    </li>

                    {/*This is for Filter By Pipeling State*/}
                    <li>
                      <div className="custom-select custom-sel-filter fullwidth-sel" onClick={this.toggledropDown.bind(this, 'filterStatus')}>Filter By Pipeling State</div>
                      {this.state.statusDropdown && 
                        <div className= "sel-outer">
                          {allData && allData.statuses.map((statussval, statusindx) => {
                           let selectedIndex = false;
                            _.find(this.state.client_status, function(o) {
                              if(o === statussval.name){
                                selectedIndex = true;
                              } 
                           });
                           return (
                            <li key={statusindx} className="custom-select-option">
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {statussval.name}
                              <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,statussval.name, 'statusFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                           )
                          })}
                        </div>
                      }
                    </li>


                    {/*This is for Filter By Markets*/}
                    <li>
                      <div 
                        className="custom-select custom-sel-filter fullwidth-sel">
                        Filter By Markets
                        </div>
                    </li>

                    

                    <li>
                      <div className="custom-select custom-sel-filter" onClick={this.toggledropDown.bind(this, 'filterSuburb')}>Filter by Suburb</div>
                      {this.state.suburbDropdown && 
                        <div className= "sel-outer">
                          {allData && allData.suburbs.map((suburbsval, suburbsindx) => {
                           let selectedIndex = false;
                            _.find(this.state.client_suburb, function(o) {
                              if(o === suburbsval.id){
                                selectedIndex = true;
                              } 
                           });
                           return (
                            <li key={suburbsindx} className="custom-select-option">
                            <span className="coverage__checkbox">
                              <label className="custom-checkbox">
                              {suburbsval.name}
                              <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,suburbsval.id, 'suburbFilter')}/>
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          </li>
                           )
                          })}
                        </div>
                      }
                    </li>

                    <div className="jungler__list">
                       <div className="">
                         <table className="table table-striped">
                            <thead>
                              <tr>
                                <th onClick={this.sortData.bind(this , 'clientName')}>Client Name</th>
                                <th onClick={this.sortData.bind(this , 'strategist')}>Strategist</th>
                                <th>Date of Questionnaire</th>
                                <th onClick={this.sortData.bind(this , 'strategist')}>Status PipeLine</th>
                                <th>Price</th>
                                <th>Bus Dev Source</th>
                                <th>Date of Initial Strategy Call</th>
                                <th>Number of days between Date of Questionnaire and Date of Initial Call</th>
                                <th>Date of first tour</th>
                                <th>Number of days between initial call and first tour</th>
                                <th>Date of pending</th>
                                <th>Number of days between initial call and pending</th>
                                <th>Number of days between date of questionnaire and pending</th>
                                <th>Closing Date</th>
                              </tr>
                            </thead>
                            {this.state.isLoadingData === true ?
                            <tbody>
                                <tr>
                                  <td colSpan="5" className="noClientResults text-center">
                                  <img src="../../img/loader2.svg" />
                                  <h5>Your Operation report is generating, please wait.........</h5>
                                  </td>
                                  </tr>
                            </tbody>
                            :
                            <tbody>
                            {
                            filterList && filterList.length > 0 ?
                              filterList.map((c,index) => {
                              return(
                              <tr className="forcursor" key={index} >
                                {/*----------Client full name--------*/}
                                <td>{c.client_full_name ? c.client_full_name:'N/A'}</td>

                              {/*----------Strategist--------*/}
                                <td>
                                {
                                  c.strategists && c.strategists.map((strategist, idx)=>{
                                    return(
                                      <span key={idx}>{ (idx ? ', ' : '') + strategist.full_name? strategist.full_name:'N/A' }</span>
                                    )
                                  })
                                }
                                </td>

                                {/*----------Questionnaire--------*/}
                                <td>{c.questionaire_date ? moment(c.questionaire_date).format("DD-MM-YYYY") :'N/A' }</td>
                               
                                {/*----------Status PipeLing--------*/}
                                <td>{c.pipeline_stage ? c.pipeline_stage: 'N/A'}</td>
                                
                                {/*----------Price--------*/}
                                <td>{c.budget ? c.budget: 'N/A'}</td>

                                {/*----------Bus Service--------*/}
                                <td>{c.bus_dev_source ? c.bus_dev_source : 'N/A'}</td>

                                {/*--------Date of Initial Strategy Call-----*/}
                                <td>{c.initial_call_date ? moment(c.initial_call_date).format("DD-MM-YYYY"): 'N/A'}</td>

                                {/*------Number of days between Date of Questionnaire and Date of Initial Call---*/}
                                <td>{c.days_bet_initial_call_and_questionarie ? c.days_bet_initial_call_and_questionarie : 'N/A'}</td>

                                {/*-----Date of first tour--*/}
                                <td>{c.initial_tour_date ? c.initial_tour_date: 'N/A'}</td>

                                {/*----Number of days between initial call and first tour--*/}
                                <td>{c.days_bet_initial_call_and_first_tour ? c.days_bet_initial_call_and_first_tour:'N/A'}</td>

                                {/*----Date of pending--*/}
                                <td>{c.pending_date ? moment(c.pending_date).format("DD-MM-YYYY"):'N/A'}</td>

                                {/*----Number of days between initial call and pending------*/}
                                <td>{c.days_bet_initial_call_and_pending ? c.days_bet_initial_call_and_pending :'N/A'}</td>

                                {/*----Number of days between date of questionnaire and pending------*/}
                                <td>{c.days_bet_questionarie_and_pending ? c.days_bet_questionarie_and_pending :'N/A'}</td>
                                
                                {/*----Closing Date------*/}
                                <td>{c.closing_date ? moment(c.closing_date).format("DD-MM-YYYY"):'N/A'}</td>

                              </tr>
                               )
                              }) : 
                              <tr><td colSpan="6" className="noClientResults">No clients found</td></tr> 
                              }
                            </tbody> 
                            }
                        </table>
                       </div>
                   </div>
                 </div>
               </div>
              </main>
              {/*<div className="dashboard-footer text-center">
              Suburban Jungler -Operation Reporting 
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'operationreportlist',  // a unique identifier for this form
  destroyOnUnmount: true,
})(OperationReport)
