import 'rxjs'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import _ from 'lodash'
import Header from './Header'
import Slider from 'react-slick'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

class GenerateTownReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      previewImage: '',
      openSnackbar: false,
      message: '',
      i: null,
      attachId: '',
      imageUrl: '',
      openFeaturedDilog: false,
      openRemoveDilog: false,
      imageId: ''
    }
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  componentWillMount() {
    const { getGeneratedReport, match: { params: { townId } } } = this.props
    getGeneratedReport(townId)
  }

  componentWillReceiveProps(np) {
    const { removeImg, imageUpload, getGeneratedReport, match: { params: { townId } }, pushToWp, history } = np
    if (imageUpload || removeImg) {
      this.setState({
        message: imageUpload ? 'Images Uploaded Successfully' : 'Image has been removed successfully',
        openSnackbar: true,
        openRemoveDilog: false
      }, () => {
        getGeneratedReport(townId)
      })
    }
    if (pushToWp && !pushToWp.success) {
      this.setState({ openSnackbar: true, message: pushToWp.message })
    }
    if (pushToWp && pushToWp.success) {
      history.push('/town-report')
    }
  }

  componentWillUnmount() {
    this.props.clearTownPhase()
  }

  handleRequestClose() {
    this.setState({ openSnackbar: false }, () => {
      this.props.clearTownPhase()
    })
  }

  handleUserImage(e) {
    const { uploadImage, match: { params: { townId } } } = this.props
    const input = e.target
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = (r) => {
        this.setState({
          previewImage: r.target.result,
          previewStatus: 'ViewPatientOnboarding_previewShowing'
        })
        const formdata = new FormData()
        formdata.append('image', input.files[0])
        formdata.append('townId', townId)
        uploadImage(formdata)
      }
      reader.readAsDataURL(input.files[0])
    }
  }

  removeImage() {
    const { removeImage, match: { params: { townId } }, disableBtn } = this.props
    const { imageId, attachId, imageUrl } = this.state
    const data = {
      town_id: townId,
      id: imageId,
      url: imageUrl,
      wp_attach_id: attachId
    }
    removeImage(data)
  }

  handleClose = () => {
    this.setState({ i: null, imageUrl: '', attachId: '', openFeaturedDilog: false, openRemoveDilog: false })
  }

  handleEmptyImage() {
    if (this.state.previewImage) {
      this.setState({ previewImage: '', previewStatus: '' })
    }
  }

  townImages(report) {
    return report && report.images.map((image, i) => {
      return(
        <div className="col-md-4 town-img-outer" key={i}>
          <div className={this.state.i === i ? 'town-image-select' : 'town-images'}>
            <img
              src={image.url}
              onClick={() => { !image.featured && this.setState({ i, imageUrl: image.url, attachId: image.attach_id, openFeaturedDilog: true }) }}
            />
          </div>
          {image.featured && <span className="featured_img">Featured</span>}
          <span className="remove_img" onClick={() => this.setState({ openRemoveDilog: true, imageId: image.id, attachId: image.attach_id, imageUrl: image.url })}>Remove</span>
        </div>
      )
    })
  }

  pushToWordpress() {
    const { pushToWordpress, match: { params: { townId } } } = this.props
    const data = {
      town_id: townId,
      reSynch: false
    }
    pushToWordpress(data)
  }

  render() {
    const { history, generateReportPayload, report, uploadProgress, disableBtn } = this.props
    const processed = []
    if (report) {
      for (var key in report.processed) {
        processed.push({ key, value: report.processed[key] })
      }
    }
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }]
    }
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton label="Yes" primary={true} keyboardFocused={true} onClick={() => this.setState({ openFeaturedDilog: false })} />
    ]
    const actions2 = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton label="Yes" primary={true} keyboardFocused={true} onClick={() => this.removeImage()} />
    ]
    return (
      <section className="town-report">
        <Header title="Generate Town Report"/>
        <div className="container">
          <div className="row town-table">
            <div className="col-md-6">
              <h3>Create a New Town Report</h3>
              <p>Search and select the correct town for which you want to create a report</p>
            </div>
            <div className="col-md-6">
              <input type="text" disabled defaultValue={_.get(report,'processed.town_name', '')} placeholder="Enter Town Name and State" className="custom_search" />
              <h5>Selected Town</h5>
              <br />
              <p>{_.get(report, 'town.name', '')}</p>
              <br />
              <h5>Upload Images</h5>
              <div className="row">
                <div className="col-md-4">
                  {!uploadProgress ?
                    <div className="dashed-border" onClick={() => document.getElementById('fileinput').click()}>
                    <input
                      type="file"
                      id="fileinput"
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      onClick={(evt) => this.handleEmptyImage(evt)}
                      onChange={(evt) => this.handleUserImage(evt)}
                    />
                    <h5 className="drag-box">
                      Drag & Drop
                      <br />
                      or
                      <br />
                      <span className="Upload_txt">Upload</span>
                    </h5>
                  </div> :
                    <img className="image-loader" src="/img/loader2.svg"/>
                  }
                </div>
                <div className="col-md-8">
                  <Slider {...settings}>
                    {this.townImages(report)}
                  </Slider>
                </div>
              </div>
              <h5 className="data_checklist">Data Checklist</h5>
              {processed.map((p, i) => {
                return (<div className="row" key={i}>
                  <div className="col-md-12">
                    {p.value === 1
                      ? <img className="town-marks" src="img/bullet-right.svg" />
                      : <img className="town-marks" src="img/remove-list.svg" />
                    }
                    <span className="listing_txt">{p.key}</span>
                  </div>
                </div>)
              })}
              <br />
              <br />
              {disableBtn ?
                <img className="push-loader" src="/img/loader2.svg"/> :
                <button disabled={!report} className="pull-right town-button" onClick={() => this.pushToWordpress()}>
                  Push to Wordpress
                </button>
              }
            </div>
            <Snackbar open={this.state.openSnackbar} message={this.state.message} autoHideDuration={2000} onRequestClose={this.handleRequestClose} />
            <Dialog
              actions={actions}
              modal={false}
              open={this.state.openFeaturedDilog}
              onRequestClose={this.handleClose}
            >
              Do you want to mark it as featured?
            </Dialog>
            <Dialog
              actions={actions2}
              modal={false}
              open={this.state.openRemoveDilog}
              onRequestClose={this.handleClose}
            >
              Do you want to remove the image?
            </Dialog>
          </div>
        </div>
      </section>
    )
  }
}

export default reduxForm({
  form: 'GenerateTownReport', // a unique identifier for this form
  destroyOnUnmount: true
})(GenerateTownReport)
