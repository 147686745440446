import { connect } from 'react-redux'
import { loginUser, fetchUser, getNotifications, clearNotification } from '../../store/user/duck'
import { getClientList, getFilterParams, addUpdateClient, clearPhase, impersonateUser } from '../../store/dashboard/duck'
import { repushToWordpress, fetchTownReport, generateReport, clearTownPhase, markFeatured, removeImage, getGeneratedReport, pushToWordpress, uploadImage, uploadVideo, editReport, communityDataByTownId, editCommunityData, editPrivateSchool, editPublicSchool, deletePrivateSchool, deletePublicSchool, addPrivateSchool,addPublicSchool, synchCommunity, addCommute, updateTownName, deleteTownById, addGooglePlace, editGooglePlace, addResearch, getResearch, deleteResearch, addResearchFile
     } from '../../store/townReport/duck'
import ClientsListForm from './component'
import { stat } from 'fs';
const ClientsList = connect(
  // Map state to props
  state => ({
    dataPhase: state.dashboard.dataPhase,
    filterParams: state.dashboard.filterParams,
    user: state.user.users,
    clientData: state.dashboard.clientData,
    isSubmitting: state.townReport.isSubmitting,
    error: state.dashboard.error,
    clearNotificationPhase: state.user.clearNotificationPhase,
    towns: state.townReport.towns,
    generateReportPayload: state.townReport.generateReport,
    disableBtn: state.townReport.disableBtn,
    editReportSuccess: state.townReport.editReportSuccess,
    report: state.townReport.report,
    imageUpload: state.townReport.imageUpload,
    uploadProgress: state.townReport.uploadProgress,
    featured: state.townReport.featured,
    pushToWp: state.townReport.pushToWp,
    removeImg: state.townReport.removeImage,
    townsCount: state.townReport.townsCount,
    suburbsData: state.townReport.suburbsData,
    communityPhase: state.townReport.communityPhase,
    communityData: state.townReport.communityData,
    editCommunityPhase: state.townReport.editCommunityPhase,
    editPrivatePhase: state.townReport.editPrivatePhase,
    editPublicPhase: state.townReport.editPublicPhase,
    deletePrivatePhase: state.townReport.deletePrivatePhase,
    deletePublicPhase: state.townReport.deletePublicPhase,
    addPrivatePhase: state.townReport.addPrivatePhase,
    addPublicPhase: state.townReport.addPublicPhase,
    synchCommunityPhase: state.townReport.synchCommunityPhase,
    addCommutePhase: state.townReport.addCommutePhase,
    updateTownPhase: state.townReport.updateTownPhase,
    townReportPhase: state.townReport.townReportPhase,
    updateTownData: state.townReport.updateTownData,
    addGooglePhase: state.townReport.addGooglePhase,
    addGoogleData: state.townReport.addGoogleData,
    editGooglePhase: state.townReport.editGooglePhase,
    editGoogleData: state.townReport.editGoogleData,
    deleteTownPhase: state.townReport.deleteTownPhase,
    addResearchPhase: state.townReport.addResearchPhase,
    addResearchData: state.townReport.addResearchData,
    getResearchData: state.townReport.getResearchData,
    deleteResearchPhase: state.townReport.deleteResearchPhase,
    researchFilePhase: state.townReport.researchFilePhase,
    researchFileData: state.townReport.researchFileData,
    getResearchPhase: state.townReport.getResearchPhase
}),
  // Map actions to props
  {
    loginUser,
    fetchUser,
    getClientList,
    getFilterParams,
    addUpdateClient,
    clearPhase,
    impersonateUser,
    getNotifications,
    clearNotification,
    fetchTownReport,
    generateReport,
    clearTownPhase,
    getGeneratedReport,
    uploadImage,
    uploadVideo,
    editReport,
    markFeatured,
    pushToWordpress,
    removeImage,
    repushToWordpress,
    communityDataByTownId, 
    editCommunityData,
    editPrivateSchool,
    editPublicSchool,
    deletePrivateSchool,
    deletePublicSchool,
    addPrivateSchool,
    addPublicSchool,
    synchCommunity,
    addCommute,
    updateTownName,
    deleteTownById,
    addGooglePlace,
    editGooglePlace,
    addResearch,
    getResearch,
    deleteResearch,
    addResearchFile
  }
)(ClientsListForm)
export default ClientsList
