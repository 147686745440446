import { connect } from 'react-redux'
// import {loginUser, handleSignOut} from '../../store/user/duck'
import AdminDashboardForm from './component'
const AdminDashboard = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    isSubmitting: state.user.isSubmitting,
    rxError: state.user.error,
    phase: state.user.phase
  }),
  // Map actions to props
  {
    // loginUser,
    // handleSignOut
  }
)(AdminDashboardForm)
export default AdminDashboard
