import React, { PureComponent /*, PropTypes*/ } from 'react';
import { Formik, Form, Field } from 'formik';
import get from 'lodash/get'

import './styles.scss';

const RadioInputField = props => {
  return (
    <Field
      {...props}
      checked={
        get(props.values, props.name, props.noval) === props.valueon
          ? true
          : false
      }
      onChange={e =>
        props.toggleradiofields(
          props.name,
          props.valueon,
          props.values,
          props.setfieldvalue
        )
      }
      className="custom__radiobox__input"
    />
  );
};

export default class EducationOverviewComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  static propTypes = {
    // PropTypes go here
  };

  componentDidMount() {
    // this.props.toggleLoading(true)
  }


  toggleRadioFields = (key, val, values, setFieldValue) => {
    if (values[key] === val) {
      setFieldValue(key, -1);
    } else {
      setFieldValue(key, val);
    }

    this.forceUpdate();
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">Education Info</h1>
          </div>
          <Formik
            enableReinitialize
            initialValues={get(this, "props.aiData", {})}
            onSubmit={data => {
              this.props.updateAiData(data)
            }}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form>
                <div className="col-md-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Language Immersion
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="language_immersion"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="language_immersion"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Full or half day kindergarten{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Full
                              <RadioInputField
                                type="checkbox"
                                name="kindergarten"
                                valueon={'full'}
                                noval={''}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              Half
                              <RadioInputField
                                type="checkbox"
                                name="kindergarten"
                                valueon={'half'}
                                noval={''}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">Busing</label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="busing"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="busing"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Strong special needs program
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="strong_special_needs_program"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="strong_special_needs_program"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          School system type
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Top
                              <RadioInputField
                                type="checkbox"
                                name="schools"
                                valueon={"top"}
                                noval={''}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              Middle
                              <RadioInputField
                                type="checkbox"
                                name="schools"
                                valueon={"middle"}
                                noval={''}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              Low
                              <RadioInputField
                                type="checkbox"
                                name="schools"
                                valueon={"low"}
                                noval={''}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Universal Pre-K{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="universal_pre_k"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="universal_pre_k"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn__btn btn-dark btn__lg w__100"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
