import React, { PureComponent /*, PropTypes*/ } from 'react';
import { Formik, Form, Field } from 'formik';
import get from 'lodash/get';
// import IPropTypes from 'react-immutable-proptypes'

import './styles.scss';

const activities = [
  { label: 'Tennis', value: 'activities_tennis' },
  { label: 'Skiing', value: 'activities_ski' },
  { label: 'Hockey', value: 'activities_hockey' },
  { label: 'Lacrosse', value: 'activities_lacrosse' },
  { label: 'Dance', value: 'activities_dance' },
  { label: 'Squash', value: 'activities_squash' },
  { label: 'Theatre', value: 'activities_theatre' },
  { label: 'Football', value: 'activities_football' },
  { label: 'Writing', value: 'activities_writing' },
  { label: 'Soccer', value: 'activities_soccer' }
];

const RadioInputField = props => {
  return (
    <Field
      {...props}
      checked={
        get(props.values, props.name, props.noval) === props.valueon
          ? true
          : false
      }
      onChange={e =>
        props.toggleradiofields(
          props.name,
          props.valueon,
          props.values,
          props.setfieldvalue
        )
      }
      className="custom__radiobox__input"
    />
  );
};

const CheckboxInputField = props => {
  return (
    <Field
      {...props}
      checked={get(props, `values.${props.name}`, 0) === 1 ? true : false}
      onChange={e => {
        props.setfieldvalue(props.name, e.target.checked ? 1 : 0);
      }}
    />
  );
};
export default class AroundTownComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    // PropTypes go here
  };

  toggleRadioFields = (key, val, values, setFieldValue) => {
    if (values[key] === val) {
      setFieldValue(key, -1);
    } else {
      setFieldValue(key, val);
    }

    this.forceUpdate();
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <h1 className="title__section">Town Details</h1>
          </div>

          <Formik
            enableReinitialize
            initialValues={get(this, "props.aiData", {})}
            onSubmit={data => {
              this.props.updateAiData(data)
            }}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form>
                <div className="col-md-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Great restaurants nearby{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="great_restaurants"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="great_restaurants"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Type of Stores{' '}
                        </label>
                        <ul className="checkbox__list vertical">
                          <li>
                            <label className="custom__checkbox">
                              Name brand
                              <CheckboxInputField
                                name="brand_name_shops"
                                type="checkbox"
                                values={values}
                                setfieldvalue={setFieldValue}
                                className="custom__checkbox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__checkbox">
                              Mom and pop
                              <CheckboxInputField
                                name="mom_and_pop_shops"
                                type="checkbox"
                                values={values}
                                setfieldvalue={setFieldValue}
                                className="custom__checkbox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">Beach nearby?</label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="beach"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="beach"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Weekend Activities{' '}
                        </label>
                        <ul className="checkbox__list vertical">
                          <li>
                            <label className="custom__checkbox">
                              Church
                              <CheckboxInputField
                                name="church_activities"
                                type="checkbox"
                                values={values}
                                setfieldvalue={setFieldValue}
                                className="custom__checkbox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__checkbox">
                              Temple
                              <CheckboxInputField
                                name="temple_activities"
                                type="checkbox"
                                values={values}
                                setfieldvalue={setFieldValue}
                                className="custom__checkbox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__checkbox">
                              Other religious institution or activity
                              <CheckboxInputField
                                name="bar_bat_mitzvahs"
                                type="checkbox"
                                values={values}
                                setfieldvalue={setFieldValue}
                                className="custom__checkbox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">Pools?</label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="backyard_pool"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="backyard_pool"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Organic food options{' '}
                        </label>
                        <ul className="radiobox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="organic_food_options"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="organic_food_options"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">
                          Hiking trails nearby?{' '}
                        </label>
                        <ul className="checkbox__list vertical">
                          <li>
                            <label className="custom__radiobox">
                              Yes
                              <RadioInputField
                                type="checkbox"
                                name="hiking_trails"
                                valueon={1}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label className="custom__radiobox">
                              No
                              <RadioInputField
                                type="checkbox"
                                name="hiking_trails"
                                valueon={0}
                                noval={-1}
                                setfieldvalue={setFieldValue}
                                toggleradiofields={this.toggleRadioFields}
                                values={values}
                                className="custom__radiobox__input"
                              />
                              <span className="custom__checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <hr className="small__hr" />
                      <div className="info__block__wrap">
                        <label className="label__text__sm">Activities </label>
                        <ul className="checkbox__list checkbox-list-grid-3">
                          {activities.map((activity, i) => {
                            return (
                              <li key={i}>
                                <label className="custom__checkbox">
                                  {activity.label}
                                  <CheckboxInputField
                                    name={activity.value}
                                    type="checkbox"
                                    values={values}
                                    setfieldvalue={setFieldValue}
                                    className="custom__checkbox__input"
                                  />
                                  <span className="custom__checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn__btn btn-dark btn__lg w__100"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
