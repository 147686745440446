import { connect } from 'react-redux'

import { getChatHistory } from '../../../../../../store/chat/duck'
import { deleteChatData, editClientChat, clearAgentPhase } from '../../../../../../store/agentV2/actions'

import ClientChatComponent from './component'
const ClientChatContainer = connect(
  // Map state to props
  (state) => ({
    chatHistoryData: state.chat.chatHistoryData,
    editClientPhase: state.agentV2.editClientPhase,
    deletePhase: state.agentV2.deletePhase,
  }),
  // Map actions to dispatch and props
  {
    getChatHistory,
    deleteChatData,
    editClientChat,
    clearAgentPhase
  }
)(ClientChatComponent)

export default ClientChatContainer
