import React, { PureComponent, Fragment } from 'react'
import TopNavigation from '../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component'
import moment from 'moment'
import Loader from '../../../../components/Loader'
import {
  CaretRightIcon,
  DashboardColorIcon,
  NotTouchedIcon,
  CalendarOtherIcon,
  HeartIcon,
} from '../../../../components/icons'
import UserContext from '../../../App/Context'
import _ from 'lodash'
import { Spin } from 'antd'
import Cookies from 'universal-cookie'
import styles from './styles.scss'
import ClientByStatusSection from './partials/ClientByStatusSection/container'
import ReportSection from './partials/ReportSection/container'
import AgentSection from './partials/AgentSection/container'
import DatePicker from '../../../../components/datePicker'
import CustomeDatePicker from '../../Strategist/Common/CustomeDatePicker'

const cookies = new Cookies()
let _user = ''

export default class DashboardComponent extends PureComponent {
  static contextType = UserContext
  constructor(props) {
    super(props)
    this.state = {
      todayDate: moment().format('LL'),
      datePickerModel: false,
      customFirstDate: moment(),
      customEndDate: moment(),
      dashboardData: [],
      isLoading: false,
      dateType: 'today',
      maxWidth: '',
      defaultSelectedType: 'today'
    }
  }

  componentDidMount() {
    const { fetchUser } = this.props
    document.title = 'Dashboard | Jungler'
    _user = cookies.get('user')
    if (_user) {
      if (
        _user.role === 'agent' ||
        _user.role === 'local' ||
        _user.role === 'client'
      ) {
        this.props.history.push({ pathname: '/login' })
      }
      fetchUser({ user_id: _user.id })
    }

    let dashboardFromToDate = JSON.parse(localStorage.getItem('dashboardFromToDate'))
    let startDate = moment(this.state.todayDate).format('YYYY-MM-DD')
    let endDate = moment(this.state.todayDate).format('YYYY-MM-DD')

    if (dashboardFromToDate) {
      this.setState({ defaultSelectedType: dashboardFromToDate.type })
    } else {
      dashboardFromToDate = {
        from: startDate,
        to: endDate,
        type: 'today'
      }
      localStorage.setItem('dashboardFromToDate', JSON.stringify(dashboardFromToDate))
    }

    // Initialize localStorage for clientFilterData for use in Dashboard components
    let clientFilterData = JSON.parse(localStorage.getItem('clientFilterData'))
    clientFilterData = Object.assign({}, clientFilterData, {
      startDate: dashboardFromToDate.from,
      endDate: dashboardFromToDate.to,
      clientType: 'all_clients',
      pageLimit: 20
    })
    localStorage.setItem('clientFilterData', JSON.stringify(clientFilterData))

    let analyticsData = {}
    if (dashboardFromToDate) {
      analyticsData.from = moment(dashboardFromToDate.from).format('YYYY-MM-DD')
      analyticsData.to = moment(dashboardFromToDate.to).format('YYYY-MM-DD')
    } else {
      analyticsData.from = moment(this.state.todayDate).format('YYYY-MM-DD')
      analyticsData.to = moment(this.state.todayDate).format('YYYY-MM-DD')
    }
    this.props.getAdministratorDashboardData(analyticsData)
  }

  static getDerivedStateFromProps(prop, state) {
    if (state.dashboardData !== prop.adminstrationDashboardData) {
      return {
        dashboardData: prop.adminstrationDashboardData,
        isLoading: false,
      }
    }
    return null
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({
      isLoading: true,
      fromDate: from,
      endDate: to,
      defaultSelectedType: val
    })
    const data = {
      from: moment.utc(from).format('YYYY-MM-DD'),
      to: moment.utc(to).format('YYYY-MM-DD'),
    }
    this.props.getAdministratorDashboardData(data)
    data.type = val
    localStorage.setItem('dashboardFromToDate', JSON.stringify(data))
  }

  openDatePickerRange() {
    this.setState({ datePickerModel: !this.state.datePickerModel })
  }

  closeCustomDate(e) {
    this.setState({
      datePickerModel: false,
      customFirstDate: this.state.previousStartDate,
      customEndDate: this.state.previousEndDate,
    })
  }

  handleSelect(range) {
    let startDate = range.startDate
    let endDate = range.endDate
    this.setState({ customFirstDate: startDate, customEndDate: endDate })
  }

  submitCustomDate() {
    this.setState(
      {
        datePickerModel: false,
        previousStartDate: this.state.customFirstDate,
        previousEndDate: this.state.customEndDate,
        dateType: 'custom',
      },
      () => {
        const analyticsData = {
          from: moment(this.state.customFirstDate).format('YYYY/MM/DD'),
          to: moment(this.state.customEndDate).format('YYYY/MM/DD'),
        }
        this.props.getAdministratorDashboardData(analyticsData)
      }
    )
  }

  render() {
    const {
      dashboardData,
      defaultSelectedType
    } = this.state
    const { user } = this.props

    // const newClientCount = dashboardData && dashboardData.newclients
    const appointmentScheduleCounts = dashboardData.appointmentSchedules
    const closedDeals = dashboardData && dashboardData.closedDeals || []
    const closedDealsCount = dashboardData && dashboardData.totalClosedDeals || 0

    return (
      <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading-title">
                    <span className="heading-icon">
                      <DashboardColorIcon className="" />
                    </span>
                    Welcome {_.startCase(_.toLower(_user && _user.first_name))}!
                  </h2>
                  <p className="heading-subtitle">Reports Overview</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <CustomeDatePicker
                    {...this.state}
                    filterByDate={this.filterByDate}
                    value={this.state.defaultSelectedType}
                  />
                  <Fragment>
                    <div
                      onClick={() => {

                        localStorage.setItem('parentFilter', true)

                        const dashboardFromToDate= JSON.parse(localStorage.getItem('dashboardFromToDate'))
                        
                        let clientFilterData = JSON.parse(localStorage.getItem('clientFilterData'))
                        clientFilterData = Object.assign({}, clientFilterData, {
                          startDate: dashboardFromToDate.from,
                          endDate: dashboardFromToDate.to,
                          clientType: 'all_clients'
                        })
                        localStorage.setItem('clientFilterData', JSON.stringify(clientFilterData))

                        this.props.history.push({
                          pathname: '/strategist/clients/all-clients',
                          state: dashboardFromToDate
                        })

                      }}
                      className="card card__administrator cursor__pointer"
                      >

                      <div className="card__tile__title">Clients received</div>
                      <div className="clients__received">
                        <span>{dashboardData.clientReceived}</span> Clients
                        received
                      </div>
                    </div>
                  </Fragment>

                  {/* {_user && _user.role !== 'strategist' && (
                    <Fragment>
                      <div
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/report/new-clients`,
                            state: { ...JSON.parse(localStorage.getItem('dashboardFromToDate')), dateType: defaultSelectedType }
                          })
                        }
                        className="card card__administrator cursor__pointer"
                      >
                        <div className="card__administrator__title">
                          New Clients
                        </div>
                        <div className="card__administrator__subtitle">
                          View new clients
                          <br />
                        </div>
                        <div className="card__administrator__count">
                          <span>{newClientCount && newClientCount.toLocaleString()}</span>
                        </div>
                        <div className="card__administrator__image">
                          <img src="./img/img_v2/dashboard.svg" />
                        </div>
                      </div>
                    </Fragment>
                  )} */}

                  <div
                    onClick={() =>
                      this.props.history.push(
                        `/report/appointment-scheduled`
                      )
                    }
                    className="card card__administrator cursor__pointer"
                  >
                    <div className="card__administrator__title">
                      Strategist Appointments Scheduled
                    </div>
                    <div className="card__administrator__subtitle">
                      View scheduled appointments
                      {/* Number of leads that have come from your website{' '} */}
                    </div>
                    <div className="card__administrator__count">
                      <span>{appointmentScheduleCounts && appointmentScheduleCounts.toLocaleString()}</span>
                    </div>
                    <div className="card__administrator__image">
                      <img src="./img/img_v2/list.svg" />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {/*  // TODO: commenting out `closed deals` portion of the response
                       //  until the below query performance is improved (especially important for admin user) */}
                  { <div className="card card__tile__other">
                    <div className="d__flex align__items__center justify__content__between">
                      <div className="card__tile__title">Total Closed</div>
                      <div className="total__closed__count">
                        {closedDealsCount}
                      </div>
                    </div>
                    <ul className="total__closed__list">
                      {closedDeals.map((close, index) => {
                          return (
                            <li key={'closed__' + index}>
                              <span>
                                {close.closedYear} - $
                                {close.totalSales && Number(close.totalSales).toLocaleString("en-US")} Total Sales
                              </span>
                              <span>{close.countClosed}</span>
                            </li>
                          )
                        })}
                    </ul>
                    {/*<a onClick={() => this.props.history.push(`/report/account-report`) } className="view__accounting__report">*/}
                    {/*  View Accounting Report*/}
                    {/*</a>*/}
                  </div>}


                  {/*follow up */}
                  {/*<div*/}
                  {/*  onClick={() =>*/}
                  {/*    this.props.history.push(`/strategist/task-list/followup`)*/}
                  {/*  }*/}
                  {/*  className="card__tile"*/}
                  {/*>*/}
                  {/*  <div*/}
                  {/*    className={*/}
                  {/*      dashboardData.followUpIn48Hrs !== 0*/}
                  {/*        ? 'card__tile__icon red'*/}
                  {/*        : 'card__tile__icon grey'*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <HeartIcon />*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__content">*/}
                  {/*    <div className="card__tile__title">*/}
                  {/*      Follow up in 48 Hours{' '}*/}
                  {/*    </div>*/}
                  {/*    <div className="card__tile__subtitle">*/}
                  {/*      Clients that you need to follow up with by{' '}*/}
                  {/*      <span className="text-black">*/}
                  {/*        {moment(dashboardData.followUpIn48HrsData).format(*/}
                  {/*          'MM/DD/YYYY'*/}
                  {/*        )}*/}
                  {/*      </span>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__arrow">*/}
                  {/*    <span className="card__tile__count">*/}
                  {/*      {dashboardData.followUpIn48Hrs ? (dashboardData.followUpIn48Hrs).toLocaleString() : 0}*/}
                  {/*    </span>*/}
                  {/*    <CaretRightIcon />{' '}*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*overdue follow up*/}
                  {/*<div*/}
                  {/*  onClick={() =>*/}
                  {/*    this.props.history.push(`/strategist/task-list/overdue`)*/}
                  {/*  }*/}
                  {/*  className="card__tile"*/}
                  {/*>*/}
                  {/*  <div*/}
                  {/*    className={*/}
                  {/*      dashboardData.overdueFollowUpDate !== 0*/}
                  {/*        ? 'card__tile__icon red'*/}
                  {/*        : 'card__tile__icon grey'*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <CalendarOtherIcon className="calendar-icon" />*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__content">*/}
                  {/*    <div className="card__tile__title">*/}
                  {/*      Overdue Follow up Date{' '}*/}
                  {/*    </div>*/}
                  {/*    <div className="card__tile__subtitle">*/}
                  {/*      Clients that have a follow up date that has past{' '}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__arrow">*/}
                  {/*    <span className="card__tile__count">*/}
                  {/*      {dashboardData.overdueFollowUpDate ? (dashboardData.overdueFollowUpDate).toLocaleString() : 0}*/}
                  {/*    </span>*/}
                  {/*    <CaretRightIcon />{' '}*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*<div*/}
                  {/*  onClick={() =>*/}
                  {/*    this.props.history.push(*/}
                  {/*      `/strategist/task-list/not-touched`*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*  className="card__tile"*/}
                  {/*>*/}
                  {/*  <div*/}
                  {/*    className={*/}
                  {/*      dashboardData.notTouchedIn4Week !== 0*/}
                  {/*        ? 'card__tile__icon red'*/}
                  {/*        : 'card__tile__icon grey'*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <NotTouchedIcon className="calendar-icon" />*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__content">*/}
                  {/*    <div className="card__tile__title">*/}
                  {/*      Not touched in 4 weeks{' '}*/}
                  {/*    </div>*/}
                  {/*    <div className="card__tile__subtitle">*/}
                  {/*      Clients that you havn’t touched since{' '}*/}
                  {/*      <span className="text-black">*/}
                  {/*        {moment(dashboardData.notTouchedIn4WeekData).format(*/}
                  {/*          'MM/DD/YYYY'*/}
                  {/*        )}*/}
                  {/*      </span>{' '}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="card__tile__arrow">*/}
                  {/*    <span className="card__tile__count">*/}
                  {/*      {dashboardData.notTouchedIn4Week ? (dashboardData.notTouchedIn4Week).toLocaleString() : 0}*/}
                  {/*    </span>*/}
                  {/*    <CaretRightIcon />{' '}*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/* this is clients by status component */}
                  {/*<ClientByStatusSection {...this.props} {...this.state} />*/}

                  {/* Moving ReportSection component */}
                  <ReportSection {...this.props} {...this.state} />


                </div>
              </div>
              <hr />
              {/* this is for agent section */}
              {/*<AgentSection {...this.props} {...this.state} />*/}
              {/*<hr />*/}
              
            </div>
          </main>

          <DatePicker
            datePickerModal={this.state.datePickerModel}
            onRequestClose={(e) => this.openDatePickerRange(e)}
            closeCustomDate={this.closeCustomDate.bind(this)}
            handleSelect={this.handleSelect.bind(this)}
            customFirstDate={this.state.customFirstDate}
            customEndDate={this.state.customEndDate}
            onSubmitDateRanges={() => this.submitCustomDate()}
          ></DatePicker>
        </div>
      </Spin>
    )
  }
}
