import { connect } from 'react-redux'

import DealComponent from './component'
import {
  fetchSchoolDistrict,
  fetchClientDeal,
  createUpdateDeal,
  clearDealSectionPhase,
  fetchTownAndSchoolDistrict,
  deleteClientDeal
} from '../../../../store/strategistV2/deals/actions'

import { getClientAgents } from '../../../../store/dashboard/duck'

const DealContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.dashboard.singleClientData,
    townDealInfo: state.strategistV2.deal.townDealInfo,
    clientDeals: state.strategistV2.deal.clientDeals,
    fetchClientDealPhase: state.strategistV2.deal.fetchClientDealPhase,
    createUpdateDealPhase: state.strategistV2.deal.createUpdateDealPhase,
    fetchTownSchoolDistrictPhase: state.strategistV2.deal.fetchTownSchoolDistrictPhase,
    schoolDistricts: state.strategistV2.deal.schoolDistricts,
    fetchSchoolDistrictPhase: state.strategistV2.deal.fetchSchoolDistrictPhase,
    clientAgentData: state.dashboard.clientAgentData,
    deleteClientDealPhase: state.strategistV2.deal.deleteClientDealPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientDeal,
    createUpdateDeal,
    fetchSchoolDistrict,
    clearDealSectionPhase,
    fetchTownAndSchoolDistrict,
    getClientAgents,
    deleteClientDeal
  }
)(DealComponent)

export default DealContainer
