import React, { PureComponent /*, PropTypes*/ } from 'react'
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2'
import { CloseIcon, CameraIcon } from '../../../../components/icons'
import UserContext from "../../../App/Context"
import './styles.scss'
import config from '../../../../config'
import _ from 'lodash'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from 'axios'
import { message } from 'antd'
import Cookies from 'universal-cookie'
import './styles.scss'
import { SUCCESS } from '../../../../constants/phase';
import { logoutInvalidRequest } from '../../../../utils/helper';
import {fetch} from "../../../../utils";

const cookies = new Cookies()
let _user = ''
const API_HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export default class UpdateProfileComponent extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      userData: {},
      avatar: '',
      file: {},
      country_code: '+1',
      // is_send_thank_you_email: '',
      checked: []
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    _user = cookies.get('user')
    if (_user && _user.id) {
      this.props.getUSerProfileByID({ user_id: _user.id })
    }
    this.props.getFilterParams()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userData !== prevState.userData) {
      return { userData: nextProps.userData, country_code: _.get(nextProps.userData, 'user.country_code', '+1') }
      // return { userData: nextProps.userData, country_code: _.get(nextProps.userData, 'user.country_code', '+1'), is_send_thank_you_email: _.get(nextProps.userData, 'user.is_send_thank_you_email', true) }
    }
    if (nextProps.ownUpdatePhase === SUCCESS) {
      nextProps.getUSerProfileByID({ user_id: _user.id })
      nextProps.clearPhaseUser()
    }
    return prevState
  }

  componentDidUpdate() {
    if (this.props.ownUpdatePhase === "success") {
      this.props.clearPhaseUser()
      message.success('Information Saved')
    }

    if (this.props.junglerUserPhase === 'success') {
      const { checked } = this.state
      this.props.clearPhase()
      this.props.userData && this.props.userData.user && this.props.userData.user.suburbs.forEach(function (val, indx) {
        if (checked.indexOf(val.id) === -1) {
          checked.push(val.id)
        }
      })
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  countryCode = (e, setFieldValue) => {
    this.setState({ country_code: '+' + e })
    setFieldValue("country_code", '+' + e)
  }

  // isSendThankYouEmail = (e, setFieldValue) => {
  //   this.setState({ is_send_thank_you_email: !this.state.is_send_thank_you_email })
  //   setFieldValue("is_send_thank_you_email", !this.state.is_send_thank_you_email)
  // }

  townChk(dataid) {
    let data = {}
    if (dataid && dataid != '') {
      const id = $.inArray(dataid, this.state.checked)
      if (id === -1) {
        this.state.checked.push(dataid)
        data.suburb_arr = this.state.checked
        //  this.props.getTowns(data)
      } else {
        this.state.checked.splice(id, 1)
        data.suburb_arr = this.state.checked
        //  this.props.getTowns(data)
      }
      this.forceUpdate()
    } else {
      data.suburb_arr = this.state.checked
      // this.props.getTowns(data)
      this.forceUpdate()
    }
  }

  handleFileUpload(e, setFieldValue) {
    var _URL = window.URL || window.webkitURL;
    let files = e.target.files[0]
    var img = new Image();
    let self = this
    let reader = new FileReader();
    reader.onloadend = () => {
      img.src = _URL.createObjectURL(files);
      img.onload = function () {
        self.setState({ file: files })
        self.setState({ avatar: reader.result })
      }
    }
    var read = reader.readAsDataURL(files)
    setFieldValue("avatar", _URL.createObjectURL(files))
  }

  submitData = (data, setSubmitting) => {
    if (!(_.isEmpty(this.state.file.name))) {
      const formData = new FormData()
      formData.append('image', this.state.file)
      formData.append('user_id', data.id)
      fetch(`${SUBURBANJUNGLE_API_HOST}/users/me/avatar`, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
        if (response.data && response.status) {
          this.setState({ avatar: response.data })
          this.handleSubmitButton(data, setSubmitting, true)
        }
        else {
          this.handleSubmitButton(data, setSubmitting, false)
        }
      }).catch(logoutInvalidRequest)
    } else {
      this.handleSubmitButton(data, setSubmitting, false)
    }
  }

  async handleSubmitButton(data, setSubmitting, isImage) {
    // let is_send_thank_you_email;
    // if(this.state.is_send_thank_you_email === true || this.state.is_send_thank_you_email === 1) {
    //   is_send_thank_you_email = "1"
    // } else {
    //   is_send_thank_you_email = "0"
    // }
    const userData = {
      user_id: data.id,
      update_type: "basic_info",
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_mobile: data.phone_mobile,
      phone_work: data.phone_work,
      time_zone: data.time_zone,
      description: data.description,
      avatar: isImage ? this.state.avatar : data.avatar,
      designation: data.designation,
      country_code: data.country_code,
      // thank_you_email_template_subject: data.thank_you_email_template_subject,
      // thank_you_email_template_content: data.thank_you_email_template_content,
      // is_send_thank_you_email: is_send_thank_you_email
    }
    userData.id = data.id ? data.id : data.user_id
    userData.role = data.role
    await this.props.updateOwnProfile(userData)
    this.context.updateUserData(userData)
    setTimeout(() => {
      setSubmitting(false)
    }, 2000)
  }

  // Saving SUBURBS part of profile
  saveSecondBtn(id, event) {
    event.preventDefault()
    const { townOptions } = this.state
    let data = {
      user_id: id,
      update_type: "suburb_info",
      towns_arr: townOptions,
      suburbs_arr: this.state.checked
    }
    this.props.updateOwnProfile(data)
  }

  GmailAuthPop() {
    fetch(`${SUBURBANJUNGLE_API_HOST}/auth/gmail`, {
      method: 'GET',
    })
      .then(res => res.json())
      .then((res) => {
        if (res && res.data && res.data != '') {
          // OPEN a seprate window with callback
          var win = window.open(res.data, 'Account verifications', 'width=600,height=500,left=50,top=100');
          //Close window in 50 seconds
          setTimeout(function () {
            win.close()
            window.location.reload();
          }, 50000);
        }
      }).catch(logoutInvalidRequest)
  }

  render() {
    const { filterParams } = this.props
    const { userData } = this.state
    const profileData = this.props.profileData
    let allData = filterParams.data;

    let timezonlist = config.timezonelist.map((val, index) => {
      return (
        <option key={index} value={val.key}>{val.name}</option>
      )
    })
    return (
      <div className="administration__profile">
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">
            Update Profile
          </div>
          <div className="head-section-cta">
            <button onClick={this.props.history.goBack} className="btn btn-link btn-back">
              <CloseIcon />
            </button>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          //userData.user
          initialValues={{
            id: _.get(this.context.userData, "id", ""),
            first_name: _.get(this.context.userData, "first_name", ""),
            last_name: _.get(this.context.userData, "last_name", ""),
            email: _.get(this.context.userData, "email", ""),
            firm_name: _.get(this.context.userData, "firm_name", "+1"),
            role: _.get(this.context.userData, "role", ""),
            phone_mobile: _.get(this.context.userData, "phone_mobile", ""),
            phone_work: _.get(this.context.userData, "phone_work", ""),
            time_zone: _.get(this.context.userData, "time_zone", ""),
            modified: _.get(this.context.userData, "modified", ""),
            created: _.get(this.context.userData, "created", ""),
            avatar: _.get(this.context.userData, "avatar", false),
            country_code: _.get(this.context.userData, "country_code", "+1"),
            is_limit: _.get(this.context.userData, "is_limit", ""),
            description: _.get(this.context.userData, "description", ""),
            designation:_.get(this.context.userData,"designation",""),
            // is_send_thank_you_email:_.get(this.context.userData,"is_send_thank_you_email",""),
            // thank_you_email_template_subject:_.get(this.context.userData,"thank_you_email_template_subject",""),
            // thank_you_email_template_content:_.get(this.context.userData,"thank_you_email_template_content","")
          }}
          validate={values => {
            let onlyNumber = values.phone_mobile && values.phone_mobile.replace(/\D/g, '')
            let mobileValidation = onlyNumber.length
            const errors = {};
            if (!values.first_name) {
              errors.first_name = 'Enter First Name';
            }
            if (!values.last_name) {
              errors.last_name = 'Enter Last Name';
            }
            if (!values.description) {
              errors.description = 'Please Enter Bio';
            }
            if (!values.phone_mobile) {
              errors.phone_mobile = 'Enter Mobile Number';
            } else if (mobileValidation < 6 || mobileValidation > 16) {
              errors.phone_mobile = 'Enter Mobile Correct Number'
            }
            if (!values.email) {
              errors.email = 'Enter Email Address';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
              errors.email = 'Invalid Email Address'
            }
            if (!values.time_zone) {
              errors.time_zone = 'Enter Timezone';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            this.submitData(values, setSubmitting)
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => (
              <Form noValidate>
                <div className="profile__card__wrapper">
                  <div className="card">
                    <div className="card-header d__flex align__items__center justify__content__between">
                      <h5 className="card-title mb__0">My Profile</h5>
                      {profileData && profileData.role === 'strategist' || profileData && profileData.role === 'admin' ?
                        <button onClick={this.GmailAuthPop.bind(this)} className="navigation__links--google-sync">
                          <img src="img/google-icon.svg" />{profileData.gmail_authentication_id && profileData.gmail_authentication_id != '' ? 'Emails Synced ' + profileData.gmail_authentication_id : ' Sync E-mail'}</button>
                        : ''
                      }
                    </div>
                    <div className="card-body">
                      <div className="form-group material-textfield">
                        <div className="d__flex align__items__center">
                          <div className="avatar-text">Photo<span> (1mb max)</span></div>
                          <div className="avatar avatar-lg">
                            <img src={values && values.avatar !== '' && values && values.avatar !== null ? values && values.avatar : '../img/blank_profile_image.png'} alt="" />
                            {
                              //   (_.isEmpty(values.avatar)) &&
                              // <span className="avatar-string">{ _.get(values, 'first_name', '') ? _.upperFirst(_.get(values, 'first_name', '').charAt(0)) : ''}{_.get(values, 'last_name', '') ? _.upperFirst(_.get(values, 'last_name', '').charAt(0)) : ''}</span>
                            }
                            <div className="upload-avatar-container">
                              <input
                                type="file"
                                className="upload-avatar"
                                accept="image/*"
                                name="image"
                                onChange={(e) => this.handleFileUpload(e, setFieldValue)}
                                id="file" />
                              <CameraIcon />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <Field
                              name="first_name"
                              type="text"
                              className="form-control material-textfield-input textfield-input-lg"
                              required
                            />
                            <label className="material-textfield-label label-lg">First Name</label>
                            <ErrorMessage className="invalid-feedback" name="first_name" component="div" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <Field
                              type="text"
                              className="form-control material-textfield-input textfield-input-lg"
                              name="last_name"
                              required
                            />
                            <label className="material-textfield-label label-lg">Last Name</label>
                            <ErrorMessage className="invalid-feedback" name="last_name" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <PhoneInput
                              defaultCountry={'us'}
                              autoFormat={false}
                              disableAreaCodes={true}
                              value={this.state.country_code}
                              enableLongNumbers={false}
                              buttonClass="flag-textfield"
                              onChange={(e) => this.countryCode(e, setFieldValue)}
                              required
                            />
                            <NumberFormat
                              format="(###) ### #### #####"
                              name='phone_mobile'
                              className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                              value={values && values.phone_mobile ? values.phone_mobile : ''}
                              onChange={e => setFieldValue("phone_mobile", e.target.value)}
                              required
                            />
                            <label className="material-textfield-label label-lg">Phone Number</label>
                            <ErrorMessage className="invalid-feedback" name="phone_mobile" component="div" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <PhoneInput
                              defaultCountry={'us'}
                              autoFormat={false}
                              disableAreaCodes={true}
                              value={this.state.country_code}
                              enableLongNumbers={false}
                              buttonClass="flag-textfield"
                              onChange={(e) => this.countryCode(e, setFieldValue)}
                              required
                            />
                            <NumberFormat
                              format="(###) ### #### #####"
                              name="phone_work"
                              className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                              value={values && values.phone_work ? values.phone_work : ''}
                              onChange={e => setFieldValue("phone_work", e.target.value)}
                            />
                            <label className="material-textfield-label label-lg">Conference Phone Number</label>
                            <ErrorMessage className="invalid-feedback" name="email" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <Field
                              type="text"
                              className="form-control material-textfield-input textfield-input-lg"
                              name="email"
                              required
                            />
                            <label className="material-textfield-label label-lg">Email</label>
                            <ErrorMessage className="invalid-feedback" name="email" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <Field
                              type="text"
                              className="form-control material-textfield-input textfield-input-lg"
                              name="designation"
                            />
                            <label className="material-textfield-label label-lg">Designation</label>
                            <ErrorMessage className="invalid-feedback" name="email" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <Field
                              name="time_zone"
                              component="select"
                              className="form-control custom-select material-textfield-input textfield-input-lg">
                              <option disabled value=""></option>
                              {timezonlist}
                          required
                        </Field>
                            <label className="material-textfield-label label-lg">Time Zone</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group material-textfield">
                        <Field
                          component="textarea"
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="description"
                          rows="9"
                          required
                        />
                        <label className="material-textfield-label label-lg">Bio</label>
                        <ErrorMessage className="invalid-feedback" name="description" component="div" />
                      </div>
                      <div className="form-group">
                        <label className="label__text__label">Region Coverage</label>
                        <ul className="checkbox__list checkbox-grid-2">
                          {
                            allData && allData.suburbs && allData.suburbs.map((filter, ind) => {
                              return (
                                <li key={ind}>
                                  <label className="custom__checkbox">
                                    {filter.name}
                                    <input
                                      type="checkbox"
                                      className="custom__checkbox__input"
                                      disabled={true}
                                      required
                                      disabled={true}
                                      checked={this.state.checked.includes(filter.id) ? true : false}
                                      onChange={this.handleChange}
                                      onClick={() => this.townChk(filter.id)}
                                    />
                                    <span className="custom__checkmark"></span>
                                  </label>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    {/* {userData && userData.user && userData.user.role === "strategist" &&
                      <div className="form-group material-textfield">
                        <Field
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="thank_you_email_template_subject"
                        />
                        <label className="material-textfield-label label-lg">Thank you email subject</label>
                        <ErrorMessage className="invalid-feedback" name="thank_you_email_template_subject" component="div" />
                      </div>
                    } */}
                    {/* {userData && userData.user && userData.user.role === "strategist" &&
                      <div className="form-group material-textfield">
                        <Field
                          component="textarea"
                          type="text"
                          className="form-control material-textfield-input textfield-input-lg"
                          name="thank_you_email_template_content"
                          rows="9"
                        />
                        <label className="material-textfield-label label-lg">Thank you email content</label>
                        <ErrorMessage className="invalid-feedback" name="thank_you_email_template_content" component="div" />
                      </div>
                    } */}
                    {/* {userData && userData.user && userData.user.role === "strategist" &&
                      <div className="form-group more__margin--btm">
                        <div className="agent-yesno">
                          Thank You automated email send? &nbsp;
                              <span className="toggle__btn">
                            No
                              <label className="switch agent_switch">
                              <input
                                type="checkbox"
                                onChange={(e) => this.isSendThankYouEmail(e, setFieldValue)}
                                checked={this.state.is_send_thank_you_email}
                              ></input>
                              <span className="slider round"></span>
                            </label>
                              Yes
                            </span>
                        </div>
                      </div>
                    } */}
                      <button type="submit" disabled={isSubmitting}
                        // onClick={this.saveSecondBtn.bind(this, _user.id, values)} 
                        className="btn btn__btn btn-dark btn__lg w__100">Save</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
        </Formik>
      </div>
    )
  }
}
