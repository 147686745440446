import 'rxjs'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import user, { userEpic } from './user/duck'
import dashboard, { dashboardEpic } from './dashboard/duck'
import agent, { agentEpic } from './agent/duck'
import { agentV2Epic, agentV2Reducer } from './agentV2'
import strategistV2, { strategistV2Epic } from './strategistV2'
import reportV2, { reportV2Epic } from './reportV2/duck'
import chat, { chatEpic } from './chat/duck'
import analytics, { analyticsEpic } from './analytics/duck'
import client, { clientEpic } from './client/duck'
import townReport, { townEpic } from './townReport/duck'
import brokerageReport, { brokerageReportEpic } from './brokerageReport/duck'
import app from './app'

// Bundling Epics
const rootEpic = combineEpics(
  userEpic,
  dashboardEpic,
  clientEpic,
  agentEpic,
  chatEpic,
  analyticsEpic,
  townEpic,
  brokerageReportEpic,
  agentV2Epic,
  strategistV2Epic,
  reportV2Epic
)

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware(rootEpic)

// Define Middleware
const middleware = [thunk, promise(), epicMiddleware]

// Define Reducers
const reducers = combineReducers({
  app,
  user,
  dashboard,
  client,
  agent,
  chat,
  analytics,
  townReport,
  brokerageReport,
  agentV2: agentV2Reducer,
  strategistV2,
  reportV2,
  form: formReducer,
})

// Create Store
export default createStore(reducers, {}, applyMiddleware(...middleware))
