import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import { agentGetUsers, 
  updateClientUser,
  getAppointments,
  getAgentAvailability,
  addAgentAppointment,
  getAgentClientsLastMessage } from '../../store/agent/duck'

import {getChatHistory} from '../../store/chat/duck'
import AgentChatCenterForm from './component'
const ClientChatCenter = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    agentCLients: state.agent.usersData,
    rxError: state.user.error,
    chatHistoryData: state.chat.chatHistoryData,
    phase: state.user.phase,
    availabilityData: state.agent.availabilityData,
    getClientLastMessagePhase: state.agent.getClientLastMessagePhase,
    getClientLastMessageData: state.agent.getClientLastMessageData
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    updateClientUser,
    getAppointments,
    getAgentAvailability,
    addAgentAppointment,
    agentGetUsers,
    getChatHistory,
    getAgentClientsLastMessage
  }
)(AgentChatCenterForm)
export default ClientChatCenter

