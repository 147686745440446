/* eslint-disable camelcase */
import React, { Component } from 'react'
import { Popover } from 'antd'
import { upperFirst, filter, get } from 'lodash'
import Cookies from 'universal-cookie'
import { FilterIcon } from '../../../../../components/icons'

const cookies = new Cookies()

let _user

export default class FilterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedRole: [],
      sortByKey: "",
      sortByType: ""
    }
  }

  componentDidMount() {
    _user = cookies.get('user')
  }

  clearFilter = () => {
    this.props.resetFilterArray()
    this.setState(
      { 
        selectedRole: [],
        filterCount: 0,
        visible: 0
      }, () => {
        const usersFilter = {
          userRole: [],
          pageLimit: 20, // this.state.resultEntry,
          activePage: 0, // this.state.activePage,
          sortBy: { 
            key: '', // this.state.sortByKey
            type: 0 // this.state.sortByType 
          },
        }
        this.props.getJunglerUsers(usersFilter);
        this.props.setFilterParams({ userRole: [] })
      }
    )
  }

  // change status close filter model with
  closeFilterModel = () => this.setState({ visible: !this.state.visible })

  filterVisible = visible => this.setState({ visible })

  handleChange = (event) => {
    const { name } = event.target
    let { selectedRole } = this.props
    if (selectedRole.includes(upperFirst(name))) {
      selectedRole = selectedRole ? filter(selectedRole, role => upperFirst(name) !== role) : []
    } else {
      let mainRole = selectedRole ? selectedRole : []
      mainRole.push(upperFirst(name))
      selectedRole = mainRole
    }
    // call api with change of role to get role wise data
    this.props.handleChangeRole({ userRole: selectedRole })
    // set filter for show selecte filter option on page
    this.props.setFilterParams({ userRole: selectedRole })
    this.setState({ selectedRole })
    this.forceUpdate()
  }

  // change entry changes
  handleResultChange = (event) => this.setState({ clientLimit: parseInt(event.target.value), resultEntry: parseInt(event.target.value) }, () => {
    this.props.handleResultChange({ clientLimit: this.state.clientLimit, resultEntry: this.state.resultEntry })
  })

  resetFilterParams = ({ userRole }) => this.setState({ selectedRole: userRole })

  handleAddJunglerUser = () => this.props.history.push('/strategist/add-user')

  render() {
    const { resultEntry } = this.state;
    const { filterCount, selectedRole, pageLimit } = this.props

    let isSuperAdmin = get(_user, 'role', '') === 'superadmin'
    let isAdmin = get(_user, 'role', '') === 'admin'

    return (
      <div className="col-md-7">
        <div className="filter__result__section">
          <button className="btn btn__btn btn-dark btn__add__jungler__user" onClick={this.handleAddJunglerUser}>Add Jungler User</button>
          <Popover
            content={
              < div className="filter" >
                <div className="filter__header">
                  <button className="btn btn__btn btn-secondry btn__xs" onClick={this.clearFilter}>Clear</button>
                  <div className="filter__title">Filters</div>
                  <button className="btn btn__btn btn-dark btn__xs" onClick={this.closeFilterModel}>Close</button>
                </div>
                <div className="filter__body">
                  <ul className="filter__list">
                    { (isAdmin || isSuperAdmin) &&
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Admin
                        <input type="checkbox"
                          name="Admin"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Admin')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>                    
                    }
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Strategist
                        <input type="checkbox"
                          name="Strategist"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Strategist')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Agents
                        <input type="checkbox" name="agent"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Agent')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Locals
                        <input type="checkbox" name="local"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Local')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Business Development
                        <input type="checkbox" name="business development"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Business development')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Prospect Agent
                        <input type="checkbox" name="Prospect Agent"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Prospect Agent')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Former Local
                        <input type="checkbox" name="Former Local"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Former Local')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Former Agent
                        <input type="checkbox" name="Former Agent"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Former Agent')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Former Strategist (inactive)
                        <input type="checkbox" name="Former Strategist (inactive)"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Former Strategist (inactive)')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Former Business Development
                        <input type="checkbox" name="Former Business Development"
                          className="custom-checkbox--input"
                          onChange={this.handleChange}
                          checked={selectedRole.includes('Former Business Development')} />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    
                  </ul>
                </div>
              </div >
            }
            placement="bottom"
            className="filter"
            overlayClassName="filter__overlay"
            // title="Title"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.filterVisible}
          >
            <button className="btn btn__btn btn-dark btn-filter"><FilterIcon className="filter-icon" /> Filters{filterCount > 0 && `(${filterCount})`}</button>
          </Popover>
          <div className="material-textfield">
            <select
              className="form-control custom-select material-textfield-input results__select"
              onChange={this.handleResultChange}
              value={this.props.pageLimit}
            >
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
              <option value={2000}>2000</option>
              <option value={3000}>3000</option>
              <option value={4000}>4000</option>
              <option value={5000}>5000</option>
              <option value={6000}>6000</option>
              <option value={7000}>7000</option>
            </select>
            <label className="material-textfield-label">Results</label>
          </div>
        </div>
      </div>
    )
  }
}
