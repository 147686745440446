import { connect } from 'react-redux'

import MessageNoteForAgentComponent from './component'

import {
  clearChatPhase,
  clearAgentChat,
  clearClientAgents,
  sendAgentMessage,
  fetchClientAgentChat,
  fetchClientAgent,
} from '../../../../../../store/strategistV2/chat/actions'

const MessageNoteForAgentContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    clientDetail: state.strategistV2.client.clientDetail,
    clientAgents: state.strategistV2.chat.clientAgents,
    fetchClientAgentChatPhase: state.strategistV2.chat.fetchClientAgentChatPhase,
    agentChats: state.strategistV2.chat.clientAgentsChat,
    fetchClientAgentListPhase: state.strategistV2.chat.fetchClientAgentListPhase,
    sendAgentMessagePhase: state.strategistV2.chat.sendAgentMessagePhase,
  }),
  // Map actions to dispatch and props
  {
    fetchClientAgentChat,
    fetchClientAgent,
    clearAgentChat,
    clearClientAgents,
    sendAgentMessage,
    clearChatPhase,
  }
)(MessageNoteForAgentComponent)

export default MessageNoteForAgentContainer
