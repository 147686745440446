import { connect } from 'react-redux';
import {
  fetchTownReport,
  generateReport,
  getGeneratedReport,
  updateTownName,
  clearTownPhase,
  deleteTownById
} from '../../../../store/townReport/duck';

import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions';

import TownsComponent from './component';

const TownsContainer = connect(
  // Map state to props
  state => ({
    townsCount: state.townReport.townsCount,
    towns: state.townReport.towns,
    suburbsData: state.townReport.suburbsData,
    townReportPhase: state.townReport.townReportPhase,
    filterParams: state.strategistV2.dashboard.filterParams,
    generateReport: state.townReport.generateReport,
    generateReportPhase: state.townReport.generateReportPhase,
    generateReportData: state.townReport.generateReportData,

    filterParamsPhase: state.strategistV2.dashboard.filterParamsPhase,
    updateTownData: state.townReport.updateTownData,
    updateTownPhase: state.townReport.updateTownPhase,
    deleteTownData: state.townReport.deleteTownData,
    deleteTownPhase: state.townReport.deleteTownPhase
  }),
  // Map actions to dispatch and props
  {
    fetchTownReport,
    getFilterParams,
    updateTownName,
    clearTownPhase,
    deleteTownById,
    generateReport
  }
)(TownsComponent);

export default TownsContainer;
