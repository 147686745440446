import { connect } from 'react-redux'
import { getFilterParams } from '../../../../store/strategistV2/dashboard/actions'
import {
  fetchAppointment,
  clearStrategistAppointment,
} from '../../../../store/strategistV2/appointment/actions'

import AgentAppointmentsComponent from './component'

const AgentAppointmentsContainer = connect(
  // Map state to props
  (state) => ({
    clientData: state.dashboard.clientData,
    appointments: state.strategistV2.appointment.appointments,
    filterParams: state.strategistV2.dashboard.filterParams,
    appointmentCount: state.strategistV2.appointment.appointmentCount,
    fetchAppointmentPhase: state.strategistV2.appointment.fetchAppointmentPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchAppointment,
    getFilterParams,
    clearStrategistAppointment,
  }
)(AgentAppointmentsComponent)

export default AgentAppointmentsContainer
