import { connect } from 'react-redux'

import {
  fetchFollowUpDate,
  updateFollowUpDate,
  clearFollowUpPhase,
} from '../../../../../../store/strategistV2/prospectus/followup/actions'

import FollowUpComponent from './component'

const FollowUpContainer = connect(
  // Map state to props
  (state) => ({
    clientDetail: state.strategistV2.client.clientDetail,
    followUpDates: state.strategistV2.prospectus.followUp.followUpDates,
    fetchFollowUpDatePhase: state.strategistV2.prospectus.followUp.fetchFollowUpDatePhase,
    updateFollowUpDatePhase: state.strategistV2.prospectus.followUp.updateFollowUpDatePhase,
  }),
  // Map actions to dispatch and props
  {
    fetchFollowUpDate,
    updateFollowUpDate,
    clearFollowUpPhase,
  }
)(FollowUpComponent)

export default FollowUpContainer
