import React, { PureComponent /*, PropTypes*/ } from 'react';
import ReactModal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  CloseIcon,
  SortUpIcon,
  SortDownIcon,
  DeleteIcon
} from '../../../../../../../components/icons';
import ConfirmModal from '../../../../Common/ConfirmModal';
import PlaceSearchBox from '../../../../../../../components/GoogleAutoComplete';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import { notification, message, Modal } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';

import './styles.scss';
import { logoutInvalidRequest } from '../../../../../../../utils/helper';

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2;
const cookies = new Cookies();
const { confirm } = Modal;

export default class GooglePlacesComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleteModalOpen: false,
      addPlaceModal: false,
      places: [],
      editMode: false,
      selectedPlace: {
        name: '',
        type: null,
        rating: '',
        vicinity: ''
      },
      selectedPlaceInitial: {
        name: '',
        type: null,
        rating: '',
        vicinity: ''
      },
      lat: 0,
      lng: 0
    };
    this.openPlaceModal = this.openPlaceModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { townId }
      }
    } = nextProps;

    const obj = {};
    // if (get(nextProps, 'getGooglePlacePhase', 'init') === 'success') {
    //   nextProps.clearTownPhase();
    //   d = [...state.places, ...get(nextProps, 'googlePlace.data', [])];
    //   obj.places = d;
    //   obj.editMode = false;
    //   obj.addPlaceModal = false;
    //   obj.isLoading = false;
    //   obj.selectedPlace = {};
    //   nextProps.toggleLoading(false);
    // }
    // if (get(nextProps, 'getGooglePlaceDataPhase', 'init') === 'success') {
    //   nextProps.clearTownPhase();
    //   d = [...state.places, ...get(nextProps, 'googlePlaceData.data', [])];
    //   obj.places = d;
    //   obj.editMode = false;
    //   obj.addPlaceModal = false;
    //   obj.isLoading = false;
    //   obj.selectedPlace = {};
    //   nextProps.toggleLoading(false);
    // }
    obj.places = nextProps.places

    if (get(nextProps, 'addGooglePhase', 'init') === 'success') {
      nextProps.clearTownPhase();
      nextProps.getGooglePlace(state.placeType)
      // nextProps.fetchGooglePlace({
      //   lat: state.lat,
      //   lng: state.lng,
      //   placeType: state.placeType
      // });

      // nextProps.fetchGooglePlaceData({ townId, placeType: state.placeType });

      obj.isLoading = false;
      obj.addPlaceModal = false;
      obj.selectedPlace = state.selectedPlaceInitial;
      obj.editMode = false;
      notification.success({
        message: 'Google Place has been added successfully'
      });
    }

    if (get(nextProps, 'editGooglePhase', 'init') === 'success') {
      nextProps.clearTownPhase();
      nextProps.getGooglePlace(state.placeType)

      // nextProps.fetchGooglePlace({
      //   lat: state.lat,
      //   lng: state.lng,
      //   placeType: state.placeType
      // });

      // nextProps.fetchGooglePlaceData({ townId, placeType: state.placeType });

      obj.isLoading = false;
      obj.addPlaceModal = false;
      obj.selectedPlace = state.selectedPlaceInitial;
      obj.editMode = false;

      notification.success({
        message: 'Google Place has been updated successfully'
      });
    }

    return obj;
  }


  deletePlace = async () => {
    const data = this.state.placeToDelete;
    const {
      match: {
        params: { townId }
      }
    } = this.props;
    this.props.toggleLoading(true);
    this.setState({ isDeleteModalOpen: false });
    // API logic
    const jwtToken = cookies.get('Authorization');
    const url = `${HOSTNAME}/deletePlacesForJungler`;
    if (data.is_manual) {
      axios({
        method: 'POST',
        url,
        headers: { Authorization: jwtToken },
        data: {
          id: data.id,
          delete: true
        }
      })
        .then(res => {
          // this.setState({ places: [] }, () => {
            this.props.getGooglePlace(this.state.placeType)
            // this.props.fetchGooglePlace({
            //   lat: this.state.lat,
            //   lng: this.state.lng,
            //   placeType: this.state.placeType
            // });

            // this.props.fetchGooglePlaceData({
            //   townId,
            //   placeType: this.state.placeType
            // });
          // });

          notification.success({
            message: 'Google Place has been deleted successfully'
          });
        })
        .catch(logoutInvalidRequest);
    } else {
      const obj = {};
      obj.name = data.name;
      obj.vicinity = data.vicinity;
      obj.google_place_id = data.place_id;
      obj.rating = data.rating;
      obj.type = data.types ? data.types.join(', ') : data.type;
      obj.user_rating_total = data.user_ratings_total;
      obj.google_primary_id = data.id;

      obj.lat = data.geometry.location.lat;
      obj.lng = data.geometry.location.lng;

      obj.is_manual = 3; // deleted place from the google result
      obj.town_id = townId;

      axios({
        method: 'POST',
        url,
        headers: { Authorization: jwtToken },
        data: obj
      })
        .then(res => {
          this.props.getGooglePlace(this.state.placeType)
          // this.setState({ places: [] }, () => {
            // this.props.fetchGooglePlace({
            //   lat: this.state.lat,
            //   lng: this.state.lng,
            //   placeType: this.state.placeType
            // });

            // this.props.fetchGooglePlaceData({
            //   townId,
            //   placeType: this.state.placeType
            // });
          // });

          notification.success({
            message: 'Google Place has been deleted successfully'
          });
        })
        .catch(logoutInvalidRequest);
    }
  };

  toggleDeleteModal = data => {
    this.setState({ isDeleteModalOpen: true, placeToDelete: data });
  };

  openPlaceModal(editMode, place) {
    this.setState({
      addPlaceModal: true,
      editMode,
      selectedPlace: place ? place : this.state.selectedPlaceInitial
    });
  }
  closeModal() {
    this.setState({ addPlaceModal: false, selectedPlace: this.state.selectedPlaceInitial, editMode: false });
  }

  selectCategory = e => {
    this.props.toggleLoading(true);
    const {
      match: {
        params: { townId }
      }
    } = this.props;

    let init = this.state.selectedPlace
    init.type = e.target.value 
    this.setState({ places: [] });
    this.props.fetchGooglePlace({
      lat: this.props.town.lat,
      lng: this.props.town.lng,
      googlePlaceType: e.target.value
    });
    
    this.props.getGooglePlace(e.target.value, '')
    // this.props.fetchGooglePlaceData({ townId, placeType: e.target.value });
    this.setState({ placeType: e.target.value, selectedPlace: init });
  };

  handleChangePlace = (setFieldValue, data) => {
    this.setState({ placesData: data });
    setFieldValue('lat', get(data, 'location.lat'));
    setFieldValue('vicinity', get(data, 'formatted_address'));
    setFieldValue('lng', get(data, 'location.lng'));
    setFieldValue('google_place_id', get(data, 'place_id'));
    setFieldValue('google_primary_id', get(data, 'google_primary_id'));
    setFieldValue('is_manual', 1);
  };

  addGooglePlaces = data => {
    this.setState({ isLoading: true, places: [] });
    this.props.toggleLoading(true);
    const {
      match: {
        params: { townId }
      },
      addGooglePlace,
      editGooglePlace
    } = this.props;

    data.town_id = parseInt(townId);
    data.type = get(data, 'type', this.state.placeType);
    if (this.state.editMode) {
      editGooglePlace(data);
    }

    if (!this.state.editMode) {
      addGooglePlace(data);
    }

  };

  render() {
    const {
      placeType,
      isLoading,
      places,
      selectedPlace,
      isDeleteModalOpen
    } = this.state;
    return (
      <div>
        <ConfirmModal
          closeModal={() => this.setState({ isDeleteModalOpen: false })}
          onConfirm={this.deletePlace}
          isLoading={isLoading}
          isOpenModal={isDeleteModalOpen}
        />
        <div className="row">
          <div className="col-xs-6 col-md-3">
            <div className="form-group material-textfield">
              <select
                as="select"
                className="form-control custom-select material-textfield-input"
                onChange={this.selectCategory}
              >
                <option value selected disabled>
                  Select Category
                </option>
                <option value="Around Town">Around Town</option>
                <option value="Food">Food</option>
                <option value="Fitness">Fitness</option>
                <option value="Parks/Beaches">Parks / Beaches</option>
                <option value="Country Clubs">Country Clubs</option>
              </select>
              <label className="material-textfield-label">
                Google Places Category
              </label>
            </div>
          </div>
          <div className="col-xs-6 col-md-9 text-right">
            <button
              className="btn btn__btn btn-dark"
              onClick={() => this.openPlaceModal(false)}
            >
              Add Place {placeType && `for ${placeType}`}
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table custom__table__simple">
            <thead>
              <tr>
                <th>
                  <span className="table-column-title">Name</span>
                  <span className="table-column-sorter">
                    <SortUpIcon />
                  </span>
                </th>
                <th>
                  <span className="table-column-title">Types </span>
                </th>
                <th>
                  <span className="table-column-title">Rating</span>
                  <span className="table-column-sorter">
                    <SortUpIcon />
                  </span>
                </th>
                <th colSpan="3">
                  <span className="table-column-title">Address</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(places) ? (
                <tr>
                  {' '}
                  <td colSpan="6" className="blank__table__state">
                    No data found.
                  </td>
                </tr>
              ) : (
                places.map((place, i) => {
                  return (
                    <tr key={i}>
                      <td>{get(place, 'name', '')} </td>
                      <td>
                        {place.types ? place.types.join(', ') : place.type}
                      </td>
                      <td>{get(place, 'rating', '')} </td>
                      <td>{get(place, 'vicinity', '')}</td>
                      <td>
                        {get(place, 'is_manual') ? (
                          <button
                            onClick={() => this.openPlaceModal(true, place)}
                            className="btn btn__btn btn__link"
                          >
                            Edit
                          </button>
                        ) : (
                          <img
                            style={{ width: '22px', height: '22px' }}
                            src="https://suburbanjungler-new.s3.amazonaws.com/googleIcon.svg"
                          />
                        )}
                      </td>
                      <td>
                        {place.is_delete ? (
                          <span>Deleted</span>
                        ) : (
                          <button
                            className="btn btn__btn btn__link"
                            onClick={() => this.toggleDeleteModal(place)}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <ReactModal
          isOpen={this.state.addPlaceModal}
          // onAfterOpen={(e) => this.afterOpenModal(e)}
          // onRequestClose={(e) => this.openDatePickerRange(e)}
          contentLabel="Add Place"
          portalClassName="react-modal"
        >
          <Formik
            enableReinitialize
            validationSchema={this.props.ValidationSchemas.googlePlaceSchema}
            initialValues={selectedPlace}
            onSubmit={this.addGooglePlaces}
          >
            {({
              isSubmitting,
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange
            }) => (
              <Form noValidate>
                <div className="react-modal-dialog react-modal-dialog-centered">
                  <div className="react-modal-header">
                    <h5 className="react-modal-title">
                      {this.state.editMode ? 'Edit' : 'Add'} Google Place
                    </h5>
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn react-modal-close"
                      onClick={this.closeModal.bind(this)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="react-modal-body">
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        name="name"
                        className="form-control material-textfield-input textfield-input-lg"
                        required
                      />
                      <label className="material-textfield-label label-lg">
                        Name
                      </label>
                      <ErrorMessage className="invalid-feedback" name="name" component="div"/>

                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        as="select"
                        name="type"
                        // defaultValue={placeType}
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        required
                      >
                        <option value selected disabled>
                          Select Type
                        </option>
                        <option value="Around Town">Around Town</option>
                        <option value="Food">Food</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Parks/Beaches">Parks / Beaches</option>
                        <option value="Country Clubs">Country Clubs</option>
                      </Field>
                      <label className="material-textfield-label label-lg">
                        Select Place Type
                      </label>
                      <ErrorMessage className="invalid-feedback" name="type" component="div"/>

                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        name="rating"
                        min="0"
                        className="form-control material-textfield-input textfield-input-lg"
                        required
                      />
                      <label className="material-textfield-label label-lg">
                        Rating
                      </label>
                      <ErrorMessage className="invalid-feedback" name="rating" component="div"/>

                    </div>
                    <div className="form-group material-textfield">
                      <Field
                        type="text"
                        component={PlaceSearchBox}
                        name="vicinity"
                        className="form-control material-textfield-input textfield-input-lg"
                        //  change={this.state.vicinity}
                        setAddress={data =>
                          this.handleChangePlace(setFieldValue, data)
                        }
                      />
                      <label className="material-textfield-label label-lg">
                        Vicinity
                      </label>
                      <ErrorMessage className="invalid-feedback" name="vicinity" component="div"/>

                    </div>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn__btn btn__lg btn-dark w__100"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ReactModal>
      </div>
    );
  }
}
