export const GET_TOWN_AND_SCHOOL_DISTRICT =
  'suburban/strategistV2/deals/GET_TOWN_AND_SCHOOL_DISTRICT'
export const GET_TOWN_AND_SCHOOL_DISTRICT_SUCCESS =
  'suburban/strategistV2/deals/GET_TOWN_AND_SCHOOL_DISTRICT_SUCCESS'
export const GET_TOWN_AND_SCHOOL_DISTRICT_ERROR =
  'suburban/strategistV2/deals/GET_TOWN_AND_SCHOOL_DISTRICT_ERROR'

export const CREATE_UPDATE_DEAL = 'suburban/strategistV2/deals/CREATE_UPDATE_DEAL'
export const CREATE_UPDATE_DEAL_SUCCESS = 'suburban/strategistV2/deals/CREATE_UPDATE_DEAL_SUCCESS'
export const CREATE_UPDATE_DEAL_ERROR = 'suburban/strategistV2/deals/CREATE_UPDATE_DEAL_ERROR'

export const FETCH_CLIENT_DEAL = 'suburban/strategistV2/deals/FETCH_CLIENT_DEAL'
export const FETCH_CLIENT_DEAL_SUCCESS = 'suburban/strategistV2/deals/FETCH_CLIENT_DEAL_SUCCESS'
export const FETCH_CLIENT_DEAL_ERROR = 'suburban/strategistV2/deals/FETCH_CLIENT_DEAL_ERROR'

export const FETCH_SCHOOL_DISTRICT = 'suburban/strategistV2/deals/FETCH_SCHOOL_DISTRICT'
export const FETCH_SCHOOL_DISTRICT_SUCCESS = 'suburban/strategistV2/deals/FETCH_SCHOOL_DISTRICT_SUCCESS'
export const FETCH_SCHOOL_DISTRICT_ERROR = 'suburban/strategistV2/deals/FETCH_SCHOOL_DISTRICT_ERROR'

export const DELETE_CLIENT_DEAL = 'suburban/strategistV2/deals/DELETE_CLIENT_DEAL'
export const DELETE_CLIENT_DEAL_SUCCESS = 'suburban/strategistV2/deals/DELETE_CLIENT_DEAL_SUCCESS'
export const DELETE_CLIENT_DEAL_ERROR = 'suburban/strategistV2/deals/DELETE_CLIENT_DEAL_ERROR'

export const CLEAR_DEALS_PHASE = 'suburban/strategistV2/deals/CLEAR_DEALS_PHASE'
