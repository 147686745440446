import React, { PureComponent } from 'react'
import PropTypes from "prop-types"
import { Redirect, Link } from 'react-router-dom'
import { AppBar } from 'material-ui'
import _ from 'lodash';
import { Container, Row, Col, } from 'react-grid-system'
import Cookies from 'universal-cookie'
import MobileFirstHeader from './partials/MobileFirstHeader'
import MobileSecondHeader from './partials/MobileSecondHeader'
import AgentClientHeader from './partials/AgentClientHeader'
import AgentHeader from './partials/AgentHeader'
import StrategistHeader from './partials/StrategistHeader'
import styles from './styles.scss'
const cookies = new Cookies()

export default class TopNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clientHeader: false
    }
  } 

  OpenScheduleBar(event) {
    this.props.openScheduleBar()
  }

  setProps(){
    const {history, getAllUsers} = this.props
    // let data = {} 
    // if(history.location.pathname == '/jungler-users'){
    //   data.user_type = "only_jungler_users"
    // } else if (history.location.pathname == '/home/new-clients' || history.location.pathname == '/dashboard'){
    //   data.user_type = "all_users"
    // }else{
    //   data.user_type = "client"
    // }
    let data = {
      user_type: 'all_users'
    }
    getAllUsers(data)
  }

  componentWillMount(){
    const {history} = this.props
    let _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      if(history.location.pathname === "/home/new-clients" || history.location.pathname === '/dashboard' || history.location.pathname === "/jungler-users" || history.location.pathname === "/calendar" || history.location.pathname === "/add-jungler-user" || history.location.pathname === "/add-clients" || history.location.pathname === "/profile"){
        localStorage.removeItem('client_strategist');
        localStorage.removeItem('client_status');
        localStorage.removeItem('client_suburb');
      }
    }
    if(_user && (_user.role=='superadmin' || _user.role=='admin' || _user.role=='strategist' )){
      this.setProps()
    }
  }

  myProfileCondition(role){
    switch(role) {
      case 'agent':
        return(
        <Link to={`/agent-edit-profile`} className="text-orange">
        My Profile</Link>
      )
      case 'local':
        return(
        <Link to={`/agent-edit-profile`} className="text-orange">
        My Profile</Link>
      )
      case 'client':
        return(
        <Link to={`/client-my-profile`} className="text-orange">
        My Profile</Link>
      )
      case 'strategist':
        return(
        <Link to={`/strategist-profile`} className="text-orange">
        My Profile</Link>
      )
      default:
        return null;
    }
  }

  render() {
    const {
      handleSubmit,
      change,
      allclientData,
      allUserData,
      user
    } = this.props;
    const currentuser = user && user;

    let windowWidth = window.screen.width < window.outerWidth ?
                      window.screen.width : window.outerWidth
    let isMobile = windowWidth < 500

    return (
      <div>
        {
          isMobile ?
            <div>
              { 
                (_.get(currentuser, 'role') === 'agent') || (_.get(currentuser, 'role') === 'client') || (_.get(currentuser, 'role') === 'local') || (_.get(currentuser, 'role') === 'business development' ) ?
                <div>
                  {
                    (_.get(currentuser, 'role') === 'agent' ) || (_.get(currentuser, 'role') === 'local' ) || (_.get(currentuser, 'role') === 'business development' )
                    ?
                    <div>
                      <AgentHeader {...this.props} />
                    </div>
                    :
                    <div>
                      <MobileFirstHeader {...this.props} />
                    </div>
                  }
                </div> 
                :
                <div>
                  <MobileSecondHeader {...this.props} />
                </div>
              }
            </div>
          :
            <div>
              { (_.get(currentuser, 'role') === 'agent') ||  (_.get(currentuser, 'role') === 'client') || (_.get(currentuser, 'role') === 'local') || (_.get(currentuser, 'role') === 'business development' ) ?
                <div>
                  {(_.get(currentuser, 'role') === 'agent') || (_.get(currentuser, 'role') === 'local')  || (_.get(currentuser, 'role') === 'business development' ) ?
                  <div>
                   <AgentHeader {...this.props} />
                  </div>
                  :
                  <AgentClientHeader {...this.props} />
                  }
                </div>
              :
                <div>
                 <StrategistHeader {...this.props} />
                </div>
             }
            </div>
      }
      </div>
    );
  }
}

