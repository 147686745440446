import { connect } from 'react-redux'

import CommuterCharacteristicsComponent from './component'

const CommuterCharacteristicsContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(CommuterCharacteristicsComponent)

export default CommuterCharacteristicsContainer
