import { connect } from 'react-redux'
import { createAgent, handleSignOut} from '../../store/user/duck'
import AgentAppForm from './component'
const AgentApp = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    createUserCheckPhase: state.user.createUserCheckPhase,
    createUserCheckError: state.user.createUserCheckError
  }),
  // Map actions to props
  {
    createAgent,
    handleSignOut
  }
)(AgentAppForm)
export default AgentApp
