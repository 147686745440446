import * as types from './action-types'
/*
    ////////////////////////////////////
  /// appointment action ///
///////////////////////////////////
*/

// fetch appointment list
export const fetchAppointment = (payload) => ({
  type: types.FETCH_APPOINTMENT_LIST,
  payload,
})

// fetch appointment list
export const fetchStrategistAvailability = (payload) => ({
  type: types.FETCH_STRATEGIST_AVAILABILITY,
  payload,
})
// fetch agent availabilitiy appointment list
export const fetchAgentAvailability = (payload) => ({
  type: types.FETCH_AGENT_AVAILABILITY,
  payload,
})

export const getStrategistAvaillableDates = (payload) => ({
  type: types.GET_STRATEGIST_AVAILLABLE_DATES,
  payload,
})
// create agent availabilitiy appointment list
export const createAgentAppointment = (payload) => ({
  type: types.CREATE_AGENT_APPOINTMENT,
  payload,
})

export const createStrategistAppointment = (payload) => ({
  type: types.CREATE_STRATEGIST_APPOINTMENT,
  payload,
})

// clear appointment
export const clearStrategistAppointment = (payload) => ({
  type: types.CLEAR_STRATEGIST_APPOINTMENT_PHASE,
})
