import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import styles from './styles.scss'


class Team extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      _clientID: "",
      chat_text: "",
      id: "",
      clientNotesdataLoading:true,
      openSchedule: false,
      type: 'add'
    }
    this.sendNote = this.sendNote.bind(this)
    this.editNote = this.editNote.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  
  componentWillMount(){
    let _clientid = this.props.match.params.id && this.props.match.params.id
    if(_clientid && _clientid !=''){
      this.setState({_clientID:_clientid})
      const clntdata = {}
      clntdata.client_id = _clientid;
      this.props.getClientNotes(clntdata)
    }
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  openScheduleBar(type, noteData, e) {
    if(type === "add"){
      this.setState({ 
        openSchedule : true, 
        type: "add", 
        noteData: "", 
        chat_text: ""
      })
    }else{
      this.setState({ 
        openSchedule : true, 
        type: "Edit", 
        noteData: noteData, 
        chat_text: noteData.note
      })
   }
  }

  closeScheduleBar(){
    this.setState({ openSchedule : false})
  }

  componentWillReceiveProps(nextProps){
    const clntdata = {}
    if(nextProps.sendMynoteData && nextProps.sendMynoteData.status){
      this.state.chat_text = "";
      clntdata.client_id = this.state._clientID;
      this.props.getClientNotes(clntdata)
      this.props.clearPhase()
    }
    if(nextProps.deleteNotePhase === "success"){
      clntdata.client_id = this.state._clientID;
      this.props.getClientNotes(clntdata)
      this.setState({openSchedule: false})
      this.props.clearPhase()
    }
    if(nextProps.clientNotesdata && nextProps.clientNotesdata.status === true){
      this.setState({clientNotesdataLoading:false})
    }

    if(nextProps.clientNotesdata && nextProps.clientNotesdata.status === false){
      this.setState({clientNotesdataLoading:false})
    }
    
  }

  sendNote(){
    if( this.state.chat_text !== '' && this.state._clientID != ''){
      let data = {}
      data.client_id = this.state._clientID
      data.note      = this.state.chat_text
      this.props.sendMyNote(data)
      this.setState({openSchedule: false})
    }
  }

  editNote(){
    if( this.state.chat_text !== '' && this.state._clientID != ''){
      let data = {}
      data.note_id   = this.state.noteData.id
      data.client_id = this.state._clientID
      data.note      = this.state.chat_text
      this.props.sendMyNote(data)
      this.setState({openSchedule: false})
    }
  }

  deleteNote(){
    let data = {}
    data.note_id = this.state.noteData.id
    data.client_id = this.state._clientID
    this.props.deleteNote(data)
    this.setState({ chat_text: ''})
  }

  render() {
    const {
      handleSubmit,
      pristine,
      isSubmitting,
      rxError,
      user,
      clientNotesdata
    } = this.props
    let userAvatar = user && user.avatar;
    let userName   = user && user.first_name +' '+user.last_name;
    let myuserId     = user && user.id;

    return (
    <div>
    {this.state.clientNotesdataLoading ?
        <main className="clients__Messages--container">
          <div className="table-responsive table-responsive-edit text-center">
              <img src="../../img/loader2.svg" />
          </div>
        </main>
        :
      <main className="clients__Messages--container">
        <div className="chat__container chatcontainer__pad">

        <div className="addnotes_bg"><button 
          className="btn btn__green notes-right new__common--btn"
          onClick={this.openScheduleBar.bind(this, "add", {})}>
          Add Note
        </button>
        </div>

          <div className="mesgs">
            <div className="chat__msg--history">
            {
              clientNotesdata && clientNotesdata.data && clientNotesdata.data.map((note,ind) => {
                return(
                  <div key={ind}>

                    { (note.author_id != myuserId) ? (
                      <div>
                          <div 
                            className="chat__msg--date__time">
                            {moment(note.created).format("dddd, MMMM Do YYYY, hh:mm:ss A")}
                          </div>

                        <div className="incoming_msg">
                          <div className="incoming_msg_img">
                            <div className="chat__msg--avtar">
                              <img 
                                className="img-responsive" 
                                src={note.user_avatar ? note.user_avatar:"img/login-bg-img.png"} 
                                alt="sbj-img"/>
                                      
                            </div>
                            <div className="chat__msg--avtar-name">
                              {note.author_full_name}
                            </div>
                          </div>
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <p><div dangerouslySetInnerHTML={{ __html: note.note}}></div></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      ):
                      <div>
                        <div className="chat__msg--date__time">{moment(note.created).format("dddd, MMMM Do YYYY, h:mm:ss A")}</div>
                          <div className="outgoing_msg">
                            <div 
                              className="sent_msg-edit"
                              onClick={this.openScheduleBar.bind(this, "edit", note)}>
                            <img 
                              src="img/edit-icon.svg"/>
                            </div>
                            <div className="sent_msg">
                              <p><div dangerouslySetInnerHTML={{ __html: note.note}}></div></p>
                            </div>
                            <div className="outgoin_msg_img">
                              <div className="chat__msg--avtar-name">
                                {userName ? userName:''}
                              </div>
                            <div className="chat__msg--avtar">
                              <img className="img-responsive" src={userAvatar ? userAvatar:"img/login-bg-img.png"} alt="sbj-img"/>
                            </div>
                          </div>
                        </div>
                      </div> 
                    }
                  </div>
                )
              })
            }
            </div>
            {/*<div className="type_msg">
              <div className="chat___msg--input-write">
                <textarea 
                  type="text" 
                  name="chat_text" 
                  value={this.state.chat_text}
                  onChange={this.handleChange.bind(this)} 
                  className="chat___msg--input" 
                  placeholder="Send a note…" />
                <button 
                  className="chat__msg--send__btn" 
                  type="button"
                  onClick={()=> this.sendNote()}>
                  Send
                </button>
              </div>
            </div>*/}
          </div>
        </div>
      </main>
    }


      <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            {
              (this.state.type === "add")?
                <div>Add Internal Note</div>
              :
                <div>Edit Internal Note</div>
            }
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body schedule__sidebar--bodymob">
            <div className="schedule__sidebar--form--wrapper">
              <div className="form-group rem__marg--btm">
                <textarea 
                  name="chat_text" 
                  className="textareainputs__global"
                  onChange={this.handleChange}
                  value={this.state.chat_text}>
                </textarea>
              </div>
            </div>
          </div>
          <div className="schedule__sidebar--footer">
            {
              (this.state.type === "add")?
            <button 
              className="btn btn__green btn__green--lg btn-block btn__schedule mobileEdit"
              onClick={()=> this.sendNote()}>
              Submit
            </button>
            :
            <div>
              <button 
                className="btn btn__green btn__green--lg btn-block btn__schedule mobileEdit"
                onClick={()=> this.editNote()}>
                Edit
              </button>
              
              {
                (this.state.type === "Edit")?(
                <h5
                  onClick={this.deleteNote.bind(this)} 
                  style={{"cursor": "pointer","color": "#D0021B", "text-align": "center", "paddingBottom":"5px"}}>
                  Delete Note
                </h5>
                ):''
              }
            </div>
          }
          </div>
        </div>


    </div>
    )
  }
}

export default reduxForm({
  form: 'Team',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Team)
