import { connect } from 'react-redux'

import AppointmentScheduledComponent from './component'
import { getAppointmentScheduled, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const AppointmentScheduledContainer = connect(
  // Map state to props
  (state) => ({
    appointmentSchedulePhase: state.reportV2.appointmentSchedulePhase,
    appointmentScheduleData: state.reportV2.appointmentScheduleData
  }),
  // Map actions to dispatch and props
  {
    getAppointmentScheduled,
    clearReportV2Phase
  }
)(AppointmentScheduledComponent)

export default AppointmentScheduledContainer
