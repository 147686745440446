import { connect } from 'react-redux'
import {fetchUser, handleSignOut} from '../../store/user/duck'
import {getClientAgent} from '../../store/client/duck'

import {getChatHistory} from '../../store/chat/duck'
import {getAgentAvailability, 
        addAgentAppointment,
        clearPhase } from '../../store/agent/duck'
import ClientChatCenterForm from './component'
const ClientChatCenter = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    isSubmitting: state.user.isSubmitting,
    agentData: state.client.agentData,
    rxError: state.user.error,
    chatHistoryData: state.chat.chatHistoryData,
    phase: state.user.phase,
    availabilityData: state.agent.availabilityData,
    addAppointmentPhase: state.agent.addAppointmentPhase,
  }),
  // Map actions to props
  {
    fetchUser,
    handleSignOut,
    getClientAgent,
    getChatHistory,
    getAgentAvailability,
    addAgentAppointment,
    clearPhase
  }
)(ClientChatCenterForm)
export default ClientChatCenter
