import { connect } from 'react-redux'

import StrategistTimesheetComponent from './component'
import { getFilterParams, clearDashboardPhase } from '../../../../../../store/strategistV2/dashboard/actions'
import { getStrategistTimesheet, clearReportV2Phase } from '../../../../../../store/reportV2/duck'

const StrategistTimesheetContainer = connect(
  // Map state to props
  (state) => ({
    filterParams: state.strategistV2.dashboard.filterParams,
    filterParamsPhase: state.strategistV2.dashboard.filterParamsPhase,
    strategistTimePhase: state.reportV2.strategistTimePhase,
    strategistTimeData: state.reportV2.strategistTimeData
  }),
  // Map actions to dispatch and props
  {
    getFilterParams,
    getStrategistTimesheet,
    clearReportV2Phase,
    clearFilterPhase: clearDashboardPhase
  }
)(StrategistTimesheetComponent)

export default StrategistTimesheetContainer
