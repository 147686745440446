import React from 'react'
import ReactModal from 'react-modal'

import { CloseIcon } from '../../../../../components/icons'

export default function RemoveTownModal({
  openModel,
  townId,
  closeRemoveTownRequestModal,
  handleRemoveTown,
}) {
  // remove town from list and update and close opened model
  const handelDeleteSubmission = () => [
    handleRemoveTown(townId),
    closeRemoveTownRequestModal(),
  ]
  return (
    <ReactModal
      isOpen={openModel}
      contentLabel="Delete User"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-body react-modal-body-sm">
          <button
            type="button"
            className="btn react-modal-close react-modal-close-sm"
            onClick={closeRemoveTownRequestModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title mb__13">Are you sure?</h5>
          <p className="react-modal-subtitle mb__3">
            This will remove the town and the assigned agent from the client’s strategy. Make sure to select “Save” after this pop-up closes.
          </p>
          <div className="text-right">
            <button
              onClick={handelDeleteSubmission}
              className="btn btn__btn btn-dark w__150"
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
