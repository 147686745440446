import { connect } from 'react-redux'
import { createUser, handleSignOut} from '../../store/user/duck'
import ThankYouForm from './component'
const ThankYou = connect(
  // Map state to props
  (state) => ({
    createUserPhase: state.user.createUserPhase,
    createUserError: state.user.createUserError
  }),
  // Map actions to props
  {
    createUser,
    handleSignOut
  }
)(ThankYouForm)
export default ThankYou
