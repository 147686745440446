import { List } from 'immutable'
import { fetch } from '../../utils'
import qs from 'qs'

const HOSTNAME = process.env.API_HOSTNAME
const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

export const getDashboardData = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/api/v1/dashboard?filter=${data}`, {
    method: 'GET'
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getFilterParams = () => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/filter-list`, {
    method: 'GET'
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getStrategy = (data) => {
  return fetch(`${HOSTNAME}/strategy/get_strategy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const saveAuthtoken = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/gmail-sync`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const saveAgentAuthtoken = (data) => {
  return fetch(`${HOSTNAME}/outlook/save_access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}


export const getTowns = (data) => {

  const params = new URLSearchParams({
      suburb_arr: data.suburb_arr
  })

  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/towns?` + params.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
      .then((res) => {
        return res.json()
      })
      .then((payload) => {
        return payload
      }).catch((error) => {
        throw error
      })
}

export const getClientList = (data) => {
  return fetch(`${HOSTNAME}/users/filter_clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getJunglerUsers = (data) => {

  // map the data to a single level for use in query string
  const queryStringParams = {
    sortKey: data.sortBy.key,
    sortDirection: data.sortBy.type,
    ...data
  }

  delete queryStringParams.sortBy

  const url = `${SUBURBANJUNGLE_API_HOST}/admin/users?` +
    new URLSearchParams(queryStringParameters).toString()

  return fetch(`${SUBURBANJUNGLE_API_HOST}/admin/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClientAgents = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/agents`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
      .then((res) => {
        return res.json()
      })
      .then((payload) => {
        return payload
      }).catch((error) => {
        throw error
      })
}

export const getClientDeal = (data) => {
  return fetch(`${HOSTNAME}/deal/get_client_deals`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addClientDeal = (data) => {
  return fetch(`${HOSTNAME}/deal/add_client_deal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getUSerProfileByID = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/strategist/users/${data.user_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClientEmail = (data) => {
  return fetch(`${HOSTNAME}/activity/emails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClientNotes = (data) => {
  return fetch(`${HOSTNAME}/activity/notes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addUpdateClient = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/client/add_update_client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const sentClientMessage = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/sms/${data.clientId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClientMessages = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/sms/${data.clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const sendMyNote = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}


export const sendBackgroundData = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/background`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getUserbyId = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}/metro-areas`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
      .then((res) => {
        return res.json()
      })
      .then((payload) => {
        return payload
      }).catch((error) => {
        throw error
      })
}

export const deleteClient = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.client_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addUsers = (data) => {
  return fetch(`${HOSTNAME}/users/add_user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const homeFilterClients = (data) => {
  return fetch(`${HOSTNAME_V2}/users/home_filter_clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getTownAgent = (data) => {
  return fetch(`${HOSTNAME}/client/get_agents_by_town`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addStrategy = (data) => {
  return fetch(`${HOSTNAME}/strategy/add_update_strategy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getStrategistApt = (data) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
  // return fetch(`${HOSTNAME}/appointment/get_appointments`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data)
  // }) 
  // .then((res) => {
  //   return res.json()
  // })
  // .then((payload) => {
  //   return payload
  // }).catch((error) => {
  //  throw error
  // })
}

export const getStrategistAvail = (data) => {
  const queryString = new URLSearchParams({
    date: data.date,
    timezone: data.timezone
  })
  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategist_id}/availability-times?${queryString}`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addStrategistApt = (data) => {
  const url = `${SUBURBANJUNGLE_API_HOST}/strategists/appointments`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const addClientStragistApt = (data) => {
  const url = data.appointment_id ? `${SUBURBANJUNGLE_API_HOST}/clients/appointments/${data.appointment_id}` : `${SUBURBANJUNGLE_API_HOST}/clients/appointments`
  const method = data.appointment_id ? `PUT` : `POST`

  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json()
    })
    .then((payload) => {
      return payload
    }).catch((error) => {
      throw error
    })
}

export const getAllUsers = (data) => {
  return fetch(`${HOSTNAME}/users/search_users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getRecordingLinks = (data) => {
  return fetch(`${HOSTNAME}/activity/get_recording_links`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const impersonateUser = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/impersonate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const updateScheduling = (data) => {
  return fetch(`${HOSTNAME}/activity/update_scheduling`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const sendGmailCode = (data) => {
  return fetch(`${HOSTNAME}/gmail/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const deleteNote = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/${data.clientId}/internal-notes/${data.noteId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const cancelAppointment = (data) => {
  data = JSON.stringify(data)
  return fetch(`${HOSTNAME}/appointment/delete_client_agent_appointment/${data}`, {
    method: 'DELETE'
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const cancelStrategistAppointment = (data) => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/clients/appointments/${data}`, {
    method: 'DELETE'
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getStrRatingData = (data) => {
  return fetch(`${HOSTNAME}/strategistRatingReport`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getNoAgentRatingData = (data) => {
  return fetch(`${HOSTNAME}/statistics/no_rating_initial_town_tour`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getAgentRatingData = (data) => {
  return fetch(`${HOSTNAME}/statistics/agent_rating_initial_town_tour`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const agentAssignedTown = (data) => {
  return fetch(`${HOSTNAME}/report/agents_assigned_to_towns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getTotalClientReceivePerMonth = (data) => {
  return fetch(`${HOSTNAME}/report/get_total_client_receive_per_month`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getClosedClientsData = (data) => {
  return fetch(`${HOSTNAME}/analytics/closedClients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getAIClientsData = (data) => {
  return fetch(`${HOSTNAME}/analytics/aiClientReport`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload
  }).catch((error) => {
   throw error
  })
}

export const getTasksList = (data) => {
  const type = data.type ? data.type : 'followup'
  const queryData = {
    type,
    client_type: data.client_type,
    client_limit: data.client_limit,
    client_offset: data.client_offset,
    client_suburb: data.client_suburb,
    client_status: data.client_status,
    client_strategist: data.client_strategist,
    sortBy: data.sortBy,
  }

  const qsQueryString = qs.stringify(queryData)

  return fetch(`${SUBURBANJUNGLE_API_HOST}/tasks-list?${qsQueryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.json()
  })
  .then((payload) => {
    return payload.data
  }).catch((error) => {
   throw error
  })
}
