import 'rxjs'
import React, { Component } from 'react'
import PropTypes, { func } from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import timezone from 'moment-timezone';
import config from '.././../../../config'
import $ from 'jquery'
import _ from "lodash";
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
const cookies = new Cookies()

const strategistArry = [];
class ActiveClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      timezone: '',
      client_status:'my_clients',
      client_type:'latest_clients',
      strategists:[],
      totalPages:0,
      activePage:1,
      client_limit:100,
      client_offset:1,
      isLoadingData: false,
      errProfileMessage: "",
      errAddressMessage: "",
      errPasswordMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      err: {},
      strategistDropdown: false,
      client_strategist : [],
      sortByKey: 'lastAction',
      sortByType : 1,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handelUserfilter = this.handelUserfilter.bind(this)
    }
  componentWillMount(){
    let _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }
  }

  componentDidMount(){
    document.title = "Jungler: Active Clients";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }
  

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handelUserfilter(btn) {
    this.setState({ isLoadingData: true})
    $('.filter__container--button').removeClass('active');
    if(btn.target.name !==''){
      $( 'button[ name='+btn.target.name+']' ).addClass( 'active' );
      
      switch(btn.target.name){
        case 'my_clients':
            this.setState({client_status:'my_clients'});
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.my_clients)})
            }
            break;
        case 'all_active_clients_today':
            this.setState({client_status:'all_active_clients_today'});
            if(this.props.clientData && this.props.clientData.user_counts){
              this.setState({totalPages:Math.round(this.props.clientData.user_counts.all_active_clients_today)})
            }
            break;
        default:
          this.setState({client_status:'my_clients'});
          if(this.props.clientData && this.props.clientData.user_counts){
            this.setState({totalPages:Math.round(this.props.clientData.user_counts.my_clients)})
          }
      }
    }else{
      this.setState({client_status:'my_clients'})
    }
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = 1;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)

    }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit = this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  handleEntryChange(event){
    this.setState({client_limit: event.target.value, activePage:1});
    setTimeout(function(){
      const clientdata = {}
      clientdata.client_type = this.state.client_type;
      clientdata.client_status = this.state.client_status;
      clientdata.client_limit =  this.state.client_limit;
      clientdata.client_offset = this.state.activePage;
      clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
      this.props.homeFilterClients(clientdata)
    }.bind(this), 500)
  }

  componentWillReceiveProps(nextProps){
    this.setState({ isLoadingData: true})
    if(nextProps.clientData && nextProps.clientData.user_counts){
      if(this.state.client_status ==='my_clients'){
        // this.setState({totalPages:Math.round(nextProps.clientData.user_counts.all_active_clients_today)})
        this.setState({totalPages:Math.round(nextProps.clientData.user_counts.my_clients)})
      }
    }
    if (nextProps.dataPhase === "success") {
      this.setState({ isLoadingData: false})
    }
  }

  toggledropDown(){
      this.setState({strategistDropdown: !this.state.strategistDropdown})
  }

  filterStrategist(id, type) {
    let { client_strategist} = this.state;
    if (client_strategist.indexOf(id) === -1) {
      client_strategist.push(id)
    } 
    else {
      client_strategist.splice(client_strategist.indexOf(id), 1)
    }
    this.setState({ client_strategist : client_strategist })
    const clientdata = {}
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.client_strategist = client_strategist;
    clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
    this.props.homeFilterClients(clientdata)
  }

  sortData(val){
    let sortByType = this.state.sortByType;
    if(val !== this.state.sortByKey ){
      sortByType = 0;
    }
    else{
      sortByType === 0 ? sortByType = 1 : sortByType = 0;
    }
    this.setState({ isLoadingData: true , sortByKey: val, sortByType: sortByType})
    const clientdata = {}
    clientdata.client_type = this.state.client_type;
    clientdata.client_status = this.state.client_status;
    clientdata.client_limit = this.state.client_limit;
    clientdata.client_offset = this.state.activePage;
    clientdata.client_strategist = this.state.client_strategist;
    clientdata.sortBy = {key: val, type: sortByType};
    clientdata.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
    this.props.homeFilterClients(clientdata)
  }

  render() {
    const {user, clientData, filterParams} = this.props;
    let allData = filterParams.data;
    let myClients = clientData && clientData.user_counts ? clientData.user_counts.my_clients : 0;
    let allActiveClientsToday = clientData && clientData.user_counts ? clientData.user_counts.all_active_clients_today : 0;
    let currentuser = user && user;
    return (
    <div>
      <main>
        <div className="filter__container">
          <div className="filter__container--filter-name rem-flex">
          <div className="col-md-6 overFlow__filter p_l--rem">
            {user && user.role != 'strategist' && user.role != 'local' ?
            <button name="all_active_clients_today" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>All Active Clients Today ({allActiveClientsToday})</button>
            : ''}
            {user && user.role != 'strategist' && user.role != 'local' ?
            <button name="my_clients" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>My Clients ({myClients})</button>
            : ''}
            </div>
            <div className="col-md-5 newfilter-list text-right">
            <li className="mobile__right">
              <span className="enteries-span">
                <label className="enteries-label">Entries</label>
                <select onChange={this.handleEntryChange.bind(this)} value={this.state.client_limit}>
                  {config.entries && config.entries.map((entry, entryindx) => {
                    return (
                    <option key={entryindx} value={entry.value}>{entry.label}</option>
                    )
                   })}
                </select>
              </span>  
            </li>
            {currentuser && currentuser.role && (currentuser.role ==='superadmin' || currentuser.role ==='admin') ?
            
              <li>
                <div className="custom-select custom-sel-filter custom__filter--dropdown" onClick={this.toggledropDown.bind(this)}>Filter by Strategist</div>
                {this.state.strategistDropdown && 
                  <div className= "sel-outer sel-outer--agent">
                    {allData && allData.strategists.map((strategistsval, strategistsindx) => {
                     let selectedIndex = false;
                      _.find(this.state.client_strategist, function(o) {
                        if(o === strategistsval.id){
                          selectedIndex = true;
                        } 
                     });
                     return (
                      <li key={strategistsindx} className="custom-select-option">
                        <span className="coverage__checkbox">
                          <label className="custom-checkbox">
                          {strategistsval.strategist_name}
                          <input type="checkbox" checked = {selectedIndex} className="custom-select-option-checkbox" onClick={this.filterStrategist.bind(this,strategistsval.id)}/>
                            <span className="checkmark"></span>
                          </label>
                        </span>
                      </li>
                     )
                    })}
                  </div>
                }
              </li>
             : ''}
             
            
            </div>
          </div>
        </div>
        
        <div className="mobileFilter filter__container" style={{ display: 'none' }}>
          {user && user.role != 'strategist' ?
          <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
          Filter <img src="./img/menu-dropdown.png" style={{ float:'right', paddingTop: '5px', width: '14px' }} /> 
          </a>
          <ul className="dropdown-menu mobile-dropdown">
            <li><button name="all_active_clients_today" className="btn filter__container--button" onClick={this.handelUserfilter.bind(this)}>All Active Clients Today ({allActiveClientsToday})</button></li>
            <li><button name="my_clients" className="btn filter__container--button active" onClick={this.handelUserfilter.bind(this)}>My Clients ({myClients})</button></li>
          </ul>
          </div>
          : ''}
          <div className="col-md-12 newfilter-list text-right filter__container--filter-name rem-flex">
            <li>
              <span className="enteries-span">
                <label className="enteries-label">Entries</label>
                <select value={this.state.client_limit} onChange={this.handleEntryChange.bind(this)}>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  <option value="300">300</option>
                  <option value="350">350</option>
                  <option value="400">400</option>
                  <option value="450">450</option>
                  <option value="500">500</option>
                </select>
              </span>  
            </li>
            </div>
        </div>
  
         <div className="uses-list useslist__extra">
             <div className="table-responsive">
               <table className="table table-striped">
                <thead className="table__td--desktop">
                    <tr>
                      <th onClick={this.sortData.bind(this , 'clientName')}>Client Name</th>
                      <th onClick={this.sortData.bind(this , 'strategist')}>Strategist</th>
                      <th onClick={this.sortData.bind(this , 'pipelineStage')}>Pipeline Stage</th>
                      <th onClick={this.sortData.bind(this , 'lastAction')}>Last Action</th>
                    </tr>
                  </thead>
                  {this.state.isLoadingData === true ? 
                  <tbody>
                  <tr>
                    <td colSpan="5" className="noClientResults text-center">
                    <img src="../../img/loader2.svg" />
                    </td>
                    </tr>
                  </tbody>
                  :
                  <tbody>
                  {
                  clientData && clientData.users && clientData.users.length > 0 ?
                    clientData.users.map((c,index) => {
                    return(
                    <tr key={index} onClick={() => { this.props.history.push({'pathname' : `/activity/${c.client_id}`, 'state':c})}}>
                      <td>{c.client_full_name}<span className="showschedule__mob">{c.status}</span></td>
                      <td className="table__td--desktop">
                      {
                        c.strategists && c.strategists.map((strategist, idx)=>{
                          return(
                            <span key={idx}>{ (idx ? ', ' : '') + strategist.strategist_full_name }</span>
                          )
                        })
                      }
                      </td>
                      <td className="table__td--desktop">{c.status} </td>
                      <td className="mobile__right">{c.latest_activity && moment(c.latest_activity).tz(moment.tz.guess()).format("MM/DD/YYYY hh:mm a z")}</td>
                    </tr>
                      )
                    }) : 
                    <tr><td colSpan="6" className="noClientResults">No clients found for this criteria. Please broaden or change your search criteria and filters</td></tr> 
                    }  
                  </tbody>
                  }
              </table>
             </div>
         </div>
      </main>
      <div className="pagination-container">
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.client_limit}
          totalItemsCount={this.state.totalPages}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
     </div>
    </div>
    )
  }
}





export default reduxForm({
  form: 'activeclient',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ActiveClients)
