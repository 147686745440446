import { connect } from 'react-redux'

import AppointmentComponent from './component'
import { fetchUser } from '../../../../store/user/duck'
import {
  agentGetUsers,
  getAppointments,
  // get agent availblity
  getAgentAvailability,
  addAgentAppointment,
  clearPhase
} from '../../../../store/agent/duck'
import { fetchAgentAllClient } from '../../../../store/agentV2/actions'

const AppointmentContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.users,
    agentClients: state.agent.usersData,
    agentAllClients: state.agentV2.agentAllClients,
    availabilityData: state.agent.availabilityData,
    addAppointmentPhase: state.agent.addAppointmentPhase
  }),
  // Map actions to dispatch and props
  {
    fetchUser,
    clearPhase,
    agentGetUsers,
    getAppointments,
    addAgentAppointment,
    fetchAgentAllClient,
    getAgentAvailability
  }
)(AppointmentComponent)

export default AppointmentContainer
