import { connect } from 'react-redux'

import FileComponent from './component'

const FileContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(FileComponent)

export default FileContainer
