import { connect } from 'react-redux'

import NoteComponent from './component'

const NoteContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(NoteComponent)

export default NoteContainer
