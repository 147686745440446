import 'rxjs'
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import Header from '../Header'
import GoogleDrive from './googleDrive'
import Slider from 'react-slick'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Snackbar from 'material-ui/Snackbar'
import Cookies from 'universal-cookie'
import Dropzone from 'react-dropzone'
import styles from './styles.scss'
import axios from 'axios'
import ReactDOM from "react-dom";
import {SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move'
import _ from 'lodash'
import { logoutInvalidRequest } from '../../../../utils/helper'
const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME

const SortableItem = SortableElement(({value, onRemove, onFeatured, imageRotate, checkedClick}) =>
  <li key={`input-${value}`}>
   <div className="ul__img"> <img id={value.id} className="image-item" src={value.url} />  </div>
    <button className="fixed__remove fixed__remove--checkbox" key={`input-${value}`} onClick={() => onRemove(value)}> </button>  
    <button className="fixed__remove fixed__check--checkbox"  onClick={() => checkedClick(value)}></button>
    <button className="fixed__featured" onClick={() => onFeatured(value)}> Set Featured </button>  
    <button className="fixed__remove add__rotate" onClick={() => imageRotate(value)}> Rotate </button>
  </li>
)

const SortableList = SortableContainer(({items, onRemove, onFeatured, image, imageRotate, checkedClick}) => {
  return (
    <ul>
        <li>
        <div className="ul__img"><img className="image-item town__image--selected" src={image}/></div></li>
        <button className="featured__btn--single">Featured </button>
        <span>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} onRemove={onRemove} checkedClick={checkedClick} onFeatured={onFeatured} imageRotate={imageRotate} index={index} value={value} />
        ))}
        </span>
    </ul>
  )
})

class SortableComponent extends Component {
  render() {
    return <SortableList items={this.props.data} image={this.props.image} onRemove={this.props.onRemove} onFeatured={this.props.onFeatured} imageRotate={this.props.imageRotate} checkedClick={this.props.checkedClick}  onSortEnd={this.props.onSortEnd} axis="xy"/>;
  }
}


class Images extends Component {
  constructor(props) {
    super(props)
    this.state = {
      previewImage: '',
      i: null,
      attachId: '',
      imageUrl: '',
      openFeaturedDilog: false,
      openRemoveDilog: false,
      imageId: '',
      openSnackbar: false,
      message: '',
      disableBtn: false,
      current_role: '',
      town_name: '',
      listItem: [],
      featuredImage: '',
      angle: 0,
      rotate: 0,
      rotateImageId: 0,
      base64: '',
    }
    this.onClickSave = this.onClickSave.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.orderList = this.orderList.bind(this)
    this.remove = this.remove.bind(this)
    this.featured = this.featured.bind(this)
    this.shouldCancelStart = this.shouldCancelStart.bind(this)
    this.sendData = this.sendData.bind(this)
  }

  componentWillMount() {
    let _user = cookies.get('user')
    this.setState({ userid: _user.id, current_role: _user.role })
    const { getGeneratedReport, match: { params: { townId } } } = this.props
    getGeneratedReport(townId)
    this.getTownName()
  }

  async getTownName(){
    const {match: { params: { townId }} } = this.props
    const fetchTownName = () => axios.get(
      `${HOSTNAME}/townById?town_id=${townId}`
    ).catch(logoutInvalidRequest)
    const townName = await fetchTownName()
    if(townName && townName.data && townName.data.town){
      let town_name = townName.data.town && townName.data.town[0].name
      this.setState({ town_name: town_name })
    }
  }

  componentWillReceiveProps(np) {
    const { removeImg, history, featured, imageUpload, getGeneratedReport, match: { params: { townId } } } = np
    if (imageUpload || removeImg) {
      this.setState({
        message: imageUpload ? 'Images Uploaded Successfully' : 'Image has been removed successfully',
        openSnackbar: true,
        openRemoveDilog: false,
        disableBtn: false
      }, () => {
        getGeneratedReport(townId)
      })
    }
    if (featured) {
      this.setState({
        message: 'Image marked featured successfully',
        openSnackbar: true,
        openRemoveDilog: false,
        disableBtn: false
      }, () => {
        getGeneratedReport(townId)
      })
      // history.push('/town-report')
    }
    if(np.report !== this.props.report){
      let imagesData = np.report && np.report.images
      let imgArray = _.orderBy(imagesData, ['sort_by'],['asc'])
      let listItem = []
      imgArray && imgArray.map((val, idx)=>{
        if(!val.featured){
          listItem.push(val)
        }else{
          this.setState({ featuredImage: val.url })
        }
      })
      this.setState({ listItem: listItem })
    }
  }

  componentWillUnmount() {
    this.props.clearTownPhase()
  }

  dragDrop(acceptFile){
    const { uploadImage,  match: { params: { townId }} } = this.props
    const { listItem } = this.state
    for (let i = 0; i < acceptFile.length; i += 1) {
      const reader = new FileReader()
      reader.onload = (r) => {
        if(acceptFile[i]){
          this.setState({
            previewImage: acceptFile[i].preview,
            previewStatus: 'ViewPatientOnboarding_previewShowing'
          })
          const formdata = new FormData()
          formdata.append('image', acceptFile[i])
          formdata.append('townId', townId)
          uploadImage(formdata)
        }
      }
      reader.readAsDataURL(acceptFile[i])
    }
  }

  orderList({oldIndex, newIndex}){
    this.setState(({listItem}) => ({
      listItem: arrayMove(listItem, oldIndex, newIndex),
    }))

    let self = this
    setTimeout(function() {
      self.state.listItem && self.state.listItem.map((val, idx)=>{
        self.updateImages(val.id, idx)
      })
    }, 500)
  }

  async updateImages(id, idx){
    const { match: { params: { townId } } } = this.props
    const townUpdate = () => axios.get(
      `${HOSTNAME}/update_town?town_id=${parseInt(townId)}&town_value=${parseInt(id)}&town_index=${parseInt(idx)}`
    ).catch(logoutInvalidRequest)
    
    const towns = await townUpdate()
  }

  handleEmptyImage() {
    if (this.state.previewImage) {
      this.setState({ previewImage: '', previewStatus: '' })
    }
  }

  handleClose = () => {
    this.setState({ i: null, imageUrl: '', attachId: '', openFeaturedDilog: false, openRemoveDilog: false })
  }

  townImages(report) {
    return report && report.images.map((image, i) => {
      return(
        <div className="col-md-4 town-img-outer revmove__width" key={i}>
          <div className={this.state.i === i ? 'town-image-select' : 'town-images'}>
            <img
              src={image.url}
              onClick={() => { !image.featured && this.setState({ i, imageUrl: image.url, attachId: image.attach_id, openFeaturedDilog: true }) }}
            />
          </div>
          {image.featured && <span className="featured_img">Featured</span>}
          <div className="col-md-12">
            <div className="featured__listing">
              <ul>
                <li>
                {image.featured ? '' : <span className="remove_img" onClick={() => this.setState({ openRemoveDilog: true, imageId: image.id, attachId: image.attach_id, imageUrl: image.url })}>Remove</span>}
                </li>
              </ul>
            </div>
          </div>
          {/*image.featured ? '' : <span className="remove_img" onClick={() => this.setState({ openRemoveDilog: true, imageId: image.id, attachId: image.attach_id, imageUrl: image.url })}>Remove</span>*/}
        </div>
      )
    })
  }

  handleRequestClose() {
    this.setState({ openSnackbar: false })
  }

  removeImage() {
    const { removeImage, match: { params: { townId } }, disableBtn } = this.props
    const { imageId, attachId, imageUrl } = this.state
    const data = {
      town_id: townId,
      url: imageUrl,
      image_id: imageId
    }
    removeImage(data)
  }

  onClickSave() {
    const { markFeatured, match: { params: { townId } }, history } = this.props
    const { imageUrl, attachId } = this.state
    this.setState({ openFeaturedDilog: false })
    const data = {
      town_id: townId,
      featured_image_url: imageUrl,
      image_id: attachId
    }
    this.setState({ disableBtn: true })
    imageUrl ? markFeatured(data) : history.push('/town-report')
  }

  shouldCancelStart(e) {
    // Prevent sorting from being triggered if target is input or button
    if (['input', 'button'].indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true; // Return true to cancel sorting
    }
  }
  
  remove (image) {
    this.setState({ openRemoveDilog: true, imageId: image.id, attachId: image.attach_id, imageUrl: image.url })
  }

  featured (image) {
    this.setState({ imageUrl: image.url, attachId: image.attach_id, openFeaturedDilog: true })
  }

  async sendData(data, token) {
    const { uploadImage, match: { params: {townId}} } = this.props
    for (let i = 0; i < data.length; i += 1) {
      if(data[i]){
        const formdata = new FormData()
        formdata.append('image', data[i].url)
        formdata.append('ext', 'png')
        formdata.append('mime', data[i].mimeType)
        formdata.append('townId', townId)
        formdata.append('drive', true)
        formdata.append('url', data[i].url)
        formdata.append('authToken', token)
        formdata.append('fileId', data[i].id)
        uploadImage(formdata)
      }
    }
  }

  imageRotate(val){
    const { angle } = this.state
    this.setState({ rotateImageId: val.id })
    var img=document.getElementById(val.id)
    img.setAttribute('style',`transform:rotate(${(angle + 90)%360}deg)`)
   
    let angleDeg = (angle + 90)%360
    let rotate = 0
    if(angleDeg == 0){
      rotate = 1
    }else if(angleDeg == 90){
      rotate = 2
    } else if(angleDeg == 180){
      rotate = 3
    } else if(angleDeg == 270){
      rotate = 4
    }
    let self = this
    this.setState({ angle: angleDeg, rotate: rotate })
    //'http://www.tutorialspoint.com/videotutorials/images/tutor_connect_home.jpg'

    var proxyUrl = 'https://cors-anywhere.herokuapp.com/',
    targetUrl = val.url
    let crossImageConvert = proxyUrl + targetUrl
    this.base64(crossImageConvert, function(dataUrl) {
      self.resetOrientation(dataUrl, rotate, function(resetBase64Image) {
        self.setState({ base64: resetBase64Image })
      })
    })
  }

  base64(url, callback) {
    var httpRequest = new XMLHttpRequest()
    httpRequest.onload = function() {
       var fileReader = new FileReader()
       fileReader.onloadend = function() {
          callback(fileReader.result)
       }
       fileReader.readAsDataURL(httpRequest.response)
    }
    httpRequest.open('GET', url)
    httpRequest.responseType = 'blob'
    httpRequest.send()
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image()
    img.onload = function() {
      var width = img.width,
      height = img.height,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext("2d")
      canvas.width = width;
      canvas.height = height;
      switch (srcOrientation) {
        case 3: ctx.transform(-1, 0, 0, -1, width, height )
        break
        case 2: ctx.transform(0, 1, -1, 0, height , 0)
        break
        case 4: ctx.transform(0, -1, 1, 0, 0, width)
        break
        default: break;
      }
      // draw image
      ctx.drawImage(img, 0, 0)
      // export base64
      callback(canvas.toDataURL())
    }
    img.src = srcBase64
  }

  checkedClick(val){
    const { uploadImage,  match: { params: { townId }} } = this.props
    if( this.state.rotateImageId === val.id ){
      const formdata = new FormData()
      formdata.append('image', this.state.base64)
      formdata.append('townId', townId)
      formdata.append('ext', 'jpeg')
      formdata.append('id', val.id)
      formdata.append('rotateImage', 'rotateImage')
      uploadImage(formdata)
    }
  }
  
  render() {
    const { history, report, uploadProgress, disableBtn, data } = this.props
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }]
    }

    let listItem = []
    if(this.state.listItem){
      this.state.listItem.map((val, idx)=>{
        if(!val.featured){
          listItem.push(val)
        }
      })
    }
    

    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton label="Yes" primary={true} keyboardFocused={true} onClick={ ()=> this.onClickSave()} />
    ]
    const actions2 = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton label="Yes" primary={true} keyboardFocused={true} onClick={() => this.removeImage()} />
    ]
    return (
      <div>
      <section className="town-report">
        <Header title={`Update ${this.state.town_name} Images`}/>

          <div className="town-table">

          <div className="row">
          <div className="col-md-12 text-right">
            <GoogleDrive key="googleDrive" sendData={this.sendData} />
          </div>
          </div>

          {/*this.townImages(report)*/}
          <div>
              <div className="row">
              <div className="col-md-12">
                {
                  !uploadProgress ?
                  <div>
                    <Dropzone
                      className="dashed-border"
                      multiple
                      onDrop={this.dragDrop.bind(this)}
                    >
                      <h5 className="drag-box">
                      <span className="Upload_txt"><span>Drag & Drop <br/>or</span><br/>Upload</span>
                      </h5>
                    </Dropzone>
                  </div>
                :
                  <div>
                    <div
                      className="dashed-border">
                      <h5 className="drag-box">
                        <span className="Upload_txt">
                          <img className="image-loader" src="/img/loader2.svg"/>
                        </span>
                      </h5>
                    </div>
                  </div>
                }
              </div>
              </div>
                
                {/* <div className="col-md-4">
                  {
                    report && report.images && report.images.map((val, idx)=>{
                      if(val.featured){
                        return(
                          <div>
                            <div key={idx} className='town-image-select'>
                              <img
                                src={val.url}
                              />
                            </div>
                            <h5>Featured</h5>
                          </div>
                        )
                      }
                    })
                  }
                </div> */}
                
            </div>
            

            <div className="row">
            <div className="col-md-12">
            <div className="grid-list-container grid-list-container-pad">
              <SortableComponent
                data={listItem} 
                onSortEnd={this.orderList}
                image={this.state.featuredImage}
                onRemove={(index) => this.remove(index)}
                onFeatured={(index) => this.featured(index)}
                imageRotate={(index) => this.imageRotate(index)}
                checkedClick={(index) => this.checkedClick(index)}
                shouldCancelStart={this.shouldCancelStart}
                />
            </div>
            </div>
            </div>


            <Dialog
              actions={actions}
              modal={false}
              open={this.state.openFeaturedDilog}
              onRequestClose={this.handleClose}
            >
              Do you want to mark it as featured?
            </Dialog>
            <Dialog
              actions={actions2}
              modal={false}
              open={this.state.openRemoveDilog}
              onRequestClose={this.handleClose}
            >
              Do you want to remove the image?
            </Dialog>
            <Snackbar open={this.state.openSnackbar} message={this.state.message} autoHideDuration={2000} onRequestClose={this.handleRequestClose} />
          </div>

      </section>
      </div>
    )
  }
}

export default reduxForm({
  form: 'Images', // a unique identifier for this form
  destroyOnUnmount: true
})(Images)