/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react'
import { CloseIcon, NotReadyTourIcon } from '../../../../../components/icons'
import './styles.scss'
import _, { get } from 'lodash'
import moment from 'moment'
import { fetch } from '../../../../../utils'
import AppointmentModel from '../../Appointment/container'
import UserContext from "../../../../App/Context"

const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
export default class clientDatarmationComponent extends PureComponent {
  static contextType = UserContext;
  constructor() {
    super()
    this.state = {
      modalIsOpen: false,
      clientData: [],
      clientDealData: [],
      status: '',
      clientId: 0
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0
    const { match: { params: { id } } } = this.props
    this.props.getAgentDeal({ client_id: parseInt(id) })
  }

  static getDerivedStateFromProps(props, state) {
    const { match: { params: { id } } } = props
    if (props.updateUserPhase === "success") {
      props.getClientById(parseInt(id))
      props.clearPhase()
    }
    if (state.clientData !== _.get(props.clientData, 'users', [])) {
      return { clientData: _.get(props.clientData, 'users', []), clientId: parseInt(id) }
    }
    if (state.clientDealData !== _.get(props.agentDealData, 'data[0]', [])) {
      let agentDealData = _.get(props.agentDealData, 'data[0]', []);
      let status = agentDealData && agentDealData.deal_status || '';
      if(status == '') {
        status = state.status
      }
      return { clientDealData: agentDealData, status }
    }
    return null;
  }

  //mobile number formatting
  formatNumber = (str) => {
    const cleaned = (`${str}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}`
    }
    return null
  }

  // on click operation perform appointment
  clickOnCreateAppointment = (event) => {
    this.setState({ appointmentModel: true, isEditAppointment: false })
  }

  // open close appointment model
  openAppointmentModel = () => {
    const { appointmentModel } = this.state
    this.setState({ appointmentModel: !appointmentModel, isEditAppointment: false })
  }

  // fetch all appointment when create/update appointment
  reFetchAppointment = () => {
    const user = this.context.userData
    if (user) {
      this.props.getAppointments({
        // eslint-disable-next-line camelcase
        user_id: user.id,
        timezone: user.time_zone
      })
    }

    this.setState({ appointmentModel: false, isEditAppointment: false })
  }

  selectedChange(clientData, e) {
    const { match: { params: { id } } } = this.props
    const status = e.target.value
    if (status !== "Select a Status") {
      this.setState({ status: status, clientId: parseInt(id), clientIsSendEmail: clientData.isSendEmail }, () => {
        if (status === "Initial Welcome Sent") {
          this.props.initialSelected(parseInt(id), clientData)
        } else if (status === "Accepted Offer") {
          this.dealClick(parseInt(id), clientData, "acceprt_offer_field")
        } else if (status === "Signed Contract") {
          this.dealClick(parseInt(id), clientData, "signed_contract_field")
        } else if (status === "Closed") {
          this.dealClick(parseInt(id), clientData, "closed_field")
        } else {
          const data1 = {
            client_id: parseInt(id),
            activity_status: status,
            isAcceptOffer: false,
            isSendEmail: this.state.isSendEmail
          }
          this.props.updateClientUser(data1)
        }
      })
    }
  }

  dealClick(clientID, clientData, field) {
    fetch(`${SUBURBANJUNGLE_API_HOST}/agent/clients/${clientID}/deals`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json())
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          if (field === "acceprt_offer_field") {
            this.props.deleteDealData(response.data, clientData, this.state.status, 'nothing')
          } else {
            const dealsData = _.sortBy(response.data, (o) => {
              return new moment(o.created).format('YYYY-MM-DD hh:mm:ss');
            }).reverse();
            this.props.editDealSection(dealsData[0], clientData, this.state.status)
          }
        } else {
          //true, false- model open close, add, edit- button type, optional- error message
          // eslint-disable-next-line no-lonely-if
          if (field === "acceprt_offer_field") {
            this.props.openDealModal(true, "add", "optional", clientData, this.state.status)
          } else {
            this.props.openDealModal(true, "add", "mandatory", clientData, this.state.status)
          }
        }
      })
      .catch((error) => {
      })
  }

  render() {
    const { clientData } = this.state
    let status = clientData && clientData.activity_status ? clientData.activity_status : '';
    let priceRange = `$${clientData.price_min ? parseInt(clientData.price_min).toLocaleString() : '0'} - $${clientData.price_max ? parseInt(clientData.price_max).toLocaleString() : '0'}`
    if (priceRange === "$0 - $0") {
      priceRange = "$0"
    }
    return (
      <div>
        <div className="mob__status__select">
          <div className="material-textfield mb__20">
            <select
              defaultValue={status}
              className={`form-control custom-select material-textfield-input textfield-input-lg uniQueselect-${this.state.clientId}`}
              required
              onChange={this.selectedChange.bind(this, clientData)}>
              <option>
                {(status !== "" && status !== "Null" && status !== "NULL" && status !== "Inspection Scheduled") ? status : 'Select a status'}
              </option>
              {
                (status !== "Initial Welcome Sent") &&
                <option value="Initial Welcome Sent">Initial Welcome Sent</option>
              }
              {
                (status !== "Actively Engaged") &&
                <option value="Actively Engaged">Actively Engaged</option>
              }
              {
                (status !== "Moderately Engaged") &&
                <option value="Moderately Engaged">Moderately Engaged</option>
              }
              {
                (status !== "Accepted Offer") &&
                <option value="Accepted Offer">Accepted Offer</option>
              }
              {
                (status !== "Signed Contract") &&
                <option value="Signed Contract">Signed Contract</option>
              }
              {
                (status !== "Closed") &&
                <option value="Closed">Closed</option>
              }
              {
                (status !== "Unresponsive") &&
                <option value="Unresponsive">Unresponsive</option>
              }
            </select>
            <label className="material-textfield-label label-lg">Status</label>
          </div>
        </div>

        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__3">{`${clientData.first_name} ${clientData.last_name}`}</h5>
              {
                clientData && clientData.secondary_name &&
                <p className="card-subtitle"><strong>Secondary Contact: </strong>{clientData.secondary_name}</p>
              }
            </div>
            {
              (_.get(this.context.userData, 'role') === "agent") &&
              <div className="cleint__information__cta">
                <div className="material-textfield">
                  <select
                    defaultValue={status}
                    value={status}
                    className={`form-control custom-select material-textfield-input client__info__status__select uniQueselect-${this.state.clientId}`}
                    onChange={this.selectedChange.bind(this, clientData)}>
                    <option>
                      {(status !== "" && status !== "Null" && status !== "NULL" && status !== "Inspection Scheduled") ? status : 'Select a status'}
                    </option>
                    {
                      (status !== "Initial Welcome Sent") &&
                      <option value="Initial Welcome Sent" disabled={status === "Accepted Offer" || status === "Signed Contract" || status === "Closed" ? true : false}>Initial Welcome Sent</option>
                    }
                    {
                      (status !== "Actively Engaged") &&
                      <option value="Actively Engaged" disabled={status === "Accepted Offer" || status === "Signed Contract" || status === "Closed" ? true : false}>Actively Engaged</option>
                    }
                    {
                      (status !== "Moderately Engaged") &&
                      <option value="Moderately Engaged" disabled={status === "Accepted Offer" || status === "Signed Contract" || status === "Closed" ? true : false}>Moderately Engaged</option>
                    }
                    {
                      (status !== "Accepted Offer") &&
                      <option value="Accepted Offer" disabled={status === "Accepted Offer" || status === "Signed Contract" || status === "Closed" ? true : false}>Accepted Offer</option>
                    }
                    {
                      (status !== "Signed Contract") &&
                      <option value="Signed Contract" disabled={status === "" || status === "Signed Contract" || status === "Closed" ? true : false}>Signed Contract</option>
                    }
                    {
                      (status !== "Closed") &&
                      <option value="Closed" disabled={status === "" || status === "Accepted Offer" || status === "Closed" ? true : false}>Closed</option>
                    }
                    {
                      (status !== "Unresponsive") &&
                      <option value="Unresponsive" disabled={status === "Accepted Offer" || status === "Signed Contract" || status === "Closed" ? true : false}>Unresponsive</option>
                    }
                  </select>
                  <label className="material-textfield-label">Status</label>
                </div>
                <button className="btn btn__btn btn-dark btn__create__appointment" onClick={this.clickOnCreateAppointment}>Create Appointment</button>
              </div>
            }
          </div>
          <div className="card-body card-body-sm">
            <div className="row">
              <div className="col-md-4">
                <div className="info__block">
                  <label>Strategist </label>
                  {
                    clientData && clientData.strategists && clientData.strategists.map((str, idx) => {
                      if (str && (str.role === 'strategist' || str.role === 'admin')) {
                        return (
                          <p key={idx}>{str.strategist_full_name}</p>
                        )
                      } else if ((clientData.strategist).length === 1 && str.role === 'assign_strategist') {
                        return (
                          <p key={idx}>{str.strategist_full_name}</p>
                        )
                      }
                    })
                  }
                </div>

                {
                  (_.get(this.context.userData, 'role') === "agent") ?
                    (
                      <span>
                        <div className="info__block">
                          <label>Price Range </label>
                          <p>{priceRange}</p>
                        </div>
                        <div className="info__block">
                          {clientData.rental_budget && clientData.rental_budget !== "" ? (<label>Rental Price </label>) : ''}
                          {clientData.rental_budget && clientData.rental_budget !== "" ? (<p>{`$${parseInt(clientData.rental_budget).toLocaleString()}`}</p>) : ''}
                        </div>
                      </span>
                    ) : ('')
                }

                {
                  clientData.share_info_to_all && clientData.share_info ? 
                  <ContactInfo clientData={clientData} formatNumber={this.formatNumber} /> : null
                }

                {
                  !clientData.share_info_to_all && clientData.share_info ? 
                  <ContactInfo clientData={clientData} formatNumber={this.formatNumber} /> : null
                }

                {/* {
                  (clientData.share_info_to_all === false) ?
                    (clientData.share_info === 1) && (
                      <span>
                        {
                          (clientData && clientData.email) &&
                          <div className="info__block">
                            <label>Client Email (primary)</label>
                            <p><a href={clientData.email ? `mailto:${clientData.email}` : ''} target="_blank">{clientData.email ? clientData.email : ''}</a></p>
                          </div>
                        }
                        {
                          (clientData && clientData.phone_mobile) &&
                          <div className="info__block">
                            <label>Client Phone Number (primary)</label>
                            <p><a href={`tel:${clientData.country_code}${clientData.phone_mobile.replace(/\D/g, '')}`}>{clientData && clientData.phone_mobile.replace(/\D/g, '') ? `${clientData.country_code} ${clientData.phone_mobile.replace(/\D/g, '').length === 10 ? this.formatNumber(clientData.phone_mobile.replace(/\D/g, '')) : clientData.phone_mobile.replace(/\D/g, '')}` : ''}</a></p>
                          </div>
                        }
                        {
                          (clientData && clientData.secondary_email) &&
                          <div className="info__block">
                            <label>Client Email (secondary)</label>
                            <p><a href={clientData.secondary_email ? `mailto:${clientData.secondary_email}` : ''}>{clientData.secondary_email ? clientData.secondary_email : ''}</a></p>
                          </div>
                        }
                        {
                          (clientData && clientData.secondary_phone_email) &&
                          <div className="info__block">
                            <label>Client Phone Number (secondary)</label>
                            <p><a href={`tel:${clientData.country_code}${clientData.secondary_phone_email.replace(/\D/g, '')}`}>{clientData && clientData.secondary_phone_email.replace(/\D/g, '') ? `${clientData.country_code} ${clientData.secondary_phone_email.replace(/\D/g, '').length === 10 ? this.formatNumber(clientData.secondary_phone_email.replace(/\D/g, '')) : clientData.secondary_phone_email.replace(/\D/g, '')}` : ''}</a></p>
                          </div>
                        }
                      </span>
                    ) :
                    <span>
                      {
                        (clientData && clientData.email) &&
                        <div className="info__block">
                          <label>Client Email (primary)</label>
                          <p><a href={clientData.email ? `mailto:${clientData.email}` : ''} target="_blank">{clientData.email ? clientData.email : ''}</a></p>
                        </div>
                      }
                      {
                        (clientData && clientData.phone_mobile) &&
                        <div className="info__block">
                          <label>Client Phone Number (primary)</label>
                          <p><a href={`tel:${clientData.country_code}${clientData.phone_mobile.replace(/\D/g, '')}`}>{clientData && clientData.phone_mobile.replace(/\D/g, '') ? `${clientData.country_code} ${clientData.phone_mobile.replace(/\D/g, '').length === 10 ? this.formatNumber(clientData.phone_mobile.replace(/\D/g, '')) : clientData.phone_mobile.replace(/\D/g, '')}` : ''}</a></p>
                        </div>
                      }
                      {
                        (clientData && clientData.secondary_email) &&
                        <div className="info__block">
                          <label>Client Email (secondary)</label>
                          <p><a href={clientData.secondary_email ? `mailto:${clientData.secondary_email}` : ''}>{clientData.secondary_email ? clientData.secondary_email : ''}</a></p>
                        </div>
                      }
                      {
                        (clientData && clientData.secondary_phone_email) &&
                        <div className="info__block">
                          <label>Client Phone Number (secondary)</label>
                          <p><a href={`tel:${clientData.country_code}${clientData.secondary_phone_email.replace(/\D/g, '')}`}>{clientData && clientData.secondary_phone_email.replace(/\D/g, '') ? `${clientData.country_code} ${clientData.secondary_phone_email.replace(/\D/g, '').length === 10 ? this.formatNumber(clientData.secondary_phone_email.replace(/\D/g, '')) : clientData.secondary_phone_email.replace(/\D/g, '')}` : ''}</a></p>
                        </div>
                      }
                    </span>
                } */}
              </div>

              <div className="col-md-8">
                <div className="info__block">
                  <label>Suburb </label>
                  {
                    clientData && clientData.suburbs && clientData.suburbs.map((suburbs, idx) => {
                      return (
                        <p key={idx}>{suburbs.suburbs_name}</p>
                      )
                    })
                  }
                  {/*<p>{clientData.suburb_name ? clientData.suburb_name : ''}</p>*/}
                </div>
                <div className="info__block">
                  <label>Assigned Towns </label>
                  {clientData && clientData.towns && clientData.towns.map((town, i) => {
                    const filteredTown = _.filter(this.props.notReadyForTownsTour, (t) => {
                      return t.town_name === town.town_name
                    })
                    return (
                      <p key={`${i}__towns__details`} className={_.size(filteredTown) ? 'text-orange' : ''}>
                        {_.size(filteredTown) ? (<NotReadyTourIcon className="nottouricon-icon" />) : ('')}
                        {town.town_name}
                      </p>
                    )
                  })}
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Create an appointment function */}
        <AppointmentModel
          {...this.props}
          // passing black event bcz there is no event to select
          event={{}}
          // selected appointment date is blank this option is optional
          selectAppointmentDate={moment()}
          // if appointment is edit send isEdit as true
          isEdit={false}
          // props for setting up client name
          clientName={`${get(clientData, 'id', false) ? clientData.id : ''}`}
          // open close state appointment model
          openAppointment={this.state.appointmentModel}
          // change open close appointment model
          closeAppointmentModal={this.openAppointmentModel}
          reFetchAppointment={this.reFetchAppointment}
        />
      </div>
    )
  }
}

const ContactInfo = ({ clientData, formatNumber }) => {
  return (
    <span>
      {clientData && clientData.email && (
        <div className="info__block">
          <label>Client Email (primary)</label>
          <p>
            <a
              href={clientData.email ? `mailto:${clientData.email}` : ''}
              target="_blank">
              {clientData.email ? clientData.email : ''}
            </a>
          </p>
        </div>
      )}
      {clientData && clientData.phone_mobile && (
        <div className="info__block">
          <label>Client Phone Number (primary)</label>
          <p>
            <a
              href={`tel:${
                clientData.country_code
              }${clientData.phone_mobile.replace(/\D/g, '')}`}>
              {clientData && clientData.phone_mobile.replace(/\D/g, '')
                ? `${clientData.country_code} ${
                    clientData.phone_mobile.replace(/\D/g, '').length === 10
                      ? formatNumber(
                          clientData.phone_mobile.replace(/\D/g, '')
                        )
                      : clientData.phone_mobile.replace(/\D/g, '')
                  }`
                : ''}
            </a>
          </p>
        </div>
      )}
      {clientData && clientData.secondary_email && (
        <div className="info__block">
          <label>Client Email (secondary)</label>
          <p>
            <a
              href={
                clientData.secondary_email
                  ? `mailto:${clientData.secondary_email}`
                  : ''
              }>
              {clientData.secondary_email ? clientData.secondary_email : ''}
            </a>
          </p>
        </div>
      )}
      {clientData && clientData.secondary_phone_email && (
        <div className="info__block">
          <label>Client Phone Number (secondary)</label>
          <p>
            <a
              href={`tel:${
                clientData.country_code
              }${clientData.secondary_phone_email.replace(/\D/g, '')}`}>
              {clientData && clientData.secondary_phone_email.replace(/\D/g, '')
                ? `${clientData.country_code} ${
                    clientData.secondary_phone_email.replace(/\D/g, '')
                      .length === 10
                      ? formatNumber(
                          clientData.secondary_phone_email.replace(/\D/g, '')
                        )
                      : clientData.secondary_phone_email.replace(/\D/g, '')
                  }`
                : ''}
            </a>
          </p>
        </div>
      )}
    </span>
  );
};