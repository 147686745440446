/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react'
import ReactModal from "react-modal"
import { find, map, get } from "lodash"
import moment from "moment"
import AvailableTimes from "../../../../../../components/react-time-selector"
import Cookies from 'universal-cookie'
import { CloseIcon, DeleteIcon } from '../../../../../../components/icons'
import './styles.scss'

const cookies = new Cookies()
// eslint-disable-next-line func-style
function createMomentDate(hour, minute) {
  const date = moment().utc();
  date.set({
    date: date.startOf('week').add(1, 'day').date(),
    hour,
    minute,
    second: 0,
    millisecond: 0
  })
  return date.toISOString();
}
class CalendarComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteSelection: false,
      selections: [],
      modalIsOpen: false,
      timeSelectionChange: false,
      isEdit: false
    }
    this.changeClearSeleation = this.changeClearSeleation.bind(this)
  }
  static getDerivedStateFromProps(props, state) {
    if (props.initialEditTime && !state.timeSelectionChange) {
      const times = props.initialEditTime.availability ? props.initialEditTime.availability : []
      const selections = map(times, time => {
        if (time.start && time.end) {
          const start = moment.utc(time.start, "HH:mm").format("HH:mm")
          const end = moment.utc(time.end, "HH:mm").format("HH:mm")
          return { id: time.id,
          start, end
            // start: createMomentDate(start.hours(), start.minutes()),
            //   end: createMomentDate(end.hours(), end.minutes())
            }
        }
      })
      return { selections }
    }
    return props;
  }

  // close modal with selection toogle
  closeModal = () => {
    this.props.toogleAvailabilitySelectorModel()
  }
  // handle change on selections time
  handleChange = (selections) => {
    this.setState({ selections, timeSelectionChange: true })
  }
  // on save update avilablility time
  save = () => {
    const { selections } = this.state
    const { selectedDay } = this.props
    const days = [
      { id: "0", name: "Sunday" },
      { id: "1", name: "Monday" },
      { id: "2", name: "Tuesday" },
      { id: "3", name: "Wednesday" },
      { id: "4", name: "Thursday" },
      { id: "5", name: "Friday" },
      { id: "6", name: "Saturday" }
    ]
    const day = find(days, { name: selectedDay })
    const availability = map(selections, selection => {
      if (selection.start && selection.end) {
        const data = {}
        data.start = moment.utc(selection.start, 'HH:mm').format("HH:mm:00")
        data.end = moment.utc(selection.end, 'HH:mm').format("HH:mm:00")
        if (selection.id)
          data.id = selection.id
        return data
      }
    })
    // set selection blank
    this.setState({ selections: [], timeSelectionChange: false })
    const user = cookies.get('user')
    this.props.updateAgentTimeSlotAvailability({
      // eslint-disable-next-line camelcase
      user_id: user.id,
      availabilities: [{ availability, day: day.id }]
    })
    this.props.toogleAvailabilitySelectorModel()
  }
  // remove all selection
  deleteSelections = () => {
    this.setState({ selections: [], clearSelection: true })
  }
  changeClearSeleation = () => {
    this.setState({ clearSelection: false, selections: [] })
  }

  render() {
    const { selectedDay } = this.props
    const { selections, clearSelection } = this.state
    return (
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Availability: {selectedDay} </h5>
          <button type="button" className="btn react-modal-close" onClick={this.closeModal}><CloseIcon /></button>
        </div>
        <div className="">
          <AvailableTimes
            timeConvention="12h"
            timeZone={'UTC'}
            height={750}
            clearSelection={clearSelection}
            changeClearSeleation={this.changeClearSeleation}
            weekStartsOn="monday"
            start={new Date()}
            onChange={this.handleChange}
            initialSelections={selections}
            availableHourRange={{ start: 1, end: 24 }}
          />
        </div>
        <div className="modal-footer">
          <button className="btn btn__btn btn-trash" onClick={this.deleteSelections}><DeleteIcon className="delete-icon" /></button>
          <button className="btn btn__btn btn-cancel" onClick={this.closeModal}>Cancel</button>
          <button className="btn btn__btn btn-save" style={{backgroundColor: "#49A44F"}} onClick={this.save}>Save</button>
        </div>
      </div>
    );
  }
}
export default CalendarComponent
