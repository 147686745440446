import React, { PureComponent /*, PropTypes*/ } from 'react'
import { Table, Spin } from 'antd'
import TopNavigation from '../../../../../../components/TopNavigation/container'
import AdministrationSidebarNavigation from '../../../../../../components/AdministrationSidebarNavigation/component'
import { BackArrowIcon, ExportIcon } from '../../../../../../components/icons'
import './styles.scss'
import moment from 'moment'
import _ from 'lodash'
import Cookies from 'universal-cookie'
//This is for Download All Data into Excel Sheet
import ReactExport from "react-data-export"
import ReactModal from 'react-modal'
import Loader from '../../../../../../components/Loader'
import ReportCustomeDatePicker from '../../../../Strategist/Common/reportCustomeDatePicker';
import Config from '../../../../../../config';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const cookies = new Cookies()
let _user = ''

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `Jane Doe ${i}`,
    age: 32,
    address: `$2,000,000 ${i}`,
  });
}
export default class LoginRecordsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      users_limit:100,
      activePage:1,
      sortByKey: '',
      sortByType : 0,
      isLoading: false,
      search: '',
      todayDate: moment().format('LL'),
      loginTableData: [],
      showAll: true,
      selectedIndex: 'all'
    }
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    _user = cookies.get('user')
    if(_user) {
      if(_user.role==='client'){
        this.props.history.push('/clients')
      }else if(_user.role==='agent'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='local'){
       this.props.history.push('/agent/dashboard')
      }else if(_user.role==='strategist'){
        this.props.history.push('/administration/dashboard')
      }
      if (this.state.selectedIndex === "all") {
        const LoginRecordsParams = {}
        LoginRecordsParams.users_limit = this.state.users_limit;
        LoginRecordsParams.users_offset = this.state.activePage;
        LoginRecordsParams.from = moment().add(-5, 'years').format("YYYY-MM-DD HH:mm:ss")
        LoginRecordsParams.to = moment(this.state.todayDate).format("YYYY-MM-DD HH:mm:ss")
        LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
        this.props.getLoginRecord(LoginRecordsParams)
      }
    }
  }

  componentDidUpdate(preProps, preState){
    const { loginRecordPhase, loginRecordData, clearReportV2Phase } = this.props
    if(loginRecordPhase === "success"){
      clearReportV2Phase()
      let data = []
      if(_.size(_.get(loginRecordData, 'users', '')) > 0){
        _.get(loginRecordData, 'users', '') && _.get(loginRecordData, 'users', '').map((login, i)=>{
          data.push({
            key: i,
            full_name: login.full_name,
            role: login.role,
            login_counts: login.login_counts,
            current_login_date: login.current_login_date
          })
        })
      }
      this.setState({ loginTableData: data, isLoading: false })
    }
    if(loginRecordPhase === "error"){
      clearReportV2Phase()
      this.setState({ loginTableData: [], isLoading: false })
    }
  }

  onSearch(e){
    this.setState({search:e.target.value})
  }

  changeLimit = limit => {
    this.setState({ isLoading: true })

    const LoginRecordsParams = {}
    LoginRecordsParams.users_limit = limit;
    LoginRecordsParams.users_offset = this.state.activePage;
    LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
    this.props.getLoginRecord(LoginRecordsParams)
  }

  // change report by date
  filterByDate = ({ from, to, val }) => {
    this.setState({ isLoading: true, selectedIndex: val, fromDate: from, toDate: to })
    const LoginRecordsParams = {}
        LoginRecordsParams.users_limit = this.state.users_limit;
        LoginRecordsParams.users_offset = this.state.activePage;
        LoginRecordsParams.from = moment(from).format("YYYY-MM-DD HH:mm:ss")
        LoginRecordsParams.to = moment(to).format("YYYY-MM-DD HH:mm:ss")
        LoginRecordsParams.sortBy = {key: this.state.sortByKey, type: this.state.sortByType};
        this.props.getLoginRecord(LoginRecordsParams)
  }

  render() {
    const { loginTableData } = this.state
    let loginRecords = loginTableData
    if(_.size(loginTableData) > 0){
      loginRecords = loginTableData.filter(
        (LoginRecordAllData) =>{
          return (
            (LoginRecordAllData.full_name.toLowerCase().indexOf(this.state.search.toLowerCase())) !== -1
          );
        }
      );
    }

    return (
      <div>
        <div className="layout-has-sidebar">
          <AdministrationSidebarNavigation {...this.props}/>
          <TopNavigation {...this.props}/>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row d__flex align__items__center">
                  <div className="col-xs-6 col-md-4">
                    <button onClick={() => this.props.history.push(`/administration/dashboard`)} className="btn btn-back"><BackArrowIcon className="arrow-icon"/>Back to Dashboard</button>
                  </div>
                  {/* <div className="col-xs-6 col-md-8 text-right">
                    <div className="material-textfield results__select__dropdown">
                      <select
                        onChange={e =>
                          this.changeLimit(_.get(e, 'target.value'))
                        }
                        defaultValue={100}
                        className="form-control custom-select material-textfield-input results__select"
                      >
                        {!_.isEmpty(Config.resultsCountOptions) &&
                          Config.resultsCountOptions.map((page, i) => {
                            return (
                              <option key={i} value={_.get(page, 'value')}>
                                {_.get(page, 'label', '')}
                              </option>
                            );
                          })}
                      </select>
                      <label className="material-textfield-label">Results</label>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                     <h2 className="reports__title">Login Records</h2>
                     <ExcelFile element={<button className="btn btn__btn btn__export"><ExportIcon className="mr__15"/> Export</button>}>
                      <ExcelSheet data={loginRecords} name="login_records">
                          <ExcelColumn label="Full Name" value="full_name"/>
                          <ExcelColumn label="User Type" value="role"/>
                          <ExcelColumn label="Times Logged In" value="login_counts"/>
                          <ExcelColumn label="Last Login Date" value="current_login_date"/>
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search__form mt__0">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      name="search"
                      onChange={this.onSearch.bind(this)}
                      placeholder="Search by client name... "
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <ReportCustomeDatePicker {...this.state} filterByDate={this.filterByDate} />
                </div>
              </div>
              <Spin size="large" spinning={this.state.isLoading} indicator={<Loader />}>
                {
                  (_.size(loginRecords) > 0) ?
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Table
                          className="table__fixed"
                          sortOrde={true}
                          columns={[
                            {
                              title: 'Full Name',
                              width: 250,
                              dataIndex: 'full_name',
                              key: '1',
                              fixed: 'left',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.full_name.localeCompare(b.full_name)
                            },
                            {
                              title: 'User Type',
                              width: 180,
                              dataIndex: 'role',
                              key: '2',
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.role.localeCompare(b.role)
                            },
                            {
                              title: 'Times Logged In',
                              dataIndex: 'login_counts',
                              key: '3',
                              width: 220,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.login_counts - b.login_counts
                            },
                            {
                              title: 'Last Login Date',
                              dataIndex: 'current_login_date',
                              key: '4',
                              width: 300,
                              showSorterTooltip: false,
                              sortDirections: ['descend', 'ascend','descend'],
                              sorter: (a, b) => a.current_login_date.localeCompare(b.current_login_date),
                              render: (text, c) => {
                                return(
                                  <span>{c.current_login_date ? moment(c.current_login_date).format('MMMM Do YYYY, h:mm a'):'N/A'}</span>
                                )
                              }
                            }
                          ]}
                          dataSource={loginRecords}
                          pagination={{ defaultPageSize: Config.defaultPageSize, showSizeChanger: true, pageSizeOptions: Config.pageSizeOptions}}
                          scroll={{ y: 420 }}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="blank__table__state">Data Not Found</div>
                }
              </Spin>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
