import { connect } from 'react-redux'

import UpdateProfileComponent from './component'
import { updateProfile } from '../../../../store/user/duck'

const UpdateProfileContainer = connect(
  // Map state to props
  (/*state*/) => ({

  }),
  // Map actions to dispatch and props
  {
  	updateProfile
  }
)(UpdateProfileComponent)

export default UpdateProfileContainer
