/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Popover, DatePicker } from 'antd'
import Cookies from "universal-cookie";
import { get, map, find, filter } from 'lodash'
import { FilterIcon } from "../../../../../../components/icons"

const cookies = new Cookies();

export default class FilterUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadPageFromDashboard: false,
      clientType: "all_clients",
      userRole: 'superadmin',
      visible: false,
      hideInactive: false,

      // Current subgroup selected values
      clientStatus: [],
      clientSuburb: [],
      clientStrategist: [],
      clientTiming: [],
      clientAgent: [],
      clientTown: [],
      clientBizDevCredit: [],

      // Value of subgroup search filter
      searchStatus: "",      
      searchAgent: "",
      searchSuburb: "",
      searchTown: "",
      searchBizDevCredit: "",
      searchCurrentLocation: "",

      // Subgroup checkbox
      status: false,
      strategists: false,
      agents: false,
      suburb: false,
      towns: false,
      createdDate: false,
      bizDevCredits: false,
      currentLocation: false,

      // Expand subgroup 
      statusDropdown: false,
      strategistsDropDown: false,
      filterStrategistDropdown: false,
      filterSuburbDropdown: false,
      filterTownDropdown: false,
      filterBizDevCreditDropdown: false,
      filterCurrentLocationDropdown: false,
      
      // Subgroup values 
      filteredStrategists: [],
      filteredSuburb: [],
      filterSearch: [],
      filteredAgents: [],      
      selectedCreatedDate: "",      
      selectedEndDate: "",
      filteredTimings: [],
      filteredTowns: [],
      filteredBizDevCredits: [],      

      // Subgroup selected 
      filteredClientTiming: [],
      filteredClientStrategist: [],
      filteredClientAgent: [],
      filteredClientSuburb: [],
      filteredClientTown: [],
      filteredClientBizDevCredit: [],

      filterCount: 0,
      activePage: 1,

      sortByKey: "",
      sortByType: 0,

      timingCheckBox: false,
      filterTimingsDropDown: false,

      // searchTimings: "",
    }

  }

  componentDidMount() {
   
    if (this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.to) {
      const { from, to } = this.props.location.state;
      localStorage.setItem('from_date', from);
      this.handleDateChange(moment.utc(from).format('MM/DD/YYYY'), 'start');
      this.handleDateChange(to, 'end');
    }


    const user = cookies.get("user");
    if (user && user.role === "strategist") {
      this.setState({ clientType: 'my_clients', userRole: "strategist" });
    }
    const localclientdata = JSON.parse(localStorage.getItem("clientFilterData"));
    if (user && localclientdata) {
      this.setState({

        // Subgroup checkbox
        status: localclientdata && localclientdata.clientStatus && localclientdata.clientStatus.length > 0,
        strategists: localclientdata && localclientdata.clientStrategist && localclientdata.clientStrategist.length > 0,
        agents: localclientdata && localclientdata.clientAgent && localclientdata.clientAgent.length > 0,
        suburb: localclientdata && localclientdata.clientSuburb && localclientdata.clientSuburb.length > 0,
        towns: localclientdata && localclientdata.clientTown && localclientdata.clientTown.length > 0,
        bizDevCredits: localclientdata && localclientdata.clientBizDevCredit && localclientdata.clientBizDevCredit.length > 0,
        timingCheckBox: localclientdata && localclientdata.clientTiming && localclientdata.clientTiming.length > 0,
        currentLocation: localclientdata && localclientdata.clientCurrentLocation && localclientdata.clientCurrentLocation.length > 0,
        
        // Subgroup expansion 
        statusDropdown: localclientdata && localclientdata.clientStatus && localclientdata.clientStatus.length > 0,
        filterStrategistDropdown: localclientdata && localclientdata.clientStrategist && localclientdata.clientStrategist.length > 0,
        filterTimingsDropDown: localclientdata && localclientdata.clientTiming && localclientdata.clientTiming.length > 0,
        filterSuburbDropdown: localclientdata && localclientdata.clientSuburb && localclientdata.clientSuburb.length > 0,
        filterTownDropdown: localclientdata && localclientdata.clientTown && localclientdata.clientTown.length > 0,
        filterBizDevCreditDropdown: localclientdata && localclientdata.clientBizDevCredit && localclientdata.clientBizDevCredit.length > 0,
      
        // Current subgroup selected values        
        clientStatus: localclientdata && localclientdata.clientStatus ? localclientdata.clientStatus : [],        
        clientAgent: localclientdata && localclientdata.clientAgent ? localclientdata.clientAgent : [],
        clientTown: localclientdata && localclientdata.clientTown ? localclientdata.clientTown : [],
        clientBizDevCredit: localclientdata && localclientdata.clientBizDevCredit ? localclientdata.clientBizDevCredit : [],
        clientCurrentLocation: localclientdata && localclientdata.clientCurrentLocation ? localclientdata.clientCurrentLocation : [],

        // Subgroup filtered values
        filteredStrategists: localclientdata && localclientdata.clientStrategist ? localclientdata.clientStrategist : [],
        filteredAgents: localclientdata && localclientdata.clientAgent ? localclientdata.clientAgent : [],
        filteredTimings: localclientdata && localclientdata.clientTiming ? localclientdata.clientTiming : [],
        filteredSuburb: localclientdata && localclientdata.clientSuburb ? localclientdata.clientSuburb : [],
        filteredTowns: localclientdata && localclientdata.clientTowns ? localclientdata.clientTowns : [],
        filteredBizDevCredits: localclientdata && localclientdata.clientBizDevCredits ? localclientdata.clientBizDevCredits : [],
        filterCount: localclientdata && localclientdata.filterCount ? localclientdata.filterCount : 0,

        selectedCreatedDate: localclientdata && localclientdata.startDate ? moment.utc(localclientdata.startDate) : "",
        selectedEndDate: localclientdata && localclientdata.endDate ? moment.utc(localclientdata.endDate) : "",
        createdDate: localclientdata && localclientdata.endDate !== "" && localclientdata.startDate !== "",

        searchCurrentLocation: localclientdata && localclientdata.clientCurrentLocation ? localclientdata.clientCurrentLocation : ""

      })
    }

  }

  static getDerivedStateFromProps(props, state) {
   
    const pageState = {
      totalPages: Math.round(
        props.clientsData &&
        props.clientsData.user_counts &&
        state.clientType &&
        props.clientsData.user_counts[state.clientType]
      ),
      clientLimit: props.resultEntry
    };
    if (get(props, "location.state.filter", false)) {
      const clientStatus = []
      switch (props.location.state.filter) {
        case "Call Scheduled":
          clientStatus.push("Call Scheduled")
          break;
        case "Green":
          clientStatus.push("Green")
          break;
        case "Green - Actively Engaged":
          clientStatus.push("Green - Actively Engaged")
          break;
        case "Green - Not Very Engaged":
          clientStatus.push("Green - Not Very Engaged")
          break;
        case "Green - Somewhat Engaged":
          clientStatus.push("Green - Somewhat Engaged")
          break;
        case "Never Had A Call":
          clientStatus.push("Never Had A Call")
          break;
        case "Online-New":
          clientStatus.push("Online-New")
          break;
        case "Trying To Schedule":
          clientStatus.push("Trying To Schedule")
          break;
        default:
          break;
      }
      const user = cookies.get("user");
      const data = {
        pageLimit: 20,
        activePage: 1,
        clientStatus,
        clientStrategist: [],
        clientTiming: [],
        clientSuburb: [],
        clientAgent: [],
        clientType: user && user.role === "strategist" ? "my_clients" : "all_clients",
        hideInactive: false,
        sortBy: { key: "", type: 0 },
        startDate: "",
        endDate: "",
      }
      localStorage.setItem("strategistFilterData", JSON.stringify({ ...data, filterCount: 0 }));
      props.getClientList(data)
      props.setfilterParams({
        clientStatus,
        filteredTimings: [],
        filteredStrategists: [],
        filteredSuburb: [],
        filteredTowns: [],
        filteredBizDevCredits: [],
        filteredAgents: [], 
        startDate: "", 
        endDate: "", 
        filterCount: 1 
      })
      props.history.replace({ pathname: '/strategist/clients/all-clients', state: {} })
      return { ...state, clientStatus, statusDropdown: true, status: true, filterCount: 1, ...pageState }
    }
    return { ...state, ...pageState }
  }
  // on click of filter item
  filterClick = (filterType, e) => {

    if (e) e.preventDefault();
    
    let filterCount = this.state.filterCount
    
    const { 
      filteredTimings,
      filteredStrategists,
      filteredSuburb,
      filteredAgents,
      filteredTowns,
      filteredBizDevCredits,
      clientStatus,
      selectedCreatedDate,
      selectedEndDate,
      searchCurrentLocation
    } = this.state
    const { filterParams } = this.props;

    switch (filterType) {
      case "status": {
        const filterSearch = filterParams && filterParams.data && filterParams.data.statuses;
        filterCount = this.state.status ? filterCount - 1 : filterCount + 1
        if (this.state.status) {
          this.setState({ status: false, statusDropdown: false, filteredTimings: [], filterSearch })
          this.props.setfilterParams({ 
            clientStatus: [], 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,            
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({ status: true, statusDropdown: true, filterSearch })
        }
        break
      }
      case "timings": {
        const filteredClientTiming = this.props.timings;
        filterCount = this.state.timingCheckBox ? filterCount - 1 : filterCount + 1
        if (this.state.timingCheckBox) {
          this.setState({ timingCheckBox: false, filterTimingsDropDown: false, filteredTimings: [], filteredClientTiming })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings: [], 
            filteredStrategists,
            filteredAgents,            
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({ timingCheckBox: true, filterTimingsDropDown: true, filteredClientTiming })
        }        
        break
      }      
      case "strategist": {
        const filteredClientStrategist = this.getAllStrategists();
        filterCount = this.state.strategists ? filterCount - 1 : filterCount + 1
        if (this.state.strategists) {
          this.setState({
            strategists: false,
            filterStrategistDropdown: false,
            filteredStrategists: [],
            filteredClientStrategist
          })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists: [],
            filteredAgents,
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({
            strategists: true,
            filterStrategistDropdown: true,
            filteredClientStrategist
          })
        }        
        break
      }
      case "suburb": {
        const filteredClientSuburb = filterParams && filterParams.data && filterParams.data.suburbs;
        filterCount = this.state.suburb ? filterCount - 1 : filterCount + 1
        if (this.state.suburb) {
          this.setState({
            suburb: false,
            filterSuburbDropdown: false,
            filteredSuburb: [],
            filteredClientSuburb
          })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,
            filteredSuburb: [],
            filteredTowns,
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({
            suburb: true,
            filterSuburbDropdown: true,
            filteredClientSuburb
          })
        }
        break
      }
      case "createdDate": {
        filterCount = this.state.createdDate ? filterCount - 1 : filterCount + 1
        if (this.state.createdDate) {
          this.setState({ createdDate: false, selectedCreatedDate: "" })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits,
            startDate: "",
            endDate: "",
            filterCount 
          })
        } else {
          this.setState({ createdDate: true })
        }        
        break
      }
      case "agent": {
        const filteredClientAgent = filterParams.data.agents
        filterCount = this.state.agents ? filterCount - 1 : filterCount + 1
        if (this.state.agents) {
          this.setState({
            agents: false,
            filterAgentDropdown: false, 
            filteredAgent: [],
            filteredClientAgent
          })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({
            agents: true,
            filterAgentDropdown: true,
            filteredClientAgent
          })
        }
        break     
      }
      case "town": {
        const filteredClientTown = filterParams.data.towns
        filterCount = this.state.towns ? filterCount - 1 : filterCount + 1        
        if (this.state.towns) {
          this.setState({
            towns: false,
            filterTownDropdown: false,
            filteredClientTown
          })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,
            filteredSuburb,
            filteredTowns: [],
            filteredBizDevCredits,
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({
            towns: true,
            filterTownDropdown: true,
            filteredClientTown
          })
        }
        break
      }
      case "bizDevCredit":
        const filteredClientBizDevCredit = filterParams.data.bizDevCredits
        filterCount = this.state.bizDevCredits ? filterCount - 1 : filterCount + 1
        if (this.state.bizDevCredits) {
          this.setState({
            bizDevCredits: false,
            filterBizDevCreditDropdown: false,
            filteredClientBizDevCredit
          })
          this.props.setfilterParams({ 
            clientStatus, 
            filteredTimings, 
            filteredStrategists,
            filteredAgents,
            filteredSuburb,
            filteredTowns,
            filteredBizDevCredits: [],
            startDate: selectedCreatedDate,
            endDate: selectedEndDate,
            filterCount 
          })
        } else {
          this.setState({
            bizDevCredits: true,
            filterBizDevCreditDropdown: true,
            filteredClientBizDevCredit
          })
        }
        break
      case "currentLocation": {
        filterCount = this.state.currentLocation ? filterCount - 1 : filterCount + 1
        this.setState({currentLocation: !this.state.currentLocation})
        break
      }
      default: {
        break
      }
    }

    this.setState({ filterCount })
    this.forceUpdate()
  }

  clearFilter = () => this.setState({
    clientAgent: [],
    clientStatus: [],
    clientStrategist: [],
    clientSuburb: [],
    clientTiming: [],
    clientTown: [],
    clientBizDevCredit: [],
    createdDate: false,
    currentLocation: false,
    filterCount: 0,
    filteredClientStrategist: [],
    filteredClientSuburb: [],
    filteredClientTiming: [],
    filteredClientTown: [],
    filteredClientBizDevCredit: [],
    filteredStrategists: [],
    filteredSuburb: [],
    filteredTimings: [],
    filterStrategistDropdown: false,
    filterSuburbDropdown: false,
    filterTimingsDropDown: false,
    filterTownDropdown: false,
    filterBizDevCreditDropdown: false,
    searchStatus: "",
    searchCurrentLocation: "",
    selectedCreatedDate: "",
    selectedEndDate: "",
    selectedStrategist: [],
    status: false,
    statusDropdown: false,
    strategists: false,
    strategistsDropDown: false,
    suburb: false,
    towns: false,
    bizDevCredits: false,
    timingCheckBox: false,
    // searchTimings: "",

  }, () => {
   
    this.props.setfilterParams({
      clientStatus: [],
      filteredTimings: [],
      filteredStrategists: [],
      filteredTimings: [],
      filteredSuburb: [],
      filteredTown: [],
      filteredBizDevCredit: [],
      startDate: "",
      endDate: "",
      filterCount: 0,
    })

    this.fetchFilterResult()
  });

  resetHeadFilterParams = data => {
   
    let filterCount = 0;

    filterCount += data.selectedClientStatus && data.selectedClientStatus.length > 0 ? 1 : 0
    filterCount += data.selectedClientStrategists && data.selectedClientStrategists.length > 0 ? 1 : 0
    filterCount += data.selectedClientTimings && data.selectedClientTimings.length > 0 ? 1 : 0
    filterCount += data.selectedClientSuburbs && data.selectedClientSuburbs.length > 0 ? 1 : 0
    filterCount += data.selectedClientAgents && data.selectedClientAgents.length > 0 ? 1 : 0
    filterCount += data.selectedClientTowns && data.selectedClientTowns.length > 0 ? 1 : 0
    filterCount += data.selectedClientBizDevCredits && data.selectedClientBizDevCredits.length > 0 ? 1 : 0

    this.setState({
      // clientTiming: this.props.timings ? this.props.timings : [],
      agents: data.selectedClientAgents && data.selectedClientAgents.length > 0,
      clientStatus: data.selectedClientStatus ? data.selectedClientStatus : [],
      clientStrategist: get(data, 'selectedClientStrategists', []),
      clientSuburb: get(data, 'selectedClientSuburbs', []),
      clientTiming: data.selectedClientTimings ? data.selectedClientTimings : [],
      createdDate: data.startDate !== "" && data.endDate !== "",
      filterAgentDropdown: data.selectedClientAgents && data.selectedClientAgents.length > 0,
      filterBizDevCreditDropdown: data.selectedClientBizDevCredits && data.selectedClientBizDevCredits.length > 0,
      filterCount,
      filteredAgents: data.selectedClientAgents ? data.selectedClientAgents :  [],
      filteredStrategists: data.selectedClientStrategists ? data.selectedClientStrategists : [],
      filteredSuburb: data.selectedClientSuburbs ? data.selectedClientSuburbs : [],
      filteredTimings: data.selectedClientTimings ? data.selectedClientTimings : [],
      filteredTowns: data.selectedClientTowns ? data.selectedClientTowns : [],
      filteredBizDevCredits: data.selectedClientBizDevCredits ? data.selectedClientBizDevCredits : [],
      filterStrategistDropdown: data.selectedClientStrategists && data.selectedClientStrategists.length > 0,
      filterSuburbDropdown: data.selectedClientSuburbs && data.selectedClientSuburbs.length > 0,
      filteredTownDropdown: data.selectedClientTowns && data.selectedClientTowns.length > 0,
      filteredBizDevCreditDropdown: data.selectedClientBizDevCredits && data.selectedClientBizDevCredits.length > 0,
      filterTimingsDropDown: data.selectedClientTimings && data.selectedClientTimings.length > 0,
      selectedCreatedDate: data.startDate ? moment.utc(data.startDate) : "",
      selectedEndDate: data.endDate ? moment.utc(data.endDate) : "",
      status: data.selectedClientStatus && data.selectedClientStatus.length > 0,
      statusDropdown: data.selectedClientStatus && data.selectedClientStatus.length > 0,
      strategists: data.selectedClientStrategists && data.selectedClientStrategists.length > 0,
      suburb: data.selectedClientSuburbs && data.selectedClientSuburbs.length > 0,
      towns: data.selectedClientTowns && data.selectedClientTowns.length > 0,
      bizDevCredits: data.selectedClientBizDevCredits && data.selectedClientBizDevCredits.length > 0,
      timingCheckBox: data.selectedClientTimings && data.selectedClientTimings.length > 0,
    }, () => this.fetchFilterResult())
    if (filterCount === 0) {
      this.clearFilter()
    }
    this.forceUpdate()
  }

  fetchFilterResult = () => {
   
    const { activePage, filterCount, clientStatus, clientAgent, clientTown, clientBizDevCredit, clientTiming, clientStrategist, clientSuburb, sortByType, sortByKey, hideInactive, selectedCreatedDate, selectedEndDate } = this.state
    let { clientType } = this.state;
    const cType = get(this, 'props.location.pathname').includes('clients/all-clients') ? 'all_clients' : clientType

    const { resultEntry } = this.props;
    const user = cookies.get("user");
    if (user && user.role === "strategist") {
      clientType = "my_clients"
      this.setState({ clientType: "my_clients" });
    }
    const data = {
      clientType: cType,
      clientStatus,
      clientSuburb,
      clientTiming,
      clientAgent,
      clientStrategist,
      clientTown,
      clientBizDevCredit,
      hideInactive,
      pageLimit: resultEntry,
      activePage,
      startDate: selectedCreatedDate,
      endDate: selectedEndDate,
      sortBy: { key: sortByKey, type: sortByType }
    }
    const currentUrl = get(this.props, "location.pathname", "")
    if (currentUrl === '/strategist/clients' && currentUrl === '/strategist/clients/new-clients') {
      this.props.getClientList(data)
    } else if (currentUrl === '/strategist/clients/all-clients') {
      this.props.getClientList(data)
    }
    localStorage.setItem('clientFilterData', JSON.stringify({ ...data, filterCount}))
  }

  filterClient = (value, type, e) => {
   
    const { clientStatus, filteredStrategists, filteredTimings, filteredSuburb, filteredTowns, filteredBizDevCredits, selectedCreatedDate, selectedEndDate,  filteredAgents, filterCount, searchCurrentLocation } = this.state
    let currentLocationFilterValue = false
    switch (type) {

      case 'filterStatus': {
        if (clientStatus.indexOf(value) === -1) {
          clientStatus.push(value)
        } else {
          clientStatus.splice(clientStatus.indexOf(value), 1)
        }
        this.setState({ clientStatus })
        break
      }
      case 'filterTiming': {
        if (filteredTimings.indexOf(value) === -1) {
          filteredTimings.push(value)
        } else {
          filteredTimings.splice(filteredTimings.indexOf(value), 1)
        }
        this.setState({ filteredTimings, clientTiming: filteredTimings })        
        break
      }
      case 'filterStrategist': {
        if (filteredStrategists.indexOf(value) === -1) {
          filteredStrategists.push(value)
        } else {
          filteredStrategists.splice(filteredStrategists.indexOf(value), 1)
        }
        this.setState({ filteredStrategists, clientStrategist: filteredStrategists })        
        break
      }
      case 'filterSuburb': {
        if (filteredSuburb.indexOf(value) === -1) {
          filteredSuburb.push(value)
        } else {
          filteredSuburb.splice(filteredSuburb.indexOf(value), 1)
        }
        this.setState({ filteredSuburb, clientSuburb: filteredSuburb })        
        break
      }
      case 'filterAgent': {

        if (filteredAgents.indexOf(value) === -1) {
          filteredAgents.push(value)
        } else {
          filteredAgents.splice(filteredAgents.indexOf(value), 1)
        }
        this.setState({ filteredAgents, clientAgent: filteredAgents })                
        break
      }

      case 'filterTown': {
        if (filteredTowns.indexOf(value) === -1) {
          filteredTowns.push(value)
        } else {
          filteredTowns.splice(filteredTowns.indexOf(value), 1)
        }
        this.setState({filteredTowns, clientTowns: filteredTowns})
        break
      }

      case 'filterBizDevCredit': {
        if (filteredBizDevCredits.indexOf(value) === -1) {
          filteredBizDevCredits.push(value)
        } else {
          filteredBizDevCredits.splice(filteredBizDevCredits.indexOf(value), 1)
        }
        this.setState({filteredBizDevCredits, clientBizDevCredits: filteredBizDevCredits})
        break
      }

      case 'filterCurrentLocation': {
        if (e.target.value) {
          currentLocationFilterValue = e.target.value
        } else {
          currentLocationFilterValue = ''
        }
        this.setState({
          searchCurrentLocation: currentLocationFilterValue
        })
        break
      }

      default: {
        break
      }                              
    }
    
    const filterParams = { 
      clientStatus, 
      filteredTimings, 
      filteredStrategists, 
      filteredSuburb,
      filteredAgents,
      filteredTowns,
      filteredBizDevCredits,
      searchCurrentLocation: currentLocationFilterValue,
      startDate: selectedCreatedDate, 
      endDate: selectedEndDate, 
      filterCount 
    }
    
    this.props.setfilterParams(filterParams)
    this.forceUpdate()
    // this.fetchFilterResult()
  }

  // show visible filter
  filterVisible = visible => {
   
    const { filterParams } = this.props;
    const filterSearch = filterParams && filterParams.data && filterParams.data.statuses;
    const filteredClientStrategist = this.getAllStrategists();  
    const filteredClientTiming = this.props.timings;
    const filteredClientSuburb = filterParams && filterParams.data && filterParams.data.suburbs
    const filteredClientAgent = filterParams && filterParams.data && filterParams.data.agents
    const filteredClientTown = filterParams && filterParams.data && filterParams.data.towns
    const filteredClientBizDevCredit = filterParams && filterParams.data && filterParams.data.bizDevCredits
    this.setState({ visible, filterSearch, filteredClientTiming, filteredClientStrategist, filteredClientSuburb, filteredClientAgent, filteredClientTown, filteredClientBizDevCredit })
  }

  // Handle filter search input change
  handleFilterSearchInput = ({ target }) => {

    const { name, value } = target
    this.setState({ [name]: value })
    const { filterParams } = this.props;
   
    switch(name) {
      case "searchStatus": {
        const strategistStatus = filterParams && filterParams.data && filterParams.data.statuses;
        const filterSearch = filter(strategistStatus, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        this.setState({ filterSearch })
        break
      }

      case "searchTimings": {
        const timingStatus = this.props.timings
        const filteredClientTiming = filter(timingStatus, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        this.setState({ filteredClientTiming })
        break
      }

      case "searchStrategist": {
        const strategistStatus = this.getAllStrategists();
        const filteredClientStrategist = filter(strategistStatus, search => search.strategist_name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        this.setState({ filteredClientStrategist })
        break
      }

      case "searchSuburb": {
        const strategistStatus = filterParams && filterParams.data && filterParams.data.suburbs;
        const filteredClientSuburb = filter(strategistStatus, search => search.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        this.setState({ filteredClientSuburb })
        break
      }

      case "searchAgent": {
        const agents = filterParams && filterParams.data && filterParams.data.agents
        const filteredClientAgent = agents.filter(a => a.agent_name.toLowerCase().includes(value.toLowerCase()))
        this.setState({ filteredClientAgent })
        break
      }

      case "searchTown": {
        const towns = filterParams && filterParams.data && filterParams.data.towns
        const filteredClientTown = towns.filter(t => t.name.toLowerCase().includes(value.toLowerCase()))
        this.setState({ filteredClientTown })
        break
      }

      case "searchBizDevCredit": {
        const bizDevCredits = filterParams && filterParams.data && filterParams.data.bizDevCredits
        const filteredClientBizDevCredit = bizDevCredits.filter(bdc => bdc.name.toLowerCase().includes(value.toLowerCase()))
        this.setState({ filteredClientBizDevCredit })
        break
      }

      case "searchCurrentLocation": {
        if (currentLocationFilter) this.setState({ currentLocation: currentLocationFilter })
        break
      }
    }
    
    this.forceUpdate()
  }

  // change status close filter model with
  closeFilterModel = () => this.setState({ visible: !this.state.visible })


  // get all strategist
  getAllStrategists = () => {
   
    const { filterParams } = this.props;
    const auth = cookies.get("i_user");
    const user = cookies.get("user");
    let allStrategists = filterParams && filterParams.data && filterParams.data.strategists;
    if (
      auth &&
      auth.role !== "superadmin" &&
      auth.role !== "admin"
    ) {
      allStrategists = filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    } else if (!auth && user && user.role == "strategist") {
      allStrategists = filter(
        allStrategists,
        s => s.role !== "former-strategist-inactive"
      );
    }
    return allStrategists;
  }
  // handle change and filter data based on date
  handleDateChange = (date, type) => {

    const { clientStatus, selectedCreatedDate, filteredStrategists, filteredTimings, filteredTowns, filteredBizDevCredits, filteredAgents, filteredSuburb, filterCount, selectedEndDate } = this.state
    if (type === "start") {
      this.setState({ selectedCreatedDate: date, selectedEndDate: "" })
    }
    if (type === "end") {
      this.setState({ selectedEndDate: date }, () => {
        let new_date_ref = selectedCreatedDate;
        if (!selectedCreatedDate || selectedCreatedDate == '') {
          new_date_ref = localStorage.getItem('from_date');
        }
        this.props.setfilterParams({
          clientStatus,
          filteredTimings,
          filteredStrategists,
          filteredTimings,
          filteredSuburb,
          filteredAgents,
          filteredTowns,
          filteredBizDevCredits,
          startDate: moment.utc(new_date_ref).format("YYYY-MM-DD"),
          endDate: moment.utc(date).format("YYYY-MM-DD"),
          filterCount
        })
      })
    }
    this.forceUpdate()
  }

  render() {
   
    const { 
      userRole, 
      filteredClientAgent,
      filteredClientSuburb, 
      filteredClientStrategist,
      filteredClientTiming,
      filteredClientTown,
      filteredClientBizDevCredit,
      searchAgent,
      searchStatus, 
      searchTimings, 
      searchStrategist, 
      searchSuburb,
      searchTown,
      searchBizDevCredit,      
      searchCurrentLocation,
      filterSearch,
      filterStrategistDropdown,
      filterTimingsDropDown,
      filterSuburbDropdown,
      filterAgentDropdown,
      filterTownDropdown,
      filterBizDevCreditDropdown,
    } = this.state;

    const displayClientTown = filteredClientTown.slice(0, 10)
    return (
      <Popover
        content={
          <div className="filter">
            <div className="filter__header">
              <button className="btn btn__btn btn-secondry btn__xs" onClick={this.clearFilter}>Clear</button>
              <div className="filter__title">Filters</div>
              <button className="btn btn__btn btn-dark btn__xs" onClick={this.closeFilterModel}>Close</button>
            </div>
            <div className="filter__body">
              <ul className="filter__list">
                <li>
                  <label onClick={this.filterClick.bind(this, "status")} className="custom-checkbox custom-checkbox-line">
                    Status
                    <input
                      type="checkbox" name="status"
                      checked={this.state.status}
                      onChange={this.handleFilterSearchInput}
                      className="custom-checkbox--input"
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {this.state.statusDropdown &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.handleFilterSearchInput}
                        name="searchStatus"
                        placeholder="Search for status..."
                        value={searchStatus} />
                    </div>
                    {filterSearch &&
                      map(filterSearch, (client, clientIdx) => {
                        const findSelected = find(this.state.clientStatus, o => o === client.name)
                        return (
                          <li key={`status__${clientIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {client.name}
                              <input
                                type="checkbox"
                                checked={findSelected ? true : false} className="custom-checkbox--input"
                                onClick={this.filterClient.bind(this, client.name, 'filterStatus')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                }
                <li>
                  <label onClick={this.filterClick.bind(this, "timings")} className="custom-checkbox custom-checkbox-line">
                    Timing
                    <input
                      type="checkbox" name="timings"
                      checked={this.state.timingCheckBox}
                      onChange={this.handleFilterSearchInput}
                      className="custom-checkbox--input"
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {filterTimingsDropDown &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.handleFilterSearchInput}
                        name="searchTimings"
                        placeholder="Search for status..."
                        value={searchTimings} />
                    </div>
                    {filteredClientTiming &&
                      map(filteredClientTiming, (client, clientIdx) => {
                        const findSelected = find(this.state.filteredTimings, o => o === client.name)
                        return (
                          <li key={`timing__${clientIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {client.name}
                              <input
                                type="checkbox"
                                checked={findSelected ? true : false} className="custom-checkbox--input"
                                onClick={this.filterClient.bind(this, client.name, 'filterTiming')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                }
                {userRole !== "strategist" && <li>
                  <label onClick={this.filterClick.bind(this, "strategist")} className="custom-checkbox custom-checkbox-line">
                    Strategist
                    <input
                      type="checkbox"
                      name="strategists"
                      checked={this.state.strategists}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>}
                {filterStrategistDropdown &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.handleFilterSearchInput}
                        name="searchStrategist"
                        placeholder="Search for strategist..."
                        value={searchStrategist}
                      />
                    </div>
                    {filteredClientStrategist &&
                      map(filteredClientStrategist, (client, clientIdx) => {
                        const findSelected = find(this.state.filteredStrategists, o => o === client.id)
                        return (
                          <li key={`strategists__${clientIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {client.strategist_name}
                              <input
                                type="checkbox"
                                checked={findSelected ? true : false} className="custom-checkbox--input"
                                onClick={this.filterClient.bind(this, client.id, 'filterStrategist')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                }

                <li>
                  <label onClick={this.filterClick.bind(this, "town")} className="custom-checkbox custom-checkbox-line">
                    Town
                    <input
                      type="checkbox"
                      name="searchTown"
                      checked={this.state.towns}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                      value={filterTownDropdown}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>


                {filterTownDropdown &&
                  <ul className="filter__list__inner">

                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.handleFilterSearchInput}
                        name="searchTown"
                        placeholder="Search for town..."
                        value={searchTown} 
                      />
                    </div>

                    {map(displayClientTown, (town, townIdx) => {
                      const optionSelected = find(this.state.filteredTowns, t => t === town.id)
                      return (
                        <li key={`towns__${townIdx}`} >
                          <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                            {town.name}
                            <input
                              type="checkbox"
                              checked={optionSelected}
                              className="custom-checkbox--input"
                              onClick={this.filterClient.bind(this, town.id, 'filterTown')}
                              readOnly
                            />
                            <span className="custom-checkbox-checkmark"></span>
                          </label>
                        </li>
                      )
                    })
                    }
                  </ul>
                }



                <li>
                  <label onClick={this.filterClick.bind(this, "agent")} className="custom-checkbox custom-checkbox-line">
                    Agent
                    <input
                      type="checkbox"
                      name="searchAgent"
                      checked={this.state.agents}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                      value={this.state.searchAgent}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>
                {filterAgentDropdown &&
                <ul className="filter__list__inner">
                  <div className="search__form mt__10 mb__10">
                    <input
                      type="text"
                      className="form-control search__form__input"
                      onChange={this.handleFilterSearchInput}
                      name="searchAgent"
                      placeholder="Search for agent..."
                      value={searchAgent}
                    />
                  </div>

                  {map(filteredClientAgent, (agent, agentIdx) => {
                    const optionSelected = find(this.state.filteredAgents, a => a === agent.id)
                    return (
                      <li key={`agents__${agentIdx}`} >
                        <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                          {agent.agent_name}
                          <input
                            type="checkbox"
                            checked={optionSelected}
                            className="custom-checkbox--input"
                            onClick={this.filterClient.bind(this, agent.id, 'filterAgent')}
                            readOnly
                          />
                          <span className="custom-checkbox-checkmark"></span>
                        </label>
                      </li>
                    )
                  })}
                </ul>
                }



                {userRole !== "strategist" && <li>
                  <label onClick={this.filterClick.bind(this, "suburb")} className="custom-checkbox custom-checkbox-line">
                    Suburb
                    <input type="checkbox"
                      name="suburb"
                      checked={this.state.suburb}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>}
                {filterSuburbDropdown &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.handleFilterSearchInput}
                        name="searchSuburb"
                        placeholder="Search for suburbs..."
                        value={searchSuburb}
                      />
                    </div>
                    {filteredClientSuburb &&
                      map(filteredClientSuburb, (suburb, suburbIdx) => {
                        const findSelected = find(this.state.filteredSuburb, o => o === suburb.id)
                        return (
                          <li key={`suburb__${suburbIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {suburb.name}
                              <input
                                type="checkbox"
                                checked={findSelected ? true : false} className="custom-checkbox--input"
                                onClick={this.filterClient.bind(this, suburb.id, 'filterSuburb')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                }

                <li>
                  <label onClick={this.filterClick.bind(this, "bizDevCredit")} className="custom-checkbox custom-checkbox-line">
                    Biz Dev Credit
                    <input
                      type="checkbox"
                      name="searchBizDevCredit"
                      checked={this.state.bizDevCredits}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                      value={this.state.searchBizDevCredit}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>

                { this.state.bizDevCredits &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      {/* Strategist users only get one option, so there's no need for an options filter */}
                      {userRole.includes('admin') && 
                        <input
                          type="text"
                          className="form-control search__form__input"
                          onChange={this.handleFilterSearchInput}
                          name="searchBizDevCredit"
                          placeholder="Search for Clients giving Biz Dev Credit to these Strategists"
                          value={searchBizDevCredit}
                        />
                      }
                    </div>
                    {filterBizDevCreditDropdown &&
                      map(filteredClientBizDevCredit, (bizDevCredit, bizDevCreditIdx) => {
                        const findSelected = find(this.state.filteredBizDevCredits, o => o === bizDevCredit.id)
                        return (
                          <li key={`bizDevCredit__${bizDevCreditIdx}`} >
                            <label style={{ fontSize: "14px" }} className="custom-checkbox custom-checkbox-line">
                              {bizDevCredit.name}
                              <input
                                type="checkbox"
                                checked={findSelected ? true : false} className="custom-checkbox--input"
                                onClick={this.filterClient.bind(this, bizDevCredit.id, 'filterBizDevCredit')}
                                readOnly
                              />
                              <span className="custom-checkbox-checkmark"></span>
                            </label>
                          </li>
                        );
                      })}                    
                  </ul>
                }

                <li>
                  <label onClick={this.filterClick.bind(this, "currentLocation")} className="custom-checkbox custom-checkbox-line">
                    Current Location
                    <input
                      type="checkbox"
                      name="currentLocation"
                      checked={this.state.currentLocation}
                      className="custom-checkbox--input"
                      onChange={this.handleFilterSearchInput}
                    />
                    <span className="custom-checkbox-checkmark"></span>
                  </label>
                </li>

                {this.state.currentLocation &&
                  <ul className="filter__list__inner">
                    <div className="search__form mt__10 mb__10">
                      <input
                        type="text"
                        className="form-control search__form__input"
                        onChange={this.filterClient.bind(this, this.state.currentLocation, 'filterCurrentLocation')}
                        value={searchCurrentLocation}
                        placeholder="Search for current location..."
                      />
                    </div>
                  </ul>
                }                


                {['superadmin', 'admin', 'strategist'].includes(userRole) &&
                  <Fragment>
                    <li>
                      <label onClick={this.filterClick.bind(this, "createdDate")} className="custom-checkbox custom-checkbox-line">
                        Created Date
                        <input type="checkbox"
                          name="createdDate"
                          checked={this.state.createdDate}
                          className="custom-checkbox--input"
                          onChange={this.handleFilterSearchInput}
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                    {this.state.createdDate && <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                        <DatePicker
                          className="datepicker__follow-up-date form-control search__form__input"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          format="MM/DD/YYYY"
                          onChange={(date) => this.handleDateChange(date, "start")}
                          value={this.state.selectedCreatedDate}
                          placeholder="Select Start Date"
                          disabledDate={(value) => value > new Date()}
                        />
                      </div>
                    </ul>}
                    {this.state.selectedCreatedDate && <ul className="filter__list__inner">
                      <div className="search__form mt__10 mb__10">
                        <DatePicker
                          className="datepicker__follow-up-date form-control search__form__input"
                          dropdownClassName="datepicker__overlay__follow-up-date"
                          format="MM/DD/YYYY"
                          disabledDate={(value) => value < this.state.selectedCreatedDate || value > new Date()}
                          onChange={(date) => this.handleDateChange(date, "end")}
                          value={this.state.selectedEndDate}
                          placeholder="Select End Date"
                        />
                      </div>
                    </ul>}
                  </Fragment>}
              </ul>
            </div>
          </div>
        }
        placement="bottom"
        className="filter"
        overlayClassName="filter__overlay"
        // title="Title"
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.filterVisible}
      >
        <button className="btn btn-dark btn-filter"><FilterIcon className="filter-icon" /> Filters{this.state.filterCount > 0 && `(${this.state.filterCount})`}</button>
      </Popover>
    )
  }
}
