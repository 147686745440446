/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react'
import moment from 'moment'

export default function OldHistory({ history, index }) {

  const activityDate = (history.activity_date && history.user_timezone) ? moment(history.activity_date).tz(history.user_timezone) : null
  const isPreFormattedClientActivity = !!(history._type === 'appointment' && history.activity && history.activity.length && history.activity.slice(-1) === '.')
  const activityTimezone = history.client_timezone ? history.client_timezone : moment.tz.guess()
  const activityCreatedDate = moment(history.created).tz(activityTimezone).format("MMMM DD, YYYY hh:mm a z")
  const activityDateFormat = "MMMM DD, YYYY [at] hh:mm a z"

  let activityMessage = history.activity

  if (!isPreFormattedClientActivity) {
    if (history.activity_date) {
      if (history.old_event_date) {
        const toDate = moment(history.activity_date).tz(history.client_timezone).format(activityDateFormat)
        const fromDate = moment(history.old_event_date).tz(history.client_timezone).format(activityDateFormat)
        activityMessage = `${activityMessage} to <b>${toDate}</b> from <b>${fromDate}</b>`
      } else if (history.activity && history.activity.indexOf(" changed the followup date ") === -1) {
        const date = activityDate && activityDate.format(activityDateFormat)
        activityMessage = `${activityMessage} ${date}`
      }
    }
  }

  return (
    <div>
      <div>
        <p className="activity-history-text" key={index} dangerouslySetInnerHTML={{ __html: activityMessage }}/>
        <div className="activity-history-time">
          { activityCreatedDate }
        </div>
      </div>
    </div>
  )
}
