/* eslint-disable camelcase */
import React, { Fragment, useEffect } from 'react'
import ReactModal from 'react-modal'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { map, get, filter } from 'lodash'
import { DatePicker } from 'antd'

import { CloseIcon } from '../../../../../components/icons'
import config from '../../../../../config'
import Cookies from 'universal-cookie'
import validation from '../../../../../config/validation'
import { SUCCESS } from '../../../../../constants/phase'
import { convertTimeString } from '../../../../../utils/helper'

const cookies = new Cookies()

export default function AgentAppointment({
  isAppointmentModelOpen,
  closeModal,
  client,
  agent,
  ...props
}) {
  const clientId = props.match.params.id
  const user = cookies.get('user')

  useEffect(() => {
    props.fetchAgentAvailability({
      user_id: agent.agentId,
      date: moment().format('YYYY-MM-DD'),
      timezone: agent.timeZone,
    })
  }, [])
  useEffect(() => {
    if (props.fetchAgentAvailabilityPhase === SUCCESS) {
      props.clearStrategistAppointment()
    }
    if (props.createAgentPhase === SUCCESS) {
      props.clearStrategistAppointment()
      closeModal()
    }
  }, [props.fetchAgentAvailabilityPhase, props.createAgentPhase])

  const typeOfAppointment = () => {
    return map(config.typeOfAppointments, (appointment, index) => (
      <option key={index} value={appointment.name}>
        {appointment.value}
      </option>
    ))
  }

  const startTimeOptions = (appointmentDate) => {
    const availabilities = get(props, 'agentAvailability.data.slot', [])
    if (moment().format("YYYY-MM-DD") === moment(appointmentDate).format("YYYY-MM-DD")) {
      // remove from current time
      const startTimes = filter(availabilities, startTime => moment().format("HH:mm") <= startTime)
      return map(startTimes, (slotval, indexslot) => {
        const pos = startTimes.length - 1
        // disable last element from time slot
        if (pos === indexslot && slotval !== "Invalid date") {
          return <option key={indexslot} disabled={true} value={slotval}>{convertTimeString(slotval)}</option>
        }
        if (pos !== indexslot && slotval !== "Invalid date") {
          return <option key={indexslot} value={slotval}>{convertTimeString(slotval)}</option>
        }
      })
    }
    return map(availabilities, (slotval, indexslot) => {
      const pos = availabilities.length - 1
      // disable last element from time slot
      if (pos === indexslot && slotval !== "Invalid date") {
        return <option key={indexslot} disabled={true} value={slotval}>{convertTimeString(slotval)}</option>
      }
      if (pos !== indexslot && slotval !== "Invalid date") {
        return <option key={indexslot} value={slotval}>{convertTimeString(slotval)}</option>
      }
    })
  }
  const endTimeOptions = (startTime) => {
    const availabilities = get(props, 'agentAvailability.data.slot', [])
    return map(availabilities, (slotval, indexslot) => {
      if (startTime < slotval && slotval !== "Invalid date") {
        return <option key={indexslot} value={slotval}> {convertTimeString(slotval)} </option>
      }
    })
  }

  const handleDateChange = (values, date, setFieldValue) => {
    const selectDate = moment(date).format('YYYY-MM-DD')
    setFieldValue('appointmentDate', date)
    props.fetchAgentAvailability({
      user_id: agent.agentId,
      timezone: agent.timeZone,
      date: moment(selectDate).format('YYYY-MM-DD'),
    })
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment.utc().startOf('day');
  }
  const handleSubmit = (values) => {
    const object = {
      user_id: values.selectStrategist,
      client_id: values.clientId,
      date: moment(values.appointmentDate).format('YYYY-MM-DD'),
      start_time: values.selectStartTime,
      end_time: values.selectEndTime,
      calendar_id: "",
      timezone: agent.timeZone,
      appointment_type: values.appoitnmentType,
      description: values.description,
    }
    props.createAgentAppointment(object)
  }
  return (
    <Fragment>
      <ReactModal
        isOpen={isAppointmentModelOpen}
        onRequestClose={closeModal}
        contentLabel="Create Appointment"
        portalClassName="react-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            // initial values
            clientId,
            selectStrategist: agent.agentId,
            selectStartTime: '',
            selectEndTime: '',
            appoitnmentType: '',
            appointmentDate: moment(),
            description: '',
          }}
          validationSchema={validation.createAgentAppointment}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            handleSubmit(values)
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <div className="react-modal-dialog react-modal-dialog-centered">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Appointment with agent</h5>
                  <button type="button" className="btn react-modal-close" onClick={closeModal}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="clientName"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value={client.clientId}>{client.name}</option>
                    </Field>
                    <label className="material-textfield-label label-lg">Client Name</label>
                    <ErrorMessage className="invalid-feedback" name="clientName" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="selectStrategist"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value={agent.agentId}>{agent.name}</option>
                    </Field>
                    <label className="material-textfield-label label-lg">Agent Name</label>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="strategistName"
                      component="div"
                    />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      component="select"
                      name="appoitnmentType"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value="">Select the type of Appointment</option>
                      {typeOfAppointment()}
                    </Field>
                    <label className="material-textfield-label label-lg">Appointment Type</label>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="appoitnmentType"
                      component="div"
                    />
                  </div>

                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">Date</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        onChange={(date) => handleDateChange(values, date, setFieldValue)}
                        disabledDate={disabledDate}
                        name="appointmentDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={values.appointmentDate ? values.appointmentDate : moment()}
                        placeholder="Select Date"
                      />
                    </div>
                    <ErrorMessage className="invalid-feedback" name="date" component="div" />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          component="select"
                          className="form-control custom__select material-textfield-input textfield-input-lg"
                          name="selectStartTime"
                        >
                          <option value="">Start Time</option>
                          {startTimeOptions(values.appointmentDate)}
                        </Field>
                        <label className="material-textfield-label label-lg">Start Time</label>
                        <ErrorMessage
                          className="invalid-feedback"
                          name="selectStartTime"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <Field
                          component="select"
                          className="form-control custom__select material-textfield-input textfield-input-lg"
                          name="selectEndTime"
                        >
                          <option value="">End Time</option>
                          {values.selectStartTime && endTimeOptions(values.selectStartTime)}
                        </Field>
                        <label className="material-textfield-label label-lg">End Time</label>
                        <ErrorMessage
                          className="invalid-feedback"
                          name="selectEndTime"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group material-textfield">
                    <Field
                      type="text"
                      rows="8"
                      component="textarea"
                      name="description"
                      className="form-control material-textfield-input textfield-input-lg"
                    />
                    <label className="material-textfield-label label-lg">
                      Appointment Description
                    </label>
                    <ErrorMessage className="invalid-feedback" name="description" component="div" />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn__btn btn__lg btn-dark w__100"
                      disabled={isSubmitting}
                    >
                      Schedule
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    </Fragment>
  )
}
