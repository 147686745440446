import React from 'react';
import { Popover, DatePicker, Spin } from 'antd';
import get from 'lodash/get';
import filter from 'lodash/filter';
import moment from 'moment';
import Cookies from 'universal-cookie';

import {
  ColoredTasklistIcon,
  FilterIcon,
  CloseIcon
} from '../../../../../components/icons';
import ReactModal from 'react-modal';
import isEmpty from 'lodash/isEmpty';

const cookies = new Cookies();

let _user;
class TaskListFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      searchStringAr: [],
      firstKey: 'none',
      statusFilters: [],
      strategistFilter: [],
      isSearchMode: false,
      suburbsFilter: [],
      currentParams: [],
      isUpdatedOnce: 0,
      ac: [],
      activeFiltersCount: 0,
      datePickerModel: false,
      // selectedCreatedDate
      currentOpenOption: { name: '' }
    };
  }

  componentDidMount() {
    _user = cookies.get('user');

    const { selectedKeys, filterParams } = this.props;
    let firstKey = '';

    let ac = []
    let activeFiltersCount = 0;
    if (!isEmpty(selectedKeys.statuses)) {
      activeFiltersCount += 1;
      ac.push('statuses')
    }
    if (!isEmpty(selectedKeys.strategists)) {
      activeFiltersCount += 1;
      ac.push('strategists')
    }
    if (!isEmpty(selectedKeys.suburbs)) {
      activeFiltersCount += 1;
      ac.push('suburbs')
    }

    Object.keys(selectedKeys).map((k, i) => {
      if (!isEmpty(selectedKeys[k]) && !firstKey) {
        firstKey = k;
      }
    });

    this.setState({
      selectedKeys,
      ac,
      statusFilters:  filterParams['statuses'],
      strategistFilter:filterParams['strategists'],
      suburbsFilter: filterParams['suburbs'],
      currentOpenOption: { name: firstKey, open: true },
      activeFiltersCount,
      currentParams: filterParams[firstKey]
    });
  }

  handleDateChange = (date, type) => {
    const { selectedCreatedDate, selectedEndDate } = this.state;
    if (type !== 'save') {
      let str = 'selectedCreatedDate';
      if (type === 'end') {
        str = 'selectedEndDate';
      }
      this.setState({ [str]: date }, () => {});
    }

    if ((selectedCreatedDate && selectedEndDate) || type === 'save') {
      this.setState({ datePickerModel: false });
      this.props.setDateFilter(
        { startDate: selectedCreatedDate, endDate: selectedEndDate },
        'createdDate'
      );
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let obj = { selectedKeys: nextProps.selectedKeys };
    // obj.currentParams = nextProps.currentParams
    if (!prevState.isSearchMode) {
      obj.suburbsFilter = nextProps.filterParams['suburbs']
      obj.strategistFilter = nextProps.filterParams['strategists']
      obj.statusFilters = nextProps.filterParams['statuses']
    }

    if(prevState.activeFiltersCount === 0) {
      let activeFiltersCount = 0;
      let ac = prevState.ac
      if (!isEmpty(nextProps.selectedKeys.statuses)) {
        activeFiltersCount += 1;
        ac.push('statuses')
      }
      if (!isEmpty(nextProps.selectedKeys.strategists)) {
        activeFiltersCount += 1;
        ac.push('strategists')
      }
      if (!isEmpty(nextProps.selectedKeys.suburbs)) {
        activeFiltersCount += 1;
        ac.push('suburbs')
      }

      obj.ac = ac 
      obj.activeFiltersCount = activeFiltersCount
    }

    // let selectedKeys = nextProps.selectedKeys;
    // let firstKey = prevState.firstKey;

    // Object.keys(selectedKeys).map((k, i) => {
    //   if (!isEmpty(selectedKeys[k]) && !firstKey) {
    //     firstKey = k;
    //   }
    // });

    // if (firstKey) {
    // obj.currentOpenOption = { name: "statuses", open: true };
    //   obj.firstKey = '';
    // }

    return obj;
  }

  disabledDate = current => {
    const startDate = this.state.selectedCreatedDate;
    return current && current < moment(startDate, 'MM/DD/YYYY');
  };

  filterVisible = visible => {
    this.setState({ visible });
  };

  selectOpenOption = e => {
    let ac = []
    this.state.ac.filter((c) => { if (c !== e.target.name) {
      ac.push(c)
    } })
    this.setState({ ac })
    const datePickerModel = this.state.datePickerModel;
    if (!e.target.checked) {
      //call
      this.props.onKeyUncheck(e.target.name)
    }
    this.setState({
      currentParams: this.props.filterParams[get(e, 'target.name')],
      datePickerModel:
        e.target.name === 'createdDate' ? !datePickerModel : datePickerModel,
      currentOpenOption: {
        name: get(e, 'target.name'),
        open: get(e, 'target.checked')
      }
    });
  };

  handleChange = (initial, e) => {
    this.setState({ isSearchMode: true })
    const value = e.target.value;
    let filtered;
    if (value) {
      const keyVal =
        e.target.name === 'strategistFilter'
          ? 'strategist_name'
          : 'name';
      filtered = filter(
        initial,
        search =>
          search[keyVal].toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      let key = e.target.name

      key = key.includes('status') && 'statuses' || key.includes('strategist') && 'strategists' || key.includes('suburbsFilter') && 'suburbs'  
      filtered = this.props.filterParams[key];
    }

    this.setState({ [e.target.name]: filtered });
  };

  closeDateModal = () => {
    this.setState({
      datePickerModel: false,
      currentOpenOption: {
        name: 'createdDate',
        open: false
      }
    });

    this.forceUpdate();
  };

  render() {
    const {
      currentOpenOption,
      currentParams,
      selectedKeys,
      selectedEndDate,
      suburbsFilter,
      strategistFilter,
      ac,
      statusFilters
    } = this.state;

    let activeFiltersCount = this.state.activeFiltersCount
    const { filterParams } = this.props
    activeFiltersCount = 0;
    if (!isEmpty(selectedKeys.statuses)) {
      activeFiltersCount += 1;
    }
    if (!isEmpty(selectedKeys.strategists)) {
      activeFiltersCount += 1;
    }
    if (!isEmpty(selectedKeys.suburbs)) {
      activeFiltersCount += 1;
    }

    const role = get(_user, 'role', '')
    const isAdminOrSuper = role === 'superadmin' || role === 'admin'
    return (
      <>
        <Popover
          content={
            <div className="filter">
              <div className="filter__header">
                <button
                  onClick={() => this.props.clearFilters()}
                  className="btn btn__btn btn-secondry btn__xs"
                >
                  Clear
                </button>
                <div className="filter__title">Filters</div>
                <button
                  onClick={() => {
                    this.setState({ visible: false });
                  }}
                  className="btn btn__btn btn-dark btn__xs"
                >
                  Close
                </button>
              </div>
              <div className="filter__body">
                <ul className="filter__list">
                  <li>
                    <label className="custom-checkbox custom-checkbox-line">
                      Status
                      <input
                        type="checkbox"
                        name="statuses"
                        checked={
                          ((get(currentOpenOption, 'name') === 'statuses' &&
                          get(currentOpenOption, 'open')) || ac.includes('statuses'))
                            ? true
                            : false
                        }
                        onChange={this.selectOpenOption.bind(this)}
                        className="custom-checkbox--input"
                      />
                      <span className="custom-checkbox-checkmark"></span>
                    </label>
                  </li>
                  {((get(currentOpenOption, 'open') &&
                    get(currentOpenOption, 'name') === 'statuses') || ac.includes('statuses')) && (
                      <ul className="filter__list__inner">
                        <div className="search__form mt__10 mb__10">
                          <input
                            type="text"
                            name="statusFilters"
                            className="form-control search__form__input"
                            onChange={(e) => this.handleChange(statusFilters, e)}
                            placeholder="Search for status..."
                            // value={searchStatus}
                          />
                        </div>
                        {!isEmpty(statusFilters) &&
                          statusFilters.map((param, i) => {
                            // const findSelected = find(this.state.clientStatus, o => o === client.name)
                            return (
                              <li key={`status__${i}`}>
                                <label
                                  style={{ fontSize: '14px' }}
                                  className="custom-checkbox custom-checkbox-line"
                                >
                                  {param.name}
                                  <input
                                    type="checkbox"
                                    checked={
                                      get(selectedKeys, `statuses`, [
                                        'NAN'
                                      ]).includes(param.name)
                                        ? true
                                        : false
                                    }
                                    className="custom-checkbox--input"
                                    onChange={e =>
                                      this.props.setSelectedKeys(
                                        e,
                                        param.name,
                                        'statuses'
                                      )
                                    }
                                    readOnly
                                  />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  {
                    isAdminOrSuper &&
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            Strategist
                            <input
                              type="checkbox"
                              name="strategists"
                              checked={
                                ((get(currentOpenOption, 'name') === 'strategists' &&
                                get(currentOpenOption, 'open')) || ac.includes('strategists'))
                                  ? true
                                  : false
                              }
                              onChange={this.selectOpenOption.bind(this)}
                              className="custom-checkbox--input"
                            />
                            <span className="custom-checkbox-checkmark"></span>
                          </label>
                        </li>
                  }
                  {((get(currentOpenOption, 'open') &&
                    get(currentOpenOption, 'name') === 'strategists') || ac.includes('strategists')) && (
                      <ul className="filter__list__inner">
                        <div className="search__form mt__10 mb__10">
                          <input
                            type="text"
                            name="strategistFilter"
                            className="form-control search__form__input"
                            onChange={(e) => this.handleChange(strategistFilter, e)}
                            placeholder="Search for strategists..."
                            // value={searchStatus}
                          />
                        </div>
                        {!isEmpty(strategistFilter) &&
                          strategistFilter.map((param, i) => {
                            // const findSelected = find(this.state.clientStatus, o => o === client.name)
                            return (
                              <li key={`status__${i}`}>
                                <label
                                  style={{ fontSize: '14px' }}
                                  className="custom-checkbox custom-checkbox-line"
                                >
                                  {param.strategist_name}
                                  <input
                                    type="checkbox"
                                    checked={
                                      get(selectedKeys, `strategists`, [
                                        'NAN'
                                      ]).includes(param.id)
                                        ? true
                                        : false
                                    }
                                    className="custom-checkbox--input"
                                    onChange={e =>
                                      this.props.setSelectedKeys(
                                        e,
                                        param.id,
                                        'strategists'
                                      )
                                    }
                                    readOnly
                                  />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                     
                    <li>
                      <label className="custom-checkbox custom-checkbox-line">
                        Suburb
                        <input
                          type="checkbox"
                          name="suburbs"
                          checked={
                            ((get(currentOpenOption, 'name') === 'suburbs' &&
                            get(currentOpenOption, 'open')) || ac.includes('suburbs'))
                              ? true
                              : false
                          }
                          onChange={this.selectOpenOption.bind(this)}
                          className="custom-checkbox--input"
                        />
                        <span className="custom-checkbox-checkmark"></span>
                      </label>
                    </li>
                  {((get(currentOpenOption, 'open') &&
                    get(currentOpenOption, 'name') === 'suburbs') || ac.includes('suburbs')) && (
                      <ul className="filter__list__inner">
                        <div className="search__form mt__10 mb__10">
                          <input
                            type="text"
                            name="suburbsFilter"
                            className="form-control search__form__input"
                            onChange={(e) => this.handleChange(suburbsFilter, e)}
                            placeholder="Search for suburbs..."
                            // value={searchStatus}
                          />
                        </div>
                        {!isEmpty(suburbsFilter) &&
                          suburbsFilter.map((param, i) => {
                            // const findSelected = find(this.state.clientStatus, o => o === client.name)
                            return (
                              <li key={`status__${i}`}>
                                <label
                                  style={{ fontSize: '14px' }}
                                  className="custom-checkbox custom-checkbox-line"
                                >
                                  {param.name}
                                  <input
                                    type="checkbox"
                                    checked={
                                      get(selectedKeys, `suburbs`, [
                                        'NAN'
                                      ]).includes(param.id)
                                        ? true
                                        : false
                                    }
                                    className="custom-checkbox--input"
                                    onChange={e =>
                                      this.props.setSelectedKeys(
                                        e,
                                        param.id,
                                        'suburbs'
                                      )
                                    }
                                    readOnly
                                  />
                                  <span className="custom-checkbox-checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  {/*<li>
                    <label className="custom-checkbox custom-checkbox-line">
                      Created Date
                      <input
                        type="checkbox"
                        name="createdDate"
                        checked={
                          get(currentOpenOption, 'name') === 'createdDate' &&
                          get(currentOpenOption, 'open')
                            ? true
                            : false
                        }
                        onChange={this.selectOpenOption.bind(this)}
                        className="custom-checkbox--input"
                      />
                      <span className="custom-checkbox-checkmark"></span>
                    </label>
                  </li>*/}
                </ul>
              </div>
            </div>
          }
          placement="bottom"
          className="filter"
          overlayClassName="filter__overlay"
          // title="Title"
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.filterVisible}
        >
          <button className="btn btn-dark btn-filter">
            <FilterIcon className="filter-icon" /> Filters{' '}
            {activeFiltersCount !== 0 && `(${activeFiltersCount})`}
          </button>
        </Popover>
        <ReactModal
          isOpen={this.state.datePickerModel}
          onAfterOpen={e => {}}
          onRequestClose={this.closeDateModal}
          contentLabel="Custom Date"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-sm react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Custom Date</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeDateModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">From</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        //disabledDate={this.disabledDate}
                        name="customFirstDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        value={this.state.customFirstDate}
                        placeholder="Select"
                        onChange={date => this.handleDateChange(date, 'start')}
                        value={this.state.selectedCreatedDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <div className="btn__follow-up-date follow-up-date-lg">
                      <div className="follow-up-date-label">To</div>
                      <DatePicker
                        className="datepicker__follow-up-date"
                        dropdownClassName="datepicker__overlay__follow-up-date"
                        //disabledDate={this.disabledDate}
                        name="customEndDate"
                        inputReadOnly={true}
                        allowClear={false}
                        format="MM/DD/YYYY"
                        placeholder="Select"
                        disabledDate={this.disabledDate}
                        onChange={date => this.handleDateChange(date, 'end')}
                        value={this.state.selectedEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn__btn btn__lg btn-dark w__100"
                  onClick={() => this.handleDateChange('', 'save')}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}

export default TaskListFilter;
