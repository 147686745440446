import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import get from 'lodash/get';
import { CloseIcon } from '../../../../components/icons';

const Confirm = props => {
  const isNotDelete = get(props, 'isNotDelete', false);
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {}, [props]);

  return (
    <ReactModal
      isOpen={props.isOpenModal}
      onRequestClose={props.closeModal}
      contentLabel="Delete"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-body react-modal-body-sm">
          <button
            type="button"
            disabled={props.isLoading}
            className="btn react-modal-close react-modal-close-sm"
            onClick={props.closeModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title mb__13">
            {isNotDelete ? 'Set Featured' : 'Delete'}
          </h5>
          <p className="react-modal-subtitle mb__15">
            {isNotDelete
              ? 'Are you sure you want to set this image as featured?'
              : 'Are you sure you want to delete this?'}
          </p>
          <div className="text-right">
            <button
              disabled={props.isLoading}
              onClick={() => props.onConfirm()}
              className={`btn btn__btn ${
                isNotDelete ? 'btn-dark' : 'btn-danger'
              } w__150`}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Confirm;
