import Rx from 'rxjs/Rx'
import { Record, List } from 'immutable'
import { combineEpics } from 'redux-observable'
import HttpStatus from 'http-status-codes'
import { assign } from 'lodash'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import * as api from './api'

/**
 * Public: Action Types
 */

export const FETCH_TOWN_REPORT = 'suburban/town/FETCH_TOWN_REPORT'
export const FETCH_TOWN_REPORT_SUCCESS = 'suburban/town/FETCH_TOWN_REPORT_SUCCESS'
export const FETCH_TOWN_REPORT_ERROR = 'suburban/town/FETCH_TOWN_REPORT_ERROR'

export const GENERATE_TOWN_REPORT = 'suburban/town/GENERATE_TOWN_REPORT'
export const GENERATE_TOWN_REPORT_SUCCESS = 'suburban/town/GENERATE_TOWN_REPORT_SUCCESS'
export const GENERATE_TOWN_REPORT_ERROR = 'suburban/town/GENERATE_TOWN_REPORT_ERROR'

export const GET_GENERATED_REPORT = 'suburban/town/GET_GENERATED_REPORT'
export const GET_GENERATED_REPORT_SUCCESS = 'suburban/town/GET_GENERATED_REPORT_SUCCESS'
export const GET_GENERATED_REPORT_ERROR = 'suburban/town/GET_GENERATED_REPORT_ERROR'

export const UPLOAD_IMAGE = 'suburban/town/UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'suburban/town/UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_ERROR = 'suburban/town/UPLOAD_IMAGE_ERROR'

export const UPLOAD_VIDEO = 'suburban/town/UPLOAD_VIDEO'
export const UPLOAD_VIDEO_SUCCESS = 'suburban/town/UPLOAD_VIDEO_SUCCESS'
export const UPLOAD_VIDEO_ERROR = 'suburban/town/UPLOAD_VIDEO_ERROR'

export const EDIT_REPORT = 'suburban/town/EDIT_REPORT'
export const EDIT_REPORT_SUCCESS = 'suburban/town/EDIT_REPORT_SUCCESS'
export const EDIT_REPORT_ERROR = 'suburban/town/EDIT_REPORT_ERROR'

export const PUSH_TO_WORDPRESS = 'suburban/town/PUSH_TO_WORDPRESS'
export const PUSH_TO_WORDPRESS_SUCCESS = 'suburban/town/PUSH_TO_WORDPRESS_SUCCESS'
export const PUSH_TO_WORDPRESS_ERROR = 'suburban/town/PUSH_TO_WORDPRESS_ERROR'

export const REPUSH_TO_WORDPRESS = 'suburban/town/REPUSH_TO_WORDPRESS'
export const REPUSH_TO_WORDPRESS_SUCCESS = 'suburban/town/REPUSH_TO_WORDPRESS_SUCCESS'
export const REPUSH_TO_WORDPRESS_ERROR = 'suburban/town/REPUSH_TO_WORDPRESS_ERROR'

export const MARK_FEATURED = 'suburban/town/MARK_FEATURED_WORDPRESS'
export const MARK_FEATURED_SUCCESS = 'suburban/town/MARK_FEATURED_SUCCESS'
export const MARK_FEATURED_ERROR = 'suburban/town/MARK_FEATURED_ERROR'

export const MARK_FEATURED_VIDEO = 'suburban/town/MARK_FEATURED_VIDEO_WORDPRESS'
export const MARK_FEATURED_VIDEO_SUCCESS = 'suburban/town/MARK_FEATURED_VIDEO_SUCCESS'
export const MARK_FEATURED_VIDEO_ERROR = 'suburban/town/MARK_FEATURED_VIDEO_ERROR'

export const REMOVE_IMAGE = 'suburban/town/REMOVE_IMAGE'
export const REMOVE_IMAGE_SUCCESS = 'suburban/town/REMOVE_IMAGE_SUCCESS'
export const REMOVE_IMAGE_ERROR = 'suburban/town/REMOVE_IMAGE_ERROR'

export const REMOVE_VIDEO = 'suburban/town/REMOVE_VIDEO'
export const REMOVE_VIDEO_SUCCESS = 'suburban/town/REMOVE_VIDEO_SUCCESS'
export const REMOVE_VIDEO_ERROR = 'suburban/town/REMOVE_VIDEO_ERROR'

export const GET_COMMUNITY_DATA = 'suburban/town/GET_COMMUNITY_DATA'
export const GET_COMMUNITY_DATA_SUCCESS = 'suburban/town/GET_COMMUNITY_DATA_SUCCESS'
export const GET_COMMUNITY_DATA_ERROR = 'suburban/town/GET_COMMUNITY_DATA_ERROR'

export const EDIT_COMMUNITY_DATA = 'suburban/town/EDIT_COMMUNITY_DATA'
export const EDIT_COMMUNITY_DATA_SUCCESS = 'suburban/town/EDIT_COMMUNITY_DATA_SUCCESS'
export const EDIT_COMMUNITY_DATA_ERROR = 'suburban/town/EDIT_COMMUNITY_DATA_ERROR'

export const EDIT_PRIVATE_DATA = 'suburban/town/EDIT_PRIVATE_DATA'
export const EDIT_PRIVATE_DATA_SUCCESS = 'suburban/town/EDIT_PRIVATE_DATA_SUCCESS'
export const EDIT_PRIVATE_DATA_ERROR = 'suburban/town/EDIT_PRIVATE_DATA_ERROR'

export const EDIT_PUBLIC_DATA = 'suburban/town/EDIT_PUBLIC_DATA'
export const EDIT_PUBLIC_DATA_SUCCESS = 'suburban/town/EDIT_PUBLIC_DATA_SUCCESS'
export const EDIT_PUBLIC_DATA_ERROR = 'suburban/town/EDIT_PUBLIC_DATA_ERROR'

export const DELETE_PRIVATE_DATA = 'suburban/town/DELETE_PRIVATE_DATA'
export const DELETE_PRIVATE_DATA_SUCCESS = 'suburban/town/DELETE_PRIVATE_DATA_SUCCESS'
export const DELETE_PRIVATE_DATA_ERROR = 'suburban/town/DELETE_PRIVATE_DATA_ERROR'

export const DELETE_PUBLIC_DATA = 'suburban/town/DELETE_PUBLIC_DATA'
export const DELETE_PUBLIC_DATA_SUCCESS = 'suburban/town/DELETE_PUBLIC_DATA_SUCCESS'
export const DELETE_PUBLIC_DATA_ERROR = 'suburban/town/DELETE_PUBLIC_DATA_ERROR'

export const GET_SCHOOLS = 'suburban/town/GET_SCHOOLS'
export const GET_SCHOOLS_SUCCESS = 'suburban/town/GET_SCHOOLS_SUCCESS'
export const GET_SCHOOLS_ERROR = 'suburban/town/GET_SCHOOLS_ERROR'

export const GET_ATTOM_SCHOOLS = 'suburban/town/GET_ATTOM_SCHOOLS'
export const GET_ATTOM_SCHOOLS_SUCCESS = 'suburban/town/GET_ATTOM_SCHOOLS_SUCCESS'
export const GET_ATTOM_SCHOOLS_ERROR = 'suburban/town/GET_ATTOM_SCHOOLS_ERROR'

export const UPDATE_IMAGE = 'suburban/town/UPDATE_IMAGE'
export const UPDATE_IMAGE_SUCCESS = 'suburban/town/UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_ERROR = 'suburban/town/UPDATE_IMAGE_ERROR'

export const UPDATE_VIDEO = 'suburban/town/UPDATE_VIDEO'
export const UPDATE_VIDEO_SUCCESS = 'suburban/town/UPDATE_VIDEO_SUCCESS'
export const UPDATE_VIDEO_ERROR = 'suburban/town/UPDATE_VIDEO_ERROR'

export const ADD_PRIVATE_DATA = 'suburban/town/ADD_PRIVATE_DATA'
export const ADD_PRIVATE_DATA_SUCCESS = 'suburban/town/ADD_PRIVATE_DATA_SUCCESS'
export const ADD_PRIVATE_DATA_ERROR = 'suburban/town/ADD_PRIVATE_DATA_ERROR'

export const ADD_PUBLIC_DATA = 'suburban/town/ADD_PUBLIC_DATA'
export const ADD_PUBLIC_DATA_SUCCESS = 'suburban/town/ADD_PUBLIC_DATA_SUCCESS'
export const ADD_PUBLIC_DATA_ERROR = 'suburban/town/ADD_PUBLIC_DATA_ERROR'

export const SYNCH_COMMUNITY_DATA = 'suburban/town/SYNCH_COMMUNITY_DATA'
export const SYNCH_COMMUNITY_DATA_SUCCESS = 'suburban/town/SYNCH_COMMUNITY_DATA_SUCCESS'
export const SYNCH_COMMUNITY_DATA_ERROR = 'suburban/town/SYNCH_COMMUNITY_DATA_ERROR'

export const FETCH_COMMUTE = 'suburban/town/FETCH_COMMUTE'
export const FETCH_COMMUTE_SUCCESS = 'suburban/town/FETCH_COMMUTE_SUCCESS'
export const FETCH_COMMUTE_ERROR = 'suburban/town/FETCH_COMMUTE_ERROR'

export const ADD_COMMUTE = 'suburban/town/ADD_COMMUTE'
export const ADD_COMMUTE_SUCCESS = 'suburban/town/ADD_COMMUTE_SUCCESS'
export const ADD_COMMUTE_ERROR = 'suburban/town/ADD_COMMUTE_ERROR'

export const DELETE_COMMUTE = 'suburban/town/DELETE_COMMUTE'
export const DELETE_COMMUTE_SUCCESS = 'suburban/town/DELETE_COMMUTE_SUCCESS'
export const DELETE_COMMUTE_ERROR = 'suburban/town/DELETE_COMMUTE_ERROR'

export const FETCH_GOOGLE_PLACE = 'suburban/town/FETCH_GOOGLE_PLACE'
export const FETCH_GOOGLE_PLACE_SUCCESS = 'suburban/town/FETCH_GOOGLE_PLACE_SUCCESS'
export const FETCH_GOOGLE_PLACE_ERROR = 'suburban/town/FETCH_GOOGLE_PLACE_ERROR'

export const FETCH_GOOGLE_PLACE_DATA = 'suburban/town/FETCH_GOOGLE_PLACE_DATA'
export const FETCH_GOOGLE_PLACE_DATA_SUCCESS = 'suburban/town/FETCH_GOOGLE_PLACE_DATA_SUCCESS'
export const FETCH_GOOGLE_PLACE_DATA_ERROR = 'suburban/town/FETCH_GOOGLE_PLACE_DATA_ERROR'

export const ADD_GOOGLE_PLACE = 'suburban/town/ADD_GOOGLE_PLACE'
export const ADD_GOOGLE_PLACE_SUCCESS = 'suburban/town/ADD_GOOGLE_PLACE_SUCCESS'
export const ADD_GOOGLE_PLACE_ERROR = 'suburban/town/ADD_GOOGLE_PLACE_ERROR'

export const EDIT_GOOGLE_PLACE = 'suburban/town/EDIT_GOOGLE_PLACE'
export const EDIT_GOOGLE_PLACE_SUCCESS = 'suburban/town/EDIT_GOOGLE_PLACE_SUCCESS'
export const EDIT_GOOGLE_PLACE_ERROR = 'suburban/town/EDIT_GOOGLE_PLACE_ERROR'

export const UPDATE_TOWN_NAME = 'suburban/town/UPDATE_TOWN_NAME'
export const UPDATE_TOWN_NAME_SUCCESS = 'suburban/town/UPDATE_TOWN_NAME_SUCCESS'
export const UPDATE_TOWN_NAME_ERROR = 'suburban/town/UPDATE_TOWN_NAME_ERROR'

export const FETCH_TOWN_BY_ID = 'suburban/town/FETCH_TOWN_BY_ID'
export const FETCH_TOWN_BY_ID_SUCCESS = 'suburban/town/FETCH_TOWN_BY_ID_SUCCESS'
export const FETCH_TOWN_BY_ID_ERROR = 'suburban/town/FETCH_TOWN_BY_ID_ERROR'

export const DELETE_TOWN_BY_ID = 'suburban/town/DELETE_TOWN_BY_ID'
export const DELETE_TOWN_BY_ID_SUCCESS = 'suburban/town/DELETE_TOWN_BY_ID_SUCCESS'
export const DELETE_TOWN_BY_ID_ERROR = 'suburban/town/DELETE_TOWN_BY_ID_ERROR'

export const ADD_RESEARCH = 'suburban/town/ADD_RESEARCH'
export const ADD_RESEARCH_SUCCESS = 'suburban/town/ADD_RESEARCH_SUCCESS'
export const ADD_RESEARCH_ERROR = 'suburban/town/ADD_RESEARCH_ERROR'

export const ADD_RESEARCH_FILE = 'suburban/town/ADD_RESEARCH_FILE'
export const ADD_RESEARCH_FILE_SUCCESS = 'suburban/town/ADD_RESEARCH_FILE_SUCCESS'
export const ADD_RESEARCH_FILE_ERROR = 'suburban/town/ADD_RESEARCH_FILE_ERROR'

export const GET_RESEARCH = 'suburban/town/GET_RESEARCH'
export const GET_RESEARCH_SUCCESS = 'suburban/town/GET_RESEARCH_SUCCESS'
export const GET_RESEARCH_ERROR = 'suburban/town/GET_RESEARCH_ERROR'

export const DELETE_RESEARCH = 'suburban/town/DELETE_RESEARCH'
export const DELETE_RESEARCH_SUCCESS = 'suburban/town/DELETE_RESEARCH_SUCCESS'
export const DELETE_RESEARCH_ERROR = 'suburban/town/DELETE_RESEARCH_ERROR'


export const FETCH_TOWN_CHARACTERISTICS = 'suburban/town/FETCH_TOWN_CHARACTERISTICS'
export const FETCH_TOWN_CHARACTERISTICS_SUCCESS = 'suburban/town/FETCH_TOWN_CHARACTERISTICS_SUCCESS'
export const FETCH_TOWN_CHARACTERISTICS_ERROR = 'suburban/town/FETCH_TOWN_CHARACTERISTICS_ERROR'

export const UPDATE_TOWN_CHARACTERISTICS = 'suburban/town/UPDATE_TOWN_CHARACTERISTICS'
export const UPDATE_TOWN_CHARACTERISTICS_SUCCESS = 'suburban/town/UPDATE_TOWN_CHARACTERISTICS_SUCCESS'
export const UPDATE_TOWN_CHARACTERISTICS_ERROR = 'suburban/town/UPDATE_TOWN_CHARACTERISTICS_ERROR'

export const CLEAR_TOWN_ERROR = 'suburban/town/CLEAR_USER_ERROR'
export const CLEAR_PHASE = 'suburban/town/CLEAR_PHASE'

/**
 * Private: Initial State
 */

const InitialState = new Record({
  phase: INIT,
  userPhase: INIT,
  chatPhase: INIT,
  chathisotryPhase: INIT,
  clientPhase: INIT,
  communityPhase: INIT,
  editCommunityPhase: INIT,
  editPrivatePhase: INIT,
  editPublicPhase: INIT,
  deletePrivatePhase: INIT,
  deletePrivateData: [],
  deletePublicPhase: INIT,
  getGooglePlacePhase: INIT,
  googlePlace: [],
  getGooglePlaceDataPhase: INIT,
  googlePlaceData: [],
  deletePublicData: [],
  fetchTownByIdPhase: INIT,
  fetchTownByIdData: {},
  editPublicData: [],
  editPrivateData: [],
  addPrivatePhase: INIT,
  deleteCommutePhase: INIT,
  addPrivateData: [],
  addPublicPhase: INIT,
  synchCommunityData: [],
  synchCommunityPhase: INIT,
  updateTownPhase: INIT,
  updateTownData: [],
  addPublicData: [],
  suburbsData: [],
  communityData: [],
  editCommunityData: [],
  addCommutePhase: INIT,
  townReportPhase: INIT,
  deleteTownPhase: INIT,
  addResearchPhase: INIT,
  deleteResearchPhase: INIT,
  deleteResearchData: [],
  addResearchData: [],
  deleteTownData: [],
  addCommuteData: [],
  addGooglePhase: INIT,
  addGoogleData: [],
  editGooglePhase: INIT,
  getResearchPhase: INIT,
  getResearchData: [],
  editGoogleData: [],
  researchFilePhase: INIT,
  researchFileData: [],
  error: null,
  isSubmitting: false,
  towns: [],
  generateReport: null,
  disableBtn: false,
  report: null,
  imageUpload: false,
  videoUpload: false,
  uploadProgress: false,
  pushToWp: null,
  featured: false,
  removeImagePhase: INIT,
  removeVideoPhase: INIT,
  imageUploadPhase: INIT,
  videoUploadPhase: INIT,
  removeImage: false,
  removeVideo: false,
  townsCount: 0,
  getGeneratedReportPhase: INIT,
  editReportSuccess: false,
  generateReportData: [],
  generateReportPhase: INIT,
  fetchCommutePhase: INIT,
  commuteData: [],
  townCharacteristics: [],
  fetchTownCharacteristicsPhase: INIT,
  updateTownCharacteristicsPhase: INIT,
  getSchoolsPhase: INIT,
  schoolData: [],
  getAttomSchoolsPhase: INIT,
  attomSchoolData: []
})

/**
 * Public: Reducer
 */

export default function reducer(state = new InitialState(), action = {}) {

  switch (action.type) {

    case FETCH_TOWN_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('towns', payload.data.towns)
        .set('townsCount', payload.data.totalCount)
        .set('error', null)
        .set('townReportPhase', SUCCESS)
        .set('isSubmitting', false)
        .set('disableBtn', false)
    }
    case FETCH_TOWN_REPORT: {
      const { payload } = action
      return state
        .set('error', null)
        .set('townReportPhase', INIT)
        .set('isSubmitting', true)
        .set('disableBtn', false)
    }
    case GENERATE_TOWN_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('generateReportData', payload)
        .set('generateReportPhase', SUCCESS)
        .set('disableBtn', false)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GENERATE_TOWN_REPORT: {
      return state
        .set('disableBtn', true)
        .set('generateReportPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_GENERATED_REPORT: {
      const { payload } = action
      return state
        .set('report', payload.data)
        .set('imageUpload', false)
        .set('removeImage', false)
        .set('removeVideoPhase', INIT)
        .set('getGeneratedReportPhase', INIT)
        .set('featured', false)
        .set('uploadProgress', false)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_GENERATED_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('report', payload.data)
        .set('imageUpload', false)
        .set('removeImage', false)
        .set('removeVideoPhase', INIT)
        .set('featured', false)
        .set('getGeneratedReportPhase', SUCCESS)
        .set('uploadProgress', false)
        .set('error', null)
        .set('isSubmitting', false)
    }
    case UPLOAD_IMAGE_SUCCESS: {
      const { payload } = action
      return state
        .set('imageUpload', payload)
        .set("imageUploadPhase", SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPLOAD_IMAGE: {
      const { payload } = action
      return state
        .set('uploadProgress', true)
        .set('imageUploadPhase', INIT)
        // .set('imageUpload', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPLOAD_IMAGE_ERROR: {
      const { payload } = action
      return state
        .set('uploadProgress', false)
        .set('imageUpload', false)
        .set('error', payload)
        .set('isSubmitting', false)
    }
    case UPLOAD_VIDEO_SUCCESS: {
      const { payload } = action
      return state
        .set('videoUpload', payload)
        .set("videoUploadPhase", SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPLOAD_VIDEO: {
      const { payload } = action
      return state
        .set('uploadProgress', true)
        .set('videoUploadPhase', INIT)
        // .set('imageUpload', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPLOAD_VIDEO_ERROR: {
      const { payload } = action
      return state
        .set('uploadProgress', false)
        .set('videoUpload', false)
        .set('error', payload)
        .set('isSubmitting', false)
    }

    case GET_SCHOOLS: {
      const { payload } = action
      return state
        .set("getSchoolsPhase", SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_SCHOOLS_SUCCESS: {
      const { payload } = action
      return state
        .set('schoolData', payload)
        .set('getSchoolsPhase', SUCCESS)
        // .set('imageUpload', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_SCHOOLS_ERROR: {
      const { payload } = action
      return state
        .set('getSchoolsPhase', ERROR)
        .set('error', payload)
        .set('isSubmitting', false)
    }


    case GET_ATTOM_SCHOOLS: {
      const { payload } = action
      return state
        .set("getAttomSchoolsPhase", INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_ATTOM_SCHOOLS_SUCCESS: {
      const { payload } = action
      return state
        .set('attomSchoolData', payload)
        .set('getAttomSchoolsPhase', SUCCESS)
        // .set('imageUpload', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case GET_ATTOM_SCHOOLS_ERROR: {
      const { payload } = action
      return state
        .set('getAttomSchoolsPhase', ERROR)
        .set('error', payload)
        .set('isSubmitting', false)
    }


    case FETCH_TOWN_CHARACTERISTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('townCharacteristics', payload)
        .set("fetchTownCharacteristicsPhase", SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case FETCH_TOWN_CHARACTERISTICS: {
      const { payload } = action
      return state
        .set('fetchTownCharacteristicsPhase', INIT)
        // .set('imageUpload', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case FETCH_TOWN_CHARACTERISTICS_ERROR: {
      const { payload } = action
      return state
        .set('fetchTownCharacteristicsPhase', false)
        .set('error', payload)
        .set('isSubmitting', false)
    }

    case UPDATE_TOWN_CHARACTERISTICS: {
      const { payload } = action
      return state
        .set("updateTownCharacteristicsPhase", INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPDATE_TOWN_CHARACTERISTICS_SUCCESS: {
      const { payload } = action
      return state
        .set('updateTownCharacteristicsPhase', SUCCESS)
        .set('townCharacteristics', payload)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case UPDATE_TOWN_CHARACTERISTICS_ERROR: {
      const { payload } = action
      return state
        .set('fetchTownCharacteristicsPhase', false)
        .set('error', payload)
        .set('isSubmitting', false)
    }


    case ADD_RESEARCH_FILE: {
      const { payload } = action
      return state
        .set('researchFilePhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case ADD_RESEARCH_FILE_SUCCESS: {
      const { payload } = action
      return state
        .set('researchFileData', payload)
        .set('researchFilePhase', SUCCESS)
        .set('isSubmitting', true)
    }
    case ADD_RESEARCH_FILE_ERROR: {
      const { payload } = action
      return state
        .set('researchFilePhase', ERROR)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case EDIT_REPORT: {
      const { payload } = action
      return state
        .set('disableBtn', true)
        .set('editReportSuccess', false)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case EDIT_REPORT_SUCCESS: {
      const { payload } = action
      return state
        .set('disableBtn', false)
        .set('editReportSuccess', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case PUSH_TO_WORDPRESS_SUCCESS: {
      const { payload } = action
      return state
        .set('pushToWp', payload)
        .set('error', null)
        .set('isSubmitting', true)
        .set('disableBtn', false)
    }
    case PUSH_TO_WORDPRESS: {
      return state
        .set('disableBtn', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case MARK_FEATURED_SUCCESS: {
      return state
        .set('featured', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case MARK_FEATURED_VIDEO_SUCCESS: {
      return state
        .set('featured', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case MARK_FEATURED: {
      return state
        .set('disableBtn', true)
        .set('error', null)
        .set('isSubmitting', true)
    }
    case MARK_FEATURED_VIDEO: {
      return state
        .set('disableBtn', true)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_COMMUNITY_DATA: {
      return state
        .set('communityPhase', LOADING)
        .set('isSubmitting', true)
    }

    case GET_COMMUNITY_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('communityData', payload)
        .set('communityPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case GET_COMMUNITY_DATA_ERROR: {
      const { payload } = action
      return state
        .set('communityPhase', ERROR)
        .set('isSubmitting', false)
    }

    case EDIT_PRIVATE_DATA: {
      return state
        .set('editPrivatePhase', LOADING)
        .set('isSubmitting', true)
    }

    case EDIT_PRIVATE_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('editPrivateData', payload)
        .set('editPrivatePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case EDIT_PRIVATE_DATA_ERROR: {
      const { payload } = action
      return state
        .set('editPrivatePhase', ERROR)
        .set('isSubmitting', false)
    }

    case EDIT_PUBLIC_DATA: {
      return state
        .set('editPublicPhase', LOADING)
        .set('isSubmitting', true)
    }

    case EDIT_PUBLIC_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('editPublicData', payload)
        .set('editPublicPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case EDIT_PUBLIC_DATA_ERROR: {
      const { payload } = action
      return state
        .set('editPublicPhase', ERROR)
        .set('isSubmitting', false)
    }

    case EDIT_COMMUNITY_DATA: {
      return state
        .set('editCommunityPhase', LOADING)
        .set('isSubmitting', true)
    }

    case EDIT_COMMUNITY_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('editCommunityData', payload)
        .set('editCommunityPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case EDIT_COMMUNITY_DATA_ERROR: {
      const { payload } = action
      return state
        .set('editCommunityPhase', ERROR)
        .set('isSubmitting', false)
    }

    case DELETE_PRIVATE_DATA: {
      return state
        .set('deletePrivatePhase', LOADING)
        .set('isSubmitting', true)
    }

    case DELETE_PRIVATE_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('deletePrivateData', payload)
        .set('deletePrivatePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case DELETE_PRIVATE_DATA_ERROR: {
      const { payload } = action
      return state
        .set('deletePrivatePhase', ERROR)
        .set('isSubmitting', false)
    }

    case DELETE_PUBLIC_DATA: {
      return state
        .set('deletePublicPhase', LOADING)
        .set('isSubmitting', true)
    }

    case DELETE_PUBLIC_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('deletePublicData', payload)
        .set('deletePublicPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case DELETE_PUBLIC_DATA_ERROR: {
      const { payload } = action
      return state
        .set('deletePublicPhase', ERROR)
        .set('isSubmitting', false)
    }

    case ADD_PRIVATE_DATA: {
      return state
        .set('addPrivatePhase', LOADING)
        .set('isSubmitting', true)
    }

    case ADD_PRIVATE_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('addPrivateData', payload)
        .set('addPrivatePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_PRIVATE_DATA_ERROR: {
      const { payload } = action
      return state
        .set('addPrivatePhase', ERROR)
        .set('addPrivateData', payload)
        .set('isSubmitting', false)
    }

    case ADD_PUBLIC_DATA: {
      return state
        .set('addPublicPhase', LOADING)
        .set('isSubmitting', true)
    }

    case ADD_PUBLIC_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('addPublicData', payload)
        .set('addPublicPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_PUBLIC_DATA_ERROR: {
      const { payload } = action
      return state
        .set('addPublicPhase', ERROR)
        .set('addPublicData', payload)
        .set('isSubmitting', false)
    }

    case SYNCH_COMMUNITY_DATA: {
      return state
        .set('synchCommunityPhase', LOADING)
        .set('isSubmitting', true)
    }

    case SYNCH_COMMUNITY_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('synchCommunityData', payload)
        .set('synchCommunityPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case SYNCH_COMMUNITY_DATA_ERROR: {
      const { payload } = action
      return state
        .set('synchCommunityPhase', ERROR)
        .set('isSubmitting', false)
    }

    case FETCH_COMMUTE: {
      return state
        .set('fetchCommutePhase', LOADING)
        .set('isSubmitting', true)
    }

    case FETCH_COMMUTE_SUCCESS: {
      const { payload } = action
      return state
        .set('commuteData', payload)
        .set('fetchCommutePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case FETCH_COMMUTE_ERROR: {
      const { payload } = action
      return state
        .set('fetchCommutePhase', ERROR)
        .set('isSubmitting', false)
    }


    case DELETE_COMMUTE: {
      return state
        .set('deleteCommutePhase', LOADING)
        .set('isSubmitting', true)
    }

    case DELETE_COMMUTE_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteCommutePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case DELETE_COMMUTE_ERROR: {
      const { payload } = action
      return state
        .set('deleteCommutePhase', ERROR)
        .set('isSubmitting', false)
    }


    case ADD_COMMUTE: {
      return state
        .set('addCommutePhase', LOADING)
        .set('isSubmitting', true)
    }

    case ADD_COMMUTE_SUCCESS: {
      const { payload } = action
      return state
        .set('addCommuteData', payload)
        .set('addCommutePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case ADD_COMMUTE_ERROR: {
      const { payload } = action
      return state
        .set('addCommutePhase', ERROR)
        .set('isSubmitting', false)
    }

    case REMOVE_IMAGE: {
      return state
        .set('removeImagePhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case REMOVE_VIDEO: {
      return state
        .set('removeVideoPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
    }


    case REMOVE_IMAGE_SUCCESS: {
      return state
        .set('removeImagePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case REMOVE_VIDEO_SUCCESS: {
      return state
        .set('removeVideoPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UPDATE_TOWN_NAME: {
      return state
        .set('updateTownPhase', LOADING)
        .set('isSubmitting', true)
    }

    case UPDATE_TOWN_NAME_SUCCESS: {
      const { payload } = action
      return state
        .set('updateTownData', payload)
        .set('updateTownPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    case UPDATE_TOWN_NAME_ERROR: {
      const { payload } = action
      return state
        .set('updateTownPhase', ERROR)
        .set('isSubmitting', false)
    }

    case FETCH_TOWN_BY_ID: {
      return state
        .set('fetchTownByIdPhase', LOADING)
        .set('isSubmitting', true)
    }

    case FETCH_TOWN_BY_ID_SUCCESS: {
      const { payload } = action
      return state
        .set('fetchTownByIdData', payload)
        .set('fetchTownByIdPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case FETCH_TOWN_BY_ID_ERROR: {
      const { payload } = action
      return state
        .set('fetchTownByIdPhase', ERROR)
        .set('isSubmitting', false)
    }

    case DELETE_TOWN_BY_ID: {
      return state
        .set('deleteTownPhase', LOADING)
        .set('isSubmitting', true)
    }

    case DELETE_TOWN_BY_ID_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteTownData', payload)
        .set('deleteTownPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case DELETE_TOWN_BY_ID_ERROR: {
      const { payload } = action
      return state
        .set('deleteTownPhase', ERROR)
        .set('isSubmitting', false)
    }

    case ADD_RESEARCH: {
      return state
        .set('addResearchPhase', LOADING)
        .set('isSubmitting', true)
    }

    case ADD_RESEARCH_SUCCESS: {
      const { payload } = action
      return state
        .set('addResearchData', payload)
        .set('addResearchPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case ADD_RESEARCH_ERROR: {
      const { payload } = action
      return state
        .set('addResearchPhase', ERROR)
        .set('isSubmitting', false)
    }

    case FETCH_GOOGLE_PLACE: {
      return state
        .set('getGooglePlacePhase', LOADING)
        .set('isSubmitting', true)
    }

    case FETCH_GOOGLE_PLACE_SUCCESS: {
      const { payload } = action
      return state
        .set('googlePlace', payload)
        .set('getGooglePlacePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case FETCH_GOOGLE_PLACE_ERROR: {
      const { payload } = action
      return state
        .set('getGooglePlacePhase', ERROR)
        .set('isSubmitting', false)
    }

    case FETCH_GOOGLE_PLACE_DATA: {
      return state
        .set('getGooglePlaceDataPhase', LOADING)
        .set('isSubmitting', true)
    }

    case FETCH_GOOGLE_PLACE_DATA_SUCCESS: {
      const { payload } = action
      return state
        .set('googlePlaceData', payload)
        .set('getGooglePlaceDataPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case FETCH_GOOGLE_PLACE_DATA_ERROR: {
      const { payload } = action
      return state
        .set('getGooglePlaceDataPhase', ERROR)
        .set('isSubmitting', false)
    }


    case ADD_GOOGLE_PLACE: {
      return state
        .set('addGooglePhase', LOADING)
        .set('isSubmitting', true)
    }

    case ADD_GOOGLE_PLACE_SUCCESS: {
      const { payload } = action
      return state
        .set('addGoogleData', payload)
        .set('addGooglePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case ADD_GOOGLE_PLACE_ERROR: {
      const { payload } = action
      return state
        .set('addGooglePhase', ERROR)
        .set('isSubmitting', false)
    }

    case EDIT_GOOGLE_PLACE: {
      return state
        .set('editGooglePhase', LOADING)
        .set('isSubmitting', true)
    }

    case EDIT_GOOGLE_PLACE_SUCCESS: {
      const { payload } = action
      return state
        .set('editGoogleData', payload)
        .set('editGooglePhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case EDIT_GOOGLE_PLACE_ERROR: {
      const { payload } = action
      return state
        .set('editGooglePhase', ERROR)
        .set('isSubmitting', false)
    }

    case GET_RESEARCH: {
      return state
        .set('getResearchPhase', LOADING)
        .set('isSubmitting', true)
    }

    case GET_RESEARCH_SUCCESS: {
      const { payload } = action
      return state
        .set('getResearchData', payload)
        .set('getResearchPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case GET_RESEARCH_ERROR: {
      const { payload } = action
      return state
        .set('getResearchPhase', ERROR)
        .set('isSubmitting', false)
    }

    case DELETE_RESEARCH: {
      return state
        .set('deleteResearchPhase', LOADING)
        .set('isSubmitting', true)
    }

    case DELETE_RESEARCH_SUCCESS: {
      const { payload } = action
      return state
        .set('deleteResearchData', payload)
        .set('deleteResearchPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false)
    }

    case DELETE_RESEARCH_ERROR: {
      const { payload } = action
      return state
        .set('deleteResearchPhase', ERROR)
        .set('isSubmitting', false)
    }

    case CLEAR_PHASE: {
      return state
        .set('chatPhase', INIT)
        .set('addResearchPhase', INIT)
        .set('synchCommunityPhase', INIT)
        .set('editCommunityPhase', INIT)
        .set('addCommutePhase', INIT)
        .set('researchFilePhase', INIT)
        .set('editPrivatePhase', INIT)
        .set('deletePrivatePhase', INIT)
        .set('getSchoolsPhase', INIT)
        .set('getAttomSchoolsPhase', INIT)
        .set('townReportPhase', INIT)
        .set('addGooglePhase', INIT)
        .set('addPublicPhase', INIT)
        .set('addPrivatePhase', INIT)
        .set('deleteCommutePhase', INIT)
        .set('fetchCommutePhase', INIT)
        .set('generateReportPhase', INIT)
        .set('editGooglePhase', INIT)
        .set('removeImagePhase', INIT)
        .set('fetchTownByIdPhase', INIT)
        .set('getGeneratedReportPhase', INIT)
        .set('imageUploadPhase', INIT)
        .set('videoUploadPhase', INIT)
        .set('getGooglePlaceDataPhase', INIT)
        .set('getGooglePlacePhase', INIT)
        .set('deletePublicPhase', INIT)
        .set('editReportSuccess', false)
        .set('editPublicPhase', INIT)
        .set('deleteResearchPhase', LOADING)
        .set('updateTownPhase', INIT)
        .set('deleteTownPhase', LOADING)
        .set('generateReport', null)
        .set('featured', false)
        .set('fetchTownCharacteristicsPhase', INIT)
        .set('updateTownCharacteristicsPhase', INIT)
    }

    default: {
      return state
    }

  }
}

/**
 * Public: Action Creators
 */

export const fetchTownReport = (payload) => ({
  type: FETCH_TOWN_REPORT,
  payload
})

export const clearTownPhase = () => ({
  type: CLEAR_PHASE
})

export const generateReport = (payload) => ({
  type: GENERATE_TOWN_REPORT,
  payload
})

export const uploadImage = (payload) => ({
  type: UPLOAD_IMAGE,
  payload
})

export const uploadVideo = (payload) => ({
  type: UPLOAD_VIDEO,
  payload
})

export const getGeneratedReport = (payload) => ({
  type: GET_GENERATED_REPORT,
  payload
})

export const fetchTownCharacteristics = (payload) => ({
  type: FETCH_TOWN_CHARACTERISTICS,
  payload
})

export const updateTownCharacteristics = (payload) => ({
  type: UPDATE_TOWN_CHARACTERISTICS,
  payload
})

export const editReport = (payload) => ({
  type: EDIT_REPORT,
  payload
})

export const fetchCommute = (payload) => ({
  type: FETCH_COMMUTE,
  payload
})

export const deleteCommute = (payload) => ({
  type: DELETE_COMMUTE,
  payload
})

export const pushToWordpress = (payload) => ({
  type: PUSH_TO_WORDPRESS,
  payload
})

export const markFeatured = (payload) => ({
  type: MARK_FEATURED,
  payload
})

export const markFeaturedVideo = (payload) => ({
  type: MARK_FEATURED_VIDEO,
  payload
})

export const removeImage = (payload) => ({
  type: REMOVE_IMAGE,
  payload
})

export const removeVideo = (payload) => ({
  type: REMOVE_VIDEO,
  payload
})

export const communityDataByTownId = (payload) => ({
  type: GET_COMMUNITY_DATA,
  payload
})

export const editCommunityData = (payload) => ({
  type: EDIT_COMMUNITY_DATA,
  payload
})

export const repushToWordpress = (payload) => ({
  type: REPUSH_TO_WORDPRESS,
  payload
})

export const editPrivateSchool = (payload) => ({
  type: EDIT_PRIVATE_DATA,
  payload
})

export const editPublicSchool = (payload) => ({
  type: EDIT_PUBLIC_DATA,
  payload
})

export const deletePrivateSchool = (payload) => ({
  type: DELETE_PRIVATE_DATA,
  payload
})

export const deletePublicSchool = (payload) => ({
  type: DELETE_PUBLIC_DATA,
  payload
})

export const addPrivateSchool = (payload) => ({
  type: ADD_PRIVATE_DATA,
  payload
})

export const addPublicSchool = (payload) => ({
  type: ADD_PUBLIC_DATA,
  payload
})

export const synchCommunity = (payload) => ({
  type: SYNCH_COMMUNITY_DATA,
  payload
})

export const updateTownName = (payload) => ({
  type: UPDATE_TOWN_NAME,
  payload
})

export const deleteTownById = (payload) => ({
  type: DELETE_TOWN_BY_ID,
  payload
})

export const addResearch = (payload) => ({
  type: ADD_RESEARCH,
  payload
})

export const getResearch = (payload) => ({
  type: GET_RESEARCH,
  payload
})

export const deleteResearch = (payload) => ({
  type: DELETE_RESEARCH,
  payload
})

export const fetchTownById = (payload) => ({
  type: FETCH_TOWN_BY_ID,
  payload
})

export const fetchGooglePlace = (payload) => ({
  type: FETCH_GOOGLE_PLACE,
  payload
})

export const fetchGooglePlaceData = (payload) => ({
  type: FETCH_GOOGLE_PLACE_DATA,
  payload
})

export const updateImage = (payload) => ({
  type: UPDATE_IMAGE,
  payload
})

export const updateVideo = (payload) => ({
  type: UPDATE_VIDEO,
  payload
})

export const fetchSchools = (payload) => ({
  type: GET_SCHOOLS,
  payload
})

export const getAttomSchools = (payload) => ({
  type: GET_ATTOM_SCHOOLS,
  payload
})

export const addGooglePlace = (payload) => ({
  type: ADD_GOOGLE_PLACE,
  payload
})

export const editGooglePlace = (payload) => ({
  type: EDIT_GOOGLE_PLACE,
  payload
})

export const addCommute = (payload) => ({
  type: ADD_COMMUTE,
  payload
})

export const addResearchFile = (payload) => ({
  type: ADD_RESEARCH_FILE,
  payload
})

/**
 * Private: Epics
 */

const getTownRerportsEpic = (action$) =>
  action$
    .ofType(FETCH_TOWN_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getTownReports(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_TOWN_REPORT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_TOWN_REPORT_ERROR,
          payload: { error }
        }))
    })

const generateReportEpic = (action$) =>
  action$
    .ofType(GENERATE_TOWN_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.generateReport(action.payload))
        .flatMap((payload) => ([{
          type: GENERATE_TOWN_REPORT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GENERATE_TOWN_REPORT_ERROR,
          payload: { error }
        }))
    })

const getGeneratedReportEpic = (action$) =>
  action$
    .ofType(GET_GENERATED_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getGeneratedReport(action.payload))
        .flatMap((payload) => ([{
          type: GET_GENERATED_REPORT_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_GENERATED_REPORT_ERROR,
          payload: { error }
        }))
    })

const uploadImageEpic = (action$) =>
  action$
    .ofType(UPLOAD_IMAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.uploadImage(action.payload))
        .flatMap((payload) => ([{
          type: UPLOAD_IMAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPLOAD_IMAGE_ERROR,
          payload: { error }
        }))
    })

const uploadVideoEpic = (action$) =>
  action$
    .ofType(UPLOAD_VIDEO)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.uploadVideo(action.payload))
        .flatMap((payload) => ([{
          type: UPLOAD_VIDEO_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPLOAD_VIDEO_ERROR,
          payload: { error }
        }))
    })

const editReportEpic = (action$) =>
  action$
    .ofType(EDIT_REPORT)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editReport(action.payload))
        .flatMap((payload) => ([{
          type: EDIT_REPORT_SUCCESS,
          payload: { town_id: payload.townId, reSynch: true }
        }]))
        .catch((error) => Rx.Observable.of({
          type: EDIT_REPORT_ERROR,
          payload: { error }
        }))
    })

const pushToWordpressEpic = (action$) =>
  action$
    .ofType(PUSH_TO_WORDPRESS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.pushToWordpress(action.payload))
        .flatMap((payload) => ([{
          type: PUSH_TO_WORDPRESS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: PUSH_TO_WORDPRESS_ERROR,
          payload: { error }
        }))
    })

const repushToWordpressEpic = (action$) =>
  action$
    .ofType(REPUSH_TO_WORDPRESS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.repushToWordpress(action.payload))
        .flatMap((payload) => ([{
          type: PUSH_TO_WORDPRESS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: REPUSH_TO_WORDPRESS_ERROR,
          payload: { error }
        }))
    })

const markFeaturedEpic = (action$) =>
  action$
    .ofType(MARK_FEATURED)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.markFeatured(action.payload))
        .flatMap((payload) => ([{
          type: MARK_FEATURED_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: MARK_FEATURED_ERROR,
          payload: { error }
        }))
    })

const markFeaturedVideoEpic = (action$) =>
  action$
    .ofType(MARK_FEATURED_VIDEO)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.markFeaturedVideo(action.payload))
        .flatMap((payload) => ([{
          type: MARK_FEATURED_VIDEO_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: MARK_FEATURED_VIDEO_ERROR,
          payload: { error }
        }))
    })

const communityDataByTownIdEpic = (action$) =>
  action$
    .ofType(GET_COMMUNITY_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.communityDataByTownId(action.payload))
        .flatMap((payload) => ([{
          type: GET_COMMUNITY_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_COMMUNITY_DATA_ERROR,
          payload: { error }
        }))
    })

const fetchTownCharacteristicsEpic = (action$) =>
  action$
    .ofType(FETCH_TOWN_CHARACTERISTICS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchTownCharacteristics(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_TOWN_CHARACTERISTICS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_TOWN_CHARACTERISTICS_ERROR,
          payload: { error }
        }))
    })

const updateTownCharacteristicsEpic = (action$) =>
  action$
    .ofType(UPDATE_TOWN_CHARACTERISTICS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateTownCharacteristics(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_TOWN_CHARACTERISTICS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_TOWN_CHARACTERISTICS_ERROR,
          payload: { error }
        }))
    })

const fetchSchoolsEpic = (action$) =>
  action$
    .ofType(GET_SCHOOLS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchSchools(action.payload))
        .flatMap((payload) => ([{
          type: GET_SCHOOLS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_SCHOOLS_ERROR,
          payload: { error }
        }))
    })

const getAttomSchoolsEpic = (action$) =>
  action$
    .ofType(GET_ATTOM_SCHOOLS)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getAttomSchools(action.payload))
        .flatMap((payload) => ([{
          type: GET_ATTOM_SCHOOLS_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_ATTOM_SCHOOLS_ERROR,
          payload: { error }
        }))
    })

const removeImageEpic = (action$) =>
  action$
    .ofType(REMOVE_IMAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.removeImage(action.payload))
        .flatMap((payload) => ([{
          type: REMOVE_IMAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: REMOVE_IMAGE_ERROR,
          payload: { error }
        }))
    })

const removeVideoEpic = (action$) =>
  action$
    .ofType(REMOVE_VIDEO)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.removeVideo(action.payload))
        .flatMap((payload) => ([{
          type: REMOVE_VIDEO_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: REMOVE_VIDEO_ERROR,
          payload: { error }
        }))
    })

const editCommunityDataEpic = (action$) =>
  action$
    .ofType(EDIT_COMMUNITY_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editCommunityData(action.payload))
        .flatMap((payload) => ([{
          type: EDIT_COMMUNITY_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: EDIT_COMMUNITY_DATA_ERROR,
          payload: { error }
        }))
    })

const editPrivateSchoolEpic = (action$) =>
  action$
    .ofType(EDIT_PRIVATE_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editPrivateSchool(action.payload))
        .flatMap((payload) => ([{
          type: EDIT_PRIVATE_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: EDIT_PRIVATE_DATA_ERROR,
          payload: { error }
        }))
    })

const editPublicSchoolEpic = (action$) =>
  action$
    .ofType(EDIT_PUBLIC_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editPublicSchool(action.payload))
        .flatMap((payload) => ([{
          type: EDIT_PUBLIC_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: EDIT_PUBLIC_DATA_ERROR,
          payload: { error }
        }))
    })

const deletePrivateSchoolEpic = (action$) =>
  action$
    .ofType(DELETE_PRIVATE_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deletePrivateSchool(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_PRIVATE_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_PRIVATE_DATA_ERROR,
          payload: { error }
        }))
    })

const deletePublicSchoolEpic = (action$) =>
  action$
    .ofType(DELETE_PUBLIC_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deletePublicSchool(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_PUBLIC_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_PUBLIC_DATA_ERROR,
          payload: { error }
        }))
    })

const addPrivateSchoolEpic = (action$) =>
  action$
    .ofType(ADD_PRIVATE_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addPrivateSchool(action.payload))
        .flatMap((payload) => ([{
          type: ADD_PRIVATE_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_PRIVATE_DATA_ERROR,
          payload: { error }
        }))
    })

const addPublicSchoolEpic = (action$) =>
  action$
    .ofType(ADD_PUBLIC_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addPublicSchool(action.payload))
        .flatMap((payload) => ([{
          type: ADD_PUBLIC_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_PUBLIC_DATA_ERROR,
          payload: { error }
        }))
    })

const synchCommunityEpic = (action$) =>
  action$
    .ofType(SYNCH_COMMUNITY_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.synchCommunity(action.payload))
        .flatMap((payload) => ([{
          type: SYNCH_COMMUNITY_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: SYNCH_COMMUNITY_DATA_ERROR,
          payload: { error }
        }))
    })

const fetchCommuteEpic = (action$) =>
  action$
    .ofType(FETCH_COMMUTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchCommute(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_COMMUTE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_COMMUTE_ERROR,
          payload: { error }
        }))
    })


const addCommuteEpic = (action$) =>
  action$
    .ofType(ADD_COMMUTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addCommute(action.payload))
        .flatMap((payload) => ([{
          type: ADD_COMMUTE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_COMMUTE_ERROR,
          payload: { error }
        }))
    })

const deleteCommuteEpic = (action$) =>
  action$
    .ofType(DELETE_COMMUTE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteCommute(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_COMMUTE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_COMMUTE_ERROR,
          payload: { error }
        }))
    })

const updateImageEpic = (action$) =>
  action$
    .ofType(UPDATE_IMAGE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateImage(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_IMAGE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_IMAGE_ERROR,
          payload: { error }
        }))
    })

const updateVideoEpic = (action$) =>
  action$
    .ofType(UPDATE_VIDEO)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateVideo(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_VIDEO_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_VIDEO_ERROR,
          payload: { error }
        }))
    })

const updateTownNameEpic = (action$) =>
  action$
    .ofType(UPDATE_TOWN_NAME)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.updateTownName(action.payload))
        .flatMap((payload) => ([{
          type: UPDATE_TOWN_NAME_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: UPDATE_TOWN_NAME_ERROR,
          payload: { error }
        }))
    })

const deleteTownByIdEpic = (action$) =>
  action$
    .ofType(DELETE_TOWN_BY_ID)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteTownById(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_TOWN_BY_ID_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_TOWN_BY_ID_ERROR,
          payload: { error }
        }))
    })


const fetchTownByIdEpic = (action$) =>
  action$
    .ofType(FETCH_TOWN_BY_ID)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchTownById(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_TOWN_BY_ID_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_TOWN_BY_ID_ERROR,
          payload: { error }
        }))
    })

const fetchGooglePlaceEpic = (action$) =>
  action$
    .ofType(FETCH_GOOGLE_PLACE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchGooglePlace(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_GOOGLE_PLACE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_GOOGLE_PLACE_ERROR,
          payload: { error }
        }))
    })


const fetchGooglePlaceDataEpic = (action$) =>
  action$
    .ofType(FETCH_GOOGLE_PLACE_DATA)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.fetchGooglePlaceData(action.payload))
        .flatMap((payload) => ([{
          type: FETCH_GOOGLE_PLACE_DATA_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: FETCH_GOOGLE_PLACE_DATA_ERROR,
          payload: { error }
        }))
    })

const addGooglePlaceEpic = (action$) =>
  action$
    .ofType(ADD_GOOGLE_PLACE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addGooglePlace(action.payload))
        .flatMap((payload) => ([{
          type: ADD_GOOGLE_PLACE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_GOOGLE_PLACE_ERROR,
          payload: { error }
        }))
    })

const editGooglePlaceEpic = (action$) =>
  action$
    .ofType(EDIT_GOOGLE_PLACE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.editGooglePlace(action.payload))
        .flatMap((payload) => ([{
          type: EDIT_GOOGLE_PLACE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: EDIT_GOOGLE_PLACE_ERROR,
          payload: { error }
        }))
    })

const addResearchEpic = (action$) =>
  action$
    .ofType(ADD_RESEARCH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addResearch(action.payload))
        .flatMap((payload) => ([{
          type: ADD_RESEARCH_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_RESEARCH_ERROR,
          payload: { error }
        }))
    })

const deleteResearchEpic = (action$) =>
  action$
    .ofType(DELETE_RESEARCH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.deleteResearch(action.payload))
        .flatMap((payload) => ([{
          type: DELETE_RESEARCH_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: DELETE_RESEARCH_ERROR,
          payload: { error }
        }))
    })

const getResearchEpic = (action$) =>
  action$
    .ofType(GET_RESEARCH)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.getResearch(action.payload))
        .flatMap((payload) => ([{
          type: GET_RESEARCH_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: GET_RESEARCH_ERROR,
          payload: { error }
        }))
    })

const addResearchFileEpic = (action$) =>
  action$
    .ofType(ADD_RESEARCH_FILE)
    .mergeMap((action) => {
      return Rx.Observable.fromPromise(api.addResearchFile(action.payload))
        .flatMap((payload) => ([{
          type: ADD_RESEARCH_FILE_SUCCESS,
          payload
        }]))
        .catch((error) => Rx.Observable.of({
          type: ADD_RESEARCH_FILE_ERROR,
          payload: { error }
        }))
    })


/**
 * Public: Export Epics
 */

export const townEpic = combineEpics(
  getTownRerportsEpic,
  generateReportEpic,
  getGeneratedReportEpic,
  uploadImageEpic,
  uploadVideoEpic,
  deleteCommuteEpic,
  editReportEpic,
  pushToWordpressEpic,
  repushToWordpressEpic,
  markFeaturedEpic,
  markFeaturedVideoEpic,
  removeImageEpic,
  removeVideoEpic,
  communityDataByTownIdEpic,
  editCommunityDataEpic,
  editPrivateSchoolEpic,
  editPublicSchoolEpic,
  deletePrivateSchoolEpic,
  deletePublicSchoolEpic,
  addPrivateSchoolEpic,
  addPublicSchoolEpic,
  synchCommunityEpic,
  addCommuteEpic,
  updateTownNameEpic,
  deleteTownByIdEpic,
  addGooglePlaceEpic,
  editGooglePlaceEpic,
  addResearchEpic,
  deleteResearchEpic,
  fetchGooglePlaceDataEpic,
  fetchGooglePlaceEpic,
  getResearchEpic,
  fetchTownByIdEpic,
  addResearchFileEpic,
  fetchTownCharacteristicsEpic,
  updateTownCharacteristicsEpic,
  fetchCommuteEpic,
  updateImageEpic,
  updateVideoEpic,
  fetchSchoolsEpic,
  getAttomSchoolsEpic
)
