import Rx from 'rxjs/Rx'
import { combineEpics } from 'redux-observable'

import * as types from './action-types'
import * as api from './apis'
/*
    ////////////////////////////////
  /// dashboard epics ///
////////////////////////////////
*/
// fetch filter parmas for show filter option
const fetchFilterParamsEpic = (action$) =>
  action$.ofType(types.FETCH_FILTER_LIST).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getFilterParams(action.payload))
      .map((payload) => ({
        type: types.FETCH_FILTER_LIST_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_FILTER_LIST_ERROR,
          payload: { error },
        })
      )
  })
// fetch client profile with details of client
const fetchClientProfileEpic = (action$) =>
  action$.ofType(types.FETCH_CLIENT_PROFILE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.fetchClientProfile(action.payload))
      .map((payload) => ({
        type: types.FETCH_CLIENT_PROFILE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_CLIENT_PROFILE_ERROR,
          payload: { error },
        })
      )
  })

  const fetchDevelopmentMembersParamsEpic = (action$) =>
  action$.ofType(types.FETCH_DEVELOPMENT_MEMBERS).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getDevelopmentMembers(action.payload))
      .map((payload) => ({
        type: types.FETCH_DEVELOPMENT_MEMBERS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: types.FETCH_DEVELOPMENT_MEMBERS_ERROR,
          payload: { error },
        })
      )
  })

// combine all dashboard related epics
export const dashboardEpic = combineEpics(fetchFilterParamsEpic, fetchClientProfileEpic,fetchDevelopmentMembersParamsEpic)
