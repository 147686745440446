import { fetch } from '../../../utils'

const HOSTNAME_V2 = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

/*
//////////////////////////////////////////
  /// dashboard  related apis ///
//////////////////////////////////////////
*/
// get user filter sububs strategist and statuses
export const getFilterParams = () => {
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/filter-list`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// get your agent filter params
export const yourAgentsFilter = (data) => {
  return fetch(`${HOSTNAME_V2}/users/agent_filter_data?term=${data && data.searchTerm ? data.searchTerm : ''}`, {
    method: 'GET'
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}
// fetch client profile and its details
export const fetchClientProfile = (data) => {
 
  return fetch(`${SUBURBANJUNGLE_API_HOST}/users/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error
    })
}

//get Devlopment members
export const getDevelopmentMembers = () => {
  return fetch(`${HOSTNAME_V2}/members/developmentMembers`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((payload) => payload)
    .catch((error) => {
      throw error
    })
}

