import React, { PureComponent /*, PropTypes*/ } from 'react';
// import IPropTypes from 'react-immutable-proptypes'
import { Formik, Form, Field } from 'formik';

import './styles.scss';
import get from 'lodash/get';

export default class LinkInternalComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  validURL(str) {
    let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }


  addInsight = formData => {
    const {
      insight,
      match: {
        params: { townId }
      }
    } = this.props;

    let data = {
      id: get(insight, 'id', 0),
      town_id: townId,
      type: 'education',
      file: '',
      is_external: 0,
      insight_type: "link",
      created_by: this.props.user.id,
      fileName: '',
      ...formData
    };

    this.props.addInsight(data)
  }

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={get(this, "props.insight", {})}
          // validate={(values) => {
          //   let errors = {}
          //   if(values.link) {
          //     let isValid = this.validURL(values.link)
          //   }
          //   return errors
          // }}
          onSubmit={this.addInsight}
        >
          {({
            isSubmitting,
            errors,
            setFieldValue,
            values,
            handleBlur,
            handleChange
          }) => (
            <Form>
              <div className="form-group material-textfield">
                <Field
                  type="text"
                  name="link"
                  pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                  className="form-control material-textfield-input textfield-input-lg"
                  required
                />
                <label className="material-textfield-label label-lg">
                  Enter URL
                </label>
              </div>
              <div className="form-group material-textfield">
                <Field
                  as="textarea"
                  name="note"
                  className="form-control material-textfield-input textfield-input-lg"
                  rows="6"
                  required
                />
                <label className="material-textfield-label label-lg">
                  Add a note about your link...{' '}
                </label>
              </div>

              <button
                hidden
                ref={this.props.submitBtnRef}
                type="submit"
                className="btn btn__btn btn-dark btn__lg w__100"
              >
                Save
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
