export const GET_LEAVE_COMMENTS =
  'suburban/strategistV2/comment/GET_LEAVE_COMMENTS_API_ON_NEW_SERVER'
export const GET_LEAVE_COMMENTS_SUCCESS =
  'suburban/strategistV2/comment/GET_LEAVE_COMMENTS_API_ON_NEW_SERVER_SUCCESS'
export const GET_LEAVE_COMMENTS_ERROR =
  'suburban/strategistV2/comment/GET_LEAVE_COMMENTS_API_ON_NEW_SERVER_ERROR'

export const ADD_LEAVE_COMMENTS =
  'suburban/strategistV2/comment/ADD_LEAVE_COMMENTS_API_ON_NEW_SERVER'
export const ADD_LEAVE_COMMENTS_SUCCESS =
  'suburban/strategistV2/comment/ADD_LEAVE_COMMENTS_API_ON_NEW_SERVER_SUCCESS'
export const ADD_LEAVE_COMMENTS_ERROR =
  'suburban/strategistV2/comment/ADD_LEAVE_COMMENTS_API_ON_NEW_SERVER_ERROR'

export const REMOVE_LEAVE_COMMENT =
  'suburban/strategistV2/comment/REMOVE_LEAVE_COMMENT_API_ON_NEW_SERVER'
export const REMOVE_LEAVE_COMMENT_SUCCESS =
  'suburban/strategistV2/comment/REMOVE_LEAVE_COMMENT_API_ON_NEW_SERVER_SUCCESS'
export const REMOVE_LEAVE_COMMENT_ERROR =
  'suburban/strategistV2/comment/REMOVE_LEAVE_COMMENT_API_ON_NEW_SERVER_ERROR'

export const CLEAR_COMMENT_PHASE = 'suburban/strategistV2/comment/CLEAR_COMMENT_PHASE'
