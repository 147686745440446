/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react'

export default class SignUpPage3 extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { onBoardingData } = this.props
    const agent_full_name = onBoardingData && onBoardingData.data && onBoardingData.data.first_name+' '+onBoardingData.data.last_name
    const managing_borker_name= onBoardingData && onBoardingData.data && onBoardingData.data.broker_first_name+' '+onBoardingData.data.broker_last_name
    return (
      <div className="contact__sent__wrapper">
        <h5 className="title">Signup Successful!</h5>
        <p className="subtitle">
          Thank you for submitting your application to Suburban Jungle! Our team is currently reviewing your details. We are experiencing high levels of demand for our services, so we request you please be patient. Our team will be in touch with you for further interview steps if there is an opening in your market. If there is no immediate opening, you will be automatically placed on our waiting list. There is no need to follow up with us, we have all the information that we need. We will be in direct touch with you for next steps. Thank you and we look forward to reviewing your application!  
        </p>

        {/* <h5 className="title">Contract Sent!</h5>
        <p className="subtitle">
          We sent you an email for you and your Managing Broker to sign. Once we
          recieve both signatures we will activate you
        </p> 
        <div className="row">
          <div className="col-md-7">
            <div className="contact__sent__wrapper__inner">
              <h2>{agent_full_name}</h2>
              <p>{onBoardingData && onBoardingData.data && onBoardingData.data.email}</p>
            </div>
          </div>
          {
            (onBoardingData && onBoardingData.data && (onBoardingData.data.onboarding_status === 3 || onBoardingData.data.onboarding_status === 6)) ? 
            <div className="col-md-5">
              <div className="signature__status">
                <span className="status pending"></span>Pending Signature
              </div>
            </div>
            :
            (onBoardingData && onBoardingData.data && onBoardingData.data.onboarding_status === 5) &&
            <div className="col-md-5">
              <div className="signature__status">
                <span className="status signed"></span>Signed
              </div>
            </div>
          }
          
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="contact__sent__wrapper__inner">
              <h2>{managing_borker_name}</h2>
              <p>{onBoardingData && onBoardingData.data && onBoardingData.data.broker_email}</p>
            </div>
          </div>
          {
            (onBoardingData && onBoardingData.data && (onBoardingData.data.onboarding_status === 3 || onBoardingData.data.onboarding_status === 5)) ? 
            <div className="col-md-5">
              <div className="signature__status">
                <span className="status pending"></span>Pending Signature
              </div>
            </div>
            :
            (onBoardingData && onBoardingData.data && onBoardingData.data.onboarding_status === 6) &&
            <div className="col-md-5">
              <div className="signature__status">
                <span className="status signed"></span>Signed
              </div>
            </div>
          }
        </div> */}
      </div>
    );
  }
}
